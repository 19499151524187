import { PAIconActionAgreeDetailsActive, PAIconActionAgreementType, PAIconActionTemplateGray, PAIconActionPreviewGray,PAIconActionPreviewActive ,PAIconActionAgreeDetailsGray,PAIconActionAgreementTypeGray} from 'assets/images/svgs'
import React, { useEffect, useState } from 'react'
import FirstStep from './components/FirstStep'
import SecondStep from './components/SecondStep'
import ThirdStep from './components/ThirdStep'
import FourtStep from './components/FourtStep'

const CreateNewAgreementPage = () => {
    let [viewCount, setViewCount] = useState(0)

    useEffect(()=>{if(viewCount<0|| viewCount>3){
        setViewCount(0)
       
    }
    // eslint-disable-next-line
    },[])

    // console.log("===viewcount==",viewCount)
    

  

    // Function to change step safely
    function changeStep(direction) {
        setViewCount(prev => {
            let newViewCount = prev + direction;
            if (newViewCount < 0) return viewList.length - 1; // Loop back to last step
            if (newViewCount >= viewList.length) return 0; // Loop back to first step
            return newViewCount;
        });
    }

    // const viewList=[
    //     {id:0,view:<div><h2>Step1</h2>
        
    //     <div className=' flex justify-between items-center'>
    //     <button className=' bg-green-800 w-full rounded-md' onClick={()=>setViewCount(viewCount--)}>prev</button>
    //     <button className=' bg-green-800 w-full rounded-md' onClick={()=>setViewCount(viewCount++)}>Next</button></div></div>},
    //      {id:1,view:<div><h2>Step2</h2>
        
    //         <div className=' flex justify-between items-center'>
    //         <button className=' bg-green-800 w-full rounded-md' onClick={()=>setViewCount(viewCount--)}>prev</button>
    //         <button className=' bg-green-800 w-full rounded-md' onClick={()=>setViewCount(viewCount++)}>Next</button></div></div>},
    //          {id:2,view:<div><h2>Step3</h2>
        
    //             <div className=' flex justify-between items-center'>
    //             <button className=' bg-green-800 w-full rounded-md' onClick={()=>setViewCount(viewCount--)}>prev</button>
    //             <button className=' bg-green-800 w-full rounded-md' onClick={()=>setViewCount(viewCount++)}>Next</button></div></div>},
    //              {id:3,view:<div><h2>Step4</h2>
        
    //                 <div className=' flex justify-between items-center'>
    //                 <button className=' bg-green-800 w-full rounded-md' onClick={()=>setViewCount(viewCount--)}>prev</button>
    //                 <button className=' bg-green-800 w-full rounded-md' onClick={()=>setViewCount(viewCount++)}>Next</button></div></div>}

    // ]

    // Reusable Step Component
// const StepComponent = ({ title, onPrev, onNext }) => {
//     return (
//         <div>
//             <h2>{title}</h2>
//             <div className='flex justify-between items-center'>
//                 <button className='bg-green-800 w-full rounded-md' onClick={onPrev}>Prev</button>
//                 <button className='bg-green-800 w-full rounded-md' onClick={onNext}>Next</button>
//             </div>
//         </div>
//     );
// }

const viewList = [
    { id: 0, view: <FirstStep title="Step 1" onPrev={() => changeStep(-1)} onNext={() => changeStep(1)} /> },
    { id: 1, view: <SecondStep title="Step 2" onPrev={() => changeStep(-1)} onNext={() => changeStep(1)} /> },
    { id: 2, view: <ThirdStep title="Step 3" onPrev={() => changeStep(-1)} onNext={() => changeStep(1)} /> },
    { id: 3, view: <FourtStep title="Step 4" onPrev={() => changeStep(-1)} onNext={() => changeStep(1)} /> }
];

  return (
    <div className=' bg-[#eeeeee] w-full h-lvh flex-1'>

        <header className=' pb-7'>
        <div class="justify-start text-black text-sm font-medium ">Create new agreement</div>
        <div class="w-80 min-w-80 justify-start text-neutral-800 text-xs font-normal ">Follow the steps below to create an agreement</div>
        </header>

        <div className='bg-white min-h-lvh w-full pt-5'>

        <div className="grid lg:grid-cols-[40%,57.5%] gap-5 w-full px-4">


             <div className=" pb-5 bg-[#eeeeee]    pt-2 flex-1 h-full p-3">

             <div class="justify-start text-neutral-800 text-base font-semibold ">Create agreement steps</div>

             {/* middle box */}
             <div className=' flex flex-col items-start justify-start  mt-6'>
                <div className=' flex flex-row  gap-3'>
                {viewCount===0?<PAIconActionAgreementType/>:<PAIconActionAgreementTypeGray/>}


<div class=" inline-flex flex-col justify-start items-start gap-[3px]">
<div className={`self-stretch justify-start  ${viewCount===0?"text-black":"text-[#afafaf]"} text-sm font-medium`}>Agreement type</div>
<div class={`self-stretch justify-start text-neutral-400 text-xs font-normal ${viewCount===0?"text-neutral-400 ":"text-[#afafaf]"}`}>Select the agreement type</div>
</div>
                </div>
                <div className="flex flex-col w-full  items-start pl-3 space-y-2 justify-start">
                    {/* <div className="h-0.5 w-[35%] bg-[#AE4551]"></div> */}
                     <div className="h-[5px] w-[1.5px] bg-[#a3a3a3]"></div>
                     <div className="h-[5px] w-[1.5px] bg-[#a3a3a3]"></div>
                     <div className="h-[5px] w-[1.5px] bg-[#a3a3a3]"></div>
                     <div className="h-[5px] w-[1.5px] bg-[#a3a3a3]"></div>
                     <div className="h-[5px] w-[1.5px] bg-[#a3a3a3]"></div>
                     <div className="h-[5px] w-[1.5px] bg-[#a3a3a3]"></div>
                     <div className="h-[5px] w-[1.5px] bg-[#a3a3a3]"></div>
                   
                     
                   
                  </div>
               

               
             </div>

             <div className=' flex flex-col items-start justify-start  '>
                <div className=' flex flex-row  gap-3'>
                <PAIconActionTemplateGray/>


<div class=" inline-flex flex-col justify-start items-start gap-[3px]">
<div class={`self-stretch justify-start  ${viewCount===1?"text-black":"text-[#afafaf]"} text-sm font-medium`}>Template</div>
<div class={`self-stretch justify-start text-neutral-400 text-xs font-normal ${viewCount===1?"text-neutral-400 ":"text-[#afafaf]"}`}>Select from a list of uploaded Active & Published documents in your templates</div>
</div>
                </div>
                <div className="flex flex-col w-full  items-start pl-3 space-y-2 justify-start">
                    {/* <div className="h-0.5 w-[35%] bg-[#AE4551]"></div> */}
                     <div className="h-[5px] w-[1.5px] bg-[#a3a3a3]"></div>
                     <div className="h-[5px] w-[1.5px] bg-[#a3a3a3]"></div>
                     <div className="h-[5px] w-[1.5px] bg-[#a3a3a3]"></div>
                     <div className="h-[5px] w-[1.5px] bg-[#a3a3a3]"></div>
                     <div className="h-[5px] w-[1.5px] bg-[#a3a3a3]"></div>
                     <div className="h-[5px] w-[1.5px] bg-[#a3a3a3]"></div>
                     <div className="h-[5px] w-[1.5px] bg-[#a3a3a3]"></div>
                   
                     
                   
                  </div>
               

               
             </div>

             <div className=' flex flex-col items-start justify-start  '>
                <div className=' flex flex-row  gap-3'>

                    {/* PAIconActionPreviewActive */}

                    {viewCount===1? < PAIconActionPreviewActive/>:<PAIconActionPreviewGray/>}
               


<div class=" inline-flex flex-col justify-start items-start gap-[3px]">
<div className={`self-stretch justify-start  ${viewCount===2?"text-black":"text-[#afafaf]"} text-sm font-medium`}>Preview</div>
<div className={`self-stretch justify-start text-neutral-400 text-xs font-normal ${viewCount===2?"text-neutral-400 ":"text-[#afafaf]"}`}>Preview your selected document</div>
</div>
                </div>
                <div className="flex flex-col w-full  items-start pl-3 space-y-2 justify-start">
                    {/* <div className="h-0.5 w-[35%] bg-[#AE4551]"></div> */}
                     <div className="h-[5px] w-[1.5px] bg-[#a3a3a3]"></div>
                     <div className="h-[5px] w-[1.5px] bg-[#a3a3a3]"></div>
                     <div className="h-[5px] w-[1.5px] bg-[#a3a3a3]"></div>
                     <div className="h-[5px] w-[1.5px] bg-[#a3a3a3]"></div>
                     <div className="h-[5px] w-[1.5px] bg-[#a3a3a3]"></div>
                     <div className="h-[5px] w-[1.5px] bg-[#a3a3a3]"></div>
                     <div className="h-[5px] w-[1.5px] bg-[#a3a3a3]"></div>
                   
                     
                   
                  </div>
               

               
             </div>

             
             <div className=' flex flex-col items-start justify-start  '>
                <div className=' flex flex-row  gap-3'>

                {viewCount===3? <PAIconActionAgreeDetailsActive/>: <PAIconActionAgreeDetailsGray/>}
                


<div class=" inline-flex flex-col justify-start items-start gap-[3px]">
<div className={`self-stretch justify-start  ${viewCount===3?"text-black":"text-[#afafaf]"} text-sm font-medium`}>Agreeement Details</div>
<div class={`self-stretch justify-start ${viewCount===3?"text-neutral-400 ":"text-[#afafaf]"} text-xs font-normal `}>Input agreement details and add recipient party</div>
</div>
                </div>
                {/* <div className="flex flex-col w-full  items-start pl-3 space-y-2 justify-start">
                  
                     <div className="h-[5px] w-[1.5px] bg-[#a3a3a3]"></div>
                     <div className="h-[5px] w-[1.5px] bg-[#a3a3a3]"></div>
                     <div className="h-[5px] w-[1.5px] bg-[#a3a3a3]"></div>
                     <div className="h-[5px] w-[1.5px] bg-[#a3a3a3]"></div>
                   
                     
                   
                  </div> */}
               

               
             </div>


             

             {/* ends middle box */}
             </div>

             {/* Dynamic side */}

             <div className=' pb-5 bg-white    pt-2  p-3'>
                <div className=' flex  justify-center items-center flex-1 h-[100%]  '>


                    <div>
                        
                    <div class="self-stretch text-center justify-start text-black text-base font-semibold ">Step {viewCount+1}</div>
                    {viewList[viewCount].view}</div>
                </div>
             </div>
        </div>
        </div>

      
      
    </div>
  )
}

export default CreateNewAgreementPage
