import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  playerUpdateCompany,
  playerUpdateProfile,
  resetUserPassword,
  enable2FA,
  generateQR,
  enroll2FA,
  verifyOTP,
  getNotificationPreference,
  editNotificationPreference,
  resetUserPasswordBuyer,
  resetUserPasswordSupplier,
  resetUserPasswordFunder,
} from "appstate/api/settings/settings";

export const updatePlayerProfile = createAsyncThunk(
  "settings/updatePlayerProfile",
  playerUpdateProfile
);
export const updatePlayerCompany = createAsyncThunk(
  "settings/updatePlayerCompany",
  playerUpdateCompany
);
export const resetPassword = createAsyncThunk(
  "settings/resetPassword",
  resetUserPassword
);

export const resetPasswordBuyer = createAsyncThunk(
  "settings/resetPasswordBuyer",
  resetUserPasswordBuyer
);

export const resetPasswordSupplier = createAsyncThunk(
  "settings/resetUserPasswordSupplier",
  resetUserPasswordSupplier
);

export const resetPasswordFunder = createAsyncThunk(
  "settings/ resetUserPasswordFunder",
  resetUserPasswordFunder
);
export const enableUser2FA = createAsyncThunk(
  "settings/enableUser2FA",
  enable2FA
);
export const generateQRcode = createAsyncThunk(
  "settings/generateQRcode",
  generateQR
);
export const enrollUser2FA = createAsyncThunk(
  "settings/enrollUser2FA",
  enroll2FA
);
export const verifyUserOTP = createAsyncThunk(
  "settings/verifyUserOTP",
  verifyOTP
);

export const editNotificationSetting = createAsyncThunk(
  "settings/editNotificationSetting",
  editNotificationPreference
);

export const getNotificationSetting = createAsyncThunk(
  "settings/getNotificationSetting",
  getNotificationPreference
);

const initialState = {
  isLoading: false,
  qrImageUri: null,
  qrSecret: null,
  notificationPreferenceData: [],
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updatePlayerProfile.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updatePlayerProfile.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updatePlayerProfile.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updatePlayerCompany.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updatePlayerCompany.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updatePlayerCompany.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(resetPassword.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(resetPassword.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(resetPassword.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(enableUser2FA.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(enableUser2FA.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(enableUser2FA.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(generateQRcode.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(generateQRcode.fulfilled, (state, action) => {
      state.isLoading = false;
      state.qrImageUri = action.payload.qrImageUri;
      state.qrSecret = action.payload.secret;
    });
    builder.addCase(generateQRcode.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(enrollUser2FA.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(enrollUser2FA.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(enrollUser2FA.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(verifyUserOTP.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(verifyUserOTP.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(verifyUserOTP.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(editNotificationSetting.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editNotificationSetting.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(editNotificationSetting.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getNotificationSetting.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getNotificationSetting.fulfilled, (state, action) => {
      state.isLoading = false;
      state.notificationPreferenceData = action.payload;
    });
    builder.addCase(getNotificationSetting.rejected, (state) => {
      state.isLoading = false;
    });



    builder.addCase(resetPasswordBuyer.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(resetPasswordBuyer.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(resetPasswordBuyer.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(resetPasswordSupplier.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(resetPasswordSupplier.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(resetPasswordSupplier.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(resetPasswordFunder.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(resetPasswordFunder.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(resetPasswordFunder.rejected, (state) => {
      state.isLoading = false;
    });
    
  },
});

export default settingsSlice.reducer;
