import React, { useEffect, useState } from "react";
import { PAIconInvoiceIcon, PAIconSearchGreen } from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import * as XLSX from "xlsx";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";
import Table from "layout/table/Table";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "shared/Pagination";
import Loading from "shared/Loading";
import ViewDocumentModal from "features/RulesConfig/common/components/modals/DeleteRuleModal";
import LienApproveCard from "features/disbursement/commonforbuyer/components/LienApproveCard";
import UpcomingPayment from "features/disbursement/commonforbuyer/components/UpcomingPayment";
import SelectedRequests from "features/disbursement/commonforbuyer/components/SelectedRequests";
import EnterPin from "../directFunding/Details/Pin";
import {
  fetchLienedPayablesByVirtualAccount,
  fetchUpcomingPayablesByVirtualAccount,
  fetchOverDuePayablesByVirtualAccount,
  getInvoiceId,
} from "appstate/buyer/buyerSlice";
import FundTransferModal from "./FundTransferModal";

const BuyerPayableDisburesement = ({ selectedStatus }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedTab] = useState("single");
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [acceptPaymentModal, setAcceptPaymentModal] = useState(false);
  const [selectAllText, setSelectAllText] = useState("Select All");
  const [pinModal, setPinModal] = useState(false);
  const [selectDownloadType, setSelectDownloadType] = useState(false);
  const [upcomingData, setUpcomingData] = useState([]);
  const [virtualAccount, setVirtualAccount] = useState([]);
  const [lienedData, setLienedData] = useState([]);
  const [overdueData, setOverdueData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [invoiceIds, setInvoiceIds] = useState([]);
  const { isLoading } = useSelector((state) => state?.invoice);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(10);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedValue, setSelectedValue] = useState("ALL");
  const [isFundTransfer, setIsFundTransfer] = useState(false);
  const [isFundTransferModal, setIsFundTransferModal] = useState(false);
  const [fundTransferAmount, setFundTransferAmount] = useState("");
  const status = "payables";
  const paymentStatus = "PAYABLES";
  const { allVirtualAccountsData } = useSelector((state) => state?.wallet);
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  const { earlyPaymentOverview, earlyPaymentVirtualAccounts } = useSelector(
    (state) => state.buyer
  );
  
  const defaultVirtualAccount = virtualAccount.find(
    (account) => account.isDefaultVirtualAccount
  );

  const handleChange = (e) => {
    const value = e.target.value;
    setSelectedValue(value);
  };

  useEffect(() => {
    setVirtualAccount(allVirtualAccountsData?.data);
  }, [allVirtualAccountsData.data]);

  useEffect(() => {
    if (selectedAccount?.virtualAccountId || defaultVirtualAccount?.id) {
      const virtualAccountId =
        selectedAccount?.virtualAccountId || defaultVirtualAccount?.id;
      dispatch(
        fetchLienedPayablesByVirtualAccount({ virtualAccountId, selectedValue })
      );
    }
  }, [
    dispatch,
    selectedAccount?.virtualAccountId,
    defaultVirtualAccount?.id,
    selectedValue,
  ]);

  useEffect(() => {
    if (paymentSuccess) {
      const timer = setTimeout(() => {
        const virtualAccountId =
          selectedAccount?.virtualAccountId || defaultVirtualAccount?.id;
        dispatch(
          fetchLienedPayablesByVirtualAccount({
            virtualAccountId,
            selectedValue,
          })
        );
        setPaymentSuccess(false); // Reset after successful call
      }, 2000); // 2-second delay

      return () => clearTimeout(timer); // Cleanup on unmount or re-run
    }
  }, [
    dispatch,
    defaultVirtualAccount?.id,
    selectedAccount?.virtualAccountId,
    paymentSuccess,
    selectedValue,
  ]);

  useEffect(() => {
    if (selectedAccount?.virtualAccountId || defaultVirtualAccount?.id) {
      const virtualAccountId =
        selectedAccount?.virtualAccountId || defaultVirtualAccount?.id;
      dispatch(
        fetchUpcomingPayablesByVirtualAccount({
          virtualAccountId,
          selectedValue,
        })
      );
    }
  }, [
    dispatch,
    selectedAccount?.virtualAccountId,
    selectedValue,
    defaultVirtualAccount?.id,
  ]);

  useEffect(() => {
    if (paymentSuccess) {
      const timer = setTimeout(() => {
        const virtualAccountId =
          selectedAccount?.virtualAccountId || defaultVirtualAccount?.id;
        dispatch(
          fetchUpcomingPayablesByVirtualAccount({
            virtualAccountId,
            selectedValue,
          })
        );
        setPaymentSuccess(false); // Reset after successful call
      }, 2000); // 2-second delay

      return () => clearTimeout(timer); // Cleanup on unmount or re-run
    }
  }, [
    dispatch,
    defaultVirtualAccount?.id,
    selectedAccount?.virtualAccountId,
    paymentSuccess,
    selectedValue,
  ]);

  useEffect(() => {
    if (selectedAccount?.virtualAccountId || defaultVirtualAccount?.id) {
      const virtualAccountId =
        selectedAccount?.virtualAccountId || defaultVirtualAccount?.id;
      dispatch(
        fetchOverDuePayablesByVirtualAccount({
          virtualAccountId,
          selectedValue,
        })
      );
    }
  }, [
    dispatch,
    selectedAccount?.virtualAccountId,
    selectedValue,
    defaultVirtualAccount?.id,
  ]);

  useEffect(() => {
    if (paymentSuccess) {
      const timer = setTimeout(() => {
        const virtualAccountId =
          selectedAccount?.virtualAccountId || defaultVirtualAccount?.id;
        dispatch(
          fetchOverDuePayablesByVirtualAccount({
            virtualAccountId,
            selectedValue,
          })
        );
        setPaymentSuccess(false); // Reset after successful call
      }, 2000); // 2-second delay

      return () => clearTimeout(timer); // Cleanup on unmount or re-run
    }
  }, [
    dispatch,
    defaultVirtualAccount?.id,
    selectedAccount?.virtualAccountId,
    paymentSuccess,
    selectedValue,
  ]);

  const {
    fetchLienedPayablesByVirtualAccountData,
    fetchUpcomingPayablesByVirtualAccountData,
    fetchOverDuePayablesByVirtualAccountData,
  } = useSelector((state) => state.buyer);

  useEffect(() => {
    setLienedData(fetchLienedPayablesByVirtualAccountData);
  }, [fetchLienedPayablesByVirtualAccountData]);

  useEffect(() => {
    setUpcomingData(fetchUpcomingPayablesByVirtualAccountData);
  }, [fetchUpcomingPayablesByVirtualAccountData]);

  useEffect(() => {
    setOverdueData(fetchOverDuePayablesByVirtualAccountData);
  }, [fetchOverDuePayablesByVirtualAccountData]);

  useEffect(() => {
    if (selectedStatus === "Liened") {
      setTableData(lienedData);
    }
    if (selectedStatus === "Upcoming") {
      setTableData(upcomingData);
    }
    if (selectedStatus === "Overdue") {
      setTableData(overdueData);
    }
  }, [upcomingData, lienedData, overdueData, selectedStatus]);

  const formatAmount = (amount) => {
    if (!amount) return "0.00";
    const parsedAmount = parseFloat(amount);
    if (isNaN(parsedAmount)) return "0.00";
    return parsedAmount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const handleSearchInputChange = (event) => {
    const value = event.target.value;
    setSearchQuery(value);
    sessionStorage.setItem("searchQueryFunder", value);
  };

  // CHECKBOX
  const handleCheckboxChange = (row) => {
    const isSelected = selectedRows.includes(row.id);
    let newSelectedRows = [];

    if (isSelected) {
      newSelectedRows = selectedRows.filter((id) => id !== row.id);
    } else {
      newSelectedRows = [...selectedRows, row.id];
    }

    setSelectedRows(newSelectedRows);
  };

  // SELECT ALL
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
      setSelectAllText("Select All");
    } else {
      const allIds = tableData?.payables?.data?.map((row) => row.id);
      setSelectedRows(allIds);
      setSelectAllText("Deselect All");
    }
    setSelectAll(!selectAll);
    // setDisabled(selectAll);
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(
      tableData?.payables?.data.map((row) => ({
        "Program Ref No": row?.refNo,
        "Invoice Number": `INV-${row?.invoiceNumber}`,
        Buyer: row?.buyerCompanyName,
        "Due Date": row?.dueDate,
        "Payment Date": row?.paymentDate,
        Currency: row?.currency,
        "Invoice Amount": formatAmount(row?.invoiceAmount),
        "Discount Amount": formatAmount(row?.discountAmount),
        "Discount Rate": formatAmount(row?.discountRate),
        "Fundable Amount": formatAmount(row?.fundableAmount),
      }))
    );
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Direct Funding");
    XLSX.writeFile(wb, "DirectFundingData.xlsx");
  };

  const getTimeRemaining = (paymentDate) => {
    if (!paymentDate) return "No Date Provided";
  
    const now = new Date();
    const paymentTime = new Date(paymentDate);
    const timeDiff = paymentTime - now;
  
    if (timeDiff <= 0) return "Time Expired";
  
    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDiff % (1000 * 60) / 1000));
  
    return `${days}d : ${hours}h : ${minutes}m : ${seconds}`;
  };

  const columns = [
    {
      name: (
        <input type="checkbox" onChange={handleSelectAll} checked={selectAll} />
      ),
      cell: (row) => (
        <input
          type="checkbox"
          onChange={() => handleCheckboxChange(row)}
          checked={selectedRows.includes(row?.id)}
        />
      ),
      width: "50px",
    },

    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Invoice Ref.
        </span>
      ),
      selector: (row) => row.invoiceRef,
      sortable: true,
      // width: "9rem",
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Supplier
        </span>
      ),
      selector: (row) => row?.supplierName,
      sortable: true,
    },

    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Funding Channel
        </span>
      ),
      selector: (row) => row?.fundingChannel,
      sortable: true,
    },

    {
      name: "Currency",
      selector: (row) => row?.currency,
      sortable: true,
    },
    {
      name: "Invoice Amount",
      selector: (row) => row?.invoiceAmount,
      sortable: true,
      cell: (row) => (
        <p className=" text-[#07593D]">{formatAmount(row?.invoiceAmount)}</p>
      ),
      // width: "9rem",
    },

    {
      name: "Due Date",
      selector: (row) => row?.dueDate,
      sortable: true,
      cell: (row) => (
        <p className=" text-[#07593D]">{getTimeRemaining(row?.dueDate)}</p>
      ),
      // width: "9rem",
    },

    {
      name: "Action",
      cell: (row) =>
        selectedStatus === "Upcoming" || selectedStatus === "Overdue" ? (
          <motion.button
            onClick={() => {
              setInvoiceIds(row?.invoiceId);
              setFundTransferAmount(row?.invoiceAmount)
              setSelectAll(false);
              setSelectedRows([]);
              setSelectAllText("Select All");
              setIsFundTransfer(true);
            }}
            whileTap={{ scale: 0.95 }}
            className="py-2 px-4 text-xs  text-white rounded bg-primaryColor"
          >
            Fund Request
          </motion.button>
        ) : (
          <motion.button
            onClick={() => {
              setInvoiceIds(row?.invoiceId);
              setSelectAll(false);
              setSelectedRows([]);
              setSelectAllText("Select All");
              setAcceptPaymentModal(true);
            }}
            whileTap={{ scale: 0.95 }}
            className="py-2 px-4 text-xs  text-white rounded bg-primaryColor"
          >
            Pay Now
          </motion.button>
        ),
    },
  ];

  return (
    <>
      <section className="flex justify-end">
        <div className="w-full flex mdw-1/2 lg:w-1/3 items-center gap-2">
          <div className="flex gap-2.5 w-full bg-white border-[0.5px] border-[#ccc] p-2.5 items-center rounded-[5px]">
            <PAIconSearchGreen />
            <input
              type="text"
              placeholder="Search for invoices on the open market"
              value={searchQuery}
              onChange={handleSearchInputChange}
              className="p-2 w-full text-xs flex items-center text-black placeholder:text-black focus:outline-none"
            />
          </div>
        </div>
      </section>

      <section className="mt-3 mb-3 flex-col lg:flex-row flex gap-5 justify-between">
        <LienApproveCard
          account={earlyPaymentVirtualAccounts.data}
          virtualAccount={virtualAccount}
          status={status}
          selectedStatus={selectedStatus}
          lienedOverview={lienedData}
          upcomingOverview={upcomingData}
          overdueOverview={overdueData}
          earlyPaymentRequestsOverviewUpcoming={upcomingData}
          selectedAccount={selectedAccount}
          earlyPaymentOverview={earlyPaymentOverview}
          overdueData={overdueData}
          setSelectedAccount={setSelectedAccount}
        />
        {selectAll ? (
          <SelectedRequests
            subTitle="Details on the selected Early Payment offers"
            // handleMakePayment={handleSendReminder}
          />
        ) : (
          <UpcomingPayment
            subText="Details on the next payable due date"
            handleMakePayment={() => {
              setSelectAll(false);
              setSelectedRows([]);
              setSelectAllText("Select All");
              setAcceptPaymentModal(true);
            }}
            isData={lienedData}
            setInvoiceIds={setInvoiceIds}
            status={status}
            earlyPaymentRequestsOverview={lienedData}
            selectedStatus={selectedStatus}
            lienedOverview={lienedData}
            upcomingOverview={upcomingData}
            overdueOverview={overdueData}
            setIsFundTransfer={setIsFundTransfer}
          />
        )}
      </section>

      {/* TABLE */}
      <div>
        {tableData?.payables?.data.length < 0 ? (
          <div>
            <div className="flex justify-between items-center px-[15px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
              <p className="text-black text-sm font-medium">
                Due Invoices from Direct Funding
              </p>
            </div>

            <TableEmptyState dashboard />
          </div>
        ) : (
          <Card className="rounded-[10px] min-h-[80vh] mt-[15px]">
            <div className="flex justify-between items-center p-[20px] bg-alabasterHeader rounded-t-[10px]">
              <div className="flex gap-6 items-center">
                <div>
                  <span className="flex items-center gap-1">
                    <p className="text-black font-bold">Payables</p>
                  </span>

                  <span className="text-xs font-thin">
                    List of all approved Payables from your supplier
                  </span>
                </div>

                <div>
                  <select
                    onChange={handleChange}
                    className="text-xs border border-[#DEDEDEC7] outline-none rounded-[5px] px-5 py-3 cursor-pointer"
                  >
                    <option value="ALL" key="">
                      ALL
                    </option>
                    <option value="PAYABLES" key="">
                      Payables
                    </option>
                    <option value="EPR_VIA_WALLET" key="">
                      EPR - via wallet
                    </option>
                    <option value="DIRECT_FUNDING_REQUEST" key="">
                      Direct Funding Request
                    </option>
                    <option value="EPR_VIA_DIRECT_FUNDING_REQUEST" key="">
                      EPR - via Direct Funding Request
                    </option>
                    <option value="EPO_DYNAMIC_DISCOUNTING" key="">
                      Dynamic Discounting
                    </option>
                    <option value="EPO_REVERSE_FACTORING" key="">
                      Reverse Factoring
                    </option>
                    <option value="OPEN_MARKET" key="">
                      Open Market
                    </option>
                    <option value="CLOSED_MARKET" key="">
                      Close Market
                    </option>
                  </select>
                </div>
              </div>

              {selectedTab === "single" && (
                <div className="flex items-center justify-center gap-3">
                  <div
                    className=" text-xs rounded-md py-2.5 px-4 text-white bg-[#2EA923] cursor-pointer"
                    onClick={handleSelectAll}
                    // checked={selectAll}
                  >
                    <p>{selectAllText}</p>
                  </div>
                  <button
                    style={{
                      boxShadow: "2px 3px 10px 0px rgba(0, 0, 0, 0.10)",
                    }}
                    className={`text-xs  rounded-md py-2.5 px-4 text-white ${
                      selectedRows.length === 0
                        ? "cursor-not-allowed bg-[#C2C0C0]"
                        : "cursor-pointer bg-secondaryColor"
                    }`}
                    disabled={selectedRows.length === 0}
                    onClick={() => setAcceptPaymentModal(true)}
                  >
                    <p> Pay Now</p>
                  </button>
                </div>
              )}
            </div>
            <div className="pb-3">
              <div className="pb-3">
                <Table
                  columns={columns}
                  data={tableData?.payables?.data}
                  onRowClicked={(row) => {
                    setFundTransferAmount(row.invoiceAmount);
                    dispatch(
                      getInvoiceId({
                        programId: row.programId,
                        invoiceId: row.invoiceId,
                        fundingChannel: row.fundingChannel,
                        pfpId: row.pfpId,
                      })
                    );
                    navigate(
                      `/disbursement/funder/disbursement/PayableDetailsPage/${row?.invoiceId}`
                    );
                  }}
                  progressPending={isLoading}
                  progressComponent={<Loading />}
                  pointer
                  tableHeader
                  className="mt-5"
                />
                <div className="flex justify-center gap-x-[8px] mt-[20px]">
                  {tableData?.payables?.data?.length > 0 && (
                    <Pagination
                      page={page}
                      startPage={startPage}
                      setEndPage={setEndPage}
                      endPage={endPage}
                      setStartPage={setStartPage}
                      setPage={setPage}
                      totalPages={tableData?.length}
                    />
                  )}
                </div>
              </div>
            </div>
          </Card>
        )}
      </div>

      {/* Accept Modal */}
      <AnimatePresence>
        {selectDownloadType && (
          <ViewDocumentModal
            icon={<PAIconInvoiceIcon className=" animate-pulse" />}
            onAction={exportToExcel}
            onClose={() => setSelectDownloadType(false)}
          >
            <span className=" text-center">
              Would you like to proceed with sending a{" "}
              <span className="font-bold text-center text-primaryColor">
                Pay
              </span>{" "}
              this invoice list
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {acceptPaymentModal && (
          <ViewDocumentModal
            onAction={() => {
              setAcceptPaymentModal(false);
              setPinModal(true);
              // setTransferModal(true);
            }}
            onClose={() => setAcceptPaymentModal(false)}
          >
            <span className=" text-center">
              Are you sure you would like to{" "}
              <span className="font-semibold">Pay now</span> for{" "}
              {selectAll ? "these multiple" : "this"} invoices?
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {isFundTransfer && (
          <ViewDocumentModal
            onAction={() => {
              setAcceptPaymentModal(false);
              setIsFundTransferModal(true);
              // setTransferModal(true);
            }}
            onClose={() => setIsFundTransfer(false)}
          >
            <span className=" text-center">
              Are you sure you want to fund this virtual account for this
              request?
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {isFundTransferModal && (
          <FundTransferModal
            onAction={() => {
              setAcceptPaymentModal(false);
              setPinModal(true);
              // setTransferModal(true);
            }}
            onClose={() => setIsFundTransferModal(false)}
            isOpen={isFundTransfer}
            defaultVirtualAccount={defaultVirtualAccount}
            upcomingOverview={upcomingData}
            fundTransferAmount={fundTransferAmount}
            invoiceIds={invoiceIds}
          >
            <span className=" text-center">
              Are you sure you want to fund this virtual account for this
              request?
            </span>
          </FundTransferModal>
        )}
      </AnimatePresence>

      <AnimatePresence>
        <EnterPin
          isOpen={pinModal}
          onClose={() => setPinModal(false)}
          paymentStatus={paymentStatus}
          invoiceId={invoiceIds}
          setPaymentSuccess={setPaymentSuccess}
          pinMessage={
            "Enter your PIN to fund this request from the chosen virtual account."
          }
        />
      </AnimatePresence>

      {/* Export Invoice List */}
      <AnimatePresence>
        {selectDownloadType && (
          <ViewDocumentModal
            icon={<PAIconInvoiceIcon className=" animate-pulse" />}
            onAction={exportToExcel}
            onClose={() => setSelectDownloadType(false)}
          >
            <span className=" text-center">
              Are you sure you want to{" "}
              <span className="font-bold text-center text-primaryColor">
                Export
              </span>{" "}
              this invoice list
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>
    </>
  );
};

export default BuyerPayableDisburesement;