import React, { useState, useEffect } from "react";
import DashboardLayout from "layout/dashboardlayout";
import { PAIconArrowLeftGreen } from "assets/images/svgs";
import { useNavigate } from "react-router-dom";

import TransitionScale from "shared/animation/TransitionScale";
import PendingApprovalSucessModal from "../components/modal/PendingApprovalSucessModals";
import PendingApprovalProceedModal from "../components/modal/PendingApprovalProceedModal";
import PendingApprovalDeclineSucessModal from "../components/modal/PendingApprovalDeclineSuccess";
import PendingApprovalDeclineModal from "../components/modal/PendingApprovalDeclineModal";
import ReasonForDeclineModal from "../components/modal/ReasonForDeclineModal";
import { AnimatePresence } from "framer-motion";
import { useParams } from "react-router-dom";
import { fetchSinglePendingRequestById } from "appstate/workflow/workflowSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

// import { useNavigate } from "react-router-dom";

const SupplierEarlyPaymentRequest = () => {
  const [ongoingCancelBidModal, setOngoingCancelBidModal] = useState(false);
  const [ongoingBidCancelledSuccessModal, setOngoingBidCancelledSuccessModal] =
    useState(false);
  const { workflowId } = useParams();

  const [ongoingCancelBidModal2, setOngoingCancelBidModal2] = useState(false);
  const [
    ongoingBidCancelledSuccessModal2,
    setOngoingBidCancelledSuccessModal2,
  ] = useState(false);
  const [reasonforDecline, setReasonForDecline] = useState(false);

  const handleOnCancelSuccessClose = () => {
    setOngoingBidCancelledSuccessModal(false);
    navigate(-1);
  };
  const handleOnCancelBidTrue = () => {
    setOngoingCancelBidModal(false);
    setOngoingBidCancelledSuccessModal(true);
  };
  const handleOnCancelSuccessClose2 = () => {
    setOngoingBidCancelledSuccessModal2(false);
    navigate(-1);
  };
  const handleOnCancelBidTrue2 = () => {
    setOngoingCancelBidModal2(false);
    // setOngoingBidCancelledSuccessModal2(true);
    setReasonForDecline(true);
  };

  const handleDeclineContinue = () => {
    setReasonForDecline(false);
    setOngoingBidCancelledSuccessModal2(true);
  };

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };
  const dispatch = useDispatch();

  const { singleDetailPendingRequest } = useSelector((state) => state.workflow);
  useEffect(() => {
    dispatch(fetchSinglePendingRequestById({ id: workflowId }));
  }, [dispatch, workflowId]);

  return (
    <>
      <TransitionScale>
        <PendingApprovalProceedModal
          isOpen={ongoingCancelBidModal}
          onClose={() => setOngoingCancelBidModal(false)}
          handleYes={handleOnCancelBidTrue}
        />
      </TransitionScale>
      <TransitionScale>
        <PendingApprovalSucessModal
          isOpen={ongoingBidCancelledSuccessModal}
          onClose={handleOnCancelSuccessClose}
          message={"Request Approved"}
        />
      </TransitionScale>
      <TransitionScale>
        <PendingApprovalProceedModal
          isOpen={ongoingCancelBidModal}
          onClose={() => setOngoingCancelBidModal(false)}
          handleYes={handleOnCancelBidTrue}
        />
      </TransitionScale>

      <AnimatePresence>
        <TransitionScale>
          <PendingApprovalDeclineSucessModal
            isOpen={ongoingBidCancelledSuccessModal2}
            onClose={handleOnCancelSuccessClose2}
            message={"Request Declined"}
          />
        </TransitionScale>
      </AnimatePresence>
      <AnimatePresence>
        <TransitionScale>
          <PendingApprovalDeclineModal
            isOpen={ongoingCancelBidModal2}
            onClose={() => setOngoingCancelBidModal2(false)}
            handleYes={handleOnCancelBidTrue2}
            message={" Are you sure you would like to decline this"}
            midMessage={"workflow?"}
          />
        </TransitionScale>
      </AnimatePresence>
      <AnimatePresence>
        <TransitionScale>
          {reasonforDecline && (
            <ReasonForDeclineModal
              onClose={() => setReasonForDecline(false)}
              onContinue={handleDeclineContinue}
            />
          )}
        </TransitionScale>
      </AnimatePresence>

      <DashboardLayout
        section="Workflow "
        subHeader="Manage all your workflow approvals"
      >
        <div className=" lg:min-h-screen relative">
          {" "}
          <>
            {/* First Phase */}

            {/* <div class="fixed top-0 left-0 h-screen w-1/4 bg-gray-300 p-4"></div> */}
            <div className=" sticky top-0">
              <header className=" text-primaryColor ">
                <span
                  onClick={() => handleBack()}
                  className="flex cursor-pointer  w-fit font-light items-center text-sm gap-2"
                >
                  <PAIconArrowLeftGreen />
                  Go Back
                </span>
              </header>
              <TransitionScale className="px-6 pt-6 pb-8 bg-white  rounded-[10px] mt-5  z-0">
                <div className="lg:flex md:flex justify-between  w-full sm:block  ">
                  <div>
                    {" "}
                    <div class="text-[#11192a] text-xs font-bold ">
                      Approval Stage
                    </div>
                  </div>

                  {/* <div>
                {" "}
                <div class="h-[30px] px-8 py-2 bg-[#de1515]/30 rounded-[5px] justify-center items-center gap-2.5 inline-flex">
                  <div class="text-center text-[#de1515] text-xs font-semibold">
                    Declined
                  </div>
                </div>
              </div> */}

                  {/* <div className=" flex gap-3">
                    <button
                      className={` w-24   py-2 px-3  text-[#de1515]  border border-[#de1515] justify-center  flex items-center bg-white rounded-[5px]  text-sm ${"cursor-pointer"}`}
                      //   class=" px-6 py-2 bg-white rounded-[5px] border border-[#de1515] justify-center items-center gap-2.5 inline-flex"
                      onClick={() => setOngoingCancelBidModal2(true)}
                    >
                      <div>Decline</div>
                    </button>
                    <button
                      className={`w-24  px-3 text-white justify-center  flex items-center bg-[#07593d] rounded-[5px] py-2 text-sm ${"cursor-pointer"}`}
                      onClick={() => setOngoingCancelBidModal(true)}
                    >
                      Approve
                    </button>
                  </div> */}
                </div>

                <div className="lg:flex md:flex justify-between  w-full sm:block  mt-3 ">
                  {/* underlined div */}
                  <div className="">
                    <div>
                      <span className="text-[#7a7a7a] text-xs font-bold ">
                        User
                      </span>
                      <span className="text-[#7a7a7a] text-xs font-normal ">
                        {" "}
                        to approve
                      </span>
                    </div>
                    <div className="mb-[15px] mt-[10px] flex justify-center items-center gap-x-[15px] ">
                      <div>
                        <div className="w-[100px] h-1   bg-[#F08000]  rounded-[100px] lg:w-[150px]  xs:w-[70px]"></div>
                      </div>
                      <div>
                        <div className="w-[100px] h-1    bg-[#F08000]/30  rounded-[100px] lg:w-[150px]  xs:w-[70px]"></div>
                      </div>
                      <div>
                        <div className="w-[100px] h-1  bg-[#F08000]/30  rounded-[100px] lg:w-[150px] xs:w-[70px] "></div>
                      </div>
                    </div>
                  </div>

                  {/* Button division */}

                  <div>
                    {" "}
                    {/* <div class="h-[30px] px-8 py-2 bg-[#07593d]/30 rounded-[5px] justify-center items-center gap-2.5 inline-flex">
                  <div class="text-center text-[#07593d] text-xs font-semibold">
                    Completed
                  </div>
                </div> */}
                  </div>
                </div>

                {/* Second Box phase and pages */}
                <div className="lg:flex md:flex justify-between  w-full sm:block  mt-6 ">
                  <div>
                    <div class=" flex-col justify-center items-start gap-2 inline-flex">
                      <div class="self-stretch justify-between items-center inline-flex gap-28">
                        <div class="text-[#222222] text-[14px] font-semibold">
                          Date Sent:
                        </div>
                        <div class="text-[#7a7a7a] text-[14px] font-medium ">
                          {singleDetailPendingRequest?.data?.dateSent}
                        </div>
                      </div>
                      <div class="self-stretch justify-between items-center inline-flex gap-28">
                        <div class="text-[#222222] text-[14px] font-semibold">
                          Transaction Type:
                        </div>
                        <div class="text-[#7a7a7a] text-[14px] font-medium ">
                          {singleDetailPendingRequest?.data?.type}
                        </div>
                      </div>
                      <div class="self-stretch justify-between items-center inline-flex  gap-28">
                        <div class="text-[#222222] text-[14px] font-semibold">
                          Action:
                        </div>
                        <div class="text-[#7a7a7a] text-[14px] font-medium ">
                          {singleDetailPendingRequest?.data?.action}
                        </div>
                      </div>
                      <div class="self-stretch justify-between items-center inline-flex">
                        <div class="text-[#222222] text-[14px] font-semibold">
                          Approval Stage:
                        </div>
                        <div class="text-[#7a7a7a] text-[14px] font-medium ">
                          {singleDetailPendingRequest?.data?.approvalStage}
                        </div>
                      </div>
                      <div class="self-stretch justify-between items-center inline-flex">
                        <div class="text-[#222222] text-[14px] font-semibold">
                          Days in Queue:
                        </div>
                        <div class="text-[#7a7a7a] text-[14px] font-medium ">
                          {singleDetailPendingRequest?.data?.daysInQueue}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Second side */}

                  <div>
                    <div class=" flex-col justify-center items-start gap-2 inline-flex">
                      <div class="self-stretch justify-between items-center inline-flex gap-28">
                        <div class="text-[#222222] text-[14px] font-semibold">
                          Sender:
                        </div>
                        <div class="text-[#7a7a7a] text-[14px] font-medium ">
                          {singleDetailPendingRequest?.data?.sender}
                        </div>
                      </div>
                      <div class="self-stretch justify-between items-center inline-flex gap-28">
                        <div class="text-[#222222] text-[14px] font-semibold">
                          Previous Approver:
                        </div>
                        <div class="text-[#7a7a7a] text-[14px] font-medium ">
                          {singleDetailPendingRequest?.data?.previousApprover}
                        </div>
                      </div>
                      <div class="self-stretch justify-between items-center inline-flex  gap-28">
                        <div class="text-[#222222] text-[14px] font-semibold">
                          Next Approver:
                        </div>
                        <div class="text-[#7a7a7a] text-[14px] font-medium ">
                          {singleDetailPendingRequest?.data?.nextApprover}
                        </div>
                      </div>
                    </div>
                    {/* Button */}
                  </div>
                </div>
              </TransitionScale>

              <TransitionScale className="px-6 py-5 bg-white  rounded-[10px] mt-8">
                <div>
                  <div class="text-black text-sm font-bold ">Reason</div>
                  <div class="text-[#222222] text-base font-normal  mt-6">
                    {singleDetailPendingRequest?.data?.reason &&
                      singleDetailPendingRequest?.data?.reason}
                  </div>
                  <div class=" mt-2 justify-start items-center gap-2 inline-flex">
                    <div class="text-[#7a7a7a] text-sm font-normal ">
                      Declined by:
                    </div>
                    <div class="text-[#222222] text-sm font-semibold ">
                      {singleDetailPendingRequest?.data?.declinedBy &&
                        singleDetailPendingRequest?.data?.declinedBy}
                    </div>
                  </div>
                </div>
              </TransitionScale>
            </div>

            <div className=" sticky top-0">
              <TransitionScale className=" pb-5 bg-white  rounded-[3px] mt-5 pt-2 z-1000">
                {/* Header with Left Padding */}

                {/* Other sections */}

                <section className="  mt-3">
                  <div class="text-[#222222] text-lg font-semibold mt-4 px-12">
                    Invoice |
                    {singleDetailPendingRequest?.data?.payload?.invoiceNo}
                  </div>

                  {/* Down Flex Texts */}
                  <div className=" flex justify-between px-12">
                    {/* first stage */}

                    <div class="lg:w-[229px]  sm:w-full h-[117px] flex-col justify-start items-start gap-3 inline-flex">
                      <div class="self-stretch justify-between items-center inline-flex mt-2">
                        <div class="text-[#222222] text-base font-semibold  ">
                          Reference No:{" "}
                        </div>
                        <div class="text-[#555555] text-sm font-semibold ">
                          {
                            singleDetailPendingRequest?.data?.payload
                              ?.invoiceRef
                          }
                        </div>
                      </div>
                      <div class="self-stretch  flex-col justify-center items-start gap-1 flex mt-2">
                        <div class="self-stretch justify-between items-center inline-flex">
                          <div class="text-[#222222] text-xs font-semibold ">
                            Invoice Number
                          </div>
                          <div class="text-[#7a7a7a] text-xs font-semibold ">
                            INV{" "}
                            {
                              singleDetailPendingRequest?.data?.payload
                                ?.invoiceNo
                            }
                          </div>
                        </div>
                        <div class="self-stretch justify-between items-center inline-flex ">
                          <div class="text-[#222222] text-xs font-semibold ">
                            Created Date:{" "}
                          </div>
                          <div class="text-[#7a7a7a] text-xs font-semibold ">
                            {
                              singleDetailPendingRequest?.data?.payload
                                ?.createdDate
                            }
                          </div>
                        </div>
                        <div class="self-stretch justify-between items-center inline-flex">
                          <div class="text-[#222222] text-xs font-semibold ">
                            End Date:{" "}
                          </div>
                          <div class="text-[#7a7a7a] text-xs font-semibold ">
                            {singleDetailPendingRequest?.data?.payload?.endDate}
                          </div>
                        </div>
                        <div class="self-stretch justify-between items-center inline-flex">
                          <div class="text-[#222222] text-xs font-semibold ">
                            Payment Date:{" "}
                          </div>
                          <div class="text-[#7a7a7a] text-xs font-semibold ">
                            {
                              singleDetailPendingRequest?.data?.payload
                                ?.paymentDate
                            }
                          </div>
                        </div>
                        <div class="self-stretch justify-between items-center inline-flex">
                          <div class="text-[#222222] text-xs font-semibold ">
                            Invoice Amount:{" "}
                          </div>
                          <div class="text-[#7a7a7a] text-xs font-semibold ">
                            {
                              singleDetailPendingRequest?.data?.payload
                                ?.invoicedAmount
                            }
                          </div>
                        </div>
                        <div class="self-stretch justify-between items-center inline-flex">
                          <div class="text-[#222222] text-xs font-semibold ">
                            Due Date:{" "}
                          </div>
                          <div class="text-[#7a7a7a] text-xs font-semibold ">
                            {singleDetailPendingRequest?.data?.payload?.dueDate}
                          </div>
                        </div>
                        <div class="self-stretch justify-between items-center inline-flex">
                          <div class="text-[#222222] text-xs font-semibold ">
                            ReceivableAmount
                          </div>
                          <div class="text-[#7a7a7a] text-xs font-semibold ">
                            {
                              singleDetailPendingRequest?.data?.payload
                                ?.receivableAmount
                            }
                          </div>
                        </div>
                        <div class="self-stretch justify-between items-center inline-flex">
                          <div class="text-[#222222] text-xs font-semibold ">
                            InvoicedAmount
                          </div>
                          <div class="text-[#7a7a7a] text-xs font-semibold ">
                            {
                              singleDetailPendingRequest?.data?.payload
                                ?.invoicedAmount
                            }
                          </div>
                        </div>
                        <div class="self-stretch justify-between items-center inline-flex">
                          <div class="text-[#222222] text-xs font-semibold ">
                            ProgramName
                          </div>
                          <div class="text-[#7a7a7a] text-xs font-semibold ">
                            {
                              singleDetailPendingRequest?.data?.payload
                                ?.programName
                            }
                          </div>
                        </div>
                      </div>

                      {/* stop */}
                    </div>
                    {/* second stage */}
                    <div className="flex justify-between items-center mt-10 ">
                      {" "}
                      <div class="h-[37px] p-2.5 rounded-[5px] border border-[#f08000]/30 justify-center items-center gap-2.5 inline-flex">
                        <div class="text-[#f08000] text-sm font-medium ">
                          {singleDetailPendingRequest?.data?.payload?.status}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* stops here */}
                  <div className="  px-12 mt-36">
                    <header className="border-b-[5px] border-b-[#F6F5FA] mt-3  flex justify-between py-1">
                      {/* <h1 className="text-[18px] font-semibold">
                  Supplier/buyer details
                </h1> */}
                      <div>
                        <div class="text-[#222222] text-lg font-semibold w-2 ">
                          {
                            singleDetailPendingRequest?.data?.payload
                              ?.supplierName
                          }
                        </div>
                        <div class="text-[#7a7a7a] text-xs font-light  ">
                          supplier Details
                        </div>
                      </div>

                      <div class="h-[41px] flex-col justify-start items-start  inline-flex px-12">
                        <div class="text-[#222222] text-base font-semibold ">
                          {
                            singleDetailPendingRequest?.data?.payload
                              ?.paymentDuration
                          }{" "}
                          Days
                        </div>
                        <div class="w-[100px] text-[#7a7a7a] text-xs font-light  mt-1">
                          Payment
                        </div>
                      </div>

                      <div class="h-[43px] flex-col justify-start items-end  inline-flex">
                        <div class="text-[#222222] text-lg font-semibold ">
                          {singleDetailPendingRequest?.data?.payload?.currency}
                        </div>
                        <div class="text-[#7a7a7a] text-xs font-semibold mt-1">
                          Currency
                        </div>
                      </div>
                    </header>

                    <div className=" w-full overflow-x-auto mt-3 pb-4"></div>
                    {/* Calculate cost and price  shaded or gray section */}
                  </div>
                </section>
              </TransitionScale>
            </div>
          </>
        </div>
      </DashboardLayout>
    </>
  );
};

export default SupplierEarlyPaymentRequest;
