import {
  // PAIconArrowRight,
  // PAIconCardSend,
  PAIconIconBoxClipBoard,
  PAIconColapse,
  PAIconIconTopArrowWhite,
  PAIconIconCollapseYello,
} from "assets/images/svgs";
import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "shared/Button";

// import { useSelector } from "react-redux";
// PAIconColapse;

const FundingOverview = ({ supplierDashboard }) => {
  // const {  supplierDashboard } = useSelector((state) => state.supplier);

  // const cards = [
  //   {
  //     count: 10,
  //     text: "Early Payment Offer Invoices",
  //     color: "text-[#065f46]",
  //   },
  //   {
  //     count: 8,
  //     text: "Total Pending Early Payment Requests",
  //     color: "text-[#065f46]",
  //   },
  // ];
  const navigate = useNavigate();

  return (
    <div className=" h-full  flex flex-col justify-between  gap-2">
      <div className="bg-primaryColor rounded-[5px]  lg:mt-0 2xl:w-[400px]  px-9 py-2">
        {" "}
        <div className="flex flex-wrap gap-1  w-full justify-center">
          {/* {cards.map((card, index) => ( */}

          <div
            // key={index}
            className="w-full    bg-[#ffffff] rounded shadow-md border  flex  flex-col py-1 mx-2 "
          >
            <div className="items-center  flex  w-full  px-2 ">
              <div className="flex gap-3  ml-9 pt-1">
                <div className={`   text-primaryColor text-2xl font-semibold `}>
                  {supplierDashboard?.data?.stats?.pendingDirectFundingRequest}
                </div>
                <div className=" text-primaryColor   w-32  justify-start  text-xs font-light">
                  {"Early Payment Offer Invoices"}
                </div>
              </div>
            </div>

            <span className=" w-full justify-end items-end flex  px-2  py-0 my-0">
              <PAIconIconBoxClipBoard />
            </span>
          </div>
          <div
            // key={index}
            className="w-full    bg-[#ffffff] rounded shadow-md border  flex  flex-col py-1 mx-2 "
            onClick={() => navigate("/supplier-early-payments")}
          >
            <div className="items-center  flex  w-full  px-2 ">
              <div className="flex gap-3  ml-9 pt-1">
                <div className={`text-primaryColor text-2xl font-semibold `}>
                  {supplierDashboard?.data?.stats?.pendingEarlyPaymentRequest}
                </div>
                <div className=" text-primaryColor   w-32  justify-start  text-xs font-light">
                  {"Total Pending Early Payment Requests"}
                </div>
              </div>
            </div>

            <span className=" w-full justify-end items-end flex  px-2  py-0 my-0">
              <PAIconIconBoxClipBoard />
            </span>
          </div>

          {/* ))} */}
        </div>{" "}
      </div>
      <div className="bg-white py-[5px]   rounded-b-[10px] border-t-[2px] border-primaryColor ">
        <div className="flex justify-between px-[20px] items-center">
          <h1 className="text-primaryColor text-[20px] font-normal">
            Collections
          </h1>
          <Button
            neutral
            className="!text-white bg-secondaryColor px-[10px] py-[5px] rounded-[5px] flex gap-x-[10px] items-center"
          >
            {/* <p className="text-base font-normal">Open market</p> */}
            <PAIconIconTopArrowWhite className="w-[12px]" />
          </Button>
        </div>
        <div className="mt-4 flex justify-between px-[20px] items-center gap-3 pb-2">
          <div className=" border-r-[1px] px-3 w-1/2">
            <div className="text-primaryColor text-center  min-w-14 justify-start text-xl font-bold">
              {supplierDashboard?.data?.funds?.totalCollections || "NA"}
            </div>
            <div class=" text-[#959595] font-light min-w-20 text-center justify-start text-neutral-400 text-xs  ">
              Total Collections
            </div>
          </div>

          <div>
            {/* <h1 className="text-lightGreen text-center text-[40px] font-bold">
              { supplierDashboard?.data?.totalSettledInvoices || "2"}
            </h1> */}

            <h1 className="text-primaryColor text-center text-[30px]    justify-start text-emerald-800 text-xl font-bold flex flex-wrap">
              {supplierDashboard?.data?.funds?.totalAmount || "NA"}
            </h1>
            <div class=" text-[#959595] font-light min-w-20 text-center justify-start text-neutral-400 text-xs  ">
              Total Amount
            </div>
          </div>
        </div>
      </div>
      <div className="rounded-r-[10px]    flex gap-4">
        <div
          className="bg-white rounded-b-[10px] border-t-[2px] border-primaryColor pb-2 "
          onClick={() => navigate("/supplier-early-payments")}
        >
          <div className="mt-4 flex justify-end mr-[14.38px]">
            <PAIconColapse />
          </div>
          <div className="">
            <div class=" text-center text-primaryColor text-3xl font-bold ">
              {supplierDashboard?.data?.stats?.approvedDirectFundingRequest ||
                "Nil"}
            </div>
            {/* <h1 className="text-center text-5xl font-bold mb-[8px] text-[#FFFFFF]">
              { supplierDashboard?.data?.totalInvoices || "78"}
            </h1> */}
            <p className="text-gray800 text-sm font-normal px-[20px]  text-center w-full">
              Approved Fund Requests
            </p>
          </div>
        </div>

        <div
          className="bg-white rounded-b-[10px] border-t-[2px] border-secondaryColor  pb-2"
          onClick={() => navigate("/supplier-early-payments")}
        >
          <div className=" flex justify-end mr-[14.38px]">
            <PAIconIconCollapseYello />
          </div>
          <div className="">
            <div class="min-w-7 text-center text-[#F08000] text-3xl font-bold ">
              {supplierDashboard?.data?.stats?.pendingDirectFundingRequest ||
                "Nil"}
            </div>
            {/* <h1 className="text-center text-5xl font-bold mb-[8px] text-[#FFFFFF]">
              { supplierDashboard?.data?.totalInvoices || "78"}
            </h1> */}
            <p className="text-gray800 text-sm font-normal px-[30px]  text-center w-full">
              Pending Fund Requests
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FundingOverview;
