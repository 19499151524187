import {
  PAIconAddGreen,
  PAIconFilledArrowLeft,
  PAIconTrashMini,
} from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { AnimatePresence, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import AssignProcessModal from "./components/AssignProcessModal";
import { useDispatch, useSelector } from "react-redux";
import { allMembers, allRoles } from "appstate/roleManager/roleManagerSlice";
import toast from "react-hot-toast";
import ViewDocumentModal from "features/RulesConfig/common/components/modals/DeleteRuleModal";
import { createWorkflow } from "appstate/workflow/workflowSlice";

const AddWorkflow = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userId } = useSelector((state) => state?.auth);
  const roleIndex = userId?.realm_access?.roles?.findIndex((arr) => {
    return (
      arr === "SUPPLIER" ||
      arr === "BUYER" ||
      arr === "FUNDER" ||
      arr === "ADMIN"
    );
  });
  const userRole = userId?.realm_access?.roles[roleIndex]?.toLowerCase();

  const [processName, setProcessName] = useState("");
  const [processDescription, setProcessDescription] = useState("");
  const [assignedProcesses, setAssignedProcesses] = useState([]);
  const [approved, setApproved] = useState(false);
  const [assignedModal, setAssignedModal] = useState(false);
  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const [approvals, setApprovals] = useState([
    { teamMember: "", role: "", finalApprover: false },
  ]);

  const { allRolesData, allMembersData } = useSelector(
    (state) => state?.roleManager
  );

  const [userSearch, setUserSearch] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false); // Track dropdown visibility
  // Filter users based on search input
  const filteredUsers = allMembersData?.data?.staffOnboardResponse?.filter(
    (user) => user?.email.toLowerCase().includes(userSearch.toLowerCase())
  );

  const handleSelectUser = (userEmail, index) => {
    const newApprovals = [...approvals];
    newApprovals[index].teamMember = userEmail;
    setApprovals(newApprovals);
    setUserSearch(userEmail); // Show the selected user in the input
    setDropdownOpen(false); // Close the dropdown after selection
  };

  useEffect(() => {
    dispatch(allRoles());
    dispatch(allMembers(userRole));
  }, [dispatch, userRole]);

  const addApproval = () => {
    const newApprovals = [
      ...approvals,
      { teamMember: "", role: "", finalApprover: false },
    ];
    setApprovals(newApprovals);
  };

  const removeApproval = (index) => {
    const newApprovals = [...approvals];
    newApprovals.splice(index, 1);
    setApprovals(newApprovals);
  };

  const handleFinalApproverChange = (index, value) => {
    const newApprovals = [...approvals];
    newApprovals[index].finalApprover = value;
    setApprovals(newApprovals);
  };

  const handleCreateWorkflow = () => {
    if (processName === "" || processDescription === "") {
      toast.error("Kindly fill all required fields");
      return;
    }

    const body = {
      processDTOList: assignedProcesses || [],
      workFlowLevelDTOList: approvals || [],
      status: true,
      description: processDescription || "",
      name: processName || "",
    };

    dispatch(createWorkflow(body)).then((data) => {
      if (data?.payload?.success) {
        setApprovals([{ teamMember: "", role: "", finalApprover: false }]);
        setProcessName("");
        setProcessDescription("");
        setAssignedProcesses([]);
        toast.success("Workflow created successfully");

        setTimeout(() => {
          navigate("/settings");
        }, 1200);
      }
    });
  };

  return (
    <DashboardLayout
      section="Settings"
      subHeader="Manage your account all in one place"
    >
      <div className="bg-white flex flex-col gap-8 min-h-full py-8 px-6 rounded-[10px]">
        <header>
          <div
            onClick={() => navigate(-1)}
            className=" w-fit text-[#7A7A7A] text-sm cursor-pointer flex gap-1 items-center "
          >
            <span>
              <PAIconFilledArrowLeft />
            </span>
            back
          </div>
        </header>
        <main className=" w-full">
          <section className=" w-full">
            <div className=" w-full font-bold text-[15px] pb-5 border-b border-[#F0F0F0]">
              Add new workflow
            </div>
            <div className="py-5 flex flex-col gap-5">
              <div className="flex text-sm flex-col gap-2">
                <span className="text-[#7A7A7A]">Name</span>
                <span className=" w-full">
                  <input
                    onChange={(e) => setProcessName(e.target.value)}
                    value={processName}
                    type="text"
                    required
                    className=" text-black rounded-[5px] outline-none border border-[#F0F0F0] w-full py-3 px-5"
                    placeholder="Enter workflow name"
                  />
                </span>
              </div>

              <div className="flex text-sm flex-col gap-2">
                <span className="text-[#7A7A7A]">Description</span>
                <span className=" w-full">
                  <textarea
                    className=" text-black rounded-[5px] outline-none border border-[#F0F0F0] w-full py-3 px-5"
                    placeholder="Enter the description for the workflow"
                    name="description"
                    id="desc"
                    cols="10"
                    rows="5"
                    required
                    value={processDescription}
                    onChange={(e) => setProcessDescription(e.target.value)}
                  ></textarea>
                </span>
              </div>

              <div>
                <motion.button
                  onClick={() => setAssignedModal(true)}
                  whileTap={{ scale: 0.95 }}
                  className="rounded-[100px] border px-4 py-2  my-2  text-primaryColor cursor-pointer  border-primaryColor text-sm "
                >
                  Assign to a process
                </motion.button>
              </div>

              {/* Here it is */}
              <div className="text-sm flex flex-col gap-3">
                <div className="flex justify-between items-center">
                  <h1 className=" font-bold ">Levels of Approval</h1>
                  <span className={`text-xs ${approved ? "hidden" : ""}`}>
                    Click on the checkbox if that would be your final approval
                  </span>
                </div>

                {approvals?.length > 0 &&
                  approvals?.map((approval, index) => (
                    <div key={index} className="w-full flex justify-between">
                      <div className="flex flex-col gap-2 w-[45%]">
                        <span className="text-sm text-[#7A7A7A] w-fit">
                          Role
                        </span>
                        <select
                          style={{ background: "rgba(246, 246, 246, 0.96)" }}
                          name="role"
                          className="cursor-pointer outline-none rounded-[5px] px-5 py-3"
                          value={approval.role}
                          onChange={(e) => {
                            const newApprovals = [...approvals];
                            newApprovals[index].role = e.target.value;
                            // newApprovals[index].teamMember = "";
                            setApprovals(newApprovals);
                          }}
                        >
                          <option className="py-3" value="">
                            Select Role
                          </option>
                          {allRolesData?.data?.length > 0 &&
                            allRolesData.data?.map((role) => (
                              <option
                                className="py-4"
                                key={role?.id}
                                value={role?.id}
                              >
                                {role?.roleName}
                              </option>
                            ))}
                        </select>
                      </div>

                      <div className="flex justify-center flex-col gap-2 ">
                        <span className=" text-white">car</span>
                        <span className="text-sm flex items-center w-fit">
                          or
                        </span>
                      </div>

                      <div className="flex flex-col gap-2 w-[45%]">
                        <span className="text-sm text-[#7A7A7A] w-fit">
                          User
                        </span>

                        {/* Searchable Dropdown */}
                        <div className="relative">
                          <input
                            type="text"
                            value={userSearch}
                            onFocus={() => setDropdownOpen(true)}
                            onChange={(e) => {
                              setUserSearch(e.target.value);
                              setDropdownOpen(true); // Open dropdown on typing
                            }}
                            placeholder="Search User"
                            className="cursor-pointer outline-none rounded-[5px] px-5 py-3 bg-[rgba(246,246,246,0.96)] w-full"
                          />
                          {/* Dropdown for users */}
                          {dropdownOpen && filteredUsers.length > 0 && (
                            <ul
                              className="absolute top-12 left-0 w-full cursor-pointer outline-none rounded-[5px] bg-white shadow-lg max-h-48 overflow-y-auto z-10"
                              onMouseLeave={() => setDropdownOpen(false)} // Close dropdown on mouse leave
                            >
                              {filteredUsers.map((user) => (
                                <li
                                  key={user.id}
                                  className="px-5 py-3 hover:bg-gray-100"
                                  onClick={() =>
                                    handleSelectUser(user.email, index)
                                  }
                                >
                                  {user.email}
                                </li>
                              ))}
                            </ul>
                          )}
                          {dropdownOpen && filteredUsers.length === 0 && (
                            <ul className="absolute top-12 left-0 w-full cursor-pointer outline-none rounded-[5px] bg-white shadow-lg z-10">
                              <li className="px-5 py-3 text-gray-500">
                                No users found
                              </li>
                            </ul>
                          )}
                        </div>
                      </div>

                      <div className="flex flex-col justify-end">
                        <div className=" text-sm text-white">ee</div>
                        <div className="flex">
                          <button
                            className="text-sm flex items-center px-5 py-3  text-red-600"
                            onClick={() => removeApproval(index)}
                          >
                            <PAIconTrashMini />
                          </button>
                          <label
                            className="flex gap-2.5 items-center "
                            htmlFor=""
                          >
                            <input
                              className=" cursor-pointer"
                              type="checkbox"
                              name="final-approver"
                              checked={approval?.finalApprover}
                              onChange={(e) =>
                                handleFinalApproverChange(
                                  index,
                                  e.target.checked
                                )
                              }
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  ))}

                {approvals?.length < 1 && (
                  <div className="flex shadow-sm font-bold justify-center p-5">
                    No level selected, kindly add a level below!
                  </div>
                )}

                <label className="flex gap-2.5 items-center " htmlFor="">
                  <input
                    className=" cursor-pointer"
                    type="checkbox"
                    name=""
                    value={approved}
                    onChange={() => setApproved(!approved)}
                    id=""
                  />
                  Yes, this is the final approval
                </label>

                <div className="flex justify-center">
                  <button
                    onClick={addApproval}
                    className="rounded-[100px] border px-4 py-2  my-2 flex gap-2 items-center  text-primaryColor cursor-pointer  border-primaryColor text-sm "
                  >
                    <PAIconAddGreen />
                    Add new level
                  </button>
                </div>
              </div>

              <div className=" flex justify-end">
                <div className=" w-fit flex gap-5">
                  <motion.button
                    onClick={() => navigate(-1)}
                    whileTap={{ scale: 0.9 }}
                    className=" text-sm rounded-[5px] shadow-sm bg-[white] border-[0.1px] border-[#7A7A7A] text-[#7A7A7A] py-3 px-6"
                  >
                    Cancel
                  </motion.button>
                  <motion.button
                    disabled={!approved}
                    onClick={() => setSaveModalOpen(true)}
                    whileTap={{ scale: 0.9 }}
                    className={`flex gap-1 items-center justify-center  text-sm rounded-[5px] text-[white]  ${
                      approved ? "bg-primaryColor" : "bg-slate-300"
                    } py-3 px-6`}
                  >
                    Save
                  </motion.button>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>

      <AssignProcessModal
        setAssignedProcesses={setAssignedProcesses}
        isOpen={assignedModal}
        setIsOpen={setAssignedModal}
      />

      <AnimatePresence>
        {saveModalOpen && (
          <ViewDocumentModal
            onAction={() => {
              handleCreateWorkflow();
            }}
            onClose={() => {
              setSaveModalOpen(false);
            }}
          >
            <span className="text-[#7A7A7A]">
              Are you sure you would like to create this workflow?
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>
    </DashboardLayout>
  );
};

export default AddWorkflow;
