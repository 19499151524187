import { PAIconPencilLine } from "../../../assets/images/svgs";
// import ProfilePic from "./ProfilePic";
import InputField from "shared/InputField";
import { useFormik } from "formik";
import { updateProfileSchema } from "validation/settings/UpdateProfileSchema";
// import { funderUpdateProfileSchema } from "validation/settings/FunderUpdateProfileSchema";
import PhoneNumber from "shared/PhoneNumber";
import { useState, useRef, useEffect } from "react";
import Button from "shared/Button";
import { useDispatch, useSelector } from "react-redux";
// import { updatePlayerProfile } from "appstate/settings/settingsSlice";
// import { getCurrentUser } from "appstate/auth/authSlice";
import Spinner from "shared/Spinner/Spinner";
import { toast } from "react-hot-toast";
import { editProfileBuyer, fetchBuyerDetails } from "appstate/buyer/buyerSlice";
import { editSupplierProfile, fetchSupplierDetails } from "appstate/supplier/supplierSlice";
import { editFunderProfile, fecthFunderDetails } from "appstate/funder/funderSlice";
import {
  PAIconChangeImage,
  PAIconSettingsProfile,
} from "../../../assets/images/svgs";

const Account = ({ currentUser }) => {
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.settings);

  const [selectedFileName, setSelectedFileName] = useState(null);
  const fileInputRef = useRef(null);
  // console.log("Wale", selectedFileName);

  const [fileUrl, setFileUrl] = useState(null);
  const MAX_FILE_SIZE = 1 * 1024 * 1024; // 1MB in bytes

  const handleTextClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const selected = e.target.files[0];
    // console.log("====selecetd====", selected);

    if (selected && selected.type.startsWith("image/")) {
      if (selected.size > MAX_FILE_SIZE) {
        toast.error("File size exceeds 1MB. Please upload a smaller file.");
        return;
      }
      setSelectedFileName(selected);
      setFileUrl(URL.createObjectURL(selected));
      // console.log("");
    } else {
      toast.error("Please select a valid image file.");
    }
  };

  
  const { userId } = useSelector((state) => state?.auth);
  // const { userDetails } = useSelector(
  //   (state) => state.auth
  // );

  const {funderDetails} = useSelector(
    (state) => state.funder
  );
  const {buyerDetails} = useSelector(
    (state) => state.buyer
  );
  const {supplierDetails} = useSelector(
    (state) => state.supplier
  );
  const roleIndex = userId?.realm_access?.roles?.findIndex((arr) => {
    return (
      arr === "SUPPLIER" ||
      arr === "BUYER" ||
      arr === "FUNDER" ||
      arr === "ADMIN"
    );
  });
  const userRole = userId?.realm_access?.roles[roleIndex]?.toLowerCase();


  const userDetailsFunc={ buyer: fetchBuyerDetails,
    supplier: fetchSupplierDetails,
    funder: fecthFunderDetails}



    const fetchUser = userDetailsFunc[userRole];
useEffect(()=>{ dispatch(fetchUser())},[dispatch,fetchUser])
  // console.log("====userid====", userDetails?.data)


  const personalDetails={ buyer: buyerDetails,
    supplier: supplierDetails,
    funder: funderDetails}


    const usersData = personalDetails[userRole];

  
  const formik = useFormik({
    initialValues: {
      fullName: funderDetails?.data?.fullName || "",
      email: funderDetails?.data?.email || "",
      phoneNumber: funderDetails?.data?.phoneNumber || "",
      password: funderDetails?.data?.password || "",
      // fullName: "",
      // email: "",
      // phoneNumber: "",
      // password: "",
    },
    validationSchema: updateProfileSchema,

    onSubmit: (values) => {
      const image = selectedFileName;

      if (!image) {
        return toast.error("Kindly select a profile image to proceed!");
      }

      const formData = new FormData();

      formData.append("profilePhoto", image);
      formData.append("fullName", values.fullName);
      formData.append("phoneNumber", values.phoneNumber);
    

      const editProfileDetailsActions = {
        buyer: editProfileBuyer,
        supplier: editSupplierProfile,
        funder: editFunderProfile,
      };
     
       
      const editProfileDetailsRequests = {
        buyer: formData,
        supplier: formData,
        // supplier: otherRequestBody,
        funder: formData,
      };

      const action = editProfileDetailsActions[userRole];
      const bodyRequest = editProfileDetailsRequests[userRole];
      
      

      // console.log("====Other Request====", bodyRequest);
      if (action) {
        dispatch(action({ body: bodyRequest })).then((data) => {
          if (data?.payload?.success) {
            dispatch(fetchUser()) 
            toast.success("Profile edited successfully!");
          } else {
            return toast.error(data?.payload?.message);
            
          }
        });
      }

      
    },
  });

  return (
    <div className="flex flex-col py-[30px] px-[25px] space-y-5">
      <div className=" flex  justify-start items-center ">
        <div onClick={handleTextClick}>
          {" "}
          {selectedFileName?.name ? (
            <>





<>
              {" "}
              {/* <ProfilePic currentUser={currentUser} /> */}

              <div className="flex flex-wrap align-middle gap-[20px] pb-[10px]">
              <div className="relative inline-block  mr-6">
              <img
                  src={fileUrl}
                  alt="Profile"
                  className="w-16 h-16 rounded-full object-cover"
                />
              </div>

              <div className="flex flex-col ml-3 justify-start gap-[3px]">
        <p className="font-bold text-md">
        
          {currentUser?.role?.toLowerCase() === "open_funder"
            ? `${usersData?.data?.firstName} ${usersData?.data?.lastName}`
            : usersData?.data?.fullName}
        </p>
        <p className="text-sm capitalize">
          {usersData?.data?.role?.toLowerCase() === "open_funder" ||
          usersData?.data?.role?.toLowerCase() === "corporate_funder"
            ? `${usersData?.data?.role?.toLowerCase().split("_")[0]} ${
              usersData?.data?.role?.toLowerCase().split("_")[1]
              }`
            : usersData?.data?.role.toLowerCase()}
        </p>
        <p className="text-sm">{ usersData?.data?.email}</p>
      </div>
              </div>
            </>
            
            </>
          ) : (
            <>
              {" "}
              {/* <ProfilePic currentUser={currentUser} /> */}

              <div className="flex flex-wrap align-middle gap-[20px] pb-[10px]">
              <div className="relative inline-block  mr-6">
                <PAIconSettingsProfile className="inline-block h-16 w-16 rounded-full ring-2 ring-white dark:ring-gray-800" />
                <PAIconChangeImage className="absolute bottom-0 -right-1 block h-6 w-6 rounded-full cursor-pointer" />
              </div>

              <div className="flex flex-col ml-3 justify-start gap-[3px]">
        <p className="font-bold text-md">
        
          {currentUser?.role?.toLowerCase() === "open_funder"
            ? `${usersData?.data?.firstName} ${usersData?.data?.lastName}`
            : usersData?.data?.fullName}
        </p>
        <p className="text-sm capitalize">
          {usersData?.data?.role?.toLowerCase() === "open_funder" ||
          usersData?.data?.role?.toLowerCase() === "corporate_funder"
            ? `${usersData?.data?.role?.toLowerCase().split("_")[0]} ${
              usersData?.data?.role?.toLowerCase().split("_")[1]
              }`
            : usersData?.data?.role.toLowerCase()}
        </p>
        <p className="text-sm">{ usersData?.data?.email}</p>
      </div>
              </div>
            </>
          )}
        </div>

        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
        <div class="h-[67px] flex-col justify-start items-start gap-2 inline-flex">
          <div class="text-[#222222] text-sm font-bold ">
            {currentUser?.fullName}
          </div>
          <div class="text-[#7a7a7a] text-sm font-normal ">
            {" "}
            {currentUser?.role}
          </div>
          <div class="text-[#7a7a7a] text-sm font-normal ">
            {" "}
            {currentUser?.email}
          </div>
        </div>
      </div>

      <div className="bg-gallery h-[1px] pb-1 w-full"></div>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-col">
          <div className="mb-5">
            <p className="font-bold">Personal Details</p>
          </div>
          <div className="flex justify-between flex-wrap gap-6">
            <div className="md:w-[350px] w-full flex flex-col gap-6 ">
              {currentUser?.role === "OPEN_FUNDER" ? (
                <div className="flex flex-col gap-6">
                  <div className="w-full">
                    <InputField
                      disabled={!isEditing}
                      className="h-[45px] !border-none bg-wildSand rounded-md p-[13px] outline-none"
                      type="text"
                      placeholder="Agba"
                      label="First Name"
                      name="firstName"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.firstName}
                    />
                    {formik.touched.firstName && formik.errors.firstName ? (
                      <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                        {formik.errors.firstName}
                      </p>
                    ) : null}
                  </div>
                  <div className="w-full">
                    <InputField
                      disabled={!isEditing}
                      className="h-[45px] !border-none bg-wildSand rounded-md p-[13px] outline-none"
                      type="text"
                      placeholder="Dev"
                      label="Last Name"
                      name="lastName"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.lastName}
                    />
                    {formik.touched.lastName && formik.errors.lastName ? (
                      <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                        {formik.errors.lastName}
                      </p>
                    ) : null}
                  </div>
                </div>
              ) : (
                <div className="w-full">
                  <InputField
                    disabled={!isEditing}
                    className="h-[45px] !border-none bg-wildSand rounded-md p-[13px] outline-none"
                    type="text"
                    placeholder="Agba Dev"
                    label="Full Name"
                    name="fullName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.fullName}
                  />
                  {formik.touched.fullName && formik.errors.fullName ? (
                    <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                      {formik.errors.fullName}
                    </p>
                  ) : null}
                </div>
              )}

              <div className="w-full md:w-[350px]">
                <InputField
                  disabled={!isEditing}
                  className="h-[45px] !border-none bg-wildSand rounded-md p-[13px] outline-none"
                  placeholder="exampleforyou@info.com"
                  label="Email"
                  id="email"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                    {formik.errors.email}
                  </p>
                ) : null}
              </div>
              {/* <div className="w-full"></div> */}
            </div>

            <div className="md:w-[350px] w-full  ">
              {" "}
              <div className="w-full">
                <PhoneNumber
                  disabled={!isEditing}
                  id="phoneNumber"
                  name="phoneNumber"
                  className="!w-full h-[45px] !border-none bg-wildSand rounded-md p-[13px] outline-none"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phoneNumber}
                />
                {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                  <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                    {formik.errors.phoneNumber}
                  </p>
                ) : null}
              </div>
          
            </div>
          </div>
        </div>
        <div className="py-6">
          {isEditing ? (
            <div className="flex gap-2 justify-start">
              <Button
                type="button"
                onClick={() => setIsEditing(!isEditing)}
                className="flex !text-[14px] !px-6 !py-3 items-center bg-wildSand border-none !rounded-[10px] h-[40px]"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="flex !text-[14px] !px-6 !py-3 items-center !bg-emerald border-none !rounded-[10px] h-[40px] hover:bg-green-400"
              >
                {isLoading ? <Spinner /> : "Save"}
              </Button>
            </div>
          ) : (
            <p
              onClick={() => setIsEditing(!isEditing)}
              className="cursor-pointer inline-flex items-center px-4 py-1 gap-[2px] hover:bg-blue-50 text-gray-800 text-sm font-medium rounded-full border border-[#213874] -mt-3"
            >
              <PAIconPencilLine />
              Edit
            </p>
          )}
        </div>
      </form>
    </div>
  );
};

export default Account;
