import BuyerTabs from "features/BuyerEarlyPayment/Tabs";
// import AllPayableFinance from "features/payableFinancing";
import DashboardLayout from "layout/dashboardlayout";
import React from "react";

const PayableFinancingDashboard = () => {
  return (
    <DashboardLayout
      section="Early Payment Request"
      subHeader="Here is an overview of your early payment request invoices"
    >
      {/* <AllPayableFinance /> */}
      <BuyerTabs />
    </DashboardLayout>
  );
};

export default PayableFinancingDashboard;
