import { allMembers, allRoles } from "appstate/roleManager/roleManagerSlice";
import {
  getUsersByRole,
  getworkflowByModule,
  updateWorkflowStatus,
} from "appstate/workflow/workflowSlice";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import WorkflowActionEdit from "../../../common/WorkflowActionEdit";

function EditWorkflowEarlyOffersFunder() {
  const { player, actionType } = useParams();
  const dispatch = useDispatch();

  const { getWorkflowByModuleData } = useSelector((state) => state?.workflow);

  // User By Rolename
  const [
    selectedRoleNameApprovalViaCreate,
    setSelectedRoleNameApprovalViaCreate,
  ] = useState("");

  const [selectedRoleNameViaDelete, setSelectedRoleNameViaDelete] =
    useState("");

  const [selectedRoleNameViaEdit, setSelectedRoleNameViaEdit] = useState("");

  const [selectedRoleNameViaSubmit, setSelectedRoleNameViaSubmit] =
    useState("");

  const [selectedRoleNameViaUpload, setSelectedRoleNameViaUpload] =
    useState("");

  // Mapping Arrays
  const [approvalsViaCreate, setApprovalsViaCreate] = useState([]);
  const [approvalsViaDelete, setApprovalsViaDelete] = useState([]);
  const [approvalsViaEdit, setApprovalsViaEdit] = useState([]);
  const [approvalsViaSubmit, setApprovalsViaSubmit] = useState([]);
  const [approvalsViaUpload, setApprovalsViaUpload] = useState([]);

  // Toggle Status
  const [approvalViaCreateStatus, setApprovalViaCreateStatus] = useState(false);
  const [approvalViaDeleteStatus, setApprovalViaDeleteStatus] = useState(false);
  const [approvalViaEditStatus, setApprovalViaEditStatus] = useState(false);
  const [approvalViaSubmitStatus, setApprovalViaSubmitStatus] = useState(false);
  const [approvalViaUploadStatus, setApprovalViaUploadStatus] = useState(false);

  // Dropdown Refs
  const dropdownRefsApprovalViaCreate = useRef([]);
  const dropdownRefsApprovalViaDelete = useRef([]);
  const dropdownRefsApprovalViaEdit = useRef([]);
  const dropdownRefsApprovalViaSubmit = useRef([]);
  const dropdownRefsApprovalViaUpload = useRef([]);

  useEffect(() => {
    dispatch(getworkflowByModule(actionType.toUpperCase())).then((data) => {
      const processes = data?.payload?.data?.processes || [];

      // 1.
      setApprovalViaCreateStatus(processes[0]?.status || false);
      setApprovalsViaCreate(processes[0]?.approvalLevels || []);

      // 2.
      setApprovalViaDeleteStatus(processes[1]?.status || false);
      setApprovalsViaDelete(processes[1]?.approvalLevels || []);

      // 3.
      setApprovalViaEditStatus(processes[2]?.status || false);
      setApprovalsViaEdit(processes[2]?.approvalLevels || []);

      // 4.
      setApprovalViaSubmitStatus(processes[3]?.status || false);
      setApprovalsViaSubmit(processes[3]?.approvalLevels || []);

      // 5.
      setApprovalViaUploadStatus(processes[4]?.status || false);
      setApprovalsViaUpload(processes[4]?.approvalLevels || []);
    });

    dispatch(allRoles());
    dispatch(allMembers(player));
  }, [dispatch, actionType, player]);

  useEffect(() => {
    if (selectedRoleNameApprovalViaCreate) {
      dispatch(getUsersByRole(selectedRoleNameApprovalViaCreate));
    }
    if (selectedRoleNameViaDelete) {
      dispatch(getUsersByRole(selectedRoleNameViaDelete));
    }
    if (selectedRoleNameViaEdit) {
      dispatch(getUsersByRole(selectedRoleNameViaEdit));
    }
    if (selectedRoleNameViaSubmit) {
      dispatch(getUsersByRole(selectedRoleNameViaSubmit));
    }
    if (selectedRoleNameViaUpload) {
      dispatch(getUsersByRole(selectedRoleNameViaUpload));
    }
  }, [
    dispatch,
    selectedRoleNameApprovalViaCreate,
    selectedRoleNameViaDelete,
    selectedRoleNameViaEdit,
    selectedRoleNameViaSubmit,
    selectedRoleNameViaUpload,
  ]);

  // Handle Dropdown ClickOutside, I have to create for each one
  useEffect(() => {
    const handleClickOutside = (event) => {
      dropdownRefsApprovalViaCreate.current.forEach((ref, index) => {
        if (ref && !ref.contains(event.target)) {
          setApprovalsViaCreate((prev) =>
            prev.map((approval, i) =>
              i === index ? { ...approval, dropdownOpen: false } : approval
            )
          );
        }
      });
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      dropdownRefsApprovalViaDelete.current.forEach((ref, index) => {
        if (ref && !ref.contains(event.target)) {
          setApprovalsViaDelete((prev) =>
            prev.map((approval, i) =>
              i === index ? { ...approval, dropdownOpen: false } : approval
            )
          );
        }
      });
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      dropdownRefsApprovalViaEdit.current.forEach((ref, index) => {
        if (ref && !ref.contains(event.target)) {
          setApprovalsViaEdit((prev) =>
            prev.map((approval, i) =>
              i === index ? { ...approval, dropdownOpen: false } : approval
            )
          );
        }
      });
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      dropdownRefsApprovalViaSubmit.current.forEach((ref, index) => {
        if (ref && !ref.contains(event.target)) {
          setApprovalsViaSubmit((prev) =>
            prev.map((approval, i) =>
              i === index ? { ...approval, dropdownOpen: false } : approval
            )
          );
        }
      });
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      dropdownRefsApprovalViaUpload.current.forEach((ref, index) => {
        if (ref && !ref.contains(event.target)) {
          setApprovalsViaUpload((prev) =>
            prev.map((approval, i) =>
              i === index ? { ...approval, dropdownOpen: false } : approval
            )
          );
        }
      });
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Process Actions for Create

  // 1.
  const addApprovalViaCreate = () => {
    let lastRow = approvalsViaCreate[approvalsViaCreate.length - 1];
    if (
      approvalsViaCreate?.length > 0 &&
      (!lastRow?.userId || !lastRow?.email || !lastRow?.role)
    ) {
      return toast.error("Complete current approval level to add a new one!");
    }
    setApprovalsViaCreate((prev) => [
      ...prev,
      {
        id: Date.now(),
        userId: "",
        teamMember: "",
        role: "",
        email: "",
        finalApproval: false,
        userSearch: "",
        dropdownOpen: false,
      },
    ]);
  };

  const removeApprovalViaCreate = (index) => {
    setApprovalsViaCreate((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSelectUserApprovalViaCreate = (userEmail, index) => {
    setApprovalsViaCreate((prev) =>
      prev.map((approval, i) =>
        i === index
          ? {
              ...approval,
              teamMember: userEmail,
              userSearch: userEmail,
              email: userEmail,
              userId: userEmail,
              dropdownOpen: false,
            }
          : approval
      )
    );
  };

  const handleFinalApprovalChangeApprovalViaCreate = (index, value) => {
    setApprovalsViaCreate((prev) =>
      prev.map((approval, i) =>
        i === index ? { ...approval, finalApproval: value } : approval
      )
    );
  };

  const handleApprovalUpdateViaCreate = () => {
    const approvalLevels = approvalsViaCreate.map(
      ({ email, role, finalApproval }) => ({
        email,
        role,
        finalApproval,
      })
    );

    const body = { status: approvalViaCreateStatus, approvalLevels };
    dispatch(
      updateWorkflowStatus({
        id: getWorkflowByModuleData?.data?.processes[0]?.id,
        body,
      })
    ).then((data) => {
      if (data?.payload?.success) {
        toast.success("Invoice approval updated successfully.");
      }
    });
  };

  // Process Actions for Approval Via Delete

  // 2.
  const addApprovalViaDelete = () => {
    let lastRow = approvalsViaDelete[approvalsViaDelete.length - 1];
    if (
      approvalsViaDelete?.length > 0 &&
      (!lastRow?.userId || !lastRow?.email || !lastRow?.role)
    ) {
      return toast.error("Complete current approval level to add a new one!");
    }
    setApprovalsViaDelete((prev) => [
      ...prev,
      {
        id: Date.now(),
        userId: "",
        teamMember: "",
        role: "",
        email: "",
        finalApproval: false,
        userSearch: "",
        dropdownOpen: false,
      },
    ]);
  };

  const removeApprovalViaDelete = (index) => {
    setApprovalsViaDelete((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSelectUserApprovalViaDelete = (userEmail, index) => {
    setApprovalsViaDelete((prev) =>
      prev.map((approval, i) =>
        i === index
          ? {
              ...approval,
              teamMember: userEmail,
              userSearch: userEmail,
              email: userEmail,
              userId: userEmail,
              dropdownOpen: false,
            }
          : approval
      )
    );
  };

  const handleFinalApprovalChangeApprovalViaDelete = (index, value) => {
    setApprovalsViaDelete((prev) =>
      prev.map((approval, i) =>
        i === index ? { ...approval, finalApproval: value } : approval
      )
    );
  };

  const handleApprovalUpdateViaDelete = () => {
    const approvalLevels = approvalsViaDelete.map(
      ({ email, role, finalApproval }) => ({
        email,
        role,
        finalApproval,
      })
    );

    const body = { status: approvalViaDeleteStatus, approvalLevels };
    dispatch(
      updateWorkflowStatus({
        id: getWorkflowByModuleData?.data?.processes[1]?.id,
        body,
      })
    ).then((data) => {
      if (data?.payload?.success) {
        toast.success("Invoice approval updated successfully.");
      }
    });
  };

  // Process Actions for Edit

  // 3.
  const addApprovalViaEdit = () => {
    let lastRow = approvalsViaEdit[approvalsViaEdit.length - 1];
    if (
      approvalsViaEdit?.length > 0 &&
      (!lastRow?.userId || !lastRow?.email || !lastRow?.role)
    ) {
      return toast.error("Complete current approval level to add a new one!");
    }
    setApprovalsViaEdit((prev) => [
      ...prev,
      {
        id: Date.now(),
        userId: "",
        teamMember: "",
        role: "",
        email: "",
        finalApproval: false,
        userSearch: "",
        dropdownOpen: false,
      },
    ]);
  };

  const removeApprovalViaEdit = (index) => {
    setApprovalsViaEdit((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSelectUserApprovalViaEdit = (userEmail, index) => {
    setApprovalsViaEdit((prev) =>
      prev.map((approval, i) =>
        i === index
          ? {
              ...approval,
              teamMember: userEmail,
              userSearch: userEmail,
              email: userEmail,
              userId: userEmail,
              dropdownOpen: false,
            }
          : approval
      )
    );
  };

  const handleFinalApprovalChangeApprovalViaEdit = (index, value) => {
    setApprovalsViaEdit((prev) =>
      prev.map((approval, i) =>
        i === index ? { ...approval, finalApproval: value } : approval
      )
    );
  };

  const handleApprovalUpdateViaEdit = () => {
    const approvalLevels = approvalsViaEdit.map(
      ({ email, role, finalApproval }) => ({
        email,
        role,
        finalApproval,
      })
    );

    const body = { status: approvalViaEditStatus, approvalLevels };
    dispatch(
      updateWorkflowStatus({
        id: getWorkflowByModuleData?.data?.processes[2]?.id,
        body,
      })
    ).then((data) => {
      if (data?.payload?.success) {
        toast.success("Invoice approval updated successfully.");
      }
    });
  };

  // Process Actions for Submit

  // 4.
  const addApprovalViaSubmit = () => {
    let lastRow = approvalsViaSubmit[approvalsViaSubmit.length - 1];
    if (
      approvalsViaSubmit?.length > 0 &&
      (!lastRow?.userId || !lastRow?.email || !lastRow?.role)
    )
      return toast.error("Complete current approval level to add a new one!");
    setApprovalsViaSubmit((prev) => [
      ...prev,
      {
        id: Date.now(),
        userId: "",
        teamMember: "",
        role: "",
        email: "",
        finalApproval: false,
        userSearch: "",
        dropdownOpen: false,
      },
    ]);
  };

  const removeApprovalViaSubmit = (index) => {
    setApprovalsViaSubmit((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSelectUserApprovalViaSubmit = (userEmail, index) => {
    setApprovalsViaSubmit((prev) =>
      prev.map((approval, i) =>
        i === index
          ? {
              ...approval,
              teamMember: userEmail,
              userSearch: userEmail,
              email: userEmail,
              userId: userEmail,
              dropdownOpen: false,
            }
          : approval
      )
    );
  };

  const handleFinalApprovalChangeApprovalViaSubmit = (index, value) => {
    setApprovalsViaSubmit((prev) =>
      prev.map((approval, i) =>
        i === index ? { ...approval, finalApproval: value } : approval
      )
    );
  };

  const handleApprovalUpdateViaSubmit = () => {
    const approvalLevels = approvalsViaSubmit.map(
      ({ email, role, finalApproval }) => ({
        email,
        role,
        finalApproval,
      })
    );

    const body = { status: approvalViaSubmitStatus, approvalLevels };
    dispatch(
      updateWorkflowStatus({
        id: getWorkflowByModuleData?.data?.processes[3]?.id,
        body,
      })
    ).then((data) => {
      if (data?.payload?.success) {
        toast.success("Invoice approval updated successfully.");
      }
    });
  };

  // Process Actions for Upload

  // 5.
  const addApprovalViaUpload = () => {
    let lastRow = approvalsViaUpload[approvalsViaUpload.length - 1];
    if (
      approvalsViaUpload?.length > 0 &&
      (!lastRow?.userId || !lastRow?.email || !lastRow?.role)
    ) {
      return toast.error("Complete current approval level to add a new one!");
    }
    setApprovalsViaUpload((prev) => [
      ...prev,
      {
        id: Date.now(),
        userId: "",
        teamMember: "",
        role: "",
        email: "",
        finalApproval: false,
        userSearch: "",
        dropdownOpen: false,
      },
    ]);
  };

  const removeApprovalViaUpload = (index) => {
    setApprovalsViaUpload((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSelectUserApprovalViaUpload = (userEmail, index) => {
    setApprovalsViaUpload((prev) =>
      prev.map((approval, i) =>
        i === index
          ? {
              ...approval,
              teamMember: userEmail,
              userSearch: userEmail,
              email: userEmail,
              userId: userEmail,
              dropdownOpen: false,
            }
          : approval
      )
    );
  };

  const handleFinalApprovalChangeApprovalViaUpload = (index, value) => {
    setApprovalsViaUpload((prev) =>
      prev.map((approval, i) =>
        i === index ? { ...approval, finalApproval: value } : approval
      )
    );
  };

  const handleApprovalUpdateViaUpload = () => {
    const approvalLevels = approvalsViaUpload.map(
      ({ email, role, finalApproval }) => ({
        email,
        role,
        finalApproval,
      })
    );

    const body = { status: approvalViaUploadStatus, approvalLevels };
    dispatch(
      updateWorkflowStatus({
        id: getWorkflowByModuleData?.data?.processes[4]?.id,
        body,
      })
    ).then((data) => {
      if (data?.payload?.success) {
        toast.success("Invoice approval updated successfully.");
      }
    });
  };

  const actionEntities = [
    {
      isToggledStatus: approvalViaCreateStatus,
      setIsToggleStatus: setApprovalViaCreateStatus,
      mappingArr: approvalsViaCreate,
      setMappingArr: setApprovalsViaCreate,
      setSelectedRoleName: setSelectedRoleNameApprovalViaCreate,
      dropdownRefs: dropdownRefsApprovalViaCreate,
      handleFinalApprovalChange: handleFinalApprovalChangeApprovalViaCreate,
      addApproval: addApprovalViaCreate,
      removeApproval: removeApprovalViaCreate,
      handleApprovalSubmit: handleApprovalUpdateViaCreate,
      handleSelectUser: handleSelectUserApprovalViaCreate,
    },

    {
      isToggledStatus: approvalViaDeleteStatus,
      setIsToggleStatus: setApprovalViaDeleteStatus,
      mappingArr: approvalsViaDelete,
      setMappingArr: setApprovalsViaDelete,
      setSelectedRoleName: setSelectedRoleNameViaDelete,
      dropdownRefs: dropdownRefsApprovalViaDelete,
      handleFinalApprovalChange: handleFinalApprovalChangeApprovalViaDelete,
      addApproval: addApprovalViaDelete,
      removeApproval: removeApprovalViaDelete,
      handleApprovalSubmit: handleApprovalUpdateViaDelete,
      handleSelectUser: handleSelectUserApprovalViaDelete,
    },

    {
      isToggledStatus: approvalViaEditStatus,
      setIsToggleStatus: setApprovalViaEditStatus,
      mappingArr: approvalsViaEdit,
      setMappingArr: setApprovalsViaEdit,
      setSelectedRoleName: setSelectedRoleNameViaEdit,
      dropdownRefs: dropdownRefsApprovalViaEdit,
      handleFinalApprovalChange: handleFinalApprovalChangeApprovalViaEdit,
      addApproval: addApprovalViaEdit,
      removeApproval: removeApprovalViaEdit,
      handleApprovalSubmit: handleApprovalUpdateViaEdit,
      handleSelectUser: handleSelectUserApprovalViaEdit,
    },

    {
      isToggledStatus: approvalViaSubmitStatus,
      setIsToggleStatus: setApprovalViaSubmitStatus,
      mappingArr: approvalsViaSubmit,
      setMappingArr: setApprovalsViaSubmit,
      setSelectedRoleName: setSelectedRoleNameViaSubmit,
      dropdownRefs: dropdownRefsApprovalViaSubmit,
      handleFinalApprovalChange: handleFinalApprovalChangeApprovalViaSubmit,
      addApproval: addApprovalViaSubmit,
      removeApproval: removeApprovalViaSubmit,
      handleApprovalSubmit: handleApprovalUpdateViaSubmit,
      handleSelectUser: handleSelectUserApprovalViaSubmit,
    },

    {
      isToggledStatus: approvalViaUploadStatus,
      setIsToggleStatus: setApprovalViaUploadStatus,
      mappingArr: approvalsViaUpload,
      setMappingArr: setApprovalsViaUpload,
      setSelectedRoleName: setSelectedRoleNameViaUpload,
      dropdownRefs: dropdownRefsApprovalViaUpload,
      handleFinalApprovalChange: handleFinalApprovalChangeApprovalViaUpload,
      addApproval: addApprovalViaUpload,
      removeApproval: removeApprovalViaUpload,
      handleApprovalSubmit: handleApprovalUpdateViaUpload,
      handleSelectUser: handleSelectUserApprovalViaUpload,
    },
  ];

  return (
    <main className="w-full flex flex-col ">
      {getWorkflowByModuleData?.data?.processes?.map((process, index) => (
        <WorkflowActionEdit
          title={process?.action}
          isToggledStatus={actionEntities[index]?.isToggledStatus}
          setIsToggleStatus={actionEntities[index]?.setIsToggleStatus}
          mappingArr={actionEntities[index]?.mappingArr}
          setMappingArr={actionEntities[index]?.setMappingArr}
          setSelectedRoleName={actionEntities[index]?.setSelectedRoleName}
          dropdownRefs={actionEntities[index]?.dropdownRefs}
          handleFinalApprovalChange={
            actionEntities[index]?.handleFinalApprovalChange
          }
          addApproval={actionEntities[index]?.addApproval}
          removeApproval={actionEntities[index]?.removeApproval}
          handleApprovalSubmit={actionEntities[index]?.handleApprovalSubmit}
          handleSelectUser={actionEntities[index]?.handleSelectUser}
          player={player}
        />
      ))}
    </main>
  );
}
export default EditWorkflowEarlyOffersFunder;
