import React from "react";
import {
  PAIconSmallClose,
  PAIconAgreementSignIcon,
  PAIconAgreementSendIcon,
} from "assets/images/svgs";
import { motion } from "framer-motion";
import InputField from "shared/InputField";
// import toast from "react-hot-toast";

const AgreementRejectedModal = ({ onClose, onContinue }) => {
  const handleCloseDeleteModal = () => {
    onClose();
  };

  return (
    <motion.div>
      <motion.div className="bg-slate-600/20 fixed inset-0 flex items-center justify-center z-50">
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.2,
            },
          }}
          exit={{
            opacity: 0,
            transition: {
              delay: 0.1,
            },
          }}
          className="modal-overlay"
        ></motion.div>

        <motion.div
          initial={{
            scale: 0,
          }}
          animate={{
            scale: 1,
            transition: {
              duration: 0.2,
            },
          }}
          exit={{
            scale: 0,
            transition: {
              delay: 0.1,
            },
          }}
          className="px-6 py-11 bg-white rounded-[10px] w-[60%] md:w-1/2 extra-md:w-[33%]  flex flex-col lg:w-[529px] lg:px-7"
        >
          <header className="flex justify-between pb-4 border-b-[3px] border-[#F6F5FA]">
            <span className=" text-[#222] font-bold">Agreement rejected</span>

            <span
              onClick={() => handleCloseDeleteModal()}
              className="flex items-center cursor-pointer gap-2 text-sm font-[500] text-[#565656]"
            >
              <PAIconSmallClose />
              Close
            </span>
          </header>

          <div className="flex flex-col pt-2 gap-6">
            <div className=" w-full flex justify-center bg-white">
              <PAIconAgreementSignIcon />
            </div>

            <div className=" text-[#222222]  text-base font-medium  tracking-wide">
              Thank you for your response. We'll communicate your decision to
              the other party. Please consider sharing your reasons for
              disagreeing with the agreement in the comments below; your input
              is appreciated.
            </div>

            <div className=" h-12 w-full  px-6 py-2 bg-white rounded-[10px] border border-[#d2d2d2] flex justify-between items-center  ">
              <div className="  h-12  flex-1 w-3/4 border-none items-center justify-center">
                <InputField
                  className=" h-12   border-none focus:outline-none text-sm font-medium p-5 "
                  placeholder={"Post a comment"}
                />
              </div>
              <PAIconAgreementSendIcon onClick={onContinue} />
            </div>
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default AgreementRejectedModal;
