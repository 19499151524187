import React, { useState } from "react";
import SingleEarlyPayment from "../singleEarlyPayment/Table";
import BulkEarlyPayment from "../bulkEarlyPayment/Table";
import AccountModalContainer from "shared/accountModal/Modal";
import { useDispatch, useSelector } from "react-redux";
import { importInvoice } from "appstate/buyer/buyerSlice";

const SingleBulkOption = ({ route, activeTabType, }) => {
  const [activeTab, setActiveTab] = useState("single");
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const { invoiceImport, pfpId, importLoading } = useSelector(
    (state) => state?.buyer
  );
  const dispatch = useDispatch();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "single":
        return <SingleEarlyPayment activeTabType={activeTabType} />;
      case "bulk":
        return <BulkEarlyPayment activeTabType={activeTabType} />;
      default:
        return null;
    }
  };

  const handleImport = () => {
    dispatch(importInvoice({ id: pfpId, body: invoiceImport }));
  };

  return (
    <div className="mt-3 gap-2">
      <AccountModalContainer
        accontModal
        isShown={open}
        onClose={() => {
          setOpen(false);
          setSuccess(false);
        }}
        isLoading={importLoading}
        success={success}
        handleAssociate={handleImport}
        warning="Are you sure you would like to import this/these invoices to the PFP?"
      />
      <div className="bg-white rounded-xl mt-5">
        <div className="flex justify-between items-center px-[15px] bg-alabasterHeader h-[70px] rounded-t-[10px] mt-3">
          <div className="py5">
            <p className="text-black text-lg font-bold">
              Rejected Early Payment Request
            </p>
            <span className="text-xs font-thin">
              List of all approved early payment requests you've Rejected
            </span>
          </div>
        </div>
        <div className="mt-3 px-[15px] text-sm bgwhite">
          <div className="flex gap-3">
            <p
              className={`cursor-pointer font-bold relative ${
                activeTab === "single" ? "text-[#F08000]" : "text-[#959595]"
              }`}
              onClick={() => handleTabClick("single")}
            >
              Single requests
              {activeTab === "single" && (
                <span
                  className="absolute bottom-0 left-1/2 transform -translate-x-1/2
                 bg-[#F08000] h-0.5 w-5"
                ></span>
              )}
            </p>

            <p
              className={`cursor-pointer font-bold relative ${
                activeTab === "bulk" ? "text-[#F08000]" : "text-[#959595]"
              }`}
              onClick={() => handleTabClick("bulk")}
            >
              Bulk requests
              {activeTab === "bulk" && (
                <span
                  className="absolute bottom-0 left-1/2 transform -translate-x-1/2
                 bg-[#F08000] h-0.5 w-5"
                ></span>
              )}
            </p>
          </div>

          {/* Render the tab content */}
          <div className="mt-3">{renderTabContent()}</div>
        </div>
      </div>
    </div>
  );
};

export default SingleBulkOption;
