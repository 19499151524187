import Table from "layout/table/Table";
import React, { useEffect, useState } from "react";
import Button from "shared/Button";
import BuyerDetailsModal from "../modal";
import DashboardLayout from "layout/dashboardlayout";
import { PAIconInventoryArrowLeft, PAIconSearch } from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AccountModalContainer from "shared/accountModal/Modal";
import {
  // acceptSupplierRequest,
  buyerRejectRequestSupplier,
  buyerRequestToAssociateWithSupplier,
  getBuyerPendingAssociateRequest,
} from "appstate/buyer/buyerSlice";
import toast from "react-hot-toast";
import Loading from "shared/Loading";
import DeclineModal from "shared/delineModal/Modal";
// import { getAllFundersAccounts } from "appstate/api/auth/onboarding";
import UserAdd from "../../../../../assets/images/svgs/useradd.svg";
import Pagination from "shared/Pagination";


const dummyFundersData = [
  {
    fullName: "John Doe",
    companyName: "ABC Corp",
    email: "john.doe@abccorp.com",
    phoneNumber: "+123456789",
    userId: "1",
  },
  {
    fullName: "Jane Smith",
    companyName: "XYZ Inc.",
    email: "jane.smith@xyz.com",
    phoneNumber: "+987654321",
    userId: "2",
  },
];

const AssociateRequestBuyer = () => {
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const {
    pendingAssociateRequestData,
    isLoading,
    declineLoading,
    associateLoading,
  } = useSelector((state) => state.buyer);
  const [getId, setGetId] = useState(null);
  const [openDeclineModal, setOpenDeclineModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [companyName, setCompanyName] = useState("");
   // eslint-disable-next-line
  const [page, setPage] = useState(1);
   // eslint-disable-next-line
  const [pageSize, setPageSize] = useState(10);
   // eslint-disable-next-line
  const [fundersData, setFundersData] = useState([]);
  // eslint-disable-next-line
  const [data, setData] = useState([]);

  const dispatch = useDispatch();
  const supplierDetails = pendingAssociateRequestData?.data?.find(
    (cur) => cur?.userId === getId
  );

  
  const sender = "supplier";
  useEffect(() => {
    const search = "";
    dispatch(
      getBuyerPendingAssociateRequest({ sender, search, page, pageSize })
    );
  }, [dispatch, page, pageSize]);

  useEffect(() => {
    setFundersData(dummyFundersData)
  }, [])

  // const fetchAllFunders = async () => {
  //   const result = await getAllFundersAccounts(page, pageSize);
  //   setFundersData(result?.data || []);
  //   const meta = result?.meta || {};
  //   const calculatedTotalPages = Math.ceil(meta.totalElements / meta.size);
  //   setTotalPages(calculatedTotalPages);
  // };

  // console.log("fundersData ", fundersData)

  // useEffect(() => {
  //   fetchAllFunders();
  //   // eslint-disable-next-line
  // }, [page, pageSize]);

  useEffect(() => {
    if (companyName.length <= 0) {
      setData(pendingAssociateRequestData?.data);
    }
  }, [pendingAssociateRequestData, companyName]);

  const onRowClicked = (row) => {
    setOpenDetailsModal(true);
    setGetId(row?.userId);
  };
  
  const handleOpenModal = (row) => {
    setGetId(row?.userId);
    setOpen(true);
  };

  // const handleOpenDeclineModal = (row) => {
  //   setGetId(row?.notificationId);
  //   setOpenDeclineModal(true);
  // };

  const handleAssociate = () => {
    const supplierId = getId;
    dispatch(buyerRequestToAssociateWithSupplier(supplierId)).then((data) => {
      if (data?.payload?.success === true) {
        setSuccess(false);
        toast.success(data?.payload?.message);
        setOpen(false);
      } else {
        toast.error(data?.payload?.message);
        setOpen(false);
      }
    })
  }

  const handleSearch = () => {
    
  };

  const handleDecline = () => {
    const notificationId = getId;
    const search = "";

    dispatch(buyerRejectRequestSupplier({ notificationId })).then((data) => {
      if (data?.payload?.success === true) {
        setSuccess(false);
        setOpenDeclineModal(false);
        dispatch(
          getBuyerPendingAssociateRequest({ sender, search, page, pageSize })
        );
      }
    });
  };

  // const totalPages = pendingAssociateRequestData?.meta?.totalElements;

  const column = [
    {
      name: "NAME",
      selector: (row) => row?.fullName,
    },
    {
      name: "COMPANY",
      selector: (row) => row?.companyName,
    },
    {
      name: "EMAIL",
      selector: (row) => row?.email,
    },
    {
      name: "PHONE",
      selector: (row) => row?.phoneNumber,
    },
    {
      cell: (row) => (
        <div className=" ">
          <Button
            neutral
            onClick={() => handleOpenModal(row)}
            className="flex !text-white text-xs font-medium border-[1px] md:px-[20px] bg-primaryColor py-[12px] px-[16px] rounded-[5px] gap-x-[4px] min-w-[120px]"
          >
            <img src={UserAdd} alt="" />
            <p className="whitespace-nowrap">Associate</p>
          </Button>
        </div>
      ),
    },
  ];

  const navigate = useNavigate();
  function handleNavigate() {
    navigate(-1);
  }
  return (
    <DashboardLayout section="Funders" subHeader="Manage all your associated funder accounts">
      <div>
        <header className="bg-alabasterHeader p-[24px] rounded-t-[10px]">
          <div
            onClick={handleNavigate}
            className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
          >
            <PAIconInventoryArrowLeft />
            <p className="mt-[2px] text-sm font-normal text-lightGray">
              Back
            </p>
          </div>
          <div className="flex justify-between flex-wrap ">
            <div>
              <p className="text-black text-sm font-medium">
                Associate with a new Funder
              </p>
              <p className="text-textColor text-xs font-normal">
                List of funders available on PayEdge
              </p>
            </div>
            <div className="flex gap-x-[10px] mt-[10px] md:mt-0 flex-wrap">
              <div className="flex items-center  bg-white p-[10px] gap-x-[8px] rounded-[5px] border-[0.5px] border-silver xl:w-[350px]">
                <PAIconSearch />
                <input
                  type="text"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  placeholder="Search request"
                  className="placeholder:text-xs outline-none"
                />
              </div>
              <Button
                neutral
                onClick={handleSearch}
                disabled={data < 1}
                className="py-[12px] mt-[10px] md:mt-0 px-[24px] border-[0.5px] border-silver bg-brightGray text-sm !text-black rounded-[5px] font-medium"
              >
                Search
              </Button>
            </div>
          </div>
        </header>
        <BuyerDetailsModal
          isShown={openDetailsModal}
          onClose={() => setOpenDetailsModal(false)}
          data={supplierDetails}
        />
        <DeclineModal
          isShown={openDeclineModal}
          onClose={() => setOpenDeclineModal(false)}
          success={success}
          handleAssociate={handleDecline}
          isLoading={declineLoading}
          warning="Are you sure you would like to Decline this request?"
        />
        <AccountModalContainer
          isShown={open}
          onClose={() => setOpen(false)}
          success={success}
          handleAssociate={handleAssociate}
          isLoading={associateLoading}
          warning="Are you sure you would like to associate with the buyer?"
        />
        <div className="p-[24px] bg-white rounded-b-[10px]">
          <Table
            columns={column}
            data={fundersData}
            pointer
            onRowClicked={onRowClicked}
            progressPending={isLoading}
            progressComponent={<Loading />}
            tableHeader
          />
          <br />
          <div className="flex justify-center gap-x-[8px]  mt-12">
            <Pagination />
        </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default AssociateRequestBuyer;