import { PAIconInventoryArrowLeft } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import Table from "layout/table/Table";
import { useNavigate } from "react-router-dom";
import Button from "shared/Button";
import TableIcons from "../../../assets/images/svgs/TableIcons.svg";
import { useState } from "react";
// import CalendarIcon from "../../assets/images/svgs/calendar-picker-icon.svg";

const data = [
  {
    invoiceReference: "INV001",
    buyer: "Nestle plc",
    invoiceDate: "2024-09-15",
    dueDate: "2024-10-15",
    currency: "NGN",
    invoiceAmount: "1,500,000",
    status: "Approved",
  },
  {
    invoiceReference: "INV002",
    buyer: "Nestle plc",
    invoiceDate: "2024-09-20",
    dueDate: "2024-10-20",
    currency: "NGN",
    invoiceAmount: "250,000,000",
    status: "Approved",
  },
  {
    invoiceReference: "INV003",
    buyer: "Nestle plc",
    invoiceDate: "2024-09-22",
    dueDate: "2024-10-22",
    currency: "NGN",
    invoiceAmount: "180,000",
    status: "Approved",
  },
  {
    invoiceReference: "INV004",
    buyer: "Nestle plc",
    invoiceDate: "2024-09-22",
    dueDate: "2024-10-22",
    currency: "NGN",
    invoiceAmount: "180,000,000",
    status: "Approved",
  },
  {
    invoiceReference: "INV005",
    buyer: "Nestle plc",
    invoiceDate: "2024-09-22",
    dueDate: "2024-10-22",
    currency: "NGN",
    invoiceAmount: "120,000",
    status: "Approved",
  },
];

const BulkDirectFundingInvoiceDetailPage = () => {
  const [selectedInvoices, setSelectedInvoices] = useState({});

  const navigate = useNavigate();
  function handleNavigate() {
    navigate(-1);
  }

  const onRowClicked = (row) => {
    let path = "/directfunding-invoice-details";
    // // dispatch(getAssociatedBuyerId(row?.id));
    navigate(path, {state: {directFunding: true}});
  };

  const handleCheckboxChange = (invoiceReference) => {
    setSelectedInvoices((prevSelected) => ({
      ...prevSelected,
      [invoiceReference]: !prevSelected[invoiceReference],
    }));
  };

  const HeaderWithIcon = ({ icon, text }) => (
    <div className="flex items-center">
      <img src={icon} alt="" className="mr-2" />
      <span>{text}</span>
    </div>
  );

  const column = [
    {
      name: <HeaderWithIcon icon={TableIcons} text="Invoice Reference" />,
      selector: (row) => (
        <div className="flex items-center">
          <input
            type="checkbox"
            checked={!!selectedInvoices[row?.invoiceReference]}
            onChange={() => handleCheckboxChange(row?.invoiceReference)}
            className={`mr-2 custom-checkbox w-4 h-4 appearance-none border-2 rounded-sm ${
              selectedInvoices[row?.invoiceReference]
                ? "bg-[#F08000] border-[#F08000]"
                : "bg-[#C2C0C0] border-[#C2C0C0]"
            } cursor-pointer`}
            style={{
              outline: "none",
            }}
          />

          {row?.invoiceReference}
        </div>
      ),
    },
    {
      name: <HeaderWithIcon icon={TableIcons} text="Buyer" />,
      selector: (row) => row?.buyer,
    },
    
    {
      name: "Invoice Date",
      selector: (row) => row?.invoiceDate,
    },
    {
      name: "Due Date",
      selector: (row) => row?.dueDate,
    },
    {
      name: "Currency",
      selector: (row) => row?.currency,
    },
    {
      name: "Invoice Amount",
      selector: (row) => row?.invoiceAmount,
    },
    {
      name: "Status",
      selector: (row) => (
        <span
          style={{
            color: row.status === "Approved" ? "#2EA923" : undefined,
          }}
        >
          {row?.status}
        </span>
      ),
    },
  ];

  const handleSelectAll = () => {
    if (
      Object.keys(selectedInvoices).length === data.length &&
      Object.keys(selectedInvoices).length > 0
    ) {
      setSelectedInvoices({});
    } else {
      const allSelected = {};
      data.forEach((invoice) => {
        allSelected[invoice.invoiceReference] = true;
      });
      setSelectedInvoices(allSelected);
    }
  };

  const isAnySelected = Object.keys(selectedInvoices).some(
    (key) => selectedInvoices[key]
  );

  return (
    <DashboardLayout
      section="Invoice"
      subHeader="Here is an overview of your invoices"
    >
      <div>
        <header className="bg-alabasterHeader p-[24px] rounded-t-[10px]">
          <div className="flex justify-between">
            <div
              onClick={handleNavigate}
              className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
            >
              <PAIconInventoryArrowLeft />
              <p className="mt-[2px] text-sm font-normal text-lightGray">
                Go Back
              </p>
            </div>
            <div className="flex">
              <div className="pr-2">
                <Button
                  neutral
                  onClick={handleSelectAll}
                  className={`py-[12px] mt-[10px] md:mt-0 px-[24px] rounded-[5px] font-medium ${
                    isAnySelected
                      ? "bg-[#2EA923] text-sm !text-white"
                      : "bg-[#C2C0C0] text-sm !text-white"
                  }`}
                >
                  Select all
                </Button>
              </div>
              <div>
                <Button
                  neutral
                  className={`py-[12px] mt-[10px] md:mt-0 px-[24px] rounded-[5px] font-medium ${
                    isAnySelected
                      ? "bg-[#F08000] text-sm !text-white"
                      : "bg-[#C2C0C0] text-sm !text-white"
                  }`}
                >
                  Set Request Percentage
                </Button>
              </div>
            </div>
          </div>
          <div className="flex justify-between flex-wrap ">
            <div>
              <p className="text-black text-sm font-medium">
                32 Approved invoices
              </p>
              <p className="text-textColor text-xs font-normal">
                 Available for payment
              </p>
              <div className="pt-2">
                <label className="text-black text-sm font-medium">
                  Select  request date
                </label>
                <div className="relative mt-2">
                  <input
                    type="date"
                    placeholder="Enter date"
                    className="border border-gray-300 rounded-[5px] py-[10px] px-[12px] text-sm w-[350px] pr-[40px]"
                  />
                </div>
              </div>
            </div>
          </div>
          <br />
        </header>
      </div>
      <div className="p-[24px] bg-white rounded-b-[10px]">
        <Table
          columns={column}
          pointer
          data={data}
          onRowClicked={onRowClicked}
        />
      </div>
    </DashboardLayout>
  );
};

export default BulkDirectFundingInvoiceDetailPage;
