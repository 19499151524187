import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { PAIconInvShop } from "assets/images/svgs";
import TooltipShared from "shared/tooltip/Tooltip";
// import { formatCapitalize } from "utilities/formatCapitalize";
import { formatCurrency } from "utilities/helper/formatters";

function MyInvoicesCard({
  id,

  invoiceNumber = "N/A",

  invoiceAmount = "N/A",

  invoiceDueDate = "N/A",

  earlyDate = "N/A",
  earlyId,
}) {
  const navigate = useNavigate();

  return (
    <motion.div
      onClick={() =>
        navigate(`/reimbursement-liened-closed-market-bulk-inner-details/${id}`)
      }
      whileTap={{ scale: 0.98 }}
      style={{ boxShadow: "0px 2px 4px 0px rgba(232, 226, 216, 0.36)" }}
      className="flex cursor-pointer justify-between bg-white py-5 px-6 rounded-[10px] mt-4"
    >
      <span className="flex gap-6   items-center">
        <PAIconInvShop />
        <div className="flex flex-col justify-center   ">
          <span className="font-[500] text-sm">
            <TooltipShared text={`INV-${invoiceNumber}`} maxLength={10} />
          </span>
          <span className=" text-[#959595] text-[10px]">Invoice number</span>
        </div>
      </span>
      <span className="flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">
            {formatCurrency(invoiceAmount)}
          </span>
          <span className=" text-[#959595] text-[10px]">Invoice Amount</span>
        </div>
      </span>

      <span className="hidden extra-md:flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{invoiceDueDate}</span>
          <span className=" text-[#959595] text-[10px]">Invoice date</span>
        </div>
      </span>

      <span className="hidden extra-md:flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{earlyDate}</span>
          <span className=" text-[#959595] text-[10px]">Due date</span>
        </div>
      </span>
    </motion.div>
  );
}

export default MyInvoicesCard;
