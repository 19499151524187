import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useSearchParams } from "react-router-dom";
import LoadingScreen from "shared/LoadingScreen";
// import { allInvoiceById } from "appstate/invoice/invoiceSlice";
import InvoiceDetails from "./InvoiceDetails";
import { PAIconArrowLeftGreen } from "assets/images/svgs";
import {
  allTransactionsDetails,
  supplierPartialReceivableInvoice,
} from "appstate/collections/collectionSlice";
import { formatCapitaliseString } from "utilities/helper/formatCapitaliseString";

const Details = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const programId = searchParams.get("programId");
  const { allTransactionsDetailsData, isLoading } = useSelector(
    (state) => state?.collection
  );
  const [activeTab, setActiveTab] = useState("transaction");

  useEffect(() => {
    dispatch(allTransactionsDetails({ transactionId: id }));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(supplierPartialReceivableInvoice({ id: programId }));
  }, [dispatch, programId]);

  const { supplierPartialReceivableInvoiceData } = useSelector(
    (state) => state?.collection
  );

  console.log("data: ", supplierPartialReceivableInvoiceData?.data);

  // Calculate the payment based on the due date and created date
  const calculatePayment = (dueDate, createdDate) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const dueDateObj = new Date(dueDate);
    const createdDateObj = new Date(createdDate?.split(" ")[0]);
    const diffDays = Math.round(
      Math.abs((dueDateObj - createdDateObj) / oneDay)
    );
    return `${diffDays} Days`;
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  const detailsData = [
    {
      label: "Transfer from",
      value: allTransactionsDetailsData?.data?.senderDetails?.companyName,
    },
    {
      label: "Transfer to",
      value: allTransactionsDetailsData?.data?.beneficiaryDetails?.companyName,
    },
    {
      label: "Status",
      value: allTransactionsDetailsData?.data?.status,
      statusColor: "text-[#07593D]",
    },
    {
      label: "Payment Date",
      value: allTransactionsDetailsData?.data?.paymentDate
        ? allTransactionsDetailsData.data.paymentDate.join("-") // Format as YYYY-MM-DD
        : "N/A",
      statusColor: "text-[#000]",
    },
    {
      label: "Transaction ID",
      value: allTransactionsDetailsData?.data?.transactionId,
      statusColor: "text-[#0076EC]",
    },
    {
      label: "Collection Type",
      value: allTransactionsDetailsData?.data?.collectionType,
    },
    {
      label: "Collection Method",
      value: allTransactionsDetailsData?.data?.collectionMethod,
      statusColor: "text-[#07593D]",
    },
    {
      label: "Funding Channel",
      value: formatCapitaliseString(
        allTransactionsDetailsData?.data?.fundingChannel
      ),
      statusColor: "text-[#F08000]",
    },
    {
      label: "Description from Sender",
      value: formatCapitaliseString(allTransactionsDetailsData?.data?.description),
    },
  ];

  const data = {
    invoiceNumber: `INV-${
      supplierPartialReceivableInvoiceData?.data?.invoices[0]?.invoiceNumber ||
      "NIL"
    }`,
    referenceNumber:
      supplierPartialReceivableInvoiceData?.data?.invoices[0]
        ?.invoiceReference || "NIL",
    createdDate:
      supplierPartialReceivableInvoiceData?.data?.invoices[0]?.createdDate?.slice(
        0,
        10
      ) || "NIL",
    dueDate:
      supplierPartialReceivableInvoiceData?.data?.invoices[0]?.dueDate || "NIL",
    payment: calculatePayment(
      supplierPartialReceivableInvoiceData?.data?.invoices[0]?.dueDate,
      supplierPartialReceivableInvoiceData?.data?.invoices[0]?.createdDate
    ),
    currency:
      supplierPartialReceivableInvoiceData?.data?.invoices[0]?.currency || "NIL",
    buyer:
      supplierPartialReceivableInvoiceData?.data?.invoices[0]
        ?.buyerCompanyName || "",
    paymentTerms:
      supplierPartialReceivableInvoiceData?.data?.invoices[0]?.paymentTerms ||
      "NIL",
    supplier:
      supplierPartialReceivableInvoiceData?.data?.invoices[0]
        ?.supplierCompanyName || "",
        status:
      formatCapitaliseString(supplierPartialReceivableInvoiceData?.data?.invoices[0]?.status ||
      "NIL"),
    buyerCompanyName:
      supplierPartialReceivableInvoiceData?.data?.invoices[0]
        ?.buyerCompanyName || "NIL",
    accountNumber:
      supplierPartialReceivableInvoiceData?.data?.invoices[0]?.fundingAccount
        ?.accountNumber || "",
    accountName:
      supplierPartialReceivableInvoiceData?.data?.invoices[0]?.fundingAccount
        ?.accountName || "NIL",
    vat:
      supplierPartialReceivableInvoiceData?.data?.invoices[0]?.vat?.toLocaleString() ||
      "NIL",
    discount:
      supplierPartialReceivableInvoiceData?.data?.invoices[0]?.discount?.toLocaleString() ||
      "NIL",
    vatAmount:
      supplierPartialReceivableInvoiceData?.data?.invoices[0]?.vatAmount?.toLocaleString() ||
      "NIL",
    discountAmount:
      supplierPartialReceivableInvoiceData?.data?.invoices[0]?.discountAmount?.toLocaleString() ||
      "NIL",
    issuanceDate:
      supplierPartialReceivableInvoiceData?.data?.invoices[0]?.issuanceDate ||
      "NIL",
    settlementDate:
      supplierPartialReceivableInvoiceData?.data?.invoices[0]?.settlementDate ||
      "NIL",
    submittedDate:
      supplierPartialReceivableInvoiceData?.data?.invoices[0]?.submittedDate ||
      "NIL",
    approvedDate:
      supplierPartialReceivableInvoiceData?.data?.invoices[0]?.approvalDate ||
      "NIL",
    grandTotal:
      supplierPartialReceivableInvoiceData?.data?.invoices[0]?.grandTotal?.toLocaleString() ||
      "NIL",
    subTotal:
      supplierPartialReceivableInvoiceData?.data?.invoices[0]?.subTotal?.toLocaleString() ||
      "NIL",
    numberOfLineItems:
      supplierPartialReceivableInvoiceData?.data?.invoices[0]?.items?.length ||
      0,
    lineItems:
      supplierPartialReceivableInvoiceData?.data?.invoices[0]?.items?.map(
        (item) => ({
          item: item?.itemName || "",
          description: item?.description || "",
          unitPrice: item?.unitPrice?.toLocaleString() || "",
          quantity: item?.quantity || 0,
          amount: item?.amount?.toLocaleString() || "",
          taxRate: item?.taxRate || "",
          taxAmount: item?.taxAmount?.toLocaleString() || "",
          totalPrice: item?.totalPrice?.toLocaleString() || "",
        })
      ) || [],
  };

  return (
    <>
      {/* HEADER */}
      <header className="rounded-md w-full bg-white mb-4 flex items-center justify-between px-5">
        <Link to="/collections" className="flex items-center gap-1 py-3">
          <PAIconArrowLeftGreen />
          <span className="text-primaryColor cursor-pointer">Go Back</span>
        </Link>
      </header>
      <div className="grid lg:grid-cols-[60%,40%] space-x-5">
        {/* 60% */}
        <InvoiceDetails data={data} showSendReminderButton={false} />

        {/* 40% */}
        <div className="bg-white rounded-[10px] gap-8 px-6 pt7 pb-24">
          <div>
            <div className="flex gap-10 my-5">
              <h2
                onClick={() => setActiveTab("transaction")}
                className={`cursor-pointer text-gray ${
                  activeTab === "transaction"
                    ? "text-primaryColor border-b-2 border-primaryColor"
                    : ""
                }`}
              >
                Transaction Details
              </h2>
              <h2
                onClick={() => setActiveTab("channel")}
                className={`cursor-pointer text-gray ${
                  activeTab === "channel"
                    ? "text-primaryColor border-b-2 border-primaryColor"
                    : ""
                }`}
              >
                Channel Details
              </h2>
            </div>
            {/* Render content based on active tab */}
            {activeTab === "transaction" && (
              <>
                <div className="flex justify-between items-center mb-4">
                  <h2 className="fontsemibold text-lg leading-[30px]">
                    Collection Transaction Details
                  </h2>
                  <button className="border border-gray300 rounded px-3 py-1.5">
                    Export
                  </button>
                </div>

                <div className="flex justify-between my-3 border-b-[3px] border-b-[#F6F5FA] pb-5">
                  <div>
                    <h5 className="text-base font-normal">Amount</h5>
                    <h2 className="text-[#07593D] text-2xl font-normal">
                      {allTransactionsDetailsData?.data?.amount?.toLocaleString()}
                    </h2>
                  </div>
                </div>
                <div className="mt-8">
                  <div className="flex flex-col gap-3">
                    {detailsData?.map((item, index) => (
                      <div key={index} className="mb-4 flex justify-between">
                        <div>
                          <p className="text-[#222] font-medium text-sm">
                            {item.label}
                          </p>
                        </div>
                        <div className="flex-1 ml-4 max-h-[50px]">
                          <p className="text-[#979797] font-medium text-sm flex justify-end text-right">
                            <span className={item.statusColor}>
                              {item.value}
                            </span>
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}

            {activeTab === "channel" && (
              <div className="h-[15%]">
                <div className="px6 py6 pb-4 border-b-[1px] border-b-slate-300 flex justify-between items-center">
                  <div>
                    <p className="text-gray600 text-xs">Request Amount</p>
                    <span className="text-sm">{supplierPartialReceivableInvoiceData?.data?.requestAmount?.toLocaleString() || "NIL"}</span>
                  </div>
                  <div>
                    <p className="text-gray600 text-xs">Receivable Amount</p>
                    <span className="flex justify-end text-sm">{supplierPartialReceivableInvoiceData?.data?.receivableAmount?.toLocaleString() || "NIL"}</span>
                  </div>
                </div>

                <div className="px6 py-6 pb-4 border-b-[1px] border-b-slate-300 flex flex-col">
                  <div className="flex justify-between items-center">
                    <div>
                      <p className="text-gray600 text-xs">Payment Date</p>
                      <span className="text-sm">{supplierPartialReceivableInvoiceData?.data?.paymentDate || "NIL"}</span>
                    </div>
                    <div>
                      <p className="text-gray600 text-xs">Request Date</p>
                      <span className="flex justify-end text-sm">
                      {supplierPartialReceivableInvoiceData?.data?.requestDate || "NIL"}
                      </span>
                    </div>
                  </div>
                  <div className="flex justify-between items-center mt-2">
                    <div>
                      <p className="text-gray600 text-xs">Lock Rate Amount</p>
                      <span className="text-sm">{supplierPartialReceivableInvoiceData?.data?.lockRateAmount?.toLocaleString() || "NIL"}</span>
                    </div>
                    <div>
                      <p className="text-gray600 text-xs">Funder's Lock Rate</p>
                      <span className="flex justify-end text-sm">
                      {supplierPartialReceivableInvoiceData?.data?.lockRate|| "NIL"}{" "}
                        <span className="text-primaryColor">(Recourse)</span>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="px6 py-6 pb-4 border-b-[1px] border-b-slate-300">
                  <p className="text-xs text-gray600">Bid Status</p>
                  <p className="text-primaryColor">{formatCapitaliseString(supplierPartialReceivableInvoiceData?.data?.status) || "NIL"}</p>
                </div>

                <div className="px6 py-6 pb-4">
                  <h2 className="font-bold ">Funder Personal Information</h2>
                  <div className="my-3">
                    <p className="text-xs text-gray600">Funder's name</p>
                    <p className="textprimaryColor">{supplierPartialReceivableInvoiceData?.data?.funderCompanyName || "NIL"}</p>
                  </div>
                  <div className="flex justify-between items-center">
                    <div>
                      <p className="text-xs text-gray600">Email</p>
                      <p className="text-sm">{supplierPartialReceivableInvoiceData?.data?.funderEmail || "NIL"}</p>
                    </div>
                    <div>
                      <p className="text-xs text-gray600 flex justify-end">
                        Contact number
                      </p>
                      <p className="text-sm">{supplierPartialReceivableInvoiceData?.data?.funderContactNumber || "NIL"}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Details;
