import { PAIconArrowLeftGreen, PAIconCocacola } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import TransitionScale from "shared/animation/TransitionScale";
import DirectFundingAcceptModal from "features/invoice/directfunding/modals/DirectFundingAcceptModal";
import ConfirmDirectFundingModal from "features/invoice/directfunding/modals/ConfirmDirectFundingModal2";
import DirectFundingBidSelectAccountModal from "features/invoice/directfunding/modals/DirectFundingBidSelectAccountModal";
import BidPinModal from "features/payableFinaceForFunder/CloseMarket/modals/BidPinModal";
import BidProceedModal from "features/payableFinaceForFunder/CloseMarket/modals/BidProceedModal";
import BidLoaderModal from "features/payableFinaceForFunder/CloseMarket/modals/BidLoaderModla";
import DirectFundingPayBidSuccessOnModal from "features/invoice/directfunding/modals/DirectFundingPaySucessOnBoardModal";
import DirectFundingRejectModal from "features/invoice/directfunding/modals/DirectFundingRejectModal";
import ReasonForRequiredModal from "features/invoice/directfunding/modals/ResaonRequired";
// import DirectFundingDeclinedModal from "./modals/DirectFundingDeclined";
import DirectFundingDeclinedModal from "features/invoice/directfunding/modals/DirectFundingDeclined";
import { useDispatch, useSelector } from "react-redux";
import { funderEarlyPaymentViewSinglePendingDetails } from "appstate/funder/funderSlice";
import { allInvoiceById } from "appstate/invoice/invoiceSlice";
import { getFunderDiscountRate } from "appstate/rulesandconfig/rulesandconfigSlice";

const FunderEarlyPaymentDetailsAccepted = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  function handleNavigate() {
    let path = -1;
    navigate(path);
  }
  const [acceptModal, setAcceptModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [bidSelectAccountModal, setBidSelectAccountModal] = useState(false);
  const [bidProceed, setBidProceed] = useState(false);
  const [bidPin, setBidPin] = useState(false);
  const [bidLoaderModal, setBidLoaderModal] = useState(false);
  const [bidSuccess, setBidSuccess] = useState(false);
  const [reasonFor, setReasonfor] = useState(false);
  const [directRejectModal, setDirectRejectModal] = useState(false);
  const { getInvoiceId, funderEarlyPaymentViewSinglePendingDetailsData } =
    useSelector((state) => state?.funder);
  const { invoiceIdData } = useSelector((state) => state.invoice);
  const [data, setData] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);
  const [checkedAccount, setCheckedAccount] = useState(null);
  const [funderRate, setFunderRate] = useState("");

  useEffect(() => {
    dispatch(
      funderEarlyPaymentViewSinglePendingDetails(getInvoiceId?.earlyPaymentId)
    );
  }, [dispatch, getInvoiceId?.earlyPaymentId]);

  useEffect(() => {
    const id = getInvoiceId?.invoiceId;
    dispatch(allInvoiceById({ id }));
  }, [dispatch, getInvoiceId?.invoiceId]);

  useEffect(() => {
    setData(funderEarlyPaymentViewSinglePendingDetailsData?.data);
  }, [funderEarlyPaymentViewSinglePendingDetailsData]);

  useEffect(() => {
    setInvoiceData(invoiceIdData?.data);
  }, [invoiceIdData]);

  const handleProceed = () => {
    setBidProceed(false);

    setTimeout(() => {
      setBidPin(true);
    }, 300);
  };

  const handleOnselectModal = () => {
    setBidSelectAccountModal(false);

    setTimeout(() => {
      setBidProceed(true);
    }, 300);
  };
  const handlePinModal = () => {
    setBidPin(false);
    setBidLoaderModal(true);
  };

  // const handleTabClick = (tabNumber) => {
  //   setIsInvoiceModalOpen(false);
  //   dispatch(setClosedMarketActiveTab(tabNumber));
  //   sessionStorage.setItem("activeTab", tabNumber);
  // };

  const handleYes = () => {
    setAcceptModal(false);
    setConfirmModal(true);
  };
  const handleReject = () => {
    setRejectModal(false);
    setReasonfor(true);
  };
  // const effectiveRate = data?.discountRate - funderRate;
  const effectiveRate = Math.min(data?.discountRate, funderRate);
  const [disCountPer, setDiscountPer] = useState(null);
  const [initialEffectiveRate, setInitialEffectiveRate] = useState(0);
  

  useEffect(() => {
    setDiscountPer(effectiveRate?.toFixed(2));
  }, [effectiveRate]);
  

  useEffect(() => {
    const fetchDiscountRate = async () => {
      try {
        const response = await dispatch(getFunderDiscountRate());
        setFunderRate(response?.payload?.data[0]?.discount);
      } catch (error) {
        console.error("Error", error);
      }
    };
    fetchDiscountRate();
  }, [dispatch]);

  const [prevDis, setPrevDis] = useState("2%");
  const [reasonData, setReasonData] = useState([]);

  // const [editDiscount, setEditDiscount] = useState(false);

  const handleDiscountChange = (e) => {
    e.preventDefault();
    setDiscountPer(e.target.value);
    setInitialEffectiveRate(effectiveRate?.toFixed(2))
  };
  const handleSave = () => {
    setDiscountPer(disCountPer);
    setPrevDis(disCountPer);
  };
  const handleCancel = () => {
    setDiscountPer(prevDis);
    setPrevDis(prevDis);
  };
  const handdleConfirmNext = () => {
    setConfirmModal(false);
    setBidSelectAccountModal(true);
  };
  const handleBidSuccessClose = () => {
    setBidSuccess(false);
    navigate(-1);
  };
  const handleContinue = () => {
    setReasonfor(false);
    setDirectRejectModal(true);
  };
  const handleDeclineClose = () => {
    setDirectRejectModal(false);
    handleNavigate();
  };
  return (
    <>
      <TransitionScale>
        <DirectFundingAcceptModal
          mess1={"Would you like to proceed with "}
          blueText={"Approving "}
          mess2={" this early"}
          mess3={"payment request"}
          isOpen={acceptModal}
          onClose={() => setAcceptModal(false)}
          handleYes={handleYes}
        />
      </TransitionScale>
      <TransitionScale>
        <DirectFundingRejectModal
          mess1={"Would you like to proceed with "}
          blueText={"Rejecting "}
          mess2={" this Early"}
          mess3={"Payment request?"}
          isOpen={rejectModal}
          onClose={() => setRejectModal(false)}
          handleYes={handleReject}
        />
      </TransitionScale>

      <TransitionScale>
        <ConfirmDirectFundingModal
          isOpen={confirmModal}
          onClose={() => setConfirmModal(false)}
          disValue={disCountPer}
          handleChange={handleDiscountChange}
          handleCancel={handleCancel}
          handleSave={handleSave}
          handleNext={handdleConfirmNext}
          funderRate={funderRate}
          data={data}
          initialEffectiveRate={initialEffectiveRate}
        />
      </TransitionScale>
      <TransitionScale>
        <DirectFundingBidSelectAccountModal
          isOpen={bidSelectAccountModal}
          onClose={() => setBidSelectAccountModal(false)}
          handleProceed={handleOnselectModal}
          message={"Select which account you want to use for this Request"}
          setCheckedAccount={setCheckedAccount}
        />
      </TransitionScale>

      <TransitionScale>
        {bidLoaderModal && (
          <BidLoaderModal
            isOpen={bidLoaderModal}
            onClose={() => setBidLoaderModal(false)}
            onAction={() => {
              setBidLoaderModal(false);
              setTimeout(() => {
                setBidSuccess(true);
              }, 300);
            }}
            statusText={"Fund Invoice"}
          />
        )}{" "}
      </TransitionScale>

      <TransitionScale>
        {" "}
        <BidPinModal
          isOpen={bidPin}
          onClose={() => setBidPin(false)}
          handleNext={handlePinModal}
          checkedAccount={checkedAccount}
        />
      </TransitionScale>
      <TransitionScale>
        <BidProceedModal
          isOpen={bidProceed}
          onClose={() => setBidProceed(false)}
          handleYes={handleProceed}
          checkedAccount={checkedAccount}
        />
      </TransitionScale>
      <TransitionScale>
        <DirectFundingPayBidSuccessOnModal
          isOpen={bidSuccess}
          onClose={handleBidSuccessClose}
          message={"Early payment request sent for"}
          sucess={"workflow approval"}
        />
      </TransitionScale>
      {reasonFor && (
        <TransitionScale>
          <ReasonForRequiredModal
            onClose={() => setReasonfor(false)}
            isOpen={setReasonfor}
            onContinue={handleContinue}
            reasonData={reasonData}
            setReasonData={setReasonData}
            earlyPaymentId={data?.earlyPaymentId}
          />
        </TransitionScale>
      )}
      <TransitionScale>
        <DirectFundingDeclinedModal
          isOpen={directRejectModal}
          onClose={handleDeclineClose}
        />
      </TransitionScale>

      <DashboardLayout
        section="Early Payment Request"
        subHeader="Manage all your Early payment requests"
      >
        <header>
          <div
            onClick={handleNavigate}
            className="flex items-center gap-x-[3px] mb-[10px] cursor-pointer"
          >
            {/* <PAIconInventoryArrowLeft />
        <p className="mt-[2px] text-sm font-normal text-lightGray">Go Back</p> */}
            <PAIconArrowLeftGreen />
            <span className="flex items-center text-primaryColor">Go Back</span>
          </div>
        </header>

        <div className="grid lg:grid-cols-[60%,40%] gap-5 h-[85vh]">
          <TransitionScale className=" pb-5 bg-white  rounded-[10px] mt-5 pt-2 ">
            <section className="  mt-3">
              {/* Down Flex Texts */}
              <div className=" flex justify-between px-6">
                {/* first stage */}

                <div class="lg:w-[240px]  sm:w-full h-[117px] flex-col justify-start items-start gap-3 inline-flex">
                  <div class=" justify-between items-center inline-flex mt-2">
                    <div class="text-[#222222] text-base font-semibold  ">
                      Reference No:
                    </div>
                    <div class="text-[#222222] text-base font-semibold ">
                      | {data?.invoiceRef}
                    </div>
                  </div>
                  <div class="self-stretch  flex-col justify-center items-start gap-1 flex mt-2">
                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Invoice Number
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        INV {data?.invoiceNo}
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex ">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Created Date:{" "}
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        {invoiceData?.createdDate}
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Submitted Date:{" "}
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        {invoiceData?.submittedDate}
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Approved Date:{" "}
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        {invoiceData?.approvedDate}
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Due Date:{" "}
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        {invoiceData?.dueDate}
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Issuance Date:
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        {invoiceData?.issuanceDate}
                      </div>
                    </div>
                  </div>

                  {/* stop */}
                </div>
                {/* second stage */}
                <div className="flex justify-between items-center mt-10 ">
                  {" "}
                  <div class="h-[37px] py-2 rounded-[5px]  bg-[#ffbe55]/30 justify-center items-center gap-2.5 inline-flex px-4">
                    <div class="text-[#f08000] text-sm font-medium ">
                      Pending Approval
                    </div>
                  </div>
                </div>
              </div>

              {/* stops here */}
              <div className="  px-6 mt-24">
                <header className="border-b-[5px] border-b-[#F6F5FA] mt-3  flex justify-between py-1">
                  {/* <h1 className="text-[18px] font-semibold">
                Supplier/buyer details
              </h1> */}
                  <div>
                    <div class="text-[#222222] text-lg font-semibold  ">
                      {data?.supplierCompanyName}
                    </div>
                    <div class="text-[#7a7a7a] text-xs font-light  ">
                      Buyer details
                    </div>
                  </div>

                  <div class="h-[41px] flex-col justify-start items-start  inline-flex px-12">
                    <div class="text-[#222222] text-base font-semibold ">
                      {invoiceData?.paymentTerms} Days
                    </div>
                    <div class="w-[100px] text-[#7a7a7a] text-xs font-light  mt-1">
                      Payment terms
                    </div>
                  </div>

                  <div class="h-[43px] flex-col justify-start items-end  inline-flex">
                    <div class="text-[#222222] text-lg font-semibold ">
                      {invoiceData?.currency}
                    </div>
                    <div class="text-[#7a7a7a] text-xs font-semibold mt-1">
                      Currency
                    </div>
                  </div>
                </header>

                <header className="border-b-[5px] border-b-[#F6F5FA] mt-6  flex justify-between py-1">
                  {/* <h1 className="text-[18px] font-semibold">
                Supplier/buyer details
              </h1> */}
                  <div class="text-[#11192a] text-sm font-semibold ">
                    Line Items
                  </div>
                  <div class="text-[#11192a] text-lg font-light  mr-6">
                    {invoiceData?.items?.length} Total Item(s)
                  </div>
                </header>
                <div className=" w-full overflow-x-auto mt-3 pb-4 h-[25vh]">
                  <table className=" w-full overflow-x-auto">
                    <thead className="bg-subtleGray w-full p-6  flex-nowrap whitespace-nowrap">
                      <tr className=" bg-unusualGray  px-6 w-full flex-nowrap whitespace-nowrap ">
                        {/* <td className="text-[#11192A] p-2.5 text-xs font-bold">
                        Item Number
                      </td> */}
                        <td class="text-[#11192a] text-xs font-semibold  p-4  flex-nowrap whitespace-nowrap ">
                          Item{" "}
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                          Description{" "}
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                          Unit Price
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                          Quantity
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                          Amount
                          <div> (before tax)</div>
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                          Tax Rate
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                          Tax Amount
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                          Total Price
                        </td>
                      </tr>
                    </thead>

                    <tbody className="">
                      {invoiceData?.items?.map((cell, idx) => {
                        return (
                          <tr key={idx} className=" w-full ">
                            <td className="text-[#11192a] text-xs  mt-2 p-4 flex-nowrap whitespace-nowrap ">
                              {cell.itemName}
                            </td>
                            <td className="text-[#11192a] text-xs  mt-2 p-4 flex-nowrap whitespace-nowrap">
                              {cell.description}
                            </td>
                            <td className="text-[#11192a] text-xs  mt-2 p-4 flex-nowrap whitespace-nowrap">
                              {cell.unitPrice}
                            </td>
                            <td className="text-[#11192a] text-xs  mt-2 text-center flex-nowrap whitespace-nowrap">
                              {cell.quantity}
                            </td>
                            <td className="text-[#11192a] text-xs  mt-2 p-4 flex-nowrap whitespace-nowrap">
                              {cell.amount}
                            </td>
                            <td className="text-[#11192a] text-xs  mt-2 p-4 flex-nowrap whitespace-nowrap">
                              {cell.taxRate}
                            </td>
                            <td className="text-[#11192a] text-xs  mt-2 p-4 flex-nowrap whitespace-nowrap">
                              {cell.taxAmount?.toLocaleString()}
                            </td>
                            <td className="text-[#11192a] text-xs  mt-2 p-4 flex-nowrap whitespace-nowrap">
                              {cell.totalPrice?.toLocaleString()}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {/* Calculate cost and price  shaded or gray section */}
              </div>

              <div className=" px-6   py-5 bg-neutral-50 flex-col justify-center items-start  inline-flex bg-[#fafafa] w-full border-t-[0.2px]  border-t-[#b1a6b2]">
                <div className="lg:flex md:flex justify-between  w-full sm:block ">
                  {/* First left side */}
                  <div>
                    <section>
                      {" "}
                      <div class="text-[#222222] text-lg  font-medium border-b-[0.2px]  border-[#b1a6b2]">
                        Receiving Funding Account Information
                      </div>
                      {/* <div class="text-[#222222] text-lg font-semibold ">
                        Receiving Funding Account Information
                      </div> */}
                      <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                        {invoiceData?.virtualAccount?.accountNumber}
                      </div>
                      <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                        {invoiceData?.virtualAccount?.accountName}
                      </div>
                    </section>
                  </div>

                  {/* second division */}

                  <div>
                    {" "}
                    <div class=" flex-col justify-start items-start inline-flex lg:min-w-[290px]">
                      <section className=" w-full px-6 pb-2 border-b-[1px] border-black">
                        <div className=" flex w-full justify-between">
                          <div class="text-[#222222] text-xs font-semibold ">
                            Subtotal
                          </div>
                          <div class=" text-[#222222] text-xs font-semibold ">
                            {invoiceData?.subTotal?.toLocaleString()}
                          </div>
                        </div>

                        <div className=" flex w-full justify-between pt-3 pb-1 border-b-[0.2px] border-b-[#b1a6b2]/30">
                          <div class="text-[#222222]  text-xs font-medium flex  space-x-9 ">
                            <span>Discount%</span>
                            <span>{invoiceData?.discount}%</span>
                          </div>
                          <div class="text-[#222222] text-xs font-medium ">
                            {invoiceData?.discountAmount?.toLocaleString()}
                          </div>
                        </div>

                        <div className=" flex w-full justify-between pt-3 pb-1 border-b-[0.2px] border-b-[#b1a6b2]/30">
                          <div class="text-[#222222]  text-xs font-medium flex  space-x-9 ">
                            <span>VAT%</span>
                            <span>{invoiceData?.vat}%</span>
                          </div>
                          <div class="text-[#222222] text-xs font-medium ">
                            {invoiceData?.vatAmount?.toLocaleString()}
                          </div>
                        </div>
                      </section>
                      <div className=" flex w-full justify-between py-2 px-6 border-b-[1px] border-b-black">
                        <div class="text-[#222222] text-lg font-semibold ">
                          Grand Total
                        </div>
                        <div class="text-[#222222] text-lg font-semibold ">
                          {invoiceData?.grandTotal?.toLocaleString()}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* stops */}
                </div>
              </div>
            </section>
          </TransitionScale>
          {/* stops the best here */}

          <div className="bg-white rounded-[10px] gap-8  mt-5">
            {/* <FundDetailSide /> */}
            <div class="w-full justify-start items-center gap-4 inline-flex ">
              <div class=" justify-start items-center gap-2.5 flex w-full px-6  py-3">
                <div class="text-black text-xl font-bold  mt-5">
                  Early Payment Details
                </div>
              </div>
            </div>

            <div>
              {" "}
              {/* second */}
              <div className="   pb-4">
                <div class=" justify-between  flex flex-row    px-6 mt-4  ">
                  <div className="flex gap-2 py-2.5">
                    <PAIconCocacola />
                    <div className="flex flex-col">
                      <span class="text-black text-sm font-normal ">
                        {data?.supplierCompanyName}
                      </span>
                      <span class="text-[#949494] text-sm font-normal ">
                        Supplier
                      </span>
                    </div>
                  </div>
                  <div class="py-2.5 flex-col justify-start items-end inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        {data?.discountRate}%
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Discount rate
                      </div>
                    </div>
                  </div>

                  {/* here */}
                </div>
                <div class=" justify-between  flex flex-row    px-6  mt-6 ">
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        {invoiceData?.currency}{" "}
                        {data?.discountAmount?.toLocaleString()}
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Discount amount
                      </div>
                    </div>
                  </div>
                  {/* hd */}
                  <div class="py-2.5 flex-col justify-start items-end inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        {data?.earlyPaymentDate}
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Early Payment Date
                      </div>
                    </div>
                  </div>{" "}
                </div>
                <div class=" justify-between  flex flex-row    border-b border-[#c2c2c2]/30 px-6  py-3  pb-12 mt-6">
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        {invoiceData?.currency}{" "}
                        {data?.requestAmount?.toLocaleString()}
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        {invoiceData?.currency} Request amount
                      </div>
                    </div>
                  </div>
                  {/* dhdh */}
                  <div class="py-2.5 flex-col justify-start items-end inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        {data?.fundableAmount?.toLocaleString()}
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Fundable Amount
                      </div>
                    </div>
                  </div>
                </div>
                {/* 2nd */}
                <div class=" justify-between  flex flex-col    border-b border-[#c2c2c2]/30 px-6  pb-10 ">
                  <div class="text-black text-lg font-semibold  mt-6">
                    Note from Supplier:{" "}
                  </div>

                  <div class=" text-[#949494] text-sm font-light  mt-4  leading-6">
                    {data?.description}.
                  </div>
                </div>{" "}
                <div class=" justify-between  flex flex-col    px-6  ">
                  <div class="text-black text-lg font-semibold  mt-6">
                    Your decision
                  </div>

                  <div class=" text-[#949494] text-sm font-light  mt-4  leading-6">
                    Early Payment Request: Please review and take action. Accept
                    if approved, or Reject if not suitable. Thank you
                  </div>

                  {/* stops */}
                </div>
                <div className=" grid lg:grid-cols-[47%,47%] gap-4 px-6  mt-7">
                  <button
                    className={`  px-3 text-white justify-center  flex items-center bg-[#07593d] rounded-[5px] py-2 text-sm ${"cursor-pointer"}`}
                    onClick={() => setAcceptModal(true)}
                  >
                    Accept
                  </button>
                  <button
                    className={`    py-2 px-3  text-[#F08000]  border border-[#F08000] justify-center  flex items-center bg-white rounded-[5px]  text-sm ${"cursor-pointer"}`}
                    onClick={() => setRejectModal(true)}
                  >
                    <div>Reject</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};
export default FunderEarlyPaymentDetailsAccepted;
