import React, { useEffect, useMemo, useCallback } from "react";

import { useNavigate } from "react-router-dom";
import Table from "layout/table/Table";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import Pagination from "shared/Pagination";

import { fetchWorkflowPendingRequestsHistory } from "appstate/workflow/workflowSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import TableEmptyState from "shared/TableEmptyState";
import Card from "shared/Card";
import { PAIconOutlineSearch } from "assets/images/svgs";
import * as XLSX from "xlsx";

const RequestsHistory = () => {
  const navigate = useNavigate();

  // const [page, setPage] = useState(0);
  // const [startPage, setStartPage] = useState(0);
  // const [endPage, setEndPage] = useState(5);
  const [search] = useState("");
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(10);
  const pageSize = 10;
  // detail/pendingrequestsdetailssignpfp/:workflowId
  const handleRowClick = (row) => {
    if (row.status === "COMPLETE" && row.action !== "Sign PFP Agreement") {
      navigate(
        `/work-flow-management/detail/pendingrequestsdetails/${row.workflowId}`
      );
    } else if (row.status === "APPROVED") {
      navigate(
        `/work-flow-management/detail/pendingrequestsdetails/${row.workflowId}`
      );
    } else if (
      // detail/PendingApprovalSignPfpdetails/:workflowId
      row.type === "PFP" &&
      row.action === "Sign PFP Agreement" &&
      row.status === "COMPLETED"
    ) {
      navigate(
        `/work-flow-management/detail/pendingrequestsdetailssignpfp/${row.workflowId}`
      );
      // navigate(
      //   `/work-flow-management/detail/DeclinedPendingRequestPFPFundOffer/${row.workflowId}`
      // );
    } else if (
      row.type === "PFP" &&
      row.action === "Sign PFP Agreement" &&
      row.status === "DECLINED"
    ) {
      navigate(
        `/work-flow-management/detail/pendingrequestsdetailssignpfp/${row.workflowId}`
      );
    } else if (
      row.type === "PFP" &&
      row.action === "Sign PFP Agreement" &&
      row.status === "APPROVED"
    ) {
      navigate(
        `/work-flow-management/detail/pendingrequestsdetailssignpfp/${row.workflowId}`
      );
    } else if (
      row.status === "DECLINED" &&
      row.action !== "Sign PFP Agreement"
    ) {
      navigate(
        `/work-flow-management/detail/pendingdeclineddetails/${row.workflowId}`
      );
    } else if (row.type === "EARLY_PAYMENT_OFFERS") {
      navigate(
        `/work-flow-management/detail/PendingRequestEarlyPaymentOffer/${row.workflowId}`
      );
    } else if (row.type === "PFP" && row.action === "Fund Offer") {
      navigate(
        `/work-flow-management/detail/PendingRequestPFPFundOffer/${row.workflowId}`
      );
    } else {
      navigate(
        `/work-flow-management/detail/pendingrequestsdetails/${row.workflowId}`
      );
    }

    //         `/work-flow-management/detail/PendingRequestApprovalViaCampagnCreationScreen/${row.workflowId}`
    // } else {
    //   navigate(
    //     `/work-flow-management/detail/pendingdeclineddetails/${row.workflowId}`
    //   );
    // }
  };
  const dispatch = useDispatch();

  const { pendingRequestHistory } = useSelector((state) => state.workflow);
  useEffect(() => {
    dispatch(fetchWorkflowPendingRequestsHistory({ page, pageSize, search }));
  }, [dispatch, page, search, pageSize]);

  const columns = [
    {
      name: "Date",
      selector: (row) => row.dateSent,
    },
    {
      name: "Transaction Type ",
      selector: (row) => row.type,
    },

    {
      name: "Final Approver Name",
      selector: (row) => row.finalApproverName,
    },
    {
      name: "Approval Level",
      selector: (row) => row.approvalLevel,
    },

    {
      name: "Final Approval Date",
      selector: (row) => row.finalApprovalDate,
    },

    {
      name: "Status",
      selector: (row) => row.status,
      cell: (row) =>
        row.status === "COMPLETED" ? (
          <p className="text-[#07593D]">{row.status}</p>
        ) : row.status === "DECLINED" ? (
          <p className="text-[#BB0C0C]">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        ),
    },

    {
      name: "",
      selector: (row) => row.supplierId,
      cell: (row) => <p>{"View Details"}</p>,
    },
  ];

  const [searchValue, setSearchValue] = useState(""); // To store the search input value
  const [activeFilter, setActiveFilter] = useState("");
  const [filteredData, setFilteredData] = useState(
    pendingRequestHistory?.data || []
  ); // To store the filtered data
  const data = useMemo(
    () => pendingRequestHistory?.data || [],
    [pendingRequestHistory?.data]
  );

  useEffect(() => {
    setFilteredData(pendingRequestHistory?.data || []); // Reset filtered data when new data is fetched
  }, [pendingRequestHistory]);

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(
      filteredData.map((row) => ({
        Date: row?.dateSent,
        "Transaction Type": row?.type,
        "Final Approver Name": row?.finalApproverName,
        "Approval Level": row?.approvalLevel,
        "Final Approver Date": row?.finalApprovalDate,
        Status: row?.status,
      }))
    );
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Direct Funding");
    XLSX.writeFile(wb, "RequestHistory.xlsx");
  };

  const filterData = useCallback(() => {
    let updatedData = [...data];

    // Apply filter by type
    if (activeFilter) {
      updatedData = updatedData.filter((item) => item.type === activeFilter);
    }

    // Apply search filter
    if (searchValue) {
      updatedData = updatedData.filter((item) => {
        const lowerSearch = searchValue.toLowerCase();
        return (
          item.dateSent.toLowerCase().includes(lowerSearch) ||
          item.type.toLowerCase().includes(lowerSearch) ||
          item.action.toLowerCase().includes(lowerSearch) ||
          item.finalApproverName.toLowerCase().includes(lowerSearch) ||
          item.approvalLevel.toLowerCase().includes(lowerSearch) ||
          (item.finalApprovalDate &&
            item.finalApprovalDate.toString().includes(lowerSearch)) ||
          (item.status && item.status.toLowerCase().includes(lowerSearch))
        );
      });
    }

    setFilteredData(updatedData);
  }, [data, activeFilter, searchValue, setFilteredData]);

  useEffect(() => {
    filterData();
  }, [filterData]);

  // Synchronize data when filters or data chang

  // Synchronize data when filters or data change
  useEffect(() => {
    filterData();
  }, [filterData]);

  const handleFilterChangeOne = (e) => {
    setActiveFilter(e.target.value);
  };

  const handleSearchChangeOne = (e) => {
    setSearchValue(e.target.value);
  };
  return (
    <div>
      <div className="my-5 grid md:grid-cols-2 items-center ">
        <div className="gap-3 flex relative flex-wrap">
          <div className=" w-44">
            <select
              className="border border-[#DEDEDE] shadow-sm rounded-[5px] px-2 py-3 text-sm focus:outline-none cursor-pointer w-44 "
              onChange={handleFilterChangeOne}
              value={activeFilter}
            >
              {/* <option value="2024-12-01">2024-12-01</option>
              <option value="INVOICING">INVOICING </option>
              <option value="7cb5df8c-0cd6-47c5-9075-c7d68a3dcd8b">
                {"7cb5df8c-0cd6..."}
              </option> */}

              <option value="" disabled selected>
                Filter by{" "}
              </option>

              <option value={"INVOICING"}>INVOICING</option>
              <option value="EARLY_PAYMENT_REQUEST">
                EARLY_PAYMENT_REQUEST
              </option>
              <option value="EARLY_PAYMENT_OFFERS">EARLY_PAYMENT_OFFERS</option>
              <option value="OPEN_MARKET">OPEN_MARKET</option>
              <option value="DIRECT_FUNDING">DIRECT_FUNDING</option>
              <option value="PFP">PFP</option>
            </select>
          </div>
          {/* {activeTab === "requestshistory" && ( */}
          {filteredData.length > 0 && (
            <button
              className="py-2 px-4 rounded-[5px] bg-white border-[#DEDEDE] shadow-sm mr-2 "
              onClick={exportToExcel}
            >
              Export
            </button>
          )}

          {/* )} */}
        </div>
        <div className="flex md:col-span-1 justify-end sm:justify-start xs:justify-start sm:w-full sm:block xs:w-full ">
          {/* SAERCH SECTION */}

          <div>
            <div className="flex items-center lg:justify-end ">
              <div className="border border-transparent md:border-gray md:shadow-sm my-4 rounded-[5px] px-2 lg:w-[300px] bg-white sm:w-full block y-3 min-h-10">
                <div className="items-center flex bg-white">
                  <PAIconOutlineSearch />
                  <input
                    className="bg-transparent p-2 w-full focus:outline-none text-sm bg-white"
                    type="text"
                    placeholder="Search by sender Date range,  Workflow type or Approver name"
                    value={searchValue}
                    onChange={handleSearchChangeOne}
                  />
                </div>
                {/* <div className="lg:hidden flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer">
                    <PAIconFilter className="bg-[#2FA06A]" />
                  </div> */}
              </div>
            </div>
            {/* stop */}
          </div>
        </div>
      </div>
      <AnimatePresence>
        <Card className="rounded-[10px] mt-[24px]   bg-black">
          <div className="flex justify-between items-center px-[24px]   rounded-t-[10px]  ">
            <div className="flex gap-2 my-6">
              {/* <div class="text-black text-xs font-medium ">Request History</div> */}
              <div className="text-black text-[13px] font-medium items-center justify-center h-[23px]  flex ">
                Request History
              </div>
            </div>
          </div>
          <div className="px-[24px] ">
            {pendingRequestHistory?.data &&
            pendingRequestHistory?.data?.length > 0 ? (
              <Table
                columns={columns}
                data={filteredData}
                onRowClicked={(row) => handleRowClick(row)}
                pointer
                tableHeader
              />
            ) : (
              <TableEmptyState dashboard />
            )}
          </div>

          <div>
            {pendingRequestHistory?.meta?.totalElements > 10 && (
              <div className="flex justify-center gap-x-[8px]  mt-5">
                <Pagination
                  page={page}
                  startPage={startPage}
                  setEndPage={setEndPage}
                  endPage={endPage}
                  setStartPage={setStartPage}
                  setPage={setPage}
                  totalPages={pendingRequestHistory?.meta?.totalElements}
                />
              </div>
            )}
          </div>
        </Card>
      </AnimatePresence>
    </div>
  );
};

export default RequestsHistory;
