import { getworkflowByModule } from "appstate/workflow/workflowSlice";
import { PAIconArrowLeftMini, PAIconEditLight } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import LoadingScreen from "shared/LoadingScreen";
import { formatCapitaliseString } from "utilities/helper/formatCapitaliseString";

function ViewWorkflowAction() {
  const { player, actionType } = useParams();

  const { getWorkflowByModuleData } = useSelector((state) => state?.workflow);

  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getworkflowByModule(actionType.toUpperCase()));
  }, [dispatch, actionType]);

  useEffect(() => {
    setLoader(true);

    setTimeout(() => {
      setLoader(false);
    }, 1000);
  }, []);
  return (
    <DashboardLayout
      section="Settings"
      subHeader="Manage your account all in one place"
    >
      <div className="bg-white min-h-full flex flex-col gap-11 rounded-lg py-8 px-7">
        <header className="flex flex-col gap-4">
          <span
            onClick={() => navigate(-1)}
            className="flex gap-1 items-center cursor-pointer w-fit"
          >
            <PAIconArrowLeftMini />
            <span className="text-[#7A7A7A]">back</span>
          </span>

          <div className="flex flex-col">
            <span className=" font-bold text-[18px]">
              {formatCapitaliseString(actionType)}
            </span>

            <span className="text-sm font-medium text-[#959595]">
              Workflow Approval for{" "}
              {formatCapitaliseString(actionType).toLowerCase()}
            </span>
          </div>
        </header>

        {loader ? (
          <LoadingScreen />
        ) : (
          <main className="flex flex-col gap-3">
            <section className="w-full flex justify-end">
              <span
                onClick={() => {
                  navigate(
                    `/settings/workflow-config/edit/${player}/${actionType}`
                  );
                }}
                className="text-sm flex gap-2 cursor-pointer text-secondaryColor items-center"
              >
                <PAIconEditLight /> Edit Process
              </span>
            </section>

            <section>
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white rounded-lg">
                  <thead>
                    <tr className="border-t border-t-[#eee] border-b border-b-[#eee]">
                      <th className="text-xs py-2 px-4 bg-[#F9F9F9] text-[#7A7A7A] font-semibold text-left">
                        PROCESS
                      </th>
                      <th className="text-xs py-2 px-4 bg-[#F9F9F9] text-[#7A7A7A] font-semibold text-left">
                        STATUS
                      </th>
                      <th className="text-xs  py-2 px-4  bg-[#F9F9F9] text-[#7A7A7A] font-semibold text-left">
                        APPROVAL LEVELS
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-[#7A7A7A] text-sm">
                    {getWorkflowByModuleData?.data?.processes?.map((item) => (
                      <tr key={item.id} className="hover:bg-gray-100 w-full">
                        <td className="py-2 px-4 start text-gray-700 w-1/4">
                          {item?.action}
                        </td>
                        <td className="py-2 px-4 w-1/4 text-gray-700">
                          {item?.status ? "On" : "Off"}
                        </td>
                        <td className="py-2 px-4 w-1/2 text-gray-700">
                          {item?.approvalLevels?.map((level) => (
                            <div className="flex justify-between">
                              <input
                                style={{
                                  background: "rgba(246, 246, 246, 0.96)",
                                }}
                                value={level?.role}
                                disabled
                                className="cursor-pointer mb-2 w-[49%] outline-none rounded-[5px] px-3 py-3 mr-2"
                                type="text"
                              />
                              <input
                                style={{
                                  background: "rgba(246, 246, 246, 0.96)",
                                }}
                                value={level?.email}
                                disabled
                                className="cursor-pointer mb-2  w-[49%] outline-none rounded-[5px] px-3 py-3"
                                type="text"
                              />
                            </div>
                          ))}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </section>
          </main>
        )}
      </div>
    </DashboardLayout>
  );
}
export default ViewWorkflowAction;
