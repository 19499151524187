import {
    PAIconAlertIcon,
  } from "assets/images/svgs";
  import React from "react";
  import Button from "shared/Button";
  // import Spinner from "shared/Spinner/Spinner";
  
  const TierDeletePopup = ({ onYes, onNo, deleteModal }) => {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="flex flex-col items-center gap-y-[24px] py-[28px] px-[24px] bg-white rounded-lg shadow-lg w-[400px]"> {/* Set a specific width and added padding */}
          <div>
            <PAIconAlertIcon />
          </div>
          <p className="text-base font-normal text-lightGray text-center px-[16px]"> {/* Added padding and centered text */}
            Are you sure you would like to delete this Tier?
          </p>
          <div className="overflow-hidden flex">
            <Button
              onClick={onYes}
              neutral
              className="bg-primaryColor text-white w-[76px] flex items-center justify-center h-[43px] rounded-[5px] text-base font-normal"
            >
              Yes
            </Button>
            <div>
              <Button
                onClick={onNo}
                neutral
                className="w-[76px] h-[43px] !text-red border-[1px] border-red rounded-[5px] ml-[24px]"
              >
                No
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default TierDeletePopup;