import { PAIconArrowLeftGreen } from "assets/images/svgs";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AcceptModal from "shared/AcceptModal";
import SuccessModal from "shared/SuccessModal";

const InvoiceDetails = ({ data, showSendReminderButton = true }) => {
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  // const successStatuses = ["CREATED", "APPROVED", "PAID", "FULLY PAID"];
  // const rejectedStatuses = ["PARTIALLY PAID", "REJECTED", "NOT PAID"];
  // const awaitingStatuses = ["PENDING", "AWAITING APPROVAL", "AWAITING PAYMENT"];

  // const getStatusColorClass = (status) => {
  //   if (successStatuses.includes(status)) return "text-success bg-[#D9F7BE]";
  //   if (rejectedStatuses.includes(status)) return "text-[#ee0816] bg-[#ffa0a0]";
  //   if (awaitingStatuses.includes(status)) return "text-[#facc15] bg-[#ffffe0]";
  //   return "text-[#000]";
  // };

  const goBack = () => {
    navigate(-1);
  };

  const handleRemainderClick = () => {
    setIsAcceptModalOpen(true);
  };

  const handleConfirm = () => {
    setIsAcceptModalOpen(false);
    setIsSuccessModalOpen(true);
    setSuccessMessage("Payment reminders sent successfully!");

    setTimeout(() => {
      goBack();
    }, 2000);
  };

  const handleCloseSuccessModal = () => {
    setIsSuccessModalOpen(false);
  };

  const {
    invoiceNumber,
    // status,
    referenceNumber,
    createdDate,
    dueDate,
    // payment,
    paymentTerms,
    currency,
    // supplier,
    buyerCompanyName,
    lineItems,
    numberOfLineItems,
    submittedDate,
    approvedDate,
    issuanceDate,

    // accountNumber,
    // accountName,
    // subTotal,
    // grandTotal,
    // discount,
    // vat,
    // vatAmount,
    // discountAmount,
  } = data;

  return (
    <div className="flex flex-col gap-8  bg-white rounded-[10px]">
      {/* HEADER */}
      <header className="px-6 border-b pt-[17px] rounded-t-[10px] border-[#B1A6B2] pb-[10px] bg-alabasterHeader">
        <div>
          <Link
            onClick={goBack}
            className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
          >
            <PAIconArrowLeftGreen />
            <span className="flex items-center text-primaryColor">Go Back</span>
          </Link>
        </div>

        <div class="text-black text-sm font-medium ">Invoices</div>
        <div className="flex justify-between items-center">
          {showSendReminderButton && (
            // <div
            //   onClick={handleRemainderClick}
            //   className="rounded-[5px] cursor-pointer bg-[#07593D] shadow-md text-[#F9F9F9] text-xs font-medium py-[10px] px-[20px]"
            // >
            <div
              onClick={handleRemainderClick}
              className="rounded-[5px] cursor-pointer bg-[#07593D] shadow-md text-[#F9F9F9] text-xs font-medium py-[10px] px-[20px]"
            >
              Send Reminder Now
            </div>
            // </div>
          )}
        </div>
      </header>

      <div className="px-6 ">
        <div className="flex flex-col gap-3">
          <header className="flex justify-between items-center">
            <h1 className="text-[18px] font-bold">Invoice | {invoiceNumber}</h1>
            {/* <button className="rounded-md bg-gray300 py-1.5 px-6">
              <p className="text-black text-center text-xs font-normal">Edit</p>
            </button> */}
            {/* <div
              className={`rounded-md py-1.5 bg-flashWhite px-6 ${getStatusColorClass(
                status
              )}`}
            >
              <p className="text-center text-xs font-bold">
                {status || "NOT PAID"}
              </p>
            </div> */}
          </header>

          <div className="w-full flex items-start justify-between">
            <div className="flex flex-col gap-3 w-1/2">
              <div className="flex gap-x-[5px]">
                <span>Reference No:</span>
                <span>{referenceNumber}</span>
              </div>
              <div className="flex flex-col gap-1">
                <div className="flex gap-x-[50px]">
                  <span className="text-xs w-[97px] font-semibold">
                    Created Date:
                  </span>
                  <span className="text-[#979797] text-sm w-[100px]">
                    {createdDate}
                  </span>
                </div>
                <div className="flex gap-x-[50px]">
                  <span className="text-xs w-[97px] font-semibold">
                    Submitted Date:
                  </span>
                  <span className="text-[#979797] text-sm w-[100px]">
                    {submittedDate}
                  </span>
                </div>
                <div className="flex gap-x-[50px]">
                  <span className="text-xs w-[97px] font-semibold">
                    Approved Date:
                  </span>
                  <span className="text-[#979797] text-sm w-[100px]">
                    {approvedDate}
                  </span>
                </div>
                <div className="flex gap-x-[50px]">
                  <span className="text-xs w-[97px] font-semibold">
                    Due Date:
                  </span>
                  <span className="text-[#979797] text-sm w-[100px]">
                    {dueDate}
                  </span>
                </div>
                <div className="flex gap-x-[50px]">
                  <span className="text-xs w-[97px] font-semibold">
                    Issuance Date:
                  </span>
                  <span className="text-[#979797] text-sm w-[100px]">
                    {issuanceDate}
                  </span>
                </div>
              </div>
            </div>
            {/* STATUS */}
            {/* <div
              className={`rounded-md py-1.5 bg-flashWhite px-6 ${getStatusColorClass(
                status
              )}`}
            >
              <p className="text-center text-xs font-bold">
                {status || "NOT PAID"}
              </p>
            </div> */}

            <div>
              {" "}
              <div className="flex flex-col gap-1">
                <div className="flex gap-x-[50px]">
                  <span className="text-xs w-[97px] font-semibold">
                    Funding Channel
                  </span>
                  <span className="text-[#979797] text-sm w-[100px]">
                    {"Direct Funding Request"}
                  </span>
                </div>
                <div className="flex gap-x-[50px]">
                  <span className="text-xs w-[97px] font-semibold">
                    Fund Amount
                  </span>
                  <span className="text-[#979797] text-sm w-[100px]">
                    {"NGN 23,090,000"}
                  </span>
                </div>
                <div className="flex gap-x-[50px]">
                  <span className="text-xs w-[97px] font-semibold">
                    Final Payment
                  </span>
                  <span className="text-[#979797] text-sm w-[100px]">
                    {"NGN 23,090,000"}
                  </span>
                </div>
                <div className="flex gap-x-[50px]">
                  <span className="text-xs w-[97px] font-semibold">
                    Payment Date:
                  </span>
                  <span className="text-[#979797] text-sm w-[100px]">
                    {dueDate}
                  </span>
                </div>
              </div>
            </div>

            <div class="h-[30px] px-8 py-2 bg-[#ff8f8f]/20 rounded-[5px] justify-center items-center gap-2.5 inline-flex overflow-hidden">
              <div class="text-center text-[#de1515] text-xs font-semibold ">
                {"Overdue"}
              </div>
            </div>
          </div>

          {/* Buyer details and co. */}
          <div className="flex justify-between my-5 border-b-[5px] border-b-[#F6F5FA] py-3">
            <div className="flex flex-col">
              <span className="font-bold text-sm">Buyer details</span>
              <span className="text-[#979797] text-xs">{buyerCompanyName}</span>
            </div>
            <div className="flex flex-col">
              <span className="font-bold text-sm">Payment terms</span>
              <span className="text-[#979797] text-xs">{paymentTerms}</span>
            </div>
            <div className="flex flex-col">
              <span className="font-bold text-sm">Currency</span>
              <span className="text-[#979797] text-xs">{currency}</span>
            </div>
          </div>
        </div>

        {/* Line Items Table */}
        <div className="flex flex-col gap-4 mb-12">
          <header className="border-b-[5px] border-b-[#F6F5FA] pb-3 flex items-center justify-between">
            <h1 className="text-[18px] font-normal">Line Items</h1>
            <p>{numberOfLineItems} Total Items</p>
          </header>
          <div className="w-full overflow-x-auto">
            <table className="w-full overflow-x-auto">
              <thead className="bg-subtleGray w-">
                <tr className="bg-unusualGray p-2.5 ">
                  <td className="text-[#11192A] p-2.5 text-xs font-bold">
                    Item
                  </td>
                  <td className="p-2.5 text-xs font-bold">Description</td>
                  <td className="p-2.5 text-xs font-bold">Unit Price</td>
                  <td className="p-2.5 text-xs font-bold">Quantity</td>
                  <td className="p-2.5 text-xs font-bold">
                    Amount
                    <br />
                    (before tax)
                  </td>
                  <td className="p-2.5 text-xs font-bold">Tax Rate</td>
                  <td className="p-2.5 text-xs font-bold">Tax Amount</td>
                  <td className="p-2.5 text-xs font-bold">Total Price</td>
                </tr>
              </thead>
              <tbody>
                {lineItems.map((item, index) => (
                  <tr key={index} className="py-[3px] px-1.5">
                    <td className="text-xs text-[#11192A] p-2.5">
                      {item.item}
                    </td>
                    <td className="text-xs text-[#11192A] p-2.5">
                      {item.description}
                    </td>
                    <td className="text-xs text-[#11192A] p-2.5">
                      {item.unitPrice}
                    </td>
                    <td className="text-xs text-[#11192A] p-2.5">
                      {item.quantity}
                    </td>
                    <td className="text-xs text-[#11192A] p-2.5">
                      {item.amount}
                    </td>
                    <td className="text-xs text-[#11192A] p-2.5">
                      {item.taxRate}
                    </td>
                    <td className="text-xs text-[#11192A] p-2.5">
                      {item.taxAmount}
                    </td>
                    <td className="text-xs text-[#11192A] p-2.5">
                      {item.totalPrice}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className=" px-6  rounded-b-md  py-5 bg-neutral-50 flex-col justify-center items-start  inline-flex bg-[#fafafa] w-full border-t-[0.2px]  border-t-[#b1a6b2] pb-6">
        <div className="lg:flex md:flex justify-between  w-full sm:block ">
          {/* First left side */}
          <div>
            <section>
              {" "}
              <div class="text-[#222222] text-lg  font-medium border-b-[0.2px]  border-[#b1a6b2]">
                Receiving Funding Account Information
              </div>
              {/* <div class="text-[#222222] text-lg font-semibold ">
                        Receiving Funding Account Information
                      </div> */}
              <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                74*******512
              </div>
              <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                Praise Akinlade
              </div>
            </section>
          </div>

          {/* second division */}

          <div>
            {" "}
            <div class=" flex-col justify-start items-start inline-flex lg:min-w-[290px]">
              <section className=" w-full px-6 pb-2 border-b-[1px] border-black">
                <div className=" flex w-full justify-between">
                  <div class="text-[#222222] text-xs font-semibold ">
                    Subtotal
                  </div>
                  <div class=" text-[#222222] text-xs font-semibold ">
                    10,129,892
                  </div>
                </div>
                <div className=" flex w-full justify-between pt-3 pb-1 border-b-[0.2px] border-b-[#b1a6b2]/30">
                  <div class="text-[#222222]  text-xs font-medium flex  space-x-9 ">
                    <span>VAT%</span>
                    <span>5.6%</span>
                  </div>
                  <div class="text-[#222222] text-xs font-medium ">573,960</div>
                </div>

                <div className=" flex w-full justify-between pt-3 pb-1 border-b-[0.2px] border-b-[#b1a6b2]/30">
                  <div class="text-[#222222]  text-xs font-medium flex  space-x-9 ">
                    <span>Discount%</span>
                    <span>2.4%</span>
                  </div>
                  <div class="text-[#222222] text-xs font-medium ">237,088</div>
                </div>
              </section>

              <div className=" flex w-full justify-between py-2 px-6 border-b-[1px] border-b-black ">
                <div class="text-[#222222] text-lg font-semibold ">
                  Grand Total
                </div>
                <div class="text-[#222222] text-lg font-semibold ">
                  10,473,890
                </div>
              </div>
            </div>
          </div>

          {/* stops */}
        </div>
      </div>

      <AcceptModal
        isOpen={isAcceptModalOpen}
        onClose={() => setIsAcceptModalOpen(false)}
        yes
        message={
          <>
            Would you like to proceed with sending a <br />
            <span className="text-[#07593D] font-bold">
              Payment reminder
            </span>{" "}
            now?
          </>
        }
        onConfirm={handleConfirm}
      />

      {/* <SuccessModal
          isOpen={isSuccessModalOpen}
          onClose={handleSuccessClose}
          message={successMessage}
        /> */}
      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={handleCloseSuccessModal}
        message={successMessage}
      />
    </div>
  );
};

export default InvoiceDetails;
