import { PAIconArrowLeftGray } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { useNavigate } from "react-router-dom";
import TransitionScale from "shared/animation/TransitionScale";
import DirectFundingAcceptModal from "features/invoice/directfunding/modals/DirectFundingAcceptModal";
import { useState, useEffect } from "react";
import DirectFundingBidSelectAccountModal from "features/invoice/directfunding/modals/DirectFundingBidSelectAccountModal";
import BidPinModal from "features/payableFinaceForFunder/CloseMarket/modals/BidPinModal";
import BidProceedModal from "features/payableFinaceForFunder/CloseMarket/modals/BidProceedModal";
import BidLoaderModal from "features/payableFinaceForFunder/CloseMarket/modals/BidLoaderModla";
import DirectFundingPayBidSuccessOnModal from "features/invoice/directfunding/modals/DirectFundingPaySucessOnBoardModal";
import DirectFundingRejectModal from "features/invoice/directfunding/modals/DirectFundingRejectModal";
import ReasonForRequiredModal from "features/invoice/directfunding/modals/ResaonRequired";
import DirectFundingDeclinedModal from "features/invoice/directfunding/modals/DirectFundingDeclined";
import Table from "layout/table/Table";
import ConfirmDirectFundingModalBulk from "features/invoice/directfunding/modals/ConfirmDirectFundingModalBulk2";
import BulkAcceptFirstModal from "features/invoice/directfunding/modals/BulkAcceptFirstModal";
import { useDispatch, useSelector } from "react-redux";
import {
  funderEarlyPaymentViewBulkPendingDetails,
  resetUpdatedBulkEffectiveDataResponse,
} from "appstate/funder/funderSlice";
import { getFunderDiscountRate } from "appstate/rulesandconfig/rulesandconfigSlice";

const EarlyPaymentFundingBulkDetailsAccepted = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const navigateToDetail = (id) =>
    navigate(
      `/payablefinance-earlypayment/funder-early-payment-bulk-inner-details-page/${id}`
    );

  function handleNavigate() {
    let path = -1;
    navigate(path);
  }
  const [acceptModal, setAcceptModal] = useState(false);
  const [acceptFirstModal, setAcceptFirstModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [bidSelectAccountModal, setBidSelectAccountModal] = useState(false);
  const {
    getInvoiceId,
    funderEarlyPaymentViewBulkPendingDetailsData,
    updatedBulkEffectiveDataResponse,
  } = useSelector((state) => state?.funder);
  const [bidProceed, setBidProceed] = useState(false);
  const [bidPin, setBidPin] = useState(false);
  const [bidLoaderModal, setBidLoaderModal] = useState(false);
  const [bidSuccess, setBidSuccess] = useState(false);
  const [reasonFor, setReasonfor] = useState(false);
  const [directRejectModal, setDirectRejectModal] = useState(false);
  const [checkedAccount, setCheckedAccount] = useState(null);
  const [editable, setEditable] = useState(false);

  // const [confirmModal, setConfirmModal] = useState(false);
  const [rateEditable, setRateEditable] = useState(false);
  // const [selectedRow, setSelectedRow] = useState(null);
  const [disCountPer, setDisCountPer] = useState("");
  const [data, setData] = useState([]);
  const [funderRate, setFunderRate] = useState("");

  // const handleRowClick = (row) => {
  //   setSelectedRow(row);
  //   // setDisCountPer(row.discountRate);
  //   setConfirmModal(true);
  //   setEditable(true);
  //   // navigate(`/funder-direct-funding-bulk-inner-details-page/${row.id}`);
  // };

  const effectiveRate = Math.min(data?.discountRate, funderRate);
  useEffect(() => {
    setDisCountPer(effectiveRate?.toFixed(2));
  }, [effectiveRate]);

  useEffect(() => {
    // Clear the updatedBulkDiscountDataResponse when the page is entered
    dispatch(resetUpdatedBulkEffectiveDataResponse());
  }, [dispatch]);

  useEffect(() => {
    const fetchDiscountRate = async () => {
      try {
        const response = await dispatch(getFunderDiscountRate());
        setFunderRate(response?.payload?.data[0]?.discount);
      } catch (error) {
        console.error("Error", error);
      }
    };
    fetchDiscountRate();
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      funderEarlyPaymentViewBulkPendingDetails(getInvoiceId?.earlyPaymentId)
    );
  }, [dispatch, getInvoiceId?.earlyPaymentId]);

  useEffect(() => {
    setData(funderEarlyPaymentViewBulkPendingDetailsData?.data);
  }, [funderEarlyPaymentViewBulkPendingDetailsData]);

  // useEffect(() => {
  //   if (updatedBulkEffectiveDataResponse) {
  //     // Update the state with the values from the API response.
  //     // setDiscountRate(updatedDiscountDataResponse?.data?.discount);
  //     // setNewDiscountAmount(updatedEffectiveDataResponse?.data?.discountedAmount);
  //     // setNewFundableAmount(updatedEffectiveDataResponse?.data?.receivableAmount);
  //     setData(updatedBulkEffectiveDataResponse?.data);
  //   }
  // }, [updatedBulkEffectiveDataResponse]);

  const handleDiscountChange = (e) => {
    setDisCountPer(e.target.value);
    // if (selectedRow) {
    //   setTableData((prevData) =>
    //     prevData.map((item) =>
    //       item.bidAmount === selectedRow.bidAmount
    //         ? { ...item, discountRate: e.target.value }
    //         : item
    //     )
    //   );
    // }
  };

  const handleSave = () => {
    // setTableData((prevData) =>
    //   prevData.map((item) =>
    //     item.bidAmount === selectedRow.bidAmount
    //       ? { ...item, discountRate: disCountPer }
    //       : item
    //   )
    // );
    setConfirmModal(false);
  };

  const handleProceed = () => {
    setBidProceed(false);

    setTimeout(() => {
      setBidPin(true);
    }, 300);
  };

  const handleOnselectModal = () => {
    setBidSelectAccountModal(false);

    setTimeout(() => {
      setBidProceed(true);
    }, 300);
  };
  const handlePinModal = () => {
    setBidPin(false);
    setBidLoaderModal(true);
  };

  const handleYes = () => {
    setAcceptModal(false);
    setBidSelectAccountModal(true);
  };

  const handleYesFirst = () => {
    setAcceptFirstModal(false);
    setAcceptModal(true);
  };
  const handleReject = () => {
    setRejectModal(false);
    setReasonfor(true);
  };

  const handleBidSuccessClose = () => {
    setBidSuccess(false);
    navigate(-1);
  };
  const handleContinue = () => {
    setReasonfor(false);
    setDirectRejectModal(true);
  };
  const handleDeclineClose = () => {
    setDirectRejectModal(false);
    handleNavigate();
  };

  const columns = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Ref Number
        </span>
      ),
      selector: (row) => row.invoiceRef,
    },

    {
      name: "Request Date",
      selector: (row) => row.requestDate,
    },
    {
      name: "Buyer's Rate",
      selector: (row) => row.discountRate,
    },
    {
      name: "Effective Rate",
      cell: (row) => {
        // Check if there's an updated discount rate available in the data
        const updatedRow =
          updatedBulkEffectiveDataResponse?.data?.earlyPaymentDiscountedResponseList?.find(
            (updated) => updated?.invoiceId === row?.invoicesId
          );

        // If there's an updated discount rate, use it; otherwise, fall back to the original discountRate
        const effectiveRate = updatedRow
          ? updatedRow?.discount
          : row?.discountRate;

        return (
          <div
            className="flex gap-2"
            // onClick={(e) => {
            //   e.stopPropagation(); // Prevents row click
            //   handleRowClick(row);
            // }}
          >
            <span>{effectiveRate}</span>
          </div>
        );
      },
    },

    {
      name: "Request Amount",
      selector: (row) => row.requestAmount.toLocaleString(),
    },
    {
      name: "Discount Amount",
      selector: (row) => row.discountedAmount.toLocaleString(),
    },
    {
      name: "Payable Amount",
      selector: (row) => row.payableAmount.toLocaleString(),
    },
  ];

  const [tableData, setTableData] = useState([]);
  const [isBulk, setIsBulk] = useState(false);
  const [reasonData, setReasonData] = useState([]);

  useEffect(() => {
    setIsBulk(true);
  }, []);

  useEffect(() => {
    setTableData(data?.earlyPaymentInvoiceResponse);
  }, [data?.earlyPaymentInvoiceResponse]);

  // useEffect(() => {
  //   if (updatedBulkEffectiveDataResponse) {
  //     setTableData(updatedBulkEffectiveDataResponse?.data?.earlyPaymentDiscountedResponseList);
  //   }
  //   // setTableData(data?.earlyPaymentInvoiceResponse);
  // }, [updatedBulkEffectiveDataResponse])
  useEffect(() => {
    if (
      data?.earlyPaymentInvoiceResponse &&
      updatedBulkEffectiveDataResponse?.data?.earlyPaymentDiscountedResponseList
    ) {
      setTableData((prevData) => {
        // Map through the static data (earlyPaymentInvoiceResponse)
        return prevData?.map((item) => {
          // Find the matching updated data by comparing invoicesId and invoiceId
          const updatedItem =
            updatedBulkEffectiveDataResponse?.data?.earlyPaymentDiscountedResponseList?.find(
              (updated) => updated?.invoiceId === item?.invoicesId
            );

          // If a match is found, update the specific fields (e.g., discountedAmount, payableAmount, receivableAmount)
          if (updatedItem) {
            return {
              ...item, // Keep the static fields intact
              discountedAmount: updatedItem?.discountedAmount, // Update with new discounted amount
              payableAmount: updatedItem?.payableAmount, // Update with new payable amount
              receivableAmount: updatedItem?.receivableAmount, // Update with new receivable amount
              requestAmount: updatedItem?.requestAmount, // Update with new request amount
              effectiveRate: updatedItem?.discount, // Use the discount rate from updated data
            };
          }

          // If no updated item is found, return the original item
          return item;
        });
      });
    }
  }, [updatedBulkEffectiveDataResponse, data?.earlyPaymentInvoiceResponse]);

  return (
    <>
      <TransitionScale>
        <DirectFundingAcceptModal
          mess1={"Would you like to proceed with "}
          blueText={"Approving "}
          mess2={" this Direct "}
          mess3={"Funding request?"}
          isOpen={acceptModal}
          onClose={() => setAcceptModal(false)}
          handleYes={handleYes}
        />
      </TransitionScale>
      <TransitionScale>
        <BulkAcceptFirstModal
          mess1={"Do you agree with the current  "}
          mess2={"discount rate "}
          mess3={" applied to each invoice?"}
          isOpen={acceptFirstModal}
          onClose={() => setAcceptFirstModal(false)}
          handleYes={handleYesFirst}
        />
      </TransitionScale>
      <TransitionScale>
        <DirectFundingRejectModal
          mess1={"Would you like to proceed with "}
          blueText={"Rejecting "}
          mess2={" this Bulk "}
          mess3={" Direct Funding request?"}
          isOpen={rejectModal}
          onClose={() => setRejectModal(false)}
          handleYes={handleReject}
        />
      </TransitionScale>

      <TransitionScale>
        <ConfirmDirectFundingModalBulk
          isOpen={confirmModal}
          onClose={() => setConfirmModal(false)}
          disValue={disCountPer}
          handleChange={handleDiscountChange}
          handleCancel={() => setConfirmModal(false)}
          handleSave={handleSave}
          handleNext={() => setConfirmModal(false)}
          editable={editable}
          setEditable={setEditable}
          onBlur={() => setEditable(false)}
          data={data}
          funderRate={funderRate}
          setRateEditable={setRateEditable}
        />
      </TransitionScale>
      <TransitionScale>
        <DirectFundingBidSelectAccountModal
          isOpen={bidSelectAccountModal}
          onClose={() => setBidSelectAccountModal(false)}
          handleProceed={handleOnselectModal}
          message={"Select which account you want to use for this Request"}
          setCheckedAccount={setCheckedAccount}
        />
      </TransitionScale>

      <TransitionScale>
        {bidLoaderModal && (
          <BidLoaderModal
            isOpen={bidLoaderModal}
            onClose={() => setBidLoaderModal(false)}
            onAction={() => {
              setBidLoaderModal(false);
              setTimeout(() => {
                setBidSuccess(true);
              }, 300);
            }}
            statusText={"Fund Invoice"}
          />
        )}{" "}
      </TransitionScale>

      <TransitionScale>
        {" "}
        <BidPinModal
          isOpen={bidPin}
          onClose={() => setBidPin(false)}
          handleNext={handlePinModal}
          checkedAccount={checkedAccount}
          isBulk={isBulk}
          rateEditable={rateEditable}
        />
      </TransitionScale>
      <TransitionScale>
        {" "}
        <BidProceedModal
          isOpen={bidProceed}
          refNum={" 7364738921"}
          onClose={() => setBidProceed(false)}
          handleYes={handleProceed}
          checkedAccount={checkedAccount}
        />
      </TransitionScale>
      <TransitionScale>
        <DirectFundingPayBidSuccessOnModal
          isOpen={bidSuccess}
          onClose={handleBidSuccessClose}
          message={"Early payment request sent for"}
          sucess={"workflow approval"}
        />
      </TransitionScale>
      {reasonFor && (
        <TransitionScale>
          <ReasonForRequiredModal
            onClose={() => setReasonfor(false)}
            isOpen={setReasonfor}
            onContinue={handleContinue}
            reasonData={reasonData}
            setReasonData={setReasonData}
            earlyPaymentId={data?.earlyPaymentId}
          />
        </TransitionScale>
      )}
      <TransitionScale>
        <DirectFundingDeclinedModal
          isOpen={directRejectModal}
          onClose={handleDeclineClose}
        />
      </TransitionScale>

      <DashboardLayout
        section="Early Payment Request"
        subHeader="Manage all your Early payment requests"
      >
        <header>
          <div
            onClick={handleNavigate}
            className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
          >
            <PAIconArrowLeftGray />
            <span className=" text-[#7A7A7A] text-sm">Go Back</span>
          </div>
        </header>

        <div className="grid lg:grid-cols-[60%,40%] gap-5">
          <TransitionScale className=" pb-5 bg-white  rounded-[10px] mt-5 pt-2 ">
            <section className="  mt-3">
              <div className=" flex justify-between px-6">
                <div class="lg:w-[360px]  sm:w-full h-[117px] flex-col justify-start items-start gap-3 inline-flex">
                  <div class=" justify-between items-center inline-flex mt-2 w-[100%]">
                    <div class="text-[#222222] text-base font-semibold  ">
                      Reference No:
                    </div>
                    <div class="text-[#222222] text-base font-semibold ">
                      | {data?.requestReferenceNumber}
                    </div>
                  </div>
                  <div class="self-stretch  flex-col justify-center items-start gap-1 flex mt-2">
                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Request Date:
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        {data?.requestDate}
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex ">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Total Request amount
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        {data?.totalRequestAmount?.toLocaleString()}
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Total Payable amount
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        {updatedBulkEffectiveDataResponse?.data?.totalPayableAmount?.toLocaleString() ||
                          data?.totalPayableAmount?.toLocaleString()}
                      </div>
                    </div>
                  </div>

                  {/* stop */}
                </div>
                {/* second stage */}
                <div className="flex justify-between items-center mt-10 ">
                  {" "}
                  <div class="h-[37px] py-2 rounded-[5px]  bg-[#ffbe55]/30 justify-center items-center gap-2.5 inline-flex px-4">
                    <div class="text-[#f08000] text-sm font-medium ">
                      Pending Approval
                    </div>
                  </div>
                </div>
              </div>

              {/* stops here */}
              <div className="  px-6  mt-10 ">
                <header className="border-b-[5px] border-b-[#F6F5FA] mt-3  flex justify-between py-1">
                  {/* <h1 className="text-[18px] font-semibold">
                    Supplier/buyer details
                  </h1> */}
                  <div>
                    <div class="text-[#222222] text-lg font-semibold  ">
                      {data?.buyerCompanyName}
                    </div>
                    <div class="text-[#7a7a7a] text-xs font-light  ">
                      Buyer details
                    </div>
                  </div>

                  <div class="h-[43px] flex-col justify-start items-end  inline-flex">
                    <div class="text-[#222222] text-lg font-semibold ">NGN</div>
                    <div class="text-[#7a7a7a] text-xs font-semibold mt-1">
                      Currency
                    </div>
                  </div>
                </header>

                <header className="border-b-[5px] border-b-[#F6F5FA] mt-6  flex justify-between py-1">
                  {/* <h1 className="text-[18px] font-semibold">
                    Supplier/buyer details
                  </h1> */}
                  <div class="text-[#11192a] text-sm font-semibold ">
                    Invoice List
                  </div>
                  <div class="text-[#11192a] text-lg font-light  mr-6">
                    {data?.earlyPaymentInvoiceResponse?.length} Invoices
                  </div>
                </header>

                {/* <div className=" w-full overflow-x-auto mt-3 pb-4"></div> */}

                <div className=" w-full overflow-x-auto mt-3 ">
                  <Table
                    columns={columns}
                    data={tableData}
                    pointer
                    className=""
                    // onRowClicked={(row) => navigateToDetail(row.bidAmount)}
                    onRowClicked={(row) => navigateToDetail(row.bidAmount)}
                    progressPending={false}
                    // progressComponent={<Loading />}
                  />
                </div>
              </div>
            </section>
          </TransitionScale>
          {/* stops the best here */}

          <div className="bg-white rounded-[10px] gap-8  mt-5">
            {/* <FundDetailSide /> */}
            <div class="w-full justify-start items-center gap-4 inline-flex ">
              <div class=" justify-start items-center gap-2.5 flex w-full px-6  py-3">
                <div class="text-black text-xl font-bold  mt-5">
                  Early Payment Details
                </div>
              </div>
            </div>

            <div>
              {" "}
              {/* second */}
              <div className="   pb-4">
                <div class=" justify-between  flex flex-row    px-6 mt-4  ">
                  <div className="flex gap-2 py-2.5">
                    {/* <PAIconCocacola /> */}
                    <div className="flex flex-col">
                      {/* <span className=" text-black text-xs font-normal">
                      Coca Cola
                    </span> */}
                      <span class="text-black text-sm font-normal ">
                        {data?.supplierCompanyName}
                      </span>
                      {/* <span className="text-[#979797]  text-xs">Supplier</span> */}
                      <span class="text-[#949494] text-sm font-normal ">
                        Supplier
                      </span>
                    </div>
                  </div>
                  <div
                    class="py-2.5 flex justify-start items-center gap-1 cursor-pointer"
                    onClick={() => {
                      setConfirmModal(true);
                      setEditable(true);
                    }}
                  >
                    <div class="text-black text-sm font-normal">
                      {updatedBulkEffectiveDataResponse?.data?.discount ||
                        effectiveRate}
                      %
                    </div>
                    <span>
                      <svg
                        width="12"
                        height="13"
                        viewBox="0 0 12 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="ic:outline-mode-edit">
                          <path
                            id="Vector"
                            d="M1.5 11.0002H3.375L8.905 5.47024L7.03 3.59525L1.5 9.12524V11.0002ZM2.5 9.54024L7.03 5.01025L7.49 5.47024L2.96 10.0002H2.5V9.54024ZM9.185 2.14525C9.13874 2.09889 9.0838 2.06212 9.02331 2.03703C8.96282 2.01194 8.89798 1.99902 8.8325 1.99902C8.76702 1.99902 8.70217 2.01194 8.64169 2.03703C8.5812 2.06212 8.52626 2.09889 8.48 2.14525L7.565 3.06025L9.44 4.93525L10.355 4.02025C10.4014 3.97399 10.4381 3.91904 10.4632 3.85856C10.4883 3.79807 10.5012 3.73323 10.5012 3.66775C10.5012 3.60226 10.4883 3.53742 10.4632 3.47693C10.4381 3.41645 10.4014 3.3615 10.355 3.31525L9.185 2.14525Z"
                            fill="#F08000"
                          />
                        </g>
                      </svg>
                    </span>
                  </div>

                  {/* here */}
                </div>
                <div class=" justify-between  flex flex-row    px-6  mt-6 ">
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        NGN{" "}
                        {updatedBulkEffectiveDataResponse?.data?.totalDiscountAmount?.toLocaleString() ||
                          data?.totalDiscountAmount?.toLocaleString()}
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Total Discount amount
                      </div>
                    </div>
                  </div>
                  {/* hd */}
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        {data?.requestDate}
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Request Date
                      </div>
                    </div>
                  </div>{" "}
                </div>
                <div class=" justify-between  flex flex-row    border-b border-[#c2c2c2]/30 px-6  py-3  pb-12 mt-6">
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        NGN{" "}
                        {updatedBulkEffectiveDataResponse?.data?.totalRequestAmount?.toLocaleString() ||
                          data?.totalRequestAmount?.toLocaleString()}
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Total request amount
                      </div>
                    </div>
                  </div>
                  {/* dhdh */}
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        NGN{" "}
                        {updatedBulkEffectiveDataResponse?.data?.totalReceivableAmount?.toLocaleString() ||
                          data?.totalFundableAmount?.toLocaleString()}
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Total Fundable Amount
                      </div>
                    </div>
                  </div>
                </div>
                {/* 2nd */}
                <div class=" justify-between  flex flex-col    border-b border-[#c2c2c2]/30 px-6  pb-10 ">
                  <div class="text-black text-lg font-semibold  mt-6">
                    Note from Supplier:{" "}
                  </div>

                  <div class=" text-[#949494] text-sm font-light  mt-4  leading-6">
                    {data?.description}
                  </div>
                </div>{" "}
                <div class=" justify-between  flex flex-col    px-6  ">
                  <div class="text-black text-lg font-semibold  mt-6">
                    Your decision
                  </div>

                  <div class=" text-[#949494] text-sm font-light  mt-4  leading-6">
                    Early Payment Request: Please review and take action. Accept
                    if approved, or Reject if not suitable. Thank you
                  </div>

                  {/* stops */}
                </div>
                <div className=" grid lg:grid-cols-[47%,47%] gap-4 px-6  mt-7">
                  <button
                    className={`  px-3 text-white justify-center  flex items-center bg-[#07593d] rounded-[5px] py-2 text-sm ${"cursor-pointer"}`}
                    //   onClick={() =>
                    //     navigate("/invoice/invoice/fundingSection/invoiceupload")
                    //   }
                    onClick={() => setAcceptFirstModal(true)}
                  >
                    Accept
                  </button>
                  <button
                    className={`    py-2 px-3  text-[#F08000]  border border-[#F08000] justify-center  flex items-center bg-white rounded-[5px]  text-sm ${"cursor-pointer"}`}
                    //   class=" px-6 py-2 bg-white rounded-[5px] border border-[#de1515] justify-center items-center gap-2.5 inline-flex"
                    onClick={() => setRejectModal(true)}
                  >
                    <div>Reject</div>
                  </button>
                </div>
                {/* stops here */}
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};
export default EarlyPaymentFundingBulkDetailsAccepted;
