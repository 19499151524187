import { PAIconMDISuccess } from "assets/images/svgs";
import React, { useEffect } from "react";

const DirectFundingPayBidSuccessOnModal = ({
  isOpen,
  onClose,
  message,
  sucess,
}) => {
  useEffect(() => {
    let timeoutId;

    if (isOpen) {
      timeoutId = setTimeout(() => {
        onClose();
      }, 3000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isOpen, onClose]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg rounded-[10px] z-[1000]">
      {" "}
      <div className="flex-1 items-center justify-center flex  w-full h-full">
        <div className="flex mt-[200px]  ">
          <div className="bg-[#F08000] w-[147.79px]  h-[160px] flex flex-col items-center justify-center rounded-l-[7px] shadow-lg">
            <PAIconMDISuccess />
          </div>

          <div className="bg-white  flex-col justify-center items-center self-stretch py-[25px] h-[160px] rounded-r-[7px] shadow-lg flex px-16 ">
            <svg
              width="31"
              height="31"
              viewBox="0 0 31 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="icon-park-outline:success">
                <g id="Group">
                  <path
                    id="Vector"
                    d="M15.4997 2.57031L18.8751 5.03264L23.0537 5.02493L24.3369 9.00116L27.722 11.4506L26.4234 15.4217L27.722 19.3928L24.3369 21.8423L23.0537 25.8185L18.8751 25.8108L15.4997 28.2731L12.1243 25.8108L7.94562 25.8185L6.6624 21.8423L3.27734 19.3928L4.57598 15.4217L3.27734 11.4506L6.6624 9.00116L7.94562 5.02493L12.1243 5.03264L15.4997 2.57031Z"
                    stroke="#F08000"
                    stroke-width="2.57028"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    id="Vector_2"
                    d="M11.0039 15.4218L14.2168 18.6347L20.6425 12.209"
                    stroke="#F08000"
                    stroke-width="2.57028"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
              </g>
            </svg>
            <p className="text-[#7A7A7A] text-[13px] text-center mt-[17.6px]">
              {message}
              <p>{sucess}</p>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DirectFundingPayBidSuccessOnModal;
