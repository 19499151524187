import DashboardLayout from "layout/dashboardlayout";
import { useRef, useState } from "react";
import SupplierEarlyPaymentHeader from "./supplierearlypaymentheader";
import Button from "shared/Button";
import Table from "layout/table/Table";
import { PAIconCancel, PAIconOption } from "assets/images/svgs";
import Card from "shared/Card";
import { useNavigate } from "react-router-dom";
import TableIcons from "../../assets/images/svgs/TableIcons.svg";
import Overview from "features/invoice/fundingSection/overview/Overview";

const bulkDummyData = [
  {
    id: 1,
    requestRefNo: "REQ001",
    buyer: "ABC Corp",
    directFundingDate: "2024-10-01",
    noOfInvoices: 5,
    requestAmount: "$10,000",
    discountAmount: "$500",
    receivableAmount: "$9,500",
    status: "Pending Approval",
  },
  {
    id: 2,
    requestRefNo: "REQ002",
    buyer: "XYZ Ltd",
    directFundingDate: "2024-10-05",
    noOfInvoices: 10,
    requestAmount: "$20,000",
    discountAmount: "$1,000",
    receivableAmount: "$19,000",
    status: "Approved",
  },
  {
    id: 3,
    requestRefNo: "REQ003",
    buyer: "DEF Inc",
    directFundingDate: "2024-10-08",
    noOfInvoices: 7,
    requestAmount: "$15,000",
    discountAmount: "$750",
    receivableAmount: "$14,250",
    status: "Rejected",
  },
  {
    id: 4,
    requestRefNo: "REQ004",
    buyer: "DEF Inc",
    directFundingDate: "2024-10-08",
    noOfInvoices: 8,
    requestAmount: "$15,000",
    discountAmount: "$1,000",
    receivableAmount: "$14,000",
    status: "Processing",
  },
];

const dummyData = [
  {
    id: 1,
    requestRefNo: "REQ001",
    invoiceNumber: "INV123",
    buyer: "ABC Corp",
    earlyPaymentDate: "2024-10-01",
    requestAmount: "$10,000",
    discountAmount: "$500",
    receivableAmount: "$9,500",
    status: "Pending Approval",
  },
  {
    id: 2,
    requestRefNo: "REQ002",
    invoiceNumber: "INV124",
    buyer: "XYZ Ltd",
    earlyPaymentDate: "2024-10-05",
    requestAmount: "$20,000",
    discountAmount: "$1,000",
    receivableAmount: "$19,000",
    status: "Approved",
  },
  {
    id: 3,
    requestRefNo: "REQ003",
    invoiceNumber: "INV125",
    buyer: "DEF Inc",
    earlyPaymentDate: "2024-10-08",
    requestAmount: "$15,000",
    discountAmount: "$750",
    receivableAmount: "$14,250",
    status: "rejected",
  },
  {
    id: 4,
    requestRefNo: "REQ004",
    invoiceNumber: "INV126",
    buyer: "DEF Inc",
    earlyPaymentDate: "2024-10-08",
    requestAmount: "$15,000",
    discountAmount: "$10000",
    receivableAmount: "$25,250",
    status: "Processing",
  },
];

const SupplierEarlyPayments = () => {
  const dropdownRef = useRef();
  const [selectedTab, setSelectedTab] = useState("Overview");
  const [dropdown, setDropdown] = useState(false);
  // eslint-disable-next-line
  const [atBottom, setAtBottom] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState("Single Request");
  const navigate = useNavigate();

  const handleDropdown = (row) => {
    if (dropdown === row.id) {
      setDropdown(null);
    } else {
      setDropdown(row.id);
    }
  };

  const requestOptions = [
    { id: 1, name: "Single Request" },
    { id: 2, name: "Bulk Request" },
  ];

  const statusOptions = [
    { id: 1, name: "Approved" },
    { id: 2, name: "Pending Approval" },
    { id: 3, name: "Processing" },
    { id: 4, name: "Rejected" },
  ];

  const onRowClicked = (row) => {
    let path = "/single-early-payment-details";
    navigate(path, { state: { state: row?.status, supplier: "true" } });
  };

  const onBulkEarlyPaymentRowClicked = (row) => {
    let path = "/single-bulk-early-payment-details";
    navigate(path, { state: { state: row?.status } });
  };

  const DirectFundingClicked = (row) => {
    let path = "/single-direct-funding-details";
    navigate(path, { state: { state: row?.status, supplier: "true" } });
  };

  const BulkDirectFundingClicked = (row) => {
    let path = "/single-bulk-direct-funding-details";
    navigate(path, { state: { state: row?.status } });
  };

  const DirectFundingRequestFlow = () => {
    if (selectedRequest === "Single Request") {
      navigate("single-direct-funding-request-flow");
    } else {
      navigate("/bulk-direct-funding-request-flow");
    }
  };

  const handleTabClick = (tabName) => {
    setSelectedTab(tabName);
  };

  const GoToRequestFlow = () => {
    if (selectedRequest === "Single Request") {
      navigate("single-early-payment-request-flow");
    } else {
      navigate("/bulk-early-payment-request-flow");
    }
  };

  const HeaderWithIcon = ({ icon, text }) => (
    <div className="flex items-center">
      <img src={icon} alt="" className="mr-2" />
      <span>{text}</span>
    </div>
  );

  const column = [
    {
      name: <HeaderWithIcon icon={TableIcons} text="Request Ref.No" />,
      selector: (row) => row?.requestRefNo,
    },
    ...(selectedRequest === "Single Request"
      ? [
          {
            name: <HeaderWithIcon icon={TableIcons} text="Invoice Number" />,
            selector: (row) => row?.invoiceNumber,
          },
        ]
      : []),
    {
      name: <HeaderWithIcon icon={TableIcons} text="Buyer" />,
      selector: (row) => row?.buyer,
      width: "15rem",
    },
    {
      name: "Early Payment date",
      selector: (row) => row?.earlyPaymentDate,
    },
    {
      name: "Request Amount",
      selector: (row) => row?.requestAmount,
    },
    {
      name: "Discount amount",
      selector: (row) => row?.discountAmount,
    },
    {
      name: "Receivable Amount",
      selector: (row) => (
        <span
          style={{
            color:
              row.status === "Pending Approval"
                ? "#F08000"
                : row.status === "Approved"
                ? "#2EA923"
                : row.status === "rejected"
                ? "#D40606"
                : row.status === "Processing"
                ? "#5C8AFF"
                : "black",
          }}
        >
          {row?.receivableAmount}
        </span>
      ),
    },
    {
      name: "Status",
      selector: (row) => (
        <span
          style={{
            color:
              row.status === "Pending Approval"
                ? "#F08000"
                : row.status === "Approved"
                ? "#2EA923"
                : row.status === "rejected"
                ? "#D40606"
                : row.status === "Processing"
                ? "#5C8AFF"
                : "black",
          }}
        >
          {row?.status}
        </span>
      ),
    },
    {
      cell: (row) => (
        <div ref={dropdownRef}>
          {dropdown ? (
            <PAIconCancel
              title="Options"
              onClick={() => handleDropdown(row)}
              className="w-[10px]"
            />
          ) : (
            <PAIconOption title="Options" onClick={() => handleDropdown(row)} />
          )}
          {dropdown === row.id && row && (
            <div className="relative">
              <Card
                className={`w-[160px] right-[-10px] ${
                  atBottom && "bottom-[20px]"
                } absolute z-10 drop-shadow-md rounded-md py-[12px]`}
              >
                <div className="flex items-center gap-x-[10px] mb-[12px] pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]">
                  <p className="font-sm font-normal">Preview</p>
                </div>
                <div className="flex items-center gap-x-[10px] mb-[12px] pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]">
                  <p className="font-sm font-normal">Export Request</p>
                </div>
                <div className="flex items-center gap-x-[10px] mt-[10px] pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]">
                  <p className="font-sm font-normal text-[#DE1515]">
                    Cancel Request
                  </p>
                </div>
              </Card>
            </div>
          )}
        </div>
      ),
      width: "5rem",
    },
  ];

  const columnBulk = [
    {
      name: <HeaderWithIcon icon={TableIcons} text="Request Ref No" />,
      selector: (row) => row?.requestRefNo,
    },
    {
      name: <HeaderWithIcon icon={TableIcons} text="Buyer" />,
      selector: (row) => row?.buyer,
      width: "15rem",
    },
    {
      name: selectedTab === "Early Payment" ? "Early Payment Date" : "Direct Funding Date",
      selector: (row) => row?.directFundingDate,
    },    
    {
      name: "No of Invoices",
      selector: (row) => row?.noOfInvoices,
    },
    {
      name: "Request Amount",
      selector: (row) => row?.requestAmount,
    },
    {
      name: "Discount Amount",
      selector: (row) => row?.discountAmount,
    },
    {
      name: "Receivable Amount",
      selector: (row) => (
        <span
          style={{
            color:
              row.status === "Pending Approval"
                ? "#F08000"
                : row.status === "Approved"
                ? "#2EA923"
                : row.status === "Rejected"
                ? "#D40606"
                : row.status === "Processing"
                ? "#5C8AFF"
                : "black",
          }}
        >
          {row?.receivableAmount}
        </span>
      ),
    },
    {
      name: "Status",
      selector: (row) => (
        <span
          style={{
            color:
              row.status === "Pending Approval"
                ? "#F08000"
                : row.status === "Approved"
                ? "#2EA923"
                : row.status === "Rejected"
                ? "#D40606"
                : row.status === "Processing"
                ? "#5C8AFF"
                : "black",
          }}
        >
          {row?.status}
        </span>
      ),
    },
    {
      cell: (row) => (
        <div ref={dropdownRef}>
          {dropdown ? (
            <PAIconCancel
              title="Options"
              onClick={() => handleDropdown(row)}
              className="w-[10px]"
            />
          ) : (
            <PAIconOption title="Options" onClick={() => handleDropdown(row)} />
          )}
          {dropdown === row.id && row && (
            <div className="relative">
              <Card
                className={`w-[160px] right-[-10px] ${
                  atBottom && "bottom-[20px]"
                } absolute z-10 drop-shadow-md rounded-md py-[12px]`}
              >
                <div className="flex items-center gap-x-[10px] mb-[12px] pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]">
                  <p className="font-sm font-normal">Preview</p>
                </div>
                <div className="flex items-center gap-x-[10px] mb-[12px] pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]">
                  <p className="font-sm font-normal">Export Request</p>
                </div>
                <div className="flex items-center gap-x-[10px] mt-[10px] pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]">
                  <p className="font-sm font-normal text-[#DE1515]">
                    Cancel Request
                  </p>
                </div>
              </Card>
            </div>
          )}
        </div>
      ),
      width: "5rem",
    },
  ];

  return (
    <DashboardLayout
      section="Invoice"
      subHeader="Here is an overview of your invoices"
    >
      <div className="mt5 mx6 mb-[17px] w-full cursor-pointer rounded-md bg-white">
        <div className="gap1 flex-col items-center justify-between gap-10 p-4 px-4 md:flex md:flex-row">
          <div className="flex-col gap-10 md:flex md:flex-row">
            <div
              className={
                selectedTab === "Overview"
                  ? "rounded-l-sm rounded-r-sm border-[#07593D] font-bold text-[#07593D] md:mb-[-18px] md:border-b-4"
                  : "text-gray500 text-base font-normal"
              }
              onClick={() => handleTabClick("Overview")}
            >
              Overview
            </div>
            <div
              className={
                selectedTab === "Early Payment"
                  ? "rounded-l-sm rounded-r-sm border-[#07593D] font-bold text-[#07593D] md:mb-[-18px] md:border-b-4"
                  : "text-gray500 my-2 text-base font-normal md:my-0"
              }
              onClick={() => handleTabClick("Early Payment")}
            >
              Early Payment
            </div>
            <div
              className={
                selectedTab === "Direct Funding"
                  ? "rounded-l-sm rounded-r-sm border-[#07593D] font-bold text-[#07593D] md:mb-[-18px] md:border-b-4"
                  : "text-gray500 my-2 text-base font-normal md:my-0"
              }
              onClick={() => handleTabClick("Direct Funding")}
            >
              Directing Funding
            </div>
          </div>
        </div>
      </div>
      {selectedTab === "Overview" && (
        <div>
          <Overview />
        </div>
      )}
      {selectedTab === "Early Payment" && (
        <div>
          <div className="mt-4">
            <SupplierEarlyPaymentHeader
              statusOptions={statusOptions}
              selectedRequest={selectedRequest}
              setSelectedRequest={setSelectedRequest}
              requestOptions={requestOptions}
            />
          </div>
          <br />
          <div>
            <header className="bg-alabasterHeader p-[24px] rounded-t-[10px]">
              <div className="flex justify-between flex-wrap ">
                <div>
                  <p className="text-black text-sm font-medium">
                    Early payment request
                  </p>
                  <p className="text-textColor text-xs font-normal">
                    {selectedRequest === "Single Request"
                      ? "List of all single payment extension requests you've made"
                      : "List of all bulk payment extension requests you've made"}
                  </p>
                </div>
                <div className="flex gap-x-[10px] mt-[10px] md:mt-0 flex-wrap">
                  <Button
                    neutral
                    // disabled={data < 1}
                    onClick={GoToRequestFlow}
                    className="py-[12px] mt-[10px] md:mt-0 px-[24px] border-[0.5px] border-silver bg-[#FFBE55] text-sm !text-black rounded-[5px] font-medium"
                  >
                    <p style={{ color: "white" }}>
                      {selectedRequest === "Single Request"
                        ? "+ Create new single request"
                        : "+ Create new bulk request"}
                    </p>
                  </Button>
                </div>
              </div>
            </header>
            <div className="p-[24px] bg-white rounded-b-[10px]">
              {selectedRequest === "Single Request" &&
                selectedTab === "Early Payment" &&<Table
                columns={column}
                data={dummyData}
                pointer
                onRowClicked={onRowClicked}
                // progressPending={loading}
                // progressComponent={<Loading />}
                // tableHeader
              />}
                 {selectedRequest === "Bulk Request" &&
                selectedTab === "Early Payment" &&<Table
                columns={columnBulk}
                data={bulkDummyData}
                pointer
                onRowClicked={onBulkEarlyPaymentRowClicked}
                // progressPending={loading}
                // progressComponent={<Loading />}
                // tableHeader
              />}
              <br />
            </div>
          </div>
        </div>
      )}
      {/* direct funding section */}
      {selectedTab === "Direct Funding" && (
        <div>
          <div className="mt-4">
            <SupplierEarlyPaymentHeader
              statusOptions={statusOptions}
              header={
                selectedRequest === "Single Request" ? "Approved" : "Sort by"
              }
              selectedRequest={selectedRequest}
              setSelectedRequest={setSelectedRequest}
              requestOptions={requestOptions}
            />
          </div>
          <br />
          <div>
            <header className="bg-alabasterHeader p-[24px] rounded-t-[10px]">
              <div className="flex justify-between flex-wrap ">
                <div>
                  <p className="text-black text-sm font-medium">
                    Direct Funding Request
                  </p>
                  <p className="text-textColor text-xs font-normal">
                    {selectedRequest === "Single Request"
                      ? "List of all single payment extension requests you've made"
                      : "List of all bulk payment extension requests you've made"}
                  </p>
                </div>
                <div className="flex gap-x-[10px] mt-[10px] md:mt-0 flex-wrap">
                  <Button
                    neutral
                    // disabled={data < 1}
                    onClick={DirectFundingRequestFlow}
                    className="py-[12px] mt-[10px] md:mt-0 px-[24px] border-[0.5px] border-silver bg-[#FFBE55] text-sm !text-black rounded-[5px] font-medium"
                  >
                    <p style={{ color: "white" }}>
                      {selectedRequest === "Single Request"
                        ? "+ Create new single request"
                        : "+ Create new bulk request"}
                    </p>
                  </Button>
                </div>
              </div>
            </header>
            <div className="p-[24px] bg-white rounded-b-[10px]">
              {selectedRequest === "Single Request" &&
                selectedTab === "Direct Funding" && (
                  <Table
                    columns={column}
                    data={dummyData}
                    pointer
                    onRowClicked={DirectFundingClicked}
                    // progressPending={loading}
                    // progressComponent={<Loading />}
                    // tableHeader
                  />
                )}
              {selectedRequest === "Bulk Request" &&
                selectedTab === "Direct Funding" && (
                  <Table
                    columns={columnBulk}
                    data={bulkDummyData}
                    pointer
                    onRowClicked={BulkDirectFundingClicked}
                    // progressPending={loading}
                    // progressComponent={<Loading />}
                    // tableHeader
                  />
                )}
              <br />
            </div>
          </div>
        </div>
      )}
    </DashboardLayout>
  );
};
export default SupplierEarlyPayments;
