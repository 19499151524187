import { PAIconSearch } from "assets/images/svgs";
import { motion } from "framer-motion";
import CardFlow from "../common/CardFlow";
// import CardWorkflow from "../components/CardsWorkflow";

function SupplierWorkflowConfig() {
  return (
    <div className="w-full flex flex-col gap-6">
      <section className="w-full flex justify-end items-center gap-2">
        <div className="flex gap-3 border-[0.5px] border-[#CCC]  rounded-[5px] items-center p-2.5 w-full lg:w-[40%]">
          <PAIconSearch />
          <input className="w-full  bg-[#fff] outline-none" type="text" />
        </div>

        <motion.button
          whileTap={{ scale: 0.95 }}
          className="text-white bg-primaryColor px-6 py-3 rounded-[5px] text-sm"
          type="submit"
        >
          Search
        </motion.button>
      </section>

      <main className="w-full max-w-[1200px] px-[3%] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-center items-center gap-4">
        <CardFlow
          title="Invoicing"
          subtitle="Last Updated: 02-05-21"
          text="Workflow Config for Invoice"
          href="/settings/workflow-config/supplier/invoicing"
        />
        <CardFlow
          title="Early Request"
          subtitle="Last Updated: 02-05-21"
          text="Workflow Config for Early Payment Request"
          href="/settings/workflow-config/supplier/early_payment_request"
        />
        <CardFlow
          title="Open Market"
          subtitle="Last Updated: 02-05-21"
          text="Workflow Config for Open Market"
          href="/settings/workflow-config/supplier/open_market"
        />

        <CardFlow
          title="Early Offers"
          subtitle="Last Updated: 02-05-21"
          text="Workflow Config for Early Offers"
          href="/settings/workflow-config/supplier/early_payment_offers"
        />

        <CardFlow
          title="Direct Funding"
          subtitle="Last Updated: 02-05-21"
          text="Workflow Config for Direct Funding"
          href="/settings/workflow-config/supplier/direct_funding"
        />
      </main>
    </div>
  );
}
export default SupplierWorkflowConfig;
