import TransitionOpacity from "shared/animation/TransitionOpacity";
import AcceptedInvoiceCard from "./cards/AcceptedInvoiceCard";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { lockedInvoicesSupplier } from "appstate/openMarket/openMarketSlice";
import LoadingScreen from "shared/LoadingScreen";
import EmptyPage from "features/roleManager/Dashbaord/components/EmptyPage";
import { PAIconSearchGreen } from "assets/images/svgs";
import { motion } from "framer-motion";
import Pagination from "shared/Pagination";

function AcceptedInvoice({ acceptedQuery, setAcceptedQuery }) {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(3);
  const pageSize = 10;

  useEffect(() => {
    dispatch(
      lockedInvoicesSupplier({
        page,
        pageSize,
        searchQuery: acceptedQuery,
      })
    );
  }, [dispatch, page, pageSize, acceptedQuery]);

  const { isLoading, lockedInvoicesSupplierData } = useSelector(
    (state) => state?.openMarket
  );

  const handleSearchInputChange = (event) => {
    const value = event.target.value;
    setAcceptedQuery(value);
  };

  // console.log(getAllSupplierOpenMarketByStatusData);
  return (
    <TransitionOpacity className="flex flex-col gap-5 min-h-[75vh] rounded-[10px] bg-unusualGray  pb-6">
      <div className="flex bg-white py-5 px-6 justify-between items-center w-full">
        <div className="flex flex-col gap-0.5">
          <span className="text-[#1D0220] font-medium text-sm">
            Locked bids from funders{" "}
          </span>

          <span className="text-[#5F4D61] text-xs font-medium">
            List of bid locked and won by your associated funders
          </span>
        </div>
        <div className="flex w-[45%]  items-center gap-3">
          <div className="flex w-full items-center gap-4">
            <div className="flex gap-2.5 w-full bg-white border-[0.5px] border-[#ccc] p-2.5 items-center rounded-[5px]">
              <PAIconSearchGreen />
              <input
                type="text"
                placeholder="Search for funders or request"
                value={acceptedQuery}
                onChange={handleSearchInputChange}
                className="px-2 w-full text-xs flex items-center text-black placeholder:text-black focus:outline-none"
              />
            </div>
          </div>

          <motion.button
            whileTap={{ scale: 0.94 }}
            className="py-2.5 text-white text-sm px-6 rounded-[5px] bg-[#2FA06A]"
          >
            Search
          </motion.button>
        </div>
      </div>
      {isLoading ? (
        <LoadingScreen />
      ) : lockedInvoicesSupplierData?.data?.length > 0 ? (
        <div className="flex px-6 flex-col gap-4 ">
          {lockedInvoicesSupplierData?.data?.map((invoice) => (
            <AcceptedInvoiceCard
              bidOfferId={invoice.offerId}
              lockRate={invoice?.lockRate}
              key={invoice.invoice.openMarketReference}
              id={invoice.openMarketReference}
              invoiceReference={invoice.openMarketReference}
              invoiceNumber={invoice.invoiceNumber}
              buyerCompanyName={invoice.buyerCompanyName}
              funderCompanyName={invoice.funderCompanyName}
              description={invoice.description}
              invoiceAmount={invoice.invoiceAmount}
              lastModifiedDate={invoice.lastModifiedDate}
              dueDate={invoice.invoiceDueDate}
              numberOfBids={invoice.numberOfBids}
            />
          ))}

          <div className="flex justify-center">
            {lockedInvoicesSupplierData?.meta?.totalElements >= pageSize && (
              <Pagination
                page={page}
                startPage={startPage}
                endPage={endPage}
                setEndPage={setEndPage}
                setPage={setPage}
                setStartPage={setStartPage}
                totalPages={lockedInvoicesSupplierData?.meta?.totalElements}
              />
            )}
          </div>
        </div>
      ) : (
        <EmptyPage />
      )}
    </TransitionOpacity>
  );
}

export default AcceptedInvoice;
