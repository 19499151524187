import {
  disbursementFunderPayNow,
  disbursementProgramOverview,
} from "appstate/disbursement/disbursementSlice";
import { allInvoiceById } from "appstate/invoice/invoiceSlice";
import {
  PAIconArrowLeftGreen,
  PAIconIconParkSucess,
  PAIconMDISuccess,
} from "assets/images/svgs";
import EnterPin from "features/disbursement/common/modal/Pin";
import { AnimatePresence, motion } from "framer-motion";
import DashboardLayout from "layout/dashboardlayout";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import TransitionScale from "shared/animation/TransitionScale";
import TransferInfoReceipt from "features/wallet/withdraw/receipt";
import ProgressModal from "shared/progressModal/ProgressModal";
import { formatCapitalize } from "utilities/formatCapitalize";
import { formatDate } from "utilities/helper/dateFormatterSpelt";
import { formatCurrency } from "utilities/helper/formatters";
import useCountdown from "utilities/helper/useCountdown";
import { formatCapitaliseString } from "utilities/helper/formatCapitaliseString";

const DisbursementEarlyPaymentDetailsPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const offerId = searchParams.get("offerId");
  const virtualAccountId = searchParams.get("VAId");
  const { invoiceIdData } = useSelector((state) => state?.invoice);
  const [pinModal, setPinModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [otpInputs, setOtpInputs] = useState(["", "", "", ""]);
  const [hidePin, setHidePin] = useState(false);
  const [showReceipt, setShowReceipt] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const { disbursementFunderReceiptData } = useSelector(
    (state) => state?.disbursement
  );

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => setIsLoading(false), 1000);
  }, []);

  useEffect(() => {
    dispatch(allInvoiceById({ id }));
  }, [dispatch, id]);

  const { timeLeft, isTimeUnderOneHour } = useCountdown(
    formatDate(invoiceIdData?.data?.dueDate)
  );

  // Here
  const handleDownloadSuccessful = () => {
    toast.custom(
      <div className="flex-1 items-center justify-center flex w-full h-full">
        <div className="flex mt-[300px]">
          <div className="bg-primaryColor w-[147.79px] h-[160px] flex flex-col items-center justify-center rounded-l-[7px] shadow-lg">
            <PAIconMDISuccess />
          </div>
          <div className="bg-white flex-col justify-center items-center self-stretch py-[25px] h-[160px] rounded-r-[7px] shadow-lg flex px-16 ">
            <PAIconIconParkSucess />
            <p className="text-dustyGray text-[13px] text-center mt-[17.6px]">
              Funds disbursed successfully
            </p>
          </div>
        </div>
      </div>
    );

    setTimeout(() => {
      toast.dismiss();
      setShowReceipt(true);
      setPinModal(false);
      setHidePin(false);
    }, 500);
  };

  const handlePayNowSingle = (pin) => {
    const pendingToast = toast.loading("Processing your Payment...");
    const body = {
      transactionPin: pin,
      invoiceIds: [offerId],
      virtualAccountId: virtualAccountId,
      disbursementType: "EPO",
    };

    dispatch(disbursementFunderPayNow(body)).then((data) => {
      if (data?.payload?.success) {
        toast.dismiss(pendingToast);
        setHidePin(true);
        setLoading(true);
        setPaymentModal(true);

        setTimeout(() => {
          setLoading(false);
          setTimeout(() => {
            setPaymentModal(false);
            handleDownloadSuccessful();
          }, 300);

          dispatch(
            disbursementProgramOverview({
              programType: "early-payment-offers",
              virtualAccountId,
            })
          );
        }, 3000);
      } else {
        toast.dismiss(pendingToast);
        return toast.error(data?.payload?.message);
      }
    });
  };
  return (
    <DashboardLayout
      section="Disbursement"
      subHeader="Track invoices due for payment disbursement"
    >
      <header className="w-full py-3">
        <span
          onClick={() => navigate(-1)}
          className="flex cursor-pointer items-center font-medium text-sm text-primaryColor gap-2"
        >
          <PAIconArrowLeftGreen /> Go back
        </span>
      </header>
      <main className="flex px-6 flex-col lg:flex-row gap-3 justify-between">
        <TransitionScale className="w-full flex flex-col gap-8 min-h-[90vh] bg-white rounded-[10px]">
          <div className="flex py-3  border-b border-[#F0F0F0] px-3 justify-between rounded-t-[10px] bg-[#FAFAFA]">
            <span>Invoices</span>

            <div className="flex gap-1 items-center">
              <span className="text-sm"> Status: </span>
              <div className="text-primaryColor text-sm font-bold">
                {formatCapitaliseString(invoiceIdData?.data?.status)}
              </div>
            </div>
          </div>
          <header className="flex px-6 flex-col gap-3">
            <section className="flex justify-between  items-center">
              <span className="text-[18px] font-bold">
                Invoice Details | INV-{invoiceIdData?.data?.invoiceNo}
              </span>

              <div className="flex min-w-64 items-end  flex-col gap-1">
                <div>
                  <motion.button
                    onClick={() => {
                      setTimeout(() => {
                        setPinModal(true);
                      }, 200);
                    }}
                    whileTap={{ scale: 0.95 }}
                    className="py-3 px-8 text-xs  text-white rounded bg-primaryColor"
                  >
                    Pay Now
                  </motion.button>
                </div>

                <span
                  className={`${
                    isTimeUnderOneHour ? "text-red" : "text-secondaryColor  "
                  } text-sm font-medium`}
                >
                  {timeLeft.days > 0 ||
                  timeLeft.hours > 0 ||
                  timeLeft.minutes > 0 ||
                  timeLeft.seconds > 0 ? (
                    `${timeLeft.days}d:${timeLeft.hours}h:${timeLeft.minutes}m:${timeLeft.seconds}s`
                  ) : isLoading ? (
                    <span className="text-secondaryColor">Fetching...</span>
                  ) : (
                    <span className="text-red">Expired</span>
                  )}
                </span>
              </div>
            </section>

            <section className="flex justify-between">
              <div className="flex flex-col gap-1 w-[30%]">
                <section className="flex items-center justify-between">
                  <span className="text-[18px] font-light font-light">
                    Reference No
                  </span>
                  <span className="text-[#7a7a7a] text-xs font-semibold">
                    {invoiceIdData?.data?.invoiceRef}
                  </span>
                </section>

                <section className="flex items-center justify-between font-light">
                  <span className="text-sm">Created Date</span>
                  <span className="text-[#7a7a7a] text-sm">
                    {formatDate(invoiceIdData?.data?.createdDate)}
                  </span>
                </section>
                <section className="flex items-center justify-between font-light">
                  <span className="text-sm">Invoice Due Date</span>
                  <span className="text-[#7a7a7a] text-sm">
                    {" "}
                    {formatDate(invoiceIdData?.data?.dueDate)}
                  </span>
                </section>

                <section className="flex items-center justify-between font-light">
                  <span className="text-sm">Invoice Amount</span>

                  <span className="text-[#7a7a7a] text-sm">
                    NGN{" "}
                    {formatCurrency(invoiceIdData?.data?.invoiceAmount ?? 0)}{" "}
                  </span>
                </section>
              </div>

              <div className="flex flex-col gap-1 w-[30%]">
                <section className="flex font-semibold items-center justify-between">
                  Payable Finance Offer
                </section>

                <section className="flex items-center justify-between">
                  <span className="text-sm font-light">Discount Rate</span>
                  <span className="text-[#7a7a7a]  text-xs font-light">
                    {" "}
                    {invoiceIdData?.data?.discount}%
                  </span>
                </section>

                <section className="flex items-center justify-between">
                  <span className="text-sm font-light">Discounted Price</span>
                  <span className="text-[#7a7a7a]  text-xs font-light">
                    NGN {formatCurrency(invoiceIdData?.data?.discountAmount)}
                  </span>
                </section>

                <section className="flex items-center justify-between">
                  <span className="text-sm font-light">Payable Amount</span>
                  <span className="text-[#7a7a7a]  text-xs font-light">
                    NGN {invoiceIdData?.data?.createdDate}
                  </span>
                </section>

                <section className="flex items-center justify-between">
                  <span className="text-sm font-light">Payment Duration</span>
                  <span className="text-[#7a7a7a]  text-xs font-light">
                    {invoiceIdData?.data?.paymentTerms} Days
                  </span>
                </section>

                <section className="flex items-center justify-between">
                  <span className="text-sm font-light">Payment Date</span>
                  <span className="text-[#7a7a7a]  text-xs font-light">
                    20-Jan-2023
                  </span>
                </section>
              </div>
            </section>
          </header>

          <section className="flex px-6 items-center justify-between">
            <div className="flex flex-col gap-2">
              <span className="text-[18px] italic font-semibold">
                {formatCapitalize(invoiceIdData?.data?.buyerName) || "N/A"}
              </span>
              <span className="text-[#7A7A7A] text-xs font-light">
                Buyer Details
              </span>
            </div>
          </section>

          <section className="flex px-6 flex-col gap-4">
            <header className="flex items-center justify-between pb-3 border-b-[5px] border-[#F6F5FA] text-[#11192A]">
              <span className="text-sm">Line Items</span>

              <span className="text-[18px] font-light">
                {invoiceIdData?.data?.items?.length} Total Items{" "}
              </span>
            </header>

            <div className=" w-full overflow-x-auto mt-3 pb-4">
              <table className=" w-full overflow-x-auto">
                <thead className="bg-subtleGray w-full p-6  flex-nowrap whitespace-nowrap  ">
                  <tr className=" bg-unusualGray  px-6 w-full flex-nowrap whitespace-nowrap ">
                    <td class="text-[#11192a] text-xs font-semibold  p-4  flex-nowrap whitespace-nowrap ">
                      Item{" "}
                    </td>
                    <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                      Description{" "}
                    </td>
                    <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                      Unit Price
                    </td>
                    <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                      Quantity
                    </td>
                    <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                      Amount
                      <div> (before tax)</div>
                    </td>
                    <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                      Tax Rate
                    </td>
                    <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                      Tax Amount
                    </td>
                    <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                      Total Price
                    </td>
                  </tr>
                </thead>

                <tbody className="  ">
                  {invoiceIdData?.data?.items?.map((cell, idx) => {
                    return (
                      <tr key={idx} className=" w-full ">
                        <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap ">
                          {cell?.itemName}
                        </td>
                        <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                          {cell?.description}
                        </td>
                        <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                          {formatCurrency(cell?.unitPrice)}
                        </td>
                        <td className="text-[#11192a] text-xs font-semibold mt-2 text-center flex-nowrap whitespace-nowrap">
                          {cell?.quantity}
                        </td>
                        <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                          {formatCurrency(cell?.amount)}
                        </td>
                        <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                          {cell?.taxRate}
                        </td>
                        <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                          {formatCurrency(cell?.taxAmount)}
                        </td>
                        <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                          {formatCurrency(cell?.totalPrice)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className=" px-6   py-5 bg-neutral-50 flex-col justify-center items-start  inline-flex bg-[#fafafa] w-full border-t-[0.2px]  border-t-[#b1a6b2]">
              <div className="lg:flex md:flex justify-between  w-full sm:block ">
                {/* First left side */}
                <div>
                  <section>
                    {" "}
                    <div class="text-[#222222] text-lg  font-medium border-b-[0.2px]  border-[#b1a6b2]">
                      Receiving Funding Account Information
                    </div>
                    {/* <div class="text-[#222222] text-lg font-semibold ">
                        Receiving Funding Account Information
                      </div> */}
                    <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                      {invoiceIdData?.data?.virtualAccount?.accountNumber?.slice(
                        0,
                        2
                      )}{" "}
                      *******{" "}
                      {invoiceIdData?.data?.virtualAccount?.accountNumber?.slice(
                        7,
                        11
                      )}
                    </div>
                    <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                      {formatCapitalize(
                        invoiceIdData?.data?.virtualAccount?.accountName
                      )}
                    </div>
                  </section>
                </div>

                {/* second division */}

                <div>
                  {" "}
                  <div class=" flex-col justify-start items-start inline-flex lg:min-w-[290px]">
                    <section className=" w-full px-6 pb-2 border-b-[1px] border-black">
                      <div className=" flex w-full justify-between">
                        <div class="text-[#222222] text-xs font-semibold ">
                          Subtotal
                        </div>
                        <div class=" text-[#222222] text-xs font-semibold ">
                          {formatCurrency(invoiceIdData?.data?.subTotal)}
                        </div>
                      </div>
                      <div className=" flex w-full justify-between pt-3 pb-1 border-b-[0.2px] border-b-[#b1a6b2]/30">
                        <div class="text-[#222222]  text-xs font-medium flex  space-x-9 ">
                          <span>VAT%</span>
                          <span>{invoiceIdData?.data?.vat}%</span>
                        </div>
                        <div class="text-[#222222] text-xs font-medium ">
                          {formatCurrency(invoiceIdData?.data?.vatAmount)}
                        </div>
                      </div>

                      <div className=" flex w-full justify-between pt-3 pb-1 border-b-[0.2px] border-b-[#b1a6b2]/30">
                        <div class="text-[#222222]  text-xs font-medium flex  space-x-9 ">
                          <span>Discount%</span>
                          <span>{invoiceIdData?.data?.discount}%</span>
                        </div>
                        <div class="text-[#222222] text-xs font-medium ">
                          {formatCurrency(invoiceIdData?.data?.discountAmount)}
                        </div>
                      </div>
                    </section>
                    <div className=" flex w-full justify-between py-2 px-6 border-b-[1px] border-b-black">
                      <div class="text-[#222222] text-lg font-semibold ">
                        Grand Total
                      </div>
                      <div class="text-[#222222] text-lg font-semibold ">
                        {formatCurrency(invoiceIdData?.data?.grandTotal)}
                      </div>
                    </div>
                  </div>
                </div>

                {/* stops */}
              </div>
            </div>
          </section>
        </TransitionScale>
      </main>

      <AnimatePresence>
        <EnterPin
          isOpen={pinModal}
          onClose={() => {
            setPinModal(false);
            setOtpInputs(["", "", "", ""]);
          }}
          hidePin={hidePin}
          otpInputs={otpInputs}
          setOtpInputs={setOtpInputs}
          onSubmit={(pin) => {
            handlePayNowSingle(pin);
          }}
        />
      </AnimatePresence>

      {/* Here */}
      <AnimatePresence>
        {showReceipt && (
          <TransferInfoReceipt
            isOpen={showReceipt}
            disbursement
            onClose={() => {
              setOtpInputs(["", "", "", ""]);
              setPinModal(false);
              setShowReceipt(false);
              navigate(
                `/disbursement/funder/disbursement/transaction-details/${
                  disbursementFunderReceiptData?.data?.transactionId ??
                  disbursementFunderReceiptData?.data?.transactionReference
                }?invId=${
                  disbursementFunderReceiptData?.data?.invoiceId
                }&offerId=${
                  disbursementFunderReceiptData?.data?.programId
                }&channel=${
                  disbursementFunderReceiptData?.data?.fundingChannel
                }`
              );
            }}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {paymentModal && (
          <ProgressModal
            openMarket
            openMarketText={
              loading ? "Transferring funds..." : "Funds Transferred"
            }
          />
        )}
      </AnimatePresence>
    </DashboardLayout>
  );
};

export default DisbursementEarlyPaymentDetailsPage;
