import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  funderOpenMarket,
  funderTransactions,
  getAllFunders,
  searchFunder,
} from "appstate/api/funder";
import {
  getAllClosedMarketByStatus,
  getAllClosedMarketBySingleStatus,
  getAllClosedMarketInvoiceById,
} from "appstate/api/funder/closedmarket";
import { funderDashboard } from "appstate/api/funder/dashboard";
import {
  directFundingApproveOrDecline,
  getNewDirectFunding,
} from "appstate/api/funder/directFunding";
import {
  allFunderNotifications,
  notificationById,
} from "appstate/api/funder/notification";
import {
  fundSelectedPfpInvoice,
  getAllFunderPfpInvoices,
  getFullFunderPfp,
  pfpViewAgreementDetailsById,
} from "appstate/api/funder/pfp";
import {
  acceptToJoinPfp,
  getAllFunderPendingPfp,
  getAllFunderPfp,
  pfpRequestDecline,
  pfpViewById,
  sendRequestToLeavePfp,
} from "appstate/api/funder/pfp";
import {
  funderAcceptBuyerRequest,
  funderAcceptSupplierRequest,
  funderAssociateWithBuyer,
  funderAssociatedBuyers,
  funderAssociatedSuppliers,
  funderDeclineBuyerRequest,
  funderDeclineSupplierRequest,
  funderDisassociateBuyer,
  funderDisassociateSupplier,
  funderSendAssociateRequestToSupplier,
  funderSendOnboardingInviteToBuyers,
  funderSendOnboardingInviteToSupplier,
  pendingRequest,
} from "appstate/api/funder/relationshipManagement";
import { createTransPin } from "appstate/api/transactionPin";

const initialState = {
  associateLoading: false,
  isLoading: false,
  funderDashboardData: [],
  funderTransactionsData: [],
  funderOpenMarketData: [],
  allFundersData: [],
  funderPendingRequestData: [],
  funderAssociatedSuppliersData: [],
  funderAssociatedBuyersData: [],
  associatedBuyerId: null,
  associatedSupplierId: null,
  disAssociateLoading: false,
  declineLoading: false,
  inviteLoading: false,
  notifications: [],
  notification: null,
  allFunderPendingPfpdpData: [],
  pfpId: null,
  notificationId: null,
  viewPfpByIdData: [],
  dataByIdLoading: false,
  allFunderPfpData: [],
  fullFunderPfpData: [],
  viewAgreementDetailsByIdData: null,
  requestToLeaveLoading: null,
  allPfpInvoicesData: null,
  newDirectFundingData: [],
  directFundingInvoiceId: null,
  directFundingApproveLoading: false,
  closeMarketactiveTab: null,
  funderClosedMarketDataByStatus: [],
  funderClosedMarketDataBySingleStatus: [],
  funderClosedMarketDetails: [],
};

export const funderDashboardStats = createAsyncThunk(
  "funder/funderDashboardStats",
  funderDashboard
);

export const getFunderTransactions = createAsyncThunk(
  "funder/getFunderTransactions",
  funderTransactions
);

export const getFunderOpenMarket = createAsyncThunk(
  "funder/getFunderOpenMarket",
  funderOpenMarket
);

export const allFunders = createAsyncThunk("funder/allFunders", getAllFunders);

export const funderSearch = createAsyncThunk(
  "funder/funderSearch",
  searchFunder
);

export const viewFunderPendingRequest = createAsyncThunk(
  "funder/viewFunderPendingRequest",
  pendingRequest
);

export const acceptSupplierRequestFunder = createAsyncThunk(
  "funder/acceptSupplierRequestFunder",
  funderAcceptSupplierRequest
);

export const acceptBuyerRequestFunder = createAsyncThunk(
  "funder/acceptBuyerRequestFunder",
  funderAcceptBuyerRequest
);

export const getFunderAssociatedSuppliers = createAsyncThunk(
  "funder/getFunderAssociatedSuppliers ",
  funderAssociatedSuppliers
);

export const associateFunderWithSupplier = createAsyncThunk(
  "funder/associateFunderWithSupplier",
  funderSendAssociateRequestToSupplier
);

export const funderOnboardingInviteToSupplier = createAsyncThunk(
  "funder/funderOnboardingInviteToSupplier",
  funderSendOnboardingInviteToSupplier
);

export const funderOnboardingInviteToBuyers = createAsyncThunk(
  "funder/funderOnboardingInviteToBuyers",
  funderSendOnboardingInviteToBuyers
);

export const associateFunderWithBuyer = createAsyncThunk(
  "funder/associateFunderWithBuyer",
  funderAssociateWithBuyer
);

export const getFunderAssociatedBuyers = createAsyncThunk(
  "funder/getFunderAssociatedBuyers",
  funderAssociatedBuyers
);

export const declineSupplierRequestFunder = createAsyncThunk(
  "funder/declineSupplierRequestFunder",
  funderDeclineSupplierRequest
);

export const declineBuyerRequestFunder = createAsyncThunk(
  "funder/declineBuyerRequestFunder",
  funderDeclineBuyerRequest
);

export const disassociateBuyerFromFunder = createAsyncThunk(
  "funder/disassociateBuyerFromFunder",
  funderDisassociateBuyer
);

export const disassociateSupplierFromFunder = createAsyncThunk(
  "funder/disassociateSupplierFromFunder",
  funderDisassociateSupplier
);

export const getAllFunderNotifications = createAsyncThunk(
  "funder/getAllFunderNotifications",
  allFunderNotifications
);

export const getFunderNotificationById = createAsyncThunk(
  "funder/getFunderNotificationById",
  notificationById
);

export const allFunderPendingPfpRequest = createAsyncThunk(
  "funder/allFunderPendingPfpRequest",
  getAllFunderPendingPfp
);

export const viewPfpById = createAsyncThunk("funder/viewPfpById", pfpViewById);

export const acceptPfp = createAsyncThunk("funder/acceptPfp", acceptToJoinPfp);

export const declinePfpRequest = createAsyncThunk(
  "funder/declinePfpRequest",
  pfpRequestDecline
);

export const fundPfpInvoice = createAsyncThunk(
  "funder/fundPfpInvoice",
  fundSelectedPfpInvoice
);

export const allFunderPfp = createAsyncThunk(
  "funder/allFunderPfp",
  getAllFunderPfp
);

export const fullFunderPfp = createAsyncThunk(
  "funder/fullFunderPfp",
  getFullFunderPfp
);

export const viewAgreementDetailsById = createAsyncThunk(
  "funder/viewAgreementDetailsById",
  pfpViewAgreementDetailsById
);

export const requestToLeavePfp = createAsyncThunk(
  "funder/requestToLeavePfp",
  sendRequestToLeavePfp
);

export const allFunderPfpInvoices = createAsyncThunk(
  "funder/allFunderPfpInvoices ",
  getAllFunderPfpInvoices
);

export const allNewDirectFunding = createAsyncThunk(
  "funder/allNewDirectFunding",
  getNewDirectFunding
);

export const approveOrDeclineDirectFunding = createAsyncThunk(
  "funder/approveOrDeclineDirectFunding",
  directFundingApproveOrDecline
);
export const funderCreateTransPin = createAsyncThunk(
  "funder/createTransPin",
  createTransPin
);

export const funderFetchAllClosedMarketByStatus = createAsyncThunk(
  "funder/getAllClosedMarketByStatus",
  getAllClosedMarketByStatus
);
export const funderFetchAllClosedMarketBySingleStatus = createAsyncThunk(
  "funder/getAllClosedMarketBySingleStatus",
  getAllClosedMarketBySingleStatus
);
export const funderFetchInvoiceDetails = createAsyncThunk(
  "funder/getAllClosedMarketInvoiceById",
  getAllClosedMarketInvoiceById
);

//closedmarket Transactions API Call

export const funderSlice = createSlice({
  name: "funder",
  initialState,
  reducers: {
    getFunderAssociatedBuyerId: (state, action) => {
      state.associatedBuyerId = action.payload;
    },
    setClosedMarketActiveTab: (state, action) => {
      state.closeMarketactiveTab = action.payload;
    },
    getFunderAssociatedSupplierId: (state, action) => {
      state.associatedSupplierId = action.payload;
    },
    getPfpId: (state, action) => {
      state.pfpId = action.payload;
    },
    getNotificationId: (state, action) => {
      state.notificationId = action.payload;
    },
    getDirectFundingInvoiceId: (state, action) => {
      state.directFundingInvoiceId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(funderDashboardStats.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(funderDashboardStats.fulfilled, (state, action) => {
      state.isLoading = false;
      state.funderDashboardData = action.payload;
    });
    builder.addCase(funderDashboardStats.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getFunderTransactions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getFunderTransactions.fulfilled, (state, action) => {
      state.isLoading = false;
      state.funderTransactionsData = action.payload;
    });
    builder.addCase(getFunderTransactions.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getFunderOpenMarket.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getFunderOpenMarket.fulfilled, (state, action) => {
      state.isLoading = false;
      state.funderOpenMarketData = action.payload;
    });
    builder.addCase(getFunderOpenMarket.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(allFunders.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allFunders.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allFundersData = action.payload;
    });
    builder.addCase(allFunders.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(funderSearch.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(funderSearch.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(funderSearch.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(viewFunderPendingRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(viewFunderPendingRequest.fulfilled, (state, action) => {
      state.isLoading = false;
      state.funderPendingRequestData = action.payload;
    });
    builder.addCase(viewFunderPendingRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(acceptSupplierRequestFunder.pending, (state) => {
      state.associateLoading = true;
    });
    builder.addCase(acceptSupplierRequestFunder.fulfilled, (state) => {
      state.associateLoading = false;
    });
    builder.addCase(acceptSupplierRequestFunder.rejected, (state) => {
      state.associateLoading = false;
    });
    builder.addCase(getFunderAssociatedSuppliers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getFunderAssociatedSuppliers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.funderAssociatedSuppliersData = action.payload;
    });
    builder.addCase(getFunderAssociatedSuppliers.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(associateFunderWithSupplier.pending, (state) => {
      state.associateLoading = true;
    });
    builder.addCase(associateFunderWithSupplier.fulfilled, (state) => {
      state.associateLoading = false;
    });
    builder.addCase(associateFunderWithSupplier.rejected, (state) => {
      state.associateLoading = false;
    });
    builder.addCase(funderOnboardingInviteToSupplier.pending, (state) => {
      state.inviteLoading = true;
    });
    builder.addCase(funderOnboardingInviteToSupplier.fulfilled, (state) => {
      state.inviteLoading = false;
    });
    builder.addCase(funderOnboardingInviteToSupplier.rejected, (state) => {
      state.inviteLoading = false;
    });
    builder.addCase(associateFunderWithBuyer.pending, (state) => {
      state.associateLoading = true;
    });
    builder.addCase(associateFunderWithBuyer.fulfilled, (state) => {
      state.associateLoading = false;
    });
    builder.addCase(associateFunderWithBuyer.rejected, (state) => {
      state.associateLoading = false;
    });
    builder.addCase(funderOnboardingInviteToBuyers.pending, (state) => {
      state.inviteLoading = true;
    });
    builder.addCase(funderOnboardingInviteToBuyers.fulfilled, (state) => {
      state.inviteLoading = false;
    });
    builder.addCase(funderOnboardingInviteToBuyers.rejected, (state) => {
      state.inviteLoading = false;
    });
    builder.addCase(getFunderAssociatedBuyers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getFunderAssociatedBuyers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.funderAssociatedBuyersData = action.payload;
    });
    builder.addCase(getFunderAssociatedBuyers.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(acceptBuyerRequestFunder.pending, (state) => {
      state.associateLoading = true;
    });
    builder.addCase(acceptBuyerRequestFunder.fulfilled, (state) => {
      state.associateLoading = false;
    });
    builder.addCase(acceptBuyerRequestFunder.rejected, (state) => {
      state.associateLoading = false;
    });
    builder.addCase(declineSupplierRequestFunder.pending, (state) => {
      state.declineLoading = true;
    });
    builder.addCase(declineSupplierRequestFunder.fulfilled, (state) => {
      state.declineLoading = false;
    });
    builder.addCase(declineSupplierRequestFunder.rejected, (state) => {
      state.declineLoading = false;
    });
    builder.addCase(declineBuyerRequestFunder.pending, (state) => {
      state.declineLoading = true;
    });
    builder.addCase(declineBuyerRequestFunder.fulfilled, (state) => {
      state.declineLoading = false;
    });
    builder.addCase(declineBuyerRequestFunder.rejected, (state) => {
      state.declineLoading = false;
    });
    builder.addCase(disassociateBuyerFromFunder.pending, (state) => {
      state.disAssociateLoading = true;
    });
    builder.addCase(disassociateBuyerFromFunder.fulfilled, (state) => {
      state.disAssociateLoading = false;
    });
    builder.addCase(disassociateBuyerFromFunder.rejected, (state) => {
      state.disAssociateLoading = false;
    });
    builder.addCase(disassociateSupplierFromFunder.pending, (state) => {
      state.disAssociateLoading = true;
    });
    builder.addCase(disassociateSupplierFromFunder.fulfilled, (state) => {
      state.disAssociateLoading = false;
    });
    builder.addCase(disassociateSupplierFromFunder.rejected, (state) => {
      state.disAssociateLoading = false;
    });
    builder.addCase(getAllFunderNotifications.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllFunderNotifications.fulfilled, (state, action) => {
      state.isLoading = false;
      state.notifications = action.payload;
    });
    builder.addCase(getAllFunderNotifications.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getFunderNotificationById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getFunderNotificationById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.notification = action.payload;
    });
    builder.addCase(getFunderNotificationById.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(allFunderPendingPfpRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allFunderPendingPfpRequest.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allFunderPendingPfpdpData = action.payload;
    });
    builder.addCase(allFunderPendingPfpRequest.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(viewPfpById.pending, (state) => {
      state.dataByIdLoading = true;
    });
    builder.addCase(viewPfpById.fulfilled, (state, action) => {
      state.dataByIdLoading = false;
      state.viewPfpByIdData = action.payload;
    });
    builder.addCase(viewPfpById.rejected, (state) => {
      state.dataByIdLoading = false;
    });

    builder.addCase(acceptPfp.pending, (state) => {
      state.associateLoading = true;
    });
    builder.addCase(acceptPfp.fulfilled, (state) => {
      state.associateLoading = false;
    });
    builder.addCase(acceptPfp.rejected, (state) => {
      state.associateLoading = false;
    });

    builder.addCase(declinePfpRequest.pending, (state) => {
      state.associateLoading = true;
    });
    builder.addCase(declinePfpRequest.fulfilled, (state) => {
      state.associateLoading = false;
    });
    builder.addCase(declinePfpRequest.rejected, (state) => {
      state.associateLoading = false;
    });

    builder.addCase(allFunderPfp.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allFunderPfp.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allFunderPfpData = action.payload;
    });
    builder.addCase(allFunderPfp.rejected, (state) => {
      state.isLoading = false;
    });

    // FULL FUNDER PFP
    builder.addCase(fullFunderPfp.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fullFunderPfp.fulfilled, (state, action) => {
      state.isLoading = false;
      state.fullFunderPfpData = action.payload;
    });
    builder.addCase(fullFunderPfp.rejected, (state) => {
      state.isLoading = false;
    });

    // VIEW AGREEMENT DETAILS PFP
    builder.addCase(viewAgreementDetailsById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(viewAgreementDetailsById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.viewAgreementDetailsByIdData = action.payload;
    });
    builder.addCase(viewAgreementDetailsById.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(requestToLeavePfp.pending, (state) => {
      state.requestToLeaveLoading = true;
    });
    builder.addCase(requestToLeavePfp.fulfilled, (state, action) => {
      state.requestToLeaveLoading = false;
    });
    builder.addCase(requestToLeavePfp.rejected, (state) => {
      state.requestToLeaveLoading = false;
    });
    // FUNDER PFP
    builder.addCase(allFunderPfpInvoices.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allFunderPfpInvoices.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allPfpInvoicesData = action.payload;
    });
    builder.addCase(allFunderPfpInvoices.rejected, (state) => {
      state.isLoading = false;
    });
    // FUND PFP
    builder.addCase(fundPfpInvoice.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fundPfpInvoice.fulfilled, (state, action) => {
      state.isLoading = false;
      // state.allPfpInvoicesData = action.payload;
    });
    builder.addCase(fundPfpInvoice.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(allNewDirectFunding.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allNewDirectFunding.fulfilled, (state, action) => {
      state.isLoading = false;
      state.newDirectFundingData = action.payload;
    });
    builder.addCase(allNewDirectFunding.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(approveOrDeclineDirectFunding.pending, (state) => {
      state.directFundingApproveLoading = true;
    });
    builder.addCase(
      approveOrDeclineDirectFunding.fulfilled,
      (state, action) => {
        state.directFundingApproveLoading = false;
      }
    );
    builder.addCase(approveOrDeclineDirectFunding.rejected, (state) => {
      state.directFundingApproveLoading = false;
    });
    builder.addCase(funderCreateTransPin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(funderCreateTransPin.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(funderCreateTransPin.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(funderFetchAllClosedMarketByStatus.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      funderFetchAllClosedMarketByStatus.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.funderClosedMarketDataByStatus = action.payload;
      }
    );
    builder.addCase(funderFetchAllClosedMarketByStatus.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(
      funderFetchAllClosedMarketBySingleStatus.pending,
      (state) => {
        state.isLoading = true;
      }
    );
    builder.addCase(
      funderFetchAllClosedMarketBySingleStatus.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.funderClosedMarketDataBySingleStatus = action.payload;
      }
    );
    builder.addCase(
      funderFetchAllClosedMarketBySingleStatus.rejected,
      (state) => {
        state.isLoading = false;
      }
    );

    // builder.addCase(
    //   funderFetchAllClosedMarketBySingleStatus.pending,
    //   (state) => {
    //     state.isLoading = true;
    //   }
    // );
    // builder.addCase(
    //   funderFetchAllClosedMarketBySingleStatus.fulfilled,
    //   (state, action) => {
    //     state.isLoading = false;
    //     state.funderClosedMarketDataBySingleStatus = action.payload;
    //   }
    // );
    // builder.addCase(
    //   funderFetchAllClosedMarketBySingleStatus.rejected,
    //   (state) => {
    //     state.isLoading = false;
    //   }
    // );

    builder.addCase(funderFetchInvoiceDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(funderFetchInvoiceDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.funderClosedMarketDetails = action.payload;
    });
    builder.addCase(funderFetchInvoiceDetails.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const {
  getFunderAssociatedBuyerId,
  getFunderAssociatedSupplierId,
  getPfpId,
  getNotificationId,
  getDirectFundingInvoiceId,
  setClosedMarketActiveTab,
} = funderSlice.actions;
export const selectClosedMarketActiveTab = (state) =>
  state.funder.closeMarketactiveTab;

export default funderSlice.reducer;
