import React, { useEffect, useState } from "react";

const PayProgressModal = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev < 100) {
          return prev + 1;
        } else {
          clearInterval(interval);
          return 100;
        }
      });
    }, 25);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="max-w-sm w-full mx-auto bg-white shadow-md rounded-lg overflow-hidden">
        <div className="px-5 pb-5 ">
          <div className="w-full bg-zinc-200 rounded-full h-2.5 mt-5">
            <div
              style={{
                width: `${progress}%`,
                transition: "width 0.2s ease-in-out",
              }}
              className={`${
                progress < 55 ? "bg-secondaryColor" : "bg-primaryColor"
              } h-2.5 rounded-full`}
            />
          </div>
          <div className="flex justify-between items-center mt-3 w-full">
            <span
              className={`text-sm text-zinc-600 italic ${
                progress < 100 ? " font-semibold" : ""
              }`}
            >
              {progress < 100 ? "Transferring of funds..." : "Funds transferred"}
            </span>
            <span className="text-sm text-[#7A7A7A]">{progress}% </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayProgressModal;
