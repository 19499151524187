// import {
//   PAIconCocacola,
//   PAIconDownload,
//   PAIconModelOne,
// } from "assets/images/svgs";

import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AcceptModal from "shared/AcceptModal";
import SuccessModal from "shared/SuccessModal";

function BulkDirectFundingInvoice({ invoiceByIdData }) {
  const invoiceIdData = {
    data: {
      items: [
        {
          id: "1",
          invoiceNumber: "INV-2024-001",
          dueDate: "2024-12-30",
          requestAmount: "200,000",
          discountRate: "10%",
          discountAmount: "20,000",
          receivableAmount: "180,000",
        },
        {
          id: "2",
          invoiceNumber: "INV-2024-002",
          dueDate: "2025-01-05",
          requestAmount: "150,000",
          discountRate: "5%",
          discountAmount: "7,500",
          receivableAmount: "142,500",
        },
        {
          id: "3",
          invoiceNumber: "INV-2024-003",
          dueDate: "2025-01-10",
          requestAmount: "300,000",
          discountRate: "15%",
          discountAmount: "45,000",
          receivableAmount: "255,000",
        },
      ],
      invoiceNumber: "INV-2024",
      createdDate: "2024-12-23",
      submittedDate: "2024-12-24",
      approvedDate: "2024-12-25",
      dueDate: "2024-12-26",
      issuanceDate: "2024-12-27",
      requestAmount: "1,000,000",
      requestDate: "2024-12-28",
      paymentDate: "2024-12-29",
      buyerDetails: "Buyer Company",
      paymentTerms: "Net 30",
      currency: "NGN",
      lineItems: { numberOfLineItems: 3 },
      subTotal: "500.00",
      discount: 10,
      discountAmount: "50.00",
      vat: 5,
      vatAmount: "25.00",
      grandTotal: "475.00",
    },
  };

  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  const handleConfirm = () => {
    setIsAcceptModalOpen(false);
    setIsSuccessModalOpen(true);
    setSuccessMessage("Payment remainders sent successfully");
  };

  const handleSuccessClose = () => {
    setIsSuccessModalOpen(false);
    navigate(-1);
  };

  return (
    <motion.section
      initial={{
        x: 20,
      }}
      animate={{
        x: 0,
      }}
      exit={{
        x: -20,
      }}
      transition={{
        delay: 0.6,
      }}
      className="flex flex-col gap8 px-6 pt-7 pb-24 w-[60%] semilg:w-[55%] bg-white rounded-[10px]"
    >
      <div className="mb-2 flex flex-col gap-3 ">
        <header className="flex items-center justify-between">
          <h1 className=" text-[18px] font-bold">
            Reference No | {invoiceByIdData?.data?.invoiceNumber || "R3456X89Y"}
          </h1>
          <button
            className="bg-primaryColor px-4 py-2 text-white rounded"
            onClick={() => setIsAcceptModalOpen(true)}
          >
            Send Remainder Now
          </button>
        </header>

        <main className="w-full flex items-center justify-between">
          <div className="flex flex-col gap-3 w-2/5">
            <div className="flex flex-col gap-1">
              <div className="flex justify-between">
                <span className=" font-semibold text-xs">Request Date:</span>
                <span className="text-[#979797]  text-xs">
                  {invoiceByIdData?.data?.requestDate.slice(0, 10) ||
                    "23-Dec-2024"}
                </span>
              </div>
              <div className="flex justify-between">
                <span className=" font-semibold text-xs">
                  Total Request amount:
                </span>
                <span className="text-[#979797]  text-xs">
                  {invoiceByIdData?.data?.totalRequestAmount || "300,000"}
                </span>
              </div>

              <div className="flex justify-between">
                <span className=" font-semibold text-xs">
                  Total Invoice amount:
                </span>
                <span className="text-[#979797]  text-xs">
                  {invoiceByIdData?.data?.totalInvoiceAmount || "200, 000"}
                </span>
              </div>
            </div>
          </div>

          {/* STATUS */}
          <div className="rounded-md py-1.5 bg-[#F080000F] px-6">
            <p className="text-center text-secondaryColor text-xs font-bold">
              {invoiceByIdData?.data?.status || "Pending Payment"}
            </p>
          </div>
        </main>
      </div>

      {/* Buyer details and co. */}
      <div className="flex justify-between my-5 border-b-[5px] border-b-[#F6F5FA] py-3">
        <div className="flex flex-col">
          <span className="font-bold text-sm">
            {invoiceByIdData?.data?.buyerDetails || "Buyer"}
          </span>
          <span className="text-[#979797] text-xs">Buyer details</span>
        </div>
        {/* <div className="flex flex-col">
          <span className="font-bold text-sm">
            {invoiceByIdData?.data?.paymentTerms || "20"}
          </span>
          <span className="text-[#979797] text-xs">Payment</span>
        </div> */}
        <div className="flex flex-col">
          <span className="font-bold text-sm ">
            {invoiceByIdData?.data?.currency || "NGN"}
          </span>
          <span className="text-[#979797] text-xs">Currency</span>
        </div>
      </div>

      <div className="flex mb-8 flex-col gap-4">
        <header className="border-b-[5px] border-b-[#F6F5FA] pb-3 flex items-center justify-between">
          <h1 className="text-[18px] font-normal">Line Items</h1>
          <p>
            {invoiceByIdData?.data?.lineItems?.numberOfLineItems || "5"} Total
            Items
          </p>
        </header>

        <div className=" w-full overflow-x-auto">
          <table className=" w-full overflow-x-auto">
            <thead className="bg-subtleGray w-">
              <tr className="bg-unusualGray p-2.5 ">
                <td className="text-[#11192A] p-2.5 text-xs font-bold">
                  Invoice Number
                </td>
                <td className="p-2.5 text-xs font-bold">Due Date</td>
                <td className="p-2.5 text-xs font-bold">Request Amount</td>
                <td className="p-2.5 text-xs font-bold">Discount Rate</td>
                <td className="p-2.5 text-xs font-bold">Discount Amount</td>
                <td className="p-2.5 text-xs font-bold">Receivable Amount</td>
              </tr>
            </thead>
            <tbody>
              {invoiceIdData?.data?.items?.map((item) => (
                <tr
                  key={item.id}
                  className=" py-[3px] px-1.5 cursor-pointer"
                  onClick={() => navigate(`/collections/invoice/${item.id}`)}
                >
                  <td className=" text-xs text-[#11192a] p-2.5">
                    {item?.invoiceNumber}
                  </td>
                  <td className=" text-xs text-[#11192a] p-2.5">
                    {item?.dueDate}
                  </td>
                  <td className=" text-xs text-[#11192a] p-2.5">
                    {item?.requestAmount}
                  </td>
                  <td className=" text-xs text-[#11192a] p-2.5">
                    {item?.discountRate}
                  </td>
                  <td className=" text-xs text-[#11192a] p-2.5">
                    {item?.discountAmount}
                  </td>
                  <td className=" text-xs text-[#11192a] p-2.5">
                    {item?.receivableAmount}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <AnimatePresence>
        <AcceptModal
          isOpen={isAcceptModalOpen}
          onClose={() => setIsAcceptModalOpen(false)}
          yes
          message={
            <>
              Would you like to proceed with sending a <br />
              <span className="text-[#07593D] font-bold">
                Payment reminder
              </span>{" "}
              now?
            </>
          }
          onConfirm={handleConfirm}
        />

        <SuccessModal
          isOpen={isSuccessModalOpen}
          onClose={handleSuccessClose}
          message={successMessage}
        />
      </AnimatePresence>
    </motion.section>
  );
}

export default BulkDirectFundingInvoice;
