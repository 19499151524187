import {
  // PAIconAdd,
  // PAIconExport,
  PAIconInventoryArrowLeft,
  PAIconPendingRequest,
  // PAIconUseradd,
} from "assets/images/svgs";
// eslint-disable-next-linef
import React from "react";
import { useNavigate } from "react-router-dom";
import InputField from "shared/InputField";
import styled from "styled-components";
import TableHeaderSelect from "./TableHeaderSelect";
import Button from "shared/Button";
import TierModal from "shared/tierModal";
// import OrangeIcon from "../../assets/images/svgs/OrangeIcon.svg";
import UserAdd from "../../assets/images/svgs/useradd.svg";
import Search from "../../assets/images/svgs/search-normal.svg";
import OrangeUserAdd from "../../assets/images/svgs/orangeusericon.svg";

const TableHeader = ({
  handleChange,
  search,
  productName,
  setProductName,
  total,
  path,
  buyer,
  title,
  options,
  accounts,
  details,
  inviteBtn,
  setOpen,
  funder,
  associateBtn,
  pendingPath,
  btnName,
  showBackBtn,
  payableFinance,
  tier,
  data,
  download,
  accept,
  handleAccept,
  showSelectContainer,
  approval,
  handleApproval,
  approvalName,
  active,
  fetchTiers,
  openTierModal,
  setOpenTierModal,
  edit,
  setEdit,
  tierId,
  isDetails,
  setIsDetails,
  getId,
  handleSearch,
  searchTerm,
  setSearchTerm,
  buyerFunder,
  funderMember,
  supplierFunder,
  funderSupplier,
  funderBuyer,
  pfp,
  df,
  funderPayableFinance
}) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(path, { state: "earlyPayments" });
  };
  const handlePendingRequest = () => {
    navigate("buyer-pending-supplier-request");
  };

  const handlePendingRequestFunder = () => {
    navigate("buyer-pending-funder-request");
  };

  const handleAssociationRequest = () => {
    navigate("buyer-association-supplier-request");
  };

  const handleBuyerAssociationRequestFunder = () => {
    navigate("buyer-association-funder-request");
  };

  const handleSupplierAssociationRequestBuyer = () => {
    navigate("supplier-pending-buyer-request");
  };

  const handleAssociateBuyerBySupplier = () => {
    navigate("supplier-associate-buyer-request");
  };

  const handlePendingSupplierByFunder = () => {
    navigate("supplier-pending-funder-request");
  };

  const handleAssociationSupplierByFunder = () => {
    navigate("supplier-association-funder-request");
  };

  const handlePendingRequestFunderSupplier = () => {
    navigate("funder-pending-request-supplier");
  };

  const handlePendingInvitesFunderSupplier = () => {
    navigate("funder-pending-invites-supplier");
  };

  const handleAssociationFunderBuyer = () => {
    navigate("funder-pending-association-buyer");
  };

  const handlePendingInviteFunderBuyer = () => {
    navigate("funder-pending-invites-buyer");
  };

  const GoToAllTiers = () => {
    navigate("/alltiers");
  };

  const handleClose = () => {
    setOpenTierModal(false);
    setEdit(false);
    setIsDetails(false);
  };

  return (
    <>
      <TierModal
        isShown={openTierModal}
        fetchTiers={fetchTiers}
        edit={edit}
        tierId={tierId}
        onClose={handleClose}
        isDetails={isDetails}
        setIsDetails={setIsDetails}
        getId={getId}
      />
      <div className="mb-[33px]">
        {showBackBtn && (
          <div
            onClick={handleNavigate}
            className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
          >
            <PAIconInventoryArrowLeft />
            <p className="mt-[2px] text-sm font-normal text-lightGray">
              Go Back
            </p>
          </div>
        )}
        <div className="md:flex justify-between flex-wrap items-center border-b-2 border-silver pb-[12px] lg:flex-nowrap">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div>
              <p className="mb-[10px] text-black text-sm font-medium md:mb-0">
                {total}
              </p>
              <p className="mb-[10px] text-xs font-normal text-textColor md:mb-0">
                {details}
              </p>
            </div>
            {tier && !buyerFunder && !funderMember && (
              <Button
                neutral
                onClick={() => setOpenTierModal(true)}
                disabled={data?.length < 1}
                className="w-[96px] h-[41px] bg-[#F08000] flex justify-center items-center border-[0.5px] rounded-[5px] text-sm font-normal text-white"
              >
                Create Tiers
              </Button>
            )}
          </div>
          {buyer && (
            <p className="text-sm font-medium mb-[10px] md:mb-0">{title}</p>
          )}
          <div className="flex gap-x-[10px] flex-wrap md:flex-nowrap md:mt-[10px] lg:mt-0">
            {/* {tier && (
              <Button
                neutral
                onClick={() => setOpenTierModal(true)}
                className="flex !text-white text-xs border-silver font-medium items-center mb-[10px] md:mb-0 border-[1px] md:px-[5px] bg-secondaryColor py-[12px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px]"
              >
                <p className="whitespace-nowrap">Create Tier</p>
              </Button>
            )} */}
            {/* {inviteBtn && (
              <Button
                neutral
                onClick={() => setOpen(true)}
                className="flex !text-primaryColor text-xs font-medium items-center mb-[10px] md:mb-0 border-[1px] md:px-[5px] border-primaryColor py-[12px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px]"
              >
                <PAIconAdd className="fill-primaryColor w-[16px]" />
                <p className="whitespace-nowrap">Invite Funder</p>
              </Button>
            )} */}
            {/* {download && (
              <Button
                neutral
                // onClick={() => setOpen(true)}
                className="flex !text-[#ccccccc9] text-xs font-medium items-center mb-[10px] md:mb-0 border-[1px] md:px-[5px] border-[#ccc] py-[6px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[12px] xl:px-[20px]"
              >
                <PAIconExport className="fill-[#ccc] w-[16px]" />
                <p className="whitespace-nowrap text-black">Export</p>
              </Button>
            )} */}
            {/* {funder && (
              <Button
                neutral
                // onClick={() => setOpen(true)}
                className="flex !text-[#ccccccc9] text-xs font-medium items-center mb-[10px] md:mb-0 border-[1px] md:px-[5px] border-[#ccc] py-[6px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[12px] xl:px-[20px]"
              >
                <PAIconExport className="fill-[#ccc] w-[16px]" />
                <p className="whitespace-nowrap text-black">Export</p>
              </Button>
            )} */}
            {/* {accept && (
              <Button
                neutral
                onClick={handleAccept}
                className="flex !text-white text-xs font-medium items-center mb-[10px] md:mb-0 border-[1px] md:px-[5px] bg-primaryColor py-[12px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px]"
              >
                <p className="whitespace-nowrap">{btnName}</p>
              </Button>
            )} */}

            {buyerFunder && (
              <div>
                <Button
                  neutral
                  onClick={handlePendingRequestFunder}
                  className="flex !text-primaryColor text-xs font-medium items-center mb-[10px] md:mb-0 md:px-[5px] border-[1px] border-primaryColor py-[12px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px]"
                >
                  <PAIconPendingRequest />
                  <p className="whitespace-nowrap">Pending Requests</p>
                </Button>
              </div>
            )}

            {buyerFunder && (
              <div>
                <Button
                  neutral
                  onClick={handleBuyerAssociationRequestFunder}
                  className="flex text-[#F08000] text-xs font-medium items-center mb-[10px] md:mb-0 md:px-[5px]  bg-[#07593D] py-[12px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px]"
                >
                  <img src={UserAdd} alt="" />
                  <p style={{ color: "white" }} className="whitespace-nowrap">
                    Associate with Funder
                  </p>
                </Button>
              </div>
            )}

            {funderBuyer && (
              <div>
                <Button
                  neutral
                  onClick={() => setOpen(true)}
                  className="flex !text-primaryColor text-xs font-medium items-center mb-[10px] md:mb-0 md:px-[5px] border-[1px] border-primaryColor py-[12px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px]"
                  style={{ border: "1px solid #07593D" }}
                >
                  <p className="whitespace-nowrap">Invite Buyer</p>
                </Button>
              </div>
            )}

            {funderBuyer && (
              <div>
                <Button
                  neutral
                  onClick={handleAssociationFunderBuyer}
                  className="flex text-primaryColor text-xs font-medium items-center mb-[10px] md:mb-0 md:px-[5px] bg-[#07593D] py-[12px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px]"
                >
                  {/* <img src={UserAdd} alt="" /> */}
                  <p style={{ color: "white" }} className="whitespace-nowrap">
                    Pending Requests
                  </p>
                </Button>
              </div>
            )}

            {funderBuyer && (
              <div>
                <Button
                  neutral
                  onClick={handlePendingInviteFunderBuyer}
                  className="flex text-[#F08000] text-xs font-medium items-center mb-[10px] md:mb-0 md:px-[5px] bg-[#F08000] py-[12px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px]"
                >
                  {/* <img src={UserAdd} alt="" /> */}
                  <p style={{ color: "white" }} className="whitespace-nowrap">
                    Pending Invites
                  </p>
                </Button>
              </div>
            )}

            {/* funderBuyer */}

            {supplierFunder && (
              <div>
                <Button
                  neutral
                  onClick={handlePendingSupplierByFunder}
                  className="flex text-[#F08000] text-xs font-medium items-center mb-[10px] md:mb-0 md:px-[5px] bg-[#F08000] py-[12px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px]"
                >
                  <img src={UserAdd} alt="" />
                  <p style={{ color: "white" }} className="whitespace-nowrap">
                    Pending Request
                  </p>
                </Button>
              </div>
            )}

            {supplierFunder && (
              <div>
                <Button
                  neutral
                  onClick={() => setOpen(true)}
                  className="flex text-[green] text-xs font-medium items-center mb-[10px] md:mb-0 md:px-[5px] border-[1px] bg-[white] py-[12px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px]"
                  style={{ borderColor: "green" }}
                >
                  <p className="whitespace-nowrap">+ Invite Funder</p>
                </Button>
              </div>
            )}

            {supplierFunder && (
              <div>
                <Button
                  neutral
                  onClick={handleAssociationSupplierByFunder}
                  className="flex text-primaryColor text-xs font-medium items-center mb-[10px] md:mb-0 md:px-[5px] bg-[#07593D] py-[12px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px]"
                >
                  <img src={UserAdd} alt="" />
                  <p style={{ color: "white" }} className="whitespace-nowrap">
                    Associate with Funder
                  </p>
                </Button>
              </div>
            )}

            {/* supplierFunder */}
            {buyerFunder && (
              <div>
                <Button
                  neutral
                  onClick={() => setOpen(true)}
                  className="flex text-primaryColor text-xs font-medium items-center mb-[10px] md:mb-0 md:px-[5px] border-[1px] bg-[#F08000] py-[12px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px]"
                >
                  <p style={{ color: "white" }} className="whitespace-nowrap">
                    {" "}
                    + Invite Funder
                  </p>
                </Button>
              </div>
            )}

            {funderMember && (
              <div>
                <Button
                  neutral
                  onClick={handleSupplierAssociationRequestBuyer}
                  className="flex text-[#F08000] text-xs font-medium items-center mb-[10px] md:mb-0 md:px-[5px] bg-[#F08000] py-[12px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px]"
                >
                  <img src={UserAdd} alt="" />
                  <p style={{ color: "white" }} className="whitespace-nowrap">
                    Pending Associations
                  </p>
                </Button>
              </div>
            )}

            {funderMember && (
              <div>
                <Button
                  neutral
                  onClick={handleAssociateBuyerBySupplier}
                  className="flex text-primaryColor text-xs font-medium items-center mb-[10px] md:mb-0 md:px-[5px] bg-[#07593D] py-[12px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px]"
                >
                  <img src={UserAdd} alt="" />
                  <p style={{ color: "white" }} className="whitespace-nowrap">
                    Associate with Buyers
                  </p>
                </Button>
              </div>
            )}

            {/* funderMember */}
            {approval && (
              <Button
                neutral
                onClick={handleApproval}
                className={`flex !text-[#ccccc9] text-xs font-medium items-center mb-[10px] md:mb-0 border-[1px] md:px-[5px] ${
                  active
                    ? "border-primaryColor text-primaryColor"
                    : "bg-[#ccc] text-white cursor-not-allowed"
                } py-[12px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px]`}
              >
                <p
                  className={`whitespace-nowrap ${
                    active ? "text-primaryColor" : "text-white"
                  }`}
                >
                  {approvalName}
                </p>
              </Button>
            )}

            {funderSupplier && (
              <div>
                <Button
                  neutral
                  onClick={handlePendingRequestFunderSupplier}
                  className="flex !text-primaryColor text-xs font-medium items-center mb-[10px] md:mb-0 md:px-[5px] border-[1px] border-primaryColor py-[12px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px]"
                >
                  <PAIconPendingRequest />
                  <p className="whitespace-nowrap">Pending Requests</p>
                </Button>
              </div>
            )}
            {funderSupplier && (
              <div>
                <Button
                  neutral
                  onClick={handlePendingInvitesFunderSupplier}
                  className="flex !text-primaryColor text-xs font-medium items-center mb-[10px] md:mb-0 md:px-[5px] border-[1px] border-[#F08000] py-[12px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px]"
                >
                  <img src={OrangeUserAdd} alt="" />
                  <p style={{ color: "#F08000" }} className="whitespace-nowrap">
                    Pending Invites
                  </p>
                </Button>
              </div>
            )}

            {funderSupplier && (
              <Button
                neutral
                onClick={() => setOpen(true)}
                className="flex !text-white text-xs font-medium items-center mb-[10px] md:mb-0 border-[1px] md:px-[5px] bg-primaryColor py-[12px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px]"
              >
                <p className="whitespace-nowrap">{btnName}</p>
              </Button>
            )}

            {/* 
funderSupplier */}

            {funder && (
              <div>
                <Button
                  neutral
                  onClick={handlePendingRequest}
                  className="flex !text-primaryColor text-xs font-medium items-center mb-[10px] md:mb-0 md:px-[5px] border-[1px] border-primaryColor py-[12px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px]"
                >
                  <PAIconPendingRequest />
                  <p className="whitespace-nowrap">Pending Requests</p>
                </Button>
              </div>
            )}
            {funder && (
              <div>
                <Button
                  neutral
                  onClick={handleAssociationRequest}
                  className="flex text-[#F08000] text-xs font-medium items-center mb-[10px] md:mb-0 md:px-[5px] border-[1px] bg-[#F08000] py-[12px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px]"
                >
                  <img src={UserAdd} alt="" />
                  <p style={{ color: "white" }} className="whitespace-nowrap">
                    Associate with Supplier
                  </p>
                </Button>
              </div>
            )}

            {/* invite button for quick idenfication */}
            {funder && (
              <Button
                neutral
                onClick={() => setOpen(true)}
                className="flex !text-white text-xs font-medium items-center mb-[10px] md:mb-0 border-[1px] md:px-[5px] bg-primaryColor py-[12px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px]"
              >
                <p className="whitespace-nowrap">{btnName}</p>
              </Button>
            )}

            {/* Here */}
            {payableFinance && (
              <div className="flex gap-2">
                <Button
                  neutral
                  onClick={setOpen ? () => setOpen(true) : handleNavigate}
                  className="flex !text-[#565656] gap-2 text-xs font-medium items-center mb-[10px] md:mb-0 border border-[#ccc] md:px-[5px] bg-white py-[12px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px]"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M13.75 14.75H2.25C1.97344 14.75 1.75 14.5266 1.75 14.25V2.75C1.75 2.47344 1.97344 2.25 2.25 2.25H7.875C7.94375 2.25 8 2.30625 8 2.375V3.25C8 3.31875 7.94375 3.375 7.875 3.375H2.875V13.625H13.125V8.625C13.125 8.55625 13.1812 8.5 13.25 8.5H14.125C14.1938 8.5 14.25 8.55625 14.25 8.625V14.25C14.25 14.5266 14.0266 14.75 13.75 14.75ZM12.0448 3.61142L11.2292 2.7958C11.1558 2.72236 11.1995 2.5958 11.3027 2.5833L14.1058 2.25517C14.1855 2.2458 14.2542 2.31298 14.2448 2.39423L13.9167 5.19736C13.9042 5.30048 13.7777 5.34423 13.7042 5.2708L12.8855 4.45205L8.88234 8.45517C8.83391 8.50361 8.75422 8.50361 8.70578 8.45517L8.04328 7.79267C7.99484 7.74423 7.99484 7.66455 8.04328 7.61611L12.0448 3.61142Z"
                      fill="#565656"
                    />
                  </svg>
                  <p className="whitespace-nowrap">Export</p>
                </Button>

                <Button
                  neutral
                  onClick={setOpen ? () => setOpen(true) : handleNavigate}
                  className="flex !text-white text-xs font-medium items-center mb-[10px] md:mb-0 border-[1px] md:px-[5px] bg-primaryColor py-[12px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px]"
                >
                  <p className="whitespace-nowrap">{btnName}</p>
                </Button>
              </div>
            )}

            {funderPayableFinance && (
              <Button
                neutral
                onClick={handleApproval}
                className={`flex !text-[#ccccc9] text-xs font-medium items-center mb-[10px] md:mb-0 border-[1px] md:px-[5px] ${
                  active
                    ? "bg-primaryColor text-white"
                    : "bg-[#ccc] text-white cursor-not-allowed"
                } py-[12px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px]`}
              >
                <p
                  className={`whitespace-nowrap ${
                    active ? "text-white" : "text-white"
                  }`}
                >
                  {approvalName}
                </p>
              </Button>
            )}
          </div>
        </div>
        <div className="flex justify-between items-center">
          <div className="flex flex-col gap-y-[10px] md:gap-x-[10px] md:flex-row md:flex-wrap pt-[12px]">
            <div className="relative">
              <img
                src={Search}
                alt="Search Icon"
                className="absolute left-3 top-1/2 transform -translate-y-1/2 w-4 h-4"
              />
              <InputField
                placeholder={pfp || df === "true" ? "Search Funders" : "Search"}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="pl-10 w-full py-2 border rounded-md border[#CCCCCC] outline-none md:w-[350px] !border-silver "
              />
            </div>

            {funderPayableFinance && (
              <SelectContainer>
                <TableHeaderSelect options={options} onChange={handleChange} />
              </SelectContainer>
            )}
            {approval && (
              <SelectContainer>
                <TableHeaderSelect options={options} onChange={handleChange} />
              </SelectContainer>
            )}
            {funder && (
              <SelectContainer>
                <TableHeaderSelect options={options} onChange={handleChange} />
              </SelectContainer>
            )}
            {tier && (
              <SelectContainer>
                <TableHeaderSelect options={options} onChange={handleChange} />
              </SelectContainer>
            )}
            {/* LEAVE THE FILTER COMMENTED */}
            {showSelectContainer && supplierFunder && (
              <SelectContainer>
                <TableHeaderSelect options={options} onChange={handleChange} />
              </SelectContainer>
            )}
            {supplierFunder && (
              <SelectContainer>
                <TableHeaderSelect options={options} onChange={handleChange} />
              </SelectContainer>
            )}
            {funderSupplier && (
              <SelectContainer>
                <TableHeaderSelect options={options} onChange={handleChange} />
              </SelectContainer>
            )}
            {pfp === "true" && (
              <SelectContainer>
                <TableHeaderSelect options={options} onChange={handleChange} />
              </SelectContainer>
            )}
            {df === "true" && (
              <SelectContainer>
                <TableHeaderSelect options={options} onChange={handleChange} />
              </SelectContainer>
            )}
            <Button
              neutral
              onClick={handleSearch}
              // disabled={data?.length < 1}
              className="w-[96px] h-[41px] bg-headerbtnBg flex justify-center items-center border-[0.5px] border-gray rounded-[5px] text-sm font-normal !text-black"
            >
              Search
            </Button>
          </div>
          <div className="pt-4">
            {funder && (
              <Button
                neutral
                onClick={GoToAllTiers}
                // disabled={data?.length < 1}
                className="w-[96px] h-[41px] bg-black flex justify-center items-center border-[0.5px] rounded-[5px] text-sm font-normal text-white"
              >
                My Tiers
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TableHeader;

const SelectContainer = styled.div`
  min-width: 180px;
  @media screen and (max-width: 470px) {
    min-width: auto;
  }
`;
