import {
  // PAIconDetailsPlaceholder,
  PAIconInventoryArrowLeft,
} from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Card from "shared/Card";
import InvoiceModalContainer from "shared/invoiceModal";

const BuyerDetails = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  function handleNavigate() {
    navigate(-1);
  }
  const location = useLocation();
  const { row } = location.state || {};
  return (
    <DashboardLayout section="Buyers" subHeader="Manage all your associated buyers account">
      <InvoiceModalContainer isShown={open} onClose={() => setOpen(false)} />
      <div>
        <header className="bg-alabasterHeader p-[24px] rounded-t-[10px]">
          <div
            onClick={handleNavigate}
            className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
          >
            <PAIconInventoryArrowLeft />
            <p className="mt-[2px] text-sm font-normal text-lightGray">
              Back
            </p>
          </div>
          <div className="flex gap-x-[395px] flex-wrap md:gap-x-[200px] lg:gap-x-[280px] xl:gap-x-[395px]">
            <div>
              <p className="text-black text-sm font-medium">Details</p>
              <p className="text-textColor text-xs font-normal">
                Basic information about the associate
              </p>
            </div>
          </div>
        </header>
        <Card className="px-[20px] md:px-[40px] pb-[40px] rounded-b-[10px] 2xl:pl-[100px]">
          <div>
            <div className="md:flex md:justify-between  pt-[24px] 2xl:justify-start 2xl:gap-x-[385px] ">
              <div>
                <h2 className="text-black text-sm font-semibold">
                  Personal Information
                </h2>
                <div className="mt-[24px]">
                  <p className="text-sm font-normal text-lightGray">Name</p>
                  <p className="mt-[8px] text-textColor text-sm font-normal">
                    {row?.fullName}
                  </p>
                </div>
                <div className="mt-[24px]">
                  <p className="text-sm font-normal text-lightGray">Email</p>
                  <p className="mt-[8px] text-textColor text-sm font-normal">
                    {row?.email}
                  </p>
                </div>
                <div className="mt-[24px]">
                  <p className="text-sm font-normal text-lightGray">
                    Contact number
                  </p>
                  <p className="mt-[8px] text-textColor text-sm font-normal">
                    {row?.phoneNumber}
                  </p>
                </div>
              </div>

              <div className="mt-[24px] md:mt-0">
                <h2 className="text-black text-sm font-semibold">
                  Company Information
                </h2>
                <div className="mt-[24px]">
                  <p className="text-sm font-normal text-lightGray">
                    Company Name
                  </p>
                  <p className="mt-[8px] text-textColor text-sm font-normal">
                    {row?.companyName}
                  </p>
                </div>
                <div className="mt-[24px]">
                  <p className="text-sm font-normal text-lightGray">
                    Company Address
                  </p>
                  <p className="mt-[8px] text-textColor text-sm font-normal md:w-[150px] lg:w-full break-all">
                    {row?.companyAddress}
                  </p>
                </div>
                <div className="mt-[24px]">
                  <p className="text-sm font-normal text-lightGray">
                    Company Website
                  </p>
                  <p className="mt-[8px] text-textColor text-sm font-normal">
                    {row?.companyWebsite}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </DashboardLayout>
  );
};

export default BuyerDetails;
