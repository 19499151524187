import React, { useEffect, useState } from "react";
import NewEarlyPaymentRequests from "./NewEarlyPaymentRequests";
import EarlyPayments from "./EarlyPayment";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "shared/Button";
import { useDispatch, useSelector } from "react-redux";
import { invoiceApprove } from "appstate/invoice/invoiceSlice";
import toast from "react-hot-toast";
import { allEarlyPayments } from "appstate/buyer/buyerSlice";

const BuyerTabs = () => {
  const location = useLocation();
  const route = location.state;
  const [activeTab, setActiveTab] = useState("earlyPayments");
  const dispatch = useDispatch();
  const { invoiceForApprove } = useSelector((state) => state?.invoice);
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const navigate = useNavigate();

  function handleNavigate() {
    let path = "/invoice/invoice/fundingSection/invoiceupload";
    navigate(path);
  }

  const renderTabContent = () => {
    switch (activeTab) {
      case "earlyPayments":
        return <EarlyPayments route={route} />;
      case "newEarlyPaymentRequests":
        return <NewEarlyPaymentRequests />;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (route === "earlyPayments") {
      setActiveTab("earlyPayments");
    }
  }, [route]);

  const handleApproveInvoice = () => {
    const approvedByBuyer = { approvedByBuyer: true };
    const page = 0;
    const pageSize = 10;
    dispatch(
      invoiceApprove({ invoiceId: invoiceForApprove, body: approvedByBuyer })
    ).then((data) => {
      if (data?.payload?.success) {
        dispatch(allEarlyPayments({ page, pageSize }));
        toast.success(data?.payload?.message);
      }
    });
  };

  const renderCreateNewRequestButton = () => {
    if (activeTab === "all") {
      return (
        <div className="fle items-center text-base font-medium text-black">
          <button
            className="p1 flex h-10 items-center gap-4 rounded-md border border-[#CCC] bg-[#FFBE55] p-2 px-5 text-base text-white"
            onClick={handleNavigate}
          >
            Import
          </button>
        </div>
      );
    }
    return <div className="invisible p-2">Hidden Button</div>;
  };

  return (
    <div>
      <div className="mt5 mx6 mb-[17px] w-full cursor-pointer rounded-md bg-white">
        <div className="gap1 flex-col items-center justify-between gap-10 p-2 px-4 md:flex md:flex-row">
          <div className="flex-col gap-10 md:flex md:flex-row">
          <div
              className={
                activeTab === "newEarlyPaymentRequests"
                  ? "rounded-l-sm rounded-r-sm border-[#07593D] font-bold text-[#07593D] md:mb-[-18px] md:border-b-4"
                  : "text-gray500 my-2 text-base font-normal md:my-0"
              }
              onClick={() => handleTabClick("newEarlyPaymentRequests")}
            >
              Pending
            </div>

            <div
              className={
                activeTab === "earlyPayments"
                  ? "rounded-l-sm rounded-r-sm border-[#07593D] font-bold text-[#07593D] md:mb-[-18px] md:border-b-4"
                  : "text-gray500 text-base font-normal"
              }
              onClick={() => handleTabClick("earlyPayments")}
            >
              Ptrocessing
            </div>

            <div
              className={
                activeTab === "earlyPayments"
                  ? "rounded-l-sm rounded-r-sm border-[#07593D] font-bold text-[#07593D] md:mb-[-18px] md:border-b-4"
                  : "text-gray500 text-base font-normal"
              }
              onClick={() => handleTabClick("earlyPayments")}
            >
              Workflow
            </div>

            <div
              className={
                activeTab === "earlyPayments"
                  ? "rounded-l-sm rounded-r-sm border-[#07593D] font-bold text-[#07593D] md:mb-[-18px] md:border-b-4"
                  : "text-gray500 text-base font-normal"
              }
              onClick={() => handleTabClick("earlyPayments")}
            >
              Approved
            </div>

            <div
              className={
                activeTab === "earlyPayments"
                  ? "rounded-l-sm rounded-r-sm border-[#07593D] font-bold text-[#07593D] md:mb-[-18px] md:border-b-4"
                  : "text-gray500 text-base font-normal"
              }
              onClick={() => handleTabClick("earlyPayments")}
            >
              Rejected
            </div>


            
          </div>
          {renderCreateNewRequestButton()}
          {activeTab === "pendingApproval" && (
            <Button
              neutral
              onClick={handleApproveInvoice}
              className="py-[12px] px-[24px] rounded-[5px] border-[.5px] border-silver bg-[#2EA923] text-white text-sm"
            >
              Approve
            </Button>
          )}
        </div>
      </div>
      {renderTabContent()}
    </div>
  );
};

export default BuyerTabs;
