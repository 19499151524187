import { PAIconInventoryArrowLeft } from "assets/images/svgs";
import { motion } from "framer-motion";
import DashboardLayout from "layout/dashboardlayout";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SingleRequestModal from "./Modal";
import DirectRequestModal from "./Modal/DirectRequestModal";
import { getAllInvoiceById } from "appstate/api/invoice/invoice";
import { formatCurrency } from "utilities/helper/formatters";

const InvoiceDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const [invoiceData, setInvoiceData] = useState(null);
  const row = location.state?.row;
  const details = location?.state?.details;
  const directFunding = location?.state?.directFunding;

  useEffect(() => {
    const fetchInvoice = async () => {
      if (id) {
        const result = await getAllInvoiceById({ id });
        setInvoiceData(result?.data);
      }
    };
    fetchInvoice();
  }, [id]);

  const listItems = invoiceData?.items?.length;

  const [open, setOpen] = useState(false);
  const [showDirectModal, setShowDirectModal] = useState(false);
  const navigate = useNavigate();
  function handleNavigate() {
    let path = -1;
    navigate(path);
  }
  const showSingleRequestModal = () => {
    setOpen(true);
  };
  const showDirectRequestModal = () => {
    setShowDirectModal(true);
  };
  return (
    <DashboardLayout
      section="Invoice"
      subHeader="Here is an overview of your invoices"
    >
      <SingleRequestModal
        data={invoiceData}
        isShown={open}
        directFunding={directFunding}
        onClose={() => setOpen(false)}
        invoiceData={invoiceData}
        id={id}
        row={row}
      />
      <DirectRequestModal
        data={invoiceData}
        isShown={showDirectModal}
        directFunding={directFunding}
        onClose={() => setShowDirectModal(false)}
      />
      <motion.section
        initial={{
          x: 20,
        }}
        animate={{
          x: 0,
        }}
        exit={{
          x: -20,
        }}
        transition={{
          delay: 0.6,
        }}
        className="flex flex-col gap-8  pb-24  bg-white rounded-[10px]"
      >
        <header className="px-6 border-b pt-[17px] rounded-t-[10px] border-[#B1A6B2] pb-[14px] bg-alabasterHeader">
          <div className="flex justify-between">
            <div
              onClick={handleNavigate}
              className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
            >
              <PAIconInventoryArrowLeft />
              <p className="mt-[2px] text-sm font-normal text-lightGray">
                Go Back
              </p>
            </div>
          </div>
        </header>
        <div className="px-6">
          <div className=" flex  flex-col gap-3 ">
            <header>
              <div className="flex justify-between">
                <div>
                  <h1 className=" text-[18px] font-light">
                    Invoice | INV-{invoiceData?.invoiceNo}
                  </h1>
                </div>
                <div className="flex justify-between items-center">
                  {details ? null : <div
                    onClick={
                      directFunding
                        ? showDirectRequestModal
                        : showSingleRequestModal
                    }
                    className="rounded-[5px] cursor-pointer bg-secondaryColor shadow-md text-[#F9F9F9] text-xs font-medium py-[10px] px-[20px]"
                  >
                    {directFunding ? "Request Funds" : "Request Early Payment"}
                  </div>}
                </div>
              </div>
            </header>
            <main className=" w-full flex justify-between">
              <div className="flex flex-col gap-3 w-1/2">
                <div className="flex gap-x-[50px]">
                  <span>Reference No:</span>
                  <span>{invoiceData?.invoiceRef}</span>
                </div>
                <div className="flex flex-col gap-1">
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Created Date:</span>
                    <span className="text-[#979797]  text-xs">
                      {invoiceData?.createdDate}
                    </span>
                  </div>
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Submitted Date:</span>
                    <span className="text-[#979797]  text-xs">
                      {invoiceData?.submittedDate}
                    </span>
                  </div>
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Approved Date:</span>
                    <span className="text-[#979797]  text-xs">
                      {invoiceData?.approvedDate}
                    </span>
                  </div>
                  <div className="flex gap-x-[97px]">
                    <span className=" text-xs">Due Date:</span>
                    <span className="text-[#979797]  text-xs">
                      {invoiceData?.dueDate}
                    </span>
                  </div>
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Issuance Date:</span>
                    <span className="text-[#979797]  text-xs">
                      {invoiceData?.createdDate}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-between">
                <motion.button
                  // onClick={() => setLockModalOpen(true)}
                  whileTap={{ scale: 0.9 }}
                  className="flex bg-[#cdecdb] text-sm justify-center gap-2 text-[#07A04A] rounded-[5px] items-center mt-4 px-[35px] py-1.5 "
                >
                  {invoiceData?.status}
                  {/* <PAIconLockYellow /> */}
                </motion.button>
              </div>
            </main>
          </div>
          <br />
          <div className="flex justify-between">
            <div className="flex flex-col">
              <span className="font-light  text-xs">
                {invoiceData?.buyerName}
              </span>
              <span className="text-[#979797] text-sm">buyer details</span>
            </div>
            <div className="flex flex-col">
              <span className="font-light   text-xs">
                {`${invoiceData?.paymentTerms} Days` }
              </span>
              <span className="text-[#979797] text-sm">Payment Terms</span>
            </div>
            <div className="flex flex-col">
              <span className="font-light text-sm">Currency</span>
              <span className="text-[#979797]  text-xs">
                {invoiceData?.currency}
              </span>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <header className="border-b-[5px] border-b-[#F6F5FA] pb-3"></header>
            <div className="flex  justify-between">
              <div className="flex gap-2"></div>
              <div className="flex gap-2">
                <div className="flex flex-col">
                  <span className="text-[#979797]  text-xs">
                    {listItems} total items
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
              <h1 className="text-sm font-bold">Line Items</h1>
            </header>
            <div className=" w-full overflow-x-auto">
              <table className=" w-full overflow-x-auto">
                <thead className="bg-subtleGray w-">
                  <tr className=" bg-unusualGray p-2.5 ">
                    <td className="text-[#11192A] p-2.5 text-xs font-bold">
                      Item
                    </td>
                    <td className=" p-2.5 text-xs font-bold">Description</td>
                    <td className=" p-2.5 text-xs font-bold">Unit price</td>
                    <td className=" p-2.5 text-xs font-bold">Quantity</td>
                    <td className=" p-2.5 text-xs font-bold">
                      {"Amount(Before Tax)"}
                    </td>
                    <td className=" p-2.5 text-xs font-bold">Tax Rate</td>
                    <td className=" p-2.5 text-xs font-bold">Tax Amount</td>
                    <td className=" p-2.5 text-xs font-bold">Total Price</td>
                  </tr>
                </thead>
                {invoiceData?.items?.map((item, index) => (
                  <tbody key={index}>
                    <tr className=" py-[3px] px-1.5">
                      <td className=" text-xs text-[#11192A] p-2.5">
                        {item?.itemName}
                      </td>
                      <td className=" text-xs text-[#11192A] p-2.5">
                        {item?.description}
                      </td>
                      <td className=" text-xs text-[#11192A] p-2.5">
                        {formatCurrency(Number(item?.unitPrice))}
                      </td>
                      <td className=" text-xs text-[#11192A] p-2.5">
                        {item?.quantity}
                      </td>
                      <td className=" text-xs text-[#11192A] p-2.5">
                        {formatCurrency(Number(item?.unitPrice))}
                      </td>
                      <td className=" text-xs text-[#11192A] p-2.5">
                        {item?.taxRate}
                      </td>
                      <td className=" text-xs text-[#11192A] p-2.5">
                        {formatCurrency(Number(item?.taxAmount))}
                      </td>
                      <td className=" text-xs text-[#11192A] p-2.5">
                        {formatCurrency(Number(item?.totalPrice))}
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
            <div className="flex justify-between">
              <div>
                <h4 className="text-sm text-[#11192A] ">
                  Receiving Funding Account Information
                </h4>
                <h5 className="text-sm text-[#11192A] ">
                  {invoiceData?.virtualAccount?.accountNumber}
                </h5>
                <h5 className="text-sm text-[#11192A] ">
                  {invoiceData?.virtualAccount?.accountName}
                </h5>
              </div>
              <div style={{ width: 300 }}>
                <div className="flex justify-between">
                  <span className="text-sm text-[#11192A] ">Subtotal</span>
                  <span className="text-sm text-[#11192A] ">
                    {formatCurrency(Number(invoiceData?.subTotal))}
                  </span>
                </div>
                <div className="flex justify-between">
                  <div>
                    <span className="text-sm text-[#11192A] ">VAT</span>
                    <span className="pl-2 text-sm text-[#11192A] ">
                      {invoiceData?.vat}%
                    </span>
                  </div>
                  <div>
                    <span className="text-sm text-[#11192A] ">
                      {formatCurrency(Number(invoiceData?.vatAmount))}
                    </span>
                  </div>
                </div>
                <div className="flex justify-between">
                  <div>
                    <span className="text-sm text-[#11192A] ">Discount</span>
                    <span className="pl-2 text-sm text-[#11192A] ">
                      {invoiceData?.discount}%
                    </span>
                  </div>
                  <div>
                    <span className="text-sm text-[#11192A] ">
                      {formatCurrency(Number(invoiceData?.discountAmount))}
                    </span>
                  </div>
                </div>
                <div>
                  <hr className="border-t border-[black] mt-2 pt-2" />
                  <div className="flex justify-between">
                    <span className="text-lg text-[#11192A] ">Grand Total</span>
                    <span className="text-lg text-[#11192A] ">
                      {formatCurrency(Number(invoiceData?.grandTotal))}
                    </span>
                  </div>
                  <hr className="border-t border-[black] mt-2 pt-2" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.section>
    </DashboardLayout>
  );
};

export default InvoiceDetails;
