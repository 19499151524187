import { motion } from "framer-motion";
import { useEffect, useState } from "react";

function UpcomingPayment({
  amountText = "Payable Amount",
  handleMakePayment,
  player = "Supplier",
  subText = "Details on the next Early Payment Request date",
  payableAmount,
  isData,
  lienedOverview,
  selectedStatus,
  upcomingOverview,
  overdueOverview,
  earlyPaymentOverview,
  status,
  setInvoiceIds,
  setIsFundTransfer,
}) {
  const animationVariants = {
    initial: { opacity: 0, x: 50, scale: 0.95 },
    animate: { opacity: 1, x: 0, scale: 1 },
    exit: { opacity: 0, x: 50, scale: 0.95 },
    transition: { duration: 6.5, ease: "easeInOut" },
  };

  const [countDown, setCountDown] = useState("");

  useEffect(() => {
    const updateCountdown = () => {
      const today = new Date();
      let paymentDate = null;

      if (selectedStatus === "Liened") {
        paymentDate = new Date(
          lienedOverview?.upcomingPaymentDetails?.paymentDate
        );
      } else if (selectedStatus === "Upcoming") {
        paymentDate = new Date(
          upcomingOverview?.upcomingPaymentDetails?.paymentDate
        );
      } else if (selectedStatus === "Overdue") {
        paymentDate = new Date(
          overdueOverview?.upcomingPaymentDetails?.paymentDate
        );
      } else {
        paymentDate = new Date(
          earlyPaymentOverview?.upcomingPaymentDetails?.paymentDate
        );
      }

      if (!paymentDate || isNaN(paymentDate)) {
        setCountDown("Invalid date");
        return;
      }

      const diff = paymentDate - today;

      if (diff > 0) {
        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((diff / (1000 * 60)) % 60);
        const seconds = Math.floor((diff / 1000) % 60);

        setCountDown(`${days}d ${hours}h ${minutes}m ${seconds}s`);
      } else {
        setCountDown("Time expired");
      }
    };

    updateCountdown();
    const interval = setInterval(updateCountdown, 1000);

    return () => clearInterval(interval);
  }, [
    lienedOverview,
    selectedStatus,
    upcomingOverview,
    overdueOverview,
    earlyPaymentOverview,
  ]);

  return (
    <motion.div
      className="w-full lg:w-1/2 px-5 flex flex-col gap-5 bg-white rounded py-3"
      initial="initial"
      animate="animate"
      exit="exit"
      variants={animationVariants}
    >
      <header className="flex gap-6 justify-between items-center">
        <div className="flex flex-col gap-1">
          <span className="text-[#222] text-[17px] font-semibold">
            Upcoming Payment
          </span>

          <span className="text-xs font-medium text-[#959595]">{subText}</span>
        </div>

        {["Upcoming", "Overdue"].includes(selectedStatus) ? (
          <span>
            <motion.button
              whileTap={{ scale: 0.92 }}
              onClick={() => {
                setInvoiceIds(
                  earlyPaymentOverview?.upcomingPaymentDetails?.offerId
                );
                setIsFundTransfer(true);
              }}
              className={`py-2 px-4 text-sm bg-primaryColor text-white rounded   ${
                !isData ? "cursor-not-allowed" : "cursor-pointer"
              }`}
              disabled={!isData}
            >
              Fund Transfer
            </motion.button>
          </span>
        ) : (
          <span>
            <motion.button
              whileTap={{ scale: 0.92 }}
              onClick={() => {
                setInvoiceIds(
                  earlyPaymentOverview?.upcomingPaymentDetails?.offerId ||
                    lienedOverview?.upcomingPaymentDetails?.offerId
                );
                handleMakePayment();
              }}
              className={`py-2 px-4 text-sm bg-primaryColor text-white rounded   ${
                !isData ? "cursor-not-allowed" : "cursor-pointer"
              }`}
              disabled={!isData}
            >
              Pay now
            </motion.button>
          </span>
        )}
      </header>

      {status === "payables" && (
        <main className="flex flex-col gap-5">
          <div className="flex justify-between items-center">
            <span className="text-sm text-[#7A7A7A] font-medium">
              Count down{" "}
            </span>

            <span className="text-secondaryColor font-medium">{countDown}</span>
          </div>

          <div className="flex justify-between items-center">
            <span className="text-sm text-[#7A7A7A] font-medium">
              Name of {player}{" "}
            </span>

            {selectedStatus === "Liened" ? (
              <span className="text-[#222] font-medium">
                {lienedOverview?.upcomingPaymentDetails?.supplierName}
              </span>
            ) : selectedStatus === "Upcoming" ? (
              <span className="text-[#222] font-medium">
                {upcomingOverview?.upcomingPaymentDetails?.supplierName}
              </span>
            ) : (
              <span className="text-[#222] font-medium">
                {overdueOverview?.upcomingPaymentDetails?.supplierName}
              </span>
            )}
          </div>

          <div className="flex justify-between items-center">
            <span className="text-sm text-[#7A7A7A] font-medium">
              {amountText}
            </span>

            <span className="text-[#222] font-medium">
              <span className="text-xs font-light">
                {payableAmount && "N"}{" "}
              </span>{" "}
              {selectedStatus === "Liened" ? (
                <span className="font-semibold text-primaryColor">
                  N
                  {lienedOverview?.upcomingPaymentDetails?.payableAmount?.toLocaleString()}
                </span>
              ) : selectedStatus === "Upcoming" ? (
                <span className="font-semibold text-primaryColor">
                  N
                  {upcomingOverview?.upcomingPaymentDetails?.payableAmount?.toLocaleString()}
                </span>
              ) : (
                <span className="font-semibold text-primaryColor">
                  N
                  {overdueOverview?.upcomingPaymentDetails?.payableAmount?.toLocaleString()}
                </span>
              )}
            </span>
          </div>
        </main>
      )}

      {status === "requests" && (
        <main className="flex flex-col gap-5">
          <div className="flex justify-between items-center">
            <span className="text-sm text-[#7A7A7A] font-medium">
              Count down{" "}
            </span>

            <span className="text-secondaryColor font-medium">{countDown}</span>
          </div>

          <div className="flex justify-between items-center">
            <span className="text-sm text-[#7A7A7A] font-medium">
              Name of {player}{" "}
            </span>

            <span className="text-[#222] font-medium">
              {earlyPaymentOverview?.upcomingPaymentDetails?.supplierName}
            </span>
          </div>

          <div className="flex justify-between items-center">
            <span className="text-sm text-[#7A7A7A] font-medium">
              {amountText}
            </span>

            <span className="text-[#222] font-medium">
              <span className="text-xs font-light">
                {payableAmount && "N"}{" "}
              </span>{" "}
              <span className="font-semibold text-primaryColor">
                N
                {earlyPaymentOverview?.upcomingPaymentDetails?.payableAmount?.toLocaleString()}
              </span>
            </span>
          </div>
        </main>
      )}

      {status === "offers" && (
        <main className="flex flex-col gap-5">
          <div className="flex justify-between items-center">
            <span className="text-sm text-[#7A7A7A] font-medium">
              Count down{" "}
            </span>

            <span className="text-secondaryColor font-medium">{countDown}</span>
          </div>

          <div className="flex justify-between items-center">
            <span className="text-sm text-[#7A7A7A] font-medium">
              Name of {player}{" "}
            </span>

            <span className="text-[#222] font-medium">
              {earlyPaymentOverview?.upcomingPaymentDetails?.supplierName}
            </span>
          </div>

          <div className="flex justify-between items-center">
            <span className="text-sm text-[#7A7A7A] font-medium">
              {amountText}
            </span>

            <span className="text-[#222] font-medium">
              <span className="text-xs font-light">
                {payableAmount && "N"}{" "}
              </span>{" "}
              <span className="font-semibold text-primaryColor">
                N
                {earlyPaymentOverview?.upcomingPaymentDetails?.payableAmount?.toLocaleString()}
              </span>
            </span>
          </div>
        </main>
      )}
    </motion.div>
  );
}

export default UpcomingPayment;
