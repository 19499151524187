import { AnimatePresence } from "framer-motion";
import OpenMarketTabCard from "./cards/OpenMarketTabCard";
import TransitionOpacity from "shared/animation/TransitionOpacity";
import { useEffect, useState } from "react";

import OfferDetailsModal from "./modal/OfferDetailsModal";
import InvoiceLockSuccessModal from "./modal/InvoiceLockSuccessModal";
import ViewDocumentModal from "features/RulesConfig/common/components/modals/DeleteRuleModal";
import { useDispatch, useSelector } from "react-redux";
import {
  allOpenMarketInvoices,
  // lockInvoice,
  // placeInvoiceBid,
} from "appstate/openMarket/openMarketSlice";
import LoadingScreen from "shared/LoadingScreen";
import { toggleCancelButtonOff } from "./buttonSlice";
import EmptyPage from "features/roleManager/Dashbaord/components/EmptyPage";
import SelectAccountModal from "./modal/SelectAccountModal";
import EnterPin from "./modal/Pin";
import VerifyLien from "./modal/VerifyLienModal";

function OpenMarketTab({ openMarketQuery, setActiveTab }) {
  const [id, setId] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(toggleCancelButtonOff());
    dispatch(allOpenMarketInvoices({ page: 0, pageSize: 10 }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      allOpenMarketInvoices({
        page: 0,
        pageSize: 10,
        searchQuery: openMarketQuery,
      })
    );
  }, [dispatch, openMarketQuery]);

  const { isLoading, allOpenMarketInvoicesData } = useSelector(
    (state) => state?.openMarket
  );

  const [offerDetailModalOpen, setOfferDetailModalOpen] = useState(false);
  const [selectAccountModal, setSelectAccountModal] = useState(false);
  const [bidRequest, setBidRequest] = useState(false);
  const [confirmSelectedAccountModal, setConfirmSelectedAccountModal] =
    useState(false);
  const [pinModal, setPinModal] = useState(false);
  const [verifyLienStatusModal, setVerifyLienStatusModal] = useState(false);
  const [invoiceLockedSuccessModal, setInvoiceLockedSuccessModal] =
    useState(false);

  // Handle Offer Detail
  // const handleOfferDetail = () => {
  //   setOfferDetailModalOpen(false);
  // };

  const handleAction = () => {
    setOfferDetailModalOpen(false);
    setTimeout(() => {
      setBidRequest(true);
    }, 500);
  };

  const handleProceedToSelectAccount = () => {
    setBidRequest(false);
    setTimeout(() => {
      setSelectAccountModal(true);
    }, 500);
  };

  const handleConfirmAccount = () => {
    setSelectAccountModal(false);
    setTimeout(() => {
      setConfirmSelectedAccountModal(true);
    }, 500);
  };

  const handleProceedEnterPin = () => {
    setConfirmSelectedAccountModal(false);
    setTimeout(() => {
      setPinModal(true);
    }, 500);
  };

  return (
    <TransitionOpacity className=" min-h-[75vh] bg-unusualGray rounded-[5px] p-6">
      <div className="w-full mb-4 flex items-center gap-2.5">
        <span className="py-2.5">Filter Bids:</span>

        <select className=" text-sm font-medium border border-[#959595] rounded-[5px] px-5 py-2.5 outline-none cursor-pointer">
          <option value="" key="">
            All Open market Bids
          </option>
          <option value="" key="">
            Bid Active Bids
          </option>
          <option value="" key="">
            Posted Bids
          </option>
        </select>
      </div>
      {isLoading ? (
        <LoadingScreen />
      ) : allOpenMarketInvoicesData?.data?.length ? (
        <div className="flex flex-col gap-4 ">
          {allOpenMarketInvoicesData?.data?.map((invoice, index) => (
            <OpenMarketTabCard
              selectId={id}
              setId={setId}
              key={index}
              lockInvoice={setOfferDetailModalOpen}
              id={invoice?.invoiceId}
              invoiceNumber={invoice?.invoiceReference}
              buyerCompanyName={invoice?.buyerCompanyName}
              supplierCompanyName={invoice?.supplierCompanyName}
              description={invoice?.description}
              invoiceAmount={invoice?.invoiceAmount}
              lastModifiedDate={invoice?.lastModifiedDate}
              dueDate={invoice?.dueDate}
              numberOfBids={invoice?.numberOfBids}
            />
          ))}
        </div>
      ) : (
        <EmptyPage />
      )}

      {/* Offer Detail Modal */}
      <AnimatePresence>
        {offerDetailModalOpen && (
          <OfferDetailsModal
            onAction={handleAction}
            onClose={() => setOfferDetailModalOpen(false)}
          />
        )}
      </AnimatePresence>

      {/* Approve Bid Request */}
      <AnimatePresence>
        {bidRequest && (
          <ViewDocumentModal
            onAction={() => handleProceedToSelectAccount()}
            onClose={() => setBidRequest(false)}
            proceed="Yes Proceed"
          >
            <span className=" text-center">
              Are you sure you want to{" "}
              <span className="font-bold text-center text-primaryColor">
                Bid
              </span>{" "}
              this invoice with reference number{" "}
              <span className="font-bold text-center text-primaryColor">
                RCYSAJCRW
              </span>{" "}
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>

      {/* Select Account Modal */}
      <AnimatePresence>
        {selectAccountModal && (
          <SelectAccountModal
            onClose={() => setSelectAccountModal(false)}
            onAction={() => {
              handleConfirmAccount();
            }}
          />
        )}
      </AnimatePresence>

      {/* Approve Selected Account */}
      <AnimatePresence>
        {confirmSelectedAccountModal && (
          <ViewDocumentModal
            onAction={() => handleProceedToSelectAccount()}
            onClose={() => setConfirmSelectedAccountModal(false)}
            proceed="Yes Proceed"
          >
            <span className="text-center">
              Are you sure you want to proceed with this account{" "}
              <span className="font-bold text-center text-primaryColor">
                736242522661
              </span>{" "}
              to bid on this invoice
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>

      {/* Approve Selected Account */}
      <AnimatePresence>
        {confirmSelectedAccountModal && (
          <ViewDocumentModal
            onAction={() => handleProceedEnterPin()}
            onClose={() => setConfirmSelectedAccountModal(false)}
            proceed="Yes Proceed"
          >
            <span className=" text-center">
              Are you sure you want to proceed with this account{" "}
              <span className="font-bold text-center text-primaryColor">
                736242522661
              </span>{" "}
              to bid on this invoice
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>

      {/* Proceed to Enter pin */}

      <AnimatePresence>
        {pinModal && (
          <EnterPin
            onClose={() => setPinModal(false)}
            onSubmit={() => {
              setPinModal(false);
              setVerifyLienStatusModal(true);
            }}
          />
        )}
      </AnimatePresence>

      {verifyLienStatusModal && (
        <VerifyLien
          onClose={() => setVerifyLienStatusModal(false)}
          onAction={() => {
            setVerifyLienStatusModal(false);
            setTimeout(() => {
              setInvoiceLockedSuccessModal(true);
            }, 500);
          }}
        />
      )}

      <AnimatePresence>
        {invoiceLockedSuccessModal && (
          <InvoiceLockSuccessModal
            setActiveTab={setActiveTab}
            onClose={() => setInvoiceLockedSuccessModal(false)}
          />
        )}
      </AnimatePresence>
    </TransitionOpacity>
  );
}

export default OpenMarketTab;
