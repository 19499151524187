import { PAIconArrowLeftGreen, PAIconCocacola } from "assets/images/svgs";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import BidAcceptModal from "../../modals/BidAcceptModal";
import BidSelectAccountModal from "../../modals/BidSelectAccountModal";

import BidProceedModal from "../../modals/BidProceedModal";
import BidPinModal from "../../modals/BidPinModal";
import BidInvoiceLockedModal from "../../modals/BidInvoiceLockedModal";
import BidLoaderModal from "../../modals/BidLoaderModla";
import { useDispatch } from "react-redux";
import { setClosedMarketActiveTab } from "appstate/funder/funderSlice";
import { AnimatePresence } from "framer-motion";
import TransitionScale from "shared/animation/TransitionScale";

const Details = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [bidSelectAccountModal, setBidSelectAccountModal] = useState(false);
  const [ongoingAcceptUpdateBidModal, setOngoingAcceptUpdateBidModal] =
    useState(false);
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);
  const [bidProceed, setBidProceed] = useState(false);
  const [bidPin, setBidPin] = useState(false);
  const [bidLoaderModal, setBidLoaderModal] = useState(false);
  const goBack = () => {
    navigate(-1);
  };
  const [discountRate, setDiscountRate] = useState("");
  const handleSetDiscountRate = (e) => {
    setDiscountRate(e.target.value);
  };

  const handleAccept = () => {
    setOngoingAcceptUpdateBidModal(false);
    setTimeout(() => {
      setBidSelectAccountModal(true);
    }, 300);
  };

  const handleProceed = () => {
    setBidProceed(false);

    setTimeout(() => {
      setBidPin(true);
    }, 300);
  };
  const handleInvoiceModalClose = () => {
    setIsInvoiceModalOpen(false);
    goBack();
  };
  const handleOnselectModal = () => {
    setBidSelectAccountModal(false);

    setTimeout(() => {
      setBidProceed(true);
    }, 300);
  };
  const handlePinModal = () => {
    setBidPin(false);
    setBidLoaderModal(true);
  };

  const handleTabClick = (tabNumber) => {
    setIsInvoiceModalOpen(false);
    goBack();
    dispatch(setClosedMarketActiveTab(tabNumber));
    sessionStorage.setItem("activeTab", tabNumber);
  };

  const dummyData = [
    {
      bidAmount: 3,
      item: "Solar Panel ",
      description: "Dangote 12 x 4 ultra high density so..",
      unitPrice: "340,000",
      Quantity: "3",
      amount: "1,020,000",
      taxRate: "1.2%",
      taxAmount: "12,240",
      totalPrice: "1,032,240",
    },

    {
      bidAmount: 4,
      item: "Solar Panel ",
      description: "Dangote 12 x 4 ultra high density so..",
      unitPrice: "340,000",
      Quantity: "3",
      amount: "1,020,000",
      taxRate: "1.2%",
      taxAmount: "12,240",
      totalPrice: "1,032,240",
    },

    {
      bidAmount: 5,
      item: "Solar Panel ",
      description: "Dangote 12 x 4 ultra high density so..",
      unitPrice: "340,000",
      Quantity: "3",
      amount: "1,020,000",
      taxRate: "1.2%",
      taxAmount: "12,240",
      totalPrice: "1,032,240",
    },
    {
      bidAmount: 6,
      item: "Solar Panel ",
      description: "Dangote 12 x 4 ultra high density so..",
      unitPrice: "340,000",
      Quantity: "3",
      amount: "1,020,000",
      taxRate: "1.2%",
      taxAmount: "12,240",
      totalPrice: "1,032,240",
    },
    {
      bidAmount: 7,
      item: "Solar Panel ",
      description: "Dangote 12 x 4 ultra high density so..",
      unitPrice: "340,000",
      Quantity: "3",
      amount: "1,020,000",
      taxRate: "1.2%",
      taxAmount: "12,240",
      totalPrice: "1,032,240",
    },
    {
      bidAmount: 8,
      item: "Solar Panel ",
      description: "Dangote 12 x 4 ultra high density so..",
      unitPrice: "340,000",
      Quantity: "3",
      amount: "1,020,000",
      taxRate: "1.2%",
      taxAmount: "12,240",
      totalPrice: "1,032,240",
    },
  ];

  return (
    <>
      <AnimatePresence>
        <BidInvoiceLockedModal
          isOpen={isInvoiceModalOpen}
          onClose={handleInvoiceModalClose}
          toOngoingTab={() => handleTabClick(3)}
        />
      </AnimatePresence>

      <>
        {bidLoaderModal && (
          <BidLoaderModal
            isOpen={bidLoaderModal}
            onClose={() => setBidLoaderModal(false)}
            onAction={() => {
              setBidLoaderModal(false);
              setTimeout(() => {
                setIsInvoiceModalOpen(true);
              }, 300);
            }}
            statusText={"Fund Invoice"}
          />
        )}{" "}
      </>

      <AnimatePresence>
        {" "}
        <BidPinModal
          isOpen={bidPin}
          onClose={() => setBidPin(false)}
          handleNext={handlePinModal}
        />
      </AnimatePresence>
      <AnimatePresence>
        {" "}
        <BidProceedModal
          isOpen={bidProceed}
          refNum={" 7364738921"}
          onClose={() => setBidProceed(false)}
          handleYes={handleProceed}
        />
      </AnimatePresence>
      <AnimatePresence>
        {" "}
        <BidAcceptModal
          isOpen={ongoingAcceptUpdateBidModal}
          onClose={() => setOngoingAcceptUpdateBidModal(false)}
          refNum={"R3456X89Y"}
          handleYes={handleAccept}
        />
      </AnimatePresence>
      <AnimatePresence>
        {" "}
        <BidSelectAccountModal
          isOpen={bidSelectAccountModal}
          onClose={() => setBidSelectAccountModal(false)}
          handleProceed={handleOnselectModal}
        />
      </AnimatePresence>
      <Link
        onClick={goBack}
        className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer w-full"
      >
        <PAIconArrowLeftGreen />
        <span className="flex items-center text-primaryColor">Go Back</span>
      </Link>

      <div className="grid lg:grid-cols-[60%,40%] gap-5">
        <TransitionScale className=" pb-5 bg-white  rounded-[10px]  pt-2">
          {/* Header with Left Padding */}

          {/* Other sections */}

          <section className="  mt-3">
            {/* Down Flex Texts */}
            <div className=" flex justify-between px-6  mt-4">
              {" "}
              <div class="text-[#222222] text-lg font-medium ">
                Reference No: | R3456X89Y
              </div>
              <div class="h-[37px] p-2.5 rounded-[5px] border border-[#f08000]/20 justify-center items-center gap-2.5 inline-flex">
                <div class="text-[#f08000] text-sm font-medium ">
                  Bid Active
                </div>
              </div>
            </div>
            <div className=" flex justify-between px-6">
              {/* first stage */}

              <div class="lg:w-[240px]  sm:w-full h-[117px] flex-col justify-start items-start gap-3 inline-flex  mt-5">
                <div class="self-stretch  flex-col justify-center items-start gap-1 flex ">
                  <div class="self-stretch justify-between items-center inline-flex">
                    <div class="text-[#222222] text-xs font-semibold ">
                      Invoice Number
                    </div>
                    <div class="text-[#7a7a7a] text-xs font-semibold ">
                      INV 2002
                    </div>
                  </div>
                  <div class="self-stretch justify-between items-center inline-flex ">
                    <div class="text-[#222222] text-xs font-semibold ">
                      Created Date:{" "}
                    </div>
                    <div class="text-[#7a7a7a] text-xs font-semibold ">
                      23-Mar -2023
                    </div>
                  </div>
                  <div class="self-stretch justify-between items-center inline-flex">
                    <div class="text-[#222222] text-xs font-semibold ">
                      Submitted Date:{" "}
                    </div>
                    <div class="text-[#7a7a7a] text-xs font-semibold ">
                      23-Mar -2023
                    </div>
                  </div>
                  <div class="self-stretch justify-between items-center inline-flex">
                    <div class="text-[#222222] text-xs font-semibold ">
                      Approved Date:{" "}
                    </div>
                    <div class="text-[#7a7a7a] text-xs font-semibold ">
                      23-April -2023
                    </div>
                  </div>
                  <div class="self-stretch justify-between items-center inline-flex">
                    <div class="text-[#222222] text-xs font-semibold ">
                      Due Date:{" "}
                    </div>
                    <div class="text-[#7a7a7a] text-xs font-semibold ">
                      30-May-2023
                    </div>
                  </div>
                  <div class="self-stretch justify-between items-center inline-flex">
                    <div class="text-[#222222] text-xs font-semibold ">
                      Issuance Date:{" "}
                    </div>
                    <div class="text-[#7a7a7a] text-xs font-semibold ">
                      30-May-2023
                    </div>
                  </div>
                </div>

                {/* stop */}
              </div>
              {/* second stage */}
              {/* <div className="flex justify-between items-center mt-5 ">
                {" "}
                <div class="h-[30px] px-8 py-2 bg-[#23ad52]/20 rounded-[5px] justify-center items-center gap-2.5 inline-flex">
                  <div class="text-center text-[#07593d] text-xs font-medium ">
                    Won Bids
                  </div>
                </div>
              </div> */}

              <div class="lg:w-[240px]  sm:w-full h-[117px] flex-col justify-start items-start gap-3 inline-flex  mt-5">
                <div class="self-stretch  flex-col justify-center items-start gap-1 flex ">
                  <div class="self-stretch justify-between items-center inline-flex">
                    <div class="text-[#222222] text-xs font-semibold ">
                      Request Amount:
                    </div>
                    <div class="text-[#7a7a7a] text-xs font-semibold ">
                      9,948,900
                    </div>
                  </div>
                  <div class="self-stretch justify-between items-center inline-flex ">
                    <div class="text-[#222222] text-xs font-semibold ">
                      Request Date:
                    </div>
                    <div class="text-[#7a7a7a] text-xs font-semibold ">
                      23-Jun -2025
                    </div>
                  </div>
                </div>

                {/* stop */}
              </div>
            </div>

            {/* stops here */}
            <div className="  px-6   mt-11">
              <header className="border-b-[5px] border-b-[#F6F5FA] mt-3  flex justify-between py-1">
                {/* <h1 className="text-[18px] font-semibold">
                  Supplier/buyer details
                </h1> */}
                <div>
                  <div class="text-[#222222] text-lg font-semibold  ">
                    Nestle PLC
                  </div>
                  <div class="text-[#7a7a7a] text-xs font-light  ">
                    Buyer details
                  </div>
                </div>

                <div class="h-[41px] flex-col justify-start items-start  inline-flex px-12">
                  <div class="text-[#222222] text-base font-semibold ">
                    60 Days
                  </div>
                  <div class="w-[100px] text-[#7a7a7a] text-xs font-light  mt-1">
                    Payment
                  </div>
                </div>

                <div class="h-[43px] flex-col justify-start items-end  inline-flex">
                  <div class="text-[#222222] text-lg font-semibold ">NGN</div>
                  <div class="text-[#7a7a7a] text-xs font-semibold mt-1">
                    Currency
                  </div>
                </div>
              </header>

              <header className="border-b-[5px] border-b-[#F6F5FA] mt-6  flex justify-between py-1">
                {/* <h1 className="text-[18px] font-semibold">
                  Supplier/buyer details
                </h1> */}
                <div class="text-[#11192a] text-sm font-semibold ">
                  Line Items
                </div>
                <div class="text-[#11192a] text-lg font-light  mr-6">
                  6 Total Items{" "}
                </div>
              </header>

              <div className=" w-full overflow-x-auto mt-3 pb-4">
                <table className=" w-full overflow-x-auto">
                  <thead className="bg-subtleGray w-full p-6  flex-nowrap whitespace-nowrap  ">
                    <tr className=" bg-unusualGray  px-6 w-full flex-nowrap whitespace-nowrap ">
                      {/* <td className="text-[#11192A] p-2.5 text-xs font-bold">
                          Item Number
                        </td> */}
                      <td class="text-[#11192a] text-xs font-semibold  p-4  flex-nowrap whitespace-nowrap ">
                        Item{" "}
                      </td>
                      <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                        Description{" "}
                      </td>
                      <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                        Unit Price
                      </td>
                      <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                        Quantity
                      </td>
                      <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                        Amount
                        <div> (before tax)</div>
                      </td>
                      <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                        Tax Rate
                      </td>
                      <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                        Tax Amount
                      </td>
                      <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                        Total Price
                      </td>
                    </tr>
                  </thead>

                  <tbody className="  ">
                    {dummyData.map((cell, idx) => {
                      return (
                        <tr key={idx} className=" w-full ">
                          <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap ">
                            {cell.item}
                          </td>
                          <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                            {cell.description}
                          </td>
                          <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                            {cell.unitPrice}
                          </td>
                          <td className="text-[#11192a] text-xs font-semibold mt-2 text-center flex-nowrap whitespace-nowrap">
                            {cell.Quantity}
                          </td>
                          <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                            {cell.amount}
                          </td>
                          <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                            {cell.taxRate}
                          </td>
                          <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                            {cell.taxAmount}
                          </td>
                          <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                            {cell.totalPrice}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              {/* Calculate cost and price  shaded or gray section */}
            </div>

            <div className=" px-6   py-5 bg-neutral-50 flex-col justify-center items-start  inline-flex bg-[#fafafa] w-full border-t-[0.2px]  border-t-[#b1a6b2]">
              <div className="lg:flex md:flex justify-between  w-full sm:block ">
                {/* First left side */}
                <div>
                  <section>
                    {" "}
                    <div class="text-[#222222] text-lg  font-medium border-b-[0.2px]  border-[#b1a6b2]">
                      Receiving Funding Account Information
                    </div>
                    {/* <div class="text-[#222222] text-lg font-semibold ">
                          Receiving Funding Account Information
                        </div> */}
                    <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                      7469479465
                    </div>
                    <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                      Praise Akinlade
                    </div>
                  </section>
                </div>

                {/* second division */}

                <div>
                  {" "}
                  <div class=" flex-col justify-start items-start inline-flex lg:min-w-[290px]">
                    <section className=" w-full px-6 pb-2 border-b-[1px] border-black">
                      <div className=" flex w-full justify-between">
                        <div class="text-[#222222] text-xs font-semibold ">
                          Subtotal
                        </div>
                        <div class=" text-[#222222] text-xs font-semibold ">
                          10,129,892
                        </div>
                      </div>
                      <div className=" flex w-full justify-between pt-3 pb-1 border-b-[0.2px] border-b-[#b1a6b2]/30">
                        <div class="text-[#222222]  text-xs font-medium flex  space-x-9 ">
                          <span>VAT %</span>
                          <span>5.6%</span>
                        </div>
                        <div class="text-[#222222] text-xs font-medium ">
                          573,960
                        </div>
                      </div>

                      <div className=" flex w-full justify-between pt-3 pb-1 border-b-[0.2px] border-b-[#b1a6b2]/30">
                        <div class="text-[#222222]  text-xs font-medium flex  space-x-9 ">
                          <span>Discount %</span>
                          <span>2.4%</span>
                        </div>
                        <div class="text-[#222222] text-xs font-medium ">
                          237,088
                        </div>
                      </div>
                    </section>
                    <div className=" flex w-full justify-between py-2 px-6 border-b-[1px] border-b-black">
                      <div class="text-[#222222] text-lg font-semibold ">
                        Grand Total
                      </div>
                      <div class="text-[#222222] text-lg font-semibold ">
                        10,473,890
                      </div>
                    </div>
                    {/* <div className=" flex w-full justify-between pt-3 pb-1  border-b-[1px] border-black">
                          <div class="text-[#222222] text-lg  font-medium  ">
                            Grand Total
                          </div>
                          <div class="text-[#222222] text-lg  font-medium ">
                            5,263,200.00
                          </div>
                        </div> */}
                  </div>
                </div>

                {/* stops */}
              </div>
            </div>
          </section>
        </TransitionScale>

        <div className="bg-white rounded-[10px] gap-8 ">
          <div className=" py-8 ">
            <div class="text-black text-lg font-semibold px-6 ">
              Bid Invoice
            </div>
            {/* starts here */}
            <div>
              {" "}
              {/* second */}
              <div className="   pb-4">
                <div class=" justify-between  flex flex-row    px-6 mt-4  ">
                  <div className="flex gap-2 py-2.5">
                    <PAIconCocacola />
                    <div className="flex flex-col">
                      {/* <span className=" text-black text-xs font-normal">
                        Coca Cola
                      </span> */}
                      <span class="text-black text-sm font-normal ">
                        Coca Cola PLC
                      </span>
                      {/* <span className="text-[#979797]  text-xs">Supplier</span> */}
                      <span class="text-[#949494] text-sm font-normal ">
                        Buyer
                      </span>
                    </div>
                  </div>
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">1.5%</div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Discount rate
                      </div>
                    </div>
                  </div>

                  {/* here */}
                </div>
                <div class=" justify-between  flex flex-row    px-6  mt-6 ">
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">N90,000</div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Discount amount
                      </div>
                    </div>
                  </div>
                  {/* hd */}
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        23-Dec-2024 (90 days)
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Early Payment Request
                      </div>
                    </div>
                    <div class="flex-col  flex w-full">
                      <div class="text-[#949494] text-sm font-normal text-end ">
                        date
                      </div>
                    </div>
                  </div>{" "}
                </div>
                <div class=" justify-between  flex flex-row    border-b border-[#c2c2c2]/30 px-6  py-3  pb-12 mt-6">
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        N9,000,000
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Request amount
                      </div>
                    </div>
                  </div>
                  {/* dhdh */}
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col  gap-0.5 flex w-full">
                      <div class="text-black text-sm font-normal text-end ">
                        8,9110,000
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Receivable amount
                      </div>
                    </div>
                  </div>
                </div>
                {/* 2nd */}
                <div class=" justify-between  flex flex-col     px-6   ">
                  <div class="text-black text-lg font-semibold  mt-6">
                    Note from Supplier:{" "}
                  </div>

                  <div class=" text-[#949494] text-sm font-light  mt-4  leading-6">
                    Seeking Early payment due to unexpected challenges. Facing
                    temporary financial constraints and need a bit more time to
                    ensure a smooth transaction. Appreciate your understanding.
                    <br className=" mt-3" />
                    Thank you.
                    <br className=" mt-3" />
                    Best regards, <br />
                    ABC Limited
                  </div>
                </div>{" "}
              </div>
              {/* stops here */}
              {/* Third */}
              {/* third stops */}
            </div>
            {/* ends here */}
            <div className=" px-6 mt-7">
              <div class="text-[#222222] text-sm font-medium  ">
                Please input your bid discount rate. The buyer's benchmark rate
                is 5.6%. Your initial bid can match or be lower than this rate,
                but it must not exceed the benchmark specified in the request.
              </div>
              <span className="text-[#07593d] text-sm font-extrabold ">
                Current lock rate: 2.7%
                <br />
              </span>
              <span className="text-[#f08000] text-sm font-medium ">
                You can cancel your bid within 30 seconds of placing it.
              </span>
              {/* search section */}
              <div>
                <input
                  type="text"
                  placeholder="12%"
                  value={discountRate}
                  onChange={handleSetDiscountRate}
                  className="px-3 w-full flex items-center text-black focus:outline-none h-[47px]  rounded-[5px] border border-[#07593d] mt-8  text-base font-medium "
                />
              </div>

              <button
                class="h-[47px] px-2.5 py-[15px] bg-[#07593d] rounded-[5px] justify-center items-center gap-2.5 inline-flex mt-8 w-full cursor-pointer"
                onClick={() => setOngoingAcceptUpdateBidModal(true)}
              >
                <div class="text-white text-base font-medium ">Bid Now</div>
              </button>
            </div>{" "}
          </div>
        </div>
      </div>
    </>
  );
};

export default Details;
