import Modal from "layout/modal/Modal";
import React from "react";
import SingleRequestModalContent from "./SingleRequestModalContent";

const SingleRequestModal = ({ isShown, onClose, data, directFunding }) => {
  return (
    <Modal largeModal accountModal isShown={isShown} hide={onClose}>
      <SingleRequestModalContent data={data} onClose={onClose} directFunding={directFunding} />
    </Modal>
  );
};

export default SingleRequestModal;
