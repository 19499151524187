import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  bankDetailsUploaded: false,
  kycDocumentUploaded: false,
  agreementDocumentAccepted: false,
  agreeToTerms: false,
  newToDashBoard: false,
};

const verificationSlice = createSlice({
  name: "verification",
  initialState,
  reducers: {
    setBankDetailsUploaded: (state, action) => {
      state.bankDetailsUploaded = action.payload;
    },
    setKycDocumentUploaded: (state, action) => {
      state.kycDocumentUploaded = action.payload;
    },
    setAgreementDocumentAccepted: (state, action) => {
      state.agreementDocumentAccepted = action.payload;
    },
    setAgreeToTerms: (state, action) => {
      state.agreeToTerms = action.payload;
    },
    setNewToDashBoard: (state, action) => {
      state.newToDashBoard = action.payload;
    },
  },
});

export const {
  setBankDetailsUploaded,
  setKycDocumentUploaded,
  setAgreementDocumentAccepted,
  setAgreeToTerms,
  setNewToDashBoard,
} = verificationSlice.actions;
export default verificationSlice.reducer;
