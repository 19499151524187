import React from "react";
import { PAIconModelOne } from "assets/images/svgs";

const ViaDirectFunding = () => {
    const invoiceData = {
        items: [
          {
            itemName: "RX83847YS",
            description: "2024-10-17",
            unitPrice: "4,500,400.99",
            quantity: 2,
            amount: (45.99 * 2).toFixed(2),
            taxRate: "5%",
            taxAmount: (45.99 * 2 * 0.05).toFixed(2),
            totalPrice: (45.99 * 2 * 1.05).toFixed(2),
          },
          {
            itemName: "RX83847YS",
            description: "2024-10-19",
            unitPrice: "120,000.5",
            quantity: 1,
            amount: (120.5).toFixed(2),
            taxRate: "8%",
            taxAmount: (120.5 * 0.08).toFixed(2),
            totalPrice: (120.5 * 1.08).toFixed(2),
          },
          {
            itemName: "RX83847YS",
            description: "2024-10-30",
            unitPrice: "890,000.75",
            quantity: 3,
            amount: (89.75 * 3).toFixed(2),
            taxRate: "6%",
            taxAmount: (89.75 * 3 * 0.06).toFixed(2),
            totalPrice: (89.75 * 3 * 1.06).toFixed(2),
          },
          {
            itemName: "RX83847YS",
            description: "2024-10-17",
            unitPrice: "120,000.5",
            quantity: 1,
            amount: (120.5).toFixed(2),
            taxRate: "8%",
            taxAmount: (120.5 * 0.08).toFixed(2),
            totalPrice: (120.5 * 1.08).toFixed(2),
          },
        ],
      };

  return (
    <div className="px-4">
      <h2 className="font-semibold text-lg leading-[30px] mb-4">
        Early Payment Details
      </h2>

      <div className="flex justify-between my-5">
        <div className="flex gap-2">
          <PAIconModelOne />
          <div className="flex flex-col">
            <span className="text-sm">Supplier</span>
            <span className="text-[#979797]  text-xs">
              {" "}
              {invoiceData?.supplierCompanyName}
            </span>
          </div>
        </div>
        <div className="flex flex-col">
          <span className="text-sm">1.6%</span>
          <span className="text-[#979797]  text-xs">Discount rate</span>
        </div>
      </div>

      <div className="flex justify-between pb-5">
        <div className="flex gap-2">
          <div className="flex flex-col">
            <span className="text-sm">N{invoiceData?.total || "740,000"}</span>
            <span className="text-[#979797]  text-xs">Discount Amount</span>
          </div>
        </div>
        <div className="flex flex-col">
          <span className="text-sm">23-Dec-2024(90days)</span>
          <span className="text-[#979797]  text-xs">
            Early Payment Request Date
          </span>
        </div>
      </div>

      <div className="flex justify-between mt-5 border-b-[5px] border-b-[#F6F5FA] pb-10">
        <div className="flex gap-2">
          <div className="flex flex-col">
            <span className="text-sm">
              N{invoiceData?.total || "12,740,000"}
            </span>
            <span className="text-[#979797]  text-xs">Request Amount</span>
          </div>
        </div>
        <div className="flex flex-col">
          <span className="text-sm">12,000,900</span>
          <span className="text-[#979797]  text-xs">Payable amount</span>
        </div>
      </div>

      <div className="border-b-[5px] border-b-[#F6F5FA] pb-5">
        <h2 className="font-medium text-base my-3">Note from Supplier:</h2>
        <p className="text-[#959595] text-sm">
          Seeking Early payment due to unexpected challenges. Facing temporary
          financial constraints and need a bit more time to ensure a smooth
          transaction. Appreciate your understanding. Thank you. Best regards,
          ABC Limited
        </p>
      </div>

      <div className="pb-3">
        <h2 className="font-medium text-base leading-[30px] my-2">
          Funding Option
        </h2>
        <p className="text-[#959595] text-sm">
          This invoice will be approved by{" "}
          <span className="font-semibold text-black">
            Elanor Penar at coca-cola
          </span>{" "}
          your associated funder for funding
        </p>
      </div>
    </div>
  );
};

export default ViaDirectFunding;
