// import {
//   PAIconCocacola,
//   PAIconDownload,
//   PAIconModelOne,
// } from "assets/images/svgs";

import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AcceptModal from "shared/AcceptModal";
import SuccessModal from "shared/SuccessModal";

function DirectFundingInvoice({ invoiceByIdData }) {
  const invoiceIdData = {
    data: {
      items: [
        {
          itemName: "Product A",
          description: "A great product",
          unitPrice: 15.99,
          quantity: 2,
        },
        {
          itemName: "Product B",
          description: "Another great product",
          unitPrice: 29.99,
          quantity: 1,
        },
        {
          itemName: "Product C",
          description: "Yet another great product",
          unitPrice: 10.5,
          quantity: 3,
        },
      ],
      invoiceNumber: "INV-2024",
      createdDate: "2024-12-23",
      submittedDate: "2024-12-24",
      approvedDate: "2024-12-25",
      dueDate: "2024-12-26",
      issuanceDate: "2024-12-27",
      requestAmount: "1,000,000",
      requestDate: "2024-12-28",
      paymentDate: "2024-12-29",
      buyerDetails: "Buyer Company",
      paymentTerms: "Net 30",
      currency: "NGN",
      lineItems: { numberOfLineItems: 3 },
      subTotal: "500.00",
      discount: 10,
      discountAmount: "50.00",
      vat: 5,
      vatAmount: "25.00",
      grandTotal: "475.00",
    },
  };

  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  const handleConfirm = () => {
    setIsAcceptModalOpen(false);
    setIsSuccessModalOpen(true);
    setSuccessMessage("Payment remainders sent successfully");
  };

  const handleSuccessClose = () => {
    setIsSuccessModalOpen(false);
    navigate(-1);
  };

  return (
    <motion.section
      initial={{
        x: 20,
      }}
      animate={{
        x: 0,
      }}
      exit={{
        x: -20,
      }}
      transition={{
        delay: 0.6,
      }}
      className="flex flex-col gap8 px-6 pt-7 pb-24 w-[60%] semilg:w-[55%] bg-white rounded-[10px]"
    >
      <div className="mb-2 flex flex-col gap-3 ">
        <header className="flex items-center justify-between">
          <h1 className=" text-[18px] font-bold">
            Reference No | {invoiceByIdData?.data?.invoiceNumber || "R3456X89Y"}
          </h1>
          <button
            className="bg-primaryColor px-4 py-2 text-white rounded"
            onClick={() => setIsAcceptModalOpen(true)}
          >
            Send Remainder Now
          </button>
        </header>

        <main className="w-full flex items-center justify-between">
          <div className="flex flex-col gap-3 w-2/5">
            <div className="flex flex-col gap-1">
              <div className="flex justify-between">
                <span className=" font-semibold text-xs">Invoice Number:</span>
                <span className="text-[#979797]  text-xs">
                  {invoiceByIdData?.data?.invoiceNumber || "INV-2024"}
                </span>
              </div>
              <div className="flex justify-between">
                <span className=" font-semibold text-xs">Created Date:</span>
                <span className="text-[#979797]  text-xs">
                  {invoiceByIdData?.data?.createdDate?.slice(0, 10) ||
                    "23-Dec-2024"}
                </span>
              </div>
              <div className="flex justify-between">
                <span className=" font-semibold text-xs">Submitted Date:</span>
                <span className="text-[#979797]  text-xs">
                  {invoiceByIdData?.data?.submittedDate?.slice(0, 10) ||
                    "23-Dec-2024"}
                </span>
              </div>
              <div className="flex justify-between">
                <span className=" font-semibold text-xs">Approved Date:</span>
                <span className="text-[#979797]  text-xs">
                  {invoiceByIdData?.data?.approvedDate?.slice(0, 10) ||
                    "23-Dec-2024"}
                </span>
              </div>
              <div className="flex justify-between">
                <span className=" font-semibold text-xs">Due Date:</span>
                <span className="text-[#979797]  text-xs">
                  {invoiceByIdData?.data?.dueDate?.slice(0, 10) ||
                    "23-Dec-2024"}
                </span>
              </div>

              <div className="flex justify-between">
                <span className=" font-semibold text-xs">Issuance Date</span>
                <span className="text-[#979797]  text-xs">
                  {invoiceByIdData?.data?.issuanceDate.slice(0, 10) ||
                    "23-Dec-2024"}
                </span>
              </div>
            </div>
          </div>

          {/* STATUS */}
          <div className="rounded-md py-1.5 bg-[#F080000F] px-6">
            <p className="text-center text-secondaryColor text-xs font-bold">
              {invoiceByIdData?.data?.status || "Pending Payment"}
            </p>
          </div>
        </main>
      </div>

      {/* Buyer details and co. */}
      <div className="flex justify-between my-5 border-b-[5px] border-b-[#F6F5FA] py-3">
        <div className="flex flex-col">
          <span className="font-bold text-sm">
            {invoiceByIdData?.data?.buyerDetails || "Buyer"}
          </span>
          <span className="text-[#979797] text-xs">Buyer details</span>
        </div>
        <div className="flex flex-col">
          <span className="font-bold text-sm">
            {invoiceByIdData?.data?.paymentTerms || "20"}
          </span>
          <span className="text-[#979797] text-xs">Payment</span>
        </div>
        <div className="flex flex-col">
          <span className="font-bold text-sm ">
            {invoiceByIdData?.data?.currency || "NGN"}
          </span>
          <span className="text-[#979797] text-xs">Currency</span>
        </div>
      </div>

      <div className="flex mb-8 flex-col gap-4">
        <header className="border-b-[5px] border-b-[#F6F5FA] pb-3 flex items-center justify-between">
          <h1 className="text-[18px] font-normal">Line Items</h1>
          <p>
            {invoiceByIdData?.data?.lineItems?.numberOfLineItems || "5"} Total
            Items
          </p>
        </header>

        <div className=" w-full overflow-x-auto">
          <table className=" w-full overflow-x-auto">
            <thead className="bg-subtleGray w-">
              <tr className="bg-unusualGray p-2.5 ">
                <td className="text-[#11192A] p-2.5 text-xs font-bold">Item</td>
                <td className="p-2.5 text-xs font-bold">Description</td>
                <td className="p-2.5 text-xs font-bold">Unit Price</td>
                <td className="p-2.5 text-xs font-bold">Quantity</td>
                <td className="p-2.5 text-xs font-bold">
                  Amount
                  <br />
                  (before tax)
                </td>
                <td className="p-2.5 text-xs font-bold">Tax Rate</td>
                <td className="p-2.5 text-xs font-bold">Tax Amount</td>
                <td className="p-2.5 text-xs font-bold">Total Price</td>
              </tr>
            </thead>
            <tbody>
              {invoiceIdData?.data?.items?.map((item, index) => (
                <tr key={index} className=" py-[3px] px-1.5">
                  <td className=" text-xs text-[#11192a] p-2.5">
                    {item?.itemName}
                  </td>
                  <td className=" text-xs text-[#11192a] p-2.5">
                    {item?.description}
                  </td>
                  <td className=" text-xs text-[#11192a] p-2.5">
                    {invoiceByIdData?.data?.currency === "NGN" ? "₦" : "$"}{" "}
                    {item?.unitPrice}
                  </td>
                  <td className=" text-xs text-[#11192a] p-2.5">
                    {item?.quantity}
                  </td>
                  <td className=" text-xs text-[#11192a] p-2.5">
                    {invoiceByIdData?.data?.currency === "NGN" ? "₦" : "$"}{" "}
                    {(item?.unitPrice * item?.quantity).toFixed(2)}
                  </td>
                  <td className=" text-xs text-[#11192a] p-2.5">10%</td>
                  <td className=" text-xs text-[#11192a] p-2.5">$60</td>
                  <td className=" text-xs text-[#11192a] p-2.5">$600</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Accounts Information */}
        <div className="flex itemscenter justify-between px6">
          <div>
            <h1 className="border-b border-[#B1A6B2] font-semibold">
              Receiving Funding Account Information
            </h1>
            <p className="mt-3 text[#979797]">
              {invoiceByIdData?.data?.accountNumber}
            </p>
            <p className="text[#979797]">
              {invoiceByIdData?.data?.accountName}
            </p>
          </div>
          <div className="flex flex-col">
            <div className="flex justify-between gap-x-[100px] w-full">
              <span className="text-xs w-[97px]">Sub Total</span>
              <span className="text-[#979797] text-xs text-right">
                {invoiceByIdData?.data?.subTotal}
              </span>
            </div>

            <div className="flex justify-between w-full gap-x-[100px] my-2">
              <span className="flex items-center text-xs w-[97px]">
                Discount%
                <span className="border border-[#979797] pr-10 pl-2 inline-block ml-4">
                  {invoiceByIdData?.data?.discount}%
                </span>
              </span>
              <span className="text-[#979797] text-xs">
                {invoiceByIdData?.data?.discountAmount}
              </span>
            </div>

            <div className="flex justify-between w-full gap-x-[100px] pb-5">
              <span className="flex items-center text-xs w-[97px]">
                VAT%
                <span className="border border-[#979797] ml-10 pr-11 pl-2 inline-block">
                  {invoiceByIdData?.data?.vat}%
                </span>
              </span>
              <span className="text-[#979797] text-xs">
                {invoiceByIdData?.data?.vatAmount}
              </span>
            </div>

            <div className="flex justify-between items-center w-full gap-x-[100px] border-t-2 border-b-2 font-bold text-lg py-1">
              <span className="w-[97px]">Grand Total</span>
              <span className="text[#979797] py-1">
                {invoiceByIdData?.data?.grandTotal}
              </span>
            </div>
          </div>
        </div>
      </div>

      <AnimatePresence>
        <AcceptModal
          isOpen={isAcceptModalOpen}
          onClose={() => setIsAcceptModalOpen(false)}
          yes
          message={
            <>
              Would you like to proceed with sending a <br />
              <span className="text-[#07593D] font-bold">
                Payment reminder
              </span>{" "}
              now?
            </>
          }
          onConfirm={handleConfirm}
        />

        <SuccessModal
          isOpen={isSuccessModalOpen}
          onClose={handleSuccessClose}
          message={successMessage}
        />
      </AnimatePresence>
    </motion.section>
  );
}

export default DirectFundingInvoice;
