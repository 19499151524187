import { allInvoiceById } from "appstate/invoice/invoiceSlice";
import DashboardLayout from "layout/dashboardlayout";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import InvoiceDetails from "shared/InvoiceDetails";
import LoadingScreen from "shared/LoadingScreen";

const BulkInvoice = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { invoiceIdData, isLoading } = useSelector((state) => state?.invoice);

  useEffect(() => {
    dispatch(allInvoiceById({ id }));
  }, [dispatch, id]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  // Calculate the payment based on the due date and created date
  const calculatePayment = (dueDate, createdDate) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const dueDateObj = new Date(dueDate);
    const createdDateObj = new Date(createdDate?.split(" ")[0]);
    const diffDays = Math.round(
      Math.abs((dueDateObj - createdDateObj) / oneDay)
    );
    return `${diffDays} Days`;
  };

  // FORMAT AMOUNT
  const formatAmount = (amount) => {
    if (typeof amount !== "number") return "0.00";
    return amount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const data = {
    invoiceNumber: `INV-${invoiceIdData?.data?.invoiceNumber}` || "",
    referenceNumber: invoiceIdData?.data?.invoiceReference || "",
    createdDate: invoiceIdData?.data?.createdDate?.slice(0, 10) || "",
    dueDate: invoiceIdData?.data?.dueDate || "",
    payment: calculatePayment(
      invoiceIdData?.data?.dueDate,
      invoiceIdData?.data?.createdDate
    ),
    currency: invoiceIdData?.data?.currency || "",
    supplier: invoiceIdData?.data?.supplierCompanyName || "",
    buyerCompanyName: invoiceIdData?.data?.buyerCompanyName || "",
    lineItems: invoiceIdData?.data?.items?.map((item) => ({
      item: item?.itemName || "",
      poNumber: item.poNumber || "",
      productNumber: item.productNumber || "",
      productDescription: item.description || "",
      quantity: item.quantity || 0,
      unitPrice: formatAmount(item.unitPrice || ""),
      extendedAmount: formatAmount(item.amount || ""),
    })) || [],
    numberOfLineItems: invoiceIdData?.data?.numberOfLineItems || 0,
    submittedDate: invoiceIdData?.data?.submittedDate || "",
    approvedDate: invoiceIdData?.data?.approvedDate || "",
    issuanceDate: invoiceIdData?.data?.issuanceDate || "",
    accountNumber: invoiceIdData?.data?.accountNumber || "",
    accountName: invoiceIdData?.data?.accountName || "",
    subTotal: formatAmount(invoiceIdData?.data?.subTotal || 0),
    grandTotal: formatAmount(invoiceIdData?.data?.grandTotal || 0),
    discount: invoiceIdData?.data?.discount || 0,
    vat: invoiceIdData?.data?.vat || 0,
    vatAmount: formatAmount(invoiceIdData?.data?.vatAmount || 0),
    discountAmount: formatAmount(invoiceIdData?.data?.discountAmount || 0),
    paymentTerms: invoiceIdData?.data?.paymentTerms || "",
    status: invoiceIdData?.data?.status,
  };

  return (
    <DashboardLayout
      section="Payment Extension Section"
      subHeader="Send and track payment extension processes"
    >
      <InvoiceDetails data={data} showSendReminderButton={false} />
    </DashboardLayout>
  );
};

export default BulkInvoice;
