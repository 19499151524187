import React, { useState } from "react";
import Headers from "../headers";
import SingleBulkOption from "./option";

const RejectedEarlyPayments = ({ route, activeTab }) => {
  const [selectedType, setSelectedType] = useState(
    activeTab === "earlyPaymentsRejected"
      ? "All Rejected Request"
      : "Request from funder"
  );
  return (
    <div>
      <Headers
        activeTab={activeTab}
        setSelectedType={setSelectedType}
        selectedType={selectedType}
      />
      <SingleBulkOption route={route} activeTabType={selectedType} />
    </div>
  );
};

export default RejectedEarlyPayments;
