import React, { useEffect, useCallback } from "react";

import { useNavigate } from "react-router-dom";
import Table from "layout/table/Table";
import { AnimatePresence } from "framer-motion";
import { useState, useMemo } from "react";
import Pagination from "shared/Pagination";

// import { useNavigate } from "react-router-dom";
import Card from "shared/Card";
import { fetchWorkflowPendingRequests } from "appstate/workflow/workflowSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import TableEmptyState from "shared/TableEmptyState";
import { PAIconOutlineSearch } from "assets/images/svgs";

const PendingRequests = () => {
  const navigate = useNavigate();

  const [search] = useState("");
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(10);
  const pageSize = 10;

  const dispatch = useDispatch();

  const { pendingRequest } = useSelector((state) => state.workflow);
  useEffect(() => {
    dispatch(fetchWorkflowPendingRequests({ page, pageSize, search }));
  }, [dispatch, page, search, pageSize]);

  /*
   const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const pageSize = 10;
   */

  const columns = [
    {
      name: "Date Sent",
      selector: (row) => row.dateSent,
    },
    {
      name: "Transaction Type ",
      selector: (row) => row.type,
    },
    {
      name: "Action Type ",
      selector: (row) => row.action,
    },

    {
      name: "Sender",
      selector: (row) => row.sender,
    },
    {
      name: "Approval Stage",
      selector: (row) => row.approvalStage,
    },

    {
      name: "Days in Queue",
      selector: (row) => row.daysInQueue,
    },

    {
      name: "Previous Approver",
      selector: (row) => row.previousApprover,
    },

    {
      name: "Next Approver",
      selector: (row) => row.nextApprover,
    },

    // {
    //   name: "",
    //   selector: (row) => row.supplierId,
    // },
  ];

  const handleRowClick = (row) => {
    // suppliersubmitforApproval
    if (
      row.type === "EARLY_PAYMENT_OFFERS" &&
      row.action === "Accept Early Payment Offers"
    ) {
      // navigate(
      //   `/work-flow-management/detail/PendingRequestInvoiceApprovalScreen/${row.workflowId}`
      // );
      navigate(
        `/work-flow-management/detail/supplierearlypaymentrequest/${row.workflowId}`
      );
      // navigate(
      //  `/work-flow-management/detail/PendingRequestApprovalViaWalletScreen/${row.workflowId}`
      // );
    } else if (
      row.type === "INVOICING" &&
      row.action === "Submit for Approval"
    ) {
      // navigate(
      //   `/work-flow-management/detail/PendingRequestInvoiceApprovalScreen/${row.workflowId}`
      // );
      navigate(
        `/work-flow-management/detail/suppliersubmitforApproval/${row.workflowId}`
      );
      // navigate(
      //   `/work-flow-management/detail/approvalviawalletscreendetails/${row.workflowId}`
      // );
    } else if (
      row.type === "INVOICING" &&
      row.action === "Supplier Upload Invoice"
    ) {
      // navigate(
      //   `/work-flow-management/detail/PendingRequestInvoiceApprovalScreen/${row.workflowId}`
      // );
      navigate(
        `/work-flow-management/detail/suppliersubmitforApproval/${row.workflowId}`
      );
      // navigate(
      //   `/work-flow-management/detail/approvalviawalletscreendetails/${row.workflowId}`
      // );
    } else if (row.type === "INVOICING" && row.action === "Upload Invoice") {
      navigate(
        `/work-flow-management/detail/PendingRequestInvoiceApprovalScreen/${row.workflowId}`
      );
    } else if (row.type === "INVOICING" && row.action === "Approve Invoice") {
      navigate(
        `/work-flow-management/detail/PendingRequestInvoiceApprovalScreen/${row.workflowId}`
      );
    } else if (row.type === "INVOICING" && row.action === "Create Invoice") {
      navigate(
        `/work-flow-management/detail/PendingRequestInvoiceApprovalScreen/${row.workflowId}`
      );
    } else if (row.type === "INVOICING" && row.action === "Edit Invoice") {
      navigate(
        `/work-flow-management/detail/PendingRequestInvoiceApprovalScreen/${row.workflowId}`
      );
    } else if (row.type === "INVOICING" && row.action === "Delete Invoice") {
      navigate(
        `/work-flow-management/detail/PendingRequestInvoiceApprovalScreen/${row.workflowId}`
      );
    } else if (
      row.type === "EARLY_PAYMENT_REQUEST" &&
      row.action === "Approve via Wallet"
    ) {
      navigate(
        `/work-flow-management/detail/PendingRequestApprovalViaWalletScreen/${row.workflowId}`
      );
    } else if (
      row.type === "EARLY_PAYMENT_REQUEST" &&
      row.action === "Send via Direct Funding"
    ) {
      navigate(
        `/work-flow-management/detail/PendingRequestApprovalViaWalletScreen/${row.workflowId}`
      );
    } else if (
      row.type === "EARLY_PAYMENT_REQUEST" &&
      row.action === "Send via Closed Market"
    ) {
      navigate(
        `/work-flow-management/detail/PendingRequestApprovalViaWalletScreen/${row.workflowId}`
      );
    } else if (
      row.type === "EARLY_PAYMENT_REQUEST" &&
      row.action === "Approve via Direct Funding"
    ) {
      navigate(
        `/work-flow-management/detail/PendingRequestApprovalViaWalletScreen/${row.workflowId}`
      );
    } else if (
      row.type === "EARLY_PAYMENT_REQUEST" &&
      row.action === "Reject Request"
    ) {
      navigate(
        `/work-flow-management/detail/PendingRequestApprovalViaWalletScreen/${row.workflowId}`
      );
    } else if (
      row.type === "EARLY_PAYMENT_REQUEST" &&
      row.action === "Early Payment Create Request"
    ) {
      navigate(
        `/work-flow-management/detail/PendingRequestApprovalViaWalletScreen/${row.workflowId}`
      );
    } else if (
      row.type === "EARLY_PAYMENT_REQUEST" &&
      row.action === "Edit Request"
    ) {
      navigate(
        `/work-flow-management/detail/PendingRequestApprovalViaWalletScreen/${row.workflowId}`
      );
    } else if (
      row.type === "EARLY_PAYMENT_REQUEST" &&
      row.action === "Cancel Request"
    ) {
      navigate(
        `/work-flow-management/detail/PendingRequestApprovalViaWalletScreen/${row.workflowId}`
      );
    } else if (
      row.type === "DIRECT_FUNDING" &&
      row.action === "Direct Funding Create Request"
    ) {
      navigate(
        `/work-flow-management/detail/PendingRequestApprovalViaWalletScreen/${row.workflowId}`
      );
    } else if (
      row.type === "DIRECT_FUNDING" &&
      row.action === "Approve Request"
    ) {
      navigate(
        `/work-flow-management/detail/PendingRequestApprovalViaWalletScreen/${row.workflowId}`
      );
    } else if (
      row.type === "DIRECT_FUNDING" &&
      row.action === "Direct Funding Buyer's Confirmation"
    ) {
      navigate(
        `/work-flow-management/detail/PendingRequestApprovalViaWalletScreen/${row.workflowId}`
      );
    } else if (
      row.type === "EARLY_PAYMENT_OFFERS" &&
      row.action === "Create Offer"
    ) {
      navigate(
        `/work-flow-management/detail/PendingRequestApprovalViaCampagnCreationScreen/${row.workflowId}`
      );
    } else if (
      row.type === "EARLY_PAYMENT_OFFERS" &&
      row.action === "Accept Early Payment Offers"
    ) {
      navigate(
        `/work-flow-management/detail/PendingRequestApprovalViaCampagnCreationScreen/${row.workflowId}`
      );
    } else if (
      row.type === "EARLY_PAYMENT_OFFERS" &&
      row.action === "Reject Early Payment Offers"
    ) {
      navigate(
        `/work-flow-management/detail/PendingRequestApprovalViaCampagnCreationScreen/${row.workflowId}`
      );
    } else if (row.type === "PFP" && row.action === "Create Campaign") {
      navigate(
        `/work-flow-management/detail/PendingRequestApprovalViaCampagnCreationScreen/${row.workflowId}`
      );
    } else if (row.type === "PFP" && row.action === "Accept Fund Offers") {
      navigate(
        `/work-flow-management/detail/PendingRequestApprovalViaCampagnCreationScreen/${row.workflowId}`
      );
    } else if (row.type === "PFP" && row.action === "Reject Fund Offers") {
      navigate(
        `/work-flow-management/detail/PendingRequestApprovalViaCampagnCreationScreen/${row.workflowId}`
      );
    } else if (
      row.type === "OPEN_MARKET" &&
      row.action === "Open Market Buyer's Confirmation"
    ) {
      navigate(
        `/work-flow-management/detail/PendingRequestApprovalViaConfirmationAndOpenMarket/${row.workflowId}`
      );
    } else if (row.type === "OPEN_MARKET" && row.action === "Import Invoice") {
      navigate(
        `/work-flow-management/detail/PendingRequestApprovalViaConfirmationAndOpenMarket/${row.workflowId}`
      );
    } else if (row.type === "PFP" && row.action === "Sign PFP Agreement") {
      navigate(
        `/work-flow-management/detail/pendingrequestsdetailssignpfp/${row.workflowId}`
      );
    } else if (row.type === "PFP" && row.action === "Fund Offer") {
      navigate(
        `/work-flow-management/detail/PendingRequestPFPFundOffer/${row.workflowId}`
      );
    }

    // } else {
    //   navigate(
    //     `/work-flow-management/detail/PendingRequestApprovalViaDirectFundingAndConfirmationScreenDetails/${row.workflowId}`
    //   );
    // }
  };

  const [searchValue, setSearchValue] = useState(""); // To store the search input value
  const [activeFilter, setActiveFilter] = useState("");
  const [filteredData, setFilteredData] = useState(pendingRequest?.data || []); // To store the filtered data
  const data = useMemo(() => pendingRequest?.data || [], [pendingRequest]);

  /*
  INVOICING, EARLY_PAYMENT_REQUEST, EARLY_PAYMENT_OFFERS, OPEN_MARKET, DIRECT_FUNDING, PFP

  */
  useEffect(() => {
    const filterValue = activeFilter || "";

    if (filterValue === "") {
      setFilteredData(data); // Show all items
    } else if (filterValue === "INVOICING") {
      setFilteredData(data.filter((bid) => bid.type === "  INVOICING"));
    } else if (filterValue === "EARLY_PAYMENT_REQUEST") {
      setFilteredData(
        data.filter((bid) => bid.type === "EARLY_PAYMENT_REQUEST")
      );
    } else if (filterValue === "EARLY_PAYMENT_OFFERS") {
      setFilteredData(
        data.filter((bid) => bid.type === "EARLY_PAYMENT_OFFERS")
      );
    } else if (filterValue === "OPEN_MARKET") {
      setFilteredData(data.filter((bid) => bid.type === "OPEN_MARKET"));
    } else if (filterValue === "DIRECT_FUNDING") {
      setFilteredData(data.filter((bid) => bid.type === "DIRECT_FUNDING"));
    } else if (filterValue === "PFP") {
      setFilteredData(data.filter((bid) => bid.type === "PFP"));
    }
  }, [activeFilter, data]);

  useEffect(() => {
    setFilteredData(pendingRequest?.data || []); // Reset filtered data when new data is fetched
  }, [pendingRequest]);

  const filterData = useCallback(() => {
    let updatedData = [...data];

    // Apply filter by type
    if (activeFilter) {
      updatedData = updatedData.filter((item) => item.type === activeFilter);
    }

    // Apply search filter
    if (searchValue) {
      updatedData = updatedData.filter((item) => {
        const lowerSearch = searchValue.toLowerCase();
        return (
          item.dateSent.toLowerCase().includes(lowerSearch) ||
          item.type.toLowerCase().includes(lowerSearch) ||
          item.sender.toLowerCase().includes(lowerSearch) ||
          item.action.toLowerCase().includes(lowerSearch) ||
          item.nextApprover.toLowerCase().includes(lowerSearch) ||
          item.previousApprover.toLowerCase().includes(lowerSearch) ||
          (item.daysInQueue &&
            item.daysInQueue.toString().includes(lowerSearch)) ||
          (item.approvalStage &&
            item.approvalStage.toLowerCase().includes(lowerSearch))
        );
      });
    }

    setFilteredData(updatedData);
  }, [data, activeFilter, searchValue, setFilteredData]);

  useEffect(() => {
    filterData();
  }, [filterData]);

  // Synchronize data when filters or data chang

  // Synchronize data when filters or data change
  useEffect(() => {
    filterData();
  }, [filterData]);

  const handleFilterChangeOne = (e) => {
    setActiveFilter(e.target.value);
  };

  const handleSearchChangeOne = (e) => {
    setSearchValue(e.target.value);
  };

  // {
  //   page,
  //   pageSize,
  //   bulk,
  //   status,
  //   search,
  // }
  /*
  
  INVOICING, EARLY_PAYMENT_REQUEST, EARLY_PAYMENT_OFFERS, OPEN_MARKET, DIRECT_FUNDING, PFP

  */
  return (
    <div>
      <div className="my-5 grid md:grid-cols-2 items-center ">
        <div className="gap-3 flex relative">
          <div>
            <select
              className="border border-[#DEDEDE] shadow-sm rounded-[5px] px-2 py-3 text-sm focus:outline-none cursor-pointer w-44"
              // onChange={handleFilterChange}
              // value={filterValue}
              onChange={handleFilterChangeOne}
              value={activeFilter}
            >
              <option value="" disabled selected>
                Filter by{" "}
              </option>

              <option value={"INVOICING"}>INVOICING</option>
              <option value="EARLY_PAYMENT_REQUEST">
                EARLY_PAYMENT_REQUEST
              </option>
              <option value="EARLY_PAYMENT_OFFERS">EARLY_PAYMENT_OFFERS</option>
              <option value="OPEN_MARKET">OPEN_MARKET</option>
              <option value="DIRECT_FUNDING">DIRECT_FUNDING</option>
              <option value="PFP">PFP</option>
            </select>
          </div>

          {/* {activeTab === "requestshistory" && (
            <button className="py-2 px-4 rounded-[5px] bg-white border-[#DEDEDE] shadow-sm mr-2 ">
              Export
            </button>
          )} */}
        </div>
        <div className="flex md:col-span-1 justify-end sm:justify-start xs:justify-start sm:w-full sm:block xs:w-full ">
          <div>
            <div className="flex items-center lg:justify-end ">
              <div className="border border-transparent md:border-gray md:shadow-sm my-4 rounded-[5px] px-2 lg:w-[300px] bg-white sm:w-full block y-3 min-h-10">
                <div className="items-center flex bg-white">
                  <PAIconOutlineSearch />
                  <input
                    className="bg-transparent p-2 w-full focus:outline-none text-sm bg-white"
                    type="text"
                    placeholder="Search by sender Date range,  Workflow type or Approver name"
                    value={searchValue}
                    onChange={handleSearchChangeOne}
                  />
                </div>
                {/* <div className="lg:hidden flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer">
                    <PAIconFilter className="bg-[#2FA06A]" />
                  </div> */}
              </div>
            </div>
            {/* stop */}
          </div>
        </div>
      </div>
      <AnimatePresence>
        <Card className="rounded-[10px] mt-[24px]   bg-black  pb-14">
          <div className="flex justify-between items-center px-[24px]   rounded-t-[10px]  ">
            <div className="flex gap-2 my-6 justify-between">
              <div className="text-black text-[13px] font-medium items-center justify-center h-[23px]  flex ">
                Pending Request
              </div>

              <div className=" justify-center items-center flex">
                {filteredData.length > 0 && (
                  <div className="w-[23px] h-[23px]  bg-[#f08000] rounded-[100%]  justify-center items-center flex">
                    <div className="text-white text-xs font-medium  ">
                      {pendingRequest?.meta?.totalElements &&
                        pendingRequest?.meta?.totalElements}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* filter section */}

            <div className=" mr-8"></div>
          </div>
          <div className="px-[24px] ">
            {pendingRequest?.data && pendingRequest?.data?.length > 0 ? (
              <Table
                columns={columns}
                data={filteredData}
                onRowClicked={(row) => handleRowClick(row)}
                pointer
                tableHeader
              />
            ) : (
              <TableEmptyState dashboard />
            )}
          </div>

          <div>
            {pendingRequest?.meta?.totalElements &&
              pendingRequest?.meta?.totalElements > 10 && (
                <div className="flex justify-center gap-x-[8px]  mt-5">
                  <Pagination
                    page={page}
                    startPage={startPage}
                    setEndPage={setEndPage}
                    endPage={endPage}
                    setStartPage={setStartPage}
                    setPage={setPage}
                    totalPages={pendingRequest?.meta?.totalElements}
                  />
                </div>
              )}
          </div>
        </Card>
      </AnimatePresence>
    </div>
  );
};

/*
  page={page}
                  startPage={startPage}
                  setEndPage={setEndPage}
                  endPage={endPage}
                  setStartPage={setStartPage}
                  setPage={setPage}
                  totalPages={earlyPaymentData?.meta?.totalElements}

  */

export default PendingRequests;
