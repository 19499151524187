import React, { useEffect, useState } from "react";
import {
  PAIconDownload,
  PAIconFilter,
  PAIconOutlineSearch,
} from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";
import Table from "layout/table/Table";
import AcceptModal from "shared/AcceptModal";
import SuccessModal from "shared/SuccessModal";
import { useDispatch, useSelector } from "react-redux";
import {
  buyerOpenMarketPending,
  confirmBuyerOpenMarket,
  // getInvoiceBidByStatus,
} from "appstate/openMarket/openMarketSlice";
import Loading from "shared/Loading";
import Pagination from "shared/Pagination";
import { allVirtualAccounts } from "appstate/wallet/walletSlice";
// import AcceptModal from "shared/AcceptModal";
import PinModal from "shared/PinModal";
import FundAccountOption from "features/wallet(new)/overview/FundAccount/ExternalSource";
// import SuccessModal from "shared/SuccessModal";

const Pending = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  // const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  // const [successMessage, setSuccessMessage] = useState("");

  const [isSelectAccountModalOpen, setIsSelectAccountModalOpen] =
    useState(false);
  const [isProceedModalOpen, setIsProceedModalOpen] = useState(false);
  const [fundAccountPinModal, setFundAccountPinModal] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(10);
  const pageSize = 10;

  const handleSearch = () => {
    dispatch(buyerOpenMarketPending({ searchKey: searchQuery }));
  };

  useEffect(() => {
    dispatch(
      buyerOpenMarketPending({ page, pageSize, searchKey: searchQuery })
    );
  }, [dispatch, page, pageSize, searchQuery]);

  const { buyerOpenMarketPendingData, isLoading } = useSelector(
    (state) => state?.openMarket
  );
  const selectedAccounts = useSelector(
    (state) => state?.wallet?.selectedAccounts
  );

  // console.log("ddd", buyerOpenMarketPendingData);

  useEffect(() => {
    dispatch(allVirtualAccounts());
  }, [dispatch]);

  const openMarketDetails = (openMarketReference) => {
    let path = `/factoring/facopenmarketpendingdetail/${openMarketReference}`;
    navigate(path);
  };

  // const handleSelect = (option) => {
  //   setSelectedOption(option.value);
  //   setIsOpen(false);
  // };

  // FORMAT AMOUNT
  const formatAmount = (amount) => {
    if (typeof amount !== "number") return "0.00";
    return amount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  // SEND REMAINDER BUTTON

  // SELECT ALL
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
      // setSelectAllText("Select All");
    } else {
      let allIds = [];

      allIds = buyerOpenMarketPendingData?.data?.map(
        (row) => row.openMarketReference
      );
      setSelectedRows(allIds);
      // setSelectAllText("Deselect All");
    }
    setSelectAll(!selectAll);
  };

  // CHECKBOX
  const handleCheckboxChange = (row) => {
    const isSelected = selectedRows.includes(row.openMarketReference);
    let newSelectedRows = [];

    if (isSelected) {
      newSelectedRows = selectedRows.filter(
        (openMarketReference) => openMarketReference !== row.openMarketReference
      );
    } else {
      newSelectedRows = [...selectedRows, row.openMarketReference];
    }

    setSelectedRows(newSelectedRows);
  };

  useEffect(() => {
    // console.log(selectedRows);
  }, [selectedRows]);

  // Function to handle account submission
  const handleAccountSubmit = () => {
    setIsSelectAccountModalOpen(false);
    setIsProceedModalOpen(true);
  };

  // Function to handle proceeding with the selected account
  const handleProceedSubmit = () => {
    setIsProceedModalOpen(false);
    setFundAccountPinModal(true);
  };

  // const handleApproveInvoice = (pin) => {
  //   setFundAccountPinModal(false);
  //   setIsSuccessModalOpen(true);
  //   setSuccessMessage("Confirmation successful");

  //   // const approvedByBuyer = {
  //   //   openMarketIds:
  //   //   virtualAccountId: selectedAccounts?.id,
  //   //   transactionPin: pin,
  //   // };

  //   // dispatch(confirmBuyerOpenMarket({ body: approvedByBuyer })).then((data) => {
  //   //   if (data?.payload?.success) {
  //   //     setIsSuccessModalOpen(true);
  //   //     setFundAccountPinModal(false);
  //   //     setSuccessMessage("Invoice upload has been approved successfully");
  //   //     // dispatch(allBuyerInvoiceDataByStatus({ status }));
  //   //   }
  //   // });
  // };
  const handleApproveInvoice = (pin) => {
    // Get the openMarketIds from the selected rows
    const openMarketIds = selectedRows
      .map((selectedId) => {
        const selectedInvoice = buyerOpenMarketPendingData?.data?.find(
          (invoice) => invoice.openMarketReference === selectedId
        );
        return selectedInvoice ? selectedInvoice.openMarketReference : null;
      })
      .filter((id) => id !== null);

    const approvedByBuyer = {
      openMarketReference: openMarketIds,
      virtualAccountId: selectedAccounts?.id,
      transactionPin: pin,
    };

    dispatch(confirmBuyerOpenMarket({ body: approvedByBuyer })).then((data) => {
      if (data?.payload?.success) {
        setIsSuccessModalOpen(true);
        setFundAccountPinModal(false);
        setSuccessMessage(data?.payload?.message);
        dispatch(buyerOpenMarketPending({ page, pageSize }));
      } 
    });
  };

  // const dummyData = [
  //   {
  //     id: 1,
  //     reference: "R3456X89Y",
  //     invoiceNumber: 12345,
  //     supplier: "XYZ Ventures",
  //     invoiceDate: "2023-05-20",
  //     paymentDate: "2023-05-23",
  //     currency: "NGN",
  //     requestAmount: "9,000,000",
  //     status: "PENDING",
  //   },
  //   {
  //     id: 2,
  //     reference: "R3456X90Z",
  //     invoiceNumber: 12346,
  //     supplier: "ABC Supplies",
  //     invoiceDate: "2023-05-21",
  //     paymentDate: "2023-05-23",
  //     currency: "NGN",
  //     requestAmount: "8,500,000",
  //     status: "PENDING",
  //   },
  // ];

  const columns = [
    {
      name: (
        <input type="checkbox" onChange={handleSelectAll} checked={selectAll} />
      ),
      cell: (row) => (
        <input
          type="checkbox"
          onChange={() => handleCheckboxChange(row)}
          checked={selectedRows.includes(row.openMarketReference)}
        />
      ),
      width: "50px",
    },
    {
      name: (
        <span className="whitespace-normal">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Request reference
        </span>
      ),
      selector: (row) => row.openMarketReference,
      sortable: true,
    },
    {
      name: "Invoice Number",
      selector: (row) => {
        const invoiceNumber = row.invoiceNumber;
        return invoiceNumber && !isNaN(invoiceNumber)
          ? `INV-${invoiceNumber}`
          : "INV-0";
      },
      sortable: true,
    },
    {
      name: "Supplier",
      selector: (row) => row.supplierCompanyName,
      sortable: true,
    },
    {
      name: "Invoice Date",
      selector: (row) => row.invoiceDate,
      sortable: true,
    },
    {
      name: "Payment Date",
      selector: (row) => row.paymentDate,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
    },
    {
      name: "Request Amount",
      selector: (row) => row.fundingAmount,
      sortable: true,
      cell: (row) => (
        <p className="text-success">{formatAmount(row.requestAmount)}</p>
      ),
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => <p className="text-primaryColor">{row.status}</p>,
    },
  ];

  return (
    <>
      <div className="my-2 grid md:grid-cols-2 items-center">
        <div className="gap-3 flex relative">
          {/* EXPORT */}
          <button className="py-2 px-4 rounded-[5px] bg-white border-[#DEDEDE] shadow-sm mr-2 flex items-center justify-center gap-1.5">
            Export {<PAIconDownload className="accent-gray300 w-4" />}
          </button>
        </div>

        {/* SEARCH */}
        <div className="flex md:col-span-1 justify-end">
          <div className="flex items-center justify-between gap-5">
            <div className="border border-transparent md:border-gray md:shadow-sm my-4 rounded-[5px] px-2 lg:w-[300px] bg-white">
              <div className="items-center flex bg-white">
                <PAIconOutlineSearch />
                <input
                  className="bg-transparent p-2 w-full focus:outline-none text-sm bg-white"
                  type="text"
                  placeholder="Search for invoices"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleSearch();
                    }
                  }}
                />
              </div>
              <div className="lg:hidden flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer">
                <PAIconFilter className="bg-[#2FA06A]" />
              </div>
            </div>
            <button className="hidden lg:flex bg-[#2FA06A] rounded-md my-4 py-2 px-6 mx3 items-center gap-2 text-white">
              Search
            </button>
          </div>
        </div>
      </div>

      {/* TABLE */}
      <div>
        {!buyerOpenMarketPendingData?.data?.length ? (
          <div>
            <div className="flex justify-between items-center px-[15px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
              <p className="text-black text-sm font-medium">
                Pending your confirmation
              </p>
            </div>
            <TableEmptyState dashboard />
          </div>
        ) : (
          <Card className="rounded-[10px] mt-[15px]">
            <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
              <div>
                <p className="text-black text-lg font-bold">
                  Invoices from Open Market awaiting collection
                </p>
                <span className="text-xs font-thin">
                  Invoice request awaiting your confirmation by your supplier to
                  be posted on the open market
                </span>
              </div>
              <div className="flex items-center justify-center gap-3">
                <button
                  className={`border border-gray300 rounded-md p-2 px-4 text-white ${
                    selectedRows?.length === 0
                      ? "cursor-not-allowed bg-gray300"
                      : "cursor-pointer bg-primaryColor"
                  }`}
                  disabled={selectedRows?.length === 0}
                  onClick={() => setIsSelectAccountModalOpen(true)}
                >
                  <p>Confirm</p>
                </button>
              </div>
            </div>
            <div className="pb-[24px]">
              <Table
                columns={columns}
                data={buyerOpenMarketPendingData?.data}
                onRowClicked={(row) =>
                  openMarketDetails(row.openMarketReference)
                }
                progressPending={isLoading}
                progressComponent={<Loading />}
                pointer
                tableHeader
                className="mt-5"
              />
              <div className="flex justify-center gap-x-[8px] mt-[20px]">
                {buyerOpenMarketPendingData?.data?.length > 0 && (
                  <Pagination
                    page={page}
                    startPage={startPage}
                    setEndPage={setEndPage}
                    endPage={endPage}
                    setStartPage={setStartPage}
                    setPage={setPage}
                    totalPages={buyerOpenMarketPendingData?.meta?.totalElements}
                  />
                )}
              </div>
            </div>
          </Card>
        )}
      </div>

      <FundAccountOption
        isOpen={isSelectAccountModalOpen}
        onClose={() => setIsSelectAccountModalOpen(false)}
        HeaderText={
          <p>
            Select which account to fund with <br />
          </p>
        }
        onSubmit={handleAccountSubmit}
      />

      <AcceptModal
        isOpen={isProceedModalOpen}
        onClose={() => setIsProceedModalOpen(false)}
        message={
          <>
            Would you like to proceed with this account{" "}
            <span className="text-[#07593D] font-bold">
              {selectedAccounts?.accountNumber}
            </span>{" "}
            for <br /> reimbursement?
          </>
        }
        onConfirm={handleProceedSubmit}
        loading={false}
      />
      <PinModal
        isOpen={fundAccountPinModal}
        onClose={() => {
          setFundAccountPinModal(false);
        }}
        onSubmit={handleApproveInvoice}
        // isLoading={isLoading}
        headerText="Enter Pin"
        instructionText="Enter your transaction PIN to verify your identity."
      />
      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={() => setIsSuccessModalOpen(false)}
        message={successMessage}
      />
    </>
  );
};

export default Pending;
