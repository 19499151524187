import { createNewSupplier } from "appstate/auth/authSlice";
import { PAIconArrowLeft, PAIconEye, PAIconVieweye } from "assets/images/svgs";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import InputField from "shared/InputField";

import Spinner from "shared/Spinner/Spinner";
import { supplierValidationSchema } from "validation/supplier/SuppliersignupSchema";
import PasswordLength from "../../../shared/PasswordLength";
import OnboardingLayout from "../category/components/OnboardingLayout";
import {
  updateAgreementDocumentAccepted,
  updateUploadBankDetails,
  updateUploadKycDocument,
} from "./supplierVerifcationSlice/verificationActions";
import MailsentConfirmationModal from "../MailsentConfirmationModal/MailsentConfirmationModal";
import Contact from "../components/Contact";

const SupplierSignup = () => {
  // eslint-disable-next-line
  const [isFocused, setIsFocused] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState("+234");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmedPassword, setShowConfirmedPassword] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const { isLoading } = useSelector((state) => state?.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const calculateStrength = (value) => {
    let strength = 0;
    const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*()])(?=.*[A-Z]).{6,}$/;

    if (/\d/.test(value)) {
      strength++;
    }
    if (/[!@#$%^&*]/.test(value)) {
      strength++;
    }
    if (/[A-Z]/.test(value)) {
      strength++;
    }
    if (value.length >= 6) {
      strength++;
    }

    if (passwordRegex.test(value)) {
      strength++;
    }

    return strength;
  };

  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      phoneNumber: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: supplierValidationSchema(selectedCountryCode),
    onSubmit: (values) => {
      const phoneNumberWithCountryCode = `${selectedCountryCode}${values.phoneNumber}`;
      const body = {
        fullName: values.fullName,
        email: values.email,
        phoneNumber: phoneNumberWithCountryCode,
        password: values.password,
        role: "SUPPLIER",
      };

      dispatch(createNewSupplier({ body })).then((data) => {
        // const email = data?.payload?.data?.email;
        if (data?.payload?.status === 200) {
          setShowModal(true);
          // let path = "/resend-verification";
          // navigate(path, { state: email });
        }
      });
    },
  });
  const handlePhoneCountryChange = (selectedCountryCode) => {
    setSelectedCountryCode(selectedCountryCode); // Update selected country code in state
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmedPassword(!showConfirmedPassword);
  };

  const goback = () => {
    navigate(-1);
  };

  useEffect(() => {
    dispatch(updateUploadBankDetails(false));
    dispatch(updateUploadKycDocument(false));
    dispatch(updateAgreementDocumentAccepted(false));
  }, [dispatch]);

  // To control disabled button
  useEffect(() => {
    if (
      formik.values.fullName &&
      formik.values.password &&
      formik.values.phoneNumber &&
      formik.values.email &&
      formik.values.confirmPassword &&
      formik.isValid
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [
    formik.isValid,
    formik.values.confirmPassword,
    formik.values.email,
    formik.values.fullName,
    formik.values.password,
    formik.values.phoneNumber,
  ]);

  return (
    <OnboardingLayout nohelp>
      <PAIconArrowLeft className="cursor-pointer mx-5 mt-4" onClick={goback} />
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>
      )}
      <div className="mt-[25px] flex justify-center items-center">
        <div>
          <p className="font-normal text-xs text-textColor mb-[10px] md:text-sm">
            Personal Details
          </p>
          <div className="w-[100px] h-[2px] bg-[#F08000] rounded-[100px] md:w-[220px]"></div>
        </div>
        <div className="ml-[15px] mt-[28px]">
          <div className="w-[100px] h-[2px] bg-[#D9D9D9] rounded-[100px] md:w-[220px]"></div>
        </div>
        <div className="ml-[15px] mt-[28px]">
          <div className="w-[100px] h-[2px] bg-[#D9D9D9] rounded-[100px] md:w-[220px]"></div>
        </div>
        <div className="ml-[15px] mt-[28px]">
          <div className="w-[100px] h-[2px] bg-[#D9D9D9] rounded-[100px] md:w-[220px]"></div>
        </div>
      </div>

      <div className="pt-[60px] flex items-center flex-col h-[100%] overflow-auto ">
        <div class="text-[#222222] text-[28px]  font-medium">
          Welcome to PayEdge
        </div>
        {/* <p className="font-medium text-[18px] text-semibold text-textColor text-center">
          Create an account
        </p> */}
        <div class="text-[#222222] text-xl font-medium mt-3">
          Create an account
        </div>
        <p className="font-light text-[15px] text-semibold text-[#7A7A7A] text-center  mb-[20px] mt-1">
          Please enter your personal details
        </p>
        <form
          onSubmit={formik.handleSubmit}
          className="flex justify-center flex-col"
        >
          <div className="mb-[30px]">
            <InputField
              className="w-[300px] py-[13px] outline-none  md:w-[424px]"
              placeholder="Full Name"
              label="Full Name"
              id="fullName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.fullName}
            />
            {formik.touched.fullName && formik.errors.fullName ? (
              <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.fullName}
              </p>
            ) : null}
          </div>
          <div className="mb-[30px]">
            <Contact
              name="phoneNumber"
              title={"Contact Number"}
              id="phoneNumber"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phoneNumber}
              onCountryCodeChange={handlePhoneCountryChange}
            />
            {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
              <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.phoneNumber}
              </p>
            ) : null}
          </div>
          <div className="mb-[30px]">
            <InputField
              className="w-[300px] py-[13px] outline-none md:w-[424px]"
              placeholder="exampleforyou@info.com"
              label="Email Address"
              id="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.email}
              </p>
            ) : null}
          </div>
          <div className="mb-[30px]">
            <InputField
              className="w-[300px] py-[13px] outline-none  md:w-[424px]"
              name="password"
              type={showPassword ? "text" : "password"}
              label="Password"
              id="password"
              placeholder="........"
              onChange={formik.handleChange}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              value={formik.values.password}
              autoComplete="new-password"
            />
            {showPassword ? (
              <PAIconVieweye
                onClick={toggleShowPassword}
                className="float-right mr-[6px] mt-[-30px] relative z-2 cursor-pointer"
              />
            ) : (
              <PAIconEye
                onClick={toggleShowPassword}
                className="float-right mr-[6px] mt-[-30px] relative z-2 cursor-pointer"
              />
            )}

            {formik.touched.password && formik.errors.password ? (
              <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.password}
              </p>
            ) : null}

            <div className="flex justify-between mt-[10px]">
              <PasswordLength
                className={`strength-bar } ${
                  calculateStrength(formik.values.password) === 1
                    ? "bg-red"
                    : calculateStrength(formik.values.password) === 2
                    ? "bg-yellow-500"
                    : calculateStrength(formik.values.password) === 3
                    ? "bg-yellow-500"
                    : calculateStrength(formik.values.password) >= 4
                    ? "bg-green-500"
                    : "bg-red-500"
                }`}
              />
              <PasswordLength
                className={`strength-bar } ${
                  calculateStrength(formik.values.password) === 2
                    ? "bg-yellow-500"
                    : calculateStrength(formik.values.password) === 3
                    ? "bg-yellow-500"
                    : calculateStrength(formik.values.password) >= 4
                    ? "bg-green-500"
                    : ""
                }`}
              />
              <PasswordLength
                className={`strength-bar ${
                  calculateStrength(formik.values.password) === 3
                    ? "bg-yellow-500"
                    : calculateStrength(formik.values.password) >= 4
                    ? "bg-green-500"
                    : ""
                }`}
              />
              <PasswordLength
                className={`strength-bar  ${
                  calculateStrength(formik.values.password) >= 4
                    ? "bg-green-500"
                    : ""
                }`}
              />
            </div>
          </div>
          <div className="mb-[30px]">
            <InputField
              className="w-[300px] py-[13px] outline-none md:w-[424px]"
              placeholder="........"
              label="Confirm Password"
              type={showConfirmedPassword ? "text" : "password"}
              id="confirmPassword"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confirmPassword}
            />

            {showConfirmedPassword ? (
              <PAIconVieweye
                onClick={toggleShowConfirmPassword}
                className="float-right mr-[6px] mt-[-30px] relative z-2 cursor-pointer"
              />
            ) : (
              <PAIconEye
                onClick={toggleShowConfirmPassword}
                className="float-right mr-[6px] mt-[-30px] relative z-2 cursor-pointer"
              />
            )}
            <div className="flex justify-between mt-[10px]">
              <PasswordLength
                className={`strength-bar } ${
                  calculateStrength(formik.values.confirmPassword) === 1
                    ? "bg-red"
                    : calculateStrength(formik.values.confirmPassword) === 2
                    ? "bg-yellow-500"
                    : calculateStrength(formik.values.confirmPassword) === 3
                    ? "bg-yellow-500"
                    : calculateStrength(formik.values.confirmPassword) >= 4
                    ? "bg-green-500"
                    : "bg-red-500"
                }`}
              />
              <PasswordLength
                className={`strength-bar } ${
                  calculateStrength(formik.values.confirmPassword) === 2
                    ? "bg-yellow-500"
                    : calculateStrength(formik.values.confirmPassword) === 3
                    ? "bg-yellow-500"
                    : calculateStrength(formik.values.confirmPassword) >= 4
                    ? "bg-green-500"
                    : ""
                }`}
              />
              <PasswordLength
                className={`strength-bar ${
                  calculateStrength(formik.values.confirmPassword) === 3
                    ? "bg-yellow-500"
                    : calculateStrength(formik.values.confirmPassword) >= 4
                    ? "bg-green-500"
                    : ""
                }`}
              />
              <PasswordLength
                className={`strength-bar  ${
                  calculateStrength(formik.values.confirmPassword) >= 4
                    ? "bg-green-500"
                    : ""
                }`}
              />
            </div>

            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
              <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.confirmPassword}
              </p>
            ) : null}
          </div>

          <button
            disabled={isLoading ? isLoading : isDisabled}
            auth
            className={`my-[30px]   md:w-[424px]  rounded-[5px] text-sm font-medium h-[45px] w-full text-[#222222] border-none  ${
              isDisabled
                ? "bg-[#d7d7d7] text-[#7a7a7a] "
                : "  bg-primaryColor text-white"
            }`}
            type="submit"
          >
            {isLoading ? <Spinner /> : "Create Account"}
          </button>
        </form>
      </div>
      {showModal && <MailsentConfirmationModal email={formik.values.email} />}
    </OnboardingLayout>
  );
};

export default SupplierSignup;
