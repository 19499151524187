import React, { useState } from "react";
import DashboardLayout from "layout/dashboardlayout";
import { PAIconArrowLeftGreen } from "assets/images/svgs";
import { useNavigate } from "react-router-dom";

import { AnimatePresence } from "framer-motion";

import TransitionScale from "shared/animation/TransitionScale";
import PendingApprovalSucessModal from "../components/modal/PendingApprovalSucessModals";
import PendingApprovalProceedModal from "../components/modal/PendingApprovalProceedModal";
import PendingApprovalDeclineSucessModal from "../components/modal/PendingApprovalDeclineSuccess";
import PendingApprovalDeclineModal from "../components/modal/PendingApprovalDeclineModal";
import ReasonForDeclineModal from "../components/modal/ReasonForDeclineModal";

// import { useNavigate } from "react-router-dom";

const DeclinedRequestsDetailsScreen = () => {
  const navigate = useNavigate();
  const [ongoingCancelBidModal, setOngoingCancelBidModal] = useState(false);
  const [ongoingBidCancelledSuccessModal, setOngoingBidCancelledSuccessModal] =
    useState(false);

  const [ongoingCancelBidModal2, setOngoingCancelBidModal2] = useState(false);
  const [
    ongoingBidCancelledSuccessModal2,
    setOngoingBidCancelledSuccessModal2,
  ] = useState(false);
  const [reasonforDecline, setReasonForDecline] = useState(false);

  const handleOnCancelSuccessClose = () => {
    setOngoingBidCancelledSuccessModal(false);
  };
  const handleOnCancelBidTrue = () => {
    setOngoingCancelBidModal(false);
    setOngoingBidCancelledSuccessModal(true);
  };
  const handleOnCancelSuccessClose2 = () => {
    setOngoingBidCancelledSuccessModal2(false);
  };
  const handleOnCancelBidTrue2 = () => {
    setOngoingCancelBidModal2(false);
    // setOngoingBidCancelledSuccessModal2(true);
    setReasonForDecline(true);
  };
  const handleBack = () => {
    navigate(-1);
  };
  const handleDeclineContinue = () => {
    setReasonForDecline(false);
    setOngoingBidCancelledSuccessModal2(true);
  };

  const dummyData = [
    {
      bidAmount: 3,
      item: "Solar Panel ",
      description: "Dangote 12 x 4 ultra high density so..",
      unitPrice: "340,000",
      Quantity: "3",
      amount: "1,020,000",
      taxRate: "1.2%",
      taxAmount: "12,240",
      totalPrice: "1,032,240",
    },

    {
      bidAmount: 4,
      item: "Solar Panel ",
      description: "Dangote 12 x 4 ultra high density so..",
      unitPrice: "340,000",
      Quantity: "3",
      amount: "1,020,000",
      taxRate: "1.2%",
      taxAmount: "12,240",
      totalPrice: "1,032,240",
    },

    {
      bidAmount: 5,
      item: "Solar Panel ",
      description: "Dangote 12 x 4 ultra high density so..",
      unitPrice: "340,000",
      Quantity: "3",
      amount: "1,020,000",
      taxRate: "1.2%",
      taxAmount: "12,240",
      totalPrice: "1,032,240",
    },
    {
      bidAmount: 6,
      item: "Solar Panel ",
      description: "Dangote 12 x 4 ultra high density so..",
      unitPrice: "340,000",
      Quantity: "3",
      amount: "1,020,000",
      taxRate: "1.2%",
      taxAmount: "12,240",
      totalPrice: "1,032,240",
    },
    {
      bidAmount: 7,
      item: "Solar Panel ",
      description: "Dangote 12 x 4 ultra high density so..",
      unitPrice: "340,000",
      Quantity: "3",
      amount: "1,020,000",
      taxRate: "1.2%",
      taxAmount: "12,240",
      totalPrice: "1,032,240",
    },
  ];

  return (
    <DashboardLayout
      section="Workflow "
      subHeader="Manage all your workflow approvals"
    >
      <TransitionScale>
        <PendingApprovalProceedModal
          isOpen={ongoingCancelBidModal}
          onClose={() => setOngoingCancelBidModal(false)}
          handleYes={handleOnCancelBidTrue}
        />
      </TransitionScale>
      <TransitionScale>
        <PendingApprovalSucessModal
          isOpen={ongoingBidCancelledSuccessModal}
          onClose={handleOnCancelSuccessClose}
          message={"Request Approved"}
        />
      </TransitionScale>
      <TransitionScale>
        <PendingApprovalProceedModal
          isOpen={ongoingCancelBidModal}
          onClose={() => setOngoingCancelBidModal(false)}
          handleYes={handleOnCancelBidTrue}
        />
      </TransitionScale>

      <AnimatePresence>
        <TransitionScale>
          <PendingApprovalDeclineSucessModal
            isOpen={ongoingBidCancelledSuccessModal2}
            onClose={handleOnCancelSuccessClose2}
            message={"Workflow Declined"}
          />
        </TransitionScale>
      </AnimatePresence>
      <AnimatePresence>
        <TransitionScale>
          <PendingApprovalDeclineModal
            isOpen={ongoingCancelBidModal2}
            onClose={() => setOngoingCancelBidModal2(false)}
            handleYes={handleOnCancelBidTrue2}
            message={" Are you sure you would like to confirm this "}
            midMessage={" decline of workflow?"}
          />
        </TransitionScale>
      </AnimatePresence>
      <AnimatePresence>
        <TransitionScale>
          {reasonforDecline && (
            <ReasonForDeclineModal
              onClose={() => setReasonForDecline(false)}
              onContinue={handleDeclineContinue}
            />
          )}
        </TransitionScale>
      </AnimatePresence>

      <div className=" lg:min-h-screen">
        {" "}
        <header className="bg-white text-primaryColor px-6  rounded-[5px] ">
          <span
            onClick={() => handleBack()}
            className="flex cursor-pointer py-4 w-fit font-light items-center text-sm gap-2"
          >
            <PAIconArrowLeftGreen />
            Go Back
          </span>
        </header>
        <div className=" mt-5 flex items-end w-full justify-end ">
          <div className=" flex gap-3">
            <button
              className={` w-24   py-2 px-3  text-[#222222] justify-center  flex items-center bg-[#D6D6D6] rounded-[5px]  text-sm ${"cursor-pointer"}`}
              // onClick={() => setOngoingCancelBidModal(true)}
              onClick={() => setOngoingCancelBidModal2(true)}
              // disabled={termsIsVisible ? false : true}
            >
              Confirm
            </button>
            <button
              className={`w-24  px-3 text-white justify-center  flex items-center bg-[#07593d] rounded-[5px] py-2 text-sm ${"cursor-pointer"}`}
              onClick={() =>
                navigate("/invoice/invoice/fundingSection/invoiceupload")
              }
            >
              Resubmit
            </button>
          </div>
        </div>
        <>
          <TransitionScale className="px-6 py-5 bg-white  rounded-[10px] mt-8">
            <div>
              <div class="text-black text-sm font-bold ">Decline Reason</div>
              <div class="text-[#222222] text-base font-normal  mt-6">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse varius enim in eros elementum tristique. Duis
                cursus, mi quis viverra ornare, eros dolor interdum nulla, ut
                commodo diam libero dolor eros.Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. Suspendisse varius enim in eros
                elementum tristique. Duis cursus, mi quis viverra ornare, eros
                dolor interdum nulla, ut commodo diam libero dolor eros.
              </div>
              <div class=" mt-2 justify-start items-center gap-2 inline-flex">
                <div class="text-[#7a7a7a] text-sm font-normal ">
                  Declined by:
                </div>
                <div class="text-[#222222] text-sm font-semibold ">Admin</div>
              </div>
            </div>
          </TransitionScale>

          <TransitionScale className="px-6 pt-6 pb-8 bg-white  rounded-[10px] mt-5">
            <div className="lg:flex md:flex justify-between  w-full sm:block  ">
              <div>
                {" "}
                <div class="text-[#11192a] text-xs font-bold ">
                  Approval Stage
                </div>
              </div>

              <div>
                {" "}
                <div class="h-[30px] px-8 py-2 bg-[#de1515]/30 rounded-[5px] justify-center items-center gap-2.5 inline-flex">
                  <div class="text-center text-[#de1515] text-xs font-semibold">
                    Declined
                  </div>
                </div>
              </div>
            </div>

            <div className="lg:flex md:flex justify-between  w-full sm:block  mt-6 ">
              {/* underlined div */}
              <div className="">
                <div>
                  <span className="text-[#7a7a7a] text-xs font-bold ">
                    User
                  </span>
                  <span className="text-[#7a7a7a] text-xs font-normal ">
                    {" "}
                    to approve
                  </span>
                </div>
                <div className="mb-[15px] mt-[10px] flex justify-center items-center gap-x-[15px] ">
                  <div>
                    <div className="w-[100px] h-1   bg-[#F08000]  rounded-[100px] lg:w-[150px]  xs:w-[70px]"></div>
                  </div>
                  <div>
                    <div className="w-[100px] h-1    bg-[#F08000]/30  rounded-[100px] lg:w-[150px]  xs:w-[70px]"></div>
                  </div>
                  <div>
                    <div className="w-[100px] h-1  bg-[#F08000]/30  rounded-[100px] lg:w-[150px] xs:w-[70px] "></div>
                  </div>
                </div>
              </div>

              {/* Button division */}

              <div>
                {" "}
                {/* <div class="h-[30px] px-8 py-2 bg-[#07593d]/30 rounded-[5px] justify-center items-center gap-2.5 inline-flex">
                  <div class="text-center text-[#07593d] text-xs font-semibold">
                    Completed
                  </div>
                </div> */}
              </div>
            </div>

            {/* Second Box phase and pages */}
            <div className="lg:flex md:flex justify-between  w-full sm:block  mt-6 ">
              <div>
                <div class=" flex-col justify-center items-start gap-2 inline-flex">
                  <div class="self-stretch justify-between items-center inline-flex gap-28">
                    <div class="text-[#222222] text-[14px] font-semibold">
                      Date :
                    </div>
                    <div class="text-[#7a7a7a] text-[14px] font-medium ">
                      2023-02-19
                    </div>
                  </div>
                  <div class="self-stretch justify-between items-center inline-flex gap-28">
                    <div class="text-[#222222] text-[14px] font-semibold">
                      Initiator:
                    </div>
                    <div class="text-[#7a7a7a] text-[14px] font-medium ">
                      First User
                    </div>
                  </div>
                  <div class="self-stretch justify-between items-center inline-flex  gap-28">
                    <div class="text-[#222222] text-[14px] font-semibold">
                      Transaction Type:
                    </div>
                    <div class="text-[#7a7a7a] text-[14px] font-medium ">
                      Invoice
                    </div>
                  </div>
                  <div class="self-stretch justify-between items-center inline-flex">
                    <div class="text-[#222222] text-[14px] font-semibold">
                      Approval Stage:
                    </div>
                    <div class="text-[#7a7a7a] text-[14px] font-medium ">
                      1 of 3
                    </div>
                  </div>
                </div>
              </div>

              {/* Second side */}

              <div>
                <div class=" flex-col justify-center items-start gap-2 inline-flex">
                  <div class="self-stretch justify-between items-center inline-flex gap-28">
                    <div class="text-[#222222] text-[14px] font-semibold">
                      Sender:
                    </div>
                    <div class="text-[#7a7a7a] text-[14px] font-medium ">
                      First User
                    </div>
                  </div>
                  <div class="self-stretch justify-between items-center inline-flex gap-28">
                    <div class="text-[#222222] text-[14px] font-semibold">
                      Previous Approver:
                    </div>
                    <div class="text-[#7a7a7a] text-[14px] font-medium ">
                      Admin
                    </div>
                  </div>
                  <div class="self-stretch justify-between items-center inline-flex  gap-28">
                    <div class="text-[#222222] text-[14px] font-semibold">
                      Next Approver:
                    </div>
                    <div class="text-[#7a7a7a] text-[14px] font-medium ">
                      Sam Johnson
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TransitionScale>

          <TransitionScale className=" pb-10 bg-white  rounded-[10px] mt-5">
            <div class="h-[49px] px-6 py-4  flex-col justify-start items-start gap-2.5 inline-flex rounded-t-[10px] bg-[#fafafa] w-full">
              <div class=" justify-start items-center inline-flex">
                <div class="justify-start items-end gap-[33px] flex">
                  <div class="text-[#1d0220] text-sm font-bold ">
                    Approval Document
                  </div>
                </div>
              </div>
            </div>

            {/* Header with Left Padding */}
            <div className=" pl-6  mt-6">
              <div class="h-[51px] px-2.5 py-[13px] bg-neutral-50 flex-col justify-center items-start gap-2.5 inline-flex bg-[#fafafa] w-full">
                <div class="h-[25px] py-1 rounded-[5px] flex-col justify-center items-start gap-[7px] flex">
                  <div class="text-black text-sm font-medium">Invoices</div>
                </div>
              </div>
              <div className=" pr-6">
                <div className=" w-full bg-[#b1a6b2] h-[1.5px]"></div>
              </div>
            </div>

            {/* Other sections */}

            <section className="px-6 mt-4">
              <div class="text-[#222222] text-lg font-semibold mt-4">
                Invoice | INV-2002
              </div>

              {/* Down Flex Texts */}
              <div class="lg:w-[229px]  sm:w-full h-[117px] flex-col justify-start items-start gap-3 inline-flex">
                <div class="self-stretch justify-between items-center inline-flex">
                  <div class="text-[#222222] text-base font-semibold ">
                    Reference No:{" "}
                  </div>
                  <div class="text-[#555555] text-sm font-semibold ">
                    0983018374BA
                  </div>
                </div>
                <div class="self-stretch h-[86px] flex-col justify-center items-start gap-1 flex">
                  <div class="self-stretch justify-between items-center inline-flex">
                    <div class="text-[#222222] text-xs font-semibold ">
                      Created Date:{" "}
                    </div>
                    <div class="text-[#7a7a7a] text-xs font-semibold ">
                      23-Mar -2023
                    </div>
                  </div>
                  <div class="self-stretch justify-between items-center inline-flex">
                    <div class="text-[#222222] text-xs font-semibold ">
                      Submitted Date:{" "}
                    </div>
                    <div class="text-[#7a7a7a] text-xs font-semibold ">
                      23-Mar -2023
                    </div>
                  </div>
                  <div class="self-stretch justify-between items-center inline-flex">
                    <div class="text-[#222222] text-xs font-semibold ">
                      Approved Date:{" "}
                    </div>
                    <div class="text-[#7a7a7a] text-xs font-semibold ">
                      23-April -2023
                    </div>
                  </div>
                  <div class="self-stretch justify-between items-center inline-flex">
                    <div class="text-[#222222] text-xs font-semibold ">
                      Due Date:{" "}
                    </div>
                    <div class="text-[#7a7a7a] text-xs font-semibold ">
                      30-May-2023
                    </div>
                  </div>
                  <div class="self-stretch justify-between items-center inline-flex">
                    <div class="text-[#222222] text-xs font-semibold ">
                      Issuance Date:{" "}
                    </div>
                    <div class="text-[#7a7a7a] text-xs font-semibold ">
                      30-Dec-2022
                    </div>
                  </div>
                </div>
              </div>
              <div className=" mt-14">
                <header className="border-b-[5px] border-b-[#F6F5FA] mt-3  flex justify-between py-2">
                  {/* <h1 className="text-[18px] font-semibold">
                  Supplier/buyer details
                </h1> */}
                  <div>
                    <div class="text-[#222222] text-lg font-semibold w-2 ">
                      Nestle PLC
                    </div>
                    <div class="text-[#7a7a7a] text-xs font-light mt-1 ">
                      Buyer details
                    </div>
                  </div>

                  <div class="h-[41px] flex-col justify-start items-start  inline-flex">
                    <div class="text-[#222222] text-base font-semibold ">
                      60 Days
                    </div>
                    <div class="w-[100px] text-[#7a7a7a] text-xs font-light  mt-1">
                      Payment
                    </div>
                  </div>

                  <div class="h-[43px] flex-col justify-start items-end  inline-flex">
                    <div class="text-[#222222] text-lg font-semibold ">NGN</div>
                    <div class="text-[#7a7a7a] text-xs font-semibold mt-1">
                      Currency
                    </div>
                  </div>
                </header>

                <header className="border-b-[5px] border-b-[#F6F5FA] mt-6  flex justify-between py-1">
                  {/* <h1 className="text-[18px] font-semibold">
                  Supplier/buyer details
                </h1> */}
                  <div class="text-[#11192a] text-sm font-semibold ">
                    Line Items
                  </div>
                  <div class="text-[#11192a] text-lg font-light  mr-6">
                    5 Total Items{" "}
                  </div>
                </header>

                <div className=" w-full overflow-x-auto mt-3">
                  <table className=" w-full overflow-x-auto">
                    <thead className="bg-subtleGray w-full p-6   ">
                      <tr className=" bg-unusualGray  px-6 w-full ">
                        {/* <td className="text-[#11192A] p-2.5 text-xs font-bold">
                          Item Number
                        </td> */}
                        <td class="text-[#11192a] text-xs font-semibold  p-4  ">
                          Item{" "}
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4">
                          Description{" "}
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4">
                          Unit Price
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4">
                          Quantity
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4">
                          Amount
                          <div> (before tax)</div>
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4">
                          Tax Rate
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4">
                          Tax Amount
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4">
                          Total Price
                        </td>
                      </tr>
                    </thead>

                    <tbody className="  ">
                      {dummyData.map((cell, idx) => {
                        return (
                          <tr key={idx} className=" w-full ">
                            <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 ">
                              {cell.item}
                            </td>
                            <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                              {cell.description}
                            </td>
                            <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                              {cell.unitPrice}
                            </td>
                            <td className="text-[#11192a] text-xs font-semibold mt-2 text-center">
                              {cell.Quantity}
                            </td>
                            <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                              {cell.amount}
                            </td>
                            <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                              {cell.taxRate}
                            </td>
                            <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                              {cell.taxAmount}
                            </td>
                            <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                              {cell.totalPrice}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

                {/* cal price stops here */}
              </div>
            </section>
          </TransitionScale>
        </>
      </div>
    </DashboardLayout>
  );
};

export default DeclinedRequestsDetailsScreen;
