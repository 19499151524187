import React, { useEffect } from "react";
import Table from "layout/table/Table";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { allTransactions } from "appstate/collections/collectionSlice";
import Loading from "shared/Loading";
// import { setActiveTab } from "appstate/collections/collectionSlice";

const RecentPaymentTableReinbursement = ({ setActiveTab }) => {
  const navigate = useNavigate();

  const transactionDetails = (id) => {
    let path = `/reimbursement/overviewdetails/${id}`;
    navigate(path);
  };

  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state?.collection);

  const formatAmount = (amount) => {
    if (typeof amount !== "number") return "0.00";
    return amount?.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  useEffect(() => {
    dispatch(allTransactions());
  }, [dispatch]);

  const rowData = [
    {
      id: "ivn12",
      company: "XYZ Venture",
      player: "Supplier",
      payData: "2023-05-23",
      invRef: "INV-2234",
      currency: "NGN",
      amount: "9,000,000",
      status: "Successful",
      type: "Balance Payment",
    },
    {
      id: "ivn13",
      company: "XYZ Venture",
      player: "Supplier",
      payData: "2023-05-23",
      invRef: "INV-2234",
      currency: "NGN",
      amount: "9,000,000",
      status: "Successful",
      type: "Balance Payment",
    },
    {
      id: "ivn14",
      company: "XYZ Venture",
      player: "Supplier",
      payData: "2023-05-23",
      invRef: "INV-2234",
      currency: "NGN",
      amount: "9,000,000",
      status: "Successful",
      type: "Balance Payment",
    },
  ];

  const columns = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Transaction Party
        </span>
      ),
      selector: (row) => row.company,
      sortable: true,
      width: "11rem",
    },
    {
      name: "Payment Date",
      selector: (row) => row.payData,
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Invoice Reference
        </span>
      ),
      selector: (row) => row.invRef,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
      width: "6.2rem",
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      cell: (row) => <p className="text-success">{formatAmount(row.amount)}</p>,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) =>
        row.status === "SUCCESSFUL" ? (
          <p className="text-[#07593D]">{row.status}</p>
        ) : row.status === "REJECTED" ? (
          <p className="text-red">{row.status}</p>
        ) : row.status === "UPDATED" ? (
          <p className="text-blue-300">{row.status}</p>
        ) : row.status === "PARTIALLY PAID" ? (
          <p className="text-yellow-400">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        ),
    },
    {
      name: "Transaction Type",
      selector: (row) => row.type,
      sortable: true,
      width: "156px",
    },
    {
      name: "Action",
      selector: (row) => row.action,
      sortable: true,
      cell: (row) => (
        <div
          className="border border-gray300 rounded text-[#959595] p-2"
          onClick={() => transactionDetails(row.invoiceId)}
        >
          {" "}
          View Details
        </div>
      ),
    },
  ];

  return (
    <>
      {!rowData.length ? (
        <div className="ml3">
          <div className="flex justify-between  bg-alabasterHeader">
            <div className="flex flex-col  justify-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
              <span className="text-black text-sm font-semibold">
                Recent Reimbursement history
              </span>

              <span className=" text-xs text-[#959595]">
                Here is a recent list of your receivables from Payment
                reimbursement
              </span>
            </div>
            <div className="relative flex flex-col pt-4 pr-4">
              <select className="border border-[#AEAEAE] bg-white text-[#3E3E3E] text-sm rounded px-3 py-1 cursor-pointer shadow-sm focus:outline-none">
                <option value="all">View All Transaction History</option>
              </select>
            </div>
          </div>
          <TableEmptyState dashboard />
        </div>
      ) : (
        <Card className="rounded-[10px] mt-[24px]">
          <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
            <div>
              <p className="text-black text-lg font-bold">
                Recent Reimbursement history
              </p>
              <span className="text-xs font-thin">
                Here is a recent list of your receivables from Payment
                reimbursement
              </span>
            </div>
            <div
              className="border border-gray300 rounded-md p-2 cursor-pointer"
              onClick={() => setActiveTab(2)}
            >
              <p className="text-[#959595] text-xs lg:text-base">
                View All Transaction History
              </p>
            </div>
          </div>
          <div className="px-[24px] pb[24px]">
            <Table
              columns={columns}
              data={rowData}
              onRowClicked={(row) => transactionDetails(row.invoiceId)}
              progressPending={isLoading}
              progressComponent={<Loading />}
              pointer
              tableHeader
              className="mt-5"
            />
          </div>
        </Card>
      )}
    </>
  );
};

export default RecentPaymentTableReinbursement;
