import React from "react";
import TransitionScale from "shared/animation/TransitionScale";
import { formatCurrency } from "utilities/helper/formatters";
import { motion } from "framer-motion";

const PayablesDetails = ({ invoice, setAcceptModal, countDown }) => {
  return (
    <main className="flex px-6 flex-col lg:flex-row gap-3 justify-between">
      <TransitionScale className="w-full flex flex-col gap-8 min-h-[90vh] bg-white rounded-[10px]">
        <div className="flex py-3  border-b border-[#F0F0F0] px-3 justify-between rounded-t-[10px] bg-[#FAFAFA]">
          <span>Invoices</span>

          <div className="flex gap-1 items-center">
            <span className="text-sm"> Status: </span>
            <div className="text-primaryColor text-sm font-bold">{invoice?.status}</div>
          </div>
        </div>
        <header className="flex px-6 flex-col gap-3">
          <section className="flex justify-between  items-start">
            <span className="text-[18px] font-bold">
              Invoice | INV-{invoice?.invoiceNo}
            </span>
          </section>

          <section className="flex justify-between">
            <div className="flex flex-col gap-1 w-[25%]">
              <section className="flex items-center justify-between">
                <span className="text-[18px] font-semibold">Reference No</span>
                <span className="text-[#7a7a7a] text-xs font-semibold">
                  {invoice?.invoiceRef}
                </span>
              </section>

              <section className="flex items-center justify-between font-light">
                <span className="text-sm">Created Date</span>
                <span className="text-[#7a7a7a] text-sm">
                  {invoice?.createdDate}
                </span>
              </section>

              <section className="flex items-center justify-between font-light">
                <span className="text-sm">Submitted Date</span>
                <span className="text-[#7a7a7a] text-sm">
                  {invoice?.submittedDate}
                </span>
              </section>

              <section className="flex items-center justify-between font-light">
                <span className="text-sm">Approved Date</span>
                <span className="text-[#7a7a7a] text-sm">
                  {invoice?.approvedDate}
                </span>
              </section>

              <section className="flex items-center justify-between font-light">
                <span className="text-sm">Invoice Due Date</span>
                <span className="text-[#7a7a7a] text-sm">
                  {invoice?.dueDate}
                </span>
              </section>

              <section className="flex items-center justify-between font-light">
                <span className="text-sm">Invoice Amount</span>
                <span className="text-[#7a7a7a] text-sm">
                  {invoice?.currency + " "}{" "}
                  {formatCurrency(invoice?.grandTotal)}
                </span>
              </section>
            </div>

            <div className="flex flex-col gap-1">
              <motion.button
                onClick={() => {
                  setTimeout(() => {
                    setAcceptModal(true);
                  }, 200);
                }}
                whileTap={{ scale: 0.95 }}
                className="py-3 px-6 text-xs  text-white rounded bg-primaryColor mt-4"
              >
                Fund Request
              </motion.button>

              <span className="text-secondaryColor text-sm font-medium">
                {countDown}
              </span>
            </div>
          </section>
        </header>

        <section className="flex px-6 items-center justify-between">
          <div className="flex flex-col gap-2">
            <span className="text-[18px] italic font-semibold">
              {invoice?.buyerCompanyName}
            </span>
            <span className="text-[#7A7A7A] text-xs font-light">
              Buyer Details
            </span>
          </div>

          <div className="flex flex-col gap-2">
            <span className="text-[18px] italic font-semibold">
              {invoice?.paymentTerms}
            </span>
            <span className="text-[#7A7A7A] text-xs font-light">
              Payment Terms
            </span>
          </div>

          <div className="flex flex-col gap-2">
            <span className="text-[18px] italic font-semibold">
              {invoice?.currency}
            </span>
            <span className="text-[#7A7A7A] text-xs font-light">Currency</span>
          </div>
        </section>

        <section className="flex px-6 flex-col gap-4">
          <header className="flex items-center justify-between pb-3 border-b-[5px] border-[#F6F5FA] text-[#11192A]">
            <span className="text-sm">Line Items</span>

            <span className="text-[18px] font-light">
              {invoice?.items?.length} Total Items{" "}
            </span>
          </header>

          <div className=" w-full overflow-x-auto mt-3 pb-4">
            <table className=" w-full overflow-x-auto">
              <thead className="bg-subtleGray w-full p-6  flex-nowrap whitespace-nowrap  ">
                <tr className=" bg-unusualGray  px-6 w-full flex-nowrap whitespace-nowrap ">
                  {/* <td className="text-[#11192A] p-2.5 text-xs font-bold">
                        Item Number
                      </td> */}
                  <td class="text-[#11192a] text-xs font-semibold  p-4  flex-nowrap whitespace-nowrap ">
                    Item{" "}
                  </td>
                  <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                    Description{" "}
                  </td>
                  <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                    Unit Price
                  </td>
                  <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                    Quantity
                  </td>
                  <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                    Amount
                    <div> (before tax)</div>
                  </td>
                  <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                    Tax Rate
                  </td>
                  <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                    Tax Amount
                  </td>
                  <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                    Total Price
                  </td>
                </tr>
              </thead>

              <tbody className="  ">
                {invoice?.items?.map((cell, idx) => {
                  return (
                    <tr key={idx} className=" w-full ">
                      <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap ">
                        {cell.itemName}
                      </td>
                      <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                        {cell.description}
                      </td>
                      <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                        {cell.unitPrice}
                      </td>
                      <td className="text-[#11192a] text-xs font-semibold mt-2 text-center flex-nowrap whitespace-nowrap">
                        {cell.quantity}
                      </td>
                      <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                        {cell.amount}
                      </td>
                      <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                        {cell.taxRate}
                      </td>
                      <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                        {cell.taxAmount}
                      </td>
                      <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                        {cell.totalPrice}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className=" px-6   py-5 bg-neutral-50 flex-col justify-center items-start  inline-flex bg-[#fafafa] w-full border-t-[0.2px]  border-t-[#b1a6b2]">
            <div className="lg:flex md:flex justify-between  w-full sm:block ">
              {/* First left side */}
              <div>
                <section>
                  {" "}
                  <div class="text-[#222222] text-lg  font-medium border-b-[0.2px]  border-[#b1a6b2]">
                    Receiving Funding Account Information
                  </div>
                  {/* <div class="text-[#222222] text-lg font-semibold ">
                        Receiving Funding Account Information
                      </div> */}
                  <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                    {invoice?.virtualAccount?.accountNumber}
                  </div>
                  <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                    {invoice?.virtualAccount?.accountName}
                  </div>
                </section>
              </div>

              {/* second division */}

              <div>
                {" "}
                <div class=" flex-col justify-start items-start inline-flex lg:min-w-[290px]">
                  <section className=" w-full px-6 pb-2 border-b-[1px] border-black">
                    <div className=" flex w-full justify-between">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Subtotal
                      </div>
                      <div class=" text-[#222222] text-xs font-semibold ">
                        {formatCurrency(Number(invoice?.subTotal))}
                      </div>
                    </div>
                    <div className=" flex w-full justify-between pt-3 pb-1 border-b-[0.2px] border-b-[#b1a6b2]/30">
                      <div class="text-[#222222]  text-xs font-medium flex  space-x-9 ">
                        <span>VAT%</span>
                        <span>{invoice?.vat}%</span>
                      </div>
                      <div class="text-[#222222] text-xs font-medium ">
                        {formatCurrency(Number(invoice?.vatAmount))}
                      </div>
                    </div>

                    <div className=" flex w-full justify-between pt-3 pb-1 border-b-[0.2px] border-b-[#b1a6b2]/30">
                      <div class="text-[#222222]  text-xs font-medium flex  space-x-9 ">
                        <span>Discount%</span>
                        <span>{invoice?.discount}%</span>
                      </div>
                      <div class="text-[#222222] text-xs font-medium ">
                        {invoice?.discountAmount}
                      </div>
                    </div>
                  </section>
                  <div className=" flex w-full justify-between py-2 px-6 border-b-[1px] border-b-black">
                    <div class="text-[#222222] text-lg font-semibold ">
                      Grand Total
                    </div>
                    <div class="text-[#222222] text-lg font-semibold ">
                      {formatCurrency(Number(invoice?.grandTotal))}
                    </div>
                  </div>
                </div>
              </div>

              {/* stops */}
            </div>
          </div>
        </section>
      </TransitionScale>
    </main>
  );
};

export default PayablesDetails;
