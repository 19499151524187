import { PAIconSearchGreen } from "assets/images/svgs";
import Card from "shared/Card";
import { motion } from "framer-motion";

function OpenMarketHeader({
  activeTab,
  setActiveTab,
  searchQuery,
  setSearchQuery,
}) {
  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
    sessionStorage.setItem("activeTab", tabNumber);
  };

  const handleSearchInputChange = (event) => {
    const value = event.target.value;
    setSearchQuery(value);
    sessionStorage.setItem("searchQuery", value);
  };

  const handleFilterButtonClick = () => {};

  return (
    <Card className="pl5 pr-2 rounded-[5px] h-16  flex items-center justify-between">
      <div className="flex h-full items-center overflow-x-auto space-x-1">
        <div
          className={`cursor-pointer relative h-full pt-5 text-sm overflow-hidden  text-[#959595] ${
            activeTab === 1 ? " text-primaryColor font-bold" : "text-gray-400"
          }`}
          onClick={() => handleTabClick(1)}
        >
          <span className="px-5 text-center flex justify-center w-full">
            Overview
          </span>

          {activeTab === 1 && (
            <div className="absolute bottom-0 left-0 w-full  h-1 rounded bg-primaryColor transition-transform duration-300 ease-in-out"></div>
          )}
        </div>
        <div
          className={`cursor-pointer relative h-full pt-5 text-center text-sm overflow-hidden  text-[#959595] ${
            activeTab === 2 ? "text-primaryColor font-bold" : "text-gray-400"
          }`}
          onClick={() => handleTabClick(2)}
        >
          <span className="px-5 text-center flex justify-center w-full ">
            Open Market
          </span>
          {activeTab === 2 && (
            <div className="absolute bottom-0 left-0 w-full h-1 rounded bg-primaryColor transition-transform duration-300 ease-in-out"></div>
          )}
        </div>
        <div
          className={`cursor-pointer relative  h-full overflow-hidden  pt-5 text-sm text-[#959595] ${
            activeTab === 3 ? "text-primaryColor font-bold" : "text-gray-400"
          }`}
          onClick={() => handleTabClick(3)}
        >
          <span className="px-5 flex justify-center text-center w-full">
            Ongoing Bid
          </span>
          {activeTab === 3 && (
            <div className="absolute bottom-0 left-0 w-full h-1 rounded bg-primaryColor transition-transform duration-300 ease-in-out"></div>
          )}
        </div>

        <div
          className={`cursor-pointer relative   h-full pt-5 overflow-hidden  text-sm text-[#959595] ${
            activeTab === 4 ? "text-primaryColor font-bold" : "text-gray-400"
          }`}
          onClick={() => handleTabClick(4)}
        >
          <span className="px-5 text-center flex justify-center  w-full">
            Won Bids
          </span>
          {activeTab === 4 && (
            <div className="absolute bottom-0 left-0 w-full h-1 rounded bg-primaryColor transition-transform duration-300 ease-in-out"></div>
          )}
        </div>
      </div>
      <div className="hidden md:flex w-[35%]  items-center gap-2">
        <div className="flex gap-2.5 w-full bg-white border-[0.5px] border-[#ccc] p-2.5 items-center rounded-[5px]">
          <PAIconSearchGreen />
          <input
            type="text"
            placeholder="Search for invoices on the open market"
            value={searchQuery}
            onChange={handleSearchInputChange}
            className="px-2 w-full text-xs flex items-center text-black placeholder:text-black focus:outline-none"
          />
        </div>

        <motion.button
          whileTap={{ scale: 0.9 }}
          onClick={handleFilterButtonClick}
          className="ml-2 flex gap-2 items-center justify-center bg-[#2FA06A] text-white text-sm  px-6 py-3 rounded-md duration-[200ms] ease-in  hover:bg-primaryColor focus:outline-none"
        >
          Search
        </motion.button>
      </div>
    </Card>
  );
}

export default OpenMarketHeader;
