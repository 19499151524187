import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import SearchInput from "../../SearchInput";
import { PAIconOption } from "assets/images/svgs";
import Table from "layout/table/Table";

const AgreeTable = ({ setShowDetails, createNewAgreement }) => {
  const [, setShowNewAgreement] = useState(false);
  const [, setShowAgreementDetails] = useState(false);
  const [, setSelectedAgreement] = useState(null);
  const [, setSearchQuery] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // const [selectedRows, setSelectedRows] = useState([]);
  const [singleSelectedRows, setSingleSelectedRows] = useState([]);
  const [bulkSelectedRows, setBulkSelectedRows] = useState([]);

  // const [selectAllText, setSelectAllText] = useState("Select All");
  const [, setIsAcceptModalOpen] = useState(false);
  // const [selectedInvoice, setSelectedInvoice] = useState(null);

  const [activeTab] = useState("single");
  const navigate = useNavigate();

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const singleDummyData = [
    {
      id: 1,
      requestRefNumber: "R3456X89Y",
      company: "Funding Agreement 02",
      recourseOption: "2023-05-23",
      paymentDate: "2023-05-23",
      currency: "NGN",
      requestAmount: "9,000,000",
      discountRate: "1.5%",
      discountAmount: "950,000",
      receivableAmount: "8,150,000",
      status: "Pending Signature",
      type: "PFP Campaign",
      role: "Initiator",
      party: "Chevron (Funder)",
    },
    {
      id: 2,
      requestRefNumber: "R3456X89Y",
      company: "PFP Campaign Macro",
      recourseOption: "2023-05-23",
      paymentDate: "2023-05-23",
      currency: "NGN",
      requestAmount: "9,000,000",
      discountRate: "1.5%",
      discountAmount: "950,000",
      receivableAmount: "8,150,000",
      status: "Rejected",
      type: "PFP Campaign",
      role: "Initiator",
      party: "Dangote PLC (Funder)",
    },
    {
      id: 3,
      requestRefNumber: "R3456X89Y",
      company: "Invoice Financing",
      recourseOption: "2023-05-23",
      paymentDate: "2023-05-23",
      currency: "NGN",
      requestAmount: "9,000,000",
      discountRate: "1.5%",
      discountAmount: "950,000",
      receivableAmount: "8,150,000",
      status: "Updated",
      type: "Closed Market",
      role: "Receipient",
      party: "Pepsi Ltd (Supplier)",
    },
  ];

  const handleCreateNewAgreement = () => {
    setShowNewAgreement(true);
    setShowAgreementDetails(false);
    setSelectedAgreement(null);
    createNewAgreement();
  };

  // const handleShowAgreementDetails = (agreement) => {
  //   setSelectedAgreement(agreement);
  //   setShowAgreementDetails(true);
  //   setShowNewAgreement(false);
  // };

  // function handleBack() {
  //   setShowNewAgreement(false);
  //   setShowAgreementDetails(false);
  //   setSelectedAgreement(null);
  // }

  const singleDetails = (id) => {
    // let path = `/collections/funcollectiondirectfundinginvoice/${id}`;
    // navigate(path);
    //  console.log("===id===",id)
    setShowDetails();
  };

  const bulkDetails = (id) => {
    let path = `/collections/funderbulk-directfunding/${id}`;
    navigate(path);
  };

  const handleNotificationClick = (row) => {
    // setSelectedInvoice(row);

    // Determine the selected rows state based on the active tab
    const isSingleTab = activeTab === "single";
    const selectedRowList = isSingleTab ? singleSelectedRows : bulkSelectedRows;

    const isSelected = selectedRowList.includes(row.id);
    let newSelectedRows = [];

    if (!isSelected) {
      newSelectedRows = [...selectedRowList, row.id];
    }

    // Update the appropriate state based on the active tab
    if (isSingleTab) {
      setSingleSelectedRows(newSelectedRows);
    } else {
      setBulkSelectedRows(newSelectedRows);
    }

    setIsAcceptModalOpen(true);
  };

  // const formatAmount = (amount) => {
  //   if (!amount || typeof amount !== "string") return "0.00";
  //   const parsedAmount = parseFloat(amount);
  //   if (isNaN(parsedAmount)) return "0.00";
  //   return parsedAmount.toLocaleString(undefined, {
  //     minimumFractionDigits: 2,
  //     maximumFractionDigits: 2,
  //   });
  // };

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen({});
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const columns = [
    {
      name: "AGREEMENT NAME",
      selector: (row) => row.company,
      sortable: true,
      width: "10rem",
    },
    {
      name: "AGREEMENT TYPE",
      selector: (row) => row.type,
      sortable: true,
      width: "10rem",
    },
    {
      name: "DATE CREATED",
      selector: (row) => row.recourseOption,
      sortable: true,
      width: "10rem",
    },
    {
      name: "Currency",
      selector: (row) => row.paymentDate,
      sortable: true,
    },
    {
      name: "YOUR ROLE",
      selector: (row) => row.role,
      sortable: true,

      width: "10rem",
    },
    {
      name: "COUNTER PARTY",
      selector: (row) => row.party,
      sortable: true,

      width: "10rem",
    },

    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <p
          className={
            row.status === "Rejected"
              ? "text-[#BB0C0C]"
              : row.status === "Updated"
              ? "text-[#3A6CCC]"
              : "text-black"
          }
        >
          {row.status}
        </p>
      ),
      width: "10rem",
    },
    {
      cell: (row) => (
        <div className="flex gap-2">
          {
            <PAIconOption
              title="Options"
              onClick={() => {
                setIsDropdownOpen((prev) => ({
                  ...prev,
                  [row.id]: !prev[row.id],
                }));
                // Close other dropdowns
                Object.keys(isDropdownOpen).forEach((key) => {
                  if (key !== row.id.toString()) {
                    setIsDropdownOpen((prev) => ({ ...prev, [key]: false }));
                  }
                });
              }}
            />
          }
          {isDropdownOpen[row.id] && (
            <div
              ref={dropdownRef}
              className="absolute bg-white border border-gray300 rounded-md shadow-md z-10"
            >
              <ul>
                <li
                  className="px-4 py-2 cursor-pointer hover:bg-primaryColor hover:text-white"
                  onClick={() => {
                    if (activeTab === "single") {
                      singleDetails(row?.id);
                    } else if (activeTab === "bulk") {
                      bulkDetails(row?.id);
                    }
                    setIsDropdownOpen((prev) => ({ ...prev, [row.id]: false }));
                  }}
                >
                  Preview
                </li>
                <li
                  className="px-4 py-2 cursor-pointer hover:bg-primaryColor hover:text-white"
                  onClick={() => {
                    handleNotificationClick(row);
                    setIsDropdownOpen((prev) => ({ ...prev, [row.id]: false }));
                  }}
                >
                  Send Reminder
                </li>
                <li className="px-4 py-2 cursor-not-allowed text-gray300">
                  Export
                </li>
              </ul>
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <div>
      {" "}
      <div className="flex items-center justify-between gap-3 ">
        <button
          onClick={handleCreateNewAgreement}
          className="text-white text-xs p-2 font-inter textbase font-normal leading-normal rounded-md border border-[#CCC] bg-[#FFBE55]"
        >
          Create new agreement
        </button>
        <div className="ml-5">
          <SearchInput onSearch={handleSearch} />
        </div>
      </div>
      <div className=" flex flex-wrap  items-center w-full mt-4 gap-5">
        <div>
          <select
            className=" shadow-sm  text-sm focus:outline-none  cursor-pointer h-[37px] px-3 py-2.5 rounded-[5px] border border-[#949494] justify-start items-end gap-2 inline-flex  "
            // onChange={(e) => {}}
            // onChange={(e) => setStatus(e.target.value)}
          >
            <option value="" key="all-bids">
              All Agreement Type
            </option>
            <option value="BID_ACTIVE" key="bid-active">
              PFP Campaign
            </option>
            <option value="POSTED" key="posted-request">
              Direct Funding
            </option>
            <option value="POSTED" key="posted-request">
              Early Payment
            </option>
            <option value="POSTED" key="posted-request">
              Closed Market
            </option>
            <option value="POSTED" key="posted-request">
              Open Market
            </option>
          </select>
        </div>
        <div>
          <select
            className=" shadow-sm  text-sm focus:outline-none  cursor-pointer h-[37px] px-3 py-2.5 rounded-[5px] border border-[#949494] justify-start items-end gap-2 inline-flex  "
            // onChange={(e) => {}}
            // onChange={(e) => setStatus(e.target.value)}
          >
            <option value="" key="all-bids">
              All Roles
            </option>
            <option value="BID_ACTIVE" key="bid-active">
              Initiator
            </option>
            <option value="POSTED" key="posted-request">
              Receipient
            </option>
          </select>
        </div>
        <div>
          <select
            className=" shadow-sm  text-sm focus:outline-none  cursor-pointer h-[37px] px-3 py-2.5 rounded-[5px] border border-[#949494] justify-start items-end gap-2 inline-flex  "
            // onChange={(e) => {}}
            // onChange={(e) => setStatus(e.target.value)}
          >
            <option value="" key="all-bids">
              Pending Signature
            </option>
            <option value="BID_ACTIVE" key="bid-active">
              Signed
            </option>
            <option value="POSTED" key="posted-request">
              Updated
            </option>
            <option value="POSTED" key="posted-request">
              Rejected
            </option>
            <option value="POSTED" key="posted-request">
              Cancelled
            </option>
          </select>
        </div>
      </div>
      <div className=" mt-3">
        <Table
          columns={columns}
          data={singleDummyData}
          onRowClicked={(row) => singleDetails(row.id)}
          pointer
          tableHeader
          className="mt-5"
        />
      </div>
    </div>
  );
};

export default AgreeTable;
