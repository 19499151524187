import { useKeycloak } from "@react-keycloak/web";
import { logOut } from "appstate/auth/authSlice";
import {
  PAIconArrowDown,
  PAIconColapsClose,
  PAIconColapsOpen,
  PAIconLogout,
  PAIconProfilepic,
} from "assets/images/svgs";
import PAIconLogo from "assets/images/svgs/PayEdgeLogo.svg";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  supplierSidebarContent,
  buyerSidebarContent,
  funderSidebarContent,
  adminSideMenu,
  generalSideMenu,
} from "utilities/SidebarContents";
import {
  SideNavLinkText,
  SidebarContainer,
  SidebarLinkWrapper,
} from "./styles";
import { formatCapitaliseString } from "utilities/helper/formatCapitaliseString";

const Sidebar = () => {
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(true);
  const [activeSubMenu, setActiveSubMenu] = useState({});
  const [activeSubMenuItem, setActiveSubMenuItem] = useState({});

  const handleSubMenuToggle = (index) => {
    setActiveSubMenu((prevActiveSubMenu) => ({
      ...prevActiveSubMenu,
      [index]: !prevActiveSubMenu[index],
    }));
  };

  const handleSubMenuItemClick = (index, subRoute) => {
    setActiveSubMenuItem((prevActiveSubMenuItem) => ({
      ...prevActiveSubMenuItem,
      [index]: subRoute,
    }));
  };

  const { isAuthenticated } = useSelector((state) => state.auth);

  const userName = keycloak?.idTokenParsed;

  const { userId } = useSelector((state) => state.auth);

  const constants = [
    "default-roles-payassyst",
    "offline_access",
    "uma_authorization",
  ];
  const roleIndex = userId?.realm_access?.roles.findIndex(
    (role) => !constants.includes(role)
  );

  const userRole = userId?.realm_access?.roles[roleIndex]?.toLowerCase();

  const player = userRole?.includes("buyer")
    ? "buyer"
    : userRole?.includes("funder")
    ? "funder"
    : "supplier";

  const handleLogout = () => {
    if (isAuthenticated || keycloak?.authenticated) {
      sessionStorage.clear();
      keycloak.logout().then(() => dispatch(logOut()));
    }
  };

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const sideMenu = (() => {
    switch (player) {
      case "supplier":
        return supplierSidebarContent;
      case "buyer":
        return buyerSidebarContent;
      case "funder":
        return funderSidebarContent;
      default:
        return adminSideMenu;
    }
  })();

  return (
    <SidebarContainer>
      <div className="flex flex-col mb-[50px] md:mb-[184.5px]">
        <div className="flex justify-between px-[6%] gap-x-[56px]">
          {toggle && (
            <div className="text-center font-[900] text-2xl hidden extra-md:block">
              <img
                src={PAIconLogo}
                alt="#"
                className="relative left-[10px] w-[115.696px]"
              />
            </div>
          )}
          <div className="text-center hidden extra-md:block mt-[3px]">
            {toggle ? (
              <PAIconColapsClose
                className="cursor-pointer"
                onClick={handleToggle}
              />
            ) : (
              <PAIconColapsOpen
                className="cursor-pointer ml-[20px]"
                onClick={handleToggle}
              />
            )}
          </div>
        </div>

        <SidebarLinkWrapper>
          {sideMenu.map(({ route, icon, text, subMenu }, index) => (
            <React.Fragment key={route}>
              <NavLink
                to={route}
                className={({ isActive }) =>
                  `nav-link text-white ${
                    route !== "#" && isActive && !activeSubMenu[index]
                      ? "bg-secondaryColor"
                      : ""
                  }`
                }
                onClick={(event) => {
                  if (subMenu) {
                    event.preventDefault();
                  }
                  handleSubMenuToggle(index);
                }}
              >
                <span>{icon}</span>
                {toggle && (
                  <>
                    <SideNavLinkText className="hidden extra-md:block ">
                      {text}
                    </SideNavLinkText>
                    {subMenu && (
                      <span className="dropdown-arrow relative right-6">
                        <PAIconArrowDown className="stroke-white" />
                      </span>
                    )}
                  </>
                )}
              </NavLink>
              {activeSubMenu[index] && subMenu && (
                <ul>
                  {subMenu.map(
                    ({ route: subRoute, icon: subIcon, text: subText }) => (
                      <li className="" key={subRoute}>
                        <NavLink
                          style={{ paddingLeft: "15%" }}
                          to={subRoute}
                          className={({ isActive }) =>
                            `nav-link text-white ${
                              subRoute !== "#" &&
                              (isActive ||
                                activeSubMenuItem[index] === subRoute)
                                ? "bg-secondaryColor"
                                : ""
                            }`
                          }
                          onClick={() =>
                            handleSubMenuItemClick(index, subRoute)
                          }
                        >
                          <span>{subIcon}</span>
                          <SideNavLinkText className="hidden extra-md:block">
                            {subText}
                          </SideNavLinkText>
                        </NavLink>
                      </li>
                    )
                  )}
                </ul>
              )}
            </React.Fragment>
          ))}

          <div className="h-[1px] bg-slate-400 w-[100%] my-[15%]"></div>

          {generalSideMenu.map(({ route, icon, text, subMenu }, index) => (
            <React.Fragment key={route}>
              <NavLink
                to={route}
                className={({ isActive }) =>
                  `nav-link text-white ${
                    route !== "#" && isActive && !activeSubMenu[index]
                      ? "bg-secondaryColor"
                      : ""
                  }`
                }
                onClick={(event) => {
                  handleSubMenuToggle(index);
                }}
              >
                <span>{icon}</span>
                {toggle && (
                  <>
                    <SideNavLinkText className="hidden extra-md:block ">
                      {text}
                    </SideNavLinkText>
                    {subMenu && (
                      <span className="dropdown-arrow relative right-6">
                        <PAIconArrowDown className="stroke-white" />
                      </span>
                    )}
                  </>
                )}
              </NavLink>
            </React.Fragment>
          ))}
        </SidebarLinkWrapper>
      </div>

      <div>
        <div
          className={` ${
            toggle ? "sidebar__footer" : "flex flex-col items-center"
          }`}
        >
          <div
            className={`flex flex-col items-center extra-md:block ${
              toggle && "md:pl-[33px]"
            }`}
          >
            <div className="flex items-center mb-[24px] cursor-pointer ">
              <PAIconProfilepic className="w-[45px] h-[45px]" />
              {toggle && (
                <div className="hidden md:ml-[16px] extra-md:block">
                  <p className="font-medium text-base">
                    {userName ? userName?.given_name?.split(" ")[0] : ""}
                  </p>
                  <p className="capitalize font-normal font-xs text-grayrgb">
                    {formatCapitaliseString(player)}
                  </p>
                </div>
              )}
            </div>
            <div
              className={`border border-secondaryColor mb-[30px] ${
                toggle ? "w-[40px] md:w-[90%]" : "w-[40px]"
              } h-[40px] rounded-[6px] flex items-center justify-center cursor-pointer`}
            >
              <PAIconLogout />
              {toggle && (
                <p
                  className="hidden extra-md:block font-normal text-base ml-[11px]"
                  onClick={handleLogout}
                >
                  Logout
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </SidebarContainer>
  );
};

export default Sidebar;
