import { motion } from "framer-motion";
import { PAIconArrowLeftGreen } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { useNavigate, useParams } from "react-router-dom";
import TransitionScale from "shared/animation/TransitionScale";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { getInvoiceById } from "appstate/openMarket/openMarketSlice";
import ReceivableInvoice from "./receivableInvoice";
import { supplierPartialReceivableInvoice } from "appstate/collections/collectionSlice";

function CollectionReceivableInvoice() {
  const { id } = useParams();
  // const location = useLocation();
  // const searchParams = new URLSearchParams(location.search);
  // const offerId = searchParams.get("offerId");

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(supplierPartialReceivableInvoice({ id }));
  }, [dispatch, id]);

  const { supplierPartialReceivableInvoiceData } = useSelector(
    (state) => state?.collection
  );

  function handleGoBack() {
    navigate(-1);
  }

  const formatDate = (dateArray) => {
    if (!dateArray || dateArray.length !== 3) return "Invalid Date"; // Handle missing or incorrect data
    const [year, month, day] = dateArray;
    return `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(
      2,
      "0"
    )}`;
  };

  const data = [
    {
      label1: supplierPartialReceivableInvoiceData?.data?.invoices[0]?.buyerCompanyName,
      value1: "Buyer",
      label2: supplierPartialReceivableInvoiceData?.data?.status,
      value2: "Status",
    },
    {
      label1: supplierPartialReceivableInvoiceData?.data?.fundingChannel,
      value1: "Funding Channel",
      value2: "Reference number",
      label2: supplierPartialReceivableInvoiceData?.data?.invoices[0]?.invoiceReference,
    },
    {
      label1: formatDate(
        supplierPartialReceivableInvoiceData?.data?.paymentDate
      ),
      value1: "Fund Date",
      label2: supplierPartialReceivableInvoiceData?.data?.fundingAmount?.toLocaleString(),
      value2: "Fund Amount",
    },
    {
      label1: formatDate(supplierPartialReceivableInvoiceData?.data?.dueDate),
      value1: "Due Date",
      label2: supplierPartialReceivableInvoiceData?.data?.discountAmount?.toLocaleString(),
      value2: "Balance Amount",
    },
  ];

  return (
    <DashboardLayout
      section="Collections"
      subHeader="Track invoices due for payment collection"
    >
      <TransitionScale className="flex  flex-col gap-6 bg-[#EEE] h-full">
        <header onClick={handleGoBack} className="w-full">
          <div className="flex cursor-pointer w-fit gap-1 items-center">
            <PAIconArrowLeftGreen />
            <span className=" text-primaryColor text-sm">Go back</span>
          </div>
        </header>

        <main className="flex flex-col semi-lg:flex-row pb-8 gap-4 w-full ">
          <ReceivableInvoice
            invoiceByIdData={supplierPartialReceivableInvoiceData}
            id={id}
          />

          <motion.section
            initial={{
              y: -20,
            }}
            animate={{
              y: 0,
            }}
            exit={{
              y: 20,
            }}
            transition={{
              delay: 0.8,
            }}
            className="flex flex-col gap-3 px-6  pt-7 pb-24 w-2/5 bg-white rounded-[10px]"
          >
            <header className="flex pb-2">
              <span className="font-bold">Receivable details</span>
            </header>

            <section className="flex flex-col gap-6">
              <div className="space-y-4">
                {data.map((item, index) => (
                  <div
                    key={index}
                    className="flex justify-between items-center"
                  >
                    <span>
                      {item.label1} <br />
                      <span className="text-sm text-gray">{item.value1}</span>
                    </span>
                    <div className="flex flex-col items-end">
                      <span>{item.label2}</span>
                      <span className="text-sm text-gray">{item.value2}</span>
                    </div>
                  </div>
                ))}
              </div>
            </section>
          </motion.section>
        </main>
      </TransitionScale>
    </DashboardLayout>
  );
}

export default CollectionReceivableInvoice;
