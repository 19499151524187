import React from 'react'
import Headers from '../headers'
import SingleBulkOption from './option'

const NewEarlyPaymentRequests = ({activeTab}) => {
  return (
    <div>
      <Headers activeTab={activeTab} />
      <SingleBulkOption />
    </div>
  )
}

export default NewEarlyPaymentRequests