import React, { useEffect, useState } from "react";
import { PAIconSmallClose } from "assets/images/svgs";
import { motion } from "framer-motion";

// import toast from "react-hot-toast";

const ReasonForRequiredModal = ({ onClose, onContinue }) => {
  const handleCloseDeleteModal = () => {
    onClose();
  };

  const [tempData, setTempData] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    const dummyData = [
      { id: "1", text: "Unreliable History" },
      { id: "2", text: "Financial Instability" },
      { id: "3", text: "Repeated Extensions Requests" },
      { id: "4", text: "Violation of Terms" },
    ];

    const timeoutId = setTimeout(() => {
      setData(dummyData);
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const filterOnClick = (item) => {
    const itemclick = data.filter((it) => it.id === item.id);
    const newdata = itemclick;
    setTempData([...tempData, ...newdata]); // Correctly spread tempData and newdata
    const updatedData = data.filter((it) => it.id !== item.id);
    setData(updatedData);
  };

  const filterTempDataOnClick = (item) => {
    const itemclick = tempData.filter((it) => it.id === item.id);
    const newdata = itemclick;
    setData([...data, ...newdata]); // Correctly spread tempData and newdata
    const updatedData = tempData.filter((it) => it.id !== item.id);
    setTempData(updatedData);
  };

  return (
    <motion.div>
      <motion.div className="bg-slate-600/20 fixed inset-0 flex items-center justify-center z-50 ">
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.2,
            },
          }}
          exit={{
            opacity: 0,
            transition: {
              delay: 0.1,
            },
          }}
          className="modal-overlay"
        ></motion.div>

        <motion.div
          initial={{
            scale: 0,
          }}
          animate={{
            scale: 1,
            transition: {
              duration: 0.2,
            },
          }}
          exit={{
            scale: 0,
            transition: {
              delay: 0.1,
            },
          }}
          className=" py-11 bg-white rounded-[10px]    flex flex-col lg:w-[612px] lg:min-h-[558px]  md:min-h-[558px]   lg:overflow-hidden   md:overflow-scroll  sm:overflow-scroll xs:overflow-scroll min-h-[558px]"
        >
          {/* header side */}
          <div className="px-6  lg:px-7">
            <header className="flex justify-between pb-4 border-b-[4px] border-[#F6F5FA] ">
              <span className="text-black text-base ">Reason Required</span>

              <span
                onClick={() => handleCloseDeleteModal()}
                className="flex items-center cursor-pointer gap-2 text-sm font-[500] text-[#565656]"
              >
                <PAIconSmallClose />
                <span class="text-[#565656] text-sm font-medium ">Close</span>
              </span>
            </header>
          </div>

          {/* text section */}
          <div className=" px-7 lg:px-10 py-4 border-b  border-[#8b8b8b]/40">
            <div class=" text-[#949494] text-base font-normal ">
              Please provide a detailed explanation for rejecting the request.
              Your insights assist in improving our platform and understanding
              user preferences. Thank you for your valuable feedback
              <br />
            </div>
          </div>

          {/* Input section */}

          <div className=" justify-center items-center flex-col px-7 lg:px-10  mt-5 w-full">
            <div className=" text-[#07593d] text-sm font-medium mb-1 ">
              Input your reply or choose from our predefined responses
            </div>
            <div className="lg:min-h-[69px]   w-full rounded-[5px]  border-[1.5px] border-[#07593d] justify-start items-start text-lg font-semibold flex-wrap  ">
              <div
                className={
                  " w-full border-none   focus:outline-none   p-2   text-[#2fa069]   rounded-t-[5px] text-lg font-bold"
                }
              >
                {tempData.length > 0 &&
                  tempData.map((item, idx) => {
                    return (
                      <div
                        class="h-[39px] p-2.5 rounded-[5px] border border-[#f08000] justify-center items-center gap-2.5 inline-flex m-2"
                        onClick={() => filterTempDataOnClick(item)}
                      >
                        <div class="text-[#2fa069] text-base font-medium">
                          {item.text}
                        </div>
                        <svg
                          width="16"
                          height="17"
                          viewBox="0 0 16 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="ei:close">
                            <path
                              id="Vector"
                              d="M11.9364 4.10938L12.3889 4.56186L4.06186 12.8883L3.60938 12.4361L11.9364 4.10938Z"
                              fill="#565656"
                            />
                            <path
                              id="Vector_2"
                              d="M4.06186 4.10938L12.3889 12.4358L11.9364 12.8886L3.60938 4.56217L4.06186 4.10938Z"
                              fill="#565656"
                            />
                          </g>
                        </svg>
                      </div>
                    );
                  })}
              </div>

              {/* Buton */}
            </div>

            <div className=" mt-2 w-full">
              {data.map((item, idx) => {
                return (
                  <div
                    class="h-[39px] p-2.5 rounded-[5px] border border-[#949494] justify-center items-center gap-2.5 inline-flex m-2"
                    onClick={() => filterOnClick(item)}
                  >
                    <div class="text-[#2fa069] text-base font-medium">
                      {item.text}
                    </div>
                  </div>
                );
              })}
            </div>
            <button
              className={`h-[49px] px-2.5 py-[15px]  ${
                tempData.length > 0 ? "bg-[#07593d]" : "bg-[#07593d]/60"
              } ${
                tempData.length > 0 ? "cursor-pointer" : "cursor-not-allowed"
              } rounded-[5px] justify-center items-center gap-2.5 flex w-full mt-6`}
              disabled={tempData.length > 0 ? false : true}
              onClick={onContinue}
            >
              <div className="text-white text-base font-normal ">Submit</div>
            </button>
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};
export default ReasonForRequiredModal;
