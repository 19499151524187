import { PAIconWalletRadio } from "assets/images/svgs";
import React from "react";
import Loading from "shared/Loading";

const SuccessModal = ({ isOpen, onClose, onSubmit, headerText, message, isLoading }) => {
  // const [showSpinner, setShowSpinner] = useState(true);

  // useEffect(() => {
  //   if (isOpen) {
  //     setTimeout(() => {
  //       setShowSpinner(false);
  //     }, 2000); 
  //   }
  // }, [isOpen]);

  if (!isOpen) return null;

  return (
    <>
      <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg rounded-md z-[1000]">
        <div className="bg-white p10 px5 rounded-md max-w-xs md:max-w-lg w-full pb-5 px10">
          <div className="flex justify-between items-center mb-4 bg-[#FAFAFA]">
            <p className="text-black font-sans text-base font-semibold p-3 px-5 rounded-sm">
              {/* Withdraw Funds */}
              {headerText}
            </p>
            <button
              onClick={onClose}
              className="text-black font-sans text-xl cursor-pointer flex justify-center items-center"
            >
              &times; <span className="text-sm mx-2 mt-1">Close</span>
            </button>
          </div>

          <div className="flex items-center justify-center p-10">
            {isLoading ? <Loading /> : <PAIconWalletRadio />}
          </div>
          <p className="flex items-center justify-center mb-10 font-bold">
            {isLoading ? "" : message}
          </p>
        </div>
      </div>
    </>
  );
};

export default SuccessModal;
