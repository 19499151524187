import {
  PAIconAddGreen,
  PAIconCancelRound,
  PAIconEmptyFolder,
} from "assets/images/svgs";
import toast from "react-hot-toast";
import { ruleTypes } from "../../data/ruleTypes";
import { useParams } from "react-router-dom";
import { formatCapitaliseString } from "utilities/helper/formatCapitaliseString";

const ConditionTable = ({ rows, setRows }) => {
  const { ruleType } = useParams();
  const handleAddRow = () => {
    const addedRow = [...rows, { type: "", operator: "", value: "" }];
    setRows(addedRow);
  };

  const handleAddCondition = () => {
    const lastRow = rows.slice(-1)[0]; // Create a copy of the last row

    if (
      lastRow &&
      lastRow.type !== "" &&
      lastRow.operator !== "" &&
      lastRow.value !== ""
    ) {
      const addedRow = [...rows, { type: "", operator: "", value: "" }];
      setRows(addedRow);
    } else {
      toast.error("Fill all fields in this action before adding a new action.");
    }
  };

  const handleRemoveRow = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
  };

  const handleInputChange = (index, column, value) => {
    const updatedRows = rows.map((row, rowIndex) => {
      if (rowIndex === index) {
        // Create a new object for the updated row
        return {
          ...row,
          [column]: value,
        };
      }
      return row;
    });

    setRows(updatedRows);
  };

  return (
    <div className=" w-full overflow-x-auto">
      {rows?.length === 0 ? (
        <div className="flex justify-center items-center bg-white rounded-[5px] py-5 w-full">
          <div className="flex flex-col justify-center items-center gap-3">
            <PAIconEmptyFolder />
            <div className=" flex flex-col gap-3">
              <div>Set conditions to create rules</div>
              <button
                onClick={handleAddRow}
                className="text-white bg-primaryColor px-5 py-2.5 rounded-[5px] "
              >
                Set Conditions
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className=" flex flex-col  gap-4">
          <table className="">
            <thead className="py-3">
              <tr>
                <th className="  py-4 text-primaryColor text-xs font-normal">
                  CONDITION
                </th>
                <th className="py-4 text-primaryColor text-xs font-normal">
                  DROPDOWN
                </th>
                <th className="py-4 text-primaryColor text-xs font-normal">
                  OPERATOR
                </th>
                <th className="py-4 text-primaryColor text-xs font-normal">
                  VALUE
                </th>
                <th className="py-4 text-primaryColor text-xs font-normal"></th>
              </tr>
            </thead>

            <tbody className="py-5 bg-white rounded-md">
              {rows?.map((row, index) => (
                <tr className=" " key={index}>
                  <td className=" mr-5 text-center">
                    {index === 0 ? (
                      <span className="text-[#474747] text-sm">If</span>
                    ) : (
                      <span className="text-[#474747] text-sm">And</span>
                    )}
                  </td>
                  <td className="w-full">
                    <select
                      className=" text-[#474747] w-full cursor-pointer overflow-hidden  whitespace-nowrap  text-ellipsis  border-[0.5px] border-[#E6E6E6] outline-none rounded my-3 mr-5 text-sm   px-6 py-2"
                      value={row.type}
                      onChange={(e) =>
                        handleInputChange(index, "type", e.target.value)
                      }
                    >
                      <option value="">Select Condition</option>

                      {ruleTypes?.[ruleType]?.conditions?.map(
                        (conditions, index) => (
                          <option key={index} value={conditions}>
                            {formatCapitaliseString(conditions)}
                          </option>
                        )
                      )}
                    </select>
                  </td>
                  <td className=" flex">
                    <select
                      className="text-[#474747] cursor-pointer w-[155px] overflow-hidden  whitespace-nowrap  text-ellipsis  my-3 mr-5 text-sm  border-[0.5px] border-[#E6E6E6] outline-none rounded   px-2 py-2"
                      value={row.operator}
                      onChange={(e) =>
                        handleInputChange(index, "operator", e.target.value)
                      }
                    >
                      <option className="text-sm text-[#B1B1B1]" value="">
                        Select Operator
                      </option>
                      <option value="EQUALS">Equals</option>
                      <option value="NOT_EQUALS">Not Equal</option>
                      <option value="GREATER_THAN">Greater Than</option>
                      <option value="LESS_THAN">Less Than</option>
                      <option value="GREATER_THAN_OR_EQUAL">
                        Greater Than Or Equal
                      </option>
                      <option value="LESS_THAN_OR_EQUAL">
                        Less Than Or Equal
                      </option>
                      <option value="CONTAINS">Contains</option>
                    </select>
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="Enter Value"
                      className=" w-24 text-sm  border-[0.5px] border-[#E6E6E6] outline-none rounded px-2 py-2"
                      value={row.value}
                      onChange={(e) =>
                        handleInputChange(index, "value", e.target.value)
                      }
                    />
                  </td>
                  <td className="px-3">
                    <button
                      className="flex"
                      onClick={() => handleRemoveRow(index)}
                    >
                      <PAIconCancelRound />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="py-4  flex justify-end">
            <button
              className=" flex justify-center items-center gap-2 text-primaryColor text-xs"
              onClick={handleAddCondition}
            >
              <PAIconAddGreen />
              <span>Add Condition</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConditionTable;
