const NinModal = ({ isOpen, setIsOpen, nin, setNin, onSubmit }) => {
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white flex justify-center flex-col py-6 rounded-lg shadow-lg w-full max-w-md lg:max-w-xl">
            <h2 className="text-xl px-6 font-semibold mb-4">Add NIN Number </h2>
            <div className="flex items-center flex-col w-full">
              <section className="flex w-[75%] flex-col gap-2.5 text-gray-700 px-6 py-24">
                <span className="text-[#222] text-xs">NIN</span>
                <input
                  placeholder="Enter NIN"
                  className="outline-none placeholder:text-sm py-2.5 border-b-[0.6px] border-[#222]"
                  value={nin}
                  onChange={(e) => setNin(e.target.value)}
                  type="text"
                  maxLength={11}
                />
              </section>
            </div>
            <footer className="flex gap-4 border-t border-[#ccc] justify-end pb-2 pt-8 px-4">
              <button
                onClick={toggleModal}
                className="px-6 py-3 text-black  border border-[#CCC] bg-[#F8F8F8] text-sm  rounded hover:bg-red-500"
              >
                Discard
              </button>
              <button
                onClick={onSubmit}
                className="px-6 py-3 text-white bg-primaryColor text-sm  rounded hover:bg-red-500"
              >
                Verify
              </button>
            </footer>
          </div>
        </div>
      )}
    </div>
  );
};

export default NinModal;
