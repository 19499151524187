import { useNavigate } from "react-router-dom";
import ContestedInvoiceCard from "./cards/ContestedInvoiceCard";
import { PAIconInvoiceIcon } from "assets/images/svgs";

function TopContestedInvoice({
  head = "Top 10 Contested Invoices",
  subHead = "Invoices with the highest number of bidders",
  topTenBids,
  setActiveTab,
}) {
  console.log(topTenBids);
  const navigate = useNavigate();
  return (
    <div className="flex flex-col gap-6 rounded-[10px]  bg-unusualGray pb-12  p-6">
      <header className="flex justify-between items-center pb-4 border-b border-b-subtleGray ">
        <div className="flex flex-col gap-1">
          <span className=" font-semibold">{head}</span>
          <span className=" text-[#959595] text-xs">{subHead}</span>
        </div>

        <button
          onClick={() => navigate("top-ten-invoices-supplier")}
          className="text-sm px-4 py-2 rounded-[5px] border"
        >
          View all
        </button>
        {/* <button
          onClick={() => {
            setActiveTab(4); 
            // navigate("top-ten-invoices");
          }}
          className="text-sm px-4 py-2 rounded-[5px] border "
        >
          View all
        </button> */}
      </header>
      {topTenBids?.length > 0 ? (
        <div className="flex flex-col gap-2.5">
          {topTenBids?.slice(0, 3)?.map((bid) => (
            <ContestedInvoiceCard
              id={bid?.openMarketReference}
              key={bid?.openMarketReference}
              invoiceNumber={bid?.openMarketReference}
              buyerName={bid?.buyerName}
              noOfBids={bid?.noOfBids}
              invoiceAmount={bid?.invoiceAmount}
              dueDate={bid?.dueDate}
            />
          ))}
        </div>
      ) : (
        <div className="w-full flex font-medium flex-col items-center gap-4 animate-pulse justify-center">
          <span> No Contested Invoice Available </span>

          <PAIconInvoiceIcon />
        </div>
      )}
    </div>
  );
}

export default TopContestedInvoice;
