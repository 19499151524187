import React, { useState } from 'react';
import VirtualSelectedAccount from './VirtualSelectedAccount';
import EmptyWallet from "../../../../../../assets/images/svgs/empty-wallet.svg";

const VirtualAccountSelection = ({ handleCloseVirtualAccount, setOpen, onClose, invoice }) => {
    // eslint-disable-next-line
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [showSelectedAccountModal, setShowSelectedAccountModal] = useState(false);
    const [showAccountSelectionModal, setShowAccountSelectionModal] = useState(true);

    // const handleAccountClick = (id) => {
    //     setSelectedAccount(id === selectedAccount ? null : id);
    // };

    const handleNextClick = () => {
      handleCloseVirtualAccount();
      setOpen(true);
    //   onClose();
    };

    const handleCloseSelectedAccountModal = () => {
        setShowSelectedAccountModal(false);
        setShowAccountSelectionModal(true);
    };

    const handleCloseModal = () => {
        handleCloseVirtualAccount();
        setShowSelectedAccountModal(false);
        setShowAccountSelectionModal(true);
    };

    const dummyAccounts = [
        { id: 1, accountName: "Praise Akunbundu", accountNumber: "727772772729", bank: "payedge MFB", accountType: "Virtual Account", amount: "26,000,000" },
        { id: 2, accountName: "Worship Akunbundu", accountNumber: "727772000729", bank: "payedge MFB", accountType: "Virtual Account", amount: "26,000,000" },
        { id: 3, accountName: "Prayer Akunbundu", accountNumber: "727772111729", bank: "payedge MFB", accountType: "Virtual Account", amount: "26,000,000" },
    ];

    if (showSelectedAccountModal) {
        return (
            <VirtualSelectedAccount 
                selectedAccount={dummyAccounts.find(account => account.id === selectedAccount)} 
                onClose={handleCloseModal} 
                handleChangeAccount={handleCloseSelectedAccountModal}
                showAccountSelectionModal={showAccountSelectionModal}
            />
        );
    }

    return (
        <div className={`fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50 ${invoice ? 'w-full' : 'w-[850px]'}`}>
            <div className="relative w-full max-w-2xl p-6 bg-white rounded-lg shadow-lg">
                <div className='flex justify-between mb-4'>
                    <h2 className="text-sm font-light">Select Account</h2>
                    <button onClick={onClose} className="text-red-500">X Close</button>
                </div>
                <div className="mb-4">
                    <h2 className="text-gray-600 text-center">Select which account you want to use for this Request</h2>
                </div>
                <br />
                <div className="mb-4">
                    <h2 className="text-gray-600">Accounts</h2>
                </div>

                <div style={{cursor: "pointer"}} className="grid grid-cols-2 gap-1">
                    {dummyAccounts.map(account => (
                        <div onClick={handleNextClick} key={account.id} className="w-[300px] mb-4">
                           <div className='bg-primaryColor p-2'>
                              <div className='flex justify-between'>
                              <div className='flex'>
                                <img src={EmptyWallet} alt='' />
                                <span className='ml-2' style={{color: "white", fontWeight: 400}}>{account?.accountName}</span>
                              </div>
                              <div>
                                <span style={{color: "white", fontSize: 13, fontWeight: 400}}>{account?.accountType}</span>
                              </div>
                              </div>
                              <div>
                                <span style={{color: "white"}}>NGN {account?.amount}</span>
                              </div>
                              <div className='flex'>
                                <span style={{color: "white",  fontWeight: 400}}>{account?.bank}</span>
                                <span className='mx-2 border-l border-white h-6'></span>
                                <span style={{color: "white", fontWeight: 400}}>{account?.accountNumber}</span>
                              </div>
                           </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default VirtualAccountSelection;
