import DashboardLayout from "layout/dashboardlayout";
import React from "react";
import { useSelector } from "react-redux";
import FunderPaymentExtensionDashboard from "./funder/paymentExtensionDashboard";
import BuyerPaymentExtensionDashboard from "./buyer/paymentExtensionDashboard";

const PaymentExtensionDashboard = () => {
  const { userId } = useSelector((state) => state?.auth);
  const roleIndex = userId?.realm_access?.roles?.findIndex((arr) => {
    return (
      arr === "SUPPLIER" ||
      arr === "BUYER" ||
      arr === "FUNDER" ||
      arr === "ADMIN"
    );
  });
  const userRole = userId?.realm_access?.roles[roleIndex]?.toLowerCase();

  const paymentExtensionPlayer =
    userRole === "funder" ? (
      <FunderPaymentExtensionDashboard />
    ) : userRole === "buyer" ? (
      <BuyerPaymentExtensionDashboard />
    ) : (
      <></>
    );
  return (
    <DashboardLayout
      section="Payment Extension Section"
      subHeader="Send and track payment extension processes"
    >
      {paymentExtensionPlayer}
    </DashboardLayout>
  );
};

export default PaymentExtensionDashboard;
