// import { getInvoiceById } from "appstate/openMarket/openMarketSlice";
// import { getInvoiceById } from "appstate/api/invoice/invoice";
import { allInvoiceById } from "appstate/invoice/invoiceSlice";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
// import InvoiceDetails from "shared/InvoiceDetails";
import LoadingScreen from "shared/LoadingScreen";
import PendingApprovalInvoiceDetails from "./partialPaymentInvoiceDetails";

const PendingDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { invoiceIdData, isLoading } = useSelector((state) => state?.invoice);

  useEffect(() => {
    dispatch(allInvoiceById({ id }));
  }, [dispatch, id]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  // Calculate the payment based on the due date and created date
  const calculatePayment = (dueDate, createdDate) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const dueDateObj = new Date(dueDate);
    const createdDateObj = new Date(createdDate?.split(" ")[0]);
    const diffDays = Math.round(
      Math.abs((dueDateObj - createdDateObj) / oneDay)
    );
    return `${diffDays} Days`;
  };

  // FORMAT AMOUNT
  const formatAmount = (amount) => {
    if (typeof amount !== "number") return "0.00";
    return amount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const data = {
    invoiceNumber: `INV-${invoiceIdData?.data?.invoiceNo}` || "",
    referenceNumber: invoiceIdData?.data?.invoiceRef || "",
    createdDate: invoiceIdData?.data?.createdDate?.slice(0, 10) || "",
    status: invoiceIdData?.data?.status,
    dueDate: invoiceIdData?.data?.dueDate || "",
    submittedDate: invoiceIdData?.data?.submittedDate,
    buyerDetails: invoiceIdData?.data?.buyerCompanyName,
    paymentTerms: invoiceIdData?.data?.paymentTerms,
    payment: calculatePayment(
      invoiceIdData?.data?.dueDate,
      invoiceIdData?.data?.createdDate
    ),
    currency: invoiceIdData?.data?.currency || "",
    accountNumber:
      invoiceIdData?.data?.virtualAccount?.accountNumber || "0000000000",
    accountName: invoiceIdData?.data?.virtualAccount?.accountName || "No name",
    vat: invoiceIdData?.data?.vat || "",
    discount: invoiceIdData?.data?.discount || "",
    vatAmount: formatAmount(invoiceIdData?.data?.vatAmount || ""),
    discountAmount: formatAmount(invoiceIdData?.data?.discountAmount || ""),
    grandTotal: formatAmount(invoiceIdData?.data?.grandTotal || ""),
    subTotal: formatAmount(invoiceIdData?.data?.subTotal || ""),
    numberOfLineItems: invoiceIdData?.data?.items?.length || 0,
    lineItems:
      invoiceIdData?.data?.items?.map((item) => ({
        // itemNumber: item?.itemNumber || "",
        item: item?.itemName ? item?.itemName : "",
        description: item.description || "",
        unitPrice: formatAmount(item.unitPrice || ""),
        quantity: item.quantity || 0,
        amount: formatAmount(item.amount || ""),
        taxRate: item.taxRate || "",
        taxAmount: item.taxAmount || "",
        totalPrice: item.totalPrice || "",
      })) || [],
  };

  return (
    <PendingApprovalInvoiceDetails data={data} showSendReminderButton={false} />
  );
};

export default PendingDetails;
