import Table from "layout/table/Table";
import React, {  useEffect, useState } from "react";
import Button from "shared/Button";
import BuyerDetailsModal from "../modal";
import DashboardLayout from "layout/dashboardlayout";
import { PAIconInventoryArrowLeft, PAIconSearch } from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  declineSupplierRequestFunder,
  viewFunderPendingRequest,
} from "appstate/funder/funderSlice";
import toast from "react-hot-toast";
import AccountModalContainer from "shared/accountModal/Modal";
import DeclineModal from "shared/delineModal/Modal";
import Loading from "shared/Loading";
import UserAdd from "../../../../../assets/images/svgs/useradd.svg";
import Pagination from "shared/Pagination";
import { getAllBuyers, searchBuyer } from "appstate/api/buyer/relationshipManagement";
import { supplierAssociateWithBuyer } from "appstate/api/supplier/relationshipManagement";
import AssociationSuccessModal from "shared/associationsuccessmodal/associationsuccessmodal";

const AssociateRequest = () => {
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  // eslint-disable-next-line
  const [getSupId, setGetSupId] = useState();
  const [open, setOpen] = useState(false);
  const [openDeclineModal, setOpenDeclineModal] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [success, setSuccess] = useState(false);
  // eslint-disable-next-line
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const pageSize = 10;
  const [total, setTotal] = useState("");
  const [loading, setLoading] = useState(false);
  const [associationSuccessful, setAssociationSuccessful] = useState(false);
  const {
    associateLoading,
    declineLoading,
  } = useSelector((state) => state.funder);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(companyName);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const sender = "supplier";

  const fetchAllBuyers = async () => {
    setLoading(true);
    const role = "buyers";
    try {
      const result = await getAllBuyers(page, pageSize, role);
      setData(result?.data?.buyers || []);
      setTotal(result?.meta?.totalElements);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllBuyers();
    // eslint-disable-next-line
  }, [page, pageSize]);

  // eslint-disable-next-line
  const handleSearch = async () => {
    try {
      setLoading(true);
      if (debouncedSearchTerm.length > 0) {
        const result = await searchBuyer(debouncedSearchTerm, "", "");
        setData(result?.data || []);
        setTotal(result?.meta?.totalElements);
      } else {
        await fetchAllBuyers();
      }
    } catch (error) {
      // toast.error("Failed to fetch buyers.");
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(companyName);
    }, 200);
    return () => {
      clearTimeout(handler);
    };
    // eslint-disable-next-line
  }, [companyName]);

  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line
  }, [companyName]);

  useEffect(() => {
    fetchAllBuyers();
    // eslint-disable-next-line
  }, [page, pageSize]);
  

  const handleSupplierAssociationRequestBuyer = async (row) => {
    const buyerId = row.userId;
    try {
      const response = await supplierAssociateWithBuyer(buyerId);
      if (response?.success) {
        setAssociationSuccessful(true);
        fetchAllBuyers();
      } else {
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleCloseModal = () => {
    setAssociationSuccessful(false);
  };

  const [getId, setGetId] = useState(null);
  const onRowClicked = (row) => {
    setOpenDetailsModal(true);
    setGetId(row?.userId);
  };
  const handleOpenModal = (row) => {
    setGetId(row?.notificationId);
    setGetSupId(row?.userId);
    setOpen(true);
  };

  // const handleOpenDeclineModal = (row) => {
  //   setGetId(row?.notificationId);
  //   setOpenDeclineModal(true);
  // };
  const totalPages = total;
  const column = [
    {
      name: "Name",
      selector: (row) => row?.fullName,
    },
    {
      name: "Company",
      selector: (row) => row?.companyName,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
      width: "15rem",
    },
    {
      name: "Phone",
      selector: (row) => row?.phoneNumber,
    },
    {
      cell: (row) => {
        return (
          <div className="flex gap-x-[12px]">
            <Button
              neutral
              onClick={() => handleOpenModal(row)}
              className="flex text-[#F08000] text-xs font-medium items-center mb-[10px] md:mb-0 md:px-[5px] bg-[#07593D] py-[12px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px]"
            >
              <img src={UserAdd} alt="" />
              <p style={{ color: "white" }} className="whitespace-nowrap">
                Associate
              </p>
            </Button>
          </div>
        );
      },
    },
  ];

  const handleAssociate = () => {
    const row = { userId: getSupId };
    handleSupplierAssociationRequestBuyer(row);
    setOpen(false);
  };

  const handleDecline = () => {
    const notificationId = getId;
    const search = "";
    dispatch(declineSupplierRequestFunder({ notificationId })).then((data) => {
      if (data?.payload?.success === true) {
        setSuccess(false);
        setOpenDeclineModal(false);
        toast.success(data?.payload?.message);
        dispatch(viewFunderPendingRequest({ sender, search, page, pageSize }));
      }
    });
  };

  const navigate = useNavigate();
  function handleNavigate() {
    navigate(-1);
  }
  return (
    <DashboardLayout
      section="Buyers"
      subHeader="Manage all your associated buyer's account"
    >
      <div>
        <div
          onClick={handleNavigate}
          className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
        >
          <PAIconInventoryArrowLeft />
          <p className="mt-[2px] text-sm font-normal text-lightGray">Back</p>
        </div>
        <header className="bg-alabasterHeader p-[24px] rounded-t-[10px]">
          <div className="flex justify-between flex-wrap ">
            <div>
              <p className="text-black text-sm font-medium">
                Associate with a new buyer
              </p>
              <p className="text-textColor text-xs font-normal">
                List of buyers available on PayEdge
              </p>
            </div>
            <div className="flex gap-x-[10px] mt-[10px] md:mt-0 flex-wrap">
              <div className="flex items-center  bg-white p-[10px] gap-x-[8px] rounded-[5px] border-[0.5px] border-silver xl:w-[350px]">
                <PAIconSearch />
                <input
                  type="text"
                  placeholder="Search buyers"
                  onChange={(e) => setCompanyName(e.target.value)}
                  value={companyName}
                  className="placeholder:text-xs outline-none"
                />
              </div>
              <Button
                neutral
                disabled={data < 1}
                onClick={handleSearch}
                className="py-[12px] mt-[10px] md:mt-0 px-[24px] border-[0.5px] border-silver bg-brightGray text-sm !text-black rounded-[5px] font-medium"
              >
                Search
              </Button>
            </div>
          </div>
        </header>
        <BuyerDetailsModal
          isShown={openDetailsModal}
          onClose={() => setOpenDetailsModal(false)}
          getId={getId}
          isPendingRequest
        />
        {associationSuccessful && (
          <AssociationSuccessModal
            message="This Buyer would receive a request mail from you to associate"
            handleCloseModal={handleCloseModal}
          />
        )}
        <DeclineModal
          isShown={openDeclineModal}
          onClose={() => setOpenDeclineModal(false)}
          success={success}
          handleAssociate={handleDecline}
          isLoading={declineLoading}
          warning="Are you sure you would like to Decline this request?"
        />
        <AccountModalContainer
          isShown={open}
          onClose={() => setOpen(false)}
          success={success}
          handleAssociate={handleAssociate}
          isLoading={associateLoading}
          warning=" Are you sure you would like to associate with the buyer?"
        />
        <div className="p-[24px] bg-white rounded-b-[10px]">
          <Table
            columns={column}
            data={data}
            pointer
            onRowClicked={onRowClicked}
            progressPending={loading}
            progressComponent={<Loading />}
            tableHeader
          />
          <br />

          <div className="flex justify-center gap-x-[8px]  mt-12">
            <Pagination
              page={page}
              startPage={startPage}
              setEndPage={setEndPage}
              endPage={endPage}
              setStartPage={setStartPage}
              setPage={setPage}
              totalPages={totalPages}
            />
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default AssociateRequest;
