import React from "react";
import Table from "layout/table/Table";
import { useNavigate } from "react-router-dom";

const Bulk = () => {
  const bulkdumData = [
    {
      idx: "1",
      companyName: "XYZ Ventures",
      player: "Supplier",
      invoiceDate: "2023-05-23",
      invoiceReference: "INV-2090",
      currency: "NGN",
      amount: 9000000,
      status: "Successful",
      channel: "Early Payment Request",
    },
    {
      idx: "2",
      companyName: "Dangote LTD ",
      player: "Supplier",
      invoiceDate: "2023-05-23",
      invoiceReference: "INV-2090",
      currency: "NGN",
      amount: 9000000,
      status: "Successful",
      channel: "Loan Default",
    },
    {
      idx: "3",
      companyName: "Nestle PLC",
      player: "Supplier",
      invoiceDate: "2023-05-23",
      invoiceReference: "INV-2090",
      currency: "NGN",
      amount: 9000000,
      status: "Successful",
      channel: "Early Payment Offers ",
    },
  ];

  const formatAmount = (amount) => {
    if (typeof amount !== "number") return "0.00";
    return amount?.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const columnsTwo = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Transaction Party
        </span>
      ),
      selector: (row) => row.companyName,
      sortable: true,
      cell: (row) => (
        <div className="">
          <p>{row.companyName}</p>
          <span
            className={`${
              row.player === "Supplier" ? "text-[#2EA923]" : "text-[#F08000]"
            }`}
          >
            {`(${row.player})`}
          </span>
        </div>
      ),
    },
    {
      name: "Payment Date",
      selector: (row) => row.invoiceDate?.slice(0, 10),
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          No. of Invoices
        </span>
      ),
      selector: (row) => row.invoiceReference,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
      width: "6.2rem",
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      cell: (row) => (
        <p className="text-[#2EA923]">{formatAmount(row.amount)}</p>
      ),
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) =>
        row.status === "Successful" ? (
          <p className="text-[#07593D]">{row.status}</p>
        ) : row.status === "REJECTED" ? (
          <p className="text-red">{row.status}</p>
        ) : row.status === "UPDATED" ? (
          <p className="text-blue-300">{row.status}</p>
        ) : row.status === "PARTIALLY Successful" ? (
          <p className="text-yellow-400">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        ),
    },

    {
      name: "Transaction Type",
      selector: (row) => row.channel,
      sortable: true,
      width: "6.2rem",
    },
    {
      name: "Action",
      selector: (row) => row.action,
      sortable: true,
      cell: (row) => (
        <div
          className="border border-gray300 rounded text-[#959595] p-2"
          // onClick={() => transactionDetails(row.invoiceId)}
        >
          View Details
        </div>
      ),
    },
  ];

  const navigate = useNavigate();
  const handleRowClick = (row) => {
    if (row.channel === "Loan Default") {
      navigate(
        `/disbursement/funder/disbursement/bulkdetailsforEarlyPaymentRequest/${row.idx}`
      );
    } else if (row.channel === "Early Payment Request") {
      // navigate(
      //   `/work-flow-management/detail/PendingRequestInvoiceApprovalScreen/${row.workflowId}`
      // );
      navigate(
        `/disbursement/funder/disbursement/bulkdetailsforEarlyPaymentRequest/${row.idx}`
      );
      // navigate(
      //   `/work-flow-management/detail/approvalviawalletscreendetails/${row.workflowId}`
      // );
    } else if (row.channel === "Early Payment Offers ") {
      navigate(
        `/disbursement/funder/disbursement/bulkdetailsforEarlyPaymentRequest/${row.idx}`
      );
    }
  };

  //"funder/disbursement/bulkdetailsforEarlyPaymentRequest/:id"

  return (
    <Table
      columns={columnsTwo}
      data={bulkdumData}
      onRowClicked={(row) => handleRowClick(row)}
      pointer
      tableHeader
      className="mt-5"
    />
  );
};

export default Bulk;
