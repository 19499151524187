import Table from "layout/table/Table";
import React, { useCallback, useEffect, useState } from "react";
import Button from "shared/Button";
import BuyerDetailsModal from "../modal";
import DashboardLayout from "layout/dashboardlayout";
import {
  PAIconInventoryArrowLeft,
  PAIconSearch,
  PAIconInviteTopArrow,
} from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AccountModalContainer from "shared/accountModal/Modal";
import toast from "react-hot-toast";
// import Pagination from "shared/Pagination";
import Loading from "shared/Loading";
import DeclineModal from "shared/delineModal/Modal";
import UserAdd from "../../../../../assets/images/svgs/useradd.svg";
import Pagination from "shared/Pagination";
import ContinueInviteModal from "shared/inviteModal/ContnueInviteModal";
import FunderInvitationModal from "shared/inviteModal/FunderInvitationModal";
import SuccessModal from "shared/inviteModal/SuccessModal";
import { debounce } from "lodash";
import {
  buyerAcceptFunderRequest,
  buyerDeclineFunderRequest,
  buyerSendOnboardingInviteToFunder,
  buyersAssociatePendingRequestFunder,
  getSentPendingAssociationRequests,
  getSentPendingInvites,
} from "appstate/api/buyer/relationshipManagement";

const debouncedSearchFunction = debounce((fetchRequests, searchValue) => {
  fetchRequests(searchValue);
}, 500);

const BuyerFunderPendingRequest = () => {
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Pending Requests");
  const { isLoading, declineLoading, inviteLoading, associateLoading } =
    useSelector((state) => state.buyer);
  const [getId, setGetId] = useState(null);
  const [openDeclineModal, setOpenDeclineModal] = useState(false);
  const [opened, setOpened] = useState(false);
  const [success, setSuccess] = useState(false);
  const [companyName, setCompanyName] = useState("");
  // eslint-disable-next-line
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const [total, setTotal] = useState("");
  const pageSize = 10;
  const [data, setData] = useState([]);
  const [invite, setInvite] = useState([]);
  const [newInvite, setNewInvite] = useState("");
  const [openmodal, setOpenmodal] = useState(false);
  const [continueInvite, setContinueInvite] = useState(false);
  const [successModal, setSucceessModal] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const funderDetails = data?.find((cur) => cur?.userId === getId);
  const sender = "funder";
  
  const fetchRequests = useCallback(async (searchValue = "") => {
    setIsLoading(true);
    try {
      let pendingRequests = [];
      const entityType = "funder";
      const searchParams = searchValue.trim();
      if (selectedTab === "Pending Requests") {
        pendingRequests = await getSentPendingAssociationRequests({
          page,
          pageSize,
          entityType,
          search: searchParams,
        });
      } else if (selectedTab === "Pending Association") {
        pendingRequests = await buyersAssociatePendingRequestFunder({
          page,
          pageSize,
          sender: "funder",
          search: searchParams,
        });
      } else if (selectedTab === "Pending Invites") {
        pendingRequests = await getSentPendingInvites({
          page,
          pageSize,
          sender: "funder",
          search: searchParams,
        });
      }
      setTotal(pendingRequests?.meta?.totalElements);
      setData(pendingRequests?.data || []);
    } catch (error) {
      console.error("Failed to fetch requests", error);
    } finally {
      setIsLoading(false);
    }
  }, [selectedTab, page, pageSize]);

  useEffect(() => {
    fetchRequests();
  }, [fetchRequests]); 

  const debouncedSearch = useCallback((searchValue) => {
    debouncedSearchFunction(fetchRequests, searchValue);
  }, [fetchRequests]);

  const handleSearchInputChange = (e) => {
    const value = e.target.value;
    setCompanyName(value);
    debouncedSearch(value);
  };

  const onRowClicked = (row) => {
    setGetId(row?.userId);
  };

  const handleOpenModal = (row) => {
    setGetId(row?.notificationId);
    setOpened(true);
  };

  const handleAssociate = async () => {
    try {
      const notificationId = getId;
      const response = await buyerAcceptFunderRequest(notificationId);
      if (response?.success === true) {
        setSuccess(false);
        const pendingRequests = await getSentPendingAssociationRequests({
          page,
          pageSize,
          sender,
        });
        setData(pendingRequests?.data || []);
        setOpened(false);
        setSucceessModal(true);
      }
    } catch (error) {
      console.error("Failed to accept request", error);
    }
  };

  const handleSearch = () => {};

  const totalPages = total;

  const handleDecline = async () => {
    try {
      const notificationId = getId;
      const response = await buyerDeclineFunderRequest(notificationId);
      if (response?.success === true) {
        setSuccess(false);
        const pendingRequests = await getSentPendingAssociationRequests({
          page,
          pageSize,
          sender,
        });
        setData(pendingRequests?.data || []);
        setOpenDeclineModal(false);
      }
    } catch (error) {
      console.error("Failed to decline request", error);
    }
  };

  const handleOpenConfirmModal = (row) => {
    setSelectedRow(row);
    setShowConfirmModal(true);
  };

  const column = [
    {
      name: "EMAIL",
      selector: (row) => row?.email,
      width: "15rem",
    },
    {
      name: "STATUS",
      selector: (row) => <span style={{ color: "orange" }}>{row?.status}</span>,
    },
    {
      cell: (row) => {
        return (
          <div className="flex  justify-end flex-1">
            <Button
              onClick={() => handleOpenConfirmModal(row)}
              neutral
              className="flex px-[16px] py-[8px] border-[1px] border-gray rounded-[5px] bg-primaryColor"
            >
              <p className="text-xs font-normal text-white whitespace-nowrap">
                Resend Invite
              </p>
            </Button>
          </div>
        );
      },
    },
  ];

  const columnsPendingAssociation = [
    {
      name: "Name",
      selector: (row) => row?.fullName,
    },
    {
      name: "Company",
      selector: (row) => row?.companyName,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
    },
    {
      name: "VendorId",
      selector: (row) => row?.vendorId,
    },
    {
      name: "Phone",
      selector: (row) => row?.phoneNumber,
    },
    {
      cell: (row) => (
        <div className="flex gap-x-[12px]">
          <Button
            onClick={() => handleOpenModal(row)}
            neutral
            className="flex px-[16px] py-[8px] border-[1px] border-gray rounded-[5px] bg-primaryColor"
          >
            <p className="text-xs font-normal text-white whitespace-nowrap">
              Accept
            </p>
          </Button>
          <Button
            onClick={() => handleOpenModal(row)}
            neutral
            className="flex px-[16px] py-[8px] border-[1px] border-[red] rounded-[5px] bg-[white]"
          >
            <p className="text-xs font-normal  text-[red] whitespace-nowrap">
              Decline
            </p>
          </Button>
        </div>
      ),
    },
  ];

  const columnsPendingRequest = [
    {
      name: "Name",
      selector: (row) => row?.fullName,
    },
    {
      name: "Company",
      selector: (row) => row?.companyName,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
    },
    {
      name: "VendorId",
      selector: (row) => row?.vendorId,
    },
    {
      name: "Phone",
      selector: (row) => row?.phoneNumber,
    },
    {
      name: "Status",
      selector: (row) => (
        <span
          style={{
            color: row?.status === "Pending" ? "orange" : "orange",
          }}
        >
          Pending
        </span>
      ),
    },
  ];

  const navigate = useNavigate();
  function handleNavigate() {
    navigate(-1);
  }
  const handleTabClick = (tabName) => {
    setSelectedTab(tabName);
  };

  const handleShowSuccessModal = () => {
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 3000);
  };

  const handleResendEmails = async () => {
    if (!selectedRow) return;
    const body = [selectedRow.email];

    try {
      const response = await buyerSendOnboardingInviteToFunder(body);
      if (response) {
        setShowConfirmModal(false);
        handleShowSuccessModal();
      }
    } catch {
      console.error("An error occurred");
      toast.error("Error occurred. Try again later!");
    }
  };

  const handleSubmitEmails = async () => {
    const body = invite;
    try {
      const response = await buyerSendOnboardingInviteToFunder(body);
      if (response) {
        setOpenmodal(false);
        // setContinueInvite(false);
        setSuccess(true);
      }
    } catch {
      console.error("an error occured");
      toast.error("error occured try again later!");
    }
  };
  const handleSubContinue = () => {
    setContinueInvite(false);
    setSuccess(true);
  };
  const handleContinueInvite = () => {
    setOpenmodal(false);
    setContinueInvite(true);
  };
  const handleClose = () => {
    setSuccess(false);
  };
 

  return (
    <DashboardLayout
      section="Funders"
      subHeader="Manage all your associated funders account"
    >
      <div
        style={{ padding: 15, borderRadius: 5 }}
        className="flex justify-between items-center bg-white"
      >
        <div className="w-[40%] flex justify-between">
          <h3
            className={`cursor-pointer ${
              selectedTab === "Pending Requests" ? "text-[#07593D]" : ""
            }`}
            onClick={() => handleTabClick("Pending Requests")}
            style={{
              borderBottom:
                selectedTab === "Pending Requests"
                  ? "2px solid #07593D"
                  : "none",
              paddingBottom: "5px",
              fontSize: "14px",
              color: "#959595",
            }}
          >
            Pending Requests
          </h3>
          <h3
            className={`cursor-pointer ${
              selectedTab === "Pending Association" ? "text-[#07593D]" : ""
            }`}
            onClick={() => handleTabClick("Pending Association")}
            style={{
              borderBottom:
                selectedTab === "Pending Association"
                  ? "2px solid #07593D"
                  : "none",
              paddingBottom: "5px",
              fontSize: "14px",
              color: "#959595",
            }}
          >
            Pending Association
          </h3>
          <h3
            className={`cursor-pointer ${
              selectedTab === "Pending Invites" ? "text-[#07593D]" : ""
            }`}
            onClick={() => handleTabClick("Pending Invites")}
            style={{
              borderBottom:
                selectedTab === "Pending Invites"
                  ? "2px solid #07593D"
                  : "none",
              paddingBottom: "5px",
              fontSize: "14px",
              color: "#959595",
            }}
          >
            Pending Invites
          </h3>
        </div>
        <div>
          <Button
            onClick={() => setOpenmodal(true)}
            neutral
            className="flex text-[#F08000] text-xs font-medium items-center mb-[10px] md:mb-0 md:px-[5px] bg-[#07593D] py-[12px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px]"
          >
            <img src={UserAdd} alt="" />
            <p style={{ color: "white" }} className="whitespace-nowrap">
              Invite Funder
            </p>
          </Button>
        </div>
      </div>

      <br />
      <div>
        {selectedTab === "Pending Requests" && (
          <header className="bg-alabasterHeader p-[24px] rounded-t-[10px]">
            <div
              onClick={handleNavigate}
              className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
            >
              <PAIconInventoryArrowLeft />
              <p className="mt-[2px] text-sm font-normal text-lightGray">
                back
              </p>
            </div>
            <div className="flex justify-between flex-wrap ">
              <div>
                <p className="text-black text-sm font-medium">
                  Pending requests to associate with funders
                </p>
                <p className="text-textColor text-xs font-normal">
                  List of association request sent out to funders
                </p>
              </div>
              <div className="flex gap-x-[10px] mt-[10px] md:mt-0 flex-wrap">
                <div className="flex items-center  bg-white p-[10px] gap-x-[8px] rounded-[5px] border-[0.5px] border-silver xl:w-[350px]">
                  <PAIconSearch />
                  <input
                    type="text"
                    value={companyName}
                    onChange={handleSearchInputChange}
                    placeholder="Search request"
                    className="placeholder:text-xs outline-none"
                  />
                </div>
                <Button
                  neutral
                  onClick={handleSearch}
                  disabled={data < 1}
                  className="py-[12px] mt-[10px] md:mt-0 px-[24px] border-[0.5px] border-silver bg-brightGray text-sm !text-black rounded-[5px] font-medium"
                >
                  Search
                </Button>
              </div>
            </div>
          </header>
        )}

        {selectedTab === "Pending Association" && (
          <header className="bg-alabasterHeader p-[24px] rounded-t-[10px]">
            <div
              onClick={handleNavigate}
              className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
            >
              <PAIconInventoryArrowLeft />
              <p className="mt-[2px] text-sm font-normal text-lightGray">
                back
              </p>
            </div>
            <div className="flex justify-between flex-wrap ">
              <div>
                <p className="text-black text-sm font-medium">
                  Pending requests to associate
                </p>
                <p className="text-textColor text-xs font-normal">
                  List of funders wanting to associate
                </p>
              </div>
              <div className="flex gap-x-[10px] mt-[10px] md:mt-0 flex-wrap">
                <div className="flex items-center  bg-white p-[10px] gap-x-[8px] rounded-[5px] border-[0.5px] border-silver xl:w-[350px]">
                  <PAIconSearch />
                  <input
                    type="text"
                    value={companyName}
                    onChange={handleSearchInputChange}
                    placeholder="Search request"
                    className="placeholder:text-xs outline-none"
                  />
                </div>
                <Button
                  neutral
                  onClick={handleSearch}
                  disabled={data < 1}
                  className="py-[12px] mt-[10px] md:mt-0 px-[24px] border-[0.5px] border-silver bg-brightGray text-sm !text-black rounded-[5px] font-medium"
                >
                  Search
                </Button>
              </div>
            </div>
          </header>
        )}

        {selectedTab === "Pending Invites" && (
          <header className="bg-alabasterHeader p-[24px] rounded-t-[10px]">
            <div
              onClick={handleNavigate}
              className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
            >
              <PAIconInviteTopArrow />
              <p className="mt-[2px] text-sm font-normal text-lightGray">
                Back
              </p>
            </div>
            <div className="flex justify-between flex-wrap ">
              <div>
                <p className="text-black text-sm font-medium">
                  Pending invites to funders
                </p>
                <p className="text-textColor text-xs font-normal">
                  List of invites sent out to funders
                </p>
              </div>
              <div className="flex gap-x-[10px] mt-[10px] md:mt-0 flex-wrap">
                <div className="flex items-center  bg-white p-[10px] gap-x-[8px] rounded-[5px] border-[0.5px] border-silver xl:w-[350px]">
                  <PAIconSearch />
                  <input
                    type="text"
                    value={companyName}
                    onChange={handleSearchInputChange}
                    placeholder="Search request"
                    className="placeholder:text-xs outline-none"
                  />
                </div>
                <Button
                  neutral
                  onClick={handleSearch}
                  disabled={data < 1}
                  className="py-[12px] mt-[10px] md:mt-0 px-[24px] border-[0.5px] border-silver bg-brightGray text-sm !text-black rounded-[5px] font-medium"
                >
                  Search
                </Button>
              </div>
            </div>
          </header>
        )}

        <ContinueInviteModal
          isOpen={continueInvite}
          onClose={() => setContinueInvite(false)}
          isLoading={isLoading}
          handleYes={handleSubContinue}
        />
        <FunderInvitationModal
          onClose={() => setOpenmodal(false)}
          isOpen={openmodal}
          message=" The Funder would received a mail that your are inviting to join
          PayEdge."
          heading="Invite Funder"
          info="You can add one or more funders at once with ease"
          role="Funder"
          invite={invite}
          setInvite={setInvite}
          newInvite={newInvite}
          setNewInvite={setNewInvite}
          success={success}
          setSuccess={setSuccess}
          handleAdd={handleSubmitEmails}
          handleYes={handleContinueInvite}
          isLoading={inviteLoading}
        />

        <SuccessModal
          isOpen={success}
          onClose={handleClose}
          message=" The Funder would received a mail that your are inviting to join
          PayEdge."
        />

        <BuyerDetailsModal
          isShown={openDetailsModal}
          onClose={() => setOpenDetailsModal(false)}
          data={funderDetails}
        />
        {successModal && (
          <SuccessModal
            isOpen={successModal}
            message="Association Successful"
          />
        )}
        <DeclineModal
          isShown={openDeclineModal}
          onClose={() => setOpenDeclineModal(false)}
          success={success}
          handleAssociate={handleDecline}
          isLoading={declineLoading}
          warning="Are you sure you would like to Decline this request?"
        />
        <AccountModalContainer
          isShown={showConfirmModal}
          onClose={() => showConfirmModal(false)}
          success={success}
          handleAssociate={handleResendEmails}
          // isLoading={associateLoading}
          warning="Are you sure you would like to resend this email to the supplier?"
        />
        <AccountModalContainer
          isShown={opened}
          onClose={() => setOpened(false)}
          success={success}
          handleAssociate={handleAssociate}
          isLoading={associateLoading}
          warning="Are you sure you would like to associate with the Funder?"
        />
        <div className="p-[24px] bg-white rounded-b-[10px]">
          {selectedTab === "Pending Association" && (
            <Table
              columns={columnsPendingAssociation}
              data={data}
              pointer
              onRowClicked={onRowClicked}
              progressPending={loading}
              progressComponent={<Loading />}
              tableHeader
            />
          )}

          {selectedTab === "Pending Requests" && (
            <Table
              columns={columnsPendingRequest}
              data={data}
              pointer
              onRowClicked={onRowClicked}
              progressPending={loading}
              progressComponent={<Loading />}
              tableHeader
            />
          )}

          {selectedTab === "Pending Invites" && (
            <Table
              columns={column}
              data={data}
              pointer
              onRowClicked={onRowClicked}
              progressPending={loading}
              progressComponent={<Loading />}
              tableHeader
            />
          )}
          <br />

          <div className="flex justify-center gap-x-[8px]  mt-12">
            {!isLoading && totalPages > 10 && (
              <Pagination
                page={page}
                startPage={startPage}
                setEndPage={setEndPage}
                endPage={endPage}
                setStartPage={setStartPage}
                setPage={setPage}
                totalPages={totalPages}
              />
            )}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default BuyerFunderPendingRequest;
