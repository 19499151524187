import { PAIconMDISuccess, PAIStarDoneSucess } from "assets/images/svgs";
import React, { useEffect } from "react";

const AgreementSuccessModal = ({ isOpen, onClose, message, sucess }) => {
  useEffect(() => {
    let timeoutId;

    if (isOpen) {
      timeoutId = setTimeout(() => {
        onClose();
      }, 1000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isOpen, onClose]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg rounded-[10px] z-[1000]">
      {" "}
      <div className="flex-1 items-center justify-center flex  w-full h-full">
        <div className="flex mt-[200px]  ">
          <div className="bg-primaryColor w-[147.79px]  h-[160px] flex flex-col items-center justify-center rounded-l-[7px] shadow-lg">
            <PAIconMDISuccess />
          </div>
          <div className="bg-white  flex-col justify-center items-center self-stretch py-[25px] h-[160px] rounded-r-[7px] shadow-lg flex px-16 ">
            <PAIStarDoneSucess />
            <p className="text-dustyGray text-[13px] text-center mt-[17.6px]">
              {message}
              <p>{sucess}</p>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgreementSuccessModal;
