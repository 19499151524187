import { SelectContainer } from "shared/DeleteModal/Delete/styles";
import TableHeaderSelect from "shared/tableHeader/TableHeaderSelect";
import Search from "../../assets/images/svgs/search-normal.svg";
import InputField from "shared/InputField";
import Button from "shared/Button";
import FilterIcons from "../../assets/images/svgs/filtericonss.svg";
import { useEffect } from "react";

const SupplierEarlyPaymentHeader = ({
  requestOptions,
  statusOptions,
  selectedRequest,
  header,
  onChange,
  setSelectedRequest,
  handleBuyerNameChange,
  buyerName

}) => {

  useEffect(() => {
    setSelectedRequest("Single Request");
  }, [setSelectedRequest]);

  const handleRequestChange = (event) => {
    setSelectedRequest(event.target.value);
  };

  return (
    <div>
      <div className="flex justify-between">
        <div className="">
          <SelectContainer>
            <TableHeaderSelect
              onChange={handleRequestChange}
              defaultValue="Single Request"
              options={requestOptions}
              header="Single Request"
            />
          </SelectContainer>
        </div>
        <div className="flex items-center">
          <div className="pr-2">
            <SelectContainer>
              <TableHeaderSelect onChange={onChange} header={header} options={statusOptions} />
            </SelectContainer>
          </div>
          <div className="relative">
            <img
              src={Search}
              alt="Search Icon"
              className="absolute left-3 top-1/2 transform -translate-y-1/2 w-4 h-4"
            />
            <InputField
              placeholder="Search for requests"
              value={buyerName}
              onChange={handleBuyerNameChange}
              className="pl-10 w-full py-2 border rounded-md border[#CCCCCC] outline-none md:w-[350px] !border-silver "
            />
          </div>
          <div className="pl-2">
            <Button
              neutral
              //   onClick={setOpen ? () => setOpen(true) : handleNavigate}
              className="flex !text-[#565656] gap-2 text-xs font-medium items-center mb-[10px] md:mb-0 border border-[#ccc] md:px-[5px] !bg-[#2FA06A] py-[12px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px]"
            >
              <img src={FilterIcons} alt="" />
              <p style={{ color: "white" }}>Filter</p>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupplierEarlyPaymentHeader;
