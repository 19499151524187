import React, { useState } from "react";
import {
  PAIconDownload,
  PAIconInfoGreen,
  PAIconInvoiceIcon,
  PAIconSearchGreen,
  PAIconDropdown,
} from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import * as XLSX from "xlsx";
import Card from "shared/Card";

import Table from "layout/table/Table";

import { useSelector } from "react-redux";

// import Pagination from "shared/Pagination";
import Loading from "shared/Loading";

import ViewDocumentModal from "features/RulesConfig/common/components/modals/DeleteRuleModal";
import ViewFundInvoiceModal from "features/funderpayablefinance/components/modals/ViewFundInvoiceModal";

import SelectedRequests from "features/disbursement/commonforbuyer/components/SelectedRequests";
import EnterPin from "../components/modals/EnterPin";
// import LienApproveCardWithoutSelect from "features/disbursement/commonforbuyer/components/LienApproveCardWithoutSelect";
import LienApproveCardWithoutSelect from "features/disbursement/commonforbuyer/components/LienApproveCardWithoutSelect";
// import ProgressModal from "shared/progressModal/ProgressModal";
// import { useDispatch } from "react-redux";

// import { fetchDefaultLoans, makePaymentNow } from "appstate/buyer/buyerSlice";

const BuyerLoanDefaultDisburesement = () => {
  const navigate = useNavigate();
  const { invoiceByIdData } = useSelector((state) => state?.openMarket);

  const [selectedFilters] = useState([]);
  const [isDropdownOpen] = useState(false);

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  // const [transferModal, setTransferModal] = useState(false);
  const [acceptPaymentModal, setAcceptPaymentModal] = useState(false);

  const [, setSelectAllText] = useState("Select All");
  const [pinModal, setPinModal] = useState(false);
  // const [paymentModal, setPaymentModal] = useState(false);
  const [selectDownloadType, setSelectDownloadType] = useState(false);
  const [makePaymentModal, setMakePaymentModal] = useState(false);
  const [rowData, setRowData] = useState({});

  const [searchQuery, setSearchQuery] = useState("");
  // const [page, setPage] = useState(0);
  // const [startPage, setStartPage] = useState(0);
  // const [endPage, setEndPage] = useState(10);
  // const pageSize = 10;

  const [selectedRequest, setSelectedRequest] = useState("Single Request");

  const formatAmount = (amount) => {
    if (!amount) return "0.00";
    const parsedAmount = parseFloat(amount);
    if (isNaN(parsedAmount)) return "0.00";
    return parsedAmount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const handleSearchInputChange = (event) => {
    const value = event.target.value;
    setSearchQuery(value);
    sessionStorage.setItem("searchQueryFunder", value);
  };

  // const handleFilterButtonClick = () => {};

  // SEND REMAINDER BUTTON
  const handleSendReminder = () => {
    setMakePaymentModal(true);
  };

  // CHECKBOX
  const handleCheckboxChange = (row) => {
    const isSelected = selectedRows.includes(row.id);
    let newSelectedRows = [];

    if (isSelected) {
      newSelectedRows = selectedRows.filter((id) => id !== row.id);
    } else {
      newSelectedRows = [...selectedRows, row.id];
    }

    setSelectedRows(newSelectedRows);
  };

  // SELECT ALL
  // const handleSelectAll = () => {
  //   if (selectAll) {
  //     setSelectedRows([]);
  //     setSelectAllText("Select All");
  //   } else {
  //     const allIds = defaultLoans?.loans?.data?.map((row) => row.id);
  //     setSelectedRows(allIds);
  //     setSelectAllText("Deselect All");
  //   }
  //   setSelectAll(!selectAll);
  //   // setDisabled(selectAll);
  // };

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(
      defaultLoans?.loans?.data.data.map((row) => ({
        "Program Ref No": row?.refNo,
        "Invoice Number": `INV-${row?.invoiceNumber}`,
        Buyer: row?.buyerCompanyName,
        "Due Date": row?.dueDate,
        "Payment Date": row?.paymentDate,
        Currency: row?.currency,
        "Invoice Amount": formatAmount(row?.invoiceAmount),
        "Discount Amount": formatAmount(row?.discountAmount),
        "Discount Rate": formatAmount(row?.discountRate),
        "Fundable Amount": formatAmount(row?.fundableAmount),
      }))
    );
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Direct Funding");
    XLSX.writeFile(wb, "DirectFundingData.xlsx");
  };

  const columns = [
    {
      // name: (
      //   <input type="checkbox" onChange={handleSelectAll} checked={selectAll} />
      // ),
      cell: (row) => (
        <input
          type="checkbox"
          onChange={() => {
            handleCheckboxChange(row);

            if (!selectedRows.includes(row?.id)) {
              setRowData(row);
            } else {
              setRowData({});
            }
          }}
          checked={selectedRows.includes(row?.id)}
        />
      ),
      width: "50px",
    },

    {
      name: "Invoice No",
      selector: (row) => row.invoiceRef,
      sortable: true,
      width: "7rem",
    },

    {
      name: "Company",
      selector: (row) => row.supplierName,
      sortable: true,
    },

    {
      name: " Due Date",
      selector: (row) => row.dueDate,
      sortable: true,
    },

    {
      name: <span className="whitespace-nowrap">Funding Channel </span>,
      selector: (row) => row.fundingChannel,
      sortable: true,
      width: "9rem",
    },

    {
      name: "Currency",
      selector: (row) => row?.currency,
      sortable: true,
    },
    {
      name: "Invoice Total",
      selector: (row) => row?.invoiceAmount,
      sortable: true,

      width: "9rem",
    },
    {
      name: "Total Loan Interest",
      selector: (row) => row?.loanInterest,
      sortable: true,
      cell: (row) => (
        <p className=" text-[#DE1515]">{formatAmount(row?.loanInterest)}</p>
      ),

      width: "10rem",
    },

    {
      name: "Grand Total",
      selector: (row) => row?.grandTotal,
      sortable: true,
      cell: (row) => <p className=" text-[#DE1515]">{row?.grandTotal}</p>,

      width: "9rem",
    },
    {
      name: "Action",
      cell: (row) => (
        <motion.button
          onClick={() => {
            setSelectAll(false);
            setSelectedRows([]);
            setSelectAllText("Select All");
            setAcceptPaymentModal(true);
          }}
          whileTap={{ scale: 0.95 }}
          className="py-2 px-4 text-xs  text-white rounded bg-primaryColor"
        >
          Pay Loan
        </motion.button>
      ),
      width: "9rem",
    },
  ];

  const dummyDataBody = [
    {
      id: "1",
      invoiceRef: "INV-2090",
      supplierName: "Supplier A",
      dueDate: "2023-05-31",
      fundingChannel: "Direct Funding",
      currency: "NGN",
      invoiceAmount: "10,473,890",
      loanInterest: "+5%",
      grandTotal: "10,997,584",
    },
    {
      id: "2",
      invoiceRef: "INV-2090",
      supplierName: "Supplier A",
      dueDate: "2023-05-31",
      fundingChannel: "Direct Funding",
      currency: "NGN",
      invoiceAmount: "10,473,890",
      loanInterest: "+5%",
      grandTotal: "10,997,584",
    },
    {
      id: "3",
      invoiceRef: "INV-2090",
      supplierName: "Supplier A",
      dueDate: "2023-05-31",
      fundingChannel: "Direct Funding",
      currency: "NGN",
      invoiceAmount: "10,473,890",
      loanInterest: "+5%",
      grandTotal: "10,997,584",
    },
  ];

  // const dispatch = useDispatch();

  const animationVariants = {
    initial: { opacity: 0, x: 50, scale: 0.95 },
    animate: { opacity: 1, x: 0, scale: 1 },
    exit: { opacity: 0, x: 50, scale: 0.95 },
    transition: { duration: 6.5, ease: "easeInOut" }, // Increased duration here
  };

  // subText = "Details on the next Early Payment Request date",
  const {
    defaultLoans,
    // earlyPaymentOfferByVa,
    isLoading,
  } = useSelector((state) => state.buyer);

  // useEffect(() => {
  //   dispatch(fetchDefaultLoans({ page, size: pageSize }));
  // }, [dispatch, page]);

  return (
    <>
      <section className="flex-row  justify-between  w-full">
        <div className="w-full flex items-center gap-2 justify-between">
          <div>
            <div className="flex items-center gap-[4px] border border-[#DEDEDE] bg-white shadow-sm rounded-[5px] px-4 py-2 text-sm focus:outline-none cursor-pointer">
              Filter{" "}
              {selectedFilters.length > 0 && (
                <span className="bg-gray rounded-lg p-1 px-2">
                  {selectedFilters.length}
                </span>
              )}{" "}
              Selected <PAIconDropdown />
            </div>
            {isDropdownOpen && (
              <div className="absolute top-full mt1 wfull border border-[#DEDEDE] shadow-lg rounded-b-[5px] px-4 py-3 bg-white">
                <label className="block">
                  <input type="checkbox" value="date" />
                  Date
                </label>
                <label className="block">
                  <input type="checkbox" value="name" />
                  Name
                </label>
                <label className="block">
                  <input
                    type="checkbox"
                    value="currency"
                    checked={selectedFilters.includes("currency")}
                  />
                  Currency
                </label>
                <label className="block">
                  <input
                    type="checkbox"
                    value="amount"
                    checked={selectedFilters.includes("amount")}
                  />
                  Amount
                </label>
              </div>
            )}
          </div>
          <div className=" flex justify-between gap-3">
            {" "}
            <div className="flex gap-2.5 w-[350px] bg-white border-[0.5px] border-[#ccc] p-2.5 items-center rounded-[5px]">
              <PAIconSearchGreen />
              <input
                type="text"
                placeholder="Search for invoices "
                value={searchQuery}
                onChange={handleSearchInputChange}
                className="p-2 w-full text-xs flex items-center text-black placeholder:text-black focus:outline-none"
              />
            </div>
            <div className=" justify-center items-center flex">
              {" "}
              <div class="h-[41px] px-6 py-3 bg-[#f2f2f2]/95 rounded-[5px] border border-[#cccccc] justify-start items-center gap-2.5 inline-flex">
                <div class="text-black text-sm font-medium ">Search</div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-6 flex-col lg:flex-row flex gap-5 justify-between">
        <LienApproveCardWithoutSelect
          sectA="Total Money Owed "
          sectB="Total Loan Defaults Requests "
          totalLoanDefault={"80"}
          defaulAmount={"556,876,343"}
        />
        {selectAll ? (
          <SelectedRequests
            subTitle="Details on the selected Early Payment offers"
            handleMakePayment={handleSendReminder}
          />
        ) : (
          <motion.div
            className="w-full lg:w-1/2 px-5 flex flex-col gap-5 bg-white rounded py-3"
            initial="initial"
            animate="animate"
            exit="exit"
            variants={animationVariants}
          >
            <header className="flex gap-6 justify-between items-center">
              <div className="flex flex-col gap-1">
                <span className="text-[#222] text-[17px]">
                  {selectedRequest === "Single Request"
                    ? " Upcoming Loan Interest Rate"
                    : "Default Loan"}
                </span>

                <span className="text-xs font-medium text-[#959595]">
                  {selectedRequest === "Single Request"
                    ? "Details on the additional interest details"
                    : "Details on the next additional interest date"}
                </span>
              </div>

              <span>
                {/* #959595 */}
                {/* <motion.button
                  whileTap={{ scale: 0.92 }}
                  onClick={() => handleSelectAll()}
                  className="py-2 px-4 text-sm bg-primaryColor text-white rounded"
                >
                  Pay now
                </motion.button> */}
                {selectedRows.length > 0 ? (
                  <motion.button
                    whileTap={{ scale: 0.92 }}
                    onClick={() => setAcceptPaymentModal(true)}
                    className="py-2 px-4 text-sm bg-primaryColor text-white rounded"
                  >
                    Pay now
                  </motion.button>
                ) : (
                  <div class="h-[33px] px-4 py-2 bg-[#d7d7d7] rounded justify-center items-center gap-2.5 inline-flex">
                    <div class="text-[#949494] text-sm font-semibold font-['SF Pro Display']">
                      Select an invoice{" "}
                    </div>
                  </div>
                )}
              </span>
            </header>

            <main className="flex flex-col gap-5">
              <div className="flex justify-between items-center">
                <span className="text-sm text-[#7A7A7A] font-medium">
                  Count down{" "}
                </span>

                <span className="text-secondaryColor font-medium">
                  {selectedRows.length > 0
                    ? `${rowData?.countDownDate ? rowData?.countDownDate : ""}`
                    : "--"}
                </span>
              </div>

              <div className="flex justify-between items-center">
                <span className="text-sm text-[#7A7A7A] font-medium">
                  Additional Interest
                </span>

                <span className="text-[#222] font-medium">
                  {" "}
                  {selectedRows.length > 0 ? `${rowData?.loanInterest}` : "--"}
                </span>
              </div>

              <div className="flex justify-between items-center">
                <span className="text-sm text-[#7A7A7A] font-medium">
                  Additional Interest amount
                </span>

                <span className="text-[#222] font-medium">
                  {selectedRows.length > 0 ? (
                    <div>
                      {" "}
                      <span className="text-xs font-light">
                        {" "}
                        {rowData?.currency}
                      </span>
                      <span className="text-[#222] font-medium">
                        {rowData?.loanAmount}
                      </span>
                    </div>
                  ) : (
                    "--"
                  )}
                </span>
              </div>
            </main>
          </motion.div>
        )}
      </section>

      {/* TABLE */}
      <div>
        {/* {!directFundingData?.data?.length ? (
          <div>
            <div className="flex justify-between items-center px-[15px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
              <p className="text-black text-sm font-medium">
                Due Invoices from Direct Funding
              </p>
            </div>

            <TableEmptyState dashboard />
          </div>
        ) : ( */}
        <Card className="rounded-[10px] min-h-[80vh] mt-[15px]">
          <div className="flex justify-between items-center p-[20px] bg-alabasterHeader rounded-t-[10px]">
            <div className="flex gap-6 items-center">
              <div>
                <span className="flex items-center gap-1">
                  <p className="text-black font-bold">Loan Defaults</p>
                </span>

                <span className="text-xs font-thin">
                  List of all outstanding loan Default
                </span>
              </div>
            </div>
          </div>
          <div className="pb-3 px-6">
            <div className="flex  space-x-8 ">
              {["Single Request", "Bulk Request"].map((requestType) => (
                <div
                  key={requestType}
                  onClick={() => setSelectedRequest(requestType)}
                  className={`cursor-pointer text-center pb-2 relative ${
                    selectedRequest === requestType
                      ? "text-orange-500"
                      : "text-gray-500"
                  }`}
                >
                  {requestType}
                  {selectedRequest === requestType && (
                    <div
                      className="absolute left-1/2 transform -translate-x-1/2 bottom-0 h-[3px] bg-orange-500"
                      style={{ width: "40px" }}
                    ></div>
                  )}
                </div>
              ))}
            </div>
            <div className="pb-3">
              {selectedRequest === "Single Request" ? (
                <>
                  <Table
                    columns={columns}
                    data={dummyDataBody}
                    onRowClicked={(row) =>
                      navigate(
                        `/reimbursement-single-reimburse-loandefault-details/${row?.id}`
                      )
                    }
                    progressPending={isLoading}
                    progressComponent={<Loading />}
                    pointer
                    tableHeader
                    className="mt-5"
                  />

                  {/* <div className="flex justify-center gap-x-[8px] mt-[20px]">
                {defaultLoans?.loans?.meta?.totalElements > 10 && (
                  <Pagination
                    page={page}
                    startPage={startPage}
                    setEndPage={setEndPage}
                    endPage={endPage}
                    setStartPage={setStartPage}
                    setPage={setPage}
                    totalPages={defaultLoans?.loans?.meta?.totalElements}
                  />
                )}
              </div> */}
                </>
              ) : (
                <>
                  <Table
                    columns={columns}
                    data={dummyDataBody}
                    onRowClicked={(row) =>
                      navigate(
                        `/reimbursement-bulk-reimburse-loandefault-details/${row?.id}`
                      )
                    }
                    progressPending={isLoading}
                    progressComponent={<Loading />}
                    pointer
                    tableHeader
                    className="mt-5"
                  />
                  {/* <div className="flex justify-center gap-x-[8px] mt-[20px]">
                {defaultLoans?.loans?.meta?.totalElements > 10 && (
                  <Pagination
                    page={page}
                    startPage={startPage}
                    setEndPage={setEndPage}
                    endPage={endPage}
                    setStartPage={setStartPage}
                    setPage={setPage}
                    totalPages={defaultLoans?.loans?.meta?.totalElements}
                  />
                )}
              </div> */}
                </>
              )}
            </div>
          </div>
        </Card>
        {/* )} */}
      </div>

      {/* Accept Modal */}
      <AnimatePresence>
        {selectDownloadType && (
          <ViewDocumentModal
            icon={<PAIconInvoiceIcon className=" animate-pulse" />}
            onAction={exportToExcel}
            onClose={() => setSelectDownloadType(false)}
          >
            <span className=" text-center">
              Would you like to proceed with sending a{" "}
              <span className="font-bold text-center text-primaryColor">
                Pay
              </span>{" "}
              this invoice list
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>

      {makePaymentModal && (
        <ViewFundInvoiceModal>
          <div className="flex lg:min-w-[520px] lg:max-w-[550px] overflowscroll max-h-[87vh]  flex-col gap-4">
            <header className="flex sticky top-0 bg-white py-3  items-center justify-between">
              <span className=" text-sm">Invoice Disbursement</span>

              <motion.button
                onClick={() => {
                  setMakePaymentModal(false);
                }}
                whileTap={{ scale: 0.9 }}
                className="text-xs border border-primaryColor py-2 px-8  rounded-[5px] text-primaryColor"
              >
                Close
              </motion.button>
            </header>

            <main className="flex flex-col  gap-6">
              <section className="flex gap-28 justify-between">
                <div className="flex flex-col gap-2">
                  <span className="text-[#222] text-[20px] font-bold">
                    Multiple Single invoice payment
                  </span>

                  <div className=" text-xs">
                    <span className=" text-[#7A7A7A]">78 Invoices</span> Invoice
                    No : | Buyer Info{" "}
                    <span className=" text-[#7A7A7A]">Walediwura</span>
                  </div>
                </div>

                <div className="flex items-center">
                  <motion.button
                    whileTap={{ scale: 0.9 }}
                    onClick={() => setAcceptPaymentModal(true)}
                    className="text-xs bg-primaryColor py-2 px-8  rounded-[5px] text-white"
                  >
                    Pay Now
                  </motion.button>
                </div>
              </section>

              <section className="flex gap-28 justify-between">
                <div className="flex flex-col">
                  <div>
                    <span>Payment Amount</span>
                  </div>
                  <span className="text-primaryColor text-[20px] font-bold">
                    NGN 1,890,400
                  </span>
                </div>

                <div className="flex flex-col gap-4 items-end">
                  <span className=" text-xs">
                    Total Invoice Amount &nbsp;
                    <span className="text-primaryColor border-b pb-0.5 border-b-[#AFAFAF]">
                      N2,345,000
                    </span>
                  </span>

                  <span className="border-b pb-0.5 text-xs  border-b-[#AFAFAF] w-fit">
                    Discount: 1.67%
                  </span>

                  <span className="text-[10px] font-medium flex items-center gap-1 text-[#F08000]">
                    <PAIconInfoGreen />
                    Tap to view more
                  </span>

                  {/* <span>
                    <motion.button
                      whileTap={{ scale: 0.9 }}
                      className="py-2 px-8 bg-[#F08000] text-xs text-white  rounded-[5px]"
                    >
                      Submit new amount
                    </motion.button>
                  </span> */}
                </div>
              </section>

              <section className=" flex flex-col ">
                <header className=" pb-3 font-bold border-b-[5px] mb-4 border-[#F6F5FA] text-[18px]">
                  Buyer Bulk Invoice details
                </header>

                <main className="flex pb-4 justify-between">
                  <div className="flex flex-col">
                    <span className="text-sm font-medium">Supplier</span>

                    <span className="text-xs text-[#7A7A7A]">
                      ABC Cooperation
                    </span>
                  </div>

                  <div className="flex flex-col">
                    <span className="text-sm font-medium">Buyer</span>

                    <span className="text-xs text-[#7A7A7A]">
                      Big Buyer Coop
                    </span>
                  </div>
                </main>

                <div>
                  <header className=" pb-3 font-bold border-b-[5px] mb-4 border-[#F6F5FA] text-sm">
                    Line Items
                  </header>

                  <div className=" w-full overflow-x-auto">
                    {invoiceByIdData ? (
                      <table className=" w-full overflow-x-auto">
                        <thead className="bg-subtleGray w-">
                          <tr className=" bg-unusualGray p-2.5 ">
                            <td className="text-[#11192A] p-2.5 text-xs font-bold">
                              Item Number
                            </td>
                            <td className=" p-2.5 text-xs font-bold">
                              PO Number
                            </td>
                            <td className=" p-2.5 text-xs font-bold">
                              Product/Service Number
                            </td>
                            <td className=" p-2.5 text-xs font-bold">
                              Product/Service Description
                            </td>
                            <td className=" p-2.5 text-xs font-bold">
                              Quantity
                            </td>
                            <td className=" p-2.5 text-xs font-bold">
                              Unit Price
                            </td>
                            <td className=" p-2.5 text-xs font-bold">
                              Line Item Extended Amount
                            </td>
                          </tr>
                        </thead>

                        <tbody>
                          {invoiceByIdData?.data?.items?.map((item, index) => (
                            <tr key={index} className=" py-[3px] px-1.5">
                              <td className=" text-xs text-[#11192a] p-2.5">
                                INV-{invoiceByIdData?.data?.invoiceNumber}
                              </td>
                              <td className=" text-xs text-[#11192a] p-2.5">
                                PO-2002
                              </td>
                              <td className=" text-xs text-[#11192a] p-2.5">
                                {item?.itemName}
                              </td>
                              <td className=" text-xs text-[#11192a] p-2.5">
                                {item?.description}
                              </td>
                              <td className=" text-xs text-[#11192a] p-2.5">
                                {item?.quantity}
                              </td>
                              <td className=" whitespace-nowrap text-xs text-[#11192a] p-2.5">
                                {invoiceByIdData?.data?.currency === "NGN"
                                  ? "₦"
                                  : "$"}{" "}
                                {item?.unitPrice}
                              </td>
                              <td className=" text-xs text-[#11192a] p-2.5">
                                {invoiceByIdData?.data?.currency === "NGN"
                                  ? "₦"
                                  : "$"}{" "}
                                {item?.amount}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <div className="flex flex-col gap-3 animate-pulse justify-center items-center w-full py-6 px-4 text-sm font-bold">
                        <PAIconInvoiceIcon />
                        No invoice list available
                      </div>
                    )}
                  </div>

                  <div className="flex py-5 flex-col gap-4">
                    <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
                      <h1 className="text-sm font-bold">Attachments</h1>
                    </header>

                    <div className="w-full overflow-x-auto">
                      <table className="w-full overflow-x-auto">
                        <thead className="  bg-subtleGray w-">
                          <tr className="px-4  bg-unusualGray p-2.5 ">
                            <td className="text-[#11192A] px-4 py-2.5 text-xs font-bold">
                              Document Name
                            </td>
                            <td className=" px-4 py-2.5 text-xs font-bold">
                              File Type
                            </td>
                            <td className=" px-4 py-2.5 text-xs font-bold">
                              Upload Date / Time
                            </td>
                            <td className=" px-4 py-2.5 text-xs font-bold">
                              Action
                            </td>
                          </tr>
                        </thead>

                        <tbody>
                          <tr className=" py-[3px] px-1.5">
                            <td className=" text-xs text-[#0076EC] px-4 py-2.5">
                              DOC-29384.pdf
                            </td>
                            <td className=" text-xs text-[#11192a] px-4 py-2.5">
                              PDF
                            </td>
                            <td className=" text-xs text-[#11192a] px-4 py-2.5">
                              2021-09-14 13:25
                            </td>
                            <td className="cursor-pointer text-xs text-[#11192a] px-4 py-2.5">
                              <PAIconDownload />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </section>
            </main>
          </div>
        </ViewFundInvoiceModal>
      )}

      <AnimatePresence>
        {acceptPaymentModal && (
          <ViewDocumentModal
            onAction={() => {
              setAcceptPaymentModal(false);
              setMakePaymentModal(false);
              setPinModal(true);
              // setTransferModal(true);
            }}
            onClose={() => setAcceptPaymentModal(false)}
          >
            <span className=" text-center">
              Are you sure you would like to{" "}
              <span className="font-semibold">Pay now</span> for{" "}
              {selectAll ? "these multiple" : "this"} invoices?
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>

      <AnimatePresence>
        <EnterPin
          isOpen={pinModal}
          onClose={() => setPinModal(false)}
          pinMessage={
            "Enter your PIN to fund this request from the chosen virtual account."
          }
          onSubmit={(pin) => {
            if (!rowData?.invoiceId || !rowData?.invoiceAmount) {
              console.error("Missing invoiceId or invoiceAmount in rowData.");
              return;
            }

            // const body = {
            //   transactionPin: pin,
            //   invoiceIds: [`${rowData.invoiceId}`],
            //   amount: `${rowData.invoiceAmount}`,
            //   virtualAccountId: "",

            //   disbursementType: `${rowData?.fundingChannel || ""}`,
            // };

            // dispatch(makePaymentNow({ body })).then((data) => {
            //   if (data?.payload?.status === 200) {
            //   }
            // });
          }}
        />
      </AnimatePresence>

      {/* Export Invoice List */}
      <AnimatePresence>
        {selectDownloadType && (
          <ViewDocumentModal
            icon={<PAIconInvoiceIcon className=" animate-pulse" />}
            onAction={exportToExcel}
            onClose={() => setSelectDownloadType(false)}
          >
            <span className=" text-center">
              Are you sure you want to{" "}
              <span className="font-bold text-center text-primaryColor">
                Export
              </span>{" "}
              this invoice list
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>
    </>
  );
};

export default BuyerLoanDefaultDisburesement;
