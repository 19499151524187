import { PAIconDoc, PAIconUndrawAddFiles } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import React, { useState } from "react";
import Button from "shared/Button";
import { useDispatch } from "react-redux";
import { getCsvFileData } from "appstate/invoice/invoiceSlice";
import {
  downloadCsvTemplateForInvoice,
  getTemplateHeaders,
} from "appstate/api/invoice/invoice";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Spinner from "shared/Spinner/Spinner";
import InvoiceSuccessModal from "shared/InvoiceSuccessModal/Index";
// import toast from "react-hot-toast";

const UploadInvoice = () => {
  // eslint-disable-next-line
  const [csvData, setCsvData] = useState(null);
  // eslint-disable-next-line
  const [fileName, setFileName] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [originalFile, setOriginalFile] = useState("");
  const [loading, setLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setOriginalFile(file);
    setFileName(file.name);
    const reader = new FileReader();

    reader.onload = async (event) => {
      const text = event.target.result;
      const dataArray = text.split("\n").map((row) => row.split(","));
      const headers = dataArray[0];

      const csvData = dataArray.slice(1).map((row) => {
        const obj = {};
        headers.forEach((header, index) => {
          obj[header] = row[index];
        });
        return obj;
      });

      dispatch(getCsvFileData(csvData));
      setCsvData(csvData);
    };

    reader.readAsText(file);
  };

  const verifyHeaders = async () => {
    setLoading(true);
    try {
      const response = await getTemplateHeaders(originalFile);

      if (response.success) {
        navigate("/invoice/invoice/fundingSection/invoiceupload/validation", {
          state: { data: response.data, originalFile },
        });
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.error("Error fetching template headers:", error);
    } finally {
      setLoading(false);
    }
  };

  const downloadCSVTemplate = async () => {
    await downloadCsvTemplateForInvoice();
    setShowSuccessModal(true)
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
  };

  return (
    <DashboardLayout
      section="Invoice"
      subHeader="Here is an overview of your invoices"
    >
      {showSuccessModal && <InvoiceSuccessModal message="Download Succeessful" onClose={handleCloseSuccessModal} />}
      <header className="bg-alabasterHeader py-[16px] px-[24px]">
        <h1>Upload Invoice</h1>
      </header>

      <div className="px-[24px] pt-[24px] pb-[236px] bg-white border-b border-silver">
        {fileName ? (
          <div className="relative max-w-md bg-[#E4E7EC]">
            <div
              className="absolute top-0 right-0 p-2 cursor-pointer"
              onClick={() => setFileName("")}
            >
              <span className="text-black text-sm font-bold">X</span>
            </div>
            <div style={{ padding: 20 }} className="flex gap-x-[12px]">
              <PAIconDoc />
              <p className="text-sm text-black">{fileName}</p>
            </div>
          </div>
        ) : (
          <div className="h-[300px] bg-[#F8FAFB]  border-[1.5px] p-[25px] border-[#E4E7EC] border-dashed flex justify-center items-center">
            <div>
              <PAIconUndrawAddFiles className="mb-[30px]" />
              <div>
                <p className="text-textColor text-xs">Drag file here to </p>
              </div>
              <span className="text-xs text-textColor">upload or </span>
              <label
                for="fileUpload"
                className="relative cursor-pointer text-xs font-medium  border-b border-secondaryColor text-secondaryColor hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <span>choose file</span>
                <input
                  id="fileUpload"
                  type="file"
                  accept=".csv"
                  onChange={handleFileChange}
                  className="sr-only"
                />
              </label>
            </div>
          </div>
        )}
        {!fileName && (
          <div className="flex justify-between items-center gap-x-[23px] py-[34px]">
            <div className="border-b border-silver w-full"></div>
            <p>OR</p>
            <div className="border-b border-silver w-full"></div>
          </div>
        )}
        {!fileName && (
          <p className="text-black text-xs mb-[12px]">Upload from URL</p>
        )}
        {!fileName && (
          <div className="flex items-center py-[12px] px-[10px] bg-alabasterHeader border border-silver rounded-[5px]">
            <input
              className="w-full h-[30px] outline-none bg-alabasterHeader"
              placeholder="Add file URL"
              type="text"
            />
            <Button className="!text-textColor text-xs py-[8px] px-[20px] border-[.5px] border-silver bg-white rounded-[2.5px]">
              Upload
            </Button>
          </div>
        )}
      </div>

      <div className="flex justify-between gap-x-[16px] py-[32px] px-[17px] bg-white">
        <div>
          <Button
            onClick={downloadCSVTemplate}
            className="!text-black text-xs py-[12px] px-[24px] border-[.5px] border-silver !bg-[#f8f8f8] rounded-[5px] font-medium"
          >
            Download CSV. Template
          </Button>
        </div>
        <div>
          <Button
            onClick={() => navigate(-1)}
            className="!text-black text-xs mr-[10px] py-[12px] px-[24px] border-[.5px] border-silver !bg-[#f8f8f8] rounded-[5px] font-medium"
          >
            Cancel
          </Button>
          <Button
            onClick={verifyHeaders}
            className={`text-xs py-[12px] px-[24px] border-[.5px] border-silver rounded-[5px] font-medium ${
              fileName
                ? "!bg-[#07593D] !text-white"
                : "!bg-[#f8f8f8] !text-black"
            }`}
            disabled={!fileName || loading}
          >
            {loading ? (
              <div className="flex items-center">
                <Spinner className="mr-2" />
                Upload
              </div>
            ) : (
              "Upload"
            )}
          </Button>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default UploadInvoice;
