import React, { useEffect } from "react";
import HeaderTabs from "shared/HeaderTabs";
import Overview from "../Overview/Overview";
import DirectFunding from "../directFunding";
import OpenMarket from "../openMarket";
import EarlyPayments from "../earlyPayment";
import Recievables from "../recievables";
import TransactionHistory from "../transactionHistory";
import EarlyPaymentOffers from "../earlyPaymentOffers";
import { setActiveTab } from "appstate/collections/collectionSlice";
import { useDispatch, useSelector } from "react-redux";

const SupplierCollectionDashboard = () => {
  const dispatch = useDispatch();
  const activeTab = useSelector((state) => state.collection.activeTab);

  useEffect(() => {
    if (!activeTab) {
      dispatch(setActiveTab("overview"));
    }
  }, [dispatch, activeTab]);
  return (
    <HeaderTabs
      tabs={[
        { key: "overview", label: "Overview", content: <Overview /> },
        {
          key: "directFunding",
          label: "Direct Funding",
          content: <DirectFunding />,
        },
        {
          key: "openMarket",
          label: "Open Market",
          content: <OpenMarket />,
        },
        {
          key: "earlyPaymentRequest",
          label: "Early Payment Requests",
          content: <EarlyPayments />,
        },
        {
          key: "earlyPaymentOffers",
          label: "Early Payment Offers",
          content: <EarlyPaymentOffers />,
        },
        {
          key: "Recievables",
          label: "Recievables",
          content: <Recievables />,
        },
        {
          key: "TransactionHistory",
          label: "Transaction History",
          content: <TransactionHistory />,
        },
      ]}
      hasPaddingY={true}
    />
  );
};

export default SupplierCollectionDashboard;
