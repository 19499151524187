import { PAIconCautionRole, PAIconSmallClose } from "assets/images/svgs";
import { motion } from "framer-motion";
import { useState } from "react";
import toast, { LoaderIcon } from "react-hot-toast";
import { useSelector } from "react-redux";

function AddNewUserModal({
  toggleUserModalOff,
  page,
  pageSize,
  setAcceptSendInvite,
  assignedRole,
  setAssignedRole,
  userEmail,
  setUserEmail,
  setId,
}) {
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const { allRolesData, isLoading } = useSelector(
    (state) => state?.roleManager
  );

  const handleSelectChange = (event) => {
    setRole(event.target.value);
  };

  const handleInvite = () => {
    if (role === "" || email === "") {
      return toast.error("Fields cannot be left empty");
    }

    setAssignedRole(role);
    setUserEmail(email);
    setAcceptSendInvite(true);
    toggleUserModalOff();
  };
  return (
    <>
      <motion.div
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
          transition: {
            duration: 0.3,
          },
        }}
        exit={{
          opacity: 0,
          transition: {
            delay: 0.3,
          },
        }}
        onClick={() => toggleUserModalOff()}
        className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-[9999] flex justify-center items-center"
      >
        <motion.div
          onClick={(e) => e.stopPropagation()}
          initial={{
            scale: 0,
          }}
          animate={{
            scale: 1,
            transition: {
              duration: 0.3,
            },
          }}
          exit={{
            scale: 0,
            transition: {
              delay: 0.3,
            },
          }}
          className={`bg-white w-[50%] md:w-[45%] lg:w-[35%] h-[55%] overflow-auto rounded-lg z-[9999]`}
        >
          <motion.div>
            <motion.div
              exit={{
                x: 20,
                opacity: 0,
                transition: {
                  duration: 0.3,
                },
              }}
              className="z-[9999]  h-full flex flex-col justify-start w-full gap-8 pt-[3%] px-[4%] "
            >
              <div className="w-full sticky bg-white top-0 z-[999999] flex px-4 py-4 border-b-[3px] border-[#F6F5FA] justify-between">
                <span className=" font-bold ">
                  <em>Add new user</em>
                </span>
                <span
                  onClick={() => toggleUserModalOff()}
                  className="cursor-pointer gap-2 flex items-center justify-center "
                >
                  <PAIconSmallClose />
                  <span className=" text-[#565656] text-[14px]">Close</span>
                </span>
              </div>

              <div className="h-full overflow-auto">
                <div className="w-full h-4/5 rounded overflow-auto ">
                  <div className="overflow-auto flex flex-col gap-8">
                    <div className=" w-full flex flex-col gap-4 px-4">
                      <div className="flex flex-col gap-2 pb-10">
                        <label className=" text-sm" htmlFor="email">
                          User email
                        </label>
                        <input
                          className=" w-full text-sm text-[#7a7a7a] px-4 py-3 rounded outline-[#7a7a7a] border border-[#7a7a7a] "
                          type="email"
                          name="email"
                          id="email"
                          placeholder="Enter User Mail"
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                          value={email}
                        />

                        <div className="flex gap-1 items-center">
                          <PAIconCautionRole />
                          <span className="text-xs text-[#7A7A7A]">
                            Email address must be in company domain name
                          </span>
                        </div>
                        <label className=" text-sm" htmlFor="assignedRole">
                          Role
                        </label>
                        <select
                          className="overflow-auto w-full text-[#7a7a7a] text-sm px-4 py-3 rounded outline-[#7a7a7a] border border-[#7a7a7a] "
                          name="assignedRole"
                          id="assignedRole"
                          onChange={handleSelectChange}
                          value={role}
                        >
                          <option className="py-3" value="#">
                            Select User Role
                          </option>
                          {allRolesData?.data?.map((role) => (
                            <option
                              className=" py-4"
                              key={role?.roleName}
                              id={role?.id}
                              value={role?.roleName}
                            >
                              {role?.roleName}
                            </option>
                          ))}
                        </select>
                        <div className=" flex pt-6  justify-end">
                          <button
                            disabled={isLoading}
                            onClick={() => {
                              handleInvite();
                            }}
                            className="flex items-center justify-end text-white rounded-[5px] bg-[#07593D] px-6 py-3"
                          >
                            {isLoading ? (
                              <LoaderIcon />
                            ) : (
                              <span className=" px-2 text-sm">Invite</span>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          </motion.div>
        </motion.div>
      </motion.div>
    </>
  );
}

export default AddNewUserModal;
