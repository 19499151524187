import {
  acceptSupplierRequest,
  buyerRejectRequestSupplier,
  getBuyerPendingAssociateRequest,
  // onboardingInviteSupplier,
} from "appstate/buyer/buyerSlice";
import DashboardLayout from "layout/dashboardlayout";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "shared/Button";
import AccountModalContainer from "shared/accountModal/Modal";
import DeclineModal from "shared/delineModal/Modal";
// import InviteModal from "shared/inviteModal";
import BuyerDetailsModal from "../modal";
import Table from "layout/table/Table";
import Loading from "shared/Loading";
import toast from "react-hot-toast";
import Card from "shared/Card";
import { PAIconInventoryArrowLeft, PAIconSearch } from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import { funderPendingInvites } from "appstate/api/buyer/relationshipManagement";
import { debounce } from "lodash";
import { funderOnboardingInviteToBuyers } from "appstate/funder/funderSlice";
import ContinueInviteModal from "shared/inviteModal/ContnueInviteModal";
import FunderInvitationModal from "shared/inviteModal/FunderInvitationModal";
import SuccessModal from "shared/SuccessModal";

const PendingInvitesFunderBuyer = () => {
  const [data, setData] = useState([]);
  const {
    // pendingAssociateRequestData,
    // isLoading,
    declineLoading,
    inviteLoading,
    associateLoading,
  } = useSelector((state) => state.buyer);
  const [getId, setGetId] = useState(null);
  const [openDeclineModal, setOpenDeclineModal] = useState(false);
   // eslint-disable-next-line
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line
  const [opened, setOpened] = useState(false);
  const [success, setSuccess] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  // eslint-disable-next-line
  const [companyName, setCompanyName] = useState("");
  // eslint-disable-next-line
  const [page, setPage] = useState(0);
  // const [startPage, setStartPage] = useState(0);
  // const [endPage, setEndPage] = useState(5);
  const pageSize = 10;
  const [invite, setInvite] = useState([]);
  // const [invitee, setInvitee] = useState([]);
  const [newInvite, setNewInvite] = useState("");
  const [loading, setLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [continueInvite, setContinueInvite] = useState(false);
  const [openmodal, setOpenmodal] = useState(false);

  const supplierDetails = data?.find((cur) => cur?.userId === getId);

  const dispatch = useDispatch();

  const navigate = useNavigate();
  function handleNavigate() {
    navigate(-1);
  }

  const fetchPendingInvites = async (searchTerm) => {
    setLoading(true);
    const sender = "buyer"
    try {
      const result = await funderPendingInvites({
        page,
        pageSize,
        search: searchTerm,
        sender
      });
      setData(result?.data);
    } catch (error) {
      toast.error("Error fetching pending invites");
    } finally {
      setLoading(false);
    }
  };

  // eslint-disable-next-line
  const debouncedFetch = useMemo(
    () => debounce(fetchPendingInvites, 300),
    // eslint-disable-next-line
    [page, pageSize]
    // eslint-disable-next-line
  );

  const handleSearch = (e) => {
    const { value } = e.target;
    setCompanyName(value);
    debouncedFetch(value);
  };

  useEffect(() => {
    fetchPendingInvites(companyName);
    // eslint-disable-next-line
  }, [page, pageSize]);

  const sender = "supplier";

  const handleDecline = () => {
    const notificationId = getId;
    const search = "";

    dispatch(buyerRejectRequestSupplier({ notificationId })).then((data) => {
      if (data?.payload?.success === true) {
        setSuccess(false);
        setOpenDeclineModal(false);
        dispatch(
          getBuyerPendingAssociateRequest({ sender, search, page, pageSize })
        );
      }
    });
  };

  const handleOpenConfirmModal = (row) => {
    setSelectedRow(row);
    setShowConfirmModal(true);
  };

  const handleAssociate = () => {
    const notificationId = getId;
    const search = "";

    dispatch(acceptSupplierRequest(notificationId)).then((data) => {
      if (data?.payload?.success === true) {
        setSuccess(false);
        toast.success(data?.payload?.message);
        dispatch(
          getBuyerPendingAssociateRequest({ sender, search, page, pageSize })
        );
        setOpened(false);
      }
    });
  };
  const column = [
    {
      name: "Email",
      selector: (row) => row?.email,
      width: "15rem",
    },
    {
      name: "Status",
      selector: (row) => (
        <span
          className={`${
            row?.status === "Pending" ? "text-orange-500" : "text-black"
          }`}
        >
          {row?.status}
        </span>
      ),
    },
    {
      cell: (row) => {
        return (
          <div className="flex gap-x-[12px] justify-end">
            <Button
             onClick={() => handleOpenConfirmModal(row)}
              neutral
              className="flex px-[16px] py-[8px]  bg-[#07593D] rounded-[5px] bg-[#07593D]"
            >
              <p className="text-xs font-normal text-white whitespace-nowrap">
                Resend Invite
              </p>
            </Button>
          </div>
        );
      },
      width: "10rem",
    },
  ];

  const onRowClicked = (row) => {
    setOpenDetailsModal(true);
    setGetId(row?.userId);
  };

  const handleResendEmails = () => {
    if (!selectedRow) return;
    const body = [selectedRow.email];
    dispatch(funderOnboardingInviteToBuyers(body)).then((data) => {
      if (data?.payload?.success === true) {
        setShowConfirmModal(false);
        setSuccess(true);
        setInvite([]);
      }
    });
  };

  const handleSubmitEmails = () => {
    const body = invite;
    dispatch(funderOnboardingInviteToBuyers(body)).then((data) => {
      if (data?.payload?.success === true) {
        setSuccess(true);
        setInvite([]);
      }
    });
  };

  const handClose = () => {
    showConfirmModal(false)
  }

  const handleSubContinue = () => {
    setContinueInvite(false);
    setSuccess(true);
  };
  const handleContinueInvite = () => {
    setOpenmodal(false);
    setContinueInvite(true);
  };
  const handleClose = () => {
    setSuccess(false);
    // navigate("/buyer-funders/buyer-pending-funder-request");
  };
  

  return (
    <DashboardLayout
      section="Buyers"
      subHeader="Manage all your associated buyers account"
    >
          <ContinueInviteModal
          isOpen={continueInvite}
          onClose={() => setContinueInvite(false)}
          // isLoading={isLoading}
          handleYes={handleSubContinue}
        />
        <FunderInvitationModal
          onClose={() => setOpenmodal(false)}
          isOpen={openmodal}
          message=" The Funder would received a mail that your are inviting to join
          PayEdge."
          heading="Invite Funder"
          info="You can add one or more funders at once with ease"
          role="Funder"
          invite={invite}
          setInvite={setInvite}
          newInvite={newInvite}
          setNewInvite={setNewInvite}
          success={success}
          setSuccess={setSuccess}
          handleAdd={handleSubmitEmails}
          handleYes={handleContinueInvite}
          isLoading={inviteLoading}
        />

        <SuccessModal
          isOpen={success}
          onClose={handleClose}
          message=" The Buyer would received a mail that your are inviting to join
          PayEdge."
        />
        
      <BuyerDetailsModal
        isShown={openDetailsModal}
        onClose={() => setOpenDetailsModal(false)}
        data={supplierDetails}
      />
      <DeclineModal
        isShown={openDeclineModal}
        onClose={() => setOpenDeclineModal(false)}
        success={success}
        handleAssociate={handleDecline}
        isLoading={declineLoading}
        warning="Are you sure you would like to Decline this request?"
      />
       <AccountModalContainer
          isShown={showConfirmModal}
          onClose={handClose}
          success={success}
          handleAssociate={handleResendEmails}
          // isLoading={associateLoading}
          warning="Are you sure you would like to resend this email to the supplier?"
        />
      <AccountModalContainer
        isShown={opened}
        onClose={() => setOpen(false)}
        success={success}
        handleAssociate={handleAssociate}
        isLoading={associateLoading}
        warning="Are you sure you would like to associate with the supplier?"
      />
      <Card className="p-[24px] rounded-[10px]">
        <header className="bg-alabasterHeader p-[24px] rounded-t-[10px]">
          <div
            onClick={handleNavigate}
            className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
          >
            <PAIconInventoryArrowLeft />
            <p className="mt-[2px] text-sm font-normal text-lightGray">back</p>
          </div>
          <div className="flex justify-between flex-wrap ">
            <div>
              <p className="text-black text-sm font-medium">
                Pending Invites to associate
              </p>
              <p className="text-textColor text-xs font-normal">
                List of invites sent out to buyers
              </p>
            </div>
            <div className="flex gap-x-[10px] mt-[10px] md:mt-0 flex-wrap">
              <div className="flex items-center  bg-white p-[10px] gap-x-[8px] rounded-[5px] border-[0.5px] border-silver xl:w-[350px]">
                <PAIconSearch />
                <input
                  type="text"
                  value={companyName}
                  onChange={handleSearch}
                  placeholder="Search Invites"
                  className="placeholder:text-xs outline-none"
                />
              </div>
              <Button
                neutral
                //   onClick={handleSearch}
                disabled={data < 1}
                className="py-[12px] mt-[10px] md:mt-0 px-[24px] border-[0.5px] border-silver bg-brightGray text-sm !text-black rounded-[5px] font-medium"
              >
                Search
              </Button>
            </div>
          </div>
        </header>
        <Table
          columns={column}
          data={data}
          pointer
          onRowClicked={onRowClicked}
          progressPending={loading}
          progressComponent={<Loading />}
          tableHeader
        />
      </Card>
    </DashboardLayout>
  );
};
export default PendingInvitesFunderBuyer;
