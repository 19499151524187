import { allSingleTransactions } from "appstate/wallet/walletSlice";
import TableHeaderTrans from "features/transactions/components/TableHeaderTrans";
import DashboardLayout from "layout/dashboardlayout";
import { useDispatch, useSelector } from "react-redux";
import Table from "layout/table/Table";
import { useCallback, useEffect, useState } from "react";
import Loading from "shared/Loading";
import { PAIconInventoryArrowLeft } from "assets/images/svgs";
import { useNavigate, useParams } from "react-router-dom";
import TransactionReceipt from "./TransactionReceipt";
import Pagination from "shared/Pagination";

const ViewAllTransactions = () => {
  const dispatch = useDispatch();
  const { singleTransactions, isLoading } = useSelector((state) => state?.wallet);
  // const [selectedTransactionId, setSelectedTransactionId] = useState(null);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const navigate = useNavigate();
  const handleNavigate = () => navigate(-1);
  const { accountId } = useParams();


  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(10);
  const startDate = "";
  const endDate = "";
  const size = 10;

  // const handleSearch = () => {
  //   dispatch(
  //     allTransactions({
  //       page,
  //       size,
  //       startDateTime,
  //       endDateTime,
  //       searchName: searchQuery,
  //     })
  //   );
  // };
  const handleSearch = useCallback(() => {
    dispatch(
      allSingleTransactions({
        accountId,
        page,
        size,
        startDate,
        endDate,
        searchKey: searchQuery,
      })
    );
  }, [dispatch, page, size, startDate, endDate, searchQuery, accountId]);

  useEffect(() => {
    const handler = setTimeout(() => {
      handleSearch();
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery, handleSearch]);

  // useEffect(() => {
  //   const handler = setTimeout(() => {
  //     handleSearch();
  //   }, 300);

  //   return () => {
  //     clearTimeout(handler);
  //   };
  // }, [searchQuery, page, size, startDateTime, endDateTime, dispatch]);

  const formatTransactionDate = (dateString) => {
    const [datePart] = dateString.split(" ");

    const [year, month, day] = datePart.split("-");

    const formattedDate = `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(2, "0")}`;
    return formattedDate;
  };

  const formatAmount = (amount) => {
    if (!amount) return "0.00";
    const parsedAmount = parseFloat(amount);
    if (isNaN(parsedAmount)) return "0.00";
    return parsedAmount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const handleRowClick = (row) => {
    // setSelectedTransactionId(row.transactionId);
    setSelectedTransaction(row);
  };

  const columns = [
    {
      name: "Transaction ID",
      selector: (row) => row.transactionReference,
      sortable: true,
      width: "8.3rem",
    },
    {
      name: "Transaction Party",
      selector: (row) => row.sourceAccountName,
      sortable: true,
      maxWidth: "100%",
    },
    {
      name: "Transaction Type",
      selector: (row) => row.transactionType,
      sortable: true,
      maxWidth: "100%",
    },
    {
      name: "Transaction Date",
      selector: (row) => formatTransactionDate(row.transactionDate),
      sortable: true,
      maxWidth: "100%",
    },
    {
      name: "Sign",
      selector: (row) => row.transactionSign,
      sortable: true,
      cell: (row) =>
        row.transactionSign === "CREDIT" ? (
          <p className="text-success">Cr</p>
        ) : row.transactionSign === "DEBIT" ? (
          <p className="text-red">Dr</p>
        ) : (
          ""
        ),
      width: "7rem",
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
      width: "7rem",
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      cell: (row) =>
        row.transactionSign === "DEBIT" ? (
          <p className="text-red">{formatAmount(row.amount)}</p>
        ) : row.transactionSign === "CREDIT" ? (
          <p className="text-success">{formatAmount(row.amount)}</p>
        ) : (
          ""
        ),
      width: "7.9rem",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) =>
        row.status === "SUCCESSFUL" ? (
          <p className="text-success">{row.status}</p>
        ) : row.status === "FAILED" ? (
          <p className="text-red">{row.status}</p>
        ) : row.status === "PENDING" ? (
          <p className="text-yellow-400">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        ),
      width: "9rem",
    },
  ];

  return (
    <DashboardLayout
      section="Transactions"
      subHeader="Here is an overview of Transactions"
    >
      <div
        onClick={handleNavigate}
        className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
      >
        <PAIconInventoryArrowLeft />
        <p className="mt-[2px] text-sm font-normal text-lightGray">Go Back</p>
      </div>
      <div className="bg-white h-auto rounded-[5px]">
        <TableHeaderTrans
          handleSearch={handleSearch}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          transactions={true}
          className="mt-5"
        />
        <Table
          columns={columns}
          data={singleTransactions?.data}
          onRowClicked={handleRowClick}
          pointer
          className="mt-5 px-4 min-h-[60vh]"
          // pagination
          progressPending={isLoading}
          progressComponent={<Loading />}
        />
        <div className="flex justify-center gap-x-[8px] mt-[20px]">
          {singleTransactions?.data?.length >= 10 && (
            <Pagination
              page={page}
              startPage={startPage}
              setEndPage={setEndPage}
              endPage={endPage}
              setStartPage={setStartPage}
              setPage={setPage}
              totalPages={singleTransactions?.meta?.totalElements}
            />
          )}
        </div>
        {selectedTransaction && (
          <TransactionReceipt
            transferDetails={selectedTransaction}
            onClose={() => setSelectedTransaction(null)}
            paymentAmount={formatAmount(selectedTransaction?.amount)}
            currency={selectedTransaction?.currency}
            senderDetails={{
              name: selectedTransaction?.sourceAccountName,
              bank: "Payedge Virtual Account",
              accountNumber: selectedTransaction?.sourceAccountNumber,
            }}
            beneficiaryDetails={{
              name: selectedTransaction?.destinationAccountName,
              bank: "Payedge Virtual Account",
              accountNumber: selectedTransaction?.destinationAccountNumber,
            }}
            transactionType={selectedTransaction?.transactionType}
            transactionReference={selectedTransaction?.transactionReference}
            description={selectedTransaction?.description}
            paymentDate={formatTransactionDate(
              selectedTransaction?.transactionDate
            )}
          />
        )}
      </div>
    </DashboardLayout>
  );
};

export default ViewAllTransactions;