import React, { useEffect, useState, useRef } from "react";
import {
  PAIconDownload,
  PAIconFilter,
  PAIconOutlineSearch,
  PAIconOption,
} from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";
import Table from "layout/table/Table";
import AcceptModal from "shared/AcceptModal";
import SuccessModal from "shared/SuccessModal";
import { useDispatch, useSelector } from "react-redux";

import Pagination from "shared/Pagination";
import Loading from "shared/Loading";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { allFunderPFP, remainder } from "appstate/collections/collectionSlice";
import { formatCapitaliseString } from "utilities/helper/formatCapitaliseString";

const EarlyPaymentOffer = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const navigate = useNavigate();

  const { allFunderPFPData, isLoading } = useSelector(
    (state) => state?.collection
  );

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen({});
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const dispatch = useDispatch();

  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const pageSize = 10;

  const handleSearch = () => {
    dispatch(allFunderPFP({ search: searchQuery }));
  };

  useEffect(() => {
    dispatch(
      allFunderPFP({
        page,
        pageSize,
        search: searchQuery,
      })
    );
  }, [dispatch, page, pageSize, searchQuery]);

  const handleNotificationClick = (row) => {
    const isSelected = selectedRows.includes(row.id);
    let newSelectedRows = [];

    if (!isSelected) {
      newSelectedRows = [...selectedRows, row.id];
    }

    setSelectedRows(newSelectedRows);
    setIsAcceptModalOpen(true);
  };

  // const handleConfirm = () => {
  //   setIsAcceptModalOpen(false);

  //   if (selectedRows.length > 0) {
  //     setIsSuccessModalOpen(true);
  //     setSuccessMessage("Payment remainders sent successfully");
  //   }
  // };

  const handleConfirm = () => {
    setIsAcceptModalOpen(false);
    // setSelectedInvoice(null);
    const selectedInvoiceIds = selectedRows.map((id) => id);
    dispatch(
      remainder({ type: "earlypaymentoffer", body: selectedInvoiceIds })
    ).then((response) => {
      if (response?.payload?.status === 200 && response?.payload?.success) {
        setIsSuccessModalOpen(true);
        setSuccessMessage(response?.payload?.message);
        setSelectedRows([]);
      }
    });
  };

  const handleCloseSuccessModal = () => {
    setIsSuccessModalOpen(false);
  };

  // Updated to pass both id and pfpId
  const viewDetails = (id, pfpId) => {
    let path = `/collections/fundercollectionofferdetails/${id}/${pfpId || ""}`;
    navigate(path);
  };

  const handleSendReminder = () => {
    if (selectedRows.length > 0) {
      setIsAcceptModalOpen(true);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      setSelectedRows(allFunderPFPData?.data?.map((row) => row.id) || []);
    }
    setSelectAll(!selectAll);
  };

  const handleCheckboxChange = (row) => {
    const isSelected = selectedRows.includes(row.id);

    if (isSelected) {
      setSelectedRows(selectedRows.filter((id) => id !== row.id));
    } else {
      setSelectedRows([...selectedRows, row.id]);
    }
  };

  const columns = [
    {
      name: (
        <input
          type="checkbox"
          onChange={handleSelectAll}
          checked={
            allFunderPFPData?.data?.length > 0 &&
            selectedRows.length === allFunderPFPData?.data?.length
          }
        />
      ),
      cell: (row) => (
        <input
          type="checkbox"
          onChange={() => handleCheckboxChange(row)}
          checked={selectedRows.includes(row.id)}
        />
      ),
      width: "50px",
    },
    {
      name: "Program Name",
      selector: (row) => row.programName,
      sortable: true,
      width: "160px",
    },
    {
      name: <span className="whitespace-normal">Buyer</span>,
      selector: (row) => row.buyerCompanyName,
      sortable: true,
    },
    {
      name: <span className="whitespace-normal">Payment Date</span>,
      selector: (row) => row.paymentDate,
      sortable: true,
      cell: (row) => {
        // Convert the array to a Date object
        const date = new Date(
          row.paymentDate[0],
          row.paymentDate[1] - 1,
          row.paymentDate[2]
        );

        // Format the date as 'YYYY-MM-DD'
        const formattedDate = date.toLocaleDateString("en-CA");

        return <p>{formattedDate}</p>;
      },
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
    },
    {
      name: "Request Amount",
      selector: (row) => row.requestAmount,
      sortable: true,
      cell: (row) => <p className="">{row.requestAmount?.toLocaleString()}</p>,
      width: "160px",
    },
    {
      name: "Discount Rate",
      selector: (row) => row.discountRate,
      sortable: true,
    },
    {
      name: "Discount Amount",
      selector: (row) => row.discountAmount?.toLocaleString(),
      sortable: true,
      width: "160px",
    },
    {
      name: "Funder Share",
      selector: (row) => row.funderShare,
      sortable: true,
      cell: (row) => <p className="">{row.funderPercentage}</p>,
      width: "160px",
    },
    {
      name: "Fundable Amount",
      selector: (row) => row.fundableAmount,
      sortable: true,
      cell: (row) => <p className="">{row.fundableAmount?.toLocaleString()}</p>,
      width: "160px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <p
          className={
            row.status === "Successful" ? "text-green-400 " : "text-[#F08000]"
          }
        >
          {formatCapitaliseString(row.status)}
        </p>
      ),
      width: "160px",
    },
    {
      cell: (row) => (
        <div className="flex gap-2">
          {
            <PAIconOption
              title="Options"
              onClick={() => {
                setIsDropdownOpen((prev) => ({
                  ...prev,
                  [row.id]: !prev[row.id],
                }));
                // Close other dropdowns
                Object.keys(isDropdownOpen).forEach((key) => {
                  if (key !== row.id.toString()) {
                    setIsDropdownOpen((prev) => ({ ...prev, [key]: false }));
                  }
                });
              }}
            />
          }
          {isDropdownOpen[row.id] && (
            <div
              ref={dropdownRef}
              className="absolute bg-white border border-gray300 rounded-md shadow-md z-10"
            >
              <ul>
                <li
                  className="px-4 py-2 cursor-pointer hover:bg-primaryColor hover:text-white"
                  onClick={() => {
                    viewDetails(row?.id, row?.pfpId);
                    setIsDropdownOpen((prev) => ({ ...prev, [row.id]: false }));
                  }}
                >
                  Preview
                </li>
                <li
                  className="px-4 py-2 cursor-pointer hover:bg-primaryColor hover:text-white"
                  onClick={() => {
                    handleNotificationClick(row);
                    setIsDropdownOpen((prev) => ({ ...prev, [row.id]: false }));
                  }}
                >
                  Send Reminder
                </li>
                <li className="px-4 py-2 cursor-not-allowed text-gray300">
                  Export
                </li>
              </ul>
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="my-2 grid md:grid-cols-2 items-center">
        <div className="gap-3 flex relative">
          <div className="flex gap-4 mb-4">
            <div className="relative">
              <DatePicker
                selected={startDate}
                onChange={(update) => setDateRange(update)}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                placeholderText="Filters"
                className="border border-[#ccc] px-4 py-3 cursor-pointer rounded-md w-60"
              />

              <div className="absolute top-2/4 right-3 transform -translate-y-2/4 pointer-events-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5 text-gray-400"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div>
            <button className="py-2 px-4 text-sm rounded-[5px] bg-white border-[#DEDEDE] shadow-sm mr-2 flex items-center justify-center gap-1.5">
              Export {<PAIconDownload className="accent-gray300 w-4" />}
            </button>
          </div>
        </div>

        <div className="flex md:col-span-1 justify-end">
          <div className="flex items-center justify-between gap-5">
            <div className="border border-transparent md:border-gray md:shadow-sm my-4 rounded-[5px] px-2 lg:w-[300px] bg-white">
              <div className="items-center flex bg-white">
                <PAIconOutlineSearch />
                <input
                  className="bg-transparent p-2 w-full focus:outline-none text-sm bg-white"
                  type="text"
                  placeholder="Search for invoices awaiting collection"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleSearch();
                    }
                  }}
                />
              </div>
              <div className="lg:hidden flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer">
                <PAIconFilter className="bg-[#2FA06A]" />
              </div>
            </div>
            <button className="hidden lg:flex bg-[#2FA06A] rounded-md my-4 py-2 px-6 mx3 items-center gap-2 text-white">
              Search
            </button>
          </div>
        </div>
      </div>

      <div>
        {!allFunderPFPData?.data?.length ? (
          <div>
            <div className="flex justify-between items-center px-[15px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
              <p className="text-black text-sm font-medium">
                Invoices from Early Payment Program awaiting collections
              </p>
            </div>
            <TableEmptyState dashboard />
          </div>
        ) : (
          <Card className="rounded-[10px] mt-[15px]">
            <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px] py-10 ">
              <div>
                <p className="text-black text-lg font-bold">
                  Invoices from Early Payment Program awaiting collections
                </p>
                <span className="text-xs font-thin">
                  List of all invoices you have funded from Early Payment
                  Program
                </span>
              </div>
              <div className="flex items-center justify-center gap-3">
                <button
                  className={`border border-gray300 rounded-md p-2 text-white ${
                    selectedRows.length === 0
                      ? "cursor-not-allowed bg-gray300"
                      : "cursor-pointer bg-secondaryColor"
                  }`}
                  disabled={selectedRows.length === 0}
                  onClick={handleSendReminder}
                >
                  <p>Send Reminder Now</p>
                </button>
              </div>
            </div>
            <div className="pb-[24px]">
              <div className="mt-3">
                <Table
                  columns={columns}
                  data={allFunderPFPData?.data || []}
                  onRowClicked={(row) => viewDetails(row.id, row.pfpId)}
                  progressPending={isLoading}
                  progressComponent={<Loading />}
                  pointer
                  tableHeader
                  className="mt-5"
                />
                <div className="flex justify-center gap-x-[8px] mt-[20px]">
                  {allFunderPFPData?.data?.length && (
                    <Pagination
                      page={page}
                      startPage={startPage}
                      setEndPage={setEndPage}
                      endPage={endPage}
                      setStartPage={setStartPage}
                      setPage={setPage}
                      totalPages={allFunderPFPData?.meta?.totalElements}
                    />
                  )}
                </div>
              </div>
            </div>
          </Card>
        )}
      </div>

      <AcceptModal
        isOpen={isAcceptModalOpen}
        onClose={() => setIsAcceptModalOpen(false)}
        yes
        message={
          <>
            Would you like to proceed with sending a <br />
            <span className="text-[#07593D] font-bold">
              Payment reminder
            </span>{" "}
            now?
          </>
        }
        onConfirm={handleConfirm}
      />

      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={handleCloseSuccessModal}
        message={successMessage}
      />
    </>
  );
};

export default EarlyPaymentOffer;
