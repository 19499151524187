import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import LoadingScreen from "shared/LoadingScreen";
import { PAIconArrowLeftGreen, PAIconDownload } from "assets/images/svgs";
import TransitionScale from "shared/animation/TransitionScale";
import {
  disbursementFunderTransactionDetailsById,
  openMarketInvoiceById,
} from "appstate/disbursement/disbursementSlice";
import { useEffect, useState } from "react";
import { formatCapitaliseString } from "utilities/helper/formatCapitaliseString";
import { formatCurrency } from "utilities/helper/formatters";
import { formatCapitalize } from "utilities/formatCapitalize";
import {
  allInvoiceById,
  getDirectFundingDetailsById,
} from "appstate/invoice/invoiceSlice";
import TransitionOpacity from "shared/animation/TransitionOpacity";
import ClosedMarketDetailsRight from "../components/openMarketReceipt/ClosedMarketDetails";
import OpenMarketInvoiceRight from "../components/openMarketReceipt/OpenMarketInvoice";
import {
  fetchAllClosedMarketIngoingBidDetails,
  funderEarlyPaymentViewSingleApprovedDetails,
} from "appstate/funder/funderSlice";
import DirectFundingDetailsRight from "../components/openMarketReceipt/DirectFundingDetails";
import EPRDetailsRight from "../components/openMarketReceipt/EPRDetails";

const Details = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [transTab, setTransTab] = useState(0);
  const fundingChannel = searchParams.get("channel");
  const invoiceId = searchParams.get("invId");
  const offerId = searchParams.get("offerId");

  const { isLoading, disbursementFunderTransactionDetailsByIdData } =
    useSelector((state) => state?.disbursement);

  const { invoiceIdData } = useSelector((state) => state?.invoice);

  useEffect(() => {
    dispatch(allInvoiceById({ id: invoiceId }));
    dispatch(disbursementFunderTransactionDetailsById({ id }));

    if (fundingChannel === "OPEN_MARKET")
      dispatch(openMarketInvoiceById({ id: offerId }));
    if (fundingChannel === "CLOSED_MARKET")
      dispatch(
        fetchAllClosedMarketIngoingBidDetails({ closeMarketId: offerId })
      );
    if (fundingChannel === "DIRECT_FUNDING")
      dispatch(getDirectFundingDetailsById(offerId));

    if (fundingChannel === "EARLY_PAYMENT_OFFER")
      dispatch(funderEarlyPaymentViewSingleApprovedDetails(offerId));

    if (fundingChannel === "DIRECT_FUNDING_EARLY_PAYMENT_REQUEST")
      dispatch(funderEarlyPaymentViewSingleApprovedDetails(offerId));
  }, [dispatch, id, invoiceId, offerId, fundingChannel]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  const detailsData = [
    {
      label: "Transfer from",
      value: formatCapitalize(
        disbursementFunderTransactionDetailsByIdData?.data?.transferFrom
      ),
    },
    {
      label: "Transfer to",
      value: formatCapitalize(
        disbursementFunderTransactionDetailsByIdData?.data?.transferTo
      ),
    },
    {
      label: "Status",
      value: formatCapitalize(
        disbursementFunderTransactionDetailsByIdData?.data?.status
      ),
      statusColor: "text-[#07593D]",
    },
    {
      label: "Payment Date",
      value: disbursementFunderTransactionDetailsByIdData?.data?.paymentDate,
      statusColor: "text-[#000]",
    },
    {
      label: "Transaction ID",
      value: disbursementFunderTransactionDetailsByIdData?.data?.transactionRef,
      statusColor: "text-[#0076EC]",
    },
    {
      label: "Payment Type",
      value: formatCapitaliseString(
        disbursementFunderTransactionDetailsByIdData?.data?.paymentType
      ),
    },
    {
      label: "Virtual account name",
      value: formatCapitalize(
        disbursementFunderTransactionDetailsByIdData?.data?.accountName
      ),
      statusColor: "text-[#07593D]",
    },
    {
      label: "Virtual account no",
      value: `${disbursementFunderTransactionDetailsByIdData?.data?.accountNo?.slice(
        0,
        3
      )}*****${disbursementFunderTransactionDetailsByIdData?.data?.accountNo?.slice(
        7,
        11
      )}`,
      statusColor: "text-[#07593D]",
    },
    {
      label: "Payment Method",
      value: disbursementFunderTransactionDetailsByIdData?.data?.paymentMethod,
      statusColor: "text-[#07593D]",
    },
    {
      label: "Funding Channel",
      value: formatCapitaliseString(
        disbursementFunderTransactionDetailsByIdData?.data?.fundingChannel
      ),
      statusColor: "text-[#F08000]",
    },
    {
      label: "Description from Sender",
      value: formatCapitaliseString(
        disbursementFunderTransactionDetailsByIdData?.data?.description
      ),
    },
  ];

  return (
    <>
      <header className="w-full pt-2 pb-4">
        <span
          onClick={() => navigate(-1)}
          className="flex cursor-pointer items-center font-medium text-sm text-primaryColor gap-2"
        >
          <PAIconArrowLeftGreen /> Go back
        </span>
      </header>{" "}
      <div className="flex flex-col lg:flex-row justify-between gap-6">
        {/* 60% */}
        <TransitionScale className="py-5 px-6 lg:w-[58%] flex flex-col gap-8 min-h-[90vh] bg-white rounded-[10px]">
          <header className="flex flex-col gap-5">
            {fundingChannel === "EARLY_PAYMENT_OFFER" ? (
              <section className="flex justify-between">
                <div className="flex flex-col gap-1 w-[40%]">
                  <section className="flex font-semibold items-center justify-between">
                    Reference No: | {invoiceIdData?.data?.invoiceRef}
                  </section>

                  <section className="flex items-center justify-between">
                    <span className="text-sm font-light">Created Date</span>
                    <span className="text-[#7a7a7a]  text-xs font-light">
                      {invoiceIdData?.data?.createdDate}
                    </span>
                  </section>

                  <section className="flex items-center justify-between">
                    <span className="text-sm font-light">
                      Invoice Due Date:{" "}
                    </span>
                    <span className="text-[#7a7a7a]  text-xs font-light">
                      {invoiceIdData?.data?.dueDate}
                    </span>
                  </section>

                  <section className="flex items-center justify-between">
                    <span className="text-sm font-light">Invoice Amount</span>
                    <span className="text-[#7a7a7a]  text-xs font-light">
                      NGN{invoiceIdData?.data?.invoiceAmount}
                    </span>
                  </section>
                </div>

                <div className="flex flex-col gap-1 w-[40%]">
                  <section className="flex font-semibold items-center justify-between">
                    Payable Finance Offer
                  </section>

                  <section className="flex items-center justify-between">
                    <span className="text-sm font-light">Discount Rate</span>
                    <span className="text-[#7a7a7a]  text-xs font-light">
                      {" "}
                      {invoiceIdData?.data?.discount}%
                    </span>
                  </section>

                  <section className="flex items-center justify-between">
                    <span className="text-sm font-light">Discounted Price</span>
                    <span className="text-[#7a7a7a]  text-xs font-light">
                      NGN {formatCurrency(invoiceIdData?.data?.discountAmount)}
                    </span>
                  </section>

                  <section className="flex items-center justify-between">
                    <span className="text-sm font-light">Payable Amount</span>
                    <span className="text-[#7a7a7a]  text-xs font-light">
                      NGN {invoiceIdData?.data?.createdDate}
                    </span>
                  </section>

                  <section className="flex items-center justify-between">
                    <span className="text-sm font-light">Payment Duration</span>
                    <span className="text-[#7a7a7a]  text-xs font-light">
                      {invoiceIdData?.data?.paymentTerms} Days
                    </span>
                  </section>

                  <section className="flex items-center justify-between">
                    <span className="text-sm font-light">Payment Date</span>
                    <span className="text-[#7a7a7a]  text-xs font-light">
                      20-Jan-2023
                    </span>
                  </section>
                </div>
              </section>
            ) : (
              <section className="flex justify-between">
                <div className="flex flex-col gap-1 w-[40%]">
                  <section className="flex font-semibold items-center justify-between">
                    Reference No: | {invoiceIdData?.data?.invoiceRef}
                  </section>

                  <section className="flex items-center justify-between">
                    <span className="text-sm font-light">Invoice Number</span>
                    <span className="text-[#7a7a7a]  text-xs font-light">
                      {"INV-" + invoiceIdData?.data?.invoiceNo}
                    </span>
                  </section>

                  <section className="flex items-center justify-between">
                    <span className="text-sm font-light">Created Date: </span>
                    <span className="text-[#7a7a7a]  text-xs font-light">
                      {invoiceIdData?.data?.createdDate}
                    </span>
                  </section>

                  <section className="flex items-center justify-between">
                    <span className="text-sm font-light">
                      {fundingChannel === "OPEN_MARKET"
                        ? "Posted"
                        : "Submitted"}{" "}
                      Date:{" "}
                    </span>
                    <span className="text-[#7a7a7a]  text-xs font-light">
                      {fundingChannel === "OPEN_MARKET"
                        ? invoiceIdData?.data?.postedDate ?? "N/A"
                        : invoiceIdData?.data?.submittedDate}
                    </span>
                  </section>

                  <section className="flex items-center justify-between">
                    <span className="text-sm font-light">
                      {fundingChannel === "OPEN_MARKET" ||
                      fundingChannel === "CLOSED_MARKET"
                        ? "Bid Locked"
                        : "Submitted"}{" "}
                      Date:{" "}
                    </span>
                    <span className="text-[#7a7a7a]  text-xs font-light">
                      {fundingChannel === "OPEN_MARKET" ||
                      fundingChannel === "CLOSED_MARKET"
                        ? invoiceIdData?.data?.lockedDate ?? "N/A"
                        : invoiceIdData?.data?.submittedDate}
                    </span>
                  </section>

                  <section className="flex items-center justify-between">
                    <span className="text-sm font-light">Due Date: </span>
                    <span className="text-[#7a7a7a]  text-xs font-light">
                      {invoiceIdData?.data?.dueDate}
                    </span>
                  </section>
                </div>
              </section>
            )}
          </header>

          <section className="flex items-center justify-between">
            <div className="flex flex-col gap-2">
              <span className="text-[18px] font-semibold">
                {formatCapitalize(invoiceIdData?.data?.buyerCompanyName)}
              </span>
              <span className="text-[#7A7A7A] text-xs font-light">
                Buyer Details
              </span>
            </div>

            <div className="flex flex-col gap-2">
              <span className="text-[18px] font-semibold">
                {invoiceIdData?.data?.paymentTerms} Days
              </span>
              <span className="text-[#7A7A7A] text-xs font-light">Payment</span>
            </div>

            <div className="flex flex-col gap-2">
              <span className="text-[18px] flex justify-end font-semibold">
                {invoiceIdData?.data?.currency ?? "NGN"}
              </span>
              <span className="text-[#7A7A7A] text-xs font-light">
                Currency
              </span>
            </div>
          </section>

          <section className="flex flex-col gap-4">
            <header className="flex items-center justify-between pb-3 border-b-[5px] border-[#F6F5FA] text-[#11192A]">
              <span className="text-sm">Line Items</span>

              <span className="text-[18px] font-light">
                {invoiceIdData?.data?.items?.length} Total Items{" "}
              </span>
            </header>

            <div className=" w-full overflow-x-auto mt-3 pb-4">
              <table className=" w-full overflow-x-auto">
                <thead className="bg-subtleGray w-full p-6  flex-nowrap whitespace-nowrap  ">
                  <tr className=" bg-unusualGray  px-6 w-full flex-nowrap whitespace-nowrap ">
                    {/* <td className="text-[#11192A] p-2.5 text-xs font-bold">
                        Item Number
                      </td> */}
                    <td class="text-[#11192a] text-xs font-semibold  p-4  flex-nowrap whitespace-nowrap ">
                      Item{" "}
                    </td>
                    <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                      Description{" "}
                    </td>
                    <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                      Unit Price
                    </td>
                    <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                      Quantity
                    </td>
                    <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                      Amount
                      <div> (before tax)</div>
                    </td>
                    <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                      Tax Rate
                    </td>
                    <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                      Tax Amount
                    </td>
                    <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                      Total Price
                    </td>
                  </tr>
                </thead>

                <tbody className="  ">
                  {invoiceIdData?.data?.items?.map((cell, idx) => {
                    return (
                      <tr key={idx} className=" w-full ">
                        <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap ">
                          {cell.itemName}
                        </td>
                        <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                          {cell.description}
                        </td>
                        <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                          {formatCurrency(cell.unitPrice)}
                        </td>
                        <td className="text-[#11192a] text-xs font-semibold mt-2 text-center flex-nowrap whitespace-nowrap">
                          {cell.quantity}
                        </td>
                        <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                          {formatCurrency(cell.amount)}
                        </td>
                        <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                          {cell.taxRate}
                        </td>
                        <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                          {formatCurrency(cell.taxAmount)}
                        </td>
                        <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                          {formatCurrency(cell.totalPrice)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className=" px-6   py-5 bg-neutral-50 flex-col justify-center items-start  inline-flex bg-[#fafafa] w-full border-t-[0.2px]  border-t-[#b1a6b2]">
              <div className="lg:flex md:flex justify-between  w-full sm:block ">
                {/* First left side */}
                <div>
                  <section>
                    {" "}
                    <div class="text-[#222222] text-lg  font-medium border-b-[0.2px]  border-[#b1a6b2]">
                      Receiving Funding Account Information
                    </div>
                    {/* <div class="text-[#222222] text-lg font-semibold ">
                        Receiving Funding Account Information
                      </div> */}
                    <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                      {invoiceIdData?.data?.virtualAccount?.accountNumber?.slice(
                        0,
                        2
                      )}
                      *******{" "}
                      {invoiceIdData?.data?.virtualAccount?.accountNumber?.slice(
                        7,
                        11
                      )}
                    </div>
                    <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                      {formatCapitalize(
                        invoiceIdData?.data?.virtualAccount?.accountName
                      )}
                    </div>
                  </section>
                </div>

                {/* second division */}

                <div>
                  {" "}
                  <div class=" flex-col justify-start items-start inline-flex lg:min-w-[290px]">
                    <section className=" w-full px-6 pb-2 border-b-[1px] border-black">
                      <div className=" flex w-full justify-between">
                        <div class="text-[#222222] text-xs font-semibold ">
                          Subtotal
                        </div>
                        <div class=" text-[#222222] text-xs font-semibold ">
                          {formatCurrency(invoiceIdData?.data?.subTotal)}
                        </div>
                      </div>
                      <div className=" flex w-full justify-between pt-3 pb-1 border-b-[0.2px] border-b-[#b1a6b2]/30">
                        <div class="text-[#222222]  text-xs font-medium flex  space-x-9 ">
                          <span>VAT%</span>
                          <span>{invoiceIdData?.data?.vat}%</span>
                        </div>
                        <div class="text-[#222222] text-xs font-medium ">
                          {formatCurrency(invoiceIdData?.data?.vatAmount)}
                        </div>
                      </div>

                      <div className=" flex w-full justify-between pt-3 pb-1 border-b-[0.2px] border-b-[#b1a6b2]/30">
                        <div class="text-[#222222]  text-xs font-medium flex  space-x-9 ">
                          <span>Discount%</span>
                          <span>{invoiceIdData?.data?.discount}%</span>
                        </div>
                        <div class="text-[#222222] text-xs font-medium ">
                          {formatCurrency(invoiceIdData?.data?.discountAmount)}
                        </div>
                      </div>
                    </section>
                    <div className=" flex w-full justify-between py-2 px-6 border-b-[1px] border-b-black">
                      <div class="text-[#222222] text-lg font-semibold ">
                        Grand Total
                      </div>
                      <div class="text-[#222222] text-lg font-semibold ">
                        {formatCurrency(invoiceIdData?.data?.grandTotal)}
                      </div>
                    </div>
                  </div>
                </div>

                {/* stops */}
              </div>
            </div>
          </section>
        </TransitionScale>

        {/* 40% */}
        {fundingChannel === "EARLY_PAYMENT_OFFER" ? (
          <TransitionScale className="bg-white w-full lg:w-[40%] rounded-[10px] gap-8 px-6 pt-7 pb-24">
            <TransitionOpacity>
              <div className="flex justify-between items-center mb-4">
                <h2 className="font-semibold text-lg leading-[30px]">
                  {fundingChannel === "EARLY_PAYMENT_OFFER"
                    ? ""
                    : fundingChannel === "DIRECT_FUNDING_EARLY_PAYMENT_REQUEST"
                    ? "Early Payment "
                    : formatCapitaliseString(fundingChannel)}
                  Transaction Details
                </h2>
              </div>

              <div className="flex justify-between my-3 border-b-[3px] border-b-[#F6F5FA] pb-5">
                <div>
                  <h5 className="text-base font-normal">Amount</h5>
                  <h2 className="text-red text-2xl font-normal">
                    {formatCurrency(
                      disbursementFunderTransactionDetailsByIdData?.data?.amount
                    )}{" "}
                    NGN
                  </h2>
                </div>

                <div>
                  <button className="border flex items-center gap-2 text-sm border-gray300 rounded px-3 py-1.5">
                    <span> Export</span>
                    <span>
                      <PAIconDownload />
                    </span>
                  </button>
                </div>
              </div>
              <div className="mt-8">
                <div className="flex flex-col gap-3">
                  {detailsData.map((item, index) => (
                    <div key={index} className="mb-4 flex justify-between">
                      <div>
                        <p className="text-[#222] font-medium text-sm">
                          {item.label}
                        </p>
                      </div>
                      <div className="flex-1 ml-4 max-h-[50px]">
                        <p className="text-[#979797] font-medium text-sm flex justify-end text-right">
                          <span className={item.statusColor}>{item.value}</span>
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </TransitionOpacity>
          </TransitionScale>
        ) : (
          <TransitionScale className="bg-white w-full lg:w-[40%] rounded-[10px] gap-8 px-6 pt-7 pb-24">
            <header className="flex gap-10 pb-5">
              <span
                onClick={() => setTransTab(0)}
                className={`w-fit cursor-pointer pb-4 ${
                  transTab === 0
                    ? "text-primaryColor font-semibold border-b border-primaryColor"
                    : "text-[#959595] "
                }`}
              >
                {fundingChannel === "DIRECT_FUNDING_EARLY_PAYMENT_REQUEST"
                  ? "Early Payment "
                  : formatCapitaliseString(fundingChannel)}{" "}
                Details
              </span>
              <span
                onClick={() => setTransTab(1)}
                className={`w-fit cursor-pointer pb-2 ${
                  transTab === 1
                    ? "text-primaryColor font-semibold border-b border-primaryColor"
                    : "text-[#959595]"
                }`}
              >
                Transaction Details
              </span>
            </header>
            {/* Ta */}
            {transTab === 0 ? (
              <>
                {fundingChannel === "CLOSED_MARKET" ? (
                  <ClosedMarketDetailsRight id={offerId} />
                ) : fundingChannel === "OPEN_MARKET" ? (
                  <OpenMarketInvoiceRight id={offerId} />
                ) : fundingChannel === "DIRECT_FUNDING" ? (
                  <DirectFundingDetailsRight />
                ) : (
                  <EPRDetailsRight />
                )}
              </>
            ) : (
              <TransitionOpacity>
                <div className="flex justify-between items-center mb-4">
                  <h2 className="font-semibold text-lg leading-[30px]">
                    {fundingChannel === "EARLY_PAYMENT_OFFER"
                      ? ""
                      : fundingChannel ===
                        "DIRECT_FUNDING_EARLY_PAYMENT_REQUEST"
                      ? "Early Payment "
                      : formatCapitaliseString(fundingChannel)}
                    Transaction Details
                  </h2>
                </div>

                <div className="flex justify-between my-3 border-b-[3px] border-b-[#F6F5FA] pb-5">
                  <div>
                    <h5 className="text-base font-normal">Amount</h5>
                    <h2 className="text-red text-2xl font-normal">
                      {formatCurrency(
                        disbursementFunderTransactionDetailsByIdData?.data
                          ?.amount
                      )}{" "}
                      NGN
                    </h2>
                  </div>

                  <div>
                    <button className="border flex items-center gap-2 text-sm border-gray300 rounded px-3 py-1.5">
                      <span> Export</span>
                      <span>
                        <PAIconDownload />
                      </span>
                    </button>
                  </div>
                </div>
                <div className="mt-8">
                  <div className="flex flex-col gap-3">
                    {detailsData.map((item, index) => (
                      <div key={index} className="mb-4 flex justify-between">
                        <div>
                          <p className="text-[#222] font-medium text-sm">
                            {item.label}
                          </p>
                        </div>
                        <div className="flex-1 ml-4 max-h-[50px]">
                          <p className="text-[#979797] font-medium text-sm flex justify-end text-right">
                            <span className={item.statusColor}>
                              {item.value}
                            </span>
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </TransitionOpacity>
            )}
          </TransitionScale>
        )}
      </div>
    </>
  );
};

export default Details;
