import {
  PAIconCocacola,
  PAIconInvShop,
  PAIconSmallDangote,
  PAIconSmallNestle,
  PAIconSmallShell,
  PAIconSmallTrust,
  PAIconSmallBrewIcon,
  PAIconLockIcon,
} from "assets/images/svgs";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

function ClosedMarketCard({
  id,
  invoiceNumber = "N/A",
  buyerName = "N/A",
  invoiceType = "N/A",
  lockRate = "N/A",
  statusOfInvoice = "N/A",
  requestAmount = "N/A",
  dueDate = "N/A",
  supplierName = "N/A",
  earlyPaymentDate,
  onBid,
}) {
  const navigate = useNavigate();

  const handleOnbid = (event) => {
    event.stopPropagation();
    onBid();
  };

  //funderclosemarketposteddetails

  return (
    <motion.div
      //
      onClick={() => {
        // invoiceType === "Single request"
        //   ? navigate(
        //       `/payablefinancing-closedmarket/funderclosemarketdetails/${id}`
        //     )
        //   : navigate(
        //       `/payablefinancing-closedmarket/funderclosemarketbulkdetails/${id}`
        // bulkclosedmarketposteddetails;
        //     );
        if (invoiceType === "Single request" && statusOfInvoice === "Posted") {
          navigate(
            `/payablefinancing-closedmarket/funderclosemarketposteddetails/${id}`
          );
        } else if (
          invoiceType === "Single request" &&
          statusOfInvoice !== "Posted"
        ) {
          navigate(
            `/payablefinancing-closedmarket/funderclosemarketdetails/${id}`
          );
        } else if (
          invoiceType === "Bulk request" &&
          statusOfInvoice !== "Posted"
        ) {
          navigate(
            `/payablefinancing-closedmarket/funderclosemarketbulkdetails/${id}`
          );
        } else if (
          invoiceType === "Bulk request" &&
          statusOfInvoice === "Posted"
        ) {
          navigate(
            `/payablefinancing-closedmarket/bulkclosedmarketposteddetails/${id}`
          );
        }
      }}
      whileTap={{ scale: 0.98 }}
      style={{ boxShadow: "0px 2px 4px 0px rgba(232, 226, 216, 0.36)" }}
      className="flex  cursor-pointer  bg-white  rounded-[10px] sm:w-full md:w-full gap-6    "
    >
      {/* first layer */}

      {/* flex cursor-pointer justify-between bg-white py-5 px-6 rounded-[10px] w-full sm:w-full md:w-full gap-6 */}

      <div className="flex flex-col cursor-pointer  bg-white py-5 px-6 rounded-[10px] sm:w-full md:w-full gap-6 ">
        <div className=" flex cursor-pointer justify-between bg-white   rounded-[10px] w-full sm:w-full md:w-full gap-6">
          <span className="flex gap-6 items-center flex-nowrap whitespace-nowrap">
            <PAIconInvShop />
            <div className="flex flex-col justify-center">
              <span className="font-[500] text-sm">{invoiceNumber}</span>
              <span className=" text-[#959595] text-[10px]">
                {"Reference number"}
              </span>
            </div>
          </span>
          {/* Button segment */}
          <span className="flex  items-center flex-nowrap whitespace-nowrap">
            <button
              className={`h-[37px] p-2.5 rounded-[5px] inline-flex ${
                invoiceType === "Single request"
                  ? " bg-[#f08000]/10"
                  : "bg-[#07593d]/10"
              }`}
            >
              {/* <div class="h-[37px] p-2.5 bg-[#07593d]/10 rounded-[5px] border flex-col justify-start items-start gap-0.5 inline-flex">
              <div class="text-black text-sm font-semibold font-['SF Pro Display']">
                Bulk request
              </div>
            </div> */}
              <div className="text-black text-sm font-medium ">
                {invoiceType}
              </div>
            </button>
          </span>

          {/* button stops here */}
          <span className="flex gap-6 items-center flex-nowrap whitespace-nowrap ">
            {buyerName === "Coca cola" ? (
              <PAIconCocacola />
            ) : buyerName === "Dangote" ? (
              <PAIconSmallDangote />
            ) : buyerName === "Nestle" ? (
              <PAIconSmallNestle />
            ) : buyerName === "Defi Ltd." ? (
              <PAIconSmallShell />
            ) : buyerName === "Tera" ? (
              <PAIconSmallShell />
            ) : buyerName === "Tacna Plc" ? (
              <PAIconSmallTrust />
            ) : (
              <PAIconSmallBrewIcon />
            )}{" "}
            <div className="flex flex-col justify-center">
              <span className="font-[500] text-sm">{buyerName}</span>
              <span className=" text-[#959595] text-[10px]">Buyer</span>
            </div>
          </span>

          <span className="flex gap-6 items-center flex-nowrap whitespace-nowrap">
            {supplierName === "Coca Cola" && id !== "3" && id !== "5" ? (
              <PAIconCocacola />
            ) : supplierName === "Coca Cola" && id === "3" ? (
              <PAIconSmallTrust />
            ) : supplierName === "Coca Cola" && id !== "5" && id !== "3" ? (
              <PAIconCocacola />
            ) : supplierName === "Coca Cola" && id === "5" ? (
              <PAIconSmallTrust />
            ) : supplierName === "Dangote" ? (
              <PAIconSmallDangote />
            ) : supplierName === "Nestle" ? (
              <PAIconSmallNestle />
            ) : supplierName === "Defi" ? (
              <PAIconSmallShell />
            ) : supplierName === "ABC Limited" ? (
              <PAIconSmallTrust />
            ) : (
              <PAIconSmallBrewIcon />
            )}{" "}
            <div className="flex flex-col justify-center">
              <span className="font-[500] text-sm">{supplierName}</span>
              <span className=" text-[#959595] text-[10px]">Supplier</span>
            </div>
          </span>

          <span className="flex gap-6 flex-nowrap whitespace-nowrap ">
            <div className="flex flex-col justify-center">
              <span
                className={`font-[500]  text-sm ${
                  statusOfInvoice === "Bid Active"
                    ? "text-[#f08000]"
                    : "text-[#07593d]"
                }`}
              >
                {statusOfInvoice}
              </span>
              <span className=" text-[#959595] text-[10px]">
                Status of invoice
              </span>
            </div>
          </span>

          {/* side end button */}

          <button
            class=" p-2.5 bg-white rounded-[5px] border border-[#07593d] justify-center items-center gap-2 inline-flex"
            onClick={handleOnbid}
          >
            <div className=" border-r-[1.5px] flex gap-3  pr-2">
              {" "}
              <div class="text-[#07593d] text-sm font-medium items-center ">
                Bid
              </div>
              <div class="justify-center items-center flex">
                <div class=" relative">
                  <PAIconLockIcon />
                </div>
              </div>
            </div>
            <div class="justify-start items-center gap-2 flex">
              {/* <div class="w-[2px] h-full border border-[#222222]"></div> */}
              <div class="text-[#222222] text-sm font-normal ">00h:56m:23s</div>
            </div>
          </button>
        </div>
        {/* side end button ends here */}
        {/* second layer */}
        <div className=" flex cursor-pointer justify-between bg-white   rounded-[10px] w-full sm:w-full md:w-full gap-6">
          <span className="flex gap-3  items-center flex-nowrap whitespace-nowrap">
            <div className="text-black text-sm font-medium ">Lock rate</div>
            <div className="text-[#949494] text-sm font-medium ">
              {lockRate}
            </div>
          </span>

          <span className="flex gap-3  items-center flex-nowrap whitespace-nowrap">
            <div className="text-black text-sm font-medium ">
              Request Amount
            </div>
            <div className="text-[#949494] text-sm font-medium ">
              {requestAmount}
            </div>
          </span>
          <span className="flex gap-3  items-center flex-nowrap whitespace-nowrap">
            <div className="text-black text-sm font-medium ">
              Early Payment Date
            </div>
            <div className="text-[#949494] text-sm font-medium ">
              {earlyPaymentDate}
            </div>
          </span>
          <span className="flex gap-6 flex-nowrap whitespace-nowrap">
            <div className="text-black text-sm font-medium ">Due Date</div>
            <div className="text-[#949494] text-sm font-medium ">{dueDate}</div>
          </span>
        </div>
      </div>
    </motion.div>
  );
}

export default ClosedMarketCard;
