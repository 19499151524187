import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import LoadingScreen from "shared/LoadingScreen";
import { allInvoiceById } from "appstate/invoice/invoiceSlice";
import InvoiceDetails from "./InvoiceDetails";
import { PAIconArrowLeftGreen } from "assets/images/svgs";
import { motion } from "framer-motion";

const Details = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { invoiceIdData, isLoading } = useSelector((state) => state?.invoice);
  const [activeTab, setActiveTab] = useState("transaction");

  useEffect(() => {
    dispatch(allInvoiceById({ id }));
  }, [dispatch, id]);

  // Calculate the payment based on the due date and created date
  const calculatePayment = (dueDate, createdDate) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const dueDateObj = new Date(dueDate);
    const createdDateObj = new Date(createdDate?.split(" ")[0]);
    const diffDays = Math.round(
      Math.abs((dueDateObj - createdDateObj) / oneDay)
    );
    return `${diffDays} Days`;
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  const detailsData = [
    {
      label: "Transfer from",
      value: "Elo",
    },
    {
      label: "Transfer to",
      value: "Wale",
    },
    {
      label: "Status",
      value: "Not Paid",
      statusColor: "text-[#07593D]",
    },
    {
      label: "Payment Date",
      value: "Oct 21, 2024 | 02:43PM",
      statusColor: "text-[#000]",
    },
    {
      label: "Transaction ID",
      value: "DbuibYYBH878huhi8UUI89ygt2132h",
      statusColor: "text-[#0076EC]",
    },
    // {
    //   label: "Collection Type",
    //   value: "Payment",
    // },
    {
      label: "Collection Method",
      value: "PayEdge Wallet",
      statusColor: "text-[#07593D]",
    },
    {
      label: "Funding Channel",
      value: "Open Market",
      statusColor: "text-[#F08000]",
    },
    {
      label: "Description from Sender",
      value: "Payment sent. Appreciate our ongoing collaboration. Regards,",
    },
  ];

  const data = {
    invoiceNumber: `INV-${invoiceIdData?.data?.invoiceNumber}` || "",
    referenceNumber: invoiceIdData?.data?.invoiceReference || "",
    createdDate: invoiceIdData?.data?.createdDate?.slice(0, 10) || "",
    dueDate: invoiceIdData?.data?.dueDate || "",
    payment: calculatePayment(
      invoiceIdData?.data?.dueDate,
      invoiceIdData?.data?.createdDate
    ),
    currency: invoiceIdData?.data?.currency || "",
    supplier: invoiceIdData?.data?.supplierCompanyName || "",
    buyer: invoiceIdData?.data?.buyerCompanyName || "",
    lineItems:
      invoiceIdData?.data?.items?.map((item) => ({
        itemNumber: item?.itemNumber || "",
        poNumber: item.poNumber || "",
        productNumber: item.productNumber || "",
        productDescription: item.productDescription || "",
        quantity: item.quantity || 0,
        unitPrice: item.unitPrice || "",
        extendedAmount: item.extendedAmount || "",
      })) || [],
    attachments: [
      {
        name: "DOC-29384.pdf",
        fileType: "PDF",
        uploadDateTime: "2021-09-14 13:25",
        downloadUrl: "https://example.com/doc-29384.pdf",
      },
    ],
  };

  return (
    <>
      {/* HEADER */}
      <header className="rounded-md w-full bg-white mb-4 flex items-center justify-between px-5">
        <Link to="/collections" className="flex items-center gap-1 py-3">
          <PAIconArrowLeftGreen />
          <span className="text-primaryColor cursor-pointer">Go Back</span>
        </Link>
      </header>
      <div className="grid lg:grid-cols-[60%,40%] space-x-5">
        {/* 60% */}
        <InvoiceDetails data={data} showSendReminderButton={false} />
        {/* 40% */}
        <div className="bg-white rounded-[10px] gap-8 px-6 pt7 pb-24">
          <div>
            <div className="flex gap-10 my-5">
              <h2
                onClick={() => setActiveTab("channel")}
                className={`cursor-pointer text-gray ${
                  activeTab === "channel"
                    ? "text-primaryColor border-b-2 border-primaryColor"
                    : ""
                }`}
              >
                Open Market Details
              </h2>
              <h2
                onClick={() => setActiveTab("transaction")}
                className={`cursor-pointer text-gray ${
                  activeTab === "transaction"
                    ? "text-primaryColor border-b-2 border-primaryColor"
                    : ""
                }`}
              >
                Transaction Details
              </h2>
            </div>
            {/* Render content based on active tab */}
            {activeTab === "transaction" && (
              <>
                <div className="flex justify-between items-center mb-4">
                  <h2 className="fontsemibold text-lg leading-[30px]">
                    Collection Transaction Details
                  </h2>
                  <button className="border border-gray300 rounded px-3 py-1.5">
                    Export
                  </button>
                </div>

                <div className="flex justify-between my-3 border-b-[3px] border-b-[#F6F5FA] pb-5">
                  <div>
                    <h5 className="text-base font-normal">Amount</h5>
                    <h2 className="text-[#07593D] text-2xl font-normal">
                      +9,000,000 NGN
                    </h2>
                  </div>
                </div>
                <div className="mt-8">
                  <div className="flex flex-col gap-3">
                    {detailsData.map((item, index) => (
                      <div key={index} className="mb-4 flex justify-between">
                        <div>
                          <p className="text-[#222] font-medium text-sm">
                            {item.label}
                          </p>
                        </div>
                        <div className="flex-1 ml-4 max-h-[50px]">
                          <p className="text-[#979797] font-medium text-sm flex justify-end text-right">
                            <span className={item.statusColor}>
                              {item.value}
                            </span>
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}

            {activeTab === "channel" && (
              <motion.section
                initial={{
                  y: -20,
                }}
                animate={{
                  y: 0,
                }}
                exit={{
                  y: 20,
                }}
                transition={{
                  delay: 0.8,
                }}
                className="flex flex-col gap-3    w-full bg-white rounded-[10px]"
              >
                {/* <header className="flex  px-6">
                  <span className="font-bold">Open Market</span>
                </header> */}

                <div>
                  {" "}
                  <div className="   pb-4">
                    <div class="w-full justify-start items-center gap-4 inline-flex "></div>
                    <div class=" justify-between  flex flex-row      ">
                      <div className="flex gap-2 py-2.5">
                        <div className="flex flex-col">
                          {/* <span className=" text-black text-xs font-normal">
        Coca Cola
      </span> */}
                          <span class="text-black text-sm font-normal ">
                            {"Nestle PLC"}
                          </span>
                          {/* <span className="text-[#979797]  text-xs">Supplier</span> */}
                          <span class="text-[#949494] text-sm font-normal ">
                            Buyer
                          </span>
                        </div>
                      </div>
                      <div class=" flex-col justify-start items-start inline-flex">
                        {" "}
                        <div className="flex gap-2 py-2.5">
                          <div className="flex flex-col">
                            {/* <span className=" text-black text-xs font-normal">
        Coca Cola
      </span> */}
                            <span class="text-black text-sm font-normal ">
                              {"Dangote"}
                            </span>
                            {/* <span className="text-[#979797]  text-xs">Supplier</span> */}
                            <span class="text-[#949494] text-sm font-normal ">
                              Supplier
                            </span>
                          </div>
                        </div>
                      </div>

                      {/* here */}
                    </div>
                    <div class=" justify-between  flex flex-row         mt-2">
                      <div class="py-2.5 flex-col justify-start items-start inline-flex">
                        <div class="flex-col justify-start items-start gap-0.5 flex">
                          <div class="text-black text-sm font-normal ">
                            N90,000{" "}
                          </div>
                        </div>
                        <div class="flex-col justify-start items-start  flex">
                          <div class="text-[#949494] text-sm font-normal ">
                            Lock Rate Amount
                          </div>
                        </div>
                      </div>
                      {/* dhdh */}
                      <div class="py-2.5 flex-col justify-start items-start inline-flex">
                        <div class="flex-col justify-start items-start gap-0.5 flex">
                          <div class="text-black text-sm font-normal ">
                            {"%15)"}
                            {/* {details?.earlyPaymentDate && details?.earlyPaymentDate} */}
                          </div>
                        </div>
                        <div class="flex-col justify-start items-start  flex">
                          <div class="text-[#949494] text-sm font-normal ">
                            Lock Rate
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class=" justify-between  flex flex-row        mt-2">
                      <div class="py-2.5 flex-col justify-start items-start inline-flex">
                        <div class="flex-col justify-start items-start gap-0.5 flex">
                          <div class="text-black text-sm font-normal ">
                            {"23-Dec-2024 "}
                          </div>
                        </div>
                        <div class="flex-col justify-start items-start  flex">
                          <div class="text-[#949494] text-sm font-normal ">
                            Payment Date
                          </div>
                        </div>
                      </div>
                      {/* dhdh */}
                      <div class="py-2.5 flex-col justify-start items-start inline-flex"></div>
                    </div>
                    <div class=" justify-between  flex flex-row    mt-2 ">
                      <div class="py-2.5 flex-col justify-start items-start inline-flex">
                        <div class="flex-col justify-start items-start gap-0.5 flex">
                          <div class="text-black text-sm font-normal ">
                            N23,090,000
                          </div>
                        </div>
                        <div class="flex-col justify-start items-start  flex">
                          <div class="text-[#949494] text-sm font-normal ">
                            Request Amount
                          </div>
                        </div>
                      </div>
                      {/* hd */}
                      <div class="py-2.5 flex-col justify-start items-start inline-flex">
                        <div class="flex-col justify-start items-start gap-0.5 flex">
                          <div class="text-black text-base font-extrabold ">
                            N22,650,650
                          </div>
                        </div>
                        <div class="flex-col justify-start items-start  flex">
                          <div class="text-[#949494] text-sm font-normal ">
                            Receivable Amount
                          </div>
                        </div>
                      </div>{" "}
                    </div>

                    {/* 2nd */}
                    {/* another header */}
                    {/* header ends here */}
                    {/* here */}
                  </div>
                </div>
              </motion.section>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Details;
