import { PAIconArrowLeft, PAIconEye, PAIconVieweye } from "assets/images/svgs";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Button from "shared/Button";
import InputField from "shared/InputField";
import PasswordLength from "shared/PasswordLength";
import PhoneNumber from "shared/PhoneNumber";
import Spinner from "shared/Spinner/Spinner";
import OnboardingLayout from "../category/components/OnboardingLayout";
import { staffValidationSchema } from "validation/staff/StaffValidationSchema";
import {
  staffProfileById,
  updateStaffToOnboard,
  viewStaffAgreement,
} from "appstate/roleManager/roleManagerSlice";
import { useKeycloak } from "@react-keycloak/web";
import toast from "react-hot-toast";

const StaffPersonalDetails = () => {
  // eslint-disable-next-line
  const { player } = useParams();
  const { keycloak } = useKeycloak();
  const [, setIsFocused] = useState(false);
  const { staffProfileByIdData } = useSelector((state) => state.roleManager);
  const [checked, setChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmedPassword, setShowConfirmedPassword] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState("+234");
  const [showModal] = useState(false);
  const { isLoading } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      invitedRole: staffProfileByIdData?.data?.role || "",
      email: staffProfileByIdData?.data?.email || "",
      fullName: "",
      phoneNumber: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: staffValidationSchema(selectedCountryCode),
    onSubmit: (values) => {
      const phoneNumberWithCountryCode = `${selectedCountryCode}${values.phoneNumber}`;
      const body = {
        fullName: values.fullName,
        phoneNumber: phoneNumberWithCountryCode,
        oldPassword: "12345678",
        newPassword: values.password,
      };
      const pendingToast = toast.loading("Updating your profile...");
      dispatch(updateStaffToOnboard({ player, body })).then((data) => {
        if (data?.payload?.success) {
          dispatch(viewStaffAgreement({ page: 0, size: 10 }));
          toast.dismiss(pendingToast);
          toast.success("Personal details Updated successfully");
          navigate(`/onboarding/${player}/staff/terms&conditions`);
        } else {
          return toast.dismiss(pendingToast);
        }
      });
    },
  });

  useEffect(() => {
    if (keycloak?.tokenParsed?.sub) {
      dispatch(
        staffProfileById({
          player,
          staffId: keycloak?.tokenParsed?.sub,
        })
      );
    }
  }, [dispatch, keycloak?.tokenParsed?.sub, player]);

  const handleChange = (e) => {
    setChecked(e.target.checked);
  };

  const calculateStrength = (value) => {
    let strength = 0;
    const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*()])(?=.*[A-Z]).{6,}$/;

    if (/\d/.test(value)) {
      strength++;
    }
    if (/[!@#$%^&*]/.test(value)) {
      strength++;
    }
    if (/[A-Z]/.test(value)) {
      strength++;
    }
    if (value?.length >= 6) {
      strength++;
    }

    if (passwordRegex.test(value)) {
      strength++;
    }

    return strength;
  };

  const handlePhoneCountryChange = (selectedCountryCode) => {
    setSelectedCountryCode(selectedCountryCode);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmedPassword(!showConfirmedPassword);
  };

  const goback = () => {
    navigate(-1);
  };

  return (
    <OnboardingLayout nohelp>
      <PAIconArrowLeft className="cursor-pointer mx-4  my-4" onClick={goback} />
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>
      )}

      <div className="mt-[30px] flex justify-center items-center">
        <div>
          <p className="font-normal text-xs text-textColor mb-[10px] md:text-sm">
            Personal Details
          </p>
          <div className="w-[100px] h-[2px] bg-[#F08000] rounded-[100px] md:w-[220px]"></div>
        </div>
        {/* <div className="ml-[15px] mt-[28px]">
          <div className="w-[100px] h-[2px] bg-[#D9D9D9] rounded-[100px] md:w-[220px]"></div>
        </div> */}
        <div className="ml-[15px] mt-[28px]">
          <div className="w-[100px] h-[2px] bg-[#D9D9D9] rounded-[100px] md:w-[220px]"></div>
        </div>
        <div className="ml-[15px] mt-[28px]">
          <div className="w-[100px] h-[2px] bg-[#D9D9D9] rounded-[100px] md:w-[220px]"></div>
        </div>
      </div>

      <div className="pt-[60px] flex items-center flex-col h-[100%] overflow-auto ">
        <h1 className="font-semibold text-[24px] text-black text-center mb-[10px]">
          Please enter and confirm your personal details
        </h1>
        <p className="font-light text-[15px] text-semibold text-[#7A7A7A] text-center  mb-[20px]">
          Please enter your personal details.
        </p>
        <form
          onSubmit={formik.handleSubmit}
          className="flex justify-center flex-col"
        >
          <div className="mb-[30px]">
            <InputField
              className="w-[300px] py-[13px] outline-none md:w-[424px]"
              placeholder="Role"
              label="Invited Role"
              name="invitedRole"
              id="invitedRole"
              type="text"
              disabled
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.invitedRole}
            />
            {formik.touched.invitedRole && formik.errors.invitedRole ? (
              <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.invitedRole}
              </p>
            ) : null}
          </div>

          <div className="mb-[30px]">
            <InputField
              className="w-[300px] py-[13px] outline-none md:w-[424px]"
              placeholder="Email"
              label="Email Address"
              name="email"
              id="email"
              type="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.email}
              disabled
            />
            {formik.touched.email && formik.errors.email ? (
              <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.email}
              </p>
            ) : null}
          </div>

          <div className="mb-[30px]">
            <InputField
              className="w-[300px] py-[13px] outline-none md:w-[424px]"
              placeholder="John Doe"
              label="Full Name"
              name="fullName"
              id="fullName"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.fullName}
            />
            {formik.touched.fullName && formik.errors.fullName ? (
              <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.fullName}
              </p>
            ) : null}
          </div>

          <div className="mb-[30px]">
            <PhoneNumber
              name="phoneNumber"
              id="phoneNumber"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phoneNumber}
              onCountryCodeChange={handlePhoneCountryChange}
            />
            {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
              <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.phoneNumber}
              </p>
            ) : null}
          </div>

          <div className="mb-[30px]">
            <InputField
              className="w-[300px] py-[13px] outline-none  md:w-[424px]"
              placeholder="........"
              label="Password"
              name="password"
              id="password"
              type={showPassword ? "text" : "password"}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              onChange={formik.handleChange}
              value={formik.values.oldPassword}
              autoComplete="new-password"
            />
            {showPassword ? (
              <PAIconVieweye
                onClick={toggleShowPassword}
                className="float-right mr-[6px] mt-[-30px] relative z-2 cursor-pointer"
              />
            ) : (
              <PAIconEye
                onClick={toggleShowPassword}
                className="float-right mr-[6px] mt-[-30px] relative z-2 cursor-pointer"
              />
            )}

            {formik.touched.password && formik.errors.password ? (
              <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.password}
              </p>
            ) : null}
            <div className="flex justify-between mt-[10px]">
              <PasswordLength
                className={`strength-bar } ${
                  calculateStrength(formik.values.password) === 1
                    ? "bg-red"
                    : calculateStrength(formik.values.password) === 2
                    ? "bg-yellow-500"
                    : calculateStrength(formik.values.password) === 3
                    ? "bg-yellow-500"
                    : calculateStrength(formik.values.password) >= 4
                    ? "bg-green-500"
                    : "bg-red-500"
                }`}
              />
              <PasswordLength
                className={`strength-bar } ${
                  calculateStrength(formik.values.password) === 2
                    ? "bg-yellow-500"
                    : calculateStrength(formik.values.password) === 3
                    ? "bg-yellow-500"
                    : calculateStrength(formik.values.password) >= 4
                    ? "bg-green-500"
                    : ""
                }`}
              />
              <PasswordLength
                className={`strength-bar ${
                  calculateStrength(formik.values.password) === 3
                    ? "bg-yellow-500"
                    : calculateStrength(formik.values.password) >= 4
                    ? "bg-green-500"
                    : ""
                }`}
              />
              <PasswordLength
                className={`strength-bar  ${
                  calculateStrength(formik.values.password) >= 4
                    ? "bg-green-500"
                    : ""
                }`}
              />
            </div>
          </div>
          <div className="mb-[30px]">
            <InputField
              className="w-[300px] py-[13px] outline-none md:w-[424px]"
              placeholder="........"
              label="Confirm Password"
              name="confirmPassword"
              type={showConfirmedPassword ? "text" : "password"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confirmPassword}
            />
            {showConfirmedPassword ? (
              <PAIconVieweye
                onClick={toggleShowConfirmPassword}
                className="float-right mr-[6px] mt-[-30px] relative z-2 cursor-pointer"
              />
            ) : (
              <PAIconEye
                onClick={toggleShowConfirmPassword}
                className="float-right mr-[6px] mt-[-30px] relative z-2 cursor-pointer"
              />
            )}
            <div className="flex justify-between mt-[10px]">
              <PasswordLength
                className={`strength-bar } ${
                  calculateStrength(formik.values.confirmPassword) === 1
                    ? "bg-red"
                    : calculateStrength(formik.values.confirmPassword) === 2
                    ? "bg-yellow-500"
                    : calculateStrength(formik.values.confirmPassword) === 3
                    ? "bg-yellow-500"
                    : calculateStrength(formik.values.confirmPassword) >= 4
                    ? "bg-green-500"
                    : "bg-red-500"
                }`}
              />
              <PasswordLength
                className={`strength-bar } ${
                  calculateStrength(formik.values.confirmPassword) === 2
                    ? "bg-yellow-500"
                    : calculateStrength(formik.values.confirmPassword) === 3
                    ? "bg-yellow-500"
                    : calculateStrength(formik.values.confirmPassword) >= 4
                    ? "bg-green-500"
                    : ""
                }`}
              />
              <PasswordLength
                className={`strength-bar ${
                  calculateStrength(formik.values.confirmPassword) === 3
                    ? "bg-yellow-500"
                    : calculateStrength(formik.values.confirmPassword) >= 4
                    ? "bg-green-500"
                    : ""
                }`}
              />
              <PasswordLength
                className={`strength-bar  ${
                  calculateStrength(formik.values.confirmPassword) >= 4
                    ? "bg-green-500"
                    : ""
                }`}
              />
            </div>
            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
              <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.confirmPassword}
              </p>
            ) : null}
          </div>

          <div className="mb-[24.5px]">
            <p className="font-medium text-xs text-textColor mb-[10px]">
              Please confirm if this data is correct
            </p>
            <label className="flex cursor-pointer" onClick={handleChange}>
              <input
                type="checkbox"
                className="accent-primaryColor mr-[10px]"
              />
              <p className="text-xs font-normal text-textColor">
                Yes, this data is correct
              </p>
            </label>
          </div>

          <Button
            auth
            disabled={isLoading || !checked}
            className="mb-[30px] w-full md:w-[424px]"
            type="submit"
          >
            {isLoading ? <Spinner /> : "Continue"}
          </Button>
        </form>
      </div>
    </OnboardingLayout>
  );
};

export default StaffPersonalDetails;
