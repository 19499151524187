import Table from "layout/table/Table";
// import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";
import { Link } from "react-router-dom";
const RecentTransactionTable = ({ summary }) => {
  const navigate = useNavigate();
  function handleViewMore() {
    let path = "/wallet/transactions";
    navigate(path);
  }

  const roundNumber = (num) => {
    return Math.floor(num); // Removes the decimal part
  };
  // const { funderTransactionsData } = useSelector((state) => state.funder);
  // const recentTransactions = funderTransactionsData?.data?.slice(0, 5);
  // const TransData = [
  //   {
  //     id: "1",
  //     transactionId: "TID5678FG99",
  //     beneficiary: "Dangote LTD",
  //     type: "Collections",
  //     amount: "9,000,000",
  //     status: "Successful",
  //   },
  //   {
  //     id: "2",
  //     transactionId: "TID7890CB34",
  //     beneficiary: "Nestle PLC",
  //     type: "Withdrawal",
  //     amount: "9,000,000",
  //     status: "Successful",
  //   },

  //   {
  //     id: "3",
  //     transactionId: "TID7890CB34",
  //     beneficiary: "Nestle PLC",
  //     type: "Withdrawal",
  //     amount: "9,000,000",
  //     status: "Successful",
  //   },
  //   {
  //     id: "4",
  //     transactionId: "TID7890CB34",
  //     beneficiary: "Nestle PLC",
  //     type: "Withdrawal",
  //     amount: "9,000,000",
  //     status: "Successful",
  //   },
  // ];
  const columns = [
    {
      name: <span className="whitespace-nowrap">Transaction ID</span>,
      selector: (row) => row.transactionId,
      sortable: true,
      width: "8.75rem",
    },
    {
      name: "Beneficiary",
      selector: (row) => row.beneficiaryName,
      sortable: true,
      width: "7.9rem",
    },
    {
      name: "Transaction Type",
      selector: (row) => row.transactionType,
      sortable: true,
      width: "7.9rem",
    },

    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      cell: (row) =>
        row.transactionStatus === "SUCCESSFUL" ? (
          <p className="text-success">{roundNumber(row.amount)}</p>
        ) : row.transactionStatus === "Failed" ? (
          <p className="text-red">{row.amount}</p>
        ) : (
          <p>{row.transactionStatus}</p>
        ),
      width: "7rem",
    },
    {
      name: "transactionStatus",
      selector: (row) => row.transactionStatus,
      sortable: true,
      cell: (row) =>
        row.transactionStatus === "SUCCESSFUL" ? (
          <p className="text-success">{row.transactionStatus}</p>
        ) : row.transactionStatus === "Failed" ? (
          <p className="text-red">{row.transactionStatus}</p>
        ) : row.transactionStatus === "Pending" ? (
          <p className="text-yellow-400">{row.transactionStatus}</p>
        ) : (
          <p>{row.transactionStatus}</p>
        ),
      width: "7rem",
    },
  ];

  // console.log("===trans====", summary?.data?.transactions);

  return (
    <>
      {!summary?.data?.transactions?.length ? (
        <div className="ml-3">
          <div className="flex justify-between items-center px-[24px] bg-white h-[60px] rounded-t-[10px]">
            <p className="text-black text-sm font-medium">
              Recent Wallet Transactions
            </p>

            <Link
              to="/wallet"
              className="text-gray-500 text-sm font-normal underline"
            >
              View More
            </Link>
          </div>
          <TableEmptyState dashboard />
        </div>
      ) : (
        <Card className="rounded-[10px] mt-[24px] ml-3">
          <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
            <div>
              <p className="text-black text-lg font-bold">
                Recent Wallet Transactions
              </p>
            </div>
            <span
              onClick={handleViewMore}
              className="text-black text-sm font-medium underline cursor-pointer"
            >
              {" "}
              <span
                to="/wallet"
                className="text-gray-500 text-sm font-normal underline"
              >
                View More
              </span>
            </span>
          </div>
          <div className="px-[24px] pb-[24px]">
            <Table
              columns={columns}
              data={summary?.data?.transactions}
              pointer
              tableHeader
              className="mt-5"
            />
          </div>
        </Card>
      )}
    </>
  );
};
export default RecentTransactionTable;
