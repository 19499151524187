import { PAIconInventoryArrowLeft } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Card from "shared/Card";
import { motion } from "framer-motion";
import CocaCola from "../../assets/images/svgs/cocacola.svg";
import { useEffect, useState } from "react";
import { getAllInvoiceById, getBulkEarlyPaymentDetails } from "appstate/api/invoice/invoice";

const SupplierBulkEarlyPaymentDetailsRejected = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { invoiceId } = location.state || {};

  const [invoiceData, setInvoiceData] = useState(null);
    // eslint-disable-next-line
  const [error, setError] = useState(null);
   // eslint-disable-next-line
  const [earlyPaymentData, setEarlyPaymentData] = useState(null);
   // eslint-disable-next-line
   const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchInvoice = async () => {
      if (invoiceId) {
        const result = await getAllInvoiceById({ id: invoiceId });
        setInvoiceData(result?.data);
      }
    };
    fetchInvoice();
    // eslint-disable-next-line
  }, [invoiceId]);

  useEffect(() => {
    const fetchEarlyPaymentDetails = async () => {
      try {
        const result = await getBulkEarlyPaymentDetails(id);
        setEarlyPaymentData(result?.data);
      } catch (error) {
        setError(error.message || "Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchEarlyPaymentDetails();
    }
  }, [id]);

  // console.log("invoice data" , invoiceId)

 
  // const { state } = location?.state || {};

  function handleNavigate() {
    let path = -1;
    navigate(path);
  }

  // const onRowClicked = () => {
  //   let path = "/supplier bulk-early-payment-details-details";
  //   navigate(path, { state: state }); 
  // };

  return (
    <DashboardLayout
      section="Invoice"
      subHeader="Here is an overview of your invoices"
    >
      <header>
        <div
          onClick={handleNavigate}
          className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
        >
          <PAIconInventoryArrowLeft />
          <p className="mt-[2px] text-sm font-normal text-lightGray">Go Back</p>
        </div>
      </header>
      <div className="flex justify-between">
        <Card className="w-[60%] p-[24px] rounded-[10px]">
          <div className=" flex  flex-col gap-3 ">
            <main className=" w-full flex justify-between">
              <div className="flex flex-col gap-3 w-1/2">
                <div className="flex">
                  <span>Reference No:</span>
                  <span className="pl-2">|RF9101010BHSHS</span>
                </div>
                <div className="flex flex-col gap-1">
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Invoice Number:</span>
                    <span className="text-[#979797]  text-xs">INV 2002</span>
                  </div>
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Created Date:</span>
                    <span className="text-[#979797]  text-xs">
                      23-March-2023
                    </span>
                  </div>
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Submitted Date:</span>
                    <span className="text-[#979797]  text-xs">
                      23-March-2023
                    </span>
                  </div>
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Approved Date:</span>
                    <span className="text-[#979797]  text-xs">
                      23-March-2023
                    </span>
                  </div>
                  <div className="flex gap-x-[97px]">
                    <span className=" text-xs">Due Date:</span>
                    <span className="text-[#979797]  text-xs">
                      23-March-2023
                    </span>
                  </div>
                </div>
              </div>
             
                <div className="flex flex-col justify-between">
                  <motion.button
                    whileTap={{ scale: 0.9 }}
                    className="flex bg-[#FAD9D9] text-sm justify-center gap-2 text-[#DE1515] rounded-[5px] items-center px-[35px] py-1.5 "
                  >
                    Rejected
                  </motion.button>
                </div>
           

              {/* {state === "Approved" && (
                <div className="flex flex-col justify-between">
                  <motion.button
                    whileTap={{ scale: 0.9 }}
                    className="flex bg-[#D9EFD6] text-sm justify-center gap-2 text-[#59BB50] rounded-[5px] items-center px-[35px] py-1.5 "
                  >
                    Approved
                  </motion.button>
                </div>
              )} */}

              {/* {state === "Processing" && (
                <div className="flex flex-col justify-between">
                  <motion.button
                    whileTap={{ scale: 0.9 }}
                    className="flex bg-[#C7D7FF] text-sm justify-center gap-2 text-[#6994FF] rounded-[5px] items-center px-[35px] py-1.5 "
                  >
                    Processing
                  </motion.button>
                </div>
              )} */}

              {/* {state === "Pending Approval" && (
                <div className="flex flex-col justify-between">
                  <motion.button
                    whileTap={{ scale: 0.9 }}
                    className="flex bg-[#FFBE5557] text-sm justify-center gap-2 text-[#F08000] rounded-[5px] items-center px-[35px] py-1.5 "
                  >
                    Pending Approval
                   
                  </motion.button>
                </div>
              )} */}
            </main>
          </div>
          <br />
          <div className="flex justify-between">
            <div className="flex flex-col">
              <span className="font-light  text-xs">Nestle Plc</span>
              <span className="text-[#979797] text-sm">buyer details</span>
            </div>
            <div className="flex flex-col">
              <span className="font-light   text-xs">60 Days</span>
              <span className="text-[#979797] text-sm">Payment</span>
            </div>
            <div className="flex flex-col">
              <span className="text-[#979797] flex justify-end text-xs">
                NGN
              </span>
              <span className="font-light text-sm">Currency</span>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <header className="border-b-[5px] border-b-[#F6F5FA] pb-3"></header>
            <div className="flex  justify-between">
              <div className="flex gap-2"></div>
              <div className="flex gap-2">
                <div className="flex flex-col">
                  <span className="text-[#979797]  text-xs">4 Invoices</span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
              <h1 className="text-sm">Invoice List</h1>
            </header>
            <div className=" w-full overflow-x-auto">
              <table className=" w-full overflow-x-auto">
                <thead className="bg-subtleGray w-">
                  <tr className=" bg-unusualGray p-2.5 ">
                    <td className="text-[#11192A] p-2.5 text-xs font-bold">
                      Ref Number
                    </td>
                    <td className=" p-2.5 text-xs font-bold">Due Date</td>
                    <td className=" p-2.5 text-xs font-bold">Request Amount</td>
                    <td className=" p-2.5 text-xs font-bold">Discount Rate</td>
                    <td className=" p-2.5 text-xs font-bold">
                      {"Amount(Before Tax)"}
                    </td>
                    <td className=" p-2.5 text-xs font-bold">
                      Discount Amount
                    </td>
                    <td className=" p-2.5 text-xs font-bold">
                      Receivable Amount
                    </td>
                    {/* <td className=" p-2.5 text-xs font-bold">Total Price</td> */}
                  </tr>
                </thead>
                {invoiceData?.items?.map((item, index) => (
                  <tbody key={index}>
                    <tr
                      style={{ cursor: "pointer" }}
                      // onClick={onRowClicked}
                      className="py-[3px] px-1.5"
                    >
                      <td className="text-xs text-[#11192A] p-2.5">
                        {item.refNumber}
                      </td>
                      <td className="text-xs text-[#11192A] p-2.5">
                        {item.dueDate}
                      </td>
                      <td className="text-xs text-[#11192A] p-2.5">
                        {item.requestAmount}
                      </td>
                      <td className="text-xs text-[#11192A] p-2.5">
                        {item.discountRate}
                      </td>
                      <td className="text-xs text-[#11192A] p-2.5">
                        {item.amountBeforeTax}
                      </td>
                      <td className="text-xs text-[#11192A] p-2.5">
                        {item.discountAmount}
                      </td>
                      <td className="text-xs text-[#11192A] p-2.5">
                        {item.receivableAmount}
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
            <div className="flex justify-between">
              <div>
                <h4 className="text-lg text-[#11192A] ">
                  Receiving Funding Account Information
                </h4>
                <h5 className="text-lg text-[#11192A] ">7662662662626</h5>
                <h5 className="text-lg text-[#11192A] ">Praise Akindade</h5>
              </div>
              <div style={{ width: 300 }}>
                <div className="flex justify-between">
                  <span className="text-sm text-[#11192A] ">Subtotal</span>
                  <span className="text-sm text-[#11192A] ">1000000</span>
                </div>
                <div className="flex justify-between">
                  <div>
                    <span className="text-sm text-[#11192A] ">Vat % </span>
                    <span className="pl-2 text-sm text-[#11192A] ">5.6%</span>
                  </div>
                  <div>
                    <span className="text-sm text-[#11192A] ">583,000</span>
                  </div>
                </div>
                <div className="flex justify-between">
                  <div>
                    <span className="text-sm text-[#11192A] ">Discount % </span>
                    <span className="pl-2 text-sm text-[#11192A] ">2.6%</span>
                  </div>
                  <div>
                    <span className="text-sm text-[#11192A] ">283,000</span>
                  </div>
                </div>
                <div>
                  <hr className="border-t border-[black] mt-2 pt-2" />
                  <div className="flex justify-between">
                    <span className="text-lg text-[#11192A] ">Grand Total</span>
                    <span className="text-lg text-[#11192A] ">10,000,000</span>
                  </div>
                  <hr className="border-t border-[black] mt-2 pt-2" />
                </div>
              </div>
            </div>
          </div>
        </Card>
        <Card className="w-[38%] p-[24px] rounded-[10px]">
          <div>
            <div className="flex justify-between">
              <span className="font-bold text-[#000000]">
                Early Payment Details
              </span>
            </div>
          </div>
          <div>
            <br />
            <div className="flex justify-between">
              <div className="flex">
                <div>
                  <img src={CocaCola} alt="" />
                </div>
                <div style={{ lineHeight: 1 }} className="flex flex-col pl-2">
                <span>{earlyPaymentData?.buyerCompanyName}</span>
                  <span className="text-[#959595]">buyer</span>
                </div>
              </div>
              <div>
                <div style={{ lineHeight: 1 }} className="flex flex-col pl-2">
                <span className="text-[#959595] flex justify-end">{earlyPaymentData?.discountRate}%</span>
                  <span className="text-[#959595]">Discount rate</span>
                </div>
              </div>
            </div>
            <br />
            <div className="flex justify-between">
              <div style={{ lineHeight: 1.3 }} className="flex flex-col pl-2">
              <span>₦{earlyPaymentData?.totalDiscountAmount}</span>
                <span className="text-[#959595]">Discount Amount</span>
              </div>
              <div>
                <div style={{ lineHeight: 1.3 }} className="flex flex-col pl-2">
                <span className="flex justify-end">
                    {earlyPaymentData?.invoiceDueDate}(
                    {earlyPaymentData?.paymentDays}Days)
                  </span>
                  <span className="flex justify-end text-[#959595]">
                    Early Payment Date
                  </span>
                </div>
              </div>
            </div>
            <br />
            <div className="flex justify-between">
              <div style={{ lineHeight: 1.3 }} className="flex flex-col pl-2">
              <span className="text-[#959595]">
                  ₦{earlyPaymentData?.totalRequestAmount}
                </span>
                <span className="text-[#959595]">Total Request Amount</span>
              </div>
              <div>
                <div style={{ lineHeight: 1.3 }} className="flex flex-col pl-2">
                <span className="flex justify-end">
                    ₦{earlyPaymentData?.totalReceivableAmount}
                  </span>
                  <span className="text-[#959595]">
                    Total Receivable Amount
                  </span>
                </div>
              </div>
            </div>
          </div>
          <br />
          <hr className="text-[#959595]" />
          <br />
            <div className="flex flex-col">
              <span className="font-bold text-[#000000]">
                Notes from supplier:
              </span>
              <span className="text-[#959595]">
              {earlyPaymentData?.notesFromSupplier}
              </span>
            </div>
         
          <br /> 
          <hr className="text-[#959595]" />
          <br />
          
            <div className="flex flex-col">
              <span className="font-bold text-[#D40606]">
                Reason for rejection
              </span>
              <span className="text-[#959595]">
              {earlyPaymentData?.reasons}
              </span>
            </div>
         
        </Card>
      </div>
    </DashboardLayout>
  );
};
export default SupplierBulkEarlyPaymentDetailsRejected;
