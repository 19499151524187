import { PAIconAddRole, PAIconArrowLeftGreen } from "assets/images/svgs";
import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import { formatCapitaliseString } from "utilities/helper/formatCapitaliseString";
import { AnimatePresence, motion } from "framer-motion";
import CreateNewRule from "./modals/CreateNewRule";
import CreateRuleFinalise from "./modals/CreateRuleFinalise";
import RulesConfigSearchInput from "./RulesConfigSearchInput";
import {
  allRules,
  updatePercentageLimitAndDiscount,
} from "appstate/rulesandconfig/rulesandconfigSlice";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
// import toast from "react-hot-toast";

function RulesConfigHeader({
  setRuleSavedModal,
  handleSearch,
  searchBy,
  setSearchBy,
  searchQuery,
  setSearchQuery,
  setTestModal,
}) {
  const dispatch = useDispatch();
  const { ruleType } = useParams();
  const [createNewRuleModal, setCreateNewRuleModal] = useState(false);
  const [createNewRuleModalFinal, setCreateNewRuleModalFinal] = useState(false);
  const [toggleEdit, setToggleEdit] = useState(false);
  const [discountRate, setDiscountRate] = useState("");
  const [percentageLimit, setPercentageLimit] = useState("");

  let programType;
  switch (ruleType) {
    case "EARLY_PAYMENT":
      programType = "EARLY_PAYMENT_REQUEST";
      break;

    default:
      programType = "";
  }

  const handleDiscountAndPercentageUpdate = () => {
    dispatch(
      updatePercentageLimitAndDiscount({
        discountRate,
        percentageLimit,
        ruleType,
        programType,
      })
    ).then((data) => {
      if (data?.payload?.success) {
        dispatch(allRules({ page: 0, pageSize: 10, ruleType }));
        toast.success(data?.payload?.message);
        setToggleEdit(!toggleEdit);
      } else {
        toast.error(data?.payload?.message);
      }
    });
  };

  return (
    <div>
      {/* Create New Rule Modal */}
      <AnimatePresence>
        {createNewRuleModal && (
          <CreateNewRule
            setTestModal={setTestModal}
            setRuleSavedModal={setRuleSavedModal}
            closeModal={() => setCreateNewRuleModal(false)}
            setCreateNewRuleModalFinal={setCreateNewRuleModalFinal}
          />
        )}
      </AnimatePresence>

      {/* Rule Modal Finalise  */}
      <AnimatePresence>
        {createNewRuleModalFinal && (
          <CreateRuleFinalise
            closeModal={() => setCreateNewRuleModalFinal(false)}
            setCreateNewRuleModal={setCreateNewRuleModal}
            setRuleSavedModal={setRuleSavedModal}
          />
        )}
      </AnimatePresence>

      <header className=" flex flex-col gap-6 w-full">
        <div className="flex gap-4">
          <Link to="/settings/rules&config" className="flex gap-1.5 text-sm ">
            <PAIconArrowLeftGreen />
            <span className="flex items-center text-primaryColor">Go Back</span>
          </Link>

          <div>
            <h1 className="text-xl font-light">
              {formatCapitaliseString(ruleType)}
            </h1>
          </div>
        </div>

        <div className="p-4 rounded-lg border border-[#95959580] bg-[#E0E0E01A] flex flex-col gap-5">
          <div className="flex justify-between">
            <span className="font-semibold">
              Default configurations for {formatCapitaliseString(ruleType)}
            </span>
          </div>

          <div className="flex flex-col md:flex-row  justify-between gap-10">
            <div className="flex flex-col md:flex-row gap-14 text-sm items-center">
              <div className="flex w-full md:w-auto items-center gap-4">
                <span className="flex gap-2 py-2 font-light">
                  Discount Rate
                </span>

                <span>
                  <input
                    value={discountRate}
                    onChange={(e) => {
                      setDiscountRate(e.target.value);
                    }}
                    disabled={!toggleEdit}
                    placeholder="0.0%"
                    className={`p-2.5 flex w-20  rounded ${
                      toggleEdit && "border border-primaryColor"
                    }  outline-none justify-center`}
                    type="text"
                  />
                </span>
              </div>

              <div className="flex w-full md:w-auto items-center gap-4">
                <span className="flex gap-2 py-2 font-light">
                  Percentage Limit
                </span>

                <span>
                  <input
                    value={percentageLimit}
                    onChange={(e) => {
                      setPercentageLimit(e.target.value);
                    }}
                    disabled={!toggleEdit}
                    placeholder="0.0%"
                    className={`p-2.5 flex w-20  rounded ${
                      toggleEdit && "border border-primaryColor"
                    }  outline-none justify-center`}
                    type="text"
                  />
                </span>
              </div>

              <div>
                {toggleEdit ? (
                  <button
                    onClick={() => {
                      handleDiscountAndPercentageUpdate();
                    }}
                    className="text-white rounded-[5px] bg-primaryColor px-5 py-2.5 text-sm font-light"
                  >
                    Save
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      setToggleEdit(!toggleEdit);
                    }}
                    className="text-secondaryColor rounded-[5px] border border-secondaryColor px-5 py-2.5 text-sm font-light"
                  >
                    Edit
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex md:flex-row justify-between flex-row-reverse">
          <motion.div whileTap={{ scale: 0.95 }} className="flex items-center">
            <button
              onClick={() => setCreateNewRuleModal(true)}
              className="px-6 gap-1.5 text-sm border-[0.5px] border-[#ccc] rounded-[5px] text-white py-3 flex justify-center items-center bg-brilliantYellow transform transition duration-500"
            >
              <PAIconAddRole />
              <span className="hidden md:inline-block ">Create new rule</span>
            </button>
          </motion.div>

          <div>
            <div>
              <RulesConfigSearchInput
                onSearch={handleSearch}
                searchBy={searchBy}
                setSearchBy={setSearchBy}
                setSearchQuery={setSearchQuery}
                searchQuery={searchQuery}
              />
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default RulesConfigHeader;
