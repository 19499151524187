import { PAIconFluentBox } from "assets/images/svgs";
import AccountFrom from "features/wallet(new)/overview/FundAccount/VirtualAccount/AccountFrom";
import AccountTo from "features/wallet(new)/overview/FundAccount/VirtualAccount/AccountTo";
import WithdrawFund from "features/wallet(new)/overview/Withdraw/WithdrawFund";
import React, { useEffect, useState } from "react";
import PinModal from "shared/PinModal";
import TransferReceipt from "shared/TransferReceipt";

const WithdrawalOptions = ({ isOpen, onClose }) => {
  //   const { withdrawalFormData } = useSelector((state) => state.wallet);

  const [isWithdrawFund, setIsWithdrawFund] = useState(false);
  const [showPinModal, setShowPinModal] = useState(false);
  const [isTransferReceiptOpen, setIsTansferReceiptOpen] = useState(false);
  const [showOptionModal, setShowOptionModal] = useState(true);

  const [isAccountFromOpen, setIsAccountFromOpen] = useState(false);
  const [isAccountToOpen, setIsAccountToOpen] = useState(false);
  //   const [fundAccountPinModal, setFundAccountPinModal] = useState(false);
  //   const [isTransferReceiptOpen, setIsTransferReceiptOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setShowOptionModal(true);
    }
  }, [isOpen]);

  const handleWithdrawFundOpen = () => {
    setIsWithdrawFund(true);
    setShowOptionModal(false);
  };

  const handleWithdrawalSubmit = () => {
    setShowPinModal(true);
    setIsWithdrawFund(false);
  };

  const handlePinModalClose = () => {
    setShowPinModal(false);
  };

  const handleCloseWithdrawOption = () => {
    setIsTansferReceiptOpen(false);
    setShowOptionModal(false);
    onClose();
  };

  const handleFundVirtualAccountClick = () => {
    setIsAccountFromOpen(true);
    // setIsFundOptionsOpen(false);
  };

  const handleAccountToBack = () => {
    setIsAccountToOpen(false);
    setIsAccountFromOpen(true);
  };

  const handleAccountFromSubmit = () => {
    setIsAccountToOpen(true);
    setIsAccountFromOpen(false);
  };

  const handleFundVirtualAccountSubmit = () => {
    setIsAccountToOpen(false);
    // setFundAccountPinModal(true);
    setShowPinModal(true);
  };

  if (!isOpen) return null;

  return (
    <>
      {/* WITHDRAW FROM EXTERNAL SOURCE MODAL */}
      {showOptionModal && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg rounded-md z-[1000]">
          <div className="bg-white rounded-md max-w-xs md:max-w-md w-full pb-5">
            <div className="flex justify-between items-center mb4">
              <p className="text-black font-sans text-base font-bold p-3 px-5 rounded-sm italic">
                Fund Account
              </p>
              <button
                onClick={onClose}
                className="text-black font-sans text-xl cursor-pointer flex justify-center items-center"
              >
                &times; <span className="text-sm mx-2 mt-1">Close</span>
              </button>
            </div>
            <div className="border border-gray mb-5 px-5"></div>

            {/* FORM */}
            <div>
              <div className="w-full px-7">
                <p className="text-[#7A7A7A] font-sans text-sm font-normal mb6">
                  Select withdrawal method
                </p>

                <div
                  className="bg-[#07593D] p-3 mt-3 text-white flex justify-between cursor-pointer"
                  onClick={handleWithdrawFundOpen}
                >
                  <p className="text-white text-xs">
                    Withdrawal an <br />
                    <span className="font-semibold">External source</span>
                  </p>
                  <br />
                  <div className="mt-2">
                    <PAIconFluentBox />
                  </div>
                </div>

                <div
                  className="bg-[#07593D] p-3 mt-3 text-white flex justify-between cursor-pointer"
                  onClick={handleFundVirtualAccountClick}
                >
                  <p className="text-white text-xs">
                    Withdrawal to a <br />
                    <span className="font-semibold">Virtual account</span>
                  </p>
                  <br />
                  <div className="mt-2">
                    <PAIconFluentBox />
                  </div>
                </div>

                {/* FOOTER */}
                <div className="flex justify-center mt-10 mb-3">
                  <p className="text-[#7A7A7A] font-sans text-sm font-normal">
                    Select your preferred withdrawal method
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isWithdrawFund && (
        <WithdrawFund
          isOpen={handleWithdrawFundOpen}
          onClose={() => setIsWithdrawFund(false)}
          onSubmit={handleWithdrawalSubmit}
        />
      )}

      {showPinModal && (
        <PinModal
          isOpen={showPinModal}
          onClose={handlePinModalClose}
          onSubmit={(pin) => {
            setShowPinModal(false);
            setIsTansferReceiptOpen(true);
          }}
          headerText="Withdrawal"
          instructionText="Enter your transaction PIN to transfer funds to your desired account."
        />
      )}

      {isTransferReceiptOpen && (
        <TransferReceipt
          isOpen={isTransferReceiptOpen}
          // onClose={() => setIsTansferReceiptOpen(false)}
          onClose={() => handleCloseWithdrawOption()}
          amount="20,000"
          currency="NGN"
          paymentStatus="Success"
          senderName="Adewale Favour Adediwura"
          sourceBank="Access bank plc"
          sourceAccountNumber="23857369282"
          beneficiaryName="Praise Akobundu Ignatius"
          destinationBank="PayEdge"
          destinationAccountNumber="45678900987"
          transactionReference="87463938573992834746893"
          createdDate="23-Dec-2024"
          supportEmail="customerservice@payedge.com"
          //   onDownload={() => console.log("Downloaded!")}
        />
      )}

      {/* WITHDRAW FROM VIRTUAL ACCOUNT MODAL */}
      <AccountFrom
        isOpen={isAccountFromOpen}
        onClose={() => setIsAccountFromOpen(false)}
        where1="From- "
        where2="to"
        onSubmit={handleAccountFromSubmit}
      />
      <AccountTo
        isOpen={isAccountToOpen}
        onClose={() => setIsAccountToOpen(false)}
        accountFromBack={() => {}}
        accountToBack={handleAccountToBack}
        onSubmit={handleFundVirtualAccountSubmit}
      />
      {/* {fundAccountPinModal && (
        <PinModal
          isOpen={fundAccountPinModal}
          onClose={() => {
            setFundAccountPinModal(false);
          }}
          onSubmit={(pin) => {
            // setShowPinModal(false);
            setIsTransferReceiptOpen(true);
          }}
          headerText="Fund Account"
          instructionText="Enter your transaction PIN to transfer funds to your desired account."
        />
      )}

      {isTransferReceiptOpen && (
        <TransferReceipt
          isOpen={isTransferReceiptOpen}
          onClose={handleTransferReceiptClose}
          // onClose={() => handleCloseWithdrawOption()}
          amount="1000"
          currency="NGN"
          paymentStatus="Success"
          senderName="Adewale Favour Adediwura"
          sourceBank="Access bank plc"
          sourceAccountNumber="23857369282"
          beneficiaryName="Praise Akobundu Ignatius"
          destinationBank="PayEdge"
          destinationAccountNumber="45678900987"
          transactionReference="87463938573992834746893"
          createdDate="23-Dec-2024"
          supportEmail="customerservice@payedge.com"
          //   onDownload={() => console.log("Downloaded!")}
        />
      )} */}
    </>
  );
};

export default WithdrawalOptions;
