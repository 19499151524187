import { buyerViewPfpById } from "appstate/buyer/buyerSlice";
import { PAIconInventoryArrowLeft } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "shared/Button";
import Card from "shared/Card";
import ProgressDetails from "./components/Modal/ProgressDetails";
import { useLocation } from "react-router-dom";
import PendingDetails from "./components/Modal/PendingDetails";
import DeleteModal from "./components/Modal/DeleteModal";

function PayableFinanceDetails() {
  // const [isFunderSelected, setIsFunderSelected] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [actionText, setActionText] = useState("");
  const dispatch = useDispatch();
  const { pfpId, viewPfpByIdData } = useSelector((state) => state?.buyer);
  useEffect(() => {
    dispatch(buyerViewPfpById({ id: pfpId }));
  }, [dispatch, pfpId]);
  const location = useLocation();
  const { financingOption } = location.state || {};

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      width: "15rem",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      width: "15rem",
    },
    {
      name: "Tier",
      selector: (row) => row.tier,
      sortable: true,
      width: "15rem",
    },
  ];

  const funderColumns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      width: "15rem",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      width: "15rem",
    },
  ];

  const handleCancelModal = () => {
    setIsDeleteModal(!isDeleteModal);
    setActionText("Cancel");
  };

  const handleStopModal = () => {
    setIsDeleteModal(!isDeleteModal);
    setActionText("Stop");
  };

  const navigate = useNavigate();
  function handleGoback() {
    let path = -1;
    navigate(path);
  }
  function handleRedirect() {
    let path = "/payablefinancing/payablefinancedetails/payablefinanceinvoice";
    navigate(path);
  }
  return (
    <DashboardLayout
      section="PFP Campaign"
      subHeader="Manage all your payable finance campaign"
    >
      <div>
        <div
          onClick={handleGoback}
          className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
        >
          <PAIconInventoryArrowLeft />
          <p className="mt-[2px] text-sm font-normal text-lightGray">Go Back</p>
        </div>
        <header className="bg-alabasterHeader p-[24px] rounded-t-[10px]">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-black text-sm font-medium">Details</p>
              <p className="text-textColor text-xs font-normal">
                Basic information about the Payable finance program
              </p>
            </div>

            <div className="flex gap-x-[10px]">
              <div className="flex items-center">
                <p>Status:</p>
                <p
                  className={
                    viewPfpByIdData?.data?.status === "PENDING"
                      ? "ml-2 text-yellow-600 text-[14px]"
                      : viewPfpByIdData?.data?.status === "ENDED"
                      ? "text-red ml-2 text-[14px]"
                      : viewPfpByIdData?.data?.status === "CANCELLED"
                      ? "text-red ml-2 text-[14px]"
                      : viewPfpByIdData?.data?.status === "CANCELLED"
                      ? "text-red ml-2 text-[14px]"
                      : "text-green-600 ml-2 text-[14px]"
                  }
                >
                  {viewPfpByIdData?.data?.status === "PENDING"
                    ? "Pending"
                    : viewPfpByIdData?.data?.status === "IN_PROGRESS"
                    ? "In Progress"
                    : viewPfpByIdData?.data?.status === "STOPPED"
                    ? "Stopped"
                    : viewPfpByIdData?.data?.status}
                </p>
              </div>
              {viewPfpByIdData?.data?.status === "PENDING" ? (
                <Button
                  neutral
                  onClick={handleCancelModal}
                  className="py-[12px] px-[24px] rounded-[5px] border-[1px] border-red !text-red text-sm"
                >
                  Cancel
                </Button>
              ) : viewPfpByIdData?.data?.status === "IN_PROGRESS" ? (
                <Button
                  neutral
                  onClick={handleStopModal}
                  className="py-[12px] px-[24px] rounded-[5px] border-[1px] border-red !text-red text-sm"
                >
                  Stop
                </Button>
              ) : null}

              <Button
                neutral
                onClick={handleRedirect}
                className="py-[12px] px-[24px] rounded-[5px] border-[1px] border-silver !text-gray800 text-sm"
              >
                View Offers
              </Button>
            </div>
          </div>
        </header>
        <DeleteModal
          isOpen={isDeleteModal}
          actionText={actionText}
          pfpId={pfpId}
          onClose={() => {
            setIsDeleteModal(false);
          }}
        />

        <Card className="px-[20px] md:px-[40px] pb-[40px] rounded-b-[10px] 2xl:pl-[100px]">
          <div>
            <div className="flex items-start">
              <div className="mt-4 w-[50%]">
                <div>
                  <h2 className="text-lightGray text-sm">Name of Program</h2>
                  <p className="text-sm font-normal text-textColor">
                    {viewPfpByIdData?.data?.programName}
                  </p>
                </div>

                <div className="mt-[16px]">
                  <p className="mt-[8px] text-lightGray text-sm font-normal">
                    Financing Option
                  </p>
                  <p className="text-sm font-normal text-textColor">
                    {viewPfpByIdData?.data?.financingOption ===
                    "REVERSE_FACTORING"
                      ? "Reverse Factoring"
                      : "Dynamic Discounting"}
                  </p>
                </div>

                <div className="mt-[16px]">
                  <p className="text-sm font-normal text-lightGray">
                    Start Date
                  </p>
                  <p className="text-textColor text-sm font-normal">
                    {viewPfpByIdData?.data?.startDate}
                  </p>
                </div>

                <div className="mt-[16px]">
                  <p className="text-sm font-normal text-lightGray">End Date</p>
                  <p className="text-textColor text-sm font-normal">
                    {viewPfpByIdData?.data?.endDate}
                  </p>
                </div>

                <div className="mt-[16px]">
                  <p className="text-sm font-normal text-lightGray">
                    Tier(s) Selected
                  </p>
                  <p className="text-textColor text-sm font-normal">
                    {viewPfpByIdData?.data?.tiers}
                  </p>
                </div>
              </div>

              <div className="mt-[24px] md:mt-4 w-[50%]">
                <div>
                  <p className="text-sm font-normal text-lightGray">
                    Amount Range
                  </p>
                  <p className="text-textColor text-sm font-normal">
                    Min: NGN {viewPfpByIdData?.data?.minAmount}
                  </p>
                  <p className="text-textColor text-sm font-normal w-[150px] md:w-full">
                    Max: NGN {viewPfpByIdData?.data?.maxAmount}
                  </p>
                </div>
                <div className="mt-[8px]">
                  <p className="text-sm font-normal text-lightGray">
                    Discount Rate
                  </p>
                  <p className="text-textColor text-sm font-normal">
                    {viewPfpByIdData?.data?.discountRate}
                  </p>
                </div>
                <div className="mt-[8px]">
                  <p className="text-sm font-normal text-lightGray">
                    Offer Percentage
                  </p>
                  <p className="text-textColor text-sm font-normal">
                    {viewPfpByIdData?.data?.offerPercentage}
                  </p>
                </div>
                <div className="mt-[8px]">
                  <p className="text-sm font-normal text-lightGray">
                    Payment Period
                  </p>
                  <p className="text-textColor text-sm font-normal">
                    {viewPfpByIdData?.data?.paymentPeriod}
                  </p>
                </div>
                <div className="mt-[8px]">
                  <p className="text-sm font-normal text-lightGray">
                    Sharing Ratio
                  </p>
                  <p className="text-textColor text-sm font-normal w-[150px] md:w-full">
                    Buyer: {viewPfpByIdData?.data?.buyerRatio}
                  </p>
                  <p className="text-textColor text-sm font-normal w-[150px] md:w-full">
                    Funder: {viewPfpByIdData?.data?.funderRatio}
                  </p>
                </div>
              </div>
            </div>
            {financingOption === "Reverse Factoring" ? (
              <ProgressDetails
                funderColumns={funderColumns}
                columns={columns}
                viewPfpByIdData={viewPfpByIdData}
              />
            ) : (
              <PendingDetails
                fundingAccount={viewPfpByIdData?.data?.fundingAccount}
              />
            )}
          </div>
        </Card>
      </div>
    </DashboardLayout>
  );
}

export default PayableFinanceDetails;
