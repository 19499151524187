import { useEffect, useState } from "react";
import RecentPaymentTable from "./Table";
import { useDispatch, useSelector } from "react-redux";
import { disbursementFunderOverview } from "appstate/disbursement/disbursementSlice";
import { formatCurrency } from "utilities/helper/formatters";

const Overview = ({ setActiveTab }) => {
  const dispatch = useDispatch();
  const [selectedFilter, setSelectedFilter] = useState("ALL");
  const { disbursementFunderOverviewData } = useSelector(
    (state) => state?.disbursement
  );
  useEffect(() => {
    dispatch(disbursementFunderOverview({ filter: selectedFilter }));
  }, [dispatch, selectedFilter]);
  const sections = [
    {
      title: "Direct Funding",
      titleColor: "#2FA06A",
      borderColor: "#07593D",
      selectColor: "#07593D",
      data: [
        {
          label: "Total Requests",
          value:
            disbursementFunderOverviewData?.data?.directFundingMetric
              ?.totalRequests ?? 0,
        },
        {
          label: "Total Amount",
          value:
            formatCurrency(
              disbursementFunderOverviewData?.data?.directFundingMetric
                ?.totalAmount
            ) ?? formatCurrency(0),
        },
      ],
    },
    {
      title: "Open Market",
      titleColor: "#2FA06A",
      borderColor: "#07593D",
      selectColor: "#07593D",
      data: [
        {
          label: "Total Invoices",
          value:
            disbursementFunderOverviewData?.data?.openMarketMetric
              ?.totalInvoices ?? 0,
        },
        {
          label: "Total Amount",
          value:
            formatCurrency(
              disbursementFunderOverviewData?.data?.openMarketMetric
                ?.totalAmount
            ) ?? formatCurrency(0),
        },
      ],
    },
    {
      title: "Early Payment Program",
      subtitle: "(Reverse Factoring)",
      titleColor: "#F08000",
      borderColor: "#F08000",
      selectColor: "#F08000",
      data: [
        {
          label: "Total Offers",
          value:
            disbursementFunderOverviewData?.data?.epoMetric?.totalOffers ?? 0,
        },
        {
          label: "Total Amount",
          value:
            formatCurrency(
              disbursementFunderOverviewData?.data?.epoMetric?.totalAmount
            ) ?? formatCurrency(0),
        },
      ],
    },

    {
      title: "Closed Market",
      subtitle: "boiler",
      titleColor: "#F08000",
      borderColor: "#F08000",
      selectColor: "#F08000",
      data: [
        {
          label: "Total Invoices",
          value:
            disbursementFunderOverviewData?.data?.closedMarketMetric
              ?.totalInvoices ?? 0,
        },
        {
          label: "Total Amount",
          value:
            formatCurrency(
              disbursementFunderOverviewData?.data?.closedMarketMetric
                ?.totalAmount
            ) ?? formatCurrency(0),
        },
      ],
    },
  ];

  return (
    <div className="w-full">
      <div className="w-full rounded-b-lg  shadow-lg">
        <header className="flex justify-between items-center mt-3 border-b-2 border-[#AEAEAE] flex-shrink-0 bg-white px-7 py-4 rounded-t-md">
          <h2 className="text-[#3E3E3E] font-bold text-[16px]">
            Summary of invoices for Disbursement from diverse channels.
          </h2>

          <div className="w-fit">
            <select
              value={selectedFilter}
              onChange={(e) => setSelectedFilter(e.target.value)}
              className={`border border-primaryColor w-fit bg-primaryColor text-xs font-semibold outline-none text-white rounded py-2 px-4 cursor-pointer`}
            >
              <option value="ALL">All</option>
              <option value="THIS_WEEK">1 week</option>
              <option value="THIS_MONTH">1 month</option>
              <option value="THIS_QUARTER">Quarterly</option>
              <option value="THIS_HALF_YEAR">Half a year</option>
              <option value="THIS_YEAR">1 year</option>
            </select>
          </div>
        </header>
        <div className="bg-[#EBEBEB] pb-5 rounded-b-lg mb-4">
          <div className="px-7 py-4 grid lg:grid-cols-2  md:grid-cols-[45%,54%] 2xl:grid-cols-[43%,56%] gap-5">
            {/* First Grid Section */}
            <section className="flex flex-col justify-between">
              <div className="flex gap-2 justify-between flex-col lg:flex-row">
                <div className="lg:w-[48%] bg-primaryColor p-10 flex-row items-center justify-center text-white rounded-l-md">
                  <p className="flex items-center justify-center font-medium text-4xl">
                    {disbursementFunderOverviewData?.data?.totalRequests ?? 0}
                    {/* {funderOverviewData?.totalDueInvoices || 0} */}
                  </p>
                  <p className="flex items-center justify-center font-normal text-[16px]">
                    Total Invoices
                  </p>
                </div>

                <div
                  style={{
                    boxShadow: "0px 3px 10.6px 5px rgba(0, 0, 0, 0.04)",
                  }}
                  className="lg:w-[48%] border-l border-primaryColor bg-[#EDFFF9] text-primaryColor p-7 py-11 flex-row items-center justify-center rounded-r-md"
                >
                  <p className="flex semi-lg:whitespace-nowrap  items-center justify-center font-bold  md:text-base xl:text-[19px]">
                    ₦{" "}
                    {formatCurrency(
                      disbursementFunderOverviewData?.data?.totalOutstanding
                    ) ?? formatCurrency(0)}
                  </p>
                  <p className="flex items-center justify-center font-normal text-sm">
                    Total Outstanding
                  </p>
                </div>
              </div>

              <div className="flex justify-between flex-col lg:flex-row">
                <div className="px-5 py-[42px]  bg-[#3A455B] flex flex-col items-center justify-center mt-5 rounded-b-[10px] rounded-tr-[10px] text-white relative shadow-sm gap-3 lg:w-[48%]">
                  <span className="font-bold md:text-base xl:text-[19px] semi-lg:whitespace-nowrap">
                    <span>N </span>{" "}
                    {formatCurrency(
                      disbursementFunderOverviewData?.data?.totalAmountLiened
                    ) ?? formatCurrency(0)}
                  </span>
                  <span className=" text-[13px] font-normal">
                    Total Amount Liened
                  </span>
                </div>

                {/*  */}

                <div className="py-[42px] px-5 bg-secondaryColor flex flex-col items-center justify-center mt-5 rounded-bl-[10px] rounded-t-[10px] relative text-white shadow-sm gap-3 lg:w-[48%] ">
                  <span className="font-bold md:text-base xl:text-[19px]">
                    <span>N </span>{" "}
                    {formatCurrency(
                      disbursementFunderOverviewData?.data?.totalAmountDisbursed
                    ) ?? formatCurrency(0)}
                  </span>
                  <span className=" text-[13px] whitespace-nowrap font-normal">
                    Total Amount Disbursed
                  </span>
                </div>
              </div>
            </section>

            <section className="grid lg:grid-cols-2 gap-5">
              {sections.map((section, index) => (
                <div
                  key={index}
                  className={`fle justifybetween p-6 itemscenter bg-white rounded-b-md border-t-2`}
                  style={{ borderColor: section.borderColor }}
                >
                  <div className="flex item-center justify-between gap10">
                    <span className="flex flex-col">
                      <p
                        className={`text-[${section.titleColor}] font-bold text-base`}
                      >
                        {section.title}
                      </p>
                      {section.subtitle && (
                        <span
                          className={`${
                            section.subtitle === "boiler"
                              ? "text-white"
                              : "text-primaryColor"
                          } font-light text-xs`}
                        >
                          (Reverse Factoring)
                        </span>
                      )}
                    </span>
                  </div>
                  <div className="flex justify-between gap28 mt-4">
                    {section.data.map((item, idx) => (
                      <div
                        key={idx}
                        className="flex-row items-center justify-end"
                      >
                        <h2
                          className={`text-[${
                            item.label === "Total Amount"
                              ? "#F08000"
                              : "#07593D"
                          }] flex items-center justify-center font-bold 2xl:text-xl`}
                        >
                          {item.value}
                        </h2>
                        <h2 className="flex items-center justify-center font-normal text-[#565656] text-xs mt-3">
                          {item.label}
                        </h2>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </section>
          </div>
        </div>
      </div>
      <div>
        <RecentPaymentTable setActiveTab={setActiveTab} />
      </div>
    </div>
  );
};

export default Overview;
