import { setSelectedAccounts } from "appstate/wallet/walletSlice";
import { PAIconPaymnet } from "assets/images/svgs";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

const FundAccountOption = ({ isOpen, onClose, HeaderText, onSubmit }) => {
  const { allVirtualAccountsData } = useSelector((state) => state?.wallet);

  const dispatch = useDispatch();

  const handleSubmit = () => {
    onSubmit();
  };

  const accountData = Array.isArray(allVirtualAccountsData?.data)
    ? allVirtualAccountsData?.data?.map((account) => ({
        id: account?.id,
        name: account?.accountName,
        accountNumber: account?.accountNumber,
        bankName: account?.provider || "Payedge",
        accountType: "Virtual Account",
        amount: account?.balance,
      }))
    : [];

  const formatAmount = (amount) => {
    if (!amount) return "0.00";
    const parsedAmount = parseFloat(amount);
    if (isNaN(parsedAmount)) return "0.00";
    return parsedAmount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  if (!isOpen) return null;

  return (
    <>
      <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg rounded-md z-[1000]">
        <div className="bg-white rounded-md max-w-xs md:max-w-lg w-full pb-5">
          <div className="flex justify-between items-center mb-4 bg-[#FAFAFA]">
            <p className="text-black font-sans text-base font-semibold p-3 px-5 rounded-sm italic">
              Fund Invoice
            </p>
            <button
              onClick={onClose}
              className="text-black font-sans text-xl cursor-pointer flex justify-center items-center"
            >
              &times; <span className="text-sm mx-2 mt-1">Close</span>
            </button>
          </div>

          {/* ACCOUNTS */}
          <div>
            <div className="w-full px-7">
              <p className="text-zinc-600 text-center text-sm font-extrabold flex justify-center italic">
                {HeaderText}
              </p>
              <h1 className="text-zinc-600 text-base font-extrabold mt-5 italic">
                Accounts
              </h1>
              {/* Check if accountData is empty */}
              {accountData?.length === 0 ? (
                <p className="text-center text-red my-10 font-bold italic">
                  No virtual accounts available.
                </p>
              ) : (
                <div className="md:flex md:gap-5 flex-wrap max-h-48 overflow-auto">
                  {accountData.map((account, index) => (
                    <div
                      key={index}
                      className="bg-[#07593D] p-3 mt-3 cursor-pointer text-white text-xs md:w-[calc(50%-10px)] md:mb-0 mb-3 rounded"
                      onClick={() => {
                        dispatch(setSelectedAccounts(account));
                        handleSubmit();
                      }}
                    >
                      <div className="flex justify-between">
                        <div className="text-white flex items-center">
                          <PAIconPaymnet className="mr-2 text-sm font-bold" />
                          <p>{account.name}</p>
                        </div>
                        <p className="text-white font-light text-xs my-2">
                          {account.accountType}
                        </p>
                      </div>
                      <p className="mt-2 italic font-bold text-[18px] flex justify-start">
                        {account.currency} {formatAmount(account.amount)}
                      </p>
                      <p className="mt-5 text-xs">
                        <span className="mr-1">{account.bankName}</span> |
                        <span className="ml-1">{account.accountNumber}</span>
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FundAccountOption;
