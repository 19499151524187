import {
  disbursementFunderPayNow,
  disbursementProgramOverview,
} from "appstate/disbursement/disbursementSlice";
import { funderEarlyPaymentViewSingleApprovedDetails } from "appstate/funder/funderSlice";
import {
  allInvoiceById,
  getDirectFundingDetailsById,
} from "appstate/invoice/invoiceSlice";
import {
  PAIconArrowLeftGreen,
  PAIconCocacola,
  PAIconIconParkSucess,
  PAIconMDISuccess,
} from "assets/images/svgs";
import EnterPin from "features/disbursement/common/modal/Pin";
import { AnimatePresence, motion } from "framer-motion";
import DashboardLayout from "layout/dashboardlayout";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import TransferInfoReceipt from "features/wallet/withdraw/receipt";
import TransitionScale from "shared/animation/TransitionScale";
import ProgressModal from "shared/progressModal/ProgressModal";
import TooltipShared from "shared/tooltip/Tooltip";
import { formatCapitalize } from "utilities/formatCapitalize";
import { getDaysLeft } from "utilities/getDaysLeft";
import { formatDate } from "utilities/helper/dateFormatterSpelt";
import { formatCurrency } from "utilities/helper/formatters";
import useCountdown from "utilities/helper/useCountdown";

const DisbursementDirectFundingDetailsPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const invoiceId = searchParams.get("invId");
  const virtualAccountId = searchParams.get("VAId");
  const fundingChannel = searchParams.get("channel");
  const [pinModal, setPinModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [otpInputs, setOtpInputs] = useState(["", "", "", ""]);
  const [hidePin, setHidePin] = useState(false);
  const [showReceipt, setShowReceipt] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);

  const { disbursementFunderReceiptData } = useSelector(
    (state) => state?.disbursement
  );

  const { getDirectFundingDetailsByIdData, invoiceIdData } = useSelector(
    (state) => state?.invoice
  );

  const { funderEarlyPaymentViewSingleApprovedDetailsData } = useSelector(
    (state) => state?.funder
  );

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => setIsLoading(false), 1000);
  }, []);

  useEffect(() => {
    dispatch(allInvoiceById({ id: invoiceId }));

    if (fundingChannel === "direct-funding-request") {
      dispatch(getDirectFundingDetailsById(id));
    } else {
      dispatch(funderEarlyPaymentViewSingleApprovedDetails(id));
    }
  }, [dispatch, id, invoiceId, fundingChannel]);

  const handlePayNow = (pin) => {
    const pendingToast = toast.loading("Processing your Payment...");
    const body = {
      transactionPin: pin,
      invoiceIds: [id],
      virtualAccountId,
      disbursementType:
        fundingChannel === "direct-funding-request"
          ? "DIRECT_FUNDING"
          : "DF_EPR",
    };

    dispatch(disbursementFunderPayNow(body)).then((data) => {
      if (data?.payload?.success) {
        toast.dismiss(pendingToast);
        setHidePin(true);
        setLoading(true);
        setPaymentModal(true);
        setTimeout(() => {
          setLoading(false);
          setTimeout(() => {
            setPaymentModal(false);
            handleDownloadSuccessful();
          }, 300);

          dispatch(
            disbursementProgramOverview({
              programType:
                fundingChannel === "direct-funding-request"
                  ? "direct-funding-request"
                  : "early-payment-request",
              virtualAccountId,
            })
          );
        }, 3000);
      } else {
        toast.dismiss(pendingToast);
        return toast.error(data?.payload?.message);
      }
    });
  };

  // Here
  const handleDownloadSuccessful = () => {
    toast.custom(
      <div className="flex-1 items-center justify-center flex w-full h-full">
        <div className="flex mt-[300px]">
          <div className="bg-primaryColor w-[147.79px] h-[160px] flex flex-col items-center justify-center rounded-l-[7px] shadow-lg">
            <PAIconMDISuccess />
          </div>
          <div className="bg-white flex-col justify-center items-center self-stretch py-[25px] h-[160px] rounded-r-[7px] shadow-lg flex px-16 ">
            <PAIconIconParkSucess />
            <p className="text-dustyGray text-[13px] text-center mt-[17.6px]">
              Funds disbursed successfully
            </p>
          </div>
        </div>
      </div>
    );

    setTimeout(() => {
      toast.dismiss();
      setShowReceipt(true);
      setPinModal(false);
      setHidePin(false);
    }, 500);
  };

  const { timeLeft, isTimeUnderOneHour } = useCountdown(
    formatDate(invoiceIdData?.data?.dueDate)
  );
  return (
    <DashboardLayout
      section="Disbursement"
      subHeader="Track invoices due for payment disbursement"
    >
      <header className="w-full py-3">
        <span
          onClick={() => navigate(-1)}
          className="flex cursor-pointer items-center font-medium text-sm text-primaryColor gap-2"
        >
          <PAIconArrowLeftGreen /> Go back
        </span>
      </header>
      <main className="flex flex-col lg:flex-row gap-3 justify-between">
        <TransitionScale className="py-5 px-6 lg:w-[58%] flex flex-col gap-8 min-h-[90vh] bg-white rounded-[10px]">
          <header className="flex flex-col gap-3">
            <section className="flex justify-between  items-center">
              <span className="text-[18px] flex items-center font-bold">
                Invoice Details |{" "}
                <TooltipShared
                  text={invoiceIdData?.data?.invoiceRef}
                  textSize="text-[18px]"
                  maxLength={13}
                />
              </span>

              <div className="flex min-w-64 items-end  flex-col gap-1">
                <div>
                  <motion.button
                    onClick={() => {
                      setTimeout(() => {
                        setPinModal(true);
                      }, 200);
                    }}
                    whileTap={{ scale: 0.95 }}
                    className="py-3 px-8 text-xs  text-white rounded bg-primaryColor"
                  >
                    Pay Now
                  </motion.button>
                </div>

                <span
                  className={`${
                    isTimeUnderOneHour ? "text-red" : "text-secondaryColor"
                  } text-sm font-medium`}
                >
                  {timeLeft.days > 0 ||
                  timeLeft.hours > 0 ||
                  timeLeft.minutes > 0 ||
                  timeLeft.seconds > 0 ? (
                    `${timeLeft.days}d:${timeLeft.hours}h:${timeLeft.minutes}m:${timeLeft.seconds}s`
                  ) : isLoading ? (
                    <span className="text-secondaryColor">Fetching...</span>
                  ) : (
                    <span className="text-red">Expired</span>
                  )}
                </span>
              </div>
            </section>

            <section className="flex justify-between">
              <div className="flex flex-col gap-1 w-[40%]">
                <section className="flex items-center justify-between">
                  <span className="text-xs font-semibold">Invoice Number</span>
                  <span className="text-[#7a7a7a] text-xs font-semibold">
                    INV-{invoiceIdData?.data?.invoiceNo}
                  </span>
                </section>

                <section className="flex items-center justify-between">
                  <span className="text-xs font-semibold">Created Date</span>
                  <span className="text-[#7a7a7a] text-xs font-semibold">
                    {invoiceIdData?.data?.createdDate || "N/A"}
                  </span>
                </section>

                <section className="flex items-center justify-between">
                  <span className="text-xs font-semibold">Submitted Date</span>
                  <span className="text-[#7a7a7a] text-xs font-semibold">
                    {invoiceIdData?.data?.submittedDate || "N/A"}
                  </span>
                </section>

                <section className="flex items-center justify-between">
                  <span className="text-xs font-semibold">Approved Date</span>
                  <span className="text-[#7a7a7a] text-xs font-semibold">
                    {invoiceIdData?.data?.approvedDate || "N/A"}
                  </span>
                </section>

                <section className="flex items-center justify-between">
                  <span className="text-xs font-semibold">Due Date</span>
                  <span className="text-[#7a7a7a] text-xs font-semibold">
                    {invoiceIdData?.data?.dueDate || "N/A"}
                  </span>
                </section>

                <section className="flex items-center justify-between">
                  <span className="text-xs font-semibold">Issuance Date</span>
                  <span className="text-[#7a7a7a] text-xs font-semibold">
                    {invoiceIdData?.data?.issuanceDate || "N/A"}
                  </span>
                </section>
              </div>

              <div className="flex items-center text-xs">
                <button
                  style={{ background: "rgba(46, 169, 35, 0.19)" }}
                  className="px-8 py-2 text-[#2EA923] rounded-[5px] "
                >
                  Approved
                </button>
              </div>
            </section>
          </header>

          <section className="flex items-center justify-between">
            <div className="flex flex-col gap-2">
              <span className="text-[18px] font-semibold">
                {" "}
                {formatCapitalize(
                  invoiceIdData?.data?.buyerCompanyName || "N/A"
                ) || "N/A"}
              </span>
              <span className="text-[#7A7A7A] text-xs font-light">
                Buyer Details
              </span>
            </div>

            <div className="flex flex-col gap-2">
              <span className="text-[18px] font-semibold">
                {invoiceIdData?.data?.paymentTerms || "N/A"} Days
              </span>
              <span className="text-[#7A7A7A] text-xs font-light">Payment</span>
            </div>

            <div className="flex flex-col gap-2">
              <span className="text-[18px] flex justify-end font-semibold">
                {invoiceIdData?.data?.currency || "N/A"}
              </span>
              <span className="text-[#7A7A7A] text-xs font-light">
                Currency
              </span>
            </div>
          </section>

          <section className="flex flex-col gap-4">
            <header className="flex items-center justify-between pb-3 border-b-[5px] border-[#F6F5FA] text-[#11192A]">
              <span className="text-sm">Line Items</span>

              <span className="text-[18px] font-light">
                {invoiceIdData?.data?.items?.length} Total Items{" "}
              </span>
            </header>

            <div className=" w-full overflow-x-auto mt-3 pb-4">
              <table className=" w-full overflow-x-auto">
                <thead className="bg-subtleGray w-full p-6  flex-nowrap whitespace-nowrap  ">
                  <tr className=" bg-unusualGray  px-6 w-full flex-nowrap whitespace-nowrap ">
                    {/* <td className="text-[#11192A] p-2.5 text-xs font-bold">
                        Item Number
                      </td> */}
                    <td class="text-[#11192a] text-xs font-semibold  p-4  flex-nowrap whitespace-nowrap ">
                      Item{" "}
                    </td>
                    <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                      Description{" "}
                    </td>
                    <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                      Unit Price
                    </td>
                    <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                      Quantity
                    </td>
                    <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                      Amount
                      <div> (before tax)</div>
                    </td>
                    <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                      Tax Rate
                    </td>
                    <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                      Tax Amount
                    </td>
                    <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                      Total Price
                    </td>
                  </tr>
                </thead>

                <tbody className="  ">
                  {invoiceIdData?.data?.items?.map((cell, idx) => {
                    return (
                      <tr key={idx} className=" w-full ">
                        <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap ">
                          {cell.itemName}
                        </td>
                        <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                          {cell.description}
                        </td>
                        <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                          {formatCurrency(cell.unitPrice)}
                        </td>
                        <td className="text-[#11192a] text-xs font-semibold mt-2 text-center flex-nowrap whitespace-nowrap">
                          {cell.quantity}
                        </td>
                        <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                          {formatCurrency(cell.amount)}
                        </td>
                        <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                          {cell.taxRate}
                        </td>
                        <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                          {formatCurrency(cell.taxAmount)}
                        </td>
                        <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                          {formatCurrency(cell.totalPrice)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className=" px-6   py-5 bg-neutral-50 flex-col justify-center items-start  inline-flex bg-[#fafafa] w-full border-t-[0.2px]  border-t-[#b1a6b2]">
              <div className="lg:flex md:flex justify-between  w-full sm:block ">
                {/* First left side */}
                <div>
                  <section>
                    {" "}
                    <div class="text-[#222222] text-lg  font-medium border-b-[0.2px]  border-[#b1a6b2]">
                      Receiving Funding Account Information
                    </div>
                    {/* <div class="text-[#222222] text-lg font-semibold ">
                        Receiving Funding Account Information
                      </div> */}
                    <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                      {invoiceIdData?.data?.virtualAccount?.accountNumber?.slice(
                        0,
                        2
                      )}
                      *******{" "}
                      {invoiceIdData?.data?.virtualAccount?.accountNumber?.slice(
                        7,
                        11
                      )}
                    </div>
                    <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                      {formatCapitalize(
                        invoiceIdData?.data?.virtualAccount?.accountName
                      )}
                    </div>
                  </section>
                </div>

                {/* second division */}

                <div>
                  {" "}
                  <div class=" flex-col justify-start items-start inline-flex lg:min-w-[290px]">
                    <section className=" w-full px-6 pb-2 border-b-[1px] border-black">
                      <div className=" flex w-full justify-between">
                        <div class="text-[#222222] text-xs font-semibold ">
                          Subtotal
                        </div>
                        <div class=" text-[#222222] text-xs font-semibold ">
                          {formatCurrency(invoiceIdData?.data?.subtotal)}
                        </div>
                      </div>
                      <div className=" flex w-full justify-between pt-3 pb-1 border-b-[0.2px] border-b-[#b1a6b2]/30">
                        <div class="text-[#222222]  text-xs font-medium flex  space-x-9 ">
                          <span>VAT%</span>
                          <span> {invoiceIdData?.data?.vat}%</span>
                        </div>
                        <div class="text-[#222222] text-xs font-medium ">
                          {formatCurrency(invoiceIdData?.data?.vatAmount)}
                        </div>
                      </div>

                      <div className=" flex w-full justify-between pt-3 pb-1 border-b-[0.2px] border-b-[#b1a6b2]/30">
                        <div class="text-[#222222]  text-xs font-medium flex  space-x-9 ">
                          <span>Discount%</span>
                          <span> {invoiceIdData?.data?.discount}%</span>
                        </div>
                        <div class="text-[#222222] text-xs font-medium ">
                          {formatCurrency(invoiceIdData?.data?.discountAmount)}
                        </div>
                      </div>
                    </section>
                    <div className=" flex w-full justify-between py-2 px-6 border-b-[1px] border-b-black">
                      <div class="text-[#222222] text-lg font-semibold ">
                        Grand Total
                      </div>
                      <div class="text-[#222222] text-lg font-semibold ">
                        {formatCurrency(invoiceIdData?.data?.grandTotal)}
                      </div>
                    </div>
                  </div>
                </div>

                {/* stops */}
              </div>
            </div>
          </section>
        </TransitionScale>

        <TransitionScale className="bg-white rounded-[10px] gap-6 w-full lg:w-[40%]">
          <div class="w-full justify-start items-center gap-4 inline-flex ">
            <div class=" justify-start items-center gap-2.5 flex w-full px-6  py-3">
              <div class="text-black text-xl font-bold  mt-5">
                {fundingChannel === "direct-funding-request"
                  ? "Direct Funding"
                  : "Early Payment"}{" "}
                Details
              </div>
            </div>
          </div>

          <div>
            <div className="   pb-4">
              <div class=" justify-between  flex flex-row    px-6 mt-4  ">
                <div className="flex gap-2 py-2.5">
                  <PAIconCocacola />
                  <div className="flex flex-col">
                    {/* <span className=" text-black text-xs font-normal">
                      Coca Cola
                    </span> */}
                    <span class="text-black text-sm font-normal ">
                      {formatCapitalize(
                        fundingChannel === "direct-funding-request"
                          ? getDirectFundingDetailsByIdData?.supplierCompanyName
                          : funderEarlyPaymentViewSingleApprovedDetailsData
                              ?.data?.supplierCompanyName
                      ) || "N/A"}
                    </span>
                    {/* <span className="text-[#979797]  text-xs">Supplier</span> */}
                    <span class="text-[#949494] text-sm font-normal ">
                      Supplier
                    </span>
                  </div>
                </div>
                <div class="py-2.5 flex-col justify-start items-start inline-flex">
                  <div class="flex-col justify-start items-start gap-0.5 flex">
                    <div class="text-black text-sm font-normal ">
                      {" "}
                      {fundingChannel === "direct-funding-request"
                        ? getDirectFundingDetailsByIdData?.discountRate
                        : funderEarlyPaymentViewSingleApprovedDetailsData?.data
                            ?.discountRate || "-"}
                      %
                    </div>
                  </div>
                  <div class="flex-col justify-start items-start  flex">
                    <div class="text-[#949494] text-sm font-normal ">
                      Discount rate
                    </div>
                  </div>
                </div>

                {/* here */}
              </div>
              <div class=" justify-between  flex flex-row    px-6  mt-6 ">
                <div class="py-2.5 flex-col justify-start items-start inline-flex">
                  <div class="flex-col justify-start items-start gap-0.5 flex">
                    <div class="text-black text-sm font-normal ">
                      NGN
                      {formatCurrency(
                        fundingChannel === "direct-funding-request"
                          ? getDirectFundingDetailsByIdData?.discountAmount
                          : funderEarlyPaymentViewSingleApprovedDetailsData
                              ?.data?.discountAmount ?? 0
                      )}
                    </div>
                  </div>
                  <div class="flex-col justify-start items-start  flex">
                    <div class="text-[#949494] text-sm font-normal ">
                      Discount amount
                    </div>
                  </div>
                </div>
                {/* hd */}
                <div class="py-2.5 flex-col justify-start items-start inline-flex">
                  <div class="flex-col justify-start items-start gap-0.5 flex">
                    <div class="text-black text-sm font-normal ">
                      {fundingChannel === "direct-funding-request"
                        ? getDirectFundingDetailsByIdData?.earlyPaymentDate
                        : funderEarlyPaymentViewSingleApprovedDetailsData?.data
                            ?.earlyPaymentDate ?? "N/A"}{" "}
                      (
                      {getDaysLeft(
                        fundingChannel === "direct-funding-request"
                          ? getDirectFundingDetailsByIdData?.earlyPaymentDate
                          : funderEarlyPaymentViewSingleApprovedDetailsData
                              ?.data?.earlyPaymentDate
                      )}{" "}
                      days)
                    </div>
                  </div>
                  <div class="flex-col justify-start items-start  flex">
                    <div class="text-[#949494] text-sm font-normal ">
                      Early Payment Date
                    </div>
                  </div>
                </div>{" "}
              </div>
              <div class=" justify-between  flex flex-row    border-b border-[#c2c2c2]/30 px-6  py-3  pb-8 mt-6">
                <div class="py-2.5 flex-col justify-start items-start inline-flex">
                  <div class="flex-col justify-start items-start gap-0.5 flex">
                    <div class="text-black text-sm font-normal ">
                      N{" "}
                      {formatCurrency(
                        fundingChannel === "direct-funding-request"
                          ? getDirectFundingDetailsByIdData?.requestAmount
                          : funderEarlyPaymentViewSingleApprovedDetailsData
                              ?.data?.requestAmount ?? 0
                      )}
                    </div>
                  </div>
                  <div class="flex-col justify-start items-start  flex">
                    <div class="text-[#949494] text-sm font-normal ">
                      Request amount
                    </div>
                  </div>
                </div>
                {/* dhdh */}
                <div class="py-2.5 flex-col justify-start items-start inline-flex">
                  <div class="flex-col justify-start items-start gap-0.5 flex">
                    <div class="text-black text-sm font-normal ">
                      {formatCurrency(
                        fundingChannel === "direct-funding-request"
                          ? getDirectFundingDetailsByIdData?.fundableAmount
                          : funderEarlyPaymentViewSingleApprovedDetailsData
                              ?.data?.fundableAmount ?? 0
                      )}
                    </div>
                  </div>
                  <div class="flex-col justify-start items-start  flex">
                    <div class="text-[#949494] text-sm font-normal ">
                      Fundable Amount
                    </div>
                  </div>
                </div>
              </div>
              {/* 2nd */}
              <div class=" justify-between  flex flex-col    border-b border-[#c2c2c2]/30 px-6  pb-10 ">
                <div class="text-black text-lg font-semibold  mt-6">
                  Note from Supplier:{" "}
                </div>

                <div class=" text-[#949494] text-sm font-light  mt-4  leading-6">
                  {fundingChannel === "direct-funding-request"
                    ? getDirectFundingDetailsByIdData?.noteFromSupplier
                    : funderEarlyPaymentViewSingleApprovedDetailsData?.data
                        ?.description || "No Message!"}
                  <br className=" mt-3" />
                  Best regards, <br />
                  {formatCapitalize(
                    fundingChannel === "direct-funding-request"
                      ? getDirectFundingDetailsByIdData?.supplierCompanyName
                      : funderEarlyPaymentViewSingleApprovedDetailsData?.data
                          ?.supplierCompanyName
                  ) || "N/A"}
                </div>
              </div>{" "}
              {/* another header */}
              {/* header ends here */}
              {/* here */}
              <div class="justify-between  flex flex-col  px-6">
                <div class="text-black text-lg font-semibold  mt-6">
                  Funding Option
                </div>

                <div class=" text-[#949494] font-light  my-3 leading-6">
                  This invoice would be funded from this virtual account:
                </div>
              </div>
              <div
                style={{ color: "rgba(0, 0, 0, 0.50)" }}
                className="flex flex-col px-6 text-sm font-semibold"
              >
                <span>
                  {formatCapitalize(
                    fundingChannel === "direct-funding-request"
                      ? getDirectFundingDetailsByIdData?.supplierAccountDetails
                          ?.accountName
                      : funderEarlyPaymentViewSingleApprovedDetailsData?.data
                          ?.funderVirtualAccountName
                  )}
                </span>
                <span>
                  {" "}
                  {fundingChannel === "direct-funding-request"
                    ? getDirectFundingDetailsByIdData?.supplierAccountDetails?.accountNumber?.slice(
                        0,
                        2
                      )
                    : funderEarlyPaymentViewSingleApprovedDetailsData?.data?.funderVirtualAccountNumber?.slice(
                        0,
                        2
                      )}
                  *******{" "}
                  {fundingChannel === "direct-funding-request"
                    ? getDirectFundingDetailsByIdData?.supplierAccountDetails?.accountNumber?.slice(
                        7,
                        11
                      )
                    : funderEarlyPaymentViewSingleApprovedDetailsData?.data?.funderVirtualAccountNumber?.slice(
                        7,
                        11
                      )}
                </span>
                <span> PayEdge MFB</span>
              </div>
            </div>
          </div>
        </TransitionScale>
      </main>

      <AnimatePresence>
        <EnterPin
          isOpen={pinModal}
          onClose={() => {
            setPinModal(false);
            setOtpInputs(["", "", "", ""]);
          }}
          hidePin={hidePin}
          otpInputs={otpInputs}
          setOtpInputs={setOtpInputs}
          onSubmit={(pin) => {
            handlePayNow(pin);
          }}
        />
      </AnimatePresence>

      {/* Here */}
      <AnimatePresence>
        {showReceipt && (
          <TransferInfoReceipt
            isOpen={showReceipt}
            disbursement
            onClose={() => {
              setOtpInputs(["", "", "", ""]);
              setPinModal(false);
              setShowReceipt(false);
              navigate(
                `/disbursement/funder/disbursement/transaction-details/${
                  disbursementFunderReceiptData?.data?.transactionId ??
                  disbursementFunderReceiptData?.data?.transactionReference
                }?invId=${
                  disbursementFunderReceiptData?.data?.invoiceId
                }&offerId=${
                  disbursementFunderReceiptData?.data?.offerId
                }&channel=${
                  disbursementFunderReceiptData?.data?.fundingChannel
                }`
              );
            }}
          />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {paymentModal && (
          <ProgressModal
            openMarket
            openMarketText={
              loading ? "Transferring funds..." : "Funds Transferred"
            }
          />
        )}
      </AnimatePresence>
    </DashboardLayout>
  );
};

export default DisbursementDirectFundingDetailsPage;
