import { motion } from "framer-motion";
import { PAIconArrowLeftGreen } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { useNavigate, useParams } from "react-router-dom";
import TransitionScale from "shared/animation/TransitionScale";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { detailsBuyerOpenMarket } from "appstate/openMarket/openMarketSlice";
import DirectFundingInvoice from "./openMarketInvoice";

function CollectionOpenMarketInvoice() {
  const { id } = useParams();
  // const location = useLocation();
  // const searchParams = new URLSearchParams(location.search);
  // const offerId = searchParams.get("offerId");
  // console.log("id: ", id)

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(detailsBuyerOpenMarket({ openMarketReference: id }));
  }, [dispatch, id]);

  const { detailsBuyerOpenMarketData } = useSelector((state) => state?.openMarket);
  // console.log(detailsBuyerOpenMarketData)

  function handleGoBack() {
    navigate(-1);
  }

  return (
    <DashboardLayout
      section="Collections"
      subHeader="Track invoices due for payment collection"
    >
      <TransitionScale className="flex  flex-col gap-6 bg-[#EEE] h-full">
        <header onClick={handleGoBack} className="w-full">
          <div className="flex cursor-pointer w-fit gap-1 items-center">
            <PAIconArrowLeftGreen />
            <span className=" text-primaryColor text-sm">Go back</span>
          </div>
        </header>

        <main className="flex flex-col semi-lg:flex-row pb-8 gap-4 w-full ">
          <DirectFundingInvoice
            invoiceByIdData={detailsBuyerOpenMarketData}
          />

          <motion.section
            initial={{
              y: -20,
            }}
            animate={{
              y: 0,
            }}
            exit={{
              y: 20,
            }}
            transition={{
              delay: 0.8,
            }}
            className="flex flex-col gap-3  pt10 pb-24 w-2/5 bg-white rounded-[10px]"
          >
            <div className="bg-white w-full rounded-[10px] pt-5 pb-24 h-[100%]">
              <div className="h-[15%]">
                <h2 className="text-lg px-6 font-semibold text-gray-800 pb-4 border-b-[1px] border-b-slate-300 text-primaryColor">
                  Bid details for this invoice
                </h2>

                <div className="px-6 py-6 pb-4 border-b-[1px] border-b-slate-300 flex justify-between items-center">
                  <div>
                    <p className="text-gray600 text-xs">Request Amount</p>
                    <span className="text-sm">{detailsBuyerOpenMarketData?.data?.fundingAmount?.toLocaleString()}</span>
                  </div>
                  <div>
                    <p className="text-gray600 text-xs">Receivable Amount</p>
                    <span className="flex justify-end text-sm">{detailsBuyerOpenMarketData?.data?.receivableAmount?.toLocaleString()}</span>
                  </div>
                </div>

                <div className="px-6 py-6 pb-4 border-b-[1px] border-b-slate-300 flex flex-col">
                  <div className="flex justify-between items-center">
                    <div>
                      <p className="text-gray600 text-xs">Payment Date</p>
                      <span className="text-sm">{detailsBuyerOpenMarketData?.data?.paymentDate}</span>
                    </div>
                    <div>
                      <p className="text-gray600 text-xs">Request Date</p>
                      <span className="flex justify-end text-sm">
                        {detailsBuyerOpenMarketData?.data?.fundRequestDate}
                      </span>
                    </div>
                  </div>
                  <div className="flex justify-between items-center mt-2">
                    <div>
                      <p className="text-gray600 text-xs">Lock Rate Amount</p>
                      <span className="text-sm">{detailsBuyerOpenMarketData?.data?.lockRateAmount?.toLocaleString()}</span>
                    </div>
                    <div>
                      <p className="text-gray600 text-xs">Funder's Lock Rate</p>
                      <span className="flex justify-end text-sm">
                       {detailsBuyerOpenMarketData?.data?.lockRate}%{" "}
                        <span className="text-primaryColor">({detailsBuyerOpenMarketData?.data?.discountType || "NIL"})</span>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="px-6 py-6 pb-4 border-b-[1px] border-b-slate-300">
                  <p className="text-xs text-gray600">Bid Status</p>
                  <p className="text-primaryColor">{detailsBuyerOpenMarketData?.data?.bidStatus}</p>
                </div>

                <div className="px-6 py-6 pb-4">
                  <h2 className="font-bold ">Funder Personal Information</h2>
                  <div className="my-3">
                    <p className="text-xs text-gray600">Funder's name</p>
                    <p className="textprimaryColor">{detailsBuyerOpenMarketData?.data?.funderDetails?.funderCompanyName || "NIL"}</p>
                  </div>
                  <div className="flex justify-between items-center">
                    <div>
                      <p className="text-xs text-gray600">Email</p>
                      <p className="text-sm">{detailsBuyerOpenMarketData?.data?.funderDetails?.email || "NIL"}</p>
                    </div>
                    <div>
                      <p className="text-xs text-gray600 flex justify-end">
                        Contact number
                      </p>
                      <p className="text-sm">{detailsBuyerOpenMarketData?.data?.funderDetails?.contactNumber || "NIL"}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </motion.section>
        </main>
      </TransitionScale>
    </DashboardLayout>
  );
}

export default CollectionOpenMarketInvoice;
