import React from "react";
import alertIcon from "../../../../assets/images/svgs/alertIcon.svg";
import Spinner from "shared/Spinner/Spinner";

const InvoiceModalUpload = ({ handleCloseConfirm, handleProceed, info, saveForLaterLoading }) => {
  return (
    <div className="bg-[rgba(0,0,0,0.7)] w-[100vw] h-[100vh] flex justify-center items-center fixed top-0 left-0 z-50">
      <div className="flex flex-col justify-between items-center bg-white rounded-[8px] p-[5%] w-[600px] shadow-lg">
        <img src={alertIcon} alt="Alert Icon" />
        <p className="text-center py-6">
          {info}
        </p>
        <div className="flex items-center">
          <button
            onClick={handleProceed}
            className="border-[#07593D] text-[#07593D] border-[1px] w-[80px] rounded-[4px] h-[45px] mr-[5%]"
          >
             {saveForLaterLoading ? (
                  <div className="flex items-center">
                    <Spinner className="mr-2" />
                    Yes
                  </div>
                ) : (
                  "Yes"
                )}
          </button>
          <button
            onClick={handleCloseConfirm}
            className="border-[#EE0816] text-[#EE0816] border-[1px] w-[80px] rounded-[4px] h-[45px]"
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default InvoiceModalUpload;
