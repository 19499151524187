import React from "react";
import alertIcon from "../../../../../assets/images/svgs/alertIcon.svg";
const ValidatingModal= ({ handleCloseConfirm, handleProceed, message }) => {
  return (
    <div className="bg-[rgba(0,0,0,0.2)] w-[100vw] h-[100vh] flex justify-center items-center absolute top-0 right-2 z-50">
      <div>
        <div className="flex flex-col justify-between items-center bg-white rounded-[8px] p-[5%] w-[600px]">
          <img src={alertIcon} alt="" />
          <p className="text-center py-6">
             {message ? message : "Are you sure you would like to save this invoice to upload later?"}
          </p>
          <div className="flex items-center">
            <button
              onClick={handleProceed}
              className="border-[#07593D] text-[#07593D] border-[1px] w-[80px] rounded-[4px] h-[45px] mr-[5%]"
            >
              Yes
            </button>
            <button
              onClick={handleCloseConfirm}
              className="border-[#EE0816] text-[#EE0816] border-[1px] w-[80px] rounded-[4px] h-[45px]"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValidatingModal;
