import { PAIconSearch } from "assets/images/svgs";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

function BuyerWorkflowConfig() {
  const navigate = useNavigate();
  return (
    <div className="w-full flex flex-col gap-6">
      <section className="w-full flex justify-end items-center gap-2">
        <div className="flex gap-3 border-[0.5px] border-[#CCC]  rounded-[5px] items-center p-2.5 w-full lg:w-[40%]">
          <PAIconSearch />
          <input className="w-full  bg-[#fff] outline-none" type="text" />
        </div>

        <motion.button
          whileTap={{ scale: 0.95 }}
          className="text-white bg-primaryColor px-6 py-3 rounded-[5px] text-sm"
          type="submit"
        >
          Search
        </motion.button>
      </section>

      <main className="w-full max-w-[1200px] px-[3%] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-center items-center gap-6">
        <motion.div
          onClick={() => {
            navigate("/settings/workflow-config/buyer/invoicing");
          }}
          whileHover={{ scale: 1.065 }}
          className="max-w-[268px] cursor-pointer"
        >
          <header
            style={{ border: "0.817px solid rgba(237, 237, 237, 0.79)" }}
            className="pl-4 pr-10 pt-4 pb-8 text-primaryColor rounded-t-[12.255px] font-bold text-xl bg-green-300 w-full "
          >
            Invoicing
          </header>
          <footer
            style={{ border: "0.817px solid rgba(237, 237, 237, 0.79)" }}
            className="pl-4 py-3 text-sm rounded-b-[12.255px]"
          >
            <span className="text-[#959595]">Last Updated: </span>
            {"  "}
            <span>November 20, 2023</span>
          </footer>
        </motion.div>

        <motion.div
          whileHover={{ scale: 1.065 }}
          className="max-w-[268px] cursor-pointer"
        >
          <header
            style={{ border: "0.817px solid rgba(237, 237, 237, 0.79)" }}
            className="pl-4 pr-10 pt-4 pb-8 text-primaryColor rounded-t-[12.255px] font-bold text-xl bg-green-300 w-full "
          >
            Early Payment Request
          </header>
          <footer
            style={{ border: "0.817px solid rgba(237, 237, 237, 0.79)" }}
            className="pl-4 py-3 text-sm rounded-b-[12.255px]"
          >
            <span className="text-[#959595]">Last Updated: </span>
            {"  "}
            <span>November 20, 2023</span>
          </footer>
        </motion.div>

        <motion.div
          whileHover={{ scale: 1.065 }}
          className="max-w-[268px] cursor-pointer"
        >
          <header
            style={{ border: "0.817px solid rgba(237, 237, 237, 0.79)" }}
            className="pl-4 pr-10 pt-4 pb-8 text-primaryColor rounded-t-[12.255px] font-bold text-xl bg-green-300 w-full "
          >
            Open Market
          </header>
          <footer
            style={{ border: "0.817px solid rgba(237, 237, 237, 0.79)" }}
            className="pl-4 py-3 text-sm rounded-b-[12.255px]"
          >
            <span className="text-[#959595]">Last Updated: </span>
            {"  "}
            <span>November 20, 2023</span>
          </footer>
        </motion.div>

        <motion.div
          whileHover={{ scale: 1.065 }}
          className="max-w-[268px] cursor-pointer"
        >
          <header
            style={{ border: "0.817px solid rgba(237, 237, 237, 0.79)" }}
            className="pl-4 pr-10 pt-4 pb-8 text-primaryColor rounded-t-[12.255px] font-bold text-xl bg-green-300 w-full "
          >
            Early Payment Offers
          </header>
          <footer
            style={{ border: "0.817px solid rgba(237, 237, 237, 0.79)" }}
            className="pl-4 py-3 text-sm rounded-b-[12.255px]"
          >
            <span className="text-[#959595]">Last Updated: </span>
            {"  "}
            <span>November 20, 2023</span>
          </footer>
        </motion.div>

        <motion.div
          whileHover={{ scale: 1.065 }}
          className="max-w-[268px] cursor-pointer"
        >
          <header
            style={{ border: "0.817px solid rgba(237, 237, 237, 0.79)" }}
            className="pl-4 pr-10 pt-4 pb-8 text-primaryColor rounded-t-[12.255px] font-bold text-xl bg-green-300 w-full "
          >
            Direct Funding
          </header>
          <footer
            style={{ border: "0.817px solid rgba(237, 237, 237, 0.79)" }}
            className="pl-4 py-3 text-sm rounded-b-[12.255px]"
          >
            <span className="text-[#959595]">Last Updated: </span>
            {"  "}
            <span>November 20, 2023</span>
          </footer>
        </motion.div>
      </main>
    </div>
  );
}
export default BuyerWorkflowConfig;
