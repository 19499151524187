import {
  allPermissionAssigned,
  allRoles,
  assignPermission,
  assignPermissionNew,
  roleById,
} from "appstate/roleManager/roleManagerSlice";
import {
  PAIconCancelRound,
  PAIconCautionRole,
  PAIconCheckMarkGreen,
  PAIconInvoiceIcon,
} from "assets/images/svgs";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import toast, { LoaderIcon } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";

function EditRoleModal({ closeModal, id, data }) {
  const [checkBoxItems, setCheckBoxItems] = useState({});
  const [storeData, setStoreData] = useState([]);
  const [storeAssignedData, setStoreAssignedData] = useState([]);
  const { allPermissionAssignedData, isLoading, roleByIdData } = useSelector(
    (state) => state?.roleManager
  );
  const dispatch = useDispatch();

  const idData = roleByIdData?.data;

  const allPermissionsData = allPermissionAssignedData?.data;

  // Initialize checkbox state and assigned permissions in the store
  useEffect(() => {
    if (allPermissionsData) {
      const assignedPermissions = allPermissionsData?.assignedPermissions || [];
      const initialCheckBoxState = {};

      assignedPermissions.forEach((permission) => {
        initialCheckBoxState[permission.id] = true;
      });

      setCheckBoxItems(initialCheckBoxState);
      setStoreAssignedData(
        assignedPermissions.map((permission) => permission.id)
      );
    }
  }, [allPermissionsData]);

  // Handle checkbox click to update storeData for available permissions
  const handleClick = (event, permissionId) => {
    const checked = event.target.checked;

    setCheckBoxItems((prev) => ({
      ...prev,
      [permissionId]: checked,
    }));

    if (checked) {
      setStoreData((prev) => [...prev, permissionId]);
    } else {
      setStoreData((prev) => prev.filter((id) => id !== permissionId));
    }
  };

  // Handle checkbox click to update storeAssignedData for assigned permissions
  const handleAssignedClick = (event, permissionId) => {
    const checked = event.target.checked;

    setCheckBoxItems((prev) => ({
      ...prev,
      [permissionId]: checked,
    }));

    if (checked) {
      setStoreAssignedData((prev) => [...prev, permissionId]);
    } else {
      setStoreAssignedData((prev) => prev.filter((id) => id !== permissionId));
    }
  };

  // Save the selected permissions
  function handleSave() {
    const pendingToast = toast.loading(
      "Updating your permission preferences..."
    );
    const body = {
      resourceIds:
        allPermissionsData?.assignedPermissions?.length < 1
          ? storeData
          : [...storeAssignedData, ...storeData],
      role: data?.keycloakRoleName,
      roleId: id,
    };

    const specifiedId =
      allPermissionsData?.assignedPermissions?.length < 1
        ? id
        : idData?.permissionId;

    const dispatchAction =
      allPermissionsData?.assignedPermissions?.length < 1
        ? assignPermissionNew
        : assignPermission;

    dispatch(dispatchAction({ body, specifiedId })).then((data) => {
      if (data?.payload?.success) {
        toast.dismiss(pendingToast);
        toast.success("Permissions updated successfully");
        dispatch(roleById(id));
        dispatch(allRoles());
        dispatch(allPermissionAssigned(id));
        closeModal();
      } else {
        return toast.dismiss(pendingToast);
      }
    });
  }

  useEffect(() => {
    dispatch(allPermissionAssigned(id));
  }, [id, dispatch]);

  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
        transition: {
          duration: 0.3,
        },
      }}
      exit={{
        opacity: 0,
        transition: {
          delay: 0.3,
        },
      }}
      onClick={() => closeModal()}
      className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-[9999] flex justify-end items-center"
    >
      <motion.div
        onClick={(e) => e.stopPropagation()}
        initial={{
          x: 20,
          opacity: 0,
          transition: {
            duration: 0.3,
          },
        }}
        animate={{
          x: 0,
          opacity: 1,
          transition: {
            duration: 0.3,
          },
        }}
        exit={{
          x: 20,
          opacity: 0,
          transition: {
            duration: 0.3,
          },
        }}
        className={`bg-white w-full md:w-[65%] lg:w-[45%] h-full overflow-auto  z-[9999]`}
      >
        <motion.div>
          <motion.div
            exit={{
              x: 20,
              opacity: 0,
              transition: {
                duration: 0.3,
              },
            }}
            className="z-[9999]   h-full flex flex-col justify-start w-full gap-8 pt-[3%] pb-[6%] px-[4%] "
          >
            <div className=" flex flex-col gap-6">
              <header className="w-full sticky bg-white top-0 z-[999999] flex py-[32px] justify-between">
                <span className="flex items-center font-bold ">
                  {allPermissionsData?.assignedPermissions.length < 1
                    ? "Assign "
                    : "Edit "}
                  Permissions
                </span>
                <span
                  onClick={() => closeModal()}
                  className="cursor-pointer gap-2 flex items-center justify-center "
                >
                  <PAIconCancelRound />
                </span>
              </header>

              <main className="h-full overflow-auto">
                <div className="w-full h-4/5 rounded overflow-auto ">
                  <div className="overflow-auto flex flex-col gap-8">
                    <div className=" w-full ">
                      <p className=" text-[#7A7A7A] text-xs">
                        Assign a permission to a role by selecting the
                        permission from the Available Permission” section and it
                        would be moved to the “Assigned Permission”
                      </p>
                    </div>

                    <div className=" w-full flex justify-between ">
                      <div className="flex w-[48%]   flex-col gap-3">
                        <span>
                          <h1 className=" text-sm font-bold text-[#565656]">
                            Available Permissions
                          </h1>
                        </span>

                        <section className="flex py-7 px-3 rounded-sm h-full bg-[#F9F9F9] flex-col gap-3">
                          {allPermissionsData?.availablePermissions?.length >
                          0 ? (
                            <>
                              {allPermissionsData?.availablePermissions?.map(
                                (item) => (
                                  <div key={item.id}>
                                    <label className="flex border-t border-b border-[#DADADA] gap-2 px-2 py-2 cursor-pointer">
                                      <input
                                        type="checkbox"
                                        checked={checkBoxItems[item.id]}
                                        onChange={(e) =>
                                          handleClick(e, item.id)
                                        }
                                        value={""}
                                      />
                                      <p className="text-sm font-bold text-black">
                                        {item.name}
                                      </p>
                                    </label>
                                  </div>
                                )
                              )}
                            </>
                          ) : (
                            <div className="text-xs h-full text-center font-bold flex items-center justify-center flex-col">
                              All available permissions is assigned
                              <PAIconCheckMarkGreen />
                            </div>
                          )}
                        </section>
                      </div>

                      <div className="flex w-[48%]  flex-col gap-3">
                        <span>
                          <h1 className=" text-sm font-bold text-[#565656]">
                            Assigned Permissions
                          </h1>
                        </span>
                        <section className="flex py-7 px-3 rounded-sm bg-[#F9F9F9] h-full flex-col gap-3">
                          {allPermissionsData?.assignedPermissions?.length >
                          0 ? (
                            <>
                              {allPermissionsData?.assignedPermissions?.map(
                                (item) => (
                                  <div key={item.id}>
                                    <label className="flex border-t border-b border-[#DADADA] gap-2 px-2 py-2 cursor-pointer">
                                      <input
                                        type="checkbox"
                                        checked={checkBoxItems[item.id]}
                                        onChange={(e) =>
                                          handleAssignedClick(e, item.id)
                                        }
                                        value={""}
                                      />
                                      <p className="text-sm font-bold text-black">
                                        {item.name}
                                      </p>
                                    </label>
                                  </div>
                                )
                              )}
                            </>
                          ) : (
                            <div className="flex items-center gap-3 flex-col">
                              <div
                                className="text-sm flex justify-center font-semibold
                            "
                              >
                                No Assigned Permissions
                              </div>
                              <PAIconInvoiceIcon />

                              <div className="flex items-center gap-1 text-xs">
                                <PAIconCautionRole />
                                <span className="text-[#7A7A7A]">
                                  Kindly Assign some permissions to this role{" "}
                                </span>
                              </div>
                            </div>
                          )}
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </main>

              <footer className=" text-white flex justify-end ">
                <button
                  disabled={isLoading}
                  onClick={() => handleSave()}
                  className=" px-4 py-2 bg-[#07593D] text-sm rounded-[5px] border-none cursor-pointer"
                >
                  {isLoading ? <LoaderIcon /> : "Save"}
                </button>
              </footer>
            </div>
          </motion.div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
}
export default EditRoleModal;
