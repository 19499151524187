import { PAIconCancelMini } from "assets/images/svgs";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { formatCapitalize } from "utilities/formatCapitalize";
import { formatCurrency } from "utilities/helper/formatters";

const MultipleTransferReceipt = ({ onClose, setActiveTab }) => {
  const data = useSelector((state) => state.selectedData.selectedData);
  let totalFundableAmount =
    data?.reduce((total, invoice) => {
      return total + (invoice?.fundableAmount ?? 0);
    }, 0) || 0;
  return (
    <motion.div
      className="fixed inset-0 flex items-center justify-center z-50"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, scale: 0.9, y: -30 }}
      transition={{ duration: 0.4, ease: "easeInOut" }}
    >
      {/* Overlay */}
      <motion.div
        className="absolute inset-0 bg-black bg-opacity-50"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3, ease: "easeOut" }}
        onClick={onClose}
      />

      {/* Modal Content */}
      <motion.div
        className="bg-white rounded-lg shadow-xl py-8 px-6 max-w-[650px] h-[80vh] w-full z-[9999999999] flex flex-col gap-8"
        initial={{ scale: 0.8, opacity: 0, y: 50 }}
        animate={{ scale: 1, opacity: 1, y: 0 }}
        exit={{ scale: 0.9, opacity: 0, y: -30 }}
        transition={{ duration: 0.4, ease: "easeInOut" }}
      >
        <header className="flex justify-between items-center">
          <div className="flex flex-col">
            <span className="text-2xl font-semibold">Invoice Disbursement</span>
            <span className="text-[#444] font-thin">
              List of invoices successfully disbursed.
            </span>
          </div>

          <div>
            <button
              className="flex gap-2 items-center justify-end top-3 right-3 text-gray-600 hover:text-gray-800"
              onClick={() => {
                setTimeout(() => onClose(), 450);
              }}
            >
              <PAIconCancelMini />
              <span className="text-[#565656] text-sm">Close</span>
            </button>
          </div>
        </header>

        <section className="p-2.5 flex justify-between">
          <div className="flex flex-col">
            <div>
              <span className="font-medium text-primaryColor">NGN</span>
              <span className="font-semibold text-[32px] text-primaryColor">
                {" "}
                {formatCurrency(totalFundableAmount)}
              </span>
            </div>
            <span className="text-[#7a7a7a] font-semibold">
              Total Liened Amount
            </span>
          </div>

          <div className="flex flex-col items-center">
            <div>
              <span className="font-semibold text-[32px] text-primaryColor">
                {" "}
                {data?.length}
              </span>
            </div>
            <span className="text-[#7a7a7a] font-semibold">
              Total Liened Payables
            </span>
          </div>
        </section>

        <section className="p-2.5 flex flex-col gap-7">
          <header className="w-full h-[43px] items-center border-b-[5px] border-[#F6F5FA] flex justify-between">
            <span className="font-medium">Funder Single Invoice details</span>

            <span
              onClick={() => {
                setTimeout(() => {
                  onClose();
                  setActiveTab(6);
                  sessionStorage.setItem("activeTabDisbursementFunder", 6);
                }, 450);
              }}
              className="underline cursor-pointer font-semibold text-sm text-[#3e0445]"
            >
              View Receipts
            </span>
          </header>

          <section className="overflow-x-auto max-h-[30vh] bg-white rounded-t-lg">
            <table className="min-w-full overflow-scroll rounded-t-lg shadow-md rounded-lg">
              <thead className="rounded-t-lg sticky top-0">
                <tr className="bg-[#EFEFEF]">
                  <th className="p-2.5 text-left text-xs">Invoice Reference</th>
                  <th className="p-2.5 text-left text-xs">Beneficiary Name</th>
                  <th className="p-2.5 text-left text-xs">Fundable Amount</th>
                  <th className="p-2.5 text-left text-xs">Payment Date</th>
                </tr>
              </thead>
              <tbody className="overflow-scroll min-w-full">
                {data?.map((row) => (
                  <tr className="text-sm text-[#11192A] py-2.5 my-2">
                    <td className="p-2.5 w-1/4 text-xs whitespace-nowrap">
                      {row?.invoiceRef?.slice(0, 15) ??
                        row?.requestRefNo?.slice(0, 15) ??
                        "N/A"}
                      ...
                    </td>
                    <td className="p-2.5 font-medium text-xs">
                      <span className="flex items-center gap-0.5">
                        <span>
                          {formatCapitalize(row?.supplierName) ?? "N/A"}
                        </span>
                        <span className="text-[10px] text-secondaryColor">
                          {" "}
                          (Supplier)
                        </span>
                      </span>
                    </td>
                    <td className="p-2.5 text-xs">
                      {formatCurrency(row?.fundableAmount)}
                    </td>
                    <td className="p-2.5 text-xs">{row.paymentDate}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </section>
        </section>
      </motion.div>
    </motion.div>
  );
};

export default MultipleTransferReceipt;
