import { PAIconInventoryArrowLeft } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Card from "shared/Card";
import { motion } from "framer-motion";
import CocaCola from "../../assets/images/svgs/cocacola.svg";
import { useEffect, useState } from "react";
import {
  getAllInvoiceById,
  getSingleEarlyPaymentDetails,
} from "appstate/api/invoice/invoice";
import { formatCurrency } from "utilities/helper/formatters";

const SupplierSingleEarlyPaymentDetailsRejected = () => {
  const { id } = useParams();
  // eslint-disable-next-line
  const [earlyPaymentData, setEarlyPaymentData] = useState(null);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line
  const [error, setError] = useState(null);
  const [invoiceData, setInvoiceData] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { invoiceId } = location.state || {};

  useEffect(() => {
    const fetchInvoice = async () => {
      if (invoiceId) {
        const result = await getAllInvoiceById({ id: invoiceId });
        setInvoiceData(result?.data);
      }
    };
    fetchInvoice();
    // eslint-disable-next-line
  }, [invoiceId]);

  useEffect(() => {
    const fetchEarlyPaymentDetails = async () => {
      try {
        const result = await getSingleEarlyPaymentDetails(id);
        setEarlyPaymentData(result?.data);
      } catch (error) {
        setError(error.message || "Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchEarlyPaymentDetails();
    }
  }, [id]);

  function handleNavigate() {
    let path = -1;
    navigate(path);
  }

  return (
    <DashboardLayout
      section="Invoice"
      subHeader="Here is an overview of your invoices"
    >
      <header>
        <div
          onClick={handleNavigate}
          className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
        >
          <PAIconInventoryArrowLeft />
          <p className="mt-[2px] text-sm font-normal text-lightGray">Go Back</p>
        </div>
      </header>
      <div className="flex justify-between">
        <Card className="w-[60%] p-[24px] rounded-[10px]">
          <div className=" flex  flex-col gap-3 ">
            <main className=" w-full flex justify-between">
              <div className="flex flex-col gap-3 w-1/2">
                <div className="flex">
                  <span>Reference No:</span>
                  <span className="pl-2">|{invoiceData?.invoiceRef}</span>
                </div>
                <div className="flex flex-col gap-1">
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Invoice Number:</span>
                    <span className="text-[#979797]  text-xs">
                      {`INV-${invoiceData?.invoiceNo}`}
                    </span>
                  </div>
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Created Date:</span>
                    <span className="text-[#979797]  text-xs">
                      {invoiceData?.createdDate}
                    </span>
                  </div>
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Submitted Date:</span>
                    <span className="text-[#979797]  text-xs">
                      {invoiceData?.submittedDate}
                    </span>
                  </div>
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Approved Date:</span>
                    <span className="text-[#979797]  text-xs">
                      {invoiceData?.approvedDate}
                    </span>
                  </div>
                  <div className="flex gap-x-[97px]">
                    <span className=" text-xs">Due Date:</span>
                    <span className="text-[#979797]  text-xs">
                      {invoiceData?.dueDate}
                    </span>
                  </div>
                </div>
              </div>

              <div className="flex flex-col justify-between">
                <motion.button
                  whileTap={{ scale: 0.9 }}
                  className="flex bg-[#FAD9D9] text-sm justify-center gap-2 text-[#DE1515] rounded-[5px] items-center px-[35px] py-1.5 "
                >
                  Rejected
                </motion.button>
              </div>
            </main>
          </div>
          <br />
          <div className="flex justify-between">
            <div className="flex flex-col">
              <span className="font-light  text-xs">
                {invoiceData?.buyerName}
              </span>
              <span className="text-[#979797] text-sm">buyer details</span>
            </div>
            <div className="flex flex-col">
              <span className="font-light   text-xs">
                {invoiceData?.paymentTerms} Days
              </span>
              <span className="text-[#979797] text-sm">Payment Terms</span>
            </div>
            <div className="flex flex-col">
              <span className="font-light text-sm">Currency</span>
              <span className="text-[#979797]  text-xs">
                {invoiceData?.currency}
              </span>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <header className="border-b-[5px] border-b-[#F6F5FA] pb-3"></header>
            <div className="flex  justify-between">
              <div className="flex gap-2"></div>
              <div className="flex gap-2">
                <div className="flex flex-col">
                  <span className="text-[#979797]  text-xs">
                    {invoiceData?.items.length} total items
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
              <h1 className="text-sm">Line Items</h1>
            </header>
            <div className=" w-full overflow-x-auto">
              <table className=" w-full overflow-x-auto">
                <thead className="bg-subtleGray w-">
                  <tr className=" bg-unusualGray p-2.5 ">
                    <td className="text-[#11192A] p-2.5 text-xs font-bold">
                      Item
                    </td>
                    <td className=" p-2.5 text-xs font-bold">Description</td>
                    <td className=" p-2.5 text-xs font-bold">Unit price</td>
                    <td className=" p-2.5 text-xs font-bold">Quantity</td>
                    <td className=" p-2.5 text-xs font-bold">
                      {"Amount(Before Tax)"}
                    </td>
                    <td className=" p-2.5 text-xs font-bold">Tax Rate</td>
                    <td className=" p-2.5 text-xs font-bold">Tax Amount</td>
                    <td className=" p-2.5 text-xs font-bold">Total Price</td>
                  </tr>
                </thead>
                {invoiceData?.items?.map((item, index) => (
                  <tbody key={index}>
                    <tr className=" py-[3px] px-1.5">
                      <td className=" text-xs text-[#11192A] p-2.5">
                        {item?.itemName}
                      </td>
                      <td className=" text-xs text-[#11192A] p-2.5">
                        {item?.description}
                      </td>
                      <td className=" text-xs text-[#11192A] p-2.5">
                        {`${formatCurrency(Number(item?.unitPrice))}`}
                      </td>
                      <td className=" text-xs text-[#11192A] p-2.5">
                        {`${formatCurrency(Number(item?.quantity))}`}
                      </td>
                      <td className=" text-xs text-[#11192A] p-2.5">
                        {`${formatCurrency(Number(item?.unitPrice))}`}
                      </td>
                      <td className=" text-xs text-[#11192A] p-2.5">
                        {item?.taxRate}
                      </td>
                      <td className=" text-xs text-[#11192A] p-2.5">
                        {`${formatCurrency(Number(item?.taxAmount))}`}
                      </td>
                      <td className=" text-xs text-[#11192A] p-2.5">
                        {`${formatCurrency(Number(item?.totalPrice))}`}
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
            <div className="flex justify-between">
              <div>
                <h4 className="text-sm text-[#11192A] ">
                  Receiving Funding Account Information
                </h4>
                <h5 className="text-sm text-[#11192A] ">
                  {invoiceData?.virtualAccount?.accountNumber}
                </h5>
                <h5 className="text-sm text-[#11192A] ">
                  {invoiceData?.virtualAccount?.accountName}
                </h5>
              </div>
              <div style={{ width: 300 }}>
                <div className="flex justify-between">
                  <span className="text-sm text-[#11192A] ">Subtotal</span>
                  <span className="text-sm text-[#11192A] ">
                    {`${formatCurrency(Number(invoiceData?.subTotal))}`}
                  </span>
                </div>
                <div className="flex justify-between">
                  <div>
                    <span className="text-sm text-[#11192A] ">VAT</span>
                    <span className="pl-2 text-sm text-[#11192A] ">
                      {invoiceData?.vat}%
                    </span>
                  </div>
                  <div>
                    <span className="text-sm text-[#11192A] ">
                      {`${formatCurrency(Number(invoiceData?.vatAmount))}`}
                    </span>
                  </div>
                </div>
                <div className="flex justify-between">
                  <div>
                    <span className="text-sm text-[#11192A] ">Discount % </span>
                    <span className="pl-2 text-sm text-[#11192A] ">
                      {invoiceData?.discount}%
                    </span>
                  </div>
                  <div>
                    <span className="text-sm text-[#11192A] ">
                      {`${formatCurrency(Number(invoiceData?.discountAmount))}`}
                    </span>
                  </div>
                </div>
                <div>
                  <hr className="border-t border-[black] mt-2 pt-2" />
                  <div className="flex justify-between">
                    <span className="text-lg text-[#11192A] ">Grand Total</span>
                    <span className="text-lg text-[#11192A] ">
                      {`${formatCurrency(Number(invoiceData?.grandTotal))}`}
                    </span>
                  </div>
                  <hr className="border-t border-[black] mt-2 pt-2" />
                </div>
              </div>
            </div>
          </div>
        </Card>
        <Card className="w-[38%] p-[24px] rounded-[10px]">
          <div>
            <div className="flex justify-between">
              <span className="font-bold text-[#000000]">
                Early Payment Details
              </span>
            </div>
          </div>
          <div>
            <br />
            <div className="flex justify-between">
              <div className="flex">
                <div>
                  <img src={CocaCola} alt="" />
                </div>
                <div style={{ lineHeight: 1 }} className="flex flex-col pl-2">
                  <span>{earlyPaymentData?.buyerCompanyName}</span>
                  <span className="text-[#959595]">buyer</span>
                </div>
              </div>
              <div>
                <div style={{ lineHeight: 1 }} className="flex flex-col pl-2">
                  <span className="flex justify-end">
                    {earlyPaymentData?.discountRate}%
                  </span>
                  <span className="text-[#959595]">Discount rate</span>
                </div>
              </div>
            </div>
            <br />
            <div className="flex justify-between">
              <div style={{ lineHeight: 1.3 }} className="flex flex-col pl-2">
                <span>{invoiceData?.currency + " "} {`${formatCurrency(Number(earlyPaymentData?.discountAmount))}`}</span>
                <span className="text-[#959595]">Discount Amount</span>
              </div>
              <div>
                <div style={{ lineHeight: 1.3 }} className="flex flex-col pl-2">
                  <span>
                    {earlyPaymentData?.invoiceDueDate}(
                    {earlyPaymentData?.paymentDays}Days)
                  </span>
                  <span className="flex justify-end text-[#959595]">
                    Early Payment Date
                  </span>
                </div>
              </div>
            </div>
            <br />
            <div className="flex justify-between">
              <div style={{ lineHeight: 1.3 }} className="flex flex-col pl-2">
                <span>Request Amount</span>
                <span className="text-[#959595]">
                {invoiceData?.currency + " "} {`${formatCurrency(Number(earlyPaymentData?.requestAmount))}`}
                </span>
              </div>
              <div>
                <div style={{ lineHeight: 1.3 }} className="flex flex-col pl-2">
                  <span className="flex justify-end">
                    {invoiceData?.currency + " "} {`${formatCurrency(Number(earlyPaymentData?.receivableAmount))}`}
                  </span>
                  <span className="text-[#959595]">Receivable Amount</span>
                </div>
              </div>
            </div>
          </div>
          <br />
          <hr className="text-[#959595]" />
          <br />
          <div className="flex flex-col">
            <span className="font-bold text-[#000000]">
              Notes from supplier:
            </span>
            <span className="text-[#959595]">
              
            </span>
          </div>
          <br />
          <hr className="text-[#959595]" />
          <br />
          <div className="flex flex-col">
            <span className="font-bold text-[#000000]">
              Reason for rejection
            </span>
            <span className="text-[#959595]">
              
            </span>
          </div>
        </Card>
      </div>
    </DashboardLayout>
  );
};
export default SupplierSingleEarlyPaymentDetailsRejected;
