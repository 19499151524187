import {
  PAIconInventoryArrowLeft,
  PAIconSmallExportDownloadIcon,
} from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Card from "shared/Card";
import { motion } from "framer-motion";
// import Download from "../../../../assets/images/svgs/downloadcsv.svg";
import { useEffect, useState } from "react";
import {
  getAllInvoiceById,
  getBulkEarlyPaymentDetails,
} from "appstate/api/invoice/invoice";

const ReimbursementTransactionHistoryDetailsPageBulk = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { invoiceId, transactionType, fundingType, factoringType, channel } =
    location.state || {};
  // console.log("===states===", factoringType, channel);

  const [, setInvoiceData] = useState(null);
  // eslint-disable-next-line
  const [, setError] = useState(null);
  // eslint-disable-next-line
  const [, setEarlyPaymentData] = useState(null);
  // eslint-disable-next-line
  const [l, setLoading] = useState(true);

  useEffect(() => {
    const fetchInvoice = async () => {
      if (invoiceId) {
        const result = await getAllInvoiceById({ id: invoiceId });
        setInvoiceData(result?.data);
      }
    };
    fetchInvoice();
    // eslint-disable-next-line
  }, [invoiceId]);

  useEffect(() => {
    const fetchEarlyPaymentDetails = async () => {
      try {
        const result = await getBulkEarlyPaymentDetails(id);
        setEarlyPaymentData(result?.data);
      } catch (error) {
        setError(error.message || "Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchEarlyPaymentDetails();
    }
  }, [id]);

  function handleNavigate() {
    let path = -1;
    navigate(path);
  }
  const invData = [
    {
      id: "1",
      invoiceNum: "INV-2090",
      dueDate: "2023-05-23",
      requestAmount: "9,000,00",
      discountRate: "1.5%",
      receivableAmount: "9,000,000",
    },
    {
      id: "2",
      invoiceNum: "INV-2090",
      dueDate: "2023-05-23",
      requestAmount: "9,000,00",
      discountRate: "1.5%",
      receivableAmount: "9,000,000",
    },
    {
      id: "3",
      invoiceNum: "INV-2090",
      dueDate: "2023-05-23",
      requestAmount: "9,000,00",
      discountRate: "1.5%",
      receivableAmount: "9,000,000",
    },
  ];

  return (
    <DashboardLayout
      section="Reimbursement"
      subHeader="Track invoices due for payment Reimbursement"
    >
      <header>
        <div
          onClick={handleNavigate}
          className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer "
        >
          <PAIconInventoryArrowLeft />
          <p className="mt-[2px] text-sm font-normal text-lightGray">Go Back</p>
        </div>
      </header>
      <div className="flex justify-between">
        <Card className="w-[60%] p-[24px] rounded-[10px]">
          <div className=" flex  flex-col gap-3 ">
            <main className=" w-full flex justify-between">
              <div className="flex flex-col gap-3 w-1/2">
                <div className="flex">
                  <span>Reference No:</span>
                  <span className="pl-2">|RF9101010BHSHS</span>
                </div>
                <div className="flex flex-col gap-1">
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Invoice Number:</span>
                    <span className="text-[#979797]  text-xs">INV-2002</span>
                  </div>
                  {/* <div className="flex gap-x-[50px]">
                      <span className=" text-xs w-[97px]">Invoice Number:</span>
                      <span className="text-[#979797]  text-xs">INV 2002</span>
                    </div> */}
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Created Date:</span>
                    <span className="text-[#979797]  text-xs">
                      23-March-2023
                    </span>
                  </div>

                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Submitted Date:</span>
                    <span className="text-[#979797]  text-xs">
                      23-March-2023
                    </span>
                  </div>
                  <div className="flex gap-x-[70px]">
                    <span className=" text-xs">Approved Date:</span>
                    <span className="text-[#979797]  text-xs">
                      23-March-2023
                    </span>
                  </div>
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Paid Date:</span>
                    <span className="text-[#979797]  text-xs">
                      23-March-2023
                    </span>
                  </div>
                  <div className="flex gap-x-[98px]">
                    <span className=" text-xs">Due Date:</span>
                    <span className="text-[#979797]  text-xs">
                      23-March-2023
                    </span>
                  </div>
                </div>
              </div>

              <div className="flex flex-col mt-16">
                <motion.button
                  whileTap={{ scale: 0.9 }}
                  className="flex bg-white py-2 text-sm justify-center gap-2 text-primaryColor border border-primaryColor rounded-[5px] items-center px-[35px]"
                >
                  Reimbursed
                </motion.button>
              </div>
            </main>
          </div>
          <br />
          <div className="flex justify-between">
            <div className="flex flex-col">
              <span className="font-light  text-xs">Nestle Plc</span>
              <span className="text-[#979797] text-sm">buyer details</span>
            </div>
            <div className="flex flex-col">
              <span className="font-light   text-xs">60 Days</span>
              <span className="text-[#979797] text-sm">Payment</span>
            </div>
            {transactionType === "Loan Reimbursed" && (
              <div className="flex flex-col">
                <span style={{ color: "red" }} className="font-light   text-xs">
                  +5%
                </span>
                <span className="text-[#979797] text-sm">
                  Total loan interest
                </span>
              </div>
            )}
            <div className="flex flex-col">
              <span className="text-[#979797] flex justify-end text-xs">
                NGN
              </span>
              <span className="font-light text-sm">Currency</span>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <header className="border-b-[5px] border-b-[#F6F5FA] pb-3"></header>
            <div className="flex  justify-between">
              <div className="flex gap-2"></div>
              <div className="flex gap-2">
                <div className="flex flex-col">
                  <span className="text-[#979797]  text-xs">
                    5 Total List Items
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
              <h1 className="text-sm">Line Items</h1>
            </header>

            <div className=" w-full overflow-x-auto">
              <table className=" w-full overflow-x-auto">
                <thead className="bg-subtleGray w-">
                  <tr className="bg-unusualGray p-2.5 ">
                    <td className="text-[#11192A] p-2.5 text-xs font-bold">
                      Invoice Number
                    </td>
                    <td className="p-2.5 text-xs font-bold">Due Date</td>
                    <td className="p-2.5 text-xs font-bold">Request Amount</td>
                    <td className="p-2.5 text-xs font-bold">Discount Rate</td>

                    <td className="p-2.5 text-xs font-bold">
                      Receivable Amount
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {invData?.map((item) => (
                    <tr
                      key={item.id}
                      className=" py-[4px] px-1.5 cursor-pointer border-b-[0.3px] border-gray"
                      onClick={() =>
                        navigate(
                          `/reimbursement-transaction-history-bulk-inner-details/${item.id}`
                        )
                      }
                    >
                      <td className=" text-xs text-[#11192a] p-3">
                        {item?.invoiceNum}
                      </td>
                      <td className=" text-xs text-[#11192a] p-2.5">
                        {item?.dueDate}
                      </td>
                      <td className=" text-xs text-[#11192a] p-2.5">
                        {item?.requestAmount}
                      </td>
                      <td className=" text-xs text-[#11192a] p-2.5">
                        {item?.discountRate}
                      </td>

                      <td className=" text-xs text-[#11192a] p-2.5">
                        {item?.receivableAmount}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex justify-between">
              <div>
                <h4
                  style={{ fontSize: 13 }}
                  className="text-lg text-[#11192A] "
                >
                  Receiving Funding Account Information
                </h4>
                <h5
                  style={{ fontSize: 13 }}
                  className="text-lg text-[#11192A] "
                >
                  7662662662626
                </h5>
                <h5
                  style={{ fontSize: 13 }}
                  className="text-lg text-[#11192A] "
                >
                  Praise Akindade
                </h5>
              </div>
              <div style={{ width: 300 }}>
                <div className="flex justify-between">
                  <span
                    style={{ fontSize: 13 }}
                    className="text-sm text-[#11192A] "
                  >
                    Subtotal
                  </span>
                  <span className="text-sm text-[#11192A] ">1000000</span>
                </div>
                <div className="flex justify-between">
                  <div>
                    <span
                      style={{ fontSize: 13 }}
                      className="text-sm text-[#11192A] "
                    >
                      Vat %{" "}
                    </span>
                    <span className="pl-2 text-sm text-[#11192A] ">5.6%</span>
                  </div>
                  <div>
                    <span
                      style={{ fontSize: 13 }}
                      className="text-sm text-[#11192A] "
                    >
                      583,000
                    </span>
                  </div>
                </div>
                <div className="flex justify-between">
                  <div>
                    <span
                      style={{ fontSize: 13 }}
                      className="text-sm text-[#11192A] "
                    >
                      Discount %{" "}
                    </span>
                    <span
                      style={{ fontSize: 13 }}
                      className="pl-2 text-sm text-[#11192A] "
                    >
                      2.6%
                    </span>
                  </div>
                  <div>
                    <span
                      style={{ fontSize: 13 }}
                      className="text-sm text-[#11192A] "
                    >
                      283,000
                    </span>
                  </div>
                </div>
                <div>
                  <hr className="border-t border-[black] mt-2 pt-2" />
                  <div className="flex justify-between">
                    <span
                      style={{ fontSize: 13 }}
                      className="text-lg text-[#11192A] "
                    >
                      Grand Total
                    </span>
                    <span
                      style={{ fontSize: 13 }}
                      className="text-lg text-[#11192A] "
                    >
                      10,000,000
                    </span>
                  </div>
                  <hr className="border-t border-[black] mt-2 pt-2" />
                </div>
              </div>
            </div>
          </div>
        </Card>
        <Card className="w-[38%] p-[24px] rounded-[10px]">
          <div>
            <div className="flex justify-between">
              <div className="flex flex-col">
                <span className="font-bold text-[#000000]">
                  {channel} Transaction Details
                </span>
                <span style={{ color: "grey" }} className="">
                  {factoringType}
                </span>
              </div>
              {/* {transactionType === "Balance Payment" && (
                  <div className="flex flex-col">
                    <span className="font-bold text-[#000000]">
                      Balance Payment Transaction Details
                    </span>
                    <span style={{ color: "grey" }} className="">
                      Without Recourse
                    </span>
                  </div>
                )}
  
                {transactionType === "Reimbursed" && (
                  <div className="flex flex-col">
                    <span className="font-bold text-[#000000]">
                      Direct Funding Details
                    </span>
                    <span style={{ color: "grey" }} className="">
                      With Recourse
                    </span>
                  </div>
                )}
  
                {transactionType === "Loan Reimbursed" && (
                  <div className="flex flex-col">
                    <span className="font-bold text-[#000000]">
                      Direct Funding Details
                    </span>
                    <span style={{ color: "grey" }} className="">
                      With Recourse
                    </span>
                  </div>
                )} */}

              <motion.button
                whileTap={{ scale: 0.9 }}
                className="flex bg-white border border-lightGray border-opacity-20 text-sm text-grey px-4 rounded-[5px] items-center gap-2"
              >
                Export
                <PAIconSmallExportDownloadIcon className="w-5 h-5 text-lightGray" />
              </motion.button>
            </div>
          </div>
          <div>
            <br />
            <div>
              <div>
                {/* icon goes here */}
                <span>Amount</span>
              </div>
              <div>
                <span style={{ color: "red" }}>
                  NGN{" "}
                  <span style={{ color: "red", fontSize: 22, fontWeight: 500 }}>
                    -9,000,000
                  </span>
                </span>
              </div>
            </div>
          </div>
          <br />
          <hr className="text-[#959595]" />
          <br />
          <div>
            <div>
              <div className="flex justify-between mt-4">
                <div className="flex flex-col">
                  <span style={{ color: "black", fontWeight: 500 }}>
                    Transfer From
                  </span>
                </div>
                <div className="flex flex-row">
                  <div className="bg-orange-500 text-white flex items-center justify-center w-8 h-8 rounded-full">
                    <span>xyz</span>
                  </div>

                  <div className="flex flex-col pl-2">
                    <span style={{ color: "grey" }}>XYZ Ventures</span>
                    <span
                      style={{
                        color: "grey",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      Buyer
                    </span>
                  </div>
                </div>
              </div>

              <div className="flex justify-between mt-4">
                <div className="flex flex-col">
                  <span style={{ color: "black", fontWeight: 500 }}>
                    Transfer To
                  </span>
                </div>
                <div className="flex flex-row">
                  <div className="bg-primaryColor text-white flex items-center justify-center w-8 h-8 rounded-full">
                    <span>AS</span>
                  </div>

                  <div className="flex flex-col pl-2">
                    <span style={{ color: "grey" }}>Agba Supplies</span>
                    <span
                      className=""
                      style={{
                        color: "#06593D",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      Supplier
                    </span>
                  </div>
                </div>
              </div>

              <div className="flex justify-between mt-4">
                <div className="flex flex-col">
                  <span style={{ color: "black", fontWeight: 500 }}>
                    Status
                  </span>
                </div>
                <div className="flex flex-col">
                  <span style={{ color: "#06593D" }}>Successful</span>
                </div>
              </div>

              <div className="flex justify-between mt-4">
                <div className="flex flex-col">
                  <span style={{ color: "black", fontWeight: 500 }}>
                    Payment Date
                  </span>
                </div>
                <div className="flex flex-col">
                  <span style={{ color: "grey" }}>Oct 21, 2024 | 02:43PM</span>
                </div>
              </div>

              <div className="flex justify-between mt-4">
                <div className="flex flex-col">
                  <span style={{ color: "black", fontWeight: 500 }}>
                    Transaction ID
                  </span>
                </div>
                <div className="flex flex-col">
                  <span style={{ color: "#0077EC" }}>
                    YWYWYWYYHSHSHSHSHHSHSH
                  </span>
                </div>
              </div>

              <div className="flex justify-between mt-4 w-[100%]">
                <div className="flex flex-col">
                  <span style={{ color: "black", fontWeight: 500 }}>
                    Payment Type
                  </span>
                </div>
                <div className="flex flex-col">
                  <span
                    style={{
                      color: "black",
                      display: "flex",
                      justifyContent: "flex-end",
                      fontWeight: 500,
                    }}
                  >
                    {transactionType}
                  </span>
                </div>
              </div>

              <div className="flex justify-between mt-4 w-[100]">
                <div className="flex flex-col">
                  <span style={{ color: "black", fontWeight: 500 }}>
                    Virtual account name
                  </span>
                </div>
                <div className="flex flex-col">
                  <span style={{ color: "#06593D" }}>XYZ Ventures</span>
                </div>
              </div>

              <div className="flex justify-between mt-4 w-[100%]">
                <div className="flex flex-col">
                  <span style={{ color: "black", fontWeight: 500 }}>
                    Virtual account no
                  </span>
                </div>
                <div className="flex flex-col">
                  <span style={{ color: "#06593D" }}>723******00</span>
                </div>
              </div>

              <div className="flex justify-between mt-4 w-[100%]">
                <div className="flex flex-col">
                  <span style={{ color: "black", fontWeight: 500 }}>
                    Payment Method
                  </span>
                </div>
                <div className="flex flex-col">
                  <span style={{ color: "#06593D" }}>PayEdge Wallet</span>
                </div>
              </div>

              <div className="flex justify-between mt-4 w-[100%]">
                <div className="flex flex-col">
                  <span style={{ color: "black", fontWeight: 500 }}>
                    Funding Channel
                  </span>
                </div>
                {fundingType === "directFunding" && (
                  <div className="flex flex-col">
                    <span style={{ color: "#F08000" }}>Direct Funding</span>
                  </div>
                )}
                {fundingType === "openMarket" && (
                  <div className="flex flex-col">
                    <span style={{ color: "#F08000" }}>Direct Funding</span>
                  </div>
                )}
              </div>

              <div className="flex justify-between mt-4 w-[100%]">
                <div className="flex flex-col">
                  <span style={{ color: "black", fontWeight: 500 }}>
                    Description from sender
                  </span>
                </div>
                <div className="flex flex-col">
                  <span style={{ color: "grey" }}>
                    Payment sent. Appreciate our ongoing collabration. Regards,
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </DashboardLayout>
  );
};
export default ReimbursementTransactionHistoryDetailsPageBulk;
