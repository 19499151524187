import React, { useEffect, useRef, useState } from "react";
import {
  PAIconDownload,
  PAIconFilter,
  PAIconOption,
  PAIconOutlineSearch,
} from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";
import Table from "layout/table/Table";
import AcceptModal from "shared/AcceptModal";
import SuccessModal from "shared/SuccessModal";
import { useDispatch, useSelector } from "react-redux";
import Loading from "shared/Loading";
import {
  remainder,
  supplierEarlyPaymentOffer,
} from "appstate/collections/collectionSlice";
import Pagination from "shared/Pagination";

const EarlyPaymentOffers = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  // const [selectAllText, setSelectAllText] = useState("Select All");
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  // const [ selectedInvoice, setSelectedInvoice] = useState(null);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(10);
  const pageSize = 10;

  const handleSearch = () => {
    dispatch(supplierEarlyPaymentOffer({ search: searchQuery }));
  };

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen({});
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    dispatch(
      supplierEarlyPaymentOffer({
        page,
        pageSize,
        search: searchQuery,
      })
    );
  }, [dispatch, page, pageSize, searchQuery]);

  const { supplierEarlyPaymentOfferData, isLoading } = useSelector(
    (state) => state?.collection
  );

  // CONFIRM REMAINDER
  const handleConfirm = () => {
    setIsAcceptModalOpen(false);
    // setSelectedInvoice(null);
    const selectedInvoiceIds = selectedRows.map((id) => id);
    dispatch(
      remainder({ type: "earlypaymentoffer", body: selectedInvoiceIds })
    ).then((response) => {
      if (response?.payload?.status === 200 && response?.payload?.success) {
        setIsSuccessModalOpen(true);
        setSuccessMessage(response?.payload?.message);
      }
    });
  };

  const handleCloseSuccessModal = () => {
    setIsSuccessModalOpen(false);
  };

  const openMarketDetails = (id) => {
    let path = `/collections/early-payment-offer/${id}`;
    navigate(path);
  };

  // SEND REMAINDER BUTTON
  const handleSendReminder = () => {
    setIsAcceptModalOpen(true);
  };

  // NOTIFICATION ICON CLICKED
  const handleNotificationClick = (row) => {
    // setSelectedInvoice(row);
    const isSelected = selectedRows.includes(row.id);
    let newSelectedRows = [];

    if (!isSelected) {
      newSelectedRows = [...selectedRows, row.id];
    }

    setSelectedRows(newSelectedRows);
    setIsAcceptModalOpen(true);
  };

  // SELECT ALL
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
      // setSelectAllText("Select All");
    } else {
      let allIds = [];

      allIds = supplierEarlyPaymentOfferData?.data?.map((row) => row.id);
      setSelectedRows(allIds);
      // setSelectAllText("Deselect All");
    }
    setSelectAll(!selectAll);
  };

  // CHECKBOX
  const handleCheckboxChange = (row) => {
    const isSelected = selectedRows.includes(row.id);
    let newSelectedRows = [];

    if (isSelected) {
      newSelectedRows = selectedRows.filter((id) => id !== row.id);
    } else {
      newSelectedRows = [...selectedRows, row.id];
    }

    setSelectedRows(newSelectedRows);
  };

  useEffect(() => {
    // console.log(selectedRows);
  }, [selectedRows]);

  const columns = [
    {
      name: (
        <input type="checkbox" onChange={handleSelectAll} checked={selectAll} />
      ),
      cell: (row) => (
        <input
          type="checkbox"
          onChange={() => handleCheckboxChange(row)}
          checked={selectedRows.includes(row.id)}
        />
      ),
      width: "50px",
    },
    {
      name: <span className="whitespace-normal">Program Name</span>,
      selector: (row) => row.programName,
      sortable: true,
    },
    {
      name: <span className="whitespace-normal">Invoice reference</span>,
      selector: (row) => row.invoiceReference,
      sortable: true,
    },
    {
      name: <span className="whitespace-normal">Due Date</span>,
      selector: (row) => row.dueDate,
      sortable: true,
      cell: (row) => {
        // Convert the array to a Date object
        const date = new Date(
          row.dueDate[0],
          row.dueDate[1] - 1,
          row.dueDate[2]
        );

        // Format the date as 'YYYY-MM-DD'
        const formattedDate = date.toLocaleDateString("en-CA");

        return <p>{formattedDate}</p>;
      },
    },
    {
      name: <span className="whitespace-normal">Payment Date</span>,
      selector: (row) => row.paymentDate,
      sortable: true,
      cell: (row) => {
        // Convert the array to a Date object
        const date = new Date(
          row.paymentDate[0],
          row.paymentDate[1] - 1,
          row.paymentDate[2]
        );

        // Format the date as 'YYYY-MM-DD'
        const formattedDate = date.toLocaleDateString("en-CA");

        return <p>{formattedDate}</p>;
      },
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
    },
    {
      name: <span className="whitespace-normal">Request Amount</span>,
      selector: (row) => row.requestAmount,
      sortable: true,
      cell: (row) => (
        <p className="text-success">{row.requestAmount?.toLocaleString()}</p>
      ),
    },
    {
      name: <span className="whitespace-normal">Discount Amount</span>,
      selector: (row) => row.discountAmount,
      sortable: true,
      cell: (row) => (
        <p className="text-success">{row.discountAmount?.toLocaleString()}</p>
      ),
    },
    {
      name: <span className="whitespace-normal">Receivable Amount</span>,
      selector: (row) => row.receivableAmount,
      sortable: true,
      cell: (row) => (
        <p className="text-success">{row.receivableAmount?.toLocaleString()}</p>
      ),
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <p
          className={
            row.status === "PARTIAL PAYMENT"
              ? "text-green-400"
              : "text-yellow-400"
          }
        >
          {row.status}
        </p>
      ),
    },
    {
      cell: (row) => (
        <div className="flex gap-2">
          {
            <PAIconOption
              title="Options"
              onClick={() => {
                setIsDropdownOpen((prev) => ({
                  ...prev,
                  [row.id]: !prev[row.id],
                }));
                // Close other dropdowns
                Object.keys(isDropdownOpen).forEach((key) => {
                  if (key !== row.id.toString()) {
                    setIsDropdownOpen((prev) => ({ ...prev, [key]: false }));
                  }
                });
              }}
            />
          }
          {isDropdownOpen[row.id] && (
            <div
              ref={dropdownRef}
              className="absolute bg-white border border-gray300 rounded-md shadow-md z-10"
            >
              <ul>
                <li
                  className="px-4 py-2 cursor-pointer hover:bg-primaryColor hover:text-white"
                  onClick={() => {
                    openMarketDetails(row?.id);
                  }}
                >
                  Preview
                </li>
                <li
                  className="px-4 py-2 cursor-pointer hover:bg-primaryColor hover:text-white"
                  onClick={() => {
                    handleNotificationClick(row);
                    setIsDropdownOpen((prev) => ({ ...prev, [row.id]: false }));
                  }}
                >
                  Send Reminder
                </li>
                <li className="px-4 py-2 cursor-not-allowed text-gray300">
                  Export
                </li>
              </ul>
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="my-2 grid md:grid-cols-2 items-center">
        <div className="gap3 flex relative">
          {/* EXPORT */}
          <button className="py-2 px-4 rounded-[5px] bg-white border-[#DEDEDE] shadow-sm mr2 flex items-center justify-center gap-1.5">
            Export {<PAIconDownload className="accent-gray300 w-4" />}
          </button>
        </div>

        {/* SEARCH */}
        <div className="flex md:col-span-1 justify-end">
          <div className="flex items-center justify-between gap-5">
            <div className="border border-transparent md:border-gray md:shadow-sm my-4 rounded-[5px] px-2 lg:w-[300px] bg-white">
              <div className="items-center flex bg-white">
                <PAIconOutlineSearch />
                <input
                  className="bg-transparent p-2 w-full focus:outline-none text-sm bg-white"
                  type="text"
                  placeholder="Search for invoices awaiting collection"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleSearch();
                    }
                  }}
                />
              </div>
              <div className="lg:hidden flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer">
                <PAIconFilter className="bg-[#2FA06A]" />
              </div>
            </div>
            <button className="hidden lg:flex bg-[#2FA06A] rounded-md my-4 py-2 px-6 mx3 items-center gap-2 text-white">
              Search
            </button>
          </div>
        </div>
      </div>

      {/* TABLE */}
      <div>
        {!supplierEarlyPaymentOfferData?.data?.length ? (
          <div>
            <div className="flex justify-between items-center px-[15px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
              <p className="text-black text-sm font-medium">
                Invoices from Early Payment Offers awaiting collection
              </p>
            </div>
            <TableEmptyState dashboard />
          </div>
        ) : (
          <Card className="rounded-[10px] mt-[15px]">
            <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
              <div>
                <p className="text-black text-lg font-bold">
                  Invoices from Early Payment Offers awaiting collection
                </p>
                <span className="text-xs font-thin">
                  List of all invoices from Early payment channel
                </span>
              </div>
              <div className="flex items-center justify-center gap-3">
                {/* <div
                  className="border border-gray300 rounded-md p-2 text-white bg-[#2FA06A] cursor-pointer"
                  onClick={handleSelectAll}
                >
                  <p>{selectAllText}</p>
                </div> */}
                <button
                  className={`border border-gray300 rounded-md p-2 text-white ${
                    selectedRows?.length === 0
                      ? "cursor-not-allowed bg-gray300"
                      : "cursor-pointer bg-secondaryColor"
                  }`}
                  disabled={selectedRows?.length === 0}
                  onClick={handleSendReminder}
                >
                  <p>Send Reminder Now</p>
                </button>
              </div>
            </div>
            <div className="px-8 pt-5">
              invoices awaiting collection from{" "}
              <span className="font-bold">Buyer</span>
            </div>
            <div className="pb-[24px]">
              <Table
                columns={columns}
                data={supplierEarlyPaymentOfferData?.data}
                onRowClicked={(row) => openMarketDetails(row.id)}
                progressPending={isLoading}
                progressComponent={<Loading />}
                pointer
                tableHeader
                className="mt-5"
              />
              <div className="flex justify-center gap-x-[8px] mt-[20px]">
                {supplierEarlyPaymentOfferData?.meta?.totalElements > 10 && (
                  <Pagination
                    page={page}
                    startPage={startPage}
                    setEndPage={setEndPage}
                    endPage={endPage}
                    setStartPage={setStartPage}
                    setPage={setPage}
                    totalPages={
                      supplierEarlyPaymentOfferData?.meta?.totalElements
                    }
                  />
                )}
              </div>
            </div>
          </Card>
        )}
      </div>

      <AcceptModal
        isOpen={isAcceptModalOpen}
        onClose={() => setIsAcceptModalOpen(false)}
        yes
        message={
          <>
            Would you like to proceed with sending a <br />
            <span className="text-[#07593D] font-bold">
              Payment reminder
            </span>{" "}
            now?
          </>
        }
        onConfirm={handleConfirm}
      />

      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={handleCloseSuccessModal}
        message={successMessage}
      />
    </>
  );
};

export default EarlyPaymentOffers;
