import { useSelector } from "react-redux";
import BuyerWorkflowConfig from "./WorkflowConfig/buyer";
import FunderWorkflowConfig from "./WorkflowConfig/funder";
import SupplierWorkflowConfig from "./WorkflowConfig/supplier";

const WorkFlowConfig = ({ viewDetails, setViewDetails }) => {
  const { userId } = useSelector((state) => state.auth);

  const constants = [
    "default-roles-payassyst",
    "offline_access",
    "uma_authorization",
  ];
  const roleIndex = userId?.realm_access?.roles.findIndex(
    (role) => !constants.includes(role)
  );

  const userRole = userId?.realm_access?.roles[roleIndex]?.toLowerCase();
  const player = userRole?.includes("buyer")
    ? "buyer"
    : userRole?.includes("funder")
    ? "funder"
    : "supplier";
  return (
    <div className="flex flex-col gap-8 pt-10 px-5">
      <header className="flex flex-col">
        <span className=" font-bold text-[18px]">Workflow Configuration</span>

        <span className="text-sm font-medium text-[#959595]">
          Configure and manage workflow settings
        </span>
      </header>

      {player === "buyer" ? (
        <BuyerWorkflowConfig />
      ) : player === "funder" ? (
        <FunderWorkflowConfig />
      ) : (
        <SupplierWorkflowConfig />
      )}
    </div>
  );
};

export default WorkFlowConfig;
