import React, { useEffect, useState, useRef } from "react";
import {
  PAIconDownload,
  PAIconFilter,
  PAIconOutlineSearch,
  PAIconOption,
} from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";
import Table from "layout/table/Table";
import AcceptModal from "shared/AcceptModal";
import SuccessModal from "shared/SuccessModal";
import { useDispatch, useSelector } from "react-redux";

import Pagination from "shared/Pagination";
import Loading from "shared/Loading";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  bulkLoanDefault,
  bulkOverdueInvoices,
  singleLoanDefault,
  singleOverdueInvoices,
} from "appstate/collections/collectionSlice";

const OverdueInvoices = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  // const [selectedRows, setSelectedRows] = useState([]);
  const [singleSelectedRows, setSingleSelectedRows] = useState([]);
  const [bulkSelectedRows, setBulkSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  // const [selectAllText, setSelectAllText] = useState("Select All");
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  // const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [activeTab, setActiveTab] = useState("single");

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [hisTab, setHisTab] = useState("overdue");

  const navigate = useNavigate();

  const {
    singleLoanDefaultsData,
    bulkLoanDefaultsData,
    singleOverdueInvoicesData,
    bulkOverdueInvoicesData,
    isLoading,
  } = useSelector((state) => state?.collection);

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen({});
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const dispatch = useDispatch();

  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const pageSize = 10;

  // const handleSearch = () => {
  //   dispatch(allDirectFunding({ search: searchQuery }));
  // };
  const handleSearch = () => {
    if (hisTab === "loandefault") {
      dispatch(
        singleLoanDefault({
          page,
          pageSize,
          search: searchQuery,
        })
      );
      dispatch(
        bulkLoanDefault({
          page,
          pageSize,
          search: searchQuery,
        })
      );
    } else {
      // For overdue tab, use dummy data or other endpoint
      // dispatch(allDirectFunding({ search: searchQuery }));
      dispatch(
        singleOverdueInvoices({
          page,
          pageSize,
          search: searchQuery,
        })
      );
      dispatch(
        bulkOverdueInvoices({
          page,
          pageSize,
          search: searchQuery,
        })
      );
    }
  };

  useEffect(() => {
    if (hisTab === "loandefault") {
      if (activeTab === "single") {
        dispatch(
          singleLoanDefault({
            page,
            pageSize,
            search: searchQuery,
          })
        );
      } else if (activeTab === "bulk") {
        dispatch(
          bulkLoanDefault({
            page,
            pageSize,
            search: searchQuery,
          })
        );
      }
    } else {
      if (hisTab === "overdue") {
        if (activeTab === "single") {
          dispatch(
            singleOverdueInvoices({
              page,
              pageSize,
              search: searchQuery,
            })
          );
        } else if (activeTab === "bulk") {
          dispatch(
            bulkOverdueInvoices({
              page,
              pageSize,
              search: searchQuery,
            })
          );
        }
      }
    }
  }, [dispatch, hisTab, activeTab, page, pageSize, searchQuery]);

  const formatAmount = (amount) => {
    if (!amount || typeof amount !== "string") return "0.00";
    const parsedAmount = parseFloat(amount);
    if (isNaN(parsedAmount)) return "0.00";
    return parsedAmount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const handleNotificationClick = (row) => {
    // setSelectedInvoice(row);

    // Determine the selected rows state based on the active tab
    const isSingleTab = activeTab === "single";
    const selectedRowList = isSingleTab ? singleSelectedRows : bulkSelectedRows;

    const isSelected = selectedRowList.includes(row.id);
    let newSelectedRows = [];

    if (!isSelected) {
      newSelectedRows = [...selectedRowList, row.id];
    }

    // Update the appropriate state based on the active tab
    if (isSingleTab) {
      setSingleSelectedRows(newSelectedRows);
    } else {
      setBulkSelectedRows(newSelectedRows);
    }

    setIsAcceptModalOpen(true);
  };

  const handleConfirm = () => {
    setIsAcceptModalOpen(false);

    const selectedInvoiceIds =
      activeTab === "single" ? singleSelectedRows : bulkSelectedRows;

    if (selectedInvoiceIds.length > 0) {
      setIsSuccessModalOpen(true);
      setSuccessMessage("Payment remainders sent successfully");
    }
  };

  const handleCloseSuccessModal = () => {
    setIsSuccessModalOpen(false);
  };

  //   overduesingleinvoicedetailsloan

  const singleDetails = (id) => {
    let path = `/collections/overduesingleinvoicedetails/${id}`;
    navigate(path);
  };

  const singleDetailsLoan = (id) => {
    let path = `/collections/overduesingleinvoicedetailsloan/${id}`;
    navigate(path);
  };

  //   overduesingleinvoicedetailsbulk

  const bulkDetails = (id) => {
    let path = `/collections/overduefirstbulkinvoicedetailsbulk/${id}`;
    navigate(path);
  };

  const bulkDetailsLoan = (id) => {
    let path = `/collections/overduesingleinvoicedetailsbulk/${id}`;
    navigate(path);
  };

  // const handleSendReminder = () => {
  //   setIsAcceptModalOpen(true);
  // };
  const handleSendReminder = () => {
    const rowsToProcess =
      activeTab === "single" ? singleSelectedRows : bulkSelectedRows;
    if (rowsToProcess.length > 0) {
      setIsAcceptModalOpen(true);
    }
  };

  const handleSelectAll = () => {
    const dataToUse =
      hisTab === "overdue"
        ? activeTab === "single"
          ? singleOverdueInvoicesData?.data || []
          : bulkOverdueInvoicesData?.data || []
        : activeTab === "single"
        ? singleLoanDefaultsData?.data || []
        : bulkLoanDefaultsData?.data || [];

    if (activeTab === "single") {
      if (selectAll) {
        setSingleSelectedRows([]);
      } else {
        setSingleSelectedRows(dataToUse.map((row) => row.id));
      }
    } else if (activeTab === "bulk") {
      if (selectAll) {
        setBulkSelectedRows([]);
      } else {
        setBulkSelectedRows(dataToUse.map((row) => row.id));
      }
    }
    setSelectAll(!selectAll);
  };

  const columns = [
    {
      name: (
        <input
          type="checkbox"
          onChange={handleSelectAll}
          checked={
            activeTab === "single"
              ? singleSelectedRows.length ===
                (hisTab === "overdue"
                  ? (singleOverdueInvoicesData?.length || [])?.length
                  : (singleLoanDefaultsData?.data || [])?.length)
              : bulkSelectedRows.length ===
                (hisTab === "overdue"
                  ? (bulkOverdueInvoicesData?.length || [])?.length
                  : (bulkLoanDefaultsData?.data || []).length)
          }
        />
      ),
      cell: (row) => (
        <input
          type="checkbox"
          onChange={() => handleCheckboxChange(row)}
          checked={
            activeTab === "single"
              ? singleSelectedRows.includes(row.id)
              : bulkSelectedRows.includes(row.id)
          }
        />
      ),
      width: "50px",
    },
    {
      name: (
        <span className="whitespace-normal">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Request ref Number
        </span>
      ),
      selector: (row) => {
        const invoiceNumber = row.invoiceNumber;
        return invoiceNumber && !isNaN(invoiceNumber)
          ? `INV-${invoiceNumber}`
          : "INV-0";
      },
      sortable: true,
      width: "160px",
    },
    {
      name: (
        <span className="whitespace-normal">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Company
        </span>
      ),
      selector: (row) => row.company,
      sortable: true,
      width: "160px",
    },
    {
      name: "Channel",
      selector: (row) => row.recourseOption,
      sortable: true,
      width: "160px",
    },
    {
      name: "Payment date",
      selector: (row) => row.recourseOption,
      sortable: true,
      width: "160px",
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
    },
    {
      name: "Request Amount",
      selector: (row) => row.totalAmount,
      sortable: true,
      cell: (row) => <p className="">{formatAmount(row.invoiceAmount)}</p>,
      width: "160px",
    },

    // {
    //   name: "Lock Rate",
    //   selector: (row) => row.discountRate,
    //   sortable: true,
    // },
    {
      name: "Discount Amount",
      selector: (row) => row.discountAmount,
      sortable: true,
      width: "160px",
    },
    {
      name: "Fundable Amount",

      selector: (row) => row.discountAmount,
      sortable: true,
      cell: (row) => <p className="">{formatAmount(row.discountAmount)}</p>,
      width: "160px",
    },
    {
      name: "Days Past Date",

      selector: (row) => row.daysPast,
      sortable: true,
      cell: (row) => <p className=" text-[#BB0C0C]">{row.daysPast}</p>,
      width: "160px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <p
          className={
            row.status === "Successful" ? "text-green-400 " : "text-[#F08000]"
          }
        >
          {row.status}
        </p>
      ),
      width: "160px",
    },
    {
      cell: (row) => (
        <div className="flex gap-2">
          {
            <PAIconOption
              title="Options"
              onClick={() => {
                setIsDropdownOpen((prev) => ({
                  ...prev,
                  [row.id]: !prev[row.id],
                }));
                // Close other dropdowns
                Object.keys(isDropdownOpen).forEach((key) => {
                  if (key !== row.id.toString()) {
                    setIsDropdownOpen((prev) => ({ ...prev, [key]: false }));
                  }
                });
              }}
            />
          }
          {isDropdownOpen[row.id] && (
            <div
              ref={dropdownRef}
              className="absolute bg-white border border-gray300 rounded-md shadow-md z-10"
            >
              <ul>
                <li
                  className="px-4 py-2 cursor-pointer hover:bg-primaryColor hover:text-white"
                  onClick={() => {
                    if (activeTab === "single") {
                      singleDetails(row?.id);
                    } else if (activeTab === "bulk") {
                      bulkDetails(row?.id);
                    }
                    setIsDropdownOpen((prev) => ({ ...prev, [row.id]: false }));
                  }}
                >
                  Preview
                </li>
                <li
                  className="px-4 py-2 cursor-pointer hover:bg-primaryColor hover:text-white"
                  onClick={() => {
                    handleNotificationClick(row);
                    setIsDropdownOpen((prev) => ({ ...prev, [row.id]: false }));
                  }}
                >
                  Send Reminder
                </li>
                <li className="px-4 py-2 cursor-not-allowed text-gray300">
                  Export
                </li>
              </ul>
            </div>
          )}
        </div>
      ),
    },
  ];

  const columnsLoan = [
    {
      name: (
        <input
          type="checkbox"
          onChange={handleSelectAll}
          checked={
            activeTab === "single"
              ? singleSelectedRows.length ===
                (hisTab === "overdue"
                  ? (singleOverdueInvoicesData?.length || [])?.length
                  : (singleLoanDefaultsData?.data || [])?.length)
              : bulkSelectedRows.length ===
                (hisTab === "overdue"
                  ? (bulkOverdueInvoicesData?.length || [])?.length
                  : (bulkLoanDefaultsData?.data || []).length)
          }
        />
      ),
      cell: (row) => (
        <input
          type="checkbox"
          onChange={() => handleCheckboxChange(row)}
          checked={
            activeTab === "single"
              ? singleSelectedRows.includes(row.id)
              : bulkSelectedRows.includes(row.id)
          }
        />
      ),
      width: "50px",
    },
    {
      name: (
        <span className="whitespace-normal">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Request ref Number
        </span>
      ),
      selector: (row) => {
        const invoiceNumber = row.invoiceNumber;
        return invoiceNumber && !isNaN(invoiceNumber)
          ? `INV-${invoiceNumber}`
          : "INV-0";
      },
      sortable: true,
      width: "160px",
    },
    {
      name: (
        <span className="whitespace-normal">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Company
        </span>
      ),
      selector: (row) => row.company,
      sortable: true,
      width: "160px",
    },

    {
      name: "Due Date",
      selector: (row) => row.recourseOption,
      sortable: true,
      width: "160px",
    },

    {
      name: " Funding Channel",
      selector: (row) => row.channel,
      sortable: true,
      width: "160px",
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
    },
    {
      name: "Invoice Amount",
      selector: (row) => row.totalAmount,
      sortable: true,
      cell: (row) => <p className="">{formatAmount(row.invoiceAmount)}</p>,
      width: "160px",
    },

    // {
    //   name: "Lock Rate",
    //   selector: (row) => row.discountRate,
    //   sortable: true,
    // },
    {
      name: "Total Loan Interest",

      selector: (row) => row.interest,
      sortable: true,
      cell: (row) => <p className=" text-[#2fa069]">{row.interest}</p>,
      width: "160px",
    },
    {
      name: "Grand Total",
      selector: (row) => row.discountAmount,
      sortable: true,
      width: "160px",
    },

    {
      cell: (row) => (
        <div className="flex gap-2">
          {
            <PAIconOption
              title="Options"
              onClick={() => {
                setIsDropdownOpen((prev) => ({
                  ...prev,
                  [row.id]: !prev[row.id],
                }));
                // Close other dropdowns
                Object.keys(isDropdownOpen).forEach((key) => {
                  if (key !== row.id.toString()) {
                    setIsDropdownOpen((prev) => ({ ...prev, [key]: false }));
                  }
                });
              }}
            />
          }
          {isDropdownOpen[row.id] && (
            <div
              ref={dropdownRef}
              className="absolute bg-white border border-gray300 rounded-md shadow-md z-10"
            >
              <ul>
                <li
                  className="px-4 py-2 cursor-pointer hover:bg-primaryColor hover:text-white"
                  onClick={() => {
                    if (activeTab === "single") {
                      singleDetails(row?.id);
                    } else if (activeTab === "bulk") {
                      bulkDetails(row?.id);
                    }
                    setIsDropdownOpen((prev) => ({ ...prev, [row.id]: false }));
                  }}
                >
                  Preview
                </li>
                <li
                  className="px-4 py-2 cursor-pointer hover:bg-primaryColor hover:text-white"
                  onClick={() => {
                    handleNotificationClick(row);
                    setIsDropdownOpen((prev) => ({ ...prev, [row.id]: false }));
                  }}
                >
                  Send Reminder
                </li>
                <li className="px-4 py-2 cursor-not-allowed text-gray300">
                  Export
                </li>
              </ul>
            </div>
          )}
        </div>
      ),
    },
  ];

  const columnsbulkLoan = [
    {
      name: (
        <input
          type="checkbox"
          onChange={handleSelectAll}
          checked={
            activeTab === "single"
              ? singleSelectedRows.length ===
                (hisTab === "overdue"
                  ? (singleOverdueInvoicesData?.length || [])?.length
                  : (singleLoanDefaultsData?.data || [])?.length)
              : bulkSelectedRows.length ===
                (hisTab === "overdue"
                  ? (bulkOverdueInvoicesData?.length || [])?.length
                  : (bulkLoanDefaultsData?.data || []).length)
          }
        />
      ),
      cell: (row) => (
        <input
          type="checkbox"
          onChange={() => handleCheckboxChange(row)}
          checked={
            activeTab === "single"
              ? singleSelectedRows.includes(row.id)
              : bulkSelectedRows.includes(row.id)
          }
        />
      ),
      width: "50px",
    },
    {
      name: (
        <span className="whitespace-normal">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Request ref Number
        </span>
      ),
      selector: (row) => {
        const invoiceNumber = row.invoiceNumber;
        return invoiceNumber && !isNaN(invoiceNumber)
          ? `INV-${invoiceNumber}`
          : "INV-0";
      },
      sortable: true,
      width: "160px",
    },
    {
      name: (
        <span className="whitespace-normal">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Company
        </span>
      ),
      selector: (row) => row.company,
      sortable: true,
      width: "160px",
    },

    {
      name: "Due Date",
      selector: (row) => row.recourseOption,
      sortable: true,
      width: "160px",
    },

    {
      name: " Funding Channel",
      selector: (row) => row.channel,
      sortable: true,
      width: "160px",
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
    },
    {
      name: " Total Invoice Amount",
      selector: (row) => row.totalAmount,
      sortable: true,
      cell: (row) => <p className="">{formatAmount(row.invoiceAmount)}</p>,
      width: "160px",
    },

    // {
    //   name: "Lock Rate",
    //   selector: (row) => row.discountRate,
    //   sortable: true,
    // },
    {
      name: "Total Loan Interest",

      selector: (row) => row.interest,
      sortable: true,
      cell: (row) => <p className=" text-[#2fa069]">{row.interest}</p>,
      width: "160px",
    },
    {
      name: "Grand Total",
      selector: (row) => row.discountAmount,
      sortable: true,
      width: "160px",
    },

    {
      cell: (row) => (
        <div className="flex gap-2">
          {
            <PAIconOption
              title="Options"
              onClick={() => {
                setIsDropdownOpen((prev) => ({
                  ...prev,
                  [row.id]: !prev[row.id],
                }));
                // Close other dropdowns
                Object.keys(isDropdownOpen).forEach((key) => {
                  if (key !== row.id.toString()) {
                    setIsDropdownOpen((prev) => ({ ...prev, [key]: false }));
                  }
                });
              }}
            />
          }
          {isDropdownOpen[row.id] && (
            <div
              ref={dropdownRef}
              className="absolute bg-white border border-gray300 rounded-md shadow-md z-10"
            >
              <ul>
                <li
                  className="px-4 py-2 cursor-pointer hover:bg-primaryColor hover:text-white"
                  onClick={() => {
                    if (activeTab === "single") {
                      singleDetails(row?.id);
                    } else if (activeTab === "bulk") {
                      bulkDetails(row?.id);
                    }
                    setIsDropdownOpen((prev) => ({ ...prev, [row.id]: false }));
                  }}
                >
                  Preview
                </li>
                <li
                  className="px-4 py-2 cursor-pointer hover:bg-primaryColor hover:text-white"
                  onClick={() => {
                    handleNotificationClick(row);
                    setIsDropdownOpen((prev) => ({ ...prev, [row.id]: false }));
                  }}
                >
                  Send Reminder
                </li>
                <li className="px-4 py-2 cursor-not-allowed text-gray300">
                  Export
                </li>
              </ul>
            </div>
          )}
        </div>
      ),
    },
  ];

  const columnBulks = [
    {
      name: (
        <input
          type="checkbox"
          onChange={handleSelectAll}
          checked={
            activeTab === "single"
              ? singleSelectedRows.length ===
                (hisTab === "overdue"
                  ? (singleOverdueInvoicesData?.length || [])?.length
                  : (singleLoanDefaultsData?.data || [])?.length)
              : bulkSelectedRows.length ===
                (hisTab === "overdue"
                  ? (bulkOverdueInvoicesData?.length || [])?.length
                  : (bulkLoanDefaultsData?.data || []).length)
          }
        />
      ),
      cell: (row) => (
        <input
          type="checkbox"
          onChange={() => handleCheckboxChange(row)}
          checked={
            activeTab === "single"
              ? singleSelectedRows.includes(row.id)
              : bulkSelectedRows.includes(row.id)
          }
        />
      ),
      width: "50px",
    },
    {
      name: (
        <span className="whitespace-normal">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Request ref Number
        </span>
      ),
      selector: (row) => {
        const invoiceNumber = row.invoiceNumber;
        return invoiceNumber && !isNaN(invoiceNumber)
          ? `INV-${invoiceNumber}`
          : "INV-0";
      },
      sortable: true,
      width: "160px",
    },
    {
      name: (
        <span className="whitespace-normal">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Company
        </span>
      ),
      selector: (row) => row.company,
      sortable: true,
      width: "160px",
    },
    {
      name: "Channel",
      selector: (row) => row.channel,
      sortable: true,
      width: "160px",
    },
    {
      name: "Payment Date",
      selector: (row) => row.recourseOption,
      sortable: true,
      width: "160px",
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
    },
    {
      name: "Total Request Amount",
      selector: (row) => row.totalAmount,
      sortable: true,
      cell: (row) => <p className="">{formatAmount(row.invoiceAmount)}</p>,
      width: "160px",
    },

    {
      name: "Total Fundable Amount",

      selector: (row) => row.discountAmount,
      sortable: true,
      cell: (row) => <p className="">{formatAmount(row.discountAmount)}</p>,
      width: "160px",
    },
    {
      name: "Days Past Date",

      selector: (row) => row.daysPast,
      sortable: true,
      cell: (row) => <p className=" text-[#BB0C0C]">{row.daysPast}</p>,
      width: "160px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      width: "160px",
      cell: (row) => (
        <p
          className={
            row.status === "Successful" ? "text-green-400" : "text-[#F08000]"
          }
        >
          {row.status}
        </p>
      ),
    },
    {
      cell: (row) => (
        <div className="flex gap-2">
          {
            <PAIconOption
              title="Options"
              onClick={() => {
                setIsDropdownOpen((prev) => ({
                  ...prev,
                  [row.id]: !prev[row.id],
                }));
                // Close other dropdowns
                Object.keys(isDropdownOpen).forEach((key) => {
                  if (key !== row.id.toString()) {
                    setIsDropdownOpen((prev) => ({ ...prev, [key]: false }));
                  }
                });
              }}
            />
          }
          {isDropdownOpen[row.id] && (
            <div
              ref={dropdownRef}
              className="absolute bg-white border border-gray300 rounded-md shadow-md z-10"
            >
              <ul>
                <li
                  className="px-4 py-2 cursor-pointer hover:bg-primaryColor hover:text-white"
                  onClick={() => {
                    if (activeTab === "single") {
                      singleDetails(row?.id);
                    } else if (activeTab === "bulk") {
                      bulkDetails(row?.id);
                    }
                    setIsDropdownOpen((prev) => ({ ...prev, [row.id]: false }));
                  }}
                >
                  Preview
                </li>
                <li
                  className="px-4 py-2 cursor-pointer hover:bg-primaryColor hover:text-white"
                  onClick={() => {
                    handleNotificationClick(row);
                    setIsDropdownOpen((prev) => ({ ...prev, [row.id]: false }));
                  }}
                >
                  Send Reminder
                </li>
                <li className="px-4 py-2 cursor-not-allowed text-gray300">
                  Export
                </li>
              </ul>
            </div>
          )}
        </div>
      ),
    },
  ];

  // const handleCheckboxChange = (row) => {
  //   const isSelected = selectedRows.includes(row.id);
  //   let newSelectedRows = [];

  //   if (isSelected) {
  //     newSelectedRows = selectedRows.filter((id) => id !== row.id);
  //   } else {
  //     newSelectedRows = [...selectedRows, row.id];
  //   }

  //   setSelectedRows(newSelectedRows);
  // };
  const handleCheckboxChange = (row) => {
    if (activeTab === "single") {
      const isSelected = singleSelectedRows.includes(row.id);
      setSingleSelectedRows(
        isSelected
          ? singleSelectedRows.filter((id) => id !== row.id)
          : [...singleSelectedRows, row.id]
      );
    } else if (activeTab === "bulk") {
      const isSelected = bulkSelectedRows.includes(row.id);
      setBulkSelectedRows(
        isSelected
          ? bulkSelectedRows.filter((id) => id !== row.id)
          : [...bulkSelectedRows, row.id]
      );
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const LoanDefaultSingleTable = () => (
    <>
      <Table
        columns={columnsLoan}
        data={singleLoanDefaultsData?.data}
        onRowClicked={(row) => singleDetailsLoan(row.id)}
        pointer
        tableHeader
        className="mt-5"
      />
      <div className="flex justify-center gap-x-[8px] mt-[20px]">
        {singleLoanDefaultsData?.data?.length > 10 && (
          <Pagination
            page={page}
            startPage={startPage}
            setEndPage={setEndPage}
            endPage={endPage}
            setStartPage={setStartPage}
            setPage={setPage}
            totalPages={singleLoanDefaultsData?.meta?.totalElements}
          />
        )}
      </div>
    </>
  );

  const LoanDefaultBulkTable = () => (
    <>
      <Table
        columns={columnsbulkLoan}
        data={bulkLoanDefaultsData?.data}
        onRowClicked={(row) => bulkDetailsLoan(row.id)}
        progressPending={isLoading}
        progressComponent={<Loading />}
        pointer
        tableHeader
        className="mt-5"
      />
      <div className="flex justify-center gap-x-[8px] mt-[20px]">
        {bulkLoanDefaultsData?.data?.length > 10 && (
          <Pagination
            page={page}
            startPage={startPage}
            setEndPage={setEndPage}
            endPage={endPage}
            setStartPage={setStartPage}
            setPage={setPage}
            totalPages={bulkLoanDefaultsData?.meta?.totalElements}
          />
        )}
      </div>
    </>
  ); // end of LoanDefaultSingleTable

  const renderTabContent = () => {
    switch (hisTab) {
      case "overdue":
        // console.log("Category: Electronics");

        switch (activeTab) {
          case "single":
            return (
              <>
                <Table
                  columns={columns}
                  data={singleOverdueInvoicesData?.data}
                  onRowClicked={(row) => singleDetails(row.id)}
                  pointer
                  tableHeader
                  className="mt-5"
                />
                <div className="flex justify-center gap-x-[8px] mt-[20px]">
                  {singleOverdueInvoicesData?.data?.length > 10 && (
                    <Pagination
                      page={page}
                      startPage={startPage}
                      setEndPage={setEndPage}
                      endPage={endPage}
                      setStartPage={setStartPage}
                      setPage={setPage}
                      totalPages={
                        singleOverdueInvoicesData?.meta?.totalElements
                      }
                    />
                  )}
                </div>
              </>
            );
          case "bulk":
            // return <BulkTableDirectFund />;
            return (
              <>
                <Table
                  columns={columnBulks}
                  data={bulkOverdueInvoicesData?.data}
                  onRowClicked={(row) => bulkDetails(row.id)}
                  progressPending={isLoading}
                  progressComponent={<Loading />}
                  pointer
                  tableHeader
                  className="mt-5"
                />
                <div className="flex justify-center gap-x-[8px] mt-[20px]">
                  {bulkOverdueInvoicesData?.data?.length > 10 && (
                    <Pagination
                      page={page}
                      startPage={startPage}
                      setEndPage={setEndPage}
                      endPage={endPage}
                      setStartPage={setStartPage}
                      setPage={setPage}
                      totalPages={bulkOverdueInvoicesData?.meta?.totalElements}
                    />
                  )}
                </div>
              </>
            );
          default:
            return null;
        }

      case "loandefault":
        switch (activeTab) {
          case "single":
            return <LoanDefaultSingleTable />;

          case "bulk":
            return <LoanDefaultBulkTable />;

          default:
            return null;
        }

      default:
        return null;
    }

    // switch (activeTab) {
    //   case "single":
    //     // return <SingleTableDirectFund data={dummyData} columns={columns} />;
    //     return (
    //       <>
    //         <Table
    //           columns={columns}
    //           data={singleDummyData}
    //           onRowClicked={(row) => singleDetails(row.id)}
    //           pointer
    //           tableHeader
    //           className="mt-5"
    //         />
    //         <div className="flex justify-center gap-x-[8px] mt-[20px]">
    //           {singleDummyData?.length > 0 && (
    //             <Pagination
    //               page={page}
    //               startPage={startPage}
    //               setEndPage={setEndPage}
    //               endPage={endPage}
    //               setStartPage={setStartPage}
    //               setPage={setPage}
    //               // totalPages={directFundingData?.meta?.totalElements}
    //             />
    //           )}
    //         </div>
    //       </>
    //     );
    //   case "bulk":
    //     // return <BulkTableDirectFund />;
    //     return (
    //       <>
    //         <Table
    //           columns={columnBulks}
    //           data={bulkDummyData}
    //           onRowClicked={(row) => bulkDetails(row.id)}
    //           progressPending={isLoading}
    //           progressComponent={<Loading />}
    //           pointer
    //           tableHeader
    //           className="mt-5"
    //         />
    //         <div className="flex justify-center gap-x-[8px] mt-[20px]">
    //           {bulkDummyData?.length > 0 && (
    //             <Pagination
    //               page={page}
    //               startPage={startPage}
    //               setEndPage={setEndPage}
    //               endPage={endPage}
    //               setStartPage={setStartPage}
    //               setPage={setPage}
    //               // totalPages={directFundingData?.meta?.totalElements}
    //             />
    //           )}
    //         </div>
    //       </>
    //     );
    //   default:
    //     return null;
    // }
  };

  return (
    <>
      <div className="my-2 grid md:grid-cols-2 items-center">
        <div className="gap-3 flex relative">
          {/* FILTER */}
          <div className="flex gap-4 mb-4">
            <div className="relative">
              <DatePicker
                selected={startDate}
                onChange={(update) => setDateRange(update)}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                placeholderText="Filters"
                className="border border-[#ccc] px-4 py-3 cursor-pointer rounded-md w-60"
              />

              <div className="absolute top-2/4 right-3 transform -translate-y-2/4 pointer-events-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5 text-gray-400"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </div>
            </div>
          </div>
          {/* EXPORT */}

          <div>
            {" "}
            <button className="py-2 px-4 text-sm rounded-[5px] bg-white border-[#DEDEDE] shadow-sm mr-2 flex items-center justify-center gap-1.5">
              Export {<PAIconDownload className="accent-gray300 w-4" />}
            </button>
          </div>
        </div>

        {/* SEARCH */}
        <div className="flex md:col-span-1 justify-end">
          <div className="flex items-center justify-between gap-5">
            <div className="border border-transparent md:border-gray md:shadow-sm my-4 rounded-[5px] px-2 lg:w-[300px] bg-white">
              <div className="items-center flex bg-white">
                <PAIconOutlineSearch />
                <input
                  className="bg-transparent p-2 w-full focus:outline-none text-sm bg-white"
                  type="text"
                  placeholder="Search for invoices awaiting collection"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleSearch();
                    }
                  }}
                />
              </div>
              <div className="lg:hidden flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer">
                <PAIconFilter className="bg-[#2FA06A]" />
              </div>
            </div>
            <button className="hidden lg:flex bg-[#2FA06A] rounded-md my-4 py-2 px-6 mx3 items-center gap-2 text-white">
              Search
            </button>
          </div>
        </div>
      </div>

      {/* TABLE - Now always shows card with tabs */}
      <Card className="rounded-[10px] mt-[15px]">
        <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px] py-10 flex-wrap mb-4">
          <div>
            <p className="text-black text-lg font-bold">
              Overdue Requests awaiting collection
            </p>
            <span className="text-xs font-thin">
              List of all overdue requests you have funded
            </span>
          </div>
          <div className="flex items-center justify-center gap-3">
            <button
              className={`border border-gray300 rounded-md p-2 text-white ${
                (activeTab === "single" && singleSelectedRows.length === 0) ||
                (activeTab === "bulk" && bulkSelectedRows.length === 0)
                  ? "cursor-not-allowed bg-gray300"
                  : "cursor-pointer bg-secondaryColor"
              }`}
              disabled={
                (activeTab === "single" && singleSelectedRows.length === 0) ||
                (activeTab === "bulk" && bulkSelectedRows.length === 0)
              }
              onClick={handleSendReminder}
            >
              <p>Send Reminder Now</p>
            </button>
          </div>
        </div>
        <div className="pb-[24px]">
          <div className="flex items-center px-6 justify-between flex-wrap pt-3">
            {" "}
            <div className="flex gap-3 my-3">
              <p
                className={`cursor-pointer font-bold relative ${
                  activeTab === "single" ? "text-[#F08000]" : "text-[#959595]"
                }`}
                onClick={() => handleTabClick("single")}
              >
                Single requests
                {activeTab === "single" && (
                  <span
                    className="absolute bottom-0 left-1/2 transform -translate-x-1/2
                   bg-[#F08000] h-0.5 w-5"
                  ></span>
                )}
              </p>

              <p
                className={`cursor-pointer font-bold relative ${
                  activeTab === "bulk" ? "text-[#F08000]" : "text-[#959595]"
                }`}
                onClick={() => handleTabClick("bulk")}
              >
                Bulk requests
                {activeTab === "bulk" && (
                  <span
                    className="absolute bottom-0 left-1/2 transform -translate-x-1/2
                   bg-[#F08000] h-0.5 w-5"
                  ></span>
                )}
              </p>
            </div>
            <div className="flex gap-2">
              {/* first button */}
              <button
                className={`h-[37px] px-5 py-2.5 ${
                  hisTab === "overdue"
                    ? "bg-[#07593d] text-white"
                    : "bg-[#949494]/5 text-[#222222]"
                } rounded-md justify-center items-center gap-2.5 inline-flex`}
                onClick={() => setHisTab("overdue")}
              >
                <div
                  className={`text-center text-sm font-normal ${
                    hisTab === "overdue" ? "text-white" : "text-[#222222]"
                  }`}
                >
                  Overdue
                </div>
              </button>

              {/* second button */}
              <button
                className={`h-[37px] px-5 py-2.5 rounded-md ${
                  hisTab === "loandefault"
                    ? "bg-[#07593d] text-white"
                    : "bg-[#949494]/5 text-[#222222]"
                } justify-center items-center gap-2.5 inline-flex font-normal text-center`}
                onClick={() => setHisTab("loandefault")}
              >
                <div
                  className={`text-center text-sm font-normal ${
                    hisTab === "loandefault" ? "text-white" : "text-[#222222]"
                  }`}
                >
                  Loan Default
                </div>
              </button>
            </div>
          </div>

          <div className="mt-3">
            {/* Always render tab content, but show empty state when no data */}
            {(!singleOverdueInvoicesData?.length && activeTab === "single") ||
            (!bulkOverdueInvoicesData?.length && activeTab === "bulk") ? (
              <div className="px-6">
                <TableEmptyState dashboard />
              </div>
            ) : (
              renderTabContent()
            )}
          </div>
        </div>
      </Card>

      <AcceptModal
        isOpen={isAcceptModalOpen}
        onClose={() => setIsAcceptModalOpen(false)}
        yes
        message={
          <>
            Would you like to proceed with sending a <br />
            <span className="text-[#07593D] font-bold">
              Payment reminder
            </span>{" "}
            now?
          </>
        }
        onConfirm={handleConfirm}
      />

      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={handleCloseSuccessModal}
        message={successMessage}
      />
    </>
  );
};

export default OverdueInvoices;
