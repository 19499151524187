import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  viewApprovedSingleEarlyPaymentDetails,
  closedMarketViewSingleDetails,
} from "appstate/buyer/buyerSlice";
import { allInvoiceById } from "appstate/invoice/invoiceSlice";

const GeneralDetails = () => {
  const {
    getInvoiceId,
    viewApprovedSingleEarlyPaymentDetailsData,
    closedMarketViewSingleDetailsData,
  } = useSelector((state) => state?.buyer);
  const { invoiceIdData } = useSelector((state) => state?.invoice);
  const [data, setData] = useState([]);
  // const [invocue, setinvocue] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (getInvoiceId?.fundingChannel === "CLOSED_MARKET") {
      dispatch(closedMarketViewSingleDetails(getInvoiceId.programId));
    } else if (
      getInvoiceId?.fundingChannel === "EPR_VIA_WALLET" ||
      "EPR_VIA_DIRECT_FUNDING_REQUEST"
    ) {
      dispatch(viewApprovedSingleEarlyPaymentDetails(getInvoiceId.programId));
    }
  }, [dispatch, getInvoiceId]);

  useEffect(() => {
    const id = getInvoiceId.invoiceId;
    dispatch(allInvoiceById({ id }));
  }, [dispatch, getInvoiceId?.invoiceId]);

  useEffect(() => {
    if (getInvoiceId.fundingChannel === "CLOSED_MARKET") {
      setData(closedMarketViewSingleDetailsData.data);
    } else if (
      getInvoiceId?.fundingChannel === "EPR_VIA_WALLET" ||
      "EPR_VIA_DIRECT_FUNDING_REQUEST"
    ) {
      setData(viewApprovedSingleEarlyPaymentDetailsData.data);
    }
  }, [
    viewApprovedSingleEarlyPaymentDetailsData,
    closedMarketViewSingleDetailsData,
    getInvoiceId,
  ]);

  useEffect(() => {
    setInvoiceData(invoiceIdData?.data);
  }, [invoiceIdData]);

  return (
    <>
      <div className="grid lg:grid-cols-[60%,40%] gap-5 h-[80vh]">
        {/* 60% */}
        <div className="flex flex-col gap-8 pb-24 bg-white rounded-[10px] pt-7">
          <div className="px-6 ">
            <div className="flex flex-col gap-3">
              <div className="flex items-center justify-between">
                <h1 className="text-[18px] font-bold">
                  Reference No: | {invoiceData?.invoiceRef}
                </h1>
              </div>

              <main className=" w-full flex justify-between">
                <div className="flex flex-col gap-3 w-1/2">
                  <div className="flex flex-col gap-1">
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs font-semibold w-[30%]">
                        Invoice Number:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        INV-{invoiceData?.invoiceNo}
                      </span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs font-semibold w-[30%]">
                        Created Date:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {invoiceData?.createdDate}
                      </span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs font-semibold w-[30%]">
                        Submitted Date:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {invoiceData?.submittedDate}
                      </span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs font-semibold w-[30%]">
                        Approved Date:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {invoiceData?.approvedDate}
                      </span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs font-semibold w-[30%]">
                        Due Date:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {invoiceData?.dueDate}
                      </span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs font-semibold w-[30%]">
                        Issunance Date:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {invoiceData?.issuanceDate}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="items-end">
                  {data?.fundingChannel === "WALLET" && (
                    <div>
                      <button className="w-[140px] h-[35px] bg-primaryColor rounded-[4px] text-white">
                        Disburse Now
                      </button>
                    </div>
                  )}

                  {data?.fundingChannel === "WALLET" && (
                    <div className="mt-4">
                      <button className="w-[120px] text-[14px] h-[35px] bg-[#D8EFD6] rounded-[4px] text-[#2EA923]">
                        Approved
                      </button>
                    </div>
                  )}
                </div>

                {data?.fundingChannel === "Direct Funding" && (
                  <div>
                    <button className="w-[140px] h-[35px] bg-[#D8EFD6] rounded-[4px] text-[#2EA923]">
                      Approved
                    </button>
                  </div>
                )}

                {data?.fundingChannel === "Closed Market" && (
                  <div>
                    <button className="w-[140px] h-[35px] border-primaryColor border-[1px] rounded-[4px] text-primaryColor">
                      Posted
                    </button>
                  </div>
                )}
              </main>
            </div>

            <div className="flex justify-between my-5 mt-15 pb-3 border-b-[5px] border-b-[#F6F5FA]">
              <div className="flex flex-col">
                <span className=" font-bold text-sm">
                  {invoiceData?.buyerCompanyName}
                </span>
                <span className="text-[#979797] text-xs">Buyer Details</span>
              </div>
              <div className="flex flex-col">
                <span className="font-bold text-sm">
                  {invoiceData?.paymentTerms} Days
                </span>
                <span className="text-[#979797] text-xs">Payment</span>
              </div>
              <div className="flex flex-col">
                <span className="font-bold text-sm">
                  {invoiceData?.currency}
                </span>
                <span className=" text-[#979797] text-xs">Currency</span>
              </div>
            </div>

            <div className="flex flex-col gap-4 mt-5">
              <header className="border-b-[5px] border-b-[#F6F5FA] pb-3 flex items-center justify-between">
                <h1 className="text-sm font-bold">Line Items</h1>
                <h1 className="text-sm font-bold">
                  {invoiceData?.items?.length} Total Item(s)
                </h1>
              </header>
              <div className=" w-full h-[25vh] overflow-x-auto">
                <table className="overflow-x-auto w-[60vw]">
                  <thead className="bg-subtleGray h-[70px] w-[50vw] overflow-x-auto">
                    <tr className=" bg-unusualGray p-2.5">
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Item
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Description
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Unit Price
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Quantity
                      </td>
                      <td className="text-[#11192A] p-2.5 text-xs font-bold w-[120px]">
                        Amount
                      </td>
                      <td className="text-[#11192A] p-2.5 text-xs font-bold w-[120px]">
                        Tax rate
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Tax Amount
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Total Price
                      </td>
                    </tr>
                  </thead>
                  {invoiceData?.items?.map((item, index) => (
                    <tbody key={index}>
                      <tr className=" py-[3px] px-1.5">
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.itemName}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5 w-[20%]">
                          {item?.description}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.unitPrice?.toLocaleString()}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.quantity}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.amount?.toLocaleString()}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.taxRate}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.taxAmount?.toLocaleString()}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.totalPrice?.toLocaleString()}
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            </div>

            <div className="flex itemscenter justify-between px-6 mt-[10%]">
              <div>
                <h1 className="border-b border-[#B1A6B2] font-semibold">
                  Receiving Funding Account Information
                </h1>
                <p className="mt-3 text[#979797]">
                  {invoiceData?.virtualAccount?.accountName}
                </p>
                <p className="text[#979797]">
                  {invoiceData?.virtualAccount?.accountNumber}
                </p>
              </div>
              <div className="flex flex-col">
                <div className="flex justify-between gap-x-[100px] w-full">
                  <span className="text-xs w-[97px]">Sub Total</span>
                  <span className="text-[#979797] text-xs text-right">
                    {invoiceData?.subTotal?.toLocaleString()}
                  </span>
                </div>

                <div className="flex justify-between w-full gap-x-[100px] my-2">
                  <span className="flex items-center text-xs w-[97px]">
                    Discount
                    <span className="border border-[#979797] pr-10 pl-2 inline-block ml-4">
                      {invoiceData?.discount}%
                    </span>
                  </span>
                  <span className="text-[#979797] text-xs">
                    {invoiceData?.discountAmount}
                  </span>
                </div>

                <div className="flex justify-between w-full gap-x-[100px] pb-5">
                  <span className="flex items-center text-xs w-[97px]">
                    VAT%
                    <span className="border border-[#979797] ml-10 pr-11 pl-2 inline-block">
                      {invoiceData?.vat}%
                    </span>
                  </span>
                  <span className="text-[#979797] text-xs">
                    {invoiceData?.vatAmount?.toLocaleString()}
                  </span>
                </div>

                <div className="flex justify-between items-center w-full gap-x-[100px] border-t-2 border-b-2 font-bold text-lg py-1">
                  <span className="w-[97px]">Grand Total</span>
                  <span className="text[#979797] py-1">
                    {invoiceData?.grandTotal?.toLocaleString()}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 40% */}
        <div className="bg-white rounded-[10px] gap-8 pt-5 pb-24">
          <div className="px-4">
            <h2 className="font-semibold text-lg leading-[30px] mb-4">
              Early Payment Details
            </h2>

            <div className="flex justify-between my-5">
              <div className="flex gap-2">
                {/* <PAIconModelOne /> */}
                <div className="flex flex-col">
                  <span className="text-sm">Supplier</span>
                  <span className="text-[#979797]  text-xs">
                    {" "}
                    {data?.supplierCompanyName}
                  </span>
                </div>
              </div>
              <div className="flex flex-col">
                <span className="text-sm">{data?.discountRate}%</span>
                <span className="text-[#979797]  text-xs">Discount rate</span>
              </div>
            </div>

            <div className="flex justify-between pb-5">
              <div className="flex gap-2">
                <div className="flex flex-col">
                  <span className="text-sm">
                    {invoiceData?.currency}{" "}
                    {data?.discountAmount?.toLocaleString() || "0.00"}
                  </span>
                  <span className="text-[#979797]  text-xs">
                    Discount Amount
                  </span>
                </div>
              </div>
              <div className="flex flex-col">
                <span className="text-sm">
                  {data?.earlyPaymentDate}({data?.paymentDay} days)
                </span>
                <span className="text-[#979797]  text-xs">
                  Early Payment Request Date
                </span>
              </div>
            </div>

            <div className="flex justify-between mt-5 border-b-[5px] border-b-[#F6F5FA] pb-10">
              <div className="flex gap-2">
                <div className="flex flex-col">
                  <span className="text-sm">
                    {invoiceData?.currency}{" "}
                    {data?.requestAmount?.toLocaleString() || "0.00"}
                  </span>
                  <span className="text-[#979797]  text-xs">
                    Request Amount
                  </span>
                </div>
              </div>
              <div className="flex flex-col">
                <span className="text-sm">
                  {invoiceData?.currency}{" "}
                  {data?.payableAmount?.toLocaleString()}
                </span>
                <span className="text-[#979797]  text-xs">Payable amount</span>
              </div>
            </div>

            {getInvoiceId.fundingChannel === "CLOSED_MARKET" && (
              <>
                <h2 className="font-semibold text-lg leading-[30px] mb-4">
                  Funder's Personal Details
                </h2>

                <div className="flex justify-between my-5">
                  <div className="flex gap-2">
                    {/* <PAIconModelOne /> */}
                    <div className="flex flex-col">
                      <span className="text-sm">Supplier</span>
                      <span className="text-[#979797]  text-xs">
                        {" "}
                        {data?.supplierCompanyName}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-sm">{data?.discountRate}%</span>
                    <span className="text-[#979797]  text-xs">
                      Discount rate
                    </span>
                  </div>
                </div>

                <div className="flex justify-between my-5">
                  <div className="flex gap-2">
                    {/* <PAIconModelOne /> */}
                    <div className="flex flex-col">
                      <span className="text-sm">Supplier</span>
                      <span className="text-[#979797]  text-xs">
                        {" "}
                        {data?.supplierCompanyName}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-sm">{data?.discountRate}%</span>
                    <span className="text-[#979797]  text-xs">
                      Discount rate
                    </span>
                  </div>
                </div>

                <div className="flex justify-between my-5">
                  <div className="flex gap-2">
                    {/* <PAIconModelOne /> */}
                    <div className="flex flex-col">
                      <span className="text-sm">Supplier</span>
                      <span className="text-[#979797]  text-xs">
                        {" "}
                        {data?.supplierCompanyName}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-sm">{data?.discountRate}%</span>
                    <span className="text-[#979797]  text-xs">
                      Discount rate
                    </span>
                  </div>
                </div>
              </>
            )}

            {["EPR_VIA_DIRECT_FUNDING_REQUEST", "EPR_VIA_WALLET"].includes(
              getInvoiceId.fundingChannel
            ) && (
              <>
                <div className="border-b-[5px] border-b-[#F6F5FA] pb-5">
                  <h2 className="font-medium text-base my-3">
                    Note from Supplier:
                  </h2>
                  <p className="text-[#959595] text-sm">{data?.description}</p>
                </div>

                <div className="pb-3">
                  <h2 className="font-medium text-base leading-[30px] my-2">
                    Funding Option
                  </h2>
                  <p className="text-[#959595] text-sm">
                    This invoice will be funded via this virtual account by
                  </p>
                  <p className="text-[16px] font-semibold text-slate-500">
                    {data?.buyerVirtualAccountName}
                  </p>
                  <p className="text-[16px] font-semibold text-slate-500">
                    {data?.buyerVirtualAccountNumber}
                  </p>
                  <p className="text-[16px] font-semibold text-slate-500">
                    PayEdge MFB
                  </p>
                </div>
              </>
            )}

            <div className="pb-3 border-t-[2px] border-[#c5c5c5]">
              <h2 className="font-medium text-base leading-[30px] my-2">
                Partial Payables
              </h2>

              <p className="text-[16px] font-semibold text-slate-500">
                NGN {invoiceData?.outstandingBalance}
              </p>
              <p className="text-[16px] font-regular text-slate-500">
                Balance Amount
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneralDetails;