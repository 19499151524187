import {
  PAIconArrowLeftGreen,
  PAIconDropdown,
  PAIconPaymnet,
} from "assets/images/svgs";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LinkExternalModal from "../../overview/LinkExternalAccount";
import CreateVirtualAccountModal from "../../overview/CreateVirtualAccount";
import CongratsModal from "features/wallet/linkExternalAccount/congrats/congratsModal";
import PinModal from "shared/PinModal";
import { useDispatch, useSelector } from "react-redux";
import WalletCard from "features/transactions/components/WalletCard";
import { allVirtualAccounts, postVirtualAccount, setSelectedAccounts, supplierWalletOverview, walletOverview } from "appstate/wallet/walletSlice";

const AccountManagementDashboard = () => {
  const [isLinkExternalModalOpen, setIsLinkExternalModalOpen] = useState(false);
  const [isCreateVirtualAccountModalOpen, setIsCreateVirtualAccountModalOpen] =
    useState(false);
  const [showPinModal, setShowPinModal] = useState(false);
  const [showCongratsModal, setShowCongratsModal] = useState(false);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleDropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const { allVirtualAccountsData, virtualAccountFormData } = useSelector((state) => state?.wallet);
  // const { externalAccountFormData, virtualAccountFormData, transferDetails } =
  // useSelector((state) => state?.wallet);

  const accountData = Array.isArray(allVirtualAccountsData?.data)
    ? allVirtualAccountsData?.data?.map((account) => ({
        id: account?.id,
        name: account?.accountName,
        accountNumber: account?.accountNumber,
        bankName: account?.provider || "Payedge",
        accountType: "Virtual Account",
        amount: account?.balance,
      }))
    : [];

  const formatAmount = (amount) => {
    if (!amount) return "0.00";
    const parsedAmount = parseFloat(amount);
    if (isNaN(parsedAmount)) return "0.00";
    return parsedAmount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const handleOutsideClick = (event) => {
  //   if (!event.target.closest('.dropdown')) {
  //     setIsDropdownOpen(false);
  //   }
  // };

  const handleOutsideClick = (event) => {
    if (
      !event.target.closest(".dropdown-button") &&
      !event.target.closest(".dropdown-button")
    ) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleLinkExternalClick = () => {
    setIsLinkExternalModalOpen(true);
  };

  const handleCreateVirtualAccountClick = () => {
    setIsCreateVirtualAccountModalOpen(true);
  };

  const handleCreateVirtualAcountSubmit = (pin) => {
    const body = {
      firstName: virtualAccountFormData?.firstName,
      lastName: virtualAccountFormData?.lastName,
      transactionPin: pin,
      bankName: virtualAccountFormData?.bank,
      currency: virtualAccountFormData?.currency,
    };

    dispatch(postVirtualAccount(body)).then((response) => {
      if (response?.payload?.status === 201 && response?.payload?.success) {
        setShowPinModal(false);
        setShowCongratsModal(true);
      }
    });
  };

  const handleVirtualAccountClick = (id) => {
    let path = `/wallet/accountManagement/${id}`;
    navigate(path);
  };

  const { userId } = useSelector((state) => state?.auth);
  const roleIndex = userId?.realm_access?.roles?.findIndex((arr) => {
    return (
      arr === "SUPPLIER" ||
      arr === "BUYER" ||
      arr === "FUNDER" ||
      arr === "ADMIN"
    );
  });
  const userRole = userId?.realm_access?.roles[roleIndex]?.toLowerCase();
  const { walletOverviewData, supplierWalletOverviewData } = useSelector(
    (state) => state?.wallet
  );

  const walletData =
    userRole === "supplier" ? supplierWalletOverviewData : walletOverviewData;

  const cardContents = [
    {
      label: "Available Balance",
      amount: formatAmount(walletData?.availableBalance),
      // percentage: "10",
      // info: "Increase since January",
    },
    {
      label:
        userRole === "buyer" || userRole === "funder"
          ? "Total Liened Balance"
          : "Total Partial Payment",
      amount:
        userRole === "buyer" || userRole === "funder"
          ? formatAmount(walletData?.totalLienedAmount)
          : userRole === "supplier"
          ? formatAmount(walletData?.totalPartialPayment)
          : "",
      // percentage: "40",
      // info: "Increase since January",
    },
    {
      label: "Total Deposit",
      amount: formatAmount(walletData?.totalDeposit),
      // percentage: "30",
      // info: "Increase since January",
    },
    {
      label: "Total Withdrawal",
      amount: formatAmount(walletData?.totalWithdrawal),
      // percentage: "25",
      // info: "Increase since January",
    },
    {
      label: "Total Invoices",
      amount: walletData?.totalInvoices,
      // percentage: "25",
      // info: "Increase since January",
    },
    {
      label:
        userRole === "buyer" || userRole === "funder"
          ? "Total Liened Invoices "
          : "Total Partially Paid Invoices",
      amount:
        userRole === "buyer" || userRole === "funder"
          ? walletData?.totalLienedInvoices
          : userRole === "supplier"
          ? walletData?.partiallyPaidInvoices
          : "",
      // percentage: "30",
      // info: "Increase since January",
    },
    {
      label:
        userRole === "buyer" || userRole === "funder"
          ? "Total Paid Invoices "
          : "Total Fully Paid Invoices",
      amount:
        userRole === "buyer" || userRole === "funder"
          ? walletData?.totalPaidInvoices
          : userRole === "supplier"
          ? walletData?.totalFullyPaidInvoices
          : "",
      // percentage: "25",
      // info: "Increase since January",
    },
  ];

  useEffect(() => {
    if (userRole === "supplier") {
      dispatch(supplierWalletOverview());
    } else {
      dispatch(walletOverview());
    }

    dispatch(allVirtualAccounts());
  }, [dispatch, userRole]);

  return (
    <div>
      {/* HEADER */}
      <header className="rounded-md w-full bg-white mb-4 flex items-center justify-between px-5">
        <Link to="/wallet" className="flex items-center gap-1 py-3">
          <PAIconArrowLeftGreen />
          <span className="text-primaryColor cursor-pointer">Go Back</span>
        </Link>
        <div className="relative">
          <div
            className="flex items-center justify-between gap-16 border border-gray p-1 px-3 rounded-t cursor-pointer dropdown-button"
            onClick={handleDropdownClick}
          >
            All Virtual Account <PAIconDropdown />
          </div>
          {isDropdownOpen && (
            <div className="absolute top-full right-0 bg-white border border-gray rounded-b shadow-md text-sm">
              {accountData?.map((item, id) => (
                <div
                  key={id}
                  className="py-2 px-6 hover:bg-gallery whitespace-break-spaces cursor-pointer"
                  onClick={() => {
                    dispatch(setSelectedAccounts(item));
                    handleVirtualAccountClick(item.id);
                  }}
                >
                  {item.name} - {item.accountNumber} - {item.bankName}
                </div>
              ))}
            </div>
          )}
        </div>
      </header>

      {/* ALL VIRTUAL ACCOUNT */}
      <div className="rounded-md w-full bg-white mb-4 px-5 p-5 mt-7">
        <div className="flex items-center justify-between">
          <div className="text-[#064731]">
            <p className="font-bold text-xl">Virtual Account</p>
            <p className="text-xs font-light">
              List of all virtual account available
            </p>
          </div>
          <div>
            <button
              onClick={handleLinkExternalClick}
              className="mr-3 border text-[#07593D] border-[#07593D] rounded p-2 px-5"
            >
              Link External Account
            </button>
            <button
              onClick={handleCreateVirtualAccountClick}
              className="text-white bg-[#F08000] rounded p-2 px-5"
            >
              Create Virtual Account
            </button>
          </div>
        </div>

        <div className="md:flex md:gap-32 flexwrap my-5">
          {accountData.map((account, index) => (
            <div
              key={index}
              className="bg-[#07593D] p-3 mt-3 cursor-pointer text-white text-xs md:w-[calc(50%-10px)] md:mb-0 mb-3 rounded"
              onClick={() => {
                dispatch(setSelectedAccounts(account));
                handleVirtualAccountClick(account.id);
              }}
            >
              <div className="flex justify-between">
                <div className="text-white flex items-center">
                  <PAIconPaymnet className="mr-2 text-sm font-bold" />{" "}
                  <p>{account.name}</p>
                </div>
                <p className="text-white font-light text-xs my-2">
                  {account.accountType}
                </p>
              </div>
              <p className="mt-2 italic font-bold text-[18px] flex justify-start">
                {account.currency} {formatAmount(account.amount)}
              </p>
              <p className="mt-5 text-xs">
                <span className="mr-1">{account.bankName}</span> |
                <span className="ml-1">{account.accountNumber}</span>
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* ACCOUNT SUMMARY */}
      <div className="bg-alabasterHeader py-[10px] px-2 rounded-md">
        <div className=" text-[#064731] mb-2 px-2">
          <p className="text-xl font-bold">My Account</p>
          <p className="text-xs font-normal">Account Summary</p>
        </div>
        <div className="flex flex-wrap justify-center mx-auto w-full mb-5">
          {cardContents.map((content, idx) => (
            <div
              key={idx}
              className="w-full md:w-1/4 lg:w-1/4 xl:w-1/4 mb-6 px-2"
            >
              <WalletCard
                label={content?.label}
                amount={content?.amount}
                percentage={content?.percentage}
                info={content?.info}
              />
            </div>
          ))}
        </div>
      </div>

      {/* MODALS */}
      {isLinkExternalModalOpen && (
        <LinkExternalModal onClose={() => setIsLinkExternalModalOpen(false)} />
      )}

      {isCreateVirtualAccountModalOpen && (
        <CreateVirtualAccountModal
          isOpen={isCreateVirtualAccountModalOpen}
          onClose={() => setIsCreateVirtualAccountModalOpen(false)}
          //   onPinOpen={() => console.log("Pin modal open")}
          //   onPinOpen={() => setShowPinModal(true)}
          onPinOpen={() => {
            setShowPinModal(true);
            setIsCreateVirtualAccountModalOpen(false);
          }}
        />
      )}

      {/* {showPinModal && (
        <PinModal
          isOpen={showPinModal}
          onClose={() => {
            setShowPinModal(false);
          }}
          onSubmit={(pin) => {
            setShowPinModal(false);
            setShowCongratsModal(true);
          }}
          headerText="Create Virtual Account"
          instructionText="Please enter your PIN to approve the creation of this virtual account."
        />
      )} */}
      {showPinModal && (
        <PinModal
          isOpen={showPinModal}
          onClose={() => {
            setShowPinModal(false);
          }}
          onSubmit={handleCreateVirtualAcountSubmit}
          headerText="Create Virtual Account"
          instructionText="Please enter your PIN to approve the creation of this virtual account."
        />
      )}

      {showCongratsModal && (
        <CongratsModal
          isOpen={showCongratsModal}
          onClose={() => {
            setShowCongratsModal(false);
          }}
          message={`Your Virtual Account has been created`}
        />
      )}
    </div>
  );
};

export default AccountManagementDashboard;
