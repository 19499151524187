import React, { useState, useRef, useEffect } from "react";
import {
  approveDirectFundingRequest,
  saveBulkEffectiveDiscount,
  saveSingleEffectiveDiscount,
} from "appstate/funder/funderSlice";
import { PAIconCancelMini } from "assets/images/svgs";
import { useSelector, useDispatch } from "react-redux";
import toast from "react-hot-toast";

const BidPinModal = ({
  isOpen,
  onClose,
  handleNext,
  checkedAccount,
  isBulk,
  rateEditable,
}) => {
  const [otpInputs, setOtpInputs] = useState(["", "", "", ""]);
  const {
    funderEarlyPaymentViewBulkPendingDetailsData,
    funderEarlyPaymentViewSinglePendingDetailsData,
  } = useSelector((state) => state?.funder);
  const dispatch = useDispatch();
  const { invoiceIdData } = useSelector((state) => state.invoice);
  const [data, setData] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);
  const [updatedEffectiveData, setUpdatedEffectiveData] = useState([]);
  const [updatedBulkEffectiveData, setUpdatedBulkEffectiveData] = useState([]);
  const [payable, setPayable] = useState([]);
  const [request, setRequest] = useState([]);
  const [fundable, setFundable] = useState([]);
  const [discountA, setDiscountA] = useState([]);

  const isDisabled = otpInputs[otpInputs.length - 1] === "";
  useEffect(() => {
    setInvoiceData(invoiceIdData?.data);
  }, [invoiceIdData]);

  const inputRefs = useRef([]);

  const { updatedEffectiveDataResponse, updatedBulkEffectiveDataResponse } =
    useSelector((state) => state?.funder);

  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, otpInputs.length);
  }, [otpInputs.length]);

  const handleInputChange = (index, value) => {
    if (!isNaN(value) || value === "") {
      const newOtpInputs = [...otpInputs];
      newOtpInputs[index] = value;
      setOtpInputs(newOtpInputs);

      if (value !== "" && index < otpInputs.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  useEffect(() => {
    setUpdatedEffectiveData(updatedEffectiveDataResponse);
  }, [updatedEffectiveDataResponse]);

  useEffect(() => {
    setUpdatedBulkEffectiveData(updatedBulkEffectiveDataResponse);
  }, [updatedBulkEffectiveDataResponse]);

  useEffect(() => {
    if (isBulk) {
      setData(funderEarlyPaymentViewBulkPendingDetailsData?.data);
    } else {
      setData(funderEarlyPaymentViewSinglePendingDetailsData?.data);
    }
  }, [
    funderEarlyPaymentViewBulkPendingDetailsData,
    funderEarlyPaymentViewSinglePendingDetailsData,
    isBulk,
  ]);

  // const funderbodySingle = {
  //   earlyPaymentId: data?.earlyPaymentId,
  //   directFundingId: data?.directFundingId,
  //   virtualAccountId: checkedAccount?.id,
  //   invoiceAmount: invoiceData?.grandTotal,
  //   payableAmount: data?.fundableAmount,
  //   requestedAmount: data?.requestAmount,
  //   fundableAmount: data?.fundableAmount,
  //   discountAmount: data?.discountAmount,
  //   transactionPin: otpInputs.join(""),
  //   buyerId: data?.userId,
  // };

  useEffect(() => {
    if (updatedEffectiveData?.length > 0) {
      setPayable(
        updatedEffectiveData?.data?.payableAmount ||
          updatedEffectiveData?.data?.totalPayableAmount
      );
      setRequest(
        updatedEffectiveData?.data?.requestAmount ||
          updatedEffectiveData?.data?.totalRequestAmount
      );
      setFundable(
        updatedEffectiveData?.data?.fundableAmount ||
          updatedEffectiveData?.data?.totalFundableAmount
      );
      setDiscountA(
        updatedEffectiveData?.data?.discountedAmount ||
          updatedEffectiveData?.data?.totalDiscountedAmount
      );
    } else {
      setPayable(data?.payableAmount || data?.totalPayableAmount);
      setRequest(data?.requestAmount || data?.totalRequestAmount);
      setFundable(data?.fundableAmount || data?.totalFundableAmount);
      setDiscountA(data?.discountAmount || data?.totalDiscountAmount);
    }
  }, [updatedEffectiveData, data]);

  const funderbodySingle = {
    earlyPaymentId: data?.earlyPaymentId,
    directFundingId: data?.directFundingId,
    virtualAccountId: checkedAccount?.id,
    invoiceAmount: invoiceData?.grandTotal,
    payableAmount: payable,
    requestedAmount: request,
    fundableAmount: fundable,
    discountAmount: discountA,
    transactionPin: otpInputs.join(""),
    buyerId: invoiceData?.buyerId || data?.buyerId,
  };

  console.log(updatedEffectiveData);

  const funderbodyBulk = {
    earlyPaymentId: data?.earlyPaymentId,
    directFundingId: data?.directFundingId,
    virtualAccountId: checkedAccount?.id,
    invoiceAmount: invoiceData?.grandTotal,
    payableAmount: payable,
    requestedAmount: request,
    fundableAmount: fundable,
    discountAmount: discountA,
    transactionPin: otpInputs.join(""),
    buyerId: invoiceData?.buyerId || data?.buyerId,
  };

  // const handleFundingApproval = async () => {
  //   const saveBody = {
  //     earlyPaymentRequestId: data?.earlyPaymentId,
  //     invoiceId: data?.invoiceId,
  //     newDiscountRate: updatedEffectiveData?.data?.discount,
  //     // updatedDiscountData?.data?.discount,
  //   };
  //   // console.log("ndr: " + saveBody.newDiscountRate);

  //   try {
  //     const saveResponse = await dispatch(
  //       saveSingleEffectiveDiscount({ body: saveBody })
  //     ).unwrap();
  //     if (saveResponse.success) {
  //       toast.success("Discount saved successfully:", saveResponse);
  //     } else {
  //       toast.error(saveResponse.message || "Error saving discount.");
  //       return; // If saving fails, stop the process here
  //     }
  //   } catch (error) {
  //     toast.error("Error saving adjusted discount.");
  //     return; // If saving fails, stop the process here
  //   }

  //   // console.log("Save Body:", saveBody);

  //   // try {
  //   //   // Check if it's bulk or single
  //   //   let saveResponse;

  //   //   if (isBulk) {
  //   //     // For bulk, dispatch saveBulkDiscountRate
  //   //     saveResponse = await dispatch(
  //   //       saveBulkDiscountRate({ body: saveBody })
  //   //     ).unwrap();
  //   //   } else {
  //   //     // For single, dispatch saveSingleDiscountRate
  //   //     saveResponse = await dispatch(
  //   //       saveSingleDiscountRate({ body: saveBody })
  //   //     ).unwrap();
  //   //   }

  //   //   // Handle the response after saving
  //   //   if (saveResponse.success) {
  //   //     toast.success("Discount saved successfully:", saveResponse);
  //   //   } else {
  //   //     toast.error(saveResponse.message || "Error saving discount.");
  //   //     return; // If saving fails, stop the process here
  //   //   }
  //   // } catch (error) {
  //   //   toast.error("Error saving adjusted discount.");
  //   //   return; // If saving fails, stop the process here
  //   // }

  //   try {
  //     const response = await dispatch(
  //       approveDirectFundingRequest(funderbodySingle)
  //     ).unwrap();
  //     if (response?.status === 200) {
  //       handleNext();
  //     } else {
  //       toast.error(response.message);
  //     }
  //   } catch (error) {
  //     console.error("Error", error);
  //   }
  // };

  const handleFundingApproval = async () => {
    // Collect invoice IDs based on whether it's bulk or single
    let invoiceId = [];

    if (isBulk) {
      invoiceId =
        data?.earlyPaymentInvoiceResponse?.map(
          (invoice) => invoice.invoicesId
        ) || [];
    } else {
      invoiceId = data?.invoiceId?.toString() || ""; // Ensures it's a string, not an array
    }

    // Determine which discount rate to use based on bulk or single
    let newDiscountRate;
    if (isBulk) {
      newDiscountRate = updatedBulkEffectiveData?.data?.discount;
    } else {
      newDiscountRate = updatedEffectiveData?.data?.discount;
    }

    // Create the saveBody object
    const saveBody = {
      earlyPaymentRequestId: data?.earlyPaymentId,
      invoiceId, // Array for bulk, string for single
      newDiscountRate,
    };

    // try {
    //   // Dispatch saveSingleEffectiveDiscount for both bulk and single cases
    //   const saveResponse = await dispatch(
    //     saveSingleEffectiveDiscount({ body: saveBody })
    //   ).unwrap();
    try {
      // Check if it's bulk or single
      let saveResponse;
      if (rateEditable === true) {
        if (isBulk) {
          // For bulk, dispatch saveBulkDiscountRate
          saveResponse = await dispatch(
            saveBulkEffectiveDiscount({ body: saveBody })
          ).unwrap();
        } else {
          // For single, dispatch saveSingleDiscountRate
          saveResponse = await dispatch(
            saveSingleEffectiveDiscount({ body: saveBody })
          ).unwrap();
        }
        if (saveResponse.success) {
          toast.success("Discount saved successfully.");
        } else {
          toast.error(saveResponse.message || "Error saving discount.");
          return; // Stop the process if saving fails
        }
      }
    } catch (error) {
      toast.error("Error saving adjusted discount.");
      return; // Stop the process if saving fails
    }

    // Determine the correct body for fund approval
    const funderbody = isBulk ? funderbodyBulk : funderbodySingle;

    try {
      const response = await dispatch(
        approveDirectFundingRequest(funderbody)
      ).unwrap();

      if (response?.status === 200) {
        handleNext();
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && index > 0 && otpInputs[index] === "") {
      const newOtpInputs = [...otpInputs];
      newOtpInputs[index - 1] = "";
      setOtpInputs(newOtpInputs);

      inputRefs.current[index - 1].focus();
    }
  };

  if (!isOpen) return null;

  return (
    <>
      <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg rounded-[10px] z-[1000]">
        <div className="bg-white p10 px5 rounded-md max-w-xs md:max-w-lg w-full pb-5 px10">
          <div className="flex justify-between items-center py-5 px-6 rounded-t-[10px]  bg-[#FAFAFA]">
            <p className="text-sm text-black font-thin">Enter Pin</p>
            <button
              onClick={onClose}
              className="gap-2 text-xl cursor-pointer flex justify-center items-center"
            >
              <PAIconCancelMini />{" "}
              <span className="text-sm text-[#565656] flex items-center">
                Close
              </span>
            </button>
          </div>

          {/* BVN Form */}
          <form
            className="w-full px-7 pt-7"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <p className="mb-5 flex justify-center text-black  text-base font-semibold  italic">
              Enter your PIN
            </p>

            <div className="mb-5 flex flex-row justify-center gap-2">
              {otpInputs.map((value, index) => (
                <input
                  key={index}
                  type="text"
                  value={value.replace(/./g, "*")}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                  maxLength="1"
                  className={`border rounded p-2 ${
                    isDisabled
                      ? "border-[#07593D] focus:border-[#07593D]"
                      : "border-[#07593D]"
                  }    focus:outline-none w-10 h-10 flex-shrink-0 text-center font-bold`}
                  ref={(ref) => (inputRefs.current[index] = ref)}
                />
              ))}
            </div>
            <p className="text-[#7A7A7A] text-sm font-normal flex justify-center my-5">
              Enter your transaction PIN to verify your identity
            </p>

            {/* Buttons */}
            <div className="flex gap-4 justify-end mt-10">
              <button
                type="button"
                onClick={onClose}
                className="text-center px-6 py-2.5 rounded-[5px] text-[#7A7A7A] text-base font-normal font-['SF Pro Display'] tracking-wide"
              >
                Cancel
              </button>

              <button
                onClick={handleFundingApproval}
                disabled={isDisabled}
                className={`text-center ${
                  isDisabled
                    ? "bg-[#EDEDED] text-[#7A7A7A]"
                    : "bg-primaryColor text-white"
                }  px-6 py-2.5 rounded-[5px]  text-base font-normal`}
                type="submit"
              >
                Next
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default BidPinModal;
