import React, { useEffect, useCallback, useMemo } from "react";
import DashboardLayout from "layout/dashboardlayout";
import {
  PAIconArrowLeftGreen,
  PAIconVieweye,
  PAIconWorkTopFilerIcon,
  PAIconOutlineSearch,
} from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import Table from "layout/table/Table";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import Pagination from "shared/Pagination";

// import { useNavigate } from "react-router-dom";
import Card from "shared/Card";
import { fetchRecentWorkflowApprovals } from "appstate/workflow/workflowSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import TableEmptyState from "shared/TableEmptyState";
import * as XLSX from "xlsx";

const ApprovalHistoryAllDetails = () => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/work-flow-management/");
  };

  const [search] = useState("");
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(10);
  const pageSize = 10;
  const [activeFilter, setActiveFilter] = useState("");

  const handleRowClick = (row) => {
    if (row.status === "COMPLETED") {
      navigate(
        `/work-flow-management/workflowcompletedpendingapprovaldetails/${row.workflowId}`
      );
    } else if (row.status === "DECLINED") {
      navigate(
        `/work-flow-management/detail/pendingdeclineddetails/${row.workflowId}`
      );
    } else if (row.type === "PFP" && row.action === "Sign PFP Agreement") {
      navigate(
        `/work-flow-management/detail/pendingrequestsdetailssignpfp/${row.workflowId}`
      );
    } else if (row.type === "PFP" && row.action === "Fund Offer") {
      navigate(
        `/work-flow-management/detail/PendingRequestPFPFundOffer/${row.workflowId}`
      );
    } else {
      navigate(
        `/work-flow-management/workflowapprovedpendingapprovaldetails/${row.workflowId}`
      );
    }
  };

  const columns = [
    {
      name: "Date",
      selector: (row) => row?.dateSent,
      sortable: true,
    },
    {
      name: "Sender",
      selector: (row) => row?.sender,
    },

    {
      name: "Transaction Type",
      selector: (row) => row?.type,
    },
    {
      name: "Approval Date",
      selector: (row) => row?.approvalDate,
      sortable: true,
    },

    {
      name: "Approval Level",
      selector: (row) => row?.approvalStage,
    },

    {
      name: "Status",
      selector: (row) => row?.status,
      sortable: true,
      cell: (row) =>
        row?.status === "APPROVED" ? (
          <p className="text-[#2EA923]">{row?.status}</p>
        ) : row?.status === "DECLINED" ? (
          <p className="text-[#DE1515]">{row?.status}</p>
        ) : row?.status === "COMPLETED" ? (
          <p className="text-[#2EA923]">{row?.status}</p>
        ) : (
          <p>{row?.status}</p>
        ),
    },
    {
      name: "",
      selector: (row) => row.view,
      cell: (row) => (
        <div className="flex">
          <PAIconVieweye height="15px" width="30px" />
          <p className="text-[#626679] cursor-pointer">View</p>
        </div>
      ),
    },
  ];

  const dispatch = useDispatch();

  const { recentWorkflowApproval } = useSelector((state) => state.workflow);

  useEffect(() => {
    dispatch(fetchRecentWorkflowApprovals({ page, pageSize, search }));
  }, [dispatch, page, search, pageSize]);

  const [searchValue, setSearchValue] = useState(""); // To store the search input value
  const [filteredData, setFilteredData] = useState(
    recentWorkflowApproval?.data || []
  ); // To store the filtered data

  const data = useMemo(
    () => recentWorkflowApproval?.data || [],
    [recentWorkflowApproval?.data]
  );

  useEffect(() => {
    const filterValue = activeFilter || "";

    if (filterValue === "") {
      setFilteredData(data); // Show all items
    } else if (filterValue === "INVOICING") {
      setFilteredData(data.filter((bid) => bid.type === "  INVOICING"));
    } else if (filterValue === "EARLY_PAYMENT_REQUEST") {
      setFilteredData(
        data.filter((bid) => bid.type === "EARLY_PAYMENT_REQUEST")
      );
    } else if (filterValue === "EARLY_PAYMENT_OFFERS") {
      setFilteredData(
        data.filter((bid) => bid.type === "EARLY_PAYMENT_OFFERS")
      );
    } else if (filterValue === "OPEN_MARKET") {
      setFilteredData(data.filter((bid) => bid.type === "OPEN_MARKET"));
    } else if (filterValue === "DIRECT_FUNDING") {
      setFilteredData(data.filter((bid) => bid.type === "DIRECT_FUNDING"));
    } else if (filterValue === "PFP") {
      setFilteredData(data.filter((bid) => bid.type === "PFP"));
    }
  }, [activeFilter, data]);

  useEffect(() => {
    setFilteredData(recentWorkflowApproval?.data || []); // Reset filtered data when new data is fetched
  }, [recentWorkflowApproval]);

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(
      filteredData.map((row) => ({
        Date: row?.dateSent,
        Sender: row?.sender,
        "Transaction Type": row?.type,
        "Approval Date": row?.approvalDate,
        "Payment Date": row?.paymentDate,
        "Approval Level": row?.approvalStage,
        Status: row?.status,
      }))
    );
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Direct Funding");
    XLSX.writeFile(wb, "ApprovalHistory.xlsx");
  };

  // Update filtered data whenever the selected type changes

  const filterData = useCallback(() => {
    let updatedData = [...data];

    // Apply filter by type
    if (activeFilter) {
      updatedData = updatedData.filter((item) => item.type === activeFilter);
    }

    const lowerSearch = searchValue.toLowerCase();

    updatedData = updatedData.filter((item) => {
      return (
        (item.status &&
          item.status.toString().toLowerCase().includes(lowerSearch)) ||
        (item.type && item.type.toLowerCase().includes(lowerSearch)) ||
        (item.sender && item.sender.toLowerCase().includes(lowerSearch)) ||
        (item.action && item.action.toLowerCase().includes(lowerSearch)) ||
        (item.approvalStage &&
          item.approvalStage.toLowerCase().includes(lowerSearch)) ||
        (item.approvalDate &&
          item.approvalDate.toLowerCase().includes(lowerSearch)) ||
        (item.dateSent &&
          item.dateSent.toString().toLowerCase().includes(lowerSearch)) ||
        (item.previousApprover &&
          item.previousApprover.toLowerCase().includes(lowerSearch))
      );
    });

    if (updatedData.length === 0) {
      setFilteredData([]);
    } else {
      setFilteredData(updatedData);
    }
  }, [data, activeFilter, searchValue, setFilteredData]);

  useEffect(() => {
    filterData();
  }, [filterData]);

  // Synchronize data when filters or data chang

  // Synchronize data when filters or data change

  const handleFilterChangeOne = (e) => {
    setActiveFilter(e.target.value);
  };

  const handleSearchChangeOne = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <DashboardLayout
      section="Workflow "
      subHeader="Manage all your workflow approvals"
    >
      <div>
        {" "}
        <header className="bg-white text-primaryColor px-6  rounded-[5px]">
          <span
            onClick={() => handleBack()}
            className="flex cursor-pointer py-4 w-fit font-light items-center text-sm gap-2"
          >
            <PAIconArrowLeftGreen />
            Go Back
          </span>
        </header>
        <>
          <div className="my-5 grid md:grid-cols-2 items-center">
            <div className="gap-3 flex relative">
              <div>
                {/* <select
                  className="border border-[#DEDEDE] shadow-sm rounded-[5px] px-4 py-3 text-sm focus:outline-none cursor-pointer"
                  onChange={(e) => {}}
                >
                  <option value="" disabled selected>
                    Sort by{" "}
                  </option>
                  <option value="date">Date</option>
                  <option value="name">Approval date</option>
                </select> */}
              </div>
              {filteredData.length > 0 && (
                <button
                  className="py-2 px-4 rounded-[5px] bg-white border-[#DEDEDE] shadow-sm mr-2 "
                  onClick={exportToExcel}
                >
                  Export
                </button>
              )}
            </div>
            <div className="flex md:col-span-1 justify-end sm:justify-start xs:justify-start sm:w-full sm:block xs:w-full ">
              {/* SAERCH SECTION */}

              <div>
                <div className="flex items-center lg:justify-end gap-5">
                  <div className="border border-transparent md:border-gray md:shadow-sm my-4 rounded-[5px] px-2 lg:w-[300px] bg-white sm:w-full block">
                    <div className="items-center flex bg-white">
                      <PAIconOutlineSearch />
                      <input
                        className="bg-transparent p-2 w-full focus:outline-none text-sm bg-white"
                        type="text"
                        placeholder="Search by sender name,  transaction type or status"
                        value={searchValue}
                        onChange={handleSearchChangeOne}
                      />
                    </div>
                    {/* <div className="lg:hidden flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer">
                    <PAIconFilter className="bg-[#2FA06A]" />
                  </div> */}
                  </div>
                </div>
                {/* stop */}
              </div>
            </div>
          </div>

          {/* Neext down section */}

          <AnimatePresence>
            <Card className="rounded-[10px] mt-[24px]  lg:h-[784px]  bg-black">
              <div className=" justify-between items-center px-[24px] bg-alabasterHeader  rounded-t-[10px] lg:flex md:flex  w-full sm:block  min-h-[60px] ">
                <div className=" sm:w-full sm:block sm:py-2  xs:w-full xm:block xm:py-2">
                  <p className="text-[#1d0220] text-sm font-bold">
                    Approval History
                  </p>
                  <p className="text-[#5f4d61] text-[10px] font-normal">
                    Workflow approval history
                  </p>
                </div>
                <div>
                  {recentWorkflowApproval?.data && (
                    <div className=" flex  gap-2 items-center lg:justify-center sm:justify-end sm:mt-2 xs:mt-2">
                      {" "}
                      <PAIconWorkTopFilerIcon />
                      <span className="text-[#7a7a7a] text-sm font-normal leading-normal">
                        Filter:
                      </span>
                      <div>
                        <select
                          className="border  shadow-sm rounded-[5px] px-4 py-2.5   focus:outline-none cursor-pointer lg:w-[143px] md:w-[143px] h-9  bg-white  border-[#cccccc] justify-start items-center  text-[#7a7a7a] text-xs font-normal"
                          onChange={handleFilterChangeOne}
                          value={activeFilter}
                        >
                          <option value="" disabled selected>
                            Filter by{" "}
                          </option>

                          <option value={"INVOICING"}>INVOICING</option>
                          <option value="EARLY_PAYMENT_REQUEST">
                            EARLY_PAYMENT_REQUEST
                          </option>
                          <option value="EARLY_PAYMENT_OFFERS">
                            EARLY_PAYMENT_OFFERS
                          </option>
                          <option value="OPEN_MARKET">OPEN_MARKET</option>
                          <option value="DIRECT_FUNDING">DIRECT_FUNDING</option>
                          <option value="PFP">PFP</option>
                        </select>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="px-[24px] ">
                {recentWorkflowApproval?.data &&
                recentWorkflowApproval?.data?.length > 0 ? (
                  <Table
                    columns={columns}
                    data={filteredData}
                    onRowClicked={(row) => handleRowClick(row)}
                    pointer
                    tableHeader
                    className="mt-5"
                  />
                ) : (
                  <TableEmptyState dashboard />
                )}
              </div>

              <div>
                {recentWorkflowApproval?.meta?.totalElements &&
                  recentWorkflowApproval?.meta?.totalElements > 10 && (
                    <div className="flex justify-center gap-x-[8px]  mt-5">
                      <Pagination
                        page={page}
                        startPage={startPage}
                        setEndPage={setEndPage}
                        endPage={endPage}
                        setStartPage={setStartPage}
                        setPage={setPage}
                        totalPages={recentWorkflowApproval?.meta?.totalElements}
                      />
                    </div>
                  )}
              </div>
            </Card>
          </AnimatePresence>
        </>
      </div>
    </DashboardLayout>
  );
};

export default ApprovalHistoryAllDetails;
