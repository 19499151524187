import DashboardLayout from "layout/dashboardlayout";
import Card from "shared/Card";
import { useState } from "react";
import RulesConfigTable from "./components/RulesConfigTable";
import RulesConfigHeader from "./components/RulesConfigHeader";

function RuleConfigScreen() {
  const [ruleSavedModal, setRuleSavedModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchBy, setSearchBy] = useState("name");
  const [testModal, setTestModal] = useState(false);

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  return (
    <DashboardLayout subHeader="Here is what is happening in your finance">
      <Card className="px-10 pt-10 pb-7 rounded-[5px]">
        <div>
          <>
            <RulesConfigHeader
              setTestModal={setTestModal}
              setRuleSavedModal={setRuleSavedModal}
              handleSearch={handleSearch}
              searchBy={searchBy}
              setSearchBy={setSearchBy}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
            <RulesConfigTable
              testModal={testModal}
              setTestModal={setTestModal}
              ruleSavedModal={ruleSavedModal}
              setRuleSavedModalOpen={setRuleSavedModal}
              searchQuery={searchQuery}
              searchBy={searchBy}
              setSearchQuery={setSearchQuery}
            />
          </>
        </div>
      </Card>
    </DashboardLayout>
  );
}

export default RuleConfigScreen;
