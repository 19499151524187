import { PAIconInventoryArrowLeft } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { useNavigate, useParams } from "react-router-dom";
import Card from "shared/Card";
import { motion } from "framer-motion";
import CocaCola from "../../assets/images/svgs/cocacola.svg";
import { useEffect, useState } from "react";
import { cancelEarlyPaymentRequest, getEarlyPaymentRequestBulkTemp } from "appstate/api/invoice/invoice";
import toast from "react-hot-toast";
import { formatCurrency } from "utilities/helper/formatters";
import EarlyPaymentConfirmation from "features/invoice/fundingSection/earlyPaymentRequest/singlerequest/Modal/EarlyPaymentConfirmation";

const SupplierBulkEarlyPaymentDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [earlyPaymentData, setEarlyPaymentData] = useState(null);
  const [open, setOpen] = useState(false);

  // eslint-disable-next-line
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEarlyPaymentBulkTemp = async () => {
      if (!id) {
        console.error("ID is not defined");
        return;
      }

      setLoading(true);
      try {
        const response = await getEarlyPaymentRequestBulkTemp(id);
        setEarlyPaymentData(response?.data)
      } catch (err) {
        toast.error(err?.response?.data?.message || "Error fetching bulk data.");
      } finally {
        setLoading(false);
      }
    };

    fetchEarlyPaymentBulkTemp();
  }, [id]);

  const handleCancelRequest = async () => {
    try {
      setLoading(true);
      await cancelEarlyPaymentRequest({ id });
      toast.success("Early Pyayment request canceled successfully!");
      navigate("/supplier-early-payments");
    } catch (error) {
      toast.error(error?.response?.data?.message || "Failed to cancel request!");
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  const handleConfirmModal = () => {
    setOpen(true);
  };

  function handleNavigate() {
    let path = -1;
    navigate(path);
  }

  return (
    <DashboardLayout
      section="Invoice"
      subHeader="Here is an overview of your invoices"
    >
      {open && (
        <div className="absolute mt-[20px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white w-[614px] h-[254px] z-10 rounded-[10px] shadow-lg">
          <EarlyPaymentConfirmation
            hide={() => setOpen(false)}
            // isLoading={earlyPaymentLoading}
            handleAssociate={handleCancelRequest}
            earlyPayment={true}
            invoice={true}
            warning={
              <>
                Are you sure you want to cancel this request?
              </>
            }
          />
        </div>
      )}
      <header>
        <div
          onClick={handleNavigate}
          className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
        >
          <PAIconInventoryArrowLeft />
          <p className="mt-[2px] text-sm font-normal text-lightGray">Go Back</p>
        </div>
      </header>
      <div className="flex justify-between">
        <Card className="w-[60%] p-[24px] rounded-[10px]">
          <div className=" flex  flex-col gap-3 ">
            <main className=" w-full flex justify-between">
              <div className="flex flex-col gap-3 w-1/2">
                <div className="flex">
                  <span>Reference No:</span>
                  <span className="pl-2">|{earlyPaymentData?.requestReferenceNumber}</span>
                </div>
                <div className="flex flex-col gap-1">
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Request Date:</span>
                    <span className="text-[#979797]  text-xs">
                      {earlyPaymentData?.paymentDate}
                    </span>
                  </div>
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Total Request Amount:</span>
                    <span className="text-[#979797]  text-xs">
                      {`${earlyPaymentData?.currency + ""}`} {formatCurrency(Number(earlyPaymentData?.totalRequestAmount))}
                    </span>
                  </div>
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Total Discount Amount:</span>
                    <span className="text-[#979797]  text-xs">
                    {`${earlyPaymentData?.currency + ""}`} {formatCurrency(Number(earlyPaymentData?.totalDiscountAmount))}
                    </span>
                  </div>
                  <div className="flex gap-x-[42px]">
                    <span className=" text-xs">Total Invoice Amount:</span>
                    <span className="text-[#979797]  text-xs">
                    {`${earlyPaymentData?.currency + ""}`} {formatCurrency(Number(earlyPaymentData?.totalInvoiceAmount))}
                    </span>
                  </div>
                </div>
              </div>

              <div className="flex flex-col justify-between">
                <motion.button
                  whileTap={{ scale: 0.9 }}
                  className="flex bg-[#FFBE5557] text-sm justify-center gap-2 text-[#F08000] rounded-[5px] items-center px-[35px] py-1.5 "
                >
                  Pending Approval

                </motion.button>
              </div>

            </main>
          </div>
          <br />
          <div className="flex justify-between">
            <div className="flex flex-col">
              <span className="font-light  text-xs">{earlyPaymentData?.buyerCompanyName}</span>
              <span className="text-[#979797] text-sm">buyer details</span>
            </div>
            
            <div className="flex flex-col">
              <span className="flex justify-end text-xs">
              {`${earlyPaymentData?.currency + ""}`}
              </span>
              <span className="text-[#979797] font-light text-sm">Currency</span>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <header className="border-b-[5px] border-b-[#F6F5FA] pb-3"></header>
            <div className="flex  justify-between">
              <div className="flex gap-2"></div>
              <div className="flex gap-2">
                <div className="flex flex-col">
                  <span className="text-[#979797]  text-xs">{earlyPaymentData?.noOfInvoices} Invoices</span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
              <h1 className="text-sm">Invoice List</h1>
            </header>
            <div className=" w-full overflow-x-auto">
              <table className=" w-full overflow-x-auto">
                <thead className="bg-subtleGray w-">
                  <tr className=" bg-unusualGray p-2.5 ">
                    <td className="text-[#11192A] p-2.5 text-xs font-bold">
                      Ref Number
                    </td>
                    <td className=" p-2.5 text-xs font-bold">Due Date</td>
                    <td className=" p-2.5 text-xs font-bold">Request Amount</td>
                    <td className=" p-2.5 text-xs font-bold">Discount Rate</td>
                   
                    <td className=" p-2.5 text-xs font-bold">
                      Discount Amount
                    </td>
                    <td className=" p-2.5 text-xs font-bold">
                      Receivable Amount
                    </td>
                    {/* <td className=" p-2.5 text-xs font-bold">Total Price</td> */}
                  </tr>
                </thead>
                {earlyPaymentData?.earlyPaymentInvoiceResponse.map((data) => (
                  <tbody>
                    <tr
                      style={{ cursor: "pointer" }}
                      // onClick={onRowClicked}
                      className="py-[3px] px-1.5"
                    >
                      <td className="text-xs text-[#11192A] p-2.5">
                        {data?.invoiceRef}
                      </td>
                      <td className="text-xs text-[#11192A] p-2.5">
                        {data?.requestDate}
                      </td>
                      <td className="text-xs text-[#11192A] p-2.5">
                        {formatCurrency(Number(data?.requestAmount))}
                      </td>
                      <td className="text-xs text-[#11192A] p-2.5">
                        {data?.discountRate}%
                      </td>
                      
                      <td className="text-xs text-[#11192A] p-2.5">
                        {formatCurrency(Number(data?.discountedAmount))}
                      </td>
                      <td className="text-xs text-[#11192A] p-2.5">
                        {formatCurrency(Number(data?.payableAmount))}
                      </td>
                    </tr>
                  </tbody>

                ))
                }
              </table>
            </div>
          </div>
        </Card>
        <Card className="w-[38%] p-[24px] rounded-[10px]">
          <div>
            <div className="flex justify-between">
              <span className="font-bold text-[#000000]">
                Early Payment Details
              </span>

              <div className="flex flex-col justify-between">
                <motion.button
                  onClick={handleConfirmModal}
                  whileTap={{ scale: 0.9 }}
                  className="flex bg-[#BB0C0C] text-sm justify-center gap-2 text-[white] rounded-[5px] items-center px-[35px] py-1.5 "
                >
                  Cancel Request

                </motion.button>
              </div>

            </div>
          </div>
          <div>
            <br />
            <div className="flex justify-between">
              <div className="flex">
                <div>
                  <img src={CocaCola} alt="" />
                </div>
                <div style={{ lineHeight: 1 }} className="flex flex-col pl-2">
                  <span>{earlyPaymentData?.buyerCompanyName}</span>
                  <span className="text-[#959595]">buyer</span>
                </div>
              </div>
              <div>
                <div style={{ lineHeight: 1 }} className="flex flex-col pl-2">
                  <span className="text-[#959595] flex justify-end">{earlyPaymentData?.earlyPaymentInvoiceResponse[0]?.discountRate}%</span>
                  <span className="text-[#959595]">Discount rate</span>
                </div>
              </div>
            </div>
            <br />
            <div className="flex justify-between">
              <div style={{ lineHeight: 1.3 }} className="flex flex-col pl-2">
                <span>{`${earlyPaymentData?.currency + ""}`} {formatCurrency(Number(earlyPaymentData?.totalDiscountAmount))}</span>
                <span className="text-[#959595]">Discount Amount</span>
              </div>
              <div>
                <div style={{ lineHeight: 1.3 }} className="flex flex-col pl-2">
                  <span className="flex justify-end">
                    {earlyPaymentData?.paymentDate}
                    {/* {earlyPaymentData?.paymentDays}Days) */}
                  </span>
                  <span className="flex justify-end text-[#959595]">
                    Early Payment Date
                  </span>
                </div>
              </div>
            </div>
            <br />
            <div className="flex justify-between">
              <div style={{ lineHeight: 1.3 }} className="flex flex-col pl-2">
                <span className="text-[#959595]">
                {`${earlyPaymentData?.currency + ""}`} {formatCurrency(Number(earlyPaymentData?.totalRequestAmount))}
                </span>
                <span className="text-[#959595]">Total Request Amount</span>
              </div>
              <div>
                <div style={{ lineHeight: 1.3 }} className="flex flex-col pl-2">
                  <span className="flex justify-end">
                  {`${earlyPaymentData?.currency + ""}`} {formatCurrency(Number(earlyPaymentData?.totalPayableAmount))}
                  </span>
                  <span className="text-[#959595]">
                    Total Receivable Amount
                  </span>
                </div>
              </div>
            </div>
          </div>
          <br />
          <hr className="text-[#959595]" />
          <br />
          <div className="flex flex-col">
            <span className="font-bold text-[#000000]">
              Notes from supplier:
            </span>
            <span className="text-[#959595]">
              {earlyPaymentData?.description}
            </span>
          </div>
        </Card>
      </div>
    </DashboardLayout>
  );
};
export default SupplierBulkEarlyPaymentDetails;
