import { PAIconAlert24 } from "assets/images/svgs";
import React from "react";

const PendingApprovalProceedModal = ({ isOpen, onClose, handleYes }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-[1000]">
      <div className="bg-white p-5 rounded-md shadow-md flex flex-col items-center px-10 md:min-w-[600px] py-7">
        <PAIconAlert24 />
        <p className="text-[#979797] text-center font-sans text-base font-normal leading-normal tracking-wide mb-3 my-5">
        Are you sure you would like to approve this
          <br />
          <span className=" ">{" workflow? "}</span>
        </p>
        <div className="flex justify-center mt-3">
          <button
            className="px-5 py-2 mr-5 text-white border border-[#07593D] bg-[#07593d] rounded-[5px]"
            onClick={handleYes}
          >
            Yes
          </button>
          <button
            className="px-5 py-2 bg-red-500  bg-[#f8f8f8] rounded-[5px] border border-[#cccccc] text-black  font-medium "
            onClick={onClose}
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default PendingApprovalProceedModal;
