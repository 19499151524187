import React from "react";
import DashboardLayout from "layout/dashboardlayout";
// import Details from "./details";
import DetailsBulkPendingConfirmation from "./detailsbulk";

const FacDirectFundingPendingDetailBulk = () => {
  return (
    <div>
      <DashboardLayout
        section="Direct Funding"
        subHeader="Monitor your invoices under direct funding"
      >
        <DetailsBulkPendingConfirmation />
      </DashboardLayout>
    </div>
  );
};

export default FacDirectFundingPendingDetailBulk;