import { PAIconArrowLeftGreen } from "assets/images/svgs";
import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { allInvoiceById } from "appstate/invoice/invoiceSlice";
import { useDispatch, useSelector } from "react-redux";
import { closedMarketViewSingleDetails } from "appstate/buyer/buyerSlice";
import DashboardLayout from "layout/dashboardlayout";
import { formatCapitalize } from "utilities/formatCapitalize";

const MyInvoiceDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const earlyId = searchParams.get("earlyId");

  const { invoiceIdData } = useSelector((state) => state?.invoice);
  const { closedMarketViewSingleDetailsData } = useSelector(
    (state) => state?.buyer
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(closedMarketViewSingleDetails(earlyId));
  }, [dispatch, earlyId]);

  useEffect(() => {
    dispatch(allInvoiceById({ id }));
  }, [dispatch, id]);

  const handleBackBtnClick = () => {
    let path = "/closedmarket";
    navigate(path);
  };

  return (
    <DashboardLayout subHeader="Here is what is happening in your finance">
      <div
        onClick={handleBackBtnClick}
        className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer w-full"
      >
        <PAIconArrowLeftGreen />
        <span className="flex items-center text-primaryColor">Go Back</span>
      </div>

      <div className="grid lg:grid-cols-[60%,40%] gap-5 h-[80vh]">
        {/* 60% */}
        <div className="flex flex-col gap-8 pb-24 bg-white rounded-[10px] pt-7">
          <div className="px-6 ">
            <div className="flex flex-col gap-3">
              <div className="flex items-center justify-between">
                <h1 className="text-[18px] font-bold">
                  Reference No: |{" "}
                  {closedMarketViewSingleDetailsData?.data?.requestRef}
                </h1>

                <div>
                  <button
                    style={{ border: "1px solid rgba(7, 89, 61, 0.20)" }}
                    className="p-2.5 rounded-[5px] text-sm text-primaryColor"
                  >
                    {formatCapitalize(invoiceIdData?.data?.status)}
                  </button>
                </div>
              </div>

              <main className=" w-full flex justify-between">
                <div className="flex flex-col gap-3 w-1/2">
                  <div className="flex flex-col gap-1">
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs font-semibold w-[30%]">
                        Invoice Number:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        INV-{invoiceIdData?.data?.invoiceNo}
                      </span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs font-semibold w-[30%]">
                        Created Date:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {invoiceIdData?.data?.createdDate}
                      </span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs font-semibold w-[30%]">
                        Submitted Date:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {invoiceIdData?.data?.submittedDate}
                      </span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs font-semibold w-[30%]">
                        Approved Date:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {invoiceIdData?.data?.approvedDate}
                      </span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs font-semibold w-[30%]">
                        Due Date:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {invoiceIdData?.data?.dueDate}
                      </span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs font-semibold w-[30%]">
                        Issuance Date:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {invoiceIdData?.data?.issuanceDate || "N/A"}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col justify-center">
                  <span className="font-bold">
                    {invoiceIdData?.data?.currency}{" "}
                    {closedMarketViewSingleDetailsData?.data?.requestAmount?.toLocaleString()}
                  </span>
                  <span className="text-xs flex justify-end text-[#979797]">
                    Request amount
                  </span>
                </div>
              </main>
            </div>

            <div className="flex justify-between my-5">
              <div className="flex flex-col">
                <span className=" font-bold text-sm">
                  {invoiceIdData?.data?.buyerCompanyName}
                </span>
                <span className="text-[#979797] text-xs">Buyer Details</span>
              </div>
              <div className="flex flex-col">
                <span className="font-bold text-sm">
                  {invoiceIdData?.data?.paymentTerms} Days
                </span>
                <span className="text-[#979797] text-xs">Payment</span>
              </div>
              <div className="flex flex-col">
                <span className="font-bold text-sm">
                  {invoiceIdData?.data?.currency}
                </span>
                <span className=" text-[#979797] text-xs">Currency</span>
              </div>
            </div>

            <div className="flex flex-col gap-4 mt-5">
              <header className="border-b-[5px] border-b-[#F6F5FA] pb-3 flex items-center justify-between">
                <h1 className="text-sm font-bold">Line Items</h1>
                <h1 className="text-sm font-regular">
                  {invoiceIdData?.data?.items?.length}Total Item(s)
                </h1>
              </header>
              <div className=" w-full overflow-x-auto h-[30vh]">
                <table className="overflow-x-auto">
                  <thead className="bg-subtleGray h-[70px] w-[50vw] overflow-x-auto">
                    <tr className=" bg-unusualGray p-2.5">
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Item
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Description
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Unit Price
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Quantity
                      </td>
                      <td className="text-[#11192A] p-2.5 text-xs font-bold w-[120px]">
                        Amount
                      </td>
                      <td className="text-[#11192A] p-2.5 text-xs font-bold w-[120px]">
                        Tax rate
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Tax Amount
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Total Price
                      </td>
                    </tr>
                  </thead>
                  {invoiceIdData?.data?.items?.map((item, index) => (
                    <tbody key={index}>
                      <tr className=" py-[3px] px-1.5">
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.itemName}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5 w-[20%]">
                          {item?.description}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.unitPrice.toLocaleString()}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.quantity}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.amount.toLocaleString()}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.taxRate}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.taxAmount.toLocaleString()}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.totalPrice.toLocaleString()}
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            </div>

            <div className="flex itemscenter justify-between px-6 mt-[10%]">
              <div>
                <h1 className="border-b border-[#B1A6B2] font-semibold">
                  Receiving Funding Account Information
                </h1>
                <p className="mt-3 text[#979797]">
                  {invoiceIdData?.data?.virtualAccount?.accountName}
                </p>
                <p className="text[#979797]">
                  {invoiceIdData?.data?.virtualAccount?.accountNumber}
                </p>
              </div>
              <div className="flex flex-col">
                <div className="flex justify-between gap-x-[100px] w-full">
                  <span className="text-xs w-[97px]">Sub Total</span>
                  <span className="text-[#979797] text-xs text-right">
                    {invoiceIdData?.data?.subTotal?.toLocaleString()}
                  </span>
                </div>

                <div className="flex justify-between w-full gap-x-[100px] my-2">
                  <span className="flex items-center text-xs w-[97px]">
                    Discount
                    <span className="border border-[#979797] pr-10 pl-2 inline-block ml-4">
                      {invoiceIdData?.data?.discount}%
                    </span>
                  </span>
                  <span className="text-[#979797] text-xs">
                    {invoiceIdData?.data?.discountAmount?.toLocaleString()}
                  </span>
                </div>

                <div className="flex justify-between w-full gap-x-[100px] pb-5">
                  <span className="flex items-center text-xs w-[97px]">
                    VAT%
                    <span className="border border-[#979797] ml-10 pr-11 pl-2 inline-block">
                      {invoiceIdData?.data?.vat}%
                    </span>
                  </span>
                  <span className="text-[#979797] text-xs">
                    {invoiceIdData?.data?.vatAmount?.toLocaleString()}
                  </span>
                </div>

                <div className="flex justify-between items-center w-full gap-x-[100px] border-t-2 border-b-2 font-bold text-lg py-1">
                  <span className="w-[97px]">Grand Total</span>
                  <span className="text[#979797] py-1">
                    {invoiceIdData?.data?.grandTotal?.toLocaleString()}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 40% */}
        <div className="bg-white rounded-[10px] gap-8 px-6 pt-7 pb-24">
          <div>
            <h2 className="font-semibold text-lg text-primaryColor leading-[30px] mb-4">
              Bid details for this invoice
            </h2>

            <div className="flex justify-between my-5">
              <div className="flex gap-2">
                <div className="flex flex-col">
                  <span className="text-sm">
                    {formatCapitalize(
                      closedMarketViewSingleDetailsData?.data
                        ?.funderInvitedResponse[0]?.funderName
                    )}
                  </span>

                  <span className="text-[#979797]  text-xs">Funder</span>
                </div>
              </div>
              <div className="flex flex-col">
                <span className="text-sm">
                  {formatCapitalize(
                    closedMarketViewSingleDetailsData?.data?.supplierCompanyName
                  ) || "N/A"}{" "}
                </span>
                <span className="text-[#979797]  text-xs">Supplier</span>
              </div>
            </div>

            <div className="flex justify-between my-5">
              <div className="flex gap-2">
                <div className="flex flex-col">
                  <span className="text-sm">
                    {closedMarketViewSingleDetailsData?.data?.discountAmount.toLocaleString()}
                  </span>
                  <span className="text-[#979797]  text-xs">
                    Lock Rate Amount
                  </span>
                </div>
              </div>
              <div className="flex flex-col">
                <span className="text-sm flex justify-end">
                  {closedMarketViewSingleDetailsData?.data?.buyerDiscountRate ||
                    "-"}
                  %
                </span>
                <span className="text-[#979797] flex justify-end  text-xs">
                  Lock rate
                </span>
              </div>
            </div>

            <div className="flex justify-between pb-5">
              <div className="flex flex-col">
                <span className="text-sm">
                  {closedMarketViewSingleDetailsData?.data?.requestDate ||
                    "N/A"}
                </span>
                <span className="text-[#979797]  text-xs">Request Date</span>
              </div>

              <div className="flex flex-col">
                <span className="text-sm flex justify-end">
                  {closedMarketViewSingleDetailsData?.data?.earlyPaymentDate}
                </span>
                <span className="text-[#979797] flex justify-end  text-xs">
                  Early Payment Date
                </span>
              </div>
            </div>

            <div className="flex justify-between mt-5 border-b-[5px] border-b-[#F6F5FA] pb-10">
              <div className="flex gap-2">
                <div className="flex flex-col">
                  <span className="text-sm">
                    N
                    {closedMarketViewSingleDetailsData?.data?.requestAmount?.toLocaleString() ||
                      "0.00"}
                  </span>
                  <span className="text-[#979797]  text-xs">
                    Request Amount
                  </span>
                </div>
              </div>
              <div className="flex flex-col">
                <span className="text-sm flex justify-end">
                  {closedMarketViewSingleDetailsData?.data?.payableAmount?.toLocaleString()}
                </span>
                <span className="text-[#979797] flex justify-end  text-xs">
                  Payable amount
                </span>
              </div>
            </div>

            <div className="pb-5">
              <h2 className="font-semibold text-base my-3">
                Funder Personal Information:
              </h2>

              <div className="flex justify-between mt-5 pb-10">
                <div className="flex gap-2">
                  <div className="flex flex-col">
                    <span className="text-[#979797]  text-xs">Funder Name</span>
                    <span className="text-sm font-semibold">
                      {
                        closedMarketViewSingleDetailsData?.data
                          ?.funderInvitedResponse[0]?.funderName
                      }
                    </span>
                  </div>
                </div>
                <div className="flex flex-col">
                  <span className="text-[#979797]  text-xs">
                    Funder Locked Rate
                  </span>
                  <span className="text-sm font-semibold">
                    {closedMarketViewSingleDetailsData?.data
                      ?.buyerDiscountRate || "-"}
                    %
                  </span>
                </div>
              </div>

              <div className="flex justify-between mt-5 pb-10">
                <div className="flex gap-2">
                  <div className="flex flex-col">
                    <span className="text-[#979797]  text-xs">Email</span>
                    <span className="text-sm">
                      {
                        closedMarketViewSingleDetailsData?.data
                          ?.funderInvitedResponse[0]?.funderEmail
                      }
                    </span>
                  </div>
                </div>
                <div className="flex flex-col">
                  <span className="text-[#979797]  text-xs">
                    Contact Number
                  </span>
                  <span className="text-sm">
                    {
                      closedMarketViewSingleDetailsData?.data
                        ?.funderInvitedResponse[0]?.contactNumber
                    }
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default MyInvoiceDetails;
