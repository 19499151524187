import { getInvoiceById } from "appstate/openMarket/openMarketSlice";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
// import InvoiceDetails from "shared/InvoiceDetails";
import LoadingScreen from "shared/LoadingScreen";
import InvoiceDetails from "./invoiceDetails";

const InvoiceDetailsPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { invoiceByIdData, isLoading } = useSelector(
    (state) => state?.openMarket
  );

  useEffect(() => {
    dispatch(getInvoiceById({ id }));
  }, [dispatch, id]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  // Calculate the payment based on the due date and created date
  const calculatePayment = (dueDate, createdDate) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const dueDateObj = new Date(dueDate);
    const createdDateObj = new Date(createdDate?.split(" ")[0]);
    const diffDays = Math.round(
      Math.abs((dueDateObj - createdDateObj) / oneDay)
    );
    return `${diffDays} Days`;
  };

  // FORMAT AMOUNT
  const formatAmount = (amount) => {
    if (typeof amount !== "number") return "0.00";
    return amount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const data = {
    invoiceNumber: `INV-${invoiceByIdData?.data?.invoiceNumber}` || "",
    referenceNumber: invoiceByIdData?.data?.invoiceReference || "",
    createdDate: invoiceByIdData?.data?.createdDate?.slice(0, 10) || "",
    dueDate: invoiceByIdData?.data?.dueDate || "",
    payment: calculatePayment(
      invoiceByIdData?.data?.dueDate,
      invoiceByIdData?.data?.createdDate
    ),
    currency: invoiceByIdData?.data?.currency || "",
    supplier: invoiceByIdData?.data?.supplierCompanyName || "",
    buyer: invoiceByIdData?.data?.buyerCompanyName || "",
    endDate: invoiceByIdData?.data?.endDate || "",
    campaignName: invoiceByIdData?.data?.campaignName || "",
    discountRate: invoiceByIdData?.data?.discountRate || "",
    paymentDate: invoiceByIdData?.data?.paymentDate || "",
    paymentDuration: invoiceByIdData?.data?.paymentDuration || "",
    receivableAmount: invoiceByIdData?.data?.receivableAmount || "",
    
    lineItems:
      invoiceByIdData?.data?.items?.map((item) => ({
        // itemNumber: item?.itemNumber || "",
        itemNumber: item?.itemName || "",
        poNumber: item.poNumber || "",
        productNumber: item.productNumber || "",
        productDescription: item.description || "",
        quantity: item.quantity || 0,
        unitPrice: formatAmount(item.unitPrice || ""),
        extendedAmount: formatAmount(item.amount || ""),
      })) || [],
    attachments: [
      {
        name: "DOC-29384.pdf",
        fileType: "PDF",
        uploadDateTime: "2021-09-14 13:25",
        downloadUrl: "https://example.com/doc-29384.pdf",
      },
    ],
  };

  return <InvoiceDetails data={data} />;
};

export default InvoiceDetailsPage;
