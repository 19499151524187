import React, { useEffect, useState } from "react";
import Table from "layout/table/Table";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { allTransactions } from "appstate/collections/collectionSlice";
import Pagination from "shared/Pagination";
import Loading from "shared/Loading";
import { PAIconFilter, PAIconOutlineSearch } from "assets/images/svgs";

const TransactionHistory = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedOption1, setSelectedOption1] = useState("inflow");
  const [selectedFundingOption, setSelectedFundingOption] =
    useState("allFundingChannel");
  // const [selectedFundingOption2, setSelectedFundingOption2] =
  //   useState("allFundingChannel");
  const [selectedCollectionOption, setSelectedCollectionOption] =
    useState("allCollectionsType");

  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const navigate = useNavigate();

  // const onRowClicked = (id) => {
  //   let path = `/collections/transactionhistory/${id}`;
  //   navigate(path);
  // };

  const onRowClicked = (id) => {
    if (
      selectedFundingOption === "receivables" ||
      selectedCollectionOption === "receivableCollections"
    ) {
      navigate(`/collections/receivables-transaction-history/${id}`);
    } else {
      let path = `/collections/transactionhistory/${id}`;
      navigate(path);
    }
  };

  const options1 = [
    { value: "inflow", label: "Inflow" },
    {
      value: "reimbursement",
      label: "Reimbursement",
    },
  ];
  const fundingChannelDropdown = [
    { value: "allFundingChannel", label: "All Funding Channel" },
    {
      value: "directFunding",
      label: "Direct Funding",
    },
    {
      value: "openMarket",
      label: "Open Market",
    },
    {
      value: "earlyPaymentRequest",
      label: "Early Payment Request",
    },
    {
      value: "earlyPaymentOffers",
      label: "Early Payment Offers",
    },
    {
      value: "receivables",
      label: "Receivables",
    },
  ];
  const fundingChannelDropdown2 = [
    { value: "allFundingChannel", label: "All Funding Channel" },
    {
      value: "directFunding",
      label: "Direct Funding",
    },
    {
      value: "openMarket",
      label: "Open Market",
    },
  ];
  // const filteredFundingChannelDropdown =
  //   selectedOption1 === "reimbursement"
  //     ? fundingChannelDropdown.filter(
  //         (option) =>
  //           option.value === "directFunding" || option.value === "openMarket"
  //       )
  //     : fundingChannelDropdown;

  const collectionTypeDropdown = [
    { value: "allCollectionsType", label: "All Collection Type" },
    {
      value: "fundingPayment",
      label: "Funding Payment",
    },
    {
      value: "receivableCollections",
      label: "Receivable Collections",
    },
  ];

  const dispatch = useDispatch();
  const { transactionData, isLoading } = useSelector(
    (state) => state?.collection
  );

  const handleSearch = () => {
    dispatch(transactionData({ search: searchQuery }));
  };

  const handleSelect = (option) => {
    setSelectedOption1(option.value);
    setIsOpen1(false);
  };

  const handleFundingSelect = (option) => {
    setSelectedFundingOption(option.value);
    setIsOpen2(false);
  };

  const handleCollectionSelect = (option) => {
    setSelectedCollectionOption(option.value);
    setIsOpen3(false);
  };

  const formatAmount = (amount) => {
    if (typeof amount !== "number") return "0.00";
    return amount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const [page, setPage] = useState(1);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(10);
  const pageSize = 10;

  useEffect(() => {
    dispatch(allTransactions({ page, pageSize }));
  }, [dispatch, page, pageSize]);

  const dummyTransactionData = {
    data: [
      {
        companyName: "Acme Corp",
        paymentDate: "2023-10-01T12:00:00Z",
        invoiceReference: "INV-1001",
        currency: "USD",
        amount: 1500.0,
        status: "SUCCESSFUL",
        invoiceId: "1",
        collectionType: "Funding Payment",
        fundingChannel: "Open Market",
        beneficiaryName: "Dangote",
        player: "Funder",
      },
      {
        companyName: "Global Industries",
        paymentDate: "2023-10-02T12:00:00Z",
        invoiceReference: "INV-1002",
        currency: "USD",
        amount: 2500.5,
        status: "PARTIALLY PAID",
        invoiceId: "2",
        collectionType: "Funding Payment",
        fundingChannel: "Open Market",
        beneficiaryName: "Dangote",
        player: "Buyer",
      },
      {
        companyName: "Tech Solutions",
        paymentDate: "2023-10-03T12:00:00Z",
        invoiceReference: "INV-1003",
        currency: "EUR",
        amount: 3200.75,
        status: "REJECTED",
        invoiceId: "3",
        collectionType: "Funding Payment",
        fundingChannel: "Open Market",
        beneficiaryName: "Dangote",
        player: "Funder",
      },
      {
        companyName: "Creative Agency",
        paymentDate: "2023-10-04T12:00:00Z",
        invoiceReference: "INV-1004",
        currency: "GBP",
        amount: 1800.0,
        status: "SUCCESSFUL",
        invoiceId: "4",
        collectionType: "Funding Payment",
        fundingChannel: "Open Market",
        beneficiaryName: "Dangote",
        player: "Funder",
      },
      {
        companyName: "Finance Corp",
        paymentDate: "2023-10-05T12:00:00Z",
        invoiceReference: "INV-1005",
        currency: "USD",
        amount: 500.0,
        status: "UPDATED",
        invoiceId: "5",
        collectionType: "Funding Payment",
        fundingChannel: "Open Market",
        beneficiaryName: "Dangote",
        player: "Funder",
      },
      {
        companyName: "Retail Co.",
        paymentDate: "2023-10-06T12:00:00Z",
        invoiceReference: "INV-1006",
        currency: "USD",
        amount: 750.25,
        status: "SUCCESSFUL",
        invoiceId: "6",
        collectionType: "Funding Payment",
        fundingChannel: "Open Market",
        beneficiaryName: "Dangote",
        player: "Buyer",
      },
      {
        companyName: "Health Services",
        paymentDate: "2023-10-07T12:00:00Z",
        invoiceReference: "INV-1007",
        currency: "CAD",
        amount: 1200.0,
        status: "REJECTED",
        invoiceId: "7",
        collectionType: "Funding Payment",
        fundingChannel: "Open Market",
        beneficiaryName: "Dangote",
        player: "Buyer",
      },
      {
        companyName: "Construction Ltd.",
        paymentDate: "2023-10-08T12:00:00Z",
        invoiceReference: "INV-1008",
        currency: "USD",
        amount: 3000.0,
        status: "SUCCESSFUL",
        invoiceId: "8",
        collectionType: "Funding Payment",
        fundingChannel: "Open Market",
        beneficiaryName: "Dangote",
        player: "Buyer",
      },
      {
        companyName: "Logistics Inc.",
        paymentDate: "2023-10-09T12:00:00Z",
        invoiceReference: "INV-1009",
        currency: "USD",
        amount: 950.5,
        status: "PARTIALLY PAID",
        invoiceId: "9",
        collectionType: "Funding Payment",
        fundingChannel: "Open Market",
        beneficiaryName: "Dangote",
        player: "Funder",
      },
      {
        companyName: "Consulting Group",
        paymentDate: "2023-10-10T12:00:00Z",
        invoiceReference: "INV-1010",
        currency: "USD",
        amount: 450.0,
        status: "SUCCESSFUL",
        invoiceId: "10",
        collectionType: "Funding Payment",
        fundingChannel: "Open Market",
        beneficiaryName: "Dangote",
        player: "Funder",
      },
    ],
    meta: {
      totalElements: 10,
    },
  };

  const getColumns = () => {
    if (selectedOption1 === "reimbursement") {
      return [
        {
          name: (
            <h1>
              {" "}
              Sender <span className="text-primaryColor text-xs">
                (Buyer)
              </span>{" "}
            </h1>
          ),
          selector: (row) => row.companyName,
          sortable: true,
        },
        {
          name: (
            <h1>
              {" "}
              Benficiary{" "}
              <span className="text-secondaryColor text-xs">(Funder)</span>{" "}
            </h1>
          ),
          selector: (row) => row.beneficiaryName,
          sortable: true,
        },
        {
          name: "Payment Date",
          selector: (row) => row.paymentDate?.slice(0, 10),
          sortable: true,
        },
        {
          name: "Reference Number",
          selector: (row) => row.invoiceReference,
          sortable: true,
        },
        {
          name: "Currency",
          selector: (row) => row.currency,
          sortable: true,
        },
        {
          name: "Amount",
          selector: (row) => row.amount,
          sortable: true,
          cell: (row) => (
            <p className="text-success">{formatAmount(row.amount)}</p>
          ),
        },
        {
          name: "Status",
          selector: (row) => row.status,
          sortable: true,
          cell: (row) => (
            <p
              className={
                row.status === "SUCCESSFUL"
                  ? "text-[#07593D]"
                  : row.status === "REJECTED"
                  ? "text-red"
                  : "text-yellow-400"
              }
            >
              {row.status}
            </p>
          ),
        },
        {
          name: "Funding Channel",
          selector: (row) => row.fundingChannel,
          sortable: true,
        },
      ];
    } else {
      return [
        {
          name: "Sender",
          selector: (row) => row.companyName,
          sortable: true,
          cell: (row) => (
            <p>
              {row.companyName}{" "}
              <span
                className={
                  row.player === "Funder"
                    ? "text-[#07593D] text-[10px]"
                    : "text-yellow-400 text-[10px]"
                }
              >
                ({row.player})
              </span>
            </p>
          ),
        },
        {
          name: "Payment Date",
          selector: (row) => row.paymentDate?.slice(0, 10),
          sortable: true,
        },
        {
          name: "Reference Number",
          selector: (row) => row.invoiceReference,
          sortable: true,
        },
        {
          name: "Currency",
          selector: (row) => row.currency,
          sortable: true,
        },
        {
          name: "Amount",
          selector: (row) => row.amount,
          sortable: true,
          cell: (row) => (
            <p className="text-success">{formatAmount(row.amount)}</p>
          ),
        },
        {
          name: "Status",
          selector: (row) => row.status,
          sortable: true,
          cell: (row) => (
            <p
              className={
                row.status === "SUCCESSFUL"
                  ? "text-[#07593D]"
                  : row.status === "REJECTED"
                  ? "text-red"
                  : "text-yellow-400"
              }
            >
              {row.status}
            </p>
          ),
        },
        {
          name: "Collection Type",
          selector: (row) => row.collectionType,
          sortable: true,
        },
        {
          name: "Funding Channel",
          selector: (row) => row.fundingChannel,
          sortable: true,
        },
      ];
    }
  };

  const columns = getColumns();

  return (
    <>
      {!dummyTransactionData?.data?.length ? (
        <div className="ml3">
          <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
            <p className="text-black text-sm font-medium">
              Overview of Cash Inflow Records
            </p>
          </div>
          <TableEmptyState dashboard />
        </div>
      ) : (
        <Card className="rounded-[10px] mt-[24px]">
          <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px] py-10">
            <div>
              <p className="text-black text-lg font-bold">
                Overview of CashInflow Records
              </p>
              <span className="text-xs font-thin">
                Here is a recent list of your inflow transactions
              </span>
            </div>
            {/* SEARCH */}
            <div className="flex md:col-span-1 justify-end">
              <div className="flex items-center justify-between gap-5">
                <div className="border border-transparent md:border-gray md:shadow-sm my-4 rounded-[5px] px-2 lg:w-[300px] bg-white">
                  <div className="items-center flex bg-white">
                    <PAIconOutlineSearch />
                    <input
                      className="bg-transparent p-2 w-full focus:outline-none text-sm bg-white"
                      type="text"
                      placeholder="Search for transaction"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          handleSearch();
                        }
                      }}
                    />
                  </div>
                  <div className="lg:hidden flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer">
                    <PAIconFilter className="bg-[#2FA06A]" />
                  </div>
                </div>
                <button className="hidden lg:flex bg-[#2FA06A] rounded-md my-4 py-2 px-6 mx3 items-center gap-2 text-white">
                  Search
                </button>
              </div>
            </div>
          </div>
          <div className="px-[24px] flex gap-5 justifybetween">
            {/* FIRST DROPDOWN */}
            <div className="relative">
              {/* Dropdown Button */}
              <button
                onClick={() => setIsOpen1(!isOpen1)}
                className="flex items-center justify-between gap-16 text-primaryColor text-xs border border-gray p-1 px-2 rounded-t cursor-pointer dropdown-button"
              >
                {options1.find((opt) => opt.value === selectedOption1)?.label}
                <svg
                  className={`w-4 h-8 ml-2 transition-transform ${
                    isOpen1 ? "rotate-180" : "rotate-0"
                  }`}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.707a1 1 0 011.414 0L10 11.293l3.293-3.586a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>

              {/* Dropdown Menu */}
              {isOpen1 && (
                <ul className="z-10 absolute top-full right-0 text-primaryColor bg-white border border-gray rounded-b shadow-md text-sm w-full">
                  {options1.map((option) => (
                    <li
                      key={option.value}
                      onClick={() => handleSelect(option)}
                      className={`px-4 py-2 flex items-center text-xs cursor-pointer hover:bg-primaryColor hover:text-white ${
                        selectedOption1 === option.value ? "bgprimaryColor" : ""
                      }`}
                    >
                      {option.label}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            {/* SECOND DROPDOWN */}
            <div className="relative">
              {/* Dropdown Button */}
              <button
                onClick={() => setIsOpen2(!isOpen2)}
                className="flex items-center justify-between gap-16 text-primaryColor text-xs border border-gray p-1 px-2 rounded-t cursor-pointer dropdown-button"
              >
                {/* {
                  fundingChannelDropdown.find(
                    (opt) => opt.value === selectedFundingOption
                  )?.label
                } */}
                {
                  (selectedOption1 === "reimbursement"
                    ? fundingChannelDropdown2
                    : fundingChannelDropdown
                  ).find((opt) => opt.value === selectedFundingOption)?.label
                }
                <svg
                  className={`w-4 h-8 ml-2 transition-transform ${
                    isOpen2 ? "rotate-180" : "rotate-0"
                  }`}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.707a1 1 0 011.414 0L10 11.293l3.293-3.586a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>

              {/* Dropdown Menu */}
              {isOpen2 && (
                <ul className="z-10 absolute top-full right-0 text-primaryColor bg-white border border-gray rounded-b shadow-md text-sm w-full">
                  {(selectedOption1 === "reimbursement"
                    ? fundingChannelDropdown2
                    : fundingChannelDropdown
                  ).map((option) => (
                    <li
                      key={option.value}
                      onClick={() => handleFundingSelect(option)}
                      className={`px-4 py-2 flex items-center text-xs cursor-pointer hover:bg-primaryColor hover:text-white ${
                        selectedFundingOption === option.value
                          ? "bgprimaryColor"
                          : ""
                      }`}
                    >
                      {option.label}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            {/* THIRD DROPDOWN */}
            {selectedOption1 !== "reimbursement" && (
              <div className="relative">
                {/* Dropdown Button */}
                <button
                  onClick={() => setIsOpen3(!isOpen3)}
                  className="flex items-center justify-between gap-16 text-primaryColor text-xs border border-gray p-1 px-2 rounded-t cursor-pointer dropdown-button"
                >
                  {
                    collectionTypeDropdown.find(
                      (opt) => opt.value === selectedCollectionOption
                    )?.label
                  }
                  <svg
                    className={`w-4 h-8 ml-2 transition-transform ${
                      isOpen3 ? "rotate-180" : "rotate-0"
                    }`}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.707a1 1 0 011.414 0L10 11.293l3.293-3.586a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>

                {/* Dropdown Menu */}
                {isOpen3 && (
                  <ul className="z-10 absolute top-full right-0 text-primaryColor bg-white border border-gray rounded-b shadow-md text-sm w-full">
                    {collectionTypeDropdown.map((option) => (
                      <li
                        key={option.value}
                        onClick={() => handleCollectionSelect(option)}
                        className={`px-4 py-2 flex items-center text-xs cursor-pointer hover:bg-primaryColor hover:text-white ${
                          selectedCollectionOption === option.value
                            ? "bgprimaryColor"
                            : ""
                        }`}
                      >
                        {option.label}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
          </div>
          <div className="px-[24px] pb-[24px]">
            <Table
              columns={columns}
              data={dummyTransactionData?.data}
              onRowClicked={(row) => onRowClicked(row.invoiceId)}
              progressPending={isLoading}
              progressComponent={<Loading />}
              pointer
              tableHeader
              className="mt-5"
            />
            <div className="flex justify-center gap-x-[8px] mt-[20px]">
              {dummyTransactionData?.data?.length > 0 && (
                <Pagination
                  page={page}
                  startPage={startPage}
                  setEndPage={setEndPage}
                  endPage={endPage}
                  setStartPage={setStartPage}
                  setPage={setPage}
                  totalPages={dummyTransactionData?.meta?.totalElements}
                />
              )}
            </div>
          </div>
        </Card>
      )}
    </>
  );
};

export default TransactionHistory;
