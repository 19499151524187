import { PAIconPayEdgeLogo } from "assets/images/svgs";
import React from "react";

const Preview = () => {
  return (
    <div className="h-[80vh] flex items-center justify-center mt-5">
      <div className="bg-white shadow-md px-10 py-5 text-center md:w-1/2">
        <div className="mb-10 flex items-center justify-center">
          <PAIconPayEdgeLogo />
        </div>
        <div className="p-3 bg-[#07593D] text-white font-rocknroll-one text-base font-normal">
          Associate with supplier
        </div>
        <div className="flex items-center justify-center mt-7">
          <img src="/happy_announcement.png" alt="/" />
        </div>
        <div className="mb-5">
          <p className="text-black font-sans font-extrabold text-xs my-5 text-left">
            Hi Collins Eze,
          </p>
          <p className="text-[#565656] font-sans italic font-extrabold text-xs text-left">
            Jenny Wilson of Dangote has requested to associate with you. Click
            the “Accept Request” button if you would like to associate and the
            “Decline Request” button if you would not like to associate with
            them.
          </p>
        </div>
        <div className="pb-5 bg[#F08000]">
          <button className="px-4 py-2 rounded-lg text-white font-custom text-xs font-medium bg-[#F08000] hover:opacity-90 transition">
            Accept Request
          </button>
          <button className="px-4 py-2 text-red-500 font-sans font-medium text-xs rounded-lg  border border-[#E64D4D] hover:opacity-90 transition ml-5">
            Decline Request
          </button>
        </div>
        <p className="text-black font-custom text-xs font-normal">The PayEdge Team</p>
      </div>
    </div>
  );
};

export default Preview;
