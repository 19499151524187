import {
  PAIconPencilLine,
  // PAIconSettingsAdd,
} from "../../../assets/images/svgs";

import ProfilePic from "./ProfilePic";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputField from "shared/InputField";
import { useFormik } from "formik";
import { updateCompanyDetailsSchema } from "validation/settings/UpdateCompanyDetailsSchema";
import PhoneNumber from "shared/PhoneNumber";
import Button from "shared/Button";
import Spinner from "shared/Spinner/Spinner";


import { toast } from "react-hot-toast";

import MultiSelectDropdown from "shared/BuyerMultiSelectDropdownSettings";
import { industries } from "utilities/Industries";
import { editCompanyDetailsBuyer } from "appstate/buyer/buyerSlice";
import { editCompanyDetailsFunder } from "appstate/funder/funderSlice";
import { editCompanyDetailsSupplier } from "appstate/supplier/supplierSlice";
import {  fetchBuyerDetails } from "appstate/buyer/buyerSlice";
import {  fetchSupplierDetails } from "appstate/supplier/supplierSlice";
import {  fecthFunderDetails } from "appstate/funder/funderSlice";
const CompanyDetails = ({ currentUser }) => {
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.settings);
  // const { currentUser } = useSelector((state) => state.auth);
  const { userId } = useSelector((state) => state?.auth);
  const roleIndex = userId?.realm_access?.roles?.findIndex((arr) => {
    return (
      arr === "SUPPLIER" ||
      arr === "BUYER" ||
      arr === "FUNDER" ||
      arr === "ADMIN"
    );
  });
  const userRole = userId?.realm_access?.roles[roleIndex]?.toLowerCase();

  const {funderDetails} = useSelector(
    (state) => state.funder
  );
  const {buyerDetails} = useSelector(
    (state) => state.buyer
  );
  const {supplierDetails} = useSelector(
    (state) => state.supplier
  );



  const personalDetails={ buyer: buyerDetails,
    supplier: supplierDetails,
    funder: funderDetails}


    const usersData = personalDetails[userRole];

  // console.log("===currentUserRole===", currentUser, userRole);

  // const { userDetails } = useSelector(
  //   (state) => state.auth
  // );

   const userDetailsFunc={ buyer: fetchBuyerDetails,
      supplier: fetchSupplierDetails,
      funder: fecthFunderDetails}
  
  
  
      const fetchUser = userDetailsFunc[userRole];
  useEffect(()=>{ dispatch(fetchUser())},[dispatch,fetchUser])
    // console.log("====userid====", userDetails?.data)
  // console.log("==userdetails===", userDetails)

  const formik = useFormik({
    initialValues: {
      registeredAddress:usersData?.data?.registeredAddress || "",
      companyWebsite: usersData?.data?.companyWebsite || "",
      companyPhoneNumber: usersData?.data?.companyPhoneNumber || "",
      companyEmail: usersData?.data?.companyEmail || "",
      industry:usersData?.data?.industryType || [],
      companyName: usersData?.data?.companyName || "",
      rcNumber: usersData?.data?.rcNumber || "",
      companyTaxId:usersData?.data?.companyTaxId || "",
      accountNumber:usersData?.data?.accountNumber || "",

    },
    validationSchema: updateCompanyDetailsSchema,
    // currentUser?.role.toLowerCase() === "supplier"
    //   ? updateSupplierCompanyDetailsSchema
    //   : updateCompanyDetailsSchema,
    onSubmit: (values) => {
      const buyerBody = {
        companyName: values.companyName,
        companyPhoneNumber: values.companyPhoneNumber,
        companyWebsite: values.companyWebsite,
        registeredAddress: values.registeredAddress,
        industryType: values.industry,
      };
     

      const editCompanyDetailsActions = {
        buyer: editCompanyDetailsBuyer,
        supplier: editCompanyDetailsSupplier,
        funder: editCompanyDetailsFunder,
      };

      const action = editCompanyDetailsActions[userRole];

      if (action) {
        dispatch(action({ body: buyerBody })).then((data) => {
          if (data?.payload?.status === 200) {
            toast.success("Company details edited successfully!");
            dispatch(fetchUser());
            setIsEditing(false);
          }
        });
      }
    },
  });

  return (
    <div className="flex flex-col py-[18px] px-[25px] space-y-5">
      <ProfilePic currentUser={usersData?.data} />
      {/* <div className="bg-gallery h-[1px] pb-1 w-full"></div> */}
      <form onSubmit={formik.handleSubmit}>
        <div className="mb-5">
          <p className="font-bold">Company Details</p>
        </div>
        <div className="flex justify-between flex-wrap gap-4 items-start">
          <div className="flex md:w-[350px] w-full flex-col gap-5">

          <div className="w-full">
              <InputField
                disabled={!isEditing}
                className="h-[45px] !border-none bg-wildSand rounded-md p-[13px] outline-none"
                placeholder="Coca cola Plc"
                label="Registered Company Name"
                id="companyName"
                name="companyName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.companyName}
              />
              {formik.touched.companyName &&
              formik.errors.companyName ? (
                <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.companyName}
                </p>
              ) : null}
            </div>
            <div className="w-full">
              <InputField
                disabled={!isEditing}
                className="h-[45px] !border-none bg-wildSand rounded-md p-[13px] outline-none"
                placeholder="www.company.com"
                label="Company Website"
                id="companyWebsite"
                name="companyWebsite"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.companyWebsite}
              />
              {formik.touched.companyWebsite && formik.errors.companyWebsite ? (
                <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.companyWebsite}
                </p>
              ) : null}
            </div>
           
            <div className="w-full">
              <InputField
                disabled={!isEditing}
                className="h-[45px] !border-none bg-wildSand rounded-md p-[13px] outline-none"
                placeholder="3rd Avenue F1 close"
                label="Registered Address"
                id="registeredAddress"
                name="registeredAddress"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.registeredAddress}
              />
              {formik.touched.registeredAddress &&
              formik.errors.registeredAddress ? (
                <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.registeredAddress}
                </p>
              ) : null}
            </div>



            <div className="mb-[30px] w-full ">
              <MultiSelectDropdown
                options={industries}
                selectedOptions={formik.values.industry}
                setSelectedOptions={(selected) =>
                  formik.setFieldValue("industry", selected)
                }
              />
              {formik.touched.industry && formik.errors.industry ? (
                <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.industry}
                </p>
              ) : null}
            </div>
            
          </div>
          <div className="flex md:w-[350px] w-full flex-col gap-5">

          <div className="w-full">
              <InputField
                disabled={!isEditing}
                className="!max-sm:w-full h-[45px] !border-none bg-wildSand rounded-md p-[13px] outline-none"
                placeholder="Registered Company Number"
                label="Registered Company Number (RC Number)"
                id="rcNumber"
                name="rcNumber"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.rcNumber}
              />

              
            </div>
{formik.touched.rcNumber && formik.errors.rcNumber ? (
                <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.rcNumber}
                </p>
              ) : null}
            <div className="w-full">
              <InputField
                disabled={!isEditing}
                className="!max-sm:w-full h-[45px] !border-none bg-wildSand rounded-md p-[13px] outline-none"
                placeholder="Registered Company Number"
                label="Company Tax ID"
                id="companyTaxId"
                name="companyTaxId"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.companyTaxId}
              />

              {formik.touched.companyTaxId && formik.errors.companyTaxId ? (
                <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.companyTaxId}
                </p>
              ) : null}
            </div>

            <div className="w-full">
              <PhoneNumber
                disabled={!isEditing}
                id="companyPhoneNumber"
                name="companyPhoneNumber"
                className="!md:w-[350px] !w-full h-[45px] !border-none bg-wildSand rounded-md p-[13px] outline-none"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.companyPhoneNumber}
              />
              {formik.touched.companyPhoneNumber &&
              formik.errors.companyPhoneNumber ? (
                <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.companyPhoneNumber}
                </p>
              ) : null}
            </div>
            <div className="w-full">
              <InputField
                disabled={!isEditing}
                className="!max-sm:w-full h-[45px] !border-none bg-wildSand rounded-md p-[13px] outline-none"
                placeholder="accountNumber"
                label="Account"
                id="accountNumber"
                name="companyName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.accountNumber}
              />

              {formik.touched.companyName && formik.errors.companyName ? (
                <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.companyName}
                </p>
              ) : null}
            </div>
         
          </div>
        </div>
        <div className="py-6">
          {isEditing ? (
            <div className="flex gap-2 justify-start">
              <Button
                type="button"
                onClick={() => setIsEditing(!isEditing)}
                className="flex !text-[14px] !px-6 !py-3 items-center bg-wildSand border-none !rounded-[10px] h-[40px]"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="flex !text-[14px] !px-6 !py-3 items-center !bg-emerald border-none !rounded-[10px] h-[40px] hover:bg-green-400"
              >
                {isLoading ? <Spinner /> : "Save"}
              </Button>
            </div>
          ) : (
            <p
              onClick={() => setIsEditing(!isEditing)}
              className="cursor-pointer inline-flex items-center px-4 py-1 gap-[2px] hover:bg-blue-50 text-gray-800 text-sm font-medium rounded-full border border-[#213874] -mt-3"
            >
              <PAIconPencilLine />
              Edit
            </p>
          )}
        </div>
      </form>
      <div className="bg-gallery h-[1px] pb-1 w-full"></div>
    </div>
  );
};

export default CompanyDetails;
