import { confirmDirectFundingRequest, getAllInvoiceByIdDF, getDirectFundingDetails, getVirtualAccounts } from "appstate/api/invoice/invoice";
import { PAIconArrowLeftGreen } from "assets/images/svgs";
import EarlyPaymentConfirmation from "features/invoice/fundingSection/earlyPaymentRequest/singlerequest/Modal/EarlyPaymentConfirmation";
import PinModalPayment from "features/invoice/fundingSection/earlyPaymentRequest/singlerequest/Modal/PinModal";
import SuccessEarlyPayment from "features/invoice/fundingSection/earlyPaymentRequest/singlerequest/Modal/SuccessEarlyPayment";
import EarlyPaymentVirtualAccountSelection from "features/invoice/fundingSection/earlyPaymentRequest/singlerequest/Modal/VirtualAccountSelection";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { formatCurrency } from "utilities/helper/formatters";

const Details = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [directFundingData, setDirectFundingData] = useState(null);
  // eslint-disable-next-line
  const [error, setError] = useState(null);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line
  const [selectedIds, setSelectedIds] = useState([]);
  const [showVirtualSelectionModal, setShowVirtualSelectionModal] =
    useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [open, setOpen] = useState(false);
  const [virtualAccounts, setVirtualAccounts] = useState([]);
  const [showPin, setShowPin] = useState(false);
  const [otpInputs, setOtpInputs] = useState(["", "", "", ""]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line
  const { earlyPaymentLoading, approvedInvoiceId } = useSelector(
    (state) => state?.supplier
  );
  const location = useLocation();
  const invoiceId = location.state?.invoiceId;
  const [invoiceData, setInvoiceData] = useState(null);
  useEffect(() => {
    const fetchDirectFundingDetails = async () => {
      try {
        const result = await getDirectFundingDetails(id);
        setDirectFundingData(result);
      } catch (error) {
        setError(error.message || "Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchDirectFundingDetails();
    }
  }, [id]);

  useEffect(() => {
    const fetchVirtualAccounts = async () => {
      try {
        const result = await getVirtualAccounts();
        setVirtualAccounts(result?.data);
      } catch (error) {
        console.error("Failed to fetch virtual accounts:", error);
      }
    };

    fetchVirtualAccounts();
  }, []);

  useEffect(() => {
    const fetchInvoiceDetails = async () => {
      if (!invoiceId) {
        console.error("Invoice ID is missing");
        return;
      }
      try {
        const response = await getAllInvoiceByIdDF(invoiceId);
        setInvoiceData(response?.data);
      } catch (error) {
        setError(error.message || "Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    if (invoiceId) {
      fetchInvoiceDetails();
    }
  }, [invoiceId]);

  const goBack = () => {
    navigate(-1);
  };

  function handleSendRequest() {
    setOpen(false);
    setShowPin(true);
  }


  const handleDirectFundingRequestConfirmation = async () => {
    let virtualAccountId = selectedAccount?.id;
    try {
      setIsLoading(true);
      const pin = otpInputs.toString();
      const directFundingIds = [id];

      const transactPin = pin.replace(/,/g, "");
      const response = await confirmDirectFundingRequest({
        directFundingIds: directFundingIds,
        transactionPin: transactPin,
        reimbursementAccountId: virtualAccountId,
      });

      if (response?.status === 200) {
        setShowConfirmModal(false);
        setShowSuccess(true);
        setTimeout(() => {
          navigate("/factoring/directfunding");
        }, 2000)
      }
    } catch (error) {
      console.error("Error confirming payment:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onClosePinModal = () => {
    setSelectedAccount(null);
    setShowPin(false);
  };

  const handleShowVirtualAccountSelection = () => {
    setShowVirtualSelectionModal(true);
  };


  const handleCloseVirtualAccount = () => {
    setShowVirtualSelectionModal(false);
  };

  const confirmPayment = () => {
    setShowConfirmModal(true);
    setShowPin(false);
  };

  const closeConfirmModal = () => {
    setShowConfirmModal(false);
    navigate('/factoring/directfunding')
  }

  const closeEarlyPaymentConfirmation = () => {
    setSelectedAccount(null);
    setOpen(false);
  }


  const onClose = () => {
    setShowVirtualSelectionModal(false)
  }

  return (
    <>
      {open && (
        <div className="absolute mt-[20px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white w-[614px] h-[254px] z-10 rounded-[10px] shadow-lg">
          <EarlyPaymentConfirmation
            hide={closeEarlyPaymentConfirmation}
            isLoading={earlyPaymentLoading}
            handleAssociate={handleSendRequest}
            earlyPayment={true}
            invoice={true}
            warning={
              <>
                Would you like to use this account{" "}
                <span className="text-primaryColor">
                  {selectedAccount?.accountNumber}
                </span>{" "}
                for reimbursement?
              </>
            }
          />
        </div>
      )}
      {showSuccess && (
        <SuccessEarlyPayment
          isOpen={showSuccess}
          onClose={() => setShowSuccess(false)}
          message="Confirmation successful"
          invoice={true}
        />
      )}
      {showConfirmModal && (
        <div className="absolute mt-[20px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white w-[614px] h-[254px] z-10 rounded-[10px] shadow-lg">
          <EarlyPaymentConfirmation
            hide={closeConfirmModal}
            isLoading={earlyPaymentLoading}
            handleAssociate={handleDirectFundingRequestConfirmation}
            // earlyPayment={true}
            warning="Would you like to proceed with this confirmation? "
          />
        </div>
      )}
      {showPin && (
        <PinModalPayment
          confirmPayment={confirmPayment}
          isOpen={showPin}
          onCancel={onClosePinModal}
          otpInputs={otpInputs}
          setOtpInputs={setOtpInputs}
          invoice={true}
        />
      )}
      {showVirtualSelectionModal && (
        <div>
          <EarlyPaymentVirtualAccountSelection
            handleCloseVirtualAccount={handleCloseVirtualAccount}
            setOpen={setOpen}
            onClose={onClose}
            virtualAccounts={virtualAccounts}
            selectedAccount={selectedAccount}
            setSelectedAccount={setSelectedAccount}
            invoice={true}
          />
        </div>
      )}
      <Link
        onClick={goBack}
        className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer w-full"
      >
        <PAIconArrowLeftGreen />
        <span className="flex items-center text-primaryColor">Go Back</span>
      </Link>

      <div className="grid lg:grid-cols-[60%,40%] gap-5">
        <div className="flex flex-col gap-8 pb-24 bg-white rounded-[10px] pt-7">
          <div className="px-6 ">
            <div className="flex flex-col gap-3">
              <main className=" w-full  ">
                <div className="flex justify-between">
                  <div className="flex flex-col gap-3 w-1/2">
                    <div className="flex">
                      <span>Reference No:</span>
                      <span className="pl-2">
                        |{directFundingData?.reference}
                      </span>
                    </div>
                    <div className="flex flex-col gap-1">
                      <div className="flex gap-x-[50px]">
                        <span className=" text-xs w-[97px]">Invoice Number:</span>
                        <span className="text-[#979797]  text-xs">
                          {`INV-${directFundingData?.invoiceNumber}`}
                        </span>
                      </div>
                      <div className="flex gap-x-[50px]">
                        <span className=" text-xs w-[97px]">Created Date:</span>
                        <span className="text-[#979797]  text-xs">
                          {invoiceData?.createdDate}
                        </span>
                      </div>
                      <div className="flex gap-x-[50px]">
                        <span className=" text-xs w-[97px]">Submitted Date:</span>
                        <span className="text-[#979797]  text-xs">
                          {invoiceData?.submittedDate}
                        </span>
                      </div>
                      <div className="flex gap-x-[50px]">
                        <span className=" text-xs w-[97px]">Approved Date:</span>
                        <span className="text-[#979797]  text-xs">
                          {invoiceData?.approvedDate}
                        </span>
                      </div>
                      <div className="flex gap-x-[97px]">
                        <span className=" text-xs">Due Date:</span>
                        <span className="text-[#979797]  text-xs">
                          {invoiceData?.dueDate}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>

                    <div>
                      <button className="border-primaryColor text-primaryColor border px-12 py-2 rounded-md bg-white">
                        Pending Confirmation
                      </button>
                    </div>

                    <div className="flex flex-col mt-4 gap-3 w-full">
                      <div className="flex flex-col gap-1">
                        <div className="flex gap-x-[50px]">
                          <span className=" text-xs w-[97px]">Request Amount:</span>
                          <span className="text-[#979797]  text-xs">
                            {`${directFundingData?.currency} ` }{formatCurrency(Number(directFundingData?.requestAmount))}
                          </span>
                        </div>
                        {/* <div className="flex gap-x-[50px]">
                          <span className=" text-xs w-[97px]">Request Date:</span>
                          <span className="text-[#979797]  text-xs">
                            {directFundingData?.earlyPaymentDate}
                          </span>
                        </div> */}
                        <div className="flex gap-x-[50px]">
                          <span className=" text-xs w-[97px]">Payment Date:</span>
                          <span className="text-[#979797]  text-xs">
                            {directFundingData?.earlyPaymentDate}
                          </span>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                {/* the  next starts here */}

                <div className="mt-4">
                  <div>
                    <div class="text-right text-[#222222] text-base font-bold ">
                      {directFundingData?.currency}{formatCurrency(Number(directFundingData?.requestAmount))}
                    </div>
                    <div class="text-[#979797] text-xs font-normal text-right">
                      Request amount
                    </div>
                  </div>
                </div>

                {/* stops here */}
                {/* </div> */}
              </main>
            </div>

            <div className="flex justify-between my-5">
              <div className="flex flex-col">
                <span className="font-light  text-xs">
                  {directFundingData?.buyerCompanyName}
                </span>
                <span className="text-[#979797] text-sm">buyer</span>
              </div>
              {/* <div className="flex flex-col">
                <span className="text-[#979797]  text-xs">60 Days</span>
                <span className="font-bold text-sm">Payment</span>
              </div> */}
              <div className="flex flex-col">
                <span className="text-[#979797] flex justify-end  text-xs">
                  {directFundingData?.currency}
                </span>
                <span className=" font-bold text-sm">Currency</span>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <header className="border-b-[5px] flex justify-between border-b-[#F6F5FA] pb-3">
                <h1 className="text-sm font-bold">Line Items</h1>
                <span className="text-[#979797]  text-xs">
                  {invoiceData?.items?.length} total items
                </span>
              </header>
              <div className=" w-full overflow-x-auto">
                <table className=" w-full overflow-x-auto">
                  <thead className="bg-subtleGray w-">
                    <tr className=" bg-unusualGray p-2.5 ">
                      <td className="text-[#11192A] p-2.5 text-xs font-bold">
                        Item
                      </td>
                      <td className=" p-2.5 text-xs font-bold">Description</td>
                      <td className=" p-2.5 text-xs font-bold">Unit price</td>
                      <td className=" p-2.5 text-xs font-bold">Quantity</td>
                      <td className=" p-2.5 text-xs font-bold">
                        Amount
                      </td>
                      <td className=" p-2.5 text-xs font-bold">Tax Rate</td>
                      <td className=" p-2.5 text-xs font-bold">Tax Amount</td>
                      <td className=" p-2.5 text-xs font-bold">Total Price</td>
                    </tr>
                  </thead>

                  {invoiceData?.items?.map((item, index) => (
                    <tbody key={index}>
                      <tr className=" py-[3px] px-1.5">
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.itemName}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.description}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {formatCurrency(Number(item?.unitPrice))}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {formatCurrency(Number(item?.quantity))}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {formatCurrency(Number(item?.unitPrice))}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {formatCurrency(Number(item?.taxRate))}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {formatCurrency(Number(item?.taxAmount))}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {formatCurrency(Number(item?.totalPrice))}
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            </div>
            <div className="flex justify-between mt-8">
              <div>
                <h4 className="text-sm text-[#11192A] ">
                  Receiving Funding Account Information
                </h4>
                <h5 className="text-sm text-[#11192A] ">
                  {directFundingData?.supplierAccountDetails?.accountNumber}
                </h5>
                <h5 className="text-sm text-[#11192A] ">
                  {directFundingData?.supplierAccountDetails?.accountName}
                </h5>
              </div>
              <div style={{ width: 300 }}>
                <div className="flex justify-between">
                  <span className="text-sm text-[#11192A] ">Subtotal</span>
                  <span className="text-sm text-[#11192A] ">
                    {formatCurrency(Number(invoiceData?.subTotal))}
                  </span>
                </div>
                <div className="flex justify-between">
                  <div>
                    <span className="text-sm text-[#11192A] ">VAT</span>
                    <span className="pl-2 text-sm text-[#11192A] ">
                      {invoiceData?.vat}%
                    </span>
                  </div>
                  <div>
                    <span className="text-sm text-[#11192A] ">{formatCurrency(Number(invoiceData?.vatAmount))}</span>
                  </div>
                </div>
                <div className="flex justify-between">
                  <div>
                    <span className="text-sm text-[#11192A] ">Discount</span>
                    <span className="pl-2 text-sm text-[#11192A] ">
                      {directFundingData?.discountRate}%
                    </span>
                  </div>
                  <div>
                    <span className="text-sm text-[#11192A] ">
                      {formatCurrency(Number(invoiceData?.discountAmount))}
                    </span>
                  </div>
                </div>
                <div>
                  <hr className="border-t border-[black] mt-2 pt-2" />
                  <div className="flex justify-between">
                    <span className="text-lg text-[#11192A] ">Grand Total</span>
                    <span className="text-lg text-[#11192A] ">
                      {formatCurrency(Number(invoiceData?.grandTotal))}
                    </span>
                  </div>
                  <hr className="border-t border-[black] mt-2 pt-2" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-[10px] gap-8 ">
          {/* <FundDetailSide /> */}
          <div class="w-full justify-start items-center gap-4 inline-flex ">
            <div class="border-b  border-[#c2c2c2]/30 justify-start items-center gap-2.5 flex w-full px-6  py-3">
              <div class="text-[#07593d] text-base font-bold ">
                Direct Funding Request Details
              </div>
            </div>
          </div>

          <div>
            {" "}
            {/* second */}
            <div className=" px-6 border-b  border-[#c2c2c2]/30  pb-4">
              <div class=" justify-between  flex flex-row   mt-4 ">
                <div class="py-2.5 flex-col justify-start items-start gap-2 inline-flex">
                  <div class="flex-col justify-start items-start gap-0.5 flex">
                    <div class="text-[#949494] text-sm font-medium ">
                      Request amount
                    </div>
                  </div>
                  <div class="flex-col justify-start items-start gap-0.5 flex">
                    <div class="text-black text-base font-medium ">
                      {directFundingData?.currency}{formatCurrency(Number(directFundingData?.requestAmount))}
                    </div>
                  </div>
                </div>
                <div class="py-2.5 flex-col justify-start items-start gap-2 inline-flex">
                  <div class="flex-col justify-start items-start gap-0.5 flex">
                    <div class="text-[#949494] text-sm font-medium ">
                      Request date
                    </div>
                  </div>
                  <div class="flex-col justify-start items-start gap-0.5 flex">
                    <div class="text-black text-base font-medium ">
                      {directFundingData?.earlyPaymentDate}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* stops here */}
            {/* Third */}
            <div className=" px-6  pb-4">
              <div class=" justify-between  flex flex-row   mt-4 ">
                <div class="py-2.5 flex-col justify-start items-start gap-2 inline-flex">
                  <div class="flex-col justify-start items-start gap-0.5 flex">
                    <div class="text-[#949494] text-sm font-medium ">
                      Status
                    </div>
                  </div>
                  <div class="flex-col justify-start items-start gap-0.5 flex">
                    <div class="text-[#f08000] text-base font-medium ">
                      {directFundingData?.status === "PENDING_CONFIRMATION" && "PENDING CONFIRMATION"}
                    </div>
                  </div>
                </div>
                <div class="py-2.5 flex-col justify-start items-start gap-2 inline-flex">
                  <div class="flex-col justify-start items-start gap-0.5 flex">
                    <div class="text-[#949494] text-sm font-medium ">
                      Discount Rate
                    </div>
                  </div>
                  <div class="flex-col justify-start items-start gap-0.5 flex">
                    <div class="text-black text-base font-medium ">
                      {directFundingData?.discountRate}%
                    </div>
                  </div>
                </div>
              </div>

              <div class="py-2.5 flex-col justify-start items-start gap-2 inline-flex mt-4  ">
                <div>
                  <h4 className="text-lg text-[#11192A] ">
                    Funder Personal Information
                  </h4>
                </div>
                <div>
                  <div class="flex-col justify-start items-start gap-0.5 flex">
                    <div class="text-[#949494] text-sm font-medium ">
                      Funder's Name
                    </div>
                  </div>
                  <div class="flex-col justify-start items-start gap-0.5 flex">
                    <div class="w-[400px] text-black text-base font-normal ">
                      {directFundingData?.funderCompanyName}
                    </div>
                  </div>
                </div>

                <div className="flex w-full justify-between">
                  <div className="flex flex-col">
                    <span className="text-sm text-[#11192A] ">Email</span>
                    <span className="text-sm text-[#11192A] ">{directFundingData?.funderEmail}</span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-sm text-[#11192A] ">
                      Contact Number
                    </span>
                    <span className="text-sm text-[#11192A] ">{directFundingData?.funderContact}</span>
                  </div>
                </div>

                <div className="w-full mt-4">
                  <button onClick={handleShowVirtualAccountSelection} className="w-full border-primaryColor text-white border px-12 py-2 rounded-md bg-primaryColor">
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Details;
