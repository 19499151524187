// import ContestedInvoiceCard from "./cards/ContestedInvoiceCard";

import ContestedInvoiceCard from "features/openMarket/supplier/components/cards/ContestedInvoiceCard";

function TopTenInvoiceDetails({
  head = "Top 10 Contested Invoices",
  subHead = "Invoices with the highest number of bidders",
  topTenBids,
  setActiveTab,
}) {
  return (
    <div className="flex flex-col gap-6 rounded-[10px] min-h-[80vh]  bg-unusualGray pb-12 ">
      <header className="flex bg-white justify-between items-center pb-4 border-b px-6 pt-6 border-b-subtleGray rounded-t-[10px]">
        <div className="flex flex-col gap-1">
          <span className=" font-semibold">{head}</span>
          <span className=" text-[#959595] text-xs">{subHead}</span>
        </div>
      </header>

      <div className="flex  p-6 flex-col gap-2.5">
        <ContestedInvoiceCard
          invoiceNumber="INV-2102"
          buyerName="Rannelle"
          noOfBids={24}
          invoiceAmount="N240,100"
          dueDate="24-03-2024"
          id="1"
        />

        <ContestedInvoiceCard
          invoiceNumber="INV-2102"
          buyerName="Rannelle"
          noOfBids={24}
          invoiceAmount="N240,100"
          dueDate="24-03-2024"
        />

        <ContestedInvoiceCard
          invoiceNumber="INV-2102"
          buyerName="Rannelle"
          noOfBids={24}
          invoiceAmount="N240,100"
          dueDate="24-03-2024"
        />
      </div>
    </div>
  );
}

export default TopTenInvoiceDetails;
