import DashboardLayout from "layout/dashboardlayout";
import Table from "layout/table/Table";
import { useNavigate } from "react-router-dom";
import Card from "shared/Card";
import TableHeader from "shared/tableHeader/TableHeader";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { buyerViewPfpOffers } from "appstate/buyer/buyerSlice";
import {
  PAIconInventoryArrowLeft,
  PAIconTableEmptyState,
} from "assets/images/svgs";

const PayableFinanceInvoices = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { pfpId, viewPfpOffersData, viewPfpByIdData } = useSelector(
    (state) => state?.buyer
  );
  useEffect(() => {
    dispatch(buyerViewPfpOffers({ id: pfpId }));
  }, [dispatch, pfpId]);

  const onRowClicked = (option) => {
    let path = "payableinvoicedetails";
    navigate(path, { state: { option } });
  };

  const columns = [
    {
      name: "Invoice Number",
      selector: (row) => "INV-" + row.invoiceNo,
      sortable: true,
      width: "7rem",
    },
    {
      name: "Supplier",
      selector: (row) => row.supplierName,
      sortable: true,
      width: "7rem",
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
      width: "7rem",
    },
    {
      name: "Due Date",
      selector: (row) => row.dueDate,
      sortable: true,
      width: "7rem",
    },
    {
      name: "Payment Date",
      selector: (row) => row.paymentDate,
      sortable: true,
      width: "7rem",
    },
    {
      name: "Invoice Amount",
      selector: (row) => row.invoiceAmount.toLocaleString(),
      sortable: true,
      width: "7rem",
    },
    {
      name: "Offer Amount",
      selector: (row) => row.invoiceAmount.toLocaleString(),
      sortable: true,
      width: "7rem",
    },
    {
      name: "Discount Amount",
      selector: (row) => row.invoiceAmount.toLocaleString(),
      sortable: true,
      width: "7rem",
    },
    {
      name: "Payable Amount",
      selector: (row) => row.invoiceAmount.toLocaleString(),
      sortable: true,
      width: "7rem",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      width: "7rem",
    },
  ];

  function handleGoback() {
    let path = -1;
    navigate(path);
  }

  const path = "/invoice/invoice";
  return (
    <DashboardLayout
      section="PFP Campaign"
      subHeader="Manage all your payable finance campaign"
    >
      <div
        onClick={handleGoback}
        className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
      >
        <PAIconInventoryArrowLeft />
        <p className="mt-[2px] text-sm font-normal text-lightGray">Go Back</p>
      </div>
      <Card className="p-[24px] rounded-[10px]">
        <div>
          <TableHeader
            details={`${viewPfpOffersData?.data?.length} Invoice(s) on ${viewPfpByIdData?.data?.programName}`}
            payableFinance
            path={path}
          />
        </div>
        {viewPfpOffersData?.data?.length === 0 ? (
          <div className="flex flex-col items-center justify-center w-full h-[65vh]">
            <div className="flex flex-col gap-4 items-center justify-center">
              <PAIconTableEmptyState />
              <p>No Data Available</p>
            </div>
          </div>
        ) : (
          <Table
            columns={columns}
            data={viewPfpOffersData?.data}
            onRowClicked={onRowClicked}
            //   progressPending={isLoading}
            //   progressComponent={<Loading />}
            pointer
            tableHeader
            className="mt-5"
          />
        )}
      </Card>
    </DashboardLayout>
  );
};

export default PayableFinanceInvoices;
