import React, { useEffect, useRef, useState } from "react";
import {
  PAIconDownload,
  PAIconFilter,
  PAIconOption,
  PAIconOutlineSearch,
} from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";
import Table from "layout/table/Table";
import AcceptModal from "shared/AcceptModal";
import SuccessModal from "shared/SuccessModal";
import { useDispatch, useSelector } from "react-redux";
import { allDirectFunding } from "appstate/invoice/invoiceSlice";
import Pagination from "shared/Pagination";
import Loading from "shared/Loading";
// import { remainder } from "appstate/collections/collectionSlice";

const Recievables = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  // const [selectedRows, setSelectedRows] = useState([]);
  const [singleSelectedRows, setSingleSelectedRows] = useState([]);
  const [bulkSelectedRows, setBulkSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  // const [selectAllText, setSelectAllText] = useState("Select All");
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  // const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [activeTab, setActiveTab] = useState("single");
  const [selectedOption, setSelectedOption] = useState("overdueInvoices");
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  const { /* directFundingData, */ isLoading } = useSelector(
    (state) => state?.invoice
  );

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen({});
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const dispatch = useDispatch();

  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const pageSize = 10;

  const handleSearch = () => {
    dispatch(allDirectFunding({ search: searchQuery }));
  };

  useEffect(() => {
    dispatch(
      allDirectFunding({
        page,
        pageSize,
        bulk: "",
        status: "APPROVED",
        search: searchQuery,
      })
    );
  }, [dispatch, page, pageSize, searchQuery]);

  const formatAmount = (amount) => {
    if (!amount || typeof amount !== "string") return "0.00";
    const parsedAmount = parseFloat(amount);
    if (isNaN(parsedAmount)) return "0.00";
    return parsedAmount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  // const handleNotificationClick = (row) => {
  //   setSelectedInvoice(row);
  //   const isSelected = selectedRows.includes(row.invoiceIds);
  //   let newSelectedRows = [];

  //   if (!isSelected) {
  //     newSelectedRows = [...selectedRows, row.invoiceIds];
  //   }

  //   setSelectedRows(newSelectedRows);
  //   setIsAcceptModalOpen(true);
  // };

  const handleNotificationClick = (row) => {
    // setSelectedInvoice(row);

    // Determine the selected rows state based on the active tab
    const isSingleTab = activeTab === "single";
    const selectedRowList = isSingleTab ? singleSelectedRows : bulkSelectedRows;

    const isSelected = selectedRowList.includes(row.id);
    let newSelectedRows = [];

    if (!isSelected) {
      newSelectedRows = [...selectedRowList, row.id];
    }

    // Update the appropriate state based on the active tab
    if (isSingleTab) {
      setSingleSelectedRows(newSelectedRows);
    } else {
      setBulkSelectedRows(newSelectedRows);
    }

    setIsAcceptModalOpen(true);
  };

  // const handleConfirm = () => {
  //   setIsAcceptModalOpen(false);
  //   setSelectedInvoice(null);
  //   const selectedInvoiceIds = selectedRows.map((id) => id);
  //   dispatch(
  //     remainder({ type: "directfunding", body: selectedInvoiceIds[0] })
  //   ).then((response) => {
  //     if (response?.payload?.status === 200 && response?.payload?.success) {
  //       setIsSuccessModalOpen(true);
  //       setSuccessMessage(response?.payload?.message);
  //     }
  //   });
  // };

  const handleConfirm = () => {
    setIsAcceptModalOpen(false);

    const selectedInvoiceIds =
      activeTab === "single" ? singleSelectedRows : bulkSelectedRows;

    if (selectedInvoiceIds.length > 0) {
      // dispatch(
      //   remainder({ type: "directfunding", body: selectedInvoiceIds[0] })
      // ).then((response) => {
      //   if (response?.payload?.status === 200 && response?.payload?.success) {
      //     setIsSuccessModalOpen(true);
      //     setSuccessMessage(response?.payload?.message);
      //   }
      // });
      setIsSuccessModalOpen(true);
      setSuccessMessage("Payment remainders sent successfully");
    }
  };

  const handleCloseSuccessModal = () => {
    setIsSuccessModalOpen(false);
  };

  const singleDetails = (id) => {
    let path = `/collections/recievables/${id}`;
    navigate(path);
  };

  const bulkDetails = (id) => {
    let path = `/collections/partial-recievables/${id}`;
    navigate(path);
  };

  const options = [
    { value: "overdueInvoices", label: "Overdue Invoices" },
    {
      value: "upcomingInvoices",
      label: "Upcoming Invoices",
    },
  ];

  const handleSelect = (option) => {
    setSelectedOption(option.value);
    setIsOpen(false);
  };

  // const handleSendReminder = () => {
  //   setIsAcceptModalOpen(true);
  // };
  
  const handleSendReminder = () => {
    const rowsToProcess =
      activeTab === "single" ? singleSelectedRows : bulkSelectedRows;
    if (rowsToProcess.length > 0) {
      setIsAcceptModalOpen(true);
    }
  };

  // const handleSelectAll = () => {
  //   if (selectAll) {
  //     setSelectedRows([]);
  //     setSelectAllText("Select All");
  //   } else {
  //     let allIds = [];
  //     if (activeTab === "single") {
  //       allIds = singleDummyData?.map((row) => row.id);
  //     } else {
  //       allIds = bulkDummyData?.map((row) => row.id);
  //     }
  //     setSelectedRows(allIds);
  //     setSelectAllText("Deselect All");
  //   }
  //   setSelectAll(!selectAll);
  // };

  const handleSelectAll = () => {
    if (activeTab === "single") {
      if (selectAll) {
        setSingleSelectedRows([]);
      } else {
        setSingleSelectedRows(fullDummyData.map((row) => row.id));
      }
    } else if (activeTab === "bulk") {
      if (selectAll) {
        setBulkSelectedRows([]);
      } else {
        setBulkSelectedRows(partialDummyData.map((row) => row.id));
      }
    }
    setSelectAll(!selectAll);
  };

  // useEffect(() => {
  //   // console.log(selectedRows);
  // }, [selectedRows]);

  const fullDummyData = [
    {
      id: 1,
      requestRefNumber: "R3456X89Y",
      buyerCompany: "XYZ Ventures",
      invoiceTimeline: "2023-05-23",
      dueDate: "2023-05-23",
      currency: "NGN",
      totalAmount: "9,000,000",
      dueDays: "2 days",
      status:
        selectedOption === "overdueInvoices" ? "Overdue" : "Pending Payment",
    },
    {
      id: 2,
      requestRefNumber: "R3456X89Y",
      buyerCompany: "XYZ Ventures",
      invoiceTimeline: "2023-05-23",
      dueDate: "2023-05-23",
      currency: "NGN",
      requestAmount: "9,000,000",
      dueDays: "4 days",
      status:
        selectedOption === "overdueInvoices" ? "Overdue" : "Pending Payment",
    },
    {
      id: 3,
      requestRefNumber: "R3456X89Y",
      buyerCompany: "XYZ Ventures",
      invoiceTimeline: "2023-05-23",
      dueDate: "2023-05-23",
      currency: "NGN",
      requestAmount: "9,000,000",
      dueDays: "1 day",
      status:
        selectedOption === "overdueInvoices" ? "Overdue" : "Pending Payment",
    },
  ];

  const partialDummyData = [
    {
      id: 1,
      invoiceNumber: "R3456X89Y",
      buyerCompany: "XYZ Ventures",
      fundingChannel: "Open market",
      dueDate: "2023-05-23",
      currency: "NGN",
      fundAmount: "9,000,000",
      fundDate: "2023-05-23",
      balanceAmount: "950,000",
      dueDays: "2 days",
      status:
        selectedOption === "overdueInvoices" ? "Overdue" : "Pending Payment",
    },
    {
      id: 2,
      invoiceNumber: "R3456X89Y",
      buyerCompany: "XYZ Ventures",
      fundingChannel: "Open market",
      dueDate: "2023-05-23",
      currency: "NGN",
      fundAmount: "9,000,000",
      fundDate: "2023-05-23",
      balanceAmount: "950,000",
      dueDays: "2 days",
      status:
        selectedOption === "overdueInvoices" ? "Overdue" : "Pending Payment",
    },
    {
      id: 3,
      invoiceNumber: "R3456X89Y",
      buyerCompany: "XYZ Ventures",
      fundingChannel: "Open market",
      dueDate: "2023-05-23",
      currency: "NGN",
      fundAmount: "9,000,000",
      fundDate: "2023-05-23",
      balanceAmount: "950,000",
      dueDays: "2 days",
      status:
        selectedOption === "overdueInvoices" ? "Overdue" : "Pending Payment",
    },
  ];

  const columns =
    activeTab === "single"
      ? [
          {
            name: (
              <input
                type="checkbox"
                onChange={handleSelectAll}
                checked={singleSelectedRows.length === fullDummyData.length}
              />
            ),
            cell: (row) => (
              <input
                type="checkbox"
                onChange={() => handleCheckboxChange(row)}
                checked={singleSelectedRows.includes(row.id)}
              />
            ),
            width: "50px",
          },
          {
            name: (
              <span className="whitespace-normal">
                <img className=" pr1 inline" src="/group-icon.svg" alt="" />
                Invoice Number
              </span>
            ),
            selector: (row) => {
              const invoiceNumber = row.invoiceNumber;
              return invoiceNumber && !isNaN(invoiceNumber)
                ? `INV-${invoiceNumber}`
                : "INV-0";
            },
            sortable: true,
          },
          {
            name: "Buyer",
            selector: (row) => row.buyerCompany,
            sortable: true,
          },
          { name: "Due Date", selector: (row) => row.dueDate, sortable: true },
          {
            name: "Invoice Timeline",
            selector: (row) => row.invoiceTimeline,
            sortable: true,
          },
          {
            name:
              selectedOption === "overdueInvoices"
                ? "Days Past Due"
                : "Days to Due",
            selector: (row) => row.dueDays,
            sortable: true,
            cell: (row) => (
              <p
                className={
                  selectedOption === "overdueInvoices"
                    ? "text-red"
                    : "text-success"
                }
              >
                {" "}
                {row.dueDays}{" "}
              </p>
            ),
          },
          { name: "Currency", selector: (row) => row.currency, sortable: true },
          {
            name: "Total Amount",
            selector: (row) => row.totalAmount,
            sortable: true,
            cell: (row) => (
              <p className="text-success">{formatAmount(row.invoiceAmount)}</p>
            ),
          },
          {
            name: "Status",
            selector: (row) => row.status,
            sortable: true,
            cell: (row) => (
              <p
                className={
                  selectedOption === "overdueInvoices"
                    ? "text-red"
                    : "text-yellow-400"
                }
              >
                {" "}
                {row.status}{" "}
              </p>
            ),
          },
          {
            cell: (row) => (
              <div className="flex gap-2">
                <PAIconOption
                  title="Options"
                  onClick={() => {
                    setIsDropdownOpen((prev) => ({
                      ...prev,
                      [row.id]: !prev[row.id],
                    }));
                    Object.keys(isDropdownOpen).forEach((key) => {
                      if (key !== row.id.toString()) {
                        setIsDropdownOpen((prev) => ({
                          ...prev,
                          [key]: false,
                        }));
                      }
                    });
                  }}
                />
                {isDropdownOpen[row.id] && (
                  <div
                    ref={dropdownRef}
                    className="absolute bg-white border border-gray300 rounded-md shadow-md z-10"
                  >
                    <ul>
                      <li
                        className="px-4 py-2 cursor-pointer hover:bg-primaryColor hover:text-white"
                        onClick={() => {
                          singleDetails(row?.id);
                          setIsDropdownOpen((prev) => ({
                            ...prev,
                            [row.id]: false,
                          }));
                        }}
                      >
                        Preview
                      </li>
                      <li
                        className="px-4 py-2 cursor-pointer hover:bg-primaryColor hover:text-white"
                        onClick={() => {
                          handleNotificationClick(row);
                          setIsDropdownOpen((prev) => ({
                            ...prev,
                            [row.id]: false,
                          }));
                        }}
                      >
                        Send Reminder
                      </li>
                      <li className="px-4 py-2 cursor-not-allowed text-gray300">
                        Export
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            ),
          },
        ]
      : [
          {
            name: (
              <input
                type="checkbox"
                onChange={handleSelectAll}
                checked={bulkSelectedRows.length === partialDummyData.length}
              />
            ),
            cell: (row) => (
              <input
                type="checkbox"
                onChange={() => handleCheckboxChange(row)}
                checked={bulkSelectedRows.includes(row.id)}
              />
            ),
            width: "50px",
          },
          {
            name: (
              <span className="whitespace-normal">
                <img className=" pr1 inline" src="/group-icon.svg" alt="" />
                Invoice Number
              </span>
            ),
            selector: (row) => row.invoiceNumber,
            sortable: true,
          },
          {
            name: "Buyer",
            selector: (row) => row.buyerCompany,
            sortable: true,
          },
          {
            name: "Due Date",
            selector: (row) => row.dueDate,
            sortable: true,
          },
          {
            name: "Funding Channel",
            selector: (row) => row.fundingChannel,
            sortable: true,
          },
          {
            name: "Currency",
            selector: (row) => row.currency,
            sortable: true,
          },
          {
            name: "Fund Amount",
            selector: (row) => row.fundAmount,
            sortable: true,
            cell: (row) => (
              <p className="text-success">{formatAmount(row.fundAmount)}</p>
            ),
          },
          {
            name: "Balance Amount",
            selector: (row) => row.balanceAmount,
            sortable: true,
          },
          {
            name:
              selectedOption === "overdueInvoices"
                ? "Days Past Due"
                : "Days to Due",
            selector: (row) => row.dueDays,
            sortable: true,
            cell: (row) => (
              <p
                className={
                  selectedOption === "overdueInvoices"
                    ? "text-red"
                    : "text-success"
                }
              >
                {" "}
                {row.dueDays}{" "}
              </p>
            ),
          },
          {
            name: "Status",
            selector: (row) => row.status,
            sortable: true,
            cell: (row) => (
              <p
                className={
                  selectedOption === "overdueInvoices"
                    ? "text-red"
                    : "text-yellow-400"
                }
              >
                {" "}
                {row.status}{" "}
              </p>
            ),
          },
          {
            cell: (row) => (
              <div className="flex gap-2">
                <PAIconOption
                  title="Options"
                  onClick={() => {
                    setIsDropdownOpen((prev) => ({
                      ...prev,
                      [row.id]: !prev[row.id],
                    }));
                    Object.keys(isDropdownOpen).forEach((key) => {
                      if (key !== row.id.toString()) {
                        setIsDropdownOpen((prev) => ({
                          ...prev,
                          [key]: false,
                        }));
                      }
                    });
                  }}
                />
                {isDropdownOpen[row.id] && (
                  <div
                    ref={dropdownRef}
                    className="absolute bg-white border border-gray300 rounded-md shadow-md z-10"
                  >
                    <ul>
                      <li
                        className="px-4 py-2 cursor-pointer hover:bg-primaryColor hover:text-white"
                        onClick={() => {
                          bulkDetails(row?.id);
                          setIsDropdownOpen((prev) => ({
                            ...prev,
                            [row.id]: false,
                          }));
                        }}
                      >
                        Preview
                      </li>
                      <li
                        className="px-4 py-2 cursor-pointer hover:bg-primaryColor hover:text-white"
                        onClick={() => {
                          handleNotificationClick(row);
                          setIsDropdownOpen((prev) => ({
                            ...prev,
                            [row.id]: false,
                          }));
                        }}
                      >
                        Send Reminder
                      </li>
                      <li className="px-4 py-2 cursor-not-allowed text-gray300">
                        Export
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            ),
          },
        ];

  const handleCheckboxChange = (row) => {
    if (activeTab === "single") {
      const isSelected = singleSelectedRows.includes(row.id);
      setSingleSelectedRows(
        isSelected
          ? singleSelectedRows.filter((id) => id !== row.id)
          : [...singleSelectedRows, row.id]
      );
    } else if (activeTab === "bulk") {
      const isSelected = bulkSelectedRows.includes(row.id);
      setBulkSelectedRows(
        isSelected
          ? bulkSelectedRows.filter((id) => id !== row.id)
          : [...bulkSelectedRows, row.id]
      );
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "single":
        // return <SingleTableDirectFund data={dummyData} columns={columns} />;
        return (
          <>
            <Table
              columns={columns}
              data={fullDummyData}
              onRowClicked={(row) => singleDetails(row.id)}
              pointer
              tableHeader
              className="mt-5"
            />
            <div className="flex justify-center gap-x-[8px] mt-[20px]">
              {fullDummyData?.length > 0 && (
                <Pagination
                  page={page}
                  startPage={startPage}
                  setEndPage={setEndPage}
                  endPage={endPage}
                  setStartPage={setStartPage}
                  setPage={setPage}
                  // totalPages={directFundingData?.meta?.totalElements}
                />
              )}
            </div>
          </>
        );
      case "bulk":
        // return <BulkTableDirectFund />;
        return (
          <>
            <Table
              columns={columns}
              data={partialDummyData}
              onRowClicked={(row) => bulkDetails(row.id)}
              progressPending={isLoading}
              progressComponent={<Loading />}
              pointer
              tableHeader
              className="mt-5"
            />
            <div className="flex justify-center gap-x-[8px] mt-[20px]">
              {partialDummyData?.length > 0 && (
                <Pagination
                  page={page}
                  startPage={startPage}
                  setEndPage={setEndPage}
                  endPage={endPage}
                  setStartPage={setStartPage}
                  setPage={setPage}
                  // totalPages={directFundingData?.meta?.totalElements}
                />
              )}
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className="my-2 grid md:grid-cols-2 items-center">
        <div className="gap-3 flex relative">
          <div>
            {/* FILTER */}
            <div className="relative">
              {/* Dropdown Button */}
              <button
                onClick={() => setIsOpen(!isOpen)}
                className="bg-white rounded flex text-sm items-center justify-between px-4 py-2 w-auto focus:outline-none"
              >
                {options.find((opt) => opt.value === selectedOption)?.label}
                <svg
                  className={`w-4 h-8 ml-2 transition-transform ${
                    isOpen ? "rotate-180" : "rotate-0"
                  }`}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.707a1 1 0 011.414 0L10 11.293l3.293-3.586a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>

              {/* Dropdown Menu */}
              {isOpen && (
                <ul className="absolute mt-2 w-auto text-sm bg-white border border-gray300 rounded-2xl shadow-md z-10">
                  {options.map((option) => (
                    <li
                      key={option.value}
                      onClick={() => handleSelect(option)}
                      className={`px-4 py-2 flex items-center text-xs cursor-pointer hover:bg-primaryColor hover:text-white ${
                        selectedOption === option.value ? "bgprimaryColor" : ""
                      }`}
                    >
                      {option.label}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>

          {/* EXPORT */}
          <button className="py-2 px-4 text-sm rounded-[5px] bg-white border-[#DEDEDE] shadow-sm mr-2 flex items-center justify-center gap-1.5">
            Export {<PAIconDownload className="accent-gray300 w-4" />}
          </button>
        </div>

        {/* SEARCH */}
        <div className="flex md:col-span-1 justify-end">
          <div className="flex items-center justify-between gap-5">
            <div className="border border-transparent md:border-gray md:shadow-sm my-4 rounded-[5px] px-2 lg:w-[300px] bg-white">
              <div className="items-center flex bg-white">
                <PAIconOutlineSearch />
                <input
                  className="bg-transparent p-2 w-full focus:outline-none text-sm bg-white"
                  type="text"
                  placeholder="Search for invoices awaiting collection"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleSearch();
                    }
                  }}
                />
              </div>
              <div className="lg:hidden flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer">
                <PAIconFilter className="bg-[#2FA06A]" />
              </div>
            </div>
            <button className="hidden lg:flex bg-[#2FA06A] rounded-md my-4 py-2 px-6 mx3 items-center gap-2 text-white">
              Search
            </button>
          </div>
        </div>
      </div>

      {/* TABLE */}
      <div>
        {!fullDummyData.length && !partialDummyData?.length ? (
          <div>
            <div className="flex justify-between items-center px-[15px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
              <p className="text-black text-sm font-medium">
                Invoices from Buyers awaiting collection (Receivables)
              </p>
            </div>
            <TableEmptyState dashboard />
          </div>
        ) : (
          <Card className="rounded-[10px] mt-[15px]">
            <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
              <div>
                <p className="text-black text-lg font-bold">
                  Invoices from Buyers awaiting collection (Receivables)
                </p>
                <span className="text-xs font-thin">
                  List of all invoices from buyers
                </span>
              </div>
              <div className="flex items-center justify-center gap-3">
                {/* <div
                  className="border border-gray300 rounded-md p-2 text-white bg-[#2FA06A] cursor-pointer"
                  onClick={handleSelectAll}
                >
                  <p>{selectAllText}</p>
                </div> */}
                {/* <button
                  className={`border border-gray300 rounded-md p-2 text-white ${
                    selectedRows.length === 0
                      ? "cursor-not-allowed bg-gray300"
                      : "cursor-pointer bg-secondaryColor"
                  }`}
                  disabled={selectedRows.length === 0}
                  onClick={handleSendReminder}
                >
                  <p>Send Reminder</p>
                </button> */}
                <button
                  className={`border border-gray300 rounded-md p-2 text-white ${
                    (activeTab === "single" &&
                      singleSelectedRows.length === 0) ||
                    (activeTab === "bulk" && bulkSelectedRows.length === 0)
                      ? "cursor-not-allowed bg-gray300"
                      : "cursor-pointer bg-secondaryColor"
                  }`}
                  disabled={
                    (activeTab === "single" &&
                      singleSelectedRows.length === 0) ||
                    (activeTab === "bulk" && bulkSelectedRows.length === 0)
                  }
                  onClick={handleSendReminder}
                >
                  <p>Send Reminder Now</p>
                </button>
              </div>
            </div>
            <div className="pb-[24px]">
              <div className="flex px-[24px] gap-3 my-3">
                <p
                  className={`cursor-pointer font-bold relative ${
                    activeTab === "single" ? "text-[#F08000]" : "text-[#959595]"
                  }`}
                  onClick={() => handleTabClick("single")}
                >
                  Full payment
                  {activeTab === "single" && (
                    <span
                      className="absolute bottom-0 left-1/2 transform -translate-x-1/2
                 bg-[#F08000] h-0.5 w-5"
                    ></span>
                  )}
                </p>

                <p
                  className={`cursor-pointer font-bold relative ${
                    activeTab === "bulk" ? "text-[#F08000]" : "text-[#959595]"
                  }`}
                  onClick={() => handleTabClick("bulk")}
                >
                  Partial payment
                  {activeTab === "bulk" && (
                    <span
                      className="absolute bottom-0 left-1/2 transform -translate-x-1/2
                 bg-[#F08000] h-0.5 w-5"
                    ></span>
                  )}
                </p>
              </div>

              <div className="mt-3">{renderTabContent()}</div>
            </div>
          </Card>
        )}
      </div>

      <AcceptModal
        isOpen={isAcceptModalOpen}
        onClose={() => setIsAcceptModalOpen(false)}
        yes
        message={
          <>
            Would you like to proceed with sending a <br />
            <span className="text-[#07593D] font-bold">
              Payment reminder
            </span>{" "}
            now?
          </>
        }
        onConfirm={handleConfirm}
      />

      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={handleCloseSuccessModal}
        message={successMessage}
      />
    </>
  );
};

export default Recievables;

// import React, { useEffect, useState } from "react";
// import {
//   PAIconDownload,
//   PAIconDropdown,
//   PAIconFilter,
//   PAIconNotification,
//   PAIconOutlineSearch,
//   PAIconVieweye,
// } from "assets/images/svgs";
// import { useNavigate } from "react-router-dom";
// import Card from "shared/Card";
// import TableEmptyState from "shared/TableEmptyState";
// import Table from "layout/table/Table";
// import AcceptModal from "shared/AcceptModal";
// import SuccessModal from "shared/SuccessModal";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   allRecievables,
//   remainder,
// } from "appstate/collections/collectionSlice";
// import Pagination from "shared/Pagination";
// import Loading from "shared/Loading";

// const Recievables = () => {
//   const [selectedFilters, setSelectedFilters] = useState([]);
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);
//   const [selectedRows, setSelectedRows] = useState([]);
//   const [selectAll, setSelectAll] = useState(false);
//   const [selectAllText, setSelectAllText] = useState("Select All");
//   const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
//   const [selectedInvoice, setSelectedInvoice] = useState(null);

//   const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
//   const [successMessage, setSuccessMessage] = useState("");

//   const navigate = useNavigate();

//   const dispatch = useDispatch();
//   const { recievablesData, isLoading } = useSelector(
//     (state) => state?.collection
//   );

//   const formatAmount = (amount) => {
//     if (typeof amount !== "number") return "0.00";
//     return amount.toLocaleString(undefined, {
//       minimumFractionDigits: 2,
//       maximumFractionDigits: 2,
//     });
//   };

//   const [searchQuery, setSearchQuery] = useState("");
//   const [page, setPage] = useState(0);
//   const [startPage, setStartPage] = useState(0);
//   const [endPage, setEndPage] = useState(10);
//   const pageSize = 10;

//   const handleSearch = () => {
//     dispatch(allRecievables({ page, pageSize, search: searchQuery }));
//   };

//   useEffect(() => {
//     dispatch(allRecievables({ page, pageSize, search: searchQuery }));
//   }, [dispatch, page, pageSize, searchQuery]);

//   const handleConfirm = () => {
//     setIsAcceptModalOpen(false);
//     setSelectedInvoice(null);
//     const selectedInvoiceIds = selectedRows.map((id) => id);
//     dispatch(remainder({ type: "receivable", body: selectedInvoiceIds })).then(
//       (response) => {
//         if (response?.payload?.status === 200 && response?.payload?.success) {
//           setIsSuccessModalOpen(true);
//           setSuccessMessage(response?.payload?.message);
//         }
//       }
//     );
//   };

//   const handleCloseSuccessModal = () => {
//     setIsSuccessModalOpen(false);
//   };

//   const receivablesDetails = (id) => {
//     let path = `/collections/recievables/${id}`;
//     navigate(path);
//   };

//   const handleFilterChange = (e) => {
//     const value = e.target.value;
//     let updatedFilters = [...selectedFilters];

//     if (updatedFilters.includes(value)) {
//       updatedFilters = updatedFilters.filter((filter) => filter !== value);
//     } else {
//       updatedFilters.push(value);
//     }

//     setSelectedFilters(updatedFilters);
//   };

//   // FILTER
//   const handleDropdownToggle = () => {
//     setIsDropdownOpen(!isDropdownOpen);
//   };

//   // SEND REMAINDER BUTTON
//   const handleSendReminder = () => {
//     setSelectedInvoice(null);
//     setIsAcceptModalOpen(true);
//   };

//   // NOTIFICATION CLICK
//   const handleNotificationClick = (row) => {
//     setSelectedInvoice(row);
//     const isSelected = selectedRows.includes(row.invoiceId);
//     let newSelectedRows = [];

//     if (!isSelected) {
//       newSelectedRows = [...selectedRows, row.invoiceId];
//     }

//     setSelectedRows(newSelectedRows);
//     setIsAcceptModalOpen(true);
//   };

//   // CHECKBOX
//   const handleCheckboxChange = (row) => {
//     const isSelected = selectedRows.includes(row.invoiceId);
//     let newSelectedRows = [];

//     if (isSelected) {
//       newSelectedRows = selectedRows.filter((id) => id !== row.invoiceId);
//     } else {
//       newSelectedRows = [...selectedRows, row.invoiceId];
//     }

//     setSelectedRows(newSelectedRows);
//   };

//   // SELECT ALL
//   const handleSelectAll = () => {
//     if (selectAll) {
//       setSelectedRows([]);
//       setSelectAllText("Select All");
//     } else {
//       const allIds = recievablesData?.data?.map((row) => row.invoiceId);
//       setSelectedRows(allIds);
//       setSelectAllText("Deselect All");
//     }
//     setSelectAll(!selectAll);
//     // setDisabled(selectAll);
//   };

//   const columns = [
//     {
//       name: (
//         <input type="checkbox" onChange={handleSelectAll} checked={selectAll} />
//       ),
//       cell: (row) => (
//         <input
//           type="checkbox"
//           onChange={() => handleCheckboxChange(row)}
//           checked={selectedRows.includes(row.invoiceId)}
//         />
//       ),
//       width: "50px",
//     },
//     {
//       name: (
//         <span className="whitespace-nowrap">
//           <img className=" pr1 inline" src="/group-icon.svg" alt="" />
//           Invoice Number
//         </span>
//       ),
//       selector: (row) => row.invoiceReference,
//       sortable: true,
//     },
//     {
//       name: "Buyer",
//       selector: (row) => row.buyerCompanyName,
//       sortable: true,
//     },
//     {
//       name: "Due Date",
//       selector: (row) => row.dueDate,
//       sortable: true,
//     },
//     {
//       name: "Currency",
//       selector: (row) => row.currency,
//       sortable: true,
//     },
//     {
//       name: "Total Amount",
//       selector: (row) => row.invoiceAmount,
//       sortable: true,
//       cell: (row) => (
//         <p className="text-success">{formatAmount(row.invoiceAmount)}</p>
//       ),
//     },
//     {
//       name: "Status",
//       selector: (row) => row.status,
//       sortable: true,
//       cell: () => <p className="text-yellow-400">PENDING PAYMENT</p>,
//     },
//     {
//       name: "Action",
//       cell: (row) => (
//         <div className="flex gap-2">
//           {
//             <PAIconVieweye
//               className="accent-gray300 w-4"
//               onClick={() => receivablesDetails(row.invoiceId)}
//             />
//           }
//           {
//             <PAIconNotification
//               className="accent-gray300 w-4"
//               onClick={() => handleNotificationClick(row)}
//             />
//           }
//           {<PAIconDownload className="accent-gray300 w-4" />}
//         </div>
//       ),
//     },
//   ];

//   return (
//     <>
//       <div className="my-2 grid md:grid-cols-2 items-center">
//         <div className="gap-3 flex relative">
//           <div>
//             {/* FILTER */}
//             <div
//               className="flex items-center gap-[4px] border border-[#DEDEDE] bg-white shadow-sm rounded-[5px] px-4 py-2 text-sm focus:outline-none cursor-pointer"
//               onClick={handleDropdownToggle}
//             >
//               Filter{" "}
//               {selectedFilters.length > 0 && (
//                 <span className="bg-gray rounded-lg p-1 px-2">
//                   {selectedFilters.length}
//                 </span>
//               )}{" "}
//               Selected <PAIconDropdown />
//             </div>
//             {isDropdownOpen && (
//               <div className="absolute top-full mt1 wfull border border-[#DEDEDE] shadow-lg rounded-b-[5px] px-4 py-3 bg-white">
//                 <label className="block">
//                   <input
//                     type="checkbox"
//                     value="date"
//                     checked={selectedFilters.includes("date")}
//                     onChange={handleFilterChange}
//                   />
//                   Date
//                 </label>
//                 <label className="block">
//                   <input
//                     type="checkbox"
//                     value="name"
//                     checked={selectedFilters.includes("name")}
//                     onChange={handleFilterChange}
//                   />
//                   Name
//                 </label>
//                 <label className="block">
//                   <input
//                     type="checkbox"
//                     value="currency"
//                     checked={selectedFilters.includes("currency")}
//                     onChange={handleFilterChange}
//                   />
//                   Currency
//                 </label>
//                 <label className="block">
//                   <input
//                     type="checkbox"
//                     value="amount"
//                     checked={selectedFilters.includes("amount")}
//                     onChange={handleFilterChange}
//                   />
//                   Amount
//                 </label>
//               </div>
//             )}
//           </div>

//           {/* SORT BY */}
//           {/* <div>
//             <select
//               className="border border-[#DEDEDE] shadow-sm rounded-[5px] px-4 py-3 text-sm focus:outline-none cursor-pointer"
//               onChange={(e) => {
//                 // const selectedSortBy = e.target.value;
//               }}
//             >
//               <option value="">Sort by</option>
//               <option value="date">Date</option>
//               <option value="name">Name</option>
//             </select>
//           </div> */}

//           {/* EXPORT */}
//           <button className="py-2 px-4 rounded-[5px] bg-white border-[#DEDEDE] shadow-sm mr-2 flex items-center justify-center gap-1.5">
//             Export {<PAIconDownload className="accent-gray300 w-4" />}
//           </button>
//         </div>

//         {/* SEARCH */}
//         <div className="flex md:col-span-1 justify-end">
//           <div className="flex items-center justify-between gap-5">
//             <div className="border border-transparent md:border-gray md:shadow-sm my-4 rounded-[5px] px-2 lg:w-[300px] bg-white">
//               <div className="items-center flex bg-white">
//                 <PAIconOutlineSearch />
//                 <input
//                   className="bg-transparent p-2 w-full focus:outline-none text-sm bg-white"
//                   type="text"
//                   placeholder="Search for request"
//                   value={searchQuery}
//                   onChange={(e) => setSearchQuery(e.target.value)}
//                   onKeyPress={(e) => {
//                     if (e.key === "Enter") {
//                       handleSearch();
//                     }
//                   }}
//                 />
//               </div>
//               <div className="lg:hidden flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer">
//                 <PAIconFilter className="bg-[#2FA06A]" />
//               </div>
//             </div>
//             <button className="hidden lg:flex bg-[#2FA06A] rounded-md my-4 py-2 px-6 mx3 items-center gap-2 text-white">
//               <PAIconFilter /> Search
//             </button>
//           </div>
//         </div>
//       </div>

//       {/* TABLE */}
//       <div>
//         {!recievablesData?.data?.length ? (
//           <div>
//             <div className="flex justify-between items-center px-[15px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
//               <p className="text-black text-sm font-medium">
//                 Due Invoices from Buyers (Receivables)
//               </p>
//             </div>
//             <TableEmptyState dashboard />
//           </div>
//         ) : (
//           <Card className="rounded-[10px] mt-[15px]">
//             <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
//               <div>
//                 <p className="text-black text-lg font-bold">
//                   Due Invoices from Buyers (Receivables)
//                 </p>
//                 <span className="text-xs font-thin">
//                   List of all due invoices from buyers (without funding from
//                   funders)
//                 </span>
//               </div>
//               <div className="flex items-center justify-center gap-3">
//                 <div
//                   className="border border-gray300 rounded-md p-2 text-white bg-[#2FA06A] cursor-pointer"
//                   onClick={handleSelectAll}
//                 >
//                   <p>{selectAllText}</p>
//                 </div>
//                 <button
//                   className={`border border-gray300 rounded-md p-2 text-white ${
//                     selectedRows.length === 0
//                       ? "cursor-not-allowed bg-gray300"
//                       : "cursor-pointer bg-secondaryColor"
//                   }`}
//                   disabled={selectedRows.length === 0}
//                   onClick={handleSendReminder}
//                 >
//                   <p>Send Reminder</p>
//                 </button>
//               </div>
//             </div>
//             <div className="pb-[24px]">
//               <div className="flex px-[24px] gap-5 my-5">
//                 <p className="text-sm font-normal">Due Invoices from</p>
//                 <p className="text-sm font-bold">Buyer</p>
//               </div>
//               <Table
//                 columns={columns}
//                 data={recievablesData?.data}
//                 onRowClicked={(row) => receivablesDetails(row.invoiceId)}
//                 progressPending={isLoading}
//                 progressComponent={<Loading />}
//                 pointer
//                 tableHeader
//                 className="mt-5"
//               />
//               <div className="flex justify-center gap-x-[8px] mt-[20px]">
//                 {recievablesData?.data?.length >= 10 && (
//                   <Pagination
//                     page={page}
//                     startPage={startPage}
//                     setEndPage={setEndPage}
//                     endPage={endPage}
//                     setStartPage={setStartPage}
//                     setPage={setPage}
//                     totalPages={recievablesData?.meta?.totalElements}
//                   />
//                 )}
//               </div>
//             </div>
//           </Card>
//         )}
//       </div>
//       <AcceptModal
//         isOpen={isAcceptModalOpen}
//         onClose={() => setIsAcceptModalOpen(false)}
//         message={
//           <>
//             Would you like to proceed with sending a <br />
//             <span className="text-[#07593D] font-bold">
//               Payment reminder
//             </span>{" "}
//             {selectedRows.length > 1 ? (
//               <span>to all selected due invoices?</span>
//             ) : (
//               <>
//                 to due invoice with ref.no{" "}
//                 <span className="text-secondaryColor font-bold">
//                   {selectedInvoice?.invoiceReference}
//                 </span>
//                 ?
//               </>
//             )}
//           </>
//         }
//         onConfirm={handleConfirm}
//       />

//       <SuccessModal
//         isOpen={isSuccessModalOpen}
//         onClose={handleCloseSuccessModal}
//         message={successMessage}
//       />
//     </>
//   );
// };

// export default Recievables;
