import React, { useEffect, useState, useRef } from "react";
import {
  PAIconDownload,
  PAIconFilter,
  // PAIconNotification,
  PAIconOutlineSearch,
  // PAIconVieweye,
  PAIconOption,
} from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";
import Table from "layout/table/Table";
import AcceptModal from "shared/AcceptModal";
import SuccessModal from "shared/SuccessModal";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "shared/Pagination";
import Loading from "shared/Loading";
import {
  allFunderOpenMarket,
  remainder,
} from "appstate/collections/collectionSlice";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formatCapitaliseString } from "utilities/helper/formatCapitaliseString";

const OpenMarket = () => {
  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  // const [setSelectAllText] = useState("Select All");
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [tableType, setTableType] = useState("buyer");
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(10);
  const pageSize = 10;

  const handleSearch = () => {
    const discountType =
      tableType === "buyer" ? "WITHOUT_RECOURSE" : "WITH_RECOURSE";
    dispatch(allFunderOpenMarket({ search: searchQuery, discountType }));
  };

  useEffect(() => {
    const discountType =
      tableType === "buyer" ? "WITHOUT_RECOURSE" : "WITH_RECOURSE";
    dispatch(
      allFunderOpenMarket({
        page,
        pageSize,
        discountType,
        search: searchQuery,
      })
    );
  }, [dispatch, page, pageSize, searchQuery, tableType]);

  const { allFunderOpenMarketData, isLoading } = useSelector(
    (state) => state?.collection
  );

  const handleNotificationClick = (row) => {
    setSelectedInvoice(row);
    const isSelected = selectedRows?.includes(row?.invoiceId);
    let newSelectedRows = [];

    if (!isSelected) {
      newSelectedRows = [...selectedRows, row?.invoiceId];
    }

    setSelectedRows(newSelectedRows);
    setIsAcceptModalOpen(true);
  };

  // CONFIRM REMAINDER
  const handleConfirm = () => {
    setIsAcceptModalOpen(false);
    setSelectedInvoice(null);
    const selectedInvoiceIds = selectedRows.map((id) => id);
    dispatch(remainder({ type: "openmarket", body: selectedInvoiceIds })).then(
      (response) => {
        if (response?.payload?.status === 200 && response?.payload?.success) {
          setIsSuccessModalOpen(true);
          setSuccessMessage(response?.payload?.message);
        }
      }
    );
  };

  const handleCloseSuccessModal = () => {
    setIsSuccessModalOpen(false);
  };

  // fundercollection-openmarket
  const receivablesDetails = (requestReference) => {
    let path = `/collections/fundercollection-openmarket/${requestReference}`;
    navigate(path);
  };

  // SEND REMAINDER BUTTON
  const handleSendReminder = () => {
    setIsAcceptModalOpen(true);
  };

  // CHECKBOX
  const handleCheckboxChange = (row) => {
    const isSelected = selectedRows?.includes(row?.invoiceId);
    let newSelectedRows = [];

    if (isSelected) {
      newSelectedRows = selectedRows?.filter((id) => id !== row?.invoiceId);
    } else {
      newSelectedRows = [...selectedRows, row?.invoiceId];
    }

    // console.log(newSelectedRows)

    setSelectedRows(newSelectedRows);
  };

  // SELECT ALL
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
      // setSelectAllText("Select All");
    } else {
      const allIds =
        allFunderOpenMarketData?.data?.map((row) => row.invoiceId) || [];
      setSelectedRows(allIds);
      // setSelectAllText("Deselect All");
      // console.log(allIds);
    }
    setSelectAll(!selectAll);
  };

  const dropdownRef = useRef(null);

  const buyerColumns = [
    {
      name: (
        <input type="checkbox" onChange={handleSelectAll} checked={selectAll} />
      ),
      cell: (row) => (
        <input
          type="checkbox"
          onChange={() => handleCheckboxChange(row)}
          checked={selectedRows?.includes(row?.invoiceId)}
        />
      ),
      width: "50px",
    },
    {
      name: (
        <span className="whitespace-normal">
          <img className="pr1 inline" src="/group-icon.svg" alt="" />
          Request ref Number
        </span>
      ),
      selector: (row) => row.requestReference || "N/A",
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-normal">
          <img className="pr1 inline" src="/group-icon.svg" alt="" />
          Buyer
        </span>
      ),
      selector: (row) => row.buyerCompanyName || "N/A",
      sortable: true,
    },
    {
      name: <span className="whitespace-normal">Payment Date</span>,
      selector: (row) => row.paymentDate,
      sortable: true,
      cell: (row) => {
        // Convert the array to a Date object
        const date = new Date(
          row.paymentDate[0],
          row.paymentDate[1] - 1,
          row.paymentDate[2]
        );

        // Format the date as 'YYYY-MM-DD'
        const formattedDate = date.toLocaleDateString("en-CA");

        return <p>{formattedDate}</p>;
      },
    },
    {
      name: "Currency",
      selector: (row) => row.currency || "N/A",
      sortable: true,
    },
    {
      name: "Request Amount",
      selector: (row) => row.requestAmount?.toLocaleString(),
      sortable: true,
      cell: (row) => <p className="">{row.requestAmount?.toLocaleString()}</p>,
    },
    {
      name: "Lock Rate",
      selector: (row) => row.lockRate || "N/A",
      sortable: true,
      cell: (row) => (
        <p className="">{row.lockRate ? `${row.lockRate}%` : "N/A"}</p>
      ),
    },
    {
      name: "Lock Rate Amount",
      selector: (row) => row.discountAmount?.toLocaleString(),
      sortable: true,
      cell: (row) => <p className="">{row.discountAmount?.toLocaleString()}</p>,
    },
    {
      name: "Fundable Amount",
      selector: (row) => row.fundableAmount,
      sortable: true,
      cell: (row) => <p className="">{row.fundableAmount?.toLocaleString()}</p>,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <p
          className={
            row.status === "SUCCESSFULL" ? "text-green-400" : "text-[#F08000]"
          }
        >
          {formatCapitaliseString(row.status || "")}
        </p>
      ),
    },
    {
      cell: (row) => (
        <div className="flex gap-2">
          {
            <PAIconOption
              title="Options"
              onClick={() => {
                setIsDropdownOpen2((prev) => ({
                  ...prev,
                  [row.invoiceId]: !prev[row.invoiceId],
                }));
                // Close other dropdowns
                Object.keys(isDropdownOpen2).forEach((key) => {
                  if (key !== row.invoiceId.toString()) {
                    setIsDropdownOpen2((prev) => ({ ...prev, [key]: false }));
                  }
                });
              }}
            />
          }
          {isDropdownOpen2[row.requestReference] && (
            <div
              ref={dropdownRef}
              className="absolute bg-white border border-gray300 rounded-md shadow-md z-10"
            >
              <ul>
                <li
                  className="px-4 py-2 cursor-pointer hover:bg-primaryColor hover:text-white"
                  onClick={() => {
                    receivablesDetails(row.requestReference);
                    setIsDropdownOpen2((prev) => ({
                      ...prev,
                      [row.requestReference]: false,
                    }));
                  }}
                >
                  Preview
                </li>
                <li
                  className="px-4 py-2 cursor-pointer hover:bg-primaryColor hover:text-white"
                  onClick={() => {
                    handleNotificationClick(row);
                    setIsDropdownOpen2((prev) => ({
                      ...prev,
                      [row.invoiceId]: false,
                    }));
                  }}
                >
                  Send Reminder
                </li>
                <li className="px-4 py-2 cursor-not-allowed text-gray300">
                  Export
                </li>
              </ul>
            </div>
          )}
        </div>
      ),
    },
  ];

  const supplierColumns = [
    {
      name: (
        <input type="checkbox" onChange={handleSelectAll} checked={selectAll} />
      ),
      cell: (row) => (
        <input
          type="checkbox"
          onChange={() => handleCheckboxChange(row)}
          checked={selectedRows?.includes(row?.invoiceId)}
        />
      ),
      width: "50px",
    },
    {
      name: (
        <span className="whitespace-normal">
          <img className="pr1 inline" src="/group-icon.svg" alt="" />
          Request ref Number
        </span>
      ),
      selector: (row) => row.requestReference || "N/A",
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-normal">
          <img className="pr1 inline" src="/group-icon.svg" alt="" />
          Buyer
        </span>
      ),
      selector: (row) => row.buyerCompanyName || "N/A",
      sortable: true,
    },
    {
      name: <span className="whitespace-normal">Payment Date</span>,
      selector: (row) => row.paymentDate,
      sortable: true,
      cell: (row) => {
        // Convert the array to a Date object
        const date = new Date(
          row.paymentDate[0],
          row.paymentDate[1] - 1,
          row.paymentDate[2]
        );

        // Format the date as 'YYYY-MM-DD'
        const formattedDate = date.toLocaleDateString("en-CA");

        return <p>{formattedDate}</p>;
      },
    },
    {
      name: "Currency",
      selector: (row) => row.currency || "N/A",
      sortable: true,
    },
    {
      name: "Request Amount",
      selector: (row) => row.requestAmount?.toLocaleString(),
      sortable: true,
      cell: (row) => <p className="">{row.requestAmount?.toLocaleString()}</p>,
    },
    {
      name: "Lock Rate",
      selector: (row) => row.lockRate || "N/A",
      sortable: true,
      cell: (row) => (
        <p className="">{row.lockRate ? `${row.lockRate}%` : "N/A"}</p>
      ),
    },
    {
      name: "Lock Rate Amount",
      selector: (row) => row.discountAmount?.toLocaleString(),
      sortable: true,
      cell: (row) => <p className="">{row.discountAmount?.toLocaleString()}</p>,
    },
    {
      name: "Fundable Amount",
      selector: (row) => row.fundableAmount,
      sortable: true,
      cell: (row) => <p className="">{row.fundableAmount?.toLocaleString()}</p>,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <p
          className={
            row.status === "SUCCESSFULL" ? "text-green-400" : "text-[#F08000]"
          }
        >
          {formatCapitaliseString(row.status || "")}
        </p>
      ),
    },
    {
      cell: (row) => (
        <div className="flex gap-2">
          {
            <PAIconOption
              title="Options"
              onClick={() => {
                setIsDropdownOpen2((prev) => ({
                  ...prev,
                  [row.invoiceId]: !prev[row.invoiceId],
                }));
                // Close other dropdowns
                Object.keys(isDropdownOpen2).forEach((key) => {
                  if (key !== row.invoiceId.toString()) {
                    setIsDropdownOpen2((prev) => ({ ...prev, [key]: false }));
                  }
                });
              }}
            />
          }
          {isDropdownOpen2[row.requestReference] && (
            <div
              ref={dropdownRef}
              className="absolute bg-white border border-gray300 rounded-md shadow-md z-10"
            >
              <ul>
                <li
                  className="px-4 py-2 cursor-pointer hover:bg-primaryColor hover:text-white"
                  onClick={() => {
                    receivablesDetails(row.requestReference);
                    setIsDropdownOpen2((prev) => ({
                      ...prev,
                      [row.requestReference]: false,
                    }));
                  }}
                >
                  Preview
                </li>
                <li
                  className="px-4 py-2 cursor-pointer hover:bg-primaryColor hover:text-white"
                  onClick={() => {
                    handleNotificationClick(row);
                    setIsDropdownOpen2((prev) => ({
                      ...prev,
                      [row.invoiceId]: false,
                    }));
                  }}
                >
                  Send Reminder
                </li>
                <li className="px-4 py-2 cursor-not-allowed text-gray300">
                  Export
                </li>
              </ul>
            </div>
          )}
        </div>
      ),
    },
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen2({});
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // The content section with the dropdown selection
  const renderTableSelectionDropdown = () => (
    <div className="flex px-[24px] gap-5 my-5">
      <div className="flex justify-center items-center px[24px] gap-5 my2">
        <p className="text-sm font-normal">Awaiting collections from</p>
        <select
          className="border border-gray300 rounded-md p-2 text-gray800 cursor-pointer"
          onChange={(e) => setTableType(e.target.value)}
          value={tableType}
        >
          <option value="buyer">Buyer</option>
          <option value="supplier">Supplier</option>
        </select>
      </div>
    </div>
  );

  return (
    <>
      <div className="my-2 grid md:grid-cols-2 items-center">
        <div className="gap-3 flex relative">
          <div className="flex gap-4 mb-4">
            <div className="relative">
              <DatePicker
                selected={startDate}
                onChange={(update) => setDateRange(update)}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                placeholderText="Filters"
                className="border border-[#ccc] px-4 py-3 cursor-pointer rounded-md w-60"
              />

              <div className="absolute top-2/4 right-3 transform -translate-y-2/4 pointer-events-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5 text-gray-400"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div>
            <button className="py-2 px-4 text-sm rounded-[5px] bg-white border-[#DEDEDE] shadow-sm mr-2 flex items-center justify-center gap-1.5">
              Export {<PAIconDownload className="accent-gray300 w-4" />}
            </button>
          </div>
        </div>

        <div className="flex md:col-span-1 justify-end">
          <div className="flex items-center justify-between gap-5">
            <div className="border border-transparent md:border-gray md:shadow-sm my-4 rounded-[5px] px-2 lg:w-[300px] bg-white">
              <div className="items-center flex bg-white">
                <PAIconOutlineSearch />
                <input
                  className="bg-transparent p-2 w-full focus:outline-none text-sm bg-white"
                  type="text"
                  placeholder="Search for request"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleSearch();
                    }
                  }}
                />
              </div>
              <div className="lg:hidden flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer">
                <PAIconFilter className="bg-[#2FA06A]" />
              </div>
            </div>
            <button
              className="hidden lg:flex bg-[#2FA06A] rounded-md my-4 py-2 px-6 mx3 items-center gap-2 text-white"
              onClick={handleSearch}
            >
              <PAIconFilter /> Search
            </button>
          </div>
        </div>
      </div>

      <div>
        {!allFunderOpenMarketData?.data?.length ? (
          <div>
            <Card className="rounded-[10px] mt-[15px]">
              <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px] py-12">
                <div>
                  <p className="text-black text-lg font-bold">
                    Invoices from Open Market awaiting collection
                  </p>
                  <span className="text-xs font-thin">
                    List of all invoices you have funded in open market
                  </span>
                </div>
                <div className="flex items-center justify-center gap-3">
                  <button
                    className="border border-gray300 rounded-md p-2 text-white cursor-not-allowed bg-gray300"
                    disabled={true}
                  >
                    <p>Send Reminder</p>
                  </button>
                </div>
              </div>
              <div className="pb-[24px]">
                {renderTableSelectionDropdown()}
                <TableEmptyState dashboard />
              </div>
            </Card>
          </div>
        ) : (
          <Card className="rounded-[10px] mt-[15px]">
            <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px] py-12">
              <div>
                <p className="text-black text-lg font-bold">
                  Invoices from Open Market awaiting collection
                </p>
                <span className="text-xs font-thin">
                  List of all invoices you have funded in open market
                </span>
              </div>
              <div className="flex items-center justify-center gap-3">
                <button
                  className={`border border-gray300 rounded-md p-2 text-white ${
                    selectedRows.length === 0
                      ? "cursor-not-allowed bg-gray300"
                      : "cursor-pointer bg-secondaryColor"
                  }`}
                  disabled={selectedRows?.length === 0}
                  onClick={handleSendReminder}
                >
                  <p>Send Reminder</p>
                </button>
              </div>
            </div>
            <div className="pb-[24px]">
              {renderTableSelectionDropdown()}
              <Table
                columns={tableType === "buyer" ? buyerColumns : supplierColumns}
                data={allFunderOpenMarketData?.data || []}
                onRowClicked={(row) =>
                  receivablesDetails(row?.requestReference)
                }
                progressPending={isLoading}
                progressComponent={<Loading />}
                pointer
                tableHeader
                className="mt-5"
              />
              <div className="flex justify-center gap-x-[8px] mt-[20px]">
                {allFunderOpenMarketData?.data?.length > 10 && (
                  <Pagination
                    page={page}
                    startPage={startPage}
                    setEndPage={setEndPage}
                    endPage={endPage}
                    setStartPage={setStartPage}
                    setPage={setPage}
                    totalPages={allFunderOpenMarketData?.meta?.totalElements}
                  />
                )}
              </div>
            </div>
          </Card>
        )}
      </div>
      <AcceptModal
        isOpen={isAcceptModalOpen}
        onClose={() => setIsAcceptModalOpen(false)}
        message={
          <>
            Would you like to proceed with sending a <br />
            <span className="text-[#07593D] font-bold">
              Payment reminder
            </span>{" "}
            {selectedRows.length > 1 ? (
              <span>to all selected due invoices?</span>
            ) : (
              <>
                to due{" "}
                <span className="text-secondaryColor font-bold">
                  invoice {selectedInvoice?.invoiceReference}
                </span>
                ?
              </>
            )}
          </>
        }
        onConfirm={handleConfirm}
      />

      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={handleCloseSuccessModal}
        message={successMessage}
      />
    </>
  );
};

export default OpenMarket;
