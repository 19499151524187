import React from "react";
import DashboardLayout from "layout/dashboardlayout";
import {
  PAIconArrowLeftGreen,
  PAIconVieweye,
  PAIconWorkTopFilerIcon,
  PAIconOutlineSearch,
} from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import Table from "layout/table/Table";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import Pagination from "shared/Pagination";

// import { useNavigate } from "react-router-dom";
import Card from "shared/Card";

const ApprovalHistoryAllDetails = () => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/work-flow-management/");
  };

  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const dummyData = [
    {
      bidAmount: 3,
      amount: "+9,000,000",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      discountRate: 3,
      funderId: "Admin",
      invoiceAmount: 32164082.78,
      status: "Approved",
      invoiceDate: "Invoice Approval",
      invoiceId: "65dfc0942b607753910b022e",
      transactionId: "2023-02-19",
      lockedBy: "Admin",
      lockedDate: "2024-03-07",
      paymentDate: "2023-04-19",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Taiwo Johnson",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",

      currency: "1 of 3",
    },

    {
      bidAmount: 4,
      amount: "-4,000,000",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      discountRate: 3,
      funderId: "Admin",
      invoiceAmount: 32164082.78,
      status: "Decline",
      invoiceDate: "Early Payment",
      invoiceId: "65dfc0942b607753910b022e",
      transactionId: "2023-02-19",
      lockedBy: "Admin",
      lockedDate: "2024-03-07",
      paymentDate: "2023-04-19",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "User",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",

      currency: "1 of 3",
    },

    {
      bidAmount: 5,
      amount: "+9,000,000",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      discountRate: 3,
      funderId: "Admin",
      invoiceAmount: 32164082.78,
      status: "Approved",
      invoiceDate: "Invoice Creation",
      invoiceId: "65dfc0942b607753910b022e",
      transactionId: "2023-02-19",
      lockedBy: "Admin",
      lockedDate: "2024-03-07",
      paymentDate: "2023-04-19",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Taiwo Johnson",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",

      currency: "1 of 3",
    },

    {
      bidAmount: 6,
      amount: "-4,000,000",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      discountRate: 3,
      funderId: "Admin",
      invoiceAmount: 32164082.78,
      status: "Decline",
      invoiceDate: "Early Payment",
      invoiceId: "65dfc0942b607753910b022e",
      transactionId: "2023-02-19",
      lockedBy: "Admin",
      lockedDate: "2024-03-07",
      paymentDate: "2023-04-19",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "User",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",

      currency: "1 of 3",
    },

    {
      bidAmount: 7,
      amount: "+9,000,000",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      discountRate: 3,
      funderId: "Admin",
      invoiceAmount: 32164082.78,
      status: "Decline",
      invoiceDate: "Early Payment",
      invoiceId: "65dfc0942b607753910b022e",
      transactionId: "2023-02-19",
      lockedBy: "Admin",
      lockedDate: "2024-03-07",
      paymentDate: "2023-04-19",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "User",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",

      currency: "1 of 3",
    },

    {
      bidAmount: 8,
      amount: "-4,000,000",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      discountRate: 3,
      funderId: "Admin",
      invoiceAmount: 32164082.78,
      status: "Completed",
      invoiceDate: "Invoice Creation",
      invoiceId: "65dfc0942b607753910b022e",
      transactionId: "2023-02-19",
      lockedBy: "Admin",
      lockedDate: "2024-03-07",
      paymentDate: "2023-04-19",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Taiwo Johnson",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",

      currency: "1 of 3",
    },
    {
      bidAmount: 9,
      amount: "-4,000,000",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      discountRate: 3,
      funderId: "Admin",
      invoiceAmount: 32164082.78,
      status: "Decline",
      invoiceDate: "Early Payment",
      invoiceId: "65dfc0942b607753910b022e",
      transactionId: "2023-02-19",
      lockedBy: "Admin",
      lockedDate: "2024-03-07",
      paymentDate: "2023-04-19",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "User",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",

      currency: "1 of 3",
    },
    {
      bidAmount: 10,
      amount: "-4,000,000",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      discountRate: 3,
      funderId: "Admin",
      invoiceAmount: 32164082.78,
      status: "Decline",
      invoiceDate: "Early Payment",
      invoiceId: "65dfc0942b607753910b022e",
      transactionId: "2023-02-19",
      lockedBy: "Admin",
      lockedDate: "2024-03-07",
      paymentDate: "2023-04-19",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "User",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",

      currency: "1 of 3",
    },
  ];

  const totalPages = dummyData.length;

  const handleRowClick = (row) => {
    if (row.status === "Completed") {
      navigate(
        `/work-flow-management/workflowcompletedpendingapprovaldetails/${row.bidAmount}`
      );
    } else if (row.status === "Decline") {
      navigate(
        `/work-flow-management/detail/pendingdeclineddetails/${row.bidAmount}`
      );
    } else {
      navigate(
        `/work-flow-management/workflowapprovedpendingapprovaldetails/${row.bidAmount}`
      );
    }
  };

  const columns = [
    {
      name: "Date",
      selector: (row) => row.transactionId,
    },
    {
      name: "Sender",
      selector: (row) => row.funder,
    },

    {
      name: "Transaction Type",
      selector: (row) => row.invoiceDate,
    },
    {
      name: "Approval Date",
      selector: (row) => row.paymentDate,
    },

    {
      name: "Approval Level",
      selector: (row) => row.currency,
    },

    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) =>
        row.status === "Approved" ? (
          <p className="text-[#2EA923]">{row.status}</p>
        ) : row.status === "Decline" ? (
          <p className="text-[#DE1515]">{row.status}</p>
        ) : row.status === "Completed" ? (
          <p className="text-[#2EA923]">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        ),
    },
    {
      name: "",
      selector: (row) => row.view,
      cell: (row) => (
        <div className="flex">
          <PAIconVieweye height="15px" width="30px" />
          <p className="text-[#626679] cursor-pointer">View</p>
        </div>
      ),
    },
  ];
  return (
    <DashboardLayout
      section="Workflow "
      subHeader="Manage all your workflow approvals"
    >
      <div>
        {" "}
        <header className="bg-white text-primaryColor px-6  rounded-[5px]">
          <span
            onClick={() => handleBack()}
            className="flex cursor-pointer py-4 w-fit font-light items-center text-sm gap-2"
          >
            <PAIconArrowLeftGreen />
            Go Back
          </span>
        </header>
        <>
          <div className="my-5 grid md:grid-cols-2 items-center">
            <div className="gap-3 flex relative">
              <div>
                <select
                  className="border border-[#DEDEDE] shadow-sm rounded-[5px] px-4 py-3 text-sm focus:outline-none cursor-pointer"
                  onChange={(e) => {}}
                >
                  <option value="" disabled selected>
                    Sort by{" "}
                  </option>
                  <option value="date">Date</option>
                  <option value="name">Approval date</option>
                </select>
              </div>
              <button className="py-2 px-4 rounded-[5px] bg-white border-[#DEDEDE] shadow-sm mr-2 ">
                Export
              </button>
            </div>
            <div className="flex md:col-span-1 justify-end sm:justify-start xs:justify-start sm:w-full sm:block xs:w-full ">
              {/* SAERCH SECTION */}

              <div>
                <div className="flex items-center lg:justify-end gap-5">
                  <div className="border border-transparent md:border-gray md:shadow-sm my-4 rounded-[5px] px-2 lg:w-[300px] bg-white sm:w-full block">
                    <div className="items-center flex bg-white">
                      <PAIconOutlineSearch />
                      <input
                        className="bg-transparent p-2 w-full focus:outline-none text-sm bg-white"
                        type="text"
                        placeholder="Search by sender name,  transaction type or status"
                      />
                    </div>
                    {/* <div className="lg:hidden flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer">
                    <PAIconFilter className="bg-[#2FA06A]" />
                  </div> */}
                  </div>
                </div>
                {/* stop */}
              </div>
            </div>
          </div>

          {/* Neext down section */}

          <AnimatePresence>
            <Card className="rounded-[10px] mt-[24px]  lg:h-[784px]  bg-black">
              <div className=" justify-between items-center px-[24px] bg-alabasterHeader  rounded-t-[10px] lg:flex md:flex  w-full sm:block  min-h-[60px] ">
                <div className=" sm:w-full sm:block sm:py-2  xs:w-full xm:block xm:py-2">
                  <p className="text-[#1d0220] text-sm font-bold">
                    Approval History
                  </p>
                  <p className="text-[#5f4d61] text-[10px] font-normal">
                    Workflow approval history
                  </p>
                </div>
                <div>
                  <div className=" flex  gap-2 items-center lg:justify-center sm:justify-end sm:mt-2 xs:mt-2">
                    {" "}
                    <PAIconWorkTopFilerIcon />
                    <span className="text-[#7a7a7a] text-sm font-normal leading-normal">
                      Filter:
                    </span>
                    <div>
                      <select
                        className="border  shadow-sm rounded-[5px] px-4 py-2.5   focus:outline-none cursor-pointer lg:w-[143px] md:w-[143px] h-9  bg-white  border-[#cccccc] justify-start items-center  text-[#7a7a7a] text-xs font-normal"
                        onChange={(e) => {
                          // const selectedSortBy = e.target.value;
                        }}
                      >
                        <option
                          value=""
                          disabled
                          selected
                          className="overflow-x-hidden"
                        >
                          {" "}
                          filter by...
                        </option>
                        <option
                          value="Transaction Type"
                          className="overflow-x-hidden"
                        >
                          Transaction Type
                        </option>
                        <option value="Sender" className="overflow-x-hidden">
                          Sender
                        </option>
                        <option value="Sender" className="overflow-x-hidden">
                          Status
                        </option>
                        <option value="Sender" className="overflow-x-hidden">
                          Approval Level
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-[24px] ">
                <Table
                  columns={columns}
                  data={dummyData}
                  pointer
                  tableHeader
                  className="pt-5"
                  onRowClicked={(row) => handleRowClick(row)}
                />
              </div>

              <div>
                {totalPages > 2 && (
                  <div className="flex justify-center gap-x-[8px]  mt-8">
                    <Pagination
                      page={page}
                      startPage={startPage}
                      setEndPage={setEndPage}
                      endPage={endPage}
                      setStartPage={setStartPage}
                      setPage={setPage}
                      totalPages={totalPages}
                    />
                  </div>
                )}
              </div>
            </Card>
          </AnimatePresence>
        </>
      </div>
    </DashboardLayout>
  );
};

export default ApprovalHistoryAllDetails;
