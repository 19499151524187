// SearchBar.js
import { PAIconOutlineSearch } from 'assets/images/svgs';
import React from 'react';

const SearchBar = ({ value, onChange, onSearch, placeholder }) => {
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      onSearch();
    }
  };

  return (
    <div className="border border-transparent md:border-gray md:shadow-sm my-4 rounded-[5px] px-2 lg:w-[300px] bg-white">
      <div className="items-center flex bg-white">
        <PAIconOutlineSearch />
        <input
          className="bg-transparent p-2 w-full focus:outline-none text-sm bg-white"
          type="text"
          value={value}
          onChange={onChange}
          onKeyPress={handleKeyPress}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

export default SearchBar;