import React from "react";
import { PASuccessIcon, PAIconCheckMarkGreen } from "assets/images/svgs";
const RejectionSuccess = ({ onClose, isOpen, confirm }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="bg-[rgba(0,0,0,0.2)] w-[100%] h-[100vh] flex justify-center items-center absolute left-0 top-0">
      <div className="bg-white p10 px5 rounded-md max-w-xs md:max-w-lg w-full px10 flex h-[200px]">
        <div className="flex items-center justify-center bg-primaryColor w-[35%] rounded-[6px]">
          <PASuccessIcon />
        </div>
        <div className="flex items-center justify-center flex-col">
          <PAIconCheckMarkGreen />
          <p className="px-[5%] text-center font-regular text-[18px] mt-3">
            Early payment request sent for workflow approval
          </p>
        </div>
      </div>
    </div>
  );
};

export default RejectionSuccess;
