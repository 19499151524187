import request from "apiInstance";
import toast from "react-hot-toast";

export const getAllRules = async ({ page, pageSize, ruleType }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/rule/all?page=${page}&size=${pageSize}&ruleType=${ruleType}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getRuleById = async ({ id, ruleType }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/rule/id/${id}?ruleType=${ruleType}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const editRuleById = async ({ id, body, ruleType }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/rule/${id}/update?ruleType=${ruleType}`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const updateRuleStatus = async ({ id, status, ruleType }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/rule/${id}/update-status?status=${status}&ruleType=${ruleType}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const adjustRulePriority = async ({ body, ruleType }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/rule/adjust-priorities?ruleType=${ruleType}`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const deleteRuleById = async ({ id, ruleType }) => {
  try {
    const response = await request({
      method: "delete",
      url: `/api/v1/rule/${id}/delete?ruleType=${ruleType}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const searchRules = async ({ searchBy, name = "", ruleType }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/rule/search?${searchBy}=${name}&ruleType=${ruleType}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const createNewRule = async ({ body, ruleType }) => {
  try {
    const response = await request({
      method: "post",
      url: `/api/v1/rule/create?ruleType=${ruleType}`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getEarlyPaymentRules = async ({
  page = 0,
  pageSize = 10,
  ruleType,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/rule/all?page=&size=10&ruleType=EARLY_PAYMENT`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const updatePercentageLimitAndDiscountApi = async ({
  programType = "EARLY_PAYMENT_REQUEST",
  discountRate,
  percentageLimit = 0,
  ruleType,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/rule/default-discount-rate?ruleType=${ruleType}&discountRate=${discountRate}&programType=${programType}&percentageLimit=${percentageLimit}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const updateDirectFundingRateApi = async ({
  programType = "DIRECT_FUNDING_REQUEST",
  discountRate,
  percentageLimit,
  recourseType,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/rule/direct-funding-config?programType=${programType}&discountRate=${discountRate}&percentageLimit=${percentageLimit}&withRecourse=${recourseType}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getFunderDirectFundingRateApi = async (
  programType = "DIRECT_FUNDING_REQUEST"
) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/rule/default-config?programType=${programType}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const updatePercentageLimitAndDiscountApiTest = async () => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/rule/default-discount-rate?ruleType=EARLY_PAYMENT&discountRate=2&programType=EARLY_PAYMENT_REQUEST&percentageLimit=70`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const fetchFunderDiscountRate = async () => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/rule/get-single-discount-config?programType=EARLY_PAYMENT_REQUEST`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
