import Table from "layout/table/Table";
import React, { useEffect, useState } from "react";
import Button from "shared/Button";
import BuyerDetailsModal from "../modal";
import DashboardLayout from "layout/dashboardlayout";
import { PAIconInventoryArrowLeft, PAIconSearch } from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AccountModalContainer from "shared/accountModal/Modal";
import { buyerRequestToAssociateWithSupplier } from "appstate/buyer/buyerSlice";
import toast from "react-hot-toast";
import Loading from "shared/Loading";
import DeclineModal from "shared/delineModal/Modal";
import { getAllSuppliers } from "appstate/api/auth/onboarding";
import UserAdd from "../../../../../assets/images/svgs/useradd.svg";
import Pagination from "shared/Pagination";
import { searchSupplier } from "appstate/api/buyer/relationshipManagement";
import RequestToAssociate from "shared/RequestToAssociate";

const AssociationRequest = () => {
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const {
    pendingAssociateRequestData,
    isLoading,
    declineLoading,
    // associateLoading,
  } = useSelector((state) => state.buyer);
  const [getId, setGetId] = useState(null);
  const [openDeclineModal, setOpenDeclineModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [loading, setLoading] = useState(false);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(companyName);
  // eslint-disable-next-line
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const pageSize = 10;
  const [suppliersData, setSuppliersData] = useState([]);
  const [total, setTotal] = useState("");
  const [associateLoading, setAssociateLoading] = useState(false);
  const [successModal, setSucceessModal] = useState(false);
  // eslint-disable-next-line
  // const userId = useSelector((state) => state);

  const dispatch = useDispatch();
  const supplierDetails = pendingAssociateRequestData?.data?.find(
    (cur) => cur?.userId === getId
  );

  const fetchAllSuppliers = async () => {
    const role = "suppliers"
    try {
      setLoading(true);
      const result = await getAllSuppliers(page, pageSize, role);
      setTotal(result?.meta?.totalElements);
      setSuppliersData(result?.data?.suppliers || []);
    } catch (error) {
      console.error("error fetching suppliers");
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async () => {
    if (debouncedSearchTerm.length > 0) {
      try {
        setLoading(true);
        const result = await searchSupplier(debouncedSearchTerm, "", "");
        setSuppliersData(result?.data || []);
        setTotal(result?.meta?.totalElements);
      } catch (error) {
        toast.error("Failed to fetch suppliers.");
      } finally {
        setLoading(false);
      }
    } else {
      await fetchAllSuppliers();
    }
  };
  
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(companyName);
    }, 200);
    return () => {
      clearTimeout(handler);
    };
    // eslint-disable-next-line
  }, [companyName]);

  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line
  }, [companyName]);

  useEffect(() => {
    fetchAllSuppliers();
    // eslint-disable-next-line
  }, [page, pageSize]);

  const handleOpenModal = (row) => {
    setGetId(row?.userId);
    setOpen(true);
  };

  const handleAssociate = () => {
    setAssociateLoading(true);
    const supplierId = getId;
    dispatch(buyerRequestToAssociateWithSupplier(supplierId))
      .then((data) => {
        if (data?.payload?.success === true) {
          setSuccess(false);
          setOpen(false);
          setSucceessModal(true);
        } else {
          toast.error(data?.payload?.message);
          setOpen(false);
        }
      })
      .finally(() => {
        setAssociateLoading(false);
      });
  };

  const totalPages = total;

  const column = [
    {
      name: "NAME",
      selector: (row) => row?.fullName,
    },
    {
      name: "COMPANY",
      selector: (row) => row?.companyName,
    },
    {
      name: "EMAIL",
      selector: (row) => row?.email,
    },
    {
      name: "PHONE",
      selector: (row) => row?.phoneNumber,
    },
    {
      cell: (row) => (
        <div className=" ">
          <Button
            neutral
            onClick={() => handleOpenModal(row)}
            className="flex !text-white text-xs font-medium border-[1px] md:px-[20px] bg-primaryColor py-[12px] px-[16px] rounded-[5px] gap-x-[4px] min-w-[120px]"
          >
            <img src={UserAdd} alt="" />
            <p className="whitespace-nowrap">Associate</p>
          </Button>
        </div>
      ),
    },
  ];

  const navigate = useNavigate();
  function handleNavigate() {
    navigate(-1);
  }
  return (
    <DashboardLayout section="Suppliers">
      <div>
        <header className="bg-alabasterHeader p-[24px] rounded-t-[10px]">
          <div
            onClick={handleNavigate}
            className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
          >
            <PAIconInventoryArrowLeft />
            <p className="mt-[2px] text-sm font-normal text-lightGray">
              Go Back
            </p>
          </div>
          <div className="flex justify-between flex-wrap ">
            <div>
              <p className="text-black text-sm font-medium">
                Associate with a new Supplier
              </p>
              <p className="text-textColor text-xs font-normal">
                List of suppliers available on PayEdge
              </p>
            </div>
            <div className="flex gap-x-[10px] mt-[10px] md:mt-0 flex-wrap">
              <div className="flex items-center  bg-white p-[10px] gap-x-[8px] rounded-[5px] border-[0.5px] border-silver xl:w-[350px]">
                <PAIconSearch />
                <input
                  type="text"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  placeholder="Search request"
                  className="placeholder:text-xs outline-none"
                />
              </div>
              <Button
                neutral
                onClick={handleSearch}
                className="py-[12px] mt-[10px] md:mt-0 px-[24px] border-[0.5px] border-silver bg-brightGray text-sm !text-black rounded-[5px] font-medium"
              >
                Search
              </Button>
            </div>
          </div>
        </header>
        <BuyerDetailsModal
          isShown={openDetailsModal}
          onClose={() => setOpenDetailsModal(false)}
          data={supplierDetails}
        />

        {successModal && (
          <RequestToAssociate hide={() => setSucceessModal(false)} info="This supplier would receive a mail that you are requesting to associate" />
        )}

        <DeclineModal
          isShown={openDeclineModal}
          onClose={() => setOpenDeclineModal(false)}
          success={success}
          isLoading={declineLoading}
          warning="Are you sure you would like to Decline this request?"
        />

        <AccountModalContainer
          isShown={open}
          onClose={() => setOpen(false)}
          success={success}
          handleAssociate={handleAssociate}
          isLoading={associateLoading}
          warning="Are you sure you would like to associate with the supplier?"
        />
        <div className="p-[24px] bg-white rounded-b-[10px]">
          {loading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Loading />
            </div>
          ) : (
            <Table
              columns={column}
              data={suppliersData}
              pointer
              // onRowClicked={onRowClicked}
              progressPending={isLoading}
              progressComponent={<Loading />}
              tableHeader
            />
          )}
          <br />
          <div className="flex justify-center gap-x-[8px]  mt-12">
            {!isLoading && totalPages > 10 && (
              <Pagination
                page={page}
                startPage={startPage}
                setEndPage={setEndPage}
                endPage={endPage}
                setStartPage={setStartPage}
                setPage={setPage}
                totalPages={totalPages}
              />
            )}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default AssociationRequest;