import { allMembers, allRoles } from "appstate/roleManager/roleManagerSlice";
import {
  getUsersByRole,
  getworkflowByModule,
  updateWorkflowStatus,
} from "appstate/workflow/workflowSlice";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import WorkflowActionEdit from "../../../common/WorkflowActionEdit";

function EditWorkflowEarlyPayment() {
  const { player, actionType } = useParams();
  const dispatch = useDispatch();

  const { getWorkflowByModuleData } = useSelector((state) => state?.workflow);

  // User By Rolename
  const [
    selectedRoleNameApprovalViaWallet,
    setSelectedRoleNameApprovalViaWallet,
  ] = useState("");
  const [selectedRoleNameViaClosedMarket, setSelectedRoleNameViaClosedMarket] =
    useState("");

  const [
    selectedRoleNameViaDirectFunding,
    setSelectedRoleNameViaDirectFunding,
  ] = useState("");

  // Mapping Arrays
  const [approvalsViaWallet, setApprovalsViaWallet] = useState([]);
  const [approvalsViaClosedMarket, setApprovalsViaClosedMarket] = useState([]);
  const [approvalsViaDirectFunding, setApprovalsViaDirectFunding] = useState(
    []
  );

  // Toggle Status
  const [approvalViaWalletStatus, setApprovalViaWalletStatus] = useState(false);
  const [approvalViaClosedMarketStatus, setApprovalViaClosedMarketStatus] =
    useState(false);
  const [approvalViaDirectFundingStatus, setApprovalViaDirectFundingStatus] =
    useState(false);

  // Dropdown Refs
  const dropdownRefsApprovalViaWallet = useRef([]);
  const dropdownRefsApprovalViaClosedMarket = useRef([]);
  const dropdownRefsApprovalViaDirectFunding = useRef([]);

  useEffect(() => {
    dispatch(getworkflowByModule(actionType.toUpperCase())).then((data) => {
      const processes = data?.payload?.data?.processes || [];

      // 1.
      setApprovalViaWalletStatus(processes[0]?.status || false);
      setApprovalsViaWallet(processes[0]?.approvalLevels || []);

      // 2.
      setApprovalViaClosedMarketStatus(processes[1]?.status || false);
      setApprovalsViaClosedMarket(processes[1]?.approvalLevels || []);

      // 3.
      setApprovalViaDirectFundingStatus(processes[2]?.status || false);
      setApprovalsViaDirectFunding(processes[2]?.approvalLevels || []);
    });

    dispatch(allRoles());
    dispatch(allMembers(player));
  }, [dispatch, actionType, player]);

  useEffect(() => {
    if (selectedRoleNameApprovalViaWallet) {
      dispatch(getUsersByRole(selectedRoleNameApprovalViaWallet));
    }
    if (selectedRoleNameViaClosedMarket) {
      dispatch(getUsersByRole(selectedRoleNameViaClosedMarket));
    }
    if (selectedRoleNameViaDirectFunding) {
      dispatch(getUsersByRole(selectedRoleNameViaDirectFunding));
    }
  }, [
    dispatch,
    selectedRoleNameApprovalViaWallet,
    selectedRoleNameViaClosedMarket,
    selectedRoleNameViaDirectFunding,
  ]);

  // Handle Dropdown ClickOutside, I have to create for each one
  useEffect(() => {
    const handleClickOutside = (event) => {
      dropdownRefsApprovalViaWallet.current.forEach((ref, index) => {
        if (ref && !ref.contains(event.target)) {
          setApprovalsViaWallet((prev) =>
            prev.map((approval, i) =>
              i === index ? { ...approval, dropdownOpen: false } : approval
            )
          );
        }
      });
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      dropdownRefsApprovalViaClosedMarket.current.forEach((ref, index) => {
        if (ref && !ref.contains(event.target)) {
          setApprovalsViaClosedMarket((prev) =>
            prev.map((approval, i) =>
              i === index ? { ...approval, dropdownOpen: false } : approval
            )
          );
        }
      });
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      dropdownRefsApprovalViaDirectFunding.current.forEach((ref, index) => {
        if (ref && !ref.contains(event.target)) {
          setApprovalsViaDirectFunding((prev) =>
            prev.map((approval, i) =>
              i === index ? { ...approval, dropdownOpen: false } : approval
            )
          );
        }
      });
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Process Actions for Approval Via ClosedMarket

  // 1.
  const addApprovalViaClosedMarket = () => {
    let lastRow = approvalsViaClosedMarket[approvalsViaClosedMarket.length - 1];
    if (
      approvalsViaClosedMarket?.length > 0 &&
      (!lastRow?.userId || !lastRow?.email || !lastRow?.role)
    ) {
      return toast.error("Complete current approval level to add a new one!");
    }
    setApprovalsViaClosedMarket((prev) => [
      ...prev,
      {
        id: Date.now(),
        userId: "",
        teamMember: "",
        role: "",
        email: "",
        finalApproval: false,
        userSearch: "",
        dropdownOpen: false,
      },
    ]);
  };

  const removeApprovalViaClosedMarket = (index) => {
    setApprovalsViaClosedMarket((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSelectUserApprovalViaClosedMarket = (userEmail, index) => {
    setApprovalsViaClosedMarket((prev) =>
      prev.map((approval, i) =>
        i === index
          ? {
              ...approval,
              teamMember: userEmail,
              userSearch: userEmail,
              email: userEmail,
              userId: userEmail,
              dropdownOpen: false,
            }
          : approval
      )
    );
  };

  const handleFinalApprovalChangeApprovalViaClosedMarket = (index, value) => {
    setApprovalsViaClosedMarket((prev) =>
      prev.map((approval, i) =>
        i === index ? { ...approval, finalApproval: value } : approval
      )
    );
  };

  const handleApprovalUpdateViaClosedMarket = () => {
    const approvalLevels = approvalsViaClosedMarket.map(
      ({ email, role, finalApproval }) => ({
        email,
        role,
        finalApproval,
      })
    );

    const body = { status: approvalViaClosedMarketStatus, approvalLevels };
    dispatch(
      updateWorkflowStatus({
        id: getWorkflowByModuleData?.data?.processes[1]?.id,
        body,
      })
    ).then((data) => {
      if (data?.payload?.success) {
        toast.success("Invoice approval updated successfully.");
      }
    });
  };

  // Process Actions for Approval Via Direct Funding

  // 2.
  const addApprovalViaDirectFunding = () => {
    let lastRow =
      approvalsViaDirectFunding[approvalsViaDirectFunding.length - 1];
    if (
      approvalsViaDirectFunding?.length > 0 &&
      (!lastRow?.userId || !lastRow?.email || !lastRow?.role)
    ) {
      return toast.error("Complete current approval level to add a new one!");
    }
    setApprovalsViaDirectFunding((prev) => [
      ...prev,
      {
        id: Date.now(),
        userId: "",
        teamMember: "",
        role: "",
        email: "",
        finalApproval: false,
        userSearch: "",
        dropdownOpen: false,
      },
    ]);
  };

  const removeApprovalViaDirectFunding = (index) => {
    setApprovalsViaDirectFunding((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSelectUserApprovalViaDirectFunding = (userEmail, index) => {
    setApprovalsViaDirectFunding((prev) =>
      prev.map((approval, i) =>
        i === index
          ? {
              ...approval,
              teamMember: userEmail,
              userSearch: userEmail,
              email: userEmail,
              userId: userEmail,
              dropdownOpen: false,
            }
          : approval
      )
    );
  };

  const handleFinalApprovalChangeApprovalViaDirectFunding = (index, value) => {
    setApprovalsViaDirectFunding((prev) =>
      prev.map((approval, i) =>
        i === index ? { ...approval, finalApproval: value } : approval
      )
    );
  };

  const handleApprovalUpdateViaDirectFunding = () => {
    const approvalLevels = approvalsViaDirectFunding.map(
      ({ email, role, finalApproval }) => ({
        email,
        role,
        finalApproval,
      })
    );

    const body = { status: approvalViaDirectFundingStatus, approvalLevels };
    dispatch(
      updateWorkflowStatus({
        id: getWorkflowByModuleData?.data?.processes[2]?.id,
        body,
      })
    ).then((data) => {
      if (data?.payload?.success) {
        toast.success("Invoice approval updated successfully.");
      }
    });
  };

  // Process Actions for Approval Via Wallet

  // 3.
  const addApprovalViaWallet = () => {
    let lastRow = approvalsViaWallet[approvalsViaWallet.length - 1];
    if (
      approvalsViaWallet?.length > 0 &&
      (!lastRow?.userId || !lastRow?.email || !lastRow?.role)
    ) {
      return toast.error("Complete current approval level to add a new one!");
    }
    setApprovalsViaWallet((prev) => [
      ...prev,
      {
        id: Date.now(),
        userId: "",
        teamMember: "",
        role: "",
        email: "",
        finalApproval: false,
        userSearch: "",
        dropdownOpen: false,
      },
    ]);
  };

  const removeApprovalViaWallet = (index) => {
    setApprovalsViaWallet((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSelectUserApprovalViaWallet = (userEmail, index) => {
    setApprovalsViaWallet((prev) =>
      prev.map((approval, i) =>
        i === index
          ? {
              ...approval,
              teamMember: userEmail,
              userSearch: userEmail,
              email: userEmail,
              userId: userEmail,
              dropdownOpen: false,
            }
          : approval
      )
    );
  };

  const handleFinalApprovalChangeApprovalViaWallet = (index, value) => {
    setApprovalsViaWallet((prev) =>
      prev.map((approval, i) =>
        i === index ? { ...approval, finalApproval: value } : approval
      )
    );
  };

  const handleApprovalUpdateViaWallet = () => {
    const approvalLevels = approvalsViaWallet.map(
      ({ email, role, finalApproval }) => ({
        email,
        role,
        finalApproval,
      })
    );

    const body = { status: approvalViaWalletStatus, approvalLevels };
    dispatch(
      updateWorkflowStatus({
        id: getWorkflowByModuleData?.data?.processes[0]?.id,
        body,
      })
    ).then((data) => {
      if (data?.payload?.success) {
        toast.success("Invoice approval updated successfully.");
      }
    });
  };

  const actionEntities = [
    {
      isToggledStatus: approvalViaWalletStatus,
      setIsToggleStatus: setApprovalViaWalletStatus,
      mappingArr: approvalsViaWallet,
      setMappingArr: setApprovalsViaWallet,
      setSelectedRoleName: setSelectedRoleNameApprovalViaWallet,
      dropdownRefs: dropdownRefsApprovalViaWallet,
      handleFinalApprovalChange: handleFinalApprovalChangeApprovalViaWallet,
      addApproval: addApprovalViaWallet,
      removeApproval: removeApprovalViaWallet,
      handleApprovalSubmit: handleApprovalUpdateViaWallet,
      handleSelectUser: handleSelectUserApprovalViaWallet,
    },

    {
      isToggledStatus: approvalViaClosedMarketStatus,
      setIsToggleStatus: setApprovalViaClosedMarketStatus,
      mappingArr: approvalsViaClosedMarket,
      setMappingArr: setApprovalsViaClosedMarket,
      setSelectedRoleName: setSelectedRoleNameViaClosedMarket,
      dropdownRefs: dropdownRefsApprovalViaClosedMarket,
      handleFinalApprovalChange:
        handleFinalApprovalChangeApprovalViaClosedMarket,
      addApproval: addApprovalViaClosedMarket,
      removeApproval: removeApprovalViaClosedMarket,
      handleApprovalSubmit: handleApprovalUpdateViaClosedMarket,
      handleSelectUser: handleSelectUserApprovalViaClosedMarket,
    },

    {
      isToggledStatus: approvalViaDirectFundingStatus,
      setIsToggleStatus: setApprovalViaDirectFundingStatus,
      mappingArr: approvalsViaDirectFunding,
      setMappingArr: setApprovalsViaDirectFunding,
      setSelectedRoleName: setSelectedRoleNameViaDirectFunding,
      dropdownRefs: dropdownRefsApprovalViaDirectFunding,
      handleFinalApprovalChange:
        handleFinalApprovalChangeApprovalViaDirectFunding,
      addApproval: addApprovalViaDirectFunding,
      removeApproval: removeApprovalViaDirectFunding,
      handleApprovalSubmit: handleApprovalUpdateViaDirectFunding,
      handleSelectUser: handleSelectUserApprovalViaDirectFunding,
    },
  ];

  return (
    <main className="w-full flex flex-col ">
      {getWorkflowByModuleData?.data?.processes?.map((process, index) => (
        <WorkflowActionEdit
          title={process?.action}
          isToggledStatus={actionEntities[index]?.isToggledStatus}
          setIsToggleStatus={actionEntities[index]?.setIsToggleStatus}
          mappingArr={actionEntities[index]?.mappingArr}
          setMappingArr={actionEntities[index]?.setMappingArr}
          setSelectedRoleName={actionEntities[index]?.setSelectedRoleName}
          dropdownRefs={actionEntities[index]?.dropdownRefs}
          handleFinalApprovalChange={
            actionEntities[index]?.handleFinalApprovalChange
          }
          addApproval={actionEntities[index]?.addApproval}
          removeApproval={actionEntities[index]?.removeApproval}
          handleApprovalSubmit={actionEntities[index]?.handleApprovalSubmit}
          handleSelectUser={actionEntities[index]?.handleSelectUser}
          player={player}
        />
      ))}
    </main>
  );
}
export default EditWorkflowEarlyPayment;
