import React, { useEffect, useState } from "react";
import RecentPaymentTable from "./Table";
import { supplierOverview } from "appstate/collections/collectionSlice";
import { useDispatch } from "react-redux";

const Overview = () => {
  // const formatAmount = (amount) => {
  //   if (typeof amount !== "number") return "0.00";
  //   return amount?.toLocaleString(undefined, {
  //     minimumFractionDigits: 2,
  //     maximumFractionDigits: 2,
  //   });
  // };

  // const { supplierOverviewData } = useSelector((state) => state?.collection);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(supplierOverview());
  }, [dispatch]);

  const dummyData = [
    {
      title: "Direct Funding",
      amount: "N8,210,730.00",
      description: "Amount awaiting collection",
      badgeNumber: 24,
      badgeColor: "#F0800024",
      textColor: "#11192A",
    },
    {
      title: "Open Market",
      amount: "N18,210,730.00",
      description: "Amount awaiting collection",
      badgeNumber: 10,
      badgeColor: "#F0800024",
      textColor: "#3E0445",
    },
    {
      title: "Early Payment Requests",
      amount: "N12,210,730.00",
      description: "Amount awaiting collection",
      badgeNumber: 33,
      badgeColor: "#F0800024",
      textColor: "#F08000",
    },
    {
      title: "Early Payment Offers",
      amount: "N4,210,730.00",
      description: "Amount awaiting collection",
      badgeNumber: 12,
      badgeColor: "#F0800024",
      textColor: "#318F29",
    },
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [recievablesOpen, setRecievablesOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("all");
  const [selectedRecievables, setSelectedReceivables] = useState("all");

  const options = [
    { value: "all", label: "All" },
    { value: "thisWeek", label: "This Week" },
    { value: "thisMonth", label: "This Month" },
    { value: "thisQuarter", label: "This Quarter" },
    { value: "thisHalfYear", label: "This Half Year" },
    { value: "thisYear", label: "This Year" },
  ];

  const handleSelect = (option) => {
    setSelectedOption(option.value);
    setIsOpen(false);
  };

  const handleReceivableSelect = (option) => {
    setSelectedReceivables(option.value);
    setRecievablesOpen(false);
  };

  return (
    <div className="w-full">
      <div className="grid lg:grid-cols-[65%,35%] grid-cols-1 gap5 mb-5">
        {/* Funds Section */}
        <section className="bg-[#FAFAFA] shadow-md rounded-xl">
          <header className="px-5 lg:px-7 py-4 bg-white shadow-lg flex items-center justify-between rounded-t-xl">
            <div className="text-secondaryColor font-semibold">FUNDS</div>
            <div className="relative">
              {/* Dropdown Button */}
              <button
                onClick={() => setIsOpen(!isOpen)}
                className="bg-secondaryColor text-white rounded flex items-center justify-between px-4 py-2 w-40 focus:outline-none"
              >
                {options.find((opt) => opt.value === selectedOption)?.label}
                <svg
                  className={`w-4 h-4 ml-2 transition-transform ${
                    isOpen ? "rotate-180" : "rotate-0"
                  }`}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.707a1 1 0 011.414 0L10 11.293l3.293-3.586a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>

              {/* Dropdown Menu */}
              {isOpen && (
                <ul className="absolute mt-2 w-40 bg-white border border-gray300 rounded-2xl shadow-md z-10">
                  {options.map((option) => (
                    <li
                      key={option.value}
                      onClick={() => handleSelect(option)}
                      className={`px-4 py-2 flex items-center cursor-pointer hover:bg-primaryColor hover:text-white ${
                        selectedOption === option.value ? "bgprimaryColor" : ""
                      }`}
                    >
                      {/* Radio Indicator */}
                      <span
                        className={`w-4 h-4 mr-3 rounded-full border-2 ${
                          selectedOption === option.value
                            ? "border-secondaryColor bg-secondaryColor"
                            : "border-gray300"
                        }`}
                      ></span>
                      {option.label}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </header>

          <div className="px-5 lg:px-7 py-4 grid grid-cols-1 md:grid-cols-2 gap-3">
            {/* Total Requests Card */}
            <div className="bg-secondaryColor w-full py-7 px-3.5 rounded-2xl text-center text-white">
              <h1 className="text-white text-[48px] font-extrabold leading-normal">
                105
              </h1>
              <p className="text-lg font-light">
                Total requests awaiting <br /> collections
              </p>
            </div>

            {/* Total Funds Card */}
            <div className="bg-[#FFF2DE] w-full py-7 px-3.5 rounded-2xl text-center">
              <h1 className="text-[32px] font-extrabold leading-normal">
                ₦124,537,360
              </h1>
              <p className="text-lg">
                Total Funds Awaiting <br /> Collections
              </p>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 px-5 lg:px-7 py-4 gap-3 mb-3">
            {dummyData.map((item, index) => (
              <div key={index} className="bg-white shadow-md rounded-[10px]">
                <div className="flex justify-between p-3">
                  <div>
                    <p className="text-[10px] font-light leading-normal">
                      {item.title}
                    </p>
                    <p
                      className="text-xl font-light leading-normal my-2"
                      style={{ color: item.textColor }}
                    >
                      {item.amount}
                    </p>
                    <p className="text-[#565656] text-[12px] font-normal leading-normal">
                      {item.description}
                    </p>
                  </div>
                  <div
                    className="w-[34px] h-[32px] shrink-0 rounded-full flex items-center justify-center text-secondaryColor"
                    style={{ backgroundColor: item.badgeColor }}
                  >
                    {item.badgeNumber}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* Receivables Section */}
        <section className="bg-[#FAFAFA] shadow-md rounded-xl lg:ml-5">
          <header className="px-5 lg:px-7 py-4 bg-white shadow-lg flex items-center justify-between rounded-t-xl">
            <div className="text-primaryColor text-xl font-semibold">RECEIVABLES</div>
            <div className="relative">
              <button
                onClick={() => setRecievablesOpen(!recievablesOpen)}
                className="bg-primaryColor text-white rounded flex items-center justify-between px-4 py-2 w-40 focus:outline-none"
              >
                {
                  options.find((opt) => opt.value === selectedRecievables)
                    ?.label
                }
                <svg
                  className={`w-4 h-4 ml-2 transition-transform ${
                    recievablesOpen ? "rotate-180" : "rotate-0"
                  }`}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.707a1 1 0 011.414 0L10 11.293l3.293-3.586a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>

              {/* Dropdown Menu */}
              {recievablesOpen && (
                <ul className="absolute mt-2 w-40 bg-white rounded-2xl brder border-gray300 shadow-md z-10">
                  {options.map((option) => (
                    <li
                      key={option.value}
                      onClick={() => handleReceivableSelect(option)}
                      className={`px-4 py-2 flex items-center cursor-pointer hover:bg-primaryColor hover:text-white ${
                        selectedRecievables === option.value
                          ? "bgprimaryColor"
                          : ""
                      }`}
                    >
                      {/* Radio Indicator */}
                      <span
                        className={`w-4 h-4 mr-3 rounded-full border-2 ${
                          selectedRecievables === option.value
                            ? "border-secondaryColor bg-secondaryColor"
                            : "border-gray300"
                        }`}
                      ></span>
                      {option.label}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </header>

          <div className="px-7 py-4">
            <div className="bg-primaryColor w[325px] h[179px] py-7 px-3.5 rounded-2xl text-center text-white">
              <h1 className="text-white text-[48px] font-extrabold leading-normal">
                45
              </h1>
              <p className="text-xl font-light">
                Total invoices awaiting <br /> collections
              </p>
            </div>
          </div>

          <div className="text-center my-5">
            <p className="text-primaryColor text-[33.091px] font-bold leading-normal">
              ₦4,537,360
            </p>
            <p className="text-[#565656] text-[20px] font-normal leading-normal">
              Total Receivable Amount
            </p>
          </div>

          <div className="flex items-center justify-between px-7 py-4 mt-5">
            <div className="text-center">
              <p className="text-primaryColor font-bold text-2xl">34</p>
              <p className="text-sm text-[#565656]">Upcoming Invoices</p>
            </div>
            <div className="h-12 border-r border-gray"></div>{" "}
            <div className="text-center">
              <p className="text-red font-bold text-2xl">12</p>
              <p className="text-sm text-[#565656]">Overdue Invoices</p>
            </div>
          </div>
        </section>
      </div>

      <div>
        <RecentPaymentTable />
      </div>
    </div>
  );
};

export default Overview;
