import React, { useState, useRef, useEffect } from "react";

import Card from "shared/Card";
import DownloadModal from "./DownloadModal";
import PermissionModal from "./PermissionModal";
import DeleteModal from "./DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAgreement,
  getAgreement,
  // getAgreementId,
} from "appstate/toolsAndAgreement/toolsAndAgreementSlice";
import Pagination from "shared/Pagination";

import toast from "react-hot-toast";
import { AnimatePresence } from "framer-motion";

import AgreeTable from "./tableComponents/AgreementTable";
import TemplateTable from "./tableComponents/TemplateTable";
import PendingTable from "./tableComponents/PendingDeletion";

const AgreementTable = ({ props, selectedItemTable, goToUpload,setShowDetails,createNewAgreement,pendingDeletion, templateDetails }) => {
  // const { searchQuery } = props;
  // const [selectedRows, setSelectedRows] = useState([]);

  const [dropdown, setDropdown] = useState(false);
  const [, setAtBottom] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isPermissionModalOpen, setPermissionModalOpen] = useState(false);
  const [getId, ] = useState(null);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const dropdownRef = useRef();
  const pageSize = 10;
  const [agreeActiveTab, setAgreeActiveTab] = useState(1);

  const { isLoading, allAgreementData } = useSelector(
    (state) => state?.toolsAndAgreements
  );
  const [, setData] = useState([]);

  const dispatch = useDispatch();

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  const togglePermissionModal = () => {
    setPermissionModalOpen(!isPermissionModalOpen);
  };

  // const toggleDeleteModal = () => {
  //   setDeleteModalOpen(!isDeleteModalOpen);
  // };

  // const handleSelect = (row) => {
  //   setDeleteModalOpen(true);
  //   setGetId(row?.id);
  // };

  const handleDelete = () => {
    const agreementId = getId;
    dispatch(deleteAgreement({ agreementId })).then((data) => {
      if (data?.payload?.success === true) {
        dispatch(getAgreement({ page, pageSize }));
        setDeleteModalOpen(false);
        toast.success("Agreement Deleted");
      }
    });
  };

  // const handleDropdown = (row) => {
  //   if (dropdown === row.id) {
  //     setDropdown(null);
  //   } else {
  //     setDropdown(row.id);
  //   }
  // };

  useEffect(() => {
    dispatch(getAgreement({ page, pageSize }));
  }, [dispatch, page, pageSize]);

  useEffect(() => {
    setData(allAgreementData?.data?.data);
    const handleClickOutside = (event) => {
      if (
        dropdown &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setDropdown(false);
      }
    };

    const handleDocumentClick = (e) => {
      const screenHeight = window.innerHeight;
      const clickY = e.clientY;
      const bottomThreshold = 200;
      if (screenHeight - clickY <= bottomThreshold) {
        setAtBottom(true);
      } else {
        setAtBottom(false);
      }
    };
    document.addEventListener("click", handleDocumentClick);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
      document.addEventListener("mousedown", handleClickOutside);
    };
  }, [dropdown, allAgreementData]);

  const totalPages = allAgreementData?.meta?.totalElements;

  

  const TablesToRender = () => (
    <main className="w-full">
      {/* <div className="flex items-center justify-between gap-3 ">
        <button
          onClick={handleCreateNewAgreement}
          className="text-white text-xs p-2 font-inter textbase font-normal leading-normal rounded-md border border-[#CCC] bg-[#FFBE55]"
        >
          Create new agreement
        </button>
        <div className="ml-5">
          <SearchInput onSearch={handleSearch} />
        </div>
      </div> */}
      <AnimatePresence mode="wait">
        {agreeActiveTab === 1 ? (
          <div>
            <AgreeTable setShowDetails={setShowDetails}createNewAgreement={createNewAgreement} />
          </div>
        ) : agreeActiveTab === 2 ? (
          <div>
            <TemplateTable goToUpload={goToUpload} templateDetails={templateDetails}  />
          </div>
        ) : agreeActiveTab === 3 ? (
          // <PendingBidRequest pendingQuery={pendingQuery} />
          <div>
            <PendingTable pendingDeletion={pendingDeletion} />
          </div>
        ) : (
          <></>
        )}
      </AnimatePresence>
    </main>
  );

  return (
    <>
      <Card className="rounded-[10px] mt-[24px]" style={{ width: "100%" }}>
        <div className="flex h-full items-center overflow-x-auto space-x-1 px-3">
          <div
            className={`cursor-pointer relative h-full pt-5 text-sm pb-2 overflow-hidden  text-[#959595] ${
              agreeActiveTab === 1
                ? " text-primaryColor font-bold"
                : "text-gray-400"
            }`}
            onClick={() => setAgreeActiveTab(1)}
          >
            <span className="px-5 text-center flex justify-center w-full">
              Agreements
            </span>

            {agreeActiveTab === 1 && (
              <div className="absolute bottom-0 left-0 w-full  h-1 rounded bg-primaryColor transition-transform duration-300 ease-in-out"></div>
            )}
          </div>

          <div
            className={`cursor-pointer relative h-full pt-5 text-center text-sm overflow-hidden pb-2  text-[#959595] ${
              agreeActiveTab === 2
                ? "text-primaryColor font-bold"
                : "text-gray-400"
            }`}
            onClick={() => setAgreeActiveTab(2)}
          >
            <span className="px-5 text-center flex justify-center w-full ">
              Templates
            </span>
            {agreeActiveTab === 2 && (
              <div className="absolute bottom-0 left-0 w-full h-1 rounded bg-primaryColor transition-transform duration-300 ease-in-out"></div>
            )}
          </div>
          <div
            className={`cursor-pointer relative  h-full overflow-hidden  pt-5 text-sm text-[#959595] pb-2 ${
              agreeActiveTab === 3
                ? "text-primaryColor font-bold"
                : "text-gray-400"
            }`}
            onClick={() => setAgreeActiveTab(3)}
          >
            <span className="px-5 flex justify-center text-center w-full">
              Pending Deletion
            </span>
            {agreeActiveTab === 3 && (
              <div className="absolute bottom-0 left-0 w-full h-1 rounded bg-primaryColor transition-transform duration-300 ease-in-out"></div>
            )}
          </div>
        </div>
        <div className="px-[24px] pb-[24px]">
          <TablesToRender />
          {/* <Table
            columns={columns}
            data={data}
            pointer
            tableHeaderPink
            onRowClicked={handleRowSelect}
            progressPending={isLoading}
            progressComponent={<Loading />}
            className="mt-5"
          /> */}
        </div>
        <div className="flex justify-center gap-x-[8px] mt-[20px]">
          {!isLoading && totalPages > 10 && (
            <Pagination
              page={page}
              startPage={startPage}
              setEndPage={setEndPage}
              endPage={endPage}
              setStartPage={setStartPage}
              setPage={setPage}
              totalPages={totalPages}
            />
          )}
        </div>
      </Card>
      <DownloadModal isOpen={isModalOpen} onClose={toggleModal} />
      <PermissionModal
        isOpen={isPermissionModalOpen}
        onClose={togglePermissionModal}
      />
      <DeleteModal
        isOpen={isDeleteModalOpen}
        handleDeleteAgreement={handleDelete}
        onClose={() => setDeleteModalOpen(false)}
      />
    </>
  );
};

export default AgreementTable;
