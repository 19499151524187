import { PAIconArrowLeftGray } from "assets/images/svgs";
import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import DashboardLayout from "layout/dashboardlayout";
import TransitionScale from "shared/animation/TransitionScale";
import { motion } from "framer-motion";
import { PABlackSmallDangote, PAEllipsisIcon } from "assets/images/svgs";
import Table from "layout/table/Table";

const BukOverViewDetails = () => {
  const columns = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Invoice Number
        </span>
      ),
      selector: (row) => row.invNum,
    },

    {
      name: "Due Date",
      selector: (row) => row.dueDate,
    },
    {
      name: "Request Amount",
      selector: (row) => row.requestAmount,
    },
    {
      name: "Discount Rate",
      selector: (row) => row.discountRate,
    },
    {
      name: "Receivable Amount",
      selector: (row) => row.receivableAmount,
    },
  ];

  const tableData = [
    {
      bidAmount: 1,
      invNum: "INV-2090 ",
      dueDate: "2023-05-23",
      requestAmount: "9,000,000.00",
      discountRate: "1.5%",
      receivableAmount: "9,000,000.00",
    },
    {
      bidAmount: 2,
      invNum: "INV-2090 ",
      dueDate: "2023-05-23",
      requestAmount: "9,000,000.00",
      discountRate: "1.5%",
      receivableAmount: "9,000,000.00",
    },

    {
      bidAmount: 3,
      invNum: "INV-2090 ",
      dueDate: "2023-05-23",
      requestAmount: "9,000,000.00",
      discountRate: "1.5%",
      receivableAmount: "9,000,000.00",
    },
    {
      bidAmount: 4,
      invNum: "INV-2090 ",
      dueDate: "2023-05-23",
      requestAmount: "9,000,000.00",
      discountRate: "1.5%",
      receivableAmount: "9,000,000.00",
    },
  ];
  const navigateToDetail = (id) =>
    navigate(
      `/payablefinancing-closedmarket/funderclosedmarketbuldetailspage/${id}`
    );

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <DashboardLayout
        section="Closed Market"
        subHeader="View invoices uploaded to the closed market by your associated buyers"
      >
        <div>
          <Link
            className="flex cursor-pointer w-fit gap-1 items-center  mb-6"
            onClick={goBack}
          >
            <PAIconArrowLeftGray />
            <span className=" text-[#7A7A7A] text-sm">Go Back</span>
          </Link>
        </div>

        <motion.section
          initial={{
            x: 20,
          }}
          animate={{
            x: 0,
          }}
          exit={{
            x: -20,
          }}
          transition={{
            delay: 0.6,
          }}
        >
          <div className="grid lg:grid-cols-[60%,40%] gap-5">
            <TransitionScale className=" pb-5 bg-white  rounded-[10px]  pt-2">
              {/* Header with Left Padding */}

              {/* Other sections */}

              <section className="  mt-3">
                {/* Down Flex Texts */}
                <div className=" flex justify-between px-6  mt-4">
                  {" "}
                  <div class="text-[#222222] text-lg font-medium ">
                    Reference No: | RF83018374BA
                  </div>
                  <div class="h-[38px] px-6 py-3 bg-[#07593d] rounded-[5px] justify-center items-center gap-2.5 inline-flex">
                    <div class="text-white text-sm font-medium ">
                      Disburse Now
                    </div>
                  </div>
                </div>
                <div className=" flex justify-between px-6">
                  {/* first stage */}

                  <div class="lg:w-[240px]  sm:w-full h-[117px] flex-col justify-start items-start gap-3 inline-flex  mt-5">
                    <div class="self-stretch  flex-col justify-center items-start gap-1 flex ">
                      <div class="self-stretch justify-between items-center inline-flex">
                        <div class="text-[#222222] text-xs font-semibold ">
                          Early Payment Date:
                        </div>
                        <div class="text-[#7a7a7a] text-xs font-semibold ">
                          23-Mar -2023
                        </div>
                      </div>
                      <div class="self-stretch justify-between items-center inline-flex ">
                        <div class="text-[#222222] text-xs font-semibold ">
                          Total Request amount
                        </div>
                        <div class="text-[#7a7a7a] text-xs font-semibold ">
                          24,000,000
                        </div>
                      </div>
                      <div class="self-stretch justify-between items-center inline-flex">
                        <div class="text-[#222222] text-xs font-semibold ">
                          Total Invoice amount
                        </div>
                        <div class="text-[#7a7a7a] text-xs font-semibold ">
                          34,000,000
                        </div>
                      </div>
                    </div>

                    {/* stop */}
                  </div>
                  {/* second stage */}
                  <div className="flex mt-5 ">
                    {" "}
                    <div class="h-[30px] px-8 py-2 bg-[#23ad52]/20 rounded-[5px] justify-center items-center gap-2.5 inline-flex">
                      <div class="text-center text-[#07593d] text-xs font-medium ">
                        Won Bid
                      </div>
                    </div>
                  </div>
                </div>

                {/* stops here */}
                <div className="  px-6   mt-11">
                  <header className="border-b-[5px] border-b-[#F6F5FA] mt-3  flex justify-between py-1">
                    {/* <h1 className="text-[18px] font-semibold">
                  Supplier/buyer details
                </h1> */}
                    <div>
                      <div class="text-[#222222] text-lg font-semibold  ">
                        Dangote Plc.
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-light  ">
                        Buyer details
                      </div>
                    </div>

                    <div class="h-[41px] flex-col justify-start items-start  inline-flex px-12">
                      <div class="text-[#222222] text-base font-semibold ">
                        60 Days
                      </div>
                      <div class="w-[100px] text-[#7a7a7a] text-xs font-light  mt-1">
                        Payment
                      </div>
                    </div>

                    <div class="h-[43px] flex-col justify-start items-end  inline-flex">
                      <div class="text-[#222222] text-lg font-semibold ">
                        NGN
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold mt-1">
                        Currency
                      </div>
                    </div>
                  </header>

                  <header className="border-b-[5px] border-b-[#F6F5FA] mt-6  flex justify-between py-1">
                    {/* <h1 className="text-[18px] font-semibold">
                  Supplier/buyer details
                </h1> */}
                    <div class="text-[#11192a] text-sm font-semibold ">
                      Invoice List
                    </div>
                    <div class="text-[#11192a] text-lg font-light  mr-6">
                      4 Invoices
                    </div>
                  </header>

                  {/* <div className=" w-full overflow-x-auto mt-3 pb-4"></div> */}

                  <div className=" w-full overflow-x-auto mt-3 ">
                    <Table
                      columns={columns}
                      data={tableData}
                      pointer
                      className=""
                      onRowClicked={(row) => navigateToDetail(row.bidAmount)}
                      progressPending={false}
                      // progressComponent={<Loading />}
                    />
                  </div>
                  {/* Calculate cost and price  shaded or gray section */}
                </div>
              </section>
            </TransitionScale>
            {/* stops the best here */}

            <div className="bg-white rounded-[10px] gap-8  ">
              {/* <FundDetailSide /> */}
              <div class="w-full justify-start items-center gap-4 inline-flex mt-4 ">
                <div class=" justify-start items-center gap-2.5 flex w-full px-6  py-3">
                  <div class="text-[#07593d] text-base font-bold ">
                    Bid details for this invoice
                  </div>
                </div>
              </div>

              <div>
                {" "}
                {/* second */}
                <div className="   pb-4">
                  <div class=" justify-between  flex flex-row    px-6 mt-4  ">
                    <div className="flex gap-2 py-2.5">
                      <PAEllipsisIcon />
                      <div className="flex flex-col">
                        {/* <span className=" text-black text-xs font-normal">
                        Coca Cola
                      </span> */}
                        <span class="text-black text-sm font-normal ">
                          Nestle Plc.
                        </span>
                        {/* <span className="text-[#979797]  text-xs">Supplier</span> */}
                        <span class="text-[#949494] text-sm font-normal ">
                          Buyer
                        </span>
                      </div>
                    </div>
                    <div class=" flex-col justify-start items-start inline-flex">
                      {" "}
                      <div className="flex gap-2 py-2.5">
                        <PABlackSmallDangote />
                        <div className="flex flex-col">
                          {/* <span className=" text-black text-xs font-normal">
                        Coca Cola
                      </span> */}
                          <span class="text-black text-sm font-normal ">
                            Dangote Plc.
                          </span>
                          {/* <span className="text-[#979797]  text-xs">Supplier</span> */}
                          <span class="text-[#949494] text-sm font-normal ">
                            Supplier
                          </span>
                        </div>
                      </div>
                    </div>

                    {/* here */}
                  </div>
                  <div class=" justify-between  flex flex-row    px-6  mt-6 ">
                    <div class="py-2.5 flex-col justify-start items-start inline-flex">
                      <div class="flex-col justify-start items-start gap-0.5 flex">
                        <div class="text-black text-sm font-normal ">
                          NGN 510,000
                        </div>
                      </div>
                      <div class="flex-col justify-start items-start  flex">
                        <div class="text-[#949494] text-sm font-normal ">
                          Lock rate amount
                        </div>
                      </div>
                    </div>
                    {/* hd */}
                    <div class="py-2.5 flex-col justify-start items-start inline-flex">
                      <div class="flex-col justify-start items-start gap-0.5 flex">
                        <div class="text-black text-sm font-normal ">1.5%</div>
                      </div>
                      <div class="flex-col justify-start items-start  flex">
                        <div class="text-[#949494] text-sm font-normal ">
                          Lock rate
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                  <div class=" justify-between  flex flex-row     px-6  py-3   mt-6">
                    <div class="py-2.5 flex-col justify-start items-start inline-flex">
                      <div class="flex-col justify-start items-start gap-0.5 flex">
                        <div class="text-black text-sm font-normal ">
                          2023-02-22 (90 days)
                        </div>
                      </div>
                      <div class="flex-col justify-start items-start  flex">
                        <div class="text-[#949494] text-sm font-normal ">
                          Request date
                        </div>
                      </div>
                    </div>
                    {/* dhdh */}
                    <div class="py-2.5 flex-col justify-start items-start inline-flex">
                      <div class="flex-col justify-start items-start gap-0.5 flex">
                        <div class="text-black text-sm font-normal ">
                          2023-05-23
                        </div>
                      </div>
                      <div class="flex-col justify-start items-start  flex">
                        <div class="text-[#949494] text-sm font-normal ">
                          Early Payment date
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class=" justify-between  flex flex-row    px-6  mt-6 ">
                    <div class="py-2.5 flex-col justify-start items-start inline-flex">
                      <div class="flex-col justify-start items-start gap-0.5 flex">
                        <div class="text-black text-sm font-normal ">
                          NGN 34,000,000
                        </div>
                      </div>
                      <div class="flex-col justify-start items-start  flex">
                        <div class="text-[#949494] text-sm font-normal ">
                          Total Request Amount
                        </div>
                      </div>
                    </div>
                    {/* hd */}
                    <div class="py-2.5 flex-col justify-start items-start inline-flex">
                      <div class="flex-col justify-start items-start gap-0.5 flex">
                        <div class="text-black text-base font-extrabold ">
                          NGN 33,490,000
                        </div>
                      </div>
                      <div class="flex-col justify-start items-start  flex">
                        <div class="text-[#949494] text-sm font-normal ">
                          Total Fundable Amount
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                  {/* 2nd */}
                </div>
              </div>
            </div>
          </div>
        </motion.section>
      </DashboardLayout>
    </>
  );
};

export default BukOverViewDetails;
