import {
  PAIconAddGreen,
  PAIconCancelRound,
  PAIconSmallClose,
} from "assets/images/svgs";
import RightSideModal from "shared/RightSideModal";
import ConditionTable from "../table/ConditionTable";
import Switch from "features/settings/components/NotificationSetting/components/Switch";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { ruleTypes } from "../../data/ruleTypes";
import { formatCapitaliseString } from "utilities/helper/formatCapitaliseString";
import {
  allRules,
  createRule,
} from "appstate/rulesandconfig/rulesandconfigSlice";
import toast from "react-hot-toast";
// import toast from "react-hot-toast";

function CreateNewRule({ closeModal, setRuleSavedModal, setTestModal }) {
  const { ruleType } = useParams();
  const dispatch = useDispatch();
  const [ruleActivated, setRuleActivated] = useState(true);
  const [selectedAction] = useState("none");
  const [selectedActions, setSelectedActions] = useState([]);
  const [rows, setRows] = useState([{ type: "", operator: "", value: "" }]);

  const [ruleName, setRuleName] = useState("");

  const [isDropdownVisible, setDropdownVisible] = useState(true);

  const toggleRuleActivated = () => {
    setRuleActivated(!ruleActivated);
  };

  const handleDropdownChange = (e) => {
    const selectedValue = e.target.value;

    if (!selectedActions.some((action) => action.type === selectedValue)) {
      setSelectedActions((prevActions) => [
        ...prevActions,
        { type: selectedValue, value: "" },
      ]);
    }
  };

  const handleInputChange = (selectedValue, value) => {
    setSelectedActions((prevActions) =>
      prevActions.map((action) =>
        action.type === selectedValue ? { ...action, value } : action
      )
    );
  };

  const handleRuleNameChange = (e) => {
    setRuleName(e.target.value);
  };

  const handleAddAction = () => {
    setDropdownVisible(true);
  };

  const handleRemoveAction = (index) => {
    setSelectedActions((prevActions) =>
      prevActions.filter((_, i) => i !== index)
    );
  };

  const handleClickNext = () => {
    // Handle submit logic here
    const body = {
      name: ruleName,
      conditions: rows,
      actions: selectedActions,
    };

    dispatch(createRule({ body, ruleType }))
      .then((data) => {
        if (data?.payload?.success) {
          dispatch(allRules({ page: 0, pageSize: 10, ruleType }));
          setTimeout(() => {
            setRuleSavedModal(true);
          }, 600);

          closeModal();
        }
      })
      .catch((error) => {
        // Error callback
        toast.error(error?.message);
      });
  };

  return (
    <>
      <RightSideModal closeModal={closeModal}>
        <div className="flex flex-col gap-3 py-4">
          <header className="flex px-[4%] pb-6 border-b-[3.25px] border-[#F6F5FA]   items-center justify-between">
            <span className="font-bold text-xl">Create Rule </span>

            <span
              onClick={closeModal}
              className=" cursor-pointer flex gap-2 items-center"
            >
              <PAIconSmallClose />

              <span>Close</span>
            </span>
          </header>
          <main className="py-3 flex flex-col gap-8">
            <section className="px-[4%] flex flex-col gap-2">
              <span className="text-sm text-primaryColor">Rule Name</span>
              <input
                onChange={handleRuleNameChange}
                value={ruleName}
                // value={formData.name}
                className="px-5 py-3 w-full rounded-[5px] outline-none border-[0.5px] border-primaryColor"
                type="text"
              />
            </section>

            <section
              style={{
                background: "rgba(203, 233, 215, 0.11)",
              }}
              className="py-5 px-[4%] flex flex-col gap-4"
            >
              <span className="text-xl font-semibold">Set Conditions</span>
              <span className=" w-full">
                <ConditionTable rows={rows} setRows={setRows} />
              </span>
            </section>

            <section className="py-5 px-[4%] flex flex-col gap-6">
              <div className="flex items-start justify-between">
                <div className="flex flex-col gap-3">
                  <span className="text-xl font-semibold">Set Action</span>
                  <span className="text-[#7A7A7A] text-xs italic font-light  ">
                    You can set one or multiple actions for your specified
                    conditions
                  </span>
                </div>

                <span className=" flex items-center gap-10">
                  <span className="text-sm">Activate</span>
                  <Switch
                    isToggled={ruleActivated}
                    onToggle={toggleRuleActivated}
                  />
                </span>
              </div>

              <div className="w-full flex flex-col gap-4">
                {selectedActions.map((action, index) => (
                  <div
                    className="flex justify-between items-center"
                    key={index}
                  >
                    <span>{formatCapitaliseString(action.type)}</span>

                    <div className="flex items-center gap-2 justify-end w-[40%] ">
                      <input
                        className="px-2.5 w-full  text-xs  rounded-[5px] border outline-none border-[#E6E6E6] py-2.5"
                        type="text"
                        value={action.value}
                        onChange={(e) =>
                          handleInputChange(action.type, e.target.value)
                        }
                        placeholder={`Enter ${formatCapitaliseString(
                          action.type
                        )}`}
                      />

                      <button
                        className="flex"
                        onClick={() => handleRemoveAction(index)}
                      >
                        <PAIconCancelRound />
                      </button>
                    </div>
                  </div>
                ))}

                {isDropdownVisible && (
                  <select
                    className="px-5 cursor-pointer text-sm text-[#bababa]  rounded-[5px] border outline-none border-[#07593D75] py-4 w-full"
                    value={selectedAction}
                    onChange={handleDropdownChange}
                  >
                    <option className="text-sm text-[#B1B1B1]" value="">
                      Select from a list of available actions
                    </option>
                    {ruleTypes?.[ruleType]?.actions?.map((action, index) => (
                      <option
                        key={index}
                        value={action}
                        className="cursor-pointer text-black hover:bg-primaryColor hover:text-white"
                      >
                        {formatCapitaliseString(action)}
                      </option>
                    ))}
                  </select>
                )}

                {!isDropdownVisible && (
                  <div className="py-4  flex justify-end">
                    <button
                      className=" flex justify-center items-center gap-2 text-primaryColor text-xs"
                      onClick={handleAddAction}
                    >
                      <PAIconAddGreen />
                      <span>Add Action</span>
                    </button>
                  </div>
                )}
              </div>
            </section>

            <footer className="px-[4%] pt-10">
              <div className="flex justify-end">
                <div className="flex gap-3">
                  <button
                    onClick={() => closeModal()}
                    className=" border border-red text-red text-xs py-2.5 px-5 rounded-[5px]"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => handleClickNext()}
                    disabled={selectedActions.length === 0}
                    className=" border border-primaryColor text-primaryColor text-xs py-2.5 px-5 rounded-[5px]"
                  >
                    Save Rule
                  </button>

                  <button
                    onClick={() => {
                      closeModal();
                      setTestModal(true);
                    }}
                    disabled={selectedActions?.length === 0}
                    className={` ${
                      selectedActions?.length === 0
                        ? "bg-[#DADADA]"
                        : "bg-primaryColor"
                    }  text-white   text-xs py-2.5 px-5 rounded-[5px]`}
                  >
                    Test and Save Rule
                  </button>
                </div>
              </div>
            </footer>
          </main>
        </div>
      </RightSideModal>
    </>
  );
}

export default CreateNewRule;
