import React from "react";
import Table from "layout/table/Table";
import Card from "shared/Card";
import Loading from "shared/Loading";
import TableEmptyState from "shared/TableEmptyState";

const EarlyPaymentComponent = ({ requestType }) => {
  const earlyPaymentData = {
    data:
      requestType === "BulkRequest"
        ? [
            {
              id: 5,
              requestRefNo: "REQ005",
              noOfInvoices: 3,
              buyer: "GHI Corp",
              earlyPaymentDate: "2024-10-10",
              requestAmount: "$30,000",
              discountAmount: "$1,500",
              receivableAmount: "$28,500",
              status: "Pending Approval",
            },
            {
              id: 6,
              requestRefNo: "REQ006",
              noOfInvoices: 4,
              buyer: "JKL Ltd",
              earlyPaymentDate: "2024-10-12",
              requestAmount: "$25,000",
              discountAmount: "$1,250",
              receivableAmount: "$23,750",
              status: "Approved",
            },
            {
              id: 7,
              requestRefNo: "REQ007",
              noOfInvoices: 2,
              buyer: "MNO Inc",
              earlyPaymentDate: "2024-10-14",
              requestAmount: "$20,000",
              discountAmount: "$1,000",
              receivableAmount: "$19,000",
              status: "Pending Approval",
            },
            {
              id: 8,
              requestRefNo: "REQ008",
              noOfInvoices: 5,
              buyer: "PQR Ltd",
              earlyPaymentDate: "2024-10-15",
              requestAmount: "$18,000",
              discountAmount: "$900",
              receivableAmount: "$17,100",
              status: "Approved",
            },
          ]
        : [
            {
              id: 1,
              requestRefNo: "REQ001",
              invoiceNumber: "INV123",
              buyer: "ABC Corp",
              earlyPaymentDate: "2024-10-01",
              requestAmount: "$10,000",
              discountAmount: "$500",
              receivableAmount: "$9,500",
              status: "Pending Approval",
            },
            {
              id: 2,
              requestRefNo: "REQ002",
              invoiceNumber: "INV124",
              buyer: "DEF Corp",
              earlyPaymentDate: "2024-10-05",
              requestAmount: "$15,000",
              discountAmount: "$750",
              receivableAmount: "$14,250",
              status: "Approved",
            },
            {
              id: 3,
              requestRefNo: "REQ003",
              invoiceNumber: "INV125",
              buyer: "XYZ Ltd",
              earlyPaymentDate: "2024-10-07",
              requestAmount: "$20,000",
              discountAmount: "$1,000",
              receivableAmount: "$19,000",
              status: "Pending Approval",
            },
            {
              id: 4,
              requestRefNo: "REQ004",
              invoiceNumber: "INV126",
              buyer: "UVW Inc",
              earlyPaymentDate: "2024-10-09",
              requestAmount: "$12,000",
              discountAmount: "$600",
              receivableAmount: "$11,400",
              status: "Approved",
            },
          ],
  };

  const columns =
    requestType === "BulkRequest"
      ? [
          { name: "Request Ref No", selector: (row) => row.requestRefNo },
          { name: "Buyer", selector: (row) => row.buyer },
          {
            name: "Early Payment Date",
            selector: (row) => row.earlyPaymentDate,
          },
          { name: "No. of Invoices", selector: (row) => row.noOfInvoices }, 
          {
            name: "Request Amount",
            selector: (row) => row.requestAmount,
            cell: (row) => <p className="text-success">{row.requestAmount}</p>,
          },
          {
            name: "Discount Amount",
            selector: (row) => row.discountAmount,
            cell: (row) => <p className="text-warning">{row.discountAmount}</p>,
          },
          {
            name: "Receivable Amount",
            selector: (row) => row.receivableAmount,
            cell: (row) => (
              <p className="text-primary">{row.receivableAmount}</p>
            ),
          },
          {
            name: "Status",
            selector: (row) => row.status,
            cell: (row) => (
              <span
                className={`${
                  row.status === "Approved"
                    ? "text-green-500"
                    : row.status === "Pending Approval"
                    ? "text-yellow-500"
                    : "text-red-500"
                }`}
              >
                {row.status}
              </span>
            ),
          },
        ]
      : [
          { name: "Request Ref No", selector: (row) => row.requestRefNo },
          { name: "Invoice Number", selector: (row) => row.invoiceNumber }, 
          { name: "Buyer", selector: (row) => row.buyer },
          {
            name: "Early Payment Date",
            selector: (row) => row.earlyPaymentDate,
          },
          {
            name: "Request Amount",
            selector: (row) => row.requestAmount,
            cell: (row) => <p className="text-success">{row.requestAmount}</p>,
          },
          {
            name: "Discount Amount",
            selector: (row) => row.discountAmount,
            cell: (row) => <p className="text-warning">{row.discountAmount}</p>,
          },
          {
            name: "Receivable Amount",
            selector: (row) => row.receivableAmount,
            cell: (row) => (
              <p className="text-primary">{row.receivableAmount}</p>
            ),
          },
          {
            name: "Status",
            selector: (row) => row.status,
            cell: (row) => (
              <span
                className={`${
                  row.status === "Approved"
                    ? "text-green-500"
                    : row.status === "Pending Approval"
                    ? "text-yellow-500"
                    : "text-red-500"
                }`}
              >
                {row.status}
              </span>
            ),
          },
        ];

  return (
    <>
      {earlyPaymentData?.data?.length === 0 ? (
        <>
          <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
            <p className="text-black text-sm font-medium">Recent Request</p>
          </div>
          <TableEmptyState dashboard />
        </>
      ) : (
        <Card className="rounded-[10px]">
          <div className="pb-[24px]">
            <Table
              columns={columns}
              data={earlyPaymentData.data}
              progressPending={false}
              progressComponent={<Loading />}
              pointer
              tableHeader
              className="mt-5"
            />
          </div>
        </Card>
      )}
    </>
  );
};

export default EarlyPaymentComponent;
