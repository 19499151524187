import request from "apiInstance";
import toast from "react-hot-toast";

export const documentUpload = async ({ body, player }) => {
  try {
    const response = await request({
      method: "post",
      url: `/api/v1/documents/upload/${player}`,
      headers: {
        "content-type": "multipart/form-data",
      },
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

//
export const documentUploadEdit = async ({ body }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/buyers/edit-profile`,
      headers: {
        "content-type": "multipart/form-data",
      },
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const companyDetailsBuyerEdit = async ({ body }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/buyers/edit/company`,
      // headers: {
      //   "content-type": "multipart/form-data",
      // },
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const companyDetailsSupplierEdit = async ({ body }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/suppliers/edit/company-details`,
      // headers: {
      //   "content-type": "multipart/form-data",
      // },
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const companyDetailsFunderEdit = async ({ body }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/funders/edit/company-details`,
      // headers: {
      //   "content-type": "multipart/form-data",
      // },
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const updateUploadedDocumentById = async (data) => {
  try {
    const formData = new FormData();
    formData.append("document", data.image);
    for (const [key, value] of Object.entries(data.body)) {
      formData.append(key, value);
    }
    const response = await request({
      method: "put",
      url: `/api/v1/documents/update/${data.id}`,
      headers: {
        "content-type": "multipart/form-data",
      },
      data: formData,
    });
    if (response?.data) {
      toast.success("Documnet updated successfully");
    }
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

// export const buyerUpdateUploadedDocument = async ({ data }) => {
//   try {
//     // const formData = new FormData();
//     // formData.append("filePart", data.filePart);
//     // for (const [key, value] of Object.entries(data.body)) {
//     //   formData.append(key, value);
//     // }

//     const formData = new FormData();
//     formData.append("filePart", data.filePart); // Fix: Changed from `data.image` to `data.filePart`
//     formData.append("fullName", data.fullName);
//     formData.append("phoneNumber", data.phoneNumber);
//     const response = await request({
//       method: "put",
//       url: `/api/v1/buyers/edit-profile`,
//       headers: {
//         "content-type": "multipart/form-data",
//       },
//       data: formData,
//     });
//     if (response?.data) {
//       toast.success("Profile updated successfully");
//     }
//     console.log("error Response", response);
//     return response?.data;
//   } catch (error) {
//     console.error("error", error);
//     toast.error(error?.response?.data?.message + "!");
//   }
// };

export const buyerUpdateUploadedDocument = async ({ body }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/buyers/edit-profile`,
      headers: {
        "content-type": "multipart/form-data",
      },
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const supplierUpdateUploadedDocument = async ({ body }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/suppliers/suppliers/edit`,

      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const funderUpdateUploadedDocument = async ({ body }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/funders/edit/funder-profile`,

      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const deleteUploadedDocumentById = async (id) => {
  try {
    const response = await request({
      method: "delete",
      url: `/api/v1/documents/delete/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getUploadedDocuments = async () => {
  try {
    const response = await request({
      method: "get",
      url: "/api/v1/documents/view-documents",
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getUploadedDocumentById = async (id) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/documents/view-document/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

  export const getfunderDetails = async () => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/funders/view`,
    });
    // console.log("==data===",response?.data)
    return response?.data;
   
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
export const getBuyerDetails = async () => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/buyers/view`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getSupplierDetails = async () => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/suppliers/view`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};


export const updateNIN = async ({ nin, player }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/bank/nin/${nin}?userType=${player}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const updateFundingAgreementDecision = async (option) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/agreement/approve-funding-agreement?approved=${option}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
