import React from "react";
import { PAIconHappy, PAIconSmallClose } from "assets/images/svgs";
import { motion } from "framer-motion";

const InvoiceLockSuccessModal = ({ onClose, setActiveTab, update }) => {
  const handleCloseDeleteModal = () => {
    onClose();
  };

  return (
    <motion.div>
      <motion.div className="bg-slate-600/20 fixed inset-0 flex items-center justify-center z-50">
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.2,
            },
          }}
          exit={{
            opacity: 0,
            transition: {
              delay: 0.1,
            },
          }}
          className="modal-overlay"
        ></motion.div>

        <motion.div
          initial={{
            scale: 0,
          }}
          animate={{
            scale: 1,
            transition: {
              duration: 0.2,
            },
          }}
          exit={{
            scale: 0,
            transition: {
              delay: 0.1,
            },
          }}
          className="px-6 py-9 bg-white rounded-[10px] w-[60%] md:w-1/2 extra-md:w-[33%]  min-h-1/2 flex flex-col"
        >
          <header className="flex justify-between pb-4 border-b-[3px] border-[#F6F5FA]">
            <span className=" text-[#222] font-bold">Invoice Locked</span>

            <span
              onClick={() => handleCloseDeleteModal()}
              className="flex items-center cursor-pointer gap-2 text-sm font-[500] text-[#565656]"
            >
              <PAIconSmallClose />
              Close
            </span>
          </header>

          <div className="flex text-[#959595] flex-col pt-2 gap-6">
            {/* Conditionally render the lien information */}
            {update ? (
              <div className=" w-full flex justify-center bg-white">
                <PAIconHappy />
              </div>
            ) : (
              <>
                <div className=" text-center text-sm">
                  Acount{" "}
                  <span className=" text-primaryColor font-bold italic">
                    7283049584
                  </span>{" "}
                  would be liened to fund invoice <br /> should you win this bid
                  offer
                </div>
                <div className=" w-full flex justify-center bg-white">
                  <PAIconHappy />
                </div>
                <div className="text-center text-[15px] text-secondaryColor font-bold">
                  You can cancel your bid within 30 seconds of placing it
                </div>
              </>
            )}

            <div className=" text-center text-[15px] text-[#959595]">
              You've successfully {update ? "updated" : "placed"} your bid on
              this request. You <br /> can monitor the bid process in the{" "}
              <span
                onClick={() => {
                  setActiveTab(3);
                  onClose();
                }}
                className="cursor-pointer font-bold underline text-primaryColor"
              >
                Ongoing bid tab.
              </span>{" "}
              <br /> Thanks
            </div>
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default InvoiceLockSuccessModal;
