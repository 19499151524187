import Modal from "layout/modal/Modal";
import InvitationModal from "./InvitationModal";

function InviteModal({
  isShown,
  onClose,
  message,
  heading,
  info,
  role,
  invite,
  setInvite,
  newInvite,
  setNewInvite,
  handleAdd,
  success,
  isLoading,
  funderBuyer
}) {
  return (
    <Modal isShown={isShown} hide={onClose} accountModal>
      <InvitationModal
        onClose={onClose}
        message={message}
        heading={heading}
        info={info}
        role={role}
        invite={invite}
        setInvite={setInvite}
        newInvite={newInvite}
        setNewInvite={setNewInvite}
        handleAdd={handleAdd}
        isLoading={isLoading}
        success={success}
        funderBuyer={funderBuyer}
      />
    </Modal>
  );
}

export default InviteModal;
