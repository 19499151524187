import {
  PAIconArrowLeft,
  PAIconCancelMini,
  PAIconCheckPass,
  PAIconFileMini,
  PAIconIllustrationKYC,
  PAIconNewArrowRight,
} from "assets/images/svgs";
import Heading from "../category/components/Heading";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import PersonalDocumentModal from "./components/PersonalDocumentModal";
import GeneralDocumentModal from "./components/GeneralUploadModal";
import { formatDate } from "utilities/helper/dateFormatterSpelt";
// import { uploadDocument } from "appstate/buyer/buyerSlice";
import { useDispatch } from "react-redux";
import { AnimatePresence } from "framer-motion";
import ViewDocumentModal from "features/RulesConfig/common/components/modals/DeleteRuleModal";
import toast from "react-hot-toast";
import { updateUploadKycDocument } from "./supplierVerifcationSlice/verificationActions";
import NinModal from "./components/NinModal";
import NINVerifiedModal from "./components/modals/NINVerifiedModal";
import SuccessModal from "shared/SuccessModal";
import { uploadNIN } from "appstate/onboarding/onboardingSlice";
import { uploadDocument } from "appstate/buyer/buyerSlice";

function KYC() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { player } = useParams();

  const [identityDocument, setIdentityDocument] = useState(false);
  const [utilityDocument, setUtilityDocument] = useState(false);
  const [cacDocument, setCacDocument] = useState(false);
  const [ninInfo, setNinInfo] = useState(false);
  const [nin, setNin] = useState("");

  const [selectedIdentityDate, setSelectedIdentityDate] = useState(null);
  const [selectedIdentityFileName, setSelectedIdentityFileName] =
    useState(null);

  const [selectedUtilityDate, setSelectedUtilityDate] = useState(null);
  const [selectedUtilityFileName, setSelectedUtilityFileName] = useState(null);

  const [selectedCacDate, setSelectedCacDate] = useState(null);
  const [selectedCacFileName, setSelectedCacFileName] = useState(null);

  const [submitUtilityModal, setSubmitUtilityModal] = useState(false);
  const [submitCacModal, setSubmitCacModal] = useState(false);

  const [ninSubmitted, setNinSubmitted] = useState(false);
  const [ninValidated, setNinValidated] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [successModal, setSuccessModal] = useState(false);

  const handleBack = () => {
    navigate(-1);
  };

  const handleSubmitNin = () => {
    if (isNaN(Number(nin)) || nin.length < 11) {
      return toast.error("NIN must be an 11 digit long number");
    }

    dispatch(uploadNIN({ nin, player })).then((data) => {
      if (data?.payload?.success) {
        setNinInfo(false);
        setNinValidated(true);

        setNinSubmitted(true);
      }
    });
  };

  const handleUploadUtilityDocuments = () => {
    const timestamp = Date.now();
    setSelectedUtilityDate(formatDate(timestamp));
    const image = selectedUtilityFileName;
    const formData = new FormData();
    formData.append("document", image);
    formData.append("documentType", "UTILITY_BILL");
    formData.append("cardType", "");

    dispatch(uploadDocument({ body: formData, player })).then(() => {
      setSelectedUtilityDate(formatDate(timestamp));
      setSuccessMessage("Document uploaded sucessfully");
      setSuccessModal(true);
    });
  };

  const handleUploadCacDocuments = () => {
    const timestamp = Date.now();
    setSelectedCacDate(formatDate(timestamp));

    const image = selectedCacFileName;
    const formData = new FormData();
    formData.append("document", image);
    formData.append("documentType", "CAC");
    formData.append("cardType", "");

    dispatch(uploadDocument({ body: formData, player })).then(() => {
      setSuccessMessage("Document uploaded sucessfully");
      setSelectedCacDate(formatDate(timestamp));
      setSuccessModal(true);
    });
  };

  const handleBackToCompleteSetup = () => {
    navigate(-1);
    dispatch(updateUploadKycDocument(true));
  };

  return (
    <>
      <Heading nohelp />
      <div className="flex flex-col gap-6   items-center w-full pb-52 pt-4 px-4 md:px-[3%]">
        <span
          onClick={() => handleBack()}
          className="left-[3%] cursor-pointer absolute"
        >
          <PAIconArrowLeft />
        </span>
        <header className="pb-4 pt-6">
          <div className="">
            <h1 className="font-medium text-2xl">Upload Documents</h1>
          </div>
        </header>

        <main className="md:min-w-[712px] min-w-[400px] w-fit border-t pt-5 flex flex-col gap-8 border-[#F0F0F0]">
          <section className="pb-[50px] flex flex-col gap-4 items-center">
            <div className=" w-full text-[#222] text-sm font-medium">
              <span>Uploaded documents</span>
            </div>

            <div className="flex w-full">
              {selectedIdentityFileName ||
              selectedUtilityFileName ||
              selectedCacDate ? (
                <div className="flex w-full flex-col gap-4">
                  {selectedIdentityFileName && (
                    <div className="w-full flex items-center justify-between">
                      <div className="rounded bg-[#E9E9E9] px-3 py-2 flex gap-3 items-center">
                        <PAIconFileMini />
                        <span>{selectedIdentityFileName?.name}</span>

                        <span className="cursor-pointer">
                          <PAIconCancelMini />
                        </span>
                      </div>

                      <div className="text-xs text-secondaryColor">Pending</div>
                    </div>
                  )}

                  {selectedUtilityFileName && (
                    <div className="w-full flex items-center justify-between">
                      <div className="rounded bg-[#E9E9E9] px-3 py-2 flex gap-3 items-center">
                        <PAIconFileMini />
                        <span>{selectedUtilityFileName?.name}</span>

                        <span className="cursor-pointer">
                          <PAIconCancelMini />
                        </span>
                      </div>

                      <div className="text-xs text-secondaryColor">Pending</div>
                    </div>
                  )}

                  {selectedCacFileName && (
                    <div className="w-full flex items-center justify-between">
                      <div className="rounded bg-[#E9E9E9] px-3 py-2 flex gap-3 items-center">
                        <PAIconFileMini />
                        <span>{selectedCacFileName?.name}</span>

                        <span className="cursor-pointer">
                          <PAIconCancelMini />
                        </span>
                      </div>

                      <div className="text-xs text-secondaryColor">Pending</div>
                    </div>
                  )}
                </div>
              ) : (
                <div className=" w-full flex flex-col gap-3 items-center">
                  <PAIconIllustrationKYC />
                  <div className=" text-sm text-[#86888B]">
                    No document uploaded yet
                  </div>
                </div>
              )}
            </div>
          </section>

          <section className="flex flex-col gap-8">
            <div className="flex justify-between">
              <div className="flex gap-5">
                <div>
                  {ninSubmitted ? (
                    <PAIconCheckPass />
                  ) : (
                    <div className="h-5 w-5 cursor-pointer bg-white rounded-full border border-[#86888B80]"></div>
                  )}
                </div>
                <div className="text-sm flex flex-col gap-2">
                  <span className="font-medium">Add NIN Number</span>

                  <span className="text-[#7A7A7A]">
                    It is essential to input your NIN number for identification
                    and security <br /> purposes
                  </span>
                </div>
              </div>

              <div>
                {ninSubmitted ? (
                  <span className="text-xs text-primaryColor">Approved</span>
                ) : (
                  <span
                    onClick={() => setNinInfo(true)}
                    className=" py-2 px-2.5 w-fit bg-[#F0EFF1] rounded-[5px] gap-4 flex items-center cursor-pointer"
                  >
                    <p className="font-medium text-[10px] px-2">Enter Number</p>
                    <PAIconNewArrowRight />
                  </span>
                )}
              </div>
            </div>

            <div className="flex justify-between">
              <div className="flex gap-5">
                <div>
                  {selectedIdentityDate ? (
                    <PAIconCheckPass />
                  ) : (
                    <div className="h-5 w-5 cursor-pointer bg-white rounded-full border border-[#86888B80]"></div>
                  )}
                </div>

                <div className="text-sm flex flex-col gap-2">
                  <span className="font-medium">
                    Add National ID card/ NIN/ Driver’s license{" "}
                  </span>

                  <span className="text-[#7A7A7A]">
                    It is essential to upload one of the above for
                    identification and security <br /> purposes
                  </span>
                </div>
              </div>

              <div>
                {selectedIdentityDate ? (
                  <span className="text-xs text-[#222]">
                    {selectedIdentityDate}
                  </span>
                ) : (
                  <span
                    onClick={() => setIdentityDocument(true)}
                    className=" py-2 px-2.5 w-fit bg-[#F0EFF1] rounded-[5px] gap-4 flex items-center cursor-pointer"
                  >
                    <p className="font-medium text-[10px] px-2">Add Document</p>
                    <PAIconNewArrowRight />
                  </span>
                )}
              </div>
            </div>

            <div className="flex justify-between">
              <div className="flex gap-5">
                <div>
                  {selectedUtilityDate ? (
                    <PAIconCheckPass />
                  ) : (
                    <div className="h-5 w-5 cursor-pointer bg-white rounded-full border border-[#86888B80]"></div>
                  )}
                </div>

                <div className="text-sm flex flex-col gap-2">
                  <span className="font-medium">Add a Utility Bill</span>

                  <span className="text-[#7A7A7A]">
                    It is essential to upload your utility bill so as to confirm
                    your address
                  </span>
                </div>
              </div>

              <div>
                {selectedUtilityDate ? (
                  <span className="text-xs text-[#222]">
                    {selectedUtilityDate}
                  </span>
                ) : (
                  <span
                    onClick={() => setUtilityDocument(true)}
                    className=" py-2 px-2.5 w-fit bg-[#F0EFF1] rounded-[5px] gap-4 flex items-center cursor-pointer"
                  >
                    <p className="font-medium text-[10px] px-2">Add Document</p>
                    <PAIconNewArrowRight />
                  </span>
                )}
              </div>
            </div>

            <div className="flex justify-between">
              <div className="flex gap-5">
                <div>
                  {selectedCacDate ? (
                    <PAIconCheckPass />
                  ) : (
                    <div className="h-5 w-5 cursor-pointer bg-white rounded-full border border-[#86888B80]"></div>
                  )}
                </div>

                <div className="text-sm flex flex-col gap-2">
                  <span className="font-medium">Add a CAC document</span>

                  <span className="text-[#7A7A7A]">
                    It is essential to upload your CAC document for
                    identification purposes
                  </span>
                </div>
              </div>

              <div>
                {selectedCacDate ? (
                  <span className="text-xs text-[#222]">{selectedCacDate}</span>
                ) : (
                  <span
                    onClick={() => setCacDocument(true)}
                    className=" py-2 px-2.5 w-fit bg-[#F0EFF1] rounded-[5px] gap-4 flex items-center cursor-pointer"
                  >
                    <p className="font-medium text-[10px] px-2">Add Document</p>
                    <PAIconNewArrowRight />
                  </span>
                )}
              </div>
            </div>
          </section>
        </main>
        {selectedUtilityDate &&
          selectedIdentityDate &&
          selectedCacDate &&
          ninSubmitted && (
            <div className="w-full pt-10 flex justify-center">
              <div
                onClick={() => handleBackToCompleteSetup()}
                className="flex cursor-pointer gap-1"
              >
                <span className=" text-primaryColor font-bold text-xs underline">
                  Back to complete setup
                </span>

                <PAIconNewArrowRight />
              </div>
            </div>
          )}
      </div>
      {identityDocument && (
        <PersonalDocumentModal
          setSuccessModal={setSuccessModal}
          setSuccessMessage={setSuccessMessage}
          selectedFileName={selectedIdentityFileName}
          setSelectedFileName={setSelectedIdentityFileName}
          setDate={setSelectedIdentityDate}
          onCloseModal={() => {
            setIdentityDocument(false);
            setSelectedIdentityFileName(null);
          }}
          onCloseUpload={() => {
            setIdentityDocument(false);
          }}
        />
      )}
      {utilityDocument && (
        <GeneralDocumentModal
          selectedFileName={selectedUtilityFileName}
          setSelectedFileName={setSelectedUtilityFileName}
          onCloseModal={() => {
            setUtilityDocument(false);
            setSelectedUtilityFileName(null);
          }}
          onCloseUpload={() => setUtilityDocument(false)}
          onAction={() => setSubmitUtilityModal(true)}
        />
      )}
      {cacDocument && (
        <GeneralDocumentModal
          selectedFileName={selectedCacFileName}
          setSelectedFileName={setSelectedCacFileName}
          onCloseModal={() => {
            setCacDocument(false);
            setSelectedCacFileName(null);
          }}
          onCloseUpload={() => setCacDocument(false)}
          onAction={() => setSubmitCacModal(true)}
        />
      )}
      {ninInfo && (
        <NinModal
          isOpen={ninInfo}
          setIsOpen={setNinInfo}
          nin={nin}
          setNin={setNin}
          onSubmit={handleSubmitNin}
        />
      )}

      {ninValidated && (
        <NINVerifiedModal isOpen={ninValidated} setIsOpen={setNinValidated} />
      )}
      {/* Accept Modals */}
      <AnimatePresence>
        {submitUtilityModal && (
          <ViewDocumentModal
            onAction={() => {
              setUtilityDocument(false);
              handleUploadUtilityDocuments();
            }}
            onClose={() => setSubmitUtilityModal(false)}
          >
            <span className=" text-center">
              Are you sure you would like to upload selected document?
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>
      <SuccessModal
        isOpen={successModal}
        onClose={() => setSuccessModal(false)}
        message={successMessage}
      />
      <AnimatePresence>
        {submitCacModal && (
          <ViewDocumentModal
            onAction={() => {
              setCacDocument(false);
              handleUploadCacDocuments();
            }}
            onClose={() => setSubmitCacModal(false)}
          >
            <span className=" text-center">
              Are you sure you would like to upload selected document?
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>
    </>
  );
}

export default KYC;
