import { PAIconInventoryArrowLeft } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { useNavigate } from "react-router-dom";
import Card from "shared/Card";
import TableHeader from "shared/tableHeader/TableHeader";
import ThreeDots from "../../../assets/images/svgs/option.svg";
import Table from "layout/table/Table";

const data = [
    {
      id: 1,
      name: "John Doe",
      company: "Acme Corp",
      email: "john.doe@acmecorp.com",
      phone: "+1234567890",
      discount: "2.6%",
      status: "Approved",
    },
    {
      id: 2,
      name: "Jane Smith",
      company: "Smith Industries",
      email: "jane.smith@smithind.com",
      phone: "+9876543210",
      discount: "2.6%",
      status: "Pending",
    },
    {
      id: 3,
      name: "Robert Johnson",
      company: "Johnson Enterprises",
      email: "robert.johnson@johnsonent.com",
      phone: "+1029384756",
      discount: "2.6%",
      status: "Approved",
    },
    {
      id: 4,
      name: "Emily Davis",
      company: "Davis Solutions",
      email: "emily.davis@davissol.com",
      phone: "+5647382910",
      discount: "2.6%",
      status: "Declined",
    },
    {
      id: 5,
      name: "Michael Brown",
      company: "Brown & Co.",
      email: "michael.brown@brownco.com",
      phone: "+0192837465",
      discount: "2.6%",
      status: "Pending",
    },
  ];
  

const DirectFundingRequestFlow = () => {
  const navigate = useNavigate();

  function handleNavigate() {
    navigate(-1);
  }

  const onRowClicked = (row) => {
    let path = "/direct-funding-invoice-details";
    // dispatch(getAssociatedBuyerId(row?.id));
    navigate(path);
  };


  const column = [
    {
      name: "Name",
      selector: (row) => row?.name,
    },
    {
      name: "Company",
      selector: (row) => row?.company,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
    },
    {
      name: "Phone",
      selector: (row) => row?.phone,
    },
    {
      name: "Discount Rate",
      selector: (row) => row?.discount,
    },
    {
        cell: (row) => (
          <div className="relative">
            <img
              src={ThreeDots}
              alt="Options"
              // onClick={() => handleThreeDotsClick(row, row?.email)} 
              className="cursor-pointer"
            />
          </div>
        )
    },
  ];
  return (
    <DashboardLayout
      section="Invoice"
      subHeader="Here is an overview of your invoices"
    >
      <div
        onClick={handleNavigate}
        className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
      >
        <PAIconInventoryArrowLeft />
        <p className="mt-[2px] text-sm font-normal text-lightGray">Go Back</p>
      </div>
      <Card className="p-[24px] rounded-[10px]">
      <div>
        <TableHeader
           total={`All funders`}
           df="true"
           details="Access contact details of the associated funders"
        />
      </div>
      <div className="p-[2px] bg-white rounded-b-[10px]">
        <Table
          columns={column}
          pointer
          data={data}
           onRowClicked={onRowClicked}
        />
      </div>
      </Card>
    </DashboardLayout>
  );
};
export default DirectFundingRequestFlow;
