import { PAIconArrowLeftMini } from "assets/images/svgs";

import { AnimatePresence } from "framer-motion";
import DashboardLayout from "layout/dashboardlayout";
import { useNavigate, useParams } from "react-router-dom";
import { formatCapitaliseString } from "utilities/helper/formatCapitaliseString";
import EditWorkflowInvoicing from "./actions/EditWorkflowInvoicing";
import EditWorkflowEarlyPayment from "./actions/EditWorkflowEarlyPayment";
import EditWorkflowOpenMarket from "./actions/EditWorkflowOpenMarket";
import EditWorkflowEarlyOffers from "./actions/EditWorkflowEarlyOffers";
import EditWorkflowDirectFunding from "./actions/EditWorkflowDirectFunding";
import EditWorkflowInvoicingSupplier from "../../supplier/components/actions/EditWorkflowInvoicing";
import EditWorkflowEarlyPaymentSupplier from "../../supplier/components/actions/EditWorkflowEarlyPayment";
import EditWorkflowEarlyOffersSupplier from "../../supplier/components/actions/EditWorkflowEarlyOffers";
import EditWorkflowDirectFundingSupplier from "../../supplier/components/actions/EditWorkflowDirectFunding";
import EditWorkflowOpenMarketSupplier from "../../supplier/components/actions/EditWorkflowOpenMarket";
import EditWorkflowInvoicingFunder from "../../funder/components/actions/EditWorkflowInvoicing";
import EditWorkflowEarlyPaymentFunder from "../../funder/components/actions/EditWorkflowEarlyPayment";
import EditWorkflowEarlyOffersFunder from "../../funder/components/actions/EditWorkflowEarlyOffers";
import EditWorkflowDirectFundingFunder from "../../funder/components/actions/EditWorkflowDirectFunding";
import EditWorkflowOpenMarketFunder from "../../funder/components/actions/EditWorkflowOpenMarket";
import LoadingScreen from "shared/LoadingScreen";
import { useEffect, useState } from "react";

function EditWorkflow() {
  const { actionType, player } = useParams();
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLoader(true);

    setTimeout(() => {
      setLoader(false);
    }, 1000);
  }, []);

  let workflowAction;

  switch (actionType) {
    case "invoicing":
      workflowAction =
        player === "buyer" ? (
          <EditWorkflowInvoicing />
        ) : player === "supplier" ? (
          <EditWorkflowInvoicingSupplier />
        ) : (
          <EditWorkflowInvoicingFunder />
        );
      break;
    case "early_payment_request":
      workflowAction =
        player === "buyer" ? (
          <EditWorkflowEarlyPayment />
        ) : player === "supplier" ? (
          <EditWorkflowEarlyPaymentSupplier />
        ) : (
          <EditWorkflowEarlyPaymentFunder />
        );
      break;
    case "pfp":
      workflowAction =
        player === "buyer" ? (
          <EditWorkflowEarlyOffers />
        ) : player === "supplier" ? (
          <EditWorkflowEarlyOffersSupplier />
        ) : (
          <EditWorkflowEarlyOffersFunder />
        );
      break;
    case "direct_funding":
      workflowAction =
        player === "buyer" ? (
          <EditWorkflowDirectFunding />
        ) : player === "supplier" ? (
          <EditWorkflowDirectFundingSupplier />
        ) : (
          <EditWorkflowDirectFundingFunder />
        );

      break;

    case "open_market":
      workflowAction =
        player === "buyer" ? (
          <EditWorkflowOpenMarket />
        ) : player === "supplier" ? (
          <EditWorkflowOpenMarketSupplier />
        ) : (
          <EditWorkflowOpenMarketFunder />
        );
      break;
    default:
      workflowAction = <EditWorkflowInvoicing />;
  }

  return (
    <DashboardLayout
      section="Settings"
      subHeader="Manage your account all in one place"
    >
      <div className="bg-white min-h-full flex flex-col gap-11 rounded-lg py-8 px-7">
        <header className="flex flex-col gap-4">
          <span
            onClick={() => navigate(-1)}
            className="flex gap-1 items-center cursor-pointer w-fit"
          >
            <PAIconArrowLeftMini />
            <span className="text-[#7A7A7A]">back</span>
          </span>
          <div className="flex flex-col">
            <span className="font-bold text-[18px]">
              {formatCapitaliseString(actionType)}
            </span>
            <span className="text-sm font-medium text-[#959595]">
              Workflow approval for{" "}
              {formatCapitaliseString(actionType).toLowerCase()}
            </span>
          </div>
        </header>

        <main className="text-sm h-full">
          {loader ? (
            <LoadingScreen />
          ) : (
            <section>
              <div className="overflow-x-auto">
                <div className="min-w-full  bg-white rounded-lg">
                  <header className="flex border-t w-full border-t-[#eee] border-b border-b-[#eee]">
                    <div className="text-xs w-[25%] py-2 px-4 bg-[#F9F9F9] text-[#7A7A7A] font-semibold text-left">
                      PROCESS
                    </div>
                    <div className="text-xs w-[25%] py-2 px-4 bg-[#F9F9F9] text-[#7A7A7A] font-semibold text-left">
                      STATUS
                    </div>
                    <div className="text-xs w-1/2  py-2 px-4  bg-[#F9F9F9] text-[#7A7A7A] font-semibold text-left">
                      APPROVAL LEVELS
                    </div>
                  </header>

                  <>{workflowAction}</>
                </div>
              </div>
            </section>
          )}
        </main>
      </div>

      <AnimatePresence>
        {/* {saveModalOpen && (
          <ViewDocumentModal
            onAction={() => {
              // handleCreateWorkflow();
            }}
            onClose={() => {
              // setSaveModalOpen(false);
            }}
          >
            <span className="text-[#7A7A7A]">
              Are you sure you would like to create this workflow?
            </span>
          </ViewDocumentModal>
        )} */}
      </AnimatePresence>
    </DashboardLayout>
  );
}

export default EditWorkflow;
