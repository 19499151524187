import { motion } from "framer-motion";
import {
  PAIconCocacola,
  PAIconInvShop,
  PAIconLockYellow,
  PAIconModelOne,
} from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import { formatCurrency } from "utilities/helper/formatters";
import { formatCapitaliseString } from "utilities/helper/formatCapitaliseString";
import { useEffect, useState } from "react";
import useCountdown from "utilities/helper/useCountdown";

function OpenMarketTabCard({
  lockInvoice,
  id,
  setId,
  invoiceNumber = "N/A",
  buyerCompanyName = "N/A",
  supplierCompanyName = "N/A",
  description = "N/A",
  invoiceAmount = "N/A",
  lastModifiedDate = "N/A",
  fundRequestDate = "N/A",
  numberOfBids = "0",
  setRefNum,
  status,
  lockRate,
  setDiscountRate,
  dueDate,
}) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    setTimeout(() => setIsLoading(false), 1000);
  }, []);

  const { timeLeft, isTimeUnderOneHour } = useCountdown(dueDate);

  const formatDate = (dateArray) => {
    if (!Array.isArray(dateArray) || dateArray.length !== 3) return "";
    const date = new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}`;
  };

  return (
    <motion.div
      onClick={() => navigate(id)}
      whileTap={{ scale: 0.97 }}
      style={{ boxShadow: "0px 4px 12px 5px rgba(0, 0, 0, 0.04)" }}
      className="bg-white cursor-pointer flex flex-col gap-7 px-6 py-[25px] rounded-[10px]"
    >
      <div className="flex justify-between">
        <div className="flex gap-9">
          <div className="flex gap-6 items-center">
            <PAIconInvShop />
            <div className="flex flex-col gap-0.5">
              <span className="text-sm font-[500]">{invoiceNumber}</span>
              <span className=" text-[10px] text-[#959595]">
                Invoice number
              </span>
            </div>
          </div>

          <div className="hidden sm:flex items-center gap-16">
            <div className="flex items-center gap-2 ">
              <PAIconModelOne />
              <div className="flex flex-col gap-0.5">
                <span className="text-sm font-[500]">{buyerCompanyName}</span>
                <span className=" text-[10px] text-[#959595]">Buyer</span>
              </div>
            </div>

            <div className="flex items-center gap-2 ">
              <PAIconCocacola />
              <div className="flex flex-col gap-0.5">
                <span className="text-sm font-[500]">
                  {supplierCompanyName}
                </span>
                <span className=" text-[10px] text-[#959595]">Supplier</span>
              </div>
            </div>

            <div className="flex items-center gap-2 ">
              <div className="flex flex-col gap-0.5">
                <span
                  className={`text-sm ${
                    status === "POSTED"
                      ? "text-primaryColor"
                      : "text-secondaryColor"
                  } font-medium`}
                >
                  {formatCapitaliseString(status)}
                </span>
                <span className=" text-[10px] text-[#959595]">
                  Status of invoice
                </span>
              </div>
            </div>
          </div>
        </div>

        <motion.button
          onClick={(event) => {
            event.stopPropagation();
            setDiscountRate("");
            setRefNum(invoiceNumber);
            setId(id);
            lockInvoice(true);
          }}
          whileTap={{ scale: 0.9 }}
          className="flex border border-primaryColor text-sm justify-center gap-2 bg-white text-primaryColor rounded-[5px] items-center px-2.5 py-[5px] "
        >
          <span className="sm:hidden md:inline">Bid</span> <PAIconLockYellow />
          <span className=" pl-2 border-l border-primaryColor">
            <div className="flex flex-col justify-center">
              <span
                className={`text-sm font-bold ${
                  isTimeUnderOneHour ? "text-red-500" : ""
                }`}
              >
                {(timeLeft.days > 0 ||
                  timeLeft.hours > 0 ||
                  timeLeft.minutes > 0 ||
                  timeLeft.seconds > 0) &&
                formatCapitaliseString(status) !== "Cancelled" ? (
                  `${timeLeft.days}d:${timeLeft.hours}h:${timeLeft.minutes}m:${timeLeft.seconds}s`
                ) : isLoading ? (
                  <span className="text-secondaryColor">Fetching...</span>
                ) : formatCapitaliseString(status) === "Cancelled" ? (
                  <span className="text-red">Cancelled</span>
                ) : (
                  <span className="text-red">Expired</span>
                )}
              </span>
              <span className="text-[#959595] text-[10px]">
                Count down to payment date
              </span>
            </div>
          </span>
        </motion.button>
      </div>

      <div className="flex justify-between">
        <div className="text-sm flex gap-3">
          <span className=" text-black">Lock Rate:</span>
          <span className=" text-[#959595]">{lockRate ? lockRate : "-"}%</span>
        </div>

        <div className="text-sm flex gap-3">
          <span className=" text-black">Request Amount:</span>
          <span className=" text-[#959595]">
            {formatCurrency(invoiceAmount)}
          </span>
        </div>

        <div className="text-sm hidden semi-lg:flex gap-3">
          <span className=" text-black">Request Date:</span>
          <span className=" text-[#959595]">{fundRequestDate}</span>
        </div>

        <div className="text-sm hidden semi-lg:flex gap-3">
          <span className=" text-black">Payment Date:</span>
          <span className=" text-[#959595]">{formatDate(dueDate)}</span>
        </div>
      </div>
    </motion.div>
  );
}

export default OpenMarketTabCard;
