import { PAIconCancel } from "assets/images/svgs";
import React from "react";

const BidInvoiceModal = ({
  isOpen,
  onClose,
  handleYes,
  discountRate,
  handleSetDiscountRate,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <>
      <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-[1000]">
        <div className="bg-white p-5 rounded-[10px] shadow-md flex flex-col items-center  md:w-[459px] lg:w-[459px]  pt-8">
          <header className="flex justify-between border-b-4 border-whisper200 pb-[16px] w-full">
            <p class="text-[#222222] text-base font-bold ">Bid Details</p>
            <div
              onClick={onClose}
              className="flex justify-between items-center gap-x-[10px] cursor-pointer"
            >
              <PAIconCancel className="w-[8px]" />

              <p
                class="text-[#565656] text-sm
font-semibold"
              >
                Close
              </p>
            </div>
          </header>

          {/* nest */}

          <div className="bg-white rounded-[10px]   w-full">
            {/* <FundDetailSide /> */}
            {/* second */}
            <div className=" pb-3 px-2">
              {/* search section */}
              <div class="w-full mt-2">
                {" "}
                <div class="text-[#222222] text-sm font-medium  ">
                  Please input your bid discount rate. The buyer's benchmark
                  rate is 5.6%. Your initial bid can match or be lower than this
                  rate, but it must not exceed the benchmark specified in the
                  request.
                </div>
                <span className="text-[#07593d] text-sm font-extrabold ">
                  Current lock rate: 2.7%
                  <br />
                </span>
                <span className="text-[#f08000] text-sm font-medium ">
                  You can cancel your bid within 30 seconds of placing it.
                </span>
              </div>
              <div>
                <input
                  type="text"
                  placeholder="Enter discount rate"
                  value={discountRate}
                  onChange={handleSetDiscountRate}
                  className="px-3 w-full flex items-center text-black focus:outline-none h-[47px]  rounded-[5px] border border-[#07593d] mt-6  text-sm font-medium  "
                />
              </div>
              {/* button section */}
              <button
                class="h-[47px] px-2.5 py-[15px] bg-[#07593d] rounded-[5px] justify-center items-center gap-2.5 inline-flex mt-8 w-full cursor-pointer"
                onClick={handleYes}
              >
                <div class="text-white  text-sm font-medium ">
                  Bid on request
                </div>
              </button>
            </div>

            {/* search section */}

            {/* stops here */}
          </div>
        </div>
      </div>
    </>
  );
};

export default BidInvoiceModal;
