import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import OpenMarketHeader from "./components/OpenMarketHeader";
import AllInvoices from "./components/AllInvoices";
import AcceptedInvoice from "./components/AcceptedInvoices";
// import LockedInvoice from "./components/LockedInvoices";
import InvoiceSummary from "./components/InvoiceSummary";
import PendingConfirmation from "./components/PendingConfirmation";

function SupplierOpenMarket() {
  const [acceptedQuery, setAcceptedQuery] = useState("");

  const [pendingConfirmationQuery, setPendingConfirmationQuery] = useState("");
  // sessionStorage.getItem("searchQuery") || ""
  const [allInvoiceQuery, setAllInvoiceQuery] = useState("");
  // sessionStorage.getItem("searchQuery") || ""

  const [requestQuery, setRequestQuery] = useState("");
  // sessionStorage.getItem("searchQuery") || ""

  const [lockedInvoiceQuery, setLockedInvoiceQuery] = useState("Wale");

  const [activeTab, setActiveTab] = useState(
    parseInt(sessionStorage.getItem("activeTabSupplier")) || 1
  );

  return (
    <div className="flex gap-4 flex-col">
      <>
        {/* <div className=" w-full flex justify-between items-center rounded-[5px] py-2.5 pl-5 pr-2 bg-white ">
          <div>Open Market</div>

         
        </div> */}
        <OpenMarketHeader
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          searchQuery={
            activeTab === 1
              ? pendingConfirmationQuery
              : activeTab === 2
              ? allInvoiceQuery
              : activeTab === 3
              ? acceptedQuery
              : activeTab === 4
              ? lockedInvoiceQuery
              : requestQuery
          }
          setSearchQuery={
            activeTab === 1
              ? setPendingConfirmationQuery
              : activeTab === 2
              ? setAllInvoiceQuery
              : activeTab === 3
              ? setAcceptedQuery
              : activeTab === 4
              ? setLockedInvoiceQuery
              : setRequestQuery
          }
        />

        <main className="w-full">
          <AnimatePresence mode="wait">
            {activeTab === 1 ? (
              <PendingConfirmation
                pendingConfirmationQuery={pendingConfirmationQuery}
                setPendingConfirmationQuery={setPendingConfirmationQuery}
              />
            ) : activeTab === 2 ? (
              <AllInvoices
                allInvoiceQuery={allInvoiceQuery}
                setAllInvoiceQuery={setAllInvoiceQuery}
              />
            ) : activeTab === 3 ? (
              <AcceptedInvoice
                setAcceptedQuery={setAcceptedQuery}
                acceptedQuery={acceptedQuery}
              />
            ) : activeTab === 4 ? (
              <InvoiceSummary requestQuery={requestQuery} />
            ) : (
              ""
            )}
          </AnimatePresence>
        </main>
      </>
    </div>
  );
}

export default SupplierOpenMarket;
