import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  transactionDetails,
  createExternalAccount,
  createFundTransfer,
  createVirtualAccount,
  createVirtualCard,
  externalAccountDelete,
  externalAccountDetails,
  externalAccountEdit,
  fetchAllExternalAccounts,
  fetchAllVirtualAccounts,
  fetchAllVirtualCards,
  getAccountOverview,
  getAllTransactions,
  virtualAccountDelete,
  virtualAccountDetails,
  virtualAccountEdit,
  virtualCardDelete,
  virtualCardDetails,
  virtualCardEdit,
  getSupplierAccountOverview,
  getSingleSupplierAccountOverview,
  getSingleAccountOverview,
  getAllSingleTransactions,
  deactivateVirtualAccount,
} from "appstate/api/wallet";

const initialState = {
  tableLoading: false,
  isLoading: false,
  transactions: [],
  singleTransactions: [],
  transactionDetails: null,
  transactionId: null,
  virtualAccount: null,
  allVirtualAccountsData: [],
  walletOverviewData: null,
  singleWalletOverviewData: null,
  singleSupplierWalletOverviewData: null,
  virtualAccountDetails: null,
  virtualAccountId: null,
  externalAccount: null,
  allExternalAccountsData: [],
  externalAccountDetails: null,
  externalAccountId: null,
  virtualCard: null,
  allVirtualCardsData: [],
  virtualCardId: null,
  virtualCardDetails: null,
  fundTransfer: null,
  selectedAccounts: null,
  secondSelectedAccounts: null,
  transferDetails: null,
  externalAccountFormData: null,
  virtualAccountFormData: null,
  withdrawalFormData: null,
};

export const allTransactions = createAsyncThunk(
  "allTransactions",
  getAllTransactions
);

export const allSingleTransactions = createAsyncThunk(
  "allSingleTransactions",
  getAllSingleTransactions
);

export const eachTransaction = createAsyncThunk(
  "eachTransaction",
  transactionDetails
);

export const postVirtualAccount = createAsyncThunk(
  "postVirtualAccount",
  createVirtualAccount
);

export const toggleDeactivateVirtualAccount = createAsyncThunk(
  "toggleDeactivateVirtualAccount",
  deactivateVirtualAccount
);

export const allVirtualAccounts = createAsyncThunk(
  "allvirtualAccounts",
  fetchAllVirtualAccounts
);

export const walletOverview = createAsyncThunk(
  "walletOverview",
  getAccountOverview
);

export const supplierWalletOverview = createAsyncThunk(
  "supplierWalletOverview",
  getSupplierAccountOverview
);

export const singleWalletOverview = createAsyncThunk(
  "singleWalletOverview",
  getSingleAccountOverview
);

export const singleSupplierWalletOverview = createAsyncThunk(
  "singleSupplierWalletOverview",
  getSingleSupplierAccountOverview
);

export const eachVirtualAccount = createAsyncThunk(
  "eachVirtualAccount",
  virtualAccountDetails
);

export const editVirtualAccount = createAsyncThunk(
  "editVirtualAccount",
  virtualAccountEdit
);

export const deleteVirtualAccount = createAsyncThunk(
  "deleteVirtualAccount",
  virtualAccountDelete
);

export const postExternalAccount = createAsyncThunk(
  "postExternalAccount",
  createExternalAccount
);

export const allExternalAccounts = createAsyncThunk(
  "allExternalAccounts",
  fetchAllExternalAccounts
);

export const deleteExternalAccount = createAsyncThunk(
  "deleteExternalAccount",
  externalAccountDelete
);

export const eachExternalAccount = createAsyncThunk(
  "eachExternalAccount",
  externalAccountDetails
);

export const editExternalAccount = createAsyncThunk(
  "editExternalAccount",
  externalAccountEdit
);

export const postVirtualCard = createAsyncThunk(
  "postVirtualCard",
  createVirtualCard
);

export const allVirtualCards = createAsyncThunk(
  "allVirtualCards",
  fetchAllVirtualCards
);

export const deleteVirtualCard = createAsyncThunk(
  "deleteVirtualCard",
  virtualCardDelete
);

export const eachVirtualCard = createAsyncThunk(
  "eachVirtualCard",
  virtualCardDetails
);

export const editVirtualCard = createAsyncThunk(
  "editVirtualCard",
  virtualCardEdit
);

export const postFundTransfer = createAsyncThunk(
  "postFundTransfer",
  createFundTransfer
);

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    getTransactionId: (state, action) => {
      state.transactionId = action.payload;
    },
    getVirtualAccountId: (state, action) => {
      state.virtualAccountId = action.payload;
    },
    getVirtualCardId: (state, action) => {
      state.virtualCardId = action.payload;
    },
    setSelectedAccounts: (state, action) => {
      state.selectedAccounts = action.payload;
    },
    setSecondSelectedAccounts: (state, action) => {
      state.secondSelectedAccounts = action.payload;
    },
    setTransferDetails: (state, action) => {
      state.transferDetails = action.payload;
    },
    setExternalAccountFormData: (state, action) => {
      state.externalAccountFormData = action.payload;
    },
    setVirtualAccountFormData: (state, action) => {
      state.virtualAccountFormData = action.payload;
    },
    setwithdrawalFormData: (state, action) => {
      state.withdrawalFormData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(allTransactions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allTransactions.fulfilled, (state, action) => {
      state.isLoading = false;
      state.transactions = action.payload;
    });
    builder.addCase(allTransactions.rejected, (state) => {
      state.isLoading = false;
    });
    // SINGLE TRANSACTIONS
    builder.addCase(allSingleTransactions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allSingleTransactions.fulfilled, (state, action) => {
      state.isLoading = false;
      state.singleTransactions = action.payload;
    });
    builder.addCase(allSingleTransactions.rejected, (state) => {
      state.isLoading = false;
    });
    // get each transaction details
    builder.addCase(eachTransaction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(eachTransaction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.transactionDetails = action.payload;
    });
    builder.addCase(eachTransaction.rejected, (state) => {
      state.isLoading = false;
    });
    // create virtual account
    builder.addCase(postVirtualAccount.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postVirtualAccount.fulfilled, (state, action) => {
      state.isLoading = false;
      state.virtualAccount = action.payload;
    });
    builder.addCase(postVirtualAccount.rejected, (state) => {
      state.isLoading = false;
    });
    // deactivate virtual account
    builder.addCase(toggleDeactivateVirtualAccount.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(toggleDeactivateVirtualAccount.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(toggleDeactivateVirtualAccount.rejected, (state) => {
      state.isLoading = false;
    });
    // all virtual accounts
    builder.addCase(allVirtualAccounts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allVirtualAccounts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allVirtualAccountsData = action.payload;
    });
    builder.addCase(allVirtualAccounts.rejected, (state) => {
      state.isLoading = false;
    });
    // account overview
    builder.addCase(walletOverview.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(walletOverview.fulfilled, (state, action) => {
      state.isLoading = false;
      state.walletOverviewData = action.payload;
    });
    builder.addCase(walletOverview.rejected, (state) => {
      state.isLoading = false;
    });
    // supplier account overview
    builder.addCase(supplierWalletOverview.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(supplierWalletOverview.fulfilled, (state, action) => {
      state.isLoading = false;
      state.supplierWalletOverviewData = action.payload;
    });
    builder.addCase(supplierWalletOverview.rejected, (state) => {
      state.isLoading = false;
    });

    // single account overview
    builder.addCase(singleWalletOverview.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(singleWalletOverview.fulfilled, (state, action) => {
      state.isLoading = false;
      state.singleWalletOverviewData = action.payload;
    });
    builder.addCase(singleWalletOverview.rejected, (state) => {
      state.isLoading = false;
    });

    // single supplier account overview
    builder.addCase(singleSupplierWalletOverview.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(singleSupplierWalletOverview.fulfilled, (state, action) => {
      state.isLoading = false;
      state.singleSupplierWalletOverviewData = action.payload;
    });
    builder.addCase(singleSupplierWalletOverview.rejected, (state) => {
      state.isLoading = false;
    });

    // get each account details
    builder.addCase(eachVirtualAccount.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(eachVirtualAccount.fulfilled, (state, action) => {
      state.isLoading = false;
      state.virtualAccountDetails = action.payload;
    });
    builder.addCase(eachVirtualAccount.rejected, (state) => {
      state.isLoading = false;
    });
    // edit account details
    builder.addCase(editVirtualAccount.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editVirtualAccount.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(editVirtualAccount.rejected, (state) => {
      state.isLoading = false;
    });
    // // // // // // // // // // DELETE VA // // // /// // // // // // //
    builder.addCase(deleteVirtualAccount.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteVirtualAccount.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteVirtualAccount.rejected, (state) => {
      state.isLoading = false;
    });
    // create External Account
    builder.addCase(postExternalAccount.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postExternalAccount.fulfilled, (state, action) => {
      state.isLoading = false;
      state.externalAccount = action.payload;
    });
    builder.addCase(postExternalAccount.rejected, (state) => {
      state.isLoading = false;
    });
    // all external accounts
    builder.addCase(allExternalAccounts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allExternalAccounts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allExternalAccountsData = action.payload;
    });
    builder.addCase(allExternalAccounts.rejected, (state) => {
      state.isLoading = false;
    });
    // // // // // // // // // // DELETE EA // // // /// // // // // // //
    builder.addCase(deleteExternalAccount.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteExternalAccount.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteExternalAccount.rejected, (state) => {
      state.isLoading = false;
    });
    // get each external account details
    builder.addCase(eachExternalAccount.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(eachExternalAccount.fulfilled, (state, action) => {
      state.isLoading = false;
      state.externalAccountDetails = action.payload;
    });
    builder.addCase(eachExternalAccount.rejected, (state) => {
      state.isLoading = false;
    });
    // edit external account details
    builder.addCase(editExternalAccount.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editExternalAccount.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(editExternalAccount.rejected, (state) => {
      state.isLoading = false;
    });
    // create virtual card
    builder.addCase(postVirtualCard.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postVirtualCard.fulfilled, (state, action) => {
      state.isLoading = false;
      state.virtualCard = action.payload;
    });
    builder.addCase(postVirtualCard.rejected, (state) => {
      state.isLoading = false;
    });
    // all virtual cards
    builder.addCase(allVirtualCards.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allVirtualCards.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allVirtualCardsData = action.payload;
    });
    builder.addCase(allVirtualCards.rejected, (state) => {
      state.isLoading = false;
    });
    // // // // // // // // // // DELETE VC // // // /// // // // // // //
    builder.addCase(deleteVirtualCard.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteVirtualCard.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteVirtualCard.rejected, (state) => {
      state.isLoading = false;
    });
    // get each card details
    builder.addCase(eachVirtualCard.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(eachVirtualCard.fulfilled, (state, action) => {
      state.isLoading = false;
      state.virtualCardDetails = action.payload;
    });
    builder.addCase(eachVirtualCard.rejected, (state) => {
      state.isLoading = false;
    });
    // edit card details
    builder.addCase(editVirtualCard.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editVirtualCard.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(editVirtualCard.rejected, (state) => {
      state.isLoading = false;
    });
    // create virtual account
    builder.addCase(postFundTransfer.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postFundTransfer.fulfilled, (state, action) => {
      state.isLoading = false;
      state.fundTransfer = action.payload;
    });
    builder.addCase(postFundTransfer.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const {
  getTransactionId,
  getVirtualAccountId,
  getVirtualCardId,
  setSelectedAccounts,
  setSecondSelectedAccounts,
  setTransferDetails,
  setExternalAccountFormData,
  setVirtualAccountFormData,
  setwithdrawalFormData,
} = walletSlice.actions;

export default walletSlice.reducer;
