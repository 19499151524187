import React, { useState } from "react";

import RequestDetails from "./RequestDetails";
import BidHistory from "./BidHistory";

const MoreInvoiceIdSegment = () => {
  const [activeTab, setActiveTab] = useState("NumberOfBids");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "NumberOfBids":
        return <BidHistory />;
      case "FundersInvited":
        return <RequestDetails />;
      // case "ViewByFunders":
      //   return <ViewByFunders />;
      default:
        return null;
    }
  };

  return (
    <div className=" gap-2">
      <div className="  ">
        <div className="text-sm ">
          <div className="flex  gap-5  flex-row px-6  ">
            <div className=" py-4">
              <p
                className={`cursor-pointer  py-1 ${
                  activeTab === "NumberOfBids"
                    ? " border-[#F08000]   text-amber-600 text-xs font-bold border-b-2 "
                    : "text-gray500  text-neutral-400 text-xs font-normal"
                }`}
                onClick={() => handleTabClick("NumberOfBids")}
              >
                Bid history
              </p>
            </div>

            <div className="py-4">
              <p
                className={`cursor-pointer  py-1 ${
                  activeTab === "FundersInvited"
                    ? " border-[#F08000]   text-amber-600 text-xs font-bold border-b-2 "
                    : "text-gray500  text-neutral-400 text-xs font-normal"
                }`}
                onClick={() => handleTabClick("FundersInvited")}
              >
                Request details
              </p>
            </div>
          </div>

          <div className="mt-3">{renderTabContent()}</div>
        </div>
      </div>
    </div>
  );
};

export default MoreInvoiceIdSegment;
