import {
  PAICalenderPickerIcon,
  PAIconArrowLeftGreen,
  PAIconDropdown,
} from "assets/images/svgs";
import WalletCard from "features/transactions/components/WalletCard";
// import WithdrawOption from "features/wallet(new)/overview/Withdraw";
import DashboardLayout from "layout/dashboardlayout";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import AcceptModal from "shared/AcceptModal";
import PinModal from "shared/PinModal";
import SuccessModal from "./Success";
import FundOptions from "features/wallet(new)/overview/FundAccount";
import FundWalletDetails from "features/wallet(new)/overview/FundAccount/ExternalSource/FundAccountDetails";
import AccountFrom from "features/wallet(new)/overview/FundAccount/VirtualAccount/AccountFrom";
import AccountTo from "features/wallet(new)/overview/FundAccount/VirtualAccount/AccountTo";
import TransferReceipt from "shared/TransferReceipt";
import WithdrawalOptions from "./WithdrawalOption";
import DatePicker from "react-datepicker";
import {
  setSelectedAccounts,
  singleSupplierWalletOverview,
  singleWalletOverview,
  postFundTransfer,
  setTransferDetails,
  toggleDeactivateVirtualAccount,
  allVirtualAccounts,
  allSingleTransactions,
} from "appstate/wallet/walletSlice";
import TransactionTable from "../transactions/TransactionTable";
import toast from "react-hot-toast";

const AccountStatementPage = () => {
  const selectedAccounts = useSelector(
    (state) => state?.wallet?.selectedAccounts
  );
  const secondSelectedAccounts = useSelector(
    (state) => state?.wallet?.secondSelectedAccounts
  );
  const { externalAccountFormData, transferDetails } = useSelector(
    (state) => state?.wallet
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeAccountId, setActiveAccountId] = useState(selectedAccounts?.id);
  const isLoading = useSelector((state) => state.wallet.isLoading);
  // const { accountId } = useParams();

  // const [isWithdrawOpen, setWithdrawOpen] = useState(false);
  const [isDeactivateOpen, setDeactivateOpen] = useState(false);
  const [showDeactivatePinModal, setShowDeactivatePinModal] = useState(false);
  const [isSuccessModal, setShowSuccessModal] = useState(false);
  const [isFundOptionsOpen, setIsFundOptionsOpen] = useState(false);
  const [externalSourceOpen, setExternalSourceOpen] = useState(false);
  const [isAccountFromOpen, setIsAccountFromOpen] = useState(false);
  const [isAccountToOpen, setIsAccountToOpen] = useState(false);
  const [fundAccountPinModal, setFundAccountPinModal] = useState(false);
  const [isTransferReceiptOpen, setIsTransferReceiptOpen] = useState(false);
  const [withdrawalOptionOpen, setWithdrawalOptionOpen] = useState(false);
  const [startDatee, setStartDate] = useState(null);
  const [endDatee, setEndDate] = useState(null);
  const [isSelectingStartDate, setIsSelectingStartDate] = useState(true);
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const { accountId } = useParams();
  const page = 0;
  const size = 5;
  const startDate = "";
  const endDate = "";
  const searchKey = "";

  const handleDateChange = (date) => {
    if (isSelectingStartDate) {
      setStartDate(date);
      if (endDate && date > endDate) {
        setEndDate(null);
      }
    } else {
      setEndDate(date);
    }
  };

  const navigate = useNavigate();

  const handleDropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // const handleVirtualAccountClick = (id) => {
  //   let path = `/wallet/accountManagement/${id}`;
  //   navigate(path);
  // };
  // const handleVirtualAccountClick = (account) => {
  //   setActiveAccountId(account);
  //   dispatch(setSelectedAccounts(account));
  //   dispatch(allVirtualAccounts())
  //   let path = `/wallet/accountManagement/${account}`;
  //   navigate(path);
  //   console.log(account);
  // };
  const handleVirtualAccountClick = (account) => {
    setActiveAccountId(account.id);
    dispatch(setSelectedAccounts(account));
    let path = `/wallet/accountManagement/${account.id}`;
    navigate(path);
  };

  // const handleWithdrawOpen = () => {
  //   setWithdrawalOptionOpen(true);
  //   // setWithdrawOpen(true);
  // };

  const handleDeactivateOpen = () => {
    setDeactivateOpen(true);
  };

  const handleFundOptionSubmit = () => {
    setIsFundOptionsOpen(false);
    setExternalSourceOpen(true);
  };

  const handleFundVirtualAccountClick = () => {
    setIsAccountFromOpen(true);
    setIsFundOptionsOpen(false);
  };

  const handleAccountFromSubmit = () => {
    setIsAccountToOpen(true);
    setIsAccountFromOpen(false);
  };

  const handleFundVirtualAccountSubmit = () => {
    setIsAccountToOpen(false);
    setFundAccountPinModal(true);
  };

  const handleTransferReceiptClose = () => {
    setIsTransferReceiptOpen(false);
    setFundAccountPinModal(false);
  };

  const handleAccountToBack = () => {
    setIsAccountToOpen(false);
    setIsAccountFromOpen(true);
  };
  // const handleDeactivatePinModal = () => {
  //   setShowDeactivatePinModal(true);
  //   setDeactivateOpen(false);
  // };
  const handleWithdrawClick = () => {
    toast(
      "This feature is awaiting external API and is unavailable and will be available later. Please Kennedy, PM, John do come back.",
      {
        icon: "⚠️",
        style: {
          background: "#333",
          color: "#fff",
        },
      }
    );
  };

  const { userId } = useSelector((state) => state?.auth);
  const roleIndex = userId?.realm_access?.roles?.findIndex((arr) => {
    return (
      arr === "SUPPLIER" ||
      arr === "BUYER" ||
      arr === "FUNDER" ||
      arr === "ADMIN"
    );
  });
  const userRole = userId?.realm_access?.roles[roleIndex]?.toLowerCase();
  const {
    singleWalletOverviewData,
    singleSupplierWalletOverviewData,
    allVirtualAccountsData,
  } = useSelector((state) => state?.wallet);

  const dispatch = useDispatch();

  const walletData =
    userRole === "supplier"
      ? singleSupplierWalletOverviewData
      : singleWalletOverviewData;

  useEffect(() => {
    if (userRole === "supplier") {
      dispatch(singleSupplierWalletOverview({ accountId }));
    } else {
      dispatch(singleWalletOverview({ accountId }));
    }
  }, [dispatch, userRole, accountId]);

  const accountData = Array.isArray(allVirtualAccountsData?.data)
    ? allVirtualAccountsData?.data?.map((account) => ({
        id: account?.id,
        name: account?.accountName,
        accountNumber: account?.accountNumber,
        bankName: account?.provider || "Payedge",
        accountType: "Virtual Account",
        amount: account?.balance,
      }))
    : [];

  const activeAccount = accountData.find(
    (account) => account.id === activeAccountId
  );

  const isActiveAccount =
    userRole === "supplier"
      ? singleSupplierWalletOverviewData?.active
      : singleWalletOverviewData?.active;

  const handleDeactivate = async (pin) => {
    setShowDeactivatePinModal(false);
    const body = {
      accountId: activeAccountId,
      transactionPin: pin,
    };
    dispatch(toggleDeactivateVirtualAccount(body)).then((response) => {
      if (response?.payload?.success) {
        setShowSuccessModal(true);
        setIsSuccessMessage(response?.payload?.data);
        if (userRole === "supplier") {
          dispatch(singleSupplierWalletOverview({ accountId }));
        } else {
          dispatch(singleWalletOverview({ accountId }));
        }
      } else {
        toast.error(response?.payload?.message);
      }
    });
  };

  const handleTransfer = async (pin) => {
    const body = {
      sourceAccountNumber: selectedAccounts?.accountNumber || "",
      destinationAccountNumber: secondSelectedAccounts?.accountNumber || "",
      amount: externalAccountFormData?.amount || 0,
      description: externalAccountFormData?.narration || "",
      transactionPin: pin,
    };

    const pendingToast = toast.loading("Processing your transaction...");

    const data = await dispatch(postFundTransfer(body));

    if (data?.payload?.status === "pending") {
      return;
    }

    if (data?.payload?.status === 200 && data?.payload?.success) {
      dispatch(setTransferDetails(data?.payload));
      toast.success("Transaction successful!");
      setIsTransferReceiptOpen(true);
      if (userRole === "supplier") {
        dispatch(singleSupplierWalletOverview({ accountId }));
      } else {
        dispatch(singleWalletOverview({ accountId }));
      }
      dispatch(
        allSingleTransactions({
          accountId,
          page,
          size,
          startDate,
          endDate,
          searchKey,
        })
      );
      dispatch(allVirtualAccounts());
    } else {
      toast.error(data?.payload?.message);
    }

    toast.dismiss(pendingToast);
  };

  const formatAmount = (amount) => {
    if (!amount) return "0.00";
    const parsedAmount = parseFloat(amount);
    if (isNaN(parsedAmount)) return "0.00";
    return parsedAmount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const cardContents = [
    {
      label: "Available Balance",
      amount: formatAmount(walletData?.availableBalance),
      // percentage: "10",
      // info: "Increase since January",
    },
    {
      label:
        userRole === "buyer" || userRole === "funder"
          ? "Total Liened Balance"
          : "Total Partial Payment",
      amount:
        userRole === "buyer" || userRole === "funder"
          ? formatAmount(walletData?.totalLienedAmount)
          : userRole === "supplier"
          ? formatAmount(walletData?.totalPartialPayment)
          : "",
      // percentage: "40",
      // info: "Increase since January",
    },
    {
      label: "Total Deposit",
      amount: formatAmount(walletData?.totalDeposit),
      // percentage: "30",
      // info: "Increase since January",
    },
    {
      label: "Total Withdrawal",
      amount: formatAmount(walletData?.totalWithdrawal),
      // percentage: "25",
      // info: "Increase since January",
    },
    {
      label: "Total Invoices",
      amount: walletData?.totalInvoices || 0,
      // percentage: "25",
      // info: "Increase since January",
    },
    {
      label:
        userRole === "buyer" || userRole === "funder"
          ? "Total Liened Invoices "
          : "Total Partially Paid Invoices",
      amount:
        userRole === "buyer" || userRole === "funder"
          ? walletData?.totalLienedInvoices || 0
          : userRole === "supplier"
          ? walletData?.partiallyPaidInvoices || 0
          : "",
      // percentage: "30",
      // info: "Increase since January",
    },
    {
      label:
        userRole === "buyer" || userRole === "funder"
          ? "Total Paid Invoices "
          : "Total Fully Paid Invoices",
      amount:
        userRole === "buyer" || userRole === "funder"
          ? walletData?.totalPaidInvoices || 0
          : userRole === "supplier"
          ? walletData?.totalFullyPaidInvoices || 0
          : "",
      // percentage: "25",
      // info: "Increase since January",
    },
  ];

  return (
    <DashboardLayout
      section="Wallet"
      subHeader="Here is an overview of your payments and payment processes"
    >
      <div>
        {/* HEADERS */}
        <header className="rounded-md w-full bg-white mb-4 flex items-center justify-between px-5">
          <Link
            to="/wallet/accountManagement"
            className="flex items-center gap-1 py-3"
          >
            <PAIconArrowLeftGreen />
            <span className="text-primaryColor cursor-pointer">Go Back</span>
          </Link>
          <div className="relative">
            <div
              className="flex items-center justify-between gap-16 text-gray600 text-sm border border-gray p-1 px-2 rounded-t cursor-pointer dropdown-button"
              onClick={handleDropdownClick}
            >
              <div className="flex-grow">
                {
                  accountData.find((account) => account.id === activeAccountId)
                    ?.name
                }{" "}
                -
                {
                  accountData.find((account) => account.id === activeAccountId)
                    ?.accountNumber
                }{" "}
                -
                {
                  accountData.find((account) => account.id === activeAccountId)
                    ?.bankName
                }
              </div>
              <PAIconDropdown />
            </div>
            {isDropdownOpen && (
              <div className="absolute top-full right-0 text-gray600 bg-white border border-gray rounded-b shadow-md text-sm w-full">
                {accountData?.map((item) => (
                  <div
                    key={item.id}
                    className="py-2 px-6 hover:bg-gallery whitespace-break-spaces cursor-pointer"
                    onClick={() => handleVirtualAccountClick(item)}
                  >
                    {item.name} - {item.accountNumber} - {item.bankName}
                  </div>
                ))}
              </div>
            )}
          </div>
        </header>

        {/* ACCOUNT STATEMENT */}
        <div>Account Statement</div>
        <div className="flex justify-between mt-2  flex-wrap gap-4">
          <div className=" flex justify-between space-x-2  flex-wrap gap-2">
            <div class=" h-10  py-2 bg-white rounded flex-col justify-center items-start px-2  ">
              <div class="justify-start items-center gap-3 flex ">
                <div class="w-6 h-6 relative">
                  <PAICalenderPickerIcon />
                </div>
                <div
                  class="text-neutral-600 text-base font-medium w-1/2 "
                  // onClick={() => setIsSelectingStartDate(true)}
                >
                  {/* _ / _ / _{" "} */}
                  <DatePicker
                    selected={startDatee}
                    onChange={handleDateChange}
                    selectsStart={isSelectingStartDate}
                    startDate={startDatee}
                    endDate={endDatee}
                    minDate={isSelectingStartDate ? null : startDatee}
                    className=" p-0  rounded   focus:outline-none text-[#565656] text-base font-medium "
                    onFocus={() => setIsSelectingStartDate(true)}
                  />
                </div>
              </div>
            </div>

            {/* second */}
            <div class=" h-10 py-2 bg-white rounded flex-col justify-center items-start  px-2 ">
              <div class="justify-start items-center gap-3 inline-flex">
                <div class="w-6 h-6 relative">
                  <PAICalenderPickerIcon />
                </div>
                <div class="text-neutral-600 text-base font-medium ">
                  {/* _ / _ / _{" "} */}
                  <div>
                    <DatePicker
                      selected={endDatee}
                      onChange={handleDateChange}
                      selectsStart={isSelectingStartDate}
                      startDate={startDatee}
                      endDate={endDatee}
                      minDate={isSelectingStartDate ? null : startDatee}
                      className=" p-0  rounded   focus:outline-none text-[#565656] text-base font-medium "
                      onFocus={() =>
                        startDatee && setIsSelectingStartDate(false)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* third */}
            <button
              class="w-[91px] h-[41px] px-6 py-3 bg-[#07593D] rounded-[5px] justify-start items-center gap-2.5 inline-flex"
              // onClick={() => setModalOpen(true)}
            >
              <div class="text-white text-sm font-medium ">Search</div>
            </button>
          </div>

          {/* end it here */}
          <div className=" ">
            <button
              class="h-[39px] p-2.5 border border-[#07593D] bg-white rounded justify-center items-center gap-2.5 inline-flex sm:w-full"
              on
              // onClick={navigateViewtransDetail}
            >
              <div class="text-[#07593D] text-base font-medium ">
                Download Statement
              </div>
            </button>
          </div>
        </div>

        {/* ACCOUNT SUMMARY */}
        <div className="bg-alabasterHeader py-[10px] px-2 rounded-md mt-10">
          <div className="flex justify-between items-center mb-5 px-2">
            <div className=" text-[#064731]">
              <p className="text-xl font-bold">My Account</p>
              <p className="text-xs font-normal">Account Summary</p>
            </div>
            <div>
              <button
                onClick={() => {
                  handleDeactivateOpen();
                }}
                className={`rounded p-2 px-5 mr-3 ${
                  isActiveAccount
                    ? "text-[#DE1515] bg-[#FFF7F7] border border-[#DE1515]"
                    : "text-[#07593D] bg-[#E7F9E7] border border-[#07593D]"
                }`}
              >
                {isActiveAccount ? "Deactivate Account" : "Activate Account"}
              </button>
              <button
                // onClick={handleLinkExternalClick}
                onClick={() => {
                  setIsFundOptionsOpen(true);
                }}
                className="mr-3 text-white bg-[#07593D] rounded p-2 px-5"
              >
                Fund Account
              </button>
              <button
                // onClick={() => {
                //   handleWithdrawOpen();
                // }}
                onClick={handleWithdrawClick}
                className="text-white bg-[#F08000] rounded p-2 px-5 cursor-not-allowed opacity-50"
              >
                Withdrawal
              </button>
            </div>{" "}
          </div>
          <div className="flex flex-wrap justify-center mx-auto w-full mb-5">
            {cardContents.map((content, idx) => (
              <div
                key={idx}
                className="w-full md:w-1/4 lg:w-1/4 xl:w-1/4 mb-6 px-2"
              >
                <WalletCard
                  label={content?.label}
                  amount={content?.amount}
                  percentage={content?.percentage}
                  info={content?.info}
                />
              </div>
            ))}
          </div>
        </div>

        {/* TRANSACTION TABLE */}
        <TransactionTable />

        {/* WITHDRAWAL MODAL */}
        {/* <WithdrawOption
          isOpen={isWithdrawOpen}
          onClose={() => setWithdrawOpen(false)}
        /> */}
        <WithdrawalOptions
          isOpen={withdrawalOptionOpen}
          onClose={() => setWithdrawalOptionOpen(false)}
          onSubmit={""}
          virtualClicked={""}
        />

        {/* DEACTIVATE MODAL */}
        <AcceptModal
          isOpen={isDeactivateOpen}
          onClose={() => setDeactivateOpen(false)}
          message={
            <>
              Are you sure you want to{" "}
              <span
                className={`font-bold ${
                  isActiveAccount ? "text-[#DE1515]" : "text-[#07593D]"
                }`}
              >
                {isActiveAccount ? "Deactivate" : "Activate"}
              </span>{" "}
              this virtual account:
              <br />
              <br />
              <span className="italic text-[#07593D] font-bold">
                {activeAccount?.name} - {activeAccount?.accountNumber} -{" "}
                {activeAccount?.bankName}
              </span>
              <br />
              <br />
            </>
          }
          onConfirm={() => {
            setShowDeactivatePinModal(true);
            setDeactivateOpen(false);
          }}
        />
        <PinModal
          isOpen={showDeactivatePinModal}
          onClose={() => {
            setShowDeactivatePinModal(false);
          }}
          // onSubmit={(pin) => {
          //   setShowSuccessModal(true);
          //   setShowDeactivatePinModal(false);
          // }}
          onSubmit={handleDeactivate}
          headerText={
            isActiveAccount ? "Deactivating Account" : "Activating Account"
          }
          instructionText={`Please enter your PIN to confirm the ${
            isActiveAccount ? "deactivation" : "activation"
          } of the account.`}
        />
        <SuccessModal
          isOpen={isSuccessModal}
          onClose={() => setShowSuccessModal()}
          headerText={
            isActiveAccount ? "Activating Account" : "Deactivating Account"
          }
          message={isSuccessMessage}
          isLoading={isLoading}
        />

        {/* FUND ACCOUNT MODAL */}
        <FundOptions
          isOpen={isFundOptionsOpen}
          onClose={() => setIsFundOptionsOpen(false)}
          onSubmit={handleFundOptionSubmit}
          virtualClicked={handleFundVirtualAccountClick}
        />
        <FundWalletDetails
          isOpen={externalSourceOpen}
          onClose={() => setExternalSourceOpen(false)}
        />
        <AccountFrom
          isOpen={isAccountFromOpen}
          onClose={() => setIsAccountFromOpen(false)}
          where1="From- "
          // where2="from"
          onSubmit={handleAccountFromSubmit}
        />
        <AccountTo
          isOpen={isAccountToOpen}
          onClose={() => setIsAccountToOpen(false)}
          accountFromBack={() => {}}
          accountToBack={handleAccountToBack}
          onSubmit={handleFundVirtualAccountSubmit}
        />
        {fundAccountPinModal && (
          <PinModal
            isOpen={fundAccountPinModal}
            onClose={() => {
              setFundAccountPinModal(false);
            }}
            // onSubmit={(pin) => {
            //   // setShowPinModal(false);
            //   setIsTransferReceiptOpen(true);
            // }}
            onSubmit={handleTransfer}
            isLoading={isLoading}
            headerText="Fund Account"
            instructionText="Enter your transaction PIN to transfer funds to your desired account."
          />
        )}

        {isTransferReceiptOpen && (
          <TransferReceipt
            isOpen={isTransferReceiptOpen}
            onClose={handleTransferReceiptClose}
            // onClose={() => handleCloseWithdrawOption()}
            amount={transferDetails?.data?.amount}
            currency={transferDetails?.data?.currency}
            paymentStatus={transferDetails?.data?.status}
            senderName={transferDetails?.data?.sourceAccountName}
            sourceBank="PayEdge"
            sourceAccountNumber={transferDetails?.data?.sourceAccountNumber}
            beneficiaryName={transferDetails?.data?.destinationAccountName}
            destinationBank="PayEdge"
            destinationAccountNumber={
              transferDetails?.data?.destinationAccountNumber
            }
            transactionReference={transferDetails?.data?.transactionReference}
            createdDate={transferDetails?.data?.transactionDate}
            supportEmail="customerservice@payedge.com"
            //   onDownload={() => console.log("Downloaded!")}
          />
        )}
      </div>
    </DashboardLayout>
  );
};

export default AccountStatementPage;
