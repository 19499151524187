import React, { useState } from "react";
import { PAIconSmallClose } from "assets/images/svgs";
import { motion } from "framer-motion";
import InputField from "shared/InputField";
// import toast from "react-hot-toast";

const ReasonForDeclineModal = ({ onClose, onContinue }) => {
  const handleCloseDeleteModal = () => {
    onClose();
  };
  const [value, setValue] = useState();
  const onChange = (e) => {
    setValue(e.target.value);
    // /^(?!^\d+$)(?!^\s+$)(?!^[^\w\s]+$).+/ regext that checks whetther is empty
  };

  return (
    <motion.div>
      <motion.div className="bg-slate-600/20 fixed inset-0 flex items-center justify-center z-50 ">
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.2,
            },
          }}
          exit={{
            opacity: 0,
            transition: {
              delay: 0.1,
            },
          }}
          className="modal-overlay"
        ></motion.div>

        <motion.div
          initial={{
            scale: 0,
          }}
          animate={{
            scale: 1,
            transition: {
              duration: 0.2,
            },
          }}
          exit={{
            scale: 0,
            transition: {
              delay: 0.1,
            },
          }}
          className=" py-11 bg-white rounded-[10px]    flex flex-col lg:w-[612px] lg:min-h-[400px]  md:min-h-[400px]   lg:overflow-hidden   md:overflow-scroll  sm:overflow-scroll xs:overflow-scroll"
        >
          {/* header side */}
          <div className="px-6  lg:px-7">
            <header className="flex justify-between pb-4 border-b-[4px] border-[#F6F5FA] ">
              <span className=" text-[#222] font-bold">
                Decline Reason Required
              </span>

              <span
                onClick={() => handleCloseDeleteModal()}
                className="flex items-center cursor-pointer gap-2 text-sm font-[500] text-[#565656]"
              >
                <PAIconSmallClose />
                <span class="text-[#565656] text-sm font-medium ">Close</span>
              </span>
            </header>
          </div>

          {/* text section */}
          <div className=" px-7 lg:px-10 py-4 border-b  border-[#8b8b8b]/40">
            <div class=" text-[#949494] text-base font-normal ">
              Please confirm declined request, with reasons that will be sent to
              the recipient Samuel James (Supplier).
              <br />
              <div className=" mt-2">Thank you for your valuable feedback.</div>
            </div>
          </div>

          {/* Input section */}

          <div className=" justify-center items-center flex-col px-7 lg:px-10  mt-5 w-full">
            <div className=" text-[#07593d] text-sm font-medium mb-1 ">
              Input your reply{" "}
            </div>
            <div className="h-[69px]   w-full rounded-[5px]  border-[1.5px] border-[#07593d] justify-start items-start text-lg font-semibold  ">
              <InputField
                className={
                  " w-full border-none   focus:outline-none  h-[63px] p-2   text-[#2fa069]   rounded-t-[5px] text-lg font-bold"
                }
                multiple={true}
                onChange={onChange}
                value={value}
              />

              {/* Buton */}
              <button
                className={`h-[49px] px-2.5 py-[15px]  ${
                  value ? "bg-[#07593d]" : "bg-[#07593d]/60"
                } ${
                  value ? "cursor-pointer" : "cursor-not-allowed"
                } rounded-[5px] justify-center items-center gap-2.5 flex w-full mt-6`}
                disabled={value ? false : true}
                onClick={onContinue}
              >
                <div className="text-white text-base font-normal ">Submit</div>
              </button>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};
export default ReasonForDeclineModal;
