import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import {
  PAIconSmallBrewIcon,
  PAIconSmallCoke,
  PAIconSmallDangote,
  PAIconSmallNestle,
  PAIconSmallShell,
  PAIconInvShop,
  PAIconCocacola,
  PAIconSmallAccessIcon,
} from "assets/images/svgs";

function RequestsCard({
  id,
  invoiceNumber = "N/A",
  invoiceReference = "N/A",
  supplierCompanyName = "N/A",
  invoiceAmount = "N/A",
  status = "N/A",
  invoiceDueDate = "N/A",
  statusRequest = "N/A",
  rateType = "N/A",
  requestType,
  numberOfInoices="N/A"
}) {
  const navigate = useNavigate();

  return (
    <motion.div
      onClick={() => navigate(`requestsdetails/${id}`)}
      whileTap={{ scale: 0.98 }}
      style={{ boxShadow: "0px 2px 4px 0px rgba(232, 226, 216, 0.36)" }}
      className="flex cursor-pointer justify-between bg-white py-5 px-6 rounded-[10px]"
    >
      <span className="flex gap-6  items-center">
        <PAIconInvShop />
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{invoiceReference}</span>
          <span className=" text-[#959595] text-[10px]">Invoice number</span>
        </div>
      </span>

      <span className="flex gap-6  items-center">
        {requestType === "single" ? (
          <div className="flex flex-col justify-center">
            <span className="font-[500] text-sm">{invoiceNumber}</span>
            <span className=" text-[#959595] text-[10px]">Invoice number</span>
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center">
            <span className="font-[500] text-sm">{numberOfInoices}</span>
            <span className=" text-[#959595] text-[10px]">Number of invoices</span>
          </div>
        )}
      </span>

      <span className="flex gap-6 items-center ">
        {supplierCompanyName === "Access Plc" ? (
          <PAIconSmallAccessIcon />
        ) : supplierCompanyName === "Cocacola" ? (
          <PAIconSmallCoke />
        ) : supplierCompanyName === "Dangote" ? (
          <PAIconSmallDangote />
        ) : supplierCompanyName === "Unity bank" ? (
          <PAIconSmallNestle />
        ) : supplierCompanyName === "XYZ Funds" ? (
          <PAIconSmallShell />
        ) : supplierCompanyName === "Trust Ltd." ? (
          <PAIconSmallBrewIcon />
        ) : (
          <PAIconCocacola />
        )}

        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{supplierCompanyName}</span>
          <span className=" text-[#959595] text-[10px]">{"Funder"}</span>
        </div>
      </span>

      <span className="flex gap-6  items-center">
        <div className="flex flex-col justify-center">
          <span
            className={
              statusRequest === "Posted"
                ? "font-[500] text-sm text-primaryColor"
                : "font-[500] text-sm text-secondaryColor"
            }
          >
            {statusRequest}
          </span>
          <span className=" text-[#959595] text-[10px]">Status of request</span>
        </div>
      </span>

      <span className="flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">N{invoiceAmount}</span>
          <span className=" text-[#959595] text-[10px]">Invoice Amount</span>
        </div>
      </span>

      <span className="hidden extra-md:flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{status}</span>
          <span className=" text-[#959595] text-[10px]">{rateType}</span>
        </div>
      </span>

      <span className="hidden extra-md:flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{invoiceDueDate}</span>
          <span className=" text-[#959595] text-[10px]">
            Early payment date
          </span>
        </div>
      </span>

      <span className="hidden extra-md:flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">01d:13h:55m:023s</span>
          <span className=" text-[#959595] text-[10px]">
            {statusRequest === "Posted"
              ? "Count down to Early Payment"
              : "Count down to the end of bid"}
          </span>
        </div>
      </span>
    </motion.div>
  );
}

export default RequestsCard;
