import { PAIconAlert } from "assets/images/svgs";
import React, { useState } from "react";
import SuccessModal from "shared/SuccessModal";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { cancelPFP, stopPFP } from "appstate/buyer/buyerSlice";
import ReasonModal from "./ReasonModal";

const DeleteModal = ({ isOpen, onClose, onConfirm, actionText, pfpId }) => {
  const [successModal, setSuccessModal] = useState(false);
  const [reasonModal, setReasonModal] = useState(false);
  const [reason, setReason] = useState("");
  const dispatch = useDispatch();
  const { cancelPfpLoading } = useSelector((state) => state.buyer);

  const proceedDelete = () => {
    dispatch(cancelPFP(pfpId));
    setSuccessModal(true);
  };

  const proceedStop = () => {
    setSuccessModal(false);
    setReasonModal(true);
  };

  const proceedStopCampaign = () => {
    const body = {
      reason: reason,
    };
    dispatch(stopPFP({ id: pfpId, body }));
    setSuccessModal(true);
  };

  const navigate = useNavigate();

  const closeModal = () => {
    navigate("/payablefinancing/payablefinanceProgram");
  };

  if (!isOpen) return null;

  return (
    <div>
      {successModal ? (
        <SuccessModal
          isOpen={successModal}
          onClose={closeModal}
          message="Payable finace program created successfully"
        />
      ) : reasonModal ? (
        <ReasonModal
          isOpen={reasonModal}
          onClose={() => {
            setReasonModal(false);
          }}
          setReason={setReason}
          proceedStopCampaign={proceedStopCampaign}
        />
      ) : (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg shadow-lg w-11/12 max-w-md p-6">
            <div className="flex justify-center">
              <div className="text-yellow-500 text-3xl">
                <PAIconAlert />
              </div>
            </div>
            <p className="text-center mt-4 text-[#7A7A7A]">
              Are you sure you would like to{" "}
              <span className="text-[#777777] font-semibold">{actionText}</span>{" "}
              this payable finance program?
            </p>
            <div className="flex justify-center mt-6 space-x-4">
              <button
                onClick={actionText === "Cancel" ? proceedDelete : proceedStop}
                className="px-8 py-2 bg-primaryColor h-[45px] text-white font-semibold rounded-[4px] hover:bg-green-700"
              >
                {cancelPfpLoading ? "Canceling..." : "Yes"}
              </button>
              <button
                onClick={onClose}
                className="px-8 py-2 bg-red-600 text-[#ed2345] h-[45px] font-semibold rounded-[4px] border-[1px] border-[#ed2345]"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeleteModal;
