import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LoadingScreen from "shared/LoadingScreen";
import InvoiceDetails from "./invoiceDetails";
import { getSingleEarlyPaymentOffer } from "appstate/api/invoice/invoice";

const InvoiceDetailsPage = () => {
  const { id } = useParams();
  const [offerDetails, setOfferDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const offerId = id;

  useEffect(() => {
    const fetchOfferDetails = async () => {
      try {
        setIsLoading(true);
        const response = await getSingleEarlyPaymentOffer(offerId);
        setOfferDetails(response);
      } catch (error) {
        console.error("Error fetching offer details:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (offerId) {
      fetchOfferDetails();
    }
  }, [offerId]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  // Calculate the payment based on the due date and created date
  const calculatePayment = (dueDate, createdDate) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const dueDateObj = new Date(dueDate);
    const createdDateObj = new Date(createdDate?.split(" ")[0]);
    const diffDays = Math.round(
      Math.abs((dueDateObj - createdDateObj) / oneDay)
    );
    return `${diffDays} Days`;
  };

  // FORMAT AMOUNT
  const formatAmount = (amount) => {
    if (typeof amount !== "number") return "0.00";
    return amount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const data = {
    id: id,
    invoiceNumber: `INV-${offerDetails?.data?.invoiceNo}` || "",
    referenceNumber: offerDetails?.data?.invoiceReference || "",
    createdDate: offerDetails?.data?.createdDate?.slice(0, 10) || "",
    dueDate: offerDetails?.data?.dueDate || "",
    payment: calculatePayment(
      offerDetails?.data?.dueDate,
      offerDetails?.data?.createdDate
    ),
    currency: offerDetails?.data?.currency || "",
    supplier: offerDetails?.data?.supplierCompanyName || "",
    buyer: offerDetails?.data?.buyerCompanyName || "",
    endDate: offerDetails?.data?.endDate || "",
    campaignName: offerDetails?.data?.programName || "",
    discountRate: offerDetails?.data?.discountRate || "",
    paymentDate: offerDetails?.data?.paymentDate || "",
    paymentDuration: offerDetails?.data?.paymentDuration || "",
    receivableAmount: offerDetails?.data?.receivableAmount || "",
    paymentTerms: offerDetails?.data?.paymentTerms,
    buyerCompanyName: offerDetails?.data?.buyerCompanyName || "",
    accountNumber: offerDetails?.data?.virtualAccount?.accountNumber || "",
    accountName: offerDetails?.data?.virtualAccount?.accountName || "",
    vat: formatAmount(offerDetails?.data?.vat),
    discount: formatAmount(offerDetails?.data?.discount),
    vatAmount: formatAmount(offerDetails?.data?.vatAmount || ""),
    discountAmount: (offerDetails?.data?.discountAmount || ""),
    issuanceDate: offerDetails?.data?.issuanceDate || "",
    settlementDate: offerDetails?.data?.settlementDate || "",
    submittedDate: offerDetails?.data?.submittedDate || "",
    approvedDate: offerDetails?.data?.approvedDate || "",
    grandTotal: formatAmount(offerDetails?.data?.grandTotal || ""),
    subTotal: formatAmount(offerDetails?.data?.subTotal || ""),
    numberOfLineItems: offerDetails?.data?.lineItems?.length || 0,
    status: offerDetails?.data?.status || "",

    lineItems:
      offerDetails?.data?.lineItems?.map((item) => ({
        // itemNumber: item?.itemNumber || "",
        item: item?.itemName || "",
        description: item.description || "",
        taxRate: item.taxRate || "",
        totalPrice: formatAmount(item.totalPrice || ""),
        taxAmount: formatAmount(item.taxAmount || ""),
        quantity: item.quantity || 0,
        unitPrice: formatAmount(item.unitPrice || ""),
        amount: formatAmount(item.amount || ""),
      })) || [],
  };

  return <InvoiceDetails data={data} />;
};

export default InvoiceDetailsPage;
