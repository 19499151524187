import React from "react";

const GeneralComment = () => {
  return (
    <div className="w-full bg-stone-50 rounded-tr-[5px] rounded-bl-[5px] rounded-br-[5px] border border-gray300 py-3 border-b-1 border-emerald-800/20 ">
      <div class="w-full inline-flex flex-col justify-start items-start gap-2 px-3">
        <div class="flex flex-col justify-start items-start gap-0.5">
          <div class="w-32 justify-start text-neutral-800 text-xs font-medium ">
            Pepsi Ltd. (Supplier)
          </div>
        </div>
        <div class="self-stretch  justify-start text-neutral-400 text-xs font-medium text-[#a3a3a3] ">
          I have reviewed the terms of the agreement and confirm my
          understanding and acceptance of the outlined responsibilities. Looking
          forward to proceeding under these conditions.
          <div class="justify-end items-end text-emerald-800 text-[10px] font-medium  flex 1px text-[#065f46]">
            October 26, 2023 at 3:30 PM
          </div>
        </div>
      </div>
      <textarea
        className="w-full h-[122px] bg-stone-50 p-2 outline-none rounded-md resize-none"
        placeholder="Type  comment here..."
      ></textarea>
    </div>
  );
};

export default GeneralComment;
