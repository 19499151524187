import { motion } from "framer-motion";
import { PAIconArrowLeftGreen } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { useNavigate, useParams } from "react-router-dom";
import TransitionScale from "shared/animation/TransitionScale";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DirectFundingInvoice from "./directFundingInvoice";
import { funderEarlyPaymentViewSinglePendingDetails } from "appstate/funder/funderSlice";
import { allInvoiceById } from "appstate/invoice/invoiceSlice";

function FunderCollectionDirectFundingInvoice() {
  const { id } = useParams();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(funderEarlyPaymentViewSinglePendingDetails({ id }));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(allInvoiceById({ id }));
  }, [dispatch, id]);

  const { funderEarlyPaymentViewSinglePendingDetailsData } = useSelector(
    (state) => state?.funder
  );

  const { allinvoiceByIdData } = useSelector((state) => state?.invoice);

  function handleGoBack() {
    navigate(-1);
  }

  return (
    <DashboardLayout
      section="Collections"
      subHeader="Track invoices due for payment collection"
    >
      <TransitionScale className="flex  flex-col gap-6 bg-[#EEE] h-full">
        <header onClick={handleGoBack} className="w-full">
          <div className="flex cursor-pointer w-fit gap-1 items-center">
            <PAIconArrowLeftGreen />
            <span className=" text-primaryColor text-sm">Go back</span>
          </div>
        </header>

        <main className="flex flex-col semi-lg:flex-row pb-8 gap-4 w-full ">
          <DirectFundingInvoice invoiceByIdData={allinvoiceByIdData} />

          <motion.section
            initial={{
              y: -20,
            }}
            animate={{
              y: 0,
            }}
            exit={{
              y: 20,
            }}
            transition={{
              delay: 0.8,
            }}
            className="flex flex-col gap-3  pt-7 pb-24 w-2/5 bg-white rounded-[10px]"
          >
            <header className="flex  px-6">
              <span className="font-bold">Direct Fund Request</span>
            </header>

            <div>
              {" "}
              <div className="   pb-4">
                <div class="w-full justify-start items-center gap-4 inline-flex "></div>
                <div class=" justify-between  flex flex-row    px-6   ">
                  <div className="flex gap-2 py-2.5">
                    <div className="flex flex-col">
                      <span class="text-black text-sm font-normal ">
                        {
                          funderEarlyPaymentViewSinglePendingDetailsData?.data
                            ?.buyerCompanyName
                        }
                      </span>
                      {/* <span className="text-[#979797]  text-xs">Supplier</span> */}
                      <span class="text-[#949494] text-sm font-normal ">
                        Buyer
                      </span>
                    </div>
                  </div>
                  <div class=" flex-col justify-start items-start inline-flex">
                    {" "}
                    <div className="flex gap-2 py-2.5">
                      <div className="flex flex-col">
                        <span class="text-black text-sm font-normal ">
                          {
                            funderEarlyPaymentViewSinglePendingDetailsData?.data
                              ?.supplierCompanyName
                          }
                        </span>
                        {/* <span className="text-[#979797]  text-xs">Supplier</span> */}
                        <span class="text-[#949494] text-sm font-normal ">
                          Supplier
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* here */}
                </div>
                <div class=" justify-between  flex flex-row     px-6     mt-2">
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        {
                          funderEarlyPaymentViewSinglePendingDetailsData?.data
                            ?.discountAmount
                        }
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Discount Amount
                      </div>
                    </div>
                  </div>
                  {/* dhdh */}
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        {
                          funderEarlyPaymentViewSinglePendingDetailsData?.data
                            ?.discountRate
                        }
                        {/* {details?.earlyPaymentDate && details?.earlyPaymentDate} */}
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Discount Rate
                      </div>
                    </div>
                  </div>
                </div>
                <div class=" justify-between  flex flex-row     px-6    mt-2">
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        {
                          funderEarlyPaymentViewSinglePendingDetailsData?.data
                            ?.requestDate
                        }
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Request Date
                      </div>
                    </div>
                  </div>
                  {/* dhdh */}
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        {
                          funderEarlyPaymentViewSinglePendingDetailsData?.data
                            ?.paymentDate
                        }
                        {/* {details?.earlyPaymentDate && details?.earlyPaymentDate} */}
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Early Payment date
                      </div>
                    </div>
                  </div>
                </div>
                <div class=" justify-between  flex flex-row    px-6  mt-2 ">
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        {
                          funderEarlyPaymentViewSinglePendingDetailsData?.data
                            ?.requestAmount
                        }
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Request Amount
                      </div>
                    </div>
                  </div>
                  {/* hd */}
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-base font-extrabold ">
                        {
                          funderEarlyPaymentViewSinglePendingDetailsData?.data
                            ?.payableAmount
                        }
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Receivable Amount
                      </div>
                    </div>
                  </div>{" "}
                </div>
                <div class=" justify-between  flex flex-col     px-6  pt-6 border-t border-[#c1c1c1]/60 border-b pb-14 mt-4">
                  <div class="text-black text-lg font-semibold  ">
                    Note from Supplier:{" "}
                  </div>

                  <div class=" text-[#949494] text-sm font-light  mt-4  leading-6">
                    {
                      funderEarlyPaymentViewSinglePendingDetailsData?.data
                        ?.description
                    }
                  </div>
                </div>{" "}
                <div class=" justify-between  flex flex-col     px-6  pt-6   pb-14 mt-4">
                  <div class="text-black text-lg font-semibold  ">
                    Funding Option
                  </div>

                  <div class=" text-[#949494] text-sm font-light  mt-4  leading-6">
                    This invoice would be funded from this virtual account:
                  </div>
                  <div class="text-black/50 text-sm font-semibold mt-4  leading-6">
                    {
                      funderEarlyPaymentViewSinglePendingDetailsData?.data
                        ?.funderVirtualAccountName
                    }
                    <br />
                    {
                      funderEarlyPaymentViewSinglePendingDetailsData?.data
                        ?.funderVirtualAccountNumber
                    }
                    <br />
                    {
                      funderEarlyPaymentViewSinglePendingDetailsData?.data
                        ?.funderBankName
                    }
                  </div>
                </div>{" "}
              </div>
            </div>
          </motion.section>
        </main>
      </TransitionScale>
    </DashboardLayout>
  );
}

export default FunderCollectionDirectFundingInvoice;
