import React, { useState } from "react";
import DashboardLayout from "layout/dashboardlayout";
import { PAIconArrowLeftGreen } from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import PendingRequests from "./PendingRequests";
import RequestsHistory from "./RequestHistory";

const WorkFlowPendingRequests = () => {
  const [activeTab, setActiveTab] = useState("pendingrequests");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/work-flow-management/");
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "pendingrequests":
        return <PendingRequests />;
      case "requestshistory":
        return <RequestsHistory />;

      default:
        return null;
    }
  };

  return (
    <DashboardLayout
      section="Workflow "
      subHeader="Manage all your workflow approvals"
    >
      <div className=" lg:min-h-screen">
        <header className="bg-white text-primaryColor px-6  rounded-[5px] ">
          <span
            onClick={() => handleBack()}
            className="flex cursor-pointer py-4 w-fit font-light items-center text-sm gap-2"
          >
            <PAIconArrowLeftGreen />
            Go Back
          </span>
        </header>
        <div>
          <div className="mt-9 mx6 mb-[17px] w-full cursor-pointer rounded-[5px] bg-white py-2">
            <div className=" flex-col items-center justify-between gap-10 p-2 px-4 md:flex md:flex-row">
              <div className="flex-col gap-10 md:flex md:flex-row">
                <div
                  className={
                    activeTab === "pendingrequests"
                      ? "rounded-l-sm rounded-r-sm border-[#07593D] font-bold text-[#07593D]   text-sm md:mb-[-18px] md:border-b-[2.5px]"
                      : "text-[#949494] text-sm  font-normal"
                  }
                  onClick={() => handleTabClick("pendingrequests")}
                >
                  Pending Requests
                </div>
                <div
                  className={
                    activeTab === "requestshistory"
                      ? "rounded-l-sm rounded-r-sm border-[#07593D] font-bold text-[#07593D]   text-sm md:mb-[-18px] md:border-b-[2.5px]"
                      : "text-[#949494] text-sm  font-normal"
                  }
                  onClick={() => handleTabClick("requestshistory")}
                >
                  Request History
                </div>
              </div>
            </div>
          </div>

          {/* search filter section 
          
          Date range
Workflow type 
Approver name (to view the specific role/user currently responsible)*/}

          {renderTabContent()}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default WorkFlowPendingRequests;
