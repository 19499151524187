import React, { useEffect, useRef, useState } from "react";
import {
  PAIconDownload,
  PAIconFilter,
  PAIconOption,
  PAIconOutlineSearch,
} from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";
import Table from "layout/table/Table";
import AcceptModal from "shared/AcceptModal";
import SuccessModal from "shared/SuccessModal";
import { useDispatch, useSelector } from "react-redux";
import Loading from "shared/Loading";
import {
  remainder,
  supplierOpenMarketPendingPayment,
  supplierOpenMarketPendingReimbursement,
} from "appstate/collections/collectionSlice";
import Pagination from "shared/Pagination";

const OpenMarket = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  // const [selectAllText, setSelectAllText] = useState("Select All");
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  // const [ selectedInvoice,  setSelectedInvoice] = useState(null);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [selectedOption, setSelectedOption] = useState("awaitingDirectFunding");
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(10);
  const pageSize = 10;

  const handleSearch = () => {
    if (selectedOption === "awaitingDirectFunding") {
      dispatch(supplierOpenMarketPendingPayment({ search: searchQuery }));
    } else if (selectedOption === "trackPaidCollectionsWithRecourse") {
      dispatch(supplierOpenMarketPendingReimbursement({ search: searchQuery }));
    }
  };

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen({});
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (selectedOption === "awaitingDirectFunding") {
      dispatch(
        supplierOpenMarketPendingPayment({
          page,
          pageSize,
          search: searchQuery,
        })
      );
    } else if (selectedOption === "trackPaidCollectionsWithRecourse") {
      dispatch(
        supplierOpenMarketPendingReimbursement({
          page,
          pageSize,
          search: searchQuery,
        })
      );
    }
  }, [dispatch, page, pageSize, selectedOption, searchQuery]);

  const {
    supplierOpenMarketPendingReimbursementData,
    supplierOpenMarketPendingPaymentData,
    isLoading,
  } = useSelector((state) => state?.collection);

  const tableData = () => {
    if (selectedOption === "awaitingDirectFunding") {
      return supplierOpenMarketPendingPaymentData?.data || [];
    } else if (selectedOption === "trackPaidCollectionsWithRecourse") {
      return supplierOpenMarketPendingReimbursementData?.data || [];
    }
    return [];
  };

  // CONFIRM REMAINDER
  const handleConfirm = () => {
    setIsAcceptModalOpen(false);
    // setSelectedInvoice(null);
    const selectedInvoiceIds = selectedRows.map((id) => id);
    dispatch(remainder({ type: "openmarket", body: selectedInvoiceIds })).then(
      (response) => {
        if (response?.payload?.status === 200 && response?.payload?.success) {
          setIsSuccessModalOpen(true);
          setSuccessMessage(response?.payload?.message);
        }
      }
    );
  };

  const handleCloseSuccessModal = () => {
    setIsSuccessModalOpen(false);
  };

  const openMarketDetails = (requestReference) => {
    let path = `/collections/open-market/${requestReference}`;
    navigate(path);
  };

  const options = [
    {
      value: "awaitingDirectFunding",
      label: "Awaiting Open market collection",
    },
    {
      value: "trackPaidCollectionsWithRecourse",
      label: "Track paid collections with recourse",
    },
  ];

  const handleSelect = (option) => {
    setSelectedOption(option.value);
    setIsOpen(false);
  };

  // SEND REMAINDER BUTTON
  const handleSendReminder = () => {
    setIsAcceptModalOpen(true);
  };

  // NOTIFICATION ICON CLICKED
  const handleNotificationClick = (row) => {
    const isSelected = selectedRows.includes(row.invoiceId); // Use invoiceId
    let newSelectedRows = [];

    if (!isSelected) {
      newSelectedRows = [...selectedRows, row.invoiceId]; // Add invoiceId to selected rows
    } else {
      newSelectedRows = selectedRows.filter((id) => id !== row.invoiceId); // Remove invoiceId from selected rows
    }

    setSelectedRows(newSelectedRows);
    setIsAcceptModalOpen(true);
  };

  // SELECT ALL
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      let allIds = [];

      // Choose the appropriate data based on selectedOption
      const data =
        selectedOption === "awaitingDirectFunding"
          ? supplierOpenMarketPendingPaymentData?.data
          : supplierOpenMarketPendingReimbursementData?.data;

      allIds = data?.map((row) => row.invoiceId); // Collect all invoiceIds
      setSelectedRows(allIds); // Select all invoices
    }
    setSelectAll(!selectAll); // Toggle the selectAll state
  };

  // CHECKBOX
  const handleCheckboxChange = (row) => {
    const isSelected = selectedRows.includes(row.invoiceId);
    let newSelectedRows = [];

    if (isSelected) {
      newSelectedRows = selectedRows.filter((id) => id !== row.invoiceId);
    } else {
      newSelectedRows = [...selectedRows, row.invoiceId];
    }

    setSelectedRows(newSelectedRows);
  };

  useEffect(() => {
    // console.log(selectedRows);
  }, [selectedRows]);

  const formatType = (type) => {
    return type
      .split("_") // Split by underscore
      .map(
        (word) => word.charAt(0).toUpperCase() + word?.slice(1).toLowerCase()
      ) // Capitalize each word
      .join(" "); // Join them back with space
  };

  const columns = [
    {
      name: (
        <input type="checkbox" onChange={handleSelectAll} checked={selectAll} />
      ),
      cell: (row) => (
        <input
          type="checkbox"
          onChange={() => handleCheckboxChange(row)}
          checked={selectedRows.includes(row.invoiceId)}
        />
      ),
      width: "50px",
    },
    {
      name: (
        <span className="whitespace-normal">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Request ref Number
        </span>
      ),
      selector: (row) => {
        const invoiceNumber = row.invoiceNumber;
        return invoiceNumber && !isNaN(invoiceNumber)
          ? `INV-${invoiceNumber}`
          : "INV-0";
      },
      sortable: true,
    },
    {
      name: "Company",
      selector: (row) => row.buyerCompanyName,
      sortable: true,
    },
    {
      name: "Recourse Option",
      selector: (row) => formatType(row.discountType),
      sortable: true,
      cell: (row) => <p>{formatType(row.discountType)}</p>,
    },
    {
      name: <span className="whitespace-normal">Payment Date</span>,
      selector: (row) => row.paymentDate,
      sortable: true,
      cell: (row) => {
        // Convert the array to a Date object
        const date = new Date(
          row.paymentDate[0],
          row.paymentDate[1] - 1,
          row.paymentDate[2]
        );

        // Format the date as 'YYYY-MM-DD'
        const formattedDate = date.toLocaleDateString("en-CA");

        return <p>{formattedDate}</p>;
      },
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
    },
    {
      name: "Request Amount",
      selector: (row) => row.requestAmount?.toLocaleString(),
      sortable: true,
      cell: (row) => (
        <p className="text-success">{row.requestAmount?.toLocaleString()}</p>
      ),
    },
    {
      name: "Lock Rate",
      selector: (row) => row.lockRate,
      sortable: true,
    },
    {
      name: "Lock Rate Amount",
      selector: (row) => row.lockRateAmount?.toLocaleString(),
      sortable: true,
    },
    {
      name: "Receiveable Amount",
      selector: (row) => row.receivableAmount?.toLocaleString(),
      sortable: true,
      cell: (row) => (
        <p className="text-success">{row.receivableAmount?.toLocaleString()}</p>
      ),
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <p
          className={
            row.status === "PARTIAL PAYMENT"
              ? "text-green-400"
              : "text-yellow-400"
          }
        >
          {formatType(row.status)}
        </p>
      ),
    },
    {
      cell: (row) => (
        <div className="flex gap-2">
          {
            <PAIconOption
              title="Options"
              onClick={() => {
                setIsDropdownOpen((prev) => ({
                  ...prev,
                  [row.id]: !prev[row.id],
                }));
                // Close other dropdowns
                Object.keys(isDropdownOpen).forEach((key) => {
                  if (key !== row.id.toString()) {
                    setIsDropdownOpen((prev) => ({ ...prev, [key]: false }));
                  }
                });
              }}
            />
          }
          {isDropdownOpen[row.id] && (
            <div
              ref={dropdownRef}
              className="absolute bg-white border border-gray300 rounded-md shadow-md z-10"
            >
              <ul>
                <li
                  className="px-4 py-2 cursor-pointer hover:bg-primaryColor hover:text-white"
                  onClick={() => {
                    openMarketDetails(row?.requestReference);
                  }}
                >
                  Preview
                </li>
                <li
                  className="px-4 py-2 cursor-pointer hover:bg-primaryColor hover:text-white"
                  onClick={() => {
                    handleNotificationClick(row);
                    setIsDropdownOpen((prev) => ({ ...prev, [row.id]: false }));
                  }}
                >
                  Send Reminder
                </li>
                <li className="px-4 py-2 cursor-not-allowed text-gray300">
                  Export
                </li>
              </ul>
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="my-2 grid md:grid-cols-2 items-center">
        <div className="gap-3 flex relative">
          <div>
            {/* FILTER */}
            <div className="relative">
              {/* Dropdown Button */}
              <button
                onClick={() => setIsOpen(!isOpen)}
                className="bg-white rounded flex text-sm items-center justify-between px-4 py-2 w-auto focus:outline-none"
              >
                {options.find((opt) => opt.value === selectedOption)?.label}
                <svg
                  className={`w-4 h-8 ml-2 transition-transform ${
                    isOpen ? "rotate-180" : "rotate-0"
                  }`}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.707a1 1 0 011.414 0L10 11.293l3.293-3.586a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>

              {/* Dropdown Menu */}
              {isOpen && (
                <ul className="absolute mt-2 w-auto text-sm bg-white border border-gray300 rounded-2xl shadow-md z-10">
                  {options.map((option) => (
                    <li
                      key={option.value}
                      onClick={() => handleSelect(option)}
                      className={`px-4 py-2 flex items-center text-xs cursor-pointer hover:bg-primaryColor hover:text-white ${
                        selectedOption === option.value ? "bgprimaryColor" : ""
                      }`}
                    >
                      {option.label}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>

          {/* EXPORT */}
          <button className="py-2 px-4 rounded-[5px] bg-white border-[#DEDEDE] shadow-sm mr-2 flex items-center justify-center gap-1.5">
            Export {<PAIconDownload className="accent-gray300 w-4" />}
          </button>
        </div>

        {/* SEARCH */}
        <div className="flex md:col-span-1 justify-end">
          <div className="flex items-center justify-between gap-5">
            <div className="border border-transparent md:border-gray md:shadow-sm my-4 rounded-[5px] px-2 lg:w-[300px] bg-white">
              <div className="items-center flex bg-white">
                <PAIconOutlineSearch />
                <input
                  className="bg-transparent p-2 w-full focus:outline-none text-sm bg-white"
                  type="text"
                  placeholder="Search for invoices awaiting collection"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleSearch();
                    }
                  }}
                />
              </div>
              <div className="lg:hidden flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer">
                <PAIconFilter className="bg-[#2FA06A]" />
              </div>
            </div>
            <button className="hidden lg:flex bg-[#2FA06A] rounded-md my-4 py-2 px-6 mx3 items-center gap-2 text-white">
              Search
            </button>
          </div>
        </div>
      </div>

      {/* TABLE */}
      <div>
        {!tableData()?.length ? (
          <div>
            <div className="flex justify-between items-center px-[15px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
              <p className="text-black text-sm font-medium">
                {selectedOption === "awaitingDirectFunding"
                  ? "Invoices from Open Market awaiting collection"
                  : "Invoices from Open Market - Paid collections with recourse"}
              </p>
            </div>
            <TableEmptyState dashboard />
          </div>
        ) : (
          <Card className="rounded-[10px] mt-[15px]">
            <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
              <div>
                <p className="text-black text-lg font-bold">
                  Invoices from Open Market awaiting collection
                </p>
                <span className="text-xs font-thin">
                  List of all invoices from open market channel
                </span>
              </div>
              <div className="flex items-center justify-center gap-3">
                {/* <div
                  className="border border-gray300 rounded-md p-2 text-white bg-[#2FA06A] cursor-pointer"
                  onClick={handleSelectAll}
                >
                  <p>{selectAllText}</p>
                </div> */}
                <button
                  className={`border border-gray300 rounded-md p-2 text-white ${
                    selectedRows?.length === 0
                      ? "cursor-not-allowed bg-gray300"
                      : "cursor-pointer bg-secondaryColor"
                  }`}
                  disabled={selectedRows?.length === 0}
                  onClick={handleSendReminder}
                >
                  <p>Send Reminder Now</p>
                </button>
              </div>
            </div>
            <div className="pb-[24px]">
              <Table
                columns={columns}
                data={tableData()}
                onRowClicked={(row) => openMarketDetails(row.requestReference)}
                progressPending={isLoading}
                progressComponent={<Loading />}
                pointer
                tableHeader
                className="mt-5"
              />
              <div className="flex justify-center gap-x-[8px] mt-[20px]">
                {tableData() && (
                  <Pagination
                    page={page}
                    startPage={startPage}
                    setEndPage={setEndPage}
                    endPage={endPage}
                    setStartPage={setStartPage}
                    setPage={setPage}
                    totalPages={
                      selectedOption === "awaitingDirectFunding"
                        ? supplierOpenMarketPendingPaymentData?.meta
                            ?.totalElements
                        : supplierOpenMarketPendingReimbursementData?.meta
                            ?.totalElements
                    }
                  />
                )}
              </div>
            </div>
          </Card>
        )}
      </div>

      <AcceptModal
        isOpen={isAcceptModalOpen}
        onClose={() => setIsAcceptModalOpen(false)}
        yes
        message={
          <>
            Would you like to proceed with sending a <br />
            <span className="text-[#07593D] font-bold">
              Payment reminder
            </span>{" "}
            now?
          </>
        }
        onConfirm={handleConfirm}
      />

      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={handleCloseSuccessModal}
        message={successMessage}
      />
    </>
  );
};

export default OpenMarket;
