import React from "react";
import { PAIconSmallClose } from "assets/images/svgs";
import { motion } from "framer-motion";
import MultiSelectComponent from "features/openMarket/supplier/components/MultiSelectedComponent";

const DirectFundingReasonForRequiredModal = ({
  onClose,
  selectedReason,
  setSelectedReason,
  userReason,
  setUserReason,
  handleRejectFunding
}) => {

  return (
    <div className="bg-[rgba(0,0,0,0.2)] w-[100%] h-[100vh] flex justify-center items-center absolute left-0 top-0 z-10">
      <div className=" py-9 overflow-auto bg-white rounded-[10px] w-[40%] h-[55%] flex flex-col">
        <header className="flex mx-6  top-0 bg-white justify-between pb-4 border-b-[3px] border-[#F6F5FA]">
          <span className=" text-[#222] font-bold">Reason Required</span>

          <span
            onClick={onClose}
            className="flex items-center cursor-pointer gap-2 text-sm font-[500] text-[#565656]"
          >
            <PAIconSmallClose />
            Close
          </span>
        </header>

        <div className="flex flex-col pt-2">
          <div className="text-[#959595] px-6 py-4 text-sm text border-b-[2px]  border-[#F6F5FA] w-full flex justify-center bg-white">
            Please provide a detailed explanation for rejecting the bid. Your
            insights assist in improving our platform and understanding user
            preferences. Thank you for your valuable feedback
          </div>

          <div className="flex flex-col gap-3 py-5 px-6 text-[#959595]">
            <span className=" text-sm text-primaryColor font-[500]">
              Input your reply or choose from our predefined responses
            </span>

            <div className="rounded-[5px] text-[#959595] border border-primaryColor h-[100px] flex items-start flex-col pt-[2%]">
              <div className="flex flex-wrap justify-start items-center">
                {selectedReason?.map((option) => (
                  <span
                    key={option.id}
                    className="border border-[#F08000] text-primaryColor rounded-md px-2 py-2 ml-2"
                  >
                    {option.label}
                    <button
                      className="ml-1"
                      onClick={() =>
                        setSelectedReason((prevSelected) =>
                          prevSelected.filter((item) => item !== option)
                        )
                      }
                    >
                      &#10006;
                    </button>
                  </span>
                ))}
                <input
                  className="outline-none px-5"
                  type="text"
                  onChange={(e) => setUserReason(e.target.value)}
                  value={userReason}
                />
              </div>
            </div>

            <div className=" flex gap-3 flex-wrap  mt-5">
              <MultiSelectComponent setSelectedReason={setSelectedReason} />
            </div>

            <motion.button
              whileTap={{ scale: 0.9 }}
              onClick={handleRejectFunding}
              className=" w-full mt-5 rounded-[5px] text-white bg-primaryColor px-6 py-3"
            >
              Submit
            </motion.button>
            {/* Congratulations! request Accepted. You've successfully agreed to
              the terms. Expect further details on the transaction shortly.
              Thank you! */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DirectFundingReasonForRequiredModal;
