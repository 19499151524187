import React, { useState, useEffect } from "react";
import {
  PAIconVieweye,
  PAIconMDISuccess,
  PAIconIconParkSucess,
  PAICalenderPickerIcon,
} from "assets/images/svgs";
import Table from "layout/table/Table";
import Loading from "shared/Loading";
import TableHeaderTrans from "../components/TableHeaderTrans";
import { useNavigate } from "react-router-dom";
import DisputeCheckModal from "../components/modal/DisputeCheckModal";
import TransferInfoModal from "../components/modal/TranferInfoModal";
import toast from "react-hot-toast";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const ReconciliationMain = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [data, setData] = useState([]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isSelectingStartDate, setIsSelectingStartDate] = useState(true);

  const handleDateChange = (date) => {
    if (isSelectingStartDate) {
      setStartDate(date);
      if (endDate && date > endDate) {
        setEndDate(null);
      }
    } else {
      setEndDate(date);
    }
  };

  const [disputeText, setDisputeText] = useState("");

  const onChangeText = (e) => {
    e.preventDefault();
    setDisputeText(e.target.value);
  };

  const navigate = useNavigate();

  const dummyTransactions = [
    {
      id: "1",
      transactionId: "04347T-2090",
      funderFullName: "Flour Mills",
      invoiceDate: "2023-05-03",
      paymentDate: "2023-05-01",
      currency: "USD",
      amount: "+9,000,000",
      status: "SUCCESSFUL",
      type: "Disbursement",
      sign: "Cr",
    },
    {
      id: "2",
      transactionId: "04347T-2090",
      funderFullName: "Flour Mills",
      invoiceDate: "2023-05-03",
      paymentDate: "2023-05-02",
      currency: "USD",
      amount: "+4,000,000",
      status: "FAILED",
      deposit: "Withdrawal",
      type: "Deposit",
      sign: "Cr",
    },
    {
      id: "3",
      transactionId: "04347T-2090",
      funderFullName: "Flour Mills",
      invoiceDate: "2023-05-03",
      paymentDate: "2023-05-03",
      currency: "USD",
      amount: "+2,000,000",
      status: "PENDING",
      deposit: "Deposit",
      type: "Withdrawal",
      sign: "Dr",
    },
    {
      id: "4",
      transactionId: "04347T-2090",
      funderFullName: "Flour Mills",
      invoiceDate: "2023-05-03",
      paymentDate: "2023-05-03",
      currency: "USD",
      amount: "+2,000,000",
      status: "PENDING",
      type: "Disbursement",
      sign: "Cr",
    },
  ];

  const options = [
    { id: 1, name: "All" },
    { id: 2, name: "Open" },
    { id: 3, name: "Fulfilled" },
    { id: 4, name: "Cancelled" },
    { id: 5, name: "Closed" },
  ];

  useEffect(() => {
    setData(dummyTransactions);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const navigateViewtransDetail = () => {
    navigate("/wallet/transactions");
  };

  const navigateToDetail = (id) => {
    navigate(`/settlement/detail/reconciliationdetail/${id}`);
  };

  const confirmDisputeModal = () => {
    setModalOpen(false);
    setInfoModalOpen(true);
  };

  const columns = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Transaction ID
        </span>
      ),
      selector: (row) => row.transactionId,
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Transaction Party
        </span>
      ),

      selector: (row) => row.funderFullName,
      sortable: true,
      width: "160px",
    },
    {
      name: "Transaction Date",
      selector: (row) => row.invoiceDate,
      sortable: true,
      width: "160px",
    },
    {
      name: "Transaction Type",
      selector: (row) => row.type,
      sortable: true,
    },
    {
      name: "Sign",
      selector: (row) => row.sign,
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      cell: (row) =>
        row.sign === "Cr" ? (
          <p className="text-success">{row.amount}</p>
        ) : row.sign === "Dr" ? (
          <p className="text-[#BB0C0C]">{row.amount}</p>
        ) : (
          <p>{row.amount}</p>
        ),
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) =>
        row.status === "SUCCESSFUL" ? (
          <p className="text-success">{row.status}</p>
        ) : row.status === "FAILED" ? (
          <p className="text-red-600">{row.status}</p>
        ) : row.status === "PENDING" ? (
          <p className="text-yellow-400">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        ),
    },
    {
      //   name: "Details",
      selector: (row) => row.view,
      cell: (row) => (
        <div className="flex">
          <PAIconVieweye height="15px" width="30px" />
          <p className="text-[#626679] cursor-pointer">View</p>
        </div>
      ),
    },
  ];

  const disputeTypeData = [
    { id: 1, type: "Failed Transfer" },
    { id: 2, type: "Double Debit" },
    { id: 3, type: "Erroneous Transfer" },
    { id: 4, type: "Unauthorized Transfer" },
    { id: 5, type: "Others" },
  ];
  function handleProceedToImport() {
    setInfoModalOpen(false);
    toast.custom(
      <>
        <div className="flex-1 items-center justify-center flex  w-full h-full">
          <div className="flex mt-[200px]  ">
            <div className="bg-primaryColor w-[147.79px]  h-[160px] flex flex-col items-center justify-center rounded-l-[7px] shadow-lg">
              <PAIconMDISuccess />
            </div>
            <div className="bg-white  flex-col justify-center items-center self-stretch py-[25px] h-[160px] rounded-r-[7px] shadow-lg flex px-16 ">
              <PAIconIconParkSucess />
              <p className="text-dustyGray text-[13px] text-center mt-[17.6px]">
                Dispute sent successfully
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
  useEffect(() => {}, [endDate, startDate]);

  return (
    <>
      <DisputeCheckModal
        isOpen={modalOpen}
        message={
          <>
            <div class=" text-center text-neutral-500 text-[13px] font-normal  tracking-tight">
              Check completed, payment discrepancies found
            </div>
          </>
        }
        onClose={() => setModalOpen(false)}
        onConfirm={confirmDisputeModal}
      />
      <TransferInfoModal
        isOpen={infoModalOpen}
        onClose={() => setInfoModalOpen(false)}
        onConfirm={handleProceedToImport}
        disputeTypeData={disputeTypeData}
        onChange={onChangeText}
        textareaValue={disputeText}
        bodyText={"Narration"}
        labelTitleText={"Dispute Type"}
      />
      <div className=" flex-1 w-full "> </div>

      {/* new section */}

      <div className=" w-full flex-1">
        <div class=" text-base font-medium ">Reconciliation Period:</div>

        <div className="flex justify-between mt-2  flex-wrap gap-4">
          <div className=" flex justify-between space-x-2  flex-wrap gap-2">
            <div class=" h-10  py-2 bg-white rounded flex-col justify-center items-start px-2  ">
              <div class="justify-start items-center gap-3 flex ">
                <div
                  class="w-6 h-6 relative cursor-pointer"
                  onClick={() => setIsSelectingStartDate(true)}
                >
                  <PAICalenderPickerIcon />
                </div>
                <div
                  class="text-neutral-600 text-base font-medium w-1/2 "
                  // onClick={() => setIsSelectingStartDate(true)}
                >
                  {/* _ / _ / _{" "} */}
                  <DatePicker
                    selected={startDate}
                    onChange={handleDateChange}
                    selectsStart={isSelectingStartDate}
                    startDate={startDate}
                    endDate={endDate}
                    minDate={isSelectingStartDate ? null : startDate}
                    className=" p-0  rounded   focus:outline-none text-[#565656] text-base font-medium "
                  />
                </div>
              </div>
            </div>

            {/* second */}

            <div class=" h-10 py-2 bg-white rounded flex-col justify-center items-start  px-2 ">
              <div class="justify-start items-center gap-3 inline-flex">
                <div class="w-6 h-6 relative cursor-pointer">
                  <PAICalenderPickerIcon />
                </div>
                <div class="text-neutral-600 text-base font-medium ">
                  {/* _ / _ / _{" "} */}
                  <div>
                    <DatePicker
                      selected={endDate}
                      onChange={handleDateChange}
                      selectsStart={isSelectingStartDate}
                      startDate={startDate}
                      endDate={endDate}
                      minDate={isSelectingStartDate ? null : startDate}
                      className=" p-0  rounded   focus:outline-none text-[#565656] text-base font-medium "
                      onFocus={() =>
                        startDate && setIsSelectingStartDate(false)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* third */}

            <button
              class="w-[91px] h-[41px] px-6 py-3 bg-[#07593D] rounded-[5px] justify-start items-center gap-2.5 inline-flex"
              onClick={() => setModalOpen(true)}
            >
              <div class="text-white text-sm font-medium ">Check</div>
            </button>
          </div>

          {/* emnd it here */}
        </div>

        <div className=" flex flex-row justify-between w-full my-7 flex-wrap ">
          {/* first box */}
          <div class=" lg:w-[49%] h-40 relative bg-white rounded-lg w-full">
            <div class="px-6  py-[17px]  justify-start items-center   bg-zinc-100  w-full">
              <div class="text-neutral-600 text-base font-medium  uppercase">
                Cleared Balance
              </div>
            </div>
            <div class=" w-full  top-[81px] absolute justify-between items-center inline-flex px-6">
              <div class="flex-col justify-start items-start gap-2 inline-flex">
                <div class="text-[#7a7a7a] text-sm font-normal  ">
                  Opening Balance
                </div>
                <div class="justify-start items-center gap-[3px] inline-flex">
                  <div class="justify-start items-center flex">
                    <div class="text-neutral-800 text-xl font-bold ">
                      340,090.00
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-neutral-800 text-lg font-medium ">-</div>
              <div class="flex-col justify-start items-start gap-2 inline-flex">
                <div>
                  <span className="text-[#7a7a7a] text-sm font-bold  ">0</span>
                  <span className="text-[#7a7a7a] text-sm font-normal  ">
                    {" "}
                    Debits
                  </span>
                </div>
                <div class="justify-start items-center gap-[3px] inline-flex">
                  <div class="justify-start items-center flex">
                    <div class="text-neutral-800 text-xl font-bold ">0.00</div>
                  </div>
                </div>
              </div>
              <div class="text-neutral-800 text-lg font-medium ">+</div>
              <div class="flex-col justify-start items-start gap-2 inline-flex">
                <div>
                  <span className="text-[#7a7a7a] text-sm font-bold  ">0</span>
                  <span className="text-[#7a7a7a] text-sm font-normal ">
                    {" "}
                    Credits
                  </span>
                </div>
                <div class="justify-start items-center gap-[3px] inline-flex">
                  <div class="justify-start items-center flex">
                    <div class="text-neutral-800 text-xl font-bold ">0.00</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* second box */}
          <div class=" lg:w-[49%] h-40 relative bg-white rounded-lg w-full">
            <div class="px-6  py-[17px]  justify-start items-center   bg-zinc-100  w-full">
              <div class="text-neutral-600 text-base font-medium  uppercase">
                RECONCILE
              </div>
            </div>
            <div class=" w-full  top-[81px] absolute justify-between items-center inline-flex px-6">
              <div class="flex-col justify-start items-start gap-2 inline-flex">
                <div class="text-[#7a7a7a] text-sm font-normal ">
                  Closing Balance
                </div>
                <div class="justify-start items-center gap-[3px] inline-flex">
                  <div class="justify-start items-center flex">
                    <div class="text-neutral-800 text-xl font-bold ">
                      220,090.00
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-neutral-800 text-lg font-medium ">-</div>
              <div class="flex-col justify-start items-start gap-2 inline-flex">
                <div>
                  <span className="text-[#7a7a7a] text-sm font-normal  ">
                    {" "}
                    Cleared Balance
                  </span>
                </div>
                <div class="justify-start items-center gap-[3px] inline-flex">
                  <div class="justify-start items-center flex">
                    <div class="text-neutral-800 text-xl font-bold ">0.00</div>
                  </div>
                </div>
              </div>
              <div class="text-neutral-800 text-lg font-medium ">=</div>
              <div class="flex-col justify-start items-start gap-2 inline-flex">
                <div>
                  <span className="text-[#7a7a7a] text-sm font-normal  ">
                    {" "}
                    Difference
                  </span>
                </div>
                <div class="justify-start items-center gap-[3px] inline-flex">
                  <div class="justify-start items-center flex">
                    <div class="text-neutral-800 text-xl font-bold ">0.00</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* stops here */}
        </div>

        <div className="bg-white h-auto rounded-md">
          <TableHeaderTrans
            options={options}
            transactions={true}
            className="mt-5"
            navigateTo={navigateViewtransDetail}
          />

          <Table
            columns={columns}
            data={data}
            pointer
            className="mt-5 px-4"
            onRowClicked={(row) => navigateToDetail(row.id)}
            progressPending={false}
            progressComponent={<Loading />}
          />
        </div>
      </div>
    </>
  );
};

export default ReconciliationMain;
