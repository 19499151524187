import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AcceptModal from "shared/AcceptModal";
import SuccessModal from "shared/SuccessModal";
import { formatCapitaliseString } from "utilities/helper/formatCapitaliseString";

function DirectFundingInvoice({ invoiceByIdData }) {
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  const handleConfirm = () => {
    setIsAcceptModalOpen(false);
    setIsSuccessModalOpen(true);
    setSuccessMessage("Payment remainders sent successfully");
  };

  const handleSuccessClose = () => {
    setIsSuccessModalOpen(false);
    navigate(-1);
  };

  return (
    <motion.section
      initial={{
        x: 20,
      }}
      animate={{
        x: 0,
      }}
      exit={{
        x: -20,
      }}
      transition={{
        delay: 0.6,
      }}
      className="flex flex-col gap8  pt-7  w-[60%] semilg:w-[55%] bg-white rounded-[10px]"
    >
      <div className="mb-2 flex flex-col gap-3  px-6">
        <header className="flex items-center justify-between">
          <h1 className=" text-[18px] font-bold">
            Reference No | {invoiceByIdData?.invoiceNumber || "NIL"}
          </h1>
          <button
            className="bg-primaryColor px-4 py-2 text-white rounded"
            onClick={() => setIsAcceptModalOpen(true)}
          >
            Send Remainder Now
          </button>
        </header>

        <main className="w-full flex items-center justify-between">
          <div className="flex flex-col gap-3 w-2/5">
            <div className="flex flex-col gap-1">
              <div className="flex justify-between">
                <span className=" font-semibold text-xs">Invoice Number:</span>
                <span className="text-[#979797]  text-xs">
                  INV-{invoiceByIdData?.invoiceNo || "NIL"}
                </span>
              </div>
              <div className="flex justify-between">
                <span className=" font-semibold text-xs">Created Date:</span>
                <span className="text-[#979797]  text-xs">
                  {invoiceByIdData?.createdDate?.slice(0, 10) || "NIL"}
                </span>
              </div>
              <div className="flex justify-between">
                <span className=" font-semibold text-xs">Submitted Date:</span>
                <span className="text-[#979797]  text-xs">
                  {invoiceByIdData?.submittedDate?.slice(0, 10) || "NIL"}
                </span>
              </div>
              <div className="flex justify-between">
                <span className=" font-semibold text-xs">Approved Date:</span>
                <span className="text-[#979797]  text-xs">
                  {invoiceByIdData?.approvedDate?.slice(0, 10) || "NIL"}
                </span>
              </div>
              <div className="flex justify-between">
                <span className=" font-semibold text-xs">Due Date:</span>
                <span className="text-[#979797]  text-xs">
                  {invoiceByIdData?.dueDate?.slice(0, 10) || "NIL"}
                </span>
              </div>

              <div className="flex justify-between">
                <span className=" font-semibold text-xs">Issuance Date</span>
                <span className="text-[#979797]  text-xs">
                  {invoiceByIdData?.issuanceDate?.slice(0, 10) || "NIL"}
                </span>
              </div>
            </div>
          </div>

          {/* STATUS */}
          <div className="rounded-md py-1.5 bg-[#F080000F] px-6">
            <p className="text-center text-secondaryColor text-xs font-bold">
              {formatCapitaliseString(invoiceByIdData?.status || "NIL")}
            </p>
          </div>
        </main>
      </div>

      {/* Buyer details and co. */}
      <div className="flex justify-between my-5 border-b-[5px] border-b-[#F6F5FA] py-3 px-6">
        <div className="flex flex-col">
          <span className="font-bold text-sm">
            {invoiceByIdData?.buyerCompanyName || "NIL"}
          </span>
          <span className="text-[#979797] text-xs">Buyer details</span>
        </div>
        <div className="flex flex-col">
          <span className="font-bold text-sm">
            {invoiceByIdData?.paymentTerms || "NIL"}
          </span>
          <span className="text-[#979797] text-xs">Payment</span>
        </div>
        <div className="flex flex-col">
          <span className="font-bold text-sm ">
            {invoiceByIdData?.currency || "NIL"}
          </span>
          <span className="text-[#979797] text-xs">Currency</span>
        </div>
      </div>

      <div className="flex  flex-col gap-4 ">
        <header className="border-b-[5px] border-b-[#F6F5FA] pb-3 flex items-center justify-between px-6">
          <h1 className="text-[18px] font-normal">Line Items</h1>
          <p>{invoiceByIdData?.items?.length || "0"} Total Items</p>
        </header>

        <div className=" w-full overflow-x-auto px-6">
          <table className=" w-full overflow-x-auto">
            <thead className="bg-subtleGray w-">
              <tr className="bg-unusualGray p-2.5 ">
                <td className="text-[#11192A] p-2.5 text-xs font-bold">Item</td>
                <td className="p-2.5 text-xs font-bold">Description</td>
                <td className="p-2.5 text-xs font-bold">Unit Price</td>
                <td className="p-2.5 text-xs font-bold">Quantity</td>
                <td className="p-2.5 text-xs font-bold">
                  Amount
                  <br />
                  (before tax)
                </td>
                <td className="p-2.5 text-xs font-bold">Tax Rate</td>
                <td className="p-2.5 text-xs font-bold">Tax Amount</td>
                <td className="p-2.5 text-xs font-bold">Total Price</td>
              </tr>
            </thead>
            <tbody>
              {invoiceByIdData?.items?.map((item, index) => (
                <tr key={index} className=" py-[3px] px-1.5">
                  <td className=" text-xs text-[#11192a] p-2.5">
                    {item?.itemName}
                  </td>
                  <td className=" text-xs text-[#11192a] p-2.5">
                    {item?.description}
                  </td>
                  <td className=" text-xs text-[#11192a] p-2.5">
                    {item?.unitPrice?.toLocaleString()}
                  </td>
                  <td className=" text-xs text-[#11192a] p-2.5">
                    {item?.quantity}
                  </td>
                  <td className=" text-xs text-[#11192a] p-2.5">
                    {item?.amount?.toLocaleString()}
                  </td>
                  <td className=" text-xs text-[#11192a] p-2.5">
                    {item?.taxRate}%
                  </td>
                  <td className=" text-xs text-[#11192a] p-2.5">
                    {item?.taxAmount?.toLocaleString()}
                  </td>
                  <td className=" text-xs text-[#11192a] p-2.5">
                    {item?.totalPrice?.toLocaleString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Accounts Information */}
        <div className="flex itemscenter justify-between p-6">
          <div>
            <h1 className="border-b border-[#B1A6B2] font-semibold">
              Receiving Funding Account Information
            </h1>
            <p className="mt-3 text[#979797]">
              {invoiceByIdData?.virtualAccount?.accountNumber}
            </p>
            <p className="text[#979797]">
              {invoiceByIdData?.virtualAccount?.accountName}
            </p>
          </div>
          <div className="flex flex-col">
            <div className="flex justify-between gap-x-[100px] w-full">
              <span className="text-xs w-[97px]">Sub Total</span>
              <span className="text-[rgb(151,151,151)] text-xs text-right">
                {invoiceByIdData?.subTotal?.toLocaleString()}
              </span>
            </div>

            <div className="flex justify-between w-full gap-x-[100px] my-2">
              <span className="flex items-center text-xs w-[97px]">
                Discount%
                <span className="border border-[#979797] pr-10 pl-2 inline-block ml-4">
                  {invoiceByIdData?.discount}%
                </span>
              </span>
              <span className="text-[#979797] text-xs">
                {invoiceByIdData?.discountAmount?.toLocaleString()}
              </span>
            </div>

            <div className="flex justify-between w-full gap-x-[100px] pb-5">
              <span className="flex items-center text-xs w-[97px]">
                VAT%
                <span className="border border-[#979797] ml-10 pr-11 pl-2 inline-block">
                  {invoiceByIdData?.vat}%
                </span>
              </span>
              <span className="text-[#979797] text-xs">
                {invoiceByIdData?.vatAmount?.toLocaleString()}
              </span>
            </div>

            <div className="flex justify-between items-center w-full gap-x-[100px] border-t-2 border-b-2 font-bold text-lg py-1">
              <span className="w-[97px]">Grand Total</span>
              <span className="text[#979797] py-1">
                {invoiceByIdData?.grandTotal?.toLocaleString()}
              </span>
            </div>
          </div>
        </div>

        
      </div>

      <AnimatePresence>
        <AcceptModal
          isOpen={isAcceptModalOpen}
          onClose={() => setIsAcceptModalOpen(false)}
          yes
          message={
            <>
              Would you like to proceed with sending a <br />
              <span className="text-[#07593D] font-bold">
                Payment reminder
              </span>{" "}
              now?
            </>
          }
          onConfirm={handleConfirm}
        />

        <SuccessModal
          isOpen={isSuccessModalOpen}
          onClose={handleSuccessClose}
          message={successMessage}
        />
      </AnimatePresence>
    </motion.section>
  );
}

export default DirectFundingInvoice;
