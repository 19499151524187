import { PAIconAddRole } from "assets/images/svgs";
import { AnimatePresence, motion } from "framer-motion";
const AddUserHeader = ({
  umRole,
  toggleUserModalOn,
  toggleRoleScreenOn,
  toggleNewRoleOn,
}) => {
  const handleAddRole = () => {
    toggleNewRoleOn();
  };
  return (
    <div className="rounded-[10px] bg-[#FAFAFA] px-6 py-3 flex w-full justify-between">
      <div className="flex items-center">
        <span className="text-[14px] font-[500]">
          {umRole ? "Roles and Permission" : "Team Members"}
        </span>
      </div>

      {umRole ? (
        <motion.div
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -20 }}
          transition={{ duration: 0.4 }}
          className="flex gap-2"
        >
          <button
            onClick={() => handleAddRole()}
            className={`bg-[#07593D] py-3 px-6 rounded-[5px] flex items-center gap-1 text-white`}
          >
            <span>
              <PAIconAddRole />
            </span>
            <span className="text-[14px] font-[500]">Add New Role</span>
          </button>
        </motion.div>
      ) : (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 20 }}
            transition={{ duration: 0.4 }}
            className="flex gap-2"
          >
            <button
              onClick={() => {
                toggleUserModalOn();
              }}
              className={` bg-[#07593D] py-3 px-6 rounded-[5px] flex items-center gap-1 text-white`}
            >
              <span>
                <PAIconAddRole />
              </span>
              <span className="text-[14px] font-[500]">Add User</span>
            </button>
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  );
};

export default AddUserHeader;
