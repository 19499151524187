import { PABlackSmallDangote, PAIconCocacola } from "assets/images/svgs";
import { useSelector } from "react-redux";
import TransitionScale from "shared/animation/TransitionScale";
import { formatCapitalize } from "utilities/formatCapitalize";
import { getDaysLeft } from "utilities/getDaysLeft";
import { formatCurrency } from "utilities/helper/formatters";

const ClosedMarketDetailsRight = () => {
  const { ongoingBidDetails } = useSelector((state) => state?.funder);

  return (
    <TransitionScale className="bg-white w-full rounded-[10px] gap-6">
      <div class="w-full justify-start items-center gap-4 inline-flex ">
        <div class=" justify-start items-center gap-2.5 flex w-full px-6  py-3">
          <div class="text-black text-xl font-bold">Closed Market Details</div>
        </div>
      </div>

      <div>
        <div className="   pb-4">
          <div class=" justify-between  flex flex-row    px-6 mt-4  ">
            <div className="flex gap-2 py-2.5">
              <PAIconCocacola />
              <div className="flex flex-col">
                <span class="text-black text-sm font-normal ">
                  {formatCapitalize(
                    ongoingBidDetails?.closedMarketRequestDetails
                      ?.supplierCompanyName
                  )}
                </span>
                {/* <span className="text-[#979797]  text-xs">Supplier</span> */}
                <span class="text-[#949494] text-sm font-normal ">
                  Supplier
                </span>
              </div>
            </div>
            <div className="flex gap-2 py-2.5">
              <PABlackSmallDangote />
              <div className="flex flex-col">
                <span class="text-black text-sm font-normal ">
                  {formatCapitalize(
                    ongoingBidDetails?.closedMarketRequestDetails
                      ?.buyerCompanyName
                  )}
                </span>
                <span class="text-[#949494] text-sm font-normal ">Buyer</span>
              </div>
            </div>

            {/* here */}
          </div>
          <div class=" justify-between  flex flex-row    px-6  mt-6 ">
            <div class="py-2.5 flex-col justify-start items-start inline-flex">
              <div class="flex-col justify-start items-start gap-0.5 flex">
                <div class="text-black text-sm font-normal ">
                  NGN{" "}
                  {formatCurrency(
                    ongoingBidDetails?.closedMarketRequestDetails
                      ?.discountAmount
                  )}
                </div>
              </div>
              <div class="flex-col justify-start items-start  flex">
                <div class="text-[#949494] text-sm font-normal ">
                  Discount amount
                </div>
              </div>
            </div>
            {/* hd */}
            <div class="py-2.5 flex-col justify-start items-start inline-flex">
              <div class="flex-col justify-start items-start gap-0.5 flex">
                <div class="text-black text-sm font-normal ">
                  {
                    ongoingBidDetails?.closedMarketRequestDetails
                      ?.earlyPaymentDate
                  }{" "}
                  (
                  {getDaysLeft(
                    ongoingBidDetails?.closedMarketRequestDetails
                      ?.earlyPaymentDate
                  )}{" "}
                  days)
                </div>
              </div>
              <div class="flex-col justify-start items-start  flex">
                <div class="text-[#949494] text-sm font-normal ">
                  Early Payment Date
                </div>
              </div>
            </div>{" "}
          </div>
          <div class=" justify-between  flex flex-row    border-b border-[#c2c2c2]/30 px-6  py-3  pb-8 mt-6">
            <div class="py-2.5 flex-col justify-start items-start inline-flex">
              <div class="flex-col justify-start items-start gap-0.5 flex">
                <div class="text-black text-sm font-normal ">
                  N{" "}
                  {formatCurrency(
                    ongoingBidDetails?.closedMarketRequestDetails?.requestAmount
                  )}{" "}
                </div>
              </div>
              <div class="flex-col justify-start items-start  flex">
                <div class="text-[#949494] text-sm font-normal ">
                  Request amount
                </div>
              </div>
            </div>
            {/* dhdh */}
            <div class="py-2.5 flex-col justify-start items-start inline-flex">
              <div class="flex-col justify-start items-start gap-0.5 flex">
                <div class="text-black text-sm font-extrabold">
                  N{" "}
                  {formatCurrency(
                    ongoingBidDetails?.closedMarketRequestDetails
                      ?.fundableAmount
                  )}{" "}
                </div>
              </div>
              <div class="flex-col justify-start items-start  flex">
                <div class="text-[#949494] text-sm font-normal ">
                  Recievable Amount
                </div>
              </div>
            </div>
          </div>
          {/* 2nd */}

          <div class="justify-between  flex flex-col  px-6">
            <div class="text-black text-lg font-semibold  mt-6">
              Funding Option
            </div>

            <div class=" text-[#949494] font-light  my-3 leading-6">
              This invoice would be funded from this virtual account:
            </div>
          </div>
          <div
            style={{ color: "rgba(0, 0, 0, 0.50)" }}
            className="flex flex-col px-6 text-sm font-semibold"
          >
            <span>
              {formatCapitalize(
                ongoingBidDetails?.closeMarketInvoice?.virtualAccount
                  ?.accountName
              )}
            </span>

            <span>
              {" "}
              {formatCapitalize(
                ongoingBidDetails?.closeMarketInvoice?.virtualAccount?.accountNumber?.slice(
                  0,
                  2
                )
              )}
              *******
              {formatCapitalize(
                ongoingBidDetails?.closeMarketInvoice?.virtualAccount?.accountNumber?.slice(
                  7,
                  11
                )
              )}
            </span>
            <span> PayEdge MFB</span>
          </div>
        </div>
      </div>
    </TransitionScale>
  );
};

export default ClosedMarketDetailsRight;
