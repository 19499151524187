import { useState, useEffect } from "react";
import emptyBid from "../../../../../assets/images/svgs/empty-bid.png";

const BidDetails = ({ data, invoiceData }) => {
  const [isActive, setIsActive] = useState("history");
  const [tab, setTab] = useState("payment");

  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      const paymentDateObj = new Date(
        `${data?.earlyPaymentDate}T00:00:00.000Z`
      );
      const currentDate = new Date();
      const timeDiff = paymentDateObj.getTime() - currentDate.getTime();

      if (timeDiff > 0) {
        const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

        setCountdown({
          days,
          hours,
          minutes,
          seconds,
        });
      } else {
        clearInterval(intervalId);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [data?.earlyPaymentDate]);

  // Add this line to log the timer only when it's defined

  return (
    <div className="bg-white w-full rounded-[10px] pt-5 pb-24 h-[100%]">
      <div className="max-h-[15%]">
        <div className="flex items-center border-b-[1px] border-b-slate-300">
          <h2
            onClick={() => {
              setTab("payment");
            }}
            style={{
              color: tab === "payment" ? "#07593D" : "#777777",
              borderBottom: tab === "payment" ? "1px solid #07593D" : "none",
            }}
            className="text-lg px-6 font-semibold text-gray-800 pb-4"
          >
            Early Payment Details
          </h2>
          <h2
            onClick={() => {
              setTab("bids");
            }}
            style={{
              color: tab === "bids" ? "#07593D" : "#777777",
              borderBottom: tab === "bids" ? "1px solid #07593D" : "none",
            }}
            className="text-lg px-6 font-semibold text-gray-800 pb-4 text-primaryColor"
          >
            Bid Details
          </h2>
        </div>

        {tab === "payment" ? null : (
          <div className="flex justify-between text-sm text-gray-600 mt-4 px-6">
            <span
              onClick={() => {
                setIsActive("history");
              }}
              className={
                isActive === "history"
                  ? "text-secondaryColor border-b-[1px] border-secondaryColor"
                  : "text-[#959595]"
              }
            >
              Bid history: {data?.bidHistory?.length || "0"}
            </span>
            <span
              onClick={() => {
                setIsActive("invited");
              }}
              className={
                isActive === "invited"
                  ? "text-secondaryColor border-b-[1px] border-secondaryColor"
                  : "text-[#959595]"
              }
            >
              Funders invited: {data?.funderInvitedResponse?.length}
            </span>
            <span
              onClick={() => {
                setIsActive("viewed");
              }}
              className={
                isActive === "viewed"
                  ? "text-secondaryColor border-b-[1px] border-secondaryColor"
                  : "text-[#959595]"
              }
            >
              Viewed by funders: {data?.viewByFunderResponse?.length}
            </span>
          </div>
        )}
      </div>

      {tab === "payment" ? (
        <div className="px-4">
          <div className="flex justify-between my-5">
            <div className="flex gap-2">
              {/* <PAIconModelOne /> */}
              <div className="flex flex-col">
                <span className="text-sm">Supplier</span>
                <span className="text-[#979797]  text-xs">
                  {" "}
                  {data?.supplierCompanyName}
                </span>
              </div>
            </div>
            <div className="flex flex-col items-end">
              <span className="text-sm">{data?.discountRate}%</span>
              <span className="text-[#979797]  text-xs">Discount rate</span>
            </div>
          </div>

          <div className="flex justify-between pb-5">
            <div className="flex gap-2">
              <div className="flex flex-col">
                <span className="text-sm">
                  {invoiceData?.currency}{" "}
                  {data?.discountAmount?.toLocaleString() || "0.00"}
                </span>
                <span className="text-[#979797]  text-xs">Discount Amount</span>
              </div>
            </div>
            <div className="flex flex-col items-end">
              <span className="text-sm">
                {data?.earlyPaymentDate}({data?.paymentDay} days)
              </span>
              <span className="text-[#979797]  text-xs">
                Early Payment Request Date
              </span>
            </div>
          </div>

          <div className="flex justify-between mt-5 pb-10">
            <div className="flex gap-2">
              <div className="flex flex-col">
                <span className="text-sm">
                  {invoiceData?.currency}{" "}
                  {data?.requestAmount?.toLocaleString() || "0.00"}
                </span>
                <span className="text-[#979797]  text-xs">Request Amount</span>
              </div>
            </div>
            <div className="flex flex-col">
              <span className="font-semibold text-[16px]">
                {invoiceData?.currency} {data?.payableAmount?.toLocaleString()}
              </span>
              <span className="text-[#979797] text-xs">Payable amount</span>
            </div>
          </div>
        </div>
      ) : (
        <>
          {isActive === "history" && (
            <div className="flex flex-col justify-center items-center mb-6 h-[65%]">
              <img src={emptyBid} alt="No Data" className="h-80 w-auto" />
              <div className="flex items-start justify-center gap-2 text-center text-3xl font-bold text-gray-800 mb-2">
                <div>
                  <p>{countdown.days}</p>
                  <p className="text-[12px] text-[#777777]">days</p>
                </div>
                :{" "}
                <div>
                  <p>{countdown.hours}</p>
                  <p className="text-[12px] text-[#777777]">hours</p>
                </div>
                :{" "}
                <div>
                  <p>{countdown.minutes}</p>
                  <p className="text-[12px] text-[#777777]">minutes</p>
                </div>{" "}
                :{" "}
                <div>
                  <p>{countdown.seconds}</p>
                  <p className="text-[12px] text-[#777777]">seconds</p>
                </div>
              </div>
              <p className="text-center text-gray-500 mb-4">
                Count down to early payment date
              </p>
            </div>
          )}

          {isActive === "invited" && (
            <div className="flex flex-col justify-start px-5 mb-6 h-[65%]">
              {data?.funderInvitedResponse?.map((item, index) => (
                <div
                  key={index}
                  className="flex justify-between items-center mb-4"
                >
                  <div className="flex items-center my-2">
                    {/* <img
                  src={item.img}
                  alt="Funder"
                  className="w-10 h-10 rounded-full mr-4"
                /> */}
                    <div>
                      <p className="text-[16px] font-regular text-gray-600">
                        {item.funderName}
                      </p>
                      <p className="text-[12px] font-regular text-zinc-400">
                        Funder
                      </p>
                    </div>
                  </div>

                  <div className="text-center">
                    <p className="text-[14px] ">{item.contactNumber}</p>
                    <p className="text-[12px] text-zinc-400">Contact number</p>
                  </div>

                  <div className="text-right">
                    <p className="text-sm text-gray-600">{item.funderEmail}</p>
                    <p className="text-[12px] text-zinc-400">Email</p>
                  </div>
                </div>
              ))}
            </div>
          )}

          {isActive === "viewed" && (
            <div className="flex flex-col justify-start px-5 mb-6 h-[65%]">
              {data?.viewByFunderResponse?.length > 0 ? (
                <>
                  {data?.viewByFunderResponse?.map((item, index) => (
                    <div
                      key={index}
                      className="flex justify-between items-center mb-4"
                    >
                      <div className="flex items-center my-2">
                        <img
                          src={item.img}
                          alt="Funder"
                          className="w-10 h-10 rounded-full mr-4"
                        />
                        <div>
                          <p className="text-[16px] font-regular text-gray-600">
                            {item.name}
                          </p>
                          <p className="text-[12px] font-regular text-zinc-400">
                            Funder
                          </p>
                        </div>
                      </div>

                      <div className="text-center">
                        <p className="text-[14px] ">{item.number}</p>
                        <p className="text-[12px] text-zinc-400">
                          Contact number
                        </p>
                      </div>

                      <div className="text-right">
                        <p className="text-sm text-gray-600">{item.number}</p>
                        <p className="text-[12px] text-zinc-400">Email</p>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div className="flex flex-col justify-center items-center mb-6 h-[65%]">
                  <img src={emptyBid} alt="No Data" className="h-80 w-auto" />

                  <p className="text-center text-gray-500 mb-4">
                    Viewed funder list is empty
                  </p>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BidDetails;
