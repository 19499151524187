import { PAIconInventoryArrowLeft } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import Table from "layout/table/Table";
import { useNavigate } from "react-router-dom";
import Button from "shared/Button";
import TableIcons from "../../assets/images/svgs/TableIcons.svg";
import { useState } from "react";
import BulkRequestModal from "features/invoice/fundingSection/earlyPaymentRequest/singlerequest/Modal/BulkRequestModal";
import BulkInvoiceModal from "features/invoice/fundingSection/earlyPaymentRequest/singlerequest/Modal/bulkinvoicemodal";
import ReceivingFundModal from "features/invoice/fundingSection/earlyPaymentRequest/singlerequest/Modal/ReceivingFundModal";
import VirtualAccountSelection from "features/invoice/fundingSection/earlyPaymentRequest/singlerequest/Modal/VirtualAccountSelection";
import EarlyPaymentConfirmation from "features/invoice/fundingSection/earlyPaymentRequest/singlerequest/Modal/EarlyPaymentConfirmation";
import SuccessEarlyPayment from "features/invoice/fundingSection/earlyPaymentRequest/singlerequest/Modal/SuccessEarlyPayment";
import PinModalPayment from "features/invoice/fundingSection/earlyPaymentRequest/singlerequest/Modal/PinModal";
import TransferFundModal from "features/invoice/fundingSection/earlyPaymentRequest/singlerequest/Modal/TransferFund";

const data = [
  {
    invoiceReference: "INV001",
    invoiceDate: "2024-09-15",
    dueDate: "2024-10-15",
    currency: "NGN",
    invoiceAmount: "1,500,000",
    status: "Approved",
  },
  {
    invoiceReference: "INV002",
    invoiceDate: "2024-09-20",
    dueDate: "2024-10-20",
    currency: "NGN",
    invoiceAmount: "250,000,000",
    status: "Approved",
  },
  {
    invoiceReference: "INV003",
    invoiceDate: "2024-09-22",
    dueDate: "2024-10-22",
    currency: "NGN",
    invoiceAmount: "180,000",
    status: "Approved",
  },
  {
    invoiceReference: "INV004",
    invoiceDate: "2024-09-22",
    dueDate: "2024-10-22",
    currency: "NGN",
    invoiceAmount: "180,000,000",
    status: "Approved",
  },
  {
    invoiceReference: "INV005",
    invoiceDate: "2024-09-22",
    dueDate: "2024-10-22",
    currency: "NGN",
    invoiceAmount: "120,000",
    status: "Approved",
  },
];

const BulkEarlyPaymentInvoiceDetail = () => {
  const [selectedInvoices, setSelectedInvoices] = useState({});
  const [showPercentageModal, setShowPercentageModal] = useState(false);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [receivingAccount, setShowReceivingAccount] = useState(false);
  const [showVirtualSelectionModal, setShowVirtualSelectionModal] =
    useState(false);
  const [open, setOpen] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false);

  const navigate = useNavigate();
  function handleNavigate() {
    navigate(-1);
  }

  const handleProceed = () => {
    setShowPercentageModal(false);
    setShowInvoiceModal(true);
  };

  const onRowClicked = (row) => {
    let path = "/single-early-payment-invoice-details";
    // // dispatch(getAssociatedBuyerId(row?.id));
    navigate(path);
  };

  const handleGoBack = () => {
    setShowInvoiceModal(false);
    setShowPercentageModal(true);
  };

  const handleSelectAccount = () => {
    setShowInvoiceModal(false);
    setShowReceivingAccount(true);
  };

  const confirmPayment = () => {
    setShowConfirmModal(true);
    setShowPin(false);
  };

  const onClosePinModal = () => {
    setShowPin(false);
  };

  const handleCheckboxChange = (invoiceReference) => {
    setSelectedInvoices((prevSelected) => ({
      ...prevSelected,
      [invoiceReference]: !prevSelected[invoiceReference],
    }));
  };

  const HeaderWithIcon = ({ icon, text }) => (
    <div className="flex items-center">
      <img src={icon} alt="" className="mr-2" />
      <span>{text}</span>
    </div>
  );

  const column = [
    {
      name: <HeaderWithIcon icon={TableIcons} text="Invoice Reference" />,
      selector: (row) => (
        <div className="flex items-center">
          <input
            type="checkbox"
            checked={!!selectedInvoices[row?.invoiceReference]}
            onChange={() => handleCheckboxChange(row?.invoiceReference)}
            className={`mr-2 custom-checkbox w-4 h-4 appearance-none border-2 rounded-sm ${
              selectedInvoices[row?.invoiceReference]
                ? "bg-[#F08000] border-[#F08000]"
                : "bg-[#C2C0C0] border-[#C2C0C0]"
            } cursor-pointer`}
            style={{
              outline: "none",
            }}
          />

          {row?.invoiceReference}
        </div>
      ),
    },
    {
      name: "Invoice Date",
      selector: (row) => row?.invoiceDate,
    },
    {
      name: "Due Date",
      selector: (row) => row?.dueDate,
    },
    {
      name: "Currency",
      selector: (row) => row?.currency,
    },
    {
      name: "Invoice Amount",
      selector: (row) => row?.invoiceAmount,
    },
    {
      name: "Status",
      selector: (row) => (
        <span
          style={{
            color: row.status === "Approved" ? "#2EA923" : undefined,
          }}
        >
          {row?.status}
        </span>
      ),
    },
  ];

  const handleSelectAll = () => {
    if (
      Object.keys(selectedInvoices).length === data.length &&
      Object.keys(selectedInvoices).length > 0
    ) {
      setSelectedInvoices({});
    } else {
      const allSelected = {};
      data.forEach((invoice) => {
        allSelected[invoice.invoiceReference] = true;
      });
      setSelectedInvoices(allSelected);
    }
  };

  const isAnySelected = Object.keys(selectedInvoices).some(
    (key) => selectedInvoices[key]
  );

  const closePercentageModal = () => {
    setShowPercentageModal(false);
  };

  const handlePercentageModal = () => {
    setShowPercentageModal(true);
  };

  const handleShowVirtualAccountSelection = () => {
    setShowVirtualSelectionModal(true);
    setShowReceivingAccount(false);
  };

  const handleShowPin = () => {
    setShowTransferModal(false);
    setShowPin(true);
  };

  const handleCloseVirtualAccount = () => {
    setShowReceivingAccount(false);
    setShowVirtualSelectionModal(false);
  };

  const handleChangeAccount = () => {
    setShowVirtualSelectionModal(true);
    setShowTransferModal(false);
  };

  const handleCloseTransferModal = () => {
    setShowTransferModal(false);
  };

  function handleSendRequest() {
    setOpen(false);
    setShowTransferModal(true);
    //  onClose();
  }

  const handleSuccess = () => {
    setShowConfirmModal(false);
    setShowSuccess(true);
  };
  return (
    <DashboardLayout
      section="Invoice"
      subHeader="Here is an overview of your invoices"
    >
      {(open || showConfirmModal) && (
        <div className="w-full fixed inset-0 bg-black bg-opacity-50 z-10"></div>
      )}
      {open && (
        <div className="mr-8 absolute mt-[20px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white w-[614px] h-[254px] z-10 rounded-[10px] shadow-lg">
          <EarlyPaymentConfirmation
            hide={() => setOpen(false)}
            // isLoading={earlyPaymentLoading}
            handleAssociate={handleSendRequest}
            // earlyPayment={true}
            warning={
              <>
                Would you like to proceed to use this account{" "}
                <span className="text-primaryColor">7364738921</span> to bid on
                invoice?
              </>
            }
          />
        </div>
      )}

      {showTransferModal && (
        <div>
          <TransferFundModal
            handleCloseVirtualAccount={handleCloseVirtualAccount}
            handleShowVirtualAccountSelection={
              handleShowVirtualAccountSelection
            }
            setShowReceivingFundModal={setShowReceivingAccount}
            handleChangeAccount={handleChangeAccount}
            handleCloseTransferModal={handleCloseTransferModal}
            handleShowPin={handleShowPin}
            invoice={true}
          />
        </div>
      )}

      {showConfirmModal && (
        <div className="mr-8 absolute mt-[20px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white w-[614px] h-[254px] z-10 rounded-[10px] shadow-lg">
          <EarlyPaymentConfirmation
            hide={() => setShowConfirmModal(false)}
            invoice={true}
            // isLoading={earlyPaymentLoading}
            handleAssociate={handleSuccess}
            // earlyPayment={true}
            warning=" Would you like to proceed with sending this bulk early payment request? "
          />
        </div>
      )}

      {showPin && (
        <PinModalPayment
          confirmPayment={confirmPayment}
          isOpen={showPin}
          onCancel={onClosePinModal}
          invoice={true}
        />
      )}

      {showSuccess && (
        <SuccessEarlyPayment
          isOpen={showSuccess}
          onClose={() => setShowSuccess(false)}
          invoice={true}
          message="Early payment request successful"
        />
      )}

      {receivingAccount && (
        <ReceivingFundModal
          invoice={true}
          handleCloseVirtualAccount={handleCloseVirtualAccount}
          handleShowVirtualAccountSelection={handleShowVirtualAccountSelection}
          setShowReceivingFundModal={setShowReceivingAccount}
        />
      )}
      {showVirtualSelectionModal && (
        <div>
          <VirtualAccountSelection
            invoice={true}
            handleCloseVirtualAccount={handleCloseVirtualAccount}
            setOpen={setOpen}
            // onClose={onClose}
          />
        </div>
      )}
      <BulkRequestModal
        handleProceed={handleProceed}
        onClose={closePercentageModal}
        isShown={showPercentageModal}
      />
      <BulkInvoiceModal
        handleGoBack={handleGoBack}
        isShown={showInvoiceModal}
        handleSelectAccount={handleSelectAccount}
      />
      <div>
        <header className="bg-alabasterHeader p-[24px] rounded-t-[10px]">
          <div className="flex justify-between">
            <div
              onClick={handleNavigate}
              className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
            >
              <PAIconInventoryArrowLeft />
              <p className="mt-[2px] text-sm font-normal text-lightGray">
                Go Back
              </p>
            </div>
            <div className="flex">
              <div className="pr-2">
                <Button
                  neutral
                  onClick={handleSelectAll}
                  className={`py-[12px] mt-[10px] md:mt-0 px-[24px] rounded-[5px] font-medium ${
                    isAnySelected
                      ? "bg-[#2EA923] text-sm !text-white"
                      : "bg-[#C2C0C0] text-sm !text-white"
                  }`}
                >
                  Select all
                </Button>
              </div>
              <div>
                <Button
                  onClick={handlePercentageModal}
                  neutral
                  className={`py-[12px] mt-[10px] md:mt-0 px-[24px] rounded-[5px] font-medium ${
                    isAnySelected
                      ? "bg-[#F08000] text-sm !text-white"
                      : "bg-[#C2C0C0] text-sm !text-white"
                  }`}
                >
                  {isAnySelected ? "Set request percentage" : "Request early payment"}
                </Button>
              </div>
            </div>
          </div>
          <div className="flex justify-between flex-wrap ">
            <div>
              <p className="text-black text-sm font-medium">
                32 Approved invoices from Coca Cola
              </p>
              <p className="text-textColor text-xs font-normal">
                List of all buyers that have activated early payment request
              </p>
              <div className="pt-2">
                <label className="text-black text-sm font-medium">
                  Select Early payment request date
                </label>
                <div className="relative mt-2">
                  <input
                    type="date"
                    placeholder=""
                    className="border border-gray-300 rounded-[5px] py-[10px] px-[12px] text-sm w-[350px] pr-[40px]"
                  />
                </div>
              </div>
            </div>
          </div>
          <br />
        </header>
      </div>
      <div className="p-[24px] bg-white rounded-b-[10px]">
        <Table
          columns={column}
          pointer
          data={data}
          onRowClicked={onRowClicked}
        />
      </div>
    </DashboardLayout>
  );
};

export default BulkEarlyPaymentInvoiceDetail;
