import { motion } from "framer-motion";
import { PAIconArrowLeftGreen } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TransitionScale from "shared/animation/TransitionScale";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInvoiceById } from "appstate/openMarket/openMarketSlice";
import BulkEarlyPaymentInvoice from "./bulkEarlyPaymentInvoice";

function CollectionBulkEarlyPaymentInvoice() {
  const { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const offerId = searchParams.get("offerId");

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getInvoiceById({ id }));
  }, [dispatch, id, offerId]);

  const { invoiceByIdData } = useSelector((state) => state?.openMarket);

  function handleGoBack() {
    navigate(-1);
  }

  const dummyData = [
    {
      label1: "Nestle",
      value1: "Buyer",
      label2: "Dangote",
      value2: "Funder",
    },
    {
      label1: "NGN 1,000",
      value1: "Total Discount Amount",
      // value2: "Discount Rate",
      // label2: "5% ",
    },
    {
      label1: "2024-11-12",
      value1: "Request Date",
      label2: "2024-11-19",
      value2: "Early Payment Date",
    },
    {
      label1: "10,000 USD",
      value1: "Total Request Amount",
      label2: "8,500 USD",
      value2: "Total Receivable Amount",
    },
  ];

  return (
    <DashboardLayout
      section="Collections"
      subHeader="Track invoices due for payment collection"
    >
      <TransitionScale className="flex  flex-col gap-6 bg-[#EEE] h-full">
        <header onClick={handleGoBack} className="w-full">
          <div className="flex cursor-pointer w-fit gap-1 items-center">
            <PAIconArrowLeftGreen />
            <span className=" text-primaryColor text-sm">Go back</span>
          </div>
        </header>

        <main className="flex flex-col semi-lg:flex-row pb-8 gap-4 w-full ">
          <BulkEarlyPaymentInvoice
            invoiceByIdData={invoiceByIdData}
            // setLockModalOpen={setLockModalOpen}
          />

          <motion.section
            initial={{
              y: -20,
            }}
            animate={{
              y: 0,
            }}
            exit={{
              y: 20,
            }}
            transition={{
              delay: 0.8,
            }}
            className="flex flex-col gap-3 px-6  pt-7 pb-24 w-2/5 bg-white rounded-[10px]"
          >
            <header className="flex pb-2">
              <span className="font-bold">Early Payment Details</span>
            </header>

            <section className="flex flex-col gap-6">
              <div className="space-y-4">
                {dummyData.map((item, index) => (
                  <div
                    key={index}
                    className="flex justify-between items-center"
                  >
                    <span>
                      {item.label1} <br />
                      <span className="text-sm text-gray">{item.value1}</span>
                    </span>
                    <div className="flex flex-col items-end">
                      <span>{item.label2}</span>
                      <span className="text-sm text-gray">{item.value2}</span>
                    </div>
                  </div>
                ))}
              </div>

              <div>
                <h1>Note from supplier: </h1>
                <p className="text-[#959595] my-3">
                  Seeking Early payment due to unexpected challenges. Facing
                  temporary financial constraints and need a bit more time to
                  ensure a smooth transaction. Appreciate your understanding.
                  Thank you. Best regards, ABC Limited
                </p>
                <h1 className="mt-5">Funding Option </h1>
                <p className="text-[#959595] my-3">
                  This invoice would be funded from this virtual account:
                </p>
                <p className="text-[#959595] my-3">Praise Akubundu</p>
                <p className="text-[#959595] my-3">7364738921</p>
                <p className="text-[#959595] my-3">Payedge MFB</p>
              </div>
            </section>
          </motion.section>
        </main>
      </TransitionScale>
    </DashboardLayout>
  );
}

export default CollectionBulkEarlyPaymentInvoice;
