import React, { useState } from "react";
import SearchInput from "../../SearchInput";
import Table from "layout/table/Table";
// import { useSelector } from "react-redux";

const PendingTable = ({pendingDeletion}) => {
  // const [showNewAgreement, setShowNewAgreement] = useState(false);
  // const [showAgreementDetails, setShowAgreementDetails] = useState(false);
  // const [selectedAgreement, setSelectedAgreement] = useState(null);
  const [, setSearchQuery] = useState("");

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const singleDetails = (id) => {
    // let path = `/collections/funcollectiondirectfundinginvoice/${id}`;
    // navigate(path);
    //  console.log("===id===",id)
     pendingDeletion()
  };


  // const handleCreateNewAgreement = () => {
  //   setShowNewAgreement(true);
  //   setShowAgreementDetails(false);
  //   setSelectedAgreement(null);
  // };

  // const handleShowAgreementDetails = (agreement) => {
  //   setSelectedAgreement(agreement);
  //   setShowAgreementDetails(true);
  //   setShowNewAgreement(false);
  // };

  // function handleBack() {
  //   setShowNewAgreement(false);
  //   setShowAgreementDetails(false);
  //   setSelectedAgreement(null);
  // }

  const singleDummyData = [
    {
      id: 1,
      requestRefNumber: "R3456X89Y",
      company: "Funding Agreement 02",
      recourseOption: "2023-05-23",
      paymentDate: "2023-05-23",
      currency: "NGN",
      requestAmount: "9,000,000",
      discountRate: "1.5%",
      discountAmount: "950,000",
      receivableAmount: "8,150,000",
      status: "Pending Approval",
      type: "PFP Campaign",
      role: "Initiator",
      party: "Chevron (Funder)",
    },
    {
      id: 2,
      requestRefNumber: "R3456X89Y",
      company: "PFP Campaign Macro",
      recourseOption: "2023-05-23",
      paymentDate: "2023-05-23",
      currency: "NGN",
      requestAmount: "9,000,000",
      discountRate: "1.5%",
      discountAmount: "950,000",
      receivableAmount: "8,150,000",
      status: "Pending Approval",
      type: "PFP Campaign",
      role: "Initiator",
      party: "Dangote PLC (Funder)",
    },
    {
      id: 3,
      requestRefNumber: "R3456X89Y",
      company: "Invoice Financing",
      recourseOption: "2023-05-23",
      paymentDate: "2023-05-23",
      currency: "NGN",
      requestAmount: "9,000,000",
      discountRate: "1.5%",
      discountAmount: "950,000",
      receivableAmount: "8,150,000",
      status: "Deletion Rejected",
      type: "Closed Market",
      role: "Receipient",
      party: "Pepsi Ltd (Supplier)",
    },
  ];

  const columns = [
    {
      name: "AGREEMENT NAME",
      selector: (row) => row.company,
      sortable: true,
      width: "10rem",
    },
    {
      name: "AGREEMENT TYPE",
      selector: (row) => row.type,
      sortable: true,
      width: "10rem",
    },
    {
      name: "DATE CREATED",
      selector: (row) => row.recourseOption,
      sortable: true,
      width: "10rem",
    },

    {
      name: "YOUR ROLE",
      selector: (row) => row.role,
      sortable: true,

      width: "10rem",
    },
    {
      name: "COUNTER PARTY",
      selector: (row) => row.party,
      sortable: true,

      width: "10rem",
    },

    {
      name: "STATUS",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <p
          className={
            row.status === "Deletion Rejected"
              ? "text-[#BB0C0C]"
              : row.status === "Updated"
              ? "text-[#3A6CCC]"
              : "text-black"
          }
        >
          {row.status}
        </p>
      ),
      width: "10rem",
    },
  ];
  return (
    <div>
      {" "}
      <div className="flex items-center justify-between gap-3 ">
        <div>
          <select
            className=" shadow-sm  text-sm focus:outline-none  cursor-pointer h-[37px] px-3 py-2.5 rounded-[5px] border border-[#949494] justify-start items-end gap-2 inline-flex  "
            // onChange={(e) => {}}
            // onChange={(e) => setStatus(e.target.value)}
          >
            <option value="" key="all-bids">
              Deletion Initiated by you
            </option>
          </select>
        </div>

        {/* <button
          onClick={handleCreateNewAgreement}
          className="text-white text-xs p-2 font-inter textbase font-normal leading-normal rounded-md border border-[#CCC] bg-[#FFBE55]"
        >
          Create new agreement
        </button> */}
        <div className="ml-5">
          <SearchInput onSearch={handleSearch} />
        </div>
      </div>
      <div className=" mt-3">
        <Table
          columns={columns}
          data={singleDummyData}
          onRowClicked={(row) => singleDetails(row.id)}
          pointer
          tableHeader
          className="mt-5"
        />
      </div>
    </div>
  );
};

export default PendingTable;
