import { getAllInvoiceByIdDF, getDirectFundingDetails } from "appstate/api/invoice/invoice";
import {
  PAIconArrowLeftGreen,
  // PAIconCocacola,
  // PAIconDownload,
  // PAIconSmallNestle,
} from "assets/images/svgs";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { formatCurrency } from "utilities/helper/formatters";

const Details = () => {

  const [directFundingData, setDirectFundingData] = useState(null);
  // eslint-disable-next-line
  const [error, setError] = useState(null);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(true);
  const [invoiceData, setInvoiceData] = useState(null);

  const location = useLocation();
  const invoiceId = location.state?.invoiceId;

  const navigate = useNavigate();
  const { id } = useParams();

  const goBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    const fetchDirectFundingDetails = async () => {
      try {
        const result = await getDirectFundingDetails(id);
        setDirectFundingData(result);
      } catch (error) {
        setError(error.message || "Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchDirectFundingDetails();
    }
  }, [id]);

  useEffect(() => {
    const fetchInvoiceDetails = async () => {
      if (!invoiceId) {
        console.error("Invoice ID is missing");
        return;
      }
      try {
        const response = await getAllInvoiceByIdDF(invoiceId);
        setInvoiceData(response?.data);
      } catch (error) {
        setError(error.message || "Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    if (invoiceId) {
      fetchInvoiceDetails();
    }
  }, [invoiceId]);

  // Factoring in progress


  return (
    <>
      <Link
        onClick={goBack}
        className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer w-full"
      >
        <PAIconArrowLeftGreen />
        <span className="flex items-center text-primaryColor">Go Back</span>
      </Link>

      <div className="grid lg:grid-cols-[60%,40%] gap-5">
        <div className="flex flex-col gap-8 pb-24 bg-white rounded-[10px] pt-7">
          <div className="px-6 ">
            <div className="flex flex-col gap-3">
              

              <main className=" w-full  ">
                <div className="flex flex-col gap-3 w-1/2">
                  <div className="flex gap-x-[5px]">
                    <span>Reference No: |{directFundingData?.reference}</span>
                  </div>
                  <div className="flex flex-col gap-1">
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs w-[97px]">Invoice Number:</span>
                      <span className="text-[#979797]  text-xs">
                        {`INV-${invoiceData?.invoiceNo}`}
                      </span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs w-[97px]">Created Date:</span>
                      <span className="text-[#979797]  text-xs">
                      {invoiceData?.createdDate}
                      </span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs w-[97px]">Submitted Date:</span>
                      <span className="text-[#979797]  text-xs">
                      {invoiceData?.submittedDate}
                      </span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs w-[97px]">Approval Date:</span>
                      <span className="text-[#979797]  text-xs">
                      {invoiceData?.approvedDate}
                      </span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs">Due Date:</span>
                      <span className="text-[#979797]  text-xs">
                      {directFundingData?.invoiceDueDate}
                      </span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs">Issuance Date:</span>
                      <span className="text-[#979797]  text-xs">
                      {invoiceData?.createdDate}
                      </span>
                    </div>
                  </div>
                </div>

                <div>
                  <div>
                    <div class="text-right text-[#222222] text-base font-bold ">
                      {directFundingData?.currency}{formatCurrency(Number(directFundingData?.requestAmount))}
                    </div>
                    <div class="text-[#979797] text-xs font-normal text-right">
                      Request amount
                    </div>
                  </div>
                </div>
              </main>
            </div>

            <div className="flex justify-between my-5">
              <div className="flex flex-col">
                <span className=" font-bold text-sm">{directFundingData?.buyerCompanyName}</span>
                <span className="text-[#979797]  text-xs">Buyer Details</span>
              </div>
              <div className="flex flex-col">
                <span className="text-[#979797]  text-xs">{directFundingData?.currency}</span>
                <span className=" font-bold text-sm">Currency</span>
              </div>
            </div>

            <div className="flex flex-col gap-4">
              <header className="border-b-[5px] flex justify-between  border-b-[#F6F5FA] pb-3">
                <h1 className="text-sm font-bold">Line Items</h1>
                <span className="text-[#979797]  text-xs">{invoiceData?.items?.length} total items</span>
              </header>
              <div className=" w-full overflow-x-auto">
                <table className=" w-full overflow-x-auto">
                  <thead className="bg-subtleGray w-">
                    <tr className=" bg-unusualGray p-2.5 ">
                      <td className="text-[#11192A] p-2.5 text-xs font-bold">
                        Item
                      </td>
                      <td className=" p-2.5 text-xs font-bold">Description</td>
                      <td className=" p-2.5 text-xs font-bold">Unit price</td>
                      <td className=" p-2.5 text-xs font-bold">Quantity</td>
                      <td className=" p-2.5 text-xs font-bold">Tax Rate</td>
                      <td className=" p-2.5 text-xs font-bold">Tax Amount</td>
                      <td className=" p-2.5 text-xs font-bold">Total Price</td>
                    </tr>
                  </thead>

                  {invoiceData?.items?.map((item, index) => (
                      <tbody key={index}>
                      <tr className=" py-[3px] px-1.5">
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.itemName}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.description}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {formatCurrency(Number(item?.unitPrice))}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {formatCurrency(Number(item?.quantity))}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {formatCurrency(Number(item?.taxRate))}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {formatCurrency(Number(item?.taxAmount))}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {formatCurrency(Number(item?.totalPrice))}
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            </div>

            <div className="flex justify-between pt-4">
              <div>
                <h4 className="text-sm text-[#11192A] ">
                  Receiving Funding Account Information
                </h4>
                <h5 className="text-sm text-[#11192A] ">
                  {invoiceData?.virtualAccount?.accountNumber}
                </h5>
                <h5 className="text-sm text-[#11192A] ">
                  {invoiceData?.virtualAccount?.accountName}
                </h5>
              </div>
              <div style={{ width: 300 }}>
                <div className="flex justify-between">
                  <span className="text-sm text-[#11192A] ">Subtotal</span>
                  <span className="text-sm text-[#11192A] ">
                  {formatCurrency(Number(invoiceData?.subTotal))}
                  </span>
                </div>
                <div className="flex justify-between">
                  <div>
                    <span className="text-sm text-[#11192A] ">VAT</span>
                    <span className="pl-2 text-sm text-[#11192A] ">
                    {invoiceData?.vat}%
                    </span>
                  </div>
                  <div>
                    <span className="text-sm text-[#11192A] ">{formatCurrency(Number(invoiceData?.vatAmount))}</span>
                  </div>
                </div>
                <div className="flex justify-between">
                  <div>
                    <span className="text-sm text-[#11192A] ">Discount</span>
                    <span className="pl-2 text-sm text-[#11192A] ">
                    {invoiceData?.discount}%
                    </span>
                  </div>
                  <div>
                    <span className="text-sm text-[#11192A] ">
                    {formatCurrency(Number(invoiceData?.discountAmount))}
                    </span>
                  </div>
                </div>
                <div>
                  <hr className="border-t border-[black] mt-2 pt-2" />
                  <div className="flex justify-between">
                    <span className="text-lg text-[#11192A] ">Grand Total</span>
                    <span className="text-lg text-[#11192A] ">
                    {formatCurrency(Number(invoiceData?.grandTotal))} 
                    </span>
                  </div>
                  <hr className="border-t border-[black] mt-2 pt-2" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white rounded-[10px] gap-8 ">
          {/* <FundDetailSide /> */}
          <div class="w-full justify-start items-center gap-4 inline-flex ">
            <div class="border-b  border-[#c2c2c2]/30 justify-start items-center gap-2.5 flex w-full px-6  py-3">
              <div class="text-[#07593d] text-base font-bold ">
                Direct Request Details
              </div>
            </div>
          </div>

          <div>
            {" "}
            {/* second */}
            <div className=" px-6 border-b  border-[#c2c2c2]/30  pb-4">
              <div class=" justify-between  flex flex-row   mt-4 ">
                <div class="py-2.5 flex-col justify-start items-start gap-2 inline-flex">
                  <div class="flex-col justify-start items-start gap-0.5 flex">
                    <div class="text-[#949494] text-sm font-medium ">
                      Request amount
                    </div>
                  </div>
                  <div class="flex-col justify-start items-start gap-0.5 flex">
                    <div class="text-black text-base font-medium ">
                    {directFundingData?.currency + " "} {formatCurrency(Number(directFundingData?.requestAmount))}
                    </div>
                  </div>
                </div>
                <div class="py-2.5 flex-col justify-start items-start gap-2 inline-flex">
                  <div class="flex-col justify-start items-start gap-0.5 flex">
                    <div class="text-[#949494] text-sm font-medium ">
                      Request date
                    </div>
                  </div>
                  <div class="flex-col justify-start items-start gap-0.5 flex">
                    <div class="text-black text-base font-medium ">
                    {directFundingData?.earlyPaymentDate}
                    </div>
                  </div>
                </div>
              </div>

            </div>
            {/* stops here */}
            {/* Third */}
            <div className=" px-6  pb-4">
              <div class=" justify-between  flex flex-row   mt-4 ">
                <div class="py-2.5 flex-col justify-start items-start gap-2 inline-flex">
                  <div class="flex-col justify-start items-start gap-0.5 flex">
                    <div class="text-[#949494] text-sm font-medium ">
                      Status
                    </div>
                  </div>
                  <div class="flex-col justify-start items-start gap-0.5 flex">
                    <div class="text-[#f08000] text-base font-medium ">
                      {directFundingData?.status === "APPROVED" && "PENDING FUNDING"}
                    </div>
                  </div>
                </div>
                <div class="py-2.5 flex-col justify-start items-start gap-2 inline-flex">
                  <div class="flex-col justify-start items-start gap-0.5 flex">
                    <div class="text-[#949494] text-sm font-medium ">
                      Discount Rate
                    </div>
                  </div>
                  <div class="flex-col justify-start items-start gap-0.5 flex">
                    <div class="text-black text-base font-medium ">{directFundingData?.discountRate}%</div>
                  </div>
                </div>
              </div>

              <div class="py-2.5 flex-col justify-start items-start gap-2 inline-flex mt-4  ">
                <div>
                  <h4 className="text-lg text-[#11192A] ">
                    Funder Personal Information
                  </h4>
                </div>
                <div>
                  <div class="flex-col justify-start items-start gap-0.5 flex">
                    <div class="text-[#949494] text-sm font-medium ">
                      Funder's Name
                    </div>
                  </div>
                  <div class="flex-col justify-start items-start gap-0.5 flex">
                    <div class="w-[400px] text-black text-base font-normal ">
                      {directFundingData?.funderCompanyName}
                    </div>
                  </div>
                </div>

                <div className="flex w-full justify-between flex-col">
                  <div className="flex-col">
                    <span className="text-sm text-[#11192A] ">Email: </span>
                    <span className="text-sm text-[#11192A] ">{directFundingData?.funderEmail}</span>
                  </div>
                  <div className="flex-col">
                    <span className="text-sm text-[#11192A] ">Contact Number: </span>
                    <span className="text-sm text-[#11192A] ">{directFundingData?.funderContact}</span>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Details;
