import React, { useEffect, useState } from "react";
import Table from "layout/table/Table";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  allTransactionsReimbursement,
  allTransactionsInflow,
} from "appstate/collections/collectionSlice";
import Pagination from "shared/Pagination";
import Loading from "shared/Loading";
import { PAIconFilter, PAIconOutlineSearch } from "assets/images/svgs";

const TransactionHistory = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedOption1, setSelectedOption1] = useState("inflow");
  const [selectedFundingOption, setSelectedFundingOption] =
    useState("allFundingChannel");
  const [selectedCollectionOption, setSelectedCollectionOption] =
    useState("allCollectionsType");

  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(10);
  const pageSize = 10;

  const { transactionReimbursementData, transactionInflowData, isLoading } =
    useSelector((state) => state?.collection);

  const handleSearch = () => {
    if (selectedOption1 === "reimbursement") {
      dispatch(
        allTransactionsReimbursement({
          page,
          pageSize,
          search: searchQuery,
          fundingChannel:
            selectedFundingOption !== "allFundingChannel"
              ? selectedFundingOption
              : undefined,
        })
      );
    } else {
      dispatch(
        allTransactionsInflow({
          page,
          pageSize,
          search: searchQuery,
          fundingChannel:
            selectedFundingOption !== "allFundingChannel"
              ? selectedFundingOption
              : undefined,
          collectionType:
            selectedCollectionOption !== "allCollectionsType"
              ? selectedCollectionOption
              : undefined,
        })
      );
    }
  };

  // this effect loads the appropriate data based on the selected option
  useEffect(() => {
    if (selectedOption1 === "reimbursement") {
      dispatch(
        allTransactionsReimbursement({
          page,
          pageSize,
          fundingChannel:
            selectedFundingOption !== "allFundingChannel"
              ? selectedFundingOption
              : "",
          search: searchQuery || "",
        })
      );
    } else {
      dispatch(
        allTransactionsInflow({
          page,
          pageSize,
          fundingChannel:
            selectedFundingOption !== "allFundingChannel"
              ? selectedFundingOption
              : "",
          collectionType:
            selectedCollectionOption !== "allCollectionsType"
              ? selectedCollectionOption
              : "",
          search: searchQuery || "",
        })
      );
    }
  }, [
    dispatch,
    page,
    pageSize,
    selectedOption1,
    selectedFundingOption,
    selectedCollectionOption,
    searchQuery,
  ]);

  const onRowClicked = (transactionId, programId) => {
    if (
      selectedFundingOption === "PAYABLES" ||
      selectedCollectionOption === "PAYABLES"
    ) {
      // navigate(`/collections/receivables-transaction-history/${transactionId}`);
      let path = `/collections/receivables-transaction-history/${transactionId}?programId=${programId}`;
      navigate(path);
    } else {
      // let path = `/collections/transactionhistory/${transactionId}`;
      // navigate(path);
      let path = `/collections/transactionhistory/${transactionId}?programId=${programId}`;
      navigate(path);
    }
  };

  const options1 = [
    { value: "inflow", label: "Inflow" },
    {
      value: "reimbursement",
      label: "Reimbursement",
    },
  ];
  const fundingChannelDropdown = [
    { value: "allFundingChannel", label: "All Funding Channel" },
    {
      value: "DIRECT_FUNDING",
      label: "Direct Funding",
    },
    {
      value: "OPEN_MARKET",
      label: "Open Market",
    },
    {
      value: "EARLY_PAYMENT_REQUEST",
      label: "Early Payment Request",
    },
    {
      value: "EARLY_PAYMENT_OFFER",
      label: "Early Payment Offers",
    },
    {
      value: "PAYABLES",
      label: "Receivables",
    },
    {
      value: "CLOSED_MARKET",
      label: "Closed Market",
    },
  ];
  const fundingChannelDropdown2 = [
    { value: "allFundingChannel", label: "All Funding Channel" },
    {
      value: "DIRECT_FUNDING",
      label: "Direct Funding",
    },
    {
      value: "OPEN_MARKET",
      label: "Open Market",
    },
  ];

  const collectionTypeDropdown = [
    { value: "allCollectionsType", label: "All Collection Type" },
    {
      value: "FUNDING_PAYMENT",
      label: "Funding Payment",
    },
    {
      value: "PAYABLES",
      label: "Receivable Collections",
    },
  ];

  const handleSelect = (option) => {
    setSelectedOption1(option.value);
    setIsOpen1(false);
    // Reset page when changing transaction type
    setPage(0);
  };

  const handleFundingSelect = (option) => {
    setSelectedFundingOption(option.value);
    setIsOpen2(false);
    setPage(0);
  };

  const handleCollectionSelect = (option) => {
    setSelectedCollectionOption(option.value);
    setIsOpen3(false);
    setPage(0);
  };

  const formatAmount = (amount) => {
    if (typeof amount !== "number") return "0.00";
    return amount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const getCurrentData = () => {
    return selectedOption1 === "reimbursement"
      ? transactionReimbursementData
      : transactionInflowData;
  };

  const getColumns = () => {
    if (selectedOption1 === "reimbursement") {
      return [
        {
          name: (
            <h1>
              {" "}
              Sender <span className="text-primaryColor text-xs">
                (Buyer)
              </span>{" "}
            </h1>
          ),
          selector: (row) => row.sender,
          sortable: true,
        },
        {
          name: (
            <h1>
              {" "}
              Benficiary{" "}
              <span className="text-secondaryColor text-xs">(Funder)</span>{" "}
            </h1>
          ),
          selector: (row) => row.beneficiaryName,
          sortable: true,
        },
        {
          name: "Payment Date",
          selector: (row) => row.paymentDate?.slice(0, 10),
          sortable: true,
        },
        {
          name: "Reference Number",
          selector: (row) => row.transactionReference,
          sortable: true,
        },
        {
          name: "Currency",
          selector: (row) => row.currency,
          sortable: true,
        },
        {
          name: "Amount",
          selector: (row) => row.amount,
          sortable: true,
          cell: (row) => (
            <p className="text-success">{formatAmount(row.amount)}</p>
          ),
        },
        {
          name: "Status",
          selector: (row) => row.status,
          sortable: true,
          cell: (row) => (
            <p
              className={
                row.status === "SUCCESSFUL"
                  ? "text-[#07593D]"
                  : row.status === "REJECTED"
                  ? "text-red"
                  : "text-yellow-400"
              }
            >
              {row.status}
            </p>
          ),
        },
        {
          name: "Funding Channel",
          selector: (row) => row.fundingChannel,
          sortable: true,
        },
      ];
    } else {
      return [
        {
          name: "Sender",
          selector: (row) => row.sender,
          sortable: true,
          cell: (row) => (
            <p>
              {row.sender}{" "}
              <span
                className={
                  row.player === "Funder"
                    ? "text-[#07593D] text-[10px]"
                    : "text-yellow-400 text-[10px]"
                }
              >
                ({row.player})
              </span>
            </p>
          ),
        },
        {
          name: "Payment Date",
          selector: (row) => row.paymentDate?.slice(0, 10),
          sortable: true,
          cell: (row) => {
            // Convert the array to a Date object
            const date = new Date( 
              row?.paymentDate[0],
              row?.paymentDate[1] - 1,
              row?.paymentDate[2]
            );
    
            // Manually format the date as 'YYYY-MM-DD'
            const formattedDate = date?.toLocaleDateString("en-CA");
    
            return <p>{formattedDate}</p>;
          },
        },
        {
          name: "Reference Number",
          selector: (row) => row.transactionReference,
          sortable: true,
        },
        {
          name: "Currency",
          selector: (row) => row.currency,
          sortable: true,
        },
        {
          name: "Amount",
          selector: (row) => row.amount,
          sortable: true,
          cell: (row) => (
            <p className="text-success">{formatAmount(row.amount)}</p>
          ),
        },
        {
          name: "Status",
          selector: (row) => row.status,
          sortable: true,
          cell: (row) => (
            <p
              className={
                row.status === "SUCCESSFUL"
                  ? "text-[#07593D]"
                  : row.status === "REJECTED"
                  ? "text-red"
                  : "text-yellow-400"
              }
            >
              {row.status}
            </p>
          ),
        },
        {
          name: "Collection Type",
          selector: (row) => row.collectionType,
          sortable: true,
        },
        {
          name: "Funding Channel",
          selector: (row) => row.fundingChannel,
          sortable: true,
        },
      ];
    }
  };

  const columns = getColumns();
  const currentData = getCurrentData();

  // Common filter section component that will appear in both states (with or without data)
  const FilterSection = () => (
    <div className="px-[24px] flex gap-5 justifybetween">
      {/* FIRST DROPDOWN - Transaction Type */}
      <div className="relative">
        <button
          onClick={() => setIsOpen1(!isOpen1)}
          className="flex items-center justify-between gap-16 text-primaryColor text-xs border border-gray p-1 px-2 rounded-t cursor-pointer dropdown-button"
        >
          {options1.find((opt) => opt.value === selectedOption1)?.label}
          <svg
            className={`w-4 h-8 ml-2 transition-transform ${
              isOpen1 ? "rotate-180" : "rotate-0"
            }`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.707a1 1 0 011.414 0L10 11.293l3.293-3.586a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>

        {isOpen1 && (
          <ul className="z-10 absolute top-full right-0 text-primaryColor bg-white border border-gray rounded-b shadow-md text-sm w-full">
            {options1.map((option) => (
              <li
                key={option.value}
                onClick={() => handleSelect(option)}
                className={`px-4 py-2 flex items-center text-xs cursor-pointer hover:bg-primaryColor hover:text-white ${
                  selectedOption1 === option.value ? "bgprimaryColor" : ""
                }`}
              >
                {option.label}
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* SECOND DROPDOWN - Funding Channel */}
      <div className="relative">
        <button
          onClick={() => setIsOpen2(!isOpen2)}
          className="flex items-center justify-between gap-16 text-primaryColor text-xs border border-gray p-1 px-2 rounded-t cursor-pointer dropdown-button"
        >
          {
            (selectedOption1 === "reimbursement"
              ? fundingChannelDropdown2
              : fundingChannelDropdown
            ).find((opt) => opt.value === selectedFundingOption)?.label
          }
          <svg
            className={`w-4 h-8 ml-2 transition-transform ${
              isOpen2 ? "rotate-180" : "rotate-0"
            }`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.707a1 1 0 011.414 0L10 11.293l3.293-3.586a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>

        {isOpen2 && (
          <ul className="z-10 absolute top-full right-0 text-primaryColor bg-white border border-gray rounded-b shadow-md text-sm w-full">
            {(selectedOption1 === "reimbursement"
              ? fundingChannelDropdown2
              : fundingChannelDropdown
            ).map((option) => (
              <li
                key={option.value}
                onClick={() => handleFundingSelect(option)}
                className={`px-4 py-2 flex items-center text-xs cursor-pointer hover:bg-primaryColor hover:text-white ${
                  selectedFundingOption === option.value ? "bgprimaryColor" : ""
                }`}
              >
                {option.label}
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* THIRD DROPDOWN - Collection Type (only for Inflow) */}
      {selectedOption1 !== "reimbursement" && (
        <div className="relative">
          <button
            onClick={() => setIsOpen3(!isOpen3)}
            className="flex items-center justify-between gap-16 text-primaryColor text-xs border border-gray p-1 px-2 rounded-t cursor-pointer dropdown-button"
          >
            {
              collectionTypeDropdown.find(
                (opt) => opt.value === selectedCollectionOption
              )?.label
            }
            <svg
              className={`w-4 h-8 ml-2 transition-transform ${
                isOpen3 ? "rotate-180" : "rotate-0"
              }`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.707a1 1 0 011.414 0L10 11.293l3.293-3.586a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>

          {isOpen3 && (
            <ul className="z-10 absolute top-full right-0 text-primaryColor bg-white border border-gray rounded-b shadow-md text-sm w-full">
              {collectionTypeDropdown.map((option) => (
                <li
                  key={option.value}
                  onClick={() => handleCollectionSelect(option)}
                  className={`px-4 py-2 flex items-center text-xs cursor-pointer hover:bg-primaryColor hover:text-white ${
                    selectedCollectionOption === option.value
                      ? "bgprimaryColor"
                      : ""
                  }`}
                >
                  {option.label}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );

  // Common search section component
  const SearchSection = () => (
    <div className="flex md:col-span-1 justify-end">
      <div className="flex items-center justify-between gap-5">
        <div className="border border-transparent md:border-gray md:shadow-sm my-4 rounded-[5px] px-2 lg:w-[300px] bg-white">
          <div className="items-center flex bg-white">
            <PAIconOutlineSearch />
            <input
              className="bg-transparent p-2 w-full focus:outline-none text-sm bg-white"
              type="text"
              placeholder="Search for transaction"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
            />
          </div>
          <div className="lg:hidden flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer">
            <PAIconFilter className="bg-[#2FA06A]" />
          </div>
        </div>
        <button
          className="hidden lg:flex bg-[#2FA06A] rounded-md my-4 py-2 px-6 mx3 items-center gap-2 text-white"
          onClick={handleSearch}
        >
          Search
        </button>
      </div>
    </div>
  );

  // Common header component
  const HeaderSection = () => (
    <div>
      <p className="text-black text-lg font-bold">
        Overview of{" "}
        {selectedOption1 === "inflow" ? "Cash Inflow" : "Reimbursement"} Records
      </p>
      <span className="text-xs font-thin">
        Here is a recent list of your{" "}
        {selectedOption1 === "inflow" ? "inflow" : "reimbursement"} transactions
      </span>
    </div>
  );

  return (
    <>
      {!currentData?.meta?.totalElements ? (
        <Card className="rounded-[10px] mt-[24px]">
          <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px] py-10">
            <HeaderSection />
            <SearchSection />
          </div>

          <FilterSection />

          <div className="px-[24px] pb-[24px]">
            <TableEmptyState dashboard />
          </div>
        </Card>
      ) : (
        <Card className="rounded-[10px] mt-[24px]">
          <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px] py-10">
            <HeaderSection />
            <SearchSection />
          </div>

          <FilterSection />

          <div className="px-[24px] pb-[24px]">
            <Table
              columns={columns}
              data={currentData?.data || []}
              onRowClicked={(row) => onRowClicked(row?.transactionId, row?.programId)}
              progressPending={isLoading}
              progressComponent={<Loading />}
              pointer
              tableHeader
              className="mt-5"
            />
            <div className="flex justify-center gap-x-[8px] mt-[20px]">
              {currentData?.meta?.totalElements > 10 && (
                <Pagination
                  page={page}
                  startPage={startPage}
                  setEndPage={setEndPage}
                  endPage={endPage}
                  setStartPage={setStartPage}
                  setPage={setPage}
                  totalPages={currentData?.meta?.totalElements}
                />
              )}
            </div>
          </div>
        </Card>
      )}
    </>
  );
};

export default TransactionHistory;
