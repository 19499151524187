import React from "react";
import {
  PAIconWorkFlowArrowLeft,
  PAIconWorkFlowArrowRedLeft,
} from "assets/images/svgs";
import Table from "layout/table/Table";
import { useNavigate } from "react-router-dom";
import Card from "shared/Card";

const DashBoard = () => {
  const navigate = useNavigate();

  const dummyData = [
    {
      bidAmount: 3,
      amount: "+9,000,000",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      discountRate: 3,
      funderId: "4bddd358-5412-4d62-b0ed-b34e2c3129fa",
      invoiceAmount: 32164082.78,
      status: "Approved",
      invoiceDate: "Invoicing",
      invoiceId: "65dfc0942b607753910b022e",
      transactionId: "2023-02-19",
      lockedBy: "4bddd358-5412-4d62-b0ed-b34e2c3129fa",
      lockedDate: "2024-03-07",
      paymentDate: "2023-04-19",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Taiwo Johnson",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",

      currency: "1 of 3",
      actionType: "Invoice Approval",
    },

    {
      bidAmount: 4,
      amount: "-4,000,000",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      discountRate: 3,
      funderId: "4bddd358-5412-4d62-b0ed-b34e2c3129fa",
      invoiceAmount: 32164082.78,
      status: "Decline",
      invoiceDate: "Early Payment r",
      invoiceId: "65dfc0942b607753910b022e",
      transactionId: "2023-02-19",
      lockedBy: "4bddd358-5412-4d62-b0ed-b34e2c3129fa",
      lockedDate: "2024-03-07",
      paymentDate: "2023-04-19",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "User",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",

      currency: "1 of 3",
      actionType: "Payment",
    },

    {
      bidAmount: 5,
      amount: "+9,000,000",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      discountRate: 3,
      funderId: "4bddd358-5412-4d62-b0ed-b34e2c3129fa",
      invoiceAmount: 32164082.78,
      status: "Approved",
      invoiceDate: "Invoicing",
      invoiceId: "65dfc0942b607753910b022e",
      transactionId: "2023-02-19",
      lockedBy: "4bddd358-5412-4d62-b0ed-b34e2c3129fa",
      lockedDate: "2024-03-07",
      paymentDate: "2023-04-19",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Taiwo Johnson",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",

      currency: "1 of 3",
      actionType: "Invoice Approval",
    },

    {
      bidAmount: 6,
      amount: "-4,000,000",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      discountRate: 3,
      funderId: "4bddd358-5412-4d62-b0ed-b34e2c3129fa",
      invoiceAmount: 32164082.78,
      status: "Decline",
      invoiceDate: "Early Payment r",
      invoiceId: "65dfc0942b607753910b022e",
      transactionId: "2023-02-19",
      lockedBy: "4bddd358-5412-4d62-b0ed-b34e2c3129fa",
      lockedDate: "2024-03-07",
      paymentDate: "2023-04-19",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "User",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",

      currency: "1 of 3",
      actionType: "Payment",
    },

    {
      bidAmount: 7,
      amount: "+9,000,000",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      discountRate: 3,
      funderId: "4bddd358-5412-4d62-b0ed-b34e2c3129fa",
      invoiceAmount: 32164082.78,
      status: "Approved",
      invoiceDate: "Invoicing",
      invoiceId: "65dfc0942b607753910b022e",
      transactionId: "2023-02-19",
      lockedBy: "4bddd358-5412-4d62-b0ed-b34e2c3129fa",
      lockedDate: "2024-03-07",
      paymentDate: "2023-04-19",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Taiwo Johnson",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",

      currency: "1 of 3",
      actionType: "Invoice Approval",
    },

    {
      bidAmount: 8,
      amount: "-4,000,000",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      discountRate: 3,
      funderId: "4bddd358-5412-4d62-b0ed-b34e2c3129fa",
      invoiceAmount: 32164082.78,
      status: "Decline",
      invoiceDate: "Early Payment r",
      invoiceId: "65dfc0942b607753910b022e",
      transactionId: "2023-02-19",
      lockedBy: "4bddd358-5412-4d62-b0ed-b34e2c3129fa",
      lockedDate: "2024-03-07",
      paymentDate: "2023-04-19",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "User",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",

      currency: "1 of 3",
      actionType: "Payment",
    },
    {
      bidAmount: 9,
      amount: "-4,000,000",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      discountRate: 3,
      funderId: "4bddd358-5412-4d62-b0ed-b34e2c3129fa",
      invoiceAmount: 32164082.78,
      status: "Approved",
      invoiceDate: "Invoicing",
      invoiceId: "65dfc0942b607753910b022e",
      transactionId: "2023-02-19",
      lockedBy: "4bddd358-5412-4d62-b0ed-b34e2c3129fa",
      lockedDate: "2024-03-07",
      paymentDate: "2023-04-19",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Taiwo Johnson",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",

      currency: "1 of 3",
      actionType: "Invoice Approval",
    },
    {
      bidAmount: 10,
      amount: "-4,000,000",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      discountRate: 3,
      funderId: "4bddd358-5412-4d62-b0ed-b34e2c3129fa",
      invoiceAmount: 32164082.78,
      status: "Decline",
      invoiceDate: "Early Payment r",
      invoiceId: "65dfc0942b607753910b022e",
      transactionId: "2023-02-19",
      lockedBy: "4bddd358-5412-4d62-b0ed-b34e2c3129fa",
      lockedDate: "2024-03-07",
      paymentDate: "2023-04-19",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "User",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",

      currency: "1 of 3",
      actionType: "Payment",
    },
  ];

  const columns = [
    {
      name: "Date",
      selector: (row) => row.transactionId,
    },
    {
      name: "Sender",
      selector: (row) => row.funder,
    },

    {
      name: "Transaction Type",
      selector: (row) => row.invoiceDate,
    },
    {
      name: "Action Type",
      selector: (row) => row.actionType,

      cell: (row) =>
        row.actionType === "Invoice Approval" ? (
          <p className="text-black font-bold">{row.actionType}</p>
        ) : (
          <>{row.actionType}</>
        ),
    },
    {
      name: "Approval Date",
      selector: (row) => row.paymentDate,
    },

    {
      name: "Approval Level",
      selector: (row) => row.currency,
    },
    // {
    //   name: "Status",
    //   selector: (row) => row.amount,

    //   cell: (row) =>
    //     row.amount.startsWith("+", 0) ? (
    //       <p className="text-success">{row.amount}</p>
    //     ) : row.amount.startsWith("-", 0) ? (
    //       <p className="text-red">{row.amount}</p>
    //     ) : (
    //       <p>{row.amount}</p>
    //     ),
    // },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) =>
        row.status === "Approved" ? (
          <p className="text-[#2EA923]">{row.status}</p>
        ) : row.status === "Decline" ? (
          <p className="text-[#DE1515]">{row.status}</p>
        ) : row.status === "Completed" ? (
          <p className="text-[#2EA923]">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        ),
    },
  ];

  const handleRowClick = (row) => {
    if (row.status === "Completed") {
      navigate(
        `/work-flow-management/workflowcompletedpendingapprovaldetails/${row.bidAmount}`
      );
    } else if (row.status === "Decline") {
      navigate(
        `/work-flow-management/workflowdeclinedpendingapprovaldetails/${row.bidAmount}`
      );
    } else {
      navigate(
        `/work-flow-management/workflowapprovedpendingapprovaldetails/${row.bidAmount}`
      );
    }
  };
  return (
    <div>
      <div className="bg-[#EBEBEB]">
        <div className=" py-4 grid md:grid-cols-[32%,32%,32%]  lg:grid-cols-[32%,32%,32%] gap-5">
          <div
            class="h-[135px] px-5 py-4 bg-white rounded-[10px] flex-col justify-start items-start gap-2.5 inline-flex cursor-pointer"
            onClick={() =>
              navigate("/work-flow-management/workflowpendingapprovalrequests")
            }
          >
            <div class="self-stretch justify-between items-center inline-flex">
              <div class="flex-col justify-start items-start gap-4 inline-flex">
                <div class="text-[#07593d]   text-[16.4px] font-medium ">
                  Pending Approvals{" "}
                </div>
                <div class="self-stretch text-[#07593d] text-[43px] font-bold ">
                  4
                </div>
              </div>
              <PAIconWorkFlowArrowLeft />
            </div>
          </div>

          {/* Next level box */}

          <div
            class="h-[135px] px-5 py-4 bg-white rounded-[10px] flex-col justify-start items-start gap-2.5 inline-flex"
            onClick={() => navigate("/work-flow-management/pendingrequests")}
          >
            <div class="self-stretch justify-between items-center inline-flex">
              <div class="flex-col justify-start items-start gap-4 inline-flex">
                <div class="text-[#07593d]   text-[16.4px] font-medium ">
                  Pending Requests{" "}
                </div>
                <div class="self-stretch text-[#07593d] text-[43px] font-bold ">
                  2
                </div>
              </div>
              <PAIconWorkFlowArrowLeft />
            </div>
          </div>

          {/* third */}

          <div
            class="h-[135px] px-5 py-4 bg-white rounded-[10px] flex-col justify-start items-start gap-2.5 inline-flex cursor-pointer"
            onClick={() =>
              navigate("/work-flow-management/workflowdclinedrequests")
            }
          >
            <div class="self-stretch justify-between items-center inline-flex">
              <div class="flex-col justify-start items-start gap-4 inline-flex">
                <div class="text-[#bb0c0c]   text-[16.4px] font-medium ">
                  Declined Requests
                </div>
                <div class="self-stretch text-[#bb0c0c] text-[43px] font-bold ">
                  2
                </div>
              </div>
              <PAIconWorkFlowArrowRedLeft />
            </div>
          </div>
        </div>

        <>
          <Card className="rounded-[10px] mt-[24px]   pb-36">
            <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
              <div>
                <p className="text-[#1d0220] text-sm font-bold">
                  Recent Approvals
                </p>
                <p className="text-[#5f4d61] text-[10px] font-normal">
                  Workflow approval history
                </p>
              </div>
              <p
                onClick={
                  () => {
                    navigate(
                      "/work-flow-management/detail/approvalhistoryalldetails"
                    );
                  }
                  // navigate("/reimbursement/detail/directfundingpageDetail")
                }
                className="text-black text-sm font-medium underline cursor-pointer"
              >
                View All
              </p>
            </div>
            <div className="px-[24px] pb-[24px]">
              <Table
                columns={columns}
                data={dummyData}
                onRowClicked={(row) => handleRowClick(row)}
                pointer
                tableHeader
                className="mt-5"
              />
            </div>
          </Card>
        </>
      </div>
    </div>
  );
};

export default DashBoard;
