import React, { useState } from "react";
import { formatCapitaliseString } from "utilities/helper/formatCapitaliseString";

const MultiSelectDropdown = ({
  options,
  selectedOptions,
  setSelectedOptions,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSelectOption = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  return (
    <div className="relative inline-block w-full ">
      <label
        htmlFor=""
        className="font-normal text-xs text-textColor relative bottom-[10px]"
      >
        Industry
      </label>
      <div
        className="flex items-center appearance-none  text-xs  min-h-10 py-1 pr-8 leading-tight text-gray-500 bg-white border-b border-gray-600 outline-none focus:outline-none cursor-pointer"
        onClick={toggleDropdown}
      >
        {selectedOptions.length === 0 ? (
          <span className="text-gray-500">Select industry</span>
        ) : (
          <div className="flex text-xs flex-wrap gap-2">
            {selectedOptions.map((option) => (
              <span
                key={option}
                className=" text-xs text-black px-2 py-1   rounded-xl border border-black/30"
              >
                {/* {option} */}
                {formatCapitaliseString(option)}
              </span>
            ))}
          </div>
        )}
      </div>
      <div className="absolute inset-y-0 right-0 flex items-center px-2 top-[30px] pointer-events-none">
        <svg
          width="16"
          height="15"
          viewBox="0 0 16 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="16" height="15" fill="#1E1E1E" />
          <g id="onoarding">
            <path
              d="M-4206 -3317C-4206 -3318.1 -4205.1 -3319 -4204 -3319H87942C87943.1 -3319 87944 -3318.1 87944 -3317V1907C87944 1908.1 87943.1 1909 87942 1909H-4204C-4205.11 1909 -4206 1908.1 -4206 1907V-3317Z"
              fill="#404040"
            />
            <path
              d="M-4204 -3318H87942V-3320H-4204V-3318ZM87943 -3317V1907H87945V-3317H87943ZM87942 1908H-4204V1910H87942V1908ZM-4205 1907V-3317H-4207V1907H-4205ZM-4204 1908C-4204.55 1908 -4205 1907.55 -4205 1907H-4207C-4207 1908.66 -4205.66 1910 -4204 1910V1908ZM87943 1907C87943 1907.55 87942.6 1908 87942 1908V1910C87943.7 1910 87945 1908.66 87945 1907H87943ZM87942 -3318C87942.6 -3318 87943 -3317.55 87943 -3317H87945C87945 -3318.66 87943.7 -3320 87942 -3320V-3318ZM-4204 -3320C-4205.66 -3320 -4207 -3318.66 -4207 -3317H-4205C-4205 -3317.55 -4204.55 -3318 -4204 -3318V-3320Z"
              fill="white"
              fill-opacity="0.1"
            />
            <g id="4">
              <rect
                width="1440"
                height="1303"
                transform="translate(-900 -819)"
                fill="white"
              />
              <g id="content">
                <g id="Frame 427322801">
                  <g id="Frame 427322800">
                    <g id="company details">
                      <g id="userId">
                        <g id="Frame 427324610">
                          <g id="email input">
                            <mask id="path-3-inside-1_0_1" fill="white">
                              <path d="M-392.5 -13H32.5V28H-392.5V-13Z" />
                            </mask>
                            <path
                              d="M-392.5 -13H32.5V28H-392.5V-13Z"
                              fill="white"
                            />
                            <path
                              d="M32.5 27.4H-392.5V28.6H32.5V27.4Z"
                              fill="#222222"
                              mask="url(#path-3-inside-1_0_1)"
                            />
                            <g id="Frame 427322791">
                              <g id="arrow-down">
                                <g id="vuesax/bold/arrow-down">
                                  <g id="arrow-down_2">
                                    <path
                                      id="Vector"
                                      d="M11.7 5.11328H7.80621H4.29996C3.69996 5.11328 3.39996 5.83828 3.82496 6.26328L7.06246 9.50078C7.58121 10.0195 8.42496 10.0195 8.94371 9.50078L10.175 8.26953L12.1812 6.26328C12.6 5.83828 12.3 5.11328 11.7 5.11328Z"
                                      fill="#292D32"
                                    />
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
      {isDropdownOpen && (
        <ul className="absolute z-10  bg-white  rounded-md text-sm mt-1 max-h-60 overflow-auto w-full">
          {options.map((option) => (
            <li
              key={option}
              className={`p-2 cursor-pointer  flex gap-2 `}
              onClick={() => handleSelectOption(option)}
            >
              <div
                className={` ${
                  selectedOptions.includes(option)
                    ? "  h-4  w-4 rounded-[3px]  bg-[#f08000] "
                    : "  h-4  w-4 rounded-[3px] border border-black"
                }`}
              ></div>

              {formatCapitaliseString(option)}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default MultiSelectDropdown;
