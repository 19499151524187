import { PAIconInventoryArrowLeft } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import Table from "layout/table/Table";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "shared/Button";
import TableIcons from "../../../assets/images/svgs/TableIcons.svg";
import { useEffect, useState } from "react";
import { ApplyRulesAndConfigBulk, createDirectFundingBulkRequest,  getInvoicesByDate,  getVirtualAccounts } from "appstate/api/invoice/invoice";
import Pagination from "shared/Pagination";
import BulkRequestModal from "../fundingSection/earlyPaymentRequest/singlerequest/Modal/BulkRequestModal";
import BulkVsa from "../fundingSection/earlyPaymentRequest/singlerequest/Modal/BulkVsa";
import ReceivingFundModal from "../fundingSection/earlyPaymentRequest/singlerequest/Modal/ReceivingFundModal";
import SuccessEarlyPayment from "../fundingSection/earlyPaymentRequest/singlerequest/Modal/SuccessEarlyPayment";
import PinModalPayment from "../fundingSection/earlyPaymentRequest/singlerequest/Modal/PinModal";
import EarlyPaymentConfirmation from "../fundingSection/earlyPaymentRequest/singlerequest/Modal/EarlyPaymentConfirmation";
import TransferFundModal from "../fundingSection/earlyPaymentRequest/singlerequest/Modal/TransferFund";
import BulkInvoiceModal from "../fundingSection/earlyPaymentRequest/singlerequest/Modal/bulkinvoicemodal";
import toast from "react-hot-toast";
// import CalendarIcon from "../../assets/images/svgs/calendar-picker-icon.svg";

const BulkDirectFundingInvoiceDetailPage = () => {
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [showPercentageModal, setShowPercentageModal] = useState(false);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [receivingAccount, setShowReceivingAccount] = useState(false);
  const [showVirtualSelectionModal, setShowVirtualSelectionModal] =
    useState(false);
  const [open, setOpen] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [apply, setApply] = useState(false);
   // eslint-disable-next-line
  const [discountData, setDiscountData] = useState([]);
  // eslint-disable-next-line
  const [totalRequestAmount, setTotalRequestAmount] = useState(0);
    // eslint-disable-next-line
  const [totalInvoiceAmount, setTotalInvoiceAmount] = useState(0);
  const [totalReceivableAmount, setTotalReceivableAmount] = useState(0);
  const [description, setDescription] = useState("");
  const [isDescriptionFilled, setIsDescriptionFilled] = useState(false);
  const [applied, setApplied] = useState(false);
  const [otpInputs, setOtpInputs] = useState(["", "", "", ""]);
  const [percentage, setPercentage] = useState("");
  const [date, setDate] = useState("");
  // eslint-disable-next-line
  const [selectedDate, setSelectedDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [approvedInvoices, setApprovedInvoices] = useState([]);
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const [total, setTotal] = useState("");
  const [virtualAccounts, setVirtualAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [isActiveRecourse, setIsActiveRecourse] = useState(false);
  const [selectedInvoiceData, setSelectedInvoiceData] = useState(null);


  
  // const [page, setPage] = useState(1);
  // eslint-disable-next-line
  const [size, setSize] = useState(10); 
  // eslint-disable-next-line
  const [search, setSearch] = useState("");
  // const [status] = useState("APPROVED");
  // const [total, setTotal] = useState(0); 
  // const [showPercentageModal, setShowPercentageModal] = useState(false);

  const location = useLocation();
  // eslint-disable-next-line
  const { funderId } = location.state || {};

  const navigate = useNavigate();
  function handleNavigate() {
    navigate(-1);
  }

  // /api/v1/direct-funding/direct-funding-request/bulk
  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  useEffect(() => {
    const fetchVirtualAccounts = async () => {
      try {
        const result = await getVirtualAccounts();
        setVirtualAccounts(result?.data);
      } catch (error) {
        console.error("Failed to fetch virtual accounts:", error);
      }
    };

    fetchVirtualAccounts();
  }, [])

  const handleApplyRulesAndConfig = async () => {
    try {
      const payload = {
        invoices: selectedInvoices,
        funderId,
        description: "desc",
        newPaymentDate: date,
        requestPercentage: percentage, 
        transactionPin: "", 
        withoutRecourse: isActiveRecourse
      };
  
      const result = await ApplyRulesAndConfigBulk(payload);
      
      // console.log("response " , response)
      if (result?.success === true) {
        setDiscountData(result?.data?.earlyPaymentDiscountedResponseList);
        setTotalRequestAmount(result?.data?.totalRequestAmount);
        setTotalReceivableAmount(result?.data?.totalReceivableAmountWithoutDiscount);
        setTotalInvoiceAmount(result?.data?.totalInvoiceAmount)
        // console.log("Discount data fetched successfully:", result?.data);
      } else {
        console.error("Unexpected response status:", result?.status);
      }
    } catch (error) {
      // Handle errors, toast messages are already being shown in ApplyRulesAndConfigBulk
      console.error("Error applying rules and config:", error);
    }
  };
  

  const handleSendEarlyPaymentRequest = async () => {
    if (!selectedInvoices.length) {
      toast.error("No invoices selected for early payment request!");
      return;
    }
    if (!selectedAccount) {
      toast.error("Please select a virtual account!");
      return;
    }

    const pin = otpInputs.toString();
    const transactPin = pin.replace(/,/g, "");

    const payload = {
      invoices: selectedInvoices,
      funderId,
      description,
      requestAmount: totalRequestAmount,
      virtualAccountId: selectedAccount.id,
      newPaymentDate: date,
      requestPercentage: percentage,
      transactionPin: transactPin,
      withoutRecourse: isActiveRecourse,
    };

    setLoading(true);
    try {
      const response = await createDirectFundingBulkRequest(payload);
      if (response.success === true) {
        setShowConfirmModal(false);
        setShowSuccess(true);
        setTimeout(() =>  {
          navigate("/supplier-early-payments");
        }, 2000)
      }
    } catch (error) {
      console.error("Failed to send early payment request:", error);
      toast.error("Failed to send request. Please try again.");
    } finally {
      setLoading(false);
    }
  };


  const calculateTotalInvoiceAmount = () => {
    const total = selectedInvoiceData?.reduce((sum, invoice) => {
      return sum + (invoice.invoicedAmount || 0);
    }, 0);
    return total.toFixed(2);
  };
  
  const handleProceed = () => {
    setShowPercentageModal(false);
    setShowInvoiceModal(true);
  };

  const handleGoBack = () => {
    setShowInvoiceModal(false);
    setShowPercentageModal(true);
  };

  const handleSelectAccount = () => {
    setShowInvoiceModal(false);
    setShowReceivingAccount(true);
  };

  const confirmPayment = () => {
    setShowConfirmModal(true);
    setShowPin(false);
  };

  const onClosePinModal = () => {
    setShowPin(false);
  };


  const fetchInvoicesByDate = async () => {
    try {
      setLoading(true);
      const result = await getInvoicesByDate({
        buyerId: funderId,
        earlyPaymentDate: selectedDate,
      });
      if (result) {
        setTotal(result?.meta?.totalElements);
         setApprovedInvoices(result?.data || []);
      } else {
        toast.error("No invoices found for the selected date.");
      }
    } catch (error) {
      console.error("Failed to fetch invoices by date:", error);
    } finally {
      setLoading(false);
    }
  };

  // const onRowClicked = (row) => {
  //   const buyerId = row?.userId;
  //   navigate("/bulk-early-payment-invoice-details", { state: { buyerId, row } });
  // };

  useEffect(() => {
    fetchInvoicesByDate();
  // eslint-disable-next-line
}, [selectedDate]);


  const onRowClicked = (row) => {
    // let path = "/directfunding-invoice-details";
    // // // dispatch(getAssociatedBuyerId(row?.id));
    // navigate(path, {state: {directFunding: true}});
  };

  const handleCheckboxChange = (invoiceId, invoice) => {
    setSelectedInvoices((prevSelected) =>
      prevSelected.includes(invoiceId)
        ? prevSelected.filter((id) => id !== invoiceId)
        : [...prevSelected, invoiceId]
    );
    setSelectedInvoiceData((prevData) => {
      if (prevData?.some((item) => item.id === invoice.id)) {
        return prevData.filter((item) => item.id !== invoice.id);
      } else {
        return prevData ? [...prevData, invoice] : [invoice];
      }
    });
  };

  const HeaderWithIcon = ({ icon, text }) => (
    <div className="flex items-center">
      <img src={icon} alt="" className="mr-2" />
      <span>{text}</span>
    </div>
  );

  const column = [
    {
      name: <HeaderWithIcon icon={TableIcons} text="Invoice Reference" />,
      selector: (row) => (
        <div className="flex items-center">
           <input
            type="checkbox"
            checked={selectedInvoices.includes(row?.id)}
            onChange={() => handleCheckboxChange(row?.id, row)}
            className={`mr-2 custom-checkbox w-4 h-4 appearance-none border-2 rounded-sm ${
              selectedInvoices.includes(row?.id)
                ? "bg-[#F08000] border-[#F08000]"
                : "bg-[#C2C0C0] border-[#C2C0C0]"
            } cursor-pointer`}
            style={{
              outline: "none",
            }}
          />
          {row?.invoiceRef}
        </div>
      ),
    },
    {
      name: <HeaderWithIcon icon={TableIcons} text="Buyer" />,
      selector: (row) => row?.buyer,
    },
    
    {
      name: "Invoice Date",
      selector: (row) => row?.invoiceDate,
    },
    {
      name: "Due Date",
      selector: (row) => row?.dueDate,
    },
    {
      name: "Currency",
      selector: (row) => row?.currency,
    },
    {
      name: "Invoice Amount",
      selector: (row) => row?.invoiceAmount,
    },
    {
      name: "Status",
      selector: (row) => (
        <span
          style={{
            color: row.status === "APPROVED" ? "#2EA923" : undefined,
          }}
        >
          {row?.status}
        </span>
      ),
    },
  ];

  const handleSelectAll = () => {
    if (selectedInvoices.length === approvedInvoices.length) {
      setSelectedInvoices([]);
    } else {
      setSelectedInvoices(approvedInvoices.map((invoice) => invoice.id));
    }
  };

  // const isAnySelected = Object.keys(selectedInvoices).some(
  //   (key) => selectedInvoices[key]
  // );

  // const handlePercentageModal = () => {
  //   setShowPercentageModal(true);
  // };

  const calculateDiscountTotalInvoiceAmount = () => {};

  // const isAnySelected = Object.keys(selectedInvoices).some(
  //   (key) => selectedInvoices[key]
  // );

  const closePercentageModal = () => {
    setShowPercentageModal(false);
  };

  const handlePercentageModal = () => {
    setShowPercentageModal(true);
  };

  const handleShowVirtualAccountSelection = () => {
    setShowVirtualSelectionModal(true);
    setShowReceivingAccount(false);
  };

  const handleShowPin = () => {
    setShowTransferModal(false);
    setShowPin(true);
  };

  const handleCloseVirtualAccount = () => {
    setShowReceivingAccount(false);
    setShowVirtualSelectionModal(false);
  };

  const handleChangeAccount = () => {
    setShowVirtualSelectionModal(true);
    setShowTransferModal(false);
  };

  const handleCloseTransferModal = () => {
    setShowTransferModal(false);
  };

  function handleSendRequest() {
    setOpen(false);
    setShowTransferModal(true);
    //  onClose();
  }

  function closeAccountModal() {
    setShowVirtualSelectionModal(false);
  }


  const totalPages = total;

  return (
    <DashboardLayout
      section="Invoice"
      subHeader="Here is an overview of your invoices"
    >
       {(open || showConfirmModal) && (
        <div className="w-full fixed inset-0 bg-black bg-opacity-50 z-10"></div>
      )}
      {open && (
        <div className="mr-8 absolute mt-[20px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white w-[614px] h-[254px] z-10 rounded-[10px] shadow-lg">
          <EarlyPaymentConfirmation
            hide={() => setOpen(false)}
            // isLoading={earlyPaymentLoading}
            handleAssociate={handleSendRequest}
            // earlyPayment={true}
            warning={
              <>
                Would you like to proceed to use this account{" "}
                <span className="text-primaryColor">7364738921</span> to bid on
                invoice?
              </>
            }
          />
        </div>
      )}

      {showTransferModal && (
        <div>
          <TransferFundModal
            handleCloseVirtualAccount={handleCloseVirtualAccount}
            handleShowVirtualAccountSelection={
              handleShowVirtualAccountSelection
            }
            setShowReceivingFundModal={setShowReceivingAccount}
            handleChangeAccount={handleChangeAccount}
            handleCloseTransferModal={handleCloseTransferModal}
            handleShowPin={handleShowPin}
            selectedAccount={selectedAccount}
            invoice={true}
          />
        </div>
      )}

      {showConfirmModal && (
        <div className="mr-8 absolute mt-[20px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white w-[614px] h-[254px] z-10 rounded-[10px] shadow-lg">
          <EarlyPaymentConfirmation
            hide={() => setShowConfirmModal(false)}
            invoice={true}
            // isLoading={earlyPaymentLoading}
            handleAssociate={handleSendEarlyPaymentRequest}
            // earlyPayment={true}
            warning=" Would you like to proceed with sending this bulk early payment request? "
          />
        </div>
      )}

      {showPin && (
        <PinModalPayment
          confirmPayment={confirmPayment}
          isOpen={showPin}
          onCancel={onClosePinModal}
          invoice={true}
          otpInputs={otpInputs}
          setOtpInputs={setOtpInputs}
        />
      )}

      {showSuccess && (
        <SuccessEarlyPayment
          isOpen={showSuccess}
          onClose={() => setShowSuccess(false)}
          invoice={true}
          message="Early payment request successful"
        />
      )}

      {receivingAccount && (
        <ReceivingFundModal
          invoice={true}
          handleCloseVirtualAccount={handleCloseVirtualAccount}
          handleShowVirtualAccountSelection={handleShowVirtualAccountSelection}
          setShowReceivingFundModal={setShowReceivingAccount}
        />
      )}
      {showVirtualSelectionModal && (
        <div>
          <BulkVsa
            handleCloseVirtualAccount={handleCloseVirtualAccount}
            setOpen={setOpen}
            onClose={closeAccountModal}
            virtualAccounts={virtualAccounts}
            selectedAccount={selectedAccount}
            setSelectedAccount={setSelectedAccount}
          />
        </div>
      )}
      <BulkRequestModal
        handleProceed={handleProceed}
        onClose={closePercentageModal}
        isShown={showPercentageModal}
        lengthOfInvoice={selectedInvoices?.length}
        buyer={approvedInvoices[0]?.buyer}
        percentage={percentage}
        setPercentage={setPercentage}
        date={date}
        setDate={setDate}
        apply={apply}
        setApply={setApply}
        handleFetchDiscount={handleApplyRulesAndConfig}
        calculateTotalInvoiceAmount={calculateTotalInvoiceAmount}
        calculateDiscountTotalInvoiceAmount={
          calculateDiscountTotalInvoiceAmount
        }
        totalRequestAmount={totalRequestAmount.toFixed(2)}
      />
      <BulkInvoiceModal
        handleGoBack={handleGoBack}
        isShown={showInvoiceModal}
        handleSelectAccount={handleSelectAccount}
        date={date}
        lengthOfInvoice={approvedInvoices?.length}
        buyer={approvedInvoices[0]?.buyer}
        totalRequestAmount={totalRequestAmount.toFixed(2)}
        percentage={percentage}
        calculateTotalInvoiceAmount={calculateTotalInvoiceAmount}
        totalReceivableAmount={totalReceivableAmount}
        description={description}
        setDescription={setDescription}
        isDescriptionFilled={isDescriptionFilled}
        setIsDescriptionFilled={setIsDescriptionFilled}
        applied={applied}
        setApplied={setApplied}
        setIsActiveRecourse={setIsActiveRecourse}
        isActiveRecourse={isActiveRecourse}
        selectedInvoiceData={selectedInvoiceData}
      />
      <div>
        <header className="bg-alabasterHeader p-[24px] rounded-t-[10px]">
          <div className="flex justify-between">
            <div
              onClick={handleNavigate}
              className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
            >
              <PAIconInventoryArrowLeft />
              <p className="mt-[2px] text-sm font-normal text-lightGray">
                Go Back
              </p>
            </div>
            <div className="flex">
              <div className="pr-2">
                <Button
                  neutral
                  onClick={handleSelectAll}
                  className={`py-[12px] mt-[10px] md:mt-0 px-[24px] rounded-[5px] font-medium ${
                    selectedInvoices?.length
                      ? "bg-[#2EA923] text-sm !text-white"
                      : "bg-[#C2C0C0] text-sm !text-white"
                  }`}
                >
                  Select all
                </Button>
              </div>
              <div>
              <Button
                  onClick={handlePercentageModal}
                  neutral
                  className={`py-[12px] mt-[10px] md:mt-0 px-[24px] rounded-[5px] font-medium ${
                    selectedInvoices?.length 
                      ? "bg-[#F08000] text-sm !text-white"
                      : "bg-[#C2C0C0] text-sm !text-white"
                  }`}
                >
                  {selectedInvoices?.length
                    ? "Set request percentage"
                    : "Request early payment"}
                </Button>
              </div>
            </div>
          </div>
          <div className="flex justify-between flex-wrap ">
            <div>
              <p className="text-black text-sm font-medium">
                {approvedInvoices?.length} Approved invoices
              </p>
              <p className="text-textColor text-xs font-normal">
                 Available for payment
              </p>
              <div className="pt-2">
                <label className="text-black text-sm font-medium">
                  Select  request date
                </label>
                <div className="relative mt-2">
                  <input
                    type="date"
                    onChange={handleDateChange}
                    placeholder="Enter date"
                    className="border border-gray-300 rounded-[5px] py-[10px] px-[12px] text-sm w-[350px] pr-[40px]"
                  />
                </div>
              </div>
            </div>
          </div>
          <br />
        </header>
      </div>
      <div className="p-[24px] bg-white rounded-b-[10px]">
        <Table
          columns={column}
          pointer
          data={approvedInvoices}
          onRowClicked={onRowClicked}
        />
      </div>
      <div className="flex justify-center gap-x-[8px]  mt-12">
        {!loading && totalPages > 10 && (
          <Pagination
            page={page}
            startPage={startPage}
            setEndPage={setEndPage}
            endPage={endPage}
            setStartPage={setStartPage}
            setPage={setPage}
            totalPages={totalPages}
          />
        )}
      </div>
    </DashboardLayout>
  );
};

export default BulkDirectFundingInvoiceDetailPage;
