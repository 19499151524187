import Disassociate from "../../../../assets/images/svgs/disassociate.svg";
import UserAdd from "../../../../assets/images/svgs/userdis.svg";
import Edit from "../../../../assets/images/svgs/edit-main.svg";
import DocumentText from "../../../../assets/images/svgs/document-text.svg";

const CardDisassociation = ({ funderMember, onRowClicked, handleShowModal  }) => {
  return (
    <div className="bg-white shadow-lg rounded-lg p-3 w-48">
      <div onClick={onRowClicked } className="flex items-center gap-2 cursor-pointer hover:bg-gray-100 p-2 rounded">
        <img src={UserAdd} alt="View Details" />
        <span>View Details</span>
      </div>
      {funderMember && <div onClick={onRowClicked }className="flex items-center gap-2 cursor-pointer hover:bg-gray-100 p-2 rounded">
        <img src={DocumentText} alt="View Details" />
        <span>{funderMember && "Create Invoice"}</span>
      </div>}
      <div onClick={onRowClicked } className="flex items-center gap-2 cursor-pointer hover:bg-gray-100 p-2 rounded">
        <img src={Edit} alt="Change Tier" />
        <span>{funderMember ? "Edit Tier" : "Change Tier"}</span>
      </div>
      <hr className="my-2" />
      <div onClick={handleShowModal}  className="flex items-center gap-2 cursor-pointer hover:bg-gray-100 p-2 rounded">
        <img src={Disassociate} alt="Disassociate" />
        <span style={{color: "#DE1515"}}>Disassociate</span>
      </div>
    </div>
  );
};

export default CardDisassociation;
