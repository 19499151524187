// import {
//   PAIconCocacola,
//   PAIconDownload,
//   PAIconModelOne,
// } from "assets/images/svgs";

import { cancelPendingConfirmation } from "appstate/openMarket/openMarketSlice";
import { PAIconIconParkSucess, PAIconMDISuccess } from "assets/images/svgs";
import ViewDocumentModal from "features/RulesConfig/common/components/modals/DeleteRuleModal";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { formatCapitaliseString } from "utilities/helper/formatCapitaliseString";

function InvoiceDetails({
  setLockModalOpen,
  invoiceByIdData,
  openMarketReference,
  status,
  pending,
  setActiveTab,
}) {
  const [confirmCancelModal, setConfirmCancelModal] = useState(false);

  const dispatch = useDispatch();

  function handleDownloadSuccessful() {
    // setConfirmCancelModal(false);

    dispatch(cancelPendingConfirmation({ openMarketReference })).then(
      (data) => {
        if (data?.payload?.success) {
          setConfirmCancelModal(false);
          // setIsSuccessModalOpen(true);
          // setSuccessMessage("Confirmation successful");
          // setTimeout(() => {
          //   handleGoBack();
          // }, 2000);
          toast.custom(
            <>
              <div className="flex-1 items-center justify-center flex w-full h-full">
                <div className="flex mt-[300px]">
                  <div className="bg-primaryColor w-[147.79px] h-[160px] flex flex-col items-center justify-center rounded-l-[7px] shadow-lg">
                    <PAIconMDISuccess />
                  </div>
                  <div className="bg-white  flex-col justify-center items-center self-stretch py-[25px] h-[160px] rounded-r-[7px] shadow-lg flex px-16 ">
                    <PAIconIconParkSucess />
                    <p className="text-dustyGray text-[13px] text-center mt-[17.6px]">
                      {data?.payload?.message || "Request Cancelled"}
                    </p>
                  </div>
                </div>
              </div>
            </>
          );

          setTimeout(() => {
            toast.dismiss();
            setActiveTab(2);
          }, 500);
        }
      }
    );

    // toast.custom(
    //   <>
    //     <div className="flex-1 items-center justify-center flex w-full h-full">
    //       <div className="flex mt-[300px]">
    //         <div className="bg-primaryColor w-[147.79px] h-[160px] flex flex-col items-center justify-center rounded-l-[7px] shadow-lg">
    //           <PAIconMDISuccess />
    //         </div>
    //         <div className="bg-white  flex-col justify-center items-center self-stretch py-[25px] h-[160px] rounded-r-[7px] shadow-lg flex px-16 ">
    //           <PAIconIconParkSucess />
    //           <p className="text-dustyGray text-[13px] text-center mt-[17.6px]">
    //             Request Cancelled
    //           </p>
    //         </div>
    //       </div>
    //     </div>
    //   </>
    // );

    // setTimeout(() => {
    //   toast.dismiss();
    //   setActiveTab(2);
    // }, 500);
  }

  const formatAmount = (amount) => {
    if (typeof amount !== "number") return "0.00";
    return amount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const formatDate = (dateArray) => {
    if (!Array.isArray(dateArray) || dateArray.length !== 3) return "";
    const date = new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}`;
  };

  return (
    <motion.section
      initial={{
        x: 20,
      }}
      animate={{
        x: 0,
      }}
      exit={{
        x: -20,
      }}
      transition={{
        delay: 0.6,
      }}
      className="flex flex-col gap8 px-6 pt-7 pb-24 w-[60%] semilg:w-[55%] bg-white rounded-[10px]"
    >
      <div className="mb-2 flex flex-col gap-3 ">
        <header className="flex items-center justify-between">
          <h1 className=" text-[18px] font-bold">
            Reference No | {invoiceByIdData?.data?.openMarketReference}
          </h1>

          {pending ? (
            <div className="flex gap-5">
              <h1
                className="rounded px-2 py-1 bg-white text-red border border-red cursor-pointer"
                onClick={() => setConfirmCancelModal(true)}
              >
                Cancel
              </h1>
              <p className="text-sm bg-[#FFBE5557] text-[#F08000] flex items-center py-1.5 px-3 rounded">
                Pending Buyer Confirmation
              </p>
            </div>
          ) : (
            <h1
              className={`rounded px-2 py-1 ${
                status === "POSTED"
                  ? "text-primaryColor bg-white border border-primaryColor"
                  : "text-secondaryColor bg-white border border-secondaryColor"
              }`}
            >
              {formatCapitaliseString(invoiceByIdData?.data?.status)}
              {/* {invoiceByIdData?.data?.status || "Bid Active" || status === "Posted" } */}
            </h1>
          )}
        </header>

        <main className=" w-full flex justify-between">
          <div className="flex flex-col gap-3 w-2/5">
            {/* <div className="flex justify-between">
              <span className=" font-medium">Invoice | INV-2002</span>
              <span>{invoiceByIdData?.data?.invoiceReference}</span>
            </div> */}

            <div className="flex flex-col gap-1">
              <div className="flex justify-between">
                <span className=" font-semibold text-xs">Invoice Number:</span>
                <span className="text-[#979797]  text-xs">
                  {invoiceByIdData?.data?.invoiceNumber || "INV-2024"}
                </span>
              </div>
              <div className="flex justify-between">
                <span className=" font-semibold text-xs">Created Date:</span>
                <span className="text-[#979797]  text-xs">
                  {formatDate(
                    invoiceByIdData?.data?.createdDate?.slice(0, 10)
                  ) || "23-Dec-2024"}
                </span>
              </div>
              <div className="flex justify-between">
                <span className=" font-semibold text-xs">Submitted Date:</span>
                <span className="text-[#979797]  text-xs">
                  {formatDate(
                    invoiceByIdData?.data?.submittedDate?.slice(0, 10)
                  ) || "23-Dec-2024"}
                </span>
              </div>
              <div className="flex justify-between">
                <span className=" font-semibold text-xs">Approved Date:</span>
                <span className="text-[#979797]  text-xs">
                  {formatDate(
                    invoiceByIdData?.data?.approvedDate?.slice(0, 10)
                  ) || "23-Dec-2024"}
                </span>
              </div>
              <div className="flex justify-between">
                <span className=" font-semibold text-xs">Due Date:</span>
                <span className="text-[#979797]  text-xs">
                  {formatDate(invoiceByIdData?.data?.dueDate?.slice(0, 10)) ||
                    "23-Dec-2024"}
                </span>
              </div>

              <div className="flex justify-between">
                <span className=" font-semibold text-xs">Issuance Date</span>
                <span className="text-[#979797]  text-xs">
                  {formatDate(
                    invoiceByIdData?.data?.issuanceDate?.slice(0, 10)
                  ) || "23-Dec-2024"}
                </span>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-3 w-2/5">
            <div className="flex justify-between">
              <span className=" font-semibold text-xs">Request Amount: </span>
              <span className="text-[#979797]  text-xs">
                {formatAmount(invoiceByIdData?.data?.fundingAmount)}
              </span>
            </div>
            <div className="flex justify-between">
              <span className=" font-semibold text-xs">Request Date: </span>
              <span className="text-[#979797]  text-xs">
                {formatDate(invoiceByIdData?.data?.fundRequestDate)}
              </span>
            </div>
            <div className="flex justify-between">
              <span className=" font-semibold text-xs">Payment Date: </span>
              <span className="text-[#979797]  text-xs">
                {formatDate(invoiceByIdData?.data?.paymentDate)}
              </span>
            </div>
          </div>
        </main>
      </div>

      {/* Buyer details and co. */}
      <div className="flex justify-between my-5 border-b-[5px] border-b-[#F6F5FA] py-3">
        <div className="flex flex-col">
          <span className="font-bold text-sm">
            {invoiceByIdData?.data?.buyerCompanyName}
          </span>
          <span className="text-[#979797] text-xs">Buyer details</span>
        </div>
        <div className="flex flex-col">
          <span className="font-bold text-sm">
            {invoiceByIdData?.data?.paymentTerms}
          </span>
          <span className="text-[#979797] text-xs">Payment</span>
        </div>
        <div className="flex flex-col">
          <span className="font-bold text-sm ">
            {invoiceByIdData?.data?.currency}
          </span>
          <span className="text-[#979797] text-xs">Currency</span>
        </div>
      </div>

      <div className="flex mb-8 flex-col gap-4">
        <header className="border-b-[5px] border-b-[#F6F5FA] pb-3 flex items-center justify-between">
          <h1 className="text-[18px] font-normal">Line Items</h1>
          <p>{invoiceByIdData?.data?.items?.length} Total Items</p>
        </header>

        <div className=" w-full overflow-x-auto">
          <table className=" w-full overflow-x-auto">
            <thead className="bg-subtleGray w-">
              <tr className="bg-unusualGray p-2.5 ">
                <td className="text-[#11192A] p-2.5 text-xs font-bold">Item</td>
                <td className="p-2.5 text-xs font-bold">Description</td>
                <td className="p-2.5 text-xs font-bold">Unit Price</td>
                <td className="p-2.5 text-xs font-bold">Quantity</td>
                <td className="p-2.5 text-xs font-bold">
                  Amount
                  <br />
                  (before tax)
                </td>
                <td className="p-2.5 text-xs font-bold">Tax Rate</td>
                <td className="p-2.5 text-xs font-bold">Tax Amount</td>
                <td className="p-2.5 text-xs font-bold">Total Price</td>
              </tr>
            </thead>
            <tbody>
              {invoiceByIdData?.data?.items?.map((item, index) => (
                <tr key={index} className=" py-[3px] px-1.5">
                  <td className=" text-xs text-[rgb(17,25,42)] p-2.5">
                    {item?.itemName}
                  </td>
                  <td className=" text-xs text-[#11192a] p-2.5">
                    {item?.description}
                  </td>
                  <td className=" text-xs text-[#11192a] p-2.5">
                    {invoiceByIdData?.data?.currency === "NGN" ? "₦" : "$"}{" "}
                    {item?.unitPrice}
                  </td>
                  <td className=" text-xs text-[#11192a] p-2.5">
                    {item?.quantity}
                  </td>
                  <td className=" text-xs text-[#11192a] p-2.5">
                    {invoiceByIdData?.data?.currency === "NGN" ? "₦" : ""}{" "}
                    {formatAmount(item?.amount)}
                  </td>
                  <td className=" text-xs text-[#11192a] p-2.5">
                    {item?.taxRate}%
                  </td>
                  <td className=" text-xs text-[#11192a] p-2.5">
                    {formatAmount(item?.taxAmount)}
                  </td>
                  <td className=" text-xs text-[#11192a] p-2.5">
                    {formatAmount(item?.totalPrice)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Accounts Information */}
        <div className="flex itemscenter justify-between px6">
          <div>
            <h1 className="border-b border-[#B1A6B2] font-semibold">
              Receiving Funding Account Information
            </h1>
            <p className="mt-3 text[#979797]">
              {invoiceByIdData?.data?.fundingAccountNumber}
            </p>
            <p className="text[#979797]">
              {invoiceByIdData?.data?.fundingAccountName}
            </p>
          </div>
          <div className="flex flex-col">
            <div className="flex justify-between gap-x-[100px] w-full">
              <span className="text-xs w-[97px]">Sub Total</span>
              <span className="text-[#979797] text-xs text-right">
                {formatAmount(invoiceByIdData?.data?.subTotal)}
              </span>
            </div>

            <div className="flex justify-between w-full gap-x-[100px] my-2">
              <span className="flex items-center text-xs w-[97px]">
                Discount%
                <span className="border border-[#979797] pr-10 pl-2 inline-block ml-4">
                  {invoiceByIdData?.data?.discount}%
                </span>
              </span>
              <span className="text-[#979797] text-xs">
                {formatAmount(invoiceByIdData?.data?.discountAmount)}
              </span>
            </div>

            <div className="flex justify-between w-full gap-x-[100px] pb-5">
              <span className="flex items-center text-xs w-[97px]">
                VAT%
                <span className="border border-[#979797] ml-10 pr-11 pl-2 inline-block">
                  {invoiceByIdData?.data?.vat}%
                </span>
              </span>
              <span className="text-[#979797] text-xs">
                {formatAmount(invoiceByIdData?.data?.vatAmount)}
              </span>
            </div>

            <div className="flex justify-between items-center w-full gap-x-[100px] border-t-2 border-b-2 font-bold text-lg py-1">
              <span className="w-[97px]">Grand Total</span>
              <span className="text[#979797] py-1">
                {formatAmount(invoiceByIdData?.data?.grandTotal)}
              </span>
            </div>
          </div>
        </div>
      </div>

      <AnimatePresence>
        {confirmCancelModal && (
          <ViewDocumentModal
            onAction={() => handleDownloadSuccessful()}
            onClose={() => setConfirmCancelModal(false)}
            proceed="Yes"
          >
            <span className=" text-center">
              Would you like to withdraw and cancel this request
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>
    </motion.section>
  );
}

export default InvoiceDetails;
