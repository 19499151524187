import React, { useEffect, useState, useRef } from "react";
import {
  PAIconDownload,
  PAIconFilter,
  PAIconOutlineSearch,
  PAIconOption,
} from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";
import Table from "layout/table/Table";
import AcceptModal from "shared/AcceptModal";
import SuccessModal from "shared/SuccessModal";
import { useDispatch, useSelector } from "react-redux";

import Pagination from "shared/Pagination";
import Loading from "shared/Loading";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  allFunderClosedMarket,
  remainder,
} from "appstate/collections/collectionSlice";
import { formatCapitaliseString } from "utilities/helper/formatCapitaliseString";

const ClosedMarket = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [singleSelectedRows, setSingleSelectedRows] = useState([]);
  const [bulkSelectedRows, setBulkSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [activeTab, setActiveTab] = useState("single");

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const navigate = useNavigate();

  const { allFunderClosedMarketData, isLoading } = useSelector(
    (state) => state?.collection
  );

  // Get the data based on active tab
  const currentData =
    activeTab === "single"
      ? allFunderClosedMarketData?.data || []
      : allFunderClosedMarketData?.data || [];

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen({});
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const dispatch = useDispatch();

  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const pageSize = 10;

  const handleSearch = () => {
    dispatch(
      allFunderClosedMarket({
        search: searchQuery,
        bulk: activeTab === "bulk" ? true : false,
        page,
        pageSize,
      })
    );
  };

  useEffect(() => {
    dispatch(
      allFunderClosedMarket({
        page,
        pageSize,
        bulk: activeTab === "bulk" ? true : false,
        search: searchQuery,
      })
    );
  }, [dispatch, page, pageSize, searchQuery, activeTab]);

  const handleNotificationClick = (row) => {
    // Determine the selected rows state based on the active tab
    const isSingleTab = activeTab === "single";
    const selectedRowList = isSingleTab ? singleSelectedRows : bulkSelectedRows;

    const isSelected = selectedRowList.includes(row.id);
    let newSelectedRows = [];

    if (!isSelected) {
      newSelectedRows = [...selectedRowList, row.id];
    }

    // Update the appropriate state based on the active tab
    if (isSingleTab) {
      setSingleSelectedRows(newSelectedRows);
    } else {
      setBulkSelectedRows(newSelectedRows);
    }

    setIsAcceptModalOpen(true);
  };

  // const handleConfirm = () => {
  //   setIsAcceptModalOpen(false);

  //   const selectedInvoiceIds =
  //     activeTab === "single" ? singleSelectedRows : bulkSelectedRows;

  //   if (selectedInvoiceIds.length > 0) {
  //     setIsSuccessModalOpen(true);
  //     setSuccessMessage("Payment remainders sent successfully");
  //   }
  // };

  const handleConfirm = () => {
    setIsAcceptModalOpen(false);

    const selectedInvoiceIds =
      activeTab === "single" ? singleSelectedRows : bulkSelectedRows;

    if (selectedInvoiceIds.length > 0) {
      dispatch(
        remainder({
          type: "closedmarket",
          body: selectedInvoiceIds,
        })
      ).then((response) => {
        if (response?.payload?.status === 200 && response?.payload?.success) {
          setIsSuccessModalOpen(true);
          setSuccessMessage(
            response?.payload?.message || "Payment reminders sent successfully"
          );

          // Reset selected rows after successful reminder
          if (activeTab === "single") {
            setSingleSelectedRows([]);
          } else {
            setBulkSelectedRows([]);
          }
          setSelectAll(false);
        }
      });
    }
  };

  const handleCloseSuccessModal = () => {
    setIsSuccessModalOpen(false);
  };

  const singleDetails = (id) => {
    let path = `/collections/funcollectiondirectfundinginvoiceforclosedMarket/${id}`;
    navigate(path);
  };

  const bulkDetails = (id) => {
    let path = `/collections/funderbulk-directfunding-closedmarket/${id}`;
    navigate(path);
  };

  const handleSendReminder = () => {
    const rowsToProcess =
      activeTab === "single" ? singleSelectedRows : bulkSelectedRows;
    if (rowsToProcess.length > 0) {
      setIsAcceptModalOpen(true);
    }
  };

  const handleSelectAll = () => {
    if (activeTab === "single") {
      if (selectAll) {
        setSingleSelectedRows([]);
      } else {
        setSingleSelectedRows(currentData.map((row) => row.id));
      }
    } else if (activeTab === "bulk") {
      if (selectAll) {
        setBulkSelectedRows([]);
      } else {
        setBulkSelectedRows(currentData.map((row) => row.id));
      }
    }
    setSelectAll(!selectAll);
  };

  const columns = [
    {
      name: (
        <input
          type="checkbox"
          onChange={handleSelectAll}
          checked={
            activeTab === "single"
              ? singleSelectedRows?.length === currentData?.length &&
                currentData?.length > 0
              : bulkSelectedRows?.length === currentData?.length &&
                currentData?.length > 0
          }
          disabled={currentData?.length === 0}
        />
      ),
      cell: (row) => (
        <input
          type="checkbox"
          onChange={() => handleCheckboxChange(row)}
          checked={
            activeTab === "single"
              ? singleSelectedRows.includes(row.id)
              : bulkSelectedRows.includes(row.id)
          }
        />
      ),
      width: "50px",
    },
    {
      name: (
        <span className="whitespace-normal">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Request ref Number
        </span>
      ),
      selector: (row) => row.requestReference,
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-normal">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Buyer
        </span>
      ),
      selector: (row) => row.buyerCompanyName,
      sortable: true,
    },
    {
      name: "Early Payment date",
      selector: (row) => row.paymentDate,
      cell: (row) => {
        // Convert the array to a Date object
        const date = new Date(
          row?.paymentDate[0],
          row?.paymentDate[1] - 1,
          row?.paymentDate[2]
        );

        // Manually format the date as 'YYYY-MM-DD'
        const formattedDate = date?.toLocaleDateString("en-CA");

        return <p>{formattedDate}</p>;
      },
      sortable: true,
      width: "160px",
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
    },
    {
      name: "Request Amount",
      selector: (row) => row.requestAmount,
      sortable: true,
      cell: (row) => <p className="">{row.requestAmount?.toLocaleString()}</p>,
      width: "160px",
    },

    {
      name: "Lock Rate",
      selector: (row) => row.discountRate,
      sortable: true,
    },
    {
      name: "Lock Rate Amount",
      selector: (row) => row.discountAmount?.toLocaleString(),
      sortable: true,
      width: "160px",
    },
    {
      name: "Fundable Amount",
      selector: (row) => row.fundableAmount,
      sortable: true,
      cell: (row) => <p className="">{row.fundableAmount?.toLocaleString()}</p>,
      width: "160px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <p
          className={
            row.status === "Successful" ? "text-green-400 " : "text-[#F08000]"
          }
        >
          {formatCapitaliseString(row.status)}
        </p>
      ),
      width: "160px",
    },
    {
      cell: (row) => (
        <div className="flex gap-2">
          {
            <PAIconOption
              title="Options"
              onClick={() => {
                setIsDropdownOpen((prev) => ({
                  ...prev,
                  [row.id]: !prev[row.id],
                }));
                // Close other dropdowns
                Object.keys(isDropdownOpen).forEach((key) => {
                  if (key !== row.id.toString()) {
                    setIsDropdownOpen((prev) => ({ ...prev, [key]: false }));
                  }
                });
              }}
            />
          }
          {isDropdownOpen[row.id] && (
            <div
              ref={dropdownRef}
              className="absolute bg-white border border-gray300 rounded-md shadow-md z-10"
            >
              <ul>
                <li
                  className="px-4 py-2 cursor-pointer hover:bg-primaryColor hover:text-white"
                  onClick={() => {
                    if (activeTab === "single") {
                      singleDetails(row?.id);
                    } else if (activeTab === "bulk") {
                      bulkDetails(row?.id);
                    }
                    setIsDropdownOpen((prev) => ({ ...prev, [row.id]: false }));
                  }}
                >
                  Preview
                </li>
                <li
                  className="px-4 py-2 cursor-pointer hover:bg-primaryColor hover:text-white"
                  onClick={() => {
                    handleNotificationClick(row);
                    setIsDropdownOpen((prev) => ({ ...prev, [row.id]: false }));
                  }}
                >
                  Send Reminder
                </li>
                <li className="px-4 py-2 cursor-not-allowed text-gray300">
                  Export
                </li>
              </ul>
            </div>
          )}
        </div>
      ),
    },
  ];

  const columnBulks = [
    {
      name: (
        <input
          type="checkbox"
          onChange={handleSelectAll}
          checked={
            activeTab === "single"
              ? singleSelectedRows?.length === currentData?.length &&
                currentData?.length > 0
              : bulkSelectedRows?.length === currentData?.length &&
                currentData?.length > 0
          }
          disabled={currentData?.length === 0}
        />
      ),
      cell: (row) => (
        <input
          type="checkbox"
          onChange={() => handleCheckboxChange(row)}
          checked={
            activeTab === "single"
              ? singleSelectedRows.includes(row.id)
              : bulkSelectedRows.includes(row.id)
          }
        />
      ),
      width: "50px",
    },
    {
      name: (
        <span className="whitespace-normal">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Request ref Number
        </span>
      ),
      selector: (row) => row.requestReference,
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-normal">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Buyer
        </span>
      ),
      selector: (row) => row.buyerCompanyName,
      sortable: true,
    },
    {
      name: "EarlyPayment Date",
      selector: (row) => row.paymentDate,
      cell: (row) => {
        // Convert the array to a Date object
        const date = new Date(
          row?.paymentDate[0],
          row?.paymentDate[1] - 1,
          row?.paymentDate[2]
        );

        // Manually format the date as 'YYYY-MM-DD'
        const formattedDate = date?.toLocaleDateString("en-CA");

        return <p>{formattedDate}</p>;
      },
      sortable: true,
      width: "160px",
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
    },
    {
      name: "Total Request Amount",
      selector: (row) => row.requestAmount,
      sortable: true,
      cell: (row) => <p className="">{row.requestAmount?.toLocaleString()}</p>,
      width: "160px",
    },

    {
      name: "Lock Rate",
      selector: (row) => row.discountRate,
      sortable: true,
    },
    {
      name: "Total Lock Rate",
      selector: (row) => row.discountAmount,
      sortable: true,
      width: "160px",
      cell: (row) => <p className="">{row.discountAmount?.toLocaleString()}</p>,
    },

    {
      name: "Total Fundable Amount",
      selector: (row) => row.fundableAmount,
      sortable: true,
      cell: (row) => <p className="">{row.fundableAmount?.toLocaleString()}</p>,
      width: "160px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      width: "160px",
      cell: (row) => (
        <p
          className={
            row.status === "Successful" ? "text-green-400" : "text-[#F08000]"
          }
        >
          {formatCapitaliseString(row.status)}
        </p>
      ),
    },
    {
      cell: (row) => (
        <div className="flex gap-2">
          {
            <PAIconOption
              title="Options"
              onClick={() => {
                setIsDropdownOpen((prev) => ({
                  ...prev,
                  [row.id]: !prev[row.id],
                }));
                // Close other dropdowns
                Object.keys(isDropdownOpen).forEach((key) => {
                  if (key !== row.id.toString()) {
                    setIsDropdownOpen((prev) => ({ ...prev, [key]: false }));
                  }
                });
              }}
            />
          }
          {isDropdownOpen[row.id] && (
            <div
              ref={dropdownRef}
              className="absolute bg-white border border-gray300 rounded-md shadow-md z-10"
            >
              <ul>
                <li
                  className="px-4 py-2 cursor-pointer hover:bg-primaryColor hover:text-white"
                  onClick={() => {
                    if (activeTab === "single") {
                      singleDetails(row?.id);
                    } else if (activeTab === "bulk") {
                      bulkDetails(row?.id);
                    }
                    setIsDropdownOpen((prev) => ({ ...prev, [row.id]: false }));
                  }}
                >
                  Preview
                </li>
                <li
                  className="px-4 py-2 cursor-pointer hover:bg-primaryColor hover:text-white"
                  onClick={() => {
                    handleNotificationClick(row);
                    setIsDropdownOpen((prev) => ({ ...prev, [row.id]: false }));
                  }}
                >
                  Send Reminder
                </li>
                <li className="px-4 py-2 cursor-not-allowed text-gray300">
                  Export
                </li>
              </ul>
            </div>
          )}
        </div>
      ),
    },
  ];

  const handleCheckboxChange = (row) => {
    if (activeTab === "single") {
      const isSelected = singleSelectedRows.includes(row.id);
      setSingleSelectedRows(
        isSelected
          ? singleSelectedRows.filter((id) => id !== row.id)
          : [...singleSelectedRows, row.id]
      );
    } else if (activeTab === "bulk") {
      const isSelected = bulkSelectedRows.includes(row.id);
      setBulkSelectedRows(
        isSelected
          ? bulkSelectedRows.filter((id) => id !== row.id)
          : [...bulkSelectedRows, row.id]
      );
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    // Reset selected rows when changing tabs
    setSingleSelectedRows([]);
    setBulkSelectedRows([]);
    setSelectAll(false);
  };

  const renderTabContent = () => {
    const tableColumns = activeTab === "single" ? columns : columnBulks;

    // if (isLoading) {
    //   return <Loading />;
    // }

    if (!currentData || currentData?.length === 0) {
      return <TableEmptyState dashboard />;
    }

    return (
      <>
        <Table
          columns={tableColumns}
          data={currentData}
          onRowClicked={(row) =>
            activeTab === "single"
              ? singleDetails(row?.id)
              : bulkDetails(row?.id)
          }
          progressPending={isLoading}
          progressComponent={<Loading />}
          pointer
          tableHeader
          className="mt-5"
        />
        <div className="flex justify-center gap-x-[8px] mt-[20px]">
          {currentData?.length && (
            <Pagination
              page={page}
              startPage={startPage}
              setEndPage={setEndPage}
              endPage={endPage}
              setStartPage={setStartPage}
              setPage={setPage}
              totalPages={allFunderClosedMarketData?.meta?.totalElements}
            />
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <div className="my-2 grid md:grid-cols-2 items-center">
        <div className="gap-3 flex relative">
          <div className="flex gap-4 mb-4">
            <div className="relative">
              <DatePicker
                selected={startDate}
                onChange={(update) => setDateRange(update)}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                placeholderText="Filters"
                className="border  border-[#ccc] px-4 py-3 cursor-pointer rounded-md w-60"
              />

              <div className="absolute top-2/4 right-3 transform -translate-y-2/4 pointer-events-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5 text-gray-400"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div>
            <button className="py-2 px-4 text-sm rounded-[5px] bg-white border-[#DEDEDE] shadow-sm mr-2 flex items-center justify-center gap-1.5">
              Export {<PAIconDownload className="accent-gray300 w-4" />}
            </button>
          </div>
        </div>

        <div className="flex md:col-span-1 justify-end">
          <div className="flex items-center justify-between gap-5">
            <div className="border border-transparent md:border-gray md:shadow-sm my-4 rounded-[5px] px-2 lg:w-[300px] bg-white">
              <div className="items-center flex bg-white">
                <PAIconOutlineSearch />
                <input
                  className="bg-transparent p-2 w-full focus:outline-none text-sm bg-white"
                  type="text"
                  placeholder="Search for invoices awaiting collection"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleSearch();
                    }
                  }}
                />
              </div>
              <div className="lg:hidden flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer">
                <PAIconFilter className="bg-[#2FA06A]" />
              </div>
            </div>
            <button
              className="hidden lg:flex bg-[#2FA06A] rounded-md my-4 py-2 px-6 mx3 items-center gap-2 text-white"
              onClick={handleSearch}
            >
              Search
            </button>
          </div>
        </div>
      </div>

      <Card className="rounded-[10px] mt-[15px]">
        <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px] py-10 ">
          <div>
            <p className="text-black text-lg font-bold">
              Invoices from Closed market awaiting collection
            </p>
            <span className="text-xs font-thin">
              List of all invoices you have funded from the Closed market
            </span>
          </div>
          <div className="flex items-center justify-center gap-3">
            <button
              className={`border border-gray300 rounded-md p-2 text-white ${
                (activeTab === "single" && singleSelectedRows.length === 0) ||
                (activeTab === "bulk" && bulkSelectedRows.length === 0)
                  ? "cursor-not-allowed bg-gray300"
                  : "cursor-pointer bg-secondaryColor"
              }`}
              disabled={
                (activeTab === "single" && singleSelectedRows.length === 0) ||
                (activeTab === "bulk" && bulkSelectedRows.length === 0)
              }
              onClick={handleSendReminder}
            >
              <p>Send Reminder Now</p>
            </button>
          </div>
        </div>
        <div className="pb-[24px]">
          <div className="flex items-center px-6">
            <div className="flex gap-3 my-3">
              <p
                className={`cursor-pointer font-bold relative ${
                  activeTab === "single" ? "text-[#F08000]" : "text-[#959595]"
                }`}
                onClick={() => handleTabClick("single")}
              >
                Single requests
                {activeTab === "single" && (
                  <span
                    className="absolute bottom-0 left-1/2 transform -translate-x-1/2
                   bg-[#F08000] h-0.5 w-5"
                  ></span>
                )}
              </p>

              <p
                className={`cursor-pointer font-bold relative ${
                  activeTab === "bulk" ? "text-[#F08000]" : "text-[#959595]"
                }`}
                onClick={() => handleTabClick("bulk")}
              >
                Bulk requests
                {activeTab === "bulk" && (
                  <span
                    className="absolute bottom-0 left-1/2 transform -translate-x-1/2
                   bg-[#F08000] h-0.5 w-5"
                  ></span>
                )}
              </p>
            </div>
          </div>
          <div className="mt-3">{renderTabContent()}</div>
        </div>
      </Card>

      <AcceptModal
        isOpen={isAcceptModalOpen}
        onClose={() => setIsAcceptModalOpen(false)}
        yes
        message={
          <>
            Would you like to proceed with sending a <br />
            <span className="text-[#07593D] font-bold">
              Payment reminder
            </span>{" "}
            now?
          </>
        }
        onConfirm={handleConfirm}
      />

      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={handleCloseSuccessModal}
        message={successMessage}
      />
    </>
  );
};

export default ClosedMarket;
