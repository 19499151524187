import DashboardLayout from "layout/dashboardlayout";
import TopTenInvoiceDetails from "./TopTenInvoiceDetails";
import { PAIconArrowLeftGreen } from "assets/images/svgs";
import { useNavigate } from "react-router-dom";

function TopContestPage() {
  const navigate = useNavigate();
  const handleBack = () => navigate(-1);
  return (
    <DashboardLayout
      section="Open Market"
      subHeader="Manage your invoices imported to the Open market"
    >
      <div className="flex flex-col gap-4">
        <header
          className="bg-white text-primaryColor px-6  rounded-[5px]"
          onClick={() => handleBack()}
        >
          <span className="flex cursor-pointer py-4 w-fit font-light items-center text-sm gap-2">
            <PAIconArrowLeftGreen />
            Go Back
          </span>
        </header>
        <TopTenInvoiceDetails />
      </div>
    </DashboardLayout>
  );
}

export default TopContestPage;
