import * as Yup from "yup";

// Custom validation method
const numberLength = (min, max) => {
  return Yup.string()
    .matches(/^(\+)?\d+$/, "Mobile number should contain digits only.")
    .test("len", `Please enter a valid mobile number.`, (val) => {
      const digitLength = val ? val.replace(/^\+/, "").length : 0;
      return digitLength >= min && digitLength <= max;
    });
};

// Custom validation method for rcNumber

const validateRCNumber = (rcNumber) => {
  const rcNumberPattern = /^(?!0+$)(?!([0-9])\1+$)(?=.*\d)[A-Z0-9]{8,12}$/;
  // Adjust length as needed for specific countries
  return rcNumberPattern.test(rcNumber);
};
const validatePhone = (phone) => {
  const phonePattern = /^(7|8|9|6|5|4|3|2)(?!\1{9,14})\d{8,9}$/;
  return phonePattern.test(phone);
};

const validateEmail = (email) => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const companyMail = /^[a-zA-Z0-9._%+-]+@example\.com$/;
  return emailPattern.test(email) || emailPattern.test(companyMail);
};
const validComName = (comName) => {
  const namePattern = /^(?!\d+$)(?![\d\W]+$)(?=.*[A-Za-z]).+$/;
  return namePattern.test(comName.trim());
};
const validComAdress = (comName) => {
  const namePattern = /^(?!\d+$)(?![\d\W]+$)(?=.*[A-Za-z]).+$/;
  return namePattern.test(comName.trim());
};

// Custom validation method for companyTaxId
const validateTIN = (tin) => {
  const tinPattern = /^(?!-)(?=.*\d)(?!([0-9])\1{7,11})[A-Za-z0-9-]{8,12}$/; // Adjust length based on specific countries
  if (!tinPattern.test(tin)) {
    return false;
  }

  return true;
};

export const buyerCompanyDetailsValidationSchema = Yup.object({
  companyName: Yup.string()
    .required("This field is required")
    .test(
      "Valid company name format",
      "Company name must not be only digits,it must be identifiable expression",
      validComName
    ),
  rcNumber: Yup.string()
    .required("This field is required")
    .test("valid-rcNumber", "Invalid RC number format", validateRCNumber),
  companyWebsite: Yup.string().matches(
    /^www\.[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    "Please enter a valid URL,Ensure that the URL starts with www."
  ),
  companyPhoneNumber: numberLength(6, 14)
    .required("Mobile number is required.")
    .test("Valid phone number", "Invalid Phone number", validatePhone),
  companyTaxId: Yup.string()
    .required("This field is required")
    .test("valid-tin", "Invalid TIN format", validateTIN),
  registeredAddress: Yup.string()
    .required("This field is required")
    .test(
      "Valid company Address format",
      "Company address must not contain only digits or contain only special character",
      validComAdress
    ),
  companyEmail: Yup.string()
    .email()

    .test(
      "Valid email address",
      "Invalid email address, please enter the right email format",
      validateEmail
    ),
});
