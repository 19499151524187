import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedData: [],
};

const selectedDataSlice = createSlice({
  name: "selectedData",
  initialState,
  reducers: {
    updateSelectedData: (state, action) => {
      state.selectedData = action.payload;
    },
    clearSelectedData: (state) => {
      state.selectedData = [];
    },
  },
});

export const { updateSelectedData, clearSelectedData } =
  selectedDataSlice.actions;
export default selectedDataSlice.reducer;
