import React, { useEffect } from "react";
import Table from "layout/table/Table";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";

import { useDispatch } from "react-redux";
import { allTransactions } from "appstate/collections/collectionSlice";
import Loading from "shared/Loading";
import { setActiveTab } from "appstate/collections/collectionSlice";
import { useNavigate } from "react-router-dom";

const RecentPaymentTable = ({ activeTab, data }) => {
  const dispatch = useDispatch();
  const [status, setStatus] = React.useState("single");

  const allTransaction = () => {
    dispatch(setActiveTab(activeTab));
  };

  const formatAmount = (amount) => {
    if (typeof amount !== "number") return "0.00";
    return amount?.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  useEffect(() => {
    dispatch(allTransactions());
  }, [dispatch]);
  const navigate = useNavigate();

  const columns = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className="pr-1 inline" src="/group-icon.svg" alt="" />
          Transaction Party
        </span>
      ),
      selector: (row) => row.transactionParty,
      sortable: true,
      cell: (row) => {
        const transactionPartyObj = row.transactionParty; // Extract the object
        const [,roleOne] = Object.entries(transactionPartyObj)[0];
        const [,role] = Object.entries(transactionPartyObj)[1];

        return (
          <div>
            <p className="font-semibold">{role}</p>
            <span
              className={`${
                role === "SUPPLIER" ? "text-[#2EA923]" : "text-[#F08000]"
              }`}
            >
              {`(${roleOne})`}
            </span>
          </div>
        );
      },
    },
    {
      name: "Payment Date",
      selector: (row) => row.paymentDate?.slice(0, 10),
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Invoice Number
        </span>
      ),
      selector: (row) => row.invoiceNo,
      sortable: true,
    },
    {
      name: "Funding Channels",
      selector: (row) => row.fundingChannel,
      sortable: true,
      width: "10.2rem",
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
      width: "6.2rem",
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      cell: (row) => (
        <p className="text-[#DE1515]">{formatAmount(row.amount)}</p>
      ),
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) =>
        row.status === "Successful" ? (
          <p className="text-[#07593D]">{row.status}</p>
        ) : row.status === "REJECTED" ? (
          <p className="text-red">{row.status}</p>
        ) : row.status === "UPDATED" ? (
          <p className="text-blue-300">{row.status}</p>
        ) : row.status === "PARTIALLY Successful" ? (
          <p className="text-yellow-400">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        ),
    },
  ];

  // funder/disbursement/detailsforEarlyPaymentRequest/:id
  // funder/disbursement/detailsforEarlyPaymentRequestMain/:id
  //funder/disbursement/detailsforEarlyPaymentOffer/:id

  const handleRowClick = (row) => {
    if (row.channel === "Loan Default") {
      navigate(
        `/disbursement/funder/disbursement/detailsforEarlyPaymentRequest/${row.idx}`
      );
    } else if (row.channel === "Early Payment Request") {
      // navigate(
      //   `/work-flow-management/detail/PendingRequestInvoiceApprovalScreen/${row.workflowId}`
      // );
      navigate(
        `/disbursement/funder/disbursement/detailsforEarlyPaymentRequestMain/${row.idx}`
      );
      // navigate(
      //   `/work-flow-management/detail/approvalviawalletscreendetails/${row.workflowId}`
      // );
    } else if (row.channel === "Early Payment Offers ") {
      navigate(
        `/disbursement/funder/disbursement/detailsforEarlyPaymentOffer/${row.idx}`
      );
    }
  };

  return (
    <>
      {!data?.recentDisbursementHistory?.singleTransactionHistory?.data
        ?.length ? (
        <div className="ml3">
          <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
            <p className="text-black text-sm font-medium">
              Overview of CashInflow Records
            </p>
          </div>
          <TableEmptyState dashboard />
        </div>
      ) : (
        <Card className="rounded-[10px] mt-[24px]">
          <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
            <div>
              <p className="text-black text-lg font-semibold">
                Recent disbursement history
              </p>
              <span className="text-xs font-thin text-[#959595]">
                Here is a recent list of your disbursements.
              </span>
            </div>

            <div
              className="border border-gray300 rounded-md p-2 cursor-pointer"
              onClick={() => allTransaction(4)}
            >
              <p className="text-[#959595] text-xs lg:text-base">
                View All Transaction History
              </p>
            </div>
          </div>
          <div className="px-[24px] pb[24px]">
            <div className="flex items-center mt-4">
              <button
                onClick={() => {
                  setStatus("single");
                }}
                style={
                  status === "single"
                    ? { borderBottom: "1px solid #F08000" }
                    : { borderBottom: "none" }
                }
                className="mr-4"
              >
                Single Requests
              </button>
              <button
                onClick={() => {
                  setStatus("bulk");
                }}
                style={
                  status === "bulk"
                    ? { borderBottom: "1px solid #F08000" }
                    : { borderBottom: "none" }
                }
              >
                Bulk Requests
              </button>
            </div>
            <Table
              columns={columns}
              data={
                status === "single"
                  ? data?.recentDisbursementHistory?.singleTransactionHistory
                      ?.data
                  : data?.recentDisbursementHistory?.bulkTransactionHistory
                      ?.data
              }
              onRowClicked={(row) => handleRowClick(row)}
              progressComponent={<Loading />}
              pointer
              tableHeader
              className="mt-5"
            />
          </div>
        </Card>
      )}
    </>
  );
};

export default RecentPaymentTable;
