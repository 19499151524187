import { PAIconArrowLeftGreen } from "assets/images/svgs";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { closedMarketViewBulkDetails } from "appstate/buyer/buyerSlice";
import DashboardLayout from "layout/dashboardlayout";
import { formatCapitalize } from "utilities/formatCapitalize";
import { formatCurrency } from "utilities/helper/formatters";

const MyInvoicesDetailsBulk = () => {
  const { id } = useParams();

  const { closedMarketViewSingleDetailsData, closedMarketViewBulkDetailsData } =
    useSelector((state) => state?.buyer);

  const totalRequestAmount =
    closedMarketViewBulkDetailsData?.data?.invoiceDetails?.reduce(
      (total, invoice) => {
        return total + invoice?.requestAmount;
      },
      0
    ) || 0;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(closedMarketViewBulkDetails(id));
  }, [dispatch, id]);

  const handleBackBtnClick = () => {
    let path = "/closedmarket";
    navigate(path);
  };

  return (
    <DashboardLayout subHeader="Here is what is happening in your finance">
      <div
        onClick={handleBackBtnClick}
        className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer w-full"
      >
        <PAIconArrowLeftGreen />
        <span className="flex items-center text-primaryColor">Go Back</span>
      </div>

      <div className="grid lg:grid-cols-[60%,40%] gap-5 h-[80vh]">
        {/* 60% */}
        <div className="flex flex-col gap-8 pb-24 bg-white rounded-[10px] pt-7">
          <div className="px-6 ">
            <div className="flex flex-col gap-3">
              <div className="flex items-center justify-between">
                <h1 className="text-[18px] font-bold">
                  Reference No: |{" "}
                  {closedMarketViewBulkDetailsData?.data?.requestRef}
                </h1>

                <div>
                  <button
                    style={{ border: "1px solid rgba(7, 89, 61, 0.20)" }}
                    className="p-2.5 rounded-[5px] text-sm text-primaryColor"
                  >
                    {formatCapitalize(
                      closedMarketViewBulkDetailsData?.data?.status
                    )}
                  </button>
                </div>
              </div>

              <main className=" w-full flex justify-between">
                <div className="flex flex-col gap-3 w-1/2">
                  <div className="flex flex-col gap-1">
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs font-semibold w-[40%]">
                        Request Date:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {
                          closedMarketViewBulkDetailsData?.data
                            ?.earlyPaymentDate
                        }
                      </span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className="whitespace-nowrap text-xs font-semibold w-[40%]">
                        Total Request amount
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {formatCurrency(totalRequestAmount)}
                      </span>
                    </div>

                    <div className="flex gap-x-[50px]">
                      <span className="whitespace-nowrap text-xs font-semibold w-[40%]">
                        Total Invoice amount
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {formatCurrency(
                          closedMarketViewBulkDetailsData?.data
                            ?.totalInvoiceAmount
                        ) || "N/A"}
                      </span>
                    </div>
                  </div>
                </div>
              </main>
            </div>

            <div className="flex justify-between my-5">
              <div className="flex flex-col">
                <span className=" font-bold text-sm">
                  {closedMarketViewSingleDetailsData?.data?.buyerCompanyName ||
                    "N/A"}
                </span>
                <span className="text-[#979797] text-xs">Buyer Details</span>
              </div>
              <div className="flex flex-col">
                <span className="font-bold text-sm">
                  {closedMarketViewSingleDetailsData?.data?.paymentTerms} Days
                </span>
                <span className="text-[#979797] text-xs">Payment</span>
              </div>
              <div className="flex flex-col">
                <span className="font-bold text-sm">
                  {closedMarketViewSingleDetailsData?.data?.currency || "NGN"}
                </span>
                <span className=" text-[#979797] text-xs">Currency</span>
              </div>
            </div>

            <div className="flex flex-col gap-4 mt-5">
              <header className="border-b-[5px] border-b-[#F6F5FA] pb-3 flex items-center justify-between">
                <h1 className="text-sm font-bold">Line Items</h1>
                <h1 className="text-sm font-regular">
                  {
                    closedMarketViewBulkDetailsData?.data?.invoiceDetails
                      ?.length
                  }{" "}
                  Total Item(s)
                </h1>
              </header>
              <div className="w-full overflow-x-auto h-[30vh]">
                <table className="overflow-x-auto w-full">
                  <thead className="bg-subtleGray h-[70px] overflow-x-auto">
                    <tr className=" bg-unusualGray p-2.5">
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Ref Number
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Due Date
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Request Amount
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Discount Rate
                      </td>
                      <td className="text-[#11192A] p-2.5 text-xs font-bold w-[120px]">
                        Discount Amount
                      </td>
                    </tr>
                  </thead>
                  {closedMarketViewBulkDetailsData?.data?.invoiceDetails?.map(
                    (item, index) => (
                      <tbody key={index}>
                        <tr className=" py-[3px] px-1.5">
                          <td className=" text-xs text-[#11192A] p-2.5">
                            {item?.invoiceRef}
                          </td>
                          <td className=" text-xs text-[#11192A] p-2.5 w-[20%]">
                            {item?.requestDate}
                          </td>
                          <td className=" text-xs text-[#11192A] p-2.5">
                            {item?.requestAmount?.toLocaleString()}
                          </td>
                          <td className=" text-xs text-[#11192A] p-2.5">
                            {item?.discountRate || "-"}%
                          </td>
                          <td className=" text-xs text-[#11192A] p-2.5">
                            {item?.discountedAmount}
                          </td>
                        </tr>
                      </tbody>
                    )
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* 40% */}
        <div className="bg-white rounded-[10px] gap-8 px-6 pt-7 pb-24">
          <div>
            <h2 className="font-semibold text-lg text-primaryColor leading-[30px] mb-4">
              Bid details for this invoice
            </h2>

            <div className="flex justify-between my-5">
              <div className="flex gap-2">
                <div className="flex flex-col">
                  <span className="text-sm">
                    {formatCapitalize(
                      closedMarketViewBulkDetailsData?.data
                        ?.funderInvitedResponse[0]?.funderName
                    )}
                  </span>

                  <span className="text-[#979797]  text-xs">Funder</span>
                </div>
              </div>
              <div className="flex flex-col">
                <span className="text-sm">
                  {formatCapitalize(
                    closedMarketViewBulkDetailsData?.data?.supplierCompanyName
                  ) || "N/A"}{" "}
                </span>
                <span className="text-[#979797]  text-xs">Supplier</span>
              </div>
            </div>

            <div className="flex justify-between my-5">
              <div className="flex gap-2">
                <div className="flex flex-col">
                  <span className="text-sm">
                    {closedMarketViewBulkDetailsData?.data?.discountAmount.toLocaleString()}
                  </span>
                  <span className="text-[#979797]  text-xs">
                    Lock Rate Amount
                  </span>
                </div>
              </div>
              <div className="flex flex-col">
                <span className="text-sm flex justify-end">
                  {closedMarketViewBulkDetailsData?.data?.buyerDiscountRate ||
                    "-"}
                  %
                </span>
                <span className="text-[#979797] flex justify-end  text-xs">
                  Lock rate
                </span>
              </div>
            </div>

            <div className="flex justify-between pb-5">
              <div className="flex flex-col">
                <span className="text-sm">
                  {closedMarketViewBulkDetailsData?.data?.requestDate || "N/A"}
                </span>
                <span className="text-[#979797]  text-xs">Request Date</span>
              </div>

              <div className="flex flex-col">
                <span className="text-sm flex justify-end">
                  {closedMarketViewBulkDetailsData?.data?.earlyPaymentDate}
                </span>
                <span className="text-[#979797] flex justify-end  text-xs">
                  Early Payment Date
                </span>
              </div>
            </div>

            <div className="flex justify-between mt-5 border-b-[5px] border-b-[#F6F5FA] pb-10">
              <div className="flex gap-2">
                <div className="flex flex-col">
                  <span className="text-sm">
                    N
                    {closedMarketViewBulkDetailsData?.data?.requestAmount?.toLocaleString() ||
                      "0.00"}
                  </span>
                  <span className="text-[#979797]  text-xs">
                    Request Amount
                  </span>
                </div>
              </div>
              <div className="flex flex-col">
                <span className="text-sm flex justify-end">
                  {closedMarketViewBulkDetailsData?.data?.payableAmount?.toLocaleString()}
                </span>
                <span className="text-[#979797] flex justify-end  text-xs">
                  Payable amount
                </span>
              </div>
            </div>

            <div className="pb-5">
              <h2 className="font-medium text-base my-3">
                Funder Personal Information:
              </h2>

              <div className="flex justify-between mt-5 pb-10">
                <div className="flex gap-2">
                  <div className="flex flex-col">
                    <span className="text-[#979797]  text-xs">Funder Name</span>
                    <span className="text-sm">
                      {
                        closedMarketViewBulkDetailsData?.data
                          ?.funderInvitedResponse[0]?.funderName
                      }
                    </span>
                  </div>
                </div>
                <div className="flex flex-col">
                  <span className="text-[#979797]  text-xs">
                    Funder Locked Rate
                  </span>
                  <span className="text-sm">
                    {closedMarketViewBulkDetailsData?.data?.buyerDiscountRate ||
                      "-"}
                    %
                  </span>
                </div>
              </div>

              <div className="flex justify-between mt-5 pb-10">
                <div className="flex gap-2">
                  <div className="flex flex-col">
                    <span className="text-[#979797]  text-xs">Email</span>
                    <span className="text-sm">
                      {
                        closedMarketViewBulkDetailsData?.data
                          ?.funderInvitedResponse[0]?.funderEmail
                      }
                    </span>
                  </div>
                </div>
                <div className="flex flex-col">
                  <span className="text-[#979797]  text-xs">
                    Contact Number
                  </span>
                  <span className="text-sm">
                    {
                      closedMarketViewBulkDetailsData?.data
                        ?.funderInvitedResponse[0]?.contactNumber
                    }
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default MyInvoicesDetailsBulk;
