import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import RecentPaymentTable from "./Table";
import { PAIconSolarLeftGreenArrow } from "assets/images/svgs";
import { useDispatch, useSelector } from "react-redux";
import { fetchDisbursementOverview } from "appstate/buyer/buyerSlice";

const Overview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(fetchDisbursementOverview());
  }, [dispatch]);

  const { fetchDisbursementOverviewData } = useSelector((state) => state.buyer);

  useEffect(() => {
    setData(fetchDisbursementOverviewData?.data);
  }, [fetchDisbursementOverviewData]);

  

  const handleNavigation = () => {
    navigate("/reimbursement");
  };
  
  return (
    <div className="w-full">
      <div className="w-full rounded-b-lg  shadow-lg">
        <header className="mt-3 flex justify-between border-b-2 border-[#AEAEAE] flex-shrink-0 bg-white px-7 py-4 rounded-t-md">
          <h2 className="text-[#3E3E3E] font-bold text-[16px]">
            Summary of invoices for Disbursement from diverse channels.
          </h2>
          <div>
            <select
              style={{
                backgroundColor: "white",
                fontSize: 13,
                borderRadius: 8,
                color: "#07593D",
                border: "1px solid #07593D",
              }}
              className="text-xs outline-none py-2 px-4 cursor-pointer"
            >
              <option value="all">All</option>
              <option value="1week">This Week</option>
              <option value="1month">This Month</option>
              <option value="quarterly">This Quarter</option>
              <option value="halfyear">This Half Year</option>
              <option value="1year">This Year</option>
            </select>
          </div>
        </header>
        <div className="bg-[#EBEBEB] pb-5 rounded-b-lg mb-4">
          <div className="px-7 py-4 grid xl:grid-cols-[35%,64%] gap-5">
            {/* First Grid Section */}
            <section className="flex flex-col justify-between">
              <div className="flex justify-between  gap-3">
                <div className=" bg-primaryColor p-10 flex-row items-center justify-center text-white rounded-[10px] min-h-[50%] w-[50%]">
                  <p className="flex items-center justify-center font-bold text-4xl">
                    {data?.totalInvoices}
                  </p>
                  <p className="flex items-center justify-center font-normal text-[16px] text-center">
                    Total Invoices
                  </p>
                </div>

                <div className="p-5 bg-[#edfff8] flex flex-col items-center justify-center  rounded-b-r-[10px] rounded-tr-[10px] text-[#07593D] shadow-sm gap-3 w-[48%]  border-l-2 ">
                  <span className="font-bold md:text-base xl:text-[20px]">
                    <span> </span> ₦
                    {data?.totalOutstanding?.toLocaleString() || "0.00"}
                  </span>
                  <span className=" text-[13px] font-normal">
                    Total Outstanding
                  </span>
                </div>
              </div>

              <div className="flex justify-between ">
                <div className="py-[42px] px-5 bg-[#3A455B] flex flex-col items-center justify-center  rounded-b-[10px] rounded-tr-[10px] relative text-white shadow-sm gap-3 w-[48%] ">
                  <span className="font-bold md:text-base xl:text-[20px]">
                    <span>₦</span>
                    {data?.totalAmountLiened?.toLocaleString() || "0.00"}
                  </span>
                  <span className=" text-[13px] whitespace-nowrap font-normal">
                    Total Amount lienened
                  </span>
                </div>

                <div className="py-[42px] px-5 bg-secondaryColor flex flex-col items-center justify-center  rounded-bl-[10px] rounded-t-[10px] relative text-white shadow-sm gap-3 w-[48%] ">
                  <span className="font-bold md:text-base xl:text-[20px]">
                    <span>₦ </span>
                    {data?.totalAmountDisbursed?.toLocaleString() || "0.00"}
                  </span>
                  <span className=" text-[13px] whitespace-nowrap font-normal">
                    Total Amount Disbursed
                  </span>
                </div>
              </div>
            </section>

            <section className="grid lg:grid-cols-2 gap-5">
              {/* First GridBox */}
              <div className="flex flex-col justify-between  gap-3">
                {/* first layer */}
                <div
                  className={`p-6 itemscenter bg-white rounded-b-md border-t-2 min-h-[46%]`}
                  style={{ borderColor: "#07593D" }}
                >
                  <div className="flex item-center justify-between ">
                    <p className={`text-[${"#2FA06A"}] font-bold text-base`}>
                      Early Payment Requests
                    </p>
                  </div>

                  <div className="flex justify-between mt-4">
                    {/* second step */}
                    <div className=" justify-end w-[35%]  items-end  flex-row">
                      <h2
                        className={`text-[${"#07593D"}] flex items-center justify-center font-bold text-2xl`}
                      >
                        {data?.earlyPaymentRequestMetric?.totalRequests || "0"}
                      </h2>
                      <h2 className="flex items-center justify-center font-normal text-[#565656] text-xs mt-3">
                        {"Total Requests"}
                      </h2>
                    </div>

                    <div className="flex-row  border-l-2 border-[#2FA069] w-[65%]">
                      <h2
                        className={`text-[${"#F08000"}] flex items-center justify-center font-bold text-2xl`}
                      >
                        ₦
                        {data?.earlyPaymentRequestMetric?.totalAmount?.toLocaleString() ||
                          "0.00"}
                      </h2>
                      <h2 className="flex items-center justify-center font-normal text-[#565656] text-xs mt-3">
                        {"Total Liened"}
                      </h2>
                    </div>
                    {/* ))} */}
                  </div>
                </div>

                {/* second layer */}

                <div
                  className={`p-6 itemscenter bg-white rounded-b-md border-t-2 min-h-[46%]`}
                  style={{ borderColor: "#07593D" }}
                >
                  <div className="flex item-center justify-between ">
                    <p className={`text-[${"#F08000"}] font-bold text-base`}>
                      {"Early Payment Offers"}
                      <br />
                      <span className="text-[#07593d] text-xs font-light  leading-none">
                        (Dynamic Discounting)
                      </span>
                    </p>
                  </div>
                  <div className="flex justify-between mt-4">
                    {/* {section.data.map((item, idx) => ( */}
                    {/* second step */}
                    <div className=" justify-end w-[35%]  items-end  flex-row">
                      <h2
                        className={`text-[${"#07593D"}] flex items-center justify-center font-bold text-2xl`}
                      >
                        {data?.earlyPaymentOfferMetric?.totalOffers}
                      </h2>
                      <h2 className="flex items-center justify-center font-normal text-[#565656] text-xs mt-3">
                        {"Total Offers"}
                      </h2>
                    </div>

                    {/* another step */}

                    <div className="flex-row  border-l-2 border-[#2FA069] w-[65%]">
                      <h2
                        className={`text-[${"#F08000"}] flex items-center justify-center font-bold text-2xl`}
                      >
                        ₦{" "}
                        {data?.earlyPaymentOfferMetric?.totalAmount?.toLocaleString() ||
                          "0.00"}
                      </h2>
                      <h2 className="flex items-center justify-center font-normal text-[#565656] text-xs mt-3">
                        {"Total Amount"}
                      </h2>
                    </div>
                    {/* ))} */}
                  </div>
                </div>
                {/* second layer stops here */}
              </div>
              {/* SECOND GRID */}
              <div className="flex flex-col justify-between gap-3">
                {/* first */}
                <div
                  className={`p-4  bg-white rounded-b-md border-t-2`}
                  style={{ borderColor: "#07593D" }}
                >
                  <div class=" text-[#565656] text-base font-medium ">
                    Total Outstanding Payables
                  </div>
                  {/*  */}
                  <div className="flex justify-between mt-4">
                    <div className=" justify-end w-[35%] items-end flex-row">
                      <h2
                        className={`text-[${"#07593D"}] flex items-center justify-center font-bold text-2xl`}
                      >
                        {data?.outstandingPayablesMetric?.totalInvoices}
                      </h2>
                      <h2 className="flex items-center justify-center font-normal text-[#565656] text-xs mt-3">
                        {"Total Invoices"}
                      </h2>
                    </div>

                    {/* another step */}

                    <div className="flex-row  border-l-2 border-[#2FA069] w-[65%]">
                      <h2
                        className={`text-[${"#F08000"}] flex items-center justify-center font-bold text-2xl`}
                      >
                        ₦
                        {data?.outstandingPayablesMetric?.totalAmount?.toLocaleString() ||
                          "0.00"}
                      </h2>
                      <h2 className="flex items-center justify-center font-normal text-[#565656] text-xs mt-3">
                        {"Total Amount"}
                      </h2>
                    </div>
                    {/* ))} */}
                  </div>
                </div>

                <div
                  className={`p-4  bg-white rounded-b-md border-t-2`}
                  style={{ borderColor: "#07593D" }}
                >
                  <div class=" text-[#565656] text-base font-medium ">
                    Loan Defaults
                  </div>
                  <div className="flex justify-between mt-4">
                    <div className=" justify-end w-[35%]  items-end  flex-row">
                      <h2
                        className={`text-[${"#07593D"}] flex items-center justify-center font-bold text-2xl`}
                      >
                        {data?.loanDefaultsMetric?.totalInvoices}
                      </h2>
                      <h2 className="flex items-center justify-center font-normal text-[#565656] text-xs mt-3">
                        {"Total Invoices"}
                      </h2>
                    </div>

                    {/* another step */}

                    <div className="flex-row  border-l-2 border-[#2FA069] w-[65%]">
                      <h2
                        className={`text-[${"#F08000"}] flex items-center justify-center font-bold text-2xl`}
                      >
                        ₦
                        {data?.loanDefaultsMetric?.totalAmount?.toLocaleString() ||
                          "0.00"}
                      </h2>
                      <h2 className="flex items-center justify-center font-normal text-[#565656] text-xs mt-3">
                        {"Total Amount"}
                      </h2>
                    </div>
                    {/* ))} */}
                  </div>
                </div>

                {/* third */}

                <div class=" px-[17.67px] py-[11.04px] bg-white rounded-md shadow border border-[#07593D] justify-center items-center gap-[5.52px] inline-flex  flex-row">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={handleNavigation}
                    class=" text-[#07593D] text-base font-normal "
                  >
                    Go to Reimbursement
                  </div>
                  <PAIconSolarLeftGreenArrow />
                  <div class=" relative origin-top-left rotate-90">
                    <div class="w-[10.49px] h-[14.64px] left-[5.24px] top-[3.50px] absolute"></div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div>
        <RecentPaymentTable data={data} activeTab={4} />
      </div>
    </div>
  );
};

export default Overview;
