// import { motion } from "framer-motion";
import { useState } from "react";
import { Link } from "react-router-dom";
import FunderOpenMarketList from "./funderOpenMarketList";
import FunderClosedMarketList from "./funderClosedMarketList";
const FunderInvoice = ({ summary }) => {
  const [activeTab, setActiveTab] = useState("open-market");
  return (
    <>
      <div className="flex flex-col md:flex-row mt-5">
        <div className="flex-1 p-5 px-7 pb3 md:pb3 mb-3 rounded-lg bg-white shadow-md flex items-center justify-between">
          <div className=" flex gap-4">
            {" "}
            <div
              className="flex flex-col items-center "
              onClick={() => setActiveTab("open-market")}
            >
              <p
                className={` ${
                  activeTab === "open-market"
                    ? "text-primaryColor"
                    : "text-[#959595]"
                } text-base font-bold mb1`}
              >
                Open Market
              </p>

              {activeTab === "open-market" && (
                <div className="w-20 h-[2px] rounded-md bg-[#07593D] shadow-md"></div>
              )}
            </div>
            <div
              className="flex flex-col items-center"
              onClick={() => setActiveTab("closed-market")}
            >
              <p
                className={` ${
                  activeTab === "closed-market"
                    ? "text-primaryColor"
                    : "text-[#959595]"
                } text-base font-bold mb1`}
              >
                Closed Market
              </p>

              {activeTab === "closed-market" && (
                <div className=" w-20 h-[2px] rounded-md bg-[#07593D] shadow-md"></div>
              )}
            </div>
          </div>
          <Link
            to="/open-market"
            className="text-gray-500 text-sm font-normal underline"
          >
            Go to market
          </Link>
        </div>
      </div>
      <section>
        {activeTab === "open-market" ? (
          <>
            <FunderOpenMarketList summary={summary} />
          </>
        ) : (
          <>
            {" "}
            <FunderClosedMarketList summary={summary} />
          </>
        )}
      </section>
    </>
  );
};

export default FunderInvoice;
