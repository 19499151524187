import { PAIconArrowLeftGreen } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { buyerViewPfpInvoiceDetails } from "appstate/buyer/buyerSlice";

const PayableFinanceInvoiceDetails = () => {
  const location = useLocation();
  const { option } = location.state || {};
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const { pfpId, viewPfpOffersInvoiceData } = useSelector(
    (state) => state?.buyer
  );

  useEffect(() => {
    const offerId = option.offerid;
    dispatch(
      buyerViewPfpInvoiceDetails({
        id: pfpId,
        offerId: offerId,
      })
    );
  }, [dispatch, pfpId, option.offerid]);

  useEffect(() => {
    setData(viewPfpOffersInvoiceData?.data);
  }, [viewPfpOffersInvoiceData?.data]);

  return (
    <DashboardLayout
      section="PFP Campaign"
      subHeader="Manage all your payable finance campaign"
    >
      <div
        onClick={() => {
          window.history.back();
        }}
        className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer w-full"
      >
        <PAIconArrowLeftGreen />
        <span className="flex items-center text-primaryColor">Go Back</span>
      </div>
      <div className="grid lg:grid-cols-[100%] justify-center mb-5 gap-5 h-[80vh]">
        {/* 60% */}
        <div className="flex flex-col gap-8 bg-white rounded-[10px] mb-4">
          <div className="">
            <div className="flex flex-col gap-3">
              <div className="flex rounded-t-[10px] px-6 items-center justify-between bg-[#fafafa] py-3">
                <h1 className="text-[18px] font-bold">Invoices</h1>
                {data?.status === "PENDING" ? (
                  <button className="w-[135px] h-[35px] rounded-[5px] text-[14px] bg-[#FCE6C2] text-secondaryColor">
                    Pending
                  </button>
                ) : data?.status === "ACCEPTED" ? (
                  <button className="w-[135px] h-[35px] rounded-[5px] bg-[#A7C3BA] text-primaryColor">
                    Accepted
                  </button>
                ) : null}
              </div>
              <div className="flex px-6 items-center justify-between">
                <h1 className="text-[18px] font-bold">
                  Reference No: | {data?.invoiceReference}
                </h1>

                <h1 className="text-[18px] font-bold w-[25%]">
                  Payable Finance Offer
                </h1>
              </div>

              <main className=" w-full flex justify-between px-6">
                <div className="flex flex-col gap-3 w-[30%]">
                  <div className="flex flex-col gap-1">
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs font-semibold w-[30%]">
                        Invoice Number:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        INV- {data?.invoiceNo}
                      </span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs font-semibold w-[30%]">
                        Created Date:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {data?.createdDate}
                      </span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs font-semibold w-[30%]">
                        Invoice Due Date:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {data?.dueDate}
                      </span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs font-semibold w-[30%]">
                        Invoice Amount:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {data?.invoiceAmount}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col gap-3 w-[25%]">
                  <div className="flex flex-col gap-1">
                    <div className="flex gap-x-[30px]">
                      <span className=" text-xs font-semibold w-[40%]">
                        Discount Rate:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {data?.discountRate}
                      </span>
                    </div>
                    <div className="flex gap-x-[30px]">
                      <span className=" text-xs font-semibold w-[40%]">
                        Discount Amount:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {data?.invoiceDiscountAmount}
                      </span>
                    </div>
                    <div className="flex gap-x-[30px]">
                      <span className=" text-xs font-semibold w-[40%]">
                        Payable Amount:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {data?.payableAmount}
                      </span>
                    </div>
                    <div className="flex gap-x-[30px]">
                      <span className=" text-xs font-semibold w-[40%]">
                        Paymnent Duration:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {data?.paymentDuration}
                      </span>
                    </div>
                    <div className="flex gap-x-[30px]">
                      <span className=" text-xs font-semibold w-[40%]">
                        Payment Date:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {data?.paymentDate}
                      </span>
                    </div>
                  </div>
                </div>
              </main>
            </div>

            <div className="flex justify-between my-5 mx-6 pb-3 border-b-[2px] border-[#fafafa]">
              <div className="flex flex-col">
                <span className=" font-bold text-sm">
                  {data?.buyerCompanyName}
                </span>
                <span className="text-[#979797] text-xs">Buyer Details</span>
              </div>
              <div className="flex flex-col">
                <span className="font-bold text-sm">
                  {data?.paymentDuration}
                </span>
                <span className="text-[#979797] text-xs">Payment</span>
              </div>
              <div className="flex flex-col">
                <span className="font-bold text-sm">{data?.currency}</span>
                <span className=" text-[#979797] text-xs">Currency</span>
              </div>
            </div>

            <div className="flex flex-col gap-4 mt-5 px-6">
              <header className="border-b-[5px] border-b-[#F6F5FA] pb-3 flex items-center justify-between">
                <h1 className="text-sm font-bold">Line Items</h1>
                <h1 className="text-sm font-bold">
                  {data?.lineItems?.length} Total Item(s)
                </h1>
              </header>
              <div className=" w-full overflow-x-auto">
                <table className="overflow-x-auto w-[100%]">
                  <thead className="bg-subtleGray h-[70px] w-[50vw] overflow-x-auto">
                    <tr className=" bg-[#fafafa] p-2.5">
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Item
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[220px]">
                        Description
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Unit Price
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Quantity
                      </td>
                      <td className="text-[#11192A] p-2.5 text-xs font-bold w-[120px]">
                        Amount
                      </td>
                      <td className="text-[#11192A] p-2.5 text-xs font-bold w-[120px]">
                        Tax rate
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Tax Amount
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Total Price
                      </td>
                    </tr>
                  </thead>
                  {data?.lineItems?.map((item, index) => (
                    <tbody key={index}>
                      <tr className=" py-[3px] px-1.5">
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.itemName}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5 w-[20%]">
                          {item?.description}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.unitPrice.toLocaleString()}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.quantity}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.amount.toLocaleString()}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.taxRate.toLocaleString()}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.taxAmount.toLocaleString()}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.totalPrice?.toLocaleString()}
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            </div>

            <div className="flex itemscenter justify-between px-6 mt-[10%] border-t-[1px] border-silver bg-[#fafafa] py-5">
              <div>
                <h1 className="border-b border-[#B1A6B2] font-semibold">
                  Receiving Funding Account Information
                </h1>
                <p className="mt-3 text[#979797]">{data?.virtualAccount?.accountNumber}</p>
                <p className="text[#979797]">{data?.virtualAccount?.accountName}</p>
              </div>
              <div className="flex flex-col">
                <div className="flex justify-between gap-x-[100px] w-full">
                  <span className="text-xs w-[97px]">Sub Total</span>
                  <span className="text-[#979797] text-xs text-right">
                    {data?.subTotal}
                  </span>
                </div>

                <div className="flex justify-between w-full gap-x-[100px] my-2">
                  <span className="flex items-center text-xs w-[97px]">
                    Discount%
                    <span className="border border-[#979797] pr-10 pl-2 inline-block ml-4">
                      {data?.discountRate}
                    </span>
                  </span>
                  <span className="text-[#979797] text-xs">
                    {data?.discountAmount?.toLocaleString()}
                  </span>
                </div>

                <div className="flex justify-between w-full gap-x-[100px] pb-5">
                  <span className="flex items-center text-xs w-[97px]">
                    VAT%
                    <span className="border border-[#979797] ml-10 pr-11 pl-2 inline-block">
                    {data?.vat}%
                    </span>
                  </span>
                  <span className="text-[#979797] text-xs">NGN {data?.vatAmount?.toLocaleString()}</span>
                </div>

                <div className="flex justify-between items-center w-full gap-x-[100px] border-t-2 border-b-2 font-bold text-lg py-1">
                  <span className="w-[97px]">Grand Total</span>
                  <span className="text[#979797] py-1">{data?.grandTotal?.toLocaleString()}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default PayableFinanceInvoiceDetails;
