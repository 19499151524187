import request from "apiInstance";
import toast from "react-hot-toast";

export const buyersAssociatePendingRequest = async ({
  page,
  pageSize,
  sender,
  search,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/buyers/pending-association-requests?sender=${sender}&search=${search}&page=${page}&size=${pageSize}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const buyersAssociatePendingRequestFunder = async ({
  page,
  pageSize,
  sender,
  search,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/buyers/pending-association-requests?page=${page}&size=${pageSize}&sender=${sender}&search=${encodeURIComponent(search)}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getSentPendingAssociationRequests = async ({page, pageSize, entityType, search}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/buyers/sent-pending-association-requests?page=${page}&size=${pageSize}&entityType=${entityType}&search=${search}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getSentPendingInvites = async ({page, pageSize, sender, search}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/buyers/onboarding-invites/pending?page=${page}&size=${pageSize}&role=${sender}&search=${search}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
}

export const SupplierGetSentPendingInvitesBuyer = async ({page, pageSize, sender}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/suppliers/onboarding-invites/pending?page=${page}&size=${pageSize}&role=${sender}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};


export const getAllBuyers = async (page, pageSize, role ="buyers" ) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/suppliers/unassociated-other-players?page=${page}&size=${pageSize}&role=${role}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

// /api/v1/suppliers/pending-association-requests?page=0&size=10&entityType=buyer
export const supplierGetPendingAssociationRequest = async ({ page, pageSize, entityType, companyName }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/suppliers/pending-association-requests?page=${page}&size=${pageSize}&entityType=${entityType}&search=${companyName}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const supplierAcceptBuyerRequest = async (notificationId) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/suppliers/buyer-association-request/accept/${notificationId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const supplierRejectBuyerRequest = async (notificationId) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/suppliers/buyer-association-request/reject/${notificationId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};



export const supplierAssociationWithBuyer = async (buyerId) => {
  try {
    const response = await request({
      method: "post",
      url: `/api/v1/suppliers/associate/${buyerId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

// /api/v1/funders/onboarding-invites/pending?page=0&size=10&search
export const funderPendingInvites = async ({ page, pageSize, search, sender }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/funders/onboarding-invites/pending?page=${page}&size=${pageSize}&search=${encodeURIComponent(search)}&role=${sender}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
}

export const buyerAcceptSupplierRequest = async (notificationId) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/buyers/association-request/accept/${notificationId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const buyerAcceptFunderRequest = async (notificationId) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/buyers/funder-association-request/accept/${notificationId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const buyerDeclineFunderRequest = async (notificationId) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/buyers/funder-association-request/reject/${notificationId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getSupplierPendingAssociationRequest = async ({ page, pageSize, search }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/suppliers/pending-association-requests?page=${page}&size=${pageSize}&search=${encodeURIComponent(search)}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

// /api/v1/suppliers/pending-association-requests?page=0&size=10&entityType=funder
export const getSupplierToFunderPendingAssociationRequest = async ({ page, pageSize, entityType, search }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/suppliers/pending-association-requests?page=${page}&size=${pageSize}&entityType=${entityType}&search=${search}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const supplierAcceptFunderRequest = async (notificationId) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/suppliers/funder-association-request/accept/${notificationId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const supplierRejectFunderRequest = async (notificationId) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/suppliers/funder-association-request/reject/${notificationId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};



export const supplierDisassociateFunder = async (funderId) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/suppliers/dissociate-funder/${funderId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};


export const getAllBuyerAssociatedSuppliers = async ({
  page,
  pageSize,
  search,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/buyers/suppliers?page=${page}&size=${pageSize}&search=${search}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllBuyerAssociatedFunders = async ({
  page,
  pageSize,
  search,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/accountTier/buyer-associated-funders?page=${page}&size=${pageSize}&search=${search}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};


export const BuyyerSendOnboardingInviteToSuppliers = async (body) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/buyers/onboarding-invites/send",
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const buyerSendRequestToFunder = async (funderId) => {
  try {
    const response = await request({
      method: "post",
      url: `/api/v1/buyers/associate-with-funder/${funderId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const buyerSendRequestToSupplier = async (supplierId) => {
  try {
    const response = await request({
      method: "post",
      url: `/api/v1/buyers/send-association-request/${supplierId}?supplierId=${supplierId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

// /api/v1/suppliers/associate-with-funder/:userId
export const supplierSendRequestToFunder = async (userId) => {
  try {
    const response = await request({
      method: "post",
      url: `/api/v1/suppliers/associate-with-funder/${userId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};


export const searchSupplier = async (companyName, page, pageSize) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/suppliers/search?companyName=${encodeURIComponent(companyName)}&page=${page}&size=${pageSize}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const searchBuyer = async (companyName, page, pageSize) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/buyers/search?companyName=${encodeURIComponent(companyName)}&page=${page}&size=${pageSize}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const searchFunder = async (fullName, page, pageSize) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/funders/search?companyName=${encodeURIComponent(fullName)}&page=${page}&size=${pageSize}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const buyerSendOnboardingInviteToSupplier = async (body) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/buyers/onboarding-invites-funders/send",
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const SupplierSendOnboardingInviteToFunderOrBuyer = async (body) => {
  try {
    const response = await request({
      method: "post",
      url: "api/v1/suppliers/onboarding-invites/send",
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const buyerSendOnboardingInviteToFunder = async (body) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/buyers/onboarding-invites-funders/send",
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const disassociateBuyerSupplier = async (supplierId) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/buyers/dissociate-supplier/${supplierId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const disassociateBuyerFunder = async (funderId) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/buyers/dissociate-funder/${funderId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const buyerRejectSupplierRequest = async ({ notificationId }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/buyers/association-request/reject/${notificationId}?note=Not trusted`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

// /api/v1/funders/view-all
export const getAllFunders = async (page, pageSize) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/funders/view-all?page=${page}&size=${pageSize}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

