import { motion } from "framer-motion";
import { PAIconArrowLeftGreen } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TransitionScale from "shared/animation/TransitionScale";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInvoiceById } from "appstate/openMarket/openMarketSlice";
// import DirectFundingInvoice from "./directFundingInvoice";
import DirectFundingInvoice from "./directFundingInvoice";

function SingleReimburseLoanDefaultDetails() {
  const { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const offerId = searchParams.get("offerId");

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getInvoiceById({ id }));
  }, [dispatch, id, offerId]);

  const { invoiceByIdData } = useSelector((state) => state?.openMarket);

  function handleGoBack() {
    navigate(-1);
  }

  return (
    <DashboardLayout
      section="Reimbursement"
      subHeader="Track invoices due for Reimbursement"
    >
      <TransitionScale className="flex  flex-col gap-6 bg-[#EEE] h-full">
        <header
          onClick={handleGoBack}
          className="w-full bg-white py-5 px-3 rounded-[10px]"
        >
          <div className="flex cursor-pointer w-fit gap-1 items-center">
            <PAIconArrowLeftGreen />
            <span className=" text-primaryColor text-sm">Go back</span>
          </div>
        </header>

        <main className="flex flex-col semi-lg:flex-row pb-8 gap-4 w-full ">
          <DirectFundingInvoice
            invoiceByIdData={invoiceByIdData}
            // setLockModalOpen={setLockModalOpen}
          />

          <motion.section
            initial={{
              y: -20,
            }}
            animate={{
              y: 0,
            }}
            exit={{
              y: 20,
            }}
            transition={{
              delay: 0.8,
            }}
            className="flex flex-col gap-3  pt-7  w-2/5 bg-white rounded-[10px]"
          >
            <header className="flex  px-6 w-full justify-between">
              <span className="font-bold"> Direct Funding Request</span>
              <span className=" font-thin italic text-black text-xs">
                {" "}
                With Recourse
              </span>
            </header>

            <div>
              {" "}
              <div className="   pb-4">
                <div class="w-full justify-start items-center gap-4 inline-flex "></div>
                <div class=" justify-between  flex flex-row    px-6  ">
                  <div className="flex gap-2 py-2.5">
                    <div className="flex flex-col">
                      {/* <span className=" text-black text-xs font-normal">
      Coca Cola
    </span> */}
                      <span class="text-black text-sm font-normal ">
                        {"Coca Cola PLC"}
                      </span>
                      {/* <span className="text-[#979797]  text-xs">Supplier</span> */}
                      <span class="text-[#949494] text-sm font-normal ">
                        Supplier
                      </span>
                    </div>
                  </div>
                  <div class=" flex-col justify-start items-start inline-flex">
                    {" "}
                    <div className="flex gap-2 py-2.5">
                      <div className="flex flex-col">
                        {/* <span className=" text-black text-xs font-normal">
      Coca Cola
    </span> */}
                        <span class="text-black text-sm font-normal ">
                          {"1.5%"}
                        </span>
                        {/* <span className="text-[#979797]  text-xs">Supplier</span> */}
                        <span class="text-[#949494] text-sm font-normal ">
                          Discount rate
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* here */}
                </div>
                <div class=" justify-between  flex flex-row     px-6     mt-2">
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">N90,000</div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Discount amount
                      </div>
                    </div>
                  </div>
                  {/* dhdh */}
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        {" 23-Dec-2024 (90 days)"}
                        {/* {details?.earlyPaymentDate && details?.earlyPaymentDate} */}
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Early Payment date
                      </div>
                    </div>
                  </div>
                </div>

                <div class=" justify-between  flex flex-row     px-6     mt-2">
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">N90,000</div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Request amount
                      </div>
                    </div>
                  </div>
                  {/* dhdh */}
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        {"8,9110,000"}
                        {/* {details?.earlyPaymentDate && details?.earlyPaymentDate} */}
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Payable amount
                      </div>
                    </div>
                  </div>
                </div>

                <div class=" justify-between  flex flex-row    px-6  mt-2 ">
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-base font-extrabold ">
                        Dangote Groups LTD
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Funder Name
                      </div>
                    </div>
                  </div>{" "}
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        +2348974998387
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Contact Number
                      </div>
                    </div>
                  </div>
                  {/* hd */}
                </div>

                {/* 2nd */}
                {/* another header */}
                {/* header ends here */}
                {/* here */}
              </div>
            </div>

            <div>
              {" "}
              <header className="flex  px-6 border-t border-[#d7d7d7] pt-6">
                <span className="font-bold">Bank Loans Details</span>
              </header>
              <div>
                {" "}
                <div className="   pb-4">
                  <div class="w-full justify-start items-center gap-4 inline-flex "></div>
                  <div class=" justify-between  flex flex-row    px-6  ">
                    <div className="flex gap-2 py-2.5">
                      <div className="flex flex-col">
                        {/* <span className=" text-black text-xs font-normal">
      Coca Cola
    </span> */}
                        <span class="text-black text-sm font-normal ">
                          {"+5%"}
                        </span>
                        {/* <span className="text-[#979797]  text-xs">Supplier</span> */}
                        <span class="text-[#949494] text-sm font-normal ">
                          Bank Loan Interest
                        </span>
                      </div>
                    </div>
                    <div class=" flex-col justify-start items-start inline-flex">
                      {" "}
                      <div className="flex gap-2 py-2.5">
                        <div className="flex flex-col">
                          {/* <span className=" text-black text-xs font-normal">
      Coca Cola
    </span> */}
                          <span class="text-black text-sm font-normal ">
                            {"NGN 10,997,584"}
                          </span>
                          {/* <span className="text-[#979797]  text-xs">Supplier</span> */}
                          <span class="text-[#949494] text-sm font-normal ">
                            Grand Total
                          </span>
                        </div>
                      </div>
                    </div>

                    {/* here */}
                  </div>
                  <div class=" justify-between  flex flex-row     px-6     mt-2">
                    <div class="py-2.5 flex-col justify-start items-start inline-flex">
                      <div class="flex-col justify-start items-start gap-0.5 flex">
                        <div class="text-black text-sm font-normal ">+4</div>
                      </div>
                      <div class="flex-col justify-start items-start  flex">
                        <div class="text-[#949494] text-sm font-normal ">
                          Additional Interest
                        </div>
                      </div>
                    </div>
                    {/* dhdh */}
                    <div class="py-2.5 flex-col justify-start items-start inline-flex">
                      <div class="flex-col justify-start items-start gap-0.5 flex">
                        <div class="text-black text-sm font-normal ">
                          {"23-Dec-2024"}
                          {/* {details?.earlyPaymentDate && details?.earlyPaymentDate} */}
                        </div>
                      </div>
                      <div class="flex-col justify-start items-start  flex">
                        <div class="text-[#949494] text-sm font-normal ">
                          Additional interest date
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* 2nd */}
                  {/* another header */}
                  {/* header ends here */}
                  {/* here */}
                </div>
              </div>
            </div>
          </motion.section>
        </main>
      </TransitionScale>
    </DashboardLayout>
  );
}

export default SingleReimburseLoanDefaultDetails;
