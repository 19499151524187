import {
  // PAIconDropdown,
  PAIconPepsi,
  PAIconQuillSend,
} from "assets/images/svgs";
import React from "react";

const ModalGeneralComment = ({ isOpen, onClose }) => {
  // const [activeTab, setActiveTab] = useState("general");

  // const handleTabClick = (tab) => {
  //   setActiveTab(tab);
  // };

  const commentsArray = [
    {
      img: "/dp.png",
      name: "Pepsi Ltd",
      comment:
        "Hi there! I've reviewed the agreement, and it looks good to me. Just a quick question: Is there a specific deadline for us to submit the final report? Thanks!",
      date: "October 28, 2023",
      time: "3:30 AM",
    },
    {
      img: "/dp1.png",
      name: "ABC Trust Limited",
      comment:
        "Hi Alice! Thanks for checking. Yes, there's a deadline mentioned in section 3. It's 60 days from the effective date. Does that work for you.",
      date: "October 28, 2023",
      time: "3:30 AM",
    },
    {
      img: "/dp.png",
      name: "Pepsi Ltd",
      comment:
        "Thanks, David! That works for us. Just one more thing - in section 5.2, it mentions indemnification. Could you clarify what types of claims fall under that?",
      date: "October 28, 2023",
      time: "3:30 AM",
    },
    {
      img: "/dp1.png",
      name: "ABC Trust Limited",
      comment:
        "Of course, Alice. Indemnification covers any third-party claims related to the project that result from our actions or negligence. We'll share more details in a separate indemnification clause.",
      date: "October 28, 2023",
      time: "3:30 AM",
    },
  ];

  if (!isOpen) return null;
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg">
      <div className="bg-white py-2 px5 rounded-md max-w-xs md:max-w-lg w-full">
        <div className="flex justify-between items-center mb-4 px-4">
          <div className="flex space-x-4">
            <div class="justify-start text-black text-base font-semibold ">
              All comment
            </div>
          </div>
          <button
            onClick={onClose}
            className="text-black font-sans text-xl cursor-pointer flex justify-center items-center"
          >
            &times; <span className="text-sm mx-2 mt-1">Close</span>
          </button>
        </div>
        <div className="w-full py-3 bg-gray300 text-[#07593D] mt-[-15px] mb-5 px-4 bg-[#065f46]/10">
          <div class="justify-start text-[#07593D] text-xs font-medium ">
            All comments from Version 4
          </div>
        </div>

        {/* COMMENTS */}
        <div className="w-full max-h-[250px] overflow-y-auto">
          {commentsArray.map((comment, index) => (
            <div className=" flex flex-col">
              <div
                key={index}
                className="flex items-start mb-4 w-full px-6 gap-3"
              >
                {/* <img
                src={comment.img}
                alt={comment.name}
                className="w-10 h-10 rounded-full mr-3"
              /> */}
                <PAIconPepsi className=" w-16 h-12" />
                <div>
                  <div className="flex items-center justify-between w-full">
                    <p className="text-black text-sm font-bold  mr-2">
                      {comment.name}
                    </p>
                    <p className="text-[#d97706] text-xs font-normal ">
                      {comment.date} at {comment.time}
                    </p>
                  </div>
                  <p className="text-neutral-800 text-sm font-normal ">
                    {comment.comment}
                  </p>
                </div>
              </div>

              <div class="self-stretch inline-flex justify-start items-center gap-5 px-6">
                <div class=" bg-white   ">
                  <div class="  text-neutral-400 text-xs font-medium ">
                    June 2, 2022
                  </div>
                </div>
                <div class=" w-96 h-0 outline outline-1 outline-offset-[-0.50px] outline-zinc-300"></div>
              </div>
            </div>
          ))}
        </div>
        {/* SEND BUTTON */}
        <div className="relative w-full mt-2 px-4 py-3">
          <input
            type="text"
            id="fileUrl"
            placeholder="Post a comment"
            className="w-full border border-[#CCC] hover:border-blue-300 outline-none rounded-md p-2 py-4"
          />
          <button className="absolute top-1/2 right-2 transform -translate-y-1/2 p-2.5">
            <PAIconQuillSend />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalGeneralComment;
