import React from "react";
import { PAIconSmallClose } from "assets/images/svgs";
import { motion } from "framer-motion";

const FunderDetails = ({ onClose }) => {

  const handleCloseDeleteModal = () => {
    onClose();
  };

  return (
    <motion.div>
      <motion.div className="bg-slate-600/20 fixed inset-0 flex items-center justify-center z-50">
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.2,
            },
          }}
          exit={{
            opacity: 0,
            transition: {
              delay: 0.1,
            },
          }}
          className="modal-overlay"
        ></motion.div>

        <motion.div
          initial={{
            scale: 0,
          }}
          animate={{
            scale: 1,
            transition: {
              duration: 0.2,
            },
          }}
          exit={{
            scale: 0,
            transition: {
              delay: 0.1,
            },
          }}
          className="px-6 pt-9 pb-12 bg-white rounded-[10px] w-[60%] md:w-1/2 extra-md:w-[33%] min-h-[565px]  max-h-[565px] flex flex-col"
        >
          <header className="flex justify-between pb-4 border-b-[3px] border-[#F6F5FA]">
            <span className=" text-[#222] font-bold">
              About Funder
            </span>

            <span
              onClick={handleCloseDeleteModal}
              className="flex items-center cursor-pointer gap-2 text-sm font-[500] text-[#565656]"
            >
              <PAIconSmallClose />
              Close
            </span>
          </header>


          <main className="pt-4 overflow-auto scrollbar-hide">
            <div className="flex flex-col gap-8">
              <section className="flex flex-col gap-6">
                <header className="font-bold text-sm">
                  Personal Information
                </header>

                <div className="text-sm flex flex-col gap-2">
                  <span className="text-[#7A7A7A] ">Name</span>
                  <span>Dangote PLC</span>
                </div>

                <div className="text-sm flex flex-col gap-2">
                  <span className="text-[#7A7A7A] ">Email</span>
                  <span>www.skyflockltd.com</span>
                </div>

                <div className="text-sm flex flex-col gap-2">
                  <span className="text-[#7A7A7A] ">Contact number</span>
                  <span>+2348974998387</span>
                </div>

                <div className="text-sm flex flex-col gap-2">
                  <span className="text-[#7A7A7A] ">Account Type</span>
                  <span>Open Market Funder</span>
                </div>
              </section>

              <section className="flex flex-col gap-3">
                <header className="font-bold text-sm">Company Information</header>

                <div className="text-sm flex flex-col gap-2">
                  <span className="text-[#7A7A7A] ">Company Address</span>
                  <span>4b Utomi Aire Ave, off Freedom way, Lekki Phase 1</span>
                </div>
              </section>
            </div>
          </main>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default FunderDetails;