import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { PAIconInvShop } from "assets/images/svgs";
import { useDispatch } from "react-redux";
import { getInvoiceId } from "appstate/buyer/buyerSlice";
import TooltipShared from "shared/tooltip/Tooltip";
import useCountdown from "utilities/helper/useCountdown";
import { formatCapitaliseString } from "utilities/helper/formatCapitaliseString";
import { formatCapitalize } from "utilities/formatCapitalize";

function RequestsCard({
  id,
  invoiceId,
  invoiceNumber = "N/A",
  invoiceReference = "N/A",
  supplierCompanyName = "N/A",
  requestAmount = "N/A",
  status = "N/A",
  statusRequest = "N/A",
  requestType,
  numberOfInvoices = "N/A",
  buyerRate = "N/A",
  earlypaymentDate = "N/A",
}) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => setIsLoading(false), 1000);
  }, []);

  const dispatch = useDispatch();

  const onRowClick = () => {
    dispatch(
      getInvoiceId({
        id,
        invoiceId,
      })
    );

    navigate(
      `${
        requestType === "bulk"
          ? `/closedmarket/requestsdetails-bulk/${id}`
          : `/closedmarket/requestsdetails`
      }`
    );
  };

  const { timeLeft } = useCountdown(earlypaymentDate);
  return (
    <motion.div
      onClick={onRowClick}
      whileTap={{ scale: 0.98 }}
      style={{ boxShadow: "0px 2px 4px 0px rgba(232, 226, 216, 0.36)" }}
      className="flex cursor-pointer justify-between bg-white py-5 px-6 rounded-[10px]"
    >
      <span className="flex gap-6  items-center">
        <PAIconInvShop />
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">
            <TooltipShared text={invoiceReference} maxLength={20} />
          </span>
          <span className=" text-[#959595] text-[10px]">Reference number</span>
        </div>
      </span>

      <span className="flex gap-6  items-center">
        {requestType === "single" ? (
          <div className="flex flex-col justify-center">
            <span className="font-[500] text-sm">
              <TooltipShared text={`INV-${invoiceNumber}`} maxLength={10} />
            </span>
            <span className=" text-[#959595] text-[10px]">Invoice number</span>
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center">
            <span className="font-[500] text-sm">{numberOfInvoices}</span>
            <span className=" text-[#959595] text-[10px]">
              Number of invoices
            </span>
          </div>
        )}
      </span>

      <span className="flex gap-6 items-center ">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{supplierCompanyName}</span>
          <span className=" text-[#959595] text-[10px]">{"Supplier"}</span>
        </div>
      </span>

      <span className="flex gap-6  items-center">
        <div className="flex flex-col justify-center">
          <span
            className={
              status === "POSTED"
                ? "font-[500] text-sm text-primaryColor"
                : "font-[500] text-sm text-secondaryColor"
            }
          >
            {formatCapitalize(status)}
          </span>
          <span className=" text-[#959595] text-[10px]">Status of request</span>
        </div>
      </span>

      <span className="flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">
            NGN {requestAmount?.toLocaleString()}
          </span>
          <span className=" text-[#959595] text-[10px]">Request Amount</span>
        </div>
      </span>

      <span className="hidden extra-md:flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">
            {Number(buyerRate)?.toFixed(2)}%
          </span>
          <span className=" text-[#959595] text-[10px]">Buyer's Rate</span>
        </div>
      </span>

      <span className="hidden extra-md:flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{earlypaymentDate}</span>
          <span className=" text-[#959595] text-[10px]">
            Early payment date
          </span>
        </div>
      </span>

      <span className="hidden extra-md:flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span className={`text-sm font-medium`}>
            {(timeLeft.days > 0 ||
              timeLeft.hours > 0 ||
              timeLeft.minutes > 0 ||
              timeLeft.seconds > 0) &&
            formatCapitaliseString(status) !== "Cancelled" ? (
              `${timeLeft.days}d:${timeLeft.hours}h:${timeLeft.minutes}m:${timeLeft.seconds}s`
            ) : isLoading ? (
              <span className="text-secondaryColor">Fetching...</span>
            ) : formatCapitaliseString(status) === "Cancelled" ? (
              <span className="text-red-500">Cancelled</span>
            ) : (
              <span className="text-red">Expired</span>
            )}
          </span>
          <span className=" text-[#959595] text-[10px]">
            {statusRequest === "POSTED"
              ? "Count down to Early Payment"
              : "Count down to the end of bid"}
          </span>
        </div>
      </span>
    </motion.div>
  );
}

export default RequestsCard;
