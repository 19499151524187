import AccountManagementDashboard from "features/wallet(new)/accountmanagement/Dashboard";
import DashboardLayout from "layout/dashboardlayout";
import React from "react";

const AccountManagementPage = () => {
  return (
    <DashboardLayout
      section="Wallet"
      subHeader="Here is an overview of your payments and payment processes"
    >
      <AccountManagementDashboard />
    </DashboardLayout>
  );
};

export default AccountManagementPage;
