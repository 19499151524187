import request from "apiInstance";
import toast from "react-hot-toast";

export const pendingRequest = async ({ page, pageSize, search, sender }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/funders/pending-association-requests?sender=${sender}&search=${search}&page${page}&size=${pageSize}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const funderAcceptSupplierRequest = async ({
  notificationId,
}) => {
  // /api/v1/funders/supplier-association-request/accept/
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/funders/supplier-association-request/accept/${notificationId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const funderAssociatedSuppliers = async ({ page, pageSize, search }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/accountTier/funder-associated-suppliers?page=${page}&size=${pageSize}&search=${search}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const funderAssociatedBuyers = async ({ page, pageSize, search }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/accountTier/funder-associated-buyers?page=${page}&size=${pageSize}&search=${search}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};


export const funderSendAssociateRequestToSupplier = async ({ supplierId }) => {
  try {
    const response = await request({
      method: "post",
      url: `/api/v1/funders/send-association-request/${supplierId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const funderSendOnboardingInviteToSupplier = async (body) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/funders/onboarding-invites/send",
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const funderAssociateWithBuyer = async (buyerId) => {
  try {
    const response = await request({
      method: "POST",
      url: `/api/v1/funders/send-association-request/${buyerId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const funderSendOnboardingInviteToBuyers = async (body) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/funders/onboarding-invites-buyers/send",
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};


export const funderAcceptBuyerRequest = async ({ notificationId }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/funders/buyer-association/accept/${notificationId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const funderDeclineSupplierRequest = async ({ notificationId }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/funders/supplier-association-request/reject/${notificationId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const funderDeclineBuyerRequest = async ({ notificationId }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/funders/buyer-association-request/reject/${notificationId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const funderDisassociateBuyer = async ({ userId }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/funders/dissociate-buyer/${userId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const funderDisassociateSupplier = async ({ userId }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/funders/dissociate-supplier/${userId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
