import React, { useEffect, useState } from "react";
import { PAIconCancelMini } from "assets/images/svgs";
import { PAVerifiedAccount } from "assets/images/svgs";
import SuccessModal from "shared/SuccessModal";
// import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const AccountVerificationModal = ({
  isOpen,
  onClose,
  //   isApproved,
  onConfirm,
}) => {
  const [successModal, setSuccessModal] = useState(false);
  const navigate = useNavigate();
  //   const { createPfpLoading } = useSelector((state) => state.buyer);

  useEffect(() => {
    setTimeout(() => {
      setSuccessModal(true);
    }, 2000);
  }, []);

  if (!isOpen) return null;

  const closeModal = () => {
    navigate("/payablefinancing/payablefinanceProgram");
  };

  return (
    <>
      <div className="bg-[rgba(0,0,0,0.2)] w-[100%] h-[100vh] flex justify-center items-center absolute left-0 top-0 z-10">
        {successModal ? (
          <SuccessModal
            isOpen={successModal}
            onClose={closeModal}
            message="Payable Flex PFP has been created Supplier would be notified when it starts"
          />
        ) : (
          <div className="bg-white p10 px5 rounded-md max-w-xs md:max-w-lg w-full pb-5 px10">
            <div className="flex justify-between items-center py-5 px-6 rounded-t-[10px] bg-[#FAFAFA]">
              <p className="text-sm text-[#7A7A7A] font-thin">
                Fund Early Payment
              </p>
              <button
                onClick={onClose}
                className="gap-2 text-xl cursor-pointer flex justify-center items-center"
              >
                <PAIconCancelMini />{" "}
                <span className="text-sm text-[#565656] flex items-center">
                  Close
                </span>
              </button>
            </div>

            {onConfirm ? (
              <div className="flex flex-col items-center justify-center h-[200px]">
                <p>Verifying if account is sufficient for liening</p>
                <div className="loader"></div>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center h-[200px]">
                <PAVerifiedAccount />
                <p>Account Verified</p>
              </div>
            )}
          </div>
        )}
      </div>

      {/* Add this CSS to your CSS file or in a <style> tag */}
      <style jsx>{`
        .loader {
          border: 4px solid #f3f3f3; /* Light grey */
          border-top: 8px solid #f08000; /* Blue */
          border-radius: 50%;
          width: 40px; /* Size of the loader */
          height: 40px; /* Size of the loader */
          animation: spin 1s linear infinite;
          margin: 10px auto; /* Center the loader */
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      `}</style>
    </>
  );
};

export default AccountVerificationModal;
