import {
  // PAIconCircleAdd,
  // PAIconColapse,
  PAIconIconAddCircleBlack,
  PAIconIconDowbloadArrow,
  PAIconIconTopArrowWhite,
} from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
// import { useSelector } from "react-redux";
import Button from "shared/Button";

const OverviewCard = ({ supplierDashboard }) => {
  // const { supplierDashboard } = useSelector((state) => state.supplier);
  const navigate = useNavigate();

  return (
    <>
      <div className="flex w-full justify-between  mt-[30px] lg:mt-0  h-48 gap-1">
        <div className="bg-white rounded-t-[10px] border-b-[2px] border-secondaryColor flex flex-col justify-center items-center  ">
          {/* <div className="mt-[23px] xl:mt-[23px] lg:mt-[11px] flex justify-end mr-[19.38px]">
            <PAIconColapse />
          </div> */}
          <div className="mt-[21.4px] xl:mt-[21.4px] lg:mt-[11px]">
            <h1 className="text-center text-5xl font-bold mb-[8px] justify-center items-center flex">
              {/* {supplierDashboardData?.data?.totalInvoices || "2"} */}
              <PAIconIconAddCircleBlack />
            </h1>
            <p className="text-gray800 text-sm font-normal px-[30px]  text-center w-full">
              Create Invoice
            </p>
          </div>
        </div>

        <div className="bg-secondaryColor rounded-t-[10px] border-b-[2px] border-secondaryColor ">
          <div className="mt-[23px] xl:mt-[23px] lg:mt-[11px] flex justify-end mr-[19.38px]">
            <PAIconIconTopArrowWhite />
          </div>
          <div className="mt-[21.4px] xl:mt-[21.4px] lg:mt-[11px]">
            <h1 className="text-center text-5xl font-bold mb-[8px] text-[#FFFFFF]">
              {supplierDashboard?.data?.stats?.totalInvoices || "78"}
            </h1>
            <p className="text-white text-sm font-normal  whitespace-nowrap lg:mx-[15px] 2xl:text-center">
              Total Invoices
            </p>
          </div>
        </div>

        {/* third */}
        <div className="bg-white rounded-t-[10px] border-b-[2px]   border-neutral-800 flex flex-col justify-center items-center  ">
          {/* <div className="mt-[23px] xl:mt-[23px] lg:mt-[11px] flex justify-end mr-[19.38px]">
            <PAIconColapse />
          </div> */}
          <div className="mt-[21.4px] xl:mt-[21.4px] lg:mt-[11px]">
            <h1 className="text-center text-5xl font-bold mb-[8px] justify-center items-center flex">
              {/* {supplierDashboardData?.data?.totalInvoices || "2"} */}
              <PAIconIconDowbloadArrow />
            </h1>
            <p className="text-gray800 text-sm font-normal px-[30px]  text-center w-full">
              Upload Invoice
            </p>
          </div>
        </div>
      </div>
      <div
        className="bg-white pt-[20px] pb-[33px] mt-[20px] rounded-b-[10px] border-t-[2px] border-secondaryColor "
        onClick={() => navigate("/open-market")}
      >
        <div className="flex justify-between px-[20px] items-center">
          <h1 className="text-primaryColor text-[20px] font-normal">
            Open Market
          </h1>
          <Button
            neutral
            className="!text-white bg-secondaryColor px-[10px] py-[5px] rounded-[5px] flex gap-x-[10px] items-center"
          >
            {/* <p className="text-base font-normal">Open market</p> */}
            <PAIconIconTopArrowWhite className="w-[12px]" />
          </Button>
        </div>
        <div className="mt-[29px] flex justify-between px-[20px] items-center">
          <div>
            <h1 className="text-primaryColor text-center text-[40px] font-bold">
              {supplierDashboard?.data?.stats?.openMarketPosted}
            </h1>
            <p className="text-sm text-gray800 font-normal">Posted</p>
          </div>
          <div className="border-r-[1px] border-primaryColor border-l-[1px] px-[40px] xl:px-[30px] lg:px-[20px]">
            <h1 className="text-secondaryColor text-center text-[40px] font-bold">
              {supplierDashboard?.data?.stats?.openMarketBidActive}
            </h1>
            <p className="text-sm text-gray800 font-normal">Bid Active</p>
          </div>
          <div>
            <h1 className="text-lightGreen text-center text-[40px] font-bold">
              {supplierDashboard?.data?.stats?.openMarketLocked}
            </h1>
            <p className="text-sm text-gray800 font-normal">Locked</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default OverviewCard;
