import {
  getAllEarlyPaymentOffers,
  // SingleEarlyPaymentAcceptOffer,
} from "appstate/api/invoice/invoice";
import Table from "layout/table/Table";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import AcceptModal from "shared/AcceptModal";
import Card from "shared/Card";
import Loading from "shared/Loading";
import TableHeader from "shared/tableHeader/TableHeader";
import LoadingScreen from "shared/LoadingScreen";

const SupplierEarlyPaymentBulkInvoice = () => {
  const navigate = useNavigate();
  // const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  // eslint-disable-next-line
  const [pageNo, setPageNo] = useState(0);
  // eslint-disable-next-line
  const [pageSize, setPageSize] = useState(10);
  const [data, setData] = useState([]);
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const result = await getAllEarlyPaymentOffers({
        pageNo,
        pageSize,
        search: searchTerm,
      });
      setData(result?.data || []);
    } catch (error) {
      // Handle error
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [pageNo, pageSize, searchTerm]);


  const onRowClicked = (id, status) => {
    let path = "";
    switch (status) {
      case "ACCEPTED":
        path = `/supplier-earlypayment/invoice/accepted/${id}`;
        break;
      case "PENDING":
        path = `/supplier-earlypayment/invoice/pending/${id}`;
        break;
      case "REJECTED":
        path = `/supplier-earlypayment/invoice/rejected/${id}`;
        break;
      default:
        break;
    }
    navigate(path);
  };

  const columns = [
    {
      name: "Program name",
      selector: (row) => (
        <div className="flex items-center">
          {row?.programName}
        </div>
      ),
    },
    {
      name: "Invoice Reference",
      selector: (row) => row.invoiceRef,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
    },
    {
      name: "Due Date",
      selector: (row) => row.dueDate,
      sortable: true,
    },
    {
      name: "Payment Date",
      selector: (row) => row.paymentDate,
      sortable: true,
    },
    {
      name: "Invoice Amount",
      selector: (row) => row.invoiceAmount,
      sortable: true,
    },
    {
      name: "Discount Amount",
      selector: (row) => (
        <span className="text-success">{row.discountAmount}</span>
      ),
      sortable: true,
    },
    {
      name: "Receivable Amount",
      selector: (row) => (
        <span className="text-success">{row.receivableAmount}</span>
      ),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => {
        if (row.status === "ACCEPTED")
          return <p className="text-success">{row.status}</p>;
        if (row.status === "REJECTED")
          return <p className="text-red">{row.status}</p>;
        if (row.status === "PENDING")
          return <p style={{ color: "#F08000" }}>{row.status}</p>;
        return <p>{row.status}</p>;
      },
    },
  ];

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <div>
        <Card className="p-[24px] rounded-[10px]">
          <div className="flex justify-between">
            <div>
              <TableHeader
                total={`${data?.length || 0} invoices`}
                details={
                  <p>
                    List of invoices in{" "}
                    <span className="font-bold">Payable flex</span> Payable
                    finance program
                  </p>
                }
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
              />
            </div>
          </div>
          <Table
            columns={columns}
            data={data}
            onRowClicked={(row) => onRowClicked(row.offerId, row.status)}
            progressComponent={<Loading />}
            pointer
            tableHeader
            className="mt-5 min-h-[60vh]"
          />
        </Card>
      </div>
    </>
  );
};

export default SupplierEarlyPaymentBulkInvoice;
