import React, { useState } from "react";

const ConfirmDiscountRateModal = ({ onClose, onNext, isOpen }) => {
  const [data, setData] = useState([
    {
      requestRef: "R3456X89Y",
      invoiceRef: "INV-2090",
      discountRate: 1.5,
      discountAmount: 950000,
      payableAmount: 9000000,
    },
    {
      requestRef: "R3456X89Y",
      invoiceRef: "INV-2090",
      discountRate: 1.5,
      discountAmount: 950000,
      payableAmount: 9000000,
    },
    {
      requestRef: "R3456X89Y",
      invoiceRef: "INV-2090",
      discountRate: 1.5,
      discountAmount: 950000,
      payableAmount: 9000000,
    },
  ]);

  const handleDiscountRateChange = (index, newRate) => {
    const updatedData = [...data];
    updatedData[index].discountRate = newRate;
    updatedData[index].discountAmount = (newRate / 100) * 9000000; // Adjust discountAmount logic if needed
    setData(updatedData);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white shadow-lg w-[50%] rounded-[10px] max-w-4xl">
        <div className="flex justify-between items-center bg-[#fafafa] px-6 py-4">
          <h2 className="text-lg font-semibold">Confirm Discount Rate</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            X Close
          </button>
        </div>
        <div className="p-6 overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="bg-[#fafafa]">
                <th className="px-4 py-2 text-left">
                  Request Ref. No.
                </th>
                <th className="px-4 py-2 text-left">
                  InvoiceRef
                </th>
                <th className="px-4 py-2 text-left">
                  Discount Rate
                </th>
                <th className="px-4 py-2 text-left">
                  Discount Amount
                </th>
                <th className="px-4 py-2 text-left">
                  Payable Amount
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index} className="hover:bg-gray-50 border-b-[1px] border-[#f7f7f7]">
                  <td className="px-4 py-2">
                    {item.requestRef}
                  </td>
                  <td className="px-4 py-2">
                    {item.invoiceRef}
                  </td>
                  <td className="px-4 py-2">
                    <input
                      type="text"
                      value={`${item.discountRate}%`}
                      onChange={(e) =>
                        handleDiscountRateChange(
                          index,
                          parseFloat(e.target.value)
                        )
                      }
                      className="w-20 border border-gray-300 rounded px-2 py-1 focus:outline-none focus:ring focus:ring-green-200"
                    />
                  </td>
                  <td className="px-4 py-2">
                    {item.discountAmount.toLocaleString()}
                  </td>
                  <td className="px-4 py-2">
                    {item.payableAmount.toLocaleString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between items-center p-6 border-t">
          <button
            onClick={onClose}
            className="bg-gray-200 text-gray-700 px-6 py-2 rounded hover:bg-gray-300"
          >
            Cancel
          </button>
          <button
            onClick={onNext}
            className="bg-green-600 text-white px-6 py-2 rounded hover:bg-green-700"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDiscountRateModal;
