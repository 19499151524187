import { PAIconActionWarning } from 'assets/images/svgs'
import React, { useState } from 'react'
import InputField from 'shared/InputField'
import AcceptCreateAgreementModal from '../../AgreementDetails/Modal/AcceptCreateAgreementModal'
import AgreementSuccessModal from '../../AgreementDetails/Modal/AgreementSucessModal'

const FourtStep = ({onPrev, onNext}) => {

    const [isOpen, setIsOpen] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false)
    const handleNext =()=>{
        setIsOpen(false)
        setIsSuccess(true)
    }
  return (
    <> 
    <AcceptCreateAgreementModal isOpen={isOpen} onClose={()=> setIsOpen(false)} mess1={"Are you sure you want to proceed with creating this agreement?"} handleYes={handleNext} />
        <AgreementSuccessModal isOpen={isSuccess} onClose={()=> setIsSuccess(false)} message={"Agreement created successfully"}/>
    
    
    <div className='lg:min-w-[360px] md:min-w-[360px]'>
    <div className='flex w-full justify-center items-center mt-2'><div class="self-stretch text-center justify-start text-[#a3a3a3] text-sm font-normal ">Agreement Details</div></div>
    <div className='flex w-full justify-center items-center mt-2'><div class="text-center justify-start text-[#065f46] text-sm font-semibold ">Enter agreement details and Click to sign</div></div>



    <div className='flex flex-col w-full justify-center items-center mt-5 gap-3'>

    <div className=' w-full'> <InputField  className=" shadow-sm  text-sm focus:outline-none  cursor-pointer  px-3   py-3.5 rounded-[5px] border border-[#949494] justify-start items-end gap-2 inline-flex  w-full " label={"Agreement Name"}/></div>

       

    <div className=' w-full'>
    <div class="self-stretch justify-start text-black text-sm font-medium ">Select recipient party</div>
      <select
        className=" shadow-sm  text-sm focus:outline-none  cursor-pointer  px-3   py-3.5 rounded-[5px] border border-[#949494] justify-start items-end gap-2 inline-flex  w-full "
        // onChange={(e) => {}}
        // onChange={(e) => setStatus(e.target.value)}
      >
        <option value="" key="all-bids">
          All Agreement Type
        </option>
        <option value="BID_ACTIVE" key="bid-active">
          PFP Campaign
        </option>
        <option value="POSTED" key="posted-request">
          Direct Funding
        </option>
        <option value="POSTED" key="posted-request">
          Early Payment
        </option>
        <option value="POSTED" key="posted-request">
          Closed Market
        </option>
        <option value="POSTED" key="posted-request">
          Open Market
        </option>
      </select>
    </div>

    <div className='w-full'>
    <div className="self-stretch justify-start text-black text-sm font-medium ">Agreement description</div>
    <textarea  className=" shadow-sm  text-sm focus:outline-none  cursor-pointer  px-3   py-3.5 rounded-[5px] border border-[#949494] justify-start items-end gap-2 inline-flex  w-full "></textarea>
    </div>


<div className=' w-full '><div className="mt-[5px]  ">

<div class="self-stretch justify-start text-neutral-800 text-sm font-medium">Signature</div>
      <div className=' flex w-full  justify-start  items-start mt-4'>
    
        <input type="checkbox" className="accent-primaryColor mr-[10px]" />
        {/* <label htmlFor="" className="font-medium text-sm text-textColor">
          I agree to the PayAssist Terms and Conditions
        </label> */}
        {/*  */}
        {/* <div>Hellio</div> */}

        <label class="justify-start text-neutral-500 text-sm font-medium ">I agree to this agreement</label>

       
      </div>
    </div>
</div>


    <div class="inline-flex justify-start items-start gap-4">
<div class="w-4 h-4 relative overflow-hidden">
    <div class="w-3.5 h-3 left-[0.75px] top-[1.25px] absolute "><PAIconActionWarning/></div>
</div>
<div class="w-80 justify-start text-amber-600 text-sm font-medium ">By selecting "I agree to this agreement", you are hereby acknowledging your agreement to the aforementioned contract, establishing a binding agreement between yourself and the counterparty.</div>
</div>


<div className=' flex w-full justify-between items-center py-3'>

<div onClick={onPrev} className=' cursor-pointer'><div class="p-2.5 rounded-[5px] outline outline-1 outline-offset-[-1px] outline-stone-300 inline-flex justify-center items-center gap-2.5 mt-5">
<div class="justify-start text-neutral-400 text-xs font-medium ">Previous step</div>
</div>

</div>


<div onClick={()=>setIsOpen(true)} className=' cursor-pointer'>


{/* <div class="p-2.5 bg-[#065f46] rounded-[5px] inline-flex justify-center items-center gap-2.5">
<div class="justify-start text-white text-xs font-medium ">Next step</div>
</div> */}

<div class="p-2.5 bg-[#e5e5e5] rounded-[5px] inline-flex justify-center items-center gap-2.5" >
<div class="justify-start text-[#7A7A7A] text-xs font-medium ">Create agreement</div>
</div>

</div>
</div>
  
    </div>

  

  
</div></>
   
  )
}

export default FourtStep
