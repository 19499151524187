import { PAIconWalletCardBlack } from "assets/images/svgs";
import React from "react";

const PendingDetails = ({ fundingAccount }) => {
  return (
    <div className="mt-10">
      <div>
        <h2 className="font-bold">Funding Account</h2>

        <di className="border-[1px] border-[#a3a2a2] rounded-[8px] p-3 w-[200px] block mt-3">
          <div className="flex items-center">
            <PAIconWalletCardBlack />
            <p className="ml-2">{fundingAccount?.accountName}</p>
          </div>
          <div className="mt-2">
            <p className="text-[18px] font-bold">
              {fundingAccount?.accountNumber}
            </p>
          </div>
          <div className="mt-2">
            <p>{fundingAccount?.bankName}</p>
          </div>
        </di>
      </div>
    </div>
  );
};

export default PendingDetails;
