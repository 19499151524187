import { PAIconArrowLeftGreen, PAIconCocacola } from "assets/images/svgs";
import EnterPin from "features/disbursement/common/modal/Pin";
import { AnimatePresence, motion } from "framer-motion";
import DashboardLayout from "layout/dashboardlayout";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import TransitionScale from "shared/animation/TransitionScale";
import Table from "layout/table/Table";

const DisbursementDirectFundingDetailsPageBulk = () => {
  const [pinModal, setPinModal] = useState(false);
  const navigate = useNavigate();

  const dummyData = [
    {
      id: "1",
      Inv: "INV-2090",
      date: "2023-05-23",
      requestAmount: "9,000,000.00",
      disRate: "1.5%",
      receivAmount: "9,000,000.00",
    },

    {
      id: "2",
      Inv: "INV-2090",
      date: "2023-05-23",
      requestAmount: "9,000,000.00",
      disRate: "1.5%",
      receivAmount: "9,000,000.00",
    },

    {
      id: "3",
      Inv: "INV-2090",
      date: "2023-05-23",
      requestAmount: "9,000,000.00",
      disRate: "1.5%",
      receivAmount: "9,000,000.00",
    },

    {
      id: "4",
      Inv: "INV-2090",
      date: "2023-05-23",
      requestAmount: "9,000,000.00",
      disRate: "1.5%",
      receivAmount: "9,000,000.00",
    },
  ];

  const columns = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Invoice Number
        </span>
      ),
      selector: (row) => row.Inv,
      sortable: true,
    },
    {
      name: "Due Date",
      selector: (row) => row.date,
      sortable: true,
    },

    {
      name: "Request Amount",
      selector: (row) => row.requestAmount,
      sortable: true,
      width: "6.2rem",
    },
    {
      name: "Discount Rate",
      selector: (row) => row.disRate,
      sortable: true,
    },
    {
      name: "Receivable Amount",
      selector: (row) => row.receivAmount,
      sortable: true,
    },
  ];

  const handleRowClick = (row) => {
    navigate(
      `/disbursement/funder/disbursement/innerbulkdetailpagefordisbursement/${row.id}`
    );
  };
  return (
    <DashboardLayout
      section="Disbursement"
      subHeader="Track invoices due for payment disbursement"
    >
      <header className="w-full py-3">
        <span
          onClick={() => navigate(-1)}
          className="flex cursor-pointer items-center font-medium text-sm text-primaryColor gap-2"
        >
          <PAIconArrowLeftGreen /> Go back
        </span>
      </header>
      <main className="flex flex-col lg:flex-row gap-3 justify-between">
        <TransitionScale className="py-5 px-6 lg:w-[58%] flex flex-col gap-8 min-h-[90vh] bg-white rounded-[10px]">
          <header className="flex flex-col gap-3">
            <section className="flex justify-between  items-center">
              <span className="text-[18px] font-bold">
                Reference No: | R3456X89Y
              </span>

              <div className="flex flex-col gap-1">
                <motion.button
                  onClick={() => {
                    setTimeout(() => {
                      setPinModal(true);
                    }, 200);
                  }}
                  whileTap={{ scale: 0.95 }}
                  className="py-3 px-6 text-xs  text-white rounded bg-primaryColor"
                >
                  Pay Now
                </motion.button>

                <span className="text-secondaryColor text-sm font-medium">
                  00d:13h:55m:32s
                </span>
              </div>
            </section>

            <section className="flex justify-between">
              <div className="flex flex-col gap-1 w-[40%]">
                <section className="flex items-center justify-between">
                  <span className="text-xs font-semibold">
                    Early Payment Date
                  </span>
                  <span className="text-[#7a7a7a] text-xs font-semibold">
                    23-Mar -2023
                  </span>
                </section>

                <section className="flex items-center justify-between">
                  <span className="text-xs font-semibold">
                    Total Request Amount
                  </span>
                  <span className="text-[#7a7a7a] text-xs font-semibold">
                    24,000,000
                  </span>
                </section>

                <section className="flex items-center justify-between">
                  <span className="text-xs font-semibold">
                    Total Invoice Amount
                  </span>
                  <span className="text-[#7a7a7a] text-xs font-semibold">
                    34,000,000
                  </span>
                </section>
              </div>

              <div className="flex items-center text-xs">
                <button
                  style={{ background: "rgba(35, 173, 82, 0.16)" }}
                  className="px-8 py-2 text-primaryColor rounded-[5px] "
                >
                  Approved
                </button>
              </div>
            </section>
          </header>

          <section className="flex items-center justify-between">
            <div className="flex flex-col gap-2">
              <span className="text-[18px] font-semibold">Nestle PLC</span>
              <span className="text-[#7A7A7A] text-xs font-light">
                Buyer Details
              </span>
            </div>

            <div className="flex flex-col gap-2">
              <span className="text-[18px] font-semibold">60 Days</span>
              <span className="text-[#7A7A7A] text-xs font-light">Payment</span>
            </div>

            <div className="flex flex-col gap-2">
              <span className="text-[18px] flex justify-end font-semibold">
                NGN
              </span>
              <span className="text-[#7A7A7A] text-xs font-light">
                Currency
              </span>
            </div>
          </section>

          <section className="flex flex-col gap-4">
            <header className="flex items-center justify-between pb-3 border-b-[5px] border-[#F6F5FA] text-[#11192A]">
              <span className="text-sm">Invoice List</span>

              <span className="text-[18px] font-light">5 Invoices </span>
            </header>

            <div className=" w-full overflow-x-auto mt-3 pb-4">
              <Table
                columns={columns}
                data={dummyData}
                onRowClicked={(row) => handleRowClick(row)}
                pointer
                tableHeader
                className="mt-5"
              />
            </div>

            <div className=" px-6   py-5 bg-neutral-50 flex-col justify-center items-start  inline-flex bg-[#fafafa] w-full border-t-[0.2px]  border-t-[#b1a6b2]">
              <div className="lg:flex md:flex justify-between  w-full sm:block ">
                {/* First left side */}
                <div>
                  <section>
                    {" "}
                    <div class="text-[#222222] text-lg  font-medium border-b-[0.2px]  border-[#b1a6b2]">
                      Receiving Funding Account Information
                    </div>
                    {/* <div class="text-[#222222] text-lg font-semibold ">
                        Receiving Funding Account Information
                      </div> */}
                    <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                      74*******512
                    </div>
                    <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                      Praise Akinlade
                    </div>
                  </section>
                </div>

                {/* second division */}

                <div>
                  {" "}
                  <div class=" flex-col justify-start items-start inline-flex lg:min-w-[290px]">
                    <section className=" w-full px-6 pb-2 border-b-[1px] border-black">
                      <div className=" flex w-full justify-between">
                        <div class="text-[#222222] text-xs font-semibold ">
                          Subtotal
                        </div>
                        <div class=" text-[#222222] text-xs font-semibold ">
                          10,129,892
                        </div>
                      </div>
                      <div className=" flex w-full justify-between pt-3 pb-1 border-b-[0.2px] border-b-[#b1a6b2]/30">
                        <div class="text-[#222222]  text-xs font-medium flex  space-x-9 ">
                          <span>VAT%</span>
                          <span>5.6%</span>
                        </div>
                        <div class="text-[#222222] text-xs font-medium ">
                          573,960
                        </div>
                      </div>

                      <div className=" flex w-full justify-between pt-3 pb-1 border-b-[0.2px] border-b-[#b1a6b2]/30">
                        <div class="text-[#222222]  text-xs font-medium flex  space-x-9 ">
                          <span>Discount%</span>
                          <span>2.4%</span>
                        </div>
                        <div class="text-[#222222] text-xs font-medium ">
                          237,088
                        </div>
                      </div>
                    </section>
                    <div className=" flex w-full justify-between py-2 px-6 border-b-[1px] border-b-black">
                      <div class="text-[#222222] text-lg font-semibold ">
                        Grand Total
                      </div>
                      <div class="text-[#222222] text-lg font-semibold ">
                        10,473,890
                      </div>
                    </div>
                  </div>
                </div>

                {/* stops */}
              </div>
            </div>
          </section>
        </TransitionScale>

        <TransitionScale className="bg-white rounded-[10px] gap-6">
          <div class="w-full justify-start items-center gap-4 inline-flex ">
            <div class=" justify-start items-center gap-2.5 flex w-full px-6  py-3">
              <div class="text-black text-xl font-bold  mt-5">
                Early Payment Details
              </div>
            </div>
          </div>

          <div>
            <div className="   pb-4">
              <div class=" justify-between  flex flex-row    px-6 mt-4  ">
                <div className="flex gap-2 py-2.5">
                  <PAIconCocacola />
                  <div className="flex flex-col">
                    {/* <span className=" text-black text-xs font-normal">
                  Coca Cola
                </span> */}
                    <span class="text-black text-sm font-normal ">
                      Coca Cola PLC
                    </span>
                    {/* <span className="text-[#979797]  text-xs">Supplier</span> */}
                    <span class="text-[#949494] text-sm font-normal ">
                      Buyer
                    </span>
                  </div>
                </div>
                <div class="py-2.5 flex-col justify-start items-start inline-flex">
                  <div class="flex-col justify-start items-start gap-0.5 flex">
                    <div class="text-black text-sm font-normal ">1.5%</div>
                  </div>
                  <div class="flex-col justify-start items-start  flex">
                    <div class="text-[#949494] text-sm font-normal ">
                      Discount rate
                    </div>
                  </div>
                </div>

                {/* here */}
              </div>
              <div class=" justify-between  flex flex-row    px-6  mt-6 ">
                <div class="py-2.5 flex-col justify-start items-start inline-flex">
                  <div class="flex-col justify-start items-start gap-0.5 flex">
                    <div class="text-black text-sm font-normal ">NGN90,000</div>
                  </div>
                  <div class="flex-col justify-start items-start  flex">
                    <div class="text-[#949494] text-sm font-normal ">
                      Discount amount
                    </div>
                  </div>
                </div>
                {/* hd */}
                <div class="py-2.5 flex-col justify-start items-start inline-flex">
                  <div class="flex-col justify-start items-start gap-0.5 flex">
                    <div class="text-black text-sm font-normal ">
                      23-Dec-2024 (20 days)
                    </div>
                  </div>
                  <div class="flex-col justify-start items-start  flex">
                    <div class="text-[#949494] text-sm font-normal ">
                      Early Payment Date
                    </div>
                  </div>
                </div>{" "}
              </div>
              <div class=" justify-between  flex flex-row    border-b border-[#c2c2c2]/30 px-6  py-3  pb-8 mt-6">
                <div class="py-2.5 flex-col justify-start items-start inline-flex">
                  <div class="flex-col justify-start items-start gap-0.5 flex">
                    <div class="text-black text-sm font-normal ">
                      N 9,000,000
                    </div>
                  </div>
                  <div class="flex-col justify-start items-start  flex">
                    <div class="text-[#949494] text-sm font-normal ">
                      Request amount
                    </div>
                  </div>
                </div>
                {/* dhdh */}
                <div class="py-2.5 flex-col justify-start items-start inline-flex">
                  <div class="flex-col justify-start items-start gap-0.5 flex">
                    <div class="text-black text-sm font-normal ">
                      8,9110,000
                    </div>
                  </div>
                  <div class="flex-col justify-start items-start  flex">
                    <div class="text-[#949494] text-sm font-normal ">
                      Payable amount
                    </div>
                  </div>
                </div>
              </div>
              {/* 2nd */}
              <div class=" justify-between  flex flex-col    border-b border-[#c2c2c2]/30 px-6  pb-10 ">
                <div class="text-black text-lg font-semibold  mt-6">
                  Note from Supplier:{" "}
                </div>

                <div class=" text-[#949494] text-sm font-light  mt-4  leading-6">
                  Seeking Early payment due to unexpected challenges. Facing
                  temporary financial constraints and need a bit more time to
                  ensure a smooth transaction. Appreciate your understanding.
                  Thank you.
                  <br className=" mt-3" />
                  Best regards, <br />
                  ABC Limited
                </div>
              </div>{" "}
              {/* another header */}
              {/* header ends here */}
              {/* here */}
              <div class="justify-between  flex flex-col  px-6">
                <div class="text-black text-lg font-semibold  mt-6">
                  Funding Option
                </div>

                <div class="w-[377px]">
                  <span className="text-black/50 text-base font-light ">
                    This invoice has been approved by{" "}
                  </span>
                  <span className="text-black/50 text-base  font-medium ">
                    Eleanor Pena at Coca-Cola,{" "}
                  </span>
                  <span className="text-black/50 text-base font-light ">
                    your associated funder, for funding.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </TransitionScale>
      </main>

      <AnimatePresence>
        <EnterPin
          isOpen={pinModal}
          onClose={() => setPinModal(false)}
          pinMessage={
            " Enter your PIN to fund this early payment request from the chosen virtual account."
          }
        />
      </AnimatePresence>
    </DashboardLayout>
  );
};

export default DisbursementDirectFundingDetailsPageBulk;
