import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  approveExitRequest,
  buyerGetOfferDetails,
  buyerViewOfferInvoiceDetails,
  cancelPfpProgram,
  createPfp,
  declineExitRequest,
  getAllBuyerPfp,
  getAllBuyerPfpInvoices,
  getAllCampaignAgreement,
  getCampaignInvoices,
  invoiceImport,
  stopPfpProgram,
  viewBuyerPfpById,
  viewMembersData,
} from "appstate/api/buyer/buyerpfp";
import { buyersDasboard } from "appstate/api/buyer/dashboard";
import {
  allearlyPaymentsRequestBulkStatus,
  allearlyPaymentsRequestBulkStatusRejected,
  allearlyPaymentsRequestProcessingBulkStatus,
  allearlyPaymentsRequestProcessingStatus,
  allearlyPaymentsRequestStatus,
  allearlyPaymentsRequestStatusRejected,
  allPendingearlyPaymentsRequestBulkStatus,
  allPendingearlyPaymentsRequestStatus,
  approveSingleEarlyPaymentRequestClosed,
  approveSingleEarlyPaymentRequestFunder,
  approveSingleEarlyPaymentRequestWallet,
  closedMarketViewBulkDetailsPage,
  closedMarketViewBulkList,
  closedMarketViewSingleDetailsPage,
  closedMarketViewSingleList,
  fundRequestAllFundersClosedMarketApi,
  getAllearlyPayments,
  getApprovedBulkEarlyPaymentDetails,
  getApprovedSingleEarlyPaymentDetails,
  getClosedMarketOverviewApi,
  getClosedMarketTopTenApi,
  getPendingBulkEarlyPaymentDetails,
  getPendingSingleEarlyPaymentDetails,
  getProcessingSingleEarlyPaymentDetails,
  getRejectedBulkEarlyPaymentDetails,
  getRejectedSingleEarlyPaymentDetails,
  getUpdateDiscountRatePreviewApi,
  importEarlyPaymentToCloseMarketApi,
  rejectSingleEarlyPaymentRequestbyBuyer,
  saveBulkEarlyDiscountRate,
  saveSingleEarlyDiscountRate,
  updateBulkDiscountPecentageClosedMarketApi,
  updateBulkEarlyDiscountRate,
  updateSingleEarlyDiscountRate,
  withdrawProcessingSingleEarlyPaymentRequest,

  // getAllPendingearlyPayments,
} from "appstate/api/buyer/earlyPayment";

import {
  documentUpload,
  updateUploadedDocumentById,
  deleteUploadedDocumentById,
  getUploadedDocumentById,
  getUploadedDocuments,
  buyerUpdateUploadedDocument,
  documentUploadEdit,
  companyDetailsBuyerEdit,
  getBuyerDetails,
} from "appstate/api/documentUpload";

import {
  earlyPaymentApproveOrDecline,
  getAllNewEarlyPaymentRequest,
} from "appstate/api/buyer/earlyPayment";
import {
  allBuyerNotifications,
  notificationById,
} from "appstate/api/buyer/notification";
import {
  SupplierSendOnboardingInviteToFunderOrBuyer,
  buyerAcceptSupplierRequest,
  buyerRejectSupplierRequest,
  buyerSendOnboardingInviteToFunder,
  // buyerSendOnboardingInviteToSupplier,
  buyerSendRequestToFunder,
  buyerSendRequestToSupplier,
  buyersAssociatePendingRequest,
  disassociateBuyerFunder,
  disassociateBuyerSupplier,
  getAllBuyerAssociatedFunders,
  getAllBuyerAssociatedSuppliers,
} from "appstate/api/buyer/relationshipManagement";
import { getAllFunders } from "appstate/api/funder";
import {
  createTransPin,
  editTransPinForBuyer,
} from "appstate/api/transactionPin";
import {
  getEarlyPaymentRequestOverview,
  getEarlyPaymentRequestVirtualAccouts,
  getEarlyPaymentByvirtualAccountId,
  getEarlyPaymentOfferByvirtualAccountId,
  getEarlyPaymentOfferOverview,
  getEarlyPaymentOfferVirtualAccouts,
  makePayment,
  getDefaultLoan,
  getTransactionHistory,
  getEarlyPaymentOfferDetails,
  getDisbursementInvoiceDetails,
  getLienedPayablesByAccount,
  getUpcomingPayablesByAccount,
  getOverduePayablesByAccount,
  getDisbursemenmtOverview,
  confirmFundTransferSuccess,
} from "appstate/api/buyer/disbursement";

const initialState = {
  isLoading: false,
  declineLoading: false,
  buyerDashboardData: [],
  pendingAssociateRequestData: [],
  buyerAssociatedSuppliersData: [],
  buyerAssociatedFundersData: [],
  associatedSupplierId: null,
  associatedFunderId: null,
  associateLoading: false,
  inviteLoading: false,
  disAssociateLoading: false,
  notifications: [],
  notification: null,
  createPfpLoading: false,
  cancelPfpLoading: false,
  stopPfpLoading: false,
  requestLoading: false,
  allBuyerPfpData: [],
  viewMembersData: [],
  allBuyerFundingAgreement: [],
  offerInvoices: [],
  importLoading: false,
  viewPfpByIdData: [],
  viewPfpOffersData: [],
  viewPfpOffersInvoiceData: [],
  allPfpInvoicesData: [],
  pfpId: null,
  updatedDiscountDataResponse: [],
  updatedBulkDiscountDataResponse: [],
  savedDiscountDataResponse: [],
  savedBulkDiscountDataResponse: [],
  allEarlyPAymentsData: [],
  allEarlyPaymentRequestSingleData: [],
  allEarlyPaymentRequestBulkData: [],
  viewApprovedSingleEarlyPaymentDetailsData: [],
  pendingSingleEarlyPaymentDetailsData: [],
  updateSingleDiscountRateData: [],
  processingSingleEarlyPaymentDetailsData: [],
  pendingBulkEarlyPaymentDetailsData: [],
  approvedBulkEarlyPaymentDetailsData: [],
  viewRejectedSingleEarlyPaymentDetailsData: [],
  rejectedBulkEarlyPaymentDetailsData: [],
  getInvoiceId: null,
  viewAllUploadLoadDocsData: [],
  viewAllUploadLoadDocByIdData: {},
  allNewEarlyPaymentRequestData: [],
  invoiceIds: [],
  supplierIds: [],
  invoiceImport: [],
  earlyPaymentOverview: {},
  earlyPaymentVirtualAccounts: [],
  earlyPaymentByVa: {},
  closedMarketViewListSingleData: [],
  closedMarketViewListBulkData: [],
  fundRequestAllFundersClosedMarketData: [],
  closedMarketViewSingleDetailsData: [],
  closedMarketViewBulkDetailsData: [],
  closedMarketOverviewData: [],
  closedMarketTopTenData: [],
  earlyPaymentOfferOverview: {},
  earlyPaymentOfferVirtualAccounts: [],
  earlyPaymentOfferByVa: {},
  defaultLoans: {},
  allEarlyPaymentRequestRejectedSingleData: [],
  allEarlyPaymentRequestRejectedBulkData: [],
  allEarlyPaymentRequestProccessingSingleData: [],
  allEarlyPaymentRequestProcessingBulkData: [],
  withdrawProcessingSingleEarlyPaymentData: [],
  fetchUpcomingPayablesByVirtualAccountData: [],
  fetchOverDuePayablesByVirtualAccountData: [],
  transHistory: [],
  earlyPaymentOfferDetails: {},
  disbursementInvoiceDetails: {},
  fetchLienedPayablesByVirtualAccountData: [],
  buyerDetails: [],
  fetchDisbursementOverviewData: [],
  // buyerDasboardData: {},
};

export const getBuyersDashboard = createAsyncThunk(
  "buyer/buyersDasboard",
  buyersDasboard
);

export const getBuyerPendingAssociateRequest = createAsyncThunk(
  "buyer/getBuyerPendingAssociateRequest",
  buyersAssociatePendingRequest
);

export const acceptSupplierRequest = createAsyncThunk(
  "buyer/acceptSupplierRequest",
  buyerAcceptSupplierRequest
);

export const allBuyerAssociatedSuppliers = createAsyncThunk(
  "buyer/allBuyersAssociatedSuppliers",
  getAllBuyerAssociatedSuppliers
);

export const onboardingInviteSupplier = createAsyncThunk(
  "buyer/onboardingInviteSupplier",
  SupplierSendOnboardingInviteToFunderOrBuyer
);

export const buyerRequestToAssociateWithFunder = createAsyncThunk(
  "buyer/buyerRequestToAssociateWithFunder",
  buyerSendRequestToFunder
);

export const buyerRequestToAssociateWithSupplier = createAsyncThunk(
  "buyer/buyerRequestToAssociateWithSupplier",
  buyerSendRequestToSupplier
);

export const buyerOnboardingInviteToFunder = createAsyncThunk(
  "buyer/buyerOnboardingInviteToFunder",
  buyerSendOnboardingInviteToFunder
);

export const allBuyerAssociatedFunders = createAsyncThunk(
  "buyer/allBuyerAssociatedFunders",
  getAllBuyerAssociatedFunders
);

export const buyerSupplierDisassociate = createAsyncThunk(
  "buyer/buyerSupplierDisassociate",
  disassociateBuyerSupplier
);

export const buyerFunderDisassociate = createAsyncThunk(
  "buyer/buyerFunderDisassociate",
  disassociateBuyerFunder
);

export const buyerRejectRequestSupplier = createAsyncThunk(
  "buyer/buyerRejectRequestSupplier",
  buyerRejectSupplierRequest
);

export const getAllBuyerNotifications = createAsyncThunk(
  "buyer/getAllBuyerNotifications",
  allBuyerNotifications
);

export const getNotificationById = createAsyncThunk(
  "buyer/getNotificationById",
  notificationById
);

export const creatNewPfp = createAsyncThunk("buyer/creatNewPfp", createPfp);
export const cancelPFP = createAsyncThunk("buyer/cancelPfp", cancelPfpProgram);
export const stopPFP = createAsyncThunk("buyer/stopPfp", stopPfpProgram);

export const getCampaignOffersInvoices = createAsyncThunk(
  "buyer/getCampaingInvoices ",
  getCampaignInvoices
);

export const allBuyerPfp = createAsyncThunk(
  "buyer/allBuyerPfp",
  getAllBuyerPfp
);

export const viewMembersPfp = createAsyncThunk(
  "buyer/viewMembers",
  viewMembersData
);

export const allAgrementDocument = createAsyncThunk(
  "buyer/allAggrementDocument",
  getAllCampaignAgreement
);

export const allPfpOfferList = createAsyncThunk(
  "buyer/allBuyerPfp",
  getAllBuyerPfp
);

export const buyerApproveExitRequest = createAsyncThunk(
  "buyer/buyerApproveExitRequest",
  approveExitRequest
);

export const buyerDeclineExitRequest = createAsyncThunk(
  "buyer/buyerDeclineExitRequest",
  declineExitRequest
);

export const importInvoice = createAsyncThunk(
  "buyer/importInvoice",
  invoiceImport
);

export const buyerViewPfpById = createAsyncThunk(
  "buyer/buyerViewPfpById",
  viewBuyerPfpById
);

export const buyerViewPfpOffers = createAsyncThunk(
  "buyer/buyerViewPfpOffers",
  buyerGetOfferDetails
);

export const buyerViewPfpInvoiceDetails = createAsyncThunk(
  "buyer/buyerViewPfpInvoiceDetails",
  buyerViewOfferInvoiceDetails
);

export const allBuyerPfpInvoices = createAsyncThunk(
  "buyer/allBuyerPfpInvoices ",
  getAllBuyerPfpInvoices
);

export const allEarlyPayments = createAsyncThunk(
  "buyer/allEarlyPayments",
  getAllearlyPayments
);

//Ealry payment request section Approved
export const allEarlyPaymentRequestSingle = createAsyncThunk(
  "buyer/allEarlyPaymentRequestSingle",
  allearlyPaymentsRequestStatus
);

export const allEarlyPaymentRequestBulk = createAsyncThunk(
  "buyer/allEarlyPaymentRequestBulk",
  allearlyPaymentsRequestBulkStatus
);

//Ealry payment request section processing
export const allEarlyPaymentRequestProccessingSingle = createAsyncThunk(
  "buyer/allEarlyPaymentRequestPendingSingle",
  allearlyPaymentsRequestProcessingStatus
);

export const allEarlyPaymentRequestProcessingBulk = createAsyncThunk(
  "buyer/allEarlyPaymentRequestPendingBulk",
  allearlyPaymentsRequestProcessingBulkStatus
);

//Ealry payment request section Rejected
export const allEarlyPaymentRequestRejectedSingle = createAsyncThunk(
  "buyer/allEarlyPaymentRequestRejectedSingle",
  allearlyPaymentsRequestStatusRejected
);

export const allEarlyPaymentRequestRejectedBulk = createAsyncThunk(
  "buyer/allEarlyPaymentRequestRejectedBulk",
  allearlyPaymentsRequestBulkStatusRejected
);

//Ealry payment request  Pending
export const allEarlyPaymentRequestSinglePending = createAsyncThunk(
  "buyer/allEarlyPaymentRequestSingle",
  allPendingearlyPaymentsRequestStatus
);

//Ealry payment request  Pending
export const fundRequestAllFundersClosedMarket = createAsyncThunk(
  "buyer/fundRequestAllFundersClosedMarket",
  fundRequestAllFundersClosedMarketApi
);

export const allEarlyPaymentRequestBulkPending = createAsyncThunk(
  "buyer/allEarlyPaymentRequestBulk",
  allPendingearlyPaymentsRequestBulkStatus
);

// View Approved single early payment details
export const viewApprovedSingleEarlyPaymentDetails = createAsyncThunk(
  "buyer/viewSingleEarlyPaymentDetails",
  getApprovedSingleEarlyPaymentDetails
);

// View rejected single early payment details
export const viewRejectedSingleEarlyPaymentDetails = createAsyncThunk(
  "buyer/viewRejectedSingleEarlyPaymentDetails",
  getRejectedSingleEarlyPaymentDetails
);

//View Pending singleEarly payment details
export const pendingSingleEarlyPaymentDetails = createAsyncThunk(
  "buyer/pendingSingleEarlyPaymentDetails",
  getPendingSingleEarlyPaymentDetails
);

export const getUpdateDiscountRatePreview = createAsyncThunk(
  "buyer/getUpdateDiscountRatePreview",
  getUpdateDiscountRatePreviewApi
);

//View Pending bulk Early payment details
export const pendingBulkEarlyPaymentDetails = createAsyncThunk(
  "buyer/pendingBulkEarlyPaymentDetails",
  getPendingBulkEarlyPaymentDetails
);

export const updateBulkDiscountPecentageClosedMarket = createAsyncThunk(
  "buyer/updateBulkDiscountPecentageClosedMarket",
  updateBulkDiscountPecentageClosedMarketApi
);

//View approved bulk Early payment details
export const approvedBulkEarlyPaymentDetails = createAsyncThunk(
  "buyer/approvedBulkEarlyPaymentDetails",
  getApprovedBulkEarlyPaymentDetails
);

//View approved bulk Early payment details
export const rejectedBulkEarlyPaymentDetails = createAsyncThunk(
  "buyer/rejectedBulkEarlyPaymentDetails",
  getRejectedBulkEarlyPaymentDetails
);

//Approve single Early payment request
export const approveSingleEarlyPaymentRequest = createAsyncThunk(
  "buyer/approveSingleEarlyPaymentRequest",
  approveSingleEarlyPaymentRequestWallet
);

//Approve single Early payment request via funder
export const approveSingleEarlyPaymentRequestViaFunder = createAsyncThunk(
  "buyer/approveSingleEarlyPaymentRequestViaFunder",
  approveSingleEarlyPaymentRequestFunder
);

//Approve single Early payment request via Closed market
export const approveSingleEarlyPaymentRequestViaClosed = createAsyncThunk(
  "buyer/approveSingleEarlyPaymentRequestViaClosed",
  approveSingleEarlyPaymentRequestClosed
);

export const updateSingleDiscountRate = createAsyncThunk(
  "buyer/updateSingleDiscountRate",
  updateSingleEarlyDiscountRate
);

export const saveSingleDiscountRate = createAsyncThunk(
  "buyer/saveSingleDiscountRate",
  saveSingleEarlyDiscountRate
);

export const saveBulkDiscountRate = createAsyncThunk(
  "buyer/saveBulkDiscountRate",
  saveBulkEarlyDiscountRate
);

export const updateBulkDiscountRate = createAsyncThunk(
  "buyer/updateBulkDiscountRate",
  updateBulkEarlyDiscountRate
);

// View processing singleEarly payment details
export const processingSingleEarlyPaymentDetails = createAsyncThunk(
  "buyer/processingSingleEarlyPaymentDetails",
  getProcessingSingleEarlyPaymentDetails
);

export const withdrawProcessingSingleEarlyPayment = createAsyncThunk(
  "buyer/withdrawProcessingSingleEarlyPayment",
  withdrawProcessingSingleEarlyPaymentRequest
);

//Reject early payment for single Early payment details
export const rejectSingleEarlyPaymentRequest = createAsyncThunk(
  "buyer/rejectSingleEarlyPaymentRequest",
  rejectSingleEarlyPaymentRequestbyBuyer
);

//Closed market view list page
export const closedMarketViewListSingle = createAsyncThunk(
  "buyer/closedMarketViewListSingle",
  closedMarketViewSingleList
);

export const closedMarketViewListBulk = createAsyncThunk(
  "buyer/closedMarketViewListBulk",
  closedMarketViewBulkList
);

//Closed market view details page
export const closedMarketViewSingleDetails = createAsyncThunk(
  "buyer/closedMarketViewSingleDetails",
  closedMarketViewSingleDetailsPage
);

//Closed market view details page
export const closedMarketViewBulkDetails = createAsyncThunk(
  "buyer/closedMarketViewBulkDetails",
  closedMarketViewBulkDetailsPage
);

export const uploadDocument = createAsyncThunk(
  "buyer/documentUpload",
  documentUpload
);

export const editProfileBuyer = createAsyncThunk(
  "buyer/buyerUpdateUploadedDocument",
  buyerUpdateUploadedDocument
);
export const editProfileBuyer2 = createAsyncThunk(
  "buyer/documentUploadEdit",
  documentUploadEdit
);
export const editCompanyDetailsBuyer = createAsyncThunk(
  "buyer/companyDetailsBuyerEdit",
  companyDetailsBuyerEdit
);
export const viewAllUploadLoadDocs = createAsyncThunk(
  "buyer/getUploadedDocuments",
  getUploadedDocuments
);
export const viewAllUploadLoadDocById = createAsyncThunk(
  "buyer/ getUploadedDocumentById",
  getUploadedDocumentById
);

export const removeUploadedDocumentById = createAsyncThunk(
  "buyer/deleteUploadedDocumentById",
  deleteUploadedDocumentById
);
export const editUploadedDocumentById = createAsyncThunk(
  "buyer/updateUploadedDocumentById",
  updateUploadedDocumentById
);
export const allNewEarlyPaymentRequest = createAsyncThunk(
  "buyer/allNewEarlyPaymentRequest",
  getAllNewEarlyPaymentRequest
);

export const approveOrDeclineEarlyPayment = createAsyncThunk(
  "buyer/approveOrDeclineEarlyPayment",
  earlyPaymentApproveOrDecline
);

export const fetchAllFunders = createAsyncThunk(
  "buyer/fetchAllFunders",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getAllFunders();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const buyerCreateTransPin = createAsyncThunk(
  "buyer/createTransPin",
  createTransPin
);
export const buyerEditTransPin = createAsyncThunk(
  "buyer/editTransPinForBuyer",
  editTransPinForBuyer
);

export const fetchEarlyPaymentOverview = createAsyncThunk(
  "buyer/getEarlyPaymentRequestOverview",
  getEarlyPaymentRequestOverview
);

export const fetchDisbursementOverview = createAsyncThunk(
  "buyer/fetchDisbursementOverview",
  getDisbursemenmtOverview
);

export const fetchEarlyPaymentVa = createAsyncThunk(
  "buyer/getEarlyPaymentRequestVirtualAccouts",
  getEarlyPaymentRequestVirtualAccouts
);

export const fetchEarlyPaymentByVa = createAsyncThunk(
  "buyer/getEarlyPaymentByvirtualAccountId",
  getEarlyPaymentByvirtualAccountId
);

export const importEarlyPaymentToCloseMarket = createAsyncThunk(
  "buyer/importEarlyPaymentToCloseMarket",
  importEarlyPaymentToCloseMarketApi
);

export const getClosedMarketOverview = createAsyncThunk(
  "buyer/getClosedMarketOverview",
  getClosedMarketOverviewApi
);

export const getClosedMarketTopTen = createAsyncThunk(
  "buyer/getClosedMarketTopTen",
  getClosedMarketTopTenApi
);
export const fetchEarlyPaymentOfferOverview = createAsyncThunk(
  "buyer/getEarlyPaymentOfferOverview",
  getEarlyPaymentOfferOverview
);

export const fetchLienedPayablesByVirtualAccount = createAsyncThunk(
  "buyer/fetchLienedPayablesByVirtualAccount",
  getLienedPayablesByAccount
);

export const fetchUpcomingPayablesByVirtualAccount = createAsyncThunk(
  "buyer/fetchUpcomingPayablesByVirtualAccount",
  getUpcomingPayablesByAccount
);

export const fetchOverDuePayablesByVirtualAccount = createAsyncThunk(
  "buyer/fetchOverDuePayablesByVirtualAccount",
  getOverduePayablesByAccount
);

export const fetchTransactionHistory = createAsyncThunk(
  "buyer/getTransactionHistory",
  getTransactionHistory
);

export const confirmFundsTransfer = createAsyncThunk(
  "buyer/confirmFundsTransfer",
  confirmFundTransferSuccess
);

export const fetchEarlyPaymentOfferVa = createAsyncThunk(
  "buyer/getEarlyPaymentOfferVirtualAccouts",
  getEarlyPaymentOfferVirtualAccouts
);

export const fetchEarlyPaymentOfferByVa = createAsyncThunk(
  "buyer/getEarlyPaymentOfferByvirtualAccountId",
  getEarlyPaymentOfferByvirtualAccountId
);

export const fetchDefaultLoans = createAsyncThunk(
  "buyer/getDefaultLoan",
  getDefaultLoan
);

// fetchEarlyPaymentOfferByVa,fetchEarlyPaymentOfferVa,fetchEarlyPaymentOfferOverview,makePaymentNow

export const makePaymentNow = createAsyncThunk(
  "buyer/makePayment",
  makePayment
);

export const fetchEarlyPaymentOfferDetails = createAsyncThunk(
  "buyer/getEarlyPaymentOfferDetails",
  getEarlyPaymentOfferDetails
);
export const fetchDisburseInvoiceDetails = createAsyncThunk(
  "buyer/getDisbursementInvoiceDetails",
  getDisbursementInvoiceDetails
);
export const fetchBuyerDetails = createAsyncThunk(
  "buyer/getBuyerDetails",
  getBuyerDetails
);

export const buyerSlice = createSlice({
  name: "buyer",
  initialState,
  reducers: {
    getAssociatedSupplierId: (state, action) => {
      state.associatedSupplierId = action.payload;
    },
    getAssociatedFunderId: (state, action) => {
      state.associatedFunderId = action.payload;
    },
    getPfpId: (state, action) => {
      state.pfpId = action.payload;
    },
    getInvoiceId: (state, action) => {
      state.getInvoiceId = action.payload;
    },
    updatedDiscountData: (state, action) => {
      state.updatedDiscountDataResponse = action.payload;
    },
    updatedBulkDiscountData: (state, action) => {
      state.updatedBulkDiscountDataResponse = action.payload;
    },
    getInvoiceIds: (state, action) => {
      state.invoiceIds = action.payload;
    },
    getSupplierIds: (state, action) => {
      state.supplierIds = action.payload;
    },
    getInvoices: (state, action) => {
      state.invoiceImport = action.payload;
    },
    updateEarlyPaymentsData: (state, action) => {
      const newData = action.payload;
      state.allEarlyPAymentsData.push(...newData);
    },
    resetUpdatedBulkDiscountDataResponse: (state) => {
      state.updatedDiscountDataResponse = [];
      state.updatedBulkDiscountDataResponse = [];
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(getBuyersDashboard.pending, (state) => {
    //   state.isLoading = true;
    // });
    // builder.addCase(getBuyersDashboard.fulfilled, (state, action) => {
    //   state.isLoading = false;
    //   state.buyerDashboardData = action.payload;
    // });
    // builder.addCase(getBuyersDashboard.rejected, (state) => {
    //   state.isLoading = false;
    // });

    builder.addCase(getClosedMarketTopTen.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getClosedMarketTopTen.fulfilled, (state, action) => {
      state.isLoading = false;
      state.closedMarketTopTenData = action.payload;
    });
    builder.addCase(getClosedMarketTopTen.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getClosedMarketOverview.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getClosedMarketOverview.fulfilled, (state, action) => {
      state.isLoading = false;
      state.closedMarketOverviewData = action.payload;
    });
    builder.addCase(getClosedMarketOverview.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getBuyerPendingAssociateRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getBuyerPendingAssociateRequest.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.pendingAssociateRequestData = action.payload;
      }
    );
    builder.addCase(getBuyerPendingAssociateRequest.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(acceptSupplierRequest.pending, (state) => {
      state.associateLoading = true;
    });
    builder.addCase(acceptSupplierRequest.fulfilled, (state) => {
      state.associateLoading = false;
    });
    builder.addCase(acceptSupplierRequest.rejected, (state) => {
      state.associateLoading = false;
    });

    builder.addCase(allBuyerAssociatedSuppliers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allBuyerAssociatedSuppliers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.buyerAssociatedSuppliersData = action.payload;
    });
    builder.addCase(allBuyerAssociatedSuppliers.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(onboardingInviteSupplier.pending, (state) => {
      state.inviteLoading = true;
    });
    builder.addCase(onboardingInviteSupplier.fulfilled, (state) => {
      state.inviteLoading = false;
    });
    builder.addCase(onboardingInviteSupplier.rejected, (state) => {
      state.inviteLoading = false;
    });

    builder.addCase(getUpdateDiscountRatePreview.pending, (state) => {
      state.inviteLoading = true;
    });
    builder.addCase(getUpdateDiscountRatePreview.fulfilled, (state) => {
      state.inviteLoading = false;
    });
    builder.addCase(getUpdateDiscountRatePreview.rejected, (state) => {
      state.inviteLoading = false;
    });

    builder.addCase(buyerRequestToAssociateWithFunder.pending, (state) => {
      state.associateLoading = true;
    });
    builder.addCase(buyerRequestToAssociateWithFunder.fulfilled, (state) => {
      state.associateLoading = false;
    });
    builder.addCase(buyerRequestToAssociateWithFunder.rejected, (state) => {
      state.associateLoading = false;
    });
    builder.addCase(buyerRequestToAssociateWithSupplier.pending, (state) => {
      state.associateLoading = true;
    });
    builder.addCase(buyerRequestToAssociateWithSupplier.fulfilled, (state) => {
      state.associateLoading = false;
    });
    builder.addCase(buyerRequestToAssociateWithSupplier.rejected, (state) => {
      state.associateLoading = false;
    });
    builder.addCase(buyerOnboardingInviteToFunder.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(buyerOnboardingInviteToFunder.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(buyerOnboardingInviteToFunder.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(allBuyerAssociatedFunders.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allBuyerAssociatedFunders.fulfilled, (state, action) => {
      state.isLoading = false;
      state.buyerAssociatedFundersData = action.payload;
    });
    builder.addCase(allBuyerAssociatedFunders.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(buyerSupplierDisassociate.pending, (state) => {
      state.disAssociateLoading = true;
    });
    builder.addCase(buyerSupplierDisassociate.fulfilled, (state) => {
      state.disAssociateLoading = false;
    });
    builder.addCase(buyerSupplierDisassociate.rejected, (state) => {
      state.disAssociateLoading = false;
    });
    builder.addCase(buyerFunderDisassociate.pending, (state) => {
      state.disAssociateLoading = true;
    });
    builder.addCase(buyerFunderDisassociate.fulfilled, (state) => {
      state.disAssociateLoading = false;
    });
    builder.addCase(buyerFunderDisassociate.rejected, (state) => {
      state.disAssociateLoading = false;
    });
    builder.addCase(buyerRejectRequestSupplier.pending, (state) => {
      state.declineLoading = true;
    });
    builder.addCase(buyerRejectRequestSupplier.fulfilled, (state) => {
      state.declineLoading = false;
    });
    builder.addCase(buyerRejectRequestSupplier.rejected, (state) => {
      state.declineLoading = false;
    });

    builder.addCase(getAllBuyerNotifications.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllBuyerNotifications.fulfilled, (state, action) => {
      state.isLoading = false;
      state.notifications = action.payload;
    });
    builder.addCase(getAllBuyerNotifications.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(fundRequestAllFundersClosedMarket.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      fundRequestAllFundersClosedMarket.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.fundRequestAllFundersClosedMarketData = action.payload;
      }
    );
    builder.addCase(fundRequestAllFundersClosedMarket.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getNotificationById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getNotificationById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.notification = action.payload;
    });
    builder.addCase(getNotificationById.rejected, (state) => {
      state.isLoading = false;
    });

    // pfp section
    builder.addCase(creatNewPfp.pending, (state) => {
      state.createPfpLoading = true;
    });
    builder.addCase(creatNewPfp.fulfilled, (state) => {
      state.createPfpLoading = false;
    });
    builder.addCase(creatNewPfp.rejected, (state) => {
      state.createPfpLoading = false;
    });

    // Cancel PFP Program
    builder.addCase(cancelPFP.pending, (state) => {
      state.cancelPfpLoading = true;
    });
    builder.addCase(cancelPFP.fulfilled, (state) => {
      state.cancelPfpLoading = false;
    });
    builder.addCase(cancelPFP.rejected, (state) => {
      state.cancelPfpLoading = false;
    });

    // Stop PFP Program
    builder.addCase(stopPFP.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(stopPFP.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(stopPFP.rejected, (state) => {
      state.isLoading = false;
    });

    //get campaign offer invoices
    builder.addCase(getCampaignOffersInvoices.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCampaignOffersInvoices.fulfilled, (state, action) => {
      state.isLoading = false;
      state.offerInvoices = action.payload;
    });
    builder.addCase(getCampaignOffersInvoices.rejected, (state) => {
      state.isLoading = false;
    });

    // All buyer payable finance Program
    builder.addCase(allBuyerPfp.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allBuyerPfp.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allBuyerPfpData = action.payload;
    });
    builder.addCase(allBuyerPfp.rejected, (state) => {
      state.isLoading = false;
    });

    // All members in payable finance Program
    builder.addCase(viewMembersPfp.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(viewMembersPfp.fulfilled, (state, action) => {
      state.isLoading = false;
      state.viewMembersData = action.payload;
    });
    builder.addCase(viewMembersPfp.rejected, (state) => {
      state.isLoading = false;
    });

    //get all campaign funding agreement
    builder.addCase(allAgrementDocument.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allAgrementDocument.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allBuyerFundingAgreement = action.payload;
    });
    builder.addCase(allAgrementDocument.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(buyerApproveExitRequest.pending, (state) => {
      state.requestLoading = true;
    });
    builder.addCase(buyerApproveExitRequest.fulfilled, (state) => {
      state.requestLoading = false;
    });
    builder.addCase(buyerApproveExitRequest.rejected, (state) => {
      state.requestLoading = false;
    });

    builder.addCase(buyerDeclineExitRequest.pending, (state) => {
      state.declineLoading = true;
    });
    builder.addCase(buyerDeclineExitRequest.fulfilled, (state) => {
      state.declineLoading = false;
    });
    builder.addCase(buyerDeclineExitRequest.rejected, (state) => {
      state.declineLoading = false;
    });

    builder.addCase(importInvoice.pending, (state) => {
      state.importLoading = true;
    });
    builder.addCase(importInvoice.fulfilled, (state) => {
      state.importLoading = false;
    });
    builder.addCase(importInvoice.rejected, (state) => {
      state.importLoading = false;
    });

    builder.addCase(buyerViewPfpById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(buyerViewPfpById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.viewPfpByIdData = action.payload;
    });
    builder.addCase(buyerViewPfpById.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(buyerViewPfpOffers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(buyerViewPfpOffers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.viewPfpOffersData = action.payload;
    });
    builder.addCase(buyerViewPfpOffers.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(buyerViewPfpInvoiceDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(buyerViewPfpInvoiceDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.viewPfpOffersInvoiceData = action.payload;
    });
    builder.addCase(buyerViewPfpInvoiceDetails.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(allBuyerPfpInvoices.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allBuyerPfpInvoices.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allPfpInvoicesData = action.payload;
    });
    builder.addCase(allBuyerPfpInvoices.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(allEarlyPayments.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allEarlyPayments.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allEarlyPAymentsData = action.payload;
    });
    builder.addCase(allEarlyPayments.rejected, (state) => {
      state.isLoading = false;
    });

    //All Pending Early payment single
    builder.addCase(allEarlyPaymentRequestSingle.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allEarlyPaymentRequestSingle.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allEarlyPaymentRequestSingleData = action.payload;
    });
    builder.addCase(allEarlyPaymentRequestSingle.rejected, (state) => {
      state.isLoading = false;
    });

    //All processing Early payment single
    builder.addCase(
      allEarlyPaymentRequestProccessingSingle.pending,
      (state) => {
        state.isLoading = true;
      }
    );
    builder.addCase(
      allEarlyPaymentRequestProccessingSingle.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.allEarlyPaymentRequestProccessingSingleData = action.payload;
      }
    );
    builder.addCase(
      allEarlyPaymentRequestProccessingSingle.rejected,
      (state) => {
        state.isLoading = false;
      }
    );

    //All processing Early payment single
    builder.addCase(allEarlyPaymentRequestProcessingBulk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      allEarlyPaymentRequestProcessingBulk.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.allEarlyPaymentRequestProcessingBulkData = action.payload;
      }
    );
    builder.addCase(allEarlyPaymentRequestProcessingBulk.rejected, (state) => {
      state.isLoading = false;
    });

    //All Rejected Early payment single
    builder.addCase(allEarlyPaymentRequestRejectedSingle.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      allEarlyPaymentRequestRejectedSingle.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.allEarlyPaymentRequestRejectedSingleData = action.payload;
      }
    );
    builder.addCase(allEarlyPaymentRequestRejectedSingle.rejected, (state) => {
      state.isLoading = false;
    });

    //All Rejected Early payment Bulk
    builder.addCase(allEarlyPaymentRequestRejectedBulk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      allEarlyPaymentRequestRejectedBulk.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.allEarlyPaymentRequestRejectedBulkData = action.payload;
      }
    );
    builder.addCase(allEarlyPaymentRequestRejectedBulk.rejected, (state) => {
      state.isLoading = false;
    });

    //All Pending Early payment bulk
    builder.addCase(allEarlyPaymentRequestBulk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allEarlyPaymentRequestBulk.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allEarlyPaymentRequestBulkData = action.payload;
    });
    builder.addCase(allEarlyPaymentRequestBulk.rejected, (state) => {
      state.isLoading = false;
    });

    //View approved single Early payment details
    builder.addCase(viewApprovedSingleEarlyPaymentDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      viewApprovedSingleEarlyPaymentDetails.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.viewApprovedSingleEarlyPaymentDetailsData = action.payload;
      }
    );
    builder.addCase(viewApprovedSingleEarlyPaymentDetails.rejected, (state) => {
      state.isLoading = false;
    });

    //View rejected single Early payment details
    builder.addCase(viewRejectedSingleEarlyPaymentDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      viewRejectedSingleEarlyPaymentDetails.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.viewRejectedSingleEarlyPaymentDetailsData = action.payload;
      }
    );
    builder.addCase(viewRejectedSingleEarlyPaymentDetails.rejected, (state) => {
      state.isLoading = false;
    });

    //View pending single Early payment details
    builder.addCase(pendingSingleEarlyPaymentDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      pendingSingleEarlyPaymentDetails.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.pendingSingleEarlyPaymentDetailsData = action.payload;
      }
    );
    builder.addCase(pendingSingleEarlyPaymentDetails.rejected, (state) => {
      state.isLoading = false;
    });

    //View pending Bulk Early payment details
    builder.addCase(pendingBulkEarlyPaymentDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      pendingBulkEarlyPaymentDetails.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.pendingBulkEarlyPaymentDetailsData = action.payload;
      }
    );
    builder.addCase(pendingBulkEarlyPaymentDetails.rejected, (state) => {
      state.isLoading = false;
    });

    //Update Bulk discount rate for Early payment details
    builder.addCase(
      updateBulkDiscountPecentageClosedMarket.pending,
      (state) => {
        state.isLoading = true;
      }
    );
    builder.addCase(
      updateBulkDiscountPecentageClosedMarket.fulfilled,
      (state) => {
        state.isLoading = false;
      }
    );
    builder.addCase(
      updateBulkDiscountPecentageClosedMarket.rejected,
      (state) => {
        state.isLoading = false;
      }
    );

    //View approved Bulk Early payment details
    builder.addCase(approvedBulkEarlyPaymentDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      approvedBulkEarlyPaymentDetails.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.approvedBulkEarlyPaymentDetailsData = action.payload;
      }
    );
    builder.addCase(approvedBulkEarlyPaymentDetails.rejected, (state) => {
      state.isLoading = false;
    });

    //View rejected Bulk Early payment details
    builder.addCase(rejectedBulkEarlyPaymentDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      rejectedBulkEarlyPaymentDetails.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.rejectedBulkEarlyPaymentDetailsData = action.payload;
      }
    );
    builder.addCase(rejectedBulkEarlyPaymentDetails.rejected, (state) => {
      state.isLoading = false;
    });

    //approve single Early payment request
    builder.addCase(approveSingleEarlyPaymentRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(approveSingleEarlyPaymentRequest.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(approveSingleEarlyPaymentRequest.rejected, (state) => {
      state.isLoading = false;
    });

    //approve single Early payment request via wallet
    builder.addCase(
      approveSingleEarlyPaymentRequestViaFunder.pending,
      (state) => {
        state.isLoading = true;
      }
    );
    builder.addCase(
      approveSingleEarlyPaymentRequestViaFunder.fulfilled,
      (state) => {
        state.isLoading = false;
      }
    );
    builder.addCase(
      approveSingleEarlyPaymentRequestViaFunder.rejected,
      (state) => {
        state.isLoading = false;
      }
    );

    //approve single Early payment request via closed market
    builder.addCase(
      approveSingleEarlyPaymentRequestViaClosed.pending,
      (state) => {
        state.isLoading = true;
      }
    );
    builder.addCase(
      approveSingleEarlyPaymentRequestViaClosed.fulfilled,
      (state) => {
        state.isLoading = false;
      }
    );
    builder.addCase(
      approveSingleEarlyPaymentRequestViaClosed.rejected,
      (state) => {
        state.isLoading = false;
      }
    );

    //update discount rate for single Early payment details
    builder.addCase(updateSingleDiscountRate.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateSingleDiscountRate.fulfilled, (state, action) => {
      state.isLoading = false;
      state.updatedDiscountDataResponse = action.payload;
    });
    builder.addCase(updateSingleDiscountRate.rejected, (state) => {
      state.isLoading = false;
    });

    //save discount rate for single Early payment details
    builder.addCase(saveSingleDiscountRate.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(saveSingleDiscountRate.fulfilled, (state, action) => {
      state.isLoading = false;
      state.savedDiscountDataResponse = action.payload;
    });
    builder.addCase(saveSingleDiscountRate.rejected, (state) => {
      state.isLoading = false;
    });

    //update discount rate for Bulk Early payment details
    builder.addCase(updateBulkDiscountRate.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateBulkDiscountRate.fulfilled, (state, action) => {
      state.isLoading = false;
      state.updatedBulkDiscountDataResponse = action.payload;
    });
    builder.addCase(updateBulkDiscountRate.rejected, (state) => {
      state.isLoading = false;
    });

    //save discount rate for bulk Early payment details
    builder.addCase(saveBulkDiscountRate.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(saveBulkDiscountRate.fulfilled, (state, action) => {
      state.isLoading = false;
      state.savedBulkDiscountDataResponse = action.payload;
    });
    builder.addCase(saveBulkDiscountRate.rejected, (state) => {
      state.isLoading = false;
    });

    //View processing single Early payment details
    builder.addCase(processingSingleEarlyPaymentDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      processingSingleEarlyPaymentDetails.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.processingSingleEarlyPaymentDetailsData = action.payload;
      }
    );
    builder.addCase(processingSingleEarlyPaymentDetails.rejected, (state) => {
      state.isLoading = false;
    });

    //withdraw processing single Early payment details
    builder.addCase(withdrawProcessingSingleEarlyPayment.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      withdrawProcessingSingleEarlyPayment.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.withdrawProcessingSingleEarlyPaymentData = action.payload;
      }
    );
    builder.addCase(withdrawProcessingSingleEarlyPayment.rejected, (state) => {
      state.isLoading = false;
    });

    //rejecct single Early payment invoice
    builder.addCase(rejectSingleEarlyPaymentRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(rejectSingleEarlyPaymentRequest.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(rejectSingleEarlyPaymentRequest.rejected, (state) => {
      state.isLoading = false;
    });

    //View single list closed market page
    builder.addCase(closedMarketViewListSingle.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(closedMarketViewListSingle.fulfilled, (state, action) => {
      state.isLoading = false;
      state.closedMarketViewListSingleData = action.payload;
    });
    builder.addCase(closedMarketViewListSingle.rejected, (state) => {
      state.isLoading = false;
    });

    //View single list closed market page
    builder.addCase(closedMarketViewListBulk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(closedMarketViewListBulk.fulfilled, (state, action) => {
      state.isLoading = false;
      state.closedMarketViewListBulkData = action.payload;
    });
    builder.addCase(closedMarketViewListBulk.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(importEarlyPaymentToCloseMarket.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(importEarlyPaymentToCloseMarket.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(importEarlyPaymentToCloseMarket.rejected, (state) => {
      state.isLoading = false;
    });

    //View single details page closed market
    builder.addCase(closedMarketViewSingleDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      closedMarketViewSingleDetails.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.closedMarketViewSingleDetailsData = action.payload;
      }
    );
    builder.addCase(closedMarketViewSingleDetails.rejected, (state) => {
      state.isLoading = false;
    });

    //View bulk details page closed market
    builder.addCase(closedMarketViewBulkDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(closedMarketViewBulkDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.closedMarketViewBulkDetailsData = action.payload;
    });
    builder.addCase(closedMarketViewBulkDetails.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(uploadDocument.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(uploadDocument.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(uploadDocument.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(viewAllUploadLoadDocs.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(viewAllUploadLoadDocs.fulfilled, (state, action) => {
      state.isLoading = false;
      state.viewAllUploadLoadDocsData = action.payload;
    });
    builder.addCase(viewAllUploadLoadDocs.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(viewAllUploadLoadDocById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(viewAllUploadLoadDocById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.viewAllUploadLoadDocByIdData = action.payload;
    });
    builder.addCase(viewAllUploadLoadDocById.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(removeUploadedDocumentById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(removeUploadedDocumentById.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(removeUploadedDocumentById.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(editUploadedDocumentById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editUploadedDocumentById.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(editUploadedDocumentById.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(allNewEarlyPaymentRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allNewEarlyPaymentRequest.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allNewEarlyPaymentRequestData = action.payload;
    });
    builder.addCase(allNewEarlyPaymentRequest.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(approveOrDeclineEarlyPayment.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(approveOrDeclineEarlyPayment.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(approveOrDeclineEarlyPayment.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchAllFunders.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAllFunders.fulfilled, (state, action) => {
      state.isLoading = false;
      state.buyerAssociatedFundersData = action.payload;
    });
    builder.addCase(fetchAllFunders.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(buyerCreateTransPin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(buyerCreateTransPin.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(buyerCreateTransPin.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(fetchEarlyPaymentOverview.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchEarlyPaymentOverview.fulfilled, (state, action) => {
      state.isLoading = false;
      state.earlyPaymentOverview = action.payload;
    });
    builder.addCase(fetchEarlyPaymentOverview.rejected, (state) => {
      state.isLoading = false;
    });

    //disbursement buyer dashboard

    builder.addCase(fetchDisbursementOverview.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchDisbursementOverview.fulfilled, (state, action) => {
      state.isLoading = false;
      state.fetchDisbursementOverviewData = action.payload;
    });
    builder.addCase(fetchDisbursementOverview.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(fetchEarlyPaymentVa.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchEarlyPaymentVa.fulfilled, (state, action) => {
      state.isLoading = false;
      state.earlyPaymentVirtualAccounts = action.payload;
    });
    builder.addCase(fetchEarlyPaymentVa.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchEarlyPaymentByVa.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchEarlyPaymentByVa.fulfilled, (state, action) => {
      state.isLoading = false;
      state.earlyPaymentByVa = action.payload;
    });
    builder.addCase(fetchEarlyPaymentByVa.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(fetchEarlyPaymentOfferOverview.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      fetchEarlyPaymentOfferOverview.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.earlyPaymentOfferOverview = action.payload;
      }
    );
    builder.addCase(fetchEarlyPaymentOfferOverview.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(fetchLienedPayablesByVirtualAccount.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      fetchLienedPayablesByVirtualAccount.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.fetchLienedPayablesByVirtualAccountData = action.payload;
      }
    );
    builder.addCase(fetchLienedPayablesByVirtualAccount.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(fetchUpcomingPayablesByVirtualAccount.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      fetchUpcomingPayablesByVirtualAccount.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.fetchUpcomingPayablesByVirtualAccountData = action.payload;
      }
    );
    builder.addCase(fetchUpcomingPayablesByVirtualAccount.rejected, (state) => {
      state.isLoading = false;
    });

    //fetch overdue invoices for disbursement under payables
    builder.addCase(fetchOverDuePayablesByVirtualAccount.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      fetchOverDuePayablesByVirtualAccount.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.fetchOverDuePayablesByVirtualAccountData = action.payload;
      }
    );
    builder.addCase(fetchOverDuePayablesByVirtualAccount.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(fetchEarlyPaymentOfferVa.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchEarlyPaymentOfferVa.fulfilled, (state, action) => {
      state.isLoading = false;
      state.earlyPaymentOfferVirtualAccounts = action.payload;
    });
    builder.addCase(fetchEarlyPaymentOfferVa.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(fetchEarlyPaymentOfferByVa.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchEarlyPaymentOfferByVa.fulfilled, (state, action) => {
      state.isLoading = false;
      state.earlyPaymentOfferByVa = action.payload;
    });
    builder.addCase(fetchEarlyPaymentOfferByVa.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchDefaultLoans.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchDefaultLoans.fulfilled, (state, action) => {
      state.isLoading = false;
      state.defaultLoans = action.payload;
    });
    builder.addCase(fetchDefaultLoans.rejected, (state) => {
      state.isLoading = false;
    });

    //Buyer disbursement make payment
    builder.addCase(makePaymentNow.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(makePaymentNow.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(makePaymentNow.rejected, (state) => {
      state.isLoading = false;
    });

    // Fetch Transaction History State
    builder.addCase(fetchTransactionHistory.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchTransactionHistory.fulfilled, (state, action) => {
      state.isLoading = false;
      state.transHistory = action.payload;
    });
    builder.addCase(fetchTransactionHistory.rejected, (state) => {
      state.isLoading = false;
    });

    //Confirm fund transfer
    builder.addCase(confirmFundsTransfer.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(confirmFundsTransfer.fulfilled, (state, action) => {
      state.isLoading = false;
      state.confirmFundsTransferData = action.payload;
    });
    builder.addCase(confirmFundsTransfer.rejected, (state) => {
      state.isLoading = false;
    });

    //Fetch Early Payment Offer Details
    builder.addCase(fetchEarlyPaymentOfferDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      fetchEarlyPaymentOfferDetails.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.earlyPaymentOfferDetails = action.payload;
      }
    );
    builder.addCase(fetchEarlyPaymentOfferDetails.rejected, (state) => {
      state.isLoading = false;
    });

    //Fetch Disbursement Invoice Details
    builder.addCase(fetchDisburseInvoiceDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchDisburseInvoiceDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.disbursementInvoiceDetails = action.payload;
    });
    builder.addCase(fetchDisburseInvoiceDetails.rejected, (state) => {
      state.isLoading = false;
    });

    //Edit Company Buyer Details
    builder.addCase(editCompanyDetailsBuyer.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editCompanyDetailsBuyer.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(editCompanyDetailsBuyer.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(editProfileBuyer.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editProfileBuyer.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(editProfileBuyer.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(buyerEditTransPin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(buyerEditTransPin.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(buyerEditTransPin.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(fetchBuyerDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchBuyerDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.buyerDetails = action.payload;
    });
    builder.addCase(fetchBuyerDetails.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getBuyersDashboard.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getBuyersDashboard.fulfilled, (state, action) => {
      state.isLoading = false;
      state.buyerDashboardData = action.payload;
    });
    builder.addCase(getBuyersDashboard.rejected, (state) => {
      state.isLoading = false;
    });

    //  editTransPinForBuye
  },
});
export const {
  getAssociatedSupplierId,
  getAssociatedFunderId,
  getPfpId,
  getInvoiceId,
  updatedDiscountData,
  getInvoiceIds,
  getSupplierIds,
  getInvoices,
  updateEarlyPaymentsData,
  resetUpdatedBulkDiscountDataResponse,
} = buyerSlice.actions;
export default buyerSlice.reducer;