import Card from "shared/Card";

function DisbursementHeaderSecond({ activeTab, setActiveTab, selectedStatus, setSelectedStatus }) {
  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
    sessionStorage.setItem("activeTabDisbursementFunder", tabNumber);
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case "Liened":
        return { backgroundColor: "#06593D", color: "white" };
      case "Upcoming":
        return { backgroundColor: "#F08000", color: "white" };
      case "Overdue":
        return { backgroundColor: "red", color: "white" };
      default:
        return {};
    }
  };


  return (
    <Card
      style={{ background: "rgba(255, 255, 255, 0.53)" }}
      className="px-2 rounded-t-[5px] h-16 flex items-center justify-between border-b border-b-subtleGray"
    >
      <div className="flex h-full pl-4  items-center gap-8 overflow-x-auto space-x3">
        <div
          className={`cursor-pointer relative h-full pt-5   text-sm overflow-hidden  text-[#959595] ${activeTab === 1 ? " text-primaryColor font-bold" : "text-gray-400"
            }`}
          onClick={() => handleTabClick(1)}
        >
          <span className="text-center flex justify-center w-full">
            Overview
          </span>

          {activeTab === 1 && (
            <div className="absolute bottom-0 left-0 w-full  h-1  rounded bg-primaryColor transition-transform duration-300 ease-in-out"></div>
          )}
        </div>
        <div
          className={`cursor-pointer relative h-full pt-5   text-center text-sm overflow-hidden  text-[#959595] ${activeTab === 2 ? "text-primaryColor font-bold" : "text-gray-400"
            }`}
          onClick={() => handleTabClick(2)}
        >
          <span className="text-center flex justify-center w-full ">
            Early Payment Requests
          </span>
          {activeTab === 2 && (
            <div className="absolute bottom-0 left-0 w-full h-1  rounded bg-primaryColor transition-transform duration-300 ease-in-out"></div>
          )}
        </div>
        <div
          className={`cursor-pointer relative  h-full overflow-hidden  pt-5   text-sm text-[#959595] ${activeTab === 3 ? "text-primaryColor font-bold" : "text-gray-400"
            }`}
          onClick={() => handleTabClick(3)}
        >
          <span className="flex justify-center text-center w-full">
            Early Payment Offers
          </span>
          {activeTab === 3 && (
            <div className="absolute bottom-0 left-0 w-full h-1  rounded bg-primaryColor transition-transform duration-300 ease-in-out"></div>
          )}
        </div>

        <div
          className={`cursor-pointer relative  h-full overflow-hidden  pt-5   text-sm text-[#959595] ${activeTab === 4 ? "text-primaryColor font-bold" : "text-gray-400"
            }`}
          onClick={() => handleTabClick(4)}
        >
          <span className="flex justify-center text-center w-full">
            Payables
          </span>
          {activeTab === 4 && (
            <div className="absolute bottom-0 left-0 w-full h-1  rounded bg-primaryColor transition-transform duration-300 ease-in-out"></div>
          )}
        </div>

        <div
          className={`cursor-pointer relative  h-full overflow-hidden  pt-5   text-sm text-[#959595] ${activeTab === 5 ? "text-primaryColor font-bold" : "text-gray-400"
            }`}
          onClick={() => handleTabClick(5)}
        >
          <span className="flex justify-center text-center w-full">
            Loan Defaults
          </span>
          {activeTab === 5 && (
            <div className="absolute bottom-0 left-0 w-full h-1  rounded bg-primaryColor transition-transform duration-300 ease-in-out"></div>
          )}
        </div>

        <div
          className={`cursor-pointer relative   h-full pt-5   overflow-hidden  text-sm text-[#959595] ${activeTab === 6 ? "text-primaryColor font-bold" : "text-gray-400"
            }`}
          onClick={() => handleTabClick(6)}
        >
          <span className="text-center flex justify-center  w-full">
            Transaction History
          </span>
          {activeTab === 6 && (
            <div className="absolute bottom-0 left-0 w-full h-1  rounded bg-primaryColor transition-transform duration-300 ease-in-out"></div>
          )}
        </div>
      </div>
      <div>

        {activeTab === 4 && <div>
          <div>
            <span className="text-[14px]">Payment Status</span>
          </div>
          <div
            style={{
              border: `1px solid ${selectedStatus === "Liened"
                  ? "#06593D"
                  : selectedStatus === "Upcoming"
                    ? "#F08000"
                    : selectedStatus === "Overdue"
                      ? "red"
                      : "grey"
                }`,
              padding: "2px",
              borderRadius: "5px",
              display: "flex",
              gap: "10px",
              marginBottom: "5px"
            }}
          >
            {["Liened", "Upcoming", "Overdue"].map((status) => (
              <span
                key={status}
                style={{
                  padding: "2px 10px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  ...getStatusStyle(
                    selectedStatus === status ? status : null
                  ),
                }}
                onClick={() =>
                  setSelectedStatus(
                    selectedStatus === status ? "" : status
                  )
                }
              >
                {status}
              </span>
            ))}
          </div>
        </div>}

      </div>

    </Card>
  );
}

export default DisbursementHeaderSecond;
