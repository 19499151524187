import {
  PAIconDashboard,
  PAIconFunder,
  PAIconPaymnet,
  PAIconReceipt,
  PAIconSettings,
  PAIconSettlement,
  PAIconSupplierNAv,
  PAIconHelpSupport,
  PAIconRoleManger,
  PAIconPfp,
  PAIconPcm,
  PAIconFunderPfp,
  PAIconShop,
  PAIconPurse,
  PAIconPaymentExtension,
  PAIconWallet,
  PAIconCardReceive,
  PAIconIconPaymentSideBarIcon,
  PAIconCardFundPortfolio,
  PAIconFactoringMainIcon,
  PAIconFactoringOpenMarket,
  PAIconFactoringDirectFunding,
  PAIconWorkFlowIcon,
} from "assets/images/svgs";

export const supplierSidebarContent = [
  {
    route: "/dashboard",
    icon: <PAIconDashboard />,
    text: "Dashboard",
  },
  {
    route: "/accounts",
    icon: <PAIconSupplierNAv />,
    text: "Buyers",
  },
  {
    route: "/supplier-funders",
    icon: <PAIconFunder />,
    text: "Funders",
  },
  {
    route: "/invoice",
    icon: <PAIconReceipt />,
    text: "Invoicing",
    subMenu: [
      {
        route: "/invoice/invoice",
        icon: <PAIconIconPaymentSideBarIcon />,
        text: "Invoice",
      },
      {
        route: "/supplier-early-payments",
        icon: <PAIconPurse />,
        text: "Funding Request",
      },
    ],
  },
  {
    route: "/open-market",
    icon: <PAIconShop />,
    text: "Open Market",
  },
  {
    route: "/supplier-earlypayment",
    icon: <PAIconPfp />,
    text: "Early Payment Offers",
  },
  {
    route: "#",
    icon: <PAIconSettlement />,
    text: "Settlement",
  },
  {
    route: "/wallet",
    icon: <PAIconPaymnet />,
    text: "Wallet",
  },
  {
    route: "/payments",
    icon: <PAIconPfp />,
    text: "Payments",
    subMenu: [
      {
        route: "/reinbursement",
        icon: <PAIconIconPaymentSideBarIcon />,
        text: "Reinbusement",
      },
      {
        route: "/collections",
        icon: <PAIconPurse />,
        text: "Collections",
      },
    ],
  },

  {
    route: "/settings",
    icon: <PAIconSettings />,
    text: "Settings",
  },
];

export const buyerSidebarContent = [
  {
    route: "/dashboard",
    icon: <PAIconDashboard />,
    text: "Dashboard",
  },
  {
    route: "/buyer-suppliers",
    icon: <PAIconSupplierNAv />,
    text: "Suppliers",
  },
  {
    route: "/buyer-funders",
    icon: <PAIconFunder />,
    text: "Funders",
  },
  {
    route: "/invoice/invoice",
    icon: <PAIconReceipt />,
    text: "Invoice",
  },

  {
    route: "/payablefinancing",
    icon: <PAIconPfp />,
    text: "Payable Financing",
    subMenu: [
      {
        route: "/payablefinancing/overview",
        icon: <PAIconCardReceive />,
        text: "Early Payment Request",
      },

      {
        route: "/payablefinancing/payablefinanceProgram",
        icon: <PAIconWallet />,
        text: "PFP Campaign",
      },
    ],
  },
  // added by kenneth
  {
    route: "/factoring",
    icon: <PAIconFactoringMainIcon />,
    text: "Factoring",
    subMenu: [
      {
        route: "/factoring/openmarket",
        icon: <PAIconFactoringOpenMarket />,
        text: "Open Market",
      },

      {
        route: "/factoring/directfunding",
        icon: <PAIconFactoringDirectFunding />,
        text: "Direct Funding",
      },
    ],
  },
  //
  // {
  //   route: "#",
  //   icon: <PAIconPfp />,
  //   text: "Factoring",
  //   subMenu: [
  //     {
  //       route: "#",
  //       icon: <PAIconCardReceive />,
  //       text: "Open Market",
  //     },

  //     {
  //       route: "#",
  //       icon: <PAIconWallet />,
  //       text: "Direct Funding",
  //     },
  //   ],
  // },

  {
    route: "/closedmarket",
    icon: <PAIconPcm />,
    text: "Closed Market",
  },
  {
    route: "/settlement",
    icon: <PAIconSettlement />,
    text: "Settlement",
  },
  {
    route: "/wallet",
    icon: <PAIconPaymnet />,
    text: "Wallet",
  },

  {
    route: "/payments",
    icon: <PAIconPfp />,
    text: "Payments",
    subMenu: [
      {
        route: "/disbursement",
        icon: <PAIconCardReceive />,
        text: "Disbursement",
      },

      {
        route: "/reimbursement",
        icon: <PAIconPurse />,
        text: "Reimbursement",
      },
      {
        route: "/payment-extension",
        icon: <PAIconPaymentExtension />,
        text: "Payment Extension",
      },
    ],
  },

  {
    route: "/settings",
    icon: <PAIconSettings />,
    text: "Settings",
  },
];

export const funderSidebarContent = [
  {
    route: "/dashboard",
    icon: <PAIconDashboard />,
    text: "Dashboard",
  },
  {
    route: "/funder-buyers",
    icon: <PAIconSupplierNAv />,
    text: "Buyers",
  },
  {
    route: "/funder-suppliers",
    icon: <PAIconFunder />,
    text: "Suppliers",
  },

  //In progress
  {
    route: "/payablefinance",
    icon: <PAIconFunderPfp />,
    text: "Payable Financing",
    subMenu: [
      {
        route: "/payablefinance-earlypayment",
        icon: <PAIconWallet />,
        text: "Early Payment Request",
      },
      {
        route: "/payablefinancing-closedmarket",
        icon: <PAIconShop />,
        text: "Closed Market",
      },
      {
        route: "/payablefinancing-campaign",
        icon: <PAIconCardReceive />,
        text: "PFP Campaign",
      },
    ],
  },
  {
    route: "/factoring",
    icon: <PAIconPfp />,
    text: "Factoring",
    subMenu: [
      {
        route: "#",
        icon: <PAIconCardReceive />,
        text: "Closed Market",
      },

      {
        route: "/directfunding",
        icon: <PAIconWallet />,
        text: "Direct Funding",
      },
    ],
  },
  {
    route: "/funds&portfolio",
    icon: <PAIconCardFundPortfolio />,
    text: "Funds & Portfolio",
  },
  {
    route: "/open-market",
    icon: <PAIconShop />,
    text: "Open Market",
  },
  {
    route: "/invoice/invoice",
    icon: <PAIconReceipt />,
    text: "Invoice",
  },
  {
    route: "#",
    icon: <PAIconSettlement />,
    text: "Settlement",
  },
  {
    route: "/wallet",
    icon: <PAIconPaymnet />,
    text: "Wallet",
  },

  {
    route: "/disbursement/",
    icon: <PAIconFunderPfp />,
    text: "Payments",
    subMenu: [
      {
        route: "/disbursement",
        icon: <PAIconCardReceive />,
        text: "Disbursement",
      },
      {
        route: "/collections",
        icon: <PAIconPurse />,
        text: "Collections",
      },
      {
        route: "/payment-extension",
        icon: <PAIconPaymentExtension />,
        text: "Payment Extension",
      },
    ],
  },

  {
    route: "/settings",
    icon: <PAIconSettings />,
    text: "Settings",
  },
];

export const adminSideMenu = [
  {
    route: "/role_manager",
    icon: <PAIconRoleManger />,
    text: "Role Manager",
  },
];

export const generalSideMenu = [
  {
    route: "/work-flow-management",
    icon: <PAIconWorkFlowIcon />,
    text: (
      <div className=" flex  justify-center gap-2">
        <div className=" justify-center items-center flex">WorkFlow</div>
        <div className=" justify-center items-center flex">
          <div className="w-[23px] h-[23px]  bg-white rounded-[100%]  justify-center items-center flex">
            <div className="text-black text-xs font-medium  ">6</div>
          </div>
        </div>
      </div>
    ),
  },
  {
    route: "/role_manager",
    icon: <PAIconRoleManger />,
    text: "Role Manager",
  },
  {
    route: "/support",
    icon: <PAIconHelpSupport />,
    text: "Help & Support",
  },
];
