import {
  PAIconArrowDownGreen,
  PAIconArrowLeftMini,
  PAIconCautionRole,
  PAIconEditGreen,
  PAIconInvoiceIcon,
} from "assets/images/svgs";
import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import {
  allPermissionAssigned,
  roleById,
} from "appstate/roleManager/roleManagerSlice";
import LoadingScreen from "shared/LoadingScreen";
import DashboardLayout from "layout/dashboardlayout";
import { useNavigate, useParams } from "react-router-dom";
import EditRoleModal from "./modals/edithrole/EditRoleModal";

function ViewRoleFullDetails({
  toggleRoleFullDetailsOff,
  setToggleEditRolePermissionModal,
  selectedId,
}) {
  const { roleByIdData } = useSelector((state) => state?.roleManager);
  const idData = roleByIdData?.data;
  const { id } = useParams();
  const [editRolePermission, setEditRolePermission] = useState(false);
  return (
    <DashboardLayout
      section="Role Manager"
      subHeader="Manage all your users and roles"
    >
      <div className="h-full w-full">
        <div className="flex flex-col gap-6 pb-8 overflow-auto bg-white rounded-lg w-full h-full">
          <>
            <ViewRoleManagerHeader
              toggleRoleFullDetailsOff={toggleRoleFullDetailsOff}
            />
            <ViewRoleManagerContent
              id={id}
              setEditRolePermission={setEditRolePermission}
            />
          </>
        </div>
      </div>
      <AnimatePresence>
        {editRolePermission && (
          <EditRoleModal
            id={id}
            closeModal={() => setEditRolePermission(false)}
            data={idData}
          />
        )}
      </AnimatePresence>
    </DashboardLayout>
  );
}

function ViewRoleManagerHeader() {
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate("/role_manager");
  };
  return (
    <div className="flex flex-col gap-4 sticky top-0 bg-[#FAFAFA] rounded-t-lg justify-between px-6 py-6">
      <div
        onClick={handleBackClick}
        className="flex gap-1 text-[15px] text-[#7A7A7A] items-center cursor-pointer"
      >
        <PAIconArrowLeftMini />
        <span>back</span>
      </div>
      <div className="flex flex-col gap-1">
        <span className="text-sm font-[500]">Details</span>
        <span className="text-xs">Basic information about the role</span>
      </div>
    </div>
  );
}

function ViewRoleManagerContent({ setEditRolePermission, id }) {
  const dispatch = useDispatch();
  const { roleByIdData, roleId, isLoading } = useSelector(
    (state) => state?.roleManager
  );
  const [activeSubheader, setActiveSubheader] = useState(null);
  const idData = roleByIdData?.data;
  const { allPermissionAssignedData } = useSelector(
    (state) => state?.roleManager
  );
  const assignedPermissions = allPermissionAssignedData?.data;
  const handleToggleSubheader = (subheaderId) => {
    setActiveSubheader((prev) => (prev === subheaderId ? null : subheaderId));
  };

  useEffect(() => {
    dispatch(roleById(id));
    dispatch(allPermissionAssigned(id));
  }, [dispatch, roleId, id]);

  const handleRoleEditClick = () => {
    // dispatch(getRoleId(id));
    setEditRolePermission(true);
  };

  const SubHeaders = ({ title, permissions, subheaderId }) => (
    <div className="flex flex-col gap-2">
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <div
            onClick={() => handleToggleSubheader(subheaderId)}
            className="flex py-2.5 cursor-pointer justify-between items-center px-7 bg-[#F9F9F9] w-full border-t border-b border-[#DADADA]"
          >
            <span className="font-bold">{title}</span>
            <motion.div
              animate={{ rotate: activeSubheader === subheaderId ? 180 : 0 }}
              transition={{ duration: 0.3 }}
              className="cursor-pointer"
            >
              <PAIconArrowDownGreen />
            </motion.div>
          </div>

          <AnimatePresence>
            {activeSubheader === subheaderId && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
                className="flex flex-col py-2.5 px-7 bg-white w-full"
              >
                {permissions?.subPermissions ? (
                  permissions?.availablePermissions?.map(
                    (permission, index) => (
                      <div key={index} className="py-2">
                        {permission.subPermissions?.length > 0 ? (
                          permission.subPermissions.map((sub, subIndex) => (
                            <span key={subIndex} className="text-[15.5px] py-3">
                              {sub.subPermissionName}
                            </span>
                          ))
                        ) : (
                          <div className="p-2.5 text-xs flex text-center w-full justify-center items-center font-medium">
                            No Subpermission Available
                          </div>
                        )}
                      </div>
                    )
                  )
                ) : (
                  <div className="p-2.5 flex text-center w-full justify-center items-center font-medium">
                    No Subpermission Available
                  </div>
                )}
              </motion.div>
            )}
          </AnimatePresence>
        </>
      )}
    </div>
  );

  return (
    <div className="flex flex-col gap-12 bg-white justify-between px-12 md:px-14 lg:px-20">
      <section className="flex flex-col gap-4">
        <div className="flex justify-between">
          <div className="flex flex-col gap-2">
            <span className="text-sm text-[#7A7A7A]">Role Title</span>
            <span className="text-sm">{idData?.roleName}</span>
          </div>
          <div className="w-1/2 flex flex-col gap-2">
            <span className="text-sm text-[#7A7A7A]">
              No of Assigned Permissions
            </span>
            <span className="text-sm">{idData?.noOfPermissionsAssigned}</span>
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <span className="text-sm text-[#7A7A7A]">Description</span>
          <span className="text-sm">{idData?.description || "N/A"}</span>
        </div>

        <div className="flex flex-col gap-2">
          <span className="text-sm text-[#7A7A7A]">Created</span>
          <span className="text-sm">{idData?.createdDate?.slice(0, 11)}</span>
        </div>
      </section>

      <section className="flex flex-col gap-6">
        <header className="flex justify-between">
          <span className="text-sm font-bold">Assigned Permissions</span>
          <button
            onClick={() => handleRoleEditClick()}
            className="flex items-center justify-center gap-1 border rounded-[5px] border-[#07593D] px-4 py-2"
          >
            <PAIconEditGreen />
            {
              <span className="text-xs">
                {assignedPermissions?.assignedPermissions?.length < 1
                  ? "Assign"
                  : "Edit"}
              </span>
            }
          </button>
        </header>

        <div>
          {assignedPermissions?.assignedPermissions.length > 0 ? (
            <div>
              {assignedPermissions?.assignedPermissions?.map((data, index) => (
                <SubHeaders
                  key={index}
                  permissions={assignedPermissions}
                  title={data?.name}
                  subheaderId={data.id}
                />
              ))}
            </div>
          ) : (
            <div className="flex items-center gap-3 flex-col">
              <div
                className="text-sm flex justify-center font-semibold
          "
              >
                No Assigned Permissions
              </div>
              <PAIconInvoiceIcon className="animate-pulse" />

              <div className="flex items-center gap-1 text-xs">
                <PAIconCautionRole />
                <span className="text-[#7A7A7A]">
                  Kindly Assign some permissions to this role{" "}
                </span>
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
}

export default ViewRoleFullDetails;
