import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  funderOpenMarket,
  funderTransactions,
  getAllFunders,
  searchFunder,
} from "appstate/api/funder";
import {
  closedMarketWonBids,
  closeMarketPlaceBid,
  getBidsCurrentlyLeading,
  getBidsNotCurrentlyLeading,
} from "appstate/api/funder/closedmarket";
import {
  getAllClosedMarketByStatus,
  getAllClosedMarketBySingleStatus,
  getAllClosedMarketInvoiceById,
  getAllClosedMarketDashBoard,
  getAllClosedMarketWonBid,
  getAllClosedMarketIngoingBidDetails,
  closeMarketUpdateBid,
  closeMarketCancelBid,
} from "appstate/api/funder/closedmarket";
import { funderDashboard } from "appstate/api/funder/dashboard";
import {
  directFundingApproveOrDecline,
  getNewDirectFunding,
} from "appstate/api/funder/directFunding";
import {
  allFunderNotifications,
  notificationById,
} from "appstate/api/funder/notification";
import {
  approveDirectFundingRequestFromBuyer,
  funderEarlyPaymentBulkApprovedDetails,
  funderEarlyPaymentBulkApprovedList,
  funderEarlyPaymentBulkList,
  funderEarlyPaymentBulkPendingDetails,
  funderEarlyPaymentBulkRejectedDetails,
  funderEarlyPaymentBulkRejectedList,
  funderEarlyPaymentSingleApprovedDetails,
  funderEarlyPaymentSingleApprovedList,
  funderEarlyPaymentSingleList,
  funderEarlyPaymentSinglePendingDetails,
  funderEarlyPaymentSingleRejectedDetails,
  funderEarlyPaymentSingleRejectedList,
  fundSelectedPfpInvoice,
  getAllFunderPfpInvoices,
  getEarlyPaymentFunderConfigApi,
  getEarlyPaymentFunderSingleConfigApi,
  getFullFunderPfp,
  getFunderDashBoardData,
  pfpViewAgreementDetailsById,
  rejectSingleEarlyPaymentRequestbyFunder,
  saveBulkEffectiveRate,
  saveSingleEffectiveRate,
  updateBulkEffectiveRate,
  updateSingleEffectiveRate,
} from "appstate/api/funder/pfp";
import {
  acceptToJoinPfp,
  getAllFunderPendingPfp,
  getAllFunderPfp,
  pfpRequestDecline,
  pfpViewById,
  sendRequestToLeavePfp,
} from "appstate/api/funder/pfp";
import {
  funderAcceptBuyerRequest,
  funderAcceptSupplierRequest,
  funderAssociateWithBuyer,
  funderAssociatedBuyers,
  funderAssociatedSuppliers,
  funderDeclineBuyerRequest,
  funderDeclineSupplierRequest,
  funderDisassociateBuyer,
  funderDisassociateSupplier,
  funderSendAssociateRequestToSupplier,
  funderSendOnboardingInviteToBuyers,
  funderSendOnboardingInviteToSupplier,
  pendingRequest,
} from "appstate/api/funder/relationshipManagement";
import {
  createTransPin,
  editTransPinForFunder,
} from "appstate/api/transactionPin";
import {
  companyDetailsFunderEdit,
  funderUpdateUploadedDocument,
} from "appstate/api/documentUpload";
import { getfunderDetails } from "appstate/api/documentUpload";
// import {} from "appstate/api/documentUpload";

const initialState = {
  associateLoading: false,
  isLoading: false,
  funderDashboardData: [],
  funderTransactionsData: [],
  funderOpenMarketData: [],
  allFundersData: [],
  funderPendingRequestData: [],
  funderAssociatedSuppliersData: [],
  funderAssociatedBuyersData: [],
  associatedBuyerId: null,
  associatedSupplierId: null,
  disAssociateLoading: false,
  declineLoading: false,
  inviteLoading: false,
  notifications: [],
  notification: null,
  allFunderPendingPfpdpData: [],
  pfpId: null,
  getInvoiceId: null,
  notificationId: null,
  viewPfpByIdData: [],
  dataByIdLoading: false,
  allFunderPfpData: [],
  fullFunderPfpData: [],
  viewAgreementDetailsByIdData: null,
  requestToLeaveLoading: null,
  allPfpInvoicesData: null,
  newDirectFundingData: [],
  directFundingInvoiceId: null,
  directFundingApproveLoading: false,
  closeMarketactiveTab: null,
  funderClosedMarketDataByStatus: [],
  allClosedMarketWonBidsData: [],
  funderClosedMarketDataBySingleStatus: [],
  funderClosedMarketDetails: [],
  closedMarketDashBoard: [],
  closedMarketWonBid: [],
  ongoingBidDetails: [],
  leadingBids: [],
  notInLeadingBids: [],
  funderEarlyPaymentViewSingleListsData: [],
  funderEarlyPaymentViewBulkListData: [],
  funderEarlyPaymentViewSingleRejectedListData: [],
  funderEarlyPaymentViewBulkRejectedListData: [],
  funderEarlyPaymentViewSingleApprovedListData: [],
  funderEarlyPaymentViewBulkApprovedListData: [],
  funderEarlyPaymentViewBulkPendingDetailsData: [],
  approveDirectFundingRequestData: [],
  funderEarlyPaymentViewBulkApprovedDetailsData: [],
  funderEarlyPaymentViewSinglePendingDetailsData: [],
  funderEarlyPaymentViewSingleRejectedDetailsData: [],
  funderEarlyPaymentViewSingleApprovedDetailsData: [],
  funderEarlyPaymentViewBulkRejectedDetailsData: [],
  getEarlyPaymentFunderConfigData: [],
  updatedEffectiveDataResponse: [],
  savedEffectiveDataResponse: [],
  updatedBulkEffectiveDataResponse: [],
  savedBulkEffectiveDataResponse: [],
  getEarlyPaymentFunderSingleConfigData: [],
  funderDetails: [],
  funderDashBoard: {},
};
// funderUpdateUploadedDocument;

export const funderDashboardStats = createAsyncThunk(
  "funder/funderDashboardStats",
  funderDashboard
);

export const getFunderTransactions = createAsyncThunk(
  "funder/getFunderTransactions",
  funderTransactions
);

export const getFunderOpenMarket = createAsyncThunk(
  "funder/getFunderOpenMarket",
  funderOpenMarket
);

export const allFunders = createAsyncThunk("funder/allFunders", getAllFunders);

export const funderSearch = createAsyncThunk(
  "funder/funderSearch",
  searchFunder
);

export const viewFunderPendingRequest = createAsyncThunk(
  "funder/viewFunderPendingRequest",
  pendingRequest
);

//  getFunderDashBoardData
export const getEarlyPaymentFunderConfig = createAsyncThunk(
  "funder/getEarlyPaymentFunderConfig",
  getEarlyPaymentFunderConfigApi
);

export const fetchFunderDashBoardData = createAsyncThunk(
  "funder/getFunderDashBoardData",
  getFunderDashBoardData
);

export const getEarlyPaymentFunderSingleConfig = createAsyncThunk(
  "funder/getEarlyPaymentFunderSingleConfig",
  getEarlyPaymentFunderSingleConfigApi
);

export const acceptSupplierRequestFunder = createAsyncThunk(
  "funder/acceptSupplierRequestFunder",
  funderAcceptSupplierRequest
);

export const acceptBuyerRequestFunder = createAsyncThunk(
  "funder/acceptBuyerRequestFunder",
  funderAcceptBuyerRequest
);

export const getFunderAssociatedSuppliers = createAsyncThunk(
  "funder/getFunderAssociatedSuppliers ",
  funderAssociatedSuppliers
);

export const associateFunderWithSupplier = createAsyncThunk(
  "funder/associateFunderWithSupplier",
  funderSendAssociateRequestToSupplier
);

export const funderOnboardingInviteToSupplier = createAsyncThunk(
  "funder/funderOnboardingInviteToSupplier",
  funderSendOnboardingInviteToSupplier
);

export const funderOnboardingInviteToBuyers = createAsyncThunk(
  "funder/funderOnboardingInviteToBuyers",
  funderSendOnboardingInviteToBuyers
);

export const associateFunderWithBuyer = createAsyncThunk(
  "funder/associateFunderWithBuyer",
  funderAssociateWithBuyer
);

export const getFunderAssociatedBuyers = createAsyncThunk(
  "funder/getFunderAssociatedBuyers",
  funderAssociatedBuyers
);

export const declineSupplierRequestFunder = createAsyncThunk(
  "funder/declineSupplierRequestFunder",
  funderDeclineSupplierRequest
);

export const declineBuyerRequestFunder = createAsyncThunk(
  "funder/declineBuyerRequestFunder",
  funderDeclineBuyerRequest
);

export const disassociateBuyerFromFunder = createAsyncThunk(
  "funder/disassociateBuyerFromFunder",
  funderDisassociateBuyer
);

export const disassociateSupplierFromFunder = createAsyncThunk(
  "funder/disassociateSupplierFromFunder",
  funderDisassociateSupplier
);

export const getAllFunderNotifications = createAsyncThunk(
  "funder/getAllFunderNotifications",
  allFunderNotifications
);

export const getFunderNotificationById = createAsyncThunk(
  "funder/getFunderNotificationById",
  notificationById
);

export const allFunderPendingPfpRequest = createAsyncThunk(
  "funder/allFunderPendingPfpRequest",
  getAllFunderPendingPfp
);

export const viewPfpById = createAsyncThunk("funder/viewPfpById", pfpViewById);

export const acceptPfp = createAsyncThunk("funder/acceptPfp", acceptToJoinPfp);

export const declinePfpRequest = createAsyncThunk(
  "funder/declinePfpRequest",
  pfpRequestDecline
);

export const fundPfpInvoice = createAsyncThunk(
  "funder/fundPfpInvoice",
  fundSelectedPfpInvoice
);

export const allFunderPfp = createAsyncThunk(
  "funder/allFunderPfp",
  getAllFunderPfp
);

export const fullFunderPfp = createAsyncThunk(
  "funder/fullFunderPfp",
  getFullFunderPfp
);

export const viewAgreementDetailsById = createAsyncThunk(
  "funder/viewAgreementDetailsById",
  pfpViewAgreementDetailsById
);

export const requestToLeavePfp = createAsyncThunk(
  "funder/requestToLeavePfp",
  sendRequestToLeavePfp
);

export const allFunderPfpInvoices = createAsyncThunk(
  "funder/allFunderPfpInvoices ",
  getAllFunderPfpInvoices
);

export const allNewDirectFunding = createAsyncThunk(
  "funder/allNewDirectFunding",
  getNewDirectFunding
);

export const approveOrDeclineDirectFunding = createAsyncThunk(
  "funder/approveOrDeclineDirectFunding",
  directFundingApproveOrDecline
);
export const funderCreateTransPin = createAsyncThunk(
  "funder/createTransPin",
  createTransPin
);

export const funderFetchAllClosedMarketByStatus = createAsyncThunk(
  "funder/getAllClosedMarketByStatus",
  getAllClosedMarketByStatus
);
export const funderFetchAllClosedMarketBySingleStatus = createAsyncThunk(
  "funder/getAllClosedMarketBySingleStatus",
  getAllClosedMarketBySingleStatus
);
export const funderFetchInvoiceDetails = createAsyncThunk(
  "funder/getAllClosedMarketInvoiceById",
  getAllClosedMarketInvoiceById
);
export const funderFetchClosedMarketDashBoard = createAsyncThunk(
  "funder/getAllClosedMarketDashBoard",
  getAllClosedMarketDashBoard
);
export const funderFetchClosedMarketWonBids = createAsyncThunk(
  "funder/getAllClosedMarketWonBid",
  getAllClosedMarketWonBid
);

export const funderCloseMarketUpdateBid = createAsyncThunk(
  "funder/funderCloseMarketUpdateBid",
  closeMarketUpdateBid
);

export const funderCloseMarketCancelBid = createAsyncThunk(
  "funder/funderCloseMarketCancelBid",
  closeMarketCancelBid
);

export const allClosedMarketWonBids = createAsyncThunk(
  "funder/allClosedMarketWonBids",
  closedMarketWonBids
);

export const placeCloseMarketBid = createAsyncThunk(
  "openMarket/placeCloseMarketBid",
  closeMarketPlaceBid
);

export const fetchAllClosedMarketIngoingBidDetails = createAsyncThunk(
  "funder/getAllClosedMarketIngoingBidDetails",
  getAllClosedMarketIngoingBidDetails
);

export const fetchBidsInLead = createAsyncThunk(
  "funder/getBidsCurrentlyLeading",
  getBidsCurrentlyLeading
);
export const fetchBidsNotInLead = createAsyncThunk(
  "funder/getBidsNotCurrentlyLeading",
  getBidsNotCurrentlyLeading
);

export const funderEarlyPaymentViewSingleList = createAsyncThunk(
  "funder/funderEarlyPaymentViewSingleList",
  funderEarlyPaymentSingleList
);

export const funderEarlyPaymentViewBulkList = createAsyncThunk(
  "funder/funderEarlyPaymentViewBulkList",
  funderEarlyPaymentBulkList
);

export const funderEarlyPaymentViewSingleRejectedList = createAsyncThunk(
  "funder/funderEarlyPaymentViewSingleRejectedList",
  funderEarlyPaymentSingleRejectedList
);

export const funderEarlyPaymentViewBulkRejectedList = createAsyncThunk(
  "funder/funderEarlyPaymentViewBulkRejectedList",
  funderEarlyPaymentBulkRejectedList
);

export const funderEarlyPaymentViewSingleApprovedList = createAsyncThunk(
  "funder/funderEarlyPaymentViewSingleApprovedList",
  funderEarlyPaymentSingleApprovedList
);

export const funderEarlyPaymentViewBulkApprovedList = createAsyncThunk(
  "funder/funderEarlyPaymentViewBulkApprovedList",
  funderEarlyPaymentBulkApprovedList
);

export const funderEarlyPaymentViewSinglePendingDetails = createAsyncThunk(
  "funder/funderEarlyPaymentViewSinglePendingDetails",
  funderEarlyPaymentSinglePendingDetails
);

export const funderEarlyPaymentViewBulkPendingDetails = createAsyncThunk(
  "funder/funderEarlyPaymentViewBulkPendingDetails",
  funderEarlyPaymentBulkPendingDetails
);

export const funderEarlyPaymentViewSingleApprovedDetails = createAsyncThunk(
  "funder/funderEarlyPaymentViewSingleApprovedDetails",
  funderEarlyPaymentSingleApprovedDetails
);

export const funderEarlyPaymentViewBulkApprovedDetails = createAsyncThunk(
  "funder/funderEarlyPaymentViewBulkApprovedDetails",
  funderEarlyPaymentBulkApprovedDetails
);

export const approveDirectFundingRequest = createAsyncThunk(
  "funder/approveDirectFundingRequest",
  approveDirectFundingRequestFromBuyer
);

//Reject early payment for single Early payment details
export const rejectSingleEarlyPaymentRequest = createAsyncThunk(
  "funder/rejectSingleEarlyPaymentRequest",
  rejectSingleEarlyPaymentRequestbyFunder
);

export const funderEarlyPaymentViewSingleRejectedDetails = createAsyncThunk(
  "funder/funderEarlyPaymentViewSingleRejectedDetails",
  funderEarlyPaymentSingleRejectedDetails
);

export const funderEarlyPaymentViewBulkRejectedDetails = createAsyncThunk(
  "funder/funderEarlyPaymentViewBulkRejectedDetails",
  funderEarlyPaymentBulkRejectedDetails
);

export const updateSingleEffectiveDisocunt = createAsyncThunk(
  "buyer/updateSingleEffectiveDisocunt",
  updateSingleEffectiveRate
);

export const saveSingleEffectiveDiscount = createAsyncThunk(
  "buyer/saveSingleEffectiveDiscount",
  saveSingleEffectiveRate
);

export const updateBulkEffectiveDisocunt = createAsyncThunk(
  "buyer/updateBulkEffectiveDisocunt",
  updateBulkEffectiveRate
);

export const saveBulkEffectiveDiscount = createAsyncThunk(
  "buyer/saveBulkEffectiveDiscount",
  saveBulkEffectiveRate
);

export const editCompanyDetailsFunder = createAsyncThunk(
  "funder/companyDetailsFunderEdit",
  companyDetailsFunderEdit
);
export const editFunderProfile = createAsyncThunk(
  "funder/funderUpdateUploadedDocument",
  funderUpdateUploadedDocument
);

export const funderEditTransPin = createAsyncThunk(
  "buyer/editTransPinForFunder",
  editTransPinForFunder
);
export const fecthFunderDetails = createAsyncThunk(
  "funder/getfunderDetails",
  getfunderDetails
);

//closedmarket Transactions API Call

export const funderSlice = createSlice({
  name: "funder",
  initialState,
  reducers: {
    getFunderAssociatedBuyerId: (state, action) => {
      state.associatedBuyerId = action.payload;
    },
    setClosedMarketActiveTab: (state, action) => {
      state.closeMarketactiveTab = action.payload;
    },
    getFunderAssociatedSupplierId: (state, action) => {
      state.associatedSupplierId = action.payload;
    },
    getPfpId: (state, action) => {
      state.pfpId = action.payload;
    },
    getInvoiceId: (state, action) => {
      state.getInvoiceId = action.payload;
    },
    getNotificationId: (state, action) => {
      state.notificationId = action.payload;
    },
    getDirectFundingInvoiceId: (state, action) => {
      state.directFundingInvoiceId = action.payload;
    },
    updatedEffectiveData: (state, action) => {
      state.updatedEffectiveDataResponse = action.payload;
    },
    updatedBulkEffectiveData: (state, action) => {
      state.updatedBulkEffectiveDataResponse = action.payload;
    },
    resetUpdatedBulkEffectiveDataResponse: (state) => {
      state.updatedBulkEffectiveDataResponse = [];
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(funderDashboardStats.pending, (state) => {
    //   state.isLoading = true;
    // });
    // builder.addCase(funderDashboardStats.fulfilled, (state, action) => {
    //   state.isLoading = false;
    //   state.funderDashboardData = action.payload;
    // });
    // builder.addCase(funderDashboardStats.rejected, (state) => {
    //   state.isLoading = false;
    // });
    builder.addCase(getFunderTransactions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getFunderTransactions.fulfilled, (state, action) => {
      state.isLoading = false;
      state.funderTransactionsData = action.payload;
    });
    builder.addCase(getFunderTransactions.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getFunderOpenMarket.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getFunderOpenMarket.fulfilled, (state, action) => {
      state.isLoading = false;
      state.funderOpenMarketData = action.payload;
    });
    builder.addCase(getFunderOpenMarket.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(allFunders.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allFunders.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allFundersData = action.payload;
    });
    builder.addCase(allFunders.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(funderSearch.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(funderSearch.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(funderSearch.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(viewFunderPendingRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(viewFunderPendingRequest.fulfilled, (state, action) => {
      state.isLoading = false;
      state.funderPendingRequestData = action.payload;
    });
    builder.addCase(viewFunderPendingRequest.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getEarlyPaymentFunderConfig.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getEarlyPaymentFunderConfig.fulfilled, (state, action) => {
      state.isLoading = false;
      state.getEarlyPaymentFunderConfigData = action.payload;
    });
    builder.addCase(getEarlyPaymentFunderConfig.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getEarlyPaymentFunderSingleConfig.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getEarlyPaymentFunderSingleConfig.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.getEarlyPaymentFunderSingleConfigData = action.payload;
      }
    );
    builder.addCase(getEarlyPaymentFunderSingleConfig.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(acceptSupplierRequestFunder.pending, (state) => {
      state.associateLoading = true;
    });
    builder.addCase(acceptSupplierRequestFunder.fulfilled, (state) => {
      state.associateLoading = false;
    });
    builder.addCase(acceptSupplierRequestFunder.rejected, (state) => {
      state.associateLoading = false;
    });
    builder.addCase(getFunderAssociatedSuppliers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getFunderAssociatedSuppliers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.funderAssociatedSuppliersData = action.payload;
    });
    builder.addCase(getFunderAssociatedSuppliers.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(associateFunderWithSupplier.pending, (state) => {
      state.associateLoading = true;
    });
    builder.addCase(associateFunderWithSupplier.fulfilled, (state) => {
      state.associateLoading = false;
    });
    builder.addCase(associateFunderWithSupplier.rejected, (state) => {
      state.associateLoading = false;
    });
    builder.addCase(funderOnboardingInviteToSupplier.pending, (state) => {
      state.inviteLoading = true;
    });
    builder.addCase(funderOnboardingInviteToSupplier.fulfilled, (state) => {
      state.inviteLoading = false;
    });
    builder.addCase(funderOnboardingInviteToSupplier.rejected, (state) => {
      state.inviteLoading = false;
    });
    builder.addCase(associateFunderWithBuyer.pending, (state) => {
      state.associateLoading = true;
    });
    builder.addCase(associateFunderWithBuyer.fulfilled, (state) => {
      state.associateLoading = false;
    });
    builder.addCase(associateFunderWithBuyer.rejected, (state) => {
      state.associateLoading = false;
    });
    builder.addCase(funderOnboardingInviteToBuyers.pending, (state) => {
      state.inviteLoading = true;
    });
    builder.addCase(funderOnboardingInviteToBuyers.fulfilled, (state) => {
      state.inviteLoading = false;
    });
    builder.addCase(funderOnboardingInviteToBuyers.rejected, (state) => {
      state.inviteLoading = false;
    });
    builder.addCase(getFunderAssociatedBuyers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getFunderAssociatedBuyers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.funderAssociatedBuyersData = action.payload;
    });
    builder.addCase(getFunderAssociatedBuyers.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(acceptBuyerRequestFunder.pending, (state) => {
      state.associateLoading = true;
    });
    builder.addCase(acceptBuyerRequestFunder.fulfilled, (state) => {
      state.associateLoading = false;
    });
    builder.addCase(acceptBuyerRequestFunder.rejected, (state) => {
      state.associateLoading = false;
    });
    builder.addCase(declineSupplierRequestFunder.pending, (state) => {
      state.declineLoading = true;
    });
    builder.addCase(declineSupplierRequestFunder.fulfilled, (state) => {
      state.declineLoading = false;
    });
    builder.addCase(declineSupplierRequestFunder.rejected, (state) => {
      state.declineLoading = false;
    });
    builder.addCase(declineBuyerRequestFunder.pending, (state) => {
      state.declineLoading = true;
    });
    builder.addCase(declineBuyerRequestFunder.fulfilled, (state) => {
      state.declineLoading = false;
    });
    builder.addCase(declineBuyerRequestFunder.rejected, (state) => {
      state.declineLoading = false;
    });
    builder.addCase(disassociateBuyerFromFunder.pending, (state) => {
      state.disAssociateLoading = true;
    });
    builder.addCase(disassociateBuyerFromFunder.fulfilled, (state) => {
      state.disAssociateLoading = false;
    });
    builder.addCase(disassociateBuyerFromFunder.rejected, (state) => {
      state.disAssociateLoading = false;
    });
    builder.addCase(disassociateSupplierFromFunder.pending, (state) => {
      state.disAssociateLoading = true;
    });
    builder.addCase(disassociateSupplierFromFunder.fulfilled, (state) => {
      state.disAssociateLoading = false;
    });
    builder.addCase(disassociateSupplierFromFunder.rejected, (state) => {
      state.disAssociateLoading = false;
    });
    builder.addCase(getAllFunderNotifications.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllFunderNotifications.fulfilled, (state, action) => {
      state.isLoading = false;
      state.notifications = action.payload;
    });
    builder.addCase(getAllFunderNotifications.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getFunderNotificationById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getFunderNotificationById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.notification = action.payload;
    });
    builder.addCase(getFunderNotificationById.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(allFunderPendingPfpRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allFunderPendingPfpRequest.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allFunderPendingPfpdpData = action.payload;
    });
    builder.addCase(allFunderPendingPfpRequest.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(viewPfpById.pending, (state) => {
      state.dataByIdLoading = true;
    });
    builder.addCase(viewPfpById.fulfilled, (state, action) => {
      state.dataByIdLoading = false;
      state.viewPfpByIdData = action.payload;
    });
    builder.addCase(viewPfpById.rejected, (state) => {
      state.dataByIdLoading = false;
    });

    builder.addCase(acceptPfp.pending, (state) => {
      state.associateLoading = true;
    });
    builder.addCase(acceptPfp.fulfilled, (state) => {
      state.associateLoading = false;
    });
    builder.addCase(acceptPfp.rejected, (state) => {
      state.associateLoading = false;
    });

    builder.addCase(declinePfpRequest.pending, (state) => {
      state.associateLoading = true;
    });
    builder.addCase(declinePfpRequest.fulfilled, (state) => {
      state.associateLoading = false;
    });
    builder.addCase(declinePfpRequest.rejected, (state) => {
      state.associateLoading = false;
    });

    builder.addCase(allFunderPfp.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allFunderPfp.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allFunderPfpData = action.payload;
    });
    builder.addCase(allFunderPfp.rejected, (state) => {
      state.isLoading = false;
    });

    // FULL FUNDER PFP
    builder.addCase(fullFunderPfp.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fullFunderPfp.fulfilled, (state, action) => {
      state.isLoading = false;
      state.fullFunderPfpData = action.payload;
    });
    builder.addCase(fullFunderPfp.rejected, (state) => {
      state.isLoading = false;
    });

    // VIEW AGREEMENT DETAILS PFP
    builder.addCase(viewAgreementDetailsById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(viewAgreementDetailsById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.viewAgreementDetailsByIdData = action.payload;
    });
    builder.addCase(viewAgreementDetailsById.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(requestToLeavePfp.pending, (state) => {
      state.requestToLeaveLoading = true;
    });
    builder.addCase(requestToLeavePfp.fulfilled, (state, action) => {
      state.requestToLeaveLoading = false;
    });
    builder.addCase(requestToLeavePfp.rejected, (state) => {
      state.requestToLeaveLoading = false;
    });
    // FUNDER PFP
    builder.addCase(allFunderPfpInvoices.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allFunderPfpInvoices.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allPfpInvoicesData = action.payload;
    });
    builder.addCase(allFunderPfpInvoices.rejected, (state) => {
      state.isLoading = false;
    });
    // FUND PFP
    builder.addCase(fundPfpInvoice.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fundPfpInvoice.fulfilled, (state, action) => {
      state.isLoading = false;
      // state.allPfpInvoicesData = action.payload;
    });
    builder.addCase(fundPfpInvoice.rejected, (state) => {
      state.isLoading = false;
    });

    // FUNDER CLOSED MARKET
    builder.addCase(allClosedMarketWonBids.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allClosedMarketWonBids.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allClosedMarketWonBidsData = action.payload;
    });
    builder.addCase(allClosedMarketWonBids.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(funderFetchClosedMarketDashBoard.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      funderFetchClosedMarketDashBoard.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.closedMarketDashBoard = action.payload;
      }
    );
    builder.addCase(funderFetchClosedMarketDashBoard.rejected, (state) => {
      state.isLoading = false;
    });

    // FUNDER PLACE BID CLOSE MARKET
    builder.addCase(placeCloseMarketBid.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(placeCloseMarketBid.fulfilled, (state) => {
      state.isLoading = false;
      // state.allClosedMarketWonBidsData = action.payload;
    });
    builder.addCase(placeCloseMarketBid.rejected, (state) => {
      state.isLoading = false;
    });

    // FUNDER  Early payment view single request list
    builder.addCase(funderEarlyPaymentViewSingleList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      funderEarlyPaymentViewSingleList.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.funderEarlyPaymentViewSingleListsData = action.payload;
      }
    );
    builder.addCase(funderEarlyPaymentViewSingleList.rejected, (state) => {
      state.isLoading = false;
    });

    // FUNDER  Early payment view Bulk request list
    builder.addCase(funderEarlyPaymentViewBulkList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      funderEarlyPaymentViewBulkList.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.funderEarlyPaymentViewBulkListData = action.payload;
      }
    );
    builder.addCase(funderEarlyPaymentViewBulkList.rejected, (state) => {
      state.isLoading = false;
    });

    // FUNDER  Early payment view Single rejected request list
    builder.addCase(
      funderEarlyPaymentViewSingleRejectedList.pending,
      (state) => {
        state.isLoading = true;
      }
    );
    builder.addCase(
      funderEarlyPaymentViewSingleRejectedList.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.funderEarlyPaymentViewSingleRejectedListData = action.payload;
      }
    );
    builder.addCase(
      funderEarlyPaymentViewSingleRejectedList.rejected,
      (state) => {
        state.isLoading = false;
      }
    );

    // FUNDER  Early payment view Bulk rejected request list
    builder.addCase(funderEarlyPaymentViewBulkRejectedList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      funderEarlyPaymentViewBulkRejectedList.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.funderEarlyPaymentViewBulkRejectedListData = action.payload;
      }
    );
    builder.addCase(
      funderEarlyPaymentViewBulkRejectedList.rejected,
      (state) => {
        state.isLoading = false;
      }
    );

    // FUNDER  Early payment view single pending request list
    builder.addCase(
      funderEarlyPaymentViewSingleApprovedList.pending,
      (state) => {
        state.isLoading = true;
      }
    );
    builder.addCase(
      funderEarlyPaymentViewSingleApprovedList.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.funderEarlyPaymentViewSingleApprovedListData = action.payload;
      }
    );
    builder.addCase(
      funderEarlyPaymentViewSingleApprovedList.rejected,
      (state) => {
        state.isLoading = false;
      }
    );

    // FUNDER  Early payment view bulk pending request list
    builder.addCase(funderEarlyPaymentViewBulkApprovedList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      funderEarlyPaymentViewBulkApprovedList.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.funderEarlyPaymentViewBulkApprovedListData = action.payload;
      }
    );
    builder.addCase(
      funderEarlyPaymentViewBulkApprovedList.rejected,
      (state) => {
        state.isLoading = false;
      }
    );

    // FUNDER  Early payment view single details page
    builder.addCase(
      funderEarlyPaymentViewSinglePendingDetails.pending,
      (state) => {
        state.isLoading = true;
      }
    );
    builder.addCase(
      funderEarlyPaymentViewSinglePendingDetails.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.funderEarlyPaymentViewSinglePendingDetailsData = action.payload;
      }
    );
    builder.addCase(
      funderEarlyPaymentViewSinglePendingDetails.rejected,
      (state) => {
        state.isLoading = false;
      }
    );

    // FUNDER  Early payment view single details page
    builder.addCase(
      funderEarlyPaymentViewSingleRejectedDetails.pending,
      (state) => {
        state.isLoading = true;
      }
    );
    builder.addCase(
      funderEarlyPaymentViewSingleRejectedDetails.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.funderEarlyPaymentViewSingleRejectedDetailsData = action.payload;
      }
    );
    builder.addCase(
      funderEarlyPaymentViewSingleRejectedDetails.rejected,
      (state) => {
        state.isLoading = false;
      }
    );

    // FUNDER  Early payment view bulk rejected details page
    builder.addCase(
      funderEarlyPaymentViewBulkRejectedDetails.pending,
      (state) => {
        state.isLoading = true;
      }
    );
    builder.addCase(
      funderEarlyPaymentViewBulkRejectedDetails.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.funderEarlyPaymentViewBulkRejectedDetailsData = action.payload;
      }
    );
    builder.addCase(
      funderEarlyPaymentViewBulkRejectedDetails.rejected,
      (state) => {
        state.isLoading = false;
      }
    );

    // FUNDER  Early payment view Bulk details page
    builder.addCase(
      funderEarlyPaymentViewBulkPendingDetails.pending,
      (state) => {
        state.isLoading = true;
      }
    );
    builder.addCase(
      funderEarlyPaymentViewBulkPendingDetails.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.funderEarlyPaymentViewBulkPendingDetailsData = action.payload;
      }
    );
    builder.addCase(
      funderEarlyPaymentViewBulkPendingDetails.rejected,
      (state) => {
        state.isLoading = false;
      }
    );

    // FUNDER  Early payment view single details page
    builder.addCase(
      funderEarlyPaymentViewSingleApprovedDetails.pending,
      (state) => {
        state.isLoading = true;
      }
    );
    builder.addCase(
      funderEarlyPaymentViewSingleApprovedDetails.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.funderEarlyPaymentViewSingleApprovedDetailsData = action.payload;
      }
    );
    builder.addCase(
      funderEarlyPaymentViewSingleApprovedDetails.rejected,
      (state) => {
        state.isLoading = false;
      }
    );

    // FUNDER  Early payment view single details page
    builder.addCase(
      funderEarlyPaymentViewBulkApprovedDetails.pending,
      (state) => {
        state.isLoading = true;
      }
    );
    builder.addCase(
      funderEarlyPaymentViewBulkApprovedDetails.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.funderEarlyPaymentViewBulkApprovedDetailsData = action.payload;
      }
    );
    builder.addCase(
      funderEarlyPaymentViewBulkApprovedDetails.rejected,
      (state) => {
        state.isLoading = false;
      }
    );

    // FUNDER  approve direct funding
    builder.addCase(approveDirectFundingRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(approveDirectFundingRequest.fulfilled, (state, action) => {
      state.isLoading = false;
      state.approveDirectFundingRequestData = action.payload;
    });
    builder.addCase(approveDirectFundingRequest.rejected, (state) => {
      state.isLoading = false;
    });

    //rejecct single Early payment invoice
    builder.addCase(rejectSingleEarlyPaymentRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(rejectSingleEarlyPaymentRequest.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(rejectSingleEarlyPaymentRequest.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(allNewDirectFunding.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allNewDirectFunding.fulfilled, (state, action) => {
      state.isLoading = false;
      state.newDirectFundingData = action.payload;
    });
    builder.addCase(allNewDirectFunding.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(approveOrDeclineDirectFunding.pending, (state) => {
      state.directFundingApproveLoading = true;
    });
    builder.addCase(
      approveOrDeclineDirectFunding.fulfilled,
      (state, action) => {
        state.directFundingApproveLoading = false;
      }
    );
    builder.addCase(approveOrDeclineDirectFunding.rejected, (state) => {
      state.directFundingApproveLoading = false;
    });
    builder.addCase(funderCreateTransPin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(funderCreateTransPin.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(funderCreateTransPin.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(funderFetchAllClosedMarketByStatus.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      funderFetchAllClosedMarketByStatus.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.funderClosedMarketDataByStatus = action.payload;
      }
    );
    builder.addCase(funderFetchAllClosedMarketByStatus.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(
      funderFetchAllClosedMarketBySingleStatus.pending,
      (state) => {
        state.isLoading = true;
      }
    );
    builder.addCase(
      funderFetchAllClosedMarketBySingleStatus.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.funderClosedMarketDataBySingleStatus = action.payload;
      }
    );
    builder.addCase(
      funderFetchAllClosedMarketBySingleStatus.rejected,
      (state) => {
        state.isLoading = false;
      }
    );

    // builder.addCase(
    //   funderFetchAllClosedMarketBySingleStatus.pending,
    //   (state) => {
    //     state.isLoading = true;
    //   }
    // );
    // builder.addCase(
    //   funderFetchAllClosedMarketBySingleStatus.fulfilled,
    //   (state, action) => {
    //     state.isLoading = false;
    //     state.funderClosedMarketDataBySingleStatus = action.payload;
    //   }
    // );
    // builder.addCase(
    //   funderFetchAllClosedMarketBySingleStatus.rejected,
    //   (state) => {
    //     state.isLoading = false;
    //   }
    // );

    builder.addCase(funderFetchInvoiceDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(funderFetchInvoiceDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.funderClosedMarketDetails = action.payload;
    });
    builder.addCase(funderFetchInvoiceDetails.rejected, (state) => {
      state.isLoading = false;
    });
    // builder.addCase(funderFetchClosedMarketDashBoard.pending, (state) => {
    //   state.isLoading = true;
    // });
    // builder.addCase(
    //   funderFetchClosedMarketDashBoard.fulfilled,
    //   (state, action) => {
    //     state.isLoading = false;
    //     state.closedMarketDashBoard = action.payload;
    //   }
    // );
    // builder.addCase(funderFetchClosedMarketDashBoard.rejected, (state) => {
    //   state.isLoading = false;
    // });
    builder.addCase(funderFetchClosedMarketWonBids.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      funderFetchClosedMarketWonBids.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.closedMarketWonBid = action.payload;
      }
    );
    builder.addCase(funderFetchClosedMarketWonBids.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(fetchAllClosedMarketIngoingBidDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      fetchAllClosedMarketIngoingBidDetails.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.ongoingBidDetails = action.payload;
      }
    );
    builder.addCase(fetchAllClosedMarketIngoingBidDetails.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(fetchBidsInLead.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchBidsInLead.fulfilled, (state, action) => {
      state.isLoading = false;
      state.leadingBids = action.payload;
    });
    builder.addCase(fetchBidsInLead.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(fetchBidsNotInLead.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchBidsNotInLead.fulfilled, (state, action) => {
      state.isLoading = false;
      state.notInLeadingBids = action.payload;
    });
    builder.addCase(fetchBidsNotInLead.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(funderCloseMarketUpdateBid.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(funderCloseMarketUpdateBid.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(funderCloseMarketUpdateBid.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(funderCloseMarketCancelBid.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(funderCloseMarketCancelBid.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(funderCloseMarketCancelBid.rejected, (state) => {
      state.isLoading = false;
    });

    // funder effective rate
    builder.addCase(updateSingleEffectiveDisocunt.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      updateSingleEffectiveDisocunt.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.updatedEffectiveDataResponse = action.payload;
      }
    );
    builder.addCase(updateSingleEffectiveDisocunt.rejected, (state) => {
      state.isLoading = false;
    });

    //save effective rate for funder single Early payment details
    builder.addCase(saveSingleEffectiveDiscount.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(saveSingleEffectiveDiscount.fulfilled, (state, action) => {
      state.isLoading = false;
      state.savedEffectiveDataResponse = action.payload;
    });
    builder.addCase(saveSingleEffectiveDiscount.rejected, (state) => {
      state.isLoading = false;
    });

    // funder BULK effective rate
    builder.addCase(updateBulkEffectiveDisocunt.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateBulkEffectiveDisocunt.fulfilled, (state, action) => {
      state.isLoading = false;
      state.updatedBulkEffectiveDataResponse = action.payload;
    });
    builder.addCase(updateBulkEffectiveDisocunt.rejected, (state) => {
      state.isLoading = false;
    });

    //save effective rate for funder BULK Early payment details
    builder.addCase(saveBulkEffectiveDiscount.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(saveBulkEffectiveDiscount.fulfilled, (state, action) => {
      state.isLoading = false;
      state.savedBulkEffectiveDataResponse = action.payload;
    });
    builder.addCase(saveBulkEffectiveDiscount.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(editCompanyDetailsFunder.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editCompanyDetailsFunder.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(editCompanyDetailsFunder.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(editFunderProfile.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editFunderProfile.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(editFunderProfile.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(funderEditTransPin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(funderEditTransPin.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(funderEditTransPin.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(fecthFunderDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fecthFunderDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.funderDetails = action.payload;
    });
    builder.addCase(fecthFunderDetails.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(funderDashboardStats.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(funderDashboardStats.fulfilled, (state, action) => {
      state.isLoading = false;
      state.funderDashBoard = action.payload;
    });
    builder.addCase(funderDashboardStats.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

//funderCloseMarketCancelBid

export const {
  getFunderAssociatedBuyerId,
  getFunderAssociatedSupplierId,
  getPfpId,
  getNotificationId,
  getInvoiceId,
  getDirectFundingInvoiceId,
  setClosedMarketActiveTab,
  updatedEffectiveData,
  resetUpdatedBulkEffectiveDataResponse,
} = funderSlice.actions;
export const selectClosedMarketActiveTab = (state) =>
  state.funder.closeMarketactiveTab;

export default funderSlice.reducer;
