import { viewPfpById } from "appstate/funder/funderSlice";
import { PAIconPayassystP } from "assets/images/svgs";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "shared/Button";

const AgreementModalContent = () => {
  const { viewPfpByIdData, pfpId} = useSelector(
    (state) => state?.funder
  );
  // console.log(pfpId);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(viewPfpById({ id: pfpId }));
  }, [dispatch, pfpId]);
  // console.log(viewPfpByIdData);
  return (
    <div className="flex  items-center justify-center bg-primaryColor-to-br">
      <div className="w-full  px-10 py-8  bg-gray-100 rounded-lg shadow-xl">
        <div>
          <div className="flex items-center justify-center text-2xl text-primaryColor font-bold">
            <span className="relative left-[3px]">
              <PAIconPayassystP />
            </span>
            Pay<span className="text-[#F08000]">Assyst</span>
          </div>

          <header className="py-[10px] border-b border-black ">
            <h1 className="text-black text-lg font-bold text-center">
              Payable finance program Agreement
            </h1>
          </header>

          <div className="pb-[16px] border-b border-black ">
            <h1 className="text-xs text-black font-bold mt-[16px] mb-[9px]">
              LISTING RULES IMPLICATIONS
            </h1>
            <p className="text-xs">
              As the highest applicable percentage ratio for the Company as
              calculated under Rule 14.07 of Listing Rules in relation to the
              Reverse Factoring Agreement exceeds 5% but is less than 25%, the
              entering into the Reverse Factoring Agreement constitutes
              discloseable transaction for the Company under the Listing Rules
              and is subject to the reporting and announcement requirements
              under Chapter 14 of the Listing Rules.
            </p>
          </div>

          <div className="pb-[16px] ">
            <h1 className="text-xs text-black font-bold mt-[16px] mb-[9px]">
              REVERSE FACTORING AGREEMENT
            </h1>
            <p className="text-xs">
              The Board hereby announces that on{" "}
              <span>{viewPfpByIdData?.data?.startDate}</span>, Yueda Commercial
              Factoring, an indirect wholly owned subsidiary of the Company,
              entered into Reverse Factoring Agreement, pursuant to which Yueda
              Commercial Factoring agreed to provide Accounts Receivable
              Services and granted reverse revolving factoring loan credit
              limits to Sheyang Tour and Sheyang Tour is entitled to apply for
              accounts receivables transfer and factoring services. If Sheyang
              Tour needs financing facility from Yueda Commercial Factoring, it
              will make an application for factoring services. If the credit
              assessment is to the satisfaction of Yueda Commercial Factoring,
              Yueda Commercial Factoring will grant the factoring loan within
              the reverse revolving credit limit. The principal terms of the
              Reverse Factoring Agreement entered into between the Company and
              Funder name are set out below:
            </p>
          </div>

          <div>
            <div className="flex justify-between mb-[5px]">
              <p className="ext-xs text-black font-bold w-[320px]">
                Name Of Program:
              </p>
              <p>{viewPfpByIdData?.data?.programName}</p>
            </div>

            <div className="flex justify-between mb-[5px]">
              <p className="ext-xs text-black font-bold w-[320px]">Parties:</p>
              <div>
                <p>Funder Name </p>
                <p>
                  {viewPfpByIdData?.data?.funderName
                    ? viewPfpByIdData?.data?.funderName
                    : ""}
                </p>
              </div>
            </div>

            <div className="flex justify-between mb-[5px]">
              <p className="ext-xs text-black font-bold w-[320px]">
                Start Date:
              </p>
              <p>{viewPfpByIdData?.data?.startDate}</p>
            </div>

            <div className="flex justify-between mb-[5px]">
              <p className="ext-xs text-black font-bold w-[320px]">End Date:</p>
              <p>{viewPfpByIdData?.data?.endDate}</p>
            </div>

            <div className="flex justify-between mb-[5px]">
              <p className="ext-xs text-black font-bold w-[320px]">
                Annual rate of return(composed of interest rate and factoring
                administration fees)
              </p>
              <p>{viewPfpByIdData?.data?.funderPercentage}</p>
            </div>
          </div>
          <div className="flex items-center mt-[30px]">
            <p className="text-secondaryColor text-sm ">
              By clicking the <span className="font-bold">“Click to sign”</span>{" "}
              button, you're approving and confirming your consent to the above
              agreement
            </p>
            <div className="flex gap-x-[16px]">
              <Button
                neutral
                className="w-[147px] h-[39px] flex justify-center items-center bg-secondaryColor text-white rounded"
              >
                Click to sign
              </Button>

              <Button
                neutral
                className="w-[109px] h-[39px] flex justify-center items-center bg-red text-white rounded"
              >
                Reject
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgreementModalContent;
