import { useState } from "react";
import { AnimatePresence } from "framer-motion";
import Overview from "./components/Overview";
import OpenMarketHeader from "./components/OpenMarketHeader";

import OpenMarketTab from "./components/OpenMarketTab";
import OngoingBidRequest from "./components/OngoingBidRequest";
import AcceptBid from "./components/AcceptBid";
// import LockedInvoices from "./components/LockedInvoices";

function FunderOpenMarket() {
  const [acceptedQuery, setAcceptedQuery] = useState("");
  const [overviewQuery, setOverviewQuery] = useState("");
  const [openMarketQuery, setOpenMarketQuery] = useState("");
  const [pendingQuery, setPendingQuery] = useState("");
  const [activeTab, setActiveTab] = useState(
    parseInt(sessionStorage.getItem("activeTab")) || 1
  );

  return (
    <div className="flex flex-col gap-8">
      <>
        <OpenMarketHeader
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          searchQuery={
            activeTab === 1
              ? overviewQuery
              : activeTab === 2
              ? openMarketQuery
              : activeTab === 3
              ? pendingQuery
              : acceptedQuery
          }
          setSearchQuery={
            activeTab === 1
              ? setOverviewQuery
              : activeTab === 2
              ? setOpenMarketQuery
              : activeTab === 3
              ? setPendingQuery
              : setAcceptedQuery
          }
        />

        <main className="w-full">
          <AnimatePresence mode="wait">
            {activeTab === 1 ? (
              <Overview setActiveTab={setActiveTab} />
            ) : activeTab === 2 ? (
              <OpenMarketTab
                setActiveTab={setActiveTab}
                openMarketQuery={openMarketQuery}
              />
            ) : activeTab === 3 ? (
              <OngoingBidRequest
                setActiveTab={setActiveTab}
                pendingQuery={pendingQuery}
              />
            ) : (
              <AcceptBid acceptedQuery={acceptedQuery} />
            )}
          </AnimatePresence>
        </main>
      </>
    </div>
  );
}

export default FunderOpenMarket;
