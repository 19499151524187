import React from "react";
import Overview from "./components/Overview/Overview";
import Agreement from "./components/Agreement/Agreement";
import Tools from "./components/Tools/Tools";
//

import Tabs from "shared/Tabs";
import DashboardLayout from "layout/dashboardlayout";

const ToolsAgreement = () => {
  const toolsandagreementlinks = [
    {
      title: "Overview",
      component: <Overview />,
    },
    {
      title: "Agreement",
      component: <Agreement />,
    },
    {
      title: "Tools",
      component: <Tools />,
    },
    // {
    //   title: "FAQs",
    //   component: <FAQs />,
    // },
    // {
    //   title: "Resources",
    //   component: <Resources />,
    // },
  ];

  const currentLinks = toolsandagreementlinks;

  return (
    <DashboardLayout
      section="Tools & Agreement"
      subHeader="Access tools for a seamless experience with our services"
    >
      <Tabs
        setWrap
        setContain
        flexTabCol
        // setButton
        setBackground
        setContnent
        variant="tools&agreement"
        tabs={currentLinks}
      />
    </DashboardLayout>
  );
};

export default ToolsAgreement;
