import React, { useState, useEffect, useCallback } from "react";
import DashboardLayout from "layout/dashboardlayout";
import Table from "layout/table/Table";
import Card from "shared/Card";
import TableHeader from "shared/tableHeader/TableHeader";
import Trash from "../../../../../assets/images/svgs/redtrash.svg";
import Enabled from "../../../../../assets/images/svgs/enable.svg";
import Disenabled from "../../../../../assets/images/svgs/disenable.svg";
import Edit from "../../../../../assets/images/svgs/edit-main.svg";
import Backbutton from "../../../../../assets/images/svgs/backbtnplaceholder.svg";
import { useDispatch, useSelector } from "react-redux";
import Loading from "shared/Loading";
import { getAllCreatedTiers } from "appstate/api/tier";
import { useNavigate } from "react-router-dom";
import Pagination from "shared/Pagination";
import { changeTierStatus, deleteTier } from "appstate/api/tier";
import { fetchTierById } from "appstate/tier/tierSlice";
import TierDeletePopup from "./tierdeletepopup";
import _ from "lodash";
import SuccessModal from "shared/SuccessModal";

const Tier = () => {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading } = useSelector((state) => state?.buyer);
  const [openTierModal, setOpenTierModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [tierId, setTierId] = useState("");
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const size = 10;
  const [total, setTotal] = useState("");
  // eslint-disable-next-line
  const [getId, setGetId] = useState("");
  const [isDetails, setIsDetails] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [tierToDelete, setTierToDelete] = useState(null);
  const [tierLoading, setTierLoading] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  const handleDeleteTier = async () => {
    if (tierToDelete) {
      try {
        await deleteTier(tierToDelete);
        setSuccessModal(true);
        fetchTiers();
        setIsOpen(false);
      } catch (error) {
        console.error("Error deleting tier:", error);
      }
    }
  };

  const toggleEnabled = async (tierId) => {
    try {
      await changeTierStatus(tierId);
      fetchTiers();
    } catch (error) {
      console.error("Error changing tier status:", error);
    }
  };

  useEffect(() => {
    fetchTiers();
    // eslint-disable-next-line
  }, []);

  const fetchTiers = async () => {
    setTierLoading(true);
    try {
      const result = await getAllCreatedTiers({ page, size, searchTerm });
      setTotal(result?.meta?.totalElements);
      setData(result?.data || []);
    } catch (error) {
      console.error("Error fetching tiers:", error);
    } finally {
      setTierLoading(false);
    }
  };

  // eslint-disable-next-line
  const debouncedSearch = useCallback(
    _.debounce(() => {
      fetchTiers(searchTerm);
    }, 300),
    [searchTerm]
  );

  useEffect(() => {
    debouncedSearch();
    return debouncedSearch.cancel;
  }, [searchTerm, debouncedSearch]);

  const handleOpenDeletePopup = (tierId) => {
    setTierToDelete(tierId);
    setIsOpen(true);
  };

  useEffect(() => {
    fetchTiers();
    // eslint-disable-next-line
  }, [searchTerm]);

  const handleSearch = () => {
    debouncedSearch();
  };

  const GoBack = () => {
    navigate(-1);
  };

  const onRowClicked = async (row) => {
    setTierId(row?.tierId);
    setIsDetails(true);
    await dispatch(fetchTierById(row?.tierId));
    setOpenTierModal(true);
  };

  const handleEdit = async (row) => {
    setTierId(row?.tierId);
    setOpenTierModal(true);
    setEdit(true);
    await dispatch(fetchTierById(row?.tierId));
  };

  const totalPages = total;

  const column = [
    { name: "Tier Name", selector: (row) => row?.tierName },
    { name: "Description", selector: (row) => row?.tierDescription },
    { name: "Updated Date", selector: (row) => row?.updatedDate.split(" ")[0] },
    {
      name: "Status",
      selector: (row) => (row?.enabled ? "Enabled" : "Not Enabled"),
    },
    {
      cell: (row) => (
        <div className="flex items-center gap-x-[10px]">
          <img
            src={Edit}
            onClick={() => handleEdit(row)}
            alt="Edit"
            className="w-[20px] h-[20px] cursor-pointer"
          />
          <img
            onClick={() => handleOpenDeletePopup(row?.tierId)}
            src={Trash}
            alt="Delete"
            className="w-[20px] h-[20px] cursor-pointer"
          />
          {row?.enabled === true ? (
            <img
              src={Enabled}
              alt="Enabled"
              className="w-[20px] h-[20px] cursor-pointer"
              onClick={() => toggleEnabled(row?.tierId)}
            />
          ) : (
            <img
              src={Disenabled}
              alt="Disabled"
              className="w-[20px] h-[20px] cursor-pointer"
              onClick={() => toggleEnabled(row?.tierId)}
            />
          )}
        </div>
      ),
      width: "5rem",
    },
  ];

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleCloseSuccessModal = () => {
    setSuccessModal(false);
  };

  return (
    <DashboardLayout
      section="Tiers"
      subHeader="Manage all your tiers on the account"
    >
      <div style={{ cursor: "pointer" }} onClick={GoBack}>
        <img src={Backbutton} alt="Go Back" />
      </div>
      <br />
      <SuccessModal
        onClose={handleCloseSuccessModal}
        isOpen={successModal}
        message="Tier deleted successfully"
      />
      <Card className="p-[24px] rounded-[10px]">
        <TableHeader
          fetchTiers={fetchTiers}
          tierId={tierId}
          setEdit={setEdit}
          edit={edit}
          openTierModal={openTierModal}
          setOpenTierModal={setOpenTierModal}
          total="All Tiers"
          details="Access details of tiers"
          tier={true}
          isDetails={isDetails}
          setIsDetails={setIsDetails}
          getId={getId}
          handleSearch={handleSearch}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
        {tierLoading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Loading />
          </div>
        ) : (
          <Table
            columns={column}
            data={Array.isArray(data) ? data : []}
            onRowClicked={onRowClicked}
            progressPending={isLoading}
            progressComponent={<Loading />}
            pointer
            tableHeader
            className="mt-5"
          />
        )}
        <br />
        <div className="flex justify-center gap-x-[8px]  mt-12">
          {!tierLoading && totalPages > 10 && (
            <Pagination
              page={page}
              startPage={startPage}
              setEndPage={setEndPage}
              endPage={endPage}
              setStartPage={setStartPage}
              setPage={setPage}
              totalPages={totalPages}
            />
          )}
        </div>
      </Card>
      {isOpen && (
        <TierDeletePopup onYes={handleDeleteTier} onNo={handleClose} />
      )}
    </DashboardLayout>
  );
};

export default Tier;
