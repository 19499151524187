import {
  onboardingInviteSupplier,
} from "appstate/buyer/buyerSlice";
import DashboardLayout from "layout/dashboardlayout";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "shared/Button";
import AccountModalContainer from "shared/accountModal/Modal";
import DeclineModal from "shared/delineModal/Modal";
import InviteModal from "shared/inviteModal";
import BuyerDetailsModal from "../modal";
import Table from "layout/table/Table";
import Loading from "shared/Loading";
import toast from "react-hot-toast";
import Card from "shared/Card";
import { PAIconInventoryArrowLeft, PAIconSearch } from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import { acceptSupplierAssociationFunder, getPendingAssociationFunderSupplier, rejectSupplierAssociationFunder } from "appstate/api/funder";
import { debounce } from "lodash";
import SuccessRelationship from "shared/SuccessRelationship";

const PendingAssociation = () => {
  const [data, setData] = useState([]);
  const {
    // pendingAssociateRequestData,
    // isLoading,
    declineLoading,
    inviteLoading,
    associateLoading,
  } = useSelector((state) => state.buyer);
  // eslint-disable-next-line
  const [getId, setGetId] = useState(null);
  const [openDeclineModal, setOpenDeclineModal] = useState(false);
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line
  const [opened, setOpened] = useState(false);
  const [success, setSuccess] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  // eslint-disable-next-line
  const [companyName, setCompanyName] = useState("");
  // eslint-disable-next-line
  const [page, setPage] = useState(0);
  // const [startPage, setStartPage] = useState(0);
  // const [endPage, setEndPage] = useState(5);
  const pageSize = 10;
  const [invite, setInvite] = useState([]);
  const [invitee, setInvitee] = useState([]);
  const [newInvite, setNewInvite] = useState("");
  const [loading, setLoading] = useState(false);
  const [notificationId, setNotificationId] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);


  const dispatch = useDispatch();

  const navigate = useNavigate();
  function handleNavigate() {
    navigate(-1);
  }

  const sender = "supplier";

  const fetchData = async () => {
    setLoading(true)
    try {
      const result = await getPendingAssociationFunderSupplier(page, pageSize, sender, companyName);
      setData(result?.data);
    } catch (error) {
      console.error("Error fetching pending associations:", error);
      toast.error("Failed to load pending association requests.");
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line 
  }, [page, pageSize, companyName]);

   // eslint-disable-next-line 
  const handleSearch = useCallback(
    debounce((searchValue) => {
      setCompanyName(searchValue);
    }, 200),
    []
  );

  const handleOpenModal = (row) => {
    setNotificationId(row?.notificationId);
    setGetId(row?.userId);
    setOpened(true);
  }

  const handleDeclineOpenModal = (row) => {
    setNotificationId(row?.notificationId);
    setGetId(row?.userId);
    setOpenDeclineModal(true);
  }
  
  const handleSearchChange = (e) => {
    handleSearch(e.target.value);
  };

  const handleDecline = async () => {
    if (notificationId) {
      try {
        const response = await rejectSupplierAssociationFunder(notificationId);
        if (response) {
          setShowSuccessModal(true);
          setOpenDeclineModal(false);
        }
      } catch (error) {
        toast.error("Failed to decline the association request.");
      }
    }
  };

  const handleAssociate = async () => {
    if (notificationId && getId) {
      try {
        const response = await acceptSupplierAssociationFunder(notificationId, getId);
        if (response) {
          toast.success("Supplier association accepted successfully!");
          setOpened(false);
          fetchData();
        }
      } catch (error) {
        toast.error("Failed to accept the association.");
      }
    }
  };
  

  const columnsPendingAssociation = [
    {
      name: "Name",
      selector: (row) => row?.fullName,
    },
    {
      name: "Company",
      selector: (row) => row?.companyName,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
    },
    {
      name: "Phone",
      selector: (row) => row?.phoneNumber,
    },
    {
      cell: (row) => (
        <div className="flex gap-x-[12px]">
          <Button
            onClick={() => handleOpenModal(row)}
            neutral
            className="flex px-[16px] py-[8px] border-[1px] border-gray rounded-[5px] bg-primaryColor"
          >
            <p className="text-xs font-normal text-white whitespace-nowrap">
              Accept
            </p>
          </Button>
          <Button
            onClick={() => handleDeclineOpenModal(row)}
            neutral
            className="flex px-[16px] py-[8px] border-[1px] border-[red] rounded-[5px] bg-[white]"
          >
            <p className="text-xs font-normal  text-[red] whitespace-nowrap">
              Decline
            </p>
          </Button>
        </div>
      ),
    },
  ];

  const onRowClicked = (row) => {
    setOpenDetailsModal(true);
    setGetId(row?.userId);
  };

  const handleSubmitEmails = () => {
    const body = invite;
    dispatch(onboardingInviteSupplier(body)).then((data) => {
      if (data?.payload?.success === true) {
        setSuccess(true);
        setInvite([]);
      }
    });
  };

  const onClose = () => {
    setShowSuccessModal(false);
  };

  return (
    <DashboardLayout
      section="Suppliers"
      subHeader="Manage all your associated suppliers account"
    >
        {showSuccessModal && (
        <SuccessRelationship
          isOpen={showSuccessModal}
          onClose={onClose}
          message="Supplier association request rejected successfully!"
        />
      )}
      <InviteModal
        isShown={open}
        onClose={() => {
          setOpen(false);
          setSuccess(false);
        }}
        message=" The Supplier would received a mail that your are inviting to join
          PayEdge."
        heading="Invite Supplier"
        info="You can add one or more supplier at once with ease"
        role="Supplier"
        invite={invitee}
        setInvite={setInvitee}
        newInvite={newInvite}
        setNewInvite={setNewInvite}
        success={success}
        setSuccess={setSuccess}
        handleAdd={handleSubmitEmails}
        isLoading={inviteLoading}
      />
      <BuyerDetailsModal
        isShown={openDetailsModal}
        onClose={() => setOpenDetailsModal(false)}
        // data={supplierDetails}
      />
      <DeclineModal
        isShown={openDeclineModal}
        onClose={() => setOpenDeclineModal(false)}
        success={success}
        handleAssociate={handleDecline}
        isLoading={declineLoading}
        warning="Are you sure you would like to Decline this request?"
      />
      <AccountModalContainer
        isShown={opened}
        onClose={() => setOpened(false)}
        success={success}
        handleAssociate={handleAssociate}
        isLoading={associateLoading}
        warning="Are you sure you would like to associate with the supplier?"
      />
      <Card className="p-[24px] rounded-[10px]">
        <header className="bg-alabasterHeader p-[24px] rounded-t-[10px]">
        <div
        onClick={handleNavigate}
          className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
        >
          <PAIconInventoryArrowLeft />
          <p className="mt-[2px] text-sm font-normal text-lightGray">
             Back
          </p>
        </div>
        <div className="flex justify-between flex-wrap ">
          <div>
            <p className="text-black text-sm font-medium">
              Pending association requests
            </p>
            <p className="text-textColor text-xs font-normal">
              List of suppliers wanting to associate
            </p>
          </div>
          <div className="flex gap-x-[10px] mt-[10px] md:mt-0 flex-wrap">
            <div className="flex items-center  bg-white p-[10px] gap-x-[8px] rounded-[5px] border-[0.5px] border-silver xl:w-[350px]">
              <PAIconSearch />
              <input
                type="text"
                value={companyName}
                onChange={handleSearchChange}
                placeholder="Search request"
                className="placeholder:text-xs outline-none"
              />
            </div>
            <Button
              neutral
            //   onClick={handleSearch}
              disabled={data < 1}
              className="py-[12px] mt-[10px] md:mt-0 px-[24px] border-[0.5px] border-silver bg-brightGray text-sm !text-black rounded-[5px] font-medium"
            >
              Search
            </Button>
          </div>
        </div>
      </header>
        <Table
          columns={columnsPendingAssociation}
          data={data}
          pointer
          onRowClicked={onRowClicked}
          progressPending={loading}
          progressComponent={<Loading />}
          tableHeader
        />
      </Card>
    </DashboardLayout>
  );
};
export default PendingAssociation;
