import {
    PAIconAlertIcon,
  } from "assets/images/svgs";
  import React from "react";
  import Button from "shared/Button";
  import Spinner from "shared/Spinner/Spinner";
  
  const EarlyPaymentConfirmation = ({
    handleAssociate,
    warning,
    isLoading,
    hide,
    earlyPayment
  }) => {
    return (
      <div>
        <div className="flex flex-col items-center gap-y-[24px] py-[28px]">
          <div>
            <PAIconAlertIcon />
          </div>
          <p className="text-base font-normal text-lightGray">{warning}</p>
          <div className="overflow-hidden flex">
            <Button
              onClick={handleAssociate}
              neutral
              className="p-4 bg-primaryColor text-white  flex items-center justify-center h-[43px] rounded-[5px] text-base font-normal"
            >
              {isLoading ? <Spinner /> : (earlyPayment ? "Yes Proceed" : "Yes")}
            </Button>
            <div>
              <Button
                onClick={hide}
                neutral
                className="w-[76px] h-[43px] !text-red border-[1px] border-red rounded-[5px] ml-[24px]"
              >
                No
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default EarlyPaymentConfirmation;
  