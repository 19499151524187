import {
  PAIconCancel,
  PAIconPaymentRequest,
  PAIconPaymentTag,
  PAIconChecked,
  PAIconUncheck,
} from "assets/images/svgs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Button from "shared/Button";
import AccountModal from "shared/accountModal/AccountModal";
import { activatePfp } from "appstate/api/buyer/buyerpfp";
// import { data } from "features/roleManager/Dashbaord/data";

const ModalContents = ({
  hide,
  userDetails,
  setPayableFinanceUpdateAction,
}) => {
  const [open, setOpen] = useState(false);
  const [isPaymentOffers, setIsPaymentOffers] = useState(false);
  const [isPaymentRequest, setIsPaymentRequest] = useState(false);
  const [program, setProgram] = useState([]);
  const { createPfpLoading } = useSelector((state) => state?.buyer);
  const [activate, setActivate] = useState(false);
  const [deactivate, setDeactivate] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    if (userDetails?.data?.payableFinanceActive === true) {
      setDeactivate(true);
    }

    if (userDetails.data.earlyPaymentOfferEnable === true) {
      setIsPaymentOffers(true);
    }

    if (userDetails.data.earlyPaymentRequestEnable === true) {
      setIsPaymentRequest(true);
    }
  }, [
    userDetails?.data?.payableFinanceActive,
    userDetails.data.earlyPaymentOfferEnable,
    userDetails.data.earlyPaymentRequestEnable,
  ]);

  const handleCheckboxChange = () => {
    setIsPaymentOffers(!isPaymentOffers);
  };

  const handleRequestCheckboxChange = () => {
    setIsPaymentRequest(!isPaymentRequest);
  };

  useEffect(() => {
    if (isPaymentOffers === true) {
      setProgram((prevPrograms) => [...prevPrograms, "EARLY_PAYMENT_OFFER"]);
    }

    if (isPaymentOffers === false) {
      setProgram((prevPrograms) =>
        prevPrograms.filter((p) => p !== "EARLY_PAYMENT_OFFER")
      );
    }
  }, [isPaymentOffers]);

  useEffect(() => {
    if (isPaymentRequest === true) {
      setProgram((prevPrograms) => [...prevPrograms, "EARLY_PAYMENT_REQUEST"]);
    }

    if (isPaymentRequest === false) {
      setProgram((prevPrograms) =>
        prevPrograms.filter((p) => p !== "EARLY_PAYMENT_REQUEST")
      );
    }
  }, [isPaymentRequest]);

  const handleProceed = () => {
    if (isPaymentOffers === true) {
      setOpen(true);
      setActivate(true);
    }

    if (isPaymentRequest === true) {
      setOpen(true);
      setActivate(true);
    }

    if (isPaymentOffers === true && isPaymentRequest === true) {
      setOpen(true);
      setActivate(true);
    }
  };

  // {userDetails.data.payableFinanceActive === true ? (

  // useEffect(() => {
  //   if (userDetails?.data?.payableFinanceActive === true) {
  //     setIsPaymentOffers(true);
  //     setIsPaymentRequest(true);
  //   }
  // }, [userDetails?.data?.payableFinanceActive]);

  const handleActivatePfp = async () => {
    const rule = "EARLY_PAYMENT";
    const programs = program;
    const active = deactivate ? false : activate;

    try {
      await activatePfp(rule, programs, active);
      setIsSuccess(true);
      setPayableFinanceUpdateAction(true);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (isSuccess === true) {
      setTimeout(() => {
        setOpen(false);
        setIsSuccess(false);
        hide();
      }, 1000);
    }
  }, [isSuccess, hide, setPayableFinanceUpdateAction]);

  return (
    <>
      {open ? (
        <div className="mt-2 bg-white">
          <AccountModal
            handleAssociate={handleActivatePfp}
            isLoading={createPfpLoading}
            warning={
              userDetails?.data?.payableFinanceActive === true
                ? "Are you sure you would like to deactivate this payable finance program?"
                : "Are you sure you would like to activate this payable financing?"
            }
            hide={hide}
            isSuccess={isSuccess}
          />
        </div>
      ) : (
        <div className="pb-[30px] bg-white rounded-lg">
          <div className="flex justify-between px-[32px] py-[21px] bg-alabasterHeader">
            {userDetails?.data?.payableFinanceActive === true ? (
              <h1 className="text-sm font-extrabold italic">
                Deactivate Payable Financing
              </h1>
            ) : (
              <h1 className="text-sm font-extrabold italic">
                Payable Financing
              </h1>
            )}
            <div
              onClick={hide}
              className="flex gap-x-[8px] items-center cursor-pointer"
            >
              <PAIconCancel className="w-[10px]" />
              <p className="text-sm">Cancel</p>
            </div>
          </div>

          <div className="px-[32px]">
            <p className="text-sm text-lightGray my-[24px]">
              {userDetails?.data?.payableFinanceActive === true
                ? "Please choose one or both options to disable payable financing"
                : "Please choose one or both options to enable payable financing"}
            </p>
            <div>
              <div
                onClick={() => {
                  handleCheckboxChange();
                }}
                className="flex items-center justify-between border-[1px] border-[#D9E6E1] rounded-[8px] py-6 px-4"
              >
                <div className="flex items-center">
                  <PAIconPaymentTag />
                  <p className="ml-3">Early Payment Offers</p>
                </div>
                {isPaymentOffers === true ? (
                  <PAIconChecked />
                ) : (
                  <PAIconUncheck />
                )}
              </div>

              <div
                onClick={() => {
                  handleRequestCheckboxChange();
                }}
                className="flex items-center justify-between border-[1px] border-[#D9E6E1] rounded-[8px] py-6 px-4 mt-4"
              >
                <div className="flex items-center">
                  <PAIconPaymentRequest />
                  <p className="ml-3">Early Payment Request</p>
                </div>

                {isPaymentRequest === true ? (
                  <PAIconChecked />
                ) : (
                  <PAIconUncheck />
                )}
              </div>
            </div>
            <div className="flex mt-5 justify-end">
              <Button
                type="Button"
                neutral
                onClick={handleProceed}
                className={`!text-white !font-normal ${
                  userDetails?.data?.payableFinanceActive
                    ? "bg-secondaryColor"
                    : "bg-primaryColor"
                } py-[8px] px-[24px] rounded-[5px]`}
              >
                {userDetails?.data?.payableFinanceActive === true
                  ? "Deactivate"
                  : "Activate"}
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalContents;
