import {
  acceptSupplierRequest,
  buyerRejectRequestSupplier,
  getBuyerPendingAssociateRequest,
  onboardingInviteSupplier,
} from "appstate/buyer/buyerSlice";
import DashboardLayout from "layout/dashboardlayout";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "shared/Button";
import AccountModalContainer from "shared/accountModal/Modal";
import DeclineModal from "shared/delineModal/Modal";
import InviteModal from "shared/inviteModal";
import BuyerDetailsModal from "../modal";
import Table from "layout/table/Table";
import Loading from "shared/Loading";
import toast from "react-hot-toast";
import Card from "shared/Card";
import { PAIconInventoryArrowLeft, PAIconSearch } from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import { funderPendingInvites } from "appstate/api/buyer/relationshipManagement";
import { debounce } from "lodash";

const PendingInvites = () => {
  const [data, setData] = useState([]);
  const {
    declineLoading,
    inviteLoading,
    associateLoading,
  } = useSelector((state) => state.buyer);
  const [getId, setGetId] = useState(null);
  const [openDeclineModal, setOpenDeclineModal] = useState(false);
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line
  const [opened, setOpened] = useState(false);
  const [success, setSuccess] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  // eslint-disable-next-line
  const [companyName, setCompanyName] = useState("");
  // eslint-disable-next-line
  const [page, setPage] = useState(0);
  // const [startPage, setStartPage] = useState(0);
  // const [endPage, setEndPage] = useState(5);
  const pageSize = 10;
  const [invite, setInvite] = useState([]);
  const [invitee, setInvitee] = useState([]);
  const [newInvite, setNewInvite] = useState("");
  const [loading, setLoading] = useState(false);

  const supplierDetails = data?.find((cur) => cur?.userId === getId);

  const dispatch = useDispatch();

  const navigate = useNavigate();
  function handleNavigate() {
    navigate(-1);
  }

  // eslint-disable-next-line
  const fetchPendingInvites = async (searchTerm) => {
    setLoading(true);
    let sender =  "supplier";
    try {
      const result = await funderPendingInvites({
        page,
        pageSize,
        search: searchTerm,
        sender,
      });
      setData(result?.data);
    } catch (error) {
      toast.error("Error fetching pending invites");
    } finally {
      setLoading(false);
    }
  };
 
   // eslint-disable-next-line
  const debouncedFetch = useMemo(
     // eslint-disable-next-line
    () => debounce(fetchPendingInvites, 300),
    // eslint-disable-next-line
    [page, pageSize]
    // eslint-disable-next-line
  );

  const handleSearch = (e) => {
    const { value } = e.target;
    setCompanyName(value);
    debouncedFetch(value);
  };

  useEffect(() => {
    fetchPendingInvites(companyName);
    // eslint-disable-next-line 
  }, [page, pageSize]);
  

  const sender = "supplier";

  const handleDecline = () => {
    const notificationId = getId;
    const search = "";

    dispatch(buyerRejectRequestSupplier({ notificationId })).then((data) => {
      if (data?.payload?.success === true) {
        setSuccess(false);
        setOpenDeclineModal(false);
        dispatch(
          getBuyerPendingAssociateRequest({ sender, search, page, pageSize })
        );
      }
    });
  };

  const handleAssociate = () => {
    const notificationId = getId;
    const search = "";

    dispatch(acceptSupplierRequest(notificationId)).then((data) => {
      if (data?.payload?.success === true) {
        setSuccess(false);
        toast.success(data?.payload?.message);
        dispatch(
          getBuyerPendingAssociateRequest({ sender, search, page, pageSize })
        );
        setOpened(false);
      }
    });
  };
  const column = [
    {
      name: "Email",
      selector: (row) => row?.email,
      width: "15rem",
    },
    {
      name: "Status",
      selector: (row) => (
        <span
          className={`${
            row?.status === "Pending" ? "text-orange-500" : "text-black"
          }`}
        >
          {row?.status}
        </span>
      ),
    },
    {
      cell: (row) => {
        return (
          <div className="flex gap-x-[12px] justify-end">
            <Button
              // onClick={() => handleOpenModal(row)}
              neutral
              className="flex px-[16px] py-[8px] border-[1px] border-red rounded-[5px] bg-[white]"
            >
              <p className="text-xs font-normal text-red whitespace-nowrap">
                Cancel Request
              </p>
            </Button>
          </div>
        );
      },
      width: "10rem",
    },
  ];

  const onRowClicked = (row) => {
    setOpenDetailsModal(true);
    setGetId(row?.userId);
  };

  const handleSubmitEmails = () => {
    const body = invite;
    dispatch(onboardingInviteSupplier(body)).then((data) => {
      if (data?.payload?.success === true) {
        setSuccess(true);
        setInvite([]);
      }
    });
  };

  return (
    <DashboardLayout
      section="Suppliers"
      subHeader="Manage all your associated suppliers account"
    >
      <InviteModal
        isShown={open}
        onClose={() => {
          setOpen(false);
          setSuccess(false);
        }}
        message=" The Supplier would received a mail that your are inviting to join
            PayEdge."
        heading="Invite Supplier"
        info="You can add one or more supplier at once with ease"
        role="Supplier"
        invite={invitee}
        setInvite={setInvitee}
        newInvite={newInvite}
        setNewInvite={setNewInvite}
        success={success}
        setSuccess={setSuccess}
        handleAdd={handleSubmitEmails}
        isLoading={inviteLoading}
      />
      <BuyerDetailsModal
        isShown={openDetailsModal}
        onClose={() => setOpenDetailsModal(false)}
        data={supplierDetails}
      />
      <DeclineModal
        isShown={openDeclineModal}
        onClose={() => setOpenDeclineModal(false)}
        success={success}
        handleAssociate={handleDecline}
        isLoading={declineLoading}
        warning="Are you sure you would like to Decline this request?"
      />
      <AccountModalContainer
        isShown={opened}
        onClose={() => setOpen(false)}
        success={success}
        handleAssociate={handleAssociate}
        isLoading={associateLoading}
        warning="Are you sure you would like to associate with the supplier?"
      />
      <Card className="p-[24px] rounded-[10px]">
        <header className="bg-alabasterHeader p-[24px] rounded-t-[10px]">
          <div
            onClick={handleNavigate}
            className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
          >
            <PAIconInventoryArrowLeft />
            <p className="mt-[2px] text-sm font-normal text-lightGray">Back</p>
          </div>
          <div className="flex justify-between flex-wrap ">
            <div>
              <p className="text-black text-sm font-medium">
                Pending Invites to associate
              </p>
              <p className="text-textColor text-xs font-normal">
                List of invites sent out to suppliers
              </p>
            </div>
            <div className="flex gap-x-[10px] mt-[10px] md:mt-0 flex-wrap">
              <div className="flex items-center  bg-white p-[10px] gap-x-[8px] rounded-[5px] border-[0.5px] border-silver xl:w-[350px]">
                <PAIconSearch />
                <input
                  type="text"
                  value={companyName}
                  onChange={handleSearch}
                  placeholder="Search Invites"
                  className="placeholder:text-xs outline-none"
                />
              </div>
              <Button
                neutral
                //   onClick={handleSearch}
                disabled={data < 1}
                className="py-[12px] mt-[10px] md:mt-0 px-[24px] border-[0.5px] border-silver bg-brightGray text-sm !text-black rounded-[5px] font-medium"
              >
                Search
              </Button>
            </div>
          </div>
        </header>
        <Table
          columns={column}
          data={data}
          pointer
          onRowClicked={onRowClicked}
          progressPending={loading}
          progressComponent={<Loading />}
          tableHeader
        />
      </Card>
    </DashboardLayout>
  );
};
export default PendingInvites;
