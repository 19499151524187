import React from "react";
import Button from "shared/Button";
import { formatCurrency } from "utilities/helper/formatters";

const BulkRequestModalContent = ({
  onClose,
  data,
  handleProceed,
  lengthOfInvoice,
  buyer,
  percentage,
  setPercentage,
  date,
  setDate,
  apply,
  setApply,
  calculateTotalInvoiceAmount,
  calculateDiscountTotalInvoiceAmount,
  totalRequestAmount,
  handleFetchDiscount
}) => {

  const isButtonEnabled = percentage && date;

  const handleApply = () => {
    handleFetchDiscount();
    setApply(true);
  };

  const disApply = () => {
    setApply(false);
  };

  return (
    <div className="w-full">
      <div className="w-[95%] mx-auto">
        <div className="border-b-4 border-[#F6F5FA] pb-[12px] flex justify-between">
          <div>
            <span className="text-[20px] font-light">Bulk Invoice Request</span>
          </div>
          <div className="cursor-pointer" onClick={onClose}>
            <span>X Close</span>
          </div>
        </div>

        <div className="flex justify-between pt-2">
          <div>
            <span>Total Invoice Amount</span>
          </div>
          <div>
            <span>
              Total Invoices:{" "}
              <span className="text-[#959595]">{lengthOfInvoice} Invoices</span>
            </span>
          </div>
        </div>
        <div className="flex justify-between">
          <div>
            <span
              className={`text-2xl font-extrabold ${
                apply ? "text-[#959595] line-through" : "text-primaryColor"
              }`}
            >
              NGN {formatCurrency(calculateTotalInvoiceAmount())}
            </span>
          </div>
          <div>
            <span>
              Buyer Info: <span className="text-[#959595]">{buyer}</span>
            </span>
          </div>
        </div>
        <div className="border-b-4 border-[#F6F5FA] pb-[12px] flex flex-col pt-4">
          <span className="text-[#959595]">
            Specify percentage invoice amount
          </span>
          <span className="text-[#959595]">
            Please indicate the percentage of the invoice for which early
            payment is required
          </span>
        </div>

        {apply ? (
          <div className="pt-4 flex justify-between">
            <div className="flex flex-col">
              <span className="text-[20px] font-light">
                Total Request Amount
              </span>
              <span className="text-primaryColor text-2xl font-extrabold">
                {formatCurrency(totalRequestAmount)}
              </span>
            </div>
            <div>
              <button
                onClick={disApply}
                style={{ backgroundColor: "#F08000", color: "white" }}
                className="border flex justify-center items-center rounded-[5px] text-xs  w-[155px]  h-[30px]"
              >
                Set new percentage
              </button>
            </div>
          </div>
        ) : (
          <div className="pt-4 flex flex-col sm:flex-row gap-4">
            <div className="relative w-full sm:w-1/2">
              <label>Set percent of amount for funding</label>
              <input
                // type="number"
                value={percentage}
                onChange={(e) => setPercentage(e.target.value)}
                className="w-full p-3 border rounded-md focus:outline-none focus:border-gray-400"
              />
              <span className="absolute right-3 top-8 text-gray-500">%</span>
            </div>

            <div className="relative w-full sm:w-1/2">
              <label>Payment Date</label>
              <input
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                className="w-full p-3 border rounded-md focus:outline-none focus:border-gray-400"
              />
            </div>
          </div>
        )}

        <div className="pt-2">
          {apply ? (
            <span className="text-[#F08000]">
              Payment request date: {date}
            </span>
          ) : (
            <span className="text-[#F08000]">
              Note: The maximum allowable request on each invoice is 80%
            </span>
          )}
        </div>
        <div className="pt-2">
          <Button
            onClick={apply ? handleProceed : handleApply}
            neutral
            className={`w-full py-[12px] ${
              isButtonEnabled ? "bg-[#06593D]" : "bg-[#C2C0C0]"
            } text-white mt-[10px] md:mt-0 px-[24px] rounded-[5px] font-medium`}
            disabled={!isButtonEnabled}
          >
            {apply
              ? "Proceed to Request"
              : "Apply percentage to Invoice Amount"}
          </Button>
        </div>
        <br />
      </div>
    </div>
  );
};

export default BulkRequestModalContent;
