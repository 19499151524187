import {
  PAIconHamburger,
  PAIconNotification,
  PAIconArrowDownGreen,
} from "assets/images/svgs";
import { HeaderContainer, Info, ProfileWrapper, TabName } from "./styles";
import { useKeycloak } from "@react-keycloak/web";
import DropdownCard from "shared/notificationcard";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";
// import { useSelector } from "react-redux";
import profileIcon from "../../../assets/images/svgs/profileIcon.svg";
import { useSelector } from "react-redux";
import { formatCapitaliseString } from "utilities/helper/formatCapitaliseString";

// import ViewMemberModal from "features/roleManager/Dashbaord/components/modals/ViewMemberModal";

function DashboardHeader({ section, subHeader, handleMobileMenu }) {
  const { keycloak } = useKeycloak();
  const { given_name } = keycloak?.idTokenParsed;
  const [dropdown, setDropdown] = useState(false);
  // const { approvedInvoicesData, isLoading } = useSelector(
  //   (state) => state?.supplier
  // );

  const { currentUser, userId, hasCompleted2FA, userDetails } = useSelector(
    (state) => state.auth
  );

  const { staffProfileByIdData } = useSelector((state) => state.roleManager);

  const constants = [
    "default-roles-payassyst",
    "offline_access",
    "uma_authorization",
  ];
  const roleIndex = userId?.realm_access?.roles.findIndex(
    (role) => !constants.includes(role)
  );

  const userRole = userId?.realm_access?.roles[roleIndex]?.toLowerCase();

  const player = userRole?.includes("buyer")
    ? "buyer"
    : userRole?.includes("funder")
    ? "funder"
    : "supplier";

  const [profileDropdown, setProfileDropdown] = useState(false);

  const handleDropdownClick = () => {
    setProfileDropdown(!profileDropdown);
  };

  return (
    <HeaderContainer>
      <PAIconHamburger
        onClick={handleMobileMenu}
        className="ml-[10px] md:hidden"
      />
      <div className="ml-[15px] md:ml-[37px]">
        <TabName style={{ color: "#222222" }}>
          {section ? section : `Hello, ${given_name.split(" ")[0]}`}
        </TabName>
        <Info>{subHeader}</Info>
      </div>
      <ProfileWrapper>
        <PAIconNotification
          className="cursor-pointer"
          onClick={() => setDropdown(true)}
        />

        {/* <ViewMemberModal/> */}
        <AnimatePresence>
          {dropdown && <DropdownCard hide={() => setDropdown(false)} />}
        </AnimatePresence>

        <div className="flex justify-between ml-[7%] items-center border-[1px] border-[#7A7A7A80] p-[10px] h-[50px] rounded-[4px] w-[146px]">
          <div>
            <h5 className="text-[12px] text-[#7A7A7A]">Current Role</h5>
            <h4 className="text-[14px] font-semibold">
              {formatCapitaliseString(player)}
            </h4>
          </div>
          <div onClick={handleDropdownClick} className="ml-[5px]">
            <PAIconArrowDownGreen />
          </div>
          {profileDropdown && (
            <div className="absolute bg-white w-[136px] top-[60px] z-[1] rounded-[4px] ml-[-10px] border-[0.7px] border-[#D1D6DD]">
              <div className="flex p-[15px] items-center border-b-[1px] border-b-slate-300">
                <img src={profileIcon} alt="" />
                <h4 className="text-[14px] font-medium ml-2">Supplier</h4>
              </div>
              <div className="flex items-center p-[15px]">
                <img src={profileIcon} alt="" />
                <h4 className="text-[14px] font-medium ml-2">Funder</h4>
              </div>
            </div>
          )}
        </div>
      </ProfileWrapper>
    </HeaderContainer>
  );
}
export default DashboardHeader;
