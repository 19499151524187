import { PAIconCocacola } from "assets/images/svgs";
import React from "react";
import { useSelector } from "react-redux";
import TransitionScale from "shared/animation/TransitionScale";
import { formatCapitalize } from "utilities/formatCapitalize";
import { getDaysLeft } from "utilities/getDaysLeft";
import { formatCurrency } from "utilities/helper/formatters";

const EPRDetailsRight = () => {
  const { funderEarlyPaymentViewSingleApprovedDetailsData } = useSelector(
    (state) => state?.funder
  );

  // funderEarlyPaymentViewSingleApprovedDetailsData

  return (
    <TransitionScale className="bg-white rounded-[10px] gap-6 w-full">
      <div class="w-full justify-start items-center gap-4 inline-flex ">
        <div class=" justify-start items-center gap-2.5 flex w-full px-6  py-3">
          <div class="text-black text-xl font-bold">Early Payment Details</div>
        </div>
      </div>

      <div>
        <div className="   pb-4">
          <div class=" justify-between  flex flex-row    px-6 mt-4  ">
            <div className="flex gap-2 py-2.5">
              <PAIconCocacola />
              <div className="flex flex-col">
                {/* <span className=" text-black text-xs font-normal">
              Coca Cola
            </span> */}
                <span class="text-black text-sm font-normal ">
                  {formatCapitalize(
                    funderEarlyPaymentViewSingleApprovedDetailsData?.data
                      ?.supplierCompanyName
                  ) || "N/A"}
                </span>
                {/* <span className="text-[#979797]  text-xs">Supplier</span> */}
                <span class="text-[#949494] text-sm font-normal ">
                  Supplier
                </span>
              </div>
            </div>
            <div class="py-2.5 flex-col justify-start items-start inline-flex">
              <div class="flex-col justify-start items-start gap-0.5 flex">
                <div class="text-black text-sm font-normal ">
                  {" "}
                  {funderEarlyPaymentViewSingleApprovedDetailsData?.data
                    ?.discountRate || "-"}
                  %
                </div>
              </div>
              <div class="flex-col justify-start items-start  flex">
                <div class="text-[#949494] text-sm font-normal ">
                  Discount rate
                </div>
              </div>
            </div>

            {/* here */}
          </div>
          <div class=" justify-between  flex flex-row    px-6  mt-6 ">
            <div class="py-2.5 flex-col justify-start items-start inline-flex">
              <div class="flex-col justify-start items-start gap-0.5 flex">
                <div class="text-black text-sm font-normal ">
                  NGN
                  {formatCurrency(
                    funderEarlyPaymentViewSingleApprovedDetailsData?.data
                      ?.discountAmount ?? 0
                  )}
                </div>
              </div>
              <div class="flex-col justify-start items-start  flex">
                <div class="text-[#949494] text-sm font-normal ">
                  Discount amount
                </div>
              </div>
            </div>
            {/* hd */}
            <div class="py-2.5 flex-col justify-start items-start inline-flex">
              <div class="flex-col justify-start items-start gap-0.5 flex">
                <div class="text-black text-sm font-normal ">
                  {funderEarlyPaymentViewSingleApprovedDetailsData?.data
                    ?.earlyPaymentDate ?? 0}{" "}
                  (
                  {getDaysLeft(
                    funderEarlyPaymentViewSingleApprovedDetailsData?.data
                      ?.earlyPaymentDate
                  )}{" "}
                  days)
                </div>
              </div>
              <div class="flex-col justify-start items-start  flex">
                <div class="text-[#949494] text-sm font-normal ">
                  Early Payment Date
                </div>
              </div>
            </div>{" "}
          </div>
          <div class=" justify-between  flex flex-row    border-b border-[#c2c2c2]/30 px-6  py-3  pb-8 mt-6">
            <div class="py-2.5 flex-col justify-start items-start inline-flex">
              <div class="flex-col justify-start items-start gap-0.5 flex">
                <div class="text-black text-sm font-normal ">
                  N{" "}
                  {formatCurrency(
                    funderEarlyPaymentViewSingleApprovedDetailsData?.data
                      ?.requestAmount ?? 0
                  )}
                </div>
              </div>
              <div class="flex-col justify-start items-start  flex">
                <div class="text-[#949494] text-sm font-normal ">
                  Request amount
                </div>
              </div>
            </div>
            {/* dhdh */}
            <div class="py-2.5 flex-col justify-start items-start inline-flex">
              <div class="flex-col justify-start items-start gap-0.5 flex">
                <div class="text-black text-sm font-normal ">
                  {formatCurrency(
                    funderEarlyPaymentViewSingleApprovedDetailsData?.data
                      ?.fundableAmount ?? 0
                  )}
                </div>
              </div>
              <div class="flex-col justify-start items-start  flex">
                <div class="text-[#949494] text-sm font-normal ">
                  Fundable Amount
                </div>
              </div>
            </div>
          </div>
          {/* 2nd */}
          <div class=" justify-between  flex flex-col    border-b border-[#c2c2c2]/30 px-6  pb-10 ">
            <div class="text-black text-lg font-semibold  mt-6">
              Note from Supplier:{" "}
            </div>

            <div class=" text-[#949494] text-sm font-light  mt-4  leading-6">
              {funderEarlyPaymentViewSingleApprovedDetailsData?.data
                ?.description || "No Message!"}
              <br className=" mt-3" />
              Best regards, <br />
              {formatCapitalize(
                funderEarlyPaymentViewSingleApprovedDetailsData?.data
                  ?.supplierCompanyName
              ) || "N/A"}
            </div>
          </div>{" "}
          {/* another header */}
          {/* header ends here */}
          {/* here */}
          <div class="justify-between  flex flex-col  px-6">
            <div class="text-black text-lg font-semibold  mt-6">
              Funding Option
            </div>

            <div class=" text-[#949494] font-light  my-3 leading-6">
              This invoice would be funded from this virtual account:
            </div>
          </div>
          <div
            style={{ color: "rgba(0, 0, 0, 0.50)" }}
            className="flex flex-col px-6 text-sm font-semibold"
          >
            <span>
              {formatCapitalize(
                funderEarlyPaymentViewSingleApprovedDetailsData?.data
                  ?.funderVirtualAccountName
              )}
            </span>
            <span>
              {" "}
              {funderEarlyPaymentViewSingleApprovedDetailsData?.data?.funderVirtualAccountNumber?.slice(
                0,
                2
              )}
              *******{" "}
              {funderEarlyPaymentViewSingleApprovedDetailsData?.data?.funderVirtualAccountNumber?.slice(
                7,
                11
              )}
            </span>
            <span> PayEdge MFB</span>
          </div>
        </div>
      </div>
    </TransitionScale>
  );
};

export default EPRDetailsRight;
