import { PAIconInventoryArrowLeft } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import Table from "layout/table/Table";
import { useNavigate } from "react-router-dom";
import TableIcons from "../../../assets/images/svgs/TableIcons.svg";

const dummyData = [
  {
    invoiceReference: "INV-001",
    buyer: "Acme Corp",
    invoiceDate: "2024-09-10",
    dueDate: "2024-10-10",
    currency: "USD",
    invoiceAmount: "$2,500",
    status: "Approved",
  },
  {
    invoiceReference: "INV-002",
    buyer: "Global Industries",
    invoiceDate: "2024-08-15",
    dueDate: "2024-09-15",
    currency: "EUR",
    invoiceAmount: "€3,200",
    status: "Approved",
  },
  {
    invoiceReference: "INV-003",
    buyer: "Tech Solutions",
    invoiceDate: "2024-07-20",
    dueDate: "2024-08-20",
    currency: "GBP",
    invoiceAmount: "£1,800",
    status: "Approved",
  },
  {
    invoiceReference: "INV-004",
    buyer: "Omega Electronics",
    invoiceDate: "2024-06-25",
    dueDate: "2024-07-25",
    currency: "USD",
    invoiceAmount: "$5,000",
    status: "Approved",
  },
];

const DirectFundingInvoiceDetailsPage = () => {
  const navigate = useNavigate();

  function handleNavigate() {
    navigate(-1);
  }

  const onRowClicked = (row) => {
    let path = "/directfunding-invoice-details";
    navigate(path, {state: {directFunding: true}});
  };

  const HeaderWithIcon = ({ icon, text }) => (
    <div className="flex items-center">
      <img src={icon} alt="" className="mr-2" />
      <span>{text}</span>
    </div>
  );

  const column = [
    {
      name: <HeaderWithIcon icon={TableIcons} text="Invoice Reference" />,
      selector: (row) => row?.invoiceReference,
      width: "15rem",
    },
    {
      name: <HeaderWithIcon icon={TableIcons} text="Buyer" />,
      selector: (row) => row?.buyer,
      width: "15rem",
    },
    {
      name: "Invoice Date",
      selector: (row) => row?.invoiceDate,
    },
    {
      name: "Due Date",
      selector: (row) => row?.dueDate,
    },
    {
      name: "Currency",
      selector: (row) => row?.currency,
    },
    {
      name: "Invoice Amount",
      selector: (row) => row?.invoiceAmount,
    },
    {
      name: "Status",
      selector: (row) => (
        <span
          style={{
            color: row.status === "Approved" ? "#2EA923" : "black",
          }}
        >
          {row?.status}
        </span>
      ),
    },
  ];

  return (
    <DashboardLayout
      section="Invoice"
      subHeader="Here is an overview of your invoices"
    >
      <header className="bg-alabasterHeader p-[24px] rounded-t-[10px]">
        <div
          onClick={handleNavigate}
          className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
        >
          <PAIconInventoryArrowLeft />
          <p className="mt-[2px] text-sm font-normal text-lightGray">Go Back</p>
        </div>
        <div className="flex justify-between flex-wrap ">
          <div>
            <p className="text-black text-sm font-medium">
              78 Approved Invoices
            </p>
            <p className="text-textColor text-xs font-normal">
              Available for payment
            </p>
          </div>
        </div>
      </header>
      <div className="p-[24px] bg-white rounded-b-[10px]">
        <Table
          columns={column}
          pointer
          data={dummyData}
          onRowClicked={onRowClicked}
        />
      </div>
    </DashboardLayout>
  );
};

export default DirectFundingInvoiceDetailsPage;
