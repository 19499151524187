import React from "react";
import SingleRequestModalContent from "./SingleRequestModalContent";

const SingleRequestModal = ({
  isShown,
  onClose,
  data,
  directFunding,
  invoiceData,
  id,
  row,
}) => {
  if (!isShown) return null;

  const handleModalClick = (e) => e.stopPropagation();

  return (
    <div
    className="w-full fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10"
    onClick={onClose}
  >
    <div
      className="bg-white w-full lg:w-[70%] max-h-[80vh] overflow-y-auto rounded-lg shadow-lg p-6"
      onClick={handleModalClick}
    >
        <SingleRequestModalContent
          data={data}
          onClose={onClose}
          directFunding={directFunding}
          invoiceData={invoiceData}
          id={id}
          row={row}
        />
      </div>
    </div>
  );
};

export default SingleRequestModal;
