import {
  // PAIconEmailIcon,
  PAIconIconParkSucess,
  PAIconInventoryArrowLeft,
  PAIconMDISuccess,
  // PAIconSmallClose,
} from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Card from "shared/Card";
import { AnimatePresence } from "framer-motion";
import ViewDocumentModal from "features/RulesConfig/common/components/modals/DeleteRuleModal";
// import MailSentModal from "./components/modals/MailSentModal";
import Table from "layout/table/Table";
import PinModal from "shared/PinModal";
import AcceptModal from "shared/AcceptModal";
import FundAccountOption from "./components/modals/SelectAccountModal";
import toast from "react-hot-toast";
import { fundPfpInvoice, viewPfpById } from "appstate/funder/funderSlice";

const Pfpdetails = () => {
  const [leavePFPModal, setLeavePFPModal] = useState(false);
  // const [mailSentModal, setMailSentModal] = useState(false);
  const [isFundAccountOptionOpen, setIsFundAccountOptionOpen] = useState(false);
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [fundAccountPinModal, setFundAccountPinModal] = useState(false);
  const [selectedOfferId, setSelectedOfferId] = useState(null); 

  const [selectedInvoicesTotalAmount, setSelectedInvoicesTotalAmount] =
    useState(0);
  const selectedAccounts = useSelector(
    (state) => state?.wallet?.selectedAccounts
  );
  const { viewPfpByIdData, pfpId } = useSelector((state) => state?.funder);
  const dispatch = useDispatch();

  const details = viewPfpByIdData?.data || {};
  // const details = allFunderPfpData?.data?.find((data) => data?.pfpId === pfpId);
  // console.log("details", viewPfpByIdData);

  useEffect(() => {
    if (pfpId) {
      dispatch(viewPfpById({ id: pfpId }));
    }
  }, [pfpId, dispatch]);

  const navigate = useNavigate();
  function handleGoback() {
    let path = -1;
    navigate(path);
  }

  const handleAccountSubmit = () => {
    setIsFundAccountOptionOpen(false);
    setIsAcceptModalOpen(true);
  };

  const AcceptModalConfirm = () => {
    setIsAcceptModalOpen(false);
    setFundAccountPinModal(true);
  };

  const handleFundInvoice = (offerId) => {
    const invoice = details?.invoices?.find(
      (invoice) => invoice.offerId === offerId
    );
    if (invoice) {
      setSelectedInvoicesTotalAmount(invoice.invoiceAmount);
      setSelectedOfferId(offerId);
      setIsFundAccountOptionOpen(true);
    }
  };

  function handleDownloadSuccessful(pin) {
    setFundAccountPinModal(false);

    const fundInvoice = {
      invoiceIds: [selectedOfferId],
      virtualAccountId: selectedAccounts?.id || "",
      transactionPin: pin,
    };
    // console.log(body);
    dispatch(fundPfpInvoice({ pfpId, body: fundInvoice })).then((data) => {
      if (data?.payload?.success) {
        toast.custom(
          <>
            <div className="flex-1 items-center justify-center flex w-full h-full">
              <div className="flex mt-[300px]">
                <div className="bg-primaryColor w-[147.79px] h-[160px] flex flex-col items-center justify-center rounded-l-[7px] shadow-lg">
                  <PAIconMDISuccess />
                </div>
                <div className="bg-white  flex-col justify-center items-center self-stretch py-[25px] h-[160px] rounded-r-[7px] shadow-lg flex px-16 ">
                  <PAIconIconParkSucess />
                  <p className="text-dustyGray text-[13px] text-center mt-[17.6px]">
                    Account{" "}
                    <span className="font-bold text-primaryColor">
                      {" "}
                      {selectedAccounts?.accountNumber}{" "}
                    </span>{" "}
                    <br /> successfully been liened to fund <br /> invoice
                  </p>
                </div>
              </div>
            </div>
          </>
        );
      }
    });

    setTimeout(() => {
      toast.dismiss();
      navigate(pathh);
    }, 3000);

    let pathh = "/payablefinancing-campaign";
  }

  const columns = [
    {
      name: "Invoice Reference",
      selector: (row) => row.invoiceReference,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
    },
    {
      name: "Due Date",
      selector: (row) => row.dueDate,
      sortable: true,
    },
    {
      name: "Payment Date",
      selector: (row) => row.paymentDate,
      sortable: true,
    },
    {
      name: "Invoice Amount",
      selector: (row) =>
        Number(row.invoiceAmount).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      sortable: true,
    },
    {
      name: "Offer Amount",
      selector: (row) =>
        Number(row.offerAmount).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      sortable: true,
    },
    {
      name: "Funder Share",
      selector: (row) =>
        Number(row.funderShare).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      sortable: true,
      cell: (row) => (
        <p className="text-primaryColor">
          {Number(row.funderShare).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </p>
      ),
    },
    {
      name: "Fundabale Amount",
      selector: (row) =>
        Number(row.fundableAmount).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      sortable: true,
      cell: (row) => (
        <p className="text-primaryColor">
          {Number(row.fundableAmount).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </p>
      ),
    },

    {
      name: "Status",
      selector: (row) => (
        <span
          style={{
            color:
              row.status === "ACCEPTED"
                ? "green"
                : row.status === "ACTIVE"
                ? "#90EE90"
                : row.status === "REJECTED"
                ? "red"
                : "orange",
            fontWeight: "bold",
          }}
        >
          {row.status}
        </span>
      ),
      sortable: true,
    },
    {
      // name: "Action",
      cell: (row) => (
        <button
          className={`py-2 px-4 rounded text-white ${
            row.status === "ACTIVE"
              ? "bg-green-900"
              : "bg-gray300 cursor-not-allowed"
          }`}
          disabled={row.status !== "ACTIVE"}
          onClick={() => handleFundInvoice(row?.offerId)}
        >
          Fund
        </button>
      ),
    },
  ];

  const InvoiceDetails = (offerId, status) => {
    // let path = "/collections/recievables";
    let path = "";

    switch (status) {
      case "ACCEPTED":
        path = `/payablefinancing-campaign/invoice/${offerId}`;
        break;
      case "REJECTED":
        path = `/payablefinancing-campaign/rejected/${offerId}`;
        break;
      case "PENDING":
        path = `/payablefinancing-campaign/invoice/${offerId}`;
        break;
      case "ACTIVE":
        path = `/payablefinancing-campaign/invoice/${offerId}`;
        break;
      case "CREATED":
        path = `/payablefinancing-campaign/invoice/${offerId}`;
        break;
      default:
        break;
    }

    navigate(path);
  };

  let path = "/payablefinancing-campaign/funder-payablefinance-view-details";

  return (
    <DashboardLayout
      section="Payable Finance Program"
      subHeader="Here is an overview of your PFP"
    >
      <div className="min-h-full">
        <div
          onClick={handleGoback}
          className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
        >
          <PAIconInventoryArrowLeft />
          <p className="mt-[2px] text-sm font-normal text-lightGray">Go Back</p>
        </div>
        <header className="bg-alabasterHeader px-6 py-4 rounded-t-[10px]">
          <div className="flex justify-between">
            <div className="flex flex-col  justify-center">
              <p className="text-black text-sm font-medium">Details</p>
              <p className="text-textColor text-xs font-normal">
                Basic information about the Payable finance program
              </p>
            </div>
            {/* <motion.button
              onClick={() => setLeavePFPModal(true)}
              whileTap={{ scale: 0.9 }}
              className="py-[12px] px-[24px] bg-[#A92B23] rounded-[5px] border-[1px] border-silver !text-white text-sm"
            >
              Leave
            </motion.button> */}
          </div>
        </header>
        <Card className=" min-h-[80%] md:px-[40px] pb-[40px] rounded-b-[10px] ">
          <div className="">
            <div className="md:flex md:justify-between  pt-[24px] 2xl:justify-start 2xl:gap-x-[370px] ">
              {/* Here */}
              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-2">
                  <p className="text-sm font-normal text-lightGray">
                    Name of Program
                  </p>
                  <p className="text-textColor text-sm font-normal">
                    {details?.programName || "Payable Flex"}
                  </p>
                </div>

                <div className="flex flex-col gap-2">
                  {" "}
                  <p className="text-sm font-normal text-lightGray">
                    Discount Rate
                  </p>
                  <p className=" text-textColor text-sm font-normal">
                    {details?.discountRate || "2%"}
                  </p>
                </div>

                <div className="flex flex-col gap-2">
                  <p className="text-sm font-normal text-lightGray">Duration</p>

                  <div className="flex flex-row">
                    <span className="text-[#BFBFBF] mr-1 font-bold text-sm">
                      From:
                    </span>
                    <span className="text-textColor text-sm font-normal">
                      {details?.startDate || "12-03-24"}
                    </span>

                    <span className="text-[#BFBFBF] mr-1 font-bold ml-2 text-sm">
                      To:
                    </span>
                    <span className="text-textColor text-sm font-normal">
                      {details?.endDate || "12-03-24"}
                    </span>
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-2">
                  <p className="text-sm text-[#7A7A7A]">Terms of Agreement</p>
                  <p
                    className="cursor-pointer underline text-lightGray text-sm font-normal"
                    onClick={() =>
                      navigate(`/payablefinancing-campaign/pfpagreement/${pfpId}`)
                    }
                  >
                    View agreement
                  </p>
                </div>

                <div className="flex flex-col gap-2">
                  <p className="text-sm text-[#7A7A7A]">Payment Period</p>
                  <p className="cursor-pointer text-lightGray text-sm font-normal">
                    {details?.paymentPeriod}
                  </p>
                </div>

                <div className="flex flex-col gap-2">
                  <p className="text-sm text-[#7A7A7A]">Percentage Offer</p>
                  <p className="cursor-pointer text-lightGray text-sm font-normal">
                    {details?.offerPercentage}
                  </p>
                </div>

                <div className="flex flex-col gap-2">
                  <span className="text-sm text-[#7A7A7A]">
                    Number of Funders in Program
                  </span>
                  <p className="cursor-pointer text-sm font-normal">
                    {details?.noOfFunders}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Here */}
          <div className="mt-[48px] flex flex-col gap-6">
            <div className=" w-full flex justify-between">
              <span className="text-sm gap-2.5 font-semibold md:justify-start text-textColor flex items-center">
                Number of Invoices
                <span className="w-[25px] h-[25px] flex justify-center items-center bg-conch  rounded-[50%]">
                  {details?.noOfInvoices}
                </span>
              </span>

              <Link
                to={path}
                className=" text-sm underline text-[#222] cursor-pointer"
              >
                View Invoices
              </Link>
            </div>

            {/* <main className="flex flex-wrap gap-6">
              <motion.div
                whileTap={{ scale: 0.9 }}
                className="flex cursor-pointer mb-2 flex-col gap-4"
              >
                <PAIconInvoiceIcon />

                <span className=" text-xs text-[#222]">#INV-2012</span>
              </motion.div>
              <motion.div
                whileTap={{ scale: 0.9 }}
                className="flex cursor-pointer mb-2 flex-col gap-4"
              >
                <PAIconInvoiceIcon />

                <span className=" text-xs text-[#222]">#INV-2012</span>
              </motion.div>
              <motion.div
                whileTap={{ scale: 0.9 }}
                className="flex cursor-pointer mb-2 flex-col gap-4"
              >
                <PAIconInvoiceIcon />

                <span className=" text-xs text-[#222]">#INV-2012</span>
              </motion.div>
            </main> */}

            <div className="mt-[24px] pr-[30px]">
              <Table
                columns={columns}
                // data={dummyData}
                data={details?.invoices?.slice(0, 5)}
                // onRowClicked={onRowClicked}
                onRowClicked={(row) => InvoiceDetails(row.offerId, row.status)}
                pointer
                tableHeader
                className="min-h-[35vh]"
              />
            </div>
          </div>
        </Card>
      </div>
      <AnimatePresence>
        {leavePFPModal && (
          <ViewDocumentModal
            onAction={() => {
              setLeavePFPModal(false);
              // setTimeout(() => {
              //   setMailSentModal(true);
              // }, 1200);
            }}
            onClose={() => setLeavePFPModal(false)}
            proceed="Send Request"
            reject="Cancel"
          >
            <div className=" text-center">
              <div>Are you sure you would like to leave this Payable</div>
              <div> Financing Program?</div>
            </div>
          </ViewDocumentModal>
        )}
      </AnimatePresence>

      {/* {mailSentModal && (
          <MailSentModal>
            <div className="flex flex-col gap-8">
              <header className="flex items-center justify-between">
                <span>Email sent</span>

                <span
                  onClick={() => setMailSentModal(false)}
                  className="flex gap-1 cursor-pointer items-center"
                >
                  <PAIconSmallClose />
                  Close
                </span>
              </header>

              <main className="flex flex-col gap-2.5">
                <span className=" text-center flex justify-center">
                  <PAIconEmailIcon />
                </span>
                <span className="text-[#959595] text-center">
                  An email has been sent. Request pending buyer's approval
                </span>
              </main>
            </div>
          </MailSentModal>
        )} */}
      <FundAccountOption
        isOpen={isFundAccountOptionOpen}
        onClose={() => setIsFundAccountOptionOpen(false)}
        HeaderText={
          <p>
            Select which account to fund with <br />{" "}
            <span className="text-xs text-center font-base">
              Amount of invoice: NGN{" "}
              {selectedInvoicesTotalAmount.toLocaleString()}
            </span>
          </p>
        }
        onSubmit={handleAccountSubmit}
      />

      <AcceptModal
        isOpen={isAcceptModalOpen}
        onClose={() => setIsAcceptModalOpen(false)}
        message={
          <>
            <p className="mb-3">
              Total invoice amount:{" "}
              <span className="text-primaryColor font-bold">
                {" "}
                NGN {selectedInvoicesTotalAmount.toLocaleString()}{" "}
              </span>
            </p>
            Would you like to proceed to fund the invoice with <br /> this
            account{" "}
            <span className="text-primaryColor font-bold">
              {selectedAccounts?.accountNumber}
            </span>{" "}
            ?
          </>
        }
        onConfirm={AcceptModalConfirm}
        fund
      />
      <PinModal
        isOpen={fundAccountPinModal}
        onClose={() => {
          setFundAccountPinModal(false);
        }}
        onSubmit={handleDownloadSuccessful}
        // isLoading={isLoading}
        headerText="Fund Account"
        instructionText="Enter your transaction PIN to use the desired account for this transaction."
      />
    </DashboardLayout>
  );
};

export default Pfpdetails;
