import React from "react";

const ReasonModal = ({ isOpen, onClose, setReason, reason, proceedStopCampaign }) => {

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-10/12 max-w-md p-6 bg-white rounded-lg shadow-lg">
        <div className="flex justify-between items-center border-b-[1px] border-silver pb-2">
          <h2 className="text-lg font-semibold">Reason Required</h2>
          <button
            className="text-gray-500 hover:text-gray-800"
            onClick={onClose}
          >
            X Close
          </button>
        </div>
        <p className="mt-4 text-sm text-[#777777]">
          Please provide a detailed explanation for trying to stop the campaign.
          Your insights assist in improving our platform and understanding user
          preferences.
        </p>
        <p className="mt-2 text-sm text-[#777777]">
          Thank you for your valuable feedback.
        </p>
        <textarea
          className="w-full mt-4 p-2 border-[1px] border-silver rounded-md focus:outline-none"
          placeholder="Input your reply"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          rows={4}
        />
        <button
          className="w-full mt-4 px-4 py-2 bg-primaryColor text-white rounded-md"
          onClick={() => {
            proceedStopCampaign()
            onClose();
          }}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default ReasonModal;
