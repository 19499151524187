import * as Yup from "yup";

export const externalAccountValidationSchema = Yup.object({
  currency: Yup.string().required("This field is required"),
  // accountName: "Yup.string().required('This field is required')",
  accountHolderName: Yup.string().required("This field is required"),
  bankName: Yup.string().required("This field is required"),
  accountNumber: Yup.number()
    .required("This field is required")
    .test(
      "len",
      "Must be exactly 10 digits",
      (val) => val && val.toString().length === 10
    ),
  bvn: Yup.number()
    .required("This field is required")
    .test(
      "len",
      "Must be exactly 11 numbers",
      (val) => val && val.toString().length === 11
    ),
});
