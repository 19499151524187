import React, { useState, useRef, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import TransferInfoReceipt from "features/wallet/withdraw/receipt";
import {
  PAIconCancelMini,
  PAIconIconParkSucess,
  PAIconMDISuccess,
} from "assets/images/svgs";
import toast from "react-hot-toast";
import ProgressModal from "shared/progressModal/ProgressModal";

const EnterPin = ({ isOpen, onClose, onSubmit, transferInfoValues }) => {
  const [otpInputs, setOtpInputs] = useState(["", "", "", ""]);
  const [showReceipt, setShowReceipt] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hidePin, setHidePin] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);

  const inputRefs = useRef([]);

  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, otpInputs.length);
  }, [otpInputs.length]);

  const handleInputChange = (index, value) => {
    if (!isNaN(value) || value === "") {
      const newOtpInputs = [...otpInputs];
      newOtpInputs[index] = value;
      setOtpInputs(newOtpInputs);

      if (value !== "" && index < otpInputs.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleDownloadSuccessful = () => {
    toast.custom(
      <div className="flex-1 items-center justify-center flex w-full h-full">
        <div className="flex mt-[300px]">
          <div className="bg-primaryColor w-[147.79px] h-[160px] flex flex-col items-center justify-center rounded-l-[7px] shadow-lg">
            <PAIconMDISuccess />
          </div>
          <div className="bg-white flex-col justify-center items-center self-stretch py-[25px] h-[160px] rounded-r-[7px] shadow-lg flex px-16 ">
            <PAIconIconParkSucess />
            <p className="text-dustyGray text-[13px] text-center mt-[17.6px]">
              Funds disbursed successfully
            </p>
          </div>
        </div>
      </div>
    );

    setTimeout(() => {
      toast.dismiss();
      setShowReceipt(true);
      onClose();
      setHidePin(false);
    }, 500);
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && index > 0 && otpInputs[index] === "") {
      const newOtpInputs = [...otpInputs];
      newOtpInputs[index - 1] = "";
      setOtpInputs(newOtpInputs);

      inputRefs.current[index - 1].focus();
    }
  };

  const modalVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 50 },
  };

  return (
    <>
      <AnimatePresence>
        {isOpen && !hidePin && (
          <motion.div
            className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg rounded-[10px] z-[9999]"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={modalVariants}
            transition={{ duration: 0.3 }}
          >
            <div className="bg-white p10 px5 rounded-md max-w-xs md:max-w-lg w-full pb-5 px10">
              <div className="flex justify-between items-center py-5 px-6 rounded-t-[10px] bg-[#FAFAFA]">
                <p className="text-sm text-[#7A7A7A] font-thin">
                  Transfer Funds
                </p>
                <button
                  onClick={onClose}
                  className="gap-2 text-xl cursor-pointer flex justify-center items-center"
                >
                  <PAIconCancelMini />{" "}
                  <span className="text-sm text-[#565656] flex items-center">
                    Close
                  </span>
                </button>
              </div>

              <form
                className="w-full px-7 pt-7"
                onSubmit={(e) => {
                  e.preventDefault();
                  setHidePin(true);
                  setIsLoading(true);
                  setPaymentModal(true);
                  setTimeout(() => {
                    setIsLoading(false);
                    setTimeout(() => {
                      setPaymentModal(false);
                      handleDownloadSuccessful();
                    }, 300);
                  }, 3000);
                }}
              >
                <p className="text-[#7A7A7A] text-sm font-normal mb-5">
                  Step 2
                </p>
                <p className="mb-5 flex justify-center text-[#7A7A7A] font-thin">
                  Enter your PIN
                </p>
                <div className="mb-5 flex flex-row justify-center gap-2">
                  {otpInputs.map((value, index) => (
                    <input
                      key={index}
                      type="password"
                      value={value}
                      onChange={(e) => handleInputChange(index, e.target.value)}
                      onKeyDown={(e) => handleKeyDown(index, e)}
                      maxLength="1"
                      className={`border rounded p-2 ${
                        otpInputs[otpInputs.length - 1] === ""
                          ? "border-[#7A7A7A] focus:border-[#7A7A7A]"
                          : "border-[#07593D]"
                      } focus:outline-none w-10 h-10 flex-shrink-0 text-center font-bold`}
                      ref={(ref) => (inputRefs.current[index] = ref)}
                    />
                  ))}
                </div>
                <p className="text-[#7A7A7A] text-sm font-normal flex justify-center my-5">
                  Enter your transaction PIN to transfer funds to desired
                  account
                </p>

                <div className="flex gap-4 justify-end mt-10">
                  <button
                    type="button"
                    onClick={onClose}
                    className="text-center px-6 py-2.5 rounded-[5px] text-[#7A7A7A] text-base font-normal font-['SF Pro Display'] tracking-wide"
                  >
                    Cancel
                  </button>
                  <button
                    disabled={otpInputs[otpInputs.length - 1] === ""}
                    className={`text-center ${
                      otpInputs[otpInputs.length - 1] === ""
                        ? "bg-[#EDEDED] text-[#7A7A7A]"
                        : "bg-primaryColor text-white"
                    } px-6 py-2.5 rounded-[5px] text-base font-normal`}
                    type="submit"
                  >
                    Next
                  </button>
                </div>
              </form>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showReceipt && (
          <TransferInfoReceipt
            isOpen={showReceipt}
            onClose={() => {
              setOtpInputs(["", "", "", ""]);
              onClose();
              setShowReceipt(false);
            }}
          />
        )}
      </AnimatePresence>

      {paymentModal && (
        <ProgressModal
          openMarket
          openMarketText={
            isLoading ? "Transferring funds..." : "Funds Transferred"
          }
        />
      )}
    </>
  );
};

export default EnterPin;
