import Table from "layout/table/Table";
import React, { useEffect, useRef, useState } from "react";
// import { useSelector } from "react-redux";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";

const DashboardTable = ({ supplierDashboard }) => {
  const dropdownRef = useRef();
  const [dropdown, setDropdown] = useState(false);
  // const [atBottom, setAtBottom] = useState(false);

  // const handleDropdown = (row) => {
  //   if (dropdown === row.id) {
  //     setDropdown(null);
  //   } else {
  //     setDropdown(row.id);
  //   }
  // };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdown &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setDropdown(false);
      }
    };

    const handleDocumentClick = (e) => {
      // const screenHeight = window.innerHeight;
      // const clickY = e.clientY;
      // const bottomThreshold = 200;
      // if (screenHeight - clickY <= bottomThreshold) {
      //   setAtBottom(true);
      // } else {
      //   setAtBottom(false);
      // }
    };
    document.addEventListener("click", handleDocumentClick);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
      document.addEventListener("mousedown", handleClickOutside);
    };
  }, [dropdown]);

  const column = [
    {
      name: "Transaction ID",
      selector: (row) => row?.transactionId,
      width: "12rem",
    },
    {
      name: "Transaction Party",
      selector: (row) => row?.beneficiaryName,
      width: "12rem",
    },
    {
      name: "Transaction Type",
      selector: (row) => row?.transactionType,
      width: "9rem",
    },
    {
      name: "Transaction Date",
      selector: (row) => row?.transactionDate,
      width: "12rem",
    },
    {
      name: "Sign",
      selector: (row) => row?.sign,
    },
    {
      name: "Currency",
      selector: (row) => row?.currency,
    },

    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      cell: (row) =>
        row.sign === "Cr" ? (
          <p className="text-success">{row.amount}</p>
        ) : row.type === "Dr" || row.type === "Disbursement" ? (
          <p className="text-red">{row.amount}</p>
        ) : (
          <p>{row.status}</p>
        ),
      width: "7rem",
    },
    {
      name: "Status",
      selector: (row) => row.transactionStatus,
      sortable: true,
      cell: (row) =>
        row.transactionStatus === "SUCCESSFUL" ? (
          <p className="text-success">{row.transactionStatus}</p>
        ) : row.transactionStatus === "Failed" ? (
          <p className="text-red">{row.transactionStatus}</p>
        ) : row.transactionStatus === "Pending" ? (
          <p className="text-yellow-400">{row.transactionStatus}</p>
        ) : (
          <p>{row.transactionStatus}</p>
        ),
      width: "7rem",
    },
  ];

  return (
    <>
      {!supplierDashboard?.data?.transactions?.length ? (
        <>
          <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
            <p className="text-black text-sm font-medium">
              Recent Transactions
            </p>
          </div>
          <TableEmptyState dashboard />
        </>
      ) : (
        <Card className="rounded-[10px]">
          <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
            <p className="text-black text-sm font-medium">
              Recent Transactions
            </p>
            <p className="text-black text-sm font-medium underline cursor-pointer">
              View All
            </p>
          </div>
          <div className="px-[24px] pb-[24px]">
            <Table
              columns={column}
              data={supplierDashboard?.data?.transactions}
              pointer
              tableHeader
              className="mt-5"
            />
          </div>
        </Card>
      )}
    </>
  );
};

export default DashboardTable;
