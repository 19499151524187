import { PAIconAlert24 } from "assets/images/svgs";
import React from "react";

const OngoingAcceptUpdateBidModal = ({
  isOpen,
  onClose,
  handleYes,
  refNum,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-[1000]">
      <div className="bg-white p-5 rounded-md shadow-md flex flex-col items-center px-10 md:min-w-[614px] py-7">
        <PAIconAlert24 />
        <p className="text-[#979797] text-center font-sans text-base font-normal leading-normal tracking-wide mb-3 my-5">
          Are you sure you want to{" "}
          <span className="text-[#07593D] font-bold">Update you bid </span>on
          this
          <br /> <span>request with reference number </span>
          <span className="text-[#07593D] font-bold">{refNum}</span>??
        </p>
        <div className="flex justify-center mt-3">
          <button
            className="px-5 py-2 mr-5 text-[#07593D] border border-[#07593D rounded-md"
            onClick={handleYes}
          >
            Yes
          </button>
          <button
            className="px-5 py-2 bg-red-500 border border-red text-red rounded-md"
            onClick={onClose}
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default OngoingAcceptUpdateBidModal;
