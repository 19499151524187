import React, { useState } from "react";
import SearchInput from "./searchInput";
import { PAIconDropdown } from "assets/images/svgs";

const Headers = ({
  setIsClosedMarketType,
  activeTab,
  setIsWallet,
  setIsClosedMarket,
  setIsDirectFunding,
  selectedType,
  setSelectedType,
}) => {
  // const [selectedFilters, setSelectedFilters] = useState([]);
  // const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [processingType, setProcessingType] = useState(false);

  // const handleFilterChange = (e) => {
  //   const value = e.target.value;
  //   let updatedFilters = [...selectedFilters];

  //   if (updatedFilters.includes(value)) {
  //     updatedFilters = updatedFilters.filter((filter) => filter !== value);
  //   } else {
  //     updatedFilters.push(value);
  //   }

  //   setSelectedFilters(updatedFilters);
  // };

  // const handleDropdownToggle = () => {
  //   setIsDropdownOpen(!isDropdownOpen);
  // };

  const selectProcessingType = () => {
    setProcessingType(!processingType);
  };

  return (
    <div className="my-2 grid md:grid-cols-2 items-center">
      <div className="gap-3 flex relative">
        {activeTab === "earlyPaymentsProcessing" && (
          <div>
            <div
              className="flex items-center gap-[4px] border border-[#DEDEDE] bg-white shadow-sm rounded-[5px] px-4 py-2 text-sm focus:outline-none cursor-pointer"
              onClick={selectProcessingType}
            >
              {selectedType}
              <PAIconDropdown />
            </div>
            {processingType && (
              <div className="absolute top-full mt1 wfull border border-[#DEDEDE] shadow-lg rounded-b-[5px] py-3 bg-white">
                <p
                  onClick={() => {
                    setIsClosedMarketType(false);
                    setProcessingType(false);
                    setSelectedType("Request from funder");
                  }}
                  className="block border-b-[1px] cursor-pointer border-slate-300 px-4 pb-2"
                >
                  Request from funder
                </p>
                <p
                  onClick={() => {
                    setIsClosedMarketType(true);
                    setProcessingType(false);
                    setSelectedType("Request in closed market");
                  }}
                  className="block px-4 pt-2 cursor-pointer"
                >
                  Request in closed market
                </p>
              </div>
            )}
          </div>
        )}

        {activeTab === "earlyPayments" && (
          <div>
            <div
              className="flex items-center gap-[4px] border border-[#DEDEDE] bg-white shadow-sm rounded-[5px] px-4 py-2 text-sm focus:outline-none cursor-pointer"
              onClick={selectProcessingType}
            >
              {selectedType}
              <PAIconDropdown />
            </div>
            {processingType && (
              <div className="absolute top-full mt1 wfull border border-[#DEDEDE] shadow-lg rounded-b-[5px] py-3 bg-white z-10">
                <p
                  onClick={() => {
                    setIsWallet(true);
                    setProcessingType(false);
                    setIsDirectFunding(false);
                    setIsClosedMarket(false);
                    setSelectedType("All Funding Options");
                  }}
                  className="block border-b-[1px] cursor-pointer border-slate-300 px-4 pb-2"
                >
                  All Funding Options
                </p>
                <p
                  onClick={() => {
                    setIsWallet(true);
                    setProcessingType(false);
                    setIsDirectFunding(false);
                    setIsClosedMarket(false);
                    setSelectedType("Via Wallet");
                  }}
                  className="block border-b-[1px] pt-2 cursor-pointer border-slate-300 px-4 pb-2"
                >
                  Via Wallet
                </p>
                <p
                  onClick={() => {
                    setIsDirectFunding(true);
                    setProcessingType(false);
                    setIsWallet(false);
                    setIsClosedMarket(false);
                    setSelectedType("Direct Funding");
                  }}
                  className="block border-b-[1px] cursor-pointer border-slate-300 px-4 pb-2 pt-2"
                >
                  Direct Funding
                </p>
                <p
                  onClick={() => {
                    setIsClosedMarket(true);
                    setProcessingType(false);
                    setIsWallet(false);
                    setIsDirectFunding(false);
                    setSelectedType("Closed Market");
                  }}
                  className="block px-4 pt-2 cursor-pointer"
                >
                  Closed Market
                </p>
              </div>
            )}
          </div>
        )}

        {activeTab === "earlyPaymentsRejected" && (
          <div>
            <div
              className="flex items-center gap-[4px] border border-[#DEDEDE] bg-white shadow-sm rounded-[5px] px-4 py-2 text-sm focus:outline-none cursor-pointer"
              onClick={selectProcessingType}
            >
              {selectedType}
              <PAIconDropdown />
            </div>
            {processingType && (
              <div className="absolute top-full mt1 wfull border border-[#DEDEDE] shadow-lg rounded-b-[5px] py-3 bg-white z-10">
                <p
                  onClick={() => {
                    setProcessingType(false);
                    setSelectedType("All Rejected Request");
                  }}
                  className="block border-b-[1px] cursor-pointer border-slate-300 px-4 pb-2"
                >
                  All Rejected Request
                </p>
                <p
                  onClick={() => {
                    setProcessingType(false);
                    setSelectedType("Rejected By Funder");
                  }}
                  className="block border-b-[1px] pt-2 cursor-pointer border-slate-300 px-4 pb-2"
                >
                  Rejected By Funder
                </p>
                <p
                  onClick={() => {
                    setProcessingType(false);
                    setSelectedType("Expired in closed market");
                  }}
                  className="block border-b-[1px] cursor-pointer border-slate-300 px-4 pb-2 pt-2"
                >
                  Expired in closed market
                </p>
                <p
                  onClick={() => {
                    setProcessingType(false);
                    setSelectedType("Rejected Supplier request");
                  }}
                  className="block px-4 pt-2 cursor-pointer"
                >
                  Rejected Supplier request
                </p>
              </div>
            )}
          </div>
        )}

        {/* <div>
          <div
            className="flex items-center gap-[4px] border border-[#DEDEDE] bg-white shadow-sm rounded-[5px] px-4 py-2 text-sm focus:outline-none cursor-pointer"
            onClick={handleDropdownToggle}
          >
            Filter{" "}
            {selectedFilters.length > 0 && (
              <span className="bg-gray rounded-lg p-1 px-2">
                {selectedFilters.length}
              </span>
            )}{" "}
            Selected <PAIconDropdown />
          </div>
          {isDropdownOpen && (
            <div className="absolute top-full mt1 wfull border border-[#DEDEDE] shadow-lg rounded-b-[5px] px-4 py-3 bg-white">
              <label className="block">
                <input
                  type="checkbox"
                  value="date"
                  checked={selectedFilters.includes("date")}
                  onChange={handleFilterChange}
                />
                Date
              </label>
              <label className="block">
                <input
                  type="checkbox"
                  value="name"
                  checked={selectedFilters.includes("name")}
                  onChange={handleFilterChange}
                />
                Name
              </label>
              <label className="block">
                <input
                  type="checkbox"
                  value="currency"
                  checked={selectedFilters.includes("currency")}
                  onChange={handleFilterChange}
                />
                Currency
              </label>
              <label className="block">
                <input
                  type="checkbox"
                  value="amount"
                  checked={selectedFilters.includes("amount")}
                  onChange={handleFilterChange}
                />
                Amount
              </label>
            </div>
          )}
        </div> */}

        <button className="py-2 px-4 rounded-[5px] bg-white border-[#DEDEDE] shadow-sm mr-2 ">
          Export
        </button>
      </div>
      <div className="flex md:col-span-1 justify-end">
        <SearchInput />
      </div>
    </div>
  );
};

export default Headers;
