import CardFlow from "features/settings/components/WorkflowConfig/common/CardFlow";

function SupplierRulesandConfig() {
  // const { allRulesData } = useSelector((state) => state?.rulesAndConfig);

  // const totalPages = allRulesData?.meta?.totalElements;
  return (
    <div className="h-full w-full pt-4 px-4 pb-4 bg-rd">
      <main className="w-full max-w-[1200px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-center items-center gap-4">
        <CardFlow
          // totalPages={totalPages}
          title="Collection"
          subtitle="Last Updated: 02-05-21"
          text="Efficient gathering of payments from buyers or debtors"
          href="COLLECTION"
        />
      </main>
    </div>
  );
}

export default SupplierRulesandConfig;
