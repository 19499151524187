const SelectBanks = ({
  label,
  value,
  onChange,
  id,
  onBlur,
  datas = [],
  type,
  setCode,
}) => {
  return (
    <div className="relative inline-block w-[300px] md:w-[424px]">
      <label
        htmlFor=""
        className="font-normal text-xs text-textColor relative bottom-[10px]"
      >
        {label}
      </label>
      <select
        value={value}
        onChange={onChange}
        id={id}
        onBlur={onBlur}
        // onSelect={() => setCode()}
        // className={`block appearance-none w-[300px] text-xs md:w-[424px] h-10  py-1 pr-8 leading-tight text-xsmall text-gray-500 bg-white border-b border-gray-600  outline-none focus:outline-none px-1.5}`}

        className={`block appearance-none w-[300px] text-xs md:w-[424px] h-10 py-1 pr-8 leading-tight text-xsmall ${
          value === "" ? "text-gray" : "text-black"
        } bg-white border-b border-gray-600 outline-none focus:outline-none px-1.5`}
      >
        {type ? (
          <option disabled value="" className="text-gray">
            {type}
          </option>
        ) : (
          <option disabled value="" className=" text-gray">
            Select an option
          </option>
        )}{" "}
        {datas?.map((data, idx) => {
          if (value === data?.name) {
            setCode(datas[idx]?.code);
          }
          return (
            <option key={idx} value={data?.name}>
              {data?.name}
            </option>
          );
        })}
      </select>

      <div className="absolute inset-y-0 right-0 flex items-center px-2 top-[30px] pointer-events-none">
        <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
          <path d="M10 12.5l-6-6 1.41-1.41L10 9.68l4.59-4.59L16 6.5l-6 6z"></path>
        </svg>
      </div>
    </div>
  );
};

export default SelectBanks;
