import CardFlow from "features/settings/components/WorkflowConfig/common/CardFlow";

function FunderRulesandConfig() {
  // const { allRulesData } = useSelector((state) => state?.rulesAndConfig);

  // const totalPages = allRulesData?.meta?.totalElements;
  return (
    <div className="h-full w-full pt-4 px-4 pb-4">
      <main className="w-full max-w-[1200px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-center items-center gap-4">
        <CardFlow
          // totalPages={totalPages}
          title="Direct Funding"
          subtitle="Last Updated: 02-05-21"
          text="Configure rules for extending payment terms for specific scenarios"
          href="DIRECT_FUNDING"
        />

        <CardFlow
          // totalPages={totalPages}
          title="Open Market"
          subtitle="Last Updated: 02-05-21"
          text="Optimize financial flows with efficient disbursement collection rules"
          href="OPEN_MARKET"
        />

        <CardFlow
          // totalPages={totalPages}
          title="Collection"
          subtitle="Last Updated: 02-05-21"
          text="Efficient gathering of payments from buyers or debtors"
          href="COLLECTION"
        />

        <CardFlow
          // totalPages={totalPages}
          title="Closed Market"
          subtitle="Last Updated: 02-05-21"
          text="Optimize financial flows with efficient disbursement collection rules"
          href="CLOSED_MARKET"
        />
      </main>
    </div>
  );
}

export default FunderRulesandConfig;
