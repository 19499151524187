import {
  PAIconInventoryArrowLeft,
  //   PAIconSmallExportDownloadIcon,
} from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Card from "shared/Card";
import { motion } from "framer-motion";
// import Download from "../../../../assets/images/svgs/downloadcsv.svg";
import { useEffect, useState } from "react";
import {
  getAllInvoiceById,
  getBulkEarlyPaymentDetails,
} from "appstate/api/invoice/invoice";

const ReimbursementTransactionHistoryDetailsPageBulkInnerDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    invoiceId,
    transactionType,

    // fundingType,
    // factoringType,
    // channel,
  } = location.state || {};
  // console.log("===states===", factoringType, channel);

  const [, setInvoiceData] = useState(null);
  // eslint-disable-next-line
  const [, setError] = useState(null);
  // eslint-disable-next-line
  const [, setEarlyPaymentData] = useState(null);
  // eslint-disable-next-line
  const [, setLoading] = useState(true);

  useEffect(() => {
    const fetchInvoice = async () => {
      if (invoiceId) {
        const result = await getAllInvoiceById({ id: invoiceId });
        setInvoiceData(result?.data);
      }
    };
    fetchInvoice();
    // eslint-disable-next-line
  }, [invoiceId]);

  useEffect(() => {
    const fetchEarlyPaymentDetails = async () => {
      try {
        const result = await getBulkEarlyPaymentDetails(id);
        setEarlyPaymentData(result?.data);
      } catch (error) {
        setError(error.message || "Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchEarlyPaymentDetails();
    }
  }, [id]);

  function handleNavigate() {
    let path = -1;
    navigate(path);
  }

  const dummyData = [
    {
      bidAmount: 3,
      item: "Solar Panel ",
      description: "Dangote 12 x 4 ultra high density so..",
      unitPrice: "340,000",
      Quantity: "3",
      amount: "1,020,000",
      taxRate: "1.2%",
      taxAmount: "12,240",
      totalPrice: "1,032,240",
    },

    {
      bidAmount: 4,
      item: "Solar Panel ",
      description: "Dangote 12 x 4 ultra high density so..",
      unitPrice: "340,000",
      Quantity: "3",
      amount: "1,020,000",
      taxRate: "1.2%",
      taxAmount: "12,240",
      totalPrice: "1,032,240",
    },

    {
      bidAmount: 5,
      item: "Solar Panel ",
      description: "Dangote 12 x 4 ultra high density so..",
      unitPrice: "340,000",
      Quantity: "3",
      amount: "1,020,000",
      taxRate: "1.2%",
      taxAmount: "12,240",
      totalPrice: "1,032,240",
    },
    {
      bidAmount: 6,
      item: "Solar Panel ",
      description: "Dangote 12 x 4 ultra high density so..",
      unitPrice: "340,000",
      Quantity: "3",
      amount: "1,020,000",
      taxRate: "1.2%",
      taxAmount: "12,240",
      totalPrice: "1,032,240",
    },
    {
      bidAmount: 7,
      item: "Solar Panel ",
      description: "Dangote 12 x 4 ultra high density so..",
      unitPrice: "340,000",
      Quantity: "3",
      amount: "1,020,000",
      taxRate: "1.2%",
      taxAmount: "12,240",
      totalPrice: "1,032,240",
    },
  ];

  return (
    <DashboardLayout
      section="Reimbursement"
      subHeader="Track invoices due for payment Reimbursement"
    >
      <header>
        {/* <div
          onClick={handleNavigate}
          className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
        >
          <PAIconInventoryArrowLeft />
          <p className="mt-[2px] text-sm font-normal text-lightGray">Go Back</p>
        </div> */}
        <div
          onClick={handleNavigate}
          className="flex items-center gap-x-[3px]  cursor-pointer rounded-t-[10px]  shadow-md bg-[#FAFAFA] py-4 border-b-[0.5px]   border-gray800 px-3"
        >
          <PAIconInventoryArrowLeft />
          <p className="mt-[2px] text-sm font-normal text-lightGray">Go Back</p>
        </div>
      </header>
      <div className="flex justify-between">
        <Card className="w-[100%]  rounded-b-[10px]">
          <div className=" flex  flex-col gap-3   p-[24px]">
            <div className=" font-semibold text-lg">Invoice|INV-2002</div>
            <main className=" w-full flex justify-between flex-wrap">
              <div className="flex flex-col gap-3 lg:w-1/3">
                <div className="flex">
                  <span>Reference No:</span>
                  <span className="pl-2">|RF9101010BHSHS</span>
                </div>

                <div className="flex flex-col gap-1">
                  {/* <div className="flex gap-x-[50px]">
                      <span className=" text-xs w-[97px]">Invoice Number:</span>
                      <span className="text-[#979797]  text-xs">INV 2002</span>
                    </div> */}
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Created Date:</span>
                    <span className="text-[#979797]  text-xs">
                      23-March-2023
                    </span>
                  </div>

                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Submitted Date:</span>
                    <span className="text-[#979797]  text-xs">
                      23-March-2023
                    </span>
                  </div>
                  <div className="flex gap-x-[70px]">
                    <span className=" text-xs">Approved Date:</span>
                    <span className="text-[#979797]  text-xs">
                      23-March-2023
                    </span>
                  </div>

                  <div className="flex gap-x-[98px]">
                    <span className=" text-xs">Due Date:</span>
                    <span className="text-[#979797]  text-xs">
                      23-March-2023
                    </span>
                  </div>
                  <div className="flex gap-x-[98px]">
                    <span className=" text-xs">Issuance Date:</span>
                    <span className="text-[#979797]  text-xs">
                      23-March-2023
                    </span>
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-3 lg:w-1/3">
                <div className="flex flex-col gap-1">
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Funding Channel</span>
                    <span className="text-[#979797]  text-xs">{""}</span>
                  </div>
                  {/* <div className="flex gap-x-[50px]">
                      <span className=" text-xs w-[97px]">Invoice Number:</span>
                      <span className="text-[#979797]  text-xs">INV 2002</span>
                    </div> */}
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Funding Amount</span>
                    <span className="text-[#979797]  text-xs">
                      NGN 1,263,00
                    </span>
                  </div>

                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Final Payment</span>
                    <span className="text-[#979797]  text-xs">
                      NGN 1,263,00
                    </span>
                  </div>
                  <div className="flex gap-x-[70px]">
                    <span className=" text-xs">Payment Date:</span>
                    <span className="text-[#979797]  text-xs">
                      23-March-2023
                    </span>
                  </div>
                </div>
              </div>

              <div className="flex flex-col ">
                <motion.button
                  whileTap={{ scale: 0.9 }}
                  className="flex bg-white py-2 text-sm justify-center gap-2 text-primaryColor border border-primaryColor rounded-[5px] items-center px-[35px]"
                >
                  Reimbursed
                </motion.button>
              </div>
            </main>
          </div>
          <br />

          <div className="p-[24px]">
            <div className="flex justify-between   border-b-[5px] border-b-[#F6F5FA]">
              <div className="flex flex-col">
                <span className="font-light text-sm">Nestle Plc</span>
                <span className="text-[#979797] text-sm">buyer details</span>
              </div>
              <div className="flex flex-col">
                <span className="font-light text-sm">60 Days</span>
                <span className="text-[#979797] text-sm">Payment</span>
              </div>
              {transactionType === "Loan Reimbursed" && (
                <div className="flex flex-col">
                  <span
                    style={{ color: "red" }}
                    className="font-light   text-xs"
                  >
                    +5%
                  </span>
                  <span className="text-[#979797] text-sm">
                    Total loan interest
                  </span>
                </div>
              )}
              <div className="flex flex-col">
                <span className="font-light text-sm">NGN</span>
                <span className="text-[#979797] flex justify-end text-xs">
                  Currency
                </span>
              </div>
            </div>
          </div>

          {/* <div className="flex flex-col gap-4">
            <header className="border-b-[5px] border-b-[#F6F5FA] pb-3"></header>
            <div className="flex  justify-between">
              <div className="flex gap-2"></div>
            </div>
          </div> */}
          <div className="flex flex-col gap-4 ">
            <div className=" p-[16px]">
              {" "}
              <header className="border-b-[5px] border-b-[#F6F5FA] pb-3 flex justify-between items-center ">
                <h1 className="text-sm">Line Items</h1>
                <div className="flex gap-2">
                  <div className="flex flex-col">
                    <span className="text-black   text-base">
                      5 Total Items
                    </span>
                  </div>
                </div>
              </header>
            </div>

            <div className=" w-full overflow-x-auto  p-[24px]">
              <table className=" w-full overflow-x-auto">
                <thead className="bg-subtleGray w-">
                  <tr className=" bg-unusualGray p-2.5 ">
                    <td className="text-[#11192A] p-2.5 text-xs font-bold">
                      Item
                    </td>
                    <td className=" p-2.5 text-xs font-bold">Description</td>
                    <td className=" p-2.5 text-xs font-bold">Unit Price</td>
                    <td className=" p-2.5 text-xs font-bold">Quantity</td>
                    <td className=" p-2.5 text-xs font-bold">
                      {"Amount(Before Tax)"}
                    </td>
                    <td className=" p-2.5 text-xs font-bold">Tax Rate</td>
                    <td className=" p-2.5 text-xs font-bold">Tax Amount</td>
                    <td className=" p-2.5 text-xs font-bold">Total Price</td>
                  </tr>
                </thead>
                {dummyData.map((item, index) => (
                  <tbody key={index}>
                    <tr
                      style={{ cursor: "pointer" }}
                      // onClick={onRowClicked}
                      className="py-[3px] px-1.5"
                    >
                      <td className="text-xs text-[#11192A] p-2.5">
                        {item.item}
                      </td>
                      <td className="text-xs text-[#11192A] p-2.5">
                        {item.description}
                      </td>
                      <td className="text-xs text-[#11192A] p-2.5">
                        {item.unitPrice}
                      </td>
                      <td className="text-xs text-[#11192A] p-2.5">
                        {item.Quantity}
                      </td>
                      <td className="text-xs text-[#11192A] p-2.5">
                        {item.amount}
                      </td>
                      <td className="text-xs text-[#11192A] p-2.5">
                        {item.taxRate}
                      </td>
                      <td className="text-xs text-[#11192A] p-2.5">
                        {item.taxAmount}
                      </td>
                      <td className="text-xs text-[#11192A] p-2.5">
                        {item.totalPrice}
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>

            <div className=" px-6   py-5 bg-neutral-50 flex-col justify-center items-start  inline-flex bg-[#fafafa] w-full border-t-[0.2px]  border-t-[#b1a6b2] rounded-b-[10px]">
              <div className="lg:flex md:flex justify-between  w-full sm:block ">
                {/* First left side */}
                <div>
                  <section>
                    {" "}
                    <div class="text-[#222222] text-lg  font-medium border-b-[0.2px]  border-[#b1a6b2]">
                      Receiving Funding Account Information
                    </div>
                    {/* <div class="text-[#222222] text-lg font-semibold ">
                          Receiving Funding Account Information
                        </div> */}
                    <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                      7469479465
                    </div>
                    <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                      Praise Akinlade
                    </div>
                  </section>
                </div>

                {/* second division */}

                <div>
                  {" "}
                  <div class=" flex-col justify-start items-start inline-flex lg:min-w-[290px]">
                    <section className=" w-full px-6 pb-2 border-b-[1px] border-black">
                      <div className=" flex w-full justify-between">
                        <div class="text-[#222222] text-xs font-semibold ">
                          Subtotal
                        </div>
                        <div class=" text-[#222222] text-xs font-semibold ">
                          10,129,892
                        </div>
                      </div>
                      <div className=" flex w-full justify-between pt-3 pb-1 border-b-[0.2px] border-b-[#b1a6b2]/30">
                        <div class="text-[#222222]  text-xs font-medium flex  space-x-9 ">
                          <span>VAT%</span>
                          <span>5.6%</span>
                        </div>
                        <div class="text-[#222222] text-xs font-medium ">
                          573,960
                        </div>
                      </div>

                      <div className=" flex w-full justify-between pt-3 pb-1 border-b-[0.2px] border-b-[#b1a6b2]/30">
                        <div class="text-[#222222]  text-xs font-medium flex  space-x-9 ">
                          <span>Discount%</span>
                          <span>2.4%</span>
                        </div>
                        <div class="text-[#222222] text-xs font-medium ">
                          237,088
                        </div>
                      </div>
                    </section>

                    <div className=" flex w-full justify-between py-2 px-6 border-b-[1px] border-b-black">
                      <div class="text-[#222222] text-lg font-semibold ">
                        Grand Total
                      </div>
                      <div class="text-[#222222] text-lg font-semibold ">
                        10,473,890
                      </div>
                    </div>
                    {/* <div className=" flex w-full justify-between pt-3 pb-1  border-b-[1px] border-black">
                          <div class="text-[#222222] text-lg  font-medium  ">
                            Grand Total
                          </div>
                          <div class="text-[#222222] text-lg  font-medium ">
                            5,263,200.00
                          </div>
                        </div> */}
                  </div>
                </div>

                {/* stops */}
              </div>
            </div>
            {/* <div className="flex justify-between">
              <div>
                <h4
                  style={{ fontSize: 13 }}
                  className="text-lg text-[#11192A] "
                >
                  Receiving Funding Account Information
                </h4>
                <h5
                  style={{ fontSize: 13 }}
                  className="text-lg text-[#11192A] "
                >
                  7662662662626
                </h5>
                <h5
                  style={{ fontSize: 13 }}
                  className="text-lg text-[#11192A] "
                >
                  Praise Akindade
                </h5>
              </div>
              <div style={{ width: 300 }}>
                <div className="flex justify-between">
                  <span
                    style={{ fontSize: 13 }}
                    className="text-sm text-[#11192A] "
                  >
                    Subtotal
                  </span>
                  <span className="text-sm text-[#11192A] ">1000000</span>
                </div>
                <div className="flex justify-between">
                  <div>
                    <span
                      style={{ fontSize: 13 }}
                      className="text-sm text-[#11192A] "
                    >
                      Vat %{" "}
                    </span>
                    <span className="pl-2 text-sm text-[#11192A] ">5.6%</span>
                  </div>
                  <div>
                    <span
                      style={{ fontSize: 13 }}
                      className="text-sm text-[#11192A] "
                    >
                      583,000
                    </span>
                  </div>
                </div>
                <div className="flex justify-between">
                  <div>
                    <span
                      style={{ fontSize: 13 }}
                      className="text-sm text-[#11192A] "
                    >
                      Discount %{" "}
                    </span>
                    <span
                      style={{ fontSize: 13 }}
                      className="pl-2 text-sm text-[#11192A] "
                    >
                      2.6%
                    </span>
                  </div>
                  <div>
                    <span
                      style={{ fontSize: 13 }}
                      className="text-sm text-[#11192A] "
                    >
                      283,000
                    </span>
                  </div>
                </div>
                <div>
                  <hr className="border-t border-[black] mt-2 pt-2" />
                  <div className="flex justify-between">
                    <span
                      style={{ fontSize: 13 }}
                      className="text-lg text-[#11192A] "
                    >
                      Grand Total
                    </span>
                    <span
                      style={{ fontSize: 13 }}
                      className="text-lg text-[#11192A] "
                    >
                      10,000,000
                    </span>
                  </div>
                  <hr className="border-t border-[black] mt-2 pt-2" />
                </div>
              </div>
            </div> */}
          </div>
        </Card>
      </div>
    </DashboardLayout>
  );
};
export default ReimbursementTransactionHistoryDetailsPageBulkInnerDetails;
