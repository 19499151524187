import { buyerSearch, getAllBuyers } from "appstate/auth/authSlice";
import { PAIconSearchPlaceholder } from "assets/images/svgs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "shared/Button";
import InputField from "shared/InputField";
import Pagination from "shared/Pagination";
import OnboardingLayout from "../category/components/OnboardingLayout";
import BuyerIcon from "./components/BuyerIcon";
import toast from "react-hot-toast";
import { supplierAssociateWithBuyer } from "appstate/api/supplier/relationshipManagement";
import AssociationSuccessModal from "shared/associationsuccessmodal/associationsuccessmodal";
const SelectBuyer = () => {
  const [select, setSelect] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [searchDatas, setSearchDatas] = useState([]);
  const [click, setClick] = useState(false);
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const [associationSuccessful, setAssociationSuccessful] = useState(false);
  const [getId, setGetId] = useState(null);
  const pageSize = 10;
  const { allBuyersData } = useSelector((state) => state?.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    if (companyName.length <= 0) {
      setClick(false);
    }
    dispatch(getAllBuyers({ page, pageSize }));
  }, [dispatch, page, pageSize, companyName]);
  const navigate = useNavigate();
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const buyerId = getId;
    try {
      const response = await supplierAssociateWithBuyer(buyerId);
      if (response?.success) {
        setAssociationSuccessful(true);
  
        setTimeout(() => {
          navigate("/supplier/company-details", { state: select });
        }, 5000);
      } else {
        toast.error("Failed to associate")
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  
  const getFirstLetter = (str) => {
    const firstLetter = str
      .split(" ")
      .filter((word) => word.toLowerCase() !== "and")
      .map((word) => word[0])
      .join("");
    return firstLetter;
  };

  const totalBuyers = allBuyersData?.meta?.totalElements;

  const handleSearch = () => {
    dispatch(buyerSearch({ companyName })).then((data) => {
      setSearchDatas(data?.payload);
      if (data?.payload?.status === 200) {
        setClick(true);
      }
    });
  };


  const handleCloseModal = () => {
    setAssociationSuccessful(false);
  };

  return (
    <OnboardingLayout>
       {associationSuccessful && (
          <AssociationSuccessModal
            message="This Buyer would receive a mail that you are requesting to associate"
            handleCloseModal={handleCloseModal}
          />
        )}
      <div className="h-[100%] overflow-auto pt-[120px] md:px-[20px] lg:px-[100px] xl:px-[280px]">
        <h1 className="font-semibold text-[28px] text-primaryColor text-center leading-tight">
          Associate with a Buyer on PayEdge SCF
        </h1>
        <p className="font-semibold text-[20px] text-textColor text-center mt-[30px]">
          Before you get started
        </p>
        <p className="font-normal text-sm text-center text-textColor mt-[15px]">
          Please choose a buyer you would like to be associated with on
          PayEdge SCF
        </p>
        <div className="flex justify-center mt-[40px]">
          <div className="flex mx-[10px]">
            <div>
              <InputField
                className="h-[41px] w-[250px] border-[.5px] border-silver border-b-[.5px] rounded placeholder:pl-[20px] px-[5px] outline-none md:w-[400px]"
                placeholder="Search buyers"
                type="text"
                id="search"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
              {!companyName.length > 0 && (
                <PAIconSearchPlaceholder className="relative bottom-7 left-[7px]" />
              )}
            </div>
            <Button
              plain
              onClick={handleSearch}
              className="w-[75px] h-[41px] flex justify-center items-center rounded-[4px] bg-whisper ml-[10px] text-black text-sm md:w-[96px]"
            >
              Search
            </Button>
          </div>
        </div>
        <div className="grid grid-cols-3 mt-[48px] gap-y-[20px] md:grid-cols-5 lg:grid-cols-6">
          {click ? (
            <>
              {searchDatas?.data?.map((data, index) => (
                <BuyerIcon
                  key={index}
                  icon={
                    data?.companyName
                      ? getFirstLetter(data?.companyName)
                      : "N/A"
                  }
                  name={data?.companyName}
                  select={select}
                  setSelect={setSelect}
                  id={data?.userId}
                  setGetId={setGetId}
                />
              ))}
            </>
          ) : (
            <>
              {allBuyersData?.data?.map((buyer) => (
                <BuyerIcon
                  key={buyer?.id}
                  icon={
                    buyer?.companyName
                      ? getFirstLetter(buyer?.companyName)
                      : "N/A"
                  }
                  name={buyer?.companyName}
                  select={select}
                  setSelect={setSelect}
                  id={buyer?.userId}
                  setGetId={setGetId}
                />
              ))}
            </>
          )}
        </div>
        <div className="flex gap-x-[8px] justify-center md:justify-end">
          {click === false ? (
            <Pagination
              page={page}
              startPage={startPage}
              setEndPage={setEndPage}
              endPage={endPage}
              setStartPage={setStartPage}
              setPage={setPage}
              totalPages={totalBuyers}
            />
          ) : null}
        </div>
        <div className="flex justify-center mx-[20px] mt-[60px] pb-[80px]">
          <Button
            onClick={handleSubmit}
            disabled={!select}
            auth
            className="w-[100%]  md:w-[424px]"
          >
            Continue
          </Button>
        </div>
      </div>
    </OnboardingLayout>
  );
};
export default SelectBuyer;
