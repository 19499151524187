import DashboardLayout from "layout/dashboardlayout";
import DetailsBulk from "./details/DetailsBulk";

const RequestDetailsBulk = () => {
  return (
    <>
      <DashboardLayout
        section={"Closed Market"}
        subHeader={"Manage your invoices on the closed market"}
      >
        <DetailsBulk />
      </DashboardLayout>
    </>
  );
};

export default RequestDetailsBulk;
