import { PAIconArrowLeftGreen, PAIconCocacola } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import Table from "layout/table/Table";
import { funderEarlyPaymentViewBulkRejectedDetails } from "appstate/funder/funderSlice";
import TransitionScale from "shared/animation/TransitionScale";

const FunderBulkEarlyPaymentDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navigateToDetail = (id) =>
    navigate(
      `/payablefinance-earlypayment/approved-funder-early-payment-bulk-inner-details-page/${id}`
    );

  function handleNavigate() {
    let path = -1;
    navigate(path);
  }
  const { getInvoiceId, funderEarlyPaymentViewBulkRejectedDetailsData } =
    useSelector((state) => state?.funder);
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(
      funderEarlyPaymentViewBulkRejectedDetails(getInvoiceId?.earlyPaymentId)
    );
  }, [dispatch, getInvoiceId?.earlyPaymentId]);

  useEffect(() => {
    setData(funderEarlyPaymentViewBulkRejectedDetailsData?.data);
  }, [funderEarlyPaymentViewBulkRejectedDetailsData]);

  const columns = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Ref Number
        </span>
      ),
      selector: (row) => row.invoiceRef,
    },

    {
      name: "Request Date",
      selector: (row) => row.requestDate,
    },

    {
      name: "Request Amount",
      selector: (row) => row.requestAmount,
    },
    {
      name: "Discount Rate",
      selector: (row) => row.discountRate,
    },
    {
      name: "Receivable Amount",
      selector: (row) => row.payableAmount,
    },
  ];

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(data?.earlyPaymentInvoiceResponse);
  }, [data?.earlyPaymentInvoiceResponse]);

  return (
    <>
      <DashboardLayout
        section="Early Payment Request"
        subHeader="Manage all your Early payment requests"
      >
        <header>
          <div
            onClick={handleNavigate}
            className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
          >
            <PAIconArrowLeftGreen />
            <span className="flex items-center text-primaryColor">Go Back</span>
          </div>
        </header>

        <div className="grid lg:grid-cols-[60%,40%] gap-5">
          <TransitionScale className=" pb-5 bg-white  rounded-[10px] mt-5 pt-2 ">
            <section className="  mt-3">
              {/* Down Flex Texts */}
              <div class=" justify-between items-center inline-flex mt-2 px-6 w-full">
                <div class=" justify-between items-center inline-flex mt-2 w-[45%]">
                  <div class="text-[#222222] text-base font-semibold">
                    Reference No:
                  </div>
                  <div class="text-[#222222] text-base font-semibold ">
                    | {data?.requestReferenceNumber}
                  </div>
                </div>
              </div>
              <div className=" flex justify-between px-6">
                {/* first stage */}

                <div class="lg:w-[240px]  sm:w-full h-[117px] flex-col justify-start items-start gap-3 inline-flex">
                  <div class="self-stretch  flex-col justify-center items-start gap-1 flex mt-2">
                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Request Date:
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        {data?.requestDate}
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex ">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Total Request amount
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        {data?.totalRequestAmount?.toLocaleString()}
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Total Payable amount
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        {data?.totalPayableAmount?.toLocaleString()}
                      </div>
                    </div>
                  </div>

                  {/* stop */}
                </div>
                {/* second stage */}
                <div className="flex justify-between items-center  ">
                  <div class="h-[30px] px-8 py-2 bg-[#d43333]/20 rounded-[5px] justify-center items-center gap-2.5 inline-flex">
                    <div class="text-center text-[#d43333] text-xs font-semibold ">
                      Rejected
                    </div>
                  </div>{" "}
                </div>
              </div>

              {/* stops here */}
              <div className="  px-6   ">
                <header className="border-b-[5px] border-b-[#F6F5FA]  flex justify-between py-1">
                  {/* <h1 className="text-[18px] font-semibold">
                    Supplier/buyer details
                  </h1> */}
                  <div>
                    <div class="text-[#222222] text-lg font-semibold  ">
                      {data?.buyerCompanyName}
                    </div>
                    <div class="text-[#7a7a7a] text-xs font-light  ">
                      Buyer details
                    </div>
                  </div>

                  <div class="h-[43px] flex-col justify-start items-end  inline-flex">
                    <div class="text-[#222222] text-lg font-semibold ">NGN</div>
                    <div class="text-[#7a7a7a] text-xs font-semibold mt-1">
                      Currency
                    </div>
                  </div>
                </header>

                <header className="border-b-[5px] border-b-[#F6F5FA] mt-6  flex justify-between py-1">
                  {/* <h1 className="text-[18px] font-semibold">
                    Supplier/buyer details
                  </h1> */}
                  <div class="text-[#11192a] text-sm font-semibold ">
                    Invoice List
                  </div>
                  <div class="text-[#11192a] text-lg font-light  mr-6">
                    {data?.earlyPaymentInvoiceResponse?.length} Invoices
                  </div>
                </header>

                {/* <div className=" w-full overflow-x-auto mt-3 pb-4"></div> */}

                <div className=" w-full overflow-x-auto mt-3 ">
                  <Table
                    columns={columns}
                    data={tableData}
                    pointer
                    className=""
                    // onRowClicked={(row) => navigateToDetail(row.bidAmount)}
                    onRowClicked={(row) => navigateToDetail(row.bidAmount)}
                    progressPending={false}
                    // progressComponent={<Loading />}
                  />
                </div>
                {/* Calculate cost and price  shaded or gray section */}
              </div>
            </section>
          </TransitionScale>
          {/* stops the best here */}

          <div className="bg-white rounded-[10px] gap-8  mt-5">
            {/* <FundDetailSide /> */}
            <div class="w-full justify-start items-center gap-4 inline-flex ">
              <div class=" justify-start items-center gap-2.5 flex w-full px-6  py-3">
                <div class="text-black text-xl font-bold  mt-5">
                  Early Payment Details
                </div>
              </div>
            </div>

            <div>
              {" "}
              {/* second */}
              <div className="   pb-4">
                <div class=" justify-between  flex flex-row    px-6 mt-4  ">
                  <div className="flex gap-2 py-2.5">
                    <PAIconCocacola />
                    <div className="flex flex-col">
                      {/* <span className=" text-black text-xs font-normal">
                      Coca Cola
                    </span> */}
                      <span class="text-black text-sm font-normal ">
                        {data?.buyerCompanyName}
                      </span>

                      <span class="text-[#949494] text-sm font-normal ">
                        Buyer
                      </span>
                    </div>
                  </div>

                  {/* here */}
                </div>
                <div class=" justify-between  flex flex-row    px-6  mt-6 ">
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        NGN {data?.totalDiscountAmount}
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Discount amount
                      </div>
                    </div>
                  </div>
                  {/* hd */}
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        {data?.requestDate}
                      </div>
                    </div>
                    <div class="flex-col justify-end items-end gap-0.5  w-full  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Early Payment Date
                      </div>
                    </div>
                  </div>{" "}
                </div>
                <div class=" justify-between  flex flex-row    border-b border-[#c2c2c2]/30 px-6  py-3  pb-12 mt-6">
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        NGN {data?.totalRequestAmount?.toLocaleString()}
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Total request amount
                      </div>
                    </div>
                  </div>
                  {/* dhdh */}
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col  gap-0.5  w-full flex">
                      <div class="text-black text-sm font-normal ">
                        NGN {data?.totalPayableAmount?.toLocaleString()}
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Total Payable Amount
                      </div>
                    </div>
                  </div>
                </div>
                {/* 2nd */}
                <div class=" justify-between  flex flex-col    border-b border-[#c2c2c2]/30 px-6  pb-10 ">
                  <div class="text-black text-lg font-semibold  mt-6">
                    Note from Supplier:{" "}
                  </div>

                  <div class=" text-[#949494] text-sm font-light  mt-4  leading-6">
                    {data?.description}
                  </div>
                </div>{" "}
                <div class=" justify-between  flex flex-col    px-6  ">
                  <div class="text-black text-lg font-semibold  mt-6">
                    Reason for rejection
                  </div>

                  <span className="text-[#959595]">
                    You rejected this Invoice based on{" "}
                    <span className="font-bold text-black">
                      {data?.reasonForRejection}
                    </span>{" "}
                    reasons
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};
export default FunderBulkEarlyPaymentDetails;
