import { PABadgeIcon, PAIconMDISuccess } from "assets/images/svgs";
import React, { useEffect } from "react";

const SuccessEarlyPayment = ({ isOpen, onClose, message, invoice }) => {
  useEffect(() => {
    let timeoutId;

    if (isOpen) {
      timeoutId = setTimeout(() => {
        onClose();
      }, 2000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isOpen, onClose]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className={`fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50 ${invoice ? 'w-full' : 'w-[900px]'}`}>
      <div className="flex">
        <div className="bg-[#07593D] p-10 shadow-md rounded-s text-center text-white">
          <PAIconMDISuccess />
        </div>

        <div className="bg-white py-10 px-3 flex flex-col items-center justify-center shadow-md text-center rounded-e">
          {/* <PAIconDelete2 /> */}
          {/* <p className="text-[#07593D]">Congratulations!!!</p> */}
          <span className="flex justify-center">
            <PABadgeIcon />
          </span>
          <p className="text-[#959595] text-center text-xs font-normal leading-normal tracking-[0.321px] mb-3 mt-3">
            {message}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SuccessEarlyPayment;
