import { motion } from "framer-motion";
import { useState } from "react";

function LienApproveCardWithoutSelect({
  sectA = "Total Default Amount ",
  sectB = "Total Loan Defaults ",
}) {
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const closeDropdown = () => setDropdownOpen(false);

  // Framer Motion Variants for the parent animation
  const parentVariants = {
    initial: { opacity: 0, y: -10 },
    animate: { opacity: 1, y: 0, transition: { duration: 0.5 } },
    exit: { opacity: 0, y: -10, transition: { duration: 0.5 } },
  };

  return (
    <motion.div
      className="w-full lg:w-[60%] relative "
      initial="initial"
      animate="animate"
      exit="exit"
      variants={parentVariants} // Apply parent animation
    >
      {isDropdownOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-25 z-10"
          onClick={closeDropdown}
        />
      )}

      {/* Main Section */}
      <main className="px-6 bg-[#fff] w-full rounded-b py-3 min-h-[196px] flex justify-center  items-center">
        <div className="flex flex-col sm:flex-row justify-between lg:justify-normal">
          <section className="flex flex-col gap-2.5 py-8 w-fit pr-9 border-r border-[#C5C5C5]">
            <div>
              <span className=" mr-0.5  font-medium  text-primaryColor">
                NGN
              </span>
              <span className="text-[32px] text-primaryColor font-medium">
                556,876,343
              </span>
            </div>

            <span className="text-[#7a7a7a] text-[15px] font-light">
              {sectA}
            </span>
          </section>

          <section className="flex flex-col items-center gap-2.5 py-8 w-fit pl-9">
            <div className="flex justify-center">
              <span className="text-[32px] text-primaryColor font-medium">
                80
              </span>
            </div>

            <span className="text-[#7a7a7a] text-center text-[15px] font-light flex justify-center">
              {sectB}
            </span>
          </section>
        </div>
      </main>
    </motion.div>
  );
}

export default LienApproveCardWithoutSelect;
