import { AnimatePresence, motion } from "framer-motion";
import { PAIconArrowLeftGreen } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { useNavigate, useParams } from "react-router-dom";
import TransitionScale from "shared/animation/TransitionScale";
import { useEffect, useState } from "react";
import cocaCola from "../../../../assets/images/svgs/cocacola.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  bidHistory,
  detailsBuyerOpenMarket,
} from "appstate/openMarket/openMarketSlice";
import { toggleCancelButtonOff } from "./buttonSlice";
import InvoiceDetails from "features/openMarket/common/components/InvoiceDetails";
import FunderDetails from "./modal/FunderDetails";
import BiddingDetails from "features/openMarket/supplier/components/modals/BiddingDetails";
import Pagination from "shared/Pagination";

function TopContestedInvoiceDetails() {
  const { id } = useParams();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(3);
  const pageSize = 5;

  useEffect(() => {
    dispatch(
      detailsBuyerOpenMarket({ openMarketReference: id })
    );
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(bidHistory({ openMarketReference: id, page, pageSize }));
  }, [dispatch, id, page, pageSize]);


  const { detailsBuyerOpenMarketData,  bidHistoryData } = useSelector(
    (state) => state?.openMarket
  );

  const invoiceByIdData = detailsBuyerOpenMarketData;

  const [funderDetail, setFunderDetail] = useState(false);
  const [biddingDetail, setBiddingDetail] = useState(false);

  const [activeTab, setActiveTab] = useState("bidHistory");

  function handleGoBack() {
    dispatch(toggleCancelButtonOff());
    navigate(-1);
  }

    const viewedFunderData = [
    {
      img: cocaCola,
      name: "Coca-Cola",
      number: "+235830495857",
      email: "www.skyflocklimited.com",
    },
    {
      img: cocaCola,
      name: "Coca-Cola",
      number: "+235830495857",
      email: "www.skyflocklimited.com",
    },
    {
      img: cocaCola,
      name: "Coca-Cola",
      number: "+235830495857",
      email: "www.skyflocklimited.com",
    },
  ];

  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const deadline = "2024-12-31T00:00:00";

  useEffect(() => {
    const calculateTimeLeft = () => {
      const difference = new Date(deadline).getTime() - new Date().getTime();
      if (difference > 0) {
        setTimeLeft({
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / (1000 * 60)) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        });
      } else {
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    };

    const timer = setInterval(calculateTimeLeft, 1000);
    return () => clearInterval(timer);
  }, [deadline]);

  return (
    <DashboardLayout subHeader="Here is what is happening in your finance">
      <TransitionScale className="flex  flex-col gap-6 bg-[#EEE] h-full">
        <header onClick={handleGoBack} className="w-full">
          <div className="flex cursor-pointer w-fit gap-1 items-center">
            <PAIconArrowLeftGreen />
            <span className=" text-primaryColor text-sm">Go back</span>
          </div>
        </header>

        <main className="flex flex-col semi-lg:flex-row pb-8 gap-8 w-full ">
          <InvoiceDetails invoiceByIdData={invoiceByIdData} />

          <motion.section
            initial={{
              y: -20,
            }}
            animate={{
              y: 0,
            }}
            exit={{
              y: 20,
            }}
            transition={{
              delay: 0.8,
            }}
            className="flex flex-col gap-3 px- pt-7 pb-24 w-2/5 bg-white rounded-[10px]"
          >
            <header className="flex pb-2 px-6 justify-between items-center">
              <span className="font-bold text-primaryColor">
                Bid details for this invoice
              </span>
              <div className="flex gap-1 items-center text-xs font-medium">
                <div className="text-center">
                  <span className="block">{timeLeft.days} : </span>
                  <span className="text-[8px] pr5">Days</span>
                </div>
                <div className="text-center">
                  <span className="block">{timeLeft.hours} : </span>
                  <span className="text-[8px]">Hours</span>
                </div>
                <div className="text-center">
                  <span className="block">{timeLeft.minutes} : </span>
                  <span className="text-[8px]">Minutes</span>
                </div>
                <div className="text-center">
                  <span className="block">{timeLeft.seconds}</span>
                  <span className="text-[8px]">Seconds</span>
                </div>
              </div>
            </header>

            <div className="border-t border-gray"></div>
            <div className="w-full ">
              <div className="mt-3 flex justifycenter px-6">
                <div className="flex space-x-4">
                  {/* Bid History Tab */}
                  <button
                    onClick={() => setActiveTab("bidHistory")}
                    className={`text-gray600 ${
                      activeTab === "bidHistory"
                        ? "border-b border-secondaryColor text-secondaryColor"
                        : ""
                    } font-medium text-sm`}
                  >
                    Bid History : {bidHistoryData?.data?.length}
                  </button>
                  {/* Request Details Tab */}
                  <button
                    onClick={() => setActiveTab("requestDetails")}
                    className={`pb2 text-gray600 ${
                      activeTab === "requestDetails"
                        ? "border-b border-secondaryColor text-secondaryColor"
                        : ""
                    } font-medium text-sm`}
                  >
                    Viewed by funders: 11
                  </button>
                </div>
              </div>
              <div className="mt-3">
                {activeTab === "bidHistory" &&
                  bidHistoryData?.data
                    ?.sort((a, b) => b?.win - a?.win)
                    ?.map((bid, index) => (
                      <div
                        key={index}
                        className={`flex justify-between px-6 pb-1 my-6 cursor-pointer ${
                          index === 0
                            ? "bg-[#F1F9FF] border-b-[2px] border-r-[2px] border-l-[2px] border-t-[2px] rounded border-primaryColor relative"
                            : ""
                        }`}
                        onClick={() => setBiddingDetail(true)}
                      >
                        {index === 0 && (
                          <div className="absolute top-[-10px] left-4 text-xs font-semibold text-primaryColor bg-white px-2">
                            Current winning bid
                          </div>
                        )}
                        <div className="flex gap-6 items-center w-[30%] mt-3">
                          <div className="flex flex-col gap-0.5">
                            <span
                              className={`text-xs font-[500] ${
                                index === 0 ? "text-primaryColor" : ""
                              }`}
                            >
                              {bid.funderName}
                            </span>
                            <span className="text-[10px] text-[#959595]">
                              Funder
                            </span>
                          </div>
                        </div>
                        <div className="flex gap-6 items-center w-[25%] mt-3">
                          <div className="flex flex-col gap-0.5">
                            <span className="text-xs font-[500]">
                              {bid.discountRate} {" - "}
                              <span
                                className={`text-[8px] ${
                                  bid.state === "Without Recourse"
                                    ? "text-primaryColor"
                                    : "text-secondaryColor"
                                }`}
                              >
                                {bid.discountType}
                              </span>
                            </span>
                            <span className="text-[10px] text-[#959595]">
                              Lock Rate
                            </span>
                          </div>
                        </div>
                        <div className="flex gap-6 items-center w-[20%] mt-3">
                          <div className="flex flex-col gap-0.5">
                            <span
                              className={`text-xs font-[500] ${
                                bid.status === "Locked"
                                  ? "text-secondaryColor"
                                  : "text-black"
                              } ${index === 0 ? "font-bold" : ""}`}
                            >
                              {bid.status}
                            </span>
                            <span className="text-[10px] text-[#959595]">
                              Status
                            </span>
                          </div>
                        </div>
                        <div className="flex gap-6 items-center w-[20%] mt-3">
                          <div className="flex flex-col gap-0.5">
                            <span className="text-xs font-[500]">
                              {bid.lockDuration}
                            </span>
                            <span className="text-[10px] text-[#959595]">
                              Lock duration{" "}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                <div className="flex justify-center">
                  {bidHistoryData?.meta?.totalElements >= pageSize && (
                    <Pagination
                      page={page}
                      startPage={startPage}
                      endPage={endPage}
                      setEndPage={setEndPage}
                      setPage={setPage}
                      setStartPage={setStartPage}
                      totalPages={bidHistoryData?.meta?.totalElements}
                    />
                  )}
                </div>

                {activeTab === "requestDetails" && (
                  <div className="space-y-4">
                    {viewedFunderData.map((item, index) => (
                      <div
                        key={index}
                        className="flex justify-between items-center mb-4 cursor-pointer px-6"
                        onClick={() => setFunderDetail(true)}
                      >
                        <div className="flex items-center my-2">
                          <img
                            src={item.img}
                            alt="Funder"
                            className="w-10 h-10 rounded-full mr-4"
                          />
                          <div>
                            <p className="text-[16px] font-regular text-gray-600">
                              {item.name}
                            </p>
                            <p className="text-[12px] font-regular text-zinc-400">
                              Funder
                            </p>
                          </div>
                        </div>

                        <div className="text-center">
                          <p className="text-[14px] ">{item.number}</p>
                          <p className="text-[12px] text-zinc-400">
                            Contact number
                          </p>
                        </div>

                        <div className="text-right">
                          <p className="text-sm text-gray-600">{item.number}</p>
                          <p className="text-[12px] text-zinc-400">Email</p>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </motion.section>
        </main>
      </TransitionScale>

      <AnimatePresence>
        {funderDetail && (
          <FunderDetails onClose={() => setFunderDetail(false)} />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {biddingDetail && (
          <BiddingDetails
            bidHistoryData={bidHistoryData}
            onClose={() => setBiddingDetail(false)}
          />
        )}
      </AnimatePresence>
    </DashboardLayout>
  );
}

export default TopContestedInvoiceDetails;
