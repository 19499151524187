// import Modal from "layout/modal/Modal";
import React from "react";
import BulkInvoiceModalContent from "./bulkinvoicemodalcontent";

const BulkInvoiceModal = ({
  isShown,
  onClose,
  data,
  directFunding,
  handleProceed,
  handleSelectAccount,
  lengthOfInvoice,
  buyer,
  date,
  percentage,
  totalRequestAmount,
  calculateTotalInvoiceAmount,
  totalReceivableAmount,
  description,
  setDescription,
  isDescriptionFilled,
  setIsDescriptionFilled,
  applied,
  setApplied,
  handleGoBack,
  isActiveRecourse,
  setIsActiveRecourse,
  selectedInvoiceData,
  earlyPaymentDiscountedResponseList,
}) => {
  if (!isShown) return null;

  const handleModalClick = (e) => e.stopPropagation();
  return (
    <div
    className="w-[100%] fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10"
    onClick={onClose}
  >
    <div
      className="bg-white w-[60%] lg:w-[60%] max-h-[80vh] overflow-y-auto rounded-lg shadow-lg p-6"
      onClick={handleModalClick}
    >
      <BulkInvoiceModalContent
        data={data}
        lengthOfInvoice={lengthOfInvoice}
        buyer={buyer}
        onClose={onClose}
        directFunding={directFunding}
        handleProceed={handleProceed}
        handleGoBack={handleGoBack}
        date={date}
        percentage={percentage}
        totalRequestAmount={totalRequestAmount}
        handleSelectAccount={handleSelectAccount}
        calculateTotalInvoiceAmount={calculateTotalInvoiceAmount}
        totalReceivableAmount={totalReceivableAmount}
        description={description}
        setDescription={setDescription}
        isDescriptionFilled={isDescriptionFilled}
        setIsDescriptionFilled={setIsDescriptionFilled}
        applied={applied}
        setApplied={setApplied}
        isActiveRecourse={isActiveRecourse}
        setIsActiveRecourse={setIsActiveRecourse}
        selectedInvoiceData={selectedInvoiceData}
        earlyPaymentDiscountedResponseList={earlyPaymentDiscountedResponseList}
      />
     </div>
     </div>
  );
};

export default BulkInvoiceModal;
