import {
  PAIconAlertIcon,
  // PAIconCancel,
  // PAIconEmailIcon,
} from "assets/images/svgs";
import React from "react";
import Button from "shared/Button";
import Spinner from "shared/Spinner/Spinner";

const AccountModal = ({
  handleAssociate,
  warning,
  isLoading,
  hide,
  isSuccess,
}) => {
  // const handle = (event) => {
  //   event.preventDefault();
  //   handleAssociate();
  // };
  return (
    <>
        <div className="flex flex-col items-center gap-y-[24px] py-[28px]">
          <div>
            <PAIconAlertIcon />
          </div>
          <p className="text-base font-normal px-[100px]  text-center  text-lightGray">
            {warning}
          </p>
          <div className="overflow-hidden flex">
            <Button
              onClick={handleAssociate}
              neutral
              className="bg-primaryColor text-white w-[76px] flex items-center justify-center h-[43px] rounded-[5px] text-base font-normal"
            >
              {isLoading ? <Spinner /> : "Yes"}
            </Button>
            <div>
              <Button
                onClick={hide}
                neutral
                className="w-[76px] h-[43px] !text-red border-[1px] border-red rounded-[5px] ml-[24px]"
              >
                No
              </Button>
            </div>
          </div>
        </div>
    </>
  );
};

export default AccountModal;
