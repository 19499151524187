import React, { useState } from "react";
import EarlyPaymentComponent from "./EarlyPaymentComponent";
import DirectFundingComponent from "./DirectFundingComponent";

const RecentRequest = ({data, bulkData}) => {
  const [activeTab, setActiveTab] = useState("earlyPayment");
  const [requestType, setRequestType] = useState("SingleRequest"); 

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleRequestTypeChange = (e) => {
    setRequestType(e.target.value);
  };

  // const renderTabContent = () => {
  //   switch (activeTab) {
  //     case "earlyPayment":
  //       return <EarlyPaymentComponent />;
  //     case "directFunding":
  //       return <DirectFundingComponent />;
  //     default:
  //       return null;
  //   }
  // };

  const renderTabContent = () => {
    switch (activeTab) {
      case "earlyPayment":
        return <EarlyPaymentComponent requestType={requestType} data={data} bulkData={bulkData} />; 
      case "directFunding":
        return <DirectFundingComponent requestType={requestType} />; 
      default:
        return null;
    }
  };

  return (
    <div className="mt-3 gap-2">
      <div>
        <div className="flex justify-between items-center w-full">
          <div className="flex px-[24px] gap-5 my-5">
            <h1 className="text-black font-bold text-lg">Recent Requests</h1>
            <div className="flex justify-center items-center px[24px] gap-5 my2">
              <select
                className="border border-gray300 rounded-md p-2 text-gray800 cursor-pointer"
                onChange={handleRequestTypeChange}
              >
                <option value="SingleRequest">Single Request</option>
                <option value="BulkRequest">Bulk Request</option>
              </select>
            </div>
          </div>
          <div className="flex gap-3 pr-5">
            <p
              className={`cursor-pointer ${
                activeTab === "earlyPayment"
                  ? "border-b-2 border-primaryColor text-primaryColor"
                  : "text-gray"
              }`}
              onClick={() => handleTabClick("earlyPayment")}
            >
              Early Payment
            </p>
            <p
              className={`cursor-pointer ${
                activeTab === "directFunding"
                  ? "border-b-2 border-primaryColor text-primaryColor"
                  : "text-gray"
              }`}
              onClick={() => handleTabClick("directFunding")}
            >
              Direct Funding
            </p>
          </div>
        </div>

        {/* Render the tab content */}
        <div className="mt-3">{renderTabContent()}</div>
      </div>
    </div>
  );
};

export default RecentRequest;
