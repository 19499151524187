import request from "apiInstance";
import toast from "react-hot-toast";
export const createTransPin = async (body) => {
  try {
    const response = await request({
      method: "post",
      url: `/api/v1/account/setTransactionPin`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
//

export const editTransPinForBuyer = async ({ body }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/buyers/edit-transaction-pin`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
export const editTransPinForSupplier = async ({ body }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/suppliers/edit-transaction-pin`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
export const editTransPinForFunder = async ({ body }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/funders/edit-transaction-pin`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
