import React, { useState, useEffect } from "react";
import { PAIconMakiCaution } from "assets/images/svgs";
import { useDispatch, useSelector } from "react-redux";
import { updateSingleEffectiveDisocunt } from "appstate/funder/funderSlice";
// import { updateSingleEffectiveRate } from "appstate/api/funder/pfp";

const ConfirmDirectFundingModal = ({
  isOpen,
  onClose,
  handleNext,
  disValue,
  handleChange,
  handleCancel,
  handleSave,
  funderRate,
  data,
  initialEffectiveRate
}) => {
  const [editable, setEditable] = useState(false);
  // const [invoiceData, setInvoiceData] = useState([]);
  const [newDiscountAmount, setNewDiscountAmount] = useState(0)
  const [newFundableAmount, setNewFundableAmount] = useState(0);
  const dispatch = useDispatch()

  // const { getInvoiceId, funderEarlyPaymentViewBulkPendingDetailsData } =
  //   useSelector((state) => state?.funder);

  // const { funderEarlyPaymentViewBulkPendingDetailsData } =
  // useSelector((state) => state?.funder);
  const { updatedEffectiveDataResponse } = useSelector((state) => state.funder);


  // useEffect(() => {
  //   setInvoiceData(funderEarlyPaymentViewBulkPendingDetailsData?.data);
  // }, [funderEarlyPaymentViewBulkPendingDetailsData]);

  useEffect(() => {
    // setDiscountRate(data?.discountRate);
    setNewDiscountAmount(
      data?.discountAmount
    );
    setNewFundableAmount(data?.receivableAmount);
  }, [data]);

  useEffect(() => {
    if (updatedEffectiveDataResponse) {
      // Update the state with the values from the API response.
      // setDiscountRate(updatedDiscountDataResponse?.data?.discount); 
      setNewDiscountAmount(updatedEffectiveDataResponse?.data?.discountedAmount);
      setNewFundableAmount(updatedEffectiveDataResponse?.data?.receivableAmount);
    }
  }, [updatedEffectiveDataResponse]);


  useEffect(() => {
    // Make sure `tempDiscountRate` has a valid value before proceeding.
    if (disValue < initialEffectiveRate) {
      const body = {
        earlyPaymentRequestId: data?.earlyPaymentId, 
        invoiceId: data?.invoiceId, 
        newDiscountRate: disValue,  // The new discount rate entered by the user
      };
  
      // Dispatch the API call to get the previewed adjusted discount values.
      dispatch(updateSingleEffectiveDisocunt({ body }));
      // Prepare the body for the API request.
    }
  }, [disValue, data?.earlyPaymentId, data?.invoiceId, dispatch, initialEffectiveRate]);

  const submItHandleCancel = () => {
    handleCancel();
    setEditable(false);
  };
  const submItHandleSave = () => {
    handleSave();
    setEditable(false);
  };

  if (!isOpen) return null;

  return (
    <>
      <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg rounded-[10px] z-[1000]">
        <div className="bg-white p10 px5 rounded-md max-w-xs md:max-w-lg w-full pb-5 px10 min-h-[404px] lg:min-w-[600px] md:min-w-[600px]">
          <div className="flex justify-between items-center py-5 px-6 rounded-t-[10px]  bg-[#FAFAFA]">
            <div class="text-[#222222] text-sm font-bold italic">
              Confirm Discount Rate{" "}
            </div>
          </div>
          {/* Rest box */}
          <div className="px-6">
            <div className=" w-full flex justify-between py-4 border-b-[1px] border-b-[#d7d7d7]">
              {/* first */}
              <div className=" flex-col gap-1">
                <div class=" text-[#555555] text-sm font-normal  mb-1">
                  Buyer Rate{" "}
                </div>
                <div class="text-center text-[#222222] text-2xl font-semibold">
                  {data?.discountRate}%
                </div>
              </div>
              <div className=" flex-col gap-1">
                <div class=" text-[#555555] text-sm font-normal  mb-1">
                  Funder Default Rate
                </div>
                <div class="text-center text-[#222222] text-2xl font-semibold">
                  {funderRate}%
                </div>
              </div>

              {/* side */}
              <div className=" items-center justify-between flex">
                {!editable ? (
                  <div className=" flex justify-between gap-1 rounded border border-[#d7d7d7]  pt-1 px-3 pb-2">
                    <div className=" flex-col gap-1">
                      <div class=" text-[#555555] text-sm font-normal  mb-1">
                        Effective Rate{" "}
                      </div>
                      <input
                        type="text"
                        placeholder=""
                        className={`${
                          editable
                            ? "w-[88px] h-[33px]  rounded border border-[#07593d]/40 justify-center items-center inline-flex text-center text-[#222222] text-2xl font-semibold  focus:outline-none"
                            : "justify-center items-center inline-flex text-center text-[#222222] text-2xl font-semibold  focus:outline-none w-[88px] h-[30px] bg-none"
                        }`}
                        value={disValue}
                        onChange={handleChange}
                        disabled={!editable}
                      />
                    </div>

                    <div className=" flex items-center justify-center">
                      {" "}
                      <button
                        class=" px-2.5  rounded border border-[#f08000] justify-center items-center inline-flex w-[57px] h-[34px] "
                        onClick={() => setEditable(true)}
                      >
                        <div class="text-center text-[#f08000] text-sm font-normal ">
                          Edit
                        </div>
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className=" flex justify-between gap-4 rounded border border-[#d7d7d7]  py-1 px-3">
                    <div className=" flex-col gap-2">
                      <div class=" text-[#555555] text-sm font-normal  mb-1">
                        Effective Rate{" "}
                      </div>
                      <input
                        type="text"
                        placeholder=""
                        className={`${
                          editable
                            ? "w-[88px] h-[38px]  rounded border border-[#07593d]/40 justify-center items-center inline-flex text-center text-[#222222] text-2xl font-semibold  focus:outline-none"
                            : "justify-center items-center inline-flex text-center text-[#222222] text-2xl font-semibold  focus:outline-none w-[88px] h-[30px] bg-none"
                        }`}
                        value={disValue}
                        onChange={handleChange}
                        disabled={!editable}
                      />
                    </div>
                    <div className="flex gap-2 justify-center items-center">
                      <button
                        class=" px-4 py-1.5 rounded-[5px] border border-[#de1515] justify-center items-center  flex h-[40px]"
                        onClick={submItHandleCancel}
                      >
                        <div class="text-[#de1515] text-sm font-normal text-center">
                          Cancel
                        </div>
                      </button>
                      <button
                        class=" px-6 py-1.5 rounded-[5px] bg-[#07593d] justify-center items-center  flex h-[40px]"
                        onClick={submItHandleSave}
                      >
                        <div class="text-[#ffffff] text-sm font-normal text-center">
                          Save
                        </div>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* next */}
            <div className="flex justify-between items-center mt-1">
              <div>
                <div class="text-left">
                  <span className="text-[#222222] text-base font-normal ">
                    NGN{" "}
                  </span>
                  <span className="text-[#222222] text-[25px] font-medium ">
                    {newDiscountAmount?.toLocaleString()}
                  </span>
                </div>
                <div class=" text-[#555555] text-sm font-normal  mt-1">
                  Discount Amount{" "}
                </div>
              </div>
              <div className="text-right">
                <div class="text-center">
                  <span className="text-[#222222] text-base font-normal ">
                    NGN{" "}
                  </span>
                  <span className="text-[#222222] text-[25px] font-medium ">
                    {newFundableAmount?.toLocaleString()}
                  </span>
                </div>
                <div class=" text-[#555555] text-sm font-normal  mt-1">
                  Fundable Amount
                </div>
              </div>
            </div>

            {/* Warning section */}
            <div className="flex  mt-12  gap-1">
              <PAIconMakiCaution />
              <div class=" text-[#7B7878] text-xs   font-semibold  leading-4  italic ">
                The <span className=" font-bold">Effective rate</span> is the
                lowest rate between the buyer’s and funder rate. <br />
                You can only set a rate within the range of the buyer's and the
                effective rates, if desired
              </div>
            </div>

            {/* Buttons */}
            <div className=" flex justify-between  mt-8">
              <button
                class=" px-4 py-1.5 rounded-[5px] border border-[#de1515] justify-center items-center  flex"
                onClick={onClose}
              >
                <div class="text-[#de1515] text-base font-medium">Cancel</div>
              </button>
              <button
                disabled={editable}
                className={`px-4 py-1.5 rounded-[5px] ${
                  !editable
                    ? "bg-[#07593d] cursor-pointer"
                    : "bg-[#d7d7d7]  cursor-not-allowed"
                } justify-center items-center  flex`}
                onClick={handleNext}
              >
                <div
                  className={`${
                    !editable ? "text-[#ffffff]" : "text-[#fafafa]"
                  } text-base font-medium`}
                >
                  Confirm
                </div>
              </button>
            </div>
          </div>

          {/* BVN Form */}
        </div>
      </div>
    </>
  );
};

export default ConfirmDirectFundingModal;
