import RecentPaymentTable from "./Table";
import { PAIconSolarLeftGreenArrow } from "assets/images/svgs";

const Overview = () => {
  return (
    <div className="w-full">
      <div className="w-full rounded-b-lg  shadow-lg">
        <header className="mt-3 border-b-2 border-[#AEAEAE] flex-shrink-0 bg-white px-7 py-4 rounded-t-md">
          <h2 className="text-[#3E3E3E] font-bold text-[16px]">
            Summary of invoices for Disbursement from diverse channels.
          </h2>
        </header>
        <div className="bg-[#EBEBEB] pb-5 rounded-b-lg mb-4">
          <div className="px-7 py-4 grid xl:grid-cols-[35%,64%] gap-5">
            {/* First Grid Section */}
            <section className="flex flex-col justify-between">
              <div className="flex justify-between  gap-3">
                <div className=" bg-primaryColor p-10 flex-row items-center justify-center text-white rounded-[10px] min-h-[50%]">
                  <p className="flex items-center justify-center font-bold text-4xl">
                    70
                  </p>
                  <p className="flex items-center justify-center font-normal text-[16px] text-center">
                    Total Invoices
                  </p>
                </div>

                <div className="p-5 bg-[#edfff8] flex flex-col items-center justify-center  rounded-b-r-[10px] rounded-tr-[10px] text-[#07593D] shadow-sm gap-3 w-[48%]  border-l-2 ">
                  <span className="font-bold md:text-base xl:text-[20px]">
                    <span> </span> ₦1,677,360
                    {/* {formatAmount(funderOverviewData?.totalDueAmount) || "0.00"} */}{" "}
                  </span>
                  <span className=" text-[13px] font-normal">
                    Total Due Amount
                  </span>
                </div>
              </div>

              <div className="flex justify-between ">
                <div className="py-[42px] px-5 bg-[#3A455B] flex flex-col items-center justify-center  rounded-b-[10px] rounded-tr-[10px] relative text-white shadow-sm gap-3 w-[48%] ">
                  <div className="flex absolute bottom-[76%] text-xs left-[65%] justify-end items-center">
                    <select className=" border border-white bg-white  text-xs outline-none text-[#2FA06A] rounded px2 cursor-pointer">
                      <option value="all">All</option>
                      <option value="1week">Jan</option>
                      <option value="1month">Feb</option>
                      <option value="quarterly">Mar</option>
                      <option value="halfyear">Apr</option>
                      <option value="1year">May</option>
                    </select>
                  </div>
                  <span className="font-bold md:text-base xl:text-[20px]">
                    <span>₦</span> 1,677,360
                    {/* {formatAmount(funderOverviewData?.totalDueAmount) || "0.00"} */}{" "}
                  </span>
                  <span className=" text-[13px] whitespace-nowrap font-normal">
                    Total Amount lienened
                  </span>
                </div>

                <div className="py-[42px] px-5 bg-secondaryColor flex flex-col items-center justify-center  rounded-bl-[10px] rounded-t-[10px] relative text-white shadow-sm gap-3 w-[48%] ">
                  <div className="flex absolute bottom-[76%] text-xs left-[65%] justify-end items-center">
                    <select className=" border border-white bg-white  text-xs outline-none text-[#2FA06A] rounded px2 cursor-pointer">
                      <option value="all">All</option>
                      <option value="1week">Jan</option>
                      <option value="1month">Feb</option>
                      <option value="quarterly">Mar</option>
                      <option value="halfyear">Apr</option>
                      <option value="1year">May</option>
                    </select>
                  </div>
                  <span className="font-bold md:text-base xl:text-[20px]">
                    <span>₦ </span> 1,048,460
                    {/* {formatAmount(funderOverviewData?.totalDueAmount) || "0.00"} */}{" "}
                  </span>
                  <span className=" text-[13px] whitespace-nowrap font-normal">
                    Total Amount Disbursed
                  </span>
                </div>
              </div>
            </section>

            <section className="grid lg:grid-cols-2 gap-5">
              {/* First GridBox */}
              <div className="flex flex-col justify-between  gap-3">
                {/* first layer */}
                <div
                  className={`p-6 itemscenter bg-white rounded-b-md border-t-2 min-h-[46%]`}
                  style={{ borderColor: "#07593D" }}
                >
                  <div className="flex item-center justify-between ">
                    <p className={`text-[${"#2FA06A"}] font-bold text-base`}>
                      {"Early Payment Requests"}
                    </p>
                    <select
                      className={`border border-[${"#07593D"}] bg-[${"#07593D"}] text-xs outline-none text-white rounded px2 cursor-pointer`}
                    >
                      <option value="all">All</option>
                      <option value="1week">1 week</option>
                      <option value="1month">1 month</option>
                      <option value="quarterly">Quarterly</option>
                      <option value="halfyear">Half a year</option>
                      <option value="1year">1 year</option>
                    </select>
                  </div>
                  <div className="flex justify-between mt-4">
                    {/* {section.data.map((item, idx) => ( */}
                    {/* second step */}
                    <div className=" justify-end w-1/2  items-end  flex-row">
                      <h2
                        className={`text-[${"#07593D"}] flex items-center justify-center font-bold text-2xl`}
                      >
                        {"54"}
                      </h2>
                      <h2 className="flex items-center justify-center font-normal text-[#565656] text-xs mt-3">
                        {"Total Requests"}
                      </h2>
                    </div>

                    {/* another step */}

                    <div className="flex-row  border-l-2 border-[#2FA069] w-1/2">
                      <h2
                        className={`text-[${"#F08000"}] flex items-center justify-center font-bold text-2xl`}
                      >
                        {"₦237,730"}
                      </h2>
                      <h2 className="flex items-center justify-center font-normal text-[#565656] text-xs mt-3">
                        {"Total Liened"}
                      </h2>
                    </div>
                    {/* ))} */}
                  </div>
                </div>

                {/* second layer */}

                <div
                  className={`p-6 itemscenter bg-white rounded-b-md border-t-2 min-h-[46%]`}
                  style={{ borderColor: "#07593D" }}
                >
                  <div className="flex item-center justify-between ">
                    <p className={`text-[${"#F08000"}] font-bold text-base`}>
                      {"Early Payment Offers"}
                      <br />
                      <span className="text-[#07593d] text-xs font-light  leading-none">
                        (Dynamic Discounting)
                      </span>
                    </p>
                    <select
                      className={`border border-[${"#F08000"}] bg-[${"#F08000"}]  text-xs outline-none text-white rounded px2 cursor-pointer h-7`}
                    >
                      <option value="all">All</option>
                      <option value="1week">1 week</option>
                      <option value="1month">1 month</option>
                      <option value="quarterly">Quarterly</option>
                      <option value="halfyear">Half a year</option>
                      <option value="1year">1 year</option>
                    </select>
                  </div>
                  <div className="flex justify-between mt-4">
                    {/* {section.data.map((item, idx) => ( */}
                    {/* second step */}
                    <div className=" justify-end w-1/2  items-end  flex-row">
                      <h2
                        className={`text-[${"#07593D"}] flex items-center justify-center font-bold text-2xl`}
                      >
                        {"21"}
                      </h2>
                      <h2 className="flex items-center justify-center font-normal text-[#565656] text-xs mt-3">
                        {"Total Offers"}
                      </h2>
                    </div>

                    {/* another step */}

                    <div className="flex-row  border-l-2 border-[#2FA069] w-1/2">
                      <h2
                        className={`text-[${"#F08000"}] flex items-center justify-center font-bold text-2xl`}
                      >
                        {"₦810,730"}
                      </h2>
                      <h2 className="flex items-center justify-center font-normal text-[#565656] text-xs mt-3">
                        {"Total Amount"}
                      </h2>
                    </div>
                    {/* ))} */}
                  </div>
                </div>
                {/* second layer stops here */}
              </div>
              {/* SECOND GRID */}
              <div className="flex flex-col justify-between gap-3">
                {/* first */}
                <div
                  className={`p-4  bg-white rounded-b-md border-t-2`}
                  style={{ borderColor: "#07593D" }}
                >
                  <div class=" text-[#565656] text-base font-medium ">
                    Total Outstanding Payables
                  </div>
                  {/*  */}
                  <div className="flex justify-between mt-4">
                    {/* {section.data.map((item, idx) => ( */}
                    {/* second step */}
                    <div className=" justify-end w-1/2  items-end  flex-row">
                      <h2
                        className={`text-[${"#07593D"}] flex items-center justify-center font-bold text-2xl`}
                      >
                        {"14"}
                      </h2>
                      <h2 className="flex items-center justify-center font-normal text-[#565656] text-xs mt-3">
                        {"Total Invoices"}
                      </h2>
                    </div>

                    {/* another step */}

                    <div className="flex-row  border-l-2 border-[#2FA069] w-1/2">
                      <h2
                        className={`text-[${"#F08000"}] flex items-center justify-center font-bold text-2xl`}
                      >
                        {"₦2,237,730"}
                      </h2>
                      <h2 className="flex items-center justify-center font-normal text-[#565656] text-xs mt-3">
                        {"Total Amount"}
                      </h2>
                    </div>
                    {/* ))} */}
                  </div>
                </div>

                <div
                  className={`p-4  bg-white rounded-b-md border-t-2`}
                  style={{ borderColor: "#07593D" }}
                >
                  <div class=" text-[#565656] text-base font-medium ">
                    Loan Defaults
                  </div>
                  {/*  */}
                  <div className="flex justify-between mt-4">
                    {/* {section.data.map((item, idx) => ( */}
                    {/* second step */}
                    <div className=" justify-end w-1/2  items-end  flex-row">
                      <h2
                        className={`text-[${"#07593D"}] flex items-center justify-center font-bold text-2xl`}
                      >
                        {"16"}
                      </h2>
                      <h2 className="flex items-center justify-center font-normal text-[#565656] text-xs mt-3">
                        {"Total Invoices"}
                      </h2>
                    </div>

                    {/* another step */}

                    <div className="flex-row  border-l-2 border-[#2FA069] w-1/2">
                      <h2
                        className={`text-[${"#F08000"}] flex items-center justify-center font-bold text-2xl`}
                      >
                        {"₦523,910"}
                      </h2>
                      <h2 className="flex items-center justify-center font-normal text-[#565656] text-xs mt-3">
                        {"Total Amount"}
                      </h2>
                    </div>
                    {/* ))} */}
                  </div>
                </div>

                {/* third */}

                <div class=" px-[17.67px] py-[11.04px] bg-white rounded-md shadow border border-[#07593D] justify-center items-center gap-[5.52px] inline-flex  flex-row">
                  <div class=" text-[#07593D] text-base font-normal ">
                    Go to Reimbursement
                  </div>
                  <PAIconSolarLeftGreenArrow />
                  <div class=" relative origin-top-left rotate-90">
                    <div class="w-[10.49px] h-[14.64px] left-[5.24px] top-[3.50px] absolute"></div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div>
        <RecentPaymentTable activeTab={4} />
      </div>
    </div>
  );
};

export default Overview;
