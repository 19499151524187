import { useState } from "react";
import "../styles/debitcard.css";
import { PAIconKitEyeclose, PAIconMasterCardLogo } from "assets/images/svgs";
function DebitCardNew({ cardData }) {
  const [isBalanceVisible, setBalanceVisibility] = useState(true);

  const toggleBalanceVisibility = () => {
    setBalanceVisibility((prevVisibility) => !prevVisibility);
  };

  const roundNumber = (num) => {
    return Math.floor(num); // Removes the decimal part
  };
  return (
    <div className="debit-body flex flex-col gap-5 py-4 rounded-lg mb-6 px-[4%] w-full h-[300px] pl-12  ">
      <div>
        <div className="text-xs font-semibold text-white mt-2 ">
          My Wallet -{" "}
          <span className=" font-normal">
            <span>{cardData?.walletName || "unregistered card"}</span> -{" "}
            {cardData?.cardNumber || "0000 **** **** 0000"}
          </span>
        </div>
        <div className="text-[9.5px]  text-white">Transactions Summary</div>
      </div>

      <div className=" flex  w-full">
        <div className="flex  cursor-pointer  w-full  transition duration-[350ms] transform hover:scale-105 ease-in-out     justify-between ">
          <div className="flex justify-between  shadow-card-mod py-[16px] px-[12px]  relative rounded-[10px]  w-[60%]">
            <div className=" w-full">
              <h2 className="text-sm font-semibold text-white mb-[27px]">
                Wallet Balance
              </h2>
              <div className=" flex gap-1 items-center text-4xl mb-[27px] text-white">
                <span>{cardData?.cardCurrency === "USD" ? "$" : "₦"}</span>
                {isBalanceVisible
                  ? `${roundNumber(cardData?.availableBalance) || "NA"}`
                  : "****"}
              </div>
              <p className="text-sm font-semibold text-white mb-[5px]">
                {cardData?.walletName || "unregistered card"}
              </p>
              <p className="text-[10px] text-white">
                {cardData?.cardNumber || "0000 **** **** 0000"}
              </p>
            </div>
            <div className="flex flex-col justify-between items-center">
              <p className="text-xs font-[300] text-white ">
                {cardData?.expiryDate || "N/A"}
              </p>
              <PAIconKitEyeclose
                onClick={toggleBalanceVisibility}
                className=" cursor-pointer "
              />
              <PAIconMasterCardLogo />
            </div>
          </div>

          <div className=" gap-5 flex justify-center flex-col">
            <button className=" bg-[#FF8E0D] rounded-[5px] text-white text-xs font-light px-5 py-2.5 transition duration-300 transform hover:scale-105 ease-in-out hover:bg-[#ff8e0de5] ">
              Fund Account
            </button>
            <button className=" bg-white rounded-[5px] text-[#07593D] font-light text-xs px-5 py-2.5 transition duration-300 transform hover:scale-105 ease-in-out hover:bg-slate-100">
              Withdraw Funds
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DebitCardNew;
