import React, { useState } from "react";

import { PAIconMakiCaution } from "assets/images/svgs";

const ConfirmDirectFundingModal = ({
  isOpen,
  onClose,
  handleNext,
  disValue,
  handleChange,

  handleCancel,
  handleSave,
}) => {
  const [editable, setEditable] = useState(false);
  const submItHandleCancel = () => {
    handleCancel();
    setEditable(false);
  };
  const submItHandleSave = () => {
    handleSave();
    setEditable(false);
  };
  if (!isOpen) return null;

  return (
    <>
      <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg rounded-[10px] z-[1000]">
        <div className="bg-white p10 px5 rounded-md max-w-xs md:max-w-lg w-full pb-5 px10 min-h-[404px]">
          <div className="flex justify-between items-center py-5 px-6 rounded-t-[10px]  bg-[#FAFAFA]">
            <div class="text-[#222222] text-sm font-bold italic">
              Confirm Discount Rate{" "}
            </div>
          </div>
          {/* Rest box */}
          <div className="px-6">
            <div className=" w-full flex justify-between py-4 border-b-[1px] border-b-[#d7d7d7]">
              {/* first */}
              <div className=" flex-col gap-1">
                <div class=" text-[#555555] text-sm font-normal  mb-1">
                  Discount Rate{" "}
                </div>
                <input
                  type="text"
                  placeholder=""
                  className={`${
                    editable
                      ? "w-[88px] h-[33px]  rounded border border-[#07593d]/40 justify-center items-center inline-flex text-center text-[#222222] text-2xl font-semibold  focus:outline-none"
                      : "justify-center items-center inline-flex text-center text-[#222222] text-2xl font-semibold  focus:outline-none w-[88px] h-[30px] bg-none"
                  }`}
                  value={disValue}
                  onChange={handleChange}
                  disabled={!editable}
                />
              </div>

              {/* side */}
              <div className=" items-center justify-between flex">
                {!editable ? (
                  <button
                    class=" px-2.5  rounded border border-[#f08000] justify-center items-center inline-flex w-[57px] h-[31px] "
                    onClick={() => setEditable(true)}
                  >
                    <div class="text-center text-[#f08000] text-sm font-normal ">
                      Edit
                    </div>
                  </button>
                ) : (
                  <div className="flex gap-2">
                    <button
                      class=" px-4 py-1.5 rounded-[5px] border border-[#de1515] justify-center items-center  flex"
                      onClick={submItHandleCancel}
                    >
                      <div class="text-[#de1515] text-sm font-normal text-center">
                        Cancel
                      </div>
                    </button>
                    <button
                      class=" px-6 py-1.5 rounded-[5px] bg-[#07593d] justify-center items-center  flex"
                      onClick={submItHandleSave}
                    >
                      <div class="text-[#ffffff] text-sm font-normal text-center">
                        Save
                      </div>
                    </button>
                  </div>
                )}
              </div>
            </div>
            {/* next */}
            <div className="flex justify-between items-center mt-1">
              <div>
                <div class="text-center">
                  <span className="text-[#222222] text-base font-normal ">
                    NGN{" "}
                  </span>
                  <span className="text-[#222222] text-[25px] font-medium ">
                    12,000
                  </span>
                </div>
                <div class=" text-[#555555] text-sm font-normal  mt-1">
                  Discount Amount{" "}
                </div>
              </div>
              <div>
                <div class="text-center">
                  <span className="text-[#222222] text-base font-normal ">
                    NGN{" "}
                  </span>
                  <span className="text-[#222222] text-[25px] font-medium ">
                    900,000
                  </span>
                </div>
                <div class=" text-[#555555] text-sm font-normal  mt-1">
                  Fundable Amount
                </div>
              </div>
            </div>

            {/* Warning section */}
            <div className="flex  mt-12  gap-1">
              <PAIconMakiCaution />
              <div class="w-[413px] text-[#7B7878] text-xs  font-medium  leading-4  ">
                The discount rate offered to suppliers upon request is only a
                benchmark rate. <br />
                Please choose a different rate if desired.
              </div>
            </div>

            {/* Buttons */}
            <div className=" flex justify-between  mt-8">
              <button
                class=" px-4 py-1.5 rounded-[5px] border border-[#de1515] justify-center items-center  flex"
                onClick={onClose}
              >
                <div class="text-[#de1515] text-base font-medium">Cancel</div>
              </button>
              <button
                disabled={editable}
                className={`px-4 py-1.5 rounded-[5px] ${
                  !editable
                    ? "bg-[#07593d] cursor-pointer"
                    : "bg-[#d7d7d7]  cursor-not-allowed"
                } justify-center items-center  flex`}
                onClick={handleNext}
              >
                <div
                  className={`${
                    !editable ? "text-[#ffffff]" : "text-[#fafafa]"
                  } text-base font-medium`}
                >
                  Confirm
                </div>
              </button>
            </div>
          </div>

          {/* BVN Form */}
        </div>
      </div>
    </>
  );
};

export default ConfirmDirectFundingModal;
