import React from "react";
import { PAIconSmallCoke } from "assets/images/svgs";
import DetailCard from "../card/DetailCard";
const BidHistory = () => {
  const rawData = [
    {
      funderName: "Defi LTD",
      discountRate: "3.6%",
      status: "Unlocked",
      biddingTime: "01:45:11:34",
    },
    {
      funderName: "Union Bank",
      discountRate: "4.3%",
      status: "Unlocked",
      biddingTime: "01:45:11:34",
    },
    {
      funderName: "Shell PLC",
      discountRate: "5.8%",
      status: "Unlocked",
      biddingTime: "01:45:11:34",
    },

    {
      funderName: "Tecna LTD",
      discountRate: "8%",
      status: "Unlocked",
      biddingTime: "01:45:11:34",
    },
    {
      funderName: "Access bank",
      discountRate: "9.1%",
      status: "Unlocked",
      biddingTime: "01:45:11:34",
    },
    {
      funderName: "FirstBank",
      discountRate: "10%",
      status: "Unlocked",
      biddingTime: "01:45:11:34",
    },
    {
      funderName: "Dangote",
      discountRate: "12.3%",
      status: "Unlocked",
      biddingTime: "01:45:11:34",
    },
  ];
  return (
    <div>
      {/* first outline card */}

      <div className=" px-4">
        <div className=" bg-[#07593d]/5 rounded-[5px] border border-[#07593d] relative ">
          <div class="text-black text-xs font-semibold absolute -left-0.5 -top-2.5 z-1111 bg-white px-1 ">
            Your bid is the Winning Bid
          </div>
          <div class=" justify-between  flex px-2  flex-row  items-center  ">
            <div class=" items-center flex gap-3">
              <PAIconSmallCoke />
              <div class="py-2.5 flex-col justify-start items-start gap-0.5 inline-flex">
                <div class="flex-col justify-start items-start gap-0.5 flex">
                  <div className="text-black text-xs font-normal">
                    ABC Limited
                  </div>
                </div>
                <div class="flex-col justify-start items-start gap-0.5 flex">
                  {/* <div className=" text-[#949494] text-[10px]">
                Funder
              </div> */}
                  <div class="text-[#949494] text-[10px] font-normal ">
                    Funder
                  </div>
                </div>
              </div>
            </div>
            <div class="p-2.5 flex-col gap-0.5 inline-flex">
              <div class="flex-col  justify-start items-start gap-0.5 flex">
                <div className="text-black text-xs font-normal">2.5%</div>
              </div>
              <div class="h-3 flex-col justify-start items-start gap-0.5 flex">
                <div className=" text-[#949494] text-[10px]">
                  Discount Rate{" "}
                </div>
              </div>
            </div>
            <div class=" p-2.5 flex-col  gap-0.5 inline-flex">
              <div class="flex-col justify-center items-center gap-0.5 flex">
                <div className={`font-[500] text-sm ${"text-[#f08000]"}`}>
                  Locked
                </div>
              </div>
              <div class="h-3 flex-col justify-start items-start gap-0.5 flex">
                <div className=" text-[#959595] text-[10px]">Status</div>
              </div>
            </div>
            <div class="w-[129px] p-2.5 flex-col justify-start items-end gap-0.5 inline-flex">
              <div class="flex-col justify-start items-start gap-0.5 flex">
                <div className="font-[500] text-sm">01:45:11:34</div>
              </div>
              <div class="flex-col justify-start items-end gap-0.5 flex">
                <div className=" text-[#959595] text-[10px]">Bidding Time</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {rawData.map((item, idx) => {
        return (
          <DetailCard
            key={idx}
            funderName={item.funderName}
            discountRate={item.discountRate}
            statusOfInvoice={item.status}
            biddingTime={item.biddingTime}
          />
        );
      })}
    </div>
  );
};

export default BidHistory;
