import React, { useEffect, useState } from "react";
import { PAIconAlertIcon } from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateSingleDiscountRate } from "appstate/buyer/buyerSlice";

const TransferFundsSetupModal = ({
  isOpen,
  onClose,
  confirm,
  proceed,
  fromThridParty,
  fromBuyer,
  toClosedMarket,
  setIsSelectClosedMarketFunder,
  isDiscountEdit,
  setIsTransferModalOpen,
  data,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [discountRate, setDiscountRate] = useState(0); // Initial discount rate
  const [tempDiscountRate, setTempDiscountRate] = useState(discountRate); // Temp state for editing
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [newdiscountAmount, setNewDiscountAmount] = useState(0);
  const [newPayableAmount, setNewPayableAmount] = useState(0);
  const dispatch = useDispatch();

  const handleDiscountUpdate = () => {
    const body = {
      earlyPaymentRequestId: data?.earlyPaymentId,
      invoiceId: data?.invoiceId,
      newDiscountRate: discountRate,
    };
    dispatch(updateSingleDiscountRate({ body }));
    proceed();
  };
  // const { updateSingleDiscountRateData } = useSelector((state) => state.buyer);

  useEffect(() => {
    setDiscountRate(data?.discountRate);
    setNewDiscountAmount(data?.discountAmount);
    setNewPayableAmount(data?.payableAmount);
  }, [data]);

  useEffect(() => {
    if (Number(tempDiscountRate) >= Number(data?.discountRate)) {
      setError("New discount rate must be below previous rate");
    } else {
      setError(null);
    }
  }, [tempDiscountRate, data?.discountRate]);

  const handleEditClick = () => {
    if (isEditing) {
      const discountAmount = (data?.requestAmount * tempDiscountRate) / 100;
      const payableAmount = data?.requestAmount - discountAmount;
      setDiscountRate(tempDiscountRate);
      setNewDiscountAmount(discountAmount);
      setNewPayableAmount(payableAmount);
    } else {
      setTempDiscountRate(discountRate); // Set temp state to current discount rate
    }
    setIsEditing(!isEditing);
  };

  const handleCancelClick = () => {
    setTempDiscountRate(discountRate);
    setIsEditing(false);
    setError(null);
  };

  if (!isOpen) {
    return null;
  }

  const handleSingle = () => {
    if (fromThridParty === true) {
      let path =
        "/payablefinancing/payablefinace/singleearlypaymentdetails/approved/directfuning_funderlist";
      navigate(path, {
        state: {
          route: "single",
          fromBuyer,
          earlyPaymentRequestId: data?.earlyPaymentId,
        },
      });
    } else if (toClosedMarket === true) {
      setIsSelectClosedMarketFunder(true);
      onClose();
    } else {
      handleDiscountUpdate();
    }
  };

  return (
    <div
      // onClick={onClose}
      className="bg-[rgba(0,0,0,0.2)] w-[100%] h-[100vh] flex justify-center items-center absolute left-0 top-0"
    >
      <div>
        <div className="flex flex-col justify-between items-center bg-white rounded-[8px] w-[600px]">
          <div className="h-[60px] px-[5%] rounded-t-[8px] bg-[#FAFAFA] flex items-center w-[100%] justify-start">
            <h1 className="font-bold text-[20px]">Confirm discount rate</h1>
          </div>

          <div className="p-[5%]">
            <div className="flex items-end justify-between w-[100%]">
              <div>
                <p>Discount rate</p>
                {isEditing ? (
                  <input
                    type="number"
                    value={tempDiscountRate}
                    onChange={(e) => setTempDiscountRate(e.target.value)}
                    className="text-[18px] w-[100px] font-semibold border rounded p-1"
                  />
                ) : (
                  <h2 className="text-[18px] font-semibold">{discountRate}%</h2>
                )}
              </div>

              <div className="flex items-center">
                <button
                  onClick={handleEditClick}
                  style={
                    isEditing
                      ? { border: "1px solid green", color: "green" }
                      : { border: "1px solid #F08000", color: "#F08000" }
                  }
                  className="w-[100px] h-[45px] rounded-[5px] flex items-center justify-center"
                >
                  {isEditing ? "Save" : "Edit"}
                </button>
                {isEditing && (
                  <button
                    onClick={handleCancelClick}
                    className="border-[1px] border-[#EE0816] w-[100px] h-[45px] rounded-[5px] flex items-center justify-center text-[#EE0816] ml-2"
                  >
                    Cancel
                  </button>
                )}
              </div>
            </div>
            {error && (
              <div className="text-red mt-2">
                <p>{error}</p>
              </div>
            )}

            <div className="flex items-center justify-between mt-10">
              <div>
                <h2 className="text-[24px] font-semibold">
                  NGN {newdiscountAmount?.toLocaleString()}
                </h2>
                <p>Discount amount</p>
              </div>

              <div className="flex flex-col justify-end">
                <h2 className="text-[24px] font-semibold">
                  NGN {newPayableAmount?.toLocaleString()}
                </h2>
                <p>Payable amount</p>
              </div>
            </div>

            <div className="flex items-center w-[100%] mt-5">
              <PAIconAlertIcon className="w-[5%] mr-[1%]" />
              <p className="text-left text-[14px]">
                The discount rate offered to suppliers upon request is only a
                benchmark rate. Please choose a different rate if desired.!
              </p>
            </div>

            <div className="mt-[6%] flex items-center justify-between w-[100%]">
              <button
                onClick={onClose}
                className="border-[#EE0816] text-[#EE0816] border-[1px] w-[120px] rounded-[4px] h-[45px]"
              >
                Cancel
              </button>

              <button
                onClick={() => {
                  if (isDiscountEdit === true) {
                    setIsTransferModalOpen(false);
                  } else {
                    handleSingle();
                  }
                }}
                className="bg-[#07593D] text-[#fff] w-[120px] rounded-[4px] h-[45px]"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransferFundsSetupModal;
