import request from "apiInstance";
import toast from "react-hot-toast";

export const createInvoice = async (invoiceData) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/invoice/for-buyer",
      data: invoiceData,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
    throw error;
  }
};

export const getVirtualAccounts = async () => {
  try {
    const response = await request({
      method: "get",
      url: "/api/v1/account/get-user-virtual-accounts",
      headers: {
        Accept: "application/json",
      },
    });
    // toast.success("Virtual account fetched successfully!");
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
    throw error;
  }
};


export const downloadCsvTemplateForInvoice = async () => {
  try {
    const response = await request({
      method: "get",
      url: "/api/v1/invoice/download-template",
      responseType: "blob",
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const a = document.createElement("a");
    a.href = url;
    a.setAttribute("download", "invoice_template.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    // toast.success("CSV template downloaded successfully!");
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getTemplateHeaders = async (csvFile) => {
  try {
    const formData = new FormData();
    formData.append("file", csvFile);
    const response = await request({
      method: "post",
      url: "/api/v1/invoice/headers",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const insertUpload = async (csvFile) => {
  try {
    const formData = new FormData();
    formData.append("file", csvFile);
    const response = await request({
      method: "post",
      url: "/api/v1/invoice/upload?page=0&size=10",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response?.data;
  } catch (error) {
    console.error("Upload error: ", error);
    toast.error(error?.response?.data?.message + "!");
  }
};

export const saveMappingForLater = async (payload, token) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/invoice/save-mapping-for-later",
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const mapInvoiceData = async (mappingRequest, token) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/invoice/mapping",
      params: mappingRequest,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const insertInvoice = async (invoiceData) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/invoice/insert-upload",
      data: invoiceData,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const saveValidationForLaterInvoice = async (invoiceData) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/invoice/save-validation-for-later",
      data: invoiceData,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getPlayerInvoices = async (data) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/${data.playerType}/invoices?page=0&size=10`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const EditInvoice = async (invoiceId, invoiceData) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/invoice/${invoiceId}?invoiceId=${invoiceId}`,
      data: invoiceData,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
    throw error;
  }
};


export const updateInvoice = async (data) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/invoice/update/${data.type}?invoice=${data.invoiceId}`,
      data: data.body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getInvoiceById = async (data) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/${data.player}/${data.invoiceId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};


export const getInvoiceByStatus = async (data) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/${data.player}/status/${data.invoiceStatus}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getInvoiceByReference = async (data) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/${data.player}/inv-ref/${data.invoiceReference}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllInvoices = async () => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoices?page=0&size=10`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllInvoiceById = async ({ id }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllInvoiceByRef = async (data) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/inv-ref/${data.invoiceRef}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllInvoiceByStatus = async (data) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/status/${data.status}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const deleteInvoiceById = async (data) => {
  try {
    const response = await request({
      method: "delete",
      url: `/api/v1/invoice/${data.invoiceId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getInvoiceDashboard = async () => {
  try {
    const response = await request({
      method: "get",
      url: "/api/v1/invoice/supplier/dashboard",
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllRecentActivitiesInvoices = async ({ page, pageSize }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/supplier/recent-activities?page=${page}&size=${pageSize}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllSupplierInvoices = async ({ page, pageSize, search, status }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/supplier/invoices?page=${page}&search=${search}&size=${pageSize}&status=${status}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
export const getBuyerInvoicesByStatus = async ({ status }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/buyer/status/${status}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getFundSectionOverview = async () => {
  try {
    const response = await request({
      method: "get",
      url: "/fund-request/overview",
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllOpenMarket = async ({ page, pageSize }) => {
  try {
    const response = await request({
      method: "get",
      url: `/fund-request/open-markets?${page}&${pageSize}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getEarlyPayments = async ({
  page,
  pageSize,
  bulk,
  status,
  search,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/fund-request/early-payments?page=${page}&size=${pageSize}&bulk=${bulk}&status=${status}&search=${search}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getDirectFundings = async ({
  page,
  pageSize,
  bulk,
  status,
  search,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/fund-request/direct-fundings?page=${page}&size=${pageSize}&bulk=${bulk}&status=${status}&search=${
        search || ""
      }`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getSupplierInvoiceById = async ({ id }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/supplier/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const approveInvoice = async ({ body }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/invoice/approve`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const approveSupplierInvoice = async ({ body }) => {
  try {
    const response = await request({
      method: "put",
      url: "/api/v1/invoice/submit-for-approval",
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const uploadInvoice = async (body) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/invoice/insert-upload",
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllPayablesInvoice = async ({ page, pageSize, search }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/payables?page=${page}&size=${pageSize}&search=${search}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllPendingUploadInvoice = async ({ page, pageSize, search }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/saveForLater?page=${page}&size=${pageSize}&search=${search}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

// /api/v1/invoice/resume/mapping/{reference}

export const getInvoiceResumeMapping = async (reference) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/resume/mapping/${reference}`,
      headers: {
        Accept: "application/json",
      },
    });
    toast.success("Invoice resume mapping fetched successfully!");
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
    throw error;
  }
};

// GET/api/v1/invoice/resume/validation/{reference}

export const getInvoiceResumeValidation = async (reference) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/resume/validation/${reference}`,
      headers: {
        Accept: "application/json",
      },
    });
    toast.success("Invoice resume validation fetched successfully!");
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
    throw error;
  }
};

export const deletePendingUploadInvoice = async ({ id }) => {
  try {
    const response = await request({
      method: "delete",
      url: `/api/v1/invoice/cancel-resume/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const deleteCreatedInvoice = async ({ id }) => {
  try {
    const response = await request({
      method: "delete",
      url: `/api/v1/invoice/delete/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const submitCreatedInvoice = async ({ id }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/invoice/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

// WebSocket connection function
export const connectToWebSocket = ({ recipient_id, sender_ids, onMessage}) => {
  const ws = new WebSocket(`wss://payapi.aetdevops.com/ws/messages/${sender_ids}/${recipient_id}`);

  ws.onopen = () => {
    console.log("WebSocket connection established");
  };

  ws.onmessage = (event) => {
    const incomingData = JSON.parse(event.data);
    // console.log("Message received:", incomingData);
    if (onMessage) {
      onMessage(incomingData);
    }
  };

  ws.onerror = (error) => {
    console.error("WebSocket error:", error);
  };

  ws.onclose = () => {
    console.log("WebSocket connection closed");
  };

  return ws; 
};
