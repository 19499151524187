// import ContestedInvoiceCard from "./cards/ContestedInvoiceCard";

import { PAIconArrowLeftGreen, PAIconInvoiceIcon } from "assets/images/svgs";
import ContestedInvoiceCard from "features/openMarket/supplier/components/cards/ContestedInvoiceCard";
import DashboardLayout from "layout/dashboardlayout";
import { useNavigate } from "react-router-dom";

function TopTenContestedSupplier({
  head = "Top 10 Contested Invoices",
  subHead = "Invoices with the highest number of bidders",
  topTenBids,
  setActiveTab,
}) {
  const navigate = useNavigate();
  const handleBack = () => navigate(-1);
  return (
    <DashboardLayout
      section="Open Market"
      subHeader="Manage your invoices imported to the Open market"
    >
      <div className="flex flex-col gap-4">
        <header
          className="bg-white text-primaryColor px-6  rounded-[5px]"
          onClick={() => handleBack()}
        >
          <span className="flex cursor-pointer py-4 w-fit font-light items-center text-sm gap-2">
            <PAIconArrowLeftGreen />
            Go Back
          </span>
        </header>
        <div className="flex flex-col gap-6 rounded-[10px] min-h-[80vh]  bg-unusualGray pb-12 ">
          <header className="flex bg-white justify-between items-center pb-4 border-b px-6 pt-6 border-b-subtleGray rounded-t-[10px]">
            <div className="flex flex-col gap-1">
              <span className=" font-semibold">{head}</span>
              <span className=" text-[#959595] text-xs">{subHead}</span>
            </div>
          </header>

          {topTenBids?.length > 0 ? (
            <div className="flex flex-col gap-2.5">
              {topTenBids?.map((bid) => (
                <ContestedInvoiceCard
                  id={bid?.openMarketReference}
                  key={bid?.openMarketReference}
                  invoiceRef={bid?.openMarketReference}
                  invoiceNumber={bid?.openMarketReference}
                  buyerName={bid?.buyerName}
                  noOfBids={bid?.noOfBids}
                  invoiceAmount={bid?.invoiceAmount}
                  dueDate={bid?.dueDate || "2024-12-31"}
                  status={bid?.status}
                />
              ))}
            </div>
          ) : (
            <div className="w-full flex font-medium flex-col items-center gap-4 animate-pulse justify-center">
              <span> No Contested Invoice Available </span>

              <PAIconInvoiceIcon />
            </div>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
}

export default TopTenContestedSupplier;
