import request from "apiInstance";
import toast from "react-hot-toast";

export const createWorkflowApi = async (body) => {
  try {
    const response = await request({
      method: "post",
      url: `/api/v1/workflow`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllWorkflowApi = async () => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/workflows`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getWorkflowByIDApi = async (id) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/workflow/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const modifyWorkflowStatusApi = async ({ id, status }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/workflow/modify-status/${id}?status=${status}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getWorkflowByModuleApi = async (moduleName) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/workflow/by-module/${moduleName}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllWorkflowDashboardApi = async () => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/workflow/dashboard`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getUsersByRoleApi = async (roleName) => {
  try {
    const response = await request({
      method: "get",
      url: `/admin/permissions/${roleName}/staff`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getRecentWorkflowApprovalsApi = async ({
  page,
  pageSize,
  search,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/workflow/recent-approvals?page=${page}&size=${pageSize}&search=${search}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const updateWorkflowStatusApi = async ({ id, body }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/workflow/process/${id}`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllWorkflowPendingApprovalsApi = async ({
  page,
  pageSize,
  search,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/workflow/pending-approvals?page=${page}&size=${pageSize}&search=${search}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

// {
//   page,
//   pageSize,
//   bulk,
//   status,
//   search,
// }
export const getAllWorkflowPendingRequestsApi = async ({
  page,
  pageSize,
  search,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/workflow/requests?page=${page}&size=${pageSize}&search=${search}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
export const getAllWorkflowPendingRequestHistorysApi = async ({
  page,
  pageSize,
  search,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/workflow/requests-history?page=${page}&size=${pageSize}&search=${search}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
export const getAllWorkflowPendingRequestByIdApi = async ({ id }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/workflow/request/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
export const approveWorkFlowById = async ({ id, body }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/workflow/approve/${id}`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
//  url: `/api/v1/workflow/declined-requests?page=0&size=10&search=${search}`,

export const getAllWorkflowDeclinedRequestsApi = async ({
  page,
  pageSize,
  search,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/workflow/declined-requests?page=${page}&size=${pageSize}&search=${search}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
