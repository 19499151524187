import React, { useEffect, useState } from "react";
import SearchInput from "../../SearchInput";
import Table from "layout/table/Table";
import { fetchAgreementTemplates } from "appstate/api/agreement/agreement";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const TemplateTable = ({ goToUpload,templateDetails }) => {
  // const [showNewAgreement, setShowNewAgreement] = useState(false);
  // const [showAgreementDetails, setShowAgreementDetails] = useState(false);
  // const [selectedAgreement, setSelectedAgreement] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  // eslint-disable-next-line
  const [templates, setTemplates] = useState([]);
  // eslint-disable-next-line
  const [page, setPage] = useState(0);
  const [size] = useState(10);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();


  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const getTemplates = async () => {
    try {
      setLoading(true);
      const response = await fetchAgreementTemplates({ search: searchQuery, page, size });
      setTemplates(response?.data || []);
    } catch (error) {
      toast.error("Failed to fetch templates!");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTemplates();
    // eslint-disable-next-line
  }, [searchQuery, page]);

  // const handleCreateNewAgreement = () => {
  //   setShowNewAgreement(true);
  //   setShowAgreementDetails(false);
  //   setSelectedAgreement(null);
  // };

  // const handleShowAgreementDetails = (agreement) => {
  //   setSelectedAgreement(agreement);
  //   setShowAgreementDetails(true);
  //   setShowNewAgreement(false);
  // };

  // function handleBack() {
  //   setShowNewAgreement(false);
  //   setShowAgreementDetails(false);
  //   setSelectedAgreement(null);
  // }
  // const handleCreateNewAgreement = () => {
  //   setShowNewAgreement(true);
  //   setShowAgreementDetails(false);
  //   setSelectedAgreement(null);
  // };
 
  const goToTemplateDetails=(row)=>{
    navigate(`template-details/${row?.id}`)
  }

  // const goToTemplateDetails=(row)=>{
  //   templateDetails();

  // }

  const columns = [
    {
      name: "TEMPLATE NAME",
      selector: (row) => row.name,
      sortable: true,
      width: "10rem",
    },
    {
      name: "AGREEMENT TYPE",
      selector: (row) => row.templateType,
      sortable: true,
      width: "10rem",
    },
    {
      name: "DATE UPLOADED",
      selector: (row) => row.uploadDate,
      sortable: true,
      width: "10rem",
    },
  ];

  return (
    <div>
      {" "}
      <div className="flex items-center justify-between gap-3 ">
        <button
          onClick={goToUpload}
          className="text-white text-xs p-2 font-inter textbase font-normal leading-normal rounded-md border border-[#CCC] bg-[#FFBE55]"
        >
          Upload document
        </button>
        <div className="ml-5">
          <SearchInput onSearch={handleSearch} />
        </div>
      </div>
      <div className=" flex flex-wrap  items-center w-full mt-4 gap-5">
        <div>
          <select
            className=" shadow-sm  text-sm focus:outline-none  cursor-pointer h-[37px] px-3 py-2.5 rounded-[5px] border border-[#949494] justify-start items-end gap-2 inline-flex  "
            // onChange={(e) => {}}
            // onChange={(e) => setStatus(e.target.value)}
          >
            <option value="" key="all-bids">
              All Agreement Type
            </option>
            <option value="BID_ACTIVE" key="bid-active">
              PFP Campaign
            </option>
            <option value="POSTED" key="posted-request">
              Direct Funding
            </option>
            <option value="POSTED" key="posted-request">
              Early Payment
            </option>
            <option value="POSTED" key="posted-request">
              Closed Market
            </option>
            <option value="POSTED" key="posted-request">
              Open Market
            </option>
          </select>
        </div>

        <div>
          <select
            className=" shadow-sm  text-sm focus:outline-none  cursor-pointer h-[37px] px-3 py-2.5 rounded-[5px] border border-[#949494] justify-start items-end gap-2 inline-flex  "
            // onChange={(e) => {}}
            // onChange={(e) => setStatus(e.target.value)}
          >
            <option value="" key="all-bids">
              Pending Signature
            </option>
            <option value="BID_ACTIVE" key="bid-active">
              Signed
            </option>
            <option value="POSTED" key="posted-request">
              Updated
            </option>
            <option value="POSTED" key="posted-request">
              Rejected
            </option>
            <option value="POSTED" key="posted-request">
              Cancelled
            </option>
          </select>
        </div>
      </div>
      <div className=" mt-3">
        <Table
          columns={columns}
          data={templates}
           onRowClicked={(row) => goToTemplateDetails(row)}
          pointer
          tableHeader
          className="mt-5"
        />
      </div>
    </div>
  );
};

export default TemplateTable;
