import {
  PAIconSmallClose,
  PAIconDirectFundingRejected,
} from "assets/images/svgs";
import { motion } from "framer-motion";

const DirectFundingDeclinedModal = ({ onClose, isOpen }) => {
  const handleCloseDeleteModal = () => {
    onClose();
  };

  if (!isOpen) {
    return null;
  }
  return (
    <motion.div>
      <motion.div className="bg-slate-600/20 fixed inset-0 flex items-center justify-center z-50 ">
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.2,
            },
          }}
          exit={{
            opacity: 0,
            transition: {
              delay: 0.1,
            },
          }}
          className="modal-overlay"
        ></motion.div>

        <motion.div
          initial={{
            scale: 0,
          }}
          animate={{
            scale: 1,
            transition: {
              duration: 0.2,
            },
          }}
          exit={{
            scale: 0,
            transition: {
              delay: 0.1,
            },
          }}
          className=" py-11 bg-white rounded-[10px]    flex flex-col lg:w-[540px] lg:min-h-[400px]  md:min-h-[400px]   lg:overflow-hidden   md:overflow-scroll  sm:overflow-scroll xs:overflow-scroll min-h-[558px]"
        >
          {/* header side */}
          <div className="px-6  lg:px-7">
            <header className="flex justify-between pb-4 border-b-[4px] border-[#F6F5FA] ">
              <span className="text-black text-base ">
                Direct Funding request rejected
              </span>

              <span
                onClick={() => handleCloseDeleteModal()}
                className="flex items-center cursor-pointer gap-2 text-sm font-[500] text-[#565656]"
              >
                <PAIconSmallClose />
                <span class="text-[#565656] text-sm font-medium ">Close</span>
              </span>
            </header>
          </div>
          <div className=" py-3  justify-center items-center flex">
            <PAIconDirectFundingRejected />
          </div>
          {/* text section */}
          <div class=" text-[#222222] text-base font-medium  tracking-wide w-full px-6">
            Request Declined. Thank you for your consideration. Feel free to
            explore more opportunities on our platform. We appreciate your
            partnership
          </div>

          {/* Input section */}
        </motion.div>
      </motion.div>
    </motion.div>
  );
};
export default DirectFundingDeclinedModal;
