import React from "react";

const RejectModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg z-[1000]">
      <div className="bg-white p-5 px5 rounded-md max-w-xs md:max-w-md w-full">
        <div className="flex justify-between items-center mb-4">
          <p className="text-black font-sans text-base font-semibold">
           Agreement rejected
          </p>
          <button
            onClick={onClose}
            className="text-black font-sans text-xl cursor-pointer flex justify-center items-center"
          >
            &times; <span className="text-sm mx-2 mt-1">Close</span>
          </button>
        </div>
        <div className="border border-gray my-5"></div>

        {/* FILE BUTTONS */}
        <div className="w-full">
          <img src="/Group-reject.png" alt="/" className="mx-auto my-3" />

          <div className="text-[#222] font-sans text-sm font-normal tracking-wide">
          Thank you for your response. We'll communicate your decision to the other party. Please consider sharing your reasons for disagreeing with the agreement in the comments below; your input is appreciated.
          </div>
        </div>
      </div>
    </div>
  );
};

export default RejectModal;
