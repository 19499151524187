import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getSupplierOverview,
  getAllTransactions,
  sendRemainder,
  getSupplierReceivableSummaryOverview,
  getSupplierFundSummaryOverview,
  getSupplierOpenMarketPendingPayment,
  getSupplierOpenMarketPendingReimbursement,
  getSupplierEarlyPaymentOffer,
  getSupplierSingleEarlyPaymentRequest,
  getSupplierBulkEarlyPaymentRequest,
  getSupplierDirectFundingSingleFundedInvoice,
  getSupplierDirectFundingBulkFundedInvoice,
  getSupplierDirectFundingBulk,
  getSupplierDirectFundingSingle,
  getRecievablesUpcomingFull,
  getRecievablesUpcomingPartial,
  getRecievablesOverdueInvoicesFull,
  getRecievablesOverdueInvoicesPartial,
  getSupplierRecievablesInvoice,
  getTransactionsInflow,
  getTransactionsReimbursement,
  getSupplierPartialRecievablesInvoice,
  getFunderOpenMarket,
  getFunderPFP,
  getFunderClosedMarket,
  getSupplierSingleEPRDetails,
  getSupplierBulkEPRDetails,
  getFunderEPRDirectFuding,
  getFunderOverviewAwaitingSummary,
  getFunderOverview,
  getAllSingleLoanDefaults,
  getAllBulkLoanDefaults,
  getFunderDirectFuding,
  getAllSingleOverdueInvoices,
  getAllBulkOverdueInvoices,
  getSupplierTransactionDetails,
} from "appstate/api/collections/collections";

const initialState = {
  activeTab: "overview",
  isLoading: false,
  supplierOverviewData: null,
  supplierReceivableOverviewData: null,
  supplierFundsOverviewData: null,
  supplierOpenMarketPendingPaymentData: [],
  supplierOpenMarketPendingReimbursementData: [],
  supplierEarlyPaymentOfferData: [],
  supplierBulkEPRData: [],
  supplierSingleEPRData: [],
  supplierBulkEPRDetailsData: [],
  supplierSingleEPRDetailsData: [],
  supplierSingleDFFundedInvoiceData: [],
  supplierBulkDFFundedInvoiceData: [],
  supplierBulkDFData: [],
  supplierSingleDFData: [],
  funderOverviewData: null,
  recievablesUpcomingFullData: [],
  recievablesUpcomingPartialData: [],
  recievablesOverdueFullData: [],
  recievablesOverduePartialData: [],
  supplierReceivableInvoiceData: [],
  supplierPartialReceivableInvoiceData: [],
  transactionReimbursementData: [],
  transactionInflowData: [],
  transactionData: [],
  allTransactionsDetailsData: [],
  remainderMessage: null,
  allFunderOpenMarketData: [],
  allFunderPFPData: [],
  allFunderClosedMarketData: [],
  allFunderEPRDirectFundingData: [],
  allFunderDirectFundingData: [],
  allFunderOverviewAwaitingSummaryData: null,
  singleLoanDefaultData: [],
  bulkLoanDefaultData: [],
  singleOverdueInvoicesData: [],
  bulkOverdueInvoicesData: [],
};

export const supplierOverview = createAsyncThunk(
  "supplierOverview",
  getSupplierOverview
);

export const supplierReceivableOverview = createAsyncThunk(
  "supplierReceivableOverview",
  getSupplierReceivableSummaryOverview
);

export const supplierOpenMarketPendingPayment = createAsyncThunk(
  "supplierOpenMarketPendingPayment",
  getSupplierOpenMarketPendingPayment
);

export const supplierOpenMarketPendingReimbursement = createAsyncThunk(
  "supplierOpenMarketPendingReimbursement",
  getSupplierOpenMarketPendingReimbursement
);

export const supplierEarlyPaymentOffer = createAsyncThunk(
  "supplierEarlyPaymentOffer",
  getSupplierEarlyPaymentOffer
);

export const supplierFundsOverview = createAsyncThunk(
  "supplierFundsOverview",
  getSupplierFundSummaryOverview
);

export const supplierSingleEPR = createAsyncThunk(
  "supplierSingleEPR",
  getSupplierSingleEarlyPaymentRequest
);

export const supplierSingleEPRDetails = createAsyncThunk(
  "supplierSingleEPRDetails",
  getSupplierSingleEPRDetails
);

export const supplierBulkEPR = createAsyncThunk(
  "supplierBulkEPR",
  getSupplierBulkEarlyPaymentRequest
);

export const supplierBulkEPRDetails = createAsyncThunk(
  "supplierBulkEPRDetails",
  getSupplierBulkEPRDetails
);

export const supplierSingleDFFundedInvoice = createAsyncThunk(
  "supplierSingleDFFundedInvoice",
  getSupplierDirectFundingSingleFundedInvoice
);

export const supplierBulkDFFundedInvoice = createAsyncThunk(
  "supplierBulkDFFundedInvoice",
  getSupplierDirectFundingBulkFundedInvoice
);

export const supplierSingleDF = createAsyncThunk(
  "supplierSingleDF",
  getSupplierDirectFundingSingle
);

export const supplierBulkDF = createAsyncThunk(
  "supplierBulkDF",
  getSupplierDirectFundingBulk
);

export const recievablesUpcomingFull = createAsyncThunk(
  "recievablesUpcomingFull",
  getRecievablesUpcomingFull
);

export const recievablesUpcomingPartial = createAsyncThunk(
  "recievablesUpcomingPartial",
  getRecievablesUpcomingPartial
);

export const recievablesOverdueFull = createAsyncThunk(
  "recievablesOverdueFull",
  getRecievablesOverdueInvoicesFull
);

export const recievablesOverduePartial = createAsyncThunk(
  "recievablesOverduePartial",
  getRecievablesOverdueInvoicesPartial
);

export const supplierReceivableInvoice = createAsyncThunk(
  "supplierReceivableInvoice",
  getSupplierRecievablesInvoice
);

export const supplierPartialReceivableInvoice = createAsyncThunk(
  "supplierPartialReceivableInvoice",
  getSupplierPartialRecievablesInvoice
);

export const allTransactionsInflow = createAsyncThunk(
  "allTransactionsInflow",
  getTransactionsInflow
);

export const allTransactionsReimbursement = createAsyncThunk(
  "allTransactionsReimbursement",
  getTransactionsReimbursement
);

export const allTransactions = createAsyncThunk(
  "allTransactions",
  getAllTransactions
);

export const allTransactionsDetails = createAsyncThunk(
  "allTransactionsDetails",
  getSupplierTransactionDetails
);

export const allFunderOpenMarket = createAsyncThunk(
  "allFunderOpenMarket",
  getFunderOpenMarket
);

export const allFunderPFP = createAsyncThunk("allFunderPFP", getFunderPFP);

export const allFunderClosedMarket = createAsyncThunk(
  "allFunderClosedMarket",
  getFunderClosedMarket
);

export const allFunderEPRDirectFunding = createAsyncThunk(
  "allFunderEPRDirectFunding",
  getFunderEPRDirectFuding
);

export const allFunderDirectFunding = createAsyncThunk(
  "allFunderDirectFunding",
  getFunderDirectFuding
);

export const allFunderOverviewAwaitingSummary = createAsyncThunk(
  "allFunderOverviewAwaitingSummary",
  getFunderOverviewAwaitingSummary
);

export const funderOverview = createAsyncThunk(
  "funderOverview",
  getFunderOverview
);

export const singleLoanDefault = createAsyncThunk(
  "singleLoanDefault",
  getAllSingleLoanDefaults
);

export const bulkLoanDefault = createAsyncThunk(
  "bulkLoanDefault",
  getAllBulkLoanDefaults
);

export const singleOverdueInvoices = createAsyncThunk(
  "singleOverdueInvoices",
  getAllSingleOverdueInvoices
);

export const bulkOverdueInvoices = createAsyncThunk(
  "bulkOverdueInvoices",
  getAllBulkOverdueInvoices
);

export const remainder = createAsyncThunk("remainder", sendRemainder);

const collectionSlice = createSlice({
  name: "collection",
  initialState,
  reducers: {
    setActiveTab(state, action) {
      state.activeTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    // supplier overview
    builder.addCase(supplierOverview.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(supplierOverview.fulfilled, (state, action) => {
      state.isLoading = false;
      state.supplierOverviewData = action.payload;
    });
    builder.addCase(supplierOverview.rejected, (state) => {
      state.isLoading = false;
    });
    // supplier receivable overview
    builder.addCase(supplierReceivableOverview.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(supplierReceivableOverview.fulfilled, (state, action) => {
      state.isLoading = false;
      state.supplierReceivableOverviewData = action.payload;
    });
    builder.addCase(supplierReceivableOverview.rejected, (state) => {
      state.isLoading = false;
    });
    // supplier fund overview
    builder.addCase(supplierFundsOverview.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(supplierFundsOverview.fulfilled, (state, action) => {
      state.isLoading = false;
      state.supplierFundsOverviewData = action.payload;
    });
    builder.addCase(supplierFundsOverview.rejected, (state) => {
      state.isLoading = false;
    });
    // supplier Open Market Pending Payment table data
    builder.addCase(supplierOpenMarketPendingPayment.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      supplierOpenMarketPendingPayment.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.supplierOpenMarketPendingPaymentData = action.payload;
      }
    );
    builder.addCase(supplierOpenMarketPendingPayment.rejected, (state) => {
      state.isLoading = false;
    });
    // supplier Open Market Pending Reimbursement table data
    builder.addCase(supplierOpenMarketPendingReimbursement.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      supplierOpenMarketPendingReimbursement.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.supplierOpenMarketPendingReimbursementData = action.payload;
      }
    );
    builder.addCase(
      supplierOpenMarketPendingReimbursement.rejected,
      (state) => {
        state.isLoading = false;
      }
    );
    // supplier Early Payment Offer table data
    builder.addCase(supplierEarlyPaymentOffer.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(supplierEarlyPaymentOffer.fulfilled, (state, action) => {
      state.isLoading = false;
      state.supplierEarlyPaymentOfferData = action.payload;
    });
    builder.addCase(supplierEarlyPaymentOffer.rejected, (state) => {
      state.isLoading = false;
    });
    // supplier Single Early Payment Request table data
    builder.addCase(supplierSingleEPR.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(supplierSingleEPR.fulfilled, (state, action) => {
      state.isLoading = false;
      state.supplierSingleEPRData = action.payload;
    });
    builder.addCase(supplierSingleEPR.rejected, (state) => {
      state.isLoading = false;
    });
    // supplier Single Early Payment Request details data
    builder.addCase(supplierSingleEPRDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(supplierSingleEPRDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.supplierSingleEPRDetailsData = action.payload;
    });
    builder.addCase(supplierSingleEPRDetails.rejected, (state) => {
      state.isLoading = false;
    });
    // supplier Bulk Early Payment Request table data
    builder.addCase(supplierBulkEPR.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(supplierBulkEPR.fulfilled, (state, action) => {
      state.isLoading = false;
      state.supplierBulkEPRData = action.payload;
    });
    builder.addCase(supplierBulkEPR.rejected, (state) => {
      state.isLoading = false;
    });
    // supplier Bulk Early Payment Request table data
    builder.addCase(supplierBulkEPRDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(supplierBulkEPRDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.supplierBulkEPRDetailsData = action.payload;
    });
    builder.addCase(supplierBulkEPRDetails.rejected, (state) => {
      state.isLoading = false;
    });
    // supplier Single Direct funding funded invoice table data
    builder.addCase(supplierSingleDFFundedInvoice.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      supplierSingleDFFundedInvoice.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.supplierSingleDFFundedInvoiceData = action.payload;
      }
    );
    builder.addCase(supplierSingleDFFundedInvoice.rejected, (state) => {
      state.isLoading = false;
    });
    // supplier Bulk Direct funding funded invoice table data
    builder.addCase(supplierBulkDFFundedInvoice.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(supplierBulkDFFundedInvoice.fulfilled, (state, action) => {
      state.isLoading = false;
      state.supplierBulkDFFundedInvoiceData = action.payload;
    });
    builder.addCase(supplierBulkDFFundedInvoice.rejected, (state) => {
      state.isLoading = false;
    });
    // supplier Single Direct funding table data
    builder.addCase(supplierSingleDF.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(supplierSingleDF.fulfilled, (state, action) => {
      state.isLoading = false;
      state.supplierSingleDFData = action.payload;
    });
    builder.addCase(supplierSingleDF.rejected, (state) => {
      state.isLoading = false;
    });
    // supplier Bulk Direct funding table data
    builder.addCase(supplierBulkDF.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(supplierBulkDF.fulfilled, (state, action) => {
      state.isLoading = false;
      state.supplierBulkDFData = action.payload;
    });
    builder.addCase(supplierBulkDF.rejected, (state) => {
      state.isLoading = false;
    });
    // RECIEVABLES UPCOMING FULL TABLE DATA
    builder.addCase(recievablesUpcomingFull.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(recievablesUpcomingFull.fulfilled, (state, action) => {
      state.isLoading = false;
      state.recievablesUpcomingFullData = action.payload;
    });
    builder.addCase(recievablesUpcomingFull.rejected, (state) => {
      state.isLoading = false;
    });
    // RECIEVABLES UPCOMING PARTIAL TABLE DATA
    builder.addCase(recievablesUpcomingPartial.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(recievablesUpcomingPartial.fulfilled, (state, action) => {
      state.isLoading = false;
      state.recievablesUpcomingPartialData = action.payload;
    });
    builder.addCase(recievablesUpcomingPartial.rejected, (state) => {
      state.isLoading = false;
    });
    // RECIEVABLES OVERDUE FULL TABLE DATA
    builder.addCase(recievablesOverdueFull.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(recievablesOverdueFull.fulfilled, (state, action) => {
      state.isLoading = false;
      state.recievablesOverdueFullData = action.payload;
    });
    builder.addCase(recievablesOverdueFull.rejected, (state) => {
      state.isLoading = false;
    });
    // RECIEVABLES OVERDUE PARTIAL TABLE DATA
    builder.addCase(recievablesOverduePartial.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(recievablesOverduePartial.fulfilled, (state, action) => {
      state.isLoading = false;
      state.recievablesOverduePartialData = action.payload;
    });
    builder.addCase(recievablesOverduePartial.rejected, (state) => {
      state.isLoading = false;
    });
    // SUPPLIER OPEN MARKET INVOICES
    builder.addCase(supplierReceivableInvoice.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(supplierReceivableInvoice.fulfilled, (state, action) => {
      state.isLoading = false;
      state.supplierReceivableInvoiceData = action.payload;
    });
    builder.addCase(supplierReceivableInvoice.rejected, (state) => {
      state.isLoading = false;
    });
    // SUPPLIER Recievable Partial INVOICES
    builder.addCase(supplierPartialReceivableInvoice.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      supplierPartialReceivableInvoice.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.supplierPartialReceivableInvoiceData = action.payload;
      }
    );
    builder.addCase(supplierPartialReceivableInvoice.rejected, (state) => {
      state.isLoading = false;
    });
    // INFLOW TRANSACTION TABLE DATA
    builder.addCase(allTransactionsInflow.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allTransactionsInflow.fulfilled, (state, action) => {
      state.isLoading = false;
      state.transactionInflowData = action.payload;
    });
    builder.addCase(allTransactionsInflow.rejected, (state) => {
      state.isLoading = false;
    });
    // REIMBURSEMENT TRANSACTION TABLE DATA
    builder.addCase(allTransactionsReimbursement.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allTransactionsReimbursement.fulfilled, (state, action) => {
      state.isLoading = false;
      state.transactionReimbursementData = action.payload;
    });
    builder.addCase(allTransactionsReimbursement.rejected, (state) => {
      state.isLoading = false;
    });
    // TRANSACTION TABLE DATA
    builder.addCase(allTransactions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allTransactions.fulfilled, (state, action) => {
      state.isLoading = false;
      state.transactionData = action.payload;
    });
    builder.addCase(allTransactions.rejected, (state) => {
      state.isLoading = false;
    });
    // TRANSACTION DETAILS DATA
    builder.addCase(allTransactionsDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allTransactionsDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allTransactionsDetailsData = action.payload;
    });
    builder.addCase(allTransactionsDetails.rejected, (state) => {
      state.isLoading = false;
    });
    // SEND REMAINDER
    builder.addCase(remainder.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(remainder.fulfilled, (state, action) => {
      state.isLoading = false;
      state.remainderMessage = action.payload;
    });
    builder.addCase(remainder.rejected, (state) => {
      state.isLoading = false;
    });
    // // // // // // // // // // FUNDER OPEN MARKET // // // // // // // // // //
    builder.addCase(allFunderOpenMarket.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allFunderOpenMarket.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allFunderOpenMarketData = action.payload;
    });
    builder.addCase(allFunderOpenMarket.rejected, (state) => {
      state.isLoading = false;
    });
    // // // // // // // // // // FUNDER PFP // // // // // // // // // //
    builder.addCase(allFunderPFP.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allFunderPFP.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allFunderPFPData = action.payload;
    });
    builder.addCase(allFunderPFP.rejected, (state) => {
      state.isLoading = false;
    });
    // // // // // // // // // // FUNDER CLOSED MARKET // // // // // // // // // //
    builder.addCase(allFunderClosedMarket.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allFunderClosedMarket.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allFunderClosedMarketData = action.payload;
    });
    builder.addCase(allFunderClosedMarket.rejected, (state) => {
      state.isLoading = false;
    });
    // // // // // // // // // // FUNDER EPR DIRECT FUNDING // // // // // // // // // //
    builder.addCase(allFunderEPRDirectFunding.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allFunderEPRDirectFunding.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allFunderEPRDirectFundingData = action.payload;
    });
    builder.addCase(allFunderEPRDirectFunding.rejected, (state) => {
      state.isLoading = false;
    });
    // // // // // // // // // // FUNDER DIRECT FUNDING // // // // // // // // // //
    builder.addCase(allFunderDirectFunding.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allFunderDirectFunding.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allFunderDirectFundingData = action.payload;
    });
    builder.addCase(allFunderDirectFunding.rejected, (state) => {
      state.isLoading = false;
    });
    // // // // // // // // // // FUNDER OVERVIEW AWAITING SUMMARY // // // // // // // // // //
    builder.addCase(allFunderOverviewAwaitingSummary.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      allFunderOverviewAwaitingSummary.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.allFunderOverviewAwaitingSummaryData = action.payload;
      }
    );
    builder.addCase(allFunderOverviewAwaitingSummary.rejected, (state) => {
      state.isLoading = false;
    });
    // funder overview
    builder.addCase(funderOverview.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(funderOverview.fulfilled, (state, action) => {
      state.isLoading = false;
      state.funderOverviewData = action.payload;
    });
    builder.addCase(funderOverview.rejected, (state) => {
      state.isLoading = false;
    });
    // single loan default
    builder.addCase(singleLoanDefault.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(singleLoanDefault.fulfilled, (state, action) => {
      state.isLoading = false;
      state.singleLoanDefaultData = action.payload;
    });
    builder.addCase(singleLoanDefault.rejected, (state) => {
      state.isLoading = false;
    });
    // bulk loan default
    builder.addCase(bulkLoanDefault.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(bulkLoanDefault.fulfilled, (state, action) => {
      state.isLoading = false;
      state.bulkLoanDefaultData = action.payload;
    });
    builder.addCase(bulkLoanDefault.rejected, (state) => {
      state.isLoading = false;
    });

    // single overdue invoices
    builder.addCase(singleOverdueInvoices.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(singleOverdueInvoices.fulfilled, (state, action) => {
      state.isLoading = false;
      state.singleOverdueInvoicesData = action.payload;
    });
    builder.addCase(singleOverdueInvoices.rejected, (state) => {
      state.isLoading = false;
    });
    // bulk overdue invoices
    builder.addCase(bulkOverdueInvoices.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(bulkOverdueInvoices.fulfilled, (state, action) => {
      state.isLoading = false;
      state.bulkOverdueInvoicesData = action.payload;
    });
    builder.addCase(bulkOverdueInvoices.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const { setActiveTab } = collectionSlice.actions;
export default collectionSlice.reducer;
