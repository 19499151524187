import request from "apiInstance";
import toast from "react-hot-toast";

export const submitTemplate = async ({ name, type, action, file }) => {
    try {
        const formData = new FormData();
        formData.append("file", file);
        const response = await request({
            method: "post",
            url: `/api/v2/agreement/templates?name=${name}&type=${type}&action=${action}`,
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        return response?.data;
    } catch (error) {
        toast.error(error?.response?.data?.message + "!");
        throw error;
    }
}

export const fetchAgreementTemplates = async ({search, page, size}) => {
    try {
        const response = await request({
            method: "get",
            url: `/api/v2/agreement/templates?search=${search}&page=${page}&size=${size}`,
        });
        return response?.data;
    } catch (error) {
        toast.error(error?.response?.data?.message + "!");
        throw error;
    }
};


export const fetchAgreementTemplatesDetails = async (id) => {
    try {
        const response = await request({
            method: "get",
            url: `/api/v2/agreement/templates/${id}`,
        });
        return response?.data;
    } catch (error) {
        toast.error(error?.response?.data?.message + "!");
        throw error;
    }
};