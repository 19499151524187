import { motion } from "framer-motion";
import {
  PAIconCocacola,
  PAIconInvShop,
  PAIconModelOne,
} from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toggleCancelButtonOn } from "../buttonSlice";

function PendingBidRequestCard({
  bidOfferId,
  setOfferId,
  id,
  invoiceNumber = "R3456X89Y",
  buyerCompanyName = "Tacna Plc.",
  supplierCompanyName = "NJ Breweries",
  description = "1.2%",
  invoiceAmount = "N272,847,900",
  lastModifiedDate = "2023-11-01",
  dueDate = "2023-11-01",
  numberOfBids = "0",
  status = "notleading",
}) {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  return (
    <motion.div
      whileTap={{ scale: 0.97 }}
      onClick={() => {
        dispatch(toggleCancelButtonOn());
        navigate(`ongoing/${id}?offerId=${bidOfferId}`);
      }}
      style={{ boxShadow: "0px 4px 12px 5px rgba(0, 0, 0, 0.04)" }}
      className="bg-white cursor-pointer flex flex-col gap-7 px-6 py-[25px] rounded-[10px]"
    >
      <div className="flex justify-between">
        <div className="flex gap-6 items-center">
          <PAIconInvShop />
          <div className="flex flex-col gap-0.5">
            <span className="text-sm font-[500]"> {invoiceNumber}</span>
            <span className=" text-[10px] text-[#959595]">Invoice Reference</span>
          </div>
        </div>

        <div className="flex items-center gap-2 ">
          <PAIconModelOne />
          <div className="flex flex-col gap-0.5">
            <span className="text-sm font-[500]">{buyerCompanyName}</span>
            <span className=" text-[10px] text-[#959595]">Buyer</span>
          </div>
        </div>

        <div className="flex items-center gap-2 ">
          <PAIconCocacola />
          <div className="flex flex-col gap-0.5">
            <span className="text-sm font-[500]">{supplierCompanyName}</span>
            <span className=" text-[10px] text-[#959595]">Supplier</span>
          </div>
        </div>

        <div className="flex items-center gap-2 ">
          <div className="flex flex-col gap-0.5">
            <span className="text-sm text-secondaryColor font-[500]">
              Bid Active
            </span>
            <span className=" text-[10px] text-[#959595]">
              Status of invoice
            </span>
          </div>
        </div>

        {status === "leading" && (
        <div className="flex items-center gap-2">
          <div className="flex flex-col gap-0.5 bg-[#FEC123] py-2.5 px-5">
            <span className="text-sm text-white font-[500]">
              Currently leading
            </span>
          </div>
        </div>
      )}

        {/* <motion.button
          onClick={(event) => {
            event.stopPropagation();
            cancelInvoice(true);
          }}
          whileTap={{ scale: 0.9 }}
          className="flex border border-red  text-sm justify-center gap-2 text-white bg-white rounded-[5px] items-center px-2.5 py-[5px] "
        >
          <span className="sm:hidden extra-md:inline text-red">
            Cancel Request
          </span>{" "}
          <span className=" flex extra-md:hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          </span>
        </motion.button> */}
      </div>

      <div className="flex justify-between">
        <div className="text-sm flex gap-3">
          <span className=" text-black">Lock Rate:</span>
          <span className=" text-[#959595]">{description || "1.2%"}</span>
        </div>

        <div className="text-sm flex gap-3">
          <span className=" text-black">Request Amount:</span>
          <span className=" text-[#959595]">{invoiceAmount}</span>
        </div>

        <div className="text-sm hidden semi-lg:flex gap-3">
          <span className=" text-black">Request Date:</span>
          <span className=" text-[#959595]">{lastModifiedDate}</span>
        </div>

        <div className="text-sm hidden semi-lg:flex gap-3">
          <span className=" text-black">Payment Date:</span>
          <span className=" text-[#959595]">{dueDate}</span>
        </div>
      </div>
    </motion.div>
  );
}

export default PendingBidRequestCard;
