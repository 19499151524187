import React from "react";
import DashboardLayout from "layout/dashboardlayout";
import DashBoard from "./dashboard/DashBoard";

const WorkFlowDashBoard = () => {
  return (
    <DashboardLayout
      section="Workflow "
      subHeader="Manage all your workflow approvals"
    >
      <DashBoard />
    </DashboardLayout>
  );
};

export default WorkFlowDashBoard;
