// import Button from 'shared/Button';
import { useFormik } from "formik";
import Spinner from "shared/Spinner/Spinner";
import { useSelector } from "react-redux";
import DeleteIcon from "../../../assets/images/svgs/DeleteIcon.svg";
import InputField from "shared/InputField";
import Button from "shared/Button";

const NewInvoiceTable = ({
  toggleTaxInclusive,
  editing,
  setEditing,
  controls,
  setControls,
  singleInvoice,
  createInvoiceSupplier,
  handleDiscard,
}) => {
  const { items, discount, vat } = controls;
  const { isLoading } = useSelector((state) => state.invoice);

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newItems = [...items];
    newItems[index] = { ...newItems[index], [name]: value };

    if (name === "quantity" || name === "unitPrice" || name === "taxRate") {
      const unitPrice = parseFloat(newItems[index].unitPrice);
      const quantity = parseFloat(newItems[index].quantity);
      const taxRate =
        newItems[index].taxRate === ""
          ? null
          : parseFloat(newItems[index].taxRate);

      if (quantity < 0 || unitPrice < 0 || taxRate < 0) {
        return;
      }

      const taxAmount = parseFloat(
        (unitPrice * quantity * (taxRate / 100)).toFixed(2)
      );
      const amount = parseFloat((unitPrice * quantity).toFixed(2));
      newItems[index].amount = amount;
      newItems[index].taxAmount = taxAmount;
      newItems[index].totalPrice = amount + taxAmount;
    }
    setControls({ ...controls, items: newItems });
  };

  const handleAddItem = () => {
    const newItems = [
      ...items,
      {
        id: Date.now(),
        itemName: "",
        description: "",
        unitPrice: "",
        taxRate: "",
        quantity: "",
      },
    ];
    setControls({ ...controls, items: newItems });
  };

  const handleDeleteItem = (id) => {
    const newItems = items.filter((item) => item.id !== id);
    setControls({ ...controls, items: newItems });
  };

  const calculateTotalPrice = () => {
    let totalPrice = 0;
    items.forEach((item) => {
      const itemAmount = parseFloat(item.amount || 0);
      const itemTaxAmount = parseFloat(item.taxAmount || 0);
  
      totalPrice += toggleTaxInclusive ? itemAmount + itemTaxAmount : itemAmount;
    });
 
    return totalPrice?.toFixed(2);
  };

  
  
  const CalculateGrandTotal = () => {
    const total = controls.items.reduce((accumulator, item) => {
      const unitPrice = parseFloat(item.unitPrice);
      const quantity = parseFloat(item.quantity);
      const taxRate = parseFloat(item.taxRate);
      const itemSubTotal = unitPrice * quantity;
    
      const taxAmount = toggleTaxInclusive
        ? (itemSubTotal * taxRate) / 100
        : 0;
    
      const tp = itemSubTotal + taxAmount;
      return accumulator + tp;
    }, 0);

    const discountAmount = total * (parseFloat(controls.discount) / 100);
    const netAmount = total - discountAmount;
  
    const vatAmount = netAmount * (parseFloat(controls.vat) / 100 || 0);

    const subTotalItems = netAmount + vatAmount;
    return subTotalItems ? subTotalItems.toFixed(2) : 0;
  };
  

  const formik = useFormik({
    initialValues: {
      dueDate: controls.dueDate,
      currency: controls.currency,
      taxInclusive: controls.taxInclusive,
      items: items,
    },
    onSubmit: () => {
      if (editing) {
      } else {
      }
    },
  });

  const areItemsValid = () => {
    return items.every(
      (item) => item.itemName && item.quantity && item.unitPrice
    );
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="overflow-x-auto">
          <div className="flex justify-between px-7 items-center bg-[#FAFAFA] h-[40px] border-y border-[#CCCCCC]">
            <p style={{ color: "#7A7A7A" }} className="p-2 flex-1 text-center">
              Item
            </p>
            <p style={{ color: "#7A7A7A" }} className="p-2 flex-1 text-center">
              Description
            </p>
            <p style={{ color: "#7A7A7A" }} className="p-2 flex-1 text-center">
              Qty
            </p>
            <p
              style={{ color: "#7A7A7A" }}
              className="p-2 flex-1 text-center whitespace-nowrap"
            >
              Unit Price
            </p>
            {toggleTaxInclusive && (
              <p
                style={{ color: "#7A7A7A" }}
                className="p-2 flex-1 text-center whitespace-nowrap"
              >
                Tax Rate
              </p>
            )}
            <p style={{ color: "#7A7A7A" }} className="p-2 flex-1 text-center">
              Amount
            </p>
           
              <p
                style={{ color: "#7A7A7A" }}
                className="p-2 flex-1 text-center"
              >
                Tax Amount
              </p>
           
              <p
                style={{ color: "#7A7A7A" }}
                className="p-2 flex-1 text-center"
              >
                Total Price
              </p>
            <p
              style={{ color: "#7A7A7A" }}
              className="p-2 w-[50px] text-center"
            ></p>
          </div>
          {items?.map((item, index) => (
            <div key={index} className="flex justify-between items-center px-7">
              <div className="flex-1">
                <InputField
                  type="text"
                  id={`name-${index}`}
                  name="itemName"
                  value={item.itemName}
                  onChange={(event) => handleInputChange(index, event)}
                  className="w-full border border-gray-400 bg-gray-100 px-2 py-1 h-[60px] mb-0"
                />
              </div>
              <div className="flex-1">
                <InputField
                  type="text"
                  id={`description-${index}`}
                  name="description"
                  value={item.description}
                  onChange={(event) => handleInputChange(index, event)}
                  className="w-full border border-gray-400 bg-gray-100 px-2 py-1 h-[60px] mb-0"
                />
              </div>
              <div className="flex-1">
                <InputField
                  type="number"
                  id={`quantity-${index}`}
                  name="quantity"
                  value={item.quantity}
                  onChange={(event) => handleInputChange(index, event)}
                  className="w-full border border-gray-400 bg-gray-100 px-2 py-1 h-[60px] mb-0"
                />
              </div>
              <div className="flex-1">
                <InputField
                  type="number"
                  id={`price-${index}`}
                  name="unitPrice"
                  value={item.unitPrice}
                  onChange={(event) => handleInputChange(index, event)}
                  className="w-full border border-gray-400 bg-gray-100 px-2 py-1 h-[60px] mb-0"
                />
              </div>
              {toggleTaxInclusive && (
                <div className="flex-1">
                  <InputField
                    type="number"
                    id={`taxRate-${index}`}
                    name="taxRate"
                    value={item.taxRate}
                    onChange={(event) => handleInputChange(index, event)}
                    className="w-full border border-gray-400 bg-gray-100 px-2 py-1 h-[60px] mb-0"
                  />
                </div>
              )}

              <div className="flex-1">
                <InputField
                  type="number"
                  id={`amount-${index}`}
                  name="amount"
                  value={item.amount}
                  onChange={(event) => handleInputChange(index, event)}
                  className="w-full border border-gray-400 bg-gray-100 px-2 py-1 h-[60px] mb-0"
                />
              </div>
              
                <div className="flex-1">
                  <InputField
                    type="number"
                    id={`taxAmount-${index}`}
                    name="taxAmount"
                    value={item.taxAmount}
                    className="w-full border border-gray-400 bg-gray-100 px-2 py-1 h-[60px] mb-0"
                    readOnly
                  />
                </div>
              
                <div className="flex-1">
                  <InputField
                    type="number"
                    id={`totalPrice-${index}`}
                    name="totalPrice"
                    value={item.totalPrice}
                    readOnly
                    className="w-full border border-gray-400 bg-gray-100 px-2 py-1 h-[60px] mb-0"
                  />
                </div>
            
                <div className="w-[50px] flex items-center justify-center">
                  <img
                    style={{ width: "24px", cursor: "pointer" }}
                    src={DeleteIcon}
                    alt="Delete"
                    onClick={() => handleDeleteItem(item.id)}
                  />
                </div>
            </div>
          ))}
        </div>

        <div className="flex flex-wrap gap-2 px-[25px] justify-between my-[30px]">
          <div>
            <button
              className="bg-[#EEEEEE] px-[25px]  w-full rounded-[5px] rounded-[5px] h-[41px] py-[6px] text-xs md:text-sm font-medium"
              onClick={handleAddItem}
            >
              Add New Line
            </button>
          </div>

          <div className="mt-2">
            <div className="flex justify-between">
              <div className="flex pr-20">
                <div>
                  <p className="text-[#7A7A7A] font-medium text-[12px] py-[5px]">
                    Subtotal
                  </p>
                  <p className="text-[#7A7A7A] font-medium text-[12px] py-[5px]">
                    {areItemsValid() ? "Discount%" : "Discount"}
                  </p>
                  <p className="text-[#7A7A7A] font-medium text-[12px] py-[5px]">
                    {areItemsValid() ? "VAT%" : "VAT"}
                  </p>
                </div>

                <div>
                  <br />
                  {areItemsValid() ? (
                    <InputField
                      type="number"
                      placeholder="Enter Discount"
                      value={discount}
                      onChange={(e) =>
                        setControls({ ...controls, discount: e.target.value })
                      }
                      className="w-[120px] ml-2 border border-[#CCCCCC] px-2 py-3 h-[8px] text-[12px]"
                    />
                  ) : null}
                  {areItemsValid() ? (
                    <InputField
                      type="number"
                      placeholder="Enter VAT"
                      value={vat}
                      onChange={(e) =>
                        setControls({ ...controls, vat: e.target.value })
                      }
                      className="w-[120px] mt-2 ml-2 border border-[#CCCCCC] px-2 py-3 h-[8px] text-[12px]"
                    />
                  ) : null}
                </div>
              </div>

              <div>
                <p className="text-[#7A7A7A] font-medium text-[12px] py-[5px]">
                  {calculateTotalPrice()}
                </p>
                <p className="text-[#7A7A7A] font-medium text-[12px] py-[5px]">
                  {(calculateTotalPrice() * (vat / 100)).toFixed(2)}
                </p>
                <p className="text-[#7A7A7A] font-medium text-[12px] py-[5px]">
                  {(calculateTotalPrice() * (discount / 100)).toFixed(2)}
                </p>
              </div>
            </div>
            <br />
            <hr />
            <div className="flex justify-between mt-2">
              <p className="text-[#7A7A7A] font-bold text-[15px] py-[5px]">
                {areItemsValid() ? "Grand Total" : "Total"}
              </p>
              <p className="text-[#222222] font-medium text-[18px]">
                {!CalculateGrandTotal() ? 0 : CalculateGrandTotal()}
              </p>
            </div>
            <hr />
          </div>
        </div>
        <div className="border-t flex justify-end">
          <div className="flex flex-wrap mx-2 my-[30px] gap-2">
            <Button
              type="button"
              onClick={handleDiscard}
              className="!bg-[#edeaea] rounded-[5px] h-[41px] px-[16px] py-[6px] text-xs !text-[black] md:text-sm font-medium shadow-1px border !border-[#CCCCCC]"
            >
              {editing ? "Cancel" : "Discard"}
            </Button>
            {/* <Button
              type="button"
              className={`!bg-[white] rounded-[5px] h-[41px] px-[16px] py-[6px] text-xs md:text-sm font-medium shadow-1px border !border-[#F0870F] !text-[#F0870F] hover:bg-[#F0870F] hover:text-white flex items-center`}
            >
              {isLoading ? <Spinner /> : "Save"}
            </Button> */}

            <Button
              type="submit"
              onClick={createInvoiceSupplier}
              disabled={!areItemsValid() || isLoading}
              className={`rounded-[5px] h-[41px] px-[16px] py-[6px] text-xs md:text-sm font-medium shadow-1px border 
    ${
      items.length === 0 ||
      items.some((item) => !item.itemName || !item.quantity || !item.unitPrice)
        ? "!bg-[#edeaea] !text-black !border-[#CCCCCC]"
        : "!bg-[#07593D] !text-white !border-[#e4dbdb] hover:bg-[#07593D]"
    } flex items-center`}
            >
              {isLoading ? <Spinner /> : editing ? "Edit" : "Create"}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default NewInvoiceTable;

