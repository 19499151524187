import { PAIconArrowLeftMini, PAIconEditLight } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { useNavigate } from "react-router-dom";

function InvoicingWorkflow() {
  const navigate = useNavigate();
  const data = [
    { id: 1, name: "John Doe", email: "john@example.com", role: "Admin" },
    { id: 2, name: "Jane Smith", email: "jane@example.com", role: "User" },
    { id: 3, name: "Mark Johnson", email: "mark@example.com", role: "Editor" },
  ];
  return (
    <DashboardLayout
      section="Settings"
      subHeader="Manage your account all in one place"
    >
      <div className="bg-white min-h-full flex flex-col gap-11 rounded-lg py-8 px-7">
        <header className="flex flex-col gap-4">
          <span
            onClick={() => navigate(-1)}
            className="flex gap-1 items-center cursor-pointer w-fit"
          >
            <PAIconArrowLeftMini />
            <span className="text-[#7A7A7A]">back</span>
          </span>

          <div className="flex flex-col">
            <span className=" font-bold text-[18px]">Invoicing</span>

            <span className="text-sm font-medium text-[#959595]">
              Workflow approval for invoicing
            </span>
          </div>
        </header>

        <main className="flex flex-col gap-3">
          <section className="w-full flex justify-end">
            <span className="text-sm flex gap-2 cursor-pointer text-secondaryColor items-center">
              <PAIconEditLight /> Edit Process
            </span>
          </section>

          <section>
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white rounded-lg">
                <thead>
                  <tr className="border-t border-t-[#eee] border-b border-b-[#eee]">
                    <th className="text-xs py-2 px-4 bg-[#F9F9F9] text-[#7A7A7A] font-semibold text-left">
                      PROCESS
                    </th>
                    <th className="text-xs py-2 px-4 bg-[#F9F9F9] text-[#7A7A7A] font-semibold text-left">
                      STATUS
                    </th>
                    <th className="text-xs  py-2 px-4  bg-[#F9F9F9] text-[#7A7A7A] font-semibold text-left">
                      APPROVAL LEVELS
                    </th>
                  </tr>
                </thead>
                <tbody className="text-[#7A7A7A] text-sm">
                  {data.map((item) => (
                    <tr key={item.id} className="hover:bg-gray-100">
                      <td className="py-2 px-4 text-gray-700">
                        Invoice Approval
                      </td>
                      <td className="py-2 px-4 text-gray-700">Off</td>
                      <td className="py-2 px-4 text-gray-700">{item.email}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </section>
        </main>
      </div>
    </DashboardLayout>
  );
}
export default InvoicingWorkflow;
