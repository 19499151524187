import { motion } from "framer-motion";

function SelectedRequests({
  amountText = "Payable Amount",
  handleMakePayment,
  player = "Supplier",
  subTitle = "Details on the next Direct funding Request",
}) {
  const animationVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 0.5 },
  };

  return (
    <motion.div
      className="w-full lg:w-1/2 px-5 flex flex-col gap-5 bg-white rounded py-3"
      initial="initial"
      animate="animate"
      exit="exit"
      variants={animationVariants}
    >
      <header className="flex gap-6 justify-between items-center">
        <div className="flex flex-col gap-1">
          <span className="text-[#222] text-[17px]">Selected Requests</span>

          <span className="text-xs font-medium text-[#959595]">{subTitle}</span>
        </div>

        <span>
          <span
            onClick={() => handleMakePayment()}
            className="underline text-sm cursor-pointer text-[#222]"
          >
            View details
          </span>
        </span>
      </header>

      <main className="flex flex-col gap-5">
        <div className="flex justify-between items-center">
          <span className="text-sm text-[#7A7A7A] font-medium">
            Total Invoices
          </span>

          <span className="text-primaryColor font-medium">4</span>
        </div>

        <div className="flex justify-between items-center">
          <span className="text-sm text-[#7A7A7A] font-medium">
            Name of {player}{" "}
          </span>

          <span className="text-[#222] font-medium">
            Adewale Favour, Eloh Ekwugha, Kanu Kennedy...{" "}
          </span>
        </div>

        <div className="flex justify-between items-center">
          <span className="text-sm text-[#7A7A7A] font-medium">
            {amountText}
          </span>

          <span className="text-[#222] font-medium">
            <span className="text-xs font-light">NGN </span> 850,000
          </span>
        </div>
      </main>
    </motion.div>
  );
}

export default SelectedRequests;
