import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
// import InvoiceDetails from "shared/InvoiceDetails";
import LoadingScreen from "shared/LoadingScreen";
import InvoiceDetails from "./invoice";
import { allFunderPfpInvoices } from "appstate/funder/funderSlice";

const PendingDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  // const { invoiceIdData, isLoading } = useSelector((state) => state?.invoice);
  const { allPfpInvoicesData, pfpId, isLoading } = useSelector(
    (state) => state?.funder
  );

  useEffect(() => {
    dispatch(allFunderPfpInvoices({ pfpId, offerId: id }));
  }, [dispatch, pfpId, id]);

  const invoiceIdData = allPfpInvoicesData;

  if (isLoading) {
    return <LoadingScreen />;
  }

  // Calculate the payment based on the due date and created date
  const calculatePayment = (dueDate, createdDate) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const dueDateObj = new Date(dueDate);
    const createdDateObj = new Date(createdDate?.split(" ")[0]);
    const diffDays = Math.round(
      Math.abs((dueDateObj - createdDateObj) / oneDay)
    );
    return `${diffDays} Days`;
  };

  // FORMAT AMOUNT
  const formatAmount = (amount) => {
    if (typeof amount !== "number") return "0.00";
    return amount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const data = {
    // ids: invoiceIdData?.id,
    invoiceNumber: `INV-${invoiceIdData?.data?.invoiceNo}` || "",
    referenceNumber: invoiceIdData?.data?.invoiceReference || "",
    createdDate: invoiceIdData?.data?.createdDate?.slice(0, 10) || "",
    status: invoiceIdData?.data?.status,
    invoiceDueDate: invoiceIdData?.data?.dueDate || "",
    invoiceAmount: invoiceIdData?.data?.invoiceAmount,
    offerAmount: invoiceIdData?.data?.offerAmount,
    funderShare: invoiceIdData?.data?.funderShare,
    fundableAmount: formatAmount(invoiceIdData?.data?.fundableAmount),
    paymentDate: invoiceIdData?.data?.paymentDate,
    paymentDuration: invoiceIdData?.data?.paymentDuration,
    reason: invoiceIdData?.data?.reason,
    supplierCompanyName: invoiceIdData?.data?.supplierCompanyName,
    buyerDetails: invoiceIdData?.data?.buyerCompanyName,
    paymentTerms: invoiceIdData?.data?.paymentTerms,
    payment: calculatePayment(
      invoiceIdData?.data?.dueDate,
      invoiceIdData?.data?.createdDate
    ),
    currency: invoiceIdData?.data?.currency || "",
    accountNumber:
      invoiceIdData?.data?.virtualAccount?.accountNumber || "",
    accountName: invoiceIdData?.data?.virtualAccount?.accountName || "",
    vat: invoiceIdData?.data?.vat || "",
    discount: invoiceIdData?.data?.discount || "",
    discountRate: invoiceIdData?.data?.discountRate || "",
    vatAmount: formatAmount(invoiceIdData?.data?.vatAmount || ""),
    discountAmount: formatAmount(invoiceIdData?.data?.discountAmount || ""),
    // discountAmount: invoiceIdData?.data?.discountAmount || "",
    grandTotal: formatAmount(invoiceIdData?.data?.grandTotal || ""),
    subTotal: formatAmount(invoiceIdData?.data?.subTotal || ""),
    numberOfLineItems: invoiceIdData?.data?.lineItems?.length || 0,
    lineItems:
      invoiceIdData?.data?.lineItems?.map((item) => ({
        // itemNumber: item?.itemNumber || "",
        item: item?.itemName ? item?.itemName : "",
        description: item.description || "",
        unitPrice: formatAmount(item.unitPrice || ""),
        quantity: item.quantity || 0,
        amount: formatAmount(item.amount || ""),
        taxRate: item.taxRate || "",
        taxAmount: formatAmount(item.taxAmount || ""),
        totalPrice: formatAmount(item.totalPrice || ""),
      })) || [],
  };

  return (
    <InvoiceDetails data={data} showSendReminderButton={false} />
  );
};

export default PendingDetails;
