import React, { useEffect, useState } from "react";
import RecentPaymentTable from "./Table";
import {
  allFunderOverviewAwaitingSummary,
  funderOverview,
} from "appstate/collections/collectionSlice";
import { useDispatch, useSelector } from "react-redux";

const Overview = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(allFunderOverviewAwaitingSummary());
  }, [dispatch]);

  useEffect(() => {
    dispatch(funderOverview());
  }, [dispatch]);

  const { allFunderOverviewAwaitingSummaryData, funderOverviewData } =
    useSelector((state) => state?.collection);

  const data = [
    {
      title: "Direct Funding",
      amount: funderOverviewData?.data?.directFundingTotal?.toLocaleString(),
      description: "Amount awaiting collection",
      badgeNumber: funderOverviewData?.data?.directFundingCount,
      badgeColor: "#F0800024",
      textColor: "#11192A",
    },
    {
      title: "PFP Campaign",
      amount: funderOverviewData?.data?.pfpCampaignTotal?.toLocaleString(),
      description: "Amount awaiting collection",
      badgeNumber: funderOverviewData?.data?.pfpCampaignCount,
      badgeColor: "#F0800024",
      textColor: "#3E0445",
    },
    {
      title: "Open Market",
      amount: funderOverviewData?.data?.openMarketTotal?.toLocaleString(),
      description: "Amount awaiting collection",
      badgeNumber: funderOverviewData?.data?.openMarketCount,
      badgeColor: "#F0800024",
      textColor: "#F08000",
    },
    {
      title: "Closed Market",
      amount: funderOverviewData?.data?.closedMarketTotal,
      description: "Amount awaiting collection",
      badgeNumber: funderOverviewData?.data?.closedMarketCount,
      badgeColor: "#F0800024",
      textColor: "#318F29",
    },
  ];

  // const [, setIsOpen] = useState(false);
  const [recievablesOpen, setRecievablesOpen] = useState(false);
  const [recievablesTwoOpen, setRecievablesTwoOpen] = useState(false);
  // const [, setSelectedOption] = useState("all");
  const [selectedRecievables, setSelectedReceivables] = useState("all");
  const [selectedRecievablesTwo, setSelectedReceivablesTwo] = useState("all");

  const options = [
    { value: "all", label: "All" },
    { value: "thisWeek", label: "This Week" },
    { value: "thisMonth", label: "This Month" },
    { value: "thisQuarter", label: "This Quarter" },
    { value: "thisHalfYear", label: "This Half Year" },
    { value: "thisYear", label: "This Year" },
  ];

  const optionsTwo = [
    { value: "all", label: "All" },
    { value: "thisWeek", label: "This Week" },
    { value: "thisMonth", label: "This Month" },
    { value: "thisQuarter", label: "This Quarter" },
    { value: "thisHalfYear", label: "This Half Year" },
    { value: "thisYear", label: "This Year" },
  ];

  // const handleSelect = (option) => {
  //   setSelectedOption(option.value);
  //   setIsOpen(false);
  // };

  const handleReceivableSelect = (option) => {
    setSelectedReceivables(option.value);
    setRecievablesOpen(false);
  };
  const handleReceivableSelectTwo = (option) => {
    setSelectedReceivablesTwo(option.value);
    setRecievablesTwoOpen(false);
  };

  return (
    <div className="w-full">
      <div className="grid lg:grid-cols-[40%,60%] grid-cols-1 gap-5 mb-5">
        <div className=" flex flex-col w-full h-full  gap-10">
          {" "}
          <section className="bg-[#FAFAFA] shadow-md rounded-xl ">
            <header className="px-5 lg:px-7 py-4 bg-white shadow-lg flex items-center justify-between rounded-t-xl">
              <div className="text-primaryColor  text-lg font-semibold">
                AWAITING COLLECTION
              </div>
              <div className="relative">
                <button
                  onClick={() => setRecievablesOpen(!recievablesOpen)}
                  className="bg-primaryColor text-white rounded flex items-center justify-between px-4 py-2 w-30 focus:outline-none"
                >
                  {
                    options.find((opt) => opt.value === selectedRecievables)
                      ?.label
                  }
                  <svg
                    className={`w-4 h-4 ml-2 transition-transform ${
                      recievablesOpen ? "rotate-180" : "rotate-0"
                    }`}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.707a1 1 0 011.414 0L10 11.293l3.293-3.586a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>

                {/* Dropdown Menu */}
                {recievablesOpen && (
                  <ul className="absolute mt-2 w-40 bg-white rounded-2xl brder border-gray300 shadow-md z-10">
                    {options.map((option) => (
                      <li
                        key={option.value}
                        onClick={() => handleReceivableSelect(option)}
                        className={`px-4 py-2 flex items-center cursor-pointer hover:bg-primaryColor hover:text-white ${
                          selectedRecievables === option.value
                            ? "bgprimaryColor"
                            : ""
                        }`}
                      >
                        {/* Radio Indicator */}
                        <span
                          className={`w-4 h-4 mr-3 rounded-full border-2 ${
                            selectedRecievables === option.value
                              ? "border-secondaryColor bg-secondaryColor"
                              : "border-gray300"
                          }`}
                        ></span>
                        {option.label}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </header>

            <div className="px-7 py-5">
              <div className="bg-primaryColor py-3 px-3.5 rounded-2xl  flex flex-col justify-center items-center text-white min-h-[170px]">
                <div className="text-white text-[48px] font-bold ">
                  {allFunderOverviewAwaitingSummaryData?.totalTransactions ||
                    "0"}
                </div>
                <div className="text-xl font-light">Total Transactions</div>
              </div>
            </div>

            <div className="text-center my-7">
              <p className="text-primaryColor text-[33.091px] font-bold leading-normal">
                {allFunderOverviewAwaitingSummaryData?.totalFundsAwaitingCollections?.toLocaleString() ||
                  "0.00"}
              </p>
              <p className="text-[#565656] text-[20px] font-normal leading-normal">
                Total Amount Awaiting
              </p>
            </div>
          </section>
          {/* yellow box down */}
          <div className="   bg-[#f08000] rounded-[18.53px]   lg:px-5 flex-1 justify-center items-center flex min-h-[100px] ">
            <div>
              <div className="w-[157px] h-[59px] px-2.5 flex-col justify-start items-center gap-3 inline-flex">
                <div className="text-center text-white text-2xl font-bold ">
                  {allFunderOverviewAwaitingSummaryData?.overdueInvoicesCount ||
                    "0"}
                </div>
                <div className="text-center text-white text-sm font-normal ">
                  Overdue Invoices
                </div>
              </div>
            </div>

            <div className="w-[157px] h-[59px] px-2.5 flex-col justify-start items-center gap-3 inline-flex border-l-[1px] border-white">
              <div className="text-center text-white text-2xl font-bold ">
                {allFunderOverviewAwaitingSummaryData?.overdueAmount?.toLocaleString() ||
                  "0.00"}
              </div>
              <div className="text-center text-white text-sm font-normal ">
                Overdue Amount
              </div>
            </div>
          </div>
        </div>{" "}
        {/* Funds Section */}
        <section className="bg-[#FAFAFA] shadow-md rounded-xl">
          <div className=" px-5">
            <div class="   bg-primaryColor rounded-[18.53px]   lg:px-5 flex-1 justify-between items-center flex min-h-[100px] mt-5 ">
              <div className="w-[35%]">
                <div class=" h-[59px] px-2.5 flex justify-start items-center gap-3 inline-fle">
                  <div class="w-[129px] text-white text-base font-normal  text-center">
                    Total Amount Collected
                  </div>
                </div>
              </div>
              {/*  */}

              <div className="   flex-1 flex  justify-between items-center flex-wrap">
                {" "}
                <div class=" h-[59px] px-2.5 flex-col justify-center items-center gap-3 inline-flex border-l-[1px] border-white">
                  <div class="text-white text-xl font-semibold ml-6 ">
                    {funderOverviewData?.data?.totalAmountCollected?.toLocaleString() ||
                      "0.00"}
                  </div>
                </div>
                <div className="relative">
                  <button
                    onClick={() => setRecievablesTwoOpen(!recievablesTwoOpen)}
                    className="bg-white  text-black rounded flex items-center justify-between px-4 py-2 w-30 focus:outline-none"
                  >
                    {
                      options.find(
                        (opt) => opt.value === selectedRecievablesTwo
                      )?.label
                    }
                    <svg
                      className={`w-4 h-4 ml-2 transition-transform ${
                        recievablesTwoOpen ? "rotate-180" : "rotate-0"
                      }`}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.707a1 1 0 011.414 0L10 11.293l3.293-3.586a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>

                  {/* Dropdown Menu */}
                  {recievablesTwoOpen && (
                    <ul className="absolute mt-2 w-40 bg-white rounded-2xl brder border-gray300 shadow-md z-10">
                      {optionsTwo.map((option) => (
                        <li
                          key={option.value}
                          onClick={() => handleReceivableSelectTwo(option)}
                          className={`px-4 py-2 flex items-center cursor-pointer hover:bg-primaryColor hover:text-white ${
                            selectedRecievablesTwo === option.value
                              ? "bgprimaryColor"
                              : ""
                          }`}
                        >
                          {/* Radio Indicator */}
                          <span
                            className={`w-4 h-4 mr-3 rounded-full border-2 ${
                              selectedRecievablesTwo === option.value
                                ? "border-secondaryColor bg-secondaryColor"
                                : "border-gray300"
                            }`}
                          ></span>
                          {option.label}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="px-5 lg:px-7 py-4 grid grid-cols-1 md:grid-cols-2 gap-3">
            {/* Total Requests Card */}

            {/* Total Funds Card */}
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 px-5 lg:px-7 py-4 gap-3 mb-3">
            {data?.map((item, index) => (
              <div key={index} className="bg-white shadow-md rounded-[10px]">
                <div className="flex justify-between p-7">
                  <div>
                    <p className="text-[10px] font-light leading-normal">
                      {item.title}
                    </p>
                    <p
                      className="text-xl font-light leading-normal my-2"
                      style={{ color: item.textColor }}
                    >
                      {item.amount}
                    </p>
                    <p className="text-[#565656] text-[12px] font-normal leading-normal">
                      {item.description}
                    </p>
                  </div>
                  <div
                    className="w-[34px] h-[32px] shrink-0 rounded-lg flex items-center justify-center text-secondaryColor"
                    style={{ backgroundColor: item.badgeColor }}
                  >
                    {item.badgeNumber}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
        {/* Receivables Section */}
      </div>

      <div>
        <RecentPaymentTable />
      </div>
    </div>
  );
};

export default Overview;
