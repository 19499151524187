import { PAIconAddGreen, PAIconDeleteCute } from "assets/images/svgs";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import Switch from "../../NotificationSetting/components/Switch";
import {
  allMembers,
  allRolesWorkflow,
} from "appstate/roleManager/roleManagerSlice";
import { formatCapitaliseString } from "utilities/helper/formatCapitaliseString";

function WorkflowActionEdit({
  title,
  isToggledStatus,
  setIsToggleStatus,
  mappingArr,
  setMappingArr,
  setSelectedRoleName,
  dropdownRefs,
  handleFinalApprovalChange,
  addApproval,
  removeApproval,
  handleApprovalSubmit,
  handleSelectUser,
  player,
}) {
  const dispatch = useDispatch();
  const { allRolesWorkflowData } = useSelector((state) => state?.roleManager);
  const { getUsersByRoleData } = useSelector((state) => state?.workflow);
  useEffect(() => {
    dispatch(allRolesWorkflow());
    dispatch(allMembers(player));
  }, [dispatch, player]);

  return (
    <div className="flex w-full flex-col lg:flex-row justify-between">
      <div className="flex mt-6 py-2 px-4 w-[25%]">
        <span className="font-medium text-base text-primary">{title}</span>
      </div>

      <div className="mt-6 py-2 px-4 w-[25%]">
        <Switch
          isToggled={isToggledStatus}
          onToggle={() => setIsToggleStatus(!isToggledStatus)}
        />
      </div>
      <div className="py-2 px-4 w-1/2">
        {mappingArr?.length > 0 &&
          mappingArr?.map((approval, index) => (
            <div
              key={approval.role}
              className="flex items-center justify-between mt-6 mb-3 relative"
            >
              <select
                className="text-[13px] placeholder-primary/80 outline-none px-[15px] bg-transparent h-[37px] flex items-center rounded border border-[#E5E5E5] w-[220px] mr-[19px]"
                value={approval.role || ""}
                onChange={(e) => {
                  const updatedRole = e.target.value;
                  setMappingArr((prev) =>
                    prev.map((item, i) =>
                      i === index
                        ? {
                            ...item,
                            role: updatedRole, // Update only the selected index
                            userSearch: "",
                            userId: "",
                            email: "",
                          }
                        : item
                    )
                  );
                  setSelectedRoleName(
                    updatedRole
                    // e.target.selectedOptions[0]?.dataset.keycloakRoleName || ""
                  );
                }}
              >
                <option value="" disabled>
                  Select Role
                </option>
                {allRolesWorkflowData?.data?.map((role) => (
                  <option
                    key={role.keycloakRoleName}
                    value={role.roleName}
                    selected={role.roleName === approval.role}
                    data-keycloak-role-name={role.keycloakRoleName}
                  >
                    {formatCapitaliseString(role.roleName)}
                  </option>
                ))}
              </select>

              <div
                className="relative"
                ref={(el) => (dropdownRefs.current[index] = el)}
              >
                <input
                  type="text"
                  className="text-[13px] placeholder-primary/80 outline-none px-[15px] bg-transparent h-[37px] flex items-center rounded border border-[#E5E5E5] w-[220px] mr-[19px]"
                  placeholder={approval?.email || "Search User"}
                  value={approval?.email || approval.userSearch}
                  onFocus={() =>
                    setMappingArr((prev) =>
                      prev.map((item, i) =>
                        i === index
                          ? { ...item, dropdownOpen: true }
                          : { ...item, dropdownOpen: false }
                      )
                    )
                  }
                  onChange={(e) =>
                    setMappingArr((prev) =>
                      prev.map((item, i) =>
                        i === index
                          ? {
                              ...item,
                              userSearch: e.target.value,
                            }
                          : item
                      )
                    )
                  }
                />
                {approval.dropdownOpen && (
                  <div className="absolute bg-white rounded-md shadow-md z-10 w-[220px]">
                    {getUsersByRoleData?.data?.map((user) => (
                      <div
                        key={user.email}
                        className="px-4 py-2 cursor-pointer hover:bg-primary/10"
                        onClick={() => handleSelectUser(user.email, index)}
                      >
                        {user.email}
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div
                className="cursor-pointer flex items-center justify-center bg-[#F7F9FB] border border-[#E5E5E5] w-[36px] h-[36px] rounded"
                onClick={() => removeApproval(index)}
              >
                <PAIconDeleteCute />
              </div>

              <label className="flex gap-2.5 items-center">
                <input
                  type="checkbox"
                  name="final-approver"
                  checked={approval.finalApproval}
                  onChange={(e) =>
                    handleFinalApprovalChange(index, e.target.checked)
                  }
                />
              </label>
            </div>
          ))}

        {mappingArr?.length < 1 && (
          <div className="flex shadow-sm font-bold justify-center p-5">
            No level selected, kindly add a level below!
          </div>
        )}

        <div className="flex justify-end">
          <button
            onClick={addApproval}
            className="border p-2 rounded items-center my-2 flex gap-1.5  text-primaryColor cursor-pointer  border-primaryColor text-[10px]"
          >
            <PAIconAddGreen />
            <span> Add new level</span>
          </button>

          <motion.button
            onClick={() => handleApprovalSubmit()}
            whileTap={{ scale: 0.9 }}
            className={`flex gap-1 items-center text-xs justify-center  rounded  my-2 ml-3 text-[white]  bg-primaryColor px-6  p-2`}
          >
            Save
          </motion.button>
        </div>
      </div>
    </div>
  );
}
export default WorkflowActionEdit;
