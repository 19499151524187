import { PAIconAlert24 } from "assets/images/svgs";
import Button from "shared/Button";
import Spinner from "shared/Spinner/Spinner";

const AcceptModal = ({
  isOpen,
  onClose,
  confirm,
  isLoading,
  checkedAccounts,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="bg-[rgba(0,0,0,0.2)] w-[100%] h-[100vh] flex justify-center items-center absolute left-0 top-0">
      <div className="bg-white p-5 rounded-md shadow-md flex flex-col items-center px-10">
        <PAIconAlert24 />
        <p className="text-[#979797] text-center font-sans text-base font-normal leading-normal tracking-wide mb-3 my-5">
          Would you like to proceed to use this account{" "}<br />
          <span className="text-[#07593D] font-bold">
            {checkedAccounts?.accountNumber}
          </span>{" "}
          to approve this Early Payment request?
        </p>
        <div className="flex justify-center mt-3">
          <Button
            neutral
            className="flex justify-center items-center h-[43px] mr-5 text-base !text-[#07593D] border !border-[#07593D rounded-md bg-white w-[77px]"
            onClick={confirm}
            disabled={isLoading}
          >
            {isLoading ? <Spinner color /> : "Yes"}
          </Button>
          <button
            className="px-5 py-2 bg-red-500 border border-red text-red rounded-md"
            onClick={onClose}
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default AcceptModal;
