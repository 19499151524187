import DashboardLayout from "layout/dashboardlayout";
import React from "react";
import Details from "./details";
// import PartialPaymentInvoiceDetails from "./partialPaymentInvoiceDetails";

const PartialPaymentDetails = () => {
  return (
    <DashboardLayout
    section="Invoice"
    subHeader="Here is an overview of your invoices"
    >
      <Details />
      {/* <PartialPaymentInvoiceDetails /> */}
    </DashboardLayout>
  );
};

export default PartialPaymentDetails;
