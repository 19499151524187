import DashboardLayout from "layout/dashboardlayout";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DisbursementFunderDashboard from "./funder";
import DisbursementBuyerDashboard from "./buyer";
import { allVirtualAccounts } from "appstate/wallet/walletSlice";
//in progress

const DisbursementDashboard = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(allVirtualAccounts());
  }, [dispatch]);
  const { userId } = useSelector((state) => state?.auth);
  const roleIndex = userId?.realm_access?.roles?.findIndex((arr) => {
    return (
      arr === "SUPPLIER" ||
      arr === "BUYER" ||
      arr === "FUNDER" ||
      arr === "ADMIN"
    );
  });
  const userRole = userId?.realm_access?.roles[roleIndex]?.toLowerCase();

  const disbursementPlayer =
    userRole === "funder" ? (
      <DisbursementFunderDashboard />
    ) : userRole === "buyer" ? (
      <DisbursementBuyerDashboard />
    ) : (
      <></>
    );
  return (
    <DashboardLayout
      section="Disbursement"
      subHeader="Track invoices due for payment disbursement"
    >
      {disbursementPlayer}
    </DashboardLayout>
  );
};

export default DisbursementDashboard;
