import { PAIconInfo } from "assets/images/svgs";
import React, { useState } from "react";
import toast from "react-hot-toast";
import Button from "shared/Button";
import InputField from "shared/InputField";
// import ConfirmModal from "./ConfirmModal";
import { useSelector } from "react-redux";
import DiscountRateModal from "./DiscountRateModal"; 
import ReceivingFundModal from "./ReceivingFundModal";
import VirtualAccountSelection from "./VirtualAccountSelection";
import ViewMore from "./ViewMore";
import TransferFundModal from "./TransferFund";
import PinModalPayment from "./PinModal";
import SuccessEarlyPayment from "./SuccessEarlyPayment";
import EarlyPaymentConfirmation from "./EarlyPaymentConfirmation";

const SingleRequestModalContent = ({ onClose, data }) => {
  // eslint-disable-next-line
  const { earlyPaymentLoading, approvedInvoiceId } = useSelector(
    (state) => state?.supplier
  );
  const [enableDiscount, setEnableDiscount] = useState(false);
  const [applied, setApplied] = useState(false);
  const [amount, setAmount] = useState("");
  const [discountedAmount, setDiscountedAmount] = useState("");
  const [open, setOpen] = useState(false);
  const [newPaymentDate, setNewPaymentDate] = useState("");
  const [description, setDescription] = useState("");
  const [showDiscountRateModal, setShowDiscountRateModal] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState("");
  const [showReceivingFundModal, setShowReceivingFundModal] = useState(false);
  const [showVirtualSelectionModal, setShowVirtualSelectionModal] =
    useState(false);
  const [showViewMore, setShowViewMore] = useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleViewMoreClick = () => {
    setShowViewMore(true);
  };

  const handleSetNewAmount = () => {
    setNewPaymentDate("");
    setDescription("");
    setApplied(false);
  };

  const handleCloseViewMore = () => {
    setShowViewMore(false);
  };

  const handleShowPin = () => {
    setShowTransferModal(false);
    setShowPin(true);
  };

  const showDiscountModal = () => {
    setShowDiscountRateModal(!showDiscountRateModal);
  };

  const handleAmountChange = (event) => {
    const value = event.target.value;
    setAmount(value);

    const parsedAmount = parseFloat(value);
    if (!isNaN(parsedAmount)) {
      const discountedValue = parsedAmount - 1.67;
      setDiscountedAmount(discountedValue.toFixed(2));
    } else {
      setDiscountedAmount("");
    }
  };

  const selectPercentage = (event) => {
    setSelectedRequest(event.target.value);
  };

  const handleShowReceivingFundModal = () => {
    setShowReceivingFundModal(true);
  };

  const handleShowVirtualAccountSelection = () => {
    setShowVirtualSelectionModal(true);
    setShowReceivingFundModal(false);
  };

  const handleCloseVirtualAccount = () => {
    setShowReceivingFundModal(false);
    setShowVirtualSelectionModal(false);
  };

  const handleChangeAccount = () => {
    setShowVirtualSelectionModal(true);
    setShowTransferModal(false);
  };

  const handleCloseTransferModal = () => {
    setShowTransferModal(false);
  };

  function handleSendRequest() {
    setOpen(false);
    setShowTransferModal(true);
    //  onClose();
  }

  const handleSuccess = () => {
    setShowConfirmModal(false);
    setShowSuccess(true);
  };

  const confirmPayment = () => {
    setShowConfirmModal(true);
    setShowPin(false);
  };

  const onClosePinModal = () => {
    setShowPin(false);
  };

  function handleOpenModal() {
    if (!description || !newPaymentDate) {
      toast.error("Description or new payment date are required.");
      return;
    }
    setOpen(true);
  }

  return (
    <>
      {(open || showConfirmModal) && (
        <div className="w-[900px] fixed inset-0 bg-black bg-opacity-50 z-10"></div>
      )}
      {open && (
        <div className="absolute mt-[20px] top-1/2 left-2/3 transform -translate-x-1/2 -translate-y-1/2 bg-white w-[614px] h-[254px] z-10 rounded-[10px] shadow-lg">
          <EarlyPaymentConfirmation
            hide={() => setOpen(false)}
            isLoading={earlyPaymentLoading}
            handleAssociate={handleSendRequest}
            earlyPayment={true}
            warning={
              <>
                Would you like to proceed to use this account{" "}
                <span className="text-primaryColor">7364738921</span> to bid on
                invoice?
              </>
            }
          />
        </div>
      )}

      {showConfirmModal && (
        <div className="absolute mt-[20px] top-1/2 left-2/3 transform -translate-x-1/2 -translate-y-1/2 bg-white w-[614px] h-[254px] z-10 rounded-[10px] shadow-lg">
          <EarlyPaymentConfirmation
            hide={() => setShowConfirmModal(false)}
            isLoading={earlyPaymentLoading}
            handleAssociate={handleSuccess}
            // earlyPayment={true}
            warning=" Would you like to proceed with sending the early payment request? "
          />
        </div>
      )}

      {showSuccess && (
        <SuccessEarlyPayment
          isOpen={showSuccess}
          onClose={() => setShowSuccess(false)}
          message="Early payment request successful"
        />
      )}

      {showDiscountRateModal && (
        <div className="w-full">
          <DiscountRateModal
            setApplied={setApplied}
            setShowDiscountRateModal={setShowDiscountRateModal}
          />
        </div>
      )}
      {showReceivingFundModal && (
        <div>
          <ReceivingFundModal
            handleCloseVirtualAccount={handleCloseVirtualAccount}
            handleShowVirtualAccountSelection={
              handleShowVirtualAccountSelection
            }
            setShowReceivingFundModal={setShowReceivingFundModal}
          />
        </div>
      )}
      {showTransferModal && (
        <div>
          <TransferFundModal
            handleCloseVirtualAccount={handleCloseVirtualAccount}
            handleShowVirtualAccountSelection={
              handleShowVirtualAccountSelection
            }
            setShowReceivingFundModal={setShowReceivingFundModal}
            handleChangeAccount={handleChangeAccount}
            handleCloseTransferModal={handleCloseTransferModal}
            handleShowPin={handleShowPin}
          />
        </div>
      )}
      {showVirtualSelectionModal && (
        <div>
          <VirtualAccountSelection
            handleCloseVirtualAccount={handleCloseVirtualAccount}
            setOpen={setOpen}
            onClose={onClose}
          />
        </div>
      )}
      {showPin && (
        <PinModalPayment
          confirmPayment={confirmPayment}
          isOpen={showPin}
          onCancel={onClosePinModal}
        />
      )}

      {showViewMore && (
        <div style={{ width: "100%" }}>
          {" "}
          <ViewMore onClose={handleCloseViewMore} />
        </div>
      )}
      <div className="p-[39px]">
        <div className="flex justify-between">
          <div className="flex-1">
            <div className="flex items-center gap-x-[23px]">
              <h1 className="text-[#222222] text-[20px] font-semibold leading-[23.87px] font-sfpro">
                Invoice | INV - 2091
              </h1>
              <p className="text-textColor text-sm">Reference No: 12345666AH</p>
            </div>
            <div>
              <span className="text-textColor text-xs mr-[10px]">
                Created Date: 23 June, 2024
              </span>

              <span className="text-textColor text-xs ml-[10px]">
                Payment Due Date: 31-Aug-2024
              </span>
            </div>
          </div>
          {!applied && (
            <Button
              neutral
              type="Button"
              disabled={!amount}
              onClick={handleOpenModal}
              className="border flex justify-center items-center border-[#AFAFAF] rounded-[5px] text-xs text-[#AFAFAF] w-[135px]  h-[30px]"
            >
              Send Request
            </Button>
          )}
          {applied && (
            <button
              onClick={handleShowReceivingFundModal}
              style={{ backgroundColor: "#F08000", color: "white" }}
              className="border flex justify-center items-center rounded-[5px] text-xs  w-[155px]  h-[30px]"
            >
              Add Account
            </button>
          )}
        </div>
        <div className="flex justify-between mt-[25px]">
          <div>
            <p className="text-textColor font-extrabold text-base">
              Invoice Amount
            </p>
            <h1 className="text-primaryColor text-2xl font-extrabold">
              NGN 2,500,000
            </h1>
            <h1 className="text-textColor text-sm font-extrabold mt-[19px]">
              Early Payment Request
            </h1>
          </div>
          <div>
            <h4 style={{ fontSize: 13 }}>Request Percentage</h4>
            {applied ? (
              <span>{selectedRequest}</span>
            ) : (
              <select
                value={selectedRequest}
                onChange={selectPercentage}
                className="border rounded-md px-2 py-1"
              >
                <option value="">--</option>
                <option value="50%">50%</option>
              </select>
            )}
          </div>
        </div>
        <div className="mt-[20px]">
          <div className="flex justify-between items-center">
            <InputField
              label="New Payment Date:"
              type="date"
              value={newPaymentDate}
              onChange={(e) => setNewPaymentDate(e.target.value)}
              className=" w-[369px] h-[39px] rounded-[5px] outline-none !border border-[#AFAFAF] px-[5px]"
            />
            <div style={{ marginTop: 20 }}>
              {!applied ? (
                <>
                  <div style={{ marginTop: enableDiscount ? 40 : 0 }}>
                    <InputField
                      label="Request Amount"
                      placeholder="₦"
                      type="number"
                      value={amount}
                      onChange={handleAmountChange}
                      className="w-[369px] h-[39px] rounded-[5px] !border border-[#AFAFAF] px-[5px] outline-none [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                    />
                  </div>

                  {!enableDiscount && (
                    <Button
                      onClick={() => setEnableDiscount(true)}
                      typeof="Button"
                      disabled={!amount}
                      neutral
                      className="!text-secondaryColor text-sm mt-[5px] float-right"
                    >
                      Check for available discount
                    </Button>
                  )}
                  {enableDiscount && (
                    <div className="mt-[10px] flex justify-between">
                      <div>
                        <p className="text-textColor text-xs font-medium border-b border-[#AFAFAF] w-[84px]">
                          Discount:{" "}
                          <span className="text-[#17191C] text-xs">7.67%</span>
                        </p>
                        <div
                          onClick={handleViewMoreClick}
                          className="flex items-center mt-[2px]"
                        >
                          <PAIconInfo />
                          <p
                            style={{ cursor: "pointer" }}
                            className="text-[#F08000] text-[10px] font-medium"
                          >
                            Tap to view more
                          </p>
                        </div>
                      </div>
                      <div>
                        <p className="text-textColor text-xs">
                          Receivable Amount:{" "}
                          <span className="text-primaryColor text-xs font-extrabold border-b border-[#AFAFAF] pb-[2px]">
                            {discountedAmount && "N" + discountedAmount}
                          </span>
                        </p>
                        <div className="flex justify-end mt-[5px]">
                          <Button
                            neutral
                            // onClick={() => setApplied(true)}
                            onClick={showDiscountModal}
                            disabled={!amount}
                            type="Button"
                            className="w-[87px] h-[24px] text-white bg-secondaryColor text-xs rounded-[5px]"
                          >
                            Apply
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className="flex justify-end">
                  <div>
                    <Button
                      neutral
                      // onClick={() => setApplied(true)}
                      onClick={handleSetNewAmount}
                      disabled={!amount}
                      type="Button"
                      className="w-[107px] h-[24px] text-white bg-primaryColor text-xs rounded-[5px]"
                    >
                      Set new amount
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col">
            <label className="text-textColor mb-[10px] text-xs" htmlFor="">
              Description
            </label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="border outline-none border-[#AFAFAF] w-full rounded-[5px] h-[67px] p-[10px]"
            ></textarea>
          </div>

          <div className="mt-[28px] border-b-4 border-[#F6F5FA] pb-[12px]">
            <h1 className="text-textColor text-lg font-bold">
              Supplier/buyer details
            </h1>
          </div>
          <div className="flex justify-between mt-[16px]">
            <div>
              <p className="text-textColor text-sm italic font-extrabold">
                Supplier
              </p>
              <p className="text-lightGray text-xs">Abubakar Dev</p>
            </div>
            <div>
              <p className="text-textColor text-sm italic font-extrabold">
                Buyer
              </p>
              <p className="text-lightGray text-xs">{data?.buyerCompanyName}</p>
            </div>
          </div>
          <div className="mt-[32px] border-b border-[#F6F5FA] pb-[12px]">
            <h1 className="text-[#11192A] text-sm font-bold">Line Items</h1>
          </div>
          <div className="mt-[28px] border-b-4 border-[#F6F5FA] pb-[12px]"></div>
          <div className=" w-full overflow-x-auto">
            <table className=" w-full overflow-x-auto">
              <thead className="bg-subtleGray w-">
                <tr className=" bg-unusualGray p-2.5 ">
                  <td className="text-[#11192A] p-2.5 text-xs font-bold">
                    Item Number
                  </td>
                  <td className=" p-2.5 text-xs font-bold">
                    Product/Service Description
                  </td>
                  <td className=" p-2.5 text-xs font-bold">Quantity</td>
                  <td className=" p-2.5 text-xs font-bold">Unit Price</td>
                  <td className=" p-2.5 text-xs font-bold">Total Amount</td>
                </tr>
              </thead>
              {data?.items?.map((item, index) => (
                <tbody key={index}>
                  <tr className=" py-[3px] px-1.5">
                    <td className=" text-xs text-[#11192A] p-2.5">INV-2002</td>
                    <td className=" text-xs text-[#11192A] p-2.5">
                      {item?.description}
                    </td>
                    <td className=" text-xs text-[#11192A] p-2.5">
                      {item?.quantity}
                    </td>
                    <td className=" text-xs text-[#11192A] p-2.5">
                      {item?.unitPrice}
                    </td>
                    <td className=" text-xs text-[#11192A] p-2.5">
                      {item?.amount}
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleRequestModalContent;