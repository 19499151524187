import React, { useEffect, useState } from "react";
import {
  PAIconFilter,
  PAIconOutlineSearch,
//   PAIconDropdown,
} from "assets/images/svgs";
import { useDispatch } from "react-redux";
import { allDirectFunding } from "appstate/invoice/invoiceSlice";
import Table from "layout/table/Table";
import Loading from "shared/Loading";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";
import { useNavigate } from "react-router-dom";

const ReimbursementTransactionHistory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchQuery] = useState("");
  const [fundingType, setFundingType] = useState("directFunding");
  const [selectedRequest, setSelectedRequest] = useState("Single Request");

  const pageSize = 10;

  useEffect(() => {
    dispatch(
      allDirectFunding({
        pageSize,
        bulk: "",
        status: "APPROVED",
        search: searchQuery,
      })
    );
  }, [dispatch, pageSize, searchQuery]);

  const formatAmount = (amount) => {
    if (typeof amount !== "number") return "0.00";
    return amount?.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const dumData = [
    {
      idx: "1",
      companyName: "XYZ Ventures",
      player: "Supplier",
      invoiceDate: "2023-05-23",
      invoiceReference: "INV-2090",
      currency: "NGN",
      amount: 9000000,
      status: "Successful",
      transactionType: "Balance Payment",
    },
    {
      idx: "4",
      companyName: "XYZ Ventures",
      player: "Supplier",
      invoiceDate: "2023-05-23",
      invoiceReference: "INV-2090",
      currency: "NGN",
      amount: 9000000,
      status: "Successful",
      transactionType: "Reimbursed",
    },
    {
      idx: "5",
      companyName: "XYZ Ventures",
      player: "Supplier",
      invoiceDate: "2023-05-23",
      invoiceReference: "INV-2090",
      currency: "NGN",
      amount: 9000000,
      status: "Successful",
      transactionType: "Balance Payment",
    },
    {
      idx: "2",
      companyName: "Dangote Ltd",
      player: "Funder",
      invoiceDate: "2023-05-23",
      invoiceReference: "INV-2090",
      currency: "NGN",
      amount: 9000000,
      status: "Successful",
      transactionType: "Reimbursed",
    },
    {
      idx: "3",
      companyName: "XYZ Ventures",
      player: "Supplier",
      invoiceDate: "2023-05-23",
      invoiceReference: "INV-2090",
      currency: "NGN",
      amount: 9000000,
      status: "Successful",
      transactionType: "Balance Payment",
    },
    {
      idx: "6",
      companyName: "Dangote Ltd",
      player: "Funder",
      invoiceDate: "2023-05-23",
      invoiceReference: "INV-2090",
      currency: "NGN",
      amount: 9000000,
      status: "Successful",
      transactionType: "Reimbursed",
    },
    {
      idx: "7",
      companyName: "XYZ Ventures",
      player: "Supplier",
      invoiceDate: "2023-05-23",
      invoiceReference: "INV-2090",
      currency: "NGN",
      amount: 9000000,
      status: "Successful",
      transactionType: "Balance Payment",
    },
    {
      idx: "8",
      companyName: "Dangote Ltd",
      player: "Funder",
      invoiceDate: "2023-05-23",
      invoiceReference: "INV-2090",
      currency: "NGN",
      amount: 9000000,
      status: "Successful",
      transactionType: "Balance Payment",
    },
    {
      idx: "9",
      companyName: "Dangote Ltd",
      player: "Funder",
      invoiceDate: "2023-05-23",
      invoiceReference: "INV-2090",
      currency: "NGN",
      amount: 9000000,
      status: "Successful",
      transactionType: "Balance Payment",
    },

    {
      idx: "10",
      companyName: "Dangote Ltd",
      player: "Funder",
      invoiceDate: "2023-05-23",
      invoiceReference: "INV-2090",
      currency: "NGN",
      amount: 9000000,
      status: "Successful",
      transactionType: "Loan Reimbursed",
    },
  ];

  const columns = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Transaction Party
        </span>
      ),
      selector: (row) => row.companyName,
      sortable: true,
      cell: (row) => (
        <div className="">
          <p>{row.companyName}</p>
          <span
            className={`${
              row.player === "Supplier" ? "text-[#2EA923]" : "text-[#F08000]"
            }`}
          >
            {`(${row.player})`}
          </span>
        </div>
      ),
    },
    {
      name: "Payment Date",
      selector: (row) => row.invoiceDate?.slice(0, 10),
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Invoice Reference
        </span>
      ),
      selector: (row) => row.invoiceReference,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
      width: "6.2rem",
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      cell: (row) => <p className="text-red">{formatAmount(row.amount)}</p>,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) =>
        row.status === "Successful" ? (
          <p className="text-[#07593D]">{row.status}</p>
        ) : row.status === "REJECTED" ? (
          <p className="text-red">{row.status}</p>
        ) : row.status === "UPDATED" ? (
          <p className="text-blue-300">{row.status}</p>
        ) : row.status === "PARTIALLY PAID" ? (
          <p className="text-yellow-400">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        ),
    },
    {
        name: "Transaction Type",
        selector: (row) => row.transactionType,
        sortable: true,
        width: "6.2rem",
      },
    {
      name: "Action",
      selector: (row) => row.action,
      sortable: true,
      cell: (row) => (
        <div onClick={() => onRowClicked(row)} className="border border-gray300 rounded text-[#959595] p-2">
          View Details
        </div>
      ),
    },
  ];

  const columnsBulk = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Transaction Party
        </span>
      ),
      selector: (row) => row.companyName,
      sortable: true,
      cell: (row) => (
        <div className="">
          <p>{row.companyName}</p>
          <span
            className={`${
              row.player === "Supplier" ? "text-[#2EA923]" : "text-[#F08000]"
            }`}
          >
            {`(${row.player})`}
          </span>
        </div>
      ),
    },
    {
      name: "Payment Date",
      selector: (row) => row.invoiceDate?.slice(0, 10),
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          No of invoices
        </span>
      ),
      selector: (row) => row.invoiceReference,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
      width: "6.2rem",
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      cell: (row) => <p className="text-red">{formatAmount(row.amount)}</p>,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) =>
        row.status === "Successful" ? (
          <p className="text-[#07593D]">{row.status}</p>
        ) : row.status === "REJECTED" ? (
          <p className="text-red">{row.status}</p>
        ) : row.status === "UPDATED" ? (
          <p className="text-blue-300">{row.status}</p>
        ) : row.status === "PARTIALLY PAID" ? (
          <p className="text-yellow-400">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        ),
    },
    {
        name: "Transaction Type",
        selector: (row) => row.transactionType,
        sortable: true,
        width: "6.2rem",
      },
    {
      name: "Action",
      selector: (row) => row.action,
      sortable: true,
      cell: (row) => (
        <div className="border border-gray300 rounded text-[#959595] p-2">
          View Details
        </div>
      ),
    },
  ];

  const onRowClicked = (row) => {
    navigate(`/reimbursement-transaction-history/${row?.idx}`, {
      state: {
        transactionType: row?.transactionType,
        fundingType: fundingType,
      },
    });
  };
  
  const onRowClickedOpenMarket = (row) => {
    navigate(`/reimbursement-transaction-history-open-market/${row?.idx}`, {
      state: {
        transactionType: row?.transactionType,
        fundingType: fundingType,
      },
    });
  };
  

  const handleFundingTypeChange = (e) => {
    setFundingType(e.target.value);
  };

  return (
    <>
      <div>
        {" "}
        <div className="my-2 grid md:grid-cols-2 items-center">
          <div className="gap-3 flex relative">
            <div>
              <select
                className="border border-[#DEDEDE] shadow-sm rounded-[5px] px-4 py-3 text-sm focus:outline-none cursor-pointer"
                onChange={(e) => {}}
                defaultValue="withoutRecourse"
              >
                <option value="withoutRecourse">Without Recourse</option>
                <option value="withRecourse">With Recourse</option>
              </select>
            </div>
            <div>
              <select
                className="border border-[#DEDEDE] shadow-sm rounded-[5px] px-4 py-3 text-sm focus:outline-none cursor-pointer"
                onChange={handleFundingTypeChange}
                defaultValue="directFunding"
              >
                <option value="directFunding">Direct Funding</option>
                <option value="openMarket">Open Market</option>
              </select>
            </div>
            {/* <button className="py-2 px-4 rounded-[5px] bg-white border-[#DEDEDE] shadow-sm mr-2 ">
              Export
            </button> */}
          </div>
          <div className="flex md:col-span-1 justify-end">
            {/* <SearchInput /> */}
            {/* starts from here */}
            <div>
              <div className="flex items-center justify-between gap-5">
                <div className="border border-transparent md:border-gray md:shadow-sm my-4 rounded-[5px] px-2 lg:w-[300px] bg-white">
                  <div className="items-center flex bg-white">
                    <PAIconOutlineSearch />
                    <input
                      className="bg-transparent p-2 w-full focus:outline-none text-sm bg-white"
                      type="text"
                      placeholder="Search for due invoices awaiting collection"
                    />
                  </div>
                  <div className="lg:hidden flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer">
                    <PAIconFilter className="bg-[#2FA06A]" />
                  </div>
                </div>
                <button className="hidden lg:flex bg-[#2FA06A] rounded-md my-4 py-2 px-6 mx3 items-center gap-2 text-white">
                  <PAIconFilter /> Filter
                </button>
              </div>
            </div>

            {/* ends here */}
          </div>
        </div>
        <>
          {!dumData.length ? (
            <div className="ml3">
              <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
                <p className="text-black text-sm font-medium">
                  Overview of CashInflow Records
                </p>
              </div>
              <TableEmptyState dashboard />
            </div>
          ) : (
            <Card className="rounded-[10px] mt-[24px] ">
              <div className="bg-alabasterHeader rounded-t-[10px] min-h-20 px-[24px]   ">
                <div className=" border-b-[0.8px] border-zinc-400 py-4 h-full ">
                  <div className="flex  items-center    ">
                    <div>
                      <p className="text-black text-lg font-semibold">
                        Direct Funding Request
                      </p>
                      <span className="text-xs font-thin text-[#959595]">
                        Here is a recent list of all your reimbursed via direct funding requests to funder
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex ml-6 mt-4 space-x-8 ">
                {["Single Request", "Bulk Request"].map((requestType) => (
                  <div
                    key={requestType}
                    onClick={() => setSelectedRequest(requestType)}
                    className={`cursor-pointer text-center pb-2 relative ${
                      selectedRequest === requestType
                        ? "text-orange-500"
                        : "text-gray-500"
                    }`}
                  >
                    {requestType}
                    {selectedRequest === requestType && (
                      <div
                        className="absolute left-1/2 transform -translate-x-1/2 bottom-0 h-[3px] bg-orange-500"
                        style={{ width: "40px" }}
                      ></div>
                    )}
                  </div>
                ))}
              </div>
              {/* top ends here */}
              {/* columnsBulk */}
              {fundingType === "directFunding" && selectedRequest === "Single Request" && <div className="px-[24px] pb[24px]">
               <Table
                  columns={columns}
                  data={dumData}
                  onRowClicked={(row) => onRowClicked(row)}
                  progressComponent={<Loading />}
                  pointer
                  tableHeader
                  className="mt-5"
                />
              </div>}

              {fundingType === "directFunding" && selectedRequest === "Bulk Request" && <div className="px-[24px] pb[24px]">
               <Table
                  columns={columnsBulk}
                  data={dumData}
                  onRowClicked={(row) => onRowClicked(row)}
                  progressComponent={<Loading />}
                  pointer
                  tableHeader
                  className="mt-5"
                />
              </div>}

              {fundingType === "openMarket" && <div className="px-[24px] pb[24px]">
               <Table
                  columns={columns}
                  data={dumData}
                  onRowClicked={(row) => onRowClickedOpenMarket(row)}
                  progressComponent={<Loading />}
                  pointer
                  tableHeader
                  className="mt-5"
                />
              </div>}
            </Card>
          )}
        </>
      </div>
    </>
  );
};

export default ReimbursementTransactionHistory;
