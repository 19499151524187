import React, { useEffect, useState, Suspense } from "react";

import DashboardLayout from "layout/dashboardlayout";
import FunderInvoice from "./components/funder_invoice";
import FunderCard from "./components/funder_card";
import FunderTable from "./components/funder_table";
import OverviewHeader from "shared/overviewHeader";

// import { buyerCreateTransPin } from "appstate/buyer/buyerSlice";
import {
  funderCreateTransPin,
  funderDashboardStats,
  getEarlyPaymentFunderSingleConfig,
} from "appstate/funder/funderSlice";
import { useDispatch } from "react-redux";
import { setNewToDashBoard } from "features/onboarding/supplier/supplierVerifcationSlice/verificationSlice";
import { useSelector } from "react-redux";
import { useKeycloak } from "@react-keycloak/web";
import { allVirtualAccounts } from "appstate/wallet/walletSlice";
import { PAIconIconLeftPointer } from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
// PAIconIconLeftPointer

const CreateTransPinModal = React.lazy(() =>
  import("shared/createTransPinModal/CreateTransPinModal")
);
const ConfirmTransPinModal = React.lazy(() =>
  import("shared/createTransPinModal/ConfirmTransPinModal")
);
const CreateTransPinSucessModal = React.lazy(() =>
  import("shared/createTransPinModal/CreatTranPinSucessModal")
);
const VirtualAccountSucessModal = React.lazy(() =>
  import("shared/createTransPinModal/VirtualAccountSucessModal")
);

const FunderDashboard = () => {
  const [pinOpen, setPinOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [createdsucess, setCreatedsucess] = useState(false);
  const [virtualAccountCreated, setVirtualAccountCreated] = useState(false);
  const { isLoading, funderDashBoard } = useSelector((state) => state.funder);
  const dispatch = useDispatch();
  const { newToDashBoard } = useSelector(
    (state) => state?.verificationSupplier
  );
  const navigate = useNavigate();

  const [pinstate, setPinState] = useState("");

  const handleNextPin = () => {
    setPinOpen(false);
    setConfirm(true);
  };
  const handleConfirm = () => {
    setConfirm(false);
    setCreatedsucess(true);
  };
  const handlepinSucess = () => {
    setCreatedsucess(false);
    setVirtualAccountCreated(true);
  };
  useEffect(() => {
    if (newToDashBoard) {
      setPinOpen(true);
    } else {
      dispatch(getEarlyPaymentFunderSingleConfig());
      dispatch(allVirtualAccounts());
    }
    // Clean up sessionStorage if necessary

    // Clean up sessionStorage if necessary
    sessionStorage.removeItem("activeTab");
    sessionStorage.removeItem("searchQuery");
    sessionStorage.removeItem("activeTabSupplier");
    sessionStorage.removeItem("searchQuerySupplier");
  }, [newToDashBoard, dispatch]);

  useEffect(() => {
    dispatch(funderDashboardStats());
  }, [dispatch]);

  const { keycloak } = useKeycloak();
  const { given_name } = keycloak?.idTokenParsed;
  return (
    <DashboardLayout subHeader="Here is what is happening in your finance">
      <Suspense fallback={"loading ..."}>
        <CreateTransPinModal
          isOpen={pinOpen}
          disabled={true}
          onClose={() => setPinOpen(false)}
          // handleNext={handleNextPin}
          onSubmit={(pin) => {
            setPinState(pin);
            handleNextPin();
          }}
        />
      </Suspense>
      <Suspense fallback={"loading ..."}>
        <ConfirmTransPinModal
          isOpen={confirm}
          onClose={() => setConfirm(false)}
          isLoading={isLoading}
          onSubmit={(pin) => {
            const body = {
              transactionPin: pinstate,
              confirmTransactionPin: pin,
            };

            dispatch(funderCreateTransPin(body)).then((data) => {
              if (
                data?.payload?.message === "Transaction PIN already exists."
              ) {
                dispatch(setNewToDashBoard(false));
              }
              if (data?.payload?.status === 200) {
                dispatch(setNewToDashBoard(false));

                handleConfirm();
              }
            });
          }}
          // onSubmit={(pin) => {
          //   handleConfirm();
          // }}
        />
      </Suspense>
      <Suspense fallback={"loading ..."}>
        <CreateTransPinSucessModal
          isOpen={createdsucess}
          onClose={handlepinSucess}
          message={"Your transaction PIN has been"}
          message2={" successfully created."}
        />
      </Suspense>
      <Suspense fallback={"loading ..."}>
        <VirtualAccountSucessModal
          isOpen={virtualAccountCreated}
          onClose={() => setVirtualAccountCreated(false)}
          userName={given_name ? given_name : ""}
          collectionType={"collection"}
        />
      </Suspense>

      <OverviewHeader />
      <div className="w-full flex flex-wrap flex-col-reverse md:flex-row">
        {/* INVOICE */}
        <section className="w-full md:w-1/2">
          {/* <FunderOpenmarket /> */}

          <FunderInvoice summary={funderDashBoard} />

          <FunderTable summary={funderDashBoard} />
        </section>

        {/* SECOND SECTION */}
        <section className="w-full md:w-1/2">
          {/* <div className="flex-1 items-center p4 pt-5 md:pb-6">
            <div className="flex md:flex-row md:items-center items-start md:justify-end justify-between lg:mb-4 mb4 md:mb0 md:ml-4">
              <button className="rounded-md border border-green-800 flex items-center lg:p-2 p-2 gap-2 text-green-800 mr-5 transition duration-300 ease-in-out transform hover:scale-105">
                <img src="/green-acct.png" alt="#" />
                <span className="hidden md:inline">Manage Suppliers</span>
                <span className="md:hidden text-sm">Suppliers</span>
              </button>
              <button className="rounded-md bg-green-800 flex items-center lg:p-2 p-2 gap-2 text-white transition duration-300 ease-in-out transform hover:scale-105">
                <img src="/white-acct.svg" alt="#" />
                <span className="hidden md:inline">Manage Buyers</span>
                <span className="md:hidden text-sm">Buyers</span>
              </button>
            </div>
          </div> */}

          <FunderCard summary={funderDashBoard} />

          {/* next section */}

          <div className=" w-full   pl-3 ">
            <div className="flex justify-between w-full gap-3  flex-wrap">
              {/* first card */}
              <div
                class=" w-full   min-h-56  xl:w-[48%]   relative bg-white rounded-lg shadow-[4px_4px_15px_0px_rgba(118,164,149,0.21)] "
                onClick={() => navigate("/collections")}
              >
                <div class="w-full h-12 px-6 py-4 left-0 top-0 absolute bg-[#07593D] rounded-tl-lg rounded-tr-lg inline-flex justify-between items-center overflow-hidden">
                  <div class="justify-start text-white text-sm font-medium uppercase">
                    COLLECTIONS
                  </div>
                </div>
                <div class="left-[54px] top-[67px] absolute inline-flex flex-col justify-start items-start gap-5">
                  <div class="flex flex-col justify-start items-center gap-1 ">
                    <div class="inline-flex justify-start items-center gap-[3px]">
                      <div class="flex justify-start items-center">
                        <div class="justify-start text-emerald-800 text-xl font-bold text-[#065f46]">
                          {funderDashBoard?.data?.stats?.totalPendingInvoices}
                        </div>
                      </div>
                    </div>
                    <div class="justify-start text-neutral-500 text-sm font-normal text-[#bababa]">
                      Total Pending Invoices
                    </div>
                  </div>
                  <div class="flex flex-col justify-start items-center gap-1">
                    <div class="inline-flex justify-start items-center gap-[3px]">
                      <div class="justify-start text-emerald-800 text-xl font-bold text-[#065f46]">
                        {
                          funderDashBoard?.data?.stats
                            ?.collectionsTotalPendingAmount
                        }
                      </div>
                    </div>
                    <div class="justify-start text-neutral-500 text-sm font-normal text-[#bababa]">
                      Total Pending Amount
                    </div>
                  </div>
                </div>
              </div>

              {/* second card */}
              <div
                class="w-full   xl:w-[48%]  min-h-56 relative bg-white rounded-lg shadow-[4px_4px_15px_0px_rgba(118,164,149,0.21)]"
                onClick={() => navigate("/disbursement")}
              >
                <div class="w-full h-12 px-6 py-4 left-0 top-0 absolute bg-[#F08000] rounded-tl-lg rounded-tr-lg inline-flex justify-between items-center overflow-hidden">
                  <div class="justify-start text-white text-sm font-medium uppercase">
                    DISBURSEMENTS
                  </div>
                </div>
                <div class="left-[54px] top-[67px] absolute inline-flex flex-col justify-start items-start gap-5">
                  <div class="flex flex-col justify-start items-center gap-1">
                    <div class="inline-flex justify-start items-center gap-[3px]">
                      <div class="flex justify-start items-center">
                        <div class="justify-start text-emerald-800 text-xl font-bold text-[#d97706]">
                          {funderDashBoard?.data?.stats?.totalPendingPayments}
                        </div>
                      </div>
                    </div>
                    <div class="justify-start text-neutral-500 text-sm font-normal text-[#bababa]">
                      Total Pending Payments
                    </div>
                  </div>
                  <div class="flex flex-col justify-start items-center gap-1">
                    <div class="inline-flex justify-start items-center gap-[3px]">
                      <div class="justify-start text-emerald-800 text-xl font-bold text-[#d97706]">
                        {
                          funderDashBoard?.data?.stats
                            ?.disbursementsTotalPendingAmount
                        }
                      </div>
                    </div>
                    <div class="justify-start text-neutral-500 text-sm font-normal text-[#bababa]">
                      Total Pending Amount
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap gap-4 mt-4 w-full justify-center">
              {/* {cards.map((card, index) => ( */}
              <div
                // key={index}
                className="w-full  xl:w-[48%]  bg-white rounded shadow-md border border-amber-600  flex  flex-col pb-1"
              >
                <div className="items-center  flex  w-full  justify-center px-2 pt-3">
                  <div className="flex items-center gap-3">
                    <div className={`text-4xl font-bold  text-[#F08000]`}>
                      {
                        funderDashBoard?.data?.stats
                          ?.pendingPaymentExtensionRequest
                      }
                    </div>
                    <div className="text-neutral-600 text-sm font-medium w-32">
                      {"Pending Payment Extension request"}
                    </div>
                  </div>
                </div>

                <div className=" w-full justify-end items-end flex  px-2 ">
                  <PAIconIconLeftPointer />
                </div>
              </div>

              <div
                // key={index}

                onClick={() => navigate("/payablefinance-earlypayment")}
                className="w-full  xl:w-[48%]  bg-white rounded shadow-md border border-amber-600  flex  flex-col pb-1"
              >
                <div className="items-center  flex  w-full  justify-center px-2 pt-3">
                  <div className="flex items-center gap-3">
                    <div className={`text-4xl font-bold  text-[#065f46]`}>
                      {funderDashBoard?.data?.stats?.pendingEarlyPaymentRequest}
                    </div>
                    <div className="text-neutral-600 text-sm font-medium w-32">
                      {"Pending Early Payment request"}
                    </div>
                  </div>
                </div>

                <div className=" w-full justify-end items-end flex  px-2 ">
                  <PAIconIconLeftPointer />
                </div>
              </div>

              <div
                // key={index}
                onClick={() => navigate("/directfunding")}
                className="w-full  xl:w-[48%]  bg-white rounded shadow-md border border-amber-600  flex  flex-col pb-1"
              >
                <div className="items-center  flex  w-full  justify-center px-2 pt-3">
                  <div className="flex items-center gap-3">
                    <div className={`text-4xl font-bold  text-[#065f46]`}>
                      {
                        funderDashBoard?.data?.stats
                          ?.pendingDirectFundingRequest
                      }
                    </div>
                    <div className="text-neutral-600 text-sm font-medium w-32">
                      {"Pending Direct funding request"}
                    </div>
                  </div>
                </div>

                <div className=" w-full justify-end items-end flex  px-2 ">
                  <PAIconIconLeftPointer />
                </div>
              </div>

              <div
                // key={index}
                onClick={() => navigate("/payablefinancing-campaign")}
                className="w-full  xl:w-[48%]  bg-white rounded shadow-md border border-amber-600  flex  flex-col pb-1"
              >
                <div className="items-center  flex  w-full  justify-center px-2 pt-3">
                  <div className="flex items-center gap-3">
                    <div className={`text-4xl font-bold  text-[#d97706]`}>
                      {funderDashBoard?.data?.stats?.pfpcampaign}
                    </div>
                    <div className="text-neutral-600 text-sm font-medium w-32">
                      {"PFP Campaign"}
                    </div>
                  </div>
                </div>

                <div className=" w-full justify-end items-end flex  px-2 ">
                  <PAIconIconLeftPointer />
                </div>
              </div>
              {/* ))} */}
            </div>
          </div>
        </section>
      </div>
    </DashboardLayout>
  );
};

export default FunderDashboard;
