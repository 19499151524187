import React, { useState } from "react";
import NewSingleEarlyPayment from "../newSingleEarlyPayment/Table";
import NewBulkEarlyPayment from "../newBulkEarlyPayment/Table";

const SingleBulkOption = () => {
  const [activeTab, setActiveTab] = useState("single");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "single":
        return <NewSingleEarlyPayment />;
      case "bulk":
        return <NewBulkEarlyPayment />;
      default:
        return null;
    }
  };

  return (
    <div className="mt-3 gap-2">
      <div className="bg-white rounded-xl mt-5">
        <div className="flex justify-between items-center px-[15px] bg-alabasterHeader h-[70px] rounded-t-[10px] mt-3">
          <div className="py5">
            <p className="text-black text-lg font-bold">
              Early Payment Request from supplier
            </p>
            <span className="text-xs font-thin">
              List of all new early payment requests 
            </span>
          </div>
          <div className="flex gap-3">
            <button className="bg-[#2EA923] py-[8px] px-[16px] rounded-[5px] text-white">Select all</button>
            <button className="bg-gray py-[8px] px-[16px] rounded-[5px] text-white">Approve Early Payment</button>
          </div>
        </div>
        <div className="mt-3 px-[15px] text-sm bgwhite">
          <div className="flex gap-3">
            <p
              className={`cursor-pointer font-bold relative ${
                activeTab === "single" ? "text-[#F08000]" : "text-[#959595]"
              }`}
              onClick={() => handleTabClick("single")}
            >
              Single requests
              {activeTab === "single" && (
                <span
                  className="absolute bottom-0 left-1/2 transform -translate-x-1/2
                 bg-[#F08000] h-0.5 w-5"
                ></span>
              )}
            </p>

            <p
              className={`cursor-pointer font-bold relative ${
                activeTab === "bulk" ? "text-[#F08000]" : "text-[#959595]"
              }`}
              onClick={() => handleTabClick("bulk")}
            >
              Bulk requests
              {activeTab === "bulk" && (
                <span
                  className="absolute bottom-0 left-1/2 transform -translate-x-1/2
                 bg-[#F08000] h-0.5 w-5"
                ></span>
              )}
            </p>
          </div>

          {/* Render the tab content */}
          <div className="mt-3">{renderTabContent()}</div>
        </div>
      </div>
    </div>
  );
};

export default SingleBulkOption;
