import Card from "shared/Card";
import Controls from "./Controls";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NewInvoiceTable from "./NewInvoiceTable";
import DashboardLayout from "layout/dashboardlayout";
import { getAllSupplierAssociatedBuyers } from "appstate/api/supplier/relationshipManagement";
import {
  createInvoice,
  getVirtualAccounts,
} from "appstate/api/invoice/invoice";
import toast from "react-hot-toast";
import ValidatingModal from "features/BuyerEarlyPayment/EarlyPayment/singleEarlyPayment/Modals/ValidatingMappingModal";
import AssociationSuccessModal from "shared/associationsuccessmodal/associationsuccessmodal";

// so i can trace it easily

const NewInvoiceDashboardContents = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [consentInvoice, setConsentInvoice] = useState(false);
  const [discard, setDiscard] = useState(false);
  const [virtualAccounts, setVirtualAccounts] = useState([]);
  const [associationSuccessful, setAssociationSuccessful] = useState(false);
  let isEditing = location.state ? location.state.editing : false;
  const singleInvoice = location.state ? location.state.singleInvoice : false;
  const [editing, setEditing] = useState(isEditing);
  const [controls, setControls] = useState({
    to: singleInvoice ? singleInvoice.buyerId : "",
    dueDate: singleInvoice ? singleInvoice.dueDate : "",
    currency: "",
    account: singleInvoice ? singleInvoice.account : "",
    fundingAccountId: virtualAccounts[0]?.id,
    taxInclusive: singleInvoice ? singleInvoice.taxInclusive : true,
    invoiceNo: "",
    items: singleInvoice
      ? singleInvoice.items
      : [
          {
            id: Date.now(),
            itemName: "",
            description: "",
            unitPrice: "",
            taxRate: "",
            quantity: "",
          },
        ],
    discount: singleInvoice ? singleInvoice.discount : "",
    vat: singleInvoice ? singleInvoice.vat : "",
  });
  const [toggleTaxInclusive, setToggleTaxInclusive] = useState(
    controls.taxInclusive
  );

  const [buyers, setBuyers] = useState([]);
  // eslint-disable-next-line
  const [page, setPage] = useState("");
  // eslint-disable-next-line
  const [pageSize, setPageSize] = useState("");
  // eslint-disable-next-line
  const [search, setSearch] = useState("");

  useEffect(() => {
    const fetchAssociatedBuyers = async () => {
      try {
        const result = await getAllSupplierAssociatedBuyers({
          page,
          pageSize,
          search,
        });
        setBuyers(result?.data || []);
      } catch (error) {
        console.error("Error fetching associated buyers:", error);
      }
    };

    fetchAssociatedBuyers();
  }, [page, pageSize, search]);

  useEffect(() => {
    const fetchVirtualAccounts = async () => {
      try {
        const result = await getVirtualAccounts();
        setVirtualAccounts(result?.data || []);
      } catch (error) {
        console.error("Error fetching associated buyers:", error);
      }
    };

    fetchVirtualAccounts();
  }, []);

  const curr = controls?.currency.split(" ");
  const currencyAbv = curr[curr.length - 1].replace(/[()]/g, "");

  const createInvoiceSupplier = async () => {

    const total = controls.items.reduce((accumulator, item) => {
      const unitPrice = parseFloat(item.unitPrice);
      const quantity = parseFloat(item.quantity);
      const taxRate = parseFloat(item.taxRate);
      const itemSubTotal = unitPrice * quantity;
    
      const taxAmount = toggleTaxInclusive
        ? (itemSubTotal * taxRate) / 100
        : 0;
    
      const tp = itemSubTotal + taxAmount;
      return accumulator + tp;
    }, 0);
    
    const formattedTotal = total.toFixed(2);
    const disAmount = formattedTotal * (controls.discount / 100);
    const netAmounts = formattedTotal - disAmount;
    const vatAmounts = netAmounts * (controls.vat / 100);
    const invAmounts = netAmounts + vatAmounts;
    
    const payload = {
      dueDate: controls.dueDate,
      buyerId: controls.to,
      currency: currencyAbv,
      invoiceNo: controls.invoiceNo,
      items: controls.items.map((item) => {
        const unitPrice = parseFloat(item.unitPrice);
        const quantity = parseFloat(item.quantity);
        const taxRate = parseFloat(item.taxRate);
        const itemSubTotal = unitPrice * quantity;
  
        const taxAmount = toggleTaxInclusive
          ? (itemSubTotal * taxRate) / 100
          : 0;
        const tp = itemSubTotal + taxAmount;
        return {
          itemName: item.itemName,
          description: item.description,
          unitPrice,
          quantity,
          amount: itemSubTotal,
          taxRate,
          taxAmount,
          totalPrice: tp.toFixed(2),
        };
      }),
      taxInclusive: toggleTaxInclusive,
      fundingAccountId: virtualAccounts[0]?.id,
      vat: controls.vat,
      discount: controls.discount,
      invoiceAmount: invAmounts.toFixed(2),
      subTotal: formattedTotal,
      issuanceDate: new Date().toISOString().split("T")[0],
    };
    try {
      const result = await createInvoice(payload);
      if (result?.status === 201) {
        setConsentInvoice(false);
        setAssociationSuccessful(true);
      }
    } catch (error) {
      console.error("Error creating invoice:", error);
      toast.error("Failed to create invoice. Please try again.");
    }
  };
  

  const handleClose = () => {
    setConsentInvoice(false);
  };

  const openConsentModal = () => {
    setConsentInvoice(true);
  };

  const handleDiscard = () => {
    setDiscard(true);
  };

  const confirmDiscard = () => {
    navigate(-1);
  };

  const closeDiscard = () => {
    setDiscard(false);
  };

  const handleCloseModal = () => {
    setAssociationSuccessful(false);
  };

  return (
    <DashboardLayout
      section="Invoice"
      subHeader="Here is an overview of your invoices"
    >
      {associationSuccessful && (
        <div  className="flex justify-center w-full"> 
        <AssociationSuccessModal
          header="Invoice Creation"
          message="Congratulation your invoice has been created successfully"
          handleCloseModal={handleCloseModal}
        />
        </div>
      )}
      {consentInvoice && (
        <div  className="flex justify-center w-full"> 
        <ValidatingModal
          handleCloseConfirm={handleClose}
          handleProceed={createInvoiceSupplier}
          message="Are you sure you would like to create this invoice ?"
        />
         </div>
      )}
      {discard && (
        <div className="flex justify-center w-full">
        <ValidatingModal
          handleCloseConfirm={closeDiscard}
          handleProceed={confirmDiscard}
          message="Are you sure you would like to discard this invoice ?"
        />
        </div>
      )}

      <Card className="rounded-md">
        <div className="bg-[#FAFAFA] rounded-[10px] px-[25px] py-[10px]">
          <Controls
            singleInvoice={singleInvoice}
            setEditing={setEditing}
            controls={controls}
            setControls={setControls}
            editing={editing}
            buyers={buyers}
            toggleTaxInclusive={toggleTaxInclusive}
            setToggleTaxInclusive={setToggleTaxInclusive}
          />
        </div>
        <NewInvoiceTable
          singleInvoice={singleInvoice}
          controls={controls}
          setControls={setControls}
          editing={editing}
          setEditing={setEditing}
          toggleTaxInclusive={toggleTaxInclusive}
          createInvoiceSupplier={openConsentModal}
          handleDiscard={handleDiscard}
        />
      </Card>
    </DashboardLayout>
  );
};

export default NewInvoiceDashboardContents;




