import Word from "../../assets/images/svgs/downloadword.svg";
import Csv from "../../assets/images/svgs/downloadcsv.svg";
import Excel from "../../assets/images/svgs/downloadexcel.svg";
import Pdf from "../../assets/images/svgs/downloadpdf.svg";
import Button from "shared/Button";
import { useState } from "react";

const DownloadOptions = ({ handleCloseModal, message, header }) => {
  const [selected, setSelected] = useState(null);

  const handleSelect = (type) => {
    setSelected(type);
  };
  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      handleCloseModal();
    }
  };

  return (
    <div
      className="w-full fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50"
      onClick={handleBackgroundClick}
    >
      <div
        className="relative w-full max-w-lg p-6 bg-white rounded-lg shadow-lg"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between">
          <div>
            <span>Choose file format</span>
          </div>
          <div style={{ cursor: "pointer" }} onClick={handleCloseModal}>
            <span>X close</span>
          </div>
        </div>
        <br />
        <hr class="border-t border-gray-300 opacity-20" />
        <br />
        <div className="flex justify-between space-x-4">
          {[
            { type: "Word", icon: Word, label: "Word file" },
            { type: "Pdf", icon: Pdf, label: "PDF file" },
            { type: "Excel", icon: Excel, label: "Excel file" },
            { type: "Csv", icon: Csv, label: "CSV file" },
          ].map((item) => (
            <div
              key={item.type}
              onClick={() => handleSelect(item.type)}
              className={`flex items-center p-2 border-[0.5px] ${
                selected === item.type ? "border-blue-500" : "border-gray-200"
              } rounded cursor-pointer`}
            >
              <img src={item.icon} alt={item.type} />
              <span className="pl-2">
                {item.type} <span style={{ fontSize: 12 }}>file</span>
              </span>
            </div>
          ))}
        </div>
        <br />
        <div className="flex justify-end">
          <Button
            neutral
            className="flex text-primaryColor text-xs font-medium items-center mb-[10px] md:mb-0 md:px-[5px] bg-[#07593D] py-[12px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px]"
          >
            <p style={{ color: "white" }} className="whitespace-nowrap">
              Download
            </p>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DownloadOptions;
