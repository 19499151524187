import { PAIconLogo } from "assets/images/svgs";
import React, { useRef, useState } from "react";
import html2pdf from "html2pdf.js";
import { motion } from "framer-motion";
import cardBackImg from "../../../../../../assets/images/svgs/card_back_img.png";

const TransferInfoReceipt = ({ isOpen, onClose, closePrevious }) => {
  const [showDownload, setShowDownload] = useState(true);
  const modalContentRef = useRef(null);

  // Dummy data
  const transferDetails = {
    amount: "10000",
    senderName: "John Doe",
    sourceAccountNumber: "1234567890",
    beneficiaryName: "Jane Smith",
    destinationAccountNumber: "0987654321",
    transactionReference: "ABC123456789",
    createdDate: "2024-05-20",
  };

  const handleDownload = () => {
    setShowDownload(false);
    const element = modalContentRef.current;

    // Get the dimensions of the modal content
    const width = element.offsetWidth;
    const height = element.offsetHeight + 20;

    // Set the options for the PDF
    const options = {
      filename: "receipt.pdf",
      jsPDF: { unit: "px", format: [width, height], orientation: "portrait" },
      image: { type: "jpeg", quality: 1 },
      output: "dataurlnewwindow",
      html2canvas: { scale: 3 },
    };

    // Generate the PDF
    html2pdf().set(options).from(element).save();

    setShowDownload(true);

    setTimeout(() => {
      setTimeout(() => closePrevious(), 4000);
      onClose();
      closePrevious();
    }, 6000);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-[1000]">
      <motion.div
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
          transition: {
            duration: 0.2,
          },
        }}
        exit={{
          opacity: 0,
          transition: {
            delay: 0.15,
          },
        }}
        ref={modalContentRef}
        className="bg-white p-10 rounded-md  flex justify-center flex-col  lg:w-[867px]   md:w-[867px]  flex-wrap"
      >
        <div className="flex justify-between items-center pb-2">
          <PAIconLogo />
          {showDownload && (
            <button
              onClick={() => {
                setShowDownload(false);
                setTimeout(() => handleDownload(), 0.001);
              }}
              className="bg-[#07593D] flex items-center justify-center p-2 text-white rounded-md text-sm px-7"
            >
              Download
            </button>
          )}
        </div>
        <div className="flex flex-col items-center justify-center  border-b-[#00929F] border-b-2">
          <img src={cardBackImg} alt="" />
          <div className="text-black  text-base font-bold mb-5 mt-4">
            Payment receipt
          </div>
        </div>

        <div className="text-[#7A7A7A] text-center flex items-center justify-between text-xs font-normal mt-5">
          <div>
            <p className="text-[#7A7A7A] font-sans italic text-sm font-thin">
              Payment Amount
            </p>
            <p className="text-[#7A7A7A]  font-sans italic text-base font-thin">
              N{transferDetails.amount} - Success
            </p>
          </div>
          <div>
            <p className="text-[#7A7A7A] font-sans text-sm font-thin">
              Currency
            </p>
            <p className="text-[black] font-sans italic flex justify-end">
              NGN
            </p>
          </div>
        </div>

        <div className="text-[#7A7A7A] textcenter flex items-center justify-between text-xs font-normal mt-5">
          <div>
            <p className="text-[#7A7A7A] font-sans italic text-sm font-thin">
              Sender details
            </p>
            <p className="text-[#7A7A7A] font-sans italic font-bold">
              {transferDetails?.senderName || ""}
            </p>
            <p className="text-[#7A7A7A] font-sans italic font-bold">
              Access bank plc | {transferDetails?.sourceAccountNumber || ""}
            </p>
          </div>
          <div>
            <p className="text-[#7A7A7A] font-sans italic  flex justify-end">
              Receiver details
            </p>
            <p className="text-[#7A7A7A] font-sans italic  flex justify-end font-bold">
              {transferDetails?.beneficiaryName || ""}
            </p>
            <p className="text-[#7A7A7A] font-sans italic  flex justify-end font-bold">
              PayEdge | {transferDetails?.destinationAccountNumber || ""}
            </p>
          </div>
        </div>

        <div className="text-[#7A7A7A] text-center flex items-center justify-between text-xs font-normal mb-5 mt-5 ">
          <div>
            <p className="text-black font-sans italic font-extrabold">
              Transaction Reference : {transferDetails.transactionReference}
            </p>
          </div>
          <div>
            <p className="text-[#7A7A7A] font-sans italic  flex justify-end">
              Payment Date
            </p>
            <p className="text-[#7A7A7A] font-sans italic font-bold flex justify-end">
              {transferDetails.createdDate}
            </p>
          </div>
        </div>

        {/* FOOTER */}
        <div className="flex flex-col text-xs items-center justify-center w-full h-auto mt-10">
          <p className="text-[#7A7A7A]">Support</p>
          <p className="text-[#F08000]">customerservice@payedge.com</p>
        </div>
      </motion.div>
    </div>
  );
};

export default TransferInfoReceipt;
