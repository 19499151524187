import React, { useState } from "react";
import MyInvoices from "./myinvoices";
import ReportsAndAnalysis from "./reportsandanalysis";
import Requests from "./requests";

import { PAIconImportPlus } from "assets/images/svgs";
import { useNavigate } from "react-router-dom";

const ClosedMarketTabs = () => {
  const [activeTab, setActiveTab] = useState("requests");
  const navigate = useNavigate();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const navigateToEarlyFund = () => {
    navigate("directEarlyPaymentRequests");
  };
  const renderTabContent = () => {
    switch (activeTab) {
      case "myinvoices":
        return <MyInvoices />;
      case "requests":
        return <Requests />;
      case "reportsAndAnalysis":
        return <ReportsAndAnalysis />;

      default:
        return null;
    }
  };

  const renderCreateNewRequestButton = () => {
    return (
      <div className="flex items-center text-base font-medium text-black">
        <button
          className="p1 flex h-10 items-center gap-4 rounded-md border border-[#CCC] bg-[#FFBE55] p-2 px-5 text-base text-white"
          onClick={navigateToEarlyFund}
        >
          <PAIconImportPlus />
          Import Early Payment Request
        </button>
      </div>
    );
  };

  return (
    <div>
      <div className="mt5 mx6 mb-[17px] w-full cursor-pointer rounded-md bg-white">
        <div className="gap1 flex-col items-center justify-between gap-10 p-2 px-4 md:flex md:flex-row">
          <div className="flex-col gap-10 md:flex md:flex-row">
          <div
              className={
                activeTab === "requests"
                  ? "rounded-l-sm rounded-r-sm border-[#07593D] font-bold text-[#07593D] md:mb-[-18px] md:border-b-4"
                  : "text-gray500 my-2 text-base font-normal md:my-0"
              }
              onClick={() => handleTabClick("requests")}
            >
             My Requests
            </div>

            <div
              className={
                activeTab === "myinvoices"
                  ? "rounded-l-sm rounded-r-sm border-[#07593D] font-bold text-[#07593D] md:mb-[-18px] md:border-b-4"
                  : "text-gray500 text-base font-normal"
              }
              onClick={() => handleTabClick("myinvoices")}
            >
              Pending Funding
            </div>
           
            <div
              className={
                activeTab === "reportsAndAnalysis"
                  ? "rounded-l-sm rounded-r-sm border-[#07593D] font-bold text-[#07593D] md:mb-[-18px] md:border-b-4"
                  : "text-gray500 text-base font-normal"
              }
              onClick={() => handleTabClick("reportsAndAnalysis")}
            >
              Report and Analysis
            </div>
          </div>
          {renderCreateNewRequestButton()}
        </div>
      </div>
      {renderTabContent()}
    </div>
  );
};

export default ClosedMarketTabs;
