import { motion } from "framer-motion";
import { PAIconArrowLeftGreen } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TransitionScale from "shared/animation/TransitionScale";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInvoiceById } from "appstate/openMarket/openMarketSlice";
import DirectFundingInvoice from "../../openMarketInvoice";
// import DirectFundingInvoice from "./openMarketInvoice";

function FundedOpenMarketInvoice() {
  const { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const offerId = searchParams.get("offerId");

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getInvoiceById({ id }));
  }, [dispatch, id, offerId]);

  const { invoiceByIdData } = useSelector((state) => state?.openMarket);

  function handleGoBack() {
    navigate(-1);
  }

  return (
    <DashboardLayout
      section="Open market"
      subHeader="Monitor your invoices in the open market"
    >
      <TransitionScale className="flex  flex-col gap-6 bg-[#EEE] h-full">
        <header onClick={handleGoBack} className="w-full">
          <div className="flex cursor-pointer w-fit gap-1 items-center">
            <PAIconArrowLeftGreen />
            <span className=" text-primaryColor text-sm">Go back</span>
          </div>
        </header>

        <main className="flex flex-col semi-lg:flex-row pb-8 gap-4 w-full ">
          <DirectFundingInvoice
            invoiceByIdData={invoiceByIdData}
            // setLockModalOpen={setLockModalOpen}
            payNow={true}
          />

          <motion.section
            initial={{
              y: -20,
            }}
            animate={{
              y: 0,
            }}
            exit={{
              y: 20,
            }}
            transition={{
              delay: 0.8,
            }}
            className="flex flex-col gap-3  pt10 pb-24 w-2/5 bg-white rounded-[10px]"
          >
            <div className="bg-white w-full rounded-[10px] pt-5 pb-24 h-[100%]">
              <div className="h-[15%]">
                <h2 className="text-lg px-6 font-semibold text-gray-800 pb-4 border-b-[1px] border-b-slate-300 text-primaryColor">
                  Bid details for this invoice
                </h2>

                <div className="px-6 py-6 pb-4 flex justify-between items-center">
                  <div>
                    <p className="text-gray600 text-xs">Request Amount</p>
                    <span className="text-sm">N18,449,000</span>
                  </div>
                  <div>
                    <p className="text-gray600 text-xs">Request Date</p>
                    <span className="flex justify-end text-sm">20/10/2024</span>
                  </div>
                </div>

                <div className="px-6 py-6 pb-4 flex flex-col">
                  <div>
                    <p className="text-gray600 text-xs">
                      Countdown to due Date
                    </p>
                    <span className="text-sm font-bold">01d:45h:1min:34s</span>
                  </div>
                </div>

                <div className="px-6 py-6 pb-4 border-b-[1px] border-b-slate-300 flex justify-between items-center">
                  <div>
                    <p className="text-gray600 text-xs">Request Amount</p>
                    <span className="text-sm">N18,449,000</span>
                  </div>
                  <div>
                    <p className="text-gray600 text-xs">Receivable Amount</p>
                    <span className="flex justify-end text-sm">N9,449,000</span>
                  </div>
                </div>

                <div className="px-6 py-6 pb-4 border-b-[1px] border-b-slate-300 flex flex-col">
                  <div className="flex justify-between items-center">
                    <div>
                      <p className="text-gray600 text-xs">Payment Date</p>
                      <span className="text-sm">20/09/2024</span>
                    </div>
                    <div>
                      <p className="text-gray600 text-xs">Request Date</p>
                      <span className="flex justify-end text-sm">
                        20/10/2024
                      </span>
                    </div>
                  </div>
                  <div className="flex justify-between items-center mt-2">
                    <div>
                      <p className="text-gray600 text-xs">Lock Rate Amount</p>
                      <span className="text-sm">N18,449,000</span>
                    </div>
                    <div>
                      <p className="text-gray600 text-xs">Funder's Lock Rate</p>
                      <span className="flex justify-end text-sm">
                        3.4%{" "}
                        <span className="text-primaryColor">(Recourse)</span>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="px-6 py-6 pb-4">
                  <p className="text-xs text-gray600">Bid Status</p>
                  <p className="text-primaryColor">Bid ended</p>
                </div>
              </div>
            </div>
          </motion.section>
        </main>
      </TransitionScale>
    </DashboardLayout>
  );
}

export default FundedOpenMarketInvoice;
