import request from "apiInstance";
import toast from "react-hot-toast";

export const createNewRole = async (body) => {
  try {
    const response = await request({
      method: "post",
      url: "/admin/roles",
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllRoles = async () => {
  try {
    const response = await request({
      method: "get",
      url: `/admin/permissions/roles-staff`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getRoleById = async (id) => {
  try {
    const response = await request({
      method: "get",
      url: `/admin/permissions/role/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const assignRolePermission = async ({ body, specifiedId }) => {
  try {
    const response = await request({
      method: "put",
      url: `/admin/permissions/role/${specifiedId}`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllAvailablePermissions = async (roleId) => {
  try {
    const response = await request({
      method: "get",
      url: `/admin/permissions/available/${roleId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllAssignedPermissions = async (roleId) => {
  try {
    const response = await request({
      method: "get",
      url: `admin/permissions/available-assigned/${roleId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllSubPermissions = async () => {
  try {
    const response = await request({
      method: "get",
      url: "/admin/permissions/sub",
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const inviteTeamMember = async ({ body, player }) => {
  try {
    const response = await request({
      method: "post",
      url: `/api/v1/${player}s/staff/create`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const onboardNewInvite = async (body, memberId) => {
  try {
    const response = await request({
      method: "put",
      url: `/admin/permissions/complete-onboarding/${memberId}`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const deleteMember = async (memberId) => {
  try {
    const response = await request({
      method: "delete",
      url: `/admin/permissions/member/${memberId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const roleUpdate = async ({ memberId, roleId }) => {
  try {
    const response = await request({
      method: "put",
      url: `/admin/permissions/member/${memberId}?roleId=${roleId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllMembers = async (player) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/${player}s/staff/views`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getStaffProfileById = async ({ player, staffId }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/${player}s/staff/view?staffId=${staffId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getStaffDashboardApi = async (player) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/${player}s/staff/dashboard`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const updateStaffToOnboardApi = async ({ player, body }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/${player}s/staff/update`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const assignPermissionsApi = async ({ body, specifiedId }) => {
  try {
    const response = await request({
      method: "post",
      url: `/admin/permissions/${specifiedId}`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const acceptStaffAgreementApi = async ({ agreementId, checked }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/agreement/approve/staff/${agreementId}?approved=${checked}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const viewStaffAgreementApi = async ({ page = 0, size = 10 }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/agreement/staff/views?page=${page}&size=${size}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const updateStatusApi = async ({ status, staffId, player }) => {
  try {
    const response = await request({
      method: "patch",
      url: `/api/v1/${player}s/staff/${status}/${staffId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
export const deleteStaffUserApi = async ({ staffId, player }) => {
  try {
    const response = await request({
      method: "delete",
      url: `api/v1/${player}s/staff/remove/${staffId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
export const deleteUserRoleApi = async (roleId) => {
  try {
    const response = await request({
      method: "delete",
      url: `/admin/roles/${roleId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
