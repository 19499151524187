import { allMembers, getMemberId } from "appstate/roleManager/roleManagerSlice";
import {
  PAIconCheckMark,
  PAIconEditlight,
  PAIconPauseRed,
  PAIconTrashMini,
  PAIconViewMini,
} from "assets/images/svgs";
import Table from "layout/table/Table";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "shared/Card";
import Pagination from "shared/Pagination";

const RoleManagerTable = ({
  setUserCount,
  setEditActive,
  setToggleViewModal,
  toggleDeleteModalOn,
  page,
  pageSize,
  setPage,
  setId,
  setStatus,
  setToggleActivateModal,
}) => {
  const { userId } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const roleIndex = userId?.realm_access?.roles?.findIndex((arr) => {
    return (
      arr === "SUPPLIER" ||
      arr === "BUYER" ||
      arr === "FUNDER" ||
      arr === "ADMIN"
    );
  });
  const userRole = userId?.realm_access?.roles[roleIndex]?.toLowerCase();
  const dropdownRef = useRef();
  const [dropdown, setDropdown] = useState(false);
  const [atBottom, setAtBottom] = useState(false);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const { allMembersData } = useSelector((state) => state?.roleManager);
  const [data, setData] = useState([]);
  const handleDropdown = (row) => {
    if (dropdown === row.userId) {
      setDropdown(null);
    } else {
      setDropdown(row.userId);
    }
  };

  useEffect(() => {
    dispatch(allMembers(userRole));
    setUserCount(allMembersData?.meta?.totalElements);
  }, [
    dispatch,
    page,
    pageSize,
    userRole,
    allMembersData?.meta?.totalElements,
    setUserCount,
  ]);

  useEffect(() => {
    setData(allMembersData?.data?.staffOnboardResponse);
    const handleClickOutside = (event) => {
      if (
        dropdown &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setDropdown(false);
      }
    };

    const handleDocumentClick = (e) => {
      const screenHeight = window.innerHeight;
      const clickY = e.clientY;
      const bottomThreshold = 200;
      if (screenHeight - clickY <= bottomThreshold) {
        setAtBottom(true);
      } else {
        setAtBottom(false);
      }
    };
    document.addEventListener("click", handleDocumentClick);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
      document.addEventListener("mousedown", handleClickOutside);
    };
  }, [dropdown, allMembersData]);

  const totalPages = allMembersData?.meta?.totalElements;

  const handleViewClick = (row) => {
    setId(row?.userId);
    setEditActive(false);
    setToggleViewModal(true);
    dispatch(getMemberId(row?.userId));
  };

  const handleEditClick = (row) => {
    setId(row?.userId);
    setEditActive(true);
    setToggleViewModal(true);
    dispatch(getMemberId(row?.userId));
  };

  const handleDeleteClick = (row) => {
    setId(row?.userId);
    toggleDeleteModalOn();
    dispatch(getMemberId(row?.userId));
  };
  const handleUpdateStatusClick = (row) => {
    setStatus(
      row?.status === "IN_ACTIVE"
        ? "IN_ACTIVE"
        : row?.status === "ACTIVE"
        ? "deactivate"
        : "activate"
    );
    setId(row?.userId);
    setToggleActivateModal(true);
    dispatch(getMemberId(row?.userId));
  };

  const columns = [
    {
      name: <span className=" text-xs font-[500] text-[#7A7A7A]">NAME</span>,
      selector: (row) => row.fullName || "N/A",
      sortable: true,
    },

    {
      name: <span className=" text-xs font-[500] text-[#7A7A7A]">EMAIL</span>,
      selector: (row) => row?.email || "N/A",
      sortable: true,
    },

    {
      name: <span className=" text-xs font-[500] text-[#7A7A7A]">PHONE</span>,
      selector: (row) => row?.phoneNumber || "N/A",
      sortable: true,
    },
    {
      name: <span className=" text-xs font-[500] text-[#7A7A7A]">ROLE</span>,
      selector: (row) => row?.role || "N/A",
      sortable: true,
    },
    {
      name: <span className=" text-xs font-[500] text-[#7A7A7A]">STATUS</span>,
      selector: (row) =>
        (
          <span>
            {row.status === "IN_ACTIVE" ? (
              <span className="text-secondaryColor">Inactive</span>
            ) : row.status === "DEACTIVATE" ? (
              <span className="text-red">Deactivated</span>
            ) : (
              <span className="text-primaryColor">Activated</span>
            )}
          </span>
        ) || "N/A",
      sortable: true,
    },

    {
      name: <span className=" text-xs font-[500] text-[#7A7A7A]"></span>,
      width: "6rem",
      selector: (row) => row.userId,

      cell: (row) => (
        <div>
          <img
            onClick={() => handleDropdown(row)}
            src="/option-btn.svg"
            alt=""
            className="z-[0]"
          />

          {dropdown === row.userId && row && (
            <div className={`text-xs relative bg-white`}>
              <Card
                className={`w-[160px] bg-white right-[-10px] ${
                  atBottom && "bottom-[15px]"
                }  absolute z-[99999] drop-shadow-md rounded-md py-[12px]`}
              >
                <div
                  onClick={() => handleViewClick(row)}
                  className="flex items-center gap-x-[10px] mb-[12px]  pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]"
                >
                  <span>
                    <PAIconViewMini />
                  </span>
                  <p className="font-xs font-normal">View User</p>
                </div>
                <div
                  onClick={() => handleEditClick(row)}
                  className="z-[99999] flex items-center gap-x-[10px] mb-[12px] pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]"
                >
                  <span>
                    <PAIconEditlight />
                  </span>
                  <p className="font-xs font-normal">Edit User</p>
                </div>
                <div className="border border-silver"></div>
                <div
                  onClick={() => handleUpdateStatusClick(row)}
                  className="flex items-center gap-x-[10px] mt-[10px] pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]"
                >
                  <span>
                    {row?.status === "IN_ACTIVE" ? (
                      <PAIconCheckMark />
                    ) : row?.status === "DEACTIVATE" ? (
                      <PAIconCheckMark />
                    ) : (
                      <PAIconPauseRed />
                    )}
                  </span>
                  <p
                    className={
                      row?.status === "IN_ACTIVE"
                        ? "text-primaryColor"
                        : row?.status === "DEACTIVATE"
                        ? "text-primaryColor"
                        : "text-[#DE1515]"
                    }
                  >
                    {row?.status === "IN_ACTIVE"
                      ? "Activate "
                      : row?.status === "DEACTIVATE"
                      ? "Activate "
                      : "Deactivate "}
                    User
                  </p>
                </div>

                <div
                  onClick={() => handleDeleteClick(row)}
                  className="flex items-center gap-x-[10px] mt-[10px] pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]"
                >
                  <span>
                    <PAIconTrashMini />
                  </span>
                  <p className="text-[#DE1515]">Remove User</p>
                </div>
              </Card>
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="min-h-[60vh] pb-6">
        <Table
          columns={columns}
          onRowClicked={(row) => handleViewClick(row)}
          data={data}
          pointer
          tableHeader
        />
        <div className="flex justify-center gap-x-[8px] mt-[20px]">
          {totalPages > 10 && (
            <Pagination
              page={page}
              startPage={startPage}
              setEndPage={setEndPage}
              endPage={endPage}
              setStartPage={setStartPage}
              setPage={setPage}
              totalPages={totalPages}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default RoleManagerTable;
