// import DashboardLayout from "layout/dashboardlayout";
import Table from "layout/table/Table";
import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import Card from "shared/Card";
import TableHeader from "shared/tableHeader/TableHeader";
import PayableFinanceModal from "./components/Modal";
import { useSelector, useDispatch } from "react-redux";
import { viewMembersPfp } from "appstate/buyer/buyerSlice";
import Pagination from "shared/Pagination";
import { useLocation } from "react-router-dom";
import {
  // PAIconEyePfp,
  PAIconInventoryArrowLeft,
  PAIconTableEmptyState,
} from "assets/images/svgs";

const ViewMembers = ({ setCurrentPage, formData }) => {
  const [open, setOpen] = useState(false);
  const [pfpFilter, setPfpFilter] = useState(false);
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const pageSize = 10;
  const { viewMembersData } = useSelector((state) => state.buyer);
  const [data, setData] = useState([]);
  const location = useLocation();
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [initialData, setInitialData] = useState();
  const { state } = location;
  const reverse = state ? state.reverse : null;

  //   const onRowClicked = (row) => {
  //     let path = "payablefinancedetails";
  //     dispatch(getPfpId(row?.pfpId));
  //     navigate(path, {
  //       state: { financingOption: row.financingOption, status: row.status },
  //     });
  //   };

  const dispatch = useDispatch();

  useEffect(() => {
    const body = {
      programName: formData?.programName || "",
      financingOption: formData?.financingOptions || "",
      startDate: formData?.startDate || "",
      endDate: formData?.endDate || "",
      tierCategory: formData?.tiers || [],
      discountRate: parseFloat(formData?.discount) || 0,
      minimumAmount: parseFloat(formData?.minAmount) || 0,
      maximumAmount: parseFloat(formData?.maxAmount) || 0,
      funderPercentage:
        reverse === true ? parseFloat(100 - formData?.buyerShare) || 0 : 0,
      buyerPercentage: parseFloat(formData?.buyerShare) || 0,
      industryTypes: formData?.industries || [],
      paymentDuration: parseInt(formData?.paymentDuration) || 0,
      invoiceIds: formData?.invoiceIds || [],
      documentId: formData?.agreemntDocument || "",
      funderIds: formData?.funderIds || [],
      fundingAccountId: formData?.accounts || "",
      offerPercentage: parseFloat(formData?.percentage) || 0,
    };
    dispatch(viewMembersPfp({ page, pageSize, body }));
  }, [dispatch, page, pageSize, formData, reverse]);

  useEffect(() => {
    setData(viewMembersData?.supplierCampaignResponse?.data);
  }, [viewMembersData]);

  const handleChange = (event) => {
    setSelectedFilter(event.target.value);
  };

  const columns = [
    {
      name: "Supplier Name",
      selector: (row) => row.supplierName,
      sortable: true,
      width: "10rem",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      width: "10rem",
    },
    {
      name: "Numbe of Invoices",
      selector: (row) => row.noOfInvoices,
      sortable: true,
      width: "10rem",
    },
    {
      name: "Phone Number",
      selector: (row) => row.phoneNo,
      sortable: true,
      width: "10rem",
    },
    {
      name: "Account Tier",
      selector: (row) => row.accountTier,
      sortable: true,
      width: "10rem",
    },

    // {
    //   name: "",
    //   selector: (row) => (
    //     <>
    //       <PAIconEyePfp />
    //     </>
    //   ),
    //   sortable: true,
    //   width: "10rem",
    // },
  ];

  useEffect(() => {
    // Set the initial data when allBuyerPfpData changes
    setInitialData(viewMembersData?.supplierCampaignResponse?.data);
    setData(viewMembersData?.supplierCampaignResponse?.data); // Set the data to the initial data
  }, [viewMembersData]);

  useEffect(() => {
    let filteredData = initialData; // Start with the initial data

    if (selectedFilter === "REVERSE_FACTORING") {
      filteredData = initialData.filter(
        (item) => item.financingOption === "Reverse Factoring"
      );
    } else if (selectedFilter === "DYNAMIC_DISCOUNTING") {
      filteredData = initialData.filter(
        (item) => item.financingOption === "Dynamic Discounting"
      );
    }

    setData(filteredData); // Update the data with the filtered data
    setPfpFilter(true);
  }, [selectedFilter, initialData]);

  const totalPages = viewMembersData?.meta?.size;
  let path = "/payablefinancing/payablefinancedetail/createpayablefinance";

  function handleGoback() {
    setCurrentPage(3);
  }
  return (
    <div>
      <div
        onClick={handleGoback}
        className="flex items-center gap-x-[3px] cursor-pointer mx-5"
      >
        <PAIconInventoryArrowLeft />
        <p className="mt-[2px] text-sm font-normal text-lightGray">Go Back</p>
      </div>
      <PayableFinanceModal isShown={open} hide={() => setOpen(false)} />
      <Card className="p-[24px] rounded-[10px]">
        <div>
          <TableHeader
            details="List of suppliers"
            total="All suppliers in the payable finance campaign"
            path={path}
            payableFinance
            pfpFilter={pfpFilter}
            handleChange={handleChange}
          />
        </div>
        {viewMembersData?.noOfSuppliers === 0 ? (
          <div className="flex flex-col items-center justify-center w-full h-[65vh]">
            <div className="flex flex-col gap-4 items-center justify-center">
              <PAIconTableEmptyState />
              <p>No Supplier Data Available</p>
            </div>
          </div>
        ) : (
          <Table
            columns={columns}
            data={data}
            //   onRowClicked={onRowClicked}
            //   progressPending={isLoading}
            //   progressComponent={<Loading />}
            pointer
            tableHeader
            className="mt-5"
          />
        )}

        <div className="flex justify-center gap-x-[8px] mt-[20px]">
          {totalPages > 9 && (
            <Pagination
              page={page}
              startPage={startPage}
              setEndPage={setEndPage}
              endPage={endPage}
              setStartPage={setStartPage}
              setPage={setPage}
              totalPages={totalPages}
            />
          )}
        </div>
      </Card>
    </div>
  );
};

export default ViewMembers;
