import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AcceptModal from "shared/AcceptModal";
import SuccessModal from "shared/SuccessModal";

function BulkDirectFundingInvoice({ invoiceByIdData }) {
  const invoiceIdData = {
    data: {
      items: [
        {
          itemName: "Product A",
          description: "A great product",
          unitPrice: 15.99,
          quantity: 2,
        },
        {
          itemName: "Product B",
          description: "Another great product",
          unitPrice: 29.99,
          quantity: 1,
        },
        {
          itemName: "Product C",
          description: "Yet another great product",
          unitPrice: 10.5,
          quantity: 3,
        },
      ],
      bulkItems: [
        {
          id: "1",
          ref: "INV-2090",
          dueDate: "2023-05-23",
          requestAmount: "9,000,000.00",
          discountRate: "1.5%",
          payableAmount: "9,000,000.00",
        },
        {
          id: "2",
          ref: "INV-2090",
          dueDate: "2023-05-23",
          requestAmount: "9,000,000.00",
          discountRate: "1.5%",
          payableAmount: "9,000,000.00",
        },
        {
          id: "3",
          ref: "INV-2090",
          dueDate: "2023-05-23",
          requestAmount: "9,000,000.00",
          discountRate: "1.5%",
          payableAmount: "9,000,000.00",
        },
      ],
      invoiceNumber: "INV-2024",
      createdDate: "2024-12-23",
      submittedDate: "2024-12-24",
      approvedDate: "2024-12-25",
      dueDate: "2024-12-26",
      issuanceDate: "2024-12-27",
      requestAmount: "1,000,000",
      requestDate: "2024-12-28",
      paymentDate: "2024-12-29",
      buyerDetails: "Buyer Company",
      paymentTerms: "Net 30",
      currency: "NGN",
      lineItems: { numberOfLineItems: 3 },
      subTotal: "500.00",
      discount: 10,
      discountAmount: "50.00",
      vat: 5,
      vatAmount: "25.00",
      grandTotal: "475.00",
    },
  };

  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  const handleConfirm = () => {
    setIsAcceptModalOpen(false);
    setIsSuccessModalOpen(true);
    setSuccessMessage("Payment remainders sent successfully");
  };

  const handleSuccessClose = () => {
    setIsSuccessModalOpen(false);
    navigate(-1);
  };

  /*


       


  */

  return (
    <motion.section
      initial={{
        x: 20,
      }}
      animate={{
        x: 0,
      }}
      exit={{
        x: -20,
      }}
      transition={{
        delay: 0.6,
      }}
      className="flex flex-col gap8  pt-7  w-[60%] semilg:w-[55%] bg-white rounded-[10px]"
    >
      <div className="mb-2 flex flex-col gap-3  px-6">
        <header className="flex items-center justify-between">
          <h1 className=" text-[18px] font-bold">
            Reference No | {invoiceByIdData?.data?.invoiceNumber || "R3456X89Y"}
          </h1>

          <div>
            <button
              class="h-[41px] px-6 py-3 bg-[#07593d] rounded-[5px] justify-center items-center gap-2.5 inline-flex"
              onClick={() => setIsAcceptModalOpen(true)}
            >
              <div class="text-white text-sm font-semibold ">Pay Loan</div>
            </button>
            <div class="text-right text-[#de1515] text-sm font-semibold  mt-1">
              00d:13h:55m:32s
            </div>
          </div>
          {/* <button
            className="bg-primaryColor px-4 py-2 text-white rounded"
            onClick={() => setIsAcceptModalOpen(true)}
          >
            Send Remainder Now
          </button> */}
        </header>

        <main className="w-full flex items-center justify-between">
          <div className="flex flex-col gap-3 w-2/5">
            <div className="flex flex-col gap-1">
              <div className="flex justify-between">
                <span className=" font-semibold text-xs">Invoice Number:</span>
                <span className="text-[#979797]  text-xs">
                  {invoiceByIdData?.data?.invoiceNumber || "INV-2024"}
                </span>
              </div>
              <div className="flex justify-between">
                <span className=" font-semibold text-xs">Created Date:</span>
                <span className="text-[#979797]  text-xs">
                  {invoiceByIdData?.data?.createdDate.slice(0, 10) ||
                    "23-Dec-2024"}
                </span>
              </div>

              <div className="flex justify-between">
                <span className=" font-semibold text-xs">Issued Date</span>
                <span className="text-[#979797]  text-xs">
                  {invoiceByIdData?.data?.issuanceDate.slice(0, 10) ||
                    "23-Dec-2024"}
                </span>
              </div>
              <div className="flex justify-between">
                <span className=" font-semibold text-xs">Submitted Date:</span>
                <span className="text-[#979797]  text-xs">
                  {invoiceByIdData?.data?.submittedDate.slice(0, 10) ||
                    "23-Dec-2024"}
                </span>
              </div>
              <div className="flex justify-between">
                <span className=" font-semibold text-xs">Approved Date:</span>
                <span className="text-[#979797]  text-xs">
                  {invoiceByIdData?.data?.approvedDate.slice(0, 10) ||
                    "23-Dec-2024"}
                </span>
              </div>
              <div className="flex justify-between">
                <span className=" font-semibold text-xs">Due Date:</span>
                <span className="text-[#979797]  text-xs">
                  {invoiceByIdData?.data?.dueDate.slice(0, 10) || "23-Dec-2024"}
                </span>
              </div>
            </div>
          </div>

          {/* STATUS */}

          <div class="h-[30px] px-8 py-2 bg-[#ff8f8f]/20 rounded-[5px] justify-center items-center gap-2.5 inline-flex overflow-hidden">
            <div class="text-center text-[#de1515] text-xs font-semibold ">
              Loan{" "}
            </div>
          </div>
          {/* <div className="rounded-md py-1.5 bg-[#F080000F] px-6">
            <p className="text-center text-secondaryColor text-xs font-bold">
              {invoiceByIdData?.data?.status || "Pending Reimbursment"}
            </p>
          </div> */}
        </main>
      </div>

      {/* Buyer details and co. */}
      <div className="flex justify-between my-5 border-b-[5px] border-b-[#F6F5FA] py-3 px-6">
        <div className="flex flex-col">
          <span className="font-bold text-sm">
            {invoiceByIdData?.data?.buyerDetails || "Nestle Plc"}
          </span>
          <span className="text-[#979797] text-xs">Buyer details</span>
        </div>
        <div className="flex flex-col">
          <span className="font-bold text-sm">
            {invoiceByIdData?.data?.paymentTerms || "60 Days"}
          </span>
          <span className="text-[#979797] text-xs">Payment</span>
        </div>
        <div className="flex flex-col">
          <span className="font-bold text-sm ">
            {invoiceByIdData?.data?.currency || "NGN"}
          </span>
          <span className="text-[#979797] text-xs">Currency</span>
        </div>
      </div>

      <div className="flex  flex-col gap-4 ">
        <header className="border-b-[5px] border-b-[#F6F5FA] pb-3 flex items-center justify-between px-6">
          <h1 className="text-[18px] font-normal">Line Items</h1>
          <p>
            {invoiceByIdData?.data?.lineItems?.numberOfLineItems || "5"} Total
            Items
          </p>
        </header>
        <div className=" w-full overflow-x-auto px-3  pb-12">
          <table className=" w-full overflow-x-auto">
            <thead className="bg-subtleGray w-">
              <tr className="bg-unusualGray p-2.5 ">
                <td className="text-[#11192A] p-2.5 text-xs font-bold">
                  Invoice Reference
                </td>
                <td className="p-2.5 text-xs font-bold">Due Date</td>
                <td className="p-2.5 text-xs font-bold">Request Amount</td>
                <td className="p-2.5 text-xs font-bold">Discount Rate</td>
                <td className="p-2.5 text-xs font-bold">Payable Amount</td>
                {/* <td className="p-2.5 text-xs font-bold">Receivable Amount</td> */}
              </tr>
            </thead>
            <tbody>
              {invoiceIdData?.data?.bulkItems?.map((item) => (
                <tr
                  key={item.id}
                  className=" py-[3px] px-1.5 cursor-pointer"
                  onClick={() =>
                    navigate(
                      `/reimbursement-bulk-reimburse-loandefault-inner-details/${item.id}`
                    )
                  }
                >
                  <td className=" text-xs text-[#11192a] p-2.5 border-b-[0.1px] border-gray py-3">
                    {item?.ref}
                  </td>
                  <td className=" text-xs text-[#11192a] p-2.5 border-b-[0.1px] border-gray py-3">
                    {item?.dueDate}
                  </td>
                  <td className=" text-xs text-[#11192a] p-2.5 border-b-[0.1px] border-gray py-3">
                    {item?.requestAmount}
                  </td>
                  <td className=" text-xs text-[#11192a] p-2.5 border-b-[0.1px] border-gray py-3">
                    {item?.discountRate}
                  </td>
                  <td className=" text-xs text-[#11192a] p-2.5 border-b-[0.1px] border-gray py-3">
                    {item?.payableAmount}
                  </td>
                  {/* <td className=" text-xs text-[#11192a] p-2.5 border-b-[0.4px] border-gray">
                    {item?.receivableAmount}
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <AnimatePresence>
        <AcceptModal
          isOpen={isAcceptModalOpen}
          onClose={() => setIsAcceptModalOpen(false)}
          yes
          message={
            <>
              Would you like to proceed with sending a <br />
              <span className="text-[#07593D] font-bold">
                Payment reminder
              </span>{" "}
              now?
            </>
          }
          onConfirm={handleConfirm}
        />

        <SuccessModal
          isOpen={isSuccessModalOpen}
          onClose={handleSuccessClose}
          message={successMessage}
        />
      </AnimatePresence>
    </motion.section>
  );
}

export default BulkDirectFundingInvoice;
