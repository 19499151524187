import React, { useEffect } from "react";
import Table from "layout/table/Table";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loading from "shared/Loading";
import {
  allTransactions,
  setActiveTab,
} from "appstate/collections/collectionSlice";

const RecentPaymentTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const page = 1;
  const pageSize = 10;

  useEffect(() => {
    const params = {
      page,
      pageSize,
      search: "",
      fundingChannel: "",
    };

    dispatch(allTransactions(params));
  }, [dispatch]);

  const { transactionData, isLoading } = useSelector(
    (state) => state?.collection
  );

  const transactionDetails = (id) => {
    let path = `/collections/transactionhistory-funder/${id}`;
    navigate(path);
  };

  const allTransaction = (activeTab) => {
    dispatch(setActiveTab(activeTab));
  };

  const columns = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Sender
        </span>
      ),
      selector: (row) => row.sender,
      sortable: true,
    },
    {
      name: "Payment Date",
      selector: (row) => row.paymentDate?.slice(0, 10),
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Reference Number
        </span>
      ),
      selector: (row) => row.invoiceReference,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
      // width: "6.2rem",
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      // cell: (row) => <p className="text-success">{formatAmount(row.amount)}</p>,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) =>
        row.status === "SUCCESSFUL" ? (
          <p className="text-[#07593D]">{row.status}</p>
        ) : row.status === "REJECTED" ? (
          <p className="text-red">{row.status}</p>
        ) : row.status === "UPDATED" ? (
          <p className="text-blue-300 ">{row.status}</p>
        ) : row.status === "PARTIALLY PAID" ? (
          <p className="text-yellow-400">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        ),
    },

    {
      name: "Funding Channel",
      selector: (row) => row.fundingChannel,
      sortable: true,
      // width: "6.2rem",
    },
  ];

  return (
    <>
      {!transactionData?.data?.length ? (
        <div className="ml3">
          <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
            <p className="text-black text-sm font-medium">
              Recent Payment Collection Transaction history
            </p>
          </div>
          <TableEmptyState dashboard />
        </div>
      ) : (
        <Card className="rounded-[10px] mt[24px]  mt-14">
          <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
            <div>
              <p className="text-black text-lg font-bold mt-2">
                Recent Payment Collection Transaction history
              </p>
              <span className="text-xs font-thin">
                Here is a recent list of your receivables from Payment
                Collections
              </span>
            </div>
            <div
              className="cursor-pointer"
              onClick={() => allTransaction("TransactionHistory")}
            >
              <p className="text-[#959595] underline text-xs">View All</p>
            </div>
          </div>
          <div className="px-[24px] pb[24px]">
            <Table
              columns={columns}
              data={transactionData?.data?.slice(-3)}
              onRowClicked={(row) => transactionDetails(row.invoiceId)}
              progressPending={isLoading}
              progressComponent={<Loading />}
              pointer
              tableHeader
              className="mt-5"
            />
          </div>
        </Card>
      )}
    </>
  );
};

export default RecentPaymentTable;
