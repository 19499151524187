import React, { useState, useEffect } from "react";
import Table from "layout/table/Table";
import Card from "shared/Card";
import Loading from "shared/Loading";
import { useNavigate } from "react-router-dom";
import walletEmpty from "assets/images/walletEmpty.png";
import TransactionReceipt from "./TransactionReceipt";
import { useDispatch, useSelector } from "react-redux";
import { allTransactions } from "appstate/wallet/walletSlice";

const TransactionTable = () => {
  const { transactions, isLoading } = useSelector((state) => state?.wallet);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getLatestTransactions = (transactions) => {
    if (!transactions || !transactions?.data) return [];

    const transactionsCopy = [...transactions?.data];

    return transactionsCopy
      .sort((a, b) => new Date(b.transactionDate) - new Date(a.transactionDate))
      .slice(0, 5);
  };

  const latestTransactions = getLatestTransactions(transactions);

  const formatTransactionDate = (dateString) => {
    const [datePart] = dateString.split(" ");
    
    const [year, month, day] = datePart.split("-");
    
    const formattedDate = `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(2, "0")}`;
    
    return formattedDate;
};

  const formatAmount = (amount) => {
    if (!amount) return "0.00";
    const parsedAmount = parseFloat(amount);
    if (isNaN(parsedAmount)) return "0.00";
    return parsedAmount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const page = 0;
  const size = 5;
  const startDate = "";
  const endDate = "";
  const searchKey = "";

  useEffect(() => {
    dispatch(
      allTransactions({ page, size, startDate, endDate, searchKey })
    );
  }, [dispatch, page, size]);

  const [selectedTransaction, setSelectedTransaction] = useState(null);

  const columns = [
    {
      name: "Transaction ID",
      selector: (row) => row.transactionReference,
      sortable: true,
      width: "8.3rem",
    },
    {
      name: "Transaction Party",
      selector: (row) => row.sourceAccountName,
      sortable: true,
      maxWidth: "100%",
    },
    {
      name: "Transaction Type",
      selector: (row) => row.transactionType,
      sortable: true,
      maxWidth: "100%",
    },
    {
      name: "Transaction Date",
      selector: (row) => formatTransactionDate(row.transactionDate),
      sortable: true,
      maxWidth: "100%",
    },
    {
      name: "Sign",
      selector: (row) => row.transactionSign,
      sortable: true,
      cell: (row) =>
        row.transactionSign === "CREDIT" ? (
          <p className="text-success">Cr</p>
        ) : row.transactionSign === "DEBIT" ? (
          <p className="text-red">Dr</p>
        ) : "",
      width: "7rem",
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
      width: "7rem",
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      cell: (row) =>
        row.transactionSign === "DEBIT" ? (
          <p className="text-red">{formatAmount(row.amount)}</p>
        ) : row.transactionSign === "CREDIT" ? (
          <p className="text-success">{formatAmount(row.amount)}</p>
        )  : "",

      width: "7.9rem",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) =>
        row.status === "SUCCESSFUL" ? (
          <p className="text-success">{row.status}</p>
        ) : row.status === "FAILED" ? (
          <p className="text-red">{row.status}</p>
        ) : row.status === "PENDING" ? (
          <p className="text-yellow-400">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        ),
      width: "9rem",
    },
  ];

  // const handleViewMore = (transaction) => {
  //   setSelectedTransaction(transaction);
  // };
  const handleViewMore = (row) => {
    // setSelectedTransactionId(row.transactionId);
    setSelectedTransaction(row);
  };

  return (
    <>
      <Card className="rounded-[10px] mt-[24px]">
        <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
          <div className="text-[#064731]">
            <p className="text-base font-bold">Recent Transactions</p>
            <p className="text-[10px]">Transactions Summary</p>
          </div>
          {latestTransactions?.length > 0 ? (
            <p
              onClick={() => navigate("/wallet/transactions")}
              className="text-black text-sm font-medium underline cursor-pointer"
            >
              View All
            </p>
          ) : (
            ""
          )}
        </div>
        <div className="px-[24px] pb-[24px]">
          {latestTransactions?.length > 0 ? (
            <Table
              columns={columns}
              data={latestTransactions}
              progressPending={isLoading}
              progressComponent={<Loading />}
              // onRowClicked={(row) => handleViewMore(row.transactionId)}
              onRowClicked={handleViewMore}
              pointer
              tableHeader
              className="mt-5 min-h[60vh]"
            />
          ) : (
            <img
              src={walletEmpty}
              className="max-w-[200px] max-h-[200px] mx-auto"
              alt="No transactions found"
            />
          )}
        </div>
      </Card>
      {selectedTransaction && (
        <TransactionReceipt
          transferDetails={selectedTransaction}
          onClose={() => setSelectedTransaction(null)}
          paymentAmount={formatAmount(selectedTransaction?.amount)}
          currency={selectedTransaction?.currency}
          senderDetails={{
            name: selectedTransaction?.sourceAccountName,
            bank: "Payedge Virtual Account",
            accountNumber: selectedTransaction?.sourceAccountNumber,
          }}
          beneficiaryDetails={{
            name: selectedTransaction?.destinationAccountName,
            bank: "Payedge Virtual Account",
            accountNumber: selectedTransaction?.destinationAccountNumber,
          }}
          transactionType={selectedTransaction?.transactionType}
          transactionReference={selectedTransaction?.transactionReference}
          description={selectedTransaction?.description}
          paymentDate={formatTransactionDate(
            selectedTransaction?.transactionDate
          )}
        />
      )}
    </>
  );
};

export default TransactionTable;
