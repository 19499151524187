import { PAIconInventoryArrowLeft } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Card from "shared/Card";
import { motion } from "framer-motion";
import CocaCola from "../../../assets/images/svgs/cocacola.svg";
import { useEffect, useState } from "react";
import { getAllInvoiceByIdDF, getDirectFundingDetails, getVirtualAccounts } from "appstate/api/invoice/invoice";
import { formatCurrency } from "utilities/helper/formatters";

const FunderDirectFundingDetailsRejectedSingleRequest = () => {
  const { id } = useParams();
  // eslint-disable-next-line
  const [error, setError] = useState(null);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line
  const [virtualAccounts, setVirtualAccounts] = useState([]);
  const [directFundingData, setDirectFundingData] = useState(null);
  const [invoiceData, setInvoiceData] = useState(null);
  const location = useLocation();
  const invoiceId = location.state?.invoiceId;

  useEffect(() => {
    const fetchInvoiceDetails = async () => {
      if (!invoiceId) {
        console.error("Invoice ID is missing");
        return;
      }
      try {
        const response = await getAllInvoiceByIdDF(invoiceId);
        setInvoiceData(response?.data);
      } catch (error) {
        setError(error.message || "Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    if (invoiceId) {
      fetchInvoiceDetails();
    }
  }, [invoiceId]);


  useEffect(() => {
    const fetchDirectFundingDetails = async () => {
      try {
        const result = await getDirectFundingDetails(id);
        setDirectFundingData(result);
      } catch (error) {
        setError(error.message || "Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchDirectFundingDetails();
    }
  }, [id]);

  useEffect(() => {
    const fetchVirtualAccounts = async () => {
      try {
        const result = await getVirtualAccounts();
        setVirtualAccounts(result?.data);
      } catch (error) {
        console.error("Failed to fetch virtual accounts:", error);
      }
    };

    fetchVirtualAccounts();
  }, []);
  const navigate = useNavigate();

  function handleNavigate() {
    let path = -1;
    navigate(path);
  }

  return (
    <DashboardLayout
      section="Direct Fund Request"
      subHeader="Manage all your direct fund requests"
    >
      <header>
        <div
          onClick={handleNavigate}
          className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
        >
          <PAIconInventoryArrowLeft />
          <p className="mt-[2px] text-sm font-normal text-lightGray">Go Back</p>
        </div>
      </header>
      <div className="flex justify-between">
        <Card className="w-[60%] p-[24px] rounded-[10px]">
          <div className=" flex  flex-col gap-3 ">
            <main className=" w-full flex justify-between">
              <div className="flex flex-col gap-3 w-1/2">
                <div className="flex">
                  <span>Reference No:</span>
                  <span className="pl-2">| {invoiceData?.invoiceRef}</span>
                </div>
                <div className="flex flex-col gap-1">
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Invoice Number:</span>
                    <span className="text-[#979797]  text-xs"> {`INV-${invoiceData?.invoiceNo}`}</span>
                  </div>
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Created Date:</span>
                    <span className="text-[#979797]  text-xs">{invoiceData?.createdDate}</span>
                  </div>
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Submitted Date:</span>
                    <span className="text-[#979797]  text-xs">{invoiceData?.submittedDate}</span>
                  </div>
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Approved Date:</span>
                    <span className="text-[#979797]  text-xs">{invoiceData?.approvedDate}</span>
                  </div>
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Due Date:</span>
                    <span className="text-[#979797]  text-xs">{invoiceData?.dueDate}</span>
                  </div>
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Issuance Date:</span>
                    <span className="text-[#979797]  text-xs">{invoiceData?.createdDate}</span>
                  </div>
                </div>
              </div>


              <div className="flex flex-col justify-between">
                <motion.button
                  whileTap={{ scale: 0.9 }}
                  className="flex bg-[#FAD9D9] text-sm justify-center gap-2 text-[#DE1515] rounded-[5px] items-center px-[35px] py-1.5 "
                >
                  {directFundingData?.status}
                </motion.button>
              </div>

            </main>
          </div>
          <br />
          <div className="flex justify-between">
            <div className="flex flex-col">
              <span className="font-light  text-xs">{directFundingData?.buyerCompanyName}</span>
              <span className="text-[#979797] text-sm">buyer details</span>
            </div>
            {/* <div className="flex flex-col">
              <span className="font-light   text-xs">60 Days</span>
              <span className="text-[#979797] text-sm">Payment</span>
            </div> */}
            <div className="flex flex-col">
              <span className="text-[#979797] flex justify-end text-xs">{invoiceData?.currency}</span>
              <span className="font-light text-sm">Currency</span>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <header className="border-b-[5px] border-b-[#F6F5FA] pb-3"></header>
            <div className="flex  justify-between">
              <div className="flex gap-2"></div>
              <div className="flex gap-2">
                <div className="flex flex-col">
                  <span className="text-[#979797]  text-xs"> {invoiceData?.items.length} Total Items</span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
              <h1 className="text-sm">Line Items</h1>
            </header>
            <div className=" w-full overflow-x-auto">
              <table className=" w-full overflow-x-auto">
                <thead className="bg-subtleGray w-">
                  <tr className=" bg-unusualGray p-2.5 ">
                    <td className="text-[#11192A] p-2.5 text-xs font-bold">
                      <div className="flex">
                        <span>Items</span>
                      </div>

                    </td>
                    <td className=" p-2.5 text-xs font-bold">Description</td>
                    <td className=" p-2.5 text-xs font-bold whitespace-nowrap">Unit Price</td>
                    <td className=" p-2.5 text-xs font-bold whitespace-nowrap">Quantity</td>
                    <div className="flex flex-col">
                      <td className="text-xs font-bold">Amount</td>
                    </div>
                    <td className=" p-2.5 text-xs font-bold">Tax Rate</td>
                    <td className=" p-2.5 text-xs font-bold">Tax Amount</td>
                    <td className=" p-2.5 text-xs font-bold">Total Price</td>
                  </tr>
                </thead>
                <tbody className="  ">
                  {/* here */}
                  {invoiceData?.items.map((cell, idx) => {
                    return (
                      <tr key={idx} className=" w-full ">
                        <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap ">
                          {cell.itemName}
                        </td>
                        <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                          {cell.description}
                        </td>
                        <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                          {formatCurrency(Number(cell.unitPrice))}
                        </td>
                        <td className="text-[#11192a] text-xs font-semibold mt-2 text-center flex-nowrap whitespace-nowrap">
                          {formatCurrency(Number(cell.quantity))}
                        </td>
                        <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                          {formatCurrency(Number(cell.amount))}
                        </td>
                        <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                          {formatCurrency(Number(cell.taxRate))}
                        </td>
                        <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                          {formatCurrency(Number(cell.taxAmount))}
                        </td>
                        <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                          {formatCurrency(Number(cell.totalPrice))}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <br />
            <div className="flex justify-between">
              <div>
                <h4 className="text-lg text-[#11192A] ">
                  Receiving Funding Account Information
                </h4>
                <h5 className="text-lg text-[#11192A] ">  {
                  invoiceData?.virtualAccount?.accountNumber
                }</h5>
                <h5 className="text-lg text-[#11192A] ">{
                  invoiceData?.virtualAccount?.accountName
                }</h5>
              </div>
              <div style={{ width: 300 }}>
                <div className="flex justify-between">
                  <span className="text-sm text-[#11192A] ">Subtotal</span>
                  <span className="text-sm text-[#11192A] "> {formatCurrency(Number(invoiceData?.subTotal))}</span>
                </div>
                <div className="flex justify-between">
                  <div>
                    <span className="text-sm text-[#11192A] ">VAT</span>
                    <span className="pl-2 text-sm text-[#11192A] ">{invoiceData?.vat}%</span>
                  </div>
                  <div>
                    <span className="text-sm text-[#11192A] ">{formatCurrency(Number(invoiceData?.vatAmount))}</span>
                  </div>
                </div>
                <div className="flex justify-between">
                  <div>
                    <span className="text-sm text-[#11192A] ">Discount</span>
                    <span className="pl-2 text-sm text-[#11192A] ">{invoiceData?.discount}%</span>
                  </div>
                  <div>
                    <span className="text-sm text-[#11192A] "> {formatCurrency(Number(invoiceData?.discountAmount))}</span>
                  </div>
                </div>
                <div>
                  <hr className="border-t border-[black] mt-2 pt-2" />
                  <div className="flex justify-between">
                    <span className="text-lg text-[#11192A] ">Grand Total</span>
                    <span className="text-lg text-[#11192A] "> {formatCurrency(Number(invoiceData?.grandTotal))}</span>
                  </div>
                  <hr className="border-t border-[black] mt-2 pt-2" />
                </div>
              </div>
            </div>
          </div>
        </Card>
        <Card className="w-[38%] p-[24px] rounded-[10px]">
          <div>
            <div className="flex justify-between">
              <span className="font-bold text-[#000000]">
                Direct Funding Details
              </span>
            </div>
          </div>
          <div>
            <br />
            <div className="flex justify-between">
              <div className="flex">
                <div>
                  <img src={CocaCola} alt="" />
                </div>
                <div style={{ lineHeight: 1 }} className="flex flex-col pl-2">
                  <span>{directFundingData?.supplierCompanyName}</span>
                  <span className="text-[#959595]">Supplier</span>
                </div>
              </div>
              <div>
                <div style={{ lineHeight: 1 }} className="flex flex-col pl-2">
                  <span className="flex justify-end">{directFundingData?.discountRate}%</span>
                  <span className="text-[#959595]">Discount rate</span>
                </div>
              </div>
            </div>
            <br />
            <div className="flex justify-between">
              <div style={{ lineHeight: 1.3 }} className="flex flex-col pl-2">
                <span> {directFundingData?.currency}{formatCurrency(Number(directFundingData?.discountAmount))}</span>
                <span className="text-[#959595]">Discount Amount</span>
              </div>
              <div>
                <div style={{ lineHeight: 1.3 }} className="flex flex-col pl-2">
                  <span>{directFundingData?.earlyPaymentDate}</span>
                  <span className="flex justify-end text-[#959595]">
                    Payment Date
                  </span>
                </div>
              </div>
            </div>
            <br />
            <div className="flex justify-between">
              <div style={{ lineHeight: 1.3 }} className="flex flex-col pl-2">
                <span>{directFundingData?.currency} {formatCurrency(Number(directFundingData?.requestAmount))}</span>
                <span className="text-[#959595]">Request Amount</span>
              </div>
              <div>
                <div style={{ lineHeight: 1.3 }} className="flex flex-col pl-2">
                  <span className="flex justify-end">{directFundingData?.currency} {formatCurrency(Number(directFundingData?.fundableAmount))}</span>
                  <span className="text-[#959595]">Fundable Amount</span>
                </div>
              </div>
            </div>
          </div>
          <br />
          <hr className="text-[#959595]" />
          <br />
          <div className="flex flex-col">
            <span className="font-bold text-[#000000]">
              Notes from supplier:
            </span>
            <span className="text-[#959595]">
              {directFundingData?.noteFromSupplier}
            </span>
          </div>
          <br />
          <hr className="text-[#959595]" />
          <br />
          {/* <div className="flex flex-col">
              <span className="font-bold text-[#000000]">
                Reason for rejection
              </span>
              <span className="text-[#959595]">
                You rejected this Invoice based on Unreliable History reasons
              </span>
            </div> */}
        </Card>
      </div>
    </DashboardLayout>
  );
};
export default FunderDirectFundingDetailsRejectedSingleRequest;
