import { PAIconInventoryArrowLeft } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Card from "shared/Card";
import { motion } from "framer-motion";
import CocaCola from "../../../../assets/images/svgs/cocacola.svg";
import { useEffect, useState } from "react";
import {
  getAllInvoiceById,
  getBulkEarlyPaymentDetails,
} from "appstate/api/invoice/invoice";
import SuccessEarlyPayment from "features/invoice/fundingSection/earlyPaymentRequest/singlerequest/Modal/SuccessEarlyPayment";
import PayProgressModal from "features/payableFinaceForFunder/CloseMarket/modals/PayProgressModal";
import PinModalPayment from "features/invoice/fundingSection/earlyPaymentRequest/singlerequest/Modal/PinModal";
import AcceptModal from "shared/AcceptModal";
import FundWalletDetails from "features/wallet/fund/externalSource/fundWallet";
import ConfirmingPaymentReimbursement from "features/wallet/fund/externalSource/confirmingpaymentreimbursement";

const ReimbursementWithRecourseDetails = () => {
  const [open, setOpen] = useState(false);
  const [showPin, setShowPin] = useState(false);

  // const [showDirectFunding, setShowDirectFunding] = useState(false);
  const [otpInputs, setOtpInputs] = useState(["", "", "", ""]);
  const [transferFunds, setTransferFunds] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [progress, setProgress] = useState(0);
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { invoiceId, selectedStatus, recourse, selectedOption } =
    location.state || {};

  // console.log("==hello====", selectedOption);

  const [invoiceData, setInvoiceData] = useState(null);

  // eslint-disable-next-line
  const [error, setError] = useState(null);
  const [earlyPaymentData, setEarlyPaymentData] = useState(null);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(true);
  const [showDirectFunding, setShowDirectFunding] = useState(false);
  const [showTransferFundsUpcoming, setShowTransferFundsUpcoming] =
    useState(false);
  const [showVerifyModal, setShowVerifyModal] = useState(false);

  const handleDirectFunding = () => {
    setShowDirectFunding(!showDirectFunding);
  };

  useEffect(() => {
    const fetchInvoice = async () => {
      if (invoiceId) {
        const result = await getAllInvoiceById({ id: invoiceId });
        setInvoiceData(result?.data);
      }
    };
    fetchInvoice();
    // eslint-disable-next-line
  }, [invoiceId]);

  useEffect(() => {
    const fetchEarlyPaymentDetails = async () => {
      try {
        const result = await getBulkEarlyPaymentDetails(id);
        setEarlyPaymentData(result?.data);
      } catch (error) {
        setError(error.message || "Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchEarlyPaymentDetails();
    }
  }, [id]);

  // console.log("invoice data" , invoiceId)

  // const { state } = location?.state || {};

  function handleNavigate() {
    let path = -1;
    navigate(path);
  }

  const handlePayNow = () => {
    setOpen(!open);
  };

  const handleConfirm = (row) => {
    setOpen(false);
    setShowPin(true);
  };

  const onClosePinModal = () => {
    // setOpen(false);
    setShowPin(false);
  };

  const confirmPayment = () => {
    setShowPin(false);
    setTransferFunds(true);
    let interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prev + 10;
      });
    }, 500);
  };

  useEffect(() => {
    if (progress === 100) {
      setTransferFunds(false);
      setShowSuccess(true);
    }
  }, [progress]);

  const onCloseSuccessModal = () => {
    setShowSuccess(false);
    setProgress(0);
  };

  const handleDirectFundingConfirm = () => {
    setShowDirectFunding(false);
    setShowTransferFundsUpcoming(true);
  };

  const confirmPaymentUpcoming = () => {
    setShowTransferFundsUpcoming(false);
    setShowVerifyModal(true);
  };

  const confirmVerifyPaymentUpcoming = () => {
    setShowVerifyModal(false);
    setShowPin(true);
  };

  // const onRowClicked = () => {
  //   let path = "/supplier bulk-early-payment-details-details";
  //   navigate(path, { state: state });
  // };

  return (
    <DashboardLayout
      section="Reimbursement"
      subHeader="Track invoices due for payment Reimbursement"
    >
      {showTransferFundsUpcoming && (
        <FundWalletDetails
          invoice={true}
          isOpen={showTransferFundsUpcoming}
          onClose={() => setShowTransferFundsUpcoming(false)}
          confirmPaymentUpcoming={confirmPaymentUpcoming}
        />
      )}

      {showVerifyModal && (
        <ConfirmingPaymentReimbursement
          invoice={true}
          isOpen={true}
          onClose={confirmVerifyPaymentUpcoming}
          confirmPaymentUpcoming={confirmVerifyPaymentUpcoming}
        />
      )}

      <AcceptModal
        isOpen={showDirectFunding}
        onClose={() => setShowDirectFunding(false)}
        message={`Are you sure you want to fund this virtual account for this request ?`}
        onConfirm={handleDirectFundingConfirm}
        // loading={false}
      />
      <AcceptModal
        isOpen={open}
        onClose={() => setOpen(false)}
        message="Are you sure you would like to Pay now for this invoice ?"
        onConfirm={handleConfirm}
        // loading={false}
      />

      <PinModalPayment
        invoice={true}
        confirmPayment={confirmPayment}
        isOpen={showPin}
        onCancel={onClosePinModal}
        otpInputs={otpInputs}
        setOtpInputs={setOtpInputs}
      />
      {transferFunds && (
        <PayProgressModal progress={progress} setProgress={setProgress} />
      )}

      {showSuccess && (
        <SuccessEarlyPayment
          isOpen={showSuccess}
          onClose={onCloseSuccessModal}
          invoice={true}
          message="Early payment request successful"
        />
      )}
      <header>
        <div
          onClick={handleNavigate}
          className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
        >
          <PAIconInventoryArrowLeft />
          <p className="mt-[2px] text-sm font-normal text-lightGray">Go Back</p>
        </div>
      </header>
      <div className="flex justify-between">
        <Card className="w-[60%] p-[24px] rounded-[10px]">
          <div className=" flex  flex-col gap-3 ">
            <main className=" w-full flex justify-between">
              <div className="flex flex-col gap-3 w-1/2">
                <div className="flex">
                  <span>Reference No:</span>
                  <span className="pl-2">|RF9101010BHSHS</span>
                </div>
                <div className="flex flex-col gap-1">
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Reference No:</span>
                    <span className="text-[#979797]  text-xs">
                      0983018374BA
                    </span>
                  </div>
                  {/* <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Invoice Number:</span>
                    <span className="text-[#979797]  text-xs">INV 2002</span>
                  </div> */}
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Created Date:</span>
                    <span className="text-[#979797]  text-xs">
                      23-March-2023
                    </span>
                  </div>
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Submitted Date:</span>
                    <span className="text-[#979797]  text-xs">
                      23-March-2023
                    </span>
                  </div>
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Approved Date:</span>
                    <span className="text-[#979797]  text-xs">
                      23-March-2023
                    </span>
                  </div>
                  <div className="flex gap-x-[97px]">
                    <span className=" text-xs">Funding Date:</span>
                    <span className="text-[#979797]  text-xs">
                      23-March-2023
                    </span>
                  </div>
                  <div className="flex gap-x-[97px]">
                    <span className=" text-xs">Due Date:</span>
                    <span className="text-[#979797]  text-xs">
                      23-March-2023
                    </span>
                  </div>
                  <div className="flex gap-x-[75px]">
                    <span className=" text-xs">Issuance Date:</span>
                    <span className="text-[#979797]  text-xs">
                      23-March-2023
                    </span>
                  </div>
                </div>
              </div>

              <div className="flex flex-col">
                <motion.button
                  whileTap={{ scale: 0.9 }}
                  className="flex bg-[#06593D] py-2 text-sm justify-center gap-2 text-white rounded-[5px] items-center px-[35px]"
                  onClick={() =>
                    selectedStatus === "Liened"
                      ? handlePayNow()
                      : handleDirectFunding()
                  }
                >
                  {selectedStatus === "Liened" ? "Pay Now" : "Fund Request"}
                </motion.button>
                {selectedStatus === "Liened" && (
                  <span className="text-[#06593D]">00d:13h:55m:32s</span>
                )}
                {selectedStatus === "Upcoming" && (
                  <span className="text-[#F08000]">00d:13h:55m:32s</span>
                )}
                {selectedStatus === "Overdue" && (
                  <span className="text-red">00d:13h:55m:32s</span>
                )}
                <motion.button
                  whileTap={{ scale: 0.9 }}
                  className="mt-4 flex bg-[#D9EFD6] text-sm justify-center gap-2 text-[#2EA924] rounded-[5px] items-center px-[35px] py-1.5"
                >
                  Approved
                </motion.button>
              </div>
            </main>
          </div>
          <br />
          <div className="flex justify-between">
            <div className="flex flex-col">
              <span className="font-light  text-xs">Nestle Plc</span>
              <span className="text-[#979797] text-sm">buyer details</span>
            </div>
            <div className="flex flex-col">
              <span className="font-light   text-xs">60 Days</span>
              <span className="text-[#979797] text-sm">Payment</span>
            </div>
            <div className="flex flex-col">
              <span className="text-[#979797] flex justify-end text-xs">
                NGN
              </span>
              <span className="font-light text-sm">Currency</span>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <header className="border-b-[5px] border-b-[#F6F5FA] pb-3"></header>
            <div className="flex  justify-between">
              <div className="flex gap-2"></div>
              <div className="flex gap-2">
                <div className="flex flex-col">
                  <span className="text-[#979797]  text-xs">
                    5 Total List Items
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
              <h1 className="text-sm">Line Items</h1>
            </header>
            <div className=" w-full overflow-x-auto">
              <table className=" w-full overflow-x-auto">
                <thead className="bg-subtleGray w-">
                  <tr className=" bg-unusualGray p-2.5 ">
                    <td className="text-[#11192A] p-2.5 text-xs font-bold">
                      Item
                    </td>
                    <td className=" p-2.5 text-xs font-bold">Description</td>
                    <td className=" p-2.5 text-xs font-bold">Unit Price</td>
                    <td className=" p-2.5 text-xs font-bold">Quantity</td>
                    <td className=" p-2.5 text-xs font-bold">
                      {"Amount(Before Tax)"}
                    </td>
                    <td className=" p-2.5 text-xs font-bold">Tax Rate</td>
                    <td className=" p-2.5 text-xs font-bold">Tax Amount</td>
                    <td className=" p-2.5 text-xs font-bold">Total Price</td>
                  </tr>
                </thead>
                {invoiceData?.items?.map((item, index) => (
                  <tbody key={index}>
                    <tr
                      style={{ cursor: "pointer" }}
                      // onClick={onRowClicked}
                      className="py-[3px] px-1.5"
                    >
                      <td className="text-xs text-[#11192A] p-2.5">
                        {item.refNumber}
                      </td>
                      <td className="text-xs text-[#11192A] p-2.5">
                        {item.dueDate}
                      </td>
                      <td className="text-xs text-[#11192A] p-2.5">
                        {item.requestAmount}
                      </td>
                      <td className="text-xs text-[#11192A] p-2.5">
                        {item.discountRate}
                      </td>
                      <td className="text-xs text-[#11192A] p-2.5">
                        {item.amountBeforeTax}
                      </td>
                      <td className="text-xs text-[#11192A] p-2.5">
                        {item.discountAmount}
                      </td>
                      <td className="text-xs text-[#11192A] p-2.5">
                        {item.receivableAmount}
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
            <div className="flex justify-between">
              <div>
                <h4
                  style={{ fontSize: 13 }}
                  className="text-lg text-[#11192A] "
                >
                  Receiving Funding Account Information
                </h4>
                <h5
                  style={{ fontSize: 13 }}
                  className="text-lg text-[#11192A] "
                >
                  7662662662626
                </h5>
                <h5
                  style={{ fontSize: 13 }}
                  className="text-lg text-[#11192A] "
                >
                  Praise Akindade
                </h5>
              </div>
              <div style={{ width: 300 }}>
                <div className="flex justify-between">
                  <span
                    style={{ fontSize: 13 }}
                    className="text-sm text-[#11192A] "
                  >
                    Subtotal
                  </span>
                  <span className="text-sm text-[#11192A] ">1000000</span>
                </div>
                <div className="flex justify-between">
                  <div>
                    <span
                      style={{ fontSize: 13 }}
                      className="text-sm text-[#11192A] "
                    >
                      Vat %{" "}
                    </span>
                    <span className="pl-2 text-sm text-[#11192A] ">5.6%</span>
                  </div>
                  <div>
                    <span
                      style={{ fontSize: 13 }}
                      className="text-sm text-[#11192A] "
                    >
                      583,000
                    </span>
                  </div>
                </div>
                <div className="flex justify-between">
                  <div>
                    <span
                      style={{ fontSize: 13 }}
                      className="text-sm text-[#11192A] "
                    >
                      Discount %{" "}
                    </span>
                    <span
                      style={{ fontSize: 13 }}
                      className="pl-2 text-sm text-[#11192A] "
                    >
                      2.6%
                    </span>
                  </div>
                  <div>
                    <span
                      style={{ fontSize: 13 }}
                      className="text-sm text-[#11192A] "
                    >
                      283,000
                    </span>
                  </div>
                </div>
                <div>
                  <hr className="border-t border-[black] mt-2 pt-2" />
                  <div className="flex justify-between">
                    <span
                      style={{ fontSize: 13 }}
                      className="text-lg text-[#11192A] "
                    >
                      Grand Total
                    </span>
                    <span
                      style={{ fontSize: 13 }}
                      className="text-lg text-[#11192A] "
                    >
                      10,000,000
                    </span>
                  </div>
                  <hr className="border-t border-[black] mt-2 pt-2" />
                </div>
              </div>
            </div>
          </div>
        </Card>
        <Card className="w-[38%] p-[24px] rounded-[10px]">
          <div>
            <div className="flex justify-between">
              <span className="font-bold text-[#000000]">{selectedOption}</span>
              <span style={{ fontSize: 12 }} className="text-[#000000]">
                {recourse ? "with recourse" : "without recourse"}
              </span>
            </div>
          </div>
          <div>
            <br />
            <div className="flex justify-between">
              <div className="flex">
                <div>
                  <img src={CocaCola} alt="" />
                </div>
                <div style={{ lineHeight: 1 }} className="flex flex-col pl-2">
                  <span>Coca Cola</span>
                  <span className="text-[#959595]">
                    {selectedOption === "Direct Funding Request"
                      ? "Supplier"
                      : "Buyer"}
                  </span>
                </div>
              </div>
              <div>
                <div style={{ lineHeight: 1 }} className="flex flex-col pl-2">
                  <span className="text-[#959595] flex justify-end">1.5%</span>
                  <span className="text-[#959595]">
                    {/* Discount rate */}
                    {selectedOption === "Direct Funding Request"
                      ? "  Discount rate"
                      : "Funder's locked rate"}
                  </span>
                </div>
              </div>
            </div>
            <br />
            <div className="flex justify-between">
              <div style={{ lineHeight: 1.3 }} className="flex flex-col pl-2">
                <span>₦90,000</span>
                <span className="text-[#959595]">Discount Amount</span>
              </div>
              <div>
                <div style={{ lineHeight: 1.3 }} className="flex flex-col pl-2">
                  <span className="flex justify-end">
                    23-DEC-2025 (90 Days)
                  </span>
                  <span className="flex justify-end text-[#959595]">
                    {selectedOption === "Direct Funding Request"
                      ? " Early Payment Date"
                      : "Request Date"}
                  </span>
                </div>
              </div>
            </div>
            <br />
            <div className="flex justify-between">
              <div style={{ lineHeight: 1.3 }} className="flex flex-col pl-2">
                <span className="text-[#959595]">₦70000</span>
                <span className="text-[#959595]">
                  {selectedOption === "Direct Funding Request"
                    ? " Request Amount"
                    : "  Total Request Amount"}
                </span>
              </div>
              <div>
                <div style={{ lineHeight: 1.3 }} className="flex flex-col pl-2">
                  <span className="flex justify-end">
                    {selectedOption === "Direct Funding Request"
                      ? " ₦65000 Request"
                      : "Bid Status"}
                  </span>
                  <span className="text-[#959595]">
                    {selectedOption === "Direct Funding Request"
                      ? " Payable Amount"
                      : "Bid ended"}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <br />
          <hr className="text-[#959595]/30" />
          <br />
          <div className="flex flex-col">
            <span className="font-bold text-[#000000]">
              Funder's Personal Information
            </span>
            <span className="text-[#959595]">
              {earlyPaymentData?.notesFromSupplier}
            </span>
          </div>

          <br />
          {/* <hr className="text-[#959595]" /> */}
          <br />

          <div>
            <div>
              <div className="flex flex-col">
                <span style={{ color: "grey" }}>Funders name</span>
                <span>Dangote Group LTD</span>
              </div>

              <div className="flex justify-between pt-2">
                <div className="flex flex-col">
                  <span style={{ color: "grey" }}>Email</span>
                  <span>www.skyrunltd.com</span>
                </div>
                <div className="flex flex-col">
                  <span style={{ color: "grey" }}>Contact Number</span>
                  <span>+2348192828919</span>
                </div>
              </div>

              <div className="flex justify-between pt-2 w-[93%]">
                <div className="flex flex-col">
                  <span style={{ color: "grey" }}>Account Number</span>
                  <span>88718718181</span>
                </div>
                <div className="flex flex-col">
                  <span style={{ color: "grey" }}>Bank Name</span>
                  <span>PayEdge MFB</span>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </DashboardLayout>
  );
};
export default ReimbursementWithRecourseDetails;
