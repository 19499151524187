import { useState, useEffect } from "react";
import emptyBid from "../../../../../assets/images/svgs/empty-bid.png";
import cocaCola from "../../../../../assets/images/svgs/cocacola.svg";

const BidDetails = () => {
  const [timeLeft, setTimeLeft] = useState({
    days: 21,
    hours: 13,
    minutes: 55,
    seconds: 10,
  });

  const [isActive, setIsActive] = useState("history");

  const invitedFunderData = [
    {
      img: cocaCola,
      name: "Coca-Cola",
      number: "+235830495857",
      email: "www.skyflocklimited.com",
    },
    {
      img: cocaCola,
      name: "Coca-Cola",
      number: "+235830495857",
      email: "www.skyflocklimited.com",
    },
    {
      img: cocaCola,
      name: "Coca-Cola",
      number: "+235830495857",
      email: "www.skyflocklimited.com",
    },
    {
      img: cocaCola,
      name: "Coca-Cola",
      number: "+235830495857",
      email: "www.skyflocklimited.com",
    },
    {
      img: cocaCola,
      name: "Coca-Cola",
      number: "+235830495857",
      email: "www.skyflocklimited.com",
    },
    {
      img: cocaCola,
      name: "Coca-Cola",
      number: "+235830495857",
      email: "www.skyflocklimited.com",
    },
  ];

  const viewedFunderData = [
    {
      img: cocaCola,
      name: "Coca-Cola",
      number: "+235830495857",
      email: "www.skyflocklimited.com",
    },
    {
      img: cocaCola,
      name: "Coca-Cola",
      number: "+235830495857",
      email: "www.skyflocklimited.com",
    },
    {
      img: cocaCola,
      name: "Coca-Cola",
      number: "+235830495857",
      email: "www.skyflocklimited.com",
    },
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev.seconds > 0) {
          return { ...prev, seconds: prev.seconds - 1 };
        } else if (prev.minutes > 0) {
          return { ...prev, seconds: 59, minutes: prev.minutes - 1 };
        } else if (prev.hours > 0) {
          return { ...prev, minutes: 59, seconds: 59, hours: prev.hours - 1 };
        } else if (prev.days > 0) {
          return {
            ...prev,
            hours: 23,
            minutes: 59,
            seconds: 59,
            days: prev.days - 1,
          };
        } else {
          clearInterval(timer);
          return prev;
        }
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="bg-white w-full rounded-[10px] pt-5 pb-24 h-[100%]">
      <div className="h-[15%]">
        <h2 className="text-lg px-6 font-semibold text-gray-800 pb-4 border-b-[1px] border-b-slate-300 text-primaryColor">
          Bid details for this invoice
        </h2>
        <div className="flex justify-between text-sm text-gray-600 mt-4 px-6">
          <span
            onClick={() => {
              setIsActive("history");
            }}
            className={
              isActive === "history"
                ? "text-secondaryColor border-b-[1px] border-secondaryColor"
                : "text-[#959595]"
            }
          >
            Bid history: 0
          </span>
          <span
            onClick={() => {
              setIsActive("invited");
            }}
            className={
              isActive === "invited"
                ? "text-secondaryColor border-b-[1px] border-secondaryColor"
                : "text-[#959595]"
            }
          >
            Funders invited: 6
          </span>
          <span
            onClick={() => {
              setIsActive("viewed");
            }}
            className={
              isActive === "viewed"
                ? "text-secondaryColor border-b-[1px] border-secondaryColor"
                : "text-[#959595]"
            }
          >
            Viewed by funders: 3
          </span>
        </div>
      </div>
      {isActive === "history" && (
        <div className="flex flex-col justify-center items-center mb-6 h-[65%]">
          <img src={emptyBid} alt="No Data" className="h-80 w-auto" />
          <div className="text-center text-3xl font-bold text-gray-800 mb-2">
            {timeLeft.days.toString().padStart(2, "0")} :{" "}
            {timeLeft.hours.toString().padStart(2, "0")} :{" "}
            {timeLeft.minutes.toString().padStart(2, "0")} :{" "}
            {timeLeft.seconds.toString().padStart(2, "0")}
          </div>
          <p className="text-center text-gray-500 mb-4">
            Count down to early payment date
          </p>
        </div>
      )}

      {isActive === "history" && (
        <div className="h-[15%] text-center">
          <button className="w-[50%] h-[60px] border-[#DE1515] text-[#DE1515] font-semibold py-2 rounded-lg border border-red-600 hover:bg-red-200 transition">
            Withdraw Request
          </button>
          <p className="text-center text-gray-400 mt-2">
            Do you want to cancel the request?
          </p>
        </div>
      )}

      {isActive === "invited" && (
        <div className="flex flex-col justify-start px-5 mb-6 h-[65%]">
          {invitedFunderData.map((item, index) => (
            <div key={index} className="flex justify-between items-center mb-4">
              <div className="flex items-center my-2">
                <img
                  src={item.img}
                  alt="Funder"
                  className="w-10 h-10 rounded-full mr-4"
                />
                <div>
                  <p className="text-[16px] font-regular text-gray-600">
                    {item.name}
                  </p>
                  <p className="text-[12px] font-regular text-zinc-400">
                    Funder
                  </p>
                </div>
              </div>

              <div className="text-center">
                <p className="text-[14px] ">{item.number}</p>
                <p className="text-[12px] text-zinc-400">Contact number</p>
              </div>

              <div className="text-right">
                <p className="text-sm text-gray-600">{item.number}</p>
                <p className="text-[12px] text-zinc-400">Email</p>
              </div>
            </div>
          ))}
        </div>
      )}

      {isActive === "viewed" && (
        <div className="flex flex-col justify-start px-5 mb-6 h-[65%]">
          {viewedFunderData.map((item, index) => (
            <div key={index} className="flex justify-between items-center mb-4">
              <div className="flex items-center my-2">
                <img
                  src={item.img}
                  alt="Funder"
                  className="w-10 h-10 rounded-full mr-4"
                />
                <div>
                  <p className="text-[16px] font-regular text-gray-600">
                    {item.name}
                  </p>
                  <p className="text-[12px] font-regular text-zinc-400">
                    Funder
                  </p>
                </div>
              </div>

              <div className="text-center">
                <p className="text-[14px] ">{item.number}</p>
                <p className="text-[12px] text-zinc-400">Contact number</p>
              </div>

              <div className="text-right">
                <p className="text-sm text-gray-600">{item.number}</p>
                <p className="text-[12px] text-zinc-400">Email</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default BidDetails;
