// import { setSelectedAccounts } from "appstate/wallet/walletSlice";
// import { PAIconPaymnet } from "assets/images/svgs";
import React from "react";
import { useSelector } from "react-redux";
import AccountList from "./AccountCard";

const VirtualAccountOption = ({ isOpen, onClose, onSubmit }) => {
  const { allVirtualAccountsData } = useSelector((state) => state?.wallet);


  const handleSubmit = () => {
    onSubmit();
  };

  const accountData = Array.isArray(allVirtualAccountsData?.data)
    ? allVirtualAccountsData?.data?.map((account) => ({
        id: account?.id,
        name: account?.accountName,
        accountNumber: account?.accountNumber,
        bankName: account?.provider || "Payedge",
        accountType: "Virtual Account",
        amount: account?.balance,
      }))
    : [];

  if (!isOpen) return null;

  return (
    <>
      {/* {showOptionModal && ( */}
      <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg rounded-md z-[1000]">
        <div className="bg-white rounded-md max-w-xs md:max-w-lg w-full pb-5">
          <div className="flex justify-between items-center mb-4 bg-[#FAFAFA]">
            <p className="text-black text-base font-bold p-3 px-5 rounded-sm italic">
              Fund Account
            </p>
            <button
              onClick={onClose}
              className="text-black font-sans text-xl cursor-pointer flex justify-center items-center"
            >
              &times; <span className="text-sm mx-2 mt-1">Close</span>
            </button>
          </div>

          {/* ACCOUNTS */}
          <div>
            <div className="w-full px-7">
              <p className="text-zinc-600 text-sm font-extrabold flex justify-center italic">
                Select which account to transfer from
              </p>
              <h1 className="text-zinc-600 text-base font-extrabold mt-5 italic">
                Accounts
              </h1>
              {/* ACCOUNTS CARD */}

              <AccountList
                accountData={accountData}
                handleSubmit={handleSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VirtualAccountOption;
