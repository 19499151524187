import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import LoadingScreen from "shared/LoadingScreen";
import { allInvoiceById } from "appstate/invoice/invoiceSlice";
import InvoiceDetails from "./InvoiceDetails";
import { PAIconArrowLeftGreen } from "assets/images/svgs";

const Details = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { invoiceIdData, isLoading } = useSelector((state) => state?.invoice);
  const [activeTab, setActiveTab] = useState("transaction");

  useEffect(() => {
    dispatch(allInvoiceById({ id }));
  }, [dispatch, id]);

  // Calculate the payment based on the due date and created date
  const calculatePayment = (dueDate, createdDate) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const dueDateObj = new Date(dueDate);
    const createdDateObj = new Date(createdDate?.split(" ")[0]);
    const diffDays = Math.round(
      Math.abs((dueDateObj - createdDateObj) / oneDay)
    );
    return `${diffDays} Days`;
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  const detailsData = [
    {
      label: "Transfer from",
      value: "Elo",
    },
    {
      label: "Transfer to",
      value: "Wale",
    },
    {
      label: "Status",
      value: "Not Paid",
      statusColor: "text-[#07593D]",
    },
    {
      label: "Payment Date",
      value: "Oct 21, 2024 | 02:43PM",
      statusColor: "text-[#000]",
    },
    {
      label: "Transaction ID",
      value: "DbuibYYBH878huhi8UUI89ygt2132h",
      statusColor: "text-[#0076EC]",
    },
    {
      label: "Collection Type",
      value: "Payment",
    },
    {
      label: "Collection Method",
      value: "PayEdge Wallet",
      statusColor: "text-[#07593D]",
    },
    {
      label: "Funding Channel",
      value: "Open Market",
      statusColor: "text-[#F08000]",
    },
    {
      label: "Description from Sender",
      value: "Payment sent. Appreciate our ongoing collaboration. Regards,",
    },
  ];

  const data = {
    invoiceNumber: `INV-${invoiceIdData?.data?.invoiceNumber}` || "",
    referenceNumber: invoiceIdData?.data?.invoiceReference || "",
    createdDate: invoiceIdData?.data?.createdDate?.slice(0, 10) || "",
    dueDate: invoiceIdData?.data?.dueDate || "",
    payment: calculatePayment(
      invoiceIdData?.data?.dueDate,
      invoiceIdData?.data?.createdDate
    ),
    currency: invoiceIdData?.data?.currency || "",
    supplier: invoiceIdData?.data?.supplierCompanyName || "",
    buyer: invoiceIdData?.data?.buyerCompanyName || "",
    lineItems:
      invoiceIdData?.data?.items?.map((item) => ({
        itemNumber: item?.itemNumber || "",
        poNumber: item.poNumber || "",
        productNumber: item.productNumber || "",
        productDescription: item.productDescription || "",
        quantity: item.quantity || 0,
        unitPrice: item.unitPrice || "",
        extendedAmount: item.extendedAmount || "",
      })) || [],
    attachments: [
      {
        name: "DOC-29384.pdf",
        fileType: "PDF",
        uploadDateTime: "2021-09-14 13:25",
        downloadUrl: "https://example.com/doc-29384.pdf",
      },
    ],
  };

  return (
    <>
      {/* HEADER */}
      <header className="rounded-md w-full bg-white mb-4 flex items-center justify-between px-5">
        <Link to="/collections" className="flex items-center gap-1 py-3">
          <PAIconArrowLeftGreen />
          <span className="text-primaryColor cursor-pointer">Go Back</span>
        </Link>
      </header>
      <div className="grid lg:grid-cols-[60%,40%] space-x-5">
        {/* 60% */}
        <InvoiceDetails data={data} showSendReminderButton={false} />
        {/* 40% */}
        <div className="bg-white rounded-[10px] gap-8 px-6 pt7 pb-24">
          <div>
            <div className="flex gap-10 my-5">
              <h2
                onClick={() => setActiveTab("transaction")}
                className={`cursor-pointer text-gray ${
                  activeTab === "transaction"
                    ? "text-primaryColor border-b-2 border-primaryColor"
                    : ""
                }`}
              >
                Transaction Details
              </h2>
              <h2
                onClick={() => setActiveTab("channel")}
                className={`cursor-pointer text-gray ${
                  activeTab === "channel"
                    ? "text-primaryColor border-b-2 border-primaryColor"
                    : ""
                }`}
              >
                Channel Details
              </h2>
            </div>
            {/* Render content based on active tab */}
            {activeTab === "transaction" && (
              <>
                <div className="flex justify-between items-center mb-4">
                  <h2 className="fontsemibold text-lg leading-[30px]">
                    Collection Transaction Details
                  </h2>
                  <button className="border border-gray300 rounded px-3 py-1.5">
                    Export
                  </button>
                </div>

                <div className="flex justify-between my-3 border-b-[3px] border-b-[#F6F5FA] pb-5">
                  <div>
                    <h5 className="text-base font-normal">Amount</h5>
                    <h2 className="text-[#07593D] text-2xl font-normal">
                      +9,000,000 NGN
                    </h2>
                  </div>
                </div>
                <div className="mt-8">
                  <div className="flex flex-col gap-3">
                    {detailsData.map((item, index) => (
                      <div key={index} className="mb-4 flex justify-between">
                        <div>
                          <p className="text-[#222] font-medium text-sm">
                            {item.label}
                          </p>
                        </div>
                        <div className="flex-1 ml-4 max-h-[50px]">
                          <p className="text-[#979797] font-medium text-sm flex justify-end text-right">
                            <span className={item.statusColor}>
                              {item.value}
                            </span>
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}

            {activeTab === "channel" && (
              <div className="h-[15%]">
                <div className="px6 py6 pb-4 border-b-[1px] border-b-slate-300 flex justify-between items-center">
                  <div>
                    <p className="text-gray600 text-xs">Request Amount</p>
                    <span className="text-sm">N18,449,000</span>
                  </div>
                  <div>
                    <p className="text-gray600 text-xs">Receivable Amount</p>
                    <span className="flex justify-end text-sm">N9,449,000</span>
                  </div>
                </div>

                <div className="px6 py-6 pb-4 border-b-[1px] border-b-slate-300 flex flex-col">
                  <div className="flex justify-between items-center">
                    <div>
                      <p className="text-gray600 text-xs">Payment Date</p>
                      <span className="text-sm">20/09/2024</span>
                    </div>
                    <div>
                      <p className="text-gray600 text-xs">Request Date</p>
                      <span className="flex justify-end text-sm">
                        20/10/2024
                      </span>
                    </div>
                  </div>
                  <div className="flex justify-between items-center mt-2">
                    <div>
                      <p className="text-gray600 text-xs">Lock Rate Amount</p>
                      <span className="text-sm">N18,449,000</span>
                    </div>
                    <div>
                      <p className="text-gray600 text-xs">Funder's Lock Rate</p>
                      <span className="flex justify-end text-sm">
                        3.4%{" "}
                        <span className="text-primaryColor">(Recourse)</span>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="px6 py-6 pb-4 border-b-[1px] border-b-slate-300">
                  <p className="text-xs text-gray600">Bid Status</p>
                  <p className="text-primaryColor">Bid ended</p>
                </div>

                <div className="px6 py-6 pb-4">
                  <h2 className="font-bold ">Funder Personal Information</h2>
                  <div className="my-3">
                    <p className="text-xs text-gray600">Funder's name</p>
                    <p className="textprimaryColor">Dangote Groups LTD.</p>
                  </div>
                  <div className="flex justify-between items-center">
                    <div>
                      <p className="text-xs text-gray600">Email</p>
                      <p className="text-sm">DangoteGroupsLTD@gmail.com</p>
                    </div>
                    <div>
                      <p className="text-xs text-gray600 flex justify-end">
                        Contact number
                      </p>
                      <p className="text-sm">0908686545</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Details;
