import React, { useEffect, useState, Suspense } from "react";
import FunderOpenmarket from "./components/funder_openmarket";
import DashboardLayout from "layout/dashboardlayout";
import FunderInvoice from "./components/funder_invoice";
import FunderCard from "./components/funder_card";
import FunderTable from "./components/funder_table";
import OverviewHeader from "shared/overviewHeader";
// import { buyerCreateTransPin } from "appstate/buyer/buyerSlice";
import { funderCreateTransPin } from "appstate/funder/funderSlice";
import { useDispatch } from "react-redux";
import { setNewToDashBoard } from "features/onboarding/supplier/supplierVerifcationSlice/verificationSlice";
import { useSelector } from "react-redux";
import { useKeycloak } from "@react-keycloak/web";

const CreateTransPinModal = React.lazy(() =>
  import("shared/createTransPinModal/CreateTransPinModal")
);
const ConfirmTransPinModal = React.lazy(() =>
  import("shared/createTransPinModal/ConfirmTransPinModal")
);
const CreateTransPinSucessModal = React.lazy(() =>
  import("shared/createTransPinModal/CreatTranPinSucessModal")
);
const VirtualAccountSucessModal = React.lazy(() =>
  import("shared/createTransPinModal/VirtualAccountSucessModal")
);

const FunderDashboard = () => {
  const [pinOpen, setPinOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [createdsucess, setCreatedsucess] = useState(false);
  const [virtualAccountCreated, setVirtualAccountCreated] = useState(false);
  const { isLoading } = useSelector((state) => state.funder);
  const dispatch = useDispatch();
  const { newToDashBoard } = useSelector(
    (state) => state?.verificationSupplier
  );

  const [pinstate, setPinState] = useState("");

  const handleNextPin = () => {
    setPinOpen(false);
    setConfirm(true);
  };
  const handleConfirm = () => {
    setConfirm(false);
    setCreatedsucess(true);
  };
  const handlepinSucess = () => {
    setCreatedsucess(false);
    setVirtualAccountCreated(true);
  };
  useEffect(() => {
    if (newToDashBoard) {
      setPinOpen(true);
    }
    // Clean up sessionStorage if necessary

    // Clean up sessionStorage if necessary
    sessionStorage.removeItem("activeTab");
    sessionStorage.removeItem("searchQuery");
    sessionStorage.removeItem("activeTabSupplier");
    sessionStorage.removeItem("searchQuerySupplier");
  }, [newToDashBoard]);

  const { keycloak } = useKeycloak();
  const { given_name } = keycloak?.idTokenParsed;
  return (
    <DashboardLayout subHeader="Here is what is happening in your finance">
      <Suspense fallback={"loading ..."}>
        <CreateTransPinModal
          isOpen={pinOpen}
          disabled={true}
          onClose={() => setPinOpen(false)}
          // handleNext={handleNextPin}
          onSubmit={(pin) => {
            setPinState(pin);
            handleNextPin();
          }}
        />
      </Suspense>
      <Suspense fallback={"loading ..."}>
        <ConfirmTransPinModal
          isOpen={confirm}
          onClose={() => setConfirm(false)}
          isLoading={isLoading}
          onSubmit={(pin) => {
            const body = {
              transactionPin: pinstate,
              confirmTransactionPin: pin,
            };

            dispatch(funderCreateTransPin(body)).then((data) => {
              if (
                data?.payload?.message === "Transaction PIN already exists."
              ) {
                dispatch(setNewToDashBoard(false));
              }
              if (data?.payload?.status === 200) {
                dispatch(setNewToDashBoard(false));

                handleConfirm();
              }
            });
          }}
          // onSubmit={(pin) => {
          //   handleConfirm();
          // }}
        />
      </Suspense>
      <Suspense fallback={"loading ..."}>
        <CreateTransPinSucessModal
          isOpen={createdsucess}
          onClose={handlepinSucess}
          message={"Your transaction PIN has been"}
          message2={" successfully created."}
        />
      </Suspense>
      <Suspense fallback={"loading ..."}>
        <VirtualAccountSucessModal
          isOpen={virtualAccountCreated}
          onClose={() => setVirtualAccountCreated(false)}
          userName={given_name ? given_name : ""}
          collectionType={"collection"}
        />
      </Suspense>

      <OverviewHeader />
      <div className="w-full flex flex-wrap flex-col-reverse md:flex-row">
        {/* INVOICE */}
        <section className="w-full md:w-1/2">
          <FunderOpenmarket />

          <FunderInvoice />
        </section>

        {/* SECOND SECTION */}
        <section className="w-full md:w-1/2">
          <div className="flex-1 items-center p4 pt-5 md:pb-6">
            <div className="flex md:flex-row md:items-center items-start md:justify-end justify-between lg:mb-4 mb4 md:mb0 md:ml-4">
              <button className="rounded-md border border-green-800 flex items-center lg:p-2 p-2 gap-2 text-green-800 mr-5 transition duration-300 ease-in-out transform hover:scale-105">
                <img src="/green-acct.png" alt="#" />
                <span className="hidden md:inline">Manage Suppliers</span>
                <span className="md:hidden text-sm">Suppliers</span>
              </button>
              <button className="rounded-md bg-green-800 flex items-center lg:p-2 p-2 gap-2 text-white transition duration-300 ease-in-out transform hover:scale-105">
                <img src="/white-acct.svg" alt="#" />
                <span className="hidden md:inline">Manage Buyers</span>
                <span className="md:hidden text-sm">Buyers</span>
              </button>
            </div>
          </div>

          <FunderCard />
          <FunderTable />
        </section>
      </div>
    </DashboardLayout>
  );
};

export default FunderDashboard;
