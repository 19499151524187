import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  approveExitRequest,
  createPfp,
  declineExitRequest,
  getAllBuyerPfp,
  getAllBuyerPfpInvoices,
  invoiceImport,
  viewBuyerPfpById,
} from "appstate/api/buyer/buyerpfp";
import { buyersDasboard } from "appstate/api/buyer/dashboard";
import { getAllearlyPayments } from "appstate/api/buyer/earlyPayment";

import {
  documentUpload,
  updateUploadedDocumentById,
  deleteUploadedDocumentById,
  getUploadedDocumentById,
  getUploadedDocuments,
} from "appstate/api/documentUpload";

import {
  earlyPaymentApproveOrDecline,
  getAllNewEarlyPaymentRequest,
} from "appstate/api/buyer/earlyPayment";
import {
  allBuyerNotifications,
  notificationById,
} from "appstate/api/buyer/notification";
import {
  SupplierSendOnboardingInviteToFunderOrBuyer,
  buyerAcceptSupplierRequest,
  buyerRejectSupplierRequest,
  buyerSendOnboardingInviteToFunder,
  // buyerSendOnboardingInviteToSupplier,
  buyerSendRequestToFunder,
  buyerSendRequestToSupplier,
  buyersAssociatePendingRequest,
  disassociateBuyerFunder,
  disassociateBuyerSupplier,
  getAllBuyerAssociatedFunders,
  getAllBuyerAssociatedSuppliers,
} from "appstate/api/buyer/relationshipManagement";
import { getAllFunders } from "appstate/api/funder";
import { createTransPin } from "appstate/api/transactionPin";

const initialState = {
  isLoading: false,
  declineLoading: false,
  buyerDashboardData: [],
  pendingAssociateRequestData: [],
  buyerAssociatedSuppliersData: [],
  buyerAssociatedFundersData: [],
  associatedSupplierId: null,
  associatedFunderId: null,
  associateLoading: false,
  inviteLoading: false,
  disAssociateLoading: false,
  notifications: [],
  notification: null,
  createPfpLoading: false,
  requestLoading: false,
  allBuyerPfpData: [],
  importLoading: false,
  viewPfpByIdData: [],
  allPfpInvoicesData: [],
  pfpId: null,
  allEarlyPAymentsData: [],
  getInvoiceId: null,
  viewAllUploadLoadDocsData: [],
  viewAllUploadLoadDocByIdData: {},
  allNewEarlyPaymentRequestData: [],
  invoiceIds: [],
  supplierIds: [],
  invoiceImport: [],
};

export const getBuyersDashboard = createAsyncThunk(
  "buyer/getBuyersDashboard",
  buyersDasboard
);

export const getBuyerPendingAssociateRequest = createAsyncThunk(
  "buyer/getBuyerPendingAssociateRequest",
  buyersAssociatePendingRequest
);

export const acceptSupplierRequest = createAsyncThunk(
  "buyer/acceptSupplierRequest",
  buyerAcceptSupplierRequest
);

export const allBuyerAssociatedSuppliers = createAsyncThunk(
  "buyer/allBuyersAssociatedSuppliers",
  getAllBuyerAssociatedSuppliers
);

export const onboardingInviteSupplier = createAsyncThunk(
  "buyer/onboardingInviteSupplier",
  SupplierSendOnboardingInviteToFunderOrBuyer
);

export const buyerRequestToAssociateWithFunder = createAsyncThunk(
  "buyer/buyerRequestToAssociateWithFunder",
  buyerSendRequestToFunder
);

export const buyerRequestToAssociateWithSupplier = createAsyncThunk(
  "buyer/buyerRequestToAssociateWithSupplier",
  buyerSendRequestToSupplier
);

export const buyerOnboardingInviteToFunder = createAsyncThunk(
  "buyer/buyerOnboardingInviteToFunder",
  buyerSendOnboardingInviteToFunder
);

export const allBuyerAssociatedFunders = createAsyncThunk(
  "buyer/allBuyerAssociatedFunders",
  getAllBuyerAssociatedFunders
);

export const buyerSupplierDisassociate = createAsyncThunk(
  "buyer/buyerSupplierDisassociate",
  disassociateBuyerSupplier
);

export const buyerFunderDisassociate = createAsyncThunk(
  "buyer/buyerFunderDisassociate",
  disassociateBuyerFunder
);

export const buyerRejectRequestSupplier = createAsyncThunk(
  "buyer/buyerRejectRequestSupplier",
  buyerRejectSupplierRequest
);

export const getAllBuyerNotifications = createAsyncThunk(
  "buyer/getAllBuyerNotifications",
  allBuyerNotifications
);

export const getNotificationById = createAsyncThunk(
  "buyer/getNotificationById",
  notificationById
);

export const creatNewPfp = createAsyncThunk("buyer/creatNewPfp", createPfp);

export const allBuyerPfp = createAsyncThunk(
  "buyer/allBuyerPfp",
  getAllBuyerPfp
);

export const buyerApproveExitRequest = createAsyncThunk(
  "buyer/buyerApproveExitRequest",
  approveExitRequest
);

export const buyerDeclineExitRequest = createAsyncThunk(
  "buyer/buyerDeclineExitRequest",
  declineExitRequest
);

export const importInvoice = createAsyncThunk(
  "buyer/importInvoice",
  invoiceImport
);

export const buyerViewPfpById = createAsyncThunk(
  "buyer/buyerViewPfpById",
  viewBuyerPfpById
);

export const allBuyerPfpInvoices = createAsyncThunk(
  "buyer/allBuyerPfpInvoices ",
  getAllBuyerPfpInvoices
);

export const allEarlyPayments = createAsyncThunk(
  "buyer/allEarlyPayments",
  getAllearlyPayments
);

export const uploadDocument = createAsyncThunk(
  "buyer/documentUpload",
  documentUpload
);
export const viewAllUploadLoadDocs = createAsyncThunk(
  "buyer/getUploadedDocuments",
  getUploadedDocuments
);
export const viewAllUploadLoadDocById = createAsyncThunk(
  "buyer/ getUploadedDocumentById",
  getUploadedDocumentById
);

export const removeUploadedDocumentById = createAsyncThunk(
  "buyer/deleteUploadedDocumentById",
  deleteUploadedDocumentById
);
export const editUploadedDocumentById = createAsyncThunk(
  "buyer/updateUploadedDocumentById",
  updateUploadedDocumentById
);
export const allNewEarlyPaymentRequest = createAsyncThunk(
  "buyer/allNewEarlyPaymentRequest",
  getAllNewEarlyPaymentRequest
);

export const approveOrDeclineEarlyPayment = createAsyncThunk(
  "buyer/approveOrDeclineEarlyPayment",
  earlyPaymentApproveOrDecline
);

export const fetchAllFunders = createAsyncThunk(
  "buyer/fetchAllFunders",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getAllFunders();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const buyerCreateTransPin = createAsyncThunk(
  "buyer/createTransPin",
  createTransPin
);

export const buyerSlice = createSlice({
  name: "buyer",
  initialState,
  reducers: {
    getAssociatedSupplierId: (state, action) => {
      state.associatedSupplierId = action.payload;
    },
    getAssociatedFunderId: (state, action) => {
      state.associatedFunderId = action.payload;
    },
    getPfpId: (state, action) => {
      state.pfpId = action.payload;
    },
    getInvoiceId: (state, action) => {
      state.getInvoiceId = action.payload;
    },
    getInvoiceIds: (state, action) => {
      state.invoiceIds = action.payload;
    },
    getSupplierIds: (state, action) => {
      state.supplierIds = action.payload;
    },
    getInvoices: (state, action) => {
      state.invoiceImport = action.payload;
    },
    updateEarlyPaymentsData: (state, action) => {
      const newData = action.payload;
      state.allEarlyPAymentsData.push(...newData);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBuyersDashboard.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getBuyersDashboard.fulfilled, (state, action) => {
      state.isLoading = false;
      state.buyerDashboardData = action.payload;
    });
    builder.addCase(getBuyersDashboard.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getBuyerPendingAssociateRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getBuyerPendingAssociateRequest.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.pendingAssociateRequestData = action.payload;
      }
    );
    builder.addCase(getBuyerPendingAssociateRequest.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(acceptSupplierRequest.pending, (state) => {
      state.associateLoading = true;
    });
    builder.addCase(acceptSupplierRequest.fulfilled, (state) => {
      state.associateLoading = false;
    });
    builder.addCase(acceptSupplierRequest.rejected, (state) => {
      state.associateLoading = false;
    });

    builder.addCase(allBuyerAssociatedSuppliers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allBuyerAssociatedSuppliers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.buyerAssociatedSuppliersData = action.payload;
    });
    builder.addCase(allBuyerAssociatedSuppliers.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(onboardingInviteSupplier.pending, (state) => {
      state.inviteLoading = true;
    });
    builder.addCase(onboardingInviteSupplier.fulfilled, (state) => {
      state.inviteLoading = false;
    });
    builder.addCase(onboardingInviteSupplier.rejected, (state) => {
      state.inviteLoading = false;
    });
    builder.addCase(buyerRequestToAssociateWithFunder.pending, (state) => {
      state.associateLoading = true;
    });
    builder.addCase(buyerRequestToAssociateWithFunder.fulfilled, (state) => {
      state.associateLoading = false;
    });
    builder.addCase(buyerRequestToAssociateWithFunder.rejected, (state) => {
      state.associateLoading = false;
    });
    builder.addCase(buyerRequestToAssociateWithSupplier.pending, (state) => {
      state.associateLoading = true;
    });
    builder.addCase(buyerRequestToAssociateWithSupplier.fulfilled, (state) => {
      state.associateLoading = false;
    });
    builder.addCase(buyerRequestToAssociateWithSupplier.rejected, (state) => {
      state.associateLoading = false;
    });
    builder.addCase(buyerOnboardingInviteToFunder.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(buyerOnboardingInviteToFunder.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(buyerOnboardingInviteToFunder.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(allBuyerAssociatedFunders.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allBuyerAssociatedFunders.fulfilled, (state, action) => {
      state.isLoading = false;
      state.buyerAssociatedFundersData = action.payload;
    });
    builder.addCase(allBuyerAssociatedFunders.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(buyerSupplierDisassociate.pending, (state) => {
      state.disAssociateLoading = true;
    });
    builder.addCase(buyerSupplierDisassociate.fulfilled, (state) => {
      state.disAssociateLoading = false;
    });
    builder.addCase(buyerSupplierDisassociate.rejected, (state) => {
      state.disAssociateLoading = false;
    });
    builder.addCase(buyerFunderDisassociate.pending, (state) => {
      state.disAssociateLoading = true;
    });
    builder.addCase(buyerFunderDisassociate.fulfilled, (state) => {
      state.disAssociateLoading = false;
    });
    builder.addCase(buyerFunderDisassociate.rejected, (state) => {
      state.disAssociateLoading = false;
    });
    builder.addCase(buyerRejectRequestSupplier.pending, (state) => {
      state.declineLoading = true;
    });
    builder.addCase(buyerRejectRequestSupplier.fulfilled, (state) => {
      state.declineLoading = false;
    });
    builder.addCase(buyerRejectRequestSupplier.rejected, (state) => {
      state.declineLoading = false;
    });
    builder.addCase(getAllBuyerNotifications.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllBuyerNotifications.fulfilled, (state, action) => {
      state.isLoading = false;
      state.notifications = action.payload;
    });
    builder.addCase(getAllBuyerNotifications.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getNotificationById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getNotificationById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.notification = action.payload;
    });
    builder.addCase(getNotificationById.rejected, (state) => {
      state.isLoading = false;
    });

    // pfp section
    builder.addCase(creatNewPfp.pending, (state) => {
      state.createPfpLoading = true;
    });
    builder.addCase(creatNewPfp.fulfilled, (state) => {
      state.createPfpLoading = false;
    });
    builder.addCase(creatNewPfp.rejected, (state) => {
      state.createPfpLoading = false;
    });

    builder.addCase(allBuyerPfp.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allBuyerPfp.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allBuyerPfpData = action.payload;
    });
    builder.addCase(allBuyerPfp.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(buyerApproveExitRequest.pending, (state) => {
      state.requestLoading = true;
    });
    builder.addCase(buyerApproveExitRequest.fulfilled, (state) => {
      state.requestLoading = false;
    });
    builder.addCase(buyerApproveExitRequest.rejected, (state) => {
      state.requestLoading = false;
    });

    builder.addCase(buyerDeclineExitRequest.pending, (state) => {
      state.declineLoading = true;
    });
    builder.addCase(buyerDeclineExitRequest.fulfilled, (state) => {
      state.declineLoading = false;
    });
    builder.addCase(buyerDeclineExitRequest.rejected, (state) => {
      state.declineLoading = false;
    });

    builder.addCase(importInvoice.pending, (state) => {
      state.importLoading = true;
    });
    builder.addCase(importInvoice.fulfilled, (state) => {
      state.importLoading = false;
    });
    builder.addCase(importInvoice.rejected, (state) => {
      state.importLoading = false;
    });

    builder.addCase(buyerViewPfpById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(buyerViewPfpById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.viewPfpByIdData = action.payload;
    });
    builder.addCase(buyerViewPfpById.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(allBuyerPfpInvoices.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allBuyerPfpInvoices.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allPfpInvoicesData = action.payload;
    });
    builder.addCase(allBuyerPfpInvoices.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(allEarlyPayments.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allEarlyPayments.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allEarlyPAymentsData = action.payload;
    });
    builder.addCase(allEarlyPayments.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(uploadDocument.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(uploadDocument.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(uploadDocument.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(viewAllUploadLoadDocs.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(viewAllUploadLoadDocs.fulfilled, (state, action) => {
      state.isLoading = false;
      state.viewAllUploadLoadDocsData = action.payload;
    });
    builder.addCase(viewAllUploadLoadDocs.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(viewAllUploadLoadDocById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(viewAllUploadLoadDocById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.viewAllUploadLoadDocByIdData = action.payload;
    });
    builder.addCase(viewAllUploadLoadDocById.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(removeUploadedDocumentById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(removeUploadedDocumentById.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(removeUploadedDocumentById.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(editUploadedDocumentById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editUploadedDocumentById.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(editUploadedDocumentById.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(allNewEarlyPaymentRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allNewEarlyPaymentRequest.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allNewEarlyPaymentRequestData = action.payload;
    });
    builder.addCase(allNewEarlyPaymentRequest.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(approveOrDeclineEarlyPayment.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(approveOrDeclineEarlyPayment.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(approveOrDeclineEarlyPayment.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchAllFunders.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAllFunders.fulfilled, (state, action) => {
      state.isLoading = false;
      state.buyerAssociatedFundersData = action.payload;
    });
    builder.addCase(fetchAllFunders.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(buyerCreateTransPin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(buyerCreateTransPin.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(buyerCreateTransPin.rejected, (state) => {
      state.isLoading = false;
    });
  },
});
export const {
  getAssociatedSupplierId,
  getAssociatedFunderId,
  getPfpId,
  getInvoiceId,
  getInvoiceIds,
  getSupplierIds,
  getInvoices,
  updateEarlyPaymentsData,
} = buyerSlice.actions;
export default buyerSlice.reducer;
