import DashboardLayout from "layout/dashboardlayout";
import React, { useEffect, useState } from "react";
import "../styles/role-manager.css";
import RoleManagerTable from "./components/tables/RoleManagerTable";
import AddUserHeader from "./components/AddUserHeader";
import EmptyPage from "./components/EmptyPage";
import { data } from "./data";
import { AnimatePresence, motion } from "framer-motion";
import ViewMemberModal from "./components/modals/ViewMemberModal";
import AcceptModal from "features/RulesConfig/common/components/modals/DeleteRuleModal";
import AddNewUserModal from "./components/modals/AddNewUserModal";
import ViewDocumentModal from "features/documents/Components/DeleteDocumentModal";
import ViewRoleManager from "features/roleManager/Dashbaord/components/ViewRoleManager";
import ViewRoleFullDetails from "./components/ViewRoleFullDetails";
import EditRoleModal from "./components/modals/edithrole/EditRoleModal";
import AddNewRoleModal from "./components/modals/AddNewRoleModal";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import {
  allMembers,
  allRoles,
  deleteStaffUser,
  teamMemberInvite,
  updateUserStatus,
} from "appstate/roleManager/roleManagerSlice";
import { formatCapitaliseString } from "utilities/helper/formatCapitaliseString";

const RoleManagerDashboard = () => {
  const { userId } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const roleIndex = userId?.realm_access?.roles?.findIndex((arr) => {
    return (
      arr === "SUPPLIER" ||
      arr === "BUYER" ||
      arr === "FUNDER" ||
      arr === "ADMIN"
    );
  });
  const userRole = userId?.realm_access?.roles[roleIndex]?.toLowerCase();
  const [selectedId, setSelectedId] = useState("");
  const [toggleViewModal, setToggleViewModal] = useState(false);
  const [toggleRoleScreen, setToggleRoleScreen] = useState(false);
  const [toggleNewRole, setToggleNewRole] = useState(false);
  const [toggleRoleFullDetails, setToggleRoleFullDetails] = useState(false);
  const [toggleDeleteModal, setToggleDeleteModal] = useState(false);
  const [toggleActivateModal, setToggleActivateModal] = useState(false);
  const [toggleAddUserModal, setToggleAddUserModal] = useState(false);
  const [page, setPage] = useState(0);
  const pageSize = 10;
  const [editRolePermission, setEditRolePermission] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [umRole, setUmRole] = useState(null);
  const [editActive, setEditActive] = useState(false);
  const [acceptSendInvite, setAcceptSendInvite] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [id, setId] = useState(null);
  const [userEmail, setUserEmail] = useState("");
  const [assignedRole, setAssignedRole] = useState("");
  const [userCount, setUserCount] = useState(0);
  const [status, setStatus] = useState("");

  const changeTab = (index) => {
    setActiveTab(index);
  };

  function toggleViewModalOff() {
    setToggleViewModal(false);
  }
  function toggleRoleScreenOn() {
    setToggleRoleScreen(true);
  }
  function toggleNewRoleOn() {
    setToggleNewRole(true);
  }
  function toggleNewRoleOff() {
    setToggleNewRole(false);
  }

  function toggleRoleScreenOff() {
    setToggleRoleScreen(false);
  }

  function toggleRoleFullDetailsOn() {
    setToggleRoleFullDetails(true);
  }
  function toggleRoleFullDetailsOff() {
    setToggleRoleFullDetails(false);
  }

  function toggleAddUserModalOff() {
    setToggleAddUserModal(false);
  }
  function toggleAddUserModalOn() {
    setUserEmail("");
    setAssignedRole("");
    setToggleAddUserModal(true);
  }

  function toggleDeleteModalOff() {
    setToggleDeleteModal(false);
  }
  function toggleDeleteModalOn() {
    setToggleDeleteModal(true);
  }

  const handleDeleteUser = () => {
    const pendingToast = toast.loading("Deleting User...");

    dispatch(deleteStaffUser({ staffId: id, player: userRole })).then(
      (data) => {
        if (data?.payload?.success) {
          toast.dismiss(pendingToast);
          dispatch(allMembers(userRole)).then((data) => {
            setUserCount(data?.payload?.meta?.size);
            toast.success("User deleted successfully");
          });
        } else {
          toast.dismiss(pendingToast);
          return toast.error(data?.payload?.message);
        }
      }
    );
  };

  const handleUpdateUserStatus = () => {
    if (status === "IN_ACTIVE")
      return toast.error("Onboard this user to update status");

    const pendingToast = toast.loading(
      formatCapitaliseString(status) + "ing User..."
    );

    dispatch(updateUserStatus({ status, staffId: id, player: userRole })).then(
      (data) => {
        if (data?.payload?.success) {
          // setUserCount);
          toast.dismiss(pendingToast);
          dispatch(allMembers(userRole)).then((data) => {
            setUserCount(data?.payload?.meta?.size);
            toast.success(`User ${status}d successfully`);
          });
        } else {
          toast.dismiss(pendingToast);
          return toast.error(data?.payload?.message);
        }
      }
    );
  };
  const handleSubmit = () => {
    const body = {
      email: userEmail,
      role: assignedRole,
    };
    const pendingToast = toast.loading("Sending user invite request...");
    dispatch(teamMemberInvite({ player: userRole, body })).then((data) => {
      if (data?.payload?.success) {
        toast.dismiss(pendingToast);
        dispatch(allMembers(userRole)).then((data) => {
          setUserCount(data?.payload?.meta?.size);
          toast.success("New user request sent successfully");
        });
      } else {
        toast.dismiss(pendingToast);
        return toast.error(data?.payload?.message);
      }
    });
    setTimeout(() => {
      toggleAddUserModalOff();
    }, 1200);
  };

  const UserRoleManager = () => {
    useEffect(() => {
      dispatch(allRoles());
    }, []);

    return (
      <ViewRoleManager
        toggleNewRoleOn={toggleNewRoleOn}
        toggleRoleScreenOff={toggleRoleScreenOff}
        toggleRoleFullDetailsOn={toggleRoleFullDetailsOn}
        toggleRoleFullDetailsOff={toggleRoleFullDetailsOff}
        setSelectedId={setSelectedId}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
      />
    );
  };

  const RoleManagerTab = () => {
    return (
      <div>
        <div className=" flex justify-between w-full  rounded-lg ">
          <motion.button
            whileTap={{ scale: 0.99 }}
            onClick={() => {
              setUmRole(false);
              changeTab(0);
            }}
            className={`flex justify-center items-center gap-1 rounded-tl-lg py-5 w-1/2 ${
              activeTab === 0
                ? "active-tab-role tab-button-role"
                : "inactive-tab-role tab-button-role"
            }`}
          >
            <span className="flex items-center justify-center h-full">
              All User
            </span>
            <span
              style={{ background: "rgba(136, 136, 136, 0.2)" }}
              className="rounded-[50%] text-[#07593d] flex justify-center items-center h-[20px]  w-[20px] text-[10px] font-[500]"
            >
              {userCount}
            </span>
          </motion.button>

          <motion.button
            whileTap={{ scale: 0.99 }}
            onClick={() => {
              setUmRole(true);
              changeTab(1);
            }}
            className={`flex rounded-tr-lg justify-center py-5 w-1/2 ${
              activeTab === 1
                ? "active-tab-role tab-button-role"
                : "inactive-tab-role tab-button-role"
            }`}
          >
            <span>User role manager</span>
          </motion.button>
        </div>

        <div className="tab-content-wrapper-role min-h-[60vh] mb-6">
          {activeTab === 0 ? (
            <AnimatePresence>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
                className="tab-content rounded-b-lg py-2 px-6 bg-white    active w-full"
              >
                {data?.length === 0 || !data ? (
                  <EmptyPage />
                ) : (
                  <RoleManagerTable
                    setToggleActivateModal={setToggleActivateModal}
                    data={data}
                    setId={setId}
                    setStatus={setStatus}
                    setUserCount={setUserCount}
                    toggleViewModal={toggleViewModal}
                    toggleDeleteModalOn={toggleDeleteModalOn}
                    setToggleViewModal={setToggleViewModal}
                    selectedCard={selectedCard}
                    setSelectedCard={setSelectedCard}
                    setEditActive={setEditActive}
                    pageSize={pageSize}
                    page={page}
                    setPage={setPage}
                  />
                )}
              </motion.div>
            </AnimatePresence>
          ) : (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.3 }}
              className="tab-content h-full min-h-[60vh]  rounded-b-lg bg-white active w-full"
            >
              {data?.length === 0 || !data === 0 ? (
                <EmptyPage />
              ) : (
                <UserRoleManager />
              )}
            </motion.div>
          )}
        </div>
      </div>
    );
  };

  return (
    <DashboardLayout
      section="Role Manager"
      subHeader="Manage all your users and roles"
    >
      <AnimatePresence>
        {acceptSendInvite && (
          <AcceptModal
            onAction={handleSubmit}
            onClose={() => setAcceptSendInvite(false)}
          >
            <span className=" text-black text-center">
              Are you sure you want to{" "}
              <span className="font-bold text-center text-primaryColor">
                Send
              </span>{" "}
              this invite?
            </span>
          </AcceptModal>
        )}
      </AnimatePresence>

      {/* Modal Popup */}
      <AnimatePresence>
        {toggleViewModal && (
          <ViewMemberModal
            userRole={userRole}
            selectedId={selectedId}
            editActive={editActive}
            setEditActive={setEditActive}
            toggleViewModalOff={toggleViewModalOff}
          />
        )}
        {toggleAddUserModal && (
          <AddNewUserModal
            setAddUserClick
            setAcceptSendInvite={setAcceptSendInvite}
            toggleUserModalOff={toggleAddUserModalOff}
            pageSize={pageSize}
            page={page}
            assignedRole={assignedRole}
            setAssignedRole={setAssignedRole}
            userEmail={userEmail}
            setUserEmail={setUserEmail}
            id={id}
            setId={setId}
          />
        )}
        {toggleNewRole && (
          <AddNewRoleModal
            page={page}
            pageSize={pageSize}
            toggleNewRoleOff={toggleNewRoleOff}
          />
        )}
        {toggleDeleteModal && (
          <ViewDocumentModal
            text="Are you sure you would like to remove this member from the platform?"
            deleteDoc={() => handleDeleteUser()}
            onCloseDeleteModal={toggleDeleteModalOff}
          />
        )}

        {toggleActivateModal && (
          <ViewDocumentModal
            text={`Are you sure you would like to ${
              status === "IN_ACTIVE" ? "activate" : status
            } this user?`}
            deleteDoc={() => handleUpdateUserStatus()}
            onCloseDeleteModal={() => setToggleActivateModal(false)}
          />
        )}

        {editRolePermission && (
          <EditRoleModal closeModal={() => setEditRolePermission(false)} />
        )}
      </AnimatePresence>

      {toggleRoleScreen ? (
        <div className=" min-h-[80vh] h-full">
          {!toggleRoleFullDetails ? (
            <ViewRoleManager
              toggleNewRoleOn={toggleNewRoleOn}
              toggleRoleScreenOff={toggleRoleScreenOff}
              toggleRoleFullDetailsOn={toggleRoleFullDetailsOn}
              toggleRoleFullDetailsOff={toggleRoleFullDetailsOff}
              setSelectedId={setSelectedId}
              page={page}
              setPage={setPage}
              pageSize={pageSize}
            />
          ) : (
            <ViewRoleFullDetails
              toggleRoleFullDetailsOff={toggleRoleFullDetailsOff}
              setToggleEditRolePermissionModal={setEditRolePermission}
              selectedId={selectedId}
            />
          )}
        </div>
      ) : (
        <div className="flex flex-col gap-6 h-full w-full">
          <>
            <AddUserHeader
              toggleNewRoleOn={toggleNewRoleOn}
              umRole={umRole}
              toggleUserModalOn={toggleAddUserModalOn}
              toggleRoleScreenOn={toggleRoleScreenOn}
            />
            <RoleManagerTab />
          </>
        </div>
      )}
    </DashboardLayout>
  );
};

export default RoleManagerDashboard;
