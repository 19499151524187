import DashboardLayout from "layout/dashboardlayout";
import React from "react";
import { useSelector } from "react-redux";
import BuyerReimbursementDashboard from "./buyerReimbursement";

import ReinbursementDashboardContent from "./buyerReimbursement/supplier";

const ReimbursementDashboard = () => {
  const { userId } = useSelector((state) => state?.auth);
  const roleIndex = userId?.realm_access?.roles?.findIndex((arr) => {
    return (
      arr === "SUPPLIER" ||
      arr === "BUYER" ||
      arr === "FUNDER" ||
      arr === "ADMIN"
    );
  });
  const userRole = userId?.realm_access?.roles[roleIndex]?.toLowerCase();

  const invoicePlayer =
    userRole === "buyer" ? (
      <BuyerReimbursementDashboard />
    ) : userRole === "supplier" ? (
      <ReinbursementDashboardContent />
    ) : (
      <></>
    );
  return (
    <DashboardLayout
      section="Reimbursement"
      subHeader="Track invoices due for payment Reimbursement"
    >
      {invoicePlayer}
    </DashboardLayout>
  );
};

export default ReimbursementDashboard;
