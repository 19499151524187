import React, { useState } from "react";
import InputField from "shared/InputField";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import ConfirmModal from "../../single/Modal/ConfirmModal";
import { PAIconConfirm, PAIconMDISuccess } from "assets/images/svgs";
import { createPaymentExtension } from "appstate/paymentExtension/paymentExtensionSlice";
import { useNavigate } from "react-router-dom";


const RequestExtensionModal = ({ onClose, data }) => {
  const [newPaymentDate, setNewPaymentDate] = useState("");
  const [description, setDescription] = useState("");
  const [isTableVisible, setIsTableVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const { associatedFunderId, isLoading } = useSelector(
    (state) => state?.paymentExtension
  );


  const navigate = useNavigate()

  function handleSendRequest() {
    const body = {
      invoiceIds: [data?.invoice?.id],
      discountRate: 0.05,
      description: description,
      newPaymentDate: newPaymentDate,
      newPaymentAmount: 1000.0,
      funderId: associatedFunderId,
    };

    dispatch(createPaymentExtension(body)).then((data) => {
      if (data?.payload?.success) {
        setOpen(false);
        toast.custom(
          <div className="flex mt-[200px]">
            <div className="bg-primaryColor w-[147.791px] h-[160px] flex flex-col items-center justify-center rounded-l-[7px] shadow-lg">
              <PAIconMDISuccess />
            </div>
            <div className="bg-white flex flex-col justify-center items-center w-[394.538px] h-[160px] rounded-r-[7px] shadow-lg">
              <PAIconConfirm />
              <p className="text-dustyGray text-[13px] text-center mt-[17.6px]">
                Payment extension request successful
              </p>
            </div>
          </div>
        );
        // onClose();
        navigate(-1);
        // handleClose()
      }
    });
  }

  const handleDateChange = (date) => {
    setNewPaymentDate(date);
    setIsTableVisible(true);
  };

  const handleDescriptionChange = (desc) => {
    setDescription(desc);
  };

  const handleClose = () => {
    setOpen(false);
    onClose();
    navigate(-1);
  };

  return (
    <>
      {open && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white w-[614px] h-[254px] rounded-[10px] shadow-lg">
            <ConfirmModal
              hide={() => setOpen(false)}
              isLoading={isLoading}
              handleAssociate={handleSendRequest}
              warning="Would you like to proceed with the payment extension?"
            />
          </div>
        </div>
      )}
      <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-40">
        <div className="bg-white p-5 rounded-md shadow-md flex flex-col max-w-[900px] w-[650px]">
          {/* HEADER */}
          <header className="flex justify-between items-center w-full">
            <p className="font-semibold">{data?.invoice?.length} 1 Invoice</p>
            <div className="flex items-center">
              <button
                className={`px-4 py-1 mr-2 text-sm rounded-md ${
                  !newPaymentDate || !description
                    ? "bg-gray-200 text-gray300 border border-gray300 cursor-not-allowed"
                    : "bg-secondaryColor text-white border-none hover:bg-opacity-90"
                }`}
                onClick={() => setOpen(true)}
                disabled={!newPaymentDate || !description}
              >
                Send Request
              </button>

              <button
                className="px-4 py-1 text-sm bg-red-500 border border-red text-red rounded-md"
                onClick={handleClose}
              >
                Close Request
              </button>
            </div>
          </header>
          {/* AMOUNT / RATE */}
          <div className="flex justify-between items-center w-full mt-3">
            <div className="font-medium">
              <p>Invoice Amount</p>
              <p className="font-normal text-green-500 text-sm">
                {data?.invoice?.total}
              </p>
            </div>
            {isTableVisible && (
              <div className="flex items-center">
                <div>
                  <p>
                    Invoice Amount +{" "}
                    <span className="text-green-400 mr-3">Interest</span>
                  </p>
                  <p className="font-normal text-green-500 text-sm">
                    {data?.invoice?.total + 1000}
                  </p>
                </div>
                <div>
                  <p>
                    Interest Amount{" "}
                    <span className="text-green-400 mr2 text-sm">1000</span>
                  </p>
                  <p className="font-normal text-green-500 text-sm">+0.05%</p>
                </div>
              </div>
            )}
          </div>
          {/* DATE */}
          <div className="mt-3">
            <InputField
              label="New Payment Date:"
              type="date"
              value={newPaymentDate}
              onChange={(e) => handleDateChange(e.target.value)}
              className="w-[369px] h-[39px] rounded-[5px] outline-none !border border-[#AFAFAF] px-[5px]"
            />
          </div>
          {/* DESCRIPTION */}
          <div className="flex flex-col mt-5">
            <label className="text-textColor mb-[5px] text-xs" htmlFor="">
              Reasons for payment extension
            </label>
            <textarea
              value={description}
              onChange={(e) => handleDescriptionChange(e.target.value)}
              className="border outline-none border-[#AFAFAF] w-full rounded-[5px] h-[50px] p-[10px]"
            ></textarea>
          </div>
          {/* TABLE */}
          {isTableVisible && (
            <>
              <div className="mt-[28px] border-b border-[#F6F5FA] pb-[12px]">
                <h1 className="text-textColor text-md font-bold">
                  Summary of all invoices
                </h1>
              </div>
              <div className="w-full overflow-x-auto">
                <table className="w-full overflow-x-auto">
                  <thead className="bg-subtleGray">
                    <tr className="bg-unusualGray p-2.5">
                      <td className="text-[#11192A] p-2.5 text-xs font-bold">
                        Invoice
                      </td>
                      <td className="p-2.5 text-xs font-bold">
                        New payment date
                      </td>
                      <td className="p-2.5 text-xs font-bold">Amount</td>
                      <td className="p-2.5 text-xs font-bold">Interest rate</td>
                      <td className="p-2.5 text-xs font-bold">
                        Interest amount
                      </td>
                      <td className="p-2.5 text-xs font-bold">
                        New invoice Amount
                      </td>
                    </tr>
                  </thead>
                  {Array.isArray(data?.invoice) ? (
                    data?.invoice.map((item, index) => (
                      <tbody key={index}>
                        <tr className="py-[3px] px-1.5">
                          <td className="text-xs text-[#11192A] p-2.5">
                            {item.invoiceNumber}
                          </td>
                          <td className="text-xs text-[#11192A] p-2.5">
                            {item.dueDate}
                          </td>
                          <td className="text-xs text-[#11192A] p-2.5">
                            {item.total}
                          </td>
                          <td className="text-xs text-[#11192A] p-2.5">
                            {item?.interestRate}
                          </td>
                          <td className="text-xs text-[#11192A] p-2.5">
                            {item?.interestAmount}
                          </td>
                          <td className="text-xs text-[#11192A] p-2.5">
                            {item?.newInvoiceAmount}
                          </td>
                        </tr>
                      </tbody>
                    ))
                  ) : (
                    <tbody>
                      <tr className="py-[3px] px-1.5">
                        <td className="text-xs text-[#11192A] p-2.5">
                          {data?.invoice?.invoiceNumber}
                        </td>
                        <td className="text-xs text-[#11192A] p-2.5">
                          {data?.invoice?.dueDate}
                        </td>
                        <td className="text-xs text-[#11192A] p-2.5">
                          {data?.invoice?.total}
                        </td>
                        <td className="text-xs text-[#11192A] p-2.5">
                          {/* {invoiceIdData?.interestRate} */} 0.05%
                        </td>
                        <td className="text-xs text-[#11192A] p-2.5">
                          {/* {invoiceIdData?.interestAmount} */} 1000
                        </td>
                        <td className="text-xs text-[#11192A] p-2.5">
                          {data?.invoice?.total + 1000}
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default RequestExtensionModal;
