import {
  PAIconAddGreen,
  PAIconArrowLeftGreen,
  PAIconCancelRound,
  PAIconEditGreen,
  PAIconSmallClose,
} from "assets/images/svgs";
import RightSideModal from "shared/RightSideModal";
import ConditionTable from "../table/ConditionTable";
import Switch from "features/settings/components/NotificationSetting/components/Switch";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  allRules,
  editRule,
  ruleById,
  updateStatus,
} from "appstate/rulesandconfig/rulesandconfigSlice";
import { clearFormData, updateField } from "../../earlyPaymentFormSlice";
import Loading from "shared/Loading";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";

// import toast from "react-hot-toast";

function EditRuleModal({
  closeModal,
  setRuleSavedModal,
  setTestModal,
  id,
  handleBack,
}) {
  const dispatch = useDispatch();
  const formData = useSelector((state) => state?.earlyPaymentFormData);
  const { modalLoading, ruleByIdData } = useSelector(
    (state) => state?.rulesAndConfig
  );
  const { ruleType } = useParams();
  const data = ruleByIdData?.data;

  useEffect(() => {
    dispatch(ruleById({ id, ruleType }));
  }, [dispatch, id, ruleType]);

  const [ruleActivated, setRuleActivated] = useState(
    data?.status === "Activated"
  );

  const [ruleStatus, setRuleStatus] = useState(
    ruleActivated ? "Activated" : "Deactivated"
  );

  const [selectedAction] = useState("none");
  const [selectedActions, setSelectedActions] = useState([]);
  const [rows, setRows] = useState([]);
  const [ruleNameEdit, setRuleNameEdit] = useState(true);

  const [ruleName, setRuleName] = useState("");

  const [isDropdownVisible, setDropdownVisible] = useState(true);

  const toggleRuleActivated = () => {
    setRuleActivated(!ruleActivated);
    setRuleStatus(ruleActivated ? "Deactivated" : "Activated");
  };

  function formatString(inputString) {
    const words = inputString.split("_");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );
    const formattedString = capitalizedWords.join(" ");
    return formattedString;
  }

  const handleDropdownChange = (e) => {
    const selectedValue = e.target.value;

    if (!selectedActions.some((action) => action.type === selectedValue)) {
      setSelectedActions((prevActions) => [
        ...prevActions,
        { type: selectedValue, value: "" },
      ]);
    }
  };

  const handleInputChange = (selectedValue, value) => {
    setSelectedActions((prevActions) =>
      prevActions.map((action) =>
        action.type === selectedValue ? { ...action, value } : action
      )
    );
  };

  const handleRuleNameChange = (e) => {
    setRuleName(e.target.value);
  };

  const handleAddAction = () => {
    setDropdownVisible(true);
  };

  const handleRemoveAction = (index) => {
    setSelectedActions((prevActions) =>
      prevActions.filter((_, i) => i !== index)
    );
  };

  const handleSubmit = () => {
    dispatch(updateStatus({ id, status: ruleStatus, ruleType })).then(
      (data) => {
        if (data?.meta?.requestStatus === "fulfilled") {
          dispatch(allRules({ page: 0, pageSize: 10, ruleType }));
        }
      }
    );
    dispatch(clearFormData());
    dispatch(updateField({ field: "name", value: ruleName }));
    dispatch(updateField({ field: "conditions", value: rows }));
    dispatch(updateField({ field: "actions", value: selectedActions }));
    closeModal();
    // Handle submit logic here
    const body = { ...formData };

    dispatch(editRule({ id, body, ruleType }))
      .then((data) => {
        if (data?.payload?.success) {
          dispatch(allRules({ page: 0, pageSize: 20, ruleType }));
          setTimeout(() => {
            setRuleSavedModal(true);
          }, 500);

          closeModal();
        }
      })
      .catch((error) => {
        // Error callback
        toast.error(error?.message);
      });
  };

  useEffect(() => {
    setRows(data?.conditions);
    setSelectedActions(data?.actions);
    setRuleName(data?.name);
  }, [data?.actions, data?.conditions, data?.name]);

  return (
    <>
      <RightSideModal closeModal={closeModal}>
        {modalLoading ? (
          <div className="w-full h-screen py-10 flex justify-center items-center">
            <Loading />
          </div>
        ) : (
          <div className="flex flex-col gap-3 py-4">
            <header className="flex px-[4%] pb-6 border-b-[3.25px] border-[#F6F5FA]   items-center justify-between">
              <span className="font-bold text-xl">Edit Rule </span>

              <span
                onClick={closeModal}
                className=" cursor-pointer flex gap-2 items-center"
              >
                <PAIconSmallClose />

                <span>Close</span>
              </span>
            </header>

            <div
              onClick={() => handleBack()}
              className="px-[4%] w-fit flex cursor-pointer items-center gap-1"
            >
              <PAIconArrowLeftGreen />{" "}
              <span className="font-light text-sm text-primaryColor">
                Go Back
              </span>
            </div>
            <main className="py-3 flex flex-col gap-8">
              <section className="px-[4%] flex flex-col gap-2">
                <span className="text-sm text-primaryColor">Rule Name</span>

                <div className=" items-center flex w-full rounded-[5px] outline-none border-[0.5px] border-primaryColor">
                  <input
                    onChange={handleRuleNameChange}
                    value={ruleName}
                    disabled={ruleNameEdit}
                    // value={formData.name}
                    className="px-5 py-3 w-full rounded-[5px] outline-none bordr-[0.5px] border-primaryColor"
                    type="text"
                  />
                  <div
                    onClick={() => setRuleNameEdit(!ruleNameEdit)}
                    className={`${
                      ruleNameEdit ? "" : "hidden"
                    } cursor-pointer px-3`}
                  >
                    <PAIconEditGreen />
                  </div>
                </div>
              </section>

              <section
                style={{
                  background: "rgba(203, 233, 215, 0.11)",
                }}
                className="py-5 px-[4%] flex flex-col gap-4"
              >
                <span className="text-xl font-semibold">Set Conditions</span>
                <span className=" w-full">
                  <ConditionTable rows={rows} setRows={setRows} />
                </span>
              </section>

              <section className="py-5 px-[4%] flex flex-col gap-6">
                <div className="flex items-start justify-between">
                  <div className="flex flex-col gap-3">
                    <span className="text-xl font-semibold">Set Action</span>
                    <span className="text-[#7A7A7A] text-xs italic font-light  ">
                      You can set one or multiple actions for your specified
                      conditions
                    </span>
                  </div>

                  <span className=" flex items-center gap-10">
                    <span className="text-sm">Activate</span>
                    <Switch
                      isToggled={ruleActivated}
                      onToggle={toggleRuleActivated}
                    />
                  </span>
                </div>

                <div className="w-full flex flex-col gap-4">
                  {selectedActions?.map((action, index) => (
                    <div
                      className="flex justify-between items-center"
                      key={index}
                    >
                      <span>{formatString(action?.type)}</span>

                      <div className="flex items-center gap-2 justify-end w-[40%] ">
                        <input
                          className="px-2.5 w-full  text-xs  rounded-[5px] border outline-none border-[#E6E6E6] py-2.5"
                          type="text"
                          value={action.value}
                          onChange={(e) =>
                            handleInputChange(action?.type, e.target.value)
                          }
                          placeholder={`Enter ${formatString(action?.type)}`}
                        />

                        <button
                          className="flex"
                          onClick={() => handleRemoveAction(index)}
                        >
                          <PAIconCancelRound />
                        </button>
                      </div>
                    </div>
                  ))}

                  {isDropdownVisible && (
                    <select
                      className="px-5 cursor-pointer text-sm text-[#bababa]  rounded-[5px] border outline-none border-[#07593D75] py-4 w-full"
                      value={selectedAction}
                      onChange={handleDropdownChange}
                    >
                      <option className="text-sm text-[#B1B1B1]" value="">
                        Select from a list of available actions
                      </option>

                      <option
                        value="DISCOUNT_PERCENTAGE"
                        className="cursor-pointer text-black hover:bg-primaryColor hover:text-white"
                      >
                        Discount Percentage
                      </option>
                      <option
                        value="DISCOUNT_EXPIRY_DATE"
                        className="cursor-pointer text-black hover:bg-primaryColor hover:text-white"
                      >
                        Discount Expiry Date
                      </option>

                      <option
                        value="MINIMUM_INVOICE_AMOUNT"
                        className="cursor-pointer text-black hover:bg-primaryColor hover:text-white"
                      >
                        Minimum Invoice Amount
                      </option>

                      <option
                        value="MAXIMUM_INVOICE_AMOUNT"
                        className="cursor-pointer text-black hover:bg-primaryColor hover:text-white"
                      >
                        Maximum Invoice Amount
                      </option>

                      <option
                        className="cursor-pointer text-black hover:bg-primaryColor hover:text-white"
                        value="DYNAMIC_DISCOUNTING"
                      >
                        Dynamic Discounting
                      </option>

                      <option
                        className="cursor-pointer text-black hover:bg-primaryColor hover:text-white"
                        value="TIERED_DISCOUNTING"
                      >
                        Tiered Discounting
                      </option>
                      <option
                        className="cursor-pointer text-black hover:bg-primaryColor hover:text-white"
                        value="EXTEND_PAYMENT_TERM"
                      >
                        Extend Payment Time
                      </option>
                      <option
                        className="cursor-pointer text-black hover:bg-primaryColor hover:text-white"
                        value="EARLY_PAYMENT_BONUS"
                      >
                        Early Payment Bonus
                      </option>
                      <option
                        className="cursor-pointer text-black hover:bg-primaryColor hover:text-white"
                        value="QUANTITY_DISCOUNT"
                      >
                        Quantity Discount
                      </option>
                      <option
                        className="cursor-pointer text-black hover:bg-primaryColor hover:text-white"
                        value="EXPEDITE_SHIPPING"
                      >
                        Expedite Shipping
                      </option>
                      <option
                        className="cursor-pointer text-black hover:bg-primaryColor hover:text-white"
                        value="SUPPLIER_FEEDBACK"
                      >
                        Supplier Feedback
                      </option>
                      <option
                        className="cursor-pointer text-black hover:bg-primaryColor hover:text-white"
                        value="ADVANCE_PAYMENT"
                      >
                        Advance Payment
                      </option>
                      <option
                        className="cursor-pointer text-black hover:bg-primaryColor hover:text-white"
                        value="PRODUCT_BUNDLING"
                      >
                        Production Bundling
                      </option>
                      <option
                        className="cursor-pointer text-black hover:bg-primaryColor hover:text-white"
                        value="LOYALTY_POINTS"
                      >
                        Loyalty Points
                      </option>
                    </select>
                  )}

                  {!isDropdownVisible && (
                    <div className="py-4  flex justify-end">
                      <button
                        className=" flex justify-center items-center gap-2 text-primaryColor text-xs"
                        onClick={handleAddAction}
                      >
                        <PAIconAddGreen />
                        <span>Add Action</span>
                      </button>
                    </div>
                  )}
                </div>
              </section>

              <footer className="px-[4%] pt-10">
                <div className="flex justify-end">
                  <div className="flex gap-3">
                    <button
                      onClick={() => closeModal()}
                      className=" border border-red text-red text-xs py-2.5 px-5 rounded-[5px]"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => handleSubmit()}
                      disabled={selectedActions?.length === 0}
                      className=" border border-primaryColor text-primaryColor text-xs py-2.5 px-5 rounded-[5px]"
                    >
                      Save Rule
                    </button>

                    <button
                      onClick={() => {
                        closeModal();
                        setTestModal(true);
                      }}
                      disabled={selectedActions?.length === 0}
                      className={` ${
                        selectedActions?.length === 0
                          ? "bg-[#DADADA]"
                          : "bg-primaryColor"
                      }  text-white   text-xs py-2.5 px-5 rounded-[5px]`}
                    >
                      Test and Save Rule
                    </button>
                  </div>
                </div>
              </footer>
            </main>
          </div>
        )}
      </RightSideModal>
    </>
  );
}

export default EditRuleModal;
