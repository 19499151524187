import {
  PAIconInventoryArrowLeft,
  //   PAIconSmallExportDownloadIcon,
} from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Card from "shared/Card";
import { motion } from "framer-motion";
// import Download from "../../../../assets/images/svgs/downloadcsv.svg";
import { useEffect, useState } from "react";
import {
  getAllInvoiceById,
  getBulkEarlyPaymentDetails,
} from "appstate/api/invoice/invoice";

const LienedEarlyPaymentWithoutRecourseDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    invoiceId,

    // fundingType,
    // factoringType,
    // channel,
  } = location.state || {};

  const [open, setOpen] = useState(false);

  const [showDirectFunding, setShowDirectFunding] = useState(false);
  // console.log("===states===", factoringType, channel);

  const [, setInvoiceData] = useState(null);
  // eslint-disable-next-line
  const [, setError] = useState(null);
  // eslint-disable-next-line
  const [, setEarlyPaymentData] = useState(null);
  // eslint-disable-next-line
  const [, setLoading] = useState(true);

  useEffect(() => {
    const fetchInvoice = async () => {
      if (invoiceId) {
        const result = await getAllInvoiceById({ id: invoiceId });
        setInvoiceData(result?.data);
      }
    };
    fetchInvoice();
    // eslint-disable-next-line
  }, [invoiceId]);

  useEffect(() => {
    const fetchEarlyPaymentDetails = async () => {
      try {
        const result = await getBulkEarlyPaymentDetails(id);
        setEarlyPaymentData(result?.data);
      } catch (error) {
        setError(error.message || "Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchEarlyPaymentDetails();
    }
  }, [id]);

  function handleNavigate() {
    let path = -1;
    navigate(path);
  }

  const dummyData = [
    {
      bidAmount: 3,
      item: "Solar Panel ",
      description: "Dangote 12 x 4 ultra high density so..",
      unitPrice: "340,000",
      Quantity: "3",
      amount: "1,020,000",
      taxRate: "1.2%",
      taxAmount: "12,240",
      totalPrice: "1,032,240",
    },

    {
      bidAmount: 4,
      item: "Solar Panel ",
      description: "Dangote 12 x 4 ultra high density so..",
      unitPrice: "340,000",
      Quantity: "3",
      amount: "1,020,000",
      taxRate: "1.2%",
      taxAmount: "12,240",
      totalPrice: "1,032,240",
    },

    {
      bidAmount: 5,
      item: "Solar Panel ",
      description: "Dangote 12 x 4 ultra high density so..",
      unitPrice: "340,000",
      Quantity: "3",
      amount: "1,020,000",
      taxRate: "1.2%",
      taxAmount: "12,240",
      totalPrice: "1,032,240",
    },
    {
      bidAmount: 6,
      item: "Solar Panel ",
      description: "Dangote 12 x 4 ultra high density so..",
      unitPrice: "340,000",
      Quantity: "3",
      amount: "1,020,000",
      taxRate: "1.2%",
      taxAmount: "12,240",
      totalPrice: "1,032,240",
    },
    {
      bidAmount: 7,
      item: "Solar Panel ",
      description: "Dangote 12 x 4 ultra high density so..",
      unitPrice: "340,000",
      Quantity: "3",
      amount: "1,020,000",
      taxRate: "1.2%",
      taxAmount: "12,240",
      totalPrice: "1,032,240",
    },
  ];

  const { selectedStatus } = location.state || {};

  const handlePayNow = () => {
    setOpen(!open);
  };
  const handleDirectFunding = () => {
    setShowDirectFunding(!showDirectFunding);
  };
  return (
    <DashboardLayout
      section="Reimbursement"
      subHeader="Track invoices due for payment Reimbursement"
    >
      <header>
        {/* <div
              onClick={handleNavigate}
              className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
            >
              <PAIconInventoryArrowLeft />
              <p className="mt-[2px] text-sm font-normal text-lightGray">Go Back</p>
            </div> */}
        <div
          onClick={handleNavigate}
          className="flex items-center gap-x-[3px]  cursor-pointer rounded-t-[10px]    px-3"
        >
          <PAIconInventoryArrowLeft />
          <p className="mt-[2px] text-sm font-normal text-lightGray">Go Back</p>
        </div>
      </header>
      <header>
        {/* <div
              onClick={handleNavigate}
              className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
            >
              <PAIconInventoryArrowLeft />
              <p className="mt-[2px] text-sm font-normal text-lightGray">Go Back</p>
            </div> */}
        <div
          onClick={handleNavigate}
          className="flex items-center gap-x-[3px]  cursor-pointer rounded-t-[10px]  shadow-md bg-[#FAFAFA] py-3 border-b-[0.5px]   border-gray800 px-3 w-full justify-between mt-6"
        >
          <div class="text-black text-sm font-medium ">Invoices</div>

          <div class="h-[17px] justify-start items-center gap-2 inline-flex">
            <div class="text-[#222222] text-sm font-normal ">Status:</div>
            <div class="text-[#07593d] text-sm font-bold ">Accepted</div>
          </div>
        </div>
      </header>
      <div className="flex justify-between">
        <Card className="w-[100%]  rounded-b-[10px]">
          <div className=" flex  w-full justify-between items-center px-6 mt-6">
            {" "}
            <div className=" font-semibold text-lg">Invoice|INV-2002</div>
            <div>
              <motion.button
                whileTap={{ scale: 0.9 }}
                className="flex bg-[#06593D] py-2 text-sm justify-center gap-2 text-white rounded-[5px] items-center px-[35px]"
                onClick={() =>
                  selectedStatus === "Liened"
                    ? handlePayNow()
                    : handleDirectFunding()
                }
              >
                {selectedStatus === "Liened" ? "Pay Now" : "Fund Request"}
              </motion.button>

              {selectedStatus === "Liened" && (
                <span className="text-[#06593D]">00d:13h:55m:32s</span>
              )}
              {selectedStatus === "Upcoming" && (
                <span className="text-[#F08000]">00d:13h:55m:32s</span>
              )}
              {selectedStatus === "Overdue" && (
                <span className="text-red">00d:13h:55m:32s</span>
              )}
            </div>
          </div>
          <div className=" flex  flex-col gap-3   p-[24px]">
            <main className=" w-full flex justify-between flex-wrap">
              <div className="flex flex-col gap-3 lg:w-1/3">
                <div className="flex">
                  <div class="text-[#222222] text-base font-normal ">
                    Reference No:{" "}
                  </div>
                </div>

                <div className="flex flex-col gap-1">
                  {/* <div className="flex gap-x-[50px]">
                          <span className=" text-xs w-[97px]">Invoice Number:</span>
                          <span className="text-[#979797]  text-xs">INV 2002</span>
                        </div> */}
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Created Date:</span>
                    <span className="text-[#979797]  text-xs">
                      23-March-2023
                    </span>
                  </div>

                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Invoice DueDate</span>
                    <span className="text-[#979797]  text-xs">
                      {" "}
                      23-March-2023
                    </span>
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-3 ">
                <div className="flex flex-col gap-1">
                  <div class="text-[#222222] text-base font-bold ">
                    Payable Finance Offer
                  </div>

                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Discount Rate</span>
                    <span className="text-[#979797]  text-xs">{"2%"}</span>
                  </div>
                  {/* <div className="flex gap-x-[50px]">
                          <span className=" text-xs w-[97px]">Invoice Number:</span>
                          <span className="text-[#979797]  text-xs">INV 2002</span>
                        </div> */}
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Discount Price</span>
                    <span className="text-[#979797]  text-xs">
                      NGN 1,263,00
                    </span>
                  </div>

                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Payable Amount</span>
                    <span className="text-[#979797]  text-xs">
                      NGN 1,263,00
                    </span>
                  </div>

                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Payment Durations</span>
                    <span className="text-[#979797]  text-xs">60 Days</span>
                  </div>
                  <div className="flex gap-x-[70px]">
                    <span className=" text-xs">Payment Date:</span>
                    <span className="text-[#979797]  text-xs">
                      23-March-2023
                    </span>
                  </div>
                </div>
              </div>
            </main>
          </div>
          {/* <br /> */}

          {/* <div className="flex flex-col gap-4">
                <header className="border-b-[5px] border-b-[#F6F5FA] pb-3"></header>
                <div className="flex  justify-between">
                  <div className="flex gap-2"></div>
                </div>
              </div> */}
          <div className="flex flex-col gap-4 ">
            <div className=" p-[16px]">
              <div class="h-[39px] flex-col justify-start items-start gap-1 inline-flex mb-6">
                <div class="w-[129px] h-5 text-[#222222] text-sm font-black ">
                  Supplier
                </div>
                <div class="w-[147px] h-[15px] text-[#7a7a7a] text-xs font-normal ">
                  ABC Cooperation
                </div>
              </div>{" "}
              <header className="border-b-[5px] border-b-[#F6F5FA] pb-3 flex justify-between items-center ">
                <h1 className="text-sm">Line Items</h1>
                <div className="flex gap-2">
                  <div className="flex flex-col">
                    <span className="text-black   text-base">
                      5 Total Items
                    </span>
                  </div>
                </div>
              </header>
            </div>

            <div className=" w-full overflow-x-auto  p-[24px]">
              <table className=" w-full overflow-x-auto">
                <thead className="bg-subtleGray w-">
                  <tr className=" bg-unusualGray p-2.5 ">
                    <td className="text-[#11192A] p-2.5 text-xs font-bold">
                      Item
                    </td>
                    <td className=" p-2.5 text-xs font-bold">Description</td>
                    <td className=" p-2.5 text-xs font-bold">Unit Price</td>
                    <td className=" p-2.5 text-xs font-bold">Quantity</td>
                    <td className=" p-2.5 text-xs font-bold">
                      {"Amount(Before Tax)"}
                    </td>
                    <td className=" p-2.5 text-xs font-bold">Tax Rate</td>
                    <td className=" p-2.5 text-xs font-bold">Tax Amount</td>
                    <td className=" p-2.5 text-xs font-bold">Total Price</td>
                  </tr>
                </thead>
                {dummyData.map((item, index) => (
                  <tbody key={index}>
                    <tr
                      style={{ cursor: "pointer" }}
                      // onClick={onRowClicked}
                      className="py-[3px] px-1.5"
                    >
                      <td className="text-xs text-[#11192A] p-2.5">
                        {item.item}
                      </td>
                      <td className="text-xs text-[#11192A] p-2.5">
                        {item.description}
                      </td>
                      <td className="text-xs text-[#11192A] p-2.5">
                        {item.unitPrice}
                      </td>
                      <td className="text-xs text-[#11192A] p-2.5">
                        {item.Quantity}
                      </td>
                      <td className="text-xs text-[#11192A] p-2.5">
                        {item.amount}
                      </td>
                      <td className="text-xs text-[#11192A] p-2.5">
                        {item.taxRate}
                      </td>
                      <td className="text-xs text-[#11192A] p-2.5">
                        {item.taxAmount}
                      </td>
                      <td className="text-xs text-[#11192A] p-2.5">
                        {item.totalPrice}
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>

            <div className=" px-6   py-5 bg-neutral-50 flex-col justify-center items-start  inline-flex bg-[#fafafa] w-full border-t-[0.2px]  border-t-[#b1a6b2] rounded-b-[10px]">
              <div className="lg:flex md:flex justify-between  w-full sm:block ">
                {/* First left side */}
                <div>
                  <section>
                    {" "}
                    <div class="text-[#222222] text-lg  font-medium border-b-[0.2px]  border-[#b1a6b2]">
                      Receiving Funding Account Information
                    </div>
                    {/* <div class="text-[#222222] text-lg font-semibold ">
                              Receiving Funding Account Information
                            </div> */}
                    <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                      7469479465
                    </div>
                    <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                      Praise Akinlade
                    </div>
                  </section>
                </div>

                {/* second division */}

                <div>
                  {" "}
                  <div class=" flex-col justify-start items-start inline-flex lg:min-w-[290px]">
                    <section className=" w-full px-6 pb-2 border-b-[1px] border-black">
                      <div className=" flex w-full justify-between">
                        <div class="text-[#222222] text-xs font-semibold ">
                          Subtotal
                        </div>
                        <div class=" text-[#222222] text-xs font-semibold ">
                          10,129,892
                        </div>
                      </div>
                      <div className=" flex w-full justify-between pt-3 pb-1 border-b-[0.2px] border-b-[#b1a6b2]/30">
                        <div class="text-[#222222]  text-xs font-medium flex  space-x-9 ">
                          <span>VAT%</span>
                          <span>5.6%</span>
                        </div>
                        <div class="text-[#222222] text-xs font-medium ">
                          573,960
                        </div>
                      </div>

                      <div className=" flex w-full justify-between pt-3 pb-1 border-b-[0.2px] border-b-[#b1a6b2]/30">
                        <div class="text-[#222222]  text-xs font-medium flex  space-x-9 ">
                          <span>Discount%</span>
                          <span>2.4%</span>
                        </div>
                        <div class="text-[#222222] text-xs font-medium ">
                          237,088
                        </div>
                      </div>
                    </section>

                    <div className=" flex w-full justify-between py-2 px-6 border-b-[1px] border-b-black">
                      <div class="text-[#222222] text-lg font-semibold ">
                        Grand Total
                      </div>
                      <div class="text-[#222222] text-lg font-semibold ">
                        10,473,890
                      </div>
                    </div>
                  </div>
                </div>

                {/* stops */}
              </div>
            </div>
          </div>
        </Card>
      </div>
    </DashboardLayout>
  );
};
export default LienedEarlyPaymentWithoutRecourseDetails;
