import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  acceptStaffAgreementApi,
  assignPermissionsApi,
  assignRolePermission,
  createNewRole,
  deleteMember,
  deleteStaffUserApi,
  deleteUserRoleApi,
  getAllAssignedPermissions,
  getAllAvailablePermissions,
  getAllMembers,
  getAllRoles,
  getAllRolesWorkflowApi,
  getAllSubPermissions,
  getRoleById,
  getStaffDashboardApi,
  getStaffProfileById,
  inviteTeamMember,
  onboardNewInvite,
  roleUpdate,
  updateRoleAssignedUserApi,
  updateStaffToOnboardApi,
  updateStatusApi,
  viewStaffAgreementApi,
} from "appstate/api/roleManager";

const initialState = {
  isLoading: false,
  updateRoleLoading: false,
  allRolesData: [],
  allRolesWorkflowData: [],
  allPermissionAssignedData: [],
  allPermissionsData: [],
  allSubPermissionsData: [],
  roleId: null,
  saveInviteData: [],
  memberId: null,
  allMembersData: [],
  roleByIdData: [],
  staffProfileByIdData: [],
  getStaffDashboardData: [],
  viewStaffAgreementData: [],
};

export const createRole = createAsyncThunk(
  "rolemanager/createRole",
  createNewRole
);

export const allRoles = createAsyncThunk(
  "rolemanager/getAllRoles",
  getAllRoles
);
export const allRolesWorkflow = createAsyncThunk(
  "rolemanager/allRolesWorkflow",
  getAllRolesWorkflowApi
);

export const roleById = createAsyncThunk("rolemanager/roleById", getRoleById);

export const assignPermission = createAsyncThunk(
  "rolemanager/assignPermission",
  assignRolePermission
);
export const assignPermissionNew = createAsyncThunk(
  "rolemanager/assignPermissionNew",
  assignPermissionsApi
);

export const allPermissionAssigned = createAsyncThunk(
  "rolemanager/allPermissionAssigned",
  getAllAssignedPermissions
);

export const allPermissions = createAsyncThunk(
  "rolemanager/allPermissions",
  getAllAvailablePermissions
);

export const allSubPermissions = createAsyncThunk(
  "rolemanager/allSubPermissions",
  getAllSubPermissions
);

export const teamMemberInvite = createAsyncThunk(
  "rolemanager/teamMemberInvite",
  inviteTeamMember
);

export const newInviteOnboard = createAsyncThunk(
  "rolemanager/newInviteOnboard",
  onboardNewInvite
);

export const memberDelete = createAsyncThunk(
  "rolemanager/memberDelete",
  deleteMember
);

export const updateRole = createAsyncThunk(
  "rolemanager/updateRole",
  roleUpdate
);
export const updateUserStatus = createAsyncThunk(
  "rolemanager/updateUserStatus",
  updateStatusApi
);

export const deleteStaffUser = createAsyncThunk(
  "rolemanager/deleteStaffUser",
  deleteStaffUserApi
);
export const deleteUserRole = createAsyncThunk(
  "rolemanager/deleteUserRole",
  deleteUserRoleApi
);

export const allMembers = createAsyncThunk(
  "rolemanager/allMembers",
  getAllMembers
);
export const staffProfileById = createAsyncThunk(
  "rolemanager/staffProfileById",
  getStaffProfileById
);
export const updateStaffToOnboard = createAsyncThunk(
  "rolemanager/updateStaffToOnboard",
  updateStaffToOnboardApi
);

export const updateRoleAssignedUser = createAsyncThunk(
  "rolemanager/updateRoleAssignedUser",
  updateRoleAssignedUserApi
);

export const getStaffDashboard = createAsyncThunk(
  "rolemanager/getStaffDashboard",
  getStaffDashboardApi
);

export const acceptStaffAgreement = createAsyncThunk(
  "rolemanager/acceptStaffAgreement",
  acceptStaffAgreementApi
);
export const viewStaffAgreement = createAsyncThunk(
  "rolemanager/viewStaffAgreement",
  viewStaffAgreementApi
);
export const roleManagerSlice = createSlice({
  name: "rolemanager",
  initialState,
  reducers: {
    getRoleId: (state, action) => {
      state.roleId = action.payload;
    },
    getMemberId: (state, action) => {
      state.memberId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createRole.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createRole.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createRole.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(allRoles.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allRoles.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allRolesData = action.payload;
    });
    builder.addCase(allRoles.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(allRolesWorkflow.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allRolesWorkflow.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allRolesWorkflowData = action.payload;
    });
    builder.addCase(allRolesWorkflow.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(roleById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(roleById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.roleByIdData = action.payload;
    });
    builder.addCase(roleById.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(assignPermission.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(assignPermission.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(assignPermission.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(allPermissionAssigned.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allPermissionAssigned.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allPermissionAssignedData = action.payload;
    });
    builder.addCase(allPermissionAssigned.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(allPermissions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allPermissions.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allPermissionsData = action.payload;
    });
    builder.addCase(allPermissions.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(allSubPermissions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allSubPermissions.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allSubPermissionsData = action.payload;
    });
    builder.addCase(allSubPermissions.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(teamMemberInvite.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(teamMemberInvite.fulfilled, (state, action) => {
      state.isLoading = false;
      state.saveInviteData = action.payload;
    });
    builder.addCase(teamMemberInvite.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(newInviteOnboard.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(newInviteOnboard.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(newInviteOnboard.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(memberDelete.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(memberDelete.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(memberDelete.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateRole.pending, (state) => {
      state.updateRoleLoading = true;
    });
    builder.addCase(updateRole.fulfilled, (state) => {
      state.updateRoleLoading = false;
    });
    builder.addCase(updateRole.rejected, (state) => {
      state.updateRoleLoading = false;
    });
    builder.addCase(allMembers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allMembers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allMembersData = action.payload;
    });
    builder.addCase(allMembers.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(staffProfileById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(staffProfileById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.staffProfileByIdData = action.payload;
    });
    builder.addCase(staffProfileById.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getStaffDashboard.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getStaffDashboard.fulfilled, (state, action) => {
      state.isLoading = false;
      state.getStaffDashboardData = action.payload;
    });
    builder.addCase(getStaffDashboard.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(assignPermissionNew.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(assignPermissionNew.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(assignPermissionNew.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(updateUserStatus.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateUserStatus.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateUserStatus.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteStaffUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteStaffUser.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteStaffUser.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteUserRole.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteUserRole.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteUserRole.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(updateStaffToOnboard.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(updateStaffToOnboard.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateStaffToOnboard.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(updateRoleAssignedUser.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(updateRoleAssignedUser.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateRoleAssignedUser.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(acceptStaffAgreement.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(acceptStaffAgreement.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(acceptStaffAgreement.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(viewStaffAgreement.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(viewStaffAgreement.fulfilled, (state, action) => {
      state.isLoading = false;
      state.viewStaffAgreementData = action.payload;
    });
    builder.addCase(viewStaffAgreement.rejected, (state) => {
      state.isLoading = false;
    });
  },
});
export const { getRoleId, getMemberId } = roleManagerSlice.actions;
export default roleManagerSlice.reducer;
