import React, { useEffect } from "react";
import {
  PAIconWorkFlowArrowLeft,
  PAIconWorkFlowArrowRedLeft,
} from "assets/images/svgs";
import Table from "layout/table/Table";
import { useNavigate } from "react-router-dom";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";
// fetchWorkflowDashBoard
import { fetchWorkflowDashBoard } from "appstate/workflow/workflowSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
const DashBoard = () => {
  const navigate = useNavigate();

  const columns = [
    {
      name: "Date",
      selector: (row) => row.dateSent,
    },
    {
      name: "Sender",
      selector: (row) => row.sender,
    },

    {
      name: "Transaction Type",
      selector: (row) => row.type,
    },
    {
      name: "Action Type",
      selector: (row) => row.action,

      cell: (row) =>
        row.actionType === "Invoice Approval" ? (
          <p className="text-black font-bold">{row.action}</p>
        ) : (
          <>{row.action}</>
        ),
    },
    {
      name: "Approval Date",
      selector: (row) => row.approvalDate,
    },

    {
      name: "Approval Level",
      selector: (row) => row.approvalStage,
    },

    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) =>
        row.status === "APPROVED" ? (
          <p className="text-[#2EA923]">{row.status}</p>
        ) : row.status === "DECLINED" ? (
          <p className="text-[#DE1515]">{row.status}</p>
        ) : row.status === "COMPLETED" ? (
          <p className="text-[#2EA923]">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        ),
    },
  ];

  const handleRowClick = (row) => {
    if (
      row.status === "COMPLETED" &&
      row.action !== "Sign PFP Agreement" &&
      row.action !== "Fund Offer"
    ) {
      navigate(
        `/work-flow-management/workflowcompletedpendingapprovaldetails/${row.workflowIdt}`
      );
    } else if (row.status === "COMPLETED" && row.action === "Fund Offer") {
      navigate(
        `/work-flow-management/detail/pendingrequestsdetailssignpfp/${row.workflowId}`
      );
    } else if (
      row.status === "DECLINED" &&
      row.action !== "Sign PFP Agreement" &&
      row.action !== "Fund Offer"
    ) {
      navigate(
        `/work-flow-management/workflowdeclinedpendingapprovaldetails/${row.workflowId}`
      );
    } else if (row.type === "PFP" && row.action === "Fund Offer") {
      navigate(
        `/work-flow-management/detail/PendingRequestPFPFundOffer/${row.workflowId}`
      );
    } else if (
      row.type === "PFP" &&
      row.action === "Sign PFP Agreement" &&
      row.status === "COMPLETED"
    ) {
      navigate(
        `/work-flow-management/detail/pendingrequestsdetailssignpfp/${row.workflowId}`
      );
    } else if (
      row.type === "PFP" &&
      row.action === "Sign PFP Agreement" &&
      row.status === "DECLINED"
    ) {
      navigate(
        `/work-flow-management/detail/pendingrequestsdetailssignpfp/${row.workflowId}`
      );
    } else if (
      row.type === "PFP" &&
      row.action === "Sign PFP Agreement" &&
      row.status === "APPROVED"
    ) {
      navigate(
        `/work-flow-management/detail/pendingrequestsdetailssignpfp/${row.workflowId}`
      );
    } else if (
      row.status === "APPROVED" &&
      row.action !== "Sign PFP Agreement" &&
      row.action !== "Fund Offer"
    ) {
      navigate(
        `/work-flow-management/workflowapprovedpendingapprovaldetails/${row.workflowId}`
      );
    }
  };

  const dispatch = useDispatch();

  const { allWorkflowDashboard } = useSelector((state) => state.workflow);
  useEffect(() => {
    dispatch(fetchWorkflowDashBoard());
  }, [dispatch]);

  return (
    <div>
      <div className="bg-[#EBEBEB]">
        <div className=" py-4 grid md:grid-cols-[32%,32%,32%]  lg:grid-cols-[32%,32%,32%] gap-5">
          <div
            class="h-[135px] px-5 py-4 bg-white rounded-[10px] flex-col justify-start items-start gap-2.5 inline-flex cursor-pointer"
            onClick={() =>
              navigate("/work-flow-management/workflowpendingapprovalrequests")
            }
          >
            <div class="self-stretch justify-between items-center inline-flex">
              <div class="flex-col justify-start items-start gap-4 inline-flex">
                <div class="text-[#07593d]   text-[16.4px] font-medium ">
                  Pending Approvals{" "}
                </div>
                <div class="self-stretch text-[#07593d] text-[43px] font-bold ">
                  {allWorkflowDashboard?.pendingApprovalsCount &&
                    allWorkflowDashboard?.pendingApprovalsCount}
                </div>
              </div>
              <PAIconWorkFlowArrowLeft />
            </div>
          </div>

          {/* Next level box */}

          <div
            class="h-[135px] px-5 py-4 bg-white rounded-[10px] flex-col justify-start items-start gap-2.5 inline-flex"
            onClick={() => navigate("/work-flow-management/pendingrequests")}
          >
            <div class="self-stretch justify-between items-center inline-flex">
              <div class="flex-col justify-start items-start gap-4 inline-flex">
                <div class="text-[#07593d]   text-[16.4px] font-medium ">
                  Pending Requests{" "}
                </div>
                <div class="self-stretch text-[#07593d] text-[43px] font-bold ">
                  {allWorkflowDashboard?.pendingRequestsCount &&
                    allWorkflowDashboard?.pendingRequestsCount}
                </div>
              </div>
              <PAIconWorkFlowArrowLeft />
            </div>
          </div>

          {/* third */}

          <div
            class="h-[135px] px-5 py-4 bg-white rounded-[10px] flex-col justify-start items-start gap-2.5 inline-flex cursor-pointer"
            onClick={() =>
              navigate("/work-flow-management/workflowdclinedrequests")
            }
          >
            <div class="self-stretch justify-between items-center inline-flex">
              <div class="flex-col justify-start items-start gap-4 inline-flex">
                <div class="text-[#bb0c0c]   text-[16.4px] font-medium ">
                  Declined Requests
                </div>
                <div class="self-stretch text-[#bb0c0c] text-[43px] font-bold ">
                  {allWorkflowDashboard?.declinedRequestsCount &&
                    allWorkflowDashboard?.declinedRequestsCount}
                </div>
              </div>
              <PAIconWorkFlowArrowRedLeft />
            </div>
          </div>
        </div>

        <>
          <Card className="rounded-[10px] mt-[24px]   pb-36">
            <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
              <div>
                <p className="text-[#1d0220] text-sm font-bold">
                  Recent Approvals
                </p>
                <p className="text-[#5f4d61] text-[10px] font-normal">
                  Workflow approval history
                </p>
              </div>
              <p
                onClick={
                  () => {
                    navigate(
                      "/work-flow-management/detail/approvalhistoryalldetails"
                    );
                  }
                  // navigate("/reimbursement/detail/directfundingpageDetail")
                }
                className="text-black text-sm font-medium underline cursor-pointer"
              >
                {/* {allWorkflowDashboard?.recentApprovals?.data.length > 1 &&
                  "View All"} */}
                View All
              </p>
            </div>
            <div className="px-[24px] pb-[24px]">
              {allWorkflowDashboard?.recentApprovals?.data &&
              allWorkflowDashboard?.recentApprovals?.data.length > 0 ? (
                <Table
                  columns={columns}
                  data={allWorkflowDashboard?.recentApprovals?.data}
                  onRowClicked={(row) => handleRowClick(row)}
                  pointer
                  tableHeader
                  className="mt-5"
                />
              ) : (
                <TableEmptyState dashboard />
              )}
            </div>
          </Card>
        </>
      </div>
    </div>
  );
};

export default DashBoard;
