import { PAIconArrowLeftGreen, PAIconSmallClose } from "assets/images/svgs";
import RightSideModal from "shared/RightSideModal";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  allRules,
  ruleById,
  updateStatus,
} from "appstate/rulesandconfig/rulesandconfigSlice";
import { clearFormData, updateField } from "../../earlyPaymentFormSlice";
import Loading from "shared/Loading";
import { useParams } from "react-router-dom";

function TestRuleModal({ closeModal, id, handleBack }) {
  const dispatch = useDispatch();
  const { modalLoading, ruleByIdData } = useSelector(
    (state) => state?.rulesAndConfig
  );
  const { ruleType } = useParams();
  const data = ruleByIdData?.data;

  useEffect(() => {
    dispatch(ruleById({ id, ruleType }));
  }, [dispatch, id, ruleType]);

  const [ruleActivated] = useState(data?.status === "Activated");

  const [ruleStatus] = useState(ruleActivated ? "Activated" : "Deactivated");

  //   const [selectedAction] = useState("none");
  const [selectedActions, setSelectedActions] = useState([]);
  const [rows, setRows] = useState([]);
  //   const [ruleNameEdit, setRuleNameEdit] = useState(true);

  const [ruleName, setRuleName] = useState("");

  //   const [isDropdownVisible, setDropdownVisible] = useState(true);

  //   const toggleRuleActivated = () => {
  //     setRuleActivated(!ruleActivated);
  //     setRuleStatus(ruleActivated ? "Deactivated" : "Activated");
  //   };

  //   function formatString(inputString) {
  //     const words = inputString.split("_");
  //     const capitalizedWords = words.map(
  //       (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  //     );
  //     const formattedString = capitalizedWords.join(" ");
  //     return formattedString;
  //   }

  //   const handleDropdownChange = (e) => {
  //     const selectedValue = e.target.value;

  //     if (!selectedActions.some((action) => action.type === selectedValue)) {
  //       setSelectedActions((prevActions) => [
  //         ...prevActions,
  //         { type: selectedValue, value: "" },
  //       ]);
  //     }
  //   };

  //   const handleInputChange = (selectedValue, value) => {
  //     setSelectedActions((prevActions) =>
  //       prevActions.map((action) =>
  //         action.type === selectedValue ? { ...action, value } : action
  //       )
  //     );
  //   };

  //   const handleRuleNameChange = (e) => {
  //     setRuleName(e.target.value);
  //   };

  //   const handleAddAction = () => {
  //     setDropdownVisible(true);
  //   };

  //   const handleRemoveAction = (index) => {
  //     setSelectedActions((prevActions) =>
  //       prevActions.filter((_, i) => i !== index)
  //     );
  //   };

  const handleClickNext = () => {
    dispatch(updateStatus({ id: id, status: ruleStatus, ruleType })).then(
      (data) => {
        // if (data?.payload?.success === true) {
        if (data?.meta?.requestStatus === "fulfilled") {
          dispatch(allRules({ page: 0, pageSize: 10, ruleType }));
        }
      }
    );
    dispatch(clearFormData());
    dispatch(updateField({ field: "name", value: ruleName }));
    dispatch(updateField({ field: "conditions", value: rows }));
    dispatch(updateField({ field: "actions", value: selectedActions }));
    closeModal();
  };

  useEffect(() => {
    setRows(data?.conditions);
    setSelectedActions(data?.actions);
    setRuleName(data?.name);
  }, [data?.actions, data?.conditions, data?.name]);

  return (
    <>
      <RightSideModal closeModal={closeModal}>
        {modalLoading ? (
          <div className="w-full h-screen py-10 flex justify-center items-center">
            <Loading />
          </div>
        ) : (
          <div className="flex flex-col gap-3 py-4">
            <header className="flex px-[4%] pb-6 border-b-[3.25px] border-[#F6F5FA]   items-center justify-between">
              <span className="font-bold text-xl">Testing Rule </span>

              <span
                onClick={closeModal}
                className=" cursor-pointer flex gap-2 items-center"
              >
                <PAIconSmallClose />

                <span>Close</span>
              </span>
            </header>

            <div
              onClick={() => handleBack()}
              className="px-[4%] w-fit flex cursor-pointer items-center gap-1"
            >
              <PAIconArrowLeftGreen />{" "}
              <span className="font-light text-sm text-primaryColor">
                Go Back
              </span>
            </div>

            <div className="px-[4%] mt-2">
              <span>Rule name: </span>
              <span className="font-normal text-primaryColor">
                {data?.name}
              </span>{" "}
            </div>
            <main className="py-3 flex flex-col gap-8">
              <section className="px-[4%] flex flex-col gap-2">
                <div>
                  <span className="text-xl font-light">Parameters </span>
                </div>
                <div className="flex flex-col gap-2">
                  <span className="text-sm text-primaryColor">
                    Invoice Amount
                  </span>
                  <div className=" items-center flex w-full rounded-[5px] outline-none border-[0.5px] border-primaryColor">
                    <input
                      //   value={ruleName}
                      className="px-5 py-3 w-full rounded-[5px] outline-none bordr-[0.5px] border-primaryColor"
                      type="text"
                    />
                  </div>
                </div>

                <div className="flex flex-col gap-2">
                  <span className="text-sm text-primaryColor">
                    Payment day from invoice due date
                  </span>
                  <div className=" items-center flex w-full rounded-[5px] outline-none border-[0.5px] border-primaryColor">
                    <input
                      //   value={ruleName}
                      className="px-5 py-3 w-full rounded-[5px] outline-none bordr-[0.5px] border-primaryColor"
                      type="text"
                    />
                  </div>
                </div>

                <div className="flex flex-col gap-2">
                  <span className="text-sm text-primaryColor">
                    Request Amount
                  </span>
                  <div className=" items-center flex w-full rounded-[5px] outline-none border-[0.5px] border-primaryColor">
                    <input
                      //   value={ruleName}
                      className="px-5 py-3 w-full rounded-[5px] outline-none bordr-[0.5px] border-primaryColor"
                      type="text"
                    />
                  </div>
                </div>
              </section>

              <footer className="px-[4%] pt-10">
                <div className="flex justify-end">
                  <div className="flex gap-3">
                    <button
                      onClick={() => closeModal()}
                      className=" border border-primaryColor text-primaryColor text-xs py-2.5 px-5 rounded-[5px]"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => handleClickNext()}
                      disabled={selectedActions?.length === 0}
                      className={` ${
                        selectedActions?.length === 0
                          ? "bg-[#DADADA]"
                          : "bg-primaryColor"
                      }  text-white   text-xs py-2.5 px-5 rounded-[5px]`}
                    >
                      Run Test
                    </button>
                  </div>
                </div>
              </footer>
            </main>
          </div>
        )}
      </RightSideModal>
    </>
  );
}

export default TestRuleModal;
