import React from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const FunderOpenMarketList = ({ summary }) => {
  const navigate = useNavigate();
  return (
    <div>
      {" "}
      {summary?.data?.openMarket?.map((item) => {
        return (
          <>
            <motion.div
              onClick={() => navigate("/open-market")}
              whileTap={{ scale: 0.95 }}
              className="w-full cursor-pointer rounded-lg bg-white shadow-sm mb-5 "
            >
              <div className="w-full">
                {/* Wrap content in a flex container */}
                <div className="w-full flex flex-row justify-between pt-5 px-5">
                  <div className="flex flex-row">
                    <span className="inline pr-3">
                      <img src="/invoice-home.svg" alt="Invoice" />
                    </span>

                    <span className="text-black  text-base font-semibold">
                      {item?.referenceNumber} <br />
                      <span className="text-gray-500  text-xs font-normal">
                        Invoice number
                      </span>
                    </span>
                  </div>

                  {/* Move the lock button here */}
                  <div>
                    <button className="border border-green-800 rounded-md flex px-3 py-1.5 gap-2 items-center">
                      Lock <img src="/unlock.png" alt="Lock" />
                    </button>
                  </div>
                </div>

                <div className=" w-full lg:flex flex-row">
                  <div className="lg:w-[80%] w-[100%] flex flex-col  sm:flex-row justify-start p-5">
                    <div className=" w-full flex flex-col mb-4 sm:mb-0 sm:mr-8">
                      <div className=" w-full flex gap-2 flex-col">
                        <div className="w-full flex flex-row">
                          <span className="w-1/2 text-black font-semibold  text-base text-justify">
                            Description:
                          </span>
                          <span className="w-1/2  flex flex-row justify-start text-gray500  text-base font-normal">
                            {item?.description}
                          </span>
                        </div>
                        <div className="w-full flex flex-row">
                          <span className="w-1/2 text-black font-semibold  text-base text-justify">
                            Invoice Amount:
                          </span>
                          <span className="w-1/2 flex flex-row justify-start text-gray500  text-base font-normal">
                            {item?.invoiceAmount}
                          </span>
                        </div>
                        <div className="w-full flex flex-row">
                          <span className="w-1/2 text-black font-semibold  text-base text-justify">
                            Invoice Date:
                          </span>
                          <span className="w-1/2 flex flex-row justify-start text-gray500  text-base font-normal">
                            {item?.requestDate}
                          </span>
                        </div>
                        <div className="w-full flex flex-row">
                          <span className="w-1/2 text-black font-semibold  text-base text-justify">
                            Due Date:
                          </span>
                          <span className="w-1/2 flex flex-row justify-start text-gray500  text-base font-normal">
                            {item?.paymentDate}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Buyer and Supplier */}
                  <div className="flex w-[100%] lg:w-[50%] flex-col lg:py-5 pb-5 px-4">
                    <div className="flex flex-row">
                      <span className="inline pr-3">
                        <img src="/abc.svg" alt="Invoice" />
                      </span>
                      <span className="text-black  text-base font-semibold">
                        {item?.buyerName} <br />
                        <span className="text-gray-500  text-xs font-normal">
                          Buyer
                        </span>
                      </span>
                    </div>
                    <div className="flex flex-row">
                      <span className="inline pr-3">
                        <img src="/coke.svg" alt="Invoice" />
                      </span>
                      <span className="text-black  text-base font-semibold">
                        {item?.supplierName} <br />
                        <span className="text-gray-500  text-xs font-normal">
                          Supplier
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
            {/* <div className="w-full rounded-lg bg-white shadow-sm mb-5 transition duration-300 ease-in-out transform hover:scale-105">
              <div className="w-full">
                <div className="w-full flex flex-row justify-between pt-5 px-5">
                  <div className="flex flex-row">
                    <span className="inline pr-3">
                      <img src="/invoice-home.svg" alt="Invoice" />
                    </span>

                    <span className="text-black  text-base font-semibold">
                      INV-001 <br />
                      <span className="text-gray-500  text-xs font-normal">
                        Invoice number
                      </span>
                    </span>
                  </div>

                  <div>
                    <button className="border border-green-800 rounded-md flex px-3 py-1.5 gap-2 items-center">
                      Lock <img src="/unlock.png" alt="Lock" />
                    </button>
                  </div>
                </div>

                <div className=" w-full lg:flex flex-row">
                  <div className="lg:w-[80%] w-[100%] flex flex-col  sm:flex-row justify-start p-5">
                    <div className=" w-full flex flex-col mb-4 sm:mb-0 sm:mr-8">
                      <div className=" w-full flex gap-2 flex-col">
                        <div className="w-full flex flex-row">
                          <span className="w-1/2 text-black font-semibold  text-base text-justify">
                            Description:
                          </span>
                          <span className="w-1/2  flex flex-row justify-start text-gray500  text-base font-normal">
                            Widget Delivery
                          </span>
                        </div>
                        <div className="w-full flex flex-row">
                          <span className="w-1/2 text-black font-semibold  text-base text-justify">
                            Invoice Amount:
                          </span>
                          <span className="w-1/2 flex flex-row justify-start text-gray500  text-base font-normal">
                            $5,000
                          </span>
                        </div>
                        <div className="w-full flex flex-row">
                          <span className="w-1/2 text-black font-semibold  text-base text-justify">
                            Invoice Date:
                          </span>
                          <span className="w-1/2 flex flex-row justify-start text-gray500  text-base font-normal">
                            2023-11-01
                          </span>
                        </div>
                        <div className="w-full flex flex-row">
                          <span className="w-1/2 text-black font-semibold  text-base text-justify">
                            Due Date:
                          </span>
                          <span className="w-1/2 flex flex-row justify-start text-gray500  text-base font-normal">
                            2023-12-01
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex w-[100%] lg:w-[50%] flex-col lg:py-5 pb-5 px-4">
                    <div className="flex flex-row">
                      <span className="inline pr-3">
                        <img src="/abc.svg" alt="Invoice" />
                      </span>
                      <span className="text-black  text-base font-semibold">
                        ABC Limited <br />
                        <span className="text-gray-500  text-xs font-normal">
                          Buyer
                        </span>
                      </span>
                    </div>
                    <div className="flex flex-row">
                      <span className="inline pr-3">
                        <img src="/coke.svg" alt="Invoice" />
                      </span>
                      <span className="text-black  text-base font-semibold">
                        Cocacola <br />
                        <span className="text-gray-500  text-xs font-normal">
                          Supplier
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </>
        );
      })}
    </div>
  );
};

export default FunderOpenMarketList;
