import { PAIconArrowLeftGreen, PAIconModelOne } from "assets/images/svgs";
import React, { useEffect, useState } from "react";
// import { useLocation } from "react-router-dom";
import ConfirmDiscountRateModal from "./ConfirmDiscountRateModal";
import AccountModalContainer from "shared/accountModal/Modal";
import { AnimatePresence } from "framer-motion";
import AddFundModal from "./AddFundModal";
import ViewDocumentModal from "features/RulesConfig/common/components/modals/DeleteRuleModal";
import SuccessModal from "shared/SuccessModal";
import SelectAccountModal from "./modalcomponent/SelectAccountModal";
import ImportInvoicesModal from "./importInvoicesModal";
import EnterPin from "features/openMarket/funder/components/modal/Pin";
import {
  allEarlyPaymentRequestBulkPending,
  allEarlyPaymentRequestSinglePending,
  getUpdateDiscountRatePreview,
  importEarlyPaymentToCloseMarket,
  pendingSingleEarlyPaymentDetails,
} from "appstate/buyer/buyerSlice";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { allInvoiceById } from "appstate/invoice/invoiceSlice";
import { formatDate } from "utilities/helper/dateFormatterSpelt";
import { formatCapitalize } from "utilities/formatCapitalize";
import { formatCapitaliseString } from "utilities/helper/formatCapitaliseString";
import { formatCurrency } from "utilities/helper/formatters";

const Details = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const earlyId = searchParams.get("earlyId");
  const { invoiceIdData } = useSelector((state) => state?.invoice);

  // const location = useLocation();
  // const { fundingChannel } = location.state || {};
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDiscountModalOpen, setIsDiscountModalOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [discountData, setDiscountData] = useState([]);
  const [isRejectModalOpen, setRejectModalOpen] = useState(false);
  const [selectedFunderIds, setSelectedFunderIds] = useState(false);
  const [addFundModal, setaddFundModal] = useState(false);
  // const [selectedContent, setSelectedContent] = useState([]);
  const [selectedAccountDetails, setSelectedAccountDetails] = useState([]);
  const [confirmSelectedAccountModal, setConfirmSelectedAccountModal] =
    useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isSelectAccountModalOpen, setIsSelectAccountModalOpen] =
    useState(false);

  const [successMessage, setSuccessMessage] = useState("");

  const [pinModal, setPinModal] = useState(false);

  const status = "PENDING_APPROVAL";

  const { pendingSingleEarlyPaymentDetailsData } = useSelector(
    (state) => state?.buyer
  );

  const handleBackBtnClick = () => {
    window.history.back();
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleAcceptProceed = () => {
    setSuccess(false);
    setIsModalOpen(false);
    setIsDiscountModalOpen(true);
  };

  const handleAccountSubmit = () => {
    setIsSelectAccountModalOpen(false);
    setTimeout(() => {
      setConfirmSelectedAccountModal(true);
    }, 500);

    // setIsProceedModalOpen(true);
  };

  const handleProceedEnterPin = () => {
    setConfirmSelectedAccountModal(false);
    setTimeout(() => {
      setPinModal(true);
    }, 500);
  };

  const handleImportInvoice = () => {
    setRejectModalOpen(false);
    setaddFundModal(true);
  };

  function handleProceedToImport() {
    setaddFundModal(false);
    setIsSelectAccountModalOpen(true);
  }

  const handleClosedMarketImport = (pin) => {
    const pendingToast = toast.loading("Importing Invoice to Closed Market...");

    let earlyPayment = discountData?.map(
      ({ earlyPaymentId, payableAmount, discountAmount }) => ({
        earlyPaymentId,
        payableAmount,
        discountAmount,
      })
    );

    let adjustedDiscountRequest = discountData?.map(
      ({ earlyPaymentId, invoiceId, discountRate }) => ({
        earlyPaymentRequestId: earlyPaymentId,
        invoiceId,
        newDiscountRate: discountRate,
      })
    );

    const body = {
      funderId: [...selectedFunderIds],
      earlyPayments: [...earlyPayment],
      virtualAccountId: selectedAccountDetails?.virtualAccountId,
      transactionPin: pin,
    };

    dispatch(
      getUpdateDiscountRatePreview({
        adjustDiscountRequests: [...adjustedDiscountRequest],
      })
    ).then((data) => {
      if (
        data?.payload?.success &&
        data?.payload?.data?.errorDiscounts?.length === 0
      ) {
        dispatch(importEarlyPaymentToCloseMarket(body)).then((data) => {
          if (data?.payload?.success) {
            toast.dismiss(pendingToast);
            setPinModal(false);
            setSuccessMessage(
              "Early Payment request sent for workflow approval"
            );
            setIsSuccessModalOpen(true);
            dispatch(allEarlyPaymentRequestBulkPending({ status }));
            dispatch(allEarlyPaymentRequestSinglePending({ status }));
            dispatch(pendingSingleEarlyPaymentDetails(id));
            setTimeout(
              () => navigate("/closedmarket/directEarlyPaymentRequests"),
              1200
            );
          } else {
            toast.dismiss(pendingToast);
            if (data?.payload?.status === 200)
              toast.error(data?.payload?.message);

            if (data?.payload?.status !== 200)
              toast.error(data?.payload?.message);
          }
        });
      } else {
        toast.dismiss(pendingToast);
        toast.error(data?.payload?.data?.errorDiscounts[0]?.message);
      }
    });
  };

  useEffect(() => {
    dispatch(allInvoiceById({ id }));
    dispatch(pendingSingleEarlyPaymentDetails(earlyId));
    // dispatch(pendingBulkEarlyPaymentDetails(earlyId));
  }, [dispatch, id, earlyId]);

  return (
    <>
      <div
        onClick={handleBackBtnClick}
        className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer w-full"
      >
        <PAIconArrowLeftGreen />
        <span className="flex items-center text-primaryColor">Go Back</span>
      </div>

      <div className="grid lg:grid-cols-[60%,40%] gap-5 min-h-[80vh]">
        {/* 60% */}
        <div className="flex flex-col gap-8 pb-24 bg-white rounded-[10px] pt-7">
          <div className="px-6 ">
            <div className="flex flex-col gap-3">
              <div className="flex items-center justify-between">
                <h1 className="text-[18px] font-bold">
                  Reference No: | {invoiceIdData?.data?.invoiceRef}
                </h1>
                {/* {paymentViaWallet === true ? (
                  <button className="w-[135px] h-[45px] rounded-[5px] bg-[#07593D] text-[white]">
                    Disburse Now
                  </button>
                ) : null} */}
              </div>

              <main className=" w-full flex justify-between">
                <div className="flex flex-col gap-3 w-1/2">
                  <div className="flex flex-col gap-1">
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs font-semibold w-[30%]">
                        Invoice Number:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        INV-{invoiceIdData?.data?.invoiceNo}
                      </span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs font-semibold w-[30%]">
                        Created Date:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {invoiceIdData?.data?.createdDate
                          ? formatDate(invoiceIdData?.data?.createdDate)
                          : "N/A"}
                      </span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs font-semibold w-[30%]">
                        Submitted Date:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {invoiceIdData?.data?.submittedDate
                          ? formatDate(invoiceIdData?.data?.submittedDate)
                          : "N/A"}
                      </span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs font-semibold w-[30%]">
                        Approved Date:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {invoiceIdData?.data?.approvedDate
                          ? formatDate(invoiceIdData?.data?.approvedDate)
                          : "N/A"}
                      </span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs font-semibold w-[30%]">
                        Due Date:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {invoiceIdData?.data?.dueDate
                          ? formatDate(invoiceIdData?.data?.dueDate)
                          : "N/A"}
                      </span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs font-semibold w-[30%]">
                        Issuance Date:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {invoiceIdData?.data?.issuanceDate
                          ? formatDate(invoiceIdData?.data?.issuanceDate)
                          : "N/A"}
                      </span>
                    </div>
                  </div>
                </div>
                <div className=" flex flex-col items-end">
                  <button
                    onClick={() => {
                      setIsModalOpen(true);
                    }}
                    className="px-4 py-2.5 text-sm  bg-primaryColor rounded-[5px] text-white"
                  >
                    Import Selected Request
                  </button>

                  <div className="mt-5 items-end">
                    <button className="px-8 py-2  text-xs bg-[#D8EFD6] rounded-[4px] text-[#2EA923]">
                      {formatCapitaliseString(invoiceIdData?.data?.status) ||
                        "Approved"}
                    </button>
                  </div>
                </div>
              </main>
            </div>

            <div className="flex justify-between my-5">
              <div className="flex flex-col">
                <span className=" font-bold text-sm">
                  {formatCapitalize(invoiceIdData?.data?.buyerCompanyName)}
                </span>
                <span className="text-[#979797] text-xs">Buyer Details</span>
              </div>
              <div className="flex flex-col">
                <span className="font-bold text-sm">
                  {invoiceIdData?.data?.paymentTerms} Days
                </span>
                <span className="text-[#979797] text-xs">Payment</span>
              </div>
              <div className="flex flex-col">
                <span className="font-bold text-sm">NGN</span>
                <span className=" text-[#979797] text-xs">Currency</span>
              </div>
            </div>

            <div className="flex flex-col gap-4 mt-5">
              <header className="border-b-[5px] flex justify-between items-center border-b-[#F6F5FA] pb-3">
                <h1 className="text-sm font-bold">Line Items</h1>

                <span className="text-[#11192A] font-light text-lg">
                  {invoiceIdData?.data?.items?.length} Total Items
                </span>
              </header>
              <div className=" w-full overflow-x-auto">
                <table className="overflow-x-auto wf">
                  <thead className="bg-subtleGray h-[70px] w-[50vw] overflow-x-auto">
                    <tr className=" bg-unusualGray p-2.5">
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Item
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Description
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Unit Price
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Quantity
                      </td>
                      <td className="text-[#11192A] p-2.5 text-xs font-bold w-[120px]">
                        Amount
                      </td>
                      <td className="text-[#11192A] p-2.5 text-xs font-bold w-[120px]">
                        Tax rate
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Tax Amount
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Total Price
                      </td>
                    </tr>
                  </thead>
                  {invoiceIdData?.data?.items?.map((item, index) => (
                    <tbody key={index}>
                      <tr className=" py-[3px] px-1.5">
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {formatCapitalize(item?.itemName)}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5 w-[20%]">
                          {formatCapitalize(item?.description)}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {formatCurrency(item?.unitPrice)}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.quantity}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {formatCurrency(item?.amount)}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.taxRate}%
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {formatCurrency((item?.taxRate / 100) * item?.amount)}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {formatCurrency(
                            item?.amount + (item?.taxRate / 100) * item?.amount
                          )}
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            </div>

            <div className="flex itemscenter justify-between px-6 mt-[10%]">
              <div>
                <h1 className="border-b border-[#B1A6B2] font-semibold">
                  Receiving Funding Account Information
                </h1>
                <p className="mt-3 text[#979797]">
                  {invoiceIdData?.data?.virtualAccount?.accountNumber}
                </p>
                <p className="text[#979797]">
                  {formatCapitalize(
                    invoiceIdData?.data?.virtualAccount?.accountName
                  )}
                </p>
              </div>
              <div className="flex flex-col">
                <div className="flex justify-between gap-x-[100px] w-full">
                  <span className="text-xs w-[97px]">Sub Total</span>
                  <span className="text-[#979797] text-xs text-right">
                    {formatCurrency(invoiceIdData?.data?.subTotal)}
                  </span>
                </div>

                <div className="flex justify-between w-full gap-x-[100px] my-2">
                  <span className="flex items-center text-xs w-[97px]">
                    Discount%
                    <span className="border border-[#979797] pr-10 pl-2 inline-block ml-4">
                      {invoiceIdData?.data?.discount
                        ? invoiceIdData?.data?.discount
                        : 0.0}
                      %
                    </span>
                  </span>
                  <span className="text-[#979797] text-xs">
                    {formatCurrency(
                      invoiceIdData?.data?.subTotal -
                        (invoiceIdData?.data?.subTotal -
                          (invoiceIdData?.data?.discount / 100) *
                            invoiceIdData?.data?.subTotal)
                    )}
                  </span>
                </div>

                <div className="flex justify-between w-full gap-x-[100px] pb-5">
                  <span className="flex items-center text-xs w-[97px]">
                    VAT%
                    <span className="border border-[#979797] ml-10 pr-11 pl-2 inline-block">
                      {invoiceIdData?.data?.vat}%
                    </span>
                  </span>
                  <span className="text-[#979797] text-xs">
                    {formatCurrency(
                      (invoiceIdData?.data?.vat / 100) *
                        invoiceIdData?.data?.subTotal
                    )}
                  </span>
                </div>

                <div className="flex justify-between items-center w-full gap-x-[100px] border-t-2 border-b-2 font-bold text-lg py-1">
                  <span className="w-[97px]">Grand Total</span>
                  <span className="text[#979797] py-1">
                    {formatCurrency(
                      invoiceIdData?.data?.subTotal -
                        (invoiceIdData?.data?.subTotal -
                          (invoiceIdData?.data?.subTotal -
                            (invoiceIdData?.data?.discount / 100) *
                              invoiceIdData?.data?.subTotal)) +
                        (invoiceIdData?.data?.vat / 100) *
                          invoiceIdData?.data?.subTotal
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 40% */}
        <div className="bg-white rounded-[10px] gap-8 pt-5 pb-24">
          <div className="px-4">
            <h2 className="font-semibold text-lg leading-[30px] mb-4">
              Early Payment Details
            </h2>

            <div className="flex justify-between my-5">
              <div className="flex gap-2">
                <PAIconModelOne />
                <div className="flex flex-col">
                  <span className="text-sm">
                    {" "}
                    {
                      pendingSingleEarlyPaymentDetailsData?.data
                        ?.supplierCompanyName
                    }
                  </span>
                  <span className="text-[#979797]  text-xs"> Supplier</span>
                </div>
              </div>
              <div className="flex flex-col">
                <span className="text-sm">
                  {pendingSingleEarlyPaymentDetailsData?.data?.discountRate}%
                </span>
                <span className="text-[#979797]  text-xs">Discount rate</span>
              </div>
            </div>

            <div className="flex justify-between pb-5">
              <div className="flex gap-2">
                <div className="flex flex-col">
                  <span className="text-sm">
                    {formatCurrency(
                      invoiceIdData?.data?.subTotal -
                        (invoiceIdData?.data?.subTotal -
                          (invoiceIdData?.data?.discount / 100) *
                            invoiceIdData?.data?.subTotal)
                    )}
                  </span>
                  <span className="text-[#979797]  text-xs">
                    Discount Amount
                  </span>
                </div>
              </div>
              <div className="flex flex-col">
                <span className="text-sm">
                  {formatDate(
                    pendingSingleEarlyPaymentDetailsData?.data?.dueDate
                  )}{" "}
                  ( {pendingSingleEarlyPaymentDetailsData?.data?.paymentDay}{" "}
                  days)
                </span>
                <span className="text-[#979797]  text-xs">
                  Early Payment Request Date
                </span>
              </div>
            </div>

            <div className="flex justify-between mt-5 border-b-[5px] border-b-[#F6F5FA] pb-10">
              <div className="flex gap-2">
                <div className="flex flex-col">
                  <span className="text-sm">
                    {formatCurrency(
                      pendingSingleEarlyPaymentDetailsData?.data?.requestAmount
                    )}
                  </span>
                  <span className="text-[#979797]  text-xs">
                    Request Amount
                  </span>
                </div>
              </div>
              <div className="flex flex-col">
                <span className="text-sm">
                  {" "}
                  {formatCurrency(
                    pendingSingleEarlyPaymentDetailsData?.data?.payableAmount
                  )}
                </span>
                <span className="text-[#979797]  text-xs">Payable amount</span>
              </div>
            </div>

            <div className="border-b-[5px] border-b-[#F6F5FA] pb-5">
              <h2 className="font-medium text-base my-3">
                Note from Supplier:
              </h2>
              <p className="text-[#959595] text-sm">
                {pendingSingleEarlyPaymentDetailsData?.data?.description}
              </p>
            </div>
          </div>

          {/* <AcceptModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            confirm={handleAcceptProceed}
            // checkedAccounts={checkedAccounts}
          /> */}

          <AccountModalContainer
            accontModal
            isShown={isModalOpen}
            onClose={() => {
              handleCloseModal();
            }}
            success={success}
            handleAssociate={handleAcceptProceed}
            warning=" Are you sure you would like to import this requests?"
          />

          {isDiscountModalOpen && (
            <ConfirmDiscountRateModal
              preData={pendingSingleEarlyPaymentDetailsData?.data}
              setDiscountData={setDiscountData}
              isOpen={isDiscountModalOpen}
              onClose={() => {
                dispatch(pendingSingleEarlyPaymentDetails(earlyId));
                setIsDiscountModalOpen(false);
              }}
              onNext={() => {
                setIsDiscountModalOpen(false);
                handleImportInvoice();
              }}
            />
          )}
        </div>
      </div>

      <ImportInvoicesModal
        isOpen={isRejectModalOpen}
        onClose={handleCloseModal}
        handleImportInvoice={handleImportInvoice}
      />
      <AnimatePresence>
        {isSelectAccountModalOpen && (
          <SelectAccountModal
            overlayDark
            onClose={() => setIsSelectAccountModalOpen(false)}
            onAction={handleAccountSubmit}
            setSelectedAccountDetails={setSelectedAccountDetails}
          />
        )}
      </AnimatePresence>

      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={() => setIsSuccessModalOpen(false)}
        message={successMessage}
      />

      {/* Approve Selected Account */}
      <AnimatePresence>
        {confirmSelectedAccountModal && (
          <ViewDocumentModal
            onAction={() => handleProceedEnterPin()}
            onClose={() => setConfirmSelectedAccountModal(false)}
            proceed="Yes Proceed"
          >
            <span className="text-center">
              Are you sure you want to proceed with this account <br />
              <span className="font-bold italic text-center text-primaryColor">
                {selectedAccountDetails?.accountNumber}
              </span>{" "}
              <br />
              for the reimbursement of this request
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>

      {addFundModal && (
        <AddFundModal
          onClose={() => setaddFundModal(false)}
          handleProceed={handleProceedToImport}
          selectedFunderIds={selectedFunderIds}
          setSelectedFunderIds={setSelectedFunderIds}
        />
      )}

      <AnimatePresence>
        {pinModal && (
          <EnterPin
            onClose={() => setPinModal(false)}
            onSubmit={(pin) => {
              handleClosedMarketImport(pin);
            }}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default Details;
