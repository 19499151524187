import DashboardLayout from "layout/dashboardlayout";
import React from "react";
import Details from "./details";

const EarlyPaymentOffersPreview = () => {
  return (
    <DashboardLayout
      section="Collections"
      subHeader="Track invoices due for payment collection"
    >
      <Details />
    </DashboardLayout>
  );
};

export default EarlyPaymentOffersPreview;
