import { motion } from "framer-motion";
import TopContestedInvoice from "./TopContestedInvoice";
import TransitionOpacity from "shared/animation/TransitionOpacity";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { funderDashboard } from "appstate/openMarket/openMarketSlice";
import { toggleCancelButtonOff } from "./buttonSlice";
import LoadingScreen from "shared/LoadingScreen";

function Overview({ setActiveTab }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(toggleCancelButtonOff());
    dispatch(funderDashboard());
  }, [dispatch]);

  const { isLoading, funderDashboardData } = useSelector(
    (state) => state?.openMarket
  );

  return (
    <TransitionOpacity className="flex flex-col gap-8">
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <div className="flex flex-col semi-lg:flex-row gap-7 w-full ">
            <section className=" rounded-[5px] bg-unusualGray flex gap-6 flex-col extra-md:flex-row px-6 py-5 w-full semi-lg:w-[70%]">
              <div className="flex w-full h-fit   gap-[18px]">
                <div className="text-white flex w-1/2  border-b-2 border-b-white rounded-[10px] flex-col items-center justify-center bg-[#F08000] px-[60px] py-6">
                  <span className=" text-[50px] font-bold">
                    {funderDashboardData?.totalInvoicesInOpenMarket || "N/A"}
                  </span>
                  <span className=" font-semibold text-center ">
                    Invoice in the Open Market
                  </span>
                </div>

                <div className="flex px-3 rounded-b-[10px] w-1/2 flex-wrap bg-white border-t-2 border-t-[#f08000] flex-col pt-5 pb-4 gap-4">
                  <div className="flex  flex-wrap justify-between">
                    <span className="text-[20px] text-primaryColor">
                      Invoices
                    </span>
                    <motion.button
                      whileTap={{ scale: 0.9 }}
                      className=" bg-[#f08000] text-white rounded-[5px] px-2 py-1"
                    >
                      Insight
                    </motion.button>
                  </div>

                  <div className="flex">
                    <div className="flex w-1/2 border-r border-primaryColor flex-col px-5">
                      <span className="text-[40px] text-center font-bold text-primaryColor">
                        {funderDashboardData?.totalOpenInvoices || 0}
                      </span>
                      <span className=" text-center text-sm text-[#565656]">
                        Opened
                      </span>
                    </div>

                    <div className="flex w-1/2 px-5 flex-col">
                      <span className="text-[40px] text-center font-bold text-[#F08000]">
                        {funderDashboardData?.totalInvoicesInOpenMarket -
                          funderDashboardData?.totalOpenInvoices || 0}
                      </span>
                      <span className=" text-center text-sm text-[#565656]">
                        Closed
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="flex gap-4 flex-col semi-lg:w-[30%] justify-between">
              <div className="border-l-2 w-full border-white flex items-center justify-between px-8  py-3 bg-primaryColor rounded-r-[10px] cursor-pointer text-white">
                <span className=" text-[50px] font-bold">
                  {funderDashboardData?.pendingBids || 0}
                </span>
                <span className=" text-sm">
                  Active bids you are not <br /> currently leading
                </span>
              </div>
              <div className=" border-l-2 border-primaryColor flex items-center justify-between px-8  py-3 bg-white text-primaryColor rounded-r-[10px] cursor-pointer">
                <span className=" text-[50px] font-bold">
                  {funderDashboardData?.acceptedBids || "N/A"}
                </span>
                <span className=" text-[#565656] text-sm">
                  Active bids you are <br /> currently leading
                </span>
              </div>
            </section>
          </div>

          <TopContestedInvoice
            head="Won Bids"
            subHead="Your accepted bids that are liened for funding"
            topTenBids={funderDashboardData?.top10ContestedBids ?? []}
            setActiveTab={setActiveTab}
          />
        </>
      )}
    </TransitionOpacity>
  );
}

export default Overview;
