import request from "apiInstance";
import toast from "react-hot-toast";

export const funderTransactions = async () => {
  try {
    const response = await request({
      method: "get",
      url: "/api/v1/funders/transactions?page&size&status",
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const funderOpenMarket = async () => {
  try {
    const response = await request({
      method: "get",
      url: "/api/v1/funders/market?page&size&filter",
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllFunders = async ({page, pageSize, role = "funders", companyName}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/buyers/unassociated-other-players?page=${page}&size=${pageSize}&role=${role}&search=${companyName}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllNewFundersSuppliers = async ({page, pageSize, role = "funders", companyName}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/suppliers/unassociated-other-players?page=${page}&size=${pageSize}&role=${role}&search=${companyName}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};


export const acceptSupplierAssociationBuyer = async (notificationId, supplierId) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/funders/buyer-association-request/accept/${notificationId}?supplierId=${supplierId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};


export const acceptSupplierAssociationFunder = async (notificationId, supplierId) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/funders/supplier-association-request/accept/${notificationId}?supplierId=${supplierId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};


export const rejectbuyerAssociationFunder = async (notificationId) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/funders/association-request/reject/${notificationId}`,
    });
    toast.success("Supplier association request rejected successfully!");
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};


export const rejectSupplierAssociationFunder = async (notificationId) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/funders/supplier-association-request/reject/${notificationId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};


export const getPendingAssociationFunderSupplier = async (page, size, sender, companyName) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/funders/pending-association-requests?page=${page}&size=${size}&sender=${sender}&search=${companyName}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getPendingAssociationFunderBuyer = async (page, size, sender) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/funders/pending-association-requests?page=${page}&size=${size}&sender=buyer`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const searchFunder = async ({ companyName }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/funders/search?fullName=${companyName}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};