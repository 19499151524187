import Table from "layout/table/Table";
import React, { useCallback, useEffect, useState } from "react";
import Button from "shared/Button";
import BuyerDetailsModal from "../modal";
import DashboardLayout from "layout/dashboardlayout";
import { PAIconInventoryArrowLeft, PAIconSearch } from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import AccountModalContainer from "shared/accountModal/Modal";
import DeclineModal from "shared/delineModal/Modal";
import Loading from "shared/Loading";
import UserAdd from "../../../../../assets/images/svgs/useradd.svg";
import Pagination from "shared/Pagination";
import { SupplierGetSentPendingInvitesBuyer, supplierAcceptBuyerRequest, supplierGetPendingAssociationRequest, supplierRejectBuyerRequest } from "appstate/api/buyer/relationshipManagement";
import { debounce } from 'lodash';
import SuccessRelationship from "shared/SuccessRelationship";


const SupplierPendingRequestBuyer = () => {
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [opened, setOpened] = useState(false);
  // eslint-disable-next-line
  const [openDeclineModal, setOpenDeclineModal] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setIsLoading] = useState(false);
  // eslint-disable-next-line
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const pageSize = 10;
  const [total, setTotal] = useState("");
  const [selectedTab, setSelectedTab] = useState("Pending Requests");
  const [successModal, setSuccessModal] = useState(false);
  const [nonsuccessModal, setNonSuccessModal] = useState(false);
  // const [selectedTab, setSelectedTab] = useState("Pending Requests");
  const {
    isLoading,
    // funderPendingRequestData,
    associateLoading,
    declineLoading,
  } = useSelector((state) => state.funder);
  const [data, setData] = useState([]);
  const sender = "supplier";

  const fetchPendingRequests = async (searchValue = "") => {
    setIsLoading(true);
    try {
      let pendingRequests = [];
      const entityType = "buyer"; 
      const sender = "buyer";
      // const searchParams = searchValue.trim();
      const size = pageSize;
      
      if (selectedTab === "Pending Requests") {
        pendingRequests = await supplierGetPendingAssociationRequest({
          page,
          pageSize: size,
          entityType,
          companyName
        });
      } else if (selectedTab === "Pending Invites") {
        pendingRequests = await SupplierGetSentPendingInvitesBuyer({
          page,
          pageSize,
          sender,
        });
      }
      setTotal(pendingRequests?.meta?.totalElements);
      setData(pendingRequests?.data || []);
    } catch (error) {
      console.error("Failed to fetch requests", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTabClick = (tabName) => {
    setSelectedTab(tabName);
  };

  useEffect(() => {
    fetchPendingRequests ();
    // eslint-disable-next-line 
  }, [selectedTab, page, pageSize, sender, companyName]);
  // eslint-disable-next-line 
  const debouncedSearch = useCallback(
    debounce((searchValue) => {
      fetchPendingRequests(searchValue);
    }, 200),  
    [selectedTab, page, pageSize, sender, companyName]
  );

  const handleSearchInputChange = (e) => {
    const value = e.target.value;
    setCompanyName(value); 
    debouncedSearch(value);
};

   // eslint-disable-next-line
  // const debouncedSearch = useCallback(
  //   _.debounce(() => {
  //     fetchPendingRequests();
  //   }, 200),
  //   [companyName]
  // );

  const handleOpenModal = (row) => {
    setGetId(row?.notificationId);
    setOpened(true);
  };

  const handleOpenDeclineModal = (row) => {
    setGetId(row?.notificationId);
    setOpenDeclineModal(true);
  }

  useEffect(() => {
    if (companyName) {
      debouncedSearch();
    } else {
      fetchPendingRequests();
    }
    // eslint-disable-next-line
  }, [companyName, debouncedSearch]);
  


  // useEffect(() => {
  //   if (companyName.length <= 0) {
  //     setData(funderPendingRequestData?.data);
  //   }
  // }, [funderPendingRequestData, companyName]);

  const [getId, setGetId] = useState(null);
  const onRowClicked = (row) => {
    setOpenDetailsModal(true);
    setGetId(row?.userId);
  };
 
  const handleSearch = () => {
    fetchPendingRequests();
  };

  const column = [
    {
      name: "Name",
      selector: (row) => row?.fullName,
    },
    {
      name: "Company",
      selector: (row) => row?.companyName,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
      width: "15rem",
    },
    {
      name: "Phone",
      selector: (row) => row?.phoneNumber,
    },
    {
      cell: (row) => (
        <div className="flex gap-x-[12px]">
          <Button
            onClick={() => handleOpenModal(row)}
            neutral
            className="flex px-[16px] py-[8px] border-[1px] border-gray rounded-[5px] bg-primaryColor"
          >
            <p className="text-xs font-normal text-white whitespace-nowrap">
              Accept
            </p>
          </Button>
          <Button
            onClick={() => handleOpenDeclineModal(row)}
            neutral
            className="flex px-[16px] py-[8px] border-[1px] border-[red] rounded-[5px] bg-[white]"
          >
            <p className="text-xs font-normal  text-[red] whitespace-nowrap">
              Decline
            </p>
          </Button>
        </div>
      ),
    },
  ];

  const handleAssociate = async () => {
    const notificationId = getId;
    if (!notificationId) {
      toast.error("Notification ID is missing!");
      return;
    }
    try {
      const response = await supplierAcceptBuyerRequest(notificationId);
      if (response?.success) {
        setSuccessModal(true)
        setSuccess(true);
        setOpened(false);
        // toast.success(response?.message || "Association request accepted!");
        fetchPendingRequests();
      } else {
        toast.error(response?.message || "Failed to accept association request.");
      }
    } catch (error) {
      console.error("Error accepting association request", error);
    }
  };

  const handleDecline = async () => {
    const notificationId = getId;
    if(!notificationId){
      toast.error("Notification ID is missing!");
      return;
    }
    try {
      const response = await supplierRejectBuyerRequest(notificationId);
      if(response?.success){
        setNonSuccessModal(true)
        setSuccess(true);
        setOpenDeclineModal(false);
        // toast.success(response?.message || "Association request declined!");
        fetchPendingRequests();
      } else {
        toast.error(response?.message || "Failed to accept association request.");
      }
    } catch (error) {
      console.error("Error accepting association request", error);
    }
  };

  const totalPages = total;

  const navigate = useNavigate();
  function handleNavigate() {
    navigate(-1);
  }

  const onClose = () => {
    setSuccessModal(false);
  };

  return (
    <DashboardLayout section="Buyers" subHeader="Manage all your associated buyer's account">
       <div
        style={{ padding: 15, borderRadius: 5 }}
        className="flex justify-between items-center bg-white"
      >
        <div className="w-[20%] flex justify-between">
          <h3
            className={`cursor-pointer ${
              selectedTab === "Pending Requests" ? "text-[#07593D]" : ""
            }`}
            onClick={() => handleTabClick("Pending Requests")}
            style={{
              borderBottom:
                selectedTab === "Pending Requests"
                  ? "2px solid #07593D"
                  : "none",
              paddingBottom: "5px",
              fontSize: "14px",
              color: "#959595",
            }}
          >
            Pending Requests
          </h3>
          <h3
            className={`cursor-pointer ${
              selectedTab === "Pending Invites" ? "text-[#07593D]" : ""
            }`}
            onClick={() => handleTabClick("Pending Invites")}
            style={{
              borderBottom:
                selectedTab === "Pending Invites"
                  ? "2px solid #07593D"
                  : "none",
              paddingBottom: "5px",
              fontSize: "14px",
              color: "#959595",
            }}
          >
            Pending Invites
          </h3>
        </div>
        <div>
          <Button
           onClick={() => navigate("/accounts/supplier-associate-buyer-request")}


            neutral
            className="flex text-[#F08000] text-xs font-medium items-center mb-[10px] md:mb-0 md:px-[5px] bg-[#07593D] py-[12px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px]"
          >
            <img src={UserAdd} alt="" />
            <p style={{ color: "white" }} className="whitespace-nowrap">
              Associate with buyer
            </p>
          </Button>
        </div>
      </div>
      <br />
      <div>
        <header className="bg-alabasterHeader p-[24px] rounded-t-[10px]">
        <div
            onClick={handleNavigate}
            className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
          >
            <PAIconInventoryArrowLeft />
            <p className="mt-[2px] text-sm font-normal text-lightGray">
              back
            </p>
          </div>
          <div className="flex justify-between flex-wrap ">
            <div>
              <p className="text-black text-sm font-medium">
                Pending Association Request
              </p>
              <p className="text-textColor text-xs font-normal">
                List of buyer pending request
              </p>
            </div>
            <div className="flex gap-x-[10px] mt-[10px] md:mt-0 flex-wrap">
              <div className="flex items-center  bg-white p-[10px] gap-x-[8px] rounded-[5px] border-[0.5px] border-silver xl:w-[350px]">
                <PAIconSearch />
                <input
                  type="text"
                  placeholder="Search Buyers"
                  value={companyName}
                  onChange={handleSearchInputChange}
                  className="placeholder:text-xs outline-none"
                />
              </div>
              <Button
                neutral
                disabled={data < 1}
                onClick={handleSearch}
                className="py-[12px] mt-[10px] md:mt-0 px-[24px] border-[0.5px] border-silver bg-brightGray text-sm !text-black rounded-[5px] font-medium"
              >
                Search
              </Button>
            </div>
          </div>
        </header>
        <BuyerDetailsModal
          isShown={openDetailsModal}
          onClose={() => setOpenDetailsModal(false)}
          getId={getId}
          isPendingRequest
        />
      {successModal && (
        <SuccessRelationship
          isOpen={successModal}
          onClose={onClose}
          message="Association successful"
        />
      )}
       {nonsuccessModal && (
        <SuccessRelationship
          isOpen={nonsuccessModal}
          onClose={onClose}
          message="Disassociation successful"
        />
      )}
      
        <DeclineModal
          isShown={openDeclineModal}
          onClose={() => setOpenDeclineModal(false)}
          success={success}
          handleAssociate={handleDecline}
          isLoading={declineLoading}
          warning="Are you sure you would like to Decline this request?"
        />
        <AccountModalContainer
          isShown={opened}
          onClose={() => setOpened(false)}
          success={success}
          handleAssociate={handleAssociate}
          isLoading={associateLoading}
          warning=" Are you sure you would like to associate with the buyer?"
        />
        <div className="p-[24px] bg-white rounded-b-[10px]">
          <Table
            columns={column}
            data={data}
            pointer
            onRowClicked={onRowClicked}
            progressPending={loading}
            progressComponent={<Loading />}
            tableHeader
          />
          <br />
          
          <div className="flex justify-center gap-x-[8px]  mt-12">
          {!isLoading && totalPages > 10 && (
            <Pagination
              page={page}
              startPage={startPage}
              setEndPage={setEndPage}
              endPage={endPage}
              setStartPage={setStartPage}
              setPage={setPage}
              totalPages={totalPages}
            />
          )}
        </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default SupplierPendingRequestBuyer;
