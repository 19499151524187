import { useKeycloak } from "@react-keycloak/web";
import {
  buyerDataById,
  funderDataById,
  getAllBuyers,
  getAllBuyersAssociatedWithSupplier,
  getAllSuppliersAssociatedWithBuyer,
  getSupplierId,
  logOut,
  supplierDataById,
} from "appstate/auth/authSlice";
import {
  getAllPlayerInvoices,
  invoiceDashboardData,
} from "appstate/invoice/invoiceSlice";
import {
  getAllSupplierTransactions,
  getSupplierDashboard,
} from "appstate/supplier/supplierSlice";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import TwoFactorModal from "./modal";
import {
  allExternalAccounts,
  allTransactions,
} from "appstate/wallet/walletSlice";
import SupplierDashboard from "./supplierDashboard";
import FunderDashboard from "./funder";
import BuyerDashboard from "./buyerdashboard";
import {
  allBuyerAssociatedSuppliers,
  getBuyerPendingAssociateRequest,
  getBuyersDashboard,
} from "appstate/buyer/buyerSlice";
import {
  funderDashboardStats,
  getFunderTransactions,
} from "appstate/funder/funderSlice";
import {
  toolsAndAgreementOverview,
  toolsAndAgreementStatusOverview,
} from "appstate/toolsAndAgreement/toolsAndAgreementSlice";
import { allRules } from "appstate/rulesandconfig/rulesandconfigSlice";
import { getNotificationSetting } from "appstate/settings/settingsSlice";
import {
  allMembers,
  allRoles,
  allSubPermissions,
  getStaffDashboard,
  staffProfileById,
} from "appstate/roleManager/roleManagerSlice";
import { allTiers } from "appstate/tier/tierSlice";
import { getFirstTwoWords } from "utilities/helper/firstTwoSnakeCase";
// import { getAllWorkflow } from "appstate/workflow/workflowSlice";
// import { allOpenMarketInvoices } from "appstate/openMarket/openMarketSlice";

const DashboardContents = () => {
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);

  const { currentUser, userId, hasCompleted2FA, userDetails } = useSelector(
    (state) => state.auth
  );

  const { staffProfileByIdData } = useSelector((state) => state.roleManager);

  const constants = [
    "default-roles-payassyst",
    "offline_access",
    "uma_authorization",
  ];
  const roleIndex = userId?.realm_access?.roles.findIndex(
    (role) => !constants.includes(role)
  );

  const userRole = userId?.realm_access?.roles[roleIndex]?.toLowerCase();
  const staffRole = getFirstTwoWords(userRole);

  const player = userRole?.includes("buyer")
    ? "buyer"
    : userRole?.includes("funder")
    ? "funder"
    : "supplier";

  useEffect(() => {
    if (staffRole?.includes("staff")) {
      dispatch(
        staffProfileById({
          player,
          staffId: keycloak?.tokenParsed?.sub,
        })
      );
    }

    if (
      staffRole === "staff_supplier" &&
      staffProfileByIdData?.data?.profilingCompleted
    ) {
      dispatch(
        staffProfileById({
          player,
          staffId: keycloak?.tokenParsed?.sub,
        })
      );
      dispatch(getSupplierId(keycloak?.tokenParsed?.sub));
      dispatch(supplierDataById({ supplierId: keycloak?.tokenParsed?.sub }));
      dispatch(getSupplierDashboard());
    }

    if (
      staffRole === "staff_buyer" &&
      staffProfileByIdData?.data?.profilingCompleted
    ) {
      dispatch(
        staffProfileById({
          player,
          staffId: keycloak?.tokenParsed?.sub,
        })
      );
      dispatch(getStaffDashboard(player));
    }

    if (
      staffRole === "staff_funder" &&
      staffProfileByIdData?.data?.profilingCompleted
    ) {
      dispatch(
        staffProfileById({
          player,
          staffId: keycloak?.tokenParsed?.sub,
        })
      );
      dispatch(getStaffDashboard(player));
    }

    if (userRole === "supplier") {
      dispatch(getSupplierId(keycloak?.tokenParsed?.sub));
      dispatch(
        getAllBuyersAssociatedWithSupplier({ playerId: currentUser?.id })
      );
      dispatch(getAllPlayerInvoices({ playerType: userRole }));
      dispatch(supplierDataById({ supplierId: keycloak?.tokenParsed?.sub }));
      dispatch(getSupplierDashboard());
      dispatch(getAllSupplierTransactions());
      dispatch(invoiceDashboardData());
      dispatch(allTiers({ page: 0, size: 10, searchTerm: "" }));
    }

    if (userRole === "buyer") {
      dispatch(
        getAllSuppliersAssociatedWithBuyer({ playerId: currentUser?.id })
      );
      dispatch(buyerDataById({ buyerId: keycloak?.tokenParsed?.sub }));
      dispatch(getBuyersDashboard());
      dispatch(getBuyerPendingAssociateRequest());
      dispatch(allBuyerAssociatedSuppliers());
      dispatch(allTiers({ page: 0, size: 10, searchTerm: "" }));
    }

    if (userRole === "funder") {
      dispatch(funderDataById({ funderId: keycloak?.tokenParsed?.sub }));
      dispatch(funderDashboardStats());
      dispatch(getFunderTransactions());
      // dispatch(getFunderOpenMarket());
    }

    if (!userRole?.includes("staff")) {
      dispatch(getAllBuyers());
      dispatch(allTransactions());
      dispatch(toolsAndAgreementOverview());
      dispatch(toolsAndAgreementStatusOverview());
      dispatch(allExternalAccounts());
      dispatch(getNotificationSetting());
      dispatch(allRules({ page: 0, pageSize: 10, ruleType: "EARLY_PAYMENT" }));
      dispatch(allRules({ page: 0, pageSize: 10, ruleType: "ASSOCIATION" }));
      // dispatch(allOpenMarketInvoices({ page: 0, pageSize: 10 }));
      // dispatch(getAllWorkflow());
      dispatch(allSubPermissions());
      dispatch(allRoles());
      dispatch(allMembers(player));
    }
  }, [
    dispatch,
    userId?.realm_access?.roles,
    userRole,
    player,
    userId?.sub,
    currentUser?.role,
    currentUser?.id,
    currentUser?.enabled2FA,
    hasCompleted2FA,
    keycloak,
    staffRole,
    staffProfileByIdData?.data?.agreement,
    staffProfileByIdData?.data?.profilingCompleted,
  ]);

  const dashboard =
    userRole === "supplier" || staffRole === "staff_supplier" ? (
      <SupplierDashboard />
    ) : userRole === "funder" || staffRole === "staff_funder" ? (
      <FunderDashboard />
    ) : userRole === "buyer" || staffRole === "staff_buyer" ? (
      <BuyerDashboard />
    ) : (
      <Navigate to="/role_manager" replace />
    );

  const handleCloseModal = () => {
    keycloak.logout().then(() => {
      dispatch(logOut());
      setOpenModal(false);
    });
  };

  const shouldDisplayDashboard =
    (["staff_buyer", "staff_supplier", "staff_funder"]?.includes(staffRole) &&
      staffProfileByIdData?.data?.profilingCompleted) ||
    (["buyer", "supplier", "funder"]?.includes(userRole) &&
      userDetails?.data?.companyDetailsCompleted);

  return (
    <>
      <TwoFactorModal
        isShown={openModal}
        onClose={handleCloseModal}
        setOpenModal={setOpenModal}
        enrolled={currentUser?.enrolled2FA}
      />
      {shouldDisplayDashboard && dashboard}
    </>
  );
};
export default DashboardContents;
