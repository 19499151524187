import React from 'react';
import Danger from "../../../../../../assets/images/svgs/danger.svg";

const DiscountRateModal = ({setApplied, setShowDiscountRateModal}) => {
    const handleClick = () => {
        setApplied(true);
        setShowDiscountRateModal(false);
    }
    return (
        <div className="w-[900px] fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50">
            <div className="relative w-full max-w-lg p-6 bg-white rounded-lg shadow-lg">
                <div className='flex justify-center'>
                    <img src={Danger} alt='' />
                </div>
                <br />
                <hr className="my-4 border-t border-[#F3F2F2]" />
                <div className='text-center font-bold text-lg pt-5'>
                    <span style={{ color: "#F4A040" }}>The discount rate of 7.6% </span>
                    <span> is the benchmark set by </span>
                    <span className="block">the buyer, with the option to approve requests at </span>
                    <span className="block">this rate or lower </span>
                </div>
                <div className='flex justify-center pt-5'>
                    <button onClick={handleClick} className='px-8 py-3' style={{ backgroundColor: "#07593D", color: "white", borderRadius: "8px" }}>Confirm</button>
                </div>
            </div>
        </div>
    );
};

export default DiscountRateModal;
