import request from "apiInstance";
import toast from "react-hot-toast";

export const getSupplierOverview = async () => {
  try {
    const response = await request({
      method: "get",
      url: "/collections/supplier/overview",
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getSupplierReceivableSummaryOverview = async ({ filter }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/collections/get-receivable-summary-for-supplier?filter=${filter}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getSupplierFundSummaryOverview = async ({ period }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/collections/get-funds-for-supplier?period=${period}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getSupplierOpenMarketPendingPayment = async ({
  pageSize,
  page,
  search,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `api/v1/collections/get-open-market-invoices-pending-payment-for-supplier?page=${page}&size=${pageSize}&searchKey=${
        search || ""
      }`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getSupplierOpenMarketPendingReimbursement = async ({
  pageSize,
  page,
  search,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `api/v1/collections/open-market-pending-reimbursement-with-recourse?page=${page}&size=${pageSize}&searchKey=${
        search || ""
      }`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getSupplierOpenMarketInvoice = async ({
  pageSize,
  page,
  search,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `api/v1/collections/open-market-pending-reimbursement-with-recourse?page=${page}&size=${pageSize}&searchKey=${
        search || ""
      }`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getSupplierEarlyPaymentOffer = async ({
  pageSize,
  page,
  search,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `api/v1/collections/supplier-pfp-collection?page=${page}&size=${pageSize}&searchKey=${
        search || ""
      }`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getSupplierSingleEarlyPaymentRequest = async ({
  pageSize,
  page,
  search,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `api/v1/collections/get-supplier-single-early-payment-request?page=${page}&size=${pageSize}&searchKey=${
        search || ""
      }`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getSupplierSingleEPRDetails = async ({ id }) => {
  try {
    const response = await request({
      method: "get",
      url: `api/v1/fund-request/early-payment-request/single-details/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getSupplierBulkEPRDetails = async ({ id }) => {
  try {
    const response = await request({
      method: "get",
      url: `api/v1/fund-request/early-payment-request/supplier-view-bulk/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getSupplierBulkEarlyPaymentRequest = async ({
  pageSize,
  page,
  search,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `api/v1/collections/get-supplier-bulk-early-payment-request?page=${page}&size=${pageSize}&searchKey=${
        search || ""
      }`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getSupplierDirectFundingSingleFundedInvoice = async ({
  pageSize,
  page,
  search,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `api/v1/collections/get-funded-single-invoices-for-direct-funding?page=${page}&size=${pageSize}&searchKey=${
        search || ""
      }`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getSupplierDirectFundingBulkFundedInvoice = async ({
  pageSize,
  page,
  search,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `api/v1/collections/get-funded-bulk-invoices-for-direct-funding?page=${page}&size=${pageSize}&searchKey=${
        search || ""
      }`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getSupplierDirectFundingSingle = async ({
  pageSize,
  page,
  search,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `api/v1/collections/direct-funding-single?page=${page}&size=${pageSize}&searchKey=${
        search || ""
      }`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getSupplierDirectFundingBulk = async ({
  pageSize,
  page,
  search,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `api/v1/collections/direct-funding-bulk?page=${page}&size=${pageSize}&searchKey=${
        search || ""
      }`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getRecievablesUpcomingFull = async ({
  pageSize,
  page,
  search,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `api/v1/collections/upcoming-invoices-for-supplier?size=${pageSize}&page=${page}&searchKey=${
        search || ""
      }`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getRecievablesUpcomingPartial = async ({
  pageSize,
  page,
  search,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `api/v1/collections/get-all-incoming-invoices-in-program?size=${pageSize}&page=${page}&searchKey=${
        search || ""
      }`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getRecievablesOverdueInvoicesFull = async ({
  pageSize,
  page,
  search,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `api/v1/collections/overdue-invoices-for-supplier?size=${pageSize}&page=${page}&searchKey=${
        search || ""
      }`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getRecievablesOverdueInvoicesPartial = async ({
  pageSize,
  page,
  search,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `api/v1/collections/get-all-overdue-invoices-in-program?size=${pageSize}&page=${page}&searchKey=${
        search || ""
      }`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getSupplierRecievablesInvoice = async (id) => {
  try {
    const response = await request({
      method: "get",
      url: `api/v1/invoice/${id}
      }`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getSupplierPartialRecievablesInvoice = async ({ id }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/collections/get-collections-details/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getTransactionsInflow = async ({
  pageSize,
  page,
  search,
  collectionType,
  fundingChannel,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `api/v1/collections/get-transaction-inflow-for-supplier?size=${pageSize}&page=${page}&fundingChannel=${
        fundingChannel || ""
      }&collectionType=${collectionType || ""}&searchKey=${search || ""}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getTransactionsReimbursement = async ({
  pageSize,
  page,
  search,
  fundingChannel,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `api/v1/collections/get-transaction-reimbursement-for-supplier?size=${pageSize}&page=${page}&fundingChannel=${
        fundingChannel || ""
      }&searchKey=${search || ""}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const sendRemainder = async ({ type, body }) => {
  try {
    const response = await request({
      method: "post",
      url: `/collections/send-reminder?type=${type}`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getSupplierTransactionDetails = async ({ transactionId }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/collections/get-transaction-details/${transactionId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

// // // // // // // // // // // // // /// // // // FUNDER COLLECTION // // // // // // // // // // // // // // // // //
export const getFunderOpenMarket = async ({
  pageSize,
  page,
  search,
  discountType,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `api/v1/collections/get-funder-open-market-collections?discountType=${discountType}&size=${pageSize}&page=${page}&searchKey=${
        search || ""
      }`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

// Funder collection early payment offer
export const getFunderPFP = async ({ pageSize, page, search }) => {
  try {
    const response = await request({
      method: "get",
      url: `api/v1/collections/funder-early-payment-offers?size=${pageSize}&page=${page}&searchKey=${
        search || ""
      }`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

// Funder collection closed market
export const getFunderClosedMarket = async ({
  pageSize,
  page,
  search,
  bulk,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `api/v1/collections/funder-closed-market?bulk=${bulk}&size=${pageSize}&page=${page}&searchKey=${
        search || ""
      }`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

// Funder collection epr direct funding
export const getFunderEPRDirectFuding = async ({
  pageSize,
  page,
  search,
  bulk,
  startDate,
  endDate,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `api/v1/collections/funder-early-payment-direct-funding?bulk=${bulk}&startDate=${
        startDate || ""
      }&endDate=${endDate || ""}&size=${pageSize}&page=${page}&searchKey=${
        search || ""
      }`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

// Funder collection epr direct funding
export const getFunderDirectFuding = async ({
  pageSize,
  page,
  search,
  bulk,
  startDate,
  endDate,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `api/v1/collections/funder-direct-funding?bulk=${bulk}&startDate=${
        startDate || ""
      }&endDate=${endDate || ""}&size=${pageSize}&page=${page}&searchKey=${
        search || ""
      }`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

// Funder collection overview
export const getFunderOverviewAwaitingSummary = async () => {
  try {
    const response = await request({
      method: "get",
      url: "/api/v1/collections/funder-awaiting-summary",
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getFunderOverview = async () => {
  try {
    const response = await request({
      method: "get",
      url: "/api/v1/collections/get-funder-collections-overview",
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllTransactions = async ({
  page,
  pageSize,
  fundingChannel,
  search,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/collections/funder-transaction-history?fundingChannel=${
        fundingChannel || ""
      }&page=${page}&size=${pageSize}&searchKey=${search || ""}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllSingleLoanDefaults = async ({ page, pageSize, search }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/collections/loan-defaults-single?page=${page}&size=${pageSize}&searchKey=${
        search || ""
      }`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllBulkLoanDefaults = async ({ page, pageSize, search }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/collections/loan-defaults-bulk?page=${page}&size=${pageSize}&searchKey=${
        search || ""
      }`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllSingleOverdueInvoices = async ({
  page,
  pageSize,
  search,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/collections/overdue-invoices-single?page=${page}&size=${pageSize}&searchKey=${
        search || ""
      }`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllBulkOverdueInvoices = async ({ page, pageSize, search }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/collections/overdue-invoices-bulk?page=${page}&size=${pageSize}&searchKey=${
        search || ""
      }`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
