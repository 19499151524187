import React, { useState } from "react";
import DashboardLayout from "layout/dashboardlayout";
import { PAIconArrowLeftGreen } from "assets/images/svgs";
import { useNavigate } from "react-router-dom";

import TransitionScale from "shared/animation/TransitionScale";
import PendingApprovalSucessModal from "../components/modal/PendingApprovalSucessModals";
import PendingApprovalProceedModal from "../components/modal/PendingApprovalProceedModal";
import PendingApprovalDeclineSucessModal from "../components/modal/PendingApprovalDeclineSuccess";
import PendingApprovalDeclineModal from "../components/modal/PendingApprovalDeclineModal";
import ReasonForDeclineModal from "../components/modal/ReasonForDeclineModal";
import { AnimatePresence } from "framer-motion";

// import { useNavigate } from "react-router-dom";

const InvoiceApprovalScreen = () => {
  const [ongoingCancelBidModal, setOngoingCancelBidModal] = useState(false);
  const [ongoingBidCancelledSuccessModal, setOngoingBidCancelledSuccessModal] =
    useState(false);

  const [ongoingCancelBidModal2, setOngoingCancelBidModal2] = useState(false);
  const [
    ongoingBidCancelledSuccessModal2,
    setOngoingBidCancelledSuccessModal2,
  ] = useState(false);
  const [reasonforDecline, setReasonForDecline] = useState(false);

  const handleOnCancelSuccessClose = () => {
    setOngoingBidCancelledSuccessModal(false);
    navigate(-1);
  };
  const handleOnCancelBidTrue = () => {
    setOngoingCancelBidModal(false);
    setOngoingBidCancelledSuccessModal(true);
  };
  const handleOnCancelSuccessClose2 = () => {
    setOngoingBidCancelledSuccessModal2(false);
    navigate(-1);
  };
  const handleOnCancelBidTrue2 = () => {
    setOngoingCancelBidModal2(false);
    // setOngoingBidCancelledSuccessModal2(true);
    setReasonForDecline(true);
  };

  const handleDeclineContinue = () => {
    setReasonForDecline(false);
    setOngoingBidCancelledSuccessModal2(true);
  };

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  const dummyData = [
    {
      bidAmount: 3,
      item: "Solar Panel ",
      description: "Dangote 12 x 4 ultra high density so..",
      unitPrice: "340,000",
      Quantity: "3",
      amount: "1,020,000",
      taxRate: "1.2%",
      taxAmount: "12,240",
      totalPrice: "1,032,240",
    },

    {
      bidAmount: 4,
      item: "Solar Panel ",
      description: "Dangote 12 x 4 ultra high density so..",
      unitPrice: "340,000",
      Quantity: "3",
      amount: "1,020,000",
      taxRate: "1.2%",
      taxAmount: "12,240",
      totalPrice: "1,032,240",
    },

    {
      bidAmount: 5,
      item: "Solar Panel ",
      description: "Dangote 12 x 4 ultra high density so..",
      unitPrice: "340,000",
      Quantity: "3",
      amount: "1,020,000",
      taxRate: "1.2%",
      taxAmount: "12,240",
      totalPrice: "1,032,240",
    },
    {
      bidAmount: 6,
      item: "Solar Panel ",
      description: "Dangote 12 x 4 ultra high density so..",
      unitPrice: "340,000",
      Quantity: "3",
      amount: "1,020,000",
      taxRate: "1.2%",
      taxAmount: "12,240",
      totalPrice: "1,032,240",
    },
    {
      bidAmount: 7,
      item: "Solar Panel ",
      description: "Dangote 12 x 4 ultra high density so..",
      unitPrice: "340,000",
      Quantity: "3",
      amount: "1,020,000",
      taxRate: "1.2%",
      taxAmount: "12,240",
      totalPrice: "1,032,240",
    },
  ];

  return (
    <>
      <TransitionScale>
        <PendingApprovalProceedModal
          isOpen={ongoingCancelBidModal}
          onClose={() => setOngoingCancelBidModal(false)}
          handleYes={handleOnCancelBidTrue}
        />
      </TransitionScale>
      <TransitionScale>
        <PendingApprovalSucessModal
          isOpen={ongoingBidCancelledSuccessModal}
          onClose={handleOnCancelSuccessClose}
          message={"Request Approved"}
        />
      </TransitionScale>
      <TransitionScale>
        <PendingApprovalProceedModal
          isOpen={ongoingCancelBidModal}
          onClose={() => setOngoingCancelBidModal(false)}
          handleYes={handleOnCancelBidTrue}
        />
      </TransitionScale>

      <AnimatePresence>
        <TransitionScale>
          <PendingApprovalDeclineSucessModal
            isOpen={ongoingBidCancelledSuccessModal2}
            onClose={handleOnCancelSuccessClose2}
            message={"Request Declined"}
          />
        </TransitionScale>
      </AnimatePresence>
      <AnimatePresence>
        <TransitionScale>
          <PendingApprovalDeclineModal
            isOpen={ongoingCancelBidModal2}
            onClose={() => setOngoingCancelBidModal2(false)}
            handleYes={handleOnCancelBidTrue2}
            message={" Are you sure you would like to decline this"}
            midMessage={"workflow?"}
          />
        </TransitionScale>
      </AnimatePresence>
      <AnimatePresence>
        <TransitionScale>
          {reasonforDecline && (
            <ReasonForDeclineModal
              onClose={() => setReasonForDecline(false)}
              onContinue={handleDeclineContinue}
            />
          )}
        </TransitionScale>
      </AnimatePresence>

      <DashboardLayout
        section="Workflow "
        subHeader="Manage all your workflow approvals"
      >
        <div className=" lg:min-h-screen relative">
          {" "}
          <>
            {/* First Phase */}

            {/* <div class="fixed top-0 left-0 h-screen w-1/4 bg-gray-300 p-4"></div> */}
            <div className=" sticky top-0">
              <header className=" text-primaryColor ">
                <span
                  onClick={() => handleBack()}
                  className="flex cursor-pointer  w-fit font-light items-center text-sm gap-2"
                >
                  <PAIconArrowLeftGreen />
                  Go Back
                </span>
              </header>
              <TransitionScale className="px-6 pt-6 pb-8 bg-white  rounded-[10px] mt-5  z-0">
                <div className="lg:flex md:flex justify-between  w-full sm:block  ">
                  <div>
                    {" "}
                    <div class="text-[#11192a] text-xs font-bold ">
                      Approval Stage
                    </div>
                  </div>

                  {/* <div>
                {" "}
                <div class="h-[30px] px-8 py-2 bg-[#de1515]/30 rounded-[5px] justify-center items-center gap-2.5 inline-flex">
                  <div class="text-center text-[#de1515] text-xs font-semibold">
                    Declined
                  </div>
                </div>
              </div> */}

                  <div className=" flex gap-3">
                    {/* <button
                  className={` w-24   py-2 px-3  text-[#222222] justify-center  flex items-center bg-[#D6D6D6] rounded-[5px]  text-sm ${"cursor-pointer"}`}
                  // onClick={() => setOngoingCancelBidModal(true)}
                  //   onClick={() => setOngoingCancelBidModal2(true)}
                  // disabled={termsIsVisible ? false : true}
                >
                  Confirm
                </button> */}
                    <button
                      className={` w-24   py-2 px-3  text-[#de1515]  border border-[#de1515] justify-center  flex items-center bg-white rounded-[5px]  text-sm ${"cursor-pointer"}`}
                      //   class=" px-6 py-2 bg-white rounded-[5px] border border-[#de1515] justify-center items-center gap-2.5 inline-flex"
                      onClick={() => setOngoingCancelBidModal2(true)}
                    >
                      <div>Decline</div>
                    </button>
                    <button
                      className={`w-24  px-3 text-white justify-center  flex items-center bg-[#07593d] rounded-[5px] py-2 text-sm ${"cursor-pointer"}`}
                      //   onClick={() =>
                      //     navigate("/invoice/invoice/fundingSection/invoiceupload")
                      //   }
                      onClick={() => setOngoingCancelBidModal(true)}
                    >
                      Approve
                    </button>
                  </div>
                </div>

                <div className="lg:flex md:flex justify-between  w-full sm:block  mt-3 ">
                  {/* underlined div */}
                  <div className="">
                    <div>
                      <span className="text-[#7a7a7a] text-xs font-bold ">
                        User
                      </span>
                      <span className="text-[#7a7a7a] text-xs font-normal ">
                        {" "}
                        to approve
                      </span>
                    </div>
                    <div className="mb-[15px] mt-[10px] flex justify-center items-center gap-x-[15px] ">
                      <div>
                        <div className="w-[100px] h-1   bg-[#F08000]  rounded-[100px] lg:w-[150px]  xs:w-[70px]"></div>
                      </div>
                      <div>
                        <div className="w-[100px] h-1    bg-[#F08000]/30  rounded-[100px] lg:w-[150px]  xs:w-[70px]"></div>
                      </div>
                      <div>
                        <div className="w-[100px] h-1  bg-[#F08000]/30  rounded-[100px] lg:w-[150px] xs:w-[70px] "></div>
                      </div>
                    </div>
                  </div>

                  {/* Button division */}

                  <div>
                    {" "}
                    {/* <div class="h-[30px] px-8 py-2 bg-[#07593d]/30 rounded-[5px] justify-center items-center gap-2.5 inline-flex">
                  <div class="text-center text-[#07593d] text-xs font-semibold">
                    Completed
                  </div>
                </div> */}
                  </div>
                </div>

                {/* Second Box phase and pages */}
                <div className="lg:flex md:flex justify-between  w-full sm:block  mt-6 ">
                  <div>
                    <div class=" flex-col justify-center items-start gap-2 inline-flex">
                      <div class="self-stretch justify-between items-center inline-flex gap-28">
                        <div class="text-[#222222] text-[14px] font-semibold">
                          Date Sent:
                        </div>
                        <div class="text-[#7a7a7a] text-[14px] font-medium ">
                          2023-02-19
                        </div>
                      </div>
                      <div class="self-stretch justify-between items-center inline-flex gap-28">
                        <div class="text-[#222222] text-[14px] font-semibold">
                          Transaction Type:
                        </div>
                        <div class="text-[#7a7a7a] text-[14px] font-medium ">
                          Invoicing
                        </div>
                      </div>
                      <div class="self-stretch justify-between items-center inline-flex  gap-28">
                        <div class="text-[#222222] text-[14px] font-semibold">
                          Action:
                        </div>
                        <div class="text-[#7a7a7a] text-[14px] font-medium ">
                          Invoice Approval
                        </div>
                      </div>
                      <div class="self-stretch justify-between items-center inline-flex">
                        <div class="text-[#222222] text-[14px] font-semibold">
                          Approval Stage:
                        </div>
                        <div class="text-[#7a7a7a] text-[14px] font-medium ">
                          1 of 3
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Second side */}

                  <div>
                    <div class=" flex-col justify-center items-start gap-2 inline-flex">
                      <div class="self-stretch justify-between items-center inline-flex gap-28">
                        <div class="text-[#222222] text-[14px] font-semibold">
                          Sender:
                        </div>
                        <div class="text-[#7a7a7a] text-[14px] font-medium ">
                          First User
                        </div>
                      </div>
                      <div class="self-stretch justify-between items-center inline-flex gap-28">
                        <div class="text-[#222222] text-[14px] font-semibold">
                          Previous Approver:
                        </div>
                        <div class="text-[#7a7a7a] text-[14px] font-medium ">
                          Admin
                        </div>
                      </div>
                      <div class="self-stretch justify-between items-center inline-flex  gap-28">
                        <div class="text-[#222222] text-[14px] font-semibold">
                          Next Approver:
                        </div>
                        <div class="text-[#7a7a7a] text-[14px] font-medium ">
                          Sam Johnson
                        </div>
                      </div>
                    </div>
                    {/* Button */}
                  </div>
                </div>
              </TransitionScale>
            </div>

            <div className=" sticky top-0">
              <TransitionScale className=" pb-5 bg-white  rounded-[3px] mt-5 pt-2 z-1000">
                {/* Header with Left Padding */}

                {/* Other sections */}

                <section className="  mt-3">
                  <div class="text-[#222222] text-lg font-semibold mt-4 px-12">
                    Invoice | INV-2002
                  </div>

                  {/* Down Flex Texts */}
                  <div className=" flex justify-between px-12">
                    {/* first stage */}

                    <div class="lg:w-[229px]  sm:w-full h-[117px] flex-col justify-start items-start gap-3 inline-flex">
                      <div class="self-stretch justify-between items-center inline-flex mt-2">
                        <div class="text-[#222222] text-base font-semibold  ">
                          Reference No:{" "}
                        </div>
                        <div class="text-[#555555] text-sm font-semibold ">
                          0983018374BA
                        </div>
                      </div>
                      <div class="self-stretch  flex-col justify-center items-start gap-1 flex mt-2">
                        <div class="self-stretch justify-between items-center inline-flex">
                          <div class="text-[#222222] text-xs font-semibold ">
                            Invoice Number
                          </div>
                          <div class="text-[#7a7a7a] text-xs font-semibold ">
                            INV 2002
                          </div>
                        </div>
                        <div class="self-stretch justify-between items-center inline-flex ">
                          <div class="text-[#222222] text-xs font-semibold ">
                            Created Date:{" "}
                          </div>
                          <div class="text-[#7a7a7a] text-xs font-semibold ">
                            23-Mar -2023
                          </div>
                        </div>
                        <div class="self-stretch justify-between items-center inline-flex">
                          <div class="text-[#222222] text-xs font-semibold ">
                            Submitted Date:{" "}
                          </div>
                          <div class="text-[#7a7a7a] text-xs font-semibold ">
                            23-Mar -2023
                          </div>
                        </div>
                        <div class="self-stretch justify-between items-center inline-flex">
                          <div class="text-[#222222] text-xs font-semibold ">
                            Approved Date:{" "}
                          </div>
                          <div class="text-[#7a7a7a] text-xs font-semibold ">
                            23-April -2023
                          </div>
                        </div>
                        <div class="self-stretch justify-between items-center inline-flex">
                          <div class="text-[#222222] text-xs font-semibold ">
                            Due Date:{" "}
                          </div>
                          <div class="text-[#7a7a7a] text-xs font-semibold ">
                            30-May-2023
                          </div>
                        </div>
                        <div class="self-stretch justify-between items-center inline-flex">
                          <div class="text-[#222222] text-xs font-semibold ">
                            Issuance Date:{" "}
                          </div>
                          <div class="text-[#7a7a7a] text-xs font-semibold ">
                            30-May-2023
                          </div>
                        </div>
                      </div>

                      {/* stop */}
                    </div>
                    {/* second stage */}
                    <div className="flex justify-between items-center mt-10 ">
                      {" "}
                      <div class="h-[37px] p-2.5 rounded-[5px] border border-[#f08000]/30 justify-center items-center gap-2.5 inline-flex">
                        <div class="text-[#f08000] text-sm font-medium ">
                          Pending Your Approval
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* stops here */}
                  <div className="  px-12 mt-24">
                    <header className="border-b-[5px] border-b-[#F6F5FA] mt-3  flex justify-between py-1">
                      {/* <h1 className="text-[18px] font-semibold">
                  Supplier/buyer details
                </h1> */}
                      <div>
                        <div class="text-[#222222] text-lg font-semibold w-2 ">
                          Nestle PLC
                        </div>
                        <div class="text-[#7a7a7a] text-xs font-light  ">
                          Buyer details
                        </div>
                      </div>

                      <div class="h-[41px] flex-col justify-start items-start  inline-flex px-12">
                        <div class="text-[#222222] text-base font-semibold ">
                          60 Days
                        </div>
                        <div class="w-[100px] text-[#7a7a7a] text-xs font-light  mt-1">
                          Payment
                        </div>
                      </div>

                      <div class="h-[43px] flex-col justify-start items-end  inline-flex">
                        <div class="text-[#222222] text-lg font-semibold ">
                          NGN
                        </div>
                        <div class="text-[#7a7a7a] text-xs font-semibold mt-1">
                          Currency
                        </div>
                      </div>
                    </header>

                    <header className="border-b-[5px] border-b-[#F6F5FA] mt-6  flex justify-between py-1">
                      {/* <h1 className="text-[18px] font-semibold">
                  Supplier/buyer details
                </h1> */}
                      <div class="text-[#11192a] text-sm font-semibold ">
                        Line Items
                      </div>
                      <div class="text-[#11192a] text-lg font-light  mr-6">
                        5 Total Items{" "}
                      </div>
                    </header>

                    <div className=" w-full overflow-x-auto mt-3 pb-4">
                      <table className=" w-full overflow-x-auto">
                        <thead className="bg-subtleGray w-full p-6   ">
                          <tr className=" bg-unusualGray  px-6 w-full ">
                            {/* <td className="text-[#11192A] p-2.5 text-xs font-bold">
                          Item Number
                        </td> */}
                            <td class="text-[#11192a] text-xs font-semibold  p-4  ">
                              Item{" "}
                            </td>
                            <td class="text-[#11192a] text-xs font-semibold  p-4">
                              Description{" "}
                            </td>
                            <td class="text-[#11192a] text-xs font-semibold  p-4">
                              Unit Price
                            </td>
                            <td class="text-[#11192a] text-xs font-semibold  p-4">
                              Quantity
                            </td>
                            <td class="text-[#11192a] text-xs font-semibold  p-4">
                              Amount
                              <div> (before tax)</div>
                            </td>
                            <td class="text-[#11192a] text-xs font-semibold  p-4">
                              Tax Rate
                            </td>
                            <td class="text-[#11192a] text-xs font-semibold  p-4">
                              Tax Amount
                            </td>
                            <td class="text-[#11192a] text-xs font-semibold  p-4">
                              Total Price
                            </td>
                          </tr>
                        </thead>

                        <tbody className="  ">
                          {dummyData.map((cell, idx) => {
                            return (
                              <tr key={idx} className=" w-full ">
                                <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 ">
                                  {cell.item}
                                </td>
                                <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                                  {cell.description}
                                </td>
                                <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                                  {cell.unitPrice}
                                </td>
                                <td className="text-[#11192a] text-xs font-semibold mt-2 text-center">
                                  {cell.Quantity}
                                </td>
                                <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                                  {cell.amount}
                                </td>
                                <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                                  {cell.taxRate}
                                </td>
                                <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                                  {cell.taxAmount}
                                </td>
                                <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                                  {cell.totalPrice}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    {/* Calculate cost and price  shaded or gray section */}
                  </div>

                  <div className=" px-12   py-5 bg-neutral-50 flex-col justify-center items-start  inline-flex bg-[#fafafa] w-full border-t-[0.2px]  border-t-[#b1a6b2]">
                    <div className="lg:flex md:flex justify-between  w-full sm:block ">
                      {/* First left side */}
                      <div>
                        <section>
                          {" "}
                          <div class="text-[#222222] text-lg  font-medium border-b-[0.2px]  border-[#b1a6b2]">
                            Receiving Funding Account Information
                          </div>
                          {/* <div class="text-[#222222] text-lg font-semibold ">
                          Receiving Funding Account Information
                        </div> */}
                          <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                            74*******512
                          </div>
                          <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                            Praise Akinlade
                          </div>
                        </section>
                      </div>

                      {/* second division */}

                      <div>
                        {" "}
                        <div class=" flex-col justify-start items-start inline-flex lg:min-w-[380px]">
                          <section className=" w-full px-6 pb-2 border-b-[1px] border-black">
                            <div className=" flex w-full justify-between">
                              <div class="text-[#222222] text-xs font-semibold ">
                                Subtotal
                              </div>
                              <div class=" text-[#222222] text-xs font-semibold ">
                                10,129,892
                              </div>
                            </div>
                            <div className=" flex w-full justify-between pt-3 pb-1 border-b-[0.2px] border-b-[#b1a6b2]/30">
                              <div class="text-[#222222]  text-xs font-medium flex  space-x-9 ">
                                <span>VAT%</span>
                                <span>5.6%</span>
                              </div>
                              <div class="text-[#222222] text-xs font-medium ">
                                573,960
                              </div>
                            </div>

                            <div className=" flex w-full justify-between pt-3 pb-1 border-b-[0.2px] border-b-[#b1a6b2]/30">
                              <div class="text-[#222222]  text-xs font-medium flex  space-x-9 ">
                                <span>Discount%</span>
                                <span>2.4%</span>
                              </div>
                              <div class="text-[#222222] text-xs font-medium ">
                                237,088
                              </div>
                            </div>
                          </section>
                          <div className=" flex w-full justify-between py-2 px-6 border-b-[1px] border-b-black">
                            <div class="text-[#222222] text-lg font-semibold ">
                              Grand Total
                            </div>
                            <div class="text-[#222222] text-lg font-semibold ">
                              10,473,890
                            </div>
                          </div>
                          {/* <div className=" flex w-full justify-between pt-3 pb-1  border-b-[1px] border-black">
                          <div class="text-[#222222] text-lg  font-medium  ">
                            Grand Total
                          </div>
                          <div class="text-[#222222] text-lg  font-medium ">
                            5,263,200.00
                          </div>
                        </div> */}
                        </div>
                      </div>

                      {/* stops */}
                    </div>
                  </div>
                </section>
              </TransitionScale>
            </div>
          </>
        </div>
      </DashboardLayout>
    </>
  );
};

export default InvoiceApprovalScreen;
