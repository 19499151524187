/* eslint-disable react-hooks/exhaustive-deps */
import {
  addBankDetails,
  addBuyerBankDetails,
  addFunderBankDetails,
  fetchAllBankList,
  fetchBankName,
  verifyAuthBVN,
} from "appstate/auth/authSlice";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import InputField from "shared/InputField";
import Select from "shared/Select";
import Spinner from "shared/Spinner/Spinner";
import { countries } from "utilities/Countries";
import { currencies } from "utilities/Currencies-updated";
import { addBankValidationSchema } from "validation/Addbank/AddbankSchema";
import OnboardingLayout from "../category/components/OnboardingLayout";
import { updateUploadBankDetails } from "./supplierVerifcationSlice/verificationActions";
import {
  getBuyerById,
  getFunderDataById,
  getSupplierById,
} from "appstate/api/auth/onboarding";
import { PAIconArrowLeft } from "assets/images/svgs";
import BvnInputField from "shared/BvnInputField";
import BankDetailsSubmitModal from "../common/modals/BankDetailsSubmitModal";
import SelectBanks from "shared/addBanks/SelectBanks";

const Addbank = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { player } = useParams();

  const { isLoading, userDetails, allBanks, bankName } = useSelector(
    (state) => state.auth
  );
  const [submittedModal, setSubmittedModal] = useState(false);

  const [bankCode, setBankCode] = useState("");

  const [bvnValidated, setBvnValidated] = useState(false);

  const formikRef = useRef(null);

  const FetchAllBanks = () => {
    dispatch(fetchAllBankList());
  };

  useEffect(() => {
    FetchAllBanks();
  }, []);

  const formik = useFormik({
    initialValues: {
      bankName: "",
      countryOfBank: "",
      currency: "",
      accountHolder: `${bankName?.data?.account_name}`,
      accountNumber: "",
      bvn: "",
    },
    validationSchema: addBankValidationSchema,
    validateOnMount: true,
    onSubmit: async (values) => {
      const body = {
        bankName: values.bankName,
        bankCountry: values.countryOfBank,
        currency: values.currency,
        accountHolder: `${
          bankName?.data?.account_name ? bankName?.data?.account_name : ""
        }`,
        accountNumber: values.accountNumber,
        bvn: values.bvn,
      };

      try {
        const response = await (player === "supplier"
          ? dispatch(addBankDetails({ body }))
          : player === "funder"
          ? dispatch(addFunderBankDetails({ body }))
          : dispatch(addBuyerBankDetails({ body })));

        if (response?.payload?.status === 200 && response?.payload?.success) {
          setSubmittedModal(true);

          dispatch(updateUploadBankDetails(true));
          setTimeout(() => {
            navigate(`/onboarding/${player}/get-started`);
          }, 2000);
        } else {
          toast.error("Failed to update bank details");
        }
      } catch (error) {
        toast.error("An error occurred while updating bank details");
      }
    },
  });

  formikRef.current = formik;
  useEffect(() => {
    if (formik.values.accountNumber.length === 10 && bankCode) {
      dispatch(
        fetchBankName({
          account: formik.values.accountNumber,
          bankCode,
        })
      );
    } else {
      // formikRef.current.setFieldValue("accountHolder", "");
    }
  }, [formik.values.accountNumber.length, bankCode]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData =
          player === "supplier"
            ? await getSupplierById({
                userId: userDetails?.data?.userId,
              })
            : player === "funder"
            ? await getFunderDataById({
                funderId: userDetails?.data?.userId,
              })
            : await getBuyerById({
                buyerId: userDetails?.data?.userId,
              });
        if (userData) {
          formikRef.current.setValues((prevValues) => ({
            ...prevValues,
            bankName: userData?.data?.bankName || "",
            countryOfBank: userData?.data?.bankCountry || "",
            currency: userData?.data?.currency || "",
            accountHolder: userData?.data?.accountHolder || "",
            accountNumber: userData?.data?.accountNumber || "",
            bvn: userData?.data?.bvn || "",
          }));
        }
      } catch (error) {
        toast.error(error?.response?.data?.message + "!");
      }
    };

    if (userDetails?.data?.userId) {
      fetchUserData();
    }
  }, [userDetails?.data?.userId]);

  useEffect(() => {
    if (formik.values.bvn.length === 11) {
      dispatch(verifyAuthBVN({ bvn: formik.values.bvn, player })).then(
        (data) => {
          if (data?.payload?.success) {
            setBvnValidated(true);
          } else {
            setBvnValidated(false);
          }
        }
      );
    }
  }, [formik.values.bvn.length]);

  const goback = () => {
    navigate(-1);
  };

  const submitClosed = () => {
    setSubmittedModal(false);
  };

  return (
    <OnboardingLayout>
      <PAIconArrowLeft className="cursor-pointer mx-4  my-4" onClick={goback} />
      <BankDetailsSubmitModal
        isOpen={submittedModal}
        onClose={submitClosed}
        message={"Bank details Submitted "}
        sucess={"successfully"}
      />

      <>
        {(player === "funder" || player === "buyer") && !formik.isValid && (
          <div className="mb-[25px] mt-[20px] flex justify-center items-center gap-x-[20px] lg:gap-x-[50px]">
            <div className="relative bottom-[15px]">
              <div className="w-[100px] h-[2px] bg-[#F08000]  rounded-[100px] md:w-[220px]"></div>
            </div>

            <div className="relative bottom-[15px]">
              <div className="w-[100px] h-[2px] bg-[#F08000] rounded-[100px] md:w-[220px]"></div>
            </div>
            <div className="relative bottom-[28px] md:bottom-[30px]">
              <div class="text-[#222222] text-sm font-medium pb-1">
                Add a bank account
              </div>
              <div className="w-[100px] h-[2px] bg-[#F08000]  rounded-[100px] md:w-[220px]"></div>
            </div>
          </div>
        )}
      </>

      <div className="pt-[100px] grid place-content-center h-[100%] overflow-auto md:pt-0 lg:pt-[150px] xl:pt-0">
        <div class="text-[#2d0432] text-2xl font-bold text-center mb-[50px] ">
          Add a bank account
        </div>

        <form
          onSubmit={formik.handleSubmit}
          className="flex justify-center flex-col"
        >
          <div className="mb-[30px]">
            <Select
              setCode={setBankCode}
              type={"Select Bank"}
              label="Country of bank"
              datas={countries}
              id="countryOfBank"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.countryOfBank}
              className="px-6 border-none focus:border-none"
            />
            {formik.touched.countryOfBank && formik.errors.countryOfBank ? (
              <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.countryOfBank}
              </p>
            ) : null}
          </div>

          <div className="mb-[30px]">
            <SelectBanks
              label="Bank name"
              type={"Enter Bank"}
              datas={allBanks?.data}
              id="bankName"
              value={formik.values.bankName}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              setCode={setBankCode}
              isLoading={isLoading}
            />
            {formik.touched.bankName && formik.errors.bankName ? (
              <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.bankName}
              </p>
            ) : null}
          </div>

          <div className="mb-[30px]">
            <Select
              setCode={setBankCode}
              label="Currency"
              type={"Select Currency"}
              datas={currencies}
              id="currency"
              value={formik.values.currency}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            {formik.touched.currency && formik.errors.currency ? (
              <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.currency}
              </p>
            ) : null}
          </div>

          <div className="mb-[30px]">
            <InputField
              className="w-[300px] py-[13px] outline-none md:w-[424px] px-1.5"
              placeholder=""
              label="Account number"
              id="accountNumber"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.accountNumber}
            />
            {formik.touched.accountNumber && formik.errors.accountNumber ? (
              <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.accountNumber}
              </p>
            ) : null}
          </div>

          {/^(?!([0-9])\1{9})\d{10}$/.test(
            formik.values.accountNumber.trim()
          ) &&
            bankName?.data?.account_name && (
              <div className="mb-[30px]">
                <InputField
                  editable={false}
                  className="w-[300px] py-[13px] outline-none md:w-[424px] px-1.5"
                  placeholder="Account Name"
                  label="Account Name"
                  id="accountHolder"
                  value={`${bankName?.data?.account_name}`}
                  disabled={true}
                  maxLength={10}
                />
              </div>
            )}

          <div className="mb-[30px]">
            <BvnInputField
              className="w-[300px] py-[13px] outline-none md:w-[424px] bg-[#f6f6f6] px-1.5"
              placeholder="12345678901"
              label="BVN"
              id="bvn"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.bvn}
              validNin={
                bvnValidated &&
                /^(?!([0-9])\1{10})\d{11}$/.test(formik.values.bvn)
              }
              maxLength={11}
            />
            {formik.touched.bvn && formik.errors.bvn ? (
              <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.bvn}
              </p>
            ) : null}
          </div>

          <button
            type="submit"
            auth
            disabled={
              isLoading ||
              !formik.isValid ||
              bankName?.data?.account_name === undefined
            }
            className={`my-[30px]   md:w-[424px]  rounded-[5px] text-sm font-medium h-[45px] w-full text-[#222222] border-none  ${
              !formik.isValid || bankName?.data?.account_name === undefined
                ? "bg-[#D8D8D8] text-[#7a7a7a] cursor-not-allowed"
                : "  bg-primaryColor text-white cursor-pointer"
            }`}
          >
            {isLoading ? <Spinner /> : "Submit"}
          </button>
        </form>
      </div>
    </OnboardingLayout>
  );
};

export default Addbank;
