import { PAIconInventoryArrowLeft } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { useNavigate } from "react-router-dom";
import Card from "shared/Card";
import { motion } from "framer-motion";
import CocaCola from "../../../assets/images/svgs/cocacola.svg";
import TableIcons from "../../../assets/images/svgs/TableIcons.svg";

const FunderDirectFundingDetailsAcceptedBulkRequest = () => {
   
    const navigate = useNavigate();

    function handleNavigate() {
      let path = -1;
      navigate(path);
    }
  
    return (
      <DashboardLayout
        section="Direct Fund Request"
        subHeader="Manage all your direct fund requests"
      >
        <header>
          <div
            onClick={handleNavigate}
            className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
          >
            <PAIconInventoryArrowLeft />
            <p className="mt-[2px] text-sm font-normal text-lightGray">Go Back</p>
          </div>
        </header>
        <div className="flex justify-between">
          <Card className="w-[60%] p-[24px] rounded-[10px]">
            <div className=" flex  flex-col gap-3 ">
              <main className=" w-full flex justify-between">
              <div className="flex flex-col gap-3 w-1/2">
                <div className="flex">
                  <span>Reference No:</span>
                  <span className="pl-2">|RF9101010BHSHS</span>
                </div>
                <div className="flex flex-col gap-1">
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Request Date:</span>
                    <span className="text-[#979797]  text-xs">23-March-2023</span>
                  </div>
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Total Request Amount:</span>
                    <span className="text-[#979797]  text-xs">
                      2,000,000
                    </span>
                  </div>
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Total Invoice Amount:</span>
                    <span className="text-[#979797]  text-xs">
                    2,000,000
                    </span>
                  </div>
                </div>
              </div>
  
                <div className="flex flex-col">
                  <div className="flex flex-col justify-between">
                    <motion.button
                      whileTap={{ scale: 0.9 }}
                      className="flex bg-primaryColor text-sm justify-center gap-2 text-white rounded-[5px] items-center px-[35px] py-1.5 "
                    >
                      Disburse Now
                    </motion.button>
                  </div>
                  <br />
                  <div className="flex flex-col justify-between">
                    <motion.button
                      whileTap={{ scale: 0.9 }}
                      className="flex bg-[#D9EFD6] text-sm justify-center gap-2 text-[#59BB50] rounded-[5px] items-center px-[35px] py-1.5 "
                    >
                      Approved
                    </motion.button>
                  </div>
                </div>
              </main>
            </div>
            <br />
            <div className="flex justify-between">
              <div className="flex flex-col">
                <span className="font-light  text-xs">Nestle Plc</span>
                <span className="text-[#979797] text-sm">buyer details</span>
              </div>
              <div className="flex flex-col">
                <span className="font-light   text-xs">60 Days</span>
                <span className="text-[#979797] text-sm">Payment</span>
              </div>
              <div className="flex flex-col">
              <span className="text-[#979797] flex justify-end text-xs">NGN</span>
                <span className="font-light text-sm">Currency</span>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <header className="border-b-[5px] border-b-[#F6F5FA] pb-3"></header>
              <div className="flex  justify-between">
                <div className="flex gap-2"></div>
                <div className="flex gap-2">
                  <div className="flex flex-col">
                    <span className="text-[#979797]  text-xs">5 Total Items</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
                <h1 className="text-sm">Line Items</h1>
              </header>
              <div className=" w-full overflow-x-auto">
              <table className=" w-full overflow-x-auto">
                <thead className="bg-subtleGray w-">
                  <tr className=" bg-unusualGray p-2.5 ">
                    <td className="text-[#11192A] p-2.5 text-xs font-bold">
                      <div className="flex">
                        <img src={TableIcons} alt="" />
                       <span>Ref Number</span> 
                      </div>
                    </td>
                    <td className=" p-2.5 text-xs font-bold">Due Date</td>
                    <td className=" p-2.5 text-xs font-bold whitespace-nowrap">Request Amount</td>
                    <td className=" p-2.5 text-xs font-bold whitespace-nowrap">Discount Rate</td>
                    <td className=" p-2.5 text-xs font-bold">Receivable Amount</td>
                  </tr>
                </thead>
              </table>
              </div>
            </div>
          </Card>
          <Card className="w-[38%] p-[24px] rounded-[10px]">
            <div>
              <div className="flex justify-between">
                <span className="font-bold text-[#000000]">
                  Direct Funding Details
                </span>
              </div>
            </div>
            <div>
              <br />
              <div className="flex justify-between">
                <div className="flex">
                  <div>
                    <img src={CocaCola} alt="" />
                  </div>
                  <div style={{ lineHeight: 1 }} className="flex flex-col pl-2">
                    <span>Coca Cola PLC</span>
                    <span className="text-[#959595]">Buyer</span>
                  </div>
                </div>
                <div>
                  <div style={{ lineHeight: 1 }} className="flex flex-col pl-2">
                    <span className="flex justify-end">1.5%</span>
                    <span className="text-[#959595]">Discount rate</span>
                  </div>
                </div>
              </div>
              <br />
              <div className="flex justify-between">
                <div style={{ lineHeight: 1.3 }} className="flex flex-col pl-2">
                  <span>NGN 90,000</span>
                  <span className="text-[#959595]">Discount Amount</span>
                </div>
                <div>
                  <div style={{ lineHeight: 1.3 }} className="flex flex-col pl-2">
                    <span>23-Dec-2024(20 days)</span>
                    <span className="flex justify-end text-[#959595]">
                       Payment Date
                    </span>
                  </div>
                </div>
              </div>
              <br />
              <div className="flex justify-between">
                <div style={{ lineHeight: 1.3 }} className="flex flex-col pl-2">
                  <span>NGN 9,000,000</span>
                  <span className="text-[#959595]">Total Request Amount</span>
                </div>
                <div>
                  <div style={{ lineHeight: 1.3 }} className="flex flex-col pl-2">
                    <span className="flex justify-end">NGN 8,900,000</span>
                    <span className="text-[#959595]">Total Payable Amount</span>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <hr className="text-[#959595]" />
            <br />
  
            <div className="flex flex-col">
              <span className="font-bold text-[#000000]">
                Notes from supplier:
              </span>
              <span className="text-[#959595]">
                Seeking Early payment due to unexpected challenges. Facing
                temporary financial constraints and need a bit more time to ensure
                a smooth transaction. Appreciate your understanding. Thank you.
                Best regards, ABC Limited
              </span>
            </div>
  
            <br />
            <hr className="text-[#959595]" />
            <br />
            <div className="flex flex-col">
              <span className="font-bold text-[#000000]">Funding Option</span>
              <span className="text-[#959595]">
                This invoice would be funded from this virtual account
              </span>
              <span className="text-[#959595]">Benjamin James</span>
              <span className="text-[#959595]">73**********12</span>
              <span className="text-[#959595]">Payedge MFB</span>
            </div>
          </Card>
        </div>
      </DashboardLayout>
    );
};
export default FunderDirectFundingDetailsAcceptedBulkRequest;
