import { getAssociateBuyerId } from 'appstate/auth/authSlice';
import React from 'react';
import { useDispatch } from 'react-redux';

const BuyerIcon = ({ icon, name, setSelect, select, id, setGetId }) => {
  const dispatch = useDispatch()
  const handleChange = (e) => {
    setSelect(e.target.value);
    dispatch(getAssociateBuyerId(id))
    setGetId(id)
  };
  return (
    <label className="flex justify-center flex-col">
      <div className='flex flex-col items-center justify-center'>
        <input
          type="radio"
          name={name}
          value={name || ""}
          onChange={handleChange}
          checked={select === name}
          className="accent-primaryColor relative right-[50px] 2xl:right-[45px]"
        />
        <div className="relative bottom-[15px] flex justify-center items-center w-[80px] h-[80px] py-[22px] px-[18px] border rounded-[100%] border-jaguarBlack bg-white">
          <p className='text-[30px] font-semibold'>
          {icon}
          </p>
        </div>
        <p className="font-semibold text-xs text-center relative bottom-[10px] text-textColor mt-[5px]  md:mb-[45px]">
          {name}
        </p>
      </div>
    </label>
  );
};

export default BuyerIcon;