import request from "apiInstance";
import toast from "react-hot-toast";

export const getAllearlyPayments = async ({ page, pageSize, search }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/buyer/invoices?page=${page}&size=${pageSize}&search=${search}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const allearlyPaymentsRequestStatus = async ({ status }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/fund-request/early-payment-request/views-pending/single?status=${status}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const allearlyPaymentsRequestBulkStatus = async ({ status }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/fund-request/early-payment-request/bulk?status=${status}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getApprovedSingleEarlyPaymentDetails = async (id) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/fund-request/early-payment-request/view-approved-single/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getRejectedSingleEarlyPaymentDetails = async (id) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/fund-request/early-payment-request/view-rejected-single/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getPendingSingleEarlyPaymentDetails = async (id) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/fund-request/early-payment-request/view-single/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getPendingBulkEarlyPaymentDetails = async ({ id, status }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/fund-request/early-payment-request/view-bulk/${id}?status=${status}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getApprovedBulkEarlyPaymentDetails = async ({ id }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/fund-request/early-payment-request/view-approved-bulk/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getRejectedBulkEarlyPaymentDetails = async ({ id }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/fund-request/early-payment-request/view-rejected-bulk/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const approveSingleEarlyPaymentRequestWallet = async (body) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/fund-request/early-payment-request/pay/wallet",
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const approveSingleEarlyPaymentRequestFunder = async (body) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/fund-request/early-payment-request/pay/direct",
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const approveSingleEarlyPaymentRequestClosed = async (body) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/fund-request/closed-market",
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const updateSingleEarlyDiscountRate = async ({ body }) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/fund-request/early-payment-request/preview-adjusted-single-discount",
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getProcessingSingleEarlyPaymentDetails = async (id) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/fund-request/early-payment-request/view-processing-single/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllNewEarlyPaymentRequest = async ({
  page,
  pageSize,
  bulk,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/fund-request/early-payments?page=${page}&size=${pageSize}&bulk=${bulk}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const earlyPaymentApproveOrDecline = async ({ id, comment, status }) => {
  try {
    const response = await request({
      method: "put",
      url: `/fund-request/approve-early-payment/${id}?comment=${comment}&status=${status}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
