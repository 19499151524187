import { PAIconAlert24 } from "assets/images/svgs";
import React from "react";
import Spinner from "shared/Spinner/Spinner";
import Button from "shared/Button";

const ContinueInviteModal = ({ isOpen, onClose, handleYes, isLoading, supplier }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-[1000]">
      <div className="bg-white p-5 rounded-[10px] shadow-md flex flex-col items-center px-10 lg:w-[500px]   py-7">
        <PAIconAlert24 />
        <p className="text-[#979797] text-center  text-base font-normal leading-normal tracking-wide mb-3 my-5">
          Are you sure you would like to
          <span className="text-[#7a7a7a] text-base  font-bold">
            {" "}
            invite
          </span>{" "}
          <br /> <span>{supplier ? "the supplier" : "the funder?"}</span>
        </p>
        <div className="flex justify-center mt-3">
          {/* <button
            className="px-5 py-2 mr-5 text-[#07593D] border border-[#07593D  bg-[#07593d] rounded-[5px] text-white"
            onClick={handleYes}
            disabled={isLoading}
          >
            {isLoading ? <Spinner /> : "Yes"}
          </button> */}
          <Button
            neutral
            // disabled={invite?.length === 0 || isLoading}
            className="px-5 py-2 mr-5 text-[#07593D] border border-[#07593D  bg-[#07593d] rounded-[5px] text-white"
            onClick={handleYes}
            disabled={isLoading}
          >
            {isLoading ? <Spinner /> : "Yes"}
          </Button>
          <button
            className="px-5 py-2 bg-red-500 border border-red text-red rounded-md"
            onClick={onClose}
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContinueInviteModal;
