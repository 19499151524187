export function getDaysLeft(earlyPaymentDate) {
  const today = new Date();
  const targetDate = new Date(earlyPaymentDate);

  // Calculate the difference in milliseconds
  const timeDiff = targetDate - today;

  // Convert milliseconds to days
  return Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
}
