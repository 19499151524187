import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const Graph = ({ totalEarlyPaymentRequests, totalDirectFundingRequests }) => {
  const [chartData, setChartData] = useState({
    series: [0, 0],
    options: {
      chart: {
        width: 250,
        type: "donut",
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                showAlways: true,
                show: true,
                fontWeight: "bold",
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              show: true,
            },
          },
        },
      ],
      legend: {
        show: true,
        position: "bottom",
      },
      colors: ["#FFA500", "#07593D"],
      labels: ["Total Early Payment Requests", "Total Direct Funding Requests"],
    },
  });

  useEffect(() => {
    setChartData((prevData) => ({
      ...prevData,
      series: [totalEarlyPaymentRequests || 0, totalDirectFundingRequests || 0],
    }));
  }, [totalEarlyPaymentRequests, totalDirectFundingRequests]);

  return (
    <div className="mr4">
      <div className="chart-wrap">
        <div id="chart">
          <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            type="donut"
            width={250}
          />
        </div>
      </div>
    </div>
  );
};

export default Graph;
