import {
  PAIconAgreeFolder,
  PAIconAgreeReject,
  PAIconAgreeWarning,
  PAIconArrowLeft,
  PAIconDownloadFunding,
  PAIconSignPen,
} from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import Heading from "../category/components/Heading";
import ViewDocumentModal from "features/RulesConfig/common/components/modals/DeleteRuleModal";
import { useState } from "react";
import AgreementSignedModal from "../common/modals/AgreementSignedModal";
import { useDispatch } from "react-redux";
import { updateAgreementDocumentAccepted } from "./supplierVerifcationSlice/verificationActions";
import AgreementRejectedModal from "../common/modals/AgreementRejectedModal";
import { useRef } from "react";
import html2pdf from "html2pdf.js";
import ProgressModal from "shared/progressModal/ProgressModal";
import { uploadFundingAgreementDecision } from "appstate/onboarding/onboardingSlice";
function FundingAgreement() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [signAgreementModal, setSignAgreementModal] = useState(false);
  const [rejectAgreementModal, setRejectAgreementModal] = useState(false);
  const [rejectedAgreement, setRejectedAgreement] = useState(false);
  const [downloadAgreementClick, setDownloadAgreementClick] = useState(false);
  const [downloadModal, setDownloadModal] = useState(false);
  // On Accept Agreement
  const [agreementAccepted, setAgreementAccepted] = useState(false);

  const handleBack = () => {
    navigate(-1);
  };

  const handleAcceptAgreement = () => {
    setSignAgreementModal(false);
    dispatch(uploadFundingAgreementDecision(true)).then((data) => {
      if (data?.payload?.success) {
        setAgreementAccepted(true);
      }
    });
  };

  const handleCloseAcceptAgreement = () => {
    dispatch(updateAgreementDocumentAccepted(true));
    setAgreementAccepted(false);
    navigate(-1);
  };
  const handleCancelContinue = () => {
    setRejectedAgreement(false);
    navigate(-1);
  };

  const handleRejectAgreement = () => {
    dispatch(uploadFundingAgreementDecision(false)).then((data) => {
      if (data?.payload?.success) {
        setRejectAgreementModal(false);
        setRejectedAgreement(true);
      }
    });
  };
  // handle download

  const modalContentRef = useRef(null);

  const handleDownload = () => {
    setDownloadAgreementClick(true);
    setDownloadModal(true);

    setTimeout(() => {
      const element = modalContentRef.current;

      // Get the dimensions of the modal content
      const width = element.offsetWidth;
      const height = element.offsetHeight;

      // Set the options for the PDF
      const options = {
        filename: "funding-agreement.pdf",
        jsPDF: { unit: "px", format: [width, height], orientation: "portrait" },
        image: { type: "jpeg", quality: 1 },
        output: "dataurlnewwindow",
        html2canvas: { scale: 3 },
      };

      // Generate the PDF
      html2pdf().set(options).from(element).save();
      setDownloadModal(false);
      setDownloadAgreementClick(false);
    }, 3500);
  };

  return (
    <>
      <Heading nohelp />
      <div className="flex flex-col gap-6 items-center w-full pb-20 pt-4 px-4 md:px-[3%] relative">
        <span
          onClick={() => handleBack()}
          className="left-[3%] cursor-pointer absolute"
          style={{ top: "1rem" }}
        >
          <PAIconArrowLeft />
        </span>
        <header className="flex flex-col items-center w-full pt-6">
          <div>
            <h1 className="font-medium text-2xl">Review Funding Agreement</h1>
          </div>
          <div className="w-full">
            {/* <span className="text-xs font-semibold">Page 1 of 1</span> */}
            <div class="text-black text-xs font-medium">Page 1 of 1</div>
          </div>
        </header>

        <main className="rounded-[10px] bg-[#F6F6F680] h-full w-full">
          <div
            className={`flex flex-col ${
              downloadAgreementClick ? "lg:flex-col" : "lg:flex-row"
            } justify-between h-full w-full`}
          >
            <section
              ref={modalContentRef}
              className={`w-fit ${
                downloadAgreementClick ? "lg:w-fit" : "lg: w-1/2"
              } m-10 p-[30px] overflow-y-auto border-[1.39px] border-[#B4B4B433] rounded-[2px] bg-white ${
                downloadAgreementClick ? "min-h-fit" : "max-h-[600px]"
              } scrollbar-hide pb-5`}
            >
              <header className="flex w-full mb-2.5 justify-between font-bold">
                <span></span>
                <span>FUNDING AGREEMENT</span>
                <span
                  onClick={() => handleDownload()}
                  className="cursor-pointer"
                >
                  {!downloadAgreementClick && <PAIconDownloadFunding />}
                </span>
              </header>

              <main className="flex flex-col gap-3">
                <section className="flex flex-col gap-1">
                  <div className="text-xs text-[13.62px]">
                    This{" "}
                    <span className="font-semibold">Funding Agreement</span>{" "}
                    (the “Agreement”) is made and entered into on [Date] by and
                    between:
                  </div>

                  <section className="flex flex-col gap-1">
                    <div className=" text-justify  text-black text-[11.62px]  capitalize tracking-wide">
                      1.{" "}
                      <span className="font-semibold">
                        Platform Provider (Facilitator)
                      </span>{" "}
                      : <br /> [Your Company Name], Address: [Company Address],
                      Email: [Company Email], (Hereinafter referred to as the
                      "Facilitator")
                    </div>
                    <div className=" text-justify  text-black text-[11.62px]  capitalize tracking-wide">
                      2. <span className="font-semibold">Funder Name</span>:{" "}
                      <br />
                      [Funder's Full Name or Company Name], Address: [Funder’s
                      Address], Email: [Funder’s Email], (Hereinafter referred
                      to as the "Funder")
                    </div>{" "}
                    <div className=" text-justify  text-black text-[11.62px]  capitalize tracking-wide">
                      3. <span className="font-semibold">Buyer Name</span>:{" "}
                      <br />
                      [Buyer’s Full Name or Company Name], Address: [Buyer’s
                      Address], Email: [Buyer’s Email], (Hereinafter referred to
                      as the "Buyer")
                    </div>
                  </section>
                </section>

                <section className="flex flex-col gap-[10px]"></section>

                <section className="flex flex-col gap-2.5">
                  <div className="text-xs font-bold  text-[13.62px]">
                    Recitals:
                  </div>

                  <div className="text-black text-xs">
                    Whereas, the Buyer seeks funding for an issued invoice (the
                    “Invoice”), and Whereas, the Funder agrees to provide
                    funding for the Invoice, and Whereas, the Facilitator
                    operates a platform that enables such funding transactions
                    between the Buyer and the Funder, Now, therefore, in
                    consideration of the mutual promises and covenants contained
                    herein, the parties agree as follows:
                  </div>

                  <section className="flex flex-col gap-1">
                    <div className=" text-justify  text-black text-[11.62px]  capitalize tracking-wide">
                      <div className="font-semibold ">i. Invoice Details: </div>
                      The Buyer has issued an Invoice to [Client Name] for the
                      amount of [Invoice Amount] USD, due on [Invoice Due Date].
                      A copy of the Invoice is attached to this Agreement as
                      Exhibit A.
                    </div>
                    <div className=" text-justify  text-black text-[11.62px]  capitalize tracking-wide">
                      <div className="font-semibold ">ii. Funding Amount</div>
                      The Funder agrees to provide the Buyer with funding in the
                      amount of [Funded Amount] USD, representing [Percentage]%
                      of the total Invoice amount. The Facilitator will release
                      the funds to the Buyer within [Number] days of this
                      Agreement.
                    </div>
                    <div className=" text-justify  text-black text-[11.62px]  capitalize tracking-wide">
                      <div className="font-semibold ">iii. Repayment Terms</div>
                      The Buyer agrees to repay the full funded amount plus a
                      [Percentage]% fee (the “Fee”) to the Funder via the
                      Facilitator upon receipt of payment from the client, but
                      no later than [Repayment Due Date]. The total repayment
                      amount is [Funded Amount + Fee] USD.
                    </div>
                    <div className="text-justify  text-black text-[11.62px]  capitalize tracking-wide">
                      <div className="font-semibold ">
                        iv. Role of the Facilitator
                      </div>
                      The Facilitator agrees to:
                      <ul>
                        <li className="flex items-center gap-1 ml-1">
                          {" "}
                          &#8226; Facilitate the transfer of funds between the
                          Funder and the Buyer.
                        </li>

                        <li className="flex items-center gap-1 ml-1">
                          {" "}
                          &#8226; Provide a secure platform for managing the
                          transaction.
                        </li>

                        <li className="flex items-center gap-1 ml-1">
                          {" "}
                          &#8226;Monitor and track repayments.
                        </li>
                      </ul>
                      The Facilitator is not liable for any disputes between the
                      Funder and Buyer regarding the Invoice or repayment.
                    </div>{" "}
                    <div className="text-justify  text-black text-[11.62px]  capitalize tracking-wide">
                      <div className="font-semibold ">v. Late Payment</div>
                      If the Buyer fails to repay the full amount by the
                      Repayment Due Date, a late fee of [Late Fee Percentage]%
                      per [Time Period, e.g., week/month] will be added to the
                      outstanding balance until full repayment is made. The
                      Facilitator may assist in facilitating further actions,
                      including collection, if necessary.
                    </div>
                    <div className="text-justify  text-black text-[11.62px]  capitalize tracking-wide">
                      <div className="font-semibold ">
                        vi. Buyer’s Representations
                      </div>
                      The Buyer represents and warrants that:
                      <ul>
                        <li className="flex items-center gap-1 ml-1">
                          {" "}
                          &#8226; The Invoice is valid, enforceable, and free
                          from disputes.
                        </li>

                        <li className="flex items-center gap-1 ml-1">
                          {" "}
                          &#8226; The Buyer has not previously assigned or
                          pledged the Invoice to any other party.
                        </li>

                        <li className="flex items-center gap-1 ml-1">
                          {" "}
                          &#8226; The Buyer has the legal authority to enter
                          into this Agreement.
                        </li>
                      </ul>
                    </div>
                    <div className="text-justify  text-black text-[11.62px]  capitalize tracking-wide">
                      <div className="font-semibold ">vii. Funder’s Rights</div>
                      If the Buyer fails to repay the funded amount by the
                      Repayment Due Date, the Funder reserves the right to:
                      <ul>
                        <li className="flex items-center gap-1 ml-1">
                          {" "}
                          &#8226;Collect the repayment directly from the client.
                        </li>

                        <li className="flex items-center gap-1 ml-1">
                          {" "}
                          &#8226; Charge any costs associated with collections
                          to the Buyer.
                        </li>

                        <li className="flex items-center gap-1 ml-1">
                          {" "}
                          &#8226; Use the Facilitator’s platform to initiate
                          collection processes, subject to platform terms.
                        </li>
                      </ul>
                    </div>
                    <div className="text-justify  text-black text-[11.62px]  capitalize tracking-wide">
                      <div className="font-semibold ">
                        viii. Facilitator’s Fees
                      </div>
                      The Facilitator will charge a fee of [Facilitator Fee]% of
                      the funded amount, which will be deducted from the amount
                      disbursed to the Buyer.
                    </div>
                    <div className="text-justify  text-black text-[11.62px]  capitalize tracking-wide">
                      <div className="font-semibold ">ix. Indemnity</div>
                      The Buyer and the Funder each agree to indemnify, defend,
                      and hold harmless the Facilitator, its affiliates,
                      officers, employees, and agents from and against any and
                      all claims, liabilities, damages, losses, costs, or
                      expenses (including reasonable attorney's fees) arising
                      out of or related to:
                      <ul>
                        <li className="flex items-center gap-1 ml-1">
                          {" "}
                          &#8226; Any breach of the representations, warranties,
                          or obligations set forth in this Agreement.
                        </li>

                        <li className="flex items-center gap-1 ml-1">
                          {" "}
                          &#8226; Any dispute between the Buyer and the Funder
                          regarding the Invoice or repayment.
                        </li>

                        <li className="flex items-center gap-1 ml-1">
                          {" "}
                          &#8226; Any action or inaction of either party that
                          leads to a third-party claim against the Facilitator.s
                        </li>
                      </ul>
                      The Facilitator shall have the right to participate in the
                      defense of any such claim at its own expense.
                    </div>
                    <div className="text-justify  text-black text-[11.62px]  capitalize tracking-wide">
                      <div className="font-semibold ">x. Termination</div>
                      This Agreement will terminate upon the full repayment of
                      the funded amount and any applicable fees. The Facilitator
                      may terminate the Agreement or its services if either the
                      Buyer or the Funder violates the platform’s terms.
                    </div>
                    <div className="text-justify  text-black text-[11.62px]  capitalize tracking-wide">
                      <div className="font-semibold ">xi. Governing Law</div>
                      This Agreement shall be governed by and construed in
                      accordance with the laws of [State/Country].
                    </div>
                    <div className="text-justify  text-black text-[11.62px]  capitalize tracking-wide">
                      <div className="font-semibold ">
                        xii. Dispute Resolution
                      </div>
                      Any disputes arising from this Agreement shall be resolved
                      through [Arbitration/mediation] in [Jurisdiction].
                    </div>
                  </section>
                </section>
              </main>
            </section>

            <section className="min-h-[570px] rounded-r-[10px] px-10 py-8 bg-custom-orange w-full lg:w-[40%]">
              <div className="py-6 px-5 border-[0.5px] border-[#CFCFCF] rounded-[10px] h-full bg-white">
                <header className="flex px-5 items-center justify-between">
                  <span className="font-semibold">Agreement Details</span>
                  <PAIconAgreeFolder onClick={handleDownload} />
                </header>

                <main className="px-5">
                  <div className="flex justify-between text-sm font-medium py-2.5 mt-2">
                    <span>Agreement name</span>
                    <span className="text-[#979797]"> Funding agreement</span>
                  </div>

                  <div className="flex justify-between text-sm font-medium py-2.5">
                    <span>Agreement Initiator</span>
                    <span className="text-[#979797]"> Payedge</span>
                  </div>

                  <div className="flex justify-between text-sm font-medium py-2.5">
                    <span>Agreement Recipient</span>
                    <span className="text-[#979797]">Dangote groups</span>
                  </div>

                  <div className="flex justify-between text-sm font-medium py-2.5">
                    <span>No. of parties involved</span>
                    <span className="text-[#979797]">2</span>
                  </div>

                  <div className="flex justify-between text-sm font-medium py-2.5">
                    <span>Initiator sign status</span>
                    <span className="text-[#979797]">Signed</span>
                  </div>

                  <div className="flex justify-between text-sm font-medium py-2.5">
                    <span>Recipient sign status</span>
                    <span className="text-[#979797]">Not signed</span>
                  </div>

                  <div className="flex justify-between text-sm font-medium py-2.5">
                    <span>No. of updates</span>
                    <span className="text-[#979797]">1</span>
                  </div>

                  <div className="flex justify-between text-sm font-medium py-2.5">
                    <span>Date of Initiation</span>
                    <span className="text-[#979797]">July 12, 2024</span>
                  </div>

                  <div className="flex justify-between text-sm font-medium py-2.5">
                    <span>Date of Finalization</span>
                    <span className="text-[#979797]">-</span>
                  </div>

                  <div className="flex justify-between text-sm font-medium py-2.5">
                    <span>Description</span>
                  </div>

                  <div className="py-2.5 text-[#979797] text-sm font-medium">
                    The funding agreement outlines the detailed terms and
                    conditions under which you will receive financial support.
                    It ensures both parties clearly understand their obligations
                    and expectations, providing a framework for the funding
                    process.
                  </div>
                </main>
              </div>
            </section>
          </div>

          <footer className="w-full flex flex-col md:flex-row justify-between px-10 py-5 shadow-sm rounded-b-[10px] bg-white">
            <section className="flex gap-3 items-center">
              <span>
                <PAIconAgreeWarning />
              </span>

              <div className="text-sm font-semi">
                By clicking the{" "}
                <span className="text-secondaryColor font-extrabold">
                  “Click to sign”
                </span>{" "}
                button, you're approving <br /> and confirming your consent to
                the above agreement
              </div>
            </section>
            <section className="flex gap-4 text-sm">
              <motion.button
                whileTap={{ scale: 0.95 }}
                onClick={() => setSignAgreementModal(true)}
                className="font-medium flex gap-2.5 justify-center items-center py-2.5 px-5 text-white rounded-[5px] bg-secondaryColor"
              >
                Click to sign <PAIconSignPen />
              </motion.button>

              <motion.button
                className="flex gap-2.5 items-center justify-center rounded-[5px] border text-[#CD3A1A] border-[#CD3A1A] py-2.5 px-5"
                whileTap={{ scale: 0.95 }}
                onClick={() => setRejectAgreementModal(true)}
              >
                Reject
                <PAIconAgreeReject />
              </motion.button>
            </section>
          </footer>
        </main>

        <AnimatePresence>
          {signAgreementModal && (
            <ViewDocumentModal
              onAction={() => {
                handleAcceptAgreement();
              }}
              onClose={() => setSignAgreementModal(false)}
            >
              <span className=" text-center">
                Are you sure you want to{" "}
                <span className="text-primaryColor">Sign</span> this Agreement?
              </span>
            </ViewDocumentModal>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {rejectedAgreement && (
            <AgreementRejectedModal
              onClose={() => setRejectedAgreement(false)}
              onContinue={handleCancelContinue}
            ></AgreementRejectedModal>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {rejectAgreementModal && (
            <ViewDocumentModal
              onAction={() => {
                handleRejectAgreement();
              }}
              onClose={() => {
                setRejectAgreementModal(false);
              }}
            >
              <span className=" text-center">
                Are you sure you want to{" "}
                <span className="text-[#BB0C0C]">Reject</span> this Agreement?
              </span>
            </ViewDocumentModal>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {agreementAccepted && (
            <AgreementSignedModal
              onClose={() => {
                handleCloseAcceptAgreement();
              }}
            />
          )}
        </AnimatePresence>
      </div>
      {downloadModal && <ProgressModal />}
    </>
  );
}

export default FundingAgreement;
