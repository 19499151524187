import Modal from "layout/modal/Modal";
import React from "react";
import BulkInvoiceModalContent from "./bulkinvoicemodalcontent";

const BulkInvoiceModal = ({
  isShown,
  onClose,
  data,
  directFunding,
  handleProceed,
  handleSelectAccount,
  handleGoBack
}) => {
  return (
    <Modal size="medium" isShown={isShown} hide={onClose}>
      <BulkInvoiceModalContent
        data={data}
        onClose={onClose}
        directFunding={directFunding}
        handleProceed={handleProceed}
        handleGoBack={handleGoBack}
        handleSelectAccount={handleSelectAccount}
      />
    </Modal>
  );
};

export default BulkInvoiceModal;
