import DashboardLayout from "layout/dashboardlayout";
import React from "react";
import Details from "./details";

const PFPPendingDetails = () => {
  return (
    <DashboardLayout
    section="Payable Finance Program"
    subHeader="Here is an overview of your PFP"
    >
      <Details />
    </DashboardLayout>
  );
};

export default PFPPendingDetails;
