import React from "react";
import DashboardLayout from "layout/dashboardlayout";
import { PAIconArrowLeftGreen } from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import Table from "layout/table/Table";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import Pagination from "shared/Pagination";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect, useMemo, useCallback } from "react";

// import { useNavigate } from "react-router-dom";fetchWorkflowPendingApprovals
import { fetchWorkflowPendingApprovals } from "appstate/workflow/workflowSlice";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";
// fetchWorkflowDashBoard

const WorkFlowPendingApprovalRequestsScreen = () => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };

  // const [page, setPage] = useState(0);
  // const [startPage, setStartPage] = useState(0);
  // const [endPage, setEndPage] = useState(5);
  const [filteredData, setFilteredData] = useState([]);
  const [search] = useState("");
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(10);
  const pageSize = 10;

  const columns = [
    {
      name: "Date Sent",
      selector: (row) => row.dateSent,
    },
    {
      name: "Transaction Type ",
      selector: (row) => row.type,
    },
    {
      name: "Action Type ",
      selector: (row) => row.action,
    },

    {
      name: "Sender",
      selector: (row) => row.sender,
    },
    {
      name: "Approval Stage",
      selector: (row) => row.approvalStage,
    },

    {
      name: "Days in Queue",
      selector: (row) => row.daysInQueue,
    },

    {
      name: "Previous Approver",
      selector: (row) => row.previousApprover,
    },

    {
      name: "Next Approver",
      selector: (row) => row.nextApproval,
    },

    // {
    //   name: "",
    //   selector: (row) => row.supplierId,
    // },
  ];

  const dispatch = useDispatch();

  const { pendingApprovals } = useSelector((state) => state.workflow);

  const handleRowClick = (row) => {
    if (row.action === "Invoice Approval" && row.type === "INVOICING") {
      navigate(
        `/work-flow-management/detail/invoiceapprovalscreen/${row.workflowId}`
      );
    } else if (
      row.type === "INVOICING" &&
      row.action === "Submit for Approval"
    ) {
      navigate(
        `/work-flow-management/detail/invoiceapprovalscreen/${row.workflowId}`
      );
    }
    if (row.action === "Upload Invoice" && row.type === "INVOICING") {
      navigate(
        `/work-flow-management/detail/invoiceapprovalscreen/${row.workflowId}`
      );
    } else if (
      row.type === "INVOICING" &&
      row.action === "Supplier Upload Invoice"
    ) {
      navigate(
        `/work-flow-management/detail/invoiceapprovalscreen/${row.workflowId}`
      );
    } else if (row.type === "INVOICING" && row.action === "Approve Invoice") {
      navigate(
        `/work-flow-management/detail/invoiceapprovalscreen/${row.workflowId}`
      );
    } else if (row.type === "INVOICING" && row.action === "Create Invoice") {
      navigate(
        `/work-flow-management/detail/invoiceapprovalscreen/${row.workflowId}`
      );
    } else if (row.type === "INVOICING" && row.action === "Edit Invoice") {
      navigate(
        `/work-flow-management/detail/invoiceapprovalscreen/${row.workflowId}`
      );
    } else if (row.type === "INVOICING" && row.action === "Delete Invoice") {
      navigate(
        `/work-flow-management/detail/invoiceapprovalscreen/${row.workflowId}`
      );
    } else if (
      row.type === "EARLY_PAYMENT_REQUEST" &&
      row.action === "Approve via Wallet"
    ) {
      navigate(
        `/work-flow-management/detail/approvalviawalletscreendetails/${row.workflowId}`
      );
    } else if (
      row.type === "EARLY_PAYMENT_REQUEST" &&
      row.action === "Send via Direct Funding"
    ) {
      navigate(
        `/work-flow-management/detail/approvalviawalletscreendetails/${row.workflowId}`
      );
    } else if (
      row.type === "EARLY_PAYMENT_REQUEST" &&
      row.action === "Send via Closed Market"
    ) {
      navigate(
        `/work-flow-management/detail/approvalviawalletscreendetails/${row.workflowId}`
      );
    } else if (
      row.type === "EARLY_PAYMENT_REQUEST" &&
      row.action === "Approve via Direct Funding"
    ) {
      navigate(
        `/work-flow-management/detail/approvalviawalletscreendetails/${row.workflowId}`
      );
    } else if (
      row.type === "EARLY_PAYMENT_REQUEST" &&
      row.action === "Reject Request"
    ) {
      navigate(
        `/work-flow-management/detail/approvalviawalletscreendetails/${row.workflowId}`
      );
    } else if (
      row.type === "EARLY_PAYMENT_REQUEST" &&
      row.action === "Early Payment Create Request"
    ) {
      navigate(
        `/work-flow-management/detail/approvalviawalletscreendetails/${row.workflowId}`
      );
    } else if (
      row.type === "EARLY_PAYMENT_REQUEST" &&
      row.action === "Edit Request"
    ) {
      navigate(
        `/work-flow-management/detail/approvalviawalletscreendetails/${row.workflowId}`
      );
    } else if (
      row.type === "EARLY_PAYMENT_REQUEST" &&
      row.action === "Cancel Request"
    ) {
      navigate(
        `/work-flow-management/detail/approvalviawalletscreendetails/${row.workflowId}`
      );
    } else if (
      row.type === "DIRECT_FUNDING" &&
      row.action === "Direct Funding Create Request"
    ) {
      navigate(
        `/work-flow-management/detail/approvalviawalletscreendetails/${row.workflowId}`
      );
    } else if (
      row.type === "DIRECT_FUNDING" &&
      row.action === "Approve Request"
    ) {
      navigate(
        `/work-flow-management/detail/approvalviawalletscreendetails/${row.workflowId}`
      );
    } else if (
      row.type === "DIRECT_FUNDING" &&
      row.action === "Direct Funding Buyer's Confirmation"
    ) {
      navigate(
        `/work-flow-management/detail/approvalviawalletscreendetails/${row.workflowId}`
      );
    } else if (
      row.type === "EARLY_PAYMENT_OFFERS" &&
      row.action === "Create Offer"
    ) {
      navigate(
        `/work-flow-management/detail/approvalviacampagncreationscreen/${row.workflowId}`
      );
    } else if (
      row.type === "EARLY_PAYMENT_OFFERS" &&
      row.action === "Accept Early Payment Offers"
    ) {
      navigate(
        `/work-flow-management/detail/approvalviacampagncreationscreen/${row.workflowId}`
      );
    } else if (
      row.type === "EARLY_PAYMENT_OFFERS" &&
      row.action === "Reject Early Payment Offers"
    ) {
      navigate(
        `/work-flow-management/detail/approvalviacampagncreationscreen/${row.workflowId}`
      );
    } else if (row.type === "PFP" && row.action === "Create Campaign") {
      navigate(
        `/work-flow-management/detail/approvalviacampagncreationscreen/${row.workflowId}`
      );
    } else if (row.type === "PFP" && row.action === "Sign PFP Agreement") {
      navigate(
        `/work-flow-management/detail/PendingApprovalSignPfpdetails/${row.workflowId}`
      );
    } else if (row.type === "PFP" && row.action === "Fund Offer") {
      navigate(
        `/work-flow-management/detail/PendingApprovalPFPFundOffer/${row.workflowId}`
      );
    } else if (row.type === "PFP" && row.action === "Accept Fund Offers") {
      navigate(
        `/work-flow-management/detail/approvalviacampagncreationscreen/${row.workflowId}`
      );
    } else if (row.type === "PFP" && row.action === "Reject Fund Offers") {
      navigate(
        `/work-flow-management/detail/approvalviacampagncreationscreen/${row.workflowId}`
      );
    } else if (
      row.type === "OPEN_MARKET" &&
      row.action === "Open Market Buyer's Confirmation"
    ) {
      navigate(
        `/work-flow-management/detail/approvalviaconfirmationandopenmarket/${row.workflowId}`
      );
    } else if (row.type === "OPEN_MARKET" && row.action === "Import Invoice") {
      navigate(
        `/work-flow-management/detail/approvalviaconfirmationandopenmarket/${row.workflowId}`
      );
    }
  };

  // detail/PendingApprovalSignPfpdetails/:workflowId

  //detail/approvalviacampagncreationscreen/
  useEffect(() => {
    dispatch(fetchWorkflowPendingApprovals({ page, pageSize, search }));
  }, [dispatch, page, search, pageSize]);

  // Extract data for table and dropdown options
  // const data = pendingApprovals?.data || [];
  const data = useMemo(() => pendingApprovals?.data || [], [pendingApprovals]);

  const [searchValue] = useState(""); // To store the search input value
  const [activeFilter, setActiveFilter] = useState("");

  // Update filtered data whenever the selected type changes

  const filterData = useCallback(() => {
    let updatedData = [...data];

    // Apply filter by type
    if (activeFilter) {
      updatedData = updatedData.filter((item) => item.type === activeFilter);
    }

    // Apply search filter
    if (searchValue) {
      updatedData = updatedData.filter((item) => {
        const lowerSearch = searchValue.toLowerCase();
        return (
          item.dateSent.toLowerCase().includes(lowerSearch) ||
          item.type.toLowerCase().includes(lowerSearch) ||
          item.action.toLowerCase().includes(lowerSearch) ||
          item.finalApproverName.toLowerCase().includes(lowerSearch) ||
          item.approvalLevel.toLowerCase().includes(lowerSearch) ||
          (item.finalApprovalDate &&
            item.finalApprovalDate.toString().includes(lowerSearch)) ||
          (item.status && item.status.toLowerCase().includes(lowerSearch))
        );
      });
    }

    setFilteredData(updatedData);
  }, [data, activeFilter, searchValue, setFilteredData]);

  useEffect(() => {
    filterData();
  }, [filterData]);

  // Synchronize data when filters or data chang

  // Synchronize data when filters or data change
  useEffect(() => {
    filterData();
  }, [filterData]);

  const handleFilterChangeOne = (e) => {
    setActiveFilter(e.target.value);
  };

  return (
    <DashboardLayout
      section="Workflow "
      subHeader="Manage all your workflow approvals"
    >
      <div className=" lg:min-h-screen">
        {" "}
        <header className="bg-white text-primaryColor px-6  rounded-[5px] ">
          <span
            onClick={() => handleBack()}
            className="flex cursor-pointer py-4 w-fit font-light items-center text-sm gap-2"
          >
            <PAIconArrowLeftGreen />
            Go Back
          </span>
        </header>
        <>
          <AnimatePresence>
            <Card className="rounded-[10px] mt-[24px]   bg-black  pb-14">
              <div className="flex justify-between items-center px-[24px]   rounded-t-[10px]  ">
                <div className="flex gap-2 my-6 justify-between">
                  <div className="text-black text-[13px] font-medium items-center justify-center h-[23px]  flex ">
                    Pending Workflow Approvals
                  </div>

                  <div className=" justify-center items-center flex">
                    <div className="w-[23px] h-[23px]  bg-[#f08000] rounded-[100%]  justify-center items-center flex">
                      <div className="text-white text-xs font-medium  ">
                        {pendingApprovals?.data?.length}
                      </div>
                    </div>
                  </div>
                </div>
                {/* filter section */}
                {pendingApprovals?.data?.length > 0 && (
                  <div className=" mr-8">
                    <select
                      className=" shadow-sm  px-4 py-2.5   focus:outline-none cursor-pointer h-[41px]    justify-start items-center  text-[#7a7a7a] text-xs font-normal bg-white rounded-[5px] border border-[#dedede]/80  gap-[7px] "
                      onChange={handleFilterChangeOne}
                      value={activeFilter}
                    >
                      <option value="" disabled selected>
                        Filter by{" "}
                      </option>

                      <option value={"INVOICING"}>INVOICING</option>
                      <option value="EARLY_PAYMENT_REQUEST">
                        EARLY_PAYMENT_REQUEST
                      </option>
                      <option value="EARLY_PAYMENT_OFFERS">
                        EARLY_PAYMENT_OFFERS
                      </option>
                      <option value="OPEN_MARKET">OPEN_MARKET</option>
                      <option value="DIRECT_FUNDING">DIRECT_FUNDING</option>
                      <option value="PFP">PFP</option>
                    </select>
                  </div>
                )}
              </div>
              <div className="px-[24px] ">
                {pendingApprovals?.data &&
                pendingApprovals?.data?.length > 0 ? (
                  <Table
                    columns={columns}
                    data={filteredData}
                    onRowClicked={(row) => handleRowClick(row)}
                    pointer
                    tableHeader
                    className="mt-5"
                  />
                ) : (
                  <TableEmptyState dashboard />
                )}
              </div>

              <div>
                {pendingApprovals?.meta?.totalElements > 10 && (
                  <div className="flex justify-center gap-x-[8px]  mt-5">
                    <Pagination
                      page={page}
                      startPage={startPage}
                      setEndPage={setEndPage}
                      endPage={endPage}
                      setStartPage={setStartPage}
                      setPage={setPage}
                      totalPages={pendingApprovals?.meta?.totalElements}
                    />
                  </div>
                )}
              </div>
            </Card>
          </AnimatePresence>
        </>
      </div>
    </DashboardLayout>
  );
};

export default WorkFlowPendingApprovalRequestsScreen;
