import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "shared/LoadingScreen";
import {
  PAIconArrowLeftGreen,
  PAIconCocacola,
  PAIconDownload,
} from "assets/images/svgs";
import TransitionScale from "shared/animation/TransitionScale";
import { useState } from "react";

const Details = () => {
  const navigate = useNavigate();
  const { isLoading } = useSelector((state) => state?.invoice);
  const [selectedTab, setSelectedTab] = useState("tab1");

  // useEffect(() => {
  //   dispatch(allInvoiceById({ id }));
  // }, [dispatch, id]);

  // Calculate the payment based on the due date and created date
  // const calculatePayment = (dueDate, createdDate) => {
  //   const oneDay = 24 * 60 * 60 * 1000;
  //   const dueDateObj = new Date(dueDate);
  //   const createdDateObj = new Date(createdDate?.split(" ")[0]);
  //   const diffDays = Math.round(
  //     Math.abs((dueDateObj - createdDateObj) / oneDay)
  //   );
  //   return `${diffDays} Days`;
  // };

  if (isLoading) {
    return <LoadingScreen />;
  }

  const dummyData = [
    {
      invoiceNo: "INV-2090",
      dueDate: "2023-05-23",
      requestAmount: "9,000,000",
      discountRate: "1.5%",
      recievableAmount: "9,000,000",
    },
    {
      invoiceNo: "INV-2090",
      dueDate: "2023-05-23",
      requestAmount: "9,000,000",
      discountRate: "1.5%",
      recievableAmount: "9,000,000",
    },

    {
      invoiceNo: "INV-2090",
      dueDate: "2023-05-23",
      requestAmount: "9,000,000",
      discountRate: "1.5%",
      recievableAmount: "9,000,000",
    },
    {
      invoiceNo: "INV-2090",
      dueDate: "2023-05-23",
      requestAmount: "9,000,000",
      discountRate: "1.5%",
      recievableAmount: "9,000,000",
    },
  ];

  const detailsData = [
    {
      label: "Transfer from",
      value: "Elo",
    },
    {
      label: "Transfer to",
      value: "Wale",
    },
    {
      label: "Status",
      value: "Successful",
      statusColor: "text-[#07593D]",
    },
    {
      label: "Payment Date",
      value: "Oct 21, 2024 | 02:43PM",
      statusColor: "text-[#000]",
    },
    {
      label: "Transaction ID",
      value: "DbuibYYBH878huhi8UUI89ygt2132h",
      statusColor: "text-[#0076EC]",
    },
    {
      label: "Payment Type",
      value: "Partial Payment",
    },
    {
      label: "Virtual account name",
      value: "XYZ Venture",
      statusColor: "text-[#07593D]",
    },
    {
      label: "Virtual account no",
      value: "736*****211",
      statusColor: "text-[#07593D]",
    },
    {
      label: "Payment Method",
      value: "PayEdge Wallet",
      statusColor: "text-[#07593D]",
    },
    {
      label: "Funding Channel",
      value: "Early Payment Request",
      statusColor: "text-[#F08000]",
    },
    {
      label: "Description from Sender",
      value: "Payment sent. Appreciate our ongoing collaboration. Regards,",
    },
  ];

  // const data = {
  //   invoiceNumber: `INV-${id}` || "",
  //   referenceNumber: invoiceIdData?.data?.invoiceReference || "",
  //   createdDate: invoiceIdData?.data?.createdDate?.slice(0, 10) || "",
  //   dueDate: invoiceIdData?.data?.dueDate || "",
  //   payment: calculatePayment(
  //     invoiceIdData?.data?.dueDate,
  //     invoiceIdData?.data?.createdDate
  //   ),
  //   currency: invoiceIdData?.data?.currency || "",
  //   supplier: invoiceIdData?.data?.supplierCompanyName || "",
  //   buyer: invoiceIdData?.data?.buyerCompanyName || "",
  //   lineItems:
  //     invoiceIdData?.data?.items?.map((item) => ({
  //       itemNumber: item?.itemNumber || "",
  //       poNumber: item.poNumber || "",
  //       productNumber: item.productNumber || "",
  //       productDescription: item.productDescription || "",
  //       quantity: item.quantity || 0,
  //       unitPrice: item.unitPrice || "",
  //       extendedAmount: item.extendedAmount || "",
  //     })) || [],
  //   attachments: [
  //     {
  //       name: "DOC-29384.pdf",
  //       fileType: "PDF",
  //       uploadDateTime: "2021-09-14 13:25",
  //       downloadUrl: "https://example.com/doc-29384.pdf",
  //     },
  //   ],
  // };

  return (
    <>
      <header className="w-full pt-2 pb-4">
        <span
          onClick={() => navigate(-1)}
          className="flex cursor-pointer items-center font-medium text-sm text-primaryColor gap-2"
        >
          <PAIconArrowLeftGreen /> Go back
        </span>
      </header>{" "}
      <div className="flex flex-col lg:flex-row justify-between gap-6">
        {/* 60% */}
        <TransitionScale className="py-5 px-6 lg:w-[60%] flex flex-col gap-8 min-h-[90vh] bg-white rounded-[10px]">
          <header className="flex flex-col gap-3">
            <section className="flex justify-between  items-center">
              <span className="text-[18px] font-bold">
                Reference No: | R3456X89Y
              </span>
            </section>

            <section className="flex justify-between">
              <div className="flex flex-col gap-1 w-[40%]">
                <section className="flex items-center justify-between">
                  <span className="text-xs font-semibold">Request Date</span>
                  <span className="text-[#7a7a7a] text-xs font-semibold">
                    23-Mar -2023
                  </span>
                </section>

                <section className="flex items-center justify-between">
                  <span className="text-xs font-semibold">
                    Total Request Amount
                  </span>
                  <span className="text-[#7a7a7a] text-xs font-semibold">
                    24,000,000
                  </span>
                </section>

                <section className="flex items-center justify-between">
                  <span className="text-xs font-semibold">
                    Total Invoice Amount
                  </span>
                  <span className="text-[#7a7a7a] text-xs font-semibold">
                    34,000,000
                  </span>
                </section>
              </div>

              <div className="flex flex-col gap-1">
                <motion.button
                  whileTap={{ scale: 0.95 }}
                  className="py-2.5 px-8 font-medium text-xs rounded bg-white text-primaryColor border border-primaryColor"
                >
                  Paid
                </motion.button>
              </div>
            </section>
          </header>

          <section className="flex items-center justify-between">
            <div className="flex flex-col gap-2">
              <span className="text-[18px] font-semibold">Nestle PLC</span>
              <span className="text-[#7A7A7A] text-xs font-light">
                Buyer Details
              </span>
            </div>

            <div className="flex flex-col gap-2">
              <span className="text-[18px] font-semibold">60 Days</span>
              <span className="text-[#7A7A7A] text-xs font-light">Payment</span>
            </div>

            <div className="flex flex-col gap-2">
              <span className="text-[18px] flex justify-end font-semibold">
                NGN
              </span>
              <span className="text-[#7A7A7A] text-xs font-light">
                Currency
              </span>
            </div>
          </section>

          <section className="flex flex-col gap-4">
            <header className="flex items-center justify-between pb-3 border-b-[5px] border-[#F6F5FA] text-[#11192A]">
              <span className="text-sm">Invoice List</span>

              <span className="text-[18px] font-light">5 Invoices </span>
            </header>

            <div className=" w-full overflow-x-auto mt-3 pb-4">
              <table className=" w-full overflow-x-auto">
                <thead className="bg-subtleGray w-full p-6  flex-nowrap whitespace-nowrap  ">
                  <tr className=" bg-unusualGray  px-6 w-full flex-nowrap whitespace-nowrap ">
                    {/* <td className="text-[#11192A] p-2.5 text-xs font-bold">
                        Item Number
                      </td> */}
                    <td class="text-[#11192a] text-xs font-semibold  p-4  flex-nowrap whitespace-nowrap ">
                      Invoice Number{" "}
                    </td>
                    <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                      Due Date{" "}
                    </td>
                    <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                      Request Amount
                    </td>
                    <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                      Discount Rate
                    </td>

                    <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                      Receivable Amount
                    </td>
                  </tr>
                </thead>

                <tbody className="  ">
                  {dummyData.map((cell, idx) => {
                    return (
                      <tr key={idx} className=" w-full ">
                        <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap ">
                          {cell.invoiceNo}
                        </td>
                        <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                          {cell.dueDate}
                        </td>
                        <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                          {cell.requestAmount}
                        </td>
                        <td className="text-[#11192a] text-xs font-semibold mt-2 text-center flex-nowrap whitespace-nowrap">
                          {cell.discountRate}
                        </td>
                        <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                          {cell.recievableAmount}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className=" px-6   py-5 bg-neutral-50 flex-col justify-center items-start  inline-flex bg-[#fafafa] w-full border-t-[0.2px]  border-t-[#b1a6b2]">
              <div className="lg:flex md:flex justify-between  w-full sm:block ">
                {/* First left side */}
                <div>
                  <section>
                    {" "}
                    <div class="text-[#222222] text-lg  font-medium border-b-[0.2px]  border-[#b1a6b2]">
                      Receiving Funding Account Information
                    </div>
                    {/* <div class="text-[#222222] text-lg font-semibold ">
                        Receiving Funding Account Information
                      </div> */}
                    <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                      74*******512
                    </div>
                    <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                      Praise Akinlade
                    </div>
                  </section>
                </div>

                {/* second division */}

                <div>
                  {" "}
                  <div class=" flex-col justify-start items-start inline-flex lg:min-w-[290px]">
                    <section className=" w-full px-6 pb-2 border-b-[1px] border-black">
                      <div className=" flex w-full justify-between">
                        <div class="text-[#222222] text-xs font-semibold ">
                          Subtotal
                        </div>
                        <div class=" text-[#222222] text-xs font-semibold ">
                          10,129,892
                        </div>
                      </div>
                      <div className=" flex w-full justify-between pt-3 pb-1 border-b-[0.2px] border-b-[#b1a6b2]/30">
                        <div class="text-[#222222]  text-xs font-medium flex  space-x-9 ">
                          <span>VAT%</span>
                          <span>5.6%</span>
                        </div>
                        <div class="text-[#222222] text-xs font-medium ">
                          573,960
                        </div>
                      </div>

                      <div className=" flex w-full justify-between pt-3 pb-1 border-b-[0.2px] border-b-[#b1a6b2]/30">
                        <div class="text-[#222222]  text-xs font-medium flex  space-x-9 ">
                          <span>Discount%</span>
                          <span>2.4%</span>
                        </div>
                        <div class="text-[#222222] text-xs font-medium ">
                          237,088
                        </div>
                      </div>
                    </section>
                    <div className=" flex w-full justify-between py-2 px-6 border-b-[1px] border-b-black">
                      <div class="text-[#222222] text-lg font-semibold ">
                        Grand Total
                      </div>
                      <div class="text-[#222222] text-lg font-semibold ">
                        10,473,890
                      </div>
                    </div>
                  </div>
                </div>

                {/* stops */}
              </div>
            </div>
          </section>
        </TransitionScale>
        {/* 40% */}
        <TransitionScale className="bg-white w-full  rounded-[10px] gap-8 px-6 pt-7 pb-24">
          <div>
            <header className="py-2 flex gap-8 justify-center">
              <span
                onClick={() => setSelectedTab("tab1")}
                className={`${
                  selectedTab === "tab1"
                    ? "text-primaryColor font-semibold border-b border-primaryColor"
                    : "text-[#959595]"
                } py-2.5 cursor-pointer`}
              >
                Early Payment Details
              </span>
              <span
                onClick={() => setSelectedTab("tab2")}
                className={`${
                  selectedTab === "tab2"
                    ? "text-primaryColor  font-semibold border-b border-primaryColor"
                    : "text-[#959595]"
                } py-2.5 cursor-pointer`}
              >
                Transaction Details{" "}
              </span>
            </header>
            {selectedTab === "tab1" ? (
              <div>
                <div class="w-full justify-start items-center gap-4 inline-flex ">
                  <div class=" justify-start items-center gap-2.5 flex w-full px-6  py-3">
                    <div class="text-black text-xl font-bold">
                      Early Payment Details
                    </div>
                  </div>
                </div>

                <div>
                  <div className="   pb-4">
                    <div class=" justify-between  flex flex-row    px-6 mt-4  ">
                      <div className="flex gap-2 py-2.5">
                        <PAIconCocacola />
                        <div className="flex flex-col">
                          {/* <span className=" text-black text-xs font-normal">
                      Coca Cola
                    </span> */}
                          <span class="text-black text-sm font-normal ">
                            Coca Cola PLC
                          </span>
                          {/* <span className="text-[#979797]  text-xs">Supplier</span> */}
                          <span class="text-[#949494] text-sm font-normal ">
                            Supplier
                          </span>
                        </div>
                      </div>
                      <div class="py-2.5 flex-col justify-start items-start inline-flex">
                        <div class="flex-col justify-start items-start gap-0.5 flex">
                          <div class="text-black text-sm font-normal ">
                            1.5%
                          </div>
                        </div>
                        <div class="flex-col justify-start items-start  flex">
                          <div class="text-[#949494] text-sm font-normal ">
                            Discount rate
                          </div>
                        </div>
                      </div>

                      {/* here */}
                    </div>
                    <div class=" justify-between  flex flex-row    px-6  mt-6 ">
                      <div class="py-2.5 flex-col justify-start items-start inline-flex">
                        <div class="flex-col justify-start items-start gap-0.5 flex">
                          <div class="text-black text-sm font-normal ">
                            NGN90,000
                          </div>
                        </div>
                        <div class="flex-col justify-start items-start  flex">
                          <div class="text-[#949494] text-sm font-normal ">
                            Discount amount
                          </div>
                        </div>
                      </div>
                      {/* hd */}
                      <div class="py-2.5 flex-col justify-start items-start inline-flex">
                        <div class="flex-col justify-start items-start gap-0.5 flex">
                          <div class="text-black text-sm font-normal ">
                            23-Dec-2024 (20 days)
                          </div>
                        </div>
                        <div class="flex-col justify-start items-start  flex">
                          <div class="text-[#949494] text-sm font-normal ">
                            Early Payment Date
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                    <div class=" justify-between  flex flex-row    border-b border-[#c2c2c2]/30 px-6  py-3  pb-8 mt-6">
                      <div class="py-2.5 flex-col justify-start items-start inline-flex">
                        <div class="flex-col justify-start items-start gap-0.5 flex">
                          <div class="text-black text-sm font-normal ">
                            N 9,000,000
                          </div>
                        </div>
                        <div class="flex-col justify-start items-start  flex">
                          <div class="text-[#949494] text-sm font-normal ">
                            Request amount
                          </div>
                        </div>
                      </div>
                      {/* dhdh */}
                      <div class="py-2.5 flex-col justify-start items-start inline-flex">
                        <div class="flex-col justify-start items-start gap-0.5 flex">
                          <div class="text-black text-sm font-normal ">
                            8,9110,000
                          </div>
                        </div>
                        <div class="flex-col justify-start items-start  flex">
                          <div class="text-[#949494] text-sm font-normal ">
                            Fundable Amount
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* 2nd */}
                    <div class=" justify-between  flex flex-col    border-b border-[#c2c2c2]/30 px-6  pb-10 ">
                      <div class="text-black text-lg font-semibold  mt-6">
                        Note from Supplier:{" "}
                      </div>

                      <div class=" text-[#949494] text-sm font-light  mt-4  leading-6">
                        Seeking Early payment due to unexpected challenges.
                        Facing temporary financial constraints and need a bit
                        more time to ensure a smooth transaction. Appreciate
                        your understanding. Thank you.
                        <br className=" mt-3" />
                        Best regards, <br />
                        ABC Limited
                      </div>
                    </div>{" "}
                    {/* another header */}
                    {/* header ends here */}
                    {/* here */}
                    <div class="justify-between  flex flex-col  px-6">
                      <div class="text-black text-lg font-semibold  mt-6">
                        Funding Option
                      </div>

                      <div class=" text-[#949494] font-light  my-3 leading-6">
                        This invoice would be funded from this virtual account:
                      </div>
                    </div>
                    <div
                      style={{ color: "rgba(0, 0, 0, 0.50)" }}
                      className="flex flex-col px-6 text-sm font-semibold"
                    >
                      <span>Benjamin James</span>
                      <span>73*******112</span>
                      <span> PayEdge MFB</span>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="flex justify-between items-center mb-4">
                  <h2 className="font-semibold text-lg leading-[30px]">
                    Early Payment Offers Transaction Details
                  </h2>
                </div>

                <div className="flex justify-between my-3 border-b-[3px] border-b-[#F6F5FA] pb-5">
                  <div>
                    <h5 className="text-base font-normal">Amount</h5>
                    <h2 className="text-red text-2xl font-normal">
                      -9,000,000 NGN
                    </h2>
                  </div>

                  <div>
                    <button className="border flex items-center gap-2 text-sm border-gray300 rounded px-3 py-1.5">
                      <span> Export</span>
                      <span>
                        <PAIconDownload />
                      </span>
                    </button>
                  </div>
                </div>
                <div className="mt-8">
                  <div className="flex flex-col gap-3">
                    {detailsData.map((item, index) => (
                      <div key={index} className="mb-4 flex justify-between">
                        <div>
                          <p className="text-[#222] font-medium text-sm">
                            {item.label}
                          </p>
                        </div>
                        <div className="flex-1 ml-4 max-h-[50px]">
                          <p className="text-[#979797] font-medium text-sm flex justify-end text-right">
                            <span className={item.statusColor}>
                              {item.value}
                            </span>
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </TransitionScale>
      </div>
    </>
  );
};

export default Details;
