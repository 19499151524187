import React, { useEffect, useState } from 'react'
import RouteHeader from 'features/ToolsAgreement/components/RouteHeader'

import TransitionScale from 'shared/animation/TransitionScale';

import AcceptSignModal from '../../AgreementDetails/Modal/AcceptSignModal';
import AgreementSuccessModal from '../../AgreementDetails/Modal/AgreementSucessModal';
// import Agreement from '../../Agreement';
import { PAIconAddDocPlusIcon, PAIconActionDownloadBtn, PAIconMore } from 'assets/images/svgs';
import Table from 'layout/table/Table';
import Modal from 'features/ToolsAgreement/components/Tools/ContractTemplate/Modal';
import toast from 'react-hot-toast';
import { fetchAgreementTemplatesDetails } from 'appstate/api/agreement/agreement';
import { useParams } from 'react-router-dom';

// PAIconAgreeFolder


const TemplateDetails = ({ templateDetails, goBack, turnAddTemplate }) => {
  const { id } = useParams();
   const [templatesDetails, setTemplatesDetails] = useState(null);
   // eslint-disable-next-line
   const [loading, setLoading] = useState(false);

  const getTemplatesDetails = async () => {
    try {
      setLoading(true);
      const response = await fetchAgreementTemplatesDetails(id);
      setTemplatesDetails(response?.data || []);
    } catch (error) {
      toast.error("Failed to fetch templates!");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTemplatesDetails();
    // eslint-disable-next-line
  }, []);


  const [isAcceptSignModalOpen, setIsAcceptSignModalOpen] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const handleRetract = () => {
    setIsAcceptSignModalOpen(false)
    setIsSuccess(true)
  }

  const [selectedRows, setSelectedRows] = useState([]);

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);


  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  const columns = [
    {
      name: "VERSION",
      selector: (row) => row.version,
      sortable: true,
    },
    {
      name: "STATUS",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (<><p className={`${row.status === "Active" ? "text-[#F08000]" : row.status === "Published" ? "text-[#2EA923]" : "text-[#959595]"}`}>{row.status}</p></>)
    },
    {
      name: "DATE",
      selector: (row) => row.uploadDate,
      sortable: true,
    },

    {
      name: "ACTION",
      selector: (row) => row.option,
      cell: (row) => (
        <div>
          {/* <PAIconActionDownloadBtn /> */}
          <div className="flex items-center justify-end cursor-pointer">
            <>{row.status === "Published" ? <PAIconActionDownloadBtn /> : <PAIconMore onClick={toggleDropdown} />}</>
          </div>
          {isDropdownOpen && (
            <div className="absolute top-full right-5 mt-[-40px] bg-white rounded-md shadow-md">
              {/* Dropdown Content */}
              <ul className="p-3">
                <li className="py-2 px-4 hover:shadow-sm cursor-pointer">
                  Move To Draft
                </li>
                <li className="py-2 px-4 hover:shadow-sm cursor-pointer">
                  Download
                </li>
                <li className="py-2 px-4 hover:shadow-sm cursor-pointer">
                  Delete Document
                </li>
                
              </ul>
            </div>
          )}
        </div>
      ),
    },
  ];

  const handleRowSelect = (rowId) => {
    const isSelected = selectedRows.includes(rowId);
    setSelectedRows((prevSelectedRows) =>
      isSelected
        ? prevSelectedRows.filter((id) => id !== rowId)
        : [...prevSelectedRows, rowId]
    );
  };


  return (

    <>

      <>

        <AcceptSignModal
          isOpen={isAcceptSignModalOpen}
          onClose={() => setIsAcceptSignModalOpen(false)}
          mess1={"Are you sure you would like to retract this deletion request"}

          handleYes={handleRetract}
        />

        <AgreementSuccessModal isOpen={isSuccess} onClose={() => setIsSuccess(false)} message={"Deletion Retracted"} />
        <div>
          <div>
            {/* this should be a side bar */}
          </div>
          <div className="px-7 mb-10  w-[100%]">
            <div className="flex items-center justify-between">
              <RouteHeader
                Route1="Templates"
                Heading={templatesDetails?.name}
                // Route2={"PFP "}
                handleBack={goBack}
              />
              {/* <button
                          onClick={handleUpdateButtonClick}
                          className="bg-blue-400 rounded-md text-white p-1 px-4 mt-3 hover:bg-blue-300"
                        >
                          Update
                        </button> */}
            </div>
          </div>

          <div style={{width: "95%", margin: "0 auto"}} className="grid lg:grid-cols-[40%,57.5%] gap-5 w-full">
            <TransitionScale className=" pb-5 bg-white  rounded-[10px]  pt-2">
            {/* sidebar */}
            <div className='flex justify-between'>
              <div>
                {/* <div>
                  <span>Overview</span>
                </div>
                <div className="py-2 bg-[#F08000] text-white px-4 rounded-md">
                <span>Agreement</span>
              </div>
                <div>
                <span>Tools</span>
                </div> */}
              </div>
              <section className="min-h-[570px] rounded-r-[10px]   w-full ml-10 ">
                <div className="py-6 px-4 bg-[#959595]/5 rounded-[5px] h-full">
                  <header className="flex  items-center justify-between shadow-[0px_2px_4px_0px_rgba(98,98,98,0.07)] border-b border-stone-300 py-3">
                    <div class="justify-start text-neutral-800 text-base font-semibold ">Template Details</div>
                    {/* <PAIconAgreeFolder  /> */}
                  </header>

                  <main className="">
                    <div className="flex flex-col justify-between text-sm font-medium py-2.5 mt-2">
                      <div class="self-stretch justify-start text-black text-sm font-semibold ">{templatesDetails?.name}</div>
                      <span className="text-[#979797]"> Template Name</span>
                    </div>

                    <div className="flex flex-col justify-between text-sm font-medium py-2.5 mt-2">
                      <div class="self-stretch justify-start text-black text-sm font-semibold ">{templatesDetails?.templateType}</div>
                      <span className="text-[#979797]"> Agreement type</span>
                    </div>



                    <div className="flex flex-col justify-between text-sm font-medium py-2.5 mt-2">
                      <div class="self-stretch justify-start text-black text-sm font-semibold ">{templatesDetails?.uploadDate}</div>
                      <span className="text-[#979797]">Date Created</span>
                    </div>





                  </main>

                </div>

              </section>
              </div>
            </TransitionScale>
            <TransitionScale className=" pb-5 bg-white  rounded-[10px]  pt-2">



              <section className="min-h-[570px]   pb-8  w-full  bg-[#ffffff]/5 rounded-[5px] ">

                <div class="w-full px-8 py-4 rounded-tl-[5px] rounded-tr-[5px]   inline-flex justify-between items-center">
                  <div class="justify-start text-neutral-800 text-base font-semibold ">Documents Under this Template</div>
                  <div class="h-10 px-3 py-1.5 bg-[#07593D] rounded-lg inline-flex justify-start items-center gap-2.5" onClick={turnAddTemplate}>
                    <div class="w-4 h-4 relative overflow-hidden">
                      <PAIconAddDocPlusIcon />
                      {/* <div class="w-3.5 h-3.5 left-[1.07px] top-[1.07px] absolute outline outline-1 outline-offset-[-0.50px] outline-white"></div> */}
                    </div>
                    <div class="justify-start text-white text-xs font-normal">Add document</div>
                  </div>
                </div>



                <main className="flex flex-col gap-3">
                  <section className="flex flex-col gap-1">

                    <>
                      {/* <Card className=" mt-[24px]" style={{ width: "100%" }}> */}
                      <div className=" pb-[24px]">
                        <Table
                          columns={columns}
                          data={templatesDetails?.versions}
                          pointer
                          // tableHeaderPink
                          handleRowSelect={handleRowSelect}
                          className="mt-5"
                        />
                      </div>
                      {/* </Card> */}
                    </>


                    {/* <div class="justify-start text-neutral-800 text-xs font-medium ">PFP CAMPAIGN AGREEMENT</div> */}


                  </section>

                  {/* <section className="flex flex-col gap-[10px]"></section> */}

                </main>
              </section>
            </TransitionScale>

          </div>



        </div>
      </>

      <Modal isOpen={isModalOpen} onClose={toggleModal} />

    </>
  )
}
export default TemplateDetails;