import request from "apiInstance";
import toast from "react-hot-toast";

export const disbursementProgramOverviewApi = async ({
  programType,
  virtualAccountId,
  page = 0,
  pageSize = 5,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/disbursement/funder/${programType}/${virtualAccountId}?page=${page}&size=${pageSize}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const disbursementFunderVaApi = async ({
  programType = "direct-funding-request",
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/disbursement/funder/${programType}-va`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const disbursementFunderOverviewApi = async ({ filter = "ALL" }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/disbursement/funder/dashboard?filter=${filter}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const disbursementFunderTransactionHistoryApi = async ({
  searchTerm = "",
  filter = "ALL",
  page = 0,
  pageSize = 10,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/disbursement/funder/transaction-history?page=${page}&size=${pageSize}&filter=${filter}&search=${searchTerm}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const disbursementFunderTransactionDetailsByIdApi = async ({ id }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/disbursement/funder/transaction-details/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const openMarketInvoiceByIdApi = async ({ id }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/open-market/invoice-details-by-id/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const disbursementFunderPayNowApi = async (body) => {
  try {
    const response = await request({
      method: "post",
      url: `/api/v1/disbursement/funder/pay-now`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
