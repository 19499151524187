import { motion } from "framer-motion";
import { PAIconArrowLeftGreen } from "assets/images/svgs";

import { useNavigate } from "react-router-dom";
import TransitionScale from "shared/animation/TransitionScale";
import DashboardLayout from "layout/dashboardlayout";

function ApprovedFunderEarlyPaymentBulkInnerDetailsPage() {
  const navigate = useNavigate();

  function handleGoBack() {
    navigate(-1);
  }

  const dummyData = [
    {
      bidAmount: 3,
      item: "Solar Panel ",
      description: "Dangote 12 x 4 ultra high density so..",
      unitPrice: "340,000",
      Quantity: "3",
      amount: "1,020,000",
      taxRate: "1.2%",
      taxAmount: "12,240",
      totalPrice: "1,032,240",
    },

    {
      bidAmount: 4,
      item: "Solar Panel ",
      description: "Dangote 12 x 4 ultra high density so..",
      unitPrice: "340,000",
      Quantity: "3",
      amount: "1,020,000",
      taxRate: "1.2%",
      taxAmount: "12,240",
      totalPrice: "1,032,240",
    },

    {
      bidAmount: 5,
      item: "Solar Panel ",
      description: "Dangote 12 x 4 ultra high density so..",
      unitPrice: "340,000",
      Quantity: "3",
      amount: "1,020,000",
      taxRate: "1.2%",
      taxAmount: "12,240",
      totalPrice: "1,032,240",
    },
    {
      bidAmount: 6,
      item: "Solar Panel ",
      description: "Dangote 12 x 4 ultra high density so..",
      unitPrice: "340,000",
      Quantity: "3",
      amount: "1,020,000",
      taxRate: "1.2%",
      taxAmount: "12,240",
      totalPrice: "1,032,240",
    },
    {
      bidAmount: 7,
      item: "Solar Panel ",
      description: "Dangote 12 x 4 ultra high density so..",
      unitPrice: "340,000",
      Quantity: "3",
      amount: "1,020,000",
      taxRate: "1.2%",
      taxAmount: "12,240",
      totalPrice: "1,032,240",
    },
  ];

  return (
    <>
      <DashboardLayout
        section="Early Payment Request"
        subHeader="Manage all your Early payment requests"
      >
        <TransitionScale className="flex  flex-col gap-6 bg-[#EEE] ">
          <main>
            <div
              className="flex cursor-pointer w-full gap-1 items-center h-[52px] rounded-t-md   px-6"
              onClick={handleGoBack}
            >
              {/* <PAIconArrowLeftGray />
              <span className=" text-[#7A7A7A] text-sm">Go Back</span> */}
              <PAIconArrowLeftGreen />
              <span className="flex items-center text-primaryColor">
                Go Back
              </span>
            </div>
            <motion.section
              initial={{
                x: 20,
              }}
              animate={{
                x: 0,
              }}
              exit={{
                x: -20,
              }}
              transition={{
                delay: 0.6,
              }}
            >
              <TransitionScale className=" pb-5 bg-white  rounded-[3px]  pt-2">
                {/* Header with Left Padding */}

                {/* Other sections */}

                <section className="  mt-3">
                  <div class="text-[#222222] text-lg font-semibold mt-4 px-12">
                    Invoice | INV-2002
                  </div>

                  {/* Down Flex Texts */}
                  <div className=" flex justify-between px-12">
                    {/* first stage */}

                    <div class="lg:w-[229px]  sm:w-full h-[117px] flex-col justify-start items-start gap-3 inline-flex">
                      <div class="self-stretch justify-between items-center inline-flex mt-2">
                        <div class="text-[#222222] text-base font-semibold  ">
                          Reference No:{" "}
                        </div>
                        <div class="text-[#555555] text-sm font-semibold ">
                          0983018374BA
                        </div>
                      </div>
                      <div class="self-stretch  flex-col justify-center items-start gap-1 flex mt-2">
                        <div class="self-stretch justify-between items-center inline-flex ">
                          <div class="text-[#222222] text-xs font-semibold ">
                            Invoice Number:{" "}
                          </div>
                          <div class="text-[#7a7a7a] text-xs font-semibold ">
                            INV 2002
                          </div>
                        </div>
                        <div class="self-stretch justify-between items-center inline-flex ">
                          <div class="text-[#222222] text-xs font-semibold ">
                            Created Date:{" "}
                          </div>
                          <div class="text-[#7a7a7a] text-xs font-semibold ">
                            23-Mar -2023
                          </div>
                        </div>
                        <div class="self-stretch justify-between items-center inline-flex">
                          <div class="text-[#222222] text-xs font-semibold ">
                            Submitted Date:{" "}
                          </div>
                          <div class="text-[#7a7a7a] text-xs font-semibold ">
                            23-Mar -2023
                          </div>
                        </div>
                        <div class="self-stretch justify-between items-center inline-flex">
                          <div class="text-[#222222] text-xs font-semibold ">
                            Approved Date:{" "}
                          </div>
                          <div class="text-[#7a7a7a] text-xs font-semibold ">
                            23-April -2023
                          </div>
                        </div>
                        <div class="self-stretch justify-between items-center inline-flex">
                          <div class="text-[#222222] text-xs font-semibold ">
                            Due Date:{" "}
                          </div>
                          <div class="text-[#7a7a7a] text-xs font-semibold ">
                            30-May-2023
                          </div>
                        </div>
                        <div class="self-stretch justify-between items-center inline-flex">
                          <div class="text-[#222222] text-xs font-semibold ">
                            Issuance Date:{" "}
                          </div>
                          <div class="text-[#7a7a7a] text-xs font-semibold ">
                            30-Dec-2022
                          </div>
                        </div>
                      </div>

                      {/* stop */}
                    </div>
                    {/* second stage */}
                    <div className="flex justify-between items-center mt-10 ">
                      {" "}
                      <div class="h-[30px] px-8 py-2 bg-[#2ea923]/20 rounded-[5px] justify-center items-center gap-2.5 inline-flex">
                        <div class="text-center text-[#2ea923] text-xs font-semibold ">
                          Approved
                        </div>
                      </div>{" "}
                    </div>
                  </div>

                  {/* stops here */}
                  <div className="  px-12 mt-24">
                    <header className="border-b-[5px] border-b-[#F6F5FA] mt-3  flex justify-between py-1">
                      {/* <h1 className="text-[18px] font-semibold">
                  Supplier/buyer details
                </h1> */}
                      <div>
                        <div class="text-[#222222] text-lg font-semibold  w-4">
                          Nestle PLC
                        </div>
                        <div class="text-[#7a7a7a] text-xs font-light  ">
                          Buyer details
                        </div>
                      </div>

                      <div class="h-[41px] flex-col justify-start items-start  inline-flex px-12">
                        <div class="text-[#222222] text-base font-semibold ">
                          60 Days
                        </div>
                        <div class="w-[100px] text-[#7a7a7a] text-xs font-light  mt-1">
                          Payment terms
                        </div>
                      </div>

                      <div class="h-[43px] flex-col justify-start items-end  inline-flex">
                        <div class="text-[#222222] text-lg font-semibold ">
                          NGN
                        </div>
                        <div class="text-[#7a7a7a] text-xs font-semibold mt-1">
                          Currency
                        </div>
                      </div>
                    </header>

                    <header className="border-b-[5px] border-b-[#F6F5FA] mt-6  flex justify-between py-1">
                      {/* <h1 className="text-[18px] font-semibold">
                  Supplier/buyer details
                </h1> */}
                      <div class="text-[#11192a] text-sm font-semibold ">
                        Line Items
                      </div>
                      <div class="text-[#11192a] text-lg font-light  mr-6">
                        5 Total Items{" "}
                      </div>
                    </header>

                    <div className=" w-full overflow-x-auto mt-3 pb-4">
                      <table className=" w-full overflow-x-auto">
                        <thead className="bg-subtleGray w-full p-6   ">
                          <tr className=" bg-unusualGray  px-6 w-full ">
                            {/* <td className="text-[#11192A] p-2.5 text-xs font-bold">
                          Item Number
                        </td> */}
                            <td class="text-[#11192a] text-xs font-semibold  p-4  ">
                              Item{" "}
                            </td>
                            <td class="text-[#11192a] text-xs font-semibold  p-4">
                              Description{" "}
                            </td>
                            <td class="text-[#11192a] text-xs font-semibold  p-4">
                              Unit Price
                            </td>
                            <td class="text-[#11192a] text-xs font-semibold  p-4">
                              Quantity
                            </td>
                            <td class="text-[#11192a] text-xs font-semibold  p-4">
                              Amount
                              <div> (before tax)</div>
                            </td>
                            <td class="text-[#11192a] text-xs font-semibold  p-4">
                              Tax Rate
                            </td>
                            <td class="text-[#11192a] text-xs font-semibold  p-4">
                              Tax Amount
                            </td>
                            <td class="text-[#11192a] text-xs font-semibold  p-4">
                              Total Price
                            </td>
                          </tr>
                        </thead>

                        <tbody className="  ">
                          {dummyData.map((cell, idx) => {
                            return (
                              <tr key={idx} className=" w-full ">
                                <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 ">
                                  {cell.item}
                                </td>
                                <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                                  {cell.description}
                                </td>
                                <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                                  {cell.unitPrice}
                                </td>
                                <td className="text-[#11192a] text-xs font-semibold mt-2 text-center">
                                  {cell.Quantity}
                                </td>
                                <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                                  {cell.amount}
                                </td>
                                <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                                  {cell.taxRate}
                                </td>
                                <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                                  {cell.taxAmount}
                                </td>
                                <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                                  {cell.totalPrice}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    {/* Calculate cost and price  shaded or gray section */}
                  </div>

                  <div className=" px-12   py-5 bg-neutral-50 flex-col justify-center items-start  inline-flex bg-[#fafafa] w-full border-t-[0.2px]  border-t-[#b1a6b2]">
                    <div className="lg:flex md:flex justify-between  w-full sm:block ">
                      {/* First left side */}
                      <div>
                        <section>
                          {" "}
                          <div class="text-[#222222] text-lg  font-medium border-b-[0.2px]  border-[#b1a6b2]">
                            Receiving Funding Account Information
                          </div>
                          <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                            74*******512
                          </div>
                          <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                            Praise Akinlade
                          </div>
                        </section>
                      </div>

                      {/* second division */}

                      <div>
                        {" "}
                        <div class=" flex-col justify-start items-start inline-flex lg:min-w-[380px]">
                          <section className=" w-full px-6 pb-2 border-b-[1px] border-black">
                            <div className=" flex w-full justify-between">
                              <div class="text-[#222222] text-xs font-semibold ">
                                Subtotal
                              </div>
                              <div class=" text-[#222222] text-xs font-semibold ">
                                10,129,892
                              </div>
                            </div>
                            <div className=" flex w-full justify-between pt-3 pb-1 border-b-[0.2px] border-b-[#b1a6b2]/30">
                              <div class="text-[#222222]  text-xs font-medium flex  space-x-9 ">
                                <span>VAT %</span>
                                <span>5.6%</span>
                              </div>
                              <div class="text-[#222222] text-xs font-medium ">
                                573,960
                              </div>
                            </div>

                            <div className=" flex w-full justify-between pt-3 pb-1 border-b-[0.2px] border-b-[#b1a6b2]/30">
                              <div class="text-[#222222]  text-xs font-medium flex  space-x-9 ">
                                <span>Discount %</span>
                                <span>2.4%</span>
                              </div>
                              <div class="text-[#222222] text-xs font-medium ">
                                237,088
                              </div>
                            </div>
                          </section>
                          <div className=" flex w-full justify-between py-2 px-6 border-b-[1px] border-b-black">
                            <div class="text-[#222222] text-lg font-semibold ">
                              Grand Total
                            </div>
                            <div class="text-[#222222] text-lg font-semibold ">
                              10,473,890
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* stops */}
                    </div>
                  </div>
                </section>
              </TransitionScale>
            </motion.section>
          </main>
        </TransitionScale>
      </DashboardLayout>
    </>
  );
}

export default ApprovedFunderEarlyPaymentBulkInnerDetailsPage;
