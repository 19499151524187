import React, { useState } from "react";
import Header from "../Header";
// import SearchInput from "../SearchInput";
import AgreementTable from "./TableAgreement";
import NewAgreement from "./NewAgreement/NewAgreement";
import AgreementDetails from "./AgreementDetails/AgreementDetails/AgreementDetails";
import CreateNewAgreementPage from "./CreateNewAgreement/CreateNewAgreementPage";
import PendingDeletionDetails from "./PendingDeletionDetails/PendingDeletionDetails/PendingDeletionDetails";
// eslint-disable-next-line
import TemplateDetails from "./AgreementDetails/AgreementDetails/TemplateDetails";

const Agreement = () => {
  const [showNewAgreement, setShowNewAgreement] = useState(false);
  const [showAgreementDetails, setShowAgreementDetails] = useState(false);
  const [selectedAgreement, setSelectedAgreement] = useState(null);
  const [searchQuery, ] = useState("");
  const [createNewAgreement, setCreateNewAgreement]= useState(false)
  const [pendingDeletion, setPendingDeletion]= useState(false)
  // eslint-disable-next-line
  const[isopenTemplateDetails, setIsOpenTemplateDetails]= useState(false)
  // eslint-disable-next-line
  const [turnTemplate, setTurnTemnplate]= useState(false)

  // const handleSearch = (query) => {
  //   setSearchQuery(query);
  // };

  const handleCreateNewAgreement = () => {
    setShowNewAgreement(true);
    setShowAgreementDetails(false);
    setSelectedAgreement(null);
  };

  const handleShowAgreementDetails = (agreement) => {
    setSelectedAgreement(agreement);
    setShowAgreementDetails(true);
    setShowNewAgreement(false);
  };
  const handlePendingDeletion=()=>{
    setPendingDeletion(true)
  }

  function handleBack() {
    setShowNewAgreement(false);
    setShowAgreementDetails(false);
    setSelectedAgreement(null);
  }
  const makeCreatNewAgreement =()=>{
    setCreateNewAgreement(true)

  }
  const openTemplateDetails = ()=>{
    setIsOpenTemplateDetails(true)

  }
//   const openTurnTemplateDetails=()=>{
// setTurnTemnplate(true)
//   }

  return (
    <div>
      <div className="px7">
        {showNewAgreement || turnTemplate? (
          <NewAgreement goBack={handleBack} />
        ) : showAgreementDetails ? (
          <AgreementDetails agreement={selectedAgreement} goBack={handleBack} />
        ): pendingDeletion ? (
          <PendingDeletionDetails pendingDeletion={selectedAgreement} goBack={handleBack} />
        ):createNewAgreement?(<><CreateNewAgreementPage/></>): (
          <>
            <Header Title="Agreement" showSearchInput={false} />
            {/* <div className="flex items-center justify-between gap-3 px-7">
              <button
                onClick={handleCreateNewAgreement}
                className="text-white text-xs p-2 font-inter textbase font-normal leading-normal rounded-md border border-[#CCC] bg-[#FFBE55]"
              >
                Create new agreement
              </button>
              <div className="ml-5">
                <SearchInput onSearch={handleSearch} />
              </div>
            </div> */}
            <AgreementTable
              onAgreementClick={handleShowAgreementDetails}
              searchQuery={searchQuery}
              goToUpload={handleCreateNewAgreement}
              setShowDetails={ handleShowAgreementDetails}
              createNewAgreement={makeCreatNewAgreement}
              pendingDeletion={handlePendingDeletion}
              templateDetails={openTemplateDetails}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Agreement;
