import React, { useEffect } from "react";
import DashboardLayout from "layout/dashboardlayout";
import { PAIconArrowLeftGreen } from "assets/images/svgs";
import { useNavigate } from "react-router-dom";

import TransitionScale from "shared/animation/TransitionScale";
import { useParams } from "react-router-dom";
import { fetchSinglePendingRequestById } from "appstate/workflow/workflowSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const SupplierSubmitForApproval = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  const dispatch = useDispatch();
  const { workflowId } = useParams();

  const { singleDetailPendingRequest } = useSelector((state) => state.workflow);
  useEffect(() => {
    dispatch(fetchSinglePendingRequestById({ id: workflowId }));
  }, [dispatch, workflowId]);

  const vatPercent = Number(
    (Number(singleDetailPendingRequest?.data?.payload?.vat) / 100) *
      Number(singleDetailPendingRequest?.data?.payload?.subTotal)
  );
  const discountPercent = Number(
    (Number(singleDetailPendingRequest?.data?.payload?.discount) / 100) *
      Number(singleDetailPendingRequest?.data?.payload?.subTotal)
  );

  return (
    <>
      <DashboardLayout
        section="WorkFlow "
        subHeader="Manage all your workflow approvals"
      >
        <header className=" text-primaryColor  pl-5 ">
          <span
            onClick={() => handleBack()}
            className="flex cursor-pointer  w-fit font-light items-center text-sm gap-2"
          >
            <PAIconArrowLeftGreen />
            Go Back
          </span>
        </header>

        <TransitionScale className="px-6 pt-6 pb-8 bg-white  rounded-[10px] mt-5 ">
          <div className="lg:flex md:flex justify-between  w-full sm:block  ">
            <div>
              <div class="text-[#11192a] text-xs font-bold ">
                Approval Stage
              </div>

              <div className="">
                {singleDetailPendingRequest?.data?.approvalStage ===
                "0 of 1" ? (
                  <div className="mb-[15px] mt-[10px] flex justify-center items-center gap-x-[15px] ">
                    <div>
                      <div className="w-[100px] h-1   bg-[#5c89ff]/30    rounded-[100px] lg:w-[150px]  xs:w-[70px]"></div>
                    </div>
                    <div>
                      <div className="w-[100px] h-1   bg-[#5c89ff]/30 rounded-[100px] lg:w-[150px]  xs:w-[70px]"></div>
                    </div>
                    <div>
                      <div className="w-[100px] h-1 bg-[#5c89ff]/30  rounded-[100px] lg:w-[150px] xs:w-[70px] "></div>
                    </div>
                  </div>
                ) : (
                  <div className="mb-[15px] mt-[10px] flex justify-center items-center gap-x-[15px] ">
                    <div>
                      <div className="w-[100px] h-1   bg-[#5c89ff]   rounded-[100px] lg:w-[150px]  xs:w-[70px]"></div>
                    </div>
                    <div>
                      <div className="w-[100px] h-1   bg-[#5c89ff] rounded-[100px] lg:w-[150px]  xs:w-[70px]"></div>
                    </div>
                    <div>
                      <div className="w-[100px] h-1 bg-[#5c89ff] rounded-[100px] lg:w-[150px] xs:w-[70px] "></div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* <div>
                {" "}
                <div class="h-[30px] px-6 py-2 bg-[#de1515]/30 rounded-[5px] justify-center items-center gap-2.5 inline-flex">
                  <div class="text-center text-[#de1515] text-xs font-semibold">
                    Declined
                  </div>
                </div>
              </div> */}

            <div className=" flex gap-3">
              {/* <button
                  className={` w-24   py-2 px-3  text-[#222222] justify-center  flex items-center bg-[#D6D6D6] rounded-[5px]  text-sm ${"cursor-pointer"}`}
                  // onClick={() => setOngoingCancelBidModal(true)}
                  //   onClick={() => setOngoingCancelBidModal2(true)}
                  // disabled={termsIsVisible ? false : true}
                >
                  Confirm
                </button> */}
              <div class="h-[37px] p-2.5 rounded-[5px] border border-[#5c8aff]/30 justify-center items-center gap-2.5 inline-flex">
                <div class="text-[#5c89ff] text-sm font-medium ">
                  {singleDetailPendingRequest?.data?.payload?.status &&
                    singleDetailPendingRequest?.data?.payload?.status}
                </div>
              </div>
            </div>
          </div>

          <div className="lg:flex md:flex justify-between  w-full sm:block  ">
            {/* underlined div */}

            {/* Button division */}

            <div>
              {" "}
              {/* <div class="h-[30px] px-6 py-2 bg-[#07593d]/30 rounded-[5px] justify-center items-center gap-2.5 inline-flex">
                  <div class="text-center text-[#07593d] text-xs font-semibold">
                    Completed
                  </div>
                </div> */}
            </div>
          </div>

          {/* Second Box phase and pages */}
          <div className="lg:flex md:flex justify-between  w-full sm:block  mt-6 ">
            <div>
              <div class=" flex-col justify-center items-start gap-2 inline-flex">
                <div class="self-stretch justify-between items-center inline-flex gap-28">
                  <div class="text-[#222222] text-[14px] font-semibold">
                    Date Sent:
                  </div>
                  <div class="text-[#7a7a7a] text-[14px] font-medium ">
                    {singleDetailPendingRequest?.data?.dateSent}
                  </div>
                </div>
                <div class="self-stretch justify-between items-center inline-flex gap-28">
                  <div class="text-[#222222] text-[14px] font-semibold">
                    Transaction Type:
                  </div>
                  <div class="text-[#7a7a7a] text-[14px] font-medium ">
                    {singleDetailPendingRequest?.data?.type}
                  </div>
                </div>
                <div class="self-stretch justify-between items-center inline-flex  gap-28">
                  <div class="text-[#222222] text-[14px] font-semibold">
                    Action:
                  </div>
                  <div class="text-[#7a7a7a] text-[14px] font-medium ">
                    {singleDetailPendingRequest?.data?.action}
                  </div>
                </div>
                <div class="self-stretch justify-between items-center inline-flex">
                  <div class="text-[#222222] text-[14px] font-semibold">
                    Approval Stage:
                  </div>
                  <div class="text-[#7a7a7a] text-[14px] font-medium ">
                    {singleDetailPendingRequest?.data?.approvalStage}
                  </div>
                </div>
                <div class="self-stretch justify-between items-center inline-flex">
                  <div class="text-[#222222] text-[14px] font-semibold">
                    Days in Queue:
                  </div>
                  <div class="text-[#7a7a7a] text-[14px] font-medium ">
                    {singleDetailPendingRequest?.data?.daysInQueue}
                  </div>
                </div>
              </div>
            </div>

            {/* Second side */}

            <div>
              <div class=" flex-col justify-center items-start gap-2 inline-flex">
                <div class="self-stretch justify-between items-center inline-flex gap-28">
                  <div class="text-[#222222] text-[14px] font-semibold">
                    Sender:
                  </div>
                  <div class="text-[#7a7a7a] text-[14px] font-medium ">
                    {singleDetailPendingRequest?.data?.sender}
                  </div>
                </div>
                <div class="self-stretch justify-between items-center inline-flex gap-28">
                  <div class="text-[#222222] text-[14px] font-semibold">
                    Previous Approver:
                  </div>
                  <div class="text-[#7a7a7a] text-[14px] font-medium ">
                    {singleDetailPendingRequest?.data?.previousApprover}
                  </div>
                </div>
                <div class="self-stretch justify-between items-center inline-flex  gap-28">
                  <div class="text-[#222222] text-[14px] font-semibold">
                    Next Approver:
                  </div>
                  <div class="text-[#7a7a7a] text-[14px] font-medium ">
                    {singleDetailPendingRequest?.data?.nextApprover}
                  </div>
                </div>
              </div>
              {/* Button */}
            </div>
          </div>
        </TransitionScale>

        <div className="grid lg:grid-cols-[60%,40%] gap-5">
          <TransitionScale className=" pb-5 bg-white  rounded-[10px] mt-5 pt-2">
            {/* Header with Left Padding */}

            {/* Other sections */}

            <section className="  mt-3">
              {/* Down Flex Texts */}
              <div className=" flex  justify-between items-center w-full px-6">
                <div class=" justify-between items-center inline-flex mt-2">
                  <div class="text-[#222222] text-base font-semibold  ">
                    Reference No:
                  </div>
                  <div class="text-[#222222] text-base font-semibold  ">
                    |{" "}
                    {singleDetailPendingRequest?.data?.payload
                      ?.invoiceReference &&
                      singleDetailPendingRequest?.data?.payload
                        ?.invoiceReference}
                  </div>
                </div>

                <div class="h-[37px] p-2.5 rounded-[5px] border border-[#5c8aff]/30 justify-center items-center gap-2.5 inline-flex">
                  <div class="text-[#5c89ff] text-sm font-medium ">
                    {singleDetailPendingRequest?.data?.payload?.status &&
                      singleDetailPendingRequest?.data?.payload?.status}
                  </div>
                </div>
              </div>{" "}
              <div className=" flex justify-between px-6 ">
                {/* first stage */}

                <div class=" lg:w-[240px] sm:w-full h-[117px] flex-col justify-start items-start gap-3 inline-flex ">
                  <div class="self-stretch  flex-col justify-center items-start gap-1 flex mt-4">
                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Invoice Number
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        {singleDetailPendingRequest?.data?.payload
                          ?.invoiceNumber &&
                          singleDetailPendingRequest?.data?.payload
                            ?.invoiceNumber}
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex ">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Created Date:{" "}
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        {singleDetailPendingRequest?.data?.payload?.createdDate}
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Submitted Date:{" "}
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        {singleDetailPendingRequest?.data?.payload
                          ?.submittedDate &&
                          singleDetailPendingRequest?.data?.payload
                            ?.submittedDate}
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Approved Date:{" "}
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        {singleDetailPendingRequest?.data?.payload
                          ?.approvedDate &&
                          singleDetailPendingRequest?.data?.payload
                            ?.approvedDate}
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Due Date:{" "}
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        {singleDetailPendingRequest?.data?.payload?.dueDate &&
                          singleDetailPendingRequest?.data?.payload?.dueDate}
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Issuance Date:{" "}
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        {singleDetailPendingRequest?.data?.payload
                          ?.issuanceDate &&
                          singleDetailPendingRequest?.data?.payload
                            ?.issuanceDate}
                      </div>
                    </div>
                  </div>
                  {/* stop */}
                </div>
                {/* second stage */}
                <div className="flex justify-between items-center mt-7 ">
                  {" "}
                  {singleDetailPendingRequest?.data?.payload?.requestAmount && (
                    <div class="w-[107px] flex-col justify-end items-end gap-1 inline-flex">
                      <div class="text-[#979797] text-xs font-normal ">
                        singleDetailPendingRequest?.data?.payload?.requestAmount
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* stops here */}
              <div className="  px-6 mt-16">
                <header className="border-b-[5px] border-b-[#F6F5FA] mt-3  flex justify-between py-1">
                  {/* <h1 className="text-[18px] font-semibold">
                  Supplier/buyer details
                </h1> */}
                  <div>
                    <div class="text-[#222222] text-lg font-semibold ">
                      {singleDetailPendingRequest?.data?.payload?.buyerName}
                    </div>
                    <div class="text-[#7a7a7a] text-xs font-light  ">
                      Buyer details
                    </div>
                  </div>

                  <div class="h-[41px] flex-col justify-start items-start  inline-flex px-12">
                    <div class="text-[#222222] text-base font-semibold ">
                      {singleDetailPendingRequest?.data?.payload?.paymentTerms}
                      <span> Days</span>{" "}
                    </div>
                    <div class="w-[100px] text-[#7a7a7a] text-xs font-light  mt-1">
                      Payment terms
                    </div>
                  </div>

                  <div class="h-[43px] flex-col justify-start items-end  inline-flex">
                    <div class="text-[#222222] text-lg font-semibold ">
                      {" "}
                      {singleDetailPendingRequest?.data?.payload?.currency}
                    </div>
                    <div class="text-[#7a7a7a] text-xs font-semibold mt-1">
                      Currency
                    </div>
                  </div>
                </header>

                <header className="border-b-[5px] border-b-[#F6F5FA] mt-6  flex justify-between py-1">
                  {/* <h1 className="text-[18px] font-semibold">
                  Supplier/buyer details
                </h1> */}
                  <div class="text-[#11192a] text-sm font-semibold ">
                    Line Items
                  </div>
                  <div class="text-[#11192a] text-lg font-light  mr-6">
                    {singleDetailPendingRequest?.data?.payload?.items &&
                      singleDetailPendingRequest?.data?.payload?.items
                        ?.length}{" "}
                    Total Items
                  </div>
                </header>

                <div className=" w-full overflow-x-auto mt-3 pb-4">
                  <table className=" w-full overflow-x-auto">
                    <thead className="bg-subtleGray w-full p-6   ">
                      <tr className=" bg-unusualGray  px-6 w-full ">
                        {/* <td className="text-[#11192A] p-2.5 text-xs font-bold">
                          Item Number
                        </td> */}
                        <td class="text-[#11192a] text-xs font-semibold  p-4  ">
                          Item{" "}
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4">
                          Description{" "}
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4">
                          Unit Price
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4">
                          Quantity
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4">
                          Amount
                          <div> (before tax)</div>
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4">
                          Tax Rate
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4">
                          Tax Amount
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4">
                          Total Price
                        </td>
                      </tr>
                    </thead>

                    <tbody className="  ">
                      {singleDetailPendingRequest?.data?.payload?.items
                        ?.length > 0 &&
                        singleDetailPendingRequest?.data?.payload?.items?.map(
                          (cell, idx) => {
                            return (
                              <tr key={idx} className=" w-full ">
                                <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 ">
                                  {cell.itemName}
                                </td>
                                <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                                  {cell.description}
                                </td>
                                <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                                  {cell.unitPrice}
                                </td>
                                <td className="text-[#11192a] text-xs font-semibold mt-2 text-center">
                                  {cell.quantity}
                                </td>
                                <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                                  {cell.amount}
                                </td>
                                <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                                  {cell.taxRate}
                                </td>
                                <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                                  {cell.taxAmount}
                                </td>
                                <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                                  {cell.totalPrice}
                                </td>
                              </tr>
                            );
                          }
                        )}
                    </tbody>
                  </table>
                </div>
                {/* Calculate cost and price  shaded or gray section */}
              </div>
              <div className=" px-6   py-5 bg-neutral-50 flex-col justify-center items-start  inline-flex bg-[#fafafa] w-full border-t-[0.2px]  border-t-[#b1a6b2]">
                <div className="lg:flex md:flex justify-between  w-full sm:block ">
                  {/* First left side */}
                  <div>
                    <section>
                      {" "}
                      <div class="text-[#222222] text-lg  font-medium border-b-[0.2px]  border-[#b1a6b2]">
                        Receiving Funding Account Information
                      </div>
                      {/* <div class="text-[#222222] text-lg font-semibold ">
                          Receiving Funding Account Information
                        </div> */}
                      <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                        {singleDetailPendingRequest?.data?.payload
                          ?.fundingAccount?.accountNumber &&
                          singleDetailPendingRequest?.data?.payload
                            ?.fundingAccount?.accountNumber}
                      </div>
                      <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                        {singleDetailPendingRequest?.data?.payload
                          ?.fundingAccount?.accountName &&
                          singleDetailPendingRequest?.data?.payload
                            ?.fundingAccount?.accountName}
                      </div>
                    </section>
                  </div>

                  {/* second division */}

                  <div>
                    {" "}
                    <div class=" flex-col justify-start items-start inline-flex lg:min-w-[290px]">
                      <section className=" w-full px-6 pb-2 border-b-[1px] border-black">
                        <div className=" flex w-full justify-between">
                          <div class="text-[#222222] text-xs font-semibold ">
                            Subtotal
                          </div>
                          <div class=" text-[#222222] text-xs font-semibold ">
                            {singleDetailPendingRequest?.data?.payload
                              ?.subTotal &&
                              singleDetailPendingRequest?.data?.payload
                                ?.subTotal}
                          </div>
                        </div>
                        <div className=" flex w-full justify-between pt-3 pb-1 border-b-[0.2px] border-b-[#b1a6b2]/30">
                          <div class="text-[#222222]  text-xs font-medium flex  space-x-9 ">
                            <span>VAT %</span>
                            <span>
                              {singleDetailPendingRequest?.data?.payload?.vat &&
                                singleDetailPendingRequest?.data?.payload?.vat}
                              %
                            </span>
                          </div>
                          <div class="text-[#222222] text-xs font-medium ">
                            {vatPercent}
                          </div>
                        </div>

                        <div className=" flex w-full justify-between pt-3 pb-1 border-b-[0.2px] border-b-[#b1a6b2]/30">
                          <div class="text-[#222222]  text-xs font-medium flex  space-x-9 ">
                            <span>Discount %</span>
                            <span>
                              {" "}
                              {singleDetailPendingRequest?.data?.payload
                                ?.discount &&
                                singleDetailPendingRequest?.data?.payload
                                  ?.discount}
                              %
                            </span>
                          </div>
                          <div class="text-[#222222] text-xs font-medium ">
                            {discountPercent}
                          </div>
                        </div>
                      </section>
                      <div className=" flex w-full justify-between py-2 px-6 border-b-[1px] border-b-black">
                        <div class="text-[#222222] text-lg font-semibold ">
                          Grand Total
                        </div>
                        <div class="text-[#222222] text-lg font-semibold ">
                          {singleDetailPendingRequest?.data?.payload
                            ?.grandTotal &&
                            singleDetailPendingRequest?.data?.payload
                              ?.grandTotal}
                        </div>
                      </div>
                      {/* <div className=" flex w-full justify-between pt-3 pb-1  border-b-[1px] border-black">
                          <div class="text-[#222222] text-lg  font-medium  ">
                            Grand Total
                          </div>
                          <div class="text-[#222222] text-lg  font-medium ">
                            5,263,200.00
                          </div>
                        </div> */}
                    </div>
                  </div>

                  {/* stops */}
                </div>
              </div>
            </section>
          </TransitionScale>
          {/* stops the best here */}

          <div className="bg-white rounded-[10px] gap-8 mt-5 ">
            {/* <FundDetailSide /> */}
            <div class="w-full justify-start items-center gap-4 inline-flex ">
              <div class="border-b  border-[#c2c2c2]/30 justify-start items-center gap-2.5 flex w-full px-6  py-3">
                <div class="text-[#07593d] text-base font-bold ">
                  {singleDetailPendingRequest?.data?.action} details
                </div>
              </div>
            </div>

            <div>
              {" "}
              {/* second */}
              <div className="   pb-4">
                {/* 2nd */}
                <div class=" justify-between  flex flex-row    border-b border-[#c2c2c2]/30 px-6  py-3">
                  <div class="py-2.5 flex-col justify-start items-start gap-2 inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-[#949494] text-sm font-medium ">
                        Status
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-[#f08000] text-base font-medium">
                        {singleDetailPendingRequest?.data?.payload?.status &&
                          singleDetailPendingRequest?.data?.payload?.status}
                      </div>
                    </div>
                  </div>
                  <div class="py-2.5 flex-col justify-start items-start gap-2 inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-[#949494] text-sm font-medium ">
                        Discount rate
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-base font-medium ">
                        {" "}
                        {singleDetailPendingRequest?.data?.payload?.discount &&
                          singleDetailPendingRequest?.data?.payload?.discount}
                        %
                      </div>
                    </div>
                  </div>
                </div>{" "}
                {/* another header */}
                <div class="w-full justify-start items-center gap-4 inline-flex my-4 ">
                  <div class=" justify-start items-center gap-2.5 flex w-full px-6  py-2">
                    <div class="text-black text-sm font-bold ">
                      Buyer’s Personal Information
                    </div>
                  </div>
                </div>
                {/* header ends here */}
                <div class=" justify-between  flex flex-row    px-6  ">
                  <div class="py-2.5 flex-col justify-start items-start gap-2 inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-[#949494] text-sm font-medium ">
                        Buyer’s name
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-[#222222] text-base font-normal ">
                        {singleDetailPendingRequest?.data?.payload?.buyerName &&
                          singleDetailPendingRequest?.data?.payload?.buyerName}
                      </div>
                    </div>
                  </div>

                  {/* stops */}
                </div>
                <div class=" justify-between  flex flex-row    px-6  ">
                  <div class="py-2.5 flex-col justify-start items-start gap-2 inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-[#949494] text-sm font-medium ">
                        Email
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-[#222222] text-base font-normal ">
                        {singleDetailPendingRequest?.data?.payload
                          ?.buyerEmail &&
                          singleDetailPendingRequest?.data?.payload?.buyerEmail}
                      </div>
                    </div>
                  </div>
                  <div class="py-2.5 flex-col justify-start items-start gap-2 inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-[#949494] text-sm font-medium ">
                        Contact number
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-base font-normal ">
                        {singleDetailPendingRequest?.data?.payload
                          ?.buyerCompanyPhoneNumber &&
                          singleDetailPendingRequest?.data?.payload
                            ?.buyerCompanyPhoneNumber}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* stops here */}
              {/* Third */}
              {/* third stops */}
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};

export default SupplierSubmitForApproval;
