import { PAIconInventoryArrowLeft } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import Table from "layout/table/Table";
import { useLocation, useNavigate } from "react-router-dom";
import TableIcons from "../../../assets/images/svgs/TableIcons.svg";
import { useEffect, useState } from "react";
import { getAllApprovedInvoices } from "appstate/api/invoice/invoice";
import toast from "react-hot-toast";
import Loading from "shared/Loading";
import Pagination from "shared/Pagination";

const DirectFundingInvoiceDetailsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userId, companyName } = location.state || {};
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const [total, setTotal] = useState("");
  const [approvedInvoices, setApprovedInvoices] = useState([]);

  const size = 10;

  function handleNavigate() {
    navigate(-1);
  }

  const GetAllApprovedInvoices = async () => {
    setLoading(true);
    try {
      const result = await getAllApprovedInvoices({
        page,
        size,
        status: "APPROVED",
        search: "",
      });
      setTotal(result?.meta?.totalElements);
      setApprovedInvoices(result?.data || []);
    } catch (error) {
      console.error("Error fetching approved invoices:", error);
      toast.error("Failed to fetch approved invoices!");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    GetAllApprovedInvoices();
    // eslint-disable-next-line
  }, [page, size]);

  const onRowClicked = (row) => {
    let path = `/directfunding-invoice-details/${row?.id}`;
    navigate(path, {
      state: {
        directFunding: true,
        userId: userId,
        invoiceAmount: row?.invoicedAmount,
        invoiceId: row?.id,
        dueDate: row?.dueDate,
        currency: row?.currency,
        companyName: companyName, 
      },
    });
  };
  
  const HeaderWithIcon = ({ icon, text }) => (
    <div className="flex items-center">
      <img src={icon} alt="" className="mr-2" />
      <span>{text}</span>
    </div>
  );

  const column = [
    {
      name: <HeaderWithIcon icon={TableIcons} text="Invoice Reference" />,
      selector: (row) => row?.invoiceRef,
      width: "15rem",
    },
    {
      name: <HeaderWithIcon icon={TableIcons} text="Buyer" />,
      selector: (row) => row?.buyer,
      width: "15rem",
    },
    {
      name: "Invoice Date",
      selector: (row) => row?.invoiceDate,
    },
    {
      name: "Due Date",
      selector: (row) => row?.dueDate,
    },
    {
      name: "Currency",
      selector: (row) => row?.currency,
    },
    {
      name: "Invoice Amount",
      selector: (row) => row?.invoicedAmount,
    },
    {
      name: "Status",
      selector: (row) => (
        <span
          style={{
            color: row.status === "APPROVED" ? "#2EA923" : "black",
          }}
        >
          {row?.status}
        </span>
      ),
    },
  ];

  const totalPages = total;

  return (
    <DashboardLayout
      section="Invoice"
      subHeader="Here is an overview of your invoices"
    >
      <header className="bg-alabasterHeader p-[24px] rounded-t-[10px]">
        <div
          onClick={handleNavigate}
          className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
        >
          <PAIconInventoryArrowLeft />
          <p className="mt-[2px] text-sm font-normal text-lightGray">Go Back</p>
        </div>
        <div className="flex justify-between flex-wrap ">
          <div>
            <p className="text-black text-sm font-medium">
             {approvedInvoices?.length} Approved Invoices
            </p>
            <p className="text-textColor text-xs font-normal">
              Available for payment
            </p>
          </div>
        </div>
      </header>
      <div className="p-[24px] bg-white rounded-b-[10px]">
      {loading ? (
          <div className="flex justify-center">
            <Loading />
          </div>
        ) : (
          <Table
            columns={column}
            pointer
            data={approvedInvoices}
            onRowClicked={onRowClicked}
          />
        )}
      </div>
      <br />
      <div className="flex justify-center gap-x-[8px]  mt-12">
        {!loading && totalPages > 10 && (
          <Pagination
            page={page}
            startPage={startPage}
            setEndPage={setEndPage}
            endPage={endPage}
            setStartPage={setStartPage}
            setPage={setPage}
            totalPages={totalPages}
          />
        )}
      </div>
    </DashboardLayout>
  );
};

export default DirectFundingInvoiceDetailsPage;
