import {
  PAIconArrowLeft,
  PAIconArrowRight,
  PAIconUnTickedRadio,
  PAIconTickedRadio,
} from "assets/images/svgs";
import OnboardingLayout from "features/onboarding/category/components/OnboardingLayout";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { useDispatch } from "react-redux";
import { funderDataById } from "appstate/auth/authSlice";
// import { useEffect } from "react";

const FunderGetStarted = () => {
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();

  const handleSkip = () => {
    dispatch(funderDataById({ funderId: keycloak?.tokenParsed?.sub })).then(
      () => {
        navigate("/dashboard");
      }
    );
  };

  const handleBankNavigate = () => {
    let path = "/funder/banking-details";
    navigate(path);
  };

  const goback = () => {
    let path = -1;
    navigate(path);
  };

  const getStatedDatas = [
    {
      id: 1,
      title: "Enter business account detail  for verification",
      info: "Provide a valid bank account to receive payment.",
      settings: "Submit account details",
      click: () => {
        handleBankNavigate();
      },
    },
    {
      id: 2,
      title: "Review and accept our funding agreement ",
      info: "Please review and sign the funding agreement",
      settings: "Review funding agreement",
      click: () => {
        handleBankNavigate();
      },
    },
    {
      id: 3,
      title: "Complete KYC",
      info: "Upload the required document for verification ",
      settings: "Upload documents",
    },
  ];
  return (
    <OnboardingLayout>
      <div className="pt-[30px] px-[20px] h-[100%] overflow-auto pb-[30px] md:px-[60px] lg:px-[50px]">
        <PAIconArrowLeft className="cursor-pointer" onClick={goback} />
        <div className="mb-[30px] mt-[30px] flex justify-center items-center gap-x-[20px]">
          <div>
            <div className="w-[100px] h-[2px] bg-[#F08000] rounded-[100px] md:w-[220px]"></div>
          </div>
          <div>
            <div className="w-[100px] h-[2px]  bg-primaryColor rounded-[100px] md:w-[220px]"></div>
          </div>
          <div className="relative bottom-[13px] md:bottom-[15px]">
            <p className="font-normal text-xs text-textColor mb-[10px] md:text-sm">
              Get started
            </p>
            <div className="w-[100px] h-[2px] bg-primaryColor rounded-[100px] md:w-[220px]"></div>
          </div>
        </div>
        <div className="flex flex-col items-center">
          <div class=" text-[#222222] text-2xl font-semibold ">
            Let’s get started
          </div>
          <div className=" pb-[20px] rounded-[5px] border border-gray500 md:w-[722px]">
            <div className="h-[47px] flex items-center pl-[30px] bg-gray500">
              <p className="font-semibold text-sm text-primaryColor">
                Complete Setup
              </p>
            </div>
            <div className="h-[47px]  flex items-center pl-[30px] border-b border-gray500">
              <p className="font-semibold text-sm text-textColor">
                Before you start using Payedge, please complete the following
                steps.
              </p>
            </div>
            <div className="px-[30px]">
              {getStatedDatas.map((data) => (
                <div
                  key={data?.id}
                  className={`pt-[30px] md:flex justify-between pb-[20px] ${
                    data.id !== 4 && "border-b"
                  } border-[#86888B]`}
                >
                  <div className="flex flex-row">
                    {/* Icon side */}
                    <div>
                      {data.title ===
                      "Enter business account detail  for verification" ? (
                        <PAIconTickedRadio />
                      ) : (
                        <PAIconUnTickedRadio />
                      )}
                    </div>
                    {/* MIDDLE TEXT SECTION */}
                    <div className=" ml-3">
                      <p className="font-semibold text-xs mb-[10px]">
                        {data.title}
                      </p>
                      <p className="font-normal text-sm md:w-[310px]">
                        {data.info}
                      </p>
                    </div>
                  </div>
                  <div
                    onClick={data?.click}
                    className="w-[179px] h-[31px] bg-[#F0EFF1] rounded-[5px] flex items-center cursor-pointer mt-[20px]"
                  >
                    <p className="font-medium text-[10px] w-[160px] pl-[5px]">
                      {data?.settings}
                    </p>
                    <PAIconArrowRight />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div
            onClick={handleSkip}
            className="underline text-primaryColor font-medium text-xs flex gap-x-[6.33px] items-center mt-[33px] hover:cursor-pointer"
          >
            Continue to Dashboard
            <PAIconArrowRight className="w-[16px]" />
          </div>
        </div>
      </div>
    </OnboardingLayout>
  );
};

export default FunderGetStarted;
