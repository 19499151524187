import React, { useState } from "react";
import DashboardLayout from "layout/dashboardlayout";
import { PAIconArrowLeftGreen, PAIconOutlineSearch } from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import PendingRequests from "./PendingRequests";
import RequestsHistory from "./RequestHistory";

const WorkFlowPendingRequests = () => {
  const [activeTab, setActiveTab] = useState("pendingrequests");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/work-flow-management/");
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "pendingrequests":
        return <PendingRequests />;
      case "requestshistory":
        return <RequestsHistory />;

      default:
        return null;
    }
  };

  return (
    <DashboardLayout
      section="Workflow "
      subHeader="Manage all your workflow approvals"
    >
      <div className=" lg:min-h-screen">
        <header className="bg-white text-primaryColor px-6  rounded-[5px] ">
          <span
            onClick={() => handleBack()}
            className="flex cursor-pointer py-4 w-fit font-light items-center text-sm gap-2"
          >
            <PAIconArrowLeftGreen />
            Go Back
          </span>
        </header>
        <div>
          <div className="mt-9 mx6 mb-[17px] w-full cursor-pointer rounded-[5px] bg-white py-2">
            <div className=" flex-col items-center justify-between gap-10 p-2 px-4 md:flex md:flex-row">
              <div className="flex-col gap-10 md:flex md:flex-row">
                <div
                  className={
                    activeTab === "pendingrequests"
                      ? "rounded-l-sm rounded-r-sm border-[#07593D] font-bold text-[#07593D]   text-sm md:mb-[-18px] md:border-b-[2.5px]"
                      : "text-[#949494] text-sm  font-normal"
                  }
                  onClick={() => handleTabClick("pendingrequests")}
                >
                  Pending Requests
                </div>
                <div
                  className={
                    activeTab === "requestshistory"
                      ? "rounded-l-sm rounded-r-sm border-[#07593D] font-bold text-[#07593D]   text-sm md:mb-[-18px] md:border-b-[2.5px]"
                      : "text-[#949494] text-sm  font-normal"
                  }
                  onClick={() => handleTabClick("requestshistory")}
                >
                  Request History
                </div>
              </div>
            </div>
          </div>

          {/* search filter section 
          
          Date range
Workflow type 
Approver name (to view the specific role/user currently responsible)*/}

          <div className="my-5 grid md:grid-cols-2 items-center ">
            <div className="gap-3 flex relative">
              <div>
                <select
                  className="border border-[#DEDEDE] shadow-sm rounded-[5px] px-4 py-3 text-sm focus:outline-none cursor-pointer"
                  onChange={(e) => {}}
                >
                  <option value="" disabled selected>
                    Filter by{" "}
                  </option>
                  <option value="date">Date range</option>
                  <option value="name">Workflow type </option>
                  <option value="name">Approver name </option>
                </select>
              </div>

              {activeTab === "requestshistory" && (
                <button className="py-2 px-4 rounded-[5px] bg-white border-[#DEDEDE] shadow-sm mr-2 ">
                  Export
                </button>
              )}
            </div>
            <div className="flex md:col-span-1 justify-end sm:justify-start xs:justify-start sm:w-full sm:block xs:w-full ">
              {/* SAERCH SECTION */}

              <div>
                <div className="flex items-center lg:justify-end ">
                  <div className="border border-transparent md:border-gray md:shadow-sm my-4 rounded-[5px] px-2 lg:w-[300px] bg-white sm:w-full block y-3 min-h-10">
                    <div className="items-center flex bg-white">
                      <PAIconOutlineSearch />
                      <input
                        className="bg-transparent p-2 w-full focus:outline-none text-sm bg-white"
                        type="text"
                        placeholder="Search by sender Date range,  Workflow type or Approver name"
                      />
                    </div>
                    {/* <div className="lg:hidden flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer">
                    <PAIconFilter className="bg-[#2FA06A]" />
                  </div> */}
                  </div>
                </div>
                {/* stop */}
              </div>
            </div>
          </div>

          {renderTabContent()}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default WorkFlowPendingRequests;
