// import { getInvoiceById } from "appstate/api/invoice/invoice";
import {
  allInvoiceById,
  // allSupplierInvoiceData,
  cancelCreatedInvoice,
  // putCreatedInvoice,
  supplierInvoiceApprove,
} from "appstate/invoice/invoiceSlice";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import LoadingScreen from "shared/LoadingScreen";
import CreatedInvoiceDetails from "./createdInvoiceDetails";
import AcceptModal from "shared/AcceptModal";
import SuccessModal from "shared/SuccessModal";
import DeleteSuccessModal from "./deleteSuccessModal";
import SelectAccountModal from "features/supplierEarlyPayment/payment";

const CreatedDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { invoiceIdData, isLoading } = useSelector((state) => state?.invoice);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [isRejectSuccessModal, setIsRejectSuccessModal] = useState(false);
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
  const [isSelectAccountModalOpen, setIsSelectAccountModalOpen] = useState("");
  const [isProceedModalOpen, setIsProceedModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  useEffect(() => {
    dispatch(allInvoiceById({ id }));
  }, [dispatch, id]);

  const selectedAccounts = useSelector(
    (state) => state?.wallet?.selectedAccounts
  );

  if (isLoading) {
    return <LoadingScreen />;
  }

  // Calculate the payment based on the due date and created date
  const calculatePayment = (dueDate, createdDate) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const dueDateObj = new Date(dueDate);
    const createdDateObj = new Date(createdDate?.split(" ")[0]);
    const diffDays = Math.round(
      Math.abs((dueDateObj - createdDateObj) / oneDay)
    );
    return `${diffDays} Days`;
  };

  // FORMAT AMOUNT
  const formatAmount = (amount) => {
    if (typeof amount !== "number") return "0.00";
    return amount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const handleDeleteClick = () => {
    setIsRejectModalOpen(true);
  };

  const handleSubmitClick = () => {
    setIsSubmitModalOpen(true);
  };

  const handleDelete = () => {
    dispatch(cancelCreatedInvoice({ id: id })).then((response) => {
      if (response.type === "cancelCreatedInvoice/fulfilled") {
        setIsRejectModalOpen(false);
        setIsRejectSuccessModal(true);
        handleRedirect();
      }
    });
  };

  // const handleSubmit = () => {
  //   dispatch(putCreatedInvoice({ id: id })).then((response) => {
  //     if (response.type === "putCreatedInvoice/fulfilled") {
  //       setIsSubmitModalOpen(false);
  //     }
  //   });
  // };

  const handleSubmit = () => {
    setIsSubmitModalOpen(false);
    setIsSelectAccountModalOpen(true);
    // setIsSuccessModalOpen(true);
    // setSuccessMessage("thanks");
    // dispatch(acceptOffer({ offerId, invIds: invoiceId })).then((response) => {
    //   if (response?.payload?.status === 200 && response?.payload?.success) {
    //     setIsSuccessModalOpen(true);
    //     setSuccessMessage(response?.payload?.message);
    //   }
    // });
  };

  const handleAccountSubmit = () => {
    setIsSelectAccountModalOpen(false);
    setIsProceedModalOpen(true);
  };

  function handleRedirect() {
    let path = "/invoice/invoice";
    navigate(path);
  }

  const handleProceedSubmit = () => {
    setIsProceedModalOpen(false);

    const approvedBySupplier = {
      invoiceIds: [id],
      virtualAccountId: selectedAccounts?.id,
    };

    dispatch(supplierInvoiceApprove({ body: approvedBySupplier })).then(
      (data) => {
        if (data?.payload?.success) {
          setIsSuccessModalOpen(true);
          setSuccessMessage(data?.payload?.message);
          setTimeout(() => {
            handleRedirect();
          }, 2000);
        }
      }
    );
  };

  function handleEditClick() {
    let path = "/edit-invoice";
    navigate(path, { state: id });
  }

  const data = {
    invoiceNumber: `INV-${invoiceIdData?.data?.invoiceNo}` || "",
    referenceNumber: invoiceIdData?.data?.invoiceRef || "",
    createdDate: invoiceIdData?.data?.createdDate?.slice(0, 10) || "",
    status: invoiceIdData?.data?.status,
    dueDate: invoiceIdData?.data?.dueDate || "",
    buyerDetails: invoiceIdData?.data?.buyerCompanyName,
    paymentTerms: invoiceIdData?.data?.paymentTerms,
    payment: calculatePayment(
      invoiceIdData?.data?.dueDate,
      invoiceIdData?.data?.createdDate
    ),
    currency: invoiceIdData?.data?.currency || "",
    fundedAmount: formatAmount(invoiceIdData?.data?.fundedAmount),
    fundingChannel: invoiceIdData?.data?.fundingChannel || "",
    outstandingBalance: formatAmount(invoiceIdData?.data?.outstandingBalance),
    fundingStatus: invoiceIdData?.data?.fundingStatus,
    accountNumber:
      invoiceIdData?.data?.virtualAccount?.accountNumber || "0000000000",
    accountName: invoiceIdData?.data?.virtualAccount?.accountName || "No name",
    vat: invoiceIdData?.data?.vat || 0,
    discount: invoiceIdData?.data?.discount || 0,
    vatAmount: formatAmount(invoiceIdData?.data?.vatAmount || ""),
    discountAmount: formatAmount(invoiceIdData?.data?.discountAmount || ""),
    grandTotal: formatAmount(invoiceIdData?.data?.grandTotal || ""),
    subTotal: formatAmount(invoiceIdData?.data?.subTotal || ""),
    numberOfLineItems: invoiceIdData?.data?.items?.length || 0,
    lineItems:
      invoiceIdData?.data?.items?.map((item) => ({
        // itemNumber: item?.itemNumber || "",
        item: item?.itemName ? item?.itemName : "",
        description: item.description || "",
        unitPrice: formatAmount(item.unitPrice || ""),
        quantity: item.quantity || 0,
        amount: formatAmount(item.amount || ""),
        taxRate: item.taxRate || 0,
        taxAmount: item.taxAmount || 0,
        totalPrice: item.totalPrice || "",
      })) || [],
  };

  return (
    <>
      <CreatedInvoiceDetails
        data={data}
        handleDeleteClick={handleDeleteClick}
        handleSubmitClick={handleSubmitClick}
        handleEditClick={handleEditClick}
        showSendReminderButton={false}
      />
      <AcceptModal
        isOpen={isRejectModalOpen}
        onClose={() => setIsRejectModalOpen(false)}
        message={
          <>
            Are you sure you would want to{" "}
            <span className="text-[#BB0C0C] font-bold">delete</span> this
            invoice
          </>
        }
        onConfirm={handleDelete}
        loading={false}
      />
      <AcceptModal
        isOpen={isSubmitModalOpen}
        onClose={() => setIsSubmitModalOpen(false)}
        message={
          <>
            Are you sure you would want to{"  "}
            <span className="text-[#07593D] font-bold">submit</span> your
            invoice to the <br /> specified buyer?
          </>
        }
        onConfirm={handleSubmit}
        loading={false}
      />
      <SelectAccountModal
        isOpen={isSelectAccountModalOpen}
        onClose={() => setIsSelectAccountModalOpen(false)}
        onSubmit={handleAccountSubmit}
      />
      <AcceptModal
        isOpen={isProceedModalOpen}
        onClose={() => setIsProceedModalOpen(false)}
        message={
          <>
            Would you like to proceed with this account{" "}
            <span className="text-[#07593D] font-bold">
              {selectedAccounts?.accountNumber}?
            </span>
          </>
        }
        onConfirm={handleProceedSubmit}
        loading={false}
      />
      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={() => setIsSuccessModalOpen(false)}
        message={successMessage}
      />
      <DeleteSuccessModal
        isOpen={isRejectSuccessModal}
        onClose={() => setIsRejectSuccessModal(false)}
      />
    </>
  );
};

export default CreatedDetails;
