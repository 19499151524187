import React, { useState } from "react";

const UploadAgreementModal = ({
  isOpen,
  onClose,
  allBuyerFundingAgreement,
  selectedTemplateIds,
  handleAgreementCheckboxChange,
  handleFileChange,
  selectedNames,
  setSelectedNames,
}) => {
  const [selected, setSelected] = useState("");

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg w-[700px] h-[600px] pb-5">
        <div className="flex flex-col justify-between h-[100%]">
          <div>
            <div>
              <div className="bg-[#fafafa] px-3 py-3 m-1">
                <h4 className="text-base font-medium">
                  Upload Agreement Document
                </h4>
              </div>
              <p className="text-[#7a7a7a] mb-4 mx-4 mt-2">
                Please select an agreement document to be sent to the funders
              </p>
            </div>

            <div className="overflow-y-auto max-h-60">
              <table className="w-full text-left">
                <thead>
                  <tr className="bg-[#fafafa]">
                    <th className="py-4 px-4 text-[14px] font-medium">
                      Template Name
                    </th>
                    <th className="py-4 px-4 text-[14px] font-medium">
                      Date Uploaded
                    </th>
                    <th className="py-4 px-4 text-[14px] font-medium">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {allBuyerFundingAgreement?.data?.data?.map((template) => (
                    <tr key={template.id} className="hover:bg-gray-100 my-5">
                      <td className="py-2 px-4 text-left text-[14px]">
                        <input
                          className="mr-2"
                          onChange={() => {
                            handleAgreementCheckboxChange(template.id, template.name);
                            setSelected(template.id);
                          }}
                          checked={selected === template.id}
                          type="checkbox"
                        />
                        {template.name}
                      </td>
                      <td className="py-2 px-4 text-[14px]">
                        {template.createdDate}
                      </td>
                      <td className="py-2 px-4 text-[14px]">
                        {template.status}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="flex items-center justify-between px-5 mt-4">
            <button
              className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              className="bg-primaryColor text-white px-8 py-2 rounded"
              onClick={() => {
                handleFileChange();
                onClose();
              }}
            >
              Select
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadAgreementModal;
