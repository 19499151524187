// import { PAIconActionAgreementArrowEnd } from 'assets/images/svgs'
import React from 'react'
import docagreedoc from 'assets/images/docagreedoc.png'

const ThirdStep = ({onPrev, onNext}) => {


   
  return (
    <div className='lg:min-w-[360px] md:min-w-[360px]'>

<div className='flex w-full justify-center items-center mt-2'><div class="self-stretch text-center justify-start text-[#a3a3a3] text-sm font-normal ">Preview Selected Template</div></div>

<div class="text-start justify-start text-amber-600 text-sm font-semibold my-4 ">Campaign PFP Macro</div>

{/* <div className='flex flex-col gap-2'> */}
<section className="flex flex-col gap-1">


{/* <div class="justify-start text-neutral-800 text-xs font-medium ">PFP CAMPAIGN AGREEMENT</div> */}
<img src={docagreedoc} alt='doc'/>
 
</section>

    
{/* </div> */}

<div className=' flex w-full justify-between items-center py-3'>

<div onClick={onPrev} className=' cursor-pointer'><div class="p-2.5 rounded-[5px] outline outline-1 outline-offset-[-1px] outline-stone-300 inline-flex justify-center items-center gap-2.5 mt-5">
    <div class="justify-start text-neutral-400 text-xs font-medium ">Previous step</div>
</div>

</div>


<div onClick={onNext} className=' cursor-pointer'>
 

<div class="p-2.5 bg-[#065f46] rounded-[5px] inline-flex justify-center items-center gap-2.5">
    <div class="justify-start text-white text-xs font-medium ">Next step</div>
</div>

</div>
</div>
      


    </div>
  )
}

export default ThirdStep
