import { PAIconAlert } from "assets/images/svgs";
import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
import AccountVerificationModal from "./AccountVerificationModal";

const ConfirmationModal = ({
  isOpen,
  onCloseReverse,
  onCloseDynamic,
  onConfirm,
  accountName,
  amount,
  selectedAccounts,
}) => {
  const [verificationModal, setVerificationModal] = useState(false);
  const proceedCreate = () => {
    setVerificationModal(true);
    onConfirm();
  };
  // const navigate = useNavigate();


  if (!isOpen) return null;

  return (
    <div>
      {verificationModal ? (
        <AccountVerificationModal
          isOpen={verificationModal}
          message="Payable finace program created successfully"
          onConfirm={onConfirm}
        />
      ) : (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg shadow-lg w-10/12 max-w-md p-6">
            <div className="flex justify-center">
              <div className="text-yellow-500 text-3xl">
                <PAIconAlert />
              </div>
            </div>
            <p className="text-center mt-4 text-[#959595]">
              Are you sure you want to create this payable finance campaign?
              Please note that the selected account{" "}
              <span className="font-semibold text-primaryColor">
                {selectedAccounts}
              </span>{" "}
              will be subjected to a lien of
              <span className="font-semibold text-primaryColor ml-1">NGN {amount}</span>
              .
            </p>
            <div className="flex justify-center mt-6 space-x-4">
              <button
                onClick={proceedCreate}
                className="px-8 py-2 bg-primaryColor h-[45px] text-white font-semibold rounded-[4px] hover:bg-green-700"
              >
                Yes
              </button>
              <button
                onClick={onCloseReverse || onCloseDynamic}
                className="px-8 py-2 bg-red-600 text-[#ed2345] h-[45px] font-semibold rounded-[4px] border-[1px] border-[#ed2345]"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConfirmationModal;
