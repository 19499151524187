import { PAIconArrowLeftGreen, PAIconModelOne } from "assets/images/svgs";
import React, { useState, useEffect } from "react";
import RejectModal from "./rejectModal";
import RejectionSuccess from "features/invoice/buyerInvoice/Modals/RejectionSuccess";
import { useNavigate, useLocation } from "react-router-dom";
import BidDetails from "./BidDetails";
import { useDispatch, useSelector } from "react-redux";
import { pendingBulkEarlyPaymentDetails } from "appstate/buyer/buyerSlice";

const Details = () => {
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [isRejectSuccessModal, setIsRejectSuccessModal] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { activeTabType } = location.state || {};
  const status = "PROCESSING";

  const { getInvoiceId, pendingBulkEarlyPaymentDetailsData } = useSelector(
    (state) => state?.buyer
  );
  const [data, setData] = useState([]);

  useEffect(() => {
    const id = getInvoiceId;
    dispatch(pendingBulkEarlyPaymentDetails({ id, status }));
  }, [dispatch, getInvoiceId]);

  useEffect(() => {
    setData(pendingBulkEarlyPaymentDetailsData?.data);
  }, [pendingBulkEarlyPaymentDetailsData]);

  //Reject modal control
  const handleRejectRequest = () => {
    setIsRejectModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsRejectModalOpen(false);
  };

  //Success modal control
  const handleSuccessModal = () => {
    setIsRejectModalOpen(false);
    setIsRejectSuccessModal(true);
  };

  const handleSuccessModalClose = () => {
    setIsRejectSuccessModal(false);
  };

  useEffect(() => {
    if (isRejectSuccessModal === true) {
      setTimeout(() => {
        navigate("/payablefinancing/overview");
      }, 3000);
    }
  }, [isRejectSuccessModal, navigate]);

  // useEffect(() => {
  //   if (isLoading === true) {
  //     setTimeout(() => {
  //       setIsLoading(false);
  //       setTimeout(() => {
  //         setIsApproved(true);
  //         setTimeout(() => {
  //           navigate("/payablefinancing/overview");
  //         }, 2000);
  //       }, 3000);
  //     }, 3000);
  //   }
  // }, [isLoading, navigate]);

  // useEffect(() => {
  //   if (isLoading === false) {

  //   }
  // }, [isLoading]);

  // const handleAcceptRequest = () => {
  //   dispatch(
  //     approveOrDeclineEarlyPayment({
  //       id: getInvoiceId,
  //       comment: "",
  //       status: "APPROVED",
  //     })
  //   ).then((data) => {
  //     if (data?.payload?.success) {
  //       toast.custom(
  //         <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
  //           <div className="flex">
  //             <div className="bg-[#07593D] p-10 shadow-md rounded-s text-center text-white">
  //               <PAIconMDISuccess />
  //             </div>
  //             <div className="bg-white py-10 px-3 flex flex-col items-center justify-center shadow-md text-center rounded-e">
  //               <p className="text-[#959595] text-center font-sans text-xs font-normal leading-normal tracking-[0.321px] mb-3 mt-3">
  //                 The Early payment request has been approved!
  //               </p>
  //             </div>
  //           </div>
  //         </div>
  //       );
  //     }
  //   });
  // };

  return (
    <>
      <div
        onClick={() => {
          let path = "/payablefinancing/overview";
          navigate(path, { state: { selectedtab: "earlyPaymentsProcessing" } });
        }}
        className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer w-full"
      >
        <PAIconArrowLeftGreen />
        <span className="flex items-center text-primaryColor">Go Back</span>
      </div>

      <div className="grid lg:grid-cols-[60%,40%] gap-5 h-[80vh]">
        {/* 60% */}
        <div className="flex flex-col gap-8 pb-24 bg-white rounded-[10px] pt-7">
          <div className="px-6 ">
            <div className="flex flex-col gap-3">
              <div className="flex items-center justify-between">
                {/* <h1 className="text-[18px] font-bold">
                  Reference No: | INV-{invoiceData?.invoiceNumber}
                </h1> */}
                <h1 className="text-[18px] font-bold">
                  Reference No: | {data?.requestReferenceNumber}
                </h1>
                {activeTabType === "Request in closed market" ? (
                  <button className="w-[135px] h-[45px] rounded-[5px] bg-[#07593D] text-[white]">
                    Posted
                  </button>
                ) : null}
              </div>

              <main className=" w-full flex justify-between">
                <div className="flex flex-col gap-3 w-1/2">
                  <div className="flex flex-col gap-1">
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs font-semibold w-[35%]">
                        Request Date:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {data?.requestDate}
                      </span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs font-semibold w-[35%]">
                        Total Request Amount:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {data?.totalRequestAmount?.toLocaleString()}
                      </span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs font-semibold w-[35%]">
                        Total Invoice Amount:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {data?.totalInvoiceAmount?.toLocaleString()}
                      </span>
                    </div>
                  </div>
                </div>
                {activeTabType === "Request from funder" ? (
                  <div>
                    <button className="w-[180px] h-[35px] bg-[#C7D7FF] rounded-[4px] text-[#5C8AFF] text-[14px]">
                      {data?.status}
                    </button>
                  </div>
                ) : null}
              </main>
            </div>

            <div className="flex justify-between my-5 border-b-[3px] border-slate-100 pb-2">
              <div className="flex flex-col">
                <span className=" font-bold text-sm">
                  {" "}
                  {data?.buyerCompanyName}
                </span>
                <span className="text-[#979797] text-xs">Buyer Details</span>
              </div>
              <div className="flex flex-col">
                <span className="font-bold text-sm">60 Days</span>
                <span className="text-[#979797] text-xs">Payment</span>
              </div>
              <div className="flex flex-col">
                <span className="font-bold text-sm">NGN</span>
                <span className=" text-[#979797] text-xs">Currency</span>
              </div>
            </div>

            <div className="flex flex-col gap-4 mt-5">
              <header className="border-b-[5px] border-b-[#F6F5FA] pb-3 flex items-center justify-between">
                <h1 className="text-sm font-bold">Invoice List</h1>
                <h1 className="text-sm font-bold">
                  {data?.earlyPaymentInvoiceResponse?.length} Invoice(s)
                </h1>
              </header>
              <div className=" w-full overflow-x-auto">
                <table className="overflow-x-auto w-[50vw]">
                  <thead className="bg-[#EFEFEF] h-[50px] w-[50vw] overflow-x-auto">
                    <tr className=" bg-unusualGray px-2.5 py-1">
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Ref Number
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Due Date
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Request Amount
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Discount Rate
                      </td>
                      <td className="text-[#11192A] p-2.5 text-xs font-bold w-[120px]">
                        Discount Amount
                      </td>
                    </tr>
                  </thead>
                  {data?.earlyPaymentInvoiceResponse?.map((item, index) => (
                    <tbody key={index}>
                      <tr className=" py-[3px] px-1.5">
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.invoiceRef}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5 w-[20%]">
                          {item?.description}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.requestAmount}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5 flex items-center gap-1">
                          {item?.discountRate}%{/* <PAIconEditPenYellow /> */}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.discountAmount}
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* 40% */}
        <div className="bg-white rounded-[10px] gap-8 px-6 pt-7 pb-24">
          {activeTabType === "Request from funder" ? (
            <div>
              <h2 className="font-semibold text-lg leading-[30px] mb-4">
                Early Payment Details
              </h2>

              <div className="flex justify-between my-5">
                <div className="flex gap-2">
                  <PAIconModelOne />
                  <div className="flex flex-col">
                    <span className="text-sm">Supplier</span>
                    <span className="text-[#979797]  text-xs">
                      {data?.supplierCompanyName}
                    </span>
                  </div>
                </div>
              </div>

              <div className="flex justify-between pb-5">
                <div className="flex gap-2">
                  <div className="flex flex-col">
                    <span className="text-sm">
                      N{data?.totalDiscountAmount || "0.00"}
                    </span>
                    <span className="text-[#979797]  text-xs">
                      Total Discount Amount
                    </span>
                  </div>
                </div>
                <div className="flex flex-col">
                  <span className="text-sm">{data?.requestDate}</span>
                  <span className="text-[#979797]  text-xs">
                    Early Payment Request Date
                  </span>
                </div>
              </div>

              <div className="flex justify-between mt-5 border-b-[5px] border-b-[#F6F5FA] pb-10">
                <div className="flex gap-2">
                  <div className="flex flex-col">
                    <span className="text-sm">
                      N{data?.totalRequestAmount?.toLocaleString() || "0.00"}
                    </span>
                    <span className="text-[#979797]  text-xs">
                      Request Amount
                    </span>
                  </div>
                </div>
                <div className="flex flex-col">
                  <span className="text-sm">
                    {" "}
                    N{data?.totalPayableAmount?.toLocaleString() || "0.00"}
                  </span>
                  <span className="text-[#979797]  text-xs">
                    Payable amount
                  </span>
                </div>
              </div>

              <div className="border-b-[5px] border-b-[#F6F5FA] pb-5">
                <h2 className="font-medium text-base my-3">
                  Note from Supplier:
                </h2>
                <p className="text-[#959595] text-sm">
                  {data?.description}
                </p>
              </div>

              <div className="pb-3">
                <h2 className="font-medium text-base leading-[30px] my-2">
                  Funding Option
                </h2>
                <p className="text-[#959595] text-sm">
                  This invoice will be approved by{" "}
                  <span className="font-semibold text-black">
                    Elanor Penar at coca-cola
                  </span>{" "}
                  your associated funder for funding
                </p>
                <div className="flex flex-col items-center justify-center mt-9">
                  <button
                    className="rounded-md bg-white border border-[#DE1515] text-[#DE1515] px-12 py-2 h-[65px]"
                    onClick={handleRejectRequest}
                  >
                    Withdraw Request
                  </button>
                  <p className="mt-3 text-lightGray">
                    Do you want to cancel this request?
                  </p>
                </div>
              </div>

              <RejectModal
                isOpen={isRejectModalOpen}
                onClose={handleCloseModal}
                confirm={handleSuccessModal}
              />
              <RejectionSuccess
                isOpen={isRejectSuccessModal}
                onClose={handleSuccessModalClose}
              />
            </div>
          ) : (
            <BidDetails />
          )}
        </div>
      </div>
    </>
  );
};

export default Details;
