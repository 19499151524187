// src/features/kyc/kycActions.js
import {
  setBankDetailsUploaded,
  setKycDocumentUploaded,
  setAgreementDocumentAccepted,
  setAgreeToTerms,
  setNewToDashBoard,
} from "./verificationSlice";

// Action to update bank details status
export const updateUploadBankDetails = (status) => (dispatch) => {
  dispatch(setBankDetailsUploaded(status));
};

// Action to update KYC document upload status
export const updateUploadKycDocument = (status) => (dispatch) => {
  dispatch(setKycDocumentUploaded(status));
};

// Action to update agreement document  status
export const updateAgreementDocumentAccepted = (status) => (dispatch) => {
  dispatch(setAgreementDocumentAccepted(status));
};

//Action Agree to Terms
export const updateAgreeToTerms = (status) => (dispatch) => {
  dispatch(setAgreeToTerms(status));
};
export const completedOnboardingAndCreatePin = (status) => (dispatch) => {
  dispatch(setNewToDashBoard(status));
};
