import React from "react";
import DashboardLayout from "layout/dashboardlayout";
import Details from "./Details";

const OverviewDirectFundingPreview = () => {
  return (
    <DashboardLayout
      section="Collections"
      subHeader="Track invoices due for payment collection"
    >
      <Details />
    </DashboardLayout>
  );
};

export default OverviewDirectFundingPreview;
