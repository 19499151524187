import DashboardLayout from "layout/dashboardlayout";
import React from "react";
import Details from "./details";

const PaidPaymentDetails = () => {
  return (
    <DashboardLayout
    section="Invoice"
    subHeader="Here is an overview of your invoices"
    >
      <Details />
    </DashboardLayout>
  );
};

export default PaidPaymentDetails;
