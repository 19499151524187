import { PAIconArrowLeftGreen } from "assets/images/svgs";

import { AnimatePresence, motion } from "framer-motion";
import DashboardLayout from "layout/dashboardlayout";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import TransitionScale from "shared/animation/TransitionScale";
import OngoingAcceptUpdateBidModal from "../../directFunding/Details/AccepUpdateBidModal";
import EnterPin from "../../directFunding/Details/Pin";

const PayableDetailsPage = () => {
  const [pinModal, setPinModal] = useState(false);
  const [acceptModal, setAcceptModal] = useState(false);
  const navigate = useNavigate();
  const dummyData = [
    {
      bidAmount: 3,
      item: "Solar Panel ",
      description: "Dangote 12 x 4 ultra high density so..",
      unitPrice: "340,000",
      Quantity: "3",
      amount: "1,020,000",
      taxRate: "1.2%",
      taxAmount: "12,240",
      totalPrice: "1,032,240",
    },

    {
      bidAmount: 4,
      item: "Solar Panel ",
      description: "Dangote 12 x 4 ultra high density so..",
      unitPrice: "340,000",
      Quantity: "3",
      amount: "1,020,000",
      taxRate: "1.2%",
      taxAmount: "12,240",
      totalPrice: "1,032,240",
    },

    {
      bidAmount: 5,
      item: "Solar Panel ",
      description: "Dangote 12 x 4 ultra high density so..",
      unitPrice: "340,000",
      Quantity: "3",
      amount: "1,020,000",
      taxRate: "1.2%",
      taxAmount: "12,240",
      totalPrice: "1,032,240",
    },
    {
      bidAmount: 6,
      item: "Solar Panel ",
      description: "Dangote 12 x 4 ultra high density so..",
      unitPrice: "340,000",
      Quantity: "3",
      amount: "1,020,000",
      taxRate: "1.2%",
      taxAmount: "12,240",
      totalPrice: "1,032,240",
    },
    {
      bidAmount: 7,
      item: "Solar Panel ",
      description: "Dangote 12 x 4 ultra high density so..",
      unitPrice: "340,000",
      Quantity: "3",
      amount: "1,020,000",
      taxRate: "1.2%",
      taxAmount: "12,240",
      totalPrice: "1,032,240",
    },
  ];

  const handleYes = () => {
    setAcceptModal(false);
    setPinModal(true);
  };
  return (
    <>
      <OngoingAcceptUpdateBidModal
        onClose={() => setAcceptModal(false)}
        isOpen={acceptModal}
        mess={"Are you sure you would like to "}
        payText={"Pay now"}
        mess2={"for this invoice  "}
        handleYes={handleYes}
      />{" "}
      <DashboardLayout
        section="Disbursement"
        subHeader="Track invoices due for payment disbursement"
      >
        <header className="w-full py-3">
          <span
            onClick={() => navigate(-1)}
            className="flex cursor-pointer items-center font-medium text-sm text-primaryColor gap-2"
          >
            <PAIconArrowLeftGreen /> Go back
          </span>
        </header>
        <main className="flex px-6 flex-col lg:flex-row gap-3 justify-between">
          <TransitionScale className="w-full flex flex-col gap-8 min-h-[90vh] bg-white rounded-[10px]">
            <div className="flex py-3  border-b border-[#F0F0F0] px-3 justify-between rounded-t-[10px] bg-[#FAFAFA]">
              <span>Invoices</span>

              <div className="flex gap-1 items-center">
                <span className="text-sm"> Status: </span>
                <div className="text-primaryColor text-sm font-bold">
                  Accepted
                </div>
              </div>
            </div>
            <header className="flex px-6 flex-col gap-3">
              <section className="flex justify-between  items-center">
                <span className="text-[18px] font-bold">
                  Invoice Details | INV-2002
                </span>

                <div className="flex flex-col gap-1">
                  <div class="h-[30px] px-8 py-2 bg-[#07593d]/30 rounded-[5px] justify-center items-center gap-2.5 inline-flex mt-4">
                    <div class="text-center text-[#07593d] text-xs font-semibold ">
                      Approved
                    </div>
                  </div>
                  <motion.button
                    onClick={() => {
                      setTimeout(() => {
                        setAcceptModal(true);
                      }, 200);
                    }}
                    whileTap={{ scale: 0.95 }}
                    className="py-3 px-6 text-xs  text-white rounded bg-primaryColor mt-4"
                  >
                    Pay Now
                  </motion.button>

                  <span className="text-secondaryColor text-sm font-medium">
                    00d:13h:55m:32s
                  </span>
                </div>
              </section>

              <section className="flex justify-between">
                <div className="flex flex-col gap-1 w-[30%]">
                  <section className="flex items-center justify-between">
                    <span className="text-[18px] font-light font-light">
                      Reference No
                    </span>
                    <span className="text-[#7a7a7a] text-xs font-semibold">
                      {/* INV 2002 */}
                    </span>
                  </section>

                  <section className="flex items-center justify-between font-light">
                    <span className="text-sm">Created Date</span>
                    <span className="text-[#7a7a7a] text-sm">23-Mar -2023</span>
                  </section>

                  <section className="flex items-center justify-between font-light">
                    <span className="text-sm">Invoice Due Date</span>
                    <span className="text-[#7a7a7a] text-sm">23-Mar -2023</span>
                  </section>

                  <section className="flex items-center justify-between font-light">
                    <span className="text-sm">Invoice Amount</span>
                    <span className="text-[#7a7a7a] text-sm">
                      NGN9,000,000{" "}
                    </span>
                  </section>
                </div>

                <div class="lg:w-[240px]  sm:w-full h-[117px] flex-col justify-start items-start gap-3 inline-flex">
                  <div class=" justify-between items-center flex mt-2 ">
                    <div class="text-[#222222] text-base font-bold ">
                      Payable Finance Offer
                    </div>
                  </div>
                  <div class="self-stretch  flex-col justify-center items-start gap-1 flex mt-2">
                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Discount Rate
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        2%
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex ">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Discounted Price
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        NGN 180,000
                      </div>
                    </div>

                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Payable Amount
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        NGN8,820,000
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Payment Duration
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        60 Days
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Payment Date
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        20-Jan-2023
                      </div>
                    </div>
                  </div>

                  {/* stop */}
                </div>
              </section>
            </header>

            <section className="flex px-6 items-center justify-between">
              <div className="flex flex-col gap-2">
                <span className="text-[18px] italic font-semibold">
                  Supplier PLC
                </span>
                <span className="text-[#7A7A7A] text-xs font-light">
                  ABC Cooperations
                </span>
              </div>
            </section>

            <section className="flex px-6 flex-col gap-4">
              <header className="flex items-center justify-between pb-3 border-b-[5px] border-[#F6F5FA] text-[#11192A]">
                <span className="text-sm">Line Items</span>

                <span className="text-[18px] font-light">5 Total Items </span>
              </header>

              <div className=" w-full overflow-x-auto mt-3 pb-4">
                <table className=" w-full overflow-x-auto">
                  <thead className="bg-subtleGray w-full p-6  flex-nowrap whitespace-nowrap  ">
                    <tr className=" bg-unusualGray  px-6 w-full flex-nowrap whitespace-nowrap ">
                      {/* <td className="text-[#11192A] p-2.5 text-xs font-bold">
                        Item Number
                      </td> */}
                      <td class="text-[#11192a] text-xs font-semibold  p-4  flex-nowrap whitespace-nowrap ">
                        Item{" "}
                      </td>
                      <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                        Description{" "}
                      </td>
                      <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                        Unit Price
                      </td>
                      <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                        Quantity
                      </td>
                      <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                        Amount
                        <div> (before tax)</div>
                      </td>
                      <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                        Tax Rate
                      </td>
                      <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                        Tax Amount
                      </td>
                      <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                        Total Price
                      </td>
                    </tr>
                  </thead>

                  <tbody className="  ">
                    {dummyData.map((cell, idx) => {
                      return (
                        <tr key={idx} className=" w-full ">
                          <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap ">
                            {cell.item}
                          </td>
                          <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                            {cell.description}
                          </td>
                          <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                            {cell.unitPrice}
                          </td>
                          <td className="text-[#11192a] text-xs font-semibold mt-2 text-center flex-nowrap whitespace-nowrap">
                            {cell.Quantity}
                          </td>
                          <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                            {cell.amount}
                          </td>
                          <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                            {cell.taxRate}
                          </td>
                          <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                            {cell.taxAmount}
                          </td>
                          <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                            {cell.totalPrice}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <div className=" px-6   py-5 bg-neutral-50 flex-col justify-center items-start  inline-flex bg-[#fafafa] w-full border-t-[0.2px]  border-t-[#b1a6b2]">
                <div className="lg:flex md:flex justify-between  w-full sm:block ">
                  {/* First left side */}
                  <div>
                    <section>
                      {" "}
                      <div class="text-[#222222] text-lg  font-medium border-b-[0.2px]  border-[#b1a6b2]">
                        Receiving Funding Account Information
                      </div>
                      {/* <div class="text-[#222222] text-lg font-semibold ">
                        Receiving Funding Account Information
                      </div> */}
                      <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                        74*******512
                      </div>
                      <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                        Praise Akinlade
                      </div>
                    </section>
                  </div>

                  {/* second division */}

                  <div>
                    {" "}
                    <div class=" flex-col justify-start items-start inline-flex lg:min-w-[290px]">
                      <section className=" w-full px-6 pb-2 border-b-[1px] border-black">
                        <div className=" flex w-full justify-between">
                          <div class="text-[#222222] text-xs font-semibold ">
                            Subtotal
                          </div>
                          <div class=" text-[#222222] text-xs font-semibold ">
                            10,129,892
                          </div>
                        </div>
                        <div className=" flex w-full justify-between pt-3 pb-1 border-b-[0.2px] border-b-[#b1a6b2]/30">
                          <div class="text-[#222222]  text-xs font-medium flex  space-x-9 ">
                            <span>VAT%</span>
                            <span>5.6%</span>
                          </div>
                          <div class="text-[#222222] text-xs font-medium ">
                            573,960
                          </div>
                        </div>

                        <div className=" flex w-full justify-between pt-3 pb-1 border-b-[0.2px] border-b-[#b1a6b2]/30">
                          <div class="text-[#222222]  text-xs font-medium flex  space-x-9 ">
                            <span>Discount%</span>
                            <span>2.4%</span>
                          </div>
                          <div class="text-[#222222] text-xs font-medium ">
                            237,088
                          </div>
                        </div>
                      </section>
                      <div className=" flex w-full justify-between py-2 px-6 border-b-[1px] border-b-black">
                        <div class="text-[#222222] text-lg font-semibold ">
                          Grand Total
                        </div>
                        <div class="text-[#222222] text-lg font-semibold ">
                          10,473,890
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* stops */}
                </div>
              </div>
            </section>
          </TransitionScale>
        </main>

        <AnimatePresence>
          <EnterPin
            isOpen={pinModal}
            onClose={() => setPinModal(false)}
            pinMessage={
              "Enter your PIN to fund this request from the chosen virtual account."
            }
          />
        </AnimatePresence>
      </DashboardLayout>
    </>
  );
};

export default PayableDetailsPage;
