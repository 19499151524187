import React from "react";
import { PAIconVerifiedIcon } from "assets/images/svgs";

const BvnInputField = ({
  type,
  placeholder,
  disabled,
  onChange,
  name,
  id,
  onBlur,
  className,
  value,
  defaultValue,
  htmlFor,
  onFocus,
  label,
  autoComplete,
  labelFontSize,
  multiple,
  validNin,
  maxLength,
}) => {
  const defaultClass = `h-[41px] border-b ${
    validNin ? "border-[#2EA923]" : "border-textColor"
  } text-xs font-normal flex  `;
  return (
    <div className="flex flex-col">
      {label && (
        <label
          className={`font-normal ${
            labelFontSize ? "text-sm" : "text-xs"
          } text-textColor mb-[10px]`}
          htmlFor={htmlFor}
        >
          {label}
        </label>
      )}
      <div className={`${className} ${defaultClass}`}>
        <input
          className=" focus:outline-none flex-1 bg-[#f6f6f6]  focus:bg-[#f6f6f6]  "
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          onChange={onChange}
          name={name}
          id={id}
          onBlur={onBlur}
          value={value}
          onWheel={(e) => e.target.blur()}
          onFocus={onFocus}
          multiple={multiple}
          defaultValue={defaultValue}
          autoComplete={autoComplete}
          maxLength={maxLength}
        />
        {validNin && <PAIconVerifiedIcon />}
      </div>
    </div>
  );
};

export default BvnInputField;
