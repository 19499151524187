import { motion } from "framer-motion";
import {
  PAIconArrowLeftGray,
  PABlackSmallDangote,
  PAEllipsisIcon,
} from "assets/images/svgs";
import { Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import TransitionScale from "shared/animation/TransitionScale";
import ProceedToPaymentModal from "../../modals/ProceedToPaymentModal";
import { useState } from "react";

import PayProgressModal from "../../modals/PayProgressModal";
import { useEffect } from "react";
import OngoingPayBidSuccessOnModal from "../../modals/OngoingBidPaySucessOnBoardModal";

import OngoingTransferReceipt from "../../modals/OngoingBidTransferReceipt";

function Details() {
  const navigate = useNavigate();

  function handleGoBack() {
    navigate(-1);
  }
  const [pay, setPay] = useState(false);
  const [progress, setProgress] = useState(false);
  const [paysucessModal, setPaySucessModal] = useState(false);
  const [transModal, setTransModal] = useState(false);
  const handleYes = () => {
    setPay(false);
    setProgress(true);
  };

  const dummyData = [
    {
      bidAmount: 3,
      item: "Solar Panel ",
      description: "Dangote 12 x 4 ultra high density so..",
      unitPrice: "340,000",
      Quantity: "3",
      amount: "1,020,000",
      taxRate: "1.2%",
      taxAmount: "12,240",
      totalPrice: "1,032,240",
    },

    {
      bidAmount: 4,
      item: "Solar Panel ",
      description: "Dangote 12 x 4 ultra high density so..",
      unitPrice: "340,000",
      Quantity: "3",
      amount: "1,020,000",
      taxRate: "1.2%",
      taxAmount: "12,240",
      totalPrice: "1,032,240",
    },

    {
      bidAmount: 5,
      item: "Solar Panel ",
      description: "Dangote 12 x 4 ultra high density so..",
      unitPrice: "340,000",
      Quantity: "3",
      amount: "1,020,000",
      taxRate: "1.2%",
      taxAmount: "12,240",
      totalPrice: "1,032,240",
    },
    {
      bidAmount: 6,
      item: "Solar Panel ",
      description: "Dangote 12 x 4 ultra high density so..",
      unitPrice: "340,000",
      Quantity: "3",
      amount: "1,020,000",
      taxRate: "1.2%",
      taxAmount: "12,240",
      totalPrice: "1,032,240",
    },
    {
      bidAmount: 7,
      item: "Solar Panel ",
      description: "Dangote 12 x 4 ultra high density so..",
      unitPrice: "340,000",
      Quantity: "3",
      amount: "1,020,000",
      taxRate: "1.2%",
      taxAmount: "12,240",
      totalPrice: "1,032,240",
    },
  ];

  const handleCloseSucess = () => {
    setPaySucessModal(false);
    setTransModal(true);
  };
  const handleCloseReceipt = () => {
    setTransModal(false);
  };

  useEffect(() => {
    let timeoutId;

    if (progress) {
      timeoutId = setTimeout(() => {
        setProgress(false);
        setPaySucessModal(true);
      }, 4000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [progress]);
  useEffect(() => {
    let timeoutId;

    if (transModal) {
      timeoutId = setTimeout(() => {
        setTransModal(false);
        navigate("/disbursement");
      }, 4000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [navigate, transModal]);

  return (
    <>
      <ProceedToPaymentModal
        isOpen={pay}
        onClose={() => setPay(false)}
        payText={"Pay now"}
        handleYes={handleYes}
      />

      {progress && <PayProgressModal />}

      <OngoingPayBidSuccessOnModal
        isOpen={paysucessModal}
        onClose={handleCloseSucess}
        message={"Fund disbursed successfully"}
      />

      <OngoingTransferReceipt
        isOpen={transModal}
        onClose={handleCloseReceipt}
      />
      <TransitionScale className="flex  flex-col  bg-[#EEE] h-full">
        <main className="flex flex-col  pb-8 gap-8 w-full ">
          <Link
            className="flex cursor-pointer w-fit gap-1 items-center "
            onClick={handleGoBack}
          >
            <PAIconArrowLeftGray />
            <span className=" text-[#7A7A7A] text-sm">Go Back</span>
          </Link>
          <motion.section
            initial={{
              x: 20,
            }}
            animate={{
              x: 0,
            }}
            exit={{
              x: -20,
            }}
            transition={{
              delay: 0.6,
            }}
          >
            <div className="grid lg:grid-cols-[60%,40%] gap-5">
              <TransitionScale className=" pb-5 bg-white  rounded-[10px]  pt-2">
                {/* Header with Left Padding */}

                {/* Other sections */}

                <section className="  mt-3">
                  {/* Down Flex Texts */}
                  <div className=" flex justify-between px-6  mt-4">
                    {" "}
                    <div class="text-[#222222] text-lg font-medium ">
                      Reference No: | RF83018374BA
                    </div>
                    <div
                      class="h-[38px] px-6 py-3 bg-[#07593d] rounded-[5px] justify-center items-center gap-2.5 inline-flex  cursor-pointer"
                      onClick={() => setPay(true)}
                    >
                      <div class="text-white text-sm font-medium ">Pay Now</div>
                    </div>
                  </div>
                  <div className=" flex justify-between px-6">
                    {/* first stage */}

                    <div class="lg:w-[240px]  sm:w-full h-[117px] flex-col justify-start items-start gap-3 inline-flex  mt-5">
                      <div class="self-stretch  flex-col justify-center items-start gap-1 flex ">
                        <div class="self-stretch justify-between items-center inline-flex">
                          <div class="text-[#222222] text-xs font-semibold ">
                            Invoice Number
                          </div>
                          <div class="text-[#7a7a7a] text-xs font-semibold ">
                            INV 2002
                          </div>
                        </div>
                        <div class="self-stretch justify-between items-center inline-flex ">
                          <div class="text-[#222222] text-xs font-semibold ">
                            Created Date:{" "}
                          </div>
                          <div class="text-[#7a7a7a] text-xs font-semibold ">
                            23-Mar -2023
                          </div>
                        </div>
                        <div class="self-stretch justify-between items-center inline-flex">
                          <div class="text-[#222222] text-xs font-semibold ">
                            Submitted Date:{" "}
                          </div>
                          <div class="text-[#7a7a7a] text-xs font-semibold ">
                            23-Mar -2023
                          </div>
                        </div>
                        <div class="self-stretch justify-between items-center inline-flex">
                          <div class="text-[#222222] text-xs font-semibold ">
                            Approved Date:{" "}
                          </div>
                          <div class="text-[#7a7a7a] text-xs font-semibold ">
                            23-April -2023
                          </div>
                        </div>
                        <div class="self-stretch justify-between items-center inline-flex">
                          <div class="text-[#222222] text-xs font-semibold ">
                            Due Date:{" "}
                          </div>
                          <div class="text-[#7a7a7a] text-xs font-semibold ">
                            30-May-2023
                          </div>
                        </div>
                        <div class="self-stretch justify-between items-center inline-flex">
                          <div class="text-[#222222] text-xs font-semibold ">
                            Issuance Date:{" "}
                          </div>
                          <div class="text-[#7a7a7a] text-xs font-semibold ">
                            30-Dec-2023
                          </div>
                        </div>
                      </div>

                      {/* stop */}
                    </div>
                    {/* second stage */}
                    <div className="flex justify-between items-center mt-5 ">
                      {" "}
                      <div class="h-[30px] px-8 py-2 bg-[#23ad52]/20 rounded-[5px] justify-center items-center gap-2.5 inline-flex">
                        <div class="text-center text-[#07593d] text-xs font-medium ">
                          Won Bids
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* stops here */}
                  <div className="  px-6   mt-11">
                    <header className="border-b-[5px] border-b-[#F6F5FA] mt-3  flex justify-between py-1">
                      {/* <h1 className="text-[18px] font-semibold">
                  Supplier/buyer details
                </h1> */}
                      <div>
                        <div class="text-[#222222] text-lg font-semibold  ">
                          Nestle PLC
                        </div>
                        <div class="text-[#7a7a7a] text-xs font-light  ">
                          Buyer details
                        </div>
                      </div>

                      <div class="h-[41px] flex-col justify-start items-start  inline-flex px-12">
                        <div class="text-[#222222] text-base font-semibold ">
                          60 Days
                        </div>
                        <div class="w-[100px] text-[#7a7a7a] text-xs font-light  mt-1">
                          Payment
                        </div>
                      </div>

                      <div class="h-[43px] flex-col justify-start items-end  inline-flex">
                        <div class="text-[#222222] text-lg font-semibold ">
                          NGN
                        </div>
                        <div class="text-[#7a7a7a] text-xs font-semibold mt-1">
                          Currency
                        </div>
                      </div>
                    </header>

                    <header className="border-b-[5px] border-b-[#F6F5FA] mt-6  flex justify-between py-1">
                      {/* <h1 className="text-[18px] font-semibold">
                  Supplier/buyer details
                </h1> */}
                      <div class="text-[#11192a] text-sm font-semibold ">
                        Line Items
                      </div>
                      <div class="text-[#11192a] text-lg font-light  mr-6">
                        5 Total Items{" "}
                      </div>
                    </header>

                    <div className=" w-full overflow-x-auto mt-3 pb-4">
                      <table className=" w-full overflow-x-auto">
                        <thead className="bg-subtleGray w-full p-6  flex-nowrap whitespace-nowrap  ">
                          <tr className=" bg-unusualGray  px-6 w-full flex-nowrap whitespace-nowrap ">
                            {/* <td className="text-[#11192A] p-2.5 text-xs font-bold">
                          Item Number
                        </td> */}
                            <td class="text-[#11192a] text-xs font-semibold  p-4  flex-nowrap whitespace-nowrap ">
                              Item{" "}
                            </td>
                            <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                              Description{" "}
                            </td>
                            <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                              Unit Price
                            </td>
                            <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                              Quantity
                            </td>
                            <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                              Amount
                              <div> (before tax)</div>
                            </td>
                            <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                              Tax Rate
                            </td>
                            <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                              Tax Amount
                            </td>
                            <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                              Total Price
                            </td>
                          </tr>
                        </thead>

                        <tbody className="  ">
                          {dummyData.map((cell, idx) => {
                            return (
                              <tr key={idx} className=" w-full ">
                                <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap ">
                                  {cell.item}
                                </td>
                                <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                                  {cell.description}
                                </td>
                                <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                                  {cell.unitPrice}
                                </td>
                                <td className="text-[#11192a] text-xs font-semibold mt-2 text-center flex-nowrap whitespace-nowrap">
                                  {cell.Quantity}
                                </td>
                                <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                                  {cell.amount}
                                </td>
                                <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                                  {cell.taxRate}
                                </td>
                                <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                                  {cell.taxAmount}
                                </td>
                                <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                                  {cell.totalPrice}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    {/* Calculate cost and price  shaded or gray section */}
                  </div>

                  <div className=" px-6   py-5 bg-neutral-50 flex-col justify-center items-start  inline-flex bg-[#fafafa] w-full border-t-[0.2px]  border-t-[#b1a6b2]">
                    <div className="lg:flex md:flex justify-between  w-full sm:block ">
                      {/* First left side */}
                      <div>
                        <section>
                          {" "}
                          <div class="text-[#222222] text-lg  font-medium border-b-[0.2px]  border-[#b1a6b2]">
                            Receiving Funding Account Information
                          </div>
                          {/* <div class="text-[#222222] text-lg font-semibold ">
                          Receiving Funding Account Information
                        </div> */}
                          <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                            7469479465
                          </div>
                          <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                            Praise Akinlade
                          </div>
                        </section>
                      </div>

                      {/* second division */}

                      <div>
                        {" "}
                        <div class=" flex-col justify-start items-start inline-flex lg:min-w-[290px]">
                          <section className=" w-full px-6 pb-2 border-b-[1px] border-black">
                            <div className=" flex w-full justify-between">
                              <div class="text-[#222222] text-xs font-semibold ">
                                Subtotal
                              </div>
                              <div class=" text-[#222222] text-xs font-semibold ">
                                22,429,892
                              </div>
                            </div>
                            <div className=" flex w-full justify-between pt-3 pb-1 border-b-[0.2px] border-b-[#b1a6b2]/30">
                              <div class="text-[#222222]  text-xs font-medium flex  space-x-9 ">
                                <span>VAT %</span>
                                <span>5.6%</span>
                              </div>
                              <div class="text-[#222222] text-xs font-medium ">
                                573,960
                              </div>
                            </div>

                            <div className=" flex w-full justify-between pt-3 pb-1 border-b-[0.2px] border-b-[#b1a6b2]/30">
                              <div class="text-[#222222]  text-xs font-medium flex  space-x-9 ">
                                <span>Discount %</span>
                                <span>2.4%</span>
                              </div>
                              <div class="text-[#222222] text-xs font-medium ">
                                437,088
                              </div>
                            </div>
                          </section>
                          <div className=" flex w-full justify-between py-2 px-6 border-b-[1px] border-b-black">
                            <div class="text-[#222222] text-lg font-semibold ">
                              Grand Total
                            </div>
                            <div class="text-[#222222] text-lg font-semibold ">
                              23,473,890
                            </div>
                          </div>
                          {/* <div className=" flex w-full justify-between pt-3 pb-1  border-b-[1px] border-black">
                          <div class="text-[#222222] text-lg  font-medium  ">
                            Grand Total
                          </div>
                          <div class="text-[#222222] text-lg  font-medium ">
                            5,263,200.00
                          </div>
                        </div> */}
                        </div>
                      </div>

                      {/* stops */}
                    </div>
                  </div>
                </section>
              </TransitionScale>
              {/* stops the best here */}

              <div className="bg-white rounded-[10px] gap-8  ">
                {/* <FundDetailSide /> */}
                <div class="w-full justify-start items-center gap-4 inline-flex mt-4 ">
                  <div class=" justify-start items-center gap-2.5 flex w-full px-6  py-3">
                    <div class="text-[#07593d] text-base font-bold ">
                      Bid details for this invoice
                    </div>
                  </div>
                </div>

                <div>
                  {" "}
                  {/* second */}
                  <div className="   pb-4">
                    <div class=" justify-between  flex flex-row    px-6 mt-4  ">
                      <div className="flex gap-2 py-2.5">
                        <PAEllipsisIcon />
                        <div className="flex flex-col">
                          <span class="text-black text-sm font-normal ">
                            Nestle Plc.
                          </span>
                          {/* <span className="text-[#979797]  text-xs">Supplier</span> */}
                          <span class="text-[#949494] text-sm font-normal ">
                            Buyer
                          </span>
                        </div>
                      </div>
                      <div class=" flex-col justify-start items-start inline-flex">
                        {" "}
                        <div className="flex gap-2 py-2.5">
                          <PABlackSmallDangote />
                          <div className="flex flex-col">
                            {/* <span className=" text-black text-xs font-normal">
                        Coca Cola
                      </span> */}
                            <span class="text-black text-sm font-normal ">
                              Dangote Plc.
                            </span>
                            {/* <span className="text-[#979797]  text-xs">Supplier</span> */}
                            <span class="text-[#949494] text-sm font-normal ">
                              Supplier
                            </span>
                          </div>
                        </div>
                      </div>

                      {/* here */}
                    </div>
                    <div class=" justify-between  flex flex-row    px-6  mt-6 ">
                      <div class="py-2.5 flex-col justify-start items-start inline-flex">
                        <div class="flex-col justify-start items-start gap-0.5 flex">
                          <div class="text-black text-sm font-normal ">
                            NGN 346,350
                          </div>
                        </div>
                        <div class="flex-col justify-start items-start  flex">
                          <div class="text-[#949494] text-sm font-normal ">
                            Lock rate amount
                          </div>
                        </div>
                      </div>
                      {/* hd */}
                      <div class="py-2.5 flex-col justify-start items-start inline-flex">
                        <div class="flex-col justify-start items-start gap-0.5 flex">
                          <div class="text-black text-sm font-normal ">
                            1.5%
                          </div>
                        </div>
                        <div class="flex-col justify-start items-start  flex">
                          <div class="text-[#949494] text-sm font-normal ">
                            Lock rate
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                    <div class=" justify-between  flex flex-row     px-6  py-3   mt-6">
                      <div class="py-2.5 flex-col justify-start items-start inline-flex">
                        <div class="flex-col justify-start items-start gap-0.5 flex">
                          <div class="text-black text-sm font-normal ">
                            2023-02-22 (90 days)
                          </div>
                        </div>
                        <div class="flex-col justify-start items-start  flex">
                          <div class="text-[#949494] text-sm font-normal ">
                            Request date
                          </div>
                        </div>
                      </div>
                      {/* dhdh */}
                      <div class="py-2.5 flex-col justify-start items-start inline-flex">
                        <div class="flex-col justify-start items-start gap-0.5 flex">
                          <div class="text-black text-sm font-normal ">
                            2023-05-23
                          </div>
                        </div>
                        <div class="flex-col justify-start items-start  flex">
                          <div class="text-[#949494] text-sm font-normal ">
                            Early Payment date
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class=" justify-between  flex flex-row    px-6  mt-6 ">
                      <div class="py-2.5 flex-col justify-start items-start inline-flex">
                        <div class="flex-col justify-start items-start gap-0.5 flex">
                          <div class="text-black text-sm font-normal ">
                            NGN 23,090,000
                          </div>
                        </div>
                        <div class="flex-col justify-start items-start  flex">
                          <div class="text-[#949494] text-sm font-normal ">
                            Request Amount
                          </div>
                        </div>
                      </div>
                      {/* hd */}
                      <div class="py-2.5 flex-col justify-start items-start inline-flex">
                        <div class="flex-col justify-start items-start gap-0.5 flex">
                          <div class="text-black text-base font-extrabold ">
                            NGN 22,659,650
                          </div>
                        </div>
                        <div class="flex-col justify-start items-start  flex">
                          <div class="text-[#949494] text-sm font-normal ">
                            Fundable Amount
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                    {/* 2nd */}

                    {/* another header */}
                    {/* header ends here */}
                    {/* here */}
                  </div>
                  {/* stops here */}
                  {/* Third */}
                  {/* third stops */}
                </div>
              </div>
            </div>
          </motion.section>
        </main>
      </TransitionScale>
    </>
  );
}

export default Details;
