import React from "react";

import { useNavigate } from "react-router-dom";
import Table from "layout/table/Table";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import Pagination from "shared/Pagination";

// import { useNavigate } from "react-router-dom";
import Card from "shared/Card";

const RequestsHistory = () => {
  const navigate = useNavigate();
  //   const handleBack = () => {
  //     navigate(-1);
  //   };

  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const handleRowClick = (row) => {
    if (row.status === "Completed") {
      navigate(
        `/work-flow-management/detail/pendingrequestsdetails/${row.bidAmount}`
      );
    } else {
      navigate(
        `/work-flow-management/detail/pendingdeclineddetails/${row.bidAmount}`
      );
    }
  };
  const dummyData = [
    {
      bidAmount: 3,
      amount: "+9,000,000",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      discountRate: 3,
      funderId: "Admin",
      invoiceAmount: 32164082.78,
      status: "Completed",
      invoiceDate: "Admin",
      invoiceId: "65dfc0942b607753910b022e",
      transactionId: "2023-02-19",
      lockedBy: "Admin",
      lockedDate: "2024-03-07",
      paymentDate: "3 of 3",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Invoice",
      supplierId: "View Details",

      currency: "2023-09-04",
    },

    {
      bidAmount: 4,
      amount: "-4,000,000",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      discountRate: 3,
      funderId: "Admin",
      invoiceAmount: 32164082.78,
      status: "Declined",
      invoiceDate: "Admin",
      invoiceId: "65dfc0942b607753910b022e",
      transactionId: "2023-02-19",
      lockedBy: "Admin",
      lockedDate: "2024-03-07",
      paymentDate: "3 of 3",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Invoice",
      supplierId: "View Details",

      currency: "2023-09-04",
    },

    {
      bidAmount: 5,
      amount: "+9,000,000",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      discountRate: 3,
      funderId: "Admin",
      invoiceAmount: 32164082.78,
      status: "Completed",
      invoiceDate: "Admin",
      invoiceId: "65dfc0942b607753910b022e",
      transactionId: "2023-02-19",
      lockedBy: "Boss",
      lockedDate: "2024-03-07",
      paymentDate: "3 of 3",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Invoice",
      supplierId: "View Details",

      currency: "2023-09-04",
    },

    {
      bidAmount: 6,
      amount: "-4,000,000",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      discountRate: 3,
      funderId: "Admin",
      invoiceAmount: 32164082.78,
      status: "Declined",
      invoiceDate: "Admin",
      invoiceId: "65dfc0942b607753910b022e",
      transactionId: "2023-02-19",
      lockedBy: "Admin",
      lockedDate: "2024-03-07",
      paymentDate: "3 of 3",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Invoice",
      supplierId: "View Details",

      currency: "2023-09-04",
    },
    {
      bidAmount: 7,
      amount: "-4,000,000",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      discountRate: 3,
      funderId: "Admin",
      invoiceAmount: 32164082.78,
      status: "Completed",
      invoiceDate: "Admin",
      invoiceId: "65dfc0942b607753910b022e",
      transactionId: "2023-02-19",
      lockedBy: "Admin",
      lockedDate: "2024-03-07",
      paymentDate: "3 of 3",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Invoice",
      supplierId: "View Details",

      currency: "2023-09-04",
    },
  ];

  const totalPages = dummyData.length;

  const columns = [
    {
      name: "Date",
      selector: (row) => row.transactionId,
    },
    {
      name: "Transaction Type ",
      selector: (row) => row.funder,
    },

    {
      name: "Final Approver Name",
      selector: (row) => row.invoiceDate,
    },
    {
      name: "Approval Level",
      selector: (row) => row.paymentDate,
    },

    {
      name: "Final Approval Date",
      selector: (row) => row.currency,
    },

    {
      name: "Status",
      selector: (row) => row.status,
      cell: (row) =>
        row.status === "Completed" ? (
          <p className="text-[#07593D]">{row.status}</p>
        ) : row.status === "Declined" ? (
          <p className="text-[#BB0C0C]">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        ),
    },

    {
      name: "",
      selector: (row) => row.supplierId,
    },
  ];
  return (
    <div>
      <AnimatePresence>
        <Card className="rounded-[10px] mt-[24px]   bg-black">
          <div className="flex justify-between items-center px-[24px]   rounded-t-[10px]  ">
            <div className="flex gap-2 my-6">
              {/* <div class="text-black text-xs font-medium ">Request History</div> */}
              <div className="text-black text-[13px] font-medium items-center justify-center h-[23px]  flex ">
                Request History
              </div>
            </div>
          </div>
          <div className="px-[24px] ">
            <Table
              columns={columns}
              data={dummyData}
              onRowClicked={(row) => handleRowClick(row)}
              pointer
              tableHeader
            />
          </div>

          <div>
            {totalPages > 10 && (
              <div className="flex justify-center gap-x-[8px]  mt-5">
                <Pagination
                  page={page}
                  startPage={startPage}
                  setEndPage={setEndPage}
                  endPage={endPage}
                  setStartPage={setStartPage}
                  setPage={setPage}
                  totalPages={totalPages}
                />
              </div>
            )}
          </div>
        </Card>
      </AnimatePresence>
    </div>
  );
};

export default RequestsHistory;
