import { PAIconPayEdgeLogo } from "assets/images/svgs";
import React from "react";

const Test = () => {
  return (
    <div className="h-[80vh] flex items-center justify-center mt-5">
      <div className="bg-white shadow-md px-10 py-5 text-center md:w-1/2">
        <div className="mb10 flex items-center justify-center">
          <PAIconPayEdgeLogo />
        </div>
        <div className="mt-9 text-left text-black font-inter font-semibold text-lg">
          <p>Test Email Template</p>
        </div>
        <div className="border border-gray mt-2 mb-5"></div>
        <div className="text-black font-inter text-sm font-normal leading-normal tracking-wide text-left mb2">
          <p>
            Kindly provide your email address to receive the email template for
            testing purposes.
          </p>
        </div>
        <div class="my-7">
          <input
            type="email"
            id="email"
            name="email"
            class="mt-1 p-2 block w-full rounded-md border border-green-500 focus:ring focus:ring-indigo-200 focus:border-green-500"
            placeholder="Email your email"
          />
        </div>
        <button className="flex items-start justify-start mb-20 rounded-md bg-[#07593D] p-3 text-white text-center font-inter text-sm font-normal leading-normal tracking-wide">
          Send Template
        </button>
      </div>
    </div>
  );
};

export default Test;
