import { PAIconArrowLeftGreen } from "assets/images/svgs";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import UpdateBillsModal from "../../modals/UpdateBillsModal";
import OngoingAcceptUpdateBidModal from "../../modals/AccepUpdateBidModal";
import InvoiceLockedModal from "../../modals/InvoiceLockedModal";
import OngoingCancelBidModal from "../../modals/OngoingCancelBidModal";
import OngoingBidCancelledSuccessModal from "../../modals/OngoingBidsCancelSuccessful";
import DashboardLayout from "layout/dashboardlayout";
import MoreInvoiceIdSegment from "./MoreInvoiceIdSegment";
import Table from "layout/table/Table";
import TransitionScale from "shared/animation/TransitionScale";

const OngoingBidBulkDetails = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ongoingAcceptUpdateBidModal, setOngoingAcceptUpdateBidModal] =
    useState(false);
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);
  const [ongoingCancelBidModal, setOngoingCancelBidModal] = useState(false);
  const [ongoingBidCancelledSuccessModal, setOngoingBidCancelledSuccessModal] =
    useState(false);
  const goBack = () => {
    navigate(-1);
  };
  const handleOpenUpdateBid = () => {
    setIsModalOpen(true);
  };

  const handleUpdateBid = () => {
    setIsModalOpen(false);
    setOngoingAcceptUpdateBidModal(true);
  };

  const handleAccept = () => {
    setIsInvoiceModalOpen(true);
    setOngoingAcceptUpdateBidModal(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    // setRejectModalOpen(false);
  };
  const handleInvoiceModalClose = () => {
    setIsInvoiceModalOpen(false);
    goBack();
  };
  const handleOnCancelSuccessClose = () => {
    setOngoingBidCancelledSuccessModal(false);
    goBack();
  };
  const handleOnCancelBidTrue = () => {
    setOngoingCancelBidModal(false);
    setOngoingBidCancelledSuccessModal(true);
  };

  const columns = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Invoice Number
        </span>
      ),
      selector: (row) => row.invNum,
    },

    {
      name: "Due Date",
      selector: (row) => row.dueDate,
    },
    {
      name: "Invoice Amount",
      selector: (row) => row.requestAmount,
    },
    {
      name: "Discount Rate",
      selector: (row) => row.discountRate,
    },
  ];

  const tableData = [
    {
      bidAmount: 1,
      invNum: "INV-2090 ",
      dueDate: "2023-05-23",
      requestAmount: "9,000,000",
      discountRate: "1.5%",
      receivableAmount: "9,000,000",
    },
    {
      bidAmount: 2,
      invNum: "INV-2090 ",
      dueDate: "2023-05-23",
      requestAmount: "9,000,000",
      discountRate: "1.5%",
      receivableAmount: "9,000,000",
    },

    {
      bidAmount: 3,
      invNum: "INV-2090 ",
      dueDate: "2023-05-23",
      requestAmount: "9,000,000",
      discountRate: "1.5%",
      receivableAmount: "9,000,000",
    },
    {
      bidAmount: 4,
      invNum: "INV-2090 ",
      dueDate: "2023-05-23",
      requestAmount: "9,000,000",
      discountRate: "1.5%",
    },
  ];
  const navigateToDetail = (id) =>
    navigate(
      `/payablefinancing-closedmarket/funderclosedmarketbuldetailspage/${id}`
    );

  return (
    <DashboardLayout
      section="Closed Market"
      subHeader="View invoices uploaded to the closed market by your associated buyers"
    >
      <>
        <UpdateBillsModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          handleYes={handleUpdateBid}
        />
        <OngoingAcceptUpdateBidModal
          isOpen={ongoingAcceptUpdateBidModal}
          onClose={() => setOngoingAcceptUpdateBidModal(false)}
          refNum={"R3456X89Y"}
          handleYes={handleAccept}
        />
        <InvoiceLockedModal
          isOpen={isInvoiceModalOpen}
          onClose={handleInvoiceModalClose}
        />
        <OngoingCancelBidModal
          isOpen={ongoingCancelBidModal}
          onClose={() => setOngoingCancelBidModal(false)}
          refNum={"R3456X89Y"}
          handleYes={handleOnCancelBidTrue}
        />
        <OngoingBidCancelledSuccessModal
          isOpen={ongoingBidCancelledSuccessModal}
          onClose={handleOnCancelSuccessClose}
          message={"Your bid has been cancelled"}
        />
        <Link
          onClick={goBack}
          className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer w-full"
        >
          <PAIconArrowLeftGreen />
          <span className="flex items-center text-primaryColor">Go Back</span>
        </Link>

        <div className="grid lg:grid-cols-[60%,40%] gap-5">
          <TransitionScale className=" pb-5 bg-white  rounded-[10px]  pt-2">
            {/* Header with Left Padding */}

            {/* Other sections */}

            <section className="  mt-3">
              {/* Down Flex Texts */}
              <div className=" flex justify-between px-6  mt-4">
                {" "}
                <div class="text-[#222222] text-lg font-medium ">
                  Reference No: | RF83018374BA
                </div>
                <div class="h-[37px] p-2.5 rounded-[5px] border border-[#f08000]/20 justify-center items-center gap-2.5 inline-flex">
                  <div class="text-[#f08000] text-sm font-medium ">
                    Bid Active
                  </div>
                </div>
              </div>
              <div className=" flex justify-between px-6">
                {/* first stage */}

                <div class="lg:w-[240px]  sm:w-full h-[117px] flex-col justify-start items-start gap-3 inline-flex  mt-5">
                  <div class="self-stretch  flex-col justify-center items-start gap-1 flex ">
                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Created Date:
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        23-Mar -2023
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex ">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Due Date:
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        30-May-2023
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Total Request amount
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        24,000,000
                      </div>
                    </div>

                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Total Invoice amount
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        34,000,000
                      </div>
                    </div>
                  </div>

                  {/* stop */}
                </div>
                {/* second stage */}

                <div className=" mt-5">
                  {" "}
                  <div class="w-[107px] flex-col justify-end items-end gap-1 inline-flex">
                    <div class="text-right text-[#222222] text-base font-bold ">
                      N23,000,000
                    </div>
                    <div class="text-[#979797] text-xs font-normal ">
                      Total request amount
                    </div>
                  </div>
                </div>
              </div>

              {/* stops here */}
              <div className="  px-6  ">
                <header className="border-b-[5px] border-b-[#F6F5FA] mt-3  flex justify-between py-1">
                  {/* <h1 className="text-[18px] font-semibold">
                  Supplier/buyer details
                </h1> */}
                  <div>
                    <div class="text-[#222222] text-lg font-semibold  ">
                      Nestle PLC
                    </div>
                    <div class="text-[#7a7a7a] text-xs font-light  ">
                      Buyer details
                    </div>
                  </div>

                  <div class="h-[41px] flex-col justify-start items-start  inline-flex px-12">
                    <div class="text-[#222222] text-base font-semibold ">
                      60 Days
                    </div>
                    <div class="w-[100px] text-[#7a7a7a] text-xs font-light  mt-1">
                      Payment
                    </div>
                  </div>

                  <div class="h-[43px] flex-col justify-start items-end  inline-flex">
                    <div class="text-[#222222] text-lg font-semibold ">NGN</div>
                    <div class="text-[#7a7a7a] text-xs font-semibold mt-1">
                      Currency
                    </div>
                  </div>
                </header>

                <header className="border-b-[5px] border-b-[#F6F5FA] mt-6  flex justify-between py-1">
                  {/* <h1 className="text-[18px] font-semibold">
                  Supplier/buyer details
                </h1> */}
                  <div class="text-[#11192a] text-sm font-semibold ">
                    Invoice List
                  </div>
                  <div class="text-[#11192a] text-lg font-light  mr-6">
                    4 Invoices
                  </div>
                </header>

                {/* <div className=" w-full overflow-x-auto mt-3 pb-4"></div> */}

                <div className=" w-full overflow-x-auto mt-3 ">
                  <Table
                    columns={columns}
                    data={tableData}
                    pointer
                    className=""
                    onRowClicked={(row) => navigateToDetail(row.bidAmount)}
                    progressPending={false}
                    // progressComponent={<Loading />}
                  />
                </div>
                {/* Calculate cost and price  shaded or gray section */}
              </div>
            </section>
          </TransitionScale>

          <div className="bg-white rounded-[10px] gap-8 ">
            {/* <FundDetailSide /> */}
            {/* second */}
            <div className=" py-8 px-6 border-b border-[#c1c1c1]/60">
              {/* first flebox */}
              <div className=" flex flex-row justify-between w-full">
                <div class="text-black text-xl font-medium ">Bid Invoice</div>
                {/* <button
                class=" p-2.5 bg-white rounded-[5px] border border-[#bb0c0c] justify-center items-center gap-2 inline-flex"
                onClick={() => setOngoingCancelBidModal(true)}
              >
                <div className=" border-r-[1.5px] flex gap-3  pr-2">
                  {" "}
                  <div class="text-[#bb0c0c] text-sm font-medium items-center ">
                    Cancel
                  </div>
                  <div class="justify-center items-center flex">
                    <div class=" relative">
                      <PAIconLockCloseIcon />
                    </div>
                  </div>
                </div>
                <div class="justify-start items-center gap-2 flex">
                  {/* <div class="w-[2px] h-full border border-[#222222]"></div> */}
                {/* <div class="text-[#222222] text-sm font-normal ">
                    00h:56m:23s
                  </div>
                </div>
              </button>  */}
              </div>

              {/* button section */}

              <button
                class="h-[47px] px-2.5 py-[15px] bg-[#07593d] rounded-[5px] justify-center items-center gap-2.5 inline-flex mt-8 w-full cursor-pointer"
                onClick={handleOpenUpdateBid}
              >
                <div class="text-white text-base font-medium ">Update Bid</div>
              </button>
            </div>

            {/* Last section */}
            <div className="py-8  gap-8 flex flex-col ">
              {/*  Winning Bid section */}
              {/* Your bid seCTION */}
              <MoreInvoiceIdSegment />
              {/* stop it */}
            </div>

            {/* search section */}

            {/* stops here */}
          </div>
        </div>
      </>
    </DashboardLayout>
  );
};

export default OngoingBidBulkDetails;
