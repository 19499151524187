import { PAIconArrowLeftGreen } from "assets/images/svgs";
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import RemoveFromCloseNarketModal from "../RemoveFromCloseNarketModal";
import { closedMarketViewBulkDetails } from "appstate/buyer/buyerSlice";
import BidDetails from "./BidDetails";
import { formatCapitalize } from "utilities/formatCapitalize";
import { formatCurrency } from "utilities/helper/formatters";

const DetailsBulk = () => {
  const { id } = useParams();
  const [isRejectModalOpen, setRejectModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { closedMarketViewBulkDetailsData, closedMarketViewSingleDetailsData } =
    useSelector((state) => state?.buyer);
  const totalRequestAmount =
    closedMarketViewBulkDetailsData?.data?.invoiceDetails?.reduce(
      (total, invoice) => {
        return total + invoice?.requestAmount;
      },
      0
    ) || 0;

  useEffect(() => {
    dispatch(closedMarketViewBulkDetails(id));
  }, [dispatch, id]);

  useEffect(() => {
    setData(closedMarketViewBulkDetailsData?.data);
  }, [closedMarketViewBulkDetailsData?.data]);

  const goBack = () => {
    navigate(-1);
  };

  const handleCloseModal = () => {
    setRejectModalOpen(false);
  };

  return (
    <>
      <Link
        onClick={goBack}
        className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer w-full"
      >
        <PAIconArrowLeftGreen />
        <span className="flex items-center text-primaryColor">Go Back</span>
      </Link>

      <div className="grid lg:grid-cols-[60%,40%] gap-5">
        <RemoveFromCloseNarketModal
          isOpen={isRejectModalOpen}
          onClose={handleCloseModal}
        />

        <div className="flex flex-col gap-8 pb-24 bg-white rounded-[10px] pt-7">
          <div className="px-6 ">
            <div className="flex flex-col gap-3">
              <div className="flex items-center justify-between">
                <h1 className="text-[18px] font-bold">
                  Reference No: |{" "}
                  {closedMarketViewBulkDetailsData?.data?.requestRef}
                </h1>

                <div>
                  <button
                    style={{ border: "1px solid rgba(7, 89, 61, 0.20)" }}
                    className="p-2.5 rounded-[5px] text-sm text-primaryColor"
                  >
                    {formatCapitalize(
                      closedMarketViewBulkDetailsData?.data?.status
                    )}
                  </button>
                </div>
              </div>

              <main className=" w-full flex justify-between">
                <div className="flex flex-col gap-3 w-1/2">
                  <div className="flex flex-col gap-1">
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs font-semibold w-[40%]">
                        Request Date:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {
                          closedMarketViewBulkDetailsData?.data
                            ?.earlyPaymentDate
                        }
                      </span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className="whitespace-nowrap text-xs font-semibold w-[40%]">
                        Total Request amount
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {formatCurrency(totalRequestAmount)}
                      </span>
                    </div>

                    <div className="flex gap-x-[50px]">
                      <span className="whitespace-nowrap text-xs font-semibold w-[40%]">
                        Total Invoice amount
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {formatCurrency(
                          closedMarketViewBulkDetailsData?.data
                            ?.totalInvoiceAmount
                        ) || "N/A"}
                      </span>
                    </div>
                  </div>
                </div>
              </main>
            </div>

            <div className="flex justify-between my-5">
              <div className="flex flex-col">
                <span className=" font-bold text-sm">
                  {closedMarketViewSingleDetailsData?.data?.buyerCompanyName ||
                    "N/A"}
                </span>
                <span className="text-[#979797] text-xs">Buyer Details</span>
              </div>
              {/* <div className="flex flex-col">
                <span className="font-bold text-sm">
                  {closedMarketViewSingleDetailsData?.data?.paymentTerms} Days
                </span>
                <span className="text-[#979797] text-xs">Payment</span>
              </div> */}
              <div className="flex flex-col">
                <span className="font-bold text-sm">
                  {closedMarketViewSingleDetailsData?.data?.currency || "NGN"}
                </span>
                <span className=" text-[#979797] text-xs">Currency</span>
              </div>
            </div>

            <div className="flex flex-col gap-4 mt-5">
              <header className="border-b-[5px] border-b-[#F6F5FA] pb-3 flex items-center justify-between">
                <h1 className="text-sm font-bold">Line Items</h1>
                <h1 className="text-sm font-regular">
                  {
                    closedMarketViewBulkDetailsData?.data?.invoiceDetails
                      ?.length
                  }{" "}
                  Total Item(s)
                </h1>
              </header>
              <div className="w-full overflow-x-auto h-[30vh]">
                <table className="overflow-x-auto w-full">
                  <thead className="bg-subtleGray h-[70px] overflow-x-auto">
                    <tr className=" bg-unusualGray p-2.5">
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Ref Number
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Due Date
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Request Amount
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Discount Rate
                      </td>
                      <td className="text-[#11192A] p-2.5 text-xs font-bold w-[120px]">
                        Discount Amount
                      </td>
                    </tr>
                  </thead>
                  {closedMarketViewBulkDetailsData?.data?.invoiceDetails?.map(
                    (item, index) => (
                      <tbody key={index}>
                        <tr className=" py-[3px] px-1.5">
                          <td className=" text-xs text-[#11192A] p-2.5">
                            {item?.invoiceRef}
                          </td>
                          <td className=" text-xs text-[#11192A] p-2.5 w-[20%]">
                            {item?.requestDate}
                          </td>
                          <td className=" text-xs text-[#11192A] p-2.5">
                            {item?.requestAmount?.toLocaleString()}
                          </td>
                          <td className=" text-xs text-[#11192A] p-2.5">
                            {item?.discountRate || "-"}%
                          </td>
                          <td className=" text-xs text-[#11192A] p-2.5">
                            {item?.discountedAmount}
                          </td>
                        </tr>
                      </tbody>
                    )
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-[10px] gap-8  ">
          <BidDetails data={data} />
        </div>
      </div>
    </>
  );
};

export default DetailsBulk;
