import request from "apiInstance";
import toast from "react-hot-toast";
export const createTransPin = async (body) => {
  try {
    const response = await request({
      method: "post",
      url: `/api/v1/account/setTransactionPin`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
