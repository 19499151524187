import { PAIconInventoryArrowLeft } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Card from "shared/Card";

import Table from "layout/table/Table";

import AcceptModal from "shared/AcceptModal";
// import FundAccountOption from "./components/modals/SelectAccountModal";
import FundAccountOption from "features/wallet(new)/overview/FundAccount/ExternalSource";

import { useParams } from "react-router-dom";
import { fetchSinglePendingRequestById } from "appstate/workflow/workflowSlice";
// detail/pendingrequestsdetailssignpfp/:workflowId
import { viewPfpById } from "appstate/funder/funderSlice";
import TransitionScale from "shared/animation/TransitionScale";

const PendingRequestsSignPfpdetails = () => {
  // const [mailSentModal, setMailSentModal] = useState(false);
  const [isFundAccountOptionOpen, setIsFundAccountOptionOpen] = useState(false);
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [, setFundAccountPinModal] = useState(false);

  const { workflowId } = useParams();

  const [selectedInvoicesTotalAmount] = useState(0);
  const selectedAccounts = useSelector(
    (state) => state?.wallet?.selectedAccounts
  );

  const dispatch = useDispatch();

  // const details = allFunderPfpData?.data?.find((data) => data?.pfpId === pfpId);

  const { singleDetailPendingRequest } = useSelector((state) => state.workflow);
  useEffect(() => {
    dispatch(fetchSinglePendingRequestById({ id: workflowId }));
  }, [dispatch, workflowId]);

  const navigate = useNavigate();
  function handleGoback() {
    let path = -1;
    navigate(path);
  }

  const handleAccountSubmit = () => {
    setIsFundAccountOptionOpen(false);
    setIsAcceptModalOpen(true);
  };

  const AcceptModalConfirm = () => {
    setIsAcceptModalOpen(false);
    setFundAccountPinModal(true);
  };

  const { viewPfpByIdData } = useSelector((state) => state?.funder);

  const details = viewPfpByIdData?.data || {};

  useEffect(() => {
    if (singleDetailPendingRequest?.data?.payload?.id) {
      dispatch(
        viewPfpById({ id: singleDetailPendingRequest?.data?.payload?.id })
      );
    }
  }, [singleDetailPendingRequest?.data?.payload?.id, dispatch]);

  const columns = [
    {
      name: "Invoice Reference",
      selector: (row) => row.invoiceReference,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
    },
    {
      name: "Due Date",
      selector: (row) => row.dueDate,
      sortable: true,
    },
    {
      name: "Payment Date",
      selector: (row) => row.paymentDate,
      sortable: true,
    },
    {
      name: "Invoice Amount",
      selector: (row) =>
        Number(row.invoiceAmount).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      sortable: true,
    },
    {
      name: "Offer Amount",
      selector: (row) =>
        Number(row.offerAmount).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      sortable: true,
    },
    {
      name: "Funder Share",
      selector: (row) =>
        Number(row.funderShare).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      sortable: true,
      cell: (row) => (
        <p className="text-primaryColor">
          {Number(row.funderShare).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </p>
      ),
    },
    {
      name: "Fundabale Amount",
      selector: (row) =>
        Number(row.fundableAmount).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      sortable: true,
      cell: (row) => (
        <p className="text-primaryColor">
          {Number(row.fundableAmount).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </p>
      ),
    },

    {
      name: "Status",
      selector: (row) => (
        <span
          style={{
            color:
              row.status === "ACCEPTED"
                ? "green"
                : row.status === "ACTIVE"
                ? "#90EE90"
                : row.status === "REJECTED"
                ? "red"
                : "orange",
            fontWeight: "bold",
          }}
        >
          {row.status}
        </span>
      ),
      sortable: true,
    },
    {
      // name: "Action",
      cell: (row) => (
        <button
          className={`py-2 px-4 rounded text-white ${
            row.status === "ACTIVE"
              ? "bg-green-900"
              : "bg-gray300 cursor-not-allowed"
          }`}
          disabled={row.status !== "ACTIVE"}
        >
          Fund
        </button>
      ),
    },
  ];

  let path = "/payablefinancing-campaign/funder-payablefinance-view-details";

  return (
    <DashboardLayout
      section="Workflow "
      subHeader="Manage all your workflow approvals"
    >
      <div className="min-h-full">
        <div
          onClick={handleGoback}
          className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
        >
          <PAIconInventoryArrowLeft />
          <p className="mt-[2px] text-sm font-normal text-lightGray">Go Back</p>
        </div>

        <TransitionScale className="px-6 pt-6 pb-8 bg-white  rounded-[10px] mt-5  mb-5">
          <div className="lg:flex md:flex justify-between  w-full sm:block  ">
            <div>
              {" "}
              <div class="text-[#11192a] text-xs font-bold ">
                Approval Stage
              </div>
            </div>
          </div>

          <div className="lg:flex md:flex justify-between  w-full sm:block  mt-3 ">
            {/* underlined div */}
            <div className="">
              <div>
                <span className="text-[#7a7a7a] text-xs font-bold ">User</span>
                <span className="text-[#7a7a7a] text-xs font-normal ">
                  {" "}
                  to approve
                </span>
              </div>
              <div className="mb-[15px] mt-[10px] flex justify-center items-center gap-x-[15px] ">
                <div>
                  <div className="w-[100px] h-1   bg-[#F08000]  rounded-[100px] lg:w-[150px]  xs:w-[70px]"></div>
                </div>
                <div>
                  <div className="w-[100px] h-1    bg-[#F08000]/30  rounded-[100px] lg:w-[150px]  xs:w-[70px]"></div>
                </div>
                <div>
                  <div className="w-[100px] h-1  bg-[#F08000]/30  rounded-[100px] lg:w-[150px] xs:w-[70px] "></div>
                </div>
              </div>
            </div>

            {/* Button division */}

            <div>
              {" "}
              {/* <div class="h-[30px] px-8 py-2 bg-[#07593d]/30 rounded-[5px] justify-center items-center gap-2.5 inline-flex">
                  <div class="text-center text-[#07593d] text-xs font-semibold">
                    Completed
                  </div>
                </div> */}
            </div>
          </div>

          {/* Second Box phase and pages */}
          <div className="lg:flex md:flex justify-between  w-full sm:block  mt-6 ">
            <div>
              <div class=" flex-col justify-center items-start gap-2 inline-flex">
                <div class="self-stretch justify-between items-center inline-flex gap-28">
                  <div class="text-[#222222] text-[14px] font-semibold">
                    Date Sent:
                  </div>
                  <div class="text-[#7a7a7a] text-[14px] font-medium ">
                    {singleDetailPendingRequest?.data?.dateSent}
                  </div>
                </div>
                <div class="self-stretch justify-between items-center inline-flex gap-28">
                  <div class="text-[#222222] text-[14px] font-semibold">
                    Transaction Type:
                  </div>
                  <div class="text-[#7a7a7a] text-[14px] font-medium ">
                    {singleDetailPendingRequest?.data?.type}
                  </div>
                </div>
                <div class="self-stretch justify-between items-center inline-flex  gap-28">
                  <div class="text-[#222222] text-[14px] font-semibold">
                    Action:
                  </div>
                  <div class="text-[#7a7a7a] text-[14px] font-medium ">
                    {singleDetailPendingRequest?.data?.action}
                  </div>
                </div>
                <div class="self-stretch justify-between items-center inline-flex">
                  <div class="text-[#222222] text-[14px] font-semibold">
                    Approval Stage:
                  </div>
                  <div class="text-[#7a7a7a] text-[14px] font-medium ">
                    {singleDetailPendingRequest?.data?.approvalStage}
                  </div>
                </div>
              </div>
            </div>

            {/* Second side */}

            <div>
              <div class=" flex-col justify-center items-start gap-2 inline-flex">
                <div class="self-stretch justify-between items-center inline-flex gap-28">
                  <div class="text-[#222222] text-[14px] font-semibold">
                    Sender:
                  </div>
                  <div class="text-[#7a7a7a] text-[14px] font-medium ">
                    {singleDetailPendingRequest?.data?.sender}
                  </div>
                </div>
                <div class="self-stretch justify-between items-center inline-flex gap-28">
                  <div class="text-[#222222] text-[14px] font-semibold">
                    Previous Approver:
                  </div>
                  <div class="text-[#7a7a7a] text-[14px] font-medium ">
                    {singleDetailPendingRequest?.data?.previousApprover}
                  </div>
                </div>
                <div class="self-stretch justify-between items-center inline-flex  gap-28">
                  <div class="text-[#222222] text-[14px] font-semibold">
                    Next Approver:
                  </div>
                  <div class="text-[#7a7a7a] text-[14px] font-medium ">
                    {singleDetailPendingRequest?.data?.nextApprover}
                  </div>
                </div>
              </div>
              {/* Button */}
            </div>
          </div>
        </TransitionScale>
        <header className="bg-alabasterHeader px-6 py-4 rounded-t-[10px]">
          <div className="flex justify-between">
            <div className="flex flex-col  justify-center">
              <p className="text-black text-sm font-medium">Details</p>
              <p className="text-textColor text-xs font-normal">
                Information about the Payable finance program
              </p>
            </div>
          </div>
        </header>

        <Card className=" min-h-[80%] md:px-[40px] pb-[40px] rounded-b-[10px] ">
          <div className="">
            <div className="md:flex md:justify-between  pt-[24px] 2xl:justify-start 2xl:gap-x-[370px] ">
              {/* Here */}
              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-2">
                  <p className="text-sm font-normal text-lightGray">
                    Name of Program
                  </p>
                  <p className="text-textColor text-sm font-normal">
                    {(singleDetailPendingRequest?.data?.payload?.programName &&
                      singleDetailPendingRequest?.data?.payload?.programName) ||
                      "Payable Flex"}
                  </p>
                </div>

                <div className="flex flex-col gap-2">
                  {" "}
                  <p className="text-sm font-normal text-lightGray">
                    Discount Rate
                  </p>
                  <p className=" text-textColor text-sm font-normal">
                    {singleDetailPendingRequest?.data?.payload?.discountRate &&
                      singleDetailPendingRequest?.data?.payload
                        ?.discountRate}{" "}
                    %
                  </p>
                </div>

                <div className="flex flex-col gap-2">
                  <p className="text-sm font-normal text-lightGray">Duration</p>

                  <div className="flex flex-row">
                    <span className="text-[#BFBFBF] mr-1 font-bold text-sm">
                      From:
                    </span>
                    <span className="text-textColor text-sm font-normal">
                      {singleDetailPendingRequest?.data?.payload?.startDate &&
                        singleDetailPendingRequest?.data?.payload
                          ?.startDate}{" "}
                    </span>

                    <span className="text-[#BFBFBF] mr-1 font-bold ml-2 text-sm">
                      To:
                    </span>
                    <span className="text-textColor text-sm font-normal">
                      {singleDetailPendingRequest?.data?.payload?.endDate}
                    </span>
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-2">
                  <p className="text-sm text-[#7A7A7A]">Terms of Agreement</p>
                </div>

                <div className="flex flex-col gap-2">
                  <p className="text-sm text-[#7A7A7A]">Payment Period</p>
                  <p className="cursor-pointer text-lightGray text-sm font-normal">
                    {singleDetailPendingRequest?.data?.payload?.paymentDuration}{" "}
                    Days
                  </p>
                </div>

                <div className="flex flex-col gap-2">
                  <p className="text-sm text-[#7A7A7A]">Percentage Offer</p>
                  <p className="cursor-pointer text-lightGray text-sm font-normal">
                    {singleDetailPendingRequest?.data?.payload?.offerPercentage}
                  </p>
                </div>

                <div className="flex flex-col gap-2">
                  <span className="text-sm text-[#7A7A7A]">
                    Number of Funders in Program
                  </span>
                  <p className="cursor-pointer text-sm font-normal">
                    {details?.noOfFunders}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Here */}
          <div className="mt-[48px] flex flex-col gap-6">
            <div className=" w-full flex justify-between">
              <span className="text-sm gap-2.5 font-semibold md:justify-start text-textColor flex items-center">
                Number of Invoices
                <span className="w-[25px] h-[25px] flex justify-center items-center bg-conch  rounded-[50%]">
                  {details?.invoices?.length}
                </span>
              </span>

              <Link
                to={path}
                className=" text-sm underline text-[#222] cursor-pointer"
              >
                View Invoices
              </Link>
            </div>

            <div className="mt-[24px] pr-[30px]">
              <Table
                columns={columns}
                // data={dummyData}
                // data={singleDetailPendingRequest?.data?.payload?.invoices?.slice(
                //   0,
                //   5
                // )}
                data={details?.invoices}
                // onRowClicked={onRowClicked}

                pointer
                tableHeader
                className="min-h-[35vh]"
              />
            </div>
          </div>
        </Card>
      </div>

      <FundAccountOption
        isOpen={isFundAccountOptionOpen}
        onClose={() => setIsFundAccountOptionOpen(false)}
        HeaderText={
          <p>
            Select which account to fund with <br />{" "}
            <span className="text-xs text-center font-base">
              Amount of invoice: NGN{" "}
              {selectedInvoicesTotalAmount.toLocaleString()}
            </span>
          </p>
        }
        onSubmit={handleAccountSubmit}
      />

      <AcceptModal
        isOpen={isAcceptModalOpen}
        onClose={() => setIsAcceptModalOpen(false)}
        message={
          <>
            <p className="mb-3">
              Total invoice amount:{" "}
              <span className="text-primaryColor font-bold">
                {" "}
                NGN {selectedInvoicesTotalAmount.toLocaleString()}{" "}
              </span>
            </p>
            Would you like to proceed to fund the invoice with <br /> this
            account{" "}
            <span className="text-primaryColor font-bold">
              {selectedAccounts?.accountNumber}
            </span>{" "}
            ?
          </>
        }
        onConfirm={AcceptModalConfirm}
        fund
      />
    </DashboardLayout>
  );
};

export default PendingRequestsSignPfpdetails;
