import { PAIconInventoryArrowLeft } from "assets/images/svgs";
import { motion } from "framer-motion";
import DashboardLayout from "layout/dashboardlayout";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const SupplierEarlyPaymentBulkDetailsScreen = () => {

  const location = useLocation();
  const row = location.state?.row;
  const state  = location?.state || {};

  const invoiceData = {
    status: "Approved",
    buyerCompanyName: "Dummy Buyer Corp",
    items: [
      {
        itemName: "Product A",
        description: "Description for Product A",
        unitPrice: 100,
        quantity: 5,
        amount: 500,
        taxRate: "10%",
        taxAmount: 50,
        totalPrice: 550,
      },
      {
        itemName: "Product B",
        description: "Description for Product B",
        unitPrice: 200,
        quantity: 2,
        amount: 400,
        taxRate: "10%",
        taxAmount: 40,
        totalPrice: 440,
      },
    ],
  };


  const navigate = useNavigate();
  function handleNavigate() {
    let path = -1;
    navigate(path);
  }

  return (
    <DashboardLayout
      section="Invoice"
      subHeader="Here is an overview of your invoices"
    >
      {/* <SingleRequestModal
        data={invoiceData}
        isShown={open}
        directFunding={directFunding}
        onClose={() => setOpen(false)}
      />
        <DirectRequestModal
        data={invoiceData}
        isShown={showDirectModal}
        directFunding={directFunding}
        onClose={() => setShowDirectModal(false)}
      /> */}
      <motion.section
        initial={{
          x: 20,
        }}
        animate={{
          x: 0,
        }}
        exit={{
          x: -20,
        }}
        transition={{
          delay: 0.6,
        }}
        className="flex flex-col gap-8  pb-24  bg-white rounded-[10px]"
      >
        <header className="px-6 border-b pt-[17px] rounded-t-[10px] border-[#B1A6B2] pb-[14px] bg-alabasterHeader">
          <div className="flex justify-between">
            <div
              onClick={handleNavigate}
              className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
            >
              <PAIconInventoryArrowLeft />
              <p className="mt-[2px] text-sm font-normal text-lightGray">
                Go Back
              </p>
            </div>
          </div>
        </header>
        <div className="px-6">
          <div className=" flex  flex-col gap-3 ">
            <header>
              <div className="flex justify-between">
                <div>
              <h1 className=" text-[18px] font-light">
                Invoice | {row?.invoiceReference}
              </h1>
              </div>
              <div className="flex justify-between items-center">
            </div>
              </div>
            </header>
            <main className=" w-full flex justify-between">
              <div className="flex flex-col gap-3 w-1/2">
                <div className="flex gap-x-[50px]">
                  <span>Reference No:</span>
                  <span>0910101010101</span>
                </div>
                <div className="flex flex-col gap-1">
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Created Date:</span>
                    <span className="text-[#979797]  text-xs">
                      {row?.invoiceDate}
                    </span>
                  </div>
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Submitted Date:</span>
                    <span className="text-[#979797]  text-xs">
                      {row?.invoiceDate}
                    </span>
                  </div>
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Approved Date:</span>
                    <span className="text-[#979797]  text-xs">
                      {row?.invoiceDate}
                    </span>
                  </div>
                  <div className="flex gap-x-[97px]">
                    <span className=" text-xs">Due Date:</span>
                    <span className="text-[#979797]  text-xs">
                      {row?.dueDate}
                    </span>
                  </div>
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Issuance Date:</span>
                    <span className="text-[#979797]  text-xs">
                      {row?.invoiceDate}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-between">
              {state === "Rejected" && (
                <div className="flex flex-col justify-between">
                  <motion.button
                    whileTap={{ scale: 0.9 }}
                    className="flex bg-[#FAD9D9] text-sm justify-center gap-2 text-[#DE1515] rounded-[5px] items-center px-[35px] py-1.5 "
                  >
                    Rejected
                  </motion.button>
                </div>
              )}

              {state === "Approved" && (
                <div className="flex flex-col justify-between">
                  <motion.button
                    whileTap={{ scale: 0.9 }}
                    className="flex bg-[#D9EFD6] text-sm justify-center gap-2 text-[#59BB50] rounded-[5px] items-center px-[35px] py-1.5 "
                  >
                    Approved
                  </motion.button>
                </div>
              )}

              {state === "Processing" && (
                <div className="flex flex-col justify-between">
                  <motion.button
                    whileTap={{ scale: 0.9 }}
                    className="flex bg-[#C7D7FF] text-sm justify-center gap-2 text-[#6994FF] rounded-[5px] items-center px-[35px] py-1.5 "
                  >
                    Processing
                  </motion.button>
                </div>
              )}

              {state === "Pending Approval" && (
                <div className="flex flex-col justify-between">
                  <motion.button
                    whileTap={{ scale: 0.9 }}
                    className="flex bg-[#FFBE5557] text-sm justify-center gap-2 text-[#F08000] rounded-[5px] items-center px-[35px] py-1.5 "
                  >
                    Pending Approval
                    {/* <PAIconLockYellow /> */}
                  </motion.button>
                </div>
              )}
              </div>
            </main>
          </div>
          <br />
          <div className="flex justify-between">
            <div className="flex flex-col">
              <span className="font-light  text-xs">{row?.buyer}</span>
              <span className="text-[#979797] text-sm">buyer details</span>
            </div>
            <div className="flex flex-col">
              <span className="font-light   text-xs">60 Days</span>
              <span className="text-[#979797] text-sm">Payment</span>
            </div>
            <div className="flex flex-col">
            <span className="text-[#979797] flex justify-end text-xs">NGN</span>
              <span className="font-light text-sm">Currency</span>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <header className="border-b-[5px] border-b-[#F6F5FA] pb-3"></header>
            <div className="flex  justify-between">
              <div className="flex gap-2"></div>
              <div className="flex gap-2">
                <div className="flex flex-col">
                  <span className="text-[#979797]  text-xs">5 total items</span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
              <h1 className="text-sm font-bold">Line Items</h1>
            </header>
            <div className=" w-full overflow-x-auto">
              <table className=" w-full overflow-x-auto">
                <thead className="bg-subtleGray w-">
                  <tr className=" bg-unusualGray p-2.5 ">
                    <td className="text-[#11192A] p-2.5 text-xs font-bold">
                      Item
                    </td>
                    <td className=" p-2.5 text-xs font-bold">Description</td>
                    <td className=" p-2.5 text-xs font-bold">Unit price</td>
                    <td className=" p-2.5 text-xs font-bold">Quantity</td>
                    <td className=" p-2.5 text-xs font-bold">
                      {"Amount(Before Tax)"}
                    </td>
                    <td className=" p-2.5 text-xs font-bold">Tax Rate</td>
                    <td className=" p-2.5 text-xs font-bold">Tax Amount</td>
                    <td className=" p-2.5 text-xs font-bold">Total Price</td>
                  </tr>
                </thead>
                {invoiceData?.items?.map((item, index) => (
                  <tbody key={index}>
                    <tr className=" py-[3px] px-1.5">
                      <td className=" text-xs text-[#11192A] p-2.5">
                        {item?.itemName}
                      </td>
                      <td className=" text-xs text-[#11192A] p-2.5">
                        {item?.description}
                      </td>
                      <td className=" text-xs text-[#11192A] p-2.5">
                        {item?.unitPrice}
                      </td>
                      <td className=" text-xs text-[#11192A] p-2.5">
                        {item?.quantity}
                      </td>
                      <td className=" text-xs text-[#11192A] p-2.5">
                        {item?.unitPrice}
                      </td>
                      <td className=" text-xs text-[#11192A] p-2.5">
                        {item?.taxRate}
                      </td>
                      <td className=" text-xs text-[#11192A] p-2.5">
                        {item?.taxAmount}
                      </td>
                      <td className=" text-xs text-[#11192A] p-2.5">
                        {item?.totalPrice}
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
            <div className="flex justify-between">
              <div>
                <h4 className="text-lg text-[#11192A] ">Receiving Funding Account Information</h4>
                <h5 className="text-lg text-[#11192A] ">7662662662626</h5>
                <h5 className="text-lg text-[#11192A] ">Praise Akindade</h5>
              </div>
              <div style={{width: 300}} >
                <div className="flex justify-between" >
                  <span className="text-sm text-[#11192A] ">Subtotal</span>
                  <span className="text-sm text-[#11192A] ">1000000</span>
                </div>
                <div className="flex justify-between">
                  <div >
                    <span className="text-sm text-[#11192A] ">Vat % </span>
                    <span className="pl-2 text-sm text-[#11192A] ">5.6%</span>
                  </div>
                  <div>
                    <span className="text-sm text-[#11192A] ">583,000</span>
                  </div>
                </div>
                <div className="flex justify-between">
                  <div>
                    <span className="text-sm text-[#11192A] ">Discount % </span>
                    <span className="pl-2 text-sm text-[#11192A] ">2.6%</span>
                  </div>
                  <div>
                    <span className="text-sm text-[#11192A] ">283,000</span>
                  </div>
                </div>
                <div>
                <hr className="border-t border-[black] mt-2 pt-2" />
                  <div className="flex justify-between">
                    <span className="text-lg text-[#11192A] ">Grand Total</span>
                    <span className="text-lg text-[#11192A] ">10,000,000</span>
                  </div>
                  <hr className="border-t border-[black] mt-2 pt-2" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.section>
    </DashboardLayout>
  );
};

export default SupplierEarlyPaymentBulkDetailsScreen;
