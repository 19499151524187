import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import TransitionOpacity from "shared/animation/TransitionOpacity";
import PendingBidRequestCard from "./cards/PendingBidRequestCard";
import { useDispatch, useSelector } from "react-redux";
import {
  getInvoiceBidByStatus,
  ongoingBidOpenMarketFunder,
} from "appstate/openMarket/openMarketSlice";
import LoadingScreen from "shared/LoadingScreen";
import EmptyPage from "features/roleManager/Dashbaord/components/EmptyPage";
import Pagination from "shared/Pagination";

function OngoingBidRequest({ pendingQuery, setActiveTab }) {
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(3);
  const pageSize = 10;

  const { isLoading, ongoingBidOpenMarketFunderData } = useSelector(
    (state) => state?.openMarket
  );

  useEffect(() => {
    dispatch(ongoingBidOpenMarketFunder());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getInvoiceBidByStatus({
        page: 0,
        pageSize: 10,
        status: "Pending",
        searchQuery: pendingQuery,
      })
    );
  }, [dispatch, pendingQuery]);

  // const [filter, setFilter] = useState("all");

  // const filteredBids = ongoingBidOpenMarketFunderData?.data?.filter((bid) => {
  //   if (filter === "") return true;
  //   if (filter === "BID_ACTIVE") return bid?.status === "leading";
  //   if (filter === "POSTED") return bid?.status === "POSTED";
  //   return true;
  // });

  return (
    <TransitionOpacity className=" min-h-[75vh] h-full  bg-unusualGray rounded-[5px] p-6">
      <div className="w-full mb-4 flex items-center gap-2.5">
        <span className="py-2.5">Filter Bids:</span>

        <select
          className="text-sm font-medium border border-[#959595] rounded-[5px] px-5 py-2.5 outline-none cursor-pointer"
          // value={filter}
          // onChange={(e) => setFilter(e.target.value)}
        >
          <option value="">All Ongoing Bids</option>
          <option value="BID">Bids I'm currently leading</option>
          <option value="notleading">Bids I'm not currently leading</option>
        </select>
      </div>

      {isLoading ? (
        <LoadingScreen />
      ) : ongoingBidOpenMarketFunderData?.data?.length > 0 ? (
        <div className="flex px-6 flex-col gap-4 h-full justify-center">
          {ongoingBidOpenMarketFunderData?.data?.map((bid) => (
            <PendingBidRequestCard key={bid.id} {...bid} />
          ))}
          <div className="flex items-center h-full justify-center">
            {ongoingBidOpenMarketFunderData?.meta?.totalElements >=
              pageSize && (
              <Pagination
                page={page}
                startPage={startPage}
                endPage={endPage}
                setEndPage={setEndPage}
                setPage={setPage}
                setStartPage={setStartPage}
                totalPages={ongoingBidOpenMarketFunderData?.meta?.totalElements}
              />
            )}
          </div>
        </div>
      ) : (
        <EmptyPage />
      )}
      {/* Cancel Modal */}
      <AnimatePresence>
        {/* {cancelModalOpen && (
          <ViewDocumentModal
            onAction={handleCancel}
            onClose={() => setCancelModalOpen(false)}
          >
            <span className=" text-center">
              Are you sure you want to{" "}
              <span className="font-bold text-center text-primaryColor">
                Cancel
              </span>{" "}
              this invoice
            </span>
          </ViewDocumentModal>
        )} */}
      </AnimatePresence>
    </TransitionOpacity>
  );
}

export default OngoingBidRequest;
