import React from "react";

const WalletCard = ({ amount, label, info, percentage }) => {
  const totalColor =
    label === "Total Partial Payment" || label === "Total Liened Balance"
      ? "text-borderColor"
      : label === "Total Withdrawal" ||
        label === "Total liened Invoices" ||
        label === "Net Expenditure" ||
        label === "Total Partially Paid Invoices"
      ? "text-secondaryColor"
      : label === "Total Deposit" ||
        label === "Total Paid Invoices" ||
        label === "Total Fully Paid Invoices" ||
        label === "Net Income"
      ? "text-primaryColor"
      : "text-black";
  return (
    <>
      <div className="bg-white  py-[18px] px-[28px] rounded-[5.184px] shadow-md 2xl:w[300px]">
        <p className="text-sm fontsemibold text-ebony">{label}</p>
        <h1 className={`${totalColor} text-[24px] font-semibold py-[13px]`}>
          {amount}
        </h1>
        <p className="text-[10px] text-gray800 fontsemibold">
          <span className="text-ebony text-[ 7.714px] fontsemibold">
            {/* +{percentage}%{" "} */}
          </span>
          {info}
        </p>
      </div>
    </>
  );
};

export default WalletCard;
