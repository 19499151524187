import { PAIconAlert24 } from "assets/images/svgs";
import React from "react";
// import Spinner from "./Spinner/Spinner";
import Button from "shared/Button";
import Spinner from "shared/Spinner/Spinner";
// import Button from "./Button";

const ResumeModal = ({ isOpen, onClose, message, onConfirm, loading }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-[1000]">
      <div className="bg-white p-5 rounded-md shadow-md flex flex-col items-center px-10">
        <PAIconAlert24 />
        {message && (
          <p className="text-[#979797] text-center  text-base font-normal leading-normal tracking-wide mb-3 my-5">
            {message}
          </p>
        )}
        <div className="flex justify-center mt-3  gap-6">
          <button
            className="px-5 py-2 bg-[#F8F8F8] border border-[#CCC]  text-black rounded-md"
            onClick={onClose}
          >
            Cancel
          </button>
          <Button
            neutral
            disabled={loading}
            className="flex justify-center items-center px-5 py-2  !text-white bg-primaryColor border !border-[#07593D] rounded-md"
            onClick={onConfirm}
          >
            {loading ? <Spinner /> : "Resume"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ResumeModal;
