import React from "react";

import { PAIconMakiCaution } from "assets/images/svgs";

const ConfirmDirectFundingModalBulk2 = ({
  isOpen,
  onClose,
  handleNext,
  disValue,
  handleChange,

  handleCancel,
  handleSave,
  editable,
  setEditable,
  onBlur,
}) => {
  if (!isOpen) return null;

  return (
    <>
      <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg rounded-[10px] z-[1000]">
        <div className="bg-white p10 px5 rounded-md max-w-xs  lg:min-w-[598px]  md:lg:min-w-[598px] pb-5 px10 min-h-[340px]">
          <div className="flex justify-between items-center py-5 px-6 rounded-t-[10px]  bg-[#FAFAFA]">
            <div class="text-[#222222] text-sm font-bold italic">
              Edit the discount rate{" "}
            </div>
          </div>
          {/* Rest box */}
          <div className="px-6">
            <div className=" w-full flex justify-between py-4 border-b-[1px] border-b-[#d7d7d7] flex-wrap">
              {/* first */}
              <div className=" flex-col gap-1">
                <div class=" text-[#555555] text-sm font-normal  mb-1">
                  Discount Rate{" "}
                </div>
                <input
                  type="text"
                  placeholder=""
                  className={`${
                    editable
                      ? "w-[88px] h-[33px]  rounded border border-[#f08000]/40 justify-center items-center inline-flex text-center text-[#222222] text-base font-medium  focus:outline-none"
                      : "justify-center items-center inline-flex text-center text-[#222222] text-base font-medium  focus:outline-none w-[88px] h-[30px] bg-none"
                  }`}
                  onBlur={onBlur}
                  value={disValue}
                  onChange={handleChange}
                  disabled={!editable}
                />
              </div>

              {/* side */}
              <div className=" items-center justify-between flex-col">
                <div class="text-center text-[#555555] text-sm font-normal ">
                  Buyer Rate
                </div>
                <div class="text-center text-[#222222] text-2xl font-semibold ">
                  2.5%
                </div>
              </div>
              <div className=" items-center justify-between flex-col">
                <div class="text-center text-[#555555] text-sm font-normal ">
                  Funder Default Rate
                </div>
                <div class="text-center text-[#222222] text-2xl font-semibold ">
                  2%
                </div>
              </div>
              <div className=" items-center justify-between flex-col">
                <div class="text-[#222222] text-xl font-semibold ">
                  R34XY456
                </div>
                <div class="text-[#555555] text-sm font-normal ">
                  Ref Number
                </div>
              </div>
            </div>
            {/* next */}

            {/* Warning section */}
            <div className="flex  mt-12  gap-1">
              <PAIconMakiCaution />
              <div class=" text-[#7B7878] text-xs   font-semibold  leading-4  italic ">
                The <span className=" font-bold">Effective rate</span> is the
                lowest rate between the buyer’s and funder rate. <br />
                You can only set a rate within the range of the buyer's and the
                effective rates, if desired
              </div>
            </div>

            {/* Buttons */}
            <div className=" flex justify-between  mt-8">
              <button
                class=" px-4 py-1.5 rounded-[5px] border border-[#de1515] justify-center items-center  flex"
                onClick={onClose}
              >
                <div class="text-[#de1515] text-base font-medium">Cancel</div>
              </button>
              <button
                disabled={editable}
                className={`px-4 py-1.5 rounded-[5px] ${
                  !editable
                    ? "bg-[#07593d] cursor-pointer"
                    : "bg-[#07593d]/40 cursor-not-allowed"
                } justify-center items-center  flex`}
                onClick={handleNext}
              >
                <div
                  className={`${
                    !editable ? "text-[#ffffff]" : "text-[#777777]"
                  } text-base font-medium`}
                >
                  Confirm
                </div>
              </button>
            </div>
          </div>

          {/* BVN Form */}
        </div>
      </div>
    </>
  );
};

export default ConfirmDirectFundingModalBulk2;
