import MailIcon from "../../assets/images/svgs/mailicon.svg";
import Approved from "../../assets/images/svgs/like-icon.svg";


const AssociationSuccessModal = ({ handleCloseModal, message, header }) => {
  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      handleCloseModal();
    }
  };

  return (
    <div
      className="w-full fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50"
      onClick={handleBackgroundClick}
    >
      <div
        className="relative w-full max-w-lg p-6 bg-white rounded-lg shadow-lg"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between">
          <div>
            <span>{header ? header : "Request Sent"}</span>
          </div>
          <div style={{ cursor: "pointer" }} onClick={handleCloseModal}>
            <span>X close</span>
          </div>
        </div>
        <br />
        <hr />
        <br />
        <div>
          <div className="flex justify-center">
            {header ? <img src={Approved} alt="" /> : <img src={MailIcon} alt="" />}
          </div>
          <br />
          <div className="flex justify-center">
            <span>
              {message}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssociationSuccessModal;
