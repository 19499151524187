import * as Yup from "yup";
const updateCompanyDetailsSchema = Yup.object({
  companyName: Yup.string().required("Company name is Required"),
  companyPhoneNumber: Yup.number("Cannot contain letters").required(
    "This field is required"
  ),
  companyWebsite: Yup.string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Please enter a valid URL"
    )
    .required("This field is required"),
  registeredAddress: Yup.string().required("This field is required"),

  // industry: Yup.string().required("Industry is Required"),
});

export { updateCompanyDetailsSchema };

/*
companyName: values.companyName,
        companyPhoneNumber: values.companyPhoneNumber,
        companyWebsite: values.companyWebsite,
        registeredAddress: values.registeredAddress,
        industryType: values.industry,

*/
