import React, { useEffect, useState } from "react";
// import PendingImport from "./pendingImport";
// import All from "./all";
import { useLocation, useNavigate } from "react-router-dom";
// import PendingApproval from "./pendingApproval";
import Button from "shared/Button";
import { useDispatch, useSelector } from "react-redux";
import { invoiceApprove } from "appstate/invoice/invoiceSlice";
// import toast from "react-hot-toast";
// import { allEarlyPayments } from "appstate/buyer/buyerSlice";
import AcceptModal from "shared/AcceptModal";
import SuccessModal from "shared/SuccessModal";
// import Payables from "./payables";
import AllSupplierInvoices from "./Tables/AllSupplierInvoices";
import PendingImport from "../buyerInvoice/pendingImport";

const SupplierTabs = () => {
  const location = useLocation();
  const route = location.state;
  const [activeTab, setActiveTab] = useState("all");
  const dispatch = useDispatch();
  const { invoiceForApprove } = useSelector((state) => state?.invoice);
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isApproveButtonEnabled, setIsApproveButtonEnabled] = useState(false);
  //   const [pendingApprovalCount, setPendingApprovalCount] = useState(0);

  const navigate = useNavigate();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleNavigate = () => {
    let path = "/invoice/invoice/fundingSection/invoiceupload";
    navigate(path);
  };

  function createInvoice() {
    let path = "/invoice/invoice/new-invoice";
    navigate(path);
  }

  const handleApproveInvoice = () => {
    setIsAcceptModalOpen(false);
    const approvedByBuyer = { approve: true };
    // const page = 0;
    // const pageSize = 10;
    dispatch(
      invoiceApprove({ invoiceId: invoiceForApprove, body: approvedByBuyer })
    ).then((data) => {
      if (data?.payload?.success) {
        // dispatch(allEarlyPayments({ page, pageSize }));
        // toast.success(data?.payload?.message);
        setIsSuccessModalOpen(true);
        setIsApproveButtonEnabled(false);
        setSuccessMessage(data?.payload?.message);
      }
    });
  };

  // const handleRowSelect = (selectedRows) => {
  //   setIsApproveButtonEnabled(selectedRows.length > 0);
  // };

  const renderCreateNewRequestButton = () => {
    if (activeTab !== "pendingApproval") {
      return (
        <div className="flex justify-end gap-3 items-center text-base font-medium text-black">
          <button
            className="p1 flex h-10 items-center gap-4 rounded-md border border-[#07593D] bg-[#07593D] p-2 px-5 text-base text-white"
            onClick={createInvoice}
          >
            Create Invoice
          </button>
          <button
            className="p1 flex h-10 items-center gap-4 rounded-md border border-[#07593D] bg-[#F2FFFA96] p-2 px-5 text-base text-[#07593D]"
            onClick={handleNavigate}
          >
            Upload Invoice
          </button>
        </div>
      );
    }
    return <div className="invisible p-2">Hidden Button</div>;
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "all":
        return <AllSupplierInvoices />;
      case "pendingUpload":
        return <PendingImport />;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (route === "earlyPayments") {
      setActiveTab("earlyPayments");
    }
  }, [route]);

  return (
    <div>
      <div className="mt5 mx6 mb-[17px] w-full cursor-pointer rounded-md bg-white">
        <div className="gap1 flex-col items-center justify-between gap-10 p-2 px-4 md:flex md:flex-row">
          <div className="flex-col gap-10 md:flex md:flex-row">
            <div
              className={
                activeTab === "all"
                  ? "rounded-l-sm rounded-r-sm border-[#07593D] font-bold text-[#07593D] md:mb-[-18px] md:border-b-4"
                  : "text-gray500 text-base font-normal"
              }
              onClick={() => handleTabClick("all")}
            >
              All
            </div>
            <div
              className={
                activeTab === "pendingUpload"
                  ? "rounded-l-sm rounded-r-sm border-[#07593D] font-bold text-[#07593D] md:mb-[-18px] md:border-b-4"
                  : "text-gray500 my-2 text-base font-normal md:my-0"
              }
              onClick={() => handleTabClick("pendingUpload")}
            >
              Pending Upload
            </div>
          </div>
          {renderCreateNewRequestButton()}
          {activeTab === "pendingApproval" && (
            <Button
              neutral
              onClick={() => setIsAcceptModalOpen(true)}
              className="py-[12px] px-[24px] rounded-[5px] border-[.5px] border-silver bg-[#2EA923] text-white text-sm"
              disabled={!isApproveButtonEnabled}
            >
              Approve
            </Button>
          )}
          <AcceptModal
            isOpen={isAcceptModalOpen}
            onClose={() => setIsAcceptModalOpen(false)}
            message="Are you sure you would like to approve this/these invoice upload"
            onConfirm={handleApproveInvoice}
            // loading={false}
          />
          <SuccessModal
            isOpen={isSuccessModalOpen}
            onClose={() => setIsSuccessModalOpen(false)}
            message={successMessage}
          />
        </div>
      </div>
      {renderTabContent()}
    </div>
  );
};

export default SupplierTabs;
