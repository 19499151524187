import { PAIconInfo } from "assets/images/svgs";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Button from "shared/Button";
import InputField from "shared/InputField";
import { useSelector } from "react-redux";
import DiscountRateModal from "./DiscountRateModal";
import ReceivingFundModal from "./ReceivingFundModal";
import { useNavigate } from "react-router-dom";
import ViewMore from "./ViewMore";
import TransferFundModal from "./TransferFund";
import PinModalPayment from "./PinModal";
import SuccessEarlyPayment from "./SuccessEarlyPayment";
import EarlyPaymentConfirmation from "./EarlyPaymentConfirmation";
import {
  createEarlyPaymentRequestSingle,
  fetchDiscountBuyer,
  getVirtualAccounts,
} from "appstate/api/invoice/invoice";
import EarlyPaymentVirtualAccountSelection from "./VirtualAccountSelection";
import { formatCurrency } from "utilities/helper/formatters";

const SingleRequestModalContent = ({ onClose, data, invoiceData, id, row }) => {
  const { earlyPaymentLoading } = useSelector((state) => state?.supplier);
  const [enableDiscount, setEnableDiscount] = useState(false);
  const [applied, setApplied] = useState(false);
  // eslint-disable-next-line
  const [discountedAmount, setDiscountedAmount] = useState("");
  // eslint-disable-next-line
  const [invoiceAmount, setInvoiceAmount] = useState("");
  const [open, setOpen] = useState(false);
  const [newPaymentDate, setNewPaymentDate] = useState("");
  const [description, setDescription] = useState("");
  const [showDiscountRateModal, setShowDiscountRateModal] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState("");
  const [showReceivingFundModal, setShowReceivingFundModal] = useState(false);
  const [showVirtualSelectionModal, setShowVirtualSelectionModal] =
    useState(false);
  const [showViewMore, setShowViewMore] = useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [virtualAccounts, setVirtualAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [discount, setDiscount] = useState("");
  const [receivableAmount, setReceivableAmount] = useState(false);
  const [otpInputs, setOtpInputs] = useState(["", "", "", ""]);
  const [requestAmount, setRequestAmount] = useState("");
  const [daysRemaining, setDaysRemaining] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVirtualAccounts = async () => {
      try {
        const result = await getVirtualAccounts();
        setVirtualAccounts(result?.data);
      } catch (error) {
        console.error("Failed to fetch virtual accounts:", error);
      }
    };

    fetchVirtualAccounts();
  }, []);

  const fetchDiscountForSingleRequest = async (earlyPaymentPercentage) => {
    try {
      const buyerId = invoiceData?.buyerId;
      const response = await fetchDiscountBuyer({
        buyerId,
        earlyPaymentPercentage: earlyPaymentPercentage,
        earlyPaymentDate: newPaymentDate,
        invoiceId: id,
      });
      // console.log("response", response);
      setDiscountedAmount(response?.data?.discountedAmount);
      setDiscount(response?.data?.discount);
      setReceivableAmount(response?.data?.receivableAmount);
      setInvoiceAmount(response?.data?.invoiceAmount);
      setRequestAmount(response?.data?.requestAmount);
      setDaysRemaining(response?.data?.daysRemaining);
      if (response?.status === 200) {
        setEnableDiscount(true);
      } else {
        toast.error(response?.message);
        setTypingInputVal(false);
        setSelectedRequest("");
      }
    } catch (error) {
      console.error("Error fetching discount:", error);
    }
  };

  const createSingleEarlyPaymentRequest = async () => {
    if (!description || !newPaymentDate || !selectedAccount) {
      toast.error("Please fill in all the required fields.");
      return;
    }
    const pin = otpInputs.toString();
    const transactPin = pin.replace(/,/g, "");
    const paymentRequestData = {
      invoiceId: id,
      discountRate: discount,
      description: description,
      newPaymentDate: newPaymentDate,
      buyerId: invoiceData?.buyerId,
      paymentDays: daysRemaining,
      virtualAccountId: selectedAccount?.id,
      requestedAmount: requestAmount,
      earlyPaymentAmount: selectedRequest,
      transactionPin: transactPin,
      receivableAmount: receivableAmount,
    };

    try {
      const response = await createEarlyPaymentRequestSingle(
        paymentRequestData
      );
      if (response?.status === 201) {
        setShowConfirmModal(false);
        setShowSuccess(true);
        toast.success(response?.message);
        setTimeout(() => {
          onClose();
          navigate("/supplier-early-payments")
        }, 3000);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.error("Error creating early payment request:", error);
      toast.error("Failed to create early payment request.", error);
    }
  };

  const handleViewMoreClick = () => {
    setShowViewMore(true);
  };

  const handleEdit = () => {
    setTypingInputVal(false);
  }

  const handleSetNewAmount = () => {
    setTypingInputVal(false);
    setSelectedAccount(null);
    setEnableDiscount(false);
    setApplied(false);
  };

  const handleCloseViewMore = () => {
    setShowViewMore(false);
  };

  const handleShowPin = () => {
    setShowTransferModal(false);
    setShowPin(true);
  };

  const showDiscountModal = () => {
    setShowDiscountRateModal(!showDiscountRateModal);
  };

  const [typingInputVal, setTypingInputVal] = useState(false);

  const [debounceTimer, setDebounceTimer] = useState(null);

  const handleInputChange = (event) => {
    const value = event.target.value;
  
    if (/^\d*$/.test(value)) {
      setSelectedRequest(value);
    } else {
      toast.error("Only numeric values are allowed.");
      return;
    }
  
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }
  
    const timer = setTimeout(() => {
      if (value) {
        setTypingInputVal(true);
        fetchDiscountForSingleRequest(value);
      }
    }, 3000);
  
    setDebounceTimer(timer);
  };
  

  const handleShowReceivingFundModal = () => {
    setShowReceivingFundModal(true);
  };

  const handleShowVirtualAccountSelection = () => {
    setShowVirtualSelectionModal(true);
    setShowReceivingFundModal(false);
  };

  const handleCloseVirtualAccount = () => {
    setShowReceivingFundModal(false);
    setShowVirtualSelectionModal(false);
  };

  const handleChangeAccount = () => {
    setSelectedAccount(null); 
    setShowVirtualSelectionModal(true);
    setShowTransferModal(false);
  };

  const handleCloseTransferModal = () => {
    setSelectedAccount(null);
    setShowTransferModal(false);
  };

  function handleSendRequest() {
    setOpen(false);
    setShowTransferModal(true);
  }

  const confirmPayment = () => {
    setShowConfirmModal(true);
    setShowPin(false);
  };

  const onClosePinModal = () => {
    setSelectedAccount(null);
    setShowPin(false);
    setOtpInputs(["", "", "", ""])
  };

  function handleOpenModal() {
    if (!description || !newPaymentDate) {
      toast.error("Description or new payment date are required.");
      return;
    }
    setOpen(true);
  }

  const handleWouldYouLikeTo = () => {
    navigate("/supplier-early-payments/single-early-payment-request-flow")
    setOpen(false);
  }

  const handleConfirm = () => {
    setSelectedAccount(null);
    setOtpInputs(["", "", "", ""])
    setShowConfirmModal(false);
  }

  return (
    <>
      {(open || showConfirmModal) && (
        <div className="w-full fixed inset-0 bg-black bg-opacity-50 z-10"></div>
      )}
      {open && (
        <div className="absolute mt-[20px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white w-[614px] h-[254px] z-10 rounded-[10px] shadow-lg">
          <EarlyPaymentConfirmation
            hide={handleWouldYouLikeTo}
            isLoading={earlyPaymentLoading}
            handleAssociate={handleSendRequest}
            earlyPayment={true}
            warning={
              <>
                Would you like to proceed to use this account{" "}
                <span className="text-primaryColor">
                  {selectedAccount?.accountNumber}
                </span>{" "}
                to bid on invoice?
              </>
            }
          />
        </div>
      )}

      {showConfirmModal && (
        <div className="absolute mt-[20px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white w-[615px] h-[254px] z-10 rounded-[10px] shadow-lg">
          <EarlyPaymentConfirmation
            hide={handleConfirm}
            isLoading={earlyPaymentLoading}
            handleAssociate={createSingleEarlyPaymentRequest}
            invoice={true}
            // earlyPayment={true}
            warning=" Would you like to proceed with sending the early payment request? "
          />
        </div>
      )}

      {showSuccess && (
        <SuccessEarlyPayment
          isOpen={showSuccess}
          invoice={true}
          onClose={() => setShowSuccess(false)}
          message="Early payment request successful"
        />
      )}

      {showDiscountRateModal && (
        <div className="w-full">
          <DiscountRateModal
            setApplied={setApplied}
            discount={discount}
            setShowDiscountRateModal={setShowDiscountRateModal}
            invoice={true}
          />
        </div>
      )}
      {showReceivingFundModal && (
        <div>
          <ReceivingFundModal
            handleCloseVirtualAccount={handleCloseVirtualAccount}
            handleShowVirtualAccountSelection={
              handleShowVirtualAccountSelection
            }
            setShowReceivingFundModal={setShowReceivingFundModal}
            invoice={true}
          />
        </div>
      )}
      {showTransferModal && (
        <div>
          <TransferFundModal
            handleCloseVirtualAccount={handleCloseVirtualAccount}
            handleShowVirtualAccountSelection={
              handleShowVirtualAccountSelection
            }
            setShowReceivingFundModal={setShowReceivingFundModal}
            handleChangeAccount={handleChangeAccount}
            handleCloseTransferModal={handleCloseTransferModal}
            handleShowPin={handleShowPin}
            selectedAccount={selectedAccount}
            invoice={true}
          />
        </div>
      )}
      {showVirtualSelectionModal && (
        <div>
          <EarlyPaymentVirtualAccountSelection
            handleCloseVirtualAccount={handleCloseVirtualAccount}
            setOpen={setOpen}
            onClose={onClose}
            virtualAccounts={virtualAccounts}
            selectedAccount={selectedAccount}
            setSelectedAccount={setSelectedAccount}
            invoice={true}
          />
        </div>
      )}
      {showPin && (
        <PinModalPayment
          confirmPayment={confirmPayment}
          isOpen={showPin}
          onCancel={onClosePinModal}
          otpInputs={otpInputs}
          setOtpInputs={setOtpInputs}
          invoice={true}
        />
      )}

      {showViewMore && (
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          {" "}
          <ViewMore invoice={true} discount={discount} onClose={handleCloseViewMore} />
        </div>
      )}
      <div className="p-[39px]">
        <div className="flex justify-between">
          <div className="flex-1">
            <div className="flex items-center gap-x-[23px]">
              <h1 className="text-[#222222] text-[20px] font-semibold leading-[23.87px] font-sfpro">
                Invoice | {`INV-${invoiceData?.invoiceNo}`}
              </h1>
              <p className="text-textColor text-sm">
                Reference No: {invoiceData?.invoiceRef}
              </p>
            </div>
            <div>
              <span className="text-textColor text-xs mr-[10px]">
                Created Date: {invoiceData?.createdDate}
              </span>

              <span className="text-textColor text-xs ml-[10px]">
                Payment Due Date: {invoiceData?.dueDate}
              </span>
            </div>
          </div>
          {!applied && (
            <Button
              neutral
              type="Button"
              disabled={!requestAmount || !applied}
              onClick={handleOpenModal}
              className="border flex justify-center items-center border-[#AFAFAF] rounded-[5px] text-xs text-[#AFAFAF] w-[135px]  h-[30px]"
            >
              Send Request
            </Button>
          )}
          {applied && (
            <button
              onClick={handleShowReceivingFundModal}
              style={{ backgroundColor: "#F08000", color: "white" }}
              className="border flex justify-center items-center rounded-[5px] text-xs  w-[155px]  h-[30px]"
            >
              Add Account
            </button>
          )}
        </div>
        <div className="flex justify-between mt-[25px]">
          <div>
            {applied ? <p className="text-textColor font-extrabold text-base">
              Receivable Amount
            </p> : <p className="text-textColor font-extrabold text-base">
              Invoice Amount
            </p>}
            {/* here */}
            {applied ? 
              <h1 className="text-primaryColor text-2xl font-extrabold">
                NGN {formatCurrency(Number(receivableAmount))}
              </h1>
            : <h1 className="text-primaryColor text-2xl font-extrabold">
            NGN {formatCurrency(Number(invoiceData?.grandTotal))}
          </h1>}

            <h1
              className={`text-2xl font-extrabold ${
                applied ? "line-through text-[#F08000]" : "text-primaryColor"
              }`}
            >
            {applied && `NGN ${formatCurrency(Number(requestAmount))}`}
            </h1>

            {applied && (
              <h4 className="text-[#F08000] text-2xl font-extrabold">
                {discount}%
              </h4>
            )}

            {/* <h1 className="text-textColor text-sm font-extrabold mt-[19px]">
              Early Payment Request
            </h1> */}
          </div>
          <div>
            <h4 style={{ fontSize: 13 }}>Request Percentage</h4>
            {typingInputVal ? (
                 <div style={{display: "flex", flexDirection: "column"}}>
              <span style={{cursor: "pointer"}} onClick={handleEdit}>{selectedRequest}%</span>
              {/* <span className="text-primaryColor text-2xl font-extrabold" style={{marginTop: 5, fontSize: 25}}>NGN{formatCurrency(Number(invoiceAmount))}</span> */}
              
              </div>
            ) : (
              <div className="flex justify-end">
              <InputField
                value={selectedRequest}
                onChange={handleInputChange}
                disabled={!newPaymentDate || !description || applied}
                placeholder="Enter percentage"
                className="border border-gray-300 rounded-md p-2"
              />{" "}
            </div>
            )}
            
          </div>
        </div>
       
        <div className="mt-[20px]">
          <div className="flex justify-between items-center">
            <InputField
              label="New Payment Date:"
              type="date"
              value={newPaymentDate}
              onChange={(e) => setNewPaymentDate(e.target.value)}
              className=" w-[369px] h-[39px] rounded-[5px] outline-none !border border-[#AFAFAF] px-[5px]"
            />
            <div style={{ marginTop: 20 }}>
              {!applied ? (
                <>
                  <div style={{ marginTop: enableDiscount ? 40 : 0 }}>
                    <InputField
                      label="Request Amount"
                      placeholder="₦"
                      type="number"
                      value={requestAmount}
                      // onChange={handleAmountChange}
                      readOnly
                      className="w-[369px] h-[39px] rounded-[5px] !border border-[#AFAFAF] px-[5px] outline-none [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                    />
                  </div>

                  {!enableDiscount && (
                    <Button
                      onClick={fetchDiscountForSingleRequest}
                      typeof="Button"
                      disabled={!description || !selectedRequest}
                      neutral
                      className="!text-secondaryColor text-sm mt-[5px] float-right"
                    >
                      {/* Check for available discount */}
                    </Button>
                  )}
                  {enableDiscount && (
                    <div className="mt-[10px] flex justify-between">
                      <div>
                        <p className="text-textColor text-xs font-medium border-b border-[#AFAFAF] w-[84px]">
                          Discount:{" "}
                          <span className="text-[#17191C] text-xs">
                            {discount}%
                          </span>
                        </p>
                        <div
                          onClick={handleViewMoreClick}
                          className="flex items-center mt-[2px]"
                        >
                          <PAIconInfo />
                          <p
                            style={{ cursor: "pointer" }}
                            className="text-[#F08000] text-[10px] font-medium"
                          >
                            Tap to view more
                          </p>
                        </div>
                      </div>
                      <div>
                        <p className="text-textColor text-xs">
                          Receivable Amount:{" "}
                          <span className="text-primaryColor text-xs font-extrabold border-b border-[#AFAFAF] pb-[2px]">
                            {formatCurrency(Number(receivableAmount))}
                          </span>
                        </p>
                        <div className="flex justify-end mt-[5px]">
                          <Button
                            neutral
                            // onClick={() => setApplied(true)}
                            onClick={showDiscountModal}
                            disabled={!requestAmount}
                            type="Button"
                            className="w-[87px] h-[24px] text-white bg-secondaryColor text-xs rounded-[5px]"
                          >
                            Apply
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className="flex justify-end">
                  
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                  
                    <Button
                      neutral
                      // onClick={() => setApplied(true)}
                      onClick={handleSetNewAmount}
                      // disabled={!requestAmount}
                      type="Button"
                      className="w-[107px] h-[24px] text-white bg-primaryColor text-xs rounded-[5px]"
                    >
                      Set new amount
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col">
            <label className="text-textColor mb-[10px] text-xs" htmlFor="">
              Description
            </label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="border outline-none border-[#AFAFAF] w-full rounded-[5px] h-[67px] p-[10px]"
            ></textarea>
          </div>

          <div className="mt-[28px] border-b-4 border-[#F6F5FA] pb-[12px]">
            <h1 className="text-textColor text-lg font-bold">
              Supplier/buyer details
            </h1>
          </div>
          <div className="flex justify-between mt-[16px]">
            <div>
              <p className="text-textColor text-sm italic font-extrabold">
                Supplier
              </p>
              <p className="text-lightGray text-xs">
                {invoiceData?.virtualAccount?.firstName + " " + invoiceData?.virtualAccount?.lastName}
              </p>
            </div>
            <div>
              <p className="text-textColor text-sm italic font-extrabold">
                Buyer
              </p>
              <p className="text-lightGray text-xs">{data?.buyerCompanyName}</p>
            </div>
          </div>
          <div className="mt-[32px] border-b border-[#F6F5FA] pb-[12px]">
            <h1 className="text-[#11192A] text-sm font-bold">Line Items</h1>
          </div>
          <div className="mt-[28px] border-b-4 border-[#F6F5FA] pb-[12px]"></div>
          <div className=" w-full overflow-x-auto">
            <table className=" w-full overflow-x-auto">
              <thead className="bg-subtleGray w-">
                <tr className=" bg-unusualGray p-2.5 ">
                  <td className="text-[#11192A] p-2.5 text-xs font-bold">
                    Item Number
                  </td>
                  <td className=" p-2.5 text-xs font-bold">
                    Description
                  </td>
                  <td className=" p-2.5 text-xs font-bold">Quantity</td>
                  <td className=" p-2.5 text-xs font-bold">Unit Price</td>
                  <td className=" p-2.5 text-xs font-bold">Amount</td>
                  <td className=" p-2.5 text-xs font-bold">Tax Rate</td>
                  <td className=" p-2.5 text-xs font-bold">Tax Amount</td>
                  <td className=" p-2.5 text-xs font-bold">Total Price</td>
                </tr>
              </thead>
              {invoiceData?.items?.map((item, index) => (
                <tbody key={index}>
                  <tr className=" py-[3px] px-1.5">
                    <td className=" text-xs text-[#11192A] p-2.5">
                      {invoiceData?.invoiceNo}
                    </td>
                    <td className=" text-xs text-[#11192A] p-2.5">
                      {item?.description}
                    </td>
                    <td className=" text-xs text-[#11192A] p-2.5">
                      {formatCurrency(Number(item?.quantity))}
                    </td>
                    <td className=" text-xs text-[#11192A] p-2.5">
                      {formatCurrency(Number(item?.unitPrice))}
                    </td>
                    <td className=" text-xs text-[#11192A] p-2.5">
                      {formatCurrency(Number(item?.amount))}
                    </td>
                    <td className=" text-xs text-[#11192A] p-2.5">
                      {item?.taxRate}
                    </td>
                    <td className=" text-xs text-[#11192A] p-2.5">
                      {formatCurrency(Number(item?.taxAmount))}
                    </td>
                    <td className=" text-xs text-[#11192A] p-2.5">
                      {formatCurrency(Number(item?.totalPrice))}
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
          <br />
          <div className="flex justify-between">
              <div>
                <h4 className="text-sm text-[#11192A] ">
                  Receiving Funding Account Information
                </h4>
                <h5 className="text-sm text-[#11192A] ">
                  {invoiceData?.virtualAccount?.accountNumber}
                </h5>
                <h5 className="text-sm text-[#11192A] ">
                  {invoiceData?.virtualAccount?.accountName}
                </h5>
              </div>
              <div style={{ width: 300 }}>
                <div className="flex justify-between">
                  <span className="text-sm text-[#11192A] ">Subtotal</span>
                  <span className="text-sm text-[#11192A] ">
                    {formatCurrency(Number(invoiceData?.subTotal))}
                  </span>
                </div>
                <div className="flex justify-between">
                  <div>
                    <span className="text-sm text-[#11192A] ">VAT</span>
                    <span className="pl-2 text-sm text-[#11192A] ">
                      {invoiceData?.vat}%
                    </span>
                  </div>
                  <div>
                    <span className="text-sm text-[#11192A] ">{formatCurrency(Number(invoiceData?.vatAmount))}</span>
                  </div>
                </div>
                <div className="flex justify-between">
                  <div>
                    <span className="text-sm text-[#11192A] ">Discount</span>
                    <span className="pl-2 text-sm text-[#11192A] ">
                      {invoiceData?.discountRate}%
                    </span>
                  </div>
                  <div>
                    <span className="text-sm text-[#11192A] ">
                      {formatCurrency(Number(invoiceData?.discountAmount))}
                    </span>
                  </div>
                </div>
                <div>
                  <hr className="border-t border-[black] mt-2 pt-2" />
                  <div className="flex justify-between">
                    <span className="text-lg text-[#11192A] ">Grand Total</span>
                    <span className="text-lg text-[#11192A] ">
                      {formatCurrency(Number(invoiceData?.grandTotal))}
                    </span>
                  </div>
                  <hr className="border-t border-[black] mt-2 pt-2" />
                </div>
              </div>
            </div>
        </div>
      </div>
    </>
  );
};

export default SingleRequestModalContent;