import React, { useEffect, useState } from "react";
import WalletCard from "features/transactions/components/WalletCard";
import NewDebitCard from "./NewDebitCard";
import TransactionTable from "../transactions/TransactionTable";
import { useDispatch, useSelector } from "react-redux";
import LinkExternalModal from "./LinkExternalAccount";
import CreateVirtualAccountModal from "./CreateVirtualAccount";
import PinModal from "shared/PinModal";
import CongratsModal from "features/wallet/linkExternalAccount/congrats/congratsModal";
import WithdrawOption from "./Withdraw";
import FundOptions from "./FundAccount";
import FundAccountOption from "./FundAccount/ExternalSource";
import FundWalletDetails from "./FundAccount/ExternalSource/FundAccountDetails";
import VirtualAccountOption from "./FundAccount/VirtualAccount";
import AccountFrom from "./FundAccount/VirtualAccount/AccountFrom";
import AccountTo from "./FundAccount/VirtualAccount/AccountTo";
import TransferReceipt from "shared/TransferReceipt";
import { useNavigate } from "react-router-dom";
import {
  allTransactions,
  allVirtualAccounts,
  postFundTransfer,
  postVirtualAccount,
  setTransferDetails,
  supplierWalletOverview,
  walletOverview,
} from "appstate/wallet/walletSlice";
import toast from "react-hot-toast";

const WalletOverview = () => {
  const secondSelectedAccounts = useSelector(
    (state) => state?.wallet?.secondSelectedAccounts
  );

  const selectedAccounts = useSelector(
    (state) => state?.wallet?.selectedAccounts
  );

  const isLoading = useSelector((state) => state.wallet.isLoading);

  const { externalAccountFormData, virtualAccountFormData, transferDetails } =
    useSelector((state) => state?.wallet);
  const [isLinkExternalAccontModalOpen, setIsLinkExternalAccontModalOpen] =
    useState(false);

  const [isCreateVirtualAccountModalOpen, setIsCreateVirtualAccontModalOpen] =
    useState(false);
  const [showPinModal, setShowPinModal] = useState(false);
  const [showCongratsModal, setShowCongratsModal] = useState(false);

  const [isWithdrawOpen, setWithdrawOpen] = useState(false);

  const [isFundOptionsOpen, setIsFundOptionsOpen] = useState(false);

  const [isFundAccountOptionOpen, setIsFundAccountOptionOpen] = useState(false);

  const [isFundWalletDetailsOpen, setFundWalletDetailsOpen] = useState(false);

  const [isVirtualAccountOptionOpen, setIsVirtualAccountOptionOpen] =
    useState(false);

  const [isAccountFromOpen, setIsAccountFromOpen] = useState(false);

  const [isAccountToOpen, setIsAccountToOpen] = useState(false);

  const [fundAccountPinModal, setFundAccountPinModal] = useState(false);

  const [isTransferReceiptOpen, setIsTransferReceiptOpen] = useState(false);
  const page = 0;
  const size = 5;
  const startDate = "";
  const endDate = "";
  const searchKey = "";

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleExternalsourceSubmit = (e) => {
    setIsFundAccountOptionOpen(true);
    setIsFundOptionsOpen(false);
  };

  const handleFundVirtualAccountClick = () => {
    setIsVirtualAccountOptionOpen(true);
    setIsFundOptionsOpen(false);
  };
  const handleWithdrawOptionSubmit = () => {
    setFundWalletDetailsOpen(true);
    setIsFundAccountOptionOpen(false);
  };

  const handleVirtualAccountOptionSubmit = () => {
    setIsAccountFromOpen(true);
    setIsVirtualAccountOptionOpen(false);
  };

  const handleAccountFromSubmit = () => {
    setIsAccountToOpen(true);
    setIsAccountFromOpen(false);
  };

  const handleAccountFromBack = () => {
    setIsAccountToOpen(false);
    setIsVirtualAccountOptionOpen(true);
  };

  const handleAccountToBack = () => {
    setIsAccountToOpen(false);
    setIsAccountFromOpen(true);
  };

  const handleFundVirtualAccountSubmit = () => {
    setIsAccountToOpen(false);
    setFundAccountPinModal(true);
  };

  const handleTransferReceiptClose = () => {
    setIsTransferReceiptOpen(false);
    setFundAccountPinModal(false);
  };

  const handleWithdrawClick = () => {
    toast(
      "This feature is awaiting external API and is unavailable and will be available later. Please Kennedy, PM, John do come back.",
      {
        icon: "⚠️",
        style: {
          background: "#333",
          color: "#fff",
        },
      }
    );
  };

  // const [pinValue, setPinValue] = useState("");

  const handleCreateVirtualAcountSubmit = (pin) => {
    const body = {
      firstName: virtualAccountFormData?.firstName,
      lastName: virtualAccountFormData?.lastName,
      transactionPin: pin,
      bankName: virtualAccountFormData?.bank,
      currency: virtualAccountFormData?.currency,
    };

    dispatch(postVirtualAccount(body)).then((response) => {
      if (response?.payload?.status === 201 && response?.payload?.success) {
        setShowPinModal(false);
        setShowCongratsModal(true);
        dispatch(allVirtualAccounts());
      }
    });
  };
  // const handleCreateVirtualAcountSubmit = async (pin) => {
  //   const body = {
  //     firstName: virtualAccountFormData?.firstName,
  //     lastName: virtualAccountFormData?.lastName,
  //     transactionPin: pin,
  //     bankName: virtualAccountFormData?.bank,
  //     currency: virtualAccountFormData?.currency,
  //   };

  //   const pendingToast = toast.loading("Processing your transaction...");

  //   try {
  //     const response = await dispatch(postVirtualAccount(body));

  //     // Dismiss the loading toast
  //     toast.dismiss(pendingToast);

  //     if (response?.payload?.status === 201 && response?.payload?.success) {
  //       setShowPinModal(false);
  //       setShowCongratsModal(true);
  //     } else {
  //       // Handle the error case, if needed
  //       setShowPinModal(false);
  //       toast.error(response?.payload?.error);
  //     }
  //   } catch (error) {
  //     // Dismiss the loading toast on error
  //     toast.dismiss(pendingToast);
  //     toast.error("An error occurred. Please try again.");
  //     return null
  //   }
  // };

  const handleTransfer = async (pin) => {
    const body = {
      sourceAccountNumber: selectedAccounts?.accountNumber || "",
      destinationAccountNumber: secondSelectedAccounts?.accountNumber || "",
      amount: externalAccountFormData?.amount || 0,
      description: externalAccountFormData?.narration || "",
      transactionPin: pin,
    };

    const pendingToast = toast.loading("Processing your transaction...");

    dispatch(postFundTransfer(body)).then((data) => {
      if (data?.payload?.status === "pending") {
        return;
      }

      if (data?.payload?.success) {
        dispatch(setTransferDetails(data?.payload));
        toast.success("Transaction successful!");
        setIsTransferReceiptOpen(true);
        if (userRole === "supplier") {
          dispatch(supplierWalletOverview());
        } else {
          dispatch(walletOverview());
        }
        dispatch(
          allTransactions({ page, size, startDate, endDate, searchKey })
        );
        // dispatch(allVirtualAccounts());
      } else {
        toast.error(data?.payload?.message);
      }

      // if (data?.payload?.success) {
      //   dispatch(setTransferDetails(data?.payload));
      //   toast.success("Transaction successful!");
      //   setIsTransferReceiptOpen(true);
      // }

      toast.dismiss(pendingToast);
    });
  };

  const handleAccountManagement = () => {
    let path = "/wallet/accountManagement";
    navigate(path);
  };

  const formatAmount = (amount) => {
    if (!amount || null) return "0.00";
    const parsedAmount = parseFloat(amount);
    if (isNaN(parsedAmount)) return "0.00";
    return parsedAmount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const { userId } = useSelector((state) => state?.auth);
  const roleIndex = userId?.realm_access?.roles?.findIndex((arr) => {
    return (
      arr === "SUPPLIER" ||
      arr === "BUYER" ||
      arr === "FUNDER" ||
      arr === "ADMIN"
    );
  });
  const userRole = userId?.realm_access?.roles[roleIndex]?.toLowerCase();
  const { walletOverviewData, supplierWalletOverviewData } = useSelector(
    (state) => state?.wallet
  );

  const walletData =
    userRole === "supplier" ? supplierWalletOverviewData : walletOverviewData;
  // console.log("supplier data: ", supplierWalletOverviewData);

  useEffect(() => {
    if (userRole === "supplier") {
      dispatch(supplierWalletOverview());
    } else {
      dispatch(walletOverview());
    }

    dispatch(allVirtualAccounts());
  }, [dispatch, userRole]);

  const cardContents = [
    {
      label: "Available Balance",
      amount: formatAmount(walletData?.availableBalance),
      // percentage: "10",
      // info: "Increase since January",
    },
    {
      label:
        userRole === "buyer" || userRole === "funder"
          ? "Total Liened Balance"
          : "Total Partial Payment",
      amount:
        userRole === "buyer" || userRole === "funder"
          ? formatAmount(walletData?.totalLienedAmount)
          : userRole === "supplier"
          ? formatAmount(walletData?.totalPartialPayment)
          : "",
      // percentage: "40",
      // info: "Increase since January",
    },
    {
      label: "Total Withdrawal",
      amount: formatAmount(walletData?.totalWithdrawal),
      // percentage: "25",
      // info: "Increase since January",
    },
    {
      label: "Total Deposit",
      amount: formatAmount(walletData?.totalDeposit),
      // percentage: "30",
      // info: "Increase since January",
    },
  ];

  return (
    <>
      <div className="flex justify-between gap-5">
        <div>
          <div className="flex flex-row gap-2 mb-3">
            <div
              className="rounded-md text-sm border border-gray300 bg-white px-5 py-2 cursor-pointer"
              onClick={() => {
                setIsLinkExternalAccontModalOpen(
                  !isLinkExternalAccontModalOpen
                );
              }}
            >
              Link External Account
            </div>
            <div
              className="rounded-md text-sm border border-gray300 bg-white px-5 py-2 cursor-pointer"
              // onClick={toggleCreateVirtualAccount}
              onClick={() => {
                setIsCreateVirtualAccontModalOpen(
                  !isCreateVirtualAccountModalOpen
                );
              }}
            >
              Create Virtual Account
            </div>
            <div
              className={`rounded-md text-sm border border-gray300 bg-white px-5 py-2 cursor-pointer`}
              onClick={handleAccountManagement}
            >
              Account Management
            </div>
          </div>
        </div>
        <div>
          <div className="flex flex-row gap-2 mb-3 justify-end">
            <div
              className={`rounded-md text-sm text-white border border-gray300 bg-[#07593D] px-5 py-2 cursor-pointer`}
              // onClick={toggleFund}
              onClick={() => {
                setIsFundOptionsOpen(!isFundOptionsOpen);
              }}
            >
              Fund Account
            </div>
            <div
              className={`rounded-md text-sm text-white border border-gray300 bg-[#07593D] px-5 py-2 cursor-not-allowed opacity-50`}
              // onClick={toggleWithdraw}
              // onClick={() => {
              //   setWithdrawOpen(!isWithdrawOpen);
              // }}
              onClick={handleWithdrawClick}
            >
              Withdraw
            </div>
          </div>
        </div>
      </div>

      <div className="grid md:grid-cols-[60%_40%] gap-3">
        {" "}
        <div className="bg-alabasterHeader py-[10px] px-2 rounded-md">
          <div className=" text-[#064731] mb-2 px-2">
            <p className="text-sm font-bold">My Account</p>
            <p className="text-xs font-normal">Account Summary</p>
          </div>
          <div className="flex flex-wrap justify-between mx-auto w-full">
            {cardContents.map((content, idx) => (
              <div
                key={idx}
                className="w-full md:w-1/2 lg:w-1/2 xl:w-1/2 mb-6 px-2"
              >
                <WalletCard
                  label={content?.label}
                  amount={content?.amount}
                  percentage={content?.percentage}
                  info={content?.info}
                />
              </div>
            ))}
          </div>
        </div>
        {/* <DebitCard cardData={firstCardData} /> */}
        {/* <NewDebitCard /> */}{" "}
        <div className="hidden md:block">
          <NewDebitCard />
        </div>
      </div>

      {/* <OverviewTable /> */}
      <TransactionTable />

      {isLinkExternalAccontModalOpen && (
        <LinkExternalModal
          onClose={() => {
            setIsLinkExternalAccontModalOpen(false);
          }}
        />
      )}

      {isCreateVirtualAccountModalOpen && (
        <CreateVirtualAccountModal
          isOpen={isCreateVirtualAccountModalOpen}
          onClose={() => {
            setIsCreateVirtualAccontModalOpen(false);
          }}
          onPinOpen={() => {
            setShowPinModal(true);
            setIsCreateVirtualAccontModalOpen(false);
          }}
        />
      )}

      {showPinModal && (
        <PinModal
          isOpen={showPinModal}
          onClose={() => {
            setShowPinModal(false);
          }}
          onSubmit={handleCreateVirtualAcountSubmit}
          headerText="Create Virtual Account"
          instructionText="Please enter your PIN to approve the creation of this virtual account."
        />
      )}

      {showCongratsModal && (
        <CongratsModal
          isOpen={showCongratsModal}
          onClose={() => {
            setShowCongratsModal(false);
          }}
          message={`Your Virtual Account has been created`}
        />
      )}

      <WithdrawOption
        isOpen={isWithdrawOpen}
        onClose={() => setWithdrawOpen(false)}
        // onSubmit={handleWithdrawOptionSubmit}
      />

      <FundOptions
        isOpen={isFundOptionsOpen}
        onClose={() => setIsFundOptionsOpen(false)}
        onSubmit={handleExternalsourceSubmit}
        virtualClicked={handleFundVirtualAccountClick}
      />

      <FundAccountOption
        isOpen={isFundAccountOptionOpen}
        onClose={() => setIsFundAccountOptionOpen(false)}
        onSubmit={handleWithdrawOptionSubmit}
      />

      <FundWalletDetails
        isOpen={isFundWalletDetailsOpen}
        onClose={() => setFundWalletDetailsOpen(false)}
        // onSubmit={handleFundWalletDetailsSubmit}
      />

      <VirtualAccountOption
        isOpen={isVirtualAccountOptionOpen}
        onClose={() => setIsVirtualAccountOptionOpen(false)}
        onSubmit={handleVirtualAccountOptionSubmit}
      />

      <AccountFrom
        isOpen={isAccountFromOpen}
        onClose={() => setIsAccountFromOpen(false)}
        where1="From- "
        where2="to"
        onSubmit={handleAccountFromSubmit}
      />

      <AccountTo
        isOpen={isAccountToOpen}
        onClose={() => setIsAccountToOpen(false)}
        accountFromBack={handleAccountFromBack}
        accountToBack={handleAccountToBack}
        onSubmit={handleFundVirtualAccountSubmit}
      />

      {fundAccountPinModal && (
        <PinModal
          isOpen={fundAccountPinModal}
          onClose={() => {
            setFundAccountPinModal(false);
          }}
          // onSubmit={handleTransfer}
          // onSubmit={(pin) => {
          //   // setPinValue(pin);
          //   // handleTransfer();
          //   handleTransfer(pin);
          // }}
          onSubmit={handleTransfer}
          isLoading={isLoading}
          headerText="Fund Account"
          instructionText="Enter your transaction PIN to transfer funds to your desired account."
        />
      )}

      {isTransferReceiptOpen && (
        <TransferReceipt
          isOpen={isTransferReceiptOpen}
          onClose={handleTransferReceiptClose}
          // onClose={() => handleCloseWithdrawOption()}
          amount={formatAmount(transferDetails?.data?.amount)}
          currency={transferDetails?.data?.currency}
          paymentStatus={transferDetails?.data?.status}
          senderName={transferDetails?.data?.sourceAccountName}
          sourceBank="PayEdge"
          sourceAccountNumber={transferDetails?.data?.sourceAccountNumber}
          beneficiaryName={transferDetails?.data?.destinationAccountName}
          destinationBank="PayEdge"
          destinationAccountNumber={
            transferDetails?.data?.destinationAccountNumber
          }
          transactionReference={transferDetails?.data?.transactionReference}
          createdDate={transferDetails?.data?.transactionDate}
          supportEmail="customerservice@payedge.com"
          //   onDownload={() => console.log("Downloaded!")}
        />
      )}
    </>
  );
};

export default WalletOverview;
