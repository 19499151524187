// import { useNavigate } from "react-router-dom";
import ContestedInvoiceCard from "./cards/ContestedInvoiceCard";

function TopContestedInvoice({
  head = "Top 10 Contested Invoices",
  subHead = "Invoices with the highest number of bidders",
  topTenBids,
  setActiveTab,
}) {
  // const navigate = useNavigate();
  return (
    <div className="flex flex-col gap-6 rounded-[10px]  bg-unusualGray pb-12  p-6">
      <header className="flex justify-between items-center pb-4 border-b border-b-subtleGray ">
        <div className="flex flex-col gap-1">
          <span className=" font-semibold">{head}</span>
          <span className=" text-[#959595] text-xs">{subHead}</span>
        </div>

        {/* <button
          onClick={() => navigate("top-ten-invoices")}
          className="text-sm px-5 py-2.5 rounded-[5px] underline"
        >
          View all
        </button> */}
        <button
          onClick={() => {
            setActiveTab(4); 
            // navigate("top-ten-invoices");
          }}
          className="text-sm px-5 py-2.5 rounded-[5px] underline"
        >
          View all
        </button>
      </header>

      <div className="flex flex-col gap-2.5">
        <ContestedInvoiceCard
          invoiceNumber="INV-2102"
          buyerName="Rannelle"
          noOfBids={24}
          invoiceAmount="N240,100"
          dueDate="24-03-2024"
          // id="1"
        />

        <ContestedInvoiceCard
          invoiceNumber="INV-2102"
          buyerName="Rannelle"
          noOfBids={24}
          invoiceAmount="N240,100"
          dueDate="24-03-2024"
          // id="2"
        />

        <ContestedInvoiceCard
          invoiceNumber="INV-2102"
          buyerName="Rannelle"
          noOfBids={24}
          invoiceAmount="N240,100"
          dueDate="24-03-2024"
          // id="3"
        />
      </div>
    </div>
  );
}

export default TopContestedInvoice;
