import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";
import { PAIconArrowDownThin } from "assets/images/svgs";
import { formatCurrency } from "utilities/helper/formatters";

function LienApproveCard({
  account,
  setSelectedAccount,
  virtualAccount,
  earlyPaymentOverview,
  status,
  selectedAccount,
  lienedOverview,
  upcomingOverview,
  overdueOverview,
  selectedStatus,
}) {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prev) => !prev);
  const closeDropdown = () => setDropdownOpen(false);

  const handleAccountSelect = (account) => {
    setSelectedAccount(account);
    closeDropdown();
    toggleDropdown();
  };

  const dropdownVariants = {
    hidden: { opacity: 0, y: -10 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.3 } },
    exit: { opacity: 0, y: -10, transition: { duration: 0.3 } },
  };

  const parentVariants = {
    initial: { opacity: 0, y: -10 },
    animate: { opacity: 1, y: 0, transition: { duration: 0.5 } },
    exit: { opacity: 0, y: -10, transition: { duration: 0.5 } },
  };

  const defaultVirtualAccount = virtualAccount?.find(
    (account) => account.isDefaultVirtualAccount
  );

  return (
    <motion.div
      className="w-full lg:w-[60%] relative"
      initial="initial"
      animate="animate"
      exit="exit"
      variants={parentVariants}
    >
      {/* Header Section */}
      <header
        className="px-6 rounded-t border-b border-[#eee] bg-[#fff] text-[15.131px] py-3 flex items-center justify-between cursor-pointer relative z-20"
        onClick={toggleDropdown}
      >
        <div>
          {/* {virtualAccount} */}
          <span className="font-bold capitalize">
            {selectedAccount?.accountName || defaultVirtualAccount?.accountName}{" "}
            |{" "}
            <span className="italic text-[#222]">
              {selectedAccount?.accountNo ||
                defaultVirtualAccount?.accountNumber}
            </span>{" "}
            |{" "}
            <span>
              {" "}
              {selectedAccount?.bank ||
                defaultVirtualAccount?.bank ||
                "Payedge"}{" "}
            </span>{" "}
            | Virtual Account
          </span>
        </div>
        <span>
          <PAIconArrowDownThin />
        </span>

        {/* Dropdown Menu with Animation */}
        <AnimatePresence>
          {isDropdownOpen && (
            <motion.ul
              className="absolute top-full left-0 w-full bg-[#fff] shadow-md rounded-b py-3 z-30"
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={dropdownVariants}
            >
              {account &&
                account?.length > 0 &&
                account?.map((account, index) => (
                  <li
                    key={index}
                    className="py-2 px-6 cursor-pointer hover:bg-gray-100 capitalize"
                    onClick={() => handleAccountSelect(account)}
                  >
                    {account?.accountName} |{" "}
                    <span className="italic font-semibold">
                      {account.accountNo}
                    </span>{" "}
                    | {account.bank} | Virtual Account
                  </li>
                ))}
            </motion.ul>
          )}
        </AnimatePresence>
      </header>

      {/* Overlay */}
      {isDropdownOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-25 z-10"
          onClick={closeDropdown}
        />
      )}

      {/* Main Section */}
      <main className="px-6 bg-[#fff] w-full rounded-b py-3">
        {status === "requests" && (
          <div className="flex flex-col sm:flex-row justify-between lg:justify-normal">
            <section className="flex flex-col gap-2.5 py-8 w-[48%] pr-9 border-r border-[#C5C5C5]">
              <div>
                <span className=" mr-0.5  font-semibold text-[32px] text-primaryColor">
                  NGN
                </span>
                {earlyPaymentOverview?.lienAmount ? (
                  <span className="text-[32px] text-primaryColor font-bold">
                    {formatCurrency(Number(earlyPaymentOverview?.lienAmount))}
                  </span>
                ) : (
                  <span className="text-[32px] text-primaryColor font-bold">
                    0.00
                  </span>
                )}
              </div>

              <span className="text-[#7a7a7a] text-[15px] font-medium">
                Total Money Liened
              </span>
            </section>

            <section className="flex flex-col items-center gap-2.5 py-8 w-[48%] pl-9">
              <div>
                <span className="text-primaryColor text-center text-[32px] font-bold flex justify-center">
                  {earlyPaymentOverview?.totalApprovedEPR || 0}
                </span>
                <span className="text-[#7a7a7a] text-center text-[15px] font-medium flex justify-center">
                  Total Approved Early Payment Requests
                </span>
              </div>
            </section>
          </div>
        )}

        {status === "offers" && (
          <div className="flex flex-col sm:flex-row justify-between lg:justify-normal">
            <section className="flex flex-col gap-2.5 py-8 pr-9 border-r border-[#C5C5C5] w-[48%]">
              <div>
                <span className=" mr-0.5  font-semibold text-[32px] text-primaryColor">
                  NGN
                </span>
                {earlyPaymentOverview?.lienAmount ? (
                  <span className="text-[32px] text-primaryColor font-bold">
                    {formatCurrency(Number(earlyPaymentOverview?.lienAmount))}
                  </span>
                ) : (
                  <span className="text-[32px] text-primaryColor font-bold">
                    0.00
                  </span>
                )}
              </div>

              <span className="text-[#7a7a7a] text-[15px] font-medium">
                Total Money Liened
              </span>
            </section>

            <section className="flex flex-col items-center gap-2.5 py-8 w-[48%] pl-9">
              <div>
                <span className="text-primaryColor text-center text-[32px] font-bold flex justify-center">
                  {earlyPaymentOverview?.acceptedOffersCount || "0"}
                </span>
                <span className="text-[#7a7a7a] text-center text-[15px] font-medium flex justify-center">
                  Total Accepted Early Payment Requests
                </span>
              </div>
            </section>
          </div>
        )}

        {status === "payables" && (
          <div className="flex flex-col sm:flex-row justify-between lg:justify-normal">
            <section className="flex flex-col gap-2.5 py-8 w-[48%] pr-9 border-r border-[#C5C5C5]">
              <div>
                <span
                  className={`mr-0.5 font-semibold text-[32px] ${
                    selectedStatus === "Liened"
                      ? "text-primaryColor"
                      : selectedStatus === "Upcoming"
                      ? "text-secondaryColor"
                      : "text-red"
                  }`}
                >
                  NGN
                </span>
                {selectedStatus === "Liened" ? (
                  <span className={`text-[32px] text-primaryColor font-bold`}>
                    {lienedOverview?.lienAmount?.toLocaleString()}
                  </span>
                ) : selectedStatus === "Upcoming" ? (
                  <span className="text-[32px] text-secondaryColor font-bold">
                    {upcomingOverview?.lienAmount?.toLocaleString()}
                  </span>
                ) : selectedStatus === "Overdue" ? (
                  <span className="text-[32px] text-red font-bold">
                    {overdueOverview?.lienAmount?.toLocaleString()}
                  </span>
                ) : (
                  <span className="text-[32px] text-primaryColor font-bold">
                    0.00
                  </span>
                )}
              </div>

              <span className="text-[#7a7a7a] text-[15px] font-medium">
                {selectedStatus === "Liened"
                  ? "Total Money Liened"
                  : selectedStatus === "Upcoming"
                  ? "Total Outstanding Amount"
                  : selectedStatus === "Overdue"
                  ? "Total Overdue Amount"
                  : ""}
              </span>
            </section>

            <section className="flex flex-col items-center gap-2.5 py-8 w-[48%] pl-9">
              <div>
                <span className="text-primaryColor text-center text-[32px] font-bold flex justify-center">
                  {selectedStatus === "Liened" ? (
                    <span className="text-[32px] text-primaryColor font-bold">
                      {lienedOverview?.totalLienedPayables}
                    </span>
                  ) : selectedStatus === "Upcoming" ? (
                    <span className="text-[32px] text-secondaryColor font-bold">
                      {upcomingOverview?.totalLienedPayables}
                    </span>
                  ) : selectedStatus === "Overdue" ? (
                    <span className="text-[32px] text-red font-bold">
                      {overdueOverview?.totalLienedPayables}
                    </span>
                  ) : (
                    <span className="text-[32px] text-primaryColor font-bold">
                      0
                    </span>
                  )}
                </span>
                <span className="text-[#7a7a7a] text-center text-[15px] font-medium flex justify-center">
                  {selectedStatus === "Liened"
                    ? "Total Liened Payables"
                    : selectedStatus === "Upcoming"
                    ? "Total Upcoming Payables"
                    : selectedStatus === "Overdue"
                    ? "Total Overdue Payables"
                    : ""}
                </span>
              </div>
            </section>
          </div>
        )}
      </main>
    </motion.div>
  );
}

export default LienApproveCard;
