import { motion } from "framer-motion";
import { PAIconArrowLeftGreen } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { useNavigate, useParams } from "react-router-dom";
import TransitionScale from "shared/animation/TransitionScale";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BulkDirectFundingInvoice from "./bulkDirectFundingInvoice";
import { fetchAllClosedMarketIngoingBidDetails } from "appstate/funder/funderSlice";

function FunderCollectionBulkDirectFundingInvoiceClosedMarket() {
  const { id } = useParams();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchAllClosedMarketIngoingBidDetails({ closeMarketId: id }));
  }, [dispatch, id]);

  const { ongoingBidDetails } = useSelector((state) => state?.funder);
  console.log(ongoingBidDetails);

  function handleGoBack() {
    navigate(-1);
  }

  return (
    <DashboardLayout
      section="Collections"
      subHeader="Track invoices due for payment collection"
    >
      <TransitionScale className="flex  flex-col gap-6 bg-[#EEE] h-full">
        <header onClick={handleGoBack} className="w-full">
          <div className="flex cursor-pointer w-fit gap-1 items-center">
            <PAIconArrowLeftGreen />
            <span className=" text-primaryColor text-sm">Go back</span>
          </div>
        </header>

        <main className="flex flex-col semi-lg:flex-row pb-8 gap-4 w-full ">
          <BulkDirectFundingInvoice
            invoiceByIdData={ongoingBidDetails}
            // setLockModalOpen={setLockModalOpen}
          />

          <motion.section
            initial={{
              y: -20,
            }}
            animate={{
              y: 0,
            }}
            exit={{
              y: 20,
            }}
            transition={{
              delay: 0.8,
            }}
            className="flex flex-col gap-3  pt-7  w-2/5 bg-white rounded-[10px]"
          >
            <header className="flex  px-6">
              <span className="font-bold">Closed Market</span>
            </header>

            <div>
              {" "}
              <div className="   pb-4">
                <div class="w-full justify-start items-center gap-4 inline-flex "></div>
                <div class=" justify-between  flex flex-row    px-6  ">
                  <div className="flex gap-2 py-2.5">
                    <div className="flex flex-col">
                      <span class="text-black text-sm font-normal ">
                        {
                          ongoingBidDetails?.closedMarketRequestDetails
                            ?.buyerCompanyName
                        }
                      </span>
                      <span class="text-[#949494] text-sm font-normal ">
                        Buyer
                      </span>
                    </div>
                  </div>
                  <div class=" flex-col justify-start items-start inline-flex">
                    {" "}
                    <div className="flex gap-2 py-2.5">
                      <div className="flex flex-col">
                        <span class="text-black text-sm font-normal ">
                          {
                            ongoingBidDetails?.closedMarketRequestDetails
                              ?.supplierCompanyName
                          }
                        </span>
                        <span class="text-[#949494] text-sm font-normal ">
                          Supplier
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* here */}
                </div>
                <div class=" justify-between  flex flex-row     px-6     mt-2">
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        {ongoingBidDetails?.closedMarketRequestDetails?.lockedRateAmount?.toLocaleString()}
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Lock Rate Amount
                      </div>
                    </div>
                  </div>
                  {/* dhdh */}
                  <div class=" justify-between  flex flex-row          mt-2">
                    <div class="py-2.5 flex-col justify-start items-start inline-flex">
                      <div class="flex-col justify-start items-start gap-0.5 flex">
                        <div class="text-black text-sm font-normal ">
                          {
                            ongoingBidDetails?.closedMarketRequestDetails
                              ?.paymentDate
                          }
                        </div>
                      </div>
                      <div class="flex-col justify-start items-start  flex">
                        <div class="text-[#949494] text-sm font-normal ">
                          Payment Date
                        </div>
                      </div>
                    </div>
                    {/* dhdh */}
                    <div class="py-2.5 flex-col justify-start items-start inline-flex"></div>
                  </div>
                </div>

                <div class=" justify-between  flex flex-row    px-6  mt-2 ">
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        {ongoingBidDetails?.closedMarketRequestDetails?.requestAmount?.toLocaleString()}
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Total Request Amount
                      </div>
                    </div>
                  </div>
                  {/* hd */}
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-base font-extrabold ">
                        {ongoingBidDetails?.closedMarketRequestDetails?.fundableAmount?.toLocaleString()}
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Total Receivable Amount
                      </div>
                    </div>
                  </div>{" "}
                </div>

                {/* 2nd */}
                {/* another header */}
                {/* header ends here */}
                {/* here */}
              </div>
            </div>
          </motion.section>
        </main>
      </TransitionScale>
    </DashboardLayout>
  );
}

export default FunderCollectionBulkDirectFundingInvoiceClosedMarket;
