import {
  PAIconArrowLeftGreen,
} from "assets/images/svgs";
import Table from "layout/table/Table";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AcceptModal from "../../single.js/Details/acceptModal";
import RejectModal from "../../single.js/Details/rejectModal";
// import { useSelector } from "react-redux";
import TransitionScale from "shared/animation/TransitionScale";

// import AcceptModal from "../../newSingleEarlyPayment/Details/acceptModal";
// import RejectModal from "../../newSingleEarlyPayment/Details/rejectModal";

const Details = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRejectModalOpen, setRejectModalOpen] = useState(false);
  const navigate = useNavigate();

  // const { paymentExtensionData, invoiceIds } = useSelector(
  //   (state) => state?.paymentExtension
  // );

  // const invoiceData = paymentExtensionData?.data?.find(
  //   (extension) => extension.invoiceIds === invoiceIds
  // );

  const goBack = () => {
    navigate(-1);
  };

  const handleAccept = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setRejectModalOpen(false);
  };

  const handleReject = () => {
    setRejectModalOpen(true);
  };

  const navigateToDetail = (id) => {
    let path = `/payment-extension/bulknewextensiondetailspreview/${id}`;
    navigate(path);
  };

  // const navigateToDetail = (id) =>
  //   navigate(
  //     `/payablefinancing-closedmarket/funderclosedmarketbuldetailspage/${id}`
  //   );

  const columns = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Invoice Number
        </span>
      ),
      selector: (row) => row.invNum,
    },

    {
      name: "Due Date",
      selector: (row) => row.dueDate,
    },
    {
      name: "Invoice Amount",
      selector: (row) => row.invoiceAmount,
    },
    {
      name: "Interest Rate",
      selector: (row) => row.interestRate,
    },
    {
      name: "Recievable Amount",
      selector: (row) => row.receivableAmount,
    },
  ];

  const tableData = [
    {
      bidAmount: 1,
      invNum: "INV-2090 ",
      dueDate: "2023-05-23",
      invoiceAmount: "9,000,000",
      interestRate: "1.5%",
      receivableAmount: "9,000,000",
    },
    {
      bidAmount: 2,
      invNum: "INV-2090 ",
      dueDate: "2023-05-23",
      invoiceAmount: "9,000,000",
      interestRate: "1.5%",
      receivableAmount: "9,000,000",
    },

    {
      bidAmount: 3,
      invNum: "INV-2090 ",
      dueDate: "2023-05-23",
      invoiceAmount: "9,000,000",
      interestRate: "1.5%",
      receivableAmount: "9,000,000",
    },
    {
      bidAmount: 4,
      invNum: "INV-2090 ",
      dueDate: "2023-05-23",
      invoiceAmount: "9,000,000",
      interestRate: "1.5%",
      receivableAmount: "9,000,000",
    },
  ];

  return (
    <div>
      <header className="rounded-md w-full bg-white mb-[17px] cursor-pointer items-center">
        <Link
          onClick={goBack}
          className="flex items-center justifycenter gap-x-[3px] mb-[20px] cursor-pointer py-3 ml-2 my-auto"
        >
          <PAIconArrowLeftGreen />
          <span className="flex items-center text-primaryColor">Go Back</span>
        </Link>
      </header>

      <div className="grid grid-row lg:grid-cols-[60%,40%] gap-5">
        <TransitionScale className=" pb-5 bg-white  rounded-[10px] pt-2">
          <section className="  mt-3">
            {/* Down Flex Texts */}
            <div className=" flex justify-between px-6 mt-4">
              {" "}
              <div class="text-[#222222] text-lg font-medium ">
                Reference No: | RF83018374BA
              </div>
            </div>
            <div className=" flex justify-between px-6">
              {/* first stage */}

              <div className="lg:w-[240px]  sm:w-full h-[117px] flex-col justify-start items-start gap-3 inline-flex  mt-5">
                <div className="self-stretch  flex-col justify-center items-start gap-1 flex ">
                  <div className="self-stretch justify-between items-center inline-flex">
                    <div className="text-[#222222] text-xs font-semibold ">
                      Request Date:
                    </div>
                    <div class="text-[#7a7a7a] text-xs font-semibold ">
                      23-Mar -2023
                    </div>
                  </div>
                  <div className="self-stretch justify-between items-center inline-flex">
                    <div className="text-[#222222] text-xs font-semibold ">
                      Total Request amount
                    </div>
                    <div className="text-[#7a7a7a] text-xs font-semibold ">
                      24,000,000
                    </div>
                  </div>

                  <div className="self-stretch justify-between items-center inline-flex">
                    <div className="text-[#222222] text-xs font-semibold ">
                      Total Invoice amount
                    </div>
                    <div className="text-[#7a7a7a] text-xs font-semibold ">
                      34,000,000
                    </div>
                  </div>
                </div>

                {/* stop */}
              </div>
              {/* second stage */}

              <div className=" mt-5">
                {" "}
                <div className="flex justify-between items-center mt-5 ">
                  {" "}
                  <div className="h-[30px] px-8 py-2 bg-[#F0800021]/20 rounded-[5px] justify-center items-center gap-2.5 inline-flex">
                    <div className="text-center text-[#F08000] text-xs font-medium ">
                      Approved
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* stops here */}
            <div className="px-6">
              <header className="border-b-[5px] border-b-[#F6F5FA] mt3  flex justify-between py-1">
                {/* <h1 className="text-[18px] font-semibold">
                  Supplier/buyer details
                </h1> */}
                <div>
                  <div className="text-[#222222] text-lg font-semibold  ">
                    Nestle PLC
                  </div>
                  <div className="text-[#7a7a7a] text-xs font-light  ">
                    Buyer details
                  </div>
                </div>

                <div className="h-[41px] flex-col justify-start items-start  inline-flex px-12">
                  <div className="text-[#222222] text-base font-semibold ">
                    60 Days
                  </div>
                  <div className="w-[100px] text-[#7a7a7a] text-xs font-light  mt-1">
                    Payment
                  </div>
                </div>

                <div className="h-[43px] flex-col justify-start items-end  inline-flex">
                  <div className="text-[#222222] text-lg font-semibold ">NGN</div>
                  <div className="text-[#7a7a7a] text-xs font-semibold mt-1">
                    Currency
                  </div>
                </div>
              </header>

              <header className="border-b-[5px] border-b-[#F6F5FA] mt-6  flex justify-between py-1">
                <div className="text-[#11192a] text-sm font-semibold ">
                  Invoice List
                </div>
                <div className="text-[#11192a] text-lg font-light  mr-6">
                  4 Invoices
                </div>
              </header>

              {/* <div className=" w-full overflow-x-auto mt-3 pb-4"></div> */}

              <div className=" w-full overflow-x-auto mt-3 ">
                <Table
                  columns={columns}
                  data={tableData}
                  pointer
                  className=""
                  onRowClicked={(row) => navigateToDetail(row.bidAmount)}
                  progressPending={false}
                  // progressComponent={<Loading />}
                />
              </div>
            </div>
          </section>
        </TransitionScale>

        <div className="bg-white rounded-[10px] gap-32 mt-5 lg:mt-0 mr-5">
          <TransitionScale>
            <div className="py-8">
              <div className="text-black text-lg font-semibold px-6">
                Bulk Extension Request
              </div>
              {/* starts here */}
              <div className="border-b border-gray shadow">
                {" "}
                {/* second */}
                <div className="pb-4">
                  <div className=" justify-between flex flex-row px-6 mt-4">
                    <div className="flex gap-2 py-2.5">
                      <div className="flex flex-col">
                        <span className="text-black text-sm font-normal ">
                          Coca Cola PLC
                        </span>
                        {/* <span className="text-[#979797]  text-xs">Supplier</span> */}
                        <span className="text-[#949494] text-sm font-normal ">
                          Buyer
                        </span>
                      </div>
                    </div>

                    <div className="py-2.5 flex-col justify-end items-end inline-flex">
                      <div className="flex-col justify-end items-end gap-0.5 flex">
                        <div className="text-black text-sm font-normal ">
                          23-Dec-2024 (90 days)
                        </div>
                      </div>
                      <div className="flex-col justify-end items-end  flex">
                        <div className="text-[#949494] text-sm font-normal ">
                          Early Payment Request date
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" justify-between  flex flex-row    px-6  mt-6 ">
                    <div className="py-2.5 flex-col justify-start items-start inline-flex">
                      <div className="flex-col justify-start items-start gap-0.5 flex">
                        <div className="text-black text-sm font-normal ">
                          N90,000
                        </div>
                      </div>
                      <div className="flex-col justify-start items-start  flex">
                        <div className="text-[#949494] text-sm font-normal ">
                          Total invoice amount
                        </div>
                      </div>
                    </div>
                    {/* hd */}
                    <div className="py-2.5 flex-col justify-start items-start inline-flex">
                      <div className="flex-col  gap-0.5 flex w-full">
                        <div className="text-black text-sm font-normal text-end ">
                          8,9110,000
                        </div>
                      </div>
                      <div className="flex-col justify-start items-start  flex">
                        <div className="text-[#949494] text-sm font-normal ">
                          Total interest amount
                        </div>
                      </div>
                    </div>{" "}
                  </div>

                  {/* 2nd */}
                  <div className="border-t border-gray justify-between  flex flex-col     px-6   ">
                    <div className="text-black text-lg font-semibold  mt-6">
                      Note from Buyer:{" "}
                    </div>

                    <div className=" text-[#949494] text-sm font-light  mt-4  leading-6">
                      Seeking Early payment due to unexpected challenges. Facing
                      temporary financial constraints and need a bit more time
                      to ensure a smooth transaction. Appreciate your
                      understanding.
                      <br className=" mt-3" />
                      Thank you.
                      <br className=" mt-3" />
                      Best regards, <br />
                      ABC Limited
                    </div>
                  </div>
                </div>
              </div>
              {/* ACTION */}
              <div className="flex items-center my-5 gap-5 mt-5 px-6">
                <button
                  className="rounded-md bg-[#07593D] text-white px-6 py-2"
                  onClick={handleAccept}
                >
                  Accept Request
                </button>
                <button
                  className="rounded-md bg-white border border-[#F08000] text-[#F08000] px-6 py-2"
                  onClick={handleReject}
                >
                  Reject Request
                </button>
              </div>
            </div>
          </TransitionScale>
        </div>
      </div>
      <AcceptModal isOpen={isModalOpen} onClose={handleCloseModal} />
      <RejectModal isOpen={isRejectModalOpen} onClose={handleCloseModal} />
    </div>
    // </div>
    // </div>
  );
};

export default Details;
