import { AnimatePresence } from "framer-motion";
import OpenMarketTabCard from "./cards/OpenMarketTabCard";
import TransitionOpacity from "shared/animation/TransitionOpacity";
import { useEffect, useState } from "react";

import OfferDetailsModal from "./modal/OfferDetailsModal";
import InvoiceLockSuccessModal from "./modal/InvoiceLockSuccessModal";
import ViewDocumentModal from "features/RulesConfig/common/components/modals/DeleteRuleModal";
import { useDispatch, useSelector } from "react-redux";
import {
  funderPlaceBidOpenMarket,
  getInvoicesInOpenMarketFunder,
  // lockInvoice,
  // placeInvoiceBid,
} from "appstate/openMarket/openMarketSlice";
import LoadingScreen from "shared/LoadingScreen";

import EmptyPage from "features/roleManager/Dashbaord/components/EmptyPage";
import SelectAccountModal from "./modal/SelectAccountModal";
import EnterPin from "./modal/Pin";
import VerifyLien from "./modal/VerifyLienModal";
import Pagination from "shared/Pagination";
import toast from "react-hot-toast";

function OpenMarketTab({ openMarketQuery, setActiveTab }) {
  const dispatch = useDispatch();
  const [status, setStatus] = useState("");
  const [id, setId] = useState("");
  const [discountRate, setDiscountRate] = useState("");
  const [refNumber, setRefNumber] = useState("");
  const [selectedAccountDetails, setSelectedAccountDetails] = useState({});
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(3);
  const pageSize = 10;

  const [offerDetailModalOpen, setOfferDetailModalOpen] = useState(false);
  const [selectAccountModal, setSelectAccountModal] = useState(false);
  const [bidRequest, setBidRequest] = useState(false);
  const [confirmSelectedAccountModal, setConfirmSelectedAccountModal] =
    useState(false);
  const [pinModal, setPinModal] = useState(false);
  const [verifyLienStatusModal, setVerifyLienStatusModal] = useState(false);
  const [invoiceLockedSuccessModal, setInvoiceLockedSuccessModal] =
    useState(false);

  // console.log(selectedAccountDetails);
  // Handle Offer Detail
  // const handleOfferDetail = () => {
  //   setOfferDetailModalOpen(false);
  // };

  const handleAction = () => {
    setOfferDetailModalOpen(false);
    setTimeout(() => {
      setBidRequest(true);
    }, 500);
  };

  const handleProceedToSelectAccount = () => {
    setBidRequest(false);
    setTimeout(() => {
      setSelectAccountModal(true);
    }, 500);
  };

  const handleConfirmAccount = () => {
    setSelectAccountModal(false);
    setTimeout(() => {
      setConfirmSelectedAccountModal(true);
    }, 500);
  };

  const handleProceedEnterPin = () => {
    setConfirmSelectedAccountModal(false);
    setTimeout(() => {
      setPinModal(true);
    }, 500);
  };

  const handlePlaceBid = (pin) => {
    const pendingToast = toast.loading("Placing your Bid...");
    const body = {
      discountRate,
      note: "",
      virtualAccountId: selectedAccountDetails?.virtualAccountId,
      transactionPin: pin,
    };

    dispatch(funderPlaceBidOpenMarket({ invoiceId: id, body })).then((data) => {
      if (data?.payload?.success) {
        toast.dismiss(pendingToast);
        setPinModal(false);
        setVerifyLienStatusModal(true);
        dispatch(
          getInvoicesInOpenMarketFunder({
            page,
            pageSize,
            searchQuery: openMarketQuery,
            status,
          })
        );
        setDiscountRate("");
      } else {
        toast.dismiss(pendingToast);
      }
    });
  };

  useEffect(() => {
    dispatch(
      getInvoicesInOpenMarketFunder({
        page,
        pageSize,
        searchQuery: openMarketQuery,
        status,
      })
    );
  }, [dispatch, page, pageSize, status, openMarketQuery]);

  const { isLoading, getInvoicesInOpenMarketFunderData } = useSelector(
    (state) => state?.openMarket
  );

  const filteredBids = getInvoicesInOpenMarketFunderData?.data?.filter(
    (bid) => {
      if (status === "") return true;
      if (status === "BID_ACTIVE") return bid?.status === "BID_ACTIVE";
      if (status === "POSTED") return bid?.status === "POSTED";
      return true;
    }
  );

  return (
    <TransitionOpacity className=" min-h-[75vh] bg-unusualGray rounded-[5px] p-6">
      <div className="w-full mb-4 flex items-center gap-2.5">
        <span className="py-2.5">Filter Bids:</span>

        <select
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          className=" text-sm font-medium border border-[#959595] rounded-[5px] px-5 py-2.5 outline-none cursor-pointer"
        >
          <option value="" key="">
            All
          </option>
          <option value="BID_ACTIVE" key="">
            Bid Active Bids
          </option>
          <option value="POSTED" key="">
            Posted Bids
          </option>
        </select>
      </div>
      {isLoading ? (
        <LoadingScreen />
      ) : getInvoicesInOpenMarketFunderData?.data?.length > 0 ? (
        <div className="flex flex-col gap-4 ">
          {/* {allOpenMarketInvoicesData?.data?.map((invoice, index) => ( */}
          {filteredBids?.map((invoice, index) => (
            <OpenMarketTabCard
              selectId={id}
              setId={setId}
              setRefNum={setRefNumber}
              key={invoice?.openMarketReference}
              lockInvoice={setOfferDetailModalOpen}
              id={invoice?.invoiceId}
              lockRate={invoice?.lockRate}
              invoiceNumber={invoice?.openMarketReference}
              buyerCompanyName={invoice?.buyerCompanyName}
              supplierCompanyName={invoice?.supplierCompanyName}
              description={invoice?.description}
              invoiceAmount={invoice?.fundingAmount}
              lastModifiedDate={invoice?.lastModifiedDate}
              dueDate={invoice?.paymentDate}
              status={invoice?.status}
              numberOfBids={invoice?.numberOfBids}
              fundRequestDate={invoice?.createdDate?.join("-")}
              setDiscountRate={setDiscountRate}
            />
          ))}

          <div className="flex items-center h-full justify-center">
            {getInvoicesInOpenMarketFunderData?.data?.meta?.totalElements >=
              pageSize && (
              <Pagination
                page={page}
                startPage={startPage}
                endPage={endPage}
                setEndPage={setEndPage}
                setPage={setPage}
                setStartPage={setStartPage}
                totalPages={
                  getInvoicesInOpenMarketFunderData?.data?.meta?.totalElements
                }
              />
            )}
          </div>
        </div>
      ) : (
        <EmptyPage />
      )}

      {/* Offer Detail Modal */}
      <AnimatePresence>
        {offerDetailModalOpen && (
          <OfferDetailsModal
            id={refNumber}
            discountRate={discountRate}
            setDiscountRate={setDiscountRate}
            onAction={handleAction}
            onClose={() => setOfferDetailModalOpen(false)}
          />
        )}
      </AnimatePresence>

      {/* Approve Bid Request */}
      <AnimatePresence>
        {bidRequest && (
          <ViewDocumentModal
            onAction={() => handleProceedToSelectAccount()}
            onClose={() => setBidRequest(false)}
            proceed="Yes Proceed"
          >
            <span className=" text-center">
              Are you sure you want to{" "}
              <span className="font-bold text-center text-primaryColor">
                Bid
              </span>{" "}
              on this request with reference number <br />
              <span className="font-bold text-center text-primaryColor">
                {refNumber}
              </span>{" "}
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>

      {/* Select Account Modal */}
      <AnimatePresence>
        {selectAccountModal && (
          <SelectAccountModal
            setSelectedAccountDetails={setSelectedAccountDetails}
            onClose={() => setSelectAccountModal(false)}
            onAction={() => {
              handleConfirmAccount();
            }}
          />
        )}
      </AnimatePresence>

      {/* Approve Selected Account */}
      <AnimatePresence>
        {confirmSelectedAccountModal && (
          <ViewDocumentModal
            onAction={() => handleProceedToSelectAccount()}
            onClose={() => setConfirmSelectedAccountModal(false)}
            proceed="Yes Proceed"
          >
            <span className="text-center">
              Are you sure you want to proceed with this account{" "}
              <span className="font-bold text-center text-primaryColor">
                {selectedAccountDetails?.accountNumber}
              </span>{" "}
              to bid on this invoice
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>

      {/* Approve Selected Account */}
      <AnimatePresence>
        {confirmSelectedAccountModal && (
          <ViewDocumentModal
            onAction={() => handleProceedEnterPin()}
            onClose={() => setConfirmSelectedAccountModal(false)}
            proceed="Yes Proceed"
          >
            <span className=" text-center">
              Are you sure you want to proceed with this account{" "}
              <span className="font-bold text-center text-primaryColor">
                {selectedAccountDetails?.accountNumber}
              </span>{" "}
              to bid on this invoice
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>

      {/* Proceed to Enter pin */}

      <AnimatePresence>
        {pinModal && (
          <EnterPin
            onClose={() => setPinModal(false)}
            onSubmit={(pin) => {
              handlePlaceBid(pin);
            }}
          />
        )}
      </AnimatePresence>

      {verifyLienStatusModal && (
        <VerifyLien
          onClose={() => setVerifyLienStatusModal(false)}
          onAction={() => {
            setVerifyLienStatusModal(false);
            setTimeout(() => {
              setInvoiceLockedSuccessModal(true);
            }, 500);
          }}
        />
      )}

      <AnimatePresence>
        {invoiceLockedSuccessModal && (
          <InvoiceLockSuccessModal
            setActiveTab={setActiveTab}
            onClose={() => setInvoiceLockedSuccessModal(false)}
          />
        )}
      </AnimatePresence>
    </TransitionOpacity>
  );
}

export default OpenMarketTab;
