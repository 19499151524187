import { motion } from "framer-motion";

import {
  PAIconSmallBrewIcon,
  PAIconSmallCoke,
  PAIconSmallDangote,
  PAIconSmallNestle,
  PAIconSmallShell,
  PAIconSmallTrust,
  PAIconCocacola,
  PAIconInvShop,
} from "assets/images/svgs";

function ReportAndAnalisCard({
  invoiceNumber = "N/A",
  supplierCompanyName = "N/A",
  invoiceAmount = "N/A",
  numberofBidders = "N/A",
  invoiceDueDate = "N/A",
  requestType = "N/A",
  doerType = "N/A",
}) {
  return (
    <motion.div
      whileTap={{ scale: 0.98 }}
      style={{ boxShadow: "0px 2px 4px 0px rgba(232, 226, 216, 0.36)" }}
      className="flex cursor-pointer justify-between bg-white py-5 px-6 rounded-[10px]"
    >
      <span className="flex gap-6  items-center">
        <PAIconInvShop />
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{invoiceNumber}</span>
          <span className=" text-[#959595] text-[10px]">Invoice number</span>
        </div>
      </span>

      <span className="flex gap-6  items-center">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{invoiceNumber}</span>
          <span className=" text-[#959595] text-[10px]">Invoice number</span>
        </div>
      </span>

      <span className="flex gap-6  items-center">
        <div
          style={
            requestType === "Bulk"
              ? { backgroundColor: "#E1EBE8" }
              : { backgroundColor: "#FDF0E0" }
          }
          className="flex flex-col justify-center w-[100px] p-2 rounded-[8px] text-center"
        >
          <span className="font-[500] text-sm">{requestType}</span>
        </div>
      </span>

      <span className="flex gap-6 items-center ">
        {supplierCompanyName === "Coca Cola" ? (
          <PAIconCocacola />
        ) : supplierCompanyName === "Coke Plc." ? (
          <PAIconSmallCoke />
        ) : supplierCompanyName === "Dangote" ? (
          <PAIconSmallDangote />
        ) : supplierCompanyName === "Nestle" ? (
          <PAIconSmallNestle />
        ) : supplierCompanyName === "Shell  Ltd." ? (
          <PAIconSmallShell />
        ) : supplierCompanyName === "Trust" ? (
          <PAIconSmallTrust />
        ) : (
          <PAIconSmallBrewIcon />
        )}
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{supplierCompanyName}</span>
          <span className=" text-[#959595] text-[10px]">{doerType}</span>
        </div>
      </span>

      <span className="hidden extra-md:flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span class="text-secondaryColor text-sm font-medium font-['SF Pro Display']">
            Bid Active
          </span>
          <span class="text-[#959595] text-[10px] font-normal font-['SF Pro Display']">
            Status of Request
          </span>
        </div>
      </span>

      <span className="hidden extra-md:flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span class="text-[#07593D] text-sm font-medium font-['SF Pro Display']">
            N{numberofBidders}
          </span>
          <span class="text-[#959595] text-[10px] font-normal font-['SF Pro Display']">
            Request Amount
          </span>
        </div>
      </span>

      <span className="hidden extra-md:flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span class="text-[#07593D] text-sm font-medium font-['SF Pro Display']">
            {numberofBidders}
          </span>
          <span class="text-[#959595] text-[10px] font-normal font-['SF Pro Display']">
            Number of Bidders
          </span>
        </div>
      </span>

      <span className="hidden extra-md:flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span class="text-sm font-medium font-['SF Pro Display']">
           4.5%
          </span>
          <span class="text-[#959595] text-[10px] font-normal font-['SF Pro Display']">
            Lock Rate
          </span>
        </div>
      </span>

      <span className="flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{invoiceAmount}</span>
          <span className=" text-[#959595] text-[10px]">Invoice Amount</span>
        </div>
      </span>

      <span className="hidden extra-md:flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">01d:13h:55m:023s</span>
          <span className=" text-[#959595] text-[10px]">Count down to the end of bid</span>
        </div>
      </span>
    </motion.div>
  );
}

export default ReportAndAnalisCard;
