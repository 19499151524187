import React, { useState } from "react";
import DashboardLayout from "layout/dashboardlayout";
import {
  PAIconArrowLeftGreen,
  PAEditTableIcon,
  PADeleteTableIcon,
} from "assets/images/svgs";
import { useNavigate } from "react-router-dom";

import TransitionScale from "shared/animation/TransitionScale";
import PendingApprovalSucessModal from "../components/modal/PendingApprovalSucessModals";
import PendingApprovalProceedModal from "../components/modal/PendingApprovalProceedModal";
import PendingApprovalDeclineSucessModal from "../components/modal/PendingApprovalDeclineSuccess";
import PendingApprovalDeclineModal from "../components/modal/PendingApprovalDeclineModal";
import ReasonForDeclineModal from "../components/modal/ReasonForDeclineModal";
import { AnimatePresence } from "framer-motion";

// import { useNavigate } from "react-router-dom";

const InvoiceUploadScreen = () => {
  const [ongoingCancelBidModal, setOngoingCancelBidModal] = useState(false);
  const [ongoingBidCancelledSuccessModal, setOngoingBidCancelledSuccessModal] =
    useState(false);

  const [ongoingCancelBidModal2, setOngoingCancelBidModal2] = useState(false);
  const [
    ongoingBidCancelledSuccessModal2,
    setOngoingBidCancelledSuccessModal2,
  ] = useState(false);
  const [reasonforDecline, setReasonForDecline] = useState(false);

  const handleOnCancelSuccessClose = () => {
    setOngoingBidCancelledSuccessModal(false);
    navigate(-1);
  };
  const handleOnCancelBidTrue = () => {
    setOngoingCancelBidModal(false);
    setOngoingBidCancelledSuccessModal(true);
  };
  const handleOnCancelSuccessClose2 = () => {
    setOngoingBidCancelledSuccessModal2(false);
    navigate(-1);
  };
  const handleOnCancelBidTrue2 = () => {
    setOngoingCancelBidModal2(false);
    // setOngoingBidCancelledSuccessModal2(true);
    setReasonForDecline(true);
  };

  const handleDeclineContinue = () => {
    setReasonForDecline(false);
    setOngoingBidCancelledSuccessModal2(true);
  };

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  const dummyData = [
    {
      bidAmount: 3,
      InvoiceNo: "BGZ099 ",
      CurrencyCode: "NGN",
      Company: "ABC Corp",
      DueDate: "2024-09-21",
      IssuanceDate: "2024-10-23",
      Discount: "2.5%",
      VAT: "7.5%",
      TotalAmount: "3,000.00",
      AccountName: "ABC Corp",
      AccountNo: "0213522134",
      BankName: "Zenith",
    },

    {
      bidAmount: 4,
      InvoiceNo: "BGZ099 ",
      CurrencyCode: "NGN",
      Company: "ABC Corp",
      DueDate: "2024-09-21",
      IssuanceDate: "2024-10-23",
      Discount: "2.5%",
      VAT: "7.5%",
      TotalAmount: "3,000.00",
      AccountName: "ABC Corp",
      AccountNo: "0213522134",
      BankName: "Zenith",
    },

    {
      bidAmount: 5,
      InvoiceNo: "BGZ099 ",
      CurrencyCode: "NGN",
      Company: "ABC Corp",
      DueDate: "2024-09-21",
      IssuanceDate: "2024-10-23",
      Discount: "2.5%",
      VAT: "7.5%",
      TotalAmount: "3,000.00",
      AccountName: "ABC Corp",
      AccountNo: "0213522134",
      BankName: "Zenith",
    },
    {
      bidAmount: 6,
      InvoiceNo: "BGZ099 ",
      CurrencyCode: "NGN",
      Company: "ABC Corp",
      DueDate: "2024-09-21",
      IssuanceDate: "2024-10-23",
      Discount: "2.5%",
      VAT: "7.5%",
      TotalAmount: "3,000.00",
      AccountName: "ABC Corp",
      AccountNo: "0213522134",
      BankName: "Zenith",
    },

    {
      bidAmount: 7,
      InvoiceNo: "BGZ099 ",
      CurrencyCode: "NGN",
      Company: "ABC Corp",
      DueDate: "2024-09-21",
      IssuanceDate: "2024-10-23",
      Discount: "2.5%",
      VAT: "7.5%",
      TotalAmount: "3,000.00",
      AccountName: "ABC Corp",
      AccountNo: "0213522134",
      BankName: "Zenith",
    },
    {
      bidAmount: 8,
      InvoiceNo: "BGZ099 ",
      CurrencyCode: "NGN",
      Company: "ABC Corp",
      DueDate: "2024-09-21",
      IssuanceDate: "2024-10-23",
      Discount: "2.5%",
      VAT: "7.5%",
      TotalAmount: "3,000.00",
      AccountName: "ABC Corp",
      AccountNo: "0213522134",
      BankName: "Zenith",
    },
    {
      bidAmount: 9,
      InvoiceNo: "BGZ099 ",
      CurrencyCode: "NGN",
      Company: "ABC Corp",
      DueDate: "2024-09-21",
      IssuanceDate: "2024-10-23",
      Discount: "2.5%",
      VAT: "7.5%",
      TotalAmount: "3,000.00",
      AccountName: "ABC Corp",
      AccountNo: "0213522134",
      BankName: "Zenith",
    },
  ];

  return (
    <>
      <TransitionScale>
        <PendingApprovalProceedModal
          isOpen={ongoingCancelBidModal}
          onClose={() => setOngoingCancelBidModal(false)}
          handleYes={handleOnCancelBidTrue}
        />
      </TransitionScale>
      <TransitionScale>
        <PendingApprovalSucessModal
          isOpen={ongoingBidCancelledSuccessModal}
          onClose={handleOnCancelSuccessClose}
          message={"Request Approved"}
        />
      </TransitionScale>
      <TransitionScale>
        <PendingApprovalProceedModal
          isOpen={ongoingCancelBidModal}
          onClose={() => setOngoingCancelBidModal(false)}
          handleYes={handleOnCancelBidTrue}
        />
      </TransitionScale>

      <AnimatePresence>
        <TransitionScale>
          <PendingApprovalDeclineSucessModal
            isOpen={ongoingBidCancelledSuccessModal2}
            onClose={handleOnCancelSuccessClose2}
            message={"Request Declined"}
          />
        </TransitionScale>
      </AnimatePresence>
      <AnimatePresence>
        <TransitionScale>
          <PendingApprovalDeclineModal
            isOpen={ongoingCancelBidModal2}
            onClose={() => setOngoingCancelBidModal2(false)}
            handleYes={handleOnCancelBidTrue2}
            message={" Are you sure you would like to decline this"}
            midMessage={"workflow?"}
          />
        </TransitionScale>
      </AnimatePresence>
      <AnimatePresence>
        <TransitionScale>
          {reasonforDecline && (
            <ReasonForDeclineModal
              onClose={() => setReasonForDecline(false)}
              onContinue={handleDeclineContinue}
            />
          )}
        </TransitionScale>
      </AnimatePresence>

      <DashboardLayout
        section="Workflow "
        subHeader="Manage all your workflow approvals"
      >
        <div className=" lg:min-h-screen">
          {" "}
          <header className=" text-primaryColor ">
            <span
              onClick={() => handleBack()}
              className="flex cursor-pointer  w-fit font-light items-center text-sm gap-2"
            >
              <PAIconArrowLeftGreen />
              Go Back
            </span>
          </header>
          <>
            {/* First Phase */}

            <TransitionScale className="px-6 pt-6 pb-8 bg-white  rounded-[10px] mt-5 ">
              <div className="lg:flex md:flex justify-between  w-full sm:block  ">
                <div>
                  {" "}
                  <div class="text-[#11192a] text-xs font-bold ">
                    Approval Stage
                  </div>
                </div>

                {/* <div>
                {" "}
                <div class="h-[30px] px-8 py-2 bg-[#de1515]/30 rounded-[5px] justify-center items-center gap-2.5 inline-flex">
                  <div class="text-center text-[#de1515] text-xs font-semibold">
                    Declined
                  </div>
                </div>
              </div> */}

                <div className=" flex gap-3">
                  {/* <button
                  className={` w-24   py-2 px-3  text-[#222222] justify-center  flex items-center bg-[#D6D6D6] rounded-[5px]  text-sm ${"cursor-pointer"}`}
                  // onClick={() => setOngoingCancelBidModal(true)}
                  //   onClick={() => setOngoingCancelBidModal2(true)}
                  // disabled={termsIsVisible ? false : true}
                >
                  Confirm
                </button> */}
                  <button
                    className={` w-24   py-2 px-3  text-[#de1515]  border border-[#de1515] justify-center  flex items-center bg-white rounded-[5px]  text-sm ${"cursor-pointer"}`}
                    //   class=" px-6 py-2 bg-white rounded-[5px] border border-[#de1515] justify-center items-center gap-2.5 inline-flex"
                    onClick={() => setOngoingCancelBidModal2(true)}
                  >
                    <div>Decline</div>
                  </button>
                  <button
                    className={`w-24  px-3 text-white justify-center  flex items-center bg-[#07593d] rounded-[5px] py-2 text-sm ${"cursor-pointer"}`}
                    //   onClick={() =>
                    //     navigate("/invoice/invoice/fundingSection/invoiceupload")
                    //   }
                    onClick={() => setOngoingCancelBidModal(true)}
                  >
                    Approve
                  </button>
                </div>
              </div>

              <div className="lg:flex md:flex justify-between  w-full sm:block  mt-3 ">
                {/* underlined div */}
                <div className="">
                  <div>
                    <span className="text-[#7a7a7a] text-xs font-bold ">
                      User
                    </span>
                    <span className="text-[#7a7a7a] text-xs font-normal ">
                      {" "}
                      to approve
                    </span>
                  </div>
                  <div className="mb-[15px] mt-[10px] flex justify-center items-center gap-x-[15px] ">
                    <div>
                      <div className="w-[100px] h-1   bg-[#F08000]  rounded-[100px] lg:w-[150px]  xs:w-[70px]"></div>
                    </div>
                    <div>
                      <div className="w-[100px] h-1    bg-[#F08000]/30  rounded-[100px] lg:w-[150px]  xs:w-[70px]"></div>
                    </div>
                    <div>
                      <div className="w-[100px] h-1  bg-[#F08000]/30  rounded-[100px] lg:w-[150px] xs:w-[70px] "></div>
                    </div>
                  </div>
                </div>

                {/* Button division */}

                <div>
                  {" "}
                  {/* <div class="h-[30px] px-8 py-2 bg-[#07593d]/30 rounded-[5px] justify-center items-center gap-2.5 inline-flex">
                  <div class="text-center text-[#07593d] text-xs font-semibold">
                    Completed
                  </div>
                </div> */}
                </div>
              </div>

              {/* Second Box phase and pages */}
              <div className="lg:flex md:flex justify-between  w-full sm:block  mt-6 ">
                <div>
                  <div class=" flex-col justify-center items-start gap-2 inline-flex">
                    <div class="self-stretch justify-between items-center inline-flex gap-28">
                      <div class="text-[#222222] text-[14px] font-semibold">
                        Date Sent:
                      </div>
                      <div class="text-[#7a7a7a] text-[14px] font-medium ">
                        2023-02-19
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex gap-28">
                      <div class="text-[#222222] text-[14px] font-semibold">
                        Transaction Type:
                      </div>
                      <div class="text-[#7a7a7a] text-[14px] font-medium ">
                        Invoicing
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex  gap-28">
                      <div class="text-[#222222] text-[14px] font-semibold">
                        Action:
                      </div>
                      <div class="text-[#7a7a7a] text-[14px] font-medium ">
                        Creation of Invoice
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-[14px] font-semibold">
                        Approval Stage:
                      </div>
                      <div class="text-[#7a7a7a] text-[14px] font-medium ">
                        1 of 3
                      </div>
                    </div>
                  </div>
                </div>

                {/* Second side */}

                <div>
                  <div class=" flex-col justify-center items-start gap-2 inline-flex">
                    <div class="self-stretch justify-between items-center inline-flex gap-28">
                      <div class="text-[#222222] text-[14px] font-semibold">
                        Sender:
                      </div>
                      <div class="text-[#7a7a7a] text-[14px] font-medium ">
                        First User
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex gap-28">
                      <div class="text-[#222222] text-[14px] font-semibold">
                        Previous Approver:
                      </div>
                      <div class="text-[#7a7a7a] text-[14px] font-medium ">
                        Admin
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex  gap-28">
                      <div class="text-[#222222] text-[14px] font-semibold">
                        Next Approver:
                      </div>
                      <div class="text-[#7a7a7a] text-[14px] font-medium ">
                        Sam Johnson
                      </div>
                    </div>
                  </div>
                  {/* Button */}
                </div>
              </div>
            </TransitionScale>

            <TransitionScale className=" pb-5 bg-white  rounded-[10px] mt-5 ">
              {/* Header with Left Padding */}

              {/* Other sections */}

              <section>
                {/* stops here */}
                <div>
                  <header className=" bg-[#FAFAFA]   w-full  h-[49px]  px-6  bg-neutral-50  flex-col items-start inline-flex justify-center rounded-t-[10px] ">
                    <div class="text-black text-sm font-medium ">
                      Review Invoice content
                    </div>
                    {/* <h1 className="text-[18px] font-semibold">
                  Supplier/buyer details
                </h1> */}
                  </header>

                  {/* filter section */}
                  <div className=" flex items-end justify-end align-middle py-6 px-5">
                    <div className=" flex gap-3">
                      <div className=" items-center justify-center flex">
                        {" "}
                        <div class="text-[#7a7a7a] text-sm font-medium ">
                          Select fields to view:
                        </div>
                      </div>{" "}
                      <div>
                        {" "}
                        <select
                          className=" shadow-sm  px-4 py-2.5   focus:outline-none cursor-pointer h-[41px]    justify-start items-center  text-[#7a7a7a] text-xs font-normal bg-white rounded-[5px] border border-[#dedede]/80  gap-[7px] "
                          onChange={(e) => {
                            // const selectedSortBy = e.target.value;
                          }}
                        >
                          <option
                            value=""
                            disabled
                            selected
                            className="overflow-x-hidden"
                          >
                            {" "}
                            View Fields
                          </option>
                          <option value="Account Name">Account Name</option>
                          <option value="Account No">Account No</option>
                          <option value="Bank Name">Bank Name</option>
                          <option value="Item Name">Item Name</option>
                          <option value="Item Desc">Item Desc</option>
                          <option value="Quantity">Quantity</option>
                          <option value="Unit Price">Unit Price</option>
                          <option value="Unit Price">Tax Rate</option>
                          <option value="Unit Price">Amount</option>
                          <option value="Unit Price">Sub Total</option>
                        </select>
                      </div>
                      {/* <div className="flex justify-end items-center">
                        <select className=" border border-white bg-white text-[#07593D] rounded cursor-pointer  w-16 h-[41px]"></select>
                      </div> */}
                    </div>
                  </div>

                  <div className=" w-full overflow-x-auto mt-3 pb-4 px-2 ">
                    <table className=" w-full overflow-x-auto">
                      <thead className="bg-[#FAFAFA] w-full py-5  border-y-[1px]  border-[#cccccc] ">
                        <tr className=" bg-[#FAFAFA]  px-6 w-full py-5 ">
                          {/* <td className="text-[#11192A] p-2.5 text-xs font-bold">
                          Item Number
                        </td> */}
                          <td class="text-[#7A7A7A] text-xs font-semibold py-3">
                            Invoice No
                          </td>
                          <td class="text-[#7A7A7A] text-xs font-semibold  ">
                            Currency Code
                          </td>
                          <td class="text-[#7A7A7A] text-xs font-semibold  ">
                            Company
                          </td>
                          <td class="text-[#7A7A7A] text-xs font-semibold  ">
                            Due Date
                          </td>
                          <td class="text-[#7A7A7A] text-xs font-semibold  ">
                            Issuance Date
                          </td>
                          <td class="text-[#7A7A7A] text-xs font-semibold  ">
                            Discount
                          </td>
                          <td class="text-[#7A7A7A] text-xs font-semibold  ">
                            VAT
                          </td>
                          <td class="text-[#7A7A7A] text-xs font-semibold ">
                            Total Amount
                          </td>
                          <td class="text-[#7A7A7A] text-xs font-semibold ">
                            Account Name
                          </td>
                          <td class="text-[#7A7A7A] text-xs font-semibold ">
                            Account No
                          </td>
                          <td class="text-[#7A7A7A] text-xs font-semibold ">
                            Bank Name
                          </td>
                          <td class="text-[#7A7A7A] text-xs font-semibold ">
                            Actions
                          </td>
                        </tr>
                      </thead>

                      <tbody className="  ">
                        {dummyData.map((cell, idx) => {
                          return (
                            <tr key={idx} className=" w-full ">
                              <td className="text-black text-xs font-normal  mt-2 p-4  border-[1px]  border-[#cccccc]">
                                {cell.InvoiceNo}
                              </td>
                              <td className="text-black text-xs font-normal  mt-2 p-4  border-[1px]  border-[#cccccc]">
                                {cell.CurrencyCode}
                              </td>
                              <td className="text-black text-xs font-normal  mt-2 p-4  border-[1px]  border-[#cccccc]">
                                {cell.Company}
                              </td>
                              <td className="text-black text-xs font-normal  mt-2 text-center  border-[1px]  border-[#cccccc]">
                                {cell.DueDate}
                              </td>
                              <td className="text-black text-xs font-normal   mt-2 p-4  border-[1px]  border-[#cccccc]">
                                {cell.IssuanceDate}
                              </td>
                              <td className="text-black text-xs font-normal  mt-2 p-4  border-[1px]  border-[#cccccc]">
                                {cell.Discount}
                              </td>
                              <td className="text-black text-xs font-normal  mt-2 p-4  border-[1px]  border-[#cccccc]">
                                {cell.VAT}
                              </td>
                              <td className="text-black text-xs font-normal  mt-2 p-4  border-[1px]  border-[#cccccc]">
                                {cell.TotalAmount}
                              </td>
                              <td className="text-black text-xs font-normal  mt-2 p-4  border-[1px]  border-[#cccccc]">
                                {cell.AccountName}
                              </td>
                              <td className="text-black text-xs font-normal  mt-2 p-4  border-[1px]  border-[#cccccc]">
                                {cell.AccountNo}
                              </td>
                              <td className="text-black text-xs font-normal  mt-2 p-4  border-[1px]  border-[#cccccc]">
                                {cell.BankName}
                              </td>
                              <td className="text-black text-xs font-normal  mt-2 p-3  border-y-[1px]  border-[#cccccc]">
                                <div className=" flex gap-2">
                                  <PADeleteTableIcon className=" h-5 w-5" />
                                  <PAEditTableIcon className=" h-5 w-5" />
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  {/* Calculate cost and price  shaded or gray section */}
                </div>

                {/* stops */}
              </section>
            </TransitionScale>
          </>
        </div>
      </DashboardLayout>
    </>
  );
};

export default InvoiceUploadScreen;
