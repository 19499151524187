import React from "react";
import Overview from "../overview/Overview";
import ApprovedExtensions from "../approvedExtensions";
import HeaderTabs from "shared/HeaderTabs";
import NewExtensionRequests from "../newExtensionRequests";

const FunderPaymentExtensionDashboard = () => {
  return (
    <HeaderTabs
      tabs={[
        { key: "overview", label: "Overview", content: <Overview /> },
        {
          key: "approvedExtensions",
          label: "Approved Extensions",
          content: <ApprovedExtensions />,
        },
        {
          key: "newExtensionRequests",
          label: "Pending Requests",
          content: <NewExtensionRequests />,
        },
      ]}
      hasPaddingY={true}
    />
  );
};

export default FunderPaymentExtensionDashboard;
