import { PAIconActionAgreementArrowEnd } from 'assets/images/svgs'
import React from 'react'

const SecondStep = ({onPrev, onNext}) => {


   const data=[{id:"1",name:"PFP Campaign Micro",version:"Version 3", status:"Active"},{id:"2",name:"PFP Campaign Micro",version:"Version 3", status:"Active"},{id:"3",name:"PFP Campaign Micro",version:"Version 3", status:"Active"},{id:"4",name:"PFP Campaign Micro",version:"Version 3", status:"Active"}]
  return (
    <div className='lg:min-w-[360px] md:min-w-[360px]'>

<div className='flex w-full justify-center items-center mt-2'><div class="self-stretch text-center justify-start text-[#a3a3a3] text-sm font-normal ">Select template document</div></div>

<div class="text-start justify-start text-amber-600 text-sm font-semibold my-4 ">PFP Campaign type</div>

<div className='flex flex-col gap-2'>

    {data.map((item)=>(
        
        
        
        <div class="w-full px-3 py-2.5 bg-white rounded-[5px] outline outline-1 outline-offset-[-1px] outline-stone-300 inline-flex justify-between items-center cursor-pointer" key={item.id} onClick={onNext}>

    
<div class=" inline-flex flex-col justify-center items-start gap-1">
    <div class="justify-start text-neutral-800 text-xs font-medium ">{item.name}</div>
    <div class="justify-start text-[#959595] text-[10px] font-normal ">Template name</div>
</div>
<div class="w-28 inline-flex flex-col justify-center items-start gap-1">
    <div class="justify-start text-neutral-800 text-xs font-medium ">{item.version}</div>
    <div class="justify-start text-[#959595] text-[10px] font-normal ">Document</div>
</div>
<div class="w-12 inline-flex flex-col justify-center items-start gap-1">
    <div class="justify-start text-amber-600 text-xs font-medium ">{item.status}</div>
    <div class="justify-start text-[#959595] text-[10px] font-normal ">Status</div>
</div>
<div class=" flex justify-center items-center">
    < PAIconActionAgreementArrowEnd/>
  
</div>
</div>))}

</div>
      

      <div onClick={onPrev} className=' cursor-pointer'><div class="p-2.5 rounded-[5px] outline outline-1 outline-offset-[-1px] outline-stone-300 inline-flex justify-center items-center gap-2.5 mt-5">
    <div class="justify-start text-neutral-400 text-xs font-medium ">Previous step</div>
</div></div>
    </div>
  )
}

export default SecondStep
