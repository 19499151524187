import { setSelectedAccounts } from "appstate/wallet/walletSlice";
import { PAIconPaymnet } from "assets/images/svgs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import WithdrawFund from "./WithdrawFund";
import PinModal from "shared/PinModal";
import TransferReceipt from "shared/TransferReceipt";

const WithdrawOption = ({ isOpen, onClose, onSubmit }) => {
  const dispatch = useDispatch();
  const { withdrawalFormData } = useSelector((state) => state.wallet);

  const [isWithdrawFund, setIsWithdrawFund] = useState(false);
  const [showPinModal, setShowPinModal] = useState(false);
  const [isTransferReceiptOpen, setIsTansferReceiptOpen] = useState(false);
  const [showOptionModal, setShowOptionModal] = useState(true);

  useEffect(() => {
    if (isOpen) {
      setShowOptionModal(true);
    }
  }, [isOpen]);

  const handleWithdrawalSubmit = () => {
    setIsWithdrawFund(false);
    setShowPinModal(true);
  };

  //   const handleWithdrawFundOpen = () => {
  //     setIsWithdrawFund(true);
  //     setShowOptionModal(false)
  //   };

  const handleWithdrawFundOpen = () => {
    setIsWithdrawFund(true);
    setShowOptionModal(false);
  };

  const handlePinModalClose = () => {
    setShowPinModal(false);
  };

  //   const handleCloseWithdrawOption = () => {
  //     setIsTansferReceiptOpen(false);
  //     setShowOptionModal(false);
  //   };

  const handleCloseWithdrawOption = () => {
    setIsTansferReceiptOpen(false);
    setIsWithdrawFund(false);
    setShowOptionModal(false);
  };

  const accountData = [
    {
      id: 1,
      name: "Abubakar",
      accountNumber: "1234567890",
      bankName: "Payedge MFB",
      accountType: "Virtual Account",
      amount: "NGN 20,000,000",
    },
    {
      id: 2,
      name: "Isaiah",
      accountNumber: "9876543210",
      bankName: "Payedge MFB",
      accountType: "Virtual Account",
      amount: "NGN 20,000,000",
    },
  ];

  if (!isOpen) return null;

  return (
    <>
      {showOptionModal && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg rounded-md z-[1000]">
          <div className="bg-white rounded-md max-w-xs md:max-w-lg w-full pb-5">
            <div className="flex justify-between items-center mb-4 bg-[#FAFAFA]">
              <p className="text-black font-sans text-base font-semibold p-3 px-5 rounded-sm italic">
                Withdrawal
              </p>
              <button
                onClick={onClose}
                className="text-black font-sans text-xl cursor-pointer flex justify-center items-center"
              >
                &times; <span className="text-sm mx-2 mt-1">Close</span>
              </button>
            </div>

            {/* ACCOUNTS */}
            <div>
              <div className="w-full px-7">
                {/* {selectedAccounts?.map((selectedAccount, index) => (
                <div
                  key={index}
                  className="text-[#7A7A7A] font-sans text-sm font-normal mb-6 flex items-center"
                >
                  <p className="text-zinc-600 text-sm font-extrabold flex justify-center"> */}
                {/* {selectedAccounts?.length > 1 ? "To" : "From"}To */}
                {/* </p>
                  <div className="border border-[#F08000] ml-3 p-1 w-[80%] flex items-center rounded text-xs">
                    <PAIconCardOutline className="mr-2 w-4" />
                    <p>{selectedAccount?.name}</p> -{" "}
                    <p>{selectedAccount?.accountNumber}</p> -{" "}
                    <p>{selectedAccount?.bankName}</p>
                  </div>
                </div>
              ))} */}
                <p className="text-zinc-600 text-sm font-extrabold flex justify-center italic">
                  Select which account to transfer from
                </p>
                <h1 className="text-zinc-600 text-base font-extrabold mt-5 italic">
                  Accounts
                </h1>
                {/* ACCOUNTS */}
                <div className="md:flex md:gap-5 flex-wrap">
                  {accountData.map((account, index) => (
                    <div
                      key={index}
                      className="bg-[#07593D] p-3 mt-3 cursor-pointer text-white text-xs md:w-[calc(50%-10px)] md:mb-0 mb-3 rounded"
                      onClick={() => {
                        dispatch(setSelectedAccounts(account));
                        setIsWithdrawFund(true);
                      }}
                    >
                      <div className="flex justify-between">
                        <div className="text-white flex items-center">
                          <PAIconPaymnet className="mr-2 text-sm font-bold" />{" "}
                          <p>{account.name}</p>
                        </div>
                        <p className="text-white font-light text-xs my-2">
                          {account.accountType}
                        </p>
                      </div>
                      <p className="mt-2 italic font-bold text-[18px] flex justify-start">
                        {account.amount}
                      </p>
                      <p className="mt-5 text-xs">
                        <span className="mr-1">{account.bankName}</span> |
                        <span className="ml-1">{account.accountNumber}</span>
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isWithdrawFund && (
        <WithdrawFund
          isOpen={handleWithdrawFundOpen}
          onClose={() => setIsWithdrawFund(false)}
          onSubmit={handleWithdrawalSubmit}
        />
      )}
      {showPinModal && (
        <PinModal
          isOpen={showPinModal}
          onClose={handlePinModalClose}
          onSubmit={(pin) => {
            setShowPinModal(false);
            setIsTansferReceiptOpen(true);
          }}
          headerText="Withdrawal"
          instructionText="Enter your transaction PIN to transfer funds to your desired account."
        />
      )}
      {isTransferReceiptOpen && (
        <TransferReceipt
          isOpen={isOpen}
          //   onClose={() => setIsTansferReceiptOpen(false)}
          onClose={() => handleCloseWithdrawOption()}
          amount={withdrawalFormData?.amount}
          currency="NGN"
          paymentStatus="Success"
          senderName="Adewale Favour Adediwura"
          sourceBank="Access bank plc"
          sourceAccountNumber="23857369282"
          beneficiaryName="Praise Akobundu Ignatius"
          destinationBank="PayEdge"
          destinationAccountNumber="45678900987"
          transactionReference="87463938573992834746893"
          createdDate="23-Dec-2024"
          supportEmail="customerservice@payedge.com"
          //   onDownload={() => console.log("Downloaded!")}
        />
      )}
    </>
  );
};

export default WithdrawOption;
