import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AcceptModal from "shared/AcceptModal";
import SuccessModal from "shared/SuccessModal";

function BulkDirectFundingInvoice({ invoiceByIdData }) {
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  const handleConfirm = () => {
    setIsAcceptModalOpen(false);
    setIsSuccessModalOpen(true);
    setSuccessMessage("Payment remainders sent successfully");
  };

  const handleSuccessClose = () => {
    setIsSuccessModalOpen(false);
    navigate(-1);
  };

  return (
    <motion.section
      initial={{
        x: 20,
      }}
      animate={{
        x: 0,
      }}
      exit={{
        x: -20,
      }}
      transition={{
        delay: 0.6,
      }}
      className="flex flex-col gap8 px-6 pt-7 pb-24 w-[60%] semilg:w-[55%] bg-white rounded-[10px]"
    >
      <div className="mb-2 flex flex-col gap-3 ">
        <header className="flex items-center justify-between">
          <h1 className=" text-[18px] font-bold">
            Reference No |{" "}
            {invoiceByIdData?.data?.requestReferenceNumber || "NIL"}
          </h1>
          <button
            className="bg-primaryColor px-4 py-2 text-white rounded"
            onClick={() => setIsAcceptModalOpen(true)}
          >
            Send Remainder Now
          </button>
        </header>

        <main className="w-full flex items-center justify-between">
          <div className="flex flex-col gap-3 w-2/5">
            <div className="flex flex-col gap-1">
              <div className="flex justify-between">
                <span className=" font-semibold text-xs">Request Date:</span>
                <span className="text-[#979797]  text-xs">
                  {invoiceByIdData?.data?.requestDate.slice(0, 10) || "NIL"}
                </span>
              </div>
              <div className="flex justify-between">
                <span className=" font-semibold text-xs">
                  Total Request amount:
                </span>
                <span className="text-[#979797]  text-xs">
                  {invoiceByIdData?.data?.totalRequestAmount?.toLocaleString() ||
                    "NIL"}
                </span>
              </div>

              <div className="flex justify-between">
                <span className=" font-semibold text-xs">
                  Total Invoice amount:
                </span>
                <span className="text-[#979797]  text-xs">
                  {invoiceByIdData?.data?.totalInvoiceAmount?.toLocaleString() ||
                    "NIL"}
                </span>
              </div>
            </div>
          </div>

          {/* STATUS */}
          {/* <div className="rounded-md py-1.5 bg-[#F080000F] px-6">
            <p className="text-center text-secondaryColor text-xs font-bold">
              {invoiceByIdData?.data?.status || "Pending Payment"}
            </p>
          </div> */}
          <button class="h-[30px] px-8 py-2 bg-[#23ad52]/20 rounded-[5px] justify-center items-center gap-2.5 inline-flex overflow-hidden">
            <div class="text-center text-[#07593d] text-xs font-medium ">
              Approved
            </div>
          </button>
        </main>
      </div>

      {/* Buyer details and co. */}
      <div className="flex justify-between my-5 border-b-[5px] border-b-[#F6F5FA] py-3">
        <div className="flex flex-col">
          <span className="font-bold text-sm">
            {invoiceByIdData?.data?.buyerCompanyName || "NIL"}
          </span>
          <span className="text-[#979797] text-xs">Buyer details</span>
        </div>
        {/* <div className="flex flex-col">
          <span className="font-bold text-sm">
            {invoiceByIdData?.data?.paymentTerms || "20"}
          </span>
          <span className="text-[#979797] text-xs">Payment</span>
        </div> */}
        <div className="flex flex-col">
          <span className="font-bold text-sm ">
            {invoiceByIdData?.data?.currency || "NIL"}
          </span>
          <span className="text-[#979797] text-xs">Currency</span>
        </div>
      </div>

      <div className="flex mb-8 flex-col gap-4">
        <header className="border-b-[5px] border-b-[#F6F5FA] pb-3 flex items-center justify-between">
          <h1 className="text-[18px] font-normal">Line Items</h1>
          <p>{invoiceByIdData?.data?.items?.length || "0"} Total Items</p>
        </header>

        <div className=" w-full overflow-x-auto">
          <table className=" w-full overflow-x-auto">
            <thead className="bg-subtleGray w-">
              <tr className="bg-unusualGray p-2.5 ">
                <td className="text-[#11192A] p-2.5 text-xs font-bold">
                  Invoice Number
                </td>
                <td className="p-2.5 text-xs font-bold">Due Date</td>
                <td className="p-2.5 text-xs font-bold">Request Amount</td>
                <td className="p-2.5 text-xs font-bold">Discount Rate</td>
                <td className="p-2.5 text-xs font-bold">Discount Amount</td>
                <td className="p-2.5 text-xs font-bold">Receivable Amount</td>
              </tr>
            </thead>
            <tbody>
              {invoiceByIdData?.data?.items?.map((item) => (
                <tr
                  key={item.id}
                  className=" py-[3px] px-1.5 cursor-pointer"
                  onClick={() => navigate(`/collections/invoice/${item.id}`)}
                >
                  <td className=" text-xs text-[#11192a] p-2.5">
                    {item?.invoiceNumber}
                  </td>
                  <td className=" text-xs text-[#11192a] p-2.5">
                    {item?.dueDate}
                  </td>
                  <td className=" text-xs text-[#11192a] p-2.5">
                    {item?.requestAmount}
                  </td>
                  <td className=" text-xs text-[#11192a] p-2.5">
                    {item?.discountRate}
                  </td>
                  <td className=" text-xs text-[#11192a] p-2.5">
                    {item?.discountAmount}
                  </td>
                  <td className=" text-xs text-[#11192a] p-2.5">
                    {item?.receivableAmount}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <AnimatePresence>
        <AcceptModal
          isOpen={isAcceptModalOpen}
          onClose={() => setIsAcceptModalOpen(false)}
          yes
          message={
            <>
              Would you like to proceed with sending a <br />
              <span className="text-[#07593D] font-bold">
                Payment reminder
              </span>{" "}
              now?
            </>
          }
          onConfirm={handleConfirm}
        />

        <SuccessModal
          isOpen={isSuccessModalOpen}
          onClose={handleSuccessClose}
          message={successMessage}
        />
      </AnimatePresence>
    </motion.section>
  );
}

export default BulkDirectFundingInvoice;
