import { PAIconInventoryArrowLeft, PAIconSearch } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import Table from "layout/table/Table";
import { useNavigate } from "react-router-dom";
import Button from "shared/Button";
import TableIcons from "../../assets/images/svgs/TableIcons.svg";

const data = [
  {
    invoiceReference: "INV001",
    buyer: "ABC Corp",
    invoiceDate: "2024-09-15",
    dueDate: "2024-10-15",
    currency: "NGN",
    invoiceAmount: "$1500.00",
    status: "Approved",
  },
  {
    invoiceReference: "INV002",
    buyer: "XYZ Ltd",
    invoiceDate: "2024-09-20",
    dueDate: "2024-10-20",
    currency: "NGN",
    invoiceAmount: "$2500.00",
    status: "Approved",
  },
  {
    invoiceReference: "INV003",
    buyer: "DEF Inc",
    invoiceDate: "2024-09-22",
    dueDate: "2024-10-22",
    currency: "NGN",
    invoiceAmount: "€1800.00",
    status: "Approved",
  },
];

const SupplierEarlyPaymentRequestFlow = () => {
  const navigate = useNavigate();
  //   const [loading, setLoading] = useState(false);

  function handleNavigate() {
    navigate(-1);
  }

  const onRowClicked = (row) => {
    let path = "/single-early-payment-invoice-details";
    // dispatch(getAssociatedBuyerId(row?.id));
    navigate(path, { state: { row } });
  };

  const HeaderWithIcon = ({ icon, text }) => (
    <div className="flex items-center">
      <img src={icon} alt="" className="mr-2" />
      <span>{text}</span>
    </div>
  );

  const column = [
    {
      name: <HeaderWithIcon icon={TableIcons} text="Invoice Reference" />,
      selector: (row) => row?.invoiceReference,
    },
    {
      name: <HeaderWithIcon icon={TableIcons} text="Buyer" />,
      selector: (row) => row?.buyer,
    },
    {
      name: "Invoice Date",
      selector: (row) => row?.invoiceDate,
    },
    {
      name: "Due Date",
      selector: (row) => row?.dueDate,
    },
    {
      name: "Currency",
      selector: (row) => row?.currency,
    },
    {
      name: "Invoice Amount",
      selector: (row) => row?.invoiceAmount,
    },
    {
      name: "Status",
      selector: (row) => (
        <span
          style={{
            color: row.status === "Approved" && "#2EA923",
          }}
        >
          {row?.status}
        </span>
      ),
    },
  ];

  return (
    <DashboardLayout
      section="Invoice"
      subHeader="Here is an overview of your invoices"
    >
      <div>
        <header className="bg-alabasterHeader p-[24px] rounded-t-[10px]">
          <div
            onClick={handleNavigate}
            className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
          >
            <PAIconInventoryArrowLeft />
            <p className="mt-[2px] text-sm font-normal text-lightGray">
              Go Back
            </p>
          </div>
          <div className="flex justify-between flex-wrap ">
            <div>
              <p className="text-black text-sm font-medium">
                78 Approved Invoices
              </p>
              <p className="text-textColor text-xs font-normal">
                Invoices With Early payment Request Activated
              </p>
            </div>
            <div className="flex gap-x-[10px] mt-[10px] md:mt-0 flex-wrap">
              <div className="flex items-center  bg-white p-[10px] gap-x-[8px] rounded-[5px] border-[0.5px] border-silver xl:w-[350px]">
                <PAIconSearch />
                <input
                  type="text"
                  //   value={companyName}
                  //   onChange={(e) => setCompanyName(e.target.value)}
                  placeholder="Search Invoice"
                  className="placeholder:text-xs outline-none"
                />
              </div>
              <Button
                neutral
                // onClick={handleSearch}
                // disabled={data < 1}
                className="py-[12px] mt-[10px] md:mt-0 px-[24px] border-[0.5px] border-silver bg-brightGray text-sm !text-black rounded-[5px] font-medium"
              >
                Search
              </Button>
            </div>
          </div>
        </header>
      </div>
      <div className="p-[24px] bg-white rounded-b-[10px]">
        <Table
          columns={column}
          pointer
          data={data}
          onRowClicked={onRowClicked}
        />
      </div>
    </DashboardLayout>
  );
};

export default SupplierEarlyPaymentRequestFlow;
