import {
  PAIconCocacola,
  PAIconInvShop,
  PAIconModelOne,
} from "assets/images/svgs";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

function AcceptedInvoiceCard({
  bidOfferId,
  id,
  invoiceNumber,
  buyerCompanyName,
  funderCompanyName,
  invoiceAmount,
  dueDate,
  lockRate,
  invoiceReference = "N/A",
}) {
  const navigate = useNavigate();

  return (
    <motion.div
      onClick={() => navigate(`acceptedInvoice/${id}`)}
      whileTap={{ scale: 0.98 }}
      style={{ boxShadow: "0px 2px 4px 0px rgba(232, 226, 216, 0.36)" }}
      className="flex cursor-pointer justify-between bg-white py-5 px-6 rounded-[10px]"
    >
      <span className="flex gap-6  items-center">
        <PAIconInvShop />
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{invoiceReference}</span>
          <span className=" text-[#959595] text-[10px]">Reference number</span>
        </div>
      </span>

      <span className="flex gap-6  items-center">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">INV-2022</span>
          <span className=" text-[#959595] text-[10px]">Invoice number</span>
        </div>
      </span>

      <span className=" hidden  extra-md:flex gap-6 items-center ">
        <PAIconCocacola />
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{funderCompanyName}</span>
          <span className=" text-[#959595] text-[10px]">Funder</span>
        </div>
      </span>

      <span className="hidden extra-md:flex gap-6 items-center ">
        <PAIconModelOne />
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{buyerCompanyName}</span>
          <span className=" text-[#959595] text-[10px]">Buyer</span>
        </div>
      </span>

      <span className="flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-secondaryColor text-sm">Locked</span>
          <span className=" text-[#959595] text-[10px]">Status of Invoice</span>
        </div>
      </span>

      <span className="flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{invoiceAmount}</span>
          <span className=" text-[#959595] text-[10px]">Request Amount</span>
        </div>
      </span>

      <span className="hidden  semi-lg:flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span className="flex items-center gap-1 text-sm">
            <span>{lockRate}%</span> -{" "}
            <span className="text-[10px] text-secondaryColor">Recourse</span>
          </span>
          <span className=" text-[#959595] text-[10px]">Lock Rate</span>
        </div>
      </span>

      <span className="hidden  semi-lg:flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{dueDate}</span>
          <span className=" text-[#959595] text-[10px]">Due Date</span>
        </div>
      </span>

      <span className="hidden  semi-lg:flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{dueDate}</span>
          <span className=" text-[#959595] text-[10px]">Bid Closure Time</span>
        </div>
      </span>

      {/* <motion.button
        whileTap={{ scale: 0.9 }}
        className="py-2.5 px-4 rounded-[10px] bg-white border text-sm border-primaryColor"
      >
        View
      </motion.button> */}
    </motion.div>
  );
}

export default AcceptedInvoiceCard;
