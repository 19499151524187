import * as Yup from "yup";

export const withdrawAccountValidationSchema = Yup.object({
  accountNumber: Yup.number()
    .required("This field is required")
    .test(
      "len",
      "Must be exactly 10 digits",
      (val) => val && val.toString().length === 10
    ),
  bankName: Yup.string()
    .required("This field is required")
    .matches(/^[a-zA-Z\s]+$/, "Must only contain alphabetical characters"),
  accountName: "",
  amount: Yup.number().required("This field is required"),
  narration: Yup.string().required("This field is required"),
});
