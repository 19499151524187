import { PAIconInventoryArrowLeft } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import Table from "layout/table/Table";
import { useNavigate } from "react-router-dom";
import Button from "shared/Button";
import TableIcons from "../../assets/images/svgs/TableIcons.svg";
import { useEffect, useState } from "react";
import BulkRequestModal from "features/invoice/fundingSection/earlyPaymentRequest/singlerequest/Modal/BulkRequestModal";
import BulkInvoiceModal from "features/invoice/fundingSection/earlyPaymentRequest/singlerequest/Modal/bulkinvoicemodal";
import ReceivingFundModal from "features/invoice/fundingSection/earlyPaymentRequest/singlerequest/Modal/ReceivingFundModal";
import EarlyPaymentConfirmation from "features/invoice/fundingSection/earlyPaymentRequest/singlerequest/Modal/EarlyPaymentConfirmation";
import SuccessEarlyPayment from "features/invoice/fundingSection/earlyPaymentRequest/singlerequest/Modal/SuccessEarlyPayment";
import PinModalPayment from "features/invoice/fundingSection/earlyPaymentRequest/singlerequest/Modal/PinModal";
import TransferFundModal from "features/invoice/fundingSection/earlyPaymentRequest/singlerequest/Modal/TransferFund";
import Pagination from "shared/Pagination";
import {
  createEarlyPaymentRequestBulk,
  fetchDiscountBuyerForBulk,
  getInvoicesByDate,
  getVirtualAccounts,
} from "appstate/api/invoice/invoice";
import Loading from "shared/Loading";
import BulkVsa from "features/invoice/fundingSection/earlyPaymentRequest/singlerequest/Modal/BulkVsa";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { formatCurrency } from "utilities/helper/formatters";

const BulkEarlyPaymentInvoiceDetail = () => {
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [showPercentageModal, setShowPercentageModal] = useState(false);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [receivingAccount, setShowReceivingAccount] = useState(false);
  const [showVirtualSelectionModal, setShowVirtualSelectionModal] =
    useState(false);
  const [open, setOpen] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const [total, setTotal] = useState("");
  const [approvedInvoices, setApprovedInvoices] = useState([]);
  const [virtualAccounts, setVirtualAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [percentage, setPercentage] = useState("");
  const [date, setDate] = useState("");
  const [apply, setApply] = useState(false);
  // eslint-disable-next-line
  const [discountData, setDiscountData] = useState([]);
  const [totalRequestAmount, setTotalRequestAmount] = useState(0);
  const [totalReceivableAmount, setTotalReceivableAmount] = useState(0);
  const [description, setDescription] = useState("");
  const [isDescriptionFilled, setIsDescriptionFilled] = useState(false);
  const [applied, setApplied] = useState(false);
  const [otpInputs, setOtpInputs] = useState(["", "", "", ""]);
  const [selectedDate, setSelectedDate] = useState("");
  const [earlyPaymentDiscountedResponseList, setEarlyPaymentDiscountedResponseList] = useState("");

  // const size = 10;
  const location = useLocation();
  const { buyerId } = location.state || {};

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const fetchInvoicesByDate = async () => {
    try {
      setLoading(true);
      const result = await getInvoicesByDate({
        buyerId,
        earlyPaymentDate: selectedDate,
      });
      if (result) {
        setTotal(result?.meta?.totalElements);
         setApprovedInvoices(result?.data || []);
      } else {
        toast.error("No invoices found for the selected date.");
      }
    } catch (error) {
      console.error("Failed to fetch invoices by date:", error);
    } finally {
      setLoading(false);
    }
  };

  const calculateTotalInvoiceAmount = () => {
    return approvedInvoices
      .filter((invoice) => selectedInvoices.includes(invoice.invoiceId))
      .reduce((total, invoice) => total + parseFloat(invoice.invoiceAmount), 0)
      .toFixed(2);
  };
  

  useEffect(() => {
      fetchInvoicesByDate();
    // eslint-disable-next-line
  }, [selectedDate]);

  useEffect(() => {
    const fetchVirtualAccounts = async () => {
      try {
        const result = await getVirtualAccounts();
        setVirtualAccounts(result?.data);
      } catch (error) {
        console.error("Failed to fetch virtual accounts:", error);
      }
    };

    fetchVirtualAccounts();
  }, []);

  const handleFetchDiscount = async () => {
    setLoading(true);
    try {
      const payload = {
        buyerId,
        earlyPaymentPercentage: percentage,
        earlyPaymentDate: date,
        invoiceIds: selectedInvoices,
      };

      const result = await fetchDiscountBuyerForBulk(payload);
      if (result?.success === true) {
        setDiscountData(result?.data?.earlyPaymentDiscountedResponseList);
        setTotalRequestAmount(result?.data?.totalRequestAmount);
        setTotalReceivableAmount(result?.data?.totalReceivableAmount);
        setEarlyPaymentDiscountedResponseList(result?.data?.earlyPaymentDiscountedResponseList);
        console.log("Discount data fetched successfully:", result?.data);
      } else {
        console.error("Unexpected response status:", result?.status);
      }
    } catch (error) {
      console.error("Error fetching discount data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSendEarlyPaymentRequest = async () => {
    if (!selectedInvoices.length) {
      toast.error("No invoices selected for early payment request!");
      return;
    }
    if (!selectedAccount) {
      toast.error("Please select a virtual account!");
      return;
    }

    const pin = otpInputs.toString();
    const transactPin = pin.replace(/,/g, "");

    const payload = {
      invoiceIds: selectedInvoices,
      virtualAccountId: selectedAccount.id,
      description,
      newPaymentDate: date,
      requestedAmount: totalRequestAmount,
      buyerId,
      transactionPin: transactPin,
      totalReceivableAmount,
      earlyPaymentPercentage: percentage,
    };

    setLoading(true);
    try {
      const response = await createEarlyPaymentRequestBulk(payload);
      // toast.success("Early payment request sent successfully!");
      if (response.status === 201) {
        setShowConfirmModal(false);
        setShowSuccess(true);
      }
    } catch (error) {
      console.error("Failed to send early payment request:", error);
      toast.error("Failed to send request. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   GetAllApprovedInvoices();
  //   // eslint-disable-next-line
  // }, [page, size]);

  const navigate = useNavigate();
  function handleNavigate() {
    navigate(-1);
  }

  const handleProceed = () => {
    setShowPercentageModal(false);
    setShowInvoiceModal(true);
  };

  const onRowClicked = (row) => {
    // let path = `/single-early-payment-invoice-details/${row?.id}`;
    // // // dispatch(getAssociatedBuyerId(row?.id));
    // navigate(path);
  };

  const handleGoBack = () => {
    setShowInvoiceModal(false);
    setShowPercentageModal(true);
  };

  const handleSelectAccount = () => {
    setShowInvoiceModal(false);
    setShowReceivingAccount(true);
  };

  const confirmPayment = () => {
    setShowConfirmModal(true);
    setShowPin(false);
  };

  const onClosePinModal = () => {
    setShowPin(false);
  };

  const handleCheckboxChange = (invoiceId) => {
    setSelectedInvoices((prevSelected) =>
      prevSelected.includes(invoiceId)
        ? prevSelected.filter((id) => id !== invoiceId)
        : [...prevSelected, invoiceId]
    );
  };

  const HeaderWithIcon = ({ icon, text }) => (
    <div className="flex items-center">
      <img src={icon} alt="" className="mr-2" />
      <span>{text}</span>
    </div>
  );

  const column = [
    {
      name: <HeaderWithIcon icon={TableIcons} text="Invoice Reference" />,
      selector: (row) => (
        <div className="flex items-center">
          <input
            type="checkbox"
            checked={selectedInvoices.includes(row?.invoiceId)}
            onChange={() => handleCheckboxChange(row?.invoiceId)}
            className={`mr-2 custom-checkbox w-4 h-4 appearance-none border-2 rounded-sm ${
              selectedInvoices.includes(row?.invoiceId)
                ? "bg-[#F08000] border-[#F08000]"
                : "bg-[#C2C0C0] border-[#C2C0C0]"
            } cursor-pointer`}
            style={{
              outline: "none",
            }}
          />
          {row?.invoiceRef}
        </div>
      ),
    },
    {
      name: "Invoice Date",
      selector: (row) => row?.invoiceDate,
    },
    {
      name: "Due Date",
      selector: (row) => row?.dueDate,
    },
    {
      name: "Currency",
      selector: (row) => row?.currency,
    },
    {
      name: "Invoice Amount",
      selector: (row) => formatCurrency(row?.invoiceAmount),
    },
    {
      name: "Status",
      selector: (row) => (
        <span
          style={{
            color: row.status === "APPROVED" ? "#2EA923" : undefined,
          }}
        >
          {row?.status}
        </span>
      ),
    },
  ];

  const handleSelectAll = () => {
    if (selectedInvoices.length === approvedInvoices.length) {
      setSelectedInvoices([]);
    } else {
      setSelectedInvoices(approvedInvoices.map((invoice) => invoice.invoiceId));
    }
  };

  // const calculateTotalInvoiceAmount = () => {
  //   return selectedInvoices
  //     .map((invoiceId) => {
  //       const invoice = approvedInvoices.find((inv) => inv.id === invoiceId);
  //       return invoice ? invoice.invoicedAmount : 0;
  //     })
  //     .reduce((total, amount) => total + amount, 0);
  // };

  const calculateDiscountTotalInvoiceAmount = () => {};

  // const isAnySelected = Object.keys(selectedInvoices).some(
  //   (key) => selectedInvoices[key]
  // );

  const closePercentageModal = () => {
    setShowPercentageModal(false);
  };

  const handlePercentageModal = () => {
    setShowPercentageModal(true);
  };

  const handleShowVirtualAccountSelection = () => {
    setShowVirtualSelectionModal(true);
    setShowReceivingAccount(false);
  };

  const handleShowPin = () => {
    setShowTransferModal(false);
    setShowPin(true);
  };

  const handleCloseVirtualAccount = () => {
    setShowReceivingAccount(false);
    setShowVirtualSelectionModal(false);
  };

  const handleChangeAccount = () => {
    setShowVirtualSelectionModal(true);
    setShowTransferModal(false);
  };

  const handleCloseTransferModal = () => {
    setShowTransferModal(false);
  };

  function handleSendRequest() {
    setOpen(false);
    setShowTransferModal(true);
    //  onClose();
  }

  function closeAccountModal() {
    setShowVirtualSelectionModal(false);
  }

  const totalPages = total;
  return (
    <DashboardLayout
      section="Invoice"
      subHeader="Here is an overview of your invoices"
    >
      {(open || showConfirmModal) && (
        <div className="w-full fixed inset-0 bg-black bg-opacity-50 z-10"></div>
      )}
      {open && (
        <div className="mr-8 absolute mt-[20px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white w-[614px] h-[254px] z-10 rounded-[10px] shadow-lg">
          <EarlyPaymentConfirmation
            hide={() => setOpen(false)}
            // isLoading={earlyPaymentLoading}
            handleAssociate={handleSendRequest}
            // earlyPayment={true}
            warning={
              <>
                Would you like to proceed to use this account{" "}
                <span className="text-primaryColor">7364738921</span> to bid on
                invoice?
              </>
            }
          />
        </div>
      )}

      {showTransferModal && (
        <div>
          <TransferFundModal
            handleCloseVirtualAccount={handleCloseVirtualAccount}
            handleShowVirtualAccountSelection={
              handleShowVirtualAccountSelection
            }
            setShowReceivingFundModal={setShowReceivingAccount}
            handleChangeAccount={handleChangeAccount}
            handleCloseTransferModal={handleCloseTransferModal}
            handleShowPin={handleShowPin}
            selectedAccount={selectedAccount}
            invoice={true}
          />
        </div>
      )}

      {showConfirmModal && (
        <div className="mr-8 absolute mt-[20px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white w-[614px] h-[254px] z-10 rounded-[10px] shadow-lg">
          <EarlyPaymentConfirmation
            hide={() => setShowConfirmModal(false)}
            invoice={true}
            // isLoading={earlyPaymentLoading}
            handleAssociate={handleSendEarlyPaymentRequest}
            // earlyPayment={true}
            warning=" Would you like to proceed with sending this bulk early payment request? "
          />
        </div>
      )}

      {showPin && (
        <PinModalPayment
          confirmPayment={confirmPayment}
          isOpen={showPin}
          onCancel={onClosePinModal}
          invoice={true}
          otpInputs={otpInputs}
          setOtpInputs={setOtpInputs}
        />
      )}

      {showSuccess && (
        <SuccessEarlyPayment
          isOpen={showSuccess}
          onClose={() => setShowSuccess(false)}
          invoice={true}
          message="Early payment request successful"
        />
      )}

      {receivingAccount && (
        <ReceivingFundModal
          invoice={true}
          handleCloseVirtualAccount={handleCloseVirtualAccount}
          handleShowVirtualAccountSelection={handleShowVirtualAccountSelection}
          setShowReceivingFundModal={setShowReceivingAccount}
        />
      )}
      {showVirtualSelectionModal && (
        <div>
          <BulkVsa
            handleCloseVirtualAccount={handleCloseVirtualAccount}
            setOpen={setOpen}
            onClose={closeAccountModal}
            virtualAccounts={virtualAccounts}
            selectedAccount={selectedAccount}
            setSelectedAccount={setSelectedAccount}
          />
        </div>
      )}
      <BulkRequestModal
        handleProceed={handleProceed}
        onClose={closePercentageModal}
        isShown={showPercentageModal}
        lengthOfInvoice={selectedInvoices?.length}
        buyer={approvedInvoices[0]?.buyer}
        percentage={percentage}
        setPercentage={setPercentage}
        date={date}
        setDate={setDate}
        apply={apply}
        setApply={setApply}
        handleFetchDiscount={handleFetchDiscount}
        calculateTotalInvoiceAmount={calculateTotalInvoiceAmount}
        calculateDiscountTotalInvoiceAmount={
          calculateDiscountTotalInvoiceAmount
        }
        totalRequestAmount={totalRequestAmount.toFixed(2)}
      />
      <BulkInvoiceModal
        handleGoBack={handleGoBack}
        isShown={showInvoiceModal}
        handleSelectAccount={handleSelectAccount}
        date={date}
        lengthOfInvoice={approvedInvoices?.length}
        buyer={approvedInvoices[0]?.buyer}
        totalRequestAmount={totalRequestAmount.toFixed(2)}
        percentage={percentage}
        calculateTotalInvoiceAmount={calculateTotalInvoiceAmount}
        totalReceivableAmount={totalReceivableAmount}
        description={description}
        setDescription={setDescription}
        isDescriptionFilled={isDescriptionFilled}
        setIsDescriptionFilled={setIsDescriptionFilled}
        applied={applied}
        setApplied={setApplied}
        earlyPaymentDiscountedResponseList={earlyPaymentDiscountedResponseList}
      />
      <div>
        <header className="bg-alabasterHeader p-[24px] rounded-t-[10px]">
          <div className="flex justify-between">
            <div
              onClick={handleNavigate}
              className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
            >
              <PAIconInventoryArrowLeft />
              <p className="mt-[2px] text-sm font-normal text-lightGray">
                Go Back
              </p>
            </div>
            <div className="flex">
              <div className="pr-2">
                <Button
                  neutral
                  onClick={handleSelectAll}
                  className={`py-[12px] mt-[10px] md:mt-0 px-[24px] rounded-[5px] font-medium ${
                    selectedInvoices?.length
                      ? "bg-[#2EA923] text-sm !text-white"
                      : "bg-[#C2C0C0] text-sm !text-white"
                  }`}
                >
                  Select all
                </Button>
              </div>
              <div>
                <Button
                  onClick={handlePercentageModal}
                  neutral
                  className={`py-[12px] mt-[10px] md:mt-0 px-[24px] rounded-[5px] font-medium ${
                    selectedInvoices?.length 
                      ? "bg-[#F08000] text-sm !text-white"
                      : "bg-[#C2C0C0] text-sm !text-white"
                  }`}
                >
                  {selectedInvoices?.length
                    ? "Set request percentage"
                    : "Request early payment"}
                </Button>
              </div>
            </div>
          </div>
          <div className="flex justify-between flex-wrap ">
            <div>
              <p className="text-black text-sm font-medium">
                {approvedInvoices?.length} Approved invoices from{" "}
                {approvedInvoices[0]?.buyer}
              </p>
              <p className="text-textColor text-xs font-normal">
                List of all buyers that have activated early payment request
              </p>
              <div className="pt-2">
                <label className="text-black text-sm font-medium">
                  Select Early payment request date
                </label>
                <div className="relative mt-2">
                  <input
                    type="date"
                    onChange={handleDateChange}
                    placeholder=""
                    className="border border-gray-300 rounded-[5px] py-[10px] px-[12px] text-sm w-[350px] pr-[40px]"
                  />
                </div>
              </div>
            </div>
          </div>
          <br />
        </header>
      </div>
      <div className="p-[24px] bg-white rounded-b-[10px]">
        {loading ? (
          <div className="flex justify-center">
            <Loading />
          </div>
        ) : (
          <Table
            columns={column}
            pointer
            data={approvedInvoices}
            onRowClicked={onRowClicked}
          />
        )}
      </div>
      <div className="flex justify-center gap-x-[8px]  mt-12">
        {!loading && totalPages > 10 && (
          <Pagination
            page={page}
            startPage={startPage}
            setEndPage={setEndPage}
            endPage={endPage}
            setStartPage={setStartPage}
            setPage={setPage}
            totalPages={totalPages}
          />
        )}
      </div>
    </DashboardLayout>
  );
};

export default BulkEarlyPaymentInvoiceDetail;
