import React, { useEffect } from "react";
import Table from "layout/table/Table";
import Card from "shared/Card";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { allTransactions } from "appstate/collections/collectionSlice";
import Loading from "shared/Loading";
import { formatCapitaliseString } from "utilities/helper/formatCapitaliseString";
import { formatCapitalize } from "utilities/formatCapitalize";

const RecentPaymentTable = ({ setActiveTab }) => {
  const navigate = useNavigate();

  const { isLoading, disbursementFunderOverviewData } = useSelector(
    (state) => state?.disbursement
  );

  const transactionDetails = (id, invId, offerId, channel) => {
    let path = `/disbursement/funder/disbursement/transaction-details/${id}?invId=${invId}&offerId=${offerId}&channel=${channel}`;
    navigate(path);
  };

  const dispatch = useDispatch();
  // const {  isLoading } = useSelector(
  //   (state) => state?.collection
  // );

  const formatAmount = (amount) => {
    if (typeof amount !== "number") return "0.00";
    return amount?.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  useEffect(() => {
    dispatch(allTransactions());
  }, [dispatch]);

  const columns = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Company Name
        </span>
      ),
      selector: (row) => formatCapitalize(row?.transactionParty?.name),
      sortable: true,
    },
    {
      name: "Payment Date",
      selector: (row) => row.paymentDate?.slice(0, 10),
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Invoice Number
        </span>
      ),
      selector: (row) => `INV-${row?.invoiceNo ?? "N?A"}`,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
      width: "6.2rem",
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      cell: (row) => <p className="text-success">{formatAmount(row.amount)}</p>,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) =>
        row.status === "SUCCESSFUL" ? (
          <p className="text-[#07593D]">{formatCapitaliseString(row.status)}</p>
        ) : row.status === "REJECTED" ? (
          <p className="text-red">{formatCapitaliseString(row.status)}</p>
        ) : row.status === "UPDATED" ? (
          <p className="text-blue-300">{formatCapitaliseString(row.status)}</p>
        ) : row.status === "PARTIALLY PAID" ? (
          <p className="text-yellow-400">
            {formatCapitaliseString(row.status)}
          </p>
        ) : (
          <p>{formatCapitaliseString(row.status)}</p>
        ),
    },
    {
      name: "Action",
      selector: (row) => row?.action,
      sortable: true,
      cell: (row) => (
        <div
          className="border border-gray300 rounded text-[#959595] p-2"
          onClick={() =>
            transactionDetails(
              row?.transactionId,
              row?.invoiceId,
              row?.programId,
              row?.fundingChannel
            )
          }
        >
          {" "}
          View Details
        </div>
      ),
    },
  ];

  return (
    <>
      <Card className="rounded-[10px] mt-[24px]">
        <div
          style={{
            borderBottom: "1px solid rgba(174, 174, 174, 0.46)",
          }}
          className="flex flex-col justify-center px-[24px] bg-alabasterHeader pt-6 pb-4 rounded-t-[10px]"
        >
          <div className="flex justify-between items-center">
            <div className="flex flex-col">
              <span className="text-black text-sm font-semibold">
                Recent disbursement history
              </span>

              <span className=" text-xs text-[#959595]">
                Here is a recent list of your receivables from Payment
                disbursement
              </span>
            </div>

            <div>
              <button
                onClick={() => {
                  setActiveTab(6);
                  sessionStorage.setItem("activeTabDisbursementFunder", 6);
                }}
                className="px-5 py-2.5 rounded-[5px] border-[0.5px] border-[#959595]"
              >
                View All Transaction History
              </button>
            </div>
          </div>
        </div>

        <div className="px-[24px] pb[24px]">
          <Table
            columns={columns}
            data={
              disbursementFunderOverviewData?.data?.recentDisbursementHistory
                ?.singleTransactionHistory?.data
            }
            onRowClicked={(row) =>
              transactionDetails(
                row?.transactionId,
                row?.invoiceId,
                row?.programId,
                row?.fundingChannel
              )
            }
            progressPending={isLoading}
            progressComponent={<Loading />}
            pointer
            tableHeader
            className="mt-5"
          />
        </div>
      </Card>
    </>
  );
};

export default RecentPaymentTable;
