import { getVirtualAccounts } from "appstate/api/invoice/invoice";
import { PAIconPaymnet } from "assets/images/svgs";
import React, { useEffect, useState } from "react";

const SelectionAccount = ({ isOpen, onClose, onSubmit,  setSelectedAccount }) => {
//   const { allVirtualAccountsData } = useSelector((state) => state?.wallet);
  const [virtualAccounts, setVirtualAccounts] = useState([]);

  const handleSubmit = () => {
    onSubmit();
  };

  useEffect(() => {
    const fetchVirtualAccounts = async () => {
      try {
        const result = await getVirtualAccounts();
        setVirtualAccounts(result?.data || []);
      } catch (error) {
        console.error("Error fetching associated buyers:", error);
      }
    };

    fetchVirtualAccounts();
  }, []);
  
  const accountData = virtualAccounts
    ? virtualAccounts?.filter(account => account.active) 
        .map((account) => ({
          id: account?.id,
          name: account?.accountName,
          accountNumber: account?.accountNumber,
          bankName: account?.bankName || "Payedge",
          accountType: "Virtual Account",
          amount: account?.balance,
        }))
    : [];

  if (!isOpen) return null;

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg rounded-md z-[1000]">
      <div className="bg-white rounded-md max-w-xs md:max-w-lg w-full pb-5">
        <div className="flex justify-between items-center mb-4 bg-[#FAFAFA]">
          <p className="text-black font-sans text-base font-semibold p-3 px-5 rounded-sm italic">
            Select account
          </p>
          <button
            onClick={onClose}
            className="text-black font-sans text-xl cursor-pointer flex justify-center items-center"
          >
            &times; <span className="text-sm mx-2 mt-1">Close</span>
          </button>
        </div>

        <div>
          <div className="w-full px-7">
            <p className="text-zinc-600 text-sm font-extrabold flex justify-center">
              Select which account you want to use to accept fund
            </p>
            <h1 className="text-zinc-600 text-base font-extrabold mt-5">
              Accounts
            </h1>

            <div className="md:flex md:gap-5 flex-wrap max-h-56 overflow-auto">
              {accountData.map((account, index) => (
                <div
                  key={index}
                  className="bg-[#07593D] p-3 mt-3 cursor-pointer text-white text-xs md:w-[calc(50%-10px)] md:mb-0 mb-3 rounded relative"
                  onClick={() => {
                    setSelectedAccount(account)
                    handleSubmit();
                  }}
                >
                  <div className="flex justify-between">
                    <div className="text-white text-xs flex items-center">
                      <PAIconPaymnet className="mr-2" /> <p>{account?.name}</p>
                    </div>
                  </div>
                  <p className="mt-2">{account?.accountNumber}</p>
                  <div className="flex items-center justify-between mt-2 text-[10px]">
                    <p>{account?.bankName}</p>
                    <p>{account?.accountType}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectionAccount;