import React, { useState } from "react";
import "./Tooltip.css"; // Add custom CSS for the tooltip

const TooltipShared = ({
  text,
  maxLength = 20,
  textSize = "text-sm",
  bold,
  highlight,
  fontWeight,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <span className="tooltip-container whitespace-nowrap flex items-center z-[49]">
      <span
        className={`tooltip-target whitespace-nowrap z-[49] ${
          bold ? "font-medium" : ""
        } ${textSize} ${fontWeight}  ${
          text?.length > maxLength ? "truncate" : ""
        }`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {text?.length > maxLength ? `${text.slice(0, maxLength)}...` : text}
      </span>

      {isHovered && text?.length > maxLength && (
        <span
          className={`tooltip-text z-[49] ${highlight ? highlight : "text-sm"}`}
        >
          {text}
        </span>
      )}
    </span>
  );
};

export default TooltipShared;
