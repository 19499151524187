import DashboardLayout from "layout/dashboardlayout";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "shared/Button";
import CheckMark from "../../../../assets/images/svgs/radio_btn.svg";
import ConfirmationModal from "features/BuyerEarlyPayment/EarlyPayment/singleEarlyPayment/Modals/ConfirmationModal";
import {
  insertUpload,
  mapInvoiceData,
  saveMappingForLater,
} from "appstate/api/invoice/invoice";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import ValidatingModal from "features/BuyerEarlyPayment/EarlyPayment/singleEarlyPayment/Modals/ValidatingMappingModal";
import Spinner from "shared/Spinner/Spinner";
import SuccessModal from "shared/SuccessModal";

const ValidationMapping = () => {
  const [headers, setHeaders] = useState([]);
  const [selectedValues, setSelectedValues] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [openSavingModal, setOpenSavingModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const navigator = useNavigate();
  const {
    state: { data = [], originalFile, mappings },
  } = useLocation();

  const token = useSelector((state) => state.auth.token);

  const dropdownData = [
    "Invoice No",
    "Currency Code",
    "Company",
    "Due Date",
    "Issuance Date",
    "VAT",
    "Discount",
    "Sub Total",
    "Grand Total",
    "Account Name",
    "Account No",
    "Bank Name",
    "Item Name",
    "Item Description",
    "Quantity",
    "Unit Price",
    "Amount",
    "Tax Rate",
    "Tax Amount",
    "Total Price",
  ];


  useEffect(() => {
    if (originalFile) {
      const reader = new FileReader();
      reader.onload = () => {
        const binaryData = btoa(reader.result);
        localStorage.setItem("originalFile", binaryData);
      };
      reader.readAsBinaryString(originalFile);
    }

    if (mappings && mappings.length > 0) {
      const invoiceFields = mappings.map((mapping) => mapping.invoiceField);
      setHeaders(invoiceFields);
      const initialSelectedValues = {};
      mappings.forEach((mapping, index) => {
        if (mapping.csvField) {
          initialSelectedValues[index] = mapping.csvField;
        }
      });
      setSelectedValues(initialSelectedValues);
    } else if (typeof data === "string") {
      // eslint-disable-next-line
      const cleanedData = data.replace(/[\[\]']/g, "").split(",").map((header) => header.trim());
      setHeaders(cleanedData);
    } else {
      setHeaders(data);
    }
  }, [originalFile, mappings, data]);

  const normalizeField = (field) => {
    return field
      .toLowerCase()
      .replace(/[^a-z0-9]/g, "")
      .trim();
  };

  const autoMatchFields = () => {
    const matchedValues = {};

    dropdownData.forEach((dropdownField, index) => {
      const normalizedDropdownField = normalizeField(dropdownField);

      const bestMatch = headers.find((header) => {
        const normalizedHeader = normalizeField(header);
        return normalizedHeader.includes(normalizedDropdownField);
      });

      if (bestMatch) {
        matchedValues[index] = bestMatch;
      }
    });

    setSelectedValues(matchedValues);
  };

  useEffect(() => {
    if (headers.length > 0) {
      autoMatchFields();
    }
    // eslint-disable-next-line
  }, [headers]);


  // useEffect(() => {
  //   if (originalFile) {
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       const binaryData = btoa(reader.result);
  //       localStorage.setItem("originalFile", binaryData);
  //     };
  //     reader.readAsBinaryString(originalFile);
  //   }

  //   if (mappings && mappings.length > 0) {
  //     const invoiceFields = mappings.map((mapping) => mapping.invoiceField);
  //     setHeaders(invoiceFields);
  //     const initialSelectedValues = {};
  //     mappings.forEach((mapping, index) => {
  //       if (mapping.csvField) {
  //         initialSelectedValues[index] = mapping.csvField;
  //       }
  //     });
  //     setSelectedValues(initialSelectedValues);
  //   } else if (typeof data === "string") {
  //     // eslint-disable-next-line no-useless-escape
  //     const cleanedData = data.replace(/[\[\]']/g, "").split(",").map((header) => header.trim());
  //     setHeaders(cleanedData);
  //   } else {
  //     setHeaders(data);
  //   }
  //   // eslint-disable-next-line
  // }, []);

  const fileData = localStorage.getItem("originalFile");
  const blob = new Blob([Uint8Array.from(atob(fileData), c => c.charCodeAt(0))]);
  const csvFile = new File([blob], "uploadedFile.csv", { type: "text/csv" })

  const handleSelectChange = (index, value) => {
    setSelectedValues((prev) => ({
      ...prev,
      [index]: value,
    }));
  };


  const handleSaveForLater = async () => {
    const formatFieldName = (field) => {
      if (field === "VAT") {
        return "VAT";
      } else {
        return field
          .toLowerCase()
          .split(" ")
          .map((word, index) =>
            index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
          )
          .join("");
      }
    };

    const payload = dropdownData.reduce((acc, field, index) => {
      const formattedField = formatFieldName(field);
      acc[formattedField] = selectedValues[index] || "";
      return acc;
    }, {});

    payload.reference = "";

    try {
      setOpenSavingModal(false);
      setSaveLoading(true);
      const response = await saveMappingForLater(payload, token);
      if (response?.status === 200) {
        toast.success(response?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message + "!");
    } finally {
      setSaveLoading(false);
    }
  };

  const handleProceed = async () => {
    const formatFieldName = (field) => {
      if (field === "VAT") {
        return "VAT";
      } else {
        return field
          .toLowerCase()
          .split(" ")
          .map((word, index) =>
            index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
          )
          .join("");
      }
    };

    const payload = dropdownData.reduce((acc, field, index) => {
      const formattedField = formatFieldName(field);
      acc[formattedField] = selectedValues[index] || null;
      return acc;
    }, {});

    payload.reference = "";
    try {
      setOpenModal(false);
      setLoading(true);
      const response = await mapInvoiceData(payload, token);
      if (response?.status === 201) {
        
        const uploadResponse = csvFile ? await insertUpload(csvFile) :  await insertUpload(originalFile);
        if (uploadResponse?.status === 200) {
          navigator(
            "/invoice/invoice/fundingSection/invoiceupload/validation/invoice-table",
            {
              state: { data: uploadResponse?.data, originalFile },
            }
          );
        }
      }
    } catch (error) {
      toast.error("Invoice mapping or file upload failed!");
    } finally {
      setLoading(false);
    }
  };

  // const handleProceed = async () => {
  //   navigator("/invoice/invoice/fundingSection/invoiceupload/validation/invoice-table", {
  //     state: { originalFile },
  //   });
  // };

  const handleCloseConfirm = () => {
    setOpenModal(false);
  };

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleOpenSaveModal = () => {
    setOpenSavingModal(true);
  };

  const handleCloseSaveModal = () => {
    setOpenSavingModal(false);
  };

  return (
    <DashboardLayout
      section="Invoice"
      subHeader="Here is an overview of your invoices"
    >
      <SuccessModal message="Upload Okay 0 issues found, and all fields were captured successfully" />
      {openModal && (
        <ConfirmationModal
          invoice
          handleCloseConfirm={handleCloseConfirm}
          handleProceed={handleProceed}
        />
      )}
      {openSavingModal && (
        <ValidatingModal
          handleCloseConfirm={handleCloseSaveModal}
          handleProceed={handleSaveForLater}
        />
      )}
      <header className="px-[24px] py-[16px] bg-alabasterHeader rounded-t-[5px]">
        <h1 className="text-black text-sm font-medium">
          Invoice Mapping and Validation
        </h1>
      </header>
      <div className="bg-white pt-[24px] px-[32px] pb-[20px]">
        <p className="text-lightGray text-sm font-medium pb-[24px]">
          Review the column headers in your CSV file with the invoice data.
        </p>
        <div className="border-t-[.7px] border-b-[.7px] border-silver bg-alabasterHeader px-[20px] py-[10px] flex gap-x-[135px]">
          <p className="text-textColor text-xs font-bold">
            Invoice Column Fields
          </p>
          <p className="text-textColor text-xs font-bold">CSV Column Fields</p>
        </div>

        <div>
          {dropdownData.map((field, index) => (
            <div
              key={index}
              className="border-b-[.7px] border-silver px-[20px] py-[10px] flex gap-x-[135px] items-center"
            >
              <p className="text-lightGray text-xs w-[119px]">{field}</p>
              <div className="flex items-center gap-x-2">
                <select
                  className="w-[200px] h-[43px] py-[12px] px-[16px] border-[.8px] rounded-[5px] outline-none text-[0.875rem] text-lightGray"
                  value={selectedValues[index] || ""}
                  onChange={(e) => handleSelectChange(index, e.target.value)}
                >
                  <option value="">Select CSV Column</option>
                  {Array.isArray(headers) &&
                    headers.map((header, headerIndex) => (
                      <option key={headerIndex} value={header}>
                        {header}
                      </option>
                    ))}
                </select>
                {dropdownData[index] === selectedValues[index] && (
                  <img src={CheckMark} alt="Checkmark" />
                )}
              </div>
            </div>
          ))}
        </div>

        <div className="flex justify-between px-[10px] pt-[30px]">
          <div>
            <Button
              onClick={handleOpenSaveModal}
              neutral
              className="bg-[#F8F8F8] border-[1px] border-silver rounded-[5px] py-[12px] px-[24px] !text-black font-medium !text-sm"
            >
              {saveLoading ? (
                <div className="flex items-center">
                  <Spinner color="green" className="mr-2" />
                  Save For Later
                </div>
              ) : (
                " Save For Later"
              )}
            </Button>
          </div>
          <div>
            <Button
              onClick={() => navigator(-1)}
              neutral
              className="bg-[#F8F8F8] border-[1px] border-silver rounded-[5px] py-[12px] px-[24px] !text-black font-medium !text-sm"
            >
              Discard
            </Button>

            <Button
              onClick={handleOpen}
              neutral
              className="bg-primaryColor border-[1px] border-silver rounded-[5px] py-[12px] px-[24px] !text-white font-medium !text-sm ml-[10px]"
            >
              {loading ? (
                <div className="flex items-center">
                  <Spinner className="mr-2" />
                  Save & Next
                </div>
              ) : (
                " Save & Next"
              )}
            </Button>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default ValidationMapping;
