import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { verifyUser } from "appstate/api/auth/onboarding";
import {
  updateFundingAgreementDecision,
  updateNIN,
} from "appstate/api/documentUpload";

const initialState = {
  isLoading: false,
  proceedToDashboardData: [],
};

export const uploadNIN = createAsyncThunk("uploadNIN", updateNIN);
export const uploadFundingAgreementDecision = createAsyncThunk(
  "uploadFundingAgreementDecision",
  updateFundingAgreementDecision
);
export const proceedToDashboard = createAsyncThunk(
  "proceedToDashboard",
  verifyUser
);

export const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(uploadNIN.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(uploadNIN.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(uploadNIN.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(uploadFundingAgreementDecision.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(uploadFundingAgreementDecision.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(uploadFundingAgreementDecision.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(proceedToDashboard.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(proceedToDashboard.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(proceedToDashboard.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export default onboardingSlice.reducer;
