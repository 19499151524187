import React, { useState } from "react";
import {
  PAIconFilter,
  PAIconOutlineSearch,
  PAIconDropdown,
  PAIExportableDownload,
} from "assets/images/svgs";

// import Table from "layout/table/Table";
// import Loading from "shared/Loading";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";
import SingleRequest from "./Single";
import Bulk from "./Bulk";

const TransactionHistory = () => {
  const [selectedFilters] = useState([]);
  const [isDropdownOpen] = useState(false);

  // const navigate = useNavigate();

  const bulkdumData = [
    {
      idx: "1",
      companyName: "XYZ Ventures",
      player: "Supplier",
      invoiceDate: "2023-05-23",
      invoiceReference: "INV-2090",
      currency: "NGN",
      amount: 9000000,
      status: "Successful",
      channel: "Early Payment Request",
    },
    {
      idx: "2",
      companyName: "Dangote LTD ",
      player: "Supplier",
      invoiceDate: "2023-05-23",
      invoiceReference: "INV-2090",
      currency: "NGN",
      amount: 9000000,
      status: "Successful",
      channel: "Loan Default",
    },
    {
      idx: "3",
      companyName: "Nestle PLC",
      player: "Supplier",
      invoiceDate: "2023-05-23",
      invoiceReference: "INV-2090",
      currency: "NGN",
      amount: 9000000,
      status: "Successful",
      channel: "Early Payment Offers ",
    },
  ];

  // const columnsTwo = [
  //   {
  //     name: (
  //       <span className="whitespace-nowrap">
  //         <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
  //         Transaction Party
  //       </span>
  //     ),
  //     selector: (row) => row.companyName,
  //     sortable: true,
  //     cell: (row) => (
  //       <div className="">
  //         <p>{row.companyName}</p>
  //         <span
  //           className={`${
  //             row.player === "Supplier" ? "text-[#2EA923]" : "text-[#F08000]"
  //           }`}
  //         >
  //           {`(${row.player})`}
  //         </span>
  //       </div>
  //     ),
  //   },
  //   {
  //     name: "Payment Date",
  //     selector: (row) => row.invoiceDate?.slice(0, 10),
  //     sortable: true,
  //   },
  //   {
  //     name: (
  //       <span className="whitespace-nowrap">
  //         <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
  //         No. of Invoices
  //       </span>
  //     ),
  //     selector: (row) => row.invoiceReference,
  //     sortable: true,
  //   },
  //   {
  //     name: "Currency",
  //     selector: (row) => row.currency,
  //     sortable: true,
  //     width: "6.2rem",
  //   },
  //   {
  //     name: "Amount",
  //     selector: (row) => row.amount,
  //     sortable: true,
  //     cell: (row) => (
  //       <p className="text-[#2EA923]">{formatAmount(row.amount)}</p>
  //     ),
  //   },
  //   {
  //     name: "Status",
  //     selector: (row) => row.status,
  //     sortable: true,
  //     cell: (row) =>
  //       row.status === "Successful" ? (
  //         <p className="text-[#07593D]">{row.status}</p>
  //       ) : row.status === "REJECTED" ? (
  //         <p className="text-red">{row.status}</p>
  //       ) : row.status === "UPDATED" ? (
  //         <p className="text-blue-300">{row.status}</p>
  //       ) : row.status === "PARTIALLY Successful" ? (
  //         <p className="text-yellow-400">{row.status}</p>
  //       ) : (
  //         <p>{row.status}</p>
  //       ),
  //   },

  //   {
  //     name: "Transaction Type",
  //     selector: (row) => row.channel,
  //     sortable: true,
  //     width: "6.2rem",
  //   },
  //   {
  //     name: "Action",
  //     selector: (row) => row.action,
  //     sortable: true,
  //     cell: (row) => (
  //       <div
  //         className="border border-gray300 rounded text-[#959595] p-2"
  //         // onClick={() => transactionDetails(row.invoiceId)}
  //       >
  //         View Details
  //       </div>
  //     ),
  //   },
  // ];

  // const handleRowClick = (row) => {
  //   console.log("====HELLO---");
  //   if (row.channel === "Loan Default") {
  //     navigate(
  //       `/disbursement/funder/disbursement/detailsforEarlyPaymentRequest/${row.idx}`
  //     );
  //   } else if (row.channel === "Early Payment Request") {
  //     // navigate(
  //     //   `/work-flow-management/detail/PendingRequestInvoiceApprovalScreen/${row.workflowId}`
  //     // );
  //     navigate(
  //       `/disbursement/funder/disbursement/detailsforEarlyPaymentRequestMain/${row.idx}`
  //     );
  //     // navigate(
  //     //   `/work-flow-management/detail/approvalviawalletscreendetails/${row.workflowId}`
  //     // );
  //   } else if (row.channel === "Early Payment Offers ") {
  //     navigate(
  //       `/disbursement/funder/disbursement/detailsforEarlyPaymentOffer/${row.idx}`
  //     );
  //   }
  // };

  const [activeTab, setActiveTab] = useState("");

  // const BulkTable = () => {
  //   return (
  //     <Table
  //       columns={columnsTwo}
  //       data={bulkdumData}
  //       // onRowClicked={(row) => transactionDetails(row.idx)}
  //       progressComponent={<Loading />}
  //       pointer
  //       tableHeader
  //       className="mt-5"
  //     />
  //   );
  // };

  const ReturnTab = () => {
    if (activeTab === "Bulk requests") {
      return <Bulk />;
    } else {
      return <SingleRequest />;
    }
  };

  return (
    <>
      <div>
        {" "}
        <div className="my-2 grid md:grid-cols-2 items-center">
          <div className="gap-3 flex relative">
            <div>
              <div className="flex items-center gap-[4px] border border-[#DEDEDE] bg-white shadow-sm rounded-[5px] px-4 py-2 text-sm focus:outline-none cursor-pointer">
                Filter{" "}
                {selectedFilters.length > 0 && (
                  <span className="bg-gray rounded-lg p-1 px-2">
                    {selectedFilters.length}
                  </span>
                )}{" "}
                Selected <PAIconDropdown />
              </div>
              {isDropdownOpen && (
                <div className="absolute top-full mt1 wfull border border-[#DEDEDE] shadow-lg rounded-b-[5px] px-4 py-3 bg-white">
                  <label className="block">
                    <input type="checkbox" value="date" />
                    Date
                  </label>
                  <label className="block">
                    <input type="checkbox" value="name" />
                    Name
                  </label>
                  <label className="block">
                    <input
                      type="checkbox"
                      value="currency"
                      checked={selectedFilters.includes("currency")}
                    />
                    Currency
                  </label>
                  <label className="block">
                    <input
                      type="checkbox"
                      value="amount"
                      checked={selectedFilters.includes("amount")}
                    />
                    Amount
                  </label>
                </div>
              )}
            </div>
            <div>
              <select
                className="border border-[#DEDEDE] shadow-sm rounded-[5px] px-4 py-3 text-sm focus:outline-none cursor-pointer"
                onChange={(e) => {}}
              >
                <option value="">Sort by</option>
                <option value="date">Date</option>
                <option value="name">Name</option>
              </select>
            </div>
            <button className="py-2 px-4 rounded-[5px] bg-white border-[#DEDEDE] shadow-sm mr-2  flex  gap-2">
              Export
              <span>{<PAIExportableDownload />}</span>
            </button>
          </div>
          <div className="flex md:col-span-1 justify-end">
            {/* <SearchInput /> */}
            {/* starts from here */}
            <div>
              <div className="flex items-center justify-between gap-5">
                <div className="border border-transparent md:border-gray md:shadow-sm my-4 rounded-[5px] px-2 lg:w-[300px] bg-white">
                  <div className="items-center flex bg-white">
                    <PAIconOutlineSearch />
                    <input
                      className="bg-transparent p-2 w-full focus:outline-none text-sm bg-white"
                      type="text"
                      placeholder="Search for due invoices awaiting collection"
                    />
                  </div>
                  <div className="lg:hidden flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer">
                    <PAIconFilter className="bg-[#2FA06A]" />
                  </div>
                </div>
                <button className="hidden lg:flex bg-[#2FA06A] rounded-md my-4 py-2 px-6 mx3 items-center gap-2 text-white">
                  <PAIconFilter /> Filter
                </button>
              </div>
            </div>

            {/* ends here */}
          </div>
        </div>
        <>
          {!bulkdumData.length ? (
            <div className="ml3">
              <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
                <p className="text-black text-sm font-medium">
                  Overview of CashInflow Records
                </p>
              </div>
              <TableEmptyState dashboard />
            </div>
          ) : (
            <Card className="rounded-[10px] mt-[24px] ">
              <div className="bg-alabasterHeader rounded-t-[10px] min-h-20 px-[24px]   ">
                <div className=" border-b-[0.8px] border-zinc-400 py-4 h-full ">
                  <div className="flex  items-center    ">
                    <div>
                      <p className="text-black text-lg font-semibold">
                        Recent disbursement history
                      </p>
                      <span className="text-xs font-thin text-[#959595]">
                        Here is a recent list of your receivables from Payment
                        disbursement
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {/* top ends here */}

              <div className="flex  items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
                <div class="flex items-center">
                  <p
                    className={
                      activeTab === "Single requests"
                        ? "text-[14px] text-secondaryColor border-b-[1px] border-secondaryColor"
                        : "text-[14px] cursor-pointer"
                    }
                    onClick={() => {
                      setActiveTab("Single requests");
                    }}
                  >
                    Single requests
                  </p>
                  <p
                    className={
                      activeTab === "Bulk requests"
                        ? "text-[14px] ml-4 text-secondaryColor border-b-[1px] border-secondaryColor"
                        : "text-[14px] ml-4 cursor-pointer"
                    }
                    onClick={() => {
                      setActiveTab("Bulk requests");
                    }}
                  >
                    Bulk requests
                  </p>
                </div>
              </div>
              <div className="px-[24px] pb[24px]">
                {/* {activeTab === "single Request" ? ( */}
                {/* <SingleRequest /> */}
                {/* // ) : ( // <BulkTable />
                // )} */}

                {<ReturnTab />}
              </div>
            </Card>
          )}
        </>
      </div>
    </>
  );
};

export default TransactionHistory;
