import { motion } from "framer-motion";
import { PAIconArrowLeftGreen } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TransitionScale from "shared/animation/TransitionScale";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInvoiceById } from "appstate/openMarket/openMarketSlice";
import DirectFundingInvoice from "./directFundingInvoice";

function FunderCollectionOverDueSingleInvoiceDeatils() {
  const { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const offerId = searchParams.get("offerId");

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getInvoiceById({ id }));
  }, [dispatch, id, offerId]);

  const { invoiceByIdData } = useSelector((state) => state?.openMarket);

  function handleGoBack() {
    navigate(-1);
  }

  return (
    <DashboardLayout
      section="Collections"
      subHeader="Track invoices due for payment collection"
    >
      <TransitionScale className="flex  flex-col gap-6 bg-[#EEE] h-full">
        <header onClick={handleGoBack} className="w-full">
          <div className="flex cursor-pointer w-fit gap-1 items-center">
            <PAIconArrowLeftGreen />
            <span className=" text-primaryColor text-sm">Go back</span>
          </div>
        </header>

        <main className="flex flex-col semi-lg:flex-row pb-8 gap-4 w-full ">
          <DirectFundingInvoice
            invoiceByIdData={invoiceByIdData}
            // setLockModalOpen={setLockModalOpen}
          />

          <motion.section
            initial={{
              y: -20,
            }}
            animate={{
              y: 0,
            }}
            exit={{
              y: 20,
            }}
            transition={{
              delay: 0.8,
            }}
            className="flex flex-col gap-3  pt-7 pb-24 w-2/5 bg-white rounded-[10px]"
          >
            <header className="flex  px-6">
              <span className="font-bold">Direct Fund Request</span>
            </header>

            <div>
              {" "}
              <div className="   pb-4">
                <div class="w-full justify-start items-center gap-4 inline-flex "></div>
                <div class=" justify-between  flex flex-row    px-6   ">
                  <div className="flex gap-2 py-2.5">
                    <div className="flex flex-col">
                      {/* <span className=" text-black text-xs font-normal">
      Coca Cola
    </span> */}
                      <span class="text-black text-sm font-normal ">
                        {"Nestle PLC"}
                      </span>
                      {/* <span className="text-[#979797]  text-xs">Supplier</span> */}
                      <span class="text-[#949494] text-sm font-normal ">
                        Buyer
                      </span>
                    </div>
                  </div>
                  <div class=" flex-col justify-start items-start inline-flex">
                    {" "}
                    <div className="flex gap-2 py-2.5">
                      <div className="flex flex-col">
                        {/* <span className=" text-black text-xs font-normal">
      Coca Cola
    </span> */}
                        <span class="text-black text-sm font-normal ">
                          {"Dangote"}
                        </span>
                        {/* <span className="text-[#979797]  text-xs">Supplier</span> */}
                        <span class="text-[#949494] text-sm font-normal ">
                          Supplier
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* here */}
                </div>
                <div class=" justify-between  flex flex-row     px-6     mt-2">
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        N90,000{" "}
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Discount Amount
                      </div>
                    </div>
                  </div>
                  {/* dhdh */}
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        {"%15)"}
                        {/* {details?.earlyPaymentDate && details?.earlyPaymentDate} */}
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Discount Rate
                      </div>
                    </div>
                  </div>
                </div>
                <div class=" justify-between  flex flex-row     px-6    mt-2">
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        {"23-Dec-2024 "}
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Request Date
                      </div>
                    </div>
                  </div>
                  {/* dhdh */}
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        {"23-Dec-2024 "}
                        {/* {details?.earlyPaymentDate && details?.earlyPaymentDate} */}
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Early Payment date
                      </div>
                    </div>
                  </div>
                </div>
                <div class=" justify-between  flex flex-row    px-6  mt-2 ">
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        N23,090,000
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Request Amount
                      </div>
                    </div>
                  </div>
                  {/* hd */}
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-base font-extrabold ">
                        N22,650,650
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Receivable Amount
                      </div>
                    </div>
                  </div>{" "}
                </div>
                <div class=" justify-between  flex flex-col     px-6  pt-6 border-t border-[#c1c1c1]/60 border-b pb-14 mt-4">
                  <div class="text-black text-lg font-semibold  ">
                    Note from Buyer:{" "}
                  </div>

                  <div class=" text-[#949494] text-sm font-light  mt-4  leading-6">
                    Seeking Early payment due to unexpected challenges. Facing
                    temporary financial constraints and need a bit more time to
                    ensure a smooth transaction. Appreciate your understanding.
                    Thank you.
                    <br /> Best regards,
                    <br /> ABC Limited
                  </div>
                </div>{" "}
                {/* 2nd */}
                {/* another header */}
                {/* header ends here */}
                {/* here */}
              </div>
            </div>
          </motion.section>
        </main>
      </TransitionScale>
    </DashboardLayout>
  );
}

export default FunderCollectionOverDueSingleInvoiceDeatils;
