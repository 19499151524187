import React, { useEffect, useState } from "react";
import RouteHeader from "../../RouteHeader";
import {
  PAIconActionAgreementArrowEnd,
  PAIconDrawAddFiles,
  PAIconTickDocIcon,
} from "assets/images/svgs";
import UploadAgreement from "./UploadAgreement";
import TemplateDownloadModal from "./TemplateDownloadModal";
import { submitTemplate } from "appstate/api/agreement/agreement";
// import { useDispatch } from "react-redux";
// import { uploadAgreement } from "appstate/toolsAndAgreement/toolsAndAgreementSlice";
// import toast from "react-hot-toast";

const NewAgreement = ({ goBack }) => {
  const [showUploadAgreement, setShowUploadAgreement] = useState(false);
  const [isTemplateModalOpen, setTemplateModalOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [templateName, setTemplateName] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isSavedSuccess, setIsSavedSuccess] = useState(false);
  const [agreementType, setAgreementType] = useState("");

  // const dispatch = useDispatch();

  const toggleTemplateDownloadModal = () => {
    setTemplateModalOpen(!isTemplateModalOpen);
  };

  const handleUploadClick = () => {
    setShowUploadAgreement(true);
  };

  const handleFileSelect = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleSubmitTemplate = async () => {
    try {
      const response = await submitTemplate({
        name: templateName,
        type: agreementType,
        action: "Submit",
        file: file,
      });
      if (response?.success) {
        setIsSubmit(false);
        setIsSubmitSuccess(true);
      }
    } catch (error) {
      console.error("Upload failed:", error);
    }
  }

  const handleSaveTemplate = async () => {
    try {
      const response = await submitTemplate({
        name: templateName,
        type: agreementType,
        action: "Save",
        file: file,
      });
      if (response?.success) {
        setIsOpen(false);
        setIsSavedSuccess(true);
      }
    } catch (error) {
      console.error("Upload failed:", error);
    }
  }


  useEffect(() => {
    if (file !== null) {
      handleUploadClick();
      // dispatch(uploadAgreement(file)).then((data) => {
      //   if (data?.payload?.success === true) {

      //     toast.success("File uploaded succcesfully");
      //   }
      // });
    }
  }, [file]);

  const back = () => {
    setShowUploadAgreement(false);
  };

  return (
    <div>
      {showUploadAgreement ? (
        <>
          {/* <div className="px-7 mb-5">
            <RouteHeader
              Route1="Agreements"
              Heading="Upload agreement"
              handleBack={goBack}
            />
          </div>
          <h1 className="px-7 text-black font-bold text-lg tracking-wide mt-10">
            Upload agreement
          </h1> */}
          <UploadAgreement
            getBack={back}
            file={file}
            setTemplateName={setTemplateName}
            setAgreementType={setAgreementType}
            templateName={templateName}
            agreementType={agreementType}
            handleSubmitTemplate={handleSubmitTemplate}
            handleSaveTemplate={handleSaveTemplate}
            isSubmit={isSubmit}
            setIsSubmit={setIsSubmit}
            isSubmitSuccess={isSubmitSuccess}
            setIsSubmitSuccess={setIsSubmitSuccess}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            isSavedSuccess={isSavedSuccess}
            setIsSavedSuccess={setIsSavedSuccess}
          />
        </>
      ) : (
        <>
          {/* <div className="px-7 mb-5">
            <RouteHeader
              Route1="Agreements"
              Heading="Upload agreement"
              handleBack={goBack}
            />
          </div>
          <h1 className="px-7 text-black font-bold text-lg tracking-wide mt-10">
            Upload agreement
          </h1> */}
          <div className="px-7 mb-5">
            <RouteHeader
              Route1="Agreements"
              // Heading="Upload agreement"
              handleBack={goBack}
            />
          </div>
          {/* <h1 className="px-7 text-black font-bold text-lg tracking-wide mt-10">
            Upload agreement
          </h1> */}

          <div class=" w-full px-6 inline-flex flex-col justify-start items-start gap-1">
            <div class="justify-start text-black text-sm font-medium ">
              Create template
            </div>
            <div class="w-80 min-w-80 justify-start text-neutral-800 text-xs font-normal ">
              Upload first document for this templates to use for your
              agreements
            </div>
          </div>
          <div className="flex items-center justify-center mt-5">
            {/* FILE UPLOAD */}
            <div className="p-10 lg:p-20 xl:p-5 xl:px-36 border-[#E4E7EC] border border-dashed bg-[#F8FAFB] flex-row items-center justify-center">
              <div className="flex items-center justify-center">
                <PAIconDrawAddFiles />
              </div>
              <p className="text-black text-center mt-3">
                Drag file here to upload or{" "}
                <label
                  htmlFor="fileInput"
                  className="text-[#F08000] cursor-pointer"
                >
                  choose file
                </label>
                <input
                  type="file"
                  id="fileInput"
                  className="hidden"
                  onChange={handleFileSelect}
                />{" "}
              </p>
            </div>
          </div>

          {/* BORDER */}
          <div className="flex items-center justify-center mt-5 px-10 lg:px-20 xl:px0 xl:px-40">
            <div className="border border-gray w-full my-5"></div>
            <p className="mx-4">OR</p>
            <div className="border border-gray w-full my-5"></div>
          </div>
          {/* UPLOAD URL */}
          <div className="flex flex-col itemscenter px-10 lg:px-20 xl:px0 xl:px-40">
            <label
              htmlFor="fileUrl"
              className="mb-2 text-sm text-black font-normal"
            >
              Upload from URL
            </label>
            <div className="relative w-full">
              <input
                type="text"
                id="fileUrl"
                placeholder="Add file URL"
                className="w-full border border-[#CCC] hover:border-blue-300 outline-none rounded bg-[#FAFAFA] p-2"
              />
              <button className="absolute top-1/2 right-2 transform -translate-y-1/2 border border-[#CCC] rounded-sm bg-white p-1.5">
                Upload
              </button>
            </div>
          </div>

          {/* BORDER */}
          <div className="flex items-center justify-center mt-5 px-10 lg:px-20 xl:px0 xl:px-40">
            <div className="border border-gray w-full my-5"></div>
            <p className="mx-4">OR</p>
            <div className="border border-gray w-full my-5"></div>
          </div>

          <div className="px-10 lg:px-20 xl:px0 xl:px-40">
            <div
              className=" border border-[#CCC] hover:border-blue-300 outline-none rounded bg-[#FAFAFA] p-4 flex items-center justify-center gap-4 cursor-pointer"
              onClick={toggleTemplateDownloadModal}
            >
              <PAIconTickDocIcon className="w6 h6 mr-2" />
              <p className="text-[#222222] font-SFProDisplay text-sm font-normal">
                <div class="justify-start text-neutral-800 text-base font-medium ">
                  Download and make use of our predesigned contract templates
                </div>
              </p>{" "}
              <PAIconActionAgreementArrowEnd />
            </div>
          </div>
        </>
      )}
      <TemplateDownloadModal
        isOpen={isTemplateModalOpen}
        onClose={toggleTemplateDownloadModal}
      />
    </div>
  );
};

export default NewAgreement;
