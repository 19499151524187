import { PAIconArrowLeftGreen } from "assets/images/svgs";
import React, { useState, useEffect } from "react";
import AcceptModal from "./acceptModal";
import RejectModal from "./rejectModal";
import FundingOptionsModal from "features/invoice/buyerInvoice/Modals/FundingOptionsModal";
import TransferFundsSetupModal from "features/invoice/buyerInvoice/Modals/TransferFundsSetupModal";
import SelectAccountModal from "features/invoice/buyerInvoice/Modals/SelectAccountModal";
import EnterPin from "features/invoice/buyerInvoice/Modals/EnterPin";
import approved from "../../../../../assets/images/svgs/approved.svg";
import RejectReason from "features/invoice/buyerInvoice/Modals/RejectReason";
import RejectionSuccess from "features/invoice/buyerInvoice/Modals/RejectionSuccess";
import ThirdPartyModal from "features/BuyerEarlyPayment/EarlyPayment/singleEarlyPayment/Modals/ThirdPartyModal";
import AccountVerifyAndSuccess from "features/invoice/buyerInvoice/Modals/AccountVerifyAndSuccess";
import { useNavigate } from "react-router-dom";
import SelectFunderClosedMarket from "features/invoice/buyerInvoice/Modals/SelectFunderClosedMarket";
import { useDispatch, useSelector } from "react-redux";
import {
  pendingSingleEarlyPaymentDetails,
  allBuyerAssociatedFunders,
} from "appstate/buyer/buyerSlice";
import { allInvoiceById } from "appstate/invoice/invoiceSlice";

const Details = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRejectModalOpen, setRejectModalOpen] = useState(false);
  const [isSelectAccountModalOpen, setSelectAccountModalOpen] = useState(false);
  const [isenterPinModal, setIsEnterPinModal] = useState(false);
  const [isVerifyAccountModal, setIsVerifyAccountModal] = useState(false);
  const [paymentViaWallet, setPaymentViaWallet] = useState(false);
  const [isRequestRejected, setIsRequestRejected] = useState(false);
  const [isRejectReasonModal, setIsRejectReasonModal] = useState(false);
  const [isRejectSuccessModal, setIsRejectSuccessModal] = useState(false);
  const [isThirdPartyModal, setIsThirdPartyModal] = useState(false);
  const [isFundingModalOpen, setIsFundingModalOpen] = useState(false);
  const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);
  const [fromBuyer, setFromBuyer] = useState(false);
  const [toClosedMarket, setToClosedMarket] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [checkedAccounts, setCheckedAccounts] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [fromThridParty, setFromThridParty] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [isSelectClosedMarketFunder, setIsSelectClosedMarketFunder] =
    useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getInvoiceId, pendingSingleEarlyPaymentDetailsData } = useSelector(
    (state) => state?.buyer
  );
  const [search] = useState("");
  const [page] = useState(0);
  const pageSize = 10;
  const { invoiceIdData } = useSelector((state) => state.invoice);
  const [data, setData] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);
  const [, setTransferInfoValues] = useState("");
  const [selectedFunders, setSelectedFunders] = useState([]);
  const [closeMarketActive, setClosedMarketActive] = useState(false);
  const invoiceType = "Single";
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    dispatch(allBuyerAssociatedFunders({ page, pageSize, search }));
  }, [dispatch, page, pageSize, search]);

  useEffect(() => {
    dispatch(pendingSingleEarlyPaymentDetails(getInvoiceId?.earlyPaymentId));
  }, [dispatch, getInvoiceId?.earlyPaymentId]);

  useEffect(() => {
    const id = getInvoiceId?.invoiceId;
    dispatch(allInvoiceById({ id }));
  }, [dispatch, getInvoiceId?.invoiceId]);

  useEffect(() => {
    setData(pendingSingleEarlyPaymentDetailsData?.data);
  }, [pendingSingleEarlyPaymentDetailsData]);

  useEffect(() => {
    setInvoiceData(invoiceIdData?.data);
  }, [invoiceIdData]);

  const handleAccept = () => {
    setIsFundingModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setRejectModalOpen(false);
  };

  const handleReject = () => {
    setRejectModalOpen(true);
  };

  //funding Request modal control
  const handleFundingRequest = () => {
    setIsFundingModalOpen(false);
    setIsTransferModalOpen(true);
  };

  const handleFundingRequestClose = () => {
    setIsFundingModalOpen(false);
  };

  //Transfer modal control

  const handleTransferRequest = () => {
    setSelectAccountModalOpen(true);
    setIsTransferModalOpen(false);
  };

  const handleTransferModalClose = () => {
    setIsTransferModalOpen(false);
  };

  // Select account controls
  const handleSelectAccountClose = () => {
    setSelectAccountModalOpen(false);
  };

  const handleSelectAccountProceed = () => {
    setSelectAccountModalOpen(false);
    setIsModalOpen(true);
  };

  //Enter pin controls
  const handleEnterPin = () => {
    setIsEnterPinModal(true);
    setIsModalOpen(false);
  };

  const handleEnterPinModalClose = () => {
    setIsEnterPinModal(false);
  };

  const handleVerifyAccountModal = () => {
    setIsVerifyAccountModal(true);
    setIsEnterPinModal(false);
    setIsLoading(true);
  };

  const handleVerifyAccountModalClose = () => {
    setIsVerifyAccountModal(false);
  };

  //Reject modal control
  const handleRejectRequest = () => {
    setRejectModalOpen(false);
    setIsRejectReasonModal(true);
  };
  const handleRejectReasonClose = () => {
    setIsRejectReasonModal(false);
  };

  //Success modal control
  const handleSuccessModal = () => {
    setIsRejectReasonModal(false);
    setIsRejectSuccessModal(true);
  };

  const handleSuccessModalClose = () => {
    setIsRejectSuccessModal(false);
  };

  //third party modal
  const handleThirdPartyModal = () => {
    setIsThirdPartyModal(true);
    setIsFundingModalOpen(false);
  };

  const handleThirdPartyModalClose = () => {
    setIsThirdPartyModal(false);
  };

  //Select closed market funder
  const handleClosedMarketFunderClose = () => {
    setIsSelectClosedMarketFunder(false);
  };

  useEffect(() => {
    if (isLoading === true) {
      setTimeout(() => {
        setIsLoading(false);
        setTimeout(() => {
          setIsApproved(true);
          setTimeout(() => {
            navigate("/payablefinancing/overview");
          }, 2000);
        }, 3000);
      }, 3000);
    }
  }, [isLoading, navigate]);

  return (
    <>
      <div
        onClick={() => {
          let path = "/payablefinancing/overview";
          navigate(path, { state: { selectedtab: "newEarlyPaymentRequests" } });
        }}
        className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer w-full"
      >
        <PAIconArrowLeftGreen />
        <span className="flex items-center text-primaryColor">Go Back</span>
      </div>

      <div className="grid lg:grid-cols-[60%,40%] gap-5 h-[80vh]">
        {/* 60% */}
        <div className="flex flex-col gap-8 pb-24 bg-white rounded-[10px] pt-7">
          <div className="px-6 ">
            <div className="flex flex-col gap-3">
              <div className="flex items-center justify-between">
                <h1 className="text-[18px] font-bold">
                  Reference No: | {invoiceData?.invoiceRef}
                </h1>
              </div>

              <main className=" w-full flex justify-between">
                <div className="flex flex-col gap-3 w-1/2">
                  <div className="flex flex-col gap-1">
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs font-semibold w-[30%]">
                        Invoice Number:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        INV-{invoiceData?.invoiceNo}
                      </span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs font-semibold w-[30%]">
                        Created Date:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {invoiceData?.createdDate}
                      </span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs font-semibold w-[30%]">
                        Submitted Date:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {invoiceData?.submittedDate}
                      </span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs font-semibold w-[30%]">
                        Approved Date:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {invoiceData?.approvedDate}
                      </span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs font-semibold w-[30%]">
                        Due Date:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {invoiceData?.dueDate}
                      </span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs font-semibold w-[30%]">
                        Issunance Date:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {invoiceData?.issuanceDate}
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <button className="w-[180px] h-[40px] bg-[#FFE9C5] rounded-[8px] text-[#F08000]">
                    Pending Approval
                  </button>
                </div>
              </main>
            </div>

            <div className="flex justify-between my-5 border-b-[4px] border-slate-100 pb-2">
              <div className="flex flex-col">
                <span className=" font-bold text-sm">
                  {" "}
                  {invoiceData?.buyerCompanyName}
                </span>
                <span className="text-[#979797] text-xs">Buyer Details</span>
              </div>
              <div className="flex flex-col">
                <span className="font-bold text-sm">
                  {invoiceData?.paymentTerms} Days
                </span>
                <span className="text-[#979797] text-xs">Payment</span>
              </div>
              <div className="flex flex-col">
                <span className="font-bold text-sm">
                  {invoiceData?.currency}
                </span>
                <span className=" text-[#979797] text-xs">Currency</span>
              </div>
            </div>

            <div className="flex flex-col gap-4 mt-5">
              <header className="border-b-[5px] border-b-[#F6F5FA] pb-3 flex items-center justify-between">
                <h1 className="text-sm font-bold">Line Items</h1>
                <h1 className="text-sm font-bold">
                  {invoiceData?.items?.length} Total Item(s)
                </h1>
              </header>

              <div className=" w-full overflow-x-auto h-[25vh]">
                <table className="overflow-x-auto w-[60vw]">
                  <thead className="bg-subtleGray h-[70px] w-[50vw] overflow-x-auto">
                    <tr className=" bg-unusualGray p-2.5">
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Item
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Description
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Unit Price
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Quantity
                      </td>
                      <td className="text-[#11192A] p-2.5 text-xs font-bold w-[120px]">
                        Amount
                      </td>
                      <td className="text-[#11192A] p-2.5 text-xs font-bold w-[120px]">
                        Tax rate
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Tax Amount
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Total Price
                      </td>
                    </tr>
                  </thead>
                  {invoiceData?.items?.map((item, index) => (
                    <tbody key={index}>
                      <tr className=" py-[3px] px-1.5">
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.itemName}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5 w-[20%]">
                          {item?.description}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.unitPrice?.toLocaleString()}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.quantity}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.amount?.toLocaleString()}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.taxRate}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.taxAmount?.toLocaleString()}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.totalPrice?.toLocaleString()}
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            </div>

            <div className="flex itemscenter justify-between px-6 mt-[10%]">
              <div>
                <h1 className="border-b border-[#B1A6B2] font-semibold">
                  Receiving Funding Account Information
                </h1>
                <p className="mt-3 text[#979797]">
                  {invoiceData?.virtualAccount?.accountName}
                </p>
                <p className="text[#979797]">
                  {" "}
                  {invoiceData?.virtualAccount?.accountNumber}
                </p>
              </div>
              <div className="flex flex-col">
                <div className="flex justify-between gap-x-[100px] w-full">
                  <span className="text-xs w-[97px]">Sub Total</span>
                  <span className="text-[#979797] text-xs text-right">
                    {invoiceData?.subTotal?.toLocaleString()}
                  </span>
                </div>

                <div className="flex justify-between w-full gap-x-[100px] my-2">
                  <span className="flex items-center text-xs w-[97px]">
                    Discount
                    <span className="border border-[#979797] pr-10 pl-2 inline-block ml-4">
                      {invoiceData?.discount}%
                    </span>
                  </span>
                  <span className="text-[#979797] text-xs">
                    {invoiceData?.discountAmount?.toLocaleString()}
                  </span>
                </div>

                <div className="flex justify-between w-full gap-x-[100px] pb-5">
                  <span className="flex items-center text-xs w-[97px]">
                    VAT
                    <span className="border border-[#979797] ml-10 pr-11 pl-2 inline-block">
                      {invoiceData?.vat}%
                    </span>
                  </span>
                  <span className="text-[#979797] text-xs">
                    {invoiceData?.vatAmount?.toLocaleString()}
                  </span>
                </div>

                <div className="flex justify-between items-center w-full gap-x-[100px] border-t-2 border-b-2 font-bold text-lg py-1">
                  <span className="w-[97px]">Grand Total</span>
                  <span className="text[#979797] py-1">
                    {invoiceData?.grandTotal?.toLocaleString()}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 40% */}
        <div className="bg-white rounded-[10px] gap-8 px-6 pt-7 pb-24">
          <div>
            <h2 className="font-semibold text-lg leading-[30px] mb-4">
              Early Payment Details
            </h2>

            <div className="flex justify-between my-5">
              <div className="flex gap-2">
                <div className="flex flex-col">
                  <span className="text-sm"> {data?.supplierCompanyName}</span>
                  <span className="text-[#979797]  text-xs">Supplier</span>
                </div>
              </div>
              <div className="flex flex-col">
                <span className="text-sm">{data?.discountRate}%</span>
                <span className="text-[#979797]  text-xs">Discount rate</span>
              </div>
            </div>

            <div className="flex justify-between pb-5">
              <div className="flex gap-2">
                <div className="flex flex-col">
                  <span className="text-sm">
                    {invoiceData?.currency}
                    {"  "}
                    {data?.discountAmount?.toLocaleString() || "0.00"}
                  </span>
                  <span className="text-[#979797]  text-xs">
                    Discount Amount
                  </span>
                </div>
              </div>
              <div className="flex flex-col">
                <span className="text-sm">
                  {data?.earlyPaymentDate}({data?.paymentDay} days)
                </span>
                <span className="text-[#979797]  text-xs">
                  Early Payment Request Date
                </span>
              </div>
            </div>

            <div className="flex justify-between mt-5 border-b-[5px] border-b-[#F6F5FA] pb-10">
              <div className="flex gap-2">
                <div className="flex flex-col">
                  <span className="text-sm">
                    {invoiceData?.currency}
                    {"  "}
                    {data?.requestAmount?.toLocaleString() || "0.00"}
                  </span>
                  <span className="text-[#979797]  text-xs">
                    Request Amount
                  </span>
                </div>
              </div>
              <div className="flex flex-col">
                <span className="text-sm">
                  {invoiceData?.currency}
                  {"  "}
                  {data?.payableAmount?.toLocaleString()}
                </span>
                <span className="text-[#979797]  text-xs">Payable amount</span>
              </div>
            </div>

            <div className="border-b-[5px] border-b-[#F6F5FA] pb-5">
              <h2 className="font-medium text-base my-3">
                Note from Supplier:
              </h2>
              <p className="text-[#959595] text-sm">{data?.description}</p>
            </div>

            {paymentViaWallet === true || isRequestRejected === true ? null : (
              <div className="pb-3">
                <h2 className="font-medium text-base leading-[30px] my-2">
                  Your decision:
                </h2>
                <p className="text-[#959595] text-sm">
                  Early Payment Request: Please review and take action. Accept
                  if approved, or Reject if not suitable. Thank you
                </p>
                <div className="flex gap-5 mt-5">
                  <button
                    className="rounded-md bg-[#07593D] text-white px-12 py-2"
                    onClick={handleAccept}
                  >
                    Accept
                  </button>
                  <button
                    className="rounded-md bg-white border border-[#F08000] text-[#F08000] px-12 py-2"
                    onClick={handleReject}
                  >
                    Reject
                  </button>
                </div>
              </div>
            )}

            {paymentViaWallet === true ? (
              <div className=" pt-[3%]">
                <div className="border-b-[3px] border-[#EFEFEF] mb-[4%] pb-[3%]">
                  <h2 className="text-[16px] font-semibold">Funding Option</h2>
                  <p>This invoice will be funded with this virtual account:</p>
                  <p>{selectedAccount.accountName}</p>
                  <p>{selectedAccount.accountNumber}</p>
                  <p>{selectedAccount.bankName}</p>
                </div>

                <div className="flex items-center justify-center mt-[3%]">
                  <img src={approved} alt="Approved" />
                </div>
              </div>
            ) : null}

            <FundingOptionsModal
              isOpen={isFundingModalOpen}
              onClose={handleFundingRequestClose}
              confirm={handleFundingRequest}
              thirdParty={handleThirdPartyModal}
              setFromBuyer={setFromBuyer}
            />
            <TransferFundsSetupModal
              data={data}
              isOpen={isTransferModalOpen}
              onClose={handleTransferModalClose}
              confirm={handleEnterPin}
              proceed={handleTransferRequest}
              selectedAccount={selectedAccount}
              fromBuyer={fromBuyer}
              fromThridParty={fromThridParty}
              toClosedMarket={toClosedMarket}
              setIsSelectClosedMarketFunder={setIsSelectClosedMarketFunder}
              currency={invoiceData?.currency}
            />
            <SelectAccountModal
              isOpen={isSelectAccountModalOpen}
              onClose={handleSelectAccountClose}
              setSelectedAccount={setSelectedAccount}
              confirm={handleSelectAccountProceed}
              setCheckedAccounts={setCheckedAccounts}
              checkedAccounts={checkedAccounts}
            />
            <AcceptModal
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              confirm={handleEnterPin}
              checkedAccounts={checkedAccounts}
            />
            <EnterPin
              isOpen={isenterPinModal}
              onClose={handleEnterPinModalClose}
              confirm={handleVerifyAccountModal}
              setTransferInfoValues={setTransferInfoValues}
              invoiceData={invoiceData}
              checkedAccounts={checkedAccounts}
              selectedFunders={selectedFunders}
              closeMarketActive={closeMarketActive}
              setSuccessMessage={setSuccessMessage}
            />
            <AccountVerifyAndSuccess
              isOpen={isVerifyAccountModal}
              onClose={handleVerifyAccountModalClose}
              setPaymentViaWallet={setPaymentViaWallet}
              isLoading={isLoading}
              isApproved={isApproved}
              message={successMessage}
            />
            <RejectModal
              isOpen={isRejectModalOpen}
              onClose={handleCloseModal}
              confirm={handleRejectRequest}
            />
            <RejectReason
              isOpen={isRejectReasonModal}
              onClose={handleRejectReasonClose}
              confirm={handleSuccessModal}
              setIsRequestRejected={setIsRequestRejected}
              setIsRejected={setIsRejected}
              earlyPaymentId={getInvoiceId?.earlyPaymentId}
            />
            <RejectionSuccess
              isOpen={isRejectSuccessModal}
              onClose={handleSuccessModalClose}
              isRejected={isRejected}
            />
            <ThirdPartyModal
              isOpen={isThirdPartyModal}
              onClose={handleThirdPartyModalClose}
              fromBuyer={fromBuyer}
              setFromThridParty={setFromThridParty}
              setIsTransferModalOpen={setIsTransferModalOpen}
              setToClosedMarket={setToClosedMarket}
              invoiceType={invoiceType}
              setIsSelectClosedMarketFunder={setIsSelectClosedMarketFunder}
            />
            <SelectFunderClosedMarket
              isOpen={isSelectClosedMarketFunder}
              onClose={handleClosedMarketFunderClose}
              setSelectAccountModalOpen={setSelectAccountModalOpen}
              selectedFunders={selectedFunders}
              setSelectedFunders={setSelectedFunders}
              setClosedMarketActive={setClosedMarketActive}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Details;
