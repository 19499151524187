import DashboardLayout from 'layout/dashboardlayout'
import React from 'react'
import Details from './details'

const BulkEarlyPaymentDetails = () => {
  return (
    <DashboardLayout
      section='Early Payment Request'
      subHeader='Here is an overview of your early payment invoices'
    >
      <Details />
    </DashboardLayout>
  )
}

export default BulkEarlyPaymentDetails