import {
  buyerFunderDisassociate,
  getAssociatedFunderId,
} from "appstate/buyer/buyerSlice";
import {
  PAIconDropUser,
  PAIconLink,
  PAIconOption,
} from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import Table from "layout/table/Table";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Card from "shared/Card";
import Loading from "shared/Loading";
import Pagination from "shared/Pagination";
// import Pagination from "shared/Pagination";
import AccountModalContainer from "shared/accountModal/Modal";

import TableHeader from "shared/tableHeader/TableHeader";
import FunderInvitationModal from "shared/inviteModal/FunderInvitationModal";
import ContinueInviteModal from "shared/inviteModal/ContnueInviteModal";
import SuccessModal from "shared/inviteModal/SuccessModal";
import { buyerSendOnboardingInviteToFunder, getAllBuyerAssociatedFunders } from "appstate/api/buyer/relationshipManagement";
import _ from "lodash"; 
import SuccessRelationship from "shared/SuccessRelationship";
import toast from "react-hot-toast";

const BuyerFunders = () => {
  const dropdownRef = useRef();
  const [dropdown, setDropdown] = useState(false);
  const [invite, setInvite] = useState([]);
  const [success, setSuccess] = useState(false);
  const [getId, setGetId] = useState(null);
  const [openDisassociateModal, setOpenDisAssociateModal] = useState(false);
  const [newInvite, setNewInvite] = useState("");
  // const [open, setOpen] = useState(false);
  // eslint-disable-next-line
  const [atBottom, setAtBottom] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [continueInvite, setContinueInvite] = useState(false);
  // eslint-disable-next-line
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [total, setTotal] = useState("");
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const [associatedFunderData, setAssociatedFunderData] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [openmodal, setOpenmodal] = useState(false);
  const pageSize = 10;
  const {
    isLoading,
    inviteLoading,
    disAssociateLoading,
  } = useSelector((state) => state?.buyer);
  // eslint-disable-next-line
  const [buyerFunder, setBuyerFunder] = useState(true);
  // eslint-disable-next-line
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const handleDropdown = (row) => {
    if (dropdown === row.userId) {
      setDropdown(null);
    } else {
      setDropdown(row.userId);
    }
  };

  const navigate = useNavigate();

  const fetchAssociatedFunders = async () => {
    setLoading(true);
    try {
      const search = searchTerm.trim();
      const result = await getAllBuyerAssociatedFunders({ page, pageSize, search });
      setTotal(result?.meta?.totalElements);
      setAssociatedFunderData(result?.data || []);
    } catch (error) {
      console.error("Error fetching associated funders:", error);
    } finally {
      setLoading(false);
    }
  };
  
  
  useEffect(() => {
    fetchAssociatedFunders();
    // eslint-disable-next-line
  }, [page, pageSize, companyName])


  const onRowClicked = (row) => {
    dispatch(getAssociatedFunderId(row?.userId));
    navigate('/buyer-funders/funderdetails', { state: { row } });
  };

  const handleOpenModal = (row) => {
    setGetId(row?.userId);
    setOpenDisAssociateModal(true);
  };

  const handleDisAssociate = () => {
    const funderId = getId;
    dispatch(buyerFunderDisassociate(funderId)).then((data) => {
      if (data?.payload?.success === true) {
        setShowSuccessModal(true);
        fetchAssociatedFunders();
        setOpenDisAssociateModal(false);
      }
    });
  };

  // const totalPages = buyerAssociatedFundersData?.meta?.totalElements;

  const column = [
    {
      name: "Name",
      selector: (row) => row?.fullName,
    },
    {
      name: "Company",
      selector: (row) => row?.companyName,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
      width: "15rem",
    },
    {
      name: "Phone Number",
      selector: (row) => row?.phoneNumber,
    },
    {
      name: "Status",
      selector: (row) => (
        <span
          style={{
            color: row?.status === "Accepted" ? "green" : "inherit",
          }}
        >
          {row?.status}
        </span>
      ),
    },
    {
      cell: (row) => (
        <div>
          <PAIconOption title="Options" onClick={() => handleDropdown(row)} />
          {dropdown === row.userId && row && (
            <div className="relative" ref={dropdownRef}>
              <Card
                className={`w-[160px] right-[-10px] ${
                  atBottom && "bottom-[20px]"
                } absolute z-10 drop-shadow-md rounded-md py-[12px]`}
              >
                <div onClick={() => onRowClicked(row?.userId)} className="flex items-center gap-x-[10px] mb-[12px]  pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]">
                  <span>
                    <PAIconDropUser />
                  </span>
                  <p className="font-sm font-normal">View Details</p>
                </div>

                {/* <div className="flex items-center gap-x-[10px] mb-[12px] pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]">
                  <span>
                    <PAIconEditlight />
                  </span>
                  <p className="font-sm font-normal">Change Tier</p>
                </div> */}
                <div className="border border-silver"></div>
                <div
                  onClick={() => handleOpenModal(row)}
                  className="flex items-center gap-x-[10px] mt-[10px] pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]"
                >
                  <span>
                    <PAIconLink />
                  </span>
                  <p className="font-sm font-normal text-[#DE1515]">
                    Disassociate
                  </p>
                </div>
              </Card>
            </div>
          )}
        </div>
      ),
      width: "5rem",
    },
  ];

  let path = "buyer-associate-funders";


  // eslint-disable-next-line
  const debouncedSearch = useCallback(
    _.debounce(() => {
      fetchAssociatedFunders();
    }, 200),
    [searchTerm]
  );

  useEffect(() => {
    if (searchTerm) {
      debouncedSearch();
    }
    // eslint-disable-next-line
  }, [searchTerm, debouncedSearch]);

  const handleSearch = () => {
    fetchAssociatedFunders();
  };

  const handleSubmitEmails = async () => {
    const body = invite;
    try {
      const response = await buyerSendOnboardingInviteToFunder(body);
      if (response) {
        setOpenmodal(false);
        // setContinueInvite(false);
        setSuccess(true);
      }
    } catch {
      console.error("an error occured");
      toast.error("error occured try again later!")
    }
  };

  const totalPages = total;

 
  const handleSubContinue = () => {
    setContinueInvite(false);
    setSuccess(true);
  };
  const handleContinueInvite = () => {
    setOpenmodal(false);
    setContinueInvite(true);
  };
  const handleClose = () => {
    setSuccess(false);
    navigate("/buyer-funders/buyer-pending-funder-request");
  };

  const onClose = () => {
    setShowSuccessModal(false);
  };

  return (
    <DashboardLayout
      section="Funders"
      subHeader="Manage all your associated funders accounts"
    >
       
       {showSuccessModal && (
        <SuccessRelationship
          isOpen={showSuccessModal}
          onClose={onClose}
          message="Disassociation successful"
        />
      )}
      <AccountModalContainer
        accontModal
        isShown={openDisassociateModal}
        onClose={() => {
          setOpenDisAssociateModal(false);
        }}
        isLoading={disAssociateLoading}
        success={success}
        handleAssociate={handleDisAssociate}
        warning="Are you sure you would like to disassociate with this member?"
      />
      <ContinueInviteModal
       isOpen={continueInvite}
       onClose={() => setContinueInvite(false)}
       isLoading={isLoading}
       handleYes={handleSubContinue}
      />
      <FunderInvitationModal
         onClose={() => setOpenmodal(false)}
         isOpen={openmodal}
         message=" The Funder would received a mail that your are inviting to join
         PayEdge."
         heading="Invite Funder"
         info="You can add one or more funders at once with ease"
         role="Funder"
         invite={invite}
         setInvite={setInvite}
         newInvite={newInvite}
         setNewInvite={setNewInvite}
         success={success}
         setSuccess={setSuccess}
         handleAdd={handleSubmitEmails}
         handleYes={handleContinueInvite}
         isLoading={inviteLoading}
      />

      <SuccessModal
        isOpen={success}
        onClose={handleClose}
        message=" The Funder would received a mail that your are inviting to join
          PayEdge."
      />
      <Card className="p-[24px] rounded-[10px]">
        <TableHeader
          path={path}
          setProductName={setCompanyName}
          productName={companyName}
          handleSearch={handleSearch}
          supplier
          associateBtn
          inviteBtn
          accounts
          tier
          data={data}
          setOpen={setOpenmodal}
          buyerFunder={buyerFunder}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          total="All Funders"
          details="Access contacts details of the associated funders"
        />
         {loading ? (
          <div className="flex justify-center">
            <Loading />
          </div>
        ) : (
        <Table
          columns={column}
          data={associatedFunderData}
          onRowClicked={onRowClicked}
          progressPending={isLoading}
          progressComponent={<Loading />}
          pointer
          tableHeader
          className="mt-5"
        />
        )}
        <br />

        <div className="flex justify-center gap-x-[8px]  mt-12">
          {!isLoading && totalPages > 10 && (
            <Pagination
              page={page}
              startPage={startPage}
              setEndPage={setEndPage}
              endPage={endPage}
              setStartPage={setStartPage}
              setPage={setPage}
              totalPages={totalPages}
            />
          )}
        </div>
      </Card>
    </DashboardLayout>
  );
};

export default BuyerFunders;
