import { disassociateBuyerSupplier } from "appstate/api/buyer/relationshipManagement";
import { getAllCreatedTiers } from "appstate/api/tier";
import { assignTiers } from "appstate/tier/tierSlice";
import { PAIconInventoryArrowLeft } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "shared/Button";
import Card from "shared/Card";
import SuccessModal from "shared/SuccessModal";
import AccountModalContainer from "shared/accountModal/Modal";
import InvoiceModalContainer from "shared/invoiceModal";

const BuyerSupplierDetails = () => {
  const [open, setOpen] = useState(false);
  const [assignTier, setAssignTier] = useState("");
  const [openDisassociateModal, setOpenDisAssociateModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [tierData, setTierData] = useState([]);
  // const [searchTerm, setSearchTerm] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [page, setPage] = useState(0);
  const size = 10;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { row } = location.state || {};
  const dropdownRef = useRef(null);
  const [loading, setIsLoading] = useState(false);
  function handleNavigate() {
    navigate(-1);
  }

  useEffect(() => {
    fetchTiers();
    const dropdown = dropdownRef.current;
    dropdown?.addEventListener("scroll", handleScroll);

    return () => dropdown?.removeEventListener("scroll", handleScroll);
    // eslint-disable-next-line 
  }, [page]);

  const fetchTiers = async () => {
    if (!hasMore || loadingMore) return;
    const searchTerm = "";
    setLoadingMore(true);
    try {
      const result = await getAllCreatedTiers({page, size, searchTerm});
      const newTiers = result?.data || [];
      if (newTiers.length < size) setHasMore(false);

      setTierData((prevData) => [...prevData, ...newTiers]);
    } catch (error) {
      console.error("Error fetching tiers:", error);
    } finally {
      setLoadingMore(false);
    }
  };

  const handleScroll = () => {
    if (dropdownRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = dropdownRef.current;
      if (scrollHeight - scrollTop <= clientHeight + 10 && !loadingMore) {
        setPage((prevPage) => prevPage + 1);
      }
    }
  };




  const userId = row?.userId;

  const handleTierChange = (e) => {
    const tierId = e.target.value;
    setAssignTier(tierId);
    localStorage.setItem(`selectedTier_${userId}`, tierId);

    dispatch(assignTiers({ userId, tierId })).then((data) => {
      if (data?.payload?.success) {
        setShowSuccess(true);
      }
    });
  };

  useEffect(() => {
    const savedTier = localStorage.getItem(`selectedTier_${userId}`);
    if (savedTier) setAssignTier(savedTier);
  }, [userId]);


  const handleDisAssociate = async () => {
    try {
      setIsLoading(true);
      const response = await disassociateBuyerSupplier(row?.userId);
      if (response?.status === 200) {
        setSuccess(true);
        setOpenDisAssociateModal(false);
      }
    } catch (error) {
      console.error("Error disassociating supplier:", error);
      toast.error("Error disassociating supplier, try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleShowModal = async () => {
    setOpenDisAssociateModal(true);
  };

  const handleCloseSuccessModal = () => {
    setShowSuccess(false);
  }

  return (
    <DashboardLayout
      section="Supplier Details"
      subHeader="Manage all your associated suppliers accounts"
    >
       <SuccessModal
        onClose={handleCloseSuccessModal}
        isOpen={showSuccess}
        message="Tier Assigned successfully"
      />
      <AccountModalContainer
        accontModal
        isShown={openDisassociateModal}
        onClose={() => {
          setOpenDisAssociateModal(false);
        }}
        isLoading={loading}
        success={success}
        handleAssociate={handleDisAssociate}
        warning="Are you sure you would like to disassociate with this member?"
      />
      <InvoiceModalContainer isShown={open} onClose={() => setOpen(false)} />
      <div>
        <header className="bg-alabasterHeader p-[24px] rounded-t-[10px]">
          <div
            onClick={handleNavigate}
            className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
          >
            <PAIconInventoryArrowLeft />
            <p className="mt-[2px] text-sm font-normal text-lightGray">
              Go Back
            </p>
          </div>
          <div className="flex  flex-wrap justify-between">
            <div>
              <p className="text-black text-sm font-medium">Details</p>
              <p className="text-textColor text-xs font-normal">
                Basic information about the associate
              </p>
            </div>
            <div>
              <div>
                <Button
                  neutral
                  onClick={handleShowModal}
                  className="flex text-[#F08000] text-xs font-medium items-center mb-[10px] md:mb-0 md:px-[5px] border-[1px] bg-[white] py-[12px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px]"
                >
                  <p style={{ color: "red" }} className="whitespace-nowrap">
                    Dissassociate with supplier
                  </p>
                </Button>
              </div>
            </div>
          </div>
        </header>
        <Card className="px-[20px] md:px-[40px] pb-[40px] rounded-b-[10px] 2xl:pl-[100px]">
          <div>
            <div className="md:flex md:justify-between  pt-[24px] 2xl:justify-start 2xl:gap-x-[385px] ">
              <div>
                <h2 className="text-black text-sm font-semibold">
                  Personal Information
                </h2>
                <div className="mt-[24px]">
                  <p className="text-sm font-normal text-lightGray">Name</p>
                  <p className="mt-[8px] text-textColor text-sm font-normal">
                    {row?.fullName}
                  </p>
                </div>
                <div className="mt-[24px]">
                  <p className="text-sm font-normal text-lightGray">Email</p>
                  <p className="mt-[8px] text-textColor text-sm font-normal">
                    {row?.email}
                  </p>
                </div>
                <div className="mt-[24px]">
                  <p className="text-sm font-normal text-lightGray">
                    Vendor ID
                  </p>
                  <p className="mt-[8px] text-textColor text-sm font-normal">
                    {row?.vendorId}
                  </p>
                </div>
                <div className="mt-[24px]">
                  <p className="text-sm font-normal text-lightGray">
                    Contact number
                  </p>
                  <p className="mt-[8px] text-textColor text-sm font-normal">
                    {row?.phoneNumber}
                  </p>
                </div>
              </div>

              <div className="mt-[24px] md:mt-0">
                <h2 className="text-black text-sm font-semibold">
                  Company Information
                </h2>
                <div className="mt-[24px]">
                  <p className="text-sm font-normal text-lightGray">
                    Company Name
                  </p>
                  <p className="mt-[8px] text-textColor text-sm font-normal">
                    {row?.companyName}
                  </p>
                </div>
                <div className="mt-[24px]">
                  <p className="text-sm font-normal text-lightGray">
                    Company Address
                  </p>
                  <p className="mt-[8px] text-textColor text-sm font-normal">
                    {row?.registeredAddress}
                  </p>
                </div>
                <div className="mt-[24px]">
                  <p className="text-sm font-normal text-lightGray">
                    Company Website
                  </p>
                  <p className="mt-[8px] text-textColor text-sm font-normal">
                    {row?.companyWebsite}
                  </p>
                </div>
                <br />
                <select
                  className="w-[159.998px] p-[5px] outline-none border border-silver rounded mt-[5px]"
                  name="assignTier"
                  id="assignTier"
                  onChange={handleTierChange}
                  value={assignTier}
                >
                  <option disabled value="">
                    Select tier
                  </option>
                  {tierData?.map((data) => (
                    <option key={data?.tierId} value={data?.tierId}>
                      {data?.tierName}
                    </option>
                  ))}
                </select>
                {/* {loadingMore && <p>Loading more tiers...</p>} */}
              </div>
            </div>
          </div>
        </Card>
      </div>
    </DashboardLayout>
  );
};

export default BuyerSupplierDetails;
