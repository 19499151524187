import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setActiveTab } from "appstate/collections/collectionSlice";

const HeaderTabs = ({
  tabs,
  buyerReimbursement,
  hasPaddingY,
  selectedStatus,
  setSelectedStatus,
}) => {
  const activeTab = useSelector((state) => state?.collection?.activeTab);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!activeTab && tabs.length > 0) {
      dispatch(setActiveTab(tabs[0].key));
    }
  }, [activeTab, dispatch, tabs]);

  const handleTabClick = (tabKey) => {
    dispatch(setActiveTab(tabKey));
  };

  const renderTabContent = () => {
    const activeTabContent = tabs.find((tab) => tab.key === activeTab)?.content;
    return activeTabContent || null;
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case "Liened":
        return { backgroundColor: "#06593D", color: "white" };
      case "Upcoming":
        return { backgroundColor: "#F08000", color: "white" };
      case "Overdue":
        return { backgroundColor: "red", color: "white" };
      default:
        return {};
    }
  };

  return (
    <div>
      <div
        className={`rounded-md w-full bg-white mt5 mx6 mb-[17px] cursor-pointer ${
          hasPaddingY ? "py-2" : ""
        }`}
      >
        <div className="md:flex md:flex-row flex-col gap1 p-2 px-7 justify-between gap-10 items-center">
          <div className="md:flex md:flex-row flex-col gap-10">
            {tabs.map((tab) => (
              <div
                key={tab.key}
                className={
                  activeTab === tab.key
                    ? "text-[#07593D] text-sm md:border-b-4 rounded-l-sm rounded-r-sm font-bold border-[#07593D] md:mb-[-18px]"
                    : "text-gray500 font-normal text-sm"
                }
                onClick={() => handleTabClick(tab.key)}
              >
                {tab.label}
              </div>
            ))}
          </div>
          {(activeTab === "withoutRecourse" ||
            activeTab === "overview" ||
            activeTab === "withRecourse") && (
            <div>
              {buyerReimbursement && (
                <div>
                  <div>
                    <span>Payment Status</span>
                  </div>
                  <div
                    style={{
                      border: `1px solid ${
                        selectedStatus === "Liened"
                          ? "#06593D"
                          : selectedStatus === "Upcoming"
                          ? "#F08000"
                          : selectedStatus === "Overdue"
                          ? "red"
                          : "grey"
                      }`,
                      padding: "2px",
                      borderRadius: "5px",
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    {["Liened", "Upcoming", "Overdue"].map((status) => (
                      <span
                        key={status}
                        style={{
                          padding: "5px 10px",
                          borderRadius: "5px",
                          cursor: "pointer",
                          ...getStatusStyle(
                            selectedStatus === status ? status : null
                          ),
                        }}
                        onClick={() =>
                          setSelectedStatus(
                            selectedStatus === status ? "" : status
                          )
                        }
                      >
                        {status}
                      </span>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}

          {/* here */}
        </div>
      </div>
      {renderTabContent()}
    </div>
  );
};

HeaderTabs.defaultProps = {
  hasPaddingY: true,
};

export default HeaderTabs;
