import React, { useEffect, useState } from "react";

// import Details from "./Details";
import InvoiceDetails from "./InvoiceDetails";
import LoadingScreen from "shared/LoadingScreen";
import { getFunderCollectionEarlyPaymentOffer } from "appstate/api/invoice/invoice";
import { useParams } from "react-router-dom";
import { formatCapitaliseString } from "utilities/helper/formatCapitaliseString";

const InvoiceDetailsPage = () => {
  const { id, pfpId } = useParams();
  const [offerDetails, setOfferDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchOfferDetails = async () => {
      try {
        setIsLoading(true);
        // Pass both id and pfpId to the API call
        const response = await getFunderCollectionEarlyPaymentOffer({ offerId: id, pfpId });
        setOfferDetails(response);
      } catch (error) {
        console.error("Error fetching offer details:", error);
      } finally {
        setIsLoading(false);
      }
    };

    // Only fetch if both parameters are available
    if (id && pfpId) {
      fetchOfferDetails();
    }
  }, [id, pfpId]);

  if (isLoading) {
    return <LoadingScreen />;
  } 

  // Calculate the payment based on the due date and created date
  const calculatePayment = (dueDate, createdDate) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const dueDateObj = new Date(dueDate);
    const createdDateObj = new Date(createdDate?.split(" ")[0]);
    const diffDays = Math.round(
      Math.abs((dueDateObj - createdDateObj) / oneDay)
    );
    return `${diffDays} Days`;
  };  
  
  // Sample data for invoice details
  const data = {
    id: id,
    pfpId: pfpId, // Add pfpId to the data object
    invoiceNumber: `INV-${offerDetails?.data?.invoiceNo}` || "",
    referenceNumber: offerDetails?.data?.invoiceReference || "",
    createdDate: offerDetails?.data?.createdDate?.slice(0, 10) || "",
    dueDate: offerDetails?.data?.dueDate || "",
    payment: calculatePayment(
      offerDetails?.data?.dueDate,
      offerDetails?.data?.createdDate
    ),
    currency: offerDetails?.data?.currency || "",
    supplier: offerDetails?.data?.supplierCompanyName || "",
    buyer: offerDetails?.data?.buyerCompanyName || "",
    endDate: offerDetails?.data?.endDate || "",
    campaignName: offerDetails?.data?.programName || "",
    discountRate: offerDetails?.data?.discountRate || "",
    paymentDate: offerDetails?.data?.paymentDate || "",
    paymentDuration: offerDetails?.data?.paymentDuration || "",
    receivableAmount: offerDetails?.data?.receivableAmount?.toLocaleString() || "",
    paymentTerms: offerDetails?.data?.paymentTerms,
    buyerCompanyName: offerDetails?.data?.buyerCompanyName || "",
    accountNumber: offerDetails?.data?.virtualAccount?.accountNumber || "",
    accountName: offerDetails?.data?.virtualAccount?.accountName || "",
    vat: offerDetails?.data?.vat?.toLocaleString(),
    discount: offerDetails?.data?.discount?.toLocaleString(),
    vatAmount: offerDetails?.data?.vatAmount?.toLocaleString() || "",
    discountAmount: offerDetails?.data?.discountAmount?.toLocaleString() || "",
    issuanceDate: offerDetails?.data?.issuanceDate || "",
    settlementDate: offerDetails?.data?.settlementDate || "",
    submittedDate: offerDetails?.data?.submittedDate || "",
    approvedDate: offerDetails?.data?.approvedDate || "",
    grandTotal: offerDetails?.data?.grandTotal?.toLocaleString() || "",
    subTotal: offerDetails?.data?.subTotal?.toLocaleString() || "",
    status: formatCapitaliseString(offerDetails?.data?.status || ""),
    numberOfLineItems: offerDetails?.data?.lineItems?.length || 0,
    funderShare: offerDetails?.data?.funderShare?.toLocaleString() || "",
    fundableAmount: offerDetails?.data?.fundableAmount?.toLocaleString() || "",
    offerAmount: offerDetails?.data?.offerAmount?.toLocaleString() || "",
    lineItems:
      offerDetails?.data?.lineItems?.map((item) => ({
        // itemNumber: item?.itemNumber || "",
        item: item?.itemName || "",
        description: item.description || "",
        taxRate: item.taxRate || "",
        totalPrice: item.totalPrice?.toLocaleString() || "",
        taxAmount: item.taxAmount?.toLocaleString() || "",
        quantity: item.quantity || 0,
        unitPrice: item.unitPrice?.toLocaleString() || "",
        amount: item.amount?.toLocaleString() || "",
      })) || [],
  };

  return <InvoiceDetails data={data} />;
};

export default InvoiceDetailsPage;