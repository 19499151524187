import React, { useEffect, useState } from "react";
import Table from "layout/table/Table";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { allTransactions } from "appstate/collections/collectionSlice";
import Pagination from "shared/Pagination";
import { PAIconOutlineSearch, PAIconFilter } from "assets/images/svgs";
import Loading from "shared/Loading";

const TransactionHistory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { transactionData, isLoading } = useSelector((state) => state?.collection);

  // States
  const [page, setPage] = useState(1);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [fundingChannel, setFundingChannel] = useState("");
  const pageSize = 10;

  const transactionDetails = (id) => {
    let path = `/collections/transactionhistory-funder/${id}`;
    navigate(path);
  };

  // Fetch data with filters
  useEffect(() => {
    const params = {
      page,
      pageSize,
      search: searchQuery || "",
      fundingChannel: fundingChannel || ""
    };
    
    dispatch(allTransactions(params));
  }, [dispatch, page, pageSize, searchQuery, fundingChannel]);

  const handleSearch = () => {
    setPage(1);
    setStartPage(0);
    setEndPage(10);
  };

  const handleFundingChannelChange = (e) => {
    setFundingChannel(e.target.value);
    setPage(1);
    setStartPage(0);
    setEndPage(10);
  };

  const columns = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Sender
        </span>
      ),
      selector: (row) => row.sender,
      sortable: true,
      cell: (row) => (
        <p className="flex gap-1">
          {row.sender}
          <span
            className={`${
              row.role === "Supplier" ? "text-[#f08000]" : "text-primaryColor"
            }`}
          >{`(${row.role})`}</span>
        </p>
      ),
    },
    {
      name: "Payment Date",
      selector: (row) => row.paymentDate?.slice(0, 10),
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Reference Number
        </span>
      ),
      selector: (row) => row.requestRefNumber,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
      width: "6.2rem",
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      cell: (row) => <p className="">{row.amount?.toLocaleString()}</p>,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) =>
        row.status === "SUCCESSFUL" ? (
          <p className="text-[#07593D]">{row.status}</p>
        ) : row.status === "REJECTED" ? (
          <p className="text-red">{row.status}</p>
        ) : row.status === "UPDATED" ? (
          <p className="text-blue-300">{row.status}</p>
        ) : row.status === "PARTIALLY_PAID" ? (
          <p className="text-yellow-400">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        ),
    },
    {
      name: "Funding Channel",
      selector: (row) => row.fundingChannel,
      sortable: true,
    },
  ];

  // Render the search and filter header
  const renderHeader = () => (
    <>
      <div className="flex justify-between items-center px-[24px] bg-alabasterHeader rounded-t-[10px] py-3 flex-wrap border-b border-[#aeaeae]/50">
        <div>
          <p className="text-black text-lg font-bold">
            Overview of Cash Inflow Records
          </p>
          <span className="text-xs font-thin">
            Here is a recent list of your inflow transactions
          </span>
        </div>
        <div className="flex md:col-span-1 justify-end">
          <div className="flex items-center justify-between gap-5">
            <div className="border border-transparent md:border-gray md:shadow-sm my-4 rounded-[5px] px-2 lg:w-[300px] bg-white">
              <div className="items-center flex bg-white">
                <PAIconOutlineSearch />
                <input
                  className="bg-transparent p-2 w-full focus:outline-none text-sm bg-white"
                  type="text"
                  placeholder="Search for transaction"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleSearch();
                    }
                  }}
                />
              </div>
              <div className="lg:hidden flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer">
                <PAIconFilter className="bg-[#2FA06A]" />
              </div>
            </div>
            <button
              className="hidden lg:flex bg-[#2FA06A] rounded-md my-4 py-2 px-6 mx3 items-center gap-2 text-white"
              onClick={handleSearch}
            >
              Search
            </button>
          </div>
        </div>
      </div>

      <div className="flex px-6 bg-alabasterHeader py-4">
        <div>
          <select
            className="shadow-sm text-sm focus:outline-none cursor-pointer h-[37px] px-5 py-2.5 rounded-[5px] border border-[#949494] justify-start items-end gap-2 inline-flex md:min-w-[12.5rem] font-bold text-[#07593d]"
            value={fundingChannel}
            onChange={handleFundingChannelChange}
          >
            <option value="">All Funding Channel</option>
            <option value="DIRECT_FUNDING">Direct Funding</option>
            <option value="OPEN_MARKET">Open Market</option>
            <option value="CLOSED_MARKET">Closed Market</option>
            <option value="PFP_CAMPAIGN">PFP Campaign</option>
          </select>
        </div>
      </div>
    </>
  );

  return (
    <>
      <Card className="rounded-[10px] mt-[24px]">
        {renderHeader()}
        
        <div className="px-[24px] pb-[24px]">
          {(!transactionData?.data || transactionData?.data?.length === 0) && !isLoading ? (
            <TableEmptyState dashboard />
          ) : (
            <>
              <Table
                columns={columns}
                data={transactionData?.data || []}
                onRowClicked={(row) => transactionDetails(row.invoiceId)}
                progressPending={isLoading}
                progressComponent={<Loading />}
                pointer
                tableHeader
              />
              <div className="flex justify-center gap-x-[8px] mt-[20px]">
                {transactionData?.meta?.totalElements > pageSize && (
                  <Pagination
                    page={page}
                    startPage={startPage}
                    setEndPage={setEndPage}
                    endPage={endPage}
                    setStartPage={setStartPage}
                    setPage={setPage}
                    totalPages={transactionData?.meta?.totalElements}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </Card>
    </>
  );
};

export default TransactionHistory;