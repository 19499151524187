import React, { useEffect, useState, useRef } from "react";
import {
  PAIconDownload,
  PAIconFilter,
  PAIconOutlineSearch,
  PAIconOption,
} from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";
import Table from "layout/table/Table";
import AcceptModal from "shared/AcceptModal";
import SuccessModal from "shared/SuccessModal";
import { useDispatch, useSelector } from "react-redux";

import Pagination from "shared/Pagination";
import Loading from "shared/Loading";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  allFunderDirectFunding,
  allFunderEPRDirectFunding,
} from "appstate/collections/collectionSlice";

const DirectFunding = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState({});
  const [singleSelectedRows, setSingleSelectedRows] = useState([]);
  const [bulkSelectedRows, setBulkSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [activeTab, setActiveTab] = useState("single");
  const [fundingType, setFundingType] = useState("earlyPayment");

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const dropdownRef = useRef(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const pageSize = 10;

  const {
    allFunderEPRDirectFundingData,
    allFunderDirectFundingData,
    isLoading,
  } = useSelector((state) => state?.collection);

  // Useefeect for API call
  useEffect(() => {
    if (fundingType === "earlyPayment") {
      dispatch(
        allFunderEPRDirectFunding({
          page,
          pageSize,
          bulk: activeTab === "bulk" ? "true" : "false",
          startDate: formatDateForAPI(startDate),
          endDate: formatDateForAPI(endDate),
          search: searchQuery,
        })
      );
    } else {
      dispatch(
        allFunderDirectFunding({
          page,
          pageSize,
          bulk: activeTab === "bulk" ? "true" : "false",
          startDate: formatDateForAPI(startDate),
          endDate: formatDateForAPI(endDate),
          search: searchQuery,
        })
      );
    }
  }, [
    dispatch,
    page,
    pageSize,
    searchQuery,
    activeTab,
    startDate,
    endDate,
    fundingType,
  ]);

  // handleSearch function
  const handleSearch = () => {
    if (fundingType === "earlyPayment") {
      dispatch(
        allFunderEPRDirectFunding({
          search: searchQuery,
          bulk: activeTab === "bulk" ? "true" : "false",
          startDate: formatDateForAPI(startDate),
          endDate: formatDateForAPI(endDate),
        })
      );
    } else {
      dispatch(
        allFunderDirectFunding({
          search: searchQuery,
          bulk: activeTab === "bulk" ? "true" : "false",
          startDate: formatDateForAPI(startDate),
          endDate: formatDateForAPI(endDate),
        })
      );
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen({});
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // function to format dates properly
  const formatDateForAPI = (date) => {
    if (!date) return "";

    // Format as YYYY-MM-DD
    return date.toISOString().split("T")[0];
  };

  const getTableData = () => {
    if (fundingType === "earlyPayment") {
      return allFunderEPRDirectFundingData?.content || [];
    } else {
      return allFunderDirectFundingData?.content || [];
    }
  };

  const formatAmount = (amount) => {
    if (!amount || typeof amount !== "string") return "0.00";
    const parsedAmount = parseFloat(amount);
    if (isNaN(parsedAmount)) return "0.00";
    return parsedAmount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const handleNotificationClick = (row) => {
    // Determine the selected rows state based on the active tab
    const isSingleTab = activeTab === "single";
    const selectedRowList = isSingleTab ? singleSelectedRows : bulkSelectedRows;

    const isSelected = selectedRowList.includes(row.id);
    let newSelectedRows = [];

    if (!isSelected) {
      newSelectedRows = [...selectedRowList, row.id];
    }

    // Update the appropriate state based on the active tab
    if (isSingleTab) {
      setSingleSelectedRows(newSelectedRows);
    } else {
      setBulkSelectedRows(newSelectedRows);
    }

    setIsAcceptModalOpen(true);
  };

  const handleConfirm = () => {
    setIsAcceptModalOpen(false);

    const selectedInvoiceIds =
      activeTab === "single" ? singleSelectedRows : bulkSelectedRows;

    if (selectedInvoiceIds.length > 0) {
      setIsSuccessModalOpen(true);
      setSuccessMessage("Payment remainders sent successfully");
    }
  };

  const handleCloseSuccessModal = () => {
    setIsSuccessModalOpen(false);
  };

  const singleDetails = (id) => {
    let path = `/collections/funcollectiondirectfundinginvoice/${id}`;
    navigate(path);
  };

  const bulkDetails = (id) => {
    let path = `/collections/funderbulk-directfunding/${id}`;
    navigate(path);
  };

  const handleSendReminder = () => {
    const rowsToProcess =
      activeTab === "single" ? singleSelectedRows : bulkSelectedRows;
    if (rowsToProcess.length > 0) {
      setIsAcceptModalOpen(true);
    }
  };

  const handleSelectAll = () => {
    const dataToUse = getTableData();

    if (activeTab === "single") {
      if (selectAll) {
        setSingleSelectedRows([]);
      } else {
        setSingleSelectedRows(dataToUse.map((row) => row.id));
      }
    } else if (activeTab === "bulk") {
      if (selectAll) {
        setBulkSelectedRows([]);
      } else {
        setBulkSelectedRows(dataToUse.map((row) => row.id));
      }
    }
    setSelectAll(!selectAll);
  };

  const handleCheckboxChange = (row) => {
    if (activeTab === "single") {
      const isSelected = singleSelectedRows.includes(row.id);
      setSingleSelectedRows(
        isSelected
          ? singleSelectedRows.filter((id) => id !== row.id)
          : [...singleSelectedRows, row.id]
      );
    } else if (activeTab === "bulk") {
      const isSelected = bulkSelectedRows.includes(row.id);
      setBulkSelectedRows(
        isSelected
          ? bulkSelectedRows.filter((id) => id !== row.id)
          : [...bulkSelectedRows, row.id]
      );
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    // Reset selections when changing tabs
    setSingleSelectedRows([]);
    setBulkSelectedRows([]);
    setSelectAll(false);
  };

  // const handleTabClick = (tab) => {
  //   setActiveTab(tab);
  //   // Reset selections when changing tabs
  //   setSingleSelectedRows([]);
  //   setBulkSelectedRows([]);
  //   setSelectAll(false);

  //   // Immediately update with the new bulk value
  //   if (fundingType === "earlyPayment") {
  //     dispatch(
  //       allFunderEPRDirectFunding({
  //         page,
  //         pageSize,
  //         bulk: tab === "bulk" ? "true" : "false", // Explicitly set true/false
  //         startDate: formatDateForAPI(startDate),
  //         endDate: formatDateForAPI(endDate),
  //         search: searchQuery,
  //       })
  //     );
  //   } else {
  //     dispatch(
  //       allFunderDirectFunding({
  //         page,
  //         pageSize,
  //         bulk: tab === "bulk" ? "true" : "false", // Explicitly set true/false
  //         startDate: formatDateForAPI(startDate),
  //         endDate: formatDateForAPI(endDate),
  //         search: searchQuery,
  //       })
  //     );
  //   }
  // };

  const handleFundingTypeChange = (e) => {
    setFundingType(e.target.value);
    // Reset selections when changing funding type
    setSingleSelectedRows([]);
    setBulkSelectedRows([]);
    setSelectAll(false);
  };

  const columns = [
    {
      name: (
        <input type="checkbox" onChange={handleSelectAll} checked={selectAll} />
      ),
      cell: (row) => (
        <input
          type="checkbox"
          onChange={() => handleCheckboxChange(row)}
          checked={
            activeTab === "single"
              ? singleSelectedRows.includes(row.id)
              : bulkSelectedRows.includes(row.id)
          }
        />
      ),
      width: "50px",
    },
    {
      name: (
        <span className="whitespace-normal">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Request ref Number
        </span>
      ),
      selector: (row) => {
        const invoiceNumber = row.invoiceNumber;
        return invoiceNumber && !isNaN(invoiceNumber)
          ? `INV-${invoiceNumber}`
          : "INV-0";
      },
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-normal">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Buyer
        </span>
      ),
      selector: (row) => row.company || row.buyer?.companyName,
      sortable: true,
    },
    {
      name: "Early Payment date",
      selector: (row) => row.recourseOption || row.earlyPaymentDate,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.paymentDate || row.currency,
      sortable: true,
    },
    {
      name: "Request Amount",
      selector: (row) => row.totalAmount?.toLocaleString() || row.requestAmount?.toLocaleString(),
      sortable: true,
      cell: (row) => (
        <p className="">
          {formatAmount(row.invoiceAmount?.toLocaleString() || row.requestAmount?.toLocaleString())}
        </p>
      ),
    },
    {
      name: "Discount Rate",
      selector: (row) => row.discountRate,
      sortable: true,
    },
    {
      name: "Discount Amount",
      selector: (row) => row.discountAmount?.toLocaleString(),
      sortable: true,
    },
    {
      name: "Fundable Amount",
      selector: (row) => row.receivableAmount?.toLocaleString() || row.fundableAmount?.toLocaleString(),
      sortable: true,
      cell: (row) => (
        <p className="">
          {(row.discountAmount?.toLocaleString() || row.fundableAmount?.toLocaleString())}
        </p>
      ),
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <p
          className={
            row.status === "Successful" ? "text-green-400" : "text-[#F08000]"
          }
        >
          {row.status}
        </p>
      ),
    },
    {
      cell: (row) => (
        <div className="flex gap-2">
          {
            <PAIconOption
              title="Options"
              onClick={() => {
                setIsDropdownOpen((prev) => ({
                  ...prev,
                  [row.id]: !prev[row.id],
                }));
                // Close other dropdowns
                Object.keys(isDropdownOpen).forEach((key) => {
                  if (key !== row.id.toString()) {
                    setIsDropdownOpen((prev) => ({ ...prev, [key]: false }));
                  }
                });
              }}
            />
          }
          {isDropdownOpen[row.id] && (
            <div
              ref={dropdownRef}
              className="absolute bg-white border border-gray300 rounded-md shadow-md z-10"
            >
              <ul>
                <li
                  className="px-4 py-2 cursor-pointer hover:bg-primaryColor hover:text-white"
                  onClick={() => {
                    if (activeTab === "single") {
                      singleDetails(row?.id);
                    } else if (activeTab === "bulk") {
                      bulkDetails(row?.id);
                    }
                    setIsDropdownOpen((prev) => ({ ...prev, [row.id]: false }));
                  }}
                >
                  Preview
                </li>
                <li
                  className="px-4 py-2 cursor-pointer hover:bg-primaryColor hover:text-white"
                  onClick={() => {
                    handleNotificationClick(row);
                    setIsDropdownOpen((prev) => ({ ...prev, [row.id]: false }));
                  }}
                >
                  Send Reminder
                </li>
                <li className="px-4 py-2 cursor-not-allowed text-gray300">
                  Export
                </li>
              </ul>
            </div>
          )}
        </div>
      ),
    },
  ];

  const columnBulks = [
    {
      name: (
        <input type="checkbox" onChange={handleSelectAll} checked={selectAll} />
      ),
      cell: (row) => (
        <input
          type="checkbox"
          onChange={() => handleCheckboxChange(row)}
          checked={
            activeTab === "single"
              ? singleSelectedRows.includes(row.id)
              : bulkSelectedRows.includes(row.id)
          }
        />
      ),
      width: "50px",
    },
    {
      name: (
        <span className="whitespace-normal">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Request ref Number
        </span>
      ),
      selector: (row) => {
        const invoiceNumber = row.invoiceNumber || row.batchNumber;
        return invoiceNumber && !isNaN(invoiceNumber)
          ? `INV-${invoiceNumber}`
          : row.batchNumber || "INV-0";
      },
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-normal">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Buyer
        </span>
      ),
      selector: (row) => row.company || row.buyer?.companyName,
      sortable: true,
    },
    {
      name: "Payment date",
      selector: (row) => row.recourseOption || row.paymentDate,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.paymentDate || row.currency,
      sortable: true,
    },
    {
      name: "Total Request Amount",
      selector: (row) => row.totalAmount || row.totalRequestAmount,
      sortable: true,
      cell: (row) => (
        <p className="">
          {formatAmount(row.totalAmount || row.totalRequestAmount)}
        </p>
      ),
    },
    {
      name: "Total Discount Rate",
      selector: (row) => row.discountRate,
      sortable: true,
    },
    {
      name: "Total Fundable Amount",
      selector: (row) => row.totalFundableAmount || row.totalReceivableAmount,
      sortable: true,
      cell: (row) => (
        <p className="">
          {formatAmount(row.totalFundableAmount || row.totalReceivableAmount)}
        </p>
      ),
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <p
          className={
            row.status === "Successful" ? "text-green-400" : "text-[#F08000]"
          }
        >
          {row.status}
        </p>
      ),
    },
    {
      cell: (row) => (
        <div className="flex gap-2">
          {
            <PAIconOption
              title="Options"
              onClick={() => {
                setIsDropdownOpen((prev) => ({
                  ...prev,
                  [row.id]: !prev[row.id],
                }));
                // Close other dropdowns
                Object.keys(isDropdownOpen).forEach((key) => {
                  if (key !== row.id.toString()) {
                    setIsDropdownOpen((prev) => ({ ...prev, [key]: false }));
                  }
                });
              }}
            />
          }
          {isDropdownOpen[row.id] && (
            <div
              ref={dropdownRef}
              className="absolute bg-white border border-gray300 rounded-md shadow-md z-10"
            >
              <ul>
                <li
                  className="px-4 py-2 cursor-pointer hover:bg-primaryColor hover:text-white"
                  onClick={() => {
                    if (activeTab === "single") {
                      singleDetails(row?.id);
                    } else if (activeTab === "bulk") {
                      bulkDetails(row?.id);
                    }
                    setIsDropdownOpen((prev) => ({ ...prev, [row.id]: false }));
                  }}
                >
                  Preview
                </li>
                <li
                  className="px-4 py-2 cursor-pointer hover:bg-primaryColor hover:text-white"
                  onClick={() => {
                    handleNotificationClick(row);
                    setIsDropdownOpen((prev) => ({ ...prev, [row.id]: false }));
                  }}
                >
                  Send Reminder
                </li>
                <li className="px-4 py-2 cursor-not-allowed text-gray300">
                  Export
                </li>
              </ul>
            </div>
          )}
        </div>
      ),
    },
  ];

  const renderTabContent = () => {
    const tableData = getTableData();
    const tableColumns = activeTab === "single" ? columns : columnBulks;

    return (
      <>
        <Table
          columns={tableColumns}
          data={tableData}
          onRowClicked={(row) =>
            activeTab === "single" ? singleDetails(row.id) : bulkDetails(row.id)
          }
          progressPending={isLoading}
          progressComponent={<Loading />}
          pointer
          tableHeader
          className="mt-5"
        />
        <div className="flex justify-center gap-x-[8px] mt-[20px]">
          {tableData?.length > 10 && (
            <Pagination
              page={page}
              startPage={startPage}
              setEndPage={setEndPage}
              endPage={endPage}
              setStartPage={setStartPage}
              setPage={setPage}
              totalPages={
                fundingType === "earlyPayment"
                  ? allFunderEPRDirectFundingData?.meta?.totalElements
                  : allFunderDirectFundingData?.meta?.totalElements
              }
            />
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <div className="my-2 grid md:grid-cols-2 items-center">
        <div className="gap-3 flex relative">
          <div className="flex gap-4 mb-4">
            <div className="relative">
              <DatePicker
                selected={startDate}
                onChange={(update) => setDateRange(update)}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                placeholderText="Filters"
                className="border  border-[#ccc] px-4 py-3 cursor-pointer rounded-md w-60"
              />

              <div className="absolute top-2/4 right-3 transform -translate-y-2/4 pointer-events-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5 text-gray-400"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div>
            <button className="py-2 px-4 text-sm rounded-[5px] bg-white border-[#DEDEDE] shadow-sm mr-2 flex items-center justify-center gap-1.5">
              Export {<PAIconDownload className="accent-gray300 w-4" />}
            </button>
          </div>
        </div>

        <div className="flex md:col-span-1 justify-end">
          <div className="flex items-center justify-between gap-5">
            <div className="border border-transparent md:border-gray md:shadow-sm my-4 rounded-[5px] px-2 lg:w-[300px] bg-white">
              <div className="items-center flex bg-white">
                <PAIconOutlineSearch />
                <input
                  className="bg-transparent p-2 w-full focus:outline-none text-sm bg-white"
                  type="text"
                  placeholder="Search for invoices awaiting collection"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleSearch();
                    }
                  }}
                />
              </div>
              <div className="lg:hidden flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer">
                <PAIconFilter className="bg-[#2FA06A]" />
              </div>
            </div>
            <button
              className="hidden lg:flex bg-[#2FA06A] rounded-md my-4 py-2 px-6 mx3 items-center gap-2 text-white"
              onClick={handleSearch}
            >
              Search
            </button>
          </div>
        </div>
      </div>

      <div>
        {getTableData()?.length === 0 && !isLoading ? (
          <div>
            <div className="flex justify-between items-center px-[15px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
              <div>
                <p className="text-black text-lg font-bold">
                  Invoices from{" "}
                  {fundingType === "earlyPayment"
                    ? "Early Payment"
                    : "Direct Funding"}{" "}
                  from buyers awaiting collection
                </p>
                <span className="text-xs font-thin">
                  List of all invoices you have funded from{" "}
                  {fundingType === "earlyPayment"
                    ? "Early payment"
                    : "Direct Funding"}{" "}
                  channel
                </span>
              </div>
            </div>

            {/* Added the tabs and filter UI */}
            <div className="flex justify-between items-center px-6 bg-white">
              <div className="flex gap-3 my-3">
                <p
                  className={`cursor-pointer font-bold relative ${
                    activeTab === "single" ? "text-[#F08000]" : "text-[#959595]"
                  }`}
                  onClick={() => handleTabClick("single")}
                >
                  Single requests
                  {activeTab === "single" && (
                    <span
                      className="absolute bottom-0 left-1/2 transform -translate-x-1/2
            bg-[#F08000] h-0.5 w-5"
                    ></span>
                  )}
                </p>

                <p
                  className={`cursor-pointer font-bold relative ${
                    activeTab === "bulk" ? "text-[#F08000]" : "text-[#959595]"
                  }`}
                  onClick={() => handleTabClick("bulk")}
                >
                  Bulk requests
                  {activeTab === "bulk" && (
                    <span
                      className="absolute bottom-0 left-1/2 transform -translate-x-1/2
            bg-[#F08000] h-0.5 w-5"
                    ></span>
                  )}
                </p>
              </div>
              <div className="flex flex-row gap-2 justify-between items-center">
                <div className="text-center text-black text-sm font-normal">
                  Awaiting collection from
                </div>

                <div>
                  <select
                    className="shadow-sm text-sm focus:outline-none cursor-pointer h-[37px] px-5 py-2.5 rounded-[5px] border border-[#949494] justify-start items-end gap-2 inline-flex md:min-w-[12.5rem] font-bold"
                    value={fundingType}
                    onChange={handleFundingTypeChange}
                  >
                    <option value="earlyPayment">Early Payment</option>
                    <option value="directFunding">Direct Funding</option>
                  </select>
                </div>

                <div className="shadow-sm text-sm focus:outline-none cursor-pointer h-[37px] px-5 py-2.5 rounded-[5px] border border-[#949494] justify-start items-end gap-2 inline-flex font-bold">
                  Buyer
                </div>
              </div>
            </div>

            <TableEmptyState dashboard />
          </div>
        ) : (
          <div>
            <Card className="rounded-[10px] mt-[15px]">
              <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
                <div>
                  <p className="text-black text-lg font-bold">
                    Invoices from{" "}
                    {fundingType === "earlyPayment"
                      ? "Early Payment"
                      : "Direct Funding"}{" "}
                    from buyers awaiting collection
                  </p>
                  <span className="text-xs font-thin">
                    List of all invoices you have funded from{" "}
                    {fundingType === "earlyPayment"
                      ? "Early payment"
                      : "Direct Funding"}{" "}
                    channel
                  </span>
                </div>
                <div className="flex items-center justify-center gap-3">
                  <button
                    className={`border border-gray300 rounded-md p-2 text-white ${
                      (activeTab === "single" &&
                        singleSelectedRows.length === 0) ||
                      (activeTab === "bulk" && bulkSelectedRows.length === 0)
                        ? "cursor-not-allowed bg-gray300"
                        : "cursor-pointer bg-secondaryColor"
                    }`}
                    disabled={
                      (activeTab === "single" &&
                        singleSelectedRows.length === 0) ||
                      (activeTab === "bulk" && bulkSelectedRows.length === 0)
                    }
                    onClick={handleSendReminder}
                  >
                    <p>Send Reminder Now</p>
                  </button>
                </div>
              </div>
              <div className="pb-[24px]">
                <div className="flex justify-between items-center px-6">
                  <div className="flex gap-3 my-3">
                    <p
                      className={`cursor-pointer font-bold relative ${
                        activeTab === "single"
                          ? "text-[#F08000]"
                          : "text-[#959595]"
                      }`}
                      onClick={() => handleTabClick("single")}
                    >
                      Single requests
                      {activeTab === "single" && (
                        <span
                          className="absolute bottom-0 left-1/2 transform -translate-x-1/2
                        bg-[#F08000] h-0.5 w-5"
                        ></span>
                      )}
                    </p>

                    <p
                      className={`cursor-pointer font-bold relative ${
                        activeTab === "bulk"
                          ? "text-[#F08000]"
                          : "text-[#959595]"
                      }`}
                      onClick={() => handleTabClick("bulk")}
                    >
                      Bulk requests
                      {activeTab === "bulk" && (
                        <span
                          className="absolute bottom-0 left-1/2 transform -translate-x-1/2
                        bg-[#F08000] h-0.5 w-5"
                        ></span>
                      )}
                    </p>
                  </div>
                  <div className="flex flex-row gap-2 justify-between items-center">
                    <div className="text-center text-black text-sm font-normal">
                      Awaiting collection from
                    </div>

                    <div>
                      <select
                        className="shadow-sm text-sm focus:outline-none cursor-pointer h-[37px] px-5 py-2.5 rounded-[5px] border border-[#949494] justify-start items-end gap-2 inline-flex md:min-w-[12.5rem] font-bold"
                        value={fundingType}
                        onChange={handleFundingTypeChange}
                      >
                        <option value="earlyPayment">Early Payment</option>
                        <option value="directFunding">Direct Funding</option>
                      </select>
                    </div>

                    <div className="shadow-sm text-sm focus:outline-none cursor-pointer h-[37px] px-5 py-2.5 rounded-[5px] border border-[#949494] justify-start items-end gap-2 inline-flex font-bold">
                      Buyer
                    </div>
                  </div>
                </div>

                {/* Show table or empty state inside the same card */}
                <div className="mt-3">
                  {getTableData().length === 0 && !isLoading ? (
                    <TableEmptyState dashboard />
                  ) : (
                    renderTabContent()
                  )}
                </div>
              </div>
            </Card>
          </div>
        )}
      </div>

      <AcceptModal
        isOpen={isAcceptModalOpen}
        onClose={() => setIsAcceptModalOpen(false)}
        yes
        message={
          <>
            Would you like to proceed with sending a <br />
            <span className="text-[#07593D] font-bold">
              Payment reminder
            </span>{" "}
            now?
          </>
        }
        onConfirm={handleConfirm}
      />

      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={handleCloseSuccessModal}
        message={successMessage}
      />
    </>
  );
};

export default DirectFunding;
