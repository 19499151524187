import React, { useState } from "react";
import Headers from "../headers";
import SingleBulkOption from "./option";

const ProcessingEarlyPayments = ({ route, activeTab }) => {
  const [isClosedMarketType, setIsClosedMarketType] = useState(false);
  const [selectedType, setSelectedType] = useState(
    activeTab === "earlyPayments"
      ? "All Funding Options"
      : "Request from funder"
  );
  return (
    <div>
      <Headers
        setIsClosedMarketType={setIsClosedMarketType}
        activeTab={activeTab}
        setSelectedType={setSelectedType}
        selectedType={selectedType}
      />
      <SingleBulkOption
        route={route}
        isClosedMarketType={isClosedMarketType}
        activeTabType={selectedType}
      />
    </div>
  );
};

export default ProcessingEarlyPayments;
