import { PAIconInventoryArrowLeft } from "assets/images/svgs";
import React from "react";
import { useNavigate } from "react-router-dom";

const TableHeaderTrans = ({
  total,
  path,
  buyer,
  title,

  options,
  transactions,
  navigateTo,
}) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(path);
  };
  return (
    <div className="mb-[33px]    bg-[#FAFAFA] rounded-t-md">
      {buyer && (
        <div
          onClick={handleNavigate}
          className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
        >
          <PAIconInventoryArrowLeft />
          <p className="mt-[2px] text-sm font-normal text-lightGray">Go Back</p>
        </div>
      )}
      {transactions && (
        <div className="flex items-center justify-between pt-4  ">
          <div className="flex-col pl-[20px] ">
            <p className="font-bold">Recent Transactions</p>
            <p className="text-[13px] text-[#626679]">Transactions Summary</p>
          </div>
          <div className="mr-[20px] ">
            {/* ENDS HERE */}

            <button
              class="text-black text-sm font-medium underline"
              onClick={navigateTo}
            >
              View All
            </button>
          </div>
        </div>
      )}
      <div className="md:flex justify-between items-center  pb-[12px]">
        <p className="mb-[10px] text-black text-sm font-medium md:mb-0">
          {total}
        </p>
        {buyer && (
          <p className="text-sm font-medium mb-[10px] md:mb-0">{title}</p>
        )}
      </div>
    </div>
  );
};

export default TableHeaderTrans;
