import { PAIconPaymentGreen } from "assets/images/svgs";
import React, { useState } from "react";
import LinkExternalAccountFormModal from "./LinkExternalAccountFormModal";
import CongratsModal from "features/wallet/linkExternalAccount/congrats/congratsModal";
import PinModal from "shared/PinModal";
import { useDispatch, useSelector } from "react-redux";
import { postExternalAccount } from "appstate/wallet/walletSlice";
import toast from "react-hot-toast";

const LinkExternalModal = ({ onClose }) => {
  const { externalAccountFormData } = useSelector((state) => state.wallet);
  const { userDetails } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const [showFormModal, setShowFormModal] = useState(false);
  const [showLinkModal, setShowLinkModal] = useState(true);
  const [showPinModal, setShowPinModal] = useState(false);
  const [showCongratsModal, setShowCongratsModal] = useState(false);

  const handleShowModal = () => {
    setShowFormModal(true);
    setShowLinkModal(false);
  };

  const handleFormModalClose = () => {
    setShowFormModal(false);
    setShowLinkModal(true);
  };

  const handleLinkExternalAcountSubmit = (pin) => {
    const body = {
      bankName: externalAccountFormData?.bankName,
      bvn: externalAccountFormData?.bvn,
      transactionPin: pin,
      accountNumber: externalAccountFormData?.accountNumber,
      currency: externalAccountFormData?.currency,
    };

    const bankCode = externalAccountFormData?.bankCode;

    dispatch(postExternalAccount({ bankCode, body })).then((response) => {
      if (response?.payload?.success) {
        setShowPinModal(false);
        setShowCongratsModal(true);
      } else {
        return toast.error(response?.payload?.message);
      }
    });
  };

  const handlePinModalClose = () => {
    setShowPinModal(false);
  };

  const handleCongratsModalClose = () => {
    setShowCongratsModal(false);
    onClose();
  };

  const handleSubmit = () => {
    setShowFormModal(false);
    setShowPinModal(true);
  };

  return (
    <>
      {showLinkModal && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg rounded-md z-[1000]">
          <div className="bg-white p5 px5 rounded-md max-w-xs md:max-w-lg w-full pb-5">
            <div className="flex justify-between items-center mb-4 bg-[#FAFAFA]">
              <p className="text-black font-sans text-base font-semibold p-3 px-5 rounded-sm italic">
                Link External Account
              </p>
              <button
                onClick={onClose}
                className="text-black font-sans text-xl cursor-pointer flex justify-center items-center"
              >
                &times; <span className="text-sm mx-2 mt-1">Close</span>
              </button>
            </div>

            <div className="md:flex md:gap-5 flex-wrap max-h-44 overflow-auto flex items-center justify-center">
              <div
                className={`bg-white border border-rounded p-3 py-5 mt-3 cursor-pointer text-[#07593D] textxs md:w-[calc(70%-10px)] md:mb-0 mb rounded`}
              >
                <div className="text-white textxs flex items-center justify-between">
                  <div className="flex justify-center items-center">
                    <PAIconPaymentGreen className="mr-2" />{" "}
                    <p className="font-bold text-[#07593D] text-base">
                      {userDetails?.data?.accountHolder
                        ? userDetails?.data?.accountHolder
                            .split(" ")
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() +
                                word.slice(1).toLowerCase()
                            )
                            .join(" ")
                        : "No AccountName"}
                    </p>
                  </div>
                  <div>
                    <p className="text-[#07593D] text-xs">External Account</p>
                  </div>
                </div>
                <p className="mt2 font-bold my-5 text-base mt10">
                  {" "}
                  {userDetails?.data?.accountNumber}
                </p>
                <div className="flex items-center justify-between mt-2 text-xs">
                  <p> {userDetails?.data?.bankName}</p>
                </div>
              </div>
            </div>

            <div className="flex justify-end items-center mx-5 mt-10 mb-5 gap-5">
              <button
                onClick={handleShowModal}
                className="border border-[#DE1515] rounded p-1 px-6"
              >
                Change account
              </button>
              <button
                onClick={() => onClose()}
                className="bg-[#07593D] flex items-center justify-center p-2 text-white rounded-md text-sm px-5"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}

      {showFormModal && (
        <LinkExternalAccountFormModal
          isOpen={showFormModal}
          onClose={handleFormModalClose}
          onSubmit={handleSubmit}
        />
      )}
      {showPinModal && (
        <PinModal
          isOpen={showPinModal}
          onClose={handlePinModalClose}
          onSubmit={handleLinkExternalAcountSubmit}
          headerText="Linked External Account"
          instructionText="Please enter your PIN to approve the linking of this external account."
        />
      )}
      {showCongratsModal && (
        <CongratsModal
          isOpen={showCongratsModal}
          onClose={handleCongratsModalClose}
          message={`Your External Account (${externalAccountFormData.accountHolderName} - ${externalAccountFormData.accountNumber} - ${externalAccountFormData.bankName}) has been linked successfully`}
        />
      )}
    </>
  );
};

export default LinkExternalModal;
