import Modal from "layout/modal/Modal";
import React from "react";
import BulkRequestModalContent from "./BulkRequestModalContent";

const BulkRequestModal = ({
  isShown,
  onClose,
  data,
  directFunding,
  lengthOfInvoice,
  buyer,
  handleProceed,
  percentage,
  setPercentage,
  date,
  setDate,
  apply,
  setApply,
  calculateTotalInvoiceAmount,
  calculateDiscountTotalInvoiceAmount,
  totalRequestAmount,
  handleFetchDiscount,
  selectedDate,
  setIsActiveRecourse,
  earlyPaymentDiscountedResponseList,
  row,
  earlyPayment,
  funderId
}) => {
  return (
    <Modal size="medium" isShown={isShown} hide={onClose}>
      <BulkRequestModalContent
        lengthOfInvoice={lengthOfInvoice}
        data={data}
        onClose={onClose}
        buyer={buyer}
        directFunding={directFunding}
        handleProceed={handleProceed}
        percentage={percentage}
        setPercentage={ setPercentage}
        date={date}
        setDate={setDate}
        apply={apply}
        setApply={setApply}
        totalRequestAmount={totalRequestAmount}
        handleFetchDiscount={handleFetchDiscount}
        calculateTotalInvoiceAmount={calculateTotalInvoiceAmount}
        selectedDate={selectedDate}
        calculateDiscountTotalInvoiceAmount={calculateDiscountTotalInvoiceAmount}
        setIsActiveRecourse={setIsActiveRecourse}
        earlyPaymentDiscountedResponseList={earlyPaymentDiscountedResponseList}
        row={row}
        earlyPayment={earlyPayment}
        funderId={funderId}
      />
    </Modal>
  );
};

export default BulkRequestModal;
