import { useState } from "react";
import {
  PAIconFilter,
  PAIconOutlineSearch,
  PAIconDropdown,
  PAIExportableDownload,
} from "assets/images/svgs";
// import { useSelector } from "react-redux";
// import Table from "layout/table/Table";

import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";
import SingleRequest from "./Single";
import Bulk from "./Bulk";
import SinglePayable from "./SinglePayable";
import BulkPayable from "./BulkPayable";

const TransactionHistory = () => {
  const [selectedFilters] = useState([]);
  const [isDropdownOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("single");
  const [hisTab, setHisTab] = useState("request");
  const [payableTab, setPayableTab] = useState("fullyPayable");

  // const navigate = useNavigate();

  const bulkdumData = [
    {
      idx: "1",
      companyName: "XYZ Ventures",
      player: "Supplier",
      invoiceDate: "2023-05-23",
      invoiceReference: "INV-2090",
      currency: "NGN",
      amount: 9000000,
      status: "Successful",
      channel: "Early Payment Request",
    },
    {
      idx: "2",
      companyName: "Dangote LTD ",
      player: "Supplier",
      invoiceDate: "2023-05-23",
      invoiceReference: "INV-2090",
      currency: "NGN",
      amount: 9000000,
      status: "Successful",
      channel: "Loan Default",
    },
    {
      idx: "3",
      companyName: "Nestle PLC",
      player: "Supplier",
      invoiceDate: "2023-05-23",
      invoiceReference: "INV-2090",
      currency: "NGN",
      amount: 9000000,
      status: "Successful",
      channel: "Early Payment Offers ",
    },
  ];

  // const { transHistory } = useSelector((state) => state.buyer);


  const ReturnTab = () => {
    if (activeTab === "Bulk requests") {
      return <Bulk />;
    } else {
      return (
        <>
          <SingleRequest />;
        </>
      );
    }
  };

  const PayableReturnTab = () => {
    if (payableTab === "fullyPayable") {
      return <SinglePayable />;
    } else {
      return (
        <>
          <BulkPayable />;
        </>
      );
    }
  };

  return (
    <>
      <div>
        {" "}
        <div className="my-2 grid md:grid-cols-2 items-center">
          <div className="gap-3 flex relative">
            <div>
              <div className="flex items-center gap-[4px] border border-[#DEDEDE] bg-white shadow-sm rounded-[5px] px-4 py-2 text-sm focus:outline-none cursor-pointer">
                Filter{" "}
                {selectedFilters.length > 0 && (
                  <span className="bg-gray rounded-lg p-1 px-2">
                    {selectedFilters.length}
                  </span>
                )}{" "}
                Selected <PAIconDropdown />
              </div>
              {isDropdownOpen && (
                <div className="absolute top-full mt1 wfull border border-[#DEDEDE] shadow-lg rounded-b-[5px] px-4 py-3 bg-white">
                  <label className="block">
                    <input type="checkbox" value="date" />
                    Date
                  </label>
                  <label className="block">
                    <input type="checkbox" value="name" />
                    Name
                  </label>
                  <label className="block">
                    <input
                      type="checkbox"
                      value="currency"
                      checked={selectedFilters.includes("currency")}
                    />
                    Currency
                  </label>
                  <label className="block">
                    <input
                      type="checkbox"
                      value="amount"
                      checked={selectedFilters.includes("amount")}
                    />
                    Amount
                  </label>
                </div>
              )}
            </div>

            <button className="py-2 px-4 rounded-[5px] bg-white border-[#DEDEDE] shadow-sm mr-2  flex  gap-2">
              Export
              <span>{<PAIExportableDownload />}</span>
            </button>
          </div>
          <div className="flex md:col-span-1 justify-end">
            <div>
              <div className="flex items-center justify-between gap-5">
                <div className="border border-transparent md:border-gray md:shadow-sm my-4 rounded-[5px] px-2 lg:w-[300px] bg-white">
                  <div className="items-center flex bg-white">
                    <PAIconOutlineSearch />
                    <input
                      className="bg-transparent p-2 w-full focus:outline-none text-sm bg-white"
                      type="text"
                      placeholder="Search for due invoices awaiting collection"
                    />
                  </div>
                  <div className="lg:hidden flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer">
                    <PAIconFilter className="bg-[#2FA06A]" />
                  </div>
                </div>
                <button className="hidden lg:flex bg-[#2FA06A] rounded-md my-4 py-2 px-6 mx3 items-center gap-2 text-white">
                  <PAIconFilter /> Filter
                </button>
              </div>
            </div>

            {/* ends here */}
          </div>
        </div>
        <>
          {!bulkdumData.length ? (
            <div className="ml3">
              <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
                <p className="text-black text-sm font-medium">
                  Overview of CashInflow Records
                </p>
              </div>
              <TableEmptyState dashboard />
            </div>
          ) : (
            <Card className="rounded-[10px] mt-[24px] ">
              <div className="bg-alabasterHeader rounded-t-[10px] min-h-20 px-[24px]   ">
                <div className=" border-b-[0.8px] border-zinc-400 py-4 h-full ">
                  <div className="flex  items-center   w-full justify-between  ">
                    <div>
                      <p className="text-black text-lg font-semibold">
                        Recent disbursement history
                      </p>
                      <span className="text-xs font-thin text-[#959595]">
                        Here is a recent list of your disbursements.
                      </span>
                    </div>

                    <div className=" flex gap-2 ">
                      {/* first button */}
                      <button
                        className={`h-[37px] px-5 py-2.5 ${
                          hisTab === "request"
                            ? "bg-[#07593d] text-white"
                            : "bg-[#949494]/5 text-[#222222]"
                        } rounded-md justify-center items-center gap-2.5 inline-flex`}
                        onClick={() => setHisTab("request")}
                      >
                        <div
                          className={`text-center text-black text-sm font-normal  ${
                            hisTab === "request"
                              ? " text-white"
                              : " text-[#222222]"
                          }`}
                        >
                          Requests and offers -{" "}
                          {/* {transHistory?.singleTransactionHistory?.data
                            ?.length ||
                            transHistory?.bulkTransactionHistory?.data
                              ?.length}{" "} */}
                        </div>
                      </button>

                      {/* second button */}
                      <button
                        className={`h-[37px] px-5 py-2.5 rounded-md ${
                          hisTab === "payables"
                            ? "bg-[#07593d] text-white"
                            : "bg-[#949494]/5 text-[#222222]"
                        }rounded justify-center items-center gap-2.5 inline-flex font-normal text-center`}
                        onClick={() => setHisTab("payables")}
                      >
                        <div
                          className={`text-center text-black text-sm font-normal  ${
                            hisTab === "payables"
                              ? " text-white"
                              : " text-[#222222]"
                          }`}
                        >
                          Payables
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* top ends here */}

              <div className="flex  items-center px-[24px] bg-alabasterHeader  rounded-t-[10px]">
                {/* another */}
              </div>
              <div className="px-[24px] pb[24px]">
                {hisTab === "request" ? (
                  <>
                    {" "}
                    <div className="flex px-[24px] gap-5 my-5">
                      <p
                        className={`cursor-pointer text-sm font-bold relative ${
                          activeTab === "single"
                            ? "text-[#F08000]"
                            : "text-[#959595]"
                        }`}
                        onClick={() => setActiveTab("single")}
                      >
                        Single Request
                        {activeTab === "single" && (
                          <span className="absolute bottom-0 left-1/2 -translate-x-1/2 bg-[#F08000] h-0.5 w-5"></span>
                        )}
                      </p>

                      <p
                        className={`cursor-pointer text-sm font-bold relative ${
                          activeTab === "bulk"
                            ? "text-[#F08000]"
                            : "text-[#959595]"
                        }`}
                        onClick={() => setActiveTab("bulk")}
                      >
                        Bulk Request
                        {activeTab === "bulk" && (
                          <span className="absolute bottom-0 left-1/2 -translate-x-1/2 bg-[#F08000] h-0.5 w-5"></span>
                        )}
                      </p>
                    </div>
                    <ReturnTab />
                  </>
                ) : (
                  <div>
                    {" "}
                    <div className="flex px-[24px] gap-5 my-5">
                      <p
                        className={`cursor-pointer text-sm font-bold relative ${
                          payableTab === "fullyPayable"
                            ? "text-[#F08000]"
                            : "text-[#959595]"
                        }`}
                        onClick={() => setPayableTab("fullyPayable")}
                      >
                        Fully Payable
                        {payableTab === "fullyPayable" && (
                          <span className="absolute bottom-0 left-1/2 -translate-x-1/2 bg-[#F08000] h-0.5 w-5"></span>
                        )}
                      </p>

                      <p
                        className={`cursor-pointer text-sm font-bold relative ${
                          payableTab === "partiallyPayable"
                            ? "text-[#F08000]"
                            : "text-[#959595]"
                        }`}
                        onClick={() => setPayableTab("partiallyPayable")}
                      >
                        Partial Payable
                        {payableTab === "partiallyPayable" && (
                          <span className="absolute bottom-0 left-1/2 -translate-x-1/2 bg-[#F08000] h-0.5 w-5"></span>
                        )}
                      </p>
                    </div>
                    <PayableReturnTab />
                  </div>
                )}
              </div>
            </Card>
          )}
        </>
      </div>
    </>
  );
};

export default TransactionHistory;
