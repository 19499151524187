import React, { useEffect, useState } from "react";
import Table from "layout/table/Table";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";
import { useNavigate } from "react-router-dom";
import Pagination from "shared/Pagination";
import { useDispatch, useSelector } from "react-redux";
import {
  allEarlyPaymentRequestBulk,
  getInvoiceId,
} from "appstate/buyer/buyerSlice";
import Loading from "shared/Loading";

const BulkEarlyPayment = ({ activeTabType }) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const dispatch = useDispatch();
  // const pageSize = 10;
  const [selectAll, setSelectAll] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const status = "REJECTED";
  const { allEarlyPaymentRequestBulkData, isLoading } = useSelector(
    (state) => state?.buyer
  );
  const onRowClicked = (row) => {
    dispatch(getInvoiceId(row.earlyPaymentId));
    let path = "/payablefinancing/invoice/bulkearlypaymentdetails/rejected";
    navigate(path);
  };

  useEffect(() => {
    dispatch(allEarlyPaymentRequestBulk({ status }));
  }, [dispatch, status]);

  useEffect(() => {
    setData(
      allEarlyPaymentRequestBulkData?.data?.buyerBulkEarlyPaymentResponse
    );
  }, [allEarlyPaymentRequestBulkData]);

  const handleCheckboxChange = (row) => {
    const selectedIndex = selectedRows.indexOf(row.id);
    let newSelectedRows = [];

    if (selectedIndex === -1) {
      newSelectedRows = [...selectedRows, row.id];
    } else {
      newSelectedRows = selectedRows.filter((id) => id !== row.id);
    }
    setSelectedRows(newSelectedRows);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      const allIds = data.map((row) => row.id);
      setSelectedRows(allIds);
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    if (activeTabType === "Expired in closed market") {
      setData((prevData) =>
        prevData.filter(
          (item) => item.fundingChannel === "Expired in Closed Market"
        )
      );
    }
    if (activeTabType === "Rejected By Funder") {
      setData((prevData) =>
        prevData.filter((item) => item.fundingChannel === "Rejected By Funder")
      );
    }
    if (activeTabType === "Rejected Supplier request") {
      setData((prevData) =>
        prevData.filter(
          (item) => item.fundingChannel === "Rejected Supplier request"
        )
      );
    }

    if (activeTabType === "All Rejected Request") {
      setData(
        allEarlyPaymentRequestBulkData?.data?.buyerBulkEarlyPaymentResponse
      );
    }
  }, [
    activeTabType,
    allEarlyPaymentRequestBulkData?.data?.buyerBulkEarlyPaymentResponse,
  ]);

  const columns = [
    {
      name: (
        <input type="checkbox" onChange={handleSelectAll} checked={selectAll} />
      ),
      selector: "",
      cell: (row) => (
        <input
          type="checkbox"
          onChange={() => handleCheckboxChange(row)}
          checked={selectedRows.includes(row.id)}
        />
      ),
      width: "50px",
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Request Ref No
        </span>
      ),
      selector: (row) => row.requestReferenceNumber,
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Supplier
        </span>
      ),
      selector: (row) => row.supplierCompanyName,
      sortable: true,
    },
    {
      name: "No. of Invoices",
      selector: (row) => row.noOfInvoices,
      sortable: true,
      // width: "120px",
    },
    {
      name: "Total Request Amount",
      selector: (row) => row.totalRequestAmount,
      sortable: true,
      cell: (row) => <p className="text-success">{row.totalRequestAmount}</p>,
    },
    {
      name: "Total Discount Amount",
      selector: (row) => row.totalDiscountAmount,
      sortable: true,
      cell: (row) => <p className="text-yellow-400">{row.totalDiscountAmount}</p>,
    },
    {
      name: "Total Payable Amount",
      selector: (row) => row.totalPayableAmount,
      sortable: true,
      cell: (row) => <p className="text-success">{row.totalPayableAmount}</p>,
    },
    {
      name: "Rejection Type",
      selector: (row) => row.rejectionType,
      sortable: true,
      cell: (row) => <p className="text-success">{row.rejectionType}</p>,
      // width: "80px",
    },
  ];

  const totalPages = allEarlyPaymentRequestBulkData?.meta?.totalElements;

  return (
    <>
      {!data?.length ? (
        <div>
          <div className="flex justify-between items-center px-[15px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
            <p className="text-black text-sm font-medium">
              Rejected Early Payment Request
            </p>
          </div>
          <TableEmptyState dashboard />
        </div>
      ) : (
        <Card className="rounded-[10px] mt-[15px]">
          <div className="px[15px] pb-[24px]">
            <Table
              columns={columns}
              data={data}
              onRowClicked={onRowClicked}
              progressPending={isLoading}
              progressComponent={<Loading />}
              pointer
              tableHeader
              className="mt-5"
            />
          </div>
          <div className="flex justify-center gap-x-[8px] mt-[20px]">
            {!isLoading && totalPages > 10 && (
              <Pagination
                page={page}
                startPage={startPage}
                setEndPage={setEndPage}
                endPage={endPage}
                setStartPage={setStartPage}
                setPage={setPage}
                totalPages={totalPages}
              />
            )}
          </div>
        </Card>
      )}
    </>
  );
};

export default BulkEarlyPayment;
