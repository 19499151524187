import Table from "layout/table/Table";
import React, { useEffect, useState } from "react";
import Button from "shared/Button";
import BuyerDetailsModal from "../modal";
import DashboardLayout from "layout/dashboardlayout";
import { PAIconInventoryArrowLeft, PAIconSearch } from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AccountModalContainer from "shared/accountModal/Modal";
import {
  // onboardingInviteSupplier,
} from "appstate/buyer/buyerSlice";
import toast from "react-hot-toast";
// import Pagination from "shared/Pagination";
import Loading from "shared/Loading";
import DeclineModal from "shared/delineModal/Modal";
import UserAdd from "../../../../../assets/images/svgs/useradd.svg";
// import InviteModal from "shared/inviteModal";
import Pagination from "shared/Pagination";
import { debounce } from "lodash";
import { SupplierGetSentPendingInvitesBuyer, SupplierSendOnboardingInviteToFunderOrBuyer, getSupplierToFunderPendingAssociationRequest, supplierAcceptFunderRequest, supplierRejectFunderRequest } from "appstate/api/buyer/relationshipManagement";
import SuccessRelationship from "shared/SuccessRelationship";
import ContinueInviteModal from "shared/inviteModal/ContnueInviteModal";
import FunderInvitationModal from "shared/inviteModal/FunderInvitationModal";
import SuccessModal from "shared/SuccessModal";
// import SuccessModal from "shared/SuccessModal";

const SupplierPendingRequestFunder = () => {
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Pending Association");
  const { isLoading, declineLoading, inviteLoading, associateLoading } =
    useSelector((state) => state.buyer);
  // eslint-disable-next-line
  const [getId, setGetId] = useState(null);
  const [openDeclineModal, setOpenDeclineModal] = useState(false);
  // eslint-disable-next-line
  const [open, setOpen] = useState(false);
  const [opened, setOpened] = useState(false);
  const [success, setSuccess] = useState(false);
  const [companyName, setCompanyName] = useState("");
  // eslint-disable-next-line
  const [page, setPage] = useState(0);
  // const [startPage, setStartPage] = useState(0);
  // const [endPage, setEndPage] = useState(5);
  const pageSize = 10;
  const [data, setData] = useState([]);
  const [invite, setInvite] = useState([]);
  const [newInvite, setNewInvite] = useState("");
  const [successModal, setSuccessModal] = useState(false);
  // const [succeessModal, setSucceessModal] = useState(false);
  const [nonsuccessModal, setNonSuccessModal] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [continueInvite, setContinueInvite] = useState(false);
  const [openmodal, setOpenmodal] = useState(false);
  // const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  // const sender = "supplier";

  const fetchRequests = async (searchTerm) => {
    setIsLoading(true);
    try {
      let pendingRequests = [];
      let entityType = "funder";
      let sender = "funder"
      if (selectedTab === "Pending Association") {
        pendingRequests = await getSupplierToFunderPendingAssociationRequest({
          page,
          pageSize,
          entityType,
          search: searchTerm,
        });
      } else if (selectedTab === "Pending Invites") {
        pendingRequests = await SupplierGetSentPendingInvitesBuyer({
          page,
          pageSize,
          sender
        });
      }
      setData(pendingRequests?.data || []);
    } catch (error) {
      console.error("Failed to fetch requests", error);
    } finally {
      setIsLoading(false);
    }
  };
  
 

  useEffect(() => {
    const debouncedSearch = debounce((searchTerm) => {
      fetchRequests(searchTerm);
    }, 200);

    debouncedSearch(companyName);
    return () => {
      debouncedSearch.cancel();
    };
    // eslint-disable-next-line 
  }, [companyName, selectedTab, page, pageSize, dispatch]);

  const handleOpenModal = (row) => {
    setGetId(row?.notificationId);
    setOpened(true);
  };

  const handleDeclineModal = (row) => {
    setGetId(row?.notificationId);
    setOpenDeclineModal(true);
  };

  const handleSearch = () => {

  };

  const handleAssociate = async () => {
    const notificationId = getId;
    if (!notificationId) {
      toast.error("Notification ID is missing!");
      return;
    }
    try {
      const response = await supplierAcceptFunderRequest(notificationId);
      if (response?.success) {
        setSuccessModal(true)
        setOpened(false);
        fetchRequests();
      } else {
        toast.error(response?.message || "Failed to accept association request.");
      }
    } catch (error) {
      console.error("Error accepting association request", error);
    }
  };

  const handleDecline = async () => {
    const notificationId = getId;
    if(!notificationId){
      toast.error("Notification ID is missing!");
      return;
    }
    try {
      const response = await supplierRejectFunderRequest(notificationId);
      if(response?.success){
        setNonSuccessModal(true)
        // setSuccess(true);
        setOpenDeclineModal(false);
        fetchRequests();
      } else {
        toast.error(response?.message || "Failed to accept association request.");
      }
    } catch (error) {
      console.error("Error accepting association request", error);
    }
  };

  const onClose = () => {
    setSuccess(false);
  };

  const handleOpenConfirmModal = (row) => {
    setSelectedRow(row);
    setShowConfirmModal(true);
  };

  // const totalPages = pendingAssociateRequestData?.meta?.totalElements;

  const column = [
    {
      name: "Email",
      selector: (row) => row?.email,
      width: "15rem",
    },
    {
      name: "Status",
      selector: (row) => row?.status,
    },
    {
      cell: (row) => {
        return (
          <div className="flex gap-x-[12px]">
            <Button
           onClick={() => handleOpenConfirmModal(row)}
              neutral
              className="flex px-[16px] py-[8px] border-[1px] border-gray rounded-[5px] bg-primaryColor"
            >
              <p className="text-xs font-normal text-white whitespace-nowrap">
                Resend Invite
              </p>
            </Button>
          </div>
        );
      },
    },
  ];

  const columnsPendingAssociation = [
    {
      name: "Name",
      selector: (row) => row?.fullName,
    },
    {
      name: "Company",
      selector: (row) => row?.companyName,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
    },
    {
      name: "Phone",
      selector: (row) => row?.phoneNumber,
    },
    {
      cell: (row) => (
        <div className="flex gap-x-[12px]">
          <Button
            onClick={() => handleOpenModal(row)}
            neutral
            className="flex px-[16px] py-[8px] border-[1px] border-gray rounded-[5px] bg-primaryColor"
          >
            <p className="text-xs font-normal text-white whitespace-nowrap">
              Accept
            </p>
          </Button>
          <Button
            onClick={() => handleDeclineModal(row)}
            neutral
            className="flex px-[16px] py-[8px] border-[1px] border-[red] rounded-[5px] bg-[white]"
          >
            <p className="text-xs font-normal  text-[red] whitespace-nowrap">
              Decline
            </p>
          </Button>
        </div>
      ),
    },
  ];

  const navigate = useNavigate();
  function handleNavigate() {
    navigate(-1);
  }
  const handleTabClick = (tabName) => {
    setSelectedTab(tabName);
  };

  const handleShowSuccessModal = () => {
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 3000);
  };

  // SupplierSendOnboardingInviteToFunderOrBuyer
  const handleResendEmails = async () => {
    if (!selectedRow) return;
    const body = [selectedRow.email];

    try {
      const response = await SupplierSendOnboardingInviteToFunderOrBuyer(body);
      if (response) {
        setShowConfirmModal(false);
        handleShowSuccessModal();
      }
    } catch {
      console.error("An error occurred");
      toast.error("Error occurred. Try again later!");
    }
  };

  const handleSubmitEmails = async () => {
    const body = invite;
    try {
      const response = await SupplierSendOnboardingInviteToFunderOrBuyer(body);
      if (response) {
        setOpenmodal(false);
        handleShowSuccessModal();
      }
    } catch {
      console.error("an error occured");
      toast.error("error occured try again later!");
    }
  };

  const handleClose = () => {
    setSuccessModal(false);
  };

  const handleSubContinue = () => {
    setContinueInvite(false);
    setSuccess(true);
  };
  const handleContinueInvite = () => {
    setOpenmodal(false);
    setContinueInvite(true);
  };

  const onCloseMod = () => {
    setShowConfirmModal(false);
  }

  return (
    <DashboardLayout
      section="Funders"
      subHeader="Manage all your associated funders account"
    >
        {successModal && (
        <SuccessRelationship
          isOpen={successModal}
          onClose={onClose}
          message="Association successful"
        />
      )}
       {nonsuccessModal && (
        <SuccessRelationship
          isOpen={nonsuccessModal}
          onClose={onClose}
          message="Disassociation successful"
        />
      )}
       <ContinueInviteModal
        isOpen={continueInvite}
        onClose={() => setContinueInvite(false)}
        isLoading={inviteLoading}
        handleYes={handleSubContinue}
      />
      <FunderInvitationModal
        onClose={() => setOpenmodal(false)}
        isOpen={openmodal}
        message=" The Funder would received a mail that your are inviting to join
         PayEdge."
        heading="Invite Funder"
        info="You can add one or more Funders at once with ease"
        role="Funder"
        invite={invite}
        setInvite={setInvite}
        newInvite={newInvite}
        setNewInvite={setNewInvite}
        success={success}
        setSuccess={setSuccess}
        handleAdd={handleSubmitEmails}
        handleYes={handleContinueInvite}
        isLoading={inviteLoading}
      />
      {/* <InviteModal
        isShown={open}
        onClose={() => {
          setOpen(false);
          setSuccess(false);
        }}
        message="The Supplier would received a mail that your are inviting to join
          PayEdge."
        heading="Invite Supplier"
        info="You can add one or more supplier at once with ease"
        role="Supplier"
        invite={invitee}
        setInvite={setInvitee}
        newInvite={newInvite}
        setNewInvite={setNewInvite}
        success={success}
        setSuccess={setSuccess}
        handleAdd={handleSubmitEmails}
        isLoading={inviteLoading}
      /> */}
      <div
        style={{ padding: 15, borderRadius: 5 }}
        className="flex justify-between items-center bg-white"
      >
        <div className="w-[25%] flex justify-between">
          <h3
            className={`cursor-pointer ${
              selectedTab === "Pending Association" ? "text-[#07593D]" : ""
            }`}
            onClick={() => handleTabClick("Pending Association")}
            style={{
              borderBottom:
                selectedTab === "Pending Association"
                  ? "2px solid #07593D"
                  : "none",
              paddingBottom: "5px",
              fontSize: "14px",
              color: "#959595",
            }}
          >
            Pending Association
          </h3>
          <h3
            className={`cursor-pointer ${
              selectedTab === "Pending Invites" ? "text-[#07593D]" : ""
            }`}
            onClick={() => handleTabClick("Pending Invites")}
            style={{
              borderBottom:
                selectedTab === "Pending Invites"
                  ? "2px solid #07593D"
                  : "none",
              paddingBottom: "5px",
              fontSize: "14px",
              color: "#959595",
            }}
          >
            Pending Invites
          </h3>
        </div>
        <div>
          <Button
            onClick={() => setOpenmodal(true)}
            neutral
            className="flex text-[#F08000] text-xs font-medium items-center mb-[10px] md:mb-0 md:px-[5px] bg-[#07593D] py-[12px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px]"
          >
            <img src={UserAdd} alt="" />
            <p style={{ color: "white" }} className="whitespace-nowrap">
              {selectedTab === "Pending Association"
                ? "New Association Request"
                : "Invite Funder"}
            </p>
          </Button>
        </div>
      </div>

      <br />
      <div>
        {selectedTab === "Pending Requests" && (
          <header className="bg-alabasterHeader p-[24px] rounded-t-[10px]">
            <div
              onClick={handleNavigate}
              className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
            >
              <PAIconInventoryArrowLeft />
              <p className="mt-[2px] text-sm font-normal text-lightGray">
                Back
              </p>
            </div>
            <div className="flex justify-between flex-wrap ">
              <div>
                <p className="text-black text-sm font-medium">
                  Pending requests to associate with suppliers
                </p>
                <p className="text-textColor text-xs font-normal">
                  List of association request sent out to suppliers
                </p>
              </div>
              <div className="flex gap-x-[10px] mt-[10px] md:mt-0 flex-wrap">
                <div className="flex items-center  bg-white p-[10px] gap-x-[8px] rounded-[5px] border-[0.5px] border-silver xl:w-[350px]">
                  <PAIconSearch />
                  <input
                    type="text"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    placeholder="Search funder"
                    className="placeholder:text-xs outline-none"
                  />
                </div>
                <Button
                  neutral
                  onClick={handleSearch}
                  disabled={data < 1}
                  className="py-[12px] mt-[10px] md:mt-0 px-[24px] border-[0.5px] border-silver bg-brightGray text-sm !text-black rounded-[5px] font-medium"
                >
                  Search
                </Button>
              </div>
            </div>
          </header>
        )}

        {selectedTab === "Pending Association" && (
          <header className="bg-alabasterHeader p-[24px] rounded-t-[10px]">
            <div
              onClick={handleNavigate}
              className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
            >
              <PAIconInventoryArrowLeft />
              <p className="mt-[2px] text-sm font-normal text-lightGray">
                Back
              </p>
            </div>
            <div className="flex justify-between flex-wrap ">
              <div>
                <p className="text-black text-sm font-medium">
                  Pending requests to associate
                </p>
                <p className="text-textColor text-xs font-normal">
                  List of funder wanting to associate
                </p>
              </div>
              <div className="flex gap-x-[10px] mt-[10px] md:mt-0 flex-wrap">
                <div className="flex items-center  bg-white p-[10px] gap-x-[8px] rounded-[5px] border-[0.5px] border-silver xl:w-[350px]">
                  <PAIconSearch />
                  <input
                    type="text"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    placeholder="Search funder"
                    className="placeholder:text-xs outline-none"
                  />
                </div>
                <Button
                  neutral
                  onClick={handleSearch}
                  disabled={data < 1}
                  className="py-[12px] mt-[10px] md:mt-0 px-[24px] border-[0.5px] border-silver bg-brightGray text-sm !text-black rounded-[5px] font-medium"
                >
                  Search
                </Button>
              </div>
            </div>
          </header>
        )}

        {selectedTab === "Pending Invites" && (
          <header className="bg-alabasterHeader p-[24px] rounded-t-[10px]">
            <div
              onClick={handleNavigate}
              className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
            >
              <PAIconInventoryArrowLeft />
              <p className="mt-[2px] text-sm font-normal text-lightGray">
                Back
              </p>
            </div>
            <div className="flex justify-between flex-wrap ">
              <div>
                <p className="text-black text-sm font-medium">
                  Pending invites to funder
                </p>
                <p className="text-textColor text-xs font-normal">
                  List of invites sent out to funder
                </p>
              </div>
              <div className="flex gap-x-[10px] mt-[10px] md:mt-0 flex-wrap">
                <div className="flex items-center  bg-white p-[10px] gap-x-[8px] rounded-[5px] border-[0.5px] border-silver xl:w-[350px]">
                  <PAIconSearch />
                  <input
                    type="text"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    placeholder="Search funder"
                    className="placeholder:text-xs outline-none"
                  />
                </div>
                <Button
                  neutral
                  onClick={handleSearch}
                  disabled={data < 1}
                  className="py-[12px] mt-[10px] md:mt-0 px-[24px] border-[0.5px] border-silver bg-brightGray text-sm !text-black rounded-[5px] font-medium"
                >
                  Search
                </Button>
              </div>
            </div>
          </header>
        )}

        <BuyerDetailsModal
          isShown={openDetailsModal}
          onClose={() => setOpenDetailsModal(false)}
          //   data={supplierDetails}
        />
      <SuccessModal
          isOpen={success}
          onClose={handleClose}
          message=" The Funder would received a mail that your are inviting to join
          PayEdge."
        />
        <DeclineModal
          isShown={openDeclineModal}
          onClose={() => setOpenDeclineModal(false)}
          success={success}
          handleAssociate={handleDecline}
          isLoading={declineLoading}
          warning="Are you sure you would like to Decline this request?"
        />
         <AccountModalContainer
          isShown={showConfirmModal}
          onClose={onCloseMod}
          success={success}
          handleAssociate={handleResendEmails}
          // isLoading={associateLoading}
          warning="Are you sure you would like to resend this email to the supplier?"
        />

        <AccountModalContainer
          isShown={opened}
          onClose={() => setOpened(false)}
          success={success}
          handleAssociate={handleAssociate}
          isLoading={associateLoading}
          warning="Are you sure you would like to associate with the funder?"
        />
        <div className="p-[24px] bg-white rounded-b-[10px]">
          {selectedTab === "Pending Association" && (
            <Table
              columns={columnsPendingAssociation}
              data={data}
              pointer
              // onRowClicked={onRowClicked}
              progressPending={loading}
              progressComponent={<Loading />}
              tableHeader
            />
          )}

          {selectedTab === "Pending Invites" && (
            <Table
              columns={column}
              data={data}
              pointer
              // onRowClicked={onRowClicked}
              progressPending={isLoading}
              progressComponent={<Loading />}
              tableHeader
            />
          )}
          <br />

          <div className="flex justify-center gap-x-[8px]  mt-12">
            <Pagination />
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default SupplierPendingRequestFunder;
