import { PAIconArrowLeftGreen, PAIconModelOne } from "assets/images/svgs";
import React, { useState, useEffect } from "react";
import RejectModal from "./rejectModal";
import RejectionSuccess from "features/BuyerEarlyPayment/ProcessingEarlyPayment/singleEarlyPayment/Modals/RejectionSuccess";
import { useLocation } from "react-router-dom";
import BidDetails from "./BidDetails";
import { useNavigate } from "react-router-dom";
import { processingSingleEarlyPaymentDetails } from "appstate/buyer/buyerSlice";
import { allInvoiceById } from "appstate/invoice/invoiceSlice";
import { useDispatch, useSelector } from "react-redux";

const Details = () => {
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [isRejectSuccessModal, setIsRejectSuccessModal] = useState(false);
  const location = useLocation();
  const { activeTabType } = location.state || {};
  const navigate = useNavigate();
  const { getInvoiceId, viewApprovedSingleEarlyPaymentDetailsData } =
    useSelector((state) => state?.buyer);
  const { invoiceIdData } = useSelector((state) => state?.invoice);
  const [data, setData] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(processingSingleEarlyPaymentDetails(getInvoiceId.earlyPaymentId));
  }, [dispatch, getInvoiceId?.earlyPaymentId]);

  useEffect(() => {
    const id = getInvoiceId.invoiceId;
    dispatch(allInvoiceById({ id }));
  }, [dispatch, getInvoiceId?.invoiceId]);

  useEffect(() => {
    setData(viewApprovedSingleEarlyPaymentDetailsData.data);
  }, [viewApprovedSingleEarlyPaymentDetailsData]);

  useEffect(() => {
    setInvoiceData(invoiceIdData?.data);
  }, [invoiceIdData]);

  //Reject modal control
  const handleRejectRequest = () => {
    setIsRejectModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsRejectModalOpen(false);
  };

  //Success modal control
  const handleSuccessModal = () => {
    setIsRejectModalOpen(false);
    setIsRejectSuccessModal(true);
  };

  const handleSuccessModalClose = () => {
    setIsRejectSuccessModal(false);
  };

  useEffect(() => {
    if (isRejectSuccessModal === true) {
      setTimeout(() => {
        navigate("/payablefinancing/overview");
      }, 3000);
    }
  }, [isRejectSuccessModal, navigate]);

  return (
    <>
      <div
        onClick={() => {
          let path = "/payablefinancing/overview";
          navigate(path, { state: { selectedtab: "earlyPaymentsProcessing" } });
        }}
        className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer w-full"
      >
        <PAIconArrowLeftGreen />
        <span className="flex items-center text-primaryColor">Go Back</span>
      </div>

      <div className="grid lg:grid-cols-[60%,40%] gap-5 h-[80vh]">
        {/* 60% */}
        <div className="flex flex-col gap-8 pb-24 bg-white rounded-[10px] pt-7">
          <div className="px-6 ">
            <div className="flex flex-col gap-3">
              <div className="flex items-center justify-between">
                <h1 className="text-[18px] font-bold">
                  Reference No: | {invoiceData?.invoiceRef}
                </h1>
                {activeTabType === "Request in closed market" ? (
                  <button className="w-[135px] h-[45px] rounded-[5px] bg-[#07593D] text-[white]">
                    Posted
                  </button>
                ) : null}
              </div>

              <main className=" w-full flex justify-between">
                <div className="flex flex-col gap-3 w-1/2">
                  <div className="flex flex-col gap-1">
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs font-semibold w-[30%]">
                        Invoice Number:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        INV-{invoiceData?.invoiceNo}
                      </span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs font-semibold w-[30%]">
                        Created Date:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {invoiceData?.createdDate}
                      </span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs font-semibold w-[30%]">
                        Submitted Date:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {invoiceData?.submittedDate}
                      </span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs font-semibold w-[30%]">
                        Approved Date:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {invoiceData?.approvedDate}
                      </span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs font-semibold w-[30%]">
                        Due Date:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {invoiceData?.dueDate}
                      </span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs font-semibold w-[30%]">
                        Issunance Date:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {invoiceData?.issuanceDate}
                      </span>
                    </div>
                  </div>
                </div>
                {activeTabType === "Request from funder" ? (
                  <div>
                    <button className="w-[180px] h-[35px] bg-[#C7D7FF] rounded-[4px] text-[#5C8AFF]">
                      Processing
                    </button>
                  </div>
                ) : null}
              </main>
            </div>

            <div className="flex justify-between my-5">
              <div className="flex flex-col">
                <span className=" font-bold text-sm">
                  {" "}
                  {invoiceData?.buyerCompanyName}
                </span>
                <span className="text-[#979797] text-xs">Buyer Details</span>
              </div>
              <div className="flex flex-col">
                <span className="font-bold text-sm">
                  {invoiceData?.paymentTerms} Days
                </span>
                <span className="text-[#979797] text-xs">Payment</span>
              </div>
              <div className="flex flex-col">
                <span className="font-bold text-sm">
                  {invoiceData?.currency}
                </span>
                <span className=" text-[#979797] text-xs">Currency</span>
              </div>
            </div>

            <div className="flex flex-col gap-4 mt-5">
              <header className="border-b-[5px] border-b-[#F6F5FA] pb-3 flex items-center justify-between">
                <h1 className="text-sm font-bold">Line Items</h1>
                <h1 className="text-sm font-bold">
                  {invoiceData?.items?.length} Total Items
                </h1>
              </header>
              <div className=" w-full overflow-x-auto h-[25vh]">
                <table className="overflow-x-auto w-[60vw]">
                  <thead className="bg-subtleGray h-[70px] w-[50vw] overflow-x-auto">
                    <tr className=" bg-unusualGray p-2.5">
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Item
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Description
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Unit Price
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Quantity
                      </td>
                      <td className="text-[#11192A] p-2.5 text-xs font-bold w-[120px]">
                        Amount
                      </td>
                      <td className="text-[#11192A] p-2.5 text-xs font-bold w-[120px]">
                        Tax rate
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Tax Amount
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Total Price
                      </td>
                    </tr>
                  </thead>
                  {invoiceData?.items?.map((item, index) => (
                    <tbody key={index}>
                      <tr className=" py-[3px] px-1.5">
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.itemName}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5 w-[20%]">
                          {item?.description}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.unitPrice.toLocaleString()}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.quantity}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.amount.toLocaleString()}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.taxRate}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.taxAmount.toLocaleString()}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.totalPrice.toLocaleString()}
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            </div>

            <div className="flex itemscenter justify-between px-6 mt-[10%]">
              <div>
                <h1 className="border-b border-[#B1A6B2] font-semibold">
                  Receiving Funding Account Information
                </h1>
                <p className="mt-3 text[#979797]">
                  {" "}
                  {invoiceData?.virtualAccount?.accountName}
                </p>
                <p className="text[#979797]">
                  {invoiceData?.virtualAccount?.accountNumber}
                </p>
              </div>
              <div className="flex flex-col">
                <div className="flex justify-between gap-x-[100px] w-full">
                  <span className="text-xs w-[97px]">Sub Total</span>
                  <span className="text-[#979797] text-xs text-right">
                    {invoiceData?.subTotal?.toLocaleString()}
                  </span>
                </div>

                <div className="flex justify-between w-full gap-x-[100px] my-2">
                  <span className="flex items-center text-xs w-[97px]">
                    Discount%
                    <span className="border border-[#979797] pr-10 pl-2 inline-block ml-4">
                      {invoiceData?.discount}%
                    </span>
                  </span>
                  <span className="text-[#979797] text-xs">
                    {invoiceData?.discountAmount}
                  </span>
                </div>

                <div className="flex justify-between w-full gap-x-[100px] pb-5">
                  <span className="flex items-center text-xs w-[97px]">
                    VAT%
                    <span className="border border-[#979797] ml-10 pr-11 pl-2 inline-block">
                      {invoiceData?.vat}%
                    </span>
                  </span>
                  <span className="text-[#979797] text-xs">
                    {invoiceData?.vatAmount?.toLocaleString()}
                  </span>
                </div>

                <div className="flex justify-between items-center w-full gap-x-[100px] border-t-2 border-b-2 font-bold text-lg py-1">
                  <span className="w-[97px]">Grand Total</span>
                  <span className="text[#979797] py-1">
                    {invoiceData?.grandTotal?.toLocaleString()}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 40% */}
        <div className="bg-white rounded-[10px] gap-8 px-6 pt-7 pb-24">
          {activeTabType === "Request from funder" ? (
            <div>
              <h2 className="font-semibold text-lg leading-[30px] mb-4">
                Early Payment Details
              </h2>

              <div className="flex justify-between my-5">
                <div className="flex gap-2">
                  <PAIconModelOne />
                  <div className="flex flex-col">
                    <span className="text-sm">Supplier</span>
                    <span className="text-[#979797]  text-xs">
                      {data?.supplierCompanyName}
                    </span>
                  </div>
                </div>
                <div className="flex flex-col">
                  <span className="text-sm">{data?.discountRate}%</span>
                  <span className="text-[#979797]  text-xs">Discount rate</span>
                </div>
              </div>

              <div className="flex justify-between pb-5">
                <div className="flex gap-2">
                  <div className="flex flex-col">
                    <span className="text-sm">
                      N{data?.discountAmount?.toLocaleString() || "0.00"}
                    </span>
                    <span className="text-[#979797]  text-xs">
                      Discount Amount
                    </span>
                  </div>
                </div>
                <div className="flex flex-col">
                  <span className="text-sm">
                    {data?.earlyPaymentDate}({data?.paymentDay} days)
                  </span>
                  <span className="text-[#979797]  text-xs">
                    Early Payment Request Date
                  </span>
                </div>
              </div>

              <div className="flex justify-between mt-5 border-b-[5px] border-b-[#F6F5FA] pb-10">
                <div className="flex gap-2">
                  <div className="flex flex-col">
                    <span className="text-sm">
                      N{data?.requestAmount?.toLocaleString() || "0.00"}
                    </span>
                    <span className="text-[#979797]  text-xs">
                      Request Amount
                    </span>
                  </div>
                </div>
                <div className="flex flex-col">
                  <span className="text-sm">
                    {data?.payableAmount?.toLocaleString()}
                  </span>
                  <span className="text-[#979797]  text-xs">
                    Payable amount
                  </span>
                </div>
              </div>

              <div className="border-b-[5px] border-b-[#F6F5FA] pb-5">
                <h2 className="font-medium text-base my-3">
                  Note from Supplier:
                </h2>
                <p className="text-[#959595] text-sm">{data?.description}</p>
              </div>

              <div className="pb-3">
                <h2 className="font-medium text-base leading-[30px] my-2">
                  Funding Option
                </h2>
                <p className="text-[#959595] text-sm">
                  This invoice will be approved by{" "}
                  <span className="font-semibold text-black">
                    Elanor Penar at coca-cola
                  </span>{" "}
                  your associated funder for funding
                </p>
                <div className="flex flex-col items-center justify-center mt-9">
                  <button
                    className="rounded-md bg-white border border-[#DE1515] text-[#DE1515] px-12 py-2 h-[65px]"
                    onClick={handleRejectRequest}
                  >
                    Withdraw Request
                  </button>
                  <p className="mt-3 text-lightGray">
                    Do you want to cancel this request?
                  </p>
                </div>
              </div>

              <RejectModal
                isOpen={isRejectModalOpen}
                onClose={handleCloseModal}
                confirm={handleSuccessModal}
              />
              <RejectionSuccess
                isOpen={isRejectSuccessModal}
                onClose={handleSuccessModalClose}
              />
            </div>
          ) : (
            <BidDetails />
          )}
        </div>
      </div>
    </>
  );
};

export default Details;
