import React from "react";
import DashboardLayout from "layout/dashboardlayout";
import Details from "./Details";

const ReimbursementOverViewDetails = () => {
  return (
    <DashboardLayout
      section="Reimbursement"
      subHeader="Track invoices due for payment Reimbursement"
    >
      <Details />
    </DashboardLayout>
  );
};

export default ReimbursementOverViewDetails;
