// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../../../public/card-bg.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.debit-body {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.shadow-card-mod {
  border: 1px solid rgba(255, 255, 255, 0.24);
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 8px 8px 10px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(15px);
}
`, "",{"version":3,"sources":["webpack://./src/features/dashboard/buyerdashboard/components/styles/debitcard.css"],"names":[],"mappings":"AAAA;EACE,yDAA6D;EAC7D,4BAA4B;EAC5B,sBAAsB;EACtB,2BAA2B;AAC7B;;AAEA;EACE,2CAA2C;EAC3C,oCAAoC;EACpC,gDAAgD;EAChD,2BAA2B;AAC7B","sourcesContent":[".debit-body {\n  background-image: url(\"../../../../../../public/card-bg.svg\");\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-position: center;\n}\n\n.shadow-card-mod {\n  border: 1px solid rgba(255, 255, 255, 0.24);\n  background: rgba(255, 255, 255, 0.2);\n  box-shadow: 8px 8px 10px 0px rgba(0, 0, 0, 0.25);\n  backdrop-filter: blur(15px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
