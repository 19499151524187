import { useState } from "react";
import "../styles/tabbedinvoice.css";
import { useNavigate } from "react-router-dom";
// import { useSelector } from "react-redux";

function InvoiceTabbedCard({ earlyPaymentsData, pfpData }) {
  const [activeTab, setActiveTab] = useState(0);
  // const { buyerDashboardData } = useSelector((state) => state.buyer);

  // const earlyPaymentsData = buyerDashboardData?.earlyPayments;
  const changeTab = (index) => {
    setActiveTab(index);
  };
  const navigate = useNavigate();
  return (
    <div>
      <div className="lg:flex-1 px-5 py-5 pl-8 rounded-md bg-white w-full pr-6 mt-3">
        <div className=" mb-3 py-2.5 w-full flex justify-between">
          <span className=" text-[#07593D] font-[500] ">Disbursement</span>
          <span className=" text-[#656565] underline text-xs">
            <p className="cursor-pointer">View all</p>
          </span>
        </div>

        <div>
          <div className=" flex justify-between w-full rounded-lg ">
            <button
              onClick={() => changeTab(0)}
              className={`flex justify-center rounded-l-lg  py-3 w-1/2 ${
                activeTab === 0
                  ? "active-tab tab-button"
                  : "inactive-tab tab-button"
              }`}
            >
              <span className={`text-[#565656)]  text-[14px]`}>
                PFP (Dynamic Discounting)
              </span>
            </button>
            <button
              onClick={() => changeTab(1)}
              className={`flex rounded-r-lg justify-center py-3 w-1/2 ${
                activeTab === 1
                  ? "active-tab tab-button"
                  : "inactive-tab tab-button"
              }`}
            >
              <span className={`text-[14px]`}>Early Payments</span>
            </button>
          </div>

          <div className="tab-content-wrapper">
            {activeTab === 0 ? (
              <div className="tab-content overflow-auto scroll-hidden py-1.5 h-[430px]  active w-full">
                {pfpData?.map((payment) => (
                  <InvCards
                    key={payment?.id}
                    amount={payment?.invoiceAmount}
                    date={`${payment?.dueDate[0]}/${payment?.dueDate[1]}/${payment?.dueDate[2]}`}
                    invoiceRef={payment?.invoiceNumber}
                    supplierCompanyName={payment?.supplierCompanyName}
                    // type="Early Payment"
                    // payExtension={false}
                  />
                ))}
              </div>
            ) : (
              <div className="tab-content overflow-auto scroll-hidden py-1.5 h-[430px]  active w-full">
                {earlyPaymentsData?.map((payment) => (
                  <InvCards
                    key={payment?.id}
                    amount={payment?.invoiceAmount}
                    date={`${payment?.dueDate[0]}/${payment?.dueDate[1]}/${payment?.dueDate[2]}`}
                    invoiceRef={payment?.invoiceNumber}
                    supplierCompanyName={payment?.supplierCompanyName}
                    navigate={() => navigate("/disbursement")}

                    // type="Early Payment"
                    // payExtension={false}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function InvCards({
  type,
  amount,
  date,
  invoiceRef,
  supplierCompanyName,
  payExtension,
  viewFunction,
  paymentExtensionFunction,
  navigate,
}) {
  return (
    <div
      className=" mt-2.5 py-3.5 px-4 flex flex-col gap-3 shadow-mod"
      onClick={navigate}
    >
      <div className=" flex justify-between">
        <div className=" flex gap-2.5">
          <div className="flex items-center ">
            <img src="/book.svg" alt="book" />
          </div>
          <div>
            <div className=" text-[#222] font-[500] text-[15px] ">
              {invoiceRef || "N/A"}
            </div>
            <div className=" text-[#7A7A7A] text-xs">
              Supplier :{supplierCompanyName || "N/A"}
            </div>
          </div>
        </div>

        <div className=" items-end flex flex-col text-[15px]">
          <div className=" text-[#222] font-[500]  ">
            Amount:
            <span> NGN {amount}</span>
          </div>
          <div className=" text-[#7A7A7A] text-xs">
            {type} Date : {date}
          </div>
        </div>
      </div>

      <div className=" w-full border border-[#DCDCDC]"></div>

      <div className=" flex justify-end gap-3">
        <button
          onClick={paymentExtensionFunction}
          className={`${
            payExtension ? "" : "hidden"
          } rounded border  md:whitespace-nowrap text-[#07593D] border-[#07593D] py-1.5 px-7 text-xs flex  justify-center items-center 9`}
        >
          Payment Extension
        </button>
        <button
          onClick={viewFunction}
          className=" rounded border whitespace-nowrap text-white bg-[#07593D] py-1.5 px-7 flex justify-center items-center transition duration-500 text-xs transform hover:scale-105 ease-in-out hover:bg-[#065f46]"
        >
          View
        </button>
      </div>
    </div>
  );
}

export default InvoiceTabbedCard;
