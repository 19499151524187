import request from "apiInstance";
import toast from "react-hot-toast";

export const getAllClosedMarketByStatus = async () => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/closed-market/requests-in-closed-market`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

// /api/v1/invoice/{invoiceId}
export const getAllClosedMarketBySingleStatus = async ({ status }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/closed-market/requests-in-closed-market?status=${status}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllClosedMarketInvoiceById = async ({ id }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
