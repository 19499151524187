import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getAgreementOverview,
  getAgreementStatusOverview,
  createAgreement,
  uploadAgreementFile,
  getAllAgreement,
  agreementUpdate,
  agreementDelete,
  getAgreementById,
} from "appstate/api/toolsAndAgreement";

const initialState = {
  isLoading: false,
  toolsAndAgreementDashboardData: [],
  statusOverviewData: [],
  getUploadURL: null,
  allAgreementData: [],
  agreementByIdData: null,
  agreementId: null,
};

export const toolsAndAgreementOverview = createAsyncThunk(
  "toolsAndAgreement/toolsAndAgreementOverview",
  getAgreementOverview
);

export const toolsAndAgreementStatusOverview = createAsyncThunk(
  "toolsAndAgreement/toolsAndAgreementStatusOverview",
  getAgreementStatusOverview
);

export const toolsAndAgreementCreateAgreement = createAsyncThunk(
  "toolsAndAgreement/toolsAndAgreementCreateAgreement",
  createAgreement
);

export const getAgreement = createAsyncThunk(
  "toolsAndAgreement/getAgreement",
  getAllAgreement
);

export const agreementById = createAsyncThunk(
  "toolsAndAgreement/agreementById",
  getAgreementById
);

export const uploadAgreement = createAsyncThunk(
  "toolsAndAgreement/uploadAgreement",
  uploadAgreementFile
);

export const updateAgreement = createAsyncThunk(
  "toolsAndAgreement/updateAgreement",
  agreementUpdate
);

export const deleteAgreement = createAsyncThunk(
  "toolsAndAgreement/deleteAgreement",
  agreementDelete
);

export const toolsAndAgreementSlice = createSlice({
  name: "toolsAndAgreement",
  initialState,
  reducers: {
    getAgreementId: (state, action) => {
      state.agreementId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(toolsAndAgreementOverview.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(toolsAndAgreementOverview.fulfilled, (state, action) => {
      state.isLoading = false;
      state.toolsAndAgreementDashboardData = action.payload;
    });
    builder.addCase(toolsAndAgreementOverview.rejected, (state) => {
      state.isLoading = false;
    });
    // // // // // // // // // // STATUS OVERVIEW // // // /// // // // // // //
    builder.addCase(toolsAndAgreementStatusOverview.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      toolsAndAgreementStatusOverview.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.statusOverviewData = action.payload;
      }
    );
    builder.addCase(toolsAndAgreementStatusOverview.rejected, (state) => {
      state.isLoading = false;
    });
    // // // // // // // // // // CREATE AGREEMENT // // // /// // // // // // //
    builder.addCase(toolsAndAgreementCreateAgreement.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(toolsAndAgreementCreateAgreement.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(toolsAndAgreementCreateAgreement.rejected, (state) => {
      state.isLoading = false;
    });
    // // // // // // // // // // UPLOAD AGREEMENT // // // /// // // // // // //
    builder.addCase(uploadAgreement.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(uploadAgreement.fulfilled, (state, action) => {
      state.isLoading = false;
      state.getUploadURL = action.payload?.data?.url;
    });
    builder.addCase(uploadAgreement.rejected, (state) => {
      state.isLoading = false;
    });
    // // // // // // // // // // ALL AGREEMENT // // // /// // // // // // //
    builder.addCase(getAgreement.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAgreement.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allAgreementData = action.payload;
    });
    builder.addCase(getAgreement.rejected, (state) => {
      state.isLoading = false;
    });
    // // // // // // // // // // AGREEMENT BY ID // // // /// // // // // // //
    builder.addCase(agreementById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(agreementById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.agreementByIdData = action.payload;
    });
    builder.addCase(agreementById.rejected, (state) => {
      state.isLoading = false;
    });
    // // // // // // // // // // UPDATE AGREEMENT // // // /// // // // // // //
    builder.addCase(updateAgreement.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateAgreement.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateAgreement.rejected, (state) => {
      state.isLoading = false;
    });
    // // // // // // // // // // DELETE AGREEMENT // // // /// // // // // // //
    builder.addCase(deleteAgreement.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteAgreement.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteAgreement.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const { getAgreementId } = toolsAndAgreementSlice.actions;

export default toolsAndAgreementSlice.reducer;
