import React, { useEffect, useState } from "react";
import { PAIconFilter, PAIconOutlineSearch } from "assets/images/svgs";
import Card from "shared/Card";

// import styled from "styled-components";

// import ClosedMarketHeaderSelect from "shared/tableHeader/ClosedMarketHeaderSelect";

import TransitionOpacity from "shared/animation/TransitionOpacity";
import MyInvoicesCard from "features/closedmarket/components/cards/MyInvoicesCard";

import { useDispatch, useSelector } from "react-redux";
import {
  closedMarketViewListBulk,
  closedMarketViewListSingle,
} from "appstate/buyer/buyerSlice";
import MyInvoicesCardBulk from "features/closedmarket/components/cards/MyInvoicesCardBulk";

const MyInvoices = ({ handleChange }) => {
  const dispatch = useDispatch();
  const { closedMarketViewListSingleData, closedMarketViewListBulkData } =
    useSelector((state) => state?.buyer);
  const [requestType, setRequestType] = useState("single");

  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermBulk, setSearchTermBulk] = useState("");

  useEffect(() => {
    dispatch(
      closedMarketViewListSingle({
        status: "LOCKED",
        search: searchTerm,
      })
    );
  }, [dispatch, searchTerm]);

  useEffect(() => {
    dispatch(
      closedMarketViewListBulk({
        status: "LOCKED",
        search: searchTermBulk,
      })
    );
  }, [dispatch, searchTermBulk]);

  return (
    <>
      <div>
        <TransitionOpacity className="min-h-[75vh] rounded-b-[10px] bg-unusualGray rounded-tl-[10px]  rounded-tr-[10px] ">
          <Card className="  rounded-tl-[10px]  rounded-tr-[10px] p-[0.5px] ">
            <div className=" px-[24px]  rounded-tl-[10px]  rounded-tr-[10px]  ">
              <div className="my-2 grid md:grid-cols-2 items-center">
                <div className="gap-3 flex relative">
                  {/* FILTER */}
                  <div className="">
                    <p className=" text-black text-[16px] font-bold">
                      Won bids from funder:
                    </p>
                    <p className=" text-black text-sm font-normal">
                      List of bids won by your associated funders:
                    </p>
                  </div>
                </div>

                <div className="flex md:col-span-1 justify-end">
                  <div className="flex items-center justify-between gap-5">
                    <div className="border border-transparent md:border-gray md:shadow-sm my-4 rounded-[5px] px-2 lg:w-[300px] bg-white">
                      <div className="items-center flex bg-white">
                        <PAIconOutlineSearch />
                        <input
                          className="bg-transparent p-2 w-full focus:outline-none text-sm bg-white"
                          type="text"
                          placeholder="Search for request"
                          value={
                            requestType === "single"
                              ? searchTerm
                              : searchTermBulk
                          }
                          onChange={(e) =>
                            requestType === "single"
                              ? setSearchTerm(e.target.value)
                              : setSearchTermBulk(e.target.value)
                          }
                        />
                      </div>
                      <div className="lg:hidden flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer">
                        <PAIconFilter className="bg-[#2FA06A]" />
                      </div>
                    </div>
                    <button className="hidden lg:flex bg-[#2FA06A] rounded-md my-4 py-2 px-6 mx3 items-center gap-2 text-white">
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Card>
          <div className=" p-6 flex flex-col gap-5 ">
            <div className="flex items-center">
              <div
                onClick={() => {
                  setRequestType("single");
                }}
                className="mr-6 flex items-center flex-col cursor-pointer"
              >
                <p
                  className={
                    requestType === "single"
                      ? "text-secondaryColor font-bold text-sm"
                      : "text-[#959595] text-sm"
                  }
                >
                  Single Requests
                </p>

                {requestType === "single" && (
                  <div className="h-[1.5px] w-6 rounded-lg bg-secondaryColor"></div>
                )}
              </div>
              <div
                onClick={() => {
                  setRequestType("bulk");
                }}
                className="flex items-center flex-col cursor-pointer"
              >
                <p
                  className={
                    requestType === "bulk"
                      ? "text-secondaryColor font-bold text-sm"
                      : "text-[#959595] text-sm"
                  }
                >
                  Bulk Requests
                </p>

                {requestType === "bulk" && (
                  <div className="h-[1.5px] w-6 rounded-lg bg-secondaryColor"></div>
                )}
              </div>
            </div>
            {requestType === "single" ? (
              closedMarketViewListSingleData?.data?.length > 0 ? (
                closedMarketViewListSingleData?.data?.map((invoice) => {
                  return (
                    <MyInvoicesCard
                      key={invoice?.closedMarketId}
                      id={invoice?.invoiceId}
                      status={invoice.status}
                      supplierCompanyName={invoice.supplierCompanyName}
                      invoiceNumber={invoice?.invoiceNo}
                      invoiceReference={invoice?.requestRef}
                      doerType={invoice.userType}
                      invoiceAmount={invoice?.requestAmount}
                      invoiceDueDate={invoice?.invoiceDueDate}
                      supplierCompanyIcon={invoice.userTypeIcon}
                      lockRate={invoice.buyerDiscountRate}
                      earlyId={invoice?.closedMarketId}
                      earlyDate={invoice?.earlyPaymentDate}
                    />
                  );
                })
              ) : (
                <div className="flex pt-6 justify-center">
                  <span className="font-medium">
                    There are no records to display
                  </span>
                </div>
              )
            ) : closedMarketViewListBulkData?.data?.length > 0 ? (
              closedMarketViewListBulkData?.data?.map((invoice) => {
                return (
                  <MyInvoicesCardBulk
                    key={invoice?.closedMarketId}
                    id={invoice?.closedMarketId}
                    status={invoice.status}
                    supplierCompanyName={invoice.supplierCompanyName}
                    invoiceNumber={invoice?.noOfInvoice}
                    invoiceReference={invoice?.requestRef}
                    doerType={invoice.userType}
                    invoiceAmount={invoice?.requestAmount}
                    invoiceDueDate={invoice?.invoiceDueDate}
                    supplierCompanyIcon={invoice.userTypeIcon}
                    earlyPaymentDate={invoice?.earlyPaymentDate}
                    discountRate={invoice.discountRate}
                  />
                );
              })
            ) : (
              <div className="flex pt-6 justify-center">
                <span className="font-medium">
                  There are no records to display
                </span>
              </div>
            )}
          </div>
        </TransitionOpacity>
      </div>
    </>
  );
};

export default MyInvoices;
// const SelectContainer = styled.div`
//   min-width: 150px;
//   @media screen and (max-width: 470px) {
//     min-width: auto;
//   }
// `;
