import React, { useState } from "react";
import DashboardLayout from "layout/dashboardlayout";
import { PAIconArrowLeftGreen, PAIconCocacola } from "assets/images/svgs";
import { useNavigate } from "react-router-dom";

import TransitionScale from "shared/animation/TransitionScale";
import PendingApprovalSucessModal from "../components/modal/PendingApprovalSucessModals";
import PendingApprovalProceedModal from "../components/modal/PendingApprovalProceedModal";
import PendingApprovalDeclineSucessModal from "../components/modal/PendingApprovalDeclineSuccess";
import PendingApprovalDeclineModal from "../components/modal/PendingApprovalDeclineModal";
import ReasonForDeclineModal from "../components/modal/ReasonForDeclineModal";
import { AnimatePresence } from "framer-motion";
import Table from "layout/table/Table";

const ApprovalViaWalletScreen = () => {
  const [ongoingCancelBidModal, setOngoingCancelBidModal] = useState(false);
  const [ongoingBidCancelledSuccessModal, setOngoingBidCancelledSuccessModal] =
    useState(false);

  const [ongoingCancelBidModal2, setOngoingCancelBidModal2] = useState(false);
  const [
    ongoingBidCancelledSuccessModal2,
    setOngoingBidCancelledSuccessModal2,
  ] = useState(false);
  const [reasonforDecline, setReasonForDecline] = useState(false);

  const handleOnCancelSuccessClose = () => {
    setOngoingBidCancelledSuccessModal(false);
    navigate(-1);
  };
  const handleOnCancelBidTrue = () => {
    setOngoingCancelBidModal(false);
    setOngoingBidCancelledSuccessModal(true);
  };
  const handleOnCancelSuccessClose2 = () => {
    setOngoingBidCancelledSuccessModal2(false);
    navigate(-1);
  };
  const handleOnCancelBidTrue2 = () => {
    setOngoingCancelBidModal2(false);
    // setOngoingBidCancelledSuccessModal2(true);
    setReasonForDecline(true);
  };

  const handleDeclineContinue = () => {
    setReasonForDecline(false);
    setOngoingBidCancelledSuccessModal2(true);
  };

  const navigate = useNavigate();

  const columns = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Invoice Number
        </span>
      ),
      selector: (row) => row.invNum,
    },

    {
      name: "Due Date",
      selector: (row) => row.dueDate,
    },
    {
      name: "Request Amount",
      selector: (row) => row.requestAmount,
    },
    {
      name: "Discount Rate",
      selector: (row) => row.discountRate,
    },
    {
      name: "Receivable Amount",
      selector: (row) => row.receivableAmount,
    },
  ];

  const tableData = [
    {
      bidAmount: 1,
      invNum: "INV-2090 ",
      dueDate: "2023-05-23",
      requestAmount: "9,000,000.00",
      discountRate: "1.5%",
      receivableAmount: "9,000,000.00",
    },
    {
      bidAmount: 2,
      invNum: "INV-2090 ",
      dueDate: "2023-05-23",
      requestAmount: "9,000,000.00",
      discountRate: "1.5%",
      receivableAmount: "9,000,000.00",
    },

    {
      bidAmount: 3,
      invNum: "INV-2090 ",
      dueDate: "2023-05-23",
      requestAmount: "9,000,000.00",
      discountRate: "1.5%",
      receivableAmount: "9,000,000.00",
    },
    {
      bidAmount: 4,
      invNum: "INV-2090 ",
      dueDate: "2023-05-23",
      requestAmount: "9,000,000.00",
      discountRate: "1.5%",
      receivableAmount: "9,000,000.00",
    },
  ];
  const navigateToDetail = (id) =>
    navigate(
      `/work-flow-management/detail/approvalviawalletscreentwodetails/${id}`
    );

  return (
    <>
      <TransitionScale>
        <PendingApprovalProceedModal
          isOpen={ongoingCancelBidModal}
          onClose={() => setOngoingCancelBidModal(false)}
          handleYes={handleOnCancelBidTrue}
        />
      </TransitionScale>
      <TransitionScale>
        <PendingApprovalSucessModal
          isOpen={ongoingBidCancelledSuccessModal}
          onClose={handleOnCancelSuccessClose}
          message={"Request Approved"}
        />
      </TransitionScale>
      <TransitionScale>
        <PendingApprovalProceedModal
          isOpen={ongoingCancelBidModal}
          onClose={() => setOngoingCancelBidModal(false)}
          handleYes={handleOnCancelBidTrue}
        />
      </TransitionScale>

      <AnimatePresence>
        <TransitionScale>
          <PendingApprovalDeclineSucessModal
            isOpen={ongoingBidCancelledSuccessModal2}
            onClose={handleOnCancelSuccessClose2}
            message={"Request Declined"}
          />
        </TransitionScale>
      </AnimatePresence>
      <AnimatePresence>
        <TransitionScale>
          <PendingApprovalDeclineModal
            isOpen={ongoingCancelBidModal2}
            onClose={() => setOngoingCancelBidModal2(false)}
            handleYes={handleOnCancelBidTrue2}
            message={" Are you sure you would like to decline this"}
            midMessage={"workflow?"}
          />
        </TransitionScale>
      </AnimatePresence>
      <AnimatePresence>
        <TransitionScale>
          {reasonforDecline && (
            <ReasonForDeclineModal
              onClose={() => setReasonForDecline(false)}
              onContinue={handleDeclineContinue}
            />
          )}
        </TransitionScale>
      </AnimatePresence>

      <DashboardLayout
        section="Workflow "
        subHeader="Manage all your workflow approvals"
      >
        <header className="bg-white text-primaryColor px-6  rounded-[5px] ">
          <span
            onClick={() => navigate("/work-flow-management/pendingrequests")}
            className="flex cursor-pointer py-4 w-fit font-light items-center text-sm gap-2"
          >
            <PAIconArrowLeftGreen />
            Go Back
          </span>
        </header>

        <TransitionScale className="px-6 pt-6 pb-8 bg-white  rounded-[10px] mt-5 ">
          <div className="lg:flex md:flex justify-between  w-full sm:block  ">
            <div>
              {" "}
              <div class="text-[#11192a] text-xs font-bold ">
                Approval Stage
              </div>
            </div>

            {/* <div>
                {" "}
                <div class="h-[30px] px-6 py-2 bg-[#de1515]/30 rounded-[5px] justify-center items-center gap-2.5 inline-flex">
                  <div class="text-center text-[#de1515] text-xs font-semibold">
                    Declined
                  </div>
                </div>
              </div> */}

            <div className=" flex gap-3">
              {/* <button
                  className={` w-24   py-2 px-3  text-[#222222] justify-center  flex items-center bg-[#D6D6D6] rounded-[5px]  text-sm ${"cursor-pointer"}`}
                  // onClick={() => setOngoingCancelBidModal(true)}
                  //   onClick={() => setOngoingCancelBidModal2(true)}
                  // disabled={termsIsVisible ? false : true}
                >
                  Confirm
                </button> */}
              <button
                className={` w-24   py-2 px-3  text-[#de1515]  border border-[#de1515] justify-center  flex items-center bg-white rounded-[5px]  text-sm ${"cursor-pointer"}`}
                //   class=" px-6 py-2 bg-white rounded-[5px] border border-[#de1515] justify-center items-center gap-2.5 inline-flex"
                onClick={() => setOngoingCancelBidModal2(true)}
              >
                <div>Decline</div>
              </button>
              <button
                className={`w-24  px-3 text-white justify-center  flex items-center bg-[#07593d] rounded-[5px] py-2 text-sm ${"cursor-pointer"}`}
                //   onClick={() =>
                //     navigate("/invoice/invoice/fundingSection/invoiceupload")
                //   }
                onClick={() => setOngoingCancelBidModal(true)}
              >
                Approve
              </button>
            </div>
          </div>

          <div className="lg:flex md:flex justify-between  w-full sm:block  mt-3 ">
            {/* underlined div */}
            <div className="">
              <div>
                <span className="text-[#7a7a7a] text-xs font-bold ">User</span>
                <span className="text-[#7a7a7a] text-xs font-normal ">
                  {" "}
                  to approve
                </span>
              </div>
              <div className="mb-[15px] mt-[10px] flex justify-center items-center gap-x-[15px] ">
                <div>
                  <div className="w-[100px] h-1   bg-[#F08000]  rounded-[100px] lg:w-[150px]  xs:w-[70px]"></div>
                </div>
                <div>
                  <div className="w-[100px] h-1    bg-[#F08000]/30  rounded-[100px] lg:w-[150px]  xs:w-[70px]"></div>
                </div>
                <div>
                  <div className="w-[100px] h-1  bg-[#F08000]/30  rounded-[100px] lg:w-[150px] xs:w-[70px] "></div>
                </div>
              </div>
            </div>

            {/* Button division */}

            <div>
              {" "}
              {/* <div class="h-[30px] px-6 py-2 bg-[#07593d]/30 rounded-[5px] justify-center items-center gap-2.5 inline-flex">
                  <div class="text-center text-[#07593d] text-xs font-semibold">
                    Completed
                  </div>
                </div> */}
            </div>
          </div>

          {/* Second Box phase and pages */}
          <div className="lg:flex md:flex justify-between  w-full sm:block  mt-6 ">
            <div>
              <div class=" flex-col justify-center items-start gap-2 inline-flex">
                <div class="self-stretch justify-between items-center inline-flex gap-28">
                  <div class="text-[#222222] text-[14px] font-semibold">
                    Date Sent:
                  </div>
                  <div class="text-[#7a7a7a] text-[14px] font-medium ">
                    2023-02-19
                  </div>
                </div>
                <div class="self-stretch justify-between items-center inline-flex gap-28">
                  <div class="text-[#222222] text-[14px] font-semibold">
                    Transaction Type:
                  </div>
                  <div class="text-[#7a7a7a] text-[14px] font-medium ">
                    Early Payment Request
                  </div>
                </div>
                <div class="self-stretch justify-between items-center inline-flex  gap-28">
                  <div class="text-[#222222] text-[14px] font-semibold">
                    Action:
                  </div>
                  <div class="text-[#7a7a7a] text-[14px] font-medium ">
                    Funding Approval
                  </div>
                </div>
                <div class="self-stretch justify-between items-center inline-flex">
                  <div class="text-[#222222] text-[14px] font-semibold">
                    Approval Stage:
                  </div>
                  <div class="text-[#7a7a7a] text-[14px] font-medium ">
                    1 of 3
                  </div>
                </div>
              </div>
            </div>

            {/* Second side */}

            <div>
              <div class=" flex-col justify-center items-start gap-2 inline-flex">
                <div class="self-stretch justify-between items-center inline-flex gap-28">
                  <div class="text-[#222222] text-[14px] font-semibold">
                    Sender:
                  </div>
                  <div class="text-[#7a7a7a] text-[14px] font-medium ">
                    First User
                  </div>
                </div>
                <div class="self-stretch justify-between items-center inline-flex gap-28">
                  <div class="text-[#222222] text-[14px] font-semibold">
                    Previous Approver:
                  </div>
                  <div class="text-[#7a7a7a] text-[14px] font-medium ">
                    Admin
                  </div>
                </div>
                <div class="self-stretch justify-between items-center inline-flex  gap-28">
                  <div class="text-[#222222] text-[14px] font-semibold">
                    Next Approver:
                  </div>
                  <div class="text-[#7a7a7a] text-[14px] font-medium ">
                    Sam Johnson
                  </div>
                </div>
              </div>
              {/* Button */}
            </div>
          </div>
        </TransitionScale>

        <div className="grid lg:grid-cols-[60%,40%] gap-5">
          <TransitionScale className=" pb-5 bg-white  rounded-[3px] mt-5 pt-2">
            {/* Header with Left Padding */}

            {/* Other sections */}

            <section className="  mt-3">
              {/* Down Flex Texts */}
              <div className=" flex justify-between px-6">
                {/* first stage */}

                <div class="lg:w-[240px]  sm:w-full h-[117px] flex-col justify-start items-start gap-3 inline-flex">
                  <div class="self-stretch justify-between items-center inline-flex mt-2">
                    <div class="text-[#222222] text-base font-semibold  ">
                      Reference No: |RF83018374BA
                    </div>
                  </div>
                  <div class="self-stretch  flex-col justify-center items-start gap-1 flex mt-2">
                    <div class="self-stretch justify-between items-center inline-flex ">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Request Date:{" "}
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        23-Mar -2023
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Total Request amount
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        24,000,000
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Total Invoice amount
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        34,000,000
                      </div>
                    </div>
                  </div>

                  {/* stop */}
                </div>
                {/* second stage */}
                <div className="flex justify-between items-center mt-10 ">
                  {" "}
                  <div class="h-[37px] p-2.5 rounded-[5px] border border-[#f08000]/30 justify-center items-center gap-2.5 inline-flex">
                    <div class="text-[#f08000] text-sm font-medium ">
                      Pending Your Approval
                    </div>
                  </div>
                </div>
              </div>

              {/* stops here */}
              <div className="  px-6 mt-24">
                <header className="border-b-[5px] border-b-[#F6F5FA] mt-3  flex justify-between py-1">
                  {/* <h1 className="text-[18px] font-semibold">
                  Supplier/buyer details
                </h1> */}
                  <div>
                    <div class="text-[#222222] text-lg font-semibold  ">
                      Coca-Cola
                    </div>
                    <div class="text-[#7a7a7a] text-xs font-light  ">
                      Buyer details
                    </div>
                  </div>

                  <div class="h-[41px] flex-col justify-start items-start  inline-flex px-12">
                    <div class="text-[#222222] text-base font-semibold ">
                      60 Days
                    </div>
                    <div class="w-[100px] text-[#7a7a7a] text-xs font-light  mt-1">
                      Payment
                    </div>
                  </div>

                  <div class="h-[43px] flex-col justify-start items-end  inline-flex">
                    <div class="text-[#222222] text-lg font-semibold ">NGN</div>
                    <div class="text-[#7a7a7a] text-xs font-semibold mt-1">
                      Currency
                    </div>
                  </div>
                </header>

                <header className="border-b-[5px] border-b-[#F6F5FA] mt-6  flex justify-between py-1">
                  {/* <h1 className="text-[18px] font-semibold">
                  Supplier/buyer details
                </h1> */}

                  <div class="text-[#11192a] text-sm font-semibold ">
                    Invoice List
                  </div>
                  <div class="text-[#11192a] text-lg font-light  mr-6">
                    4 Invoices
                  </div>
                </header>

                <div className=" w-full overflow-x-auto mt-3 ">
                  <Table
                    columns={columns}
                    data={tableData}
                    pointer
                    className=""
                    onRowClicked={(row) => navigateToDetail(row.bidAmount)}
                    progressPending={false}
                    // progressComponent={<Loading />}
                  />
                </div>
                {/* Calculate cost and price  shaded or gray section */}
              </div>
            </section>
          </TransitionScale>
          {/* stops the best here */}

          <div className="bg-white rounded-[10px] gap-8  mt-5">
            {/* <FundDetailSide /> */}
            <div class="w-full justify-start items-center gap-4 inline-flex ">
              <div class=" justify-start items-center gap-2.5 flex w-full px-6  py-3">
                <div class="text-black text-xl font-bold  mt-5">
                  Early Payment Details
                </div>
              </div>
            </div>

            <div>
              {" "}
              {/* second */}
              <div className="   pb-4">
                <div class=" justify-between  flex flex-row    px-6 mt-4  ">
                  <div className="flex gap-2 py-2.5">
                    <PAIconCocacola />
                    <div className="flex flex-col">
                      {/* <span className=" text-black text-xs font-normal">
                        Coca Cola
                      </span> */}
                      <span class="text-black text-sm font-normal ">
                        Coca Cola PLC
                      </span>
                      {/* <span className="text-[#979797]  text-xs">Supplier</span> */}
                      <span class="text-[#949494] text-sm font-normal ">
                        Supplier
                      </span>
                    </div>
                  </div>
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">1.5%</div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Discount rate
                      </div>
                    </div>
                  </div>

                  {/* here */}
                </div>
                <div class=" justify-between  flex flex-row    px-6  mt-6 ">
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">N90,000</div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Discount amount
                      </div>
                    </div>
                  </div>
                  {/* hd */}
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        23-Dec-2024 (90 days)
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Early Payment Request
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        date
                      </div>
                    </div>
                  </div>{" "}
                </div>
                <div class=" justify-between  flex flex-row    border-b border-[#c2c2c2]/30 px-6  py-3  pb-12 mt-6">
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        N9,000,000
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Request amount
                      </div>
                    </div>
                  </div>
                  {/* dhdh */}
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        8,9110,000
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Payable Amount
                      </div>
                    </div>
                  </div>
                </div>
                {/* 2nd */}
                <div class=" justify-between  flex flex-col    border-b border-[#c2c2c2]/30 px-6  pb-10 ">
                  <div class="text-black text-lg font-semibold  mt-6">
                    Note from Supplier:{" "}
                  </div>

                  <div class=" text-[#949494] text-sm font-light  mt-4  leading-6">
                    Seeking Early payment due to unexpected challenges. Facing
                    temporary financial constraints and need a bit more time to
                    ensure a smooth transaction. Appreciate your understanding.
                    Thank you.
                    <br className=" mt-3" />
                    Best regards, <br />
                    ABC Limited
                  </div>
                </div>{" "}
                {/* another header */}
                {/* header ends here */}
                {/* here */}
                <div class=" justify-between  flex flex-col    px-6  ">
                  <div class="text-black text-lg font-semibold  mt-6">
                    Funding Option
                  </div>

                  <div class=" text-[#949494] text-sm font-light  mt-4  leading-6">
                    This invoice would be funded from this virtual account
                  </div>

                  {/* stops */}

                  <div className=" mt-3">
                    <div className="text-[#7a7a7a]   font-medium mt-2  body text-[16px] ">
                      Benjamin James
                    </div>
                    <div className="text-[#7a7a7a]  font-medium  mt-2  body text-[16px] ">
                      7364738921
                    </div>
                    <div className="text-[#7a7a7a]  font-medium  mt-2  body text-[16px] ">
                      PayEdge MFB
                    </div>
                  </div>
                </div>
              </div>
              {/* stops here */}
              {/* Third */}
              {/* third stops */}
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};

export default ApprovalViaWalletScreen;
