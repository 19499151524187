export function formatCapitalize(sentence) {
  if (typeof sentence !== "string") {
    sentence = "N/A";
    return sentence;
  }

  return sentence
    ?.split(" ") // Split the sentence into words
    ?.map((word) => {
      return word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase();
    })
    ?.join(" "); // Join the words back into a sentence
}
