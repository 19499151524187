import Table from "layout/table/Table";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "shared/Card";
import Loading from "shared/Loading";
import Pagination from "shared/Pagination";
import AccountModalContainer from "shared/accountModal/Modal";
import Heading from "shared/heading/Heading";
import {
  allEarlyPayments,
  updateEarlyPaymentsData,
} from "appstate/buyer/buyerSlice";
import { getInvoiceForApprove } from "appstate/invoice/invoiceSlice";
import { useNavigate } from "react-router-dom";
import { connectToWebSocket } from "appstate/api/invoice/invoice";

function All() {
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [companyName, setCompanyName] = useState("");

  const { userDetails } = useSelector((state) => state?.auth);

  // console.log(userDetails?.data?.userId)
  // console.log(userDetails);

  const { allEarlyPAymentsData, isLoading } = useSelector(
    (state) => state?.buyer
  );

  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(10);
  const pageSize = 10;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSearch = () => {
    dispatch(allEarlyPayments({ page, pageSize, search: companyName }));
  };

  useEffect(() => {
    dispatch(allEarlyPayments({ page, pageSize, search: companyName }));
  }, [dispatch, page, pageSize, companyName]);

  // WebSocket connection
  // useEffect(() => {
  //   // const ws = connectToWebSocket((incomingData) => {
  //   //   dispatch(allEarlyPayments({ page, pageSize, search: companyName }));
  //   //   console.log("Incoming Data: ", incomingData);
  //   // });
  //   const recipient_Id = userDetails?.data?.userId;
  //   // const sender_ids = userDetails?.data?.supplierIds.join(",");
  //   const sender_ids = userDetails?.data?.supplierIds;

  //   console.log(sender_ids)

  //   // sender_ids.forEach((id) => {
  //   //   console.log("Supplier ID:", id);
  //   // });

  //   const ws = connectToWebSocket({
  //     recipient_Id,
  //     sender_ids,
  //     onMessage: (incomingData) => {
  //       dispatch(allEarlyPayments({ page, pageSize, search: companyName }));
  //       console.log("Incoming Data:", incomingData);
  //     },
  //   });

  //   // Cleanup function
  //   return () => {
  //     ws.close();
  //   };
  // }, [dispatch, page, pageSize, companyName]);
  useEffect(() => {
    const recipient_Id = userDetails?.data?.userId;
    const sender_ids = userDetails?.data?.supplierIds || [];

    const ws = sender_ids?.map((senderId) => {
      console.log("Creating WebSocket for sender ID:", senderId);
      return connectToWebSocket({
        recipient_Id,
        sender_ids: senderId,
        onMessage: (incomingData) => {
          console.log("Incoming Data:", incomingData);
          dispatch(
            updateEarlyPaymentsData({ page, pageSize, search: companyName })
          );
        },
      });
    });

    return () => {
      ws.forEach((ws) => ws.close());
    };
  }, [dispatch, page, pageSize, companyName, userDetails]);

    const formatAmount = (amount) => {
    if (!amount) return "0.00";
    const parsedAmount = parseFloat(amount);
    if (isNaN(parsedAmount)) return "0.00";
    return parsedAmount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  // const InvoiceDetails = (id) => {
  //   let path = `/invoice/invoice/approve/${id}`;
  //   navigate(path);
  // };

  const InvoiceDetails = (id, status) => {
    // let path = "/collections/recievables";
    let path = "";

    switch (status) {
      case "APPROVED":
        path = `/invoice/invoice/approve/${id}`;
        break;
      case "PARTIAL_PAYMENT":
        path = `/invoice/invoice/partial-payment/${id}`;
        break;
      case "PAID":
        path = `/invoice/invoice/paid/${id}`;
        break;
      case "PENDING_WORKFLOW":
        path = `/invoice/invoice/pending-workflow/${id}`;
        break;
      case "PENDING_APPROVAL":
        path = `/invoice/invoice/pending-approval/${id}`;
        break;
      case "ACTIVE":
        path = `/invoice/invoice/active/${id}`;
        break;
      default:
        break;
    }

    navigate(path);
  };

  const columns = [
    { name: "Invoice Reference", selector: (row) => row.invoiceRef },
    { name: "Company", selector: (row) => row.company },
    { name: "Invoice Date", selector: (row) => row.invoiceDate },
    { name: "Due Date", selector: (row) => row.dueDate },
    { name: "Currency", selector: (row) => row.currency },
    {
      name: "Grand Total",
      selector: (row) => row.grandTotal,
      cell: (row) => (
        <p className="text-success">{formatAmount(row.grandTotal)}</p>
      ),
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => {
        const successStatuses = ["APPROVED", "PAID", "FULLY_PAID"];
        const rejectedStatuses = ["PARTIALLY_PAID", "REJECTED"];
        const awaitingStatuses = [
          "PENDING_APPROVAL",
          "PARTIAL_PAYMENT",
          "ACTIVE",
        ];
        return successStatuses.includes(row.status) ? (
          <p className="text-success">{row.status}</p>
        ) : rejectedStatuses.includes(row.status) ? (
          <p className="text-red">{row.status}</p>
        ) : awaitingStatuses.includes(row.status) ? (
          <p className="text-[#F08000] text-xs">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        );
      },
    },
  ];

  const totalPages = allEarlyPAymentsData?.invoices?.meta?.totalElements;

  const handleRowSelect = (row) => {
    const checkedRows = row.selectedRows.map((curr) => curr.id);
    dispatch(getInvoiceForApprove(checkedRows.toString()));
  };

  return (
    <>
      <AccountModalContainer
        accontModal
        isShown={open}
        onClose={() => {
          setOpen(false);
          setSuccess(false);
        }}
        success={success}
        warning="Are you sure you would like to approve this/these invoice upload?"
      />
      <Card className="rounded-lg">
        <Heading
          setCompanyName={setCompanyName}
          heading={!isLoading && `${totalPages ? totalPages : 0} Invoices`}
          companyName={companyName}
          search={handleSearch}
          invoice
        />
        <div className="rounded-b-lg px-[24px] pb-[24px] min-h-[70vh]">
          <Table
            columns={columns}
            data={allEarlyPAymentsData?.invoices?.data}
            onRowClicked={(row) => InvoiceDetails(row.id, row.status)}
            pointer
            progressPending={isLoading}
            handleRowSelect={handleRowSelect}
            progressComponent={<Loading />}
            tableHeader
            className="mt-5"
          />
          <div className="flex justify-center gap-x-[8px] mt-[20px]">
            {!isLoading &&
              allEarlyPAymentsData?.invoices?.data?.length >= 10 && (
                <Pagination
                  page={page}
                  startPage={startPage}
                  setStartPage={setStartPage}
                  endPage={endPage}
                  setEndPage={setEndPage}
                  setPage={setPage}
                  totalPages={totalPages}
                />
              )}
          </div>
        </div>
      </Card>
    </>
  );
}

export default All;
