import React, { useEffect, useState } from "react";
import Table from "layout/table/Table";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";
import { useNavigate } from "react-router-dom";
import Pagination from "shared/Pagination";
import { useDispatch, useSelector } from "react-redux";
import {
  allEarlyPaymentRequestBulk,
  getInvoiceId,
} from "appstate/buyer/buyerSlice";
import Loading from "shared/Loading";

const NewBulkEarlyPayment = ({ activeTabType }) => {
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const dispatch = useDispatch();
  // const pageSize = 10;
  const [data, setData] = useState([]);
  const status = "APPROVED";
  const navigate = useNavigate();
  const { allEarlyPaymentRequestBulkData, isLoading } = useSelector(
    (state) => state?.buyer
  );

  const onRowClicked = (row) => {
    dispatch(getInvoiceId(row.earlyPaymentId));
    let path = "/payablefinancing/invoice/bulkearlypaymentdetails/approved";
    navigate(path);
  };

  useEffect(() => {
    dispatch(allEarlyPaymentRequestBulk({ status }));
  }, [dispatch, status]);

  useEffect(() => {
    setData(
      allEarlyPaymentRequestBulkData?.data?.buyerBulkEarlyPaymentResponse
    );
  }, [allEarlyPaymentRequestBulkData]);

  useEffect(() => {
    if (activeTabType === "Via Wallet") {
      setData((prevData) =>
        prevData.filter((item) => item.fundingChannel === "WALLET")
      );
    }
    if (activeTabType === "Direct Funding") {
      setData((prevData) =>
        prevData.filter((item) => item.fundingChannel === "DIRECT_FUNDING")
      );
    }
    if (activeTabType === "Closed Market") {
      setData((prevData) =>
        prevData.filter((item) => item.fundingChannel === "CLOSED_MARKET")
      );
    }

    if (activeTabType === "All Funding Options") {
      setData(
        allEarlyPaymentRequestBulkData?.data?.buyerBulkEarlyPaymentResponse
      );
    }
  }, [
    activeTabType,
    allEarlyPaymentRequestBulkData?.data?.buyerBulkEarlyPaymentResponse,
  ]);

  const columns = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Request Ref No
        </span>
      ),
      selector: (row) => row.requestReferenceNumber,
      sortable: true,
    },

    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Supplier
        </span>
      ),
      selector: (row) => row.supplierCompanyName,
      sortable: true,
    },
    {
      name: "Request Date",
      selector: (row) => row.requestDate,
      sortable: true,
    },
    {
      name: "No. of Invoices",
      selector: (row) => row.noOfInvoices,
      sortable: true,
      // width: "120px",
      // width: "120px",
    },

    {
      name: "Total Request Amount",
      selector: (row) => row.totalRequestAmount,
      sortable: true,
      cell: (row) => <p className="text-success">{row.totalRequestAmount}</p>,
    },
    {
      name: "Total Discount Amount",
      selector: (row) => row.totalDiscountAmount,
      sortable: true,
      cell: (row) => (
        <p className="text-yellow-600">{row.totalDiscountAmount}</p>
      ),
    },
    {
      name: "Total Payable Amount",
      selector: (row) => row.totalDiscountAmount,
      sortable: true,
    },
    {
      name: "Funding Channel",
      selector: (row) => row.fundingChannel,
      sortable: true,
    },
  ];

  const totalPages = allEarlyPaymentRequestBulkData?.meta?.totalElements;
  return (
    <>
      {!data?.length ? (
        <div>
          <div className="flex justify-between items-center px-[15px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
            <p className="text-black text-sm font-medium">
              New Early Payment Request New Early Payment Request
            </p>
          </div>
          <TableEmptyState dashboard />
        </div>
      ) : (
        <Card className="rounded-[10px] mt-[15px]">
          <div className="px[15px] pb-[24px]">
            <Table
              columns={columns}
              data={data}
              onRowClicked={onRowClicked}
              progressPending={isLoading}
              progressComponent={<Loading />}
              pointer
              tableHeader
              className="mt-5"
            />
          </div>
          <div className="flex justify-center gap-x-[8px] mt-[20px]">
            {!isLoading && totalPages > 10 && (
              <Pagination
                page={page}
                startPage={startPage}
                setEndPage={setEndPage}
                endPage={endPage}
                setStartPage={setStartPage}
                setPage={setPage}
                totalPages={totalPages}
              />
            )}
          </div>
          <div className="flex justify-center gap-x-[8px] mt-[20px]">
            {!isLoading && totalPages > 10 && (
              <Pagination
                page={page}
                startPage={startPage}
                setEndPage={setEndPage}
                endPage={endPage}
                setStartPage={setStartPage}
                setPage={setPage}
                totalPages={totalPages}
              />
            )}
          </div>
        </Card>
      )}
    </>
  );
};

export default NewBulkEarlyPayment;
