import Table from "layout/table/Table";
import React, { useState, useEffect } from "react";
import Button from "shared/Button";
import { useSelector, useDispatch } from "react-redux";
import { creatNewPfp } from "appstate/buyer/buyerSlice";
import ConfirmationModal from "../components/Modal/ConfirmModal";

function Preview({ handlePreviousPage, formData, reverse, selectedAccounts }) {
  const [data, setData] = useState([]);
  const { offerInvoices } = useSelector((state) => state.buyer);
  const { allVirtualAccountData } = useSelector((state) => state.wallet);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [totalOffer, setTotalOffer] = useState(0);
  const [totalPayable, setTotalPayable] = useState(0);
  const [dynamicConfirmModal, setDynamicConfirmModal] = useState(false);
  const [reverseConfirmModal, setReverseConfirmModal] = useState(false);
  const dispatch = useDispatch();
  // useEffect(() => {
  //   setData(offerInvoices?.data);
  // }, [offerInvoices]);

  useEffect(() => {
    if (offerInvoices?.data && formData.invoiceIds) {
      // Filter the data to only include items with matching invoiceIds
      const filteredData = offerInvoices.data.filter((invoice) =>
        formData.invoiceIds.includes(invoice.invoiceId)
      );
      setData(filteredData);

      const totalAmount = filteredData.reduce((sum, invoice) => {
        return sum + (invoice.discountAmount || 0); // Assuming discountAmount is the field name
      }, 0);

      setTotalDiscount(parseFloat(totalAmount.toFixed(1)));

      const totalOfferAmount = filteredData.reduce((sum, invoice) => {
        return sum + (invoice.offerAmount || 0); // Assuming discountAmount is the field name
      }, 0);

      setTotalOffer(parseFloat(totalOfferAmount.toFixed(1)));

      const totalPayableAmount = filteredData.reduce((sum, invoice) => {
        return sum + (invoice.payableAmount || 0); // Assuming discountAmount is the field name
      }, 0);

      setTotalPayable(parseFloat(totalPayableAmount.toFixed(1)));
    } else {
      // If there's no data or invoiceIds, you might want to set data to an empty array
      setData([]);
      setTotalDiscount(0);
    }
  }, [offerInvoices, formData.invoiceIds]);

  const industriesText = formData?.industries?.join(", ") || "";

  const column = [
    {
      name: "Invoice Number",
      selector: (row) => `INV-${row?.invoiceNo}`,
      sortable: true,
    },
    {
      name: "Supplier",
      selector: (row) => row?.supplierName,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row?.currency,
      sortable: true,
    },
    {
      name: "Due Date",
      selector: (row) => row?.dueDate,
      sortable: true,
    },
    {
      name: "Payment Date",
      selector: (row) => row?.paymentDate,
      sortable: true,
    },
    {
      name: "Invoiced Amount",
      selector: (row) => row?.invoiceAmount?.toLocaleString(),
      sortable: true,
    },
    {
      name: "Offer Amount",
      selector: (row) => row?.offerAmount?.toLocaleString(),
      sortable: true,
    },
    {
      name: "Discount Amount",
      selector: (row) => row?.discountAmount?.toLocaleString(),
      sortable: true,
    },
    {
      name: "Payable Amt",
      selector: (row) => row?.payableAmount?.toLocaleString(),
      sortable: true,
    },
  ];

  const handleProceed = () => {
    setReverseConfirmModal(true);
    setDynamicConfirmModal(true);
  };

  const handleCreatePfp = () => {
    const body = {
      programName: formData.programName || "",
      financingOption: formData.financingOptions || "",
      startDate: formData.startDate || "",
      endDate: formData.endDate || "",
      tierCategory: formData.tiers || [],
      discountRate: parseFloat(formData.discount) || 0,
      minimumAmount: parseFloat(formData.minAmount) || 0,
      maximumAmount: parseFloat(formData.maxAmount) || 0,
      funderPercentage:
        reverse === true ? parseFloat(100 - formData.buyerShare) || 0 : 0,
      buyerPercentage: parseFloat(formData.buyerShare) || 0,
      industryTypes: formData.industries || [],
      paymentDuration: parseInt(formData.paymentDuration) || 0,
      invoiceIds: formData.invoiceIds || [],
      documentId: formData.agreemntDocument || "",
      funderIds: formData.funderIds || [],
      fundingAccountId: formData.accounts || "",
      offerPercentage: parseFloat(formData.percentage) || 0,
    };
    dispatch(creatNewPfp(body));
  };

  return (
    <div className="mx-[3.5rem]">
      <ConfirmationModal
        isOpen={reverseConfirmModal || dynamicConfirmModal}
        onCloseReverse={() => {
          setReverseConfirmModal(false);
        }}
        onCloseDynamic={() => {
          setDynamicConfirmModal(false);
        }}
        onConfirm={handleCreatePfp}
        selectedAccounts={selectedAccounts}
        accountName={allVirtualAccountData?.data?.map(
          (name) => name.accountName
        )}
        amount={totalPayable}
      />
      <div className="flex gap-x-[20rem]">
        <div>
          <div className="mb-[16px]">
            <p className="text-lightGray text-sm">Name of Program</p>
            <p className="text-textColor text-sm">{formData?.programName}</p>
          </div>
          <div className="mb-[16px]">
            <p className="text-lightGray text-sm">Financing Option</p>
            <p className="text-textColor text-sm">
              {formData?.financingOptions}
            </p>
          </div>
          <div className="mb-[16px]">
            <p className="text-lightGray text-sm">Duration </p>
            <p className="text-textColor text-sm">
              <span className="text-[#BFBFBF] font-bold">From:</span>{" "}
              {formData?.startDate}
              <span className="text-[#BFBFBF] font-bold ml-[5px]">
                To:
              </span>{" "}
              {formData?.endDate}
            </p>
          </div>
          <div className="mb-[16px]">
            <p className="text-lightGray text-sm">Industry Selection</p>
            <p className="text-textColor text-sm">{industriesText}</p>
          </div>
        </div>

        <div>
          <div className="mb-[16px]">
            <p className="text-lightGray text-sm">Amount Range</p>
            <p className="text-textColor text-sm">
              Min: NGN {formData?.minAmount}
            </p>
            <p className="text-textColor text-sm">
              Max: NGN {formData?.maxAmount}
            </p>
          </div>
          <div className="mb-[16px]">
            <p className="text-lightGray text-sm">Discount Rate</p>
            <p className="text-textColor text-sm">{formData?.discount}%</p>
          </div>
          <div className="mb-[16px]">
            <p className="text-lightGray text-sm">Payment Period</p>
            <p className="text-textColor text-sm">
              {formData?.paymentDuration} Days
            </p>
          </div>
          <div className="mb-[16px]">
            <p className="text-lightGray text-sm">Offer Percentage</p>
            <p className="text-textColor text-sm">
              {formData?.percentage}%
            </p>
          </div>
        </div>
      </div>
      <div className="flex gap-x-[5px] items-center mt-[32px]">
        <p className="text-textColor text-sm mt-[8px]">
          Select invoices for the campaign
        </p>
        <div className="bg-[#C9D9D3] py-[4px] px-[6px] rounded-[100%] text-textColor text-xs flex justify-center items-center relative top-[5px]">
          {data?.length}
        </div>
      </div>
      <div className="border border-brightGray rounded-[0.5rem] px-[1.69rem] pb-[1.69rem] mt-[8px]">
        <Table
          columns={column}
          data={data}
          pointer
          tableHeader
          className="mt-5"
        />
      </div>
      <div className="flex gap-x-[2.5rem] mt-[1.75rem]">
        <div className="flex gap-x-[5px] items-center">
          <p className="text-lightGray text-[0.875rem]">
            Total Discounted Amount:
          </p>
          <h1 className="text-primaryColor text-[1.125rem] font-bold">
            NGN {totalDiscount?.toLocaleString() || 0}
          </h1>
        </div>
        <div className="flex gap-x-[5px] items-center">
          <p className="text-lightGray text-[0.875rem]">
            Total Payable Amount:
          </p>
          <h1 className="text-primaryColor text-[1.125rem] font-bold">
            NGN {totalPayable?.toLocaleString() || 0}
          </h1>
        </div>

        {formData?.financingOptions === "REVERSE_FACTORING" && (
          <div className="flex gap-3 items-center">
            <div className="flex gap-x-[5px] items-center">
              <p className="text-lightGray text-[0.875rem]">
                Total Offer Amount:
              </p>
              <h1 className="text-primaryColor text-[1.125rem] font-bold">
                NGN {totalOffer?.toLocaleString() || 0}
              </h1>
            </div>

            <div className="flex gap-x-[5px] items-center">
              <p className="text-lightGray text-[0.875rem]">
                Total Buyier Yield:
              </p>
              <h1 className="text-primaryColor text-[1.125rem] font-bold">
                NGN 920,000
              </h1>
            </div>
          </div>
        )}
      </div>
      <div className="flex justify-between mt-[1.88rem]">
        <Button
          neutral
          className=" py-[0.75rem] px-[1.5rem] !text-lightGray !text-[0.875rem] bg-alabasterHeader rounded-[0.25rem]"
          onClick={handlePreviousPage}
        >
          Previous
        </Button>

        <Button
          neutral
          className=" py-[0.75rem] px-[1.5rem] !text-white !text-[0.875rem] bg-primaryColor rounded-[0.25rem]"
          onClick={handleProceed}
        >
          Create
        </Button>
      </div>
    </div>
  );
}

export default Preview;
