import React from "react";
import {
  PAIconCancelMini,
  PASuccessWave,
  PASuccessIcon,
} from "assets/images/svgs";
import { PAVerifiedAccount } from "assets/images/svgs";

const AccountVerifyAndSuccess = ({
  isOpen,
  onClose,
  isLoading,
  isApproved,
}) => {
  if (!isOpen) return null;

  return (
    <>
      <div className="bg-[rgba(0,0,0,0.2)] w-[100%] h-[100vh] flex justify-center items-center absolute left-0 top-0">
        {isApproved ? (
          <div className="bg-white p10 px5 rounded-md max-w-xs md:max-w-lg w-full px10 flex h-[200px]">
            <div className="flex items-center justify-center bg-[#F08000] w-[35%] rounded-[6px]">
              <PASuccessIcon />
            </div>
            <div className="flex items-center justify-center flex-col">
              <PASuccessWave />
              <p className="px-[5%] text-center font-regular text-[18px] mt-3">
                Early payment request sent for workflow approval
              </p>
            </div>
          </div>
        ) : (
          <div className="bg-white p10 px5 rounded-md max-w-xs md:max-w-lg w-full pb-5 px10">
            <div className="flex justify-between items-center py-5 px-6 rounded-t-[10px] bg-[#FAFAFA]">
              <p className="text-sm text-[#7A7A7A] font-thin">
                Fund Early Payment
              </p>
              <button
                onClick={onClose}
                className="gap-2 text-xl cursor-pointer flex justify-center items-center"
              >
                <PAIconCancelMini />{" "}
                <span className="text-sm text-[#565656] flex items-center">
                  Close
                </span>
              </button>
            </div>

            {isLoading === true ? (
              <div className="flex flex-col items-center justify-center h-[200px]">
                <p>Verifying if account is sufficient for liening</p>
                <div className="loader"></div>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center h-[200px]">
                <PAVerifiedAccount />
                <p>Account Verified</p>
              </div>
            )}
          </div>
        )}
      </div>

      {/* Add this CSS to your CSS file or in a <style> tag */}
      <style jsx>{`
        .loader {
          border: 4px solid #f3f3f3; /* Light grey */
          border-top: 8px solid #f08000; /* Blue */
          border-radius: 50%;
          width: 40px; /* Size of the loader */
          height: 40px; /* Size of the loader */
          animation: spin 1s linear infinite;
          margin: 10px auto; /* Center the loader */
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      `}</style>
    </>
  );
};

export default AccountVerifyAndSuccess;
