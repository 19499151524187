import {
  PAIconArrowLeftGreen,
  PAIconModelOne,
  PAIconEditPenYellow,
} from "assets/images/svgs";
import React, { useState, useEffect } from "react";
import AcceptModal from "./acceptModal";
import RejectModal from "./rejectModal";
import FundingOptionsModal from "features/invoice/buyerInvoice/Modals/FundingOptionsModal";
import TransferFundsSetupModal from "features/invoice/buyerInvoice/Modals/TransferFundsSetupModal";
import SelectAccountModal from "features/invoice/buyerInvoice/Modals/SelectAccountModal";
import EnterPin from "features/invoice/buyerInvoice/Modals/EnterPin";
import approved from "../../../../../assets/images/svgs/approved.svg";
import RejectReason from "features/invoice/buyerInvoice/Modals/RejectReason";
import RejectionSuccess from "features/invoice/buyerInvoice/Modals/RejectionSuccess";
import ThirdPartyModal from "features/BuyerEarlyPayment/EarlyPayment/singleEarlyPayment/Modals/ThirdPartyModal";
import AccountVerifyAndSuccess from "features/invoice/buyerInvoice/Modals/AccountVerifyAndSuccess";
import { useNavigate } from "react-router-dom";
import SelectFunderClosedMarket from "features/invoice/buyerInvoice/Modals/SelectFunderClosedMarket";
import { useDispatch, useSelector } from "react-redux";
import { pendingBulkEarlyPaymentDetails } from "appstate/buyer/buyerSlice";

const Details = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRejectModalOpen, setRejectModalOpen] = useState(false);
  const [isSelectAccountModalOpen, setSelectAccountModalOpen] = useState(false);
  const [isenterPinModal, setIsEnterPinModal] = useState(false);
  const [isVerifyAccountModal, setIsVerifyAccountModal] = useState(false);
  const [paymentViaWallet, setPaymentViaWallet] = useState(false);
  const [isRequestRejected, setIsRequestRejected] = useState(false);
  const [isRejectReasonModal, setIsRejectReasonModal] = useState(false);
  const [isRejectSuccessModal, setIsRejectSuccessModal] = useState(false);
  const [isThirdPartyModal, setIsThirdPartyModal] = useState(false);
  const [isFundingModalOpen, setIsFundingModalOpen] = useState(false);
  const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);
  const [fromBuyer, setFromBuyer] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [rejectedReason, setRejectedReason] = useState([]);
  const [checkedAccounts, setCheckedAccounts] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [fromThridParty, setFromThridParty] = useState(false);
  const [toClosedMarket, setToClosedMarket] = useState(false);
  const [isDiscountEdit, setIsDiscountEdit] = useState(false);
  const [closeMarketActive, setClosedMarketActive] = useState(false);
  const [selectedFunders, setSelectedFunders] = useState([]);
  const [isSelectClosedMarketFunder, setIsSelectClosedMarketFunder] =
    useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const status = "PENDING_APPROVAL";

  const { getInvoiceId, pendingBulkEarlyPaymentDetailsData } = useSelector(
    (state) => state?.buyer
  );
  // const { invoiceIdData } = useSelector((state) => state.invoice);
  const [data, setData] = useState([]);
  // const [invoiceData, setInvoiceData] = useState([]);

  useEffect(() => {
    const id = getInvoiceId;
    dispatch(pendingBulkEarlyPaymentDetails({ id, status }));
  }, [dispatch, getInvoiceId]);

  useEffect(() => {
    setData(pendingBulkEarlyPaymentDetailsData?.data);
  }, [pendingBulkEarlyPaymentDetailsData]);

  const handleAccept = () => {
    setIsFundingModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setRejectModalOpen(false);
  };

  const handleReject = () => {
    setRejectModalOpen(true);
  };

  //funding Request modal control
  const handleFundingRequest = () => {
    setIsFundingModalOpen(false);
    setSelectAccountModalOpen(true);
  };

  const handleFundingRequestClose = () => {
    setIsFundingModalOpen(false);
  };

  //Transfer modal control

  const handleTransferRequest = () => {
    setSelectAccountModalOpen(true);
    setIsTransferModalOpen(false);
  };

  const handleTransferModalClose = () => {
    setIsTransferModalOpen(false);
  };

  // Select account controls
  const handleSelectAccountClose = () => {
    setSelectAccountModalOpen(false);
  };

  const handleSelectAccountProceed = () => {
    setSelectAccountModalOpen(false);
    setIsModalOpen(true);
  };

  //Enter pin controls
  const handleEnterPin = () => {
    setIsEnterPinModal(true);
    setIsModalOpen(false);
  };

  const handleEnterPinModalClose = () => {
    setIsEnterPinModal(false);
  };

  const handleVerifyAccountModal = () => {
    setIsVerifyAccountModal(true);
    setIsEnterPinModal(false);
    setIsLoading(true);
  };

  const handleVerifyAccountModalClose = () => {
    setIsVerifyAccountModal(false);
  };

  //Reject modal control
  const handleRejectRequest = () => {
    setRejectModalOpen(false);
    setIsRejectReasonModal(true);
  };
  const handleRejectReasonClose = () => {
    setIsRejectReasonModal(false);
  };

  //Success modal control
  const handleSuccessModal = () => {
    setIsRejectReasonModal(false);
    setIsRejectSuccessModal(true);
  };

  const handleSuccessModalClose = () => {
    setIsRejectSuccessModal(false);
  };

  //third party modal
  const handleThirdPartyModal = () => {
    setIsThirdPartyModal(true);
    setIsFundingModalOpen(false);
  };
  const handleThirdPartyModalClose = () => {
    setIsThirdPartyModal(false);
  };

  //Select closed market funder
  const handleClosedMarketFunderClose = () => {
    setIsSelectClosedMarketFunder(false);
  };

  useEffect(() => {
    if (isLoading === true) {
      setTimeout(() => {
        setIsLoading(false);
        setTimeout(() => {
          setIsApproved(true);
          setTimeout(() => {
            navigate("/payablefinancing/overview");
          }, 2000);
        }, 3000);
      }, 3000);
    }
  }, [isLoading, navigate]);

  // useEffect(() => {
  //   if (isLoading === false) {

  //   }
  // }, [isLoading]);

  // const handleAcceptRequest = () => {
  //   dispatch(
  //     approveOrDeclineEarlyPayment({
  //       id: getInvoiceId,
  //       comment: "",
  //       status: "APPROVED",
  //     })
  //   ).then((data) => {
  //     if (data?.payload?.success) {
  //       toast.custom(
  //         <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
  //           <div className="flex">
  //             <div className="bg-[#07593D] p-10 shadow-md rounded-s text-center text-white">
  //               <PAIconMDISuccess />
  //             </div>
  //             <div className="bg-white py-10 px-3 flex flex-col items-center justify-center shadow-md text-center rounded-e">
  //               <p className="text-[#959595] text-center font-sans text-xs font-normal leading-normal tracking-[0.321px] mb-3 mt-3">
  //                 The Early payment request has been approved!
  //               </p>
  //             </div>
  //           </div>
  //         </div>
  //       );
  //     }
  //   });
  // };

  return (
    <>
      <div
        onClick={() => {
          window.history.back();
        }}
        className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer w-full"
      >
        <PAIconArrowLeftGreen />
        <span className="flex items-center text-primaryColor">Go Back</span>
      </div>

      <div className="grid lg:grid-cols-[60%,40%] gap-5 h-[80vh]">
        {/* 60% */}
        <div className="flex flex-col gap-8 pb-24 bg-white rounded-[10px] pt-7">
          <div className="px-6 ">
            <div className="flex flex-col gap-3">
              <div className="flex items-center justify-between">
                {/* <h1 className="text-[18px] font-bold">
                  Reference No: | INV-{invoiceData?.invoiceNumber}
                </h1> */}
                <h1 className="text-[18px] font-bold">
                  Reference No: | {data?.requestReferenceNumber}
                </h1>
                {paymentViaWallet === true ? (
                  <button className="w-[135px] h-[45px] rounded-[5px] bg-[#07593D] text-[white]">
                    Disburse Now
                  </button>
                ) : null}
              </div>

              <main className=" w-full flex justify-between">
                <div className="flex flex-col gap-3 w-1/2">
                  <div className="flex flex-col gap-1">
                    <div className="flex gap-x-[30px]">
                      <span className=" text-xs font-semibold w-[45%]">
                        Request Date:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {data?.requestDate}
                      </span>
                    </div>
                    <div className="flex gap-x-[30px]">
                      <span className=" text-xs font-semibold w-[45%]">
                        Total Request Amount:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {data?.totalRequestAmount?.toLocaleString()}
                      </span>
                    </div>
                    <div className="flex gap-x-[30px]">
                      <span className=" text-xs font-semibold w-[45%]">
                        Total Invoice Amount:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {data?.totalInvoiceAmount}
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <button className="w-[180px] h-[40px] bg-[#FFE9C5] rounded-[8px] text-[#F08000] text-[14px]">
                    {data?.status}
                  </button>
                </div>
              </main>
            </div>

            <div className="flex justify-between my-5 border-b-[3px] border-slate-100 pb-2">
              <div className="flex flex-col">
                <span className=" font-bold text-sm">
                  {data?.buyerCompanyName}
                </span>
                <span className="text-[#979797] text-xs">Buyer Details</span>
              </div>
              <div className="flex flex-col">
                <span className="font-bold text-sm">60 Days</span>
                <span className="text-[#979797] text-xs">Payment</span>
              </div>
              <div className="flex flex-col">
                <span className="font-bold text-sm">NGN</span>
                <span className=" text-[#979797] text-xs">Currency</span>
              </div>
            </div>

            <div className="flex flex-col gap-4 mt-5">
              <header className="border-b-[5px] border-b-[#F6F5FA] pb-3 flex items-center justify-between">
                <h1 className="text-sm font-bold">Invoice List</h1>
                <h1 className="text-sm font-bold">
                  {data?.earlyPaymentInvoiceResponse?.length} Invoice(s)
                </h1>
              </header>
              <div className=" w-full overflow-x-auto h-[40vh]">
                <table className="overflow-x-auto w-[50vw]">
                  <thead className="bg-[#EFEFEF] h-[50px] w-[50vw] overflow-x-auto">
                    <tr className=" bg-unusualGray px-2.5 py-1">
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Ref Number
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Due Date
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Request Amount
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Discount Rate
                      </td>
                      <td className="text-[#11192A] p-2.5 text-xs font-bold w-[120px]">
                        Discount Amount
                      </td>
                    </tr>
                  </thead>
                  {data?.earlyPaymentInvoiceResponse?.map((item, index) => (
                    <tbody key={index}>
                      <tr className=" py-[3px] px-1.5">
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.invoiceRef}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5 w-[20%]">
                          {item?.description}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.requestAmount}
                        </td>
                        <td
                          onClick={() => {
                            setIsTransferModalOpen(true);
                            setIsDiscountEdit(true);
                          }}
                          className=" text-xs cursor-pointer text-[#11192A] p-2.5 flex items-center gap-1"
                        >
                          {item?.discountRate}%
                          <PAIconEditPenYellow />
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.discountedAmount}
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* 40% */}
        <div className="bg-white rounded-[10px] gap-8 px-6 pt-7 pb-24">
          <div>
            <h2 className="font-semibold text-lg leading-[30px] mb-4">
              Early Payment Details
            </h2>

            <div className="flex justify-between my-5">
              <div className="flex gap-2">
                <PAIconModelOne />
                <div className="flex flex-col">
                  <span className="text-lg">{data?.supplierCompanyName}</span>
                  <span className="text-sm text-[#959595]">Supplier</span>
                  {/* <span className="text-[#979797]  text-xs">
                    {" "}
                    {invoiceData?.supplierCompanyName}
                  </span> */}
                </div>
              </div>
            </div>

            <div className="flex justify-between pb-5">
              <div className="flex gap-2">
                <div className="flex flex-col">
                  <span className="text-sm">
                    N{data?.totalDiscountAmount || "0.00"}
                  </span>
                  <span className="text-[#979797]  text-xs">
                    Total Discount Amount
                  </span>
                </div>
              </div>
              <div className="flex flex-col items-end">
                <span className="text-sm">{data?.requestDate}</span>
                <span className="text-[#979797]  text-xs">
                  Early Payment Request Date
                </span>
              </div>
            </div>

            <div className="flex justify-between mt-5 border-b-[5px] border-b-[#F6F5FA] pb-10">
              <div className="flex gap-2">
                <div className="flex flex-col">
                  <span className="text-sm">
                    N{data?.totalRequestAmount?.toLocaleString() || "0.00"}
                  </span>
                  <span className="text-[#979797]  text-xs">
                    Total Request Amount
                  </span>
                </div>
              </div>
              <div className="flex flex-col items-end">
                <span className="text-sm">
                  {" "}
                  N{data?.totalPayableAmount?.toLocaleString() || "0.00"}
                </span>
                <span className="text-[#979797]  text-xs">
                  Total Payable amount
                </span>
              </div>
            </div>

            <div className="border-b-[5px] border-b-[#F6F5FA] pb-5">
              <h2 className="font-medium text-base my-3">
                Note from Supplier:
              </h2>
              <p className="text-[#959595] text-sm">{data?.description}</p>
            </div>

            {paymentViaWallet === true || isRequestRejected === true ? null : (
              <div className="pb-3">
                <h2 className="font-medium text-base leading-[30px] my-2">
                  Your decision:
                </h2>
                <p className="text-[#959595] text-sm">
                  Early Payment Request: Please review and take action. Accept
                  if approved, or Reject if not suitable. Thank you
                </p>
                <div className="flex gap-5 mt-5">
                  <button
                    className="rounded-md bg-[#07593D] text-white px-12 py-2"
                    onClick={handleAccept}
                  >
                    Accept
                  </button>
                  <button
                    className="rounded-md bg-white border border-[#F08000] text-[#F08000] px-12 py-2"
                    onClick={handleReject}
                  >
                    Reject
                  </button>
                </div>
              </div>
            )}

            {isRequestRejected === true ? (
              <div className=" pt-[3%]">
                <div className="border-b-[3px] border-[#EFEFEF] mb-[4%] pb-[3%]">
                  <h2 className="text-[16px] font-normal">
                    You rejected this invoice based on
                    {rejectedReason?.map((option) => (
                      <span
                        key={option.id}
                        className="rounded-md font-semibold ml-2"
                      >
                        {option.label}
                      </span>
                    ))}
                    <span className="ml-[3px]">reasons</span>
                  </h2>
                </div>
              </div>
            ) : null}

            {paymentViaWallet === true ? (
              <div className=" pt-[3%]">
                <div className="border-b-[3px] border-[#EFEFEF] mb-[4%] pb-[3%]">
                  <h2 className="text-[16px] font-semibold">Funding Option</h2>
                  <p>This invoice will be funded with this virtual account:</p>
                  <p>{selectedAccount.accountName}</p>
                  <p>{selectedAccount.accountNumber}</p>
                  <p>{selectedAccount.bankName}</p>
                </div>

                <div className="flex items-center justify-center mt-[3%]">
                  <img src={approved} alt="Approved" />
                </div>
              </div>
            ) : null}

            <FundingOptionsModal
              isOpen={isFundingModalOpen}
              onClose={handleFundingRequestClose}
              confirm={handleFundingRequest}
              thirdParty={handleThirdPartyModal}
              setFromBuyer={setFromBuyer}
            />
            <TransferFundsSetupModal
              isOpen={isTransferModalOpen}
              onClose={handleTransferModalClose}
              confirm={handleEnterPin}
              proceed={handleTransferRequest}
              selectedAccount={selectedAccount}
              fromBuyer={fromBuyer}
              fromThridParty={fromThridParty}
              toClosedMarket={toClosedMarket}
              setIsSelectClosedMarketFunder={setIsSelectClosedMarketFunder}
              setIsTransferModalOpen={setIsTransferModalOpen}
              isDiscountEdit={isDiscountEdit}
            />
            <SelectAccountModal
              isOpen={isSelectAccountModalOpen}
              onClose={handleSelectAccountClose}
              setSelectedAccount={setSelectedAccount}
              confirm={handleSelectAccountProceed}
              setCheckedAccounts={setCheckedAccounts}
              checkedAccounts={checkedAccounts}
            />
            <AcceptModal
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              confirm={handleEnterPin}
              checkedAccounts={checkedAccounts}
            />
            <EnterPin
              isOpen={isenterPinModal}
              onClose={handleEnterPinModalClose}
              confirm={handleVerifyAccountModal}
              data={data}
              // invoiceData={invoiceData}
              checkedAccounts={checkedAccounts}
              closeMarketActive={closeMarketActive}
              selectedFunders={selectedFunders}
            />
            <AccountVerifyAndSuccess
              isOpen={isVerifyAccountModal}
              onClose={handleVerifyAccountModalClose}
              setPaymentViaWallet={setPaymentViaWallet}
              isLoading={isLoading}
              isApproved={isApproved}
            />
            <RejectModal
              isOpen={isRejectModalOpen}
              onClose={handleCloseModal}
              confirm={handleRejectRequest}
            />
            <RejectReason
              isOpen={isRejectReasonModal}
              onClose={handleRejectReasonClose}
              confirm={handleSuccessModal}
              setIsRequestRejected={setIsRequestRejected}
              setRejectedReason={setRejectedReason}
            />
            <RejectionSuccess
              isOpen={isRejectSuccessModal}
              onClose={handleSuccessModalClose}
            />
            <ThirdPartyModal
              isOpen={isThirdPartyModal}
              onClose={handleThirdPartyModalClose}
              fromBuyer={fromBuyer}
              setFromThridParty={setFromThridParty}
              setToClosedMarket={setToClosedMarket}
              setSelectAccountModalOpen={setSelectAccountModalOpen}
              data={data}
              setIsSelectClosedMarketFunder={setIsSelectClosedMarketFunder}
            />
            <SelectFunderClosedMarket
              isOpen={isSelectClosedMarketFunder}
              onClose={handleClosedMarketFunderClose}
              setSelectAccountModalOpen={setSelectAccountModalOpen}
              setClosedMarketActive={setClosedMarketActive}
              setSelectedFunders={setSelectedFunders}
              selectedFunders={selectedFunders}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Details;
