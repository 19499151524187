import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { injectStore } from "apiInstance";
import authReducer from "appstate/auth/authSlice";
import inventoryReducer from "appstate/inventory/inventorySlice";
import invoiceReducer from "appstate/invoice/invoiceSlice";
import poReducer from "appstate/purchaseOrder/poSlice";
import settingsReducer from "appstate/settings/settingsSlice";
import supplierReducer from "appstate/supplier/supplierSlice";
import walletReducer from "appstate/wallet/walletSlice";
import buyerReducer from "appstate/buyer/buyerSlice";
import funderReducer from "appstate/funder/funderSlice";
import roleManagerReducer from "appstate/roleManager/roleManagerSlice";
import toolsAndAgreementReducer from "./toolsAndAgreement/toolsAndAgreementSlice";
import rulesAndConfigReducer from "./rulesandconfig/rulesandconfigSlice";
import earlyPaymentFormReducer from "features/RulesConfig/common/earlyPaymentFormSlice";
import notificationReducer from "appstate/notification/notificationSlice";
import openMarketReducer from "appstate/openMarket/openMarketSlice";
import lockInvoiceReducer from "features/openMarket/funder/lockInvoiceSlice";
import buttonReducer from "features/openMarket/funder/components/buttonSlice";
import collectionReducer from "appstate/collections/collectionSlice";
import paymentExtensionReducer from "appstate/paymentExtension/paymentExtensionSlice";
import tierReducer from "appstate/tier/tierSlice";
import workflowReducer from "appstate/workflow/workflowSlice";
import helpandsupportReducer from "appstate/helpandsupport/helpandsupportSlice";
import supplierEarlyPaymentOfferReducer from "appstate/supplierEarlyPayment/supplierEarlyPaymentSlice";
import verificationSupplierReducer from "../features/onboarding/supplier/supplierVerifcationSlice/verificationSlice";
import onboardingReducer from "appstate/onboarding/onboardingSlice";
import disbursementReducer from "appstate/disbursement/disbursementSlice";
import selectedDataReducer from "features/disbursement/funder/selectedDataSlice";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
// import storage from "redux-persist/lib/storage";
import sessionStorage from "redux-persist/es/storage/session";

const persistConfig = {
  key: `PayAssyst`,
  version: 1,
  storage: sessionStorage,
};

const rootReducer = combineReducers({
  auth: authReducer,
  invoice: invoiceReducer,
  settings: settingsReducer,
  inventory: inventoryReducer,
  purchaseOrder: poReducer,
  supplier: supplierReducer,
  wallet: walletReducer,
  buyer: buyerReducer,
  funder: funderReducer,
  roleManager: roleManagerReducer,
  toolsAndAgreements: toolsAndAgreementReducer,
  rulesAndConfig: rulesAndConfigReducer,
  earlyPaymentFormData: earlyPaymentFormReducer,
  notification: notificationReducer,
  lockInvoiceModal: lockInvoiceReducer,
  openMarket: openMarketReducer,
  invoiceButton: buttonReducer,
  collection: collectionReducer,
  paymentExtension: paymentExtensionReducer,
  tier: tierReducer,
  workflow: workflowReducer,
  helpAndSupport: helpandsupportReducer,
  supplierEarlyPaymentOffer: supplierEarlyPaymentOfferReducer,
  verificationSupplier: verificationSupplierReducer,
  onboarding: onboardingReducer,
  disbursement: disbursementReducer,
  selectedData: selectedDataReducer,
});

export const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
injectStore(store);
export const persistor = persistStore(store);
export default store;
