import { PAIconCancel, PAIconEmailIcon } from "assets/images/svgs";
import React from "react";

const SuccessModal = ({ onClose, message, isOpen }) => {
  if (!isOpen) {
    return null;
  }
  return (
    // <div>

    // </div>
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-[1000]">
      <div className="bg-white p-5 rounded-[10px] shadow-md flex flex-col items-center px-10 lg:w-[500px]   pt-[30px] pb-[40px] lg:min-h-[252px]">
        <div className="">
          <header className="flex justify-between border-b-4 border-whisper200 pb-[16px] ">
            {/* <p className="text-[#7a7a7a] text-base font-thin ">
              Invitation sent
            </p> */}
            <div class="text-[#7a7a7a]   font-thin ">
              Invitation sent
            </div>
            <div
              onClick={onClose}
              className="flex justify-between items-center gap-x-[10px] cursor-pointer"
            >
              <PAIconCancel className="w-[8px]" />
              {/* <p className="text-sm font-medium text-gray800">Close</p> */}
              <div class="text-[#565656] text-sm font-medium ">Close</div>
            </div>
          </header>
          <div className="flex justify-center mt-[32px] mb-[10px]">
            <PAIconEmailIcon />
          </div>
          <p className=" text-center text-[#7a7a7a] text-base font-normal  mt-3 px-7">
            {message}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;
