import { PAIconInventoryArrowLeft, PAIconSearch } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import Table from "layout/table/Table";
import { useNavigate } from "react-router-dom";
import Button from "shared/Button";
import TableIcons from "../../assets/images/svgs/TableIcons.svg";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
 // eslint-disable-next-line
import { getAllApprovedDfInvoices, getAllApprovedInvoices } from "appstate/api/invoice/invoice";
import Pagination from "shared/Pagination";
import Loading from "shared/Loading";
import { formatCurrency } from "utilities/helper/formatters";

const SupplierEarlyPaymentRequestFlow = () => {
  const navigate = useNavigate();
  const [approvedInvoices, setApprovedInvoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const [total, setTotal] = useState("");
  const [buyerName, setBuyerName] = useState("");

  const size = 10;

  function handleNavigate() {
    navigate(-1);
  }

  const GetAllApprovedInvoices = async () => {
    setLoading(true);
    try {
      const result = await getAllApprovedDfInvoices({
        page,
        size,
        status: "APPROVED",
        search: buyerName,
      });
      setTotal(result?.meta?.totalElements);
      setApprovedInvoices(result?.data || []);
    } catch (error) {
      console.error("Error fetching approved invoices:", error);
      toast.error("Failed to fetch approved invoices!");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    GetAllApprovedInvoices();
    // eslint-disable-next-line
  }, [page, size, buyerName]);

  const onRowClicked = (row) => {
    let path = `/single-early-payment-invoice-details/${row?.invoiceId}`;
    navigate(path, { state: { row } });
  };

  const handleBuyerNameChange = (event) => {
    setBuyerName(event.target.value);
  };

  const HeaderWithIcon = ({ icon, text }) => (
    <div className="flex items-center">
      <img src={icon} alt="" className="mr-2" />
      <span>{text}</span>
    </div>
  );

  const column = [
    {
      name: <HeaderWithIcon icon={TableIcons} text="Invoice Reference" />,
      selector: (row) => row?.invoiceRef,
    },
    {
      name: <HeaderWithIcon icon={TableIcons} text="Buyer" />,
      selector: (row) => row?.buyerName,
    },
    {
      name: "Invoice Date",
      selector: (row) => row?.createdDate,
    },
    {
      name: "Due Date",
      selector: (row) => row?.dueDate,
    },
    {
      name: "Currency",
      selector: (row) => row?.currency,
    },
    {
      name: "Invoice Amount",
      selector: (row) => `${formatCurrency(Number(row?.grandTotal))}`,
    },
    {
      name: "Status",
      selector: (row) => (
        <span
          style={{
            color: row.status === "APPROVED" && "#2EA923",
          }}
        >
          {row?.status}
        </span>
      ),
    },
  ];

  const totalPages = total;

  return (
    <DashboardLayout
      section="Invoice"
      subHeader="Here is an overview of your invoices"
    >
      <div>
        <header className="bg-alabasterHeader p-[24px] rounded-t-[10px]">
          <div
            onClick={handleNavigate}
            className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
          >
            <PAIconInventoryArrowLeft />
            <p className="mt-[2px] text-sm font-normal text-lightGray">
              Go Back
            </p>
          </div>
          <div className="flex justify-between flex-wrap ">
            <div>
              <p className="text-black text-sm font-medium">
                {approvedInvoices?.length} Approved Invoices
              </p>
              <p className="text-textColor text-xs font-normal">
                Invoices With Early payment Request Activated
              </p>
            </div>
            <div className="flex gap-x-[10px] mt-[10px] md:mt-0 flex-wrap">
              <div className="flex items-center  bg-white p-[10px] gap-x-[8px] rounded-[5px] border-[0.5px] border-silver xl:w-[350px]">
                <PAIconSearch />
                <input
                  type="text"
                  value={buyerName}
                  onChange={handleBuyerNameChange}
                  placeholder="Search Invoice"
                  className="placeholder:text-xs outline-none"
                />
              </div>
              <Button
                neutral
                // onClick={handleSearch}
                // disabled={data < 1}
                className="py-[12px] mt-[10px] md:mt-0 px-[24px] border-[0.5px] border-silver bg-brightGray text-sm !text-black rounded-[5px] font-medium"
              >
                Search
              </Button>
            </div>
          </div>
        </header>
      </div>
      <div className="p-[24px] bg-white rounded-b-[10px]">
        {loading ? (
          <div className="flex justify-center">
            <Loading />
          </div>
        ) : (
          <Table
            columns={column}
            pointer
            data={approvedInvoices}
            onRowClicked={onRowClicked}
          />
        )}
      </div>
      <br />
      <div className="flex justify-center gap-x-[8px]  mt-12">
        {!loading && totalPages > 10 && (
          <Pagination
            page={page}
            startPage={startPage}
            setEndPage={setEndPage}
            endPage={endPage}
            setStartPage={setStartPage}
            setPage={setPage}
            totalPages={totalPages}
          />
        )}
      </div>
    </DashboardLayout>
  );
};

export default SupplierEarlyPaymentRequestFlow;
