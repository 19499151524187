import { motion } from "framer-motion";
import { PAIconArrowLeftGreen } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { useNavigate, useParams } from "react-router-dom";
import TransitionScale from "shared/animation/TransitionScale";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  detailsBuyerOpenMarket,
  supplierAllBidDetails,
  // getInvoiceById,
} from "appstate/openMarket/openMarketSlice";
// import InvoiceDetails from "features/openMarket/common/components/InvoiceDetails";
import DirectFundingInvoice from "features/factoring/openMarket/openMarketInvoice";

function AcceptedInvoiceDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function handleGoBack() {
    navigate(-1);
  }
  // useEffect(() => {
  //   dispatch(getInvoiceById({ id }));
  // }, [dispatch, id]);

  // const { invoiceByIdData } = useSelector((state) => state?.openMarket);
  // console.log(invoiceByIdData);

  useEffect(() => {
    dispatch(detailsBuyerOpenMarket({ openMarketReference: id }));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(supplierAllBidDetails({ openMarketReference: id }));
  }, [dispatch, id]);

  const { detailsBuyerOpenMarketData, supplierAllBidDetailsData } = useSelector(
    (state) => state?.openMarket
  );
  const invoiceByIdData = detailsBuyerOpenMarketData;

  const formatAmount = (amount) => {
    if (typeof amount !== "number") return "0.00";
    return amount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const formatDate = (dateArray) => {
    if (!Array.isArray(dateArray) || dateArray.length !== 3) return "";
    const date = new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}`;
  };

  return (
    <DashboardLayout subHeader="Here is what is happening in your finance">
      <TransitionScale className="flex  flex-col gap-6 bg-[#EEE] h-full">
        <header onClick={handleGoBack} className="w-full">
          <div className="flex cursor-pointer w-fit gap-1 items-center">
            <PAIconArrowLeftGreen />
            <span className=" text-primaryColor text-sm">Go back</span>
          </div>
        </header>

        <main className="flex flex-col semi-lg:flex-row pb-8 gap-8 w-full ">
          {/* <InvoiceDetails
            invoiceByIdData={invoiceByIdData}
            // setLockModalOpen={setLockModalOpen}
          /> */}
          {/* <InvoiceDetails invoiceByIdData={invoiceByIdData} status="Locked" /> */}
          <DirectFundingInvoice
            invoiceByIdData={invoiceByIdData}
            // setLockModalOpen={setLockModalOpen}
          />

          <motion.section
            initial={{
              y: -20,
            }}
            animate={{
              y: 0,
            }}
            exit={{
              y: 20,
            }}
            transition={{
              delay: 0.8,
            }}
            className="flex flex-col gap-3  pt10 pb-24 w-2/5 bg-white rounded-[10px]"
          >
            <div className="bg-white w-full rounded-[10px] pt-5 pb-24 h-[100%]">
              <div className="h-[15%]">
                <h2 className="text-lg px-6 font-semibold text-gray-800 pb-4 border-b-[1px] border-b-slate-300 text-primaryColor">
                  Bid details for this invoice
                </h2>

                <div className="px-6 py-6 pb-4 border-b-[1px] border-b-slate-300 flex justify-between items-center">
                  <div>
                    <p className="text-gray600 text-xs">Request Amount</p>
                    <span className="text-sm">
                      {" "}
                      {formatAmount(supplierAllBidDetailsData?.data?.requestAmount)}
                    </span>
                  </div>
                  <div>
                    <p className="text-gray600 text-xs">Receivable Amount</p>
                    <span className="flex justify-end text-sm">
                      {formatAmount(supplierAllBidDetailsData?.data?.receivableAmount)}
                    </span>
                  </div>
                </div>

                <div className="px-6 py-6 pb-4 border-b-[1px] border-b-slate-300 flex flex-col">
                  <div className="flex justify-between items-center">
                    <div>
                      <p className="text-gray600 text-xs">Payment Date</p>
                      <span className="text-sm">
                        {" "}
                        {formatDate(supplierAllBidDetailsData?.data?.paymentDate)}
                      </span>
                    </div>
                    <div>
                      <p className="text-gray600 text-xs">Request Date</p>
                      <span className="flex justify-end text-sm">
                        {formatDate(supplierAllBidDetailsData?.data?.requestDate)}
                      </span>
                    </div>
                  </div>
                  <div className="flex justify-between items-center mt-2">
                    <div>
                      <p className="text-gray600 text-xs">Lock Rate Amount</p>
                      <span className="text-sm">
                        {" "}
                        {formatAmount(supplierAllBidDetailsData?.data?.lockRateAmount)}
                      </span>
                    </div>
                    <div>
                      <p className="text-gray600 text-xs">Funder's Lock Rate</p>
                      <span className="flex justify-end text-sm">
                        {supplierAllBidDetailsData?.data?.fundersLockRate}{" "}
                        <span className="text-primaryColor">({supplierAllBidDetailsData?.data?.discountType})</span>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="px-6 py-6 pb-4 border-b-[1px] border-b-slate-300">
                  <p className="text-xs text-gray600">Bid Status</p>
                  <p className="text-primaryColor">{supplierAllBidDetailsData?.data?.bidStatus}</p>
                </div>

                <div className="px-6 py-6 pb-4">
                  <h2 className="font-bold ">Funder Personal Details</h2>
                  <div className="my-3">
                    <p className="text-xs text-gray600">Funder's name</p>
                    <p className="text-primaryColor">{supplierAllBidDetailsData?.data?.funderCompanyName}</p>
                  </div>
                  <div className="flex justify-between items-center">
                    <div>
                      <p className="text-xs text-gray600">Email</p>
                      <p className="text-sm">{supplierAllBidDetailsData?.data?.funderEmail}</p>
                    </div>
                    <div>
                      <p className="text-xs text-gray600 flex justify-end">
                        Contact number
                      </p>
                      <p className="text-sm">{supplierAllBidDetailsData?.data?.funderContactNumber}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </motion.section>
        </main>
      </TransitionScale>
    </DashboardLayout>
  );
}

export default AcceptedInvoiceDetails;
