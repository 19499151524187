import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const CheckLoginComplete = ({ children }) => {
  const { userDetails } = useSelector((state) => state.auth);

  if (userDetails?.data?.companyDetailsCompleted === false) {
    let path =
      userDetails?.data?.role === "SUPPLIER"
        ? "/supplier/select-buyer"
        : userDetails?.data?.role === "BUYER"
        ? "/buyer/company-details"
        : userDetails?.data?.role === "FUNDER"
        ? "/funder/funder-company-details"
        : null;
    return <Navigate to={path} replace />;
  } else {
    return <>{children}</>;
  }
};
export default CheckLoginComplete;
