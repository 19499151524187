import React, { useState } from "react";
import Table from "layout/table/Table";
import Card from "shared/Card";
import { PAIconActionDownloadBtn } from "assets/images/svgs";

const UpdateHistory = () => {
  const [selectedRows, setSelectedRows] = useState([]);

  const columns = [
    {
      name: "TEMPLATE NAME",
      selector: (row) => row.updates,
      sortable: true,
    },
    {
      name: "DATE UPLOADED",
      selector: (row) => row.Date,
      sortable: true,
    },

    {
      name: "ACTION",
      selector: (row) => row.option,
      cell: (row) => (
        <div>
          <PAIconActionDownloadBtn />
        </div>
      ),
    },
  ];

  const data = [
    {
      id: 1,
      Date: "oct 17, 2023",
      updates: "Latest update",
      status: "Approved",
      initiator: "Party A",
      Recipient: "Party B",
    },
    {
      id: 2,
      Date: "oct 17, 2023",
      updates: "Version 4",
      status: "Approved",
      initiator: "Party A",
      Recipient: "Party B",
    },
    {
      id: 3,
      Date: "oct 17, 2023",
      updates: "Version 3",
      status: "Approved",
      initiator: "Party A",
      Recipient: "Party B",
    },
    {
      id: 4,
      Date: "oct 17, 2023",
      updates: "Version 2",
      status: "Approved",
      initiator: "Party A",
      Recipient: "Party B",
    },
    {
      id: 5,
      Date: "oct 17, 2023",
      updates: "Version 1",
      status: "Approved",
      initiator: "Party A",
      Recipient: "Party B",
    },
  ];

  const handleRowSelect = (rowId) => {
    const isSelected = selectedRows.includes(rowId);
    setSelectedRows((prevSelectedRows) =>
      isSelected
        ? prevSelectedRows.filter((id) => id !== rowId)
        : [...prevSelectedRows, rowId]
    );
  };

  return (
    <>
      <Card className=" mt-[24px]" style={{ width: "100%" }}>
        <div className="px-[24px] pb-[24px]">
          <Table
            columns={columns}
            data={data}
            pointer
            tableHeaderPink
            handleRowSelect={handleRowSelect}
            className="mt-5"
          />
        </div>
      </Card>
    </>
  );
};

export default UpdateHistory;
