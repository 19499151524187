import DashboardLayout from "layout/dashboardlayout";
// import Overview from "./Overview";
// import InvoiceDashboardTable from "./Tables/InvoiceDashboardTable";
// import RecentTables from "./Tables/RecentTables";
import { useSelector } from "react-redux";
import FunderInvoice from "../funder";
import BuyerTabs from "../buyerInvoice/Tabs";
import SupplierTabs from "./SupplierTabs";

const InvoiceDashboardContents = () => {
  // const { dashboardData } = useSelector((state) => state?.invoice);

  const { userId } = useSelector((state) => state?.auth);
  const roleIndex = userId?.realm_access?.roles?.findIndex((arr) => {
    return (
      arr === "SUPPLIER" ||
      arr === "BUYER" ||
      arr === "FUNDER" ||
      arr === "ADMIN"
    );
  });
  const userRole = userId?.realm_access?.roles[roleIndex]?.toLowerCase();
  // const isRecentActivitiesEmpty = !dashboardData?.recentActivities?.data?.length;
  // const isInvoicesEmpty = !dashboardData?.invoices?.data?.length;

  const invoicePlayer =
    userRole === "supplier" ? (
      // <>
      //   <Overview />
      //   {isInvoicesEmpty && isRecentActivitiesEmpty ? (
      //     <div className="mt-5 w-full">
      //       <TableEmptyState />
      //     </div>
      //   ) : (
      //     <div className="flex">
      //       <InvoiceDashboardTable />
      //       <RecentTables />
      //     </div>
      //   )}
      // </>
      <SupplierTabs />
    ) : userRole === "funder" ? (
      <FunderInvoice />
    ) : userRole === "buyer" ? (
      <>
        <BuyerTabs />
      </>
    ) : null;

  return (
    <DashboardLayout
      section="Invoice"
      subHeader="Here is an overview of your invoices"
    >
      {invoicePlayer}
    </DashboardLayout>
  );
};

export default InvoiceDashboardContents;
