import { PAIconArrowLeftGreen } from "assets/images/svgs";
import { AnimatePresence } from "framer-motion";
import DashboardLayout from "layout/dashboardlayout";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import OngoingAcceptUpdateBidModal from "../../directFunding/Details/AccepUpdateBidModal";
import EnterPin from "../../directFunding/Details/Pin";
import { getAllInvoiceById } from "appstate/api/invoice/invoice";
import toast from "react-hot-toast";
import PayablesDetails from "./PayablesDetails";
import { useSelector } from "react-redux";
import GeneralDetails from "./GeneralDetails";

const PayableDetailsPage = () => {
  const [pinModal, setPinModal] = useState(false);
  const [acceptModal, setAcceptModal] = useState(false);
  const navigate = useNavigate();
  const [invoice, setInvoice] = useState(null);
  const { getInvoiceId } = useSelector((state) => state?.buyer);

  useEffect(() => {
    const id = getInvoiceId.invoiceId;
    const fetchInvoice = async () => {
      if (!id) return;
      try {
        const response = await getAllInvoiceById({ id });
        setInvoice(response?.data);
      } catch (error) {
        toast.error("Failed to fetch invoice details!");
      }
    };
    fetchInvoice();
  }, [getInvoiceId.invoiceId]);

  const [countDown, setCountDown] = useState("");

  useEffect(() => {
    const updateCountdown = () => {
      const today = new Date();
      const paymentDate = new Date(invoice?.dueDate);

      const diff = paymentDate - today;

      if (diff > 0) {
        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((diff / (1000 * 60)) % 60);
        const seconds = Math.floor((diff / 1000) % 60);

        setCountDown(`${days}d ${hours}h ${minutes}m ${seconds}s`);
      } else {
        setCountDown("Time expired");
      }
    };

    updateCountdown();
    const interval = setInterval(updateCountdown, 1000);

    return () => clearInterval(interval);
  }, [invoice]);

  const handleYes = () => {
    setAcceptModal(false);
    setPinModal(true);
  };

  // const handleBackBtnClick = () => {
  //   let path = "/payablefinancing/overview";
  //   navigate(path, { state: { selectedtab: "earlyPayments" } });
  // };

  return (
    <>
      <OngoingAcceptUpdateBidModal
        onClose={() => setAcceptModal(false)}
        isOpen={acceptModal}
        mess={"Are you sure you would like to "}
        payText={"Pay now"}
        mess2={"for this invoice  "}
        handleYes={handleYes}
      />{" "}
      <DashboardLayout
        section="Disbursement"
        subHeader="Track invoices due for payment disbursement"
      >
        <header className="w-full py-3">
          <span
            onClick={() => navigate(-1)}
            className="flex cursor-pointer items-center font-medium text-sm text-primaryColor gap-2"
          >
            <PAIconArrowLeftGreen /> Go back
          </span>
        </header>

        {[
          "PAYABLES",
          "EPO_DYNAMIC_DISCOUNTING",
          "EPO_REVERSE_FACTORING",
        ].includes(getInvoiceId.fundingChannel) ? (
          <PayablesDetails
            invoice={invoice}
            setAcceptModal={setAcceptModal}
            countDown={countDown}
          />
        ) : (
          <GeneralDetails />
        )}

        <AnimatePresence>
          <EnterPin
            isOpen={pinModal}
            onClose={() => setPinModal(false)}
            pinMessage={
              "Enter your PIN to fund this request from the chosen virtual account."
            }
          />
        </AnimatePresence>
      </DashboardLayout>
    </>
  );
};

export default PayableDetailsPage;
