import Details from "features/invoice/buyerInvoice/invoiceDetails/details";
import DashboardLayout from "layout/dashboardlayout";
import React from "react";
// import Details from "./details";

const DirectFundingPreview = () => {
  return (
    <DashboardLayout
      section="Collections"
      subHeader="Track invoices due for payment collection"
    >
      <Details />
    </DashboardLayout>
  );
};

export default DirectFundingPreview;
