import React, { useEffect, useState } from "react";
import {
  PAIconDownload,
  PAIconIconParkSucess,
  PAIconInfoGreen,
  PAIconInvoiceIcon,
  PAIconMDISuccess,
  PAIconSearchGreen,
} from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import * as XLSX from "xlsx";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";
import Table from "layout/table/Table";

import { useDispatch, useSelector } from "react-redux";
import Pagination from "shared/Pagination";
import Loading from "shared/Loading";
import ViewDocumentModal from "features/RulesConfig/common/components/modals/DeleteRuleModal";
import ViewFundInvoiceModal from "features/funderpayablefinance/components/modals/ViewFundInvoiceModal";
import EnterPin from "features/disbursement/common/modal/Pin";
import LienApproveCard from "features/disbursement/common/components/LienApproveCard";
import UpcomingPayment from "features/disbursement/common/components/UpcomingPayment";
import SelectedRequests from "features/disbursement/common/components/SelectedRequests";
import TooltipShared from "shared/tooltip/Tooltip";
import { formatCapitalize } from "utilities/formatCapitalize";
import {
  disbursementFunderPayNow,
  disbursementProgramOverview,
} from "appstate/disbursement/disbursementSlice";
import toast from "react-hot-toast";
import ProgressModal from "shared/progressModal/ProgressModal";
import TransferInfoReceipt from "features/wallet/withdraw/receipt";
import MultipleTransferReceipt from "features/disbursement/common/modal/MultipleTransferReceipt";
import { clearSelectedData, updateSelectedData } from "../selectedDataSlice";

const OpenMarketDisbursement = ({
  page,
  setPage,
  selectedVAId,
  setSelectedVAId,
  setActiveTab,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { invoiceByIdData } = useSelector((state) => state?.openMarket);
  const { disbursementProgramOverviewData, isLoading } = useSelector(
    (state) => state?.disbursement
  );
  const { disbursementFunderReceiptData } = useSelector(
    (state) => state?.disbursement
  );

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [singleId, setSingleId] = useState([]);
  const [singlePay, setSinglePay] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [multipleReceiptModal, setMultipleReceiptModal] = useState(false);
  const [acceptPaymentModal, setAcceptPaymentModal] = useState(false);
  const [selectAllText, setSelectAllText] = useState("Select All");
  const [pinModal, setPinModal] = useState(false);
  const [selectDownloadType, setSelectDownloadType] = useState(false);
  const [makePaymentModal, setMakePaymentModal] = useState(false);
  const [bulkReceipt, setBulkReceipt] = useState(false);

  const [otpInputs, setOtpInputs] = useState(["", "", "", ""]);
  const [hidePin, setHidePin] = useState(false);
  const [showReceipt, setShowReceipt] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);

  // const { isLoading } = useSelector((state) => state?.invoice);
  const [searchQuery, setSearchQuery] = useState("");
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const pageSize = 10;

  const formatAmount = (amount) => {
    if (!amount) return "0.00";
    const parsedAmount = parseFloat(amount);
    if (isNaN(parsedAmount)) return "0.00";
    return parsedAmount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const handleSearchInputChange = (event) => {
    const value = event.target.value;
    setSearchQuery(value);
    sessionStorage.setItem("searchQueryFunder", value);
  };

  // SEND REMAINDER BUTTON
  const handleSendReminder = () => {
    setMakePaymentModal(true);
  };

  // CHECKBOX
  const handleCheckboxChange = (row) => {
    const isSelected = selectedRows?.includes(row?.openMarketId);
    let newSelectedRows = [];
    let newSelectedRowsData = [];

    if (isSelected) {
      newSelectedRows = selectedRows?.filter((id) => id !== row?.openMarketId);
      newSelectedRowsData = selectedRows?.filter(
        (id) => id !== row?.openMarketId
      );
    } else {
      newSelectedRows = [...selectedRows, row.openMarketId];
      newSelectedRowsData = [...selectedRowsData, row];
    }

    setSelectedRows(newSelectedRows);
    setSelectedRowsData(newSelectedRowsData);
  };

  // SELECT ALL
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
      setSelectedRowsData([]);
      setSelectAllText("Select All");
      dispatch(clearSelectedData());
    } else {
      const allIds = disbursementProgramOverviewData?.bids?.data?.map(
        (row) => row.openMarketId
      );

      const allIdsData = disbursementProgramOverviewData?.bids?.data?.map(
        (row) => row
      );

      setSelectedRows(allIds);
      setSelectedRowsData(allIdsData);
      setSelectAllText("Deselect All");
    }
    setSelectAll(!selectAll);
    // setDisabled(selectAll);
  };

  // Here
  const handleDownloadSuccessful = () => {
    toast.custom(
      <div className="flex-1 items-center justify-center flex w-full h-full">
        <div className="flex mt-[300px]">
          <div className="bg-primaryColor w-[147.79px] h-[160px] flex flex-col items-center justify-center rounded-l-[7px] shadow-lg">
            <PAIconMDISuccess />
          </div>
          <div className="bg-white flex-col justify-center items-center self-stretch py-[25px] h-[160px] rounded-r-[7px] shadow-lg flex px-16 ">
            <PAIconIconParkSucess />
            <p className="text-dustyGray text-[13px] text-center mt-[17.6px]">
              Funds disbursed successfully
            </p>
          </div>
        </div>
      </div>
    );

    setTimeout(() => {
      toast.dismiss();
      bulkReceipt ? setMultipleReceiptModal(true) : setShowReceipt(true);
      setPinModal(false);
      setHidePin(false);
      setBulkReceipt(false);
    }, 500);
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(
      disbursementProgramOverviewData?.bids?.data?.map((row) => ({
        "Program Ref No": row?.refNo,
        "Invoice Number": `INV-${row?.invoiceNumber}`,
        Buyer: row?.buyerCompanyName,
        "Due Date": row?.dueDate,
        "Payment Date": row?.paymentDate,
        Currency: row?.currency,
        "Invoice Amount": formatAmount(row?.invoiceAmount),
        "Discount Amount": formatAmount(row?.discountAmount),
        "Discount Rate": `${formatAmount(row?.lockedRate)}%`,
        "Fundable Amount": formatAmount(row?.fundableAmount),
      }))
    );
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Open Market");
    XLSX.writeFile(wb, "OpenMarketData.xlsx");
  };

  const handlePayNow = (pin) => {
    dispatch(updateSelectedData(selectedRowsData));
    const pendingToast = toast.loading("Processing your Payment...");
    setBulkReceipt(true);
    const body = {
      transactionPin: pin,
      invoiceIds: [...selectedRows],
      virtualAccountId: selectedVAId,
      disbursementType: "OPEN_MARKET",
    };

    dispatch(disbursementFunderPayNow(body)).then((data) => {
      if (data?.payload?.success) {
        toast.dismiss(pendingToast);
        setHidePin(true);
        setLoading(true);
        setPaymentModal(true);
        setTimeout(() => {
          setLoading(false);
          setTimeout(() => {
            setPaymentModal(false);
            handleDownloadSuccessful();
          }, 300);

          dispatch(
            disbursementProgramOverview({
              programType: "open-market",
              virtualAccountId: selectedVAId || "",
            })
          );
          setSinglePay(false);
        }, 3000);
      } else {
        toast.dismiss(pendingToast);
        dispatch(clearSelectedData());
        return toast.error(data?.payload?.message);
      }
    });
  };

  const handlePayNowSingle = (pin) => {
    const pendingToast = toast.loading("Processing your Payment...");
    const body = {
      transactionPin: pin,
      invoiceIds: [...singleId],
      virtualAccountId: selectedVAId,
      disbursementType: "OPEN_MARKET",
    };

    dispatch(disbursementFunderPayNow(body)).then((data) => {
      if (data?.payload?.success) {
        toast.dismiss(pendingToast);
        setHidePin(true);
        setLoading(true);
        setPaymentModal(true);
        setTimeout(() => {
          setLoading(false);
          setTimeout(() => {
            setPaymentModal(false);
            handleDownloadSuccessful();
          }, 300);

          dispatch(
            disbursementProgramOverview({
              programType: "open-market",
              virtualAccountId: selectedVAId || "",
            })
          );
          setSinglePay(false);
        }, 3000);
      } else {
        toast.dismiss(pendingToast);
        return toast.error(data?.payload?.message);
      }
    });
  };

  const columns = [
    {
      name: (
        <input type="checkbox" onChange={handleSelectAll} checked={selectAll} />
      ),
      cell: (row) => (
        <input
          type="checkbox"
          onChange={() => handleCheckboxChange(row)}
          checked={selectedRows.includes(row?.openMarketId)}
        />
      ),
      width: "50px",
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />{" "}
          {/* Program */}
          Ref. No.
        </span>
      ),
      cell: (row) => (
        <div className="relative group overflow-visible z-[999999999]">
          <TooltipShared
            text={row?.requestRefNo}
            maxLength={10}
            textSize="text-xs"
            highlight="text-xs"
          />
        </div>
      ),
      sortable: true,
    },

    {
      name: "Buyer",
      selector: (row) => formatCapitalize(row?.buyerName),
      sortable: true,
    },
    {
      name: "Supplier",
      selector: (row) => formatCapitalize(row?.supplierName),
      sortable: true,
    },
    {
      name: "Due Date",
      selector: (row) => row?.dueDate,
      sortable: true,
    },
    {
      name: "Inv. Amount",
      selector: (row) => row?.invoiceAmount,
      sortable: true,
      cell: (row) => (
        <p className=" text-[#2EA923]">{formatAmount(row?.invoiceAmount)}</p>
      ),
      width: "7.5rem",
    },
    {
      name: "Req. Amount",
      selector: (row) => row?.requestAmount,
      sortable: true,
      cell: (row) => (
        <p className=" text-secondaryColor">
          {formatAmount(row?.requestAmount)}
        </p>
      ),
      // width: "9rem",
    },
    {
      name: "Locked Rate",
      selector: (row) => row?.lockedRate,
      sortable: true,
      cell: (row) => <p className=" text-[#2EA923]">{row?.lockedRate}%</p>,
      width: "5rem",
    },
    {
      name: "Fundable Amount",
      selector: (row) => row?.fundableAmount,
      sortable: true,
      cell: (row) => (
        <p className=" text-[#2EA923]">{formatAmount(row?.fundableAmount)}</p>
      ),
      width: "7.5rem",
    },
    {
      name: "Pymt Date",
      selector: (row) => row?.paymentDate,
      sortable: true,
      cell: (row) => <p className=" text-secondaryColor">{row?.paymentDate}</p>,
    },
    {
      name: "Action",
      cell: (row) => (
        <motion.button
          onClick={() => {
            setSinglePay(true);
            setBulkReceipt(false);
            setSelectAll(false);
            setSingleId([row?.openMarketId]);
            setSelectAllText("Select All");
            setAcceptPaymentModal(true);
          }}
          whileTap={{ scale: 0.95 }}
          className="py-2 px-4 text-xs  text-white rounded bg-primaryColor"
        >
          Pay Now
        </motion.button>
      ),
    },
  ];

  useEffect(() => {
    dispatch(
      disbursementProgramOverview({
        programType: "open-market",
        virtualAccountId: selectedVAId || "",
        page,
        pageSize,
      })
    );
  }, [dispatch, selectedVAId, page, pageSize]);

  return (
    <>
      <section className="flex justify-end">
        <div className="w-full flex mdw-1/2 lg:w-1/3 items-center gap-2">
          <div className="flex gap-2.5 w-full bg-white border-[0.5px] border-[#ccc] p-2.5 items-center rounded-[5px]">
            <PAIconSearchGreen />
            <input
              type="text"
              placeholder="Search for invoices on the open market"
              value={searchQuery}
              onChange={handleSearchInputChange}
              className="p-2 w-full text-xs flex items-center text-black placeholder:text-black focus:outline-none"
            />
          </div>
        </div>
      </section>

      <section className="mt-6 flex-col lg:flex-row flex gap-5 justify-between">
        <LienApproveCard
          setSelectedVAId={setSelectedVAId}
          sectA="Total Money Liened"
          sectB="Total Won invoices in the Open Market"
          sectAValue={disbursementProgramOverviewData?.lienAmount}
          sectBValue={disbursementProgramOverviewData?.totalWonInvoices}
        />
        {selectAll ? (
          <SelectedRequests
            player="Buyer"
            subTitle="Details on the selected won open market invoices"
            handleMakePayment={handleSendReminder}
          />
        ) : (
          <UpcomingPayment
            player="Buyer"
            subText="Details on the next won open market invoice due date"
            handleMakePayment={() => {
              setSinglePay(true);
              setBulkReceipt(false);
              setSingleId([
                disbursementProgramOverviewData?.upcomingPaymentDetails
                  ?.offerId,
              ]);
              setSelectAll(false);
              setSelectedRows([]);
              setSelectAllText("Select All");
              setAcceptPaymentModal(true);
            }}
            duedate={
              disbursementProgramOverviewData?.upcomingPaymentDetails
                ?.paymentDate
            }
            playerName={
              disbursementProgramOverviewData?.upcomingPaymentDetails
                ?.supplierName
            }
            payableAmount={
              disbursementProgramOverviewData?.upcomingPaymentDetails
                ?.payableAmount
            }
          />
        )}
      </section>

      {/* TABLE */}
      <div>
        {disbursementProgramOverviewData?.bids?.data?.length > 0 ? (
          <Card className="rounded-[10px] min-h-[80vh] mt-[15px]">
            <div className="flex justify-between items-center p-[20px] bg-alabasterHeader rounded-t-[10px]">
              <div className="flex gap-6 items-center">
                <div>
                  <span className="flex items-center gap-1">
                    <p className="text-black font-bold">Open Market</p>
                  </span>

                  <span className="text-xs font-thin">
                    List of all invoices for which you won the bid in the Open
                    Market.
                  </span>
                </div>
              </div>

              <div className="flex items-center justify-center gap-3">
                <div
                  className=" text-xs rounded-md py-2.5 px-4 text-white bg-[#2EA923] cursor-pointer"
                  onClick={handleSelectAll}
                  // checked={selectAll}
                >
                  <p>{selectAllText}</p>
                </div>
                <button
                  style={{
                    boxShadow: "2px 3px 10px 0px rgba(0, 0, 0, 0.10)",
                  }}
                  className={`text-xs  rounded-md py-2.5 px-4 text-white ${
                    selectedRows.length < 2
                      ? "cursor-not-allowed bg-[#C2C0C0]"
                      : "cursor-pointer bg-secondaryColor"
                  }`}
                  disabled={selectedRows.length < 2}
                  onClick={() => {
                    setBulkReceipt(true);
                    setSinglePay(false);
                    setAcceptPaymentModal(true);
                  }}
                >
                  <p>Make Payment</p>
                </button>
              </div>
            </div>
            <div className="pb-3">
              <div className="pb-3">
                <Table
                  columns={columns}
                  data={disbursementProgramOverviewData?.bids?.data}
                  onRowClicked={(row) =>
                    navigate(
                      `funder/disbursement/openMarket/${row?.invoiceId}?openRef=${row?.requestRefNo}&openId=${row?.openMarketId}&VAId=${selectedVAId}`
                    )
                  }
                  progressPending={isLoading}
                  progressComponent={<Loading />}
                  pointer
                  tableHeader
                  className="mt-5"
                />
                <div className="flex justify-center gap-x-[8px] mt-[20px]">
                  {disbursementProgramOverviewData?.bids?.meta?.totalElements >=
                    pageSize && (
                    <Pagination
                      page={page}
                      startPage={startPage}
                      setEndPage={setEndPage}
                      endPage={endPage}
                      setStartPage={setStartPage}
                      setPage={setPage}
                      totalPages={
                        disbursementProgramOverviewData?.bids?.meta
                          ?.totalElements
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </Card>
        ) : (
          <div className="mt-[15px]">
            <div className="flex justify-between items-center px-[15px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
              <p className="text-black text-sm font-medium">Open Market</p>
            </div>

            <TableEmptyState dashboard />
          </div>
        )}
      </div>

      {/* Accept Modal */}
      <AnimatePresence>
        {selectDownloadType && (
          <ViewDocumentModal
            icon={<PAIconInvoiceIcon className=" animate-pulse" />}
            onAction={exportToExcel}
            onClose={() => setSelectDownloadType(false)}
          >
            <span className=" text-center">
              Would you like to proceed with sending a{" "}
              <span className="font-bold text-center text-primaryColor">
                Pay
              </span>{" "}
              this invoice list
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>

      {makePaymentModal && (
        <ViewFundInvoiceModal>
          <div className="flex w-full max-w-[650px] sm:max-w-[90%] md:max-w-[680px] overflow-auto h-[87vh] px-4 sm:px-6 flex-col gap-4 z-[9999]">
            <header className="flex sticky top-0 bg-white py-3  items-center justify-between">
              <span className=" text-sm">Invoice Disbursement</span>

              <motion.button
                onClick={() => {
                  setMakePaymentModal(false);
                }}
                whileTap={{ scale: 0.9 }}
                className="text-xs border border-primaryColor py-2 px-8  rounded-[5px] text-primaryColor"
              >
                Close
              </motion.button>
            </header>

            <main className="flex flex-col  gap-6">
              <section className="flex gap-28 justify-between">
                <div className="flex flex-col gap-2">
                  <span className="text-[#222] text-[20px] font-bold">
                    Multiple Single invoice payment
                  </span>

                  <div className=" text-xs">
                    <span className=" text-[#7A7A7A]">78 Invoices</span> Invoice
                    No : | Buyer Info{" "}
                    <span className=" text-[#7A7A7A]">Walediwura</span>
                  </div>
                </div>

                <div className="flex items-center">
                  <motion.button
                    whileTap={{ scale: 0.9 }}
                    onClick={() => setAcceptPaymentModal(true)}
                    className="text-xs bg-primaryColor py-2 px-8  rounded-[5px] text-white"
                  >
                    Pay Now
                  </motion.button>
                </div>
              </section>

              <section className="flex gap-28 justify-between">
                <div className="flex flex-col">
                  <div>
                    <span>Payment Amount</span>
                  </div>
                  <span className="text-primaryColor text-[20px] font-bold">
                    NGN 1,890,400
                  </span>
                </div>

                <div className="flex flex-col gap-4 items-end">
                  <span className=" text-xs">
                    Total Invoice Amount &nbsp;
                    <span className="text-primaryColor border-b pb-0.5 border-b-[#AFAFAF]">
                      N2,345,000
                    </span>
                  </span>

                  <span className="border-b pb-0.5 text-xs  border-b-[#AFAFAF] w-fit">
                    Discount: 1.67%
                  </span>

                  <span className="text-[10px] font-medium flex items-center gap-1 text-[#F08000]">
                    <PAIconInfoGreen />
                    Tap to view more
                  </span>

                  {/* <span>
                    <motion.button
                      whileTap={{ scale: 0.9 }}
                      className="py-2 px-8 bg-[#F08000] text-xs text-white  rounded-[5px]"
                    >
                      Submit new amount
                    </motion.button>
                  </span> */}
                </div>
              </section>

              <section className=" flex flex-col ">
                <header className=" pb-3 font-bold border-b-[5px] mb-4 border-[#F6F5FA] text-[18px]">
                  Buyer Bulk Invoice details
                </header>

                <main className="flex pb-4 justify-between">
                  <div className="flex flex-col">
                    <span className="text-sm font-medium">Supplier</span>

                    <span className="text-xs text-[#7A7A7A]">
                      ABC Cooperation
                    </span>
                  </div>

                  <div className="flex flex-col">
                    <span className="text-sm font-medium">Buyer</span>

                    <span className="text-xs text-[#7A7A7A]">
                      Big Buyer Coop
                    </span>
                  </div>
                </main>

                <div>
                  <header className=" pb-3 font-bold border-b-[5px] mb-4 border-[#F6F5FA] text-sm">
                    Line Items
                  </header>

                  <div className=" w-full overflow-x-auto">
                    {invoiceByIdData ? (
                      <table className=" w-full overflow-x-auto">
                        <thead className="bg-subtleGray w-">
                          <tr className=" bg-unusualGray p-2.5 ">
                            <td className="text-[#11192A] p-2.5 text-xs font-bold">
                              Item Number
                            </td>
                            <td className=" p-2.5 text-xs font-bold">
                              PO Number
                            </td>
                            <td className=" p-2.5 text-xs font-bold">
                              Product/Service Number
                            </td>
                            <td className=" p-2.5 text-xs font-bold">
                              Product/Service Description
                            </td>
                            <td className=" p-2.5 text-xs font-bold">
                              Quantity
                            </td>
                            <td className=" p-2.5 text-xs font-bold">
                              Unit Price
                            </td>
                            <td className=" p-2.5 text-xs font-bold">
                              Line Item Extended Amount
                            </td>
                          </tr>
                        </thead>

                        <tbody>
                          {invoiceByIdData?.data?.items?.map((item, index) => (
                            <tr key={index} className=" py-[3px] px-1.5">
                              <td className=" text-xs text-[#11192a] p-2.5">
                                INV-{invoiceByIdData?.data?.invoiceNumber}
                              </td>
                              <td className=" text-xs text-[#11192a] p-2.5">
                                PO-2002
                              </td>
                              <td className=" text-xs text-[#11192a] p-2.5">
                                {item?.itemName}
                              </td>
                              <td className=" text-xs text-[#11192a] p-2.5">
                                {item?.description}
                              </td>
                              <td className=" text-xs text-[#11192a] p-2.5">
                                {item?.quantity}
                              </td>
                              <td className=" whitespace-nowrap text-xs text-[#11192a] p-2.5">
                                {invoiceByIdData?.data?.currency === "NGN"
                                  ? "₦"
                                  : "$"}{" "}
                                {item?.unitPrice}
                              </td>
                              <td className=" text-xs text-[#11192a] p-2.5">
                                {invoiceByIdData?.data?.currency === "NGN"
                                  ? "₦"
                                  : "$"}{" "}
                                {item?.amount}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <div className="flex flex-col gap-3 animate-pulse justify-center items-center w-full py-6 px-4 text-sm font-bold">
                        <PAIconInvoiceIcon />
                        No invoice list available
                      </div>
                    )}
                  </div>

                  <div className="flex py-5 flex-col gap-4">
                    <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
                      <h1 className="text-sm font-bold">Attachments</h1>
                    </header>

                    <div className="w-full overflow-x-auto">
                      <table className="w-full overflow-x-auto">
                        <thead className="  bg-subtleGray w-">
                          <tr className="px-4  bg-unusualGray p-2.5 ">
                            <td className="text-[#11192A] px-4 py-2.5 text-xs font-bold">
                              Document Name
                            </td>
                            <td className=" px-4 py-2.5 text-xs font-bold">
                              File Type
                            </td>
                            <td className=" px-4 py-2.5 text-xs font-bold">
                              Upload Date / Time
                            </td>
                            <td className=" px-4 py-2.5 text-xs font-bold">
                              Action
                            </td>
                          </tr>
                        </thead>

                        <tbody>
                          <tr className=" py-[3px] px-1.5">
                            <td className=" text-xs text-[#0076EC] px-4 py-2.5">
                              DOC-29384.pdf
                            </td>
                            <td className=" text-xs text-[#11192a] px-4 py-2.5">
                              PDF
                            </td>
                            <td className=" text-xs text-[#11192a] px-4 py-2.5">
                              2021-09-14 13:25
                            </td>
                            <td className="cursor-pointer text-xs text-[#11192a] px-4 py-2.5">
                              <PAIconDownload />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </section>
            </main>
          </div>
        </ViewFundInvoiceModal>
      )}

      {/*Multiple Receipt */}

      <AnimatePresence>
        {multipleReceiptModal && (
          <MultipleTransferReceipt
            onClose={() => {
              setMultipleReceiptModal(false);
              setOtpInputs(["", "", "", ""]);
              dispatch(clearSelectedData());
            }}
            setActiveTab={setActiveTab}
          />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {acceptPaymentModal && (
          <ViewDocumentModal
            onAction={() => {
              setAcceptPaymentModal(false);
              setMakePaymentModal(false);
              setPinModal(true);
              // setTransferModal(true);
            }}
            onClose={() => setAcceptPaymentModal(false)}
          >
            <span className=" text-center">
              Are you sure you would like to{" "}
              <span className="font-semibold">Pay now</span> for{" "}
              {selectAll ? "these multiple" : "this"} single invoices?
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>

      <AnimatePresence>
        <EnterPin
          isOpen={pinModal}
          onClose={() => {
            setPinModal(false);
            setOtpInputs(["", "", "", ""]);
          }}
          hidePin={hidePin}
          otpInputs={otpInputs}
          setOtpInputs={setOtpInputs}
          onSubmit={(pin) => {
            singlePay ? handlePayNowSingle(pin) : handlePayNow(pin);
          }}
        />
      </AnimatePresence>

      {/* Export Invoice List */}
      <AnimatePresence>
        {selectDownloadType && (
          <ViewDocumentModal
            icon={<PAIconInvoiceIcon className=" animate-pulse" />}
            onAction={exportToExcel}
            onClose={() => setSelectDownloadType(false)}
          >
            <span className=" text-center">
              Are you sure you want to{" "}
              <span className="font-bold text-center text-primaryColor">
                Export
              </span>{" "}
              this invoice list
            </span>
          </ViewDocumentModal>
        )}

        {/* Here */}
        <AnimatePresence>
          {showReceipt && (
            <TransferInfoReceipt
              isOpen={showReceipt}
              disbursement
              onClose={() => {
                setOtpInputs(["", "", "", ""]);
                setPinModal(false);
                setShowReceipt(false);
                navigate(
                  `/disbursement/funder/disbursement/transaction-details/${
                    disbursementFunderReceiptData?.data?.transactionId ??
                    disbursementFunderReceiptData?.data?.transactionReference
                  }?invId=${
                    disbursementFunderReceiptData?.data?.invoiceId
                  }&offerId=${
                    disbursementFunderReceiptData?.data?.programId
                  }&channel=${
                    disbursementFunderReceiptData?.data?.fundingChannel
                  }`
                );
              }}
            />
          )}
        </AnimatePresence>

        {paymentModal && (
          <ProgressModal
            openMarket
            openMarketText={
              loading ? "Transferring funds..." : "Funds Transferred"
            }
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default OpenMarketDisbursement;
