import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import TransitionOpacity from "shared/animation/TransitionOpacity";
import ViewDocumentModal from "features/RulesConfig/common/components/modals/DeleteRuleModal";
import AcceptBidCard from "./cards/AcceptBidCard";
import { useDispatch, useSelector } from "react-redux";
import { wonBidsFunder } from "appstate/openMarket/openMarketSlice";
import LoadingScreen from "shared/LoadingScreen";
import EmptyPage from "features/roleManager/Dashbaord/components/EmptyPage";

function AcceptBid({ acceptedQuery }) {
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const dispatch = useDispatch();
  // const [offerId, setOfferId] = useState("");

  // useEffect(() => {
  //   dispatch(toggleCancelButtonOff());
  //   dispatch(
  //     getInvoiceBidByStatus({ page: 0, pageSize: 10, status: "Accepted" })
  //   );
  // }, [dispatch]);

  useEffect(() => {
    dispatch(
      wonBidsFunder({
        page: 0,
        pageSize: 10,
        searchQuery: acceptedQuery,
      })
    );
  }, [dispatch, acceptedQuery]);

  const { isLoading, wonBidsFunderData } = useSelector(
    (state) => state?.openMarket
  );

  // Handle Cancel
  const handleCancel = () => {
    setCancelModalOpen(false);
    setTimeout(() => {
      toast.success("Invoice Cancelled Successfully");
    }, 600);
  };

  return (
    <TransitionOpacity className=" min-h-[75vh] bg-unusualGray rounded-[5px] p-6">
      {isLoading ? (
        <LoadingScreen />
      ) : wonBidsFunderData?.data?.length > 0 ? (
        <div className="flex flex-col gap-4 ">
          {wonBidsFunderData?.data?.map((invoice) => (
            <AcceptBidCard
              // offerId={offerId}
              // setOfferId={setOfferId}
              openMarketReference={invoice?.openMarketReference}
              bidOfferId={invoice?.offerId}
              key={invoice?.openMarketReference}
              cancelInvoice={setCancelModalOpen}
              id={invoice?.openMarketReference}
              invoiceNumber={invoice?.invoiceNumber}
              buyerCompanyName={invoice?.buyerCompanyName}
              supplierCompanyName={invoice?.supplierCompanyName}
              description={invoice?.description}
              invoiceAmount={invoice?.invoiceAmount}
              lastModifiedDate={invoice?.lastModifiedDate}
              dueDate={invoice?.dueDate}
              numberOfBids={invoice?.numberOfBids}
              status={invoice?.status}
              lockRate={invoice?.lockRate}
              fundingAmount={invoice?.fundingAmount}
              fundRequestDate={invoice?.createdDate?.join("-")}
              paymentDate={invoice?.paymentDate?.join("-")}
            />
          ))}
        </div>
      ) : (
        <EmptyPage />
      )}
      {/* <div className='flex flex-col gap-4 '>
        <AcceptBidCard cancelInvoice={setCancelModalOpen} id='3' />
        <AcceptBidCard cancelInvoice={setCancelModalOpen} id='3' />
        <AcceptBidCard cancelInvoice={setCancelModalOpen} id='3' />
      </div> */}

      {/* Cancel Modal */}
      <AnimatePresence>
        {cancelModalOpen && (
          <ViewDocumentModal
            onAction={handleCancel}
            onClose={() => setCancelModalOpen(false)}
          >
            <span className=" text-center">
              Are you sure you want to{" "}
              <span className="font-bold text-center text-primaryColor">
                Cancel
              </span>{" "}
              this invoice
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>
    </TransitionOpacity>
  );
}

export default AcceptBid;
