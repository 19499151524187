import Modal from "layout/modal/Modal";
import React from "react";
import BulkRequestModalContent from "./BulkRequestModalContent";

const BulkRequestModal = ({ isShown, onClose, data, directFunding, handleProceed  }) => {
  return (
    <Modal size="medium" isShown={isShown} hide={onClose}>
      <BulkRequestModalContent data={data} onClose={onClose} directFunding={directFunding} handleProceed={handleProceed } />
    </Modal>
  );
};

export default BulkRequestModal;
