import {
  PAIconCocacola,
  PAIconInvShop,
  PAIconSmallDangote,
  PAIconSmallNestle,
  PAIconSmallShell,
  PAIconSmallTrust,
  PAIconSmallBrewIcon,
} from "assets/images/svgs";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

function OngoingBidCard({
  id,
  invoiceNumber = "N/A",
  buyerName = "N/A",
  invoiceType = "N/A",
  lockRate = "N/A",
  statusOfInvoice = "N/A",
  requestAmount = "N/A",
  dueDate = "N/A",
  supplierName = "N/A",
  earlyPaymentDate,
}) {
  const navigate = useNavigate();

  return (
    <motion.div
      //
      onClick={() =>
        invoiceType === "Single Invoice"
          ? navigate(
              `/payablefinancing-closedmarket/funderclosedmarketongoingbiddetails/${id}`
            )
          : navigate(
              `/payablefinancing-closedmarket/funderclosedmarketongoingbidbulkdetails/${id}`
            )
      }
      whileTap={{ scale: 0.98 }}
      style={{ boxShadow: "0px 2px 4px 0px rgba(232, 226, 216, 0.36)" }}
      className="flex  cursor-pointer  bg-white  rounded-[10px] sm:w-full md:w-full gap-6    "
    >
      {/* first layer */}

      <div className="flex flex-col cursor-pointer  bg-white py-5 px-6 rounded-[10px] sm:w-full md:w-full gap-6 ">
        <div className=" flex cursor-pointer justify-between bg-white   rounded-[10px] w-full sm:w-full md:w-full gap-6">
          <span className="flex gap-6 items-center flex-nowrap whitespace-nowrap">
            <PAIconInvShop />
            <div className="flex flex-col justify-center">
              <span className="font-[500] text-sm">{invoiceNumber}</span>
              <span className=" text-[#959595] text-[10px]">
                {"Reference number"}
              </span>
            </div>
          </span>
          {/* Button segment */}
          <span className="flex  items-center flex-nowrap whitespace-nowrap">
            <button
              className={`h-[37px] p-2.5 rounded-[5px] inline-flex ${
                invoiceType === "Single Invoice"
                  ? " bg-[#f08000]/10"
                  : "bg-[#07593d]/10"
              }`}
            >
              <div className="text-black text-sm font-medium ">
                {invoiceType}
              </div>
            </button>
          </span>

          {/* button stops here */}
          <span className="flex gap-6 items-center flex-nowrap whitespace-nowrap ">
            {buyerName === "Coca Cola" ? (
              <PAIconCocacola />
            ) : buyerName === "Dangote" ? (
              <PAIconSmallDangote />
            ) : buyerName === "Nestle Ltd." ? (
              <PAIconSmallNestle />
            ) : buyerName === "Defi Ltd." ? (
              <PAIconSmallShell />
            ) : buyerName === "Tera Plc." ? (
              <PAIconSmallTrust />
            ) : (
              <PAIconSmallBrewIcon />
            )}{" "}
            <div className="flex flex-col justify-center">
              <span className="font-[500] text-sm">{buyerName}</span>
              <span className=" text-[#959595] text-[10px]">Buyer</span>
            </div>
          </span>

          <span className="flex gap-6 items-center flex-nowrap whitespace-nowrap">
            {supplierName === "Coca Cola" ? (
              <PAIconCocacola />
            ) : supplierName === "Dangote" ? (
              <PAIconSmallDangote />
            ) : supplierName === "Nestle Plc." ? (
              <PAIconSmallNestle />
            ) : supplierName === "Defi Ltd." ? (
              <PAIconSmallShell />
            ) : supplierName === "Tacna Plc." ? (
              <PAIconSmallTrust />
            ) : (
              <PAIconSmallBrewIcon />
            )}{" "}
            <div className="flex flex-col justify-center">
              <span className="font-[500] text-sm">{supplierName}</span>
              <span className=" text-[#959595] text-[10px]">Supplier</span>
            </div>
          </span>

          <span className="flex gap-6 flex-nowrap whitespace-nowrap ">
            <div className="flex flex-col justify-center">
              <span className="flex gap-6 flex-nowrap whitespace-nowrap">
                <div className="flex flex-col justify-center">
                  <span
                    className={`font-[500]  text-sm ${
                      statusOfInvoice === "Bid Active"
                        ? "text-[#f08000]"
                        : "text-[#07593d]"
                    }`}
                  >
                    {statusOfInvoice}
                  </span>
                  <span className=" text-[#959595] text-[10px]">
                    Status of invoice
                  </span>
                </div>
              </span>
            </div>
          </span>

          {id === "4" && (
            <div className="h-[37px] p-2.5 bg-[#fec022] justify-center items-center flex">
              <div className="text-white text-sm font-medium ">
                Currently leading
              </div>
              <div className="w-4 h-4 relative">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="material-symbols-light:trophy-outline">
                    <path
                      id="Vector"
                      d="M5.5913 13.3332V12.6665H7.66797V10.1638C7.08086 10.0589 6.56708 9.81339 6.12664 9.42717C5.68619 9.04095 5.38286 8.56295 5.21664 7.99317C4.51175 7.91006 3.91064 7.62095 3.4133 7.12584C2.91597 6.63073 2.66752 6.03317 2.66797 5.33317V4.6665C2.66797 4.48784 2.73441 4.33206 2.8673 4.19917C3.00019 4.06628 3.15597 3.99984 3.33464 3.99984H5.10397V2.6665H10.8986V3.99984H12.668C12.8466 3.99984 13.0024 4.06628 13.1353 4.19917C13.2682 4.33206 13.3346 4.48784 13.3346 4.6665V5.33317C13.3346 6.03228 13.0862 6.62961 12.5893 7.12517C12.0924 7.62073 11.4913 7.90962 10.786 7.99184C10.6202 8.56295 10.3171 9.04139 9.87664 9.42717C9.43619 9.81295 8.92219 10.0585 8.33464 10.1638V12.6665H10.4113V13.3332H5.5913ZM5.10397 7.27717V4.6665H3.33464V5.33317C3.33464 5.84073 3.50397 6.27695 3.84264 6.64184C4.1813 7.00673 4.60175 7.2185 5.10397 7.27717ZM8.0013 9.5125C8.61686 9.5125 9.13819 9.29895 9.5653 8.87184C9.99286 8.44428 10.2066 7.92273 10.2066 7.30717V3.33317H5.79597V7.30784C5.79597 7.92295 6.00975 8.44428 6.4373 8.87184C6.86441 9.29895 7.38575 9.5125 8.0013 9.5125ZM10.8986 7.2765C11.4013 7.21784 11.8217 7.00628 12.16 6.64184C12.4982 6.27739 12.6675 5.84117 12.668 5.33317V4.6665H10.8986V7.2765Z"
                      fill="white"
                    />
                  </g>
                </svg>
              </div>
            </div>
          )}

          {/* side end button */}
        </div>
        {/* side end button ends here */}
        {/* second layer */}
        <div className=" flex cursor-pointer justify-between bg-white   rounded-[10px] w-full sm:w-full md:w-full gap-6">
          <span className="flex gap-3  items-center flex-nowrap whitespace-nowrap">
            <div className="text-black text-sm font-medium ">Description</div>
            <div className="text-[#949494] text-sm font-medium ">
              {lockRate}
            </div>
          </span>

          <span className="flex gap-3  items-center flex-nowrap whitespace-nowrap">
            <div className="text-black text-sm font-medium ">
              Request Amount
            </div>
            <div className="text-[#949494] text-sm font-medium ">
              {requestAmount}
            </div>
          </span>
          <span className="flex gap-3  items-center flex-nowrap whitespace-nowrap">
            <div className="text-black text-sm font-medium ">
              Early Payment Date
            </div>
            <div className="text-[#949494] text-sm font-medium ">
              {earlyPaymentDate}
            </div>
          </span>
          <span className="flex gap-6 flex-nowrap whitespace-nowrap">
            <div className="text-black text-sm font-medium ">Due Date</div>
            <div className="text-[#949494] text-sm font-medium ">{dueDate}</div>
          </span>
        </div>
      </div>
    </motion.div>
  );
}

export default OngoingBidCard;
