import React from "react";
import DashboardLayout from "layout/dashboardlayout";
import {
  PAIconArrowLeftGreen,
  PAIExportableDownload,
  PAICashSmall,
} from "assets/images/svgs";
import { useNavigate } from "react-router-dom";

import TransitionScale from "shared/animation/TransitionScale";

const DetailsForEarlyPaymentRequestMain = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  const dummyData = [
    {
      bidAmount: 3,
      item: "Solar Panel ",
      description: "Dangote 12 x 4 ultra high density so..",
      unitPrice: "340,000",
      Quantity: "3",
      amount: "1,020,000",
      taxRate: "1.2%",
      taxAmount: "12,240",
      totalPrice: "1,032,240",
    },

    {
      bidAmount: 4,
      item: "Solar Panel ",
      description: "Dangote 12 x 4 ultra high density so..",
      unitPrice: "340,000",
      Quantity: "3",
      amount: "1,020,000",
      taxRate: "1.2%",
      taxAmount: "12,240",
      totalPrice: "1,032,240",
    },

    {
      bidAmount: 5,
      item: "Solar Panel ",
      description: "Dangote 12 x 4 ultra high density so..",
      unitPrice: "340,000",
      Quantity: "3",
      amount: "1,020,000",
      taxRate: "1.2%",
      taxAmount: "12,240",
      totalPrice: "1,032,240",
    },
    {
      bidAmount: 6,
      item: "Solar Panel ",
      description: "Dangote 12 x 4 ultra high density so..",
      unitPrice: "340,000",
      Quantity: "3",
      amount: "1,020,000",
      taxRate: "1.2%",
      taxAmount: "12,240",
      totalPrice: "1,032,240",
    },
    {
      bidAmount: 7,
      item: "Solar Panel ",
      description: "Dangote 12 x 4 ultra high density so..",
      unitPrice: "340,000",
      Quantity: "3",
      amount: "1,020,000",
      taxRate: "1.2%",
      taxAmount: "12,240",
      totalPrice: "1,032,240",
    },
  ];

  return (
    <>
      <DashboardLayout
        section="Disbursement"
        subHeader="Track invoices due for payment disbursement"
      >
        <header className=" text-primaryColor  pl-5 ">
          <span
            onClick={() => handleBack()}
            className="flex cursor-pointer  w-fit font-light items-center text-sm gap-2"
          >
            <PAIconArrowLeftGreen />
            Go Back
          </span>
        </header>

        <div className="grid lg:grid-cols-[60%,40%] gap-5">
          <TransitionScale className=" pb-5 bg-white  rounded-[3px] mt-5 pt-2">
            {/* Header with Left Padding */}

            {/* Other sections */}

            <section className="  mt-3">
              {/* Down Flex Texts */}
              <div className=" flex justify-between px-6">
                {/* first stage */}

                <div class="lg:w-[240px]  sm:w-full h-[117px] flex-col justify-start items-start gap-3 inline-flex">
                  <div class=" justify-between items-center flex mt-2 ">
                    <div class="text-[#222222] text-base font-semibold   ">
                      Reference No:
                    </div>
                    <div class="text-[#222222] text-base font-semibold ">
                      | RF83018374BA
                    </div>
                  </div>
                  <div class="self-stretch  flex-col justify-center items-start gap-1 flex mt-2">
                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Invoice Number
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        INV 2002
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex ">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Created Date:{" "}
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        23-Mar -2023
                      </div>
                    </div>

                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Issued Date:{" "}
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        30-May-2023
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Submitted Date:{" "}
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        23-Mar -2023
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Approved Date:{" "}
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        23-April -2023
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Due Date:{" "}
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        30-May-2023
                      </div>
                    </div>
                  </div>

                  {/* stop */}
                </div>
                {/* second stage */}
                <div className="flex justify-between items-center mt-10 ">
                  {" "}
                  <div class="h-[30px] p-2.5 rounded-[5px] border border-[#07593d]/30 justify-center items-center gap-2.5 inline-flex px-4">
                    <div class="text-center text-[#07593d] text-xs font-semibold ">
                      Paid
                    </div>
                  </div>
                </div>
              </div>

              {/* stops here */}
              <div className="  px-6 mt-24">
                <header className="border-b-[5px] border-b-[#F6F5FA] mt-3  flex justify-between py-1">
                  {/* <h1 className="text-[18px] font-semibold">
                  Supplier/buyer details
                </h1> */}
                  <div>
                    <div class="text-[#222222] text-lg font-semibold  ">
                      Nestle PLC
                    </div>
                    <div class="text-[#7a7a7a] text-xs font-light  ">
                      Supplier details
                    </div>
                  </div>

                  <div class="h-[41px] flex-col justify-start items-start  inline-flex px-12">
                    <div class="text-[#222222] text-base font-semibold ">
                      60 Days
                    </div>
                    <div class="w-[100px] text-[#7a7a7a] text-xs font-light  mt-1">
                      Payment Terms
                    </div>
                  </div>

                  <div class="h-[43px] flex-col justify-start items-end  inline-flex">
                    <div class="text-[#222222] text-lg font-semibold ">NGN</div>
                    <div class="text-[#7a7a7a] text-xs font-semibold mt-1">
                      Currency
                    </div>
                  </div>
                </header>

                <header className="border-b-[5px] border-b-[#F6F5FA] mt-6  flex justify-between py-1">
                  {/* <h1 className="text-[18px] font-semibold">
                  Supplier/buyer details
                </h1> */}
                  <div class="text-[#11192a] text-sm font-semibold ">
                    Line Items
                  </div>
                  <div class="text-[#11192a] text-lg font-light  mr-6">
                    5 Total Items{" "}
                  </div>
                </header>

                <div className=" w-full overflow-x-auto mt-3 pb-4">
                  <table className=" w-full overflow-x-auto">
                    <thead className="bg-subtleGray w-full p-6   ">
                      <tr className=" bg-unusualGray  px-6 w-full ">
                        {/* <td className="text-[#11192A] p-2.5 text-xs font-bold">
                          Item Number
                        </td> */}
                        <td class="text-[#11192a] text-xs font-semibold  p-4  ">
                          Item{" "}
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4">
                          Description{" "}
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4">
                          Unit Price
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4">
                          Quantity
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4">
                          Amount
                          <div> (before tax)</div>
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4">
                          Tax Rate
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4">
                          Tax Amount
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4">
                          Total Price
                        </td>
                      </tr>
                    </thead>

                    <tbody className="  ">
                      {dummyData.map((cell, idx) => {
                        return (
                          <tr key={idx} className=" w-full ">
                            <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 ">
                              {cell.item}
                            </td>
                            <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                              {cell.description}
                            </td>
                            <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                              {cell.unitPrice}
                            </td>
                            <td className="text-[#11192a] text-xs font-semibold mt-2 text-center">
                              {cell.Quantity}
                            </td>
                            <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                              {cell.amount}
                            </td>
                            <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                              {cell.taxRate}
                            </td>
                            <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                              {cell.taxAmount}
                            </td>
                            <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                              {cell.totalPrice}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {/* Calculate cost and price  shaded or gray section */}
              </div>

              <div className=" px-6   py-5 bg-neutral-50 flex-col justify-center items-start  inline-flex bg-[#fafafa] w-full border-t-[0.2px]  border-t-[#b1a6b2]">
                <div className="lg:flex md:flex justify-between  w-full sm:block ">
                  {/* First left side */}
                  <div>
                    <section>
                      {" "}
                      <div class="text-[#222222] text-lg  font-medium border-b-[0.2px]  border-[#b1a6b2]">
                        Receiving Funding Account Information
                      </div>
                      {/* <div class="text-[#222222] text-lg font-semibold ">
                          Receiving Funding Account Information
                        </div> */}
                      <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                        7469479465
                      </div>
                      <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                        Praise Akinlade
                      </div>
                    </section>
                  </div>

                  {/* second division */}

                  <div>
                    {" "}
                    <div class=" flex-col justify-start items-start inline-flex lg:min-w-[290px]">
                      <section className=" w-full px-6 pb-2 border-b-[1px] border-black">
                        <div className=" flex w-full justify-between">
                          <div class="text-[#222222] text-xs font-semibold ">
                            Subtotal
                          </div>
                          <div class=" text-[#222222] text-xs font-semibold ">
                            10,129,892
                          </div>
                        </div>
                        <div className=" flex w-full justify-between pt-3 pb-1 border-b-[0.2px] border-b-[#b1a6b2]/30">
                          <div class="text-[#222222]  text-xs font-medium flex  space-x-9 ">
                            <span>VAT%</span>
                            <span>5.6%</span>
                          </div>
                          <div class="text-[#222222] text-xs font-medium ">
                            573,960
                          </div>
                        </div>

                        <div className=" flex w-full justify-between pt-3 pb-1 border-b-[0.2px] border-b-[#b1a6b2]/30">
                          <div class="text-[#222222]  text-xs font-medium flex  space-x-9 ">
                            <span>Discount%</span>
                            <span>2.4%</span>
                          </div>
                          <div class="text-[#222222] text-xs font-medium ">
                            237,088
                          </div>
                        </div>
                      </section>

                      <div className=" flex w-full justify-between py-2 px-6 border-b-[1px] border-b-black">
                        <div class="text-[#222222] text-lg font-semibold ">
                          Grand Total
                        </div>
                        <div class="text-[#222222] text-lg font-semibold ">
                          10,473,890
                        </div>
                      </div>
                      {/* <div className=" flex w-full justify-between pt-3 pb-1  border-b-[1px] border-black">
                          <div class="text-[#222222] text-lg  font-medium  ">
                            Grand Total
                          </div>
                          <div class="text-[#222222] text-lg  font-medium ">
                            5,263,200.00
                          </div>
                        </div> */}
                    </div>
                  </div>

                  {/* stops */}
                </div>
              </div>
            </section>
          </TransitionScale>
          {/* stops the best here */}

          <div className="bg-white rounded-[10px] gap-8  mt-5">
            {/* <FundDetailSide /> */}
            <div class="w-full justify-start items-center gap-4 inline-flex ">
              <div class=" justify-between items-center gap-2.5 flex w-full px-6  py-3">
                <div class="text-black text-xl font-semibold ">
                  Early Payment Transaction Details
                </div>
                <div class="h-[35px] px-5 py-1.5 bg-white rounded-[5px] border border-[#dedede]/80 justify-center items-center gap-[7px] inline-flex">
                  <div class="text-[#222222] text-xs font-normal ">Export</div>
                  <div class="w-4 h-4 relative">
                    <PAIExportableDownload />
                  </div>
                </div>
              </div>
            </div>

            <div>
              {" "}
              {/* second */}
              <div className="   pb-4">
                <div class=" justify-between  flex flex-row    px-6 mt-4  ">
                  <div className=" flex gap-2">
                    {" "}
                    <div className=" justify-center items-center flex">
                      {" "}
                      <PAICashSmall />
                    </div>
                    <div class="text-black text-base font-semibold ">
                      AMOUNT
                    </div>
                  </div>
                  {/* here */}
                </div>
                <div class=" justify-between  flex flex-row    px-6 mt-2  ">
                  <div>
                    <span className="text-[#de1515] text-lg font-semibold ">
                      {" "}
                      NGN
                    </span>
                    <span className="text-[#de1515] text-[32px] font-semibold ">
                      {" "}
                      -9,500,000
                    </span>
                  </div>
                  {/* here */}
                </div>

                <div class=" justify-between  flex flex-col    px-6  ">
                  <div class=" justify-between  flex flex-row   mt-5   ">
                    <div class="text-black text-base font-semibold ">
                      Transfer from
                    </div>

                    <div class="h-9 justify-start items-center gap-2 inline-flex">
                      <div class="w-7 h-7 relative">
                        <div class="w-7 h-7 left-0 top-0 absolute bg-[#f08000] rounded-full"></div>
                        <div class="left-[2px] top-[7px] absolute text-white text-xs font-bold ">
                          XYZ
                        </div>
                      </div>
                      <div class="flex-col justify-start items-end gap-0.5 inline-flex">
                        <div class="flex-col justify-start items-start gap-0.5 flex">
                          <div class="text-black text-sm font-normal ">
                            XYZ Ventures
                          </div>
                        </div>
                        <div class="flex-col justify-start items-start gap-0.5 flex">
                          <div class="text-[#949494] text-sm font-normal ">
                            Buyer
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class=" justify-between  flex flex-row   mt-5   ">
                    <div class="text-black text-base font-semibold ">
                      Transfer to
                    </div>

                    <div class="h-9 justify-start items-center gap-2 inline-flex">
                      <div class="w-7 h-7 relative">
                        <div class="w-7 h-7 left-0 top-0 absolute bg-[#07593D] rounded-full"></div>
                        <div class="left-[2px] top-[7px] absolute text-white text-xs font-bold ">
                          PE
                        </div>
                      </div>
                      <div class="flex-col justify-start items-end gap-0.5 inline-flex">
                        <div class="flex-col justify-start items-start gap-0.5 flex">
                          <div class="text-black text-sm font-normal ">
                            PayEdge
                          </div>
                        </div>
                        <div class="flex-col justify-start items-start gap-0.5 flex">
                          <div class="text-[#949494] text-sm font-normal ">
                            PayEdge
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class=" justify-between  flex flex-row   mt-5   ">
                    <div class="text-black text-base font-semibold ">
                      Status
                    </div>

                    <div class="h-9 justify-start items-center gap-2 inline-flex">
                      <div class="text-[#07593d] text-base font-semibold ">
                        Successful
                      </div>
                    </div>
                  </div>
                  <div class=" justify-between  flex flex-row   mt-5   ">
                    <div class="text-black text-base font-semibold ">
                      Payment Date
                    </div>

                    <div class="h-9 justify-start items-center gap-2 inline-flex">
                      <div class="text-black text-base font-semibold font-['SF Pro Display']">
                        Oct 21, 2024 | 02:43PM
                      </div>
                    </div>
                  </div>
                  <div class=" justify-between  flex flex-row   mt-5   ">
                    <div class="text-black text-base font-semibold ">
                      Transaction ID
                    </div>

                    <div class="h-9 justify-start items-center gap-2 inline-flex">
                      <div class="text-[#0076ec] text-base font-semibold">
                        DbuibYYBH878huhi8UUI89ygt2132h
                      </div>
                    </div>
                  </div>

                  <div class=" justify-between  flex flex-row   mt-5   ">
                    <div class="text-black text-base font-semibold ">
                      Payment Type
                    </div>

                    <div class="h-9 justify-start items-center gap-2 inline-flex">
                      <div class="text-black text-base font-semibold font-['SF Pro Display']">
                        Disbursement
                      </div>
                    </div>
                  </div>
                  <div class=" justify-between  flex flex-row   mt-5   ">
                    <div class="text-black text-base font-semibold ">
                      Virtual account name
                    </div>

                    <div class="text-[#07593d] text-base font-semibold ">
                      XYZ Venture
                    </div>
                  </div>
                  <div class=" justify-between  flex flex-row   mt-5   ">
                    <div class="text-black text-base font-semibold ">
                      Virtual account No
                    </div>

                    <div class="text-[#07593d] text-base font-semibold ">
                      736*****211
                    </div>
                  </div>

                  <div class=" justify-between  flex flex-row   mt-5   ">
                    <div class="text-black text-base font-semibold ">
                      Payment Method
                    </div>

                    <div class="text-[#07593d] text-base font-semibold ">
                      PayEdge Wallet
                    </div>
                  </div>

                  <div class=" justify-between  flex flex-row   mt-5   ">
                    <div class="text-black text-base font-semibold ">
                      Funding Channel
                    </div>

                    <div class="text-[#f08000] text-base font-semibold ">
                      Early Payment Request
                    </div>
                  </div>
                  <div class=" justify-between  flex flex-row   mt-5   ">
                    <div class="text-black text-base font-semibold ">
                      Description from Sender
                    </div>

                    <div class="w-[223px] text-right text-[#949494] text-base font-semibold ">
                      Payment sent. Appreciate our ongoing collaboration.
                      Regards,
                    </div>
                  </div>

                  {/* stops */}

                  {/* stops */}
                </div>
              </div>
              {/* stops here */}
              {/* Third */}
              {/* third stops */}
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};

export default DetailsForEarlyPaymentRequestMain;
