import { allMembers, allRoles } from "appstate/roleManager/roleManagerSlice";
import {
  getUsersByRole,
  getworkflowByModule,
  updateWorkflowStatus,
} from "appstate/workflow/workflowSlice";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import WorkflowActionEdit from "../../../common/WorkflowActionEdit";

function EditWorkflowInvoicing() {
  const { player, actionType } = useParams();
  const dispatch = useDispatch();

  const { getWorkflowByModuleData } = useSelector((state) => state?.workflow);

  // User By Rolename
  const [selectedRoleNameApproval, setSelectedRoleNameApproval] = useState("");
  const [selectedRoleNameUpload, setSelectedRoleNameUpload] = useState("");

  // Mapping Arrays
  const [approvals, setApprovals] = useState([]);
  const [uploads, setUploads] = useState([]);

  // Toggle Status
  const [invoiceApprovalStatus, setInvoiceApprovalStatus] = useState(false);
  const [invoiceUploadStatus, setInvoiceUploadStatus] = useState(false);
  // Dropdown Refs

  const dropdownRefsApproval = useRef([]);
  const dropdownRefsUpload = useRef([]);

  useEffect(() => {
    dispatch(getworkflowByModule(actionType.toUpperCase())).then((data) => {
      const processes = data?.payload?.data?.processes || [];

      // 1.
      setInvoiceApprovalStatus(processes[0]?.status || false);
      setApprovals(processes[0]?.approvalLevels || []);

      // 2.

      setInvoiceUploadStatus(processes[1]?.status || false);
      setUploads(processes[1]?.approvalLevels || []);
    });
    dispatch(allRoles());
    dispatch(allMembers(player));
  }, [dispatch, actionType, player]);

  useEffect(() => {
    if (selectedRoleNameApproval) {
      dispatch(getUsersByRole(selectedRoleNameApproval));
    }
    if (selectedRoleNameUpload) {
      dispatch(getUsersByRole(selectedRoleNameUpload));
    }
  }, [dispatch, selectedRoleNameApproval, selectedRoleNameUpload]);

  // Handle Dropdown ClickOutside, I have to create for each one
  useEffect(() => {
    const handleClickOutside = (event) => {
      dropdownRefsApproval.current.forEach((ref, index) => {
        if (ref && !ref.contains(event.target)) {
          setApprovals((prev) =>
            prev.map((approval, i) =>
              i === index ? { ...approval, dropdownOpen: false } : approval
            )
          );
        }
      });
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      dropdownRefsUpload.current.forEach((ref, index) => {
        if (ref && !ref.contains(event.target)) {
          setUploads((prev) =>
            prev.map((approval, i) =>
              i === index ? { ...approval, dropdownOpen: false } : approval
            )
          );
        }
      });
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Process Actions

  // 1.
  const addApproval = () => {
    let lastRow = approvals[approvals.length - 1];
    if (
      approvals?.length > 0 &&
      (!lastRow?.userId || !lastRow?.email || !lastRow?.role)
    ) {
      return toast.error("Complete current approval level to add a new one!");
    }
    setApprovals((prev) => [
      ...prev,
      {
        id: Date.now(),
        userId: "",
        teamMember: "",
        role: "",
        email: "",
        finalApproval: false,
        userSearch: "",
        dropdownOpen: false,
      },
    ]);
  };

  const removeApproval = (index) => {
    setApprovals((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSelectUserApproval = (userEmail, index) => {
    setApprovals((prev) =>
      prev.map((approval, i) =>
        i === index
          ? {
              ...approval,
              teamMember: userEmail,
              userSearch: userEmail,
              email: userEmail,
              userId: userEmail,
              dropdownOpen: false,
            }
          : approval
      )
    );
  };

  const handleFinalApprovalChangeApproval = (index, value) => {
    setApprovals((prev) =>
      prev.map((approval, i) =>
        i === index ? { ...approval, finalApproval: value } : approval
      )
    );
  };

  const handleApprovalUpdate = () => {
    const approvalLevels = approvals.map(({ email, role, finalApproval }) => ({
      email,
      role,
      finalApproval,
    }));

    const body = { status: invoiceApprovalStatus, approvalLevels };
    dispatch(
      updateWorkflowStatus({
        id: getWorkflowByModuleData?.data?.processes[0]?.id,
        body,
      })
    ).then((data) => {
      if (data?.payload?.success) {
        toast.success("Invoice approval updated successfully.");
      }
    });
  };

  // 2.

  const addUpload = () => {
    let lastRow = uploads[uploads.length - 1];
    if (
      uploads?.length > 0 &&
      (!lastRow?.userId || !lastRow?.email || !lastRow?.role)
    ) {
      return toast.error("Complete current approval level to add a new one!");
    }
    setUploads((prev) => [
      ...prev,
      {
        id: Date.now(),
        userId: "",
        teamMember: "",
        role: "",
        email: "",
        finalApproval: false,
        userSearch: "",
        dropdownOpen: false,
      },
    ]);
  };

  const removeUpload = (index) => {
    setUploads((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSelectUserUpload = (userEmail, index) => {
    setUploads((prev) =>
      prev.map((approval, i) =>
        i === index
          ? {
              ...approval,
              teamMember: userEmail,
              userSearch: userEmail,
              email: userEmail,
              userId: userEmail,
              dropdownOpen: false,
            }
          : approval
      )
    );
  };

  const handleFinalApprovalChangeUpload = (index, value) => {
    setUploads((prev) =>
      prev.map((approval, i) =>
        i === index ? { ...approval, finalApproval: value } : approval
      )
    );
  };

  const handleUploadUpdate = () => {
    const approvalLevels = uploads.map(({ email, role, finalApproval }) => ({
      email,
      role,
      finalApproval,
    }));

    const body = { status: invoiceUploadStatus, approvalLevels };
    dispatch(
      updateWorkflowStatus({
        id: getWorkflowByModuleData?.data?.processes[1]?.id,
        body,
      })
    ).then((data) => {
      if (data?.payload?.success) {
        toast.success("Invoice approval updated successfully.");
      }
    });
  };

  const actionEntities = [
    {
      isToggledStatus: invoiceApprovalStatus,
      setIsToggleStatus: setInvoiceApprovalStatus,
      mappingArr: approvals,
      setMappingArr: setApprovals,
      setSelectedRoleName: setSelectedRoleNameApproval,
      dropdownRefs: dropdownRefsApproval,
      handleFinalApprovalChange: handleFinalApprovalChangeApproval,
      addApproval: addApproval,
      removeApproval: removeApproval,
      handleApprovalSubmit: handleApprovalUpdate,
      handleSelectUser: handleSelectUserApproval,
    },

    {
      isToggledStatus: invoiceUploadStatus,
      setIsToggleStatus: setInvoiceUploadStatus,
      mappingArr: uploads,
      setMappingArr: setUploads,
      setSelectedRoleName: setSelectedRoleNameUpload,
      dropdownRefs: dropdownRefsUpload,
      handleFinalApprovalChange: handleFinalApprovalChangeUpload,
      addApproval: addUpload,
      removeApproval: removeUpload,
      handleApprovalSubmit: handleUploadUpdate,
      handleSelectUser: handleSelectUserUpload,
    },
  ];

  return (
    <main className="w-full flex flex-col ">
      {getWorkflowByModuleData?.data?.processes?.map((process, index) => (
        <WorkflowActionEdit
          title={process?.action}
          isToggledStatus={actionEntities[index]?.isToggledStatus}
          setIsToggleStatus={actionEntities[index]?.setIsToggleStatus}
          mappingArr={actionEntities[index]?.mappingArr}
          setMappingArr={actionEntities[index]?.setMappingArr}
          setSelectedRoleName={actionEntities[index]?.setSelectedRoleName}
          dropdownRefs={actionEntities[index]?.dropdownRefs}
          handleFinalApprovalChange={
            actionEntities[index]?.handleFinalApprovalChange
          }
          addApproval={actionEntities[index]?.addApproval}
          removeApproval={actionEntities[index]?.removeApproval}
          handleApprovalSubmit={actionEntities[index]?.handleApprovalSubmit}
          handleSelectUser={actionEntities[index]?.handleSelectUser}
          player={player}
        />
      ))}
    </main>
  );
}

export default EditWorkflowInvoicing;
