import React from "react";

const RequestDetails = ({ details }) => {
  // earlyPaymentDate
  return (
    <div>
      {" "}
      <div className="   pb-4">
        <div class="w-full justify-start items-center gap-4 inline-flex ">
          <div class=" justify-between items-center gap-2.5 flex-col w-full px-6  ">
            <div class="text-black text-xl font-semibold ">
              Early Payment Details
            </div>
            <div class="text-black text-xs font-light  italic">via Wallet</div>
          </div>
        </div>
        <div class=" justify-between  flex flex-row    px-6 mt-4  ">
          <div className="flex gap-2 py-2.5">
            <div className="flex flex-col">
              {/* <span className=" text-black text-xs font-normal">
      Coca Cola
    </span> */}
              <span class="text-black text-sm font-normal ">
                {"Cocola PLC"}
              </span>
              {/* <span className="text-[#979797]  text-xs">Supplier</span> */}
              <span class="text-[#949494] text-sm font-normal ">Buyer</span>
            </div>
          </div>
          <div class=" flex-col justify-start items-start inline-flex">
            {" "}
            <div className="flex gap-2 py-2.5">
              <div className="flex flex-col">
                {/* <span className=" text-black text-xs font-normal">
      Coca Cola
    </span> */}
                <span class="text-black text-sm font-normal ">{"1.5%"}</span>
                {/* <span className="text-[#979797]  text-xs">Supplier</span> */}
                <span class="text-[#949494] text-sm font-normal ">
                  Discount Rate
                </span>
              </div>
            </div>
          </div>

          {/* here */}
        </div>
        <div class=" justify-between  flex flex-row     px-6  py-3   mt-6">
          <div class="py-2.5 flex-col justify-start items-start inline-flex">
            <div class="flex-col justify-start items-start gap-0.5 flex">
              <div class="text-black text-sm font-normal ">N90,000 </div>
            </div>
            <div class="flex-col justify-start items-start  flex">
              <div class="text-[#949494] text-sm font-normal ">
                Discount Amount
              </div>
            </div>
          </div>
          {/* dhdh */}
          <div class="py-2.5 flex-col justify-start items-start inline-flex">
            <div class="flex-col justify-start items-start gap-0.5 flex">
              <div class="text-black text-sm font-normal ">
                {"23-Dec-2024 (90 days)"}
                {/* {details?.earlyPaymentDate && details?.earlyPaymentDate} */}
              </div>
            </div>
            <div class="flex-col justify-start items-start  flex">
              <div class="text-[#949494] text-sm font-normal ">
                Early Payment Request date
              </div>
            </div>
          </div>
        </div>
        <div class=" justify-between  flex flex-row    px-6  mt-6 ">
          <div class="py-2.5 flex-col justify-start items-start inline-flex">
            <div class="flex-col justify-start items-start gap-0.5 flex">
              <div class="text-black text-sm font-normal ">N9,000,000</div>
            </div>
            <div class="flex-col justify-start items-start  flex">
              <div class="text-[#949494] text-sm font-normal ">
                Request Amount
              </div>
            </div>
          </div>
          {/* hd */}
          <div class="py-2.5 flex-col justify-start items-start inline-flex">
            <div class="flex-col justify-start items-start gap-0.5 flex">
              <div class="text-black text-base font-extrabold ">8,9110,000</div>
            </div>
            <div class="flex-col justify-start items-start  flex">
              <div class="text-[#949494] text-sm font-normal ">
                Payable Amount
              </div>
            </div>
          </div>{" "}
        </div>
        <div class=" justify-between  flex flex-col     px-6  pt-6 border-t border-[#c1c1c1]/60 border-b pb-14 mt-4">
          <div class="text-black text-lg font-semibold  ">
            Note from Supplier:{" "}
          </div>

          <div class=" text-[#949494] text-sm font-light  mt-4  leading-6">
            Seeking Early payment due to unexpected challenges. Facing temporary
            financial constraints and need a bit more time to ensure a smooth
            transaction. Appreciate your understanding. Thank you.
            <br /> Best regards,
            <br /> ABC Limited
          </div>
        </div>{" "}
        <div class=" justify-between  flex flex-col     px-6  pt-6   pb-14 mt-4">
          <div class="text-black text-lg font-semibold  ">Funding Option</div>

          <div class=" text-[#949494] text-sm font-light  mt-4  leading-6">
            This invoice would be funded from this virtual account:
          </div>
          <div class="text-black/50 text-sm font-semibold mt-4  leading-6">
            Benjamin James
            <br />
            73*******112
            <br />
            PayEdge MFB
          </div>
        </div>{" "}
        {/* 2nd */}
        {/* another header */}
        {/* header ends here */}
        {/* here */}
      </div>
    </div>
  );
};

export default RequestDetails;
