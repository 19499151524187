import DashboardLayout from "layout/dashboardlayout";
import React, { useEffect, useState } from "react";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepOne from "./StepOne";
import { useDispatch } from "react-redux";
import { allBuyerAssociatedFunders } from "appstate/buyer/buyerSlice";
import Preview from "./Preview";

const CreatePfp = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchName, setSearchName] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [dynamic, setDynamic] = useState(false);
  const [reverse, setReverse] = useState(false);
  const [storeData, setStoreData] = useState([]);
  const [errors, setErrors] = useState({});
  const [industryErrors, setIndustryErrors] = useState({});

  const industry = [
    "AGRICULTURE",
    "AUTOMOTIVE",
    "BANKING",
    "CONSTRUCTION",
    "EDUCATION",
    "ENERGY",
    "ENTERTAINMENT",
    "FINANCE",
    "HEALTHCARE",
    "HOSPITALITY",
    "INFORMATION_TECHNOLOGY",
    "INSURANCE",
    "MANUFACTURING",
    "MEDIA",
    "PHARMACEUTICAL",
    "REAL_ESTATE",
    "RETAIL",
    "TELECOMMUNICATIONS",
    "TRANSPORTATION",
    "UTILITIES",
  ];

  const accounts = ["Account 1", "Account 2"];

  const [options, setOptions] = useState([]);
  const [, setFunders] = useState([]);
  const [fundersName, setFundersName] = useState([]);
  const [industryOptions] = useState(industry);
  const [accountOptions] = useState(accounts);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isFunderDropdownOpen, setIsFunderDropdownOpen] = useState(false);
  const [isAccountDropdownOpen, setIsAccountDropdownOpen] = useState(false);
  const [isIndustryDropdownOpen, setIsIndustryDropdownOpen] = useState(false);
  const selectedOptions = options.filter((option) => option.checked);
  const [userPfpId, setuserPfpId] = useState("");
  const [selectedIndustryOptions, setSelectedIndustryOptions] = useState([]);
  const [selectedAccountOptions, setSelectedAccountOptions] = useState("");
  const [selectedAccounts, setselectedAccounts] = useState(null);
  const [agreementDocument] = useState([]);
  const [selectedTemplateIds, setSelectedTemplateIds] = useState("");
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [selectedNames, setSelectedNames] = useState("");

  const [formData, setFormData] = useState({
    programName: "",
    startDate: "",
    endDate: "",
    tiers: "",
    financingOptions: "",
    discount: "",
    minAmount: "",
    maxAmount: "",
    buyerShare: "",
    funderIds: "",
    funderName: "",
    paymentDuration: "",
    percentage: "",
    industries: "",
    accounts: "",
    agreemntDocument: "",
    invoiceIds: "",
  });
  const dispatch = useDispatch();

  const handleDynamic = (e) => {
    const isChecked = e.target.checked;
    setDynamic(isChecked);
    if (isChecked) {
      setFormData((prev) => ({
        ...prev,
        financingOptions: "DYNAMIC_DISCOUNTING",
      }));
      // Disable reverse checkbox when dynamic is checked
      setReverse(false); // Uncheck reverse if dynamic is checked
    } else {
      setFormData((prev) => ({
        ...prev,
        financingOptions: "",
      }));
    }
  };

  const handleReverse = (e) => {
    const isChecked = e.target.checked;
    setReverse(isChecked);
    if (isChecked) {
      setFormData((prev) => ({
        ...prev,
        financingOptions: "REVERSE_FACTORING",
      }));
      // Disable dynamic checkbox when reverse is checked
      setDynamic(false); // Uncheck dynamic if reverse is checked
    } else {
      setFormData((prev) => ({
        ...prev,
        financingOptions: "",
      }));
    }
  };

  const page = 0;

  const pageSize = 10;

  const handleSearch = (searchQuery) => {
    dispatch(
      allBuyerAssociatedFunders({ page, pageSize, search: searchQuery })
    ).then((response) => {
      setFilteredData(response?.payload?.data);
    });
  };

  const handleChange = (event) => {
    const searchQuery = event.target.value;
    setSearchName(searchQuery);
    handleSearch(searchQuery);
  };

  const handleSelect = (funder) => {
    setFormData((prevData) => ({
      ...prevData,
      funderIds: [...prevData.funderIds, funder.userId],
    }));
    setStoreData([funder, ...storeData]);
    setFilteredData([]);
    setSearchName("");
  };

  useEffect(() => {
    if (formData.funderIds.length === 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        funderIds: "Please select at least one funder",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        funderIds: "",
      }));
    }
  }, [formData]);

  const handleRemoveEmail = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      funderIds: prevData.financingOptions.filter((_, i) => i !== index),
    }));
    const updatedFunder = storeData.filter((_, i) => i !== index);
    setStoreData(updatedFunder);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setErrors({
      ...errors,
      [name]: "",
    });

    if (name === "startDate") {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const selectedDate = new Date(value);
      selectedDate.setHours(0, 0, 0, 0);

      if (selectedDate < today) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Please select a date on or after today.",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
      }
    }

    if (name === "endDate") {
      const startDate = new Date(formData.startDate);
      const endDate = new Date(value);

      if (endDate < startDate) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "End date cannot be before the start date",
        }));
      }
    }

    if (name === "minAmount" || name === "maxAmount") {
      const minAmount =
        name === "minAmount"
          ? parseFloat(value)
          : parseFloat(formData.minAmount);
      const maxAmount =
        name === "maxAmount"
          ? parseFloat(value)
          : parseFloat(formData.maxAmount);

      if (!isNaN(minAmount) && !isNaN(maxAmount) && minAmount >= maxAmount) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Amount can not be below minimum",
        }));
      }
    }

    setFormData({
      ...formData,
      [name]: value,
    });

    if (value.trim() === "") {
      const formattedName = name
        .split(/(?=[A-Z])/)
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");

      setErrors({
        ...errors,
        [name]: `${formattedName} is required`,
      });
    }
  };

  const handleIndustryChange = (option) => {
    setSelectedIndustryOptions((prevSelected) => {
      const updatedSelected = prevSelected.includes(option)
        ? prevSelected.filter((selected) => selected !== option)
        : [...prevSelected, option];

      setFormData((prevData) => ({
        ...prevData,
        industries: updatedSelected,
      }));

      if (updatedSelected.length === 0) {
        setIndustryErrors((prevErrors) => ({
          ...prevErrors,
          Industries: "Select industries for the campaign",
        }));
      } else {
        setIndustryErrors((prevErrors) => ({
          ...prevErrors,
          Industries: "",
        }));
      }

      return updatedSelected;
    });
  };

  const handleTiersChange = (option) => {
    setOptions((prevSelected) => {
      const tierName = option?.tierName;
      const updatedSelected = prevSelected.includes(tierName)
        ? prevSelected.filter((selected) => selected !== tierName)
        : [...prevSelected, tierName];

      setFormData((prevData) => ({
        ...prevData,
        tiers: updatedSelected,
      }));

      if (updatedSelected.length === 0) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          tiers: "Tiers is required",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          tiers: "",
        }));
      }

      return updatedSelected;
    });
  };

  const handleFunderChange = (option) => {
    setFunders((prevSelected) => {
      const userId = option?.userId;
      const updatedSelected = prevSelected.includes(userId)
        ? prevSelected.filter((selected) => selected !== userId)
        : [...prevSelected, userId];

      setFormData((prevData) => ({
        ...prevData,
        funderIds: updatedSelected,
      }));

      if (updatedSelected.length === 0) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          funderIds: "Funder is required",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          funderIds: "",
        }));
      }

      return updatedSelected;
    });

    setFundersName((prevSelected) => {
      const funderName = option?.companyName;

      const updatedSelectedName = prevSelected.includes(funderName)
        ? prevSelected.filter((selected) => selected !== funderName)
        : [...prevSelected, funderName];

      setFormData((prevData) => ({
        ...prevData,
        funderName: updatedSelectedName,
      }));

      return updatedSelectedName;
    });
  };

  const handleAccountChange = (option) => {
    setSelectedAccountOptions(option.id);
    setselectedAccounts(option.accountName);
  };

  const handleAgreementCheckboxChange = (id, name) => {
    setSelectedTemplateIds(id);
    setSelectedNames(name);
  };

  const handleFileChange = () => {
    setFormData((prevData) => ({
      ...prevData,
      agreemntDocument: selectedTemplateIds,
    }));
  };

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      agreemntDocument: selectedTemplateIds,
    }));
  }, [selectedTemplateIds]);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      invoiceIds: selectedInvoice,
    }));
  }, [selectedInvoice]);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      accounts: selectedAccountOptions,
    }));
  }, [selectedAccountOptions]);

  // const validateForm = () => {
  //   let isValid = true;
  //   const newErrors = {};

  //   if (!formData.programName) {
  //     newErrors.programName = "Programe is required";
  //     isValid = false;
  //   }

  //   if (!formData.startDate) {
  //     newErrors.startDate = "Start Date is required";
  //     isValid = false;
  //   }

  //   if (!formData.endDate) {
  //     newErrors.endDate = "End Date is required";
  //     isValid = false;
  //   }

  //   // if (formData.tiers.length === 0) {
  //   //   newErrors.tiers = "Tiers is required";
  //   //   isValid = false;
  //   // }

  //   if (formData.financingOptions.length === 0) {
  //     newErrors.financingOptions = "Financing options is required";
  //     isValid = false;
  //   }

  //   if (!formData.discount) {
  //     newErrors.discount = "Discount is required";
  //     isValid = false;
  //   }

  //   if (!formData.minAmount) {
  //     newErrors.minAmount = "Minimum amount is required";
  //     isValid = false;
  //   }

  //   if (!formData.maxAmount) {
  //     newErrors.maxAmount = "Maximum amount is required";
  //     isValid = false;
  //   }

  //   if (!formData.paymentDuration) {
  //     newErrors.paymentDuration = "Payment duration is required";
  //     isValid = false;
  //   }

  //   if (!formData.percentage) {
  //     newErrors.percentage = "Offer percentage is required";
  //     isValid = false;
  //   }

  //   if (!dynamic && !formData.buyerShare) {
  //     newErrors.buyerShare = "Buyer share is required";
  //     isValid = false;
  //   }

  //   if (!dynamic && formData.funderIds.length === 0) {
  //     newErrors.funderIds = "Please select funder";
  //     isValid = false;
  //   }

  //   setErrors(newErrors);
  //   return isValid;
  // };

  const isNextDisabled =
    !formData.programName ||
    !formData.startDate ||
    !formData.endDate ||
    !formData.financingOptions ||
    !formData.discount ||
    !formData.minAmount ||
    !formData.maxAmount ||
    !formData.percentage ||
    new Date(formData.endDate) < new Date(formData.startDate) ||
    new Date(formData.startDate).setHours(0, 0, 0, 0) <
      new Date().setHours(0, 0, 0, 0);

  const isStepTwoDynamicDisabled =
    !formData.paymentDuration ||
    !formData.tiers ||
    !formData.industries ||
    !formData.accounts;

    const isStepTwoReverseDisabled =
    !formData.paymentDuration ||
    !formData.tiers ||
    !formData.industries ||
    !formData.buyerShare || 
    !formData.agreemntDocument || 
    !formData.funderIds;

  const handleNextPage = (e) => {
    setCurrentPage(currentPage + 1);
    // if (validateForm()) {
    // } else {
    //   toast.error("Fill all required fields");
    // }
  };

  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  useEffect(() => {
    if (currentPage === 1) {
      setReverse(false);
      setDynamic(false);
    }
  }, [currentPage]);

  return (
    <DashboardLayout>
      <div className="bg-white rounded-[10px] pb-[2.75rem]">
        <div className="bg-alabasterHeader px-[1.5rem] py-[1rem] rounded-t-[10px] mb-[2.75rem]">
          {currentPage === 4 ? (
            <p className="text-[#A6A6A6] text-[14px]">
              <span className="text-primaryColor text-[16px] font-semibold mr-[5px]">
                Preview Page
              </span>
            </p>
          ) : (
            <p className="text-[#A6A6A6] text-[14px]">
              <span className="text-primaryColor text-[16px] font-semibold mr-[5px]">
                Step {currentPage}
              </span>{" "}
              of Step 3
            </p>
          )}
        </div>
        {currentPage === 1 || currentPage === 2 ? (
          <h1 className="text-black text-[1rem] font-semibold mb-[1.88rem] text-center">
            Create New Campaign
          </h1>
        ) : null}

        <div className="flex justify-center">
          {currentPage === 1 && (
            <StepOne
              formData={formData}
              handleInputChange={handleInputChange}
              errors={errors}
              dynamic={dynamic}
              reverse={reverse}
              handleReverse={handleReverse}
              handleDynamic={handleDynamic}
              isNextDisabled={isNextDisabled}
              handleNextPage={handleNextPage}
              // validateForm={validateForm}
              setuserPfpId={setuserPfpId}
            />
          )}
          {currentPage === 2 && (
            <StepTwo
              formData={formData}
              errors={errors}
              searchName={searchName}
              handleChange={handleChange}
              dynamic={dynamic}
              reverse={reverse}
              handleInputChange={handleInputChange}
              handleNextPage={handleNextPage}
              handlePreviousPage={handlePreviousPage}
              filteredData={filteredData}
              storeData={storeData}
              handleSelect={handleSelect}
              handleRemoveEmail={handleRemoveEmail}
              isDropdownOpen={isDropdownOpen}
              isAccountDropdownOpen={isAccountDropdownOpen}
              isIndustryDropdownOpen={isIndustryDropdownOpen}
              setIsIndustryDropdownOpen={setIsIndustryDropdownOpen}
              selectedOptions={selectedOptions}
              setIsDropdownOpen={setIsDropdownOpen}
              setIsAccountDropdownOpen={setIsAccountDropdownOpen}
              handleTiersChange={handleTiersChange}
              options={options}
              userPfpId={userPfpId}
              financingOptions={formData.financingOptions}
              handleIndustryChange={handleIndustryChange}
              industryErrors={industryErrors}
              setSelectedIndustryOptions={setSelectedIndustryOptions}
              selectedAccounts={selectedAccounts}
              selectedIndustryOptions={selectedIndustryOptions}
              selectedAccountOptions={selectedAccountOptions}
              industryOptions={industryOptions}
              handleAccountChange={handleAccountChange}
              accountOptions={accountOptions}
              handleFileChange={handleFileChange}
              agreementDocument={agreementDocument}
              handleAgreementCheckboxChange={handleAgreementCheckboxChange}
              isStepTwoReverseDisabled={isStepTwoReverseDisabled}
              selectedNames={selectedNames}
              setSelectedNames={setSelectedNames}
              isFunderDropdownOpen={isFunderDropdownOpen}
              setIsFunderDropdownOpen={setIsFunderDropdownOpen}
              handleFunderChange={handleFunderChange}
              funderName={fundersName}
              isStepTwoDynamicDisabled={isStepTwoDynamicDisabled}
            />
          )}
        </div>
        {currentPage === 3 && (
          <StepThree
            formData={formData}
            setFormData={setFormData}
            handlePreviousPage={handlePreviousPage}
            handleNextPage={handleNextPage}
            dynamic={dynamic}
            reverse={reverse}
            setSelectedInvoice={setSelectedInvoice}
            //   handleFormSubmit={handleFormSubmit}
          />
        )}
        {currentPage === 4 && (
          <Preview
            formData={formData}
            selectedAccounts={selectedAccounts}
            //   setFormData={setFormData}
            handlePreviousPage={handlePreviousPage}
            //   handleFormSubmit={handleFormSubmit}
          />
        )}
      </div>
    </DashboardLayout>
  );
};

export default CreatePfp;
