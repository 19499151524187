import { PAIconDeleteSuccess } from "assets/images/svgs";
import React, { useEffect } from "react";

const DeleteSuccessModal = ({ isOpen, onClose }) => {
  useEffect(() => {
    if (isOpen) {
      setTimeout(onClose, 1500);
    }
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg rounded-md z-[1000]">
      <div className="bg-white rounded-md max-w-xs md:max-w-lg w-full pb-5">
        <div className="flex justify-between items-center mb4">
          <p className="text-black font-sans text-base font-bold p-3 px-5 rounded-sm italic">
            Invoice Creation
          </p>
          <button
            onClick={onClose}
            className="text-black font-sans text-xl cursor-pointer flex justify-center items-center"
          >
            &times; <span className="text-sm mx-2 mt-1">Close</span>
          </button>
        </div>
        <div className="border border-gray mb-5 px-5"></div>

        <div className="flex flex-col items-center justify-center p-10">
          <PAIconDeleteSuccess />
          <p className="mt-3">
            Your invoice has been{" "}
            <span className="text-[#BB0C0C] font-bold">Deleted</span>{" "}
            successfully
          </p>
        </div>
      </div>
    </div>
  );
};

export default DeleteSuccessModal;
