import DashboardLayout from "layout/dashboardlayout";
import { useState } from "react";
import Button from "shared/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Search from "../../assets/images/svgs/search-normal.svg";
import InputField from "shared/InputField";
import Card from "shared/Card";
import TableIcons from "../../assets/images/svgs/TableIcons.svg";
import Table from "layout/table/Table";
import { useNavigate } from "react-router-dom";
import {
  PAIconOption,
  PAIconSmallExportDownloadIcon,
} from "assets/images/svgs";
import DownloadOptions from "shared/downloadoptions/downloadoptions";
import Pagination from "shared/Pagination";
import TransitionScale from "shared/animation/TransitionScale";

import DirectFundingAcceptModal from "features/invoice/directfunding/modals/DirectFundingAcceptModal";
import DirectFundingRejectModal from "features/invoice/directfunding/modals/DirectFundingRejectModal";
import BidProceedModal from "features/payableFinaceForFunder/CloseMarket/modals/BidProceedModal";
import BidPinModal from "features/payableFinaceForFunder/CloseMarket/modals/BidPinModal";
import DirectFundingPayBidSuccessOnModal from "features/invoice/directfunding/modals/DirectFundingPaySucessOnBoardModal";
import DirectFundingBidSelectAccountModal from "features/invoice/directfunding/modals/DirectFundingBidSelectAccountModal";
import BidLoaderModal from "features/payableFinaceForFunder/CloseMarket/modals/BidLoaderModla";

import ReasonForRequiredModal from "features/invoice/directfunding/modals/ResaonRequired";
import DirectFundingDeclinedModal from "features/invoice/directfunding/modals/DirectFundingDeclined";

const data = [
  {
    id: "1",
    requestRefNo: "R3456X89Y",
    invoiceRefNo: "INV-2090",
    currency: "NGN",
    supplier: "XYZ Ventures",
    dueDate: "2023-05-23",
    paymentDate: "2023-05-31",
    invoiceAmount: "9,000,000",
    requestAmount: "950,000",
    discountRate: "1.5%",
    funderAmount: "950,000",
  },
  {
    id: "2",
    requestRefNo: "R3456X89Y",
    invoiceRefNo: "INV-2090",
    currency: "NGN",
    supplier: "XYZ Ventures",
    dueDate: "2023-05-23",
    paymentDate: "2023-05-31",
    invoiceAmount: "9,000,000",
    requestAmount: "950,000",
    discountRate: "1.5%",
    funderAmount: "950,000",
  },
  {
    id: "3",
    requestRefNo: "R3456X89Y",
    invoiceRefNo: "INV-2090",
    currency: "NGN",
    supplier: "XYZ Ventures",
    dueDate: "2023-05-23",
    paymentDate: "2023-05-31",
    invoiceAmount: "9,000,000",
    requestAmount: "950,000",
    discountRate: "1.5%",
    funderAmount: "950,000",
  },
  {
    id: "4",
    requestRefNo: "R3456X89Y",
    invoiceRefNo: "INV-2090",
    currency: "NGN",
    supplier: "XYZ Ventures",
    dueDate: "2023-05-23",
    paymentDate: "2023-05-31",
    invoiceAmount: "9,000,000",
    requestAmount: "950,000",
    discountRate: "1.5%",
    funderAmount: "950,000",
  },
  {
    id: "5",
    requestRefNo: "R3456X89Y",
    invoiceRefNo: "INV-2090",
    currency: "NGN",
    supplier: "XYZ Ventures",
    dueDate: "2023-05-23",
    paymentDate: "2023-05-31",
    invoiceAmount: "9,000,000",
    requestAmount: "950,000",
    discountRate: "1.5%",
    funderAmount: "950,000",
  },
  {
    id: "6",
    requestRefNo: "R3456X89Y",
    invoiceRefNo: "INV-2090",
    currency: "NGN",
    supplier: "XYZ Ventures",
    dueDate: "2023-05-23",
    paymentDate: "2023-05-31",
    invoiceAmount: "9,000,000",
    requestAmount: "950,000",
    discountRate: "1.5%",
    funderAmount: "950,000",
  },
  {
    id: "7",
    requestRefNo: "R3456X89Y",
    invoiceRefNo: "INV-2090",
    currency: "NGN",
    supplier: "XYZ Ventures",
    dueDate: "2023-05-23",
    paymentDate: "2023-05-31",
    invoiceAmount: "9,000,000",
    requestAmount: "950,000",
    discountRate: "1.5%",
    funderAmount: "950,000",
  },
  {
    id: "8",
    requestRefNo: "R3456X89Y",
    invoiceRefNo: "INV-2090",
    currency: "NGN",
    supplier: "XYZ Ventures",
    dueDate: "2023-05-23",
    paymentDate: "2023-05-31",
    invoiceAmount: "9,000,000",
    requestAmount: "950,000",
    discountRate: "1.5%",
    funderAmount: "950,000",
  },
];

//bulk reeuest
const SingleRequestData = [
  {
    id: "1",
    requestRefNo: "R3456X89Y",
    invoiceNo: "INV-2090",
    Supplier: "XYZ Ventures",
    dueDate: "2023-05-23",
    Paymentdate: "2023-05-31",
    requestDate: "2024-10-15",
    NoInvoices: "5",
    InvoiceAmount: "950,000",
    RequestAmount: "950,000",
    BuyerRate: "1.5%",
    PayableAmount: "950,000",
  },
  {
    id: "2",
    requestRefNo: "R3456X89Y",
    invoiceNo: "INV-2090",
    Supplier: "XYZ Ventures",
    dueDate: "2023-05-23",
    Paymentdate: "2023-05-31",
    requestDate: "2024-10-15",
    NoInvoices: "5",
    InvoiceAmount: "950,000",
    RequestAmount: "950,000",
    BuyerRate: "1.5%",
    PayableAmount: "950,000",
  },
  {
    id: "3",
    requestRefNo: "R3456X89Y",
    invoiceNo: "INV-2090",
    Supplier: "XYZ Ventures",
    dueDate: "2023-05-23",
    Paymentdate: "2023-05-31",
    requestDate: "2024-10-15",
    NoInvoices: "5",
    InvoiceAmount: "950,000",
    RequestAmount: "950,000",
    BuyerRate: "1.5%",
    PayableAmount: "950,000",
  },
  {
    id: "4",
    requestRefNo: "R3456X89Y",
    invoiceNo: "INV-2090",
    Supplier: "XYZ Ventures",
    dueDate: "2023-05-23",
    Paymentdate: "2023-05-31",
    requestDate: "2024-10-15",
    NoInvoices: "5",
    InvoiceAmount: "950,000",
    RequestAmount: "950,000",
    BuyerRate: "1.5%",
    PayableAmount: "950,000",
  },
  {
    id: "5",
    requestRefNo: "R3456X89Y",
    invoiceNo: "INV-2090",
    Supplier: "XYZ Ventures",
    dueDate: "2023-05-23",
    Paymentdate: "2023-05-31",
    requestDate: "2024-10-15",
    NoInvoices: "5",
    InvoiceAmount: "950,000",
    RequestAmount: "950,000",
    BuyerRate: "1.5%",
    PayableAmount: "950,000",
  },
  {
    id: "6",
    requestRefNo: "R3456X89Y",
    invoiceNo: "INV-2090",
    Supplier: "XYZ Ventures",
    dueDate: "2023-05-23",
    Paymentdate: "2023-05-31",
    requestDate: "2024-10-15",
    NoInvoices: "5",
    InvoiceAmount: "950,000",
    RequestAmount: "950,000",
    BuyerRate: "1.5%",
    PayableAmount: "950,000",
  },
  {
    id: "7",
    requestRefNo: "R3456X89Y",
    invoiceNo: "INV-2090",
    Supplier: "XYZ Ventures",
    dueDate: "2023-05-23",
    Paymentdate: "2023-05-31",
    requestDate: "2024-10-15",
    NoInvoices: "5",
    InvoiceAmount: "950,000",
    RequestAmount: "950,000",
    BuyerRate: "1.5%",
    PayableAmount: "950,000",
  },
  {
    id: "8",
    requestRefNo: "R3456X89Y",
    invoiceNo: "INV-2090",
    Supplier: "XYZ Ventures",
    dueDate: "2023-05-23",
    Paymentdate: "2023-05-31",
    requestDate: "2024-10-15",
    NoInvoices: "5",
    InvoiceAmount: "950,000",
    RequestAmount: "950,000",
    BuyerRate: "1.5%",
    PayableAmount: "950,000",
  },
];

//data for rejected single and bulk
const SingleRequestRejectedData = [
  {
    requestRefNo: "REQ001",
    invoiceNumber: "INV001",
    supplier: "Supplier A",
    dueDate: "2024-10-15",
    requestDate: "2024-10-10",
    currency: "1,500,000",
    invoiceAmount: "1,400,000",
    discountAmount: "10,0000",
    discountRate: "10%",
  },
];

const BulkRequestRejectedData = [
  {
    requestRefNo: "REQ001",
    invoiceNumber: "INV001",
    supplier: "Supplier A",
    dueDate: "2024-10-15",
    paymentDate: "2024-10-10",
    noOfInvoices: "12",
    invoiceAmount: "1,400,000",
    requestAmount: "1,400,000",
    buyerRate: "1.5%",
    payableAmount: "100000",
  },
];

//data for accepted single and bulk
const SingleRequestAcceptedData = [
  {
    requestRefNo: "REQ001",
    invoiceNumber: "INV001",
    supplier: "Supplier A",
    dueDate: "2024-10-15",
    requestDate: "2024-10-10",
    currency: "NGN",
    invoiceAmount: "1,400,000",
    discountAmount: "10,0000",
    discountRate: "10%",
  },
];

const BulkRequestAcceptedData = [
  {
    requestRefNo: "REQ001",
    supplier: "Supplier A",
    requestDate: "2024-10-15",
    noOfInvoices: "3",
    tier: "3",
    invoiceAmount: "1,400,000",
    discountAmount: "10,0000",
    discountRate: "10%",
  },
];

const FunderFactoringDirectFunding = () => {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("Pending");
  const [tab, setTab] = useState("Single Request");
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  // eslint-disable-next-line
  const [selectedInvoices, setSelectedInvoices] = useState({});
  const [showDownloadOptions, setShowDownloadOptions] = useState(false);
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const handleTabClick = (tabName) => {
    setSelectedTab(tabName);
  };

  const handleRequestTabClick = (tabName) => {
    setTab(tabName);
  };

  const [selectedRows, setSelectedRows] = useState([]);

  const totalPages = data.length;
  const HeaderWithIcon = ({ icon, text }) => (
    <div className="flex items-center justify-between ">
      <img src={icon} alt="" className="mr-2" />
      <span>{text}</span>
    </div>
  );
 
  const onSingleRowClickedAccepted = () => {
    let path = `/funder-direct-funding-details-single-request-accepted`;
    navigate(path);
  };

  const onSingleRowClickedAcceptedBulk = () => {
    let path = `/funder-direct-funding-details-bulk-request-accepted`;
    navigate(path);
  };

  const onSingleRowClicked = () => {
    let path = `/funder-direct-funding-details-accepted`;
    navigate(path);
  };
  const onRowClicked = (row) => {
    let path = `/funder-direct-funding-bulk-details-accepted/${row.id}`;
    navigate(path);
  };


  const onSingleRequestRowRejectedClicked = (row) => {
    let path = "/funder-direct-funding-details-single-request-rejected";
    navigate(path);
  };

  const onRowRejectedClicked = (row) => {
    let path = "/funder-direct-funding-details-rejected";
    navigate(path);
  };

  const handleShowDownloadOptions = () => {
    setShowDownloadOptions(true);
  };

  //for single accepted requests
  const columnSingleRequestAccepted = [
    {
      name: <HeaderWithIcon icon={TableIcons} text="Request Ref No" />,
      selector: (row) => row?.requestRefNo,
    },
    {
      name: <HeaderWithIcon icon={TableIcons} text="Invoice Number" />,
      selector: (row) => row?.invoiceNumber,
    },
    {
      name: <HeaderWithIcon icon={TableIcons} text="Supplier" />,
      selector: (row) => row?.supplier,
    },
    {
      name: "Due Date",
      selector: (row) => row?.dueDate,
    },
    {
      name: "Request Date",
      selector: (row) => row?.requestDate,
    },
    {
      name: "Currency",
      selector: (row) => row?.currency,
    },
    {
      name: "Invoice Amount",
      selector: (row) => (
        <span style={{ color: "#2EA923" }}>{row?.invoiceAmount}</span>
      ),
    },

    {
      name: "Discount Amount",
      selector: (row) => (
        <span style={{ color: "#F08000" }}>{row?.discountAmount}</span>
      ),
    },

    {
      name: "Discount Rate",
      selector: (row) => (
        <span style={{ color: "#2EA923" }}>{row?.discountRate}</span>
      ),
    },
  ];

  //for bulk accepted requests
  //  const BulkRequestAcceptedData = [
  //   {
  //     requestRefNo: "REQ001",
  //     supplier: "Supplier A",
  //     requestDate: "2024-10-15",
  //     noOfInvoices: "3",
  //     tier: "3",
  //     invoiceAmount: "1,400,000",
  //     discountAmount: "10,0000",
  //     discountRate: "10%",
  //   },
  // ];
  const columnBulkRequestAccepted = [
    {
      name: <HeaderWithIcon icon={TableIcons} text="Request Ref No" />,
      selector: (row) => row?.requestRefNo,
    },
    {
      name: <HeaderWithIcon icon={TableIcons} text="Supplier" />,
      selector: (row) => row?.supplier,
    },
    {
      name: "Request Date",
      selector: (row) => row?.requestDate,
    },
    {
      name: "No Of Invoices",
      selector: (row) => row?.noOfInvoices,
    },
    {
      name: "Tier",
      selector: (row) => row?.tier,
    },
    {
      name: "Invoice Amount",
      selector: (row) => (
        <span style={{ color: "#2EA923" }}>{row?.invoiceAmount}</span>
      ),
    },

    {
      name: "Discount Amount",
      selector: (row) => (
        <span style={{ color: "#F08000" }}>{row?.discountAmount}</span>
      ),
    },

    {
      name: "Discount Rate",
      selector: (row) => (
        <span style={{ color: "#2EA923" }}>{row?.discountRate}</span>
      ),
    },
  ];

  //for single rejected requests
  const ColumnSingleRequestRejected = [
    {
      name: <HeaderWithIcon icon={TableIcons} text="Request Ref No" />,
      selector: (row) => row?.requestRefNo,
    },
    {
      name: <HeaderWithIcon icon={TableIcons} text="Invoice Number" />,
      selector: (row) => row?.invoiceNumber,
    },
    {
      name: <HeaderWithIcon icon={TableIcons} text="Supplier" />,
      selector: (row) => row?.supplier,
    },
    {
      name: "Due Date",
      selector: (row) => row?.dueDate,
    },
    {
      name: "Request Date",
      selector: (row) => row?.requestDate,
    },
    {
      name: "Currency",
      selector: (row) => row?.currency,
    },
    {
      name: "Invoice Amount",
      selector: (row) => (
        <span style={{ color: "#2EA923" }}>{row?.invoiceAmount}</span>
      ),
    },

    {
      name: "Discount Amount",
      selector: (row) => (
        <span style={{ color: "#F08000" }}>{row?.discountAmount}</span>
      ),
    },

    {
      name: "Discount Rate",
      selector: (row) => (
        <span style={{ color: "#2EA923" }}>{row?.discountRate}</span>
      ),
    },
  ];

  //for rejected bulk requests
  const columnRejectedBulkRequest = [
    {
      name: <HeaderWithIcon icon={TableIcons} text="Request Ref No" />,
      selector: (row) => row?.requestRefNo,
    },
    {
      name: <HeaderWithIcon icon={TableIcons} text="Invoice No" />,
      selector: (row) => row?.invoiceNumber,
    },
    {
      name: <HeaderWithIcon icon={TableIcons} text="Supplier" />,
      selector: (row) => row?.supplier,
    },
    {
      name: "Due Date",
      selector: (row) => row?.dueDate,
    },
    {
      name: "Payment Date",
      selector: (row) => row?.paymentDate,
    },
    {
      name: "No Of Invoices",
      selector: (row) => row?.noOfInvoices,
    },
    {
      name: "Invoice Amount",
      selector: (row) => (
        <span style={{ color: "#F08000" }}>{row?.invoiceAmount}</span>
      ),
    },
    {
      name: "Request Amount",
      selector: (row) => (
        <span style={{ color: "#F08000" }}>{row?.requestAmount}</span>
      ),
    },
    {
      name: "Buyer Rate",
      selector: (row) => (
        <span style={{ color: "#2EA923" }}>{row?.buyerRate}</span>
      ),
    },
    {
      name: "Payable Amount",
      selector: (row) => (
        <span style={{ color: "#F08000" }}>{row?.payableAmount}</span>
      ),
    },
    {
      name: "",
      selector: () => <PAIconOption title="Options" />,
    },
  ];

  //for bulk requests
  const columnSingleRequest = [
    {
      name: <HeaderWithIcon icon={TableIcons} text="Request Ref. No." />,
      selector: (row) => row?.requestRefNo,
    },
    {
      name: <HeaderWithIcon icon={TableIcons} text="Invoice No." />,
      selector: (row) => row?.invoiceNo,
    },
    {
      name: <HeaderWithIcon icon={TableIcons} text="Supplier" />,

      selector: (row) => row?.Supplier,
    },
    {
      name: "Due Date",
      selector: (row) => row?.dueDate,
    },
    {
      name: "Payment date",
      selector: (row) => row?.Paymentdate,
    },
    {
      name: "No.  Invoices",
      selector: (row) => row?.NoInvoices,
    },
    {
      name: "Invoice Amount",
      selector: (row) => (
        <span style={{ color: "#F08000" }}>{row?.InvoiceAmount}</span>
      ),
    },
    {
      name: "Request Amount",
      selector: (row) => (
        <span style={{ color: "#F08000" }}>{row?.RequestAmount}</span>
      ),
    },
    {
      name: "Buyer Rate",
      selector: (row) => (
        <span style={{ color: "#2EA923" }}>{row?.BuyerRate}</span>
      ),
    },
    {
      name: "Payable Amount",
      selector: (row) => (
        <span style={{ color: "#F08000" }}>{row?.PayableAmount}</span>
      ),
    },
    // {
    //   name: "",
    //   selector: () => <PAIconOption title="Options" />,
    // },
  ];

  // eslint-disable-next-line
  const handleCheckboxChange = (invoiceReference) => {
    setSelectedInvoices((prevSelected) => ({
      ...prevSelected,
      [invoiceReference]: !prevSelected[invoiceReference],
    }));
  };

  const handleSelectAll = () => {
    if (
      Object.keys(selectedInvoices).length === data.length &&
      Object.keys(selectedInvoices).length > 0
    ) {
      setSelectedInvoices({});
    } else {
      const allSelected = {};
      data.forEach((invoice) => {
        allSelected[invoice.invoiceRefNo] = true;
      });
      setSelectedInvoices(allSelected);
    }
  };

  const isAnySelected = Object.keys(selectedInvoices).some(
    (key) => selectedInvoices[key]
  );

  const handlCheckboxChange = (row) => {
    const isSelected = selectedRows.includes(row.id);
    let newSelectedRows = [];

    if (isSelected) {
      newSelectedRows = selectedRows.filter((id) => id !== row.id);
    } else {
      newSelectedRows = [...selectedRows, row.id];
    }

    setSelectedRows(newSelectedRows);
  };

  // SELECT ALL
  const handlSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
      setSelectAllText("Select All");
    } else {
      const allIds = data.map((row) => row.id);
      setSelectedRows(allIds);
      setSelectAllText("Deselect All");
    }
    setSelectAll(!selectAll);
    // setDisabled(selectAll);
  };

  const [activeTab, setActiveTab] = useState("single");
  const handleTableClick = (tab) => {
    setActiveTab(tab);
  };

  const handleCloseModal = () => {
    setShowDownloadOptions(false);
  };

  const [selectAll, setSelectAll] = useState(false);
  const [selectAllText, setSelectAllText] = useState("Select All");

  //for single requests
  const column = [
    {
      name: "",
      cell: (row) => (
        <input
          type="checkbox"
          onChange={() => handlCheckboxChange(row)}
          checked={selectedRows.includes(row.id)}
        />
      ),
      width: "50px",
    },
    {
      name: <HeaderWithIcon icon={TableIcons} text="Request Ref. No." />,
      selector: (row) => row?.requestRefNo,
    },
    {
      name: <HeaderWithIcon icon={TableIcons} text="Invoice No." />,
      selector: (row) => row?.invoiceRefNo,
    },
    {
      name: <HeaderWithIcon icon={TableIcons} text="Supplier" />,
      selector: (row) => row?.supplier,
    },
    {
      name: "Due Date",
      selector: (row) => row?.dueDate,
    },
    {
      name: "Payment Date",
      selector: (row) => row?.paymentDate,
    },
    {
      name: "Invoice Amount",
      // selector: (row) => row?.invoiceAmount,
      selector: (row) => (
        <span style={{ color: "#2EA923" }}>{row?.invoiceAmount}</span>
      ),
    },
    {
      name: "Request Amount",
      // selector: (row) => row?.requestAmount,
      selector: (row) => (
        <span style={{ color: "#F08000" }}>{row?.requestAmount}</span>
      ),
    },

    {
      name: "Discount Rate",
      selector: (row) => (
        <span style={{ color: "#2EA923" }}>{row?.discountRate}</span>
      ),
    },

    {
      name: "Fundable Amount",
      selector: (row) => (
        <span style={{ color: "#F08000" }}>{row?.funderAmount}</span>
      ),
    },
  ];

  // const handlSelectAll = () => {
  //   if (selectAll) {
  //     setSelectedRows([]);
  //     setSelectAllText("Select All");
  //   } else {
  //     let allIds = [];
  //     if (selectedTab === "single") {
  //       allIds = singleRequestData.map((row) => row.id);
  //     } else {
  //       allIds = bulkRequestData.map((row) => row.invoiceIds);
  //     }
  //     setSelectedRows(allIds);
  //     setSelectAllText("Deselect All");
  //   }
  //   setSelectAll(!selectAll);
  // };

  const [acceptModal, setAcceptModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);

  const [bidSelectAccountModal, setBidSelectAccountModal] = useState(false);

  const [bidProceed, setBidProceed] = useState(false);
  const [bidPin, setBidPin] = useState(false);
  const [bidLoaderModal, setBidLoaderModal] = useState(false);
  const [bidSuccess, setBidSuccess] = useState(false);
  const [reasonFor, setReasonfor] = useState(false);
  const [directRejectModal, setDirectRejectModal] = useState(false);
  const handleProceed = () => {
    setBidProceed(false);

    setBidPin(true);
  };

  const handleOnselectModal = () => {
    setBidSelectAccountModal(false);

    setBidProceed(true);
  };
  const handlePinModal = () => {
    setBidPin(false);
    setBidLoaderModal(true);
  };

  const handleYes = () => {
    setAcceptModal(false);
    setBidSelectAccountModal(true);
  };
  const handleReject = () => {
    setRejectModal(false);
    setReasonfor(true);
  };

  // const [editDiscount, setEditDiscount] = useState(false);

  const handleBidSuccessClose = () => {
    setBidSuccess(false);
    setSelectedRows([]);
    setSelectAll(false);
    setSelectAllText("Select All");
  };
  const handleContinue = () => {
    setReasonfor(false);
    setBidSuccess(true);
  };
  const handleDeclineClose = () => {
    setDirectRejectModal(false);
    // handleNavigate();
  };
  const renderTabContent = () => {
    switch (activeTab) {
      case "single":
        return (
          <div className=" bg-white rounded-b-[10px]">
            <Table
              columns={column}
              pointer
              data={data}
              onRowClicked={onSingleRowClicked}
              tableHeader
            />

            <div>
              {totalPages > 2 && (
                <div className="flex justify-center gap-x-[8px]  mt-8">
                  <Pagination
                    page={page}
                    startPage={startPage}
                    setEndPage={setEndPage}
                    endPage={endPage}
                    setStartPage={setStartPage}
                    setPage={setPage}
                    totalPages={totalPages}
                  />
                </div>
              )}
            </div>
          </div>
        );
      case "bulk":
        return (
          <div className=" bg-white rounded-b-[10px]">
            <Table
              columns={columnSingleRequest}
              pointer
              data={SingleRequestData}
              onRowClicked={onRowClicked}
            />

            <div>
              {totalPages > 2 && (
                <div className="flex justify-center gap-x-[8px]  mt-8">
                  <Pagination
                    page={page}
                    startPage={startPage}
                    setEndPage={setEndPage}
                    endPage={endPage}
                    setStartPage={setStartPage}
                    setPage={setPage}
                    totalPages={totalPages}
                  />
                </div>
              )}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <TransitionScale>
        <DirectFundingAcceptModal
          mess1={"Would you like to proceed with "}
          blueText={"Approving "}
          mess2={"all"}
          mess3={
            "select Direct Funding requests at their respective effective rates?"
          }
          isOpen={acceptModal}
          onClose={() => {
            setRejectModal(true);
            setAcceptModal(false);
          }}
          handleYes={handleYes}
        />
      </TransitionScale>
      <TransitionScale>
        <DirectFundingRejectModal
          mess1={"Would you like to proceed with "}
          blueText={"Rejecting "}
          mess2={" all the"}
          mess3={" selected Direct Funding request?"}
          isOpen={rejectModal}
          onClose={() => {
            setRejectModal(false);
            setSelectedRows([]);
            setSelectAll(false);
            setSelectAllText("Select All");
          }}
          handleYes={handleReject}
        />
      </TransitionScale>

      <TransitionScale>
        <DirectFundingBidSelectAccountModal
          isOpen={bidSelectAccountModal}
          onClose={() => setBidSelectAccountModal(false)}
          handleProceed={handleOnselectModal}
          message={"Select which account you want to use for this Request"}
        />
      </TransitionScale>

      <TransitionScale>
        {bidLoaderModal && (
          <BidLoaderModal
            isOpen={bidLoaderModal}
            onClose={() => setBidLoaderModal(false)}
            onAction={() => {
              setBidLoaderModal(false);
              setTimeout(() => {
                setBidSuccess(true);
              }, 300);
            }}
            statusText={"Fund Invoice"}
          />
        )}{" "}
      </TransitionScale>

      <TransitionScale>
        {" "}
        <BidPinModal
          isOpen={bidPin}
          onClose={() => setBidPin(false)}
          handleNext={handlePinModal}
        />
      </TransitionScale>
      <TransitionScale>
        {" "}
        <BidProceedModal
          isOpen={bidProceed}
          refNum={" 7364738921"}
          onClose={() => setBidProceed(false)}
          handleYes={handleProceed}
        />
      </TransitionScale>
      <TransitionScale>
        <DirectFundingPayBidSuccessOnModal
          isOpen={bidSuccess}
          onClose={handleBidSuccessClose}
          message={"Early payment request sent for"}
          sucess={"workflow approval"}
        />
      </TransitionScale>
      {reasonFor && (
        <TransitionScale>
          <ReasonForRequiredModal
            onClose={() => setReasonfor(false)}
            onContinue={handleContinue}
          />
        </TransitionScale>
      )}
      <TransitionScale>
        <DirectFundingDeclinedModal
          isOpen={directRejectModal}
          onClose={handleDeclineClose}
        />
      </TransitionScale>
      <TransitionScale>
        {" "}
        {/* <BidAcceptModal
          isOpen={ongoingAcceptUpdateBidModal}
          onClose={() => setOngoingAcceptUpdateBidModal(false)}
          refNum={"R3456X89Y"}
          handleYes={handleAccept}
        /> */}
      </TransitionScale>
      <DashboardLayout
        section="Direct Fund Request"
        subHeader="Manage all your direct fund requests"
      >
        {showDownloadOptions && (
          <DownloadOptions handleCloseModal={handleCloseModal} />
        )}
        <div className="mt5 mx6 mb-[17px] w-full cursor-pointer rounded-md bg-white">
          <div className="gap1 flex-col items-center justify-between gap-10 p-4 px-4 md:flex md:flex-row">
            <div className="flex-col gap-10 md:flex md:flex-row">
              <div
                className={
                  selectedTab === "Pending"
                    ? "rounded-l-sm rounded-r-sm border-[#07593D] font-bold text-[#07593D] md:mb-[-18px] md:border-b-4"
                    : "text-gray500 text-base font-normal"
                }
                onClick={() => handleTabClick("Pending")}
              >
                Pending
              </div>
              <div
                className={
                  selectedTab === "Approved"
                    ? "rounded-l-sm rounded-r-sm border-[#07593D] font-bold text-[#07593D] md:mb-[-18px] md:border-b-4"
                    : "text-gray500 my-2 text-base font-normal md:my-0"
                }
                onClick={() => handleTabClick("Approved")}
              >
                Approved
              </div>
              <div
                className={
                  selectedTab === "Rejected"
                    ? "rounded-l-sm rounded-r-sm border-[#07593D] font-bold text-[#07593D] md:mb-[-18px] md:border-b-4"
                    : "text-gray500 my-2 text-base font-normal md:my-0"
                }
                onClick={() => handleTabClick("Rejected")}
              >
                Rejection
              </div>
            </div>
          </div>
        </div>

        {selectedTab === "Pending" && (
          <div>
            <div className="flex justify-between flex-wrap py-3">
              <div className="flex flex-wrap">
                <div className="flex gap-4 mb-4">
                  <div className="relative">
                    <DatePicker
                      selected={startDate}
                      onChange={(update) => setDateRange(update)}
                      startDate={startDate}
                      endDate={endDate}
                      selectsRange
                      placeholderText="Filters"
                      className="border  border-[#ccc] px-4 py-2 cursor-pointer rounded-md w-60"
                    />

                    <div className="absolute top-2/4 right-3 transform -translate-y-2/4 pointer-events-none">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5 text-gray-400"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                        />
                      </svg>
                    </div>
                  </div>
                </div>

                <div className="flex ml-2">
                  <Button
                    neutral
                    className="flex !text-[#565656] gap-2 text-xs font-medium items-center  md:mb-0 border border-[#ccc] bg-white py-2 px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px] h-[37px]"
                  >
                    <p className="whitespace-nowrap">Export</p>
                    <PAIconSmallExportDownloadIcon />
                  </Button>
                </div>
              </div>
              {/* <div className=" flex items-center justify-center bg-red"> */}
              <div className="flex ">
                <div className="relative flex">
                  <div className=" items-center justify-center mt-3">
                    {" "}
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="absolute left-3 w-4 h-4"
                    >
                      <g
                        id="clarity:search-line"
                        clip-path="url(#clip0_4085_289550)"
                      >
                        <path
                          id="Vector"
                          d="M7.25825 2.7451C8.22097 2.74422 9.16233 3.02889 9.96323 3.56312C10.7641 4.09734 11.3886 4.8571 11.7576 5.74629C12.1267 6.63548 12.2237 7.61414 12.0364 8.55847C11.8491 9.50281 11.386 10.3704 10.7055 11.0514C10.0251 11.7325 9.15793 12.1964 8.21377 12.3846C7.26961 12.5727 6.29085 12.4766 5.40133 12.1084C4.5118 11.7402 3.75147 11.1164 3.21652 10.316C2.68156 9.51558 2.39603 8.57449 2.39603 7.61176C2.40186 6.32361 2.91587 5.08981 3.82632 4.17852C4.73678 3.26724 5.9701 2.75211 7.25825 2.7451ZM7.25825 1.83398C6.11551 1.83398 4.99844 2.17285 4.04829 2.80772C3.09814 3.44259 2.35758 4.34495 1.92028 5.4007C1.48297 6.45645 1.36855 7.61817 1.59149 8.73895C1.81443 9.85973 2.36471 10.8892 3.17274 11.6973C3.98078 12.5053 5.01028 13.0556 6.13106 13.2785C7.25184 13.5015 8.41356 13.387 9.46931 12.9497C10.5251 12.5124 11.4274 11.7719 12.0623 10.8217C12.6972 9.87157 13.036 8.7545 13.036 7.61176C13.036 6.0794 12.4273 4.6098 11.3438 3.52626C10.2602 2.44271 8.79061 1.83398 7.25825 1.83398Z"
                          fill="#2FA06A"
                        />
                        <path
                          id="Vector_2"
                          d="M15.5551 15.2958L12.2795 11.998L11.6484 12.6247L14.924 15.9225C14.9651 15.9639 15.014 15.9969 15.0679 16.0194C15.1218 16.0419 15.1796 16.0536 15.238 16.0538C15.2964 16.0541 15.3542 16.0428 15.4083 16.0206C15.4623 15.9984 15.5114 15.9659 15.5529 15.9247C15.5943 15.8836 15.6273 15.8347 15.6498 15.7808C15.6723 15.7269 15.684 15.6691 15.6842 15.6107C15.6844 15.5523 15.6732 15.4945 15.651 15.4404C15.6288 15.3864 15.5963 15.3373 15.5551 15.2958Z"
                          fill="#2FA06A"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_4085_289550">
                          <rect
                            width="16"
                            height="16"
                            fill="white"
                            transform="translate(0 0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <InputField
                    placeholder="Search for requests"
                    className="pl-10 w-full py-2 border rounded-md border[#CCCCCC] outline-none md:w-[350px] !border-silver"
                  />
                </div>

                <div className="ml-2">
                  <Button
                    neutral
                    className="flex text-white  text-xs font-medium bg-[#2FA06A] items-center mb-[10px] md:mb-0  py-[12px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px]"
                  >
                    <p className="whitespace-nowrap">Search</p>
                  </Button>
                </div>
              </div>
              {/* </div> */}
            </div>

            {/* starts from  here */}
            <Card className="pb-[24px] rounded-[10px]   min-h-[670px]">
              <div className="flex justify-between flex-wrap bg-[#fafafa] py-7 px-6 rounded-t-[10px]">
                <div>
                  <div class=" flex-col justify-start items-start inline-flex">
                    <div class="text-[#1d0220] text-base font-medium ">
                      Direct Funding Requests
                    </div>
                    <div class="text-[#5f4d61] text-sm font-medium ">
                      {activeTab === "single"
                        ? "List of all pending Direct Funding requests"
                        : "List of all Direct Funding requests for you to review"}
                    </div>
                  </div>
                </div>

                {activeTab === "single" && (
                  <div className=" flex flex-wrap gap-2">
                    <div className="">
                      <Button
                        neutral
                        className="flex text-white  text-xs font-medium bg-[#2FA06A] items-center mb-[10px] md:mb-0  py-[10px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px]"
                        onClick={handlSelectAll}
                      >
                        <p className="whitespace-nowrap">{selectAllText}</p>
                      </Button>
                    </div>
                    <div>
                      <Button
                        neutral
                        className={`flex text-white  text-xs font-medium ${
                          selectAll ? "bg-[#F08000]" : "bg-[#c2c0c0]"
                        } items-center mb-[10px] md:mb-0  py-[10px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px]`}
                        disabled={!selectAll}
                        onClick={() => setAcceptModal(true)}
                      >
                        <p className="whitespace-nowrap">
                          Approve Direct Funding
                        </p>
                      </Button>
                    </div>
                  </div>
                )}
              </div>

              <div className="mt-3 px-[15px] text-sm bg-white">
                <div className="flex gap-3 py-3">
                  <p
                    className={`cursor-pointer font-bold relative ${
                      activeTab === "single"
                        ? "text-[#F08000]"
                        : "text-[#959595]"
                    }`}
                    onClick={() => handleTableClick("single")}
                  >
                    Single requests
                    {activeTab === "single" && (
                      <span
                        className="absolute bottom-0 left-1/2 transform -translate-x-1/2
                 bg-[#F08000] h-0.5 w-5"
                      ></span>
                    )}
                  </p>

                  <p
                    className={`cursor-pointer font-bold relative ${
                      activeTab === "bulk" ? "text-[#F08000]" : "text-[#959595]"
                    }`}
                    onClick={() => handleTableClick("bulk")}
                  >
                    Bulk requests
                    {activeTab === "bulk" && (
                      <span
                        className="absolute bottom-0 left-1/2 transform -translate-x-1/2
                 bg-[#F08000] h-0.5 w-5"
                      ></span>
                    )}
                  </p>
                </div>

                {/* Render the tab content */}
                <div className="mt-3">{renderTabContent()}</div>
              </div>
            </Card>
          </div>
        )}

        {selectedTab === "Approved" && (
          <div>
            <div className="flex justify-between">
              <div className="flex ">
                <div className="flex gap-4 mb-4">
                  <div className="relative">
                    <DatePicker
                      selected={startDate}
                      onChange={(update) => setDateRange(update)}
                      startDate={startDate}
                      endDate={endDate}
                      selectsRange
                      placeholderText="Filters"
                      className="border  border-[#ccc] px-4 py-2 cursor-pointer rounded-md w-60"
                    />

                    <div className="absolute top-2/4 right-3 transform -translate-y-2/4 pointer-events-none">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5 text-gray-400"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                        />
                      </svg>
                    </div>
                  </div>
                </div>

                <div className="flex ml-2">
                  <Button
                    neutral
                    onClick={handleShowDownloadOptions}
                    className="flex !text-[#565656] gap-2 text-xs font-medium items-center mb-[10px] md:mb-0 border border-[#ccc] bg-white py-[12px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px] h-[43px]"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13.75 14.75H2.25C1.97344 14.75 1.75 14.5266 1.75 14.25V2.75C1.75 2.47344 1.97344 2.25 2.25 2.25H7.875C7.94375 2.25 8 2.30625 8 2.375V3.25C8 3.31875 7.94375 3.375 7.875 3.375H2.875V13.625H13.125V8.625C13.125 8.55625 13.1812 8.5 13.25 8.5H14.125C14.1938 8.5 14.25 8.55625 14.25 8.625V14.25C14.25 14.5266 14.0266 14.75 13.75 14.75ZM12.0448 3.61142L11.2292 2.7958C11.1558 2.72236 11.1995 2.5958 11.3027 2.5833L14.1058 2.25517C14.1855 2.2458 14.2542 2.31298 14.2448 2.39423L13.9167 5.19736C13.9042 5.30048 13.7777 5.34423 13.7042 5.2708L12.8855 4.45205L8.88234 8.45517C8.83391 8.50361 8.75422 8.50361 8.70578 8.45517L8.04328 7.79267C7.99484 7.74423 7.99484 7.66455 8.04328 7.61611L12.0448 3.61142Z"
                        fill="#565656"
                      />
                    </svg>
                    {/* <p className="whitespace-nowrap">Export</p> */}
                  </Button>
                </div>
              </div>
              <div className="flex items-center">
                <div className="relative flex items-center">
                  <img
                    src={Search}
                    alt="Search Icon"
                    className="absolute left-3 w-4 h-4"
                  />
                  <InputField
                    placeholder="Search for requests"
                    className="pl-10 w-full py-2 border rounded-md border[#CCCCCC] outline-none md:w-[350px] !border-silver"
                  />
                </div>

                <div className="ml-2">
                  <Button
                    neutral
                    className="flex text-white  text-xs font-medium bg-[#2FA06A] items-center mb-[10px] md:mb-0  py-[12px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px]"
                  >
                    <p className="whitespace-nowrap">Search</p>
                  </Button>
                </div>
              </div>
            </div>
            <Card className="p-[24px] rounded-[10px]">
              <div className="flex justify-between">
                <div className="flex flex-col">
                  <p className="mb-[10px] text-black text-sm font-medium md:mb-0">
                    Direct Fund Requests
                  </p>
                  <p className="mb-[10px] text-xs font-normal text-textColor md:mb-0">
                    List of all approved Direct funding requests you have
                    accepted
                  </p>
                </div>

                <div className="flex">
                  <div className="pr-2">
                    <Button
                      neutral
                      onClick={handleSelectAll}
                      className={`py-[12px] mt-[10px] md:mt-0 px-[24px] rounded-[5px] font-medium ${
                        isAnySelected
                          ? "bg-[#2FA06A] text-sm !text-white"
                          : "bg-[#2FA06A] text-sm !text-white"
                      }`}
                    >
                      Select all
                    </Button>
                  </div>
                  <div>
                    <Button
                      neutral
                      className={`py-[12px] mt-[10px] md:mt-0 px-[24px] rounded-[5px] font-medium ${
                        isAnySelected
                          ? //   #F08000
                            "bg-[#F08000] text-sm !text-white"
                          : "bg-[#C2C0C0] text-sm !text-white"
                      }`}
                    >
                      Make Payment
                    </Button>
                  </div>
                </div>
              </div>
              <br />
              <div className="mt5 mx6 mb-[17px] w-full cursor-pointer rounded-md bg-white">
                <div className="gap1 flex-col items-center justify-between gap-10 p-4 px-4 md:flex md:flex-row">
                  <div className="flex-col gap-10 md:flex md:flex-row">
                    <div
                      className={
                        tab === "Single Request"
                          ? "rounded-l-sm rounded-r-sm border-[#F08000] font-bold text-[#F08000] md:mb-[-18px] md:border-b-4"
                          : "text-gray500 my-2 text-base font-normal md:my-0"
                      }
                      onClick={() => handleRequestTabClick("Single Request")}
                    >
                      Single Requests
                    </div>
                    <div
                      className={
                        tab === "Bulk Request"
                          ? "rounded-l-sm rounded-r-sm border-[#F08000] font-bold text-[#F08000] md:mb-[-18px] md:border-b-4"
                          : "text-gray500 my-2 text-base font-normal md:my-0"
                      }
                      onClick={() => handleRequestTabClick("Bulk Request")}
                    >
                      Bulk Requests
                    </div>
                  </div>
                </div>
              </div>

              {tab === "Single Request" && (
                <div className=" bg-white rounded-b-[10px]">
                  <Table
                    columns={columnSingleRequestAccepted}
                    pointer
                    data={SingleRequestAcceptedData}
                    onRowClicked={onSingleRowClickedAccepted}
                  />
                </div>
              )}

              {tab === "Bulk Request" && (
                <div className=" bg-white rounded-b-[10px]">
                  <Table
                    columns={columnBulkRequestAccepted}
                    pointer
                    data={BulkRequestAcceptedData}
                    onRowClicked={onSingleRowClickedAcceptedBulk}
                  />
                </div>
              )}
            </Card>
          </div>
        )}

        {selectedTab === "Rejected" && (
          <div>
            <div className="flex justify-between">
              <div className="flex ">
                <div className="flex gap-4 mb-4">
                  <div className="relative">
                    <DatePicker
                      selected={startDate}
                      onChange={(update) => setDateRange(update)}
                      startDate={startDate}
                      endDate={endDate}
                      selectsRange
                      placeholderText="Filters"
                      className="border  border-[#ccc] px-4 py-2 cursor-pointer rounded-md w-60"
                    />

                    <div className="absolute top-2/4 right-3 transform -translate-y-2/4 pointer-events-none">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5 text-gray-400"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                        />
                      </svg>
                    </div>
                  </div>
                </div>

                <div className="flex ml-2">
                  <Button
                    neutral
                    onClick={handleShowDownloadOptions}
                    className="flex !text-[#565656] gap-2 text-xs font-medium items-center mb-[10px] md:mb-0 border border-[#ccc] bg-white py-[12px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px] h-[43px]"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13.75 14.75H2.25C1.97344 14.75 1.75 14.5266 1.75 14.25V2.75C1.75 2.47344 1.97344 2.25 2.25 2.25H7.875C7.94375 2.25 8 2.30625 8 2.375V3.25C8 3.31875 7.94375 3.375 7.875 3.375H2.875V13.625H13.125V8.625C13.125 8.55625 13.1812 8.5 13.25 8.5H14.125C14.1938 8.5 14.25 8.55625 14.25 8.625V14.25C14.25 14.5266 14.0266 14.75 13.75 14.75ZM12.0448 3.61142L11.2292 2.7958C11.1558 2.72236 11.1995 2.5958 11.3027 2.5833L14.1058 2.25517C14.1855 2.2458 14.2542 2.31298 14.2448 2.39423L13.9167 5.19736C13.9042 5.30048 13.7777 5.34423 13.7042 5.2708L12.8855 4.45205L8.88234 8.45517C8.83391 8.50361 8.75422 8.50361 8.70578 8.45517L8.04328 7.79267C7.99484 7.74423 7.99484 7.66455 8.04328 7.61611L12.0448 3.61142Z"
                        fill="#565656"
                      />
                    </svg>
                    <p className="whitespace-nowrap">Export</p>
                  </Button>
                </div>
              </div>
              <div className="flex items-center">
                <div className="relative flex items-center">
                  <img
                    src={Search}
                    alt="Search Icon"
                    className="absolute left-3 w-4 h-4"
                  />
                  <InputField
                    placeholder="Search for requests"
                    className="pl-10 w-full py-2 border rounded-md border[#CCCCCC] outline-none md:w-[350px] !border-silver"
                  />
                </div>

                <div className="ml-2">
                  <Button
                    neutral
                    className="flex text-white  text-xs font-medium bg-[#2FA06A] items-center mb-[10px] md:mb-0  py-[12px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px]"
                  >
                    <p className="whitespace-nowrap">Search</p>
                  </Button>
                </div>
              </div>
            </div>
            <Card className="p-[24px] rounded-[10px]">
              <div className="flex justify-between">
                <div className="flex flex-col">
                  <p className="mb-[10px] text-black text-sm font-medium md:mb-0">
                    Rejected Direct Fund Requests
                  </p>
                  <p className="mb-[10px] text-xs font-normal text-textColor md:mb-0">
                    List of all Direct funding requests you have rejected
                  </p>
                </div>
              </div>
              <br />
              <div className="mt5 mx6 mb-[17px] w-full cursor-pointer rounded-md bg-white">
                <div className="gap1 flex-col items-center justify-between gap-10 p-4 px-4 md:flex md:flex-row">
                  <div className="flex-col gap-10 md:flex md:flex-row">
                    <div
                      className={
                        tab === "Single Request"
                          ? "rounded-l-sm rounded-r-sm border-[#F08000] font-bold text-[#F08000] md:mb-[-18px] md:border-b-4"
                          : "text-gray500 my-2 text-base font-normal md:my-0"
                      }
                      onClick={() => handleRequestTabClick("Single Request")}
                    >
                      Single Requests
                    </div>
                    <div
                      className={
                        tab === "Bulk Request"
                          ? "rounded-l-sm rounded-r-sm border-[#F08000] font-bold text-[#F08000] md:mb-[-18px] md:border-b-4"
                          : "text-gray500 my-2 text-base font-normal md:my-0"
                      }
                      onClick={() => handleRequestTabClick("Bulk Request")}
                    >
                      Bulk Requests
                    </div>
                  </div>
                </div>
              </div>

              {tab === "Single Request" && selectedTab === "Approved" && (
                <div className=" bg-white rounded-b-[10px]">
                  <Table
                    columns={column}
                    pointer
                    data={data}
                    onRowClicked={onRowClicked}
                  />
                </div>
              )}

              {tab === "Single Request" && selectedTab === "Rejected" && (
                <div className=" bg-white rounded-b-[10px]">
                  <Table
                    columns={ColumnSingleRequestRejected}
                    pointer
                    data={SingleRequestRejectedData}
                    onRowClicked={onSingleRequestRowRejectedClicked}
                  />
                </div>
              )}

              {tab === "Bulk Request" && selectedTab === "Approved" && (
                <div className=" bg-white rounded-b-[10px]">
                  <Table
                    columns={columnSingleRequest}
                    pointer
                    data={SingleRequestData}
                    onRowClicked={onRowClicked}
                  />
                </div>
              )}

              {tab === "Bulk Request" && selectedTab === "Rejected" && (
                <div className=" bg-white rounded-b-[10px]">
                  <Table
                    columns={columnRejectedBulkRequest}
                    pointer
                    data={BulkRequestRejectedData}
                    onRowClicked={onRowRejectedClicked}
                  />
                </div>
              )}
            </Card>
          </div>
        )}
      </DashboardLayout>
    </>
  );
};

export default FunderFactoringDirectFunding;
