import {
  PAIconIconParkSucess,
  PAIconInventoryArrowLeft,
  PAIconMDISuccess,
  // PAIconUnlockPad,
  // PAIconViewMini,
} from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import Table from "layout/table/Table";
import { useNavigate } from "react-router-dom";
import Card from "shared/Card";
import TableHeader from "shared/tableHeader/TableHeader";
import { useState } from "react";
import FundAccountOption from "./components/modals/SelectAccountModal";
import AcceptModal from "shared/AcceptModal";
import { useSelector } from "react-redux";
import PinModal from "shared/PinModal";
import toast from "react-hot-toast";

function PfpTableDetails() {
  const navigate = useNavigate();
  // const [showCheckbox, setShowCheckbox] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [hasSelected, setHasSelected] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isFundAccountOptionOpen, setIsFundAccountOptionOpen] = useState(false);
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [fundAccountPinModal, setFundAccountPinModal] = useState(false);
  const [selectedInvoicesTotalAmount, setSelectedInvoicesTotalAmount] =
    useState(0);
  const selectedAccounts = useSelector(
    (state) => state?.wallet?.selectedAccounts
  );

  // const onRowClicked = () => {
  //   let path = "1";
  //   navigate(path);
  // };

  function handleGoback() {
    let path = -1;
    navigate(path);
  }

  const options = [
    { id: 1, name: "All" },
    { id: 2, name: "ACTIVE" },
    { id: 3, name: "PENDING_APPROVAL" },
    { id: 4, name: "APPROVED" },
    { id: 5, name: "REJECTED" },
  ];

  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
    // setShowCheckbox(event.target.value === "ACTIVE");
  };

  const dummyData = [
    {
      id: 1,
      invoiceReference: "INV-12345",
      currency: "USD",
      dueDate: "2024-03-12",
      invoicedAmount: 10000,
      paymentDate: "2024-03-15",
      offerAmount: 9000,
      funderShare: 8000,
      fundableAmount: 7000,
      status: "ACTIVE",
      action: "View",
    },
    {
      id: 2,
      invoiceReference: "INV-67890",
      currency: "EUR",
      dueDate: "2024-04-15",
      invoicedAmount: 5000,
      paymentDate: "2024-04-18",
      offerAmount: 4500,
      funderShare: 4000,
      fundableAmount: 3500,
      status: "PENDING",
      action: "View",
    },
    {
      id: 3,
      invoiceReference: "INV-11122",
      currency: "GBP",
      dueDate: "2024-05-20",
      invoicedAmount: 2500,
      paymentDate: "2024-05-23",
      offerAmount: 2250,
      funderShare: 2000,
      fundableAmount: 1750,
      status: "REJECTED",
      action: "View",
    },
    {
      id: 4,
      invoiceReference: "INV-33344",
      currency: "USD",
      dueDate: "2024-06-15",
      invoicedAmount: 7500,
      paymentDate: "2024-06-18",
      offerAmount: 6750,
      funderShare: 6000,
      fundableAmount: 5250,
      status: "ACCEPTED",
      action: "View",
    },
    {
      id: 5,
      invoiceReference: "INV-12345",
      currency: "NGN",
      dueDate: "2024-03-12",
      invoicedAmount: 10000,
      paymentDate: "2024-03-15",
      offerAmount: 9000,
      funderShare: 8000,
      fundableAmount: 7000,
      status: "ACTIVE",
      action: "View",
    },
  ];

  const InvoiceDetails = (id, status) => {
    // let path = "/collections/recievables";
    let path = "";

    switch (status) {
      case "ACCEPTED":
        path = `/payablefinancing-campaign/invoice/${id}`;
        break;
      case "REJECTED":
        path = `/payablefinancing-campaign/rejected/${id}`;
        break;
      case "PENDING":
        path = `/payablefinancing-campaign/invoice/${id}`;
        break;
      case "ACTIVE":
        path = `/payablefinancing-campaign/invoice/${id}`;
        break;
      default:
        break;
    }

    navigate(path);
  };

  // const handleCheckboxChange = (id) => {
  //   setSelectedInvoices((prevSelected) => {
  //     const newSelected = prevSelected.includes(id)
  //       ? prevSelected.filter((invoiceId) => invoiceId !== id)
  //       : [...prevSelected, id];

  //     setHasSelected(newSelected.length > 0);
  //     return newSelected;
  //   });
  // };

  const handleCheckboxChange = (id) => {
    setSelectedInvoices((prevSelected) => {
      const newSelected = prevSelected.includes(id)
        ? prevSelected.filter((invoiceId) => invoiceId !== id)
        : [...prevSelected, id];

      setHasSelected(newSelected.length > 0);
      calculateTotalAmount(newSelected);
      return newSelected;
    });
  };

  const handleSelectAllChange = (e) => {
    const isChecked = e.target.checked;
    setIsSelectAllChecked(isChecked);
    if (isChecked) {
      const allIds = filteredData.map((invoice) => invoice.id);
      setSelectedInvoices(allIds);
      setHasSelected(true);
      calculateTotalAmount(allIds);
    } else {
      setSelectedInvoices([]);
      setHasSelected(false);
      setSelectedInvoicesTotalAmount(0);
    }
  };

  // const handleSelectAllChange = (e) => {
  //   const isChecked = e.target.checked;
  //   setIsSelectAllChecked(isChecked);
  //   if (isChecked) {
  //     setSelectedInvoices(filteredData.map((invoice) => invoice.id));
  //     setHasSelected(true);
  //   } else {
  //     setSelectedInvoices([]);
  //     setHasSelected(false);
  //   }
  // };

  const calculateTotalAmount = (selectedIds) => {
    const totalAmount = selectedIds.reduce((total, id) => {
      const invoice = dummyData.find((invoice) => invoice.id === id);
      return total + (invoice ? invoice.invoicedAmount : 0);
    }, 0);
    setSelectedInvoicesTotalAmount(totalAmount);
  };

  const handleApproval = () => {
    const selectedIds = selectedInvoices;
    if (selectedIds.length > 0) {
      setIsFundAccountOptionOpen(true);
    }
  };

  const handleAccountSubmit = () => {
    setIsFundAccountOptionOpen(false);
    setIsAcceptModalOpen(true);
  };

  const AcceptModalConfirm = () => {
    setIsAcceptModalOpen(false);
    setFundAccountPinModal(true);
  };

  const handleFundInvoice = (id) => {
    const invoice = dummyData.find((invoice) => invoice.id === id);
    if (invoice) {
      setSelectedInvoicesTotalAmount(invoice.invoicedAmount);
      setIsFundAccountOptionOpen(true);
    }
  };

  const filteredData = dummyData.filter((invoice) => {
    if (selectedFilter === "All") return true;
    return invoice.status === selectedFilter;
  });

  function handleDownloadSuccessful() {
    setFundAccountPinModal(false);

    toast.custom(
      <>
        <div className="flex-1 items-center justify-center flex  w-full h-full">
          <div className="flex mt-[200px]">
            <div className="bg-primaryColor w-[147.79px]  h-[160px] flex flex-col items-center justify-center rounded-l-[7px] shadow-lg">
              <PAIconMDISuccess />
            </div>
            <div className="bg-white  flex-col justify-center items-center self-stretch py-[25px] h-[160px] rounded-r-[7px] shadow-lg flex px-16 ">
              <PAIconIconParkSucess />
              <p className="text-dustyGray text-[13px] text-center mt-[17.6px]">
                Account{" "}
                <span className="font-bold text-primaryColor">
                  {" "}
                  {selectedAccounts?.accountNumber}{" "}
                </span>{" "}
                <br /> successfully been liened to fund <br /> invoice
              </p>
            </div>
          </div>
        </div>
      </>
    );

    setTimeout(() => {
      toast.dismiss();
    }, 500);
  }

  const columns = [
    ...(selectedFilter === "ACTIVE"
      ? [
          {
            name: (
              <input
                type="checkbox"
                onChange={handleSelectAllChange}
                checked={isSelectAllChecked}
              />
            ),
            cell: (row) => (
              <input
                type="checkbox"
                checked={selectedInvoices.includes(row.id)}
                onChange={() => handleCheckboxChange(row.id)}
              />
            ),
            width: "50px",
          },
        ]
      : []),
    {
      name: "Invoice Reference",
      selector: (row) => row.invoiceReference,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
      width: "6rem",
    },
    {
      name: "Due Date",
      selector: (row) => row.dueDate,
      sortable: true,
    },
    {
      name: "Payment Date",
      selector: (row) => row.paymentDate,
      sortable: true,
    },
    {
      name: "Invoice Amount",
      selector: (row) =>
        Number(row.invoicedAmount).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      sortable: true,
    },
    {
      name: "Offer Amount",
      selector: (row) =>
        Number(row.offerAmount).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      sortable: true,
    },
    {
      name: "Funder Share",
      selector: (row) =>
        Number(row.funderShare).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      sortable: true,
    },
    {
      name: "Fundable Amount",
      selector: (row) =>
        Number(row.fundableAmount).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => (
        <span
          style={{
            color:
              row.status === "ACCEPTED"
                ? "green"
                : row.status === "ACTIVE"
                ? "#90EE90"
                : row.status === "REJECTED"
                ? "red"
                : "orange",
            // fontWeight: "bold",
          }}
        >
          {row.status}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <button
          className={`py-2 px-4 rounded text-white ${
            row.status === "ACTIVE"
              ? "bg-green-900"
              : "bg-gray300 cursor-not-allowed"
          }`}
          disabled={row.status !== "ACTIVE"}
          onClick={() => handleFundInvoice(row.id)}
        >
          Fund
        </button>
      ),
      width: "6rem",
    },
  ];

  return (
    <DashboardLayout>
      <div className="min-h-full">
        <div
          onClick={handleGoback}
          className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
        >
          <PAIconInventoryArrowLeft />
          <p className="mt-[2px] text-sm font-normal text-lightGray">Go Back</p>
        </div>
        <div className="mt-[17px] min-h-full">
          <Card className="shadow-md min-h-[80%] p-[20px] rounded-[10px]">
            <div>
              <TableHeader
                total={`${filteredData.length} Invoices`}
                btnName="Fund Invoices"
                options={options}
                handleChange={handleFilterChange}
                details={
                  <span className="text-[#5F4D61]">
                    List of invoices in{" "}
                    <span className="font-bold">Payable flex</span> Payable
                    finance program
                  </span>
                }
                // payableFinance
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                handleSearch={() => {}}
                funderPayableFinance
                approvalName="Fund Invoice"
                handleApproval={handleApproval}
                active={hasSelected}
                nonActive={!hasSelected}
              />
            </div>
            <Table
              columns={columns}
              data={filteredData}
              // selectableRows
              // onRowClicked={onRowClicked}
              onRowClicked={(row) => InvoiceDetails(row.id, row.status)}
              pointer
              tableHeader
              className="mt-5"
            />
          </Card>
        </div>
      </div>

      <FundAccountOption
        isOpen={isFundAccountOptionOpen}
        onClose={() => setIsFundAccountOptionOpen(false)}
        HeaderText={
          <p>
            Select which account to fund with <br />{" "}
            <span className="text-xs text-center font-base">
              Amount of invoice: NGN{" "}
              {selectedInvoicesTotalAmount.toLocaleString()}
            </span>
          </p>
        }
        onSubmit={handleAccountSubmit}
      />

      <AcceptModal
        isOpen={isAcceptModalOpen}
        onClose={() => setIsAcceptModalOpen(false)}
        message={
          <>
            {selectedInvoices.length >= 1 ? (
              <p>
                Active invoice selected:{" "}
                <span className="text-primaryColor font-bold">
                  {selectedInvoices.length}{" "}
                </span>{" "}
              </p>
            ) : null}
            <p className="mb-3">
              Total invoice amount:{" "}
              <span className="text-primaryColor font-bold">
                {" "}
                NGN {selectedInvoicesTotalAmount.toLocaleString()}{" "}
              </span>
            </p>
            Would you like to proceed to fund the invoice with <br /> this
            account{" "}
            <span className="text-primaryColor font-bold">
              {selectedAccounts?.accountNumber}
            </span>{" "}
            ?
          </>
        }
        onConfirm={AcceptModalConfirm}
        fund
      />
      <PinModal
        isOpen={fundAccountPinModal}
        onClose={() => {
          setFundAccountPinModal(false);
        }}
        onSubmit={handleDownloadSuccessful}
        // isLoading={isLoading}
        headerText="Fund Account"
        instructionText="Enter your transaction PIN to use the desired account for this transaction."
      />
    </DashboardLayout>
  );
}

export default PfpTableDetails;
