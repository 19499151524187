import React, { useEffect, useRef, useState } from "react";
import {
  PAIconDownload,
  PAIconFilter,
  PAIconOption,
  PAIconOutlineSearch,
} from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";
import Table from "layout/table/Table";
import AcceptModal from "shared/AcceptModal";
import SuccessModal from "shared/SuccessModal";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "shared/Pagination";
import Loading from "shared/Loading";
import {
  supplierBulkEPR,
  supplierSingleEPR,
} from "appstate/collections/collectionSlice";
import { remainder } from "appstate/collections/collectionSlice";

const EarlyPayments = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [singleSelectedRows, setSingleSelectedRows] = useState([]);
  const [bulkSelectedRows, setBulkSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [activeTab, setActiveTab] = useState("single");

  const navigate = useNavigate();

  const { supplierBulkEPRData, supplierSingleEPRData, isLoading } = useSelector(
    (state) => state?.collection
  );

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen({});
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const dispatch = useDispatch();

  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const pageSize = 10;

  const handleSearch = () => {
    if (activeTab === "single") {
      dispatch(
        supplierSingleEPR({
          search: searchQuery,
        })
      );
    } else {
      dispatch(
        supplierBulkEPR({
          search: searchQuery,
        })
      );
    }
  };

  useEffect(() => {
    dispatch(
      supplierBulkEPR({
        page,
        pageSize,
        search: searchQuery,
      })
    );
  }, [dispatch, page, pageSize, searchQuery]);

  useEffect(() => {
    dispatch(
      supplierSingleEPR({
        page,
        pageSize,
        search: searchQuery,
      })
    );
  }, [dispatch, page, pageSize, searchQuery]);

  const handleNotificationClick = (row) => {
    // Determine the selected rows state based on the active tab
    const isSingleTab = activeTab === "single";
    const selectedRowList = isSingleTab ? singleSelectedRows : bulkSelectedRows;

    const isSelected = selectedRowList.includes(row.id);
    let newSelectedRows = [];

    if (!isSelected) {
      newSelectedRows = [...selectedRowList, row.id];
    }

    // Update the appropriate state based on the active tab
    if (isSingleTab) {
      setSingleSelectedRows(newSelectedRows);
    } else {
      setBulkSelectedRows(newSelectedRows);
    }

    setIsAcceptModalOpen(true);
  };

  const handleConfirm = () => {
    setIsAcceptModalOpen(false);

    const selectedInvoiceIds =
      activeTab === "single" ? singleSelectedRows : bulkSelectedRows;

    if (selectedInvoiceIds?.length > 0) {
      dispatch(
        remainder({ type: "earlypayment", body: selectedInvoiceIds })
      ).then((response) => {
        if (response?.payload?.status === 200 && response?.payload?.success) {
          setIsSuccessModalOpen(true);
          setSuccessMessage(response?.payload?.message);
        }
      });
    }
  };

  const handleCloseSuccessModal = () => {
    setIsSuccessModalOpen(false);
  };

  const singleDetails = (earlyPaymentId, invoiceId) => {
    // let path = `/collections/earlypayment/${earlyPaymentId}`;
    // navigate(path);
    let path = `/collections/earlypayment/${earlyPaymentId}?invoice=${invoiceId}`;
    navigate(path);
  };

  const bulkDetails = (earlyPaymentId) => {
    let path = `/collections/bulk-earlypayment/${earlyPaymentId}`;
    navigate(path);
  };

  const handleSendReminder = () => {
    const rowsToProcess =
      activeTab === "single" ? singleSelectedRows : bulkSelectedRows;
    if (rowsToProcess.length > 0) {
      setIsAcceptModalOpen(true);
    }
  };

  const handleSelectAll = () => {
    if (activeTab === "single") {
      if (selectAll) {
        setSingleSelectedRows([]);
      } else {
        const allIds = supplierSingleEPRData?.data?.map((row) => row.id) || [];
        setSingleSelectedRows(allIds);
      }
    } else if (activeTab === "bulk") {
      if (selectAll) {
        setBulkSelectedRows([]);
      } else {
        const allIds = supplierBulkEPRData?.data?.map((row) => row.id) || [];
        setBulkSelectedRows(allIds);
      }
    }
    setSelectAll(!selectAll);
  };

  const columns = [
    {
      name: (
        <input
          type="checkbox"
          onChange={handleSelectAll}
          checked={
            activeTab === "single"
              ? singleSelectedRows.length ===
                (supplierSingleEPRData?.data?.length || 0)
              : bulkSelectedRows.length ===
                (supplierBulkEPRData?.data?.length || 0)
          }
        />
      ),
      cell: (row) => (
        <input
          type="checkbox"
          onChange={() => handleCheckboxChange(row)}
          checked={
            activeTab === "single"
              ? singleSelectedRows.includes(row.id)
              : bulkSelectedRows.includes(row.id)
          }
        />
      ),
      width: "50px",
    },
    {
      name: (
        <span className="whitespace-normal">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Request ref Number
        </span>
      ),
      selector: (row) => row.requestReference,
      sortable: true,
    },
    {
      name: "Buyer",
      selector: (row) => row.buyer,
      sortable: true,
    },
    {
      name: "Early Payment Date",
      selector: (row) => row?.paymentDate,
      sortable: true,
      cell: (row) => {
        // Convert the array to a Date object
        const date = new Date( 
          row?.paymentDate[0],
          row?.paymentDate[1] - 1,
          row?.paymentDate[2]
        );

        // Manually format the date as 'YYYY-MM-DD'
        const formattedDate = date?.toLocaleDateString("en-CA");

        return <p>{formattedDate}</p>;
      },
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
    },
    {
      name: activeTab === "single" ? "Request Amount" : "Total Request Amount",
      sortable: true,
      cell: (row) => {
        // Convert the string to a valid number
        const amount =
          activeTab === "single"
            ? parseFloat(row.requestAmount)
            : parseFloat(row.requestAmount);

        // Ensure it's a valid number before formatting
        return (
          <p className="text-success">
            {isNaN(amount) ? "Invalid Amount" : amount.toLocaleString()}
          </p>
        );
      },
    },
    {
      name: "Discount Rate",
      selector: (row) => row.discountRate,
      sortable: true,
    },
    {
      name:
        activeTab === "single" ? "Discount Amount" : "Total Discount Amount",
      // selector: (row) =>
      //   activeTab === "single" ? row.discountAmount : row.totalDiscountAmount,
      sortable: true,
      cell: (row) => {
        // Convert the string to a valid number
        const amount =
          activeTab === "single"
            ? parseFloat(row.discountAmount)
            : parseFloat(row.discountAmount);

        // Ensure it's a valid number before formatting
        return (
          <p className="text-success">
            {isNaN(amount) ? "Invalid Amount" : amount.toLocaleString()}
          </p>
        );
      },
    },
    {
      name:
        activeTab === "single"
          ? "Receiveable Amount"
          : "Total Receiveable Amount",
      selector: (row) => row.receivableAmount,
      sortable: true,
      cell: (row) => (
        <p className="text-success">{row.receivableAmount?.toLocaleString()}</p>
      ),
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <p
          className={
            row.status === "PARTIAL PAYMENT"
              ? "text-green-400"
              : "text-yellow-400"
          }
        >
          {row.status}
        </p>
      ),
    },
    {
      cell: (row) => (
        <div className="flex gap-2">
          {
            <PAIconOption
              title="Options"
              onClick={() => {
                setIsDropdownOpen((prev) => ({
                  ...prev,
                  [row.id]: !prev[row.id],
                }));
                // Close other dropdowns
                Object.keys(isDropdownOpen).forEach((key) => {
                  if (key !== row.id.toString()) {
                    setIsDropdownOpen((prev) => ({ ...prev, [key]: false }));
                  }
                });
              }}
            />
          }
          {isDropdownOpen[row.id] && (
            <div
              ref={dropdownRef}
              className="absolute bg-white border border-gray300 rounded-md shadow-md z-10"
            >
              <ul>
                <li
                  className="px-4 py-2 cursor-pointer hover:bg-primaryColor hover:text-white"
                  onClick={() => {
                    if (activeTab === "single") {
                      singleDetails(row?.id);
                    } else if (activeTab === "bulk") {
                      bulkDetails(row?.id);
                    }
                    setIsDropdownOpen((prev) => ({ ...prev, [row.id]: false }));
                  }}
                >
                  Preview
                </li>
                <li
                  className="px-4 py-2 cursor-pointer hover:bg-primaryColor hover:text-white"
                  onClick={() => {
                    handleNotificationClick(row);
                    setIsDropdownOpen((prev) => ({ ...prev, [row.id]: false }));
                  }}
                >
                  Send Reminder
                </li>
                <li className="px-4 py-2 cursor-not-allowed text-gray300">
                  Export
                </li>
              </ul>
            </div>
          )}
        </div>
      ),
    },
  ];

  const handleCheckboxChange = (row) => {
    if (activeTab === "single") {
      const isSelected = singleSelectedRows.includes(row.id);
      setSingleSelectedRows(
        isSelected
          ? singleSelectedRows.filter((id) => id !== row.id)
          : [...singleSelectedRows, row.id]
      );
    } else if (activeTab === "bulk") {
      const isSelected = bulkSelectedRows.includes(row.id);
      setBulkSelectedRows(
        isSelected
          ? bulkSelectedRows.filter((id) => id !== row.id)
          : [...bulkSelectedRows, row.id]
      );
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    // Reset selected rows when switching tabs
    if (tab === "single") {
      setBulkSelectedRows([]);
    } else {
      setSingleSelectedRows([]);
    }
    setSelectAll(false);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "single":
        return (
          <>
            <Table
              columns={columns}
              data={supplierSingleEPRData?.data}
              onRowClicked={(row) =>
                singleDetails(row.earlyPaymentId, row.invoiceId)
              }
              progressPending={isLoading}
              progressComponent={<Loading />}
              pointer
              tableHeader
              className="mt-5"
              noDataComponent={<TableEmptyState dashboard />}
            />
            <div className="flex justify-center gap-x-[8px] mt-[20px]">
              {supplierSingleEPRData?.meta?.totalElements > 10 && (
                <Pagination
                  page={page}
                  startPage={startPage}
                  setEndPage={setEndPage}
                  endPage={endPage}
                  setStartPage={setStartPage}
                  setPage={setPage}
                  totalPages={supplierSingleEPRData?.meta?.totalElements}
                />
              )}
            </div>
          </>
        );
      case "bulk":
        return (
          <>
            <Table
              columns={columns}
              data={supplierBulkEPRData?.data}
              onRowClicked={(row) => bulkDetails(row.earlyPaymentId)}
              progressPending={isLoading}
              progressComponent={<Loading />}
              pointer
              tableHeader
              className="mt-5"
              noDataComponent={<TableEmptyState dashboard />}
            />
            <div className="flex justify-center gap-x-[8px] mt-[20px]">
              {supplierBulkEPRData?.meta?.totalElements > 10 && (
                <Pagination
                  page={page}
                  startPage={startPage}
                  setEndPage={setEndPage}
                  endPage={endPage}
                  setStartPage={setStartPage}
                  setPage={setPage}
                  totalPages={supplierBulkEPRData?.meta?.totalElements}
                />
              )}
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className="my-2 grid md:grid-cols-2 items-center">
        <div className="gap3 flex relative">
          <div>{/* FILTER (commented out for now) */}</div>

          {/* EXPORT */}
          <button className="py-2 px-4 text-sm rounded-[5px] bg-white border-[#DEDEDE] shadow-sm mr2 flex items-center justify-center gap-1.5">
            Export {<PAIconDownload className="accent-gray300 w-4" />}
          </button>
        </div>

        {/* SEARCH */}
        <div className="flex md:col-span-1 justify-end">
          <div className="flex items-center justify-between gap-5">
            <div className="border border-transparent md:border-gray md:shadow-sm my-4 rounded-[5px] px-2 lg:w-[300px] bg-white">
              <div className="items-center flex bg-white">
                <PAIconOutlineSearch />
                <input
                  className="bg-transparent p-2 w-full focus:outline-none text-sm bg-white"
                  type="text"
                  placeholder="Search for invoices awaiting collection"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleSearch();
                    }
                  }}
                />
              </div>
              <div className="lg:hidden flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer">
                <PAIconFilter className="bg-[#2FA06A]" />
              </div>
            </div>
            <button
              className="hidden lg:flex bg-[#2FA06A] rounded-md my-4 py-2 px-6 mx3 items-center gap-2 text-white"
              onClick={handleSearch}
            >
              Search
            </button>
          </div>
        </div>
      </div>

      {/* TABLE */}
      <div>
        {!supplierSingleEPRData?.data?.length &&
        !supplierBulkEPRData?.data?.length &&
        !isLoading ? (
          <div>
            <div className="flex justify-between items-center px-[15px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
              <p className="text-black text-sm font-medium">
                Invoices from Early Payment Requests awaiting collection
              </p>
            </div>
            <TableEmptyState dashboard />
          </div>
        ) : (
          <Card className="rounded-[10px] mt-[15px]">
            <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
              <div>
                <p className="text-black text-lg font-bold">
                  Invoices from Early Payment Requests awaiting collection
                </p>
                <span className="text-xs font-thin">
                  List of all invoices from early payments requests channel
                </span>
              </div>
              <div className="flex items-center justify-center gap-3">
                <button
                  className={`border border-gray300 rounded-md p-2 text-white ${
                    (activeTab === "single" &&
                      singleSelectedRows?.length === 0) ||
                    (activeTab === "bulk" && bulkSelectedRows?.length === 0)
                      ? "cursor-not-allowed bg-gray300"
                      : "cursor-pointer bg-secondaryColor"
                  }`}
                  disabled={
                    (activeTab === "single" &&
                      singleSelectedRows?.length === 0) ||
                    (activeTab === "bulk" && bulkSelectedRows?.length === 0)
                  }
                  onClick={handleSendReminder}
                >
                  <p>Send Reminder Now</p>
                </button>
              </div>
            </div>
            <div className="pb-[24px]">
              <div className="flex px-[24px] gap-3 my-3">
                <p
                  className={`cursor-pointer font-bold relative ${
                    activeTab === "single" ? "text-[#F08000]" : "text-[#959595]"
                  }`}
                  onClick={() => handleTabClick("single")}
                >
                  Single requests
                  {activeTab === "single" && (
                    <span
                      className="absolute bottom-0 left-1/2 transform -translate-x-1/2
                 bg-[#F08000] h-0.5 w-5"
                    ></span>
                  )}
                </p>

                <p
                  className={`cursor-pointer font-bold relative ${
                    activeTab === "bulk" ? "text-[#F08000]" : "text-[#959595]"
                  }`}
                  onClick={() => handleTabClick("bulk")}
                >
                  Bulk requests
                  {activeTab === "bulk" && (
                    <span
                      className="absolute bottom-0 left-1/2 transform -translate-x-1/2
                 bg-[#F08000] h-0.5 w-5"
                    ></span>
                  )}
                </p>
              </div>

              <div className="mt-3">{renderTabContent()}</div>
            </div>
          </Card>
        )}
      </div>

      <AcceptModal
        isOpen={isAcceptModalOpen}
        onClose={() => setIsAcceptModalOpen(false)}
        yes
        message={
          <>
            Would you like to proceed with sending a <br />
            <span className="text-[#07593D] font-bold">
              Payment reminder
            </span>{" "}
            now?
          </>
        }
        onConfirm={handleConfirm}
      />

      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={handleCloseSuccessModal}
        message={successMessage}
      />
    </>
  );
};

export default EarlyPayments;
