// import { PAIconCancel, PAIconOption } from "assets/images/svgs";
import React, { useEffect, useState } from "react";
import TableIcons from "../../../../assets/images/svgs/TableIcons.svg";
import Table from "layout/table/Table";
import InputField from "shared/InputField";
import Search from "../../../../assets/images/svgs/search-normal.svg";
import { useNavigate } from "react-router-dom";
import ArrowDown from "../../../../assets/images/svgs/downarrow.svg";
import SuccessEarlyPayment from "features/invoice/fundingSection/earlyPaymentRequest/singlerequest/Modal/SuccessEarlyPayment";
import SuccessUpcomingReimbursement from "features/wallet/fund/externalSource/successupcomingreimbursement";
import ConfirmingPaymentReimbursement from "features/wallet/fund/externalSource/confirmingpaymentreimbursement";
import FundWalletDetails from "features/wallet/fund/externalSource/fundWallet";
import PayProgressModal from "features/payableFinaceForFunder/CloseMarket/modals/PayProgressModal";
import PinModalPayment from "features/invoice/fundingSection/earlyPaymentRequest/singlerequest/Modal/PinModal";
import AcceptModal from "shared/AcceptModal";
// import Search from "../../assets/images/svgs/search-normal.svg";

const data = [
  {
    id: "1",
    invoiceRef: "INV12345",
    supplier: "Agaba Dev",
    dueDate: "2024-12-15",
    currency: "NGN",
    invoiceAmount: 850000,
    discountedAmount: 800000,
    paymentDate: "2024-12-10",

    amount: 9000000,
    status: "Successful",
    transactionType: "Balance Payment",
    channel: "Direct Funding",
    transType: "Reimbursed",
    invoiceNo: "4",
    discountRate: "1.5",
  },
  {
    id: "2",
    invoiceRef: "INV12345",
    supplier: "Agaba Dev",
    dueDate: "2024-12-15",
    currency: "NGN",
    invoiceAmount: 850000,
    discountedAmount: 800000,
    paymentDate: "2024-12-10",

    amount: 9000000,
    status: "Successful",
    transactionType: "Balance Payment",
    channel: "Direct Funding",
    transType: "Reimbursed",
    invoiceNo: "4",
    discountRate: "1.5",
  },
];

const bulkdata = [
  {
    invoiceRef: "INV12345",
    supplier: "Agaba Dev",
    dueDate: "2024-12-15",
    currency: "NGN",
    invoiceAmount: 850000,
    discountedAmount: 800000,
    totalPayableAmount: 780000,
    totalDiscountAmount: 60100,
    invoiceNo: 6,
    paymentDate: "2024-12-10",
  },
];

const ReimbursementWithoutRecourse = ({ selectedStatus }) => {
  const [selectedRequest, setSelectedRequest] = useState("Single Request");
  const [selectedOption, setSelectedOption] = useState(
    "Direct Funding Request"
  );

  const [showDropdown, setShowDropdown] = useState(false);
  const [showDirectFunding, setShowDirectFunding] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [showPin, setShowPin] = useState(false);
  const [showTransferFundsUpcoming, setShowTransferFundsUpcoming] =
    useState(false);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [showSuccessUpcoming, setShowSuccessUpcoming] = useState(false);
  const [otpInputs, setOtpInputs] = useState(["", "", "", ""]);
  const [transferFunds, setTransferFunds] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();

  const HeaderWithIcon = ({ icon, text }) => (
    <div className="flex items-center">
      <img src={icon} alt="" className="mr-2" />
      <span>{text}</span>
    </div>
  );

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handlePayNow = () => {
    setOpen(!open);
  };

  const onRowClicked = () => {
    let path = "/reimbursement-with-recourse-details-single";
    // console.log("===single clicked===");
    navigate(path, {
      state: { selectedStatus: selectedStatus, selectedOption: selectedOption },
    });
  };

  // liened-closed-market-reimbursement-with-out-recourse-details-bulk

  const onRowClickedClosedMarket = () => {
    let path =
      "/liened-closed-market-reimbursement-with-out-recourse-details-bulk";
    // console.log("===single clicked===");
    navigate(path, {
      state: { selectedStatus: selectedStatus, selectedOption: selectedOption },
    });
  };

  const onRowClickedBulk = () => {
    let path = "/reimbursement-with-recourse-details-bulk";
    // console.log("===bulk clicked===");

    navigate(path, { state: { selectedStatus, recourse: false } });
  };

  // const onRowClickedOpenMarket = (row) => {
  //   let path = "/reimbursement-with-recourse-details-open-market";
  //   navigate(path, { state: { selectedStatus } });
  // };

  const handleDropdownChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedInvoices([]);
    } else {
      const allInvoiceIds = data.map((invoice) => invoice.invoiceId);
      setSelectedInvoices(allInvoiceIds);
    }
    setSelectAll(!selectAll);
  };

  const handleCheckboxChange = (invoiceId) => {
    setSelectedInvoices((prevSelected) =>
      prevSelected.includes(invoiceId)
        ? prevSelected.filter((id) => id !== invoiceId)
        : [...prevSelected, invoiceId]
    );
  };

  const column = [
    // {
    //   selector: (row) => (
    //     <div className="flex items-center bg-red">
    //       <input
    //         type="checkbox"
    //         checked={selectedInvoices.includes(row?.invoiceId)}
    //         onChange={() => handleCheckboxChange(row?.invoiceId)}
    //         className={` w-4 h-4 appearance-none border-2 rounded-sm ${
    //           selectedInvoices.includes(row?.invoiceId)
    //             ? "bg-[#F08000] border-[#F08000]"
    //             : "bg-[#C2C0C0] border-[#C2C0C0]"
    //         } cursor-pointer`}
    //         style={{
    //           outline: "none",
    //         }}
    //       />
    //     </div>
    //   ),
    // },
    {
      name: <HeaderWithIcon icon={TableIcons} text="Invoice No" />,
      selector: (row) => row?.invoiceRef,
      width: "9rem",
      cell: (row) => (
        <div className="flex items-center gap-4">
          <input
            type="checkbox"
            checked={selectedInvoices.includes(row?.invoiceId)}
            onChange={() => handleCheckboxChange(row?.invoiceId)}
            className={` w-4 h-4 appearance-none border-2 rounded-sm ${
              selectedInvoices.includes(row?.invoiceId)
                ? "bg-[#F08000] border-[#F08000]"
                : "bg-[#C2C0C0] border-[#C2C0C0]"
            } cursor-pointer`}
            style={{
              outline: "none",
            }}
          />

          <span>{row?.invoiceRef}</span>
        </div>
      ),
    },
    ...(selectedRequest === "Single Request"
      ? [
          {
            name: <HeaderWithIcon icon={TableIcons} text="Supplier" />,
            selector: (row) => row?.supplier,
          },
        ]
      : []),
    {
      name: "Due Date",
      selector: (row) => row?.dueDate,
      // width: "9rem",
    },
    {
      name: "Currency",
      selector: (row) => row?.currency,
    },
    {
      name: "Invoice Amount",
      selector: (row) => row?.invoiceAmount,
      cell: (row) => (
        <span style={{ color: "green" }}>
          {row?.currency} {row?.invoiceAmount}
        </span>
      ),
      width: "9rem",
    },

    {
      name: "Discount Amount",
      selector: (row) => row?.discountedAmount,
      cell: (row) => (
        <span style={{ color: "green" }}>
          {row?.currency} {row?.discountedAmount}
        </span>
      ),
      width: "9rem",
    },
    {
      name: "Payable Amount",
      selector: (row) => row?.payableAmount,
      cell: (row) => (
        <span style={{ color: "green" }}>
          {row?.currency} {row?.invoiceAmount}
        </span>
      ),
      width: "9rem",
    },
    {
      name: "Payment Date",
      selector: (row) => row?.paymentDate,
      cell: (row) => (
        <div>
          {selectedStatus === "Liened" && (
            <span style={{ color: "#F08000" }}>{row?.paymentDate}</span>
          )}
          {selectedStatus === "Upcoming" && (
            <span style={{ color: "#F08000" }}>{row?.paymentDate}</span>
          )}
          {selectedStatus === "Overdue" && (
            <span style={{ color: "red" }}>{row?.paymentDate}</span>
          )}
        </div>
      ),
      width: "9rem",
    },
    {
      name: "",
      cell: (row) => (
        <button
          onClick={() => {
            if (selectedStatus === "Liened") {
              handlePayNow(row);
            } else {
              handleDirectFunding();
              // console.log("Fund Request clicked");
            }
          }}
          style={{
            backgroundColor: "#06593D",
            color: "white",
            padding: "8px 16px",
            borderRadius: "8px",
            border: "none",
            cursor: "pointer",
          }}
        >
          {selectedStatus === "Liened" ? "Pay Now" : "Fund Request"}
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  console.log("===SELECETEDTAB===", selectedRequest);

  const columnOpSingle = [
    {
      name: <HeaderWithIcon icon={TableIcons} text="Invoice No" />,
      selector: (row) => row?.invoiceRef,
      width: "9rem",
      cell: (row) => (
        <div className="flex items-center gap-4">
          <input
            type="checkbox"
            checked={selectedInvoices.includes(row?.invoiceId)}
            onChange={() => handleCheckboxChange(row?.invoiceId)}
            className={` w-4 h-4 appearance-none border-2 rounded-sm ${
              selectedInvoices.includes(row?.invoiceId)
                ? "bg-[#F08000] border-[#F08000]"
                : "bg-[#C2C0C0] border-[#C2C0C0]"
            } cursor-pointer`}
            style={{
              outline: "none",
            }}
          />

          <span>{row?.invoiceRef}</span>
        </div>
      ),
    },
    ...(selectedRequest === "Single Request"
      ? [
          {
            name: <HeaderWithIcon icon={TableIcons} text="Supplier" />,
            selector: (row) => row?.supplier,
          },
        ]
      : []),
    {
      name: "Due Date",
      selector: (row) => row?.dueDate,
      // width: "9rem",
    },
    {
      name: "Currency",
      selector: (row) => row?.currency,
    },
    {
      name: "Invoice Amount",
      selector: (row) => row?.invoiceAmount,
      cell: (row) => (
        <span style={{ color: "green" }}>{row?.invoiceAmount}</span>
      ),
      width: "9rem",
    },

    {
      name: "Discount Rate",
      selector: (row) => row?.discountRate,
      cell: (row) => (
        <span style={{ color: "green" }}>{row?.discountRate}</span>
      ),
      width: "9rem",
    },
    {
      name: "Payable Amount",
      selector: (row) => row?.payableAmount,
      cell: (row) => (
        <span style={{ color: "green" }}>{row?.invoiceAmount}</span>
      ),
      width: "9rem",
    },
    {
      name: "Payment Date",
      selector: (row) => row?.paymentDate,
      cell: (row) => (
        <div>
          {selectedStatus === "Liened" && (
            <span style={{ color: "#F08000" }}>{row?.paymentDate}</span>
          )}
          {selectedStatus === "Upcoming" && (
            <span style={{ color: "#F08000" }}>{row?.paymentDate}</span>
          )}
          {selectedStatus === "Overdue" && (
            <span style={{ color: "red" }}>{row?.paymentDate}</span>
          )}
        </div>
      ),
      width: "9rem",
    },
    {
      name: "",
      cell: (row) => (
        <button
          onClick={() => {
            if (selectedStatus === "Liened") {
              handlePayNow(row);
            } else {
              handleDirectFunding();
              // console.log("Fund Request clicked");
            }
          }}
          style={{
            backgroundColor: "#06593D",
            color: "white",
            padding: "8px 16px",
            borderRadius: "8px",
            border: "none",
            cursor: "pointer",
          }}
        >
          {selectedStatus === "Liened" ? "Pay Now" : "Fund Request"}
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const columnClosedMarketLienedSingle = [
    {
      name: <HeaderWithIcon icon={TableIcons} text="Reference No." />,
      selector: (row) => row?.invoiceRef,
      width: "9rem",
      cell: (row) => (
        <div className="flex items-center gap-4">
          <input
            type="checkbox"
            checked={selectedInvoices.includes(row?.invoiceId)}
            onChange={() => handleCheckboxChange(row?.invoiceId)}
            className={` w-4 h-4 appearance-none border-2 rounded-sm ${
              selectedInvoices.includes(row?.invoiceId)
                ? "bg-[#F08000] border-[#F08000]"
                : "bg-[#C2C0C0] border-[#C2C0C0]"
            } cursor-pointer`}
            style={{
              outline: "none",
            }}
          />

          <span>{row?.invoiceRef}</span>
        </div>
      ),
    },
    ...(selectedRequest === "Single Request"
      ? [
          {
            name: <HeaderWithIcon icon={TableIcons} text="Supplier" />,
            selector: (row) => row?.supplier,
          },
        ]
      : []),
    {
      name: "Due Date",
      selector: (row) => row?.dueDate,
      // width: "9rem",
    },
    {
      name: "Currency",
      selector: (row) => row?.currency,
    },
    {
      name: "Invoice Amount",
      selector: (row) => row?.invoiceAmount,
      cell: (row) => (
        <span style={{ color: "green" }}>{row?.invoiceAmount}</span>
      ),
      width: "9rem",
    },

    {
      name: "Discount Amount",
      selector: (row) => row?.discountRate,
      cell: (row) => (
        <span style={{ color: "green" }}>{row?.invoiceAmount}</span>
      ),
      width: "9rem",
    },
    {
      name: "Payable Amount",
      selector: (row) => row?.payableAmount,
      cell: (row) => (
        <span style={{ color: "green" }}>{row?.invoiceAmount}</span>
      ),
      width: "9rem",
    },
    {
      name: "Payment Date",
      selector: (row) => row?.paymentDate,
      cell: (row) => (
        <div>
          {selectedStatus === "Liened" && (
            <span style={{ color: "#F08000" }}>{row?.paymentDate}</span>
          )}
          {selectedStatus === "Upcoming" && (
            <span style={{ color: "#F08000" }}>{row?.paymentDate}</span>
          )}
          {selectedStatus === "Overdue" && (
            <span style={{ color: "red" }}>{row?.paymentDate}</span>
          )}
        </div>
      ),
      width: "9rem",
    },
    {
      name: "",
      cell: (row) => (
        <button
          onClick={() => {
            if (selectedStatus === "Liened") {
              handlePayNow(row);
            } else {
              handleDirectFunding();
              // console.log("Fund Request clicked");
            }
          }}
          style={{
            backgroundColor: "#06593D",
            color: "white",
            padding: "8px 16px",
            borderRadius: "8px",
            border: "none",
            cursor: "pointer",
          }}
        >
          {selectedStatus === "Liened" ? "Pay Now" : "Fund Request"}
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const handleConfirm = (row) => {
    setOpen(false);
    setShowPin(true);
  };

  // setShowDirectFunding(false);

  const handleDirectFunding = () => {
    setShowDirectFunding(!showDirectFunding);
  };

  const handleDirectFundingConfirm = () => {
    setShowDirectFunding(false);
    setShowTransferFundsUpcoming(true);
  };

  const onClosePinModal = () => {
    // setOpen(false);
    setShowPin(false);
  };

  const confirmPayment = () => {
    setShowPin(false);
    setTransferFunds(true);
    let interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prev + 10;
      });
    }, 500);
  };

  const confirmSuccessPaymentUpcoming = () => {
    setShowPin(false);
    setShowSuccessUpcoming(true);
  };

  useEffect(() => {
    if (progress === 100) {
      setTransferFunds(false);
      setShowSuccess(true);
    }
  }, [progress]);

  const onCloseSuccessModal = () => {
    setShowSuccess(false);
    setProgress(0);
  };

  const confirmPaymentUpcoming = () => {
    setShowTransferFundsUpcoming(false);
    setShowVerifyModal(true);
  };

  const confirmVerifyPaymentUpcoming = () => {
    setShowVerifyModal(false);
    setShowPin(true);
  };

  const closeUpcomingSuccessModal = () => {
    setShowSuccessUpcoming(false);
  };
  //   const handlePayNow = (row) => {

  //   };

  const formatAmount = (amount) => {
    if (typeof amount !== "number") return "0.00";
    return amount?.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const dumData = [
    {
      idx: "1",
      companyName: "XYZ Ventures",
      player: "Funder",
      invoiceDate: "2023-05-23",
      invoiceReference: "INV-2090",
      currency: "NGN",
      amount: 9000000,
      status: "Successful",
      transactionType: "Balance Payment",
      channel: "Direct Funding",
      transType: "Reimbursed",
      invoiceNo: "4",
    },
    {
      idx: "4",
      companyName: "XYZ Ventures",
      player: "Funder",
      invoiceDate: "2023-05-23",
      invoiceReference: "INV-2090",
      currency: "NGN",
      amount: 9000000,
      status: "Successful",
      transactionType: "Reimbursed",
      channel: "Direct Funding",
      transType: "Reimbursed",
      invoiceNo: "4",
    },
    {
      idx: "5",
      companyName: "XYZ Ventures",
      player: "Funder",
      invoiceDate: "2023-05-23",
      invoiceReference: "INV-2090",
      currency: "NGN",
      amount: 9000000,
      status: "Successful",
      transactionType: "Balance Payment",
      channel: "Direct Funding",
      transType: "Reimbursed",
      invoiceNo: "4",
    },
    {
      idx: "2",
      companyName: "Dangote Ltd",
      player: "Funder",
      invoiceDate: "2023-05-23",
      invoiceReference: "INV-2090",
      currency: "NGN",
      amount: 9000000,
      status: "Successful",
      transactionType: "Reimbursed",
      channel: "Direct Funding",
      transType: "Reimbursed",
      invoiceNo: "7",
    },
    {
      idx: "3",
      companyName: "XYZ Ventures",
      player: "Funder",
      invoiceDate: "2023-05-23",
      invoiceReference: "INV-2090",
      currency: "NGN",
      amount: 9000000,
      status: "Successful",
      transactionType: "Balance Payment",
      channel: "Open Market",
      transType: "Reimbursed",
      invoiceNo: "6",
    },
    {
      idx: "6",
      companyName: "Dangote Ltd",
      player: "Funder",
      invoiceDate: "2023-05-23",
      invoiceReference: "INV-2090",
      currency: "NGN",
      amount: 9000000,
      status: "Successful",
      transactionType: "Reimbursed",
      channel: "Direct Funding",
      transType: "Reimbursed",
      invoiceNo: "10",
    },
    {
      idx: "7",
      companyName: "XYZ Ventures",
      player: "Funder",
      invoiceDate: "2023-05-23",
      invoiceReference: "INV-2090",
      currency: "NGN",
      amount: 9000000,
      status: "Successful",
      transactionType: "Balance Payment",
      invoiceNo: "4",
    },
    {
      idx: "8",
      companyName: "Dangote Ltd",
      player: "Funder",
      invoiceDate: "2023-05-23",
      invoiceReference: "INV-2090",
      currency: "NGN",
      amount: 9000000,
      status: "Successful",
      transactionType: "Balance Payment",
      channel: "Direct Funding",
      transType: "Reimbursed",
      invoiceNo: "4",
    },
  ];

  const WithRecourseDirectcolumnsBulk = [
    {
      name: <HeaderWithIcon icon={TableIcons} text="Request Ref. No." />,
      selector: (row) => row?.invoiceRef,
      width: "9rem",
      cell: (row) => (
        <div className="flex items-center gap-4">
          <span>{row?.invoiceRef}</span>
        </div>
      ),
    },
    {
      name: <HeaderWithIcon icon={TableIcons} text="Invoice No" />,
      selector: (row) => row?.invoiceRef,
      width: "9rem",
      cell: (row) => (
        <div className="flex items-center gap-4">
          {/* <input
            type="checkbox"
            checked={selectedInvoices.includes(row?.invoiceId)}
            onChange={() => handleCheckboxChange(row?.invoiceId)}
            className={` w-4 h-4 appearance-none border-2 rounded-sm ${
              selectedInvoices.includes(row?.invoiceId)
                ? "bg-[#F08000] border-[#F08000]"
                : "bg-[#C2C0C0] border-[#C2C0C0]"
            } cursor-pointer`}
            style={{
              outline: "none",
            }}
          /> */}

          <span>{row?.invoiceRef}</span>
        </div>
      ),
    },

    {
      name: <HeaderWithIcon icon={TableIcons} text="Supplier" />,
      selector: (row) => row?.invoiceRef,
      width: "9rem",
      cell: (row) => (
        <div className="flex items-center gap-4">
          <span>{row?.invoiceRef}</span>
        </div>
      ),
    },
    ...(selectedRequest === "Single Request"
      ? [
          {
            name: <HeaderWithIcon icon={TableIcons} text="Supplier" />,
            selector: (row) => row?.supplier,
          },
        ]
      : []),
    {
      name: "Request Date",
      selector: (row) => row?.dueDate,
      // width: "9rem",
    },
    {
      name: "No. of invoices",
      selector: (row) => row?.invoiceNo,
      // width: "9rem",
      width: "10rem",
    },

    {
      name: "Total Discount Amount",
      selector: (row) => row?.invoiceAmount,
      cell: (row) => (
        <span style={{ color: "green" }}>
          {row?.currency} {row?.invoiceAmount}
        </span>
      ),
      width: "11rem",
    },

    {
      name: " Total Payable Amount",
      selector: (row) => row?.payableAmount,
      cell: (row) => (
        <span style={{ color: "green" }}>
          {row?.currency} {row?.invoiceAmount}
        </span>
      ),
      width: "11rem",
    },
    {
      name: "Payment Date",
      selector: (row) => row?.paymentDate,
      cell: (row) => (
        <div>
          {selectedStatus === "Liened" && (
            <span style={{ color: "#F08000" }}>{row?.paymentDate}</span>
          )}
          {selectedStatus === "Upcoming" && (
            <span style={{ color: "#F08000" }}>{row?.paymentDate}</span>
          )}
          {selectedStatus === "Overdue" && (
            <span style={{ color: "red" }}>{row?.paymentDate}</span>
          )}
        </div>
      ),
      width: "9rem",
    },
    {
      name: "",
      cell: (row) => (
        <button
          onClick={() => {
            if (selectedStatus === "Liened") {
              handlePayNow(row);
            } else {
              handleDirectFunding();
              // console.log("Fund Request clicked");
            }
          }}
          style={{
            backgroundColor: "#06593D",
            color: "white",
            padding: "8px 16px",
            borderRadius: "8px",
            border: "none",
            cursor: "pointer",
          }}
        >
          {selectedStatus === "Liened" ? "Pay Now" : "Fund Request"}
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const WithRecourseOpenMarketSinglecolumns = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Transaction Party
        </span>
      ),
      selector: (row) => row.companyName,
      sortable: true,
      cell: (row) => (
        <div className="">
          <p>{row.companyName}</p>
          <span
            className={`${
              row.player === "Funder" ? "text-[#2EA923]" : "text-[#F08000]"
            }`}
          >
            {`(${row.player})`}
          </span>
        </div>
      ),
    },
    {
      name: "Payment Date",
      selector: (row) => row.invoiceDate?.slice(0, 10),
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Invoice Reference
        </span>
      ),
      selector: (row) => row.invoiceReference,
      sortable: true,
      width: "150px",
    },

    {
      name: "Transaction Type",
      selector: (row) => row.transType,
      sortable: true,
      width: "150px",
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
      width: "6.2rem",
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      cell: (row) => <p className="text-red">{formatAmount(row.amount)}</p>,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) =>
        row.status === "Successful" ? (
          <p className="text-[#07593D]">{row.status}</p>
        ) : row.status === "REJECTED" ? (
          <p className="text-red">{row.status}</p>
        ) : row.status === "UPDATED" ? (
          <p className="text-blue-300">{row.status}</p>
        ) : row.status === "PARTIALLY PAID" ? (
          <p className="text-yellow-400">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        ),
    },
  ];

  // const onRowClicked = (row) => {
  //   navigate(`/reimbursement-transaction-history/${row?.idx}`, {
  //     state: {
  //       transactionType: row?.transactionType,
  //       fundingType: factoringInputType,
  //       channel: row?.channel,
  //       factoringType: factoringType,
  //     },
  //   });
  // };
  // const onCommonBulkRowClicked = (row) => {
  //   navigate(`/reimbursement-transaction-history-bulk/${row?.idx}`, {
  //     state: {
  //       transactionType: row?.transactionType,
  //       fundingType: factoringInputType,
  //       channel: row?.channel,
  //       factoringType: factoringType,
  //     },
  //   });
  // };

  // reimbursement-transaction-history-bulk

  // const handleFundingTypeChange = (e) => {
  //   setFactoringInputType(e.target.value);
  // };
  // const [factoringType, setFactoringType] = useState("Without Recourse");
  // const [factoringInputType, setFactoringInputType] = useState("All");

  const isNotOpeMarket =
    selectedOption === "Open Market" ||
    selectedOption === "Early Payment Offers" ||
    selectedOption === "Reverse Factoring (PFP)";
  // (factoringType === "Without Recourse" &&
  //   factoringInputType === "Early Payment Offers");

  // const RequestTypeToReturn = ({ reqestType }) => {
  //   if (reqestType === "Single Request") {
  //     return (
  //       <div className="px-[24px] pb[24px]">
  //         <Table
  //           columns={columns}
  //           data={dumData}
  //           onRowClicked={(row) => onRowClicked(row)}
  //           // progressComponent={<Loading />}
  //           pointer
  //           tableHeader
  //           className="mt-5"
  //         />
  //       </div>
  //     );
  //   } else {
  //     return (
  //       <div className="px-[24px] pb[24px]">
  //         <Table
  //           columns={columnsBulk}
  //           data={dumData}
  //           onRowClicked={(row) => onCommonBulkRowClicked(row)}
  //           // progressComponent={<Loading />}
  //           pointer
  //           tableHeader
  //           className="mt-5"
  //         />
  //       </div>
  //     );
  //   }
  // };

  const RequestTypeToReturnDirectFunding = ({ requestType }) => {
    return (
      <div className="pb-[24px]">
        <Table
          columns={column}
          data={requestType === "Single Request" ? data : bulkdata}
          pointer
          onRowClicked={
            requestType === "Single Request" ? onRowClicked : onRowClickedBulk
          }
        />
      </div>
    );
  };
  const RequestTypeToReturnOpenMarket = ({ reqestType }) => {
    return (
      <div className="px-[24px] pb[24px]">
        {/* <div>This is direct funding</div> */}

        <Table
          columns={columnOpSingle}
          data={data}
          pointer
          onRowClicked={onRowClicked}
          // progressPending={loading}
          // progressComponent={<Loading />}
          // tableHeader
        />
        {/* <Table
          columns={WithRecourseOpenMarketSinglecolumns}
          data={dumData}
          onRowClicked={(row) => onRowClicked(row)}
          // progressComponent={<Loading />}
          pointer
          tableHeader
          className="mt-5"
        /> */}
      </div>
    );
  };

  const RequestTypeToReturnOpenMarketWithoutRecourse = ({ requestType }) => {
    if (requestType === "Single Request") {
      return (
        <div className="px-[24px] pb[24px]">
          {/* <div>This is direct funding</div> */}
          <Table
            columns={WithRecourseOpenMarketSinglecolumns}
            data={dumData}
            onRowClicked={(row) => onRowClicked(row)}
            // progressComponent={<Loading />}
            pointer
            tableHeader
            className="mt-5"
          />
        </div>
      );
    } else {
      return (
        <div className="px-[24px] pb[24px]">
          <Table
            columns={WithRecourseDirectcolumnsBulk}
            data={dumData}
            onRowClicked={(row) => onRowClicked(row)}
            // progressComponent={<Loading />}
            pointer
            tableHeader
            className="mt-5"
          />
        </div>
      );
    }
    // return (

    // );
  };

  const RequestTypeToReturnClosedMarketWithoutRecourse = ({ requestType }) => {
    if (requestType === "Single Request") {
      return (
        <div className="px-[24px] pb[24px]">
          {/* <div>This is direct funding</div> */}
          <Table
            columns={
              selectedStatus === "Liened"
                ? columnOpSingle
                : columnClosedMarketLienedSingle
            }
            data={data}
            pointer
            onRowClicked={onRowClicked}
            // progressComponent={<Loading />}

            tableHeader
            className="mt-5"
          />
        </div>
      );
    } else {
      return (
        <div className="px-[24px] pb[24px]">
          <Table
            columns={WithRecourseDirectcolumnsBulk}
            data={data}
            onRowClicked={(row) => onRowClickedClosedMarket(row)}
            // progressComponent={<Loading />}
            pointer
            tableHeader
            className="mt-5"
          />
        </div>
      );
    }
    // return (

    // );
  };

  const RequestTypeToReturnEarlyOfferWithoutRecourse = ({ reqestType }) => {
    return (
      <div className="px-[24px] pb[24px]">
        {/* <div>This is direct funding</div> */}
        <Table
          columns={columnOpSingle}
          data={data}
          onRowClicked={(row) =>
            navigate(
              `/reimbursement-liened-earlypayment-offer-details/${row.id}`,
              {
                state: {
                  selectedStatus: selectedStatus,
                  selectedOption: selectedOption,
                },
              }
            )
          }
          // progressComponent={<Loading />}
          pointer
          tableHeader
          className="mt-5"
        />
      </div>
    );
  };

  const RenderTabContent = () => {
    switch (selectedStatus) {
      case "Liened":
        // console.log("Category: Electronics");

        switch (selectedOption) {
          case "Direct Funding Request":
            return (
              <>
                <RequestTypeToReturnDirectFunding
                  requestType={selectedRequest}
                />{" "}
              </>
            );

          case "Open Market":
            return (
              <>
                {/* <div>This is Open Market</div> */}
                <RequestTypeToReturnOpenMarket
                  requestType={selectedRequest}
                />{" "}
              </>
            );

          case "Closed Market":
            return (
              <>
                {/* <div>This is Open Market</div> */}
                <RequestTypeToReturnClosedMarketWithoutRecourse
                  requestType={selectedRequest}
                />
              </>
            );

          case "Early Payment Offers":
            return (
              <>
                {/* <div>This is Open Market</div> */}
                <RequestTypeToReturnEarlyOfferWithoutRecourse
                  requestType={selectedRequest}
                />{" "}
              </>
            );

          // return <BulkTableDirectFund />;
          // return <></>;
          default:
            return null;
        }

      // without recourse

      case "Upcoming":
        // console.log("Category: Electronics");

        switch (selectedOption) {
          // case "All":
          //   return (
          //     <>
          //       <RequestTypeToReturn  requestType={selectedRequest} />{" "}
          //     </>
          //   );

          case "Direct Funding Request":
            return (
              <>
                <RequestTypeToReturnDirectFunding
                  requestType={selectedRequest}
                />{" "}
              </>
            );

          case "Open Market":
            return (
              <>
                {/* <div>This is Open Market</div> */}
                <RequestTypeToReturnOpenMarket
                  requestType={selectedRequest}
                />{" "}
              </>
            );

          case "Closed Market":
            return (
              <>
                {/* <div>This is Open Market</div> */}
                <RequestTypeToReturnClosedMarketWithoutRecourse
                  requestType={selectedRequest}
                />{" "}
              </>
            );

          case "Reverse Factoring (PFP)":
            return (
              <>
                {/* <div>This is Open Market</div> */}
                <RequestTypeToReturnEarlyOfferWithoutRecourse
                  requestType={selectedRequest}
                />{" "}
              </>
            );

          // return <BulkTableDirectFund />;
          // return <></>;
          default:
            return null;
        }

      case "Overdue":
        // console.log("Category: Electronics");

        switch (selectedOption) {
          // case "All":
          //   return (
          //     <>
          //       <RequestTypeToReturn  requestType={selectedRequest} />{" "}
          //     </>
          //   );

          case "Direct Funding Request":
            return (
              <>
                <RequestTypeToReturnDirectFunding
                  requestType={selectedRequest}
                />{" "}
              </>
            );

          case "Open Market":
            return (
              <>
                {/* <div>This is Open Market</div> */}
                <RequestTypeToReturnOpenMarketWithoutRecourse
                  requestType={selectedRequest}
                />{" "}
              </>
            );

          case "Closed Market":
            return (
              <>
                {/* <div>This is Open Market</div> */}
                <RequestTypeToReturnClosedMarketWithoutRecourse
                  requestType={selectedRequest}
                />{" "}
              </>
            );

          case "Reverse Factoring (PFP)":
            return (
              <>
                {/* <div>This is Open Market</div> */}
                <RequestTypeToReturnEarlyOfferWithoutRecourse
                  requestType={selectedRequest}
                />{" "}
              </>
            );

          // return <BulkTableDirectFund />;
          // return <></>;
          default:
            return null;
        }

      default:
        return null;
    }
  };

  // const RenderTabContent = () => {
  //   switch (selectedStatus) {
  //     case "Liened":
  //     case "Upcoming":
  //     case "Overdue":
  //       switch (selectedOption) {
  //         case "Direct Funding Request":
  //           return (
  //             <RequestTypeToReturnDirectFunding requestType={selectedRequest} />
  //           );
  //         case "Open Market":
  //           return (
  //             <RequestTypeToReturnOpenMarket  requestType={selectedRequest} />
  //           );
  //         case "Closed Market":
  //           return (
  //             <RequestTypeToReturnClosedMarketWithoutRecourse
  //                requestType={selectedRequest}
  //             />
  //           );
  //         case "Early Payment Offers":
  //         case "Reverse Factoring (PFP)":
  //           return (
  //             <RequestTypeToReturnEarlyOfferWithoutRecourse
  //               reqestType={selectedRequest}
  //             />
  //           );
  //         default:
  //           return null;
  //       }
  //     default:
  //       return null;
  //   }
  // };

  return (
    <div className="w-full ">
      <AcceptModal
        isOpen={open}
        onClose={() => setOpen(false)}
        message={
          selectAll
            ? `Are you sure you would like to Pay now for the selected invoices ?`
            : `Are you sure you would like to Pay now for this invoice ?`
        }
        onConfirm={handleConfirm}
        // loading={false}
      />

      <AcceptModal
        isOpen={showDirectFunding}
        onClose={() => setShowDirectFunding(false)}
        message={
          selectAll
            ? `Are you sure you would like to Pay now for the selected invoices ?`
            : `Are you sure you want to fund this virtual account for this modal ?`
        }
        onConfirm={handleDirectFundingConfirm}
        // loading={false}
      />

      {selectedStatus === "Liened" && (
        <PinModalPayment
          invoice={true}
          confirmPayment={confirmPayment}
          isOpen={showPin}
          onCancel={onClosePinModal}
          otpInputs={otpInputs}
          setOtpInputs={setOtpInputs}
        />
      )}
      {selectedStatus === "Upcoming" && (
        <PinModalPayment
          invoice={true}
          confirmPayment={confirmSuccessPaymentUpcoming}
          isOpen={showPin}
          onCancel={onClosePinModal}
          otpInputs={otpInputs}
          setOtpInputs={setOtpInputs}
        />
      )}
      {transferFunds && (
        <PayProgressModal progress={progress} setProgress={setProgress} />
      )}

      {showTransferFundsUpcoming && (
        <FundWalletDetails
          invoice={true}
          isOpen={showTransferFundsUpcoming}
          onClose={() => setShowTransferFundsUpcoming(false)}
          confirmPaymentUpcoming={confirmPaymentUpcoming}
        />
      )}

      {showVerifyModal && (
        <ConfirmingPaymentReimbursement
          invoice={true}
          isOpen={true}
          onClose={confirmVerifyPaymentUpcoming}
          confirmPaymentUpcoming={confirmVerifyPaymentUpcoming}
        />
      )}

      {showSuccessUpcoming && (
        <SuccessUpcomingReimbursement
          invoice={true}
          isOpen={showSuccessUpcoming}
          onClose={closeUpcomingSuccessModal}
          confirmPaymentUpcoming={confirmVerifyPaymentUpcoming}
        />
      )}

      {showSuccess && (
        <SuccessEarlyPayment
          isOpen={showSuccess}
          onClose={onCloseSuccessModal}
          invoice={true}
          message="Early payment request successful"
        />
      )}
      <div className="w-full  mt-2 bg-white ">
        <div className="bg-[#EBEBEB]">
          <div className=" py-4 grid md:grid-cols-[49%,49%] gap-5">
            {/* here */}
            <div className=" flex flex-col   gap-4 rounded-[10px] cursor-pointer  bg-white ">
              <div className="flex flex-col" style={{ padding: 15 }}>
                <div className="flex justify-between relative">
                  <span style={{ color: "grey" }}>
                    Praise Akobundu | 7364738021 | PayEdgeMFB | Virtual Account
                  </span>
                  <img onClick={toggleDropdown} src={ArrowDown} alt="" />

                  {showDropdown && (
                    <div
                      className="absolute top-full right-0 mt-2 bg-white shadow-lg rounded-lg p-4 z-10"
                      // style={{ width: "200px" }}
                    >
                      <span style={{ color: "black", fontWeight: 500 }}>
                        Praise Akobundu | 7364738021 | PayEdgeMFB | Virtual
                        Account
                      </span>
                    </div>
                  )}
                </div>

                <span
                  style={{ color: "black", fontWeight: "bold", marginTop: 5 }}
                >
                  NGN Balance 234,889,900
                </span>
              </div>

              <div style={{ padding: 15 }} className="">
                <div className="flex justify-between items-center">
                  {selectedStatus === "Liened" && (
                    <div className="flex flex-col">
                      <span style={{ color: "#06593D" }}>
                        NGN{" "}
                        <span
                          style={{
                            color: "#06593D",
                            fontSize: 24,
                            fontWeight: "bold",
                          }}
                        >
                          234,889,900
                        </span>
                      </span>

                      {selectedStatus === "Liened" && (
                        <span style={{ color: "grey" }}>
                          Total Liened Amount
                        </span>
                      )}

                      {selectedStatus === "Upcoming" && (
                        <span style={{ color: "grey" }}>
                          Total Outstanding Amount
                        </span>
                      )}

                      {selectedStatus === "Overdue" && (
                        <span style={{ color: "grey" }}>
                          Total Approved Invoice Request
                        </span>
                      )}
                    </div>
                  )}

                  {selectedStatus === "Upcoming" && (
                    <div className="flex flex-col">
                      <span style={{ color: "#F08000" }}>
                        NGN{" "}
                        <span
                          style={{
                            color: "#F08000",
                            fontSize: 24,
                            fontWeight: "bold",
                          }}
                        >
                          234,889,900
                        </span>
                      </span>
                      <span style={{ color: "grey" }}>
                        Total Outstanding Amount
                      </span>
                    </div>
                  )}

                  {selectedStatus === "Overdue" && (
                    <div className="flex flex-col">
                      <span style={{ color: "red" }}>
                        NGN{" "}
                        <span
                          style={{
                            color: "red",
                            fontSize: 24,
                            fontWeight: "bold",
                          }}
                        >
                          234,889,900
                        </span>
                      </span>
                      <span style={{ color: "grey" }}>
                        Total Outstanding Payment
                      </span>
                    </div>
                  )}

                  <div
                    style={{
                      width: "1px",
                      height: "120px",
                      backgroundColor: "lightgrey",
                      margin: "0 20px",
                    }}
                  ></div>

                  {selectedStatus === "Liened" && (
                    <div className="flex flex-col">
                      <span
                        className="text-center"
                        style={{
                          color: "#06593D",
                          fontSize: 30,
                          fontWeight: "bold",
                        }}
                      >
                        70
                      </span>
                      <span style={{ color: "grey" }}>
                        Total Liened Request
                      </span>
                    </div>
                  )}

                  {selectedStatus === "Upcoming" && (
                    <div className="flex flex-col">
                      <span
                        className="text-center"
                        style={{ color: "#F08000" }}
                      >
                        70
                      </span>
                      <span style={{ color: "grey" }}>
                        Total Outstanding Requests
                      </span>
                    </div>
                  )}

                  {selectedStatus === "Overdue" && (
                    <div className="flex flex-col">
                      <span className="text-center" style={{ color: "red" }}>
                        70
                      </span>
                      <span style={{ color: "grey" }}>
                        Total Approved Invoice Request
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div>
              <div className="flex justify-end">
                <div className=" relative">
                  <img
                    src={Search}
                    alt="Search Icon"
                    className="absolute left-2 top-1/2 transform -translate-y-1/2 w-4 h-4"
                  />
                  <InputField
                    placeholder={"Search for due invoices awaiting collection"}
                    // value={searchTerm}
                    // onChange={(e) => setSearchTerm(e.target.value)}
                    className="pl-10 w-full py-2 border rounded-md border[#CCCCCC] outline-none md:w-[350px] !border-silver "
                  />
                </div>
              </div>

              <div className="  flex flex-col mt-4 p-6  gap-4 rounded-[10px] cursor-pointer bg-white ">
                <div className="p-4">
                  <div className="flex justify-between">
                    <div className="flex flex-col">
                      <span
                        style={{ fontWeight: "bold", fontSize: 18 }}
                        className=""
                      >
                        Upcoming Payment
                      </span>
                      <span
                        style={{ color: "grey", fontSize: 14 }}
                        className=""
                      >
                        Details on the next Direct funding Request Due Date
                      </span>
                    </div>

                    {selectedStatus !== "Liened" && (
                      <button
                        style={{
                          backgroundColor: "#06593D",
                          color: "white",
                          padding: "8px",
                          borderRadius: "8px",
                          border: "none",
                          cursor: "pointer",
                        }}
                        onClick={handleDirectFunding}
                      >
                        Fund Request
                      </button>
                    )}

                    {selectedStatus === "Liened" && (
                      <button
                        style={{
                          // selectAll ? "#C2C0C0" : "#06593D",#06593D
                          backgroundColor: selectAll ? "#C2C0C0" : "#06593D",
                          color: "white",
                          padding: "8px",
                          borderRadius: "8px",
                          border: "none",
                          cursor: "pointer",
                        }}
                        onClick={handlePayNow}
                      >
                        Pay Now
                      </button>
                    )}
                  </div>
                </div>

                <div className="p-2">
                  <div className="">
                    {selectedStatus === "Overdue" ? (
                      <div className="flex justify-between">
                        <span style={{ color: "grey" }}>Count down</span>
                        <span style={{ color: "red" }}>00d:00h:55m:32s</span>
                      </div>
                    ) : (
                      <div className="flex justify-between">
                        <span style={{ color: "grey" }}>Count down</span>
                        <span style={{ color: "#F08000" }}>
                          00d:00h:55m:32s
                        </span>
                      </div>
                    )}

                    <div className="flex justify-between">
                      <span style={{ color: "grey" }}>Name of Supplier</span>
                      <span>Agaba Dev</span>
                    </div>

                    <div className="flex justify-between">
                      <span style={{ color: "grey" }}>Payable Amount</span>
                      <span>NGN 850,000</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <header className="bg-alabasterHeader p-[24px] rounded-t-[10px]">
            <div>
              <div className="flex justify-between">
                {selectedOption === "Direct Funding" ? (
                  <div className="flex flex-col">
                    <span style={{ fontWeight: "bold" }}>Direct Funding</span>
                    <span style={{ color: "grey" }}>
                      {selectedStatus === "Liened"
                        ? "List of all approved Direct funding Request from your supplier"
                        : "List of all approved direct funding requests from your supplier awaiting liening ."}
                    </span>
                  </div>
                ) : (
                  <div className="flex flex-col">
                    <span style={{ fontWeight: "bold" }}>Open Market</span>
                    <span style={{ color: "grey" }}>
                      List of all invoices in the open market from your supplier
                    </span>
                  </div>
                )}
                <select
                  className="rounded-md p-2 mr-4"
                  value={selectedOption}
                  onChange={handleDropdownChange}
                >
                  <option value="Direct Funding Request">
                    Direct Funding Request
                  </option>
                  <option value="Open Market">Open Market</option>
                  <option value="Closed Market">Closed Market</option>
                  <option
                    value={
                      selectedStatus === "Liened"
                        ? "Early Payment Offers"
                        : "Reverse Factoring (PFP)"
                    }
                  >
                    {selectedStatus === "Liened"
                      ? "Early Payment Offers"
                      : "Reverse Factoring (PFP)"}
                  </option>
                </select>{" "}
                <div>
                  <button
                    onClick={handleSelectAll}
                    style={{
                      marginRight: 10,
                      backgroundColor: "#2EA924",
                      color: "white",
                      padding: "8px",
                      // fontSize: "11px",
                      border: "none",
                      borderRadius: "8px",
                      cursor: "pointer",
                    }}
                  >
                    {selectAll ? "Deselect All" : "Select All"}
                  </button>
                  {selectedStatus === "Liened" && (
                    <button
                      style={{
                        backgroundColor: selectAll ? "#06593D" : "#C2C0C0",
                        color: "white",
                        padding: "8px",
                        // fontSize: "11px",
                        border: "none",
                        borderRadius: "8px",
                        cursor: "pointer",
                      }}
                      onClick={handlePayNow}
                    >
                      Pay Now
                    </button>
                  )}
                  {selectedStatus !== "Liened" && (
                    <button
                      style={{
                        backgroundColor: selectAll ? "#06593D" : "#C2C0C0",
                        color: "white",
                        padding: "8px",
                        // fontSize: "11px",
                        border: "none",
                        borderRadius: "8px",
                        cursor: "pointer",
                      }}
                      onClick={handleDirectFunding}
                    >
                      Fund Request
                    </button>
                  )}
                </div>
              </div>
            </div>
          </header>
          <div className="p-[24px] bg-white rounded-b-[10px]">
            {!isNotOpeMarket && (
              <div className="flex  space-x-8 ">
                {["Single Request", "Bulk Request"].map((requestType) => (
                  <div
                    key={requestType}
                    onClick={() => setSelectedRequest(requestType)}
                    className={`cursor-pointer text-center pb-2 relative ${
                      selectedRequest === requestType
                        ? "text-orange-500"
                        : "text-gray-500"
                    }`}
                  >
                    {requestType}
                    {selectedRequest === requestType && (
                      <div
                        className="absolute left-1/2 transform -translate-x-1/2 bottom-0 h-[3px] bg-orange-500"
                        style={{ width: "40px" }}
                      ></div>
                    )}
                  </div>
                ))}
              </div>
            )}
            <br />
            <RenderTabContent />
            {/* {selectedRequest === "Single Request" &&
              selectedOption === "Direct Funding" && (
                <Table
                  columns={column}
                  data={data}
                  pointer
                  onRowClicked={onRowClicked}
                  // progressPending={loading}
                  // progressComponent={<Loading />}
                  // tableHeader
                />
              )}

            {selectedRequest === "Bulk Request" &&
              selectedOption === "Direct Funding" && (
                <Table
                  columns={columnBulk}
                  data={bulkdata}
                  pointer
                  onRowClicked={onRowClickedBulk}
                  // progressPending={loading}
                  // progressComponent={<Loading />}
                  // tableHeader
                />
              )}

            {selectedOption === "Open Market" && (
              <Table
                columns={columnOpenMarket}
                data={openMarketData}
                pointer
                onRowClicked={onRowClickedOpenMarket}
                // progressPending={loading}
                // progressComponent={<Loading />}
                // tableHeader
              />
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReimbursementWithoutRecourse;
