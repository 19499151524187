import React, { useEffect } from "react";
import Card from "shared/Card";
import Table from "layout/table/Table";
import Pagination from "shared/Pagination";
import {
  PAIconOutlineSearch,
  PAIconFilter,
  PAIconArrowDownThin,
  PAIconClearSelect,
} from "assets/images/svgs";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getFunderDirectFundingPending, getPendingFunding, } from "appstate/api/invoice/invoice";
import toast from "react-hot-toast";
import { formatCurrency } from "utilities/helper/formatters";
// import NewPagination from "shared/NewPagination";
// Pending Funding My Own

const AllInvoices = () => {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const [activeTab, setActiveTab] = useState("pending");
  const [tab, setTab] = useState("single_request");
  const [pendingData, setPendingData] = useState([]);
  const [pendingDataBulk, setPendingDataBulk] = useState([]);
  const [fundedData, setFundedData] = useState([]);
  const [fundedDataBulk, setFundedDataBulk] = useState([]);
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line
  const [error, setError] = useState(null);
  // eslint-disable-next-line
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState("");

  const handleFilterChange = (e) => {
    const value = e.target.value;
    let updatedFilters = [...selectedFilters];

    if (updatedFilters.includes(value)) {
      updatedFilters = updatedFilters.filter((filter) => filter !== value);
    } else {
      updatedFilters.push(value);
    }

    setSelectedFilters(updatedFilters);
  };


  useEffect(() => {
    const fetchRequestsApproved = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await getFunderDirectFundingPending({
          page,
          size,
          bulk: false,
          status: "APPROVED",
        });
        setTotal(response?.meta?.totalElements);
        setPendingData(response?.data || [])

      } catch (err) {
        setError(err.message || "Failed to fetch requests.");
        toast.error(error);
      } finally {
        setIsLoading(true);
      }
    };

    fetchRequestsApproved();
    // eslint-disable-next-line
  }, [page, size]);


  const fetchPendingDataBulk = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await getFunderDirectFundingPending({
        page,
        size,
        bulk: true,
        status: "APPROVED",
      });
      setPendingDataBulk(response?.data)
      // setData(response);
    } catch (err) {
      toast.error(err?.response?.data?.message || "Failed to fetch data!");
      setError(err?.response?.data?.message || "Failed to fetch data");
    } finally {
      setIsLoading(false);
    }
  }

  const fetchFundedData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await getPendingFunding({
        page,
        size,
        funded: true,
        direction: "DESC",
        bulk: false,
      });
      setFundedData(response?.data || []);
    } catch (err) {
      setError(err?.response?.data?.message || "Failed to fetch funded invoices");
    } finally {
      setIsLoading(false);
    }
  };


  const fetchFundedDataBulk = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await getPendingFunding({
        page,
        size,
        funded: true,
        direction: "DESC",
        bulk: false,
      });
      setFundedDataBulk(response?.data || []);
    } catch (err) {
      setError(err?.response?.data?.message || "Failed to fetch funded invoices");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (activeTab === "pending") {
      // fetchPendingData();
      fetchPendingDataBulk();
    }
    else {
      fetchFundedData();
      fetchFundedDataBulk();
    };
    // eslint-disable-next-line
  }, [activeTab, page, size]);

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toDetail = (id, row) => {
    navigate(`/factoring/facopenmarketallinvoicedetail/${id}`, {
      state: { invoiceId: row?.invoiceId },
    });
  };

  const toDetailBulk = (id, row) => {
    navigate(`/funder-direct-funding-details-bulk-request-accepted/${id}`, {
      state: { invoiceId: row?.invoiceId, pf: true },
    });
  };

  const columns = [
    {
      name: <span className="whitespace-nowrap">Invoice Reference</span>,
      selector: (row) => row.reference,
      sortable: true,
    },
    {
      name: <span className="whitespace-nowrap">Supplier</span>,
      selector: (row) => row.supplierCompanyName,
      sortable: true,
    },

    {
      name: "Payment Date",
      selector: (row) => row.earlyPaymentDate,
      sortable: true,
    },
    // {
    //   name: "Due Date",
    //   selector: (row) => row.invoiceDueDate,
    //   sortable: true,
    // },

    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
    },
    {
      name: "Invoice Amount",
      selector: (row) => `${formatCurrency(Number(row.invoiceAmount))}`,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) =>
        row.status === "APPROVED" ? (
          <p className="text-[#F08000]">{row.status === "APPROVED" && "PENDING FUNDING"}</p>
        ) : row.status === "Accepted" ? (
          <p className="text-[#2EA923]">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        ),
    },
  ];

  const columnsBulkPendingFunding = [
    {
      name: <span className="whitespace-nowrap">Request Ref.No</span>,
      selector: (row) => row?.reference,
      sortable: true,
    },
    {
      name: <span className="whitespace-nowrap">Supplier</span>,
      selector: (row) => row?.supplierCompanyName,
      sortable: true,
    },

    // {
    //   name: "Due Date",
    //   selector: (row) => row.invoiceDate,
    //   sortable: true,
    // },
    {
      name: "Payment Date",
      selector: (row) => row.earlyPaymentDate,
      sortable: true,
    },

    {
      name: "No Of Invoices",
      selector: (row) => row.numberOfInvoices,
      sortable: true,
    },
    {
      name: "Total Invoice Amount",
      selector: (row) => `${formatCurrency(Number(row.invoiceAmount))}`,
      sortable: true,
    },
    {
      name: "Total Request Amount",
      selector: (row) => `${formatCurrency(Number(row.requestAmount))}`,
      sortable: true,
    },
    {
      name: "Total Discount Amount",
      selector: (row) => `${formatCurrency(Number(row.discountAmount))}`,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) =>
        row.status === "PENDING_FUNDING" ? (
          <p className="text-[#F08000]">{row.status}</p>
        ) : row.status === "Accepted" ? (
          <p className="text-[#2EA923]">{row.status}</p>
        ) : (
          <p>{row.status === "APPROVED" && "PENDING FUNDING"}</p>
        ),
    },
  ];
  const totalPages = pendingData.length;
  // eslint-disable-next-line
  const totalPagesPendingFunding = total;
  // const totalPendingPages = pendingData.length;

  return (
    <>
      <div className="my-2 grid md:grid-cols-2 items-center">
        <div className="gap-3 flex relative">
          <div>
            <div
              className="flex items-center gap-[4px] border border-[#DEDEDE] bg-white shadow-sm rounded-[5px] px-4 py-3 text-sm focus:outline-none cursor-pointer"
              onClick={handleDropdownToggle}
            >
              Filter{" "}
              {selectedFilters.length > 0 && (
                <span className="bg-[#7b61ff] rounded-lg p-1 px-2">
                  {selectedFilters.length}
                </span>
              )}{" "}
              Selected <PAIconArrowDownThin />
              {selectedFilters.length > 0 ? (
                <span>
                  <PAIconClearSelect onClick={() => setSelectedFilters([])} />
                </span>
              ) : (
                <></>
              )}
            </div>
            {isDropdownOpen && (
              <div className="absolute top-full mt1 wfull border border-[#DEDEDE] shadow-lg rounded-b-[5px] px-4 py-3 bg-white">
                <label className="block">
                  <input
                    type="checkbox"
                    value="date"
                    checked={selectedFilters.includes("date")}
                    onChange={handleFilterChange}
                  />
                  Date
                </label>
                <label className="block">
                  <input
                    type="checkbox"
                    value="name"
                    checked={selectedFilters.includes("name")}
                    onChange={handleFilterChange}
                  />
                  Name
                </label>
                <label className="block">
                  <input
                    type="checkbox"
                    value="currency"
                    checked={selectedFilters.includes("currency")}
                    onChange={handleFilterChange}
                  />
                  Currency
                </label>
                <label className="block">
                  <input
                    type="checkbox"
                    value="amount"
                    checked={selectedFilters.includes("amount")}
                    onChange={handleFilterChange}
                  />
                  Amount
                </label>
              </div>
            )}
          </div>
          <div>
            <select
              className="border border-[#DEDEDE] shadow-sm rounded-[5px] px-4 py-3 text-sm focus:outline-none cursor-pointer"
              onChange={(e) => { }}
            >
              <option value="">Sort by</option>
              <option value="date">Date</option>
              <option value="name">Name</option>
            </select>
          </div>
        </div>
        <div className="flex md:col-span-1 justify-end">
          {/* <SearchInput /> */}
          {/* starts from here */}
          <div>
            <div className="flex items-center justify-between gap-5">
              <div className="border border-transparent md:border-gray md:shadow-sm my-4 rounded-[5px] px-2 lg:w-[300px] bg-white">
                <div className="items-center flex bg-white">
                  <PAIconOutlineSearch />
                  <input
                    className="bg-transparent p-2 w-full focus:outline-none text-sm bg-white"
                    type="text"
                    placeholder="Search for invoices"
                  />
                </div>
                <div className="lg:hidden flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer">
                  <PAIconFilter className="bg-[#2FA06A]" />
                </div>
              </div>

              <div class=" px-6 py-2 bg-[#f2f2f2]/95 rounded-[5px] border border-[#cccccc] justify-start items-center gap-2.5 inline-flex">
                <div class="text-black text-sm font-medium ">Search</div>
              </div>
            </div>
          </div>

          {/* ends here */}
        </div>
      </div>

      <Card className="rounded-[10px] mt-[24px] lg:min-h-[660px]">
        <div className="pt-2 px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
          <div class="pt-2 flex items-center">
            <p
              className={
                activeTab === "pending"
                  ? "text-[14px] text-secondaryColor border-b-[1px] border-secondaryColor"
                  : "text-[14px] cursor-pointer"
              }
              onClick={() => {
                setActiveTab("pending");
              }}
            >
              Pending Funding
            </p>
            <p
              className={
                activeTab === "funded"
                  ? "text-[14px] ml-4 text-secondaryColor border-b-[1px] border-secondaryColor"
                  : "text-[14px] ml-4 cursor-pointer"
              }
              onClick={() => {
                setActiveTab("funded");
              }}
            >
              Funded
            </p>
          </div>

          <div class="flex items-center pt-8">
            <p
              className={
                tab === "single_request"
                  ? "text-[14px] text-primaryColor border-b-[1px] border-primaryColor"
                  : "text-[14px] cursor-pointer"
              }
              onClick={() => {
                setTab("single_request");
              }}
            >
              Single Requests
            </p>
            <p
              className={
                tab === "bulk_request"
                  ? "text-[14px] ml-4 text-primaryColor border-b-[1px] border-primaryColor"
                  : "text-[14px] ml-4 cursor-pointer"
              }
              onClick={() => {
                setTab("bulk_request");
              }}
            >
              Bulk Requests
            </p>
          </div>

        </div>
        <div className="px-[24px] pb-[24px]">
          {activeTab === "pending" && tab === "single_request" && <Table
            columns={columns}
            data={pendingData}
            pointer
            tableHeader
            className="mt-8"
            onRowClicked={(row) => toDetail(row?.directFundingId, row)}
          />}
          {activeTab === "pending" && tab === "bulk_request" && <Table
            columns={columnsBulkPendingFunding}
            data={pendingDataBulk}
            pointer
            tableHeader
            className="mt-8"
            onRowClicked={(row) => toDetailBulk(row?.directFundingId, row)}
          />}
          {activeTab === "funded" && tab === "single_request" && <Table
            columns={columns}
            data={fundedData}
            pointer
            tableHeader
            className="mt-8"
            onRowClicked={(row) => toDetail(row?.id)}
          />}
          {activeTab === "funded" && tab === "bulk_request" && <Table
            columns={columns}
            data={fundedDataBulk}
            pointer
            tableHeader
            className="mt-8"
            onRowClicked={(row) => toDetail(row?.id)}
          />}
        </div>
        <div>
        </div>
        <div className="flex justify-center gap-x-[8px]  mt-12">
          {totalPages > 2 && (
            <Pagination
              page={page}
              startPage={startPage}
              setEndPage={setEndPage}
              endPage={endPage}
              setStartPage={setStartPage}
              setPage={setPage}
              totalPages={totalPages}
            />
          )}
          {/* <NewPagination /> */}
        </div>
      </Card>
    </>
  );
};

export default AllInvoices;
