import {
  allRoles,
  deleteUserRole,
} from "appstate/roleManager/roleManagerSlice";
import {
  PAIconEditGreen,
  PAIconOption,
  PAIconTrashMini,
  PAIconViewMini,
} from "assets/images/svgs";
import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import ViewDocumentModal from "features/documents/Components/DeleteDocumentModal";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Pagination from "shared/Pagination";

function ViewRoleManager({
  toggleRoleFullDetailsOn,
  setSelectedId,
  page,
  setPage,
  pageSize,
}) {
  const dispatch = useDispatch();
  const { allRolesData } = useSelector((state) => state?.roleManager);
  const [data, setData] = useState([]);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const totalPages = allRolesData?.meta?.totalElements;
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    dispatch(allRoles());
    setSearchQuery("");
  }, [dispatch, page, pageSize, searchQuery]);

  useEffect(() => {
    setData(allRolesData?.data);
  }, [allRolesData]);

  return (
    <>
      <ViewRoleManagerTable
        toggleRoleFullDetailsOn={toggleRoleFullDetailsOn}
        data={data}
        setSelectedId={setSelectedId}
      />
      <div className="flex justify-center gap-x-[8px] mt-[20px]">
        {totalPages > 10 && (
          <Pagination
            page={page}
            startPage={startPage}
            setEndPage={setEndPage}
            endPage={endPage}
            setStartPage={setStartPage}
            setPage={setPage}
            totalPages={totalPages}
          />
        )}
      </div>
    </>
  );
}

function ViewRoleManagerTable({ data, setSelectedId }) {
  const columns = [
    "Role Title",
    "Description",
    "Created",
    "No of Assigned Permissions",
    "Actions",
  ];
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // State to track open/close state of each row's options menu
  const [openMenuId, setOpenMenuId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [roleId, setRoleId] = useState(false);

  const handleViewFullDetails = (data) => {
    navigate(`/role_manager/${data?.id}`);
  };

  const handleDeleteRole = (id) => {
    const pendingToast = toast.loading("Deleting User Role...");
    dispatch(deleteUserRole(id)).then((data) => {
      if (data?.payload?.success) {
        toast.dismiss(pendingToast);
        toast.success("Role deleted successfully");
        dispatch(allRoles());
      } else {
        toast.dismiss(pendingToast);
      }
    });
  };

  const toggleOptionsMenu = (id) => {
    setOpenMenuId(openMenuId === id ? null : id); // Toggle the visibility based on current state
  };

  const NewTableHeader = ({ column }) => {
    return (
      <tr className="w-full bg-[#EFEFEF] font-bold py-6 border-b border-t border-[#EEE] text-[14px]">
        {column?.map((header, index) => (
          <td key={index} className="w-[10%] px-[30px] py-[17px]">
            {header}
          </td>
        ))}
      </tr>
    );
  };

  const NewTableContent = ({ data }) => {
    return (
      <tbody className="h-full min-h-[60vh]">
        {data?.map((data) => (
          <tr
            onClick={() => {
              handleViewFullDetails(data);
            }}
            key={data?.id} // Using unique ID for key
            className="w-full cursor-pointer border-b border-t border-[#EEE] text-[14px]"
          >
            <td className="px-7 whitespace-nowrap py-5">{data?.roleName}</td>
            <td className="px-7 whitespace-nowrap py-5">
              {data.reportingTo || "Admin"}
            </td>
            <td className="px-7 whitespace-nowrap py-5">
              {data?.createdDate?.slice(0, 11) || "N/A"}
            </td>
            <td className="px-7 whitespace-nowrap py-5">
              {data?.noOfAssignedPermissions}
            </td>

            <td className="relative">
              <button
                onClick={(e) => {
                  e.stopPropagation(); // Prevent triggering row click
                  toggleOptionsMenu(data?.id); // Toggle options menu for the specific row
                }}
              >
                <PAIconOption />
              </button>

              {/* Conditional rendering of the options menu */}
              {openMenuId === data?.id && (
                <div className="absolute w-[150px] left-[-98px]  z-[999999] top-9 bg-white drop-shadow-md text-xs  p-3 rounded-md">
                  <div
                    onClick={() => {
                      setSelectedId(data?.id);
                      handleViewFullDetails();
                    }}
                    className="flex items-center gap-x-[10px] mb-[12px]  pl-[15px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]"
                  >
                    <span>
                      <PAIconViewMini />
                    </span>
                    <p className="font-xs font-normal">View Role</p>
                  </div>

                  <div
                    onClick={() => handleViewFullDetails()}
                    className="flex items-center gap-x-[10px] mb-[12px]  pl-[15px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]"
                  >
                    <span>
                      <PAIconEditGreen />
                    </span>
                    Edit permission
                  </div>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      setRoleId(data?.id);
                      setOpenMenuId(null);
                      setDeleteModal(true);
                    }}
                    className="flex items-center gap-x-[10px] mb-[12px]  pl-[15px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]"
                  >
                    <span>
                      <PAIconTrashMini />
                    </span>
                    Delete Role
                  </div>
                </div>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    );
  };

  return (
    <div className="px-5 bg-white pt-1.5 w-full min-h-[60vh]  h-full  overflow-x-auto">
      <table className="overflow-x-auto h-full w-full border-collapse">
        <thead>
          <NewTableHeader column={columns} />
        </thead>
        <NewTableContent data={data} />
      </table>

      <AnimatePresence>
        {deleteModal && (
          <ViewDocumentModal
            text="Are you sure you would like to remove this role from the platform?"
            deleteDoc={() => handleDeleteRole(roleId)}
            onCloseDeleteModal={() => setDeleteModal(false)}
          />
        )}
      </AnimatePresence>
    </div>
  );
}

export default ViewRoleManager;
