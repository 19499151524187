import React from 'react'

const FirstStep = ({onPrev, onNext}) => {
  return (
    <div className='lg:min-w-[360px] md:min-w-[360px]'>
        <div className='flex w-full justify-center items-center mt-2'><div class="self-stretch text-center justify-start text-[#a3a3a3] text-sm font-normal ">Agreement type</div></div>

        <div className='flex flex-col w-full justify-center items-center mt-5 gap-3'>

        <div className=' w-full'>
          <select
            className=" shadow-sm  text-sm focus:outline-none  cursor-pointer  px-3   py-3.5 rounded-[5px] border border-[#949494] justify-start items-end gap-2 inline-flex  w-full "
            // onChange={(e) => {}}
            // onChange={(e) => setStatus(e.target.value)}
          >
            <option value="" key="all-bids">
              All Agreement Type
            </option>
            <option value="BID_ACTIVE" key="bid-active">
              PFP Campaign
            </option>
            <option value="POSTED" key="posted-request">
              Direct Funding
            </option>
            <option value="POSTED" key="posted-request">
              Early Payment
            </option>
            <option value="POSTED" key="posted-request">
              Closed Market
            </option>
            <option value="POSTED" key="posted-request">
              Open Market
            </option>
          </select>
        </div>


{/* button */}

        <div class="self-stretch px-5 py-3.5 bg-[#e5e5e5] rounded-[5px] inline-flex justify-center items-center gap-2.5 overflow-hidden" onClick={onNext}>
    <div class="justify-start text-[#7A7A7A] text-sm font-normal ">Next</div>
</div>
        </div>

      

      
    </div>
  )
}

export default FirstStep
