import React, { useEffect, useState } from "react";
import Table from "layout/table/Table";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";

import { useDispatch, useSelector } from "react-redux";
// import { allTransactions } from "appstate/collections/collectionSlice";
import Pagination from "shared/Pagination";
import * as XLSX from "xlsx";
import Loading from "shared/Loading";
import DatePicker from "react-datepicker";
import {
  PAIconDownload,
  PAIconFilter,
  PAIconInvoiceIcon,
  PAIconSearchGreen,
} from "assets/images/svgs";
import { AnimatePresence, motion } from "framer-motion";

import ViewDocumentModal from "features/RulesConfig/common/components/modals/DeleteRuleModal";
import { useNavigate } from "react-router-dom";
import EnterPin from "features/disbursement/common/modal/Pin";
import { disbursementFunderTransactionHistory } from "appstate/disbursement/disbursementSlice";
import { formatCapitalize } from "utilities/formatCapitalize";
import { formatCapitaliseString } from "utilities/helper/formatCapitaliseString";

const TransactionHistory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { disbursementFunderTransactionHistoryData, isLoading } = useSelector(
    (state) => state?.disbursement
  );

  const [pinModal, setPinModal] = useState(false);
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(10);
  const [selectedTab, setSelectedTab] = useState("single");
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [searchQuery, setSearchQuery] = useState("");
  const [acceptPaymentModal, setAcceptPaymentModal] = useState(false);
  const [selectAll] = useState(false);
  const [selectDownloadType, setSelectDownloadType] = useState(false);
  const [debouncedQuery, setDebouncedQuery] = useState(searchQuery);
  const pageSize = 10;

  const formatAmount = (amount) => {
    if (typeof amount !== "number") return "0.00";
    return amount?.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const handleSearchInputChange = (event) => {
    const value = event.target.value;
    setSearchQuery(value);
    sessionStorage.setItem("searchQueryFunder", value);
  };

  const handleFilterButtonClick = () => {};

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(
      disbursementFunderTransactionHistoryData?.data.map((row) => ({
        "Program Ref No": row?.refNo,
        "Invoice Number": `INV-${row?.invoiceNumber}`,
        Buyer: row?.buyerCompanyName,
        "Due Date": row?.dueDate,
        "Payment Date": row?.paymentDate,
        Currency: row?.currency,
        "Invoice Amount": formatAmount(row?.invoiceAmount),
        "Discount Amount": formatAmount(row?.discountAmount),
        "Discount Rate": formatAmount(row?.discountRate),
        "Fundable Amount": formatAmount(row?.fundableAmount),
      }))
    );
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Direct Funding");
    XLSX.writeFile(wb, "DirectFundingData.xlsx");
  };

  useEffect(() => {
    // Set a timeout to update the debounced query after 1 second
    const handler = setTimeout(() => {
      setDebouncedQuery(searchQuery);
    }, 1000);

    // Cleanup function to clear the timeout if searchQuery changes before 1 second
    return () => clearTimeout(handler);
  }, [searchQuery]);

  useEffect(() => {
    dispatch(
      disbursementFunderTransactionHistory({
        filter: "ALL",
        searchTerm: debouncedQuery,
        page,
        pageSize,
      })
    );
  }, [dispatch, debouncedQuery, page, pageSize]);

  const columns = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />{" "}
          {/* Program */}
          Transaction Party
        </span>
      ),
      selector: (row) => (
        <div className="flex justify-center flex-col">
          <span>{formatCapitalize(row?.transactionParty?.name) ?? "N/A"}</span>
          <span className="text-xs font-light text-primaryColor">
            ({formatCapitalize(row?.transactionParty?.role) || "Player"})
          </span>
        </div>
      ),
      sortable: true,
    },

    {
      name: "Payment Date",
      selector: (row) => row?.paymentDate ?? "N/A",
      sortable: true,
    },

    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />{" "}
          {/* Program */}
          Invoice No.
        </span>
      ),
      selector: (row) => `INV-${row?.invoiceNo || "N?A"}` ?? "N/A",
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-nowrap">
          {/* Program */}
          Currency
        </span>
      ),
      selector: (row) => row?.currency || "NGN",
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => row?.amount ?? 0,
      sortable: true,
      cell: (row) => (
        <p className=" text-red">{formatAmount(row?.amount) ?? 0}</p>
      ),
    },
    {
      name: "Status",
      selector: (row) => formatCapitalize(row?.status),
      sortable: true,
      cell: (row) => (
        <p className=" text-primaryColor">
          {formatCapitalize(row?.status) ?? "N/A"}
        </p>
      ),
      // width: "9rem",
    },
    {
      name: "Funding Channel",
      selector: (row) => row?.fundingChannel,
      sortable: true,
      cell: (row) => (
        <p>{formatCapitaliseString(row?.fundingChannel) ?? "N/A"}</p>
      ),
    },
  ];

  const columnsBulk = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />{" "}
          {/* Program */}
          Transaction Party
        </span>
      ),
      selector: (row) => (
        <div className="flex justify-center flex-col">
          <span>{formatCapitalize(row?.transactionParty?.name)}</span>
          <span className="text-xs font-light text-primaryColor">
            ({formatCapitalize(row?.transactionParty?.role) || "Player"})
          </span>
        </div>
      ),
      sortable: true,
    },

    {
      name: "Payment Date",
      selector: (row) => row?.paymentDate,
      sortable: true,
    },

    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />{" "}
          {/* Program */}
          No of Invoices
        </span>
      ),
      selector: (row) => (
        <div className="text-center flex justify-center w-full">
          {row?.noOfInvoice}
        </div>
      ),
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-nowrap">
          {/* Program */}
          Currency
        </span>
      ),
      selector: (row) => row?.currency || "NGN",
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => row?.amount,
      sortable: true,
      cell: (row) => <p className=" text-red">{formatAmount(row?.amount)}</p>,
    },
    {
      name: "Status",
      selector: (row) => formatCapitalize(row?.status),
      sortable: true,
      cell: (row) => (
        <p className=" text-primaryColor">{formatCapitalize(row?.status)}</p>
      ),
      // width: "9rem",
    },
    {
      name: "Funding Channel",
      selector: (row) => row?.fundingChannel,
      sortable: true,
      cell: (row) => <p>{formatCapitaliseString(row?.fundingChannel)}</p>,
    },
  ];
  return (
    <>
      <div className="mb-2 grid md:grid-cols-2 items-center">
        <div className="gap-3 flex relative">
          <DatePicker
            className="px-5 py-2.5 min-w-56 text-sm bg-white rounded"
            selected={startDate}
            onChange={(update) => setDateRange(update)}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            placeholderText="Filters: Start date - End Date"
            // className="border  border-[#ccc] px-4 py-2 cursor-pointer rounded-md w-60"
          />

          <button
            onClick={() => {
              setSelectDownloadType(true);
            }}
            className="py-2.5 text-xs px-5 rounded-[5px] bg-white border-[#DEDEDE] shadow-sm mr-2 flex items-center justify-center gap-1.5"
          >
            Export {<PAIconDownload className="accent-gray300 w-4" />}
          </button>
        </div>

        <div className="hidden md:flex  items-center gap-2">
          <div className="flex gap-2.5 w-full bg-white border-[0.5px] border-[#ccc] p-2.5 items-center rounded-[5px]">
            <PAIconSearchGreen />
            <input
              type="text"
              placeholder="Search for invoices on the open market"
              value={searchQuery}
              onChange={handleSearchInputChange}
              className="p-2 w-full text-xs flex items-center text-black placeholder:text-black focus:outline-none"
            />
          </div>

          <motion.button
            whileTap={{ scale: 0.9 }}
            onClick={handleFilterButtonClick}
            className="ml-2 flex gap-2 items-center justify-center bg-[#2FA06A] text-white text-sm  px-6 py-4 rounded-md duration-[200ms] ease-in  hover:bg-primaryColor focus:outline-none"
          >
            <PAIconFilter />
            Filter
          </motion.button>
        </div>
      </div>

      {/* TABLE */}
      <div>
        {!disbursementFunderTransactionHistoryData?.singleTransactionHistory
          ?.data?.length ? (
          <div>
            <div className="flex justify-between items-center px-[15px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
              <p className="text-black text-sm font-medium">
                Due Invoices from Direct Funding
              </p>
            </div>

            <TableEmptyState dashboard />
          </div>
        ) : (
          <Card className="rounded-[10px] min-h-[80vh] mt-[15px]">
            <div className="flex justify-between items-center p-[20px] bg-alabasterHeader rounded-t-[10px]">
              <div className="flex gap-6 items-center">
                <div>
                  <p className="text-black text-lg font-bold">
                    Recent disbursement history
                  </p>
                  <span className="text-xs font-thin">
                    Here is a list of your recievables from Payment disbursement
                  </span>
                </div>
              </div>
            </div>
            <div className="pb-3">
              <div className="pb-3">
                <div className="flex px-[24px] gap-5 my-5">
                  <p
                    className={`cursor-pointer text-sm font-bold relative ${
                      selectedTab === "single"
                        ? "text-[#F08000]"
                        : "text-[#959595]"
                    }`}
                    onClick={() => {
                      setSelectedTab("single");
                      setSearchQuery("");
                    }}
                  >
                    Single Request
                    {selectedTab === "single" && (
                      <span className="absolute bottom-0 left-1/2 -translate-x-1/2 bg-[#F08000] h-0.5 w-5"></span>
                    )}
                  </p>

                  <p
                    className={`cursor-pointer text-sm font-bold relative ${
                      selectedTab === "bulk"
                        ? "text-[#F08000]"
                        : "text-[#959595]"
                    }`}
                    onClick={() => {
                      setSelectedTab("bulk");
                      setSearchQuery("");
                    }}
                  >
                    Bulk Request
                    {selectedTab === "bulk" && (
                      <span className="absolute bottom-0 left-1/2 -translate-x-1/2 bg-[#F08000] h-0.5 w-5"></span>
                    )}
                  </p>
                </div>
                {selectedTab === "bulk" ? (
                  <>
                    <Table
                      columns={columnsBulk}
                      data={
                        disbursementFunderTransactionHistoryData
                          ?.bulkTransactionHistory?.data
                      }
                      onRowClicked={(row) => {
                        navigate(
                          `funder/disbursement/transaction-details/bulk/${row?.transactionId}?offerId=${row?.programId}&channel=${row?.fundingChannel}`
                        );
                      }}
                      progressPending={isLoading}
                      progressComponent={<Loading />}
                      pointer
                      tableHeader
                      className="mt-5"
                    />

                    <div className="flex items-center h-full justify-center mt-4">
                      {disbursementFunderTransactionHistoryData
                        ?.bulkTransactionHistory?.meta?.totalElements >=
                        pageSize && (
                        <Pagination
                          page={page}
                          startPage={startPage}
                          endPage={endPage}
                          setEndPage={setEndPage}
                          setPage={setPage}
                          setStartPage={setStartPage}
                          totalPages={
                            disbursementFunderTransactionHistoryData
                              ?.bulkTransactionHistory?.meta?.totalElements
                          }
                        />
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <Table
                      columns={columns}
                      data={
                        disbursementFunderTransactionHistoryData
                          ?.singleTransactionHistory?.data
                      }
                      onRowClicked={(row) => {
                        navigate(
                          `funder/disbursement/transaction-details/${row?.transactionId}?invId=${row?.invoiceId}&offerId=${row?.programId}&channel=${row?.fundingChannel}`
                        );
                      }}
                      progressPending={isLoading}
                      progressComponent={<Loading />}
                      pointer
                      tableHeader
                      className="mt-5"
                    />
                    {/* singleTransactionHistory?.data?.length */}
                    <div className="flex items-center h-full  mt-4 justify-center">
                      {disbursementFunderTransactionHistoryData
                        ?.singleTransactionHistory?.meta?.totalElements >=
                        pageSize && (
                        <Pagination
                          page={page}
                          startPage={startPage}
                          endPage={endPage}
                          setEndPage={setEndPage}
                          setPage={setPage}
                          setStartPage={setStartPage}
                          totalPages={
                            disbursementFunderTransactionHistoryData
                              ?.singleTransactionHistory?.meta?.totalElements
                          }
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </Card>
        )}
      </div>
      <AnimatePresence>
        {selectDownloadType && (
          <ViewDocumentModal
            icon={<PAIconInvoiceIcon className=" animate-pulse" />}
            onAction={exportToExcel}
            onClose={() => setSelectDownloadType(false)}
          >
            <span className=" text-center">
              Are you sure you want to{" "}
              <span className="font-bold text-center text-primaryColor">
                Export
              </span>{" "}
              this invoice list
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {acceptPaymentModal && (
          <ViewDocumentModal
            onAction={() => {
              setAcceptPaymentModal(false);
              setPinModal(true);
              // setTransferModal(true);
            }}
            onClose={() => setAcceptPaymentModal(false)}
          >
            <span className=" text-center">
              Are you sure you would like to{" "}
              <span className="font-semibold">Pay now</span> for{" "}
              {selectAll ? "these multiple" : "this"} single invoices?
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {pinModal && (
          <EnterPin
            onClose={() => setPinModal(false)}
            // onSubmit={(pin) => {
            //   singlePay ? handlePayNowSingle(pin) : handlePayNow(pin);
            // }}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default TransactionHistory;
