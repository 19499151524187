import { PAIconCancel } from "assets/images/svgs";
import React from "react";
import Button from "shared/Button";

function BuyerDetails({ onClose, data }) {
  return (
    <div className="px-[27px] py-[38px]">
      <header className="flex justify-between border-b-2 border-whisper200 pb-[16px]">
        <p>About Member</p>
        <div
          onClick={onClose}
          className="flex justify-between items-center gap-x-[10px] cursor-pointer"
        >
          <PAIconCancel className="w-[8px]" />
          <p className="text-sm font-medium text-gray800">Close</p>
        </div>
      </header>
      <h2 className="text-sm font-semibold text-black mt-[32px] mb-[24px]">
        Personal Information
      </h2>
      <div>
        <p className="text-sm font-normal text-lightGray mb-[8px]">Name</p>
        <p className="text-sm font-normal text-textColor mb-[16px]">
          {data?.name ? data?.name : "N/A"}
        </p>
        <p className="text-sm font-normal text-lightGray mb-[8px]">Email</p>
        <p className="text-sm font-normal text-textColor mb-[16px]">
          {data?.email ? data?.email : "N/A"}
        </p>
        <p className="text-sm font-normal text-lightGray mb-[8px]">
          Contact number
        </p>
        <p className="text-sm font-normal text-textColor">
          {data?.phone ? data?.phone : "N/A"}
        </p>
      </div>
      <h2 className="text-sm font-semibold text-black mt-[32px] mb-[24px]">
        Company Information
      </h2>
      <div>
        <p className="text-sm font-normal text-lightGray mb-[8px]">
          Company Name
        </p>
        <p className="text-sm font-normal text-textColor mb-[16px]">
          {data?.companyName ? data?.companyName : "Cola Cola plc"}
        </p>
        <p className="text-sm font-normal text-lightGray mb-[8px]">
          Company Address
        </p>
        <p className="text-sm font-normal text-textColor mb-[16px]">
          {data?.registeredAddress ? data?.registeredAddress : "4b Utomi Aire Ave, off Freedom way, Lekki Phase 1"}
        </p>
        <p className="text-sm font-normal text-lightGray mb-[8px]">
          Company Website
        </p>
        <p className="text-sm font-normal text-textColor mb-[16px]">
          {data?.companyWebsite ? data?.companyWebsite : "www.colacola.com"}
        </p>
      </div>
      <div className="flex gap-x-[12px]">
          <Button
            // onClick={() => handleOpenModal(row)}
            neutral
            className="flex px-[16px] py-[8px] border-[1px] border-gray rounded-[5px] bg-primaryColor"
          >
            <p className="text-xs font-normal text-white whitespace-nowrap">
              Accept
            </p>
          </Button>
          <Button
            // onClick={() => handleOpenModal(row)}
            neutral
            className="flex px-[16px] py-[8px] border-[1px] border-[red] rounded-[5px] bg-[white]"
          >
            <p className="text-xs font-normal  text-[red] whitespace-nowrap">
              Decline
            </p>
          </Button>
        </div>
    </div>
  );
}

export default BuyerDetails;
