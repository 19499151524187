import { PAIconAddRole, PAIconArrowLeftGreen } from "assets/images/svgs";
import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import { formatCapitaliseString } from "utilities/helper/formatCapitaliseString";
import { AnimatePresence, motion } from "framer-motion";
import CreateNewRule from "./modals/CreateNewRule";
import CreateRuleFinalise from "./modals/CreateRuleFinalise";
import RulesConfigSearchInput from "./RulesConfigSearchInput";
import Switch from "features/settings/components/NotificationSetting/components/Switch";
// import toast from "react-hot-toast";

function RulesConfigHeader({
  setRuleSavedModal,
  handleSearch,
  searchBy,
  setSearchBy,
  searchQuery,
  setSearchQuery,
}) {
  const { ruleType } = useParams();
  const [createNewRuleModal, setCreateNewRuleModal] = useState(false);
  const [createNewRuleModalFinal, setCreateNewRuleModalFinal] = useState(false);

  // Create New Rule

  return (
    <div>
      {/* Create New Rule Modal */}
      <AnimatePresence>
        {createNewRuleModal && (
          <CreateNewRule
            closeModal={() => setCreateNewRuleModal(false)}
            setCreateNewRuleModalFinal={setCreateNewRuleModalFinal}
          />
        )}
      </AnimatePresence>

      {/* Rule Modal Finalise  */}
      <AnimatePresence>
        {createNewRuleModalFinal && (
          <CreateRuleFinalise
            closeModal={() => setCreateNewRuleModalFinal(false)}
            setCreateNewRuleModal={setCreateNewRuleModal}
            setRuleSavedModal={setRuleSavedModal}
          />
        )}
      </AnimatePresence>

      <header className=" flex flex-col gap-6 w-full">
        <div className="flex gap-4">
          <Link to="/settings/rules&config" className="flex gap-1.5 text-sm ">
            <PAIconArrowLeftGreen />
            <span className="flex items-center text-primaryColor">Go Back</span>
          </Link>

          <div>
            <h1 className="text-xl font-light">
              {formatCapitaliseString(ruleType)}
            </h1>
          </div>
        </div>

        <div className="p-4 rounded-lg border border-[#95959580] bg-[#E0E0E01A] flex flex-col gap-5">
          <div className="flex justify-between">
            <span className="font-semibold">
              Default configurations for Collections
            </span>
            <select
              className="cursor-pointer outline-none text-[#959595]"
              name=""
              id=""
            >
              <option value="" className="font-light font-sm text-[#959595]">
                Show All
              </option>
            </select>
          </div>

          <div>
            <span className="text-[#959595] font-semibold">
              Send Reminders:-
            </span>
          </div>

          <div className="flex justify-between">
            <div className="flex text-sm items-center">
              <div className="flex items-center gap-2">
                <span className="flex gap-2 py-2 font-light">
                  Send Reminders
                </span>
                <Switch />
              </div>

              <div className="ml-8 font-light">Upcoming Alert Days</div>

              <input className="p-2.5 ml-2 font-light" type="text" />
            </div>

            <div>
              <button className="text-secondaryColor rounded-[5px] border border-secondaryColor px-5 py-2.5 text-sm font-light">
                Edit
              </button>
            </div>
          </div>
        </div>

        <div className="flex md:flex-row justify-between flex-row-reverse">
          <motion.div whileTap={{ scale: 0.95 }} className="flex items-center">
            <button
              onClick={() => setCreateNewRuleModal(true)}
              className="px-6 gap-1.5 text-sm border-[0.5px] border-[#ccc] rounded-[5px] text-white py-3 flex justify-center items-center bg-brilliantYellow transform transition duration-500"
            >
              <PAIconAddRole />
              <span className="hidden md:inline-block ">Create new rule</span>
            </button>
          </motion.div>

          <div>
            <div>
              <RulesConfigSearchInput
                onSearch={handleSearch}
                searchBy={searchBy}
                setSearchBy={setSearchBy}
                setSearchQuery={setSearchQuery}
                searchQuery={searchQuery}
              />
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default RulesConfigHeader;
