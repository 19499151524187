import request from "apiInstance";
import toast from "react-hot-toast";

export const getEarlyPaymentRequestOverview = async (virtualAccountId) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/disbursement/early-payment-requests/${virtualAccountId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getDisbursemenmtOverview = async () => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/disbursement/buyer/dashboard`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getEarlyPaymentOfferOverview = async (virtualAccountId) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/disbursement/early-payment-offers/${virtualAccountId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getLienedPayablesByAccount = async ({
  virtualAccountId,
  selectedValue,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/disbursement/liened-payables/${virtualAccountId}?fundingChannel=${selectedValue}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getUpcomingPayablesByAccount = async ({
  virtualAccountId,
  selectedValue,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/disbursement/upcoming-payables/${virtualAccountId}?fundingChannel=${selectedValue}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getOverduePayablesByAccount = async ({
  virtualAccountId,
  selectedValue,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/disbursement/overdue/payables/${virtualAccountId}?fundingChannel=${selectedValue}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getTransactionHistory = async ({ page, size, search, isBulk }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/disbursement/transaction-history?page=${page}&size=${size}&search=${search}&isBulk=${isBulk}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getEarlyPaymentOfferDetails = async (offerId) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/payable-finance/early-payment-offer/${offerId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getDisbursementInvoiceDetails = async ({ invoiceId }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/${invoiceId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getEarlyPaymentRequestVirtualAccouts = async () => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/disbursement/early-payment-requests-va`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
export const getEarlyPaymentByvirtualAccountId = async ({
  virtualAccountId,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/disbursement/early-payment-requests/${virtualAccountId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getEarlyPaymentOfferVirtualAccouts = async () => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/disbursement/early-payment-offers-va`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getDefaultLoan = async ({ page, size }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/disbursement/loan-defaults?page=${page}&size=${size}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
export const getEarlyPaymentOfferByvirtualAccountId = async ({
  virtualAccountId,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/disbursement/early-payment-offers/${virtualAccountId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const makePayment = async (body) => {
  try {
    const response = await request({
      method: "post",
      url: `/api/v1/disbursement/pay-now`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const confirmFundTransferSuccess = async (body) => {
  try {
    const response = await request({
      method: "post",
      url: `/api/v1/disbursement/confirm-fund-transfer`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

/*
  return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }

*/
