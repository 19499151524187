export { ReactComponent as PAIconSettlement } from "assets/images/svgs/settlement.svg";
export { ReactComponent as PAIconArrowLeft } from "assets/images/svgs/arrow-left.svg";
export { ReactComponent as PAIconArrowRight } from "assets/images/svgs/arrow-right.svg";
export { ReactComponent as PAIconNewArrowRight } from "assets/images/svgs/new-arrow-right.svg";
export { ReactComponent as PAInoUploadImage } from "assets/images/svgs/no-upload-woman-img.svg";
export { ReactComponent as PAIconCancel } from "assets/images/svgs/cancel.svg";
export { ReactComponent as PAIconPurchace } from "assets/images/svgs/card-tick.svg";
export { ReactComponent as PAIconCaretDown } from "assets/images/svgs/caretdown.svg";
export { ReactComponent as PAIconSettings } from "assets/images/svgs/clarity_settings-line.svg";
export { ReactComponent as PAIconClose } from "assets/images/svgs/close.svg";
export { ReactComponent as PAIconColapsClose } from "assets/images/svgs/colapsclose.svg";
export { ReactComponent as PAIconColapsOpen } from "assets/images/svgs/colapsopen.svg";
export { ReactComponent as PAIconDashboard } from "assets/images/svgs/dashboard.svg";
export { ReactComponent as PAIconDistributor } from "assets/images/svgs/distributor.svg";
export { ReactComponent as PAIconProfilepic } from "assets/images/svgs/dp.svg";
export { ReactComponent as PAIconPaymnet } from "assets/images/svgs/empty-wallet.svg";
export { ReactComponent as PAIconEye } from "assets/images/svgs/eye.svg";
export { ReactComponent as PAIconFacebook } from "assets/images/svgs/facebook.svg";
export { ReactComponent as PAIconFilterIcon } from "assets/images/svgs/FilterIcon.svg";
export { ReactComponent as PAIconFrame } from "assets/images/svgs/Frame.svg";
export { ReactComponent as PAIconGlobal } from "assets/images/svgs/global-search.svg";
export { ReactComponent as PAIconHamburger } from "assets/images/svgs/hamburger.svg";
export { ReactComponent as PAIconInstagram } from "assets/images/svgs/Instagram.svg";
export { ReactComponent as PAIconInvoiceNotification } from "assets/images/svgs/InvoiceNotification.svg";
export { ReactComponent as PAIconCardReceive } from "assets/images/svgs/linear-card-receive.svg";
export { ReactComponent as PAIconWallet } from "assets/images/svgs/linear-empty-wallet-time.svg";
export { ReactComponent as PAIconPending } from "assets/images/svgs/linear-timer.svg";
export { ReactComponent as PAIconAddWallet } from "assets/images/svgs/linear-wallet-add.svg";
export { ReactComponent as PAIconLogout } from "assets/images/svgs/logout.svg";
export { ReactComponent as PAIconNotification } from "assets/images/svgs/notification.svg";
export { ReactComponent as PAIconLogo } from "assets/images/svgs/payEdgeLogoC.svg";
export { ReactComponent as PAIconProfile } from "assets/images/svgs/profile.svg";
export { ReactComponent as PAIconInvoice } from "assets/images/svgs/receipt-invoice.svg";
export { ReactComponent as PAIconReceipt } from "assets/images/svgs/receipt-minus.svg";
export { ReactComponent as PAIconRectangle } from "assets/images/svgs/Rectangle.svg";
export { ReactComponent as PAIconSearch } from "assets/images/svgs/search-normal.svg";
export { ReactComponent as PAIconSupplier } from "assets/images/svgs/supplier.svg";
export { ReactComponent as PAIconApprove } from "assets/images/svgs/tick-circle.svg";
export { ReactComponent as PAIconTime } from "assets/images/svgs/time.svg";
export { ReactComponent as PAIconTwitter } from "assets/images/svgs/twitter.svg";
export { ReactComponent as PAIconUndrawAddFiles } from "assets/images/svgs/undraw_add_files_re_v09g 1.svg";
export { ReactComponent as PAIconUser } from "assets/images/svgs/user.svg";
export { ReactComponent as PAIconVieweye } from "assets/images/svgs/view-eye.svg";
export { ReactComponent as PAIconSpinner } from "assets/images/svgs/spinner.svg";
export { ReactComponent as PAIconSuccess } from "assets/images/svgs/greenradio.svg";
export { ReactComponent as PAIconCheck } from "assets/images/svgs/radio.svg";
export { ReactComponent as PAIconInventory } from "assets/images/svgs/inventory.svg";
export { ReactComponent as PAIconAdd } from "assets/images/svgs/add.svg";
export { ReactComponent as PAIconImport } from "assets/images/svgs/import.svg";
export { ReactComponent as PAIconExport } from "assets/images/svgs/export.svg";
export { ReactComponent as PAIconPrint } from "assets/images/svgs/print.svg";
export { ReactComponent as PAIconInventoryArrowLeft } from "assets/images/svgs/inventory-arrow-left.svg";
export { ReactComponent as PAIconFilledArrowLeft } from "assets/images/svgs/FilledArrowLeft.svg";
export { ReactComponent as PAIconJamWrite } from "assets/images/svgs/jam_write-f.svg";
export { ReactComponent as PAIconFile } from "assets/images/svgs/ph_file-bold.svg";
export { ReactComponent as PAIconDownload } from "assets/images/svgs/material-symbols-download.svg";
export { ReactComponent as PAIconSearchPlaceholder } from "assets/images/svgs/clarity-search.svg";
export { ReactComponent as PAIconPaginationRight } from "assets/images/svgs/carat-right.svg";
export { ReactComponent as PAIconPaginationLeft } from "assets/images/svgs/carat-left.svg";
export { ReactComponent as PAIconPayassystP } from "assets/images/svgs/PayAssyst-P.svg";
export { ReactComponent as PAIconsDoublering } from "assets/images/svgs/DoubleRing.svg";
export { ReactComponent as PAIconInventoryPrint } from "assets/images/svgs/print.svg";
export { ReactComponent as PAIconAlert } from "assets/images/svgs/octicon-alert-24.svg";
export { ReactComponent as PAIconSettingsProfile } from "assets/images/svgs/settings-profile.svg";
export { ReactComponent as PAIconChangeImage } from "assets/images/svgs/change-image.svg";
export { ReactComponent as PAIconPencilLine } from "assets/images/svgs/pencil-line.svg";
export { ReactComponent as PAIconSettingsAdd } from "assets/images/svgs/settings-add.svg";
export { ReactComponent as PAIconSecurityLock } from "assets/images/svgs/security-lock.svg";
export { ReactComponent as PAIconQrCode } from "assets/images/svgs/qr-code.svg";
export { ReactComponent as PAIconUnlock } from "assets/images/svgs/unlock.svg";
export { ReactComponent as PAIconDelete } from "assets/images/svgs/delete.svg";
export { ReactComponent as PAIconWorkflow } from "assets/images/svgs/workflow.svg";
export { ReactComponent as PAIconSupplierNAv } from "assets/images/svgs/supplierNav.svg";
export { ReactComponent as PAIconFunder } from "assets/images/svgs/funder-profile.svg";
export { ReactComponent as PAIconFolder } from "assets/images/svgs/folder-open.svg";
export { ReactComponent as PARadioChecked } from "assets/images/svgs/radioChecked.svg";
export { ReactComponent as PARadioUncheked } from "assets/images/svgs/radioUnchecked.svg";
export { ReactComponent as PAAddFileImg } from "assets/images/svgs/addFileImg.svg";
export { ReactComponent as PAOpenEye } from "assets/images/svgs/eyeIcon.svg";
export { ReactComponent as PATrash } from "assets/images/svgs/trashIcon.svg";
export { ReactComponent as PACircleArrow } from "assets/images/svgs/circleArrowLeft.svg";
export { ReactComponent as PACautionIcon } from "assets/images/svgs/cautionIcon.svg";
export { ReactComponent as PAIconFilterOrder } from "assets/images/svgs/filter-order.svg";
export { ReactComponent as PAIconInboxActive } from "assets/images/svgs/inbox-active.svg";
export { ReactComponent as PAIconDraft } from "assets/images/svgs/draft.svg";
export { ReactComponent as PAIconSent } from "assets/images/svgs/send.svg";
export { ReactComponent as PAIconTrash } from "assets/images/svgs/trash.svg";
export { ReactComponent as PAIconNewSupport } from "assets/images/svgs/new-support.svg";
export { ReactComponent as PAIconHelpSupport } from "assets/images/svgs/support.svg";
export { ReactComponent as PAIconTrashRed } from "assets/images/svgs/trash-red.svg";
export { ReactComponent as PAIconFluentSend } from "assets/images/svgs/fluent-send.svg";
export { ReactComponent as PAIconEmail } from "assets/images/svgs/email.svg";
export { ReactComponent as PAIconDirectInbox } from "assets/images/svgs/directinbox-receive.svg";
export { ReactComponent as PAIconOption } from "assets/images/svgs/option-select.svg";
export { ReactComponent as PAIconOptionTwo } from "assets/images/svgs/option-select.svg";
export { ReactComponent as PAIconUmsExport } from "assets/images/svgs/ums-export.svg";
export { ReactComponent as PAIconUserAdd } from "assets/images/svgs/user-add.svg";
export { ReactComponent as PAIconUseradd } from "assets/images/svgs/user-add.svg";
export { ReactComponent as PAIconAccount } from "assets/images/svgs/people.svg";
export { ReactComponent as PAIconLink } from "assets/images/svgs/link-light.svg";
export { ReactComponent as PAIconDropUser } from "assets/images/svgs/vuesax-linear-user.svg";
export { ReactComponent as PAIconEditlight } from "assets/images/svgs/edit-light.svg";
export { ReactComponent as PAIconDoc } from "assets/images/svgs/document-text.svg";
export { ReactComponent as PAIconDetailsPlaceholder } from "assets/images/svgs/inv.svg";
export { ReactComponent as PAIconAlertIcon } from "assets/images/svgs/alert.svg";
export { ReactComponent as PAIconEmailIcon } from "assets/images/svgs/email-line.svg";
export { ReactComponent as PAIconCircle } from "assets/images/svgs/circle.svg";
export { ReactComponent as PAIconCardCircle } from "assets/images/svgs/Cardcirlce.svg";
export { ReactComponent as PAIconKitEyeclose } from "assets/images/svgs/formkit_eyeclosed.svg";
export { ReactComponent as PAIconMasterCardLogo } from "assets/images/svgs/atmicon.svg";
export { ReactComponent as PAIconRoleManger } from "assets/images/svgs/candle.svg";
export { ReactComponent as PAIconTableEmptyState } from "assets/images/svgs/emptystate.svg";
export { ReactComponent as PAIconCircleAdd } from "assets/images/svgs/add-circle.svg";
export { ReactComponent as PAIconColapse } from "assets/images/svgs/colaspe.svg";
export { ReactComponent as PAIconCardSend } from "assets/images/svgs/card-send.svg";
export { ReactComponent as PAIconInviteLink } from "assets/images/svgs/link-solid.svg";
export { ReactComponent as PAIconPendingRequest } from "assets/images/svgs/pending-outline.svg";
export { ReactComponent as PAIconTrashMini } from "assets/images/svgs/trash-mini.svg";
export { ReactComponent as PAIconViewMini } from "assets/images/svgs/eye-mini.svg";
export { ReactComponent as PAIconMarkRead } from "assets/images/svgs/mark-read.svg";
export { ReactComponent as PAIconAddRole } from "assets/images/svgs/add-role.svg";
export { ReactComponent as PAImageEmpty } from "assets/images/svgs/empty-error.svg";
export { ReactComponent as PAIconViewGreen } from "assets/images/svgs/eye-green.svg";
export { ReactComponent as PAIconSmallClose } from "assets/images/svgs/sm_close.svg";
export { ReactComponent as PAIconEditGreen } from "assets/images/svgs/edit-green.svg";
export { ReactComponent as PAIconCautionClear } from "assets/images/svgs/caution-alert.svg";
export { ReactComponent as PAIconArrowLeftMini } from "assets/images/svgs/arrow-circle-left.svg";
export { ReactComponent as PAIconArrowDown } from "assets/images/svgs/arrow-down.svg";
export { ReactComponent as PAIconCancelRound } from "assets/images/svgs/cancel-round.svg";
export { ReactComponent as PAIconCheckMark } from "assets/images/svgs/checkmark.svg";
export { ReactComponent as PAIconAgreementBag } from "assets/images/svgs/agreement-bag.svg";
export { ReactComponent as PAIconAgreementTools } from "assets/images/svgs/agreement-tools.svg";
export { ReactComponent as PAIconIllustration } from "assets/images/svgs/illustration.svg";
export { ReactComponent as PAIconCustomerHeadset } from "assets/images/svgs/customer-headset.svg";
export { ReactComponent as PAIconOutlineSearch } from "assets/images/svgs/outline-search.svg";
export { ReactComponent as PAIconFilter } from "assets/images/svgs/filter.svg";
export { ReactComponent as PAIconIosArrow } from "assets/images/svgs/ios-arrow.svg";
export { ReactComponent as PAIconDiscountCalculator } from "assets/images/svgs/discount-calculator.svg";
export { ReactComponent as PAIconPaymentCalculator } from "assets/images/svgs/payment-calculator.svg";
export { ReactComponent as PAIconROICalculator } from "assets/images/svgs/roi-calculator.svg";
export { ReactComponent as PAIconEmailTemplate } from "assets/images/svgs/email-template.svg";
export { ReactComponent as PAIconContractTemplate } from "assets/images/svgs/contract-template.svg";
export { ReactComponent as PAIconContract } from "assets/images/svgs/contract.svg";
export { ReactComponent as PAIconMore } from "assets/images/svgs/more.svg";
export { ReactComponent as PAIconStep1 } from "assets/images/svgs/step1.svg";
export { ReactComponent as PAIconStep2 } from "assets/images/svgs/step2.svg";
export { ReactComponent as PAIconStep3 } from "assets/images/svgs/step3.svg";
export { ReactComponent as PAIconStep4 } from "assets/images/svgs/step4.svg";
export { ReactComponent as PAIconStep5 } from "assets/images/svgs/step5.svg";
export { ReactComponent as PAIconWord } from "assets/images/svgs/word.svg";
export { ReactComponent as PAIconPDF } from "assets/images/svgs/pdf.svg";
export { ReactComponent as PAIconExcel } from "assets/images/svgs/excel.svg";
export { ReactComponent as PAIconCSV } from "assets/images/svgs/csv.svg";
export { ReactComponent as PAIconTemplateEmail } from "assets/images/svgs/template-email.svg";
export { ReactComponent as PAIconAddLine } from "assets/images/svgs/clarity_add-line.svg";
export { ReactComponent as PAIconCloseLine } from "assets/images/svgs/mingcute_close-line.svg";
export { ReactComponent as PAIconTablerEdit } from "assets/images/svgs/tabler_edit.svg";
export { ReactComponent as PAIconMIDelete } from "assets/images/svgs/mi_delete.svg";
export { ReactComponent as PAIconAlert24 } from "assets/images/svgs/octicon-alert-24.svg";
export { ReactComponent as PAIconDelete2 } from "assets/images/svgs/delete-02.svg";
export { ReactComponent as PAIconMDISuccess } from "assets/images/svgs/mdi_success.svg";
export { ReactComponent as PAIconView } from "assets/images/svgs/view.svg";
export { ReactComponent as PAIconSendLight } from "assets/images/svgs/send-light.svg";
export { ReactComponent as PAIconEditLight } from "assets/images/svgs/iconamoon_edit-light.svg";
export { ReactComponent as PAIconPrimeSave } from "assets/images/svgs/prime_save.svg";
export { ReactComponent as PAIconDrawAddFiles } from "assets/images/svgs/draw_add_files.svg";
export { ReactComponent as PAIconAgreementTemp } from "assets/images/svgs/agreement-temp.svg";
export { ReactComponent as PAIconArrowLeftSharp } from "assets/images/svgs/arrow-left-01-sharp.svg";
export { ReactComponent as PAIconWarningBold } from "assets/images/svgs/ph_warning-bold.svg";
export { ReactComponent as PAIconReject } from "assets/images/svgs/fluent_reject.svg";
export { ReactComponent as PAIconSign } from "assets/images/svgs/fluent_signature.svg";
export { ReactComponent as PAIconQuillSend } from "assets/images/svgs/quill_send.svg";
export { ReactComponent as PAIconMDIClock } from "assets/images/svgs/mdi_clock.svg";
export { ReactComponent as PAIconMDIClock1 } from "assets/images/svgs/mdi_clock (1).svg";
export { ReactComponent as PAIconDropdown } from "assets/images/svgs/dropdown.svg";
export { ReactComponent as PAIconGroupAgreement } from "assets/images/svgs/Group-2186.svg";
export { ReactComponent as PAIconclarityEmail } from "assets/images/svgs/clarity_email-line.svg";
export { ReactComponent as PAIconQuillSend1 } from "assets/images/svgs/quill_send (1).svg";
export { ReactComponent as PAIconWalletEcclipse } from "assets/images/svgs/wallet-eclipse.svg";
export { ReactComponent as PAIconCardArrow } from "assets/images/svgs/arrow-Card.svg";
export { ReactComponent as PAIconWalletYellow } from "assets/images/svgs/wallet-yellow.svg";
export { ReactComponent as PAIconArrowYellow } from "assets/images/svgs/arrowCard-yellow.svg";
export { ReactComponent as PAIconWalletBlue } from "assets/images/svgs/wallet-icon-blue.svg";
export { ReactComponent as PAIconArrowBlue } from "assets/images/svgs/arrow-blue.svg";
export { ReactComponent as PAIconWalletPink } from "assets/images/svgs/wallet-icon-pink.svg";
export { ReactComponent as PAIconArrowPink } from "assets/images/svgs/arrow-pink.svg";
export { ReactComponent as PAIconWalletReddish } from "assets/images/svgs/reddish-wallet-icon.svg";
export { ReactComponent as PAIconArrowReddish } from "assets/images/svgs/arrow-reddish.svg";
export { ReactComponent as PAIconWalletIndigo } from "assets/images/svgs/wallet-indigo.svg";
export { ReactComponent as PAIconArrowIndigo } from "assets/images/svgs/indigo-arrow.svg";
export { ReactComponent as PAIconWalletPurple } from "assets/images/svgs/wallet-purple.svg";
export { ReactComponent as PAIconArrowPurple } from "assets/images/svgs/arrow-purple.svg";
export { ReactComponent as PAIconWalletLightYellow } from "assets/images/svgs/light-yellow-wallet.svg";
export { ReactComponent as PAIconArrowLightYellow } from "assets/images/svgs/light-yellow-arrow.svg";
export { ReactComponent as PAIconWalletLightGreen } from "assets/images/svgs/light-green-wallet.svg";
export { ReactComponent as PAIconArrowLightGreen } from "assets/images/svgs/light-green-arrow.svg";
export { ReactComponent as PAIconWalletSplendidGreen } from "assets/images/svgs/splendid-green-wallet.svg";
export { ReactComponent as PAIconArrowSplendidGreen } from "assets/images/svgs/splendid-green-arrow.svg";
export { ReactComponent as PAIconArrowLeftGreen } from "assets/images/svgs/arrow-left-green.svg";
export { ReactComponent as PAIconTestTube } from "assets/images/svgs/test-tube.svg";
export { ReactComponent as PAIconDeactivate } from "assets/images/svgs/deactivate.svg";
export { ReactComponent as PAIconSortUp } from "assets/images/svgs/sort-up.svg";
export { ReactComponent as PAIconSortDown } from "assets/images/svgs/sort-down.svg";
export { ReactComponent as PAIconFluentBox } from "assets/images/svgs/fluent_box.svg";
export { ReactComponent as PAIconCardOutline } from "assets/images/svgs/ion_card-outline.svg";
export { ReactComponent as PAIconTransferFund } from "assets/images/svgs/transferFund.svg";
export { ReactComponent as PAIconSolarCopyBroken } from "assets/images/svgs/solar_copy-broken.svg";
export { ReactComponent as PAIconBasilOutline } from "assets/images/svgs/basil_other-1-outline.svg";
export { ReactComponent as PAIconVirtualDebitCard } from "assets/images/svgs/debit_card.svg";
export { ReactComponent as PAIconBasilOutlineWhite } from "assets/images/svgs/basil_other-1-outline-white.svg";
export { ReactComponent as PAIconMastercardWhite } from "assets/images/svgs/mastercard_white.svg";
export { ReactComponent as PAIconCardPayment } from "assets/images/svgs/card-payment.svg";
export { ReactComponent as PAIconManageSearch } from "assets/images/svgs/ic_round-manage-search.svg";
export { ReactComponent as PAIconViewDay } from "assets/images/svgs/ic_outline-view-day.svg";
export { ReactComponent as PAIconDelete3 } from "assets/images/svgs/ic_outline-delete.svg";
export { ReactComponent as PAIconTestTubeGreen } from "assets/images/svgs/test-tube-green.svg";
export { ReactComponent as PAIconCheckMarkGreen } from "assets/images/svgs/checkmark-circle-green.svg";
export { ReactComponent as PAIconCheckmarkYellow } from "assets/images/svgs/checkmark-circle-yellow.svg";
export { ReactComponent as PAIconAddGreen } from "assets/images/svgs/add-green.svg";
export { ReactComponent as PAIconEmptyFolder } from "assets/images/svgs/empty-folder.svg";
export { ReactComponent as PAIconRoundedCheck } from "assets/images/svgs/rounded-check.svg";
export { ReactComponent as PAIconRoundedCheckYellow } from "assets/images/svgs/circle-checkmark-yellow.svg";
export { ReactComponent as PAIconFluentBoxGreen } from "assets/images/svgs/fluent_box-green.svg";
export { ReactComponent as PAIconMore1 } from "assets/images/svgs/more-1.svg";
export { ReactComponent as PAIconPfp } from "assets/images/svgs/trade.svg";
export { ReactComponent as PAIconPcm } from "assets/images/svgs/closed-market-side-bar-icon.svg";
export { ReactComponent as PAIconConfirm } from "assets/images/svgs/gg_check-o.svg";
export { ReactComponent as PAIconFunderPfp } from "assets/images/svgs/invoice-svgrepo-com 1.svg";
export { ReactComponent as PAIconShop } from "assets/images/svgs/shop.svg";
export { ReactComponent as PAIconSearchGreen } from "assets/images/svgs/search-green.svg";
export { ReactComponent as PAIconInvShop } from "assets/images/svgs/inv-shop.svg";
export { ReactComponent as PAIconCocacola } from "assets/images/svgs/cocacola.svg";
export { ReactComponent as PAIconLockYellow } from "assets/images/svgs/lock-yellow.svg";
export { ReactComponent as PAIconModelOne } from "assets/images/svgs/cocacola.svg";
export { ReactComponent as PAIconHappy } from "assets/images/svgs/happy.svg";
export { ReactComponent as PAIconSabModel } from "assets/images/svgs/model-sabs.svg";
export { ReactComponent as PAIconBinTrash } from "assets/images/svgs/bin-trash.svg";
export { ReactComponent as PAIconArrowUpGreen } from "assets/images/svgs/arrow-up-green.svg";
export { ReactComponent as PAIconArrowDownGreen } from "assets/images/svgs/arrow-down-green.svg";
export { ReactComponent as PAIconModelLady } from "assets/images/svgs/cocacola.svg";
export { ReactComponent as PAIconInfo } from "assets/images/svgs/material-symbols-info.svg";
export { ReactComponent as PAIconPurse } from "assets/images/svgs/purse.svg";
export { ReactComponent as PAIconPaymentExtension } from "assets/images/svgs/Payment-extension.svg";
export { ReactComponent as PAIconEditMain } from "assets/images/svgs/edit-main.svg";
export { ReactComponent as PAIconInvoiceIcon } from "assets/images/svgs/invNew.svg";
export { ReactComponent as PAIconUnlockPad } from "assets/images/svgs/unlock-pad.svg";
export { ReactComponent as PAIconLockPad } from "assets/images/svgs/lock-pad.svg";
export { ReactComponent as PAIconInfoGreen } from "assets/images/svgs/info.svg";
export { ReactComponent as PAIconAccept } from "assets/images/svgs/accept.svg";
export { ReactComponent as PAIconSmallBrewIcon } from "assets/images/svgs/small-brewery.svg";
export { ReactComponent as PAIconSmallNestle } from "assets/images/svgs/small-nestle.svg";
export { ReactComponent as PAIconSmallShell } from "assets/images/svgs/small-shell.svg";
export { ReactComponent as PAIconSmallCoke } from "assets/images/svgs/small_coke_icon.svg";
export { ReactComponent as PAIconSmallDangote } from "assets/images/svgs/small_dangote_icon.svg";
export { ReactComponent as PAIconSmallTrust } from "assets/images/svgs/small-trust.svg";
export { ReactComponent as PAIconImportPlus } from "assets/images/svgs/import_plus.svg";
export { ReactComponent as PAIconImportArrrowDown } from "assets/images/svgs/import_arrow_down.svg";
export { ReactComponent as PAIconSmallAccessIcon } from "assets/images/svgs/small_access_icon.svg";
export { ReactComponent as PAIconCancelMini } from "assets/images/svgs/cancel-mini.svg";
export { ReactComponent as PAIconSearchForFunder } from "assets/images/svgs/search-for-funder.svg";
export { ReactComponent as PAIconIconParkSucess } from "assets/images/svgs/icon-park-outline_success.svg";
export { ReactComponent as PAIconIconRejectedBid } from "assets/images/svgs/rejectbidicon.svg";
export { ReactComponent as PAIconIconAcceptedBid } from "assets/images/svgs/undraw_happy_announcement_re_tsm0 1.svg";
export { ReactComponent as PAIconIconPaymentSideBarIcon } from "assets/images/svgs/payment-sidebar-icon.svg";
export { ReactComponent as PAIconIconFundBidIcon } from "assets/images/svgs/fundbidacceptedmodal.svg";
export { ReactComponent as PAIconPayEdgeLogo } from "assets/images/svgs/pay-edge-logo.svg";
export { ReactComponent as PAIconIllustrationKYC } from "assets/images/svgs/illustration-kyc.svg";
export { ReactComponent as PAIconSolarLeftGreenArrow } from "assets/images/svgs/solar_arrow-up-line-duotone.svg";
export { ReactComponent as PAISelectPaymentOptionLogo } from "assets/images/svgs/select_payment_option_logo.svg";
export { ReactComponent as PAISelectBoxFilled } from "assets/images/svgs/fluent_box-filled.svg";
export { ReactComponent as PAICloseMarketWhite } from "assets/images/svgs/closemarketwhite.svg";
export { ReactComponent as PAIDangerAlert } from "assets/images/svgs/danger-alert.svg";
export { ReactComponent as PAIThickArrowDropDown } from "assets/images/svgs/arrow-drop-downl.svg";
export { ReactComponent as PAIMsIcon } from "assets/images/svgs/icons_-type-word.svg";
export { ReactComponent as PAIPdfIcon } from "assets/images/svgs/vscode-icons_file-type-pdf2.svg";
export { ReactComponent as PAIExcelIcon } from "assets/images/svgs/vscode-icons_file-type-excel.svg";
export { ReactComponent as PAICsvIcon } from "assets/images/svgs/fa6-solid_file-csv.svg";
export { ReactComponent as PAICalenderPickerIcon } from "assets/images/svgs/calendar-picker-icon.svg";
export { ReactComponent as PAIconCardFundPortfolio } from "assets/images/svgs/invoice-bill.svg";
export { ReactComponent as PAIconOutlineFunds } from "assets/images/svgs/icon-park-outline_funds.svg";
export { ReactComponent as PAIconSquareArrowUp } from "assets/images/svgs/square-arrow-up-right-round.svg";
export { ReactComponent as PAIconSolarWalletOutline } from "assets/images/svgs/solar_wallet-outline.svg";
export { ReactComponent as PAIconLockOutline } from "assets/images/svgs/material-symbols_lock-outline.svg";
export { ReactComponent as PAIconOutlineFundsWhite } from "assets/images/svgs/icon-park-outline_funds_white.svg";
export { ReactComponent as PAIconFluentBoxPurple } from "assets/images/svgs/fluent_box-multiple-20-filled.svg";
export { ReactComponent as PAIconFluentBoxBrown } from "assets/images/svgs/fluent_box-multiple-20-filled_brown.svg";
export { ReactComponent as PAIconFiller } from "assets/images/svgs/octicon_filter-16.svg";
export { ReactComponent as PAIconCalendar } from "assets/images/svgs/clarity_date-line.svg";
export { ReactComponent as PAIconMdiReload } from "assets/images/svgs/mdi_reload.svg";
export { ReactComponent as PAIconTickedRadio } from "assets/images/svgs/tickedradio.svg";
export { ReactComponent as PAIconUnTickedRadio } from "assets/images/svgs/untickedradio.svg";
export { ReactComponent as PAIconFileMini } from "assets/images/svgs/file-mini.svg";
export { ReactComponent as PAIconCheckPass } from "assets/images/svgs/checkpass.svg";
export { ReactComponent as PAIconAgreeFolder } from "assets/images/svgs/agreement-file.svg";
export { ReactComponent as PAIconAgreeReject } from "assets/images/svgs/reject-agreement.svg";
export { ReactComponent as PAIconSignPen } from "assets/images/svgs/signature-pen.svg";
export { ReactComponent as PAIconAgreeWarning } from "assets/images/svgs/agreement-warning.svg";
export { ReactComponent as PAIconThickArrowDown } from "assets/images/svgs/thick-arrow-down.svg";
export { ReactComponent as PAIconFactoringMainIcon } from "assets/images/svgs/factoring-main-icon.svg";
export { ReactComponent as PAIconArrowDownThin } from "assets/images/svgs/arrow-down-thin.svg";
export { ReactComponent as PAIconClearSelect } from "assets/images/svgs/clearselect.svg";
export { ReactComponent as PAIconLockIcon } from "assets/images/svgs/lock-yellow.svg";
export { ReactComponent as PAIconLockCloseIcon } from "assets/images/svgs/carbon_close-outline.svg";
export { ReactComponent as PAIconFactoringOpenMarket } from "assets/images/svgs/cash-01.svg";
export { ReactComponent as PAIconFactoringDirectFunding } from "assets/images/svgs/wallet-directfunding.svg";
export { ReactComponent as PAIconCircleSuccessCheck } from "assets/images/svgs/gcircle_check-o.svg";
export { ReactComponent as PAIconSmallWallet } from "assets/images/svgs/small-empty-wallet.svg";
export { ReactComponent as PAIconYellowLoader } from "assets/images/svgs/yellow-loader.svg";
export { ReactComponent as PAIconSuccessCash } from "assets/images/svgs/payment-success.svg";
export { ReactComponent as PAIconArrowLeftGray } from "assets/images/svgs/gray-arrow-left.svg";
export { ReactComponent as PAIconPayedgeWallet } from "assets/images/svgs/payedgeWallet.svg";
export { ReactComponent as PAIconGroup } from "assets/images/svgs/Group.svg";

export { ReactComponent as PAIconPagRightIcon } from "assets/images/svgs/paginate-right-icon.svg";
export { ReactComponent as PAIconPagLeftIcon } from "assets/images/svgs/iconoir_forward.svg";
export { ReactComponent as PAIconPagDownArrowIcon } from "assets/images/svgs/arrow-down-pg.svg";
export { ReactComponent as PAIconPagLeftArrowIcon } from "assets/images/svgs/arrow-left-pg.svg";
export { ReactComponent as PAIconPagRightArrowIcon } from "assets/images/svgs/arrow-right-pg.svg";
export { ReactComponent as PAIconOnboardArrowLeftIcon } from "assets/images/svgs/arrow-left-Bigger.svg";
export { ReactComponent as PAIconVerifiedIcon } from "assets/images/svgs/ic_outline-verified.svg";
export { ReactComponent as PAIconAgreementSignIcon } from "assets/images/svgs/agreement-rejected-icon.svg";
export { ReactComponent as PAIconAgreementSendIcon } from "assets/images/svgs/agreement_send.svg";
export { ReactComponent as PAIconPrimaryColorArrowRight } from "assets/images/svgs/arrowrightblue.svg";
export { ReactComponent as PAIconDownloadFunding } from "assets/images/svgs/download-btnn.svg";
export { ReactComponent as PAIconPaymentGreen } from "assets/images/svgs/paymentGreen.svg";
export { ReactComponent as PAIconWalletCardBlack } from "assets/images/svgs/walletCardBlack.svg";
export { ReactComponent as PAIconWalletRadio } from "assets/images/svgs/wallet-radio.svg";

export { ReactComponent as PAIconBigArrowLeft } from "assets/images/svgs/big-closeimg.svg";
export { ReactComponent as PAIconWorkFlowIcon } from "assets/images/svgs/candle-workflow.svg";
export { ReactComponent as PAIconWorkFlowArrowRedLeft } from "assets/images/svgs/Line.svg";
export { ReactComponent as PAIconWorkFlowArrowLeft } from "assets/images/svgs/Line-arrow.svg";
export { ReactComponent as PAIconWorkTopFilerIcon } from "assets/images/svgs/filter-list.svg";
export { ReactComponent as PAIconPauseRed } from "assets/images/svgs/pause-red.svg";
export { ReactComponent as PAIStarDoneSucess } from "assets/images/svgs/icon-park-star_success.svg";
export { ReactComponent as PAIconCautionRole } from "assets/images/svgs/caution-role-manager.svg";
export { ReactComponent as PAIconInviteRemove } from "assets/images/svgs/removevector.svg";
export { ReactComponent as PAIconInviteTopArrow } from "assets/images/svgs/rounded-arrow-circle-left.svg";
export { ReactComponent as PAIconVirtualAccounSuccess } from "assets/images/svgs/virtualaccountcreated.svg";
export { ReactComponent as PAIconDeleteSuccess } from "assets/images/svgs/deleteSuccess.svg";
export { ReactComponent as PAIconResume } from "assets/images/svgs/resume.svg";
export { ReactComponent as PALikeIcon } from "assets/images/svgs/like-icon.svg";
export { ReactComponent as PABadgeIcon } from "assets/images/svgs/badge.svg";
export { ReactComponent as PAVerifiedAccount } from "assets/images/svgs/accountVerifiedIcon.svg";
export { ReactComponent as PASuccessIcon } from "assets/images/svgs/successIcon.svg";
export { ReactComponent as PASuccessWave } from "assets/images/svgs/successWave.svg";
export { ReactComponent as PAEditTableIcon } from "assets/images/svgs/edit-02 1.svg";
export { ReactComponent as PADeleteTableIcon } from "assets/images/svgs/delete-02 1.svg";
export { ReactComponent as PAEllipsisIcon } from "assets/images/svgs/EllipseMat.svg";
export { ReactComponent as PABlackSmallDangote } from "assets/images/svgs/blacksmalldangote.svg";
export { ReactComponent as PAIconDeleteCute } from "assets/images/svgs/mingcute_delete-line.svg";
export { ReactComponent as PAIconSmallExportDownloadIcon } from "assets/images/svgs/small-download-05.svg";
// export { ReactComponent as PABlackSmallDangote } from "assets/images/svgs/blacksmalldangote.svg";
export { ReactComponent as PAIconMakiCaution } from "assets/images/svgs/maki_caution.svg";
export { ReactComponent as PAIconDirectFundingRejected } from "assets/images/svgs/RejectGroup.svg";

export { ReactComponent as PAIconEditPenYellow } from "assets/images/svgs/editPen.svg";
export { ReactComponent as PAIconPaymentTag } from "assets/images/svgs/paymentOfferTag.svg";
export { ReactComponent as PAIconPaymentRequest } from "assets/images/svgs/paymentRequestIcon.svg";
export { ReactComponent as PAIconChecked } from "assets/images/svgs/checkbox-checked.svg";
export { ReactComponent as PAIconUncheck } from "assets/images/svgs/checkbox-unchecked.svg";
export { ReactComponent as PAIconEdgeLogo } from "assets/images/svgs/PayEdgeLogo.svg";
export { ReactComponent as PAIconYellowBadge } from "assets/images/svgs/yellow-badge.svg";
export { ReactComponent as PAIconProfileAvatar } from "assets/images/svgs/dp.svg";
export { ReactComponent as PAIconProfilePicSide } from "assets/images/svgs/dpNew.svg";
export { ReactComponent as PAIconEyePfp } from "assets/images/svgs/eye-pfp.svg";
export { ReactComponent as PAIconEmptyStateTable } from "assets/images/svgs/emptyStateTable.svg";
export { ReactComponent as PAIExportableDownload } from "assets/images/svgs/exportable-download-05.svg";
export { ReactComponent as PAICashSmall } from "assets/images/svgs/cash-02.svg";
export { ReactComponent as PAIconTrophy } from "assets/images/svgs/trophy.svg";
export { ReactComponent as PAIconTickDocIcon } from "assets/images/svgs/basil_document-solid.svg";
export { ReactComponent as PAIconTextRgularIcon } from "assets/images/svgs/text-regular.svg";
export { ReactComponent as PAIconPepsi } from "assets/images/svgs/pepsi.svg";
export { ReactComponent as PAIconActionDownloadBtn } from "assets/images/svgs/action_download.svg";
export { ReactComponent as PAIconActionDownloadDoc } from "assets/images/svgs/document-large-text.svg";
export { ReactComponent as PAIconActionAgreeDetailsActive } from "assets/images/svgs/license-draft-active.svg";
export { ReactComponent as PAIconActionAgreeDetailsGray } from "assets/images/svgs/license-draftgray.svg";
export { ReactComponent as PAIconActionPreviewGray } from "assets/images/svgs/eye-gray.svg";
export { ReactComponent as PAIconActionPreviewActive } from "assets/images/svgs/eye-active.svg";
export { ReactComponent as PAIconActionTemplateGray } from "assets/images/svgs/template-gray.svg";
// export { ReactComponent as PAIconActionTemplateActive } from "assets/images/svgs/artboard-tool-active.svg";
// export { ReactComponent as PAIconActionAgreementType } from "assets/images/svgs/agreement-03.svg";
// export { ReactComponent as PAIconActionAgreementTypeGray } from "assets/images/svgs/agreement-03-gray.svg";
// export { ReactComponent as PAIconActionAgreementArrowEnd } from "assets/images/svgs/arrow-down-01.svg";
// export { ReactComponent as PAIconActionWarning } from "assets/images/svgs/ph_warning-bold.svg";
// export { ReactComponent as PAIconAddDocPlusIcon } from "assets/images/svgs/teenyicons_add-outline.svg";
// export { ReactComponent as PAIconDashedIconUpdate } from "assets/images/svgs/dashicons_update.svg";
// export { ReactComponent as PAIconIconThumbsUp } from "assets/images/svgs/🦆 icon _like 1_.svg";
export { ReactComponent as PAIconIconLeftPointer } from "assets/images/svgs/colaspearrow.svg";
export { ReactComponent as PAIconIconLineLeftArrow } from "assets/images/svgs/Line-left-arrow.svg";
export { ReactComponent as PAIconIconLineLeftArrowWhite } from "assets/images/svgs/line-left-white.svg";
export { ReactComponent as PAIconIconPfpDashLogo } from "assets/images/svgs/Pfpdash-logo.svg";
export { ReactComponent as PAIconIconAddCircleBlack } from "assets/images/svgs/add-circle-black.svg";
export { ReactComponent as PAIconIconDowbloadArrow } from "assets/images/svgs/circle-arrow-upload-up-left.svg";
export { ReactComponent as PAIconIconBoxClipBoard } from "assets/images/svgs/fluent_box-clipboardsvg.svg";
export { ReactComponent as PAIconIconTopArrowWhite } from "assets/images/svgs/topArrowwhite.svg";
export { ReactComponent as PAIconIconCollapseYello } from "assets/images/svgs/colaspe-yellow.svg";
export { ReactComponent as PAIconActionTemplateActive } from "assets/images/svgs/artboard-tool-active.svg";
export { ReactComponent as PAIconActionAgreementType } from "assets/images/svgs/agreement-03.svg";
export { ReactComponent as PAIconActionAgreementTypeGray } from "assets/images/svgs/agreement-03-gray.svg";
export { ReactComponent as PAIconActionAgreementArrowEnd } from "assets/images/svgs/arrow-down-01.svg";
export { ReactComponent as PAIconActionWarning } from "assets/images/svgs/ph_warning-bold.svg";
export { ReactComponent as PAIconAddDocPlusIcon } from "assets/images/svgs/teenyicons_add-outline.svg";
