import { PAIconCancelMini, PALikeIcon } from "assets/images/svgs";

const NINVerifiedModal = ({ isOpen, setIsOpen }) => {
  return (
    <div>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white  px-6 flex justify-center flex-col py-4 rounded-lg shadow-lg w-fit min-w-[30%] max-w-md">
            <header className="flex border-b border-b-barColor items-center py-2  gap-2 justify-between">
              <span className="text-xl flex items-center font-semibold">
                NIN Verification{" "}
              </span>

              <span
                onClick={() => setIsOpen(false)}
                className="text-sm cursor-pointer items-center flex gap-1"
              >
                <PAIconCancelMini /> Close
              </span>
            </header>
            <div className="flex items-center flex-col w-full">
              <section className="flex w-full flex-col gap-2.5 text-gray-700 py-10">
                <div className="text-[#222] text-center flex justify-center text-xs">
                  <PALikeIcon />
                </div>

                <div className="font-medium text-base text-[#7a7a7a] text-center">
                  Congratulations, your NIN has been verified successfully
                </div>
              </section>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NINVerifiedModal;
