import { setSelectedAccounts } from "appstate/wallet/walletSlice";
import { PAIconPaymnet } from "assets/images/svgs";
import React from "react";
import { useDispatch } from "react-redux";

const AccountCard = ({ account, onSelect }) => {
  const formatAmount = (amount) => {
    if (!amount) return "0.00";
    const parsedAmount = parseFloat(amount);
    if (isNaN(parsedAmount)) return "0.00";
    return parsedAmount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };
  return (
    <div
      className="bg-[#07593D] p-3 mt-3 cursor-pointer text-white text-xs md:w-[calc(50%-10px)] md:mb-0 mb-3 rounded"
      onClick={onSelect}
    >
      <div className="flex justify-between">
        <div className="text-white flex items-center">
          <PAIconPaymnet className="mr-2 text-sm font-bold" />
          <p>{account.name}</p>
        </div>
        <p className="text-white font-light text-xs my-2">
          {account.accountType}
        </p>
      </div>
      <p className="mt-2 italic font-bold text-[18px] flex justify-start">
      {account.currency} {formatAmount(account.amount)}
      </p>
      <p className="mt-5 text-xs">
        <span className="mr-1">{account.bankName}</span> |
        <span className="ml-1">{account.accountNumber}</span>
      </p>
    </div>
  );
};

const AccountList = ({ accountData, handleSubmit }) => {
  const dispatch = useDispatch();

  return (
    <div className="md:flex md:gap-5 flex-wrap max-h-48 overflow-auto">
      {accountData.map((account, index) => (
        <AccountCard
          key={index}
          account={account}
          onSelect={() => {
            dispatch(setSelectedAccounts(account));
            handleSubmit();
          }}
        />
      ))}
    </div>
  );
};

export default AccountList;
