
import {
    PAIconCancel,
    PAIconEmailIcon,
  } from "assets/images/svgs";
  import React from "react";
  
  const RequestToAssociate = ({ info, hide }) => {
    return (
      <>
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg p-6 w-[500px] max-w-full shadow-lg">
            <header className="flex justify-between border-b-2 border-whisper200 pb-4">
              <p className="text-lg font-semibold">Request to associate</p>
              <div
                onClick={hide}
                className="flex justify-between items-center gap-2 cursor-pointer"
              >
                <PAIconCancel className="w-2" />
                <p className="text-sm font-medium text-gray800">Close</p>
              </div>
            </header>
            <div className="flex justify-center mt-8 mb-4">
              <PAIconEmailIcon />
            </div>
            <p className="text-center px-14">{info}</p>
          </div>
        </div>
      </>
    );
  };
  
  export default RequestToAssociate;
  