import React, { useEffect } from "react";
import HeaderTabs from "shared/HeaderTabs";
import Overview from "../overview";
import DirectFunding from "../directFunding";
import OpenMarket from "../openMarket";
import TransactionHistory from "../transactionHistory";
// import ClosedMarket from "../closedMarket";
// import EarlyPaymentProgram from "../earlyPaymentProgram";
import EarlyPaymentOffer from "../earlyPaymentProgram";
import ClosedMarket from "../closedMarket";
import { useDispatch } from "react-redux";
import { setActiveTab } from "appstate/collections/collectionSlice";
import OverdueInvoices from "../overdueInvoices";

const FunderCollectionDashboard = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActiveTab("overview"));
  }, [dispatch]);

  return (
    <HeaderTabs
      tabs={[
        { key: "overview", label: "Overview", content: <Overview /> },
        {
          key: "directFunding",
          label: "Direct Funding",
          content: <DirectFunding />,
        },
        {
          key: "openMarket",
          label: "Open Market",
          content: <OpenMarket />,
        },
        {
          key: "earlyPaymentRequest",
          label: "Closed Market",
          // content: <EarlyPayments />,

          content: <ClosedMarket />,
        },
        {
          key: "earlyPaymentOffers",
          label: "Early Payment Offers",
          // content: <EarlyPaymentOffers />,
          content: <EarlyPaymentOffer />,
        },
        {
          key: "Recievables",
          label: "Overdue Invoices",
          // content: <Recievables />,
          content: <OverdueInvoices />,
        },
        {
          key: "TransactionHistory",
          label: "Transaction History",
          content: <TransactionHistory />,
        },
      ]}
      hasPaddingY={true}
    />
    // <HeaderTabs
    //   tabs={[
    //     { key: "overview", label: "Overview", content: <Overview /> },
    //     {
    //       key: "directFunding",
    //       label: "Direct Funding",
    //       content: <DirectFunding />,
    //     },
    //     {
    //       key: "openMarket",
    //       label: "Open Market",
    //       content: <OpenMarket />,
    //     },
    //     {
    //       key: "closedMarket",
    //       label: "Closed Market",
    //       content: <ClosedMarket />,
    //     },
    //     {
    //       key: "earlyPaymentProgram",
    //       label: "Early Payment Program",
    //       content: <EarlyPaymentProgram />,
    //     },
    //     {
    //       key: "TransactionHistory",
    //       label: "Transaction History",
    //       content: <TransactionHistory />,
    //     },
    //   ]}
    //   hasPaddingY={true}
    // />
  );
};

export default FunderCollectionDashboard;
