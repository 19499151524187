import Modal from "layout/modal/Modal";
import React from "react";
import DirectRequestModalContent from "./DirectRequestModalContent";

const DirectRequestModal = ({ isShown, onClose, data, directFunding, setShowDirectModal }) => {
  return (
    <Modal largeModal accountModal isShown={isShown} hide={onClose}>
      <DirectRequestModalContent data={data} onClose={onClose} directFunding={directFunding} setShowDirectModal={setShowDirectModal} />
    </Modal>
  );
};

export default DirectRequestModal;
