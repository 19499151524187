import React, { useEffect, useState } from "react";
import {  PAIconInventoryArrowLeft } from "assets/images/svgs";
import { Link, useNavigate, useParams } from "react-router-dom";
import LoadingScreen from "shared/LoadingScreen";
import DashboardLayout from "layout/dashboardlayout";
import SuccessModal from "shared/SuccessModal";
import AcceptModal from "shared/AcceptModal";
import { useDispatch, useSelector } from "react-redux";
import {
  acceptOffer,
  campaignInvoiceById,
} from "appstate/supplierEarlyPayment/supplierEarlyPaymentSlice";

const SupplierEarlyPaymentRejectedInvoice = () => {
  // const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  // const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const navigate = useNavigate();

  const { offerId, invoiceId } = useParams();
  const dispatch = useDispatch();
  const { campaignInvoiceByIdData, isLoading } = useSelector(
    (state) => state?.supplierEarlyPaymentOffer
  );

  useEffect(() => {
    dispatch(campaignInvoiceById({ offerId, invoiceId }));
  }, [dispatch, offerId, invoiceId]);

  const handleAccept = () => {
    // setIsAcceptModalOpen(false);
    dispatch(acceptOffer({ offerId, invIds: invoiceId })).then((response) => {
      if (response?.payload?.status === 200 && response?.payload?.success) {
        setIsSuccessModalOpen(true);
        setSuccessMessage(response?.payload?.message);
      }
    });
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  const formatAmount = (amount) => {
    if (typeof amount !== "number") return "0.00";
    return amount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  return (
    <DashboardLayout
      section="Payable Finance Program"
      subHeader="Here is an overview of your pfp"
    >
       <div>
        <Link
          onClick={() => navigate(-1)}
          className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
        >
          <PAIconInventoryArrowLeft />
          <span className="flex items-center">Go Back</span>
        </Link>
      </div>
      <div className="flex flex-col gap-8 pb-24 bg-white rounded-[10px] min-h-[80vh]">
        <header className="px-6 border-b pt-[17px] rounded-t-[10px] border-[#B1A6B2] pb-[14px] bg-alabasterHeader">
          <div className="flex justify-between items-center">
            <h1 className="text-black font-medium">Invoices</h1>
            <div className="flex items-center gap-5">
              <div
                // onClick={() => setIsAcceptModalOpen(true)}
                className="rounded-[5px] cursor-pointer bg-[#07593D] shadow-md text-[#F9F9F9] text-xs font-medium py-[10px] px-[20px]"
              >
                Accept Payment
              </div>
              <div
                // onClick={() => setIsRejectModalOpen(true)}
                className="rounded-[5px] cursor-pointer text-[#BB0C0C] border border-[#BB0C0C]  shadow-md text-xs font-medium py-[10px] px-[20px]"
              >
                Reject Payment
              </div>
            </div>
          </div>
        </header>

        <div className="px-6">
          <div className="flex flex-col gap-3">
            <header className="flex justify-between items-center">
              <h1 className="text-[18px] font-bold">
                Invoice | INV-
                {campaignInvoiceByIdData?.data?.invoiceNumber || "0000"}
              </h1>
            </header>

            <main className="w-full flex justify-between">
            <div className="flex flex-col gap-3 w-1/2">
                <div className="flex gap-x-[5px]">
                  <span>Reference No:</span>
                </div>
                <div className="flex flex-col gap-1">
                  <div className="flex gap-x-[50px]">
                    <span className="text-xs w-[97px]">Created Date:</span>
                    <span className="text-[#979797] text-xs">23-Mar-2023</span>
                  </div>
                  <div className="flex gap-x-[50px]">
                    <span className="text-xs w-[97px]">Submitted Date:</span>
                    <span className="text-[#979797] text-xs">23-Mar-2023</span>
                  </div>
                  <div className="flex gap-x-[50px]">
                    <span className="text-xs w-[97px]">Approved Date:</span>
                    <span className="text-[#979797] text-xs">23-Mar-2023</span>
                  </div>
                  <div className="flex gap-x-[50px]">
                    <span className="text-xs">Due Date:</span>
                    <span style={{marginLeft: 50}} className="text-[#979797] text-xs">23-Mar-2023</span>
                  </div>
                  <div className="flex gap-x-[50px]">
                    <span className="text-xs w-[97px]">Issuance Date:</span>
                    <span className="text-[#979797] text-xs">23-Mar-2023</span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-between">
                <div className="text-sm flex flex-col justify-end items-end ">
                  <div className="flex flex-col gap-1">
                    <p className="font-bold">Payable Finance Offer</p>
                    <div className="flex justify-between">
                      <span className="text-xs ">Name of campaign:</span>
                      <span
                        style={{ marginLeft: 4 }}
                        className="text[#979797] text-xs"
                      >
                        {" "}
                        Payable Flex
                      </span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-xs ">End Date:</span>
                      <span className="text[#979797] text-xs">
                        {" "}
                        03-Jun-2025
                      </span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-xs ">Discount Rate:</span>
                      <span className="text[#979797] text-xs"> 2%</span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-xs ">Discount Amount:</span>
                      <span className="text[#979797] text-xs">
                        {" "}
                        NGN 180,000
                      </span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-xs ">Receivable Amount:</span>
                      <span
                        style={{ marginLeft: 4 }}
                        className="text[#979797] text-xs"
                      >
                        {" "}
                        NGN 320,000
                      </span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-xs ">Payment Duration:</span>
                      <span className="text[#979797] text-xs flex justify-end">
                        {" "}
                        60 Days
                      </span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-xs ">Payment Date:</span>
                      <span className="text[#979797] text-xs flex justify-end">
                        {" "}
                        01-Mar-2023
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
          <br />
          <div className="flex flex-col gap-4">
            <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
              <h1 className="text-[18px]">Supplier/buyer details</h1>
            </header>
            <div className="flex justify-between mb-5">
              <div className="flex gap-2">
                <div className="flex flex-col">
                  <span className="font-bold text-sm">Supplier</span>
                  <span className="text-[#979797] text-xs">
                    {campaignInvoiceByIdData?.data?.supplierName ||
                      "supplier name here"}
                  </span>
                </div>
              </div>
              <div className="flex gap-2">
                <div className="flex flex-col">
                  <span className="font-bold text-sm">Buyer</span>
                  <span className="text-[#979797] text-xs">
                    {campaignInvoiceByIdData?.data?.buyerName ||
                      "buyer name here"}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
              <h1 className="text-sm font-bold">Line Items</h1>
            </header>
            <div className="w-full overflow-x-auto">
              <table className="w-full overflow-x-auto">
                <thead className="bg-subtleGray w-5">
                  <tr className="bg-unusualGray p-2.5">
                    <td className="text-[#11192A] p-2.5 text-xs font-bold">
                      Item Number
                    </td>
                    <td className="p-2.5 text-xs font-bold">Product/Service Description</td>
                    <td className="p-2.5 text-xs font-bold">Quantity</td>
                    <td className="p-2.5 text-xs font-bold">Unit Price</td>
                    <td className="p-2.5 text-xs font-bold">
                      Total Amount
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {campaignInvoiceByIdData?.data?.lineItems?.map(
                    (item, index) => (
                      <tr key={index} className="py-[3px] px-1.5">
                        <td className="text-xs text-[#11192A] p-2.5">
                          {item.itemName}
                        </td>
                        <td className="text-xs text-[#11192A] p-2.5">
                          {item.description}
                        </td>
                        <td className="text-xs text-[#11192A] p-2.5">
                          {item.quantity}
                        </td>
                        <td className="text-xs text-[#11192A] p-2.5">
                          {formatAmount(item.unitPrice)}
                        </td>
                        <td className="text-xs text-[#11192A] p-2.5">
                          {formatAmount(item.amount)}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <AcceptModal
          // isOpen={isRejectModalOpen}
          // onClose={() => setIsAcceptModalOpen(false)}
          message={
            <>
              Are you sure you would want to{" "}
              <span className="text-[#BB0C0C] font-bold">discard</span> this
              offer
              <br />
              from Payable flex Payable Finance Program?
            </>
          }
          onConfirm={handleAccept}
          loading={false}
        />
        <SuccessModal
          isOpen={isSuccessModalOpen}
          onClose={() => setIsSuccessModalOpen(false)}
          message={successMessage}
        />
      </div>
    </DashboardLayout>
  );
};

export default SupplierEarlyPaymentRejectedInvoice;
