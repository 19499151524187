import React, { useState } from 'react'
import RouteHeader from 'features/ToolsAgreement/components/RouteHeader'
import { useSelector } from 'react-redux';
import TransitionScale from 'shared/animation/TransitionScale';
import { PAIconActionDownloadBtn, PAIconActionDownloadDoc, } from 'assets/images/svgs';
// import docagreedoc from 'assets/images/docagreedoc.png'
import AcceptSignModal from '../../AgreementDetails/Modal/AcceptSignModal';
import AgreementSuccessModal from '../../AgreementDetails/Modal/AgreementSucessModal';
import AgreementDoc from "../../../../../../assets/images/AgreementDoc.pdf"

// PAIconAgreeFolder


const PendingDeletionDetails = ({ pendingDeletion, goBack }) => {

    const { allAgreementData, agreementId } = useSelector(
      (state) => state?.toolsAndAgreements
    );
  
    const details = allAgreementData?.data?.data?.find(
      (curr) => curr?.id === agreementId
    );
    const[isAcceptSignModalOpen, setIsAcceptSignModalOpen] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const handleRetract=()=>{
      setIsAcceptSignModalOpen(false)
      setIsSuccess(true)
    }
  
  return (

    <>

    <AcceptSignModal
            isOpen={isAcceptSignModalOpen}
            onClose={() => setIsAcceptSignModalOpen(false)}
            mess1={"Are you sure you would like to retract this deletion request"}
           
            handleYes={handleRetract}
          />

<AgreementSuccessModal isOpen={isSuccess} onClose={()=> setIsSuccess(false)} message={"Deletion Retracted"}/>
    <div>
     
      <div className="px-7 mb-10  w-[100%]">
                  <div className="flex items-center justify-between">
                    <RouteHeader
                      Route1="Pending Deletion"
                      Heading={details?.name}
                      // Route2={"PFP "}
                      handleBack={goBack}
                    />
                    {/* <button
                      onClick={handleUpdateButtonClick}
                      className="bg-blue-400 rounded-md text-white p-1 px-4 mt-3 hover:bg-blue-300"
                    >
                      Update
                    </button> */}
                  </div>
                </div>

                 <div className="grid lg:grid-cols-[40%,57.5%] gap-5 w-full">
                        <TransitionScale className=" pb-5 bg-white  rounded-[10px]  pt-2">


                        <section className="min-h-[570px] rounded-r-[10px]   w-full ">
              <div className="py-6 px-4 bg-[#959595]/5 rounded-[5px] h-full">
                <header className="flex  items-center justify-between shadow-[0px_2px_4px_0px_rgba(98,98,98,0.07)] border-b border-stone-300 py-3">
                <div class="justify-start text-neutral-800 text-base font-semibold ">Agreement Details</div>
                  {/* <PAIconAgreeFolder  /> */}
                </header>

                <main className="">
                  <div className="flex flex-col justify-between text-sm font-medium py-2.5 mt-2">
                  <div class="self-stretch justify-start text-black text-sm font-semibold ">PFP Campaign Macro</div>
                    <span className="text-[#979797]"> Template Name</span>
                  </div>

                  <div className="flex flex-col justify-between text-sm font-medium py-2.5 mt-2">
                  <div class="self-stretch justify-start text-black text-sm font-semibold ">PFP Campaign</div>
                    <span className="text-[#979797]"> Agreement type</span>
                  </div>

                  <div className="flex flex-col justify-between text-sm font-medium py-2.5 mt-2">
                  <div class="self-stretch justify-start text-black text-sm font-semibold ">ABC Trust</div>
                    <span className="text-[#979797]"> Agreement initiator</span>
                  </div>

                  
                  <div className="flex flex-col justify-between text-sm font-medium py-2.5 mt-2">
                  <div class="self-stretch justify-start text-black text-sm font-semibold ">Pepsi Ltd.</div>
                    <span className="text-[#979797]"> Agreement Recipient</span>
                  </div>

                  <div className="flex flex-col justify-between text-sm font-medium py-2.5 mt-2">
                  <div class="self-stretch justify-start text-black text-sm font-semibold ">Signed</div>
                    <span className="text-[#979797]">Agreement Status</span>
                  </div>
                  <div className="flex flex-col justify-between text-sm font-medium py-2.5 mt-2">
                  <div class="self-stretch justify-start text-black text-sm font-semibold ">2024-02-22</div>
                    <span className="text-[#979797]">Date uploaded</span>
                  </div>

                  <div className="flex flex-col justify-between text-sm font-medium py-2.5 mt-2">
                  <div class="self-stretch justify-start text-black text-sm font-semibold ">Deletion initiated by you</div>
                    <span className="text-[#979797]">Delete initiator</span>
                  </div>
                  

                  <div class="w-full p-2.5 bg-amber-600/60 inline-flex justify-center items-center gap-2.5">
    <div class="justify-start text-white text-xs font-normal ">Pending delete approval from counterparty...</div>
</div>
                </main>

                <div className='py-3 border-t-[0.5px] border-[#CFCFCF] mt-5' >
                <div class="justify-start text-neutral-800 text-base font-semibold ">Actions</div>

                <div class="w-full px-5 py-2.5 bg-[#07593D] rounded-[5px] inline-flex justify-center items-center gap-2.5 mt-3" onClick={()=>setIsAcceptSignModalOpen(true)}>
    <div class="justify-start text-white text-xs font-normal ">Retract deletion</div>
</div>
                </div>
              </div>
             
            </section>
                        </TransitionScale>
                        <TransitionScale className=" pb-5 bg-white  rounded-[10px]  pt-2">



                        <section className="min-h-[570px]   py-8  w-full  bg-[#959595]/5 rounded-[5px] ">

                        <header className="flex w-full mb-2.5 justify-between font-bold px-3">
                          <div className='flex items-center gap-3'> <span>< PAIconActionDownloadDoc/></span><span>FUNDING AGREEMENT</span>
                          </div>
                          <div><PAIconActionDownloadBtn/></div>
                
               
                {/* <span
                  onClick={() => handleDownload()}
                  className="cursor-pointer"
                >
                  {!downloadAgreementClick && <PAIconDownloadFunding />}
                </span> */}
              </header>

              <main className="flex flex-col gap-3">
                <section className="flex flex-col gap-1 ">


                {/* <div class="justify-start text-neutral-800 text-xs font-medium ">PFP CAMPAIGN AGREEMENT</div> */}
                {/* <iframe src={}/> */}

                {/* <div style={{ width: "100%", height: "90vh", border: "2px solid #ddd", borderRadius: "10px", overflow: "hidden" }}> */}
                <iframe
                disableprint
        src={`${AgreementDoc}#toolbar=0&navpanes=0&scrollbar=0`} // Ensure the file is in the public folder or accessible via URL
        // style={{ width: "100%", height: "100%", border: "none" }}
        className='bg-none border-none h-screen w-full '
        title="PDF Viewer"
        
      />
      {/* </div> */}
                 
                </section>

                {/* <section className="flex flex-col gap-[10px]"></section> */}

              </main>
              </section>
                        </TransitionScale>

                        </div>


      
    </div>
    </>
  )
}

export default PendingDeletionDetails
