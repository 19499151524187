import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  createWorkflowApi,
  getAllWorkflowApi,
  getUsersByRoleApi,
  getWorkflowByIDApi,
  getWorkflowByModuleApi,
  modifyWorkflowStatusApi,
  updateWorkflowStatusApi,
  getAllWorkflowDashboardApi,
  getRecentWorkflowApprovalsApi,
  getAllWorkflowPendingApprovalsApi,
  getAllWorkflowPendingRequestsApi,
  getAllWorkflowPendingRequestHistorysApi,
  getAllWorkflowPendingRequestByIdApi,
  approveWorkFlowById,
  getAllWorkflowDeclinedRequestsApi,
} from "appstate/api/workflow";
//getAllWorkflowPendingRequestsApi

const initialState = {
  isLoading: false,
  getAllWorkflowData: [],
  getWorkflowByIdData: [],
  getWorkflowByModuleData: [],
  getUsersByRoleData: [],
  allWorkflowDashboard: [],
  recentWorkflowApproval: [],
  pendingApprovals: [],
  pendingRequest: [],
  pendingRequestHistory: [],
  singleDetailPendingRequest: [],
  declinedRequests: [],
};

export const createWorkflow = createAsyncThunk(
  "workflow/createWorkflow",
  createWorkflowApi
);

export const getAllWorkflow = createAsyncThunk(
  "workflow/getAllWorkflow",
  getAllWorkflowApi
);

export const getWorkflowById = createAsyncThunk(
  "workflow/getWorkflowById",
  getWorkflowByIDApi
);

export const modifyWorkflowStatus = createAsyncThunk(
  "workflow/modifyWorkflowStatus",
  modifyWorkflowStatusApi
);
export const getworkflowByModule = createAsyncThunk(
  "workflow/getworkflowByModule",
  getWorkflowByModuleApi
);

export const getUsersByRole = createAsyncThunk(
  "workflow/getUsersByRole",
  getUsersByRoleApi
);
export const updateWorkflowStatus = createAsyncThunk(
  "workflow/updateWorkflowStatus",
  updateWorkflowStatusApi
);
export const fetchWorkflowDashBoard = createAsyncThunk(
  "workflow/fetchWorkflowDashBoard",
  getAllWorkflowDashboardApi
);

export const fetchRecentWorkflowApprovals = createAsyncThunk(
  "workflow/fetchRecentWorkflowApprovals",
  getRecentWorkflowApprovalsApi
);
export const fetchWorkflowPendingApprovals = createAsyncThunk(
  "workflow/getAllWorkflowPendingApprovalsApi",
  getAllWorkflowPendingApprovalsApi
);
export const fetchWorkflowPendingRequests = createAsyncThunk(
  "workflow/getAllWorkflowPendingRequestsApi",
  getAllWorkflowPendingRequestsApi
);
export const fetchWorkflowPendingRequestsHistory = createAsyncThunk(
  "workflow/getAllWorkflowPendingRequestHistorysApi",
  getAllWorkflowPendingRequestHistorysApi
);
export const fetchSinglePendingRequestById = createAsyncThunk(
  "workflow/getAllWorkflowPendingRequestByIdApi",
  getAllWorkflowPendingRequestByIdApi
);
export const approveOrUnapproveWorkflowById = createAsyncThunk(
  "workflow/approveWorkFlowById",
  approveWorkFlowById
);
export const fetchAllWorkflowDeclinedRequests = createAsyncThunk(
  "workflow/getAllWorkflowDeclinedRequestsApi",
  getAllWorkflowDeclinedRequestsApi
);

export const workflowSlice = createSlice({
  name: "workflow",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createWorkflow.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createWorkflow.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createWorkflow.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getAllWorkflow.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllWorkflow.fulfilled, (state, action) => {
      state.isLoading = false;
      state.getAllWorkflowData = action.payload;
    });
    builder.addCase(getAllWorkflow.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getWorkflowById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getWorkflowById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.getWorkflowByIdData = action.payload;
    });
    builder.addCase(getWorkflowById.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(modifyWorkflowStatus.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(modifyWorkflowStatus.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(modifyWorkflowStatus.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getworkflowByModule.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getworkflowByModule.fulfilled, (state, action) => {
      state.isLoading = false;
      state.getWorkflowByModuleData = action.payload;
    });
    builder.addCase(getworkflowByModule.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getUsersByRole.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUsersByRole.fulfilled, (state, action) => {
      state.isLoading = false;
      state.getUsersByRoleData = action.payload;
    });
    builder.addCase(getUsersByRole.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(updateWorkflowStatus.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateWorkflowStatus.fulfilled, (state) => {
      state.isLoading = false;
    });

    builder.addCase(updateWorkflowStatus.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchWorkflowDashBoard.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchWorkflowDashBoard.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allWorkflowDashboard = action.payload;
    });
    builder.addCase(fetchWorkflowDashBoard.rejected, (state) => {
      state.isLoading = false;
    });

    // fetchWorkflowPendingApprovals
    builder.addCase(fetchRecentWorkflowApprovals.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchRecentWorkflowApprovals.fulfilled, (state, action) => {
      state.isLoading = false;
      state.recentWorkflowApproval = action.payload;
    });

    builder.addCase(fetchRecentWorkflowApprovals.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(fetchWorkflowPendingApprovals.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(
      fetchWorkflowPendingApprovals.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.pendingApprovals = action.payload;
      }
    );

    builder.addCase(fetchWorkflowPendingApprovals.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchAllWorkflowDeclinedRequests.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(
      fetchAllWorkflowDeclinedRequests.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.declinedRequests = action.payload;
      }
    );

    builder.addCase(fetchAllWorkflowDeclinedRequests.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchWorkflowPendingRequests.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchWorkflowPendingRequests.fulfilled, (state, action) => {
      state.isLoading = false;
      state.pendingRequest = action.payload;
    });
    builder.addCase(fetchWorkflowPendingRequests.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchWorkflowPendingRequestsHistory.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      fetchWorkflowPendingRequestsHistory.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.pendingRequestHistory = action.payload;
      }
    );
    builder.addCase(fetchWorkflowPendingRequestsHistory.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchSinglePendingRequestById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      fetchSinglePendingRequestById.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.singleDetailPendingRequest = action.payload;
      }
    );
    builder.addCase(fetchSinglePendingRequestById.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(approveOrUnapproveWorkflowById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(approveOrUnapproveWorkflowById.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(approveOrUnapproveWorkflowById.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export default workflowSlice.reducer;
