import React, { useState, useEffect } from "react";
import CheckMark from "../../../../assets/images/svgs/checkmarkorange.svg";

const ConfirmingPaymentReimbursement = ({
  isOpen,
  onClose,
}) => {
  const [isProcessing, setIsProcessing] = useState(true);

  useEffect(() => {
    if (isOpen) {
      setIsProcessing(true);
      const timer = setTimeout(() => {
        setIsProcessing(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg rounded-md z-[1000]">
      <div className="bg-white rounded-md max-w-xs md:max-w-lg w-full pb-5">
        <div className="flex justify-between items-center mb-4">
          <p className="text-black font-sans text-base p-3 px-5 rounded-sm italic">
            Transfer of funds
          </p>
          <button
            onClick={onClose}
            className="text-black font-sans text-xl cursor-pointer flex justify-center items-center"
          >
            &times; <span className="text-sm mx-2 mt-1">Close</span>
          </button>
        </div>

        <div className="flex justify-center items-center h-48 flex-col space-y-4">
          {isProcessing ? (
            <>
              <span>Confirming Payment</span>
              <div className="animate-spin rounded-full border-t-4 border-b-4 border-[#F08000] w-12 h-12"></div>
            </>
          ) : (
            <div className="text-center">
              <div className="flex justify-center items-center mb-4">
                <img src={CheckMark} alt="Checkmark" className="w-16 h-16" />
              </div>
              <p className="text-xl text-black font-bold">Payment Confirmed</p>
              <div className="mt-6">
                <span className="text-center">
                  Please wait as the received funds are about to be liened for the selected requests
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConfirmingPaymentReimbursement;
