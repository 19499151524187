import React, { useState } from "react";
import Headers from "../headers";
import SingleBulkOption from "./option";

const EarlyPayments = ({ route, activeTab, setActiveTab }) => {
  const [isWallet, setIsWallet] = useState(false);
  const [isClosedMarket, setIsClosedMarket] = useState(false);
  const [isDirectFunding, setIsDirectFunding] = useState(false);
  const [selectedType, setSelectedType] = useState(
    activeTab === "earlyPayments"
      ? "All Funding Options"
      : "Request from funder"
  );
  return (
    <div>
      <Headers
        activeTab={activeTab}
        setIsWallet={setIsWallet}
        setIsClosedMarket={setIsClosedMarket}
        setIsDirectFunding={setIsDirectFunding}
        setSelectedType={setSelectedType}
        selectedType={selectedType}
      />
      <SingleBulkOption
        activeTabType={selectedType}
        isWallet={isWallet}
        isClosedMarket={isClosedMarket}
        route={route}
        isDirectFunding={isDirectFunding}
        setActiveTab={setActiveTab}
      />
    </div>
  );
};

export default EarlyPayments;
