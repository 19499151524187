import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import HeaderTabs from "shared/HeaderTabs";
// import Dashboard from "./dashboard";
import { setActiveTab } from "appstate/collections/collectionSlice";
import DirectFunding from "./supplier/directFunding";
import OverviewReinbursement from "./dashboard";
import ReimbursementWithRecourse from "./dashboard/reimbursementwithrecourse";
import ReimbursementWithoutRecourse from "./dashboard/reimbursementwithoutrecourse";
import ReimbursementTransactionHistory from "./dashboard/ReimbursementTranscationHistory";

const BuyerReimbursementTab = () => {
  const dispatch = useDispatch();
  const [selectedStatus, setSelectedStatus] = useState("Liened");

  useEffect(() => {
    dispatch(setActiveTab("overview"));
  }, [dispatch]);

  return (
    <HeaderTabs
      tabs={[
        { key: "overview", label: "Overview", content: <OverviewReinbursement selectedStatus={selectedStatus} /> },
        { key: "withRecourse", label: "With Recourse", content: <ReimbursementWithRecourse selectedStatus={selectedStatus} /> },
        { key: "withoutRecourse", label: "Without Recourse", content: <ReimbursementWithoutRecourse selectedStatus={selectedStatus}  /> },
        { key: "loanDefault", label: "Loan Default", content: <DirectFunding /> },
        { key: "transactionHistory", label: "Transaction History", content: <ReimbursementTransactionHistory /> },
      ]}
      hasPaddingY={true}
      buyerReimbursement={true}
      selectedStatus={selectedStatus}
      setSelectedStatus={setSelectedStatus}
    />
  );
};

export default BuyerReimbursementTab;
