import { setwithdrawalFormData } from "appstate/wallet/walletSlice";
import { PAIconWalletCardBlack } from "assets/images/svgs";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withdrawAccountValidationSchema } from "validation/wallet/withdraw";
import { withdrawFromLinkAccountValidationSchema } from "validation/wallet/withdraw/withdrawFromLinkAccount";

const WithdrawFund = ({ isOpen, onClose, onSubmit }) => {
  const dispatch = useDispatch();
  const selectedAccounts = useSelector(
    (state) => state?.wallet?.selectedAccounts
  );
  const [useExternalAccount, setUseExternalAccount] = useState(false);
  const validationSchema = useExternalAccount
  ? withdrawFromLinkAccountValidationSchema
  : withdrawAccountValidationSchema;

const formik = useFormik({
  initialValues: {
    accountNumber: "",
    amount: "",
    narration: "",
    bankName: "",
    accountName: "",
  },
  validationSchema: validationSchema,
  onSubmit: (values, { setSubmitting }) => {
    dispatch(setwithdrawalFormData(values));
    setSubmitting(false);
    onSubmit(values);
  },
});

const handleToggleAccount = () => {
  setUseExternalAccount((prev) => !prev);
  formik.resetForm(); // Reset the form when toggling
};

  if (!isOpen) return null;

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg rounded-md z-[1000]">
      <div className="bg-white p5 px5 rounded-md max-w-xs md:max-w-lg w-full pb-5">
        <div className="flex justify-between items-center mb-4 bg-[#FAFAFA]">
          <p className="text-black font-sans text-base font-semibold p-3 px-5 rounded-sm italic">
            Withdrawal
          </p>
          <button
            onClick={onClose}
            className="text-black font-sans text-xl cursor-pointer flex justify-center items-center"
          >
            &times; <span className="text-sm mx-2 mt-1">Close</span>
          </button>
        </div>

        {/* FORM */}
        <div>
          <form className="w-full px-7" onSubmit={formik.handleSubmit}>
            <div className="text-[#7A7A7A] font-sans text-sm font-normal mb-6 flex items-center cursor-pointer" onClick={() => onClose()}>
              <p>From- </p>
              <div className="border border-[#F08000] ml-3 p-1 textwhite w-[90%] flex items-center rounded text-xs">
                <div className="flex items-center">
                  <div className="flex items-center ml-2 flex-basis-[20%]">
                    <PAIconWalletCardBlack className="w-full" />
                  </div>
                  <div className="flex text-base flex-col justify-start ml-5 flex-basis-[80%]">
                    <div className="flex items-center justify-start">
                      <p className="mr-1">{selectedAccounts?.name || ""}</p> -{" "}
                      <p className="mx-1">
                        {selectedAccounts?.accountNumber || ""}
                      </p>{" "}
                      -{" "}
                      <p className="ml-1">{selectedAccounts?.bankName || ""}</p>
                    </div>
                    <div className="flex justify-center mt-2">
                      <p className="text-sm font-semibold text-black">
                        Balance: {selectedAccounts?.amount || ""}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex text-[#5F4D61] items-center justify-center mb-5 font-semibold italic">
              Enter account information to transfer to
            </div>
            <div className="flex justify-between items-center mb-4">
              <p className="text-[#222] font-sans text-sm font-bold">
                Account Information
              </p>
              <div
                className="cursor-pointer text-sm text-[#F08000] underline"
                onClick={handleToggleAccount}
              >
                {useExternalAccount
                  ? "Enter an external account"
                  : "Use your linked External Account"}
              </div>
            </div>

            {useExternalAccount ? (
              <div>
                {/* External Account Form */}
                <div className="mb-4">
                  <label className="text-[#222] text-sm font-normal mb-2">
                    Bank Name
                  </label>
                  <input
                    type="text"
                    id="bankName"
                    value="Dummy Bank" // Dummy text
                    readOnly
                    className="border text-sm w-full py-2 border-t-0 border-x-0 focus:border-[#222] focus:outline-none bg-gray500 bg-opacity-50"
                  />
                </div>
                <div className="mb-4">
                  <label className="text-[#222] font-sans text-sm font-normal mb-2">
                    Account Number
                  </label>
                  <input
                    type="text"
                    id="accountNumber"
                    value="1234567890" // Dummy text
                    readOnly
                    className="border text-sm w-full py-2 border-t-0 border-x-0 focus:border-[#222] focus:outline-none bg-gray500 bg-opacity-50"
                  />
                </div>
                <div className="mb-4">
                  <label className="text-[#222] text-sm font-normal mb-2">
                    Account Name
                  </label>
                  <input
                    type="text"
                    id="accountName"
                    value="John Doe" // Dummy text
                    readOnly
                    className="border text-sm w-full py-2 border-t-0 border-x-0 focus:border-[#222] focus:outline-none bg-gray500 bg-opacity-50"
                  />
                </div>
              </div>
            ) : (
              <div>
                {/* Linked Account Form */}
                <div className="mb-4">
                  <label className="text-[#222] text-sm font-normal mb-2">
                    Bank Name
                  </label>
                  <input
                    type="text"
                    id="bankName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.bankName}
                    className="border text-sm w-full py-2 border-t-0 border-x-0 focus:border-[#222] focus:outline-none"
                  />
                  {formik.touched.bankName && formik.errors.bankName ? (
                    <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                      {formik.errors.bankName}
                    </p>
                  ) : null}
                </div>
                <div className="mb-4">
                  <label className="text-[#222] font-sans text-sm font-normal mb-2">
                    Account Number
                  </label>
                  <input
                    type="number"
                    name="accountNumber"
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    value={formik?.values?.accountNumber}
                    className={`border w-full py-2 border-t-0 border-x-0 focus:border-[#222] focus:outline-none ${
                      formik.touched.accountNumber &&
                      formik.errors.accountNumber
                        ? "border-red"
                        : ""
                    }`}
                  />
                  {formik.values.bankName && formik.values.accountNumber ? (
                    <div className="mb-4">
                      <label className="text-[#222] text-sm font-normal mb-2">
                        Account Name
                      </label>
                      <input
                        type="text"
                        id="accountName"
                        value={formik.values.accountName}
                        className="border text-sm w-full bg-gray500 py-2 border-t-0 border-x-0 focus:border-[#222] focus:outline-none"
                        readOnly
                      />
                    </div>
                  ) : null}
                </div>
                {/* Additional fields for Amount and Description */}
              </div>
            )}

            <div className="mb-4">
              <label className="text-[#222] font-sans text-sm font-normal mb-2">
                Amount
              </label>
              <input
                type="number"
                name="amount"
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                value={formik?.values?.amount}
                className={`border w-full py-2 border-t-0 border-x-0 focus:border-[#222] focus:outline-none ${
                  formik.touched.amount && formik.errors.amount
                    ? "border-red"
                    : ""
                }`}
              />
              {formik.touched.amount && formik.errors.amount && (
                <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik?.errors?.amount}
                </p>
              )}
            </div>
            <div className="mb-4">
              <label className="text-[#222] font-sans text-sm font-normal mb-2">
                Description
              </label>
              <input
                type="text"
                name="narration"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik?.values?.narration}
                className={`border w-full py-2 border-t-0 border-x-0 focus:border-[#222] focus:outline-none ${
                  formik.touched.narration && formik.errors.narration
                    ? "border-red"
                    : ""
                }`}
              />
              {formik.touched.narration && formik.errors.narration && (
                <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik?.errors?.narration}
                </p>
              )}
            </div>

            {/* Buttons */}
            <div className="flex justify-end mt-10">
              <div className="w-[105px] h-[35px] px-6 py-2 rounded-[5px] justify-center items-center gap-2.5 inline-flex mx-2">
                <button
                  type="button"
                  onClick={onClose}
                  className="text-center text-gray600 text-base font-normal tracking-wide"
                >
                  Cancel
                </button>
              </div>
              <div className="w-[105px] h-[35px] px-6 py-2 bg-emerald-800 rounded-[5px] justify-center items-center gap-2.5 inline-flex bg-[#07593D]">
                <button
                  className="text-center text-white text-base font-normal tracking-wide"
                  type="submit"
                >
                  Next
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default WithdrawFund;
