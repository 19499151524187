import { PAIconInventoryArrowLeft } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { useNavigate } from "react-router-dom";
import Card from "shared/Card";
import TableHeader from "shared/tableHeader/TableHeader";
import ThreeDots from "../../../assets/images/svgs/option.svg";
import Table from "layout/table/Table";
import { useCallback, useEffect, useState } from "react";
import Pagination from "shared/Pagination";
// import { getAllSupplierAssociatedFunder } from "appstate/supplier/supplierSlice";
import { useSelector } from "react-redux";
import _ from "lodash";
import { supplierAssociatedFunderDirectFunding } from "appstate/api/supplier/relationshipManagement";
import toast from "react-hot-toast";

const DirectFundingRequestFlow = () => {
   // eslint-disable-next-line
  const [total, setTotal] = useState("");
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [data, setData] = useState([]);
  const pageSize = 10;
  const {
    supplierAssociatedFundersData,
    isLoading,
  } = useSelector((state) => state?.supplier);
  // const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();
  // const dispatch = useDispatch();

  function handleNavigate() {
    navigate(-1);
  }

  const fetchFunders = async (search = "") => {
    try {
      const response = await supplierAssociatedFunderDirectFunding({ page, pageSize, search });
      setData(response?.data || []);
      // setTotalPages(response?.totalElements || 0);
    } catch (error) {
      toast.error(error?.response?.data?.message || "Failed to fetch funders");
    }
  };

// eslint-disable-next-line
  const debouncedSearch = useCallback(
    // eslint-disable-next-line
    _.debounce((search) => {
      fetchFunders(search);
    }, 300),
    // eslint-disable-next-line
    [page]
  );

  useEffect(() => {
    if (searchTerm) {
      debouncedSearch(searchTerm);
    } else {
      fetchFunders();
    }
    return () => debouncedSearch.cancel();
    // eslint-disable-next-line
  }, [searchTerm, page]);


  // eslint-disable-next-line
  // const debouncedSearch = useCallback(
  //   _.debounce(() => {
  //     const search = searchTerm;
  //     dispatch(getAllSupplierAssociatedFunder({ page, pageSize, search }))
  //       .then((data) => {
  //         setData(data?.payload?.data || []);
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching funders:", error);
  //       });
  //   }, 200),
  //    // eslint-disable-next-line
  //   [searchTerm, page, pageSize, dispatch]
  // );

  // useEffect(() => {
  //   if (searchTerm) {
  //     debouncedSearch();
  //   }
  //   debouncedSearch();
  //   return () => {
  //     debouncedSearch.cancel();
  //   };
  //    // eslint-disable-next-line
  // }, [searchTerm, debouncedSearch]);

  // useEffect(() => {
  //   const search = "";
  //   dispatch(getAllSupplierAssociatedFunder({ page, pageSize, search }));
  // }, [dispatch, page, pageSize]);

  useEffect(() => {
    if (companyName.length <= 0) {
      setData(supplierAssociatedFundersData?.data);
    }
  }, [supplierAssociatedFundersData, companyName]);

  const onRowClicked = (row) => {
    let path = "/direct-funding-invoice-details";
    navigate(path, { state: { userId: row.userId, companyName: row.companyName } });
  };

  const column = [
    {
      name: "Name",
      selector: (row) => row?.fullName,
    },
    {
      name: "Company",
      selector: (row) => row?.companyName,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
    },
    {
      name: "Phone",
      selector: (row) => row?.phoneNumber,
    },
    {
      name: "Discount Rate",
      selector: (row) => row?.discountRate,
    },
    {
      cell: (row) => (
        <div className="relative">
          <img
            src={ThreeDots}
            alt="Options"
            // onClick={() => handleThreeDotsClick(row, row?.email)}
            className="cursor-pointer"
          />
        </div>
      ),
    },
  ];
  const totalPages = total;

  return (
    <DashboardLayout
      section="Invoice"
      subHeader="Here is an overview of your invoices"
    >
      <div
        onClick={handleNavigate}
        className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
      >
        <PAIconInventoryArrowLeft />
        <p className="mt-[2px] text-sm font-normal text-lightGray">Go Back</p>
      </div>
      <Card className="p-[24px] rounded-[10px]">
        <div>
          <TableHeader
            total={`All funders`}
            df="true"
            details="Access contact details of the associated funders"
            searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          setProductName={setCompanyName}
          />
        </div>
        <div className="p-[2px] bg-white rounded-b-[10px]">
          <Table
            columns={column}
            pointer
            data={data}
            onRowClicked={onRowClicked}
          />
        </div>
        <div className="flex justify-center gap-x-[8px]  mt-12">
          {!isLoading && totalPages > 10 && (
            <Pagination
              page={page}
              startPage={startPage}
              setEndPage={setEndPage}
              endPage={endPage}
              setStartPage={setStartPage}
              setPage={setPage}
              totalPages={totalPages}
            />
          )}
        </div>
      </Card>
    </DashboardLayout>
  );
};
export default DirectFundingRequestFlow;
