import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import {
  PAIconSmallBrewIcon,
  PAIconSmallCoke,
  PAIconSmallDangote,
  PAIconSmallNestle,
  PAIconSmallShell,
  PAIconInvShop,
  PAIconCocacola,
  PAIconSmallAccessIcon,
} from "assets/images/svgs";

function RequestsCard({
  id,
  invoiceNumber = "N/A",
  supplierCompanyName = "N/A",
  invoiceAmount = "N/A",
  status = "N/A",
  invoiceDueDate = "N/A",
}) {
  const navigate = useNavigate();

  return (
    <motion.div
      onClick={() => navigate(`requestsdetails/${id}`)}
      whileTap={{ scale: 0.98 }}
      style={{ boxShadow: "0px 2px 4px 0px rgba(232, 226, 216, 0.36)" }}
      className="flex cursor-pointer justify-between bg-white py-5 px-6 rounded-[10px]"
    >
      <span className="flex gap-6 items-center ">
        {supplierCompanyName === "Access Plc" ? (
          <PAIconSmallAccessIcon />
        ) : supplierCompanyName === "Cocacola" ? (
          <PAIconSmallCoke />
        ) : supplierCompanyName === "Dangote" ? (
          <PAIconSmallDangote />
        ) : supplierCompanyName === "Unity bank" ? (
          <PAIconSmallNestle />
        ) : supplierCompanyName === "XYZ Funds" ? (
          <PAIconSmallShell />
        ) : supplierCompanyName === "Trust Ltd." ? (
          <PAIconSmallBrewIcon />
        ) : (
          <PAIconCocacola />
        )}

        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{supplierCompanyName}</span>
          <span className=" text-[#959595] text-[10px]">{"Funder"}</span>
        </div>
      </span>

      <span className="flex gap-6  items-center">
        <PAIconInvShop />
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{invoiceNumber}</span>
          <span className=" text-[#959595] text-[10px]">Invoice number</span>
        </div>
      </span>

      <span className="flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{invoiceAmount}</span>
          <span className=" text-[#959595] text-[10px]">Invoice Amount</span>
        </div>
      </span>

      <span className="hidden extra-md:flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{status}</span>
          <span className=" text-[#959595] text-[10px]">Bid Discount</span>
        </div>
      </span>

      <span className="hidden extra-md:flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{invoiceDueDate}</span>
          <span className=" text-[#959595] text-[10px]">Due Date</span>
        </div>
      </span>
    </motion.div>
  );
}

export default RequestsCard;
