import {
  PAIconCocacola,
  PAIconInvShop,
  PAIconModelOne,
} from "assets/images/svgs";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import TooltipShared from "shared/tooltip/Tooltip";
import { formatCapitaliseString } from "utilities/helper/formatCapitaliseString";
import { formatCurrency } from "utilities/helper/formatters";
function AcceptBidCard({
  id,
  buyerCompanyName = "N/A",
  supplierCompanyName = "N/A",
  openMarketReference,
  status,
  lockRate,
  fundingAmount,
  fundRequestDate,
  paymentDate,
}) {
  const navigate = useNavigate();
  return (
    <motion.div
      onClick={() => navigate(`accepted-bid/${id}`)}
      whileTap={{ scale: 0.97 }}
      style={{ boxShadow: "0px 4px 12px 5px rgba(0, 0, 0, 0.04)" }}
      className="bg-white cursor-pointer flex flex-col gap-7 px-6 py-[25px] rounded-[10px]"
    >
      <div className="flex justify-between">
        <div className="flex gap-9">
          <div className="flex gap-6 w-[250px] items-center">
            <PAIconInvShop />
            <div className="flex flex-col gap-0.5">
              <span className="text-sm font-[500]">
                {" "}
                <TooltipShared text={openMarketReference} maxLength={20} />
              </span>
              <span className=" text-[10px] text-[#959595]">
                Reference number
              </span>
            </div>
          </div>

          <div className="hidden sm:flex items-center gap-16">
            <div className="flex items-center gap-2 ">
              <PAIconModelOne />
              <div className="flex flex-col gap-0.5">
                <span className="text-sm font-[500]">
                  <TooltipShared text={buyerCompanyName} maxLength={15} />
                </span>
                <span className=" text-[10px] text-[#959595]">Buyer</span>
              </div>
            </div>

            <div className="flex items-center gap-2 ">
              <PAIconCocacola />
              <div className="flex flex-col gap-0.5">
                <span className="text-sm font-[500]">
                  <TooltipShared text={supplierCompanyName} maxLength={15} />
                </span>
                <span className=" text-[10px] text-[#959595]">Supplier</span>
              </div>
            </div>

            <div className="flex items-center gap-2 ">
              <div className="flex flex-col gap-0.5">
                <span className="text-sm text-primaryColor font-[500]">
                  {formatCapitaliseString(status)}
                </span>
                <span className=" text-[10px] text-[#959595]">
                  Status of invoice
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* <motion.button
          // onClick={(event) => {
          //   event.stopPropagation();
          //   // cancelInvoice(true);
          // }}
          whileTap={{ scale: 0.9 }}
          className="flex border text-sm justify-center gap-2 text-white bg-primaryColor rounded-[5px] items-center px-2.5 py-[5px] "
        >
          <span className="sm:hidden extra-md:inline">View Bid</span>{" "}
          <span className=" flex extra-md:hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          </span>
        </motion.button> */}
      </div>

      <div className="flex justify-between">
        <div className="text-sm flex gap-3">
          <span className=" text-black">Lock Rate:</span>
          <span className=" text-[#959595]">{formatCurrency(lockRate)}%</span>
        </div>

        <div className="text-sm flex gap-3">
          <span className=" text-black">Request Amount:</span>
          <span className=" text-[#959595]">
            {formatCurrency(fundingAmount)}
          </span>
        </div>

        <div className="text-sm hidden semi-lg:flex gap-3">
          <span className=" text-black">Request Date:</span>
          <span className=" text-[#959595]">{fundRequestDate}</span>
        </div>

        <div className="text-sm hidden semi-lg:flex gap-3">
          <span className=" text-black">Payment Date:</span>
          <span className=" text-[#959595]">{paymentDate}</span>
        </div>

        {/* <div className="text-sm hidden semi-lg:flex gap-3">
          <span className=" text-black">{numberOfBids}</span>
          <span className=' text-[#959595]'>2023-12-01</span>
        </div> */}
      </div>
    </motion.div>
  );
}

export default AcceptBidCard;
