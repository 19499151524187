import { PAIconArrowLeftGreen } from "assets/images/svgs";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AcceptModal from "shared/AcceptModal";
import SuccessModal from "shared/SuccessModal";

const ActiveInvoiceDetails = ({ data, showSendReminderButton = true }) => {
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  const successStatuses = ["CREATED", "APPROVED", "PAID", "FULLY PAID"];
  const rejectedStatuses = ["PARTIALLY PAID", "REJECTED", "NOT PAID"];
  const awaitingStatuses = ["PENDING", "AWAITING APPROVAL", "PARTIAL_PAYMENT"];

  const getStatusColorClass = (status) => {
    if (successStatuses.includes(status)) return "text-success bg-[#D9F7BE]";
    if (rejectedStatuses.includes(status)) return "text-[#ee0816] bg-[#ffa0a0]";
    if (awaitingStatuses.includes(status)) return "text-[#F08000] bg-[#FADBB8]";
    return "text-[#000]";
  };

  const goBack = () => {
    navigate(-1);
  };

  const handleRemainderClick = () => {
    setIsAcceptModalOpen(true);
  };

  const handleConfirm = () => {
    setIsAcceptModalOpen(false);
    setIsSuccessModalOpen(true);
    setSuccessMessage("Payment reminders sent successfully!");
  };

  const handleCloseSuccessModal = () => {
    setIsSuccessModalOpen(false);
  };

  const {
    invoiceNumber,
    status,
    referenceNumber,
    createdDate,
    dueDate,
    // payment,
    buyerDetails,
    paymentTerms,
    currency,
    // supplier,
    // buyer,
    lineItems,
    numberOfLineItems,
    submittedDate,
    approvedDate,
    issuanceDate,
    accountNumber,
    accountName,
    subTotal,
    discount,
    vat,
    fundingChannel,
    fundedAmount,
    fundingStatus,
    outstandingBalance,
    discountAmount,
    vatAmount,
    grandTotal,
  } = data;

  return (
    <div className="flex flex-col gap-8 pb-24 bg-white rounded-[10px]">
      {/* HEADER */}
      <header className="px-6 border-b pt-[17px] rounded-t-[10px] border-[#B1A6B2] pb-[14px] bg-alabasterHeader">
        <div>
          <Link
            onClick={goBack}
            className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
          >
            <PAIconArrowLeftGreen />
            <span className="flex items-center text-primaryColor">Go Back</span>
          </Link>
        </div>
        <div className="flex justify-between items-center">
          <h1 className="text-black font-medium underline">Invoices</h1>
          {showSendReminderButton && (
            // <div
            //   onClick={handleRemainderClick}
            //   className="rounded-[5px] cursor-pointer bg-[#07593D] shadow-md text-[#F9F9F9] text-xs font-medium py-[10px] px-[20px]"
            // >
            <div
              onClick={handleRemainderClick}
              className="rounded-[5px] cursor-pointer bg-[#07593D] shadow-md text-[#F9F9F9] text-xs font-medium py-[10px] px-[20px]"
            >
              Send Reminder
            </div>
            // </div>
          )}
        </div>
      </header>

      <div className="px-6 border-b border-[#B1A6B2]">
        <div className="flex flex-col gap-3">
          <header className="flex justify-between items-center">
            <h1 className="text-[18px] font-bold">Invoice | {invoiceNumber}</h1>
            {/* <button className="rounded-md bg-gray300 py-1.5 px-6">
              <p className="text-black text-center text-xs font-normal">Edit</p>
            </button> */}
            {/* <div
              className={`rounded-md py-1.5 bg-flashWhite px-6 ${getStatusColorClass(
                status
              )}`}
            >
              <p className="text-center text-xs font-bold">
                {status || "NOT PAID"}
              </p>
            </div> */}
          </header>

          <div className="w-full flex items-start justify-between">
            <div className="flex flex-col gap-3 w1/2">
              <div className="flex gap-x-[5px]">
                <span>Reference No:</span>
                <span>{referenceNumber}</span>
              </div>
              <div className="flex flex-col gap-1">
                <div className="flex gap-x-[50px]">
                  <span className="text-xs w-[97px] font-semibold">
                    Created Date:
                  </span>
                  <span className="text-[#979797] text-sm w-[100px]">
                    {createdDate}
                  </span>
                </div>
                <div className="flex gap-x-[50px]">
                  <span className="text-xs w-[97px] font-semibold">
                    Submitted Date:
                  </span>
                  <span className="text-[#979797] text-sm w-[100px]">
                    {submittedDate}
                  </span>
                </div>
                <div className="flex gap-x-[50px]">
                  <span className="text-xs w-[97px] font-semibold">
                    Approved Date:
                  </span>
                  <span className="text-[#979797] text-sm w-[100px]">
                    {approvedDate}
                  </span>
                </div>
                <div className="flex gap-x-[50px]">
                  <span className="text-xs w-[97px] font-semibold">
                    Due Date:
                  </span>
                  <span className="text-[#979797] text-sm w-[100px]">
                    {dueDate}
                  </span>
                </div>
                <div className="flex gap-x-[50px]">
                  <span className="text-xs w-[97px] font-semibold">
                    Issuance Date:
                  </span>
                  <span className="text-[#979797] text-sm w-[100px]">
                    {issuanceDate}
                  </span>
                </div>
              </div>
            </div>

            {/* Channels */}
            <div className="flex flex-col gap-2">
              <div className="flex gap-x-[50px]">
                <span className="text-xs w-[97px] font-semibold">
                  Funding Status:
                </span>
                <span className="text-[#979797] text-xs">{fundingStatus}</span>
              </div>
              <div className="flex gap-x-[50px]">
                <span className="text-xs w-[97px] font-semibold">
                  Funding Channel:
                </span>
                <span className="text-[#979797] text-xs">{fundingChannel}</span>
              </div>
              <div className="flex gap-x-[50px]">
                <span className="text-xs w-[97px] font-semibold">
                  Funded Amount:
                </span>
                <span className="text-[#979797] text-xs">{fundedAmount}</span>
              </div>
              <div className="flex gap-x-[50px]">
                <span className="text-xs w-[97px] font-semibold">
                  Outstanding Balance:
                </span>
                <span className="text-[#979797] text-xs">{outstandingBalance}</span>
              </div>
            </div>
            {/* STATUS */}
            <div
              className={`rounded-md py-1.5 bg-flashWhite px-6 ${getStatusColorClass(
                status
              )}`}
            >
              <p className="text-center text-xs font-bold">
                {status || "NO STATUS"}
              </p>
            </div>
          </div>

          {/* Buyer details and co. */}
          <div className="flex justify-between my-5 border-b-[5px] border-b-[#F6F5FA] py-3">
            <div className="flex flex-col">
              <span className="font-bold text-sm">Buyer details</span>
              <span className="text-[#979797] text-xs">{buyerDetails}</span>
            </div>
            <div className="flex flex-col">
              <span className="font-bold text-sm">Payment terms</span>
              <span className="text-[#979797] text-xs">{paymentTerms}</span>
            </div>
            <div className="flex flex-col">
              <span className="font-bold text-sm">Currency</span>
              <span className="text-[#979797] text-xs">{currency}</span>
            </div>
          </div>
        </div>

        {/* Line Items Table */}
        <div className="flex flex-col gap-4 mb-12">
          <header className="border-b-[5px] border-b-[#F6F5FA] pb-3 flex items-center justify-between">
            <h1 className="text-[18px] font-normal">Line Items</h1>
            <p>{numberOfLineItems ? numberOfLineItems : "5 "} Total Items</p>
          </header>
          <div className="w-full overflow-x-auto">
            <table className="w-full overflow-x-auto">
              <thead className="bg-subtleGray w-">
                <tr className="bg-unusualGray p-2.5 ">
                  <td className="text-[#11192A] p-2.5 text-xs font-bold">
                    Item
                  </td>
                  <td className="p-2.5 text-xs font-bold">Description</td>
                  <td className="p-2.5 text-xs font-bold">Unit Price</td>
                  <td className="p-2.5 text-xs font-bold">Quantity</td>
                  <td className="p-2.5 text-xs font-bold">
                    Amount
                    <br />
                    (before tax)
                  </td>
                  <td className="p-2.5 text-xs font-bold">Tax Rate</td>
                  <td className="p-2.5 text-xs font-bold">Tax Amount</td>
                  <td className="p-2.5 text-xs font-bold">Total Price</td>
                </tr>
              </thead>
              <tbody>
                {lineItems.map((item, index) => (
                  <tr key={index} className="py-[3px] px-1.5">
                    <td className="text-xs text-[#11192A] p-2.5">
                      {item.item}
                    </td>
                    <td className="text-xs text-[#11192A] p-2.5">
                      {item.description}
                    </td>
                    <td className="text-xs text-[#11192A] p-2.5">
                      {item.unitPrice}
                    </td>
                    <td className="text-xs text-[#11192A] p-2.5">
                      {item.quantity}
                    </td>
                    <td className="text-xs text-[#11192A] p-2.5">
                      {item.amount}
                    </td>
                    <td className="text-xs text-[#11192A] p-2.5">
                      {item.taxRate}
                    </td>
                    <td className="text-xs text-[#11192A] p-2.5">
                      {item.taxAmount}
                    </td>
                    <td className="text-xs text-[#11192A] p-2.5">
                      {item.totalPrice}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Accounts Information */}
      <div className="flex itemscenter justify-between px-6">
        <div>
          <h1 className="border-b border-[#B1A6B2] font-semibold">
            Receiving Funding Account Information
          </h1>
          <p className="mt-3 text[#979797]">{accountNumber}</p>
          <p className="text[#979797]">{accountName}</p>
        </div>
        <div className="flex flex-col">
          <div className="flex justify-between gap-x-[100px] w-full">
            <span className="text-xs w-[97px]">Sub Total</span>
            <span className="text-[#979797] text-xs text-right">
              {subTotal}
            </span>
          </div>

          <div className="flex justify-between w-full gap-x-[100px] my-2">
            <span className="flex items-center text-xs w-[97px]">
              Discount%
              <span className="border border-[#979797] pr-10 pl-2 inline-block ml-4">
                {discount}%
              </span>
            </span>
            <span className="text-[#979797] text-xs">{discountAmount}</span>
          </div>

          <div className="flex justify-between w-full gap-x-[100px] pb-5">
            <span className="flex items-center text-xs w-[97px]">
              VAT%
              <span className="border border-[#979797] ml-10 pr-11 pl-2 inline-block">
                {vat}%
              </span>
            </span>
            <span className="text-[#979797] text-xs">{vatAmount}</span>
          </div>

          <div className="flex justify-between items-center w-full gap-x-[100px] border-t-2 border-b-2 font-bold text-lg py-1">
            <span className="w-[97px]">Grand Total</span>
            <span className="text[#979797] py-1">{grandTotal}</span>
          </div>
        </div>
      </div>

      <AcceptModal
        isOpen={isAcceptModalOpen}
        onClose={() => setIsAcceptModalOpen(false)}
        message={
          <>
            Would you like to proceed with sending a <br />
            <span className="text-[#07593D] font-bold">
              Payment reminder
            </span>{" "}
            to due{" "}
            <span className="text-secondaryColor font-bold">
              invoice {invoiceNumber}
            </span>
            ?
          </>
        }
        onConfirm={handleConfirm}
      />
      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={handleCloseSuccessModal}
        message={successMessage}
      />
    </div>
  );
};

export default ActiveInvoiceDetails;
