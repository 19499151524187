import DashboardLayout from "layout/dashboardlayout";

import { useSelector } from "react-redux";

// import SettlementDashboard from "./buyer/dashboard/SettlementDashboard";
import BuyerSettlementDashboard from "./buyer/dashboard/SettlementDashboard";

const SettlementDashboardContents = () => {
  const { userId } = useSelector((state) => state?.auth);
  const roleIndex = userId?.realm_access?.roles?.findIndex((arr) => {
    return (
      arr === "SUPPLIER" ||
      arr === "BUYER" ||
      arr === "FUNDER" ||
      arr === "ADMIN"
    );
  });
  const userRole = userId?.realm_access?.roles[roleIndex]?.toLowerCase();

  const settlementPlayer =
    userRole === "supplier" ? (
      <>
        <div>for supplier</div>
      </>
    ) : userRole === "funder" ? (
      <div>for funder</div>
    ) : (
      <div>
        <BuyerSettlementDashboard />
      </div>
    );

  return (
    <DashboardLayout
      section="Settlement  & Reconciliation"
      subHeader="Here is an overview of your outstanding transactions"
    >
      {settlementPlayer}
    </DashboardLayout>
  );
};

export default SettlementDashboardContents;
