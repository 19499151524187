import React from "react";
import DashboardLayout from "layout/dashboardlayout";
import { PAIconArrowLeftGreen } from "assets/images/svgs";
import { useNavigate } from "react-router-dom";

import TransitionScale from "shared/animation/TransitionScale";

// import { useNavigate } from "react-router-dom";
import { fetchSinglePendingRequestById } from "appstate/workflow/workflowSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { viewPfpById } from "appstate/funder/funderSlice";
import Table from "layout/table/Table";
const PendingRequestPFPFundOffer = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  const dispatch = useDispatch();
  const { workflowId } = useParams();

  const { singleDetailPendingRequest } = useSelector((state) => state.workflow);
  useEffect(() => {
    dispatch(fetchSinglePendingRequestById({ id: workflowId }));
  }, [dispatch, workflowId]);

  const { viewPfpByIdData } = useSelector((state) => state?.funder);

  const details = viewPfpByIdData?.data || {};

  useEffect(() => {
    if (singleDetailPendingRequest?.data?.payload?.id) {
      dispatch(
        viewPfpById({ id: singleDetailPendingRequest?.data?.payload?.id })
      );
    }
  }, [singleDetailPendingRequest?.data?.payload?.id, dispatch]);
  const columns = [
    {
      name: "Invoice Reference",
      selector: (row) => row.invoiceReference,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
    },
    {
      name: "Due Date",
      selector: (row) => row.dueDate,
      sortable: true,
    },
    {
      name: "Payment Date",
      selector: (row) => row.paymentDate,
      sortable: true,
    },
    {
      name: "Invoice Amount",
      selector: (row) =>
        Number(row.invoiceAmount).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      sortable: true,
    },
    {
      name: "Offer Amount",
      selector: (row) =>
        Number(row.offerAmount).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      sortable: true,
    },
    {
      name: "Funder Share",
      selector: (row) =>
        Number(row.funderShare).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      sortable: true,
      cell: (row) => (
        <p className="text-primaryColor">
          {Number(row.funderShare).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </p>
      ),
    },
    {
      name: "Fundabale Amount",
      selector: (row) =>
        Number(row.fundableAmount).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      sortable: true,
      cell: (row) => (
        <p className="text-primaryColor">
          {Number(row.fundableAmount).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </p>
      ),
    },

    {
      name: "Status",
      selector: (row) => (
        <span
          style={{
            color:
              row.status === "ACCEPTED"
                ? "green"
                : row.status === "ACTIVE"
                ? "#90EE90"
                : row.status === "REJECTED"
                ? "red"
                : "orange",
            fontWeight: "bold",
          }}
        >
          {row.status}
        </span>
      ),
      sortable: true,
    },
    {
      // name: "Action",
      cell: (row) => (
        <button
          className={`py-2 px-4 rounded text-white ${
            row.status === "ACTIVE"
              ? "bg-green-900"
              : "bg-gray300 cursor-not-allowed"
          }`}
          disabled={row.status !== "ACTIVE"}
        >
          Fund
        </button>
      ),
    },
  ];

  return (
    <>
      <DashboardLayout
        section="Workflow "
        subHeader="Manage all your workflow approvals"
      >
        <div className=" lg:min-h-screen">
          {" "}
          <header className=" text-primaryColor ">
            <span
              onClick={() => handleBack()}
              className="flex cursor-pointer  w-fit font-light items-center text-sm gap-2"
            >
              <PAIconArrowLeftGreen />
              Go Back
            </span>
          </header>
          <>
            {/* First Phase */}

            <TransitionScale className="px-6 pt-6 pb-8 bg-white  rounded-[10px] mt-5 ">
              <div className="lg:flex md:flex justify-between  w-full sm:block  ">
                <div>
                  <div class="text-[#11192a] text-xs font-bold ">
                    Approval Stage
                  </div>

                  <div className="">
                    {singleDetailPendingRequest?.data?.approvalStage ===
                    "0 of 1" ? (
                      <div className="mb-[15px] mt-[10px] flex justify-center items-center gap-x-[15px] ">
                        <div>
                          <div className="w-[100px] h-1   bg-[#5c89ff]/30    rounded-[100px] lg:w-[150px]  xs:w-[70px]"></div>
                        </div>
                        <div>
                          <div className="w-[100px] h-1   bg-[#5c89ff]/30 rounded-[100px] lg:w-[150px]  xs:w-[70px]"></div>
                        </div>
                        <div>
                          <div className="w-[100px] h-1 bg-[#5c89ff]/30  rounded-[100px] lg:w-[150px] xs:w-[70px] "></div>
                        </div>
                      </div>
                    ) : (
                      <div className="mb-[15px] mt-[10px] flex justify-center items-center gap-x-[15px] ">
                        <div>
                          <div className="w-[100px] h-1   bg-[#5c89ff]   rounded-[100px] lg:w-[150px]  xs:w-[70px]"></div>
                        </div>
                        <div>
                          <div className="w-[100px] h-1   bg-[#5c89ff] rounded-[100px] lg:w-[150px]  xs:w-[70px]"></div>
                        </div>
                        <div>
                          <div className="w-[100px] h-1 bg-[#5c89ff] rounded-[100px] lg:w-[150px] xs:w-[70px] "></div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* <div>
                {" "}
                <div class="h-[30px] px-6 py-2 bg-[#de1515]/30 rounded-[5px] justify-center items-center gap-2.5 inline-flex">
                  <div class="text-center text-[#de1515] text-xs font-semibold">
                    Declined
                  </div>
                </div>
              </div> */}

                <div className=" flex gap-3">
                  <div class="h-[37px] p-2.5 rounded-[5px] border border-[#5c8aff]/30 justify-center items-center gap-2.5 inline-flex">
                    <div class="text-[#5c89ff] text-sm font-medium ">
                      Pending Workflow
                    </div>
                  </div>
                </div>
              </div>

              <div className="lg:flex md:flex justify-between  w-full sm:block  ">
                {/* underlined div */}

                {/* Button division */}

                <div> </div>
              </div>

              {/* Second Box phase and pages */}
              <div className="lg:flex md:flex justify-between  w-full sm:block  mt-6 ">
                <div>
                  <div class=" flex-col justify-center items-start gap-2 inline-flex">
                    <div class="self-stretch justify-between items-center inline-flex gap-28">
                      <div class="text-[#222222] text-[14px] font-semibold">
                        Date Sent:
                      </div>
                      <div class="text-[#7a7a7a] text-[14px] font-medium ">
                        {singleDetailPendingRequest?.data?.dateSent}
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex gap-28">
                      <div class="text-[#222222] text-[14px] font-semibold">
                        Transaction Type:
                      </div>
                      <div class="text-[#7a7a7a] text-[14px] font-medium ">
                        {singleDetailPendingRequest?.data?.type}
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex  gap-28">
                      <div class="text-[#222222] text-[14px] font-semibold">
                        Action:
                      </div>
                      <div class="text-[#7a7a7a] text-[14px] font-medium ">
                        {singleDetailPendingRequest?.data?.action}
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-[14px] font-semibold">
                        Approval Stage:
                      </div>
                      <div class="text-[#7a7a7a] text-[14px] font-medium ">
                        {singleDetailPendingRequest?.data?.approvalStage}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Second side */}

                <div>
                  <div class=" flex-col justify-center items-start gap-2 inline-flex">
                    <div class="self-stretch justify-between items-center inline-flex gap-28">
                      <div class="text-[#222222] text-[14px] font-semibold">
                        Sender:
                      </div>
                      <div class="text-[#7a7a7a] text-[14px] font-medium ">
                        {singleDetailPendingRequest?.data?.sender}
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex gap-28">
                      <div class="text-[#222222] text-[14px] font-semibold">
                        Previous Approver:
                      </div>
                      <div class="text-[#7a7a7a] text-[14px] font-medium ">
                        {singleDetailPendingRequest?.data?.previousApprover}
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex  gap-28">
                      <div class="text-[#222222] text-[14px] font-semibold">
                        Next Approver:
                      </div>
                      <div class="text-[#7a7a7a] text-[14px] font-medium ">
                        {singleDetailPendingRequest?.data?.nextApprover}
                      </div>
                    </div>
                  </div>
                  {/* Button */}
                </div>
              </div>
            </TransitionScale>

            <TransitionScale className=" pb-5 bg-white  rounded-[10px] mt-5 ">
              <div class="  px-[22px]  bg-neutral-50  rounded-t-[10px] flex-col justify-start items-start gap-2.5 inline-flex w-full bg-[#fafafa] py-[13px]">
                <div class="self-stretch justify-between items-center inline-flex">
                  <div class="text-black text-sm font-medium ">Invoices</div>
                </div>
              </div>
              {/* Header with Left Padding */}

              {/* Other sections */}

              <section className="  mt-3">
                <div class="text-[#222222] text-lg font-semibold mt-4 px-12">
                  Invoice |{" "}
                  {singleDetailPendingRequest?.data?.payload?.invoiceNo &&
                    singleDetailPendingRequest?.data?.payload?.invoiceNo}
                </div>
                {/* Down Flex Texts */}
                <div className=" flex justify-between px-12">
                  {/* first stage */}

                  <div class="lg:w-[229px]  sm:w-full h-[117px] flex-col justify-start items-start gap-3 inline-flex">
                    <div class="self-stretch justify-between items-center inline-flex ">
                      <div class="text-[#222222] text-base font-normal ">
                        Reference No:{" "}
                        {singleDetailPendingRequest?.data?.payload
                          ?.invoiceRef &&
                          singleDetailPendingRequest?.data?.payload?.invoiceRef}
                      </div>{" "}
                    </div>
                    <div class="self-stretch  flex-col justify-center items-start gap-1 flex ">
                      <div class="self-stretch justify-between items-center inline-flex">
                        <div class="text-[#222222] text-xs font-normal ">
                          Created Date:{" "}
                        </div>
                        <div class="text-[#7a7a7a] text-xs font-normal ">
                          {
                            singleDetailPendingRequest?.data?.payload
                              ?.createdDate
                          }
                        </div>
                      </div>
                      <div class="self-stretch justify-between items-center inline-flex ">
                        <div class="text-[#222222] text-xs font-normal ">
                          Invoice Due Date:
                        </div>
                        <div class="text-[#7a7a7a] text-xs font-normal ">
                          {details?.dueDate && details?.dueDate}
                        </div>
                      </div>
                      <div class="self-stretch justify-between items-center inline-flex">
                        <div class="text-[#222222] text-xs font-normal ">
                          Invoice Amount
                        </div>
                        <div class="text-[#7a7a7a] text-xs font-normal ">
                          {singleDetailPendingRequest?.data?.payload
                            ?.invoicedAmount &&
                            singleDetailPendingRequest?.data?.payload
                              ?.invoicedAmount}
                        </div>
                      </div>
                    </div>

                    {/* stop */}
                  </div>

                  {/* endes instantly here */}
                  {/* second stage */}
                  {/* <div className="flex justify-between items-center mt-10 ">
                    {" "}
                    <div class="h-[37px] p-2.5 rounded-[5px] border border-[#f08000]/30 justify-center items-center gap-2.5 inline-flex">
                      <div class="text-[#f08000] text-sm font-medium ">
                        Pending Your Approval
                      </div>
                    </div>
                  </div>
                </div> */}

                  <div class="lg:w-[229px]  sm:w-full h-[117px] flex-col justify-start items-start gap-3 inline-flex mt-4">
                    <div class="self-stretch justify-between items-center inline-flex ">
                      <div class="text-[#222222] text-base font-bold ">
                        Payable Finance Offer
                      </div>
                    </div>
                    <div class="self-stretch  flex-col justify-center items-start gap-1 flex ">
                      <div class="self-stretch justify-between items-center inline-flex">
                        <div class="text-[#222222] text-xs font-normal ">
                          Discount Rate
                        </div>
                        <div class="text-[#7a7a7a] text-xs font-normal ">
                          {singleDetailPendingRequest?.data?.payload
                            ?.discountRate &&
                            singleDetailPendingRequest?.data?.payload
                              ?.discountRate}
                          %
                        </div>
                      </div>
                      <div class="self-stretch justify-between items-center inline-flex ">
                        <div class="text-[#222222] text-xs font-normal ">
                          Funder Share
                        </div>
                        <div class="text-[#7a7a7a] text-xs font-normal ">
                          {singleDetailPendingRequest?.data?.payload
                            ?.funderShare &&
                            singleDetailPendingRequest?.data?.payload
                              ?.funderShare}
                        </div>
                      </div>
                      <div class="self-stretch justify-between items-center inline-flex ">
                        <div class="text-[#222222] text-xs font-normal ">
                          Offer Amount
                        </div>
                        <div class="text-[#7a7a7a] text-xs font-normal ">
                          {singleDetailPendingRequest?.data?.payload
                            ?.offerAmount &&
                            singleDetailPendingRequest?.data?.payload
                              ?.offerAmount}
                        </div>
                      </div>
                      <div class="self-stretch justify-between items-center inline-flex">
                        <div class="text-[#222222] text-xs font-normal ">
                          Fundable Amount
                        </div>
                        <div class="text-[#7a7a7a] text-xs font-normal ">
                          {singleDetailPendingRequest?.data?.payload
                            ?.payableAmount &&
                            singleDetailPendingRequest?.data?.payload
                              ?.payableAmount}
                        </div>
                      </div>
                      <div class="self-stretch justify-between items-center inline-flex">
                        <div class="text-[#222222] text-xs font-normal ">
                          Payment Duration
                        </div>
                        <div class="text-[#7a7a7a] text-xs font-normal ">
                          {
                            singleDetailPendingRequest?.data?.payload
                              ?.paymentDuration
                          }{" "}
                          Days
                        </div>
                      </div>
                      <div class="self-stretch justify-between items-center inline-flex">
                        <div class="text-[#222222] text-xs font-normal ">
                          Payment Date
                        </div>
                        <div class="text-[#7a7a7a] text-xs font-normal ">
                          {singleDetailPendingRequest?.data?.payload
                            ?.paymentDate &&
                            singleDetailPendingRequest?.data?.payload
                              ?.paymentDate}{" "}
                        </div>
                      </div>
                    </div>

                    {/* stop */}
                  </div>

                  {/* stop */}
                </div>
                {/* stops here */}{" "}
                <div className="flex w-full justify-between">
                  {" "}
                  <div class="  px-12 h-[39px] flex-col justify-start items-start gap-1 inline-flex mt-10">
                    <div class="w-[129px] h-5 text-[#222222] text-sm font-black  italic">
                      Supplier
                    </div>
                    <div class="w-[147px] h-[15px] text-[#7a7a7a] text-xs font-normal ">
                      {
                        singleDetailPendingRequest?.data?.payload
                          ?.supplierCompanyName
                      }
                    </div>
                  </div>
                  <div class="  px-12 h-[39px] flex-col justify-start items-start gap-1 inline-flex mt-10">
                    <div class="w-[129px] h-5 text-[#222222] text-sm font-black  italic">
                      Buyer
                    </div>
                    <div class="w-[147px] h-[15px] text-[#7a7a7a] text-xs font-normal ">
                      {
                        singleDetailPendingRequest?.data?.payload
                          ?.buyerCompanyName
                      }
                    </div>
                  </div>
                </div>
                <div className="  px-12 ">
                  <header className="border-b-[5px] border-b-[#F6F5FA]  flex justify-between py-1 mt-12">
                    {/* <h1 className="text-[18px] font-semibold">
                    Supplier/buyer details
                  </h1> */}
                    <div class="text-[#11192a] text-sm font-semibold ">
                      Supplier/buyer Details
                    </div>
                  </header>
                  <header className="border-b-[5px] border-b-[#F6F5FA]  flex justify-between py-1 mt-12">
                    {/* <h1 className="text-[18px] font-semibold">
                    Supplier/buyer details
                  </h1> */}
                    <div class="text-[#11192a] text-sm font-semibold ">
                      Line Items
                    </div>
                    <div class="text-[#11192a] text-lg font-light  mr-6">
                      {details?.noOfInvoices}Total Items{" "}
                    </div>
                  </header>

                  <div className=" w-full overflow-x-auto mt-3 pb-4">
                    <div className="mt-[24px] pr-[30px]">
                      <Table
                        columns={columns}
                        // data={dummyData}
                        // data={singleDetailPendingRequest?.data?.payload?.invoices?.slice(
                        //   0,
                        //   5
                        // )}
                        data={details?.invoices}
                        // onRowClicked={onRowClicked}

                        pointer
                        tableHeader
                        className="min-h-[35vh]"
                      />
                    </div>
                  </div>
                  {/* Calculate cost and price  shaded or gray section */}
                </div>
              </section>
            </TransitionScale>
          </>
        </div>
      </DashboardLayout>
    </>
  );
};

export default PendingRequestPFPFundOffer;
