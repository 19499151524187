import React, { useEffect, useRef, useState } from "react";
import {
  PAIconDownload,
  PAIconFilter,
  PAIconOption,
  PAIconOutlineSearch,
} from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";
import Table from "layout/table/Table";
import AcceptModal from "shared/AcceptModal";
import SuccessModal from "shared/SuccessModal";
import { useDispatch, useSelector } from "react-redux";
import { allDirectFunding } from "appstate/invoice/invoiceSlice";
import Pagination from "shared/Pagination";
import Loading from "shared/Loading";
import {
  recievablesOverdueFull,
  recievablesOverduePartial,
  recievablesUpcomingFull,
  recievablesUpcomingPartial,
} from "appstate/collections/collectionSlice";
import { remainder } from "appstate/collections/collectionSlice";

const Recievables = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [singleSelectedRows, setSingleSelectedRows] = useState([]);
  const [bulkSelectedRows, setBulkSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [activeTab, setActiveTab] = useState("single");
  const [selectedOption, setSelectedOption] = useState("overdueInvoices");
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  const {
    recievablesOverduePartialData,
    recievablesOverdueFullData,
    recievablesUpcomingPartialData,
    recievablesUpcomingFullData,
    isLoading,
  } = useSelector((state) => state?.collection);

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen({});
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const dispatch = useDispatch();

  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(10);
  const pageSize = 10;

  const handleSearch = () => {
    dispatch(allDirectFunding({ search: searchQuery }));
  };

  // Consolidated useEffect for fetching data based on filters
  useEffect(() => {
    const fetchRecievables = () => {
      const params = {
        page,
        pageSize,
        search: searchQuery,
        yes: "2",
      };

      // Determine which API to call based on activeTab and selectedOption
      if (activeTab === "single") {
        if (selectedOption === "overdueInvoices") {
          dispatch(recievablesOverdueFull(params));
        } else {
          dispatch(recievablesUpcomingFull(params));
        }
      } else {
        if (selectedOption === "overdueInvoices") {
          dispatch(recievablesOverduePartial(params));
        } else {
          dispatch(recievablesUpcomingPartial(params));
        }
      }
    };

    fetchRecievables();
  }, [dispatch, page, pageSize, searchQuery, activeTab, selectedOption]);

  // Get the appropriate data based on the active tab and selected option
  const getTableData = () => {
    if (activeTab === "single") {
      return selectedOption === "overdueInvoices"
        ? recievablesOverdueFullData?.data || []
        : recievablesUpcomingFullData?.data || [];
    } else {
      return selectedOption === "overdueInvoices"
        ? recievablesOverduePartialData?.data || []
        : recievablesUpcomingPartialData?.data || [];
    }
  };

  // Get the total pages based on the active tab and selected option
  const getTotalPages = () => {
    if (activeTab === "single") {
      return selectedOption === "overdueInvoices"
        ? recievablesOverdueFullData?.meta?.totalElements
        : recievablesUpcomingFullData?.meta?.totalElements;
    } else {
      return selectedOption === "overdueInvoices"
        ? recievablesOverduePartialData?.meta?.totalElements
        : recievablesUpcomingPartialData?.meta?.totalElements;
    }
  };

  const handleNotificationClick = (row) => {
    // Determine the selected rows state based on the active tab
    const isSingleTab = activeTab === "single";
    const selectedRowList = isSingleTab ? singleSelectedRows : bulkSelectedRows;

    const isSelected = selectedRowList.includes(row.id);
    let newSelectedRows = [];

    if (!isSelected) {
      newSelectedRows = [...selectedRowList, row.id];
    }

    // Update the appropriate state based on the active tab
    if (isSingleTab) {
      setSingleSelectedRows(newSelectedRows);
    } else {
      setBulkSelectedRows(newSelectedRows);
    }

    setIsAcceptModalOpen(true);
  };

  const handleConfirm = () => {
    setIsAcceptModalOpen(false);

    const selectedInvoiceIds =
      activeTab === "single" ? singleSelectedRows : bulkSelectedRows;

    if (selectedInvoiceIds.length > 0) {
      // Add the API call for sending the reminder here
      dispatch(
        remainder({ type: "receivables", body: selectedInvoiceIds })
      ).then((response) => {
        if (response?.payload?.status === 200 && response?.payload?.success) {
          setIsSuccessModalOpen(true);
          setSuccessMessage(response?.payload?.message);
        }
      });
      // setIsSuccessModalOpen(true);
      // setSuccessMessage("Payment remainders sent successfully");
    }
  };

  const handleCloseSuccessModal = () => {
    setIsSuccessModalOpen(false);
  };

  const singleDetails = (id) => {
    let path = `/collections/recievables/${id}`;
    navigate(path);
  };

  const bulkDetails = (id) => {
    let path = `/collections/partial-recievables/${id}`;
    navigate(path);
  };

  const options = [
    { value: "overdueInvoices", label: "Overdue Invoices" },
    { value: "upcomingInvoices", label: "Upcoming Invoices" },
  ];

  const handleSelect = (option) => {
    setSelectedOption(option.value);
    setIsOpen(false);
  };

  const handleSendReminder = () => {
    const rowsToProcess =
      activeTab === "single" ? singleSelectedRows : bulkSelectedRows;
    if (rowsToProcess?.length > 0) {
      setIsAcceptModalOpen(true);
    }
  };

  const handleSelectAll = () => {
    const tableData = getTableData();

    if (activeTab === "single") {
      if (selectAll) {
        setSingleSelectedRows([]);
      } else {
        setSingleSelectedRows(tableData.map((row) => row.id));
      }
    } else if (activeTab === "bulk") {
      if (selectAll) {
        setBulkSelectedRows([]);
      } else {
        setBulkSelectedRows(tableData.map((row) => row.id));
      }
    }
    setSelectAll(!selectAll);
  };

  const columns =
    activeTab === "single"
      ? [
          {
            name: (
              <input
                type="checkbox"
                onChange={handleSelectAll}
                checked={selectAll}
              />
            ),
            cell: (row) => (
              <input
                type="checkbox"
                onChange={() => handleCheckboxChange(row)}
                checked={singleSelectedRows.includes(row.id)}
              />
            ),
            width: "50px",
          },
          {
            name: (
              <span className="whitespace-normal">
                <img className=" pr1 inline" src="/group-icon.svg" alt="" />
                Invoice Number
              </span>
            ),
            selector: (row) => {
              const invoiceNumber = row.invoiceNumber;
              return invoiceNumber && !isNaN(invoiceNumber)
                ? `INV-${invoiceNumber}`
                : row.invoiceNumber || "INV-0";
            },
            sortable: true,
          },
          {
            name: "Buyer",
            selector: (row) => row.buyer,
            sortable: true,
          },
          {
            name: <span className="whitespace-normal">Due Date</span>,
            selector: (row) => row.dueDate,
            sortable: true,
            cell: (row) => {
              // Convert the array to a Date object
              const date = new Date(
                row.dueDate[0],
                row.dueDate[1] - 1,
                row.dueDate[2]
              );

              // Format the date as 'YYYY-MM-DD'
              const formattedDate = date.toLocaleDateString("en-CA");

              return <p>{formattedDate}</p>;
            },
          },
          {
            name: "Invoice Timeline",
            selector: (row) => row.invoiceTimeline,
            sortable: true,
          },
          {
            name:
              selectedOption === "overdueInvoices"
                ? "Days Past Due"
                : "Days to Due",
            selector: (row) =>
              selectedOption === "overdueInvoices"
                ? row.daysPastDue
                : row.daysUntilDue,
            sortable: true,
            cell: (row) => (
              <p
                className={
                  selectedOption === "overdueInvoices"
                    ? "text-red"
                    : "text-success"
                }
              >
                {selectedOption === "overdueInvoices"
                  ? row.daysPastDue
                  : row.daysUntilDue}{" "}
                days
              </p>
            ),
          },
          {
            name: "Currency",
            selector: (row) => row.currency,
            sortable: true,
          },
          {
            name: "Total Amount",
            selector: (row) => row.totalAmount,
            sortable: true,
            cell: (row) => (
              <p className="text-success">
                {row.totalAmount?.toLocaleString()}
              </p>
            ),
          },
          {
            name: "Status",
            selector: (row) => row.status,
            sortable: true,
            cell: (row) => (
              <p
                className={
                  selectedOption === "overdueInvoices" ||
                  row.status === "Overdue"
                    ? "text-red"
                    : "text-yellow-400"
                }
              >
                {row.status}
              </p>
            ),
          },
          {
            cell: (row) => (
              <div className="flex gap-2">
                <PAIconOption
                  title="Options"
                  onClick={() => {
                    setIsDropdownOpen((prev) => ({
                      ...prev,
                      [row.id]: !prev[row.id],
                    }));
                    Object.keys(isDropdownOpen).forEach((key) => {
                      if (key !== row.id.toString()) {
                        setIsDropdownOpen((prev) => ({
                          ...prev,
                          [key]: false,
                        }));
                      }
                    });
                  }}
                />
                {isDropdownOpen[row.id] && (
                  <div
                    ref={dropdownRef}
                    className="absolute bg-white border border-gray300 rounded-md shadow-md z-10"
                  >
                    <ul>
                      <li
                        className="px-4 py-2 cursor-pointer hover:bg-primaryColor hover:text-white"
                        onClick={() => {
                          singleDetails(row?.id);
                          setIsDropdownOpen((prev) => ({
                            ...prev,
                            [row.id]: false,
                          }));
                        }}
                      >
                        Preview
                      </li>
                      <li
                        className="px-4 py-2 cursor-pointer hover:bg-primaryColor hover:text-white"
                        onClick={() => {
                          handleNotificationClick(row);
                          setIsDropdownOpen((prev) => ({
                            ...prev,
                            [row.id]: false,
                          }));
                        }}
                      >
                        Send Reminder
                      </li>
                      <li className="px-4 py-2 cursor-not-allowed text-gray300">
                        Export
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            ),
          },
        ]
      : [
          {
            name: (
              <input
                type="checkbox"
                onChange={handleSelectAll}
                checked={selectAll}
              />
            ),
            cell: (row) => (
              <input
                type="checkbox"
                onChange={() => handleCheckboxChange(row)}
                checked={bulkSelectedRows.includes(row.id)}
              />
            ),
            width: "50px",
          },
          {
            name: (
              <span className="whitespace-normal">
                <img className=" pr1 inline" src="/group-icon.svg" alt="" />
                Invoice Number
              </span>
            ),
            selector: (row) => {
              const invoiceNumber = row.invoiceNumber;
              return invoiceNumber && !isNaN(invoiceNumber)
                ? `INV-${invoiceNumber}`
                : row.invoiceNumber || "INV-0";
            },
            sortable: true,
          },
          {
            name: "Buyer",
            selector: (row) => row.buyer,
            sortable: true,
          },
          {
            name: <span className="whitespace-normal">Due Date</span>,
            selector: (row) => row.dueDate,
            sortable: true,
            cell: (row) => {
              // Convert the array to a Date object
              const date = new Date(
                row.dueDate[0],
                row.dueDate[1] - 1,
                row.dueDate[2]
              );

              // Format the date as 'YYYY-MM-DD'
              const formattedDate = date.toLocaleDateString("en-CA");

              return <p>{formattedDate}</p>;
            },
          },
          {
            name: "Funding Channel",
            selector: (row) => row.fundingChannel,
            sortable: true,
          },
          {
            name: "Currency",
            selector: (row) => row.currency,
            sortable: true,
          },
          {
            name: "Fund Amount",
            selector: (row) => row.fundAmount,
            sortable: true,
            cell: (row) => (
              <p className="text-success">
                {row.totalAmount?.toLocaleString()}
              </p>
            ),
          },
          {
            name: "Balance Amount",
            selector: (row) => row.balanceAmount,
            sortable: true,
            cell: (row) => <p>{row.balanceAmount?.toLocaleString()}</p>,
          },
          {
            name:
              selectedOption === "overdueInvoices"
                ? "Days Past Due"
                : "Days to Due",
            selector: (row) => row.dueDays,
            sortable: true,
            cell: (row) => (
              <p
                className={
                  selectedOption === "overdueInvoices" ||
                  row.status === "Overdue"
                    ? "text-red"
                    : "text-success"
                }
              >
                {selectedOption === "overdueInvoices"
                  ? row.daysPastDue
                  : row.daysUntilDue}{" "}
                days
              </p>
            ),
          },
          {
            name: "Status",
            selector: (row) => row.status,
            sortable: true,
            cell: (row) => (
              <p
                className={
                  selectedOption === "overdueInvoices" ||
                  row.status === "Overdue"
                    ? "text-red"
                    : "text-yellow-400"
                }
              >
                {row.status}
              </p>
            ),
          },
          {
            cell: (row) => (
              <div className="flex gap-2">
                <PAIconOption
                  title="Options"
                  onClick={() => {
                    setIsDropdownOpen((prev) => ({
                      ...prev,
                      [row.id]: !prev[row.id],
                    }));
                    Object.keys(isDropdownOpen).forEach((key) => {
                      if (key !== row.id.toString()) {
                        setIsDropdownOpen((prev) => ({
                          ...prev,
                          [key]: false,
                        }));
                      }
                    });
                  }}
                />
                {isDropdownOpen[row.id] && (
                  <div
                    ref={dropdownRef}
                    className="absolute bg-white border border-gray300 rounded-md shadow-md z-10"
                  >
                    <ul>
                      <li
                        className="px-4 py-2 cursor-pointer hover:bg-primaryColor hover:text-white"
                        onClick={() => {
                          bulkDetails(row?.id);
                          setIsDropdownOpen((prev) => ({
                            ...prev,
                            [row.id]: false,
                          }));
                        }}
                      >
                        Preview
                      </li>
                      <li
                        className="px-4 py-2 cursor-pointer hover:bg-primaryColor hover:text-white"
                        onClick={() => {
                          handleNotificationClick(row);
                          setIsDropdownOpen((prev) => ({
                            ...prev,
                            [row.id]: false,
                          }));
                        }}
                      >
                        Send Reminder
                      </li>
                      <li className="px-4 py-2 cursor-not-allowed text-gray300">
                        Export
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            ),
          },
        ];

  const handleCheckboxChange = (row) => {
    if (activeTab === "single") {
      const isSelected = singleSelectedRows.includes(row.id);
      setSingleSelectedRows(
        isSelected
          ? singleSelectedRows.filter((id) => id !== row.id)
          : [...singleSelectedRows, row.id]
      );
    } else if (activeTab === "bulk") {
      const isSelected = bulkSelectedRows.includes(row.id);
      setBulkSelectedRows(
        isSelected
          ? bulkSelectedRows.filter((id) => id !== row.id)
          : [...bulkSelectedRows, row.id]
      );
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    // Reset selections when changing tabs
    if (tab === "single") {
      setBulkSelectedRows([]);
    } else {
      setSingleSelectedRows([]);
    }
    setSelectAll(false);
  };

  const renderTabContent = () => {
    const tableData = getTableData();
    const totalPages = getTotalPages();

    return (
      <>
        <Table
          columns={columns}
          data={tableData}
          onRowClicked={(row) =>
            activeTab === "single" ? singleDetails(row.id) : bulkDetails(row.id)
          }
          progressPending={isLoading}
          progressComponent={<Loading />}
          pointer
          tableHeader
          className="mt-5"
        />
        <div className="flex justify-center gap-x-[8px] mt-[20px]">
          {tableData?.length && (
            <Pagination
              page={page}
              startPage={startPage}
              setEndPage={setEndPage}
              endPage={endPage}
              setStartPage={setStartPage}
              setPage={setPage}
              totalPages={totalPages}
            />
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <div className="my-2 grid md:grid-cols-2 items-center">
        <div className="gap-3 flex relative">
          <div>
            {/* FILTER */}
            <div className="relative">
              {/* Dropdown Button */}
              <button
                onClick={() => setIsOpen(!isOpen)}
                className="bg-white rounded flex text-sm items-center justify-between px-4 py-2 w-auto focus:outline-none"
              >
                {options.find((opt) => opt.value === selectedOption)?.label}
                <svg
                  className={`w-4 h-8 ml-2 transition-transform ${
                    isOpen ? "rotate-180" : "rotate-0"
                  }`}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.707a1 1 0 011.414 0L10 11.293l3.293-3.586a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>

              {/* Dropdown Menu */}
              {isOpen && (
                <ul className="absolute mt-2 w-auto text-sm bg-white border border-gray300 rounded-2xl shadow-md z-10">
                  {options.map((option) => (
                    <li
                      key={option.value}
                      onClick={() => handleSelect(option)}
                      className={`px-4 py-2 flex items-center text-xs cursor-pointer hover:bg-primaryColor hover:text-white ${
                        selectedOption === option.value ? "bgprimaryColor" : ""
                      }`}
                    >
                      {option.label}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>

          {/* EXPORT */}
          <button className="py-2 px-4 text-sm rounded-[5px] bg-white border-[#DEDEDE] shadow-sm mr-2 flex items-center justify-center gap-1.5">
            Export {<PAIconDownload className="accent-gray300 w-4" />}
          </button>
        </div>

        {/* SEARCH */}
        <div className="flex md:col-span-1 justify-end">
          <div className="flex items-center justify-between gap-5">
            <div className="border border-transparent md:border-gray md:shadow-sm my-4 rounded-[5px] px-2 lg:w-[300px] bg-white">
              <div className="items-center flex bg-white">
                <PAIconOutlineSearch />
                <input
                  className="bg-transparent p-2 w-full focus:outline-none text-sm bg-white"
                  type="text"
                  placeholder="Search for invoices awaiting collection"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleSearch();
                    }
                  }}
                />
              </div>
              <div className="lg:hidden flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer">
                <PAIconFilter className="bg-[#2FA06A]" />
              </div>
            </div>
            <button
              className="hidden lg:flex bg-[#2FA06A] rounded-md my-4 py-2 px-6 mx3 items-center gap-2 text-white"
              onClick={handleSearch}
            >
              Search
            </button>
          </div>
        </div>
      </div>

      {/* TABLE */}
      <div>
        {!getTableData()?.length ? (
          <div>
            <div className="flex justify-between items-center px-[15px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
              <p className="text-black text-sm font-medium">
                Invoices from Buyers awaiting collection (Receivables)
              </p>
            </div>
            <TableEmptyState dashboard />
          </div>
        ) : (
          <Card className="rounded-[10px] mt-[15px]">
            <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
              <div>
                <p className="text-black text-lg font-bold">
                  Invoices from Buyers awaiting collection (Receivables)
                </p>
                <span className="text-xs font-thin">
                  List of all invoices from buyers
                </span>
              </div>
              <div className="flex items-center justify-center gap-3">
                <button
                  className={`border border-gray300 rounded-md p-2 text-white ${
                    (activeTab === "single" &&
                      singleSelectedRows?.length === 0) ||
                    (activeTab === "bulk" && bulkSelectedRows?.length === 0)
                      ? "cursor-not-allowed bg-gray300"
                      : "cursor-pointer bg-secondaryColor"
                  }`}
                  disabled={
                    (activeTab === "single" &&
                      singleSelectedRows?.length === 0) ||
                    (activeTab === "bulk" && bulkSelectedRows?.length === 0)
                  }
                  onClick={handleSendReminder}
                >
                  <p>Send Reminder Now</p>
                </button>
              </div>
            </div>
            <div className="pb-[24px]">
              <div className="flex px-[24px] gap-3 my-3">
                <p
                  className={`cursor-pointer font-bold relative ${
                    activeTab === "single" ? "text-[#F08000]" : "text-[#959595]"
                  }`}
                  onClick={() => handleTabClick("single")}
                >
                  Full payment
                  {activeTab === "single" && (
                    <span
                      className="absolute bottom-0 left-1/2 transform -translate-x-1/2
                 bg-[#F08000] h-0.5 w-5"
                    ></span>
                  )}
                </p>

                <p
                  className={`cursor-pointer font-bold relative ${
                    activeTab === "bulk" ? "text-[#F08000]" : "text-[#959595]"
                  }`}
                  onClick={() => handleTabClick("bulk")}
                >
                  Partial payment
                  {activeTab === "bulk" && (
                    <span
                      className="absolute bottom-0 left-1/2 transform -translate-x-1/2
                 bg-[#F08000] h-0.5 w-5"
                    ></span>
                  )}
                </p>
              </div>

              <div className="mt-3">{renderTabContent()}</div>
            </div>
          </Card>
        )}
      </div>

      <AcceptModal
        isOpen={isAcceptModalOpen}
        onClose={() => setIsAcceptModalOpen(false)}
        yes
        message={
          <>
            Would you like to proceed with sending a <br />
            <span className="text-[#07593D] font-bold">
              Payment reminder
            </span>{" "}
            now?
          </>
        }
        onConfirm={handleConfirm}
      />

      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={handleCloseSuccessModal}
        message={successMessage}
      />
    </>
  );
};

export default Recievables;
