import {
  PAIconInventoryArrowLeft,
  PAIconOutlineSearch,
} from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Card from "shared/Card";
import { motion } from "framer-motion";
import CocaCola from "../../../../../assets/images/svgs/cocacola.svg";
import { useEffect, useState } from "react";
import {
  getAllInvoiceById,
  getBulkEarlyPaymentDetails,
} from "appstate/api/invoice/invoice";
import SuccessEarlyPayment from "features/invoice/fundingSection/earlyPaymentRequest/singlerequest/Modal/SuccessEarlyPayment";
import PayProgressModal from "features/payableFinaceForFunder/CloseMarket/modals/PayProgressModal";
import PinModalPayment from "features/invoice/fundingSection/earlyPaymentRequest/singlerequest/Modal/PinModal";
import AcceptModal from "shared/AcceptModal";
import FundWalletDetails from "features/wallet/fund/externalSource/fundWallet";
import ConfirmingPaymentReimbursement from "features/wallet/fund/externalSource/confirmingpaymentreimbursement";
import MyInvoicesCard from "../cards/InvoiceCard";

const LienedClosedMarketReimbursementWithoutRecourseDetails = () => {
  const [open, setOpen] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [otpInputs, setOtpInputs] = useState(["", "", "", ""]);
  const [transferFunds, setTransferFunds] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [progress, setProgress] = useState(0);
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { invoiceId, selectedStatus, recourse, selectedOption } =
    location.state || {};

  // console.log("==hello====", selectedOption);

  const [, setInvoiceData] = useState(null);

  // eslint-disable-next-line
  const [error, setError] = useState(null);
  const [earlyPaymentData, setEarlyPaymentData] = useState(null);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(true);
  const [showDirectFunding, setShowDirectFunding] = useState(false);
  const [showTransferFundsUpcoming, setShowTransferFundsUpcoming] =
    useState(false);
  const [showVerifyModal, setShowVerifyModal] = useState(false);

  const handleDirectFunding = () => {
    setShowDirectFunding(!showDirectFunding);
  };

  useEffect(() => {
    const fetchInvoice = async () => {
      if (invoiceId) {
        const result = await getAllInvoiceById({ id: invoiceId });
        setInvoiceData(result?.data);
      }
    };
    fetchInvoice();
    // eslint-disable-next-line
  }, [invoiceId]);

  useEffect(() => {
    const fetchEarlyPaymentDetails = async () => {
      try {
        const result = await getBulkEarlyPaymentDetails(id);
        setEarlyPaymentData(result?.data);
      } catch (error) {
        setError(error.message || "Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchEarlyPaymentDetails();
    }
  }, [id]);

  // console.log("invoice data" , invoiceId)

  // const { state } = location?.state || {};

  function handleNavigate() {
    let path = -1;
    navigate(path);
  }

  const handlePayNow = () => {
    setOpen(!open);
  };

  const handleConfirm = (row) => {
    setOpen(false);
    setShowPin(true);
  };

  const onClosePinModal = () => {
    // setOpen(false);
    setShowPin(false);
  };

  const confirmPayment = () => {
    setShowPin(false);
    setTransferFunds(true);
    let interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prev + 10;
      });
    }, 500);
  };

  useEffect(() => {
    if (progress === 100) {
      setTransferFunds(false);
      setShowSuccess(true);
    }
  }, [progress]);

  const onCloseSuccessModal = () => {
    setShowSuccess(false);
    setProgress(0);
  };

  const handleDirectFundingConfirm = () => {
    setShowDirectFunding(false);
    setShowTransferFundsUpcoming(true);
  };

  const confirmPaymentUpcoming = () => {
    setShowTransferFundsUpcoming(false);
    setShowVerifyModal(true);
  };

  const confirmVerifyPaymentUpcoming = () => {
    setShowVerifyModal(false);
    setShowPin(true);
  };

  // const onRowClicked = () => {
  //   let path = "/supplier bulk-early-payment-details-details";
  //   navigate(path, { state: state });
  // };
  const data = [
    {
      id: "1",

      invoiceNumber: "001",

      invoiceAmount: "14263400",

      invoiceDueDate: "2023-12-01",

      earlyDate: "2023-12-01",
      earlyId: "1",
    },
    {
      id: "2",

      invoiceNumber: "001",

      invoiceAmount: "14263400",

      invoiceDueDate: "2023-12-01",

      earlyDate: "2023-12-01",
      earlyId: "2",
    },
    {
      id: "3",

      invoiceNumber: "001",

      invoiceAmount: "14263400",

      invoiceDueDate: "2023-12-01",

      earlyDate: "2023-12-01",
      earlyId: "3",
    },
    {
      id: "4",

      invoiceNumber: "001",

      invoiceAmount: "14263400",

      invoiceDueDate: "2023-12-01",

      earlyDate: "2023-12-01",
      earlyId: "4",
    },
  ];

  return (
    <DashboardLayout
      section="Reimbursement"
      subHeader="Track invoices due for payment Reimbursement"
    >
      {showTransferFundsUpcoming && (
        <FundWalletDetails
          invoice={true}
          isOpen={showTransferFundsUpcoming}
          onClose={() => setShowTransferFundsUpcoming(false)}
          confirmPaymentUpcoming={confirmPaymentUpcoming}
        />
      )}

      {showVerifyModal && (
        <ConfirmingPaymentReimbursement
          invoice={true}
          isOpen={true}
          onClose={confirmVerifyPaymentUpcoming}
          confirmPaymentUpcoming={confirmVerifyPaymentUpcoming}
        />
      )}

      <AcceptModal
        isOpen={showDirectFunding}
        onClose={() => setShowDirectFunding(false)}
        message={`Are you sure you want to fund this virtual account for this request ?`}
        onConfirm={handleDirectFundingConfirm}
        // loading={false}
      />
      <AcceptModal
        isOpen={open}
        onClose={() => setOpen(false)}
        message="Are you sure you would like to Pay now for this invoice ?"
        onConfirm={handleConfirm}
        // loading={false}
      />

      <PinModalPayment
        invoice={true}
        confirmPayment={confirmPayment}
        isOpen={showPin}
        onCancel={onClosePinModal}
        otpInputs={otpInputs}
        setOtpInputs={setOtpInputs}
      />
      {transferFunds && (
        <PayProgressModal progress={progress} setProgress={setProgress} />
      )}

      {showSuccess && (
        <SuccessEarlyPayment
          isOpen={showSuccess}
          onClose={onCloseSuccessModal}
          invoice={true}
          message="Early payment request successful"
        />
      )}
      <header>
        <div
          onClick={handleNavigate}
          className="flex items-center  mb-[20px] cursor-pointer bg-white rounded-md w-full py-3 px-3 "
        >
          <PAIconInventoryArrowLeft />
          <p className="mt-[2px] text-sm font-normal text-lightGray">Go Back</p>
        </div>
      </header>
      <div className="grid lg:grid-cols-[60%,40%] gap-5">
        <Card className=" py-[24px] rounded-[10px]">
          <div className=" flex  flex-col gap-3  px-6  border-b-[#F6F5FA] border-b-[3px]  pb-6">
            <main className=" w-full flex justify-between">
              <div className="flex flex-col  w-1/2">
                <div className="flex">
                  <div>
                    <span class="text-[#222222] text-base font-bold ">
                      Request ref no:-{" "}
                    </span>
                    <span class="text-[#07593d] text-base font-bold ">
                      R3456X89Y
                    </span>
                  </div>
                </div>
                <div className="flex flex-col ">
                  <div class="w-[206px] h-[17px]">
                    <span class="text-[#949494] text-xs font-normal ">
                      Number of invoices -{" "}
                    </span>
                    <span class="text-[#222222] text-xs font-normal ">
                      (4 invoices)
                    </span>
                  </div>
                  {/* <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Invoice Number:</span>
                    <span className="text-[#979797]  text-xs">INV 2002</span>
                  </div> */}
                </div>
              </div>

              <div className="flex flex-col">
                <motion.button
                  whileTap={{ scale: 0.9 }}
                  className="flex bg-[#06593D] py-2 text-sm justify-center gap-2 text-white rounded-[5px] items-center px-[35px]"
                  onClick={() =>
                    selectedStatus === "Liened"
                      ? handlePayNow()
                      : handleDirectFunding()
                  }
                >
                  {selectedStatus === "Liened" ? "Pay Now" : "Fund Request"}
                </motion.button>
              </div>
            </main>

            <div class=" h-[17px] justify-between items-start gap-1 inline-flex">
              <div class=" flex-col justify-between items-start gap-2 inline-flex">
                <div class="w-[137px] h-[17px]">
                  <span class="text-[#949494] text-sm font-normal ">
                    Supplier -
                  </span>
                  <span class="text-[#222222] text-sm font-normal ">
                    {" "}
                    Coca Cola
                  </span>
                </div>
              </div>
              <div class="w-[290px] flex-col justify-start items-start gap-2 inline-flex">
                <div class="w-[223px] h-[17px]">
                  <span class="text-[#949494] text-sm font-normal ">
                    Early payment date -
                  </span>
                  <span class="text-[#222222] text-sm font-normal ">
                    {" "}
                    2023-12-01
                  </span>
                </div>
              </div>

              {selectedStatus === "Liened" && (
                <span className="text-[#06593D]">00d:13h:55m:32s</span>
              )}
              {selectedStatus === "Upcoming" && (
                <span className="text-[#F08000]">00d:13h:55m:32s</span>
              )}
              {selectedStatus === "Overdue" && (
                <span className="text-red">00d:13h:55m:32s</span>
              )}
            </div>
          </div>
          <br />

          {/* <div className=" flex  items-center  w-full"> */}
          <div className="flex items-center justify-between gap-5 px-6 flex-wrap">
            <div className="border border-transparent md:border-gray md:shadow-sm my-4 rounded-[5px] px-2 lg:w-[300px] bg-white flex-1">
              <div className="items-center flex bg-white">
                <PAIconOutlineSearch />
                <input
                  className="bg-transparent p-2 w-full focus:outline-none text-sm bg-white"
                  type="text"
                  placeholder="Search for due invoices awaiting collection"
                />
              </div>
              {/* <div className="flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer text-white">
                Search
              </div> */}
            </div>
            <button className=" lg:flex bg-[#2FA06A] rounded-md my-4 py-2 px-6 mx3 items-center gap-2 text-white">
              Search
            </button>
          </div>

          <div className=" px-6 mt-5 w-full">
            {data?.map((item) => (
              <MyInvoicesCard
                id={item.id}
                invoiceAmount={item.invoiceAmount}
                invoiceDueDate={item.invoiceDueDate}
                invoiceNumber={item.invoiceNumber}
                earlyDate={item.earlyDate}
              />
            ))}
          </div>

          {/* </div> */}
        </Card>
        <Card className=" p-[24px] rounded-[10px]">
          <div>
            <div className="flex justify-between">
              <span className="font-bold text-[#000000]">{selectedOption}</span>
              <span style={{ fontSize: 12 }} className="text-[#000000]">
                {recourse ? "with recourse" : "without recourse"}
              </span>
            </div>
          </div>
          <div>
            <br />
            <div className="flex justify-between">
              <div className="flex">
                <div>
                  <img src={CocaCola} alt="" />
                </div>
                <div style={{ lineHeight: 1 }} className="flex flex-col pl-2">
                  <span>Coca Cola</span>
                  <span className="text-[#959595]">
                    {selectedOption === "Direct Funding Request"
                      ? "Supplier"
                      : "Buyer"}
                  </span>
                </div>
              </div>
              <div>
                <div style={{ lineHeight: 1 }} className="flex flex-col pl-2">
                  <span className="text-[#959595] flex justify-end">1.5%</span>
                  <span className="text-[#959595]">
                    {/* Discount rate */}
                    {selectedOption === "Direct Funding Request"
                      ? "  Discount rate"
                      : "Funder's locked rate"}
                  </span>
                </div>
              </div>
            </div>
            <br />
            <div className="flex justify-between">
              <div style={{ lineHeight: 1.3 }} className="flex flex-col pl-2">
                <span>₦90,000</span>
                <span className="text-[#959595]">Discount Amount</span>
              </div>
              <div>
                <div style={{ lineHeight: 1.3 }} className="flex flex-col pl-2">
                  <span className="flex justify-end">
                    23-DEC-2025 (90 Days)
                  </span>
                  <span className="flex justify-end text-[#959595]">
                    {selectedOption === "Direct Funding Request"
                      ? " Early Payment Date"
                      : "Request Date"}
                  </span>
                </div>
              </div>
            </div>
            <br />
            <div className="flex justify-between">
              <div style={{ lineHeight: 1.3 }} className="flex flex-col pl-2">
                <span className="text-[#959595]">₦70000</span>
                <span className="text-[#959595]">
                  {selectedOption === "Direct Funding Request"
                    ? " Request Amount"
                    : "  Total Request Amount"}
                </span>
              </div>
              <div>
                <div style={{ lineHeight: 1.3 }} className="flex flex-col pl-2">
                  <span className="flex justify-end">
                    {selectedOption === "Direct Funding Request"
                      ? " ₦65000 Request"
                      : "Bid Status"}
                  </span>
                  <span className="text-[#959595]">
                    {selectedOption === "Direct Funding Request"
                      ? " Payable Amount"
                      : "Bid ended"}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <br />
          <hr className="text-[#959595]/30" />
          <br />
          <div className="flex flex-col">
            <span className="font-bold text-[#000000]">
              Funder's Personal Information
            </span>
            <span className="text-[#959595]">
              {earlyPaymentData?.notesFromSupplier}
            </span>
          </div>

          <br />
          {/* <hr className="text-[#959595]" /> */}
          <br />

          <div>
            <div>
              <div className="flex flex-col">
                <span style={{ color: "grey" }}>Funders name</span>
                <span>Dangote Group LTD</span>
              </div>

              <div className="flex justify-between pt-2">
                <div className="flex flex-col">
                  <span style={{ color: "grey" }}>Email</span>
                  <span>www.skyrunltd.com</span>
                </div>
                <div className="flex flex-col">
                  <span style={{ color: "grey" }}>Contact Number</span>
                  <span>+2348192828919</span>
                </div>
              </div>

              <div className="flex justify-between pt-2 w-[93%]">
                <div className="flex flex-col">
                  <span style={{ color: "grey" }}>Account Number</span>
                  <span>88718718181</span>
                </div>
                <div className="flex flex-col">
                  <span style={{ color: "grey" }}>Bank Name</span>
                  <span>PayEdge MFB</span>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </DashboardLayout>
  );
};
export default LienedClosedMarketReimbursementWithoutRecourseDetails;
