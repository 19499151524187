import {
  // PAIconIconLeftPointer,
  PAIconIconLineLeftArrow,
} from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
// import { useSelector } from "react-redux";

function SimpleCard({ summary }) {
  // const { buyerDashboardData } = useSelector((state) => state.buyer);

  const navigate = useNavigate();
  return (
    <div className=" w-full mx-auto">
      <div className="lg:flex-1 px-2  py-8   rounded-md bg-white w-full">
        <div className="flex flex-wrap gap-4 mt-4 w-full justify-center">
          {/* {cards.map((card, index) => ( */}
          <div
            // key={index}

            onClick={() => navigate("/invoice/invoice")}
            className="w-full  xl:w-[48%]  bg-white rounded shadow-md border border-[#065f46]  flex  flex-col pb-1"
          >
            <div className="items-center  flex  w-full  justify-center px-2 pt-3">
              <div className="flex items-center gap-3">
                <div className={`text-4xl font-bold  text-[#065f46]`}>
                  {summary?.data?.stats?.totalInvoices}
                </div>
                <div className="text-[#065f46] text-sm font-medium w-32">
                  {"Total Invoices"}
                </div>
              </div>
            </div>

            <div className=" w-full justify-end items-end flex  px-2 ">
              <PAIconIconLineLeftArrow />
            </div>
          </div>

          <div
            // key={index}

            onClick={() => navigate("/payablefinancing/overview")}
            className="w-full  xl:w-[48%]  bg-white rounded shadow-md border border-[#065f46]  flex  flex-col pb-1"
          >
            <div className="items-center  flex  w-full  justify-center px-2 pt-3">
              <div className="flex items-center gap-3">
                <div className={`text-4xl font-bold text-[#065f46] `}>
                  {summary?.data?.stats?.pendingEarlyPaymentRequest}
                </div>
                <div className="text-[#065f46] text-sm font-medium w-32">
                  {"Pending Early Payment Request"}
                </div>
              </div>
            </div>

            <div className=" w-full justify-end items-end flex  px-2 ">
              <PAIconIconLineLeftArrow />
            </div>
          </div>

          <div
            // key={index}

            onClick={() => navigate("/invoice/invoice")}
            className="w-full  xl:w-[48%]  bg-white rounded shadow-md border border-[#065f46]  flex  flex-col pb-1"
          >
            <div className="items-center  flex  w-full  justify-center px-2 pt-3">
              <div className="flex items-center gap-3">
                <div className={`text-4xl font-bold text-[#065f46] `}>
                  {summary?.data?.stats?.invoicesPendingApproval}
                </div>
                <div className="text-[#065f46] text-sm font-medium w-32">
                  {"Invoices Pending Approval"}
                </div>
              </div>
            </div>

            <div className=" w-full justify-end items-end flex  px-2 ">
              <PAIconIconLineLeftArrow />
            </div>
          </div>

          <div
            // key={index}/factoring/openmarket

            onClick={() => navigate("/factoring/openmarket")}
            className="w-full  xl:w-[48%]  bg-white rounded shadow-md border border-[#065f46]  flex  flex-col pb-1"
          >
            <div className="items-center  flex  w-full  justify-center px-2 pt-3">
              <div className="flex items-center gap-3">
                <div className={`text-4xl font-bold text-[#065f46] `}>
                  {summary?.data?.stats?.buyerConfirmation}
                </div>
                <div className="text-[#065f46] text-sm font-medium w-32">
                  {"Buyer confirmation"}
                </div>
              </div>
            </div>

            <div className=" w-full justify-end items-end flex  px-2 ">
              <PAIconIconLineLeftArrow />
            </div>
          </div>
          {/* ))} */}
        </div>
      </div>
    </div>
  );
}

// function Card({ amount = 0, title, color }) {
//   const cardStyle = {
//     backgroundColor: color,
//   };
//   return (
//     <div
//       style={cardStyle}
//       className={` cursor-pointer mb-2 flex flex-col  justify-center items-center text-white py-10  w-full md:w-[156px] h-[69px] rounded-md transition duration-500 transform hover:scale-105 ease-in-out`}
//     >
//       <div className=" flex flex-row justify-between gap-3  items-center ">
//         <span className="block font-semibold pl-2 text-4xl md:text-[2rem] lg:text-[2.5rem] ">
//           {amount}
//         </span>
//         <span className="block pr-2 text-sm">{title}</span>
//       </div>

//       <span className="w-full flex px-[5%] justify-end ">
//         <img src="/arrow-lead.svg" alt="" />
//       </span>
//     </div>
//   );
// }

export default SimpleCard;
