import React from "react";
import ModalContents from "./ModalContents";

const PayableFinanceModal = ({
  isShown,
  hide,
  userDetails,
  setPayableFinanceUpdateAction,
}) => {
  return (
    <>
      {isShown && (
        <div className="flex items-center justify-center bg-[rgba(0,0,0,0.3)] absolute w-[100%] h-[100vh] z-10 top-0 left-0">
          <ModalContents
            userDetails={userDetails}
            hide={hide}
            setPayableFinanceUpdateAction={setPayableFinanceUpdateAction}
          />
        </div>
      )}
    </>
  );
};

export default PayableFinanceModal;
