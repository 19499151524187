import { motion } from "framer-motion";

import { PAIconSmallNestle, PAIconInvShop } from "assets/images/svgs";
import TooltipShared from "shared/tooltip/Tooltip";
import { formatCurrency } from "utilities/helper/formatters";
import useCountdown from "utilities/helper/useCountdown";
import { useEffect, useState } from "react";
import { formatCapitaliseString } from "utilities/helper/formatCapitaliseString";
import { useNavigate } from "react-router-dom";

function ReportAndAnalisCard({
  invoiceNumber = "N/A",
  invoiceRef = "N/A",
  supplierCompanyName = "N/A",
  invoiceAmount = "N/A",
  numberofBidders = "N/A",
  invoiceDueDate = "N/A",
  requestType = "N/A",
  doerType = "N/A",
  lockRate = "-",
  status = "Bid Active",
  id,
}) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);

    setTimeout(() => setIsLoading(false), 1000);
  }, []);
  const { timeLeft, isTimeUnderOneHour } = useCountdown(invoiceDueDate);
  return (
    <motion.div
      onClick={() => {
        navigate(
          `${
            requestType === "BULK"
              ? `/closedmarket/requestsdetails-bulk/${id}`
              : `/closedmarket/requestsdetails`
          }`
        );
      }}
      whileTap={{ scale: 0.98 }}
      style={{ boxShadow: "0px 2px 4px 0px rgba(232, 226, 216, 0.36)" }}
      className="flex cursor-pointer justify-between bg-white py-5 px-6 rounded-[10px]"
    >
      <span className="flex gap-6  items-center">
        <PAIconInvShop />
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">
            <TooltipShared maxLength={15} text={invoiceRef} />
          </span>
          <span className=" text-[#959595] text-[10px]">Reference number</span>
        </div>
      </span>

      <span className="flex gap-6  items-center">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">
            {" "}
            <TooltipShared maxLength={15} text={`INV-${invoiceNumber}`} />
          </span>
          <span className=" text-[#959595] text-[10px]">Invoice number</span>
        </div>
      </span>

      <span className="flex gap-6  items-center">
        <div
          style={
            requestType === "BULK"
              ? { backgroundColor: "#E1EBE8" }
              : { backgroundColor: "#FDF0E0" }
          }
          className="flex flex-col justify-center w-[120px] p-2 rounded-[8px] text-center"
        >
          <span className="font-[500] whitespace-nowrap text-sm">
            {requestType === "BULK" ? "Bulk Request" : "Single Request"}
          </span>
        </div>
      </span>

      <span className="flex gap-6 items-center ">
        <PAIconSmallNestle />

        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">
            <TooltipShared text={supplierCompanyName} maxLength={15} />
          </span>
          <span className=" text-[#959595] text-[10px]">Supplier</span>
        </div>
      </span>

      <span className="hidden extra-md:flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span class="text-secondaryColor text-sm font-medium font-['SF Pro Display']">
            Bid Active
          </span>
          <span class="text-[#959595] text-[10px] font-normal font-['SF Pro Display']">
            Status of Request
          </span>
        </div>
      </span>

      <span className="hidden extra-md:flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span class="text-[#07593D] text-sm font-medium font-['SF Pro Display']">
            {formatCurrency(invoiceAmount)}
          </span>
          <span class="text-[#959595] text-[10px] font-normal font-['SF Pro Display']">
            Request Amount
          </span>
        </div>
      </span>

      <span className="hidden extra-md:flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span class="text-[#07593D] text-sm font-medium font-['SF Pro Display']">
            {numberofBidders}
          </span>
          <span class="text-[#959595] text-[10px] font-normal font-['SF Pro Display']">
            No of Bidders
          </span>
        </div>
      </span>

      <span className="hidden extra-md:flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span class="text-sm font-medium font-['SF Pro Display']">
            {lockRate}%
          </span>
          <span class="text-[#959595] text-[10px] font-normal font-['SF Pro Display']">
            Lock Rate
          </span>
        </div>
      </span>

      <span className="flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{invoiceAmount}</span>
          <span className=" text-[#959595] text-[10px]">Invoice Amount</span>
        </div>
      </span>

      <span className="w-[135px] hidden extra-md:flex gap-6">
        <div className="flex flex-col justify-center">
          <span
            className={`text-sm font-bold ${
              isTimeUnderOneHour ? "text-red-500" : ""
            }`}
          >
            {(timeLeft.days > 0 ||
              timeLeft.hours > 0 ||
              timeLeft.minutes > 0 ||
              timeLeft.seconds > 0) &&
            formatCapitaliseString(status) !== "Cancelled" ? (
              `${timeLeft.days}d:${timeLeft.hours}h:${timeLeft.minutes}m:${timeLeft.seconds}s`
            ) : isLoading ? (
              <span className="text-secondaryColor">Fetching...</span>
            ) : formatCapitaliseString(status) === "Cancelled" ? (
              <span className="text-red">Cancelled</span>
            ) : (
              <span className="text-red">Expired</span>
            )}
          </span>
          <span className="text-[#959595] text-[10px]">
            Count down to payment date
          </span>
        </div>
      </span>
    </motion.div>
  );
}

export default ReportAndAnalisCard;
