import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  approveInvoice,
  approveSupplierInvoice,
  createInvoice,
  deleteCreatedInvoice,
  deletePendingUploadInvoice,
  getAllInvoiceById,
  getAllOpenMarket,
  getAllPayablesInvoice,
  getAllPendingUploadInvoice,
  getAllRecentActivitiesInvoices,
  getAllSupplierInvoices,
  getBuyerInvoicesByStatus,
  getDirectFundings,
  getEarlyPayments,
  getFundSectionOverview,
  getInvoiceById,
  getInvoiceByStatus,
  getInvoiceDashboard,
  getPlayerInvoices,
  getSupplierInvoiceById,
  submitCreatedInvoice,
  updateInvoice,
  uploadInvoice,
} from "appstate/api/invoice/invoice";

export const createNewInvoice = createAsyncThunk(
  "invoice/createNewInvoice",
  createInvoice
);
export const updateNewInvoice = createAsyncThunk(
  "invoice/updateNewInvoice",
  updateInvoice
);
export const getPlayerInvoiceById = createAsyncThunk(
  "invoice/getPlayerInvoiceById",
  getInvoiceById
);
export const getAllPlayerInvoices = createAsyncThunk(
  "invoice/getAllPlayerInvoices",
  getPlayerInvoices
);
export const getPlayerInvoiceByStatus = createAsyncThunk(
  "invoice/getPlayerInvoiceByStatus",
  getInvoiceByStatus
);
export const invoiceDashboardData = createAsyncThunk(
  "invoiceDashboardData",
  getInvoiceDashboard
);
export const allRecentActivitiesData = createAsyncThunk(
  "allRecentActivitiesData",
  getAllRecentActivitiesInvoices
);
export const allSupplierInvoiceData = createAsyncThunk(
  "allSupplierInvoiceData",
  getAllSupplierInvoices
);
export const allBuyerInvoiceDataByStatus = createAsyncThunk(
  "allBuyerInvoiceDataByStatus",
  getBuyerInvoicesByStatus
);
export const fundingSectionOverview = createAsyncThunk(
  "fundingSectionOverview",
  getFundSectionOverview
);
export const allOpenMarket = createAsyncThunk(
  "allOpenMarket",
  getAllOpenMarket
);
export const allEarlyPayment = createAsyncThunk(
  "allEarlyPayment",
  getEarlyPayments
);
export const allDirectFunding = createAsyncThunk(
  "allDirectFunding",
  getDirectFundings
);
export const allSupplierInvoiceById = createAsyncThunk(
  "allSupplierInvoiceById",
  getSupplierInvoiceById
);
export const allInvoiceById = createAsyncThunk(
  "allInvoiceById",
  getAllInvoiceById
);
export const invoiceApprove = createAsyncThunk(
  "invoiceApprove",
  approveInvoice
);
export const supplierInvoiceApprove = createAsyncThunk(
  "supplierInvoiceApprove",
  approveSupplierInvoice
);
export const payablesInvoice = createAsyncThunk(
  "payablesInvoice",
  getAllPayablesInvoice
);
export const pendingUploadInvoice = createAsyncThunk(
  "pendingUploadInvoice",
  getAllPendingUploadInvoice
);

export const cancelPendingUploadInvoice = createAsyncThunk(
  "cancelPendingUploadInvoice",
  deletePendingUploadInvoice
);

export const cancelCreatedInvoice = createAsyncThunk(
  "cancelCreatedInvoice",
  deleteCreatedInvoice
);

export const putCreatedInvoice = createAsyncThunk(
  "putCreatedInvoice",
  submitCreatedInvoice
);

export const invoiceUpload = createAsyncThunk("invoiceUpload", uploadInvoice);

const initialState = {
  isLoading: false,
  playerInvoices: [],
  singleInvoice: null,
  dashboardData: [],
  activeButton: "single",
  savedCsvData: [],
  recentActivitiesData: [],
  supplierInvoiceData: [],
  buyerInvoiceDataByStatus: [],
  fundingSectionOverviewData: null,
  openMarketData: [],
  earlyPaymentData: [],
  directFundingData: [],
  supplierInvoiceIdData: null,
  invoiceIdData: null,
  invoiceForApprove: [],
  payablesInvoiceData: [],
  pendingUploadInvoiceData: [],
};

export const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    getSingleInvoice: (state, action) => {
      state.singleInvoice = action.payload;
    },
    setActiveButton: (state, action) => {
      state.activeButton = action.payload;
    },
    getInvoiceForApprove: (state, action) => {
      state.invoiceForApprove = action.payload;
    },
    getCsvFileData: (state, action) => {
      state.savedCsvData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createNewInvoice.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createNewInvoice.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createNewInvoice.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateNewInvoice.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateNewInvoice.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateNewInvoice.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getPlayerInvoiceById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPlayerInvoiceById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.singleInvoice = action.payload;
    });
    builder.addCase(getPlayerInvoiceById.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getPlayerInvoiceByStatus.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPlayerInvoiceByStatus.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getPlayerInvoiceByStatus.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getAllPlayerInvoices.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllPlayerInvoices.fulfilled, (state, action) => {
      state.isLoading = false;
      state.playerInvoices = action.payload;
    });
    builder.addCase(getAllPlayerInvoices.rejected, (state) => {
      state.isLoading = false;
    });
    // INVOICE DASHBOARD(SUP)
    builder.addCase(invoiceDashboardData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(invoiceDashboardData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.dashboardData = action.payload;
    });
    builder.addCase(invoiceDashboardData.rejected, (state) => {
      state.isLoading = false;
    });
    // RECENT ACTIVITIES DATA
    builder.addCase(allRecentActivitiesData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allRecentActivitiesData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.recentActivitiesData = action.payload;
    });
    builder.addCase(allRecentActivitiesData.rejected, (state) => {
      state.isLoading = false;
    });
    // ALL SUPPLIER INVOICE DATA
    builder.addCase(allSupplierInvoiceData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allSupplierInvoiceData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.supplierInvoiceData = action.payload;
    });
    builder.addCase(allSupplierInvoiceData.rejected, (state) => {
      state.isLoading = false;
    });
    // ALL BUYER INVOICE DATA BY STATUS
    builder.addCase(allBuyerInvoiceDataByStatus.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allBuyerInvoiceDataByStatus.fulfilled, (state, action) => {
      state.isLoading = false;
      state.buyerInvoiceDataByStatus = action.payload;
    });
    builder.addCase(allBuyerInvoiceDataByStatus.rejected, (state) => {
      state.isLoading = false;
    });
    // FundingSectionOverview
    builder.addCase(fundingSectionOverview.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fundingSectionOverview.fulfilled, (state, action) => {
      state.isLoading = false;
      state.fundingSectionOverviewData = action.payload;
    });
    builder.addCase(fundingSectionOverview.rejected, (state) => {
      state.isLoading = false;
    });
    // OPEN MARKET TABLE
    builder.addCase(allOpenMarket.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allOpenMarket.fulfilled, (state, action) => {
      state.isLoading = false;
      state.openMarketData = action.payload;
    });
    builder.addCase(allOpenMarket.rejected, (state) => {
      state.isLoading = false;
    });
    // EARLY PAYMENT TABLE
    builder.addCase(allEarlyPayment.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allEarlyPayment.fulfilled, (state, action) => {
      state.isLoading = false;
      state.earlyPaymentData = action.payload;
    });
    builder.addCase(allEarlyPayment.rejected, (state) => {
      state.isLoading = false;
    });
    // DIRECT FUNDING TABLE
    builder.addCase(allDirectFunding.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allDirectFunding.fulfilled, (state, action) => {
      state.isLoading = false;
      state.directFundingData = action.payload;
    });
    builder.addCase(allDirectFunding.rejected, (state) => {
      state.isLoading = false;
    });
    // SUPPLIER BY ID
    builder.addCase(allSupplierInvoiceById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allSupplierInvoiceById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.supplierInvoiceIdData = action.payload;
    });
    builder.addCase(allSupplierInvoiceById.rejected, (state) => {
      state.isLoading = false;
    });
    // INVOICE BY ID
    builder.addCase(allInvoiceById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allInvoiceById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.invoiceIdData = action.payload;
    });
    builder.addCase(allInvoiceById.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(invoiceApprove.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(invoiceApprove.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(invoiceApprove.rejected, (state) => {
      state.isLoading = false;
    });

    // SUPPLIER INVOICE APPROVE
    builder.addCase(supplierInvoiceApprove.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(supplierInvoiceApprove.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(supplierInvoiceApprove.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(invoiceUpload.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(invoiceUpload.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(invoiceUpload.rejected, (state) => {
      state.isLoading = false;
    });
    // PAYABLES INVOICES
    builder.addCase(payablesInvoice.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(payablesInvoice.fulfilled, (state, action) => {
      state.isLoading = false;
      state.payablesInvoiceData = action.payload;
    });
    builder.addCase(payablesInvoice.rejected, (state) => {
      state.isLoading = false;
    });
    // PENDING UPLOAD INVOICES
    builder.addCase(pendingUploadInvoice.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(pendingUploadInvoice.fulfilled, (state, action) => {
      state.isLoading = false;
      state.pendingUploadInvoiceData = action.payload;
    });
    builder.addCase(pendingUploadInvoice.rejected, (state) => {
      state.isLoading = false;
    });
    // DELETE PENDING UPLOAD INVOICES
    builder.addCase(cancelPendingUploadInvoice.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(cancelPendingUploadInvoice.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(cancelPendingUploadInvoice.rejected, (state) => {
      state.isLoading = false;
    });
    // DELETE CREATED INVOICES
    builder.addCase(cancelCreatedInvoice.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(cancelCreatedInvoice.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(cancelCreatedInvoice.rejected, (state) => {
      state.isLoading = false;
    });
    // SUBMIT CREATED INVOICES
    builder.addCase(putCreatedInvoice.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(putCreatedInvoice.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(putCreatedInvoice.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const {
  getSingleInvoice,
  setActiveButton,
  getInvoiceForApprove,
  getCsvFileData,
} = invoiceSlice.actions;

export default invoiceSlice.reducer;
