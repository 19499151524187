import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import DisbursementHeader from "../common/DisbursementHeader";
import Overview from "./overview/Overview";
import DirectFunding from "./directFunding";

import EarlyPaymentDisburesement from "./earlyPayment";
import OpenMarketDisbursement from "./openMarket";
import ClosedMarketDisbursement from "./closedMarket";
import TransactionHistory from "./transactionHistory/index";
import { useDispatch, useSelector } from "react-redux";

import { allVirtualAccounts } from "appstate/wallet/walletSlice";
// import ScrollToTop from "../common/components/ScrollToTop";

function DisbursementFunderDashboard() {
  const dispatch = useDispatch();
  const { allVirtualAccountsData } = useSelector((state) => state?.wallet);
  const [page, setPage] = useState(0);
  const pageSize = 10;
  const [activeTab, setActiveTab] = useState(
    parseInt(sessionStorage.getItem("activeTabDisbursementFunder")) || 1
  );
  const [directFunding, setDirectFunding] = useState("direct-funding-request");

  const selectedProgram =
    activeTab === 2
      ? directFunding
      : activeTab === 3
      ? "early-payment-offers"
      : activeTab === 4
      ? "open-market"
      : activeTab === 5
      ? "closed-market"
      : "";

  const [selectedVAId, setSelectedVAId] = useState(
    allVirtualAccountsData?.data[0]?.virtualAccountId
  );

  useEffect(() => {
    if (
      activeTab === 2 ||
      activeTab === 3 ||
      activeTab === 4 ||
      activeTab === 5
    ) {
      dispatch(allVirtualAccounts());
    }
  }, [dispatch, selectedProgram, selectedVAId, activeTab, page, pageSize]);

  return (
    <div className="flex flex-col gap-8">
      <>
        <DisbursementHeader
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          setPage={setPage}
        />

        <main className="w-full">
          <AnimatePresence mode="wait">
            {activeTab === 1 ? (
              <Overview
                setActiveTab={setActiveTab}
                selectedVAId={selectedVAId}
              />
            ) : activeTab === 2 ? (
              <DirectFunding
                setActiveTab={setActiveTab}
                selectedVAId={selectedVAId}
                setSelectedVAId={setSelectedVAId}
                directFundingState={directFunding}
                setDirectFundingState={setDirectFunding}
              />
            ) : activeTab === 3 ? (
              <div>
                <EarlyPaymentDisburesement
                  page={page}
                  setPage={setPage}
                  setActiveTab={setActiveTab}
                  selectedVAId={selectedVAId}
                  setSelectedVAId={setSelectedVAId}
                />
              </div>
            ) : activeTab === 4 ? (
              <div>
                <OpenMarketDisbursement
                  setActiveTab={setActiveTab}
                  page={page}
                  setPage={setPage}
                  pageSize={pageSize}
                  selectedVAId={selectedVAId}
                  setSelectedVAId={setSelectedVAId}
                />
              </div>
            ) : activeTab === 5 ? (
              <div>
                <ClosedMarketDisbursement
                  page={page}
                  setPage={setPage}
                  setActiveTab={setActiveTab}
                  selectedVAId={selectedVAId}
                  setSelectedVAId={setSelectedVAId}
                />
              </div>
            ) : (
              <TransactionHistory />
            )}
          </AnimatePresence>
        </main>
      </>
    </div>
  );
}

export default DisbursementFunderDashboard;
