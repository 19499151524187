import React from "react";

import WonBidCard from "./card/WonBidCard";
import Pagination from "shared/Pagination";
import { useState } from "react";

const WonBid = ({ closedMarketQuery }) => {
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);

  const data = [
    {
      id: "1",
      invoiceNumber: "R3456X89Y",
      buyerName: "ABC Limited",
      invoiceType: "Single Invoice",

      lockRate: "2.3%",
      statusOfInvoice: "Locked",
      requestAmount: "N35,847,000",
      dueDate: "2023-12-01",
      supplierName: "Coca Cola",
      earlyPaymentDate: "2023-11-01",
    },
    {
      id: "2",
      invoiceNumber: "R3456X89Y",
      buyerName: "Tacna Plc.",
      invoiceType: "Bulk Invoice",

      lockRate: "1.1%",
      statusOfInvoice: "Locked",
      requestAmount: "N35,847,000",
      dueDate: "2023-12-01",
      supplierName: "Defi",
      earlyPaymentDate: "2023-11-01",
    },
    {
      id: "3",
      invoiceNumber: "R3456X89Y",
      buyerName: "ABC Limited",
      invoiceType: "Single Invoice",

      lockRate: "2.3%",
      statusOfInvoice: "Locked",
      requestAmount: "N35,847,000",
      dueDate: "2023-12-01",
      supplierName: "Nestle",
      earlyPaymentDate: "2023-11-01",
    },
  ];

  const totalPages = data.length;
  return (
    <div>
      <div className="flex flex-col  rounded-[10px] bg-unusualGray  px-6 mt-2 py-7 lg:min-h-[750px] md:min-h-[750px]">
        {/* <div className="flex flex-col gap-2.5 "> */}
        <div className="w-full  overflow-x-auto  ">
          <div className="flex flex-col gap-2.5 ">
            {data.map((bids, idx) => (
              <WonBidCard
                key={idx}
                id={bids.id}
                invoiceNumber={bids.invoiceNumber}
                buyerName={bids.buyerName}
                invoiceType={bids.invoiceType}
                lockRate={bids.lockRate}
                statusOfInvoice={bids.statusOfInvoice}
                requestAmount={bids.requestAmount}
                dueDate={bids?.dueDate}
                earlyPaymentDate={bids.earlyPaymentDate}
                supplierName={bids.supplierName}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="flex justify-center gap-x-[8px] mt-[20px]">
        {totalPages > 2 && (
          <Pagination
            page={page}
            startPage={startPage}
            setEndPage={setEndPage}
            endPage={endPage}
            setStartPage={setStartPage}
            setPage={setPage}
            totalPages={totalPages}
          />
        )}
      </div>
    </div>
  );
};

export default WonBid;
