// import { PAIconSearchGreen } from "assets/images/svgs";
import Card from "shared/Card";
// import { motion } from "framer-motion";
import { motion } from "framer-motion";
import { PAIconImportPlus } from "assets/images/svgs";
import { useNavigate } from "react-router-dom";

function OpenMarketHeader({
  activeTab,
  setActiveTab,
  searchQuery,
  setSearchQuery,
}) {
  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
    sessionStorage.setItem("activeTabSupplier", tabNumber);
  };

  const navigate = useNavigate();

  // const handleSearchInputChange = (event) => {
  //   const value = event.target.value;
  //   setSearchQuery(value);
  //   sessionStorage.setItem("searchQuerySupplier", value);
  // };

  return (
    <Card className="px-2 rounded-t-[5px] h-16 flex items-center justify-between border-b bg-white border-b-subtleGray">
      <div className="flex h-full items-center overflow-x-auto space-x-3">
        <div
          className={`cursor-pointer relative h-full pt-5 text-sm overflow-hidden  text-[#959595] ${
            activeTab === 1 ? " text-primaryColor font-bold" : "text-gray-400"
          }`}
          onClick={() => handleTabClick(1)}
        >
          <span className="px-5 text-center flex justify-center w-full">
            Pending Buyer Confirmation
          </span>

          {activeTab === 1 && (
            <div className="absolute bottom-0 left-0 w-full  h-0.5  rounded bg-primaryColor transition-transform duration-300 ease-in-out"></div>
          )}
        </div>

        <div
          className={`cursor-pointer relative h-full pt-5 text-sm overflow-hidden  text-[#959595] ${
            activeTab === 2 ? " text-primaryColor font-bold" : "text-gray-400"
          }`}
          onClick={() => handleTabClick(2)}
        >
          <span className="px-5 text-center flex justify-center w-full">
            My Invoices
          </span>

          {activeTab === 2 && (
            <div className="absolute bottom-0 left-0 w-full  h-0.5  rounded bg-primaryColor transition-transform duration-300 ease-in-out"></div>
          )}
        </div>

        <div
          className={`cursor-pointer relative h-full pt-5 text-center text-sm overflow-hidden  text-[#959595] ${
            activeTab === 3 ? "text-primaryColor font-bold" : "text-gray-400"
          }`}
          onClick={() => handleTabClick(3)}
        >
          <span className="px-5 text-center flex justify-center w-full ">
            Pending Funding
          </span>
          {activeTab === 3 && (
            <div className="absolute bottom-0 left-0 w-full h-0.5  rounded bg-primaryColor transition-transform duration-300 ease-in-out"></div>
          )}
        </div>

        {/* <div
          className={`cursor-pointer relative  h-full overflow-hidden  pt-5 text-sm text-[#959595] ${
            activeTab === 3 ? "text-primaryColor font-bold" : "text-gray-400"
          }`}
          onClick={() => handleTabClick(3)}
        >
          <span className="px-5 flex justify-center text-center w-full">
            Locked
          </span>
          {activeTab === 3 && (
            <div className="absolute bottom-0 left-0 w-full h-0.5  rounded bg-primaryColor transition-transform duration-300 ease-in-out"></div>
          )}
        </div> */}

        <div
          className={`cursor-pointer relative   h-full pt-5 overflow-hidden  text-sm text-[#959595] ${
            activeTab === 4 ? "text-primaryColor font-bold" : "text-gray-400"
          }`}
          onClick={() => handleTabClick(4)}
        >
          <span className="px-5 text-center flex justify-center  w-full">
            Report and Analysis
          </span>
          {activeTab === 4 && (
            <div className="absolute bottom-0 left-0 w-full h-0.5  rounded bg-primaryColor transition-transform duration-300 ease-in-out"></div>
          )}
        </div>
      </div>

      <div className="flex items-center gap-3">
        {/* <motion.button
          whileTap={{ scale: 0.9 }}
          onClick={() => {
            navigate("/invoice/invoice/fundingSection");
          }}
          className="rounded-[5px] flex items-center gap-2 text-sm border text-primaryColor border-primaryColor  p-2.5"
        >
          <PAIconSuccessCash />
          Funding Section
        </motion.button> */}

        <motion.button
          onClick={() => {
            navigate("/open-market/invoices/import");
          }}
          whileTap={{ scale: 0.9 }}
          className="rounded-[5px] text-sm bg-[#FFBE55] flex items-center gap-2 text-white px-5 py-2.5"
        >
          <PAIconImportPlus />
          Import Invoice
        </motion.button>
      </div>
    </Card>
  );
}

export default OpenMarketHeader;
