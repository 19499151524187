import {
  EditInvoice,
getSupplierInvoiceById,
getVirtualAccounts,
} from "appstate/api/invoice/invoice";
import { getAllSupplierAssociatedBuyers } from "appstate/api/supplier/relationshipManagement";
import DashboardLayout from "layout/dashboardlayout";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import Card from "shared/Card";
import EditControls from "./EditControls";
import EditInvoiceTable from "./EditNewInvoiceTable";
import ValidatingModal from "features/BuyerEarlyPayment/EarlyPayment/singleEarlyPayment/Modals/ValidatingMappingModal";
import AssociationSuccessModal from "shared/associationsuccessmodal/associationsuccessmodal";

const EditNewInvoiceIndex = () => {
const location = useLocation();
const id = location.state;
const [consentInvoice, setConsentInvoice] = useState(false);
const [discard, setDiscard] = useState(false);
const navigate = useNavigate();
const [invoiceData, setInvoiceData] = useState([]);
const [virtualAccounts, setVirtualAccounts] = useState([]);
const [associationSuccessful, setAssociationSuccessful] = useState(false);
const [editing, setEditing] = useState(false);
const [controls, setControls] = useState({
  to: "",
  dueDate: "",
  issuanceDate: "",
  currency: "",
  account: "",
  fundingAccountId: "",
  taxInclusive: "",
  items: [
    {
      id: "",
      itemName: "",
      description: "",
      unitPrice: "",
      taxRate: "",
      quantity: "",
      taxAmount: "",
      totalPrice: "",
    },
  ],
  discount: "",
  vat: "",
});

const [toggleTaxInclusive, setToggleTaxInclusive] = useState(
  invoiceData?.taxInclusive
);

const [buyers, setBuyers] = useState([]);

// eslint-disable-next-line
const [page, setPage] = useState("");
// eslint-disable-next-line
const [pageSize, setPageSize] = useState("");
// eslint-disable-next-line
const [search, setSearch] = useState("");

useEffect(() => {
  const fetchAssociatedBuyers = async () => {
    try {
      const result = await getAllSupplierAssociatedBuyers({
        page,
        pageSize,
        search,
      });
      setBuyers(result?.data || []);
    } catch (error) {
      console.error("Error fetching associated buyers:", error);
    }
  };

  fetchAssociatedBuyers();
}, [page, pageSize, search]);



const fetchInvoiceById = async () => {
  try {
    const response = await getSupplierInvoiceById({ id });
    setInvoiceData(response?.data);
    setControls((prevControls) => ({
      ...prevControls,
      to: response?.data?.buyerId,
      dueDate: response?.data?.dueDate,
      issuanceDate: response?.data?.issuanceDate,
      // currency: response?.data?.currency,
      fundingAccountId: response?.data?.fundingAccount?.id || "",
      taxInclusive: response?.data?.taxInclusive,
      discount: response?.data?.discount,
      vat: response?.data?.vat,
      items: response?.data?.items?.map((item) => ({
        id: item?.itemName,
        itemName: item?.itemName,
        description: item.description,
        unitPrice: item?.unitPrice,
        taxRate: item?.taxRate,
        quantity: item?.quantity,
        amount: item.amount || 0,
        taxAmount: item.taxAmount || 0,
        totalPrice: item.totalPrice || 0
      })),
    }));
  } catch (error) {
    console.error(error, "error");
    toast.error("Failed to fetch invoice data!");
  }
};

useEffect(() => {
  fetchInvoiceById();
  // eslint-disable-next-line
}, []);

useEffect(() => {
  const fetchVirtualAccounts = async () => {
    try {
      const result = await getVirtualAccounts();
      setVirtualAccounts(result?.data || []);
    } catch (error) {
      console.error("Error fetching associated buyers:", error);
    }
  };

  fetchVirtualAccounts();
}, []);

const curr = controls?.currency.split(" ");
const currencyAbv = curr[curr.length - 1].replace(/[()]/g, "");

const EditInvoiceSupplier = async () => {
  const total = controls.items.reduce((accumulator, item) => {
    const unitPrice = parseFloat(item.unitPrice);
    const quantity = parseFloat(item.quantity);
    const taxRate = parseFloat(item.taxRate);
    const itemSubTotal = unitPrice * quantity;

    const taxAmount =  (itemSubTotal * taxRate) / 100;

    const tp = itemSubTotal + taxAmount;
    return accumulator + tp;
  }, 0);

  const formattedTotal = total.toFixed(2);
  const disAmount = formattedTotal * (controls.discount / 100);
  const netAmounts = formattedTotal - disAmount;
  const vatAmounts = netAmounts * (controls.vat / 100);
  const invAmounts = netAmounts + vatAmounts;

  const payload = {
    dueDate: controls.dueDate,
    buyerId: controls.to,
    currency: currencyAbv,
    invoiceNo: "",
    items: controls.items.map((item) => {
      const unitPrice = parseFloat(item.unitPrice);
      const quantity = parseFloat(item.quantity);
      const taxRate = parseFloat(item.taxRate);
      const itemSubTotal = unitPrice * quantity;

      const taxAmount = (itemSubTotal * taxRate) / 100
      const tp = itemSubTotal + taxAmount;
      return {
        itemName: item.itemName,
        description: item.description,
        unitPrice,
        quantity,
        amount: itemSubTotal,
        taxRate,
        taxAmount,
        totalPrice: tp.toFixed(2),
      };
    }),
    taxInclusive: toggleTaxInclusive,
    fundingAccountId: virtualAccounts[0]?.id,
    vat: controls.vat,
    discount: controls.discount,
    invoiceAmount: invAmounts.toFixed(2),
    subTotal: formattedTotal,
    issuanceDate: new Date().toISOString().split("T")[0],
  };
  try {
      if(!controls.currency){
        toast.error("Currency not selected");
        return;
      }
      const result = await EditInvoice(id, payload);
      if (result?.status === 200) {
        setConsentInvoice(false);
        setAssociationSuccessful(true);
        setTimeout(() => {
          navigate('/invoice/invoice');
          }, 2000);
      }
  } catch (error) {
      console.error("Error creating invoice:", error);
      toast.error("Failed to create invoice. Please try again.");
  }
};

const handleClose = () => {
  setConsentInvoice(false);
};

const openConsentModal = () => {
  setConsentInvoice(true);
};

const handleDiscard = () => {
  setDiscard(true);
};

const confirmDiscard = () => {
  navigate(-1);
};

const closeDiscard = () => {
  setDiscard(false);
};

const handleCloseModal = () => {
  setAssociationSuccessful(false);
};

return (
  <DashboardLayout
    section="Invoice"
    subHeader="Here is an overview of your invoices"
  >
    {associationSuccessful && (
      <div className="flex justify-center w-full">
        <AssociationSuccessModal
          header="Invoice Creation"
          message="Congratulation your invoice has been updated successfully"
          handleCloseModal={handleCloseModal}
        />
      </div>
    )}
    {consentInvoice && (
      <div className="flex justify-center w-full">
        <ValidatingModal
          handleCloseConfirm={handleClose}
          handleProceed={EditInvoiceSupplier}
          message="Are you sure you would like to edit this invoice ?"
        />
      </div>
    )}
    {discard && (
      <div className="flex justify-center w-full">
        <ValidatingModal
          handleCloseConfirm={closeDiscard}
          handleProceed={confirmDiscard}
          message="Are you sure you would like to discard this invoice ?"
        />
      </div>
    )}

    <Card className="rounded-md">
      <div className="bg-[#FAFAFA] rounded-[10px] px-[25px] py-[10px]">
        <EditControls
          setEditing={setEditing}
          controls={controls}
          setControls={setControls}
          editing={editing}
          buyers={buyers}
          toggleTaxInclusive={toggleTaxInclusive}
          invoiceData={invoiceData}
          setToggleTaxInclusive={setToggleTaxInclusive}
        />
        <EditInvoiceTable
          controls={controls}
          setControls={setControls}
          editing={editing}
          setEditing={setEditing}
          toggleTaxInclusive={toggleTaxInclusive}
          EditInvoiceSupplier={openConsentModal}
          handleDiscard={handleDiscard}
        />
      </div>
    </Card>
  </DashboardLayout>
);
};
export default EditNewInvoiceIndex;
