import { motion } from "framer-motion";
import { PABadgeIcon, PAIconMDISuccess } from "assets/images/svgs";
import React, { useEffect } from "react";

const SuccessModal = ({ isOpen, onClose, message }) => {
  useEffect(() => {
    let timeoutId;

    if (isOpen) {
      timeoutId = setTimeout(() => {
        onClose();
      }, 2000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isOpen, onClose]);

  if (!isOpen) {
    return null;
  }

  return (
    <motion.div
      className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-[1000]"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <motion.div
        className="flex"
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.8, opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <div className="bg-[#07593D] p-10 shadow-md rounded-s text-center text-white">
          <PAIconMDISuccess />
        </div>

        <div className="bg-white py-10 px-3 flex flex-col items-center justify-center shadow-md text-center rounded-e">
          <span className="flex justify-center">
            <PABadgeIcon />
          </span>
          <p className="text-[#959595] text-center text-xs font-normal leading-normal tracking-[0.321px] mb-3 mt-3">
            {message}
          </p>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default SuccessModal;
