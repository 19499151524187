import React from "react";
import DirectRequestModalContent from "./DirectRequestModalContent";

const DirectRequestModal = ({
  isShown,
  onClose,
  data,
  directFunding,
  setShowDirectModal,
  funderId,
  invoiceAmount,
  invoiceId,
  dueDate,
  currency,
  companyName,
  invoiceDate,
}) => {
  if (!isShown) return null;
  const handleModalClick = (e) => e.stopPropagation();
  
  return (
    <div
      className="w-full fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10"
      onClick={onClose}
    >
      <div
        className="bg-white w-full lg:w-[70%] max-h-[80vh] overflow-y-auto rounded-lg shadow-lg p-6"
        onClick={handleModalClick}
      >
        <DirectRequestModalContent
          data={data}
          onClose={onClose}
          directFunding={directFunding}
          setShowDirectModal={setShowDirectModal}
          funderId={funderId}
          invoiceAmount={invoiceAmount}
          invoiceId={invoiceId}
          dueDate={dueDate}
          currency={currency}
          companyName={companyName}
          invoiceDate={invoiceDate}
        />
      </div>
    </div>
  );
};

export default DirectRequestModal;
