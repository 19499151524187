import * as Yup from "yup";

const validBank = (bankNum) => {
  const namePattern = /^(?!([0-9])\1{9})\d{10}$/;
  return namePattern.test(bankNum.trim());
};

export const addBankValidationSchema = Yup.object().shape({
  bankName: Yup.string().required("Bank name is required"),
  countryOfBank: Yup.string().required("Country of bank is required"),
  currency: Yup.string().required("Currency is required"),
  accountNumber: Yup.string()
    .required("Account number is required")
    .test("valid account number", "Invalid Account Number", validBank),
  // accountHolder: Yup.string().required("Account holder name is required"),
  bvn: Yup.string()
    .matches(/^(?!([0-9])\1{10})\d{11}$/, "BVN must be an 11 digit numbers ")

    .required("BVN is required"),
});
