import React from "react";
import DebitCardNew from "./new_debitcard";
import { useSelector } from "react-redux";

const FunderCard = ({ summary }) => {
  // const { funderDashboardData } = useSelector((state) => state.funder);
  const { allVirtualCardsData } = useSelector((state) => state?.wallet);

  const formatCardNumber = (cardNumber) => {
    if (!cardNumber) {
      return "";
    }

    const sanitizedCardNumber = cardNumber.replace(/\D/g, "");
    const maskedCardNumber = sanitizedCardNumber.replace(
      /(\d{4})(\d{4})(\d{4})(\d{4})/,
      (_, firstPart, middlePart1, middlePart2, lastPart) => {
        const maskedMiddlePart = `${middlePart1.replace(
          /\d/g,
          "*"
        )} ${middlePart2.replace(/\d/g, "*")}`;
        return `${firstPart} ${maskedMiddlePart} ${lastPart}`;
      }
    );

    return maskedCardNumber.trim();
  };

  const formatExpiryDate = (expiryDate) => {
    if (!expiryDate) {
      return "";
    }

    const options = { month: "2-digit", year: "2-digit" };
    return new Date(expiryDate).toLocaleDateString(undefined, options);
  };

  const getFirstCardData = () => {
    const cardData = Array.isArray(allVirtualCardsData?.data)
      ? allVirtualCardsData.data.map((card) => ({
          id: card?.id,
          cardName: card?.cardName,
          cardNumber: formatCardNumber(card?.cardNumber),
          cardCurrency: card?.cardCurrency,
          paymentMethod: card?.paymentMethod,
          balance: card?.balance,
          expiryDate: formatExpiryDate(card?.expiryDate),
        }))
      : [];
    return cardData?.length > 0 ? cardData[0] : null;
  };

  getFirstCardData();
  return (
    <div className="w-full rounded-lg bg-white bg-opacity-30 shadow-sm mb-5 md:mt-0 mt-3 lg:mt-0 md:mx-2 p-4 flex">
      {/* Section (60%) */}
      <div className="w-full flex flex-wrap">
        <section className="w-full lg:w-full lg:pr-4">
          <div className="w-full lg:w-full pr4 mb-4 md:mb-0">
            <div>
              <DebitCardNew cardData={summary?.data?.walletResponseDTO} />
            </div>
          </div>
          {/* <div className="rounded-b-lg border-t-2 border-solid border-[#0EBB68] bg-white shadow-md mt-3 p-3 transition duration-300 ease-in-out transform hover:scale-105">
            <div className="flex flex-col items-center">
              <div className="text-black text-2xl font-bold self-start">
                {funderDashboardData?.data?.totalFundingAmount}
              </div>
              <div className="text-[#565656]   text-[13px] self-end">
                Total amount in Funding
              </div>
            </div>
          </div> */}
        </section>

        {/* Section (40%) */}
      </div>
    </div>
  );
};

export default FunderCard;
