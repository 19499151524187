import { AnimatePresence, motion } from "framer-motion";
import {
  PAIconArrowLeftGreen,
  PAIconIconParkSucess,
  PAIconMDISuccess,
} from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TransitionScale from "shared/animation/TransitionScale";
import { useEffect, useState } from "react";
import ViewDocumentModal from "features/RulesConfig/common/components/modals/DeleteRuleModal";
import toast from "react-hot-toast";
import OfferDetailsModal from "./modal/OfferDetailsModal";
import InvoiceLockSuccessModal from "./modal/InvoiceLockSuccessModal";
import { useDispatch, useSelector } from "react-redux";
import {
  // cancelBid,
  getInvoiceById,
  // lockInvoice,
  placeInvoiceBid,
  updateInvoice,
  // getInvoiceBidByStatus,
} from "appstate/openMarket/openMarketSlice";
import { toggleCancelButtonOff } from "./buttonSlice";
import InvoiceDetails from "features/openMarket/common/components/InvoiceDetails";
// import ViewMoreBidders from "features/openMarket/supplier/components/ViewMoreBidders";
// import CardLoading from "./cards/CardLoading";
// import TransitionOpacity from "shared/animation/TransitionOpacity";
// import { timeAgo } from "utilities/helper/timeAgo";
import VerifyLien from "./modal/VerifyLienModal";
import Loading from "shared/Loading";

function OngoingBidInvoiceDetails() {
  const { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const offerId = searchParams.get("offerId");

  const dispatch = useDispatch();

  // console.log(invoiceByIdData);

  // console.log();

  // const invoiceByIdData = allOpenMarketInvoicesData?.data?.find(
  //   (data) => data?.invoiceId === id
  // );

  const navigate = useNavigate();
  const buttonClicked = useSelector(
    (state) => state?.invoiceButton?.buttonClicked
  );

  useEffect(() => {
    dispatch(getInvoiceById({ id }));
  }, [dispatch, id, offerId]);

  const { invoiceByIdData } = useSelector((state) => state?.openMarket);

  const [bidAmount, setBidAmount] = useState("");
  const [discountRate, setDiscountRate] = useState("");
  const [noteToSupplier, setNoteToSupplier] = useState("");

  // const [bidAmountOffer, setBidAmountOffer] = useState("");
  // const [discountRateOffer, setDiscountRateOffer] = useState("");
  // const [noteToSupplierOffer, setNoteToSupplierOffer] = useState("");

  // const [bidAmountNoRecourse, setBidAmountNoRecourse] = useState("");
  // const [discountRateNoRecourse, setDiscountRateNoRecourse] = useState("");
  // const [noteToSupplierNoRecourse, setNoteToSupplierNoRecourse] = useState("");

  // const [activeTab, setActiveTab] = useState("tab1");

  // const handleTabClick = (tab) => {
  //   setActiveTab(tab);
  // };

  // const isDisabled =
  //   bidAmount === "" || discountRate === "" || noteToSupplier === "";

  // const isDisabledNoRecourse =
  //   bidAmountNoRecourse === "" ||
  //   discountRateNoRecourse === "" ||
  //   noteToSupplierNoRecourse === "";

  // const lockDisabled = isDisabled || isDisabledNoRecourse;

  // const lockDisabled = isDisabled || isDisabledNoRecourse;

  const [bidModalOpen, setBidModalOpen] = useState(false);
  // const [lockModalOpen, setLockModalOpen] = useState(false);
  // const [offerDetailModalOpen, setOfferDetailModalOpen] = useState(false);
  // const [invoiceLockModalOpen, setInvoiceLockModalOpen] = useState(false);
  // const [cancelModalOpen, setCancelModalOpen] = useState(false);

  const [offerDetailModalOpen, setOfferDetailModalOpen] = useState(false);
  const [bidRequest, setBidRequest] = useState(false);
  const [verifyLienStatusModal, setVerifyLienStatusModal] = useState(false);
  const [invoiceLockedSuccessModal, setInvoiceLockedSuccessModal] =
    useState(false);
  const [activeTab, setActiveTab] = useState("bidHistory");

  // Handle Cancel
  // const handleCancel = () => {
  //   dispatch(cancelBid({ offerId })).then((data) => {
  //     if (data?.payload?.success) {
  //       setCancelModalOpen(false);
  //       dispatch(toggleCancelButtonOff());
  //       toast.success("Invoice Cancelled Successfully");
  //       dispatch(
  //         getInvoiceBidByStatus({ page: 0, pageSize: 10, status: "Pending" })
  //       );
  //     }
  //   });
  // };

  function handleGoBack() {
    dispatch(toggleCancelButtonOff());
    navigate(-1); // Navigate back one step
  }

  // Handle Bid
  const handleBid = () => {
    const body = { amount: bidAmount, discountRate, note: noteToSupplier };
    dispatch(placeInvoiceBid({ id, body })).then((data) => {
      if (data?.payload?.success) {
        toast.success("Your bid has been sent succesfully");
        setBidAmount("");
        setDiscountRate("");
        setNoteToSupplier("");
      }
    });
  };

  // Handle Submit

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   // if (bidAmount && discountRate !== "") {
  //   //   setBidModalOpen(true);
  //   // } else {
  //   //   toast.error("Fill appropraite fields and try again!");
  //   // }
  // };

  // Handle Lock
  // const handleLock = () => {
  //   dispatch(lockInvoice({ id })).then((res) => {
  //     if (res?.payload?.success) {
  //       setInvoiceLockModalOpen(true);
  //       toast.success("Invoice Locked Successfully");
  //       setBidAmountOffer("");
  //       setDiscountRateOffer("");
  //       setNoteToSupplierOffer("");
  //       setOfferDetailModalOpen(false);
  //     }
  //   });
  //   setLockModalOpen(false);
  //   // setOfferDetailModalOpen(true);
  // };

  // Handle Offer Detail
  // const handleOfferDetail = () => {
  //   dispatch(lockInvoice({ id })).then((res) => {
  //     if (res?.payload?.success) {
  //       setInvoiceLockModalOpen(true);
  //       toast.success("Invoice Locked Successfully");
  //       setBidAmountOffer("");
  //       setDiscountRateOffer("");
  //       setNoteToSupplierOffer("");
  //       setOfferDetailModalOpen(false);
  //     }
  //   });
  // };

  // Handle Update Detail
  const handleUpdate = () => {
    const body = { amount: bidAmount, discountRate, note: noteToSupplier };
    dispatch(updateInvoice({ id: offerId, body })).then((res) => {
      if (res?.payload?.success) {
        toast.success("Your bid has been updated succesfully");
        setBidAmount("");
        setDiscountRate("");
        setNoteToSupplier("");
      }
    });
  };

  const handleAction = () => {
    setOfferDetailModalOpen(false);
    setTimeout(() => {
      setBidRequest(true);
    }, 500);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setOfferDetailModalOpen(true);

    // handleAction();
  };

  const handleProceedToSelectAccount = () => {
    setBidRequest(false);
    setTimeout(() => {
      setInvoiceLockedSuccessModal(true);
    }, 500);
  };

  const dummyData = [
    {
      label1: "Nestle",
      value1: "Supplier",
      label2: "Dangote",
      value2: "Buyer",
    },
    {
      label1: "2024-11-12",
      value1: "Request Date",
      label2: "2024-11-19",
      value2: "Payment Date",
    },
    {
      label1: "10,000 USD",
      value1: "Request Amount",
      label2: "8,500 USD",
      value2: "Fundable Amount",
    },
  ];

  const bidHistoryDummyData = [
    {
      funder: "ABC Limited",
      discountRate: "2.4%",
      state: "Recourse",
      status: "Locked",
      biddingTime: "01:35:11:23",
      win: false,
    },
    {
      funder: "XYZ Finance",
      discountRate: "2.5%",
      state: "Recourse",
      status: "Unlocked",
      biddingTime: "02:15:45:12",
      win: false,
    },
    {
      funder: "First National Bank",
      discountRate: "2.6%",
      state: "Recourse",
      status: "Locked",
      biddingTime: "03:05:22:55",
      win: true,
    },
    {
      funder: "Capital One",
      discountRate: "2.3%",
      state: "Without Recourse",
      status: "Unlocked",
      biddingTime: "04:10:30:40",
      win: false,
    },
  ];
  const [cancelBid, setCancelBid] = useState(false);

  const [showCancelButton, setShowCancelButton] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowCancelButton(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  function handleDownloadSuccessful() {
    setCancelBid(false);

    toast.custom(
      <>
        <div className="flex-1 items-center justify-center flex w-full h-full">
          <div className="flex mt-[300px]">
            <div className="bg-primaryColor w-[147.79px] h-[160px] flex flex-col items-center justify-center rounded-l-[7px] shadow-lg">
              <PAIconMDISuccess />
            </div>
            <div className="bg-white  flex-col justify-center items-center self-stretch py-[25px] h-[160px] rounded-r-[7px] shadow-lg flex px-16 ">
              <PAIconIconParkSucess />
              <p className="text-dustyGray text-[13px] text-center mt-[17.6px]">
                Your bid has been Cancelled
              </p>
            </div>
          </div>
        </div>
      </>
    );

    setTimeout(() => {
      toast.dismiss();
      navigate(-1);
    }, 500);
  }

  return (
    <DashboardLayout subHeader="Here is what is happening in your finance">
      <TransitionScale className="flex  flex-col gap-6 bg-[#EEE] h-full">
        <header onClick={handleGoBack} className="w-full">
          <div className="flex cursor-pointer w-fit gap-1 items-center">
            <PAIconArrowLeftGreen />
            <span className=" text-primaryColor text-sm">Go back</span>
          </div>
        </header>

        <main className="flex flex-col semi-lg:flex-row pb-8 gap-8 w-full ">
          <InvoiceDetails
            invoiceByIdData={invoiceByIdData}
            // setLockModalOpen={setLockModalOpen}
          />

          <motion.section
            initial={{
              y: -20,
            }}
            animate={{
              y: 0,
            }}
            exit={{
              y: 20,
            }}
            transition={{
              delay: 0.8,
            }}
            className="flex flex-col gap-3 px- pt-7 pb-24 w-2/5 bg-white rounded-[10px]"
          >
            <header className="flex pb-2 px-6">
              <span className="font-bold">Bid Invoice</span>
            </header>

            <motion.button
              whileTap={{ scale: 0.96 }}
              className="p-2.5 rounded-[5px] bg-primaryColor text-white mx-6"
              onClick={handleSubmit}
            >
              Update Bid
            </motion.button>
            <div className="border-t border-gray"></div>
            <div className="w-full ">
              <div className="mt-3 flex justifycenter px-6">
                <div className="flex space-x-4">
                  {/* Bid History Tab */}
                  <button
                    onClick={() => setActiveTab("bidHistory")}
                    className={`text-gray600 ${
                      activeTab === "bidHistory"
                        ? "border-b border-secondaryColor text-secondaryColor"
                        : ""
                    } font-medium text-sm`}
                  >
                    Bid History
                  </button>
                  {/* Request Details Tab */}
                  <button
                    onClick={() => setActiveTab("requestDetails")}
                    className={`pb2 text-gray600 ${
                      activeTab === "requestDetails"
                        ? "border-b border-secondaryColor text-secondaryColor"
                        : ""
                    } font-medium text-sm`}
                  >
                    Request Details
                  </button>
                </div>
              </div>
              <div className="mt-3">
                {activeTab === "bidHistory" &&
                  bidHistoryDummyData
                    .sort((a, b) => b.win - a.win)
                    .map((bid, index) => (
                      <div key={index} className="relative">
                        <div
                          className={`flex justify-between px-6 pb-1 my-6 ${
                            bid.win
                              ? "bg-[#F1F9FF] border-b-[2px] border-r-[2px] border-l-[2px] border-t-[2px] rounded border-primaryColor"
                              : ""
                          } ${
                            bid.win ? "transition-opacity duration-300" : ""
                          } ${
                            bid.win && showCancelButton
                              ? "opacity-50"
                              : "opacity-100"
                          }`}
                        >
                          {bid.win && (
                            <div className="absolute top-[-10px] left-4 text-xs font-semibold text-primaryColor bg-white px-2">
                              Your bid is the winning bid
                            </div>
                          )}
                          <div className="flex gap-6 items-center w-[30%] mt-3">
                            <div className="flex flex-col gap-0.5">
                              <span
                                className={`text-xs font-[500] ${
                                  bid.win ? "text-primaryColor" : ""
                                }`}
                              >
                                {bid.funder}
                              </span>
                              <span className="text-[10px] text-[#959595]">
                                Funder
                              </span>
                            </div>
                          </div>
                          <div className="flex gap-6 items-center w-[25%] mt-3">
                            <div className="flex flex-col gap-0.5">
                              <span className="text-xs font-[500]">
                                {bid.discountRate} {" - "}
                                <span
                                  className={`text-[8px] ${
                                    bid.state === "Without Recourse"
                                      ? "text-primaryColor"
                                      : "text-secondaryColor"
                                  }`}
                                >
                                  {bid.state}
                                </span>
                              </span>
                              <span className="text-[10px] text-[#959595]">
                                Discount Rate
                              </span>
                            </div>
                          </div>
                          <div className="flex gap-6 items-center w-[20%] mt-3">
                            <div className="flex flex-col gap-0.5">
                              <span
                                className={`text-xs font-[500] ${
                                  bid.status === "Locked"
                                    ? "text-secondaryColor"
                                    : "text-black"
                                } ${bid.win ? "font-bold" : ""}`}
                              >
                                {bid.status}
                              </span>
                              <span className="text-[10px] text-[#959595]">
                                Status
                              </span>
                            </div>
                          </div>
                          <div className="flex gap-6 items-center w-[20%] mt-3">
                            <div className="flex flex-col gap-0.5">
                              <span className="text-xs font-[500]">
                                {bid.biddingTime}
                              </span>
                              <span className="text-[10px] text-[#959595]">
                                Bidding Time
                              </span>
                            </div>
                          </div>
                        </div>
                        {bid.win && showCancelButton && (
                          <>
                            <div className="absolute inset-0 flex items-center justify-center z-20">
                              <div className="transform scale-50">
                                <Loading />
                              </div>
                            </div>
                            <AnimatePresence>
                              <motion.div
                                initial={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                onClick={() => setCancelBid(true)}
                                className="absolute top-0 right-0 m-2 flex items-center bg-white text-[8px] text-red border-[1.5px] border-red py-1 px-2 rounded cursor-pointer z-30"
                              >
                                Cancel x{" "}
                                <span className="text-black ml-1">| 30s</span>
                              </motion.div>
                            </AnimatePresence>
                          </>
                        )}
                      </div>
                    ))}

                {activeTab === "requestDetails" && (
                  <div className="space-y-4">
                    {dummyData.map((item, index) => (
                      <div
                        key={index}
                        className="flex justify-between items-center px-6"
                      >
                        <span>
                          {item.label1} <br />
                          <span className="text-sm text-gray">
                            {item.value1}
                          </span>
                        </span>
                        <div className="flex flex-col items-end">
                          <span>{item.label2}</span>
                          <span className="text-sm text-gray">
                            {item.value2}
                          </span>
                        </div>
                      </div>
                    ))}
                    <br />
                    <div className="border mt-5 border-gray"></div>
                    <div className="px-6">
                      <h1>Note from Supplier: </h1>
                      <p className="text-gray600 text-sm mt-3">
                        Seeking Early payment due to unexpected challenges.
                        Facing temporary financial constraints and need a bit
                        more time to ensure a smooth transaction. Appreciate
                        your understanding. Thank you. Best regards, ABC Limited
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </motion.section>
        </main>
      </TransitionScale>
      {/* Bid Modal */}
      <AnimatePresence>
        {bidModalOpen && (
          <ViewDocumentModal
            onAction={buttonClicked ? handleUpdate : handleBid}
            onClose={() => setBidModalOpen(false)}
          >
            <span className=" text-center">
              Would you like to proceed with{" "}
              {buttonClicked ? "updating you bid on" : "bidding on"} this
              invoice
              <br />
              <span className="font-bold text-center text-primaryColor">
                {invoiceByIdData?.invoiceReference}
              </span>
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>

      {/* Offer Detail Modal */}
      <AnimatePresence>
        {offerDetailModalOpen && (
          <OfferDetailsModal
            onAction={handleAction}
            onClose={() => setOfferDetailModalOpen(false)}
            update
          />
        )}
      </AnimatePresence>

      {/* Lock Modal */}
      <AnimatePresence>
        {bidRequest && (
          <ViewDocumentModal
            onAction={() => handleProceedToSelectAccount()}
            onClose={() => setBidRequest(false)}
            proceed="Yes"
          >
            <span className=" text-center">
              Are you sure you want to{" "}
              <span className="font-bold text-center text-primaryColor">
                Update your bid
              </span>{" "}
              on this request with reference number{" "}
              <span className="font-bold text-center text-primaryColor">
                RCYSAJCRW
              </span>{" "}
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {cancelBid && (
          <ViewDocumentModal
            onAction={() => handleDownloadSuccessful()}
            onClose={() => setCancelBid(false)}
            proceed="Yes"
          >
            <span className=" text-center">
              Would you like to cancel your bid on request <br />
              <span className="font-bold text-center text-primaryColor">
                RCYSAJCRW
              </span>
              ?{" "}
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>

      {verifyLienStatusModal && (
        <VerifyLien
          onClose={() => setVerifyLienStatusModal(false)}
          onAction={() => {
            setVerifyLienStatusModal(false);
            setTimeout(() => {
              setInvoiceLockedSuccessModal(true);
            }, 500);
          }}
        />
      )}

      <AnimatePresence>
        {invoiceLockedSuccessModal && (
          <InvoiceLockSuccessModal
            setActiveTab={() => {
              sessionStorage.setItem("activeTab", 3);
              navigate("/open-market");
            }}
            update
            onClose={() => setInvoiceLockedSuccessModal(false)}
          />
        )}
      </AnimatePresence>
    </DashboardLayout>
  );
}

export default OngoingBidInvoiceDetails;
