import React, { useEffect, useState } from "react";
import Table from "layout/table/Table";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getInvoiceId,
  allEarlyPaymentRequestSingle,
} from "appstate/buyer/buyerSlice";
import Pagination from "shared/Pagination";
import Loading from "shared/Loading";

const SingleEarlyPayment = ({ activeTabType }) => {
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const status = "APPROVED";

  const navigate = useNavigate();
  const { allEarlyPaymentRequestSingleData, isLoading } = useSelector(
    (state) => state?.buyer
  );

  const onRowClicked = (row) => {
    dispatch(
      getInvoiceId({
        earlyPaymentId: row.earlyPaymentId,
        invoiceId: row.invoiceId,
      })
    );
    let path = "/payablefinancing/invoice/singleearlypaymentdetails/approved";
    navigate(path);
  };

  useEffect(() => {
    dispatch(allEarlyPaymentRequestSingle({ status }));
  }, [dispatch, status]);

  useEffect(() => {
    setData(
      allEarlyPaymentRequestSingleData?.data?.buyerSingleEarlyPaymentResponses
    );
  }, [allEarlyPaymentRequestSingleData]);

  useEffect(() => {
    if (activeTabType === "Via Wallet") {
      setData((prevData) =>
        prevData.filter((item) => item.fundingChannel === "WALLET")
      );
    }
    if (activeTabType === "Direct Funding") {
      setData((prevData) =>
        prevData.filter((item) => item.fundingChannel === "DIRECT_FUNDING")
      );
    }
    if (activeTabType === "Closed Market") {
      setData((prevData) =>
        prevData.filter((item) => item.fundingChannel === "CLOSED_MARKET")
      );
    }

    if (activeTabType === "All Funding Options") {
      setData(
        allEarlyPaymentRequestSingleData?.data?.buyerSingleEarlyPaymentResponses
      );
    }
  }, [
    activeTabType,
    allEarlyPaymentRequestSingleData?.data?.buyerSingleEarlyPaymentResponses,
  ]); // Empty dependency array to run only on component mount

  const columns = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Request Ref No
        </span>
      ),
      selector: (row) => row.earlyPaymentRef,
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Invocie Number.
        </span>
      ),
      selector: (row) => row.invoiceNo,
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Supplier
        </span>
      ),
      selector: (row) => row.supplierCompanyName,
      sortable: true,
    },
    {
      name: "Due Date",
      selector: (row) => row.dueDate,
      sortable: true,
    },
    {
      name: "Request Date",
      selector: (row) => row.earlyPaymentDate,
      sortable: true,
    },
    {
      name: "Request Amount",
      selector: (row) => row.requestAmount.tolocaleString(),
      sortable: true,
      cell: (row) => <p className="text-success">{row.requestAmount}</p>,
    },
    {
      name: "Discount Amount",
      selector: (row) => row.discountRate,
      sortable: true,
      cell: (row) => <p className="text-secondaryColor">{row.discountRate}</p>,
    },
    {
      name: "Payable Amount",
      selector: (row) => row.payableAmount,
      sortable: true,
      cell: (row) => <p className="text-success">{row.payableAmount}</p>,
    },
    {
      name: "Funding Channel",
      selector: (row) => row.fundingChannel,
      sortable: true,
    },
  ];
  const totalPages = allEarlyPaymentRequestSingleData?.meta?.totalElements;

  return (
    <>
      {!data?.length ? (
        <div>
          <div className="flex justify-between items-center px-[15px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
            <p className="text-black text-sm font-medium">
              Approved Early Payment Request
            </p>
          </div>
          <TableEmptyState dashboard />
        </div>
      ) : (
        <Card className="rounded-[10px] mt-[15px]">
          <div className="px[15px] pb-[24px]">
            <Table
              columns={columns}
              data={data}
              onRowClicked={onRowClicked}
              progressPending={isLoading}
              progressComponent={<Loading />}
              pointer
              tableHeader
              className="mt-5"
            />
            <div className="flex justify-center gap-x-[8px] mt-[20px]">
              {!isLoading && totalPages > 10 && (
                <Pagination
                  page={page}
                  startPage={startPage}
                  setEndPage={setEndPage}
                  endPage={endPage}
                  setStartPage={setStartPage}
                  setPage={setPage}
                  totalPages={totalPages}
                />
              )}
            </div>
          </div>
        </Card>
      )}
    </>
  );
};

export default SingleEarlyPayment;
