import { PAIconArrowLeftGreen, PAIconCocacola } from "assets/images/svgs";
import { AnimatePresence, motion } from "framer-motion";
import DashboardLayout from "layout/dashboardlayout";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TransitionScale from "shared/animation/TransitionScale";
import OngoingAcceptUpdateBidModal from "./AccepUpdateBidModal";
import EnterPin from "./Pin";
import { useDispatch, useSelector } from "react-redux";
import { viewApprovedSingleEarlyPaymentDetails } from "appstate/buyer/buyerSlice";
import { allInvoiceById } from "appstate/invoice/invoiceSlice";

const DisbursementDirectFundingDetailsPage = () => {
  const [pinModal, setPinModal] = useState(false);
  const [acceptModal, setAcceptModal] = useState(false);
  const [invoiceData, setInvoiceData] = useState([]);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getInvoiceId, viewApprovedSingleEarlyPaymentDetailsData } =
    useSelector((state) => state?.buyer);

  // console.log()
  const { invoiceIdData } = useSelector((state) => state.invoice);

  useEffect(() => {
    dispatch(viewApprovedSingleEarlyPaymentDetails(getInvoiceId?.id));
  }, [dispatch, getInvoiceId?.id]);

  useEffect(() => {
    const id = getInvoiceId?.invoiceId;
    dispatch(allInvoiceById({ id }));
  }, [dispatch, getInvoiceId?.invoiceId]);

  useEffect(() => {
    setData(viewApprovedSingleEarlyPaymentDetailsData?.data);
  }, [viewApprovedSingleEarlyPaymentDetailsData]);

  useEffect(() => {
    setInvoiceData(invoiceIdData?.data);
  }, [invoiceIdData]);

  const handleYes = () => {
    setAcceptModal(false);
    setPinModal(true);
  };
  return (
    <>
      <OngoingAcceptUpdateBidModal
        onClose={() => setAcceptModal(false)}
        isOpen={acceptModal}
        mess={"Are you sure you would like to "}
        payText={"Pay now"}
        mess2={"for this invoice  "}
        handleYes={handleYes}
      />{" "}
      <DashboardLayout
        section="Disbursement"
        subHeader="Track invoices due for payment disbursement"
      >
        <header className="w-full py-3">
          <span
            onClick={() => navigate(-1)}
            className="flex cursor-pointer items-center font-medium text-sm text-primaryColor gap-2"
          >
            <PAIconArrowLeftGreen /> Go back
          </span>
        </header>
        <main className="flex flex-col lg:flex-row gap-3 justify-between">
          <TransitionScale className="py-5 px-6 lg:w-[58%] flex flex-col gap-8 min-h-[90vh] bg-white rounded-[10px]">
            <header className="flex flex-col gap-3">
              <section className="flex justify-between  items-center">
                <h1 className="text-[18px] font-bold">
                  Reference No: | {invoiceData?.invoiceRef}
                </h1>

                <div className="flex flex-col gap-1">
                  <motion.button
                    onClick={() => {
                      setTimeout(() => {
                        setAcceptModal(true);
                      }, 200);
                    }}
                    whileTap={{ scale: 0.95 }}
                    className="py-3 px-6 text-xs  text-white rounded bg-primaryColor"
                  >
                    Pay Now
                  </motion.button>
                </div>
              </section>

              <section className="flex justify-between">
                <div className="flex flex-col gap-1 w-[40%]">
                  <section className="flex items-center justify-between">
                    <span className="text-xs font-semibold">
                      Invoice Number
                    </span>
                    <span className="text-[#979797]  text-xs">
                      INV-{invoiceData?.invoiceNo}
                    </span>
                  </section>

                  <section className="flex items-center justify-between">
                    <span className="text-xs font-semibold">Created Date</span>
                    <span className="text-[#979797]  text-xs">
                      {invoiceData?.createdDate}
                    </span>
                  </section>

                  <section className="flex items-center justify-between">
                    <span className="text-xs font-semibold">
                      Submitted Date
                    </span>
                    <span className="text-[#979797]  text-xs">
                      {invoiceData?.submittedDate}
                    </span>
                  </section>

                  <section className="flex items-center justify-between">
                    <span className="text-xs font-semibold">Approved Date</span>
                    <span className="text-[#979797]  text-xs">
                      {invoiceData?.approvedDate}
                    </span>
                  </section>

                  <section className="flex items-center justify-between">
                    <span className="text-xs font-semibold">Due Date</span>
                    <span className="text-[#979797]  text-xs">
                      {invoiceData?.dueDate}
                    </span>
                  </section>

                  <section className="flex items-center justify-between">
                    <span className="text-xs font-semibold">Issuance Date</span>
                    <span className="text-[#979797]  text-xs">
                      {invoiceData?.issuanceDate}
                    </span>
                  </section>
                </div>

                <div className="flex items-center text-xs">
                  <button
                    style={{ background: "rgba(46, 169, 35, 0.19)" }}
                    className="px-8 py-2 text-[#2EA923] rounded-[5px] "
                  >
                    Approved
                  </button>
                </div>
              </section>
            </header>

            <section className="flex items-center justify-between">
              <div className="flex flex-col gap-2">
                <span className=" font-bold text-sm">
                  {invoiceData?.buyerCompanyName}
                </span>
                <span className="text-[#7A7A7A] text-xs font-light">
                  Buyer Details
                </span>
              </div>

              <div className="flex flex-col gap-2">
                <span className="font-bold text-sm">
                  {invoiceData?.paymentTerms} Days
                </span>
                <span className="text-[#7A7A7A] text-xs font-light">
                  Payment
                </span>
              </div>

              <div className="flex flex-col gap-2">
                <span className="font-bold text-sm">
                  {invoiceData?.currency}
                </span>
                <span className="text-[#7A7A7A] text-xs font-light">
                  Currency
                </span>
              </div>
            </section>

            <section className="flex flex-col gap-4">
              <header className="flex items-center justify-between pb-3 border-b-[5px] border-[#F6F5FA] text-[#11192A]">
                <span className="text-sm">Line Items</span>

                <h1 className="text-sm font-bold">
                  {invoiceData?.items?.length} Total Item(s)
                </h1>
              </header>

              <div className=" w-full overflow-x-auto mt-3 pb-4">
                <table className=" w-full overflow-x-auto">
                  <thead className="bg-subtleGray w-full p-6  flex-nowrap whitespace-nowrap  ">
                    <tr className=" bg-unusualGray  px-6 w-full flex-nowrap whitespace-nowrap ">
                      <td class="text-[#11192a] text-xs font-semibold  p-4  flex-nowrap whitespace-nowrap ">
                        Item{" "}
                      </td>
                      <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                        Description{" "}
                      </td>
                      <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                        Unit Price
                      </td>
                      <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                        Quantity
                      </td>
                      <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                        Amount
                        <div> (before tax)</div>
                      </td>
                      <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                        Tax Rate
                      </td>
                      <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                        Tax Amount
                      </td>
                      <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                        Total Price
                      </td>
                    </tr>
                  </thead>

                  <tbody className="  ">
                    {invoiceData?.items?.map((cell, idx) => {
                      return (
                        <tr key={idx} className=" w-full ">
                          <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap ">
                            {cell.itemName}
                          </td>
                          <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                            {cell.description}
                          </td>
                          <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                            {cell.unitPrice.toLocaleString()}
                          </td>
                          <td className="text-[#11192a] text-xs font-semibold mt-2 text-center flex-nowrap whitespace-nowrap">
                            {cell.Quantity}
                          </td>
                          <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                            {cell.amount.toLocaleString()}
                          </td>
                          <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                            {cell.taxRate}
                          </td>
                          <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                            {cell.taxAmount.toLocaleString()}
                          </td>
                          <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                            {cell.totalPrice.toLocaleString()}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <div className=" px-6   py-5 bg-neutral-50 flex-col justify-center items-start  inline-flex bg-[#fafafa] w-full border-t-[0.2px]  border-t-[#b1a6b2]">
                <div className="lg:flex md:flex justify-between  w-full sm:block ">
                  {/* First left side */}
                  <div>
                    <section>
                      <div class="text-[#222222] text-lg  font-medium border-b-[0.2px]  border-[#b1a6b2]">
                        Receiving Funding Account Information
                      </div>
                      {/* <div class="text-[#222222] text-lg font-semibold ">
                    Receiving Funding Account Information
                  </div> */}
                      <p className="mt-3 text[#979797]">
                        {invoiceData?.virtualAccount?.accountName}
                      </p>
                      <p className="text[#979797]">
                        {invoiceData?.virtualAccount?.accountNumber}
                      </p>
                    </section>
                  </div>

                  {/* second division */}

                  <div>
                    <div class=" flex-col justify-start items-start inline-flex lg:min-w-[290px]">
                      <section className=" w-full px-6 pb-2 border-b-[1px] border-black">
                        <div className=" flex w-full justify-between">
                          <div class="text-[#222222] text-xs font-semibold ">
                            Subtotal
                          </div>
                          <span className="text-[#979797] text-xs text-right">
                            {invoiceData?.subTotal?.toLocaleString()}
                          </span>
                        </div>
                        <div className=" flex w-full justify-between pt-3 pb-1 border-b-[0.2px] border-b-[#b1a6b2]/30">
                          <div class="text-[#222222]  text-xs font-medium flex  space-x-9 ">
                            <span> Discount</span>
                            <span className="border border-[#979797] pr-10 pl-2 inline-block ml-4">
                              {invoiceData?.discount}%
                            </span>
                          </div>
                          <span className="text-[#979797] text-xs">
                            {invoiceData?.discountAmount?.toLocaleString()}
                          </span>
                        </div>

                        <div className=" flex w-full justify-between pt-3 pb-1 border-b-[0.2px] border-b-[#b1a6b2]/30">
                          <div class="text-[#222222]  text-xs font-medium flex  space-x-9 ">
                            <span>VAT%</span>
                            <span className="border border-[#979797] ml-10 pr-11 pl-2 inline-block">
                              {invoiceData?.vat}%
                            </span>
                          </div>
                          <span className="text-[#979797] text-xs">
                            {invoiceData?.vatAmount?.toLocaleString()}
                          </span>
                        </div>
                      </section>
                      <div className=" flex w-full justify-between py-2 px-6 border-b-[1px] border-b-black">
                        <div class="text-[#222222] text-lg font-semibold ">
                          Grand Total
                        </div>
                        <span className="text[#979797] py-1">
                          {invoiceData?.grandTotal?.toLocaleString()}
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* stops */}
                </div>
              </div>
            </section>
          </TransitionScale>

          <TransitionScale className="bg-white rounded-[10px] gap-6 w-[40%]">
            <div class="w-full justify-start items-center gap-4 inline-flex ">
              <div class=" justify-start items-center gap-2.5 flex w-full px-6  py-3">
                <div class="text-black text-xl font-bold  mt-5">
                  Early Payment Details
                </div>
              </div>
            </div>

            <div>
              <div className="   pb-4">
                <div class=" justify-between  flex flex-row    px-6 mt-4  ">
                  <div className="flex gap-2 py-2.5">
                    <PAIconCocacola />
                    <div className="flex flex-col">
                      <span className="text-sm">Supplier</span>
                      <span className="text-[#979797]  text-xs">
                        {data?.supplierCompanyName}
                      </span>
                    </div>
                  </div>
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <span className="text-sm">{data?.discountRate}%</span>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Discount rate
                      </div>
                    </div>
                  </div>

                  {/* here */}
                </div>
                <div class=" justify-between  flex flex-row    px-6  mt-6 ">
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <span className="text-sm">
                        {invoiceData?.currency}{" "}
                        {data?.discountAmount?.toLocaleString() || "0.00"}
                      </span>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Discount amount
                      </div>
                    </div>
                  </div>
                  {/* hd */}
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <span className="text-sm">
                        {data?.earlyPaymentDate}({data?.paymentDay} days)
                      </span>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Early Payment Date
                      </div>
                    </div>
                  </div>{" "}
                </div>
                <div class=" justify-between  flex flex-row    border-b border-[#c2c2c2]/30 px-6  py-3  pb-8 mt-6">
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <span className="text-sm">
                        {invoiceData?.currency}{" "}
                        {data?.requestAmount?.toLocaleString() || "0.00"}
                      </span>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Request amount
                      </div>
                    </div>
                  </div>
                  {/* dhdh */}
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <span className="text-sm">
                        {invoiceData?.currency}{" "}
                        {data?.payableAmount?.toLocaleString()}
                      </span>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Payable amount
                      </div>
                    </div>
                  </div>
                </div>
                {/* 2nd */}
                <div class=" justify-between  flex flex-col    border-b border-[#c2c2c2]/30 px-6  pb-10 ">
                  <div class="text-black text-lg font-semibold  mt-6">
                    Note from Supplier:{" "}
                  </div>
                  <p className="text-[#959595] text-sm">{data?.description}</p>
                </div>{" "}
                <div class="justify-between  flex flex-col  px-6">
                  <div class="text-black text-lg font-semibold  mt-6">
                    Funding Option
                  </div>

                  <div class=" text-[#949494] font-light  my-3 leading-6">
                    This invoice would be funded from this virtual account:
                  </div>
                </div>
                <div
                  style={{ color: "rgba(0, 0, 0, 0.50)" }}
                  className="flex flex-col px-6 text-sm font-semibold"
                >
                  <p className="text-[16px] font-semibold text-slate-500">
                    {data?.buyerVirtualAccountName}
                  </p>
                  <p className="text-[16px] font-semibold text-slate-500">
                    {data?.buyerVirtualAccountNumber}
                  </p>
                  <span> PayEdge MFB</span>
                </div>
              </div>
            </div>
          </TransitionScale>
        </main>

        <AnimatePresence>
          <EnterPin
            isOpen={pinModal}
            onClose={() => setPinModal(false)}
            paymentStatus="EPR"
            invoiceId={getInvoiceId?.invoiceId}
            pinMessage={
              "Enter your PIN to fund this early payment request from the chosen virtual account."
            }
          />
        </AnimatePresence>
      </DashboardLayout>
    </>
  );
};

export default DisbursementDirectFundingDetailsPage;
