import { PAIconInventoryArrowLeft, PAIconSearch } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import Button from "shared/Button";
import { useNavigate } from "react-router-dom";
import { SelectContainer } from "shared/DeleteModal/Delete/styles";
import TableHeaderSelect from "shared/tableHeader/TableHeaderSelect";
import Table from "layout/table/Table";
import ThreeDots from "../../assets/images/svgs/option.svg";

const data = [
  {
    id: 1,
    name: "John Doe",
    company: "Acme Corp",
    email: "john.doe@acmecorp.com",
    phone: "+1234567890",
    invoices: 5,
    status: "Approved",
  },
  {
    id: 2,
    name: "Jane Smith",
    company: "Smith Industries",
    email: "jane.smith@smithind.com",
    phone: "+9876543210",
    invoices: 8,
    status: "Pending",
  },
  {
    id: 3,
    name: "Robert Johnson",
    company: "Johnson Enterprises",
    email: "robert.johnson@johnsonent.com",
    phone: "+1029384756",
    invoices: 3,
    status: "Approved",
  },
  {
    id: 4,
    name: "Emily Davis",
    company: "Davis Solutions",
    email: "emily.davis@davissol.com",
    phone: "+5647382910",
    invoices: 2,
    status: "Declined",
  },
  {
    id: 5,
    name: "Michael Brown",
    company: "Brown & Co.",
    email: "michael.brown@brownco.com",
    phone: "+0192837465",
    invoices: 10,
    status: "Pending",
  },
];


const BulkEarlyPaymentRequestFlow = () => {
  const navigate = useNavigate();

  function handleNavigate() {
    navigate(-1);
  }

  const onRowClicked = (row) => {
    let path = "/bulk-early-payment-invoice-details";
    // dispatch(getAssociatedBuyerId(row?.id));
    navigate(path);
  };

  const column = [
    {
      name: "Name",
      selector: (row) => row?.name,
    },
    {
      name: "Company",
      selector: (row) => row?.company,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
    },
    {
      name: "Phone",
      selector: (row) => row?.phone,
    },
    {
      name: "No of invoices",
      selector: (row) => row?.invoices,
    },
    {
        cell: (row) => (
          <div className="relative">
            <img
              src={ThreeDots}
              alt="Options"
              // onClick={() => handleThreeDotsClick(row, row?.email)} 
              className="cursor-pointer"
            />
          </div>
        )
    },
  ];

  return (
    <DashboardLayout
      section="Invoice"
      subHeader="Here is an overview of your invoices"
    >
      <div>
        <header className="bg-alabasterHeader p-[24px] rounded-t-[10px]">
          <div
            onClick={handleNavigate}
            className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
          >
            <PAIconInventoryArrowLeft />
            <p className="mt-[2px] text-sm font-normal text-lightGray">
              Go Back
            </p>
          </div>
          <div className="flex justify-between flex-wrap ">
            <div>
              <p className="text-black text-sm font-medium">All buyers</p>
              <p className="text-textColor text-xs font-normal">
                List of all buyers that have activated early payment request
              </p>
            </div>
          </div>
          <br />
          <hr />
          <br />
          <div className="flex gap-x-[10px] mt-[10px] md:mt-0 flex-wrap">
            <div className="flex items-center  bg-white p-[10px] gap-x-[8px] rounded-[5px] border-[0.5px] border-silver xl:w-[350px]">
              <PAIconSearch />
              <input
                type="text"
                //   value={companyName}
                //   onChange={(e) => setCompanyName(e.target.value)}
                placeholder="Search funders"
                className="placeholder:text-xs outline-none"
              />
            </div>
            <div className="pr-2">
              <SelectContainer>
                <TableHeaderSelect />
              </SelectContainer>
            </div>
            <Button
              neutral
              // onClick={handleSearch}
              // disabled={data < 1}
              className="py-[12px] mt-[10px] md:mt-0 px-[24px] border-[0.5px] border-silver bg-brightGray text-sm !text-black rounded-[5px] font-medium"
            >
              Search
            </Button>
          </div>
        </header>
      </div>
      <div className="p-[24px] bg-white rounded-b-[10px]">
        <Table
          columns={column}
          pointer
          data={data}
          onRowClicked={onRowClicked}
        />
      </div>
    </DashboardLayout>
  );
};
export default BulkEarlyPaymentRequestFlow;
