import React, { useState, useEffect } from "react";
import DashboardLayout from "layout/dashboardlayout";
import { PAIconArrowLeftGreen, PAIconCocacola } from "assets/images/svgs";
import { useNavigate } from "react-router-dom";

import TransitionScale from "shared/animation/TransitionScale";
import PendingApprovalSucessModal from "../components/modal/PendingApprovalSucessModals";
import PendingApprovalProceedModal from "../components/modal/PendingApprovalProceedModal";
import PendingApprovalDeclineSucessModal from "../components/modal/PendingApprovalDeclineSuccess";
import PendingApprovalDeclineModal from "../components/modal/PendingApprovalDeclineModal";
import ReasonForDeclineModal from "../components/modal/ReasonForDeclineModal";
import { AnimatePresence } from "framer-motion";
import {
  fetchSinglePendingRequestById,
  approveOrUnapproveWorkflowById,
} from "appstate/workflow/workflowSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

const ApprovalViaWalletScreenDetails = () => {
  const [ongoingCancelBidModal, setOngoingCancelBidModal] = useState(false);
  const [ongoingBidCancelledSuccessModal, setOngoingBidCancelledSuccessModal] =
    useState(false);

  const [ongoingCancelBidModal2, setOngoingCancelBidModal2] = useState(false);
  const [
    ongoingBidCancelledSuccessModal2,
    setOngoingBidCancelledSuccessModal2,
  ] = useState(false);
  const [reasonforDecline, setReasonForDecline] = useState(false);

  const handleOnCancelSuccessClose = () => {
    setOngoingBidCancelledSuccessModal(false);
    navigate(-1);
  };
  // const handleOnCancelBidTrue = () => {
  //   setOngoingCancelBidModal(false);
  //   setOngoingBidCancelledSuccessModal(true);
  // };
  const handleOnCancelSuccessClose2 = () => {
    setOngoingBidCancelledSuccessModal2(false);
    navigate(-1);
  };
  const handleOnCancelBidTrue2 = () => {
    setOngoingCancelBidModal2(false);
    // setOngoingBidCancelledSuccessModal2(true);
    setReasonForDecline(true);
  };

  // const handleDeclineContinue = () => {
  //   setReasonForDecline(false);
  //   setOngoingBidCancelledSuccessModal2(true);
  // };

  const navigate = useNavigate();

  const [reas, setReas] = useState("");

  const dispatch = useDispatch();
  const { workflowId } = useParams();

  const { singleDetailPendingRequest } = useSelector((state) => state.workflow);
  useEffect(() => {
    dispatch(fetchSinglePendingRequestById({ id: workflowId }));
  }, [dispatch, workflowId]);

  const approveBody = {
    reason: "",
    approve: true,
  };
  const rejectBody = {
    reason: reas,
    approve: false,
  };
  const handleApprove = async () => {
    try {
      const response = await dispatch(
        approveOrUnapproveWorkflowById({ id: workflowId, body: approveBody })
      );

      if (response?.payload?.status === 200 && response?.payload?.success) {
        setOngoingBidCancelledSuccessModal(true);
      } else {
        toast.error("Failed to approve workflow");
      }
    } catch (error) {
      toast.error("An error occurred while approving workflow");
    }
  };

  const handledReject = async () => {
    try {
      const response = await dispatch(
        approveOrUnapproveWorkflowById({ id: workflowId, body: rejectBody })
      );

      if (response?.payload?.status === 200 && response?.payload?.success) {
        setOngoingBidCancelledSuccessModal2(true);
      } else {
        toast.error("Failed to reject workflow");
      }
    } catch (error) {
      toast.error("An error occurred while rejecting workflow");
    }
  };

  const handleOnCancelBidTrue = () => {
    setOngoingCancelBidModal(false);
    handleApprove();
  };
  const handleDeclineContinue = () => {
    setReasonForDecline(false);
    handledReject();
  };

  const vatPercent = Number(
    (Number(singleDetailPendingRequest?.data?.payload?.vat) / 100) *
      Number(singleDetailPendingRequest?.data?.payload?.subTotal)
  );
  const discountPercent = Number(
    (Number(singleDetailPendingRequest?.data?.payload?.discount) / 100) *
      Number(singleDetailPendingRequest?.data?.payload?.subTotal)
  );

  return (
    <>
      <TransitionScale>
        <PendingApprovalProceedModal
          isOpen={ongoingCancelBidModal}
          onClose={() => setOngoingCancelBidModal(false)}
          handleYes={handleOnCancelBidTrue}
        />
      </TransitionScale>
      <TransitionScale>
        <PendingApprovalSucessModal
          isOpen={ongoingBidCancelledSuccessModal}
          onClose={handleOnCancelSuccessClose}
          message={"Request Approved"}
        />
      </TransitionScale>
      <TransitionScale>
        <PendingApprovalProceedModal
          isOpen={ongoingCancelBidModal}
          onClose={() => setOngoingCancelBidModal(false)}
          handleYes={handleOnCancelBidTrue}
        />
      </TransitionScale>

      <AnimatePresence>
        <TransitionScale>
          <PendingApprovalDeclineSucessModal
            isOpen={ongoingBidCancelledSuccessModal2}
            onClose={handleOnCancelSuccessClose2}
            message={"Request Declined"}
          />
        </TransitionScale>
      </AnimatePresence>
      <AnimatePresence>
        <TransitionScale>
          <PendingApprovalDeclineModal
            isOpen={ongoingCancelBidModal2}
            onClose={() => setOngoingCancelBidModal2(false)}
            handleYes={handleOnCancelBidTrue2}
            message={" Are you sure you would like to decline this"}
            midMessage={"workflow?"}
          />
        </TransitionScale>
      </AnimatePresence>
      <AnimatePresence>
        <TransitionScale>
          {reasonforDecline && (
            <ReasonForDeclineModal
              onClose={() => setReasonForDecline(false)}
              onContinue={handleDeclineContinue}
              getReason={setReas}
              supplierName={
                singleDetailPendingRequest?.data?.payload?.supplierName
              }
            />
          )}
        </TransitionScale>
      </AnimatePresence>

      <DashboardLayout
        section="Workflow "
        subHeader="Manage all your workflow approvals"
      >
        <header className="bg-white text-primaryColor px-6  rounded-[5px] ">
          <span
            onClick={() => navigate("/work-flow-management/pendingrequests")}
            className="flex cursor-pointer py-4 w-fit font-light items-center text-sm gap-2"
          >
            <PAIconArrowLeftGreen />
            Go Back
          </span>
        </header>

        <TransitionScale className="px-6 pt-6 pb-8 bg-white  rounded-[10px] mt-5 ">
          <div className="lg:flex md:flex justify-between  w-full sm:block  ">
            <div>
              {" "}
              <div class="text-[#11192a] text-xs font-bold ">
                Approval Stage
              </div>
            </div>

            {/* <div>
                {" "}
                <div class="h-[30px] px-6 py-2 bg-[#de1515]/30 rounded-[5px] justify-center items-center gap-2.5 inline-flex">
                  <div class="text-center text-[#de1515] text-xs font-semibold">
                    Declined
                  </div>
                </div>
              </div> */}

            <div className=" flex gap-3">
              {/* <button
                  className={` w-24   py-2 px-3  text-[#222222] justify-center  flex items-center bg-[#D6D6D6] rounded-[5px]  text-sm ${"cursor-pointer"}`}
                  // onClick={() => setOngoingCancelBidModal(true)}
                  //   onClick={() => setOngoingCancelBidModal2(true)}
                  // disabled={termsIsVisible ? false : true}
                >
                  Confirm
                </button> */}
              <button
                className={` w-24   py-2 px-3  text-[#de1515]  border border-[#de1515] justify-center  flex items-center bg-white rounded-[5px]  text-sm ${"cursor-pointer"}`}
                //   class=" px-6 py-2 bg-white rounded-[5px] border border-[#de1515] justify-center items-center gap-2.5 inline-flex"
                onClick={() => setOngoingCancelBidModal2(true)}
              >
                <div>Decline</div>
              </button>
              <button
                className={`w-24  px-3 text-white justify-center  flex items-center bg-[#07593d] rounded-[5px] py-2 text-sm ${"cursor-pointer"}`}
                //   onClick={() =>
                //     navigate("/invoice/invoice/fundingSection/invoiceupload")
                //   }
                onClick={() => setOngoingCancelBidModal(true)}
              >
                Approve
              </button>
            </div>
          </div>

          <div className="lg:flex md:flex justify-between  w-full sm:block  mt-3 ">
            {/* underlined div */}
            <div className="">
              <div>
                <span className="text-[#7a7a7a] text-xs font-bold ">User</span>
                <span className="text-[#7a7a7a] text-xs font-normal ">
                  {" "}
                  to approve
                </span>
              </div>

              <div className="">
                {singleDetailPendingRequest?.data?.approvalStage ===
                "0 of 1" ? (
                  <div className="mb-[15px] mt-[10px] flex justify-center items-center gap-x-[15px] ">
                    <div>
                      <div className="w-[100px] h-1   bg-[#F08000]/30    rounded-[100px] lg:w-[150px]  xs:w-[70px]"></div>
                    </div>
                    <div>
                      <div className="w-[100px] h-1   bg-[#F08000]/30 rounded-[100px] lg:w-[150px]  xs:w-[70px]"></div>
                    </div>
                    <div>
                      <div className="w-[100px] h-1 bg-[#F08000]/30  rounded-[100px] lg:w-[150px] xs:w-[70px] "></div>
                    </div>
                  </div>
                ) : (
                  <div className="mb-[15px] mt-[10px] flex justify-center items-center gap-x-[15px] ">
                    <div>
                      <div className="w-[100px] h-1   bg-[#F08000]   rounded-[100px] lg:w-[150px]  xs:w-[70px]"></div>
                    </div>
                    <div>
                      <div className="w-[100px] h-1   bg-[#F08000] rounded-[100px] lg:w-[150px]  xs:w-[70px]"></div>
                    </div>
                    <div>
                      <div className="w-[100px] h-1 bg-[#F08000] rounded-[100px] lg:w-[150px] xs:w-[70px] "></div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Button division */}

            <div>
              {" "}
              {/* <div class="h-[30px] px-6 py-2 bg-[#07593d]/30 rounded-[5px] justify-center items-center gap-2.5 inline-flex">
                  <div class="text-center text-[#07593d] text-xs font-semibold">
                    Completed
                  </div>
                </div> */}
            </div>
          </div>

          {/* Second Box phase and pages */}
          <div className="lg:flex md:flex justify-between  w-full sm:block  mt-6 ">
            <div>
              <div class=" flex-col justify-center items-start gap-2 inline-flex">
                <div class="self-stretch justify-between items-center inline-flex gap-28">
                  <div class="text-[#222222] text-[14px] font-semibold">
                    Date Sent:
                  </div>
                  <div class="text-[#7a7a7a] text-[14px] font-medium ">
                    {singleDetailPendingRequest?.data?.dateSent}
                  </div>
                </div>
                <div class="self-stretch justify-between items-center inline-flex gap-28">
                  <div class="text-[#222222] text-[14px] font-semibold">
                    Transaction Type:
                  </div>
                  <div class="text-[#7a7a7a] text-[14px] font-medium ">
                    {singleDetailPendingRequest?.data?.type}
                  </div>
                </div>
                <div class="self-stretch justify-between items-center inline-flex  gap-28">
                  <div class="text-[#222222] text-[14px] font-semibold">
                    Action:
                  </div>
                  <div class="text-[#7a7a7a] text-[14px] font-medium ">
                    {singleDetailPendingRequest?.data?.action}
                  </div>
                </div>
                <div class="self-stretch justify-between items-center inline-flex">
                  <div class="text-[#222222] text-[14px] font-semibold">
                    Approval Stage:
                  </div>
                  <div class="text-[#7a7a7a] text-[14px] font-medium ">
                    {singleDetailPendingRequest?.data?.approvalStage}
                  </div>
                </div>
              </div>
            </div>

            {/* Second side */}

            <div>
              <div class=" flex-col justify-center items-start gap-2 inline-flex">
                <div class="self-stretch justify-between items-center inline-flex gap-28">
                  <div class="text-[#222222] text-[14px] font-semibold">
                    Sender:
                  </div>
                  <div class="text-[#7a7a7a] text-[14px] font-medium ">
                    {singleDetailPendingRequest?.data?.sender}
                  </div>
                </div>
                <div class="self-stretch justify-between items-center inline-flex gap-28">
                  <div class="text-[#222222] text-[14px] font-semibold">
                    Previous Approver:
                  </div>
                  <div class="text-[#7a7a7a] text-[14px] font-medium ">
                    {singleDetailPendingRequest?.data?.previousApprover}
                  </div>
                </div>
                <div class="self-stretch justify-between items-center inline-flex  gap-28">
                  <div class="text-[#222222] text-[14px] font-semibold">
                    Next Approver:
                  </div>
                  <div class="text-[#7a7a7a] text-[14px] font-medium ">
                    {singleDetailPendingRequest?.data?.nextApprover}
                  </div>
                </div>
              </div>
              {/* Button */}
            </div>
          </div>
        </TransitionScale>

        <div className="grid lg:grid-cols-[60%,40%] gap-5">
          <TransitionScale className=" pb-5 bg-white  rounded-[3px] mt-5 pt-2">
            {/* Header with Left Padding */}

            {/* Other sections */}

            <section className="  mt-3">
              <div class="text-[#222222] text-lg font-semibold mt-4  px-6">
                Invoice |{" "}
                {singleDetailPendingRequest?.data?.payload?.invoiceNo &&
                  singleDetailPendingRequest?.data?.payload?.invoiceNo}
              </div>

              {/* Down Flex Texts */}
              <div className=" flex justify-between px-6">
                {/* first stage */}

                <div class="lg:w-[240px]  sm:w-full h-[117px] flex-col justify-start items-start gap-3 inline-flex">
                  <div class="self-stretch justify-between items-center inline-flex mt-2">
                    <div class="text-[#222222] text-base font-semibold  ">
                      Reference No: |{" "}
                      {singleDetailPendingRequest?.data?.payload?.invoiceRef &&
                        singleDetailPendingRequest?.data?.payload?.invoiceRef}
                    </div>
                  </div>
                  <div class="self-stretch  flex-col justify-center items-start gap-1 flex mt-2">
                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Invoice Number
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        {singleDetailPendingRequest?.data?.payload?.invoiceNo &&
                          singleDetailPendingRequest?.data?.payload?.invoiceNo}
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex ">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Created Date:{" "}
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        {singleDetailPendingRequest?.data?.payload?.createdDate}
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Submitted Date:{" "}
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        {singleDetailPendingRequest?.data?.payload
                          ?.submittedDate &&
                          singleDetailPendingRequest?.data?.payload
                            ?.submittedDate}
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Approved Date:{" "}
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        {singleDetailPendingRequest?.data?.payload
                          ?.approvedDate &&
                          singleDetailPendingRequest?.data?.payload
                            ?.approvedDate}
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Due Date:{" "}
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        {" "}
                        {singleDetailPendingRequest?.data?.payload?.dueDate &&
                          singleDetailPendingRequest?.data?.payload?.dueDate}
                      </div>
                    </div>
                  </div>

                  {/* stop */}
                </div>
                {/* second stage */}
                <div className="flex justify-between items-center mt-10 ">
                  {" "}
                  <div class="h-[37px] p-2.5 rounded-[5px] border border-[#f08000]/30 justify-center items-center gap-2.5 inline-flex">
                    <div class="text-[#f08000] text-sm font-medium ">
                      {singleDetailPendingRequest?.data?.payload?.status}
                    </div>
                  </div>
                </div>
              </div>

              {/* stops here */}
              <div className="  px-6 mt-16">
                <header className="border-b-[5px] border-b-[#F6F5FA] mt-3  flex justify-between py-1">
                  {/* <h1 className="text-[18px] font-semibold">
                  Supplier/buyer details
                </h1> */}
                  <div>
                    <div class="text-[#222222] text-lg font-semibold  ">
                      {singleDetailPendingRequest?.data?.payload?.buyerName}
                    </div>
                    <div class="text-[#7a7a7a] text-xs font-light  ">
                      Buyer details
                    </div>
                  </div>

                  <div class="h-[41px] flex-col justify-start items-start  inline-flex px-12">
                    <div class="text-[#222222] text-base font-semibold ">
                      {
                        singleDetailPendingRequest?.data?.payload
                          ?.paymentDuration
                      }{" "}
                      Days
                    </div>
                    <div class="w-[100px] text-[#7a7a7a] text-xs font-light  mt-1">
                      Payment terms
                    </div>
                  </div>

                  <div class="h-[43px] flex-col justify-start items-end  inline-flex">
                    <div class="text-[#222222] text-lg font-semibold ">
                      {singleDetailPendingRequest?.data?.payload?.currency}
                    </div>
                    <div class="text-[#7a7a7a] text-xs font-semibold mt-1">
                      Currency
                    </div>
                  </div>
                </header>

                <header className="border-b-[5px] border-b-[#F6F5FA] mt-6  flex justify-between py-1">
                  {/* <h1 className="text-[18px] font-semibold">
                  Supplier/buyer details
                </h1> */}
                  <div class="text-[#11192a] text-sm font-semibold ">
                    Line Items
                  </div>
                  <div class="text-[#11192a] text-lg font-light  mr-6">
                    {singleDetailPendingRequest?.data?.payload?.items?.length}{" "}
                    Total Items{" "}
                  </div>
                </header>

                <div className=" w-full overflow-x-auto mt-3 pb-4">
                  <table className=" w-full overflow-x-auto">
                    <thead className="bg-subtleGray w-full p-6   ">
                      <tr className=" bg-unusualGray  px-6 w-full ">
                        {/* <td className="text-[#11192A] p-2.5 text-xs font-bold">
                          Item Number
                        </td> */}
                        <td class="text-[#11192a] text-xs font-semibold  p-4  ">
                          Item{" "}
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4">
                          Description{" "}
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4">
                          Unit Price
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4">
                          Quantity
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4">
                          Amount
                          <div> (before tax)</div>
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4">
                          Tax Rate
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4">
                          Tax Amount
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4">
                          Total Price
                        </td>
                      </tr>
                    </thead>

                    <tbody className="  ">
                      {singleDetailPendingRequest?.data?.payload?.items
                        ?.length > 0 &&
                        singleDetailPendingRequest?.data?.payload?.items?.map(
                          (cell, idx) => {
                            return (
                              <tr key={idx} className=" w-full ">
                                <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 ">
                                  {cell.itemName}
                                </td>
                                <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                                  {cell.description}
                                </td>
                                <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                                  {cell.unitPrice}
                                </td>
                                <td className="text-[#11192a] text-xs font-semibold mt-2 text-center">
                                  {cell.quantity}
                                </td>
                                <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                                  {cell.amount}
                                </td>
                                <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                                  {cell.taxRate}
                                </td>
                                <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                                  {cell.taxAmount}
                                </td>
                                <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                                  {cell.totalPrice}
                                </td>
                              </tr>
                            );
                          }
                        )}
                    </tbody>
                  </table>
                </div>
                {/* Calculate cost and price  shaded or gray section */}
              </div>

              <div className=" px-12   py-5 bg-neutral-50 flex-col justify-center items-start  inline-flex bg-[#fafafa] w-full border-t-[0.2px]  border-t-[#b1a6b2]">
                <div className="lg:flex md:flex justify-between  w-full sm:block ">
                  {/* First left side */}
                  <div>
                    <section>
                      {" "}
                      <div class="text-[#222222] text-lg  font-medium border-b-[0.2px]  border-[#b1a6b2]">
                        Receiving Funding Account Information
                      </div>
                      {/* <div class="text-[#222222] text-lg font-semibold ">
                          Receiving Funding Account Information
                        </div> */}
                      <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                        {singleDetailPendingRequest?.data?.payload
                          ?.fundingAccount?.accountNumber &&
                          singleDetailPendingRequest?.data?.payload
                            ?.fundingAccount?.accountNumber}
                      </div>
                      <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                        {singleDetailPendingRequest?.data?.payload
                          ?.fundingAccount?.accountName &&
                          singleDetailPendingRequest?.data?.payload
                            ?.fundingAccount?.accountName}
                      </div>
                    </section>
                  </div>

                  {/* second division */}

                  <div>
                    {" "}
                    <div class=" flex-col justify-start items-start inline-flex lg:min-w-[380px]">
                      <section className=" w-full px-6 pb-2 border-b-[1px] border-black">
                        <div className=" flex w-full justify-between">
                          <div class="text-[#222222] text-xs font-semibold ">
                            Subtotal
                          </div>
                          <div class=" text-[#222222] text-xs font-semibold ">
                            {singleDetailPendingRequest?.data?.payload
                              ?.subTotal &&
                              singleDetailPendingRequest?.data?.payload
                                ?.subTotal}
                          </div>
                        </div>
                        <div className=" flex w-full justify-between pt-3 pb-1 border-b-[0.2px] border-b-[#b1a6b2]/30">
                          <div class="text-[#222222]  text-xs font-medium flex  space-x-9 ">
                            <span>VAT %</span>
                            <span>
                              {singleDetailPendingRequest?.data?.payload?.vat &&
                                singleDetailPendingRequest?.data?.payload?.vat}
                              %
                            </span>
                          </div>
                          <div class="text-[#222222] text-xs font-medium ">
                            {vatPercent}
                          </div>
                        </div>

                        <div className=" flex w-full justify-between pt-3 pb-1 border-b-[0.2px] border-b-[#b1a6b2]/30">
                          <div class="text-[#222222]  text-xs font-medium flex  space-x-9 ">
                            <span>Discount %</span>
                            <span>
                              {" "}
                              {singleDetailPendingRequest?.data?.payload
                                ?.discount &&
                                singleDetailPendingRequest?.data?.payload
                                  ?.discount}
                              %
                            </span>
                          </div>
                          <div class="text-[#222222] text-xs font-medium ">
                            {discountPercent}
                          </div>
                        </div>
                      </section>
                      <div className=" flex w-full justify-between py-2 px-6 border-b-[1px] border-b-black">
                        <div class="text-[#222222] text-lg font-semibold ">
                          Grand Total
                        </div>
                        <div class="text-[#222222] text-lg font-semibold ">
                          {singleDetailPendingRequest?.data?.payload
                            ?.grandTotal &&
                            singleDetailPendingRequest?.data?.payload
                              ?.grandTotal}
                        </div>
                      </div>{" "}
                      {/* <div className=" flex w-full justify-between pt-3 pb-1  border-b-[1px] border-black">
                          <div class="text-[#222222] text-lg  font-medium  ">
                            Grand Total
                          </div>
                          <div class="text-[#222222] text-lg  font-medium ">
                            5,263,200.00
                          </div>
                        </div> */}
                    </div>
                  </div>

                  {/* stops */}
                </div>
              </div>
            </section>
          </TransitionScale>
          {/* stops the best here */}

          <div className="bg-white rounded-[10px] gap-8  mt-5">
            {/* <FundDetailSide /> */}
            <div class="w-full justify-start items-center gap-4 inline-flex ">
              <div class=" justify-start items-center gap-2.5 flex w-full px-6  py-3">
                <div class="text-black text-xl font-bold  mt-5">
                  Early Payment Details
                </div>
              </div>
            </div>

            <div>
              {" "}
              {/* second */}
              <div className="   pb-4">
                <div class=" justify-between  flex flex-row    px-6 mt-4  ">
                  <div className="flex gap-2 py-2.5">
                    <PAIconCocacola />
                    <div className="flex flex-col">
                      {/* <span className=" text-black text-xs font-normal">
                        Coca Cola
                      </span> */}
                      <span class="text-black text-sm font-normal ">
                        {
                          singleDetailPendingRequest?.data?.payload
                            ?.supplierName
                        }
                      </span>
                      {/* <span className="text-[#979797]  text-xs">Supplier</span> */}
                      <span class="text-[#949494] text-sm font-normal ">
                        Supplier
                      </span>
                    </div>
                  </div>
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        {" "}
                        {
                          singleDetailPendingRequest?.data?.payload
                            ?.discountRate
                        }{" "}
                        %
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Discount rate
                      </div>
                    </div>
                  </div>

                  {/* here */}
                </div>
                <div class=" justify-between  flex flex-row    px-6  mt-6 ">
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        {" "}
                        {singleDetailPendingRequest?.data?.payload
                          ?.discountedAmount &&
                          singleDetailPendingRequest?.data?.payload
                            ?.discountedAmount}{" "}
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Discount amount
                      </div>
                    </div>
                  </div>
                  {/* hd */}
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        {singleDetailPendingRequest?.data?.payload
                          ?.paymentDate &&
                          singleDetailPendingRequest?.data?.payload
                            ?.paymentDate}{" "}
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Early Payment Request
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        date
                      </div>
                    </div>
                  </div>{" "}
                </div>
                <div class=" justify-between  flex flex-row    border-b border-[#c2c2c2]/30 px-6  py-3  pb-12 mt-6">
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        {singleDetailPendingRequest?.data?.payload
                          ?.receivableAmount &&
                          singleDetailPendingRequest?.data?.payload
                            ?.receivableAmount}{" "}
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Request amount
                      </div>
                    </div>
                  </div>
                  {/* dhdh */}
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        {singleDetailPendingRequest?.data?.payload
                          ?.invoicedAmount &&
                          singleDetailPendingRequest?.data?.payload
                            ?.invoicedAmount}{" "}
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Payable Amount
                      </div>
                    </div>
                  </div>
                </div>
                {/* 2nd */}
                {singleDetailPendingRequest?.data?.payload?.reason && (
                  <div class=" justify-between  flex flex-col    border-b border-[#c2c2c2]/30 px-6  pb-10 ">
                    <div class="text-black text-lg font-semibold  mt-6">
                      Note from Supplier:{" "}
                    </div>

                    <div class=" text-[#949494] text-sm font-light  mt-4  leading-6">
                      {singleDetailPendingRequest?.data?.payload?.reason}
                    </div>
                  </div>
                )}
                {/* another header */}
                {/* header ends here */}
                {/* here */}
                <div class=" justify-between  flex flex-col    px-6  ">
                  <div class="text-black text-lg font-semibold  mt-6">
                    Funding Option
                  </div>

                  <div class=" text-[#949494] text-sm font-light  mt-4  leading-6">
                    This invoice would be funded from this virtual account
                  </div>

                  {/* stops */}

                  <div className=" mt-3">
                    <div className="text-[#7a7a7a]   font-medium mt-2  body text-[16px] ">
                      {singleDetailPendingRequest?.data?.payload?.bankDetails
                        ?.accountName &&
                        singleDetailPendingRequest?.data?.payload?.bankDetails
                          ?.accountName}{" "}
                    </div>
                    <div className="text-[#7a7a7a]  font-medium  mt-2  body text-[16px] ">
                      {singleDetailPendingRequest?.data?.payload?.bankDetails
                        ?.accountNo &&
                        singleDetailPendingRequest?.data?.payload?.bankDetails
                          ?.accountNo}{" "}
                    </div>
                    <div className="text-[#7a7a7a]  font-medium  mt-2  body text-[16px] ">
                      {singleDetailPendingRequest?.data?.payload?.bankDetails
                        ?.bankName &&
                        singleDetailPendingRequest?.data?.payload?.bankDetails
                          ?.bankName}{" "}
                    </div>
                  </div>
                </div>
              </div>
              {/* stops here */}
              {/* Third */}
              {/* third stops */}
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};

export default ApprovalViaWalletScreenDetails;
