import DashboardLayout from "layout/dashboardlayout";
import React from "react";
import Details from "./details";

const PFPRejectedInvoiceDetails = () => {
  return (
    <DashboardLayout
    section="Payable Finance Program"
    subHeader="Here is an overview of your PFP"
    >
      <Details />
    </DashboardLayout>
  );
};

export default PFPRejectedInvoiceDetails;
