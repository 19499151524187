import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import LoadingScreen from "shared/LoadingScreen";
import {
  PAIconArrowLeftGreen,
  PAIconCocacola,
  PAIconDownload,
} from "assets/images/svgs";
import TransitionScale from "shared/animation/TransitionScale";
import { useEffect, useState } from "react";
import { formatCapitaliseString } from "utilities/helper/formatCapitaliseString";
import { formatCapitalize } from "utilities/formatCapitalize";
import { formatCurrency } from "utilities/helper/formatters";
import {
  fetchAllClosedMarketIngoingBidDetails,
  funderEarlyPaymentViewBulkApprovedDetails,
} from "appstate/funder/funderSlice";
import { getDirectFundingBulkDetails } from "appstate/invoice/invoiceSlice";
import TooltipShared from "shared/tooltip/Tooltip";
import { disbursementFunderTransactionDetailsById } from "appstate/disbursement/disbursementSlice";
import { getDaysLeft } from "utilities/getDaysLeft";

const Details = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { disbursementFunderTransactionDetailsByIdData } = useSelector(
    (state) => state?.disbursement
  );
  const { funderEarlyPaymentViewBulkApprovedDetailsData, ongoingBidDetails } =
    useSelector((state) => state?.funder);
  const { getDirectFundingBulkDetailsData } = useSelector(
    (state) => state?.invoice
  );

  const offerId = searchParams.get("offerId");
  const fundingChannel = searchParams.get("channel");
  const { isLoading } = useSelector((state) => state?.invoice);
  const [selectedTab, setSelectedTab] = useState("tab1");

  const data =
    fundingChannel === "DIRECT_FUNDING"
      ? getDirectFundingBulkDetailsData?.data
      : fundingChannel === "DIRECT_FUNDING_EARLY_PAYMENT_REQUEST"
      ? funderEarlyPaymentViewBulkApprovedDetailsData?.data
      : fundingChannel === "CLOSED_MARKET"
      ? ongoingBidDetails?.closedMarketRequestDetails
      : [];

  const detailsData = [
    {
      label: "Transfer from",
      value: formatCapitalize(
        disbursementFunderTransactionDetailsByIdData?.data?.transferFrom
      ),
    },
    {
      label: "Transfer to",
      value: formatCapitalize(
        disbursementFunderTransactionDetailsByIdData?.data?.transferTo
      ),
    },
    {
      label: "Status",
      value: formatCapitalize(
        disbursementFunderTransactionDetailsByIdData?.data?.status
      ),
      statusColor: "text-[#07593D]",
    },
    {
      label: "Payment Date",
      value: disbursementFunderTransactionDetailsByIdData?.data?.paymentDate,
      statusColor: "text-[#000]",
    },
    {
      label: "Transaction ID",
      value: disbursementFunderTransactionDetailsByIdData?.data?.transactionRef,
      statusColor: "text-[#0076EC]",
    },
    {
      label: "Payment Type",
      value: formatCapitaliseString(
        disbursementFunderTransactionDetailsByIdData?.data?.paymentType
      ),
    },
    {
      label: "Virtual account name",
      value: formatCapitalize(
        disbursementFunderTransactionDetailsByIdData?.data?.accountName
      ),
      statusColor: "text-[#07593D]",
    },
    {
      label: "Virtual account no",
      value: `${disbursementFunderTransactionDetailsByIdData?.data?.accountNo?.slice(
        0,
        3
      )}*****${disbursementFunderTransactionDetailsByIdData?.data?.accountNo?.slice(
        7,
        11
      )}`,
      statusColor: "text-[#07593D]",
    },
    {
      label: "Payment Method",
      value: disbursementFunderTransactionDetailsByIdData?.data?.paymentMethod,
      statusColor: "text-[#07593D]",
    },
    {
      label: "Funding Channel",
      value: formatCapitaliseString(
        disbursementFunderTransactionDetailsByIdData?.data?.fundingChannel
      ),
      statusColor: "text-[#F08000]",
    },
    {
      label: "Description from Sender",
      value: formatCapitaliseString(
        disbursementFunderTransactionDetailsByIdData?.data?.description
      ),
    },
  ];
  useEffect(() => {
    dispatch(disbursementFunderTransactionDetailsById({ id }));

    if (fundingChannel === "CLOSED_MARKET") {
      dispatch(
        fetchAllClosedMarketIngoingBidDetails({ closeMarketId: offerId })
      );
    }

    if (fundingChannel === "DIRECT_FUNDING") {
      dispatch(getDirectFundingBulkDetails({ id: offerId }));
    }

    if (fundingChannel === "DIRECT_FUNDING_EARLY_PAYMENT_REQUEST") {
      dispatch(funderEarlyPaymentViewBulkApprovedDetails(offerId));
    }
  }, [offerId, id, dispatch, fundingChannel]);

  if (isLoading) {
    return <LoadingScreen />;
  }
  return (
    <>
      <header className="w-full pt-2 pb-4">
        <span
          onClick={() => navigate(-1)}
          className="flex cursor-pointer items-center font-medium text-sm text-primaryColor gap-2"
        >
          <PAIconArrowLeftGreen /> Go back
        </span>
      </header>{" "}
      <div className="flex flex-col lg:flex-row justify-between gap-6">
        {/* 60% */}
        <TransitionScale className="py-5 px-6 lg:w-[58%] flex flex-col gap-8 min-h-[90vh] bg-white rounded-[10px]">
          <header className="flex flex-col gap-3">
            <section className="flex justify-between  items-center">
              <span className="text-[18px] flex items-center font-bold">
                Reference No: |{" "}
                <TooltipShared
                  text={
                    data?.requestReferenceNumber ??
                    data?.closedMarketReference ??
                    "N/A"
                  }
                  textSize="text-lg"
                  maxLength={15}
                />
              </span>
            </section>

            <section className="flex justify-between">
              <div className="flex flex-col gap-1 w-[40%]">
                <section className="flex items-center justify-between">
                  <span className="text-xs font-semibold">Request Date</span>
                  <span className="text-[#7a7a7a] text-xs font-semibold">
                    {data?.requestDate ??
                      ongoingBidDetails?.invoiceSummary?.createdDate ??
                      "N/A"}
                  </span>
                </section>

                <section className="flex items-center justify-between">
                  <span className="text-xs font-semibold">
                    Total Request Amount
                  </span>
                  <span className="text-[#7a7a7a] text-xs font-semibold">
                    {formatCurrency(
                      data?.totalRequestAmount ??
                        ongoingBidDetails?.invoiceSummary?.totalRequestAmount ??
                        0
                    )}
                  </span>
                </section>

                <section className="flex items-center justify-between">
                  <span className="text-xs font-semibold">
                    Total{" "}
                    {data?.totalInvoiceAmount ||
                    ongoingBidDetails?.invoiceSummary?.totalInvoiceAmount
                      ? "Invoice"
                      : "Payable"}{" "}
                    Amount
                  </span>
                  <span className="text-[#7a7a7a] text-xs font-semibold">
                    {formatCurrency(
                      data?.totalInvoiceAmount ??
                        data?.totalPayableAmount ??
                        ongoingBidDetails?.invoiceSummary?.totalInvoiceAmount ??
                        0
                    )}
                  </span>
                </section>
              </div>

              <div className="flex flex-col gap-1">
                <motion.button
                  whileTap={{ scale: 0.95 }}
                  className="py-2.5 px-6 text-xs rounded bg-white text-primaryColor font-medium border border-primaryColor/10"
                >
                  {formatCapitaliseString(
                    data?.status ??
                      ongoingBidDetails?.closedMarketRequestDetails
                        ?.closedMarketStatus
                  )}
                </motion.button>
              </div>
            </section>
          </header>

          <section className="flex items-center justify-between">
            <div className="flex flex-col gap-2">
              <span className="text-[18px] font-semibold">
                {" "}
                {formatCapitalize(data?.buyerCompanyName) ?? "N/A"}
              </span>
              <span className="text-[#7A7A7A] text-xs font-light">
                Buyer Details
              </span>
            </div>

            {/* <div className="flex flex-col gap-2">
              <span className="text-[18px] font-semibold">60 Days</span>
              <span className="text-[#7A7A7A] text-xs font-light">Payment</span>
            </div> */}

            <div className="flex flex-col gap-2">
              <span className="text-[18px] flex justify-end font-semibold">
                {data?.currency ?? "NGN"}
              </span>
              <span className="text-[#7A7A7A] text-xs font-light">
                Currency
              </span>
            </div>
          </section>

          <section className="flex flex-col gap-4">
            <header className="flex items-center justify-between pb-3 border-b-[5px] border-[#F6F5FA] text-[#11192A]">
              <span className="text-sm">Invoice List</span>

              <span className="text-[18px] font-light">
                {data?.noOfInvoices ??
                  ongoingBidDetails?.bulkInvoice?.data?.length ??
                  0}{" "}
                Invoices{" "}
              </span>
            </header>

            <div className="w-full overflow-x-auto mt-3 pb-4">
              <table className="w-full overflow-x-auto">
                <thead className="bg-subtleGray w-full p-6  flex-nowrap whitespace-nowrap">
                  <tr className=" bg-unusualGray  px-6 w-full flex-nowrap whitespace-nowrap ">
                    {/* <td className="text-[#11192A] p-2.5 text-xs font-bold">
                        Item Number
                      </td> */}
                    <td class="text-[#11192a] text-xs font-semibold  p-4  flex-nowrap whitespace-nowrap ">
                      Inv Number{" "}
                    </td>
                    <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                      Due Date{" "}
                    </td>
                    <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                      Req Amount
                    </td>
                    <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                      Discount Rate
                    </td>

                    <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                      Receivable Amount
                    </td>
                  </tr>
                </thead>

                <tbody className="w-full">
                  {(fundingChannel === "DIRECT_FUNDING"
                    ? data?.directFundingDiscountResponseSingles
                    : fundingChannel === "CLOSED_MARKET"
                    ? ongoingBidDetails?.bulkInvoice?.data
                    : data?.earlyPaymentInvoiceResponse
                  )?.map((cell, idx) => (
                    <tr key={idx} className="w-full">
                      <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                        {cell?.invoiceReference ?? cell?.invoiceRef}
                      </td>
                      <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                        {cell?.invoiceDueDate ??
                          cell?.requestDate ??
                          cell?.dueDate}
                      </td>
                      <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                        {formatCurrency(cell?.requestAmount)}
                      </td>
                      <td className="text-[#11192a] text-xs font-semibold mt-2 text-center flex-nowrap whitespace-nowrap">
                        {cell?.discount ?? cell?.discountRate ?? "-"}%
                      </td>
                      <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                        {formatCurrency(
                          cell?.receivableAmount ??
                            cell?.payableAmount ??
                            cell?.fundableAmount
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className=" px-6   py-5 bg-neutral-50 flex-col justify-center items-start  inline-flex bg-[#fafafa] w-full border-t-[0.2px]  border-t-[#b1a6b2]">
              <div className="lg:flex md:flex justify-between  w-full sm:block ">
                <div>
                  <section>
                    {" "}
                    <div class="text-[#222222] text-lg  font-medium border-b-[0.2px]  border-[#b1a6b2]">
                      Receiving Funding Account Information
                    </div>
                    <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                      {data?.buyerVirtualAccountNumber?.slice(0, 2)}*******
                      {data?.buyerVirtualAccountNumber?.slice(7, 11)}
                    </div>
                    <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                      {formatCapitalize(data?.buyerVirtualAccountName) ?? "N/A"}
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </section>
        </TransitionScale>
        {/* 40% */}
        <TransitionScale className="bg-white w-full lg:w-[48%]  rounded-[10px] gap-8 px-6 pt-7 pb-24">
          <div>
            <header className="py-2 flex gap-8 justify-center">
              <span
                onClick={() => setSelectedTab("tab1")}
                className={`${
                  selectedTab === "tab1"
                    ? "text-primaryColor font-semibold border-b border-primaryColor"
                    : "text-[#959595]"
                } py-2.5 cursor-pointer`}
              >
                {fundingChannel === "DIRECT_FUNDING_EARLY_PAYMENT_REQUEST"
                  ? "Early Payment"
                  : formatCapitaliseString(fundingChannel)}{" "}
                Details
              </span>
              <span
                onClick={() => setSelectedTab("tab2")}
                className={`${
                  selectedTab === "tab2"
                    ? "text-primaryColor  font-semibold border-b border-primaryColor"
                    : "text-[#959595]"
                } py-2.5 cursor-pointer`}
              >
                Transaction Details{" "}
              </span>
            </header>
            {selectedTab === "tab1" ? (
              <div>
                <div class="w-full justify-start items-center gap-4 inline-flex ">
                  <div class=" justify-start items-center gap-2.5 flex w-full px-6  py-3">
                    <div class="text-black text-xl font-bold">
                      {fundingChannel === "DIRECT_FUNDING_EARLY_PAYMENT_REQUEST"
                        ? "Early Payment"
                        : formatCapitaliseString(fundingChannel)}{" "}
                      Details
                    </div>
                  </div>
                </div>

                <div>
                  <div className="   pb-4">
                    <div class=" justify-between  flex flex-row    px-6 mt-4  ">
                      <div className="flex gap-2 py-2.5">
                        <PAIconCocacola />
                        <div className="flex flex-col">
                          {/* <span className=" text-black text-xs font-normal">
                      Coca Cola
                    </span> */}
                          <span class="text-black text-sm font-normal ">
                            {formatCapitalize(data?.supplierCompanyName) ??
                              "N/A"}
                          </span>
                          {/* <span className="text-[#979797]  text-xs">Supplier</span> */}
                          <span class="text-[#949494] text-sm font-normal ">
                            Supplier
                          </span>
                        </div>
                      </div>
                      <div class="py-2.5 flex-col justify-start items-start inline-flex">
                        <div class="flex-col justify-start items-start gap-0.5 flex">
                          <div class="text-black text-sm font-normal ">
                            {data?.lockRate ?? data?.discountRate ?? "-"}%
                          </div>
                        </div>
                        <div class="flex-col justify-start items-start  flex">
                          <div class="text-[#949494] text-sm font-normal ">
                            Discount rate
                          </div>
                        </div>
                      </div>

                      {/* here */}
                    </div>
                    <div class=" justify-between  flex flex-row    px-6  mt-6 ">
                      <div class="py-2.5 flex-col justify-start items-start inline-flex">
                        <div class="flex-col justify-start items-start gap-0.5 flex">
                          <div class="text-black text-sm font-normal ">
                            NGN{" "}
                            {formatCurrency(
                              data?.totalDiscountAmount ??
                                data?.discountAmount ??
                                0
                            )}
                          </div>
                        </div>
                        <div class="flex-col justify-start items-start  flex">
                          <div class="text-[#949494] text-sm font-normal ">
                            Discount amount
                          </div>
                        </div>
                      </div>
                      {/* hd */}
                      <div class="py-2.5 flex-col justify-start items-start inline-flex">
                        <div class="flex-col justify-start items-start gap-0.5 flex">
                          <div class="text-black text-sm font-normal ">
                            {data?.earlyPaymentDate} (
                            {getDaysLeft(data?.earlyPaymentDate)} days)
                          </div>
                        </div>
                        <div class="flex-col justify-start items-start  flex">
                          <div class="text-[#949494] text-sm font-normal ">
                            Early Payment Date
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                    <div class=" justify-between  flex flex-row    border-b border-[#c2c2c2]/30 px-6  py-3  pb-8 mt-6">
                      <div class="py-2.5 flex-col justify-start items-start inline-flex">
                        <div class="flex-col justify-start items-start gap-0.5 flex">
                          <div class="text-black text-sm font-normal ">
                            N{" "}
                            {formatCurrency(
                              data?.totalRequestAmount ??
                                data?.requestAmount ??
                                0
                            )}
                          </div>
                        </div>
                        <div class="flex-col justify-start items-start  flex">
                          <div class="text-[#949494] text-sm font-normal ">
                            Request amount
                          </div>
                        </div>
                      </div>
                      {/* dhdh */}
                      <div class="py-2.5 flex-col justify-start items-start inline-flex">
                        <div class="flex-col justify-start items-start gap-0.5 flex">
                          <div class="text-black text-sm font-normal ">
                            N{" "}
                            {formatCurrency(
                              data?.totalPayableAmount ??
                                data?.fundableAmount ??
                                0
                            )}
                          </div>
                        </div>
                        <div class="flex-col justify-start items-start  flex">
                          <div class="text-[#949494] text-sm font-normal ">
                            Fundable Amount
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* 2nd */}
                    <div class=" justify-between  flex flex-col    border-b border-[#c2c2c2]/30 px-6  pb-10 ">
                      <div class="text-black text-lg font-semibold  mt-6">
                        Note from Supplier:{" "}
                      </div>

                      <div class=" text-[#949494] text-sm font-light  mt-4  leading-6">
                        {data?.description ?? data?.note ?? "No Description"}
                        <br className=" mt-3" />
                        Best regards, <br />
                        {formatCapitalize(data?.supplierCompanyName)}
                      </div>
                    </div>{" "}
                    {/* another header */}
                    {/* header ends here */}
                    {/* here */}
                    <div class="justify-between  flex flex-col  px-6">
                      <div class="text-black text-lg font-semibold  mt-6">
                        Funding Option
                      </div>

                      <div class=" text-[#949494] font-light  my-3 leading-6">
                        This invoice would be funded from this virtual account:
                      </div>
                    </div>
                    <div
                      style={{ color: "rgba(0, 0, 0, 0.50)" }}
                      className="flex flex-col px-6 text-sm font-semibold"
                    >
                      <span>
                        {formatCapitalize(data?.buyerVirtualAccountName) ??
                          "N/A"}
                      </span>
                      <span>
                        {data?.buyerVirtualAccountNumber?.slice(0, 2)}
                        *******
                        {data?.buyerVirtualAccountNumber?.slice(7, 11)}
                      </span>
                      <span> PayEdge MFB</span>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="flex justify-between items-center mb-4">
                  <h2 className="font-semibold text-lg leading-[30px]">
                    {fundingChannel === "DIRECT_FUNDING_EARLY_PAYMENT_REQUEST"
                      ? "Early Payment"
                      : formatCapitaliseString(fundingChannel)}{" "}
                    Transaction Details
                  </h2>
                </div>

                <div className="flex justify-between my-3 border-b-[3px] border-b-[#F6F5FA] pb-5">
                  <div>
                    <h5 className="text-base font-normal">Amount</h5>
                    <h2 className="text-red text-2xl font-normal">
                      {formatCurrency(
                        disbursementFunderTransactionDetailsByIdData?.data
                          ?.amount
                      )}{" "}
                      NGN
                    </h2>
                  </div>

                  <div>
                    <button className="border flex items-center gap-2 text-sm border-gray300 rounded px-3 py-1.5">
                      <span> Export</span>
                      <span>
                        <PAIconDownload />
                      </span>
                    </button>
                  </div>
                </div>
                <div className="mt-8">
                  <div className="flex flex-col gap-3">
                    {detailsData.map((item, index) => (
                      <div key={index} className="mb-4 flex justify-between">
                        <div>
                          <p className="text-[#222] font-medium text-sm">
                            {item.label}
                          </p>
                        </div>
                        <div className="flex-1 ml-4 max-h-[50px]">
                          <p className="text-[#979797] font-medium text-sm flex justify-end text-right">
                            <span className={item.statusColor}>
                              {item.value}
                            </span>
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </TransitionScale>
      </div>
    </>
  );
};

export default Details;
