import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";
import { PAIconArrowDownThin } from "assets/images/svgs";
import { useSelector } from "react-redux";
import { formatCapitalize } from "utilities/formatCapitalize";
import { formatCurrency } from "utilities/helper/formatters";

function LienApproveCard({
  sectA = "Total Money Liened",
  sectB = "Total Approved Early Payment Request ",
  setSelectedVAId,
  sectAValue = 0,
  sectBValue = 0,
}) {
  const { allVirtualAccountsData } = useSelector((state) => state?.wallet);
  const { isLoading } = useSelector((state) => state?.disbursement);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(
    allVirtualAccountsData?.data[0]
  );

  const toggleDropdown = () => setDropdownOpen((prev) => !prev);

  const selectAccount = (account) => {
    setSelectedAccount(account);
    setDropdownOpen(false);
  };

  const closeDropdown = () => setDropdownOpen(false);

  // Framer Motion Variants for the dropdown animation
  const dropdownVariants = {
    hidden: { opacity: 0, y: -10 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.3 } },
    exit: { opacity: 0, y: -10, transition: { duration: 0.3 } },
  };

  // Framer Motion Variants for the parent animation
  const parentVariants = {
    initial: { opacity: 0, y: -10 },
    animate: { opacity: 1, y: 0, transition: { duration: 0.5 } },
    exit: { opacity: 0, y: -10, transition: { duration: 0.5 } },
  };

  return (
    <motion.div
      className="w-full lg:w-[60%] relative"
      initial="initial"
      animate="animate"
      exit="exit"
      variants={parentVariants} // Apply parent animation
    >
      {/* Header Section */}
      <header
        className="px-6 rounded-t border-b border-[#eee] bg-[#fff] text-[15.131px] py-3 flex items-center justify-between cursor-pointer relative z-20"
        onClick={toggleDropdown}
      >
        <span>
          {formatCapitalize(selectedAccount?.accountName)} |{" "}
          <span className="italic text-[#222] font-[600]">
            {selectedAccount?.accountNumber}
          </span>{" "}
          | <span className="font-[500]">{"PayEdge MFB"}</span>{" "}
        </span>
        <span>
          <PAIconArrowDownThin />
        </span>

        {/* Dropdown Menu with Animation */}
        <AnimatePresence>
          {isDropdownOpen && (
            <motion.ul
              className="absolute top-full left-0 w-full bg-[#fff] shadow-md rounded-b py-3 z-30"
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={dropdownVariants}
            >
              {allVirtualAccountsData?.data?.map((account, index) => (
                <li
                  key={index}
                  className="py-2 px-6 cursor-pointer hover:bg-gray-100"
                  onClick={() => {
                    setSelectedVAId(account?.virtualAccountId);
                    selectAccount(account || []);
                    toggleDropdown();
                  }}
                >
                  {formatCapitalize(account?.accountName)} |{" "}
                  <span className="italic">{account?.accountNumber}</span> |{" "}
                  {account?.bank || "PayEdge MFB"} | {account?.currency}{" "}
                  {formatCurrency(account?.balance)}
                </li>
              ))}
            </motion.ul>
          )}
        </AnimatePresence>
      </header>

      {/* Overlay */}
      {isDropdownOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-25 z-10"
          onClick={closeDropdown}
        />
      )}

      {/* Main Section */}
      <main className="px-6 bg-[#fff] w-full rounded-b py-3">
        <div className="flex flex-col sm:flex-row justify-between lg:justify-normal">
          <section className="flex min-w-[290px] flex-col gap-2.5 py-8 pr-9 border-r border-[#C5C5C5]">
            <div>
              <span className="font-light mr-0.5 text-[18px] text-primaryColor">
                NGN
              </span>
              <span
                className={`text-[28px] 2xl:text-[32px] text-primaryColor font-medium ${
                  isLoading ? "animate-pulse" : ""
                }`}
              >
                {formatCurrency(sectAValue)}
              </span>
            </div>

            <span className="text-[#7a7a7a] text-[15px] font-light">
              {sectA}
            </span>
          </section>

          <section className="flex flex-col items-center gap-2.5 py-8 w-fit pl-9">
            <div className="flex justify-center">
              <span
                className={`text-[28px] 2xl:text-[32px] text-primaryColor font-medium ${
                  isLoading ? "animate-pulse" : ""
                }`}
              >
                {sectBValue}
              </span>
            </div>

            <span className="text-[#7a7a7a] text-center text-[15px] font-light flex justify-center">
              {sectB}
            </span>
          </section>
        </div>
      </main>
    </motion.div>
  );
}

export default LienApproveCard;
