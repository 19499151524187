// import { PAIconPaymnet } from "assets/images/svgs";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { setExternalAccountFormData } from "appstate/wallet/walletSlice";
import Spinner from "shared/Spinner/Spinner";
import { PAIconPaymentGreen } from "assets/images/svgs";
import { fundAccountValidationSchema } from "validation/wallet/fundAccount";

const AccountTo = ({
  isOpen,
  onClose,
  onSubmit,
  accountFromBack,
  accountToBack,
}) => {
  const secondSelectedAccounts = useSelector(
    (state) => state?.wallet?.secondSelectedAccounts
  );

  const selectedAccounts = useSelector(
    (state) => state?.wallet?.selectedAccounts
  );

  // const { isLoading, externalAccountFormData } = useSelector((state) => state?.wallet);

  const dispatch = useDispatch();

  //   const handleSubmit = () => {
  //     onSubmit();
  //   };

  const handleAccountFromBack = () => {
    accountFromBack();
  };

  const handleAccountToBack = () => {
    accountToBack();
  };

  const formik = useFormik({
    initialValues: {
      amount: "",
      narration: "",
    },
    validationSchema: fundAccountValidationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      // console.log("Values being sent to Redux:", values);
      // console.log(selectedAccounts?.accountNumber)
      // console.log(secondSelectedAccounts?.accountNumber)
      // console.log(externalAccountFormData)
      dispatch(setExternalAccountFormData(values));
      resetForm();
      setSubmitting(false);
      onSubmit(values);
    },
  });

  const formatAmount = (amount) => {
    if (!amount) return "0.00";
    const parsedAmount = parseFloat(amount);
    if (isNaN(parsedAmount)) return "0.00";
    return parsedAmount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  if (!isOpen) return null;

  return (
    <>
      {/* {showOptionModal && ( */}
      <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg rounded-md z-[1000]">
        <div className="bg-white rounded-md max-w-xs md:max-w-lg w-full pb-5">
          <div className="flex justify-between items-center mb-4 bg-[#FAFAFA]">
            <p className="text-black text-base font-bold p-3 px-5 rounded-sm italic">
              Fund Account
            </p>
            <button
              onClick={onClose}
              className="text-black font-sans text-xl cursor-pointer flex justify-center items-center"
            >
              &times; <span className="text-sm mx-2 mt-1">Close</span>
            </button>
          </div>

          {/* ACCOUNTS */}
          <div>
            <div className="w-full px-7 flex flex-wrap justify-center">
              <div className="md:w-1/2 pr-3">
                <p className="text-xs">From - Virtual Account</p>
                <div
                  className="bg-[#FFFAF4] border-[0.8px] border-[#F08000] p-3 mt-3 cursor-pointer text-[#07593D] text-xs rounded"
                  onClick={() => {
                    //   dispatch(setSelectedAccounts(account));
                    //   setIsWithdrawFund(true);
                    handleAccountFromBack();
                  }}
                >
                  <div className="flex justify-between">
                    <div className="flex items-center font-bold">
                      <PAIconPaymentGreen className="mr-2 text-sm" />{" "}
                      <p>{selectedAccounts.name}</p>
                    </div>
                    <p className="font-light text-xs my-2 whitespace-nowrap">
                      {selectedAccounts.accountType}
                    </p>
                  </div>
                  <p className="mt-2 italic font-bold text-[18px] flex justify-start">
                  {selectedAccounts.currency}
                   {formatAmount(selectedAccounts.amount)}
                  </p>
                  <p className="mt-5 text-xs">
                    <span className="mr-1">{selectedAccounts.bankName}</span> |
                    <span className="ml-1">
                      {selectedAccounts.accountNumber}
                    </span>
                  </p>
                </div>
                <p
                  className="flex justify-end text-xs my-2 cursor-pointer"
                  onClick={handleAccountFromBack}
                >
                  Change account
                </p>
              </div>
              <div className="md:w-1/2 pl-3">
                <p className="text-xs">To - Another Virtual Account</p>
                <div
                  className="bg-[#F8FDFB] border-[0.8px] border-[#07593D] p-3 mt-3 cursor-pointer text-[#07593D] text-xs rounded"
                  onClick={() => {
                    //   dispatch(setSelectedAccounts(account));
                    //   setIsWithdrawFund(true);
                    handleAccountToBack();
                  }}
                >
                  <div className="flex justify-between">
                    <div className="flex items-center font-bold">
                      <PAIconPaymentGreen className="mr-2 text-sm" />{" "}
                      <p>{secondSelectedAccounts.name}</p>
                    </div>
                    <p className="font-light text-xs my-2 whitespace-nowrap">
                      {secondSelectedAccounts.accountType}
                    </p>
                  </div>
                  <p className="mt-2 italic font-bold text-[18px] flex justify-start">
                  {selectedAccounts.currency} {formatAmount(secondSelectedAccounts.amount)}
                  </p>
                  <p className="mt-5 text-xs">
                    <span className="mr-1">
                      {secondSelectedAccounts.bankName}
                    </span>{" "}
                    |
                    <span className="ml-1">
                      {secondSelectedAccounts.accountNumber}
                    </span>
                  </p>
                </div>
                <p
                  className="flex justify-end text-xs my-2 cursor-pointer"
                  onClick={handleAccountToBack}
                >
                  Change account
                </p>
              </div>
            </div>

            <form className="w-full mt-10 px-7" onSubmit={formik.handleSubmit}>
              <div className="mb-4">
                <label className="text-[#222] font-sans text-sm font-normal mb-2">
                  Amount
                </label>
                <input
                  type="number"
                  name="amount"
                  placeholder="Enter amount"
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  value={formik?.values?.amount}
                  className={`border w-full py2 border-t-0 border-x-0 focus:border-[#222] focus:outline-none ${
                    formik.touched.amount && formik.errors.amount
                      ? "border-red"
                      : ""
                  }`}
                />
                {formik.touched.amount && formik.errors.amount && (
                  <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                    {formik?.errors?.amount}
                  </p>
                )}
              </div>
              <div className="mb-4">
                <label className="text-[#222] font-sans text-sm font-normal mb-2">
                  Description
                </label>
                <input
                  type="text"
                  name="narration"
                  placeholder="Enter description"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.narration}
                  className={`border w-full py2 border-t-0 border-x-0 focus:border-[#222] focus:outline-none ${
                    formik.touched.narration && formik.errors.narration
                      ? "border-red"
                      : ""
                  }`}
                />
                {formik.touched.narration && formik.errors.narration && (
                  <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                    {formik?.errors?.narration}
                  </p>
                )}
              </div>

              {/* Buttons */}
              <div className="flex justify-end mt-10">
                <div className="w-[105px] h-[35px] px-6 py-2 rounded-[5px] justify-center items-center gap-2.5 inline-flex mx-2">
                  <button
                    type="button"
                    onClick={onClose}
                    className="text-center text-gray600 text-base font-normal tracking-wide"
                  >
                    Cancel
                  </button>
                </div>
                <div
                  className="w-[105px] h-[35px] px-6 py-2 rounded-[5px] justify-center items-center gap-2.5 inline-flex bg-[#07593D] cursor-pointer"
                  // onClick={onSubmit}
                >
                  <button
                    className="text-center text-white text-base font-normal tracking-wide"
                    type="submit"
                    disabled={formik.isSubmitting ? true : null}
                  >
                    {formik.isSubmitting ? <Spinner /> : "Next"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountTo;
