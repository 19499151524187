import { motion } from "framer-motion";
import { PAIconArrowLeftGreen } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import TransitionScale from "shared/animation/TransitionScale";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DirectFundingInvoice from "./earlyPaymentInvoice";
import {
  supplierSingleEPRDetails,
  // supplierReceivableInvoice,
} from "appstate/collections/collectionSlice";
import { allInvoiceById } from "appstate/invoice/invoiceSlice";

function CollectionEarlyPaymentInvoice() {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const invoiceId = searchParams.get("invoice");

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(supplierSingleEPRDetails({ id }));
  }, [dispatch, id]);

  // useEffect(() => {
  //   dispatch(supplierReceivableInvoice({ invoiceId }));
  // }, [dispatch, invoiceId]);

  useEffect(() => {
    dispatch(allInvoiceById({ id: invoiceId }));
  }, [dispatch, invoiceId]);

  const { supplierSingleEPRDetailsData /* , supplierReceivableInvoiceData */ } =
    useSelector((state) => state?.collection);

  const { invoiceIdData } = useSelector((state) => state?.invoice);

  function handleGoBack() {
    navigate(-1);
  }

  const data = [
    {
      label1: supplierSingleEPRDetailsData?.data?.buyerCompanyName,
      value1: "Buyer",
      label2: supplierSingleEPRDetailsData?.data?.funderCompanyName,
      value2: "Funder",
    },
    {
      label1: supplierSingleEPRDetailsData?.data?.discountAmount?.toLocaleString(),
      value1: "Discount Amount",
      value2: "Discount Rate",
      label2: supplierSingleEPRDetailsData?.data?.discountRate,
    },
    {
      label1: supplierSingleEPRDetailsData?.data?.requestDate,
      value1: "Request Date",
      label2: supplierSingleEPRDetailsData?.data?.earlyPaymentDate,
      value2: "Early Payment Date",
    },
    {
      label1: supplierSingleEPRDetailsData?.data?.requestAmount?.toLocaleString(),
      value1: "Request Amount",
      label2: supplierSingleEPRDetailsData?.data?.receivableAmount?.toLocaleString(),
      value2: "Receivable Amount",
    },
  ];

  return (
    <DashboardLayout
      section="Collections"
      subHeader="Track invoices due for payment collection"
    >
      <TransitionScale className="flex  flex-col gap-6 bg-[#EEE] h-full">
        <header onClick={handleGoBack} className="w-full">
          <div className="flex cursor-pointer w-fit gap-1 items-center">
            <PAIconArrowLeftGreen />
            <span className=" text-primaryColor text-sm">Go back</span>
          </div>
        </header>

        <main className="flex flex-col semi-lg:flex-row pb-8 gap-4 w-full ">
          <DirectFundingInvoice
            invoiceByIdData={invoiceIdData}
            // setLockModalOpen={setLockModalOpen}
          />

          <motion.section
            initial={{
              y: -20,
            }}
            animate={{
              y: 0,
            }}
            exit={{
              y: 20,
            }}
            transition={{
              delay: 0.8,
            }}
            className="flex flex-col gap-3 px-6  pt-7 pb-24 w-2/5 bg-white rounded-[10px]"
          >
            <header className="flex pb-2">
              <span className="font-bold">Early Payment Details</span>
            </header>

            <section className="flex flex-col gap-6">
              <div className="space-y-4">
                {data?.map((item, index) => (
                  <div
                    key={index}
                    className="flex justify-between items-center"
                  >
                    <span>
                      {item.label1} <br />
                      <span className="text-sm text-gray">{item.value1}</span>
                    </span>
                    <div className="flex flex-col items-end">
                      <span>{item.label2}</span>
                      <span className="text-sm text-gray">{item.value2}</span>
                    </div>
                  </div>
                ))}
              </div>
            </section>
          </motion.section>
        </main>
      </TransitionScale>
    </DashboardLayout>
  );
}

export default CollectionEarlyPaymentInvoice;
