import { PAIconArrowLeftGreen } from "assets/images/svgs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const DirectFundingInvoiceModal = ({
  isOpen,
  setSelectAccountModalOpen,
  setDirectFundingInvoiceModal,
  setInvoiceData,
  invoiceData,
  requestData,
  setRequestData
}) => {
  const [, setPaymentDate] = useState("20th October, 2024");
  const { invoiceIdData } = useSelector((state) => state?.invoice);
  const { pendingSingleEarlyPaymentDetailsData } = useSelector(
    (state) => state?.buyer
  );

  useEffect(() => {
    setInvoiceData(invoiceIdData.data);
    setRequestData(pendingSingleEarlyPaymentDetailsData.data);
  }, [
    invoiceIdData.data,
    setInvoiceData,
    pendingSingleEarlyPaymentDetailsData.data,
    setRequestData
  ]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className="bg-[rgba(0,0,0,0.2)] w-[100%] h-[100vh] flex justify-center items-center absolute left-0 top-0">
      <div className="p-6 max-w-5xl h-[75%] overflow-y-auto mx-auto bg-white rounded-[12px] shadow-lg">
        <div className="flex items-center w-full mb-3">
          <p
            className="flex gap-2"
            onClick={() => {
              setDirectFundingInvoiceModal(false);
            }}
          >
            <PAIconArrowLeftGreen /> Cancel
          </p>
        </div>
        {/* Invoice Header */}

        <div className="flex justify-between items-center mb-6">
          <div>
            <div className="flex items-center">
              <h1 className="text-2xl font-semibold mr-3">
                Invoice | INV-{invoiceData.invoiceNo}
              </h1>
              <p className="text-sm text-gray-600">
                Reference No: {invoiceData.invoiceRef}
              </p>
            </div>
            <div className="flex items-center">
              <p className="text-sm text-gray-600">
                Created Date: {invoiceData.createdDate}
              </p>
              <p className="mx-3">|</p>
              <p className="text-sm text-gray-600">
                Payment Due Date: {invoiceData.dueDate}
              </p>
            </div>
          </div>
          <button
            onClick={() => {
              setSelectAccountModalOpen(true);
              setDirectFundingInvoiceModal(false);
            }}
            className="px-10 py-2 bg-secondaryColor text-white rounded-[8px] hover:bg-orange-600"
          >
            Continue
          </button>
        </div>

        {/* Amount Details */}
        <div>
          <h2 className="text-lg font-semibold text-gray-700">
            Payable Amount
          </h2>
          <p className="text-[28px] font-bold text-primaryColor">
            NGN {requestData?.payableAmount?.toLocaleString()}
          </p>
          <p className="text-sm text-gray-600">
            Applied Percentage: {requestData?.discountRate}%
          </p>
        </div>

        <div className="flex items-center justify-between my-6">
          {/* Early Payment Request */}
          <div className="">
            <h3 className="text-lg font-semibold text-gray-700">
              Early Payment Request
            </h3>
            <div className="flex flex-col items-start">
              <label className="text-gray-600">New Payment Date:</label>
              <input
                type="date"
                value={requestData?.earlyPaymentDate}
                onChange={(e) => setPaymentDate(e.target.value)}
                className="w-80 max-w-sm px-4 py-2 border border-slate-400 rounded"
              />
            </div>
          </div>

          <div className="text-right">
            <h2 className="text-lg font-semibold text-gray-700">
              Request Amount
            </h2>
            <p className="text-[28px] font-semibold text-primaryColor">
              NGN {requestData.requestAmount.toLocaleString()}
            </p>
            <p className="text-sm text-red-600">{requestData?.discountRate}%</p>
          </div>
        </div>

        {/* Description */}
        <div className="mb-6">
          <h3 className="text-lg font-semibold text-gray-700">Description</h3>
          <textarea className="text-gray-400 border-[1px] border-slate-400 rounded-[4px] w-full px-2 py-1">
            {requestData?.description}
          </textarea>
        </div>

        {/* Buyer and Payment Terms */}
        <div className="flex items-center justify-between border-b-[2px] border-[#efefef] mb-6">
          <div>
            <h4 className="font-semibold text-gray-700">
              {invoiceData?.buyerCompanyName}
            </h4>
            <p className="text-gray-600">Buyer details</p>
          </div>
          <div>
            <h4 className="font-semibold text-gray-700">
              {invoiceData?.paymentTerms} Days
            </h4>
            <p className="text-gray-600">Payment</p>
          </div>
          <div>
            <h4 className="font-semibold text-gray-700">
              {invoiceData.currency}
            </h4>
            <p className="text-gray-600">Currency</p>
          </div>
        </div>

        {/* Line Items */}
        <div>
          <div className="flex items-center justify-between">
            <h3 className="text-lg font-semibold text-gray-700 mb-2">
              Line Items
            </h3>
            <h3 className="text-lg font-semibold text-gray-700 mb-2">
              {invoiceData?.items?.length} Total Item(s)
            </h3>
          </div>

          <div className=" w-full overflow-x-auto">
            <table className="overflow-x-auto w-[60vw]">
              <thead className="bg-subtleGray h-[70px] w-[50vw] overflow-x-auto">
                <tr className=" bg-unusualGray p-2.5">
                  <td className=" p-2.5 text-xs font-bold w-[120px]">Item</td>
                  <td className=" p-2.5 text-xs font-bold w-[120px]">
                    Description
                  </td>
                  <td className=" p-2.5 text-xs font-bold w-[120px]">
                    Unit Price
                  </td>
                  <td className=" p-2.5 text-xs font-bold w-[120px]">
                    Quantity
                  </td>
                  <td className="text-[#11192A] p-2.5 text-xs font-bold w-[120px]">
                    Amount
                  </td>
                  <td className="text-[#11192A] p-2.5 text-xs font-bold w-[120px]">
                    Tax rate
                  </td>
                  <td className=" p-2.5 text-xs font-bold w-[120px]">
                    Tax Amount
                  </td>
                  <td className=" p-2.5 text-xs font-bold w-[120px]">
                    Total Price
                  </td>
                </tr>
              </thead>
              {invoiceData?.items?.map((item, index) => (
                <tbody key={index}>
                  <tr className=" py-[3px] px-1.5">
                    <td className=" text-xs text-[#11192A] p-2.5">
                      {item?.itemName}
                    </td>
                    <td className=" text-xs text-[#11192A] p-2.5 w-[20%]">
                      {item?.description}
                    </td>
                    <td className=" text-xs text-[#11192A] p-2.5">
                      {item?.unitPrice}
                    </td>
                    <td className=" text-xs text-[#11192A] p-2.5">
                      {item?.quantity}
                    </td>
                    <td className=" text-xs text-[#11192A] p-2.5">
                      {item?.amount.toLocaleString()}
                    </td>
                    <td className=" text-xs text-[#11192A] p-2.5">
                      {item?.taxRate}
                    </td>
                    <td className=" text-xs text-[#11192A] p-2.5">
                      {item?.taxAmount.toLocaleString()}
                    </td>
                    <td className=" text-xs text-[#11192A] p-2.5">
                      {item?.totalPrice}
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
        </div>

        <div className="flex itemscenter justify-between px-6 mt-[10%]">
          <div>
            <h1 className="border-b border-[#B1A6B2] font-semibold">
              Receiving Funding Account Information
            </h1>
            <p className="mt-3 text[#979797]">
              {invoiceData?.virtualAccount?.accountNumber}
            </p>
            <p className="text[#979797]">
              {invoiceData?.virtualAccount?.accountName}
            </p>
          </div>
          <div className="flex flex-col">
            <div className="flex justify-between gap-x-[100px] w-full">
              <span className="text-xs w-[97px]">Sub Total</span>
              <span className="text-[#979797] text-xs text-right">
                {invoiceData?.subTotal.toLocaleString()}
              </span>
            </div>

            <div className="flex justify-between w-full gap-x-[100px] my-2">
              <span className="flex items-center text-xs w-[97px]">
                Discount%
                <span className="border border-[#979797] pr-10 pl-2 inline-block ml-4">
                  {invoiceData.discount}%
                </span>
              </span>
              <span className="text-[#979797] text-xs">
                {invoiceData.discountAmount.toLocaleString()}
              </span>
            </div>

            <div className="flex justify-between w-full gap-x-[100px] pb-5">
              <span className="flex items-center text-xs w-[97px]">
                VAT%
                <span className="border border-[#979797] ml-10 pr-11 pl-2 inline-block">
                  {invoiceData.vat}%
                </span>
              </span>
              <span className="text-[#979797] text-xs">
                {invoiceData.vatAmount.toLocaleString()}
              </span>
            </div>

            <div className="flex justify-between items-center w-full gap-x-[100px] border-t-2 border-b-2 font-bold text-lg py-1">
              <span className="w-[97px]">Grand Total</span>
              <span className="text[#979797] py-1">
                {invoiceData.grandTotal.toLocaleString()}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DirectFundingInvoiceModal;
