import request from "apiInstance";
import toast from "react-hot-toast";

export const createAgreement = async (body) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/agreement",
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const uploadAgreementFile = async (file) => {
  try {
    const body = new FormData();
    body.append("file", file);

    const response = await request({
      method: "post",
      url: "/api/v1/agreement/files",
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAgreementOverview = async () => {
  try {
    const response = await request({
      method: "get",
      url: "/api/v1/agreement/overview",
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAgreementStatusOverview = async () => {
  try {
    const response = await request({
      method: "get",
      url: "/api/v1/agreement/status-overview",
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllAgreement = async ({ page, pageSize }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/agreement/?${page}&${pageSize}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAgreementById = async ({ id }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/agreement/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const agreementUpdate = async ({ agreementId, status }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/agreement/approve/${agreementId}?approved=${status}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const agreementDelete = async ({ agreementId }) => {
  try {
    const response = await request({
      method: "delete",
      url: `/api/v1/agreement/${agreementId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
