import DashboardLayout from "layout/dashboardlayout";
import { useState } from "react";
import Button from "shared/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Search from "../../../assets/images/svgs/search-normal.svg";
import InputField from "shared/InputField";
import Card from "shared/Card";
import TableIcons from "../../../assets/images/svgs/TableIcons.svg";
import Table from "layout/table/Table";
import { useNavigate } from "react-router-dom";
import DownloadOptions from "shared/downloadoptions/downloadoptions";

const data = [
  {
    requestRefNo: "REQ001",
    invoiceRefNo: "INV001",
    buyer: "Buyer A",
    supplier: "Supplier A",
    dueDate: "2024-10-15",
    paymentDate: "2024-10-10",
    invoiceAmount: "1,500,000",
    requestAmount: "1,400,000",
    discountRate: "10%",
    paymentAmount: "1,350,000",
  },
  {
    requestRefNo: "REQ002",
    invoiceRefNo: "INV002",
    buyer: "Buyer B",
    supplier: "Supplier B",
    dueDate: "2024-10-20",
    paymentDate: "2024-10-18",
    invoiceAmount: "250,000,000",
    requestAmount: "240,000,000",
    discountRate: "5%",
    paymentAmount: "228,000,000",
  },
];

const SingleRequestData = [
  {
    requestRefNo: "REQ001",
    invoiceRefNo: "INV001",
    buyer: "Buyer A",
    supplier: "Supplier A",
    requestDate: "2024-10-15",
    noOfInvoices: "5",
    totalInvoiceAmount: "1,500,000",
    totalRequestAmount: "1,400,000",
    totalPayableAmount: "1,350,000",
  },
  {
    requestRefNo: "REQ002",
    invoiceRefNo: "INV002",
    buyer: "Buyer B",
    supplier: "Supplier B",
    requestDate: "2024-10-15",
    noOfInvoices: "3",
    totalInvoiceAmount: "1,900,000",
    totalRequestAmount: "2,400,000",
    totalPayableAmount: "2,350,000",
  },
];

const FunderPayableFinanceEarlyPayment = () => {
  const [selectedTab, setSelectedTab] = useState("Pending");
  const [tab, setTab] = useState("Single Request");
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [selectedInvoices, setSelectedInvoices] = useState({});
  const [showDownloadOptionsModal, setShowDownloadOptionsModal] = useState(false);
 
  const handleTabClick = (tabName) => {
    setSelectedTab(tabName);
  };

  const handleRequestTabClick = (tabName) => {
    setTab(tabName);
  };

  const HeaderWithIcon = ({ icon, text }) => (
    <div className="flex items-center">
      <img src={icon} alt="" className="mr-2" />
      <span>{text}</span>
    </div>
  );

  const onRowClicked = (row) => {
    let path = "/funder-single-early-payment-details-rejected";
    navigate(path, {state: {state: "rejected"}});
  };

  const onBulkRowClicked = (row) => {
    let path = "/funder-bulk-early-payment-details-rejected";
    navigate(path, {state: {state: "rejected"}});
  };

  const columnSingleRequest = [
    {
        name: <HeaderWithIcon icon={TableIcons} text="Request Ref No" />,
        selector: (row) => row?.requestRefNo,
      },
    {
      name: <HeaderWithIcon icon={TableIcons} text="Buyer" />,
      selector: (row) => row?.buyer,
    },
    {
      name: <HeaderWithIcon icon={TableIcons} text="Supplier" />,
      selector: (row) => row?.supplier,
    },
    {
      name: "Request Date",
      selector: (row) => row?.requestDate,
    },
    {
      name: "No of invoices",
      selector: (row) => row?.noOfInvoices,
    },
    {
      name: "Total Invoice Amount",
      selector: (row) => (
        <span style={{ color: "#2EA923" }}>{row?.totalInvoiceAmount}</span>
      ),
    },
    {
      name: "Total Request Amount",
      selector: (row) => (
        <span style={{ color: "#F08000" }}>{row?.totalRequestAmount}</span>
      ),
    },
    {
      name: "Total Payable Amount",
      selector: (row) => (
        <span style={{ color: "#F08000" }}>{row?.totalPayableAmount}</span>
      ),
    },
  ];

  const column = [
    {
      name: <HeaderWithIcon icon={TableIcons} text="Request Ref No" />,
      selector: (row) => row?.requestRefNo,
    },
    {
      name: <HeaderWithIcon icon={TableIcons} text="Invoice Ref" />,
      selector: (row) => row?.invoiceRefNo,
    },
    {
      name: <HeaderWithIcon icon={TableIcons} text="Buyer" />,
      selector: (row) => row?.buyer,
    },
    {
      name: <HeaderWithIcon icon={TableIcons} text="Supplier" />,
      selector: (row) => row?.supplier,
    },
    {
      name: "Due Date",
      selector: (row) => row?.dueDate,
    },
    {
      name: "Payment Date",
      selector: (row) => row?.paymentDate,
    },
    {
      name: "Invoice Amount",
      selector: (row) => (
        <span style={{ color: "#2EA923" }}>{row?.invoiceAmount}</span>
      ),
    },
    {
      name: "Request Amount",
      selector: (row) => (
        <span style={{ color: "#F08000" }}>{row?.requestAmount}</span>
      ),
    },
    {
      name: "Discount Rate",
      selector: (row) => (
        <span style={{ color: "#2EA923" }}>{row?.discountRate}</span>
      ),
    },
    {
      name: "Payment Amount",
      selector: (row) => (
        <span style={{ color: "#F08000" }}>{row?.paymentAmount}</span>
      ),
    },
  ];

  // eslint-disable-next-line
  const handleCheckboxChange = (invoiceReference) => {
    setSelectedInvoices((prevSelected) => ({
      ...prevSelected,
      [invoiceReference]: !prevSelected[invoiceReference],
    }));
  };

  const showDownloadOptions = () => {
    setShowDownloadOptionsModal(true);
  }

  const handleClose = () => {
    setShowDownloadOptionsModal(false);
  }

//   const handleSelectAll = () => {
//     if (
//       Object.keys(selectedInvoices).length === data.length &&
//       Object.keys(selectedInvoices).length > 0
//     ) {
//       setSelectedInvoices({});
//     } else {
//       const allSelected = {};
//       data.forEach((invoice) => {
//         allSelected[invoice.invoiceRefNo] = true;
//       });
//       setSelectedInvoices(allSelected);
//     }
//   };

//   const isAnySelected = Object.keys(selectedInvoices).some(
//     (key) => selectedInvoices[key]
//   );

  return (
    <DashboardLayout
      section="Early Payment Request"
      subHeader="Manage all your Early payment requests"
    >
      {showDownloadOptionsModal && <DownloadOptions handleCloseModal={handleClose} />}
      <div className="mt5 mx6 mb-[17px] w-full cursor-pointer rounded-md bg-white">
        <div className="gap1 flex-col items-center justify-between gap-10 p-4 px-4 md:flex md:flex-row">
          <div className="flex-col gap-10 md:flex md:flex-row">
            <div
              className={
                selectedTab === "Pending"
                  ? "rounded-l-sm rounded-r-sm border-[#07593D] font-bold text-[#07593D] md:mb-[-18px] md:border-b-4"
                  : "text-gray500 text-base font-normal"
              }
              onClick={() => handleTabClick("Pending")}
            >
              Pending
            </div>
            <div
              className={
                selectedTab === "Approved"
                  ? "rounded-l-sm rounded-r-sm border-[#07593D] font-bold text-[#07593D] md:mb-[-18px] md:border-b-4"
                  : "text-gray500 my-2 text-base font-normal md:my-0"
              }
              onClick={() => handleTabClick("Approved")}
            >
              Approved
            </div>
            <div
              className={
                selectedTab === "Rejected"
                  ? "rounded-l-sm rounded-r-sm border-[#07593D] font-bold text-[#07593D] md:mb-[-18px] md:border-b-4"
                  : "text-gray500 my-2 text-base font-normal md:my-0"
              }
              onClick={() => handleTabClick("Rejected")}
            >
              Rejected
            </div>
          </div>
        </div>
      </div>

      {selectedTab === "Rejected" && (
        <div>
          <div className="flex justify-between">
            <div className="flex ">
              <div className="flex gap-4 mb-4">
                <div className="relative">
                  <DatePicker
                    selected={startDate}
                    onChange={(update) => setDateRange(update)}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    placeholderText="Filters"
                    className="border  border-[#ccc] px-4 py-2 cursor-pointer rounded-md w-60"
                  />

                  <div className="absolute top-2/4 right-3 transform -translate-y-2/4 pointer-events-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 text-gray-400"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              <div className="flex ml-2">
                <Button
                  neutral
                  onClick={showDownloadOptions }
                  className="flex !text-[#565656] gap-2 text-xs font-medium items-center mb-[10px] md:mb-0 border border-[#ccc] bg-white py-[12px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px] h-[43px]"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M13.75 14.75H2.25C1.97344 14.75 1.75 14.5266 1.75 14.25V2.75C1.75 2.47344 1.97344 2.25 2.25 2.25H7.875C7.94375 2.25 8 2.30625 8 2.375V3.25C8 3.31875 7.94375 3.375 7.875 3.375H2.875V13.625H13.125V8.625C13.125 8.55625 13.1812 8.5 13.25 8.5H14.125C14.1938 8.5 14.25 8.55625 14.25 8.625V14.25C14.25 14.5266 14.0266 14.75 13.75 14.75ZM12.0448 3.61142L11.2292 2.7958C11.1558 2.72236 11.1995 2.5958 11.3027 2.5833L14.1058 2.25517C14.1855 2.2458 14.2542 2.31298 14.2448 2.39423L13.9167 5.19736C13.9042 5.30048 13.7777 5.34423 13.7042 5.2708L12.8855 4.45205L8.88234 8.45517C8.83391 8.50361 8.75422 8.50361 8.70578 8.45517L8.04328 7.79267C7.99484 7.74423 7.99484 7.66455 8.04328 7.61611L12.0448 3.61142Z"
                      fill="#565656"
                    />
                  </svg>
                  <p className="whitespace-nowrap">Export</p>
                </Button>
              </div>
            </div>
            <div className="flex items-center">
              <div className="relative flex items-center">
                <img
                  src={Search}
                  alt="Search Icon"
                  className="absolute left-3 w-4 h-4"
                />
                <InputField
                  placeholder="Search for requests"
                  className="pl-10 w-full py-2 border rounded-md border[#CCCCCC] outline-none md:w-[350px] !border-silver"
                />
              </div>

              <div className="ml-2">
                <Button
                  neutral
                  className="flex text-white  text-xs font-medium bg-[#2FA06A] items-center mb-[10px] md:mb-0  py-[12px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px]"
                >
                  <p className="whitespace-nowrap">Search</p>
                </Button>
              </div>
            </div>
          </div>
          <Card className="p-[24px] rounded-[10px]">
            <div className="flex justify-between">
              <div className="flex flex-col">
                <p className="mb-[10px] text-black text-sm font-medium md:mb-0">
                  Rejected Early Payment Requests
                </p>
                <p className="mb-[10px] text-xs font-normal text-textColor md:mb-0">
                  List of all rejected early payment requests you've rejected
                </p>
              </div>
              {/* {tab === "Bulk Request" && (
                <div className="flex">
                  <div className="pr-2">
                    <Button
                      neutral
                      onClick={handleSelectAll}
                      className={`py-[12px] mt-[10px] md:mt-0 px-[24px] rounded-[5px] font-medium ${
                        isAnySelected
                          ? "bg-[#2FA06A] text-sm !text-white"
                          : "bg-[#2FA06A] text-sm !text-white"
                      }`}
                    >
                      Select all
                    </Button>
                  </div>
                  <div>
                    <Button
                      neutral
                      className={`py-[12px] mt-[10px] md:mt-0 px-[24px] rounded-[5px] font-medium ${
                        isAnySelected
                          ? //   #F08000
                            "bg-[#F08000] text-sm !text-white"
                          : "bg-[#C2C0C0] text-sm !text-white"
                      }`}
                    >
                      Approve early payment
                    </Button>
                  </div>
                </div>
              )} */}
            </div>
            <br />
            <div className="mt5 mx6 mb-[17px] w-full cursor-pointer rounded-md bg-white">
              <div className="gap1 flex-col items-center justify-between gap-10 p-4 px-4 md:flex md:flex-row">
                <div className="flex-col gap-10 md:flex md:flex-row">
                  <div
                    className={
                      tab === "Single Request"
                        ? "rounded-l-sm rounded-r-sm border-[#F08000] font-bold text-[#F08000] md:mb-[-18px] md:border-b-4"
                        : "text-gray500 my-2 text-base font-normal md:my-0"
                    }
                    onClick={() => handleRequestTabClick("Single Request")}
                  >
                    Single Request
                  </div>
                  <div
                    className={
                      tab === "Bulk Request"
                        ? "rounded-l-sm rounded-r-sm border-[#F08000] font-bold text-[#F08000] md:mb-[-18px] md:border-b-4"
                        : "text-gray500 my-2 text-base font-normal md:my-0"
                    }
                    onClick={() => handleRequestTabClick("Bulk Request")}
                  >
                    Bulk Request
                  </div>
                </div>
              </div>
            </div>

            {tab === "Single Request" && (
              <div className=" bg-white rounded-b-[10px]">
                <Table
                  columns={column}
                  pointer
                  data={data}
                  onRowClicked={onRowClicked}
                />
              </div>
            )}

            {tab === "Bulk Request" && (
              <div className=" bg-white rounded-b-[10px]">
                <Table
                  columns={columnSingleRequest}
                  pointer
                  data={SingleRequestData}
                  onRowClicked={onBulkRowClicked }
                />
              </div>
            )}
          </Card>
        </div>
      )}
    </DashboardLayout>
  );
};

export default FunderPayableFinanceEarlyPayment;
