import Table from "layout/table/Table";
import { useNavigate } from "react-router-dom";
import TableEmptyState from "shared/TableEmptyState";
import Loading from "shared/Loading";
import { fetchTransactionHistory } from "appstate/buyer/buyerSlice";
import { useDispatch, useSelector } from "react-redux";
// import Pagination from "shared/Pagination";

import { useState, useEffect } from "react";
import Pagination from "shared/Pagination";

const SingleRequest = () => {
  const [searchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(10);
  const pageSize = 10;
  const dispatch = useDispatch();

  const formatAmount = (amount) => {
    if (typeof amount !== "number") return "0.00";
    return amount?.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const column = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className="pr-1 inline" src="/group-icon.svg" alt="" />
          Transaction Party
        </span>
      ),
      selector: (row) => row.transactionParty,
      sortable: true,
      cell: (row) => {
        const transactionPartyObj = row.transactionParty; // Extract the object
        const [partyName, role] = Object.entries(transactionPartyObj)[0]; // Get the first key-value pair

        return (
          <div>
            <p className="font-semibold">{partyName}</p> {/* Airtel Funding */}
            <span
              className={`${
                role === "SUPPLIER" ? "text-[#2EA923]" : "text-[#F08000]"
              }`}
            >
              {`(${role})`} {/* SUPPLIER */}
            </span>
          </div>
        );
      },
    },
    {
      name: "Payment Date",
      selector: (row) => row.paymentDate?.slice(0, 10),
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Invoice Number
        </span>
      ),
      selector: (row) => row.invoiceNo,
      sortable: true,
    },
    {
      name: "Funding Channels",
      selector: (row) => row.fundingChannel,
      sortable: true,
      width: "10.2rem",
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
      width: "6.2rem",
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      cell: (row) => (
        <p className="text-[#DE1515]">{formatAmount(row.amount)}</p>
      ),
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) =>
        row.status === "Successful" ? (
          <p className="text-primaryColor">{row.status}</p>
        ) : row.status === "REJECTED" ? (
          <p className="text-red">{row.status}</p>
        ) : row.status === "UPDATED" ? (
          <p className="text-blue-300">{row.status}</p>
        ) : row.status === "PARTIALLY Successful" ? (
          <p className="text-yellow-400">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        ),
    },
  ];

  useEffect(() => {
    dispatch(
      fetchTransactionHistory({
        page,
        size: pageSize,
        search: searchQuery,
        isBulk: false,
      })
    );
  }, [dispatch, page, searchQuery]);

  const {
    transHistory,
    // earlyPaymentOfferByVa,
    isLoading,
  } = useSelector((state) => state.buyer);

  const ReturnedComponent = () => (
    <div>
      {" "}
      {transHistory?.singleTransactionHistory?.data?.length > 0 ? (
        <>
          <Table
            columns={column}
            data={transHistory?.singleTransactionHistory?.data}
            onRowClicked={(row) => handleRowClick(row)}
            progressPending={isLoading}
            progressComponent={<Loading />}
            pointer
            tableHeader
            className="mt-5"
          />
          <div className="flex justify-center gap-x-[8px] mt-[20px]">
            {transHistory?.data?.meta?.totalElements > 10 && (
              <Pagination
                page={page}
                startPage={startPage}
                setEndPage={setEndPage}
                endPage={endPage}
                setStartPage={setStartPage}
                setPage={setPage}
                progressPending={isLoading}
                progressComponent={<Loading />}
                pointer
                tableHeader
                totalPages={transHistory?.data?.meta?.totalElements}
              />
            )}
          </div>
        </>
      ) : (
        <TableEmptyState />
      )}
    </div>
  );

  const navigate = useNavigate();
  const handleRowClick = (row) => {
    if (row.channel === "Loan Default") {
      navigate(
        `/disbursement/funder/disbursement/detailsforEarlyPaymentRequest/${row.idx}`
      );
    } else if (row.channel === "Early Payment Request") {
      navigate(
        `/disbursement/funder/disbursement/detailsforEarlyPaymentRequestMain/${row.idx}`
      );
    } else if (row.channel === "Early Payment Offers ") {
      navigate(
        `/disbursement/funder/disbursement/detailsforEarlyPaymentOffer/${row.idx}`
      );
    }
  };
  return <ReturnedComponent />;
};
export default SingleRequest;
