import React, { useEffect, useState } from "react";
import { PAIconArrowLeftGreen, PAIconOutlineSearch } from "assets/images/svgs";
import { Link, useNavigate } from "react-router-dom";
import Card from "shared/Card";
import Table from "layout/table/Table";
import { useDispatch } from "react-redux";
import Loading from "shared/Loading";
import DashboardLayout from "layout/dashboardlayout";
import RequestExtensionModal from "./requestExtension";
import { allInvoiceById } from "appstate/invoice/invoiceSlice";
import { useSelector } from "react-redux";

const BulkExtensionInvoiceList = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectAllText, setSelectAllText] = useState("Select All");
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const { invoiceIds } = useSelector((state) => state?.paymentExtension);
  const { disbursedInvoiceData, associatedFunderId, isLoading } = useSelector(
    (state) => state?.paymentExtension
  );

  const invoiceIdData = disbursedInvoiceData?.data?.find(
    (invoice) => invoice.funderId === associatedFunderId
  );
  // const { invoiceIdData, isLoading } = useSelector((state) => state?.invoice);

  // const id = invoiceIds;
  const id = selectedInvoice;

  useEffect(() => {
    if (!id) return;

    dispatch(allInvoiceById({ id }));
    // dispatch(allDisbursedInvoice());
  }, [dispatch, id]);

  // The error was fixed by me converting the single invoice object to an array
  const dummyData = invoiceIdData ? [invoiceIdData] : [];


  const formatAmount = (amount) => {
    // if (typeof amount !== "number") return "0.00";
    return amount?.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  // const handleConfirm = () => {
  //   setIsAcceptModalOpen(false);
  //   setSelectedInvoice(null);
  //   const selectedInvoiceIds = selectedRows.map((id) => id);
  //   dispatch(remainder({ type: "receivable", body: selectedInvoiceIds })).then(
  //     (response) => {
  //       if (response?.payload?.status === 200 && response?.payload?.success) {
  //         setIsSuccessModalOpen(true);
  //         setSuccessMessage(response?.payload?.message);
  //       }
  //     }
  //   );
  // };

  // const handleCloseSuccessModal = () => {
  //   setIsSuccessModalOpen(false);
  // };

  const bulkDetails = (id) => {
    let path = `/payment-extension/bulk/invoice/`;
    navigate(path);
    // dispatch(getInvoiceId(id));
  };

  const handleSendReminder = () => {
    setSelectedInvoice(null);
    setIsAcceptModalOpen(true);
  };

  // const handleCheckboxChange = (row) => {
  //   const isSelected = selectedRows.includes(row?.funderId);
  //   let newSelectedRows = [];

  //   if (isSelected) {
  //     newSelectedRows = selectedRows.filter((id) => id !== row?.funderId);
  //   } else {
  //     newSelectedRows = [...selectedRows, row?.funderId];
  //   }

  //   setSelectedRows(newSelectedRows);
  // };

  // const handleCheckboxChange = (row) => {
  //   const isSelected = selectedRows.includes(row?.funderId);
  //   let newSelectedRows = [];

  //   if (isSelected) {
  //     newSelectedRows = selectedRows.filter((id) => id !== row?.funderId);
  //   } else {
  //     newSelectedRows = [...selectedRows, row?.funderId];
  //   }

  //   setSelectedRows(newSelectedRows);

  //   // Update selectAll state based on the number of selected rows
  //   if (newSelectedRows?.length === dummyData?.length) {
  //     console.log(newSelectedRows)
  //     setSelectAll(true);
  //     setSelectAllText("Deselect All");
  //   } else {
  //     setSelectAll(false);
  //     setSelectAllText("Select All");
  //   }
  // };

  const handleCheckboxChange = (row) => {
    setSelectedRows((prevSelectedRows) => {
      const isSelected = prevSelectedRows?.includes(row?.funderId);
      let newSelectedRows = [];

      if (isSelected) {
        newSelectedRows = prevSelectedRows?.filter(
          (id) => id !== row?.funderId
        );
      } else {
        newSelectedRows = [...prevSelectedRows, row?.funderId];
      }

      // Update selectAll state based on the number of selected rows
      if (newSelectedRows?.length === dummyData?.length) {
        setSelectAll(true);
        setSelectAllText("Deselect All");
      } else {
        setSelectAll(false);
        setSelectAllText("Select All");
      }

      return newSelectedRows;
    });
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
      setSelectAllText("Select All");
    } else {
      const allIds = dummyData?.map((row) => row?.funderId);
      setSelectedRows(allIds);
      setSelectAllText("Deselect All");
    }
    setSelectAll(!selectAll);
  };

  // const handleSelectAll = () => {
  //   if (selectAll) {
  //     setSelectedRows([]);
  //     setSelectAllText("Select All");
  //   } else {
  //     const allIds = dummyData?.map((row) => row.invoiceId);
  //     setSelectedRows(allIds);
  //     setSelectAllText("Deselect All");
  //   }
  //   setSelectAll(!selectAll);
  // };

  const columns = [
    {
      name: (
        <input type="checkbox" onChange={handleSelectAll} checked={selectAll} />
      ),
      cell: (row) => (
        <input
          type="checkbox"
          onChange={() => handleCheckboxChange(row)}
          checked={selectedRows.includes(row?.funderId)}
        />
      ),
      width: "50px",
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Invoice Reference
        </span>
      ),
      selector: (row) => row?.invoice?.invoiceReference,
      sortable: true,
    },
    {
      name: "Buyer",
      selector: (row) => row?.invoice?.buyerCompanyName,
      sortable: true,
    },
    {
      name: "Invoice Date",
      selector: (row) => row?.invoice?.createdDate,
      sortable: true,
    },
    {
      name: "Due Date",
      selector: (row) => row?.invoice?.dueDate,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row?.invoice?.currency,
      sortable: true,
    },
    {
      name: "Total Amount",
      selector: (row) => row?.invoice?.total,
      sortable: true,
      cell: (row) => (
        <p className="text-success">{formatAmount(row?.invoice?.total)}</p>
      ),
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: () => <p className="text-yellow-400">PENDING PAYMENT</p>,
    },
  ];

  return (
    <DashboardLayout>
      <header className="rounded-tl-md rounded-tr-md w-full bg-alabasterHeader mb-[17px] cursor-pointer items-center">
        <Link
          to="/payment-extension"
          className="flex items-center justifycenter gap-x-[3px] mb-[20px] cursor-pointer py-3 ml-4 my-auto"
        >
          <PAIconArrowLeftGreen />
          <span className="flex items-center text-primaryColor">Go Back</span>
        </Link>
      </header>

      {/* <div> */}
      {isLoading ? (
        <div className="flex items-center justify-center h-full">
          <Loading />
        </div>
      ) : (
        <Card className="rounded-[10px] mt-[15px]">
          <div className="bg-alabasterHeader px-[24px]">
            <p className="text-sm font-semibold pt-10">
              {dummyData?.length} Invoices for Nestle Inc.
            </p>
          </div>
          <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
            <div className="flex md:col-span-1 justify-end">
              <div className="flex items-center justify-between gap-5">
                <div className="border border-transparent md:border-gray md:shadow-sm my-4 rounded-[5px] px-2 lg:w-[300px] bg-white">
                  <div className="items-center flex bg-white">
                    <PAIconOutlineSearch />
                    <input
                      className="bg-transparent p-2 w-full focus:outline-none text-sm bg-white"
                      type="text"
                      placeholder="Search for request"
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          // handleSearch();
                        }
                      }}
                    />
                  </div>
                </div>
                <button className="hidden lg:flex bg-transparent shadow rounded-md my-4 py-2 px-6 mx3 items-center gap-2 textwhite">
                  Search
                </button>
              </div>
            </div>
            <div className="flex items-center justify-center gap-3">
              <div
                className="border border-gray300 rounded-md p-2 text-white bg-[#2FA06A] cursor-pointer"
                onClick={handleSelectAll}
              >
                <p>{selectAllText}</p>
              </div>
              <button
                className={`border border-gray300 rounded-md p-2 text-white ${
                  selectedRows.length === 0
                    ? "cursor-not-allowed bg-gray300"
                    : "cursor-pointer bg-secondaryColor"
                }`}
                disabled={selectedRows.length === 0}
                onClick={handleSendReminder}
              >
                <p>Request Payment Extension</p>
              </button>
            </div>
          </div>
          <div className="pb-[24px]">
            <Table
              columns={columns}
              data={dummyData}
              onRowClicked={(row) => bulkDetails(row?.id)}
              pointer
              tableHeader
              className="mt-5"
            />
          </div>
        </Card>
      )}
      {/* </div> */}

      {isAcceptModalOpen && (
        <RequestExtensionModal
          open={() => setIsAcceptModalOpen(true)}
          onClose={() => setIsAcceptModalOpen(false)}
          data={invoiceIdData}
        />
      )}
    </DashboardLayout>
  );
};

export default BulkExtensionInvoiceList;
