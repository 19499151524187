import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  disbursementFunderOverviewApi,
  disbursementFunderPayNowApi,
  disbursementFunderTransactionDetailsByIdApi,
  disbursementFunderTransactionHistoryApi,
  disbursementFunderVaApi,
  disbursementProgramOverviewApi,
  openMarketInvoiceByIdApi,
} from "appstate/api/disbursement/disbursement";

const initialState = {
  isLoading: false,
  disbursementProgramOverviewData: [],
  disbursementFunderVaData: [],
  disbursementFunderOverviewData: [],
  disbursementFunderTransactionHistoryData: [],
  disbursementFunderTransactionDetailsByIdData: [],
  disbursementFunderReceiptData: [],
  openMarketInvoiceByIdData: [],
};

export const disbursementProgramOverview = createAsyncThunk(
  "disbursement/disbursementProgramOverview",
  disbursementProgramOverviewApi
);

export const disbursementFunderVa = createAsyncThunk(
  "disbursement/disbursementFunderVa",
  disbursementFunderVaApi
);

export const disbursementFunderPayNow = createAsyncThunk(
  "disbursement/disbursementFunderPayNow",
  disbursementFunderPayNowApi
);
export const disbursementFunderOverview = createAsyncThunk(
  "disbursement/disbursementFunderOverview",
  disbursementFunderOverviewApi
);

export const disbursementFunderTransactionHistory = createAsyncThunk(
  "disbursement/disbursementFunderTransactionHistory",
  disbursementFunderTransactionHistoryApi
);

export const openMarketInvoiceById = createAsyncThunk(
  "disbursement/openMarketInvoiceById",
  openMarketInvoiceByIdApi
);

export const disbursementFunderTransactionDetailsById = createAsyncThunk(
  "disbursement/disbursementFunderTransactionDetailsById",
  disbursementFunderTransactionDetailsByIdApi
);

export const disbursementSlice = createSlice({
  name: "disbursement",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(disbursementProgramOverview.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(disbursementProgramOverview.fulfilled, (state, action) => {
      state.isLoading = false;
      state.disbursementProgramOverviewData = action.payload;
    });
    builder.addCase(disbursementProgramOverview.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(openMarketInvoiceById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(openMarketInvoiceById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.openMarketInvoiceByIdData = action.payload;
    });
    builder.addCase(openMarketInvoiceById.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(disbursementFunderTransactionHistory.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      disbursementFunderTransactionHistory.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.disbursementFunderTransactionHistoryData = action.payload;
      }
    );
    builder.addCase(disbursementFunderTransactionHistory.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(
      disbursementFunderTransactionDetailsById.pending,
      (state) => {
        state.isLoading = true;
      }
    );
    builder.addCase(
      disbursementFunderTransactionDetailsById.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.disbursementFunderTransactionDetailsByIdData = action.payload;
      }
    );
    builder.addCase(
      disbursementFunderTransactionDetailsById.rejected,
      (state) => {
        state.isLoading = false;
      }
    );

    builder.addCase(disbursementFunderVa.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(disbursementFunderVa.fulfilled, (state, action) => {
      state.isLoading = false;
      state.disbursementFunderVaData = action.payload;
    });
    builder.addCase(disbursementFunderVa.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(disbursementFunderOverview.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(disbursementFunderOverview.fulfilled, (state, action) => {
      state.isLoading = false;
      state.disbursementFunderOverviewData = action.payload;
    });
    builder.addCase(disbursementFunderOverview.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(disbursementFunderPayNow.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(disbursementFunderPayNow.fulfilled, (state, action) => {
      state.isLoading = false;
      state.disbursementFunderReceiptData = action.payload;
    });
    builder.addCase(disbursementFunderPayNow.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export default disbursementSlice.reducer;
