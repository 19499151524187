import { useKeycloak } from "@react-keycloak/web";
import { staffProfileById } from "appstate/roleManager/roleManagerSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { getFirstTwoWords } from "utilities/helper/firstTwoSnakeCase";

const CheckLoginComplete = ({ children }) => {
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();
  const { userId, userDetails } = useSelector((state) => state.auth);
  const { staffProfileByIdData } = useSelector((state) => state.roleManager);

  const constants = [
    "default-roles-payassyst",
    "offline_access",
    "uma_authorization",
  ];
  const roleIndex = userId?.realm_access?.roles.findIndex(
    (role) => !constants.includes(role)
  );

  const userRole = userId?.realm_access?.roles[roleIndex]?.toLowerCase();
  const staffRole = getFirstTwoWords(userRole);
  const player = userRole?.includes("buyer")
    ? "buyer"
    : userRole?.includes("funder")
    ? "funder"
    : "supplier";

  useEffect(() => {
    if (userRole?.includes("staff")) {
      // alert(staffRole);
      dispatch(
        staffProfileById({
          player,
          staffId: keycloak?.tokenParsed?.sub,
        })
      );
    }
  }, [dispatch, keycloak?.tokenParsed?.sub, player, userRole]);

  if (userDetails?.data?.companyDetailsCompleted === false) {
    let path =
      userDetails?.data?.role === "SUPPLIER"
        ? "/supplier/select-buyer"
        : userDetails?.data?.role === "BUYER"
        ? "/buyer/company-details"
        : userDetails?.data?.role === "FUNDER"
        ? "/funder/funder-company-details"
        : null;
    return <Navigate to={path} replace />;
  } else if (
    ["staff_buyer", "staff_supplier", "staff_funder"]?.includes(staffRole) &&
    staffProfileByIdData?.data?.profilingCompleted === false
  ) {
    let path = staffRole.includes("staff_buyer")
      ? "/onboarding/buyer/staff/personal-details"
      : staffRole.includes("staff_supplier")
      ? "/onboarding/supplier/staff/personal-details"
      : "/onboarding/funder/staff/personal-details";

    return <Navigate to={path} replace />;
  } else {
    return <>{children}</>;
  }
};
export default CheckLoginComplete;
