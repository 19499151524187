import React, { useEffect } from "react";
import Table from "layout/table/Table";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { allTransactions } from "appstate/collections/collectionSlice";
import Loading from "shared/Loading";
import { setActiveTab } from "appstate/collections/collectionSlice";

const RecentPaymentTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state?.collection);

  // Dummy data
  const dummyTransactionData = {
    data: [
      {
        sender: "Company A",
        paymentDate: "2023-10-01",
        invoiceReference: "INV-001",
        currency: "USD",
        amount: 1500.0,
        status: "SUCCESSFUL",
        collectionType: "Type A",
        fundingChannel: "Bank Transfer",
        invoiceId: "1",
      },
      {
        sender: "Company B",
        paymentDate: "2023-10-02",
        invoiceReference: "INV-002",
        currency: "USD",
        amount: 2000.0,
        status: "REJECTED",
        collectionType: "Type B",
        fundingChannel: "Credit Card",
        invoiceId: "2",
      },
      {
        sender: "Company C",
        paymentDate: "2023-10-03",
        invoiceReference: "INV-003",
        currency: "USD",
        amount: 500.0,
        status: "UPDATED",
        collectionType: "Type C",
        fundingChannel: "PayPal",
        invoiceId: "3",
      },
      {
        sender: "Company D",
        paymentDate: "2023-10-04",
        invoiceReference: "INV-004",
        currency: "USD",
        amount: 750.0,
        status: "PARTIALLY PAID",
        collectionType: "Type D",
        fundingChannel: "Wire Transfer",
        invoiceId: "4",
      },
    ],
  };

  useEffect(() => {
    dispatch(allTransactions());
  }, [dispatch]);

  const transactionDetails = (id) => {
    let path = `/collections/transactionhistory/${id}`;
    navigate(path);
  };

  const allTransaction = (activeTab) => {
    dispatch(setActiveTab(activeTab));
  };

  const formatAmount = (amount) => {
    if (typeof amount !== "number") return "0.00";
    return amount?.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const columns = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Sender
        </span>
      ),
      selector: (row) => row.sender,
      sortable: true,
    },
    {
      name: "Payment Date",
      selector: (row) => row.paymentDate?.slice(0, 10),
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Reference Number
        </span>
      ),
      selector: (row) => row.invoiceReference,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
      // width: "6.2rem",
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      cell: (row) => <p className="text-success">{formatAmount(row.amount)}</p>,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) =>
        row.status === "SUCCESSFUL" ? (
          <p className="text-[#07593D]">{row.status}</p>
        ) : row.status === "REJECTED" ? (
          <p className="text-red">{row.status}</p>
        ) : row.status === "UPDATED" ? (
          <p className="text-blue-300 ">{row.status}</p>
        ) : row.status === "PARTIALLY PAID" ? (
          <p className="text-yellow-400">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        ),
    },
    {
      name: "Collection Type",
      selector: (row) => row.collectionType,
      sortable: true,
      // width: "6.2rem",
    },
    {
      name: "Funding Channel",
      selector: (row) => row.fundingChannel,
      sortable: true,
      // width: "6.2rem",
    },
  ];

  return (
    <>
      {!dummyTransactionData?.data?.length ? (
        <div className="ml3">
          <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
            <p className="text-black text-sm font-medium">
              Recent Payment Collection Transaction history
            </p>
          </div>
          <TableEmptyState dashboard />
        </div>
      ) : (
        <Card className="rounded-[10px] mt[24px]">
          <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
            <div>
              <p className="text-black text-lg font-bold mt-2">
                Recent Payment Collection Transaction history
              </p>
              <span className="text-xs font-thin">
                Here is a recent list of your receivables from Payment
                Collections
              </span>
            </div>
            <div
              className="cursor-pointer"
              onClick={() => allTransaction("TransactionHistory")}
            >
              <p className="text-[#959595] underline text-xs">
                View All
              </p>
            </div>
          </div>
          <div className="px-[24px] pb[24px]">
            <Table
              columns={columns}
              data={dummyTransactionData.data.slice(-3)}
              onRowClicked={(row) => transactionDetails(row.invoiceId)}
              progressPending={isLoading}
              progressComponent={<Loading />}
              pointer
              tableHeader
              className="mt-5"
            />
          </div>
        </Card>
      )}
    </>
  );
};

export default RecentPaymentTable;
