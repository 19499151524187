import { PAIconInventoryArrowLeft } from "assets/images/svgs";
import React from "react";
import Button from "shared/Button";
import TableIcon from "../../../../../../assets/images/svgs/TableIcons.svg";
import { formatCurrency } from "utilities/helper/formatters";

const BulkInvoiceModalContent = ({
  onClose,
  data,
  handleProceed,
  handleGoBack,
  handleSelectAccount,
  lengthOfInvoice,
  buyer,
  date,
  totalRequestAmount,
  calculateTotalInvoiceAmount,
  totalReceivableAmount,
  description,
  setDescription,
  isDescriptionFilled,
  setIsDescriptionFilled,
  applied,
  setApplied,
  percentage,
  setIsActiveRecourse,
  isActiveRecourse,
  selectedInvoiceData,
  earlyPaymentDiscountedResponseList,
}) => {
  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    setDescription(value);
    setIsDescriptionFilled(value.trim().length > 0);
  };

  const handleApplied = () => {
    setApplied(true);
  };

  const handleCheckboxChange = () => {
    setIsActiveRecourse((prevState) => !prevState);
  };

  return (
    <div className="">
      <div className="w-[95%] mx-auto">
        <div
          onClick={handleGoBack}
          className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
        >
          <PAIconInventoryArrowLeft />
          <p className="mt-[2px] text-sm font-normal text-lightGray">Go Back</p>
        </div>
        <div>
          <div className="flex justify-between">
            <span className="text-[20px] font-light leading-[23.87px] font-sfpro">
              Bulk Direct Funding Request
            </span>
            {applied ? (
              <Button
                neutral
                onClick={handleSelectAccount}
                // disabled={!isDescriptionFilled}
                type="Button"
                className={`w-[97px] h-[24px] text-xs rounded-[5px] bg-primaryColor text-white`}
              >
                Select Account
              </Button>
            ) : null}
          </div>
          <div>
            <span className="font-sfpro">Total invoice no: </span>
            <span className="text-[#959595]">
              {selectedInvoiceData?.length}
            </span>{" "}
            | Buyer Info: <span className="text-[#959595]">{buyer}</span>
          </div>
        </div>
        <div className="flex justify-between">
          <div className="flex flex-col pt-8">
            {applied ? (
              <span>Receivable Amount</span>
            ) : (
              <span>Total Request Amount</span>
            )}
            <span className="text-primaryColor text-2xl font-extrabold">
              NGN {formatCurrency(totalRequestAmount)}
            </span>
            {applied ? null : (
              <span className="text-primaryColor">
                Applied Percentage: {percentage}%
              </span>
            )}
          </div>
          <div className="flex flex-col">
            {applied ? (
              <span>Total Request Amount</span>
            ) : (
              <span>Total Invoice Amount</span>
            )}
            <span className="text-[#959595] line-through">
              NGN {formatCurrency(calculateTotalInvoiceAmount())}
            </span>
            <hr />
            {applied ? (
              <span className="pt-2 text-[#959595]">
                Applied Percentage: {percentage}%
              </span>
            ) : (
              <span className="pt-2">Receivable Amount</span>
            )}
            {applied ? null : (
              <span className="flex justify-end text-[12px] text-primaryColor underline">
                NGN {formatCurrency(totalReceivableAmount)}
              </span>
            )}
            {applied ? null : (
              <div className="mt-[5px] flex justify-end">
                <Button
                  neutral
                  disabled={!isDescriptionFilled}
                  onClick={handleApplied}
                  type="Button"
                  className={`w-[87px] h-[24px] text-xs rounded-[5px] ${
                    isDescriptionFilled
                      ? "bg-secondaryColor text-white"
                      : "bg-[#C2C0C0] text-white"
                  }`}
                >
                  Apply
                </Button>
              </div>
            )}
          </div>
        </div>
        <div className="flex justify-between">
          <div className="flex flex-col">
            <span className="text-[12px]">New Payment Date</span>
            <span className="text-[12px]">{date}</span>
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              id="activeRecourse"
              className="mr-2"
              onChange={handleCheckboxChange}
            />
            <label htmlFor="activeRecourse" className="text-xs text-gray-700">
              Active Recourse
            </label>
          </div>
        </div>
        <div className="flex flex-col">
          <label
            className="text-textColor mb-[10px] text-xs"
            htmlFor="description"
          >
            Description
          </label>
          <textarea
            id="description"
            value={description}
            onChange={handleDescriptionChange}
            className="border outline-none border-[#AFAFAF] w-full rounded-[5px] h-[67px] p-[10px]"
          ></textarea>
        </div>
        <br />
        <div className="mt-[28px] border-b-4 border-[#F6F5FA] pb-[12px]">
          <span>Buyer Bulk Invoice Details</span>
        </div>
        {isDescriptionFilled && (
          <div className="flex justify-between pt-2">
            <div className="flex flex-col">
              <span>Supplier</span>
              <span>Abubakar Dev</span>
            </div>
            <div className="flex flex-col">
              <span>Buyer</span>
              <span>Nestle</span>
            </div>
          </div>
        )}
        <div className="w-full overflow-x-auto pt-2">
          <table className="w-full overflow-x-auto">
            <thead className="bg-subtleGray">
              <tr className="bg-unusualGray p-2.5">
                <td className="text-[#11192A] p-2.5 text-xs font-bold flex items-center whitespace-nowrap">
                  <img src={TableIcon} alt="" className="mr-1" />
                  <span>Invoice Reference</span>
                </td>
                <td className="p-2.5 text-xs font-bold whitespace-nowrap">
                  Request Amount
                </td>
                <td className="p-2.5 text-xs font-bold whitespace-nowrap">
                  Discount Rate
                </td>
                <td className="p-2.5 text-xs font-bold whitespace-nowrap">
                  Discount Amount
                </td>
                <td className="p-2.5 text-xs font-bold whitespace-nowrap">
                  Discount Invoice Amount
                </td>
              </tr>
            </thead>
            {earlyPaymentDiscountedResponseList?.map((item, index) => (
              <tbody key={index}>
                <tr className="py-[3px] px-1.5">
                  <td className="text-xs text-[#11192A] p-2.5"></td>
                  <td className="text-xs text-[#11192A] p-2.5">
                    {/* {item?.requestAmount} */}
                    {formatCurrency(item?.requestAmount)}
                  </td>
                  <td className="text-xs text-[#11192A] p-2.5">
                    {item?.discount}%
                  </td>
                  <td className="text-xs text-[#11192A] p-2.5">
                    {formatCurrency(item?.discountedAmount)}
                  </td>
                  <td className="text-xs text-[#11192A] p-2.5">
                    {formatCurrency(item?.invoiceAmount)}
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
        </div>
      </div>
    </div>
  );
};

export default BulkInvoiceModalContent;
