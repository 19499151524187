import DashboardLayout from "layout/dashboardlayout";
import Table from "layout/table/Table";
import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import Card from "shared/Card";
import TableHeader from "shared/tableHeader/TableHeader";
import PayableFinanceModal from "./components/Modal";
import { useSelector, useDispatch } from "react-redux";
import { viewMembersPfp } from "appstate/buyer/buyerSlice";
import Pagination from "shared/Pagination";
import { useLocation, useNavigate } from "react-router-dom";
import { PAIconEyePfp, PAIconInventoryArrowLeft, PAIconTableEmptyState } from "assets/images/svgs";

const ViewMembers = () => {
  const [open, setOpen] = useState(false);
  const [pfpFilter, setPfpFilter] = useState(false);
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const pageSize = 10;
  const { viewMembersData } = useSelector((state) => state.buyer);
  const [data, setData] = useState([]);
    const navigate = useNavigate();
  const location = useLocation();
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [initialData, setInitialData] = useState();
  const { state } = location;
  const formData = state ? state.formData : null;
  const reverse = state ? state.reverse : null;

  //   const onRowClicked = (row) => {
  //     let path = "payablefinancedetails";
  //     dispatch(getPfpId(row?.pfpId));
  //     navigate(path, {
  //       state: { financingOption: row.financingOption, status: row.status },
  //     });
  //   };

  const dispatch = useDispatch();

  useEffect(() => {
    const body = {
      programName: formData.programName || "",
      financingOption: formData.financingOptions || "",
      startDate: formData.startDate || "",
      endDate: formData.endDate || "",
      tierCategory: formData.tiers || [],
      discountRate: parseFloat(formData.discount) || 0,
      minimumAmount: parseFloat(formData.minAmount) || 0,
      maximumAmount: parseFloat(formData.maxAmount) || 0,
      funderPercentage:
        reverse === true ? parseFloat(100 - formData.buyerShare) || 0 : 0,
      buyerPercentage: parseFloat(formData.buyerShare) || 0,
      industryTypes: formData.industries || [],
      paymentDuration: parseInt(formData.paymentDuration) || 0,
      invoiceIds: formData.invoiceIds || [],
      documentId: formData.agreemntDocument || "",
      funderIds: formData.funderIds || [],
      fundingAccountId: formData.accounts || "",
      offerPercentage: parseFloat(formData.percentage) || 0,
    };
    dispatch(viewMembersPfp({ page, pageSize, body }));
  }, [dispatch, page, pageSize, formData, reverse]);

  useEffect(() => {
    setData(viewMembersData?.data);
  }, [viewMembersData]);

  const handleChange = (event) => {
    setSelectedFilter(event.target.value);
  };

  const columns = [
    {
      name: "Campaign Name",
      selector: (row) => row.campaignName,
      sortable: true,
      width: "10rem",
    },
    {
      name: "Financing Type",
      selector: (row) => row.financingOption,
      sortable: true,
      width: "10rem",
    },
    {
      name: "Start Date",
      selector: (row) => row.startDate,
      sortable: true,
      width: "10rem",
    },
    {
      name: "End Date",
      selector: (row) => row.endDate,
      sortable: true,
      width: "10rem",
    },
    {
      name: "Payment Period",
      selector: (row) => row.paymentPeriod + " days",
      sortable: true,
      width: "10rem",
    },
    {
      name: "Status",
      selector: (row) => (
        <p
          className={
            row.status === "IN_PROGRESS"
              ? "text-primaryColor"
              : row.status === "PENDING"
              ? "text-secondaryColor"
              : row.status === "STOPPED"
              ? "text-[#BB0C0C]"
              : "text-black"
          }
        >
          {row.status === "IN_PROGRESS"
            ? "In Progress"
            : row.status === "PENDING"
            ? "Pending"
            : row.status === "STOPPED"
            ? "Stopped"
            : row.status === "ENDED"
            ? "Ended"
            : "Cancelled"}
        </p>
      ),
      sortable: true,
      width: "10rem",
    },
    {
      name: "",
      selector: (row) => (
        <>
          <PAIconEyePfp />
        </>
      ),
      sortable: true,
      width: "10rem",
    },
  ];

  useEffect(() => {
    // Set the initial data when allBuyerPfpData changes
    setInitialData(viewMembersData?.data);
    setData(viewMembersData?.data); // Set the data to the initial data
  }, [viewMembersData]);

  useEffect(() => {
    let filteredData = initialData; // Start with the initial data

    if (selectedFilter === "REVERSE_FACTORING") {
      filteredData = initialData.filter(
        (item) => item.financingOption === "Reverse Factoring"
      );
    } else if (selectedFilter === "DYNAMIC_DISCOUNTING") {
      filteredData = initialData.filter(
        (item) => item.financingOption === "Dynamic Discounting"
      );
    }

    setData(filteredData); // Update the data with the filtered data
    setPfpFilter(true);
  }, [selectedFilter, initialData]);

  const totalPages = viewMembersData?.meta?.size;
  let path = "/payablefinancing/payablefinancedetail/createpayablefinance";

  function handleGoback() {
    let path = -1;
    navigate(path);
  }
  return (
    <DashboardLayout
      section="PFP Campaign"
      subHeader="Manage all your payable finance campaign"
    >
      <div
        onClick={handleGoback}
        className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
      >
        <PAIconInventoryArrowLeft />
        <p className="mt-[2px] text-sm font-normal text-lightGray">Go Back</p>
      </div>
      <PayableFinanceModal isShown={open} hide={() => setOpen(false)} />
      <Card className="p-[24px] rounded-[10px]">
        <div>
          <TableHeader
            details="List of suppliers"
            total="All suppliers in the payable finance campaign"
            path={path}
            payableFinance
            pfpFilter={pfpFilter}
            handleChange={handleChange}
          />
        </div>
        {viewMembersData?.noOfSuppliers === 0 ? (
          <div className="flex flex-col items-center justify-center w-full h-[65vh]">
            <div className="flex flex-col gap-4 items-center justify-center">
              <PAIconTableEmptyState />
              <p>No Supplier Data Available</p>
            </div>
          </div>
        ) : (
          <Table
            columns={columns}
            data={data}
            //   onRowClicked={onRowClicked}
            //   progressPending={isLoading}
            //   progressComponent={<Loading />}
            pointer
            tableHeader
            className="mt-5"
          />
        )}

        <div className="flex justify-center gap-x-[8px] mt-[20px]">
          {totalPages > 9 && (
            <Pagination
              page={page}
              startPage={startPage}
              setEndPage={setEndPage}
              endPage={endPage}
              setStartPage={setStartPage}
              setPage={setPage}
              totalPages={totalPages}
            />
          )}
        </div>
      </Card>
    </DashboardLayout>
  );
};

export default ViewMembers;
