// import { setActiveTab } from "appstate/collections/collectionSlice";
import RecentPaymentTableReinbursement from "../RecentPaymentReinbursement";

const OverviewReinbursement = ({ selectedStatus }) => {
  return (
    <div className="w-full">
      <div className="w-full rounded-b-lg  shadow-lg">
        <header className="flex items-center justify-between mt-3 border-b-2 border-[#AEAEAE] flex-shrink-0 bg-white px-7 py-4 rounded-t-md">
          <h2 className="text-[#3E3E3E] font-bold text-[16px]">
            Summary of pending invoices for reimbursement from diverse channels.
          </h2>
          <div className="relative flex flex-col items-end">
            <select className="border border-[#AEAEAE] bg-white w-[45%] text-[#3E3E3E] text-sm rounded px-2 py-1 cursor-pointer shadow-sm focus:outline-none">
              <option value="all">All</option>
              <option value="bank1">Next 24 hours</option>
              <option value="bank2">Next 7 Days</option>
              <option value="bank3">Next 14 Days</option>
            </select>
            <span>Due Payment</span>
          </div>
        </header>
        <div className="bg-[#EBEBEB] pb-5 rounded-b-lg mb-4">
          <div className="px-7 py-4 grid xl:grid-cols-[35%,64%] gap-5">
            <section className="flex flex-col justify-between">
              <div className="flex justify-between">
                <div className="p-5 bg-primaryColor flex flex-col items-center justify-center rounded-bl-[10px] rounded-tl-[10px] text-white shadow-sm gap-3 w-[49%] ">
                  <span className="font-bold md:text-base xl:text-[20px]">
                    <span>N </span> 70
                  </span>
                  <span className=" text-[13px] font-normal">
                    Total Invoices
                  </span>
                </div>

                {selectedStatus === "Liened" && (
                  <div className="py-[42px] px-5 bg-[#EDFFF9] border-l-[2px] border-primaryColor flex flex-col items-center justify-center  rounded-br-[10px] rounded-tr-[10px] relative text-white shadow-sm gap-3 w-[49%] ">
                    <span
                      style={{ color: "#06593D" }}
                      className="font-bold  md:text-base xl:text-[20px]"
                    >
                      <span>N </span> 1,677,360
                    </span>
                    <span
                      style={{ color: "#06593D" }}
                      className=" text-[13px] whitespace-nowrap font-normal"
                    >
                      Total Amount
                    </span>
                  </div>
                )}

                {selectedStatus === "Upcoming" && (
                  <div className="py-[42px] px-5 bg-[#FFFBF6] border-secondaryColor border-l-[2px] flex flex-col items-center justify-center  rounded-br-[10px] rounded-tr-[10px] relative text-white shadow-sm gap-3 w-[49%] ">
                    <span
                      style={{ color: "#06593D" }}
                      className="font-bold  md:text-base xl:text-[20px]"
                    >
                      <span>N </span> 1,677,360
                    </span>
                    <span
                      style={{ color: "#06593D" }}
                      className=" text-[13px] whitespace-nowrap font-normal"
                    >
                      Total Due Amount (outstanding)
                    </span>
                  </div>
                )}

                {selectedStatus === "Overdue" && (
                  <div className="py-[42px] px-5 bg-[#FFFBF6] flex flex-col items-center justify-center  rounded-br-[10px] rounded-tr-[10px] border-l-[2px] border-[#DE1515] relative text-white shadow-sm gap-3 w-[49%] ">
                    <span
                      style={{ color: "#06593D" }}
                      className="font-bold  md:text-base xl:text-[20px]"
                    >
                      <span>N </span> 1,677,360
                    </span>
                    <span
                      style={{ color: "#06593D" }}
                      className=" text-[13px] whitespace-nowrap font-normal"
                    >
                      Total Amount
                    </span>
                  </div>
                )}
              </div>

              <div className="bg-primaryColor p-10 flex-row items-center justify-center mt-5 text-white rounded-[10px] min-h-[50%]">
                <p className="flex items-center justify-center font-bold text-4xl">
                  12
                </p>
                <p className="flex items-center justify-center font-normal text-[16px]">
                  Total Funders
                </p>
              </div>
            </section>

            <section className="grid lg:grid-cols-2 gap-5">
              <div className="flex flex-col justify-between  gap-3">
                <div
                  className={`px-6 py-4 items-center shadow-lg bg-white rounded-[10px] h-[48%]`}
                >
                  <div className="flex items-center justify-between">
                    <p className={`text-black font-bold text-base`}>
                      Close Market
                    </p>
                    <select
                      className={`border border-[${"#06593D"}] bg-[#F3FEFA] text-xs outline-none text-[#06593D] rounded px-2 py-2 shadow-lg cursor-pointer`}
                    >
                      <option value="all">Without Recourse</option>
                    </select>
                  </div>
                  <div className="flex justify-between mt-4">
                    {selectedStatus === "Liened" && (
                      <div className=" justify-end w-1/2  items-end  flex-row">
                        <h2
                          className={`text-[${"#07593D"}] flex items-center justify-center font-bold text-2xl`}
                        >
                          {"54"}
                        </h2>
                        <h2 className="flex items-center justify-center font-normal text-[#565656] text-xs mt-3">
                          {"Total Request"}
                        </h2>
                      </div>
                    )}

                    {selectedStatus === "Upcoming" && (
                      <div className=" justify-end w-1/2  items-end  flex-row">
                        <h2
                          style={{ color: "#F18811" }}
                          className={`text-["#F18811"] flex items-center justify-center font-bold text-2xl`}
                        >
                          {"54"}
                        </h2>
                        <h2 className="flex items-center justify-center font-normal text-[#565656] text-xs mt-3">
                          {"Total Request"}
                        </h2>
                      </div>
                    )}

                    {selectedStatus === "Overdue" && (
                      <div className=" justify-end w-1/2  items-end  flex-row">
                        <h2
                          style={{ color: "red" }}
                          className={`flex items-center justify-center font-bold text-2xl`}
                        >
                          {"54"}
                        </h2>
                        <h2 className="flex items-center justify-center font-normal text-[#565656] text-xs mt-3">
                          {"Total Request"}
                        </h2>
                      </div>
                    )}

                    {selectedStatus === "Liened" && (
                      <div className="flex-row  border-l-2 border-[#2FA069] w-1/2">
                        <h2
                          className={`text-[${"#07593D"}] flex items-center justify-center font-bold text-2xl`}
                        >
                          {"₦237,730"}
                        </h2>
                        <h2 className="flex items-center justify-center font-normal text-[#565656] text-xs mt-3">
                          {"Total Liened"}
                        </h2>
                      </div>
                    )}

                    {selectedStatus === "Upcoming" && (
                      <div className="flex-row  border-l-2 border-[#2FA069] w-1/2">
                        <h2
                          style={{ color: "#F08000" }}
                          className={` flex items-center justify-center font-bold text-2xl`}
                        >
                          {"₦237,730"}
                        </h2>
                        <h2 className="flex items-center justify-center font-normal text-[#565656] text-xs mt-3">
                          {"Total Liened"}
                        </h2>
                      </div>
                    )}

                    {selectedStatus === "Overdue" && (
                      <div className="flex-row  border-l-2 border-[#2FA069] w-1/2">
                        <h2
                          style={{ color: "red" }}
                          className={`flex items-center justify-center font-bold text-2xl`}
                        >
                          {"₦237,730"}
                        </h2>
                        <h2 className="flex items-center justify-center font-normal text-[#565656] text-xs mt-3">
                          {"Total Liened"}
                        </h2>
                      </div>
                    )}
                  </div>
                </div>

                <div
               className={`px-6 py-4 items-center shadow-lg bg-white rounded-[10px] h-[48%]`}
                >
                  <div className="flex items-center justify-between ">
                    <p className={`text-black font-bold text-base`}>
                      Open Market
                    </p>
                    <select
                      className={`border border-[${"#06593D"}] bg-[${"#06593D"}]  text-xs outline-none text-white rounded px-2 py-2 cursor-pointer`}
                    >
                      <option value="all">With Recourse</option>
                      <option value="1week">Without Recourse</option>
                    </select>
                  </div>
                  <div className="flex justify-between mt-4">
                    {selectedStatus === "Liened" && (
                      <div className=" justify-end w-1/2  items-end  flex-row">
                        <h2
                          className={`text-[${"#07593D"}] flex items-center justify-center font-bold text-2xl`}
                        >
                          {"54"}
                        </h2>
                        <h2 className="flex items-center justify-center font-normal text-[#565656] text-xs mt-3">
                          {"Total Request"}
                        </h2>
                      </div>
                    )}

                    {selectedStatus === "Upcoming" && (
                      <div className=" justify-end w-1/2  items-end  flex-row">
                        <h2
                          style={{ color: "#F08000" }}
                          className={` flex items-center justify-center font-bold text-2xl`}
                        >
                          {"54"}
                        </h2>
                        <h2 className="flex items-center justify-center font-normal text-[#565656] text-xs mt-3">
                          {"Total Request"}
                        </h2>
                      </div>
                    )}

                    {selectedStatus === "Overdue" && (
                      <div className=" justify-end w-1/2  items-end  flex-row">
                        <h2
                          style={{ color: "red" }}
                          className={`text-[${"#07593D"}] flex items-center justify-center font-bold text-2xl`}
                        >
                          {"54"}
                        </h2>
                        <h2 className="flex items-center justify-center font-normal text-[#565656] text-xs mt-3">
                          {"Total Request"}
                        </h2>
                      </div>
                    )}

                    {selectedStatus === "Liened" && (
                      <div className="flex-row  border-l-2 border-[#2FA069] w-1/2">
                        <h2
                          className={`text-[${"#06593D"}] flex items-center justify-center font-bold text-2xl`}
                        >
                          {"₦237,730"}
                        </h2>
                        <h2 className="flex items-center justify-center font-normal text-[#565656] text-xs mt-3">
                          {"Total Liened"}
                        </h2>
                      </div>
                    )}

                    {selectedStatus === "Upcoming" && (
                      <div className="flex-row  border-l-2 border-[#2FA069] w-1/2">
                        <h2
                          style={{ color: "#F08000" }}
                          className={`flex items-center justify-center font-bold text-2xl`}
                        >
                          {"₦237,730"}
                        </h2>
                        <h2 className="flex items-center justify-center font-normal text-[#565656] text-xs mt-3">
                          {"Total Liened"}
                        </h2>
                      </div>
                    )}

                    {selectedStatus === "Overdue" && (
                      <div className="flex-row  border-l-2 border-[#2FA069] w-1/2">
                        <h2
                          style={{ color: "red" }}
                          className={`flex items-center justify-center font-bold text-2xl`}
                        >
                          {"₦237,730"}
                        </h2>
                        <h2 className="flex items-center justify-center font-normal text-[#565656] text-xs mt-3">
                          {"Total Liened"}
                        </h2>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="flex flex-col justify-between gap-3">
                <div
               className={`px-6 py-4 items-center shadow-lg bg-white rounded-[10px] h-[48%]`}
                >
                  <div className="flex item-center justify-between">
                    <p className={`text-black font-bold text-base`}>
                      Reverse Factoring(PFP)
                    </p>
                    <select
                      className={`border border-[${"#06593D"}] bg-[#F3FEFA] text-xs outline-none  text-[#06593D] rounded px-2 py-2 cursor-pointer`}
                    >
                      <option value="all">Without Recourse</option>
                    </select>
                  </div>

                  <div className="flex justify-between mt-4">
                    {selectedStatus === "Liened" && (
                      <div className=" justify-end w-1/2  items-end  flex-row">
                        <h2
                          className={`text-[${"#07593D"}] flex items-center justify-center font-bold text-2xl`}
                        >
                          {"54"}
                        </h2>
                        <h2 className="flex items-center justify-center font-normal text-[#565656] text-xs mt-3">
                          {"Total Request"}
                        </h2>
                      </div>
                    )}

                    {selectedStatus === "Upcoming" && (
                      <div className=" justify-end w-1/2  items-end  flex-row">
                        <h2
                          style={{ color: "#F08000" }}
                          className={`text-[${"#07593D"}] flex items-center justify-center font-bold text-2xl`}
                        >
                          {"54"}
                        </h2>
                        <h2 className="flex items-center justify-center font-normal text-[#565656] text-xs mt-3">
                          {"Total Request"}
                        </h2>
                      </div>
                    )}

                    {selectedStatus === "Overdue" && (
                      <div className=" justify-end w-1/2  items-end  flex-row">
                        <h2
                          style={{ color: "red" }}
                          className={` flex items-center justify-center font-bold text-2xl`}
                        >
                          {"54"}
                        </h2>
                        <h2 className="flex items-center justify-center font-normal text-[#565656] text-xs mt-3">
                          {"Total Request"}
                        </h2>
                      </div>
                    )}

                    {selectedStatus === "Liened" && (
                      <div className="flex-row  border-l-2 border-[#2FA069] w-1/2">
                        <h2
                          className={`text-[${"#06593D"}] flex items-center justify-center font-bold text-2xl`}
                        >
                          {"₦2,237,730"}
                        </h2>
                        <h2 className="flex items-center justify-center font-normal text-[#565656] text-xs mt-3">
                          {"Total Liened"}
                        </h2>
                      </div>
                    )}

                    {selectedStatus === "Upcoming" && (
                      <div className="flex-row  border-l-2 border-[#2FA069] w-1/2">
                        <h2
                          style={{ color: "#F08000" }}
                          className={`text-[${"#06593D"}] flex items-center justify-center font-bold text-2xl`}
                        >
                          {"₦2,237,730"}
                        </h2>
                        <h2 className="flex items-center justify-center font-normal text-[#565656] text-xs mt-3">
                          {"Total Liened"}
                        </h2>
                      </div>
                    )}

                    {selectedStatus === "Overdue" && (
                      <div className="flex-row  border-l-2 border-[#2FA069] w-1/2">
                        <h2
                          style={{ color: "red" }}
                          className={`text-[${"#06593D"}] flex items-center justify-center font-bold text-2xl`}
                        >
                          {"₦2,237,730"}
                        </h2>
                        <h2 className="flex items-center justify-center font-normal text-[#565656] text-xs mt-3">
                          {"Total Liened"}
                        </h2>
                      </div>
                    )}
                  </div>
                </div>

                <div
                 className={`px-6 py-4 items-center shadow-lg bg-white rounded-[10px] h-[48%]`}
                >
                  <div className="flex item-center justify-between ">
                    <p className={`text-black font-bold text-base`}>
                      {"Direct Funding Request"}
                    </p>
                    <select
                      className={`border border-[${"#06593D"}] bg-[#06593D] text-xs outline-none text-white rounded px-2 py-2 cursor-pointer`}
                    >
                      <option value="all">With Recourse</option>
                    </select>
                  </div>

                  <div className="flex justify-between mt-4">
                    {selectedStatus === "Liened" && (
                      <div className=" justify-end w-1/2  items-end  flex-row">
                        <h2
                          className={`text-[${"#07593D"}] flex items-center justify-center font-bold text-2xl`}
                        >
                          {"54"}
                        </h2>
                        <h2 className="flex items-center justify-center font-normal text-[#565656] text-xs mt-3">
                          {"Total Request"}
                        </h2>
                      </div>
                    )}

                    {selectedStatus === "Upcoming" && (
                      <div className=" justify-end w-1/2  items-end  flex-row">
                        <h2
                          style={{ color: "#F08000" }}
                          className={`text-[${"#07593D"}] flex items-center justify-center font-bold text-2xl`}
                        >
                          {"54"}
                        </h2>
                        <h2 className="flex items-center justify-center font-normal text-[#565656] text-xs mt-3">
                          {"Total Request"}
                        </h2>
                      </div>
                    )}

                    {selectedStatus === "Overdue" && (
                      <div className=" justify-end w-1/2  items-end  flex-row">
                        <h2
                          style={{ color: "red" }}
                          className={`text-[${"#07593D"}] flex items-center justify-center font-bold text-2xl`}
                        >
                          {"54"}
                        </h2>
                        <h2 className="flex items-center justify-center font-normal text-[#565656] text-xs mt-3">
                          {"Total Request"}
                        </h2>
                      </div>
                    )}

                    {selectedStatus === "Liened" && (
                      <div className="flex-row  border-l-2 border-[#2FA069] w-1/2">
                        <h2
                          className={`text-[${"#06593D"}] flex items-center justify-center font-bold text-2xl`}
                        >
                          {"₦523,910"}
                        </h2>
                        <h2 className="flex items-center justify-center font-normal text-[#565656] text-xs mt-3">
                          {"Total Liened"}
                        </h2>
                      </div>
                    )}

                    {selectedStatus === "Upcoming" && (
                      <div className="flex-row  border-l-2 border-[#2FA069] w-1/2">
                        <h2
                          style={{ color: "#F08000" }}
                          className={` flex items-center justify-center font-bold text-2xl`}
                        >
                          {"₦523,910"}
                        </h2>
                        <h2 className="flex items-center justify-center font-normal text-[#565656] text-xs mt-3">
                          {"Total Liened"}
                        </h2>
                      </div>
                    )}

                    {selectedStatus === "Overdue" && (
                      <div className="flex-row  border-l-2 border-[#2FA069] w-1/2">
                        <h2
                          style={{ color: "red" }}
                          className={`text-[${"#06593D"}] flex items-center justify-center font-bold text-2xl`}
                        >
                          {"₦523,910"}
                        </h2>
                        <h2 className="flex items-center justify-center font-normal text-[#565656] text-xs mt-3">
                          {"Total Liened"}
                        </h2>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div>
        {" "}
        <RecentPaymentTableReinbursement
        // activeTab={4}
        // setActiveTab={setActiveTab}
        />
      </div>
    </div>
  );
};

export default OverviewReinbursement;
