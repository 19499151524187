import React, { useEffect } from "react";
import Card from "shared/Card";
import Table from "layout/table/Table";
import {
  PAIconOutlineSearch,
  PAIconFilter,
  PAIconArrowDownThin,
  PAIconClearSelect,
} from "assets/images/svgs";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Pagination from "shared/Pagination";
import {
  confirmDirectFundingRequest,
  getPendingDirectFundingConfirmation,
  getVirtualAccounts,
} from "appstate/api/invoice/invoice";
import EarlyPaymentVirtualAccountSelection from "features/invoice/fundingSection/earlyPaymentRequest/singlerequest/Modal/VirtualAccountSelection";
import EarlyPaymentConfirmation from "features/invoice/fundingSection/earlyPaymentRequest/singlerequest/Modal/EarlyPaymentConfirmation";
import { useSelector } from "react-redux";
import PinModalPayment from "features/invoice/fundingSection/earlyPaymentRequest/singlerequest/Modal/PinModal";
import SuccessEarlyPayment from "features/invoice/fundingSection/earlyPaymentRequest/singlerequest/Modal/SuccessEarlyPayment";
import { formatCurrency } from "utilities/helper/formatters";

const Pending = () => {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [data, setData] = useState([]);
  // eslint-disable-next-line
  const [size, setSize] = useState(10);
  // eslint-disable-next-line
  const [error, setError] = useState(null);
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [showVirtualSelectionModal, setShowVirtualSelectionModal] =
    useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [open, setOpen] = useState(false);
  const [virtualAccounts, setVirtualAccounts] = useState([]);
  const [showPin, setShowPin] = useState(false);
  const [otpInputs, setOtpInputs] = useState(["", "", "", ""]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [tab, setTab] = useState("single_request");
  const [bulkData, setBulkData] = useState([]);
  // eslint-disable-next-line
  const { earlyPaymentLoading } = useSelector(
    (state) => state?.supplier
  );

  const [endPage, setEndPage] = useState(5);

  const handleFilterChange = (e) => {
    const value = e.target.value;
    let updatedFilters = [...selectedFilters];

    if (updatedFilters.includes(value)) {
      updatedFilters = updatedFilters.filter((filter) => filter !== value);
    } else {
      updatedFilters.push(value);
    }

    setSelectedFilters(updatedFilters);
  };

  useEffect(() => {
    const fetchVirtualAccounts = async () => {
      try {
        const result = await getVirtualAccounts();
        setVirtualAccounts(result?.data);
      } catch (error) {
        console.error("Failed to fetch virtual accounts:", error);
      }
    };

    fetchVirtualAccounts();
  }, []);

  const fetchPendingData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await getPendingDirectFundingConfirmation({
        size,
        sort: "invoiceDate",
        bulk: false,
        sortDirection: "asc",
        page,
      });
      setData(response?.data);
    } catch (err) {
      setError(err?.response?.data?.message || "Failed to fetch data");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPendingBulkData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await getPendingDirectFundingConfirmation({
        size,
        sort: "invoiceDate",
        bulk: true,
        sortDirection: "asc",
        page,
      });
      setBulkData(response?.data);
    } catch (err) {
      setError(err?.response?.data?.message || "Failed to fetch data");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPendingData();
    fetchPendingBulkData();
    // eslint-disable-next-line
  }, [page, size]);



  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const navigate = useNavigate();

  const totalPages = data?.length;

  const handleCheckboxToggle = (id) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };

  const handleShowVirtualAccountSelection = () => {
    setShowVirtualSelectionModal(true);
  };

  const handleCloseVirtualAccount = () => {
    setShowVirtualSelectionModal(false);
  };

  const columns = [
    {
      name: (
        <div className="flex items-center gap-4">
          <input
            type="checkbox"
            onChange={(e) => {
              const isChecked = e.target.checked;
              setSelectedIds(isChecked ? data.map((item) => item?.directFundingId) : []);
            }}
          />
          <span className="whitespace-nowrap">Request Reference</span>
        </div>
      ),
      selector: (row) => (
        <div className="flex items-center gap-4">
          <input
            type="checkbox"
            checked={selectedIds.includes(row?.directFundingId)}
            onChange={() => handleCheckboxToggle(row?.directFundingId)}
          />
          <span>{row.reference}</span>
        </div>
      ),
      sortable: true,
    },
    {
      name: <span className="whitespace-nowrap">Invoice Number</span>,
      selector: (row) => `INV-${row.invoiceNumber}`,
      sortable: true,
    },
    {
      name: <span className="whitespace-nowrap">Supplier</span>,
      selector: (row) => row?.supplierCompanyName,
      sortable: true,
    },
    // {
    //   name: "Invoice Date",
    //   selector: (row) => row?.invoiceDate,
    //   sortable: true,
    // },
    {
      name: "Payment Date",
      selector: (row) => row?.earlyPaymentDate,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row?.currency,
      sortable: true,
    },
    {
      name: "Request Amount",
      selector: (row) => `${formatCurrency(Number(row?.requestAmount))}`,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row?.status,
      sortable: true,
      cell: (row) =>
        row.status === "PENDING_CONFIRMATION" ? (
          <p className="text-[#F08000] whitespace-nowrap">PENDING CONFIRMATION</p>
        ) : row.status === "ACCEPTED" ? (
          <p className="text-[#2EA923] whitespace-nowrap">{row?.status}</p>
        ) : (
          <p className="whitespace-nowrap">{row.status}</p>
        ),
    },
  ];

  const columnBulk = [
    {
      name: (
        <input
          type="checkbox"
          onChange={(e) => {
            const isChecked = e.target.checked;
            setSelectedIds(isChecked ? data?.map((item) => item?.directFundingId) : []);
          }}
        />
      ),
      selector: (row) => (
        <input
          type="checkbox"
          checked={selectedIds.includes(row?.directFundingId)}
          onChange={() => handleCheckboxToggle(row?.directFundingId)}
        />
      ),
      sortable: false,
    },
    {
      name: <span className="whitespace-nowrap">Request Ref.No</span>,
      selector: (row) => row?.reference,
      sortable: true,
    },
    {
      name: <span className="whitespace-nowrap">Supplier</span>,
      selector: (row) => row?.supplierCompanyName,
      sortable: true,
    },
    // {
    //   name: <span className="whitespace-nowrap">Due Date</span>,
    //   selector: (row) => row?.invoiceDueDate,
    //   sortable: true,
    // },
    {
      name: "Payment Date",
      selector: (row) => row?.earlyPaymentDate,
      sortable: true,
    },
    {
      name: "No Of Invoices",
      selector: (row) => row?.numberOfInvoices,
      sortable: true,
    },
    {
      name: "Total Invoice Amount",
      selector: (row) => `${formatCurrency(Number(row?.invoiceAmount))}`,
      sortable: true,
    },
    {
      name: "Total Request Amount",
      selector: (row) => `${formatCurrency(Number(row?.requestAmount))}`,
      sortable: true,
    },
    {
      name: "Total Discount Amount",
      selector: (row) => `${formatCurrency(Number(row?.discountAmount))}`,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row?.status,
      sortable: true,
      cell: (row) =>
        row.status === "PENDING_CONFIRMATION" ? (
          <p className="text-[#F08000]">{row.status === "PENDING_CONFIRMATION" && "PENDING CONFIRMATION"}</p>
        ) : row.status === "ACCEPTED" ? (
          <p className="text-[#2EA923]">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        ),
    },
  ];

  function handleSendRequest() {
    setOpen(false);
    setShowPin(true);
  }

  const handleDirectFundingRequestConfirmation = async () => {
    let virtualAccountId = selectedAccount?.id;
    try {
      setIsLoading(true);
      const pin = otpInputs.toString();
      const directFundingIds = selectedIds;

      const transactPin = pin.replace(/,/g, "");
      const response = await confirmDirectFundingRequest({
        directFundingIds: directFundingIds,
        transactionPin: transactPin,
        reimbursementAccountId: virtualAccountId,
      });

      if (response?.status === 200) {
        setShowConfirmModal(false);
        setShowSuccess(true);
        fetchPendingData();
        fetchPendingBulkData();
        setTimeout(() => {
          navigate("/factoring/directfunding");
        }, 2000)
      }
    } catch (error) {
      console.error("Error confirming payment:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onClosePinModal = () => {
    setSelectedAccount(null);
    setShowPin(false);
  };

  const confirmPayment = () => {
    setShowConfirmModal(true);
    setShowPin(false);
  };

  const toDetail = (id, invoiceId) => {
    navigate(`/factoring/facdirectfundingpendingdetail/${id}`, { state: { invoiceId } });
  };

  const toDetailBulk = (id) => {
    navigate(`/factoring/facdirectfundingpendingdetailbulk/${id}`);
  };

  const closeEarlyPaymentConfirmation = () => {
    setSelectedAccount(null);
    setOpen(false);
  }

  const closeConfirmModal = () => {
    setShowConfirmModal(false);
    setSelectedAccount(null);
    // navigate('/factoring/directfunding')
  }



  const isButtonDisabled = selectedIds.length === 0;
  return (
    <>
      {open && (
        <div className="absolute mt-[20px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white w-[614px] h-[254px] z-10 rounded-[10px] shadow-lg">
          <EarlyPaymentConfirmation
            hide={closeEarlyPaymentConfirmation}
            isLoading={earlyPaymentLoading}
            handleAssociate={handleSendRequest}
            earlyPayment={true}
            invoice={true}
            warning={
              <>
                Would you like to use this account{" "}
                <span className="text-primaryColor">
                  {selectedAccount?.accountNumber}
                </span>{" "}
                for reimbursement?
              </>
            }
          />
        </div>
      )}
      {showSuccess && (
        <SuccessEarlyPayment
          isOpen={showSuccess}
          onClose={() => setShowSuccess(false)}
          message="Confirmation successful"
          invoice={true}
        />
      )}
      {showConfirmModal && (
        <div className="absolute mt-[20px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white w-[614px] h-[254px] z-10 rounded-[10px] shadow-lg">
          <EarlyPaymentConfirmation
            hide={closeConfirmModal}
            isLoading={earlyPaymentLoading}
            handleAssociate={handleDirectFundingRequestConfirmation}
            // earlyPayment={true}
            warning="Would you like to proceed with this confirmation? "
          />
        </div>
      )}
      {showPin && (
        <PinModalPayment
          confirmPayment={confirmPayment}
          isOpen={showPin}
          onCancel={onClosePinModal}
          otpInputs={otpInputs}
          setOtpInputs={setOtpInputs}
          invoice={true}
        />
      )}
      {showVirtualSelectionModal && (
        <div>
          <EarlyPaymentVirtualAccountSelection
            handleCloseVirtualAccount={handleCloseVirtualAccount}
            setOpen={setOpen}
            // onClose={onClose}
            virtualAccounts={virtualAccounts}
            selectedAccount={selectedAccount}
            setSelectedAccount={setSelectedAccount}
            invoice={true}
          />
        </div>
      )}
      <div className="my-2 grid md:grid-cols-2 items-center">
        <div className="gap-3 flex relative">
          <div>
            <div
              className="flex items-center gap-[4px] border border-[#DEDEDE] bg-white shadow-sm rounded-[5px] px-4 py-3 text-sm focus:outline-none cursor-pointer"
              onClick={handleDropdownToggle}
            >
              Filter{" "}
              {selectedFilters.length > 0 && (
                <span className="bg-[#7b61ff] rounded-lg p-1 px-2">
                  {selectedFilters.length}
                </span>
              )}{" "}
              Selected <PAIconArrowDownThin />
              {selectedFilters.length > 0 ? (
                <span>
                  <PAIconClearSelect onClick={() => setSelectedFilters([])} />
                </span>
              ) : (
                <></>
              )}
            </div>
            {isDropdownOpen && (
              <div className="absolute top-full mt1 wfull border border-[#DEDEDE] shadow-lg rounded-b-[5px] px-4 py-3 bg-white">
                <label className="block">
                  <input
                    type="checkbox"
                    value="date"
                    checked={selectedFilters.includes("date")}
                    onChange={handleFilterChange}
                  />
                  Date
                </label>
                <label className="block">
                  <input
                    type="checkbox"
                    value="name"
                    checked={selectedFilters.includes("name")}
                    onChange={handleFilterChange}
                  />
                  Name
                </label>
                <label className="block">
                  <input
                    type="checkbox"
                    value="currency"
                    checked={selectedFilters.includes("currency")}
                    onChange={handleFilterChange}
                  />
                  Currency
                </label>
                <label className="block">
                  <input
                    type="checkbox"
                    value="amount"
                    checked={selectedFilters.includes("amount")}
                    onChange={handleFilterChange}
                  />
                  Amount
                </label>
              </div>
            )}
          </div>
          <div>
            <select
              className="border border-[#DEDEDE] shadow-sm rounded-[5px] px-4 py-3 text-sm focus:outline-none cursor-pointer"
              onChange={(e) => { }}
            >
              <option value="">Sort by</option>
              <option value="date">Date</option>
              <option value="name">Name</option>
            </select>
          </div>
        </div>
        <div className="flex md:col-span-1 justify-end">
          <div>
            <div className="flex items-center justify-between gap-5">
              <div className="border border-transparent md:border-gray md:shadow-sm my-4 rounded-[5px] px-2 lg:w-[300px] bg-white">
                <div className="items-center flex bg-white">
                  <PAIconOutlineSearch />
                  <input
                    className="bg-transparent p-2 w-full focus:outline-none text-sm bg-white"
                    type="text"
                    placeholder="Search for invoices"
                  />
                </div>
                <div className="lg:hidden flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer">
                  <PAIconFilter className="bg-[#2FA06A]" />
                </div>
              </div>

              <div class=" px-6 py-2 bg-[#f2f2f2]/95 rounded-[5px] border border-[#cccccc] justify-start items-center gap-2.5 inline-flex">
                <div class="text-black text-sm font-medium ">Search</div>
              </div>
            </div>
          </div>

          {/* ends here */}
        </div>
      </div>

      <Card className="rounded-[10px] mt-[24px] md:min-h-[752px] lg:min-h-[752px]">
        <div className="px-[24px] ">
          <div className="flex justify-between">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingTop: 20,
              }}
            >
              <span className="text-black text-sm font-medium ">
                Pending your confirmation
              </span>
              <span className="text-black text-sm font-light ">
                Invoice request awaiting your confirmation by your supplier to
                be sent to a funder for direct funding
              </span>
            </div>
            {/* this button */}
            {/* onClick={handleShowVirtualAccountSelection} */}
            <div style={{ paddingTop: 20 }}>
              <button
                className={`px-4 py-2 rounded-md ${isButtonDisabled ? "bg-[#E8E8E8] text-white cursor-not-allowed" : "bg-primaryColor text-white"
                  }`}
                disabled={isButtonDisabled}
                onClick={handleShowVirtualAccountSelection}
              >
                Confirm
              </button>

            </div>
          </div>
          <div class="flex items-center pt-8">
            <p
              className={
                tab === "single_request"
                  ? "text-[14px] text-primaryColor border-b-[1px] border-primaryColor cursor-pointer"
                  : "text-[14px] cursor-pointer"
              }
              onClick={() => {
                setTab("single_request");
              }}
            >
              Single Requests
            </p>
            <p
              className={
                tab === "bulk_request"
                  ? "text-[14px] ml-4 text-primaryColor border-b-[1px] border-primaryColor cursor-pointer"
                  : "text-[14px] ml-4 cursor-pointer"
              }
              onClick={() => {
                setTab("bulk_request");
              }}
            >
              Bulk Requests
            </p>
          </div>

        </div>
        <div className="px-[24px] pb-[24px]">
          {tab === "single_request" && <Table
            columns={columns}
            data={data}
            onRowClicked={(row) => toDetail(row?.directFundingId, row?.invoiceId)}
            pointer
            tableHeader
            className="mt-5"
          />}
          {tab === "bulk_request" && <Table
            columns={columnBulk}
            data={bulkData}
            onRowClicked={(row) => toDetailBulk(row.directFundingId)}
            pointer
            tableHeader
            className="mt-5"
          />}
        </div>
        <div className="flex justify-center gap-x-[8px]  mt-12">
          {totalPages > 1 && (
            <Pagination
              page={page}
              startPage={startPage}
              setEndPage={setEndPage}
              endPage={endPage}
              setStartPage={setStartPage}
              setPage={setPage}
              totalPages={totalPages}
            />
          )}
        </div>{" "}
      </Card>
    </>
  );
};

export default Pending;
