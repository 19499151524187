import React, { useRef, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { PAIconCancelMini } from "assets/images/svgs";

const EnterPin = ({
  isOpen,
  onClose,
  onSubmit,
  transferInfoValues,
  hidePin,
  otpInputs,
  setOtpInputs,
}) => {
  const inputRefs = useRef([]);

  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, otpInputs.length);
  }, [otpInputs.length]);

  const handleInputChange = (index, value) => {
    if (!isNaN(value) || value === "") {
      const newOtpInputs = [...otpInputs];
      newOtpInputs[index] = value;
      setOtpInputs(newOtpInputs);

      if (value !== "" && index < otpInputs.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && index > 0 && otpInputs[index] === "") {
      const newOtpInputs = [...otpInputs];
      newOtpInputs[index - 1] = "";
      setOtpInputs(newOtpInputs);

      inputRefs.current[index - 1].focus();
    }
  };

  const modalVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 50 },
  };

  return (
    <>
      <AnimatePresence>
        {isOpen && !hidePin && (
          <motion.div
            className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg rounded-[10px] z-[9999]"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={modalVariants}
            transition={{ duration: 0.3 }}
          >
            <div className="bg-white p10 px5 rounded-md max-w-xs md:max-w-lg w-full pb-5 px10">
              <div className="flex justify-between items-center py-5 px-6 rounded-t-[10px] bg-[#FAFAFA]">
                <p className="text-sm text-[#7A7A7A] font-thin">
                  Transfer Funds
                </p>
                <button
                  onClick={onClose}
                  className="gap-2 text-xl cursor-pointer flex justify-center items-center"
                >
                  <PAIconCancelMini />{" "}
                  <span className="text-sm text-[#565656] flex items-center">
                    Close
                  </span>
                </button>
              </div>

              <form
                className="w-full px-7 pt-7"
                onSubmit={(e) => {
                  e.preventDefault();
                  onSubmit(otpInputs?.join(""));
                }}
              >
                <p className="mb-5 flex justify-center text-[#7A7A7A] font-thin">
                  Enter your PIN
                </p>
                <div className="mb-5 flex flex-row justify-center gap-2">
                  {otpInputs.map((value, index) => (
                    <input
                      key={index}
                      type="password"
                      value={value}
                      onChange={(e) => handleInputChange(index, e.target.value)}
                      onKeyDown={(e) => handleKeyDown(index, e)}
                      maxLength="1"
                      className={`border rounded p-2 ${
                        otpInputs[otpInputs.length - 1] === ""
                          ? "border-[#7A7A7A] focus:border-[#7A7A7A]"
                          : "border-[#07593D]"
                      } focus:outline-none w-10 h-10 flex-shrink-0 text-center font-bold`}
                      ref={(ref) => (inputRefs.current[index] = ref)}
                    />
                  ))}
                </div>
                <p className="text-[#7A7A7A] text-sm font-normal flex justify-center my-5">
                  Enter your transaction PIN to transfer funds to desired
                  account
                </p>

                <div className="flex gap-4 justify-end mt-10">
                  <button
                    type="button"
                    onClick={onClose}
                    className="text-center px-6 py-2.5 rounded-[5px] text-[#7A7A7A] text-base font-normal font-['SF Pro Display'] tracking-wide"
                  >
                    Cancel
                  </button>
                  <button
                    disabled={otpInputs[otpInputs.length - 1] === ""}
                    className={`text-center ${
                      otpInputs[otpInputs.length - 1] === ""
                        ? "bg-[#EDEDED] text-[#7A7A7A]"
                        : "bg-primaryColor text-white"
                    } px-6 py-2.5 rounded-[5px] text-base font-normal`}
                    type="submit"
                  >
                    Next
                  </button>
                </div>
              </form>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default EnterPin;
