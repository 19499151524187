import React, { useEffect, useState } from "react";

const ProgressModal = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev < 100) {
          return prev + 1;
        } else {
          clearInterval(interval);
          return 100;
        }
      });
    }, 25);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="max-w-sm w-full mx-auto bg-white shadow-md rounded-lg overflow-hidden">
        <div className="px-5 py-3 flex justify-between items-center">
          <h3 className="text-zinc-900 text-lg">Download Progress</h3>
          <svg
            strokeWidth={2}
            stroke="currentColor"
            viewBox="0 0 24 24"
            fill="none"
            className="h-6 w-6 text-zinc-900 e"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              strokeLinejoin="round"
              strokeLinecap="round"
            />
          </svg>
        </div>
        <div className="px-5 pb-5">
          <p className="mb-2 text-sm text-zinc-600 -400">
            File: funding-agreement.pdf downloading...
          </p>
          <div className="w-full bg-zinc-200 rounded-full h-2.5">
            <div
              style={{
                width: `${progress}%`,
                transition: "width 0.2s ease-in-out",
              }}
              className={`${
                progress < 55 ? "bg-secondaryColor" : "bg-primaryColor"
              } h-2.5 rounded-full`}
            />
          </div>
          <div className="flex justify-between items-center mt-3">
            <span className="text-sm text-zinc-600">{progress}% Complete</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressModal;
