import { useState, useEffect } from "react";
import emptyBid from "../../../../../assets/images/svgs/empty-bid.png";
import useCountdown from "utilities/helper/useCountdown";
import { formatCapitaliseString } from "utilities/helper/formatCapitaliseString";
import { formatCapitalize } from "utilities/formatCapitalize";
// import cocaCola from "../../../../../assets/images/svgs/cocacola.svg";

const BidDetails = ({ data, status = "" }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setIsActive] = useState("history");
  const [tab, setTab] = useState("payment");
  const { timeLeft, isTimeUnderOneHour } = useCountdown(data?.earlyPaymentDate);

  // console.log(data);

  useEffect(() => {
    setIsLoading(true);

    setTimeout(() => setIsLoading(false), 1000);
  }, []);
  return (
    <div className="bg-white w-full rounded-[10px] pt-5 pb-24 h-[100%]">
      <div className="max-h-[15%]">
        <div className="flex items-center border-b-[1px] border-b-slate-300">
          <h2
            onClick={() => {
              setTab("payment");
            }}
            style={{
              color: tab === "payment" ? "#07593D" : "#777777",
              borderBottom: tab === "payment" ? "1px solid #07593D" : "none",
            }}
            className="text-lg px-6 cursor-pointer font-semibold text-gray-800 pb-4"
          >
            Early Payment Details
          </h2>
          <h2
            onClick={() => {
              setTab("bids");
            }}
            style={{
              color: tab === "bids" ? "#07593D" : "#777777",
              borderBottom: tab === "bids" ? "1px solid #07593D" : "none",
            }}
            className="text-lg px-6 cursor-pointer font-semibold text-gray-800 pb-4 text-primaryColor"
          >
            Bid Details
          </h2>
        </div>

        {tab !== "payment" && (
          <div className="flex justify-between text-sm text-gray-600 mt-4 px-6">
            <span
              onClick={() => {
                setIsActive("history");
              }}
              className={
                isActive === "history"
                  ? "text-secondaryColor border-b-[1px] border-secondaryColor cursor-pointer"
                  : "text-[#959595] cursor-pointer"
              }
            >
              Bid history: {data?.bidHistoryResponse?.length}
            </span>
            <span
              onClick={() => {
                setIsActive("invited");
              }}
              className={
                isActive === "invited"
                  ? "text-secondaryColor border-b-[1px] border-secondaryColor cursor-pointer"
                  : "text-[#959595] cursor-pointer z-[99999]"
              }
            >
              Funders invited: {data?.funderInvitedResponse?.length}
            </span>
            <span
              onClick={() => {
                setIsActive("viewed");
              }}
              className={
                isActive === "viewed"
                  ? "text-secondaryColor w-fit border-b-[1px] border-secondaryColor cursor-pointer"
                  : "text-[#959595] cursor-pointer"
              }
            >
              Viewed by funders: {data?.viewByFunderResponse?.length}
            </span>
          </div>
        )}
      </div>

      {tab === "payment" ? (
        <div className="px-4 mt-4">
          <div className="flex justify-between my-5">
            <div className="flex gap-2">
              {/* <PAIconModelOne /> */}
              <div className="flex flex-col">
                <span className="text-sm"> {data?.supplierCompanyName} </span>
                <span className="text-[#979797]  text-xs"> Supplier </span>
              </div>
            </div>
            <div className="flex flex-col items-end">
              <span className="text-sm">{data?.lockRate || "-"}%</span>
              <span className="text-[#979797]  text-xs">Discount rate</span>
            </div>
          </div>

          <div className="flex justify-between pb-5">
            <div className="flex gap-2">
              <div className="flex flex-col">
                <span className="text-sm">
                  N{data?.discountAmount?.toLocaleString() || "0.00"}
                </span>
                <span className="text-[#979797]  text-xs">Discount Amount</span>
              </div>
            </div>
            <div className="flex flex-col items-end">
              <span className="text-sm">{data?.earlyPaymentDate}</span>
              <span className="text-[#979797]  text-xs">
                Early Payment Request Date
              </span>
            </div>
          </div>

          <div className="flex justify-between mt-5 pb-10">
            <div className="flex gap-2">
              <div className="flex flex-col">
                <span className="text-sm">
                  NGN {data?.requestAmount?.toLocaleString() || "0.00"}
                </span>
                <span className="text-[#979797]  text-xs">Request Amount</span>
              </div>
            </div>
            <div className="flex flex-col">
              <span className="font-semibold text-[16px]">
                NGN {data?.payableAmount?.toLocaleString()}
              </span>
              <span className="text-[#979797] text-xs">Payable amount</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-16">
          {isActive === "history" &&
            (data?.bidHistoryResponse?.length < 1 ? (
              <div className="flex flex-col justify-center items-center mb-6 mt-8">
                <img src={emptyBid} alt="No Data" className="h-80 w-auto" />
                <div className="text-center text-3xl font-bold text-gray-800 mb-2">
                  <span
                    className={`text-[26.136px] font-bold ${
                      isTimeUnderOneHour ? "text-red-500" : ""
                    }`}
                  >
                    {(timeLeft.days > 0 ||
                      timeLeft.hours > 0 ||
                      timeLeft.minutes > 0 ||
                      timeLeft.seconds > 0) &&
                    formatCapitaliseString(status) !== "Cancelled" ? (
                      `${timeLeft.days}d:${timeLeft.hours}h:${timeLeft.minutes}m:${timeLeft.seconds}s`
                    ) : isLoading ? (
                      <span className="text-secondaryColor">Fetching...</span>
                    ) : (
                      <span className="text-red">Expired</span>
                    )}
                  </span>
                </div>
                <p className="text-center text-gray-500 mb-4">
                  Count down to early payment date
                </p>
              </div>
            ) : (
              <div className="flex flex-col justify-center items-center mb-6 mt16 mx-4">
                {data?.bidHistoryResponse?.map((history, index) => (
                  <section className="flex justify-between w-full items-center">
                    <div className="p-2.5 flex flex-col">
                      <span className="text-xs">
                        {history?.funderName || "N/A"}
                      </span>

                      <span className="text-[#959595] text-[10px]">Funder</span>
                    </div>

                    <div className="p-2.5 flex flex-col">
                      <span className="text-xs">
                        {history?.lockRate || "-"}%
                      </span>

                      <span className="text-[#959595] text-[10px]">
                        Lock Rate
                      </span>
                    </div>

                    <div className="p-2.5 flex flex-col">
                      <span className="text-xs text-secondaryColor">
                        {formatCapitalize(history?.status) || "N/A"}
                      </span>

                      <span className="text-[#959595] text-[10px]">
                        Status{" "}
                      </span>
                    </div>

                    <div className="flex flex-col p-2.5">
                      <span className="text-xs">
                        {history?.remainingTime?.join("-") || "-"}
                      </span>

                      <span className="text-[#959595] text-[10px]">
                        Lock duration{" "}
                      </span>
                    </div>
                  </section>
                ))}
              </div>
            ))}

          {isActive === "invited" && (
            <div className="flex mt-16 flex-col justify-start px-5 mb-6">
              {data?.funderInvitedResponse?.map((item, index) => (
                <div
                  key={index}
                  className="flex justify-between items-center mb-4"
                >
                  <div className="flex items-center my-2">
                    {/* <img
                  src={item.img}
                  alt="Funder"
                  className="w-10 h-10 rounded-full mr-4"
                /> */}
                    <div>
                      <p className="text-[16px] font-regular text-gray-600">
                        {formatCapitalize(item?.funderName)}
                      </p>
                      <p className="text-xs text-zinc-400">Funder</p>
                    </div>
                  </div>

                  <div className="text-center">
                    <p className="text-[14px] ">{item?.contactNumber}</p>
                    <p className="text-[12px] text-zinc-400">Contact number</p>
                  </div>

                  <div className="text-right">
                    <p className="text-sm text-gray-600">{item?.funderEmail}</p>
                    <p className="text-[12px] text-zinc-400">Email</p>
                  </div>
                </div>
              ))}
            </div>
          )}

          {isActive === "viewed" && (
            <div className="flex mt-16 flex-col justify-start px-5 mb-6">
              {data?.viewByFunderResponse?.length > 0 ? (
                <>
                  {data?.viewByFunderResponse?.map((item, index) => (
                    <div
                      key={index}
                      className="flex justify-between items-center mb-4"
                    >
                      <div className="flex items-center my-2">
                        <div>
                          <p className="text-[16px] font-regular text-gray-600">
                            {formatCapitalize(item?.funderName)}
                          </p>
                          <p className="text-[12px] font-regular text-zinc-400">
                            Funder
                          </p>
                        </div>
                      </div>

                      <div className="text-center">
                        <p className="text-[14px] ">{item?.contactNumber}</p>
                        <p className="text-[12px] text-zinc-400">
                          Contact number
                        </p>
                      </div>

                      <div className="text-right">
                        <p className="text-sm text-gray-600">
                          {item?.funderEmail}
                        </p>
                        <p className="text-[12px] text-zinc-400">Email</p>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div className="flex flex-col justify-center items-center mb-6 h-fit">
                  <div className="h-full">
                    <img src={emptyBid} alt="No Data" className="h-80 w-auto" />
                  </div>

                  <p className="text-center text-gray-500 mb-4">
                    Viewed funder list is empty
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default BidDetails;
