import {
  allBuyerAssociatedFunders,
  buyerFunderDisassociate,
  buyerOnboardingInviteToFunder,
  getAssociatedFunderId,
} from "appstate/buyer/buyerSlice";
import {
  PAIconArrowLeftGreen,
  PAIconDoc,
  PAIconDropUser,
  PAIconEditlight,
  PAIconLink,
  PAIconOption,
} from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import Table from "layout/table/Table";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import Card from "shared/Card";
import Loading from "shared/Loading";
import Pagination from "shared/Pagination";
import AccountModalContainer from "shared/accountModal/Modal";
import InviteModal from "shared/inviteModal";
import { useLocation } from "react-router-dom";
import TableHeaderFunder from "./TableHeaderFunder";
import DirectFundingInvoiceModal from "features/invoice/buyerInvoice/Modals/DirectFundingInvoiceModal";
import SelectAccountModal from "features/invoice/buyerInvoice/Modals/SelectAccountModal";
import AcceptModal from "../NewEarlyPaymentRequests/newSingleEarlyPayment/Details/acceptModal";
import EnterPin from "features/invoice/buyerInvoice/Modals/EnterPin";
import AccountVerifyAndSuccess from "features/invoice/buyerInvoice/Modals/AccountVerifyAndSuccess";
import { useNavigate } from "react-router-dom";
import { pendingSingleEarlyPaymentDetails } from "appstate/buyer/buyerSlice";

const FunderList = () => {
  const location = useLocation();
  const state = location.state;
  const earlyPaymentRequestId = state?.earlyPaymentRequestId;

  const dropdownRef = useRef();
  const [dropdown, setDropdown] = useState(false);
  const [invite, setInvite] = useState([]);
  const [success, setSuccess] = useState(false);
  const [getId, setGetId] = useState(null);
  const [openDisassociateModal, setOpenDisAssociateModal] = useState(false);
  const [newInvite, setNewInvite] = useState("");
  const [open, setOpen] = useState(false);
  const [atBottom, setAtBottom] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const [directFundingInvoiceModal, setDirectFundingInvoiceModal] =
    useState(false);
  // const [selectedAccount, setSelectedAccount] = useState(null);
  const [checkedAccounts, setCheckedAccounts] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isenterPinModal, setIsEnterPinModal] = useState(false);
  const [isVerifyAccountModal, setIsVerifyAccountModal] = useState(false);
  const [isLoadingAccount, setIsLoadingAccount] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [isDirectFundingActive, setIsDirectFundingActive] = useState(false);
  const [isSelectAccountModalOpen, setSelectAccountModalOpen] = useState(false);
  const [selectedFunder, setSelectedFunder] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [invoiceData, setInvoiceData] = useState([]);
  const [requestData, setRequestData] = useState();

  useEffect(() => {
    dispatch(pendingSingleEarlyPaymentDetails(earlyPaymentRequestId));
  }, [dispatch, earlyPaymentRequestId]);

  const pageSize = 10;
  const {
    buyerAssociatedFundersData,
    isLoading,
    inviteLoading,
    disAssociateLoading,
  } = useSelector((state) => state?.buyer);
  const [data, setData] = useState([]);
  const handleDropdown = (row) => {
    if (dropdown === row.id) {
      setDropdown(null);
    } else {
      setDropdown(row.id);
    }
  };

  // const navigate = useNavigate();

  useEffect(() => {
    if (isLoadingAccount === true) {
      setTimeout(() => {
        setIsLoadingAccount(false);
        setTimeout(() => {
          setIsApproved(true);
          setTimeout(() => {
            navigate("/payablefinancing/overview");
          }, 2000);
        }, 3000);
      }, 3000);
    }
  }, [isLoadingAccount, navigate]);

  useEffect(() => {
    const search = "";
    if (companyName.length <= 0) {
      dispatch(allBuyerAssociatedFunders({ page, pageSize, search }));
    }
  }, [dispatch, page, pageSize, companyName]);

  const onRowClicked = (row) => {
    dispatch(getAssociatedFunderId(row?.id));
    setDirectFundingInvoiceModal(true);
    setIsDirectFundingActive(true);
    setSelectedFunder(row);
  };

  // console.log(fromBuyer)

  const handleOpenModal = (row) => {
    setGetId(row?.userId);
    setOpenDisAssociateModal(true);
  };

  const handleDisAssociate = () => {
    const funderId = getId;
    const search = "";
    dispatch(buyerFunderDisassociate(funderId)).then((data) => {
      if (data?.payload?.success === true) {
        dispatch(allBuyerAssociatedFunders({ page, pageSize, search }));
        setOpenDisAssociateModal(false);
      }
    });
  };

  useEffect(() => {
    setData(buyerAssociatedFundersData.data);

    const handleClickOutside = (event) => {
      if (
        dropdown &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setDropdown(false);
      }
    };

    const handleDocumentClick = (e) => {
      const screenHeight = window.innerHeight;
      const clickY = e.clientY;
      const bottomThreshold = 200;
      if (screenHeight - clickY <= bottomThreshold) {
        setAtBottom(true);
      } else {
        setAtBottom(false);
      }
    };

    document.addEventListener("click", handleDocumentClick);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdown, buyerAssociatedFundersData]);

  const totalPages = buyerAssociatedFundersData?.meta?.totalElements;

  const column = [
    {
      name: "Name",
      selector: (row) => row?.fullName,
    },
    {
      name: "Company",
      selector: (row) => row?.companyName,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
      width: "15rem",
    },
    {
      name: "Phone",
      selector: (row) => row?.phoneNumber,
    },
    {
      cell: (row) => (
        <div>
          <PAIconOption title="Options" onClick={() => handleDropdown(row)} />
          {dropdown === row.id && row && (
            <div className="relative" ref={dropdownRef}>
              <Card
                className={`w-[160px] right-[-10px] ${
                  atBottom && "bottom-[20px]"
                } absolute z-10 drop-shadow-md rounded-md py-[12px]`}
              >
                <div className="flex items-center gap-x-[10px] mb-[12px]  pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]">
                  <span>
                    <PAIconDropUser />
                  </span>
                  <p className="font-sm font-normal">View Details</p>
                </div>
                <div className="flex items-center gap-x-[10px] mb-[12px] pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]">
                  <span>
                    <PAIconDoc />
                  </span>
                  <p className="font-sm font-normal">Create Invoice</p>
                </div>
                <div className="flex items-center gap-x-[10px] mb-[12px] pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]">
                  <span>
                    <PAIconEditlight />
                  </span>
                  <p className="font-sm font-normal">Edit Tier</p>
                </div>
                <div className="border border-silver"></div>
                <div
                  onClick={() => handleOpenModal(row)}
                  className="flex items-center gap-x-[10px] mt-[10px] pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]"
                >
                  <span>
                    <PAIconLink />
                  </span>
                  <p className="font-sm font-normal text-[#DE1515]">
                    Disassociate
                  </p>
                </div>
              </Card>
            </div>
          )}
        </div>
      ),
      width: "5rem",
    },
  ];

  let path = "buyer-associate-funders";

  const handleSearch = () => {
    const search = companyName;
    dispatch(allBuyerAssociatedFunders({ page, pageSize, search })).then(
      (data) => {
        setData(data?.payload?.data);
      }
    );
  };

  const handleSubmitEmails = () => {
    const body = invite;
    dispatch(buyerOnboardingInviteToFunder(body)).then((data) => {
      if (data?.payload?.success === true) {
        setSuccess(true);
        setInvite([]);
      }
    });
  };

  // Select account controls
  const handleSelectAccountClose = () => {
    setSelectAccountModalOpen(false);
  };

  const handleSelectAccountProceed = () => {
    setSelectAccountModalOpen(false);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    // setRejectModalOpen(false);
  };

  //Enter pin controls
  const handleEnterPin = () => {
    setIsEnterPinModal(true);
    setIsModalOpen(false);
  };

  const handleEnterPinModalClose = () => {
    setIsEnterPinModal(false);
  };

  const handleVerifyAccountModal = () => {
    setIsVerifyAccountModal(true);
    setIsEnterPinModal(false);
    setIsLoadingAccount(true);
  };

  const handleVerifyAccountModalClose = () => {
    setIsVerifyAccountModal(false);
  };

  const handleBackBtnClick = () => {
    let path = "/payablefinancing/overview";
    navigate(path, { state: { selectedtab: "newEarlyPaymentRequests" } });
  };

  return (
    <DashboardLayout section="Funders">
      <div
        onClick={handleBackBtnClick}
        className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer w-full"
      >
        <PAIconArrowLeftGreen />
        <span className="flex items-center text-primaryColor">Go Back</span>
      </div>
      <AccountModalContainer
        accontModal
        isShown={openDisassociateModal}
        onClose={() => {
          setOpenDisAssociateModal(false);
        }}
        isLoading={disAssociateLoading}
        success={success}
        handleAssociate={handleDisAssociate}
        warning="Are you sure you would like to disassociate with this member?"
      />
      <InviteModal
        isShown={open}
        onClose={() => {
          setOpen(false);
          setSuccess(false);
        }}
        message=" The Funder would received a mail that your are inviting to join
        PayEdge."
        heading="Invite Funder"
        info="You can add one or more funders at once with ease"
        role="Funder"
        invite={invite}
        setInvite={setInvite}
        newInvite={newInvite}
        setNewInvite={setNewInvite}
        success={success}
        setSuccess={setSuccess}
        handleAdd={handleSubmitEmails}
        isLoading={inviteLoading}
      />
      <Card className="p-[24px] rounded-[10px]">
        <TableHeaderFunder
          path={path}
          setProductName={setCompanyName}
          productName={companyName}
          search={handleSearch}
          supplier
          associateBtn
          inviteBtn
          accounts
          tier
          data={data}
          setOpen={setOpen}
          total="All Funders"
          details=""
        />
        <Table
          columns={column}
          data={data}
          onRowClicked={onRowClicked}
          progressPending={isLoading}
          progressComponent={<Loading />}
          pointer
          tableHeader
          className="mt-5"
        />
        <div className="flex justify-center gap-x-[8px] mt-[20px]">
          {!isLoading && totalPages > 10 && (
            <Pagination
              page={page}
              startPage={startPage}
              setEndPage={setEndPage}
              endPage={endPage}
              setStartPage={setStartPage}
              setPage={setPage}
              totalPages={totalPages}
            />
          )}
        </div>
      </Card>

      <DirectFundingInvoiceModal
        isOpen={directFundingInvoiceModal}
        setSelectAccountModalOpen={setSelectAccountModalOpen}
        setDirectFundingInvoiceModal={setDirectFundingInvoiceModal}
        setInvoiceData={setInvoiceData}
        invoiceData={invoiceData}
        setRequestData={setRequestData}
        requestData={requestData}
      />
      <SelectAccountModal
        isOpen={isSelectAccountModalOpen}
        onClose={handleSelectAccountClose}
        // setSelectedAccount={setSelectedAccount}
        confirm={handleSelectAccountProceed}
        setCheckedAccounts={setCheckedAccounts}
        checkedAccounts={checkedAccounts}
      />
      <AcceptModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        confirm={handleEnterPin}
        checkedAccounts={checkedAccounts}
      />
      <EnterPin
        isOpen={isenterPinModal}
        onClose={handleEnterPinModalClose}
        confirm={handleVerifyAccountModal}
        isDirectFundingActive={isDirectFundingActive}
        selectedFunder={selectedFunder}
        data={data}
        requestData={requestData}
        checkedAccounts={checkedAccounts}
      />
      <AccountVerifyAndSuccess
        isOpen={isVerifyAccountModal}
        onClose={handleVerifyAccountModalClose}
        // setPaymentViaWallet={setPaymentViaWallet}
        isLoading={isLoadingAccount}
        isApproved={isApproved}
      />
    </DashboardLayout>
  );
};

export default FunderList;
