import { motion } from "framer-motion";

import TransitionOpacity from "shared/animation/TransitionOpacity";
import ReportAndAnalisCard from "features/closedmarket/components/cards/ReportAndAnalysisCard";
import ViewMetricScoreModal from "features/closedmarket/components/carddetails/ViewMetricScoresModal";

import Card from "shared/Card";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

import {
  PAIconSmallCoke,
  PAIconSmallDangote,
  PAIconSmallNestle,
  PAIconCocacola,
} from "assets/images/svgs";
// import InvoicesTab from "features/invoice/SingleInvoice/TabsComponents/InvoicesTab";

const ReportAndAnalysis = () => {
  const navigate = useNavigate();
  const [viewInvoiceDetailModal, setViewInvoiceDetailModal] = useState(false);
  const dummyData = [
    {
      bidAmount: 3,
      buyerCompanyName: "Coca Cola",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      discountRate: 3,
      funderId: "4bddd358-5412-4d62-b0ed-b34e2c3129fa",
      invoiceAmount: 32164082.78,
      invoiceDueDate: "2024-03-01",
      invoiceId: "65dfc0942b607753910b022e",
      invoiceReference: "INV-001",
      lockedBy: "4bddd358-5412-4d62-b0ed-b34e2c3129fa",
      lockedDate: "2024-03-07",
      note: "Wale",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      supplierCompanyName: "Lancoan",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",
      userTypeIcon: PAIconCocacola,
      userType: "Supplier",
      requestType: "Bulk",
    },
    {
      bidAmount: 2,
      buyerCompanyName: "Coke Plc.",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f22",
      discountRate: 2,
      funderId: "4bddd358-5412-4d62-b0ed-b34e2c3129fa2",
      invoiceAmount: 32164082.78,
      invoiceDueDate: "2024-03-01",
      invoiceId: "65dfc0942b607753910b022e2",
      invoiceReference: "INV-345",
      lockedBy: "4bddd358-5412-4d62-b0ed-b34e2c3129fa2",
      lockedDate: "2024-03-07",
      note: "Wale",
      offerId: "65e1eda9e789120b67af15b42",
      numberofBidders: "235,490",
      supplierCompanyName: "Lancoan",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c3802",
      userTypeIcon: PAIconSmallCoke,
      userType: "Buyer",
      requestType: "Single",
    },
    {
      bidAmount: 4,
      buyerCompanyName: "Dangote",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f24",
      discountRate: 4,
      funderId: "4bddd358-5412-4d62-b0ed-b34e2c3129fa4",
      invoiceAmount: 32164082.78,
      invoiceDueDate: "2024-03-01",
      invoiceId: "65dfc0942b607753910b022e4",
      invoiceReference: "INV-937",
      lockedBy: "4bddd358-5412-4d62-b0ed-b34e2c3129fa4",
      lockedDate: "2024-03-07",
      note: "Wale",
      offerId: "65e1eda9e789120b67af15b44",
      numberofBidders: "235,490",
      supplierCompanyName: "Lancoan",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c3804",
      userTypeIcon: PAIconSmallDangote,
      userType: "Buyer",
      requestType: "Bulk",
    },
    {
      bidAmount: 5,
      buyerCompanyName: "Nestle",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f25",
      discountRate: 5,
      funderId: "4bddd358-5412-4d62-b0ed-b34e2c3129fa5",
      invoiceAmount: 32164082.78,
      invoiceDueDate: "2024-03-01",
      invoiceId: "65dfc0942b607753910b022e5",
      invoiceReference: "INV-892",
      lockedBy: "4bddd358-5412-4d62-b0ed-b34e2c3129fa5",
      lockedDate: "2024-03-07",
      note: "Wale",
      offerId: "65e1eda9e789120b67af15b45",
      numberofBidders: "235,490",
      supplierCompanyName: "Lancoan",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c3805",
      userTypeIcon: PAIconSmallNestle,
      userType: "Buyer",
      requestType: "Bulk",

    },
  ];

  const handleViewDetail = () => {
    setViewInvoiceDetailModal(true);
  };

  return (
    <>
      <ViewMetricScoreModal
        isShown={viewInvoiceDetailModal}
        onClose={() => setViewInvoiceDetailModal(false)}
      />
      <TransitionOpacity className="flex flex-col gap-8">
        <>
          <div className="flex flex-col semi-lg:flex-row gap-7 w-full ">
            <section className=" rounded-[5px] bg-unusualGray flex gap-6 flex-col extra-md:flex-row px-6 py-5 w-full semi-lg:w-full">
              <div className=" w-full">
                <div className="flex w-full flex-row gap-[18px] flex-wrap">
                  <div className=" border-l-2 border-white flex flex-col items-center justify-between px-14  py-7 gap-4 bg-primaryColor rounded-b-[10px] cursor-pointer text-white  md:w-[37%]">
                    <div>
                      <span className=" text-[50px] font-bold">{"53"}</span>
                    </div>
                    <span class="w-[198px] text-center text-white text-xl font-normal">
                      Request in the Closed market
                    </span>
                  </div>

                  <div className=" border-l-2 border-white flex flex-col   gap-4 rounded-[10px] cursor-pointer text-white w-[61%] bg-white ">
                    <div className=" flex bg-[#F08000] rounded-t-[10px] px-6 py-[8px]  ">
                      <div className="flex flex-row justify-between w-full">
                        <div className=" flex items-center justify-center">
                          <span class="text-white text-sm font-medium uppercase">
                            CLOSED Market
                          </span>
                        </div>
                        <div
                          class="text-[#222222] text-sm font-medium  bg-stone-50 rounded p-2.5"
                          on
                          onClick={() => navigate("/")}
                        >
                          View All Invoices
                        </div>
                      </div>
                    </div>

                    <div className="flex cursor-pointer justify-between bg-white py-5  rounded-[10px] px-6">
                      <div class=" flex-col justify-start  gap-2 inline-flex  ">
                        <div class="text-[#7A7A7A] text-sm font-normal ">
                          Total Invoice Amount
                        </div>
                        <div class="justify-start items-center gap-[3px] inline-flex">
                          <div class="text-[#222222]  text-xl font-bold ">
                            340,090.00
                          </div>
                        </div>
                      </div>

                      <div class=" h-[49px] flex-col justify-center  items-center inline-flex">
                        <div class="text-[black] text-lg font-medium ">-</div>
                      </div>

                      <div class="flex-col justify-start  gap-2 inline-flex ">
                        <div class="text-[#7A7A7A] text-sm font-normal ">
                          Total Bid requests
                        </div>
                        <div class="justify-center items-center gap-[3px] inline-flex">
                          <div class="text-[#222222]  text-xl font-bold  text-center">
                            <span>32 </span>
                            <span className="text-neutral-800 text-sm font-normal  ml-1">
                              Invoices
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class=" h-[49px] flex-col justify-center  items-center inline-flex ">
                        <div class="text-[black] text-lg font-medium ">-</div>
                      </div>

                      <div class=" flex-col justify-start  gap-2 inline-flex">
                        <div class="text-[#7A7A7A] text-sm font-normal ">
                          Total Funders
                        </div>
                        <div class="justify-center items-center gap-[3px] inline-flex">
                          <div class="text-[#222222]  text-xl font-bold  text-center">
                            54
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" flex flex-row w-full mt-4 gap-[18px]">
                  <div className="flex px-3 rounded-b-[10px] flex-wrap bg-white border-t-2 border-t-[#f08000] flex-col pt-5 pb-4 gap-4 w-1/2">
                    <div className="flex  flex-wrap justify-between ">
                      <span className="text-[20px] text-primaryColor">
                        Requests
                      </span>
                      <motion.button
                        whileTap={{ scale: 0.9 }}
                        className=" bg-[#f08000] text-white rounded-[5px]  py-[5px] px-[32px]"
                      >
                        Insight
                      </motion.button>
                    </div>

                    <div className="flex">
                      <div className="flex w-1/2 px-5 flex-col gap-3  border-r-2 border-r-[#07593D]">
                        <span class="text-center text-[#07593D] text-[40px] font-bold ">
                          13
                        </span>
                        <span className=" text-center text-sm text-[#565656]">
                          Posted
                        </span>
                      </div>

                      <div className="flex w-1/2 px-5 flex-col gap-3  border-r-2 border-r-[#07593D]">
                        <span class="text-center text-amber-600 text-[40px] font-bold ">
                          24
                        </span>
                        <span className=" text-center text-sm text-[#565656]">
                          Bid Active
                        </span>
                      </div>
                      <div className="flex w-1/2 px-5 flex-col gap-3">
                        <span class="text-center text-[#07593D] text-[40px] font-bold ">
                          16
                        </span>
                        <span className=" text-center text-sm text-[#565656]">
                          Pending Funding
                        </span>
                      </div>

                      {/* unit */}
                    </div>
                  </div>
                  {/* flex-side */}
                  <div className=" border-l-2 border-white flex flex-col   gap-4 rounded-[10px] cursor-pointer text-white w-[61%] bg-white ">
                    <div className=" flex bg-[#07593D] rounded-t-[10px] px-6 py-[8px]  ">
                      <div className="flex flex-row justify-between w-full">
                        <div className=" flex items-center justify-center">
                          <span class="text-white text-sm font-medium  uppercase">
                            Top Performing Funder
                          </span>
                        </div>
                        <div
                          class="text-[#222222] text-sm font-medium  bg-stone-50 rounded p-2.5"
                          onClick={handleViewDetail}
                        >
                          View Details
                        </div>
                      </div>
                    </div>

                    <div className="flex cursor-pointer justify-between bg-white py-5  rounded-[10px] px-6">
                      <div class=" flex-col justify-start  gap-2 inline-flex  ">
                        <div class="text-[#7A7A7A] text-sm font-normal ">
                          Funder
                        </div>
                        <div class="justify-start items-center gap-[3px] inline-flex">
                          <div class="text-[#222222]  text-xl font-bold">
                            Dangote PLC
                          </div>
                        </div>
                      </div>

                      <div class=" h-[49px] flex-col justify-center  items-center inline-flex">
                        <div class="text-[black] text-lg font-medium ">-</div>
                      </div>

                      <div class="flex-col justify-start  gap-2 inline-flex ">
                        <div class="text-[#7A7A7A] text-sm font-normal ">
                          Metric Score
                        </div>
                        <div class="justify-center items-center gap-[3px] inline-flex">
                          <div class="text-[#222222]  text-xl font-bold  text-center">
                            <span>97%</span>
                          </div>
                        </div>
                      </div>

                      <div class=" h-[49px] flex-col justify-center  items-center inline-flex ">
                        <div class="text-[black] text-lg font-medium ">-</div>
                      </div>

                      <div class=" flex-col justify-start  gap-2 inline-flex">
                        <div class="text-[#7A7A7A] text-sm font-normal ">
                          Number of Accepted bids
                        </div>
                        <div class="justify-center items-center gap-[3px] inline-flex">
                          <div class="text-[#222222]  text-xl font-bold  text-center">
                            54
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <TransitionOpacity className="min-h-[75vh] rounded-b-[10px] bg-unusualGray rounded-tl-[10px]  rounded-tr-[10px] ">
            <Card className="  rounded-tl-[10px]  rounded-tr-[10px] p-[0.5px] ">
              <div className=" px-[24px]  rounded-tl-[10px]  rounded-tr-[10px]  ">
                <div className="my-2 grid md:grid-cols-2 items-center">
                  <div className=" flex flex-col relative">
                    <div className="  flex ">
                      <div class="text-black text-base font-semibold ">
                        Top 10 Contested Invoices
                      </div>
                    </div>

                    <div class="text-neutral-400 text-xs font-normal ">
                      Invoices with the highest number of bidders
                    </div>
                  </div>

                  <div className="flex md:col-span-1 justify-end">
                    <div class="h-[34px] px-5 py-2.5 rounded-[5px] border border-neutral-400 justify-start items-center gap-2.5 inline-flex">
                      <div
                        class="text-neutral-800 text-xs font-normal font-['SF Pro Display']"
                        onClick={() => navigate(`topContestedInvoices`)}
                      >
                        View all
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>

            <div className=" p-6 flex flex-col gap-5 ">
              {dummyData.map((invoice) => {
                return (
                  <ReportAndAnalisCard
                    key={invoice?.invoiceId}
                    id={invoice?.invoiceId}
                    numberofBidders={invoice.numberofBidders}
                    supplierCompanyName={invoice.buyerCompanyName}
                    invoiceNumber={invoice?.invoiceReference}
                    doerType={invoice.userType}
                    invoiceAmount={invoice?.invoiceAmount}
                    invoiceDueDate={invoice?.invoiceDueDate}
                    supplierCompanyIcon={invoice.userTypeIcon}
                    requestType={invoice.requestType}
                  />
                );
              })}
            </div>
          </TransitionOpacity>
        </>
      </TransitionOpacity>
    </>
  );
};

export default ReportAndAnalysis;
