import request from "apiInstance";
import toast from "react-hot-toast";

export const playerUpdateProfile = async (data) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/${data.role}s/update`,
      data: data.body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
export const playerUpdateCompany = async (data) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/${data.role}s/update/company`,
      data: data.body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
export const resetUserPassword = async (body) => {
  try {
    const response = await request({
      method: "put",
      url: `admin/users/reset-password`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};


export const resetUserPasswordBuyer = async (body) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/buyers/edit-password`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const resetUserPasswordFunder = async (body) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/funders/edit-password`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const resetUserPasswordSupplier = async (body) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/suppliers/edit-password`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
export const enable2FA = async (body) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/2fa/enable`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
export const generateQR = async (body) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/2fa/qrcode/generate`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
export const enroll2FA = async (body) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/2fa/enroll`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
export const verifyOTP = async (body) => {
  try {
    const response = await request({
      method: "post",
      url: `/api/2fa/verify`,
      data: body,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
export const editNotificationPreference = async (body) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/notification/preferences`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getNotificationPreference = async () => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/notification/preferences`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
