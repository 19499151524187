import { AnimatePresence, motion } from "framer-motion";
import { PAIconArrowLeftGreen } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { useNavigate, useParams } from "react-router-dom";
import TransitionScale from "shared/animation/TransitionScale";
import { useEffect, useState } from "react";
import ViewDocumentModal from "features/RulesConfig/common/components/modals/DeleteRuleModal";
import OfferDetailsModal from "./modal/OfferDetailsModal";
import InvoiceLockSuccessModal from "./modal/InvoiceLockSuccessModal";
import { useDispatch, useSelector } from "react-redux";
import { detailsBuyerOpenMarket } from "appstate/openMarket/openMarketSlice";
import { toggleCancelButtonOff } from "./buttonSlice";
import SelectAccountModal from "./modal/SelectAccountModal";
import VerifyLien from "./modal/VerifyLienModal";
import EnterPin from "./modal/Pin";
import WonBidsInvoiceDetails from "features/openMarket/common/components/WonBidsInvoiceDetails";
import { formatCurrency } from "utilities/helper/formatters";
import { formatDate } from "utilities/helper/dateFormatterSpelt";

function AcceptedInvoiceFunderDetails() {
  const { id } = useParams();

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const buttonClicked = useSelector(
    (state) => state?.invoiceButton?.buttonClicked
  );

  const [bidModalOpen, setBidModalOpen] = useState(false);

  const [offerDetailModalOpen, setOfferDetailModalOpen] = useState(false);
  const [bidRequest, setBidRequest] = useState(false);
  const [selectAccountModal, setSelectAccountModal] = useState(false);
  const [confirmSelectedAccountModal, setConfirmSelectedAccountModal] =
    useState(false);
  const [pinModal, setPinModal] = useState(false);
  const [verifyLienStatusModal, setVerifyLienStatusModal] = useState(false);
  const [invoiceLockedSuccessModal, setInvoiceLockedSuccessModal] =
    useState(false);

  function handleGoBack() {
    dispatch(toggleCancelButtonOff());
    navigate(-1);
  }

  useEffect(() => {
    dispatch(detailsBuyerOpenMarket({ openMarketReference: id }));
  }, [dispatch, id]);

  const { detailsBuyerOpenMarketData } = useSelector(
    (state) => state?.openMarket
  );
  const invoiceByIdData = detailsBuyerOpenMarketData;

  // Handle Bid
  // const handleBid = () => {
  //   const body = { amount: bidAmount, discountRate, note: noteToSupplier };
  //   dispatch(placeInvoiceBid({ id, body })).then((data) => {
  //     if (data?.payload?.success) {
  //       toast.success("Your bid has been sent succesfully");
  //       setBidAmount("");
  //       setDiscountRate("");
  //       setNoteToSupplier("");
  //     }
  //   });
  // };

  // Handle Update Detail
  // const handleUpdate = () => {
  //   const body = { amount: bidAmount, discountRate, note: noteToSupplier };
  //   dispatch(updateInvoice({ id: offerId, body })).then((res) => {
  //     if (res?.payload?.success) {
  //       toast.success("Your bid has been updated succesfully");
  //       setBidAmount("");
  //       setDiscountRate("");
  //       setNoteToSupplier("");
  //     }
  //   });
  // };

  // const handleDiscountRateChange = (e) => {
  //   const value = e.target.value.replace("%", "");
  //   if (value === "" || /^[0-9]*\.?[0-9]*$/.test(value)) {
  //     setDiscountRate(value);
  //   }
  // };

  // const handleFocus = (e) => {
  //   const { target } = e;
  //   const len = target.value.length;
  //   target.setSelectionRange(len - 1, len - 1);
  // };

  // const handleCursorPosition = (e) => {
  //   const { target } = e;
  //   const len = target.value.length;
  //   if (target.selectionStart === len) {
  //     target.setSelectionRange(len - 1, len - 1);
  //   }
  // };

  // const handleAction = () => {
  //   setOfferDetailModalOpen(false);
  //   Promise.resolve().then(() => {
  //     setBidRequest(true);
  //   });
  // };

  const handleAction = () => {
    setOfferDetailModalOpen(false);
    setTimeout(() => {
      setBidRequest(true);
    }, 500);
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   if (!discountRate) {
  //     return toast.error("Kindly provide a discount rate to proceed");
  //   }
  //   setOfferDetailModalOpen(true);

  //   // handleAction();
  // };

  const handleProceedToSelectAccount = () => {
    setBidRequest(false);
    setTimeout(() => {
      setSelectAccountModal(true);
    }, 500);
  };

  const handleConfirmAccount = () => {
    setSelectAccountModal(false);
    setTimeout(() => {
      setConfirmSelectedAccountModal(true);
    }, 500);
  };

  const handleProceedEnterPin = () => {
    setConfirmSelectedAccountModal(false);
    setTimeout(() => {
      setPinModal(true);
    }, 500);
  };

  return (
    <DashboardLayout subHeader="Here is what is happening in your finance">
      <TransitionScale className="flex  flex-col gap-6 bg-[#EEE] h-full">
        <header onClick={handleGoBack} className="w-full">
          <div className="flex cursor-pointer w-fit gap-1 items-center">
            <PAIconArrowLeftGreen />
            <span className=" text-primaryColor text-sm">Go back</span>
          </div>
        </header>

        <main className="flex flex-col semi-lg:flex-row pb-8 gap-4 w-full ">
          <WonBidsInvoiceDetails
            invoiceByIdData={invoiceByIdData}
            // setLockModalOpen={setLockModalOpen}
          />

          <motion.section
            initial={{
              y: -20,
            }}
            animate={{
              y: 0,
            }}
            exit={{
              y: 20,
            }}
            transition={{
              delay: 0.8,
            }}
            className="flex flex-col gap-3 px-6  pt-7 pb-24 w-full semi-lg:w-2/5 bg-white rounded-[10px]"
          >
            <header className="flex pb-2">
              <span className="font-bold">Open Market</span>
            </header>

            <section className="flex flex-col gap-6">
              <div className="space-y-4">
                <div className="flex justify-between items-center">
                  <span>
                    {invoiceByIdData?.data?.buyerCompanyName} <br />
                    <span className="text-sm text-gray">Buyer</span>
                  </span>
                  <div className="flex flex-col items-end">
                    <span>{invoiceByIdData?.data?.supplierCompanyName}</span>
                    <span className="text-sm text-gray">Supplier</span>
                  </div>
                </div>

                <div className="flex justify-between items-center">
                  <span>
                    {formatCurrency(invoiceByIdData?.data?.lockRateAmount)}{" "}
                    <br />
                    <span className="text-sm text-gray">Lock Rate Amount</span>
                  </span>
                  <div className="flex flex-col items-end">
                    <span>
                      {formatCurrency(invoiceByIdData?.data?.lockRate)}%
                    </span>
                    <span className="text-sm text-gray">Lock Rate</span>
                  </div>
                </div>

                <div className="flex justify-between items-center">
                  <span>
                    {formatDate(invoiceByIdData?.data?.fundRequestDate)} <br />
                    <span className="text-sm text-gray">Request Date</span>
                  </span>
                  <div className="flex flex-col items-end">
                    <span>
                      {formatDate(invoiceByIdData?.data?.paymentDate)}
                    </span>
                    <span className="text-sm text-gray">Payment Date</span>
                  </div>
                </div>

                <div className="flex justify-between items-center">
                  <span>
                    {formatCurrency(invoiceByIdData?.data?.payableAmount)}{" "}
                    <br />
                    <span className="text-sm text-gray">Request Amount</span>
                  </span>
                  <div className="flex flex-col items-end">
                    <span>
                      {formatCurrency(invoiceByIdData?.data?.fundingAmount)}
                    </span>
                    <span className="text-sm text-gray">Fundable Amount</span>
                  </div>
                </div>
              </div>
            </section>
          </motion.section>
        </main>
      </TransitionScale>
      {/* Bid Modal */}
      <AnimatePresence>
        {bidModalOpen && (
          <ViewDocumentModal
            // onAction={buttonClicked ? handleUpdate : handleBid}
            onClose={() => setBidModalOpen(false)}
          >
            <span className=" text-center">
              Would you like to proceed with{" "}
              {buttonClicked ? "updating you bid on" : "bidding on"} this
              invoice
              <br />
              <span className="font-bold text-center text-primaryColor">
                {invoiceByIdData?.invoiceReference}
              </span>
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>

      {/* Offer Detail Modal */}
      <AnimatePresence>
        {offerDetailModalOpen && (
          <OfferDetailsModal
            id={id}
            onAction={handleAction}
            onClose={() => setOfferDetailModalOpen(false)}
          />
        )}
      </AnimatePresence>

      {/* Lock Modal */}
      <AnimatePresence>
        {bidRequest && (
          <ViewDocumentModal
            onAction={() => handleProceedToSelectAccount()}
            onClose={() => setBidRequest(false)}
            proceed="Yes Proceed"
          >
            <span className=" text-center">
              Are you sure you want to{" "}
              <span className="font-bold text-center text-primaryColor">
                Bid
              </span>{" "}
              on this request with reference number{" "}
              <span className="font-bold text-center text-primaryColor">
                RCYSAJCRW
              </span>{" "}
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>

      {/* Select Account Modal */}
      <AnimatePresence>
        {selectAccountModal && (
          <SelectAccountModal
            onClose={() => setSelectAccountModal(false)}
            onAction={() => {
              handleConfirmAccount();
            }}
          />
        )}
      </AnimatePresence>

      {/* Approve Selected Account */}
      <AnimatePresence>
        {confirmSelectedAccountModal && (
          <ViewDocumentModal
            onAction={() => handleProceedEnterPin()}
            onClose={() => setConfirmSelectedAccountModal(false)}
            proceed="Yes Proceed"
          >
            <span className=" text-center">
              Are you sure you want to proceed with this account{" "}
              <span className="font-bold text-center text-primaryColor">
                736242522661
              </span>{" "}
              to bid on this invoice
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>

      {/* Proceed to Enter pin */}
      <AnimatePresence>
        {pinModal && (
          <EnterPin
            onClose={() => setPinModal(false)}
            onSubmit={() => {
              setPinModal(false);
              setVerifyLienStatusModal(true);
            }}
          />
        )}
      </AnimatePresence>

      {verifyLienStatusModal && (
        <VerifyLien
          onClose={() => setVerifyLienStatusModal(false)}
          onAction={() => {
            setVerifyLienStatusModal(false);
            setTimeout(() => {
              setInvoiceLockedSuccessModal(true);
            }, 500);
          }}
        />
      )}

      <AnimatePresence>
        {invoiceLockedSuccessModal && (
          <InvoiceLockSuccessModal
            // setActiveTab={setActiveTab}
            onClose={() => setInvoiceLockedSuccessModal(false)}
          />
        )}
      </AnimatePresence>

      {/* Offer Detail Modal */}
      {/* <AnimatePresence>
        {offerDetailModalOpen && (
          <OfferDetailsModal
            onAction={handleOfferDetail}
            onClose={() => {
              setBidAmountOffer("");
              setDiscountRateOffer("");
              setNoteToSupplierOffer("");
              setOfferDetailModalOpen(false);
            }}
            bidAmount={bidAmountOffer}
            setBidAmount={setBidAmountOffer}
            discountRate={discountRateOffer}
            setDiscountRate={setDiscountRateOffer}
            noteToSupplier={noteToSupplierOffer}
            setNoteToSupplier={setNoteToSupplierOffer}
          />
        )}
      </AnimatePresence> */}

      {/* Invoice Lock Success Modal */}
      {/* <AnimatePresence>
        {invoiceLockModalOpen && (
          <InvoiceLockSuccessModal
            onClose={() => setInvoiceLockModalOpen(false)}
          />
        )}
      </AnimatePresence> */}

      {/* Cancel Modal */}
      {/* <AnimatePresence>
        {cancelModalOpen && (
          <ViewDocumentModal
            onAction={handleCancel}
            onClose={() => setCancelModalOpen(false)}
          >
            <span className=" text-center">
              Are you sure you want to{" "}
              <span className="font-bold text-center text-primaryColor">
                Cancel
              </span>{" "}
              this invoice
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence> */}
    </DashboardLayout>
  );
}

export default AcceptedInvoiceFunderDetails;
