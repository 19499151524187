import { allPaymentExtension } from "appstate/paymentExtension/paymentExtensionSlice";
import Table from "layout/table/Table";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "shared/Loading";

const RecentRequest = () => {
  const [selectedTab, setSelectedTab] = useState("single");

  const dispatch = useDispatch();
  const { paymentExtensionData, isLoading } = useSelector(
    (state) => state?.paymentExtension
  );

  useEffect(() => {
    dispatch(
      allPaymentExtension({
        page: "",
        pageSize: "",
        search: "",
        bulk: selectedTab === "bulk",
        status: "",
      })
    );
  }, [dispatch, selectedTab]);

  const formatAmount = (amount) => {
    if (!amount || typeof amount !== "string") return "0.00";
    const parsedAmount = parseFloat(amount);
    if (isNaN(parsedAmount)) return "0.00";
    return parsedAmount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const singleRequestData =
    paymentExtensionData?.data?.filter((item) => !item.bulk)?.slice(0, 3) || [];

  const bulkRequestData =
    paymentExtensionData?.data?.filter((item) => item.bulk)?.slice(0, 3) || [];

  // const bulkRequestData = [
  //   {
  //     id: "3",
  //     invoiceNumber: "INV-2022003",
  //     extensionDate: "2024-02-10",
  //     invoiceAmount: "$9,000.00",
  //     status: "UPDATED",
  //   },
  //   {
  //     id: "4",
  //     invoiceNumber: "INV-2022004",
  //     extensionDate: "2024-02-10",
  //     invoiceAmount: "$9,000.00",
  //     status: "PENDING",
  //   },
  // ];

  const columns = [
    {
      name: "Invoice Reference",
      selector: (row) => row.reference,
      sortable: true,
    },
    {
      name: "Extension Date",
      selector: (row) => row.newRequestedDate,
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => row.invoiceAmount,
      sortable: true,
      cell: (row) => (
        <p className="text-success">{formatAmount(row.invoiceAmount)}</p>
      ),
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) =>
        row.status === "APPROVED" ? (
          <p className="text-success">{row.status}</p>
        ) : row.status === "REJECTED" ? (
          <p className="text-red">{row.status}</p>
        ) : row.status === "UPDATED" ? (
          <p className="text-blue-300">{row.status}</p>
        ) : row.status === "Pending" ? (
          <p className="text-yellow-400">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        ),
    },
  ];

  return (
    <div className="mt3 gap-2 px-5">
      <div>
        <div className="flex justify-between items-center">
          <h1 className="text-black font-bold text-lg">
            Recent Extension Requests
          </h1>
          <div className="underline">View all</div>
        </div>
        <div className="mt-3 text-sm">
          <div className="pb-[24px]">
            <div className="flex px[24px] gap-5 my-5">
              <p
                className={`cursor-pointer font-bold relative ${
                  selectedTab === "single" ? "text-[#F08000]" : "text-[#959595]"
                }`}
                onClick={() => setSelectedTab("single")}
              >
                Single Request
                {selectedTab === "single" && (
                  <span className="absolute bottom-0 left-1/2 -translate-x-1/2 bg-[#F08000] h-0.5 w-5"></span>
                )}
              </p>

              <p
                className={`cursor-pointer font-bold relative ${
                  selectedTab === "bulk" ? "text-[#F08000]" : "text-[#959595]"
                }`}
                onClick={() => setSelectedTab("bulk")}
              >
                Bulk Request
                {selectedTab === "bulk" && (
                  <span className="absolute bottom-0 left-1/2 -translate-x-1/2 bg-[#F08000] h-0.5 w-5"></span>
                )}
              </p>
            </div>
            {selectedTab === "bulk" ? (
              <Table
                columns={columns}
                data={bulkRequestData}
                tableHeader
                className="mt-5"
                progressPending={isLoading}
                progressComponent={<Loading />}
              />
            ) : (
              <Table
                columns={columns}
                data={singleRequestData}
                tableHeader
                className="mt-5"
                progressPending={isLoading}
                progressComponent={<Loading />}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecentRequest;
