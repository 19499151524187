import { motion } from "framer-motion";
import { PAIconArrowLeftGreen } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { useNavigate, useParams } from "react-router-dom";
import TransitionScale from "shared/animation/TransitionScale";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  // buyerOpenMarketPending,
  confirmBuyerOpenMarket,
  detailsBuyerOpenMarket,
} from "appstate/openMarket/openMarketSlice";
// import DirectFundingInvoice from "./openMarketInvoice";
import AcceptModal from "shared/AcceptModal";
import PinModal from "shared/PinModal";
import FundAccountOption from "features/wallet(new)/overview/FundAccount/ExternalSource";
import SuccessModal from "shared/SuccessModal";
import DirectFundingInvoice from "../../openMarketInvoice";
import useCountdown from "utilities/helper/useCountdown";

function FacOpenMarketPendingDetail() {
  const { id } = useParams();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [isSelectAccountModalOpen, setIsSelectAccountModalOpen] =
    useState(false);
  const [isProceedModalOpen, setIsProceedModalOpen] = useState(false);
  const [fundAccountPinModal, setFundAccountPinModal] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    dispatch(detailsBuyerOpenMarket({ openMarketReference: id }));
  }, [dispatch, id]);

  const { detailsBuyerOpenMarketData } = useSelector(
    (state) => state?.openMarket
  );
  const selectedAccounts = useSelector(
    (state) => state?.wallet?.selectedAccounts
  );
  const invoiceByIdData = detailsBuyerOpenMarketData;

  function handleGoBack() {
    navigate(-1);
  }

  const formatAmount = (amount) => {
    if (typeof amount !== "number") return "0.00";
    return amount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const formatDate = (dateArray) => {
    if (!Array.isArray(dateArray) || dateArray.length !== 3) return "";
    const date = new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}`;
  };

  // Function to handle account submission
  const handleAccountSubmit = () => {
    setIsSelectAccountModalOpen(false);
    setIsProceedModalOpen(true);
  };

  // Function to handle proceeding with the selected account
  const handleProceedSubmit = () => {
    setIsProceedModalOpen(false);
    setFundAccountPinModal(true);
  };

  const handleApproveInvoice = (pin) => {
    // setFundAccountPinModal(false);
    // setIsSuccessModalOpen(true);
    // setSuccessMessage("Confirmation successful");

    // Get the openMarketIds from the selected rows
    // const openMarketIds = selectedRows
    //   .map((selectedId) => {
    //     const selectedInvoice = buyerOpenMarketPendingData?.data?.find(
    //       (invoice) => invoice.invoiceId === selectedId
    //     );
    //     return selectedInvoice ? selectedInvoice.openMarketId : null;
    //   })
    //   .filter((id) => id !== null);

    const approvedByBuyer = {
      openMarketReference: [id],
      virtualAccountId: selectedAccounts?.id,
      transactionPin: pin,
    };

    dispatch(confirmBuyerOpenMarket({ body: approvedByBuyer })).then((data) => {
      if (data?.payload?.success) {
        setFundAccountPinModal(false);
        setIsSuccessModalOpen(true);
        setSuccessMessage(data?.payload?.message);
        setTimeout(() => {
          handleGoBack();
        }, 2000);
      }
    });
  };

  const paymentDate = invoiceByIdData?.data?.paymentDate;
  const { timeLeft, isTimeUnderOneHour } = useCountdown(paymentDate);

  return (
    <DashboardLayout
      section="Open market"
      subHeader="Monitor your invoices in the open market"
    >
      <TransitionScale className="flex  flex-col gap-6 bg-[#EEE] h-full">
        <header onClick={handleGoBack} className="w-full">
          <div className="flex cursor-pointer w-fit gap-1 items-center">
            <PAIconArrowLeftGreen />
            <span className=" text-primaryColor text-sm">Go back</span>
          </div>
        </header>

        <main className="flex flex-col semi-lg:flex-row pb-8 gap-4 w-full ">
          <DirectFundingInvoice
            invoiceByIdData={invoiceByIdData}
            // setLockModalOpen={setLockModalOpen}
          />

          <motion.section
            initial={{
              y: -20,
            }}
            animate={{
              y: 0,
            }}
            exit={{
              y: 20,
            }}
            transition={{
              delay: 0.8,
            }}
            className="flex flex-col gap-3  pt10 pb-24 w-2/5 bg-white rounded-[10px]"
          >
            <div className="bg-white w-full rounded-[10px] pt-5 pb-24 h-[100%]">
              <div className="h-[15%]">
                <h2 className="text-lg px-6 font-semibold text-gray-800 pb-4 border-b-[1px] border-b-slate-300 text-primaryColor">
                  Bid details for this invoice
                </h2>

                <div className="px-6 py-6 pb-4 flex justify-between items-center">
                  <div>
                    <p className="text-gray600 text-xs">Request Amount</p>
                    <span className="text-sm">
                      {formatAmount(invoiceByIdData?.data?.fundingAmount)}
                    </span>
                  </div>
                  <div>
                    <p className="text-gray600 text-xs">Request Date</p>
                    <span className="flex justify-end text-sm">
                      {formatDate(invoiceByIdData?.data?.fundRequestDate)}
                    </span>
                  </div>
                </div>

                <div className="px-6 py-6 pb-4 flex flex-col">
                  <div>
                    <p className="text-gray600 text-xs">
                      Countdown to due Date
                    </p>
                    <span
                      className={`text-sm font-bold ${
                        isTimeUnderOneHour ? "text-red-500" : ""
                      }`}
                    >
                      {timeLeft.days > 0 ||
                      timeLeft.hours > 0 ||
                      timeLeft.minutes > 0 ||
                      timeLeft.seconds > 0 ? (
                        `${timeLeft.days}d:${timeLeft.hours}h:${timeLeft.minutes}m:${timeLeft.seconds}s`
                      ) : (
                        <span className="text-red-500">Expired</span>
                      )}
                    </span>
                  </div>
                </div>

                <div
                  onClick={() => setIsSelectAccountModalOpen(true)}
                  className="px-6 py-6 w-full items-center justify-center"
                >
                  <button className="bg-primaryColor text-white w-full py-3 rounded">
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </motion.section>
        </main>
        <FundAccountOption
          isOpen={isSelectAccountModalOpen}
          onClose={() => setIsSelectAccountModalOpen(false)}
          HeaderText={
            <p>
              Select which account to fund with <br />
            </p>
          }
          onSubmit={handleAccountSubmit}
        />

        <AcceptModal
          isOpen={isProceedModalOpen}
          onClose={() => setIsProceedModalOpen(false)}
          message={
            <>
              Would you like to proceed with this account{" "}
              <span className="text-[#07593D] font-bold">
                {selectedAccounts?.accountNumber}
              </span>{" "}
              for <br /> reimbursement?
            </>
          }
          onConfirm={handleProceedSubmit}
          loading={false}
        />
        <PinModal
          isOpen={fundAccountPinModal}
          onClose={() => {
            setFundAccountPinModal(false);
          }}
          onSubmit={handleApproveInvoice}
          // isLoading={isLoading}
          headerText="Enter Pin"
          instructionText="Enter your transaction PIN to verify your identity."
        />
        <SuccessModal
          isOpen={isSuccessModalOpen}
          onClose={() => setIsSuccessModalOpen(false)}
          message={successMessage}
        />
      </TransitionScale>
    </DashboardLayout>
  );
}

export default FacOpenMarketPendingDetail;
