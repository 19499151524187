export const ruleTypes = {
  ASSOCIATION: {
    actions: [
      "GRANT_MEMBERSHIP_BENEFITS",
      "REJECT_ASSOCIATION",
      "ACCEPT_ASSOCIATION",
    ],
    conditions: [
      "MEMBERSHIP_TYPE",
      "DURATION_OF_MEMBERSHIP",
      "NUMBER_OF_ASSOCIATES",
      "TRANSACTION_VOLUME",
      "TIER_LEVEL",
      "NUMBER_OF_TRANSACTIONS",
      "NUMBER_OF_INVOICES",
      "NUMBER_OF_PAYMENTS",
      "RISK_SCORE",
      "NUMBER_OF_REJECTED_INVOICES",
      "NUMBER_OF_REJECTED_PAYMENTS",
    ],
  },
  DISBURSEMENT: {
    actions: ["EXTEND_PAYMENT_TERM", "QUANTITY_DISCOUNT", "EXPEDITE_SHIPPING"],
    conditions: [
      "INVOICE_AMOUNT",
      "PAYMENT_TERM",
      "SUPPLIER_RATING",
      "PRODUCT_CATEGORY",
      "DELIVERY_DATE",
    ],
  },
  EARLY_PAYMENT: {
    actions: [
      "DISCOUNT_PERCENTAGE",
      "DISCOUNT_EXPIRY_DATE",
      "MINIMUM_INVOICE_AMOUNT",
      "MAXIMUM_INVOICE_AMOUNT",
      "DYNAMIC_DISCOUNTING",
      "TIERED_DISCOUNTING",
      "EXTEND_PAYMENT_TERM",
      "EARLY_PAYMENT_BONUS",
      "QUANTITY_DISCOUNT",
      "EXPEDITE_SHIPPING",
      "SUPPLIER_FEEDBACK",
      "ADVANCE_PAYMENT",
      "PRODUCT_BUNDLING",
      "LOYALTY_POINTS",
    ],
    conditions: [
      "INVOICE_AMOUNT",
      "EARLY_PAYMENT_DUE_DATE",
      "EARLY_PAYMENT_AMOUNT",
    ],
  },
  INVOICE_APPROVAL: {
    actions: [
      "APPROVE",
      "REJECT",
      "ESCALATE",
      "NOTIFY",
      "AUTOMATIC_APPROVAL",
      "PAYMENT_TERM_NEGOTIATION",
      "VENDOR_PERFORMANCE_REVIEW",
      "AUDIT_TRAIL_LOGGING",
    ],
    conditions: [
      "INVOICE_AMOUNT",
      "SUPPLIER_RATING",
      "PAYMENT_TERM",
      "INVOICE_DUE_DATE",
      "PRODUCT_CATEGORY",
      "PURCHASE_ORDER_MATCHING",
      "EXPENSE_CATEGORY",
    ],
  },
  NOTIFICATION: {
    actions: [
      "EMAIL_NOTIFICATION",
      "IN_APP_NOTIFICATION",
      "SMS_NOTIFICATION",
      "AUTOMATED_RESPONSES",
      "LOG_NOTIFICATION_EVENTS",
    ],
    conditions: [
      "INVOICE_APPROVAL",
      "ASSOCIATION_EVENTS",
      "INVOICE_CREATION",
      "EVENT_TRIGGER",
      "EARLY_PAYMENT_REQUEST",
      "PAYMENT_EXTENDED",
      "DAYS_TO_DUE_DATE",
      "CUSTOMER_INTERACTION",
      "INVENTORY_LEVEL",
      "USER_ROLE_OR_DEPARTMENT",
    ],
  },
  OPEN_MARKET: {
    actions: [
      "PRICE_ADJUSTMENTS",
      "PROMOTIONS_AND_DISCOUNTS",
      "INVENTORY_MANAGEMENT",
      "MARKETING_CAMPAIGNS",
      "COMPETITOR_ANALYSIS",
      "DYNAMIC_PRODUCT_BUNDLING",
      "ALERTS_AND_NOTIFICATIONS",
      "SUPPLIER_COLLABORATION",
      "STRATEGIC_PARTNERSHIPS",
    ],
    conditions: [
      "MARKETPLACE_ACTIVITY",
      "COMPETITOR_PRICING",
      "PRODUCT_AVAILABILITY",
      "CUSTOMER_REVIEWS_AND_RATINGS",
      "TRENDING_PRODUCTS",
      "SEASONAL_TRENDS",
      "SUPPLY_CHAIN_DISRUPTIONS",
    ],
  },

  PAYABLE_FINANCING: {
    actions: [
      "DISCOUNT_PERCENTAGE",
      "EARLY_PAYMENT_BONUS",
      "EXTEND_PAYMENT_TERM",
      "EXTEND_DUE_DATE",
      "SUPPLIER_FINANCING_PROGRAMS",
      "INVOICE_FACTORING",
      "SUPPLY_CHAIN_FINANCING",
      "NEGOTIATE_EXTENDED_PAYMENT_TERMS",
      "DYNAMIC_DISCOUNTING",
      "STRATEGIC_SOURCING",
      "DIVERSIFY_FINANCING_PARTNERS",
      "AUTOMATED_INVOICE_PROCESSING",
      "SUPPLIER_COLLABORATION_PLATFORMS",
      "PAYMENT_TRACKING_AND_VISIBILITY",
      "RISK_MITIGATION_STRATEGIES",
      "FINANCING_EDUCATION_PROGRAMS",
      "SUPPLIER_RELATIONSHIP_MANAGEMENT",
      "INVOICE_ANALYTICS",
    ],
    conditions: [
      "INVOICE_AMOUNT",
      "PAYMENT_TERM",
      "INVOICE_DUE_DATE",
      "INVOICE_AGEING",
      "EARLY_PAYMENT_DISCOUNT_AVAILABILITY",
      "CASH_FLOW_FORECAST",
      "SUPPLIER_CREDITWORTHINESS",
      "DYNAMIC_DISCOUNTING_THRESHOLD",
      "MARKET_INTEREST_RATES",
      "SEASONAL_PAYMENT_PATTERNS",
      "INVOICE_DISPUTES",
      "SUPPLY_CHAIN_DISRUPTIONS",
    ],
  },

  PAYMENT_EXTENSION: {
    actions: [
      "DISCOUNT_PERCENTAGE",
      "EARLY_PAYMENT_BONUS",
      "EXTEND_PAYMENT_TERM",
      "EXTEND_DUE_DATE",
    ],
    conditions: ["INVOICE_AMOUNT", "PAYMENT_TERM", "INVOICE_DUE_DATE"],
  },

  RELATIONSHIP_MANAGEMENT: {
    actions: [
      "DISCOUNTS_OR_REBATES",
      "EXCLUSIVE_OFFERS",
      "DEDICATED_ACCOUNT_MANAGER",
      "CUSTOMIZED_PRODUCT_SERVICE_BUNDLES",
      "PRIORITY_SUPPORT",
      "JOINT_MARKETING_INITIATIVES",
      "EXTENDED_PAYMENT_TERMS",
      "EARLY_ACCESS_TO_NEW_PRODUCTS_FEATURES",
      "TRAINING_AND_EDUCATION",
      "EXCLUSIVE_EVENTS_OR_NETWORKING_OPPORTUNITIES",
      "LOYALTY_PROGRAMS",
      "PARTNERSHIP_RECOGNITION",
    ],
    conditions: [
      "DISCOUNTS_OR_REBATES",
      "EXCLUSIVE_OFFERS",
      "DEDICATED_ACCOUNT_MANAGER",
      "CUSTOMIZED_PRODUCT_SERVICE_BUNDLES",
      "PRIORITY_SUPPORT",
      "JOINT_MARKETING_INITIATIVES",
      "EXTENDED_PAYMENT_TERMS",
      "EARLY_ACCESS_TO_NEW_PRODUCTS_FEATURES",
      "TRAINING_AND_EDUCATION",
      "EXCLUSIVE_EVENTS_OR_NETWORKING_OPPORTUNITIES",
      "LOYALTY_PROGRAMS",
      "PARTNERSHIP_RECOGNITION",
    ],
  },

  WORKFLOW: {
    actions: [
      "SEND_AUTOMATED_NOTIFICATIONS",
      "TASK_ASSIGNMENTS",
      "DATA_UPDATES",
      "DOCUMENT_ROUTING",
      "ESCALATION_PROCEDURES",
      "INTEGRATION_WITH_THIRD_PARTY_TOOLS",
      "STATUS_UPDATES",
      "EMPLOYEE_NOTIFICATIONS",
      "REPORT_GENERATION",
      "RECORD_ARCHIVING",
      "APPROVALS_AND_REJECTIONS",
      "INVOICE_PROCESSING",
      "CUSTOMER_COMMUNICATION",
      "AUDIT_TRAIL_LOGGING",
    ],
    conditions: [
      "DOCUMENT_APPROVAL_STATUS",
      "TASK_DUE_DATES",
      "FORM_SUBMISSION",
      "BUDGET_THRESHOLDS",
      "EMPLOYEE_ONBOARDING",
      "CUSTOMER_SUPPORT_TICKET_ESCALATION",
      "INVENTORY_REORDER_LEVELS",
      "EMPLOYEE_PERFORMANCE_REVIEWS",
      "CONTRACT_EXPIRY",
      "QUALITY_CONTROL_CHECKS",
      "CUSTOMER_ORDER_STATUS",
      "PROJECT_MILESTONES",
      "VENDOR_COMPLIANCE",
      "REGULATORY_COMPLIANCE",
      "IT_SECURITY_ALERTS",
    ],
  },
};
