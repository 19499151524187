import React, { useState } from "react";
import Header from "../Header";
import post4 from "../../../../assets/images/svgs/post4.png";
import post1 from "../../../../assets/images/svgs/post1.png";
import post2 from "../../../../assets/images/svgs/post2.png";

import "./PostDetails";
import PostDetails from "./PostDetails";

const posts = [
  {
    id: 1,
    image: post1,
    title: "Streamlining Supplier Onboarding",
    text: "Your Roadmap to Effortless Supplier Onboarding – Uncover Strategies, Tips, and Best Practices for Seamlessly Integrating ...",
    timeAgo: 15,
  },
  {
    id: 2,
    image: post2,
    title: "Navigating the Financial Supply Chain",
    text: "Discover how efficient supply chain finance strategies can empower your business and boost profitability",
    timeAgo: 30,
  },
  {
    id: 3,
    image: post1,
    title: "The Role of Blockchain in Supply Chain Finance",
    text: "Dive into the innovative use of blockchain technology and its impact on supply chain financing",
    timeAgo: 15,
  },
  {
    id: 4,
    image: post4,
    title: "Unraveling the Cash Flow Mystery",
    text: "Learn the essential elements of cash flow management and how it impacts your supply chain",
    timeAgo: 30,
  },
  {
    id: 5,
    image: post1,
    title: "The Role of Blockchain in Supply Chain Finance",
    text: "Dive into the innovative use of blockchain technology and its impact on supply chain financing",
    timeAgo: 15,
  },
  {
    id: 6,
    image: post2,
    title: "Navigating the Financial Supply Chain",
    text: "Discover how efficient supply chain finance strategies can empower your business and boost profitability",
    timeAgo: 30,
  },
];

const Resources = () => {
  const [selectedCard, setSelectedCard] = useState(null);

  function handleBack() {
    setSelectedCard(null);
  }

  const handleCardClick = (id) => {
    setSelectedCard(id);
  };

  const cards = (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
      {posts.map((post) => (
        <div
          key={post.id}
          className={`bg-white p-6 rounded-md shadow-md cursor-pointer ${
            selectedCard === post.id ? "border-4 border-blue-500" : ""
          }`}
          onClick={() => handleCardClick(post.id)}
        >
          <div className="w-full object-cover rounded-md mb-4">
          <img src={post.image} alt="" />
          </div>
          <h2 className="text-xl font-bold mb-2 overflow-hidden overflow-ellipsis line-clamp-2">
            {post.title}
          </h2>
          <p className="text-gray-600 mb-4 overflow-hidden overflow-ellipsis line-clamp-4">
            {post.text}
          </p>
          <span className="text-[#F08000]">{`${post.timeAgo} mins ago`}</span>
        </div>
      ))}
    </div>
  );

  // Render different content based on the selected card
  const content = selectedCard ? <PostDetails goBack={handleBack} /> : cards;
  const header = !selectedCard && (
    <Header Title="Resources" showSearchInput={true} />
  );

  return (
    <>
      {header}
      {content}
    </>
  );
};

export default Resources;
