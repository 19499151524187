import React, { useEffect, useState, Suspense } from "react";
import Overview from "./Overview";
import DashboardTable from "./Table";
import DashboardLayout from "layout/dashboardlayout";
import BuyerOverview from "../../../shared/overviewHeader";
import { useSelector } from "react-redux";
import { supplierCreateTransPin } from "appstate/supplier/supplierSlice";
import { useDispatch } from "react-redux";
// import { setNewToDashBoard } from "./supplierVerifcationSlice/verificationSlice";
import { setNewToDashBoard } from "features/onboarding/supplier/supplierVerifcationSlice/verificationSlice";
import { useKeycloak } from "@react-keycloak/web";
//getSupplierDashboard
import { getSupplierDashboard } from "appstate/supplier/supplierSlice";

const CreateTransPinModal = React.lazy(() =>
  import("shared/createTransPinModal/CreateTransPinModal")
);
const ConfirmTransPinModal = React.lazy(() =>
  import("shared/createTransPinModal/ConfirmTransPinModal")
);
const CreateTransPinSucessModal = React.lazy(() =>
  import("shared/createTransPinModal/CreatTranPinSucessModal")
);
const VirtualAccountSucessModal = React.lazy(() =>
  import("shared/createTransPinModal/VirtualAccountSucessModal")
);

const SupplierDashboard = () => {
  // const { allVirtualCardsData } = useSelector((state) => state?.wallet);
  const [pinOpen, setPinOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [createdsucess, setCreatedsucess] = useState(false);
  const [virtualAccountCreated, setVirtualAccountCreated] = useState(false);
  const dispatch = useDispatch();
  const { newToDashBoard } = useSelector(
    (state) => state?.verificationSupplier
  );
  const { isLoading, supplierDashboard } = useSelector(
    (state) => state.supplier
  );

  const [pinstate, setPinState] = useState("");
  // const [confirmpinstate, setConfirmPinState] = useState("");

  // const formatCardNumber = (cardNumber) => {
  //   if (!cardNumber) {
  //     return "";
  //   }

  //   const sanitizedCardNumber = cardNumber?.replace(/\D/g, "");
  //   const maskedCardNumber = sanitizedCardNumber.replace(
  //     /(\d{4})(\d{4})(\d{4})(\d{4})/,
  //     (_, firstPart, middlePart1, middlePart2, lastPart) => {
  //       const maskedMiddlePart = `${middlePart1.replace(
  //         /\d/g,
  //         "*"
  //       )} ${middlePart2.replace(/\d/g, "*")}`;
  //       return `${firstPart} ${maskedMiddlePart} ${lastPart}`;
  //     }
  //   );

  //   return maskedCardNumber.trim();
  // };

  // const formatExpiryDate = (expiryDate) => {
  //   if (!expiryDate) {
  //     return "";
  //   }

  //   const options = { month: "2-digit", year: "2-digit" };
  //   return new Date(expiryDate).toLocaleDateString(undefined, options);
  // };

  // const getFirstCardData = () => {
  //   const cardData = Array.isArray(allVirtualCardsData?.data)
  //     ? allVirtualCardsData?.data?.map((card) => ({
  //         id: card?.id,
  //         cardName: card?.cardName,
  //         cardNumber: formatCardNumber(card?.cardNumber),
  //         cardCurrency: card?.cardCurrency,
  //         paymentMethod: card?.paymentMethod,
  //         balance: card?.balance,
  //         expiryDate: formatExpiryDate(card?.expiryDate),
  //       }))
  //     : [];
  //   return cardData?.length > 0 ? cardData[0] : null;
  // };

  // const firstCardData = getFirstCardData();
  const handleNextPin = () => {
    setPinOpen(false);
    setConfirm(true);
  };
  const handleConfirm = () => {
    setConfirm(false);
    setCreatedsucess(true);
  };
  const handlepinSucess = () => {
    setCreatedsucess(false);
    setVirtualAccountCreated(true);
  };

  useEffect(() => {
    if (newToDashBoard) {
      setPinOpen(true);
    }
    // Clean up sessionStorage if necessary

    sessionStorage.removeItem("activeTab");
    sessionStorage.removeItem("searchQuery");
    sessionStorage.removeItem("activeTabSupplier");
    sessionStorage.removeItem("searchQuerySupplier");
  }, [newToDashBoard]);
  useEffect(() => {
    dispatch(getSupplierDashboard());
  }, [dispatch]);

  const { keycloak } = useKeycloak();
  const { given_name } = keycloak?.idTokenParsed;

  return (
    <DashboardLayout subHeader="Here is what is happening in your finance">
      <Suspense fallback={"loading ..."}>
        <CreateTransPinModal
          disabled={true}
          isOpen={pinOpen}
          onClose={() => setPinOpen(false)}
          onSubmit={(pin) => {
            handleNextPin();
            setPinState(pin);
          }}
        />
      </Suspense>
      <Suspense fallback={"loading ..."}>
        <ConfirmTransPinModal
          isOpen={confirm}
          onClose={() => setConfirm(false)}
          isLoading={isLoading}
          onSubmit={(pin) => {
            const body = {
              transactionPin: pinstate,
              confirmTransactionPin: pin,
            };

            dispatch(supplierCreateTransPin(body)).then((data) => {
              if (
                data?.payload?.message === "Transaction PIN already exists."
              ) {
                dispatch(setNewToDashBoard(false));
              }
              if (data?.payload?.status === 200) {
                dispatch(setNewToDashBoard(false));

                handleConfirm();
              }
            });
          }}
        />
      </Suspense>
      <Suspense fallback={"loading ..."}>
        <CreateTransPinSucessModal
          isOpen={createdsucess}
          onClose={handlepinSucess}
          message={"Your transaction PIN has been"}
          message2={" successfully created."}
        />
      </Suspense>
      <Suspense fallback={"loading ..."}>
        <VirtualAccountSucessModal
          isOpen={virtualAccountCreated}
          onClose={() => setVirtualAccountCreated(false)}
          userName={given_name ? given_name : ""}
          collectionType={"collection"}
        />
      </Suspense>

      <BuyerOverview />
      <Overview
        supplierDashboard={supplierDashboard}
        cardData={supplierDashboard?.data?.walletResponseDTO}
      />
      <DashboardTable supplierDashboard={supplierDashboard} />
    </DashboardLayout>
  );
};

export default SupplierDashboard;
