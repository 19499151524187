import DashboardLayout from "layout/dashboardlayout";
// import React from 'react'
import {
  PAIconArrowLeftGreen,
  // PAIconCocacola,
  PAIconDownload,
  // PAIconModelOne,
} from "assets/images/svgs";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import RejectModal from "./rejectModal";
import { allInvoiceById } from "appstate/invoice/invoiceSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import LoadingScreen from "shared/LoadingScreen";

const SingleRejected = () => {
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRejectModalOpen, setRejectModalOpen] = useState(false);
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  // const { getInvoiceId, allEarlyPAymentsData } = useSelector(
  //   (state) => state?.buyer
  // );

  // const invoiceData = allEarlyPAymentsData?.data?.find(
  //   (cur) => cur?.id === getInvoiceId
  // );

  const { id } = useParams();
  const dispatch = useDispatch();
  const { invoiceIdData, isLoading } = useSelector((state) => state?.invoice);

  useEffect(() => {
    dispatch(allInvoiceById({ id }));
  }, [dispatch, id]);

  // Calculate the payment based on the due date and created date
  const calculatePayment = (dueDate, createdDate) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const dueDateObj = new Date(dueDate);
    const createdDateObj = new Date(createdDate?.split(" ")[0]);
    const diffDays = Math.round(
      Math.abs((dueDateObj - createdDateObj) / oneDay)
    );
    return `${diffDays} Days`;
  };

  // FORMAT AMOUNT
  const formatAmount = (amount) => {
    if (typeof amount !== "number") return "0.00";
    return amount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const handleCloseModal = () => {
    // setIsModalOpen(false);
    setRejectModalOpen(false);
  };

  const handleReject = () => {
    setRejectModalOpen(true);
  };
  return (
    <DashboardLayout
      section="Payment Extension"
      subHeader="Send and track payment extension processes"
    >
      {/* <div>Rejected</div> */}
      {isLoading && <LoadingScreen />}
      <>
        <Link
          onClick={goBack}
          className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer w-full"
        >
          <PAIconArrowLeftGreen />
          <span className="flex items-center text-primaryColor">Go Back</span>
        </Link>

        <div className="grid lg:grid-cols-[60%,40%] gap-5">
          {/* 60% */}
          <div className="flex flex-col gap-8 pb-24 bg-white rounded-[10px] pt-7">
            <div className="px-6 ">
              <div className="flex flex-col gap-3">
                <h1 className="text-[18px] font-bold">
                  Invoice | INV-{invoiceIdData?.data?.invoiceNumber || "0000"}
                </h1>

                <main className=" w-full flex justify-between">
                  <div className="flex flex-col gap-3 w-1/2">
                    <div className="flex gap-x-[5px]">
                      <span>Reference No:</span>
                      <span>
                        {invoiceIdData?.data?.invoiceReference || "REF-0000"}
                      </span>
                    </div>
                    <div className="flex flex-col gap-1">
                      <div className="flex gap-x-[50px]">
                        <span className=" text-xs w-[97px]">Created Date:</span>
                        <span className="text-[#979797]  text-xs">
                          {invoiceIdData?.data?.createdDate?.slice(0, 10) ||
                            "0000"}
                        </span>
                      </div>
                      <div className="flex gap-x-[50px]">
                        <span className=" text-xs">Payment Due Date:</span>
                        <span className="text-[#979797]  text-xs">
                          {invoiceIdData?.data?.dueDate || "0000"}
                        </span>
                      </div>
                    </div>
                  </div>
                </main>
              </div>

              <div className="flex justify-between my-5">
                <div className="flex flex-col">
                  <span className="font-bold text-sm">Payment</span>
                  <span className="text-[#979797]  text-xs">
                    {calculatePayment(
                      invoiceIdData?.data?.dueDate,
                      invoiceIdData?.data?.createdDate
                    )}{" "}
                    Days
                  </span>
                </div>
                <div className="flex flex-col">
                  <span className=" font-bold text-sm">Currency</span>
                  <span className="text-[#979797]  text-xs">
                    {invoiceIdData?.data?.currency || ""}
                  </span>
                </div>
              </div>

              <div className="flex flex-col gap-4">
                <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
                  <h1 className="text-[18px] font-bold">
                    Supplier/buyer details
                  </h1>
                </header>
                <div className="flex  justify-between mb-5">
                  <div className="flex gap-2">
                    {/* <PAIconCocacola /> */}
                    <div className="flex flex-col">
                      <span className=" font-bold text-sm">Supplier</span>
                      <span className="text-[#979797]  text-xs">
                        {invoiceIdData?.data?.supplierCompanyName ||
                          "supplier name here"}
                      </span>
                    </div>
                  </div>
                  <div className="flex gap-2">
                    {/* <PAIconModelOne /> */}
                    <div className="flex flex-col">
                      <span className=" font-bold text-sm">Buyer</span>
                      <span className="text-[#979797]  text-xs">
                        {invoiceIdData?.data?.buyerCompanyName ||
                          "buyer name here"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-4">
                <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
                  <h1 className="text-sm font-bold">Line Items</h1>
                </header>
                <div className=" w-full overflow-x-auto">
                  <table className=" w-full overflow-x-auto">
                    <thead className="bg-subtleGray w-">
                      <tr className=" bg-unusualGray p-2.5 ">
                        <td className="text-[#11192A] p-2.5 text-xs font-bold">
                          Item Number
                        </td>
                        <td className=" p-2.5 text-xs font-bold">PO Number</td>
                        <td className=" p-2.5 text-xs font-bold">
                          Product/Service Number
                        </td>
                        <td className=" p-2.5 text-xs font-bold">
                          Product/Service Description
                        </td>
                        <td className=" p-2.5 text-xs font-bold">Quantity</td>
                        <td className=" p-2.5 text-xs font-bold">Unit Price</td>
                        <td className=" p-2.5 text-xs font-bold">
                          Line Item Extended Amount
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {invoiceIdData?.data?.items?.map((item, index) => (
                        <tr key={index} className="py-[3px] px-1.5">
                          <td className="text-xs text-[#11192A] p-2.5">
                            {item.itemNumber}
                          </td>
                          <td className="text-xs text-[#11192A] p-2.5">
                            {item.poNumber}
                          </td>
                          <td className="text-xs text-[#11192A] p-2.5">
                            {item.productNumber}
                          </td>
                          <td className="text-xs text-[#11192A] p-2.5">
                            {item.productDescription}
                          </td>
                          <td className="text-xs text-[#11192A] p-2.5">
                            {item.quantity}
                          </td>
                          <td className="text-xs text-[#11192A] p-2.5">
                            {formatAmount(item.unitPrice)}
                          </td>
                          <td className="text-xs text-[#11192A] p-2.5">
                            {formatAmount(item.amount)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="flex flex-col gap-4 mt-5">
                <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
                  <h1 className="text-sm font-bold">Attachments</h1>
                </header>
                <div className="w-full overflow-x-auto">
                  <table className="w-full overflow-x-auto">
                    <thead className="  bg-subtleGray w-">
                      <tr className="px-4  bg-unusualGray p-2.5 ">
                        <td className="text-[#11192A] px-4 py-2.5 text-xs font-bold">
                          Document Name
                        </td>
                        <td className=" px-4 py-2.5 text-xs font-bold">
                          File Type
                        </td>
                        <td className=" px-4 py-2.5 text-xs font-bold">
                          Upload Date / Time
                        </td>
                        <td className=" px-4 py-2.5 text-xs font-bold">
                          Action
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className=" py-[3px] px-1.5">
                        <td className=" text-xs text-[#0076EC] px-4 py-2.5">
                          DOC-29384.pdf
                        </td>
                        <td className=" text-xs text-[#11192A] px-4 py-2.5">
                          PDF
                        </td>
                        <td className=" text-xs text-[#11192A] px-4 py-2.5">
                          2021-09-14 13:25
                        </td>
                        <td className="cursor-pointer text-xs text-[#11192A] px-4 py-2.5">
                          <PAIconDownload />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {/* 40% */}
          <div className="bg-white rounded-[10px] gap-8 px-6 pt-7 pb-24 border-b-[#F6F5FA] border-b-[5px]">
            <div>
              <h2 className="font-semibold text-lg leading-[30px] mb-4 text-red border-b-[5px] border-b-[#F6F5FA] pb-5">
                Payment Extension Rejected
              </h2>

              <div className="border-b-[5px] border-b-[#F6F5FA] pb-5">
                <h2 className="font-medium text-base my-3">
                  Funders reason for rejecting request
                </h2>
                <p className="text-[#959595] text-sm">
                  Insufficient financial viability and market potentil for
                  investment at present
                </p>
              </div>

              <div className="pb-3">
                <h2 className="font-medium text-base leading-[30px] my-2">
                  Action Buttons
                </h2>
                <p className="text-[#959595] text-sm">
                  You can consider revisiting your request for the invoice to
                  align with the funde's interest or alternatively, withdraw the
                  current submission
                </p>
              </div>

              <div className="flex gap-5 mt-5">
                <button className="rounded-md bg-[#07593D] text-white px-8 py-2">
                  Edit Request
                </button>
                <button
                  className="rounded-md bg-white border border-red text-red px-8 py-2"
                  onClick={handleReject}
                >
                  Close Request
                </button>
              </div>
              <RejectModal
                isOpen={isRejectModalOpen}
                onClose={handleCloseModal}
              />
            </div>
          </div>
        </div>
      </>
    </DashboardLayout>
  );
};

export default SingleRejected;
