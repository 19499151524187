import React, { useEffect, useState } from "react";

import { PAIconMakiCaution } from "assets/images/svgs";
import { updateBulkEffectiveDisocunt } from "appstate/funder/funderSlice";
import { useDispatch } from "react-redux";

const ConfirmDirectFundingModalBulk2 = ({
  isOpen,
  onClose,
  handleNext,
  disValue,
  handleChange,

  handleCancel,
  handleSave,
  editable,
  setEditable,
  onBlur,
  data,
  funderRate,
  setRateEditable
}) => {
  const [inputError, setInputError] = useState(false);
  const [inputValue, setInputValue] = useState(disValue);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen) {
      setInputValue(inputValue || disValue);
    }
  }, [isOpen, inputValue, disValue]);

  useEffect(() => {
    // Check if the entered value is greater than disValue (the max allowed rate)
    if (parseFloat(inputValue) > parseFloat(disValue)) {
      setInputError(true);
    } else {
      setInputError(false);
    }
  }, [inputValue, disValue]);

  useEffect(() => {
    const invoiceIds =
      data?.earlyPaymentInvoiceResponse?.map((invoice) => invoice.invoicesId) ||
      [];
    // Make sure `tempDiscountRate` has a valid value before proceeding.
    if (inputValue > 0) {
      // Prepare the body for the API request.
      const body = {
        earlyPaymentRequestId: data?.earlyPaymentId,
        // invoiceId: data?.invoiceId,
        invoiceId: invoiceIds,
        // newDiscountRate: disValue, // The new discount rate entered by the user
        newDiscountRate: inputValue, // The new discount rate entered by the user
      };
      setRateEditable(true)

      // console.log("id: ", invoiceIds);

      // Dispatch the API call to get the previewed adjusted discount values.
      dispatch(updateBulkEffectiveDisocunt({ body }));
    }
  }, [
    // disValue,
    inputValue,
    data?.earlyPaymentId,
    data?.earlyPaymentInvoiceResponse,
    data?.invoiceId,
    dispatch,
    setRateEditable
  ]);

  if (!isOpen) return null;

  return (
    <>
      <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg rounded-[10px] z-[1000]">
        <div className="bg-white p10 px5 rounded-md max-w-xs  lg:min-w-[598px]  md:lg:min-w-[598px] pb-5 px10 min-h-[340px]">
          <div className="flex justify-between items-center py-5 px-6 rounded-t-[10px]  bg-[#FAFAFA]">
            <div class="text-[#222222] text-sm font-bold italic">
              Edit the discount rate{" "}
            </div>
          </div>
          {/* Rest box */}
          <div className="px-6">
            <div className=" w-full flex justify-between py-4 border-b-[1px] border-b-[#d7d7d7] flex-wrap">
              {/* first */}
              <div className=" flex-col gap-1">
                <div class=" text-[#555555] text-sm font-normal  mb-1">
                  Discount Rate{" "}
                </div>
                <input
                  type="text"
                  placeholder=""
                  className={`${
                    editable
                      ? "w-[88px] h-[33px]  rounded border border-[#f08000]/40 justify-center items-center inline-flex text-center text-[#222222] text-base font-medium  focus:outline-none"
                      : "justify-center items-center inline-flex text-center text-[#222222] text-base font-medium  focus:outline-none w-[88px] h-[30px] bg-none"
                  }`}
                  onBlur={onBlur}
                  value={inputValue} // Use the input value state
                  onChange={(e) => setInputValue(e.target.value)} // Update inputValue state
                  disabled={!editable}
                />
                {/* Error message if value exceeds disValue */}
                {inputError && (
                  <p className="text-red text-xs mt-1">
                    The value cannot exceed {disValue}
                  </p>
                )}
              </div>

              {/* side */}
              <div className=" items-center justify-between flex-col">
                <div class="text-center text-[#555555] text-sm font-normal ">
                  Buyer Rate
                </div>
                <div class="text-center text-[#222222] text-2xl font-semibold ">
                  {data?.discountRate}%
                </div>
              </div>
              <div className=" items-center justify-between flex-col">
                <div class="text-center text-[#555555] text-sm font-normal ">
                  Funder Default Rate
                </div>
                <div class="text-center text-[#222222] text-2xl font-semibold ">
                  {funderRate}%
                </div>
              </div>
              {/* <div className=" items-center justify-between flex-col">
                <div class="text-[#222222] text-xl font-semibold ">
                  {data?.requestReferenceNumber}
                </div>
                <div class="text-[#555555] text-sm font-normal ">
                  Ref Number
                </div>
              </div> */}
              <div className="flex flex-col items-center justify-between">
                <div
                  className="text-[#222222] text-xl font-semibold max-w-[150px] truncate cursor-pointer"
                  title={data?.requestReferenceNumber}
                >
                  {data?.requestReferenceNumber}
                </div>
                <div className="text-[#555555] text-sm font-normal">
                  Ref Number
                </div>
              </div>
            </div>
            {/* next */}

            {/* Warning section */}
            <div className="flex  mt-12  gap-1">
              <PAIconMakiCaution />
              <div class=" text-[#7B7878] text-xs   font-semibold  leading-4  italic ">
                The <span className=" font-bold">Effective rate</span> is the
                lowest rate between the buyer’s and funder rate. <br />
                You can only set a rate within the range of the buyer's and the
                effective rates, if desired
              </div>
            </div>

            {/* Buttons */}
            <div className=" flex justify-between  mt-8">
              <button
                class=" px-4 py-1.5 rounded-[5px] border border-[#de1515] justify-center items-center  flex"
                onClick={onClose}
              >
                <div class="text-[#de1515] text-base font-medium">Cancel</div>
              </button>
              <button
                disabled={editable}
                className="px-4 py-1.5 rounded-[5px]
                
                  bg-[#07593d] cursor-pointer
                  justify-center items-center  flex"
                // onClick={handleNext}
                onClick={onClose}
                // : "bg-[#07593d]/40 cursor-not-allowed
              >
                <div className="text-[#ffffff] text-base font-medium">
                  Preview
                </div>
              </button>
            </div>
          </div>

          {/* BVN Form */}
        </div>
      </div>
    </>
  );
};

export default ConfirmDirectFundingModalBulk2;
