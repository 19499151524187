import { PAIconIllustration } from "assets/images/svgs";
import React, { useEffect, useState } from "react";
import Graph from "./Graph";
import Header from "../Header";
// import { useSelector } from "react-redux";

const Overview = () => {
  const [isAnimated, setIsAnimated] = useState(false);

  // const { toolsAndAgreementDashboardData } = useSelector(
  //   (state) => state?.toolsAndAgreements
  // );

  useEffect(() => {
    const animationTimeout = setTimeout(() => {
      setIsAnimated(true);
    }, 200);

    return () => clearTimeout(animationTimeout);
  }, []);
  return (
    <>
      {/* HEADER */}
      <Header Title="Overview" showSearchInput={false} />

      <div className="grid lg:grid-cols-[57.5%,40%] gap-5 w-full">


      <section className="w-full   px-5 gap-5">
          <div className="w-full lg:flex px7 gap-4">
            {/* LEFT HAND CARD */}
            <div className="w-full lg:mb5">
              {/* CARD 1 */}
              {/* <div className="rounded-2xl border border-transparent bg-[#2FA06A] shadow-lg p-4 text-white mb-5">
                <div className="flex items-center gap-4">
                  <PAIconAgreementBag />
                  <div className="text-base font-normal leading-normal tracking-wide lg:text-xs">
                    <p>Total Agreement</p>
                    <div className="text-white text-2xl font-bold tracking-wide">
                      {toolsAndAgreementDashboardData?.data?.totalAgreements}
                    </div>
                  </div>
                </div>
              </div> */}
              {/* CARD 2 */}

              <div class="w-[207px] h-[134px] pl-[17px] pr-[17.59px] pt-[17px] pb-[20.59px] bg-white rounded-2xl shadow-[8px_7px_11.699999809265137px_4px_rgba(233,233,233,0.10)]  shadow-md justify-center items-center inline-flex overflow-hidden">
                <div class="grow shrink basis-0 self-stretch flex-col justify-start items-start gap-4 inline-flex">
                  <div class="self-stretch justify-start items-center gap-2 inline-flex">
                    <div class="w-[47.41px] h-[47.41px] relative">
                      <div class="w-[47.41px] h-[47.41px] left-0 top-0 absolute bg-[#07593d] rounded-[32.32px]"></div>
                      <div
                        data-svg-wrapper
                        class="left-[12px] top-[12px] absolute"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.0008 22H16.0008C20.0208 22 20.7408 20.39 20.9508 18.43L21.7008 10.43C21.9708 7.99 21.2708 6 17.0008 6H7.0008C2.7308 6 2.0308 7.99 2.3008 10.43L3.0508 18.43C3.2608 20.39 3.9808 22 8.0008 22Z"
                            stroke="#E8E8E8"
                            stroke-width="1.07741"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M8 6V5.2C8 3.43 8 2 11.2 2H12.8C16 2 16 3.43 16 5.2V6"
                            stroke="#E8E8E8"
                            stroke-width="1.07741"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M14 13V14C14 14.01 14 14.01 14 14.02C14 15.11 13.99 16 12 16C10.02 16 10 15.12 10 14.03V13C10 12 10 12 11 12H13C14 12 14 12 14 13Z"
                            stroke="#E8E8E8"
                            stroke-width="1.07741"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M21.65 11C19.34 12.68 16.7 13.68 14 14.02"
                            stroke="#E8E8E8"
                            stroke-width="1.07741"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M2.61719 11.2656C4.86719 12.8056 7.40719 13.7356 9.99719 14.0256"
                            stroke="#E8E8E8"
                            stroke-width="1.07741"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start gap-1 inline-flex">
                      <div class="text-[#222222] text-xl font-semibold  tracking-wide">
                        37
                      </div>
                      <div class="text-[#7a7a7a] text-sm font-medium  tracking-wide">
                        Total Agreements
                      </div>
                    </div>
                  </div>
                  {/* <div class="self-stretch h-[0px] border border-[#222222]"></div> */}
                  <div className="flex w-full items-center space-x-2">
                    {/* <div className="h-0.5 w-[35%] bg-[#AE4551]"></div> */}
                    <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                    <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                    <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                    <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                    <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                    <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                    <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                    <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                    <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                    <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                    <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                    <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                    <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                  </div>
                  <div class="self-stretch justify-between items-end inline-flex">
                    <div class="text-[#7a7a7a] text-sm font-medium  tracking-wide">
                      View details
                    </div>
                    <div data-svg-wrapper class="relative">
                      <svg
                        width="17"
                        height="17"
                        viewBox="0 0 17 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.737 8.40625H3.07031"
                          stroke="#7A7A7A"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M10.4063 11.737C10.4063 11.737 13.7396 9.28205 13.7396 8.40365C13.7396 7.52525 10.4062 5.07031 10.4062 5.07031"
                          stroke="#7A7A7A"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>

              <div class="w-[209px] h-[134px] pl-[17px] pr-[12.59px] pt-[17px] pb-[20.59px] bg-white rounded-2xl shadow-[8px_7px_11.699999809265137px_4px_rgba(233,233,233,0.10)] shadow-md  justify-end items-center inline-flex overflow-hidden mt-4">
                <div class="self-stretch flex-col justify-start items-start gap-4 inline-flex">
                  <div class="justify-start items-center gap-2 inline-flex">
                    <div class="w-[47.41px] h-[47.41px] relative">
                      <div class="w-[47.41px] h-[47.41px] left-0 top-0 absolute bg-[#f08000] rounded-[32.32px]"></div>
                      <div
                        data-svg-wrapper
                        class="left-[12px] top-[12px] absolute"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.0008 22H16.0008C20.0208 22 20.7408 20.39 20.9508 18.43L21.7008 10.43C21.9708 7.99 21.2708 6 17.0008 6H7.0008C2.7308 6 2.0308 7.99 2.3008 10.43L3.0508 18.43C3.2608 20.39 3.9808 22 8.0008 22Z"
                            stroke="#E8E8E8"
                            stroke-width="1.07741"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M8 6V5.2C8 3.43 8 2 11.2 2H12.8C16 2 16 3.43 16 5.2V6"
                            stroke="#E8E8E8"
                            stroke-width="1.07741"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M14 13V14C14 14.01 14 14.01 14 14.02C14 15.11 13.99 16 12 16C10.02 16 10 15.12 10 14.03V13C10 12 10 12 11 12H13C14 12 14 12 14 13Z"
                            stroke="#E8E8E8"
                            stroke-width="1.07741"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M21.65 11C19.34 12.68 16.7 13.68 14 14.02"
                            stroke="#E8E8E8"
                            stroke-width="1.07741"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M2.61719 11.2656C4.86719 12.8056 7.40719 13.7356 9.99719 14.0256"
                            stroke="#E8E8E8"
                            stroke-width="1.07741"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start gap-1 inline-flex">
                      <div class="text-[#222222] text-xl font-semibold  tracking-wide">
                        9
                      </div>
                      <div class="text-[#7a7a7a] text-sm font-medium  tracking-wide">
                        Uploaded template
                      </div>
                    </div>
                  </div>
                  <div className="flex w-full items-center space-x-2">
                    {/* <div className="h-0.5 w-[35%] bg-[#AE4551]"></div> */}
                    <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                    <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                    <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                    <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                    <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                    <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                    <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                    <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                    <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                    <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                    <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                    <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                    <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                  </div>
                  <div class="self-stretch justify-between items-end inline-flex">
                    <div class="text-[#7a7a7a] text-sm font-medium  tracking-wide">
                      View details
                    </div>
                    <div data-svg-wrapper class="relative">
                      <svg
                        width="17"
                        height="17"
                        viewBox="0 0 17 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.737 8.40625H3.07031"
                          stroke="#7A7A7A"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M10.4063 11.737C10.4063 11.737 13.7396 9.28205 13.7396 8.40365C13.7396 7.52525 10.4062 5.07031 10.4062 5.07031"
                          stroke="#7A7A7A"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="rounded-2xl border border-transparent bg-[#FFBE55] shadow-lg p-4 text-white">
                <div className="flex items-center gap-4">
                  <PAIconAgreementTools />
                  <div className="text-base font-normal leading-normal tracking-wide lg:text-sm">
                    <p>Tools Avaliable</p>
                    <div className="text-2xl font-bold tracking-wide">5</div>
                  </div>
                </div>
              </div> */}
            </div>
            {/* RIGHT HAND CARD */}

            <div className="w-full lg:w-1/2 mt-5 xl:mt-0 ">
              <div className="relative h-full">
                {" "}
                {/* first stops here */}
                <div class="w-[200px] h-[134px] relative bg-[#f08000] rounded-2xl  overflow-hidden">
                  <div class="w-[46.87px] h-[46.87px] left-[146px] top-[16px] absolute">
                    <div class="w-[46.87px] h-[46.87px] left-0 top-0 absolute bg-white/40 rounded-[31.96px]"></div>
                    <div
                      data-svg-wrapper
                      class="left-[10.66px] top-[10.66px] absolute"
                    >
                      <svg
                        width="26"
                        height="26"
                        viewBox="0 0 26 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M23.0913 11.4722L22.0763 15.8015C21.2063 19.5405 19.487 21.0526 16.2555 20.7419C15.7376 20.7005 15.1784 20.6073 14.5776 20.4623L12.8376 20.048C8.51863 19.0226 7.18254 16.889 8.19756 12.5597L9.21257 8.21998C9.41971 7.33961 9.66829 6.57317 9.97901 5.94138C11.1908 3.43492 13.2519 2.7617 16.7112 3.57992L18.4409 3.98386C22.7806 4.99887 24.1063 7.14282 23.0913 11.4722Z"
                          stroke="white"
                          stroke-width="1.06532"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M16.252 20.738C15.6099 21.173 14.802 21.5355 13.8181 21.8566L12.1816 22.3952C8.0698 23.7209 5.90513 22.6127 4.56904 18.5009L3.24331 14.4097C1.91758 10.2979 3.01545 8.12288 7.12729 6.79715L8.76374 6.25858C9.18839 6.12393 9.59232 6.01 9.97554 5.9375C9.66482 6.56929 9.41625 7.33573 9.2091 8.2161L8.19409 12.5558C7.17908 16.8851 8.51516 19.0187 12.8341 20.0441L14.5742 20.4584C15.1749 20.6034 15.7342 20.6966 16.252 20.738Z"
                          stroke="white"
                          stroke-width="1.06532"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M13.75 9.49219L18.7733 10.7661"
                          stroke="white"
                          stroke-width="1.06532"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12.7344 13.5L15.738 14.2664"
                          stroke="white"
                          stroke-width="1.06532"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                  <div class="h-[74px] left-[16px] top-[24px] absolute">
                    <div class="left-0 top-0 absolute text-white text-[22.73px] font-medium  tracking-tight">
                      3
                    </div>
                    <div class="w-[117px] left-0 top-[40px] absolute text-white text-sm font-medium  tracking-tight">
                      New Agreements
                      <br />
                      Pending Signature
                    </div>
                  </div>
                </div>
                {/* second stops here */}
                <div class="w-[209px] h-[134px] relative bg-[#07593d] rounded-2xl  overflow-hidden mt-4">
                  <div class="h-[74px] left-[16px] top-[24px] absolute">
                    <div class="left-0 top-0 absolute text-white text-[22.73px] font-medium  tracking-tight">
                      3
                    </div>
                    <div class="w-[123px] left-0 top-[40px] absolute text-white text-sm font-medium  tracking-tight">
                      New Updated Agreements
                    </div>
                  </div>
                  <div class="w-[46.87px] h-[46.87px] left-[146px] top-[16px] absolute">
                    <div class="w-[46.87px] h-[46.87px] left-0 top-0 absolute bg-white/40 rounded-[31.96px]"></div>
                    <div
                      data-svg-wrapper
                      class="left-[10.66px] top-[10.66px] absolute"
                    >
                      <svg
                        width="26"
                        height="26"
                        viewBox="0 0 26 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M23.0913 11.4722L22.0763 15.8015C21.2063 19.5405 19.487 21.0526 16.2555 20.7419C15.7376 20.7005 15.1784 20.6073 14.5776 20.4623L12.8376 20.048C8.51863 19.0226 7.18254 16.889 8.19756 12.5597L9.21257 8.21998C9.41971 7.33961 9.66829 6.57317 9.97901 5.94138C11.1908 3.43492 13.2519 2.7617 16.7112 3.57992L18.4409 3.98386C22.7806 4.99887 24.1063 7.14282 23.0913 11.4722Z"
                          stroke="white"
                          stroke-width="1.06532"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M16.252 20.738C15.6099 21.173 14.802 21.5355 13.8181 21.8566L12.1816 22.3952C8.0698 23.7209 5.90513 22.6127 4.56904 18.5009L3.24331 14.4097C1.91758 10.2979 3.01545 8.12288 7.12729 6.79715L8.76374 6.25858C9.18839 6.12393 9.59232 6.01 9.97554 5.9375C9.66482 6.56929 9.41625 7.33573 9.2091 8.2161L8.19409 12.5558C7.17908 16.8851 8.51516 19.0187 12.8341 20.0441L14.5742 20.4584C15.1749 20.6034 15.7342 20.6966 16.252 20.738Z"
                          stroke="white"
                          stroke-width="1.06532"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M13.75 9.49219L18.7733 10.7661"
                          stroke="white"
                          stroke-width="1.06532"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12.7344 13.5L15.738 14.2664"
                          stroke="white"
                          stroke-width="1.06532"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* down Box */}
          <div className=" flex justify-between items-center w-full gap-1 mt-4 flex-wrap">
            {/* first box */}
            <div class="w-[200px] h-[147px] p-4 bg-white rounded-xl justify-center items-center inline-flex overflow-hidden">
              <div class="grow shrink basis-0 self-stretch flex-col justify-between items-start inline-flex">
                <div class="justify-start items-center gap-2 inline-flex">
                  <div class="w-[47.41px] h-[47.41px] relative">
                    <div class="w-[47.41px] h-[47.41px] left-0 top-0 absolute bg-[#ffbe55] rounded-[32.32px]"></div>
                    <div
                      data-svg-wrapper
                      class="left-[12px] top-[12px] absolute"
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.0008 22H16.0008C20.0208 22 20.7408 20.39 20.9508 18.43L21.7008 10.43C21.9708 7.99 21.2708 6 17.0008 6H7.0008C2.7308 6 2.0308 7.99 2.3008 10.43L3.0508 18.43C3.2608 20.39 3.9808 22 8.0008 22Z"
                          stroke="#E8E8E8"
                          stroke-width="1.07741"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M8 6V5.2C8 3.43 8 2 11.2 2H12.8C16 2 16 3.43 16 5.2V6"
                          stroke="#E8E8E8"
                          stroke-width="1.07741"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M14 13V14C14 14.01 14 14.01 14 14.02C14 15.11 13.99 16 12 16C10.02 16 10 15.12 10 14.03V13C10 12 10 12 11 12H13C14 12 14 12 14 13Z"
                          stroke="#E8E8E8"
                          stroke-width="1.07741"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M21.65 11C19.34 12.68 16.7 13.68 14 14.02"
                          stroke="#E8E8E8"
                          stroke-width="1.07741"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M2.61719 11.2656C4.86719 12.8056 7.40719 13.7356 9.99719 14.0256"
                          stroke="#E8E8E8"
                          stroke-width="1.07741"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                  <div class="w-[124px] flex-col justify-start items-start gap-1 inline-flex">
                    <div class="text-[#222222] text-xl font-semibold  tracking-wide">
                      5
                    </div>
                    <div class="text-[#7a7a7a] text-sm font-medium  tracking-wide">
                      Tools Available
                    </div>
                  </div>
                </div>
                <div className="flex w-full items-center space-x-2">
                  {/* <div className="h-0.5 w-[35%] bg-[#AE4551]"></div> */}
                  <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                  <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                  <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                  <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                  <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                  <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                  <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                  <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                  <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                  <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                  <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                  <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                  <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                </div>
                <div class="self-stretch justify-between items-end inline-flex">
                  <div class="text-[#7a7a7a] text-sm font-medium  tracking-wide">
                    View tools page
                  </div>
                  <div data-svg-wrapper class="relative">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.3307 8H2.66406"
                        stroke="#7A7A7A"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.0001 11.3307C10.0001 11.3307 13.3333 8.8758 13.3333 7.9974C13.3333 7.119 10 4.66406 10 4.66406"
                        stroke="#7A7A7A"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>

            {/* second box */}
            <div class="w-[200px] h-[147px] p-4 bg-white rounded-xl  justify-center items-center inline-flex overflow-hidden">
              <div class="grow shrink basis-0 self-stretch flex-col justify-between items-start inline-flex">
                <div class="justify-start items-center gap-2 inline-flex">
                  <div class="w-[47.41px] h-[47.41px] relative">
                    <div class="w-[47.41px] h-[47.41px] left-0 top-0 absolute bg-[#2fa069] rounded-[32.32px]"></div>
                    <div
                      data-svg-wrapper
                      class="left-[12px] top-[12px] absolute"
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.0008 22H16.0008C20.0208 22 20.7408 20.39 20.9508 18.43L21.7008 10.43C21.9708 7.99 21.2708 6 17.0008 6H7.0008C2.7308 6 2.0308 7.99 2.3008 10.43L3.0508 18.43C3.2608 20.39 3.9808 22 8.0008 22Z"
                          stroke="#E8E8E8"
                          stroke-width="1.07741"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M8 6V5.2C8 3.43 8 2 11.2 2H12.8C16 2 16 3.43 16 5.2V6"
                          stroke="#E8E8E8"
                          stroke-width="1.07741"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M14 13V14C14 14.01 14 14.01 14 14.02C14 15.11 13.99 16 12 16C10.02 16 10 15.12 10 14.03V13C10 12 10 12 11 12H13C14 12 14 12 14 13Z"
                          stroke="#E8E8E8"
                          stroke-width="1.07741"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M21.65 11C19.34 12.68 16.7 13.68 14 14.02"
                          stroke="#E8E8E8"
                          stroke-width="1.07741"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M2.61719 11.2656C4.86719 12.8056 7.40719 13.7356 9.99719 14.0256"
                          stroke="#E8E8E8"
                          stroke-width="1.07741"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                  <div class="w-[124px] flex-col justify-start items-start gap-1 inline-flex">
                    <div class="text-[#222222] text-xl font-semibold  tracking-wide">
                      3
                    </div>
                    <div class="text-[#7a7a7a] text-sm font-medium  tracking-wide">
                      Email template
                    </div>
                  </div>
                </div>
                <div className="flex w-full items-center space-x-2">
                  {/* <div className="h-0.5 w-[35%] bg-[#AE4551]"></div> */}
                  <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                  <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                  <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                  <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                  <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                  <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                  <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                  <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                  <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                  <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                  <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                  <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                  <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                </div>
                <div class="self-stretch justify-between items-end inline-flex">
                  <div class="text-[#7a7a7a] text-sm font-medium  tracking-wide">
                    View tools page
                  </div>
                  <div data-svg-wrapper class="relative">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.3307 8H2.66406"
                        stroke="#7A7A7A"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.0001 11.3307C10.0001 11.3307 13.3333 8.8758 13.3333 7.9974C13.3333 7.119 10 4.66406 10 4.66406"
                        stroke="#7A7A7A"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* last text body */}

          <div className="mt-10 px-7">
            <h5 className="text-black font-bold text-lg tracking-wide lg:mb-5">
              Tools that Simplify
            </h5>
            <p className="text-gray-800 font-normal text-base leading-6 tracking-wide">
              "Our digital platform offers real-time financial insights,
              simplifying payables, receivables, supplier tracking, and informed
              decision-making."
            </p>
          </div>
        </section>


        {/* second side */}

        <section className="w-full  px-4  bg-orange-50 rounded-2xl shadow-[inset_0px_4px_4px_0px_rgba(255,220,163,0.94)] shadow-[inset_0px_-10px_4px_0px_rgba(254,250,246,1.00)]  px-3 ">
          <div className="flex items-center justify-center">
            <PAIconIllustration />
          </div>

          <div class="w-full h-[134px] pl-[17px] pr-[12.59px] pt-[17px] pb-[20.59px] bg-white rounded-2xl shadow-[8px_7px_11.699999809265137px_4px_rgba(233,233,233,0.10)]  justify-end items-center inline-flex overflow-hidden mt-4">
            <div class="self-stretch flex-col justify-start items-start gap-4 inline-flex w-full">
              <div class="justify-start items-center gap-2 inline-flex">
                <div class="w-[47.41px] h-[47.41px] relative">
                  <div class="w-[47.41px] h-[47.41px] left-0 top-0 absolute bg-[#f08000] rounded-[32.32px]"></div>
                  <div data-svg-wrapper class="left-[12px] top-[12px] absolute">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.0008 22H16.0008C20.0208 22 20.7408 20.39 20.9508 18.43L21.7008 10.43C21.9708 7.99 21.2708 6 17.0008 6H7.0008C2.7308 6 2.0308 7.99 2.3008 10.43L3.0508 18.43C3.2608 20.39 3.9808 22 8.0008 22Z"
                        stroke="#E8E8E8"
                        stroke-width="1.07741"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8 6V5.2C8 3.43 8 2 11.2 2H12.8C16 2 16 3.43 16 5.2V6"
                        stroke="#E8E8E8"
                        stroke-width="1.07741"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M14 13V14C14 14.01 14 14.01 14 14.02C14 15.11 13.99 16 12 16C10.02 16 10 15.12 10 14.03V13C10 12 10 12 11 12H13C14 12 14 12 14 13Z"
                        stroke="#E8E8E8"
                        stroke-width="1.07741"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M21.65 11C19.34 12.68 16.7 13.68 14 14.02"
                        stroke="#E8E8E8"
                        stroke-width="1.07741"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M2.61719 11.2656C4.86719 12.8056 7.40719 13.7356 9.99719 14.0256"
                        stroke="#E8E8E8"
                        stroke-width="1.07741"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div class="flex-col justify-start items-start gap-1 inline-flex">
                  <div class="text-[#222222] text-xl font-semibold  tracking-wide">
                    5
                  </div>
                  <div class="text-[#7a7a7a] text-sm font-medium  tracking-wide">
                    Agreement Pending Deletion
                  </div>
                </div>
              </div>
              <div className="flex w-full items-center space-x-2">
                {/* <div className="h-0.5 w-[35%] bg-[#AE4551]"></div> */}
                <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                <div className="h-0.5 w-[3%] bg-[#262626]"></div>
                <div className="h-0.5 w-[3%] bg-[#262626]"></div>
              </div>
              <div class="self-stretch justify-between items-end inline-flex">
                <div class="text-[#7a7a7a] text-sm font-medium  tracking-wide">
                  View details
                </div>
                <div data-svg-wrapper class="relative">
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.737 8.40625H3.07031"
                      stroke="#7A7A7A"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.4063 11.737C10.4063 11.737 13.7396 9.28205 13.7396 8.40365C13.7396 7.52525 10.4062 5.07031 10.4062 5.07031"
                      stroke="#7A7A7A"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-3">
            <div className="rounded-2xl border border-transparent bg-white shadow-lg p-4 text-black  items-center justify-center">
              <div className="text-black font-bold text-lg tracking-wide flex items-center justify-center">
                Agreement Status
              </div>
              <div className="mt-5 flex items-center justify-center">
                <Graph />
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* SECTION */}
      <div
        className={`w-full flex flex-wrap lg:flex-row px-7 mb-5 transition-transform transform ${
          isAnimated ? "opacity-100 translate-y-0" : "opacity-0 translate-y-4"
        } ease-in duration-500`}
      >
        {/* LEFT HAND SECTION */}
      

        {/* RIGHT HAND SECTON */}
       
      </div>
    </>
  );
};

export default Overview;
