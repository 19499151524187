import React, { useEffect, useState } from "react";
import { PAIconInventoryArrowLeft } from "assets/images/svgs";
import { Link,  useNavigate, useParams } from "react-router-dom";
// import LoadingScreen from "shared/LoadingScreen";
import DashboardLayout from "layout/dashboardlayout";
import SuccessModal from "shared/SuccessModal";
import AcceptModal from "shared/AcceptModal";
// import { campaignInvoiceById } from "appstate/supplierEarlyPayment/supplierEarlyPaymentSlice";
// import SelectAccountModal from "../payment";
import {
  getSingleEarlyPaymentOffer,
  SingleEarlyPaymentAcceptOffer,
  SingleEarlyPaymentRejectOffer,
} from "appstate/api/invoice/invoice";
import toast from "react-hot-toast";
import SelectionAccount from "../payment/SelectionAccount";
import LoadingScreen from "shared/LoadingScreen";
import PfpReject from "shared/pfpReject";

const SupplierEarlyPaymentPendingInvoice = () => {
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isSelectAccountModalOpen, setIsSelectAccountModalOpen] = useState("");
  const [isProceedModalOpen, setIsProceedModalOpen] = useState(false);
  const [offerDetails, setOfferDetails] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  // const location = useLocation();

  // const { selectedInvoices } = location.state || {};

  // const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const { id } = useParams();
  const offerId = id;

  useEffect(() => {
    const fetchOfferDetails = async () => {
      try {
        setIsLoading(true);
        const response = await getSingleEarlyPaymentOffer(offerId);
        setOfferDetails(response?.data);
      } catch (error) {
        console.error("Error fetching offer details:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (offerId) {
      fetchOfferDetails();
    }
  }, [offerId]);

  const handleAccept = () => {
    setIsAcceptModalOpen(false);
    setIsSelectAccountModalOpen(true);
  };

  const handleAccountSubmit = () => {
    setIsSelectAccountModalOpen(false);
    setIsProceedModalOpen(true);
  };

  const handleProceedSubmit = async () => {
    if (!selectedAccount) {
      toast.error("Please select an account!");
      return;
    }

    setIsProceedModalOpen(false);

    try {
      const payload = {
        virtualAccountId: selectedAccount.id,
        offerIds: [id],
        accept: true,
        reason: "Accepted for early payment",
      };
      const response = await SingleEarlyPaymentAcceptOffer(payload);
      setIsSuccessModalOpen(true);
      setSuccessMessage(response?.message || "Offer accepted successfully!");
      setTimeout(() => {
        navigate("/supplier-earlypayment");
      }, 2000);
    } catch (error) {
      console.error("Error accepting offer:", error);
      toast.error(error?.response?.data?.message || "Failed to accept offer!");
    }
  };

  const handleReject = async () => {
    try {
      const payload = {
        offerIds: [id],
        accept: false,
        reason: "We dont like your offer",
      };
      const response = await SingleEarlyPaymentRejectOffer(payload);
      setIsSuccessModalOpen(true);
      setSuccessMessage(response?.message || "Early payment offer rejected");
      setTimeout(() => {
        navigate("/supplier-earlypayment");
      }, 2000);
    } catch (error) {
      console.error("Error accepting offer:", error);
      // toast.error(error?.response?.data?.message || "Failed to accept offer!");
    }
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  const formatAmount = (amount) => {
    if (typeof amount !== "number") return "0.00";
    return amount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  return (
    <DashboardLayout
      section="Payable Finance Program"
      subHeader="Here is an overview of your pfp"
    >
      <div>
        <Link
          onClick={() => navigate(-1)}
          className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
        >
          <PAIconInventoryArrowLeft />
          <span className="flex items-center">Go Back</span>
        </Link>
      </div>
      <div className="flex flex-col gap-8 pb-24 bg-white rounded-[10px] min-h-[80vh]">
        {/* first header */}
        <header className="px-6 border-b pt-[17px] rounded-t-[10px] border-[#B1A6B2] pb-[14px] bg-alabasterHeader">
          <div className="flex justify-between items-center">
            <h1 className="text-black font-medium">Invoices</h1>
            <div className="flex items-center gap-5">
              <div
                onClick={() => setIsAcceptModalOpen(true)}
                className="rounded-[5px] cursor-pointer bg-[#07593D] shadow-md text-[#F9F9F9] text-xs font-medium py-[10px] px-[20px]"
              >
                Accept Payment
              </div>
              <div
                onClick={() => setIsRejectModalOpen(true)}
                className="rounded-[5px] cursor-pointer text-[#BB0C0C] border border-[#BB0C0C]  shadow-md text-xs font-medium py-[10px] px-[20px]"
              >
                Reject Payment
              </div>
            </div>
          </div>
        </header>

        <div className="px-6">
          <div className="flex flex-col gap-3">
            <header className="flex justify-between items-center">
              <h1 className="text-[18px] font-bold">
                Invoice | INV-
                {`${offerDetails?.invoiceNo}`}
              </h1>
            </header>

            <main className="w-full flex justify-between">
              <div className="flex flex-col gap-3 w-1/2">
                <div className="flex gap-x-[5px]">
                  <span>Reference No:</span>
                </div>
                <div className="flex flex-col gap-1">
                  <div className="flex gap-x-[50px]">
                    <span className="text-xs w-[97px]">Created Date:</span>
                    <span className="text-[#979797] text-xs">
                      {offerDetails?.createdDate}
                    </span>
                  </div>
                  <div className="flex gap-x-[50px]">
                    <span className="text-xs w-[97px]">Submitted Date:</span>
                    <span className="text-[#979797] text-xs">
                      {offerDetails?.submittedDate}
                    </span>
                  </div>
                  <div className="flex gap-x-[50px]">
                    <span className="text-xs w-[97px]">Approved Date:</span>
                    <span className="text-[#979797] text-xs">
                      {offerDetails?.approvedDate}
                    </span>
                  </div>
                  <div className="flex gap-x-[50px]">
                    <span className="text-xs">Due Date:</span>
                    <span
                      style={{ marginLeft: 50 }}
                      className="text-[#979797] text-xs"
                    >
                      {offerDetails?.dueDate}
                    </span>
                  </div>
                  <div className="flex gap-x-[50px]">
                    <span className="text-xs w-[97px]">Issuance Date:</span>
                    <span className="text-[#979797] text-xs">
                      {offerDetails?.issuanceDate}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-between">
                <div className="text-sm flex flex-col justify-end items-end ">
                  <div className="flex flex-col gap-1">
                    <p className="font-bold">Payable Finance Offer</p>
                    <div className="flex justify-between">
                      <span className="text-xs ">Name of campaign:</span>
                      <span
                        style={{ marginLeft: 4 }}
                        className="text[#979797] text-xs"
                      >
                        {offerDetails?.programName}
                      </span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-xs ">End Date:</span>
                      <span className="text[#979797] text-xs">
                        {offerDetails?.endDate}
                      </span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-xs ">Discount Rate:</span>
                      <span className="text[#979797] text-xs">
                        {offerDetails?.discountRate}
                      </span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-xs ">Discount Amount:</span>
                      <span className="text[#979797] text-xs">
                        {offerDetails?.discountAmount}
                      </span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-xs ">Receivable Amount:</span>
                      <span
                        style={{ marginLeft: 4 }}
                        className="text[#979797] text-xs"
                      >
                        {offerDetails?.receivableAmount}
                      </span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-xs ">Payment Duration:</span>
                      <span className="text[#979797] text-xs flex justify-end">
                        {offerDetails?.paymentDuration}
                      </span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-xs ">Payment Date:</span>
                      <span className="text[#979797] text-xs flex justify-end">
                        {offerDetails?.paymentDate}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
          <br />
          <div className="flex flex-col gap-4">
            <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
              <h1 className="text-[18px] ">Supplier/buyer details</h1>
            </header>
            <div className="flex justify-between mb-5">
              <div className="flex gap-2">
                <div className="flex flex-col">
                  <span className="font-bold text-sm">Supplier</span>
                  <span className="text-[#979797] text-xs">
                    {offerDetails?.supplierName}
                  </span>
                </div>
              </div>
              <div className="flex gap-2">
                <div className="flex flex-col">
                  <span className="font-bold text-sm">Buyer</span>
                  <span className="text-[#979797] text-xs">
                    {offerDetails?.buyerName}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
              <h1 className="text-sm font-bold">Line Items</h1>
            </header>
            <div className="w-full overflow-x-auto">
              <table className="w-full overflow-x-auto">
                <thead className="bg-subtleGray w-5">
                  <tr className="bg-unusualGray p-2.5">
                    <td className="text-[#11192A] p-2.5 text-xs font-bold">
                      Item Number
                    </td>
                    <td className="p-2.5 text-xs font-bold">
                      Product/Service Description
                    </td>
                    <td className="p-2.5 text-xs font-bold">Quantity</td>
                    <td className="p-2.5 text-xs font-bold">Unit Price</td>
                    <td className="p-2.5 text-xs font-bold">Total Amount</td>
                  </tr>
                </thead>
                <tbody>
                  {/* REPLACE THIS WITH DUMMY DATA SINCE CAMPAIGNiNVOICEiDDATA IS NOT AVAILABLE */}
                  {offerDetails?.lineItems?.map((item, index) => (
                    <tr key={index} className="py-[3px] px-1.5">
                      <td className="text-xs text-[#11192A] p-2.5">
                        {item.itemName}
                      </td>
                      <td className="text-xs text-[#11192A] p-2.5">
                        {item.description}
                      </td>
                      <td className="text-xs text-[#11192A] p-2.5">
                        {item.quantity}
                      </td>
                      <td className="text-xs text-[#11192A] p-2.5">
                        {formatAmount(item.unitPrice)}
                      </td>
                      <td className="text-xs text-[#11192A] p-2.5">
                        {formatAmount(item.amount)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <AcceptModal
          isOpen={isAcceptModalOpen}
          onClose={() => setIsAcceptModalOpen(false)}
          message={
            <>
              Are you sure you would like to{" "}
              <span className="text-[#07593D] font-bold">accept</span> this
              offer
              <br />
              from Payable flex Payable Finance Program?
            </>
          }
          onConfirm={handleAccept}
          loading={false}
        />

        <PfpReject
          isOpen={isRejectModalOpen}
          onClose={() => setIsRejectModalOpen(false)}
          message={
            <>
              Are you sure you would like to{" "}
              <span className="text-[#BB0C0C] font-bold">reject</span> this
              offer
              <br />
              from Payable flex Payable Finance Program?
            </>
          }
          handleReject={handleReject}
          loading={false}
        />
        <SuccessModal
          isOpen={isSuccessModalOpen}
          onClose={() => setIsSuccessModalOpen(false)}
          message={successMessage}
        />
        <SelectionAccount
          isOpen={isSelectAccountModalOpen}
          onClose={() => setIsSelectAccountModalOpen(false)}
          onSubmit={handleAccountSubmit}
          setSelectedAccount={setSelectedAccount}
        />
        <AcceptModal
          isOpen={isProceedModalOpen}
          onClose={() => setIsProceedModalOpen(false)}
          message={
            <>
              Would you like to proceed with this account{" "}
              <span className="text-[#07593D] font-bold">
                {selectedAccount?.accountNumber}
              </span>
            </>
          }
          onConfirm={handleProceedSubmit}
          loading={false}
        />
      </div>
    </DashboardLayout>
  );
};

export default SupplierEarlyPaymentPendingInvoice;
