import request from "apiInstance";
import toast from "react-hot-toast";

export const createPfp = async (body) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/payable-finance/create",
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const cancelPfpProgram = async (id) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/payable-finance/cancel-campaign/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const stopPfpProgram = async ({ id, body }) => {

  try {
    const response = await request({
      method: "put",
      url: `/api/v1/payable-finance/stop-campaign/${id}`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getCampaignInvoices = async (body, page, pageSize) => {
  try {
    const response = await request({
      method: "post",
      url: `/api/v1/payable-finance/generate-campaign-offers?page=${0}&size=${10}`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const activatePfp = async (rule, programs, activate) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/rule/activate?ruleType=${rule}&programType=${programs}&activate=${activate}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const updatePfpDetails = async ({ id, body }) => {
  try {
    const response = await request({
      method: "put",
      url: `/payable-finance/buyer/import-invoices?pfpID=${id}`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllBuyerPfp = async ({ page, pageSize, searchTerm }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/payable-finance/campaigns?page=${page}&size=${pageSize}&search=${searchTerm}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const viewMembersData = async ({ body }) => {
  try {
    const response = await request({
      method: "post",
      url: `/api/v1/payable-finance/campaign-members`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllCampaignAgreement = async () => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/agreement`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const buyerGetOfferDetails = async ({ id }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/payable-finance/campaigns/offers/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const buyerViewOfferInvoiceDetails = async ({ id, offerId }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/payable-finance/campaigns/${id}/invoice/${offerId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const approveExitRequest = async ({ id }) => {
  try {
    const response = await request({
      method: "post",
      url: `/api/v1/payable-finance/exit-program/accept?notificationID=${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const declineExitRequest = async ({ id, note }) => {
  try {
    const response = await request({
      method: "post",
      url: `/api/v1/payable-finance/exit-program/reject?notificationID=${id}&note=${note}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const invoiceImport = async ({ id, body }) => {
  try {
    const response = await request({
      method: "put",
      url: `/payable-finance/buyer/import-invoices?pfpID=${id}`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const viewBuyerPfpById = async ({ id }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/payable-finance/campaigns/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllBuyerPfpInvoices = async ({ page, pageSize }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/payable-finance/buyer/pfp-invoices?page=${page}&size=${pageSize}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
