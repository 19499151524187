import { PAIconFilter, PAIconOutlineSearch } from "assets/images/svgs";
import Card from "shared/Card";
import TransitionOpacity from "shared/animation/TransitionOpacity";
import RequestsCard from "features/closedmarket/components/cards/RequestsCard";
import { useEffect, useState } from "react";
import {
  closedMarketViewListBulk,
  closedMarketViewListSingle,
} from "appstate/buyer/buyerSlice";
import { useDispatch, useSelector } from "react-redux";
import Loading from "shared/Loading";

const Request = () => {
  const dispatch = useDispatch();
  const {
    isLoading,
    closedMarketViewListSingleData,
    closedMarketViewListBulkData,
  } = useSelector((state) => state?.buyer);
  const [requestType, setRequestType] = useState("single");
  const [selectedFilter, setSelectedFilter] = useState("");
  const [selectedFilterBulk, setSelectedFilterBulk] = useState("");

  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermBulk, setSearchTermBulk] = useState("");

  const handleChange = (event) => {
    if (requestType === "single") {
      setSelectedFilter(event.target.value);
    } else {
      setSelectedFilterBulk(event.target.value);
    }
  };

  useEffect(() => {
    dispatch(
      closedMarketViewListSingle({ status: selectedFilter, search: searchTerm })
    );
  }, [dispatch, searchTerm, selectedFilter]);

  useEffect(() => {
    dispatch(
      closedMarketViewListBulk({
        status: selectedFilterBulk,
        search: searchTermBulk,
      })
    );
  }, [dispatch, searchTermBulk, selectedFilterBulk]);
  return (
    <>
      <div>
        <TransitionOpacity className="min-h-[75vh] rounded-b-[10px] bg-unusualGray rounded-tl-[10px]  rounded-tr-[10px] ">
          <Card className="  rounded-tl-[10px]  rounded-tr-[10px] p-[0.5px] ">
            <div className=" px-[24px]  rounded-tl-[10px]  rounded-tr-[10px]  ">
              <div className="my-2 grid md:grid-cols-2 items-center">
                <div className=" flex flex-col relative">
                  <div className="flex items-center gap-2.5">
                    <div className="text-[#000] text-sm">
                      Filter request status:
                    </div>
                    <div class="text-zinc-600 text-sm font-normal font-['SF Pro Display']">
                      <select
                        onChange={handleChange}
                        className="border-[1px] rounded-[5px] border-[#959595] px-5 py-2.5 outline-none"
                      >
                        <option value="">All Requests</option>
                        <option value="POSTED">Posted Requests</option>
                        <option value="BID_ACTIVE">Bid Active Requests</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="flex md:col-span-1 justify-end">
                  <div className="flex items-center justify-between gap-5">
                    <div className="border border-transparent md:border-gray md:shadow-sm my-4 rounded-[5px] px-2 lg:w-[300px] bg-white">
                      <div className="items-center flex bg-white">
                        <PAIconOutlineSearch />
                        <input
                          className="bg-transparent p-2 w-full focus:outline-none text-sm bg-white"
                          type="text"
                          placeholder="Search for funders or invoices"
                          value={
                            requestType === "single"
                              ? searchTerm
                              : searchTermBulk
                          }
                          onChange={(e) =>
                            requestType === "single"
                              ? setSearchTerm(e.target.value)
                              : setSearchTermBulk(e.target.value)
                          }
                        />
                      </div>
                      <div className="lg:hidden flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer">
                        <PAIconFilter className="bg-[#2FA06A]" />
                      </div>
                    </div>
                    <button className="hidden text-sm lg:flex bg-[#2FA06A] rounded-md my-4 py-2 px-6 mx3 items-center gap-2 text-white">
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Card>

          <div className=" p-6 flex flex-col gap-5 ">
            <div className="flex items-center">
              <div
                onClick={() => {
                  setRequestType("single");
                }}
                className="mr-6 flex items-center flex-col cursor-pointer"
              >
                <p
                  className={
                    requestType === "single"
                      ? "text-secondaryColor font-bold text-sm"
                      : "text-[#959595] text-sm"
                  }
                >
                  Single Requests
                </p>

                {requestType === "single" && (
                  <div className="h-[1.5px] w-6 rounded-lg bg-secondaryColor"></div>
                )}
              </div>
              <div
                onClick={() => {
                  setRequestType("bulk");
                }}
                className="flex items-center flex-col cursor-pointer"
              >
                <p
                  className={
                    requestType === "bulk"
                      ? "text-secondaryColor font-bold text-sm"
                      : "text-[#959595] text-sm"
                  }
                >
                  Bulk Requests
                </p>

                {requestType === "bulk" && (
                  <div className="h-[1.5px] w-6 rounded-lg bg-secondaryColor"></div>
                )}
              </div>
            </div>

            {isLoading ? (
              <div className="w-full h-full flex justify-center items-center">
                <Loading />
              </div>
            ) : (
              <div className="flex flex-col gap-4">
                {requestType === "single" ? (
                  closedMarketViewListSingleData?.data?.length > 0 ? (
                    closedMarketViewListSingleData?.data?.map((invoice) => {
                      return (
                        <RequestsCard
                          key={invoice?.closedMarketId}
                          id={invoice?.closedMarketId}
                          invoiceId={invoice?.invoiceId}
                          status={invoice?.status}
                          rateType={invoice.rateType}
                          statusRequest={invoice.statusRequest}
                          supplierCompanyName={invoice.supplierCompanyName}
                          invoiceNumber={invoice?.invoiceNo}
                          invoiceReference={invoice?.requestRef}
                          doerType={invoice.userType}
                          requestAmount={invoice?.requestAmount}
                          invoiceDueDate={invoice?.invoiceDueDate}
                          supplierCompanyIcon={invoice.userTypeIcon}
                          numberOfInvoices={invoice.bidAmount}
                          requestType={requestType}
                          buyerRate={invoice?.buyerDiscountRate}
                          earlypaymentDate={invoice?.earlyPaymentDate}
                        />
                      );
                    })
                  ) : (
                    <div className="flex pt-6 justify-center">
                      <span className="font-medium">
                        There are no records to display
                      </span>
                    </div>
                  )
                ) : closedMarketViewListBulkData?.data?.length > 0 ? (
                  closedMarketViewListBulkData?.data?.map((invoice) => {
                    return (
                      <RequestsCard
                        invoiceReference={invoice?.requestRef}
                        key={invoice?.invoiceId}
                        id={invoice?.closedMarketId}
                        invoiceId={invoice?.invoiceId}
                        status={invoice?.status}
                        rateType={invoice?.rateType}
                        statusRequest={invoice?.statusRequest}
                        supplierCompanyName={invoice?.supplierCompanyName}
                        invoiceNumber={invoice?.invoiceNo}
                        doerType={invoice.userType}
                        requestAmount={invoice?.requestAmount}
                        invoiceDueDate={invoice?.invoiceDueDate}
                        supplierCompanyIcon={invoice?.userTypeIcon}
                        numberOfInvoices={invoice?.noOfInvoice}
                        requestType={"bulk"}
                        buyerRate={invoice?.lockRate}
                        earlypaymentDate={invoice?.earlyPaymentDate}
                      />
                    );
                  })
                ) : (
                  <div className="flex pt-6 justify-center">
                    <span className="font-medium">
                      There are no records to display
                    </span>
                  </div>
                )}
              </div>
            )}
          </div>
        </TransitionOpacity>
      </div>
    </>
  );
};

export default Request;
