import React, { useState, useEffect, useMemo } from "react";

import OngoingBidCard from "./card/OngoingBidCard";
import OngoingBidCancelSuccessOnBoardModal from "../modals/OngoingBidCancelSucessOnBoardModal";
import OngoingCancelOnBoardModal from "../modals/OngoingCancelOnBoardModal";
import { useSelector } from "react-redux";

import Pagination from "shared/Pagination";
import { funderFetchAllClosedMarketBySingleStatus } from "appstate/funder/funderSlice";
import { useDispatch } from "react-redux";
import TableEmptyState from "shared/TableEmptyState";

const OngoingBid = ({ closedMarketQuery }) => {
  const [ongoingCancelBidModal, setOngoingCancelBidModal] = useState(false);
  const [ongoingBidCancelledSuccessModal, setOngoingBidCancelledSuccessModal] =
    useState(false);

  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);

  const handleOnCancelSuccessClose = () => {
    setOngoingBidCancelledSuccessModal(false);
  };
  const handleOnCancelBidTrue = () => {
    setOngoingCancelBidModal(false);
    setOngoingBidCancelledSuccessModal(true);
  };
  const { ongoingBidType } = useSelector(
    (state) => state?.verificationSupplier
  );
  const data = useMemo(
    () => [
      {
        id: "1",
        invoiceNumber: "R3456X89Y",
        buyerName: "NJ Breweries",
        invoiceType: "Single Invoice",

        lockRate: "Widget Delivery",
        statusOfInvoice: "Bid Active",
        requestAmount: "N35,847,000",
        dueDate: "2023-12-01",
        supplierName: "Tacna Plc.",
        earlyPaymentDate: "2023-11-01",
        currentlyLeading: "",
      },
      {
        id: "2",
        invoiceNumber: "R3456X89Y",
        buyerName: "Tera Plc.",
        invoiceType: "Bulk Invoice",

        lockRate: "Widget Delivery",
        statusOfInvoice: "Bid Active",
        requestAmount: "N35,847,000",
        dueDate: "2023-12-01",
        supplierName: "Defi Ltd.",
        earlyPaymentDate: "2023-11-01",
        currentlyLeading: "",
      },
      {
        id: "3",
        invoiceNumber: "R3456X89Y",
        buyerName: "NJ Breweries",
        invoiceType: "Single Invoice",

        lockRate: "Widget Delivery",
        statusOfInvoice: "Bid Active",
        requestAmount: "N35,847,000",
        dueDate: "2023-12-01",
        supplierName: "Tacna Plc.",
        earlyPaymentDate: "2023-11-01",
        currentlyLeading: "",
      },
      {
        id: "4",
        invoiceNumber: "R3456X89Y",
        buyerName: "Nestle Ltd.",
        invoiceType: "Single Invoice",

        lockRate: "Widget Delivery",
        statusOfInvoice: "Bid Active",
        requestAmount: "N35,847,000",
        dueDate: "2023-12-01",
        supplierName: "Coca Cola",
        earlyPaymentDate: "2023-11-01",
        currentlyLeading: "Currently Leading",
      },
    ],
    []
  );
  const totalPages = data.length;

  const [filteredData, setFilteredData] = useState(data);
  const [activeFilter, setActiveFilter] = useState("");

  // Synchronize activeFilter with ongoingBidType when ongoingBidType changes
  useEffect(() => {
    if (ongoingBidType) {
      setActiveFilter(ongoingBidType);
    }
  }, [ongoingBidType]);

  // Apply filtering logic whenever `activeFilter` changes
  useEffect(() => {
    const filterValue = activeFilter || "";

    if (filterValue === "") {
      setFilteredData(data); // Show all items
    } else if (filterValue === "Currently Leading") {
      setFilteredData(
        data.filter((bid) => bid.currentlyLeading === "Currently Leading")
      );
    } else if (filterValue === "Bid Active") {
      setFilteredData(
        data.filter(
          (bid) =>
            bid.statusOfInvoice === "Bid Active" &&
            bid.currentlyLeading !== "Currently Leading"
        )
      );
    }
  }, [activeFilter, data]);

  const handleFilterChange = (e) => {
    setActiveFilter(e.target.value); // Override ongoingBidType with select option
  };
  const dispatch = useDispatch();

  const { funderClosedMarketDataBySingleStatus } = useSelector(
    (state) => state.funder
  );
  useEffect(() => {
    dispatch(
      funderFetchAllClosedMarketBySingleStatus({ status: "BID_ACTIVE" })
    );
  }, [dispatch]);

  return (
    <>
      <OngoingCancelOnBoardModal
        isOpen={ongoingCancelBidModal}
        onClose={() => setOngoingCancelBidModal(false)}
        refNum={"R3456X89Y"}
        handleYes={handleOnCancelBidTrue}
      />
      <OngoingBidCancelSuccessOnBoardModal
        isOpen={ongoingBidCancelledSuccessModal}
        onClose={handleOnCancelSuccessClose}
        message={"Your bid has been cancelled"}
      />
      <div>
        <div className="flex flex-col  rounded-[10px] bg-unusualGray  px-6 mt-2">
          <header className="pb-4  flex flex-row gap-1  py-8 ">
            <div className="flex flex-row gap-2">
              <div className="text-black text-sm font-normal ">
                Filter bids:
              </div>

              <div>
                <select
                  className="shadow-sm text-sm focus:outline-none cursor-pointer h-[37px] px-5 py-2.5 rounded-[5px] border border-[#949494] justify-start items-end gap-2 inline-flex md:min-w-[12.5rem]"
                  onChange={handleFilterChange}
                  value={activeFilter || ""}
                >
                  <option value="">All Ongoing Bids</option>
                  <option value={"Currently Leading"}>
                    Bids I'm currently leading
                  </option>
                  <option value="Bid Active">
                    Bids I'm not currently leading
                  </option>
                </select>
              </div>

              {/*  */}
            </div>
            <div></div>
          </header>

          <div className="w-full  overflow-x-auto  ">
            {/*  <TableEmptyState dashboard /> */}
            {funderClosedMarketDataBySingleStatus?.data?.length > 0 ? (
              <div className="flex flex-col gap-2.5 ">
                {funderClosedMarketDataBySingleStatus?.data &&
                  funderClosedMarketDataBySingleStatus?.data.length > 0 &&
                  funderClosedMarketDataBySingleStatus?.data?.map(
                    (bids, idx) => (
                      <OngoingBidCard
                        key={idx}
                        id={bids.id}
                        invoiceNumber={bids.invoiceNumber}
                        buyerName={bids.buyerName}
                        invoiceType={bids.invoiceType}
                        lockRate={bids.lockRate}
                        statusOfInvoice={bids.statusOfInvoice}
                        requestAmount={bids.requestAmount}
                        dueDate={bids?.dueDate}
                        earlyPaymentDate={bids.earlyPaymentDate}
                        supplierName={bids.supplierName}
                        onCancel={() => setOngoingCancelBidModal(true)}
                        currentlyLeading={bids.currentlyLeading}
                      />
                    )
                  )}
              </div>
            ) : (
              <TableEmptyState dashboard />
            )}
          </div>

          <div className="w-full  overflow-x-auto  ">
            <div className="flex flex-col gap-2.5 ">
              {filteredData.map((bids, idx) => (
                <OngoingBidCard
                  key={idx}
                  id={bids.id}
                  invoiceNumber={bids.invoiceNumber}
                  buyerName={bids.buyerName}
                  invoiceType={bids.invoiceType}
                  lockRate={bids.lockRate}
                  statusOfInvoice={bids.statusOfInvoice}
                  requestAmount={bids.requestAmount}
                  dueDate={bids?.dueDate}
                  earlyPaymentDate={bids.earlyPaymentDate}
                  supplierName={bids.supplierName}
                  onCancel={() => setOngoingCancelBidModal(true)}
                  currentlyLeading={bids.currentlyLeading}
                />
              ))}
            </div>
          </div>
        </div>

        <div className="flex justify-center gap-x-[8px] mt-[20px]">
          {totalPages > 2 && (
            <Pagination
              page={page}
              startPage={startPage}
              setEndPage={setEndPage}
              endPage={endPage}
              setStartPage={setStartPage}
              setPage={setPage}
              totalPages={totalPages}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default OngoingBid;
