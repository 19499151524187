import React, { useEffect, useState } from "react";
import {  PAIconInventoryArrowLeft } from "assets/images/svgs";
import { Link, useNavigate, useParams } from "react-router-dom";
// import LoadingScreen from "shared/LoadingScreen";
import DashboardLayout from "layout/dashboardlayout";
import Button from "shared/Button";
import { getSingleEarlyPaymentOffer } from "appstate/api/invoice/invoice";

const SupplierEarlyPaymentAcceptedInvoice = () => {
  const navigate = useNavigate();
  const [offerDetails, setOfferDetails] = useState(null);

  const { id } = useParams();
  const offerId = id;

  useEffect(() => {
    const fetchOfferDetails = async () => {
      try {
        // setIsLoading(true);
        const response = await getSingleEarlyPaymentOffer(offerId);
        setOfferDetails(response?.data);
      } catch (error) {
        console.error("Error fetching offer details:", error);
      } finally {
        // setIsLoading(false);
      }
    };

    if (offerId) {
      fetchOfferDetails();
    }
  }, [offerId]);

  // if (isLoading) {
  //   return <LoadingScreen />;
  // }

  const formatAmount = (amount) => {
    if (typeof amount !== "number") return "0.00";
    return amount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  return (
    <DashboardLayout
      section="Payable Finance Program"
      subHeader="Here is an overview of your pfp"
    >
      <div>
        <Link
          onClick={() => navigate(-1)}
          className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
        >
          <PAIconInventoryArrowLeft />
          <span className="flex items-center">Go Back</span>
        </Link>
      </div>
      <div className="flex flex-col gap-8 pb-24 bg-white rounded-[10px] min-h-[80vh]">
        {/* first header */}
        <header className="px-6 border-b pt-[17px] rounded-t-[10px] border-[#B1A6B2] pb-[14px] bg-alabasterHeader">
          <div className="flex justify-between items-center">
            <h1 className="text-black font-medium">Invoices</h1>
            <div>
                <Button
                  neutral
                  className="flex text-[#F08000] text-xs font-medium items-center mb-[10px] md:mb-0 md:px-[5px] bg-[#A0D5B0] py-[12px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px]"
                >
                  <p style={{ color: "#07593D" }} className="whitespace-nowrap">
                    Accepted
                  </p>
                </Button>
              </div>
          </div>
        </header>

        <div className="px-6">
          <div className="flex flex-col gap-3">
            <header className="flex justify-between items-center">
              <h1 className="text-[18px] font-bold">
                Invoice | INV-
                {offerDetails?.invoiceNo}
              </h1>
            </header>

            <main className="w-full flex justify-between">
              <div className="flex flex-col gap-3 w-1/2">
                <div className="flex gap-x-[5px]">
                  <span>Reference No:</span>
                </div>
                <div className="flex flex-col gap-1">
                  <div className="flex gap-x-[50px]">
                    <span className="text-xs w-[97px]">Created Date:</span>
                    <span className="text-[#979797] text-xs"> {offerDetails?.createdDate}</span>
                  </div>
                  <div className="flex gap-x-[50px]">
                    <span className="text-xs w-[97px]">Submitted Date:</span>
                    <span className="text-[#979797] text-xs"> {offerDetails?.submittedDate}</span>
                  </div>
                  <div className="flex gap-x-[50px]">
                    <span className="text-xs w-[97px]">Approved Date:</span>
                    <span className="text-[#979797] text-xs"> {offerDetails?.approvedDate}</span>
                  </div>
                  <div className="flex gap-x-[50px]">
                    <span className="text-xs">Due Date:</span>
                    <span
                      style={{ marginLeft: 50 }}
                      className="text-[#979797] text-xs"
                    >
                       {offerDetails?.dueDate}
                    </span>
                  </div>
                  <div className="flex gap-x-[50px]">
                    <span className="text-xs w-[97px]">Issuance Date:</span>
                    <span className="text-[#979797] text-xs"> {offerDetails?.issuanceDate}</span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-between">
                <div className="text-sm flex flex-col justify-end items-end ">
                  <div className="flex flex-col gap-1">
                    <p className="font-bold">Payable Finance Offer</p>
                    <div className="flex justify-between">
                      <span className="text-xs ">Name of campaign:</span>
                      <span
                        style={{ marginLeft: 4 }}
                        className="text[#979797] text-xs"
                      >
                        {" "}
                        {offerDetails?.programName}
                      </span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-xs ">End Date:</span>
                      <span className="text[#979797] text-xs">
                        {" "}
                        {offerDetails?.endDate}
                      </span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-xs ">Discount Rate:</span>
                      <span className="text[#979797] text-xs"> {offerDetails?.discountRate}</span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-xs ">Discount Amount:</span>
                      <span className="text[#979797] text-xs">
                      {offerDetails?.discountAmount}
                      </span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-xs ">Receivable Amount:</span>
                      <span
                        style={{ marginLeft: 4 }}
                        className="text[#979797] text-xs"
                      >
                        {" "}
                        {offerDetails?.receivableAmount}
                      </span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-xs ">Payment Duration:</span>
                      <span className="text[#979797] text-xs flex justify-end">
                      {offerDetails?.paymentDuration}
                      </span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-xs ">Payment Date:</span>
                      <span className="text[#979797] text-xs flex justify-end">
                      {offerDetails?.paymentDate}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
          <br />
          <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
          <div className="flex justify-between">
            <div className="flex flex-col">
              <span className="font-light  text-xs">{offerDetails?.buyerCompanyName}</span>
              <span className="text-[#979797] text-sm">buyer details</span>
            </div>
            <div className="flex flex-col">
              <span className="font-light   text-xs">{offerDetails?.paymentDuration}</span>
              <span className="text-[#979797] text-sm">Payment</span>
            </div>
            <div className="flex flex-col">
            <span className="text-[#979797]  text-xs">{offerDetails?.currency}</span>
              <span className="font-light text-sm">Currency</span>
            </div>
          </div>
          </header>
          <br />
          <div className="flex flex-col gap-4">
            <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
              <h1 className="text-sm font-bold">Line Items</h1>
            </header>
            <div className="w-full overflow-x-auto">
              <table className="w-full overflow-x-auto">
                <thead className="bg-subtleGray w-5">
                  <tr className="bg-unusualGray p-2.5">
                    <td className="text-[#11192A] p-2.5 text-xs font-bold">
                      Item
                    </td>
                    <td className="p-2.5 text-xs font-bold">
                      Description
                    </td>
                    <td className="p-2.5 text-xs font-bold">
                      Unit Price
                    </td>
                    <td className="p-2.5 text-xs font-bold">Quantity</td>
                    <td className="p-2.5 text-xs font-bold">Amount (Before Tax)</td>
                    <td className="p-2.5 text-xs font-bold">Tax Rate</td>
                    <td className="p-2.5 text-xs font-bold">Tax Amount</td>
                    <td className="p-2.5 text-xs font-bold">Total Price</td>
                  </tr>
                </thead>
                <tbody>
                  {offerDetails?.lineItems?.map(
                    (item, index) => (
                      <tr key={index} className="py-[3px] px-1.5">
                        <td className="text-xs text-[#11192A] p-2.5">
                          {item.itemName}
                        </td>
                        <td className="text-xs text-[#11192A] p-2.5">
                          {item.description}
                        </td>
                        <td className="text-xs text-[#11192A] p-2.5">
                          {item.quantity}
                        </td>
                        <td className="text-xs text-[#11192A] p-2.5">
                          {formatAmount(item.unitPrice)}
                        </td>
                        <td className="text-xs text-[#11192A] p-2.5">
                          {formatAmount(item.amount)}
                        </td>
                        <td className="text-xs text-[#11192A] p-2.5">
                          {item.taxRate}%
                        </td>
                        <td className="text-xs text-[#11192A] p-2.5">
                          {item.taxAmount}
                        </td>
                        <td className="text-xs text-[#11192A] p-2.5">
                          {item.totalPrice}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="flex justify-between mx-auto w-[95%]">
              <div>
                <h4 className="text-lg text-[#11192A] ">
                  Receiving Funding Account Information
                </h4>
                <h5 className="text-lg text-[#11192A] ">{offerDetails?.virtualAccount?.accountNumber}</h5>
                <h5 className="text-lg text-[#11192A] ">{offerDetails?.virtualAccount?.accountName}</h5>
              </div>
              <div style={{ width: 300 }}>
                <div className="flex justify-between">
                  <span className="text-sm text-[#11192A] ">Subtotal</span>
                  <span className="text-sm text-[#11192A] ">{offerDetails?.subTotal}</span>
                </div>
                <div className="flex justify-between">
                  <div>
                    <span className="text-sm text-[#11192A] ">Vat % </span>
                    <input value={offerDetails?.vat} className="w-[120px] mt-2 ml-2 border border-[#CCCCCC] px-2 py-3 h-[8px] text-[12px]" />
                  </div>
                  <div>
                    <span className="text-sm text-[#11192A] ">{offerDetails?.vatAmount}</span>
                  </div>
                </div>
                <div className="flex justify-between">
                  <div>
                    <span className="text-sm text-[#11192A] ">Discount % </span>
                    <input value={offerDetails?.discount} className="w-[120px] mt-2 ml-2 border border-[#CCCCCC] px-2 py-3 h-[8px] text-[12px]" />
                  </div>
                  <div>
                    <span className="text-sm text-[#11192A] ">{offerDetails?.discountAmount}</span>
                  </div>
                </div>
                <div>
                  <hr className="border-t border-[black] mt-2 pt-2" />
                  <div className="flex justify-between">
                    <span className="text-lg text-[#11192A] ">Grand Total</span>
                    <span className="text-lg text-[#11192A] ">{offerDetails?.grandTotal}</span>
                  </div>
                  <hr className="border-t border-[black] mt-2 pt-2" />
                </div>
              </div>
            </div>

      </div>
    </DashboardLayout>
  );
};

export default SupplierEarlyPaymentAcceptedInvoice;
