import { useEffect, useState } from "react";
import emptyBid from "../../../../assets/images/svgs/empty-bid.png";
import FunderDetails from "features/openMarket/funder/components/modal/FunderDetails";
import { AnimatePresence } from "framer-motion";
import useCountdown from "utilities/helper/useCountdown";
import { bidHistory } from "appstate/openMarket/openMarketSlice";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { formatCapitaliseString } from "utilities/helper/formatCapitaliseString";
import { formatCapitalize } from "utilities/formatCapitalize";
import { PAIconInvoiceIcon, PAIconProfilePicSide } from "assets/images/svgs";

const BidDetails = ({ pending, paymentDate }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(bidHistory({ openMarketReference: id }));
  }, [dispatch, id]);

  const { bidHistoryData } = useSelector((state) => state?.openMarket);

  const [sortedBidHistory, setSortedBidHistory] = useState([]);

  // console.log(bidHistoryData);

  const dueDate = paymentDate;
  const { timeLeft, isTimeUnderOneHour } = useCountdown(dueDate);

  const [isActive, setIsActive] = useState("history");
  const [funderDetail, setFunderDetail] = useState(false);

  useEffect(() => {
    if (bidHistoryData?.bidHistory?.data) {
      // Create a shallow copy of the bid history and sort it
      const sortedData = [...bidHistoryData?.bidHistory?.data]?.sort(
        (a, b) => b?.isLeading - a?.isLeading
      );
      setSortedBidHistory(sortedData);
    }
  }, [bidHistoryData]);

  return (
    <div className="bg-white w-full rounded-[10px] pt-5 pb-24 h-[100%]">
      <div className="h-[15%]">
        <header className="flex px-6 justify-between ">
          <h2 className="text-lg  font-semibold text-gray-800 pb-4 border-b-[1px] border-b-slate-300 text-primaryColor">
            Bid details for this invoice
          </h2>

          <span>
            <section className={`flex ${isTimeUnderOneHour ? "text-red" : ""}`}>
              <div className="flex">
                <div className="flex items-center justify-center flex-col">
                  <span className="font-semibold">{timeLeft.days}</span>
                  <span className="text-xs">days</span>
                </div>
                <span>:</span>
              </div>

              <div className="flex">
                <div className="flex items-center justify-center flex-col">
                  <span className="font-semibold">{timeLeft.hours}</span>
                  <span className="text-xs">hours</span>
                </div>
                <span>:</span>
              </div>

              <div className="flex gap-0">
                <div className="flex items-center justify-center flex-col">
                  <span className="font-semibold">{timeLeft.minutes}</span>
                  <span className="text-xs">mins</span>
                </div>
                <span>:</span>
              </div>

              <div className="flex">
                <div className="flex  items-center justify-center flex-col">
                  <span className="font-semibold">{timeLeft.seconds}</span>
                  <span className="text-xs">secs</span>
                </div>
              </div>
            </section>
          </span>
        </header>

        <div className="flex gap-5 cursor-pointer text-sm text-gray-600 mt-4 px-6">
          <span
            onClick={() => {
              setIsActive("history");
            }}
            className={
              isActive === "history"
                ? "text-secondaryColor border-b-[1px] border-secondaryColor"
                : "text-[#959595]"
            }
          >
            Bid history: {bidHistoryData?.bidHistory?.data?.length}
          </span>
          {pending ? null : (
            <span
              onClick={() => {
                setIsActive("viewed");
              }}
              className={
                isActive === "viewed"
                  ? "text-secondaryColor border-b-[1px] border-secondaryColor"
                  : "text-[#959595]"
              }
            >
              Viewed by funders: {bidHistoryData?.viewedByFunders?.data?.length}
            </span>
          )}
        </div>
      </div>

      {isActive === "history" && (
        <div>
          {bidHistoryData?.bidHistory?.data?.length === 0 ? (
            <div className="flex flex-col justify-center items-center my-6 h-[65%]">
              <img src={emptyBid} alt="No Data" className="h-80 w-auto" />
              <div
                // className="text-center text-3xl font-bold text-gray800 mb-2"
                className={`text-3xl text-center font-bold mb-2 ${
                  isTimeUnderOneHour ? "text-red" : ""
                }`}
              >
                {timeLeft.days > 0 ||
                timeLeft.hours > 0 ||
                timeLeft.minutes > 0 ||
                timeLeft.seconds > 0 ? (
                  `${timeLeft.days}d:${timeLeft.hours}h:${timeLeft.minutes}m:${timeLeft.seconds}s`
                ) : (
                  <span className="text-red">Expired</span>
                )}
              </div>
              <p className="text-center text-gray-500 mb-4">
                Count down to payment date
              </p>
            </div>
          ) : (
            <div>
              {sortedBidHistory?.map((bid, index) => (
                <div
                  key={index}
                  className={`flex justify-between px-6 pb-1 my-6 cursor-pointer ${
                    index === 0
                      ? "bg-[#F1F9FF] border-b-[2px] border-r-[2px] border-l-[2px] border-t-[2px] rounded border-primaryColor relative"
                      : ""
                  }`}
                  // onClick={() => setBiddingDetail(true)}
                >
                  {bid?.isLeading && (
                    <div className="absolute top-[-10px] left-4 text-xs font-semibold text-primaryColor bg-white px-2">
                      Current winning bid
                    </div>
                  )}
                  <div className="flex gap-6 items-center w-[30%] mt-3">
                    <div className="flex flex-col gap-0.5">
                      <span
                        className={`text-xs font-[500] ${
                          index === 0 ? "text-primaryColor" : ""
                        }`}
                      >
                        {formatCapitalize(bid?.funderName)}
                      </span>
                      <span className="text-[10px] text-[#959595]">Funder</span>
                    </div>
                  </div>

                  <div className="flex gap-6 items-center w-[25%] mt-3">
                    <div className="flex flex-col gap-0.5">
                      <span className="text-xs font-[500]">
                        {bid?.discountRate?.toFixed(2)}% {" - "}
                        <span
                          className={`text-[8px] ${
                            bid.state === "Without Recourse"
                              ? "text-primaryColor"
                              : "text-secondaryColor"
                          }`}
                        >
                          {formatCapitaliseString(bid?.discountType)}
                        </span>
                      </span>
                      <span className="text-[10px] text-[#959595]">
                        Lock Rate
                      </span>
                    </div>
                  </div>
                  <div className="flex gap-6 items-center w-[20%] mt-3">
                    <div className="flex flex-col gap-0.5">
                      <span
                        className={`text-xs font-[500] ${
                          bid.status === "Locked"
                            ? "text-secondaryColor"
                            : "text-black"
                        } ${index === 0 ? "font-bold" : ""}`}
                      >
                        {bid?.status === "BID_ENDED"
                          ? formatCapitaliseString(bid?.status)
                          : `${formatCapitaliseString(bid?.status)}ed`}
                      </span>
                      <span className="text-[10px] text-[#959595]">Status</span>
                    </div>
                  </div>
                  <div className="flex gap-6 items-center w-[20%] mt-3">
                    <div className="flex flex-col gap-0.5">
                      <span className="text-xs font-[500]">
                        {bid?.lockDuration}
                      </span>
                      <span className="text-[10px] text-[#959595]">
                        Lock duration{" "}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      {isActive === "viewed" && (
        <div className="space-y-4">
          {bidHistoryData?.viewedByFunders?.data?.length === 0 ? (
            <div className="flex gap-1.5 flex-col justify-center font-medium min-h-[50vh] items-center">
              <PAIconInvoiceIcon className="animate-pulse" />
              <span className="font-semibold italic">
                No view by funder yet
              </span>
            </div>
          ) : (
            bidHistoryData?.viewedByFunders?.data?.map((item, index) => (
              <div
                key={index}
                className="flex justify-between shadow-sm items-center mb-4 cursor-pointer px-6"
                onClick={() => setFunderDetail(true)}
              >
                <div className="flex items-center my-2 gap-2">
                  <PAIconProfilePicSide />
                  <div>
                    <p className="text-[16px] font-regular text-gray-600">
                      {item?.funder || "N/A"}
                    </p>
                    <p className="text-[12px] font-regular text-zinc-400">
                      Funder
                    </p>
                  </div>
                </div>

                <div className="text-center">
                  <p className="text-[14px] ">{item?.phoneNo || "N/A"}</p>
                  <p className="text-[12px] text-zinc-400">Contact number</p>
                </div>

                <div className="text-right">
                  <p className="text-sm text-gray-600">
                    {item?.companyEmail || "N/A"}
                  </p>
                  <p className="text-[12px] text-zinc-400">Email</p>
                </div>
              </div>
            ))
          )}
        </div>
      )}

      <AnimatePresence>
        {funderDetail && (
          <FunderDetails onClose={() => setFunderDetail(false)} />
        )}
      </AnimatePresence>
    </div>
  );
};

export default BidDetails;
