import { PAIconArrowLeftGreen, PAIconModelOne } from "assets/images/svgs";
import React from "react";
// import { useLocation } from "react-router-dom";
import AcceptModal from "./acceptModal";
import ConfirmDiscountRateModal from "./ConfirmDiscountRateModal";

const Details = () => {
  // const location = useLocation();
  // const { fundingChannel } = location.state || {};
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isDiscountModalOpen, setIsDiscountModalOpen] = React.useState(false);

  const invoiceData = {
    items: [
      {
        itemName: "Wireless Keyboard",
        description: "Bluetooth-enabled ergonomic keyboard",
        unitPrice: 4500400.99,
        quantity: 2,
        amount: (45.99 * 2).toFixed(2),
        taxRate: "5%",
        taxAmount: (45.99 * 2 * 0.05).toFixed(2),
        totalPrice: (45.99 * 2 * 1.05).toFixed(2),
      },
      {
        itemName: "HD Monitor",
        description: "24-inch full HD display",
        unitPrice: 120000.5,
        quantity: 1,
        amount: (120.5).toFixed(2),
        taxRate: "8%",
        taxAmount: (120.5 * 0.08).toFixed(2),
        totalPrice: (120.5 * 1.08).toFixed(2),
      },
      {
        itemName: "Office Chair",
        description: "Adjustable mesh back office chair",
        unitPrice: 890000.75,
        quantity: 3,
        amount: (89.75 * 3).toFixed(2),
        taxRate: "6%",
        taxAmount: (89.75 * 3 * 0.06).toFixed(2),
        totalPrice: (89.75 * 3 * 1.06).toFixed(2),
      },
      {
        itemName: "HD Monitor",
        description: "24-inch full HD display",
        unitPrice: 120000.5,
        quantity: 1,
        amount: (120.5).toFixed(2),
        taxRate: "8%",
        taxAmount: (120.5 * 0.08).toFixed(2),
        totalPrice: (120.5 * 1.08).toFixed(2),
      },
    ],
  };

  const handleBackBtnClick = () => {
    window.history.back();
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleAcceptProceed = () => {
    setIsModalOpen(false);
    setIsDiscountModalOpen(true);
  };

  return (
    <>
      <div
        onClick={handleBackBtnClick}
        className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer w-full"
      >
        <PAIconArrowLeftGreen />
        <span className="flex items-center text-primaryColor">Go Back</span>
      </div>

      <div className="grid lg:grid-cols-[60%,40%] gap-5 h-[80vh]">
        {/* 60% */}
        <div className="flex flex-col gap-8 pb-24 bg-white rounded-[10px] pt-7">
          <div className="px-6 ">
            <div className="flex flex-col gap-3">
              <div className="flex items-center justify-between">
                <h1 className="text-[18px] font-bold">
                  Reference No: | RF6373645BA
                </h1>
                {/* {paymentViaWallet === true ? (
                  <button className="w-[135px] h-[45px] rounded-[5px] bg-[#07593D] text-[white]">
                    Disburse Now
                  </button>
                ) : null} */}
              </div>

              <main className=" w-full flex justify-between">
                <div className="flex flex-col gap-3 w-1/2">
                  <div className="flex flex-col gap-1">
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs font-semibold w-[30%]">
                        Invoice Number:
                      </span>
                      <span className="text-[#979797]  text-xs">INV-2002</span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs font-semibold w-[30%]">
                        Created Date:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        30th-May-2023
                      </span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs font-semibold w-[30%]">
                        Submitted Date:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        30th-May-2023
                      </span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs font-semibold w-[30%]">
                        Approved Date:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        30th-May-2023
                      </span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs font-semibold w-[30%]">
                        Due Date:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        30th-May-2023
                      </span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs font-semibold w-[30%]">
                        Issunance Date:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        30th-May-2023
                      </span>
                    </div>
                  </div>
                </div>
                <div className=" flex flex-col items-end">
                  <button
                    onClick={() => {
                      setIsModalOpen(true);
                    }}
                    className="w-[180px] h-[35px] bg-primaryColor rounded-[4px] text-white"
                  >
                    Import selected request
                  </button>

                  <div className="mt-5 items-end">
                    <button className="w-[120px] h-[35px] bg-[#D8EFD6] rounded-[4px] text-[#2EA923]">
                      Approved
                    </button>
                  </div>
                </div>
              </main>
            </div>

            <div className="flex justify-between my-5">
              <div className="flex flex-col">
                <span className=" font-bold text-sm">Nestle PLC</span>
                <span className="text-[#979797] text-xs">Buyer Details</span>
              </div>
              <div className="flex flex-col">
                <span className="font-bold text-sm">60 Days</span>
                <span className="text-[#979797] text-xs">Payment</span>
              </div>
              <div className="flex flex-col">
                <span className="font-bold text-sm">NGN</span>
                <span className=" text-[#979797] text-xs">Currency</span>
              </div>
            </div>

            <div className="flex flex-col gap-4 mt-5">
              <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
                <h1 className="text-sm font-bold">Line Items</h1>
              </header>
              <div className=" w-full overflow-x-auto">
                <table className="overflow-x-auto w-[60vw]">
                  <thead className="bg-subtleGray h-[70px] w-[50vw] overflow-x-auto">
                    <tr className=" bg-unusualGray p-2.5">
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Item
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Description
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Unit Price
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Quantity
                      </td>
                      <td className="text-[#11192A] p-2.5 text-xs font-bold w-[120px]">
                        Amount
                      </td>
                      <td className="text-[#11192A] p-2.5 text-xs font-bold w-[120px]">
                        Tax rate
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Tax Amount
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Total Price
                      </td>
                    </tr>
                  </thead>
                  {invoiceData?.items?.map((item, index) => (
                    <tbody key={index}>
                      <tr className=" py-[3px] px-1.5">
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.itemName}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5 w-[20%]">
                          {item?.description}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.unitPrice}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.quantity}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.amount}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.taxRate}
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            </div>

            <div className="flex itemscenter justify-between px-6 mt-[10%]">
              <div>
                <h1 className="border-b border-[#B1A6B2] font-semibold">
                  Receiving Funding Account Information
                </h1>
                <p className="mt-3 text[#979797]">7462383995</p>
                <p className="text[#979797]">Collins Eze</p>
              </div>
              <div className="flex flex-col">
                <div className="flex justify-between gap-x-[100px] w-full">
                  <span className="text-xs w-[97px]">Sub Total</span>
                  <span className="text-[#979797] text-xs text-right">
                    374293
                  </span>
                </div>

                <div className="flex justify-between w-full gap-x-[100px] my-2">
                  <span className="flex items-center text-xs w-[97px]">
                    Discount%
                    <span className="border border-[#979797] pr-10 pl-2 inline-block ml-4">
                      5%
                    </span>
                  </span>
                  <span className="text-[#979797] text-xs">90,900</span>
                </div>

                <div className="flex justify-between w-full gap-x-[100px] pb-5">
                  <span className="flex items-center text-xs w-[97px]">
                    VAT%
                    <span className="border border-[#979797] ml-10 pr-11 pl-2 inline-block">
                      5%
                    </span>
                  </span>
                  <span className="text-[#979797] text-xs">75000</span>
                </div>

                <div className="flex justify-between items-center w-full gap-x-[100px] border-t-2 border-b-2 font-bold text-lg py-1">
                  <span className="w-[97px]">Grand Total</span>
                  <span className="text[#979797] py-1">840000</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 40% */}
        <div className="bg-white rounded-[10px] gap-8 pt-5 pb-24">
          <div className="px-4">
            <h2 className="font-semibold text-lg leading-[30px] mb-4">
              Early Payment Details
            </h2>

            <div className="flex justify-between my-5">
              <div className="flex gap-2">
                <PAIconModelOne />
                <div className="flex flex-col">
                  <span className="text-sm">Supplier</span>
                  <span className="text-[#979797]  text-xs">
                    {" "}
                    {invoiceData?.supplierCompanyName}
                  </span>
                </div>
              </div>
              <div className="flex flex-col">
                <span className="text-sm">1.6%</span>
                <span className="text-[#979797]  text-xs">Discount rate</span>
              </div>
            </div>

            <div className="flex justify-between pb-5">
              <div className="flex gap-2">
                <div className="flex flex-col">
                  <span className="text-sm">
                    N{invoiceData?.total || "740,000"}
                  </span>
                  <span className="text-[#979797]  text-xs">
                    Discount Amount
                  </span>
                </div>
              </div>
              <div className="flex flex-col">
                <span className="text-sm">23-Dec-2024(90days)</span>
                <span className="text-[#979797]  text-xs">
                  Early Payment Request Date
                </span>
              </div>
            </div>

            <div className="flex justify-between mt-5 border-b-[5px] border-b-[#F6F5FA] pb-10">
              <div className="flex gap-2">
                <div className="flex flex-col">
                  <span className="text-sm">
                    N{invoiceData?.total || "12,740,000"}
                  </span>
                  <span className="text-[#979797]  text-xs">
                    Request Amount
                  </span>
                </div>
              </div>
              <div className="flex flex-col">
                <span className="text-sm">12,000,900</span>
                <span className="text-[#979797]  text-xs">Payable amount</span>
              </div>
            </div>

            <div className="border-b-[5px] border-b-[#F6F5FA] pb-5">
              <h2 className="font-medium text-base my-3">
                Note from Supplier:
              </h2>
              <p className="text-[#959595] text-sm">
                Seeking Early payment due to unexpected challenges. Facing
                temporary financial constraints and need a bit more time to
                ensure a smooth transaction. Appreciate your understanding.
                Thank you. Best regards, ABC Limited
              </p>
            </div>
          </div>

          <AcceptModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            confirm={handleAcceptProceed}
            // checkedAccounts={checkedAccounts}
          />

          <ConfirmDiscountRateModal
            isOpen={isDiscountModalOpen}
            onClose={() => setIsDiscountModalOpen(false)}
            onNext={() => alert("Next step triggered")}
          />
        </div>
      </div>
    </>
  );
};

export default Details;
