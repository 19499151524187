import React, { useEffect, useState } from "react";
import {
  PAIconDownload,
  PAIconIconParkSucess,
  PAIconInfoGreen,
  PAIconInvoiceIcon,
  PAIconMDISuccess,
  PAIconSearchGreen,
} from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import * as XLSX from "xlsx";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";
import Table from "layout/table/Table";

import { useDispatch, useSelector } from "react-redux";
// import { allDirectFunding } from "appstate/invoice/invoiceSlice";

import Pagination from "shared/Pagination";
import Loading from "shared/Loading";
// import DateRangePickerModal from "shared/datePicker/DateRangePicker";
import ViewDocumentModal from "features/RulesConfig/common/components/modals/DeleteRuleModal";
import ViewFundInvoiceModal from "features/funderpayablefinance/components/modals/ViewFundInvoiceModal";
// import TransferFundsModal from "features/disbursement/common/modal/TransferFundsModal";
import EnterPin from "features/disbursement/common/modal/Pin";
import LienApproveCard from "features/disbursement/common/components/LienApproveCard";
import UpcomingPayment from "features/disbursement/common/components/UpcomingPayment";
import SelectedRequests from "features/disbursement/common/components/SelectedRequests";
import TooltipShared from "shared/tooltip/Tooltip";
import { formatCapitalize } from "utilities/formatCapitalize";
import { formatCurrency } from "utilities/helper/formatters";
import {
  disbursementFunderPayNow,
  disbursementProgramOverview,
} from "appstate/disbursement/disbursementSlice";
import toast from "react-hot-toast";
import ProgressModal from "shared/progressModal/ProgressModal";
import TransferInfoReceipt from "features/wallet/withdraw/receipt";
import useCountdown from "utilities/helper/useCountdown";
import { formatDate } from "utilities/helper/dateFormatterSpelt";
import MultipleTransferReceipt from "features/disbursement/common/modal/MultipleTransferReceipt";
import { clearSelectedData, updateSelectedData } from "../selectedDataSlice";

const DirectFunding = ({
  page,
  setPage,
  setActiveTab,
  selectedVAId,
  setSelectedVAId,
  directFundingState,
  setDirectFundingState,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { invoiceByIdData } = useSelector((state) => state?.openMarket);

  const {
    disbursementProgramOverviewData,
    disbursementFunderReceiptData,
    isLoading,
  } = useSelector((state) => state?.disbursement);
  const [multipleReceiptModal, setMultipleReceiptModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState("single");
  const [dateLoading, setDateLoading] = useState(false);
  const [singleId, setSingleId] = useState([]);
  const [singlePay, setSinglePay] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [bulkReceipt, setBulkReceipt] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  let pageSize = 10;

  const [acceptPaymentModal, setAcceptPaymentModal] = useState(false);
  const [selectAllText, setSelectAllText] = useState("Select All");
  const [pinModal, setPinModal] = useState(false);
  const [selectDownloadType, setSelectDownloadType] = useState(false);
  const [makePaymentModal, setMakePaymentModal] = useState(false);

  const [otpInputs, setOtpInputs] = useState(["", "", "", ""]);
  const [hidePin, setHidePin] = useState(false);
  const [showReceipt, setShowReceipt] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);

  const directFundingData = {
    data: [
      {
        id: 1,
        refNo: "R3456X89Y",
        invoiceNumber: "2090",
        buyerCompanyName: "Access Bank",
        dueDate: "2023-01-12",
        paymentDate: "2023-07-12",
        currency: "NGN",
        invNo: "INV-2002",
        earlyDueDate: "00d:13h:55m:32s",
        invoiceAmount: 9000000,
        discountAmount: 950000,
        discountRate: "1.5%",
        fundableAmount: 8150000,
      },

      {
        id: 2,
        refNo: "R3456Y89Z",
        invoiceNumber: "2080",
        buyerCompanyName: "Providus Bank",
        dueDate: "2023-01-12",
        paymentDate: "2023-07-12",
        currency: "NGN",
        invNo: "INV-2002",
        earlyDueDate: "00d:13h:55m:32s",
        invoiceAmount: 9000000,
        discountAmount: 90000,
        discountRate: "1.5%",
        fundableAmount: 1350000,
      },

      {
        id: 3,
        refNo: "R3456X89Y",
        invoiceNumber: "2090",
        buyerCompanyName: "Access Bank",
        dueDate: "2023-01-12",
        paymentDate: "2023-07-12",
        currency: "NGN",
        invNo: "INV-2002",
        earlyDueDate: "00d:13h:55m:32s",
        invoiceAmount: 9000000,
        discountAmount: 950000,
        discountRate: "1.5%",
        fundableAmount: 8150000,
      },

      {
        id: 4,
        refNo: "R3456X89Y",
        invoiceNumber: "2090",
        buyerCompanyName: "Access Bank",
        dueDate: "2023-01-12",
        paymentDate: "2023-07-12",
        currency: "NGN",
        invNo: "INV-2002",
        earlyDueDate: "00d:13h:55m:32s",
        invoiceAmount: 9000000,
        discountAmount: 950000,
        discountRate: "1.5%",
        fundableAmount: 8150000,
      },
    ],
  };

  const [searchQuery, setSearchQuery] = useState("");
  // const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(10);

  const formatAmount = (amount) => {
    if (!amount) return "0.00";
    const parsedAmount = parseFloat(amount);
    if (isNaN(parsedAmount)) return "0.00";
    return parsedAmount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const handleSearchInputChange = (event) => {
    const value = event.target.value;
    setSearchQuery(value);
    sessionStorage.setItem("searchQueryFunder", value);
  };

  // SEND REMAINDER BUTTON
  const handleSendReminder = () => {
    setMakePaymentModal(true);
  };

  // CHECKBOX
  const handleCheckboxChange = (row) => {
    const isSelected = selectedRows.includes(row.id);
    let newSelectedRows = [];

    if (isSelected) {
      newSelectedRows = selectedRows.filter((id) => id !== row.id);
    } else {
      newSelectedRows = [...selectedRows, row.id];
    }

    setSelectedRows(newSelectedRows);
  };

  useEffect(() => {
    setDateLoading(true);
    setTimeout(() => setDateLoading(false), 1000);
  }, []);

  const { timeLeft, isTimeUnderOneHour } = useCountdown(
    formatDate(
      disbursementProgramOverviewData?.requests?.data?.earlyPaymentDate
    )
  );

  useEffect(() => {
    dispatch(
      disbursementProgramOverview({
        programType: directFundingState,
        virtualAccountId: selectedVAId || "",
        page,
        pageSize,
      })
    );
  }, [dispatch, selectedVAId, page, pageSize, directFundingState]);

  // SELECT ALL
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
      setSelectedRowsData([]);
      setSelectAllText("Select All");
      dispatch(clearSelectedData());
    } else {
      const allIds =
        selectedTab === "single"
          ? disbursementProgramOverviewData?.requests?.data?.map((row) =>
              directFundingState ? row.id : row?.earlyPaymentId
            )
          : disbursementProgramOverviewData?.bulkRequests?.data?.map((row) =>
              directFundingState ? row.id : row?.earlyPaymentId
            );

      const allIdsData =
        selectedTab === "single"
          ? disbursementProgramOverviewData?.requests?.data?.map((row) => row)
          : disbursementProgramOverviewData?.bulkRequests?.data?.map(
              (row) => row
            );
      setSelectedRows(allIds);
      setSelectedRowsData(allIdsData);

      setSelectAllText("Deselect All");
    }
    setSelectAll(!selectAll);
    // setDisabled(selectAll);
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(
      directFundingData.data.map((row) => ({
        "Program Ref No": row?.refNo,
        "Invoice Number": `INV-${row?.invoiceNumber}`,
        Buyer: row?.buyerCompanyName,
        "Due Date": row?.dueDate,
        "Payment Date": row?.paymentDate,
        Currency: row?.currency,
        "Invoice Amount": formatAmount(row?.invoiceAmount),
        "Discount Amount": formatAmount(row?.discountAmount),
        "Discount Rate": formatAmount(row?.discountRate),
        "Fundable Amount": formatAmount(row?.fundableAmount),
      }))
    );
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Direct Funding");
    XLSX.writeFile(wb, "DirectFundingData.xlsx");
  };

  // Here
  const handleDownloadSuccessful = () => {
    toast.custom(
      <div className="flex-1 items-center justify-center flex w-full h-full">
        <div className="flex mt-[300px]">
          <div className="bg-primaryColor w-[147.79px] h-[160px] flex flex-col items-center justify-center rounded-l-[7px] shadow-lg">
            <PAIconMDISuccess />
          </div>
          <div className="bg-white flex-col justify-center items-center self-stretch py-[25px] h-[160px] rounded-r-[7px] shadow-lg flex px-16 ">
            <PAIconIconParkSucess />
            <p className="text-dustyGray text-[13px] text-center mt-[17.6px]">
              Funds disbursed successfully
            </p>
          </div>
        </div>
      </div>
    );

    setTimeout(() => {
      toast.dismiss();
      bulkReceipt ? setMultipleReceiptModal(true) : setShowReceipt(true);
      setPinModal(false);
      setHidePin(false);
      setBulkReceipt(false);
    }, 500);
  };

  const handlePayNow = (pin) => {
    dispatch(updateSelectedData(selectedRowsData));
    const pendingToast = toast.loading("Processing your Payment...");
    setBulkReceipt(true);
    const body = {
      transactionPin: pin,
      invoiceIds: [...selectedRows],
      virtualAccountId: selectedVAId,
      disbursementType:
        directFundingState === "direct-funding-request"
          ? "DIRECT_FUNDING"
          : "DF_EPR",
    };

    dispatch(disbursementFunderPayNow(body)).then((data) => {
      if (data?.payload?.success) {
        toast.dismiss(pendingToast);
        setHidePin(true);
        setLoading(true);
        setPaymentModal(true);
        setTimeout(() => {
          setLoading(false);
          setTimeout(() => {
            setPaymentModal(false);
            handleDownloadSuccessful();
          }, 300);

          dispatch(
            disbursementProgramOverview({
              programType: directFundingState,
              virtualAccountId: selectedVAId || "",
            })
          );
          setSinglePay(false);
        }, 3000);
      } else {
        toast.dismiss(pendingToast);
        dispatch(clearSelectedData());
        return toast.error(data?.payload?.message);
      }
    });
  };

  const handlePayNowSingleDF = (pin) => {
    const pendingToast = toast.loading("Processing your Payment...");
    const body = {
      transactionPin: pin,
      invoiceIds: [...singleId],
      virtualAccountId: selectedVAId,
      disbursementType:
        directFundingState === "direct-funding-request"
          ? "DIRECT_FUNDING"
          : "DF_EPR",
    };

    dispatch(disbursementFunderPayNow(body)).then((data) => {
      if (data?.payload?.success) {
        toast.dismiss(pendingToast);
        setHidePin(true);
        setLoading(true);
        setPaymentModal(true);
        setTimeout(() => {
          setLoading(false);
          setTimeout(() => {
            setPaymentModal(false);
            handleDownloadSuccessful();
          }, 300);

          dispatch(
            disbursementProgramOverview({
              programType: "direct-funding-request",
              virtualAccountId: selectedVAId || "",
            })
          );
          setSinglePay(false);
        }, 3000);
      } else {
        toast.dismiss(pendingToast);
        return toast.error(data?.payload?.message);
      }
    });
  };

  const columns = [
    {
      name: (
        <input type="checkbox" onChange={handleSelectAll} checked={selectAll} />
      ),
      cell: (row) => (
        <input
          type="checkbox"
          onChange={() => handleCheckboxChange(row)}
          checked={selectedRows.includes(row?.id)}
        />
      ),
      width: "45px",
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />{" "}
          {/* Program */}
          Request Ref No.
        </span>
      ),
      cell: (row) => (
        <div className="relative group overflow-visible z-[999999999]">
          <TooltipShared
            text={row?.requestNo}
            maxLength={10}
            textSize="text-xs"
            highlight="text-xs"
          />
        </div>
      ),
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />{" "}
          {/* Program */}
          Invoice Ref.
        </span>
      ),
      selector: (row) => `INV-${row?.invoiceNo}`,
      sortable: true,
    },

    {
      name: "Supplier",
      selector: (row) => formatCapitalize(row?.supplierName),
      sortable: true,
      width: "6.5rem",
    },
    {
      name: "Due Date",
      selector: (row) => row?.dueDate,
      sortable: true,
      width: "7rem",
    },
    {
      name: "Inv Amount",
      selector: (row) => row?.invoiceAmount,
      sortable: true,
      cell: (row) => (
        <p className=" text-[#2EA923]">{formatAmount(row?.invoiceAmount)}</p>
      ),
      width: "8rem",
    },
    {
      name: "Dis. Amount",
      selector: (row) => row?.discountAmount,
      sortable: true,
      cell: (row) => (
        <p className=" text-secondaryColor">
          {formatAmount(row?.discountAmount)}
        </p>
      ),
      // width: "9rem",
    },
    {
      name: "Fundable Amount",
      selector: (row) => row?.payableAmount,
      sortable: true,
      cell: (row) => (
        <p className=" text-primaryColor">
          {formatAmount(row?.discountAmount)}
        </p>
      ),
      width: "8rem",
    },
    {
      name: "Pymt Date",
      selector: (row) => row?.earlyPaymentDate,
      sortable: true,
      cell: (row) => (
        <span
          className={`${
            isTimeUnderOneHour ? "text-red" : "text-secondaryColor"
          }`}
        >
          {timeLeft.days > 0 ||
          timeLeft.hours > 0 ||
          timeLeft.minutes > 0 ||
          timeLeft.seconds > 0 ? (
            `${timeLeft.days}d:${timeLeft.hours}h:${timeLeft.minutes}m:${timeLeft.seconds}s`
          ) : dateLoading ? (
            <span className="text-secondaryColor">Fetching...</span>
          ) : (
            <span className="text-red">Expired</span>
          )}
        </span>
      ),
      width: "8rem",
    },
    {
      name: "Action",
      cell: (row) => (
        <motion.button
          onClick={() => {
            setSinglePay(true);
            setBulkReceipt(false);
            setSelectAll(false);
            setSingleId(
              directFundingState === "direct-funding-request"
                ? [row?.id]
                : [row?.earlyPaymentId]
            );
            setSelectAllText("Select All");
            setAcceptPaymentModal(true);
          }}
          whileTap={{ scale: 0.95 }}
          className="py-2 px-4 text-xs  text-white rounded bg-primaryColor"
        >
          Pay Now
        </motion.button>
      ),
    },
  ];

  const columnBulk = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />{" "}
          {/* Program */}
          Request Ref No.
        </span>
      ),
      cell: (row) => (
        <div className="relative group overflow-visible z-[999999999]">
          <TooltipShared
            text={row?.requestNo}
            maxLength={10}
            textSize="text-xs"
            highlight="text-xs"
          />
        </div>
      ),
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />{" "}
          {/* Program */}
          Invoice Ref.
        </span>
      ),
      selector: (row) => `INV-${row?.invNo || "0000"}`,
      sortable: true,
    },

    {
      name: "Supplier",
      selector: (row) => formatCapitalize(row?.supplierName),
      sortable: true,
    },
    {
      name: "No of Invoices",
      selector: (row) => row?.noOfInvoices,
      sortable: true,
    },
    {
      name: "Total Payable Amount",
      selector: (row) => formatCurrency(row?.totalPayableAmount ?? 0),
      sortable: true,
      cell: (row) => (
        <p className=" text-[#2EA923]">
          {formatAmount(row?.totalPayableAmount ?? 0)}
        </p>
      ),
    },
    {
      name: "Total Discount Amount",
      selector: (row) => formatCurrency(row?.totalDiscountAmount),
      sortable: true,
      cell: (row) => (
        <p className=" text-secondaryColor">
          {formatCurrency(row?.totalDiscountAmount ?? 0)}
        </p>
      ),
    },

    {
      name: "Payment Date",
      selector: (row) => row?.earlyPaymentDate,
      sortable: true,
      cell: (row) => (
        <p className=" text-secondaryColor">{row?.earlyPaymentDate || "N/A"}</p>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <motion.button
          onClick={() => {
            setSinglePay(true);
            setBulkReceipt(false);
            setSelectAll(false);
            setSingleId(
              directFundingState === "direct-funding-request"
                ? [row?.id]
                : [row?.earlyPaymentId]
            );
            setSelectedRows([]);
            setSelectAllText("Select All");
            setAcceptPaymentModal(true);
          }}
          whileTap={{ scale: 0.95 }}
          className="py-2 px-4 text-xs  text-white rounded bg-primaryColor"
        >
          Pay Now
        </motion.button>
      ),
    },
  ];

  return (
    <>
      <section className="flex justify-end">
        <div className="w-full flex mdw-1/2 lg:w-1/3 items-center gap-2">
          <div className="flex gap-2.5 w-full bg-white border-[0.5px] border-[#ccc] p-2.5 items-center rounded-[5px]">
            <PAIconSearchGreen />
            <input
              type="text"
              placeholder="Search for invoices on the open market"
              value={searchQuery}
              onChange={handleSearchInputChange}
              className="p-2 w-full text-xs flex items-center text-black placeholder:text-black focus:outline-none"
            />
          </div>
        </div>
      </section>

      <section className="mt-6 flex-col lg:flex-row flex gap-5 justify-between">
        <LienApproveCard
          setSelectedVAId={setSelectedVAId}
          sectAValue={disbursementProgramOverviewData?.lienAmount}
          sectBValue={disbursementProgramOverviewData?.totalApprovedEPR}
        />
        {selectAll ? (
          <SelectedRequests
            amountText="Fundable Amount"
            handleMakePayment={handleSendReminder}
          />
        ) : (
          <UpcomingPayment
            amountText="Fundable Amount"
            handleMakePayment={() => {
              setSinglePay(true);
              setSingleId([
                disbursementProgramOverviewData?.upcomingPaymentDetails
                  ?.offerId,
              ]);
              setSelectAll(false);
              setSelectedRows([]);
              setSelectAllText("Select All");
              setAcceptPaymentModal(true);
            }}
            duedate={
              disbursementProgramOverviewData?.upcomingPaymentDetails
                ?.paymentDate
            }
            playerName={
              disbursementProgramOverviewData?.upcomingPaymentDetails
                ?.supplierName
            }
            payableAmount={
              disbursementProgramOverviewData?.upcomingPaymentDetails
                ?.payableAmount
            }
          />
        )}
      </section>

      {/* TABLE */}
      <div>
        {!directFundingData?.data?.length ? (
          <div>
            <div className="flex justify-between items-center px-[15px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
              <p className="text-black text-sm font-medium">
                Due Invoices from Direct Funding
              </p>
            </div>

            <TableEmptyState dashboard />
          </div>
        ) : (
          <Card className="rounded-[10px] min-h-[80vh] mt-[15px]">
            <div className="flex justify-between items-center p-[20px] bg-alabasterHeader rounded-t-[10px]">
              <div className="flex gap-6 items-center">
                <div>
                  <p className="text-black text-lg font-bold">
                    {directFundingState === "direct-funding-request"
                      ? "Direct Funding"
                      : "Early Payment Request"}
                  </p>
                  <span className="text-xs font-thin">
                    List of all approved{" "}
                    {directFundingState === "direct-funding-request"
                      ? "Direct Funding"
                      : "Early Payment "}{" "}
                    Request from suppliers.
                  </span>
                </div>

                <div>
                  <select
                    value={directFundingState}
                    onChange={(e) => setDirectFundingState(e.target.value)}
                    className="text-xs border border-[#DEDEDEC7] outline-none rounded-[5px] px-5 py-3 cursor-pointer"
                  >
                    <option value="direct-funding-request">
                      Direct Funding Request
                    </option>
                    <option value="early-payment-request">
                      Early Payment Request
                    </option>
                  </select>
                </div>
              </div>

              {selectedTab === "single" && (
                <div className="flex items-center justify-center gap-3">
                  <div
                    className=" text-xs rounded-md py-2.5 px-4 text-white bg-[#2EA923] cursor-pointer"
                    onClick={handleSelectAll}
                    // checked={selectAll}
                  >
                    <p>{selectAllText}</p>
                  </div>
                  <button
                    style={{
                      boxShadow: "2px 3px 10px 0px rgba(0, 0, 0, 0.10)",
                    }}
                    className={`text-xs  rounded-md py-2.5 px-4 text-white ${
                      selectedRows.length === 0
                        ? "cursor-not-allowed bg-[#C2C0C0]"
                        : "cursor-pointer bg-secondaryColor"
                    }`}
                    disabled={selectedRows.length === 0}
                    onClick={() => {
                      setBulkReceipt(true);
                      setSinglePay(false);
                      setAcceptPaymentModal(true);
                    }}
                  >
                    <p>Make Payment</p>
                  </button>
                </div>
              )}
            </div>
            <div className="pb-3">
              <div className="pb-3">
                <div className="flex px-[24px] gap-5 my-5">
                  <p
                    className={`cursor-pointer text-sm font-bold relative ${
                      selectedTab === "single"
                        ? "text-[#F08000]"
                        : "text-[#959595]"
                    }`}
                    onClick={() => setSelectedTab("single")}
                  >
                    Single Request
                    {selectedTab === "single" && (
                      <span className="absolute bottom-0 left-1/2 -translate-x-1/2 bg-[#F08000] h-0.5 w-5"></span>
                    )}
                  </p>

                  <p
                    className={`cursor-pointer text-sm font-bold relative ${
                      selectedTab === "bulk"
                        ? "text-[#F08000]"
                        : "text-[#959595]"
                    }`}
                    onClick={() => setSelectedTab("bulk")}
                  >
                    Bulk Request
                    {selectedTab === "bulk" && (
                      <span className="absolute bottom-0 left-1/2 -translate-x-1/2 bg-[#F08000] h-0.5 w-5"></span>
                    )}
                  </p>
                </div>
                {selectedTab === "bulk" ? (
                  <Table
                    columns={columnBulk}
                    data={disbursementProgramOverviewData?.bulkRequests?.data}
                    onRowClicked={(row) => {
                      navigate(
                        `funder/disbursement/directfunding/bulk/${
                          directFundingState === "direct-funding-request"
                            ? row?.id
                            : row?.earlyPaymentId
                        }?VAId=${selectedVAId}&channel=${directFundingState}`
                      );
                    }}
                    progressPending={isLoading}
                    progressComponent={<Loading />}
                    pointer
                    tableHeader
                    className="mt-5"
                  />
                ) : (
                  <Table
                    columns={columns}
                    data={disbursementProgramOverviewData?.requests?.data}
                    onRowClicked={(row) =>
                      navigate(
                        `funder/disbursement/directfunding/${
                          directFundingState === "direct-funding-request"
                            ? row?.id
                            : row?.earlyPaymentId
                        }?invId=${
                          row?.invoiceId
                        }&VAId=${selectedVAId}&channel=${directFundingState}`
                      )
                    }
                    progressPending={isLoading}
                    progressComponent={<Loading />}
                    pointer
                    tableHeader
                    className="mt-5"
                  />
                )}
                <div className="flex justify-center gap-x-[8px] mt-[20px]">
                  {selectedTab === "single"
                    ? disbursementProgramOverviewData?.requests?.meta
                        ?.totalElements > pageSize && (
                        <Pagination
                          page={page}
                          startPage={startPage}
                          setEndPage={setEndPage}
                          endPage={endPage}
                          setStartPage={setStartPage}
                          setPage={setPage}
                          totalPages={
                            disbursementProgramOverviewData?.requests?.meta
                              ?.totalElements
                          }
                        />
                      )
                    : disbursementProgramOverviewData?.bulkRequests?.meta
                        ?.totalElements > pageSize && (
                        <Pagination
                          page={page}
                          startPage={startPage}
                          setEndPage={setEndPage}
                          endPage={endPage}
                          setStartPage={setStartPage}
                          setPage={setPage}
                          totalPages={
                            disbursementProgramOverviewData?.bulkRequests?.meta
                              ?.totalElements
                          }
                        />
                      )}
                </div>
              </div>
            </div>
          </Card>
        )}
      </div>

      {/* Accept Modal */}
      <AnimatePresence>
        {selectDownloadType && (
          <ViewDocumentModal
            icon={<PAIconInvoiceIcon className=" animate-pulse" />}
            onAction={exportToExcel}
            onClose={() => setSelectDownloadType(false)}
          >
            <span className=" text-center">
              Would you like to proceed with sending a{" "}
              <span className="font-bold text-center text-primaryColor">
                Pay
              </span>{" "}
              this invoice list
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>

      {makePaymentModal && (
        <ViewFundInvoiceModal>
          <div className="flex w-full max-w-[650px] sm:max-w-[90%] md:max-w-[680px] overflow-auto h-[87vh] px-4 sm:px-6 flex-col gap-4 z-[9999]">
            <header className="flex sticky top-0 bg-white py-3  items-center justify-between">
              <span className=" text-sm">Invoice Disbursement</span>

              <motion.button
                onClick={() => {
                  setMakePaymentModal(false);
                }}
                whileTap={{ scale: 0.9 }}
                className="text-xs border border-primaryColor py-2 px-8  rounded-[5px] text-primaryColor"
              >
                Close
              </motion.button>
            </header>

            <main className="flex flex-col  gap-6">
              <section className="flex gap-28 justify-between">
                <div className="flex flex-col gap-2">
                  <span className="text-[#222] text-[20px] font-bold">
                    Multiple Single invoice payment
                  </span>

                  <div className=" text-xs">
                    <span className=" text-[#7A7A7A]">78 Invoices</span> Invoice
                    No : | Buyer Info{" "}
                    <span className=" text-[#7A7A7A]">Walediwura</span>
                  </div>
                </div>

                <div className="flex items-center">
                  <motion.button
                    whileTap={{ scale: 0.9 }}
                    onClick={() => {
                      setMakePaymentModal(false);
                      setAcceptPaymentModal(true);
                    }}
                    className="text-xs bg-primaryColor py-2 px-8  rounded-[5px] text-white"
                  >
                    Pay Now
                  </motion.button>
                </div>
              </section>

              <section className="flex gap-28 justify-between">
                <div className="flex flex-col">
                  <div>
                    <span>Payment Amount</span>
                  </div>
                  <span className="text-primaryColor text-[20px] font-bold">
                    NGN 1,890,400
                  </span>
                </div>

                <div className="flex flex-col gap-4 items-end">
                  <span className=" text-xs">
                    Total Invoice Amount &nbsp;
                    <span className="text-primaryColor border-b pb-0.5 border-b-[#AFAFAF]">
                      N2,345,000
                    </span>
                  </span>

                  <span className="border-b pb-0.5 text-xs  border-b-[#AFAFAF] w-fit">
                    Discount: 1.67%
                  </span>

                  <span className="text-[10px] font-medium flex items-center gap-1 text-[#F08000]">
                    <PAIconInfoGreen />
                    Tap to view more
                  </span>
                </div>
              </section>

              <section className=" flex flex-col ">
                <header className=" pb-3 font-bold border-b-[5px] mb-4 border-[#F6F5FA] text-[18px]">
                  Buyer Bulk Invoice details
                </header>

                <main className="flex pb-4 justify-between">
                  <div className="flex flex-col">
                    <span className="text-sm font-medium">Supplier</span>

                    <span className="text-xs text-[#7A7A7A]">
                      ABC Cooperation
                    </span>
                  </div>

                  <div className="flex flex-col">
                    <span className="text-sm font-medium">Buyer</span>

                    <span className="text-xs text-[#7A7A7A]">
                      Big Buyer Coop
                    </span>
                  </div>
                </main>

                <div>
                  <header className=" pb-3 font-bold border-b-[5px] mb-4 border-[#F6F5FA] text-sm">
                    Line Items
                  </header>

                  <div className=" w-full overflow-x-auto">
                    {invoiceByIdData ? (
                      <table className=" w-full overflow-x-auto">
                        <thead className="bg-subtleGray w-">
                          <tr className=" bg-unusualGray p-2.5 ">
                            <td className="text-[#11192A] p-2.5 text-xs font-bold">
                              Item Number
                            </td>
                            <td className=" p-2.5 text-xs font-bold">
                              PO Number
                            </td>
                            <td className=" p-2.5 text-xs font-bold">
                              Product/Service Number
                            </td>
                            <td className=" p-2.5 text-xs font-bold">
                              Product/Service Description
                            </td>
                            <td className=" p-2.5 text-xs font-bold">
                              Quantity
                            </td>
                            <td className=" p-2.5 text-xs font-bold">
                              Unit Price
                            </td>
                            <td className=" p-2.5 text-xs font-bold">
                              Line Item Extended Amount
                            </td>
                          </tr>
                        </thead>

                        <tbody>
                          {invoiceByIdData?.data?.items?.map((item) => (
                            <tr
                              key={item?.itemName}
                              className=" py-[3px] px-1.5"
                            >
                              <td className=" text-xs text-[#11192a] p-2.5">
                                INV-{invoiceByIdData?.data?.invoiceNumber}
                              </td>
                              <td className=" text-xs text-[#11192a] p-2.5">
                                PO-2002
                              </td>
                              <td className=" text-xs text-[#11192a] p-2.5">
                                {item?.itemName}
                              </td>
                              <td className=" text-xs text-[#11192a] p-2.5">
                                {item?.description}
                              </td>
                              <td className=" text-xs text-[#11192a] p-2.5">
                                {item?.quantity}
                              </td>
                              <td className=" whitespace-nowrap text-xs text-[#11192a] p-2.5">
                                {invoiceByIdData?.data?.currency === "NGN"
                                  ? "₦"
                                  : "$"}{" "}
                                {item?.unitPrice}
                              </td>
                              <td className=" text-xs text-[#11192a] p-2.5">
                                {invoiceByIdData?.data?.currency === "NGN"
                                  ? "₦"
                                  : "$"}{" "}
                                {item?.amount}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <div className="flex flex-col gap-3 animate-pulse justify-center items-center w-full py-6 px-4 text-sm font-bold">
                        <PAIconInvoiceIcon />
                        No invoice list available
                      </div>
                    )}
                  </div>

                  <div className="flex py-5 flex-col gap-4">
                    <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
                      <h1 className="text-sm font-bold">Attachments</h1>
                    </header>

                    <div className="w-full overflow-x-auto">
                      <table className="w-full overflow-x-auto">
                        <thead className="  bg-subtleGray w-">
                          <tr className="px-4  bg-unusualGray p-2.5 ">
                            <td className="text-[#11192A] px-4 py-2.5 text-xs font-bold">
                              Document Name
                            </td>
                            <td className=" px-4 py-2.5 text-xs font-bold">
                              File Type
                            </td>
                            <td className=" px-4 py-2.5 text-xs font-bold">
                              Upload Date / Time
                            </td>
                            <td className=" px-4 py-2.5 text-xs font-bold">
                              Action
                            </td>
                          </tr>
                        </thead>

                        <tbody>
                          <tr className=" py-[3px] px-1.5">
                            <td className=" text-xs text-[#0076EC] px-4 py-2.5">
                              DOC-29384.pdf
                            </td>
                            <td className=" text-xs text-[#11192a] px-4 py-2.5">
                              PDF
                            </td>
                            <td className=" text-xs text-[#11192a] px-4 py-2.5">
                              2021-09-14 13:25
                            </td>
                            <td className="cursor-pointer text-xs text-[#11192a] px-4 py-2.5">
                              <PAIconDownload />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </section>
            </main>
          </div>
        </ViewFundInvoiceModal>
      )}

      <AnimatePresence>
        {acceptPaymentModal && (
          <ViewDocumentModal
            onAction={() => {
              setAcceptPaymentModal(false);
              setMakePaymentModal(false);
              setPinModal(true);
              // setTransferModal(true);
            }}
            onClose={() => setAcceptPaymentModal(false)}
          >
            <span className=" text-center">
              Are you sure you would like to{" "}
              <span className="font-semibold">Pay now</span> for{" "}
              {selectAll ? "these multiple" : "this"}{" "}
              {selectedTab?.toLowerCase()} invoices?
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>

      <AnimatePresence>
        <EnterPin
          isOpen={pinModal}
          onClose={() => {
            setPinModal(false);
            setOtpInputs(["", "", "", ""]);
          }}
          hidePin={hidePin}
          otpInputs={otpInputs}
          setOtpInputs={setOtpInputs}
          onSubmit={(pin) => {
            singlePay ? handlePayNowSingleDF(pin) : handlePayNow(pin);
          }}
        />
      </AnimatePresence>

      {/* Export Invoice List */}
      <AnimatePresence>
        {selectDownloadType && (
          <ViewDocumentModal
            icon={<PAIconInvoiceIcon className=" animate-pulse" />}
            onAction={exportToExcel}
            onClose={() => setSelectDownloadType(false)}
          >
            <span className=" text-center">
              Are you sure you want to{" "}
              <span className="font-bold text-center text-primaryColor">
                Export
              </span>{" "}
              this invoice list
            </span>
          </ViewDocumentModal>
        )}

        {/* Here */}
        <AnimatePresence>
          {showReceipt && (
            <TransferInfoReceipt
              isOpen={showReceipt}
              disbursement
              onClose={() => {
                setOtpInputs(["", "", "", ""]);
                setPinModal(false);
                setShowReceipt(false);
                navigate(
                  selectedTab === "bulk"
                    ? `/disbursement/funder/disbursement/transaction-details/bulk/${
                        disbursementFunderReceiptData?.data?.transactionId ??
                        disbursementFunderReceiptData?.data
                          ?.transactionReference
                      }?offerId=${
                        disbursementFunderReceiptData?.data?.programId
                      }&channel=${
                        disbursementFunderReceiptData?.data?.fundingChannel
                      }`
                    : `/disbursement/funder/disbursement/transaction-details/${
                        disbursementFunderReceiptData?.data?.transactionId ??
                        disbursementFunderReceiptData?.data
                          ?.transactionReference
                      }?invId=${
                        disbursementFunderReceiptData?.data?.invoiceId
                      }&offerId=${
                        disbursementFunderReceiptData?.data?.programId
                      }&channel=${
                        disbursementFunderReceiptData?.data?.fundingChannel
                      }`
                );
              }}
            />
          )}
        </AnimatePresence>

        {/*Multiple Receipt */}

        <AnimatePresence>
          {multipleReceiptModal && (
            <MultipleTransferReceipt
              onClose={() => {
                setMultipleReceiptModal(false);
                setOtpInputs(["", "", "", ""]);
                dispatch(clearSelectedData());
              }}
              setActiveTab={setActiveTab}
            />
          )}
        </AnimatePresence>

        {paymentModal && (
          <ProgressModal
            openMarket
            openMarketText={
              loading ? "Transferring funds..." : "Funds Transferred"
            }
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default DirectFunding;
