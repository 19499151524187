import { PAIconSolarCopyBroken } from "assets/images/svgs";
import React, { useState } from "react";
import toast from "react-hot-toast";

const FundCard = ({ accountName, accountType, accountNumber, bankName }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(accountNumber);
      setIsCopied(true);
      toast("copied");
      setTimeout(() => setIsCopied(false), 3000);
    } catch (error) {
      toast("Error copying to clipboard:", error);
    }
  };

  return (
    <div className="w-[335px] h-[221px] relative">
      <div className="w-[335px] h-[171px] left-0 top-[25px] absolute bg-[#FFBE55] rounded-[10px] border border-white">
        <div className="left-[12px] top-[20px] absolute justify-start items-center gap-[118px] inline-flex">
          <div className="w-[107px] h-[37px] relative">
            <div className="w-[107px] h-[17px] text-[#17191C] left-0 top-[20px] absolute flex-col justify-start items-start gap-3 inline-flex">
              <div className="text-neutral800 text-sm font-semibold">
                {accountName}
              </div>
            </div>
            <div className="left-[3px] top-0 absolute text-center text-zinc-900 text-[11px] font-normal">
              Account Name
            </div>
          </div>
          <div className="relative">
            <div className="w-[79px] text-[#17191C] h-3.5 left-0 top-[2px] absolute flex-col justify-start items-start gap-3 inline-flex">
              <div className="text-neutral-800 text-xs font-medium">
                {bankName}
              </div>
            </div>
            <div className="left-[1px] top-0 mt-[-18px] absolute text-center text-zinc-900 text-[11px]">
              Bank
            </div>
          </div>
        </div>
        <div className="left-[245px] top-[146px] absolute flex-col justify-start items-start gap-3 inline-flex">
          <div className="text-[#EDEDED] text-[10px] font-medium">
            {accountType}
          </div>
        </div>
        <div className="left-[12px] top-[89px] absolute flex-col justify-start items-start gap-2 inline-flex">
          <div className="text-center text-zinc-900 text-xs font-normal">
            Account Number
          </div>
          <div
            className="w-[138px] h-8 px-[11px] py-[3px] bg-white rounded-[20px] justify-center items-center gap-2.5 inline-flex cursor-pointer"
            onClick={handleCopyToClipboard}
          >
            <div
              className={`text-neutral-800 text-xs font-medium ${
                isCopied ? "text-success" : ""
              }`}
            >
              {accountNumber}
            </div>
            {!isCopied ? (
              <div
                className="w-[18px] h-[18px] relative cursor-pointer"
              >
                <PAIconSolarCopyBroken />
              </div>
            ) : (
              <div className="w-[18px] h-[18px] text-xs relative text-success">
                ✓
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="w-[53px] h-[47.57px] left-[142px] top-0 absolute">
        <div className="w-[44.57px] h-[44.57px] left-[3px] top-[3px] absolute bg-white rounded-full border border-amber-300" />
        <div className="w-[53px] h-[25px] left-0 top-0 absolute bg-white" />
      </div>
      <div className="w-[53px] h-[51.64px] left-[141px] top-[169.36px] absolute">
        <div className="w-[44.57px] h-[44.57px] left-[4px] top-0 absolute bg-white rounded-full border border-amber-300" />
        <div className="w-[53px] h-[25px] left-0 top-[26.64px] absolute bg-white" />
      </div>
    </div>
  );
};

export default FundCard;
