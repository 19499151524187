import { PAIconPaymnet, PAIconWalletCardBlack } from "assets/images/svgs";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
// import AccountList from "./AccountCard";
import { setSecondSelectedAccounts } from "appstate/wallet/walletSlice";

const AccountFrom = ({ isOpen, onClose, where1, where2, onSubmit }) => {
  const { allVirtualAccountsData } = useSelector((state) => state?.wallet);

  const dispatch = useDispatch();

  const selectedAccounts = useSelector(
    (state) => state?.wallet?.selectedAccounts
  );

  const handleSubmit = () => {
    onSubmit();
  };

  const accountData = Array.isArray(allVirtualAccountsData?.data)
    ? allVirtualAccountsData?.data?.map((account) => ({
        id: account?.id,
        name: account?.accountName,
        accountNumber: account?.accountNumber,
        bankName: account?.provider || "Payedge",
        accountType: "Virtual Account",
        amount: account?.balance,
      }))
    : [];

    // const filteredAccountData = accountData.filter(account => account.id !== selectedAccounts.id);
    const filteredAccountData = accountData.filter(account => 
      selectedAccounts && account.id !== selectedAccounts.id
    );


  const formatAmount = (amount) => {
    if (!amount) return "0.00";
    const parsedAmount = parseFloat(amount);
    if (isNaN(parsedAmount)) return "0.00";
    return parsedAmount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  if (!isOpen) return null;

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg rounded-md z-[1000]">
      <div className="bg-white p5 px5 rounded-md max-w-xs md:max-w-lg w-full pb-5">
        <div className="flex justify-between items-center mb-4 bg-[#FAFAFA]">
          <p className="text-black text-base font-bold p-3 px-5 rounded-sm italic">
            Fund Account
          </p>
          <button
            onClick={onClose}
            className="text-black font-sans text-xl cursor-pointer flex justify-center items-center"
          >
            &times; <span className="text-sm mx-2 mt-1">Close</span>
          </button>
        </div>

        {/* FORM */}
        <div className="w-full px-7">
          <div
            className="text-[#7A7A7A] font-sans text-sm font-normal mb-6 flex items-center cursor-pointer"
            onClick={() => onClose()}
          >
            <p>{where1} </p>
            <div className="border border-[#F08000] ml-3 p-1 textwhite w-[90%] flex items-center rounded text-xs">
              <div className="flex items-center">
                <div className="flex items-center ml-2 flex-basis-[20%]">
                  <PAIconWalletCardBlack className="w-full" />
                </div>
                <div className="flex text-base flex-col justify-start ml-5 flex-basis-[80%]">
                  <div className="flex items-center justify-start">
                    <p className="mr-1">{selectedAccounts?.name || ""}</p> -{" "}
                    <p className="mx-1">
                      {selectedAccounts?.accountNumber || ""}
                    </p>{" "}
                    - <p className="ml-1">{selectedAccounts?.bankName || ""}</p>
                  </div>
                  <div className="flex justify-center mt-2">
                    <p className="text-sm font-semibold text-black">
                      Balance: {selectedAccounts.currency} {formatAmount(selectedAccounts?.amount || "")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p className="text-zinc-600 text-sm font-extrabold flex justify-center italic">
            Select which account to transfer {where2}
          </p>
          <h1 className="text-zinc-600 text-base font-extrabold mt-5 italic">
            Accounts
          </h1>
          {/* <div>
            <AccountList
              accountData={accountData}
              handleSubmit={handleSubmit}
            />
          </div> */}
          <div className="md:flex md:gap-5 flex-wrap max-h-48 overflow-auto">
            {filteredAccountData.map((account, index) => (
              <div
                key={index}
                className="bg-[#07593D] p-3 mt-3 cursor-pointer text-white text-xs md:w-[calc(50%-10px)] md:mb-0 mb-3 rounded"
                onClick={() => {
                  dispatch(setSecondSelectedAccounts(account));
                  handleSubmit();
                }}
              >
                <div className="flex justify-between">
                  <div className="text-white flex items-center">
                    <PAIconPaymnet className="mr-2 text-sm font-bold" />
                    <p>{account.name}</p>
                  </div>
                  <p className="text-white font-light text-xs my-2">
                    {account.accountType}
                  </p>
                </div>
                <p className="mt-2 italic font-bold text-[18px] flex justify-start">
                {account.currency} {formatAmount(account.amount)}
                </p>
                <p className="mt-5 text-xs">
                  <span className="mr-1">{account.bankName}</span> |
                  <span className="ml-1">{account.accountNumber}</span>
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountFrom;
