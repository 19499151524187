import request from "apiInstance";
import toast from "react-hot-toast";

export const getAllearlyPayments = async ({ page, pageSize, search }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/buyer/invoices?page=${page}&size=${pageSize}&search=${search}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const allearlyPaymentsRequestStatus = async ({ status }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/fund-request/early-payment-request/views-approved/single?status=${status}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const allearlyPaymentsRequestBulkStatus = async ({ status }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/fund-request/early-payment-request/views-approved/bulk?status=${status}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const allearlyPaymentsRequestProcessingStatus = async ({ status }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/fund-request/early-payment-request/views-processing/single?status=${status}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const allearlyPaymentsRequestProcessingBulkStatus = async ({
  status,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/fund-request/early-payment-request/views-processing/bulk?status=${status}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const allearlyPaymentsRequestStatusRejected = async ({ status }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/fund-request/early-payment-request/views-rejected/single?status=${status}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const allearlyPaymentsRequestBulkStatusRejected = async ({ status }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/fund-request/early-payment-request/views-rejected/bulk?status=${status}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const allPendingearlyPaymentsRequestStatus = async ({ status }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/fund-request/early-payment-request/views-pending/single?status=${status}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const fundRequestAllFundersClosedMarketApi = async ({ searchQuery }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/fund-request/early-payment-request/view-funders`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const allPendingearlyPaymentsRequestBulkStatus = async ({ status }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/fund-request/early-payment-request/views-pending/bulk?status=${status}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getApprovedSingleEarlyPaymentDetails = async (id) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/fund-request/early-payment-request/view-approved-single/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getRejectedSingleEarlyPaymentDetails = async (id) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/fund-request/early-payment-request/view-rejected-single/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getPendingSingleEarlyPaymentDetails = async (id) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/fund-request/early-payment-request/view-single/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getUpdateDiscountRatePreviewApi = async (body) => {
  try {
    const response = await request({
      method: "post",
      url: `/api/v1/fund-request/early-payment-request/preview-adjusted-single-discount-list`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getPendingBulkEarlyPaymentDetails = async ({ id, status }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/fund-request/early-payment-request/view-bulk/${id}?status=${status}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getApprovedBulkEarlyPaymentDetails = async ({ id }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/fund-request/early-payment-request/view-approved-bulk/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getRejectedBulkEarlyPaymentDetails = async ({ id }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/fund-request/early-payment-request/view-rejected-bulk/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const approveSingleEarlyPaymentRequestWallet = async (body) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/fund-request/early-payment-request/pay/wallet",
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const updateBulkDiscountPecentageClosedMarketApi = async (body) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/fund-request/early-payment-request/preview-adjusted-bulk-discount",
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const approveSingleEarlyPaymentRequestFunder = async (body) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/fund-request/early-payment-request/pay/direct",
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const approveSingleEarlyPaymentRequestClosed = async (body) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/fund-request/closed-market-list",
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const updateSingleEarlyDiscountRate = async ({ body }) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/fund-request/early-payment-request/preview-adjusted-single-discount",
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const saveSingleEarlyDiscountRate = async ({ body }) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/fund-request/early-payment-request/save-adjusted-single-discount",
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const updateBulkEarlyDiscountRate = async ({ body }) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/fund-request/early-payment-request/preview-adjusted-bulk-discount",
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const saveBulkEarlyDiscountRate = async ({ body }) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/fund-request/early-payment-request/save-adjusted-bulk-discount",
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const importEarlyPaymentToCloseMarketApi = async (body) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/fund-request/closed-market-list",
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getProcessingSingleEarlyPaymentDetails = async (id) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/fund-request/early-payment-request/view-processing-single/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const withdrawProcessingSingleEarlyPaymentRequest = async ({earlyPaymentId, channel}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/fund-request/early-payment-request/withdraw-request/${earlyPaymentId}?fundingChannel=${channel}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getClosedMarketOverviewApi = async (id) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/closed-market/buyer/overview`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getClosedMarketTopTenApi = async (id) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/closed-market/buyer/top-contestant`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const rejectSingleEarlyPaymentRequestbyBuyer = async ({
  id,
  reasons,
}) => {
  try {
    const rejectionReason = encodeURIComponent(reasons);
    const response = await request({
      method: "get",
      url: `/api/v1/fund-request/early-payment-request/reject-request/${id}?fundingChannel=NIL&rejectionType=REJECTED_BY_BUYER&userType=BUYER&rejectionReason=${rejectionReason}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const closedMarketViewSingleList = async ({
  status = "",
  search = "",
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/closed-market/buyer/view-single/list?status=${status}&search=${search}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const closedMarketViewBulkList = async ({
  status = "",
  search = "",
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/closed-market/buyer/view-bulk/list?status=${status}&search=${search}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const closedMarketViewSingleDetailsPage = async (id) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/closed-market/buyer/view-single/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const closedMarketViewBulkDetailsPage = async (id) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/closed-market/buyer/view-bulk/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllNewEarlyPaymentRequest = async ({
  page,
  pageSize,
  bulk,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/fund-request/early-payments?page=${page}&size=${pageSize}&bulk=${bulk}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const earlyPaymentApproveOrDecline = async ({ id, comment, status }) => {
  try {
    const response = await request({
      method: "put",
      url: `/fund-request/approve-early-payment/${id}?comment=${comment}&status=${status}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
