import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  adjustRulePriority,
  createNewRule,
  deleteRuleById,
  editRuleById,
  getAllRules,
  getRuleById,
  searchRules,
  updatePercentageLimitAndDiscountApi,
  updateRuleStatus,
} from "appstate/api/rulesandConfig";

const initialState = {
  isLoading: false,
  modalLoading: false,
  allRulesData: [],
  searchRuleData: [],
  ruleByIdData: [],
  totalItemsByRuleType: {},
};

export const allRules = createAsyncThunk(
  "rulesandconfig/allRules",
  getAllRules
);

export const ruleById = createAsyncThunk(
  "rulesandconfig/ruleById",
  getRuleById
);

export const createRule = createAsyncThunk(
  "rulesandconfig/createRule",
  createNewRule
);
export const updatePercentageLimitAndDiscount = createAsyncThunk(
  "rulesandconfig/updatePercentageLimitAndDiscount",
  updatePercentageLimitAndDiscountApi
);

export const editRule = createAsyncThunk(
  "rulesandconfig/editRule",
  editRuleById
);
export const updateRulePriority = createAsyncThunk(
  "rulesandconfig/updateRulePriority",
  adjustRulePriority
);

export const updateStatus = createAsyncThunk(
  "rulesandconfig/updateStatus",
  updateRuleStatus
);

export const deleteRule = createAsyncThunk(
  "rulesandconfig/deleteRule",
  deleteRuleById
);

export const searchRule = createAsyncThunk(
  "rulesandconfig/searchRule",
  searchRules
);

export const rulesandConfigSlice = createSlice({
  name: "rulesandconfig",
  initialState,
  reducers: {
    setTotalItems: (state, action) => {
      const { ruleType, totalItems } = action.payload;
      state.totalItemsByRuleType[ruleType] = totalItems; // Update total items for the specified rule type
    },
  },
  extraReducers: (builder) => {
    builder.addCase(allRules.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allRules.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allRulesData = action.payload;
    });
    builder.addCase(allRules.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(ruleById.pending, (state) => {
      state.modalLoading = true;
    });
    builder.addCase(ruleById.fulfilled, (state, action) => {
      state.modalLoading = false;
      state.ruleByIdData = action.payload;
    });
    builder.addCase(ruleById.rejected, (state) => {
      state.modalLoading = false;
    });

    builder.addCase(searchRule.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(searchRule.fulfilled, (state, action) => {
      state.isLoading = false;
      state.searchRuleData = action.payload;
    });
    builder.addCase(searchRule.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(createRule.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createRule.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createRule.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(updatePercentageLimitAndDiscount.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updatePercentageLimitAndDiscount.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updatePercentageLimitAndDiscount.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(editRule.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editRule.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(editRule.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(updateStatus.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateStatus.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateStatus.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(updateRulePriority.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateRulePriority.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateRulePriority.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(deleteRule.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteRule.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteRule.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const { setTotalItems } = rulesandConfigSlice.actions;

export default rulesandConfigSlice.reducer;
