import { disassociateBuyerFunder } from "appstate/api/buyer/relationshipManagement";
import {
  PAIconInventoryArrowLeft,
  PAIconLink,
} from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "shared/Button";
import Card from "shared/Card";
import AccountModalContainer from "shared/accountModal/Modal";
import InvoiceModalContainer from "shared/invoiceModal";

const BuyerFunderDetails = () => {
  const [open, setOpen] = useState(false);
  const [openDisassociateModal, setOpenDisAssociateModal] = useState(false);
  // eslint-disable-next-line
  const [success, setSuccess] = useState(false);
  const location = useLocation();
  const { row } = location?.state || {};
  const [loading, setIsLoading] = useState(false);


  const navigate = useNavigate();

  function handleNavigate() {
    navigate(-1);
  }

  const handleOpenModal = () => {
    // setGetId(row?.userId);
    setOpenDisAssociateModal(true);
  };

  const handleDisAssociate = async () => {
    try {
      setIsLoading(true);
      const response = await disassociateBuyerFunder(row?.userId);
      if (response?.status === 200) {
        toast.success(response?.message);
        setSuccess(true);
        setOpenDisAssociateModal(false);
      }
    } catch (error) {
      console.error("Error disassociating funder:", error);
      toast.error("Error disassociating funder, try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <DashboardLayout
      section="Funders"
      subHeader="Manage all your associated funders account"
    >
      <AccountModalContainer
        accontModal
        isShown={openDisassociateModal}
        onClose={() => {
          setOpenDisAssociateModal(false);
        }}
        isLoading={loading}
        success={success}
        handleAssociate={handleDisAssociate}
        warning="Are you sure you would like to disassociate with this member?"
      />
      <InvoiceModalContainer isShown={open} onClose={() => setOpen(false)} />
      <div>
        <header className="bg-alabasterHeader p-[24px] rounded-t-[10px]">
          <div className="flex justify-between">
            <div
              onClick={handleNavigate}
              className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
            >
              <PAIconInventoryArrowLeft />
              <p className="mt-[2px] text-sm font-normal text-lightGray">
                Back
              </p>
            </div>
            <Button
              onClick={() => handleOpenModal(true)}
              className="bg-white flex text-xs font-medium items-center mb-[10px] md:mb-0 md:px-[5px] border-[1px] border-[red] py-[12px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px] hover:bg-white hover:border-red"
            >
              <span>
                <PAIconLink />
              </span>
              <p className="font-sm font-normal text-[#DE1515]">Disassociate</p>
            </Button>
          </div>
          <div className="flex gap-x-[395px] flex-wrap md:gap-x-[200px] lg:gap-x-[280px] xl:gap-x-[395px]">
            <div>
              <p className="text-black text-sm font-medium">Details</p>
              <p className="text-textColor text-xs font-normal">
                Basic information about the associate
              </p>
            </div>
            <div>
              <div>
                <label className="text-black text-sm font-medium" htmlFor="">
                  Account Type
                </label>
                <p>Open market funder</p>
              </div>
            </div>
          </div>
        </header>
        <Card className="px-[20px] md:px-[40px] pb-[40px] rounded-b-[10px] 2xl:pl-[100px]">
          <div>
            <div className="md:flex md:justify-between  pt-[24px] 2xl:justify-start 2xl:gap-x-[370px] ">
              <div>
                <h2 className="text-black text-sm font-semibold">
                  Personal Information
                </h2>
                <div className="mt-[24px]">
                  <p className="text-sm font-normal text-lightGray">Name</p>
                  <p className="mt-[8px] text-textColor text-sm font-normal">
                    {row?.fullName}
                  </p>
                </div>
                <div className="mt-[24px]">
                  <p className="text-sm font-normal text-lightGray">Email</p>
                  <p className="mt-[8px] text-textColor text-sm font-normal">
                    {row?.email}
                  </p>
                </div>
                <div className="mt-[24px]">
                  <p className="text-sm font-normal text-lightGray">
                    Contact number
                  </p>
                  <p className="mt-[8px] text-textColor text-sm font-normal">
                    {row?.phoneNumber}
                  </p>
                </div>
              </div>

              <div className="mt-[24px] md:mt-0">
                <h2 className="text-black text-sm font-semibold">
                  Company Information
                </h2>
                <div className="mt-[24px]">
                  <p className="text-sm font-normal text-lightGray">
                    Company Name
                  </p>
                  <p className="mt-[8px] text-textColor text-sm font-normal">
                    {row?.companyName}
                  </p>
                </div>
                <div className="mt-[24px]">
                  <p className="text-sm font-normal text-lightGray">
                    Company Address
                  </p>
                  <p className="mt-[8px] text-textColor text-sm font-normal w-[150px] md:w-full">
                    {row?.registeredAddress}
                  </p>
                </div>
                <div className="mt-[24px]">
                  <p className="text-sm font-normal text-lightGray">
                    Company Website
                  </p>
                  <p className="mt-[8px] text-textColor text-sm font-normal">
                    {row?.companyWebsite}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </DashboardLayout>
  );
};

export default BuyerFunderDetails;
