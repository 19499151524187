import React from "react";

import { useNavigate } from "react-router-dom";
import Table from "layout/table/Table";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import Pagination from "shared/Pagination";

// import { useNavigate } from "react-router-dom";
import Card from "shared/Card";

const PendingRequests = () => {
  const navigate = useNavigate();
  //   const handleBack = () => {
  //     navigate(-1);
  //   };

  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const dummyData = [
    {
      bidAmount: 3,
      amount: "+9,000,000",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      discountRate: 3,
      funderId: "Admin",
      invoiceAmount: 32164082.78,
      status: "Admin",
      invoiceDate: "First User",
      invoiceId: "65dfc0942b607753910b022e",
      transactionId: "2023-02-19",
      lockedBy: "Admin",
      lockedDate: "2024-03-07",
      paymentDate: "1 of 3",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Invoicing ",
      supplierId: "View Details",
      actionType: "Invoice Approval",

      currency: "3",
    },

    {
      bidAmount: 4,
      amount: "-4,000,000",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      discountRate: 3,
      funderId: "Admin",
      invoiceAmount: 32164082.78,
      status: "Admin",
      invoiceDate: "First User",
      invoiceId: "65dfc0942b607753910b022e",
      transactionId: "2023-02-19",
      lockedBy: "Admin",
      lockedDate: "2024-03-07",
      paymentDate: "1 of 3",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Invoicing ",
      supplierId: "View Details",

      currency: "3",
      actionType: "Invoice Upload",
    },

    {
      bidAmount: 5,
      amount: "+9,000,000",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      discountRate: 3,
      funderId: "Admin",
      invoiceAmount: 32164082.78,
      status: "Admin",
      invoiceDate: "First User",
      invoiceId: "65dfc0942b607753910b022e",
      transactionId: "2023-02-19",
      lockedBy: "Admin",
      lockedDate: "2024-03-07",
      paymentDate: "1 of 3",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Early Payment Request ",
      supplierId: "View Details",

      currency: "3",
      actionType: "Approval via Wallet",
    },

    {
      bidAmount: 6,
      amount: "-4,000,000",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      discountRate: 3,
      funderId: "Admin",
      invoiceAmount: 32164082.78,
      status: "Admin",
      invoiceDate: "First User",
      invoiceId: "65dfc0942b607753910b022e",
      transactionId: "2023-02-19",
      lockedBy: "Admin",
      lockedDate: "2024-03-07",
      paymentDate: "1 of 3",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Early Payment Request",
      supplierId: "View Details",

      currency: "3",
      actionType: "Approval via Wallet",
    },
    {
      bidAmount: 7,
      amount: "-4,000,000",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      discountRate: 3,
      funderId: "Admin",
      invoiceAmount: 32164082.78,
      status: "Admin",
      invoiceDate: "First User",
      invoiceId: "65dfc0942b607753910b022e",
      transactionId: "2023-02-19",
      lockedBy: "Admin",
      lockedDate: "2024-03-07",
      paymentDate: "1 of 3",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Early Payment Request",
      supplierId: "View Details",

      currency: "3",
      actionType: "Approval via Direct Funding",
    },
    {
      bidAmount: 8,
      amount: "-4,000,000",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      discountRate: 3,
      funderId: "Admin",
      invoiceAmount: 32164082.78,
      status: "Admin",
      invoiceDate: "First User",
      invoiceId: "65dfc0942b607753910b022e",
      transactionId: "2023-02-19",
      lockedBy: "Admin",
      lockedDate: "2024-03-07",
      paymentDate: "1 of 3",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Early Payment Request",
      supplierId: "View Details",

      currency: "3",
      actionType: "Approval via Direct Funding",
    },

    {
      bidAmount: 9,
      amount: "-4,000,000",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      discountRate: 3,
      funderId: "Admin",
      invoiceAmount: 32164082.78,
      status: "Admin",
      invoiceDate: "First User",
      invoiceId: "65dfc0942b607753910b022e",
      transactionId: "2023-02-19",
      lockedBy: "Admin",
      lockedDate: "2024-03-07",
      paymentDate: "1 of 3",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Early Payment Request",
      supplierId: "View Details",

      currency: "3",
      actionType: "Approval via Closed Market",
    },
    {
      bidAmount: 10,
      amount: "-4,000,000",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      discountRate: 3,
      funderId: "Admin",
      invoiceAmount: 32164082.78,
      status: "Admin",
      invoiceDate: "First User",
      invoiceId: "65dfc0942b607753910b022e",
      transactionId: "2023-02-19",
      lockedBy: "Admin",
      lockedDate: "2024-03-07",
      paymentDate: "1 of 3",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Early Payment Request",
      supplierId: "View Details",

      currency: "3",
      actionType: "Approval via Closed Market",
    },
    {
      bidAmount: 11,
      amount: "-4,000,000",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      discountRate: 3,
      funderId: "Admin",
      invoiceAmount: 32164082.78,
      status: "Admin",
      invoiceDate: "First User",
      invoiceId: "65dfc0942b607753910b022e",
      transactionId: "2023-02-19",
      lockedBy: "Admin",
      lockedDate: "2024-03-07",
      paymentDate: "1 of 3",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Open Market  ",
      supplierId: "View Details",

      currency: "3",
      actionType: "Confirmation",
    },
    {
      bidAmount: 12,
      amount: "-4,000,000",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      discountRate: 3,
      funderId: "Admin",
      invoiceAmount: 32164082.78,
      status: "Admin",
      invoiceDate: "First User",
      invoiceId: "65dfc0942b607753910b022e",
      transactionId: "2023-02-19",
      lockedBy: "Admin",
      lockedDate: "2024-03-07",
      paymentDate: "1 of 3",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Early Payment Offers",
      supplierId: "View Details",

      currency: "3",
      actionType: "Campaign Creation",
    },
    {
      bidAmount: 13,
      amount: "-4,000,000",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      discountRate: 3,
      funderId: "Admin",
      invoiceAmount: 32164082.78,
      status: "Admin",
      invoiceDate: "First User",
      invoiceId: "65dfc0942b607753910b022e",
      transactionId: "2023-02-19",
      lockedBy: "Admin",
      lockedDate: "2024-03-07",
      paymentDate: "1 of 3",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Direct Funding ",
      supplierId: "View Details",

      currency: "3",
      actionType: "Confirmation",
    },
  ];

  const totalPages = dummyData.length;

  const columns = [
    {
      name: "Date Sent",
      selector: (row) => row.transactionId,
    },
    {
      name: "Transaction Type ",
      selector: (row) => row.funder,
    },
    {
      name: "Action Type ",
      selector: (row) => row.actionType,
    },

    {
      name: "Sender",
      selector: (row) => row.invoiceDate,
    },
    {
      name: "Approval Stage",
      selector: (row) => row.paymentDate,
    },

    {
      name: "Days in Queue",
      selector: (row) => row.currency,
    },

    {
      name: "Previous Approver",
      selector: (row) => row.status,
    },

    {
      name: "Next Approver",
      selector: (row) => row.lockedBy,
    },

    // {
    //   name: "",
    //   selector: (row) => row.supplierId,
    // },
  ];

  const handleRowClick = (row) => {
    if (row.actionType === "Invoice Approval") {
      navigate(
        `/work-flow-management/detail/PendingRequestInvoiceApprovalScreen/${row.bidAmount}`
      );
    } else if (row.actionType === "Invoice Upload") {
      navigate(
        `/work-flow-management/detail/PendingRequestInvoiceUpload/${row.bidAmount}`
      );
    } else if (
      row.actionType === "Approval via Wallet" &&
      row.bidAmount !== 6
    ) {
      navigate(
        `/work-flow-management/detail/PendingRequestApprovalViaWalletScreen/${row.bidAmount}`
      );
    } else if (
      row.bidAmount === 6 &&
      row.actionType === "Approval via Wallet"
    ) {
      navigate(
        `/work-flow-management/detail/PendingRequestApprovalViaWallet/${row.bidAmount}`
      );
    } else if (
      row.actionType === "Approval via Direct Funding" &&
      row.bidAmount !== 8
    ) {
      navigate(
        `/work-flow-management/detail/PendingRequestsApprovalViaDirectFundingScreenDetails/${row.bidAmount}`
      );
    } else if (
      row.bidAmount === 8 &&
      row.actionType === "Approval via Direct Funding"
    ) {
      navigate(
        `/work-flow-management/detail/PendingRequestApprovalViaDirectFundingScreen/${row.bidAmount}`
      );
    } else if (
      row.actionType === "Approval via Closed Market" &&
      row.bidAmount === 9
    ) {
      navigate(
        `/work-flow-management/detail/PendingRequestApprovalViaClosedMarketScreenDetails/${row.bidAmount}`
      );
    } else if (
      row.actionType === "Approval via Closed Market" &&
      row.bidAmount === 10
    ) {
      navigate(
        `/work-flow-management/detail/PendingRequestApprovalViaClosedMarketScreen/${row.bidAmount}`
      );
    } else if (
      row.actionType === "Confirmation" &&
      row.funder === "Open Market  "
    ) {
      navigate(
        `/work-flow-management/detail/PendingRequestApprovalViaConfirmationAndOpenMarket/${row.bidAmount}`
      );
    } else if (row.actionType === "Campaign Creation") {
      navigate(
        `/work-flow-management/detail/PendingRequestApprovalViaCampagnCreationScreen/${row.bidAmount}`
      );
    } else {
      navigate(
        `/work-flow-management/detail/PendingRequestApprovalViaDirectFundingAndConfirmationScreenDetails/${row.bidAmount}`
      );
    }
  };

  //detail/approvalviacampagncreationscreen/
  return (
    <div>
      <AnimatePresence>
        <Card className="rounded-[10px] mt-[24px]   bg-black  pb-14">
          <div className="flex justify-between items-center px-[24px]   rounded-t-[10px]  ">
            <div className="flex gap-2 my-6 justify-between">
              <div className="text-black text-[13px] font-medium items-center justify-center h-[23px]  flex ">
                Pending Request
              </div>

              <div className=" justify-center items-center flex">
                <div className="w-[23px] h-[23px]  bg-[#f08000] rounded-[100%]  justify-center items-center flex">
                  <div className="text-white text-xs font-medium  ">4</div>
                </div>
              </div>
            </div>

            {/* filter section */}

            <div className=" mr-8">
              <select
                className=" shadow-sm  px-4 py-2.5   focus:outline-none cursor-pointer h-[41px]    justify-start items-center  text-[#7a7a7a] text-xs font-normal bg-white rounded-[5px] border border-[#dedede]/80  gap-[7px]   "
                onChange={(e) => {
                  // const selectedSortBy = e.target.value;
                }}
              >
                <option
                  value=""
                  disabled
                  selected
                  className="overflow-x-hidden"
                >
                  {" "}
                  All
                </option>
                <option value="Transaction Type" className="overflow-x-hidden">
                  All
                </option>
                <option value="Sender" className="overflow-x-hidden">
                  Invoice
                </option>
                <option value="Sender" className="overflow-x-hidden">
                  Early Payment Request
                </option>
                <option value="Sender" className="overflow-x-hidden">
                  Direct Funding
                </option>
                <option value="Sender" className="overflow-x-hidden">
                  Early Payment Offers
                </option>
                <option value="Sender" className="overflow-x-hidden">
                  Closed Market
                </option>
                <option value="Sender" className="overflow-x-hidden">
                  Open Market
                </option>
              </select>
            </div>
          </div>
          <div className="px-[24px] ">
            <Table
              columns={columns}
              data={dummyData}
              onRowClicked={(row) => handleRowClick(row)}
              pointer
              tableHeader
            />
          </div>

          <div>
            {totalPages > 20 && (
              <div className="flex justify-center gap-x-[8px]  mt-5">
                <Pagination
                  page={page}
                  startPage={startPage}
                  setEndPage={setEndPage}
                  endPage={endPage}
                  setStartPage={setStartPage}
                  setPage={setPage}
                  totalPages={totalPages}
                />
              </div>
            )}
          </div>
        </Card>
      </AnimatePresence>
    </div>
  );
};

export default PendingRequests;
