import { PAIconInventoryArrowLeft } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { useNavigate, useLocation } from "react-router-dom";
import Card from "shared/Card";
import { motion } from "framer-motion";
import CocaCola from "../../../assets/images/svgs/cocacola.svg";

const SupplierDirectFundingDetails = () => {
  const invoiceData = {
    status: "Approved",
    buyerCompanyName: "Dummy Buyer Corp",
    items: [
      {
        itemName: "Product A",
        description: "Description for Product A",
        unitPrice: 100,
        quantity: 5,
        amount: 500,
        taxRate: "10%",
        taxAmount: 50,
        totalPrice: 550,
      },
      {
        itemName: "Product B",
        description: "Description for Product B",
        unitPrice: 200,
        quantity: 2,
        amount: 400,
        taxRate: "10%",
        taxAmount: 40,
        totalPrice: 440,
      },
    ],
  };
  const navigate = useNavigate();
  const location = useLocation();
  const { state, supplier } = location?.state || {};

  function handleNavigate() {
    let path = -1;
    navigate(path);
  }

  return (
    <DashboardLayout
      section="Invoice"
      subHeader="Here is an overview of your invoices"
    >
      <header>
        <div
          onClick={handleNavigate}
          className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
        >
          <PAIconInventoryArrowLeft />
          <p className="mt-[2px] text-sm font-normal text-lightGray">Go Back</p>
        </div>
      </header>
      <div className="flex justify-between">
        <Card className="w-[60%] p-[24px] rounded-[10px]">
          <div className=" flex  flex-col gap-3 ">
            <main className=" w-full flex justify-between">
              <div className="flex flex-col gap-3 w-1/2">
                <div className="flex">
                  <span>Reference No:</span>
                  <span className="pl-2">|RF9101010BHSHS</span>
                </div>
                <div className="flex flex-col gap-1">
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Invoice Number:</span>
                    <span className="text-[#979797]  text-xs">INV 2002</span>
                  </div>
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Created Date:</span>
                    <span className="text-[#979797]  text-xs">
                      23-March-2023
                    </span>
                  </div>
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Submitted Date:</span>
                    <span className="text-[#979797]  text-xs">
                      23-March-2023
                    </span>
                  </div>
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Approved Date:</span>
                    <span className="text-[#979797]  text-xs">
                      23-March-2023
                    </span>
                  </div>
                  <div className="flex gap-x-[97px]">
                    <span className=" text-xs">Due Date:</span>
                    <span className="text-[#979797]  text-xs">
                      23-March-2023
                    </span>
                  </div>
                </div>
              </div>

              {state === "rejected" && (
                <div className="flex flex-col justify-between">
                  <motion.button
                    whileTap={{ scale: 0.9 }}
                    className="flex bg-[#FAD9D9] text-sm justify-center gap-2 text-[#DE1515] rounded-[5px] items-center px-[35px] py-1.5 "
                  >
                    Rejected
                  </motion.button>
                </div>
              )}

              {state === "Approved" && (
                <div className="flex flex-col justify-between">
                  <motion.button
                    whileTap={{ scale: 0.9 }}
                    className="flex bg-[#D9EFD6] text-sm justify-center gap-2 text-[#59BB50] rounded-[5px] items-center px-[35px] py-1.5 "
                  >
                    Approved
                  </motion.button>
                </div>
              )}

              {state === "Processing" && (
                <div className="flex flex-col justify-between">
                  <motion.button
                    whileTap={{ scale: 0.9 }}
                    className="flex bg-[#C7D7FF] text-sm justify-center gap-2 text-[#6994FF] rounded-[5px] items-center px-[35px] py-1.5 "
                  >
                    Processing
                  </motion.button>
                </div>
              )}

              {state === "Pending Approval" && (
                <div className="flex flex-col justify-between">
                  <motion.button
                    whileTap={{ scale: 0.9 }}
                    className="flex bg-[#FFBE5557] text-sm justify-center gap-2 text-[#F08000] rounded-[5px] items-center px-[35px] py-1.5 "
                  >
                    Pending Approval
                    {/* <PAIconLockYellow /> */}
                  </motion.button>
                </div>
              )}
            </main>
          </div>
          <br />
          <div className="flex justify-between">
            <div className="flex flex-col">
              <span className="font-light  text-xs">Nestle Plc</span>
              <span className="text-[#979797] text-sm">buyer details</span>
            </div>
            <div className="flex flex-col">
              <span className="font-light   text-xs">60 Days</span>
              <span className="text-[#979797] text-sm">Payment</span>
            </div>
            <div className="flex flex-col">
              <span className="text-[#979797] text-xs flex justify-end">NGN</span>
              <span className="font-light text-sm">Currency</span>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <header className="border-b-[5px] border-b-[#F6F5FA] pb-3"></header>
            <div className="flex  justify-between">
              <div className="flex gap-2"></div>
              <div className="flex gap-2">
                <div className="flex flex-col">
                  <span className="text-[#979797]  text-xs">5 total items</span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
              <h1 className="text-sm">Line Items</h1>
            </header>
            <div className=" w-full overflow-x-auto">
              <table className=" w-full overflow-x-auto">
                <thead className="bg-subtleGray w-">
                  <tr className=" bg-unusualGray p-2.5 ">
                    <td className="text-[#11192A] p-2.5 text-xs font-bold">
                      Item
                    </td>
                    <td className=" p-2.5 text-xs font-bold">Description</td>
                    <td className=" p-2.5 text-xs font-bold">Unit price</td>
                    <td className=" p-2.5 text-xs font-bold">Quantity</td>
                    <td className=" p-2.5 text-xs font-bold">
                      {"Amount(Before Tax)"}
                    </td>
                    <td className=" p-2.5 text-xs font-bold">Tax Rate</td>
                    <td className=" p-2.5 text-xs font-bold">Tax Amount</td>
                    <td className=" p-2.5 text-xs font-bold">Total Price</td>
                  </tr>
                </thead>
                {invoiceData?.items?.map((item, index) => (
                  <tbody key={index}>
                    <tr className=" py-[3px] px-1.5">
                      <td className=" text-xs text-[#11192A] p-2.5">
                        {item?.itemName}
                      </td>
                      <td className=" text-xs text-[#11192A] p-2.5">
                        {item?.description}
                      </td>
                      <td className=" text-xs text-[#11192A] p-2.5">
                        {item?.unitPrice}
                      </td>
                      <td className=" text-xs text-[#11192A] p-2.5">
                        {item?.quantity}
                      </td>
                      <td className=" text-xs text-[#11192A] p-2.5">
                        {item?.unitPrice}
                      </td>
                      <td className=" text-xs text-[#11192A] p-2.5">
                        {item?.taxRate}
                      </td>
                      <td className=" text-xs text-[#11192A] p-2.5">
                        {item?.taxAmount}
                      </td>
                      <td className=" text-xs text-[#11192A] p-2.5">
                        {item?.totalPrice}
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
            <div className="flex justify-between">
              <div>
                <h4 className="text-lg text-[#11192A] ">
                  Receiving Funding Account Information
                </h4>
                <h5 className="text-lg text-[#11192A] ">7662662662626</h5>
                <h5 className="text-lg text-[#11192A] ">Praise Akindade</h5>
              </div>
              <div style={{ width: 300 }}>
                <div className="flex justify-between">
                  <span className="text-sm text-[#11192A] ">Subtotal</span>
                  <span className="text-sm text-[#11192A] ">1000000</span>
                </div>
                <div className="flex justify-between">
                  <div>
                    <span className="text-sm text-[#11192A] ">Vat % </span>
                    <span className="pl-2 text-sm text-[#11192A] ">5.6%</span>
                  </div>
                  <div>
                    <span className="text-sm text-[#11192A] ">583,000</span>
                  </div>
                </div>
                <div className="flex justify-between">
                  <div>
                    <span className="text-sm text-[#11192A] ">Discount % </span>
                    <span className="pl-2 text-sm text-[#11192A] ">2.6%</span>
                  </div>
                  <div>
                    <span className="text-sm text-[#11192A] ">283,000</span>
                  </div>
                </div>
                <div>
                  <hr className="border-t border-[black] mt-2 pt-2" />
                  <div className="flex justify-between">
                    <span className="text-lg text-[#11192A] ">Grand Total</span>
                    <span className="text-lg text-[#11192A] ">10,000,000</span>
                  </div>
                  <hr className="border-t border-[black] mt-2 pt-2" />
                </div>
              </div>
            </div>
          </div>
        </Card>
        <Card className="w-[38%] p-[24px] rounded-[10px]">
          <div>
            <div className="flex justify-between">
              <span className="font-bold text-[#000000]">
                Direct Funding Details
              </span>
              {state === "rejected" ||
              state === "Approved" ||
              state === "Processing" ? null : (
                <div className="flex flex-col justify-between">
                  <motion.button
                    // onClick={() => setLockModalOpen(true)}
                    whileTap={{ scale: 0.9 }}
                    className="flex bg-[#BB0C0C] text-sm justify-center gap-2 text-[white] rounded-[5px] items-center px-[35px] py-1.5 "
                  >
                    Cancel Request
                    {/* <PAIconLockYellow /> */}
                  </motion.button>
                </div>
              )}
            </div>
          </div>
          <div>
            <br />
            <div className="flex justify-between">
              <div className="flex">
                <div>
                  <img src={CocaCola} alt="" />
                </div>
                <div style={{ lineHeight: 1 }} className="flex flex-col pl-2">
                  <span>Coca Cola PLC</span>
                  <span className="text-[#959595]">buyer</span>
                </div>
              </div>
              <div>
                <div style={{ lineHeight: 1 }} className="flex flex-col pl-2">
                  <span className="flex justify-end">1.5%</span>
                  <span className="text-[#959595]">Discount rate</span>
                </div>
              </div>
            </div>
            <br />
            <div className="flex justify-between">
              <div style={{ lineHeight: 1.3 }} className="flex flex-col pl-2">
                <span>₦90,000</span>
                <span className="text-[#959595]">Discount Amount</span>
              </div>
              <div>
                <div style={{ lineHeight: 1.3 }} className="flex flex-col pl-2">
                  <span>23-Dec-2024(20 days)</span>
                  <span className="flex justify-end text-[#959595]">
                    Early Payment Date
                  </span>
                </div>
              </div>
            </div>
            <br />
            <div className="flex justify-between">
              <div style={{ lineHeight: 1.3 }} className="flex flex-col pl-2">
                <span>₦9,000,000</span>
                <span className="text-[#959595]">Request Amount</span>
              </div>
              <div>
                <div style={{ lineHeight: 1.3 }} className="flex flex-col pl-2">
                  <span className="flex justify-end">8,900,000</span>
                  <span className="text-[#959595]">Receivable Amount</span>
                </div>
              </div>
            </div>
          </div>
          <br />
          {supplier === "true" ? null : <hr className="text-[#959595]" />}
          <br />
          {supplier === "true" ? null : (
            <div className="flex flex-col">
              <span className="font-bold text-[#000000]">
                Notes from supplier:
              </span>
              <span className="text-[#959595]">
                Seeking Early payment due to unexpected challenges. Facing
                temporary financial constraints and need a bit more time to
                ensure a smooth transaction. Appreciate your understanding.
                Thank you. Best regards, ABC Limited
              </span>
            </div>
          )}
          <br />
          {supplier === "true" ? null : <hr className="text-[#959595]" />}
          <br />
          {supplier === "true" ? null : (
            <div className="flex flex-col">
              <span className="font-bold text-[#000000]">
                Reason for rejection
              </span>
              <span className="text-[#959595]">
                You rejected this Invoice based on Unreliable History reasons
              </span>
            </div>
          )}
        </Card>
      </div>
    </DashboardLayout>
  );
};
export default SupplierDirectFundingDetails;
