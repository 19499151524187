import React, { useState, useEffect } from "react";
import { PAIconCocacola, PAIconSearch } from "assets/images/svgs";
import { useSelector } from "react-redux";

const SelectFunderClosedMarket = ({
  isOpen,
  onClose,
  setSelectAccountModalOpen,
  setSelectedFunders,
  selectedFunders,
  setClosedMarketActive
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [funders, setFunders] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  // Handles individual funder selection
  const toggleSelect = (userId) => {
    setClosedMarketActive(true)
    setFunders((prev) => {
      const updatedFunders = prev.map((funder) =>
        funder.userId === userId
          ? { ...funder, selected: !funder.selected }
          : funder
      );

      // Create an array of selected userIds
      const selectedUserIds = updatedFunders
        .filter((funder) => funder?.selected)
        .map((funder) => funder?.userId);

      setSelectedFunders(selectedUserIds);

      return updatedFunders;
    });
  };

  const { buyerAssociatedFundersData } = useSelector((state) => state.buyer);

  useEffect(() => {
    setFunders(buyerAssociatedFundersData?.data);
  }, [buyerAssociatedFundersData.data]);

  // Handles Select All
  const toggleSelectAll = () => {
    const newState = !selectAll;
    setClosedMarketActive(true)
    setSelectAll(newState);
    setFunders((prev) =>
      prev.map((funder) => ({ ...funder, selected: newState }))
    );
  };

  // Handles search
  const filteredFunders = funders?.filter((funder) =>
    funder?.companyName?.toLowerCase()?.includes(searchTerm?.toLowerCase())
  );

  if (!isOpen) {
    return null;
  }

  const handleSelectedFunderProceed = () => {
    setSelectAccountModalOpen(true);
    onClose();
  };

  return (
    <div className="bg-[rgba(0,0,0,0.2)] w-[100%] h-[100vh] flex justify-center items-center absolute left-0 top-0">
      <div className="px-8 py-6 w-[40%] mx-auto bg-white rounded-[12px] shadow-lg">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">
            Add funders to your closed market
          </h2>
          <button
            onClick={() => {
              onClose();
            }}
            className="text-gray-500 hover:text-gray-700"
          >
            X Close
          </button>
        </div>

        <div className="mb-4 flex items-center border-b-[1px] border-stone-300">
          <PAIconSearch />
          <input
            type="text"
            placeholder="Search for funder"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full px-2 py-2 border-none focus:outline-none focus:ring-2 focus:ring-green-500"
          />
        </div>

        <div className="mb-4">
          <div className="flex items-center mb-4 pb-4 border-b-[1px] border-stone-300">
            <input
              type="checkbox"
              checked={selectAll}
              onChange={toggleSelectAll}
              className="mr-2"
            />
            <label>Select All</label>
          </div>

          <div className="max-h-70 overflow-y-auto scrollbar-hide">
            {filteredFunders.map((funder) => (
              <div
                key={funder.userId}
                className="flex items-center justify-between py-3 border-b-[1px] border-stone-300"
              >
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    checked={funder.selected}
                    onChange={() => toggleSelect(funder.userId)}
                    className="mr-2"
                  />
                  <PAIconCocacola className="mr-2" />
                  <div className="flex flex-col">
                    <span className="text-[16px] font-semibold">
                      {funder.companyName}
                    </span>
                    <span className="text-[14px] text-[#777777]">
                      {funder.fullName}
                    </span>
                  </div>
                </div>
                <div className="flex-col flex justify-end items-end">
                  <span className="text-[16px] font-medium">
                    {funder.email}
                  </span>

                  <span className="text-[14px] text-green-600">
                    {funder.phoneNumber}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>

        <button
          onClick={handleSelectedFunderProceed}
          className="w-full py-3 bg-primaryColor text-white rounded-[8px] hover:bg-green-700"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default SelectFunderClosedMarket;
