import {
  PAIconChangeImage,
  PAIconSettingsProfile,
} from "../../../assets/images/svgs";

const ProfilePic = ({ currentUser }) => {
  return (
    <div className="flex flex-wrap align-middle gap-[20px] pb-[10px]">
      <div className="relative inline-block">
        <PAIconSettingsProfile className="inline-block h-16 w-16 rounded-full ring-2 ring-white dark:ring-gray-800" />
        <PAIconChangeImage className="absolute bottom-0 -right-1 block h-6 w-6 rounded-full cursor-pointer" />
      </div>
      <div className="flex flex-col ml-3 justify-start gap-[3px]">
        <p className="font-bold text-md">
          {currentUser?.role?.toLowerCase() === "open_funder"
            ? `${currentUser?.firstName} ${currentUser?.lastName}`
            : currentUser?.fullName}
        </p>
        <p className="text-sm capitalize">
          {currentUser?.role?.toLowerCase() === "open_funder" ||
          currentUser?.role?.toLowerCase() === "corporate_funder"
            ? `${currentUser?.role?.toLowerCase().split("_")[0]} ${
                currentUser?.role?.toLowerCase().split("_")[1]
              }`
            : currentUser?.role?.toLowerCase()}
        </p>
        <p className="text-sm">{currentUser?.email}</p>
      </div>
    </div>
  );
};

export default ProfilePic;
