import { PAIconArrowLeftGreen } from "assets/images/svgs";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { useDispatch } from "react-redux";
import DashboardLayout from "layout/dashboardlayout";
import Table from "layout/table/Table";
import TransitionScale from "shared/animation/TransitionScale";
import SuccessModal from "shared/SuccessModal";
import AcceptModal from "shared/AcceptModal";
import { AnimatePresence } from "framer-motion";

const BulkApprovedExtensionPreview = () => {
  const navigate = useNavigate();

  // const dispatch = useDispatch();

  const goBack = () => {
    navigate(-1);
  };

  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handleRemainderClick = () => {
    setIsAcceptModalOpen(true);
  };

  const handleConfirm = () => {
    setIsAcceptModalOpen(false);
    setIsSuccessModalOpen(true);
    setSuccessMessage("Payment reminders sent successfully!");

    setTimeout(() => {
      goBack();
    }, 2000);
  };

  const handleCloseSuccessModal = () => {
    setIsSuccessModalOpen(false);
  };

  const columns = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Invoice Number
        </span>
      ),
      selector: (row) => row.invNum,
    },

    {
      name: "Due Date",
      selector: (row) => row.dueDate,
    },
    {
      name: "Invoice Amount",
      selector: (row) => row.requestAmount,
    },
    {
      name: "Interest Rate",
      selector: (row) => row.interestRate,
    },
    {
      name: "Recievable Amount",
      selector: (row) => row.receivableAmount,
    },
  ];

  const tableData = [
    {
      bidAmount: 1,
      invNum: "INV-2090 ",
      dueDate: "2023-05-23",
      requestAmount: "9,000,000",
      interestRate: "1.5%",
      receivableAmount: "9,000,000",
    },
    {
      bidAmount: 2,
      invNum: "INV-2090 ",
      dueDate: "2023-05-23",
      requestAmount: "9,000,000",
      interestRate: "1.5%",
      receivableAmount: "9,000,000",
    },

    {
      bidAmount: 3,
      invNum: "INV-2090 ",
      dueDate: "2023-05-23",
      requestAmount: "9,000,000",
      interestRate: "1.5%",
      receivableAmount: "9,000,000",
    },
    {
      bidAmount: 4,
      invNum: "INV-2090 ",
      dueDate: "2023-05-23",
      requestAmount: "9,000,000",
      interestRate: "1.5%",
      receivableAmount: "9,000,000",
    },
  ];

  const navigateToDetail = (id) =>
    navigate(`/payment-extension/bulkapprovedextensionpreview/${id}`);

  return (
    <DashboardLayout
      section="Payment Extension Section"
      subHeader="Send and track payment extension processes"
    >
      <>
        <Link
          onClick={goBack}
          className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer w-full"
        >
          <PAIconArrowLeftGreen />
          <span className="flex items-center text-primaryColor">Go Back</span>
        </Link>

        <div className="grid lg:grid-cols-[60%,40%] gap-5">
          <TransitionScale className=" pb-5 bg-white  rounded-[10px] pt-2">
            <section className="  mt-3">
              {/* Down Flex Texts */}
              <div className=" flex justify-between px-6 mt-4">
                {" "}
                <div class="text-[#222222] text-lg font-medium ">
                  Reference No: | RF83018374BA
                </div>
                {/* <div class="h-[37px] p-2.5 rounded-[5px] border border-[#f08000]/20 justify-center items-center gap-2.5 inline-flex">
                  <div class="text-[#f08000] text-sm font-medium ">
                    Bid Active
                  </div>
                </div> */}
              </div>
              <div className=" flex justify-between px-6">
                {/* first stage */}

                <div class="lg:w-[240px]  sm:w-full h-[117px] flex-col justify-start items-start gap-3 inline-flex  mt-5">
                  <div class="self-stretch  flex-col justify-center items-start gap-1 flex ">
                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Request Date:
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        23-Mar -2023
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Total Request amount
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        24,000,000
                      </div>
                    </div>

                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Total Invoice amount
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        34,000,000
                      </div>
                    </div>
                  </div>

                  {/* stop */}
                </div>
                {/* second stage */}

                <div className=" mt-5">
                  {" "}
                  <div className="flex justify-between items-center mt-5 ">
                    {" "}
                    <div class="h-[30px] px-8 py-2 bg-[#23ad52]/20 rounded-[5px] justify-center items-center gap-2.5 inline-flex">
                      <div class="text-center text-[#07593d] text-xs font-medium ">
                        Approved
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* stops here */}
              <div className="px-6">
                <header className="border-b-[5px] border-b-[#F6F5FA] mt3  flex justify-between py-1">
                  {/* <h1 className="text-[18px] font-semibold">
                  Supplier/buyer details
                </h1> */}
                  <div>
                    <div class="text-[#222222] text-lg font-semibold  ">
                      Nestle PLC
                    </div>
                    <div class="text-[#7a7a7a] text-xs font-light  ">
                      Buyer details
                    </div>
                  </div>

                  <div class="h-[41px] flex-col justify-start items-start  inline-flex px-12">
                    <div class="text-[#222222] text-base font-semibold ">
                      60 Days
                    </div>
                    <div class="w-[100px] text-[#7a7a7a] text-xs font-light  mt-1">
                      Payment
                    </div>
                  </div>

                  <div class="h-[43px] flex-col justify-start items-end  inline-flex">
                    <div class="text-[#222222] text-lg font-semibold ">NGN</div>
                    <div class="text-[#7a7a7a] text-xs font-semibold mt-1">
                      Currency
                    </div>
                  </div>
                </header>

                <header className="border-b-[5px] border-b-[#F6F5FA] mt-6  flex justify-between py-1">
                  <div class="text-[#11192a] text-sm font-semibold ">
                    Invoice List
                  </div>
                  <div class="text-[#11192a] text-lg font-light  mr-6">
                    4 Invoices
                  </div>
                </header>

                {/* <div className=" w-full overflow-x-auto mt-3 pb-4"></div> */}

                <div className=" w-full overflow-x-auto mt-3 ">
                  <Table
                    columns={columns}
                    data={tableData}
                    pointer
                    className=""
                    onRowClicked={(row) => navigateToDetail(row.bidAmount)}
                    progressPending={false}
                    // progressComponent={<Loading />}
                  />
                </div>
              </div>
            </section>
          </TransitionScale>

          <div className="bg-white rounded-[10px] gap-8">
            <TransitionScale>
              <div className="py-8">
                <div class="text-black text-lg font-semibold px-6">
                  Bulk Extension Request
                </div>
                {/* starts here */}
                <div className="border-b border-gray shadow">
                  {" "}
                  {/* second */}
                  <div className="   pb-4">
                    <div class=" justify-between flex flex-row px-6 mt-4">
                      <div className="flex gap-2 py-2.5">
                        <div className="flex flex-col">
                          <span class="text-black text-sm font-normal ">
                            Coca Cola PLC
                          </span>
                          {/* <span className="text-[#979797]  text-xs">Supplier</span> */}
                          <span class="text-[#949494] text-sm font-normal ">
                            Buyer
                          </span>
                        </div>
                      </div>

                      <div class="py-2.5 flex-col justify-end items-end inline-flex">
                        <div class="flex-col justify-end items-end gap-0.5 flex">
                          <div class="text-black text-sm font-normal ">
                            23-Dec-2024 (90 days)
                          </div>
                        </div>
                        <div class="flex-col justify-end items-end  flex">
                          <div class="text-[#949494] text-sm font-normal ">
                            Early Payment Request date
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class=" justify-between  flex flex-row    px-6  mt-6 ">
                      <div class="py-2.5 flex-col justify-start items-start inline-flex">
                        <div class="flex-col justify-start items-start gap-0.5 flex">
                          <div class="text-black text-sm font-normal ">
                            N90,000
                          </div>
                        </div>
                        <div class="flex-col justify-start items-start  flex">
                          <div class="text-[#949494] text-sm font-normal ">
                            Total invoice amount
                          </div>
                        </div>
                      </div>
                      {/* hd */}
                      <div class="py-2.5 flex-col justify-start items-start inline-flex">
                        <div class="flex-col  gap-0.5 flex w-full">
                          <div class="text-black text-sm font-normal text-end ">
                            8,9110,000
                          </div>
                        </div>
                        <div class="flex-col justify-start items-start  flex">
                          <div class="text-[#949494] text-sm font-normal ">
                            Total interest amount
                          </div>
                        </div>
                      </div>{" "}
                    </div>

                    {/* 2nd */}
                    {/* <div class=" justify-between  flex flex-col     px-6   ">
                    <div class="text-black text-lg font-semibold  mt-6">
                      Note from Supplier:{" "}
                    </div>

                    <div class=" text-[#949494] text-sm font-light  mt-4  leading-6">
                      Seeking Early payment due to unexpected challenges. Facing
                      temporary financial constraints and need a bit more time
                      to ensure a smooth transaction. Appreciate your
                      understanding.
                      <br className=" mt-3" />
                      Thank you.
                      <br className=" mt-3" />
                      Best regards, <br />
                      ABC Limited
                    </div>
                  </div> */}
                  </div>
                </div>
                {/* ACTION */}
                <div className="px-6 mt-7">
                  <div class="text-[#222222] text- font-bold mb-5 ">Action</div>
                  <div
                    onClick={handleRemainderClick}
                    className="w-1/3 rounded-[5px] cursor-pointer bg-[#07593D] shadow-md text-[#F9F9F9] text-sm font-medium py-[10px] px-[20px]"
                  >
                    Send Remainder
                  </div>
                </div>{" "}
              </div>
            </TransitionScale>
          </div>

          <AnimatePresence>
            <AcceptModal
              isOpen={isAcceptModalOpen}
              onClose={() => setIsAcceptModalOpen(false)}
              yes
              message={
                <>
                  Would you like to send a{" "}
                  <span className="text-[#07593D] font-bold">
                    Payment reminder
                  </span>{" "}
                  for this <br />
                  invoice?
                </>
              }
              onConfirm={handleConfirm}
            />
          </AnimatePresence>

          <AnimatePresence>
            <SuccessModal
              isOpen={isSuccessModalOpen}
              onClose={handleCloseSuccessModal}
              message={successMessage}
            />
          </AnimatePresence>
        </div>
      </>
    </DashboardLayout>
  );
};

export default BulkApprovedExtensionPreview;
