import { PAIconCocacola, PAIconArrowLeftGray } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import TransitionScale from "shared/animation/TransitionScale";
import DirectFundingAcceptModal from "./modals/DirectFundingAcceptModal";
import { useEffect, useState } from "react";

// import DirectFundingBidSelectAccountModal from "./modals/DirectFundingBidSelectAccountModal";

// import BidPinModal from "features/payableFinaceForFunder/CloseMarket/modals/BidPinModal";
import BidProceedModal from "features/payableFinaceForFunder/CloseMarket/modals/BidProceedModal";
import BidLoaderModal from "features/payableFinaceForFunder/CloseMarket/modals/BidLoaderModla";
import DirectFundingPayBidSuccessOnModal from "./modals/DirectFundingPaySucessOnBoardModal";
import DirectFundingRejectModal from "./modals/DirectFundingRejectModal";
import DirectFundingDeclinedModal from "./modals/DirectFundingDeclined";
// import Table from "layout/table/Table";
import ConfirmDirectFundingModalBulk from "./modals/ConfirmDirectFundingModalBulk";
import BulkAcceptFirstModal from "./modals/BulkAcceptFirstModal";
import { approveDirectFunding, getDirectFundingDetailsBulk, getVirtualAccounts, rejectDirectFunding } from "appstate/api/invoice/invoice";
import PinModalPayment from "../fundingSection/earlyPaymentRequest/singlerequest/Modal/PinModal";
import EarlyPaymentVirtualAccountSelection from "../fundingSection/earlyPaymentRequest/singlerequest/Modal/VirtualAccountSelection";
import EarlyPaymentConfirmation from "../fundingSection/earlyPaymentRequest/singlerequest/Modal/EarlyPaymentConfirmation";
import toast from "react-hot-toast";
import { formatCurrency } from "utilities/helper/formatters";
import DirectFundingReasonForRequiredModal from "./modals/DirectFundingReasonsRequired";

const FunderDirectFundingBulkDetailsAccepted = () => {
  const { id } = useParams();
  const [directFundingData, setDirectFundingData] = useState(null);
  // eslint-disable-next-line
  const [error, setError] = useState(null);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(true);
  const [virtualAccounts, setVirtualAccounts] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [otpInputs, setOtpInputs] = useState(["", "", "", ""]);
  const [acceptModal, setAcceptModal] = useState(false);
  const [acceptFirstModal, setAcceptFirstModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [bidSelectAccountModal, setBidSelectAccountModal] = useState(false);

  const [bidProceed, setBidProceed] = useState(false);
  const [bidPin, setBidPin] = useState(false);
  const [bidLoaderModal, setBidLoaderModal] = useState(false);
  const [bidSuccess, setBidSuccess] = useState(false);
  const [reasonFor, setReasonfor] = useState(false);
  const [directRejectModal, setDirectRejectModal] = useState(false);

  const [selectedAccount, setSelectedAccount] = useState(null);
  const [editable, setEditable] = useState(false);
  // eslint-disable-next-line
  const [selectedRow, setSelectedRow] = useState(null);
  // eslint-disable-next-line
  const [disCountPer, setDisCountPer] = useState("");
  const [newApprovedData, setNewApprovedData] = useState(null);
  const [selectedReason, setSelectedReason] = useState([]);
  const [userReason, setUserReason] = useState("");


   const location = useLocation();
    const invoiceId = location.state?.invoiceId;

  const navigate = useNavigate();
  
  useEffect(() => {
    const fetchDirectFundingDetails = async () => {
      try {
        const result = await getDirectFundingDetailsBulk(id);
        setDirectFundingData(result?.data);
      } catch (error) {
        setError(error.message || "Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchDirectFundingDetails();
    }
  }, [id]);

  useEffect(() => {
    const fetchVirtualAccounts = async () => {
      try {
        const result = await getVirtualAccounts();
        setVirtualAccounts(result?.data);
      } catch (error) {
        console.error("Failed to fetch virtual accounts:", error);
      }
    };

    fetchVirtualAccounts();
  }, []);

  function handleNavigate() {
    let path = -1;
    navigate(path);
  }
  const handleDiscountChange = (e) => {
 
  };

  const handleSave = () => {
   
  };

  const handleProceed = () => {
    setBidProceed(false);

    setTimeout(() => {
      setBidPin(true);
    }, 300);
  };

  const handleApprove = async () => {
    try {
      setLoading(true);
      const pin = otpInputs?.toString();
      const transactPin = pin?.replace(/,/g, "");
      const payload = {
        directFundingIds: directFundingData?.directFundingId,
        virtualAccountId: selectedAccount?.id,
        fundableAmount: directFundingData?.totalPayableAmount,
        discountAmount: directFundingData?.totalDiscountAmount,
        transactionPin: transactPin,
      };
      const response = await approveDirectFunding({
        directFundingId: payload?.directFundingIds,
        virtualAccountId: payload?.virtualAccountId,
        fundableAmount: payload?.fundableAmount,
        discountAmount: payload?.discountAmount,
        transactionPin: payload?.transactionPin
      });
      if (response?.success === true) {
        setBidLoaderModal(true);
        setTimeout(() => {
          setBidLoaderModal(false);
          setBidSuccess(true);

          setTimeout(() => {
            setBidSuccess(false);
            navigate(-1);
          }, 3000);
        }, 2000);
      }
    } catch (error) {
      console?.error("Approval failed:", error);
      toast?.error("Something went wrong! Please try again .");
    } finally {
      setLoading(false);
    }
  };
  
  const reasons = selectedReason.map((item) => item.label) + ", " + userReason;

  const handleRejectFunding = async () => {
    try {
      const response = await rejectDirectFunding({
        id: directFundingData?.directFundingId,
        rejectionReason: userReason || reasons,
      });
      if (response?.success === true) {
        setReasonfor(false);
        setRejectModal(false)
        setDirectRejectModal(true);
        setTimeout(() => {
          navigate("/directfunding");
      }, 2000)
      }
    } catch (error) {
      console.error("Error rejecting funding:", error);
      toast.error(error);
    }
  };

  const handleYes = () => {
    setAcceptModal(false);
    setBidSelectAccountModal(true);
  };

  const handleYesFirst = () => {
    setAcceptFirstModal(false);
    setAcceptModal(true);
  };

  const handleBidSuccessClose = () => {
    setBidSuccess(false);
    navigate(-1);
  };

  const handleReject = () => {
    setRejectModal(false);
    setReasonfor(true);
  };

  const handleDeclineClose = () => {
    setDirectRejectModal(false);
    handleNavigate();
  };

  const confirmPayment = () => {
    setShowConfirmModal(true);
    setBidPin(false);
  };

  const onClosePinModal = () => {
    setBidPin(false);
  };

  const onClose = () => {
    setBidSelectAccountModal(false);
  };

  const handleDiscountClick = () => {
    setConfirmModal(true);
  };

  const handleNavigateDetails = (id) => {
    navigate(`/single-early-payment-invoice-details/${id}`, {
      state: { details: true }
    });
  };


  return (
    <>
      <TransitionScale>
        <DirectFundingAcceptModal
          mess1={"Would you like to proceed with "}
          blueText={"Approving "}
          mess2={" this Bulk "}
          mess3={" Direct Funding request?"}
          isOpen={acceptModal}
          onClose={() => setAcceptModal(false)}
          handleYes={handleYes}
        />
      </TransitionScale>
      <TransitionScale>
        <BulkAcceptFirstModal
          mess1={"Do you agree with the current  "}
          mess2={"discount rate "}
          mess3={" applied to each invoice?"}
          isOpen={acceptFirstModal}
          onClose={() => setAcceptFirstModal(false)}
          handleYes={handleYesFirst}
        />
      </TransitionScale>
      <TransitionScale>
        <DirectFundingRejectModal
          mess1={"Would you like to proceed with "}
          blueText={"Rejecting "}
          mess2={" this Bulk "}
          mess3={" Direct Funding request?"}
          isOpen={rejectModal}
          onClose={() => setRejectModal(false)}
          handleYes={handleReject}
        />
      </TransitionScale>
      <TransitionScale>
        {" "}
        <PinModalPayment
          confirmPayment={confirmPayment}
          isOpen={bidPin}
          onCancel={onClosePinModal}
          otpInputs={otpInputs}
          setOtpInputs={setOtpInputs}
          invoice={true}
        />
      </TransitionScale>

      <TransitionScale>
        <ConfirmDirectFundingModalBulk
          isOpen={confirmModal}
          onClose={() => setConfirmModal(false)}
          disValue={disCountPer}
          handleChange={handleDiscountChange}
          handleCancel={() => setConfirmModal(false)}
          handleSave={handleSave}
          handleNext={() => setConfirmModal(false)}
          editable={editable}
          setEditable={setEditable}
          onBlur={() => setEditable(false)}
          newApprovedData={newApprovedData}
          setNewApprovedData={setNewApprovedData}
          directFundingId={directFundingData?.directFundingId}
          discountRate={directFundingData?.discountRate}
          discountAmount={directFundingData?.totalDiscountAmount}
          fundableAmount={directFundingData?.totalPayableAmount}
          invoiceId={invoiceId}
          directFundingData={directFundingData}
          setConfirmModal={setConfirmModal}
        />
      </TransitionScale>
      <TransitionScale>
        {
          bidSelectAccountModal && (
            <div>
              <EarlyPaymentVirtualAccountSelection
                handleCloseVirtualAccount={onClose}
                setOpen={setBidProceed}
                onClose={onClose}
                virtualAccounts={virtualAccounts}
                selectedAccount={selectedAccount}
                setSelectedAccount={setSelectedAccount}
                invoice={true}
              />
            </div>
          )
        }
      </TransitionScale>

      <TransitionScale>
        {bidLoaderModal && (
          <BidLoaderModal
            isOpen={bidLoaderModal}
            onClose={() => setBidLoaderModal(false)}
            onAction={() => {
              setBidLoaderModal(false);
              setTimeout(() => {
                setBidSuccess(true);
              }, 300);
            }}
            statusText={"Fund Invoice"}
          />
        )}{" "}
      </TransitionScale>
  
      {showConfirmModal && (
        <div className="absolute mt-[20px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white w-[615px] h-[254px] z-10 rounded-[10px] shadow-lg">
          <EarlyPaymentConfirmation
            hide={() => setShowConfirmModal(false)}
            // isLoading={earlyPaymentLoading}
            handleAssociate={handleApprove}
            invoice={true}
            // earlyPayment={true}
            warning=" Would you like to proceed with approving this direct funding? "
          />
        </div>
      )}

      <TransitionScale>
        {" "}
        <BidProceedModal
          isOpen={bidProceed}
          refNum={" 7364738921"}
          onClose={() => setBidProceed(false)}
          handleYes={handleProceed}
        />
      </TransitionScale>
      <TransitionScale>
        <DirectFundingPayBidSuccessOnModal
          isOpen={bidSuccess}
          onClose={handleBidSuccessClose}
          message={"Early payment request sent for"}
          sucess={"workflow approval"}
        />
      </TransitionScale>
      {reasonFor && (
        <TransitionScale>
            <DirectFundingReasonForRequiredModal
            onClose={() => setReasonfor(false)}
            handleRejectFunding={handleRejectFunding}
            selectedReason={selectedReason}
            setSelectedReason={setSelectedReason}
            userReason={userReason}
            setUserReason={setUserReason}
          />
        </TransitionScale>
      )}
      <TransitionScale>
        <DirectFundingDeclinedModal
          isOpen={directRejectModal}
          onClose={handleDeclineClose}
        />
      </TransitionScale>

      <DashboardLayout
        section="Direct Fund Request"
        subHeader="Manage all your direct fund requests"
      >
        <header>
          <div
            onClick={handleNavigate}
            className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
          >
            <PAIconArrowLeftGray />
            <span className=" text-[#7A7A7A] text-sm">Go Back</span>
          </div>
        </header>

        <div className="grid lg:grid-cols-[60%,40%] gap-5">
          <TransitionScale className=" pb-5 bg-white  rounded-[10px] mt-5 pt-2 ">
            {/* Header with Left Padding */}

            {/* Other sections */}

            <section className="  mt-3">
              {/* Down Flex Texts */}
              <div className=" flex justify-between px-6">
                {/* first stage */}

                <div class="lg:w-[240px]  sm:w-full h-[117px] flex-col justify-start items-start gap-3 inline-flex">
                  <div class=" justify-between items-center inline-flex mt-2">
                    <div class="text-[#222222] text-base font-semibold  ">
                      Reference No:
                    </div>
                    <div class="text-[#222222] text-base font-semibold ">
                      | {directFundingData?.requestReferenceNumber}
                    </div>
                  </div>
                  <div class="self-stretch  flex-col justify-center items-start gap-1 flex mt-2">
                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Request Date:
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        {directFundingData?.requestDate}
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex ">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Total Request amount
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        {formatCurrency(Number(directFundingData?.totalRequestAmount))}
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Total Invoice amount
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        {formatCurrency(Number(directFundingData?.totalInvoiceAmount))}
                      </div>
                    </div>
                  </div>

                  {/* stop */}
                </div>
                {/* second stage */}
                <div className="flex justify-between items-center mt-10 ">
                  {" "}
                  <div class="h-[37px] py-2 rounded-[5px]  bg-[#ffbe55]/30 justify-center items-center gap-2.5 inline-flex px-4">
                    <div class="text-[#f08000] text-sm font-medium ">
                      {directFundingData?.status === "PENDING_APPROVAL" && "PENDING APPROVAL"}
                    </div>
                  </div>
                </div>
              </div>

              {/* stops here */}
              <div className="  px-6  mt-10 ">
                <header className="border-b-[5px] border-b-[#F6F5FA] mt-3  flex justify-between py-1">
                  <div>
                    <div class="text-[#222222] text-lg font-semibold  ">
                      {directFundingData?.buyerCompanyName}
                    </div>
                    <div class="text-[#7a7a7a] text-xs font-light  ">
                      Buyer details
                    </div>
                  </div>

                  <div class="h-[43px] flex-col justify-start items-end  inline-flex">
                    <div class="text-[#222222] text-lg font-semibold "></div>
                    <div class="text-[#7a7a7a] text-xs font-semibold mt-1">
                      Currency
                    </div>
                  </div>
                </header>

                <header className="border-b-[5px] border-b-[#F6F5FA] mt-6  flex justify-between py-1">

                  <div class="text-[#11192a] text-sm font-semibold ">
                    Invoice List
                  </div>
                  <div class="text-[#11192a] text-lg font-light  mr-6">
                    {directFundingData?.directFundingDiscountResponseSingles?.length} Invoices
                  </div>
                </header>

                {/* <div className=" w-full overflow-x-auto mt-3 pb-4"></div> */}

                <div className=" w-full overflow-x-auto mt-3 pb-4">
                  <table className=" w-full overflow-x-auto">
                    <thead className="bg-subtleGray w-full p-6  flex-nowrap whitespace-nowrap  ">
                      <tr className=" bg-unusualGray  px-6 w-full flex-nowrap whitespace-nowrap ">
                        {/* <td className="text-[#11192A] p-2.5 text-xs font-bold">
                      Item Number
                    </td> */}
                        <td class="text-[#11192a] text-xs font-semibold  p-4  flex-nowrap whitespace-nowrap ">
                          Ref Number
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                          Due Date
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                          Request Amount
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                          Discount Rate
                        </td>

                        <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                          Discount Amount
                        </td>
                      </tr>
                    </thead>


                    {/* here */}
                    {
                      directFundingData?.directFundingDiscountResponseSingles?.map((item, index) => (
                        <tbody style={{cursor: "pointer"}}  key={index} className="  ">
                          <tr className=" w-full ">
                            <td onClick={() => handleNavigateDetails(item?.invoiceId)} className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap ">
                              {item?.invoiceReference}
                            </td>
                            <td onClick={() => handleNavigateDetails(item?.invoiceId)} className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                              {item?.invoiceDueDate}
                            </td>
                            <td onClick={() => handleNavigateDetails(item?.invoiceId)} className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                              {formatCurrency(Number(item?.requestAmount))}
                            </td>
                            <td style={{cursor: "pointer"}} onClick={handleDiscountClick} className="text-[#11192a] text-xs font-semibold mt-2 text-center flex-nowrap whitespace-nowrap">
                              {item?.discount}%
                            </td>
                            <td onClick={() => handleNavigateDetails(item?.invoiceId)} className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                              {formatCurrency(Number(item?.discountedAmount))}
                            </td>

                          </tr>

                        </tbody>

                      ))
                    }


                  </table>
                </div>
                {/* Calculate cost and price  shaded or gray section */}
              </div>
            </section>
          </TransitionScale>
          {/* stops the best here */}

          <div className="bg-white rounded-[10px] gap-8  mt-5">
            {/* <FundDetailSide /> */}
            <div class="w-full justify-start items-center gap-4 inline-flex ">
              <div class=" justify-start items-center gap-2.5 flex w-full px-6  py-3">
                <div class="text-black text-xl font-bold  mt-5">
                  Direct Funding Details
                </div>
              </div>
            </div>

            <div>
              {" "}
              {/* second */}
              <div className="   pb-4">
                <div class=" justify-between  flex flex-row    px-6 mt-4  ">
                  <div className="flex gap-2 py-2.5">
                    <PAIconCocacola />
                    <div className="flex flex-col">
                      {/* <span className=" text-black text-xs font-normal">
                    Coca Cola
                  </span> */}
                      <span class="text-black text-sm font-normal ">
                        {directFundingData?.supplierCompanyName}
                      </span>
                      {/* <span className="text-[#979797]  text-xs">Supplier</span> */}
                      <span class="text-[#949494] text-sm font-normal ">
                        Supplier
                      </span>
                    </div>
                  </div>
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">{directFundingData?.discountRate}%</div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Buyer’s Rate
                      </div>
                    </div>
                  </div>

                  {/* here */}
                </div>
                <div class=" justify-between  flex flex-row    px-6  mt-6 ">
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        {formatCurrency(Number(directFundingData?.totalDiscountAmount))}
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Discount amount
                      </div>
                    </div>
                  </div>
                  {/* hd */}
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        {directFundingData?.requestDate}
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Early Payment Date
                      </div>
                    </div>
                  </div>{" "}
                </div>
                <div class=" justify-between  flex flex-row    border-b border-[#c2c2c2]/30 px-6  py-3  pb-12 mt-6">
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        {formatCurrency(Number(directFundingData?.totalRequestAmount))}
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Total request amount
                      </div>
                    </div>
                  </div>
                  {/* dhdh */}
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        {formatCurrency(Number(directFundingData?.totalPayableAmount))}
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Total Fundable Amount
                      </div>
                    </div>
                  </div>
                </div>
                {/* 2nd */}
                <div class=" justify-between  flex flex-col    border-b border-[#c2c2c2]/30 px-6  pb-10 ">
                  <div class="text-black text-lg font-semibold  mt-6">
                    Note from Supplier:{" "}
                  </div>

                  <div class=" text-[#949494] text-sm font-light  mt-4  leading-6">
                    {directFundingData?.description}
                    <br className=" mt-3" />

                  </div>
                </div>{" "}
                {/* another header */}
                {/* header ends here */}
                {/* here */}
                <div class=" justify-between  flex flex-col    px-6  ">
                  <div class="text-black text-lg font-semibold  mt-6">
                    Your decision
                  </div>

                  <div class=" text-[#949494] text-sm font-light  mt-4  leading-6">

                  </div>

                  {/* stops */}
                </div>
                <div className=" grid lg:grid-cols-[47%,47%] gap-4 px-6  mt-7">
                  <button
                    className={`  px-3 text-white justify-center  flex items-center bg-[#07593d] rounded-[5px] py-2 text-sm ${"cursor-pointer"}`}
                    //   onClick={() =>
                    //     navigate("/invoice/invoice/fundingSection/invoiceupload")
                    //   }
                    onClick={() => setAcceptFirstModal(true)}
                  >
                    Accept
                  </button>
                  <button
                    className={`    py-2 px-3  text-[#F08000]  border border-[#F08000] justify-center  flex items-center bg-white rounded-[5px]  text-sm ${"cursor-pointer"}`}
                    //   class=" px-6 py-2 bg-white rounded-[5px] border border-[#de1515] justify-center items-center gap-2.5 inline-flex"
                    onClick={() => setRejectModal(true)}
                  >
                    <div>Reject</div>
                  </button>
                </div>
                {/* stops here */}
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};
export default FunderDirectFundingBulkDetailsAccepted;