import React, { useState } from "react";
import RouteHeader from "../../RouteHeader";
import ToolsModal from "../ToolsModal";

const DiscountCalculator = ({ goBack }) => {
  const [paymentRangeValue, setPaymentRangeValue] = useState(0);
  const [showResults, setShowResults] = useState(false);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [discountPrice, setDiscountPrice] = useState(0);
  const [newDiscountAmount, setNewDiscountAmount] = useState(0);
  const [invoiceAmount, setInvoiceAmount] = useState("");
  const [invoiceDueDate, setinvoiceDueDate] = useState("");
  const [requestAmount, setRequestAmount] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handlePaymentRangeChange = (e) => {
    setPaymentRangeValue(e.target.value);
  };

  const handleCalculate = () => {
    if (!invoiceAmount || !paymentRangeValue) {
      setIsModalOpen(true);
      return;
    }

    // CALCULATIONS HERE
    const calculatedDiscountPercentage = 10;
    const calculatedDiscountPrice = 11600;
    const calculatedNewDiscountAmount = 189400;

    setDiscountPercentage(calculatedDiscountPercentage);
    setDiscountPrice(calculatedDiscountPrice);
    setNewDiscountAmount(calculatedNewDiscountAmount);

    setShowResults(true);
  };

  const handleReset = () => {
    setShowResults(false);
    setInvoiceAmount("");
    setRequestAmount("");
    setinvoiceDueDate("");
    setPaymentRangeValue(0);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="px-5">
      <RouteHeader
        Route1="Tools"
        Heading="Discount Calculators"
        handleBack={goBack}
      />
      <div className="mt-10">
        <div>
          <h1 className="text-black font-sans text-lg font-semibold leading-normal tracking-wide">
            EarlyPayment Calculator
          </h1>
          <p className="text-gray font-sans text-base font-normal leading-normal mt-3">
            This sophisticated tool empowers you to determine the discount you
            may offer when seeking prompt payment as a supplier
          </p>
          <div className="border border-gray my-7"></div>

          {/* INPUT FIELD */}
          <div className="flex flex-col md:flex-row gap-4 justify-between">
            {/* FIRST INPUT */}
            <div className="mb-4 md:mb-0 w-full">
              <label
                htmlFor="invoiceAmount"
                className="block text-sm font-semibold text-gray-600 mb-2 mt-2"
              >
                Set Invoice Amount
              </label>
              <input
                type="number"
                id="invoiceAmount"
                name="invoiceAmount"
                value={invoiceAmount}
                onChange={(e) => setInvoiceAmount(e.target.value)}
                className="w-full p-2 border border-[#FCD596] rounded-md focus:outline-none focus:border-green-500"
                placeholder="Enter invoice amount"
              />
            </div>

            {/* SECOND INPUT */}
            <div className="w-full">
              <div className="mb-4 md:mb-0 flex items-center gap-4 justify-around">
                <label
                  htmlFor="earlyPaymentInvoice"
                  className="block text-sm font-semibold text-gray-600 mb-2"
                >
                  Early Payment Amount
                </label>
                <input
                  type="text"
                  id="earlyPaymentInvoice"
                  name="earlyPaymentInvoice"
                  value={paymentRangeValue}
                  onChange={(e) => setPaymentRangeValue(e.target.value)}
                  className="w-[40%] p-2 border border-[#FCD596] rounded-md focus:outline-none focus:border-green-500"
                  placeholder="Enter early payment invoice"
                />
              </div>
              {/* RANGE */}
              <div className="mt-1">
                <input
                  type="range"
                  id="paymentRange"
                  name="paymentRange"
                  min="0"
                  max="500000"
                  step="5000"
                  value={paymentRangeValue}
                  onChange={handlePaymentRangeChange}
                  className="w-full slider-thumb"
                />
                <div className="flex justify-between text-gray text-xs">
                  <span>0</span>
                  <span>500,000</span>
                </div>
              </div>
            </div>
          </div>
          <div className=" flex w-full flex-wrap justify-between">
            {/* FIRST INPUT */}
            <div className="mb-4 md:mb-0 lg:w-[45%]">
              <label
                htmlFor="invoiceAmount"
                className="block text-sm font-semibold text-gray-600 mb-2 mt-2"
              >
                Request Date
              </label>
              <input
                type="number"
                id="requestAmount"
                name="requestAmount"
                value={requestAmount}
                onChange={(e) => setRequestAmount(e.target.value)}
                className="w-full p-2 border border-[#FCD596] rounded-md focus:outline-none focus:border-green-500"
                placeholder="Enter request date"
              />
            </div>

            {/* FIRST INPUT */}
            <div className="mb-4 md:mb-0 lg:w-[45%]">
              <label
                htmlFor="invoiceAmount"
                className="block text-sm font-semibold text-gray-600 mb-2 mt-2"
              >
                Invoice Due Date
              </label>
              <input
                type="number"
                id="invoiceDueDate"
                name="invoiceDueDate"
                value={invoiceDueDate}
                onChange={(e) => setinvoiceDueDate(e.target.value)}
                className="w-full p-2 border border-[#FCD596] rounded-md focus:outline-none focus:border-green-500"
                placeholder="Enter invoice due date"
              />
            </div>
          </div>

          {/* Calculate and Reset Buttons */}
          <div className="flex gap-4 mt-4">
            <button
              onClick={handleCalculate}
              className="bg-[#FFBE55] rounded-sm p-[10px] text-white w-[182px] hover:bg-yellow-200"
            >
              Calculate
            </button>
            {showResults && (
              <button
                onClick={handleReset}
                className="border border-[#FFBE55] bg-white rounded-sm p-[10px] text-[#FFBE55] w-[182px] hover:bg-yellow-200"
              >
                Reset
              </button>
            )}
          </div>

          {/* Results */}
          {showResults && (
            <div className="flex mt-10 w-full justify-between text-black font-sans text-base font-medium">
              <div className="mr-4">
                <p>Discount Percentage: {discountPercentage}%</p>
              </div>
              <div className="mr-4">
                <span>
                  Discount Price:
                  <span className="text-[#07593D]">NG {discountPrice}</span>
                </span>
              </div>
              <div>
                New Discount Amount:
                <span className="text-[#07593D]">NG {newDiscountAmount}</span>
              </div>
            </div>
          )}

          {/* Modal */}
          {isModalOpen && (
            <ToolsModal
              message="Input fields are empty. Please fill in the required fields."
              onClose={closeModal}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DiscountCalculator;
