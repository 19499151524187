import { PAIconCocacola, PAIconInvShop } from "assets/images/svgs";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatCapitaliseString } from "utilities/helper/formatCapitaliseString";
import { formatCurrency } from "utilities/helper/formatters";
import useCountdown from "utilities/helper/useCountdown";

function PendingConfirmationCard({
  id,
  invoiceNumber = "N/A",
  buyerCompanyName = "N/A",
  invoiceAmount = "N/A",
  status = "N/A",
  invoiceDueDate = "N/A",
  requestAmount = "",
  invoiceReference = "N/A",
}) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);

    setTimeout(() => setIsLoading(false), 1000);
  }, []);
  const { timeLeft, isTimeUnderOneHour } = useCountdown(invoiceDueDate);

  return (
    <motion.div
      onClick={() => navigate(`pendingInvoice/${id}`)}
      whileTap={{ scale: 0.98 }}
      style={{ boxShadow: "0px 2px 4px 0px rgba(232, 226, 216, 0.36)" }}
      className="flex cursor-pointer justify-between bg-white py-5 px-6 rounded-[10px]"
    >
      <span className="flex gap-6  items-center">
        <PAIconInvShop />
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{invoiceReference}</span>
          <span className=" text-[#959595] text-[10px]">Reference number</span>
        </div>
      </span>

      <span className="flex gap-6  items-center">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{invoiceNumber}</span>
          <span className=" text-[#959595] text-[10px]">Invoice number</span>
        </div>
      </span>

      <span className="flex gap-6 items-center ">
        <PAIconCocacola />
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{buyerCompanyName}</span>
          <span className=" text-[#959595] text-[10px]">Buyer</span>
        </div>
      </span>

      <span className="flex gap-6  items-center">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-brilliantYellow text-sm">
            {formatCapitaliseString(status)}
          </span>
          <span className=" text-[#959595] text-[10px]">Status of Invoice</span>
        </div>
      </span>

      <span className="flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span className="font-[500] flex justify-center text-sm">
            {formatCurrency(requestAmount)}
          </span>
          <span className=" text-[#959595] text-[10px]">Request Amount</span>
        </div>
      </span>

      <span className="hidden extra-md:flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{invoiceDueDate}</span>
          <span className=" text-[#959595] text-[10px]">Payment Date</span>
        </div>
      </span>

      <span className="flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span
            className={`text-sm font-bold ${
              isTimeUnderOneHour ? "text-red-500" : ""
            }`}
          >
            {(timeLeft.days > 0 ||
              timeLeft.hours > 0 ||
              timeLeft.minutes > 0 ||
              timeLeft.seconds > 0) &&
            formatCapitaliseString(status) !== "Cancelled" ? (
              `${timeLeft.days}d:${timeLeft.hours}h:${timeLeft.minutes}m:${timeLeft.seconds}s`
            ) : isLoading ? (
              <span className="text-secondaryColor">Fetching...</span>
            ) : formatCapitaliseString(status) === "Cancelled" ? (
              <span className="text-red">Cancelled</span>
            ) : (
              <span className="text-red">Expired</span>
            )}
          </span>
          <span className="text-[#959595] text-[10px]">
            Count down to payment date
          </span>
        </div>
      </span>
    </motion.div>
  );
}

export default PendingConfirmationCard;
