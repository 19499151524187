import { PAIconMDISuccess } from "assets/images/svgs";
import React from "react";
//specifically for invoice
const InvoiceSuccessModal = ({ message, onClose }) => {
  return (
    <div
      className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-[1000]"
      onClick={onClose}
    >
      <div className="flex" onClick={(e) => e.stopPropagation()}>
        <div className="bg-[#07593D] p-10 shadow-md rounded-s text-center text-white">
          <PAIconMDISuccess />
        </div>

        <div className="bg-white py-10 px-3 flex flex-col items-center justify-center shadow-md text-center rounded-e">
          <p className="text-[#959595] text-center text-xs font-normal leading-normal tracking-[0.321px] mb-3 mt-3">
            {message}
          </p>
        </div>
      </div>
    </div>
  );
};

export default InvoiceSuccessModal;
