import { useState } from "react";
import emptyBid from "../../../../assets/images/svgs/empty-bid.png";
import cocaCola from "../../../../assets/images/svgs/cocacola.svg";
import FunderDetails from "features/openMarket/funder/components/modal/FunderDetails";
import { AnimatePresence } from "framer-motion";
import useCountdown from "utilities/helper/useCountdown";

const BidDetails = ({ pending, paymentDate }) => {
  // useEffect(() => {
  //   dispatch(detailsBuyerOpenMarket({ openMarketReference: id }));
  // }, [dispatch, id]);

  // const { detailsBuyerOpenMarketData } = useSelector(
  //   (state) => state?.openMarket
  // );
  const dueDate = paymentDate;
  const { timeLeft, isTimeUnderOneHour } = useCountdown(dueDate);

  const [isActive, setIsActive] = useState("history");
  const [funderDetail, setFunderDetail] = useState(false);

  const viewedFunderData = [
    {
      img: cocaCola,
      name: "Coca-Cola",
      number: "+235830495857",
      email: "www.skyflocklimited.com",
    },
    {
      img: cocaCola,
      name: "Coca-Cola",
      number: "+235830495857",
      email: "www.skyflocklimited.com",
    },
    {
      img: cocaCola,
      name: "Coca-Cola",
      number: "+235830495857",
      email: "www.skyflocklimited.com",
    },
  ];

  return (
    <div className="bg-white w-full rounded-[10px] pt-5 pb-24 h-[100%]">
      <div className="h-[15%]">
        <h2 className="text-lg px-6 font-semibold text-gray-800 pb-4 border-b-[1px] border-b-slate-300 text-primaryColor">
          Bid details for this invoice
        </h2>
        <div className="flex gap-5 cursor-pointer text-sm text-gray-600 mt-4 px-6">
          <span
            onClick={() => {
              setIsActive("history");
            }}
            className={
              isActive === "history"
                ? "text-secondaryColor border-b-[1px] border-secondaryColor"
                : "text-[#959595]"
            }
          >
            Bid history: 0
          </span>
          {pending ? null : (
            <span
              onClick={() => {
                setIsActive("viewed");
              }}
              className={
                isActive === "viewed"
                  ? "text-secondaryColor border-b-[1px] border-secondaryColor"
                  : "text-[#959595]"
              }
            >
              Viewed by funders: 3
            </span>
          )}
        </div>
      </div>

      {isActive === "history" && (
        <div className="flex flex-col justify-center items-center my-6 h-[65%]">
          <img src={emptyBid} alt="No Data" className="h-80 w-auto" />
          <div
            // className="text-center text-3xl font-bold text-gray800 mb-2"
            className={`text-3xl text-center font-bold mb-2 ${
              isTimeUnderOneHour ? "text-red" : ""
            }`}
          >
            {timeLeft.days > 0 ||
            timeLeft.hours > 0 ||
            timeLeft.minutes > 0 ||
            timeLeft.seconds > 0 ? (
              `${timeLeft.days}d:${timeLeft.hours}h:${timeLeft.minutes}m:${timeLeft.seconds}s`
            ) : (
              <span className="text-red">Expired</span>
            )}
          </div>
          <p className="text-center text-gray-500 mb-4">
            Count down to payment date
          </p>
        </div>
      )}

      {isActive === "viewed" && (
        <div className="flex flex-col justify-start px-5 my-6 h-[65%]">
          {viewedFunderData.map((item, index) => (
            <div
              key={index}
              className="flex justify-between items-center mb-4 cursor-pointer"
              onClick={() => setFunderDetail(true)}
            >
              <div className="flex items-center my-2">
                <img
                  src={item.img}
                  alt="Funder"
                  className="w-10 h-10 rounded-full mr-4"
                />
                <div>
                  <p className="text-[16px] font-regular text-gray-600">
                    {item.name}
                  </p>
                  <p className="text-[12px] font-regular text-zinc-400">
                    Funder
                  </p>
                </div>
              </div>

              <div className="text-center">
                <p className="text-[14px] ">{item.number}</p>
                <p className="text-[12px] text-zinc-400">Contact number</p>
              </div>

              <div className="text-right">
                <p className="text-sm text-gray-600">{item.number}</p>
                <p className="text-[12px] text-zinc-400">Email</p>
              </div>
            </div>
          ))}
        </div>
      )}

      <AnimatePresence>
        {funderDetail && (
          <FunderDetails onClose={() => setFunderDetail(false)} />
        )}
      </AnimatePresence>
    </div>
  );
};

export default BidDetails;
