import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  approveExitRequest,
  buyerGetOfferDetails,
  buyerViewOfferInvoiceDetails,
  cancelPfpProgram,
  createPfp,
  declineExitRequest,
  getAllBuyerPfp,
  getAllBuyerPfpInvoices,
  getAllCampaignAgreement,
  getCampaignInvoices,
  invoiceImport,
  stopPfpProgram,
  viewBuyerPfpById,
  viewMembersData,
} from "appstate/api/buyer/buyerpfp";
import { buyersDasboard } from "appstate/api/buyer/dashboard";
import {
  allearlyPaymentsRequestBulkStatus,
  allearlyPaymentsRequestStatus,
  approveSingleEarlyPaymentRequestClosed,
  approveSingleEarlyPaymentRequestFunder,
  approveSingleEarlyPaymentRequestWallet,
  getAllearlyPayments,
  getApprovedBulkEarlyPaymentDetails,
  getApprovedSingleEarlyPaymentDetails,
  getPendingBulkEarlyPaymentDetails,
  getPendingSingleEarlyPaymentDetails,
  getProcessingSingleEarlyPaymentDetails,
  getRejectedBulkEarlyPaymentDetails,
  getRejectedSingleEarlyPaymentDetails,
  updateSingleEarlyDiscountRate,
  // getAllPendingearlyPayments,
} from "appstate/api/buyer/earlyPayment";

import {
  documentUpload,
  updateUploadedDocumentById,
  deleteUploadedDocumentById,
  getUploadedDocumentById,
  getUploadedDocuments,
} from "appstate/api/documentUpload";

import {
  earlyPaymentApproveOrDecline,
  getAllNewEarlyPaymentRequest,
} from "appstate/api/buyer/earlyPayment";
import {
  allBuyerNotifications,
  notificationById,
} from "appstate/api/buyer/notification";
import {
  SupplierSendOnboardingInviteToFunderOrBuyer,
  buyerAcceptSupplierRequest,
  buyerRejectSupplierRequest,
  buyerSendOnboardingInviteToFunder,
  // buyerSendOnboardingInviteToSupplier,
  buyerSendRequestToFunder,
  buyerSendRequestToSupplier,
  buyersAssociatePendingRequest,
  disassociateBuyerFunder,
  disassociateBuyerSupplier,
  getAllBuyerAssociatedFunders,
  getAllBuyerAssociatedSuppliers,
} from "appstate/api/buyer/relationshipManagement";
import { getAllFunders } from "appstate/api/funder";
import { createTransPin } from "appstate/api/transactionPin";

const initialState = {
  isLoading: false,
  declineLoading: false,
  buyerDashboardData: [],
  pendingAssociateRequestData: [],
  buyerAssociatedSuppliersData: [],
  buyerAssociatedFundersData: [],
  associatedSupplierId: null,
  associatedFunderId: null,
  associateLoading: false,
  inviteLoading: false,
  disAssociateLoading: false,
  notifications: [],
  notification: null,
  createPfpLoading: false,
  cancelPfpLoading: false,
  stopPfpLoading: false,
  requestLoading: false,
  allBuyerPfpData: [],
  viewMembersData: [],
  allBuyerFundingAgreement: [],
  offerInvoices: [],
  importLoading: false,
  viewPfpByIdData: [],
  viewPfpOffersData: [],
  viewPfpOffersInvoiceData: [],
  allPfpInvoicesData: [],
  pfpId: null,
  updatedDiscountData: [],
  allEarlyPAymentsData: [],
  allEarlyPaymentRequestSingleData: [],
  allEarlyPaymentRequestBulkData: [],
  viewApprovedSingleEarlyPaymentDetailsData: [],
  pendingSingleEarlyPaymentDetailsData: [],
  updateSingleDiscountRateData: [],
  processingSingleEarlyPaymentDetailsData: [],
  pendingBulkEarlyPaymentDetailsData: [],
  approvedBulkEarlyPaymentDetailsData: [],
  viewRejectedSingleEarlyPaymentDetailsData: [],
  rejectedBulkEarlyPaymentDetailsData: [],
  getInvoiceId: null,
  viewAllUploadLoadDocsData: [],
  viewAllUploadLoadDocByIdData: {},
  allNewEarlyPaymentRequestData: [],
  invoiceIds: [],
  supplierIds: [],
  invoiceImport: [],
};

export const getBuyersDashboard = createAsyncThunk(
  "buyer/getBuyersDashboard",
  buyersDasboard
);

export const getBuyerPendingAssociateRequest = createAsyncThunk(
  "buyer/getBuyerPendingAssociateRequest",
  buyersAssociatePendingRequest
);

export const acceptSupplierRequest = createAsyncThunk(
  "buyer/acceptSupplierRequest",
  buyerAcceptSupplierRequest
);

export const allBuyerAssociatedSuppliers = createAsyncThunk(
  "buyer/allBuyersAssociatedSuppliers",
  getAllBuyerAssociatedSuppliers
);

export const onboardingInviteSupplier = createAsyncThunk(
  "buyer/onboardingInviteSupplier",
  SupplierSendOnboardingInviteToFunderOrBuyer
);

export const buyerRequestToAssociateWithFunder = createAsyncThunk(
  "buyer/buyerRequestToAssociateWithFunder",
  buyerSendRequestToFunder
);

export const buyerRequestToAssociateWithSupplier = createAsyncThunk(
  "buyer/buyerRequestToAssociateWithSupplier",
  buyerSendRequestToSupplier
);

export const buyerOnboardingInviteToFunder = createAsyncThunk(
  "buyer/buyerOnboardingInviteToFunder",
  buyerSendOnboardingInviteToFunder
);

export const allBuyerAssociatedFunders = createAsyncThunk(
  "buyer/allBuyerAssociatedFunders",
  getAllBuyerAssociatedFunders
);

export const buyerSupplierDisassociate = createAsyncThunk(
  "buyer/buyerSupplierDisassociate",
  disassociateBuyerSupplier
);

export const buyerFunderDisassociate = createAsyncThunk(
  "buyer/buyerFunderDisassociate",
  disassociateBuyerFunder
);

export const buyerRejectRequestSupplier = createAsyncThunk(
  "buyer/buyerRejectRequestSupplier",
  buyerRejectSupplierRequest
);

export const getAllBuyerNotifications = createAsyncThunk(
  "buyer/getAllBuyerNotifications",
  allBuyerNotifications
);

export const getNotificationById = createAsyncThunk(
  "buyer/getNotificationById",
  notificationById
);

export const creatNewPfp = createAsyncThunk("buyer/creatNewPfp", createPfp);
export const cancelPFP = createAsyncThunk("buyer/cancelPfp", cancelPfpProgram);
export const stopPFP = createAsyncThunk("buyer/stopPfp", stopPfpProgram);

export const getCampaignOffersInvoices = createAsyncThunk(
  "buyer/getCampaingInvoices ",
  getCampaignInvoices
);

export const allBuyerPfp = createAsyncThunk(
  "buyer/allBuyerPfp",
  getAllBuyerPfp
);

export const viewMembersPfp = createAsyncThunk(
  "buyer/viewMembers",
  viewMembersData
);

export const allAgrementDocument = createAsyncThunk(
  "buyer/allAggrementDocument",
  getAllCampaignAgreement
);

export const allPfpOfferList = createAsyncThunk(
  "buyer/allBuyerPfp",
  getAllBuyerPfp
);

export const buyerApproveExitRequest = createAsyncThunk(
  "buyer/buyerApproveExitRequest",
  approveExitRequest
);

export const buyerDeclineExitRequest = createAsyncThunk(
  "buyer/buyerDeclineExitRequest",
  declineExitRequest
);

export const importInvoice = createAsyncThunk(
  "buyer/importInvoice",
  invoiceImport
);

export const buyerViewPfpById = createAsyncThunk(
  "buyer/buyerViewPfpById",
  viewBuyerPfpById
);

export const buyerViewPfpOffers = createAsyncThunk(
  "buyer/buyerViewPfpOffers",
  buyerGetOfferDetails
);

export const buyerViewPfpInvoiceDetails = createAsyncThunk(
  "buyer/buyerViewPfpInvoiceDetails",
  buyerViewOfferInvoiceDetails
);

export const allBuyerPfpInvoices = createAsyncThunk(
  "buyer/allBuyerPfpInvoices ",
  getAllBuyerPfpInvoices
);

export const allEarlyPayments = createAsyncThunk(
  "buyer/allEarlyPayments",
  getAllearlyPayments
);

//Ealry payment request section
export const allEarlyPaymentRequestSingle = createAsyncThunk(
  "buyer/allEarlyPaymentRequestSingle",
  allearlyPaymentsRequestStatus
);

export const allEarlyPaymentRequestBulk = createAsyncThunk(
  "buyer/allEarlyPaymentRequestBulk",
  allearlyPaymentsRequestBulkStatus
);

// View Approved single early payment details
export const viewApprovedSingleEarlyPaymentDetails = createAsyncThunk(
  "buyer/viewSingleEarlyPaymentDetails",
  getApprovedSingleEarlyPaymentDetails
);

// View rejected single early payment details
export const viewRejectedSingleEarlyPaymentDetails = createAsyncThunk(
  "buyer/viewRejectedSingleEarlyPaymentDetails",
  getRejectedSingleEarlyPaymentDetails
);

//View Pending singleEarly payment details
export const pendingSingleEarlyPaymentDetails = createAsyncThunk(
  "buyer/pendingSingleEarlyPaymentDetails",
  getPendingSingleEarlyPaymentDetails
);

//View Pending bulk Early payment details
export const pendingBulkEarlyPaymentDetails = createAsyncThunk(
  "buyer/pendingBulkEarlyPaymentDetails",
  getPendingBulkEarlyPaymentDetails
);

//View approved bulk Early payment details
export const approvedBulkEarlyPaymentDetails = createAsyncThunk(
  "buyer/approvedBulkEarlyPaymentDetails",
  getApprovedBulkEarlyPaymentDetails
);

//View approved bulk Early payment details
export const rejectedBulkEarlyPaymentDetails = createAsyncThunk(
  "buyer/rejectedBulkEarlyPaymentDetails",
  getRejectedBulkEarlyPaymentDetails
);

//Approve single Early payment request
export const approveSingleEarlyPaymentRequest = createAsyncThunk(
  "buyer/approveSingleEarlyPaymentRequest",
  approveSingleEarlyPaymentRequestWallet
);

//Approve single Early payment request via funder
export const approveSingleEarlyPaymentRequestViaFunder = createAsyncThunk(
  "buyer/approveSingleEarlyPaymentRequestViaFunder",
  approveSingleEarlyPaymentRequestFunder
);

//Approve single Early payment request via Closed market
export const approveSingleEarlyPaymentRequestViaClosed = createAsyncThunk(
  "buyer/approveSingleEarlyPaymentRequestViaClosed",
  approveSingleEarlyPaymentRequestClosed
);

export const updateSingleDiscountRate = createAsyncThunk(
  "buyer/updateSingleDiscountRate",
  updateSingleEarlyDiscountRate
);

//View processing singleEarly payment details
export const processingSingleEarlyPaymentDetails = createAsyncThunk(
  "buyer/processingSingleEarlyPaymentDetails",
  getProcessingSingleEarlyPaymentDetails
);

export const uploadDocument = createAsyncThunk(
  "buyer/documentUpload",
  documentUpload
);
export const viewAllUploadLoadDocs = createAsyncThunk(
  "buyer/getUploadedDocuments",
  getUploadedDocuments
);
export const viewAllUploadLoadDocById = createAsyncThunk(
  "buyer/ getUploadedDocumentById",
  getUploadedDocumentById
);

export const removeUploadedDocumentById = createAsyncThunk(
  "buyer/deleteUploadedDocumentById",
  deleteUploadedDocumentById
);
export const editUploadedDocumentById = createAsyncThunk(
  "buyer/updateUploadedDocumentById",
  updateUploadedDocumentById
);
export const allNewEarlyPaymentRequest = createAsyncThunk(
  "buyer/allNewEarlyPaymentRequest",
  getAllNewEarlyPaymentRequest
);

export const approveOrDeclineEarlyPayment = createAsyncThunk(
  "buyer/approveOrDeclineEarlyPayment",
  earlyPaymentApproveOrDecline
);

export const fetchAllFunders = createAsyncThunk(
  "buyer/fetchAllFunders",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getAllFunders();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const buyerCreateTransPin = createAsyncThunk(
  "buyer/createTransPin",
  createTransPin
);

export const buyerSlice = createSlice({
  name: "buyer",
  initialState,
  reducers: {
    getAssociatedSupplierId: (state, action) => {
      state.associatedSupplierId = action.payload;
    },
    getAssociatedFunderId: (state, action) => {
      state.associatedFunderId = action.payload;
    },
    getPfpId: (state, action) => {
      state.pfpId = action.payload;
    },
    getInvoiceId: (state, action) => {
      state.getInvoiceId = action.payload;
    },
    getInvoiceIds: (state, action) => {
      state.invoiceIds = action.payload;
    },
    getSupplierIds: (state, action) => {
      state.supplierIds = action.payload;
    },
    getInvoices: (state, action) => {
      state.invoiceImport = action.payload;
    },
    updateEarlyPaymentsData: (state, action) => {
      const newData = action.payload;
      state.allEarlyPAymentsData.push(...newData);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBuyersDashboard.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getBuyersDashboard.fulfilled, (state, action) => {
      state.isLoading = false;
      state.buyerDashboardData = action.payload;
    });
    builder.addCase(getBuyersDashboard.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getBuyerPendingAssociateRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getBuyerPendingAssociateRequest.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.pendingAssociateRequestData = action.payload;
      }
    );
    builder.addCase(getBuyerPendingAssociateRequest.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(acceptSupplierRequest.pending, (state) => {
      state.associateLoading = true;
    });
    builder.addCase(acceptSupplierRequest.fulfilled, (state) => {
      state.associateLoading = false;
    });
    builder.addCase(acceptSupplierRequest.rejected, (state) => {
      state.associateLoading = false;
    });

    builder.addCase(allBuyerAssociatedSuppliers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allBuyerAssociatedSuppliers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.buyerAssociatedSuppliersData = action.payload;
    });
    builder.addCase(allBuyerAssociatedSuppliers.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(onboardingInviteSupplier.pending, (state) => {
      state.inviteLoading = true;
    });
    builder.addCase(onboardingInviteSupplier.fulfilled, (state) => {
      state.inviteLoading = false;
    });
    builder.addCase(onboardingInviteSupplier.rejected, (state) => {
      state.inviteLoading = false;
    });
    builder.addCase(buyerRequestToAssociateWithFunder.pending, (state) => {
      state.associateLoading = true;
    });
    builder.addCase(buyerRequestToAssociateWithFunder.fulfilled, (state) => {
      state.associateLoading = false;
    });
    builder.addCase(buyerRequestToAssociateWithFunder.rejected, (state) => {
      state.associateLoading = false;
    });
    builder.addCase(buyerRequestToAssociateWithSupplier.pending, (state) => {
      state.associateLoading = true;
    });
    builder.addCase(buyerRequestToAssociateWithSupplier.fulfilled, (state) => {
      state.associateLoading = false;
    });
    builder.addCase(buyerRequestToAssociateWithSupplier.rejected, (state) => {
      state.associateLoading = false;
    });
    builder.addCase(buyerOnboardingInviteToFunder.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(buyerOnboardingInviteToFunder.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(buyerOnboardingInviteToFunder.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(allBuyerAssociatedFunders.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allBuyerAssociatedFunders.fulfilled, (state, action) => {
      state.isLoading = false;
      state.buyerAssociatedFundersData = action.payload;
    });
    builder.addCase(allBuyerAssociatedFunders.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(buyerSupplierDisassociate.pending, (state) => {
      state.disAssociateLoading = true;
    });
    builder.addCase(buyerSupplierDisassociate.fulfilled, (state) => {
      state.disAssociateLoading = false;
    });
    builder.addCase(buyerSupplierDisassociate.rejected, (state) => {
      state.disAssociateLoading = false;
    });
    builder.addCase(buyerFunderDisassociate.pending, (state) => {
      state.disAssociateLoading = true;
    });
    builder.addCase(buyerFunderDisassociate.fulfilled, (state) => {
      state.disAssociateLoading = false;
    });
    builder.addCase(buyerFunderDisassociate.rejected, (state) => {
      state.disAssociateLoading = false;
    });
    builder.addCase(buyerRejectRequestSupplier.pending, (state) => {
      state.declineLoading = true;
    });
    builder.addCase(buyerRejectRequestSupplier.fulfilled, (state) => {
      state.declineLoading = false;
    });
    builder.addCase(buyerRejectRequestSupplier.rejected, (state) => {
      state.declineLoading = false;
    });
    builder.addCase(getAllBuyerNotifications.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllBuyerNotifications.fulfilled, (state, action) => {
      state.isLoading = false;
      state.notifications = action.payload;
    });
    builder.addCase(getAllBuyerNotifications.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getNotificationById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getNotificationById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.notification = action.payload;
    });
    builder.addCase(getNotificationById.rejected, (state) => {
      state.isLoading = false;
    });

    // pfp section
    builder.addCase(creatNewPfp.pending, (state) => {
      state.createPfpLoading = true;
    });
    builder.addCase(creatNewPfp.fulfilled, (state) => {
      state.createPfpLoading = false;
    });
    builder.addCase(creatNewPfp.rejected, (state) => {
      state.createPfpLoading = false;
    });

    // Cancel PFP Program
    builder.addCase(cancelPFP.pending, (state) => {
      state.cancelPfpLoading = true;
    });
    builder.addCase(cancelPFP.fulfilled, (state) => {
      state.cancelPfpLoading = false;
    });
    builder.addCase(cancelPFP.rejected, (state) => {
      state.cancelPfpLoading = false;
    });

    // Stop PFP Program
    builder.addCase(stopPFP.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(stopPFP.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(stopPFP.rejected, (state) => {
      state.isLoading = false;
    });

    //get campaign offer invoices
    builder.addCase(getCampaignOffersInvoices.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCampaignOffersInvoices.fulfilled, (state, action) => {
      state.isLoading = false;
      state.offerInvoices = action.payload;
    });
    builder.addCase(getCampaignOffersInvoices.rejected, (state) => {
      state.isLoading = false;
    });

    // All buyer payable finance Program
    builder.addCase(allBuyerPfp.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allBuyerPfp.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allBuyerPfpData = action.payload;
    });
    builder.addCase(allBuyerPfp.rejected, (state) => {
      state.isLoading = false;
    });

    // All members in payable finance Program
    builder.addCase(viewMembersPfp.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(viewMembersPfp.fulfilled, (state, action) => {
      state.isLoading = false;
      state.viewMembersData = action.payload;
    });
    builder.addCase(viewMembersPfp.rejected, (state) => {
      state.isLoading = false;
    });

    //get all campaign funding agreement
    builder.addCase(allAgrementDocument.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allAgrementDocument.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allBuyerFundingAgreement = action.payload;
    });
    builder.addCase(allAgrementDocument.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(buyerApproveExitRequest.pending, (state) => {
      state.requestLoading = true;
    });
    builder.addCase(buyerApproveExitRequest.fulfilled, (state) => {
      state.requestLoading = false;
    });
    builder.addCase(buyerApproveExitRequest.rejected, (state) => {
      state.requestLoading = false;
    });

    builder.addCase(buyerDeclineExitRequest.pending, (state) => {
      state.declineLoading = true;
    });
    builder.addCase(buyerDeclineExitRequest.fulfilled, (state) => {
      state.declineLoading = false;
    });
    builder.addCase(buyerDeclineExitRequest.rejected, (state) => {
      state.declineLoading = false;
    });

    builder.addCase(importInvoice.pending, (state) => {
      state.importLoading = true;
    });
    builder.addCase(importInvoice.fulfilled, (state) => {
      state.importLoading = false;
    });
    builder.addCase(importInvoice.rejected, (state) => {
      state.importLoading = false;
    });

    builder.addCase(buyerViewPfpById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(buyerViewPfpById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.viewPfpByIdData = action.payload;
    });
    builder.addCase(buyerViewPfpById.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(buyerViewPfpOffers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(buyerViewPfpOffers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.viewPfpOffersData = action.payload;
    });
    builder.addCase(buyerViewPfpOffers.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(buyerViewPfpInvoiceDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(buyerViewPfpInvoiceDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.viewPfpOffersInvoiceData = action.payload;
    });
    builder.addCase(buyerViewPfpInvoiceDetails.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(allBuyerPfpInvoices.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allBuyerPfpInvoices.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allPfpInvoicesData = action.payload;
    });
    builder.addCase(allBuyerPfpInvoices.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(allEarlyPayments.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allEarlyPayments.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allEarlyPAymentsData = action.payload;
    });
    builder.addCase(allEarlyPayments.rejected, (state) => {
      state.isLoading = false;
    });

    //All Pending Early payment single
    builder.addCase(allEarlyPaymentRequestSingle.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allEarlyPaymentRequestSingle.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allEarlyPaymentRequestSingleData = action.payload;
    });
    builder.addCase(allEarlyPaymentRequestSingle.rejected, (state) => {
      state.isLoading = false;
    });

    //All Pending Early payment bulk
    builder.addCase(allEarlyPaymentRequestBulk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allEarlyPaymentRequestBulk.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allEarlyPaymentRequestBulkData = action.payload;
    });
    builder.addCase(allEarlyPaymentRequestBulk.rejected, (state) => {
      state.isLoading = false;
    });

    //View approved single Early payment details
    builder.addCase(viewApprovedSingleEarlyPaymentDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      viewApprovedSingleEarlyPaymentDetails.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.viewApprovedSingleEarlyPaymentDetailsData = action.payload;
      }
    );
    builder.addCase(viewApprovedSingleEarlyPaymentDetails.rejected, (state) => {
      state.isLoading = false;
    });

    //View rejected single Early payment details
    builder.addCase(viewRejectedSingleEarlyPaymentDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      viewRejectedSingleEarlyPaymentDetails.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.viewRejectedSingleEarlyPaymentDetailsData = action.payload;
      }
    );
    builder.addCase(viewRejectedSingleEarlyPaymentDetails.rejected, (state) => {
      state.isLoading = false;
    });

    //View pending single Early payment details
    builder.addCase(pendingSingleEarlyPaymentDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      pendingSingleEarlyPaymentDetails.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.pendingSingleEarlyPaymentDetailsData = action.payload;
      }
    );
    builder.addCase(pendingSingleEarlyPaymentDetails.rejected, (state) => {
      state.isLoading = false;
    });

    //View pending Bulk Early payment details
    builder.addCase(pendingBulkEarlyPaymentDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      pendingBulkEarlyPaymentDetails.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.pendingBulkEarlyPaymentDetailsData = action.payload;
      }
    );
    builder.addCase(pendingBulkEarlyPaymentDetails.rejected, (state) => {
      state.isLoading = false;
    });

    //View approved Bulk Early payment details
    builder.addCase(approvedBulkEarlyPaymentDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      approvedBulkEarlyPaymentDetails.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.approvedBulkEarlyPaymentDetailsData = action.payload;
      }
    );
    builder.addCase(approvedBulkEarlyPaymentDetails.rejected, (state) => {
      state.isLoading = false;
    });

      //View rejected Bulk Early payment details
      builder.addCase(rejectedBulkEarlyPaymentDetails.pending, (state) => {
        state.isLoading = true;
      });
      builder.addCase(
        rejectedBulkEarlyPaymentDetails.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.rejectedBulkEarlyPaymentDetailsData = action.payload;
        }
      );
      builder.addCase(rejectedBulkEarlyPaymentDetails.rejected, (state) => {
        state.isLoading = false;
      });

    //approve single Early payment request
    builder.addCase(approveSingleEarlyPaymentRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(approveSingleEarlyPaymentRequest.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(approveSingleEarlyPaymentRequest.rejected, (state) => {
      state.isLoading = false;
    });

    //approve single Early payment request via wallet
    builder.addCase(
      approveSingleEarlyPaymentRequestViaFunder.pending,
      (state) => {
        state.isLoading = true;
      }
    );
    builder.addCase(
      approveSingleEarlyPaymentRequestViaFunder.fulfilled,
      (state) => {
        state.isLoading = false;
      }
    );
    builder.addCase(
      approveSingleEarlyPaymentRequestViaFunder.rejected,
      (state) => {
        state.isLoading = false;
      }
    );

    //approve single Early payment request via closed market
    builder.addCase(
      approveSingleEarlyPaymentRequestViaClosed.pending,
      (state) => {
        state.isLoading = true;
      }
    );
    builder.addCase(
      approveSingleEarlyPaymentRequestViaClosed.fulfilled,
      (state) => {
        state.isLoading = false;
      }
    );
    builder.addCase(
      approveSingleEarlyPaymentRequestViaClosed.rejected,
      (state) => {
        state.isLoading = false;
      }
    );

    //update discount rate for single Early payment details
    builder.addCase(updateSingleDiscountRate.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateSingleDiscountRate.fulfilled, (state, action) => {
      state.isLoading = false;
      state.updatedDiscountData = action.payload;
    });
    builder.addCase(updateSingleDiscountRate.rejected, (state) => {
      state.isLoading = false;
    });

    //View processing single Early payment details
    builder.addCase(processingSingleEarlyPaymentDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      processingSingleEarlyPaymentDetails.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.processingSingleEarlyPaymentDetailsData = action.payload;
      }
    );
    builder.addCase(processingSingleEarlyPaymentDetails.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(uploadDocument.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(uploadDocument.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(uploadDocument.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(viewAllUploadLoadDocs.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(viewAllUploadLoadDocs.fulfilled, (state, action) => {
      state.isLoading = false;
      state.viewAllUploadLoadDocsData = action.payload;
    });
    builder.addCase(viewAllUploadLoadDocs.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(viewAllUploadLoadDocById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(viewAllUploadLoadDocById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.viewAllUploadLoadDocByIdData = action.payload;
    });
    builder.addCase(viewAllUploadLoadDocById.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(removeUploadedDocumentById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(removeUploadedDocumentById.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(removeUploadedDocumentById.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(editUploadedDocumentById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editUploadedDocumentById.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(editUploadedDocumentById.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(allNewEarlyPaymentRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allNewEarlyPaymentRequest.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allNewEarlyPaymentRequestData = action.payload;
    });
    builder.addCase(allNewEarlyPaymentRequest.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(approveOrDeclineEarlyPayment.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(approveOrDeclineEarlyPayment.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(approveOrDeclineEarlyPayment.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchAllFunders.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAllFunders.fulfilled, (state, action) => {
      state.isLoading = false;
      state.buyerAssociatedFundersData = action.payload;
    });
    builder.addCase(fetchAllFunders.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(buyerCreateTransPin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(buyerCreateTransPin.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(buyerCreateTransPin.rejected, (state) => {
      state.isLoading = false;
    });
  },
});
export const {
  getAssociatedSupplierId,
  getAssociatedFunderId,
  getPfpId,
  getInvoiceId,
  getInvoiceIds,
  getSupplierIds,
  getInvoices,
  updateEarlyPaymentsData,
} = buyerSlice.actions;
export default buyerSlice.reducer;
