import React, { useState } from "react";

import Agreement from "../Agreement";
import RouteHeader from "../../RouteHeader";

import TransitionScale from "shared/animation/TransitionScale";
import { PAIconActionDownloadDoc } from "assets/images/svgs";

import AcceptSignModal2 from "../AgreementDetails/Modal/AcceptSignModal2";
import AgreementSuccessModal from "../AgreementDetails/Modal/AgreementSucessModal";

const UploadAgreement = ({ getBack, file, setTemplateName, setAgreementType, templateName, agreementType, handleSubmitTemplate, handleSaveTemplate, isSubmit, setIsSubmit, isSubmitSuccess, setIsSubmitSuccess, isSavedSuccess, setIsSavedSuccess, isOpen, setIsOpen  }) => {
  const [showAgreement] = useState(false);

  return (
    <>
      {showAgreement ? (
        <Agreement />
      ) : (
        <>
          <AcceptSignModal2
            isOpen={isSubmit}
            onClose={() => setIsSubmit(false)}
            mess1={
              "Are you sure you would like to proceed with submitting this document in this template? Doing so would make it"
            }
            action={"Active"}
            mess2={"and available when creating agreements"}
            handleYes={handleSubmitTemplate}
          />

          <AcceptSignModal2
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            mess1={
              "Are you sure you want to save this document? It will be saved as"
            }
            action={"Draft"}
            mess2={"and won't be available when creating a new agreement."}
            handleYes={handleSaveTemplate}
          />
          <AgreementSuccessModal
            isOpen={isSavedSuccess}
            onClose={() => setIsSavedSuccess(false)}
            message={"Document saved successfully"}
          />
          <AgreementSuccessModal
            isOpen={isSubmitSuccess}
            onClose={() => setIsSubmitSuccess(false)}
            message={"Document submitted successfully"}
          />

          <div className="px-7 mb-5">
            <RouteHeader
              Route1="Agreements"
              //  Heading="Upload agreement"
              handleBack={getBack}
            />
          </div>
          <div class=" px-6 w-96 inline-flex flex-col justify-start items-start gap-1">
            <div class="justify-start text-black text-sm font-medium ">
              Upload template
            </div>
            <div class="w-80 min-w-80 justify-start text-neutral-800 text-xs font-normal ">
              Upload document templates for your agreements
            </div>
          </div>
          <div>
            <div className="px-7 mb-10  w-[100%]"></div>

            <div className="grid lg:grid-cols-[40%,57.5%] gap-5 w-full">
              <TransitionScale className=" pb-5 bg-white  rounded-[10px]  pt-2">
                <section className="min-h-[570px] rounded-r-[10px]   w-full ">
                  <div className="py-6 px-4 bg-[#959595]/5 rounded-[5px] h-full">
                    <header className="flex  items-center justify-between shadow-[0px_2px_4px_0px_rgba(98,98,98,0.07)] border-b border-stone-300 py-3">
                      <div class="justify-start text-neutral-800 text-base font-semibold ">
                        Template Details
                      </div>
                      {/* <PAIconAgreeFolder  /> */}
                    </header>

                    <div className="flex flex-col w-full justify-center items-center mt-5 gap-3">
                      <div className="w-full">
                        <div className="self-stretch justify-start text-black text-sm font-medium ">
                          Template Name
                        </div>
                        <input className=" shadow-sm  text-sm focus:outline-none  cursor-pointer  px-3   py-3.5 rounded-[5px] border border-[#949494] justify-start items-end gap-2 inline-flex  w-full "
                          value={templateName}
                           onChange={(e) => setTemplateName(e.target.value)}
                        />
                      </div>

                      <div className=" w-full">
                        <div class="self-stretch justify-start text-black text-sm font-medium ">
                          Select agreement type
                        </div>
                        <select
                          className=" shadow-sm  text-sm focus:outline-none  cursor-pointer  px-3   py-3.5 rounded-[5px] border border-[#949494] justify-start items-end gap-2 inline-flex  w-full "
                          value={agreementType}
                          onChange={(e) => setAgreementType(e.target.value)}
                        >
                          <option value="">
                            All Agreement Type
                          </option>
                          <option value="PFP_CAMPAIGN">
                            PFP Campaign
                          </option>
                          <option value="SUPPLIER_ONBOARDING">
                            Supplier Onboarding
                          </option>
                          <option value="BUYER_ONBOARDING">
                            Buyer Onboarding
                          </option>
                          <option value="FUNDER_ONBOARDING">
                            Funder Onboarding
                          </option>
                        </select>
                      </div>

                      <div class="inline-flex justify-start items-start gap-4"></div>

                      <div class="self-stretch justify-start text-black text-sm font-medium ">
                        Action for document uploaded
                      </div>

                      <div className=" flex w-full justify-between items-center ">
                        <div
                          className=" cursor-pointer"
                          onClick={() => setIsOpen(true)}
                        >
                          <div class="p-2.5 rounded-[5px] outline outline-1 outline-offset-[-1px] outline-stone-300 inline-flex justify-center items-center gap-2.5   px-7">
                            <div class="justify-start text-neutral-400 text-xs font-medium ">
                              Save
                            </div>
                          </div>
                        </div>

                        <div
                          className=" cursor-pointer"
                          onClick={() => setIsSubmit(true)}
                        >
                          {/* <div class="p-2.5 bg-[#065f46] rounded-[5px] inline-flex justify-center items-center gap-2.5">
             <div class="justify-start text-white text-xs font-medium ">Next step</div>
             </div> */}

                          <div class="p-2.5 bg-[#065f46] rounded-[5px] inline-flex justify-center items-center gap-2.5 px-5">
                            <div class="justify-start text-[#ffffff] text-xs font-medium ">
                              Submit
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </TransitionScale>
              <TransitionScale className=" pb-5 bg-white  rounded-[10px]  pt-2">
                <section className="min-h-[570px]   py-8  w-full  bg-[#959595]/5 rounded-[5px] ">
                  <header className="flex w-full mb-2.5 justify-between font-bold px-3">
                    <div className="flex items-center gap-3">
                      {" "}
                      <span>
                        <PAIconActionDownloadDoc className=" w-7" />
                      </span>
                      <div class="justify-start text-neutral-800  text-base font-medium ">
                        Document preview
                      </div>
                    </div>
                    <div>
                      <div class="justify-start text-neutral-800 text-base font-medium ">
                      {file ? `${file.name}` : "No file selected"}
                      </div>
                    </div>

                    {/* <span
                 onClick={() => handleDownload()}
                 className="cursor-pointer"
               >
                 {!downloadAgreementClick && <PAIconDownloadFunding />}
               </span> */}
                  </header>

                  <main className="flex flex-col gap-3">
                    <section className="flex flex-col gap-1">
                      <iframe
                        disableprint
                        src={file ? URL.createObjectURL(file) : ""}
                        // style={{ width: "100%", height: "100%", border: "none" }}
                        className="bg-none border-none h-screen w-full "
                        title="PDF Viewer"
                      />
                    </section>

                    {/* <section className="flex flex-col gap-[10px]"></section> */}
                  </main>
                </section>
              </TransitionScale>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default UploadAgreement;
