import { PAIconSuccess, PAIconTransferFund } from "assets/images/svgs";
import React, { useState, useEffect } from "react";
import FundCard from "features/wallet(new)/overview/FundAccount/ExternalSource/card";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { confirmFundsTransfer } from "appstate/buyer/buyerSlice";

const FundTransferModal = ({
  onAction,
  invoiceIds,
  onClose,
  defaultVirtualAccount,
  upcomingOverview,
  fundTransferAmount,
}) => {
  const dispatch = useDispatch();
  const [isConfirm, setIsConfirm] = useState(false);
  const [isConfirmSuccess, setIsConfirmSuccess] = useState(false);

  const handleFundConfirmation = async () => {
    try {
      const response = await dispatch(
        confirmFundsTransfer({
          virtualAccountId: defaultVirtualAccount.id,
          amount: fundTransferAmount,
          disbursementType: "PAYABLES",
          invoiceIds: [invoiceIds],
        })
      ).unwrap();

      if (response.status === 400) {
        setIsConfirm(true);
      } else {
        return
      }
    } catch (e) {
      console.error(e);
    }
    // onAction();
  };

  useEffect(() => {
    if (isConfirm) {
      setTimeout(() => {
        setIsConfirmSuccess(true);
        onAction();
      }, 5000);
    }
  }, [isConfirm, onAction]);


  return (
    <motion.div>
      <motion.div className="bg-slate-600/20 fixed inset-0 flex items-center justify-center z-50">
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.2,
            },
          }}
          exit={{
            opacity: 0,
            transition: {
              delay: 0.1,
            },
          }}
          className="modal-overlay"
        ></motion.div>
        <motion.div
          onClick={(e) => e.stopPropagation()}
          initial={{
            scale: 0,
          }}
          animate={{
            scale: 1,
            transition: {
              duration: 0.2,
            },
          }}
          exit={{
            scale: 0,
            transition: {
              delay: 0.1,
            },
          }}
          className="modal-container ml-16 bg-white p-4 rounded-lg shadow-lg z-50 w-[40%]"
        >
          {isConfirm === true ? (
            <div>
              {isConfirmSuccess ? (
                <>
                  <div className="flex justify-between items-center mb4">
                    <p className="text-black font-sans text-base font-bold p-3 px-5 rounded-sm italic">
                      Trnsfer of Funds
                    </p>
                    <button
                      onClick={onClose}
                      className="text-black font-sans text-xl cursor-pointer flex justify-center items-center"
                    >
                      &times; <span className="text-sm mx-2 mt-1">Close</span>
                    </button>
                  </div>
                  <div className="border border-gray mb-5 px-5"></div>
                  <div className="bg-white p-6 rounded-lg text-center flex flex-col items-center justify-center">
                    <PAIconSuccess />
                    <p className="my-5 text-[20px] font-semibold text-gray-700">
                      Payment Confirmed
                    </p>
                    <p className="text-[20px]">
                      Please wait as the received funds are about to be liened
                      for the selected request...
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <div className="flex justify-between items-center mb4">
                    <p className="text-black font-sans text-base font-bold p-3 px-5 rounded-sm italic">
                      Verifying Account
                    </p>
                    <button
                      onClick={onClose}
                      className="text-black font-sans text-xl cursor-pointer flex justify-center items-center"
                    >
                      &times; <span className="text-sm mx-2 mt-1">Close</span>
                    </button>
                  </div>
                  <div className="border border-gray mb-5 px-5"></div>
                  <div className="bg-white p-6 rounded-lg text-center">
                    <p className="mb-5 text-[20px] font-semibold text-gray-700">
                      Confirming Payment
                    </p>
                    {/* Spinning Loader */}
                    <div className="w-16 h-16 border-4 border-white border-t-secondaryColor rounded-full animate-spin mx-auto"></div>
                  </div>
                </>
              )}
            </div>
          ) : (
            <>
              <div className="flex justify-between items-center mb4">
                <p className="text-black font-sans text-base font-bold p-3 px-5 rounded-sm italic">
                  Fund Account
                </p>
                <button
                  onClick={onClose}
                  className="text-black font-sans text-xl cursor-pointer flex justify-center items-center"
                >
                  &times; <span className="text-sm mx-2 mt-1">Close</span>
                </button>
              </div>
              <div className="border border-gray mb-5 px-5"></div>

              <div className="flex flex-col items-center justify-center">
                <PAIconTransferFund />
                <div className="text-neutral-800 text-sm font-bold">
                  Fund Wallet Through Bank Transfer
                </div>
                <div className="text-center text-neutral-500 text-xs mt-5 mb-7">
                  Transfer fund to your wallet by sending money to the account
                  below
                </div>
                <FundCard
                  accountName={defaultVirtualAccount?.accountName}
                  bankName={defaultVirtualAccount?.bank}
                  accountNumber={defaultVirtualAccount?.accountNumber}
                  accountType={"Virtual Account"}
                />

                <div className="mt-4">
                  <h2>
                    NGN{" "}
                    <span className="font-semibold text-[24px]">
                      {fundTransferAmount ||
                        upcomingOverview?.upcomingPaymentDetails.payableAmount}
                    </span>{" "}
                  </h2>
                  <p className="font-semibold text-[16px]">Fund Amount</p>
                </div>

                <div className="flex items-center w-[100%] mt-5 justify-end gap-4 mb-5">
                  <button onClick={onClose}>Cancel</button>
                  <button
                    onClick={handleFundConfirmation}
                    className="px-4 py-2 bg-primaryColor rounded-lg text-white"
                  >
                    Confirm Payment
                  </button>
                </div>
              </div>
            </>
          )}
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default FundTransferModal;
